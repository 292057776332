import { IncClickAwayPopper, IncErrorIcon, IncFaIcon, IncModal, IncModalProps, IncToolTip } from "@inception/ui";
import React, { Component, createRef, CSSProperties, RefObject } from "react";
import { VerticallyCenteredRow } from "../../../../../../components";
import { getDisplayTextForRichText, updateBlockDataInEditorState } from "../../utils";
import { CustomBlockState, CustomBlockProps } from "../types";
import { getCommonStateFromProps, getErrorStateForSection } from "../propToState";
import { TemplateCanvasSection } from "../../../../../../services/api/operationalise";
import { TablePropertiesRenderer } from "./TablePropertiesRenderer";

export class TableRenderer extends Component<CustomBlockProps, CustomBlockState> {
  private ref: RefObject<HTMLDivElement> = createRef();

  constructor(props: CustomBlockProps) {
    super(props);

    const partState = getCommonStateFromProps(props);
    this.state = {
      isSettingsOpen: false,
      isPropertiesOpen: false,
      ...partState
    };

    props.blockProps.onErrors?.(partState.errors, partState.section.sectionId);
  }

  setReadOnly = () => {
    const { onChangeReadOnly } = this.props.blockProps;
    onChangeReadOnly(true);
  };

  unSetReadOnly = () => {
    const { onChangeReadOnly } = this.props.blockProps;
    onChangeReadOnly(false);
  };

  openSettings = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({
      isSettingsOpen: true
    });
  };

  closeSettings = () => {
    this.setState({
      isSettingsOpen: false
    });
  };

  openProperties = (e: React.MouseEvent) => {
    this.setReadOnly();

    e.stopPropagation();
    this.setState({
      isPropertiesOpen: true,
      isSettingsOpen: false
    });
  };

  closeProperties = () => {
    this.unSetReadOnly();

    this.setState({
      isPropertiesOpen: false,
      isSettingsOpen: false,
      section: getCommonStateFromProps(this.props).section
    });
  };

  onSectionChange = (section: TemplateCanvasSection) => {
    this.setState({
      section
    });
  };

  onApplyChanges = () => {
    this.unSetReadOnly();

    const { block, blockProps } = this.props;
    const { onEditorStateChange, onErrors, beforeEditorStateChange } = blockProps;

    const { section } = this.state;

    const blockKey = block.getKey();
    onEditorStateChange(prevEditorState => {
      beforeEditorStateChange();

      const nextEditorState = updateBlockDataInEditorState(prevEditorState, blockKey, {
        section
      });
      return nextEditorState;
    });

    const errorState = getErrorStateForSection(this.state);
    this.setState({
      isPropertiesOpen: false,
      isSettingsOpen: false,
      ...errorState
    });

    onErrors(errorState.errors, section.sectionId);
  };

  actions: IncModalProps["actions"] = {
    primary: {
      id: "common.actions.apply",
      color: "secondary-blue",
      onClick: this.onApplyChanges
    },
    secondary: {
      id: "common.actions.cancel",
      color: "secondary-red",
      onClick: this.closeProperties
    }
  };

  render() {
    const { blockProps, block, children } = this.props;
    const { onSectionDelete, template, onErrors } = blockProps;

    const { textElements, elementGroups } = template;

    const {
      isSettingsOpen,
      isPropertiesOpen,
      section,
      sectionProperties,
      isValid,
      displayErrorMessage,
      sectionElement
    } = this.state;

    const loopText = sectionElement?.displayText || "";

    let { title, content, title2, content2 } = section || {};

    const { childElementProp = {}, childElementProp2 = {} } = section || {};

    const onDelete = () => {
      onSectionDelete(block.getKey());
      onErrors([], section.sectionId);
    };

    title = title || "Add Column I";
    content = content || "Add Row I";
    title2 = title2 || "Add Column II";
    content2 = content2 || "Add Row II";

    const properties = {
      ...childElementProp,
      ...childElementProp2
    };

    const head1 = getDisplayTextForRichText(title, textElements, properties);
    const row1 = getDisplayTextForRichText(content, textElements, properties);
    const head2 = getDisplayTextForRichText(title2, textElements, properties);
    const row2 = getDisplayTextForRichText(content2, textElements, properties);

    return (
      <div
        className="template-editor-v2--section table-section"
        ref={this.ref}
      >
        <div
          className="section-content"
          contentEditable="false"
          suppressContentEditableWarning
        >
          <table
            className="table table-dark inc-cursor-pointer"
            onClick={this.openSettings}
            style={style}
          >
            <thead>
              <tr>
                <th></th>
                <th>{head1}</th>
                <th>{head2}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: 80 }}>
                  <VerticallyCenteredRow
                    className="inc-label-common"
                    style={{ height: "100%" }}
                  >
                    Rows
                    <IncToolTip
                      placement="top"
                      showArrow
                      titleText={loopText}
                    >
                      <VerticallyCenteredRow className="marginLt8">
                        <IncFaIcon iconName="redo" />
                      </VerticallyCenteredRow>
                    </IncToolTip>
                  </VerticallyCenteredRow>
                </td>
                <td>{row1}</td>
                <td>{row2}</td>
              </tr>
            </tbody>
          </table>

          {!isValid && (
            <IncToolTip
              placement="top"
              showArrow
              titleText={displayErrorMessage}
              variant="error"
            >
              <VerticallyCenteredRow className="error-icon">
                <IncErrorIcon />
              </VerticallyCenteredRow>
            </IncToolTip>
          )}
        </div>

        <div
          className="marginLt6 editable-content"
          contentEditable="true"
          suppressContentEditableWarning
        >
          {children}
        </div>

        <IncClickAwayPopper
          anchorEl={this.ref.current}
          className="section-settings"
          onClickAway={this.closeSettings}
          placement="bottom-start"
          show={isSettingsOpen}
        >
          <VerticallyCenteredRow>
            <div
              className="section-settings--option"
              onClick={this.openProperties}
            >
              <IncFaIcon
                className="marginRt6"
                iconName="edit"
              />
              Edit
            </div>

            <div className="section-settings--separator" />

            <div
              className="section-settings--option status-danger"
              onClick={onDelete}
            >
              <IncFaIcon
                className="marginRt6"
                iconName="minus-circle"
              />
              Delete
            </div>
          </VerticallyCenteredRow>
        </IncClickAwayPopper>

        <IncModal
          actions={this.actions}
          className="section-properties"
          disableFocusOnLoad
          onClose={this.closeProperties}
          show={isPropertiesOpen}
          size="lg"
          titleText="Edit"
        >
          <TablePropertiesRenderer
            elementGroups={elementGroups}
            loopTooltip={loopText}
            onChange={this.onSectionChange}
            properties={sectionProperties}
            section={section}
            textElements={textElements}
          />
        </IncModal>
      </div>
    );
  }
}

const style: CSSProperties = {
  borderRadius: 8,
  margin: 0
};
