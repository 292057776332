import React, { FC, useMemo } from "react";
import { escape } from "lodash";
import { GeoMap, GeoSeriesLatLon } from "../../../../../components";
import { logger } from "../../../../../core";
import { getLatLonFromPointWKT, getPointWKTLocationFromJSONString } from "../../../../../utils/LocationUtils";
import { EntityWidgetData } from "../../../../../biz-entity";
import { CatalogVizRendererProps, useCommonRendererFunctionality } from "./common";

export const GeoMapRenderer: FC<CatalogVizRendererProps> = props => {
  const { className, loadingElement, noDataElement, aggregatedTags } = props;

  const { data, isFetching, isError, dataExists } = useCommonRendererFunctionality(props);

  const geoSeries = useMemo(() => constructGeoSeries(data[0], aggregatedTags), [aggregatedTags, data]);

  if (isFetching) {
    return loadingElement;
  }

  if (!dataExists || isError) {
    return noDataElement;
  }

  return (
    <GeoMap
      autoAdjustZoom
      containerElemClass={className}
      series={geoSeries}
    />
  );
};

export const constructGeoSeries = (data: EntityWidgetData, aggregatedTags: string[]) => {
  const aggregatedTag = aggregatedTags?.[0];
  const postAggData = data.postAggResult.data;
  const compareData = data.postAggResult.timeShiftData;
  const refId = Object.keys(postAggData)[0] || Object.keys(compareData)[0] || "";

  const dataframes = postAggData[refId]?.data || [];

  const compareRefId = Object.keys(compareData)[0];
  const compareDataFrames = compareData[compareRefId]?.data || [];

  const geoSeries: GeoSeriesLatLon[] = [];

  dataframes.forEach((df, i) => {
    const { fields, labels, eLabels } = df;
    const { fields: cFields } = compareDataFrames?.[i] || {};

    const values = fields[1].data;
    const value = values[0];
    const compareValues = cFields?.[1]?.data;
    const compareValue = compareValues?.[0];

    const rawId = labels[aggregatedTag] || "";
    const name = eLabels[aggregatedTag] || rawId;

    const geoLocationPointWKT = getPointWKTLocationFromJSONString(rawId);
    try {
      const { lat, lon } = getLatLonFromPointWKT(geoLocationPointWKT);
      geoSeries.push({
        lat,
        lon,
        name: escape(name),
        value,
        custom: {
          compareData: compareValue
        }
      });
    } catch (err) {
      logger.error("GeoMapRenderer", "Error getting lat/lon data for series. ", labels);
    }
  });

  return geoSeries;
};
