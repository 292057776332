import React, { FC, useMemo, useCallback, memo, useEffect, useState, useRef } from "react";
import { IncDurationSelector, IncSelect, IncSelectOption, IncSelectorDuration } from "@inception/ui";
import { isEqual, last } from "lodash";
import {
  FieldPickerOptionData,
  UserServiceField,
  TraceUIAggregation,
  UserServiceFieldMetricConfig,
  TimeObj,
  UserServiceFilterExpression,
  FieldPickerContextDTO,
  DemoDataParams
} from "../../../services/api/explore";
import { FieldPickerContainer } from "../../field-picker";
import { AggregationSelect } from "../../AggregationSelect";
import { getAggregationUIOption, logger } from "../../../core";
import { getDefaultDataTypeAggregation, getTraceUIAggregation } from "../../../utils/ExploreUtils";
import { getDurationFromTimeObj, getTimeObjFromDuration } from "../../../utils/DurationUtils";
import { useFieldPicker } from "../../../field-picker";
import { USFieldWidgetUtils } from "../../../dashboard/widgets/USField/USFieldWidgetUtils";
import { FieldPickerUtils, pluralizeWord } from "../../../utils";
import { BaseProps } from "./types";

export type EventFieldSelectionPayload = {
  userServiceField?: UserServiceField;
  filterExpressions?: UserServiceFilterExpression[];
  aggregator?: string;
  lookBack?: TimeObj;
};

interface Props extends BaseProps {
  eventTypeName: string;
  aggregator: string;
  userServiceField: UserServiceField;
  filterExpressions: UserServiceFilterExpression[];
  lookBack: TimeObj;
  onChange: (nPayload: EventFieldSelectionPayload, metricName?: string) => void;

  hideLookBack?: boolean;
  demoDataParams?: DemoDataParams;
  readOnly?: boolean;
  fieldLabel?: string;

  addRateMetricOptions?: boolean;
  onRateMetricSelect?: (
    isSuccessfulRateMetric: boolean,
    eventIdField: UserServiceField,
    hasErrorField: UserServiceField,
    metricName: string
  ) => void;
}

export const EventFieldSelector: FC<Props> = memo(props => {
  const {
    fieldPickerContext,
    aggregator,
    userServiceField,
    getLatestTimeRange,
    onChange,
    lookBack,
    eventTypeName,
    filterExpressions,
    hideLookBack = false,
    demoDataParams = null,
    readOnly = false,
    fieldLabel = "",
    addRateMetricOptions,
    onRateMetricSelect
  } = props;

  const {
    data,
    error: fieldPickerError,
    isError: isFieldPickerError,
    getFields,
    isFetching: isFieldsFetching
  } = useFieldPicker();

  const prevFieldPickerContext = useRef<FieldPickerContextDTO>();

  useEffect(() => {
    if (!isEqual(prevFieldPickerContext.current, fieldPickerContext)) {
      prevFieldPickerContext.current = fieldPickerContext;
      const { from, to } = getLatestTimeRange();
      getFields(fieldPickerContext, from.valueOf(), to.valueOf(), false, demoDataParams);
    }
  }, [demoDataParams, fieldPickerContext, getFields, getLatestTimeRange]);

  const [fieldPickerModel, setFieldPickerModel] = useState(data);
  useEffect(() => {
    if (!isFieldPickerError && !isFieldsFetching) {
      setFieldPickerModel(data);
    }
  }, [data, isFieldPickerError, isFieldsFetching]);

  useEffect(() => {
    if (!isFieldPickerError) {
      logger.error("Field Selector", "Error fetching fields", fieldPickerError);
    }
  }, [fieldPickerError, isFieldPickerError]);

  const isEventIDFieldSelection =
    USFieldWidgetUtils.isEventIDField(userServiceField?.fieldName) && aggregator === "count";
  const { isFailedEventsSelection, isSuccessEventsSelection } = useMemo(() => {
    let isSuccessEventsSelection = false;
    let isFailedEventsSelection = false;

    if (isEventIDFieldSelection) {
      const hasErrorFilter = filterExpressions.find(f => USFieldWidgetUtils.isHasErrorField(f.field.fieldName));

      if (hasErrorFilter?.value === "true") {
        isFailedEventsSelection = true;
      } else if (hasErrorFilter?.value === "false") {
        isSuccessEventsSelection = true;
      }
    }

    return {
      isSuccessEventsSelection,
      isFailedEventsSelection
    };
  }, [filterExpressions, isEventIDFieldSelection]);

  const { fieldOrMetricOptions, hasErrorField, eventIdField } = useMemo(() => {
    if (isFieldsFetching || !fieldPickerModel) {
      const hasErrorFieldExists = isSuccessEventsSelection || isFailedEventsSelection;
      return {
        fieldOrMetricOptions: getFieldSelectionOpts(
          eventTypeName,
          fieldLabel,
          true,
          hasErrorFieldExists,
          addRateMetricOptions
        ),
        eventIdField: FieldPickerUtils.getMockEventIDField(),
        dummyHasErrorField: hasErrorFieldExists ? FieldPickerUtils.getMockHasErrorField() : null
      };
    }

    const usFields = fieldPickerModel?.getAllUserServiceFields() || [];
    const hasErrorField = usFields.find(f =>
      FieldPickerUtils.isHasErrorField(f.userServiceField.fieldName)
    )?.userServiceField;
    const eventIdField = usFields.find(f =>
      FieldPickerUtils.isEventIDField(f.userServiceField.fieldName)
    )?.userServiceField;

    return {
      fieldOrMetricOptions: getFieldSelectionOpts(
        eventTypeName,
        fieldLabel,
        Boolean(eventIdField),
        Boolean(hasErrorField),
        addRateMetricOptions
      ),
      eventIdField,
      hasErrorField
    };
  }, [
    addRateMetricOptions,
    eventTypeName,
    fieldLabel,
    fieldPickerModel,
    isFailedEventsSelection,
    isFieldsFetching,
    isSuccessEventsSelection
  ]);

  const selFieldOpt = isSuccessEventsSelection
    ? fieldOrMetricOptions[1]
    : isFailedEventsSelection
      ? fieldOrMetricOptions[2]
      : isEventIDFieldSelection
        ? fieldOrMetricOptions[0]
        : last(fieldOrMetricOptions);

  const selOptValue = selFieldOpt?.value;
  const onFieldOptChange = useCallback(
    (opt: IncSelectOption) => {
      const { value, label: metricName } = opt;

      if (value !== selOptValue) {
        const nonErrorFilterExpressions = (filterExpressions || []).filter(
          expr => !USFieldWidgetUtils.isHasErrorField(expr.field.fieldName)
        );
        if (value === EVENT_ID_OPT_VALUE) {
          const eventIDField = fieldPickerModel
            .getAllUserServiceFields()
            .find(usField => USFieldWidgetUtils.isEventIDField(usField.userServiceField.fieldName));

          if (eventIDField) {
            onChange(
              {
                userServiceField: eventIDField.userServiceField,
                aggregator: "count",
                filterExpressions: nonErrorFilterExpressions
              },
              metricName
            );
          }
        } else if (value === FAILED_EVENT_ID_OPT_VALUE) {
          const eventIDField = fieldPickerModel
            .getAllUserServiceFields()
            .find(usField => USFieldWidgetUtils.isEventIDField(usField.userServiceField.fieldName));
          const hasErrorField = fieldPickerModel
            .getAllUserServiceFields()
            .find(usField => USFieldWidgetUtils.isHasErrorField(usField.userServiceField.fieldName));

          if (eventIDField && hasErrorField) {
            onChange(
              {
                userServiceField: eventIDField.userServiceField,
                aggregator: "count",
                filterExpressions: [
                  ...nonErrorFilterExpressions,
                  {
                    field: hasErrorField.userServiceField,
                    operator: "=",
                    value: "true"
                  }
                ]
              },
              metricName
            );
          }
        } else if (value === SUCCESS_EVENT_ID_OPT_VALUE) {
          const eventIDField = fieldPickerModel
            .getAllUserServiceFields()
            .find(usField => USFieldWidgetUtils.isEventIDField(usField.userServiceField.fieldName));
          const hasErrorField = fieldPickerModel
            .getAllUserServiceFields()
            .find(usField => USFieldWidgetUtils.isHasErrorField(usField.userServiceField.fieldName));

          if (eventIDField && hasErrorField) {
            onChange(
              {
                userServiceField: eventIDField.userServiceField,
                aggregator: "count",
                filterExpressions: [
                  ...nonErrorFilterExpressions,
                  {
                    field: hasErrorField.userServiceField,
                    operator: "=",
                    value: "false"
                  }
                ]
              },
              metricName
            );
          }
        } else if (value === SUCCESS_EVENT_ID_RATE_OPT_VALUE) {
          onRateMetricSelect && onRateMetricSelect(true, eventIdField, hasErrorField, metricName);
        } else if (value === FAILED_EVENT_ID_RATE_OPT_VALUE) {
          onRateMetricSelect && onRateMetricSelect(false, eventIdField, hasErrorField, metricName);
        } else {
          onChange({
            aggregator: "sum",
            userServiceField: null,
            filterExpressions
          });
        }
      }
    },
    [eventIdField, fieldPickerModel, filterExpressions, hasErrorField, onChange, onRateMetricSelect, selOptValue]
  );

  const { entityId } = fieldPickerContext;

  const duration = useMemo<IncSelectorDuration>(() => (lookBack ? getDurationFromTimeObj(lookBack) : null), [lookBack]);

  const onDurationChange = useCallback(
    (nDuration: IncSelectorDuration) => {
      const { duration, durationType } = nDuration;
      const numDuration = parseInt(duration === "" ? "1" : duration.toString(), 10);
      const nTimeObj = getTimeObjFromDuration(numDuration, durationType);
      onChange({
        lookBack: nTimeObj
      });
    },
    [onChange]
  );

  const onFieldChange = useCallback(
    (options: FieldPickerOptionData[]) => {
      const nUserserviceField = options?.[0]?.payload as UserServiceField;
      if (nUserserviceField) {
        let payload: Partial<UserServiceFieldMetricConfig> = {
          userServiceField: nUserserviceField
        };

        if (entityId) {
          payload = {
            userServiceField: {
              ...nUserserviceField,
              userServices: [
                {
                  userServiceEntityId: entityId
                }
              ]
            }
          };
        }

        const { aggrType = "avg", fraction } = getDefaultDataTypeAggregation(nUserserviceField.dataType);
        const aggregator = getAggregationUIOption(aggrType, fraction)?.value;
        if (aggregator) {
          payload.aggregator = aggregator;
        }

        onChange(payload);
      }
    },
    [entityId, onChange]
  );

  const onAggregateChange = useCallback(
    (opt: TraceUIAggregation) => {
      const aggregator = getAggregationUIOption(opt.aggrType, opt.fraction)?.value;
      if (aggregator) {
        onChange({
          aggregator
        });
      }
    },
    [onChange]
  );

  const selectedUSFieldOptions = useMemo<FieldPickerOptionData[]>(
    () =>
      userServiceField?.fieldName
        ? [
            {
              payload: userServiceField,
              type: "userServiceField"
            }
          ]
        : [],
    [userServiceField]
  );

  const selectedAgg = useMemo(() => getTraceUIAggregation(aggregator), [aggregator]);

  const isFieldSelection = selFieldOpt?.value === FIELD_OPT_VALUE;

  return (
    <div
      className="event-field-agg-selector"
      data-field-selection={isFieldSelection}
    >
      <IncSelect
        autoSort={false}
        className="event-field-agg-selector--field-selection-dd"
        isDisabled={isFieldsFetching}
        isLoading={isFieldsFetching}
        isSearchable={false}
        onChange={onFieldOptChange}
        options={fieldOrMetricOptions}
        readOnly={readOnly}
        value={selFieldOpt}
      />

      {isFieldSelection && (
        <>
          <FieldPickerContainer
            demoDataParams={demoDataParams}
            disabled={isFieldsFetching}
            fieldPickerContextDto={fieldPickerContext}
            fieldPickerModel={fieldPickerModel}
            fieldTypes={["userServiceField"]}
            getLatestTimeRange={getLatestTimeRange}
            isMulti={false}
            label=""
            minimal
            onChange={onFieldChange}
            onDataFetch={setFieldPickerModel}
            readOnly={readOnly}
            selectedOptions={selectedUSFieldOptions}
          />

          <AggregationSelect
            hideLabel
            isDisabled={isFieldsFetching}
            onChange={onAggregateChange}
            readOnly={readOnly}
            value={selectedAgg}
          />
        </>
      )}

      {!hideLookBack && Boolean(duration) && (
        <>
          <div className="inc-text-subtext-medium marginLt8 marginRt8">over last</div>

          <IncDurationSelector
            duration={duration}
            hideLabel
            onChange={onDurationChange}
            readOnly={readOnly}
          />
        </>
      )}
    </div>
  );
});

const FIELD_OPT_VALUE = "__field__";
const EVENT_ID_OPT_VALUE = "__eventId__";
const FAILED_EVENT_ID_OPT_VALUE = "__failed_eventId__";
const SUCCESS_EVENT_ID_OPT_VALUE = "__success_eventId__";
const FAILED_EVENT_ID_RATE_OPT_VALUE = "__failed_eventId_rate__";
const SUCCESS_EVENT_ID_RATE_OPT_VALUE = "__success_eventId_rate__";

const getFieldSelectionOpts = (
  eventTypeName: string,
  fieldLabel: string,
  eventIDFieldExists: boolean,
  hasErrorFieldExists: boolean,
  addRateMetricOptions: boolean
) => {
  const options: IncSelectOption[] = [];

  if (eventIDFieldExists) {
    options.push({
      label: `Total ${pluralizeWord(eventTypeName)}`,
      value: EVENT_ID_OPT_VALUE
    });
  }

  if (eventIDFieldExists && hasErrorFieldExists) {
    options.push(
      ...[
        {
          label: `Successful ${pluralizeWord(eventTypeName)}`,
          value: SUCCESS_EVENT_ID_OPT_VALUE
        },
        {
          label: `Failed ${pluralizeWord(eventTypeName)}`,
          value: FAILED_EVENT_ID_OPT_VALUE
        }
      ]
    );

    if (addRateMetricOptions) {
      options.push(
        ...[
          {
            label: `Success Rate`,
            value: SUCCESS_EVENT_ID_RATE_OPT_VALUE
          },
          {
            label: `Failure Rate`,
            value: FAILED_EVENT_ID_RATE_OPT_VALUE
          }
        ]
      );
    }
  }

  options.push({
    label: fieldLabel || "Field is",
    value: FIELD_OPT_VALUE
  });

  return options;
};
