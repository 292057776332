import { defaultsDeep } from "lodash";
import { generateId } from "@inception/ui";
import { RegionHighlight } from "../../../../components/charts/plot-bands/RegionHighlights";
import BaseWidgetModel from "../../../models/BaseWidgetModel";
import BaseWidgetImpl from "../../../model-impl/BaseWidgetImpl";
import { TimeSeriesChartType } from "../../../../components/time-series/types";
import TimeSeriesWidgetModel, { SeriesOverride, TimeSeriesProperties } from "./model";

const getDefaults = (): Partial<TimeSeriesWidgetModel> => ({
  title: "New timeseries",
  chartType: "spline",
  options: {
    showSeriesDropdown: true,
    hideActionsOnDragAndSelect: true
  },
  highlights: [],
  seriesOverrides: [],
  properties: {
    chartType: "line",
    legend: {
      show: true,
      position: "bottom"
    },
    yAxis: {
      axisProps: [
        {
          unit: "",
          label: "",
          max: "",
          min: "",
          unitBeforeValue: false
        }
      ]
    },
    tooltip: {
      shared: false,
      sortBy: "none"
    },
    highlights: [],
    comparisonSeries: {
      enabled: false,
      threshold: 1
    }
  }
});
export default class TimeSeriesWidgetImpl extends BaseWidgetImpl implements TimeSeriesWidgetModel {
  chartType: TimeSeriesChartType;
  highlights: RegionHighlight[];
  seriesOverrides: SeriesOverride[];
  properties: TimeSeriesProperties;
  options: Record<string, any>;

  constructor(model: Partial<TimeSeriesWidgetModel>, meta?: Record<string, any>) {
    super(model, meta);
    this.assign(model);
  }

  assign(model: Partial<TimeSeriesWidgetModel>) {
    const defaultOptions = getDefaults();
    const tsModel: any = defaultsDeep({ type: "timeseries" }, model, defaultOptions);
    super.assign(tsModel);

    const { chartType, highlights, seriesOverrides, properties, options = {} } = tsModel;

    this.chartType = chartType;
    this.properties = properties;
    this.properties.highlights = this.properties.highlights.concat(highlights);
    this.properties?.highlights.forEach((highlight: RegionHighlight) => {
      highlight.id = highlight.id || generateId();
    });
    this.seriesOverrides = seriesOverrides;
    this.options = { ...options };
  }

  getSaveModel(): BaseWidgetModel {
    const model = super.getSaveModel();

    // Doing this since the min, max options are relative to global time range and keep changing
    delete model?.options?.xAxis;
    return model;
  }
}
