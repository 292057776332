import { UserServiceFieldWithMeta, WidgetResponseDTO } from "../../services/api/explore";

export interface USFieldInfoWithId {
  id: string;
  displayName: string;
  isPredefinedField: boolean;
  isMetricField: boolean;
  usField: UserServiceFieldWithMeta;
}

export interface USMetricInfoWithId {
  metricId: string;
  childMetricIds?: string[];
  metricName: string;
  widgetResponse: WidgetResponseDTO;
}

export enum FieldOrMetricTypes {
  BUSINESS_FIELDS = "businessFields",
  PERFORMANCE_FIELDS = "perfFields",
  ENTITY_FIELDS = "entityFields",
  JOURNEY_FIELDS = "journeyFields",
  USAGE_METRICS = "usageMetrics",
  METRICS = "metrics"
}
