import { isEmpty } from "lodash";
import { featureFlagService, FEATURE_FLAGS } from "../../../feature-flags";
import { BizService, BizEntityApiResult } from "../../explore/BizServiceCommon";
import {
  ConversationResponse,
  Conversation,
  AllConversationsList,
  ConversationRequest,
  ChatBotStrategy,
  ConversationContext,
  ChatRequest,
  ParameterValue,
  Plan,
  FeedbackInput,
  ActionContext,
  PlanActionContext
} from "../types";

class ChatApi extends BizService {
  internalIdSuffix = "i_biz_catalog";

  sendMessage(
    chatId: string,
    message: string,
    strategy: ChatBotStrategy,
    conversationContext = ConversationContext.UNDEFINED,
    request: ChatRequest = {},
    useCaseId?: string,
    companyName?: string,
    removeMsgFromQueryParams?: boolean
  ) {
    return this.sendMessageInternal(
      chatId,
      message,
      strategy,
      false,
      conversationContext,
      request,
      useCaseId,
      companyName,
      removeMsgFromQueryParams
    );
  }

  getChatStatus(
    chatId: string,
    message: string,
    strategy: ChatBotStrategy,
    conversationContext = ConversationContext.UNDEFINED,
    request: ChatRequest = {},
    useCaseId?: string,
    companyName?: string,
    removeMsgFromQueryParams?: boolean
  ) {
    return this.sendMessageInternal(
      chatId,
      message,
      strategy,
      true,
      conversationContext,
      request,
      useCaseId,
      companyName,
      removeMsgFromQueryParams
    );
  }

  private async sendMessageInternal(
    chatId: string,
    message: string,
    strategy: ChatBotStrategy,
    isStatusCall: boolean,
    conversationContext = ConversationContext.UNDEFINED,
    request: ChatRequest = {},
    useCaseId?: string,
    companyName?: string,
    removeMsgFromQueryParams = false
  ): Promise<BizEntityApiResult<ConversationResponse>> {
    if (isEmpty(request)) {
      request = {};
      request.chatId = chatId;
      request.message = message;
      request.conversationContext = conversationContext;
    }

    this.init();
    const subUrl = isStatusCall ? "chat/continue/status" : "chat/continue";
    const url = this.getChatURL(subUrl);

    const result: BizEntityApiResult<ConversationResponse> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const queryParam = removeMsgFromQueryParams
        ? {
            chatId,
            strategy,
            conversationContext,
            useCaseId,
            companyName
          }
        : {
            chatId,
            message,
            strategy,
            conversationContext,
            useCaseId,
            companyName
          };
      const { data, status, statusText } = await this.datasource.post<ConversationResponse, any>(url, request, {
        params: queryParam
      });

      const isErr = status > 299;
      result.error = isErr;
      result.message = isErr ? statusText : "";
      result.data = data;
    } catch (err) {
      this.handleError(err, result);
    }

    return result;
  }

  async sendQuery(chatId: string, query: string): Promise<BizEntityApiResult<ConversationResponse>> {
    this.init();
    const url = this.getChatURL("chat/query");

    const result: BizEntityApiResult<ConversationResponse> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const { data, status, statusText } = await this.datasource.post<ConversationResponse, any>(
        url,
        {},
        {
          params: {
            chatId,
            query
          }
        }
      );

      const isErr = status > 299;
      result.error = isErr;
      result.message = isErr ? statusText : "";
      result.data = data;
    } catch (err) {
      this.handleError(err, result);
    }

    return result;
  }

  async getConversations(
    chatId: string,
    userId: string,
    useCaseId?: string,
    page = 1,
    limit = 15
  ): Promise<BizEntityApiResult<Conversation>> {
    this.init();
    const url = this.getChatURL("conversation/search");

    const result: BizEntityApiResult<Conversation> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const { data, status, statusText } = await this.datasource.post<Conversation, ConversationRequest>(url, {
        chatId,
        userId,
        useCaseId,
        limit,
        page
      });

      const isErr = status > 299;
      result.error = isErr;
      result.message = isErr ? statusText : "";
      result.data =
        data ||
        ({
          chatId,
          conversationEntries: [],
          nextPage: -1,
          strategy: ChatBotStrategy.DEFAULT,
          page,
          userId
        } as Conversation);
    } catch (err) {
      this.handleError(err, result);
    }

    return result;
  }

  async getAllConversations(
    includeInternalChats = false,
    useCaseId = "",
    limit?: number
  ): Promise<BizEntityApiResult<AllConversationsList>> {
    this.init();
    const url = this.getChatURL("conversation");

    const result: BizEntityApiResult<AllConversationsList> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const { data, status, statusText } = await this.datasource.get<AllConversationsList, ConversationRequest>(url, {
        useCaseId,
        limit
      });

      const isErr = status > 299;
      result.error = isErr;
      result.message = isErr ? statusText : "";

      const convData = data || {
        conversations: []
      };

      if (!includeInternalChats) {
        convData.conversations = (convData.conversations || []).filter(
          ({ chatId }) => !chatId.endsWith(this.internalIdSuffix)
        );
      }

      result.data = convData;
    } catch (err) {
      this.handleError(err, result);
    }

    return result;
  }

  async implementPlan(
    chatId: string,
    planId: string,
    useCaseId: string,
    companyName: string,
    paramValues: ParameterValue[]
  ): Promise<BizEntityApiResult<ConversationResponse>> {
    this.init();
    const subUrl = `chat/run_plan?chatId=${chatId}&useCaseId=${useCaseId}${companyName ? `&companyName=${companyName}` : ""}`;
    const url = this.getBizEntityUrl(subUrl);
    const payload = {
      planId,
      suggestedValues: paramValues
    };

    const result: BizEntityApiResult<ConversationResponse> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.post<ConversationResponse, typeof payload>(url, payload);
      if (response.data) {
        result.data = response.data;
      } else {
        result.error = true;
      }
    } catch (e) {
      result.error = true;
      result.message = (e as Error).message;
    }

    return result;
  }

  async savePlan(plan: Plan, useCaseId?: string): Promise<BizEntityApiResult<string>> {
    this.init();
    const subUrl = `saveCustomPlan`;
    const url = this.getBizEntityUrl(subUrl);

    const result: BizEntityApiResult<string> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.post<string, Plan>(
        url,
        { ...plan },
        {
          params: {
            useCaseId
          }
        }
      );

      if (response.data) {
        result.data = response.data;
      } else {
        result.error = true;
      }
    } catch (e) {
      result.error = true;
      result.message = (e as Error).message;
    }

    return result;
  }

  async editPlan(
    chatId: string,
    useCaseId: string,
    companyName: string,
    payload: PlanActionContext
  ): Promise<BizEntityApiResult<ConversationResponse>> {
    this.init();
    const subUrl = `chat/edit_plan`;
    const url = this.getBizEntityUrl(subUrl);

    const result: BizEntityApiResult<ConversationResponse> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.post<ConversationResponse, PlanActionContext>(url, payload, {
        params: {
          useCaseId,
          chatId,
          companyName
        }
      });

      if (response.data) {
        result.data = response.data;
      } else {
        result.error = true;
      }
    } catch (e) {
      result.error = true;
      result.message = (e as Error).message;
    }

    return result;
  }

  async getPlan(planId: string): Promise<BizEntityApiResult<Plan>> {
    this.init();
    const subUrl = `command/${planId}`;
    const url = this.getBizEntityUrl(subUrl);

    const result: BizEntityApiResult<Plan> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.get<Plan, never>(url);

      if (response.data) {
        result.data = response.data;
      } else {
        result.error = true;
      }
    } catch (e) {
      result.error = true;
      result.message = (e as Error).message;
    }

    return result;
  }

  async postChatFeedback(
    chatId: string,
    useCaseId: string,
    messageId: string,
    feedbackInput: FeedbackInput
  ): Promise<BizEntityApiResult<string>> {
    this.init();
    const subUrl = `chat/feedback`;
    const url = this.getBizEntityUrl(subUrl);

    const result: BizEntityApiResult<string> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.post<string, FeedbackInput>(url, feedbackInput, {
        params: {
          chatId,
          useCaseId,
          messageId
        }
      });

      if (response.data) {
        result.data = "Feedback submitted";
      } else {
        result.error = true;
      }
    } catch (e) {
      result.error = true;
      result.message = (e as Error).message;
    }
    return result;
  }

  async runChatAction(
    chatId: string,
    actionContext: ActionContext,
    useCaseId: string,
    companyName: string
  ): Promise<BizEntityApiResult<string>> {
    this.init();
    const subUrl = `chat/action`;
    const url = this.getBizEntityUrl(subUrl);

    const result: BizEntityApiResult<string> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.post<string, ActionContext>(url, actionContext, {
        params: {
          chatId: chatId || "",
          useCaseId: useCaseId || "",
          companyName: companyName || ""
        }
      });

      if (response.data) {
        result.data = response.data;
      } else {
        result.error = true;
      }
    } catch (e) {
      result.error = true;
      result.message = (e as Error).message;
    }
    return result;
  }

  private getChatURL(subUrl: string): string {
    const url = this.getBizEntityUrl(subUrl);
    const useV1Api = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.useV1ApiForChat);
    return useV1Api ? url : `${url}?useV3=false`;
  }
}

export const chatApi = new ChatApi();
