import { Options, XAxisPlotBandsOptions, XAxisOptions } from "highcharts";
import { isArray, isEmpty, orderBy } from "lodash";

export interface RegionHighlight extends XAxisPlotBandsOptions {}

const addRegionHighlights = (chartOptions: Options, bands: RegionHighlight[]) => {
  if (chartOptions) {
    const xAxisOptions: XAxisOptions = isArray(chartOptions.xAxis) ? chartOptions.xAxis[0] : chartOptions.xAxis;
    if (xAxisOptions && !isEmpty(bands)) {
      if (bands.length < 2) {
        xAxisOptions.plotBands = bands;
      } else {
        const result = [];
        orderBy(bands, ["from"]);
        let previous = bands[0];
        for (let i = 1; i < bands.length; i++) {
          if (previous.to >= bands[i].from) {
            previous.to = Math.max(previous.to, bands[i].to);
          } else {
            result.push(previous);
            previous = bands[i];
          }
        }
        result.push(previous);
        xAxisOptions.plotBands = result;
      }
    }
  }
};

export default addRegionHighlights;
