import React, { FC, useMemo } from "react";
import { cloneDeep, size } from "lodash";
import { AxisLabelsFormatterContextObject } from "highcharts";
import { formatNumber } from "@inception/ui";
import { BarColumnChart } from "../../../../../components/charts/common";
import BarColumnChartOptionsBuilder from "../../../../../components/bar-chart/BarColumnChartOptionsBuilder";
import { IncVBarSeriesOptions } from "../../../../../components/bar-chart/types";
import { EntityWidgetData } from "../../../../../biz-entity";
import { DataFrame, DataType } from "../../../../../core";
import { BarsOrder, NegationColors } from "../../models";
import {
  CatalogVizRendererProps,
  getSerieName,
  getCompareFieldsFromLabels,
  getMetricIdFromDataFrameId,
  getChartColorForSerie,
  useCommonRendererFunctionality,
  getFormattedValue
} from "./common";

type Props = CatalogVizRendererProps & {
  showAllDataPoints?: boolean;
};

export const BarChartRenderer: FC<Props> = props => {
  const {
    className,
    dataType: defDataType,
    loadingElement,
    noDataElement,
    aggregatedTags,
    displayAggregatedTags,
    showAllDataPoints = false,
    properties,
    aggregator,
    currencyType,
    dataTypeByMetricId
  } = props;

  const { bar, useNegationColors, negationColors } = properties;
  const { stacked = false, barsOrder } = bar || {};

  const { data, fieldName, isFetching, isError, dataExists, metricName, childMetricNames, hasChildMetrics } =
    useCommonRendererFunctionality(props);

  const aggregatorLabel = aggregator?.label;
  const { tooltipValueFormatter, xAxisLabelFormatter, yAxisLabelFormatter } = useMemo(() => {
    const labelFormatterFn = (value: string | number, dataType = defDataType): string =>
      getFormattedValue(fieldName, value, dataType, currencyType);
    const labelFormatter = skipFormatterAggs.includes(aggregatorLabel) ? null : labelFormatterFn;

    const tooltipValueFormatter = function (pointValue: string | number, custom: Record<string, unknown>) {
      if (labelFormatter) {
        const { labelInfo, pointIndex } = custom || {};
        const dataType = ((labelInfo || []) as any)[pointIndex as number]?.dataType as DataType;
        return labelFormatter(pointValue, dataType);
      }

      return formatNumber(pointValue as number);
    };

    const xyAxisLabelFormatter = (tick: AxisLabelsFormatterContextObject) => {
      if (labelFormatter) {
        return labelFormatter(tick.value);
      }

      return formatNumber(tick.value as number);
    };

    return {
      tooltipValueFormatter,
      xAxisLabelFormatter: xyAxisLabelFormatter,
      yAxisLabelFormatter: xyAxisLabelFormatter
    };
  }, [aggregatorLabel, currencyType, defDataType, fieldName]);

  const aggregationsExist = Boolean(aggregatedTags?.length);

  const optionsBuilder = useMemo(() => {
    // We need this only when aggregations exist, since there can be sub bars
    const numMetrics = aggregationsExist ? 1 + (hasChildMetrics ? size(childMetricNames) : 0) : 1;
    const barDimension = Math.max(Math.floor(40 / numMetrics), 15);
    return new BarColumnChartOptionsBuilder({ barDimension }).setLayout("vertical");
  }, [aggregationsExist, childMetricNames, hasChildMetrics]);

  const series = useMemo(
    () =>
      constructBarSeries(
        data,
        aggregatedTags,
        displayAggregatedTags,
        defDataType,
        dataTypeByMetricId,
        fieldName,
        metricName,
        childMetricNames,
        hasChildMetrics,
        showAllDataPoints,
        useNegationColors,
        negationColors,
        barsOrder
      ),
    [
      data,
      aggregatedTags,
      displayAggregatedTags,
      defDataType,
      dataTypeByMetricId,
      fieldName,
      metricName,
      childMetricNames,
      hasChildMetrics,
      showAllDataPoints,
      useNegationColors,
      negationColors,
      barsOrder
    ]
  );

  if (isFetching) {
    return loadingElement;
  }

  if (!dataExists || isError) {
    return noDataElement;
  }

  const showAxisNameInTooltip = aggregationsExist ? hasChildMetrics : false;

  return (
    <BarColumnChart
      containerElemClass={className}
      optionsBuilder={optionsBuilder}
      series={series}
      showAxisNameInTooltip={showAxisNameInTooltip}
      showLegend={false}
      stacked={stacked}
      title=""
      tooltipValueFormatter={tooltipValueFormatter}
      xAxisLabelFormatter={xAxisLabelFormatter}
      yAxisLabelFormatter={yAxisLabelFormatter}
    />
  );
};

const constructBarSeries = (
  data: EntityWidgetData[],
  aggregatedTags: string[],
  displayAggregatedTags: string[],
  defaultDataType: CatalogVizRendererProps["dataType"],
  dataTypeByMetricId: Record<string, DataType>,
  fieldName: string,
  metricName: string,
  childMetricNames: Record<string, string>,
  childMetricsExist: boolean,
  showAllDataPoints: boolean,
  useNegationColors: boolean,
  negationColors: NegationColors,
  barsOrder: BarsOrder[]
) => {
  const aggregationExists = aggregatedTags?.length > 0;

  let parentMetricId: string;
  const dataframes: DataFrame[] = [];
  const compareDataFrames: DataFrame[] = [];

  const childrenDataFrames: Record<string, DataFrame[]> = {};
  const childrenCompareDataFrames: Record<string, DataFrame[]> = {};

  data.forEach(datum => {
    const postAggData = datum.postAggResult.data;
    const compareData = datum.postAggResult.timeShiftData || {};

    const refId = Object.keys(postAggData)[0] || Object.keys(compareData)[0] || "";

    const dfs = postAggData[refId]?.data || [];
    const cDfs = compareData[refId]?.data || [];

    const metricId = getMetricIdFromDataFrameId(refId);
    if (childMetricNames[metricId]) {
      childrenDataFrames[metricId] = dfs;
      childrenCompareDataFrames[metricId] = cDfs;
    } else {
      parentMetricId = metricId;
      dataframes.push(...dfs);
      compareDataFrames.push(...cDfs);
    }
  });

  const isAggregatedSeries = dataframes.length === 1 && !aggregationExists;

  const invertStatusColors = !childMetricsExist && fieldName.toLowerCase().includes("error");
  useNegationColors = useNegationColors && dataframes.length <= 2;

  // Construct parent data from dataframes
  const parentDataArr: Datum[] = [];
  dataframes.forEach(dataFrame => {
    const { labels, eLabels } = dataFrame;

    const { aggLabel, compareData, data } = getDataAndCompareDataForDataFrame(
      dataFrame,
      compareDataFrames,
      isAggregatedSeries,
      aggregatedTags,
      displayAggregatedTags,
      metricName
    );

    parentDataArr.push({
      compareData,
      data,
      eLabels,
      labels,
      aggLabel
    });
  });
  const sortedParentData = parentDataArr.sort((a, b) => b.data - a.data);

  const aggLabels: string[] = [];
  const parentBarOption = getBarOption(metricName, aggregatedTags, displayAggregatedTags);

  sortedParentData.forEach((datum, idx) => {
    const { aggLabel, compareData, data, eLabels, labels } = datum;

    const colorIdx = childMetricsExist ? 0 : idx;
    const dataType = dataTypeByMetricId[parentMetricId] || defaultDataType;
    const defaultColor = getChartColorForSerie(dataType, aggLabel, colorIdx, invertStatusColors);
    const color = useNegationColors ? negationColors?.[idx] || defaultColor : defaultColor;

    aggLabels.push(aggLabel);
    if (showAllDataPoints) {
      const dataFrame = dataframes?.[idx];
      const values = dataFrame?.fields?.[1]?.data;
      values?.forEach(val => {
        if (val) {
          parentBarOption.data.push([aggLabel, val]);
        }
      });
    } else {
      parentBarOption.data.push([aggLabel, data]);
    }
    parentBarOption.colors.push(color);
    parentBarOption.custom.labelInfo.push({
      eLabels,
      labels,
      dataType
    });
    parentBarOption.custom.compareData.push(compareData);

    if (!aggregationExists) {
      parentBarOption.color = color;
    }
  });

  const barOptions: IncVBarSeriesOptions[] = [parentBarOption];

  if (childMetricsExist) {
    // metricId vs (aggLabel vs [data, compareData]) map
    const childrenDataMap: Record<string, Record<string, Datum>> = {};
    Object.keys(childrenDataFrames).forEach(childMetricId => {
      const dataframes = childrenDataFrames[childMetricId];
      const compareDataFrames = childrenCompareDataFrames[childMetricId];
      const childMetricName = childMetricNames[childMetricId] || metricName;

      dataframes.forEach(dataFrame => {
        const { aggLabel, compareData, data } = getDataAndCompareDataForDataFrame(
          dataFrame,
          compareDataFrames,
          isAggregatedSeries,
          aggregatedTags,
          displayAggregatedTags,
          childMetricName
        );

        const metricIdMap = childrenDataMap[childMetricId] || {};
        metricIdMap[aggLabel] = {
          aggLabel,
          compareData,
          data,
          eLabels: dataFrame.eLabels || dataFrame.labels || {},
          labels: dataFrame.labels || {}
        };
        childrenDataMap[childMetricId] = metricIdMap;
      });
    });

    Object.keys(childrenDataMap).forEach((childMetricId, idx) => {
      const dataType = dataTypeByMetricId[childMetricId] || defaultDataType;
      const metricName = childMetricNames[childMetricId];
      const childBarOption = getBarOption(metricName, aggregatedTags, displayAggregatedTags);
      const barOption = aggregationExists ? childBarOption : parentBarOption;

      const metricIdvsDataMap = childrenDataMap[childMetricId];
      const aggLabelsToUse = aggregationExists ? aggLabels : Object.keys(metricIdvsDataMap);
      aggLabelsToUse.forEach(aggLabel => {
        const color = getChartColorForSerie(dataType, aggLabel, idx + 1, false);

        const { compareData = 0, data = 0, eLabels = {}, labels = {} } = metricIdvsDataMap[aggLabel] || {};

        barOption.data.push([aggLabel, data]);
        barOption.colors.push(color);
        barOption.custom.labelInfo.push({
          eLabels,
          labels,
          dataType
        });
        barOption.custom.compareData.push(compareData);
      });

      if (aggregationExists) {
        barOptions.push(childBarOption);
      }
    });
  }

  if (!aggregatedTags.length && barsOrder?.length) {
    const clonedBarOptions = cloneDeep(barOptions);
    clonedBarOptions.forEach(chartOption => {
      const sortedData = barsOrder
        .map(bar => chartOption.data.find((data: any) => Array.isArray(data) && data?.[0] === bar.id))
        .filter(Boolean);

      const sortedColors = barsOrder
        .map(bar => {
          const index = chartOption.data.findIndex((data: any) => Array.isArray(data) && data?.[0] === bar.id);
          return chartOption.colors[index];
        })
        .filter(Boolean);
      chartOption.data = sortedData;
      chartOption.colors = sortedColors;
    });

    return clonedBarOptions;
  }
  return barOptions;
};

const getDataAndCompareDataForDataFrame = (
  dataFrame: DataFrame,
  compareDataFrames: DataFrame[],
  isAggregatedMetric: boolean,
  aggregatedTags: string[],
  displayAggregatedTags: string[],
  metricName: string
) => {
  const { fields, labels, eLabels, name } = dataFrame;

  const values = fields[1].data;
  const data = values[0];

  const cFields = getCompareFieldsFromLabels(compareDataFrames, labels);
  const compareData = cFields?.[1]?.data?.[0] || null;

  const { aggLabel } = isAggregatedMetric
    ? { aggLabel: name }
    : getSerieName(aggregatedTags, displayAggregatedTags, labels, eLabels, metricName);

  return {
    aggLabel,
    data,
    compareData
  };
};

type Datum = {
  aggLabel: string;
  eLabels: Record<string, string>;
  labels: Record<string, string>;
  data: number;
  compareData: number;
};

const getBarOption = (
  metricName: string,
  aggregateTags: string[],
  displayAggregateTags: string[]
): IncVBarSeriesOptions => ({
  type: "column",
  name: metricName,
  data: [],
  custom: {
    aggregateTags,
    displayAggregateTags,
    labelInfo: [],
    compareData: []
  },
  colors: []
});

const skipFormatterAggs = ["count", "distinctCount"];
