import React, { ReactElement } from "react";
import { ReactComponent as SalesforceIcon } from "../../../../images/salesforce.svg";
import { ReactComponent as ZuoraIcon } from "../../../../images/zuora.svg";
import { ReactComponent as CsvIcon } from "../../../../images/csv.svg";
import { ReactComponent as MarketoIcon } from "../../../../images/user-onboarding/marketo.svg";
import { ReactComponent as KafkaIcon } from "../../../../images/user-onboarding/kafka.svg";
import { CSV_CONFIG_TYPE, MARKETO_CONFIG_TYPE, SALESFORCE_CONFIG_TYPE, ZUORA_CONFIG_TYPE } from "../source-constants";

export const getSourceTypeName = (sourceTypeId: string, sourceTypeName: string): string => {
  if (!sourceTypeIdToName[sourceTypeId]) {
    return sourceTypeName;
  }

  return sourceTypeIdToName[sourceTypeId];
};

/**
 *
 * @param sourceTypeId Get the source type icon. If neither imgClassName or imgSize is specified, default size is 14
 * @param iconImg
 * @param imgClassName If this is specified, the size parameter is ignored
 * @param imgSize Specify the size in pixels.
 * @returns size
 */
export const getSourceTypeIcon = (
  sourceTypeId: string,
  iconImg: string,
  imgClassName?: string,
  imgSize?: number,
  noAlt?: boolean,
  iconShape?: string
): ReactElement => {
  const size = imgSize || 14;
  const defaultImgSizeStyle = {
    width: size,
    height: size
  };
  const icon = getIcon(sourceTypeId.toLowerCase(), defaultImgSizeStyle);
  if (!icon && !iconImg) {
    return <div title={sourceTypeId}>-</div>;
  }

  if (iconImg) {
    // Currently the backend API returns the icon in 3 different formats
    // 1a. which is just an svg file string with starting string as "<?xml version"
    // 1b: which is a svg file which starts with "<svg"
    // 2. base64 encoding of svg which has prefix of data:image/svg+xml;base64
    // 3. base64 encoding of svg which does NOT have prefix of data:image/svg+xml;base64

    let url = "";
    if (sourceTypeId.toLowerCase() !== "source-kafka") {
      if (iconImg.indexOf("<?xml version") !== -1 || iconImg.indexOf("<svg") !== -1) {
        const blob = new Blob([iconImg], { type: "image/svg+xml" });
        url = URL.createObjectURL(blob);
      } else {
        const base64HeaderExists = iconImg.indexOf("data:image/svg+xml;") !== -1;
        if (!base64HeaderExists) {
          url = `data:image/svg+xml;base64,${iconImg}`;
        } else {
          url = iconImg;
        }
      }
    } else {
      return <KafkaIcon />;
    }

    //if iconShape is rectangle , size of icon is increased
    if (iconShape === "rectangle") {
      return (
        <img
          alt={!noAlt ? sourceTypeId : ""}
          className={""}
          height={52}
          src={url}
          title={sourceTypeId}
          width={68}
        />
      );
    }

    // If we specify the className, use that and done use imgSize
    if (imgClassName && !size) {
      return (
        <img
          alt={!noAlt ? sourceTypeId : ""}
          className={imgClassName || ""}
          src={url}
          title={sourceTypeId}
        />
      );
    }

    return (
      <img
        alt={!noAlt ? sourceTypeId : ""}
        className={imgClassName || ""}
        height={size}
        src={url}
        title={sourceTypeId}
        width={size}
      />
    );
  }

  return icon;
};

const sourceTypeIdToName: Record<string, string> = {};
sourceTypeIdToName["salesforce"] = "Salesforce";
sourceTypeIdToName[SALESFORCE_CONFIG_TYPE] = "Salesforce";
sourceTypeIdToName["marketo"] = "Marketo";
sourceTypeIdToName[MARKETO_CONFIG_TYPE] = "Marketo";
sourceTypeIdToName["zuora"] = "Zuora";
sourceTypeIdToName[ZUORA_CONFIG_TYPE] = "Zuora";
sourceTypeIdToName["csv"] = "CSV";
sourceTypeIdToName[CSV_CONFIG_TYPE] = "CSV";

const getIcon = (type: string, sizeStyle: { width: number; height: number }) => {
  switch (type) {
    case "salesforce":
    case SALESFORCE_CONFIG_TYPE:
      return (
        <SalesforceIcon
          {...sizeStyle}
          title={sourceTypeIdToName[type]}
        />
      );

    case "marketo":
    case MARKETO_CONFIG_TYPE:
      return (
        <MarketoIcon
          {...sizeStyle}
          title={sourceTypeIdToName[type]}
        />
      );

    case "zuora":
    case ZUORA_CONFIG_TYPE:
      return (
        <ZuoraIcon
          {...sizeStyle}
          title={sourceTypeIdToName[type]}
        />
      );

    case "csv":
    case CSV_CONFIG_TYPE:
      return (
        <CsvIcon
          {...sizeStyle}
          title={sourceTypeIdToName[type]}
        />
      );

    default:
      return null;
  }
};
