import {
  IncDateTimeFormat,
  IncHighchartsDateTimeFormat,
  DateTimeOptions,
  getFormattedDateTime,
  IncSelectOption
} from "@inception/ui";
import { SeriesHeatmapOptions } from "highcharts";
import { duration } from "moment";
import { dateTime, TimeRange } from "../../../core";
import kbn from "../../../services/datasources/core/kbn";
import { AlertCountResponseEntry } from "../../../services/api/operationalise";
import { IncVBarSeriesOptions } from "../../../components/bar-chart/types";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { oneMonthOpt, timeIntervalOptions } from "./options";

export const HEATMAP_ENTRY_CUSTOM_ACCESSOR = "heatMapEntryData";
export const HEATMAP_AXIS_LABEL_CUSTOM_ACCESSOR = "heatMapYAxisLabel";
export const HEATMAP_DATA_SPACE_BUFFER = 2;

export const getHeatMapEntries = (
  countEntries: AlertCountResponseEntry[],
  timeIntervalMillis: number,
  startTimeMillis: number,
  endTimeMillis: number,
  numPaddingColumns: number
): SeriesHeatmapOptions[] => {
  const { groupInterval, xAxisFormat, xAxisFormatOptions, yAxisFormat, yAxisFormatOptions, yAxisTimeInterval } =
    getHeatMapAxesFormats(startTimeMillis, endTimeMillis, timeIntervalMillis);

  const seriesOptions: SeriesHeatmapOptions[] = [];

  const xAxisTimeInterval = Math.min(yAxisTimeInterval, timeIntervalMillis);

  const bucketSize = Math.floor(yAxisTimeInterval / xAxisTimeInterval);

  const groupFactor = Math.floor(groupInterval / yAxisTimeInterval);
  const groupSize = groupFactor * bucketSize;

  let iterStartTimeMillis = startTimeMillis;
  const numGroups = Math.floor(countEntries.length / groupSize);

  let entryIdx = 0;

  for (let groupIdx = 0; groupIdx < numGroups; groupIdx++) {
    const maxX = groupIdx === numGroups - 1 ? bucketSize + numPaddingColumns : bucketSize + numPaddingColumns;
    const colsize = 1 / maxX;

    const start = iterStartTimeMillis;
    const end = Math.min(iterStartTimeMillis + groupInterval, endTimeMillis);

    const formattedStart = getFormattedDateTime(start, xAxisFormat, xAxisFormatOptions);
    const formattedEnd = getFormattedDateTime(end, xAxisFormat, xAxisFormatOptions);

    const isDailyInterval = groupInterval === duration(1, "d").asMilliseconds();
    const startAndEndMatch = formattedStart === formattedEnd;
    const serieName = startAndEndMatch || isDailyInterval ? formattedStart : `${formattedStart} - ${formattedEnd}`;

    const serieOptions: SeriesHeatmapOptions = {
      name: serieName,
      type: "heatmap",
      data: [],
      colsize
    };

    for (let yIdx = 0; yIdx < groupFactor; yIdx++) {
      for (let xIdx = 0; xIdx < maxX; xIdx++) {
        // Actual Series
        if (xIdx < bucketSize) {
          const entry = countEntries[entryIdx];
          entryIdx += 1;

          if (entry) {
            const { count: countStr } = entry;

            const start = iterStartTimeMillis + yIdx * yAxisTimeInterval + xIdx * xAxisTimeInterval;
            const end = Math.min(start + xAxisTimeInterval, endTimeMillis);

            const formattedStart = getFormattedDateTime(start, yAxisFormat);
            const formattedEnd = getFormattedDateTime(end, yAxisFormat);

            const pointName = formattedStart === formattedEnd ? formattedStart : `${formattedStart} - ${formattedEnd}`;

            const x = groupIdx + xIdx * colsize;
            const y = yIdx;

            const count = parseInt(countStr.toString(), 10);

            serieOptions.data.push({
              color: getTileColorByCount(count),
              x,
              y,
              value: count,
              name: pointName,
              custom: {
                [HEATMAP_ENTRY_CUSTOM_ACCESSOR]: entry,
                [HEATMAP_AXIS_LABEL_CUSTOM_ACCESSOR]: getFormattedDateTime(start, yAxisFormat, yAxisFormatOptions)
              }
            });
          }
        } else {
          // Buffer series to show a gap between groups
          const x = groupIdx + xIdx * colsize;
          const y = yIdx;

          serieOptions.data.push({
            color: "transparent",
            x,
            y,
            value: 0,
            name: "",
            selected: false,
            className: "disableClick"
          });
        }
      }
    }

    iterStartTimeMillis += groupInterval;
    seriesOptions.push(serieOptions);
  }

  return seriesOptions;
};

export const getBarChartSeries = (
  countEntries: AlertCountResponseEntry[],
  timeIntervalMillis: number,
  startTimeMillis: number
): IncVBarSeriesOptions[] => {
  let iterStartTimeMillis = startTimeMillis;
  const seriesOptions: IncVBarSeriesOptions[] = [
    {
      type: "column",
      data: []
    }
  ];

  countEntries.forEach(entry => {
    const { count: countStr } = entry;
    const count = parseInt(countStr.toString(), 10);
    const color = getTileColorByCount(count);

    seriesOptions[0].data.push({
      color,
      y: count,
      x: timeRangeUtils.getSecondsFromMillis(iterStartTimeMillis),
      className: "series-point-top-round-4px"
    });

    iterStartTimeMillis += timeIntervalMillis;
  });

  return seriesOptions;
};

export const getDefaultTimeIntervalOpt = (timeRange: TimeRange): IncSelectOption<number> => {
  const isRelativeTime = timeRange.raw.to === "now";
  const diffMillis = timeRange.to.valueOf() - timeRange.from.valueOf();

  const predefinedOpt =
    timeIntervalOptions.find(opt => {
      const optMillis = parseInt(opt.value, 10);
      return optMillis <= diffMillis;
    }) || oneMonthOpt;

  if (!isRelativeTime) {
    const fromMillis = timeRange.from.clone().startOf("day").add(1, "ms").valueOf();
    const toMillis = timeRange.to.clone().endOf("day").valueOf();

    const fromLabel = getFormattedDateTime(fromMillis, IncDateTimeFormat.minimal);
    const toLabel = getFormattedDateTime(toMillis, IncDateTimeFormat.minimal);

    return {
      label: `${fromLabel} to ${toLabel}`,
      value: `${fromMillis} to ${toMillis}`,
      data: predefinedOpt.data
    };
  } else {
    return predefinedOpt;
  }
};

export const heatMapColorsByRange: Record<string, string> = {
  "0": "#ffffff29",
  "1-5": "#FFCF72",
  "5-10": "#F9A471",
  "10+": "#D25D6C"
};

export const getTileColorByCount = (count: number) => {
  if (count <= 0) {
    return heatMapColorsByRange["0"];
  } else if (count > 0 && count <= 5) {
    return heatMapColorsByRange["1-5"];
  } else if (count > 5 && count <= 10) {
    return heatMapColorsByRange["5-10"];
  }

  return heatMapColorsByRange["10+"];
};

export const getHeatMapAxesFormats = (startTimeMillis: number, endTimeMillis: number, timeIntervalMillis: number) => {
  const startDateTime = dateTime(startTimeMillis);
  const endDateTime = dateTime(endTimeMillis);

  const diff = endDateTime.diff(startDateTime, "millisecond");

  let xAxisFormat: IncHighchartsDateTimeFormat | IncDateTimeFormat = IncHighchartsDateTimeFormat.monthDay;
  const xAxisFormatOptions: DateTimeOptions = {
    skipTime: true
  };

  let yAxisFormat: IncHighchartsDateTimeFormat | IncDateTimeFormat = IncHighchartsDateTimeFormat.shortDayOnly;
  const yAxisFormatOptions: DateTimeOptions = {
    skipTime: true
  };

  let yAxisTimeInterval = kbn.round_interval(timeIntervalMillis);
  let groupInterval = yAxisTimeInterval;

  if (diff > ONE_WEEK) {
    xAxisFormat = IncHighchartsDateTimeFormat.monthDayDescriptive;

    yAxisFormat = IncHighchartsDateTimeFormat.shortDayOnly;

    yAxisTimeInterval = ONE_DAY;
    groupInterval = ONE_WEEK;
  } else if (diff > ONE_DAY) {
    xAxisFormat = IncHighchartsDateTimeFormat.monthDayDescriptive;

    yAxisFormat = IncHighchartsDateTimeFormat.timeOnly;
    yAxisFormatOptions.skipTime = false;

    yAxisTimeInterval = 3 * ONE_HOUR;
    groupInterval = ONE_DAY;
  } else {
    xAxisFormat = IncHighchartsDateTimeFormat.timeOnly;
    xAxisFormatOptions.skipTime = false;

    yAxisFormat = IncHighchartsDateTimeFormat.timeOnly;
    yAxisFormatOptions.withSeconds = true;
    yAxisFormatOptions.skipTime = false;

    const hrDiff = endDateTime.hour() - startDateTime.hour();
    if (hrDiff > 12) {
      yAxisTimeInterval = 15 * ONE_MIN;
      groupInterval = ONE_HOUR;
    } else if (hrDiff > 6) {
      yAxisTimeInterval = 10 * ONE_MIN;
      groupInterval = 30 * ONE_MIN;
    } else if (hrDiff > 3) {
      yAxisTimeInterval = 5 * ONE_MIN;
      groupInterval = 15 * ONE_MIN;
    } else if (hrDiff > 1) {
      yAxisTimeInterval = ONE_MIN;
      groupInterval = 5 * ONE_MIN;
    } else {
      yAxisTimeInterval = ONE_MIN;
      groupInterval = ONE_MIN;
    }
  }

  return {
    xAxisFormat,
    xAxisFormatOptions,
    yAxisFormat,
    yAxisFormatOptions,
    yAxisTimeInterval,
    groupInterval
  };
};

const ONE_MIN = 60 * 1000;
const ONE_HOUR = 60 * ONE_MIN;
const ONE_DAY = 24 * ONE_HOUR;
const ONE_WEEK = 7 * ONE_DAY;
