import { BizOpQuery } from "../../../services/api/operationalise";
import BizOpQueryWidgetImpl from "../../widgets/BizOpQueryWidget/models/impl";
import BizOpQueryWidgetModel from "../../widgets/BizOpQueryWidget/models/model";
import { BaseWidgetBuilder, WidgetBuilderCallbackResult } from "./BaseWidgetBuilder";

type Result = WidgetBuilderCallbackResult<BizOpQueryWidgetModel, BizOpQueryWidgetBuilder>;
const bizOpQueryWidgetBuilder = (model: Partial<BizOpQueryWidgetModel> = {}): Result => {
  const bizOpQueryModel = new BizOpQueryWidgetImpl(model).getSafeDefaults() as BizOpQueryWidgetModel;

  // Return a method that ensures a datasource is set before setting any other properties
  return {
    setDatasource: (dsName: string) => {
      bizOpQueryModel.datasource = dsName;

      return new BizOpQueryWidgetBuilder(bizOpQueryModel);
    }
  };
};

export class BizOpQueryWidgetBuilder<
  T extends BizOpQueryWidgetModel = BizOpQueryWidgetModel
> extends BaseWidgetBuilder<T> {
  constructor(model: T) {
    super(model);
    this.model = model;
    this.model.properties = this.model.properties || ({} as any);
    this.model.options = this.model.options || {};
  }

  setBizOpQuery(bizOpQuery: BizOpQuery) {
    this.model.bizOpQuery = bizOpQuery;
    return this;
  }

  setGenerateDemoData(generateDemoData: boolean) {
    this.model.generateDemoData = generateDemoData;
    return this;
  }
}

export default bizOpQueryWidgetBuilder;
