import { IncFaIcon, IncGenericIcon, IncTextArea, IncTextfield } from "@inception/ui";
import React, { CSSProperties, FC, useCallback, useMemo, useRef } from "react";
import { DataType, useRefState, useToggleState } from "../../core";
import { UberOperationalizeTypes } from "../../services/api/operationalise";
import { Icon, KPI } from "../../services/api";
import { VerticallyCenteredRow } from "../flex-components";
import { UploadIconPopover } from "../UploadIconPopover";
import { WidgetConfigUtils } from "../../services/api/explore";
import { EventFieldPropertiesEditor } from "../event-field-query-editor";
import { KPIPreview } from "./KPIPreview";
import { KPIDTO, KPIEditorSection, KPIState, KPIWorkflowDTO } from "./types";
import { KPIQueryEditor } from "./KPIQueryEditor";
import { RelatedKPIsEditor } from "./RelatedKPIsEditor";
import { KPIWorkflowsEditor } from "./KPIWorkflowsEditor";

interface Props {
  kpiState: KPIState;
  onChange: (kpiState: KPIState) => void;
  sectionsToShow?: KPIEditorSection[];
  skipCollapse?: boolean;
}

export const KPIEditor: FC<Props> = props => {
  const { kpiState, onChange, skipCollapse = false } = props;
  const sectionsToShow = props.sectionsToShow || defSectionsToShow;

  const { kpiDTO, isValid, errorsText } = kpiState;
  const kpiDTORef = useRefState(kpiDTO);

  const isQueryValidRef = useRef(isValid);
  const queryErrorsTextRef = useRef(errorsText);

  const { description, name, icon, bizDataQuery, workflowDTOs, isSpikePositive, subType, id: kpiId } = kpiDTO;

  const iconComp = useMemo(() => {
    const iconName = icon?.iconName || "image";
    return <IncGenericIcon iconName={iconName} />;
  }, [icon]);

  const onChangeInternal = useCallback(
    (partKPIDTO: Partial<KPIDTO>) => {
      const kpiDto = kpiDTORef.current;
      const newKPI: KPIDTO = {
        ...kpiDto,
        ...partKPIDTO
      };

      const isValid = isQueryValidRef.current && Boolean(newKPI.name);
      const errorsText = queryErrorsTextRef.current || (!newKPI.name ? "Name is required" : "");

      const kpiState: KPIState = {
        kpiDTO: newKPI,
        isValid,
        errorsText
      };

      if (partKPIDTO.name) {
        WidgetConfigUtils.setNameForBizDataQuery(newKPI.bizDataQuery, partKPIDTO.name);
      }

      onChange(kpiState);
    },
    [kpiDTORef, onChange]
  );

  const onNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const nKpiName = e.target.value || "";
      onChangeInternal({ name: nKpiName });
    },
    [onChangeInternal]
  );

  const onDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const nKpiDescription = e.target.value || "";
      onChangeInternal({ description: nKpiDescription });
    },
    [onChangeInternal]
  );

  const onIconChange = useCallback(
    (iconName: Icon["iconName"]) => {
      onChangeInternal({
        icon: {
          iconColor: "",
          iconName
        }
      });
    },
    [onChangeInternal]
  );

  const onIsSpikePositiveChange = useCallback(
    (isSpikePositive: boolean) => {
      onChangeInternal({ isSpikePositive });
    },
    [onChangeInternal]
  );

  const onSubTypeChange = useCallback(
    (subType: string) => {
      onChangeInternal({ subType: subType as DataType });
    },
    [onChangeInternal]
  );

  const kpiQuery = useMemo<UberOperationalizeTypes.OpBizDataQuery>(
    () => ({
      bizDataQuery,
      rollingFreq: null,
      rollingFunction: null
    }),
    [bizDataQuery]
  );

  const onKPIStateChange = useCallback(
    (isQueryValid: boolean, queryErrorsText: string, kpiQuery: UberOperationalizeTypes.OpBizDataQuery) => {
      isQueryValidRef.current = isQueryValid;
      queryErrorsTextRef.current = queryErrorsText;
      onChangeInternal({ bizDataQuery: kpiQuery.bizDataQuery });
    },
    [onChangeInternal]
  );

  const onRelatedKPIsChange = useCallback(
    (relatedKpis: KPI[]) => {
      onChangeInternal({ relatedKpis });
    },
    [onChangeInternal]
  );

  const onWorkflowsChange = useCallback(
    (workflows: KPIWorkflowDTO[]) => {
      onChangeInternal({ workflowDTOs: workflows });
    },
    [onChangeInternal]
  );

  const { isOpen: isQuerySectionOpen, toggle: onQuerySectionToggle } = useToggleState(true);
  const { isOpen: isPreviewSectionOpen, toggle: onPreviewSectionToggle } = useToggleState(!skipCollapse);
  const { isOpen: isRelatedKPISectionOpen, toggle: onRelatedKPISectionToggle } = useToggleState(!skipCollapse);
  const { isOpen: isWorkflowSectionOpen, toggle: onWorkflowSectionToggle } = useToggleState(!skipCollapse);

  const {
    shouldShowMetaEdit,
    shouldShowPreview,
    shouldShowQueryEdit,
    shouldShowRelatedKpisEdit,
    shouldShowWorkflowsEdit
  } = useMemo(
    () => ({
      shouldShowMetaEdit: sectionsToShow.includes("meta"),
      shouldShowQueryEdit: sectionsToShow.includes("query"),
      shouldShowPreview: sectionsToShow.includes("preview"),
      shouldShowRelatedKpisEdit: sectionsToShow.includes("relatedKpis"),
      shouldShowWorkflowsEdit: sectionsToShow.includes("workflows")
    }),
    [sectionsToShow]
  );

  const { entityTypeId, eventTypeId } = useMemo(
    () => WidgetConfigUtils.getEntityTypeAndEventTypeFromBizDataQuery(bizDataQuery),
    [bizDataQuery]
  );

  return (
    <div
      className="kpi-editor"
      data-collapsible={!skipCollapse}
    >
      {/* Meta Section (name, icon and description) */}
      {shouldShowMetaEdit && (
        <div className="inc-flex-column width-100 flex-gap-12">
          <VerticallyCenteredRow className="flex-gap-16">
            <UploadIconPopover
              iconElem={iconComp}
              noEditText
              onSaveIcon={onIconChange}
              wrapperStyle={iconWrapperStyle}
            />

            <IncTextfield
              autoAdjustWidth
              className="width-100"
              containerClassName="width-100"
              onChange={onNameChange}
              placeholder="KPI Name"
              value={name}
            />
          </VerticallyCenteredRow>

          <IncTextArea
            className="width-100"
            containerClassName="width-100"
            onChange={onDescriptionChange}
            placeholder="Description"
            rows={3}
            value={description || ""}
          />
        </div>
      )}

      {/* Query Edit Section */}
      {shouldShowQueryEdit && (
        <div className="kpi-editor--section query-section">
          {!skipCollapse && (
            <VerticallyCenteredRow
              className="kpi-editor--section-header"
              onClick={onQuerySectionToggle}
            >
              <IncFaIcon
                className="collapse-arrow"
                data-expanded={isQuerySectionOpen}
                iconName="chevron-circle-down"
              />
              Query Configuration
            </VerticallyCenteredRow>
          )}

          {isQuerySectionOpen && (
            <div className="kpi-editor--section-content flex-gap-12">
              <KPIQueryEditor
                kpiId={kpiId}
                kpiQuery={kpiQuery}
                onStateChange={onKPIStateChange}
              />

              <EventFieldPropertiesEditor
                enableSpikeConfiguration
                enableSubTypeSelection
                isSpikePositive={isSpikePositive}
                onIsSpikePositiveChange={onIsSpikePositiveChange}
                onSubTypeChange={onSubTypeChange}
                subType={subType}
              />
            </div>
          )}
        </div>
      )}

      {/* Preview Section */}
      {shouldShowPreview && (
        <div className="kpi-editor--section preview-section">
          {!skipCollapse && (
            <VerticallyCenteredRow
              className="kpi-editor--section-header"
              onClick={onPreviewSectionToggle}
            >
              <IncFaIcon
                className="collapse-arrow"
                data-expanded={isPreviewSectionOpen}
                iconName="chevron-circle-down"
              />
              Preview
            </VerticallyCenteredRow>
          )}

          {isPreviewSectionOpen && (
            <div className="kpi-editor--section-content">
              <KPIPreview
                bizDataQuery={kpiQuery.bizDataQuery}
                errorsText={kpiState.errorsText}
                isValid={kpiState.isValid}
              />
            </div>
          )}
        </div>
      )}

      {/* Related KPIs Section */}
      {shouldShowRelatedKpisEdit && (
        <div className="kpi-editor--section related-kpis-section">
          {!skipCollapse && (
            <VerticallyCenteredRow
              className="kpi-editor--section-header"
              onClick={onRelatedKPISectionToggle}
            >
              <IncFaIcon
                className="collapse-arrow"
                data-expanded={isRelatedKPISectionOpen}
                iconName="chevron-circle-down"
              />
              Related KPIs
            </VerticallyCenteredRow>
          )}

          {isRelatedKPISectionOpen && (
            <div className="kpi-editor--section-content">
              <RelatedKPIsEditor
                onChange={onRelatedKPIsChange}
                parentKpiId={kpiState.kpiDTO.id}
                relatedKPIs={kpiState.kpiDTO.relatedKpis}
              />
            </div>
          )}
        </div>
      )}

      {/* Workflows Section */}
      {shouldShowWorkflowsEdit && (
        <div className="kpi-editor--section workflows-section">
          {!skipCollapse && (
            <VerticallyCenteredRow
              className="kpi-editor--section-header"
              onClick={onWorkflowSectionToggle}
            >
              <IncFaIcon
                className="collapse-arrow"
                data-expanded={isWorkflowSectionOpen}
                iconName="chevron-circle-down"
              />
              Workflows
            </VerticallyCenteredRow>
          )}

          {isWorkflowSectionOpen && (
            <div className="kpi-editor--section-content">
              <KPIWorkflowsEditor
                entityTypeId={entityTypeId}
                eventTypeId={eventTypeId}
                kpiId={kpiDTO.id}
                kpiWorkflows={workflowDTOs}
                onChange={onWorkflowsChange}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const defSectionsToShow: KPIEditorSection[] = ["meta", "preview", "query", "relatedKpis", "workflows"];

const iconWrapperStyle: CSSProperties = {
  background: "rgba(255, 255, 255, 0.15)",
  height: 32,
  borderRadius: 8,
  width: 32,
  fontSize: 14,
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};
