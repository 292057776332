import { cloneDeep } from "lodash";
import {
  UserServiceFieldSlice,
  UserServiceFieldSliceSet,
  OverTagAggregators,
  PostAggProjection,
  SelectorSpec,
  SortSpec,
  WidgetResponseDTO,
  WidgetConfigUtils,
  ForecastProjection
} from "../../services/api/explore";
import { TimeRange, logger } from "../../core";
import { convertUSFieldSliceSetToTagSlice } from "../../utils/ExploreUtils";
import { ExploreQueryUtils, getMillisFromTimeObj } from "../../utils";
import datasourceApiManager from "../../services/api/DatasourceApiService";
import { DataQueryRequest } from "../../services/api/types";
import { ExploreQuery, ExploreQueryType } from "../../services/datasources/explore/types";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { ExploreDatasource } from "../../services/datasources/explore/datasource";
import { bizEntityDataTransformer } from "../../dashboard/widgets/utils";
import { entityDataTransformer, EntityWidgetData } from "../../biz-entity";
import { getVariablesBasedOnWidget } from "../../dashboard/widgets/useVariables";
import { USFieldWidgetImpl } from "../../dashboard/widgets/USField/models";
import {
  MetricTableColumnQueryConfig,
  MTCMetricQueryConfig,
  MTCEntityQueryConfig,
  MetricTableVariableContext
} from "./types";

export type MetricTableQueryResult = {
  queryType: MetricTableColumnQueryConfig["queryType"];
  projection: PostAggProjection;
  forecastProjections: ForecastProjection[];
  isError: boolean;
  error: string;
  data: EntityWidgetData[] | any;
};

export const getMetricData = async (
  metricName: string,
  metricQueryConfig: MTCMetricQueryConfig,
  slices: UserServiceFieldSlice[],
  timeRange: TimeRange,
  uniqId: string,
  queryIdx: number,
  entityTypeMap: Record<string, string>,
  widgetResponseDTO: WidgetResponseDTO,
  sortSpec?: SortSpec,
  selectorSpec?: SelectorSpec,
  variablesContext?: MetricTableVariableContext
): Promise<MetricTableQueryResult> => {
  const result: MetricTableQueryResult = {
    data: null,
    isError: false,
    error: null,
    queryType: "metric",
    projection: null,
    forecastProjections: []
  };

  try {
    const { request, projection, widgetConfigDto, forecastProjections } = getMetricDataRequest(
      metricName,
      metricQueryConfig,
      slices,
      timeRange,
      uniqId,
      queryIdx,
      widgetResponseDTO,
      sortSpec,
      selectorSpec,
      variablesContext
    );

    result.projection = projection;
    result.forecastProjections = forecastProjections;

    const datasource = datasourceApiManager.get("explore") as ExploreDatasource;

    const { data, error } = await datasource.query(request);

    if (!error && data) {
      const partTransformed = bizEntityDataTransformer([
        {
          data
        }
      ]);
      const transformed = await entityDataTransformer(widgetConfigDto, partTransformed, entityTypeMap, true);

      const { data: transformedData, error } = transformed[0];

      if (!error && transformedData) {
        result.data = transformedData;
      } else {
        result.isError = true;
        result.error = error?.message || "Error fetching metric data";
      }
    } else {
      result.isError = true;
      result.error = error?.message || "Error fetching metric data";
    }
  } catch (err) {
    result.isError = true;
    result.error = err.message || String(err);
  }

  return result;
};

export const getMetricDataRequest = (
  metricName: string,
  metricQueryConfig: MTCMetricQueryConfig,
  slices: UserServiceFieldSlice[],
  timeRange: TimeRange,
  uniqId: string,
  queryIdx: number,
  widgetResponseDTO: WidgetResponseDTO,
  sortSpec?: SortSpec,
  selectorSpec?: SelectorSpec,
  variablesContext?: MetricTableVariableContext
) => {
  const {
    downsample: cDS,
    bizDataQuery,
    entityTypeId,
    eventTypeId,
    projection = "current",
    compareOffset,
    enableForecast,
    forecastProjections = []
  } = metricQueryConfig;

  const cDsIntervalSecs = cDS ? timeRangeUtils.getSecondsFromMillis(getMillisFromTimeObj(cDS)) : null;
  const dsIntervalSecs = cDS ? cDsIntervalSecs : getIntervalSecs(timeRange);

  const { from, to } = timeRangeUtils.getTimeRangeMillisFromRaw(timeRange.raw);

  const compareOffsetMillis = compareOffset ? getMillisFromTimeObj(compareOffset) : 0;
  const timeShiftCompareSeconds = timeRangeUtils.getSecondsFromMillis(compareOffsetMillis);

  const usfSliceSet: UserServiceFieldSliceSet = { slices };
  const sliceSet = convertUSFieldSliceSetToTagSlice(usfSliceSet);
  const aggTags = slices.map(slice => slice.tagName);

  const { sliceSpec, widgetConfig, id: widgetId } = bizDataQuery;

  const { metricId } = sliceSpec;

  const querySchema = widgetResponseDTO?.querySchema?.querySchema || [];
  const sliceTagNames = WidgetConfigUtils.getTagNamesFromSliceSet(sliceSet);
  const matchingQs = WidgetConfigUtils.getMatchingQuerySchema(querySchema, metricId, sliceTagNames);

  let tagAggregator: OverTagAggregators = matchingQs?.defaultTagAgg;

  if (widgetConfig && !tagAggregator) {
    const metricConfig = widgetConfig.dataDefinition.metrics[metricId];
    if (metricConfig && metricConfig.sourceType === "userServiceField") {
      const { aggregator } = metricConfig.userServiceFieldMetricConfig;
      tagAggregator = ExploreQueryUtils.getOverTagAggregatorForAggregator(aggregator);
    }
  }

  tagAggregator = tagAggregator || "avg";

  const fSliceSpec = cloneDeep(sliceSpec);
  fSliceSpec.postAgg = {
    isSingleStatQuery: true,
    projections: [projection],
    overTagAgg: {
      aggregator: tagAggregator,
      tagName: aggTags
    },
    timeShiftCompareSeconds
  };

  if (sortSpec) {
    fSliceSpec.postAgg.sortSpec = sortSpec;
  }

  if (forecastProjections?.length && enableForecast) {
    fSliceSpec.postAgg.forecastSpec = {
      projections: forecastProjections
    };
  }

  fSliceSpec.selectorSpec = selectorSpec || fSliceSpec.selectorSpec;

  if (!compareOffset) {
    delete fSliceSpec.postAgg.timeShiftCompareSeconds;
  } else {
    delete fSliceSpec.postAgg.isSingleStatQuery;
  }

  fSliceSpec.sliceSet = sliceSet;

  const {
    eventTypeIdToFieldsMap = {},
    loadingStateMap = {},
    variableSrvMap = {},
    dashboardWidgetId = uniqId
  } = variablesContext || {};

  const widget = new USFieldWidgetImpl({
    entityType: entityTypeId,
    id: dashboardWidgetId
  });

  const { eEntityFilters, eEventFilters, eCohortFilters } = getVariablesBasedOnWidget(
    variableSrvMap,
    loadingStateMap,
    widget,
    widgetResponseDTO,
    eventTypeIdToFieldsMap,
    true
  );

  const widgetConfigDto = widgetResponseDTO.widgetConfig;
  widgetConfigDto.name = metricName;

  const entityFilters = [...(eCohortFilters || []), ...(eEntityFilters || [])];

  const request: DataQueryRequest<ExploreQuery> = {
    dashboardId: uniqId,
    interval: `${dsIntervalSecs}s`,
    panelId: queryIdx.toString(),
    requestId: `metric-table-widget-metric-query-${uniqId}-${queryIdx}`,
    startTime: from,
    endTime: to,
    resolveEntityIdsToName: true,
    intervalMs: dsIntervalSecs * 1000,
    targets: [
      {
        payload: {
          compareInfo: null,
          entityFilters,
          metricUserServiceFilters: eEventFilters,
          entityId: eventTypeId,
          entityType: entityTypeId,
          sliceSpec: [fSliceSpec],
          tagFilters: [],
          excludeWidgetData: true,
          includeQueryConfig: false,
          widgetConfig: widgetConfigDto,
          widgetId,
          limit: -1
        },
        query: "",
        refId: "A",
        type: widgetId ? ExploreQueryType.saved : ExploreQueryType.adhoc
      }
    ]
  };

  return {
    request,
    projection,
    forecastProjections: enableForecast ? forecastProjections || [ForecastProjection.forecast] : [],
    widgetConfigDto
  };
};

export const getEntityData = (
  entityQueryConfig: MTCEntityQueryConfig,
  timeRange: TimeRange
): Promise<MetricTableQueryResult> => {
  logger.debug("MetricTableWidget", "Get entity data", {
    entityQueryConfig,
    timeRange
  });

  const result: MetricTableQueryResult = {
    data: {},
    isError: false,
    error: null,
    queryType: "metric",
    projection: null,
    forecastProjections: []
  };

  return Promise.resolve(result);
};

export const getDataFramesFromEntityDataByProjection = (item: EntityWidgetData, projection: PostAggProjection) => {
  const { postAggResult } = item;
  const {
    data: postAggData,
    percentChangeData: postAggPerChangeData,
    timeShiftData: postAggTimeShiftData
  } = postAggResult;

  const dataObj =
    projection === "current" || projection === "all"
      ? postAggData
      : projection === "deltaPercentage"
        ? postAggPerChangeData
        : postAggTimeShiftData;

  const dataEntry = Object.values(dataObj)[0];

  const { data: dataFrames = [] } = dataEntry || {};

  return dataFrames;
};

const getIntervalSecs = (timeRange: TimeRange) => {
  const nTimeRange = timeRangeUtils.getTimeRangeFromRaw(timeRange.raw);

  const dsIntervalMillis = timeRangeUtils.getDiffMillisFromTimeRange(nTimeRange);
  const dsIntervalSecs = timeRangeUtils.getSecondsFromMillis(dsIntervalMillis);

  return dsIntervalSecs;
};
