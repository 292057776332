import React, { FC, useMemo } from "react";
import { inceptionDarkColorPalette } from "@inception/ui-styles";
import { cx } from "emotion";
import { isNull, isUndefined } from "lodash";
import { IncSmartText, ISaxIcon } from "@inception/ui";
import {
  BizDataQuery,
  WidgetResponseDTO,
  OverTimePostAgg,
  OverTagPostAgg,
  WidgetConfigUtils,
  OverallMappingStatus,
  DemoDataParams
} from "../../services/api/explore";
import { useTimeRange, logger, useTenantConfig } from "../../core";
import { VizOption } from "../../services/api/chat";
import { textWidgetBuilder, catalogWidgetBuilder } from "../../dashboard/builders";
import appConfig from "../../../appConfig";
import { BaseWidgetImpl } from "../../dashboard";
import TextWidgetImpl from "../../dashboard/widgets/Text/impl";
import { USFWQueryConfig } from "../../dashboard/widgets/USField/models";
import { StandAloneWidget } from "../../components/StandaloneWidget";
import { getDtoFromWidgetConfig } from "../../utils/ExploreUtils";
import { getVisualisationFromVizOption } from "../utils";
import { ExploreQueryUtils } from "../../utils";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { CatalogWidgetImpl, CatalogWidgetProperties } from "../../dashboard/widgets/Catalog/models";
import { MappingIncompleteOverlay, VerticallyCenteredRow } from "../../components";

interface Props {
  bizDataQuery: BizDataQuery;
  fromMillis: string;
  toMillis: string;
  vizType: VizOption;
  compareFromOffset: string;
  title: string;
  disableCompare: boolean;
  properties: CatalogWidgetProperties;
  demoDataParams: DemoDataParams;
}

export const ChatBotVizRenderer: FC<Props> = props => {
  const {
    bizDataQuery,
    vizType,
    fromMillis: fromMillisStr,
    toMillis: toMillisStr,
    compareFromOffset: compareFromOffsetMillisStr = "1w",
    title,
    disableCompare,
    properties: defProperties,
    demoDataParams
  } = props;

  const { tenantConfigState } = useTenantConfig();
  const { demoTenant } = tenantConfigState || {};

  const { timeRange } = useTimeRange();

  const fromMillis = parseInt(fromMillisStr, 10);
  const toMillis = parseInt(toMillisStr, 10);

  let tr = timeRange;

  if (fromMillis && toMillis) {
    tr = timeRangeUtils.getTimeRangeFromRaw({
      from: fromMillisStr,
      to: toMillisStr,
      fromType: "absolute",
      toType: "absolute"
    });
  }

  const cTr = timeRangeUtils.getCompareTimeRangeFromRaw(tr, compareFromOffsetMillisStr);

  const { widgetImpl, generateDemoData, unmappedFields } = useMemo(() => {
    const { queryConfig, properties, entityType, eventTypeId, generateDemoData, unmappedFields } =
      getSourceQueryAndProperties(bizDataQuery, vizType, disableCompare, defProperties);

    let widgetImpl: BaseWidgetImpl;

    if (!queryConfig) {
      const builder = textWidgetBuilder()
        .setDatasource(appConfig.defaultExploreDsName)
        .setText("No metrics found")
        .setFontColor(inceptionDarkColorPalette.accentRed)
        .disableHeader();
      const model = builder.buildModel();

      widgetImpl = new TextWidgetImpl(model);
    } else {
      const builder = catalogWidgetBuilder()
        .setDatasource(appConfig.defaultExploreDsName)
        .setQueryConfig(queryConfig)
        .setProperties(properties as any)
        .disableEdit()
        .disableResize()
        .setRenderMode("adhoc-view")
        .setTitle(title)
        .setEntityType(entityType)
        .setUserserviceId(eventTypeId)
        .setGenerateDemoData(generateDemoData)
        .setDisableUpdate(true)
        .disableHeader();

      const model = builder.buildModel();
      widgetImpl = new CatalogWidgetImpl(model);

      if (generateDemoData) {
        (widgetImpl as CatalogWidgetImpl).setDemoDataParams({
          ...demoDataParams,
          generateDemoData: true
        });
      }
    }

    return {
      widgetImpl,
      generateDemoData,
      unmappedFields
    };
  }, [bizDataQuery, defProperties, demoDataParams, disableCompare, title, vizType]);

  const overlayJsx = useMemo(
    () => (
      <div className="unmapped-fields-overlay">
        <VerticallyCenteredRow className="inc-flex-center width-100">
          <ISaxIcon
            iconName="Unlock"
            size={30}
          />
        </VerticallyCenteredRow>
        <VerticallyCenteredRow className="inc-flex-center width-100">Missing dimensions</VerticallyCenteredRow>
        <IncSmartText
          className="inc-flex-center width-100 inc-text-subtext-medium inc-text-inactive text-center"
          numLines={3}
          text="We found these dimensions missing. To complete your data request add the missing data and unlock new insights"
        />

        {unmappedFields.map(fieldName => (
          <VerticallyCenteredRow
            className="inc-flex-center width-100 unmapped-fields-overlay--dimension"
            key={fieldName}
          >
            <IncSmartText
              className="inc-text-subtext-medium"
              text={fieldName}
            />
          </VerticallyCenteredRow>
        ))}
      </div>
    ),
    [unmappedFields]
  );

  const className = cx("chat-bot-widget-renderer", {
    "full-width": fullWidthViz.includes(vizType)
  });

  const showMappingIncomplete = generateDemoData && !demoTenant;

  return (
    <div
      className={className}
      data-incomplete={showMappingIncomplete}
    >
      <MappingIncompleteOverlay
        asCard
        isIncomplete={showMappingIncomplete}
        message=""
        overlayJsx={overlayJsx}
      >
        <StandAloneWidget
          compareTimeRange={cTr}
          timeRange={tr}
          widget={widgetImpl}
        />
      </MappingIncompleteOverlay>
    </div>
  );
};

export const MemoizedChatBotVizRenderer = React.memo(ChatBotVizRenderer);

const getSourceQueryAndProperties = (
  bizDataQuery: BizDataQuery,
  vizType: VizOption,
  disableCompare: boolean,
  defProperties: CatalogWidgetProperties
) => {
  disableCompare =
    isNull(disableCompare) || isUndefined(disableCompare)
      ? vizType !== VizOption.INSIGHTS && vizType !== VizOption.TABLE
      : disableCompare;

  const visualisation = getVisualisationFromVizOption(vizType);
  const properties: CatalogWidgetProperties = {
    visualisation,
    disableCompare,
    ...defProperties
  };

  try {
    const { sliceSpec, widgetConfig } = bizDataQuery;

    const generateDemoData =
      widgetConfig?.mappingStatus?.isIncomplete ||
      widgetConfig?.mappingStatus?.overallStatus === OverallMappingStatus.PENDING;

    const unmappedEntityFields = widgetConfig?.mappingStatus?.entityFields?.map(f => f.field.propName) || [];
    const unmappedUSFields = widgetConfig?.mappingStatus?.usFields?.map(f => f.field.fieldName) || [];
    const unmappedFields = [...unmappedEntityFields, ...unmappedUSFields];

    const widgetConfigDto = getDtoFromWidgetConfig(widgetConfig);

    const { sliceSet, metricId } = sliceSpec;
    const postAgg = sliceSpec.postAgg as OverTagPostAgg & OverTimePostAgg;

    const metricDef = WidgetConfigUtils.getMetricDefinition(widgetConfigDto, metricId);
    const metricName = metricDef?.name || "Custom metric";
    const { bizEntityMetricDef, entityMetricDef, expressionMetricDef, usFieldMetricDef, usMetricMetricDef } =
      WidgetConfigUtils.getMetricDefinitions(metricDef);

    const aggregator =
      bizEntityMetricDef?.bizEntityMetricConfig?.aggregator ||
      entityMetricDef?.entityMetricConfig?.aggregator ||
      null ||
      usFieldMetricDef?.userServiceFieldMetricConfig?.aggregator ||
      usMetricMetricDef?.userServiceMetricConfig?.aggregator;

    const defaultTagAgg =
      postAgg?.overTagAgg?.aggregator ||
      expressionMetricDef?.expressionMetricConfig?.tagAgg?.aggregator ||
      ExploreQueryUtils.getOverTagAggregatorForAggregator(aggregator) ||
      "avg";

    const defaultTimeAgg =
      postAgg?.overTimeAgg?.aggregator ||
      expressionMetricDef?.expressionMetricConfig?.timeAgg?.aggregator ||
      ExploreQueryUtils.getOverTimeAggregatorForAggregator(aggregator) ||
      "avg";

    const widgetResponseDto: WidgetResponseDTO = {
      querySchema: {
        querySchema: [
          {
            defaultTagAgg,
            defaultTimeAgg,
            metricId,
            metricName,
            resultKey: "",
            sliceSet: sliceSet
          }
        ]
      },
      version: 1,
      widgetConfig: widgetConfigDto,
      widgetId: "",
      widgetName: widgetConfig.name
    };

    const queryConfig: USFWQueryConfig = {
      sourceQueryConfig: {
        queryType: "widgetConfig",
        metricId,
        widgetResponse: widgetResponseDto
      },
      sliceSet
    };

    const aggregatedTags = postAgg?.overTagAgg?.tagName || sliceSet?.slices?.map(s => s.tagName) || [];
    properties.topN = {
      metricIds: [metricId],
      aggregatedTags,
      showBarChart: true
    };
    properties.aggregateTags = aggregatedTags;

    return {
      queryConfig,
      properties,
      entityType: widgetConfigDto.bizEntityType,
      eventTypeId: widgetConfigDto.userServiceEntityId,
      generateDemoData,
      unmappedFields
    };
  } catch (error) {
    logger.error("ChatBotVizRenderer", "Error constructing payload", {
      error,
      bizDataQuery
    });

    return {
      queryConfig: null,
      properties,
      entityType: null,
      eventTypeId: null,
      generateDemoData: false,
      unmappedFields: []
    };
  }
};

const fullWidthViz = [VizOption.BAR_CHART, VizOption.TREE_MAP, VizOption.TIME_SERIES, VizOption.INSIGHTS];
