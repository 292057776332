import React, { FC, useMemo } from "react";
import { IncTextArea, IncToggle } from "@inception/ui";
import { EntityPropertyValue } from "../../../../../../platform/core";
import { ToolParam } from "../../../../../../platform/services/api/explore";

type Props = {
  toolParam: ToolParam;
  paramValueRecord: Record<string, EntityPropertyValue>;
  onChange: (key: string, value: EntityPropertyValue) => void;
};

const ParamsEditor: FC<Props> = props => {
  const { onChange: pOnChange, paramValueRecord, toolParam } = props;
  const isDescriptionType = toolParam?.primKind === "_str";
  const isToggleType = toolParam.primKind === "_bool";

  const toggleValue = paramValueRecord[toolParam.param]?.booleanVal || false;
  const descriptionValue = paramValueRecord[toolParam.param]?.stringVal || "";

  const paramsEditorJsx = useMemo(() => {
    if (isDescriptionType) {
      const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        pOnChange(toolParam.param, { stringVal: e.target.value });
      return (
        <IncTextArea
          label={toolParam.paramDisplayName}
          onChange={onChange}
          placeholder={toolParam.description}
          value={descriptionValue}
        />
      );
    }
    if (isToggleType) {
      const onChange = (booleanVal: boolean) => pOnChange(toolParam.param, { booleanVal });
      return (
        <IncToggle
          checked={toggleValue}
          label={toolParam.paramDisplayName}
          onChange={onChange}
        />
      );
    }
    return <></>;
  }, [
    descriptionValue,
    isDescriptionType,
    isToggleType,
    pOnChange,
    toggleValue,
    toolParam.description,
    toolParam.param,
    toolParam.paramDisplayName
  ]);

  return <>{paramsEditorJsx}</>;
};

export default ParamsEditor;
