import { IncFaIcon, IncSmartText, IncToolTip } from "@inception/ui";
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { OpSchedule } from "../../services/api/operationalise";
import { OpScheduleEditorV2 } from "../v3/editors/ScheduleEditor/ScheduleEditorV2";
import { VerticallyCenteredRow } from "../../components";
import { OpTimeFrame } from "../v3/simulation";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { TimeZone } from "../../core";
import { setAnalysisSchedule, useOpAnalysisStore, validateAnalysisSchedule } from "./context";
import OpTimeFrameEditorWrapper from "./OpTimeFrameEditorWrapper";

type Props = {
  readOnly?: boolean;
};

const ScheduleEditor: FC<Props> = memo(props => {
  const { readOnly } = props;
  const { state, dispatch } = useOpAnalysisStore();
  const { opAnalysisImpl } = state;

  const onChange = useCallback(
    (nSchedule: OpSchedule) => {
      dispatch(setAnalysisSchedule(nSchedule));
    },
    [dispatch]
  );

  const schedule = useMemo(() => opAnalysisImpl.getSchedule(), [opAnalysisImpl]);
  const errors = useMemo(() => validateAnalysisSchedule(schedule), [schedule]);

  const isValid = !Object.keys(errors).length;

  const errorElement = useMemo(() => {
    const jsxList: JSX.Element[] = [];
    Object.keys(errors).forEach(title => {
      const jsx = (
        <span
          className="inc-flex-row"
          key={title}
        >
          {title}: {errors[title]}
        </span>
      );
      jsxList.push(jsx);
    });
    if (jsxList.length) {
      return <div className="inc-flex-column padding8 flex-gap-8">{jsxList}</div>;
    }
    return <></>;
  }, [errors]);

  const { schedule: childSchedule, labels } = schedule || {};
  const { endTimeEpochSecs, startTimeEpochSecs } = childSchedule || {};
  const { timeZone: defTimeZone = "browser" } = labels || {};

  const defTimeFrame = useMemo<OpTimeFrame>(() => {
    let numStartTimeEpochSecs = parseInt(String(startTimeEpochSecs), 10);
    numStartTimeEpochSecs = Number.isNaN(numStartTimeEpochSecs) ? 0 : numStartTimeEpochSecs;
    let numEndTimeEpochSecs = parseInt(String(endTimeEpochSecs), 10);
    numEndTimeEpochSecs = Number.isNaN(numEndTimeEpochSecs) ? 0 : numEndTimeEpochSecs;
    return {
      from: numStartTimeEpochSecs ? String(numStartTimeEpochSecs * 1000) : null,
      to: numEndTimeEpochSecs ? String(numEndTimeEpochSecs * 1000) : null,
      label: null,
      isCustom: Boolean(numStartTimeEpochSecs || numEndTimeEpochSecs),
      isStartingNow: !(numStartTimeEpochSecs || numEndTimeEpochSecs)
    };
  }, [endTimeEpochSecs, startTimeEpochSecs]);

  const [timeFrame, setTimeFrame] = useState<OpTimeFrame>(null);

  useEffect(() => {
    setTimeFrame(prev => prev || defTimeFrame);
  }, [defTimeFrame]);

  const onChangeTimeFrame = useCallback(
    (timeFrame: OpTimeFrame, timeZone?: TimeZone) => {
      const startTimeMillis = timeFrame.from ? timeRangeUtils.getMillis(timeFrame.from).millis : 0;
      const endTimeMillis = timeFrame.to ? timeRangeUtils.getMillis(timeFrame.to).millis : 0;
      const nSchedule: OpSchedule = {
        ...schedule,
        schedule: {
          ...schedule?.schedule,
          endTimeEpochSecs: parseInt(String(endTimeMillis / 1000), 10),
          startTimeEpochSecs: parseInt(String(startTimeMillis / 1000), 10)
        },
        labels: {
          ...(schedule?.labels || {}),
          timeZone
        }
      };
      dispatch(setAnalysisSchedule(nSchedule));
      setTimeFrame(timeFrame);
    },
    [dispatch, schedule]
  );

  return (
    <div className="analysis-editor--schedule-editor">
      <VerticallyCenteredRow className="flex-gap-12">
        <IncSmartText text="Schedule" />
        {!isValid && (
          <IncToolTip titleElement={errorElement}>
            <IncFaIcon
              className="status-danger inc-cursor-pointer marginLt12"
              iconName="warning"
            />
          </IncToolTip>
        )}
      </VerticallyCenteredRow>
      <VerticallyCenteredRow className="flex-gap-12 analysis-editor--schedule-editor-container">
        <OpScheduleEditorV2
          onChange={onChange}
          opSchedule={schedule}
          readOnly={readOnly}
        />
        {timeFrame && (
          <OpTimeFrameEditorWrapper
            defTimeZone={defTimeZone}
            onChange={onChangeTimeFrame}
            opTimeFrame={timeFrame}
          />
        )}
      </VerticallyCenteredRow>
    </div>
  );
});

export default ScheduleEditor;
