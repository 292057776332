import { IncButton, IncFaIcon, IncPopper, IncToggle, IncClickAway } from "@inception/ui";
import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import qs from "query-string";

import { logger, useClipboard, useNotifications } from "../../core";
import shortenUrlApiService from "../../services/api/shorten-url/ShortenUrlApiService";

interface ShareUrlProps {
  nonHashKeys?: string[];
}

const URL_CHAR_LIMIT = 1200;

export const ShareUrl = ({ nonHashKeys = [] }: ShareUrlProps) => {
  const [showHoverEl, setShowHoverEl] = useState<boolean>(false);
  const [enableShortUrl, setEnableShortUrl] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { addToClipboard } = useClipboard();
  const { notifySuccess, notifyError } = useNotifications();
  const popUpRef = useRef<HTMLButtonElement>(null);

  const { pathname, search, href, origin } = window.location;

  const { queryObject, nonHashParams } = useMemo(() => {
    const nonHashParams: Record<string, any> = {};
    const queryObject = qs.parse(search);

    nonHashKeys.forEach(key => {
      const value = queryObject[key];
      nonHashParams[key] = value;
      delete queryObject[key];
    });
    return {
      nonHashParams,
      queryObject
    };
  }, [nonHashKeys, search]);

  useEffect(() => {
    if (href.length > URL_CHAR_LIMIT) {
      setEnableShortUrl(true);
    }
  }, [href.length, setEnableShortUrl]);

  function onClick() {
    setShowHoverEl(prev => !prev);
  }

  function onToggle() {
    setEnableShortUrl(prev => !prev);
  }

  const getShortUrl = useCallback(async () => {
    const result = await shortenUrlApiService.getShortUrl(JSON.stringify(queryObject));
    if (result.error) {
      throw new Error(result.message);
    }
    const newQuery = qs.stringify({
      ...nonHashParams,
      paramsHash: result.data.key
    });
    const newUrl = `${origin}/goto${pathname}?${newQuery}`;
    return newUrl;
  }, [origin, pathname, queryObject, nonHashParams]);

  const fullUrl = useMemo(() => {
    const url = origin + pathname + search;
    return url;
  }, [origin, pathname, search]);

  const onCopyClick = async () => {
    try {
      setIsLoading(true);
      const newUrl = enableShortUrl ? await getShortUrl() : fullUrl;
      await addToClipboard(newUrl);
      notifySuccess("Copied to clipboard");
      setShowHoverEl(false);
    } catch (err) {
      logger.error("Share Url", "Error generating share url", err);
      notifyError("Error in genrating Share URL");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <IncButton
        className="marginLt16"
        color="link"
        iconType="icon"
        onClick={onClick}
        ref={popUpRef}
      >
        <IncFaIcon iconName="share-alt" />
      </IncButton>
      <IncClickAway onClickAway={() => setShowHoverEl(false)}>
        {refFn => (
          <IncPopper
            anchorEl={popUpRef as any}
            placement="bottom-end"
            ref={refFn}
            show={showHoverEl}
          >
            <div className="p-a-1 share-url-container">
              <div className="title">Share URL</div>
              <IncToggle
                checked={enableShortUrl}
                className="inc-flex-space-contents m-b-0-50 m-t-0-50"
                label="Short URL"
                onChange={onToggle}
              />
              <IncButton
                className="width-100 inc-flex-center"
                color="primary"
                loading={isLoading}
                onClick={onCopyClick}
              >
                Copy URL
              </IncButton>
            </div>
          </IncPopper>
        )}
      </IncClickAway>
    </div>
  );
};
