import React, { useMemo, useCallback, useState, useEffect } from "react";
import { IncFaIcon, IncMenu, IncMenuItemProps, IncMenuToggleProps, IncSelectOption } from "@inception/ui";
import { clone, forEach, isEmpty } from "lodash";
import { WidgetCustomAction } from "../../../types";
import { TimeRange, useInceptionRoute, useNotifications, useToggleState } from "../../../../../core";
import { getEventsURL, getSliceQueryFromSelectorSpec } from "../../widgetActionsUtils";
import { SelectorSpec, Slice, WidgetResponseDTO, getWidgetTraceQueryLinks } from "../../../../../services/api/explore";
import { FetchResultMetaProps, useGetResultMetaAndDQConfig } from "../useGetResultMeta";
import { LoadingSpinner } from "../../../../../components";

type Props = {
  widgetSelectorSpec: SelectorSpec;
  timeRange: TimeRange;
  widgetResponseDTO: WidgetResponseDTO;
  fetchResultMetaProps: FetchResultMetaProps;
};

export const useViewEventsAction = (props: Props) => {
  const { widgetResponseDTO, fetchResultMetaProps: pFetchResultMetaProps, widgetSelectorSpec, timeRange } = props;

  const [isViewEventsClicked, setIsViewEventsClicked] = useState(false);

  const fetchResultMetaProps = useMemo<FetchResultMetaProps>(() => {
    const nProps = clone(pFetchResultMetaProps);
    nProps.id = `${nProps.id}-view-events`;
    return nProps;
  }, [pFetchResultMetaProps]);

  const { dataQueryConfig, isResultMetaFetching, refetch } = useGetResultMetaAndDQConfig(fetchResultMetaProps, true);

  const widgetTraceQueryLinks: IncSelectOption[] = useMemo(
    () =>
      dataQueryConfig && !isEmpty(widgetResponseDTO?.widgetConfig)
        ? getWidgetTraceQueryLinks(dataQueryConfig, widgetResponseDTO.widgetConfig)
        : [],
    [dataQueryConfig, widgetResponseDTO]
  );

  const { navigate } = useInceptionRoute();

  const { bizFilter, sliceQuery } = useMemo(() => {
    const bizFilter = "";
    let sliceQuery = "";

    if (widgetSelectorSpec) {
      const { querySchema } = widgetResponseDTO || {};
      const slices: Slice[] = querySchema?.querySchema[0]?.sliceSet?.slices || [];
      //get the slice query
      sliceQuery = getSliceQueryFromSelectorSpec(widgetSelectorSpec, slices);
    }

    return {
      bizFilter,
      sliceQuery
    };
  }, [widgetResponseDTO, widgetSelectorSpec]);

  const navigateToEvents = useCallback(
    (link: string) => {
      const path = getEventsURL(link, bizFilter, sliceQuery, timeRange.from.valueOf(), timeRange.to.valueOf());
      setIsViewEventsClicked(false);
      navigate(path, { newTab: true });
    },
    [bizFilter, navigate, sliceQuery, timeRange.from, timeRange.to]
  );

  const { notifyError } = useNotifications();
  const { isOpen, open, close } = useToggleState();

  const navigateOrOpenPopup = useCallback(() => {
    if (widgetTraceQueryLinks?.length) {
      if (widgetTraceQueryLinks.length > 1) {
        open();
      } else {
        navigateToEvents(widgetTraceQueryLinks?.[0]?.value);
      }
    } else {
      notifyError("No events found");
    }
  }, [navigateToEvents, notifyError, open, widgetTraceQueryLinks]);

  useEffect(() => {
    if (isViewEventsClicked && !isResultMetaFetching) {
      setTimeout(() => {
        navigateOrOpenPopup();
      }, 0);
    }
  }, [isResultMetaFetching, isViewEventsClicked, navigateOrOpenPopup]);

  const onViewEventsClick = useCallback(() => {
    setIsViewEventsClicked(true);
    refetch();
  }, [refetch]);

  const menuItems = useCallback(
    (props: IncMenuItemProps) => {
      const { closeMenu, className } = props;
      const items: JSX.Element[] = [];
      const onClick = (e: React.MouseEvent, link: string) => {
        closeMenu(e as any);
        close();
        navigateToEvents(link);
      };
      forEach(widgetTraceQueryLinks, link => {
        items.push(
          <div
            className={className}
            onClick={e => onClick(e, link.value)}
          >
            <span className="list-item">{link.label}</span>
          </div>
        );
      });
      return items;
    },
    [close, navigateToEvents, widgetTraceQueryLinks]
  );

  const toggle = useCallback((props: IncMenuToggleProps) => {
    const { show, toggleMenu } = props;
    if (!show) {
      toggleMenu(false);
    }
    return <></>;
  }, []);

  const viewEventsAction = useMemo<WidgetCustomAction>(
    () =>
      isResultMetaFetching
        ? {
            actionComponent: (
              <LoadingSpinner
                className="status-info"
                titleText=" "
              />
            ),
            showInHeader: true
          }
        : isOpen
          ? {
              actionComponent: (
                <>
                  <IncMenu
                    items={menuItems}
                    toggle={toggle}
                  />
                  Vraj
                </>
              ),
              showInHeader: true
            }
          : {
              actionComponent: (
                <div
                  className="inc-flex-row inc-flex-center-vertical"
                  onClick={onViewEventsClick}
                >
                  {isResultMetaFetching && <LoadingSpinner titleText="Fetching events.." />}
                  {!isResultMetaFetching && (
                    <>
                      <IncFaIcon iconName="list-ul" />
                      View events
                    </>
                  )}
                </div>
              )
            },
    [isOpen, isResultMetaFetching, menuItems, onViewEventsClick, toggle]
  );

  return viewEventsAction;
};
