import VariableModel from "../../models/VariableModel";
import {
  CatalogQueryConfig,
  CatalogWidgetImpl,
  CatalogWidgetModel,
  CatalogWidgetProperties,
  CatalogWidgetRenderMode
} from "../../widgets/Catalog/models";
import { BaseWidgetBuilder, WidgetBuilderCallbackResult } from "./BaseWidgetBuilder";

const catalogWidgetBuilder = (
  model: Partial<CatalogWidgetModel> = {}
): WidgetBuilderCallbackResult<CatalogWidgetModel, CatalogWidgetBuilder> => {
  const catalogModel = new CatalogWidgetImpl(model).getSafeDefaults() as CatalogWidgetModel;

  // Return a method that ensures a datasource is set before setting any other properties
  return {
    setDatasource: (dsName: string) => {
      catalogModel.datasource = dsName;

      return new CatalogWidgetBuilder(catalogModel);
    }
  };
};

export class CatalogWidgetBuilder<T extends CatalogWidgetModel = CatalogWidgetModel> extends BaseWidgetBuilder<T> {
  constructor(model: T) {
    super(model);
    this.model = model;
    this.model.properties = this.model.properties || ({} as any);
    this.model.options = this.model.options || {};
  }

  setUserserviceId(usId: string) {
    this.model.userServiceId = usId;
    return this;
  }

  setEntityType(entityType: string) {
    this.model.entityType = entityType;
    return this;
  }

  setBizEntityFieldName(bizEntityFieldName: string) {
    this.model.bizEntityFieldName = bizEntityFieldName;
    return this;
  }

  setQueryConfig(queryConfig: CatalogQueryConfig) {
    this.model.queryConfig = queryConfig;
    return this;
  }

  setWidgetConfigRefId(widgetConfigRefId: string) {
    this.model.widgetConfigRefId = widgetConfigRefId;
    return this;
  }

  setHideMappingControls(hideMappingControls: boolean) {
    this.model.hideMappingControls = hideMappingControls;
    return this;
  }

  setEntityLookUp(entityLookUp: Record<string, string>) {
    this.model.entityLookUp = entityLookUp;
    return this;
  }

  setEventTypeName(eventTypeName: string) {
    this.model.eventTypeName = eventTypeName;
    return this;
  }

  setVariables(variables: VariableModel[]) {
    this.model.variables = variables;
    return this;
  }

  setRenderMode(renderMode: CatalogWidgetRenderMode) {
    this.model.renderMode = renderMode;
    return this;
  }

  setProperties(properties: CatalogWidgetProperties) {
    this.model.properties = properties;
    return this;
  }

  setGenerateDemoData(generateDemoData: boolean) {
    this.model.generateDemoData = generateDemoData;
    return this;
  }

  setDisableUpdate(disableUpdate: boolean) {
    this.model.disableUpdate = disableUpdate;
    return this;
  }

  setHashLabelsOnSave(hashLabelsOnSave: boolean) {
    this.model.hashLabelsOnSave = hashLabelsOnSave;
    return this;
  }

  setMetricsVisualOrder(metricsVisualOrder: string[]) {
    this.model.metricsVisualOrder = metricsVisualOrder || [];
    return this;
  }
}

export default catalogWidgetBuilder;
