import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { IncFaIcon, IncModal, IncModalProps, IncSelect, IncSelectOption } from "@inception/ui";
import { BizDataQuery, Slice } from "../../../../services/api/explore";
import { KPIQueryEditor, VerticallyCenteredRow } from "../../../../components";
import { UberOperationalizeTypes } from "../../../../services/api/operationalise";
import { OpContext } from "../../../context/types";
import { ENTITY_TAG } from "../../../../utils";

type Props = {
  onClose: () => void;
  onSave: (bizDataQuery: BizDataQuery) => void;
  bizDataQuery: BizDataQuery;
  opBizDataQuery: BizDataQuery;
  isOpen: boolean;
  pickerContext: OpContext;
};

const DataQueryEditor: FC<Props> = props => {
  const { bizDataQuery: pBizDataQuery, isOpen, onClose, onSave: pOnSave, opBizDataQuery, pickerContext } = props;

  const { sliceSpec } = opBizDataQuery || {};
  const { sliceSet } = sliceSpec || {};
  const { slices: optionSlices = [] } = sliceSet || {};
  const { entityTypeName, eventTypeName } = pickerContext;

  const validStateRef = useRef({
    isValid: true,
    error: ""
  });
  const [bizDataQuery, setBizDataQuery] = useState<BizDataQuery>(pBizDataQuery);

  const { sliceSpec: selectedSliceSpec } = bizDataQuery || {};

  const { sliceSet: selectedSliceSet } = selectedSliceSpec || {};
  const { slices: selectedSlices = [] } = selectedSliceSet || {};

  const sliceOptions = useMemo<Array<IncSelectOption<Slice>>>(() => {
    const slices: Slice[] = optionSlices;
    return slices.map(slice => {
      const label = slice.tagName === ENTITY_TAG ? entityTypeName || eventTypeName : slice.tagName;
      return {
        label: label,
        value: slice.tagName,
        data: slice
      };
    });
  }, [entityTypeName, eventTypeName, optionSlices]);

  const selectedSliceOptions = useMemo<Array<IncSelectOption<Slice>>>(
    () =>
      selectedSlices.map(slice => {
        const label = slice.tagName === ENTITY_TAG ? entityTypeName || eventTypeName : slice.tagName;
        return {
          label: label,
          value: slice.tagName,
          data: slice
        };
      }),
    [entityTypeName, eventTypeName, selectedSlices]
  );

  const onChangeSlices = useCallback((selectedOptions: ReadonlyArray<IncSelectOption<Slice>>) => {
    setBizDataQuery(currentQuery => {
      const nQuery = {
        ...currentQuery,
        sliceSpec: {
          ...currentQuery.sliceSpec,
          sliceSet: {
            ...currentQuery?.sliceSpec?.sliceSet,
            slices: selectedOptions.map(option => option.data)
          }
        }
      };
      return nQuery;
    });
  }, []);

  const onSave = useCallback(() => {
    pOnSave(bizDataQuery);
    onClose();
  }, [bizDataQuery, onClose, pOnSave]);

  const actions = useMemo<IncModalProps["actions"]>(
    () => ({
      primary: {
        onClick: onSave,
        label: "Save",
        disabled: !validStateRef.current.isValid
      },
      secondary: {
        onClick: onClose,
        label: "Cancel"
      },
      postJSX: !validStateRef.current.isValid && (
        <VerticallyCenteredRow className="inc-text-subtext-medium flex-gap-8 status-danger">
          <IncFaIcon iconName="exclamation-triangle" />
          {validStateRef.current.error}
        </VerticallyCenteredRow>
      )
    }),
    [onClose, onSave]
  );

  const onChange = useCallback((isValid: boolean, errorsText: string, kpi: UberOperationalizeTypes.OpBizDataQuery) => {
    validStateRef.current = {
      isValid,
      error: errorsText
    };
    setBizDataQuery(kpi.bizDataQuery);
  }, []);

  const kpiQuery = useMemo<UberOperationalizeTypes.OpBizDataQuery>(
    () => ({
      bizDataQuery,
      rollingFreq: null,
      rollingFunction: null
    }),
    [bizDataQuery]
  );

  return (
    <IncModal
      actions={actions}
      className="data-query-editor"
      onClose={onClose}
      show={isOpen}
      size="lg"
      titleText={"Edit Query Info"}
      withActionsBorder
      withTitleBorder
    >
      {isOpen && (
        <div className="inc-flex-column flex-gap-12 width-100 height-100 paddingTp16 paddingBt16">
          <IncSelect
            isMulti
            label="Metrics"
            onChange={onChangeSlices}
            options={sliceOptions}
            value={selectedSliceOptions}
          />

          <KPIQueryEditor
            kpiQuery={kpiQuery}
            onStateChange={onChange}
          />
        </div>
      )}
    </IncModal>
  );
};

export default DataQueryEditor;
