import React, { FC, useMemo, useCallback } from "react";
import { getTimeZones, TimeZone } from "@vvo/tzdb";
import { BROWSER_TIME_ZONE, IncFaIcon, IncGroupSelectOption, IncSelect, IncSelectOption } from "@inception/ui";
import { sortBy } from "lodash";
import { IANA_DEPRECATED_TZ_MAP } from "../core";
import timeRangeUtils from "../utils/TimeRangeUtils";
import { VerticallyCenteredRow } from "./flex-components";

interface Props {
  timeZone: string; // IANA Timezone
  onChange: (ianaTimeZone: string, abbreviation: string) => void;
  includeUtc?: boolean;
  label?: string;
  skipIcon?: boolean;
  width?: string;
  alignment?: "row" | "column";
  autoAdjustWidth?: boolean;
  skipBrowserTimeZone?: boolean;
}

type Option = IncSelectOption<TimeZone>;

export const TimezoneSelector: FC<Props> = props => {
  const {
    onChange,
    timeZone,
    label,
    includeUtc = true,
    skipIcon = false,
    width,
    alignment = "row",
    autoAdjustWidth = true,
    skipBrowserTimeZone = false
  } = props;

  const browserTimeZone = timeRangeUtils.getBrowserTimeZone();

  const timeZoneOptions = useMemo(() => {
    const timeZoneOptions = getTimeZones({ includeUtc });
    return timeZoneOptions.map((tz): Option => {
      const { name, currentTimeFormat, alternativeName } = tz;
      const label = formatTimeZoneLabel(currentTimeFormat, name, alternativeName);
      return {
        label,
        value: name,
        data: tz
      };
    });
  }, [includeUtc]);

  const timeZoneGroupByOptions = useMemo(
    () => getGroupByOptions(timeZoneOptions, browserTimeZone, skipBrowserTimeZone),
    [timeZoneOptions, browserTimeZone, skipBrowserTimeZone]
  );

  const selTimeZoneOpt = useMemo(() => {
    const tz1 = timeZone;
    const tz2 = IANA_DEPRECATED_TZ_MAP[timeZone];
    return timeZoneOptions.find(tz => tz.value === tz1 || tz.value === tz2);
  }, [timeZoneOptions, timeZone]);

  const onOptChange = useCallback(
    (opt: Option) => {
      const { data, value } = opt;
      if (value === BROWSER_TIME_ZONE) {
        onChange(browserTimeZone, browserTimeZone);
      } else {
        const { name, abbreviation } = data;
        onChange(name, abbreviation);
      }
    },
    [browserTimeZone, onChange]
  );

  return (
    <VerticallyCenteredRow>
      {!skipIcon && (
        <IncFaIcon
          className="marginRt8 status-info"
          iconName="clock-rotate-left"
        />
      )}
      <IncSelect
        alignment={alignment}
        autoAdjustWidth={autoAdjustWidth}
        label={label}
        onChange={onOptChange}
        options={timeZoneGroupByOptions}
        value={selTimeZoneOpt}
        width={width}
        wrapperClass="timezone-selector"
      />
    </VerticallyCenteredRow>
  );
};

const formatTimeZoneLabel = (currentTimeFormat: string, name: string, timeZoneString: string) => {
  const isUTC = name.includes("UTC");
  const nameArr = currentTimeFormat?.split(" ");
  const offset = nameArr?.[0]?.trim();
  const gmtOffset = `(GMT ${offset})`;
  const cityName = name?.split("/")?.[1] || name?.split("/")?.[0];
  const cityString = `(${cityName?.trim()?.replaceAll("_", " ")})`;
  const labelString = `${gmtOffset} ${timeZoneString} ${cityString}`;

  const label = isUTC ? "UTC" : `${labelString}`;
  return label;
};

const getGroupByOptions = (timeZoneOptions: Option[], browserTimeZone: string, skipBrowserTimeZone: boolean) => {
  const grpOptions: Record<string, Option[]> = {};
  timeZoneOptions.forEach(tz => {
    const { value: name } = tz;
    const groupByKeyArray = name.split("/");
    const key = groupByKeyArray?.[0];
    grpOptions[key] = [...(grpOptions[key] || []), tz];
  });
  const groupByOptions: IncGroupSelectOption[] = [];
  Object.keys(grpOptions).forEach(key => {
    if (grpOptions?.[key]?.length) {
      const sortedTimeZones = sortBy(grpOptions[key], arg => arg?.data?.rawOffsetInMinutes);
      groupByOptions.push({
        groupLabel: key,
        options: sortedTimeZones
      });
    }
  });
  if (!skipBrowserTimeZone) {
    groupByOptions.push({
      groupLabel: "",
      options: [getBrowserTimeZoneOption(browserTimeZone)]
    });
  }
  return groupByOptions;
};

const getBrowserTimeZoneOption = (timeZone: string) => ({
  label: `Browser (${timeZone})`,
  value: BROWSER_TIME_ZONE
});
