import React, { useState, useMemo, useCallback } from "react";
import { IncModal, IncRadioButton, IncTextfield } from "@inception/ui";
import { IncButtonColor } from "@inception/ui/src/components/Button/Button";
import { startCase } from "lodash";
import { LimitSpecFunction } from "../../../../../../services/api/explore";
import { VerticallyCenteredRow } from "../../../../../../components";
import { MAX_INT32 } from "../../../../../../utils";

interface Props {
  show: boolean;
  defaultFunction: LimitSpecFunction;
  initialValue: number;
  fileName?: string;
  onClose: () => void;
  onConfirmClick: (func: LimitSpecFunction, value: number, fileName: string) => void;
}

export const DownloadCSVModel: React.FC<Props> = (props: Props) => {
  const { show, defaultFunction, initialValue, onClose, onConfirmClick, fileName: pFileName } = props;
  const [value, setValue] = useState(initialValue || "");
  const [fileName, setFileName] = useState(pFileName);
  const [selectedRadioBtn, setSelectedRadioBtn] = useState<"custom" | "all">("custom");

  const actions = useMemo(() => {
    const intVal = selectedRadioBtn === "custom" ? Number(value) : MAX_INT32;
    return {
      primary: {
        id: "common.actions.confirm",
        onClick: () => onConfirmClick(defaultFunction, intVal, fileName),
        color: "primary" as IncButtonColor
      },
      secondary: {
        id: "common.actions.close",
        onClick: () => onClose(),
        color: "link" as IncButtonColor
      }
    };
  }, [defaultFunction, fileName, onClose, onConfirmClick, selectedRadioBtn, value]);

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
  };

  const labelContainer = useMemo(
    () => (
      <VerticallyCenteredRow>
        <span>{startCase(defaultFunction)}</span>
        <IncTextfield
          className="textfield marginLt8"
          onChange={onValueChange}
          type={"number"}
          value={value}
        />
      </VerticallyCenteredRow>
    ),
    [defaultFunction, value]
  );
  /**
   * set file name
   */
  const onFileNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value);
  }, []);

  const onCustomRadioBtnClicked = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setSelectedRadioBtn("custom");
    }
  }, []);

  const onAllClick = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setSelectedRadioBtn("all");
    }
  }, []);

  return (
    <IncModal
      actions={actions}
      onClose={onClose}
      show={show}
      size="sm"
      titleText="Select"
    >
      <VerticallyCenteredRow className="marginBt10  inc-text-body-medium">
        <IncTextfield
          containerClassName="d-flex flex-row flex-gap-10"
          label="File Name:"
          onChange={onFileNameChange}
          required
          value={fileName}
        />
        <span className="d-flex align-middle text-muted">.xlsx</span>
      </VerticallyCenteredRow>
      <VerticallyCenteredRow className="marginBt10 inc-text-body-medium">
        <IncRadioButton
          checked={selectedRadioBtn === "custom"}
          label={labelContainer}
          onChange={onCustomRadioBtnClicked}
        />
        <IncRadioButton
          checked={selectedRadioBtn === "all"}
          className="marginLt16"
          label="All"
          onChange={onAllClick}
        />
      </VerticallyCenteredRow>
    </IncModal>
  );
};
