import { IncFaIcon, IncMenuItem, IncMenuV2 } from "@inception/ui";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { LoadingSpinner, VerticallyCenteredRow } from "../../components";
import EditableLabelInput from "../../components/editable-input-label/EditableInputLabel";
import { logger, useFetchDashboards, useInceptionRoute } from "../../core";

interface Props {
  onNameChange?: (name: string) => void;
  onSaveNameChange?: (name: string) => void;
  allowNameChange?: boolean;

  dashboardId: string;
  dashboardName: string;

  onNavigate: (url: string) => void;
}

export const DashToolbarBreadCrumbs: FC<Props> = props => {
  const { dashboardId, dashboardName, allowNameChange, onNameChange, onSaveNameChange, onNavigate } = props;

  const { getRoute } = useInceptionRoute();

  const {
    data: dashboardItems,
    error: dashboardItemsError,
    isError: isDashboardItemsError,
    isFetching: isDashboardsFetching
  } = useFetchDashboards();

  useEffect(() => {
    if (isDashboardItemsError) {
      logger.error("Dashboard breadcrumbs", "Error fetching dashboards", dashboardItemsError);
    }
  }, [dashboardItemsError, isDashboardItemsError]);

  const menuItems = useMemo<IncMenuItem[]>(() => {
    const menuItems: IncMenuItem[] = [];

    dashboardItems.forEach(dbItem => {
      const { id, name } = dbItem;

      if (id !== dashboardId) {
        menuItems.push({
          label: name,
          value: id,
          data: dbItem
        });
      }
    });

    return menuItems;
  }, [dashboardId, dashboardItems]);

  const onNavigateInternal = useCallback(
    (url: string) => {
      const route = getRoute(url);
      onNavigate(route);
    },
    [getRoute, onNavigate]
  );

  const onItemClick = useCallback(
    (menuItem: IncMenuItem) => {
      const { value: dbId } = menuItem;
      const route = `/dashboards/edit/${dbId}`;
      onNavigateInternal(route);
    },
    [onNavigateInternal]
  );

  const onHomeClick = useCallback(() => {
    onNavigateInternal("/home");
  }, [onNavigateInternal]);

  const onDbListClick = useCallback(() => {
    onNavigateInternal("/dashboards/list");
  }, [onNavigateInternal]);

  const menuLabelClassName = isDashboardsFetching ? "readonly" : "";
  const menuLabel = useMemo(
    () =>
      isDashboardsFetching ? (
        <LoadingSpinner titleText=" " />
      ) : (
        <IncFaIcon
          className="marginLt8"
          iconName="angle-down"
        />
      ),
    [isDashboardsFetching]
  );

  return (
    <VerticallyCenteredRow className="dashboard-breadcrumbs">
      <div
        className="dashboard-breadcrumbs--text-entry"
        onClick={onHomeClick}
      >
        Home
      </div>

      <div className="dashboard-breadcrumbs--separator">
        <IncFaIcon iconName="angle-right" />
      </div>

      <div
        className="dashboard-breadcrumbs--text-entry"
        onClick={onDbListClick}
      >
        Dashboards
      </div>

      <div className="dashboard-breadcrumbs--separator">
        <IncFaIcon iconName="angle-right" />
      </div>

      <div className="dashboard-breadcrumbs--entry">
        <EditableLabelInput
          change={onNameChange}
          editingDisabled={!allowNameChange}
          initialValue={dashboardName}
          save={onSaveNameChange}
        />

        {!isDashboardItemsError && (
          <IncMenuV2
            label={menuLabel}
            menuItems={menuItems}
            menuLabelClassName={menuLabelClassName}
            onMenuItemClick={onItemClick}
          />
        )}
      </div>
    </VerticallyCenteredRow>
  );
};
