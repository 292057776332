import { IncFaIcon, IncFaIconName } from "@inception/ui";
import React, { FC, memo, useMemo } from "react";
import { InsightMemberShipResponse } from "../../services/api/operationalise";
import { dataTypeManager } from "../../utils";
import { VerticallyCenteredRow } from "../flex-components";
import { getChangePercent } from "../../core";
import { getStatusClassNameForSingleStat } from "../insight-card";

interface Props {
  insightMembershipData: InsightMemberShipResponse;
  durationLabel: string;
  isLoading?: boolean;
}

export const InsightStats: FC<Props> = memo(props => {
  const { insightMembershipData, durationLabel: pDurationLabel, isLoading } = props;

  const {
    impactData,
    data: dataStr,
    timeshiftData,
    isSpike,
    isSpikePositive,
    displayDataType
  } = useMemo<InsightMemberShipResponse>(() => {
    if (isLoading) {
      return {
        data: 35000,
        impactData: 35000,
        isSpike: true,
        isSpikePositive: true,
        timeshiftData: 38000,
        impactDataStr: "35,000",
        incidentSeriesCount: 30,
        metricName: "Spike in total drop of orders",
        totalSeriesCount: 50,
        displayDataType: "NUMBER"
      };
    }

    return insightMembershipData || ({} as InsightMemberShipResponse);
  }, [insightMembershipData, isLoading]);

  const durationLabel = isLoading ? "Since 1 hour ago" : pDurationLabel;

  const subType = !displayDataType || (displayDataType as any) === "NA" ? "DOUBLE" : displayDataType;
  const data = parseFloat(String(dataStr));
  const formattedData = dataTypeManager.getDataTypeDescriptor(subType).getFormattedValue(data, {
    durationFormatOptions: {
      maxTerms: 2,
      precision: null
    }
  });
  const { changePercent } = timeshiftData
    ? data === timeshiftData
      ? {
          changePercent: 0
        }
      : getChangePercent(data, timeshiftData)
    : {
        changePercent: Math.round((impactData / data) * 100)
      };

  const impactDataStr = dataTypeManager.getDataTypeDescriptor(subType).getFormattedValue(impactData, {
    durationFormatOptions: {
      maxTerms: 2,
      precision: null
    }
  });
  const formattedImpactData = `${impactDataStr} (${dataTypeManager.getDataTypeDescriptor("PERCENT_100").getFormattedValue(changePercent)})`;

  const className = isLoading ? "" : getStatusClassNameForSingleStat(isSpike, isSpikePositive);
  const trendIcon: IncFaIconName = isSpike ? "caret-up" : "caret-down";

  return (
    <div
      className="inc-flex-column"
      style={{ minWidth: "140px" }}
    >
      <VerticallyCenteredRow className="flex-gap-8">
        <span
          className="inc-flex-row inc-flex-center-vertical marginRt4 inc-text-lead-bold"
          data-show-loader={isLoading}
        >
          {formattedData === "NaN" ? "-" : formattedData}
        </span>

        {formattedImpactData !== "NaN" && (
          <>
            {formattedImpactData !== "0" && (
              <IncFaIcon
                className={className}
                data-show-loader={isLoading}
                iconName={trendIcon}
                style={{ fontSize: 14 }}
              />
            )}

            <span
              className={`${className} inc-flex-row inc-flex-center-vertical inc-text-body-bold`}
              data-show-loader={isLoading}
            >
              {formattedImpactData}
            </span>
          </>
        )}

        <span
          className="inc-text-inactive inc-text-subtext-medium"
          data-show-loader={isLoading}
        >
          {durationLabel}
        </span>
      </VerticallyCenteredRow>
    </div>
  );
});
