import { isEmpty } from "lodash";
import { HighChartsFragment } from "../../services/api/chat";

export const isValidHighchartsData = (highchartsFragment: HighChartsFragment) => {
  const { options } = highchartsFragment || {};
  if (!isEmpty(options)) {
    const series = (options?.series || []).filter(s => (s as any)?.data?.length > 0) || [];
    return series.length > 0;
  }
  return false;
};
