import { defaults } from "lodash";
import { VariableResolverResult } from "../dashboard-variables/resolvers/CustomVariableResolver";
import { ConstantVariableModel, VariableType } from "../models/VariableModel";
import { templateSrv } from "../variables";
import { TimeRange } from "../../core";
import { ScopedVars } from "../../services/api/types";
import VariableImpl from "./VariableImpl";

const DEFAULTS: ConstantVariableModel = {
  id: "",
  name: "",
  multi: false,
  value: null,
  defaultValue: "",
  type: VariableType.Constant,
  allValue: "",
  includeAll: false,
  label: "",
  hide: true,
  query: ""
};

export default class ConstantVariableImpl extends VariableImpl implements ConstantVariableModel {
  query: string; // Controls the user defined string
  value: string; // Stores the actual value after resolving dependencies

  readonly type = VariableType.Constant;

  constructor(model: Partial<ConstantVariableModel>) {
    super(model);
    this.assign(model);
  }

  protected assign(model: Partial<ConstantVariableModel>) {
    const cvModel: Partial<ConstantVariableModel> = {};
    defaults(cvModel, model, DEFAULTS);
    const { id, query } = cvModel;

    this.id = id;
    this.query = query;

    super.assign(cvModel);
    this.helpText = "Use a constant string";
    this.setDependencies();
  }

  update(vModel: Partial<ConstantVariableModel>) {
    this.assign(vModel);
  }

  setQuery(query: string) {
    this.query = query;
    this.setDependencies();
  }

  setDefaultValue(value: string) {
    this.value = value;
    this.defaultValue = value;
  }

  private setValue(value: string) {
    this.setDefaultValue(value);
  }

  resolveValues(tr: TimeRange, dependencyValues: ScopedVars = {}): Promise<VariableResolverResult> {
    const value = templateSrv.replace(this.query, dependencyValues, "pipe");
    if (value) {
      this.setValue(value);
      this.optionsData = {
        error: "",
        data: [value]
      };
      return Promise.resolve({
        ...this.optionsData
      });
    } else {
      return Promise.resolve({
        error: "Empty or invalid value",
        data: []
      });
    }
  }

  private setDependencies() {
    this.dependencies = this.processMatches(this.query);
  }

  validate() {
    const base = super.validate();
    let hasError = false;
    const { messages } = base;

    if (!this.query) {
      hasError = true;
      messages["query"] = "Query cannot be empty";
    }

    return {
      hasError: hasError || base.hasError,
      messages
    };
  }

  getSaveModel(): ConstantVariableModel {
    const model = super.getSaveModel();
    return model as ConstantVariableModel;
  }
}
