import { IncButton, IncFaIcon } from "@inception/ui";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FieldPickerPopUpV2 } from "../../../components/field-picker/FieldPickerPopUpV2";
import { useTimeRange, useToggleState } from "../../../core";
import { FieldPickerModel } from "../../../field-picker";
import {
  BizEntityType,
  UserServiceField,
  UserServiceFilterExpression,
  UserServiceTuple
} from "../../../services/api/explore";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../components";

interface Props {
  onAddFilter: (filterExpr: UserServiceFilterExpression) => void;
  onAddFilterTree: () => void;

  isMetaLoading?: boolean;
  userServices?: UserServiceTuple[];
}

export const AddFilter: React.FC<Props> = (props: Props) => {
  const { isMetaLoading = false, userServices, onAddFilter, onAddFilterTree } = props;

  const { isOpen: isOpenFieldsPopUp, toggle: toggleFieldsPopUp } = useToggleState();

  const [fieldPickerModel, setFieldPickerModel] = useState<FieldPickerModel>(null);
  const [bizEntityTypes, setBizEntityTypes] = useState<BizEntityType[]>(null);

  const { timeRange } = useTimeRange();
  useEffect(() => {
    setFieldPickerModel(null);
  }, [timeRange]);

  const anchorEl = useRef();

  const onSelect = useCallback(
    (field: UserServiceField) => {
      onAddFilter({
        field: field,
        operator: "",
        value: "",
        values: []
      });
      toggleFieldsPopUp();
    },
    [onAddFilter, toggleFieldsPopUp]
  );

  return (
    <>
      <div ref={anchorEl}>
        <IncButton
          color="secondary-blue"
          disabled={isMetaLoading}
          onClick={toggleFieldsPopUp}
        >
          {!isMetaLoading && (
            <>
              <IncFaIcon
                className="marginRt4"
                iconName="filter"
              />
              Add Filter
            </>
          )}
          {isMetaLoading && <LoadingSpinner titleText="Add Filter" />}
        </IncButton>
      </div>

      {isOpenFieldsPopUp && (
        <FieldPickerPopUpV2
          anchorEl={anchorEl.current}
          bizEntityTypes={bizEntityTypes}
          fieldPickerModel={fieldPickerModel}
          isMetaLoading={isMetaLoading}
          onBizEntityTypesFetched={setBizEntityTypes}
          onClose={toggleFieldsPopUp}
          onFieldsFetched={setFieldPickerModel}
          onSelect={onSelect}
          show={isOpenFieldsPopUp}
          userServices={userServices}
        >
          {!isMetaLoading && (
            <VerticallyCenteredRow
              className="paddingTp12"
              style={{ borderTop: "1px solid #4A505C" }}
            >
              <IncButton
                className="inc-text-subtext-medium"
                color="link"
                label="Add Advanced Filter"
                onClick={onAddFilterTree}
                size="small"
              />
            </VerticallyCenteredRow>
          )}
        </FieldPickerPopUpV2>
      )}
    </>
  );
};
