import { IncFaIcon, IncPill, IncToolTip } from "@inception/ui";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { VerticallyCenteredRow } from "../../../../components";
import { useToggleState } from "../../../../core";
import { FieldPickerOptionData, UserServiceFilterExpression } from "../../../../services/api/explore";
import { FieldPickerUtils, isCohortField } from "../../../../utils";
import { useFetchEntityLookUp } from "../hooks";
import { EventFieldNumericRangePopper } from "./EventFieldNumericRangePopper";
import { EventFieldValuePopper } from "./EventFieldValuesPopper";

interface EVFVContainerProps {
  filterExpr: UserServiceFilterExpression;
  onChange: (selectedValues: string[], op: string) => void;
  onDelete: () => void;
  onToggleLock: () => void;
  canEdit?: boolean;
  isInternalUser?: boolean;
  isLocked?: boolean;
}

export const EventFilterValuesContainer: React.FC<EVFVContainerProps> = (props: EVFVContainerProps) => {
  const { filterExpr, onChange, onDelete, onToggleLock, canEdit = false, isLocked = false } = props;

  const { field, value, values, operator } = filterExpr || {};
  const { isOpen, close, open } = useToggleState();
  const anchorEl = useRef(null);

  const selectedValues = useMemo(() => (value ? [value] : values), [value, values]);
  const { data, isFetching, refetch } = useFetchEntityLookUp(selectedValues);
  const { entityLookupData = {} } = data || {};

  useEffect(() => {
    if (FieldPickerUtils.isEntityField(field) || isCohortField(field)) {
      refetch();
    }
  }, [field, refetch]);

  const fieldLabel = useMemo(() => FieldPickerUtils.getUserServiceFieldLabel(field), [field]);
  const isNumericDataType = useMemo(
    () =>
      (field?.dataType === "LONG" ||
        field?.dataType === "DOUBLE" ||
        field?.dataType === "NUMBER" ||
        field?.entityField?.propType === "DOUBLE" ||
        field?.entityField?.propType === "LONG" ||
        field?.entityField?.propType === "NUMBER") &&
      !fieldLabel?.toUpperCase()?.includes("ID"),
    [field, fieldLabel]
  );

  const valuesContext = useMemo<FieldPickerOptionData>(() => {
    if (field) {
      return {
        payload: field,
        type: "userServiceField"
      };
    }
    return null;
  }, [field]);

  const onApplyChanges = useCallback(
    (selectedValues: string[]) => {
      const op = selectedValues?.length > 1 ? "in" : "=";
      onChange(selectedValues, op);
      close();
    },
    [close, onChange]
  );

  const onApplyNumberChange = useCallback(
    (selectedValues: string[], op: string) => {
      onChange(selectedValues, op);
      close();
    },
    [close, onChange]
  );

  const numericValueLabel = useMemo(() => {
    if (operator === "range") {
      return `between ${selectedValues[0]} and ${selectedValues[1]}`;
    } else if (operator === "<") {
      return `< ${selectedValues[0]}`;
    } else if (operator === ">") {
      return `> ${selectedValues[0]}`;
    } else {
      return selectedValues[0];
    }
  }, [operator, selectedValues]);

  const valuesJsx = useMemo(() => {
    const labels = selectedValues.map(x => (entityLookupData[x] ? entityLookupData[x] : x));
    const valsToShow = labels.slice(0, 2);
    const remVals = selectedValues.length - 2;
    const pills = valsToShow.map(x => (
      <IncPill
        className="pill-item"
        key={x}
        label={x}
        labelClass={"text-ellipsis"}
        onClick={open}
      />
    ));
    const remValues =
      remVals > 0 ? (
        <IncPill
          className={"pill-item"}
          label={`+ ${remVals} more`}
          labelClass={"text-ellipsis"}
          onClick={open}
        />
      ) : null;

    if (isNumericDataType) {
      return numericValueLabel;
    } else {
      return (
        <>
          {pills} {remValues}
        </>
      );
    }
  }, [selectedValues, open, isNumericDataType, entityLookupData, numericValueLabel]);

  const handleOpenFilterPopper = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      open();
    },
    [open]
  );

  const handleToggleLock = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onToggleLock();
    },
    [onToggleLock]
  );

  return (
    <>
      <VerticallyCenteredRow
        className="values-container paddingLt4 flex-gap-6"
        onClick={handleOpenFilterPopper}
        ref={anchorEl}
      >
        <VerticallyCenteredRow className="inc-flex-row flex-gap-2">
          {isFetching ? <span className="inc-text-subtext">Loading...</span> : valuesJsx}
        </VerticallyCenteredRow>
        <div className="marginLtAuto" />
        {(canEdit || !isLocked) && (
          <IncFaIcon
            className="inc-cursor-pointer marginRt4"
            iconName="caret-down"
          />
        )}
        {!canEdit && isLocked && (
          <IncToolTip titleText="Locked filters will be read-only for Viewers and hidden for Guest users">
            <IncFaIcon
              className="lock-btn-containter inc-cursor-pointer marginRt4"
              iconName="lock"
              style={{ fontSize: 12 }}
            />
          </IncToolTip>
        )}
        {canEdit && (
          <IncToolTip titleText="Locked filters will be read-only for Viewers and hidden for Guest users">
            <IncFaIcon
              className="lock-btn-containter inc-cursor-pointer"
              iconName={isLocked ? "lock" : "lock-open"}
              onClick={handleToggleLock}
              style={{ fontSize: 12 }}
            />
          </IncToolTip>
        )}

        {canEdit && (
          <IncFaIcon
            className="close-btn-containter inc-cursor-pointer"
            iconName="xmark"
            onClick={onDelete}
          />
        )}
      </VerticallyCenteredRow>
      {isOpen && !isNumericDataType && valuesContext && (
        <EventFieldValuePopper
          anchorEl={anchorEl.current}
          context={valuesContext}
          onChange={onApplyChanges}
          onClose={close}
          selectedValues={selectedValues}
          show={isOpen}
          showCohortSection={false}
          tag=""
        />
      )}
      {isOpen && isNumericDataType && (
        <EventFieldNumericRangePopper
          anchorEl={anchorEl.current}
          field={field}
          onChange={onApplyNumberChange}
          onClose={close}
          op={operator}
          show={isOpen}
          values={selectedValues}
        />
      )}
    </>
  );
};
