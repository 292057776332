import React, { useState, useCallback, useMemo } from "react";
import { IncFaIcon, IncModal } from "@inception/ui";
import BaseWidgetImpl from "../../../model-impl/BaseWidgetImpl";
import { useAccessPrivilege, generateId } from "../../../../core";
import { PartialWidgetLayout } from "../../../models";
import { AddToDashboardModal } from "./AddToDashboardAction";

const defaultActionElement = (
  <>
    <IncFaIcon iconName="share-square" />
    Add to Dashboards
  </>
);

export const useAddToDashboardAction = <T extends BaseWidgetImpl>(
  widgets: T | T[],
  activeDashboardId = "",
  actionElement = defaultActionElement,
  onClose?: () => void,
  layouts?: PartialWidgetLayout | PartialWidgetLayout[]
) => {
  const { canEdit } = useAccessPrivilege();

  const [open, setOpen] = useState(false);

  const openModal = useCallback(() => setOpen(true), []);

  const closeModal = useCallback(() => {
    setOpen(false);
    onClose && onClose();
  }, [onClose]);

  const addToDashboardAction = useMemo(
    () => (
      <div
        className="inc-flex-row inc-flex-center-vertical"
        key={`${generateId()}-add-to-dashboard-action`}
        onClick={openModal}
      >
        {actionElement}
      </div>
    ),
    [actionElement, openModal]
  );

  const widgetsArr = useMemo(() => (Array.isArray(widgets) ? widgets : [widgets]), [widgets]);
  const layoutsArr = useMemo(() => (Array.isArray(layouts) ? layouts : [layouts]), [layouts]);

  const addToDashboardActionModal = useMemo(
    () => (
      <IncModal
        className="paddingBt24"
        onClose={closeModal}
        show={open}
        size="md"
        titleText="Add to dashboards"
      >
        <AddToDashboardModal
          activeDashboardId={activeDashboardId}
          layouts={layoutsArr}
          onAddSuccess={closeModal}
          widgets={widgetsArr}
        />
      </IncModal>
    ),
    [activeDashboardId, closeModal, layoutsArr, open, widgetsArr]
  );

  return canEdit
    ? {
        addToDashboardAction,
        addToDashboardActionModal,
        openCallback: openModal,
        closeCallback: closeModal,
        isAddToDashboardModalOpen: open
      }
    : {
        addToDashboardAction: null,
        addToDashboardActionModal: null,
        openCallback: null,
        closeCallback: null,
        isAddToDashboardModalOpen: null
      };
};
