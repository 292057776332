import {
  formatNumber,
  getFormattedDateTime,
  getInceptionTheme,
  HighChartsNumberFormatter,
  IncDateTimeFormat
} from "@inception/ui";
import { Point, SeriesLineOptions, XAxisOptions, YAxisOptions } from "highcharts";
import { isNumber, merge } from "lodash";
import { TimeRange, TimeZone } from "../../core";
import { TimeSeriesTooltipProperties } from "../../dashboard/widgets/TimeSeries/models/model";
import { MAX_NUM_SERIES_DISPLAYED } from "../../dashboard/widgets/TimeSeries/models/TimeSeriesUtils";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { FONT_STYLE } from "../charts";
import { syncExtremes } from "../charts/SynchronousTooltip";
import { ascendingComparator, getXAxisLabelsAndFormat } from "../time-series/ChartOptionsBuilder";
import { IncTimeSeriesOptions } from "../time-series/types";

const LegendSeriesMaxLength = 100;

export default class SparkLineOptionsBuilder {
  private chartOptions: Highcharts.Options = {};

  constructor(options?: Highcharts.Options) {
    // Use constructor to set basic options
    this.chartOptions.chart = {
      backgroundColor: null,
      borderWidth: 0,
      type: "area",
      margin: [0, 0, 12, 0],
      style: {
        overflow: "visible"
      }
    };

    this.chartOptions.title = {
      text: ""
    };

    this.chartOptions.credits = {
      enabled: false
    };

    this.chartOptions.xAxis = {
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      startOnTick: false,
      endOnTick: false,
      tickPositions: []
    };

    this.chartOptions.yAxis = {
      endOnTick: false,
      startOnTick: false,
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      tickPositions: [0]
    };

    this.chartOptions.legend = {
      enabled: false
    };

    this.chartOptions.boost = {
      enabled: true,
      allowForce: true,
      seriesThreshold: MAX_NUM_SERIES_DISPLAYED + 1, // for some reason +1 is required else all lines charts are of same color. TODO: Figure out why
      usePreallocated: true
    };

    this.chartOptions.plotOptions = {
      series: {
        animation: false,
        lineWidth: 1,
        shadow: false,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        marker: {
          radius: 1,
          states: {
            hover: {
              radius: 2
            }
          }
        }
      },
      area: {
        fillOpacity: 0.25
      }
    };

    if (options) {
      this.chartOptions = merge(this.chartOptions, options);
    }
  }

  setSeriesData(series: IncTimeSeriesOptions[]): SparkLineOptionsBuilder {
    if (this.chartOptions) {
      this.chartOptions.series = [...(series || [])];
    }

    if (this.chartOptions.series.length <= 30) {
      this.chartOptions.series.forEach(s => {
        if (s.type === "line" || s.type === "spline") {
          (s as SeriesLineOptions).lineWidth = 2;
        }
      });
    }

    return this;
  }

  setXAxis(
    title?: string,
    timeRange?: TimeRange,
    intervalSecs?: number,
    axisOverrides?: XAxisOptions | XAxisOptions[]
  ): SparkLineOptionsBuilder {
    const {
      includeSeconds,
      labelFormat: xAxisLabelFormat,
      labels: xAxisLabels
    } = getXAxisLabelsAndFormat(timeRange, this.chartOptions.chart.width as number, intervalSecs);

    const xAxisOptions: XAxisOptions = merge(
      {
        // startOnTick: true,
        // ordinal: false,
        // endOnTick: true,
        type: "datetime",
        grid: {
          enabled: false
        },
        title: {
          text: title,
          style: {
            color: getInceptionTheme().plugins.graph.legendTextHover,
            marginTop: 16
          }
        },
        tickPositioner: function (this) {
          // Cloning this to ensure the labels array is not mutated after the zoom behaviour
          return [...xAxisLabels];
        },
        labels: {
          enabled: false,
          align: "center",
          style: FONT_STYLE,
          x: 0,
          formatter: that => {
            const value = parseInt(that.value.toString(), 10) * 1000;
            return getFormattedDateTime(value, xAxisLabelFormat, {
              withSeconds: includeSeconds
            });
          }
        },
        events: {
          setExtremes: (this.chartOptions as any).syncTooltip ? syncExtremes : null
        }
      } as XAxisOptions,
      this.chartOptions.xAxis || {}
    );

    if (timeRange?.from && timeRange.to) {
      xAxisOptions.min = timeRangeUtils.getSecondsFromMillis(timeRange.from.valueOf());
      xAxisOptions.max = timeRangeUtils.getSecondsFromMillis(timeRange.to.valueOf());
    }

    this.chartOptions.xAxis = merge(xAxisOptions, axisOverrides) as XAxisOptions[];
    return this;
  }

  setYAxis(
    maxValue: number,
    axisOverrides?: YAxisOptions | YAxisOptions[],
    allowDecimals = true
  ): SparkLineOptionsBuilder {
    const defaultYAxis: YAxisOptions[] = [
      {
        id: "yAxis1",
        startOnTick: false,
        endOnTick: false,
        gridLineWidth: 1,
        gridLineColor: getInceptionTheme().inceptionColors.palette.BL400,
        visible: false,
        //tickAmount: 4,   // hide the axis to prevent double axis
        tickColor: getInceptionTheme().plugins.graph.legendText,
        title: {
          text: ""
        },
        tickPositions: [0],
        maxRange: maxValue,
        opposite: false,
        allowDecimals,
        labels: {
          enabled: false,
          style: FONT_STYLE,
          formatter: function (this) {
            if (isNumber(this.value)) {
              return formatNumber(this.value);
            }
            return this.value;
          }
        }
      }
    ];

    this.chartOptions.yAxis = merge(defaultYAxis, axisOverrides) as YAxisOptions[];

    return this;
  }

  setChartFields(width: number, height: number) {
    this.chartOptions.chart = {
      height,
      width,
      backgroundColor: "transparent",
      spacingBottom: 0,
      numberFormatter: HighChartsNumberFormatter
    };
    return this;
  }

  setPlotOptions(disableInteractions: boolean): SparkLineOptionsBuilder {
    if (disableInteractions) {
      this.chartOptions.plotOptions.series.enableMouseTracking = false;
      if (!this.chartOptions.plotOptions.series.events) {
        this.chartOptions.plotOptions.series.events = {};
      }

      this.chartOptions.plotOptions.series.events.legendItemClick = () => false;
    }

    return this;
  }

  setToolTip(
    shiftedTime: number,
    tooltipProperties: TimeSeriesTooltipProperties,
    disabled: boolean,
    timeZone?: TimeZone
  ): SparkLineOptionsBuilder {
    const { formatter, valueFomatter, shared, sortBy } = tooltipProperties || {};

    this.chartOptions.tooltip = {
      enabled: !disabled,
      shared: false,
      //followPointer: true,
      outside: true,
      useHTML: true,
      formatter:
        formatter ??
        function () {
          let points: Point[] = [];
          if (this.points) {
            points = this.points.map(p => p.point);
          } else if (this.point) {
            // When shared tooltip = false, we only get 1 point.
            points.push(this.point);
          } else {
            return "";
          }

          let timestampMs: number = (this.x as number) * 1000;
          const timeStampStr = getFormattedDateTime(
            timestampMs,
            IncDateTimeFormat.full,
            { withSeconds: true },
            timeZone
          );
          let finalStr = `<div class='inc-charts-tooltip'>
          <div class='inc-charts-tooltip-datetime'>${timeStampStr}</div>
        `;

          const sortedPoints = points;

          // If we are having tooltip in shared mode, obey the sort order
          if (shared && sortBy) {
            // If we have specified some sort order, sort by ascending order.
            // If sort by descending, just reverse the ascendingly sorted array.
            if (tooltipProperties.sortBy !== "none") {
              sortedPoints.sort(ascendingComparator);
              if (tooltipProperties.sortBy === "desc") {
                sortedPoints.reverse();
              }
            }
          }

          for (let i = 0; i < sortedPoints.length; ++i) {
            const point = sortedPoints[i];
            const { series } = sortedPoints[i];

            const { name, type, options } = series;
            if (name.indexOf("Comparison: ") !== -1) {
              timestampMs = (this.x as number) - shiftedTime;
            }
            let seriesName = name;

            if (seriesName.length > LegendSeriesMaxLength) {
              seriesName = `${seriesName.substr(0, LegendSeriesMaxLength)}...`;
            }

            const formatValue = (value: number) =>
              valueFomatter ? valueFomatter(value, options?.custom) : formatNumber;
            const { y, high, low } = point.options;

            if (type === "arearange") {
              const max = !isNaN(high) ? formatValue(+(high || 0)) : "-NA-";
              const min = !isNaN(low) ? formatValue(+(low || 0)) : "-NA-";
              finalStr += `<div class='inc-charts-tooltip-series-row'>
              <div class="inc-highcharts-square-symbol" style="background-color:${point.color};"></div>
              <div class='inc-charts-tooltip-series-name'>${seriesName} (lower):</div> 
              <div class='inc-charts-tooltip-series-value'><span>${min}</span></div>
            </div>`;
              finalStr += `<div class='inc-charts-tooltip-series-row'>
              <div class="inc-highcharts-square-symbol" style="background-color:${point.color};"></div>
              <div class='inc-charts-tooltip-series-name'>${seriesName} (upper):</div> 
              <div class='inc-charts-tooltip-series-value'><span>${max}</span></div>
            </div>`;
            } else {
              const value = y ? formatValue(+y.toFixed(3)) : "-";
              finalStr += `<div class='inc-charts-tooltip-series-row'>
              <div class="inc-highcharts-square-symbol" style="background-color:${point.color};"></div>
              <div class='inc-charts-tooltip-series-name'>${seriesName}</div> 
              <div class='inc-charts-tooltip-series-value'><span>${value}</span></div>
            </div>`;
            }
          }

          return `${finalStr}</div>`;
        }
    };

    if (tooltipProperties) {
      this.chartOptions.tooltip.shared = tooltipProperties.shared;
    }

    return this;
  }

  setTitle(title: string): SparkLineOptionsBuilder {
    this.chartOptions.title = {};
    this.chartOptions.title.text = title;
    return this;
  }

  clearSeriesData(): SparkLineOptionsBuilder {
    this.chartOptions.series = [];
    return this;
  }

  setBackgroundColor(backgroundColor: string): SparkLineOptionsBuilder {
    this.chartOptions.chart.backgroundColor = backgroundColor;
    return this;
  }

  build(): Highcharts.Options {
    return {
      ...this.chartOptions
    };
  }
}
