import React, { FC, useCallback, useMemo } from "react";

import { duration } from "moment";
import { ComputedCell } from "@nivo/heatmap";
import { IncSmartText } from "@inception/ui";
import {
  HeatMapV2,
  IncHeatMapAxisTickPropsFunction,
  IncHeatMapTooltipFormatter
} from "../../../../components/heat-map-v2";
import { getHeatMapEntries, getTileColorByCount } from "./utils";
import { ExtAlertCountResponseEntry, ResultsHeatMapDatum } from "./types";
import { OneDimensionalHeatMap } from "./OneDimensionalHeatMap";

interface Props {
  data: ExtAlertCountResponseEntry[];
  startTimeMillis: number;
  endTimeMillis: number;
  timeIntervalMillis: number;

  isTwoDimensional?: boolean;
  getColorByValue?: (value: number) => string;
  showOpNameInTooltip?: boolean;
}

export const ResultsHeatMapV2: FC<Props> = props => {
  const {
    data,
    endTimeMillis,
    startTimeMillis,
    timeIntervalMillis,
    isTwoDimensional = true,
    getColorByValue = getTileColorByCount,
    showOpNameInTooltip = false
  } = props;

  const numPaddingColumns = useMemo(() => {
    let numPaddingColumns = 2;

    const diffMillis = endTimeMillis - startTimeMillis + 1;
    if (!isTwoDimensional) {
      return 0;
    } else if (diffMillis >= DAYS_56) {
      numPaddingColumns = 1;
    } else if (diffMillis >= DAYS_28) {
      numPaddingColumns = 2;
    } else if (diffMillis >= DAYS_7) {
      numPaddingColumns = 1;
    }

    return numPaddingColumns;
  }, [endTimeMillis, startTimeMillis, isTwoDimensional]);

  const { heatMapSeries, bucketSize } = useMemo(
    () =>
      getHeatMapEntries(data, timeIntervalMillis, startTimeMillis, endTimeMillis, numPaddingColumns, isTwoDimensional),
    [data, endTimeMillis, numPaddingColumns, startTimeMillis, timeIntervalMillis, isTwoDimensional]
  );

  const getXAxisValue = useCallback<IncHeatMapAxisTickPropsFunction>(
    axisProps => {
      const { value: xValue, textAnchor, x, textBaseline } = axisProps;

      const xIdx = parseInt(xValue.toString(), 10);

      const isEvenBucketSize = bucketSize % 2 === 0;
      const idxToUse = Math.max(0, Math.ceil(bucketSize / 2) - 1);

      const groupIdxRange = bucketSize + numPaddingColumns;

      const groupIdx = xIdx % groupIdxRange;

      if (groupIdx === idxToUse) {
        let groupLabel = "";

        for (let i = 0; i < heatMapSeries.length; i++) {
          const serieData = heatMapSeries[i].data;
          for (let j = 0; j < serieData.length; j++) {
            const { x, groupName } = serieData[j];
            if (x === xIdx) {
              groupLabel = groupName;
              break;
            }
          }

          if (groupLabel) {
            break;
          }
        }

        if (groupLabel) {
          const extraTranslateX = isEvenBucketSize ? (x / xIdx) * 0.25 : 0;
          const transformStr = `translate(${x + extraTranslateX}, -6)`;

          return (
            <text
              dominantBaseline={textBaseline}
              fontSize="9"
              textAnchor={textAnchor}
              transform={transformStr}
            >
              {groupLabel}
            </text>
          );
        }

        return <></>;
      }
      return <></>;
    },
    [bucketSize, heatMapSeries, numPaddingColumns]
  );

  const ttFormatter = useCallback<TTCallback>(
    cell => tooltipFormatter(cell, showOpNameInTooltip),
    [showOpNameInTooltip]
  );

  const heatMapEntriesExist = Boolean(heatMapSeries.length);
  const hrs = timeIntervalMillis / (1000 * 3600);
  return (
    <>
      {heatMapEntriesExist && (
        <div className="alerts-heatmap">
          {isTwoDimensional && (
            <div className="chart-container">
              <HeatMapV2
                borderRadius={hrs >= 6 ? 3 : 6}
                containerElemClass="chart-container"
                forceSquare
                getColorByValue={getColorByValue}
                getXAxisValue={getXAxisValue}
                series={heatMapSeries}
                tooltipFormatter={ttFormatter as any}
                xInnerPadding={hrs >= 6 ? 0.35 : 0.25}
              />
            </div>
          )}

          {!isTwoDimensional && (
            <OneDimensionalHeatMap
              bucketSize={bucketSize}
              containerElemClass="chart-container"
              getColorByValue={getColorByValue}
              series={heatMapSeries}
              tooltipFormatter={ttFormatter as any}
            />
          )}
        </div>
      )}
    </>
  );
};

type TTCallback = IncHeatMapTooltipFormatter<ResultsHeatMapDatum>;

const tooltipFormatter = (cell: ComputedCell<ResultsHeatMapDatum>, showOpName: boolean) => {
  const { value, data } = cell;

  const { cellName, entry } = data;

  const { opName } = entry || {};

  return entry ? (
    <>
      <div className="inc-text-body marginBt12">{cellName}</div>
      <div className="inc-flex-row">
        <div className="width-80 inc-label-common">
          {showOpName && opName ? <IncSmartText text={opName} /> : "Violations"}
        </div>
        <div className="inc-text-subtext-medium marginLtAuto">{value}</div>
      </div>
    </>
  ) : null;
};

const DAYS_56 = duration(56, "d").asMilliseconds();
const DAYS_28 = duration(28, "d").asMilliseconds();
const DAYS_7 = duration(7, "d").asMilliseconds();
