import { IncFaIcon } from "@inception/ui";
import React, { FC, memo } from "react";
import { VerticallyCenteredRow } from "../../../components";
import { WidgetMaximizeProps } from "../../widgets/types";
import { widgetRegistry } from "../../widgets/WidgetRegistry";

interface Props extends WidgetMaximizeProps {}

export const DashboardMaximizeView: FC<Props> = memo(props => {
  const { widget } = props;

  const { type } = widget;
  const WidgetMaximizeComponent = widgetRegistry.getPropsByWidgetId(type)?.maximizeComponent;

  return (
    <div className="dashboard-grid--maximize-view">
      {Boolean(WidgetMaximizeComponent) && <WidgetMaximizeComponent {...props} />}
      {!WidgetMaximizeComponent && (
        <VerticallyCenteredRow className="inc-flex-center height-100 width-100 status-warning">
          <div className="inc-flex-column width-fit-content flex-gap-10">
            <IncFaIcon
              iconName="exclamation-triangle"
              style={{ fontSize: 30 }}
            />
            <div>Maximize component not configured for widget type: {type}</div>
          </div>
        </VerticallyCenteredRow>
      )}
    </div>
  );
});
