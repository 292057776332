import React, { FC, useState, useEffect, useCallback } from "react";
import { IncFaIcon } from "@inception/ui";
import { VerticallyCenteredRow } from "../../components";
import { logger, useNotifications, useToggleState, useVerticalConfig } from "../../core";
import { ParameterValue, Plan, chatApi } from "../../services/api/chat";
import { PlanNodesEditor } from "./PlanNodesEditor";
import { PlanEditorActions } from "./PlanEditorActions";
import { PlanParamsEditor } from "./PlanParamsEditor";
import { PlanEditorModal } from "./PlanEditorModal";

interface Props {
  plan: Plan;
  isFetching: boolean;
  onImplementPlan: (planId: string, paramValues: ParameterValue[]) => void;
  chatId?: string;
}

export const PlanEditor: FC<Props> = props => {
  const { plan: oPlan, isFetching, onImplementPlan, chatId = "" } = props || {};
  const { verticalConfig } = useVerticalConfig();
  const { useCaseId } = verticalConfig;
  const [plan, setPlan] = useState<Plan>(oPlan);
  useEffect(() => setPlan(oPlan), [oPlan]);

  const { notifySuccess, notifyError } = useNotifications();

  const { open: openPlanEditorModal, close: closePlanEditorModal, isOpen: isPlanEditorModalOpen } = useToggleState();

  const implementPlanInternal: () => void = useCallback(() => {
    onImplementPlan(plan?.id, plan?.suggestedValues);
    // isPlanImplementedRef.current = true;
  }, [plan, onImplementPlan]);

  const saveWorkflow: () => void = useCallback(async () => {
    try {
      const response = await chatApi.savePlan(plan, useCaseId);
      if (response.data) {
        notifySuccess("Workflow successfully saved");
      }
    } catch (e) {
      logger.error("failed to save plan", e);
      notifyError("Failed to save workflow");
    }
  }, [notifyError, notifySuccess, plan, useCaseId]);

  const onUpdatePlanParams = useCallback((paramValues: ParameterValue[]) => {
    setPlan(plan => ({
      ...plan,
      suggestedValues: paramValues
    }));
  }, []);

  return (
    <div className="plan-editor paddingBt12">
      <VerticallyCenteredRow className="flex-gap-16 padding24">
        {magicButtonIcon}
        <div className="title inc-text-header">{plan?.name || "Generating Plan..."}</div>
        {isFetching && (
          <div className="live-pill inc-text-subtext">
            <div className="dot"></div>
            Live
          </div>
        )}
        {!isFetching && (
          <IncFaIcon
            className="status-info marginLtAuto inc-cursor-pointer"
            iconName="pen-to-square"
            onClick={openPlanEditorModal}
          />
        )}
      </VerticallyCenteredRow>
      <PlanParamsEditor
        onChange={onUpdatePlanParams}
        paramInputs={plan?.paramInputs}
        suggestedValues={plan?.suggestedValues}
      />

      <PlanNodesEditor
        isFetching={isFetching}
        plan={plan}
        setPlan={setPlan}
      />

      {/* <AddPlanStep /> */}

      <PlanEditorActions
        onImplementPlan={implementPlanInternal}
        saveWorkflow={saveWorkflow}
        showSave={Boolean(useCaseId)}
      />
      <PlanEditorModal
        chatId={chatId}
        closePlanEditorModal={closePlanEditorModal}
        onImplementPlan={onImplementPlan}
        plan={plan}
        setPlan={setPlan}
        show={isPlanEditorModalOpen}
      />
    </div>
  );
};

export const magicButtonIcon = (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 23 20"
    width="23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.14286 12.4167L10.2857 10L12.7714 8.88889L10.2857 7.77778L9.14286 5.36111L8 7.77778L5.51429 8.88889L8 10L9.14286 12.4167ZM9.14286 17.7778L6.28571 11.6667L0 8.88889L6.28571 6.11111L9.14286 0L12 6.11111L18.2857 8.88889L12 11.6667L9.14286 17.7778ZM18.2857 20L16.8571 16.9444L13.7143 15.5556L16.8571 14.1667L18.2857 11.1111L19.7143 14.1667L22.8571 15.5556L19.7143 16.9444L18.2857 20Z"
      fill="#F8485E"
    />
  </svg>
);
