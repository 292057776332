import { IncHighchartsDateTimeFormat, IncDateTimeFormat, getFormattedDateTime } from "@inception/ui";
import { useState, useCallback, useEffect, useRef } from "react";
import {
  OpSimulationState,
  operationaliseV2ApiService,
  OpSimulationStatus
} from "../../../services/api/operationalise";
import { logger } from "../../../core/logging/Logger";
import { getMillisFromTimeObj } from "../../../utils";
import { TEMPORARY_SIMULATION_KEY, PRIMARY_CONFIG_AS_SIMULATION_KEY } from "../../constants";

const REFRESH_INTERVAL = 10000;

export const useFetchSimulationState = (op10zeId: string, simulationId: string) => {
  const [simulationState, setSimulationState] = useState<OpSimulationState>(null);
  const [isStateLoading, setIsStateLoading] = useState(false);
  const [simulationStateError, setSimulationStateError] = useState<string>("");

  const shouldFetchSimulationState =
    simulationId !== TEMPORARY_SIMULATION_KEY && simulationId !== PRIMARY_CONFIG_AS_SIMULATION_KEY;

  const timeoutIdRef = useRef<number>();

  const fetchSimulationState = useCallback(async () => {
    if (shouldFetchSimulationState) {
      setIsStateLoading(true);
      setSimulationStateError("");

      const { data, error, message } = await operationaliseV2ApiService.getSimulationState(op10zeId, simulationId);
      if (error) {
        setSimulationStateError("Error fetching simulation state");
        logger.error("OpSimulationCard", "Error fetching simulation state", message);
      } else {
        setSimulationState(data);
        const { status } = data;
        if (status === OpSimulationStatus.IN_PROGERSS) {
          timeoutIdRef.current = window.setTimeout(() => fetchSimulationState(), REFRESH_INTERVAL);
        }
      }
      setIsStateLoading(false);
    }
  }, [op10zeId, shouldFetchSimulationState, simulationId]);

  useEffect(() => {
    fetchSimulationState();

    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [fetchSimulationState]);

  return {
    isFetching: isStateLoading,
    isError: Boolean(simulationStateError),
    data: simulationState,
    error: simulationStateError
  };
};

export const getSimulationInfo = (simulationState: OpSimulationState) => {
  const { estimatedTimeLeft, percentCompleted, status } = simulationState || {};

  let numPercentCompleted = parseInt(percentCompleted?.toString(), 10);
  if (isNaN(numPercentCompleted)) {
    numPercentCompleted = 0;
  }

  let timeRemainingStr = "-";
  if (estimatedTimeLeft) {
    const millis = getMillisFromTimeObj(estimatedTimeLeft);
    const estTsMillis = Date.now() + millis;

    const nowDate = new Date().getDate();
    const estDate = new Date(estTsMillis).getDate();

    const format = nowDate === estDate ? IncHighchartsDateTimeFormat.timeOnly : IncDateTimeFormat.cohortNumericDateTime;

    timeRemainingStr = getFormattedDateTime(estTsMillis, format);
  }

  return {
    numPercentCompleted,
    timeRemainingStr,
    status
  };
};
