import { isArray } from "lodash";
import React, { useRef, useMemo, useCallback } from "react";
import { GenericFilter, GenericFilterField } from "../../../components";
import { useTenantConfig } from "../../../core";
import { BizFieldPredicate, EntityOperation, ResultCohort } from "../../../services/api/explore";
import { BizFieldsWithAggregations, HighCardinalityEntityFieldNames, Options } from "../../../utils";
import CohortVariableImpl from "../../model-impl/CohortVariableImpl";
import VariableImpl from "../../model-impl/VariableImpl";
import { VariableSrv } from "../../variables";
import { processAggregationsResponse } from "../utils";
import { CohortFilterWrapper } from "../../../components/filters/CohortFilterWrapper";
import { getEntityVariableModelFromBizFieldPredicate } from "./EntityFieldsPicker";

interface FilterContainerProps {
  widgetId: string;
  onVariablesUpdate: (variables: VariableImpl[], widgetId: string) => void;
  entityTypeId: string;
  fieldValues: BizFieldsWithAggregations;
  variableSrv: VariableSrv;
  onUpdateCohortId: (cohortId: string, widgetId: string, isCohortLocked?: boolean, isCohortVisible?: boolean) => void;
  variableLoadingStateMap: Record<string, boolean>;
  isInternalUser?: boolean;
  canEdit?: boolean;
}

export const FiltersContainer = (props: FilterContainerProps) => {
  const {
    entityTypeId,
    fieldValues,
    onUpdateCohortId,
    onVariablesUpdate,
    variableLoadingStateMap,
    variableSrv,
    widgetId,
    canEdit,
    isInternalUser
  } = props;

  const { aggregations, bizFields, suggAggregationsMap } = fieldValues;

  const cohortIdRef = useRef("");

  const bizFieldPredicates = useMemo(() => variableSrv.getCohortVariablePredicatesWithProperties(true), [variableSrv]);

  const { tenantConfigState } = useTenantConfig();
  const options: Options = useMemo(() => ({ currency: tenantConfigState?.currency || "USD" }), [tenantConfigState]);

  const onCohortChange = useCallback(
    (resultCohort: ResultCohort, isCohortLocked?: boolean, isCohortVisible?: boolean) => {
      const id = resultCohort?.id || "";
      if (cohortIdRef.current !== id) {
        cohortIdRef.current = id;
      }
      onUpdateCohortId(id, widgetId, isCohortLocked, isCohortVisible);
    },
    [onUpdateCohortId, widgetId]
  );

  const cohortId = useMemo(() => {
    if (!variableLoadingStateMap[widgetId]) {
      const id = variableSrv.getCohortId();
      if (cohortIdRef.current !== id) {
        cohortIdRef.current = id;
      }
      return id;
    }
    return "";
  }, [variableLoadingStateMap, widgetId, variableSrv]);

  const genericFilterFields = useMemo<GenericFilterField[]>(
    () =>
      (bizFields || [])
        .map(bizField => {
          const { propName, propType, kindDescriptor } = bizField.bizField.entityField;
          const aggregationMeta = suggAggregationsMap?.[propName];
          const cardinality = suggAggregationsMap?.[propName]?.aggregationMeta?.cardinality;
          const fieldPredicate = bizFieldPredicates.find(
            x => x.predicate?.bizField?.entityField?.propName === propName
          );
          if (fieldPredicate) {
            const predicate = fieldPredicate?.predicate;
            const op = predicate?.op || null;
            const value = predicate?.values.length > 0 ? predicate.values : predicate?.value || "";
            const isHighCardinalityField = HighCardinalityEntityFieldNames.includes(propName);
            const { aggValues } = processAggregationsResponse(
              {
                aggregations,
                entityType: entityTypeId
              },
              null,
              propName
            );
            const aggregationValues = aggValues || [];

            return {
              id: propName,
              fieldName: propName,
              fieldType: propType,
              op: op,
              value: value,
              isHighCardinalityField,
              kindDescriptor,
              entityTypeId,
              cohortId,
              cardinality,
              aggregationMeta: aggregationMeta?.aggregationMeta,
              aggregationValues,
              isPinned: fieldPredicate?.isPinned,
              isLocked: fieldPredicate?.isLocked
            };
          }
          return null;
        })
        .filter(x => x !== null)
        .sort(a => (a.fieldName === "Name" ? -1 : 0)),
    [bizFields, suggAggregationsMap, bizFieldPredicates, aggregations, entityTypeId, cohortId]
  );

  const onChange = useCallback(
    (fieldName: string, value: string | string[], operator: EntityOperation) => {
      const tBizFieldPredicates = [...bizFieldPredicates];
      let fieldExists = false;
      tBizFieldPredicates.forEach(x => {
        if (x.predicate?.bizField?.entityField?.propName === fieldName && !fieldExists) {
          x.predicate.op = operator;
          if (isArray(value)) {
            x.predicate.value = "";
            x.predicate.values = value;
          } else {
            x.predicate.value = value;
            x.predicate.values = [];
          }
          fieldExists = true;
        }
      });
      if (!fieldExists) {
        const bizField = bizFields.find(x => x.bizField?.entityField?.propName === fieldName);
        const predicate: BizFieldPredicate = {
          bizField: bizField.bizField,
          op: operator,
          value: isArray(value) ? "" : value,
          values: isArray(value) ? value : []
        };
        tBizFieldPredicates.push({
          predicate,
          isPinned: true,
          isLocked: false
        });
      }

      const entityVariables: CohortVariableImpl[] = [];
      tBizFieldPredicates.forEach(bizField => {
        const varImpl = getEntityVariableModelFromBizFieldPredicate(
          bizField.predicate,
          entityTypeId,
          bizField.isPinned,
          bizField.isLocked
        );
        if (varImpl) {
          entityVariables.push(varImpl);
        }
      });

      onVariablesUpdate(entityVariables, widgetId);
    },
    [bizFieldPredicates, bizFields, entityTypeId, onVariablesUpdate, widgetId]
  );

  const onRemove = useCallback(
    (fieldName: string) => {
      if (!canEdit) {
        return null;
      }

      const tBizFieldPredicates = bizFieldPredicates.filter(
        x => x.predicate?.bizField?.entityField?.propName !== fieldName
      );

      const entityVariables: CohortVariableImpl[] = [];
      tBizFieldPredicates.forEach(bizField => {
        const varImpl = getEntityVariableModelFromBizFieldPredicate(
          bizField.predicate,
          entityTypeId,
          bizField.isPinned
        );
        if (varImpl) {
          entityVariables.push(varImpl);
        }
      });

      onVariablesUpdate(entityVariables, widgetId);
    },
    [bizFieldPredicates, canEdit, entityTypeId, onVariablesUpdate, widgetId]
  );

  const onToggleLock = useCallback(
    (fieldName: string) => {
      if (!canEdit) {
        return null;
      }

      const tBizFieldPredicates = [...bizFieldPredicates];
      let fieldExists = false;
      tBizFieldPredicates.forEach(x => {
        if (x.predicate?.bizField?.entityField?.propName === fieldName && !fieldExists) {
          x.isLocked = !x.isLocked;
          x.isPinned = undefined;
          fieldExists = true;
        }
      });

      if (!fieldExists) {
        const bizField = bizFields.find(x => x.bizField?.entityField?.propName === fieldName);
        const predicate: BizFieldPredicate = {
          bizField: bizField.bizField,
          op: "eq",
          value: "",
          values: []
        };
        tBizFieldPredicates.push({
          predicate,
          isPinned: true,
          isLocked: false
        });
      }

      const entityVariables: CohortVariableImpl[] = [];
      tBizFieldPredicates.forEach(bizField => {
        const varImpl = getEntityVariableModelFromBizFieldPredicate(
          bizField.predicate,
          entityTypeId,
          bizField.isPinned,
          bizField.isLocked
        );
        if (varImpl) {
          entityVariables.push(varImpl);
        }
      });

      onVariablesUpdate(entityVariables, widgetId);
    },
    [bizFieldPredicates, bizFields, canEdit, entityTypeId, onVariablesUpdate, widgetId]
  );

  return (
    <>
      <CohortFilterWrapper
        canEdit={canEdit}
        cohortState="saved"
        containerClass={"cohort-name-container"}
        entityTypeId={entityTypeId}
        isCohortLocked={variableSrv.isCohortLocked}
        isCohortPinned={variableSrv.isCohortPinned}
        isCohortVisible={variableSrv.cohortVisible}
        isInternalUser={isInternalUser}
        label={`${entityTypeId}.Cohort`}
        labelClass={"inc-text-color-secondary inc-text-subtext"}
        onCohortChange={onCohortChange}
        value={cohortId}
      />

      {genericFilterFields.map(genericFilterField => (
        <GenericFilter
          canEdit={canEdit}
          field={genericFilterField}
          isInternalUser={isInternalUser}
          key={genericFilterField.id}
          onChange={onChange}
          onRemove={onRemove}
          onToggleLock={onToggleLock}
          options={options}
        />
      ))}
    </>
  );
};
