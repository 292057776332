import React, { FC, useMemo, memo, useCallback, useState } from "react";
import { HorizontalBar, IncSmartText, IncSelectOption, IncSelect, IncFaIcon } from "@inception/ui";
import { inceptionDarkColorPalette } from "@inception/ui-styles";
import { round } from "lodash";
import { cx } from "emotion";
import { ImpactedWidget, UserServiceFilterExpression } from "../../../services/api/explore";
import LoadingSpinner from "../../Loading/Loading";
import { generateId } from "../../../core";
import { VerticallyCenteredRow } from "../../flex-components";
import { getFormattedValueForImpactedWidget } from "../impact-widget/utils";
import { ENTITY_TAG, getBizFieldPredicateForEntityField } from "../../../utils";
import { getDefaultFilters, getGroupedBarRows, getPropsFromPreset } from "./common";
import { DrilldownVizRenderer, DrilldownVizRendererProps } from "./DrilldownVizRenderer";
import { DrilldownSubRendererProps } from "./types";

interface Props extends DrilldownSubRendererProps {
  impactedWidgets: ImpactedWidget[];
}

type Option = IncSelectOption<ImpactedWidget>;

export const FieldDrilldownStats: FC<Props> = memo(props => {
  const { preset, impactedWidgets, impactedWidget: defImpactedWidget, onAddEntityFilter, onAddEventFilter } = props;

  const { slice } = getPropsFromPreset(preset, "stats");
  const { tagName, userServiceField } = slice;

  const { entityField } = userServiceField;

  const isValidEntityFilter = tagName === ENTITY_TAG && Boolean(entityField);
  const isValidEventFieldFilter = tagName !== ENTITY_TAG;

  const shouldShowAddFilter = Boolean(onAddEntityFilter) || Boolean(onAddEventFilter);
  const onAddFilter = useCallback(
    (value: string, rawValue: string) => {
      if (shouldShowAddFilter) {
        if (isValidEntityFilter) {
          const predicate = getBizFieldPredicateForEntityField(entityField, value);
          onAddEntityFilter(predicate);
        }

        if (isValidEventFieldFilter) {
          const filExpr: UserServiceFilterExpression = {
            field: userServiceField,
            operator: "=",
            value: rawValue
          };
          onAddEventFilter(filExpr);
        }
      }
    },
    [
      entityField,
      isValidEntityFilter,
      isValidEventFieldFilter,
      onAddEntityFilter,
      onAddEventFilter,
      shouldShowAddFilter,
      userServiceField
    ]
  );

  const sortOptions = useMemo<Option[]>(
    () =>
      impactedWidgets.map(iw => ({
        label: iw.name,
        value: iw.id,
        data: iw
      })),
    [impactedWidgets]
  );

  const defSortOpt = sortOptions.find(opt => opt.value === defImpactedWidget?.id) || sortOptions[0];
  const [sortBy, setSortBy] = useState<Option>(defSortOpt);

  const impactedWidget = sortBy?.data;

  const payloadProps = useMemo(
    () => ({
      groupBy: [tagName],
      ...getDefaultFilters()
    }),
    [tagName]
  );

  const uniqId = useMemo(() => generateId(), []);

  const vizRenderer = useCallback<DrilldownVizRendererProps["vizRenderer"]>(
    (state, ref) => {
      const { data, error, isError, isFetching } = state;

      const { bars, totalValue } = getGroupedBarRows(data, tagName);
      const horizontalBars = bars.map((entry, idx) => {
        const { id, name, value, custom } = entry;
        const rawValue = custom?.rawValue || name;

        const key = [uniqId, idx, "horizontal-bar", id].join("-");

        const barColor = inceptionDarkColorPalette.accentBlue;
        const barWidth = round((value / totalValue) * 100, 2);
        const barWidthPer = `${barWidth}%`;

        const formattedValue = getFormattedValueForImpactedWidget(impactedWidget, value);
        const addFilter = () => onAddFilter(name, rawValue);

        const valuesClassName = cx("values", {
          "can-add-filter": shouldShowAddFilter
        });

        return (
          <VerticallyCenteredRow
            className="horizontal-bars--bar marginBt10"
            key={key}
          >
            <VerticallyCenteredRow className={valuesClassName}>
              <IncSmartText
                text={name}
                textClass="name"
              />
              <IncSmartText
                text={formattedValue}
                textClass="value"
              />

              {shouldShowAddFilter && (
                <IncFaIcon
                  className="inc-cursor-pointer status-info add-filter value"
                  iconName="plus-circle"
                  onClick={addFilter}
                />
              )}
            </VerticallyCenteredRow>

            <HorizontalBar
              backgroundColor="#4E565E"
              barColor={barColor}
              barWidth={barWidthPer}
              size="xxs"
            />
          </VerticallyCenteredRow>
        );
      });

      return (
        <div
          className="horizontal-bars"
          ref={ref}
        >
          {isFetching && <LoadingSpinner />}
          {!isFetching && (
            <>
              {isError && <div className="error-message">Error fetching data: {error}</div>}
              {!isError && (
                <>
                  {!bars.length && <div className="no-data-message">No data found</div>}
                  {Boolean(bars.length) && horizontalBars}
                </>
              )}
            </>
          )}
        </div>
      );
    },
    [impactedWidget, onAddFilter, shouldShowAddFilter, tagName, uniqId]
  );

  return (
    <div className="field-drilldown-stats">
      <div className="transparent-select-container marginLtAuto marginBt12">
        <IncSelect
          alignment="row"
          label="Sort by"
          onChange={setSortBy}
          options={sortOptions}
          value={sortBy}
        />
      </div>

      {sortBy ? (
        <DrilldownVizRenderer
          {...payloadProps}
          {...props}
          impactedWidget={sortBy.data}
          vizRenderer={vizRenderer}
        />
      ) : (
        <></>
      )}
    </div>
  );
});
