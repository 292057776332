import { defaultsDeep } from "lodash";
import BaseWidgetModel from "../../../models/BaseWidgetModel";
import TimeSeriesWidgetImpl from "../../TimeSeries/models/impl";
import { Op10zeDataQuery } from "../../../../services/datasources/operationalize/types";
import { OpThresholdSeasonality } from "../../../../services/api/operationalise";
import { TimeObj } from "../../../../services/api/explore";
import Op10zeWidgetModel, { Op10zeWidgetMode, Op10zeWidgetProperties } from "./model";

export default class Op10zeWidgetImpl extends TimeSeriesWidgetImpl implements Op10zeWidgetModel {
  type: "operationalize";
  subTitleHtmlStr: string;
  queries: Op10zeDataQuery[];
  mode: Op10zeWidgetMode;
  properties: Op10zeWidgetProperties;
  isSparkline: boolean;
  disableSparklineInteraction: boolean;
  seasonality: OpThresholdSeasonality;
  lookBack: TimeObj;
  isOpportunity: boolean;

  constructor(model: Partial<Op10zeWidgetModel>, meta?: Record<string, any>) {
    super(model, meta);
    this.assign(model);
  }

  assign(model: Partial<Op10zeWidgetModel>) {
    const tsModel: any = defaultsDeep({ type: "operationalize" }, model);
    super.assign(tsModel);
    this.type = "operationalize";
    this.properties.tooltip = {
      ...(this.properties.tooltip || {}),
      shared: true
    };

    this.mode = tsModel.mode || "data";
    this.subTitleHtmlStr = tsModel.subTitleHtmlStr || "";
    this.isSparkline = tsModel.isSparkline;
    this.disableSparklineInteraction = tsModel.disableSparklineInteraction;
    this.seasonality = tsModel.seasonality;
    this.lookBack = tsModel.lookBack;
    this.isOpportunity = tsModel.isOpportunity;
  }

  getSaveModel(): BaseWidgetModel {
    const model = super.getSaveModel();

    // Doing this since the min, max options are relative to global time range and keep changing
    delete model?.options?.xAxis;
    return model;
  }
}
