import { DepGraph, DepGraphCycleError } from "dependency-graph";
import { cloneDeep, isEmpty, difference, groupBy, forEach, isUndefined } from "lodash";
import { ScopedVar, ScopedVars, EntityAggregationRequest, EntityAggregationResponse } from "../../services/api/types";
import VariableImpl, {
  VARIABLE_ALL_VALUE,
  VARIABLE_ALL_VALUE_FALLBACK,
  VariableAggRequestPayload
} from "../model-impl/VariableImpl";
import CohortVariableImpl from "../model-impl/CohortVariableImpl";
import { VariableType, VAR_URL_PREFIX } from "../models/VariableModel";
import {
  CohortEntityFilter,
  UserServiceFilterExpression,
  FieldPickerContext,
  fieldPickerApiService,
  UserServiceFilterExpressionTree,
  LogicalOperator
} from "../../services/api/explore";
import { getCohortIdPredicate } from "../../utils/CohortUtils";
import { TimeRange, generateId } from "../../core";
import entityStoreApiService from "../../services/api/EntityStoreApiService";
import { logger } from "../../core/logging/Logger";
import { isErrorResponse } from "../../services/api/utils";
import EventVariableImpl from "../model-impl/EventVariableImpl";
import { getFinalValuesAndOperatorForDataType } from "../widgets/utils";
import { QueryParamDTO } from "../../utils/QueryParamUtils";
import EventExpressionVariableImpl from "../model-impl/EventExpressionVariableImpl";

export class VariableSrv {
  variables: VariableImpl[];
  private variableMap: Map<string, VariableImpl>;
  appliedWidgets: string[];

  private timeRange: TimeRange;
  private dependencyGraph: DepGraph<string>;

  // Following are populated for cohort dashboard.
  private entityTypeId: string;
  private cohortId: string;
  private entityTypeName: string;
  private cohortName: string;
  private userServiceId: string;
  isFilterWidget: boolean;
  isCohortPinned: boolean;
  isCohortLocked: boolean;
  cohortVisible: boolean;

  loading: boolean;

  constructor(
    timeRange: TimeRange,
    variables?: VariableImpl[],
    entityTypeId?: string,
    userServiceId?: string,
    entityTypeName?: string,
    cohortId?: string,
    cohortName?: string,
    appliedWidgets?: string[],
    isFilterWidget?: boolean,
    isCohortPinned?: boolean,
    isCohortLocked?: boolean,
    cohortVisible?: boolean
  ) {
    this.timeRange = timeRange;
    this.entityTypeId = entityTypeId;
    this.userServiceId = userServiceId;
    this.entityTypeName = entityTypeName;
    this.cohortId = cohortId;
    this.cohortName = cohortName;
    this.loading = false;
    this.appliedWidgets = appliedWidgets;
    this.isFilterWidget = isFilterWidget;
    this.isCohortPinned = isCohortPinned;
    this.isCohortLocked = isCohortLocked;
    this.cohortVisible = cohortVisible;
    this.assignVariables(variables);
  }

  getEntityTypeId() {
    return this.entityTypeId;
  }

  getUserServiceId() {
    return this.userServiceId;
  }

  getCohortId() {
    return this.cohortId;
  }

  setCohortId(id: string, isCohortLocked?: boolean, isCohortVisible?: boolean) {
    this.cohortId = id;
    if (!isUndefined(isCohortLocked)) {
      this.isCohortLocked = isCohortLocked;
    }
    if (!isUndefined(isCohortVisible)) {
      this.cohortVisible = isCohortVisible;
    }
  }

  getCohortName() {
    return this.cohortName;
  }

  getEntityTypeName() {
    return this.entityTypeName;
  }

  updateVariables(variables: VariableImpl[], entityTypeId?: string, cohortId?: string) {
    this.assign(variables, entityTypeId, cohortId);
  }

  addVariable(variable: VariableImpl) {
    const newVars = [...this.variables, variable];
    this.assignVariables(newVars);
  }

  updateVariable(idx: number, newVariable: VariableImpl) {
    const newVars = [...this.variables];
    newVars[idx] = newVariable;
    this.assignVariables(newVars);
  }

  removeVariable(idx: number) {
    const newVars = [...this.variables];
    newVars.splice(idx, 1);
    this.assignVariables(newVars);
  }

  cloneVariable(idx: number) {
    const newVars = [...this.variables];
    const newVar = cloneDeep(newVars[idx]);
    newVar.name += `_copy_${generateId()}`;
    // Insert right next to the original variable
    newVars.splice(idx + 1, 0, newVar);
    this.assignVariables(newVars);
  }

  getVariable(idx: number) {
    return this.variables[idx];
  }

  getVariables(): VariableImpl[] {
    return this.variables;
  }

  getAppliedWidgets(): string[] {
    return this.appliedWidgets;
  }

  checkIfFilterWidget(): boolean {
    return this.isFilterWidget;
  }

  updateVariableValues(varName: string, value: string | string[]) {
    const variable = this.variableMap.get(varName);
    if (variable) {
      if (isEmpty(value)) {
        value = variable.optionsData?.data[0] || variable.defaultValue;
      }
      variable.value = value;
      if (variable.type === VariableType.Cohort && value) {
        const cohortVar = variable as CohortVariableImpl;
        const { operator, values } = getFinalValuesAndOperatorForDataType(value as string[], cohortVar.dataType);
        cohortVar.operator = operator;
        cohortVar.value = values;
      }
    }
  }

  getVariableValues(): ScopedVars {
    const variableKVs: ScopedVars = {};
    this.variables.forEach(variable => {
      const varValue: ScopedVar = this.getVariableValue(variable);
      variableKVs[varValue.text] = varValue;
      return variable;
    });
    return variableKVs;
  }

  async updateVariableOptions(timeRange: TimeRange, varName?: string) {
    this.loading = true;
    await this.resolveBizFields(timeRange);
    const { valid, error } = this.validateVariables();
    if (valid) {
      const promises: Array<Promise<any>> = [];

      // Update the non dependant variables only when no variable is specified.
      if (!varName) {
        const nonDepUpdatePromise = this.updateNonDependantVariablesOptions(timeRange);
        promises.push(nonDepUpdatePromise);
      }

      const depUpdatePromise = this.updateDependantVariablesOptions(timeRange, varName);
      promises.push(depUpdatePromise);

      const aggVariablesPromise = this.resolveAggregationSupportedVariables(timeRange);
      promises.push(aggVariablesPromise);

      await Promise.all(promises);
      this.loading = false;
    } else {
      this.loading = false;
      const err = `Variable value init failed. Error: ${error}`;
      throw Error(err);
    }
  }

  validateVariable(variable: VariableImpl) {
    let valid = true;
    let error = "";
    const countMap: Record<string, number> = {};
    const { name } = variable;

    this.variables.forEach(variable => {
      const { name } = variable;
      countMap[name] = (countMap[name] || 0) + 1;
    });

    valid = countMap[name] === 1;

    if (!valid) {
      error = "Variable names must be unique.";
    } else {
      try {
        this.dependencyGraph.dependenciesOf(name);
      } catch (err) {
        valid = false;
        const cyclePathStr = (err as DepGraphCycleError).cyclePath.join(" -> ");
        error = `Cyclic dependency found ${cyclePathStr}`;
      }
    }

    return {
      error,
      valid
    };
  }

  validateVariables() {
    let valid = true;
    const errors: string[] = [];

    this.variables.forEach(variable => {
      const { valid: curValid, error: err } = this.validateVariable(variable);
      valid = valid && curValid;
      if (err) {
        errors.push(err);
      }
    });

    return {
      error: errors.join(","),
      valid
    };
  }

  getCohortFilters(): CohortEntityFilter[] {
    const cohortFilters = this.getCohortVariablePredicates(true);
    const cohortIdPredicate = getCohortIdPredicate(this.cohortId, this.entityTypeId);
    if (cohortIdPredicate) {
      cohortFilters.push({
        fieldType: "bizEntityField",
        predicate: cohortIdPredicate
      });
    }

    return cohortFilters;
  }

  getEntityFilters(): CohortEntityFilter[] {
    return this.getCohortVariablePredicates(false);
  }

  getEntityCohortVariables(): CohortVariableImpl[] {
    return this.getVariables().filter(
      v => v.type === "cohort" && !(v as CohortVariableImpl).isCohortFilter()
    ) as CohortVariableImpl[];
  }

  getEventVariables(): EventVariableImpl[] {
    return this.getVariables().filter(x => x.type === VariableType.Event) as EventVariableImpl[];
  }

  getEventExpressionVariables(): EventExpressionVariableImpl[] {
    return this.getVariables().filter(x => x.type === VariableType.EventExpression) as EventExpressionVariableImpl[];
  }

  getEventFiltersWithEventId(): Record<string, UserServiceFilterExpression[]> {
    const userServiceToFilterExpr: Record<string, UserServiceFilterExpression[]> = {};
    const eventVariables = this.getEventVariables();
    eventVariables.forEach(ev => {
      const filterExpr = ev.getFilterExpression();
      const { userServiceId } = ev;
      const isValidValue = Boolean(filterExpr.value) || filterExpr.values.length > 0;
      if (isValidValue) {
        if (userServiceToFilterExpr[userServiceId]) {
          userServiceToFilterExpr[userServiceId].push(filterExpr);
        } else {
          userServiceToFilterExpr[userServiceId] = [filterExpr];
        }
      }
    });
    return userServiceToFilterExpr;
  }

  getEventFiltersTreeWithEventId(): Record<string, UserServiceFilterExpressionTree> {
    const userServiceToFilterExpr: Record<string, UserServiceFilterExpressionTree> = {};
    const eventExprVariables = this.getEventExpressionVariables();

    const variablesByEventType = groupBy(eventExprVariables, v => v.userServiceId);
    forEach(variablesByEventType, (eventExprVariables, userServiceId) => {
      const numVars = eventExprVariables.length;
      if (numVars === 1) {
        userServiceToFilterExpr[userServiceId] = eventExprVariables[0].expressionTree;
      } else {
        userServiceToFilterExpr[userServiceId] = {
          filterNodes: eventExprVariables.map(ev => ({
            expressionTree: ev.expressionTree
          })),
          logicalOperator: LogicalOperator.AND
        };
      }
    });

    return userServiceToFilterExpr;
  }

  getFilterQueryParams(): QueryParamDTO[] {
    const filters: QueryParamDTO[] = this.variables.map(x => x.getFilterQueryParams()).filter(x => x);
    if (this.cohortId) {
      filters.push({
        queryKey: `${VAR_URL_PREFIX}${this.entityTypeId}.cohortId`,
        queryValue: this.cohortId
      });
    }
    return filters;
  }

  async resolveBizFields(timeRange: TimeRange) {
    const { from, to } = timeRange;
    const entityVariables = this.getEntityCohortVariables() as CohortVariableImpl[];
    const shouldCallBizFields = entityVariables.find(x => !x.bizField) !== undefined;
    if (shouldCallBizFields) {
      const pickerContext: FieldPickerContext = {
        bizEntityType: this.entityTypeId,
        showFields: true
      };
      const { bizFields = [] } = await fieldPickerApiService.getBizEntityFields(
        this.entityTypeId,
        pickerContext,
        from.valueOf(),
        to.valueOf()
      );

      if (bizFields.length > 0) {
        entityVariables.forEach(ev => {
          if (!ev.bizField) {
            let fieldAdded = false;
            bizFields.forEach(bfInfo => {
              const { bizField } = bfInfo;
              if (bizField && bizField.entityField && !fieldAdded) {
                const { entityField } = bizField;
                const { propName } = entityField;
                if (propName === ev.name) {
                  ev.bizField = bizField;
                  ev.field = bizField;
                  ev.dataType = entityField.propType;
                  fieldAdded = true;
                }
              }
            });
          }
        });
      }
    }
  }

  private async resolveAggregationSupportedVariables(timeRange: TimeRange) {
    const aggSupportedVariables = this.variables.filter(v => v.supportsAggregation);
    const requestPayloadPromises = aggSupportedVariables.map(v => v.getAggregationRequest(timeRange));
    const settledPromises = await Promise.allSettled(requestPayloadPromises);

    const aggRequestPayloads: VariableAggRequestPayload[] = [];
    settledPromises.forEach(p => {
      if (p.status === "fulfilled") {
        const { aggRequest, variableName } = p.value || {};
        if (aggRequest) {
          aggRequestPayloads.push(p.value);
        } else {
          logger.debug(`Variable ${variableName} returned no aggregation request payload`, null);
        }
      } else {
        logger.error("Failed to get aggregation request payload", p.reason);
      }
    });

    const aggRequestPromises: Array<Promise<AggRequestPromiseResponse>> = [];

    // Group payloads by entityTypeId
    const entityTypeGroup = groupBy(aggRequestPayloads, "entityTypeId");
    Object.keys(entityTypeGroup).forEach(entityTypeId => {
      const group = entityTypeGroup[entityTypeId];
      // Group payloads by cohortId
      const cohortIdGroup = groupBy(group, "cohortId");
      Object.keys(cohortIdGroup).forEach(cohortId => {
        const cGroup = cohortIdGroup[cohortId];
        // Group payloads by mockData status
        const mockGroup = groupBy(cGroup, "mockData");
        Object.keys(mockGroup).forEach(mockData => {
          const isMockData = mockData === "true";
          const mGroup = mockGroup[mockData];
          let startTimeMillis = Number.POSITIVE_INFINITY;
          let endTimeMillis = Number.NEGATIVE_INFINITY;
          const varNames: string[] = [];

          const eAggPayloads = mGroup.map(p => {
            const { startMillis: s, endMillis: e, variableName } = p;
            startTimeMillis = Math.min(startTimeMillis, s);
            endTimeMillis = Math.max(endTimeMillis, e);
            varNames.push(variableName);
            return p.aggRequest;
          });

          // We need the varNames to figure out what all variables will receive this response
          aggRequestPromises.push(
            this.resolveAggregationRequests(
              eAggPayloads,
              startTimeMillis,
              endTimeMillis,
              entityTypeId,
              cohortId,
              isMockData,
              varNames
            )
          );
        });
      });
    });

    const aggSettledResponses = await Promise.allSettled(aggRequestPromises);
    aggSettledResponses.forEach(pr => {
      if (pr.status === "fulfilled") {
        const { aggResponse, varNames, error } = pr.value;
        const matchingVars = varNames.map(v => this.variableMap.get(v)).filter(v => v);
        matchingVars.forEach(mVar => mVar.updateValues(aggResponse, error));
      } else {
        logger.error("Resolve aggregation requests failed", pr.reason);
      }
    });
  }

  private async resolveAggregationRequests(
    aggRequests: EntityAggregationRequest[],
    startMillis: number,
    endMillis: number,
    entityTypeId: string,
    cohortId: string,
    mockData: boolean,
    varNames: string[]
  ): Promise<AggRequestPromiseResponse> {
    const response: AggRequestPromiseResponse = {
      aggResponse: null,
      error: null,
      varNames
    };

    try {
      const { data, status, statusText } = await entityStoreApiService.getEntityAggregation(
        startMillis,
        endMillis,
        entityTypeId,
        cohortId,
        aggRequests,
        mockData
      );

      const isError = isErrorResponse(status);
      if (isError) {
        response.error = statusText;
      } else {
        response.aggResponse = data;
      }
    } catch (err) {
      response.error = err as string;
    }

    return response;
  }

  private async updateOptionsForVariableInternal(
    varName: string,
    timeRange: TimeRange,
    scopedVarsPromise?: Promise<ScopedVars>
  ) {
    const variable = this.variableMap.get(varName);
    scopedVarsPromise = scopedVarsPromise || Promise.resolve({} as ScopedVars);
    const scopedVars = await scopedVarsPromise;
    if (variable) {
      variable.lock = true;
      await variable.resolveValues(timeRange, scopedVars);
      variable.adjustValueAndDefaultValue();
      scopedVars[varName] = this.getVariableValue(variable);
      variable.lock = false;
    }
    return scopedVars;
  }

  private updateNonDependantVariablesOptions(timeRange: TimeRange) {
    const nonDepVarNames = this.dependencyGraph.entryNodes();
    const promises = nonDepVarNames
      .filter(vName => !this.variableMap.get(vName)?.supportsAggregation)
      .map(varName => this.updateOptionsForVariableInternal(varName, timeRange));
    return Promise.all(promises);
  }

  private updateDependantVariablesOptions(timeRange: TimeRange, varName?: string) {
    const depsExist = varName ? this.dependencyGraph.dependantsOf(varName).length > 0 : true;

    if (depsExist) {
      const nonDepVarNames = this.dependencyGraph.entryNodes();
      const safeOrder = this.dependencyGraph.overallOrder();

      let varsToUpdate = difference(safeOrder, nonDepVarNames);
      varsToUpdate = varsToUpdate.filter(vName => !this.variableMap.get(vName)?.supportsAggregation);

      const promise = varsToUpdate.reduce(
        (scopedVarsPromise, varName) => this.updateOptionsForVariableInternal(varName, timeRange, scopedVarsPromise),
        Promise.resolve({} as ScopedVars)
      );

      return promise;
    }
    return Promise.resolve();
  }

  private getCohortVariablePredicates(isFilterTypeCohort: boolean) {
    const cohortVariables = this.variables.filter(v => v.type === VariableType.Cohort) as CohortVariableImpl[];
    return cohortVariables
      .filter(v => {
        const isTypeCohort = v.isCohortFilter();
        return isFilterTypeCohort === isTypeCohort;
      })
      .map(v => v.getPredicate())
      .filter(p => p);
  }

  getCohortVariablePredicatesWithProperties(enableEmptyPredicates = false) {
    const cohortVariables = this.variables.filter(v => v.type === VariableType.Cohort) as CohortVariableImpl[];
    return cohortVariables
      .map(v => ({
        predicate: v.getPredicate(enableEmptyPredicates)?.predicate,
        isPinned: v.isPinned,
        isLocked: v.isLocked
      }))
      .filter(p => Boolean(p.predicate));
  }

  private assignVariables(variables?: VariableImpl[]) {
    this.variables = variables || [];
    this.variableMap = new Map();
    this.variables.forEach(variable => {
      const { name } = variable;
      this.variableMap.set(name, variable);
    });
    this.constructDependencyGraph();
  }

  private getVariableValue(variable: VariableImpl): ScopedVar {
    const { name, value, allValue, multi, includeAll } = variable;

    let finValue = value;

    if (variable.type !== VariableType.Event) {
      // We will have to check if all value is present, if no we should fetch all the possible values
      if (value === VARIABLE_ALL_VALUE) {
        if (allValue) {
          finValue = allValue;
        } else {
          const { data: varValues = [], error = "" } = variable.optionsData || {};
          if (!error) {
            finValue = varValues;
          } else {
            finValue = VARIABLE_ALL_VALUE_FALLBACK;
          }
        }
      }
    }

    return {
      text: name,
      value: finValue,
      multi,
      includeAll,
      allValue
    };
  }

  private constructDependencyGraph() {
    this.dependencyGraph = new DepGraph<string>();
    this.variables.forEach(variable => {
      const { name } = variable;
      if (!this.dependencyGraph.hasNode(name)) {
        this.dependencyGraph.addNode(name);
      }
      const depArray = variable.dependencies || [];
      depArray.forEach(depName => {
        if (!this.dependencyGraph.hasNode(depName)) {
          this.dependencyGraph.addNode(depName);
        }
        this.dependencyGraph.addDependency(name, depName);
      });
    });
  }

  private assign(variables: VariableImpl[], entityTypeId?: string, cohortId?: string) {
    this.assignVariables(variables);
    this.cohortId = cohortId;
    this.entityTypeId = entityTypeId;
  }
}

type AggRequestPromiseResponse = {
  varNames: string[];
  aggResponse: EntityAggregationResponse;
  error: string;
};
