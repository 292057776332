import { IncSelect, IncSelectOption } from "@inception/ui";
import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { TypedObjectValue } from "../services/api/operationalise";
import { FieldPrimType, FieldSubType } from "../core/data/types/DataTypes";
import { FEATURE_FLAGS, featureFlagService } from "../services/feature-flags";
import { DiscoveredFieldDef } from "../services/api/auto-discovery/types";
import {
  ArithmeticConfig,
  ArrayReductionConfig,
  FieldTransformationConfig,
  TransformationConfig,
  TransformationTypeList,
  TransformType
} from "./transform-config/TransformConfig";
import { ArithmeticComp } from "./components/ArithmeticComp";
import {
  ArrayReductionComp,
  PropertyValueComp,
  RegroupingComp,
  ScriptComp,
  TypeConversionComp,
  ConditionalComp,
  DateTimeComp
} from "./components";
import { LocationComp } from "./components/LocationComp";
import { DruidScriptComp } from "./components/DruidScriptComp";

interface TransformationCompProps {
  config: FieldTransformationConfig<TransformType>;
  onChange: (
    config: FieldTransformationConfig<TransformType>,
    deleteTransformation?: boolean,
    isLocationTransformation?: boolean
  ) => void;
  fieldDataType: FieldPrimType;
  showWarning?: boolean;
  getLocationEntityTypes?: () => Record<string, string>;
  setEntityTypeValidity?: (valid: boolean) => void;
  fieldSubType: FieldSubType;
  uniqueFieldName: string;
  disableTranformSelect?: boolean;
  restrictLocationTransformation?: boolean;
  allowedtransformTypes?: TransformType[];
  preSelectTransformation?: TransformType;
  isEntityMapping?: boolean;
  rawOptions?: Array<IncSelectOption<DiscoveredFieldDef>>;
}

export const FieldTransformationForm: React.FC<TransformationCompProps> = props => {
  const {
    config,
    onChange,
    getLocationEntityTypes,
    setEntityTypeValidity,
    fieldSubType,
    fieldDataType,
    uniqueFieldName,
    disableTranformSelect,
    restrictLocationTransformation,
    allowedtransformTypes,
    preSelectTransformation,
    rawOptions,
    isEntityMapping = false
  } = props;

  const [transformationVal, setTransformationVal] = useState<IncSelectOption<TransformType>>({
    label: "none",
    value: "none"
  });

  const prevDefaultOutputType = useRef<FieldPrimType>(fieldDataType);
  const transformType = transformationVal?.value as TransformType;
  const transformationsList: IncSelectOption[] = useMemo(() => {
    const list = allowedtransformTypes?.length
      ? allowedtransformTypes.map(value => ({
          label: TransformationTypeList[value],
          value: value
        }))
      : Object.entries(TransformationTypeList)
          .filter(entry => entry[0] !== "druidTransformationConfig")
          .map(entry => ({
            label: entry[1],
            value: entry[0]
          }));
    if (restrictLocationTransformation && !allowedtransformTypes) {
      return list.filter(x => x.value !== "location");
    }
    return list;
  }, [restrictLocationTransformation, allowedtransformTypes]);
  const onTransformationChange = useCallback(
    (selection: IncSelectOption) => {
      setTransformationVal(selection);
      const transformType = selection?.value as TransformType;
      const outputType = fieldDataType ?? getOutputType(transformType);

      if (transformType === "staticValue") {
        const staticValue = getDefaultStaticValue(outputType);
        onChange({
          staticValue,
          outputType
        });
      } else if (transformType === "typeConversion") {
        onChange({
          typeConversion: {
            toType: outputType
          },
          outputType
        });
      } else if (transformType === "location") {
        onChange(null, false, true);
      } else if (transformType === "dateTransformation") {
        onChange({
          dateTransformation: config?.dateTransformation,
          outputType: "_long"
        });
      } else {
        onChange(null, transformType === "none");
      }
    },
    [fieldDataType, onChange, config]
  );

  useEffect(() => {
    if (config) {
      const tranformTypes = Object.keys(TransformationTypeList);
      const transformationVal = Object.keys(config).find(key => tranformTypes.includes(key));
      const selectedOp = transformationsList.find(op => op.value === transformationVal);
      setTransformationVal(selectedOp);
    }
    if (fieldSubType === "geolocation") {
      const selectedOp = transformationsList.find(op => op.value === "location");
      onTransformationChange(selectedOp);
    }
  }, [config, transformationsList, fieldSubType, onTransformationChange]);

  useEffect(() => {
    if (preSelectTransformation) {
      if (config === null) {
        setTransformationVal({
          label: TransformationTypeList[preSelectTransformation],
          value: preSelectTransformation
        });
      }
    } else {
      if (config === null) {
        setTransformationVal({
          label: "none",
          value: "none"
        });
      }
    }
  }, [preSelectTransformation, config]);

  const updateFieldTransformationConfig = useCallback(
    (config: TransformationConfig<TransformType>, outputType: FieldPrimType) => {
      if ((!transformType || !config || !outputType) && !isEntityMapping) {
        return;
      }

      onChange({
        [transformType]: config,
        outputType: outputType
      });
    },
    [transformType, isEntityMapping, onChange]
  );

  const updateTransformationConfig = useCallback(
    (tConfig: TransformationConfig<TransformType>) => {
      updateFieldTransformationConfig(tConfig, fieldDataType);
    },
    [updateFieldTransformationConfig, fieldDataType]
  );

  const updateOutputType = useCallback(
    (outputType: FieldPrimType) => {
      if (transformType === "staticValue") {
        const staticVal = getDefaultStaticValue(outputType);
        updateFieldTransformationConfig(staticVal, outputType);
      } else if (transformType === "typeConversion") {
        const fieldConfig = config?.typeConversion as TransformationConfig<"typeConversion">;
        if (fieldConfig) {
          fieldConfig.toType = outputType;
          updateFieldTransformationConfig(fieldConfig, outputType);
        }
      } else {
        const fieldConfig = config?.[transformType];
        updateFieldTransformationConfig(fieldConfig, outputType);
      }
    },
    [config, transformType, updateFieldTransformationConfig]
  );

  useEffect(() => {
    if (fieldDataType && prevDefaultOutputType.current !== fieldDataType) {
      if (prevDefaultOutputType.current) {
        updateOutputType(fieldDataType);
      }
      prevDefaultOutputType.current = fieldDataType;
    }
  }, [fieldDataType, updateOutputType]);

  const renderTransformationComponent = useMemo(() => {
    let transformationComponent = <></>;
    switch (transformType) {
      case "arrayReduction": {
        transformationComponent = (
          <ArrayReductionComp
            config={config?.arrayReduction as ArrayReductionConfig}
            onChange={updateTransformationConfig}
          />
        );
        break;
      }
      case "arithmetic":
        transformationComponent = (
          <ArithmeticComp
            config={config?.arithmetic as ArithmeticConfig}
            onChange={updateTransformationConfig}
          />
        );
        break;
      case "script":
        transformationComponent = (
          <ScriptComp
            config={config?.script as TransformationConfig<"script">}
            onChange={updateTransformationConfig}
          />
        );
        break;
      case "druidTransformationConfig":
        transformationComponent = isEntityMapping ? (
          <DruidScriptComp
            config={config?.druidTransformationConfig as TransformationConfig<"druidTransformationConfig">}
            onChange={updateTransformationConfig}
            rawOptions={rawOptions}
          />
        ) : (
          <ScriptComp
            config={config?.druidTransformationConfig as TransformationConfig<"druidTransformationConfig">}
            onChange={updateTransformationConfig}
          />
        );
        break;
      case "staticValue": {
        if (config?.staticValue) {
          transformationComponent = (
            <PropertyValueComp
              config={config?.staticValue as TransformationConfig<"staticValue">}
              onChange={updateTransformationConfig}
            />
          );
        }
        break;
      }
      case "typeConversion": {
        if (config?.typeConversion) {
          transformationComponent = (
            <TypeConversionComp
              config={config?.typeConversion as TransformationConfig<"typeConversion">}
              onChange={updateTransformationConfig}
            />
          );
        }
        break;
      }
      case "regrouping":
        transformationComponent = (
          <RegroupingComp
            config={config?.regrouping as TransformationConfig<"regrouping">}
            onChange={updateTransformationConfig}
          />
        );
        break;
      case "conditional":
        transformationComponent = (
          <ConditionalComp
            config={config?.conditional as TransformationConfig<"conditional">}
            defaultOutputType={fieldDataType}
            fieldSubType={fieldSubType}
            getLocationEntityTypes={getLocationEntityTypes}
            onChange={updateTransformationConfig}
            setEntityTypeValidity={setEntityTypeValidity}
            uniqueFieldName={uniqueFieldName}
          />
        );
        break;
      case "location":
        transformationComponent = (
          <LocationComp
            config={config?.location as TransformationConfig<"location">}
            fieldName={uniqueFieldName}
            getLocationEntityTypes={getLocationEntityTypes}
            onChange={updateTransformationConfig}
            setEntityTypeValidity={setEntityTypeValidity}
          />
        );
        break;
      case "dateTransformation":
        transformationComponent = (
          <DateTimeComp
            config={config?.dateTransformation as TransformationConfig<"dateTransformation">}
            onChange={updateTransformationConfig}
          />
        );
        break;
      default:
        break;
    }
    return transformationComponent;
  }, [
    transformType,
    config,
    updateTransformationConfig,
    isEntityMapping,
    rawOptions,
    fieldDataType,
    fieldSubType,
    getLocationEntityTypes,
    setEntityTypeValidity,
    uniqueFieldName
  ]);
  const isDebugMode = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.enableDebugMode);

  return (
    <div className="transformations-form marginBt16">
      <IncSelect
        autoSort={false}
        className="compare-select marginBt16"
        isDisabled={fieldSubType === "geolocation" || disableTranformSelect}
        label="Select Transformation Type"
        onChange={onTransformationChange}
        options={transformationsList}
        value={transformationVal}
      />
      {transformType && transformType !== "none" && (
        <>
          <div className="flex inc-flex-space-contents">
            <div className="marginBt16 inc-text-subtext-medium">Transformation Config</div>
            {allowedtransformTypes?.length && transformType === "druidTransformationConfig" ? (
              <a
                className="marginBt16 inc-text-body-medium"
                href="https://bicycleio.atlassian.net/wiki/spaces/ME/pages/1513029633/Druid+Transformations+for+Virtual+Field"
                rel="noopener noreferrer"
                target="_blank"
              >
                Help
              </a>
            ) : (
              <></>
            )}
            {isDebugMode && transformType === "script" && (
              <a
                className="marginBt16 inc-text-body-medium"
                href="https://bicycleio.atlassian.net/wiki/spaces/CON/pages/1628504066/Jexl+Script+Examples"
                rel="noopener noreferrer"
                target="_blank"
              >
                Help
              </a>
            )}
          </div>
          <div className="marginBt16">{renderTransformationComponent}</div>
        </>
      )}
    </div>
  );
};

function getOutputType(
  transformType: TransformType,
  config?: TransformationConfig<TransformType>,
  fieldDataType?: FieldPrimType
) {
  let outputType: FieldPrimType = null;
  switch (transformType) {
    case "arithmetic":
      outputType = "_double";
      break;
    case "arrayReduction": {
      const conf = config as TransformationConfig<"arrayReduction">;
      if (conf) {
        if (conf.op === "avg" || conf.op === "sum") {
          outputType = "_double";
        } else if (conf.op === "percentile" || conf.op === "max" || conf.op === "min") {
          outputType = fieldDataType;
        } else if (conf.op === "count") {
          outputType = "_long";
        }
      } else {
        outputType = "_long";
      }
      break;
    }
    default:
      outputType = "_str";
      break;
  }
  return outputType;
}

function getDefaultStaticValue(type: FieldPrimType): TypedObjectValue {
  if (type === "_double") {
    return { doubleVal: 0 };
  }
  if (type === "_long") {
    return { longVal: 0 };
  }
  if (type === "_bool") {
    return { booleanVal: true };
  }
  if (type === "_date") {
    return { dateVal: "" };
  }
  if (type === "_datetime") {
    return { dateTimeVal: "" };
  }
  if (type === "_str") {
    return { stringVal: "" };
  }
  return { stringVal: "" };
}
