import React, { useState, useMemo, useCallback } from "react";
import { IncModal, IncRadioButton, IncTextfield } from "@inception/ui";
import { IncButtonColor } from "@inception/ui/src/components/Button/Button";
import { MAX_INT32 } from "../../utils";
import { VerticallyCenteredRow } from "../flex-components";

interface Props {
  show: boolean;
  downloadInProgress: boolean;

  seriesLimit: number;
  fileName: string;

  onFileNameChange: (fileName: string) => void;
  onSeriesLimitChange: (seriesLimit: number) => void;

  onClose: () => void;
  onConfirmClick: () => void;
}

export const DownloadCSVModal: React.FC<Props> = props => {
  const {
    show,
    onClose,
    onConfirmClick,
    onFileNameChange,
    onSeriesLimitChange,
    downloadInProgress,
    fileName,
    seriesLimit
  } = props;
  const [selectedRadioBtn, setSelectedRadioBtn] = useState<"custom" | "all">("custom");

  const actions = useMemo(
    () => ({
      primary: {
        id: "common.actions.confirm",
        onClick: onConfirmClick,
        color: "primary" as IncButtonColor,
        disabled: downloadInProgress || !seriesLimit || !fileName,
        showLoader: downloadInProgress
      },
      secondary: {
        id: "common.actions.close",
        onClick: onClose,
        color: "link" as IncButtonColor,
        disabled: downloadInProgress
      }
    }),
    [downloadInProgress, fileName, onClose, onConfirmClick, seriesLimit]
  );

  const onValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.valueAsNumber;
      onSeriesLimitChange(value);
    },
    [onSeriesLimitChange]
  );

  const labelContainer = useMemo(() => {
    const isCustomSel = selectedRadioBtn === "custom";
    return (
      <VerticallyCenteredRow>
        {!isCustomSel && <span>Custom</span>}

        {isCustomSel && (
          <>
            <span>Entries: </span>
            <IncTextfield
              className="textfield marginLt8"
              onChange={onValueChange}
              type={"number"}
              value={seriesLimit}
            />
          </>
        )}
      </VerticallyCenteredRow>
    );
  }, [onValueChange, selectedRadioBtn, seriesLimit]);

  const onCustomRadioBtnClicked = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setSelectedRadioBtn("custom");
        if (seriesLimit === MAX_INT32) {
          onSeriesLimitChange(100);
        }
      }
    },
    [onSeriesLimitChange, seriesLimit]
  );

  const onAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setSelectedRadioBtn("all");
        onSeriesLimitChange(MAX_INT32);
      }
    },
    [onSeriesLimitChange]
  );

  const onFileNameChangeInternal = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFileNameChange(event.target.value);
    },
    [onFileNameChange]
  );

  return (
    <IncModal
      actions={actions}
      closeOnBackdrop={!downloadInProgress}
      closeOnEscape={!downloadInProgress}
      onClose={onClose}
      show={show}
      showClose={!downloadInProgress}
      size="sm"
      titleText="Download data"
    >
      <IncTextfield
        label="File name"
        onChange={onFileNameChangeInternal}
        value={fileName}
      />

      <VerticallyCenteredRow className="marginTp12">
        <IncRadioButton
          checked={selectedRadioBtn === "all"}
          label="All"
          onChange={onAllClick}
        />

        <IncRadioButton
          checked={selectedRadioBtn === "custom"}
          className="marginLt16"
          label={labelContainer}
          onChange={onCustomRadioBtnClicked}
        />
      </VerticallyCenteredRow>
    </IncModal>
  );
};
