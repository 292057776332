import { IncSmartText, ISaxIcon } from "@inception/ui";
import React, { FC } from "react";

interface Props {
  isIncomplete: boolean;
  message: string;
  children: JSX.Element | JSX.Element[];

  preJsx?: JSX.Element | JSX.Element[];
  postJsx?: JSX.Element | JSX.Element[];
  hideIcon?: boolean;
  asCard?: boolean;
  overlayJsx?: JSX.Element;
}

export const MappingIncompleteOverlay: FC<Props> = props => {
  const {
    children,
    isIncomplete,
    message,
    hideIcon = false,
    postJsx = null,
    preJsx = null,
    asCard = false,
    overlayJsx = null
  } = props;

  if (!isIncomplete) {
    return <>{children}</>;
  }

  return (
    <div
      className="mapping-incomplete-overlay"
      data-is-card={asCard}
    >
      <div
        className="mapping-incomplete-overlay--overlay inc-flex-row inc-flex-center"
        style={{
          height: message ? "calc(100% - 46px" : "100%",
          borderRadius: asCard ? (message ? "8px 8px 0px 0px" : "8px") : "0px"
        }}
      />

      {overlayJsx}

      {children}

      {Boolean(message) && (
        <div className="mapping-incomplete-overlay--banner">
          {preJsx}
          {!hideIcon && (
            <ISaxIcon
              className="status-brand"
              iconName="Magicpen"
              size={16}
            />
          )}
          <IncSmartText
            className="inc-text-subtext-medium"
            text={message}
          />
          {postJsx}
        </div>
      )}
    </div>
  );
};
