import React, { FC, memo, useState, useCallback, useMemo } from "react";
import { IncSmartText, IncFaIcon, IncModal } from "@inception/ui";
import { VerticallyCenteredRow } from "../../flex-components";
import { UserServiceFilterExpression, UserServiceFieldSlice } from "../../../services/api/explore";
import { ENTITY_TAG, getBizFieldPredicateForEntityField } from "../../../utils";
import { FieldDrilldownDrawer } from "./FieldDrilldownDrawer";
import { DrilldownSubRendererProps } from "./types";

interface Props extends DrilldownSubRendererProps {
  rawValue: string;
  value: string;
  slice: UserServiceFieldSlice;
}

export const TableRowRenderer: FC<Props> = memo(props => {
  const { value, rawValue, onAddEntityFilter, onAddEventFilter, slice, ...restProps } = props;

  const { tagName, userServiceField } = slice;

  const extendedUSFilters: UserServiceFilterExpression[] = useMemo(
    () => [
      {
        field: userServiceField,
        operator: "=",
        value: rawValue || value
      }
    ],
    [rawValue, userServiceField, value]
  );

  const lookupData = useMemo(() => ({ [rawValue]: value }), [rawValue, value]);

  const { entityField } = userServiceField;

  const isValidEntityFilter = tagName === ENTITY_TAG && Boolean(entityField);
  const isValidEventFieldFilter = tagName !== ENTITY_TAG;

  const shouldShowAddFilter = Boolean(onAddEntityFilter) || Boolean(onAddEventFilter);
  const onAddFilter = shouldShowAddFilter
    ? () => {
        if (isValidEntityFilter) {
          const predicate = getBizFieldPredicateForEntityField(entityField, value);
          onAddEntityFilter(predicate);
        }

        if (isValidEventFieldFilter) {
          const filExpr: UserServiceFilterExpression = {
            field: userServiceField,
            operator: "=",
            value: rawValue
          };
          onAddEventFilter(filExpr);
        }
      }
    : null;

  const [open, setOpen] = useState(false);
  const openDrawer = useCallback(() => setOpen(true), []);
  const closeDrawer = useCallback(() => setOpen(false), []);

  return (
    <VerticallyCenteredRow className="fields-drilldown-table--row visible-on-hover">
      <VerticallyCenteredRow
        className="inc-flex-grow"
        style={{ maxWidth: "calc(100% - 50px)" }}
      >
        <IncSmartText text={value} />
      </VerticallyCenteredRow>

      {shouldShowAddFilter && (
        <IncFaIcon
          className="marginLt10 inc-cursor-pointer status-info display-element"
          iconName="plus-circle"
          onClick={onAddFilter}
        />
      )}

      <IncFaIcon
        className="marginLt10 inc-cursor-pointer display-element"
        iconName="indent"
        onClick={openDrawer}
      />

      <IncModal
        className="fields-drilldown-drawer-modal"
        onClose={closeDrawer}
        show={open}
        showClose
        size="side-pane"
        titleText={value}
      >
        <FieldDrilldownDrawer
          {...restProps}
          lookupData={lookupData}
          viewOnlyUSFilters={extendedUSFilters}
        />
      </IncModal>
    </VerticallyCenteredRow>
  );
});
