import { round } from "lodash";
import {
  ExploreEntityFilter,
  TriagePageFieldDrillDown,
  FieldDrillDownVizConfigs,
  UserServiceFilterList
} from "../../../services/api/explore";
import { ImpactedWidgetsDataEntry } from "../impact-widget/hooks";
import getChartColor from "../../charts/colors";
import { GroupedBarEntry } from "../../bar-group";

export const getDefaultFilters = () => ({
  entityFilters: [] as ExploreEntityFilter[],
  userServiceFilters: {} as Record<string, UserServiceFilterList>
});

export const getGroupedBarRows = (data: ImpactedWidgetsDataEntry["data"], tagName: string) => {
  const result = Object.values(data?.postAggData || {})[0];
  const dataArr = result?.data || [];

  let maxValue = Number.NEGATIVE_INFINITY;
  let minValue = Number.POSITIVE_INFINITY;
  let totalValue = 0;

  const bars = dataArr.map((datum, idx): GroupedBarEntry => {
    const { fields, labels = {}, eLabels = labels, id } = datum;

    const rawValue = labels[tagName];
    const tagValue = eLabels[tagName] || rawValue;

    let dataValue = fields[1].data[0] as number;
    dataValue = round(dataValue, 2);

    maxValue = Math.max(maxValue, dataValue);
    minValue = Math.min(minValue, dataValue);
    totalValue += dataValue;

    return {
      color: getChartColor(idx),
      id,
      name: tagValue,
      value: dataValue,
      custom: {
        rawValue
      }
    };
  });

  return {
    bars,
    maxValue,
    totalValue,
    minValue
  };
};

export const getPropsFromPreset = (preset: TriagePageFieldDrillDown, vizType: keyof FieldDrillDownVizConfigs) => {
  const { vizConfigs, slice: pSlice } = preset;

  const { slice: cSlice, title = "" } = vizConfigs[vizType] || {};

  return {
    slice: cSlice || pSlice,
    title
  };
};
