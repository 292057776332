import { IncButton, IncFaIcon, IncModal, IncModalProps, IncSmartText } from "@inception/ui";
import React, { useEffect, useState, useMemo, useCallback, memo } from "react";
import { cloneDeep } from "lodash";
import { KPI } from "../../services/api";
import { VerticallyCenteredRow } from "../flex-components";
import { IconInfoRenderer } from "../IconInfoRenderer";
import { KPIEditor } from "./KPIEditor";
import { KPIState, KPIEditorSection } from "./types";
import { getKPIDTOForKPI, getKPIForKPIDTO } from "./utils";

type Props = {
  kpi: KPI;
  onKPIUpdate: (kpi: KPI) => Promise<void>;
  onClose: () => void;

  getHeaderJsx?: () => string | JSX.Element;
  updateBtnLabel?: string;
  asSidePane?: boolean;
  sectionsToShow?: KPIEditorSection[];
};

export const KPIEditModalWithPreview = memo<Props>(props => {
  const {
    kpi: defKPI,
    onClose,
    onKPIUpdate,
    asSidePane = false,
    updateBtnLabel = "Update",
    sectionsToShow,
    getHeaderJsx
  } = props;

  const [isUpdateInProgress, setUpdateInProgress] = useState<boolean>(false);

  const defKpiIcon = defKPI?.icon;
  const defKpiName = defKPI?.name || "";
  const header = useMemo(
    () => (
      <VerticallyCenteredRow className="flex-gap-16">
        {Boolean(defKpiIcon) && (
          <div className="icon">
            <IconInfoRenderer
              icon={defKpiIcon}
              size={20}
            />
          </div>
        )}
        <div className="inc-text-header">{getHeaderJsx ? getHeaderJsx() : `Edit KPI - ${defKpiName}`}</div>
      </VerticallyCenteredRow>
    ),
    [defKpiIcon, defKpiName, getHeaderJsx]
  );

  const [kpiState, setKPIState] = useState<KPIState>({
    errorsText: "",
    isValid: true,
    kpiDTO: cloneDeep(getKPIDTOForKPI(defKPI))
  });

  const resetKPIState = useCallback(
    (shouldClose = false) => {
      setKPIState({
        errorsText: "",
        isValid: true,
        kpiDTO: cloneDeep(getKPIDTOForKPI(defKPI))
      });
      shouldClose && onClose();
    },
    [defKPI, onClose]
  );

  useEffect(() => {
    resetKPIState();
  }, [resetKPIState]);

  const onKPIUpdateInternal = useCallback(async () => {
    if (kpiState.isValid && !isUpdateInProgress) {
      setUpdateInProgress(true);
      await onKPIUpdate(getKPIForKPIDTO(kpiState.kpiDTO));
      setUpdateInProgress(false);
    }
  }, [isUpdateInProgress, kpiState.isValid, kpiState.kpiDTO, onKPIUpdate]);

  const actions = useMemo<IncModalProps["actions"]>(
    () => ({
      preJSX: (
        <>
          <IncButton
            color="primary"
            disabled={!kpiState.isValid || isUpdateInProgress}
            loading={isUpdateInProgress}
            loadingText="In Progress..."
            onClick={onKPIUpdateInternal}
          >
            {updateBtnLabel}
          </IncButton>

          {!isUpdateInProgress && (
            <IncButton
              color="secondary-blue"
              onClick={() => resetKPIState(true)}
            >
              Cancel
            </IncButton>
          )}

          {!kpiState.isValid && Boolean(kpiState.errorsText) && (
            <>
              <IncFaIcon
                className="status-danger"
                iconName="exclamation-triangle"
              />
              <IncSmartText
                className="inc-text-subtext-medium status-danger"
                text={kpiState.errorsText}
              />
            </>
          )}
        </>
      )
    }),
    [isUpdateInProgress, kpiState.errorsText, kpiState.isValid, onKPIUpdateInternal, resetKPIState, updateBtnLabel]
  );

  return (
    <IncModal
      actions={actions}
      className={asSidePane ? "" : "inc-regular-modal"}
      closeOnBackdrop={false}
      closeOnEscape={!isUpdateInProgress}
      disableFocusOnLoad
      onClose={() => resetKPIState(true)}
      show
      showClose={!isUpdateInProgress}
      size={asSidePane ? "side-pane" : "lg"}
      titleText={header}
    >
      <KPIEditor
        kpiState={kpiState}
        onChange={setKPIState}
        sectionsToShow={sectionsToShow}
      />
    </IncModal>
  );
});
