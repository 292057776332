import React, { FC, useEffect, useMemo, useState, CSSProperties, useCallback } from "react";
import {
  IncTabButtons,
  Tab,
  TableDataColumn,
  IncRTable,
  TableDataItem,
  FilterRendererProps,
  IncSmartText,
  IncFaIcon,
  IncRTableProps,
  IncModal
} from "@inception/ui";
import { pick, groupBy, inRange, isEqual } from "lodash";
import {
  ImpactedWidgetList,
  ImpactedWidget,
  ExploreEntityFilter,
  TimeObj,
  UserServiceFilterExpression,
  SelectorTag,
  UserServiceFilterList
} from "../../../services/api/explore";
import { TimeRange, useTimeRange, useToggleState } from "../../../core";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { isEntity, pluralizeWord } from "../../../utils";
import { SimpleEntityNameRenderer } from "../../entity-properties-popover";
import { MTSIncidentSummary, MTSIncidentSummaryLine } from "../../../services/api/operationalise";
import { ChartPoint } from "../../../dashboard/widgets/TimeSeries/models/model";
import LoadingSpinner from "../../Loading/Loading";
import { TableRangeFilterRenderer, TableSelectFilterRenderer } from "../../table-filter-renderers";
import { VerticallyCenteredRow } from "../../flex-components";
import { IncidentTimelineMonitoredSeriesContext, INCIDENT_STATUS_KEY } from "../types";
import { getMetricIdForImpactWidget } from "../utils";
import { IncidentDataResponseItem } from "../../../services/api/CommonAlerts";
import { FieldDrilldownDrawer } from "../field-drilldown/FieldDrilldownDrawer";
import { DrilldownSubRendererProps } from "../field-drilldown/types";
import {
  useFetchImpactedWidgetsData,
  ImpactedWidgetsDataEntryMap,
  getGroupKey,
  ImpactedWidgetsDataEntry
} from "./hooks";
import { ResultRenderer } from "./ResultRenderer";
import { getDataValue } from "./utils";
import { getGanttHeader, getGanttRows } from "./IncidentGanttRenderer";

interface Props {
  impactedWidgetsLists: ImpactedWidgetList[];
  incidentId: string;
  opConfigId: string;

  frequency?: TimeObj;
  lookBack?: TimeObj;

  tagsData: Array<Record<string, string>>;
  tagsToDisplay: string[];
  entityTags?: string[];
  op10zeIncidentData?: IncidentDataResponseItem;

  accessorHeaderMap?: Record<string, string>;
  lookupData?: Record<string, string>;

  entityFilters?: ExploreEntityFilter[];
  eventFieldFilters?: UserServiceFilterList;
  onAddFilter?: (value: string, tagName: string, displayValue: string) => void;

  timeRange?: TimeRange;
  hideGroupBy?: boolean;
  hideStatusColumn?: boolean;
  hideGanttChartRow?: boolean;
  addlColumns?: TableDataColumn[];

  drilldownProps?: DrilldownSubRendererProps;
  generateDemoData?: boolean;
}

export const ImpactedWidgetsRenderer: FC<Props> = props => {
  const {
    accessorHeaderMap,
    incidentId,
    opConfigId,
    impactedWidgetsLists,
    tagsData,
    entityTags,
    tagsToDisplay,
    lookupData,
    entityFilters,
    eventFieldFilters,
    timeRange: pTimeRange,
    onAddFilter,
    hideGroupBy = false,
    hideStatusColumn = false,
    hideGanttChartRow = false,
    addlColumns,
    frequency,
    lookBack,
    op10zeIncidentData,
    drilldownProps,
    generateDemoData
  } = props;

  const { close, isOpen, open } = useToggleState();

  const [selTagRec, setSelTagRec] = useState<Record<string, string>>({});
  const viewOnlyUSFilters = useMemo<UserServiceFilterExpression[]>(() => {
    const { opSchema } = drilldownProps || {};
    const { alertingEventFields, diagnosticEventFields } = opSchema || {};

    const allSlices = [...(alertingEventFields?.slices || []), ...(diagnosticEventFields?.slices || [])];

    const viewOnlyUSFilters: UserServiceFilterExpression[] = [];

    Object.keys(selTagRec).forEach(tagName => {
      const tagValue = selTagRec[tagName];
      const slice = allSlices.find(s => s.tagName === tagName);
      if (slice) {
        viewOnlyUSFilters.push({
          field: slice.userServiceField,
          operator: "=",
          value: tagValue
        });
      }
    });

    return viewOnlyUSFilters;
  }, [drilldownProps, selTagRec]);

  const onTagsUpdate = useCallback(
    (tags: Record<string, string>) => {
      setSelTagRec(tags);
      open();
    },
    [open]
  );

  const monitoredSeriesCtx = useMemo<IncidentTimelineMonitoredSeriesContext>(() => {
    const tags: SelectorTag[] = [];
    const titleTagsArr: string[] = [];

    Object.keys(selTagRec).forEach(tagName => {
      const shouldIncludeTag = tagsToDisplay.includes(tagName);

      if (shouldIncludeTag) {
        const tagValue = selTagRec[tagName];
        tags.push({
          key: tagName,
          value: [tagValue]
        });

        const includeTagKey = tagsToDisplay?.length > 1;
        const displayTagValue = lookupData?.[tagValue] || tagValue;
        const titleTag = includeTagKey ? `${tagName}: ${displayTagValue}` : displayTagValue;
        titleTagsArr.push(titleTag);
      }
    });

    const title = titleTagsArr.join(", ");

    return {
      lookBack,
      frequency,
      tags,
      title
    };
  }, [frequency, lookBack, lookupData, selTagRec, tagsToDisplay]);

  const [groupByStr, setGroupByStr] = useState<string>(NONE);

  const groupByTabs = useMemo(() => {
    const tagOptions = (tagsToDisplay || []).map(
      (tag): Tab => ({
        label: accessorHeaderMap?.[tag] || tag,
        id: tag
      })
    );

    return [noneOpt, ...tagOptions];
  }, [accessorHeaderMap, tagsToDisplay]);

  const { timeRange: gTimeRange } = useTimeRange();
  const timeRange = useMemo(() => pTimeRange || gTimeRange, [gTimeRange, pTimeRange]);

  const { startTimeMillis, endTimeMillis } = useMemo(() => {
    const tr = timeRange;
    const rawTr = tr.raw;
    const { from, to } = timeRangeUtils.getTimeRangeMillisFromRaw(rawTr);

    return {
      startTimeMillis: from,
      endTimeMillis: to
    };
  }, [timeRange]);

  const groupByTag = groupByStr === NONE ? null : groupByStr;

  const payloadGroupBy = useMemo(
    () => (groupByStr === NONE ? tagsToDisplay : [groupByStr]),
    [groupByStr, tagsToDisplay]
  );

  const impactedWidgets = useMemo(() => {
    const impactedWidgets: ImpactedWidget[] = [];
    impactedWidgetsLists.forEach(list => impactedWidgets.push(...list.impactedWidgets));
    return impactedWidgets;
  }, [impactedWidgetsLists]);

  const userServiceFilters = useMemo(() => {
    const userServiceFilters: Record<string, UserServiceFilterList> = {};

    if (eventFieldFilters) {
      impactedWidgets.forEach(iw => {
        const id = getMetricIdForImpactWidget(iw);
        if (id) {
          userServiceFilters[id] = eventFieldFilters;
        }
      });
    }

    return userServiceFilters;
  }, [eventFieldFilters, impactedWidgets]);

  const { refetch, resultsByImpactedWidget } = useFetchImpactedWidgetsData(
    impactedWidgets,
    incidentId,
    opConfigId,
    payloadGroupBy,
    entityFilters,
    userServiceFilters,
    startTimeMillis,
    endTimeMillis,
    tagsToDisplay,
    null,
    null,
    null,
    null,
    null,
    generateDemoData
  );

  useEffect(() => {
    if (tagsData?.length && impactedWidgetsLists?.length && incidentId) {
      refetch();
    }
  }, [impactedWidgetsLists, incidentId, refetch, tagsData]);

  const data = useMemo<TableDataEntry[]>(() => {
    const linesByTimeSeries = op10zeIncidentData?.incidentPayload?.incident?.timeSeriesSummary?.linesByTimeSeries || [];

    let dataEntries = tagsData.map(tagData => {
      let entry = {
        ...(tagData || {}),
        ...(resultsByImpactedWidget || {})
      };
      if (linesByTimeSeries) {
        const series = getSeriesForCurrentTags(linesByTimeSeries, tagData, tagsToDisplay);
        entry = {
          ...entry,
          ganttSeries: {
            tags: tagData,
            series: series
          } as any
        };
      }

      return entry;
    });

    if (groupByTag) {
      const groups = groupBy(dataEntries, entry => entry[groupByTag]);

      dataEntries = [];
      const expandedRows: Record<string, boolean> = {};
      Object.keys(groups).forEach(groupValue => {
        expandedRows[groupValue] = false;
        const subRows = groups[groupValue];

        const uniqTagValues: Record<string, Set<string>> = {};

        subRows.forEach(row => {
          tagsToDisplay.forEach(tag => {
            const tagSet = uniqTagValues[tag] || new Set();
            tagSet.add(row[tag] as string);
            uniqTagValues[tag] = tagSet;
          });
          if (row?.ganttSeries) {
            const allSeries = (row?.ganttSeries as any)?.series;
            const tags = (row?.ganttSeries as any)?.tags;
            const subSeries = (allSeries as any[])?.filter((serie: any) =>
              compareTags((serie as any).ts.tags, tags, tagsToDisplay)
            );
            (row.ganttSeries as any).series = subSeries;
          }
        });

        const tagData: Record<string, string> = {};
        tagsToDisplay.forEach(t => {
          let value: string;
          if (t === groupByTag) {
            value = groupValue;
          } else {
            const count = uniqTagValues[t]?.size || 0;
            const displayTag = accessorHeaderMap[t] || t;
            value = pluralizeWord(displayTag, count, true);
          }
          tagData[t] = value;
        });

        const series = getSeriesForCurrentTags(linesByTimeSeries, tagData, tagsToDisplay, groupByTag);
        const entry = {
          ...tagData,
          ...(resultsByImpactedWidget || {}),
          ganttSeries: {
            tags: tagData,
            series: series
          },
          subRows
        } as TableDataItem;
        dataEntries.push(entry);
      });
    }

    return dataEntries;
  }, [accessorHeaderMap, groupByTag, op10zeIncidentData, resultsByImpactedWidget, tagsData, tagsToDisplay]);

  const hasGroupBy = Boolean(groupByTag);

  const columns = useMemo(() => {
    const lColumns = getColumns(
      opConfigId,
      tagsToDisplay,
      accessorHeaderMap,
      entityTags,
      impactedWidgets,
      lookupData,
      payloadGroupBy,
      tagsToDisplay,
      data,
      onAddFilter,
      timeRange,
      frequency,
      lookBack,
      hasGroupBy,
      hideStatusColumn,
      !hideGanttChartRow && Boolean(op10zeIncidentData),
      onTagsUpdate
    );

    return [...(addlColumns || []), ...lColumns];
  }, [
    opConfigId,
    tagsToDisplay,
    accessorHeaderMap,
    entityTags,
    impactedWidgets,
    lookupData,
    payloadGroupBy,
    data,
    onAddFilter,
    timeRange,
    frequency,
    lookBack,
    hasGroupBy,
    hideStatusColumn,
    hideGanttChartRow,
    op10zeIncidentData,
    onTagsUpdate,
    addlColumns
  ]);

  const defaultSort = useMemo<IncRTableProps["sort"]>(() => {
    const primaryImpactWidgetId = impactedWidgets.find(iw => iw.isPrimary)?.id;
    if (!primaryImpactWidgetId) {
      return undefined;
    }

    return {
      accessor: primaryImpactWidgetId,
      order: "desc"
    };
  }, [impactedWidgets]);

  const title = useMemo(
    () =>
      tagsToDisplay
        .map(tagKey => {
          let value = selTagRec[tagKey];
          value = lookupData[value] || value;

          const key = accessorHeaderMap[tagKey] || tagKey;

          return [key, value].join(": ");
        })
        .join(" , "),
    [accessorHeaderMap, lookupData, selTagRec, tagsToDisplay]
  );

  return (
    <div
      className="impact-widget"
      data-has-groupby={hasGroupBy}
    >
      {groupByTabs.length > 2 && !hideGroupBy && (
        <VerticallyCenteredRow className="impact-widget--group-by">
          <VerticallyCenteredRow className="inc-label-common text-nowrap marginRt12">Group by</VerticallyCenteredRow>
          <IncTabButtons
            asPills
            onChange={setGroupByStr}
            selectedTabId={groupByStr}
            tabs={groupByTabs}
          />
        </VerticallyCenteredRow>
      )}

      <IncRTable
        columns={columns}
        data={data}
        density="compact"
        expandIconVariant="circle"
        pagination={pagination}
        showDisplayStats
        sort={defaultSort}
      />

      <IncModal
        className="fields-drilldown-drawer-modal"
        disableFocusOnLoad
        onClose={close}
        show={isOpen}
        size="side-pane"
        titleText={title}
      >
        {Boolean(drilldownProps) && Boolean(viewOnlyUSFilters.length) && (
          <FieldDrilldownDrawer
            {...drilldownProps}
            enableContextSelection
            lookupData={lookupData}
            monitoredSeriesCtx={monitoredSeriesCtx}
            timeRange={timeRange}
            userServiceFilters={userServiceFilters}
            viewOnlyUSFilters={viewOnlyUSFilters}
          />
        )}
      </IncModal>
    </div>
  );
};

const getColumns = (
  opConfigId: string,
  tagsToDisplay: Props["tagsToDisplay"],
  accessorHeaderMap: Props["accessorHeaderMap"],
  entityTags: Props["entityTags"],
  impactedWidgets: ImpactedWidget[],
  lookupData: Props["lookupData"],
  groupBy: string[],
  groupTags: string[],
  tableData: TableDataEntry[],
  onAddFilter: (value: string, tagName: string, displayValue: string) => void,
  timeRange: TimeRange,
  frequency: TimeObj,
  lookBack: TimeObj,
  hasGroupBy: boolean,
  hideStatusColumn: boolean,
  addGanttRow: boolean,
  setSelTagRec: (tags: Record<string, string>) => void
) => {
  const columns: Array<TableDataColumn<TableDataEntry>> = [];

  if (!hideStatusColumn) {
    columns.push({
      accessor: INCIDENT_STATUS_KEY,
      header: "",
      width: 40,
      className: "status-pill",
      renderer: value => {
        const isActive = value === "active";
        const isClosed = value === "stopped";

        const style: CSSProperties = {
          borderRadius: "0px 4px 4px 0px",
          background: isActive ? ACTIVE_COLOR : isClosed ? CLOSED_COLOR : "transparent"
        };

        return (
          <div
            className="status-pill--pill height-100"
            style={style}
          />
        );
      }
    });
  }

  tagsToDisplay.forEach(accessor => {
    const isGroupedColumn = hasGroupBy && groupBy.length === 1 && groupBy[0] === accessor;

    const header = accessorHeaderMap?.[accessor] || accessor;
    const column: TableDataColumn<TableDataEntry> = {
      accessor,
      header,
      renderer: (value: string, r, isSubRow) => {
        const renderAsEntity = isEntity(value) || entityTags?.includes(accessor);

        let renderValue: any = renderAsEntity ? value : lookupData?.[value] || value;

        const shouldRenderValue = !(isSubRow && isGroupedColumn);
        const shouldAddFilter = hasGroupBy ? isSubRow : true;

        if (shouldRenderValue) {
          const displayValue = lookupData?.[value] || value;
          const handleAddFilter = () => onAddFilter(value, accessor, displayValue);

          if (renderAsEntity) {
            const entityName = lookupData?.[value];
            renderValue = (
              <SimpleEntityNameRenderer
                id={value}
                name={entityName}
                showPropertiesPopover
              />
            );
          }

          return (
            <VerticallyCenteredRow className="visible-on-hover height-100">
              {typeof renderValue === "string" ? <IncSmartText text={renderValue} /> : renderValue}
              {Boolean(onAddFilter) && shouldAddFilter && (
                <IncFaIcon
                  className="status-info marginLt10 inc-cursor-pointer display-element"
                  iconName="plus-circle"
                  onClick={handleAddFilter}
                />
              )}
            </VerticallyCenteredRow>
          );
        }

        return " ";
      },
      sortable: true,
      sortFn: getSortFn(accessor, lookupData),
      filterable: true,
      filterFn: (rows, filters) => {
        if (!filters?.length) {
          return rows;
        }

        return rows.filter(row => {
          const value = row.original[accessor] as string;
          return filters.includes(value);
        });
      },
      filterRenderer: (props: FilterRendererProps<string[]>) => {
        const valuesSet: Set<string> = new Set();
        tableData.forEach(row => {
          const value = row[accessor];
          valuesSet.add(value);
        });

        const options = Array.from(valuesSet).map(value => {
          const label = lookupData?.[value] || value;

          return {
            label,
            value
          };
        });

        return (
          <TableSelectFilterRenderer
            {...props}
            label={header}
            options={options}
          />
        );
      }
    };

    if (isGroupedColumn) {
      columns.unshift(column);
    } else {
      columns.push(column);
    }
  });

  impactedWidgets.forEach(impactedWidget => {
    const { id, name } = impactedWidget;
    columns.push({
      accessor: id,
      header: name,
      renderer: (value: ImpactedWidgetsDataEntryMap, rowData: TableDataEntry, isSubRow: boolean) => {
        const groupByKeys = isSubRow ? groupTags : groupBy;
        const resultEntryKey = getGroupKey(groupByKeys);

        const tagsData = pick(rowData, groupByKeys) as Record<string, string>;
        const impactedData = value?.[resultEntryKey];

        return impactedData ? (
          <ResultRenderer
            dataEntry={impactedData}
            impactedWidget={impactedWidget}
            tagsData={tagsData}
          />
        ) : (
          <></>
        );
      },
      align: "center",
      filterable: true,
      filterRenderer: (props: FilterRendererProps<ChartPoint>) => {
        let isLoading = false;
        let min = Number.POSITIVE_INFINITY;
        let max = Number.NEGATIVE_INFINITY;

        const resultEntryKey = getGroupKey(groupBy);

        tableData.forEach(entry => {
          const resultMap = entry?.[id]?.[resultEntryKey] as ImpactedWidgetsDataEntry;
          const tagsData = pick(entry || {}, groupBy) as Record<string, string>;

          if (resultMap) {
            const { data, isFetching } = resultMap;

            const value = getDataValue(data, tagsData);

            isLoading = isLoading || isFetching;
            min = Math.min(min, value || 0);
            max = Math.max(max, value || 0);
          }
        });

        if (isLoading) {
          return <LoadingSpinner />;
        }

        min = min - 1;
        max = max + 1;

        return (
          <TableRangeFilterRenderer
            {...props}
            label={name}
            range={[min, max]}
          />
        );
      },
      filterFn: (rows, range) => {
        if (!range?.length) {
          return rows;
        }

        return rows.filter(row => {
          const { original } = row;
          const impactedDataMap = original[id];

          const resultEntryKey = getGroupKey(groupBy);

          const tagsData = pick(original, groupBy) as Record<string, string>;
          const impactedData = impactedDataMap?.[resultEntryKey]?.data;

          const value = getDataValue(impactedData, tagsData);

          return inRange(value, range[0], range[1]);
        });
      },
      sortable: true,
      sortFn: (a, b, _c, _d, sra, srb) => {
        const groupByKeysA = sra ? groupTags : groupBy;
        const groupByKeysB = srb ? groupTags : groupBy;

        const resultEntryKeyA = getGroupKey(groupByKeysA);
        const resultEntryKeyB = getGroupKey(groupByKeysB);

        const aTagsData = pick(a, groupByKeysA) as Record<string, string>;
        const bTagsData = pick(b, groupByKeysB) as Record<string, string>;

        const aImpactedData = a?.[id]?.[resultEntryKeyA]?.data;
        const bImpactedData = b?.[id]?.[resultEntryKeyB]?.data;

        const aValue = getDataValue(aImpactedData, aTagsData);
        const bValue = getDataValue(bImpactedData, bTagsData);

        if (aValue < bValue) {
          return "lesser";
        } else if (aValue > bValue) {
          return "greater";
        }

        return "equal";
      }
    });
  });

  if (addGanttRow) {
    const groupByKey = hasGroupBy ? groupBy[0] : "";
    columns.push({
      accessor: INCIDENT_STATUS_KEY,
      header: <div className="header-row">{getGanttHeader(timeRange, 700)}</div>,
      width: 700,
      align: "center",
      className: "sticky-right-40",
      headerClassName: "sticky-right-40",
      renderer: (value, rowData) =>
        getGanttRows(
          rowData.ganttSeries,
          timeRange,
          (rowData.subRows || []).map(x => x.ganttSeries),
          accessorHeaderMap,
          tagsToDisplay,
          lookupData,
          groupByKey
        )
    });

    if (frequency || lookBack) {
      columns.push({
        accessor: INCIDENT_STATUS_KEY,
        header: "",
        width: 40,
        align: "center",
        className: "sticky-right",
        headerClassName: "sticky-right",
        renderer: (value, rowData) => {
          const onClick = () => setSelTagRec(rowData);
          if (!rowData.subRows?.length) {
            return (
              <VerticallyCenteredRow
                className="inc-cursor-pointer status-info height-100"
                onClick={onClick}
              >
                <IncFaIcon iconName="up-right-from-square" />
              </VerticallyCenteredRow>
            );
          }
          return <></>;
        }
      });
    }
  }

  return columns;
};

type TableDataEntry = Record<string, string | ImpactedWidgetsDataEntryMap> & TableDataItem;

const NONE = "$__none";

const noneOpt: Tab = {
  label: "None",
  id: NONE
};

const pagination: IncRTableProps["pagination"] = {
  enabled: true,
  pageSize: 5,
  viewMode: "minimal"
};

const getSortFn = (key: string, lookupData: Record<string, string>) => {
  const sortFn = (a: TableDataEntry, b: TableDataEntry) => {
    const valA = (a[key] as string) || "";
    const valB = (b[key] as string) || "";

    const displayValA = (lookupData[valA] || valA).toLowerCase();
    const displayValB = (lookupData[valB] || valB).toLowerCase();
    const compareResult = displayValA.localeCompare(displayValB);

    if (compareResult < 0) {
      return "lesser";
    } else if (compareResult > 0) {
      return "greater";
    }
    return "equal";
  };

  return sortFn;
};

const getSeriesForCurrentTags = (
  linesByTimeSeries: MTSIncidentSummary[],
  tags: Record<string, string>,
  tagsToDisplay: string[],
  groupByTag?: string
): MTSIncidentSummaryLine[] => {
  const summaryLines: MTSIncidentSummaryLine[] = [];

  (linesByTimeSeries || []).forEach(lts => {
    const ltsTags = lts.summaryLine.ts.tags;
    if (groupByTag && ltsTags[groupByTag] === tags[groupByTag]) {
      if (ltsTags[groupByTag] === tags[groupByTag]) {
        summaryLines.push(lts.summaryLine);
      }
    } else {
      if (compareTags(ltsTags, tags, tagsToDisplay)) {
        summaryLines.push(lts.summaryLine);
      }
    }
  });

  return summaryLines;
};

const compareTags = (tagSet1: Record<string, string>, tagSet2: Record<string, string>, tagsToDisplay: string[]) => {
  const tags1: Record<string, string> = {};
  const tags2: Record<string, string> = {};
  tagsToDisplay.forEach(x => {
    tags1[x] = tagSet1[x];
    tags2[x] = tagSet2[x];
  });

  return isEqual(tags1, tags2);
};

const CLOSED_COLOR = "#BAE637";
const ACTIVE_COLOR = "#FF4D4F";
