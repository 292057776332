import React, { useCallback, useMemo, useState } from "react";
import { IncButton, IncFaIcon, IncModal, IncTextArea } from "@inception/ui";
import { VerticallyCenteredRow } from "../../components";
import { logger, useInputState, useNotifications, useToggleState, useTypedQueryParams } from "../../core";
import { FeedbackInput, chatApi } from "../../services/api/chat";

interface Props {
  messageId: string;
}

export const FeebackPanel: React.FC<Props> = (props: Props) => {
  const { chatId, useCaseId } = useTypedQueryParams();
  const { messageId } = props;
  const { isOpen, open, close } = useToggleState();
  const [isFeedbackPositive, setFeedbackPostive] = useState(false);
  const { inputValue, onInputChange, setInputValue } = useInputState("");
  const { notifySuccess, notifyError } = useNotifications();

  const closeFeebackModal = useCallback(() => {
    setInputValue("");
    close();
  }, [close, setInputValue]);

  const handleFeedbackIconClick = useCallback(
    (isPositive: boolean) => {
      setFeedbackPostive(isPositive);
      open();
    },
    [open]
  );

  const titleText = isFeedbackPositive
    ? "What did you like about this answer"
    : "Help us improve by sharing what went wrong";

  const sendFeeback = useCallback(async () => {
    try {
      const feebackInput: FeedbackInput = {
        category: "chat",
        comment: inputValue,
        isPositive: isFeedbackPositive
      };

      const response = await chatApi.postChatFeedback(chatId, useCaseId, messageId, feebackInput);
      if (response.data) {
        notifySuccess("Feedback sent");
      }
    } catch (e) {
      logger.error("Error sending feedback", e);
      notifyError("Failed to submit feeback");
    } finally {
      closeFeebackModal();
    }
  }, [chatId, closeFeebackModal, inputValue, isFeedbackPositive, messageId, notifyError, notifySuccess, useCaseId]);

  const footer = useMemo(
    () => (
      <VerticallyCenteredRow>
        <IncButton
          color="primary"
          onClick={sendFeeback}
        >
          Send Feedback
        </IncButton>
        <IncButton
          color="secondary-blue"
          onClick={closeFeebackModal}
        >
          Cancel
        </IncButton>
      </VerticallyCenteredRow>
    ),
    [closeFeebackModal, sendFeeback]
  );

  return (
    <VerticallyCenteredRow className="flex-gap-12">
      <IncFaIcon
        className="inc-cursor-pointer"
        iconName="thumbs-up"
        onClick={() => handleFeedbackIconClick(true)}
        variant="regular"
      />
      <IncFaIcon
        className="inc-cursor-pointer"
        iconName="thumbs-down"
        onClick={() => handleFeedbackIconClick(false)}
        variant="regular"
      />
      {isOpen && (
        <IncModal
          footerChildren={footer}
          onClose={closeFeebackModal}
          show={isOpen}
          size="sm"
          titleText={titleText}
        >
          <IncTextArea
            onChange={onInputChange}
            placeholder={isFeedbackPositive ? "What did we do right?" : "Tell us what went wrong"}
            rows={4}
            value={inputValue}
          />
        </IncModal>
      )}
    </VerticallyCenteredRow>
  );
};
