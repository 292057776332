import { IncFaIcon, IncFaIconName, IncButton } from "@inception/ui";
import React, { useState, useCallback } from "react";
import { useToggleState, useNotifications, useVerticalConfig, useTypedQueryParams, logger } from "../../core";
import {
  ObjectFragment,
  ConversationStatus,
  LowLevelFragmentList,
  ActionContext,
  chatApi
} from "../../services/api/chat";
import { VerticallyCenteredRow } from "../flex-components";
import { ObjectActionModal } from "./ObjectActionModal";

interface Props {
  objFragment: ObjectFragment;
  status: ConversationStatus;
}

export const ObjectFragmentRenderer: React.FC<Props> = ({ objFragment, status }) => {
  const { name, objectFragmentList: initialFragmentList, icon } = objFragment;
  const { isOpen, open, close } = useToggleState(true);
  const [fragmentList, setFragmentList] = useState(initialFragmentList);
  const { notifyError, notifySuccess } = useNotifications();
  const {
    verticalConfig: { companyName, useCaseId }
  } = useVerticalConfig();
  const { chatId } = useTypedQueryParams();

  const onUpdateFragment = useCallback((fragmentList: LowLevelFragmentList) => setFragmentList(fragmentList), []);

  const onActionClick = useCallback(async () => {
    try {
      const actionContext: ActionContext = {
        actionBody: {
          ...objFragment,
          objectFragmentList: fragmentList
        }
      };
      const response = await chatApi.runChatAction(chatId, actionContext, useCaseId, companyName);

      if (response.data) {
        notifySuccess(`${name} sent`);
      }
    } catch (e) {
      notifyError("failed to run action");
      logger.error("failed to perform action", e);
    }
  }, [chatId, companyName, fragmentList, name, notifyError, notifySuccess, objFragment, useCaseId]);

  return (
    <>
      <VerticallyCenteredRow className="object-fragment flex-gap-16 marginBt12">
        <IncFaIcon iconName={icon as IncFaIconName} />
        <span className="inc-text-body-medium">{name}</span>
        <IncButton
          className="marginLtAuto"
          color="secondary-blue"
          onClick={open}
        >
          View
        </IncButton>
      </VerticallyCenteredRow>
      <ObjectActionModal
        header={name}
        objectFragmentList={fragmentList}
        onActionClick={onActionClick}
        onClose={close}
        onUpdateFragmentList={onUpdateFragment}
        show={isOpen}
        status={status}
      />
    </>
  );
};
