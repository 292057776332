import { MetricAggregationsRecord } from "../../../../core";
import { USFieldWidgetUtils } from "../../../../dashboard/widgets/USField/USFieldWidgetUtils";
import { BizDataQuery, UserServiceFieldMetricConfigDefinition } from "../../../../services/api/explore";
import { OpCreationConfig } from "../../../../services/api/operationalise";
import { pluralizeWord } from "../../../../utils";

export const getMetricNameFromBizDataQuery = (bizDataQuery: BizDataQuery, eventTypeName: string) => {
  if (bizDataQuery) {
    const { labels, id, buildingBlockConfig, widgetConfig, sliceSpec } = bizDataQuery;
    const metricName = labels?.name || "";
    // Metric Operationalize case
    if (id) {
      return metricName;
    } else if (buildingBlockConfig) {
      const { buildingBlockDef } = buildingBlockConfig;
      const { fieldConfig, filters, aggregator } = buildingBlockDef || {};
      const { filterExpressions } = filters || {};
      const { userServiceField } = fieldConfig || {};

      const fieldName = userServiceField?.fieldName;
      const isEventIDField = USFieldWidgetUtils.isEventIDField(fieldName);
      const isHasErrorField =
        isEventIDField && filterExpressions?.find((f: any) => USFieldWidgetUtils.isHasErrorField(f.field.fieldName));

      if (isHasErrorField) {
        return `Failed ${pluralizeWord(eventTypeName)}`;
      } else if (isEventIDField) {
        return `Total ${pluralizeWord(eventTypeName)}`;
      } else {
        const aggregateDisplayName = MetricAggregationsRecord[aggregator]?.label;
        return [aggregateDisplayName, fieldName].join(" ");
      }
    } else if (widgetConfig) {
      const { dataDefinition } = widgetConfig;
      const { metrics } = dataDefinition || {};
      const metricId = sliceSpec?.metricId || "";
      const metric = metrics && metricId ? metrics[metricId] : null;
      if (metric) {
        const { name } = (metric as UserServiceFieldMetricConfigDefinition) || {};
        return name;
      }
    }
  }

  return "";
};

export const transformStartTriggersForOpConfig = (opConfig: OpCreationConfig) => {
  if (opConfig) {
    const { opCreationConfigDef, simulations } = opConfig;
    if (opCreationConfigDef) {
      if (opCreationConfigDef.triggerCondition?.startTriggerCondition && !opCreationConfigDef.startTrigger) {
        opCreationConfigDef.startTrigger = {
          allMatchStartTrigger: {
            triggerConditions: [
              {
                windowTrigger: opCreationConfigDef.triggerCondition.startTriggerCondition
              }
            ]
          }
        };
        delete opCreationConfigDef.triggerCondition;
      }
    }

    simulations?.forEach(sim => transformStartTriggersForOpConfig(sim.opCreationConfig));
  }
};

export const transformTimeZoneForOpConfig = (opConfig: OpCreationConfig, tenantTimeZone: string) => {
  if (Number(tenantTimeZone) === 1) {
    tenantTimeZone = "UTC";
  }
  if (opConfig) {
    if (opConfig.opCreationConfigDef?.schedule?.schedule) {
      opConfig.opCreationConfigDef.schedule.schedule.timeZone = tenantTimeZone;
    }

    if (opConfig.outlierConfig?.schedule?.schedule) {
      opConfig.outlierConfig.schedule.schedule.timeZone = tenantTimeZone;
    }

    if (opConfig.opAnalysisConfig?.schedule?.schedule) {
      opConfig.opAnalysisConfig.schedule.schedule.timeZone = tenantTimeZone;
    }

    if (opConfig.trendDetectionConfigDef?.schedule?.schedule) {
      opConfig.trendDetectionConfigDef.schedule.schedule.timeZone = tenantTimeZone;
    }
  }
};
