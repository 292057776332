import { forEach } from "lodash";
import { routePaths } from "../../../../../app/RoutePaths";
import { getFilterForURL, getFilterValueForURL } from "../../../../../features/traces/Analytics/utils";
import { TagFilterSelectionBySliceSet } from "../../../../biz-entity";

const getFilterOp = (values: string[]) => (values.length > 1 ? "in" : "=");

export const getBizFilter = (bizFilter: TagFilterSelectionBySliceSet) => {
  let filter = "";
  if (bizFilter?.selectionValues?.length) {
    const { fieldName } = bizFilter.selectionValues[0].slice;
    const selectionValues = bizFilter.selectionValues[0].selValToDValArr[0];
    const valArr = Object.values(selectionValues);
    const filterOp = getFilterOp(valArr);
    const filterValue = getFilterValueForURL(valArr.length > 1 ? valArr : valArr[0]);
    filter = `&filter=${getFilterForURL(fieldName, filterOp, filterValue)}`;
  }
  return filter;
};

export const getSliceQuery = (slice: TagFilterSelectionBySliceSet) => {
  let result = "";
  const fields = slice?.selectionValues || [];
  if (fields.length > 1) {
    const sliceNum = fields[0].selValToDValArr.length;
    const sliceQueryArr = [];
    for (let i = 0; i < sliceNum; i++) {
      const query: string[] = [];
      forEach(fields, field => {
        const { fieldName } = field.slice;
        const values = Object.values(field.selValToDValArr[i]);
        const filterOp = getFilterOp(values);
        const filterValue = getFilterValueForURL(values.length > 1 ? values : values[0]);
        const slice = `${fieldName} ${filterOp} ${filterValue}`;
        query.push(slice);
      });
      sliceQueryArr.push(`(${query.join(" AND ")})`);
    }
    result = sliceNum > 1 ? `(${sliceQueryArr.join(" OR ")})` : sliceQueryArr[0];
  }
  if (fields.length === 1) {
    const { fieldName } = fields[0].slice;
    const valuesRecord = fields[0].selValToDValArr;
    const valArray: string[] = [];
    forEach(valuesRecord, record => {
      const values = Object.values(record);
      forEach(values, value => (!valArray.includes(value) ? valArray.push(value) : null));
    });
    const filterOp = getFilterOp(valArray);
    const filterValue = getFilterValueForURL(valArray.length > 1 ? valArray : valArray[0]);
    result = `${fieldName} ${filterOp} ${filterValue}`;
  }

  return result;
};

export const getEventsURL = (link: string, bizFilter: string, sliceQuery: string, from: number, to: number) => {
  let params = link;
  params += bizFilter || "";
  params += sliceQuery ? `&query=${sliceQuery}` : "";
  params += "&mode=events";
  params += `&from=${from}&to=${to}`;
  const paramObj = new URLSearchParams(params);
  const queryArr = paramObj.getAll("query");
  const updatedQuery = queryArr.join(" AND ");
  paramObj.delete("query");
  paramObj.set("query", updatedQuery);
  const paramsStr = new URLSearchParams(paramObj).toString();
  const path = `${routePaths.traces}?${paramsStr}`;
  return path;
};
