import {
  formatNumber,
  getInceptionTheme,
  IncButton,
  IncClickAwayPopper,
  IncFaIcon,
  IncRTable,
  IncSmartText,
  TableDataColumn
} from "@inception/ui";
import { cloneDeep, isEqual, isNil } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CypressConstants } from "@bicycle/tests";
import { FieldPickerContainer, VerticallyCenteredRow } from "..";
import { dateTime, TimeRange, useTimeRange } from "../../core";
import {
  AlertSummaryEntry,
  FieldPickerContextDTO,
  FieldPickerOptionData,
  TriageClustersResponse,
  UserServiceFilterExpression,
  WidgetAlertsSummaryResponseV3
} from "../../services/api/explore";
import { FieldPickerUtils, noOp } from "../../utils";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { useFetchCorrelatedEvents } from "../../services/api/explore/hooks/useFetchCorrelatedEvents";
import { MetricSummaryInfo } from "../MetricSummInfo";
import { ChangeEventContext } from "./ChangeEventDrawer";

interface Props {
  alertSummary?: TriageClustersResponse;
  customAlertSummary?: WidgetAlertsSummaryResponseV3; // one of these two are required

  compareString: string;
  changeThresholdPercent?: number;
  correlatedEventInfo?: CorrelatedEventInfo;
  setChangeEventContext?: (ctx?: ChangeEventContext) => void;
  pickerContext?: FieldPickerContextDTO;
  enableCombinationSelection?: boolean;
  selectedFields?: FieldPickerOptionData[];
  onChangeFields?: (fields: FieldPickerOptionData[]) => void;
  isSpikePositive?: boolean;
  onAddEventFields?: (filterExprs: UserServiceFilterExpression[]) => void;
  isHighlightTable?: boolean;
}

type CorrelatedEventInfo = {
  incidentId: string;
  op10zeId: string;
  bins?: number;
};

type CorrelatedEventContext = CorrelatedEventInfo & {
  userServiceFilterExpressions: UserServiceFilterExpression[];
};

type CommonMetricInfo = {
  data: number;
  compareData: number;
  change: {
    deviation: "positive" | "negative";
    iconName: "caret-up" | "caret-down";
    value: number;
    percent: number;
  };
};

type AdditionalKpiInfo = CommonMetricInfo & {
  kpiName: string;
};

type DrilldownTableData = CommonMetricInfo & {
  combinationLabel: string;
  correlatedEventContext?: CorrelatedEventContext;
  setChangeEventContext?: (ctx?: ChangeEventContext) => void;
  isPreSelected?: boolean;
  additionalKpiInfo?: AdditionalKpiInfo[];
};

export const TriageTableDrillDown: React.FC<Props> = (props: Props) => {
  const {
    compareString,
    alertSummary,
    customAlertSummary,
    changeThresholdPercent,
    correlatedEventInfo,
    setChangeEventContext,
    pickerContext,
    enableCombinationSelection = false,
    selectedFields,
    onChangeFields,
    onAddEventFields,
    isSpikePositive = true,
    isHighlightTable = false
  } = props;

  const { groupColumns, kpiName, additionalKpi } = alertSummary || {};
  const { incidentId, op10zeId, bins } = correlatedEventInfo || {};

  const combinationHeader = useMemo(
    () =>
      enableCombinationSelection ? (
        <VerticallyCenteredRow>
          <span className="inc-text-body marginRt6">Combination</span>
          <FieldPickerPopperContainer
            onChangeFields={onChangeFields}
            pickerContext={pickerContext}
            selectedOptions={selectedFields}
          />
        </VerticallyCenteredRow>
      ) : (
        "Combination"
      ),
    [enableCombinationSelection, onChangeFields, pickerContext, selectedFields]
  );

  const columns = useMemo<Array<TableDataColumn<DrilldownTableData>>>(() => {
    const columns: Array<TableDataColumn<DrilldownTableData>> = [
      {
        accessor: "combinationLabel",
        header: combinationHeader,
        sortable: true,
        width: "40%",
        renderer: (cellData, rowData: DrilldownTableData) => (
          <VerticallyCenteredRow className="visible-on-hover">
            <IncSmartText text={rowData.combinationLabel} />
            {correlatedEventInfo && <CorrelatedEventCell {...rowData} />}
            {Boolean(onAddEventFields) && (
              <AddfilterAction
                onAddEventFields={onAddEventFields}
                rowData={rowData}
              />
            )}
          </VerticallyCenteredRow>
        )
      }
    ];
    if (!groupColumns) {
      columns.push(
        {
          accessor: "data",
          header: "Current",
          sortable: true,
          type: "number"
        },
        {
          accessor: "compareData",
          header: compareString,
          sortable: true,
          type: "number"
        },
        {
          accessor: "change",
          header: "Change",
          sortable: true,
          sortFn: (a: DrilldownTableData, b: DrilldownTableData) =>
            a?.change?.value > b?.change?.value
              ? "greater"
              : a?.change?.value === b?.change?.value
                ? "equal"
                : "lesser",
          renderer: (cellData, rowData: DrilldownTableData) => {
            const {
              change: {
                value
                // percent
              }
            } = rowData;

            // const barWidth = percent > 100 ? "100%" : `${percent}%`;

            return (
              <VerticallyCenteredRow>
                {isNil(value) && ""}
                {!isNil(value) && (
                  <>
                    <span className="inc-text-body marginRt8">{value ? formatNumber(value) : ""}</span>
                    {/* <div style={{ width: "80%" }}>
                  {value && (
                    <HorizontalBar
                      backgroundColor={"transparent"}
                      barColor={"#39ACFF"}
                      barWidth={barWidth}
                      bordered={false}
                      size="xxs"
                    />
                  )}
                </div> */}
                  </>
                )}
              </VerticallyCenteredRow>
            );
          }
        },
        {
          accessor: "change",
          header: "Change Percent",
          sortable: true,
          sortFn: (a: DrilldownTableData, b: DrilldownTableData) =>
            a?.change?.percent > b?.change?.percent
              ? "greater"
              : a?.change?.percent === b?.change?.percent
                ? "equal"
                : "lesser",
          renderer: (cellData: any, rowData: DrilldownTableData) => {
            const {
              change: { deviation, percent, iconName }
            } = rowData;

            const isPositive = deviation === "positive";
            const color = isPositive
              ? getInceptionTheme().inceptionColors.accentGreen
              : getInceptionTheme().inceptionColors.accentRed;

            return (
              <VerticallyCenteredRow>
                {isNil(percent) && "-"}
                {!isNil(percent) && (
                  <>
                    <IncFaIcon
                      className="marginRt6"
                      color={color}
                      iconName={iconName}
                    />
                    <span className="inc-text-body">{formatNumber(percent)}%</span>
                  </>
                )}
              </VerticallyCenteredRow>
            );
          }
        }
      );
    } else {
      columns.push({
        accessor: "data",
        header: kpiName,
        sortable: true,
        sortFn: (a: DrilldownTableData, b: DrilldownTableData) =>
          a?.data > b?.data ? "greater" : a?.data === b?.data ? "equal" : "lesser",
        renderer: (cellData: any, rowData: DrilldownTableData) => {
          const { data, change, compareData } = rowData;
          const { percent, deviation } = change || {};
          const isPositiveChange = deviation === "positive";

          return (
            <VerticallyCenteredRow>
              {isNil(data) && "-"}
              {!isNil(data) && (
                <MetricSummaryInfo
                  changePercent={percent.toString()}
                  compareLabel={compareString}
                  compareValue={compareData}
                  dataType={"string"}
                  isPositiveChange={isPositiveChange}
                  subType={"none"}
                  value={data}
                />
              )}
            </VerticallyCenteredRow>
          );
        }
      });
      additionalKpi.forEach(kpi => {
        columns.push({
          accessor: "data",
          header: kpi,
          sortable: true,
          sortFn: (a: DrilldownTableData, b: DrilldownTableData) => {
            const { additionalKpiInfo: aAdditionalKpiInfo } = a;
            const { additionalKpiInfo: bAdditionalKpiInfo } = b;
            const aKpiInfo = aAdditionalKpiInfo.find(x => x.kpiName === kpi);
            const bKpiInfo = bAdditionalKpiInfo.find(x => x.kpiName === kpi);
            return aKpiInfo?.data > bKpiInfo?.data ? "greater" : aKpiInfo?.data === bKpiInfo?.data ? "equal" : "lesser";
          },
          renderer: (cellData: any, rowData: DrilldownTableData) => {
            const { additionalKpiInfo } = rowData;
            const kpiInfo = additionalKpiInfo.find(x => x.kpiName === kpi);
            const { data, change, compareData } = kpiInfo || {};
            const { percent, deviation } = change || {};
            const isPositiveChange = deviation === "positive";
            return (
              <VerticallyCenteredRow>
                {isNil(data) && "-"}
                {!isNil(data) && (
                  <MetricSummaryInfo
                    changePercent={percent?.toString() || ""}
                    compareLabel={compareString}
                    compareValue={compareData}
                    dataType={"string"}
                    isPositiveChange={isPositiveChange}
                    subType={"none"}
                    value={data}
                  />
                )}
              </VerticallyCenteredRow>
            );
          }
        });
      });
    }

    return columns;
  }, [combinationHeader, groupColumns, correlatedEventInfo, onAddEventFields, compareString, kpiName, additionalKpi]);

  const tableData = useMemo(
    () =>
      getTableDataBasedOnResponse(
        alertSummary,
        customAlertSummary,
        incidentId,
        op10zeId,
        changeThresholdPercent,
        bins,
        setChangeEventContext,
        isSpikePositive
      ),
    [
      alertSummary,
      customAlertSummary,
      bins,
      changeThresholdPercent,
      incidentId,
      op10zeId,
      setChangeEventContext,
      isSpikePositive
    ]
  );

  const getRowStyle = useCallback(
    (row: DrilldownTableData) => {
      let pStyle = {};
      if (row?.isPreSelected && isHighlightTable) {
        pStyle = {
          borderRadius: "0px",
          background: "#26587C",
          boxShadow: "0px 2.088px 12.529px 0px rgba(0, 0, 0, 0.30)"
        };
      }
      return pStyle;
    },
    [isHighlightTable]
  );

  return (
    <div
      className="triage-table-drilldown"
      data-cy={CypressConstants.features.Triage.attributes.drilldownTable}
    >
      <IncRTable
        columns={columns}
        data={tableData}
        getRowStyle={getRowStyle}
      />
    </div>
  );
};

const CorrelatedEventCell: FC<DrilldownTableData> = props => {
  const { correlatedEventContext, setChangeEventContext, combinationLabel } = props;
  const { incidentId, op10zeId, bins, userServiceFilterExpressions } = correlatedEventContext;

  const { timeRange } = useTimeRange();

  const { toMillis, fromMillis } = useMemo(() => {
    const { fromMillis: incidentFromMillis, toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);
    const fromMillis = dateTime(incidentFromMillis).subtract("3", "days").valueOf();

    return {
      toMillis,
      fromMillis
    };
  }, [timeRange]);

  const { data, isFetching, refetch } = useFetchCorrelatedEvents(
    incidentId,
    op10zeId,
    fromMillis,
    toMillis,
    userServiceFilterExpressions,
    bins
  );

  const tr: TimeRange = useMemo(
    () => ({
      from: dateTime(fromMillis),
      to: dateTime(toMillis),
      raw: {
        from: fromMillis.toString(),
        to: toMillis.toString()
      }
    }),
    [fromMillis, toMillis]
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const eventsLength = data?.resultList?.[0]?.correlatedEventList?.[0]?.correlatedEvents?.length || 0;

  const handleChangeEventContext = useCallback(() => {
    if (setChangeEventContext) {
      setChangeEventContext({
        userServiceFilterExpressions,
        drawerLabel: combinationLabel,
        timeRange: tr
      });
    }
  }, [combinationLabel, setChangeEventContext, tr, userServiceFilterExpressions]);

  return (
    <>
      {isFetching ? (
        <IncFaIcon
          className="marginLt10"
          iconName="spinner"
          spin
        />
      ) : eventsLength ? (
        <IncFaIcon
          className="marginLt10 inc-cursor-pointer"
          iconName="calendar-days"
          onClick={handleChangeEventContext}
        />
      ) : null}
    </>
  );
};

type AddfilterProps = {
  rowData: DrilldownTableData;
  onAddEventFields?: (filterExprs: UserServiceFilterExpression[]) => void;
};

const AddfilterAction: FC<AddfilterProps> = props => {
  const { rowData, onAddEventFields } = props;
  const onAddFilters = useCallback(() => {
    const expressions = rowData.correlatedEventContext?.userServiceFilterExpressions;
    if (onAddEventFields && expressions) {
      onAddEventFields(expressions);
    }
  }, [onAddEventFields, rowData.correlatedEventContext?.userServiceFilterExpressions]);
  const onAddNotEqualFilters = useCallback(() => {
    const expressions = rowData.correlatedEventContext?.userServiceFilterExpressions?.map(e => ({
      ...e,
      operator: "!="
    }));
    if (onAddEventFields && expressions) {
      onAddEventFields(expressions);
    }
  }, [onAddEventFields, rowData.correlatedEventContext?.userServiceFilterExpressions]);
  const redColor = useMemo(() => getInceptionTheme().inceptionColors.accentRed, []);
  return (
    <div className="inc-flex inc-flex-row inc-flex-vertical-center marginLt8 display-element flex-gap-8">
      <IncButton
        color="link"
        onClick={onAddNotEqualFilters}
      >
        <IncFaIcon
          color={redColor}
          iconName="circle-minus"
        />
      </IncButton>
      <IncButton
        color="link"
        onClick={onAddFilters}
      >
        <IncFaIcon iconName="circle-plus" />
      </IncButton>
    </div>
  );
};

interface FieldPickerPopperProps {
  pickerContext: FieldPickerContextDTO;
  onChangeFields: (options: FieldPickerOptionData[]) => void;
  selectedOptions: FieldPickerOptionData[];
}

const FieldPickerPopperContainer = (props: FieldPickerPopperProps) => {
  const { pickerContext, onChangeFields, selectedOptions } = props;
  const [open, setOpen] = useState(false);
  const openPicker = useCallback(() => setOpen(true), []);

  const [pickerOptions, setPickerOptions] = useState(selectedOptions);

  const onConfirmClick = useCallback(() => {
    onChangeFields(pickerOptions);
    setOpen(false);
  }, [onChangeFields, pickerOptions]);

  const onClose = useCallback(() => {
    setOpen(false);
    setPickerOptions(selectedOptions);
  }, [selectedOptions]);

  const anchorEl = useRef(null);

  return (
    <div>
      <span
        className="inc-cursor-pointer"
        ref={anchorEl}
      >
        <IncFaIcon
          iconName="gear"
          onClick={openPicker}
        />
      </span>

      <IncClickAwayPopper
        anchorEl={anchorEl.current}
        className="field-picker-popper-container"
        onClickAway={noOp}
        placement="bottom-start"
        show={open}
      >
        <div className="inc-flex-column">
          <FieldPickerContainer
            fieldPickerContextDto={pickerContext}
            fieldTypes={["userServiceField"]}
            isMulti={true}
            label=""
            onChange={setPickerOptions}
            selectedOptions={pickerOptions}
          />
          <VerticallyCenteredRow className="marginTp16">
            <IncButton
              color="primary"
              onClick={onConfirmClick}
            >
              Save
            </IncButton>
            <IncButton
              className="marginLt16"
              color="secondary-blue"
              onClick={onClose}
            >
              Cancel
            </IncButton>
          </VerticallyCenteredRow>
        </div>
      </IncClickAwayPopper>
    </div>
  );
};

const getTableDataBasedOnResponse = (
  alertSummary: TriageClustersResponse,
  customAlertSummary: WidgetAlertsSummaryResponseV3,
  incidentId: string,
  op10zeId: string,
  changeThresholdPercent: number,
  bins: number,
  setChangeEventContext: (ctx?: ChangeEventContext) => void,
  isSpikePositive?: boolean // used for customerAlertSummary use case
) => {
  const rows: DrilldownTableData[] = [];

  if (alertSummary) {
    const {
      entityLookupData = {},
      isSpikeGood,
      highlightedClusters = [],
      clusters = [],
      isSpikeGoodForAdditionalKpi
    } = alertSummary || {};

    clusters?.forEach(cluster => {
      const { currentCount: value, previousCount: compareVal, additionalKpiClusters, fields } = cluster;
      const labels: string[] = [];
      const { change, compareData, data } = getCommonMetricInfo(value, compareVal, isSpikeGood);

      let isPreSelected = false;
      const isClusterPreSeclected = highlightedClusters?.find(highlightedCluster =>
        isEqual(highlightedCluster, cluster)
      );
      if (isClusterPreSeclected) {
        isPreSelected = true;
      }

      const additionalKpiInfo: AdditionalKpiInfo[] = additionalKpiClusters?.map(kpiCluster => {
        const { currentCount, kpiName, previousCount } = kpiCluster || {};
        const isSpikeGoodInternal = !isNil(isSpikeGoodForAdditionalKpi?.[kpiName])
          ? isSpikeGoodForAdditionalKpi[kpiName]
          : isSpikeGood;
        const metricInfo = getCommonMetricInfo(currentCount, previousCount, isSpikeGoodInternal);
        return {
          ...metricInfo,
          kpiName: kpiName
        };
      });

      fields.forEach(x => {
        const fieldLabel = FieldPickerUtils.getUserServiceFieldLabel(x.field);
        const value = entityLookupData[x.value] ? entityLookupData[x.value] : x.value;
        labels.push(`${fieldLabel} : ${value}`);
      }, []);

      const shouldPushRow = changeThresholdPercent ? change?.percent > changeThresholdPercent : true;

      if (shouldPushRow) {
        rows.push({
          setChangeEventContext,
          change,
          compareData,
          data,
          combinationLabel: labels.join(" + "),
          correlatedEventContext: {
            userServiceFilterExpressions: cluster.fields.map(field => ({
              field: field.field,
              value: field.value,
              operator: "="
            })),
            incidentId,
            op10zeId,
            bins
          },
          isPreSelected: isPreSelected,
          additionalKpiInfo
        });
      }
    });
  } else if (customAlertSummary) {
    const { combinations, entityLookupData } = customAlertSummary;
    combinations.forEach(combination => {
      const appendRowsFromCombination = (
        combination: AlertSummaryEntry,
        rows: DrilldownTableData[],
        labels: string[] = [],
        userServiceExpressions: UserServiceFilterExpression[] = []
      ) => {
        const clonedLabels = cloneDeep(labels);
        const cUserServiceExpressions = cloneDeep(userServiceExpressions);
        const fieldLabel = FieldPickerUtils.getUserServiceFieldLabel(combination.field);
        const fieldVal = entityLookupData[combination.value.value]
          ? entityLookupData[combination.value.value]
          : combination.value.value;

        clonedLabels.push(`${fieldLabel} : ${fieldVal}`);

        cUserServiceExpressions.push({
          field: combination.field,
          value: combination.value.value,
          operator: "="
        });

        if (combination.drilldown.length) {
          combination.drilldown.forEach(x => {
            appendRowsFromCombination(x, rows, clonedLabels, cUserServiceExpressions);
          });
        } else {
          const value = combination.value.currentCount;
          const compareVal = combination.value.previousCount;
          const changeVal = !isNil(value) && !isNil(compareVal) ? Math.abs(value - compareVal) : null;
          const changePercent =
            !isNil(value) && !isNil(compareVal) && !isNil(changeVal) ? (changeVal / compareVal) * 100 : null;
          let deviation: "positive" | "negative" = null;

          const iconName = value > compareVal ? "caret-up" : "caret-down";

          if (isSpikePositive) {
            deviation = value > compareVal ? "positive" : "negative";
          } else {
            deviation = value > compareVal ? "negative" : "positive";
          }

          const shouldPushRow = changeThresholdPercent ? changePercent > changeThresholdPercent : true;
          if (shouldPushRow) {
            rows.push({
              setChangeEventContext,
              combinationLabel: clonedLabels.join(" + "),
              change: {
                deviation,
                value: changeVal as number,
                percent: changePercent,
                iconName
              },
              compareData: !isNil(compareVal) ? compareVal : null,
              data: value,
              correlatedEventContext: {
                userServiceFilterExpressions: cUserServiceExpressions,
                incidentId,
                op10zeId,
                bins
              }
            });
          }
        }
      };

      const currentRows: DrilldownTableData[] = [];
      appendRowsFromCombination(combination, currentRows);
      rows.push(...currentRows);
    });
  }

  return rows;
};

const getCommonMetricInfo = (value: number, compareVal: number, isSpikeGood: boolean): CommonMetricInfo => {
  let deviation: "positive" | "negative" = null;

  const iconName = value > compareVal ? "caret-up" : "caret-down";
  if (isSpikeGood) {
    deviation = value > compareVal ? "positive" : "negative";
  } else {
    deviation = value > compareVal ? "negative" : "positive";
  }

  const changeVal = !isNil(value) && !isNil(compareVal) ? value - compareVal : null;
  const changePercent =
    !isNil(value) && !isNil(compareVal) && !isNil(changeVal) ? (Math.abs(changeVal) / compareVal) * 100 : null;

  return {
    change: {
      deviation,
      value: changeVal as number,
      percent: changePercent,
      iconName
    },
    compareData: compareVal,
    data: value
  };
};
