import { NAME_TAG } from "../../../core";
import { MonitoredDataSchema } from "../../../services/api/operationalise";

export const getResultSchemaFromMonitoredDataSchema = (
  dataSchema: MonitoredDataSchema
): Array<Record<string, string>> => {
  const { series } = dataSchema;
  return series.map(serie => {
    const { timeSeries } = serie;
    const { label, metricName } = timeSeries;
    const labels = label.reduce(
      (acc, tag) => {
        const { name, value } = tag;
        acc[name] = value;
        return acc;
      },
      {} as Record<string, string>
    );

    labels[NAME_TAG] = metricName;

    return labels;
  });
};
