import { useMemo, useCallback } from "react";
import { InitialState, FetchCallBackResult, useDataFetch } from "../../../../core";
import { triageV2ApiService } from "../TriageV2ApiService";
import { TriageSummaryTypes } from "../types";

export const useFetchInsightSummary = (
  opId: string,
  incidentId: string,
  defInsightSummary?: TriageSummaryTypes.InsightSummary,
  generateDemoData = false
) => {
  const initialData = useMemo<InitialState<TriageSummaryTypes.TriageStatusResponse, string>>(
    () => ({
      data: defInsightSummary
        ? {
            insightSummary: defInsightSummary,
            status: TriageSummaryTypes.TriageStatus.completed
          }
        : null,
      error: null,
      isError: false,
      isFetching: !defInsightSummary,
      isSuccess: false
    }),
    [defInsightSummary]
  );

  const fetchSuggestedUsecases = useCallback(async () => {
    const result: FetchCallBackResult<TriageSummaryTypes.TriageStatusResponse, string> = {
      data: null,
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const { data, error, message } = await triageV2ApiService.fetchTriageSummary(opId, incidentId, generateDemoData);

      if (error) {
        result.error = message;
        result.isError = true;
      } else {
        result.data = data;
        result.isSuccess = true;
      }
    } catch (e) {
      result.error = e;
      result.isError = true;
    }
    return result;
  }, [generateDemoData, opId, incidentId]);

  return useDataFetch(fetchSuggestedUsecases, initialData, true);
};
