import React, { FC, useEffect, useState, useMemo, useCallback } from "react";
import { FieldPickerContainer, VerticallyCenteredRow } from "../../../../components";
import { logger, useTimeRange, useToggleState } from "../../../../core";
import { useFieldPicker } from "../../../../field-picker";
import {
  FieldPickerContextDTO,
  FieldPickerOptionData,
  UserServiceField,
  UserServiceFieldSlice,
  UserServiceFieldSliceSet
} from "../../../../services/api/explore";
import { operationaliseV2ApiService, OpTriageConfig } from "../../../../services/api/operationalise";
import { FieldPickerUtils } from "../../../../utils";
import timeRangeUtils from "../../../../utils/TimeRangeUtils";
import { setOpTriageConfig, useOpStore } from "../../../context";
import { ImpactFields } from "./ImpactFields";

interface Props {
  saveOrUpdateInProgress: boolean;
  setSaveOrUpdateInProgress: (saveOrUpdateInProgress: boolean) => void;

  initialized: boolean;
  setInitialized: (init: boolean) => void;
}

export const ImpactAndCausalFieldsEditor: FC<Props> = props => {
  const { saveOrUpdateInProgress, setSaveOrUpdateInProgress, initialized, setInitialized } = props;

  const beforeTriageConfigUpdate = useCallback(() => setSaveOrUpdateInProgress(true), [setSaveOrUpdateInProgress]);
  const afterTriageConfigUpdate = useCallback(() => setSaveOrUpdateInProgress(false), [setSaveOrUpdateInProgress]);

  const { timeRange } = useTimeRange();
  const { fromMillis, toMillis } = useMemo(() => timeRangeUtils.getMillisFromTimeRange(timeRange), [timeRange]);

  const { dispatch, state } = useOpStore();

  const { context, op10zeId, opCreationConfig } = state;

  const { widgetId, entityTypeId, eventTypeId } = context;

  const updateTriageConfig = useCallback(
    async (partOpTriageConfig: Partial<OpTriageConfig>) => {
      const updateConfig = () =>
        dispatch(
          setOpTriageConfig({
            diagnosticFields: null,
            impactFields: null,
            impactedWidgets: null,
            ...partOpTriageConfig
          })
        );

      if (op10zeId) {
        beforeTriageConfigUpdate();

        try {
          const { data, error, message } = await operationaliseV2ApiService.updateOpTriageConfig(
            op10zeId,
            partOpTriageConfig
          );

          if (error) {
            logger.error("ImpactAndCausalFields", "Error updating triage config", {
              data,
              message
            });
          } else {
            updateConfig();
          }
        } catch (err) {
          logger.error("ImpactAndCausalFields", "Error updating triage config", err);
        } finally {
          afterTriageConfigUpdate();
        }
      } else {
        updateConfig();
      }
    },
    [afterTriageConfigUpdate, beforeTriageConfigUpdate, dispatch, op10zeId]
  );

  const {
    diagnosticFields: initDiagnosticFields,
    impactedWidgets: initImpactedWidgets,
    impactFields: initImpactFields
  } = opCreationConfig;

  const {
    close: afterDefaultConfigFetched,
    isOpen: isDefaultConfigFetching,
    open: beforeDefaultConfigFetched
  } = useToggleState(true);

  const [editorState, setEditorState] = useState<OpTriageConfig>({
    diagnosticFields: initDiagnosticFields || { slices: [] },
    impactFields: initImpactFields || { slices: [] },
    impactedWidgets: initImpactedWidgets || {
      impactedWidgets: []
    }
  });

  const { diagnosticFields, impactFields, impactedWidgets } = editorState;

  useEffect(() => {
    setEditorState({
      diagnosticFields: initDiagnosticFields || { slices: [] },
      impactFields: initImpactFields || { slices: [] },
      impactedWidgets: initImpactedWidgets || {
        impactedWidgets: []
      }
    });
  }, [initDiagnosticFields, initImpactFields, initImpactedWidgets]);

  const fetchDefaultImpactedWidgets = useCallback(async () => {
    beforeDefaultConfigFetched();

    try {
      const { data, error, message } = await operationaliseV2ApiService.getDefaultOpTriageConfig(
        opCreationConfig,
        widgetId
      );

      if (error) {
        logger.error("ImpactAndCausalFields", "Error fetching default triage config", {
          error,
          data,
          message
        });
      } else {
        updateTriageConfig({
          ...data
        });
        setInitialized(true);
      }
    } catch (err) {
      logger.error("ImpactAndCausalFields", "Error fetching default triage config", err);
    } finally {
      afterDefaultConfigFetched();
    }
  }, [
    beforeDefaultConfigFetched,
    opCreationConfig,
    widgetId,
    setInitialized,
    updateTriageConfig,
    afterDefaultConfigFetched
  ]);

  useEffect(() => {
    if (!op10zeId && !initialized) {
      fetchDefaultImpactedWidgets();
    } else {
      afterDefaultConfigFetched();
    }
  }, [fetchDefaultImpactedWidgets, op10zeId, afterDefaultConfigFetched, initialized]);

  const fieldPickerContext = useMemo<FieldPickerContextDTO>(
    () => ({
      entityType: entityTypeId,
      bizEntityType: entityTypeId,
      showFields: true,
      userServices:
        entityTypeId && eventTypeId
          ? [
              {
                userServiceEntityId: eventTypeId
              }
            ]
          : [],
      entityId: entityTypeId ? null : eventTypeId,
      entityName: null
    }),
    [entityTypeId, eventTypeId]
  );

  const {
    data: fieldPickerModel,
    error: fieldPickerError,
    getFields,
    isFetching: isFieldPickerFetching,
    isError: isFieldPickerError
  } = useFieldPicker();

  useEffect(() => {
    if (entityTypeId || eventTypeId) {
      getFields(fieldPickerContext, fromMillis, toMillis);
    }
  }, [entityTypeId, eventTypeId, fieldPickerContext, fromMillis, getFields, toMillis]);

  const onImpactFieldsChange = useCallback(
    (impactedWidgets: OpTriageConfig["impactedWidgets"], impactFields: UserServiceFieldSliceSet) => {
      updateTriageConfig({
        impactFields,
        impactedWidgets
      });
    },
    [updateTriageConfig]
  );

  const selectedDiagnosticFields = useMemo(
    () =>
      diagnosticFields.slices.map(
        (slice): FieldPickerOptionData => ({
          payload: slice.userServiceField,
          type: "userServiceField"
        })
      ),
    [diagnosticFields]
  );

  const saveDiagnosticFields = useCallback(
    (diagnosticFields: UserServiceFieldSliceSet) => {
      updateTriageConfig({ diagnosticFields });
    },
    [updateTriageConfig]
  );

  const onDiagnosticFieldsChange = useCallback(
    (options: FieldPickerOptionData[], isFieldPickerClosed: boolean) => {
      const nDiagnosticFields = options.map((opt): UserServiceFieldSlice => {
        const usField = opt.payload as UserServiceField;

        return {
          tagName: FieldPickerUtils.getPromSanitizedUSFName(usField),
          userServiceField: usField
        };
      });

      if (isFieldPickerClosed) {
        saveDiagnosticFields({
          slices: nDiagnosticFields
        });
      } else {
        setEditorState(prevState => ({
          ...prevState,
          diagnosticFields: {
            slices: nDiagnosticFields
          }
        }));
      }
    },
    [saveDiagnosticFields]
  );

  const isLoading = isFieldPickerFetching || isDefaultConfigFetching || saveOrUpdateInProgress;
  const displayFieldPickerError = isFieldPickerError ? String(fieldPickerError) : null;

  return (
    <VerticallyCenteredRow className="impact-and-causal-fields">
      <ImpactFields
        fieldPickerError={displayFieldPickerError}
        fieldPickerModel={fieldPickerModel}
        impactedFields={impactFields}
        impactedWidgets={impactedWidgets}
        isLoading={isLoading}
        onSave={onImpactFieldsChange}
      />

      <div className="vertical-separator" />

      <FieldPickerContainer
        disabled={isLoading}
        fieldPickerContextDto={fieldPickerContext}
        fieldPickerModel={fieldPickerModel}
        fieldTypes={["userServiceField"]}
        isLoading={isLoading}
        isMulti
        label="Impact contributors"
        onChange={onDiagnosticFieldsChange}
        selectedOptions={selectedDiagnosticFields}
      />
    </VerticallyCenteredRow>
  );
};
