import {
  BizField,
  MetricSourceType,
  FieldSourceType,
  UserServiceField,
  BizServiceMetric,
  UserServiceMetric,
  EntityOperation,
  UserServiceFilterExpressionTree,
  BizFieldPredicate
} from "../../services/api/explore";
import { EntityAggregationSuggestion } from "../../services/api/types";

export const VAR_URL_PREFIX = "var-";
export const EVENT_VAR_URL_PREFIX = "evar-";
export const EVENT_EXPR_VAR_URL_PREFIX = "exprvar-";

export enum VariableType {
  Query = "query",
  Custom = "custom",
  Constant = "constant",
  Cohort = "cohort",
  Event = "event",
  EventExpression = "eventExpression"
}

export enum CohortFilterType {
  Entity = "entity",
  Cohort = "cohort"
}

export default interface VariableModel {
  id: string;
  label?: string;
  name: string;
  type: VariableType;
  hide?: boolean;
  supportsAggregation?: boolean;

  value: string | string[];
  defaultValue?: string;

  includeAll?: boolean;
  multi?: boolean;
  allValue?: string;
}

export interface QueryVariableModel extends VariableModel {
  query: string;
  datasource: string;
  type: VariableType.Query;
}

export interface CustomVariableModel extends VariableModel {
  query: string;
}

export interface ConstantVariableModel extends VariableModel {
  query: string;
  value: string;
}

export interface CohortVariableModel extends VariableModel {
  cohortId: string;
  entityTypeId: string;
  depFieldNames: string[];
  field: BizField | UserServiceField | BizServiceMetric | UserServiceMetric;
  fieldType: MetricSourceType | FieldSourceType;
  filterType: CohortFilterType;
  operator?: EntityOperation;
  isPinned?: boolean;
  isLocked?: boolean;
  aggSuggestion?: EntityAggregationSuggestion;
}

export interface EventVariableModel extends VariableModel {
  userServiceId: string;
  userServiceName: string;
  userServiceField: UserServiceField;
  operator?: string;
  isPinned?: boolean;
  isLocked?: boolean;
}

export interface EventExpressionVariableModel extends VariableModel {
  userServiceId: string;
  userServiceName: string;
  expressionTree: UserServiceFilterExpressionTree;
}

export type BizFieldPredicateWithVarProperies = {
  predicate: BizFieldPredicate;
  isPinned?: boolean;
  isLocked?: boolean;
};
