import React, { CSSProperties, FC, useCallback } from "react";
import { IncCheckbox } from "@inception/ui";
import { NegationColors } from "../../../../models";
import { VerticallyCenteredRow } from "../../../../../../../components";
import ColorPicker from "../../../../../../../components/color-picker/ColorPicker";
import { WidgetCustomizationProps } from "./types";

export const BaseUICustomization: FC<WidgetCustomizationProps> = props => {
  const { properties, onPropertiesChange } = props;

  const { useNegationColors: pUseNegationColors = false, negationColors: pNegationColors = [] } = properties || {};

  const firstColor = pNegationColors[0] || "#3BB443";
  const secondColor = pNegationColors[1] || "#F44336";

  const onNegationColorsChange = useCallback(
    (negationColors: NegationColors) => {
      if (pUseNegationColors) {
        properties.negationColors = negationColors;
        onPropertiesChange(properties);
      }
    },
    [onPropertiesChange, pUseNegationColors, properties]
  );

  const onFirstColorChange = useCallback(
    (firstColor: string) => {
      onNegationColorsChange([firstColor, secondColor]);
    },
    [secondColor, onNegationColorsChange]
  );

  const onSecondColorChange = useCallback(
    (secondColor: string) => {
      onNegationColorsChange([firstColor, secondColor]);
    },
    [onNegationColorsChange, firstColor]
  );

  const onUseNegationColorsChange = useCallback(
    (e: any, useNegationColors: boolean) => {
      properties.useNegationColors = useNegationColors;
      onPropertiesChange(properties);
    },
    [onPropertiesChange, properties]
  );

  return (
    <div className="inc-flex-column width-100 flex-gap-12">
      <div
        className="inc-flex-column flex-gap-8 paddingBt16 width-100"
        style={style}
      >
        <VerticallyCenteredRow>Metric Color Overrides</VerticallyCenteredRow>
        <IncCheckbox
          checked={pUseNegationColors}
          infoText="Useful when you have only two series that indicate status. Eg: Failed-Success Rate"
          label="Use Negation Colors"
          noImplicitHeight
          onChange={onUseNegationColorsChange}
        />
        {pUseNegationColors && (
          <>
            <ColorPicker
              color={firstColor}
              defaultColor="#3BB443"
              label="First Serie Color"
              mode="hex"
              onUpdate={onFirstColorChange}
            />

            <ColorPicker
              color={secondColor}
              defaultColor="#F44336"
              label="Second Serie Color"
              mode="hex"
              onUpdate={onSecondColorChange}
            />
          </>
        )}
      </div>
    </div>
  );
};

const style: CSSProperties = {
  borderBottom: "1px solid #313B45"
};
