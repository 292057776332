import { cloneDeep, uniqBy } from "lodash";
import { UsageJourneyConfig, UsageJourneyConfigNode, WidgetResponseDTO } from "../../../services/api/explore";
import { convertUSFieldSliceSetToTagSlice, getDefaultWidgetResponseDto } from "../../../utils/ExploreUtils";
import FunnelWidgetImpl from "./models/impl";

export const getWidgetResponseDTOForFunnel = (widget: FunnelWidgetImpl): WidgetResponseDTO => {
  const bizEntityType = widget.getBizEntityType();
  const funnelConfig = widget.getFunnelConfig();

  const widgetResponseDto = getDefaultWidgetResponseDto(bizEntityType, null);

  if (funnelConfig) {
    const { sliceSet: sliceSets, fieldDef, name } = funnelConfig;

    const userServiceField = cloneDeep(fieldDef?.fields?.[0]);

    if (userServiceField) {
      userServiceField.userServices = fieldDef.fields.reduce((acc, curr) => [...acc, ...curr.userServices], []);
      userServiceField.userServices = uniqBy(userServiceField.userServices, tuple => tuple.userServiceEntityId);

      const metricId = widget.id;

      widgetResponseDto.widgetConfig.dataDefinition.metrics[metricId] = {
        id: metricId,
        sourceType: "userServiceField",
        name,
        userServiceFieldMetricConfig: {
          aggregator: "avg",
          eventFilters: {
            userServiceFilters: []
          },
          sliceSets,
          userServiceField
        }
      };

      widgetResponseDto.querySchema.querySchema = sliceSets.map(sliceSet => ({
        defaultTagAgg: "avg",
        defaultTimeAgg: "sum",
        metricId,
        metricName: name,
        resultKey: name,
        sliceSet: convertUSFieldSliceSetToTagSlice(sliceSet),
        sourceUserServiceField: userServiceField
      }));
    }
  }

  return widgetResponseDto;
};

export const getFunnelNodeIds = (funnelConfig: UsageJourneyConfig): string[] => {
  const nodeIdsSet = new Set<string>();

  if (funnelConfig) {
    const { node } = funnelConfig;
    addJourneyNodeIds(node, nodeIdsSet);
  }

  return Array.from(nodeIdsSet);
};

const addJourneyNodeIds = (node: UsageJourneyConfigNode, nodeIdsSet: Set<string>) => {
  if (!node) {
    return;
  } else {
    const { nextStep, id } = node;
    nodeIdsSet.add(id);

    nextStep?.forEach(step => {
      const { nextNode } = step;
      addJourneyNodeIds(nextNode, nodeIdsSet);
    });
  }
};
