import { IncSelect, IncSlimSelect } from "@inception/ui";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import { Entity, useNotifications } from "../../core";
import { TimeRange } from "../../core/hooks/time-range/types";
import { entityApiService } from "../../services/api";
import { getEntityOptions, getEntitySelectorLabelId } from "./EntitySelectorUtils";
import { EntityOption, EntitySelectorSupportedTypes } from "./types";

type EntitySelectorProps = {
  /**
   * Type of entity the the component wil list.
   *
   * Automatically fetches and lists the entities of given specific type, if `options` are not provided.
   *
   * Adds `label` and placeholder, if not provided.
   */
  // @devs note
  // Automatic fetching is only there for Root APIs.Add the api when needed.
  // add relavant logic in EntitySelectorUtils.ts to support a new type
  entityType: EntitySelectorSupportedTypes;

  /**
   * Executes everytime the selection changes
   * @param entities Array of currently selected entities
   */
  onChange: (entities: Entity[]) => void;

  label?: string;

  /**
   * Place holder text for selector. Defaults to label
   */
  placeHolder?: string;

  /**
   * If the label should not be seen. set this to true
   */
  hideLabel?: boolean;

  helpTextId?: string;
  /**
   * Passing options is optional. If its not passed the component can fetch it on its own.
   * If passed, at a minimum the Entity should contain id and name.
   *
   * - If provided, `timeRange` and `entityType` props will be ignored
   */
  options?: Entity[];

  /**
   * In case the options are being loaded and you want to denote loading, passing this as true.
   */
  isLoading?: boolean;

  /**
   * Entities to be selected when the selector loads
   */
  defaultSelections?: Entity[];

  /**
   * By default allows multiple selection. For single selection set this to false
   * @default true
   */
  multipleSelections?: boolean;

  /**
   * Disable selections
   * @default false
   */
  isDisabled?: boolean;

  /**
   * Time range for which the API options will be listed.
   *
   * If not provided, all the root API's from unix time '0' will be listed
   *
   * Ignored if `options` are provided.
   */
  timeRange?: TimeRange;

  /**
   * If certain entities are not to be shown, pass in the entity ids for such entities
   */
  filterOutEntities?: string[];

  /**
   * Renders slim version of select
   */
  slim?: boolean;

  /**
   * executes once when rendered
   */
  onLoad?: (options: Entity[]) => void;

  className?: string;
};

const EntitySelector: React.FC<EntitySelectorProps> = (props: EntitySelectorProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [entities, setEntities] = useState<EntityOption[]>([]);
  const [selectedEntities, setSelectedEntities] = useState<EntityOption[]>(null);
  const { notifyError, notifyWarning } = useNotifications();
  const { formatMessage } = useIntl();

  const {
    label,
    options,
    multipleSelections = true,
    defaultSelections,
    timeRange,
    onChange,
    hideLabel = false,
    entityType,
    placeHolder: placeHolderFromProp,
    isLoading: pIsLoading,
    filterOutEntities,
    slim,
    onLoad
  } = props;

  const isComponentLoading = pIsLoading || isLoading;

  const isOnLoadExecuted = useRef<boolean>(false);

  const setDefaultSelections = useCallback(
    (entityOptions: EntityOption[]) => {
      if (pIsLoading) {
        setSelectedEntities([]);
        return;
      }
      // set selected options from defaultSelections if passed
      if (defaultSelections) {
        const selections: Map<string, EntityOption> = new Map();
        const idsToLookup: Set<string> = new Set();
        defaultSelections.forEach(s => {
          const rootApiMatch = entityOptions.find(r => r.value === s.id);
          if (!rootApiMatch) {
            idsToLookup.add(s.id);
          }
          // set id in map even if its match is undefined to maintain order of selections
          selections.set(s.id, rootApiMatch);
        });
        if (!isEmpty(idsToLookup)) {
          entityApiService
            .fetchEntityNamesForIds(idsToLookup)
            .then(nameMap => {
              if (nameMap.size !== idsToLookup.size) {
                notifyWarning(formatMessage({ id: "entity.selector.warning.missing.apis" }));
              }
              const optionsToAdd: Set<EntityOption> = new Set();
              selections.forEach((option, id) => {
                if (isEmpty(option)) {
                  const entityName = nameMap.get(id);
                  const label = entityName ?? id;
                  const option: EntityOption = {
                    label,
                    value: id,
                    entity: {
                      id,
                      name: label
                    }
                  };
                  selections.set(id, option);
                  if (entityName) {
                    optionsToAdd.add(option);
                  }
                }
              });
              setSelectedEntities(Array.from(selections.values()));
              setEntities([...entityOptions, ...Array.from(optionsToAdd)]);
            })
            .catch(() => {
              notifyError(formatMessage({ id: "entity.selector.error.fetching.ids" }));
            });
        } else {
          setSelectedEntities(Array.from(selections.values()));
        }
      }
    },
    [defaultSelections, pIsLoading, formatMessage, notifyError, notifyWarning]
  );

  const fetchEntityOptions = useCallback(async () => {
    setIsLoading(true);
    let options = await getEntityOptions(entityType, timeRange);

    // Filter out entities if 'filterOutEntities' is passed
    if (filterOutEntities && filterOutEntities.length > 0) {
      options = options.filter(opt => filterOutEntities.indexOf(opt.entity.id) === -1);
    }

    setIsLoading(false);
    return options;
  }, [entityType, filterOutEntities, timeRange]);

  const setOptionsAndDefaults = useCallback(async () => {
    if (pIsLoading) {
      return;
    }
    let entityOptions: EntityOption[] = [];
    if (!options) {
      // If options are not specified go and fetch it
      entityOptions = await fetchEntityOptions();
    } else {
      entityOptions = options.map(opt => ({
        label: opt.name,
        value: opt.id,
        entity: opt
      }));
    }
    setEntities(entityOptions);
    setDefaultSelections(entityOptions);
  }, [options, pIsLoading, fetchEntityOptions, setDefaultSelections]);

  useEffect(() => {
    setOptionsAndDefaults();
  }, [setOptionsAndDefaults]);

  const setStateAndCallBack = (selections: EntityOption[]) => {
    setSelectedEntities(selections);

    const entities: Entity[] = selections.map(s => s.entity);
    onChange(entities);
  };

  const onMultipleSelections = (selections: readonly EntityOption[]) => {
    const selectedRootApis = [...selections] || [];
    setStateAndCallBack(selectedRootApis);
  };

  const onSingleSelection = (selections: EntityOption) => {
    setStateAndCallBack([selections]);
  };

  useEffect(() => {
    if (onLoad && entities.length > 0 && !isOnLoadExecuted.current) {
      isOnLoadExecuted.current = true;
      onLoad(entities.map(e => e.entity));
    }
  }, [onLoad, entities]);

  const labelId = getEntitySelectorLabelId(entityType, multipleSelections);
  const finalLabel = hideLabel ? "" : label || formatMessage({ id: labelId });
  // placeholder is same as derived label for lack of better expression
  const placeholder = placeHolderFromProp || formatMessage({ id: labelId });

  const commonProps = {
    getOptionLabel: (option: EntityOption) => option.label,
    isLoading: isComponentLoading,
    options: entities,
    label: finalLabel,
    placeholder: placeholder,
    value: selectedEntities,
    helpTextId: props.helpTextId,
    isDisabled: props.isDisabled,
    className: props.className
  };

  if (multipleSelections) {
    return (
      <IncSelect<EntityOption, true>
        {...commonProps}
        isMulti
        onChange={onMultipleSelections}
      ></IncSelect>
    );
  }

  if (slim) {
    return (
      <IncSlimSelect
        {...commonProps}
        onChange={onSingleSelection}
        value={commonProps.value ? commonProps.value[0] : null}
      />
    );
  }
  return (
    <IncSelect<EntityOption, false>
      {...commonProps}
      onChange={onSingleSelection}
      value={commonProps.value ? commonProps.value[0] : null}
    ></IncSelect>
  );
};

export default EntitySelector;
