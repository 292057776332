import { cloneDeep } from "lodash";
import { Query } from "../models/BaseWidgetModel";

interface CompareResult {
  refId: string;
}

// This suffix helps us differentiate between original and compare queries. Added UUIDv1 for extra safety
const CompareQueryIdSuffix = "-compare";

export const getCompareQueries = <T extends Query>(queries: T[]): T[] => {
  const newQueries = cloneDeep(queries);
  newQueries.forEach(query => {
    if (query) {
      query.refId = query.refId + CompareQueryIdSuffix;
    }
  });
  return newQueries;
};

export const isCompareResult = <T extends CompareResult>(result: T) =>
  result?.refId?.endsWith(CompareQueryIdSuffix) || false;
