import { useMemo } from "react";
import { useTenantConfig } from "../../../../../core";
import { CohortEntityFilter, UserServiceFilterList } from "../../../../../services/api/explore";
import { getEntityFilterLabelsComponent } from "../../../../../utils";
import { featureFlagService } from "../../../../../services/feature-flags";

type Props = {
  entityFilters: CohortEntityFilter[];
  cohortFilters: CohortEntityFilter[];
  eventFilters: Record<string, UserServiceFilterList>;
};

export const useViewDashboardFilters = (props: Props) => {
  const { entityFilters, eventFilters, cohortFilters } = props;

  const isDemoFlagEnabled = featureFlagService.isDebugMode();

  const { tenantConfigState } = useTenantConfig();

  const options = useMemo(() => ({ currency: tenantConfigState?.currency || "USD" }), [tenantConfigState]);

  const viewDashboardFiltersAction = useMemo(() => {
    const filtersExist =
      entityFilters?.length > 0 || cohortFilters?.length > 0 || Object.values(eventFilters)?.length > 0;
    if (filtersExist) {
      const allEntityFilters = entityFilters
        .concat(cohortFilters)
        .map(filter => {
          if (filter?.predicate?.bizField) {
            return filter.predicate;
          }
          return null;
        })
        .filter(filter => filter !== null);
      const component = getEntityFilterLabelsComponent(allEntityFilters, eventFilters, options, "bars");
      return {
        actionComponent: component,
        isTitleAction: true,
        showInHeader: true
      };
    }
    return null;
  }, [cohortFilters, entityFilters, eventFilters, options]);

  return isDemoFlagEnabled ? viewDashboardFiltersAction : null;
};
