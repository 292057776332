import React, { useCallback, useMemo } from "react";
import { cx } from "emotion";
import { IncSmartText, IncToolTip } from "@inception/ui";
import { DataType, FieldSubType } from "../core";
import { dataTypeManager } from "../utils";
import { VerticallyCenteredRow } from "./flex-components";

type MetricSummaryProps = {
  value: string | number;
  compareValue: string | number;
  changePercent: string;
  isPositiveChange: boolean;
  dataType: string | DataType;
  subType: FieldSubType;
  label?: string;
  compareLabel?: string;
  className?: string;
};

export const MetricSummaryInfo = (props: MetricSummaryProps) => {
  const {
    value,
    compareValue,
    changePercent,
    isPositiveChange,
    dataType,
    subType,
    label = "Current",
    compareLabel = "Previous",
    className = ""
  } = props;
  const kind = useMemo(() => dataTypeManager.getDataTypeDescriptorByKind(dataType, subType), [dataType, subType]);

  const getFormattedValue = useCallback(
    (value: number | string) =>
      kind
        .getFormattedValue(value, {
          numberFormatOptions: { compact: true },
          durationFormatOptions: {
            maxTerms: 2,
            precision: null
          }
        })
        ?.toString(),
    [kind]
  );

  const { changeValueStr, changeValue } = useMemo(() => {
    const changeValue = Math.abs(parseFloat(value?.toString()) - parseFloat(compareValue?.toString()));
    return {
      changeValueStr: getFormattedValue(changeValue),
      changeValue
    };
  }, [compareValue, getFormattedValue, value]);
  const percentValue = parseFloat(changePercent);
  const isPercentValid = !isNaN(percentValue) && percentValue !== 0;
  const percentValueString = dataTypeManager.getDataTypeDescriptor("PERCENT_100").getFormattedValue(percentValue);

  const percentClass = cx("inc-text-body", {
    "status-danger": !isPositiveChange && isPercentValid,
    "status-success": isPositiveChange && isPercentValid
  });

  const changeIcon = isPercentValid ? (value > compareValue ? "↑" : "↓") : "";

  const compareToolTip = useMemo(
    () => (
      <VerticallyCenteredRow className="impacted-widget-info">
        <div className="inc-flex-column">
          <IncSmartText
            text={label}
            textClass="inc-text-element-medium inc-text-color-secondary"
          />
          <IncSmartText
            text={getFormattedValue(value)}
            textClass="inc-text-body"
          />
        </div>
        <div className="spacer" />
        <div className="inc-flex-column">
          <IncSmartText
            text={compareLabel}
            textClass="inc-text-element-medium inc-text-color-secondary"
          />
          <IncSmartText
            text={getFormattedValue(compareValue)}
            textClass="inc-text-body width-100"
          />
        </div>
      </VerticallyCenteredRow>
    ),
    [compareLabel, compareValue, getFormattedValue, label, value]
  );

  return (
    <IncToolTip titleElement={compareToolTip}>
      <VerticallyCenteredRow className={`${className} width-100 flex-gap-4`}>
        <span className="inc-text-header-medium">{getFormattedValue(value)}</span>
        <IncSmartText
          text={`${changeIcon} ${changeValue > 0 ? changeValueStr : ""}(${percentValueString})`}
          textClass={percentClass}
        />
      </VerticallyCenteredRow>
    </IncToolTip>
  );
};
