import { IncPopper } from "@inception/ui";
import { isString } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import PopOverInfo from "../PopOverInfo";
import { PickerFieldType, UIUserService } from "../../services/api/explore";
import { FieldPickerRow } from "../../field-picker";

export default function SourcesRenderer(sources: FieldPickerRow<PickerFieldType>["sources"], allSources?: boolean) {
  const targetRef = useRef<HTMLDivElement>(null);
  const [showInfo, setShowInfo] = useState<boolean>(false);

  const isUserServiceArray = !isString(sources[0]);
  const firstSource = isUserServiceArray ? (sources[0] as UIUserService)?.userService?.name : (sources[0] as string);

  const maxLength = 17;
  const plusCount = isUserServiceArray ? (sources.length - 1 > 0 ? `+${sources.length - 1}` : "") : "";
  const firstSourceLabel = firstSource?.length > maxLength ? `${firstSource.slice(0, maxLength)}... ` : firstSource;
  const displayLabel = plusCount ? `${firstSourceLabel} ${plusCount}` : firstSourceLabel;

  const popUpSources: Map<string, string[]> = new Map();
  if (isUserServiceArray) {
    (sources as UIUserService[]).forEach(s => {
      popUpSources.set(
        s.userService.name,
        s.rootApis.map(r => r.name)
      );
    });
  } else {
    (sources as string[]).forEach(s => {
      popUpSources.set(s, []);
    });
  }

  useEffect(() => {
    if (!targetRef.current) {
      return;
    }
    const target = targetRef.current;
    if (targetRef.current) {
      target.onmouseenter = () => setShowInfo(true);
      target.onmouseleave = () => setShowInfo(false);
    }
    return () => {
      if (target) {
        target.onmouseenter = null;
        target.onmouseleave = null;
      }
    };
  }, []);

  if (sources.length === 0 && !allSources) {
    return <></>;
  }

  return (
    <div ref={targetRef}>
      {displayLabel}
      {!allSources ? (
        <IncPopper
          anchorEl={targetRef.current}
          offset={{
            x: -20,
            y: 6
          }}
          placement={"right-start"}
          show={showInfo}
        >
          <PopOverInfo
            headerText="User Services"
            list={popUpSources}
          />
        </IncPopper>
      ) : (
        "All"
      )}
    </div>
  );
}
