import React from "react";
import { IncCheckbox, IncCheckboxProps } from "@inception/ui";

interface Props {
  options: IncCheckboxProps[];
  onChange: (id: string, checked: boolean) => void;
}

export const CheckBoxList: React.FC<Props> = (props: Props) => {
  const { options, onChange } = props;

  return (
    <div className="inc-flex-column flex-gap-12">
      {options.map((x, idx) => (
        <IncCheckbox
          key={`${x.id}-${idx}`}
          {...x}
          onChange={(_e, checked) => onChange(x.id, checked)}
        />
      ))}
    </div>
  );
};
