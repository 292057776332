import React, { FC, useMemo } from "react";
import { sortBy, isUndefined } from "lodash";
import { PieChart, PieSeries, PieChartOptions } from "../../../../../components";
import { DataFrame, DataType, generateId } from "../../../../../core";
import { OTHERS_PERCENT, OTHERS_LABEL, HighChartsDrilldownSeries } from "../../../../../components/charts";
import { eventFieldUtils } from "../../../../../utils";
import { EntityWidgetData } from "../../../../../biz-entity";
import { NegationColors } from "../../models";
import {
  CatalogVizRendererProps,
  getSerieName,
  getCompareFieldsFromLabels,
  getMetricIdFromDataFrameId,
  getChartColorForSerie,
  useCommonRendererFunctionality,
  getFormattedValue
} from "./common";

type Props = CatalogVizRendererProps & {
  showAsGauge?: boolean;
  showAsDonut?: boolean;
};

type PieSeriesResult = {
  series: PieSeries[];
  drillDownSeries: Array<HighChartsDrilldownSeries<PieSeries>>;
  aggValue: number;
};

export const PieChartRenderer: FC<Props> = props => {
  const {
    className,
    showAsGauge = false,
    dataType: defDataType,
    dataTypeByMetricId,
    currencyType,
    showAsDonut = false,
    loadingElement,
    noDataElement,
    aggregatedTags,
    displayAggregatedTags,
    properties
  } = props;

  const { useNegationColors, negationColors } = properties;

  const { data, fieldName, metricName, isFetching, isError, dataExists, childMetricNames, hasChildMetrics } =
    useCommonRendererFunctionality(props);

  const { series, drillDownSeries, aggValue } = useMemo(
    () =>
      constructPieSeries(
        data,
        aggregatedTags,
        displayAggregatedTags,
        defDataType,
        dataTypeByMetricId,
        fieldName,
        metricName,
        childMetricNames,
        hasChildMetrics,
        useNegationColors,
        negationColors
      ),
    [
      aggregatedTags,
      childMetricNames,
      data,
      dataTypeByMetricId,
      defDataType,
      displayAggregatedTags,
      fieldName,
      hasChildMetrics,
      metricName,
      negationColors,
      useNegationColors
    ]
  );

  const options = useMemo<PieChartOptions>(() => {
    const valueFormatterFn = (value: number, dataType = defDataType) =>
      getFormattedValue(fieldName, value, dataType, currencyType);

    const opts: PieChartOptions = {
      showLegend: !showAsGauge,
      customMode: showAsGauge ? "gauge" : showAsDonut ? "donut" : null,
      seriesName: eventFieldUtils.removeFieldsPrefix(fieldName),
      tooltipValueFormatter: (val, custom) => valueFormatterFn(val as number, custom?.dataType as DataType)
    };

    if (showAsGauge && !isUndefined(aggValue)) {
      opts.stats = valueFormatterFn(aggValue);
    }

    return opts;
  }, [aggValue, currencyType, defDataType, fieldName, showAsDonut, showAsGauge]);

  if (isFetching) {
    return loadingElement;
  }

  if (!dataExists || isError) {
    return noDataElement;
  }

  return (
    <PieChart
      containerElemClass={className}
      drillDownSeries={drillDownSeries}
      options={options}
      series={series}
    />
  );
};

export const constructPieSeries = (
  data: EntityWidgetData[],
  aggregatedTags: string[],
  displayAggregatedTags: string[],
  defDataType: CatalogVizRendererProps["dataType"],
  dataTypeByMetricId: Record<string, DataType>,
  fieldName: string,
  metricName: string,
  childMetricNames: Record<string, string>,
  hasChildMetrics: boolean,
  useNegationColors: boolean,
  negationColors: NegationColors
): PieSeriesResult => {
  const dataframes: DataFrame[] = [];
  const compareDataFrames: DataFrame[] = [];

  data.forEach(datum => {
    const postAggData = datum.postAggResult.data;
    const compareData = datum.postAggResult.timeShiftData || {};

    const refId = Object.keys(postAggData)[0] || Object.keys(compareData)[0] || "";

    const dfs = postAggData[refId]?.data || [];
    const cDfs = compareData[refId]?.data || [];

    dataframes.push(...dfs);
    compareDataFrames.push(...cDfs);
  });

  const isAggregatedMetric = !aggregatedTags?.length;
  const shouldAppendMetricName = hasChildMetrics && data.length >= 2;

  const invertStatusColors = fieldName.toLowerCase().includes("error");
  useNegationColors = useNegationColors && dataframes.length <= 2;

  const pieSeries: PieSeries[] = [];
  const drillDownSeries: Array<HighChartsDrilldownSeries<PieSeries>> = [];

  const sortedDataFrames = sortBy(dataframes, [
    (df: DataFrame) => {
      const { fields } = df;
      const values = fields[1].data;
      const data = values[0];
      return data;
    }
  ]);
  sortedDataFrames.reverse();

  const top4Series = sortedDataFrames.splice(0, 4);
  let top4Val = 0;

  top4Series.forEach((df, i) => {
    const { fields, labels, eLabels, name, id } = df;

    const metricId = getMetricIdFromDataFrameId(id);
    const appliedMetricName = childMetricNames[metricId] || metricName;
    const dataType = dataTypeByMetricId[metricId] || defDataType;

    const compareFields = getCompareFieldsFromLabels(compareDataFrames, labels);
    const compareData = compareFields?.[1]?.data[0] || null;

    const values = fields[1].data;
    const data = values[0];
    top4Val += data;

    let { aggLabel } = isAggregatedMetric
      ? { aggLabel: name }
      : getSerieName(aggregatedTags, displayAggregatedTags, labels, eLabels, appliedMetricName);

    if (shouldAppendMetricName) {
      aggLabel = `${appliedMetricName} - ${aggLabel}`;
    }

    const defaultColor = getChartColorForSerie(dataType, aggLabel, i, invertStatusColors);
    pieSeries.push({
      name: aggLabel,
      value: data,
      color: useNegationColors && i < 2 ? negationColors?.[i] || defaultColor : defaultColor,
      drillDownId: null,
      custom: {
        compareData,
        dataType
      }
    });
  });

  if (sortedDataFrames.length > 0) {
    pieSeries.push({
      name: OTHERS_LABEL,
      value: Math.round((top4Val / (100 - OTHERS_PERCENT)) * OTHERS_PERCENT),
      color: getChartColorForSerie(defDataType, OTHERS_LABEL, 4, invertStatusColors),
      drillDownId: OTHERS_LABEL,
      custom: {
        isOthersSeries: true
      }
    });

    updateDrillDownSeries(
      sortedDataFrames,
      drillDownSeries,
      OTHERS_LABEL,
      aggregatedTags,
      displayAggregatedTags,
      defDataType,
      dataTypeByMetricId,
      invertStatusColors,
      metricName,
      childMetricNames,
      compareDataFrames,
      isAggregatedMetric,
      shouldAppendMetricName
    );
  }

  const aggValue = pieSeries[0]?.value;

  return {
    series: pieSeries,
    drillDownSeries,
    aggValue
  };
};

const updateDrillDownSeries = (
  dataFrames: DataFrame[],
  drillDownSeries: Array<HighChartsDrilldownSeries<PieSeries>>,
  drilldownId: string,
  aggregatedTags: string[],
  displayAggregatedTags: string[],
  defDataType: CatalogVizRendererProps["dataType"],
  dataTypeByMetricId: Record<string, DataType>,
  negateColors: boolean,
  metricName: string,
  childMetricNames: Record<string, string>,
  compareDataFrames: DataFrame[],
  shouldModifyMetricName: boolean,
  shouldAppendMetricName: boolean
) => {
  const clonedList = [...dataFrames];
  const top4Series = clonedList.splice(0, 4);

  const drillDownSeriesEntry: HighChartsDrilldownSeries<PieSeries> = {
    id: drilldownId,
    name: OTHERS_LABEL,
    series: []
  };
  let top4Val = 0;

  top4Series.forEach((df, i) => {
    const { fields, labels, eLabels, name, id } = df;

    const metricId = getMetricIdFromDataFrameId(id);
    const appliedMetricName = childMetricNames[metricId] || metricName;
    const dataType = dataTypeByMetricId[metricId] || defDataType;

    const values = fields[1].data;
    const data = values[0];
    top4Val += data;

    const compareFields = getCompareFieldsFromLabels(compareDataFrames, labels);
    const compareData = compareFields?.[1]?.data[0] || 0;

    let { aggLabel } = shouldModifyMetricName
      ? { aggLabel: name }
      : getSerieName(aggregatedTags, displayAggregatedTags, labels, eLabels, appliedMetricName);

    if (shouldAppendMetricName) {
      aggLabel = `${appliedMetricName} - ${aggLabel}`;
    }

    drillDownSeriesEntry.series.push({
      name: aggLabel,
      value: data,
      color: getChartColorForSerie(dataType, aggLabel, i, negateColors),
      custom: {
        compareData,
        dataType
      }
    });
  });

  if (clonedList.length > 0) {
    const newOthersId = generateId();

    drillDownSeriesEntry.series.push({
      name: OTHERS_LABEL,
      value: Math.round((top4Val / (100 - OTHERS_PERCENT)) * OTHERS_PERCENT),
      color: getChartColorForSerie(defDataType, OTHERS_LABEL, 4, negateColors),
      drillDownId: newOthersId,
      custom: {
        isOthersSeries: true
      }
    });
    drillDownSeries.push(drillDownSeriesEntry);

    updateDrillDownSeries(
      clonedList,
      drillDownSeries,
      newOthersId,
      aggregatedTags,
      displayAggregatedTags,
      defDataType,
      dataTypeByMetricId,
      negateColors,
      metricName,
      childMetricNames,
      compareDataFrames,
      shouldModifyMetricName,
      shouldAppendMetricName
    );
  } else {
    drillDownSeries.push(drillDownSeriesEntry);
  }
};
