import { useMemo, useCallback } from "react";
import { InitialState, FetchFn, useDataFetch } from "../../core";
import { DestinationMapping, entityMappingApiService } from "../../services/api/entity-mapping";
import { getMockDestinationData } from "./mockData";

type Data = {
  isMockData: boolean;
  destinationMappings: DestinationMapping[];
};

export const useFetchDestinationMappings = (
  entityTypeId: string,
  fieldName: string,
  initDestinationMappings: DestinationMapping[],
  mockDataIfEmpty = false
) => {
  const mockData = useMemo(() => getMockDestinationData(fieldName), [fieldName]);

  const initState = useMemo<InitialState<Data, string>>(() => {
    const uInitDestinations = initDestinationMappings || [];
    const showMockData = mockDataIfEmpty && uInitDestinations.length === 0;
    const initDestinations = showMockData ? mockData : uInitDestinations;

    return {
      data: {
        isMockData: showMockData,
        destinationMappings: initDestinations
      },
      error: "",
      isError: false,
      isFetching: false,
      isSuccess: false
    };
  }, [initDestinationMappings, mockData, mockDataIfEmpty]);

  const fetchDestinationMappings = useCallback<FetchFn<Data, string>>(async () => {
    const destinationMappings: DestinationMapping[] = [];
    let error = "";
    let isMockData = false;
    try {
      const { mappings } = await entityMappingApiService.getBusinessEntitiesAndRelationship([entityTypeId]);
      (mappings || []).forEach(mapping => {
        let destMappings = mapping.destinationMappings || [];
        isMockData = destMappings.length === 0 && mockDataIfEmpty;
        destMappings = isMockData ? mockData : destMappings;
        destinationMappings.push(...destMappings);
      });
    } catch (e) {
      error = e.message;
    }

    return {
      data: {
        destinationMappings,
        isMockData
      },
      error,
      isError: Boolean(error),
      isSuccess: !error,
      isFetching: false
    };
  }, [entityTypeId, mockData, mockDataIfEmpty]);

  const { data, ...rest } = useDataFetch(fetchDestinationMappings, initState, true);

  return {
    ...rest,
    ...data
  };
};
