import React, { useCallback, CSSProperties, useState, useEffect } from "react";
import { IncButton, IncClickAway, IncPopper, getInceptionTheme } from "@inception/ui";
import { FormattedMessage } from "react-intl";

type Props = {
  onChange: (color: string) => void;
  color: string;
  size?: number;
  showSave?: boolean;
  hideEditOnHover?: boolean;
};

export const ChartColorPicker: React.FC<Props> = props => {
  const { onChange, color: pColor, size = 24, showSave, hideEditOnHover } = props;

  const renderColor = pColor || "transparent";

  const [color, setColor] = useState(renderColor);

  useEffect(() => {
    setColor(renderColor);
  }, [renderColor]);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onSelectColor = useCallback(
    (selectedColor: string) => {
      setColor(selectedColor);
      //if the save button exist then dont call the close of modal
      if (!showSave) {
        onChange(selectedColor);
        handleClose();
      }
    },
    [handleClose, onChange, showSave]
  );

  const onSave = useCallback(() => {
    onChange(color);
    handleClose();
  }, [color, handleClose, onChange]);

  const chipStyle: CSSProperties = {
    background: renderColor,
    width: size,
    height: size,
    borderRadius: 4,
    border: renderColor === "transparent" ? "1px solid #ccc" : "none"
  };

  const { chartColors } = getInceptionTheme();
  const selBorderColor = getInceptionTheme().inceptionColors.accentBlue;

  return (
    <div className="upload-icon-popover visible-on-hover">
      <div
        className="inc-flex-column inc-flex-center flex-gap-6 inc-cursor-pointer"
        onClick={handleClick}
      >
        <div style={chipStyle} />
        {!hideEditOnHover && <div className="inc-text-element-medium display-element">Edit</div>}
      </div>
      <IncClickAway onClickAway={handleClose}>
        {refFn => (
          <IncPopper
            anchorEl={anchorEl}
            offset={{
              x: 0,
              y: 0
            }}
            placement="bottom"
            ref={refFn}
            show={open}
          >
            <div style={popperStyle}>
              {showSave && (
                <div>
                  <div style={{ marginBottom: 16 }}>
                    <IncButton
                      color="primary"
                      disabled={!color}
                      onClick={onSave}
                    >
                      <FormattedMessage id="common.actions.save" />
                    </IncButton>
                  </div>
                </div>
              )}

              <div className="biz-entity-config-icon-list-wrapper">
                {chartColors.map((cColor, idx) => {
                  const isSelected = color === cColor;

                  const chipStyle = {
                    border: isSelected ? `1px solid ${selBorderColor}` : "none",
                    background: cColor,
                    width: 24,
                    height: 24,
                    borderRadius: 4,
                    marginRight: 6,
                    marginBottom: 6
                  };

                  const onClick = (event: any) => {
                    event.stopPropagation();
                    onSelectColor(cColor);
                  };

                  return (
                    <div
                      key={idx}
                      onClick={onClick}
                      style={chipStyle}
                    />
                  );
                })}
              </div>
            </div>
          </IncPopper>
        )}
      </IncClickAway>
    </div>
  );
};

const popperStyle: CSSProperties = {
  width: 340,
  padding: 16,
  height: 260,
  overflowX: "hidden"
};
