export const SALESFORCE_CONFIG_TYPE = "connector_salesforce";
export const SALESFORCE_CONFIG_TYPE_2 = "source-salesforce";
export const MARKETO_CONFIG_TYPE = "source-marketo";

export const ZUORA_CONFIG_TYPE = "connector_zuora";
export const CSV_CONFIG_TYPE = "connector_csv";
export const JDBC_CONFIG_TYPE = "connector_jdbc";
export const WEBHOOK_CONFIG_TYPE = "connector_webhook";
export const GITLAB_CONFIG_TYPE = "connector_gitlab";
export const DEMO_CONFIG_TYPE = "connector_demo";

export const ELASTIC_CONFIG_TYPE = "elastic";
