import { IncButton, IncFaIcon } from "@inception/ui";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { routePaths } from "../../../app/RoutePaths";
import { logger } from "../../core";
import { useInceptionRoute, useNotifications, useAccessPrivilege } from "../../core/hooks";
import { useBootConfig } from "../../login/state/useBootConfig";
import { FilledHomeIcon, HomeIcon, ViewingIcon } from "../../core/iconwrapper";
import usersApiService from "../../services/api/Admin/UsersApiService";
import { DashboardMode } from "../types";
import DashboardImpl from "../model-impl/DashboardImpl";
import { VariableSrv } from "../variables";
import VariableImpl from "../model-impl/VariableImpl";
import { Feature, DASHBOARD_ACTIONS } from "../../permissions/features";
import { featureFlagService } from "../../services/feature-flags";
import AddWidgetMenu from "./AddWidgetMenu";
import { DashToolbarBreadCrumbs } from "./Breadcrumbs";

// interface ModeSelectOption extends IncSelectWithIconOption {
//   mode: DashboardMode;
// }

export interface DashToolbarProps {
  dbImpl: DashboardImpl;
  variableSrvMap: Record<string, VariableSrv>;
  onSave: () => void;
  onUpdate: (saveDashboard?: boolean, widgetAdded?: boolean) => void;
  onVariablesUpdate: (variables: VariableImpl[], widgetId: string) => void;
  onAddWidget: (type: string) => void;
  onOpenSettings: () => void;
  mode: DashboardMode;

  redirectOnAction?: boolean;
  onAddWidgetCustom?: () => void;
  infoComponent?: JSX.Element;
}

// Temporarily disabling this since configId is string and grafana doesn't support string as home dashboard ID
const enableSetHomeDashboard = false;

const DashToolbar: React.FC<DashToolbarProps> = (props: DashToolbarProps) => {
  const {
    onUpdate,
    dbImpl: usrDbImpl,
    onSave,
    onAddWidget: pOnAddWidget,
    infoComponent,
    mode: pMode,
    redirectOnAction,
    onAddWidgetCustom,
    onOpenSettings
  } = props;

  const { permissions } = useAccessPrivilege();
  const { bootConfigState } = useBootConfig();

  const [dbImpl, setDbImpl] = useState(usrDbImpl);

  const [isHomeDashboard, setIsHomeDashboard] = useState<boolean>(false);

  useEffect(() => {
    const { homeDashboardId } = bootConfigState.preferences;
    setIsHomeDashboard(usrDbImpl.id === homeDashboardId);
  }, [bootConfigState.preferences, usrDbImpl.id]);

  const { name, meta, id } = dbImpl;
  const { edit } = meta;

  const hasEditAcess = permissions.hasAccess(Feature.dashboards, DASHBOARD_ACTIONS.updateDashboard);
  const canEditInit = hasEditAcess && (pMode === "edit" || pMode === "clone");
  const initialMode: DashboardMode = canEditInit ? "edit" : "view";
  const [selectedMode, setSelectedMode] = useState<DashboardMode>(initialMode);
  const prevModeRef = useRef<string>();

  const { notifyWarning, notifyError } = useNotifications();
  const { navigate } = useInceptionRoute();

  const onAddWidget = useCallback(
    (type: string) => {
      if (edit) {
        pOnAddWidget(type);
      }
    },
    [edit, pOnAddWidget]
  );

  useEffect(() => setDbImpl(usrDbImpl), [usrDbImpl]);

  const onDashboardNameChange = useCallback(
    (name: string, isBlur = false) => {
      if (isBlur) {
        if (name) {
          dbImpl.name = name;
          onUpdate(isBlur);
        } else {
          dbImpl.name = `Dashboard-${dbImpl.id}`;
          notifyWarning("Name cannot be empty. Assigned a constructed value.");
          onUpdate();
        }
      } else {
        dbImpl.name = name;
      }
    },
    [dbImpl, notifyWarning, onUpdate]
  );

  const onNavigate = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  const toggleModes = useCallback(() => {
    setSelectedMode(prev => {
      if (prev === "edit") {
        return "view";
      } else {
        return "edit";
      }
    });
  }, []);

  useEffect(() => {
    if (prevModeRef.current !== selectedMode) {
      dbImpl.setEditable(selectedMode === "edit");
      onUpdate();
      prevModeRef.current = selectedMode;
    }
  }, [dbImpl, onUpdate, selectedMode]);

  const isEditable = selectedMode === "edit";
  const isDebugMode = featureFlagService.isDebugMode();

  // set as home dashboard if isHomeDb = false, unset it otherwise
  const updateUserHomeDashboard = useCallback(
    async (isHomeDb: boolean) => {
      try {
        const response = await usersApiService.setUserPreferences({
          homeDashboardId: isHomeDb ? null : dbImpl.id
        });
        if (!response.error) {
          setIsHomeDashboard(!isHomeDb);
        } else {
          notifyError("Failed to set user home dashboard");
          logger.error("Dashboard Grid", response.message);
        }
      } catch (err) {
        notifyError("Failed to set user home dashboard");
        logger.error("Dashboard Grid", err as string);
      }
    },
    [dbImpl.id, notifyError]
  );

  const unsetHomePage = useCallback(() => {
    updateUserHomeDashboard(true);
    if (redirectOnAction) {
      navigate(routePaths.home, { reload: true });
    }
  }, [updateUserHomeDashboard, redirectOnAction, navigate]);

  const canEdit = selectedMode === "edit";

  const isDynamicDashboard = dbImpl.isDynamicDashboard() || dbImpl.isCohortDashboard();

  const shouldShowAddWidget = isEditable ? Boolean(onAddWidgetCustom) || isDebugMode : false;
  const shouldShowSettings = isEditable;

  return (
    <>
      <div className="dashboard-toolbar">
        <div className="dashboard-name">
          <DashToolbarBreadCrumbs
            allowNameChange={canEdit}
            dashboardId={id}
            dashboardName={name}
            onNameChange={(value: string) => onDashboardNameChange(value)}
            onNavigate={onNavigate}
            onSaveNameChange={(value: string) => onDashboardNameChange(value, true)}
          />

          <div className="marginLt10">{infoComponent}</div>
        </div>
        <div className="spacer" />

        {!isDynamicDashboard && (
          <>
            {enableSetHomeDashboard && (
              <>
                {isHomeDashboard && (
                  <FilledHomeIcon
                    className="dashboard-action-icon cur-active"
                    onClick={() => unsetHomePage()}
                  />
                )}
                {!isHomeDashboard && (
                  <HomeIcon
                    className="dashboard-action-icon cur-inactive"
                    onClick={() => updateUserHomeDashboard(false)}
                  />
                )}
                <div className="divider" />
              </>
            )}
            {isEditable && (
              <>
                <IncButton
                  color="link"
                  iconType="iconText"
                  onClick={onSave}
                >
                  <IncFaIcon iconName="save" />
                  <FormattedMessage id="common.actions.save" />
                </IncButton>
              </>
            )}
          </>
        )}

        {shouldShowSettings && (
          <>
            <div className="divider" />
            <div data-cy="dashboard-add-widget">
              <IncButton
                color="link"
                iconType="iconText"
                onClick={onOpenSettings}
              >
                <IncFaIcon iconName="gear" />
                Settings
              </IncButton>
            </div>
          </>
        )}
        {shouldShowAddWidget && (
          <>
            <div className="divider" />
            <div data-cy="dashboard-add-widget">
              {!onAddWidgetCustom && isDebugMode && <AddWidgetMenu add={onAddWidget} />}
              {Boolean(onAddWidgetCustom) && (
                <IncButton
                  color="link"
                  iconType="iconText"
                  onClick={onAddWidgetCustom}
                >
                  <IncFaIcon iconName="plus" />
                  Add Widget
                </IncButton>
              )}
            </div>
          </>
        )}

        {pMode !== "clone" && (
          <>
            <div className="divider" />
            <IncButton
              color="primary"
              disabled={!hasEditAcess}
              onClick={toggleModes}
            >
              <div className="inc-flex-row inc-flex-center edit-button">
                {selectedMode === "view" ? (
                  <>
                    <IncFaIcon
                      className="marginRt8"
                      iconName="edit"
                    />
                    Edit
                  </>
                ) : (
                  <>
                    <ViewingIcon className="marginRt8" />
                    View
                  </>
                )}
              </div>
            </IncButton>
          </>
        )}
      </div>
    </>
  );
};

export default DashToolbar;
