import React, { FC, useState, useCallback, useEffect, useMemo } from "react";
import { IncButton, IncCheckbox, IncModal, IncModalProps, IncTextfield } from "@inception/ui";
import { TimeRange, useInputState } from "../../core";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { VerticallyCenteredRow } from "../flex-components";

interface Props {
  defaultLabel?: string;
  defaultTimeRange: TimeRange;
  onSaveCustomTimeRange: (timeRange: TimeRange, label: string, markAsDefault: boolean, shouldApply: boolean) => void;
  onClose: () => void;
}

export const SaveCustomTimeRangeModal: FC<Props> = props => {
  const { defaultTimeRange, onSaveCustomTimeRange, defaultLabel: pDefaultLabel, onClose: pOnClose } = props;

  const defaultLabel =
    pDefaultLabel || timeRangeUtils.getLabelByTimeRange(defaultTimeRange.raw.from, defaultTimeRange.raw.to, true);

  const {
    inputValue: customTrLabel,
    onInputChange: onCustomTrLabelChange,
    setInputValue: setCustomTrLabel
  } = useInputState(defaultLabel);

  const [customTrToSave, setCustomTrToSave] = useState<TimeRange>(defaultTimeRange);
  const [isCustomTrDefault, setIsCustomTrDefault] = useState(false);

  useEffect(() => {
    setCustomTrToSave(defaultTimeRange);
  }, [defaultTimeRange]);

  const onSave = useCallback(() => {
    onSaveCustomTimeRange(customTrToSave, customTrLabel, isCustomTrDefault, false);
  }, [customTrLabel, customTrToSave, isCustomTrDefault, onSaveCustomTimeRange]);

  const onSaveAndApply = useCallback(() => {
    onSaveCustomTimeRange(customTrToSave, customTrLabel, isCustomTrDefault, true);
  }, [customTrLabel, customTrToSave, isCustomTrDefault, onSaveCustomTimeRange]);

  const onCloseOrCancel = useCallback(() => {
    pOnClose();
    setCustomTrLabel(defaultLabel);
    setCustomTrToSave(defaultTimeRange);
    setIsCustomTrDefault(false);
  }, [defaultLabel, defaultTimeRange, pOnClose, setCustomTrLabel]);

  const isTrValid = useMemo(() => timeRangeUtils.isValidTimeRange(customTrToSave), [customTrToSave]);

  const actions = useMemo<IncModalProps["actions"]>(
    () => ({
      preJSX: (
        <>
          <IncButton
            color="primary"
            disabled={!isTrValid}
            label="Save and Apply"
            onClick={onSaveAndApply}
          />

          <IncButton
            color="primary"
            disabled={!isTrValid}
            label="Save"
            onClick={onSave}
          />

          <IncButton
            color="secondary-blue"
            label="Cancel"
            onClick={onCloseOrCancel}
          />
        </>
      )
    }),
    [isTrValid, onCloseOrCancel, onSave, onSaveAndApply]
  );

  return (
    <IncModal
      actions={actions}
      disableFocusOnLoad
      onClose={onCloseOrCancel}
      show
      size="sm"
      titleText="Save Custom Time Range"
    >
      <div className="inc-flex-column width-100 flex-gap-16">
        <VerticallyCenteredRow className="flex-gap-16">
          <IncTextfield
            defaultValue={timeRangeUtils.getLabelForTimeStr(customTrToSave.raw.from, true).timeLabel}
            label="From"
            readOnly
          />

          <IncTextfield
            defaultValue={timeRangeUtils.getLabelForTimeStr(customTrToSave.raw.to, true).timeLabel}
            label="To"
            readOnly
          />
        </VerticallyCenteredRow>

        <IncTextfield
          label="Name"
          onChange={onCustomTrLabelChange}
          value={customTrLabel}
        />

        <IncCheckbox
          checked={isCustomTrDefault}
          label="Set as default"
          onChange={(e, checked) => setIsCustomTrDefault(checked)}
        />
      </div>
    </IncModal>
  );
};
