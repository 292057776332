import React, { ReactNode, ReactElement } from "react";
import { IncToolTip } from "@inception/ui";
import { getDataTypeNameAndIcon } from "../utils";
import { KindDescriptor } from "..";

type SimpleDataTypeRendererProps = {
  kindType: string;
  kindDescriptor: KindDescriptor;
  iconSize?: number; // this is used for both height and width
};

const IncSimpleDataTypeRenderer: React.FC<SimpleDataTypeRendererProps> = props => {
  const { kindType, kindDescriptor, iconSize } = props;

  const nameAndIcon: {
    name: string;
    iconElem: ReactNode;
  } = getDataTypeNameAndIcon(kindType, kindDescriptor);

  return (
    <IncToolTip
      placement="top"
      titleText={nameAndIcon.name}
    >
      {getDataTypeIconElem(kindType, kindDescriptor, iconSize)}
    </IncToolTip>
  );
};

export default IncSimpleDataTypeRenderer;

export const getDataTypeIconElem = (kindType: string, kindDescriptor: KindDescriptor, iconSize = 14): ReactElement => {
  const typeIconStyleProps = {
    width: iconSize,
    height: iconSize
  };

  const nameAndIcon: {
    name: string;
    iconElem: ReactNode;
  } = getDataTypeNameAndIcon(kindType, kindDescriptor, typeIconStyleProps);

  return <>{nameAndIcon.iconElem}</>;
};
