import React, { useState, useMemo, useCallback, useEffect } from "react";
import { IncModal, IncModalProps, IncRadioButton, IncSelect, IncSelectOption, IncTextfield } from "@inception/ui";
import { LimitSpecFunction } from "../../../../../../services/api/explore";
import { VerticallyCenteredRow } from "../../../../../../components";
import { MAX_INT32 } from "../../../../../../utils";
import { useToggleState } from "../../../../../../core";

interface Props {
  show: boolean;
  defaultSortFunction: LimitSpecFunction;
  defaultLimit: number;
  defaultFileName?: string;
  onClose: () => void;
  onConfirmClick: (func: LimitSpecFunction, value: number, fileName: string) => void;
}

export const DownloadCSVModal: React.FC<Props> = (props: Props) => {
  const { defaultSortFunction, defaultFileName, defaultLimit, onClose, onConfirmClick, show } = props;

  const [limit, setLimit] = useState(defaultLimit || "");
  const [sortFunction, setSortFunction] = useState(defaultSortFunction || "top");
  const [fileName, setFileName] = useState(defaultFileName || "");

  useEffect(() => {
    setSortFunction(defaultSortFunction);
  }, [defaultSortFunction]);

  const { isOpen: isCustomSelection, open: switchToCustomSelection, close: switchToAllSelection } = useToggleState();

  const resetState = useCallback(() => {
    setTimeout(() => {
      setLimit(defaultLimit || "");
      setSortFunction(defaultSortFunction || "top");
      setFileName(defaultFileName || "");
      switchToAllSelection();
    }, 1000);
  }, [defaultFileName, defaultLimit, defaultSortFunction, switchToAllSelection]);

  const actions = useMemo<IncModalProps["actions"]>(() => {
    const intVal = isCustomSelection ? Number(limit) : MAX_INT32;
    return {
      primary: {
        label: "Download",
        onClick: () => {
          onConfirmClick(sortFunction, intVal, fileName);
          resetState();
        },
        color: "primary"
      },
      secondary: {
        label: "Cancel",
        onClick: () => {
          onClose();
          resetState();
        },
        color: "secondary-blue"
      }
    };
  }, [isCustomSelection, limit, onConfirmClick, sortFunction, fileName, resetState, onClose]);

  const onLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setLimit(value);
  };

  const onFileNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value);
  }, []);

  const sortOption = sortFunction === "top" ? topSortOption : bottomSortOption;
  const onSortOptionChange = useCallback((option: SortOption) => setSortFunction(option.data), []);

  return (
    <IncModal
      actions={actions}
      onClose={onClose}
      show={show}
      size="sm"
      titleText="Download Data"
    >
      <div className="inc-flex-column width-100 paddingTp12 paddingBt16 flex-gap-20">
        <VerticallyCenteredRow className="inc-text-body-medium flex-gap-10">
          <VerticallyCenteredRow className="inc-label-common">File Name: </VerticallyCenteredRow>
          <IncTextfield
            onChange={onFileNameChange}
            required
            value={fileName}
          />
          <VerticallyCenteredRow className="inc-text-inactive">.xlsx</VerticallyCenteredRow>
        </VerticallyCenteredRow>

        <IncSelect
          alignment="row"
          autoAdjustWidth
          autoAdjustWidthBuffer={14}
          autoSort={false}
          label="Order"
          onChange={onSortOptionChange}
          options={sortOptions}
          value={sortOption}
        />

        <VerticallyCenteredRow className="flex-gap-16">
          <IncRadioButton
            checked={!isCustomSelection}
            label="All rows"
            onChange={switchToAllSelection}
          />

          <IncRadioButton
            checked={isCustomSelection}
            label={
              <VerticallyCenteredRow className="flex-gap-8">
                <VerticallyCenteredRow className="inc-text-inactive">Only</VerticallyCenteredRow>

                <IncTextfield
                  autoAdjustWidth
                  autoAdjustWidthBuffer={14}
                  disabled={!isCustomSelection}
                  onChange={onLimitChange}
                  placeholder="Rows"
                  type="number"
                  value={limit}
                />

                <VerticallyCenteredRow className="inc-text-inactive">rows</VerticallyCenteredRow>
              </VerticallyCenteredRow>
            }
            onChange={switchToCustomSelection}
          />
        </VerticallyCenteredRow>
      </div>
    </IncModal>
  );
};

type SortOption = IncSelectOption<LimitSpecFunction>;

const topSortOption: SortOption = {
  label: "Top",
  value: "top",
  data: "top"
};

const bottomSortOption: SortOption = {
  label: "Bottom",
  value: "bottom",
  data: "bottom"
};
const sortOptions: SortOption[] = [topSortOption, bottomSortOption];
