import React, { useMemo } from "react";
import { BaseWidgetWithHeader } from "../dashboard/widgets/BaseWidget";
import { BaseWidgetProps } from "../dashboard/widgets/types";
import { DashboardImpl } from "../dashboard";
import { GLOBAL_VARIABLE_SRV_KEY } from "../dashboard/variables/constants";
import { VariableSrv } from "../dashboard/variables/VariableSrv";

interface StandAloneWidgetProps
  extends Pick<BaseWidgetProps, "widget" | "timeRange" | "compareTimeRange" | "onAction" | "eventTypeToFieldsMap"> {
  dbImpl?: DashboardImpl;
  variableSrvMap?: Record<string, VariableSrv>;
  onUpdate?: () => void;
  skipWidgetVarSrvAddition?: boolean;
}

export const StandAloneWidget: React.FC<StandAloneWidgetProps> = React.memo<StandAloneWidgetProps>(props => {
  const {
    timeRange,
    compareTimeRange,
    onAction,
    widget,
    dbImpl: pDbImpl,
    variableSrvMap: pVariableSrvMap,
    onUpdate,
    skipWidgetVarSrvAddition = false,
    eventTypeToFieldsMap
  } = props;

  const dbImpl = useMemo(() => {
    const impl = new DashboardImpl({});
    if (pDbImpl) {
      impl.variables = pDbImpl.variables;
      impl.entityTypeId = pDbImpl.entityTypeId;
      impl.entityTypeName = pDbImpl.entityTypeName;
      impl.cohortId = pDbImpl.cohortId;
      impl.cohortName = pDbImpl.cohortName;
    }
    impl.addWidget(widget);
    impl.timeRange = timeRange;
    impl.compareTimeRange = timeRange;
    return impl;
  }, [pDbImpl, timeRange, widget]);

  const { variables, entityTypeId, entityTypeName, cohortId, cohortName } = dbImpl;

  const { variableSrvMap, variableLoadingStateMap } = useMemo(() => {
    const varSrv = new VariableSrv(
      timeRange,
      variables,
      entityTypeId,
      null,
      entityTypeName,
      cohortId,
      cohortName,
      [],
      false
    );
    varSrv.loading = false;

    if (cohortId) {
      varSrv.setCohortId(cohortId);
    }

    const variableSrvMap = {
      [GLOBAL_VARIABLE_SRV_KEY]: varSrv,
      [widget.id]: varSrv,
      ...(pVariableSrvMap || {})
    };

    const variableLoadingStateMap = {
      [widget.id]: false,
      [GLOBAL_VARIABLE_SRV_KEY]: false
    };

    Object.keys(pVariableSrvMap || {}).forEach(key => (variableLoadingStateMap[key] = false));

    if (skipWidgetVarSrvAddition) {
      delete variableSrvMap[widget.id];
      delete variableLoadingStateMap[widget.id];
    }

    return {
      variableLoadingStateMap,
      variableSrvMap
    };
  }, [
    cohortId,
    cohortName,
    entityTypeId,
    entityTypeName,
    pVariableSrvMap,
    skipWidgetVarSrvAddition,
    timeRange,
    variables,
    widget.id
  ]);

  return (
    <BaseWidgetWithHeader
      compare={false}
      compareTimeRange={compareTimeRange}
      dbImpl={dbImpl}
      edit={false}
      eventTypeToFieldsMap={eventTypeToFieldsMap}
      isStandalone
      locked={true}
      onAction={onAction}
      onUpdate={onUpdate}
      timeRange={timeRange}
      variableLoadingStateMap={variableLoadingStateMap}
      variableSrvMap={variableSrvMap}
      widget={widget}
    />
  );
});
