import React, { FC, useState, useCallback, memo, useEffect, useMemo, useRef } from "react";
import {
  IncSelectOption,
  IncSelect,
  IncFaIcon,
  IncTextfield,
  generateId,
  IncButton,
  IncClickAwayPopper
} from "@inception/ui";
import { isNumber, clone } from "lodash";
import { VerticallyCenteredRow } from "../../../../../../components";
import { MAX_INT32 } from "../../../../../../utils";
import { ChartPoint } from "../../../../TimeSeries/models/model";
import { binSelectionOptions } from "./useFetchData";

interface Props {
  binType: IncSelectOption;
  setBinType: (binType: IncSelectOption) => void;
  bins: number[];
  setBins: (bins: number[]) => void;
}

export const BinSelector: FC<Props> = props => {
  const { binType, bins, setBinType, setBins } = props;

  const [open, setOpen] = useState(false);
  const openPopper = useCallback(() => setOpen(true), []);
  const closePopper = useCallback(() => setOpen(false), []);

  const ref = useRef<HTMLDivElement>();

  const isCustomBinSelection = binType?.value === "custom";

  return (
    <VerticallyCenteredRow>
      <IncSelect
        isDisabled={!bins?.length}
        isSearchable={false}
        label="Bins"
        onChange={setBinType}
        options={binSelectionOptions}
        value={binType}
      />

      {isCustomBinSelection && (
        <VerticallyCenteredRow
          className="inc-cursor-pointer marginLt10 marginTp18"
          onClick={openPopper}
          ref={ref}
        >
          <IncFaIcon iconName="edit" />
        </VerticallyCenteredRow>
      )}

      <IncClickAwayPopper
        anchorEl={ref.current}
        onClickAway={closePopper}
        show={open}
      >
        <BinCustomiser
          bins={bins}
          closePopper={closePopper}
          setBins={setBins}
        />
      </IncClickAwayPopper>
    </VerticallyCenteredRow>
  );
};

type BProps = Pick<Props, "bins" | "setBins"> & {
  closePopper: () => void;
};

const BinCustomiser: FC<BProps> = memo(props => {
  const uniqId = useMemo(() => generateId(), []);

  const { bins, setBins, closePopper } = props;

  const [binsMap, setBinsMap] = useState<Map<string, ChartPoint>>(new Map());

  useEffect(() => {
    const map = new Map<string, ChartPoint>();

    bins.forEach((binEnd, idx) => {
      const binStart = idx === 0 ? null : bins[idx - 1];
      const id = generateId();
      map.set(id, [binStart, binEnd]);
    });

    setBinsMap(map);
  }, [bins]);

  const onBinChange = useCallback((bin: ChartPoint, key: string) => {
    setBinsMap(prevMap => {
      const nextMap = clone(prevMap);

      const keys = Array.from(prevMap.keys());
      const keyIdx = keys.indexOf(key);

      const nextKey = keys[keyIdx + 1];
      const nextBin = nextMap.get(nextKey);

      if (nextBin) {
        const nPrevBin: ChartPoint = [bin[1], nextBin[1]];
        nextMap.set(nextKey, nPrevBin);
      }

      nextMap.set(key, bin);

      return nextMap;
    });
  }, []);

  const onRemoveBin = useCallback((key: string) => {
    setBinsMap(prevMap => {
      const nextMap = clone(prevMap);
      const keys = Array.from(prevMap.keys());
      const keyIdx = keys.indexOf(key);

      const prevKey = keys[keyIdx - 1];
      const prevBin = nextMap.get(prevKey);

      const nextKey = keys[keyIdx + 1];
      const nextBin = nextMap.get(nextKey);

      if (nextBin) {
        const nPrevBin: ChartPoint = [prevBin[0], nextBin[0]];
        nextMap.set(prevKey, nPrevBin);
      }

      nextMap.delete(key);

      return nextMap;
    });
  }, []);

  const updateBins = useCallback(() => {
    const bins = Array.from(binsMap.values()).map(([, binEnd]) => binEnd);
    setBins(bins);
    closePopper();
  }, [closePopper, binsMap, setBins]);

  const addNewBin = useCallback((nextBin?: ChartPoint) => {
    setBinsMap(prevMap => {
      const nextMap = clone(prevMap);

      if (!nextBin) {
        const keys = Array.from(prevMap.keys());

        const numBins = prevMap.size;
        const lastBinKey = keys[numBins - 1];
        const secondLastBinKey = keys[numBins - 2];

        const lastBinEnd = nextMap.get(lastBinKey)?.[1];
        const secondLastBinEnd = nextMap.get(secondLastBinKey)?.[1];

        const diff = numBins > 1 ? lastBinEnd - secondLastBinEnd : 10;
        nextBin = [lastBinEnd, lastBinEnd + diff];
      }

      nextMap.set(generateId(), nextBin);
      return nextMap;
    });
  }, []);

  const addMaxBin = useCallback(() => {
    setBinsMap(prevMap => {
      const nextMap = clone(prevMap);

      const keys = Array.from(prevMap.keys());
      const numBins = prevMap.size;
      const lastBinKey = keys[numBins - 1];

      const lastBinEnd = nextMap.get(lastBinKey)[1];
      const nextBin: ChartPoint = [lastBinEnd, MAX_INT32];

      nextMap.set(MAX_BIN_ID, nextBin);
      return nextMap;
    });
  }, []);

  const maxBinExists = binsMap.has(MAX_BIN_ID);

  const components = useMemo(() => {
    const components: JSX.Element[] = [];

    let idx = 0;
    binsMap.forEach(([binStart, binEnd], entryKey) => {
      const isMaxBin = binEnd === MAX_INT32;
      const binStartStr = isNaN(binStart) || !binStart ? "" : binStart.toString();

      const binStr = binStart === null ? "<=   " : isMaxBin ? `>=   ${binStart}` : `${binStartStr}   -  `;

      const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.valueAsNumber;
        onBinChange([binStart, value], entryKey);
      };

      const onRemove = () => onRemoveBin(entryKey);

      const isValidValue = isNumber(binEnd);
      const err = isValidValue ? (binEnd > binStart ? "" : "End must be greater than start") : "Invalid value";

      const value = isValidValue ? binEnd : "";

      const key = [uniqId, entryKey].join("-");

      components.push(
        <VerticallyCenteredRow
          className="marginBt8"
          key={key}
        >
          <VerticallyCenteredRow className="marginRt8 bin-left">{binStr}</VerticallyCenteredRow>

          {!isMaxBin && (
            <>
              <IncTextfield
                containerClassName="bin-right"
                defaultValue=""
                errorText={err}
                hasError={Boolean(err)}
                onChange={onChange}
                type="number"
                value={value}
              />

              {idx !== 0 && (
                <IncFaIcon
                  className="marginLt10 status-danger"
                  iconName="minus-circle"
                  onClick={onRemove}
                  title="Remove"
                />
              )}
            </>
          )}
        </VerticallyCenteredRow>
      );

      idx++;
    });

    return components;
  }, [binsMap, onBinChange, onRemoveBin, uniqId]);

  const { allBinsSorted, allBinsValid } = useMemo(() => {
    const binEnds = Array.from(binsMap.keys()).map(key => {
      const bin = binsMap.get(key);
      return bin[1];
    });
    const allBinsValid = binEnds.every(bin => isNumber(bin));
    const allBinsSorted = allBinsValid ? binEnds.sort((a, b) => a - b).join("") === binEnds.join("") : false;

    return {
      allBinsValid,
      allBinsSorted
    };
  }, [binsMap]);

  const valid = allBinsValid && allBinsSorted;

  return (
    <div className="bin-selector">
      <VerticallyCenteredRow className="marginBt12 inc-flex-grow">
        <VerticallyCenteredRow>Custom Bins</VerticallyCenteredRow>

        <IncFaIcon
          className="inc-cursor-pointer marginLtAuto"
          iconName="close"
          onClick={closePopper}
        />
      </VerticallyCenteredRow>

      <div className="wrapper">
        {components}

        {!maxBinExists && (
          <>
            <VerticallyCenteredRow
              className="marginBt8 inc-cursor-pointer link-button inc-text-subtext-medium"
              onClick={() => addNewBin()}
            >
              <IncFaIcon
                className="marginRt6"
                iconName="plus-circle"
              />
              Add
            </VerticallyCenteredRow>

            <VerticallyCenteredRow
              className="inc-cursor-pointer link-button inc-text-subtext-medium"
              onClick={addMaxBin}
            >
              <IncFaIcon
                className="marginRt6"
                iconName="plus-circle"
              />
              Add max bin
            </VerticallyCenteredRow>
          </>
        )}
      </div>

      <IncButton
        color="primary"
        disabled={!valid}
        onClick={updateBins}
      >
        Update
      </IncButton>
    </div>
  );
});

const MAX_BIN_ID = "__max__bin__";
