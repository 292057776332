import { setAutoFreeze, produce } from "immer";
import { EventGroup, EventGroupResponse } from "../../../platform/services/api/auto-discovery/types";
import {
  DiscoverMappingContextState,
  DiscoverMappingStateAction,
  EventEntityDetailsData,
  EventTypeFieldMappingDetails,
  MappingGroupDetailsData,
  MappingSourceDetailsData
} from "../types";
import { autoDiscoveryUtils } from "../utils/autoDiscoveryUtils";
import {
  DELETE_EVENT_GROUP,
  RESET_EVENT_TYPE_SUMMARY_NODES,
  SET_EVENT_ENTITY_DETAILS_DATA,
  SET_EVENT_GROUP_RESPONSE,
  SET_MAPPING_DETAILS_DATA,
  SET_MAPPING_SOURCE_DETAILS_DATA,
  UPDATE_EVENT_GROUP,
  UPDATE_EVENT_TYPE_SUMMARY_NODES
} from "./discoverMappingActions";

export const initEventsGroupResponse: EventGroupResponse = {
  eventGroups: null,
  verifiedMappingsBySource: null,
  eventSources: null
};

export const initDiscoverMappingState = (
  eventGroupResponse: EventGroupResponse,
  requestId: string,
  sourceTypeIcons: Record<string, string>
): DiscoverMappingContextState => ({
  requestId: requestId || "",
  mappingState: eventGroupResponse || initEventsGroupResponse,
  sourceTypeIcons,
  eventTypeSummaryNodes: {
    nodes: [],
    edges: []
  },
  mappingSourceDetails: null,
  mappingGroupDetails: null,
  eventEntityFieldDetails: null,
  summaryNodesOpenId: ""
});

const discoverMappingReducer = (
  state: DiscoverMappingContextState,
  action: DiscoverMappingStateAction
): DiscoverMappingContextState => {
  switch (action.type) {
    case SET_EVENT_GROUP_RESPONSE: {
      const response = action.payload as EventGroupResponse;
      state.mappingState = response;
      state.mappingGroupDetails = null;
      state.eventEntityFieldDetails = null;
      state.summaryNodesOpenId = "";
      if (response.summaryNodesGroupId) {
        state.summaryNodesOpenId = response.summaryNodesGroupId;
      }
      return;
    }
    case DELETE_EVENT_GROUP: {
      const groupDeleted = action.payload as EventGroup;
      //we are setting isDeleted to true based on API response
      state.mappingState.eventGroups[groupDeleted.id] = groupDeleted;
      return;
    }
    case UPDATE_EVENT_GROUP: {
      const groupToUpdate = action.payload as EventGroup;
      if (groupToUpdate) {
        state.mappingState.eventGroups[groupToUpdate.id] = groupToUpdate;
      }
      state.mappingGroupDetails = null;
      return;
    }
    case UPDATE_EVENT_TYPE_SUMMARY_NODES: {
      const eventTypeSummaryDetails = action.payload as EventTypeFieldMappingDetails;
      const { nodes, edges } = autoDiscoveryUtils.getSummaryNodes(eventTypeSummaryDetails);

      state.eventTypeSummaryNodes["nodes"] = nodes;
      state.eventTypeSummaryNodes["edges"] = edges;
      state.summaryNodesOpenId = "";
      return;
    }
    case SET_MAPPING_SOURCE_DETAILS_DATA: {
      state.mappingSourceDetails = action.payload as MappingSourceDetailsData;
      return;
    }
    case SET_MAPPING_DETAILS_DATA: {
      const response = action.payload as MappingGroupDetailsData;
      state.mappingGroupDetails = response;
      state.summaryNodesOpenId = `${response.mappingGroupData.eventTypeName}${response.mappingGroupData.eventGroup.id}`;
      return;
    }
    case SET_EVENT_ENTITY_DETAILS_DATA: {
      state.eventEntityFieldDetails = action.payload as EventEntityDetailsData;
      return;
    }
    case RESET_EVENT_TYPE_SUMMARY_NODES: {
      state.eventTypeSummaryNodes.nodes = [];
      state.eventTypeSummaryNodes.edges = [];
      state.summaryNodesOpenId = "";
      return;
    }

    default:
      return state;
  }
};

setAutoFreeze(false);
export default produce(discoverMappingReducer);
