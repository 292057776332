import { request } from "../base-api";
import appConfig from "../../../../appConfig";
import { Invitee, User, PendingInviteResult, InviteListReponse } from "../../../../features/admin/users/types";
import datasourceApiManager from "../DatasourceApiService";
import {
  BulkCreatePartnerUsersRequest,
  Partner,
  PartnerUserListReponse,
  PartnerUserResult,
  PartnerUser,
  PartnerListReponse
} from "../../../../features/admin/partners/types";
import { GlobalUserListReponse, GlobalUserResult } from "../../../../features/admin/allusers/types";

export interface Preferences {
  homeDashboardId: string;
  theme: string;
  timezone: string;
}

interface ResponseMessage {
  message: string;
}

interface Result<T> {
  data: T;
  error: boolean;
  message: string;
}

class UsersApiService {
  async getUsersList(): Promise<Result<User[]>> {
    const result: Result<User[]> = {
      data: [],
      error: false,
      message: ""
    };

    const subPath = "/users";
    const url = this.getOrgUrl(subPath);

    try {
      const response = await request.get<User[]>(url);
      result.data = response.data;
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  async getPendingInvites(): Promise<PendingInviteResult> {
    const dataSourceManagerRequest = datasourceApiManager.getDefault();

    const result: PendingInviteResult = {
      data: null,
      error: false,
      message: ""
    };

    const url = "/api/tenants/users/invites";

    try {
      const response = await dataSourceManagerRequest.get<InviteListReponse>(url);
      result.data = response.data;
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  async sendInvite(data: Invitee): Promise<Result<ResponseMessage>> {
    const dataSourceManagerRequest = datasourceApiManager.getDefault();

    const result: Result<ResponseMessage> = {
      data: { message: "" },
      error: false,
      message: ""
    };

    const url = "/api/tenants/users/invites";

    try {
      const response = await dataSourceManagerRequest.post<ResponseMessage, Invitee>(url, data);
      result.data = response.data;
      result.message = result.data.message;
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  async revokeInvite(inviteId: string): Promise<Result<ResponseMessage>> {
    const dataSourceManagerRequest = datasourceApiManager.getDefault();

    const result: Result<ResponseMessage> = {
      data: { message: "" },
      error: false,
      message: ""
    };
    const url = `/api/tenants/users/invites/${inviteId}`;

    try {
      const response = await dataSourceManagerRequest.delete<ResponseMessage, any>(url);
      result.data = response.data;
      result.message = result.data.message;
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  async removeUser(userId: number): Promise<Result<ResponseMessage>> {
    const dataSourceManagerRequest = datasourceApiManager.getDefault();

    const result: Result<ResponseMessage> = {
      data: { message: "" },
      error: false,
      message: ""
    };

    const url = `/api/tenants/users/${userId}`;

    try {
      const response = await dataSourceManagerRequest.delete<ResponseMessage>(url);
      result.data = response.data;
      result.message = result.data.message;
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  async updateUserRole(userId: number, role: string): Promise<Result<ResponseMessage>> {
    const dataSourceManagerRequest = datasourceApiManager.getDefault();

    const result: Result<ResponseMessage> = {
      data: { message: "" },
      error: false,
      message: ""
    };

    const url = `/api/tenants/users/${userId}?role=${role}`;

    try {
      const response = await dataSourceManagerRequest.put<ResponseMessage, { role: string }>(url);
      result.data = response.data;
      result.message = result.data.message;
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  async starDashboard(dashboardId: number): Promise<Result<ResponseMessage>> {
    const result: Result<ResponseMessage> = {
      data: { message: "" },
      error: false,
      message: ""
    };

    const subPath = `/stars/dashboard/${dashboardId}`;
    const url = this.getUserUrl(subPath);

    try {
      const response = await request.post<ResponseMessage, undefined>(url);
      result.data = response.data;
      result.message = result.data.message;
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  async unStarDashboard(dashboardId: number): Promise<Result<ResponseMessage>> {
    const result: Result<ResponseMessage> = {
      data: { message: "" },
      error: false,
      message: ""
    };

    const subPath = `/stars/dashboard/${dashboardId}`;
    const url = this.getUserUrl(subPath);

    try {
      const response = await request.delete<ResponseMessage>(url);
      result.data = response.data;
      result.message = result.data.message;
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  async getUserByEmailOrUsername(emailOrUsername: string): Promise<Result<User>> {
    const result: Result<User> = {
      data: null,
      error: false,
      message: ""
    };

    const subPath = `/lookup?loginOrEmail=${emailOrUsername}`;
    const url = this.getUserUrl(subPath);

    try {
      const response = await request.get<User>(url);
      result.data = response.data;
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  getOrgPreferences() {
    return this.getPreferences(true);
  }

  setOrgPreferences(preferences: Partial<Preferences>) {
    return this.setPreferences(preferences, true);
  }

  getUserPreferences() {
    return this.getPreferences(false);
  }

  setUserPreferences(preferences: Partial<Preferences>) {
    return this.setPreferences(preferences, false);
  }

  private async setPreferences(preferences: Partial<Preferences>, orgPreferences: boolean) {
    const result: Result<ResponseMessage> = {
      data: { message: "" },
      error: false,
      message: ""
    };

    const subPath = `/preferences`;
    const url = orgPreferences ? this.getOrgUrl(subPath) : this.getUrl(`/api/user${subPath}`);

    try {
      const response = await request.put<ResponseMessage, Partial<Preferences>>(url, preferences);
      result.data = response.data;
      result.message = result.data.message;
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  private async getPreferences(orgPreferences: boolean) {
    const result: Result<Preferences> = {
      data: {
        homeDashboardId: "",
        theme: "",
        timezone: ""
      },
      error: false,
      message: ""
    };

    const subPath = `/preferences`;
    const url = orgPreferences ? this.getOrgUrl(subPath) : this.getUrl(`/api/user${subPath}`);

    try {
      const response = await request.get<Preferences>(url);
      result.data = response.data;
      result.message = "Preferences fetched successfully";
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  createPartner(payloadData: Partner) {
    const url = `/api/tenants/partners`;
    return this.handlePartnerCRUD(url, payloadData, true);
  }

  editPartner(payloadData: Partner) {
    const url = `api/tenants/partners`;
    return this.handlePartnerCRUD(url, payloadData, false);
  }

  private async handlePartnerCRUD(url: string, payloadData: Partner, isCreate: boolean) {
    const dataSourceManagerRequest = datasourceApiManager.getDefault();

    const result: Result<ResponseMessage> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      let response;
      if (isCreate) {
        response = await dataSourceManagerRequest.post<ResponseMessage, Partner>(url, payloadData);
      } else {
        response = await dataSourceManagerRequest.put<ResponseMessage, Partner>(url, payloadData);
      }
      result.data = response.data;
      result.message = response.data.message;
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  async getPartnerUsersList(): Promise<PartnerUserResult> {
    const dataSourceManagerRequest = datasourceApiManager.getDefault();

    const url = "/api/tenants/partners/users";

    const result: PartnerUserResult = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await dataSourceManagerRequest.get<PartnerUserListReponse, any>(url);
      result.data = response.data;
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  async getPartnerList(): Promise<Result<PartnerListReponse>> {
    const dataSourceManagerRequest = datasourceApiManager.getDefault();

    const url = "/api/tenants/partners";
    const result: Result<PartnerListReponse> = {
      data: { partner: [] },
      error: false,
      message: ""
    };

    try {
      const response = await dataSourceManagerRequest.get<PartnerListReponse, any>(url);
      result.data = response.data;
      result.message = response.statusText;
    } catch (err: any) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  async createPartnerUser(data: PartnerUser): Promise<Result<ResponseMessage>> {
    const dataSourceManagerRequest = datasourceApiManager.getDefault();

    const url = `/api/tenants/partners/${data?.partnerId}/users`;

    const result: Result<ResponseMessage> = {
      data: { message: "" },
      error: false,
      message: ""
    };

    try {
      const response = await dataSourceManagerRequest.post<ResponseMessage, PartnerUser>(url, data);
      result.data = response.data;
      result.message = result.data.message;
    } catch (err: any) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  async createBulkPartnerUser(partnerId: string, emails: string[]): Promise<Result<ResponseMessage>> {
    const dataSourceManagerRequest = datasourceApiManager.getDefault();

    const url = `/api/tenants/partners/${partnerId}/users/bulk-create`;

    const result: Result<ResponseMessage> = {
      data: { message: "" },
      error: false,
      message: ""
    };

    const requestBody: BulkCreatePartnerUsersRequest = {
      emails
    };

    try {
      const response = await dataSourceManagerRequest.post<ResponseMessage, BulkCreatePartnerUsersRequest>(
        url,
        requestBody
      );
      result.data = response.data;
      result.message = result.data.message;
    } catch (err: any) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  async updatePartnerUser(data: PartnerUser): Promise<Result<ResponseMessage>> {
    const dataSourceManagerRequest = datasourceApiManager.getDefault();

    const url = `/api/tenants/partners/${data?.partnerId}/users`;

    const result: Result<ResponseMessage> = {
      data: { message: "" },
      error: false,
      message: ""
    };

    try {
      const response = await dataSourceManagerRequest.put<ResponseMessage, PartnerUser>(url, data);
      result.data = response.data;
      result.message = result.data.message;
    } catch (err: any) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  async removePartnerUser(userId: string, partnerId: string): Promise<Result<ResponseMessage>> {
    const dataSourceManagerRequest = datasourceApiManager.getDefault();

    const url = `/api/tenants/partners/${partnerId}/users/${userId}`;

    const result: Result<ResponseMessage> = {
      data: { message: "" },
      error: false,
      message: ""
    };

    try {
      const response = await dataSourceManagerRequest.delete<ResponseMessage>(url);
      result.data = response.data;
      result.message = result.data.message;
    } catch (err: any) {
      result.error = true;
      result.message = err?.response?.data.message;
    }
    return result;
  }

  async getGlobalUserList(): Promise<GlobalUserResult> {
    const dataSourceManagerRequest = datasourceApiManager.getDefault();

    const url = "/api/tenants/global-users";
    const result: GlobalUserResult = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await dataSourceManagerRequest.get<GlobalUserListReponse, any>(url);
      result.data = response.data;
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  async removeGlobalUser(userId: string): Promise<Result<ResponseMessage>> {
    const dataSourceManagerRequest = datasourceApiManager.getDefault();
    const url = `/api/tenants/global-users/${userId}`;
    const result: Result<ResponseMessage> = {
      data: { message: "" },
      error: false,
      message: ""
    };

    try {
      const response = await dataSourceManagerRequest.delete<ResponseMessage>(url);
      result.data = response.data;
      result.message = result.data.message;
    } catch (err: any) {
      result.error = true;
      result.message = err?.response?.data.message;
    }
    return result;
  }

  private getOrgUrl(subPath: string) {
    return this.getUrl(`/api/org${subPath}`);
  }

  private getUserUrl(subPath: string) {
    return this.getUrl(`/api/users${subPath}`);
  }

  private getUrl(subPath: string) {
    return appConfig.basePath + subPath;
  }
}

const usersApiService = new UsersApiService();

export default usersApiService;
