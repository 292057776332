import { IncButton, IncFaIcon, IncFaIconName, IncTextfield } from "@inception/ui";
import React, { useCallback, useRef, useState, useEffect, useMemo } from "react";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import { cx } from "emotion";
import { CypressConstants } from "@bicycle/tests";
import {
  PickerFieldType,
  FieldPickerOptionData,
  UserServiceField,
  FieldPickerContextDTO,
  BizServiceMetric,
  BizFieldInfo,
  UserServiceMetric,
  UserServiceFieldWithMeta,
  DemoDataParams
} from "../../services/api/explore";
import { FieldPickerUtils } from "../../utils/FieldPickerUtils";
import { CondensedPillRenderer as Pill } from "../SimplePill";
import { FieldPickerModel } from "../../field-picker";
import { TimeRange, useInputState, useToggleState } from "../../core";
import { VerticallyCenteredRow } from "../flex-components";
import FieldPickerTablePopUp from "./FieldPickerTablePopup";

interface FieldPickerContainerProps {
  label: string;
  disabled?: boolean;
  isMulti?: boolean;
  hideLabel?: boolean;
  minimal?: boolean;
  className?: string;
  placeholder?: string;

  fieldPickerModel?: FieldPickerModel;
  fieldTypes: PickerFieldType[];
  selectedOptions: FieldPickerOptionData[];
  onChange: (options: FieldPickerOptionData[], isFieldPickerClosed: boolean) => void;
  fieldPickerContextDto: FieldPickerContextDTO;
  labelIcon?: IncFaIconName;
  onLabelIconClick?: () => void;
  onDataFetch?: (model: FieldPickerModel) => void;
  getLatestTimeRange?: () => TimeRange;

  container?: HTMLElement;
  hideEventIdField?: boolean;
  readOnly?: boolean;
  asInlineEditor?: boolean;
  skipOptions?: FieldPickerOptionData[];
  isLoading?: boolean;
  demoDataParams?: DemoDataParams;

  canAddCustomField?: boolean;
  getUSFieldForCustomFieldName?: (fieldName: string) => UserServiceFieldWithMeta;
  customUserServiceFields?: UserServiceFieldWithMeta[];
  showCommonUSFieldsOnly?: boolean;
}

const FieldPickerContainer: React.FC<FieldPickerContainerProps> = props => {
  const {
    label,
    fieldPickerModel,
    fieldPickerContextDto: fieldPickerContext,
    fieldTypes,
    selectedOptions,
    onChange,
    hideLabel,
    isMulti,
    labelIcon,
    onLabelIconClick,
    onDataFetch,
    minimal = false,
    getLatestTimeRange,
    className: pClassName,
    container,
    hideEventIdField = false,
    readOnly = false,
    disabled = false,
    skipOptions,
    isLoading,
    demoDataParams = null,
    asInlineEditor = false,
    placeholder = "Choose Field",
    canAddCustomField = false,
    getUSFieldForCustomFieldName,
    customUserServiceFields: pCustomUserServcieFields,
    showCommonUSFieldsOnly,
    ...restProps
  } = props;

  const [showPicker, setShowPicker] = useState(false);
  const targetRef = useRef<any>(null);

  const [multiSelectOptions, setMultiSelectOptions] = useState<FieldPickerOptionData[]>([]);
  const [singleSelectOption, setSingleSelectOption] = useState<FieldPickerOptionData>();

  useEffect(() => {
    if (!isEmpty(selectedOptions)) {
      setMultiSelectOptions(selectedOptions);
    }
  }, [selectedOptions]);

  const onPickerSelectionChange = useCallback(
    (option: FieldPickerOptionData, closePicker = true) => {
      if (!isMulti) {
        setSingleSelectOption(option);
        if (closePicker) {
          setShowPicker(false);
          if (option) {
            onChange([option], true);
          }
        }
      }
    },
    [onChange, isMulti]
  );

  const onPickerRowSelection = useCallback((options: FieldPickerOptionData[]) => {
    setMultiSelectOptions(options);
  }, []);

  const openPicker = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowPicker(true);
  };

  const closePicker = () => {
    setShowPicker(false);
    if (isMulti) {
      onChange(multiSelectOptions, true);
    } else if (singleSelectOption) {
      onChange([singleSelectOption], true);
      setSingleSelectOption(null);
    }
  };

  const removeOption = useCallback(
    (idx: number) => {
      const _ops = cloneDeep(selectedOptions);
      _ops.splice(idx, 1);
      setMultiSelectOptions(_ops);
      onChange(_ops, !showPicker);
    },
    [selectedOptions, onChange, showPicker]
  );

  const memoisedFieldPickerModel = useMemo(
    (): FieldPickerModel =>
      fieldPickerContext && fieldPickerModel
        ? isEqual(fieldPickerContext, fieldPickerModel.context)
          ? fieldPickerModel
          : null
        : null,
    [fieldPickerContext, fieldPickerModel]
  );

  const className = cx("field-picker", {
    minimal,
    [pClassName]: Boolean(pClassName)
  });

  const containerClassName = cx("field-picker-container ", {
    disableClick: disabled,
    readonly: readOnly
  });

  const selectedOptionsJsx = useMemo(
    () =>
      selectedOptions.map((op, i) => {
        const removeOpt = op.readOnly || disabled || readOnly || asInlineEditor ? null : removeOption;
        return renderOptionLabel(op, i, isMulti, removeOpt);
      }),
    [asInlineEditor, disabled, isMulti, readOnly, removeOption, selectedOptions]
  );

  const {
    inputValue: customFieldName,
    onInputChange: onCustomFieldNameChange,
    setInputValue: setCustomFieldName
  } = useInputState("");

  const { isOpen: isAddFieldView, close: closeAddFieldView, open: openAddFieldView } = useToggleState();

  const resetAddFieldView = useCallback(() => {
    setCustomFieldName("");
    closeAddFieldView();
  }, [closeAddFieldView, setCustomFieldName]);

  const [customUserServiceFields, setCustomUserServiceFields] = useState<UserServiceFieldWithMeta[]>(
    pCustomUserServcieFields || []
  );

  const onAddCustomField = useCallback(() => {
    if (getUSFieldForCustomFieldName) {
      const usFieldWithMeta = getUSFieldForCustomFieldName(customFieldName);
      setCustomUserServiceFields(prev => [...prev, usFieldWithMeta]);
      setMultiSelectOptions(prev => [
        ...prev,
        {
          payload: usFieldWithMeta.userServiceField,
          type: "userServiceField"
        }
      ]);
    }

    resetAddFieldView();
  }, [customFieldName, getUSFieldForCustomFieldName, resetAddFieldView]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();
        onAddCustomField();
      }
    },
    [onAddCustomField]
  );

  const { attributes } = CypressConstants.components.FieldPickerV1;
  const dataCy = (restProps as any)["data-cy"];
  const tablePopUpDataCy = attributes.fieldPickerPopup(dataCy);

  return (
    <>
      <div
        className={className}
        data-inline-editor={asInlineEditor}
        {...restProps}
      >
        {!hideLabel && (
          <div
            className="field-picker-label"
            style={{ marginBottom: "6px" }}
          >
            <span className="marginRt4">{label}</span>
            {labelIcon && (
              <IncFaIcon
                iconName={labelIcon}
                onClick={onLabelIconClick}
              />
            )}
          </div>
        )}
        <div
          className={containerClassName}
          data-cy={attributes.fieldPickerDropDown}
          onClick={asInlineEditor ? null : openPicker}
          ref={targetRef}
        >
          {selectedOptions.length === 0 && <div className="field-picker-placeholder">{placeholder}</div>}
          {selectedOptionsJsx}
          {!(disabled || readOnly) && (
            <div className="inc-flex-row m-l-auto">
              <div className="drop-icon">
                {asInlineEditor && (
                  <IncFaIcon
                    className="status-info inc-cursor-pointer"
                    iconName="pen-to-square"
                    onClick={openPicker}
                  />
                )}
                {!asInlineEditor && <IncFaIcon iconName="caret-down" />}
              </div>
            </div>
          )}
        </div>

        <FieldPickerTablePopUp
          anchorEl={targetRef.current}
          closePicker={closePicker}
          container={container}
          customUserServiceFields={customUserServiceFields}
          data-cy={tablePopUpDataCy}
          demoDataParams={demoDataParams}
          fieldPickerContextDto={fieldPickerContext}
          fieldPickerModel={memoisedFieldPickerModel}
          fieldTypes={fieldTypes}
          getLatestTimeRange={getLatestTimeRange}
          hideEventIdField={hideEventIdField}
          isLoading={isLoading}
          isMulti={isMulti}
          offset={{
            x: 0,
            y: 0
          }}
          onChange={onPickerSelectionChange}
          onDataFetch={onDataFetch}
          onSelectionChange={onPickerRowSelection}
          placement="bottom-start"
          selectedOptions={multiSelectOptions}
          showCommonUSFieldsOnly={showCommonUSFieldsOnly}
          showPicker={showPicker}
          skipOptions={skipOptions}
        >
          {canAddCustomField && (
            <VerticallyCenteredRow className="flex-gap-12 marginBt12">
              {isAddFieldView && (
                <>
                  <IncTextfield
                    autoFocus
                    className="inc-flex-grow"
                    containerClassName="inc-flex-grow"
                    onChange={onCustomFieldNameChange}
                    onKeyDown={onKeyDown}
                    placeholder="Add Dimension"
                    value={customFieldName}
                  />

                  <IncButton
                    color="secondary-blue"
                    iconName="plus"
                    iconType="iconText"
                    label="Add"
                    onClick={onAddCustomField}
                    size="small"
                  />

                  <IncButton
                    color="secondary-red"
                    iconType="iconText"
                    label="Cancel"
                    onClick={resetAddFieldView}
                    size="small"
                  />
                </>
              )}

              {!isAddFieldView && (
                <IncButton
                  className="marginLtAuto"
                  color="link"
                  disabled={isLoading || !fieldPickerModel}
                  iconName="plus"
                  iconType="iconText"
                  label="Add Dimension"
                  onClick={openAddFieldView}
                  size="small"
                />
              )}
            </VerticallyCenteredRow>
          )}
        </FieldPickerTablePopUp>
      </div>
    </>
  );
};

const getLabel = (op: FieldPickerOptionData) => {
  const { type, payload } = op;
  switch (type) {
    case "userServiceField": {
      return FieldPickerUtils.getUserServiceFieldLabel(payload as UserServiceField);
    }

    case "userServiceMetric":
    case "bizEntityMetric": {
      return FieldPickerUtils.getMetricLabel(payload as BizServiceMetric | UserServiceMetric);
    }

    case "bizEntityField": {
      return FieldPickerUtils.getBizFieldLabel(payload as BizFieldInfo);
    }

    default:
      return "";
  }
};

const renderOptionLabel = (
  op: FieldPickerOptionData,
  i: number,
  isMulti: boolean,
  removeOption: (idx: number) => void
) => {
  const label = getLabel(op);
  const onRemove = removeOption ? () => removeOption(i) : null;

  if (isMulti) {
    return (
      <div
        className="inc-flex-row pill-container"
        key={i}
      >
        <Pill
          label={label}
          onRemove={onRemove}
        />
      </div>
    );
  }

  const textLabel = i > 0 ? `${label} , ` : label;
  return (
    <div
      className="inc-flex-row inc-flex-vertical-center single-selection-label"
      key={i}
    >
      <span>{textLabel}</span>
    </div>
  );
};

export default FieldPickerContainer;
