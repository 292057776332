import { IncModal, IncModalProps } from "@inception/ui";
import { css } from "emotion";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  ConditionsBuilder,
  ConditionWithPickerTypeExpressionTree,
  VerticallyCenteredRow
} from "../../../../components";
import {
  ConditionWithPickerType,
  FieldPickerContextDTO,
  LogicalOperator,
  UserServiceFilterExpressionTree
} from "../../../../services/api/explore";
import { ExploreQueryUtils } from "../../../../utils";
import { ALL_USERSERVICES_ENTITY_TYPE_ID } from "../../../../utils/ExploreUtils";

interface EVFVContainerProps {
  onChange: (exprTree: UserServiceFilterExpressionTree) => void;
  onClose: () => void;

  filterExprTree?: UserServiceFilterExpressionTree;
  fieldPickerContext?: FieldPickerContextDTO;
}

export const EventFilterExpressionTreeModal: React.FC<EVFVContainerProps> = props => {
  const { filterExprTree: pFilterExprTree, onChange, onClose, fieldPickerContext: pFieldPickerContext } = props;

  const isValidRef = useRef(false);
  const isValid = isValidRef.current;

  const fieldPickerContext = useMemo<FieldPickerContextDTO>(
    () =>
      pFieldPickerContext || {
        entityId: null,
        entityType: ALL_USERSERVICES_ENTITY_TYPE_ID,
        entityName: "",
        showFields: true,
        userServices: []
      },
    [pFieldPickerContext]
  );

  const getDefExprTree = useCallback(() => {
    if (pFilterExprTree) {
      return ExploreQueryUtils.getAdvancedConditionFilters(pFilterExprTree);
    }

    return {
      filterNodes: [],
      logicalOperator: LogicalOperator.AND
    };
  }, [pFilterExprTree]);
  const [filterExprTree, setFilterExprTree] = useState(getDefExprTree());
  useEffect(() => {
    setFilterExprTree(getDefExprTree());
  }, [getDefExprTree]);

  const onApplyChange = useCallback(() => {
    const eFilterExprTree = ExploreQueryUtils.getUserServiceFilterExpressionTree(filterExprTree);
    onChange(eFilterExprTree);
    onClose();
  }, [filterExprTree, onChange, onClose]);

  const onCancelChange = useCallback(() => {
    setFilterExprTree(getDefExprTree());
    onClose();
  }, [getDefExprTree, onClose]);

  const actions = useMemo<IncModalProps["actions"]>(
    () => ({
      primary: {
        onClick: onApplyChange,
        label: "Apply Filters",
        color: "primary",
        disabled: !isValid
      },
      secondary: {
        onClick: onCancelChange,
        color: "secondary",
        label: "Cancel"
      }
    }),
    [isValid, onApplyChange, onCancelChange]
  );

  const onFiltersChange = useCallback(
    (filters: ConditionWithPickerType[], isValid: boolean, filterExprTree: ConditionWithPickerTypeExpressionTree) => {
      isValidRef.current = isValid;
      setFilterExprTree(filterExprTree);
    },
    []
  );

  return (
    <IncModal
      actions={actions}
      className={className}
      disableFocusOnLoad
      onClose={onClose}
      show
      showClose
      size="lg"
      titleText="Advanced Filter"
    >
      <VerticallyCenteredRow className="inc-label-common marginBt12">Add your criteria</VerticallyCenteredRow>
      <div className="event-filters-container marginTp12">
        <ConditionsBuilder
          fieldPickerContext={fieldPickerContext}
          fieldTypes={["userServiceField"]}
          filterExpressionTree={filterExprTree}
          filterJoin="AND"
          filters={filters}
          label="Where"
          labelAlign="horizontal"
          onChange={onFiltersChange}
          useExpressionTree
        />
      </div>
    </IncModal>
  );
};

const className = css`
  max-height: 85vh;
`;
const filters: ConditionWithPickerType[] = [];
