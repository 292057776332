import { isEmpty, max } from "lodash";
import { generateId } from "@inception/ui";
import { WidgetLayout, WidgetModel } from "../models/DashboardModel";
import VariableModel from "../models/VariableModel";
import { CommonWidgetProperties, Query } from "../models/BaseWidgetModel";
import { SeriesOverride } from "../widgets/TimeSeries/models/model";
import { RegionHighlight } from "../../components/charts/plot-bands/RegionHighlights";
import { dashboardUtils } from "../dashboard-grid";

export const getLegendFormatTemplate = (keys: string[], defaultLegend: string): string => {
  if (!isEmpty(keys)) {
    const legendList = keys.map(tag => `${tag}: {{${tag}}}`);
    return legendList.join(", ");
  }
  return `${defaultLegend}`;
};

export const getWidgetTemplate = (
  wId: string,
  datasource: string,
  title: string,
  type: string,
  chartType: string
): WidgetModel => ({
  datasource: datasource,
  id: wId,
  title: title,
  type: type,
  chartType: chartType,
  queries: [],
  background: ""
});

export const getLayoutTemplate = (
  id: string,
  width: number,
  height: number,
  x: number,
  y: number,
  minW: number,
  minH: number,
  staticLayout = false
): WidgetLayout => ({
  w: width || 12,
  h: height || 16,
  x: x || 0,
  y: y || 0,
  i: id || "",
  minW: minW,
  minH: minH,
  moved: false,
  static: staticLayout
});

export const generateNxMLayout = (
  configs: Array<Array<[number, number]>>,
  offsetHeight = 0,
  minHeight: number = null
): WidgetLayout[] => {
  const layouts: WidgetLayout[] = [];
  let offsetY = offsetHeight;
  configs.forEach(rowConfigs => {
    let x = 0;
    rowConfigs.forEach(config => {
      const [width, height] = config;
      const w = width;
      const h = height;
      const minH = minHeight ? minHeight : height;

      const y = offsetY;
      layouts.push(getLayoutTemplate("", w, h, x, y, 3, minH, false));
      x = x + width;
    });
    offsetY = max(rowConfigs.map(config => config[1])) + offsetY;
  });
  return layouts;
};

export function QueryBuilder() {
  const model = {
    expr: "",
    query: "",
    hide: false,
    refId: "",
    datasource: "",
    showingGraph: true,
    instant: false,
    legendFormat: ""
  } as Query;

  return {
    setExpr: function (expr: string) {
      model.expr = expr;
      return this;
    },
    setQuery: function (query: unknown) {
      model.query = query;
      return this;
    },
    setHide: function (hide = false) {
      model.hide = hide;
      return this;
    },

    setDatasource: function (datasource: string) {
      model.datasource = datasource;
      return this;
    },

    setLegendFormat: function (legendFormat: string) {
      model.legendFormat = legendFormat;
      return this;
    },

    setCustom: function (key: string, value: any) {
      (model as any)[key] = value;
      return this;
    },

    // setInstant: function (instant: boolean) {
    //   model.instant = instant;
    //   return this;
    // },

    // setShowingGraph: function (showingGraph: boolean) {
    //   model.showingGraph = showingGraph;
    //   return this;
    // },

    build: function () {
      return model;
    }
  };
}

export const getRegionHighlight = (id: string, from: number, to: number, color?: string): RegionHighlight => ({
  id: `${id}`,
  from: from,
  to: to,
  color: color ? color : "rgba(233, 104, 89,0.1)"
});

export const WidgetBuilder = {
  genericBuilder: genericWidgetBuilder
};

export function genericWidgetBuilder(incomingModel?: WidgetModel) {
  let refId = 0;
  const nextRefId = () => String.fromCharCode(65 + (refId === 0 ? refId : ++refId));

  const model: WidgetModel = incomingModel
    ? incomingModel
    : ({
        id: "",
        title: "",
        background: "",
        type: "timeseries",
        datasource: "",
        queries: [],
        meta: {
          edit: false,
          compare: false,
          resizable: true,
          hideHeader: false,
          hideActions: false
        },
        seriesOverrides: [],
        highlights: []
      } as WidgetModel);

  return {
    //model: model,
    id: function (id: string) {
      model.id = id;
      return this;
    },

    title: function (title: string) {
      model.title = title;
      return this;
    },
    type: function (type: string) {
      model.type = type;
      return this;
    },
    datasource: function (ds: string) {
      model.datasource = ds;
      return this;
    },
    addQuery: function (q: Query) {
      q.refId = nextRefId();
      model.queries.push(q);
      return this;
    },

    meta: function (edit = false, compare = false, resizable = true, hideHeader = false, hideActions = false) {
      model.meta.edit = edit;
      model.meta.compare = compare;
      model.meta.resizable = resizable;
      model.meta.hideHeader = hideHeader;
      model.meta.hideActions = hideActions;
      return this;
    },

    options: function (options: Record<string, unknown>) {
      model.options = options;
      return this;
    },

    properties: function <T extends CommonWidgetProperties>(properties: T) {
      model.properties = properties;
      return this;
    },

    seriesOverrides: function (seriesOverrides: SeriesOverride[]) {
      model.seriesOverrides = seriesOverrides;
      return this;
    },

    highlights: function (highlights: RegionHighlight[]) {
      model.highlights = highlights;
      return this;
    },

    setCustom: function (key: string, value: any) {
      (model as any)[key] = value;
      return this;
    },

    build() {
      model.queries.forEach(q => {
        q.datasource = q.datasource || model.datasource;
      });

      return model;
    }
  };
}

export function DashboardBuilder(name: string) {
  const model = dashboardUtils.getNewDashboard();
  const widgetModelMap: Map<string, WidgetModel> = new Map();
  const widgetLayoutMap: Map<string, WidgetLayout> = new Map();

  model.name = !isEmpty(name) ? name : "";
  let commonDatasource = "";

  let widgetId = 0;
  const nextWidgetId = () => ++widgetId;

  return {
    id: function (id: string) {
      model.id = id ? id : `${name}-customDashboard-${generateId()}`;
      return this;
    },

    name: function (name: string) {
      model.name = name;
      return this;
    },

    description: function (description: string) {
      model.description = description;
      return this;
    },

    cohort: function (entityTypeId: string, entityTypeName: string, cohortId: string, cohortName: string) {
      model.entityTypeId = entityTypeId;
      model.entityTypeName = entityTypeName;
      model.cohortId = cohortId;
      model.cohortName = cohortName;
      model.type = "cohort";
      return this;
    },

    meta: function (compare = true, edit = false, resizable = true) {
      model.meta.compare = compare;
      model.meta.edit = edit;
      model.meta.resizable = resizable;
      return this;
    },

    dynamic: function (dynamic: boolean) {
      model.meta.edit = !dynamic;
      model.meta.resizable = !dynamic;
      model.isDynamic = dynamic;
      return this;
    },

    variables: function (variables: VariableModel[]) {
      model.variables = variables;
      return this;
    },

    addWidget: function (wModel: WidgetModel, wLayout: WidgetLayout, wId?: string) {
      const id = wId ? wId : `${nextWidgetId()}`;
      wModel.id = wLayout.i = id;
      widgetModelMap.set(id, wModel);
      widgetLayoutMap.set(id, wLayout);
      return this;
    },

    /**
     *
     * @param datasource setting this parameter would set datasource to all widgets and its queries
     * exisitng value is not overriden
     */
    datasource: function (datasource: string) {
      commonDatasource = datasource;
      return this;
    },

    build: function () {
      const widgets: WidgetModel[] = Array.from(widgetModelMap.values());
      const layout: WidgetLayout[] = Array.from(widgetLayoutMap.values());
      model.widgets = widgets;

      model.widgets.forEach(w => {
        w.datasource = w.datasource || commonDatasource;
        w.queries.forEach(q => (q.datasource = q.datasource || commonDatasource));
      });

      model.layout = layout;

      return model;
    }
  };
}
