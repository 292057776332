import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";
import { ISaxIcon, IncPopConfirm, IncSmartText, IncTextfield, IncToolTip } from "@inception/ui";
import { OpBizDataQuery, UberOperationalizeTypes } from "../../services/api/operationalise";
import { BizDataQuery, WidgetConfigUtils } from "../../services/api/explore";
import { VerticallyCenteredRow, KPIQueryEditor, LoadingSpinner } from "../../components";
import { useToggleState } from "../../core";
import { pluralizeWord } from "../../utils";
import {
  analysisCardClassName,
  diagnosticKpiWrapperClass,
  getDefaultBizDataQuery,
  useOpAnalysisStore
} from "./context";
import AnalysisDiagnosticKpiEditor from "./AnalysisDiagnosticKPIEditor";

type Props = {
  opBizDataQuery: OpBizDataQuery;
  id: string;
  eventTypeId: string;
  onChangeBizDataQuery: (bizDataQuery: BizDataQuery) => void;
  onChangeName: (name: string) => void;
  onDelete: () => void;
  onClone: () => void;
};

const KpiEditorWrapper: FC<Props> = props => {
  const {
    id,
    onChangeBizDataQuery: pOnChangeBizDataQuery,
    opBizDataQuery,
    onChangeName: pOnChangeName,
    onDelete,
    eventTypeId,
    onClone
  } = props;
  const { bizDataQuery, name = "" } = opBizDataQuery || {};
  const { state } = useOpAnalysisStore();
  const { opAnalysisImpl } = state;
  const diagnosticKpis = useMemo(() => opAnalysisImpl.getDiagnosticKpis(id), [id, opAnalysisImpl]);

  const { kpi: diagnosticKpiList = [] } = diagnosticKpis || {};

  const [kpiQuery, setKpiQuery] = useState<OpBizDataQuery>();
  const initialiseKPI = useCallback(async () => {
    try {
      if (opBizDataQuery) {
        const transformedQuery = await WidgetConfigUtils.getWidgetConfigBasedBizDataQuery(opBizDataQuery.bizDataQuery);
        setKpiQuery({
          bizDataQuery: transformedQuery,
          rollingFreq: null,
          rollingFunction: null,
          name: opBizDataQuery.name
        });
      } else {
        setKpiQuery({
          bizDataQuery: getDefaultBizDataQuery(eventTypeId),
          rollingFreq: null,
          rollingFunction: null,
          name: "Key Performance Indicator"
        });
      }
    } catch (error) {
      setKpiQuery({
        bizDataQuery: getDefaultBizDataQuery(eventTypeId),
        rollingFreq: null,
        rollingFunction: null,
        name: "Key Performance Indicator"
      });
    }
  }, [eventTypeId, opBizDataQuery]);

  useEffect(() => {
    initialiseKPI();
  }, [initialiseKPI]);

  const onChange = useCallback(
    (isValid: boolean, errorsText: string, kpi: UberOperationalizeTypes.OpBizDataQuery) => {
      if (isValid && !isEqual(bizDataQuery, kpi.bizDataQuery)) {
        pOnChangeBizDataQuery(kpi.bizDataQuery);
      }
    },
    [bizDataQuery, pOnChangeBizDataQuery]
  );

  const onChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      pOnChangeName(e.target.value);
    },
    [pOnChangeName]
  );

  const { close: onCloseDeletePopup, isOpen: isDeleteOpen, open: onOpenDeletePopup } = useToggleState();
  const { isOpen, toggle } = useToggleState();
  const onDeleteConfirm = useCallback(() => {
    onDelete();
    onCloseDeletePopup();
  }, [onCloseDeletePopup, onDelete]);

  const isDiagnosticKpiInfoVisible = !isOpen && !!diagnosticKpiList.length;

  return (
    <div className={`inc-flex-column flex-gap-12 ${analysisCardClassName} analysis-editor--kpi-container`}>
      <VerticallyCenteredRow className="flex-gap-12">
        <IncTextfield
          errorText="Name is Required"
          hasError={!name}
          label="KPI Name"
          onChange={onChangeName}
          placeholder="Enter KPI name"
          value={name}
        />
        <VerticallyCenteredRow className="marginLtAuto flex-gap-12">
          {isDiagnosticKpiInfoVisible && (
            <IncSmartText
              text={`${diagnosticKpiList.length} diagnostic ${pluralizeWord("KPI", diagnosticKpiList.length, false)} Available`}
            />
          )}
          <IncToolTip titleText="Edit">
            <ISaxIcon
              className="inc-cursor-pointer status-info"
              iconName="Edit"
              onClick={toggle}
              variant="Outline"
            />
          </IncToolTip>
          <IncToolTip titleText="Clone">
            <ISaxIcon
              className="status-info inc-cursor-pointer"
              iconName="Copy"
              onClick={onClone}
            />
          </IncToolTip>
          <IncToolTip titleText="Delete">
            <ISaxIcon
              className="status-danger inc-cursor-pointer"
              iconName="Trash"
              onClick={onOpenDeletePopup}
            />
          </IncToolTip>
          <IncPopConfirm
            buttonProps={{
              okButton: {
                text: "Confirm"
              },
              cancelButton: {
                text: "Cancel"
              }
            }}
            color="secondary-blue"
            iconProps={{
              iconName: "trash",
              className: "status-danger"
            }}
            onCancel={onCloseDeletePopup}
            onConfirm={onDeleteConfirm}
            open={isDeleteOpen}
            placement="bottomRight"
            showCancel
            title={"Confirm Delete?"}
          />
        </VerticallyCenteredRow>
      </VerticallyCenteredRow>
      {isOpen && (
        <>
          {!kpiQuery && <LoadingSpinner titleText="Loading Key Performance Indicator..." />}
          {Boolean(kpiQuery) && (
            <KPIQueryEditor
              kpiQuery={kpiQuery}
              onStateChange={onChange}
              showIsSpikePositive
            />
          )}

          <div className={`${diagnosticKpiWrapperClass} analysis-editor--diagnostic-kpi-editor`}>
            <AnalysisDiagnosticKpiEditor
              eventTypeId={eventTypeId}
              relatedKpiKey={id}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default KpiEditorWrapper;
