import React, { FC, useMemo } from "react";
import {
  TableDataColumn,
  IncRTable,
  CurrencyType,
  IncSmartText,
  IncToolTip,
  IncFaIcon,
  FilterRendererProps,
  IncButton
} from "@inception/ui";
import { cx } from "emotion";
import { inRange, round } from "lodash";
import {
  SimpleEntityNameRenderer,
  VerticallyCenteredRow,
  EntityDetails,
  LoadingSpinner,
  TableRangeFilterRenderer
} from "../../components";
import { ENTITY_TAG, isEntity } from "../../utils";
import { DataType } from "../../core";
import { SingleStatData, ChangeMetric } from "../types";
import { ChartPoint } from "../../dashboard/widgets/TimeSeries/models/model";
import { ChangeDataRenderer } from "./ChangeRenderer";

export interface EntityTableStatsData {
  ssData: SingleStatData[];
  columnData: Record<string, string>;
}

interface Props {
  isFetching?: boolean;
  isError?: boolean;
  error?: string;

  data: Map<string, EntityTableStatsData>;
  compareData: Map<string, EntityTableStatsData>;

  changeMetrics: ChangeMetric[];
  changeMetricHeaders: string[];
  compareChangeMetricHeaders?: Array<Record<ChangeMetric, string>>;
  addCompareInfoAsColumns?: boolean;

  columnAccessors: string[];
  columnHeaders: string[];
  entityColumnKeys?: string[];

  compareTimeStr: string;
  aggTag: string | string[];
  dataType?: DataType;
  currencyType?: CurrencyType;

  valueFormatter?: (value: number, changeType: "absolute" | "percent" | "none") => string;
  hideHeader?: boolean;
  useTitleAsTooltip?: boolean;
  hideCompare?: boolean;

  entityType?: string;
  existingEntityFilters?: Record<string, boolean>;
  onDataKeyClick?: (value: string, entityType: string) => void;
  onChangeDataClick?: (matchTags: Record<string, string>, label: string) => void;
  onDownloadClick?: () => void;
  downloadInProgress?: boolean;
}

export const BizEntityTableWithStats: FC<Props> = props => {
  const {
    data,
    compareData,
    isFetching = false,
    isError = false,
    error = null,
    changeMetrics,
    compareTimeStr,
    dataType = "STRING",
    aggTag,
    currencyType = "USD",
    changeMetricHeaders,
    compareChangeMetricHeaders,
    onDataKeyClick,
    hideHeader = false,
    valueFormatter,
    useTitleAsTooltip,
    columnHeaders,
    columnAccessors,
    entityColumnKeys,
    onChangeDataClick,
    entityType,
    existingEntityFilters,
    onDownloadClick,
    downloadInProgress,
    hideCompare = false,
    addCompareInfoAsColumns
  } = props;

  const { entityChangeData, entityData, labelToTagsMap, entityLookupData } = useMemo(() => {
    const entityChangeData: Map<string, number[]> = new Map();
    const entityLookupData: Record<string, string> = {};
    const entityData: Record<string, ChartPoint[]> = {};
    const labelToTagsMap: Record<string, Record<string, string>> = {};

    const dataArr = [...data.entries()];
    dataArr.forEach(([k, v]) => {
      const { ssData, columnData } = v;
      const numEntries = ssData.length;

      ssData.forEach((ve, idx) => {
        if (ve) {
          const { percentChange, rawName: entityId, value: currValue } = ve;

          if (entityId) {
            const compareValue = compareData.get(k)?.ssData?.[idx]?.value || 0;

            const changeMetric = changeMetrics?.[idx];
            const change =
              changeMetric === "deltaPercentage"
                ? percentChange
                : changeMetric === "delta"
                  ? currValue - compareValue
                  : changeMetric === "timeShift"
                    ? compareValue
                    : currValue;

            const entityChangeDataEntry = entityChangeData.get(entityId) || new Array(numEntries).fill(0);
            entityChangeDataEntry[idx] = change;

            entityChangeData.set(entityId, entityChangeDataEntry);

            entityData[entityId] = entityData[entityId] || new Array(numEntries).fill([0, 0]);
            entityData[entityId].splice(idx, 1, [currValue, compareValue]);

            labelToTagsMap[entityId] = {
              ...columnData
            };

            entityLookupData[entityId] = k;
          }
        }
      });
    });

    return {
      entityChangeData,
      entityData,
      labelToTagsMap,
      entityLookupData
    };
  }, [changeMetrics, compareData, data]);

  const numEntries = Object.values(entityData)[0]?.length || 0;
  const entityIds = useMemo(() => Array.from(entityChangeData.keys()), [entityChangeData]);

  const tableData = useMemo(
    () =>
      entityIds.map(entityId => {
        const tagsData = labelToTagsMap[entityId] || {};
        const entry: Record<string, any> = {
          entityId,
          ...tagsData,
          tagsData,
          custom: {}
        };

        const entityDataArr = entityData[entityId];
        entityDataArr.forEach((ed, idx) => {
          const key1 = `percentChange${idx}`;
          const key2 = `data${idx}`;

          const entityChangeDataEntry = entityChangeData.get(entityId);
          entry[key1] = entityChangeDataEntry?.[idx] || 0;
          entry[key2] = ed;
        });

        return entry;
      }),
    [entityChangeData, entityData, entityIds, labelToTagsMap]
  );

  const tableColumns = useMemo<TableDataColumn[]>(() => {
    const columns: TableDataColumn[] = [];

    columnAccessors.forEach((accessor, idx) => {
      const header = columnHeaders[idx];

      columns.push({
        accessor,
        header,
        filterable: true,
        filterFn: (rows, filter) => {
          if (filter) {
            const lowerFilter = filter.toString().toLowerCase();
            return rows.filter(row => {
              const name = row.original[accessor];
              const entityDetails = row.original.custom[accessor];

              const isEntityFieldAgg = entityColumnKeys?.includes(accessor) || isEntity(name);

              const valueToCheck = isEntityFieldAgg ? entityDetails?.name || name : name;
              return (valueToCheck || "").toString().toLowerCase().includes(lowerFilter);
            });
          }

          return rows;
        },
        renderer: (name: string, rowData) => {
          const isEntityFieldAgg = entityColumnKeys?.includes(accessor) || isEntity(name);

          const entityTypeName = accessor === "i_entity" ? entityType || accessor : accessor;
          const onClick = existingEntityFilters?.[entityTypeName]
            ? () => onDataKeyClick(rowData?.custom?.[accessor]?.name || name, entityTypeName)
            : null;

          const onDetailsFetched = (entityDetails: EntityDetails) => (rowData.custom[accessor] = entityDetails);

          return isEntityFieldAgg ? (
            onClick ? (
              <>
                <VerticallyCenteredRow className="width-fit-content visible-on-hover">
                  <SimpleEntityNameRenderer
                    id={name}
                    minimalLoader
                    onDetailsFetched={onDetailsFetched}
                    showPropertiesPopover
                  />

                  {onDataKeyClick && (
                    <IncFaIcon
                      className="marginLt10 inc-cursor-pointer display-element"
                      iconName="plus-circle"
                      onClick={onClick}
                    />
                  )}
                </VerticallyCenteredRow>
              </>
            ) : (
              <SimpleEntityNameRenderer
                id={name}
                minimalLoader
                onDetailsFetched={onDetailsFetched}
                showPropertiesPopover
              />
            )
          ) : (
            <IncSmartText text={name} />
          );
        },
        sortable: true,
        sortFn: (a, b) => {
          const valueA = a[accessor] || "";
          const valueB = b[accessor] || "";

          const isAEntity = entityColumnKeys?.includes(accessor) || isEntity(valueA);

          const isBEntity = entityColumnKeys?.includes(accessor) || isEntity(valueB);

          const nameA: string = isAEntity ? a.custom?.[accessor]?.name || valueA || "" : valueA;
          const nameB: string = isBEntity ? b.custom?.[accessor]?.name || valueB || "" : valueB;

          const diff = nameA.toString().localeCompare(nameB.toString());

          return diff === 0 ? "equal" : diff > 0 ? "lesser" : "greater";
        }
      });
    });

    if (!addCompareInfoAsColumns) {
      for (let idx = 0; idx < numEntries; idx++) {
        const accessor = `percentChange${idx}`;
        const changeMetricHeaderStr = changeMetricHeaders?.[idx] || "";
        const changeMetricHeader = useTitleAsTooltip ? (
          <IncToolTip
            placement="top"
            showArrow
            titleText={changeMetricHeaderStr}
          >
            <div className="width-100">
              <IncSmartText text={changeMetricHeaderStr} />
            </div>
          </IncToolTip>
        ) : (
          changeMetricHeaderStr
        );

        let currentMin = Number.POSITIVE_INFINITY;
        let currentMax = Number.NEGATIVE_INFINITY;

        let compareMin = Number.POSITIVE_INFINITY;
        let compareMax = Number.NEGATIVE_INFINITY;

        const key = `data${idx}`;

        const changeMetric = changeMetrics?.[idx];
        const changeType =
          changeMetric === "deltaPercentage" ? "percent" : changeMetric === "delta" ? "absolute" : "none";

        const valueFormatterWrapper = (value: number) =>
          valueFormatter ? valueFormatter(value, changeType) : String(value);

        tableData.forEach(rowData => {
          const [current, compare] = rowData[key];

          currentMin = Math.min(currentMin, current);
          currentMax = Math.max(currentMax, current);

          compareMin = Math.min(compareMin, compare);
          compareMax = Math.max(compareMax, compare);
        });

        currentMin = round(currentMin - 0.05, 2);
        currentMax = round(currentMax + 0.05, 2);

        const currentRange: ChartPoint = [currentMin, currentMax];
        // const compareRange: ChartPoint = [compareMin, compareMax];

        const filterable = currentMax > currentMin;

        columns.push({
          accessor,
          header: changeMetricHeader,
          sortable: true,
          sortFn: (datumA, datumB) =>
            (datumA || 0) < (datumB || 0) ? "lesser" : (datumA || 0) > (datumB || 0) ? "greater" : "equal",
          filterable,
          filterFn: (rows, filter) => {
            if (filter?.length) {
              return rows.filter(row => {
                const rowData = row.original;

                const key = `data${idx}`;
                const [current] = rowData[key];

                const [min, max] = filter;
                return inRange(current, min, max);
              });
            }

            return rows;
          },
          filterRenderer: (props: FilterRendererProps<ChartPoint>) => (
            <TableRangeFilterRenderer
              {...props}
              label={changeMetricHeaderStr}
              range={currentRange}
            />
          ),
          renderer: (value, rowData) => {
            const [current, compare] = rowData[key];

            const onClick = onChangeDataClick
              ? () => {
                  const { entityId, tagsData } = rowData;
                  //todo: Refactor make sure metricName is not initialized in tagsData
                  tagsData?.["metricName"] && delete tagsData["metricName"];
                  const name = entityLookupData[entityId] || entityId;
                  onChangeDataClick(tagsData, name);
                }
              : null;

            return (
              <ChangeDataRenderer
                change={value}
                changeType={changeType}
                compare={compare}
                compareTimeStr={compareTimeStr}
                currencyType={currencyType}
                current={current}
                dataType={dataType}
                hideCompare={addCompareInfoAsColumns || hideCompare}
                onClick={onClick}
                valueFormatter={valueFormatterWrapper}
              />
            );
          },
          align: "center"
        });
      }
    } else {
      for (let idx = 0; idx < numEntries; idx++) {
        const colKeys = ["current", "compare", "comparePercentage"];

        const accessor = `data${idx}`;
        const percentageAccessor = `percentChange${idx}`;

        colKeys.forEach(key => {
          const isCurrent = key === "current";
          const isCompare = key === "compare";
          const isComparePercent = !isCurrent && !isCompare;

          const changeMetricHeaderStr = isCurrent
            ? changeMetricHeaders?.[idx] || ""
            : isCompare
              ? compareChangeMetricHeaders?.[idx]?.timeShift || ""
              : compareChangeMetricHeaders?.[idx]?.deltaPercentage || "";

          const changeMetricHeader = useTitleAsTooltip ? (
            <IncToolTip
              placement="top"
              showArrow
              titleText={changeMetricHeaderStr}
            >
              <div className="width-100">
                <IncSmartText text={changeMetricHeaderStr} />
              </div>
            </IncToolTip>
          ) : (
            changeMetricHeaderStr
          );

          let rangeMin = Number.POSITIVE_INFINITY;
          let rangeMax = Number.NEGATIVE_INFINITY;

          tableData.forEach(rowData => {
            const [current, compare] = rowData[accessor];
            const value = isCurrent ? current : compare;

            rangeMin = Math.min(rangeMin, value);
            rangeMax = Math.max(rangeMax, value);
          });

          const range: ChartPoint = [rangeMin, rangeMin];
          const filterable = rangeMin > rangeMin && !isComparePercent;

          const changeType = isComparePercent ? "percent" : "none";
          const valueFormatterWrapper = (value: number) =>
            valueFormatter ? valueFormatter(value, changeType) : String(value);

          columns.push({
            accessor,
            header: changeMetricHeader,
            sortable: true,
            sortFn: (datumA, datumB) =>
              (datumA || 0) < (datumB || 0) ? "lesser" : (datumA || 0) > (datumB || 0) ? "greater" : "equal",
            filterable,
            filterFn: (rows, filter) => {
              if (filter?.length) {
                return rows.filter(row => {
                  const rowData = row.original;

                  const [current, compare] = rowData[accessor];
                  const value = isCurrent ? current : compare;

                  const [min, max] = filter;
                  return inRange(value, min, max);
                });
              }

              return rows;
            },
            filterRenderer: (props: FilterRendererProps<ChartPoint>) => (
              <TableRangeFilterRenderer
                {...props}
                label={changeMetricHeaderStr}
                range={range}
              />
            ),
            renderer: (value, rowData) => {
              const [current, compare] = rowData[accessor];
              const percentChange = rowData[percentageAccessor] as number;

              const changeValue = isCurrent ? current : isCompare ? compare : percentChange;

              const onClick = onChangeDataClick
                ? () => {
                    const { entityId, tagsData } = rowData;
                    //todo: Refactor make sure metricName is not initialized in tagsData
                    tagsData?.["metricName"] && delete tagsData["metricName"];
                    const name = entityLookupData[entityId] || entityId;
                    onChangeDataClick(tagsData, name);
                  }
                : null;

              return (
                <ChangeDataRenderer
                  change={changeValue}
                  changeType={changeType}
                  compare={compare}
                  compareTimeStr={compareTimeStr}
                  currencyType={currencyType}
                  current={current}
                  dataType={dataType}
                  hideCompare={addCompareInfoAsColumns || hideCompare}
                  onClick={onClick}
                  valueFormatter={valueFormatterWrapper}
                />
              );
            },
            align: "center"
          });
        });
      }
    }

    return columns;
  }, [
    columnAccessors,
    addCompareInfoAsColumns,
    columnHeaders,
    entityColumnKeys,
    entityType,
    existingEntityFilters,
    onDataKeyClick,
    numEntries,
    changeMetricHeaders,
    useTitleAsTooltip,
    changeMetrics,
    tableData,
    onChangeDataClick,
    compareTimeStr,
    currencyType,
    dataType,
    hideCompare,
    valueFormatter,
    entityLookupData,
    compareChangeMetricHeaders
  ]);

  const slices = (Array.isArray(aggTag) ? aggTag : [aggTag]).filter(slice => slice !== ENTITY_TAG);
  const sliceStr = slices.length ? `by ${slices.join(", ")}` : "";

  const className = useMemo(
    () =>
      cx("ecs-entity-table", {
        "ecs-entity-table--with-header": Boolean(changeMetricHeaders)
      }),
    [changeMetricHeaders]
  );

  const hideTableHeaders = useMemo(() => {
    const allHeaders: string[] = [];
    changeMetricHeaders && allHeaders.push(...changeMetricHeaders);
    columnHeaders && allHeaders.push(...columnHeaders);

    const headersExist = allHeaders.some(Boolean);
    return !headersExist;
  }, [changeMetricHeaders, columnHeaders]);

  const errorMessage = useMemo(
    () => (
      <VerticallyCenteredRow>
        {Boolean(error) && (
          <IncToolTip
            placement="top"
            titleText={error}
            variant="error"
          >
            <VerticallyCenteredRow>
              <IncFaIcon
                className="marginRt8 status-danger"
                iconName="exclamation-triangle"
              />
            </VerticallyCenteredRow>
          </IncToolTip>
        )}
        Error fetching data
      </VerticallyCenteredRow>
    ),
    [error]
  );

  return (
    <div
      className={className}
      data-download-enabled={Boolean(onDownloadClick)}
    >
      {!hideHeader && (
        <div className="ecs-entity-table--header">
          <div className="inc-text-body-medium">Top Insights</div>
          {Boolean(sliceStr) && <div className="inc-text-subtext marginLt8 subtext">{sliceStr}</div>}
        </div>
      )}

      {onDownloadClick && (
        <div className="flex inc-flex-end width-100 marginBt6">
          {downloadInProgress ? (
            <LoadingSpinner
              position="right"
              titleText="Downloading..."
            />
          ) : (
            <IncButton
              color="link"
              onClick={onDownloadClick}
            >
              <IncFaIcon
                className="marginBt4 marginRt4"
                iconName="download"
              />
              Download
            </IncButton>
          )}
        </div>
      )}

      <IncRTable
        classNames={{
          table: "ecs-entity-table--table"
        }}
        columns={tableColumns}
        data={tableData}
        errorDataMessage={errorMessage}
        hasError={isError}
        hideHeaders={hideTableHeaders}
        isLoading={isFetching}
        variant="minimal"
      />
    </div>
  );
};
