import React, { FC, useCallback, useMemo, useState } from "react";
import { IncButton, IncPopConfirm, IncSmartText, generateId } from "@inception/ui";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import { isEqual, sortBy } from "lodash";
import { css } from "emotion";
import { DrilldownTypes } from "../../../../../../platform/services/api/triage-v2";
import {
  FieldPickerContextDTO,
  ImpactedWidget,
  ToolMetaDataList
} from "../../../../../../platform/services/api/explore";
import { VerticallyCenteredRow } from "../../../../../../platform/components";
import { useRefState, useToggleState } from "../../../../../../platform/core";
import DrillDownStepChild from "./DrillDownStepChild";
import { addChildIdPrefix } from "./utils";

type Props = {
  drillDownSteps: DrilldownTypes.DrillDownStep[];
  toolSpec: ToolMetaDataList;
  isToolSpecFetching: boolean;
  isToolSpecError: boolean;
  toolSpecError: string;
  onChangeSteps: (drillDownSteps: DrilldownTypes.DrillDownStep[]) => void;
  impactedWidgets?: ImpactedWidget[];
  setError?: (stepId: string, errors: string[]) => void;
  pickerContextDto: FieldPickerContextDTO;
  onOverride: () => void;
  shouldShowOverrideWorkflow?: boolean;
  isOverridingInProgress: boolean;
  children?: React.ReactNode;
};
const baseChildHeight = 420;
const ResponsiveGridLayout = WidthProvider(Responsive);

const DrillDownStepList: FC<Props> = props => {
  const {
    drillDownSteps,
    isToolSpecError,
    isToolSpecFetching,
    onChangeSteps,
    toolSpec,
    toolSpecError,
    impactedWidgets,
    pickerContextDto,
    shouldShowOverrideWorkflow = false,
    isOverridingInProgress = false,
    children,
    setError,
    onOverride
  } = props;

  const onAddStep = useCallback(() => {
    const id = generateId();
    onChangeSteps([
      ...drillDownSteps,
      {
        description: "",
        faIcon: "",
        id: `${addChildIdPrefix}-${id}`,
        uiKey: `${addChildIdPrefix}-${id}`,
        name: "",
        predicate: null,
        source: null,
        steps: []
      }
    ]);
  }, [drillDownSteps, onChangeSteps]);

  const onDelete = useCallback(
    (stepId: string) => {
      setError?.(stepId, []);
      setLayouts(prev => prev.filter(e => e.i !== stepId));
      onChangeSteps(drillDownSteps.filter(listStep => listStep.uiKey !== stepId));
    },
    [drillDownSteps, onChangeSteps, setError]
  );

  const drillDownStepsRef = useRefState<DrilldownTypes.DrillDownStep[]>(drillDownSteps);

  const onUpdateStep = useCallback(
    (step: DrilldownTypes.DrillDownStep) => {
      if (drillDownStepsRef.current) {
        onChangeSteps(drillDownStepsRef.current.map(listStep => (listStep.uiKey === step.uiKey ? step : listStep)));
      }
    },
    [drillDownStepsRef, onChangeSteps]
  );
  const onClone = useCallback(
    (step: DrilldownTypes.DrillDownStep) => {
      onChangeSteps([...drillDownSteps, step]);
    },
    [drillDownSteps, onChangeSteps]
  );

  const disableAdd = !!drillDownSteps.filter(step => !step.uiKey).length || isOverridingInProgress;
  const [layouts, setLayouts] = useState<Layout[]>([]);

  const layout = useMemo(
    () => ({
      lg: layouts,
      md: layouts,
      sm: layouts,
      xs: layouts,
      xxs: layouts
    }),
    [layouts]
  );

  const changeLayout = useCallback((id: string, hRatio: number) => {
    setLayouts(prev => {
      const nLayouts = [...prev];
      const lIndex = nLayouts.findIndex(layout => layout.i === id);
      if (lIndex !== -1) {
        if (nLayouts[lIndex].h !== hRatio) {
          nLayouts[lIndex] = {
            ...nLayouts[lIndex],
            h: hRatio
          };
        }
      } else {
        nLayouts.push({
          h: 1,
          x: 0,
          y: nLayouts.length,
          w: 1,
          moved: false,
          i: id,
          static: false
        });
      }
      return nLayouts;
    });
  }, []);

  const drillDownStepListJsx = useMemo(
    () =>
      drillDownSteps.map(step => {
        const onChangeLayout = (id: string, hRatio: number) => changeLayout(id, hRatio);
        return (
          <div key={step.uiKey}>
            <DrillDownStepChild
              addClonedDrillDownStep={onClone}
              baseHeight={baseChildHeight}
              drillDownStep={step}
              impactedWidgets={impactedWidgets}
              isToolSpecError={isToolSpecError}
              isToolSpecFetching={isToolSpecFetching}
              onChangeLayout={onChangeLayout}
              onDeleteDrillDownStep={onDelete}
              onUpdateStep={onUpdateStep}
              pickerContextDto={pickerContextDto}
              setError={setError}
              toolSpec={toolSpec}
              toolSpecError={toolSpecError}
            />
          </div>
        );
      }),
    [
      changeLayout,
      drillDownSteps,
      impactedWidgets,
      isToolSpecError,
      isToolSpecFetching,
      onClone,
      onDelete,
      onUpdateStep,
      pickerContextDto,
      setError,
      toolSpec,
      toolSpecError
    ]
  );

  const onItemOrderChanged = useCallback(
    (layout: Layout[]) => {
      const sortedLayout = sortBy(layout, "y");

      // Rearrange the items array to match the sorted layout
      const rearrangedItems = sortedLayout
        .map(layoutItem => {
          const matchingItem = drillDownSteps.find(item => item.uiKey === layoutItem.i);
          return matchingItem || null;
        })
        .filter(Boolean) as any[]; // Filter out any potential null values
      if (!isEqual(drillDownSteps, rearrangedItems)) {
        onChangeSteps(rearrangedItems);
      }

      setLayouts(prev => {
        const idArray = prev.map(e => e.i);
        const newIdArray = sortedLayout.map(e => e.i);
        if (!isEqual(idArray, newIdArray)) {
          return sortedLayout;
        }
        return prev;
      });
    },
    [drillDownSteps, onChangeSteps]
  );

  const { close: closeOverrideModel, isOpen: isOverrideModalOpen, open: openOverrideModal } = useToggleState();

  const onOpenOverrideModal = useCallback(() => {
    if (drillDownSteps.length) {
      openOverrideModal();
    } else {
      onOverride();
    }
  }, [drillDownSteps.length, onOverride, openOverrideModal]);

  const onConfirmOverride = useCallback(() => {
    onOverride();
    closeOverrideModel();
  }, [closeOverrideModel, onOverride]);

  return (
    <div className="inc-flex-column width-100 flex-gap-12">
      <VerticallyCenteredRow className="width-100 flex-gap-12 inc-flex-end">
        {children}
        {!!drillDownSteps.length && (
          <IncButton
            color="primary"
            disabled={disableAdd}
            iconName="add"
            onClick={onAddStep}
          >
            Add New Drilldown Step
          </IncButton>
        )}
        {shouldShowOverrideWorkflow && (
          <>
            <IncButton
              color="secondary-blue"
              disabled={isOverridingInProgress}
              loading={isOverridingInProgress}
              onClick={onOpenOverrideModal}
            >
              Override From Impact Contributors
            </IncButton>
            <IncPopConfirm
              buttonProps={{
                okButton: {
                  text: "Confirm"
                },
                cancelButton: {
                  text: "Cancel"
                }
              }}
              color="secondary-blue"
              onCancel={closeOverrideModel}
              onConfirm={onConfirmOverride}
              open={isOverrideModalOpen}
              placement="bottomRight"
              showCancel
              title="Confirm Override?"
            />
          </>
        )}
      </VerticallyCenteredRow>
      {!drillDownSteps.length && (
        <div className="inc-flex-column inc-flex-center flex-gap-12">
          <IncSmartText text="No Steps Available" />
          <IncButton
            color="primary"
            disabled={disableAdd}
            iconName="add"
            onClick={onAddStep}
          >
            Add New Drilldown Step
          </IncButton>
        </div>
      )}

      <div
        className="inc-flex-column width-100 paddingRt8 flex-gap-12"
        style={wrapperStyle}
      >
        <ResponsiveGridLayout
          autoSize
          className={bClassName}
          cols={gridColumns}
          compactType="vertical"
          containerPadding={containerPadding}
          draggableHandle=".list-item-handle"
          isDraggable={true}
          isResizable={false}
          layouts={layout}
          onLayoutChange={onItemOrderChanged}
          rowHeight={baseChildHeight}
          useCSSTransforms={false}
        >
          {drillDownStepListJsx}
        </ResponsiveGridLayout>
      </div>
    </div>
  );
};

const gridColumns = {
  lg: 1,
  md: 1,
  sm: 1,
  xs: 1,
  xxs: 1
};

const containerPadding: [number, number] = [0, 0];

const bClassName = css`
  height: 350px;
`;

export default DrillDownStepList;

const wrapperStyle = {
  maxHeight: "calc(100vh - 250px)",
  overflow: "auto"
};
