import React, { FC } from "react";
import { ActionTemplateElementProp, TemplateCanvasSection } from "../../../../../../services/api/operationalise";
import { SectionPropertiesEditor } from "../../section-properties";

interface Props {
  section: TemplateCanvasSection;
  properties: ActionTemplateElementProp[];
  onChange: (section: TemplateCanvasSection) => void;
}

export const ToolbarPropertiesRenderer: FC<Props> = props => {
  const { section, properties, onChange } = props;

  return (
    <SectionPropertiesEditor
      onChange={onChange}
      properties={properties}
      section={section}
      skipTitle
    />
  );
};
