import React, { FC, MouseEvent, useState, useCallback } from "react";
import { IncFaIcon, IncMenu, IncMenuItemProps, IncMenuToggleProps } from "@inception/ui";
import { FormattedMessage } from "react-intl";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import { BaseWidgetProps } from "../types";
import { useTimeRange, useInceptionRoute } from "../../../core";
import featureFlagService from "../../../services/feature-flags/service/FeatureFlagService";
import { VerticallyCenteredRow } from "../../../components";

interface Props extends BaseWidgetProps {}

const WidgetActions: FC<Props> = props => {
  const noOp = () => {};

  const { widget, onClone, onDelete, onEdit, edit, children } = props;

  const [showDeleteConfirmation, toggleDeleteConfirmation] = useState<boolean>(false);
  const {
    timeRange: {
      raw: { from, to }
    }
  } = useTimeRange();
  const { navigate } = useInceptionRoute();

  const { mqId } = widget.options;
  const shouldShowMQLink = mqId && featureFlagService.isDebugMode();
  const customActionItems = React.Children.toArray(children);

  const onWidgetClone = onClone || noOp;
  const onWidgetDelete = onDelete || noOp;

  const shouldShowEdit = Boolean(onEdit);
  const onEditClick = useCallback(() => onEdit && onEdit(widget), [onEdit, widget]);

  const onCloseDeleteModal = useCallback(() => {
    toggleDeleteConfirmation(false);
  }, []);

  const onDeleteConfirmation = useCallback(() => {
    onWidgetDelete();
  }, [onWidgetDelete]);

  const actionItems = useCallback(
    (itemProps: IncMenuItemProps) => {
      const { className, closeMenu } = itemProps;

      const onDeleteClick = (e: MouseEvent) => {
        toggleDeleteConfirmation(true);
        closeMenu(e.nativeEvent);
      };

      const onCloneClick = (e: MouseEvent) => {
        onWidgetClone();
        closeMenu(e.nativeEvent);
      };

      const onNavToMqClick = () => {
        const url = `/apptuit/monitoring/${mqId}/mts?from=${from}&to=${to}`;
        navigate(url, { newTab: true });
      };

      const widgetActionClassName = `widget-action ${className}`;

      const onActionClick = (e: MouseEvent) => {
        const { nativeEvent } = e;
        const { target } = nativeEvent;
        const el = target as HTMLElement;
        // Trigger click event on children if the click is triggered on wrapper element
        if (el.className === widgetActionClassName) {
          el.childNodes.forEach(elChild => {
            const elDiv = elChild as HTMLDivElement;
            elDiv.click && elDiv.click();
          });
        }
        closeMenu(e.nativeEvent);
      };

      return (
        <>
          {customActionItems.map((child, i) => {
            const key = `widget-${widget.id}-custom-action-${i}`;
            return (
              <div
                className={widgetActionClassName}
                key={key}
                onClick={onActionClick}
              >
                {child}
              </div>
            );
          })}

          {edit && (
            <>
              {shouldShowEdit && (
                <div
                  className={`${widgetActionClassName} widget-action-edit flex-gap-0`}
                  onClick={onEditClick}
                >
                  <IncFaIcon iconName="edit" />
                  <FormattedMessage id="common.actions.edit" />
                </div>
              )}

              <div
                className={`${widgetActionClassName} widget-action-clone flex-gap-0`}
                onClick={onCloneClick}
              >
                <IncFaIcon iconName="clone" />
                <FormattedMessage id="common.actions.clone" />
              </div>

              <div
                className={`${widgetActionClassName} widget-action-remove flex-gap-0`}
                onClick={onDeleteClick}
              >
                <IncFaIcon iconName="trash-can" />
                <FormattedMessage id="common.actions.delete" />
              </div>
            </>
          )}

          {shouldShowMQLink && (
            <div
              className={`${widgetActionClassName} widget-action-mq-link flex-gap-0`}
              onClick={onNavToMqClick}
            >
              <IncFaIcon iconName="external-link" />
              <FormattedMessage id="dashboards.widget.actions.view.mq" />
            </div>
          )}
        </>
      );
    },
    [
      customActionItems,
      edit,
      from,
      mqId,
      navigate,
      onEditClick,
      onWidgetClone,
      shouldShowEdit,
      shouldShowMQLink,
      to,
      widget
    ]
  );

  const actionsToggle = useCallback((toggleProps: IncMenuToggleProps) => {
    const { show, toggleMenu } = toggleProps;

    const onClick = () => toggleMenu(!show);

    return (
      <VerticallyCenteredRow onClick={onClick}>
        <IncFaIcon
          iconName="ellipsis-v"
          style={{ fontSize: 18 }}
        />
      </VerticallyCenteredRow>
    );
  }, []);

  const hasAtLeastOneActionItem = edit || shouldShowMQLink || customActionItems?.length > 0;
  return hasAtLeastOneActionItem ? (
    <>
      <IncMenu
        className="widget-actions"
        defaultFocus
        items={actionItems}
        toggle={actionsToggle}
      />
      <ConfirmationModal
        bodyStringId={"dashboard.widget.delete.confirmation.message"}
        confirmBtnStringId="common.actions.delete"
        headerStringId={"dashboard.widget.delete.confirmation.header"}
        onClose={onCloseDeleteModal}
        onConfirm={onDeleteConfirmation}
        open={showDeleteConfirmation}
      />
    </>
  ) : (
    <></>
  );
};

export default WidgetActions;
