import { isEqual } from "lodash";
import moment from "moment";
import { DashboardTimeRange } from "../../../platform/dashboard";
import TraceStructureModel from "../../../platform/models/TraceStructureModel";
import kbn from "../../../platform/services/datasources/core/kbn";
import { MISSING } from "../../../platform/utils";
import timeRangeUtils from "../../../platform/utils/TimeRangeUtils";
import { StructureTagWithOpValue } from "./types";

export const getEntityFallBack = (entityField: string): string => {
  if (entityField === "api") {
    return "operationName";
  }
  if (entityField === "service") {
    return "serviceName";
  }
};

export function getFilterForURL(filterKey: string, filterOp: string, filterValue: string) {
  return `((${filterKey})(${filterOp})(${filterValue}))`;
}

export function getFilterKeyFromNode(filter: StructureTagWithOpValue) {
  let filterKey = filter.tagWithPrefix;
  if (filter.entityType) {
    filterKey = filter.configType ? `${filterKey}$${filter.tagKey}` : filterKey;
    if (!(filter.displayName || filter.displayNames)) {
      filterKey = `${filterKey}.__id__`;
    }
  }
  return filterKey;
}
export function getFilterValueForURL(value: string | string[]): string {
  if (Array.isArray(value)) {
    return `["${value.join('","')}"]`;
  } else {
    return value ? `"${value}"` : null;
  }
}
export function getFilterValueFromNode(filter: StructureTagWithOpValue) {
  const nodeValue = filter.displayName || filter.displayNames || filter.values || filter.value;
  if (nodeValue === MISSING) {
    return "null";
  }
  return getFilterValueForURL(nodeValue);
}

interface Filter {
  key: string;
  operator: string;
  value: string;
}

function parseFormattedFilter(filter: string) {
  const result: Filter = {
    key: "",
    operator: "",
    value: ""
  };
  if (filter) {
    // eslint-disable-next-line no-useless-escape
    const regExp = new RegExp(/\(([^\)]+)\)/g);
    const values = filter.slice(1, filter.length - 1).match(regExp);
    if (values?.length === 3) {
      result.key = values[0].substring(1, values[0].length - 1);
      result.operator = values[1].substring(1, values[1].length - 1);
      result.value = values[2].substring(1, values[2].length - 1);
    }
  }
  return result;
}

export function getTraceFilters(filter: string, traceStructureModel: TraceStructureModel) {
  if (filter) {
    const parsedFilter = parseFormattedFilter(filter);
    const filterOperator = parsedFilter.operator;
    const filterKey = parsedFilter.key;
    const filterTagKey = filterKey.replace("__id__", "");
    const filterValue = parsedFilter.value.replace(/[[\]"']+/g, "").split(",");
    const node = traceStructureModel.getTagByKey(filterTagKey);
    if (node) {
      const filterNode: StructureTagWithOpValue = {
        ...node,
        value: undefined,
        operator: filterOperator
      };
      if (filterKey.indexOf("__id__") !== -1 || !node.entityType) {
        if (filterOperator === "in") {
          filterNode.values = filterValue;
        } else {
          filterNode.value = filterValue[0] === "null" ? MISSING : filterValue[0];
        }
      } else if (filterOperator !== "in") {
        filterNode.displayName = filterValue[0];
      } else {
        filterNode.displayNames = filterValue;
      }
      return filterNode;
    }
  }
}

export function checkFilterTagOpExist(filters: StructureTagWithOpValue[], payload: StructureTagWithOpValue) {
  return filters.find(filter => {
    const {
      value: pValue,
      values: pValues,
      displayName: pDisplayName,
      displayNames: pDisplayNames,
      ...payloadStructureTag
    } = payload;
    const {
      value: fValue,
      values: fValues,
      displayName: fDisplayName,
      displayNames: fDisplayNames,
      ...filterStructureTag
    } = filter;
    if (isEqual(payloadStructureTag, filterStructureTag)) {
      return (
        fDisplayName === pDisplayName || fDisplayNames === pDisplayNames || fValue === pValue || fValues === pValues
      );
    }
    return false;
  });
}

export function getPeriodicitySecsForHistogramOverTime(timeRange: DashboardTimeRange) {
  const { from, to } = timeRange;
  const timeDiffInMinutes = moment.duration(to.valueOf() - from.valueOf()).asMinutes();
  let buckets = 0;
  if (timeDiffInMinutes < 5) {
    buckets = 10;
  }
  if (timeDiffInMinutes >= 5 && timeDiffInMinutes < 15) {
    buckets = 15;
  }
  if (timeDiffInMinutes >= 15 && timeDiffInMinutes < 30) {
    buckets = 20;
  } else {
    buckets = 30;
  }
  const intervalMillis = kbn.calculateInterval(timeRange, buckets, "30s").intervalMs;

  const periodicity = timeRangeUtils.getSecondsFromMillis(intervalMillis);
  return periodicity;
}
