import { clone, isEqual, sortBy } from "lodash";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { Layout, ResponsiveProps } from "react-grid-layout";
import { ActionType, DashboardWidgetActionHandler } from "../../BaseWidgetActions";
import DashboardImpl from "../../model-impl/DashboardImpl";
import VariableImpl from "../../model-impl/VariableImpl";
import BaseWidgetModel from "../../models/BaseWidgetModel";
import { TimeRange, useTenantConfig } from "../../../core";
import BaseWidgetImpl from "../../model-impl/BaseWidgetImpl";
import { WidgetExtProps, WidgetMaximizeProps } from "../../widgets/types";
import { DashboardPresetTimeInfo } from "../../models";

interface Props {
  dbImpl: DashboardImpl;
  onDashboardUpdate: (dbImpl: DashboardImpl) => void;
  onWidgetAction?: DashboardWidgetActionHandler;

  onWidgetClone?: (widget: BaseWidgetImpl, defaultCallback: () => void) => void;
  onWidgetDelete?: (widget: BaseWidgetImpl, defaultCallback: () => void) => void;
  onWidgetMaximize?: (widget: BaseWidgetImpl, defaultCallback: () => void) => void;
  onWidgetMinimize?: (widget: BaseWidgetImpl, defaultCallback: () => void) => void;
}

export const useDashboardState = (props: Props) => {
  const {
    onDashboardUpdate: usrOnDashboardUpdate,
    onWidgetAction,
    dbImpl: usrDbImpl,
    onWidgetClone: pOnWidgetClone,
    onWidgetDelete: pOnWidgetDelete,
    onWidgetMaximize: pOnWidgetMaximize,
    onWidgetMinimize: pOnWidgetMinimize
  } = props;

  const { tenantConfigState } = useTenantConfig();

  const [dbImpl, dbDispatch] = useReducer(DashboardImplReducer, usrDbImpl);
  const [maximizeWidgetProps, setMaximizeWidgetProps] = useState<WidgetMaximizeProps>(null);

  const previousMode = useRef(dbImpl?.meta?.edit ? "edit" : "view");

  useEffect(() => {
    previousMode.current = dbImpl?.meta?.edit ? "edit" : "view";
  }, [dbImpl]);

  useEffect(() => {
    dbDispatch({
      type: "init",
      data: { dbImpl: usrDbImpl }
    });
  }, [usrDbImpl]);

  const onDashboardUpdate = useCallback(() => {
    usrOnDashboardUpdate(dbImpl);
  }, [dbImpl, usrOnDashboardUpdate]);

  const refreshDashboard = useCallback(() => {
    dbDispatch({
      type: "refresh",
      data: null
    });
  }, []);

  const onWidgetUpdate = useCallback(() => {
    dbDispatch({
      type: "refresh",
      data: null
    });
    onDashboardUpdate();
  }, [onDashboardUpdate]);

  const defOnWidgetClone = useCallback(
    (widgetId: string) => {
      dbDispatch({
        type: "wClone",
        data: { widgetId }
      });
      onDashboardUpdate();
    },
    [onDashboardUpdate]
  );

  const defOnWidgetDelete = useCallback(
    (widgetId: string) => {
      dbDispatch({
        type: "wDelete",
        data: { widgetId }
      });
      onDashboardUpdate();
    },
    [onDashboardUpdate]
  );

  const defOnWidgetMinimize = useCallback(() => setMaximizeWidgetProps(null), []);

  const onWidgetMinimize = useCallback(
    (widgetImpl: BaseWidgetImpl) => {
      if (pOnWidgetMinimize) {
        const defaultCallback = () => defOnWidgetMinimize();
        pOnWidgetMinimize(widgetImpl, defaultCallback);
      } else {
        defOnWidgetMinimize();
      }
    },
    [defOnWidgetMinimize, pOnWidgetMinimize]
  );

  const defOnWidgetMaximize = useCallback(
    (maximizeProps: WidgetExtProps, addlContext: Record<string, any>) => {
      setMaximizeWidgetProps({
        ...maximizeProps,
        onMinimize: () => {
          onWidgetMinimize(maximizeProps.widget);
        },
        addlContext
      });
    },
    [onWidgetMinimize]
  );

  const onWidgetDelete = useCallback(
    (widgetId: string) => {
      if (pOnWidgetDelete) {
        const defaultCallback = () => defOnWidgetDelete(widgetId);
        const widgetImpl = dbImpl.getWidgetById(widgetId);

        pOnWidgetDelete(widgetImpl, defaultCallback);
      } else {
        defOnWidgetDelete(widgetId);
      }
    },
    [dbImpl, defOnWidgetDelete, pOnWidgetDelete]
  );

  const onWidgetMaximize = useCallback(
    (maximizeProps: WidgetExtProps, addlContext: Record<string, any>) => {
      if (pOnWidgetMaximize) {
        const defaultCallback = () => defOnWidgetMaximize(maximizeProps, addlContext);
        pOnWidgetMaximize(maximizeProps.widget, defaultCallback);
      } else {
        defOnWidgetMaximize(maximizeProps, addlContext);
      }
    },
    [defOnWidgetMaximize, pOnWidgetMaximize]
  );

  const onWidgetClone = useCallback(
    (widgetId: string) => {
      if (pOnWidgetClone) {
        const defaultCallback = () => defOnWidgetClone(widgetId);
        const widgetImpl = dbImpl.getWidgetById(widgetId);

        pOnWidgetClone(widgetImpl, defaultCallback);
      } else {
        defOnWidgetClone(widgetId);
      }
    },
    [dbImpl, defOnWidgetClone, pOnWidgetClone]
  );

  const onWidgetTypeUpdate = useCallback((widgetId: string, newVizType: string, newModel?: BaseWidgetModel) => {
    dbDispatch({
      type: "wTypeChanged",
      data: {
        widgetId,
        newVizType,
        newModel
      }
    });
  }, []);

  // const onVariablesSelectionPanelLoaded = () => {
  //     setLoadedVariablesPanel(true);
  // };

  const onLayoutChanged = useCallback(
    (layout: Layout[], allLayouts: ResponsiveProps["layouts"]) => {
      const enableDashboardFilterPanel = !tenantConfigState.disableNewDashboardFilterPanel;

      if (enableDashboardFilterPanel) {
        const sortedDbLayout = sortBy(dbImpl?.layout || [], l => l.i);
        const sortedUpdatedLayout = sortBy(allLayouts?.lg || [], l => l.i);
        if (previousMode.current !== "view" && dbImpl?.meta?.edit && !isEqual(sortedDbLayout, sortedUpdatedLayout)) {
          dbDispatch({
            type: "dLayoutChanged",
            data: {
              layout: allLayouts.lg
            }
          });
          onDashboardUpdate();
        }
      } else {
        dbDispatch({
          type: "dLayoutChanged",
          data: {
            layout: allLayouts.lg
          }
        });
        onDashboardUpdate();
      }
    },
    [dbImpl?.layout, dbImpl?.meta?.edit, onDashboardUpdate, tenantConfigState.disableNewDashboardFilterPanel]
  );

  const onWidgetLockToggle = useCallback((widgetId: string) => {
    dbDispatch({
      type: "wLockToggle",
      data: { widgetId }
    });
  }, []);

  const refreshAndUpdateDashboard = useCallback(
    (saveDashboard?: boolean) => {
      dbDispatch({
        type: "refresh",
        data: null
      });
      if (saveDashboard) {
        onDashboardUpdate();
      }
    },
    [onDashboardUpdate]
  );

  const onDashboardWidgetAction = useCallback(
    (action: ActionType, id: string) => {
      if (onWidgetAction) {
        onWidgetAction(action, id);
      }
    },
    [onWidgetAction]
  );

  const updateDashboardVariables = useCallback((newVars: VariableImpl[]) => {
    // We want to silently update dashboard variables without changing the state, since new variable srv triggers a state change
    dbDispatch({
      type: "vUpdate",
      data: { variables: newVars }
    });
  }, []);

  const updateDashboardTime = useCallback((timeRange: TimeRange, compareTimeRange: TimeRange) => {
    dbDispatch({
      type: "timeStateUpdate",
      data: {
        timeRange,
        compareTimeRange
      }
    });
  }, []);

  const updateDashboardTimeInfo = useCallback((timeInfo: DashboardPresetTimeInfo) => {
    dbDispatch({
      type: "updatePresetTimeInfo",
      data: {
        timeInfo
      }
    });
  }, []);

  return {
    dbImpl,
    maximizeWidgetProps,
    onWidgetClone,
    onWidgetDelete,
    onWidgetMaximize,
    onWidgetLockToggle,
    onWidgetTypeUpdate,
    onWidgetUpdate,
    onDashboardWidgetAction,
    refreshAndUpdateDashboard,
    refreshDashboard,
    onLayoutChanged,
    updateDashboardVariables,
    onDashboardUpdate,
    updateDashboardTime,
    updateDashboardTimeInfo
  };
};

type DbActionType =
  | "wDelete"
  | "wClone"
  | "wResize"
  | "wLockToggle"
  | "wTypeChanged"
  | "dResized"
  | "dLayoutChanged"
  | "vUpdate"
  | "timeStateUpdate"
  | "refresh"
  | "init"
  | "updatePresetTimeInfo"
  | "wMaximize";

interface DashboardAction {
  type: DbActionType;
  data: Record<string, any>;
}

const DashboardImplReducer = (state: DashboardImpl, action: DashboardAction): DashboardImpl => {
  const { type, data = {} } = action;
  switch (type) {
    case "init":
      return data.dbImpl;

    case "wDelete": {
      const { widgetId } = data;
      state.removeWidget(widgetId);
      break;
    }

    case "updatePresetTimeInfo": {
      const { timeInfo } = data;
      state.setTimeInfo(timeInfo);
      break;
    }

    case "wClone": {
      const { widgetId } = data;
      state.cloneWidget(widgetId);
      break;
    }

    case "wLockToggle": {
      const { widgetId } = data;
      state.toggleWidgetLock(widgetId);
      break;
    }

    case "wTypeChanged": {
      const { widgetId, newVizType, newModel } = data;
      state.updateWidgetType(widgetId, newVizType, newModel);
      break;
    }

    case "dLayoutChanged": {
      const { layout } = data;
      state.updateLayout(layout);
      break;
    }

    case "vUpdate": {
      const { variables } = data;
      state.variables = variables;
      break;
    }

    case "wMaximize": {
      const { widgetId } = data;
      state.toggleMaximizeWidget(widgetId);
      break;
    }

    case "timeStateUpdate": {
      const { timeRange, compareTimeRange } = data;
      state.timeRange = timeRange;
      state.compareTimeRange = compareTimeRange;
      break;
    }

    default:
      break;
  }
  return clone(state);
};
