import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ISaxIcon, IncButton, IncCheckbox, IncFaIcon, IncSmartText, IncToolTip } from "@inception/ui";
import { isEqual, unionBy } from "lodash";
import {
  BizIdProps,
  Op10zeStage,
  OpAnalysisCreationConfig,
  OpCreationConfig,
  OpMetaDataV2
} from "../../services/api/operationalise";
import { JSonConfigEditorModel, VerticallyCenteredRow } from "../../components";
import { featureFlagService } from "../../services/feature-flags";
import { useToggleState } from "../../core";
import { NameAndDescriptionModal } from "../v3/editors";
import { UserServiceTuple } from "../../services/api/explore";
import {
  getDefaultAnalysisConfig,
  setAnalysisConfig,
  setDryRun,
  useOpAnalysisStore,
  validateOpAnalysisConfig
} from "./context";

type Props = {
  onSave?: (config: OpCreationConfig, dryRun: boolean, callBack?: () => void) => void;
  onStateChange?: (config: OpAnalysisCreationConfig) => void;
  showSave?: boolean;
  isSaveInProgress?: boolean;
  analysisConfig: OpAnalysisCreationConfig;
  hideDryRun?: boolean;
  hideConfigView?: boolean;
  hideTitle?: boolean;
};

const AnalysisOperationalizeHeader: FC<Props> = props => {
  const {
    onSave: pOnSave,
    onStateChange,
    showSave = false,
    isSaveInProgress = false,
    analysisConfig: defConfig,
    hideDryRun = false,
    hideConfigView = false,
    hideTitle = false
  } = props;
  const { state, dispatch } = useOpAnalysisStore();
  const { opAnalysisImpl, dryRun } = state;
  const opAnalysisConfig = useMemo(() => opAnalysisImpl.getConfig(), [opAnalysisImpl]);
  const errors = useMemo(() => validateOpAnalysisConfig(opAnalysisConfig), [opAnalysisConfig]);
  const [opCreationConfig, setOpCreationConfig] = useState<OpCreationConfig>(null);
  const { name = "", description = "" } = opCreationConfig || {};
  const {
    isOpen: isNameAndDescriptionModalOpen,
    open: openNameAndDescriptionModal,
    close: closeNameAndDescriptionModal
  } = useToggleState();
  const onSaveNameAndDescription = useCallback(
    (opMeta: OpMetaDataV2) => {
      const { name, description } = opMeta;
      setOpCreationConfig(prev => ({
        ...prev,
        name,
        description
      }));
      closeNameAndDescriptionModal();
    },
    [closeNameAndDescriptionModal]
  );
  const { isOpen, close, open } = useToggleState();
  useEffect(() => {
    const opAnalysisKpis = Object.values(opAnalysisConfig?.kpis || {});
    const userServiceInfo: UserServiceTuple[] = [];
    opAnalysisKpis.forEach(kpi => {
      const { bizDataQuery } = kpi;
      const { widgetConfig } = bizDataQuery || {};
      const { userServiceEntityId } = widgetConfig || {};
      if (userServiceEntityId) {
        userServiceInfo.push({
          userServiceEntityId
        });
      }
    });

    const idProps: BizIdProps = {
      primary: {
        eventTypes: {
          userServiceInfo: unionBy(userServiceInfo, usrInfo => usrInfo.userServiceEntityId)
        }
      },
      secondary: {}
    };

    const config: OpCreationConfig = {
      bizActions: {},
      createdBy: null,
      description: "",
      name: "",
      idProps,
      lastUpdatedBy: null,
      opCreationConfigDef: null,
      opCreationConfigExpr: null,
      opAnalysisConfig,
      runBooks: [],
      stage: Op10zeStage.draft
    };
    setOpCreationConfig(prev => {
      if (!prev) {
        return config;
      }
      if (!isEqual(prev?.opAnalysisConfig, opAnalysisConfig)) {
        return {
          ...prev,
          idProps,
          opAnalysisConfig
        };
      } else {
        return prev;
      }
    });
  }, [opAnalysisConfig]);

  const onSave = useCallback(() => {
    if (pOnSave) {
      pOnSave(opCreationConfig, dryRun, () => {
        dispatch(setAnalysisConfig(getDefaultAnalysisConfig()));
      });
    }
  }, [pOnSave, opCreationConfig, dryRun, dispatch]);

  const onSaveConfig = useCallback(
    (opConfig: OpCreationConfig) => {
      const { opAnalysisConfig } = opConfig || {};
      if (opAnalysisConfig) {
        setOpCreationConfig(opConfig);
        dispatch(setAnalysisConfig(opAnalysisConfig));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (onStateChange) {
      onStateChange(opAnalysisConfig);
    }
  }, [onStateChange, opAnalysisConfig]);
  useEffect(() => {
    dispatch(setAnalysisConfig(defConfig));
  }, [defConfig, dispatch]);
  const onChangeDryRun = useCallback(
    (_: any, c: boolean) => {
      dispatch(setDryRun(c));
    },
    [dispatch]
  );
  const isDebugMode = featureFlagService.isDebugMode();
  const renderConfigModal = isDebugMode && !hideConfigView;
  const isValid = !Object.keys(errors).length && !!name;

  const errorElement = useMemo(() => {
    const jsxList: JSX.Element[] = [];
    if (!name) {
      const jsx = <span className="inc-flex-row">OpCreationConfig: OpConfig Name is required</span>;
      jsxList.push(jsx);
    }
    Object.keys(errors).forEach((title, key) => {
      const jsx = (
        <span
          className="inc-flex-row"
          key={key}
        >
          {title}: {errors[title]}
        </span>
      );
      jsxList.push(jsx);
    });
    if (jsxList.length) {
      return <div className="padding8">{jsxList}</div>;
    }
    return <></>;
  }, [errors, name]);

  const isShowVisible = Boolean(showSave && pOnSave);
  const isDryRunVisible = isDebugMode && !hideDryRun;
  const defMeta = useMemo<OpMetaDataV2>(
    () => ({
      name,
      description,
      actionsContext: "",
      causes: [],
      icon: null,
      importance: "",
      isOpportunity: false,
      labelFragment: [],
      opConfigProps: {}
    }),
    [description, name]
  );

  return (
    <VerticallyCenteredRow className="width-100 inc-flex-end flex-gap-12 paddingLt16 paddingRt16">
      {
        <NameAndDescriptionModal
          defMetaData={defMeta}
          hideCauses
          hideIcons
          hideImportance
          hideLabels
          hideOpportunity
          onClose={closeNameAndDescriptionModal}
          onSaveOrUpdate={onSaveNameAndDescription}
          open={isNameAndDescriptionModalOpen}
          saveOrUpdateInProgress={false}
        />
      }
      {isOpen && (
        <JSonConfigEditorModel<OpCreationConfig>
          isOpen
          jsonValue={opCreationConfig}
          onClose={close}
          onSave={onSaveConfig}
        />
      )}
      {!hideTitle && (
        <VerticallyCenteredRow className="marginRtAuto flex-gap-12">
          <IncSmartText
            className="inc-text-header-medium"
            text={name || "Operationalise"}
          />
          <IncToolTip titleText="Edit">
            <ISaxIcon
              className="status-info inc-cursor-pointer"
              iconName="Edit"
              onClick={openNameAndDescriptionModal}
              variant="Outline"
            />
          </IncToolTip>
        </VerticallyCenteredRow>
      )}
      {isDryRunVisible && (
        <IncCheckbox
          checked={dryRun}
          label={"Dry Run"}
          labelProps={{
            placement: "end"
          }}
          onChange={onChangeDryRun}
        />
      )}
      {renderConfigModal && (
        <IncButton
          color={"link"}
          onClick={open}
        >
          View Configuration
        </IncButton>
      )}
      {isShowVisible && (
        <>
          {!isValid && (
            <IncToolTip
              placement="bottom"
              showArrow
              titleElement={errorElement}
              variant="error"
            >
              <IncFaIcon
                className="status-danger inc-cursor-pointer"
                iconName="warning"
              />
            </IncToolTip>
          )}
          <IncButton
            color={"primary"}
            disabled={!isValid}
            iconName={"save"}
            loading={isSaveInProgress}
            onClick={onSave}
          >
            Save Config
          </IncButton>
        </>
      )}
    </VerticallyCenteredRow>
  );
};

export default AnalysisOperationalizeHeader;
