import React, { FC, useEffect, useState, Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { IncSelect } from "@inception/ui";
import { css } from "emotion";
import { VerticallyCenteredRow, LoadingSpinner } from "../../components";
import { getDefaultWidgetConfigDto, getDtoFromWidgetConfig, getWidgetConfigFromDto } from "../../utils/ExploreUtils";
import { getDefaultMetricsForWidgetConfig, getWidgetConfigForMetrics } from "../../biz-entity/AddMetric/utils";
import { MetricsWithEventTypeAndId } from "../../biz-entity/AddMetric/types";
import { AddMetricRenderer } from "../../biz-entity/AddMetric/renderers/AddMetricRenderer";
import { useTimeRange } from "../../core";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { useFetchOptions, EntityTypeOption } from "../hooks/useFetchOptions";
import { UIBizFlowNode } from "../types";
import { getSliceSpec } from "../utils";
import { UserServiceFieldSliceSet, WidgetConfigUtils } from "../../services/api/explore";
import { USERSERVICE_TAG } from "../../utils";

interface Props {
  node: UIBizFlowNode;
  setNode: Dispatch<SetStateAction<UIBizFlowNode>>;
  setError: (error: string) => void;

  hideEntityTypeSelector?: boolean;
  disableSliceSelection?: boolean;
  defaultSliceSet?: UserServiceFieldSliceSet;
}

export const DerivedNodeEditor: FC<Props> = props => {
  const {
    node,
    setNode,
    setError,
    hideEntityTypeSelector = false,
    disableSliceSelection = false,
    defaultSliceSet
  } = props;

  const { timeRange } = useTimeRange();

  const getLatestTimeRange = useCallback(() => {
    const latestTr = timeRangeUtils.getTimeRangeFromRaw(timeRange.raw);
    return latestTr;
  }, [timeRange.raw]);

  const { idProps, widgetConfig, buildingBlockConfig, labels } = node?.bizDataQuery || {};

  const { entityTypeId: idPropsEntityTypeId, eventTypeId: idPropsEventTypeId } =
    WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(idProps);

  const { entityTypeId: bbIdPropsEntityTypeId, eventTypeId: bbIdPropsEventTypeId } =
    WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(buildingBlockConfig?.bizIdProps);

  const preSelEventTypeId =
    idPropsEventTypeId || widgetConfig?.userServiceEntityId || bbIdPropsEventTypeId || labels?.eventTypeId;
  const preSelEntityTypeId = preSelEventTypeId
    ? null
    : idPropsEntityTypeId || widgetConfig?.bizEntityType || bbIdPropsEntityTypeId || labels?.entityTypeId;

  const isEventFirstMetric = !preSelEntityTypeId && Boolean(preSelEventTypeId);
  const defSelEntityTypeOpt: EntityTypeOption = isEventFirstMetric
    ? {
        label: "",
        value: USERSERVICE_TAG,
        data: {
          count: 0,
          entityTypeId: USERSERVICE_TAG,
          metadata: null,
          name: ""
        }
      }
    : null;
  const [selectedEntityTypeOpt, setSelectedEntityTypeOpt] = useState<EntityTypeOption>(defSelEntityTypeOpt);

  const selectedEntityType = selectedEntityTypeOpt?.data;
  const selectedEntityTypeId = selectedEntityType?.entityTypeId;
  const selectedEntityTypeName = selectedEntityType?.name;

  const getDefaultWidgetConfig = useCallback(
    () =>
      getDefaultWidgetConfigDto(
        isEventFirstMetric ? null : preSelEntityTypeId || selectedEntityTypeId,
        isEventFirstMetric ? preSelEventTypeId : null,
        null,
        selectedEntityTypeName,
        null
      ),
    [isEventFirstMetric, preSelEntityTypeId, preSelEventTypeId, selectedEntityTypeId, selectedEntityTypeName]
  );

  const {
    expression: dExpression,
    metricsWithEventTypeAndId: dMetricsWithEventTypeAndId,
    exprMetricId,
    metricUserServiceFilters
  } = useMemo(() => {
    const { bizDataQuery } = node || {};
    const { widgetConfig, buildingBlockConfig } = bizDataQuery || {};

    const defWidgetConfig = widgetConfig
      ? getDtoFromWidgetConfig(widgetConfig)
      : buildingBlockConfig
        ? WidgetConfigUtils.getWidgetConfigDtoFromBuildingBlockConfig(buildingBlockConfig)
        : getDefaultWidgetConfig();

    if (defWidgetConfig?.bizEntityType && defWidgetConfig?.userServiceEntityId) {
      delete defWidgetConfig.userServiceEntityId;
    }

    const result = getDefaultMetricsForWidgetConfig(defWidgetConfig, null, true);
    return {
      ...result,
      metricUserServiceFilters: defWidgetConfig.metricUserServiceFilters || {}
    };
  }, [getDefaultWidgetConfig, node]);

  const [metricsWithEventTypeAndId, setMetricsWithEventTypeAndId] =
    useState<MetricsWithEventTypeAndId>(dMetricsWithEventTypeAndId);
  const [expression, setExpression] = useState<string>(dExpression);

  const { entityTypeOptions, entityTypesFetching, eventTypeOptions, eventTypesFetching } =
    useFetchOptions(selectedEntityType);

  useEffect(() => {
    if (entityTypeOptions?.length && !selectedEntityTypeId) {
      const selEntityTypeOpt =
        entityTypeOptions.find(opt => opt.data.entityTypeId === preSelEntityTypeId) || entityTypeOptions[0];
      setSelectedEntityTypeOpt(selEntityTypeOpt);
    }
  }, [entityTypeOptions, preSelEntityTypeId, selectedEntityTypeId]);

  const eventTypes = useMemo(() => (eventTypeOptions || []).map(({ data }) => data), [eventTypeOptions]);

  useEffect(() => {
    if (metricsWithEventTypeAndId && selectedEntityTypeId) {
      const { widgetConfigDto, isValid, message, vizMetricId } = getWidgetConfigForMetrics(
        metricsWithEventTypeAndId,
        expression,
        node.label,
        selectedEntityTypeId,
        null,
        false,
        true,
        isEventFirstMetric,
        exprMetricId
      );

      const widgetConfig = getWidgetConfigFromDto(widgetConfigDto);
      widgetConfig.metricUserServiceFilters = metricUserServiceFilters;

      if (isValid) {
        setNode(prev => ({
          ...prev,
          bizDataQuery: {
            widgetConfig,
            sliceSpec: getSliceSpec(widgetConfigDto, vizMetricId),
            labels: {
              entityTypeId: widgetConfigDto.bizEntityType,
              eventTypeId: widgetConfigDto.userServiceEntityId,
              name: node.label
            },
            idProps: {
              primary: {
                bizEntityTypeId: widgetConfigDto.bizEntityType || "",
                eventTypes: widgetConfigDto.userServiceEntityId
                  ? {
                      userServiceInfo: [
                        {
                          userServiceEntityId: widgetConfigDto.userServiceEntityId
                        }
                      ]
                    }
                  : {
                      userServiceInfo: []
                    }
              },
              secondary: {}
            }
          }
        }));
        setError("");
      } else {
        setError(message);
      }
    }
  }, [
    exprMetricId,
    expression,
    isEventFirstMetric,
    metricUserServiceFilters,
    metricsWithEventTypeAndId,
    node.label,
    selectedEntityTypeId,
    setError,
    setNode
  ]);

  return (
    <div className="ui-biz-flow--derived-node-editor">
      {!hideEntityTypeSelector && !isEventFirstMetric && (
        <VerticallyCenteredRow className="marginBt12">
          <IncSelect
            isLoading={entityTypesFetching}
            label="Entity Type"
            onChange={setSelectedEntityTypeOpt}
            options={entityTypeOptions}
            value={selectedEntityTypeOpt}
            wrapperClass={wrapperClass}
          />
        </VerticallyCenteredRow>
      )}

      {Boolean(selectedEntityTypeId) && (
        <>
          {eventTypesFetching && <LoadingSpinner titleText="Fetching Event types..." />}
          {!eventTypesFetching && eventTypes?.length > 0 && (
            <AddMetricRenderer
              defaultSliceSet={defaultSliceSet}
              disableSliceSelection={disableSliceSelection}
              entityTypeId={selectedEntityTypeId}
              eventTypeInfos={eventTypes}
              expression={expression}
              getLatestTimeRange={getLatestTimeRange}
              metricsWithEventTypeAndId={metricsWithEventTypeAndId}
              onChange={setMetricsWithEventTypeAndId}
              onExpressionChange={setExpression}
            />
          )}
        </>
      )}
    </div>
  );
};

const wrapperClass = css`
  min-width: 200px;
  margin-right: 16px;
`;
