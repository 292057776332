import { FilterRendererProps, IncButton, IncFaIcon } from "@inception/ui";
import React, { FC, memo, useCallback } from "react";
import { ChartPoint } from "../../dashboard/widgets/TimeSeries/models/model";
import { NumericRangeSlider } from "../NumericRangeSlider";

type FProps = FilterRendererProps<ChartPoint> & {
  label: string;
  range: ChartPoint;
};

export const TableRangeFilterRenderer: FC<FProps> = memo(props => {
  const { filter, setFilter, label, range } = props;

  const updateLimit = useCallback(
    (limit: number | number[]) => {
      setFilter(limit as ChartPoint);
    },
    [setFilter]
  );

  const resetFilter = useCallback(
    (evt: React.MouseEvent) => {
      evt.stopPropagation();
      setFilter(null);
    },
    [setFilter]
  );

  return (
    <>
      <IncButton
        className="marginLtAuto marginBt16"
        color="link"
        onClick={resetFilter}
        size="small"
      >
        <IncFaIcon
          className="marginRt6"
          iconName="undo"
          size="sm"
        />
        Reset
      </IncButton>

      <NumericRangeSlider
        label={label}
        max={range[1]}
        min={range[0]}
        onChange={updateLimit}
        onlyRange
        operator="range"
        subType={null}
        value={filter || range}
      />
    </>
  );
});
