import React, { FC } from "react";
import { CatalogVizRendererProps } from "./common";
import { SingleStatRenderer } from "./SingleStatRenderer";

export const SingleStatWrapper: FC<CatalogVizRendererProps> = props => {
  const { properties } = props;
  const { sparkLine } = properties || {};
  return (
    <SingleStatRenderer
      {...props}
      metricId={sparkLine?.metricId}
    />
  );
};
