import { IncButton, IncClickAwayPopper, IncFaIcon, IncPill, IncSelectOption, IncToolTip } from "@inception/ui";
import { cx } from "emotion";
import { cloneDeep, isArray, forEach } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInceptionRoute, useRefState, useTenantConfig, useTimeRange, useTypedQueryParams } from "../../core";
import { VAR_URL_PREFIX } from "../../dashboard/models/VariableModel";
import {
  BizField,
  BizFieldInfo,
  BizFieldPredicate,
  EntityOperation,
  FieldPickerContextDTO,
  MetricPredicate,
  UserServiceFieldMetricConfigDefinition,
  UserServiceFieldWithMeta
} from "../../services/api/explore";
import { EntityAggregationSuggestion, EntityAggregationValue } from "../../services/api/types";
import {
  EventIdFieldName,
  FieldPickerUtils,
  getBizFieldsWithSuggestedAggregations,
  getOpFromPredicateOp,
  HighCardinalityEntityFieldNames,
  noOp,
  Options,
  pluralizeWord
} from "../../utils";
import { getFilterExprQueryParam, resolveFiltersFromQueryParams } from "../../utils/QueryParamUtils";
import { CustomSelect, InfoPopper } from "../CustomSelect";
import { QuickFiltersPicker } from "../filters/QuickFiltersPicker";
import { UsageEventFilter } from "./UsageEventsFilter";

interface Props {
  bizFieldPredicates: BizFieldPredicate[];
  metricPredicates?: MetricPredicate[];
  entityTypeId: string;
  onChange: (bizFieldPredicates: BizFieldPredicate[], metricPredicates?: MetricPredicate[]) => void;
  disabled?: boolean;
  className?: string;
  pillLimit?: number;
  eventTypeId?: string;
  showUsageEvents?: boolean;
  eventTypeList?: IncSelectOption[];
  cohortId?: string;
  label?: string;
  placeholder?: string;
  updateQueryParams?: boolean;
}

export const CohortBizFieldPicker: React.FC<Props> = (props: Props) => {
  const {
    entityTypeId,
    bizFieldPredicates: eBizFieldPredicates,
    metricPredicates: eMetricPredicates = [],
    onChange: onUpdateFilters,
    className,
    disabled = false,
    pillLimit = 2,
    showUsageEvents = false,
    eventTypeId: eEventTypeId,
    eventTypeList,
    cohortId,
    label = "Entity Criteria",
    placeholder = "Select",
    updateQueryParams = false
  } = props;

  const prevEntityTypeId = useRef<string>("");
  const [bizFields, setBizFields] = useState<BizFieldInfo[]>([]);
  const [userServiceFields, setUserServiceFields] = useState<UserServiceFieldWithMeta[]>([]);
  const [suggAggregationsMap, setSuggAggregationsMap] = useState<Record<string, EntityAggregationSuggestion>>({});
  const [aggregationsMap, setAggregationsMap] = useState<Record<string, EntityAggregationValue[]>>({});
  const [bizFieldPredicates, setBizFieldPredicates] = useState<BizFieldPredicate[]>([...eBizFieldPredicates]);
  const [metricPredicates, setMetricPredicates] = useState<MetricPredicate[]>([...eMetricPredicates]);
  const [loadingBizField, setLoadingBizFields] = useState(false);

  const { tenantConfigState } = useTenantConfig();
  const queryParams = useTypedQueryParams();
  const queryParamsRef = useRefState(queryParams);
  const { navigate } = useInceptionRoute();

  const options: Options = useMemo(() => ({ currency: tenantConfigState?.currency || "USD" }), [tenantConfigState]);
  const [showPopper, setShowPopper] = useState(false);
  const ref = useRef(null);
  const {
    timeRange: { from, to }
  } = useTimeRange();

  const eventTypeId = useMemo(() => {
    if (metricPredicates.length) {
      const usFieldMetric = metricPredicates?.[0]?.metric as UserServiceFieldMetricConfigDefinition;
      const eventType =
        usFieldMetric?.userServiceFieldMetricConfig?.userServiceField?.userServices?.[0]?.userServiceEntityId;
      return eventType;
    }
    return eEventTypeId;
  }, [eEventTypeId, metricPredicates]);

  const fieldPickerContext: FieldPickerContextDTO = useMemo(
    () => ({
      bizEntityType: entityTypeId,
      cohort: cohortId,
      showFields: true,
      entityId: eventTypeId,
      entityName: entityTypeId,
      entityType: entityTypeId
    }),
    [cohortId, entityTypeId, eventTypeId]
  );

  useEffect(() => {
    setBizFieldPredicates(cloneDeep(eBizFieldPredicates));
  }, [eBizFieldPredicates]);

  const openPopper = useCallback(() => setShowPopper(true), []);
  const closePopper = useCallback(() => setShowPopper(false), []);

  const containerClassName = cx("cohort-biz-fields-container", className);

  const getBizFields = useCallback(async () => {
    if (entityTypeId) {
      setLoadingBizFields(true);
      const { bizFields, userServiceFields, suggAggregationsMap, aggregationsMap } =
        await getBizFieldsWithSuggestedAggregations(cohortId, entityTypeId, from.valueOf(), to.valueOf());
      setBizFields(bizFields);
      setUserServiceFields(userServiceFields);
      setSuggAggregationsMap(suggAggregationsMap);
      setAggregationsMap(aggregationsMap);
      setLoadingBizFields(false);
      if (updateQueryParams) {
        const queryParams = queryParamsRef.current;
        const bizFieldPredicates = getBizFieldPredicatesFromQueryParams(
          queryParams,
          bizFields.map(x => x.bizField)
        );
        bizFieldPredicates.length && onUpdateFilters(bizFieldPredicates, []);
      }
    }
  }, [cohortId, entityTypeId, from, onUpdateFilters, queryParamsRef, to, updateQueryParams]);

  useEffect(() => {
    if ((!bizFields.length || prevEntityTypeId.current !== entityTypeId) && !disabled) {
      getBizFields();
      prevEntityTypeId.current = entityTypeId;
    }
  }, [bizFields, disabled, entityTypeId, getBizFields]);

  const applyFilterQueryParams = useCallback(
    (filterQueryParams: Record<string, string | string[]>) => {
      const otherParams: Record<string, string | string[]> = {};
      forEach(queryParams, (val, key) => {
        if (!key.startsWith(VAR_URL_PREFIX)) {
          otherParams[key] = val;
        }
      });
      const url = location.pathname;
      navigate(url, {
        queryParams: {
          ...otherParams,
          ...filterQueryParams
        }
      });
    },
    [navigate, queryParams]
  );

  const applyFilters = useCallback(() => {
    onUpdateFilters(bizFieldPredicates, metricPredicates);
    if (updateQueryParams) {
      const filterQueryParams = getQueryParamsFromBizFieldPredicates(bizFieldPredicates, VAR_URL_PREFIX);
      applyFilterQueryParams(filterQueryParams);
    }
    closePopper();
  }, [applyFilterQueryParams, bizFieldPredicates, closePopper, metricPredicates, onUpdateFilters, updateQueryParams]);

  const onCancel = useCallback(() => {
    setBizFieldPredicates(cloneDeep(eBizFieldPredicates));
    setMetricPredicates(eMetricPredicates);
    closePopper();
  }, [closePopper, eBizFieldPredicates, eMetricPredicates]);

  const onChange = useCallback(
    (fieldName: string, value: string | string[], operator: EntityOperation) => {
      let tBizFieldPredicates = [...bizFieldPredicates];
      let fieldExists = false;
      tBizFieldPredicates.forEach(x => {
        if (getNameFromBizField(x.bizField) === fieldName) {
          x.op = operator;
          if (isArray(value)) {
            x.value = "";
            x.values = value;
          } else {
            x.value = value;
            x.values = [];
          }
          fieldExists = true;
        }
      });
      if (!fieldExists) {
        const bizField = bizFields.find(x => getNameFromBizField(x.bizField) === fieldName);
        const predicate: BizFieldPredicate = {
          bizField: bizField.bizField,
          op: operator,
          value: isArray(value) ? "" : value,
          values: isArray(value) ? value : []
        };
        tBizFieldPredicates.push(predicate);
      }
      tBizFieldPredicates = tBizFieldPredicates.filter(x => x.value || x.values.length > 0);
      setBizFieldPredicates(tBizFieldPredicates);
    },
    [bizFieldPredicates, bizFields]
  );

  const quickFilterFields = useMemo(
    () =>
      bizFields
        .map(bizField => {
          const { propName, propType, kindDescriptor } = bizField.bizField.entityField;
          const aggregationMeta = suggAggregationsMap[propName];
          const cardinality = suggAggregationsMap[propName]?.aggregationMeta?.cardinality;
          const predicate = bizFieldPredicates.find(x => getNameFromBizField(x.bizField) === propName);
          const op = predicate?.op || null;
          const value = predicate?.values.length > 0 ? predicate.values : predicate?.value || "";
          const isHighCardinalityField = HighCardinalityEntityFieldNames.includes(propName);
          const aggregationValues = aggregationsMap?.[propName] || [];

          return {
            id: propName,
            fieldName: propName,
            fieldType: propType,
            op: op,
            value: value,
            isHighCardinalityField,
            kindDescriptor,
            entityTypeId,
            cohortId,
            cardinality,
            aggregationMeta: aggregationMeta?.aggregationMeta,
            aggregationValues
          };
        })
        .sort(a => (a.fieldName === "Name" ? -1 : 0)),
    [aggregationsMap, bizFieldPredicates, bizFields, entityTypeId, suggAggregationsMap, cohortId]
  );

  const removePredicate = useCallback(
    (propName: string) => {
      const tBizFieldPredicates = bizFieldPredicates.filter(x => x.bizField.entityField.propName !== propName);
      onUpdateFilters(tBizFieldPredicates, eMetricPredicates);
      if (updateQueryParams) {
        const queryParams = getQueryParamsFromBizFieldPredicates(tBizFieldPredicates);
        applyFilterQueryParams(queryParams);
      }
    },
    [applyFilterQueryParams, bizFieldPredicates, eMetricPredicates, onUpdateFilters, updateQueryParams]
  );

  const removeMetricPredicate = useCallback(
    idx => {
      const predicates = eMetricPredicates;
      predicates.splice(idx, 1);
      onUpdateFilters(bizFieldPredicates, predicates);
    },
    [bizFieldPredicates, eMetricPredicates, onUpdateFilters]
  );

  const valueLabel = useMemo(() => {
    let labels = eBizFieldPredicates
      .map(bizPredicate => {
        if (bizPredicate.bizField) {
          const {
            bizField: {
              entityField: { propName }
            }
          } = bizPredicate;
          const { label } = FieldPickerUtils.getBizFieldPredicatePillLabelAndInfo(bizPredicate, options);
          return {
            label,
            propName,
            onRemove: () => removePredicate(propName)
          };
        }
        return null;
      })
      .filter(x => x !== null);

    let metricPredicateLabel = "";
    if (eMetricPredicates) {
      eMetricPredicates.forEach((predicate, idx) => {
        const op = getOpFromPredicateOp(predicate.op);
        const metric = predicate?.metric as UserServiceFieldMetricConfigDefinition;
        const eventTypeId =
          metric.userServiceFieldMetricConfig?.userServiceField?.userServices?.[0]?.userServiceEntityId;
        const eventTypeName = eventTypeList.find(x => x.value === eventTypeId)?.label || eventTypeId || "";
        const name = pluralizeWord(eventTypeName);
        metricPredicateLabel = `${name} ${op} ${predicate.value}`;
        labels = [
          ...labels,
          {
            label: metricPredicateLabel,
            propName: "usage-filter",
            onRemove: () => removeMetricPredicate(idx)
          }
        ];
      });
    }

    if (loadingBizField) {
      return <span className="inc-text-subtext">Loading...</span>;
    } else if (labels.length || eMetricPredicates.length) {
      const displayLabels = labels.splice(0, pillLimit);
      const pills = displayLabels.map((x, i) => {
        const { label } = x;
        const { propName } = x;

        return (
          <IncPill
            className="pill-container"
            key={`${propName}-${i}`}
            label={label}
            labelClass={"text-ellipsis"}
            onClick={openPopper}
            onRemove={disabled ? null : x.onRemove}
            readonly={disabled}
          />
        );
      });

      const diff = labels.length;
      const hoverElement = InfoPopper(labels.map(x => x.label));

      return (
        <div className="inc-flex-row cohort-biz-picker-pills">
          {pills}
          {labels.length > 0 && (
            <IncToolTip
              placement="bottom"
              titleElement={hoverElement}
            >
              <span className="inc-text-subtext inc-link marginTp4">{`+${diff}`}</span>
            </IncToolTip>
          )}
        </div>
      );
    }
    return null;
  }, [
    disabled,
    eBizFieldPredicates,
    eMetricPredicates,
    eventTypeList,
    loadingBizField,
    openPopper,
    options,
    pillLimit,
    removeMetricPredicate,
    removePredicate
  ]);

  const selectDisabled = loadingBizField || disabled;
  const eventIdField = useMemo(
    () => userServiceFields.find(x => x.userServiceField.fieldName === EventIdFieldName)?.userServiceField,
    [userServiceFields]
  );

  const showUsageEventFilters = showUsageEvents && eventIdField && eventTypeList.length > 0 && fieldPickerContext;

  return (
    <>
      <CustomSelect
        anchor={ref}
        className={containerClassName}
        disabled={selectDisabled}
        hideSelectIconWhenDisabled
        label={label}
        labelPosition="left"
        onClick={openPopper}
        placeholderText={placeholder}
        value={valueLabel}
      />
      <IncClickAwayPopper
        anchorEl={ref.current}
        onClickAway={noOp}
        overlay
        placement="bottom"
        show={showPopper}
      >
        <div className="cohort-biz-fields-popper">
          <div className="header-container">
            <span className="inc-text-header-medium">Business Criteria</span>
            <IncFaIcon
              className="icon"
              iconName="close"
              onClick={onCancel}
            />
          </div>
          <div className="content">
            <QuickFiltersPicker
              fields={quickFilterFields}
              onChange={onChange}
              options={options}
            />
            {showUsageEventFilters && (
              <div className="paddingLt16 paddingRt16">
                <UsageEventFilter
                  eventTypeId={eventTypeId}
                  eventTypeList={eventTypeList}
                  fieldPickerContext={fieldPickerContext}
                  isCollapsible
                  metricPredicates={metricPredicates}
                  onChange={setMetricPredicates}
                  usField={eventIdField}
                />
              </div>
            )}
          </div>
          <div className="footer-container">
            <IncButton
              className="marginRt12"
              color="primary"
              onClick={applyFilters}
            >
              Apply
            </IncButton>
            <IncButton
              className="red-link"
              color="link"
              onClick={onCancel}
            >
              Cancel
            </IncButton>
          </div>
        </div>
      </IncClickAwayPopper>
    </>
  );
};

const getNameFromBizField = (bizField: BizField) => bizField?.entityField?.propName || "";

const getQueryParamsFromBizFieldPredicates = (bizFieldpredicates: BizFieldPredicate[], prefix = VAR_URL_PREFIX) => {
  const queryParamList = bizFieldpredicates.map(x =>
    getFilterExprQueryParam(x.bizField.entityField.propName, x.op, x.values?.length ? x.values : x.value, prefix)
  );

  const queryParams: Record<string, string | string[]> = {};
  queryParamList.forEach(x => {
    queryParams[x.queryKey] = x.queryValue;
  });
  return queryParams;
};

const getBizFieldPredicatesFromQueryParams = (
  queryParams: Record<string, string | string[]>,
  bizFields: BizField[],
  prefix = VAR_URL_PREFIX
) => {
  const bizFieldPredicates: BizFieldPredicate[] = [];
  const resolvedQueryParams = resolveFiltersFromQueryParams(queryParams, prefix);

  bizFields.forEach(bf => {
    const queryParam = resolvedQueryParams.find(qp => qp.key === bf.entityField.propName);
    if (queryParam) {
      bizFieldPredicates.push({
        bizField: bf,
        op: queryParam.operator,
        value: isArray(queryParam.value) ? "" : queryParam.value,
        values: isArray(queryParam.value) ? queryParam.value : []
      });
    }
  });

  return bizFieldPredicates;
};
