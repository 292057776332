import React, { useState, useEffect, useRef, useMemo, memo } from "react";
import { IncFaIcon, IncClickAway, IncPopper, IncToolTip } from "@inception/ui";
import { MultipleSelects } from "./MultipleSelects";

type SeriesSelectProps = {
  selectedOptions: Map<string, string[]>;
  onSeriesSelectionChanged: (args: Map<string, string[]>, id?: string) => void;
  groupOptions?: Map<string, string[]>;
  highlightOnSelection?: boolean;
};

export const SeriesSelect: React.FC<SeriesSelectProps> = memo((props: SeriesSelectProps) => {
  const { selectedOptions, groupOptions, onSeriesSelectionChanged, highlightOnSelection = false } = props;

  const seriesSelectRef = useRef<HTMLDivElement>(null);
  const [parentElmWidth, setParentElmWidth] = useState<number>();
  const [open, setOpen] = useState(false);
  const toggleRef = useRef(null);

  useEffect(() => {
    if (seriesSelectRef.current) {
      const { clientWidth } = seriesSelectRef.current;
      setParentElmWidth(clientWidth || 0);
    }
  }, [seriesSelectRef]);

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars-ts
  const addBadge = highlightOnSelection && selectedOptions.size > 0;

  const filterComponent = useMemo(
    () => (
      <span style={{ display: "inline-flex" }}>
        <IncFaIcon
          iconName="filter"
          onClick={handleClick}
          ref={toggleRef}
          style={{ cursor: "pointer" }}
        />
      </span>
    ),
    []
  );

  return (
    <div
      className="timeseries-selector"
      ref={seriesSelectRef}
    >
      <IncToolTip
        placement="top"
        titleText="Tag filters"
      >
        {filterComponent}
      </IncToolTip>

      <IncClickAway onClickAway={handleClickAway}>
        {ref => (
          <IncPopper
            anchorEl={toggleRef.current}
            className="timeseries-selector-popper"
            offset={{
              x: 5,
              y: 5
            }}
            placement="bottom-start"
            ref={ref}
            show={open}
          >
            <MultipleSelects
              className="timeseries-selector-wrapper"
              groupOptions={groupOptions}
              itemsCount={3}
              onSeriesSelectionChanged={onSeriesSelectionChanged}
              parentElmWidth={parentElmWidth}
              selectedOptions={selectedOptions}
            />
          </IncPopper>
        )}
      </IncClickAway>
    </div>
  );
});
