import React, { FC, useCallback, useMemo, useState } from "react";
import { IncCheckbox, IncLabel, getInceptionTheme, IncSelect, IncSelectOption } from "@inception/ui";
import { ChartColorPicker } from "../../../../../../../biz-flow/ChartColorPicker";
import { SparkLineProperties } from "../../../../models";
import { useFetchFieldNameMetricNames } from "../../../../components/renderers";
import { WidgetCustomizationProps } from "./types";
import { ColorRangeCustomization, UIColorRangeCondition, getGeneratedThreshold } from "./UIColorRangeCondition";
import { useCommonCustomisationExtraction } from "./common";

const CheckboxStyleProps: any = { placement: "end" };

export const SparkLineUICustomization: FC<WidgetCustomizationProps> = props => {
  const { widgetImpl, properties, onPropertiesChange } = props;

  const defaultSingleStatProp = useMemo(
    () => ({
      id: "1",
      defaultColor: getInceptionTheme().inceptionColors.palette.BL25,
      defaultBackground: "transparent",
      thresholds: [getGeneratedThreshold() as any]
    }),
    []
  );

  const defaultSparkLineProp: SparkLineProperties = useMemo(
    () => ({
      connectNulls: false,
      pointRadius: 2,
      shape: "circle",
      color: "#fe85fc",
      background: "transparent",
      hide: false,
      singleStatProperties: defaultSingleStatProp
    }),
    [defaultSingleStatProp]
  );

  const { sparkLine: sparkLineProperties } = properties || {};

  const sparkLine = sparkLineProperties || defaultSparkLineProp;

  const [showSparkLine, setShowSparkLine] = useState(!sparkLine?.hide);

  const {
    background,
    color,
    hide,
    singleStatProperties: pSingleStatProperties,
    metricId: propertyMetricId
  } = sparkLine;

  const {
    metricId: queryMetricId,
    queryConfig,
    aggregator,
    eventTypeName
  } = useCommonCustomisationExtraction(widgetImpl);

  const metricId = propertyMetricId || queryMetricId;

  const { metricName, childMetricNames } = useFetchFieldNameMetricNames(aggregator, queryConfig, eventTypeName);

  const { metricNamesMap, metricIds } = useMemo(() => {
    const metricNamesMap = {
      [queryMetricId]: metricName,
      ...childMetricNames
    };
    const metricIds = Object.keys(metricNamesMap);

    return {
      metricNamesMap,
      metricIds
    };
  }, [childMetricNames, metricName, queryMetricId]);

  const selectedMetricOpt = useMemo(
    () => ({
      value: metricId,
      label: metricNamesMap[metricId] || ""
    }),
    [metricId, metricNamesMap]
  );

  const metricOpts = useMemo(
    () =>
      metricIds.map(id => ({
        value: id,
        label: metricNamesMap[id] || ""
      })),
    [metricIds, metricNamesMap]
  );

  const singleStatProperties = pSingleStatProperties || defaultSingleStatProp;

  const { thresholds: pThresholds } = singleStatProperties;
  const thresholds = pThresholds || [getGeneratedThreshold()];

  /**
   * Update the property object for persistence
   */
  const updateWidgetRenderProp = useCallback(() => {
    properties.sparkLine = sparkLine;
    onPropertiesChange(properties);
  }, [onPropertiesChange, properties, sparkLine]);

  /**
   * handle line color change
   */
  const onLineColorChange = useCallback(
    (color: string) => {
      sparkLine.color = color;
      updateWidgetRenderProp();
    },
    [sparkLine, updateWidgetRenderProp]
  );
  /**
   * handle background color change
   */
  const onFillColorChange = useCallback(
    (color: string) => {
      sparkLine.background = color;
      updateWidgetRenderProp();
    },
    [sparkLine, updateWidgetRenderProp]
  );
  /**
   * handle threshold changes
   */
  const handleThresholdChange = useCallback(
    (thresholds: ColorRangeCustomization[]) => {
      singleStatProperties.thresholds = thresholds;
      sparkLine.singleStatProperties = singleStatProperties;
      updateWidgetRenderProp();
    },
    [singleStatProperties, sparkLine, updateWidgetRenderProp]
  );
  /**
   * handle spark line toggle change
   */
  const handleSparklineToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setShowSparkLine(checked);
      sparkLine.hide = !checked;
      updateWidgetRenderProp();
    },
    [sparkLine, updateWidgetRenderProp]
  );

  const onChangeMetric = useCallback(
    (metricOpt: IncSelectOption) => {
      sparkLine.metricId = metricOpt.value;
      updateWidgetRenderProp();
    },
    [sparkLine, updateWidgetRenderProp]
  );

  return (
    <div className="customization-content">
      <div className="query-editor-wrapper--content">
        <IncSelect
          autoAdjustWidth
          label="Metric to show"
          onChange={onChangeMetric}
          options={metricOpts}
          value={selectedMetricOpt}
          wrapperClass="marginBt12"
        />

        <UIColorRangeCondition
          onThresholdChange={handleThresholdChange}
          thresholds={thresholds as ColorRangeCustomization[]}
        />

        <div className="d-flex list-item flex-gap-12 width-100 align-items-center justify-content-start">
          <div className="flex-gap-12">
            <IncCheckbox
              checked={showSparkLine}
              defaultChecked={true}
              label="Show Sparkline"
              labelProps={CheckboxStyleProps}
              onChange={handleSparklineToggle}
              value={!hide}
            ></IncCheckbox>
          </div>
          <div className="d-flex align-items-center flex-gap-8 left-border">
            <div className="color-picker-container">
              <ChartColorPicker
                color={color || "#fe85fc"}
                hideEditOnHover
                onChange={onLineColorChange}
                size={24}
              />
            </div>
            <IncLabel className="input-label">Line Colour</IncLabel>
          </div>

          <div className="d-flex align-items-center flex-gap-8 left-border">
            <div className="color-picker-container">
              <ChartColorPicker
                color={background || "transparent"}
                hideEditOnHover
                onChange={onFillColorChange}
                size={24}
              />
            </div>
            <IncLabel className="input-label">Fill Color</IncLabel>
          </div>
        </div>
      </div>
    </div>
  );
};
