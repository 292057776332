import { useMemo, useCallback } from "react";
import { InitialState, FetchCallBackResult, useDataFetch, FetchFn } from "../../../../core";
import {
  ExploreEntityFilter,
  FunnelData,
  journeyApiService,
  SliceSpec,
  TimeObj,
  TimeObjUnit,
  UserServiceFilterList
} from "../../../../services/api/explore";

export const useFetchFunnelData = (
  funnelId: string,
  sliceSpec: SliceSpec[],
  compareTimeInSeconds: number,
  startTimeMillis: number,
  endTimeMillis: number,
  entityFilters: ExploreEntityFilter[],
  eventFilters: UserServiceFilterList
) => {
  const compareTimeObj = useMemo<TimeObj>(
    () =>
      compareTimeInSeconds
        ? {
            unit: TimeObjUnit.minutes,
            value: compareTimeInSeconds / 60
          }
        : null,
    [compareTimeInSeconds]
  );

  const initialData = useMemo<InitialState<FunnelData, string>>(
    () => ({
      data: null,
      error: null,
      isError: false,
      isFetching: true,
      isSuccess: false
    }),
    []
  );

  const fetchFn = useCallback<FetchFn<FunnelData, string>>(async () => {
    const result: FetchCallBackResult<FunnelData, string> = {
      data: null,
      error: null,
      isError: false,
      isSuccess: false
    };

    const { data, error, message } = await journeyApiService.getFunnelData(
      funnelId,
      sliceSpec,
      compareTimeObj,
      startTimeMillis,
      endTimeMillis,
      entityFilters,
      eventFilters
    );

    result.data = data;
    result.isError = error;
    result.isSuccess = !error;
    result.error = error ? message : null;

    return result;
  }, [compareTimeObj, endTimeMillis, entityFilters, eventFilters, funnelId, sliceSpec, startTimeMillis]);

  return useDataFetch(fetchFn, initialData, true);
};
