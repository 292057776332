import React, { FC, useState, useCallback, useMemo, useEffect } from "react";
import {
  IncModal,
  IncButton,
  IncInModalConfirmation,
  IncFaIcon,
  IncToolTip,
  IncModalProps,
  IncTextfield,
  IncTextArea
} from "@inception/ui";
import { clone, cloneDeep, last } from "lodash";
import {
  exploreApiService,
  UserServiceFieldMetricConfigDefinition,
  WidgetConfigUtils,
  WidgetResponseDTO
} from "../../services/api/explore";
import { getDefaultWidgetConfigDto, getDtoFromWidgetConfig } from "../../utils/ExploreUtils";
import {
  generateId,
  logger,
  useInputState,
  useTenantConfig,
  useToggleState,
  useVerticalConfig,
  Visualisations
} from "../../core";
import { LoadingSpinner, VerticallyCenteredRow } from "../../components";
import { SaveCohortModal } from "../SaveCohortModal";
import { featureFlagService, FEATURE_FLAGS } from "../../services/feature-flags";
import { CatalogWidgetImpl, WidgetQuerySourceConfig } from "../../dashboard/widgets/Catalog/models";
import { catalogWidgetBuilder } from "../../dashboard/builders";
import appConfig from "../../../appConfig";
import { WidgetConfiguration } from "../../dashboard/widgets/Catalog/maximize-view/components";
import { useAddToDashboardAction } from "../../dashboard/widgets/widget-components";
import { AddMetricModalProps } from "./types";

export type AddMetricModalV2Props = AddMetricModalProps & {
  presetViz?: Visualisations;
  metricsVisualOrder?: string[];
  defWidgetName?: string;
  defWidgetDescription?: string;
};

export const AddMetricModalV2: FC<AddMetricModalV2Props> = props => {
  const {
    entityTypeInfo,
    eventTypeInfos,
    activeEventTypeId,
    onMetricAdded,
    cohortConfig,
    cohortState,
    onCohortSave: cohortSaveCallback,
    cohortSaveInProgress,
    defWidgetConfigDto,
    defUSField,
    onClose,
    open,
    presetViz,
    disableAddToDashboard,
    eventTypeToBizEntityFieldNamesMap,
    onAddWidget,
    metricsVisualOrder,
    defWidgetName = "",
    defWidgetDescription = ""
  } = props;

  const [isInitialising, setIsInitialising] = useState(true);

  const { tenantConfigState } = useTenantConfig();
  const { demoTenant = false } = tenantConfigState || {};

  // The map will have only one bizEntityFieldName (if selected)
  const bizEntityFieldName = eventTypeToBizEntityFieldNamesMap?.[activeEventTypeId]?.[0];
  const defWidgetConfigDtoExists = Boolean(defWidgetConfigDto);

  const dryRun = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.dryRunAddMetric);
  const addCohortToWidgetConfig = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.enableCohortsInWidgetConfigs);

  const [unsavedChangesExist, setUnsavedChangesExist] = useState(false);
  const [validState, setValidState] = useState<[boolean, string]>([true, ""]);

  const vizErrorMessage =
    !defWidgetConfigDtoExists && unsavedChangesExist ? "" : `Start configuring data to view preview`;

  const updateValidState = useCallback((isValid: boolean, validationMessage: string) => {
    setValidState([isValid, validationMessage]);
  }, []);

  const cohortDefinition = cohortConfig?.cohortDefinition?.cohortDefinition;
  const widgetCohortDefinition = addCohortToWidgetConfig ? cohortDefinition : null;
  const isCohortValid = addCohortToWidgetConfig
    ? cohortDefinition
      ? cohortDefinition && cohortState === "saved"
      : true
    : true;

  const { entityTypeId, name: entityTypeName = entityTypeId } = entityTypeInfo;

  const defEntityTypeId = entityTypeId;
  const defEventTypeId = entityTypeId ? null : activeEventTypeId || eventTypeInfos[0]?.entityId;

  const getDefaultWidgetResponse = useCallback((): WidgetResponseDTO => {
    const widgetConfigDTO = getDefaultWidgetConfigDto(
      defEntityTypeId,
      defEventTypeId,
      null,
      entityTypeName,
      widgetCohortDefinition
    );

    return {
      querySchema: {
        querySchema: []
      },
      version: 1,
      widgetConfig: widgetConfigDTO,
      widgetId: null
    };
  }, [defEntityTypeId, defEventTypeId, entityTypeName, widgetCohortDefinition]);

  const defWidgetResponseDto = useMemo<WidgetResponseDTO>(() => {
    if (defWidgetConfigDto) {
      return {
        querySchema: {
          querySchema: []
        },
        version: 1,
        widgetConfig: defWidgetConfigDto,
        widgetId: null
      };
    }

    return getDefaultWidgetResponse();
  }, [defWidgetConfigDto, getDefaultWidgetResponse]);

  const { useCaseApi } = useVerticalConfig();
  const { getKpisForSelectedUseCases } = useCaseApi;

  const initialiseWidgetImpl = useCallback(async () => {
    setIsInitialising(true);

    let metricId: string;
    let childMetricIds: string[];
    let aggregateTags: string[];
    let widgetResponseDTO = defWidgetResponseDto;

    if (!defWidgetConfigDtoExists) {
      let hasInited = false;

      const kpis = getKpisForSelectedUseCases();
      const kpi = kpis[0];
      if (kpi) {
        try {
          const nBizDataQuery = await WidgetConfigUtils.getWidgetConfigBasedBizDataQuery(kpi.bizDataQuery);
          const widgetConfigDto = getDtoFromWidgetConfig(cloneDeep(nBizDataQuery.widgetConfig));
          widgetConfigDto.name = kpi.name;
          const metrics = widgetConfigDto.dataDefinition.metrics || {};
          const visibleMetricIds = WidgetConfigUtils.getVisibleMetricIds(metrics);
          metricId = visibleMetricIds[0];
          childMetricIds = visibleMetricIds.slice(1);
          widgetResponseDTO = {
            querySchema: {
              querySchema: []
            },
            version: 1,
            widgetConfig: widgetConfigDto,
            widgetId: null
          };

          visibleMetricIds.forEach(metricId => {
            metrics[metricId].kpiId = kpi.id;
          });

          const usfm = Object.values(metrics).find(
            m => m.sourceType === "userServiceField"
          ) as UserServiceFieldMetricConfigDefinition;
          if (usfm) {
            const sliceSet = last(usfm.userServiceFieldMetricConfig.sliceSets || []);
            aggregateTags = sliceSet?.slices?.map(s => s.tagName) || [];
          } else {
            aggregateTags = [];
          }
          hasInited = true;
        } catch (e) {
          logger.error("AddMetricModal", "Failed to get widget config for kpi", {
            kpi: kpis[0],
            error: e
          });
        }
      }

      if (!hasInited) {
        metricId = generateId();
        childMetricIds = [];
        aggregateTags = [];

        defWidgetResponseDto.widgetConfig.dataDefinition.metrics = {
          [metricId]: {
            id: metricId,
            name: "Metric 1",
            sourceType: "userServiceField",
            userServiceFieldMetricConfig: {
              aggregator: "count",
              eventFilters: {
                userServiceFilters: []
              },
              sliceSets: [
                {
                  slices: []
                }
              ],
              userServiceField: defUSField
            }
          }
        };
      }
    } else {
      const metrics = defWidgetResponseDto.widgetConfig.dataDefinition.metrics || {};
      const visibleMetricIds = WidgetConfigUtils.getVisibleMetricIds(metrics);
      metricId = visibleMetricIds[0];
      childMetricIds = visibleMetricIds.slice(1);

      const usfm = Object.values(metrics).find(
        m => m.sourceType === "userServiceField"
      ) as UserServiceFieldMetricConfigDefinition;
      if (usfm) {
        const sliceSet = last(usfm.userServiceFieldMetricConfig.sliceSets || []);
        aggregateTags = sliceSet?.slices?.map(s => s.tagName) || [];
      } else {
        aggregateTags = [];
      }
    }

    const catalogModel = catalogWidgetBuilder()
      .setDatasource(appConfig.defaultExploreDsName)
      .setBizEntityFieldName(bizEntityFieldName)
      .setUserserviceId(defEventTypeId)
      .setEntityType(defEntityTypeId)
      .setQueryConfig({
        sourceQueryConfig: {
          queryType: "widgetConfig",
          metricId,
          widgetResponse: widgetResponseDTO,
          childMetricIds
        }
      })
      .setRenderMode("viz-only")
      .setProperties({
        visualisation: presetViz || Visualisations.timeseries,
        aggregateTags
      })
      .setGenerateDemoData(demoTenant)
      .setMetricsVisualOrder(metricsVisualOrder)
      .disableEdit()
      .disableHeader()
      .disableBorder()
      .disableActions()
      .disableBorder()
      .enableTransparent()
      .buildModel();

    const defWidgetImpl = new CatalogWidgetImpl(catalogModel);
    setWidgetImpl(defWidgetImpl);
    setIsInitialising(false);
  }, [
    bizEntityFieldName,
    defEntityTypeId,
    defEventTypeId,
    defUSField,
    defWidgetConfigDtoExists,
    defWidgetResponseDto,
    demoTenant,
    getKpisForSelectedUseCases,
    metricsVisualOrder,
    presetViz
  ]);

  const [widgetImpl, setWidgetImpl] = useState<CatalogWidgetImpl>();
  useEffect(() => {
    if (!widgetImpl) {
      initialiseWidgetImpl();
    }
  }, [initialiseWidgetImpl, widgetImpl]);

  const widgetResponseDTO = useMemo(() => {
    if (widgetImpl?.queryConfig.sourceQueryConfig.queryType === "widgetConfig") {
      return widgetImpl.queryConfig.sourceQueryConfig.widgetResponse;
    }
    return defWidgetResponseDto;
  }, [defWidgetResponseDto, widgetImpl]);

  const [widgetToAdd, setWidgetToAdd] = useState<CatalogWidgetImpl>(null);

  const [saveInProgress, setSaveInProgress] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [error, setError] = useState("");

  const { inputValue: widgetName, onInputChange: onWidgetNameChange } = useInputState(defWidgetName || "");

  const { inputValue: widgetDescription, onInputChange: onWidgetDescriptionChange } = useInputState(
    defWidgetDescription || ""
  );

  const { close: closeWidgetNameModal, open: openWidgetNameModal, isOpen: isWidgetNameModalOpen } = useToggleState();

  const { close: closeCohortSaveModal, open: isCohortSaveModalOpen, isOpen: showSaveCohortModal } = useToggleState();

  const onCohortSave = useCallback(
    (name: string) => {
      closeCohortSaveModal();
      cohortSaveCallback(name);
    },
    [closeCohortSaveModal, cohortSaveCallback]
  );

  const beforeReset = useCallback(() => {
    if (onMetricAdded && widgetToAdd && !dryRun) {
      const { queryConfig } = widgetToAdd;
      const { sourceQueryConfig } = queryConfig;
      const widgetResponseDTO =
        sourceQueryConfig.queryType === "widgetConfig" ? sourceQueryConfig.widgetResponse : null;

      if (widgetResponseDTO) {
        onMetricAdded(widgetResponseDTO, widgetResponseDTO.widgetId);
      }
    }
  }, [dryRun, onMetricAdded, widgetToAdd]);

  const openWarning = useCallback(() => setShowWarning(true), []);
  const closeWarning = useCallback(() => setShowWarning(false), []);

  const closeAddMetricModal = useCallback(() => {
    onClose();
    closeWidgetNameModal();
    closeWarning();
    beforeReset();
  }, [beforeReset, closeWarning, closeWidgetNameModal, onClose]);

  const preOnCloseAddMetricModal = useCallback(() => {
    if (unsavedChangesExist) {
      openWarning();
    } else {
      closeAddMetricModal();
    }
  }, [closeAddMetricModal, openWarning, unsavedChangesExist]);

  const {
    openCallback: openAddToDashboardModal,
    addToDashboardActionModal,
    isAddToDashboardModalOpen
  } = useAddToDashboardAction(widgetToAdd, null, null, closeAddMetricModal);

  const onSave = useCallback(async () => {
    setSaveInProgress(true);
    try {
      const sourceQueryConfig = widgetImpl.queryConfig.sourceQueryConfig as WidgetQuerySourceConfig;
      const saveWidgetConfig = clone(sourceQueryConfig.widgetResponse.widgetConfig);
      saveWidgetConfig.name = widgetName;

      logger.info("AddMetricModalV2", "Save widget config", saveWidgetConfig);

      const promise = dryRun ? dryRunPromise : exploreApiService.saveWidgetConfig(saveWidgetConfig, {}, false);

      const { data, error, message } = await promise;
      if (error) {
        setError(message);
      } else if (!dryRun) {
        const clSourceQueryConfig = cloneDeep(sourceQueryConfig);
        clSourceQueryConfig.widgetResponse.widgetId = data.widgetId;
        clSourceQueryConfig.widgetResponse.querySchema = data.querySchema;

        const clWidgetImpl = cloneDeep(widgetImpl);
        delete clWidgetImpl.renderMode;
        delete clWidgetImpl.meta;
        delete clWidgetImpl.queryConfig;

        clWidgetImpl.title = widgetName;
        clWidgetImpl.description = widgetDescription;

        clWidgetImpl.widgetConfigRefId = data.widgetId;
        clWidgetImpl.queryConfig = {
          sourceQueryConfig: clSourceQueryConfig
        };
        clWidgetImpl.background = null;
        clWidgetImpl.properties = {
          ...(clWidgetImpl.properties || {}),
          borderLess: null,
          transparent: null,
          background: null
        };

        if (onAddWidget) {
          const nWidgetImpl = cloneDeep(clWidgetImpl);
          nWidgetImpl.id = generateId();

          onAddWidget(nWidgetImpl);
        }

        if (disableAddToDashboard) {
          closeAddMetricModal();
        } else {
          setWidgetToAdd(clWidgetImpl);
          openAddToDashboardModal();
        }
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setSaveInProgress(false);
    }
  }, [
    closeAddMetricModal,
    disableAddToDashboard,
    dryRun,
    onAddWidget,
    openAddToDashboardModal,
    widgetDescription,
    widgetImpl,
    widgetName
  ]);

  const cohortLabelChildren = useMemo(() => {
    if (cohortState === "draft") {
      return (
        <IncButton
          className="marginLt8"
          color="link"
          onClick={isCohortSaveModalOpen}
        >
          Save
        </IncButton>
      );
    } else if (cohortSaveInProgress) {
      return <LoadingSpinner titleText="Saving cohort..." />;
    }
    return null;
  }, [cohortSaveInProgress, cohortState, isCohortSaveModalOpen]);

  const cohortValidityMessage = isCohortValid ? null : "Cohort needs to be saved before saving the metric";

  const addMetricActions = useMemo<IncModalProps["actions"]>(() => {
    const [isValid, validMessage] = validState;
    const canSave = isValid && !validMessage;
    const errorMessage = cohortValidityMessage || validMessage;

    return {
      primary: {
        onClick: openWidgetNameModal,
        disabled: !canSave || saveInProgress,
        label: "Next"
      },
      secondary: {
        onClick: preOnCloseAddMetricModal,
        label: "Cancel",
        color: "secondary-blue"
      },
      preJSX: (
        <>
          {!canSave && (
            <IncToolTip
              placement="top"
              titleText={errorMessage}
              variant="error"
            >
              <VerticallyCenteredRow>
                <IncFaIcon
                  className="status-danger"
                  iconName="exclamation-triangle"
                />
              </VerticallyCenteredRow>
            </IncToolTip>
          )}
        </>
      )
    };
  }, [cohortValidityMessage, openWidgetNameModal, preOnCloseAddMetricModal, saveInProgress, validState]);

  const widgetNameActions = useMemo<IncModalProps["actions"]>(() => {
    const isValid = Boolean(widgetName);

    return {
      primary: {
        onClick: onSave,
        disabled: !isValid || saveInProgress,
        label: "Save Widget",
        showLoader: saveInProgress
      },
      secondary: {
        onClick: closeWidgetNameModal,
        label: "Back to Edit",
        disabled: saveInProgress,
        color: "secondary-blue"
      }
    };
  }, [closeWidgetNameModal, onSave, saveInProgress, widgetName]);

  return (
    <>
      <IncModal
        actions={addMetricActions}
        className="add-metric-modal"
        closeOnBackdrop={false}
        closeOnEscape={false}
        disableFocusOnLoad
        onClose={preOnCloseAddMetricModal}
        show={open && !isWidgetNameModalOpen && !isAddToDashboardModalOpen}
        showClose={!saveInProgress}
        size="full-screen"
        titleText="Add widget"
      >
        {!isInitialising && (
          <>
            <WidgetConfiguration
              onUnsavedChangesExist={setUnsavedChangesExist}
              onWidgetImplChange={setWidgetImpl}
              setValidState={updateValidState}
              vizErrorMessage={vizErrorMessage}
              widgetImpl={widgetImpl}
              widgetResponseDTO={widgetResponseDTO}
            >
              {(Boolean(cohortLabelChildren) || Boolean(error)) && (
                <VerticallyCenteredRow className="flex-gap-12">
                  {Boolean(cohortLabelChildren) && cohortLabelChildren}
                  {Boolean(error) && (
                    <VerticallyCenteredRow className="flex-gap-8">
                      <IncFaIcon
                        className="status-danger"
                        iconName="exclamation-triangle"
                      />
                      {error}
                    </VerticallyCenteredRow>
                  )}
                </VerticallyCenteredRow>
              )}
            </WidgetConfiguration>

            {showWarning && (
              <IncInModalConfirmation
                message="Unsaved changes exist. Do you really want to quit?"
                onCancel={closeWarning}
                onConfirm={closeAddMetricModal}
              />
            )}
          </>
        )}
        {isInitialising && <LoadingSpinner titleText="Initialising..." />}
      </IncModal>

      <IncModal
        actions={widgetNameActions}
        closeOnBackdrop={false}
        closeOnEscape={false}
        show={isWidgetNameModalOpen}
        showClose={false}
        size="sm"
        titleText="Save Widget"
      >
        <div className="inc-flex-column flex-gap-12 width-100">
          <IncTextfield
            containerClassName="width-100"
            label="Name"
            onChange={onWidgetNameChange}
            placeholder="Name"
            required
            value={widgetName}
          />

          <IncTextArea
            containerClassName="width-100"
            label="Description"
            onChange={onWidgetDescriptionChange as any}
            placeholder="Description"
            required
            value={widgetDescription}
          />
        </div>
      </IncModal>

      {addToDashboardActionModal}

      <SaveCohortModal
        onClose={closeCohortSaveModal}
        saveCohort={onCohortSave}
        show={showSaveCohortModal}
        size="sm"
        titleText="Save cohort"
      />
    </>
  );
};

const dryRunPromise = Promise.resolve({
  data: {
    widgetId: "",
    querySchema: {
      querySchema: []
    }
  },
  error: false,
  message: ""
});
