import { IncButton, IncFaIcon, IncInModalConfirmation, IncModal, IncModalProps, IncToolTip } from "@inception/ui";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { clone, cloneDeep, isEqual } from "lodash";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../../components";
import { WidgetMaximizeProps } from "../../types";
import { CatalogWidgetImpl, WidgetQuerySourceConfig } from "../models";
import { CatalogWidgetUtils } from "../CatalogWidgetUtils";
import { generateId, logger, useAccessPrivilege, useFetchOpConfigs, useToggleState } from "../../../../core";
import EditableLabelInput from "../../../../components/editable-input-label/EditableInputLabel";
import { exploreApiService } from "../../../../services/api/explore";
import { widgetRegistry } from "../../WidgetRegistry";
import { dashboardUtils } from "../../../dashboard-grid";
import appConfig from "../../../../../appConfig";
import { useAuth } from "../../../../login/state/useAuth";
import { VisualizationTab, WidgetConfiguration } from "./components";

interface Props extends WidgetMaximizeProps {}

export const CatalogMaximizeView: React.FC<Props> = memo((props: Props) => {
  const { widget, dbImpl, onUpdate, onMinimize, variableSrvMap, addlContext, eventTypeToFieldsMap } = props;

  const shouldOpenEdit = addlContext?.mode === "edit";

  const catalogWidget = widget as CatalogWidgetImpl;
  const { widgetConfigRefId, queryConfig: cQueryConfig, id, meta, renderMode, disableUpdate } = catalogWidget;

  const { authState } = useAuth();
  const { user } = authState;
  const shouldFetchOpConfigs = widgetConfigRefId && !user?.isMockUser;

  const {
    data: opConfigs,
    error: opConfigError,
    isError: isOpConfigsError,
    isFetching: isOpConfigsFetching,
    refetch: fetchOpConfigs
  } = useFetchOpConfigs(widgetConfigRefId, null, null, null, null, true);

  useEffect(() => {
    if (shouldFetchOpConfigs) {
      fetchOpConfigs();
    }
  }, [fetchOpConfigs, shouldFetchOpConfigs]);

  const numOpConfigs = useMemo(() => opConfigs?.length || 0, [opConfigs]);

  useEffect(() => {
    if (!isOpConfigsFetching && isOpConfigsError) {
      logger.error("Catalog Widget", "Error fetching opConfigs", {
        widgetId: widgetConfigRefId,
        error: opConfigError
      });
    }
  }, [isOpConfigsError, isOpConfigsFetching, opConfigError, widgetConfigRefId]);

  const currentWidgetVersion = useMemo(() => {
    const sourceQueryConfig = cQueryConfig.sourceQueryConfig as WidgetQuerySourceConfig;
    return sourceQueryConfig.widgetResponse?.version ?? 0;
  }, [cQueryConfig.sourceQueryConfig]);

  const { canEdit: accessCanEdit } = useAccessPrivilege();
  const canEdit = accessCanEdit && dbImpl.meta?.edit && widget.meta?.edit;

  const [title, setTitle] = useState<string>(catalogWidget.title);
  useEffect(() => {
    setTitle(catalogWidget.title);
  }, [catalogWidget.title]);

  /**
   * Flow of widgetImpl context:
   *  - We extract vizWidgetImpl (visualization tab) from the latest catalogWidget
   *  - configCatalogWidgetImpl always is deep clone of vizWidgetImpl, done to make sure we're configuring on top of the latest viz's model
   *  - Everytime viz's properties chnage, we update the model in the dashboard.
   *  - When the configuration saves a new model, the viz model will be contructed from the latest model saved by configuration
   */
  const [vizWidgetImpl, setVizWidgetImpl] = useState(getModifiedWidgetImpl(catalogWidget));
  useEffect(() => {
    setVizWidgetImpl(getModifiedWidgetImpl(catalogWidget));
  }, [catalogWidget]);

  const [configWidgetImpl, setConfigWidgetImpl] = useState(vizWidgetImpl);

  const unsavedPropertiesExist = useMemo(
    () => !isEqual(configWidgetImpl.properties, catalogWidget.properties),
    [catalogWidget.properties, configWidgetImpl.properties]
  );

  const { isOpen: saveInProgress, open: initiateSave, close: completeSave } = useToggleState();

  const { isOpen: isQuitWarningOpen, open: openQuitWarning, close: closeQuitWarning } = useToggleState();

  const { isOpen: isSaveWarningOpen, open: openSaveWarning, close: closeSaveWarning } = useToggleState();

  const [unsavedChangesExist, setUnsavedChangesExist] = useState(false);

  const [error, setError] = useState("");

  const addWidget = useCallback(
    (nWidgetImpl: CatalogWidgetImpl) => {
      const fWidgetImpl = clone(nWidgetImpl);
      fWidgetImpl.id = generateId();

      const partLayout = widgetRegistry.getPropsByWidgetId("catalog").dimensions();
      const layout = dashboardUtils.getBestFitLayout(dbImpl.layout, partLayout);
      dbImpl.addWidget(fWidgetImpl, layout);

      onUpdate();
      onMinimize();
    },
    [dbImpl, onMinimize, onUpdate]
  );

  const closeModal = useCallback(() => {
    closeQuitWarning();
    closeSaveWarning();
  }, [closeQuitWarning, closeSaveWarning]);

  const { isOpen: isEditMode, toggle: toggleEditMode } = useToggleState(shouldOpenEdit);

  const updateWidget = useCallback(
    (nWidgetImpl: CatalogWidgetImpl, toggleMode = false) => {
      const fWidgetImpl = clone(nWidgetImpl);
      fWidgetImpl.id = id;
      fWidgetImpl.meta = meta;
      fWidgetImpl.renderMode = renderMode;
      fWidgetImpl.widgetConfigRefId = widgetConfigRefId;

      dbImpl.updateWidget(fWidgetImpl.id, fWidgetImpl);
      onUpdate();
      toggleMode && toggleEditMode();
      toggleMode && onMinimize();
      setVizWidgetImpl(getModifiedWidgetImpl(fWidgetImpl));
    },
    [dbImpl, id, meta, onMinimize, onUpdate, renderMode, toggleEditMode, widgetConfigRefId]
  );

  const onVizImplUpdate = useCallback(
    (updaterFn: (impl: CatalogWidgetImpl) => CatalogWidgetImpl) => {
      const nextWidgetImpl = updaterFn(vizWidgetImpl);
      setVizWidgetImpl(nextWidgetImpl);
      updateWidget(nextWidgetImpl);
    },
    [updateWidget, vizWidgetImpl]
  );

  const onPerformSave = useCallback(
    async (saveAs = false) => {
      if (!saveAs && !unsavedChangesExist) {
        updateWidget(configWidgetImpl, true);
      } else {
        initiateSave();

        try {
          const sourceQueryConfig = configWidgetImpl.queryConfig.sourceQueryConfig as WidgetQuerySourceConfig;
          const saveWidgetConfig = sourceQueryConfig.widgetResponse.widgetConfig;

          logger.info("Maximize View", "Save widget config", saveWidgetConfig);

          const promise = saveAs
            ? exploreApiService.saveWidgetConfig(saveWidgetConfig, {}, false)
            : exploreApiService.updateWidgetConfig(saveWidgetConfig, widgetConfigRefId, currentWidgetVersion);

          const { data, error, message } = await promise;
          if (error) {
            setError(message);
          } else {
            if (saveAs) {
              const clSourceQueryConfig = cloneDeep(sourceQueryConfig);
              clSourceQueryConfig.widgetResponse.widgetId = data.widgetId;

              const clWidgetImpl = cloneDeep(configWidgetImpl);
              delete clWidgetImpl.renderMode;
              delete clWidgetImpl.meta;
              delete clWidgetImpl.queryConfig;

              clWidgetImpl.widgetConfigRefId = data.widgetId;
              clWidgetImpl.queryConfig = {
                sourceQueryConfig: cloneDeep(sourceQueryConfig)
              };
              clWidgetImpl.properties = {
                ...(clWidgetImpl.properties || {}),
                borderLess: null,
                transparent: null,
                background: null
              };

              addWidget(clWidgetImpl);
              closeModal();
            } else {
              sourceQueryConfig.widgetResponse.version = currentWidgetVersion + 1;
              updateWidget(configWidgetImpl, true);
            }
          }
        } catch (err) {
          setError(err.message);
        } finally {
          closeSaveWarning();
          completeSave();
        }
      }
    },
    [
      addWidget,
      closeModal,
      closeSaveWarning,
      completeSave,
      configWidgetImpl,
      currentWidgetVersion,
      initiateSave,
      unsavedChangesExist,
      updateWidget,
      widgetConfigRefId
    ]
  );

  const onSaveInitiate = useCallback(() => {
    if (!saveInProgress) {
      if (disableUpdate) {
        onPerformSave(true);
      } else {
        if (unsavedChangesExist && numOpConfigs) {
          openSaveWarning();
        } else {
          onPerformSave(false);
        }
      }
    }
  }, [disableUpdate, numOpConfigs, onPerformSave, openSaveWarning, saveInProgress, unsavedChangesExist]);

  const onSaveTitleChange = useCallback(
    (nTitle: string) => {
      configWidgetImpl.title = nTitle;
      setTitle(nTitle);
    },
    [configWidgetImpl]
  );

  const { queryConfig } = configWidgetImpl;

  const clWidgetResponseDTO = useMemo(() => {
    const widgetResponseDTO = CatalogWidgetUtils.getWidgetResponseDTO("", "", "", queryConfig, [], "", "");
    const clWidgetResponseDTO = cloneDeep(widgetResponseDTO);
    // Remove the widgetId reference since all the edits should make adhoc queries and not saved queries
    clWidgetResponseDTO.widgetId = "";

    return clWidgetResponseDTO;
  }, [queryConfig]);

  const compareWidgetConfigDto = useMemo(() => {
    const widgetResponseDTO = CatalogWidgetUtils.getWidgetResponseDTO(
      "",
      "",
      "",
      catalogWidget.queryConfig,
      [],
      "",
      ""
    );
    return widgetResponseDTO?.widgetConfig;
  }, [catalogWidget.queryConfig]);

  const breadCrumbsJsx = useMemo(
    () => (
      <VerticallyCenteredRow className="flex-gap-10">
        <VerticallyCenteredRow
          className="inc-text-body-medium inc-cursor-pointer"
          onClick={onMinimize}
        >
          {dbImpl.name}
        </VerticallyCenteredRow>

        <IncFaIcon iconName="angle-right" />

        <EditableLabelInput
          change={setTitle}
          editingDisabled={false}
          initialValue={title}
          save={onSaveTitleChange}
        />
      </VerticallyCenteredRow>
    ),
    [dbImpl.name, onMinimize, onSaveTitleChange, title]
  );

  const onSwitchMode = useCallback(() => {
    if ((unsavedChangesExist || unsavedPropertiesExist) && isEditMode) {
      openQuitWarning();
    } else {
      setConfigWidgetImpl(cloneDeep(vizWidgetImpl));
      toggleEditMode();
    }
  }, [isEditMode, openQuitWarning, toggleEditMode, unsavedChangesExist, unsavedPropertiesExist, vizWidgetImpl]);

  const getSaveButtonJSX = useCallback(
    (isValid: boolean, errorMessage: string) => {
      const canSave = isValid && !errorMessage;

      return (
        <>
          {isEditMode && (
            <VerticallyCenteredRow className="flex-gap-10 marginTp12">
              <>
                {Boolean(error) && (
                  <>
                    <IncFaIcon
                      className="status-danger"
                      iconName="exclamation-triangle"
                    />
                    <span className="status-danger inc-text-subtext-medium">{error}</span>
                  </>
                )}

                {!canSave && (
                  <IncToolTip
                    placement="top"
                    titleText={errorMessage}
                    variant="error"
                  >
                    <VerticallyCenteredRow>
                      <IncFaIcon
                        className="status-danger"
                        iconName="exclamation-triangle"
                      />
                    </VerticallyCenteredRow>
                  </IncToolTip>
                )}

                <IncButton
                  color="primary"
                  disabled={!canSave || saveInProgress}
                  onClick={onSaveInitiate}
                >
                  {!saveInProgress && (disableUpdate ? "Save as" : "Save")}
                  {saveInProgress && <LoadingSpinner titleText="Saving..." />}
                </IncButton>
              </>
            </VerticallyCenteredRow>
          )}
        </>
      );
    },
    [disableUpdate, error, isEditMode, onSaveInitiate, saveInProgress]
  );

  const actions = useMemo<IncModalProps["actions"]>(
    () => ({
      preJSX: (
        <VerticallyCenteredRow className="flex-gap-12">
          {!saveInProgress && (
            <>
              <IncButton
                color="secondary-red"
                onClick={() => onPerformSave(false)}
              >
                Save & Override
              </IncButton>

              <IncButton
                color="primary"
                onClick={() => onPerformSave(true)}
              >
                Save as New
              </IncButton>

              <IncButton
                color="secondary-blue"
                onClick={() => closeModal()}
              >
                Cancel
              </IncButton>
            </>
          )}

          {saveInProgress && (
            <LoadingSpinner
              className="inc-tetx-subtext-medium text-info"
              titleText="Saving..."
            />
          )}
        </VerticallyCenteredRow>
      )
    }),
    [closeModal, onPerformSave, saveInProgress]
  );

  const hideSliceSelection = Boolean(appConfig.anomShareId);

  return (
    <div className="catalog-widget-maximize">
      <VerticallyCenteredRow className="catalog-widget-maximize--toolbar">
        {breadCrumbsJsx}

        <VerticallyCenteredRow className="flex-gap-8 marginLtAuto">
          {canEdit && (
            <>
              <IncButton
                color="secondary-blue"
                iconType="iconText"
                onClick={onSwitchMode}
              >
                {isEditMode && (
                  <>
                    <IncFaIcon
                      iconName="chevron-left"
                      style={{ height: 12 }}
                    />
                    Back
                  </>
                )}

                {!isEditMode && (
                  <>
                    <IncFaIcon
                      iconName="edit"
                      style={{ height: 12 }}
                    />
                    Edit
                  </>
                )}
              </IncButton>
            </>
          )}

          {!canEdit && (
            <IncButton
              color="secondary-blue"
              iconType="iconText"
              onClick={onMinimize}
            >
              <IncFaIcon
                iconName="chevron-left"
                style={{ height: 12 }}
              />
              Back
            </IncButton>
          )}
        </VerticallyCenteredRow>
      </VerticallyCenteredRow>
      <div className="catalog-widget-maximize--content">
        {!isEditMode && (
          <VisualizationTab
            dbImpl={dbImpl}
            eventTypeToFieldsMap={eventTypeToFieldsMap}
            hideSliceSelection={hideSliceSelection}
            onWidgetImplChange={onVizImplUpdate}
            variableSrvMap={variableSrvMap}
            widgetImpl={vizWidgetImpl}
            widgetResponseDTO={clWidgetResponseDTO}
          />
        )}

        {isEditMode && (
          <WidgetConfiguration
            compareWidgetConfigDto={compareWidgetConfigDto}
            dbImpl={dbImpl}
            getSaveButtonJSX={getSaveButtonJSX}
            onUnsavedChangesExist={setUnsavedChangesExist}
            onWidgetImplChange={setConfigWidgetImpl}
            variableSrvMap={variableSrvMap}
            widgetImpl={configWidgetImpl}
            widgetResponseDTO={clWidgetResponseDTO}
          />
        )}
      </div>

      {isQuitWarningOpen && (
        <IncInModalConfirmation
          message="Unsaved changes exist. Do you really want to quit?"
          onCancel={closeQuitWarning}
          onConfirm={() => {
            toggleEditMode();
            closeQuitWarning();
          }}
        />
      )}

      {isSaveWarningOpen && (
        <IncModal
          actions={actions}
          onClose={closeSaveWarning}
          show
          showClose={!saveInProgress}
          size="md"
          titleText="Confirm Widget Changes"
          withActionsBorder
          withTitleBorder
        >
          <div className="inc-flex-column width-100 inc-flex-center-horizontal flex-gap-24 padding16">
            <VerticallyCenteredRow>
              This widget has operationalize attached. How would you like to proceed?
            </VerticallyCenteredRow>

            <div className="inc-flex-column flex-gap-8">
              <VerticallyCenteredRow className="inc-text-subtext-medium">
                Save & Override - This will save your changes and replace the existing operationalize.
              </VerticallyCenteredRow>
              <VerticallyCenteredRow className="inc-text-subtext-medium">
                Save As New - This will create a new widget with your changes, leaving the existing operationalize
                untouched.
              </VerticallyCenteredRow>
            </div>

            <VerticallyCenteredRow className="inc-text-body-medium status-warning flex-gap-10">
              <IncFaIcon
                className="status-warning"
                iconName="exclamation-triangle"
              />
              {"Remember, changes are permanent once saved"}
            </VerticallyCenteredRow>
          </div>
        </IncModal>
      )}
    </div>
  );
});

const getModifiedWidgetImpl = (widgetImpl: CatalogWidgetImpl) => {
  const clImpl = cloneDeep(widgetImpl);
  clImpl.id = clImpl.id.endsWith("-viz-clone") ? clImpl.id : `${clImpl.id}-viz-clone`;
  clImpl.renderMode = "viz-only";
  clImpl.meta = {
    compare: false,
    edit: false,
    hideActions: true,
    hideHeader: true,
    resizable: false
  };

  return clImpl;
};
