import { IncButton, IncInModalConfirmation } from "@inception/ui";
import { cloneDeep } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { toString } from "cronstrue";
import { generateId, logger, useAccessPrivilege, useNotifications, useToggleState } from "../../../core";
import {
  CompareOperator,
  DraftOpSimulationRequest,
  Op10zeSetupResponse,
  OpCreationConfig,
  OpCreationConfigDef,
  operationaliseV2ApiService,
  OpStartTrigger,
  SavedOpSimulationRequest,
  SuppressionConfigDef
} from "../../../services/api/operationalise";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import {
  setIsDraft,
  setOp10zeId,
  setOpMode,
  setPrimaryOpCreationConfig,
  setReadOnly,
  setSaveOpCreationConfig,
  setSimulationConfig,
  setSimulations,
  setStartTrigger,
  setSuppressions,
  useOpStore
} from "../../context";
import { OpContext, SimulationConfig } from "../../context/types";
import { OpPreview } from "../preview";
import { OpSimulation } from "../simulation";
import {
  PRIMARY_CONFIG_AS_SIMULATION_KEY,
  SCHEDULE_TYPES,
  SIMULATION_SCHEDULE_KEY,
  UI_SCHEDULE_KEY
} from "../../constants";
import { getMillisFromTimeObj, noOp } from "../../../utils";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../components";
import {
  TriggersEditorWrapper,
  SuppressionsEditorWrapper,
  ThresholdEditorWrapper,
  ScheduleAndConfigEditorWrapper
} from "../editors";
import { getCronExpressionForSpecificSchedule, validateOpConfig } from "../../utils";
import { OP_ACTION_ERROR_PREFIX } from "../../types";
import { getMetricNameFromBizDataQuery } from "../editors/common";
import { ResponseStatus } from "../../../services/api/explore";
import { getRollingFreqFromSchedule } from "../../context/reducerUtils";
import OpAnalysisEditorWrapper from "./OpAnalysisEditorWrapper";

interface Props {
  skipSaveOrUpdate?: boolean;
  onSaveOrUpdate: (opConfigId: string, opCreationConfig: OpCreationConfig) => void;
  onSimulate: (opConfigId: string) => void;
  setSaveOrUpdateInProgress: (inProgress: boolean) => void;

  monitorBtnLabel?: string;
}

export const OpV3Editor: FC<Props> = props => {
  const {
    setSaveOrUpdateInProgress: pSetSaveOrUpdateInProgress,
    onSaveOrUpdate: pOnSaveOrUpdate,
    onSimulate: pOnSimulate,
    skipSaveOrUpdate,
    monitorBtnLabel
  } = props;

  const { notifyError } = useNotifications();

  const { state, dispatch } = useOpStore();
  const {
    opCreationConfig,
    context,
    op10zeId,
    selectedSimulation,
    simulationConfigs,
    isDraft,
    readOnly,
    primaryOpCreationConfig,
    sourceTypeInfoByCategory
  } = state;

  const { canEdit } = useAccessPrivilege();

  const primarySimulations = useMemo(() => primaryOpCreationConfig?.simulations || [], [primaryOpCreationConfig]);

  const isPrimaryConditionEdit = selectedSimulation.simulationId === PRIMARY_CONFIG_AS_SIMULATION_KEY;

  const { uiIntegrationActionConfigsMap, widgetId, ignoreActionConnectionErrors, mode } = context;
  const isCreateMode = mode === "create";

  const [saveOrUpdateInProgress, setSaveOrUpdateInProgress] = useState(false);
  const [saveOrUpdateError, setSaveOrUpdateError] = useState("");

  const { opCreationConfigDef, outlierConfig, opAnalysisConfig } = opCreationConfig;
  const {
    bizDataQuery: opBizDataQuery,
    startTrigger: defStartTrigger,
    schedule: opConfigDefSchedule,
    triggerCondition,
    suppression: suppressions
  } = opCreationConfigDef || {};
  const { bizDataQuery: outlierOpBizDataQuery, schedule: outlierSchedule } = outlierConfig || {};
  const { schedule: opAnalysisSchedule } = opAnalysisConfig || {};
  const opSchedule = useMemo(
    () => opConfigDefSchedule || outlierSchedule || opAnalysisSchedule,
    [opAnalysisSchedule, opConfigDefSchedule, outlierSchedule]
  );
  const { bizDataQuery: outlierBizDataQuery } = outlierOpBizDataQuery || {};
  const bizDataQuery = useMemo(() => opBizDataQuery || outlierBizDataQuery, [opBizDataQuery, outlierBizDataQuery]);

  const startTrigger = useMemo(
    () =>
      defStartTrigger || {
        allMatchStartTrigger: {
          triggerConditions: triggerCondition?.startTriggerCondition
            ? [
                {
                  windowTrigger: triggerCondition?.startTriggerCondition
                }
              ]
            : []
        }
      },
    [defStartTrigger, triggerCondition]
  );

  const buildingBlockDef = bizDataQuery?.buildingBlockConfig?.buildingBlockDef;
  const isEventOperationalize = !bizDataQuery?.buildingBlockConfig ? false : !buildingBlockDef?.aggregator;

  const { close: closeConfirmation, isOpen: isConfirmationOpen, open: openConfirmation } = useToggleState();

  useEffect(() => {
    pSetSaveOrUpdateInProgress(saveOrUpdateInProgress);
  }, [pSetSaveOrUpdateInProgress, saveOrUpdateInProgress]);

  useEffect(() => {
    if (saveOrUpdateError) {
      notifyError(saveOrUpdateError);
    }
  }, [notifyError, saveOrUpdateError]);

  const { isOpen: showPreview, close: closePreview, open: openPreview } = useToggleState(true);

  const { errors: opConfigErrors, isValid: isValidOpConfig } = useMemo(
    () =>
      validateOpConfig(
        opCreationConfig,
        uiIntegrationActionConfigsMap,
        sourceTypeInfoByCategory,
        true,
        ignoreActionConnectionErrors
      ),
    [ignoreActionConnectionErrors, opCreationConfig, sourceTypeInfoByCategory, uiIntegrationActionConfigsMap]
  );

  const { previewError, error } = useMemo(() => {
    const errorKeys = Object.keys(opConfigErrors).filter(key => !key.startsWith(OP_ACTION_ERROR_PREFIX));
    return {
      previewError: errorKeys.map(key => opConfigErrors[key]).join("\n"),
      error: Object.values(opConfigErrors).join("\n")
    };
  }, [opConfigErrors]);

  const onSuppressionsChange = useCallback(
    (suppressions: SuppressionConfigDef[]) => {
      dispatch(setSuppressions(suppressions));
    },
    [dispatch]
  );

  const onTriggerChange = useCallback(
    (trigger: OpStartTrigger) => {
      dispatch(setStartTrigger(trigger));
    },
    [dispatch]
  );

  const onSimulate = useCallback(
    async (startTimeMillis: number, endTimeMillis: number, trLabel: string, executeActions: boolean) => {
      setSaveOrUpdateInProgress(true);
      setSaveOrUpdateError("");

      const simulationId = generateId();
      const isDraft = !op10zeId;

      const { description, name } = selectedSimulation;
      const saveOpCreationConfig: OpCreationConfig = cloneDeep(opCreationConfig);
      const scheduleConfig =
        saveOpCreationConfig.opCreationConfigDef?.schedule ||
        saveOpCreationConfig.outlierConfig?.schedule ||
        saveOpCreationConfig.opAnalysisConfig?.schedule;
      if (scheduleConfig && scheduleConfig?.schedule) {
        scheduleConfig.schedule.startTimeEpochSecs = timeRangeUtils.getSecondsFromMillis(startTimeMillis);
        scheduleConfig.schedule.endTimeEpochSecs = timeRangeUtils.getSecondsFromMillis(endTimeMillis);
        scheduleConfig.labels = {
          ...(scheduleConfig.labels || {}),
          [SIMULATION_SCHEDULE_KEY]: trLabel || ""
        };
      }

      let simulationRequest: DraftOpSimulationRequest | SavedOpSimulationRequest;

      // If draft opCreationConfig or saved opCreationConfig doesn't exist
      if (!op10zeId) {
        simulationRequest = {
          description,
          name,
          isDraftOp: true,
          draftOpSetupRequest: {
            widgetId,
            opCreationConfig: saveOpCreationConfig
          },
          executeActions
        };

        logger.info("OpV3Editor", "Creating simulation with draft request", simulationRequest);
      } else {
        simulationRequest = {
          description,
          name,
          opId: op10zeId,
          simulationOpConfig: saveOpCreationConfig,
          executeActions
        };

        logger.info("OpV3Editor", "Creating simulation with saved request", simulationRequest);
      }

      const { data, error, message } = await operationaliseV2ApiService.runSimulation(simulationId, simulationRequest);
      if (error) {
        setSaveOrUpdateError("Error creating simulation");
        logger.error("OpV3Editor", "Error creating simulation with draft request", message);
      } else {
        const { opId, simulationId } = data;
        const nSelectedSimulation: SimulationConfig = {
          ...selectedSimulation,
          opCreationConfig: saveOpCreationConfig,
          simulationId
        };

        const nSimulationConfigs = [...simulationConfigs];
        nSimulationConfigs.push(nSelectedSimulation);

        dispatch(setSimulationConfig(nSelectedSimulation));
        dispatch(setOp10zeId(opId));
        dispatch(setSimulations(nSimulationConfigs));
        dispatch(setReadOnly(true));
        dispatch(setIsDraft(isDraft));

        dispatch(setSaveOpCreationConfig(cloneDeep(saveOpCreationConfig)));
        dispatch(setOpMode("edit"));

        if (pOnSimulate) {
          pOnSimulate(opId);
        }
      }

      setSaveOrUpdateInProgress(false);
    },
    [dispatch, op10zeId, opCreationConfig, pOnSimulate, selectedSimulation, simulationConfigs, widgetId]
  );

  const onSaveOrUpdatePrimaryConfig = useCallback(
    (data: Op10zeSetupResponse) => {
      const { op10zeId, opCreationConfig } = data;
      pOnSaveOrUpdate(op10zeId, opCreationConfig);

      dispatch(setPrimaryOpCreationConfig(opCreationConfig));
      dispatch(setSaveOpCreationConfig(cloneDeep(opCreationConfig)));
      dispatch(
        setSimulationConfig({
          createdBy: opCreationConfig.lastUpdatedBy || opCreationConfig.createdBy,
          description: opCreationConfig.description,
          isPrimary: true,
          labels: {
            isPrimary: "true"
          },
          name: opCreationConfig.name,
          opCreationConfig,
          simulationId: PRIMARY_CONFIG_AS_SIMULATION_KEY
        })
      );
      dispatch(setOp10zeId(op10zeId));
      dispatch(setIsDraft(false));
      dispatch(setReadOnly(true));
      dispatch(setOpMode("edit"));
    },
    [dispatch, pOnSaveOrUpdate]
  );

  const onCancelOverride = useCallback(() => {
    closeConfirmation();
  }, [closeConfirmation]);

  const onConfirmOverride = useCallback(async () => {
    closeConfirmation();
    setSaveOrUpdateInProgress(true);
    const saveOpCreationConfig = cloneDeep(opCreationConfig);
    saveOpCreationConfig.isDraft = false;

    const { data, error, message } = await operationaliseV2ApiService.editOpConfig(
      saveOpCreationConfig,
      op10zeId,
      widgetId
    );
    if (error) {
      setSaveOrUpdateError("Error editing configuration");
      logger.error("OpV3Editor", "Error editing operationalization", message);
    } else {
      onSaveOrUpdatePrimaryConfig(data);
    }
    setSaveOrUpdateInProgress(false);
  }, [closeConfirmation, onSaveOrUpdatePrimaryConfig, op10zeId, opCreationConfig, widgetId]);

  const onMonitor = useCallback(
    async (startTimeMillis?: number, endTimeMillis?: number, trLabel?: string, shouldUpdateTimeFrame = false) => {
      if (!op10zeId) {
        setSaveOrUpdateInProgress(true);
        setSaveOrUpdateError("");

        const saveOpCreationConfig = cloneDeep(opCreationConfig);
        saveOpCreationConfig.isDraft = false;
        saveOpCreationConfig.simulations = primarySimulations;

        if (shouldUpdateTimeFrame) {
          const scheduleConfig =
            saveOpCreationConfig.opCreationConfigDef?.schedule ||
            saveOpCreationConfig.outlierConfig?.schedule ||
            saveOpCreationConfig.opAnalysisConfig?.schedule;
          if (scheduleConfig && scheduleConfig?.schedule) {
            scheduleConfig.schedule.endTimeEpochSecs =
              endTimeMillis && timeRangeUtils.getSecondsFromMillis(endTimeMillis);
            scheduleConfig.schedule.startTimeEpochSecs =
              startTimeMillis && timeRangeUtils.getSecondsFromMillis(startTimeMillis);
            scheduleConfig.labels = {
              ...(saveOpCreationConfig.opCreationConfigDef?.schedule.labels || {}),
              [SIMULATION_SCHEDULE_KEY]: trLabel
            };
          }
        }

        if (skipSaveOrUpdate) {
          onSaveOrUpdatePrimaryConfig({
            op10zeId: "",
            opCreationConfig: saveOpCreationConfig,
            responseInfo: {
              errors: [],
              ignoredProps: {},
              status: ResponseStatus.SUCCESS
            },
            statusCode: 200
          });
        } else {
          const { data, error, message } = await operationaliseV2ApiService.createOpConfig(
            saveOpCreationConfig,
            widgetId
          );
          if (error) {
            setSaveOrUpdateError("Error saving configuration");
            logger.error("OpV3Editor", "Error creating operationalization", message);
          } else {
            onSaveOrUpdatePrimaryConfig(data);
          }
        }

        setSaveOrUpdateInProgress(false);
      } else if (isDraft) {
        onConfirmOverride();
      } else {
        openConfirmation();
      }
    },
    [
      isDraft,
      onConfirmOverride,
      onSaveOrUpdatePrimaryConfig,
      op10zeId,
      opCreationConfig,
      openConfirmation,
      primarySimulations,
      skipSaveOrUpdate,
      widgetId
    ]
  );

  const suppressionsPickerContext = useMemo<OpContext>(
    () => ({
      ...context,
      scheduleSelectionContext: "fieldOrMetric",
      selectionContext: "field"
    }),
    [context]
  );

  const { eventTypeName } = context;
  const metricName = useMemo(
    () => getMetricNameFromBizDataQuery(bizDataQuery, eventTypeName),
    [bizDataQuery, eventTypeName]
  );
  const shouldShowOpAnalysisConfig = Boolean(opAnalysisConfig);

  const { comparatorText, scheduleText, altComparatorText } = useMemo(
    () => getComparatorAndScheduleText(opCreationConfigDef),
    [opCreationConfigDef]
  );

  const getPredefinedMetrics = useCallback(async () => {
    try {
      const { data, error } = await operationaliseV2ApiService.getDefaultOpTriageConfig(opCreationConfig);
      if (error) {
        logger.error("SuppressionEditorWrapper", "Error fetching impacted widgets");
        return [];
      }
      const { impactedWidgets } = data || {};
      return impactedWidgets?.impactedWidgets || [];
    } catch (error) {
      logger.error("SuppressionEditorWrapper", "Error fetching impacted widgets", error);
      return [];
    }
  }, [opCreationConfig]);

  const compareTimeSeconds = useMemo(() => {
    if (!opSchedule) {
      return 0;
    }
    const freq = getRollingFreqFromSchedule(opSchedule);
    const millis = getMillisFromTimeObj(freq);
    const secs = timeRangeUtils.getSecondsFromMillis(millis);
    return secs;
  }, [opSchedule]);

  return (
    <div
      className="op-config-editor-v2-wrapper"
      data-readonly={readOnly}
    >
      {!readOnly && (
        <VerticallyCenteredRow className="flex-gap-12">
          {isPrimaryConditionEdit && canEdit && (
            <IncButton
              className="marginLtAuto"
              color="secondary-green"
              onClick={() => onMonitor()}
            >
              Update Primary Condition
            </IncButton>
          )}
        </VerticallyCenteredRow>
      )}
      {!!shouldShowOpAnalysisConfig && <OpAnalysisEditorWrapper />}
      {!shouldShowOpAnalysisConfig && (
        <>
          <ScheduleAndConfigEditorWrapper showWhatsNewConfig={Boolean(opCreationConfigDef)} />
          {Boolean(opCreationConfigDef) && <ThresholdEditorWrapper />}

          {!isEventOperationalize && !readOnly && (
            <OpPreview
              error={previewError}
              onClose={closePreview}
              onOpen={openPreview}
              show={showPreview}
            />
          )}

          {!isEventOperationalize && Boolean(startTrigger) && Boolean(opCreationConfigDef) && (
            <TriggersEditorWrapper
              altComparatorText={altComparatorText}
              comparatorText={comparatorText}
              metricName={metricName}
              onChange={onTriggerChange}
              readOnly={readOnly}
              scheduleText={scheduleText}
              startTrigger={startTrigger}
            />
          )}
          {Boolean(opCreationConfigDef) && (
            <SuppressionsEditorWrapper
              bizDataQuery={bizDataQuery}
              compareTimeSeconds={compareTimeSeconds}
              getPredefinedMetrics={getPredefinedMetrics}
              onChange={onSuppressionsChange}
              pickerContext={suppressionsPickerContext}
              readOnly={readOnly}
              suppressions={suppressions || []}
            />
          )}
        </>
      )}
      {!isPrimaryConditionEdit && !readOnly && (
        <OpSimulation
          allowSimulationOnly={!isPrimaryConditionEdit && !isCreateMode}
          canMonitorOrSimulate={isValidOpConfig}
          error={error}
          monitorBtnLabel={monitorBtnLabel}
          onMonitor={onMonitor}
          onSimulate={onSimulate}
          saveOrUpdateInProgress={saveOrUpdateInProgress}
        />
      )}

      {isConfirmationOpen && (
        <IncInModalConfirmation
          message="This will override existing configuration. Are you sure you want to proceed?"
          onCancel={onCancelOverride}
          onConfirm={onConfirmOverride}
        />
      )}

      {isPrimaryConditionEdit && saveOrUpdateInProgress && (
        <IncInModalConfirmation
          message=""
          noOperation
          onCancel={noOp}
          onConfirm={noOp}
        >
          <LoadingSpinner titleText="Updating configuration..." />
        </IncInModalConfirmation>
      )}
    </div>
  );
};

const getComparatorAndScheduleText = (opCreationConfigDef: OpCreationConfigDef) => {
  let comparatorText = "";
  let altComparatorText = "";
  let scheduleText = "";

  if (opCreationConfigDef) {
    const { schedule, threshold } = opCreationConfigDef;

    const comparator = threshold?.comparator;
    comparatorText =
      comparator === CompareOperator.Above
        ? "spike"
        : comparator === CompareOperator.Below
          ? "drop"
          : (comparatorText = comparator === CompareOperator.AboveOrBelow ? "spike/drop" : "");

    altComparatorText =
      comparator === CompareOperator.Above
        ? "more"
        : comparator === CompareOperator.Below
          ? "less"
          : comparator === CompareOperator.AboveOrBelow
            ? "more/less"
            : "";

    const { schedule: scheduleObj, labels } = schedule || {};

    const uiSchedule = labels?.[UI_SCHEDULE_KEY];
    const isContinuousSchedule = uiSchedule === SCHEDULE_TYPES.everyMinute;
    if (isContinuousSchedule) {
      scheduleText = "";
    } else if (uiSchedule) {
      scheduleText = uiSchedule === "custom" ? "" : uiSchedule;
    } else if (scheduleObj?.specificScheduleConfig) {
      const cronExpr = getCronExpressionForSpecificSchedule(scheduleObj?.specificScheduleConfig);
      scheduleText = cronExpr ? toString(cronExpr).toLowerCase().replaceAll("every", "") : "";
    }
  }

  return {
    comparatorText,
    altComparatorText,
    scheduleText
  };
};
