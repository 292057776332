import React, { FC, useMemo } from "react";
import { BarColumnChart } from "../../../../../components/charts/common";
import BarColumnChartOptionsBuilder from "../../../../../components/bar-chart/BarColumnChartOptionsBuilder";
import { HCBarSeries } from "../../../../../components/bar-chart/types";
import { ChartPoint } from "../../../TimeSeries/models/model";
import { BarChartHelper } from "../../../BarChart/BarChartHelper";
import { EntityWidgetData } from "../../../../../biz-entity";
import { DataFrame } from "../../../../../core";
import { USFWRendererProps } from "./types";
import { getUSFieldChartColor, getSerieName, getFieldsFromLabels } from "./utils";
import { useCommonRendererFunctionality } from "./common";

export const BarChartRenderer: FC<USFWRendererProps> = props => {
  const { className, dataType, loadingElement, noDataElement, aggregatedTags, displayAggregatedTags } = props;

  const { data, fieldName, isFetching, isError, dataExists, metricName } = useCommonRendererFunctionality(props);

  const optionsBuilder = useMemo(
    () => new BarColumnChartOptionsBuilder({ barDimension: 40 }).setLayout("vertical"),
    []
  );
  const series = useMemo(
    () => constructBarSeries(data, aggregatedTags, displayAggregatedTags, dataType, fieldName, metricName),
    [aggregatedTags, data, dataType, displayAggregatedTags, fieldName, metricName]
  );

  if (isFetching) {
    return loadingElement;
  }

  if (!dataExists || isError) {
    return noDataElement;
  }

  return (
    <BarColumnChart
      containerElemClass={className}
      optionsBuilder={optionsBuilder}
      series={series}
      showLegend={false}
      title=""
    />
  );
};

export const constructBarSeries = (
  data: EntityWidgetData[],
  aggregatedTags: string[],
  displayAggregatedTags: string[],
  dataType: USFWRendererProps["dataType"],
  fieldName: string,
  metricName: string
) => {
  const dataframes: DataFrame[] = [];
  const compareDataFrames: DataFrame[] = [];

  data.forEach(datum => {
    const postAggData = datum.postAggResult.data;
    const compareData = datum.postAggResult.timeShiftData || {};

    const refId = Object.keys(postAggData)[0];

    const dfs = postAggData[refId]?.data || [];
    const cDfs = compareData[refId]?.data || [];

    dataframes.push(...dfs);
    compareDataFrames.push(...cDfs);
  });

  const shouldModifyMetricName = data.length < 2;

  const negateColors = fieldName.toLowerCase().includes("error");

  const barSeries: HCBarSeries[] = [];

  dataframes.forEach((df, i) => {
    const { fields, labels, eLabels, id, name } = df;

    const cFields = getFieldsFromLabels(compareDataFrames, labels);

    const timestamps = fields[0].data;
    const values = fields[1].data;

    const datapoints = values.map((value, idx) => {
      const ts = timestamps[idx];
      return [ts, value] as ChartPoint;
    });
    const data = values[0];
    const compareData = cFields?.[1]?.data?.[0] || null;

    const { aggLabel } = shouldModifyMetricName
      ? getSerieName(aggregatedTags, displayAggregatedTags, labels, eLabels, metricName)
      : { aggLabel: name };

    barSeries.push({
      aggLabel,
      name: aggLabel,
      aggTags: aggregatedTags,
      data,
      datapoints,
      refId: id,
      tagNameMap: labels,
      color: getUSFieldChartColor(dataType, aggLabel, i, negateColors),
      custom: {
        compareData
      }
    });
  });

  const sortedBarSeries = barSeries.sort((a, b) => b.data - a.data);
  const barOptions = new BarChartHelper(sortedBarSeries, "vertical").getSeries(null);

  return barOptions;
};
