import { PrimKindDetails } from "./types";

export const PrimKindMap: Record<string, PrimKindDetails> = {
  STRING: {
    kind: "_str",
    kindDescriptor: null
  },
  BOOL: {
    kind: "_bool",
    kindDescriptor: null
  },
  LONG: {
    kind: "_long",
    kindDescriptor: null
  },
  DOUBLE: {
    kind: "_double",
    kindDescriptor: null
  },
  DATE: {
    kind: "_date",
    kindDescriptor: null
  },
  DATETIME: {
    kind: "_datetime",
    kindDescriptor: null
  },
  SET: {
    kind: "_set",
    kindDescriptor: null
  },
  MAP: {
    kind: "_map",
    kindDescriptor: null
  },
  OBJECT_MAP: {
    kind: "_objectmap",
    kindDescriptor: null
  },
  CURRENCY: {
    kind: "_long",
    kindDescriptor: {
      type: "currency",
      customTypeName: ""
    }
  },
  EMAIL: {
    kind: "_str",
    kindDescriptor: {
      type: "email",
      customTypeName: ""
    }
  },
  URL: {
    kind: "_str",
    kindDescriptor: {
      type: "url",
      customTypeName: ""
    }
  },
  IPADDRESS: {
    kind: "_str",
    kindDescriptor: {
      type: "ipAddress",
      customTypeName: ""
    }
  }
};
