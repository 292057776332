import { UserServiceFieldDef } from "../../../../../platform/services/api/event-mapping";
import { SelectorGridFieldDef } from "./types";

export function getUserServiceFieldDef(rowData: SelectorGridFieldDef): UserServiceFieldDef {
  if (!rowData) {
    return null;
  }
  // eslint-disable-next-line
  const { selected, sampleVals, ...usFieldDef } = rowData;
  return usFieldDef;
}
