import { IncTextArea } from "@inception/ui";
import { capitalize, debounce, isNull } from "lodash";
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { WidgetTextAreaProperty } from "../types";

interface Props {
  property: WidgetTextAreaProperty;
  onUpdate: (newState: WidgetTextAreaProperty) => void;
}

const TextAreaPropertyRenderer: FC<Props> = props => {
  const { onUpdate: usrUpdate, property } = props;

  const {
    name,
    value: usrValue,
    label = capitalize(name),
    updateOnBlur = true,
    placeholder = capitalize(name),
    resize = false,
    rows = 1,
    disabled = false,
    info = ""
  } = property;

  const [value, setValue] = useState<string>(null);

  const onUpdate = useMemo(
    () =>
      debounce((property: WidgetTextAreaProperty, newValue: string) => {
        const newProperty: WidgetTextAreaProperty = {
          ...property,
          value: newValue
        };
        usrUpdate(newProperty);
      }, 500),
    [usrUpdate]
  );

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value || "";
    setValue(newValue);
    if (!updateOnBlur) {
      onUpdate(property, newValue);
    }
  };

  const noOp = useCallback(() => {}, []);
  const onBlur = updateOnBlur ? () => onUpdate(property, value) : noOp;

  useEffect(() => {
    setValue(usrValue as string);
  }, [usrValue]);

  return (
    !isNull(value) && (
      <IncTextArea
        disabled={disabled}
        helpText={info}
        label={label}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        resize={resize}
        rows={rows}
        value={value}
      />
    )
  );
};

export default TextAreaPropertyRenderer;
