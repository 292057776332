import { cloneDeep, defaults, isEqual } from "lodash";
import { ScopedVars } from "../../services/api/types";
import customVariableResolver, {
  VariableResolverResult
} from "../dashboard-variables/resolvers/CustomVariableResolver";
import { CustomVariableModel, VariableType } from "../models/VariableModel";
import { TimeRange } from "../../core";
import VariableImpl from "./VariableImpl";

const DEFAULTS: CustomVariableModel = {
  id: "",
  name: "",
  multi: false,
  value: null,
  defaultValue: "",
  type: VariableType.Custom,
  allValue: "",
  includeAll: false,
  label: "",
  query: ""
};

export default class CustomVariableImpl extends VariableImpl implements CustomVariableModel {
  query: string;
  private prevScopedVars: ScopedVars;

  readonly type = VariableType.Custom;

  constructor(model: Partial<CustomVariableModel>) {
    super(model);
    this.omitProperties = [...this.omitProperties, "prevScopedVars"];
    this.assign(model);
  }
  protected assign(model: Partial<CustomVariableModel>) {
    const cvModel: Partial<CustomVariableModel> = {};
    defaults(cvModel, model, DEFAULTS);
    const { query } = cvModel;

    this.query = query;
    super.assign(cvModel);
    this.helpText = "Generate list of values from a custom string";
    this.setDependencies();
  }

  update(vModel: Partial<CustomVariableModel>) {
    this.assign(vModel);
  }

  setQuery(query: string) {
    this.query = query;
    this.setDependencies();
  }

  setDefaultValue(value: string) {
    this.defaultValue = value;
  }

  async resolveValues(tr: TimeRange, scopedVars: ScopedVars = {}): Promise<VariableResolverResult> {
    if (!isEqual(scopedVars, this.prevScopedVars)) {
      this.prevScopedVars = cloneDeep(scopedVars);
      this.optionsData = {
        data: [],
        error: ""
      };
    }
    // Cloning to avoid any changes made to the object being saved to the implementation
    if (this.optionsData.data.length) {
      return Promise.resolve(cloneDeep(this.optionsData));
    } else {
      const optionsData = await customVariableResolver.resolveValues(this.query);
      this.optionsData = optionsData;
      return cloneDeep(optionsData);
    }
  }

  getSaveModel(): CustomVariableModel {
    const model = super.getSaveModel();
    return model as CustomVariableModel;
  }

  private setDependencies() {
    this.dependencies = this.processMatches(this.query);
  }

  validate() {
    const base = super.validate();
    let hasError = false;
    const { messages } = base;

    if (!this.query) {
      hasError = true;
      messages["query"] = "Query cannot be empty";
    }

    return {
      hasError: hasError || base.hasError,
      messages
    };
  }
}
