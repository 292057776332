import { IncCheckbox, IncFaIcon } from "@inception/ui";
import { findIndex, isEqual, map, sortBy } from "lodash";
import React, { FC, useCallback, useMemo, useState } from "react";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";

export interface UIOrderedListItems {
  items: any[];
  onListChange: (result: any) => void;
  hideAction?: boolean;
  actionLabel?: string;
  actionValueProp?: string;
  onActionChange?: (e: any, checked: boolean) => void;
  itemOrder?: string[];
}

const ResponsiveGridLayout = WidthProvider(Responsive);
const CheckboxStyleProps: any = { placement: "end" };

/**
 * Ui Order list
 * @param props
 * @returns
 */
export const UIOrderedList: FC<UIOrderedListItems> = props => {
  const { items, onListChange, hideAction, actionLabel, onActionChange, actionValueProp, itemOrder } = props;

  const [checkboxState, setCheckboxState] = useState<boolean>();

  const layouts = useMemo(() => {
    if (itemOrder) {
      const layouts: Layout[] = [];
      //
      const re = sortBy(items, (item: any) => {
        const index = findIndex(itemOrder, (o: string) => o === item.id);
        return index !== -1 ? index : Number.MAX_SAFE_INTEGER;
      });
      re.forEach((it: any, index) => {
        layouts.push({
          h: 1,
          x: 0,
          y: index,
          w: 1,
          moved: false,
          i: it.id,
          static: false
        });
      });
      const qLayout = {
        lg: layouts,
        md: layouts,
        sm: layouts,
        xs: layouts,
        xxs: layouts
      };
      return qLayout;
    }
    return {};
  }, [itemOrder, items]);

  const onItemOrderChanged = useCallback(
    (layout: Layout[]) => {
      const sortedLayout = sortBy(layout, "y");
      // Rearrange the items array to match the sorted layout
      const rearrangedItems = sortedLayout
        .map(layoutItem => {
          const matchingItem = items.find(item => item.id === layoutItem.i);
          return matchingItem || null;
        })
        .filter(Boolean) as any[]; // Filter out any potential null values
      //checking the checkbox click to prevent the onListChange called
      if (onListChange && !isEqual(items, rearrangedItems) && !checkboxState) {
        onListChange(rearrangedItems);
      }
    },
    [checkboxState, items, onListChange]
  );

  const widgets = useMemo(
    () =>
      map(items, w => {
        const { id, label } = w;
        const checked = w[actionValueProp]; //fetch the value for item object
        return (
          <li
            className="list-item"
            id={`widget-${id}`}
            key={id}
          >
            <div className="d-flex justify-content-between width-100 align-items-center">
              <div>
                <span className="handle-icon inline-block list-item-handle">
                  <IncFaIcon iconName="ellipsis-v"></IncFaIcon>
                  <IncFaIcon iconName="ellipsis-v"></IncFaIcon>
                </span>
                <span className="title">{label}</span>
              </div>
              {!hideAction && (
                <div>
                  <IncCheckbox
                    checked={checked}
                    className="list-action-checkbox"
                    label={actionLabel}
                    labelProps={CheckboxStyleProps}
                    onChange={(e, checked) => {
                      onActionChange(w, checked);
                      setCheckboxState(checked);
                    }}
                  ></IncCheckbox>
                </div>
              )}
            </div>
          </li>
        );
      }),
    [actionLabel, actionValueProp, hideAction, items, onActionChange]
  );

  return (
    <>
      <ResponsiveGridLayout
        className="ordered-list"
        cols={{
          lg: 1,
          md: 1,
          sm: 1,
          xs: 1,
          xxs: 1
        }}
        compactType="vertical"
        containerPadding={[0, 0]}
        draggableHandle=".list-item-handle"
        isDraggable={true}
        isResizable={false}
        layouts={layouts}
        onLayoutChange={onItemOrderChanged}
        rowHeight={40}
        // This cannot be changed since the menu items will be hidden due to stacking context due to transform property
        useCSSTransforms={false}
      >
        {widgets}
      </ResponsiveGridLayout>
    </>
  );
};
