import React, { useCallback, useMemo, FC } from "react";
import {
  ISaxIcon,
  IncButton,
  IncEditor,
  IncInModalConfirmation,
  IncSmartText,
  IncTextfield,
  IncToolTip
} from "@inception/ui";
import { VerticallyCenteredRow } from "../../components";
import { useToggleState } from "../../core";
import { StoryTemplateStr } from "../../services/api/operationalise";
import { isJsonString } from "../../utils";
import {
  addAnalysisStoryTemplate,
  cloneAnalysisStoryTemplate,
  deleteAnalysisStoryTemplate,
  setAnalysisStoryTemplateJson,
  setAnalysisStoryTemplateName,
  useOpAnalysisStore
} from "./context";

const StoryTemplateEditor = () => {
  const { dispatch, state } = useOpAnalysisStore();
  const { opAnalysisImpl } = state;
  const templateEditorJson = useMemo(() => opAnalysisImpl.getStoryTemplates() || [], [opAnalysisImpl]);
  const onChangeTemplateJson = useCallback(
    (index: number, payload: string) => {
      dispatch(
        setAnalysisStoryTemplateJson({
          index,
          payload
        })
      );
    },
    [dispatch]
  );
  const onChangeTemplateName = useCallback(
    (index: number, payload: string) => {
      dispatch(
        setAnalysisStoryTemplateName({
          index,
          payload
        })
      );
    },
    [dispatch]
  );
  const onDeleteTemplate = useCallback(
    (index: number) => {
      dispatch(deleteAnalysisStoryTemplate(index));
    },
    [dispatch]
  );
  const onCloneTemplate = useCallback(
    (index: number) => {
      dispatch(cloneAnalysisStoryTemplate(index));
    },
    [dispatch]
  );
  const onAddTemplateJson = useCallback(() => {
    dispatch(addAnalysisStoryTemplate());
  }, [dispatch]);

  const StoryTemplateEntriesJsx = useMemo(
    () =>
      templateEditorJson.map((template, index) => {
        const onDelete = () => onDeleteTemplate(index);
        const onClone = () => onCloneTemplate(index);
        const onChangeName = (name: string) => onChangeTemplateName(index, name);
        const onChangeJson = (json: string) => onChangeTemplateJson(index, json);
        return (
          <StoryTemplateEntry
            key={index}
            onChangeJson={onChangeJson}
            onChangeName={onChangeName}
            onClone={onClone}
            onDelete={onDelete}
            template={template}
          />
        );
      }),
    [onChangeTemplateJson, onChangeTemplateName, onCloneTemplate, onDeleteTemplate, templateEditorJson]
  );

  return (
    <div
      className="inc-flex-column flex-gap-16 paddingBt24 paddingTp24"
      style={{ height: "70vh" }}
    >
      <VerticallyCenteredRow>
        <IncButton
          className={"marginLtAuto"}
          color={"primary"}
          iconName={"add"}
          onClick={onAddTemplateJson}
        >
          Add Template
        </IncButton>
      </VerticallyCenteredRow>
      {!templateEditorJson.length && (
        <VerticallyCenteredRow className="inc-flex-column">
          <IncSmartText
            className="inc-text-header"
            text="No templates added!"
          />
          <IncSmartText text="Add story templates for advance configuration" />
        </VerticallyCenteredRow>
      )}
      {!!templateEditorJson.length && (
        <div
          className="inc-flex-column flex-gap-16"
          style={wrapperStyle}
        >
          {StoryTemplateEntriesJsx}
        </div>
      )}
    </div>
  );
};

type Props = {
  template: StoryTemplateStr;
  onChangeName: (name: string) => void;
  onChangeJson: (json: string) => void;
  onDelete: () => void;
  onClone: () => void;
};

const StoryTemplateEntry: FC<Props> = props => {
  const { onChangeName: pOnChangeName, onChangeJson, template, onDelete, onClone } = props;
  const onChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      pOnChangeName(e.target.value);
    },
    [pOnChangeName]
  );
  const { name = "", templateJsonStr = "" } = template || {};
  const { isOpen, close, open } = useToggleState();
  const onDeleteConfirm = useCallback(() => {
    onDelete();
    close();
  }, [close, onDelete]);
  return (
    <div className="inc-flex-column flex-gap-12">
      {isOpen && (
        <IncInModalConfirmation
          message={"Confirm Delete?"}
          onCancel={close}
          onConfirm={onDeleteConfirm}
        />
      )}
      <VerticallyCenteredRow className="flex-gap-12">
        <IncTextfield
          errorText="Name is required"
          hasError={!name}
          label="Template Name"
          onChange={onChangeName}
          placeholder="Template Name"
          value={name}
        />
        <IncToolTip titleText="Clone">
          <ISaxIcon
            className="status-info inc-cursor-pointer marginLtAuto"
            iconName="Copy"
            onClick={onClone}
          />
        </IncToolTip>
        <IncToolTip titleText="Delete">
          <ISaxIcon
            className="status-danger inc-cursor-pointer"
            iconName="Trash"
            onClick={open}
          />
        </IncToolTip>
      </VerticallyCenteredRow>
      <IncEditor
        hasError={!isJsonString(templateJsonStr)}
        maxLines={50}
        minLines={20}
        mode="json"
        onChange={onChangeJson}
        value={templateJsonStr}
      />
    </div>
  );
};

const wrapperStyle = {
  maxHeight: "100%",
  overflow: "auto"
};

export default StoryTemplateEditor;
