import React, { FC, CSSProperties } from "react";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import EmptyIcon from "../../images/empty-state.svg";

interface Props {
  messageId: string;
  style?: CSSProperties;
  className?: string;
  hideImage?: boolean;
}

const EmptyState: FC<Props> = props => {
  const { className = "", style = {}, messageId, hideImage = false } = props;

  return (
    <div>
      {!hideImage && (
        <Image
          alt="empty-state"
          src={EmptyIcon}
        />
      )}
      <Message
        className={className}
        style={style}
      >
        <FormattedMessage id={messageId} />
      </Message>
    </div>
  );
};

const Message = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #f4f4f4;
  width: fit-content;
  margin: 0 auto;
`;

const Image = styled.img`
  display: block;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 12px;
`;

export default EmptyState;
