import { IncFaIcon, IncToolTip } from "@inception/ui";
import IncDurationSelector, {
  IncSelectorDuration
} from "@inception/ui/src/components/DurationSelector/DurationSelector";
import React, { FC, useMemo, useCallback } from "react";
import { VerticallyCenteredRow } from "../../../../components";
import { useRefState } from "../../../../core";
import { OpSchedule } from "../../../../services/api/operationalise";
import { getMillisFromTimeObj } from "../../../../utils";
import { getDurationFromTimeObj, getTimeObjFromDuration } from "../../../../utils/DurationUtils";

interface Props {
  schedule: OpSchedule;
  onScheduleChange: (schedule: OpSchedule) => void;

  readOnly: boolean;
}

export const OffsetEditor: FC<Props> = props => {
  const { onScheduleChange, schedule } = props;

  const scheduleRef = useRefState(schedule);

  const { endOffset, startOffset } = schedule;

  const onOffsetChange = useCallback(
    (nDuration: IncSelectorDuration, type: "start" | "end") => {
      const schedule = scheduleRef.current;
      const nSchedule = { ...schedule };

      const { duration, durationType } = nDuration;
      const offset = getTimeObjFromDuration(duration as number, durationType);

      if (type === "start") {
        nSchedule.startOffset = offset;
      } else {
        nSchedule.endOffset = offset;
      }

      onScheduleChange(nSchedule);
    },
    [onScheduleChange, scheduleRef]
  );

  const onStartOffsetChange = useCallback(
    (duration: IncSelectorDuration) => onOffsetChange(duration, "start"),
    [onOffsetChange]
  );
  const onEndOffsetChange = useCallback(
    (duration: IncSelectorDuration) => onOffsetChange(duration, "end"),
    [onOffsetChange]
  );

  const offsetError = useMemo(() => {
    const startOffsetMillis = getMillisFromTimeObj(startOffset);
    const endOffsetMillis = getMillisFromTimeObj(endOffset);

    if (startOffsetMillis < endOffsetMillis) {
      return "Start offset cannot be less than end offset";
    }
  }, [endOffset, startOffset]);

  const startOffsetDuration = useMemo(() => getDurationFromTimeObj(startOffset), [startOffset]);
  const endOffsetDuration = useMemo(() => getDurationFromTimeObj(endOffset), [endOffset]);

  const errComponent = useMemo(
    () => (
      <IncToolTip
        showArrow
        titleText={offsetError}
        variant="error"
      >
        <VerticallyCenteredRow className="marginLt8">
          <IncFaIcon
            className="status-danger"
            iconName="exclamation-triangle"
          />
        </VerticallyCenteredRow>
      </IncToolTip>
    ),
    [offsetError]
  );

  return (
    <>
      <IncDurationSelector
        duration={startOffsetDuration}
        hideLabel
        onChange={onStartOffsetChange}
      />

      {Boolean(offsetError) && errComponent}

      <div className="inc-text-subtext-medium inc-text-inactive marginLt8 marginRt8">ago to</div>

      <IncDurationSelector
        duration={endOffsetDuration}
        hideLabel
        onChange={onEndOffsetChange}
      />

      <div className="inc-text-subtext-medium inc-text-inactive marginLt8">ago</div>

      {Boolean(offsetError) && errComponent}
    </>
  );
};
