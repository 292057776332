import React from "react";
import { IncButton, IncClickAway, IncPopper, IncTooltipProps } from "@inception/ui";
import { CypressConstants } from "@bicycle/tests";
import FieldPickerTable, { FieldPickerTableProps } from "./FieldPickerTable";

interface FieldPickerTablePopupProps extends FieldPickerTableProps {
  anchorEl: HTMLElement;
  container?: HTMLElement;
  placement?: IncTooltipProps["placement"];
  className?: string;
  showPicker: boolean;
  offset?: {
    x?: number;
    y?: number;
  };
  closePicker: () => void;
  children?: JSX.Element | JSX.Element[];
}

const FieldPickerTablePopUp: React.FC<FieldPickerTablePopupProps> = (props: FieldPickerTablePopupProps) => {
  const { anchorEl, placement, className, showPicker, offset, closePicker, container, children, ...rest } = props;
  const { fieldPickerSaveBtn } = CypressConstants.components.FieldPickerV1.attributes;

  return (
    <IncClickAway onClickAway={closePicker}>
      {el => (
        <IncPopper
          anchorEl={anchorEl}
          className={className}
          container={container}
          offset={offset}
          placement={placement}
          ref={el}
          show={showPicker}
        >
          <div className={"field-picker-table-popver"}>
            <div className="field-picker-table-popver--table">
              {children}

              <FieldPickerTable {...rest} />
            </div>
            {rest.isMulti && (
              <div className="footer">
                <span className={"inc-text-body-medium"}>{rest.selectedOptions.length} selected</span>
                <IncButton
                  color="primary"
                  data-cy={fieldPickerSaveBtn}
                  onClick={closePicker}
                >
                  Done
                </IncButton>
              </div>
            )}
          </div>
        </IncPopper>
      )}
    </IncClickAway>
  );
};

export default FieldPickerTablePopUp;
