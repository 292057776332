import React, { useCallback, useMemo } from "react";
import { IncSmartText } from "@inception/ui";
import { VerticallyCenteredRow } from "../../components";
import { RollingFrequencyEditorV2 } from "../v3/editors/common";
import { TimeObj, TimeObjUnit } from "../../services/api/explore";
import { setAnalysisCompareToOffset, setAnalysisRollingFreq, useOpAnalysisStore } from "./context";

const CompareToAndRollingFrequencyEditorWrapper = () => {
  const { dispatch, state } = useOpAnalysisStore();
  const { opAnalysisImpl } = state;
  const rollingFreq = useMemo<TimeObj>(
    () =>
      opAnalysisImpl.getRollingFreq() || {
        unit: TimeObjUnit.weeks,
        value: 1
      },
    [opAnalysisImpl]
  );
  const compareToOffset = useMemo<TimeObj>(
    () =>
      opAnalysisImpl.getCompareToOffset() || {
        unit: TimeObjUnit.weeks,
        value: 1
      },
    [opAnalysisImpl]
  );
  const onChangeRollingFreq = useCallback(
    (rollingFreq: TimeObj) => {
      dispatch(setAnalysisRollingFreq(rollingFreq));
    },
    [dispatch]
  );
  const onChangeCompareToOffset = useCallback(
    (compareToOffset: TimeObj) => {
      dispatch(setAnalysisCompareToOffset(compareToOffset));
    },
    [dispatch]
  );

  return (
    <div className={`analysis-editor--suppression-editor`}>
      <VerticallyCenteredRow className="flex-gap-12">
        <IncSmartText text="Rolling Frequency" />
        <RollingFrequencyEditorV2
          onChange={onChangeRollingFreq}
          rollingFreq={rollingFreq}
        />
        <IncSmartText text="Compare To" />
        <RollingFrequencyEditorV2
          onChange={onChangeCompareToOffset}
          rollingFreq={compareToOffset}
        />
      </VerticallyCenteredRow>
    </div>
  );
};

export default CompareToAndRollingFrequencyEditorWrapper;
