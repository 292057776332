import { IncSelect, IncSelectOption } from "@inception/ui";
import React, { FC, useMemo, useCallback } from "react";
import { TimeObj, TimeObjUnit } from "../../../../services/api/explore";
import { OpSchedule } from "../../../../services/api/operationalise";
import { SCHEDULE_TYPES, UI_SCHEDULE_KEY } from "../../../constants";
import { RollingFrequencyEditorV2 } from "../common";
import { OffsetEditor } from "./OffsetEditor";

interface Props {
  rollingFreq: TimeObj;
  onRollingFreqChange: (freq: TimeObj) => void;

  skipRollingFrequency?: boolean;
  readOnly: boolean;
  skipPrefixForRollingFreq: boolean;

  schedule: OpSchedule;
  onScheduleChange: (schedule: OpSchedule) => void;
}

export const OffsetOrRollingFrequencyEditor: FC<Props> = props => {
  const {
    onRollingFreqChange,
    onScheduleChange,
    readOnly,
    rollingFreq,
    schedule,
    skipPrefixForRollingFreq,
    skipRollingFrequency
  } = props;

  const { startOffset, endOffset } = schedule || {};

  const options = useMemo(() => getOptions(skipRollingFrequency), [skipRollingFrequency]);

  const selectedOption = useMemo(() => {
    const isOffsetCase = startOffset && endOffset;

    if (skipRollingFrequency) {
      return isOffsetCase ? eventOffsetOption : occursOption;
    } else {
      return isOffsetCase ? offsetOption : rollingFreqOption;
    }
  }, [endOffset, skipRollingFrequency, startOffset]);

  const onScheduleOptChange = useCallback(
    (opt: IncSelectOption) => {
      const { value } = opt;

      let startOffset: TimeObj = null;
      let endOffset: TimeObj = null;

      const uiSchedule = schedule.labels?.[UI_SCHEDULE_KEY] || "everyMinute";
      let rollingFreq = getRollingFreqForSchedule(uiSchedule);

      if (value === OFFSET_OPT_VALUE) {
        endOffset = {
          ...rollingFreq,
          value: 3 * rollingFreq.value
        };

        startOffset = {
          ...endOffset,
          value: 7 * rollingFreq.value
        };

        rollingFreq = {
          ...rollingFreq,
          value: startOffset.value - endOffset.value
        };
      }

      onScheduleChange({
        ...schedule,
        startOffset,
        endOffset
      });

      if (!skipRollingFrequency) {
        onRollingFreqChange(rollingFreq);
      }
    },
    [onRollingFreqChange, onScheduleChange, schedule, skipRollingFrequency]
  );

  const onScheduleUpdate = useCallback(
    (schedule: OpSchedule) => {
      onScheduleChange(schedule);
      const { endOffset, startOffset } = schedule;

      // Assuming both start and end offsets are valid and have same units
      const rollingFreq = {
        ...endOffset,
        value: startOffset.value - endOffset.value
      };

      onRollingFreqChange(rollingFreq);
    },
    [onRollingFreqChange, onScheduleChange]
  );

  const displayOffsetEditor = selectedOption.value === OFFSET_OPT_VALUE;
  const displayRollingFreqEditor = selectedOption.value === ROLLING_FREQ_OPT_VALUE;

  return (
    <>
      {!readOnly && (
        <IncSelect
          autoAdjustWidth
          autoSort={false}
          isSearchable={false}
          onChange={onScheduleOptChange}
          options={options}
          value={selectedOption}
          wrapperClass="marginRt8 marginLt8"
        />
      )}

      {readOnly && <div className="marginRt8 marginLt8 inc-label-common">{selectedOption.label}</div>}

      {displayOffsetEditor && (
        <OffsetEditor
          onScheduleChange={onScheduleUpdate}
          readOnly={readOnly}
          schedule={schedule}
        />
      )}

      {displayRollingFreqEditor && (
        <RollingFrequencyEditorV2
          onChange={onRollingFreqChange}
          readOnly={readOnly}
          rollingFreq={rollingFreq}
          skipPrefix={skipPrefixForRollingFreq}
        />
      )}
    </>
  );
};

const ROLLING_FREQ_OPT_VALUE = "rollingFreq";
const OCCURS_OPT_VALUE = "always";
const OFFSET_OPT_VALUE = "scheduleOffset";

const rollingFreqOption: IncSelectOption = {
  label: "in the last",
  value: ROLLING_FREQ_OPT_VALUE
};

const occursOption: IncSelectOption = {
  label: "when it occurs",
  value: OCCURS_OPT_VALUE
};

const offsetOption: IncSelectOption = {
  label: "in between",
  value: OFFSET_OPT_VALUE
};

const eventOffsetOption: IncSelectOption = {
  label: "occurring in between",
  value: OFFSET_OPT_VALUE
};

const getOptions = (skipRollingFrequency: boolean) =>
  skipRollingFrequency ? [occursOption, eventOffsetOption] : [rollingFreqOption, offsetOption];

const getRollingFreqForSchedule = (uiSchedule: string): TimeObj => {
  switch (uiSchedule) {
    case SCHEDULE_TYPES.everyMinute: {
      return {
        unit: TimeObjUnit.minutes,
        value: 1
      };
    }

    case SCHEDULE_TYPES.every5Minutes: {
      return {
        unit: TimeObjUnit.minutes,
        value: 5
      };
    }

    case SCHEDULE_TYPES.every15Minutes: {
      return {
        unit: TimeObjUnit.minutes,
        value: 15
      };
    }

    case SCHEDULE_TYPES.every30Minutes: {
      return {
        unit: TimeObjUnit.minutes,
        value: 30
      };
    }

    case SCHEDULE_TYPES.everyHour: {
      return {
        unit: TimeObjUnit.hours,
        value: 1
      };
    }

    case SCHEDULE_TYPES.everyDay: {
      return {
        unit: TimeObjUnit.days,
        value: 1
      };
    }

    case SCHEDULE_TYPES.everyWeek: {
      return {
        unit: TimeObjUnit.weeks,
        value: 1
      };
    }

    case SCHEDULE_TYPES.everyMonth: {
      return {
        unit: TimeObjUnit.months,
        value: 1
      };
    }

    default: {
      return {
        unit: TimeObjUnit.minutes,
        value: 1
      };
    }
  }
};
