import React, { FC, useState, useMemo, useCallback } from "react";
import {
  IncButton,
  IncCheckbox,
  IncDateTimeFormat,
  IncFaIcon,
  IncTextfield,
  IncToolTip,
  getFormattedDateTime
} from "@inception/ui";
import { OpCreationConfig } from "../../services/api/operationalise";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { OpTimeFrame, OpTimeFrameEditor } from "../../operationalise-v2/v3/simulation";
import { useInputState, useToggleState } from "../../core";
import { VerticallyCenteredRow } from "../../components";

interface Props {
  opCreationConfig: OpCreationConfig;
  onSimulate: (startTimeMillis: number, endTimeMillis: number, trLabel: string, executeActions: boolean) => void;
  inProgress: boolean;
}

export const OpRecipeSimulation: FC<Props> = props => {
  const { inProgress, onSimulate, opCreationConfig } = props;

  const { endTimeEpochSecs, startTimeEpochSecs } =
    opCreationConfig?.opCreationConfigDef?.schedule?.schedule ||
    opCreationConfig?.trendDetectionConfigDef?.schedule?.schedule ||
    opCreationConfig?.outlierConfig?.schedule?.schedule ||
    opCreationConfig?.opAnalysisConfig?.schedule?.schedule ||
    {};

  let numStartTimeEpochSecs = parseInt(String(startTimeEpochSecs), 10);
  numStartTimeEpochSecs = Number.isNaN(numStartTimeEpochSecs) ? 0 : numStartTimeEpochSecs;

  let numEndTimeEpochSecs = parseInt(String(endTimeEpochSecs), 10);
  numEndTimeEpochSecs = Number.isNaN(numEndTimeEpochSecs) ? 0 : numEndTimeEpochSecs;

  const { inputValue: simulationName, onInputChange: onSimulationNameChange } = useInputState(
    opCreationConfig?.name || ""
  );

  const [timeFrame, setTimeFrame] = useState<OpTimeFrame>({
    from: numStartTimeEpochSecs ? String(numStartTimeEpochSecs * 1000) : "now-1w",
    to: numEndTimeEpochSecs ? String(numEndTimeEpochSecs * 1000) : "now",
    label: "",
    isCustom: Boolean(numStartTimeEpochSecs || numEndTimeEpochSecs),
    isStartingNow: false
  });

  const { from: startTimeMillis, to: endTimeMillis } = useMemo(
    () => ({
      from: timeRangeUtils.getMillis(timeFrame.from).millis,
      to: timeRangeUtils.getMillis(timeFrame.to).millis
    }),
    [timeFrame.from, timeFrame.to]
  );

  const { isOpen: executeActions, toggle: onToggleExecuteActions } = useToggleState();

  const onTriggerSimulate = useCallback(() => {
    const { from: startTimeMillis, to: endTimeMillis } = timeFrame.isStartingNow
      ? {
          from: 0,
          to: 0
        }
      : timeRangeUtils.getTimeRangeMillisFromRaw({
          from: timeFrame.from,
          to: timeFrame.to
        });

    const trLabel = timeFrame.isCustom
      ? `${getFormattedDateTime(startTimeMillis, IncDateTimeFormat.minimal)} - ${getFormattedDateTime(endTimeMillis, IncDateTimeFormat.minimal)}`
      : timeFrame.label;
    onSimulate(startTimeMillis, endTimeMillis, trLabel, executeActions);
  }, [executeActions, onSimulate, timeFrame]);

  const simulationNameError = simulationName.length === 0 ? "Name is required" : "";
  const trError = endTimeMillis < startTimeMillis ? "End time cannot be before start time" : undefined;
  const displayError = useMemo(
    () => [trError, simulationNameError].filter(Boolean).join("\n"),
    [simulationNameError, trError]
  );

  return (
    <div className="inc-flex-column flex-gap-16 width-100">
      <div className="inc-flex-column width-100 flex-gap-4">
        <div className="inc-text-body">Simulate with Past Data</div>
        <div className="inc-text-subtext-medium inc-text-inactive">
          Analyze historical trends and simulate alerts using past data to gain insights and make informed decisions
          based on historical patterns.
        </div>
      </div>

      <IncTextfield
        className="simulation-name-tf"
        label="Name your Simulation"
        onChange={onSimulationNameChange}
        required
        value={simulationName}
      />

      <OpTimeFrameEditor
        onChange={setTimeFrame}
        timeFrame={timeFrame}
      />

      <IncCheckbox
        checked={executeActions}
        label="Execute actions"
        labelProps={{ placement: "end" }}
        onChange={onToggleExecuteActions}
      />

      <VerticallyCenteredRow>
        <IncButton
          color="primary"
          disabled={Boolean(displayError) || inProgress}
          label="Simulate"
          loading={inProgress}
          loadingText="Simulating..."
          onClick={onTriggerSimulate}
        />

        {Boolean(displayError) && (
          <IncToolTip
            placement="top"
            titleText={displayError}
            variant="error"
          >
            <VerticallyCenteredRow>
              <IncFaIcon
                className="status-danger marginLt10"
                iconName="exclamation-triangle"
              />
            </VerticallyCenteredRow>
          </IncToolTip>
        )}
      </VerticallyCenteredRow>
    </div>
  );
};
