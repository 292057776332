import React, { FC, useMemo } from "react";
import { cloneDeep } from "lodash";
import { CatalogVizRendererProps } from "../common";
import { SingleStatRenderer } from "../SingleStatRenderer";
import { OverTagPostAgg } from "../../../../../../services/api/explore";
import { getWidgetTitleBasedOnTimeRange } from "./topNUtils";

type Props = CatalogVizRendererProps & {
  selectedMetricIds: string[];
};

const SingleStatWrapper: FC<Props> = props => {
  const { selectedMetricIds } = props;
  const metricsJsx = useMemo(
    () =>
      (selectedMetricIds || []).map(metricId => (
        <SingleStatCard
          {...props}
          key={metricId}
          metricId={metricId}
        />
      )),
    [selectedMetricIds, props]
  );
  return <div className="inc-grid inc-grid--grid-2 w-100">{metricsJsx}</div>;
};

type SsProp = CatalogVizRendererProps & {
  metricId: string;
};

const SingleStatCard: FC<SsProp> = props => {
  const { metricId, timeRange, widgetResponseDTO, dataFetchPayload, childrenDataFetchPayload } = props;
  const { widgetConfig } = widgetResponseDTO;
  const { dataDefinition } = widgetConfig;
  const metricName = useMemo(() => {
    const metricName = dataDefinition?.metrics?.[metricId]?.name;
    return metricName;
  }, [dataDefinition?.metrics, metricId]);

  const title = useMemo(() => getWidgetTitleBasedOnTimeRange(timeRange, metricName, "ss"), [timeRange, metricName]);
  const payload = useMemo(() => {
    const payload = cloneDeep(dataFetchPayload);
    payload.sliceSpec = (payload?.sliceSpec || []).filter(spec => spec.metricId === metricId);
    if (!payload.sliceSpec.length) {
      payload.sliceSpec = (childrenDataFetchPayload?.sliceSpec || []).filter(spec => spec.metricId === metricId);
      payload.mode = childrenDataFetchPayload?.mode;
    }
    payload.sliceSpec.forEach(spec => {
      const overTagPostAgg = spec.postAgg as OverTagPostAgg;
      if (overTagPostAgg?.overTagAgg) {
        overTagPostAgg.overTagAgg.tagName = [];
      }
    });
    return payload;
  }, [childrenDataFetchPayload, dataFetchPayload, metricId]);

  return (
    <div className="single-stat-wrapper">
      <div className="single-stat-container">
        <span className="inc-text-color-secondary">{title}</span>
        <div>
          <SingleStatRenderer
            {...props}
            dataFetchPayload={payload}
            fontSize={20}
            metricId={metricId}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleStatWrapper;
