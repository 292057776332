import { ChartOptions, Options, PlotTreemapDataLabelsOptions, DrilldownOptions, PointOptionsObject } from "highcharts";
import { isValidElement } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { HighChartsLegendClick, HighChartsNumberFormatter } from "@inception/ui";
import { merge } from "lodash";
import { OnClickCallback } from "../bubble-chart";
import { getTooltipId } from "../bubble-chart/OptionsBuilder";
import { renderReactComponent } from "../../../AppRenderer";
import getChartColor from "../charts/colors";
import { HighChartsDrilldownSeries, getCommonToolTipForCompare } from "../charts";
import { getDataLabelType, getTooltipType, TreeMapSeries, TreeMapOptions } from "./types";

export class TreeMapOptionsBuilder {
  private options: Options;

  constructor(defOptions: Partial<Options> = {}) {
    this.initOptions(defOptions);
  }

  build() {
    return this.options;
  }

  private getDataLabelOptions(showDataLabels: boolean, getDataLabel: (point: TreeMapSeries) => any) {
    return {
      enabled: showDataLabels,
      formatter: function () {
        const { value, name, custom } = (this as any).point as any;
        const { isOthersSeries } = custom || {};
        if (getDataLabel) {
          return renderToStaticMarkup(getDataLabel((this as any).point as TreeMapSeries));
        } else {
          const style = `
            width: 100%; 
            overflow: hidden;
            text-overflow: ellipsis; 
            display: flex; 
            flex-direction: column;
            white-space: nowrap;
            text-align: center;
          `;

          return `<div style="${style}">
            <span>${name}</span>
            <span>${!isOthersSeries ? value : ""}</span>
          </div>`;
        }
      }
    };
  }

  private initOptions(defOptions: Partial<Options> = {}) {
    this.options = this.getDefaultOptions();
    merge(this.options, defOptions);
  }

  setChartFields(width: number, height: number): TreeMapOptionsBuilder {
    this.options.chart = merge(
      {
        type: "treemap",
        height,
        width,
        zoomType: "x",
        backgroundColor: "transparent",
        numberFormatter: HighChartsNumberFormatter
      } as ChartOptions,
      this.options.chart
    );

    return this;
  }

  clearSeriesData(): TreeMapOptionsBuilder {
    this.options.series = [];
    return this;
  }

  setSeriesData(
    series: TreeMapSeries[],
    showDataLabels: boolean,
    getDataLabel: getDataLabelType = null,
    getToolTip: getTooltipType = null,
    onClick: OnClickCallback = null,
    seriesName = ""
  ): TreeMapOptionsBuilder {
    this.options.colorAxis = {
      minColor: "#243A4E",
      maxColor: "#295476"
    };
    let maxValueInSeries = 0;
    series.forEach(x => {
      if (x.value > maxValueInSeries) {
        maxValueInSeries = x.value;
      }
    });

    const seriesWithColorValues = series.map((x): PointOptionsObject => {
      const colorValue = Math.ceil((x.value / maxValueInSeries) * 10);
      x.colorValue = x.colorValue || colorValue;

      return {
        ...x,
        drilldown: (x as any).drillDownId
      };
    });

    const dataLabelOptions: PlotTreemapDataLabelsOptions = this.getDataLabelOptions(showDataLabels, getDataLabel);

    this.options.series = [
      {
        type: "treemap",
        dataLabels: dataLabelOptions,
        data: seriesWithColorValues,
        name: seriesName,
        point: {
          events: {
            mouseOver: function () {
              if (getToolTip) {
                const point = this as any;
                const tooltipProps = getToolTip(point as TreeMapSeries);
                const isTooltipReactElement = isValidElement(tooltipProps);

                if (isTooltipReactElement) {
                  window.setTimeout(() => {
                    const tooltipId = getTooltipId(point?.id);
                    const tooltipElem = document.querySelector(`#${tooltipId}`);
                    if (tooltipElem) {
                      renderReactComponent(tooltipProps, tooltipElem);
                    }
                  }, 0);
                }
              }
            },
            click: function (event) {
              onClick?.(this.series, event);
            }
          }
        }
      }
    ];
    return this;
  }

  setDrillDownSeries(
    drillDownSeries: Array<HighChartsDrilldownSeries<TreeMapSeries>>,
    showDataLabels: boolean,
    getDataLabel: getDataLabelType = null,
    getToolTip: getTooltipType = null,
    onClick: OnClickCallback = null
  ) {
    const dataLabelOptions: PlotTreemapDataLabelsOptions = this.getDataLabelOptions(showDataLabels, getDataLabel);

    if (drillDownSeries?.length > 0) {
      const treeMapDDSeries: DrilldownOptions["series"] = drillDownSeries.map((ddSerie, i) => ({
        name: ddSerie.name,
        id: ddSerie.id,
        type: "treemap",
        dataLabels: dataLabelOptions,
        data: ddSerie.series.map(serie => ({
          name: serie.name,
          x: serie.name as any,
          value: serie.value,
          color: serie.color || getChartColor(i),
          drilldown: serie.drillDownId,
          custom: serie.custom
        })),
        borderRadius: 6,
        borderWidth: 4,
        borderColor: this.options.plotOptions?.treemap?.borderColor || "#212B36",
        states: {
          hover: {
            borderColor: this.options.plotOptions?.treemap?.borderColor || "#212B36"
          }
        },
        point: {
          events: {
            mouseOver: function () {
              if (getToolTip) {
                const point = this as any;
                const tooltipProps = getToolTip(point as TreeMapSeries);
                const isTooltipReactElement = isValidElement(tooltipProps);

                if (isTooltipReactElement) {
                  window.setTimeout(() => {
                    const tooltipId = getTooltipId(point?.id);
                    const tooltipElem = document.querySelector(`#${tooltipId}`);
                    if (tooltipElem) {
                      renderReactComponent(tooltipProps, tooltipElem);
                    }
                  }, 0);
                }
              }
            },
            click: function (event) {
              onClick?.(this.series, event);
            }
          }
        }
      }));
      this.options.drilldown = {
        series: treeMapDDSeries,
        activeDataLabelStyle: {
          textDecoration: "none"
        },
        activeAxisLabelStyle: {
          textDecoration: "none"
        }
      };
    }
    return this;
  }

  setToolTip(
    getTooltip: getTooltipType,
    tooltipValueFormatter: TreeMapOptions["tooltipValueFormatter"]
  ): TreeMapOptionsBuilder {
    this.options.tooltip = {
      enabled: true,
      shared: false,
      outside: true,
      useHTML: true,
      formatter: function () {
        if (getTooltip) {
          const element = getTooltip(this.point as TreeMapSeries);
          const isTooltipReactElement = isValidElement(element);
          if (isTooltipReactElement) {
            const tooltipId = getTooltipId((this.point as any)?.id);
            return `<div id="${tooltipId}" />`;
          }
        }

        const { series, value, name, color, custom } = this.point as any;

        return getCommonToolTipForCompare(name, value, color, series, custom, tooltipValueFormatter);
      }
    };
    return this;
  }

  setDataLabels(showDataLabels: boolean): TreeMapOptionsBuilder {
    (this.options.plotOptions.treemap.dataLabels as any).enabled = showDataLabels;
    return this;
  }

  setLegend(showLegend: boolean): TreeMapOptionsBuilder {
    this.options.legend.enabled = showLegend;
    this.options.legend.width = "25%";
    return this;
  }

  private getDefaultOptions(): Options {
    return {
      chart: {
        spacingRight: 8,
        spacingLeft: 8,
        spacingBottom: 8
      },
      credits: { enabled: false },
      accessibility: {
        enabled: false
      },
      plotOptions: {
        series: {
          animation: false,
          events: {
            legendItemClick: HighChartsLegendClick
          }
        },
        treemap: {
          allowPointSelect: true,
          allowTraversingTree: false,
          animation: false,
          dataLabels: {
            enabled: true,
            useHTML: true,
            allowOverlap: true
          },
          borderRadius: 6,
          borderWidth: 4,
          borderColor: "#212B36",
          states: {
            hover: {
              borderColor: "#212B36"
            }
          },
          layoutAlgorithm: "sliceAndDice"
        } as any
      },
      legend: {
        enabled: false
      },
      title: {
        text: ""
      }
    };
  }
}
