import {
  generateId,
  IncFaIcon,
  IncLoadingOutlinedSpin,
  IncSelect,
  IncSelectOption,
  IncSlimSelect,
  IncTextfield,
  IncToggle
} from "@inception/ui";
import { cloneDeep, isEqual } from "lodash";
import React, { FC, useMemo, useCallback, useState, useEffect, memo } from "react";
import { MetricsWithEventTypeAndId } from "../../../../../../../biz-entity";
import {
  EventFieldPropertiesEditor,
  EventFieldQueryEditor,
  VerticallyCenteredRow
} from "../../../../../../../components";
import { useRefState, useTimeRange } from "../../../../../../../core";
import {
  compareUSFields,
  DemoDataParams,
  EntityMeta,
  ExploreUserService,
  FieldPickerOptionData,
  TimeObj,
  UserServiceField,
  UserServiceFieldMetricConfig,
  UserServiceFieldSlice,
  UserServiceFieldSliceSet,
  WidgetConfigUtils
} from "../../../../../../../services/api/explore";
import { ENTITY_TAG, FieldPickerUtils, pluralizeWord } from "../../../../../../../utils";
import { DataEditorStateEntry } from "../types";
import { ALL_USERSERVICES_EVENT_TYPE_ID } from "../../../../../../../utils/ExploreUtils";
import { KPI } from "../../../../../../../services/api";

interface Props {
  entityTypeId?: string;
  eventTypes: ExploreUserService[];
  metricHeaderDescriptions: Record<string, string>;
  updateMetricHeaderDescriptions: (metricId: string, descriptions: string) => void;

  metricsState: DataEditorStateEntry;
  setMetricsState: (metricsState: DataEditorStateEntry) => void;
  sliceOptions: FieldPickerOptionData[];
  entityUsField?: UserServiceField;

  demoDataParams?: DemoDataParams;
  metricNameLabel?: string;
  metricDescriptionLabel?: string;
  hideSliceSelection?: boolean;
  hideMetricCustomisation?: boolean;
  hideHeader?: boolean;

  isEditable: boolean;
  canDelete: boolean;
  canClone: boolean;
  onDelete: () => void;
  onClone: () => void;

  kpis: KPI[];
  onSelectKPI: (kpi: KPI) => Promise<void>;
  onAddCustomMetric: () => void;
}

export const QueryEditor: FC<Props> = memo(props => {
  const {
    entityTypeId,
    eventTypes,
    metricsState,
    setMetricsState: pSetMetricsState,
    metricHeaderDescriptions = {},
    updateMetricHeaderDescriptions,
    sliceOptions: sliceOptionsData,
    entityUsField,
    canDelete,
    canClone,
    onDelete,
    onClone,
    isEditable,
    demoDataParams = null,
    metricNameLabel = "",
    metricDescriptionLabel = "",
    hideSliceSelection = false,
    hideHeader = false,
    hideMetricCustomisation = false,
    onAddCustomMetric,
    onSelectKPI,
    kpis
  } = props;

  const kpiOpts = useMemo<KPIOption[]>(() => {
    let kpiOpts = kpis.map(
      (kpi): KPIOption => ({
        label: kpi.name,
        value: kpi.id,
        data: kpi
      })
    );
    kpiOpts = kpiOpts.sort((a, b) => a.label.localeCompare(b.label));

    if (kpiOpts.length > 0) {
      kpiOpts.push(customMetricOpt);
    }

    return kpiOpts;
  }, [kpis]);

  const metricOpt = useMemo(
    () => kpiOpts.find(opt => opt.value === metricsState.kpiId) || customMetricOpt,
    [kpiOpts, metricsState.kpiId]
  );

  const [isMetricChangeInProgress, setIsMetricChangeInProgress] = useState(false);
  const onMetricChange = useCallback(
    async (opt: KPIOption) => {
      if (opt.value === CUSTOM_METRIC_VALUE) {
        onAddCustomMetric();
      } else {
        setIsMetricChangeInProgress(true);
        await onSelectKPI(opt.data);
        setIsMetricChangeInProgress(false);
      }
    },
    [onAddCustomMetric, onSelectKPI]
  );

  const allowEventTypeSelection = eventTypes?.length > 1;
  const eventTypeOpts = useMemo<IncSelectOption[]>(() => {
    let eventTypeOpts = eventTypes.map(us => ({
      label: us.name,
      value: us.entityId
    }));

    eventTypeOpts = eventTypeOpts.sort((a, b) => a.label.localeCompare(b.label));
    eventTypeOpts.unshift({
      label: "All Event Types",
      value: ALL_USERSERVICES_EVENT_TYPE_ID
    });

    return eventTypeOpts;
  }, [eventTypes]);

  const eventTypeNameMap = useMemo<Record<string, string>>(
    () =>
      eventTypes.reduce(
        (map, us) => ({
          ...map,
          [us.entityId]: us.name
        }),
        {} as Record<string, string>
      ),
    [eventTypes]
  );

  const sliceOptions = useMemo(() => {
    const sliceOptions: Array<IncSelectOption<UserServiceFieldSlice>> = [];

    sliceOptionsData?.forEach(option => {
      if (option.type === "userServiceField") {
        const usField = option.payload as UserServiceField;
        const displayTagName = FieldPickerUtils.getPromSanitizedUSFName(usField);
        const tagName = entityUsField && compareUSFields(usField, entityUsField) ? ENTITY_TAG : displayTagName;
        const slice: UserServiceFieldSlice = {
          tagName,
          userServiceField: usField
        };

        sliceOptions.push({
          label: displayTagName,
          value: tagName,
          data: slice
        });
      }
    });

    return sliceOptions;
  }, [entityUsField, sliceOptionsData]);

  const metricsStateRef = useRefState(metricsState);
  const setMetricsState = useCallback(
    (feederFunction: (prevMetricState: DataEditorStateEntry) => DataEditorStateEntry) => {
      const nextMetricState = feederFunction(metricsStateRef.current);
      if (!isEqual(nextMetricState, metricsStateRef.current)) {
        // Add filters label to child metric names
        if (nextMetricState.hasExpression) {
          nextMetricState.metricsWithEventTypeAndId.forEach(metric => {
            if (metric.metricConfig) {
              const metricNameWithoutFilters = (metric.metricName || "").split(" where")[0];
              const filtersStr = WidgetConfigUtils.getFiltersLabelForUserServiceFilters(
                metric.metricConfig.eventFilters
              );
              metric.metricName = filtersStr
                ? `${metricNameWithoutFilters} where ${filtersStr}`
                : metricNameWithoutFilters;
            }
          });
        }
        pSetMetricsState(nextMetricState);
      }
    },
    [metricsStateRef, pSetMetricsState]
  );

  const { getLatestTimeRange } = useTimeRange();

  const {
    exprMetricId,
    metricsWithEventTypeAndId,
    metricName: defMetricName,
    hasExpression,
    expression,
    subType,
    isSpikePositive,
    lookBack
  } = metricsState;

  const metricId = exprMetricId !== "" ? exprMetricId : metricsWithEventTypeAndId[0]?.configId;
  const defMetricDescription = useMemo(
    () => metricHeaderDescriptions[metricId] || "",
    [metricHeaderDescriptions, metricId]
  );

  const [metricName, setMetricName] = useState(defMetricName);
  const [metricDescription, setMetricDescription] = useState(defMetricDescription);

  useEffect(() => {
    setMetricName(defMetricName);
    setMetricDescription(defMetricDescription);
  }, [defMetricDescription, defMetricName]);

  const numMetrics = metricsWithEventTypeAndId.length;

  const onMetricNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const metricName = e.target.value;
    setMetricName(metricName);
  }, []);

  const onMetricNameBlur = useCallback(() => {
    setMetricsState(prev =>
      prev.metricName !== metricName
        ? {
            ...prev,
            metricName
          }
        : prev
    );
  }, [metricName, setMetricsState]);

  const onMetricDescriptionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const metricDescription = e.target.value;
    setMetricDescription(metricDescription);
  }, []);

  const onMetricDescriptionBlur = useCallback(() => {
    updateMetricHeaderDescriptions(metricId, metricDescription);
  }, [metricDescription, metricId, updateMetricHeaderDescriptions]);

  const onExpressionChange = useCallback(
    (opt: IncSelectOption) => {
      setMetricsState(prev => ({
        ...prev,
        expression: opt.value,
        kpiId: ""
      }));
    },
    [setMetricsState]
  );

  const onAddExpressionToggle = useCallback(
    (addExpression: boolean) => {
      if (addExpression) {
        setMetricsState(prev => ({
          ...prev,
          expression: "A / 100",
          hasExpression: true,
          kpiId: ""
        }));
      } else {
        setMetricsState(prev => ({
          ...prev,
          metricsWithEventTypeAndId: metricsWithEventTypeAndId.length ? [metricsWithEventTypeAndId[0]] : [],
          expression: "",
          hasExpression: false,
          kpiId: ""
        }));
      }
    },
    [metricsWithEventTypeAndId, setMetricsState]
  );

  const onEventTypeChange = useCallback(
    (opt: IncSelectOption, idx: number) => {
      setMetricsState(prev => {
        const eventTypeId = opt.value;
        const next = { ...prev };
        next.eventTypeId = eventTypeId;
        next.metricsWithEventTypeAndId = [...prev.metricsWithEventTypeAndId];
        next.metricsWithEventTypeAndId[idx] = {
          ...next.metricsWithEventTypeAndId[idx],
          eventTypeId,
          metricConfig: {
            aggregator: "avg",
            eventFilters: {
              userServiceFilters: []
            },
            sliceSets: [
              {
                slices: []
              }
            ],
            lookBack: null,
            ...(next.metricsWithEventTypeAndId[idx]?.metricConfig || {}),
            userServiceField: {
              userServices: [
                {
                  userServiceEntityId: eventTypeId
                }
              ]
            } as UserServiceField
          },
          metricName: ""
        };
        next.kpiId = "";

        return next;
      });
    },
    [setMetricsState]
  );

  const onRateMetricSelect = useCallback(
    (
      eventTypeId: string,
      isSuccess: boolean,
      eventIdField: UserServiceField,
      hasErrorField: UserServiceField,
      sliceSets: UserServiceFieldSliceSet[],
      metricIdx: number,
      metricName: string,
      eventTypeName: string
    ) => {
      const rateMetrics: MetricsWithEventTypeAndId = [
        {
          configId: generateId(),
          eventTypeId,
          metricConfig: {
            userServiceField: eventIdField,
            eventFilters: {
              userServiceFilters: [
                {
                  userServiceFilterExpressions: [
                    {
                      field: hasErrorField,
                      operator: "=",
                      value: isSuccess ? "false" : "true"
                    }
                  ]
                }
              ]
            },
            sliceSets,
            aggregator: "count"
          },
          metricName: isSuccess
            ? `Successful ${pluralizeWord(eventTypeName)}`
            : `Failed ${pluralizeWord(eventTypeName)}`
        },
        {
          configId: generateId(),
          eventTypeId,
          metricConfig: {
            userServiceField: eventIdField,
            eventFilters: {
              userServiceFilters: []
            },
            sliceSets,
            aggregator: "count"
          },
          metricName: `Total ${pluralizeWord(eventTypeName)}`
        }
      ];

      setMetricsState(prev => {
        const nextMetrics = [...prev.metricsWithEventTypeAndId];
        nextMetrics.splice(metricIdx, 1, ...rateMetrics);

        return {
          ...prev,
          metricName,
          hasExpression: true,
          expression: prev.expression || "(A / B) * 100",
          metricsWithEventTypeAndId: nextMetrics
        };
      });
    },
    [setMetricsState]
  );

  const metricEditors = useMemo(
    () =>
      metricsWithEventTypeAndId.map((configEntry, idx) => {
        const { eventTypeId, metricConfig, configId } = configEntry;

        const eventTypeInfo: EntityMeta = {
          name: eventTypeId === ALL_USERSERVICES_EVENT_TYPE_ID ? "Events" : eventTypeNameMap[eventTypeId] || "",
          entityId: eventTypeId
        };

        const onConfigChange = (nMetricConfig: UserServiceFieldMetricConfig, metricName?: string) => {
          if (!nMetricConfig?.userServiceField && eventTypeId !== ALL_USERSERVICES_EVENT_TYPE_ID) {
            nMetricConfig.userServiceField = {
              userServices: [
                {
                  userServiceEntityId: eventTypeId
                }
              ]
            } as UserServiceField;
          }

          setMetricsState(prev => {
            const next = { ...prev };
            next.metricsWithEventTypeAndId = [...prev.metricsWithEventTypeAndId];
            next.metricsWithEventTypeAndId[idx] = {
              ...next.metricsWithEventTypeAndId[idx],
              metricConfig: nMetricConfig
            };
            next.metricName = metricName || next.metricName;
            next.kpiId = "";

            return next;
          });
        };

        const onDuplicate = hasExpression
          ? () => {
              setMetricsState(prev => {
                const dupConfigEntry = cloneDeep(configEntry);
                dupConfigEntry.configId = generateId();

                const nextMetrics = [...prev.metricsWithEventTypeAndId];
                nextMetrics.splice(idx + 1, 0, dupConfigEntry);

                return {
                  ...prev,
                  metricsWithEventTypeAndId: nextMetrics
                };
              });
            }
          : null;

        const onRemove =
          hasExpression && numMetrics > 1
            ? () => {
                setMetricsState(prev => {
                  const nextMetrics = [...prev.metricsWithEventTypeAndId];
                  nextMetrics.splice(idx, 1);

                  const next = {
                    ...prev,
                    metricsWithEventTypeAndId: nextMetrics
                  };

                  if (next.hasExpression && nextMetrics.length === 1) {
                    next.hasExpression = false;
                    next.expression = "";
                    next.exprMetricId = "";
                  }

                  return next;
                });
              }
            : null;

        const onIsSpikePositiveChange = (isSpikePositive: boolean) => {
          setMetricsState(prev => ({
            ...prev,
            isSpikePositive
          }));
        };

        const onSubTypeChange = (subType: string) => {
          setMetricsState(prev => ({
            ...prev,
            subType
          }));
        };

        const onLookBackChange = (lookBack: TimeObj) => {
          setMetricsState(prev => ({
            ...prev,
            lookBack
          }));
        };

        const onEventTypeChangeInternal = (opt: IncSelectOption) => {
          onEventTypeChange(opt, idx);
        };

        const onRateMetricSelectInternal = (
          isSuccess: boolean,
          eventIdField: UserServiceField,
          hasErrorField: UserServiceField,
          metricName: string
        ) => {
          onRateMetricSelect(
            eventTypeId,
            isSuccess,
            eventIdField,
            hasErrorField,
            metricConfig.sliceSets || [
              {
                slices: []
              }
            ],
            idx,
            metricName,
            eventTypeNameMap[eventTypeId] || ""
          );
        };

        const queryId = hasExpression ? String.fromCharCode(65 + idx) : null;
        const selEventTypeOpt = eventTypeOpts.find(opt => opt.value === eventTypeId) || {
          label: eventTypeNameMap[eventTypeId] || eventTypeId,
          value: eventTypeId
        };
        const key = `${configId}-${eventTypeId}`;

        return (
          <EventFieldQueryEditor
            addRateMetricOptions
            demoDataParams={demoDataParams}
            disableSliceSelection={!hasExpression || hideSliceSelection}
            displayImplicitSlice
            enableLookBackConfiguration={!hasExpression && !hideMetricCustomisation}
            enableSpikeConfiguration={!hasExpression && !hideMetricCustomisation}
            enableSubTypeSelection={!hasExpression && !hideMetricCustomisation}
            entityTypeId={entityTypeId}
            eventTypeInfo={eventTypeInfo}
            fieldLabel="Dimension is"
            getLatestTimeRange={getLatestTimeRange}
            isSpikePositive={isSpikePositive}
            key={key}
            lookBack={lookBack}
            minimalView
            nonCollapsibleFilters
            onChange={onConfigChange}
            onDuplicate={onDuplicate}
            onIsSpikePositiveChange={onIsSpikePositiveChange}
            onLookBackChange={onLookBackChange}
            onRateMetricSelect={onRateMetricSelectInternal}
            onRemove={onRemove}
            onSubTypeChange={onSubTypeChange}
            queryId={queryId}
            queryReadOnly={!isEditable}
            sliceOptions={sliceOptions}
            subType={subType}
            usFieldMetricConfig={metricConfig}
          >
            <IncSlimSelect
              alignment="row"
              autoAdjustWidth
              autoSort={false}
              isDisabled={!isEditable || !allowEventTypeSelection}
              isSearchable
              label="EventType"
              onChange={onEventTypeChangeInternal}
              options={eventTypeOpts}
              value={selEventTypeOpt}
            />
          </EventFieldQueryEditor>
        );
      }),
    [
      allowEventTypeSelection,
      demoDataParams,
      entityTypeId,
      eventTypeNameMap,
      eventTypeOpts,
      getLatestTimeRange,
      hasExpression,
      hideMetricCustomisation,
      hideSliceSelection,
      isEditable,
      isSpikePositive,
      lookBack,
      metricsWithEventTypeAndId,
      numMetrics,
      onEventTypeChange,
      onRateMetricSelect,
      setMetricsState,
      sliceOptions,
      subType
    ]
  );

  const expressionMetricOpts = useMemo(() => getOptionsForMetrics(numMetrics), [numMetrics]);

  const onSubTypeChange = useCallback(
    (subType: string) => {
      setMetricsState(prev => ({
        ...prev,
        subType
      }));
    },
    [setMetricsState]
  );

  const onIsSpikePositiveChange = useCallback(
    (isSpikePositive: boolean) => {
      setMetricsState(prev => ({
        ...prev,
        isSpikePositive
      }));
    },
    [setMetricsState]
  );

  const onLookBackChange = useCallback(
    (lookBack: TimeObj) => {
      setMetricsState(prev => ({
        ...prev,
        lookBack
      }));
    },
    [setMetricsState]
  );

  return (
    <div
      className="query-editor"
      data-has-expression={hasExpression}
    >
      {kpiOpts.length > 1 && (
        <VerticallyCenteredRow className="flex-gap-12 width-100">
          <IncSelect
            autoAdjustWidth
            autoAdjustWidthBuffer={36}
            autoSort={false}
            isDisabled={isMetricChangeInProgress}
            label="Key Performance Indicator"
            onChange={onMetricChange}
            options={kpiOpts}
            value={metricOpt}
          />

          {isMetricChangeInProgress && <IncLoadingOutlinedSpin size={14} />}
        </VerticallyCenteredRow>
      )}

      {!hideHeader && (
        <VerticallyCenteredRow className="width-100 flex-gap-10">
          <IncTextfield
            containerClassName="width-100"
            label={metricNameLabel}
            onBlur={onMetricNameBlur}
            onChange={onMetricNameChange}
            value={metricName}
          />
          <IncTextfield
            containerClassName="width-100"
            label={metricDescriptionLabel}
            onBlur={onMetricDescriptionBlur}
            onChange={onMetricDescriptionChange}
            value={metricDescription}
          />

          {canClone && (
            <IncFaIcon
              className="status-info inc-cursor-pointer"
              iconName="clone"
              onClick={onClone}
              style={{ marginTop: metricNameLabel ? 16 : 0 }}
            />
          )}

          {canDelete && (
            <IncFaIcon
              className="status-danger inc-cursor-pointer"
              iconName="trash-can"
              onClick={onDelete}
              style={{ marginTop: metricNameLabel ? 16 : 0 }}
            />
          )}
        </VerticallyCenteredRow>
      )}

      {metricEditors}

      <VerticallyCenteredRow className="flex-gap-20">
        <IncToggle
          checked={hasExpression}
          className={!isEditable ? "readonly" : ""}
          disabled={!metricEditors?.length}
          label="Add Expression"
          onChange={onAddExpressionToggle}
        />

        {hasExpression && (
          <IncSelect
            alignment="row"
            allowCreate
            autoAdjustWidth
            label="Expression"
            onChange={onExpressionChange}
            options={expressionMetricOpts}
            value={getOpt(expression)}
            wrapperClass="expression-selector"
          />
        )}
      </VerticallyCenteredRow>

      {hasExpression && !hideMetricCustomisation && (
        <EventFieldPropertiesEditor
          enableLookBackConfiguration
          enableSpikeConfiguration
          enableSubTypeSelection
          isSpikePositive={isSpikePositive}
          lookBack={lookBack}
          onIsSpikePositiveChange={onIsSpikePositiveChange}
          onLookBackChange={onLookBackChange}
          onSubTypeChange={onSubTypeChange}
          queryReadOnly={!isEditable}
          subType={subType}
        />
      )}
    </div>
  );
});

const CUSTOM_METRIC_VALUE = "__CUSTOM_METRIC__";

const customMetricOpt: KPIOption = {
  label: "Custom",
  value: CUSTOM_METRIC_VALUE,
  data: null
};

const getOptionsForMetrics = (numMetrics: number) => {
  const options: IncSelectOption[] = [];

  if (numMetrics === 1) {
    options.push(getOpt("None", ""));
    options.push(getOpt("A * 100"));
    options.push(getOpt("A + 100"));
    options.push(getOpt("A / 100"));
    options.push(getOpt("A - 100"));
  } else if (numMetrics === 2) {
    options.push(getOpt("A / B"));
    options.push(getOpt("A + B"));
    options.push(getOpt("(A / B) * 100"));
    options.push(getOpt("(A + B) * B"));
    options.push(getOpt("(A - B) * B"));
  } else if (numMetrics > 2) {
    options.push(getOpt("A / B"));
    options.push(getOpt("(A / B) * 100"));
    options.push(getOpt("A / C"));
    options.push(getOpt("(A / C) * 100"));
    options.push(getOpt("(A / B) * C"));
    options.push(getOpt("(A + B) * C"));
    options.push(getOpt("(A - B) * C"));
  }

  return options;
};

const getOpt = (label: string, value = label): IncSelectOption => ({
  label,
  value
});

type KPIOption = IncSelectOption<KPI>;
