import datasourceApiManager from "../../../services/api/DatasourceApiService";
import { ScopedVars } from "../../../services/api/types";
import { VariableResolverResult } from "./CustomVariableResolver";

class QueryVariableResolver {
  // Makes this piece reusable to get tag suggestions elsewhere
  async resolveValues(query: string, datasource: string, variableVals: ScopedVars): Promise<VariableResolverResult> {
    const result: VariableResolverResult = {
      error: "",
      data: []
    };
    try {
      const dsInstance = datasourceApiManager.get(datasource);
      const suggestResponse = await dsInstance.suggestQuery(query, variableVals);
      const { data, error } = suggestResponse;
      result.data = data;
      result.error = error?.data.message || error?.message || "";
    } catch (err) {
      result.error = err.message;
    }
    return result;
  }
}

const queryVariableResolver = new QueryVariableResolver();

export default queryVariableResolver;
