import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Highcharts, { Chart } from "highcharts";
import { isEqual, uniqBy } from "lodash";
import { IncFaIcon, IncHighchartsDateTimeFormat, IncToolTip, getFormattedDateTime } from "@inception/ui";
import { getBarSeriesFromBins } from "../IncidentTimeline/utils";
import BarColumnChartOptionsBuilder from "../../bar-chart/BarColumnChartOptionsBuilder";
import { TimeRange, dateTime } from "../../../core";
import { renderReactComponent } from "../../../../AppRenderer";
import { CorrelatedEventsResponse, IncidentTimelineBin } from "../../../services/api/explore";
import { getChangeEventTimelineHighChartOptions, getChangeEventTimelineXAxisOptions } from "./changeEventTimelineUtils";
import { BinnedCorrelatedEventsInfo } from "./types";

export interface GraphContext {
  bins: IncidentTimelineBin[];
  accessorHeaderMap?: Record<string, string>;
  timeSeriesAxisLabels: number[];
  correlatedEventsResponse: CorrelatedEventsResponse;
}

const corEventsPrefix = "ceimp-";

export const useChangeEventGraphBuilder = (props: GraphContext) => {
  const { bins, accessorHeaderMap, timeSeriesAxisLabels, correlatedEventsResponse } = props;

  const filteredTimeSeriesAxisLabels = useMemo(
    () => uniqBy(timeSeriesAxisLabels, at => Math.floor(parseInt(at.toString(), 10) / 1000)),
    [timeSeriesAxisLabels]
  );
  const binnedCorrelatedEvents = useMemo<BinnedCorrelatedEventsInfo[]>(() => {
    const binnedCorrelatedEvents: BinnedCorrelatedEventsInfo[] = [];
    correlatedEventsResponse?.resultList?.[0]?.correlatedEventList?.forEach(x => {
      if (x?.correlatedEvents?.length > 0) {
        const st = parseInt(x.startTimeMillis, 10);
        const et = parseInt(x.endTimeMillis, 10);
        const timeStamp = Math.floor((st + et) / 2 / 1000);

        binnedCorrelatedEvents.push({
          timeStampSecs: timeStamp,
          correlatedEvents: x.correlatedEvents
        });
      }
    }, []);
    return binnedCorrelatedEvents;
  }, [correlatedEventsResponse]);

  // bins
  const [selectedBins, setSelectedBins] = useState<IncidentTimelineBin[]>([]);
  const selectedBinsRef = useRef<IncidentTimelineBin[]>([]);
  const onSelect = useCallback((bin: IncidentTimelineBin) => {
    const selectedBins = selectedBinsRef.current;
    const selectionIdx = selectedBins.findIndex(b => isEqual(b, bin));
    const shouldRemoveSelection = selectionIdx !== -1;
    const nSelectedBins = shouldRemoveSelection ? [] : [bin];
    selectedBinsRef.current = nSelectedBins;
    setSelectedBins(nSelectedBins);
  }, []);
  const { series, timestamps } = useMemo(
    () => getBarSeriesFromBins(bins, onSelect, selectedBins, true),
    [bins, onSelect, selectedBins]
  );

  const optionsBuilder = useMemo(
    () =>
      new BarColumnChartOptionsBuilder({ barDimension: 16 })
        .setLayout("vertical")
        .setShowDataLabels(true)
        .setDataLabelsOptions({
          align: "center",
          verticalAlign: "top",
          inside: false,
          position: "center",
          y: -25
        })
        .setLegend(false),
    []
  );

  // graph and tickPositioners
  const [chartInstance, setChartInstance] = useState<Chart>(null);
  useEffect(() => {
    if (chartInstance) {
      chartInstance.redraw();
    }
  }, [chartInstance]);
  useEffect(() => {
    if (chartInstance && filteredTimeSeriesAxisLabels.length) {
      binnedCorrelatedEvents.forEach(event => {
        const id = `${corEventsPrefix}${event.timeStampSecs * 1000}`;
        const element = document.getElementById(id);
        if (element) {
          const eventCount = event?.correlatedEvents?.length;
          const toolTip = (
            <div className="change-event-tooltip inc-flex-column">
              {event.correlatedEvents.map((corEvent, i) => {
                const value = parseInt(corEvent.timeStampMillis, 10);
                const formattedString = getFormattedDateTime(value, IncHighchartsDateTimeFormat.timeOnly, {
                  withSeconds: false
                });
                return (
                  <div
                    className="inc-flex-column marginTp6"
                    key={i}
                  >
                    <span className="inc-text-subtext inc-text-color-secondary">{formattedString}</span>
                    <span className="inc-text-subtext-medium">{corEvent?.entityChangeEvent?.description}</span>
                  </div>
                );
              })}
            </div>
          );

          const reactNode = (
            <div
              style={{
                marginTop: -28,
                border: "1px solid #3BB443",
                borderRadius: "8px",
                padding: "4px"
              }}
            >
              <IncToolTip titleElement={toolTip}>
                <span className="change-event-container">
                  <IncFaIcon iconName="calendar-days" />
                  <span className="inc-text-subtext marginLt2">{eventCount}</span>
                </span>
              </IncToolTip>
            </div>
          );
          renderReactComponent(reactNode, element);
        }
      });
    }
  }, [filteredTimeSeriesAxisLabels, chartInstance, binnedCorrelatedEvents]);
  const highChartsCallback = useCallback(chart => setChartInstance(chart), []);

  const ref = useRef<HTMLDivElement>();

  const timeRange = useMemo(() => {
    const fromMillis = timestamps[0] * 1000;
    const toMillis = timestamps.slice(-1)[0] * 1000;
    const timeRange: TimeRange = {
      from: dateTime(fromMillis),
      to: dateTime(toMillis),
      raw: {
        from: String(fromMillis),
        to: String(toMillis)
      }
    };
    return timeRange;
  }, [timestamps]);

  // chart dimensions
  const getChartDimensions = useCallback(() => {
    const canvasElem: HTMLElement = ref.current;
    if (canvasElem) {
      const widgetItemContainer: Element = canvasElem.closest("." + "incident-timeline");
      const dimensions = widgetItemContainer?.getBoundingClientRect();

      if (dimensions) {
        const { height } = dimensions;
        const { width } = dimensions;
        return {
          width,
          height: height
        };
      }
    }
    return {
      width: -1,
      height: -1
    };
  }, [ref]);
  const { width, height } = getChartDimensions();

  // xAxis Options
  const xAxisOptions = useMemo(
    () =>
      getChangeEventTimelineXAxisOptions(
        binnedCorrelatedEvents,
        filteredTimeSeriesAxisLabels,
        timeRange,
        width,
        corEventsPrefix
      ),
    [binnedCorrelatedEvents, filteredTimeSeriesAxisLabels, timeRange, width]
  );

  // series data format
  const formattedSeries = useMemo(
    () =>
      series.map(s => ({
        ...s,
        colorByPoint: true,
        getExtremesFromAll: true,
        data:
          s.data?.map(e => {
            const temp = e as Highcharts.PointOptionsObject;
            const data = {
              ...temp,
              x: timeSeriesAxisLabels[temp?.x]
            } as Highcharts.PointOptionsObject;
            return data;
          }) || []
      })),
    [series, timeSeriesAxisLabels]
  );

  // options
  const options: Highcharts.Options = useMemo(() => {
    optionsBuilder
      .setChartFields(width, height)
      .setPlotOptions(false)
      .setXAxis()
      .clearSeriesData()
      .setSeriesData(formattedSeries as any);
    const hOptions = optionsBuilder.build();

    return getChangeEventTimelineHighChartOptions(
      hOptions,
      width,
      height,
      xAxisOptions,
      formattedSeries,
      accessorHeaderMap
    );
  }, [optionsBuilder, width, height, formattedSeries, xAxisOptions, accessorHeaderMap]);

  return {
    options,
    optionsBuilder,
    highChartsCallback,
    ref
  };
};
