import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IncModal, IncModalProps, IncSelect, IncSelectOption, IncSmartText } from "@inception/ui";
import { cloneDeep } from "lodash";
import { DashboardPresetTimeInfo } from "../../models";
import { RawTimeRange, useTimeRange } from "../../../core";
import { VerticallyCenteredRow } from "../../../components";
import { CompareTimeRangeSelector, TimeRangeController } from "../../../components/time-range";
import { noOp } from "../../../utils";
import timeRangeUtils from "../../../utils/TimeRangeUtils";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (presetTimeInfo: DashboardPresetTimeInfo) => void;
  presetTimeInfo: DashboardPresetTimeInfo;
};

type TimeRangeOption = IncSelectOption<boolean>;

export const SettingsModal: FC<Props> = props => {
  const { isOpen, onClose, onSave: pOnSave, presetTimeInfo: pPresetTimeInfo } = props;

  const [presetTimeInfo, setPresetTimeInfo] = useState(cloneDeep(pPresetTimeInfo));

  useEffect(() => {
    setPresetTimeInfo(cloneDeep(pPresetTimeInfo));
  }, [pPresetTimeInfo]);

  const { compareTimeRange: rCTr, timeRange: rTr, shouldOverrideTimeRange } = presetTimeInfo || {};
  const { compareTimeRange: gCTr, timeRange: gTr } = useTimeRange();
  const timeRange = useMemo(() => (rTr ? timeRangeUtils.getTimeRangeFromRaw(rTr) : gTr), [gTr, rTr]);
  const compareTimeRange = useMemo(() => (rCTr ? timeRangeUtils.getTimeRangeFromRaw(rCTr) : null), [rCTr]);
  const onSave = useCallback(() => {
    pOnSave(presetTimeInfo);
    onClose();
  }, [onClose, pOnSave, presetTimeInfo]);

  const actions = useMemo<IncModalProps["actions"]>(
    () => ({
      primary: {
        onClick: onSave,
        label: "Save",
        icon: "save"
      },
      secondary: {
        onClick: onClose,
        label: "Cancel"
      }
    }),
    [onClose, onSave]
  );

  const onChangeOption = useCallback(
    (option: TimeRangeOption) => {
      const { data: shouldOverrideTimeRange = false } = option;
      setPresetTimeInfo(prev => ({
        ...prev,
        shouldOverrideTimeRange,
        timeRange: shouldOverrideTimeRange ? gTr.raw : null
      }));
    },
    [gTr]
  );

  const onChangeTimeRange = useCallback((timeRange: RawTimeRange) => {
    setPresetTimeInfo(prev => ({
      ...prev,
      shouldOverrideTimeRange: true,
      timeRange: timeRange
    }));
  }, []);
  const onChangeCompareTimeRange = useCallback((timeRange: RawTimeRange) => {
    if (timeRange) {
      setPresetTimeInfo(prev => ({
        ...prev,
        shouldOverrideCompareTimeRange: true,
        compareTimeRange: timeRange
      }));
    } else {
      setPresetTimeInfo(prev => ({
        ...prev,
        shouldOverrideCompareTimeRange: false,
        compareTimeRange: null
      }));
    }
  }, []);

  const selectedOption = useMemo(
    () => timeRangeOptions.find(option => option.data === shouldOverrideTimeRange),
    [shouldOverrideTimeRange]
  );

  return (
    <IncModal
      actions={actions}
      contentClassName="padding24"
      onClose={onClose}
      show={isOpen}
      titleText={"Settings"}
      withActionsBorder
      withTitleBorder
    >
      <VerticallyCenteredRow className="flex-gap-12">
        <IncSelect
          autoAdjustWidth
          autoAdjustWidthBuffer={20}
          autoSort={false}
          label="Time Range"
          onChange={onChangeOption}
          options={timeRangeOptions}
          value={selectedOption}
        />
        {shouldOverrideTimeRange && Boolean(timeRange) && (
          <TimeRangeController
            className="marginTp16"
            compareTimeRange={gCTr}
            disableRefresh={true}
            disableTooltip
            setCompareTimeRange={noOp}
            setTimeRange={onChangeTimeRange}
            showCompareSelector={false}
            showTimeRangeSelector
            size="small"
            timeRange={timeRange}
          />
        )}
      </VerticallyCenteredRow>

      <IncSmartText
        className="inc-text-subtext-medium inc-text-inactive marginBt4 marginTp16"
        text="Show Comparison with"
      />
      <CompareTimeRangeSelector
        compareLabel=""
        compareTimeRange={compareTimeRange}
        disablePopper
        includeNoneOption
        noneOptionLabel="Default"
        renderer="default"
        setCompareTimeRange={onChangeCompareTimeRange}
      />
    </IncModal>
  );
};

const timeRangeOptions: TimeRangeOption[] = [
  {
    label: "Default",
    value: "default",
    data: false
  },
  {
    label: "Custom",
    value: "custom",
    data: true
  }
];
