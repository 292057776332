import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import Highcharts, { Chart, Options } from "highcharts";
import { IncPercentRenderer, getStringPossibleWidth } from "@inception/ui";
import TreeMap from "highcharts/modules/treemap";
import HeatMap from "highcharts/modules/heatmap";
import Drilldown from "highcharts/modules/drilldown";
import HighchartsReact from "highcharts-react-official";
import { useForceUpdate } from "../../core";
import { HighChartsDrilldownSeries } from "../charts";
import { TreeMapOptionsBuilder } from "./TreeMapOptionsBuilder";
import { TreeMapSeries, TreeMapOptions } from "./types";

TreeMap(Highcharts);
HeatMap(Highcharts);
Drilldown(Highcharts);

interface IncTreeMapProps {
  series: TreeMapSeries[];
  containerElemClass: string;

  drillDownSeries?: Array<HighChartsDrilldownSeries<TreeMapSeries>>;
  options?: TreeMapOptions;
  onRender?: (chartInstance: Chart) => void;
  seriesName?: string;
}

export const IncTreeMap: React.FC<IncTreeMapProps> = (props: IncTreeMapProps) => {
  const { series, options, onRender, containerElemClass, drillDownSeries, seriesName } = props;

  const {
    getDataLabel: pGetDataLabel,
    getTooltip,
    hideDataLabels,
    onClick,
    showLegends,
    tooltipValueFormatter,
    restOptions
  } = useMemo(() => {
    const {
      getDataLabel: pGetDataLabel,
      getTooltip,
      hideDataLabels,
      onClick,
      showLegends,
      tooltipValueFormatter,
      ...restOptions
    } = options || {};

    return {
      getDataLabel: pGetDataLabel,
      getTooltip,
      hideDataLabels,
      onClick,
      showLegends,
      tooltipValueFormatter,
      restOptions
    };
  }, [options]);

  const showDataLabels = !hideDataLabels;
  const ref = useRef();
  const forceUpdate = useForceUpdate();
  const [chartOptions, setChartOptions] = useState<Options>(null);

  const getChartDimensions = useCallback(() => {
    const canvasElem: HTMLElement = ref.current;
    if (canvasElem) {
      const widgetItemContainer: Element = canvasElem.closest(`.${containerElemClass}`);
      const dimensions = (widgetItemContainer || canvasElem)?.getBoundingClientRect();
      if (dimensions) {
        const { height } = dimensions;
        const { width } = dimensions;
        return {
          width,
          height
        };
      }
    }
    return {
      width: -1,
      height: -1
    };
  }, [containerElemClass, ref]);

  // Calculate dimensions on every render
  const { width, height } = getChartDimensions();

  const getDataLabel = useCallback(
    (element: TreeMapSeries) => {
      if (pGetDataLabel) {
        return pGetDataLabel(element);
      }
      // colorValue is a color code value ranges between 1-10 assigned inside the TreeMapOptionsBuilder
      const { colorValue, percentChange, shapeArgs = {} } = element as any;

      const textClassName = colorValue && colorValue <= 2 ? "data-label-text-dark" : "data-label-text-light";
      const size = colorValue > 2 ? "regular" : "small";
      const direction = percentChange > 0 ? "up" : "down";

      /**
       * Highchart provides the pont dimension in shapeeArgs
       */
      const { width: treeLeafWidth = 1000, height: treeLeafHeight = 1000 } = shapeArgs;
      /**
       * max width percent renderer can take with 2 decimal points
       */
      const PERCENT_RENDERER_WIDTH = 67;
      /**
       * Height for label + percent renderer
       */
      const DATA_LABEL_HEIGHT = 36;

      const SHORT_LABEL_SUFFIX = "...";

      const elementName = element.name || "";
      const elNameSize = elementName.length || 1;

      const labelWidth = getStringPossibleWidth(elementName, 14, null, 500).width;
      const labelHeight = getStringPossibleWidth(elementName, 14, null, 500).height;

      const perCharWidth = Math.floor(labelWidth / elNameSize);
      const maxChar = Math.floor(treeLeafWidth / perCharWidth) - SHORT_LABEL_SUFFIX.length;
      const label = treeLeafWidth < labelWidth ? elementName.slice(0, maxChar) + SHORT_LABEL_SUFFIX : elementName;

      return (
        <div className="inc-flex-column inc-flex-center-vertical">
          {treeLeafHeight > labelHeight && (
            <>
              <span className={textClassName}>{label}</span>
              {treeLeafWidth > PERCENT_RENDERER_WIDTH &&
                treeLeafHeight > DATA_LABEL_HEIGHT &&
                typeof percentChange === "number" && (
                  <IncPercentRenderer
                    direction={direction}
                    size={size}
                    value={percentChange}
                  />
                )}
            </>
          )}
        </div>
      );
    },
    [pGetDataLabel]
  );

  useEffect(() => {
    if (ref.current) {
      forceUpdate();
    }
  }, [ref, forceUpdate]);

  useEffect(() => {
    if (height <= 0 || !width) {
      return;
    }

    const options = new TreeMapOptionsBuilder(restOptions || {})
      .setChartFields(width, height)
      .clearSeriesData()
      .setSeriesData(series, showDataLabels, getDataLabel, getTooltip, onClick, seriesName)
      .setDrillDownSeries(drillDownSeries, showDataLabels, getDataLabel, getTooltip, onClick)
      .setDataLabels(showDataLabels)
      .setToolTip(getTooltip, tooltipValueFormatter)
      .setLegend(showLegends)
      .build();

    setChartOptions(options);
  }, [
    height,
    width,
    showDataLabels,
    series,
    onClick,
    getTooltip,
    getDataLabel,
    showLegends,
    drillDownSeries,
    seriesName,
    tooltipValueFormatter,
    restOptions
  ]);

  const highchartsCallback = useCallback(
    (chart: Chart) => {
      forceUpdate();
      onRender && onRender(chart);
    },
    [onRender, forceUpdate]
  );

  return (
    <div
      className={containerElemClass}
      ref={ref}
    >
      {chartOptions && (
        <HighchartsReact
          callback={highchartsCallback}
          highcharts={Highcharts}
          options={chartOptions}
        />
      )}
    </div>
  );
};
