import React, { FC, useCallback, memo, useState, useMemo, useEffect } from "react";
import { IncFaIcon, IncTextfield, IncButton, IncSmartText, IncAvatar } from "@inception/ui";
import { debounce, startCase } from "lodash";
import { useChatBotStore, setSelectedConversationId, setConversationsList, setConversations } from "../context";
import { NEW_CONVERSATION_ID } from "../constants";
import { ConversationResponse, ConversationFragmentType, ConversationEntry } from "../../services/api/chat";
import { VerticallyCenteredRow, LoadingSpinner } from "../../components";
import { useFetchAllConversations } from "../hooks";
import { useLoggedInUserInfo, iconInfoMap } from "../../core";
import { ReactComponent as EmptyState } from "../../../images/chat-bot/chat-empty.svg";
import { getChatTimeStampStr, getVisualisationFromVizOption, getConversationListFromConversations } from "../utils";

interface Props {}

export const ConversationsList: FC<Props> = () => {
  const { state, dispatch } = useChatBotStore();

  const { conversationsList: conversationMetaList, selectedConversationId: selectedConversation } = state;

  const { data, error, isError, isFetching } = useFetchAllConversations();

  useEffect(() => {
    if (!isFetching && !isError) {
      const conversationList = getConversationListFromConversations(data);

      dispatch(setConversationsList(conversationList));
      dispatch(setConversations(data));
    }
  }, [data, dispatch, isError, isFetching]);

  const [searchText, setSearchText] = useState("");
  const [appliedSearchText, setAppliedSearchText] = useState("");

  const onAddConversation = useCallback(() => {
    dispatch(setSelectedConversationId(NEW_CONVERSATION_ID));
  }, [dispatch]);

  const updateAppliedSearchText = useMemo(
    () => debounce((searchText: string) => setAppliedSearchText(searchText), 300),
    []
  );

  const onSearchTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value || "";
      setSearchText(value);
      updateAppliedSearchText(value);
    },
    [updateAppliedSearchText]
  );

  const filteredConversations = useMemo(() => {
    if (appliedSearchText) {
      return conversationMetaList.filter(({ message, response }) => {
        const messageSearchStr = message || "";
        const responseSearchStr = JSON.stringify(response || {});
        return messageSearchStr.includes(appliedSearchText) || responseSearchStr.includes(appliedSearchText);
      });
    }

    return conversationMetaList;
  }, [appliedSearchText, conversationMetaList]);

  const conversationListEntries = useMemo(() => {
    if (isFetching) {
      return (
        <div className="empty-message">
          <LoadingSpinner titleText="Loading conversations..." />
        </div>
      );
    }

    if (isError) {
      return <div className="empty-message status-danger">Error fetching conversations: {error}</div>;
    }

    const numFilteredConversations = filteredConversations.length;
    if (numFilteredConversations) {
      return filteredConversations.map((entry, idx) => {
        const { chatId } = entry;
        const isSelected = chatId === selectedConversation;
        const onSelect = () => dispatch(setSelectedConversationId(chatId));
        const isLast = idx === numFilteredConversations - 1;

        return (
          <ConversationEntryRenderer
            conversationEntry={entry}
            isLast={isLast}
            isSelected={isSelected}
            key={chatId}
            onSelect={onSelect}
          />
        );
      });
    }

    return (
      <div className="empty-message">
        <div className="inc-flex-column inc-flex-center">
          <EmptyState className="empty-state-svg" />
          <div className="marginBt24">Get quick insights!</div>
          <IncButton
            color="secondary"
            onClick={onAddConversation}
          >
            <IncFaIcon
              className="marginRt6"
              iconName="question"
            />
            <VerticallyCenteredRow>Ask a query</VerticallyCenteredRow>
          </IncButton>
        </div>
      </div>
    );
  }, [dispatch, error, filteredConversations, isError, isFetching, onAddConversation, selectedConversation]);

  return (
    <div className="conversations-list">
      <VerticallyCenteredRow className="chat-actions inc-flex-grow">
        <IncTextfield
          className="inc-flex-grow"
          onChange={onSearchTextChange}
          placeholder="Search messages"
          value={searchText}
        />

        <IncButton
          className="new-conversation"
          color="primary"
          iconType="icon"
          onClick={onAddConversation}
        >
          <IncFaIcon iconName="pen" />
        </IncButton>
      </VerticallyCenteredRow>

      <div className="conversations-list--list">{conversationListEntries}</div>
    </div>
  );
};

type CEProps = {
  conversationEntry: ConversationEntry;
  isSelected: boolean;
  isLast: boolean;
  onSelect: () => void;
};

const ConversationEntryRenderer = memo<CEProps>(ceProps => {
  const { name } = useLoggedInUserInfo();

  const { conversationEntry, isSelected, onSelect } = ceProps;
  const { timeStampInMillis, message, response } = conversationEntry;

  const avatar = <IncAvatar>{name.charAt(0).toUpperCase()}</IncAvatar>;

  const latestMessageTimeStr = getChatTimeStampStr(timeStampInMillis);

  const messageJsx = getMessageJsx(message, response) as string;

  return (
    <>
      <VerticallyCenteredRow
        className="conversation-list-entry"
        data-selected={isSelected}
        onClick={onSelect}
      >
        {avatar}
        <div className="inc-flex-column inc-flex-grow marginLt10">
          <div className="username marginBt8">{name}</div>
          <IncSmartText
            text={messageJsx}
            textClass="last-message marginBt10"
          />
          <div className="timestamp">{latestMessageTimeStr}</div>
        </div>
      </VerticallyCenteredRow>
      <div className="separator" />
    </>
  );
});

const getMessageJsx = (message: string, response: ConversationResponse): any => {
  if (response) {
    const { fragment, message } = response;

    if (message) {
      return message;
    }

    const { fragment: fragmentData, type, responseInfo } = fragment[0] || {};

    if (type === ConversationFragmentType.NA) {
      const errors = (responseInfo?.errors || []).map(err => err.message);
      const message = errors.length ? errors.join("\n") : fragmentData?.response || "Unknown response";
      return message;
    } else if (type === ConversationFragmentType.MARKDOWN) {
      const errors = (responseInfo?.errors || []).map(err => err.message);
      const message = errors.length ? errors.join("\n") : fragmentData?.response || "Unknown response";
      return message;
    } else if (type === ConversationFragmentType.QUERY) {
      return (
        <VerticallyCenteredRow>
          <IncFaIcon
            className="marginRt10"
            iconName="code"
          />
          <VerticallyCenteredRow>Query</VerticallyCenteredRow>
        </VerticallyCenteredRow>
      );
    } else {
      const { vizFragment } = fragmentData || {};
      if (vizFragment) {
        const { viz } = vizFragment;
        const visualisation = getVisualisationFromVizOption(viz);
        const { iconName, style } = iconInfoMap[visualisation];
        return (
          <VerticallyCenteredRow>
            <IncFaIcon
              className="marginRt10"
              iconName={iconName}
              style={style}
            />
            <VerticallyCenteredRow>{startCase(visualisation)}</VerticallyCenteredRow>
          </VerticallyCenteredRow>
        );
      }

      return (
        <VerticallyCenteredRow>
          <IncFaIcon
            className="marginRt10"
            iconName="chart-bar"
          />
          <VerticallyCenteredRow>Visualisation</VerticallyCenteredRow>
        </VerticallyCenteredRow>
      );
    }
  }

  return message || "";
};
