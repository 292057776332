import { escape, merge } from "lodash";
import Highcharts, { DrilldownOptions } from "highcharts";
import { getInceptionTheme, HighChartsLegendClick, HighChartsNumberFormatter } from "@inception/ui";
import { CommonWidgetOptions } from "../../dashboard/models/BaseWidgetModel";

export const FONT_STYLE: Highcharts.CSSObject = {
  color: getInceptionTheme().inceptionColors.palette.BL50,
  fontSize: "12px",
  height: 16,
  fontWeight: "500"
};

export const tickColor = getInceptionTheme().plugins.graph.legendText;

export class BaseOptionsBuilder {
  chartOptions: Highcharts.Options = {};

  protected stacked = false;

  constructor(options?: CommonWidgetOptions & Highcharts.Options) {
    // set basic options
    this.chartOptions.chart = {
      margin: [0, 0, 0, 0],
      spacingTop: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0
    };
    this.chartOptions.accessibility = {
      enabled: false
    };
    this.chartOptions.credits = { enabled: false };
    this.chartOptions.boost = {
      enabled: true,
      allowForce: true,
      seriesThreshold: 10000,
      usePreallocated: true
    };
    this.chartOptions.plotOptions = {
      series: {
        states: {
          hover: {
            enabled: true,
            lineWidthPlus: 0
          }
        },
        // Please don't decrease this without talking with @akshay.
        // This is causing some weird behavior of join end points in case this is quite low in case of comparisons usecase
        boostThreshold: 2880,
        marker: {
          enabled: true,
          radius: 0,
          states: {
            hover: {
              enabled: true,
              radius: 4,
              radiusPlus: 0,
              fillColor: "#FFFFFF",
              lineWidth: 1
            }
          }
        },
        events: {
          legendItemClick: HighChartsLegendClick
        }
      },
      arearange: {
        showInLegend: true,
        lineWidth: 0,
        linkedTo: ":previous",
        color: "#aacfff",
        fillOpacity: 0.3,
        zIndex: 1,
        marker: {
          enabled: true,
          radius: 2,
          symbol: "circle"
        },
        states: {
          hover: {
            enabled: true
          }
        }
      },
      spline: {
        lineWidth: 1
      },
      line: {
        lineWidth: 1
      },
      pie: {
        showInLegend: true
      },
      column: {
        borderWidth: 0
      },
      areaspline: {
        fillOpacity: 0.1,
        lineWidth: 1
      }
    };

    this.chartOptions.series = [];
    this.chartOptions = merge(this.chartOptions, options);
  }

  setChartFields(width: number, height: number) {
    this.chartOptions.chart = {
      height,
      width,
      backgroundColor: "transparent",
      spacingBottom: 0,
      numberFormatter: HighChartsNumberFormatter
    };
    return this;
  }

  setPlotOptions(disableInteractions: boolean, stacked = false) {
    if (disableInteractions) {
      this.chartOptions.plotOptions.series.enableMouseTracking = false;
      if (!this.chartOptions.plotOptions.series.events) {
        this.chartOptions.plotOptions.series.events = {};
      }

      this.chartOptions.plotOptions.series.events.legendItemClick = () => false;
    }

    if (stacked) {
      this.chartOptions.plotOptions.column = {
        ...(this.chartOptions.plotOptions.column || {}),
        stacking: "normal"
      };

      this.chartOptions.plotOptions.bar = {
        ...(this.chartOptions.plotOptions.bar || {}),
        stacking: "normal"
      };

      this.stacked = true;
    }

    return this;
  }

  setTitle(title: string) {
    this.chartOptions.title = {};
    this.chartOptions.title.text = title;
    return this;
  }

  clearSeriesData() {
    this.chartOptions.series = [];
    return this;
  }

  setLegend(showLegend: boolean) {
    this.chartOptions.series.forEach(s => (s.showInLegend = showLegend));
    this.chartOptions.legend = {
      align: "right",
      margin: 16,
      // The following symbol relateda settings are required to not show the default highcharts symbols.
      // We want to show the square symbol always which is done on the labelFormatter.
      symbolPadding: 0,
      symbolHeight: 0,
      symbolWidth: 0,
      squareSymbol: false,
      symbolRadius: 0,
      itemMarginBottom: 6,
      enabled: showLegend !== false,
      useHTML: true,
      itemStyle: FONT_STYLE,
      layout: "vertical",
      verticalAlign: "middle",
      labelFormatter: function () {
        const ctx = this as unknown as Highcharts.Point;
        const { color, name, visible } = ctx;
        const sqStyle = `background-color: ${color};`;
        const style = `opacity: ${visible ? "1" : "0.5"}`;

        return `
          <div class='inc-highcharts-legend' style="${style}">
            <div class='inc-highcharts-square-symbol' style="${sqStyle}"></div>
            <div class="legend-title" title="${escape(name)}">${name}</div>
          </div>
        `;
      }
    };
    return this;
  }

  setDrillDownSeriesOptions(drillDownOptions: DrilldownOptions) {
    this.chartOptions.drilldown = {
      ...drillDownOptions,
      activeAxisLabelStyle: {
        textDecoration: "none"
      },
      activeDataLabelStyle: {
        textDecoration: "none"
      }
    };
    return this;
  }

  build(): Highcharts.Options {
    return {
      ...this.chartOptions
    };
  }
}

export default BaseOptionsBuilder;
