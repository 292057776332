import { IncButton, IncFaIcon } from "@inception/ui";
import React, { useCallback, useRef } from "react";
import { useToggleState } from "../../../core";
import { LoadingSpinner } from "../../../components";
import { getEntityVariableModel } from "../../variables/CohortVariableUtils";
import { BizEntityType, BizField } from "../../../services/api/explore";
import { CohortVariableModel } from "../../models/VariableModel";
import CohortVariableImpl from "../../model-impl/CohortVariableImpl";
import { EntityFilterSelectorPopup } from "./EntityFilterSelectorPopup";

interface Props {
  onAddEntityFilter: (bizEntityType: string, variable: CohortVariableModel) => void;
  onAddCohortFilter: (entityTypeId: BizEntityType["entityTypeId"]) => void;
  isMetaLoading?: boolean;
  selectedCohortVariables: CohortVariableImpl[];
}

export const AddEntityFilter: React.FC<Props> = (props: Props) => {
  const { isMetaLoading = false, onAddEntityFilter, onAddCohortFilter, selectedCohortVariables } = props;

  const { isOpen: isOpenEntityFilterPopup, toggle: toggleEntityFilterPopUp } = useToggleState();

  const anchorEl = useRef();

  const onSelect = useCallback(
    (bizEntityType: BizEntityType["entityTypeId"], bizField: BizField) => {
      const { entityField } = bizField;
      const { propName } = entityField;
      const variable = getEntityVariableModel(bizEntityType, "", propName, bizField);

      onAddEntityFilter(bizEntityType, variable);
      toggleEntityFilterPopUp();
    },
    [onAddEntityFilter, toggleEntityFilterPopUp]
  );

  const onSelectCohort = useCallback(
    (entityTypeId: BizEntityType["entityTypeId"]) => {
      onAddCohortFilter(entityTypeId);
      toggleEntityFilterPopUp();
    },
    [onAddCohortFilter, toggleEntityFilterPopUp]
  );

  return (
    <>
      <div ref={anchorEl}>
        <IncButton
          color="secondary-blue"
          disabled={isMetaLoading}
          onClick={toggleEntityFilterPopUp}
        >
          {!isMetaLoading && (
            <>
              <IncFaIcon
                className="marginRt4"
                iconName="filter"
              />
              Add Entity Filter
            </>
          )}
          {isMetaLoading && <LoadingSpinner titleText="Add Filter" />}
        </IncButton>
      </div>

      {isOpenEntityFilterPopup && (
        <EntityFilterSelectorPopup
          anchorEl={anchorEl.current}
          onClose={toggleEntityFilterPopUp}
          onSelect={onSelect}
          onSelectCohort={onSelectCohort}
          selectedCohortVariables={selectedCohortVariables}
          show={isOpenEntityFilterPopup}
        />
      )}
    </>
  );
};
