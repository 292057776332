import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IncButton, IncFaIcon, IncModal, IncModalProps, IncSmartText } from "@inception/ui";
import { cloneDeep } from "lodash";
import { OpFragmentEditProps } from "../types";
import { VerticallyCenteredRow } from "../../components";
import { useToggleState } from "../../core";
import { OpCreationFragmentEditType } from "../../services/api/operationalise";
import { RollingFrequencyEditorV2 } from "../../operationalise-v2/v3/editors/common";

type Props = OpFragmentEditProps;

export const LookBackFragmentEditor: FC<Props> = props => {
  const { opFragment, invalidFragmentJsx } = props;
  if (opFragment?.lookBack) {
    return <LookBackFragmentEditorInternal {...props} />;
  }

  return invalidFragmentJsx;
};

const LookBackFragmentEditorInternal: FC<Props> = props => {
  const { className, opFragment, onChange, onClose, showEditorInModal = false } = props;

  const { isOpen: isUpdateInProgress, open: startUpdate, close: finishUpdate } = useToggleState();

  const errorMessageRef = useRef("");

  const defLookBack = opFragment.lookBack;
  const [lookBack, setLookBack] = useState(cloneDeep(defLookBack));

  const resetLookBack = useCallback(() => {
    setLookBack(cloneDeep(defLookBack));
  }, [defLookBack]);

  useEffect(() => {
    resetLookBack();
  }, [resetLookBack]);

  const onCancelChanges = useCallback(() => {
    resetLookBack();
    onClose?.();
  }, [onClose, resetLookBack]);

  const onSaveChanges = useCallback(async () => {
    errorMessageRef.current = "";
    startUpdate();

    const { isError, message } = await onChange(
      {
        ...opFragment,
        lookBack: lookBack
      },
      OpCreationFragmentEditType.LOOKBACK
    );

    if (isError) {
      errorMessageRef.current = message;
    }

    finishUpdate();
  }, [finishUpdate, lookBack, onChange, opFragment, startUpdate]);

  const jsx = useMemo(
    () => (
      <div className={className}>
        <VerticallyCenteredRow className="flex-gap-8">
          <VerticallyCenteredRow className="inc-label-common">Every</VerticallyCenteredRow>

          <RollingFrequencyEditorV2
            onChange={setLookBack}
            rollingFreq={lookBack}
            skipPrefix
          />
        </VerticallyCenteredRow>

        {!showEditorInModal && (
          <VerticallyCenteredRow className="flex-gap-12 marginTp10">
            {!isUpdateInProgress && (
              <IncButton
                color="primary"
                label="Save Changes"
                onClick={onSaveChanges}
                size="small"
              />
            )}

            {isUpdateInProgress && (
              <IncButton
                color="primary"
                loading
                loadingText="Saving..."
                size="small"
              />
            )}

            {Boolean(errorMessageRef.current) && (
              <VerticallyCenteredRow className="status-danger flex-gap-10">
                <IncFaIcon
                  className="status-danger"
                  iconName="exclamation-triangle"
                />
                <IncSmartText
                  className="status-danger"
                  text={errorMessageRef.current}
                />
              </VerticallyCenteredRow>
            )}

            {!isUpdateInProgress && (
              <IncButton
                color="secondary-blue"
                label="Cancel"
                onClick={onCancelChanges}
                size="small"
              />
            )}
          </VerticallyCenteredRow>
        )}
      </div>
    ),
    [className, isUpdateInProgress, lookBack, onCancelChanges, onSaveChanges, showEditorInModal]
  );

  const { close: closeModal, isOpen: IsModalOpen } = useToggleState(true);

  const actions: IncModalProps["actions"] = useMemo(
    () => ({
      primary: {
        label: "Save Changes",
        onClick: () => {
          closeModal();
          onSaveChanges();
        },
        color: "primary",
        showLoader: isUpdateInProgress
      },
      secondary: {
        label: "Cancel",
        onClick: () => {
          closeModal();
          onCancelChanges();
        },
        color: "secondary"
      }
    }),
    [closeModal, isUpdateInProgress, onCancelChanges, onSaveChanges]
  );

  return (
    <>
      {!showEditorInModal && jsx}
      {showEditorInModal && (
        <IncModal
          actions={actions}
          contentClassName="op-fragment-editors-modal-content padding16"
          onClose={closeModal}
          show={IsModalOpen}
          size="xxlg"
          titleText="Lookback"
          withActionsBorder
          withTitleBorder
        >
          {jsx}
        </IncModal>
      )}
    </>
  );
};
