import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { computeFurtherOccurrencesForCron } from "@inception/ui";
import { isEqual } from "lodash";
import { LoadingSpinner } from "../../../components";
import { USFieldWidgetUtils } from "../../../dashboard/widgets/USField/USFieldWidgetUtils";
import {
  BizDataQuery,
  DemoDataParams,
  TimeObj,
  UserServiceFieldMetricConfigDefinition,
  WidgetConfigUtils,
  fieldPickerApiService
} from "../../../services/api/explore";
import { OpSchedule, OpThreshold } from "../../../services/api/operationalise";
import { getCronExpressionForSpecificSchedule, getMetricSliceSet } from "../../../operationalise-v2/utils";
import { SCHEDULE_TYPES, UI_SCHEDULE_KEY } from "../../../operationalise-v2/constants";
import { scheduleOptions } from "../../../operationalise-v2/v3/editors/ScheduleEditor/options";
import { logger } from "../../../core";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { getTimeRange } from "../../../core/hooks/time-range/TimeRangeGetter";
import { ThresholdContext } from "./types";
import { ThresholdEditorV2 } from "./ThresholdEditorV2";

type Props = {
  bizDataQuery: BizDataQuery;
  baseMetricRollingFreq: TimeObj;
  baseMetricRollingFunction: string;
  schedule: OpSchedule;

  threshold: OpThreshold;
  onChange: (threshold: OpThreshold) => void;

  demoDataParams?: DemoDataParams;
  showPopperForManualThreshold?: boolean;
};

export const ThresholdEditorWrapper: FC<Props> = props => {
  const {
    onChange: onThresholdChange,
    threshold,
    bizDataQuery,
    baseMetricRollingFreq,
    baseMetricRollingFunction,
    schedule,
    demoDataParams,
    showPopperForManualThreshold = true
  } = props;

  const { generateDemoData = false } = demoDataParams || {};

  const metricOrFieldName = useMemo(() => getMetricOrFieldName(bizDataQuery), [bizDataQuery]);
  const metricSliceSet = useMemo(() => getMetricSliceSet(bizDataQuery), [bizDataQuery]);

  const buildingBlockDef = bizDataQuery?.buildingBlockConfig?.buildingBlockDef;
  const enableAutomaticThreshold = shouldEnableAutomaticThreshold(schedule);

  const { baseMetricAggFunction, isRateMetric, isEventOperationalize, isEventIDOperationalize } = useMemo(() => {
    if (bizDataQuery?.buildingBlockConfig) {
      const { buildingBlockDef } = bizDataQuery.buildingBlockConfig;
      const baseMetricAggFunction = buildingBlockDef?.aggregator;
      const fieldName = buildingBlockDef?.fieldConfig?.userServiceField?.fieldName;

      return {
        baseMetricAggFunction,
        isRateMetric: false,
        isEventOperationalize: !baseMetricAggFunction,
        isEventIDOperationalize:
          !baseMetricAggFunction &&
          (USFieldWidgetUtils.isEventIDField(fieldName) || USFieldWidgetUtils.isHasErrorField(fieldName))
      };
    } else if (bizDataQuery?.widgetConfig) {
      const { dataDefinition } = bizDataQuery.widgetConfig;
      const { metricId } = bizDataQuery.sliceSpec;
      const metricDef = dataDefinition.metrics[metricId];

      const baseMetricAggFunction = (metricDef as UserServiceFieldMetricConfigDefinition)?.userServiceFieldMetricConfig
        ?.aggregator;
      const fieldName = (metricDef as UserServiceFieldMetricConfigDefinition)?.userServiceFieldMetricConfig
        ?.userServiceField?.fieldName;

      return {
        baseMetricAggFunction,
        isRateMetric: metricDef.sourceType === "expression",
        isEventOperationalize: !baseMetricAggFunction,
        isEventIDOperationalize:
          !baseMetricAggFunction &&
          (USFieldWidgetUtils.isEventIDField(fieldName) || USFieldWidgetUtils.isHasErrorField(fieldName))
      };
    }

    return {
      baseMetricAggFunction: "count",
      isRateMetric: false
    };
  }, [bizDataQuery]);

  const { eventTypeId, entityTypeId } = useMemo(
    () => WidgetConfigUtils.getEntityTypeAndEventTypeFromBizDataQuery(bizDataQuery),
    [bizDataQuery]
  );

  const [context, setContext] = useState<ThresholdContext>({
    entityTypeId,
    entityTypeName: entityTypeId || "",
    eventTypeId,
    eventTypeName: eventTypeId || "",
    isFieldOperationalize: Boolean(buildingBlockDef?.fieldConfig?.userServiceField),
    isRateMetric
  });

  const [isContextLoading, setIsContextLoading] = useState(true);
  const fetchContext = useCallback(async () => {
    setIsContextLoading(true);

    const timeRange = getTimeRange();
    const { fromMillis, toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);

    const eventTypeInfoPromise = eventTypeId
      ? fieldPickerApiService.getUserserviceInfo(eventTypeId, fromMillis, toMillis, generateDemoData)
      : Promise.resolve({
          data: {
            name: eventTypeId
          },
          error: false,
          message: ""
        });

    const entityTypeInfoPromise = entityTypeId
      ? fieldPickerApiService.getBizEntityType(entityTypeId, generateDemoData)
      : Promise.resolve({
          data: {
            name: entityTypeId
          },
          error: false,
          message: ""
        });

    const [eventTypeInfoResult, entityTypeInfoResult] = await Promise.allSettled([
      eventTypeInfoPromise,
      entityTypeInfoPromise
    ]);

    let eventTypeName = eventTypeId;
    let entityTypeName = entityTypeId;

    if (eventTypeInfoResult.status === "fulfilled") {
      const { data, error, message } = eventTypeInfoResult.value;

      if (error) {
        logger.error("ThresholdEditorWrapper", "Error fetching event type info", message);
      } else {
        eventTypeName = data?.name || eventTypeId;
      }
    } else {
      logger.error("ThresholdEditorWrapper", "Error fetching event type info", eventTypeInfoResult.reason);
    }

    if (entityTypeInfoResult.status === "fulfilled") {
      const { data, error, message } = entityTypeInfoResult.value;

      if (error) {
        logger.error("ThresholdEditorWrapper", "Error fetching entity type info", message);
      } else {
        entityTypeName = data?.name || entityTypeId;
      }
    } else {
      logger.error("ThresholdEditorWrapper", "Error fetching entity type info", entityTypeInfoResult.reason);
    }

    setContext(prev => ({
      ...prev,
      eventTypeName,
      entityTypeName
    }));

    setIsContextLoading(false);
  }, [entityTypeId, eventTypeId, generateDemoData]);

  useEffect(() => {
    if (!isEventIDOperationalize) {
      fetchContext();
    }
  }, [fetchContext, isEventIDOperationalize]);

  return (
    <>
      {!isEventIDOperationalize && (
        <>
          {isContextLoading && <LoadingSpinner titleText="Fetching info..." />}
          {Boolean(threshold) && !isContextLoading && (
            <ThresholdEditorV2
              baseMetricAggFunction={baseMetricAggFunction}
              baseMetricRollingFreq={baseMetricRollingFreq}
              baseMetricRollingFunction={baseMetricRollingFunction}
              bizDataQuery={bizDataQuery}
              enableAutomaticThreshold={enableAutomaticThreshold}
              isEventIDOperationalize={isEventIDOperationalize}
              isEventOperationalize={isEventOperationalize}
              metricOrFieldName={metricOrFieldName}
              metricSliceSet={metricSliceSet}
              onChange={onThresholdChange}
              opThreshold={threshold}
              pickerContext={context}
              showPopperForManualThreshold={showPopperForManualThreshold}
            />
          )}
        </>
      )}
    </>
  );
};

const getMetricOrFieldName = (bizDataQuery: BizDataQuery): string => {
  const { buildingBlockConfig, widgetConfig, sliceSpec } = bizDataQuery || {};

  if (buildingBlockConfig) {
    const { buildingBlockDef, name } = buildingBlockConfig;
    return buildingBlockDef?.name || name;
  } else if (widgetConfig) {
    const { dataDefinition, labels, name: widgetName } = widgetConfig;
    const { metricId } = sliceSpec;
    const metricDef = dataDefinition.metrics[metricId] as UserServiceFieldMetricConfigDefinition;
    return labels?.name || metricDef?.name || widgetName || "";
  }

  return "";
};

const shouldEnableAutomaticThreshold = (opSchedule: OpSchedule) => {
  if (opSchedule) {
    const { labels, schedule } = opSchedule;

    const scheduleStr = labels?.[UI_SCHEDULE_KEY];
    const existsInLabels = [
      SCHEDULE_TYPES.everyMinute,
      SCHEDULE_TYPES.every5Minutes,
      SCHEDULE_TYPES.every15Minutes,
      SCHEDULE_TYPES.every30Minutes,
      SCHEDULE_TYPES.everyHour,
      SCHEDULE_TYPES.everyDay,
      SCHEDULE_TYPES.everyWeek,
      SCHEDULE_TYPES.everyMonth
    ].includes(scheduleStr);

    if (existsInLabels) {
      return true;
    }

    if (schedule?.specificScheduleConfig) {
      const opSchCronExpr = getCronExpressionForSpecificSchedule(schedule.specificScheduleConfig);
      const matchingOpt = scheduleOptions.find(opt => {
        if (opt.data) {
          const optCronExpr = getCronExpressionForSpecificSchedule(opt.data);

          const optCronOccurrances = computeFurtherOccurrencesForCron(optCronExpr, 3);
          const cronOccurrances = computeFurtherOccurrencesForCron(opSchCronExpr, 3);

          return isEqual(optCronOccurrances, cronOccurrances);
        }

        return false;
      });

      return Boolean(matchingOpt);
    }
  }

  return false;
};
