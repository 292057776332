import React, { FC, useMemo, memo, CSSProperties, useEffect, useRef, useState, useCallback } from "react";
import { IncFaIcon, IncSmartText, IncPopper, generateId, formatNumber, getStringPossibleWidth } from "@inception/ui";
import { cx, css } from "emotion";
import {
  IncidentSliceContribution,
  IncidentSliceContributionValue,
  getDisplayTagNameForUSFieldSlice,
  UserServiceFilterExpression,
  BizFieldPredicate,
  IncidentSlicesContribution
} from "../../../services/api/explore";
import { VerticallyCenteredRow } from "../../flex-components";
import { ENTITY_TAG, getBizFieldPredicateForEntityField } from "../../../utils";
import { DeepLinkValueList } from "../../../services/api/explore/types/deepLinkTypes";
import { useInceptionRoute } from "../../../core";
import { getCountOfGroupsByTagValue } from "./utils";

interface Props {
  keyContributors: IncidentSliceContribution[];
  nonGroupedKeyContributors: IncidentSlicesContribution[];
  primaryMetricName: string;

  lookupData?: Record<string, string>;
  deepLinkData?: Record<string, DeepLinkValueList>;
  numContributors?: number;

  presetTagNames?: string[];

  showAll?: boolean;
  setNumExtraItems?: (numExtraItems: number) => void;

  onAddEntityFilter?: (entityFilter: BizFieldPredicate) => void;
  onAddEventFilter?: (eventFilter: UserServiceFilterExpression) => void;
}

const MAX_CONTRIBUTORS = 4;
const MAX_PILLS = 30;

export const KeyContributors: FC<Props> = memo(props => {
  const {
    keyContributors,
    numContributors = 3,
    lookupData,
    presetTagNames,
    showAll,
    setNumExtraItems,
    onAddEntityFilter,
    onAddEventFilter,
    nonGroupedKeyContributors,
    primaryMetricName,
    deepLinkData
  } = props;

  const tagNameMap = useMemo(() => {
    const tagNameMap: Record<string, string> = {};

    (keyContributors || []).forEach(({ slice }) => {
      const { displayTagName, tagName } = getDisplayTagNameForUSFieldSlice(slice);
      tagNameMap[tagName] = displayTagName;
    });

    return tagNameMap;
  }, [keyContributors]);

  const { sliceContributors, numExtraItems } = useMemo(() => {
    const pinnedContributorsIds = presetTagNames
      ? presetTagNames
      : (keyContributors || []).map(contr => contr.slice.tagName);

    const pinnedContributorsJsx: JSX.Element[] = [];
    pinnedContributorsIds.forEach((tagName, idx) => {
      const sliceContributors = (keyContributors || []).find(contr => contr.slice.tagName === tagName);
      if (sliceContributors) {
        const { slice } = sliceContributors;

        const { tagName, userServiceField } = slice;

        const { fieldName, entityField } = userServiceField;

        const isValidEntityFilter = tagName === ENTITY_TAG && Boolean(entityField);
        const isValidEventFieldFilter = tagName !== ENTITY_TAG;

        const shouldShowAddFilter = isValidEntityFilter
          ? Boolean(onAddEntityFilter)
          : isValidEventFieldFilter
            ? Boolean(onAddEventFilter)
            : false;

        const onAddFilter = shouldShowAddFilter
          ? (value: string) => {
              if (isValidEntityFilter) {
                const displayValue = lookupData?.[value] || value;
                const predicate = getBizFieldPredicateForEntityField(entityField, displayValue);
                onAddEntityFilter(predicate);
              }

              if (isValidEventFieldFilter) {
                const filExpr: UserServiceFilterExpression = {
                  field: userServiceField,
                  operator: "=",
                  value
                };
                onAddEventFilter(filExpr);
              }
            }
          : null;

        const key = `${tagName}-${fieldName}-${idx}`;

        pinnedContributorsJsx.push(
          <SliceContribution
            deepLinkData={deepLinkData}
            id={key}
            isPinned
            key={key}
            lookupData={lookupData}
            nonGroupedKeyContributors={nonGroupedKeyContributors}
            numContributors={numContributors}
            onAddFilter={onAddFilter}
            primaryMetricName={primaryMetricName}
            sliceContributors={sliceContributors}
            tagNameMap={tagNameMap}
          />
        );
      }
    });

    const numPinnedContributors = pinnedContributorsJsx.length;
    // const numUnpinnedContributors = unPinnedContributorsJsx.length;

    if (!numPinnedContributors) {
      return {
        sliceContributors: <div className="inc-text-subtext-mediun">No key contributors</div>,
        numExtraItems: 0
      };
    }

    const sliceContributors: JSX.Element[] = [];

    let numExtraItems = numPinnedContributors - MAX_CONTRIBUTORS;
    numExtraItems = numExtraItems > 0 ? numExtraItems : 0;

    if (showAll) {
      sliceContributors.push(...pinnedContributorsJsx);
    } else {
      const diffContributorsJsx = pinnedContributorsJsx.slice(0, MAX_CONTRIBUTORS);
      sliceContributors.push(...diffContributorsJsx);
    }

    return {
      sliceContributors,
      numExtraItems
    };
  }, [
    deepLinkData,
    keyContributors,
    lookupData,
    nonGroupedKeyContributors,
    numContributors,
    onAddEntityFilter,
    onAddEventFilter,
    presetTagNames,
    primaryMetricName,
    showAll,
    tagNameMap
  ]);

  useEffect(() => {
    setNumExtraItems && setNumExtraItems(numExtraItems);
  }, [numExtraItems, setNumExtraItems]);

  return (
    <div className="key-contributors">
      <div className="key-contributors--slices-wrapper">{sliceContributors}</div>
    </div>
  );
});

type SProps = {
  sliceContributors: IncidentSliceContribution;
  id: string;
  numContributors: number;
  isPinned: boolean;

  primaryMetricName: string;
  tagNameMap: Record<string, string>;
  nonGroupedKeyContributors: IncidentSlicesContribution[];

  onAddFilter?: (value: string) => void;
  lookupData?: Props["lookupData"];
  deepLinkData?: Props["deepLinkData"];
};

const SliceContribution: FC<SProps> = memo(props => {
  const {
    id,
    sliceContributors,
    numContributors,
    lookupData,
    isPinned,
    onAddFilter,
    nonGroupedKeyContributors,
    tagNameMap,
    primaryMetricName,
    deepLinkData
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = useCallback(() => setIsExpanded(prev => !prev), []);

  const { slice, values, total } = sliceContributors;

  const { displayTagName, tagName } = getDisplayTagNameForUSFieldSlice(slice);

  let numHiddenContributors = (values?.length || 0) - numContributors;
  numHiddenContributors = Math.max(0, numHiddenContributors);

  const contributorsToShow = isExpanded ? values : values.slice(0, numContributors);

  const widthPer = Math.floor(100 / contributorsToShow.length);
  const cssClassName = isExpanded ? "" : css(`width: calc(${widthPer}% - 8px);`);

  const groupsByTagValues = useMemo(
    () => getCountOfGroupsByTagValue(nonGroupedKeyContributors, tagName),
    [nonGroupedKeyContributors, tagName]
  );

  const contributorsJsx = contributorsToShow.map((contributor, cIdx) => {
    const cKey = `${id}-${cIdx}`;

    return (
      <ContributorPill
        className={cssClassName}
        contribution={contributor}
        deepLinkData={deepLinkData}
        groupsByTagValues={groupsByTagValues}
        isExpanded={false}
        key={cKey}
        lookupData={lookupData}
        onAddFilter={onAddFilter}
        primaryMetricName={primaryMetricName}
        tagNameMap={tagNameMap}
        total={total}
      />
    );
  });

  const wrapperClass = "inc-flex-row inc-flex-row-wrap";

  const contributorsToRender = contributorsJsx.slice(0, MAX_PILLS);

  return (
    <div
      className="key-contributors--slice"
      data-expanded={isExpanded}
    >
      <VerticallyCenteredRow className="marginBt8 width-100">
        {Boolean(numHiddenContributors) && (
          <IncFaIcon
            className="arrow-icon"
            iconName="chevron-circle-up"
            onClick={toggleExpand}
          />
        )}

        {displayTagName}

        {!isExpanded && Boolean(numHiddenContributors) && (
          <div
            className="marginLt10 inc-label-common inc-cursor-pointer"
            onClick={toggleExpand}
          >
            + {numHiddenContributors} more
          </div>
        )}

        <IncFaIcon
          className="marginLtAuto star-icon"
          data-pinned={isPinned}
          iconName="star"
          regular={!isPinned}
        />
      </VerticallyCenteredRow>
      <div className={wrapperClass}>{contributorsToRender}</div>
    </div>
  );
});

type CProps = {
  contribution: IncidentSliceContributionValue;
  total: number;
  className: string;
  isExpanded: boolean;

  primaryMetricName: string;
  tagNameMap: Record<string, string>;
  groupsByTagValues: Record<string, Record<string, number>>;

  onAddFilter?: (value: string) => void;
  lookupData?: Props["lookupData"];
  deepLinkData?: Props["deepLinkData"];
};

const ContributorPill: FC<CProps> = memo(props => {
  const {
    contribution,
    total,
    lookupData = {},
    className: pClassName = "",
    isExpanded,
    onAddFilter,
    groupsByTagValues,
    tagNameMap,
    primaryMetricName,
    deepLinkData
  } = props;

  const { navigate } = useInceptionRoute();

  const uniqId = useMemo(() => generateId(), []);

  const tooltipRef = useRef<HTMLDivElement>();

  const [showTooltip, setShowTooltip] = useState(false);
  const openTooltip = useCallback((e: any) => {
    e.stopPropagation();
    setShowTooltip(true);
  }, []);
  const closeTooltip = useCallback((e: any) => {
    e.stopPropagation();
    setShowTooltip(false);
  }, []);

  const shouldShowAddFilter = Boolean(onAddFilter);

  const { occurrence = 0, value } = contribution;

  const displayValue = lookupData?.[value] || value;

  const deepLinks = deepLinkData?.[value]?.link;
  const deepLinksExist = deepLinks?.length > 0;

  const percentage = (occurrence / (total || 1)) * 100;
  const numDecimals = percentage - Math.floor(percentage) ? PERCENTAGE_PRECISION : 0;
  const percentageStr = percentage.toFixed(numDecimals);

  const className = cx(
    {
      "key-contributors--contributor-pill": !isExpanded,
      "key-contributors--contributor-band": isExpanded,
      "inc-cursor-pointer": deepLinksExist,
      "status-info": deepLinksExist
    },
    pClassName
  );

  const onAddFilterClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onAddFilter(value);
  };

  const perSliceOccurrance = groupsByTagValues[value];

  let minValueWidth = getStringPossibleWidth(displayValue, 14).width;
  minValueWidth = Math.min(minValueWidth, 50);

  const valueWidthStyle: CSSProperties = {
    minWidth: minValueWidth,
    textDecoration: deepLinksExist ? "underline" : "none"
  };

  const style: CSSProperties = {
    width: isExpanded ? "50%" : "100%",
    minWidth: minValueWidth + 46
  };

  const onClick = () => {
    if (deepLinksExist) {
      deepLinks.forEach(linkEntry => {
        const { linkValue } = linkEntry;
        navigate(linkValue, { newTab: true });
      });
    }
  };

  return (
    <VerticallyCenteredRow
      className={className}
      data-has-add-filter={shouldShowAddFilter}
    >
      <VerticallyCenteredRow
        onClick={onClick}
        onMouseEnter={openTooltip}
        onMouseLeave={closeTooltip}
        ref={tooltipRef}
        style={style}
      >
        <div
          className="value-label"
          style={valueWidthStyle}
        >
          <IncSmartText text={displayValue} />
        </div>

        <div className="percentage-label marginLt8">({percentageStr}%)</div>

        {shouldShowAddFilter && (
          <IncFaIcon
            className="marginLt8 status-info inc-cursor-pointer add-filter"
            iconName="plus-circle"
            onClick={onAddFilterClick}
          />
        )}
      </VerticallyCenteredRow>

      {isExpanded && (
        <progress
          className="marginLt10 progress-bar--linear"
          max="100"
          value={percentage}
        />
      )}

      <IncPopper
        anchorEl={tooltipRef.current}
        placement="bottom"
        show={showTooltip}
      >
        <div
          className="inc-card-layout display-block"
          style={{ width: 300 }}
        >
          <VerticallyCenteredRow className="marginBt6">
            <IncSmartText
              className="width-75"
              text={displayValue}
            />
            <IncSmartText
              className="width-20 marginLt10"
              text={`${percentageStr}%`}
            />
          </VerticallyCenteredRow>

          <VerticallyCenteredRow className="marginBt6">
            <IncSmartText
              className="width-75"
              text={primaryMetricName}
            />
            <IncSmartText
              className="width-20 marginLt10"
              text={formatNumber(occurrence)}
            />
          </VerticallyCenteredRow>

          {Object.keys(perSliceOccurrance || {}).map((tagName, idx) => {
            const key = [uniqId, tagName, idx].join("-");
            return (
              <VerticallyCenteredRow
                className="marginBt6"
                key={key}
              >
                <IncSmartText
                  className="width-75"
                  text={tagNameMap[tagName] || tagName}
                />
                <IncSmartText
                  className="width-20 marginLt10"
                  text={perSliceOccurrance[tagName]?.toString() || "-"}
                />
              </VerticallyCenteredRow>
            );
          })}
        </div>
      </IncPopper>
    </VerticallyCenteredRow>
  );
});

const PERCENTAGE_PRECISION = 0;
