import { useCallback, useEffect, useState } from "react";
import { useTimeRange } from "../../../core";
import { logger } from "../../../core/logging/Logger";
import { BizFieldsWithAggregations, getBizFieldsWithAggregations } from "../../../utils/EntityUtils";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import DashboardImpl from "../../model-impl/DashboardImpl";
import { FilterWidgetImpl } from "../../widgets/FilterWidget/models";

export const useDashboardFilters = (dbImpl: DashboardImpl) => {
  const { widgets } = dbImpl;
  const [fieldValuesMap, setFieldValuesMap] = useState<Record<string, BizFieldsWithAggregations>>();
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const { timeRange } = useTimeRange();

  const fetchFilterFieldValues = useCallback(async () => {
    const fieldValuesResponse: Record<string, any> = {};
    const entityFilterWidgets = widgets.filter(
      w => w.type === "filter" && Boolean((w as FilterWidgetImpl).entityTypeId)
    );
    const { fromMillis, toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);

    const promises = entityFilterWidgets.map(async widget => {
      const widgetId = widget.id;
      const entityType = (widget as FilterWidgetImpl).entityTypeId;
      const { cohortId } = widget as FilterWidgetImpl;

      const response = await getBizFieldsWithAggregations(cohortId, entityType, fromMillis, toMillis);

      fieldValuesResponse[widgetId] = response;

      return null;
    });

    try {
      setIsFetching(true);
      await Promise.allSettled(promises);
      setFieldValuesMap(fieldValuesResponse);
      setError(false);
    } catch (e) {
      logger.error("Error fetching field suggrestions", e);
      setErrorText(e.message);
      setError(true);
    } finally {
      setIsFetching(false);
    }
  }, [widgets, timeRange]);

  useEffect(() => {
    fetchFilterFieldValues();
  }, [fetchFilterFieldValues]);

  return {
    fieldValuesMap,
    isFetching,
    isError,
    errorText
  };
};
