import { IncSelect, IncSelectOption, IncFaIcon } from "@inception/ui";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { cx } from "emotion";
import { isEqual } from "lodash";
import {
  FieldPickerContextDTO,
  FieldPickerOptionData,
  PickerFieldType,
  UserServiceField,
  ConditionWithPickerType,
  DemoDataParams
} from "../../services/api/explore";
import { FieldPickerUtils } from "../../utils";
import { getOptionFromString } from "../../utils/ExploreUtils";
import { FieldPickerContainer } from "../../components";
import { useForceUpdate, TimeRange } from "../../core";
import { FieldPickerModel } from "../../field-picker";
import { AutoCompleter } from "./Autocompleter";
import { ConditionsBuilderUtils, getInitialCondition } from "./utils";
import { SliceContext } from "./types";

interface ConditionsBuilderRowProps {
  fieldPickerModel?: FieldPickerModel;
  demoDataParams?: DemoDataParams;
  fieldTypes: PickerFieldType[];
  fieldPickerContext?: FieldPickerContextDTO;
  filter: ConditionWithPickerType | null;
  dependentFilters: ConditionWithPickerType[];
  bizEntityType?: string;
  sliceContext?: SliceContext;
  onChange: (filter: ConditionWithPickerType) => void;
  onDelete: () => void;
  showLabel?: boolean;
  removable?: boolean;
  getLatestTimeRange?: () => TimeRange;
  readOnly?: boolean;
  onFieldPickerFetch?: (fieldPickerModel: FieldPickerModel) => void;
}

export const ConditionsBuilderRow: React.FC<ConditionsBuilderRowProps> = props => {
  const {
    fieldPickerModel: pFieldPickerModel,
    fieldPickerContext,
    filter,
    dependentFilters,
    fieldTypes,
    sliceContext,
    onChange,
    onDelete,
    showLabel,
    removable,
    readOnly = false,
    demoDataParams,
    onFieldPickerFetch
  } = props;

  const prevPickerContext = useRef<FieldPickerContextDTO>(fieldPickerContext);
  const [fieldPickerModel, setFieldPickerModel] = useState(pFieldPickerModel);

  useEffect(() => {
    if (pFieldPickerModel) {
      setFieldPickerModel(pFieldPickerModel);
    }
  }, [pFieldPickerModel]);

  useEffect(() => {
    if (!isEqual(prevPickerContext.current, fieldPickerContext)) {
      prevPickerContext.current = fieldPickerContext;
      setFieldPickerModel(null);
    }
  }, [fieldPickerContext]);

  const conditionRef = useRef<ConditionWithPickerType>(getInitialCondition(filter, sliceContext));
  const currentCondition = conditionRef.current;

  const forceUpdate = useForceUpdate();

  const sliceTagOptions = useMemo(() => {
    if (sliceContext && sliceContext.tags) {
      return sliceContext.tags.map(tag => getOptionFromString(tag));
    }
    return [];
  }, [sliceContext]);

  const filterOptions = ConditionsBuilderUtils.getFilterOperators(currentCondition?.field);

  const updateConditionRef = useCallback(
    (field: FieldPickerOptionData, operator: string, value: string, tag: string, values: string[]) => {
      const newFilter = {
        field,
        operator,
        value,
        tag,
        values
      };
      conditionRef.current = Object.assign(newFilter);
      forceUpdate();
    },
    [forceUpdate]
  );

  const onFilterKeyChange = useCallback(
    (options: FieldPickerOptionData[]) => {
      const selOp = options[0];
      // set default operator equal when field is selected
      const defaultOperatorValue = ConditionsBuilderUtils.getDefaultOperatorValue(selOp, "");
      updateConditionRef(
        selOp,
        defaultOperatorValue,
        undefined,
        FieldPickerUtils.getPromSanitizedUSFName(selOp.payload as UserServiceField),
        []
      );
    },
    [updateConditionRef]
  );

  const onTagKeyChange = useCallback(
    (option: IncSelectOption) => {
      const selOp = option.value;
      const payload = sliceContext?.payload;

      // set default operator equal when tagKey is selected
      const defaultOperatorValue = ConditionsBuilderUtils.getDefaultOperatorValue(payload, "");
      updateConditionRef(payload, defaultOperatorValue, undefined, selOp, []);
    },
    [sliceContext, updateConditionRef]
  );

  const onOpChange = useCallback(
    (option: IncSelectOption) => {
      const { field, value, tag, values } = currentCondition;
      const nOperator = option.value;

      const isDoesNotExistOption = nOperator === ConditionsBuilderUtils.nullOperatorOpt.value;
      if (isDoesNotExistOption) {
        updateConditionRef(field, "=", null, tag, []);
      } else {
        const nValue = value === null ? undefined : value;
        updateConditionRef(field, nOperator, nValue, tag, values);
      }
    },
    [currentCondition, updateConditionRef]
  );

  const onValueChange = useCallback(
    (value: string | string[]) => {
      const { field, operator, tag } = currentCondition;

      if (Array.isArray(value)) {
        updateConditionRef(field, operator, undefined, tag, value);
      } else {
        updateConditionRef(field, operator, value, tag, []);
      }
    },
    [currentCondition, updateConditionRef]
  );

  useEffect(() => {
    const {
      field: conditionField,
      operator: conditionOp,
      value: conditionValue,
      values: conditionValues
    } = currentCondition;

    const { field: filterField, operator: filterOp, value: filterValue, values: filterValues } = filter || {};

    if (!conditionField || !conditionOp) {
      return;
    }

    const shouldTriggerOnChange =
      !ConditionsBuilderUtils.isEqualOptionData(conditionField, filterField) ||
      conditionOp !== filterOp ||
      conditionValue !== filterValue ||
      !isEqual(conditionValues, filterValues);

    if (shouldTriggerOnChange) {
      // debounce on change for value change
      onChange(currentCondition);
    }
  }, [currentCondition, filter, onChange]);

  const menuPortalTarget = useMemo(() => document.body, []);

  const isMulti = () => ["in", "not in"].includes(currentCondition.operator);

  const selectedFieldOptions = currentCondition.field ? [currentCondition.field] : [];
  const selectedSliceOptions = currentCondition.tag
    ? sliceTagOptions.find(tOp => tOp.label === currentCondition.tag)
    : null;
  const selectedFilterOption = ConditionsBuilderUtils.getSelectedOperator(
    currentCondition.operator,
    currentCondition.value,
    filterOptions
  );
  const isDoesNotExistOperator = selectedFilterOption?.value === ConditionsBuilderUtils.nullOperatorOpt.value;

  const onDataFetch = useCallback(
    (fieldPickerModel: FieldPickerModel) => {
      if (fieldPickerModel) {
        setFieldPickerModel(fieldPickerModel);
        onFieldPickerFetch?.(fieldPickerModel);
      }
    },
    [onFieldPickerFetch]
  );

  return (
    <div className="condition-builder-row">
      <div className="key-container">
        {!sliceContext && (
          <FieldPickerContainer
            demoDataParams={demoDataParams}
            fieldPickerContextDto={fieldPickerContext}
            fieldPickerModel={fieldPickerModel}
            fieldTypes={fieldTypes}
            hideLabel={!showLabel}
            label={showLabel ? "Field" : ""}
            onChange={onFilterKeyChange}
            onDataFetch={onDataFetch}
            readOnly={readOnly}
            selectedOptions={selectedFieldOptions}
          />
        )}

        {sliceContext && (
          <IncSelect
            label={showLabel ? "Label" : ""}
            onChange={onTagKeyChange}
            options={sliceTagOptions}
            placeholder="Choose label"
            readOnly={readOnly}
            value={selectedSliceOptions}
            wrapperClass="width-100"
          />
        )}
      </div>

      <div className="op-container">
        <IncSelect
          autoSort={false}
          label={showLabel ? "Condition" : ""}
          menuPlacement="auto"
          menuPortalTarget={menuPortalTarget}
          onChange={onOpChange}
          options={filterOptions}
          placeholder="Condition"
          readOnly={readOnly}
          value={selectedFilterOption}
        />
      </div>

      <div className="value-container">
        {!isDoesNotExistOperator && (
          <AutoCompleter
            context={currentCondition.field}
            dependentFilters={dependentFilters}
            isMulti={isMulti()}
            onChange={onValueChange}
            readOnly={readOnly}
            selected={isMulti() ? currentCondition.values : currentCondition.value}
            showLabel={showLabel}
            tag={currentCondition.tag}
          />
        )}
      </div>

      {removable && !readOnly && (
        <div
          className={cx("remove-container", showLabel ? "remove-container--with-label" : "")}
          onClick={onDelete}
        >
          <IncFaIcon
            className="trash-icon"
            iconName="trash-can"
            regular
          />
        </div>
      )}
    </div>
  );
};
