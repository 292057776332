import { without, max, flatten, uniq, intersection, isUndefined, isNumber, isEmpty } from "lodash";
import { TimeSeries, Tags, generateId } from "../../../../../platform/core";
import { IncTimeSeriesOptions } from "../../../../components/time-series/types";
import getChartColor from "../../../../components/charts/colors";

import { SeriesOverride, ChartPoint } from "./model";
import TimeSeriesWidgetImpl from "./impl";

export type TagsGroupMap = Map<string, string[]>;
const knownFiltersToExclude: string[] = ["__name__", "metric"]; // known tags to be excluded from filters on the widget

export const MAX_NUM_SERIES_DISPLAYED = 30;

export class TimeSeriesHelper {
  timeseries: TimeSeries[];
  widget: TimeSeriesWidgetImpl;
  seriesOptions: IncTimeSeriesOptions[];
  static NUM_SERIES_THRESHOLD = MAX_NUM_SERIES_DISPLAYED; // By default only show NUM_SERIES_THRESHOLD series.

  constructor(ts: TimeSeries[], widget: TimeSeriesWidgetImpl) {
    this.timeseries = ts || [];
    this.widget = widget;
    this.process();
  }

  getSeries(): TimeSeries[] {
    return this.timeseries;
  }

  getHCSeriesOptions(): IncTimeSeriesOptions[] {
    return [];
  }

  getSeriesNames(): string[] {
    return this.timeseries.map((ts: TimeSeries) => ts.target);
  }

  getSeriesTagsMapPartial(series: IncTimeSeriesOptions[]): TagsGroupMap {
    let tagsMap: TagsGroupMap = new Map();

    this.timeseries.forEach((ts: TimeSeries) => {
      const { tags, target } = ts;
      const seriesFound = series.find(s => s.name === target);

      if (seriesFound) {
        tagsMap = this.constructTagsMap(tags, tagsMap);
      }
    });

    return tagsMap;
  }

  /**
   * Construct Map based on the avaialbel tags in the timeseries & filter them by widget filterOptions
   * returns Map<string, string[]> // Map<Geo => [Hyderabad, bangalore], Partner => [ICICI, Chase]>
   */
  getSeriesTagsMap(): TagsGroupMap {
    let tagsMap: TagsGroupMap = new Map();

    this.timeseries.forEach((ts: TimeSeries) => {
      const { tags: defTags = {}, eTags: tags = defTags } = ts;
      tagsMap = this.constructTagsMap(tags, tagsMap);
    });
    const filters = this.widget?.options?.tagFilters || {};
    const filtersIncluded: string[] = filters.include || [];
    const filtersExcluded: string[] = [...(filters.exclude || []), ...knownFiltersToExclude];

    const tagsFiltered = [...tagsMap].filter(([key]) => !filtersExcluded.includes(key));

    if (filtersIncluded.length) {
      return this.constructOrderedMap(filtersIncluded, new Map([...tagsFiltered]));
    }
    return new Map([...tagsFiltered]);
  }

  constructTagsMap(tags: Tags, tagsMap: TagsGroupMap): TagsGroupMap {
    const tagKeys = Object.keys(tags);

    if (tagKeys.length) {
      tagKeys.forEach((tagKey: string) => {
        const value = tags[tagKey];
        if (!tagsMap.has(tagKey)) {
          tagsMap.set(tagKey, []);
        }
        const values = tagsMap.get(tagKey);
        tagsMap.set(tagKey, uniq([...values, value]));
      });
    }
    return tagsMap;
  }

  constructOrderedMap = (orderedTags: string[], tagsMap: TagsGroupMap) => {
    if (!orderedTags.length) {
      return tagsMap;
    }
    const orderedMap: TagsGroupMap = new Map();

    orderedTags.forEach((tag: string) => {
      if (tagsMap.has(tag)) {
        const value = tagsMap.get(tag);
        orderedMap.set(tag, value);
      }
    });
    return orderedMap;
  };

  private getSeriesFilteredByTagValue = ({ key, values }: Record<string, any>): IncTimeSeriesOptions[] => {
    const seriesOptions: IncTimeSeriesOptions[] = [];

    this.seriesOptions.forEach(tsOpt => {
      const { tagsData } = tsOpt.custom;
      const tags = tagsData?.etags || {};

      values.forEach((val: string) => {
        if (tags[key] && tags[key] === val) {
          seriesOptions.push(tsOpt);
        }
      });
    });

    return seriesOptions;
  };

  /**
   * Recursively filter all the series names based on the selected filters;
   * @param selectedOptions // Map<Geo => [Hyderabad, bangalore], Partner => [ICICI, Chase]>
   * returns Set['Geo: Hyderabad, Partner: ICICI, PaymentType: Apple Pay', 'Geo: Bangalore, Partner: Chase, PaymentType: AliPay']
   */

  getSeriesBySelectedOptions = (selectedOptions: Map<string, string[]>): IncTimeSeriesOptions[] => {
    const filteredSeriesOpts: IncTimeSeriesOptions[][] = [];

    for (const [key, value] of selectedOptions.entries()) {
      const curFilSeries = this.getSeriesFilteredByTagValue({
        key,
        values: value
      });

      filteredSeriesOpts.push(curFilSeries);
    }

    return intersection(...filteredSeriesOpts);
  };

  getSeriesSize(): number {
    return this.timeseries.length;
  }

  getSeriesThreshold(): number {
    return this.widget?.options?.showSeriesDropdown && this.getSeriesSize() > TimeSeriesHelper.NUM_SERIES_THRESHOLD
      ? TimeSeriesHelper.NUM_SERIES_THRESHOLD
      : this.getSeriesSize();
  }

  getSubsetSeries(count: number): IncTimeSeriesOptions[] {
    return this.seriesOptions.slice(0, count);
  }

  private process() {
    this.seriesOptions = this.processSeriesOverrides();
  }

  getMaxValue(seriesOptions: IncTimeSeriesOptions[] = this.seriesOptions): number {
    // SeriesOptionsType is generic and SeriesAbandsOptions doesn't have data property. So typing to any
    const values = (seriesOptions as any[]).map(so => (so.data as ChartPoint[]).map(d => d[1]));
    return max(flatten(values)) || 0;
  }

  /**
   * Processes all timeseries with series overrides
   * separates out arearange matching series to compute a merged arearange series options payload
   * @returns IncTimeSeriesOptions
   */

  private processSeriesOverrides(): IncTimeSeriesOptions[] {
    const seriesOverrides = this.widget.seriesOverrides || [];
    const boundsSO = seriesOverrides.find(s => s.type === "timeseries" && s.chartType === "arearange");
    const overriddenBoundsSo = seriesOverrides.find(s => s.name === "Overriden Confidence Band");
    const timeseriesSO = without(seriesOverrides, boundsSO);

    const boundsSOAliases = boundsSO
      ? [boundsSO.alias ? new RegExp(boundsSO.alias) : null, boundsSO.alias2 ? new RegExp(boundsSO.alias2) : null]
      : [];

    const overriddenBoundsSoAliases = overriddenBoundsSo
      ? [
          overriddenBoundsSo.alias ? new RegExp(overriddenBoundsSo.alias) : null,
          overriddenBoundsSo.alias2 ? new RegExp(overriddenBoundsSo.alias2) : null
        ]
      : [];

    const seriesOptions = [] as IncTimeSeriesOptions[];

    let lowerAreaRange: TimeSeries;
    let upperAreaRange: TimeSeries;
    let overriddenLowerAreaRange: TimeSeries;
    let overriddenUpperAreaRange: TimeSeries;

    for (let i = 0; i < this.timeseries.length; i++) {
      const ts = this.timeseries[i];

      const match: boolean[] = boundsSOAliases.map(alias => alias && alias.test(ts.target));
      const match2: boolean[] = overriddenBoundsSoAliases.map(alias => alias && alias.test(ts.target));
      const isConfidenceSeries = match.includes(true);
      const isOverridenSeries = match2.includes(true);

      // skip the series that is part of arearange
      if (isOverridenSeries) {
        const [isLower, isUpper] = match;
        if (isLower) {
          overriddenLowerAreaRange = ts;
        } else if (isUpper) {
          overriddenUpperAreaRange = ts;
        }
        continue;
      } else if (isConfidenceSeries) {
        const [isLower, isUpper] = match;
        if (isLower) {
          lowerAreaRange = ts;
        } else if (isUpper) {
          upperAreaRange = ts;
        }
        continue;
      }

      let soMatched = null;
      for (let j = 0; j < timeseriesSO.length; j++) {
        const seriesOverride = timeseriesSO[j];
        if (seriesOverride.alias && new RegExp(seriesOverride.alias).test(ts.target)) {
          soMatched = seriesOverride;
          break;
        }
      }
      seriesOptions.push(this.generateSeriesOptionType(i, ts, soMatched));
    }

    const twoAreaRangeSeriesExist = Boolean(lowerAreaRange) || Boolean(upperAreaRange);
    if (boundsSO && twoAreaRangeSeriesExist) {
      // Only 2 series should match any arearange alias regexp
      const options = this.generateAreaRangeOption(boundsSO, [
        upperAreaRange || lowerAreaRange,
        lowerAreaRange || upperAreaRange
      ]);
      seriesOptions.push(options);
    }

    const overriddenTwoAreaRangeSeriesExist = Boolean(overriddenLowerAreaRange) || Boolean(overriddenUpperAreaRange);
    if (overriddenBoundsSo && overriddenTwoAreaRangeSeriesExist) {
      const options = this.generateAreaRangeOption(overriddenBoundsSo, [
        overriddenUpperAreaRange || overriddenLowerAreaRange,
        overriddenLowerAreaRange || overriddenUpperAreaRange
      ]);
      seriesOptions.push(options);
    }
    return seriesOptions;
  }

  /**
   *
   * @param  {SeriesOverride} seriesOverride
   * @returns IncTimeSeriesOptions
   */
  private generateAreaRangeOption(seriesOverride: SeriesOverride, timeseries: TimeSeries[]): IncTimeSeriesOptions {
    const seriesMax = timeseries[0],
      seriesMin = timeseries[1];

    let seriesMinExists = false;
    let seriesMaxExists = false;

    const map: Map<number, { max: number; min: number }> = new Map();
    if (seriesMax && seriesMin) {
      seriesMax.datapoints.forEach(dp => {
        const timestampInMillis = dp[1];
        const value = dp[0];
        seriesMaxExists = seriesMaxExists || (isNumber(value) ? true : !isEmpty(value));

        const [timestampsecs, replacedValue] = this.replaceNullOrNaN(timestampInMillis, value, false);
        map.set(timestampsecs, {
          max: replacedValue,
          min: null
        });
      });

      seriesMin.datapoints.forEach(dp => {
        const timestampInMillis = dp[1];
        const value = dp[0];
        seriesMinExists = seriesMinExists || (isNumber(value) ? true : !isEmpty(value));

        const [timestampsecs, replacedValue] = this.replaceNullOrNaN(timestampInMillis, value, false);
        const range = map.get(timestampsecs);
        if (range) {
          range.min = replacedValue;
        }
      });
    }

    let minAndMaxEqual = true;

    const areaRangeData: Array<[number, number, number]> = [];
    map.forEach((range, key) => {
      const { min, max } = range;
      if (seriesMaxExists && seriesMinExists) {
        areaRangeData.push([key, min, max]);
        minAndMaxEqual = minAndMaxEqual && min === max;
      } else if (seriesMaxExists) {
        areaRangeData.push([key, max, max]);
        minAndMaxEqual = true;
      } else {
        areaRangeData.push([key, min, min]);
        minAndMaxEqual = true;
      }
    });

    const seriesOptions: IncTimeSeriesOptions = minAndMaxEqual
      ? {
          refId: seriesMax.refId,
          id: seriesMax.target,
          type: "line",
          data: areaRangeData,
          opacity: 0.6,
          name: seriesOverride.name || seriesMax.target,
          custom: {
            tagsData: [
              {
                tags: seriesMin.tags,
                targets: seriesMin.target
              },
              {
                tags: seriesMax.tags,
                target: seriesMax.target
              }
            ],
            visible: isUndefined(seriesOverride?.visible) ? true : seriesOverride.visible || false
          } as any
        }
      : {
          refId: seriesMax.refId,
          id: seriesMax.target,
          type: "arearange",
          data: areaRangeData,
          linkedTo: ":previous",
          name: seriesOverride.name || seriesMax.target,
          custom: {
            tagsData: [
              {
                tags: seriesMin.tags,
                targets: seriesMin.target
              },
              {
                tags: seriesMax.tags,
                target: seriesMax.target
              }
            ],
            visible: isUndefined(seriesOverride?.visible) ? true : seriesOverride.visible || false
          } as any
        };
    const defaults = {
      lineWidth: 1,
      zIndex: 0,
      yAxis: 0,
      showInLegend: true
    } as IncTimeSeriesOptions;

    return Object.assign(defaults, seriesOverride || {}, seriesOptions) as IncTimeSeriesOptions;
  }

  replaceNullOrNaN(timestamp: number, value: number | string | null, replaceNullWithZero: boolean): ChartPoint {
    let computedValue = value === null || value === "NaN" ? null : value;
    if (replaceNullWithZero && computedValue === null) {
      computedValue = 0;
    }
    return [Math.floor(timestamp / 1000), computedValue as number];
  }

  /**
   *
   * @param  {number} idx // for color code generation
   * @param  {TimeSeries} ts
   * @param  {SeriesOverride} seriesOverride?
   * @returns IncTimeSeriesOptions
   */
  private generateSeriesOptionType(idx: number, ts: TimeSeries, seriesOverride?: SeriesOverride): IncTimeSeriesOptions {
    const { target, datapoints, refId } = ts;
    //const replaceNullWithZero = seriesOverride?.connectNulls !== false;
    const dps: ChartPoint[] = datapoints.map((dp: any) => {
      const timeStamp: number = dp[1];
      const value: any = dp[0];
      return this.replaceNullOrNaN(timeStamp, value, false);
    });

    const seriesOptions: IncTimeSeriesOptions = {
      id: generateId(),
      type: seriesOverride?.chartType || this.widget.properties.chartType || this.widget.chartType,
      data: dps,
      name: seriesOverride?.name || target,
      color: seriesOverride?.color ? seriesOverride?.color : getChartColor(idx),
      refId: refId,
      yAxis: ts.meta?.yAxis || seriesOverride?.yAxis || 0,
      custom: {
        tagsData: {
          target,
          tags: ts.tags,
          etags: ts.eTags
        },
        meta: ts.meta || {},
        visible: isUndefined(seriesOverride?.visible) ? true : seriesOverride.visible || false,
        dataType: ts.meta?.dataType || "STRING"
      }
    };

    // count of valid data points of the time series
    // let dataPointCount = 0;
    // datapoints.forEach((dp: any) => {
    //   if (dp[0] && dp[1]) {
    //     dataPointCount++;
    //   }
    // });

    const defaults = {
      lineWidth: 1,
      zIndex: 0,
      yAxis: 0,
      showInLegend: true,
      visible: true,
      connectNulls: false,
      marker: {
        enabled: true,
        radius: 1,
        symbol: "circle"
      }
    } as IncTimeSeriesOptions;

    return Object.assign(defaults, seriesOverride || {}, seriesOptions) as IncTimeSeriesOptions;
  }
}
