import { BizService } from "../explore/BizServiceCommon";
import { ShortenUrlRequest, ShortenUrlResponse, UrlFromShortKeyResponse } from "./types/shorten-url";

class ShortenUrlService extends BizService {
  async getShortUrl(value: string) {
    await this.init();
    const url = this.getBizEntityUrl("shortenUrl");
    const payload = {
      value
    };
    const request = () => this.datasource.post<ShortenUrlResponse, ShortenUrlRequest>(url, payload);
    return this.getResult(request);
  }

  async getUrlFromShortKey(key: string) {
    await this.init();
    const url = this.getBizEntityUrl(`shortenUrl/${key}`);
    const request = () => this.datasource.get<UrlFromShortKeyResponse, null>(url);
    return this.getResult(request);
  }
}

const shortenUrlApiService = new ShortenUrlService();

export default shortenUrlApiService;
