import { IncEditor } from "@inception/ui";
import React, { useCallback, useEffect, useState } from "react";
import { ScriptConfig } from "../transform-config/TransformConfig";

interface ScriptCompProps {
  config: ScriptConfig;
  onChange: (config: ScriptConfig) => void;
}

export const ScriptComp: React.FC<ScriptCompProps> = (props: ScriptCompProps) => {
  const { config, onChange } = props;
  const [value, setValue] = useState<string>("");

  const onScriptValueChange = useCallback(
    script => {
      onChange({
        multilineScript: script
      });
    },
    [onChange]
  );

  useEffect(() => {
    if (config) {
      setValue(config?.multilineScript ?? "");
    }
  }, [config]);

  return (
    <div className="inc-flex-row">
      <IncEditor
        focus
        maxLines={10}
        onChange={onScriptValueChange}
        setOptions={{
          showGutter: false
        }}
        value={value}
      />
    </div>
  );
};
