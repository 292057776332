import React, { FC, useMemo } from "react";
import { sortBy, isUndefined } from "lodash";
import { PieChart, PieSeries, PieChartOptions } from "../../../../../components";
import { DataFrame, generateId } from "../../../../../core";
import { OTHERS_PERCENT, OTHERS_LABEL, HighChartsDrilldownSeries } from "../../../../../components/charts";
import { eventFieldUtils } from "../../../../../utils";
import { EntityWidgetData } from "../../../../../biz-entity";
import { USFWRendererProps } from "./types";
import { getUSFieldChartColor, getFormattedValue, getSerieName, getFieldsFromLabels } from "./utils";
import { useCommonRendererFunctionality } from "./common";

type Props = USFWRendererProps & {
  showAsGauge?: boolean;
  showAsDonut?: boolean;
};

type PieSeriesResult = {
  series: PieSeries[];
  drillDownSeries: Array<HighChartsDrilldownSeries<PieSeries>>;
  aggValue: number;
};

export const PieChartRenderer: FC<Props> = props => {
  const {
    className,
    showAsGauge = false,
    dataType,
    currencyType,
    showAsDonut = false,
    loadingElement,
    noDataElement,
    aggregatedTags,
    displayAggregatedTags
  } = props;

  const { data, fieldName, metricName, isFetching, isError, dataExists } = useCommonRendererFunctionality(props);

  const { series, drillDownSeries, aggValue } = useMemo(
    () => constructPieSeries(data, aggregatedTags, displayAggregatedTags, dataType, fieldName, metricName),
    [aggregatedTags, data, dataType, displayAggregatedTags, fieldName, metricName]
  );

  const options = useMemo<PieChartOptions>(() => {
    const valueFormatterFn = (value: number) => getFormattedValue(fieldName, value, dataType, currencyType);

    const opts: PieChartOptions = {
      showLegend: !showAsGauge,
      customMode: showAsGauge ? "gauge" : showAsDonut ? "donut" : null,
      seriesName: eventFieldUtils.removeFieldsPrefix(fieldName),
      tooltipValueFormatter: (val: number | string) => valueFormatterFn(val as number)
    };

    if (showAsGauge && !isUndefined(aggValue)) {
      opts.stats = valueFormatterFn(aggValue);
    }

    return opts;
  }, [aggValue, currencyType, dataType, fieldName, showAsDonut, showAsGauge]);

  if (isFetching) {
    return loadingElement;
  }

  if (!dataExists || isError) {
    return noDataElement;
  }

  return (
    <PieChart
      containerElemClass={className}
      drillDownSeries={drillDownSeries}
      options={options}
      series={series}
    />
  );
};

export const constructPieSeries = (
  data: EntityWidgetData[],
  aggregatedTags: string[],
  displayAggregatedTags: string[],
  dataType: USFWRendererProps["dataType"],
  fieldName: string,
  metricName: string
): PieSeriesResult => {
  const dataframes: DataFrame[] = [];
  const compareDataFrames: DataFrame[] = [];

  data.forEach(datum => {
    const postAggData = datum.postAggResult.data;
    const compareData = datum.postAggResult.timeShiftData || {};

    const refId = Object.keys(postAggData)[0];

    const dfs = postAggData[refId]?.data || [];
    const cDfs = compareData[refId]?.data || [];

    dataframes.push(...dfs);
    compareDataFrames.push(...cDfs);
  });

  const shouldModifyMetricName = data.length < 2;

  const negateColors = fieldName.toLowerCase().includes("error");

  const pieSeries: PieSeries[] = [];
  const drillDownSeries: Array<HighChartsDrilldownSeries<PieSeries>> = [];

  const sortedDataFrames = sortBy(dataframes, [
    (df: DataFrame) => {
      const { fields } = df;
      const values = fields[1].data;
      const data = values[0];
      return data;
    }
  ]);
  sortedDataFrames.reverse();

  const top4Series = sortedDataFrames.splice(0, 4);
  let top4Val = 0;

  top4Series.forEach((df, i) => {
    const { fields, labels, eLabels, name } = df;
    const compareFields = getFieldsFromLabels(compareDataFrames, labels);
    const compareData = compareFields?.[1]?.data[0] || null;

    const values = fields[1].data;
    const data = values[0];
    top4Val += data;

    const { aggLabel } = shouldModifyMetricName
      ? getSerieName(aggregatedTags, displayAggregatedTags, labels, eLabels, metricName)
      : { aggLabel: name };

    pieSeries.push({
      name: aggLabel,
      value: data,
      color: getUSFieldChartColor(dataType, aggLabel, i, negateColors),
      drillDownId: null,
      custom: {
        compareData
      }
    });
  });

  if (sortedDataFrames.length > 0) {
    pieSeries.push({
      name: OTHERS_LABEL,
      value: Math.round((top4Val / (100 - OTHERS_PERCENT)) * OTHERS_PERCENT),
      color: getUSFieldChartColor(dataType, OTHERS_LABEL, 4, negateColors),
      drillDownId: OTHERS_LABEL,
      custom: {
        isOthersSeries: true
      }
    });

    updateDrillDownSeries(
      sortedDataFrames,
      drillDownSeries,
      OTHERS_LABEL,
      aggregatedTags,
      displayAggregatedTags,
      dataType,
      negateColors,
      metricName,
      compareDataFrames,
      shouldModifyMetricName
    );
  }

  const aggValue = pieSeries[0]?.value;

  return {
    series: pieSeries,
    drillDownSeries,
    aggValue
  };
};

const updateDrillDownSeries = (
  dataFrames: DataFrame[],
  drillDownSeries: Array<HighChartsDrilldownSeries<PieSeries>>,
  drilldownId: string,
  aggregatedTags: string[],
  displayAggregatedTags: string[],
  dataType: USFWRendererProps["dataType"],
  negateColors: boolean,
  metricName: string,
  compareDataFrames: DataFrame[],
  shouldModifyMetricName: boolean
) => {
  const clonedList = [...dataFrames];
  const top4Series = clonedList.splice(0, 4);

  const drillDownSeriesEntry: HighChartsDrilldownSeries<PieSeries> = {
    id: drilldownId,
    name: OTHERS_LABEL,
    series: []
  };
  let top4Val = 0;

  top4Series.forEach((df, i) => {
    const { fields, labels, eLabels, name } = df;
    const values = fields[1].data;
    const data = values[0];
    top4Val += data;

    const compareFields = getFieldsFromLabels(compareDataFrames, labels);
    const compareData = compareFields?.[1]?.data[0] || 0;

    const { aggLabel } = shouldModifyMetricName
      ? getSerieName(aggregatedTags, displayAggregatedTags, labels, eLabels, metricName)
      : { aggLabel: name };

    drillDownSeriesEntry.series.push({
      name: aggLabel,
      value: data,
      color: getUSFieldChartColor(dataType, aggLabel, i, negateColors),
      custom: {
        compareData
      }
    });
  });

  if (clonedList.length > 0) {
    const newOthersId = generateId();

    drillDownSeriesEntry.series.push({
      name: OTHERS_LABEL,
      value: Math.round((top4Val / (100 - OTHERS_PERCENT)) * OTHERS_PERCENT),
      color: getUSFieldChartColor(dataType, OTHERS_LABEL, 4, negateColors),
      drillDownId: newOthersId,
      custom: {
        isOthersSeries: true
      }
    });
    drillDownSeries.push(drillDownSeriesEntry);

    updateDrillDownSeries(
      clonedList,
      drillDownSeries,
      newOthersId,
      aggregatedTags,
      displayAggregatedTags,
      dataType,
      negateColors,
      metricName,
      compareDataFrames,
      shouldModifyMetricName
    );
  } else {
    drillDownSeries.push(drillDownSeriesEntry);
  }
};
