import { IncSelect } from "@inception/ui";
import { capitalize } from "lodash";
import React, { FC, useMemo } from "react";
import { WidgetSelectProperty } from "../types";

interface Props {
  property: WidgetSelectProperty;
  onUpdate: (newState: WidgetSelectProperty) => void;
}

const SelectPropertyRenderer: FC<Props> = props => {
  const { onUpdate: usrUpdate, property } = props;

  const {
    name,
    label = capitalize(name),
    disabled,
    options,
    value,
    placeholder = `Select ${name}`,
    info = ""
  } = property;

  let selection = options.filter(opt => opt.value === value)[0];
  selection = selection
    ? selection
    : {
        label: value as any,
        value: value as any
      };

  const onChange = (newSelection: any) => {
    const newProperty: WidgetSelectProperty = {
      ...property,
      value: newSelection.value
    };
    usrUpdate(newProperty);
  };

  const menuPortalTarget = useMemo(() => document.body, []);

  return (
    <IncSelect
      helpText={info}
      isDisabled={disabled}
      isMulti={false}
      label={label}
      menuPlacement="auto"
      menuPortalTarget={menuPortalTarget}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      value={selection}
    />
  );
};

export default SelectPropertyRenderer;
