import React, { useCallback, useState, useRef } from "react";
import { IncFaIcon, IncButton, IncClickAwayPopper } from "@inception/ui";
import { EventGroupBySelector, VerticallyCenteredRow } from "../../../../../components";
import { UserServiceFieldSliceSet, FieldPickerContextDTO } from "../../../../../services/api/explore";
import { TimeRange } from "../../../../../core";
import { AggregateTagSelectorProps, AggregateTagSelector } from "../AggregateTagSelector";

interface Props extends AggregateTagSelectorProps {
  fieldPickerContext: FieldPickerContextDTO;
  sliceSets: UserServiceFieldSliceSet[];
  onSliceSetsChange: (sliceSets: UserServiceFieldSliceSet[]) => void;
  getLatestTimeRange: () => TimeRange;
  isViewMode: boolean;
  queryConfigType: "widgetConfig" | "userServiceField";
}

export const GroupBySelector: React.FC<Props> = (props: Props) => {
  const {
    fieldPickerContext,
    sliceSets: eSliceSets,
    onSliceSetsChange,
    getLatestTimeRange,
    queryConfigType,
    isViewMode,
    aggregateTags,
    onAggregateTagsChange,
    ...restAggTagSelectorProps
  } = props;

  const [sliceSets, setSliceSets] = useState(eSliceSets);
  const [tagNames, setTagNames] = useState(aggregateTags);
  const usFieldQueryType = queryConfigType === "userServiceField";

  const shouldShowGroupByPicker = usFieldQueryType && !isViewMode;

  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);
  const openPopover = useCallback(() => setOpen(true), []);
  const closePopover = useCallback(() => {
    if (shouldShowGroupByPicker) {
      setSliceSets(eSliceSets);
    } else {
      setTagNames(aggregateTags);
    }
    setOpen(false);
  }, [aggregateTags, eSliceSets, shouldShowGroupByPicker]);

  const saveTagsOrSlices = useCallback(() => {
    if (shouldShowGroupByPicker) {
      onSliceSetsChange(sliceSets);
    } else {
      onAggregateTagsChange(tagNames);
    }

    setOpen(false);
  }, [onAggregateTagsChange, onSliceSetsChange, shouldShowGroupByPicker, sliceSets, tagNames]);

  return (
    <div>
      <div
        className="inc-cursor-pointer"
        onClick={openPopover}
        ref={anchorEl}
      >
        <IncFaIcon iconName="chart-pie" />
      </div>
      <IncClickAwayPopper
        anchorEl={anchorEl.current}
        onClickAway={() => {}}
        placement="bottom-end"
        show={open}
      >
        <div className="group-by-selector inc-flex-column">
          {!shouldShowGroupByPicker ? (
            <AggregateTagSelector
              aggregateTags={tagNames}
              label="Group by"
              onAggregateTagsChange={setTagNames}
              {...restAggTagSelectorProps}
            />
          ) : (
            <EventGroupBySelector
              canRemoveImplicitSlice={true}
              displayImplicitSlice
              fieldPickerContext={fieldPickerContext}
              getLatestTimeRange={getLatestTimeRange}
              onChange={setSliceSets}
              sliceSets={sliceSets}
            />
          )}
          <VerticallyCenteredRow className="marginTp16">
            <IncButton
              color="primary"
              onClick={saveTagsOrSlices}
            >
              Save
            </IncButton>
            <IncButton
              className="marginLt12"
              color="link"
              onClick={closePopover}
            >
              Cancel
            </IncButton>
          </VerticallyCenteredRow>
        </div>
      </IncClickAwayPopper>
    </div>
  );
};
