import React, { FC } from "react";
import { OpAnalysisCreationConfig, OpCreationConfig } from "../../services/api/operationalise";
import ScheduleEditorWrapper from "./ScheduleEditorWrapper";
import { OpAnalysisProvider } from "./context";
import AnalysisKpiEditor from "./AnalysisKPIEditor";
import TemplateFormatEditor from "./TemplateFormatEditor";
import SuppressionEditorWrapper from "./SuppressionEditorWrapper";
import DimensionsEditorWrapper from "./DimensionsEditorWrapper";
import AnalysisOperationalizeHeader from "./AnalysisOperationalizeHeader";
import DiagnosticFieldsEditorWrapper from "./DiagnosticFieldsEditorWrapper";
import CompareToAndRollingFrequencyEditorWrapper from "./CompareToAndRollingFrequencyEditorWrapper";
import { AnalysisTypesEditor } from "./AnalysisTypesEditor";

type Props = {
  opAnalysisCreationConfig: OpAnalysisCreationConfig;
  onStateChange?: (opAnalysisCreationConfig: OpAnalysisCreationConfig) => void;
  onSave?: (opAnalysisCreationConfig: OpCreationConfig, dryRun: boolean, callBack?: () => void) => void;
  showSave?: boolean;
  isSaveInProgress?: boolean;
  eventTypeId: string;
  hideDryRun?: boolean;
  hideConfigView?: boolean;
  isFetchingEventType?: boolean;
  hideTitle?: boolean;
};

const AnalysisOperationalize: FC<Props> = props => {
  const {
    opAnalysisCreationConfig,
    onStateChange,
    onSave,
    showSave = false,
    isSaveInProgress,
    eventTypeId,
    hideDryRun,
    hideConfigView,
    isFetchingEventType = false,
    hideTitle
  } = props;

  return (
    <OpAnalysisProvider opAnalysisConfig={opAnalysisCreationConfig}>
      <div className="analysis-editor--wrapper">
        <AnalysisOperationalizeHeader
          analysisConfig={opAnalysisCreationConfig}
          hideConfigView={hideConfigView}
          hideDryRun={hideDryRun}
          hideTitle={hideTitle}
          isSaveInProgress={isSaveInProgress}
          onSave={onSave}
          onStateChange={onStateChange}
          showSave={showSave}
        />
        <div
          className="analysis-editor--container"
          style={!showSave || !onSave ? null : wrapperStyle}
        >
          <AnalysisTypesEditor />
          {/* schedule */}
          <ScheduleEditorWrapper />
          {/* kpis & Related KPIs */}
          <AnalysisKpiEditor
            eventTypeId={eventTypeId}
            isFetchingEventTypeList={isFetchingEventType}
          />
          {/* template formate and templateformatJSON */}
          <TemplateFormatEditor />
          {/* dimensionsToAnalyze editor */}
          <DimensionsEditorWrapper eventTypeId={eventTypeId} />
          {/* dimensionsToAnalyze editor */}
          <DiagnosticFieldsEditorWrapper eventTypeId={eventTypeId} />
          {/* rolling freq and compare to offset editor */}
          <CompareToAndRollingFrequencyEditorWrapper />
          {/* suppression editor */}
          <SuppressionEditorWrapper eventTypeId={eventTypeId} />
        </div>
      </div>
    </OpAnalysisProvider>
  );
};

const wrapperStyle: React.CSSProperties = {
  height: "calc(100vh - 200px)",
  overflowY: "auto"
};

export default AnalysisOperationalize;
