import { SuppressionInfo } from "../../../services/api/explore";
import { RegionHighlight } from "../../../components/charts/plot-bands/RegionHighlights";
import { INCIDENT_COLORS } from "../../../components/incidentTimeSeries/TimeSeriesWidgetHelper";
import { MTSIncidentSummary } from "../../../services/api/operationalise";
import timeRangeUtils from "../../../utils/TimeRangeUtils";

export const Op10zeQueryWidgetUtils = {
  getSuppressionBands: (dSuppressionRegions: SuppressionInfo[]) => {
    const suppressionRegions = dSuppressionRegions.map(
      (x): RegionHighlight => ({
        color: INCIDENT_COLORS.suppressionRegion,
        from: x.start === x.end ? x.start - 2 : x.start,
        to: x.end
      })
    );

    return suppressionRegions;
  },
  getIncidentBands: (summary: MTSIncidentSummary[], widgetId: string, endTsMillis: number, isOpportunity: boolean) => {
    const incidentRegions: RegionHighlight[] = [];
    summary?.forEach((summary, sIdx) => {
      const { ranges, mtsId } = summary.summaryLine;
      ranges.forEach((range, idx) => {
        const regionId = [mtsId, sIdx, idx, widgetId].join("-");
        const { windowStartSec, endSec: defEndSec } = range;
        const endSec = parseInt(String(defEndSec), 10);

        incidentRegions.push({
          id: regionId,
          color: isOpportunity ? INCIDENT_COLORS.opportunityHighlight : INCIDENT_COLORS.incidents,
          from: parseInt(String(windowStartSec), 10),
          to: endSec > 0 ? endSec : timeRangeUtils.getSecondsFromMillis(endTsMillis)
        });
      });
    });

    return incidentRegions;
  }
};
