import { PointEventsOptionsObject, Point } from "highcharts";
import { IncVBarSeriesOptions } from "../../../../../../components/bar-chart/types";
import getChartColor from "../../../../../../components/charts/colors";
import { ENTITY_TAG } from "../../../../../../utils";
import { EntityWidgetData } from "../../../../../../biz-entity";

export const getHistogramBarSeriesFromData = (
  ewd: EntityWidgetData,
  sliceBy: string[],
  entityType: string,
  onSelect: OnSelectCallback
) => {
  const pointEvents: PointEventsOptionsObject = {
    click: function () {
      return clickCallback.bind(this)(onSelect);
    },
    select: function () {
      return selectCallback.bind(this)(onSelect);
    }
  };

  const data = Object.values(ewd?.postAggResult?.data || {})[0];

  const series: IncVBarSeriesOptions[] = [];

  const dataByBucket: Map<string, Map<number, string>> = new Map();
  const binsSet = new Set<number>();

  data?.data?.forEach(df => {
    const { fields, labels = {}, eLabels = labels } = df;

    const binStart = parseFloat(labels[BUCKET_TAG]);
    binsSet.add(binStart);

    const sliceValues = Object.keys(eLabels)
      .filter(k => sliceBy.includes(k))
      .map(k => {
        const value = eLabels[k];
        const displayKey = k === ENTITY_TAG ? entityType || k : k;
        return sliceBy?.length > 1 ? `${displayKey} : ${value}` : value;
      });
    const name = sliceValues.join(", ");

    const value = fields[1]?.data?.[0] as number;

    const mapEntry = dataByBucket.get(name) || new Map();
    mapEntry.set(binStart, value);
    dataByBucket.set(name, mapEntry);
  });

  const bins = Array.from(binsSet).sort((a, b) => a - b);

  let idx = 0;
  dataByBucket.forEach((data, serieName) => {
    const serie: IncVBarSeriesOptions = {
      type: "column",
      color: getChartColor(idx++),
      data: [],
      name: serieName,
      point: {
        events: pointEvents
      },
      allowPointSelect: true,
      cursor: "pointer"
    };

    bins.forEach(binStart => {
      const k = binStart.toString();
      const v = data.get(binStart) || 0;

      Boolean(v) && serie.data.push([k, v]);
    });

    series.push(serie);
  });

  const binsStrArr = bins.map(String);
  return {
    bins: binsStrArr,
    series
  };
};

const BUCKET_TAG = "bucket";

type OnSelectCallback = (binStart: string) => void;

const clickCallback = function (this: Point, onSelect: OnSelectCallback) {
  processSelection(this, onSelect);
};

const selectCallback = function (this: Point, onSelect: OnSelectCallback) {
  processSelection(this, onSelect);
};

const processSelection = (point: Point, onSelect: OnSelectCallback) => {
  const { category } = point;
  const binStart = category.toString();
  onSelect(binStart);
};
