import { clone, map } from "lodash";
import React, { FC, useCallback, useMemo } from "react";
import { IncButton, IncToolTip, generateId } from "@inception/ui";
import { ChartColorPicker } from "../../../../../../../biz-flow/ChartColorPicker";
import {
  Condition,
  GenericFilterDataOption,
  GenericFiltersBuilder,
  VerticallyCenteredRow
} from "../../../../../../../components";
import { ColorCustomizationCondition } from "../../../../models/model";

export interface ColorCustomizationProp {
  filters: Record<string, GenericFilterDataOption>;
  queries: ColorCustomizationCondition[];
  onConditionsChange: (conditions: ColorCustomizationCondition[]) => void;
  mode?: string;
}

/**
 * Ui Order list
 * @param props
 * @returns
 */
export const UIColorCondition: FC<ColorCustomizationProp> = props => {
  const { filters, queries, onConditionsChange } = props;

  /**
   * change the color
   */
  const onColorChange = useCallback(
    (color: string, item: any) => {
      const qs = clone(queries);
      const q = qs.find(b => b.id === item.id);
      q.color = color;
      onConditionsChange(qs);
    },
    [onConditionsChange, queries]
  );
  /**
   * update the filter changes
   */
  const onFiltersChange = useCallback(
    (conditions: Condition[], query: ColorCustomizationCondition) => {
      const nQueries = clone(queries);
      nQueries.forEach(q => {
        if (q.id === query.id) {
          q.conditions = conditions;
        }
      });
      onConditionsChange(nQueries);
    },
    [onConditionsChange, queries]
  );

  /**
   * handle adding filter
   */
  const handleAddFilter = useCallback(() => {
    const nQueries = clone(queries);
    nQueries.push(getGeneratedColorCondition());
    onConditionsChange(nQueries);
  }, [onConditionsChange, queries]);

  /**
   * handle add conditions
   */
  const handleAddConditions = useCallback(
    (query: ColorCustomizationCondition) => {
      const insertionIndex = queries.findIndex(item => item.id === query.id);
      const cQuery = clone(query);
      cQuery.id = generateId();
      const nQueries = [...queries.slice(0, insertionIndex), cQuery, ...queries.slice(insertionIndex)];
      onConditionsChange(nQueries);
    },
    [onConditionsChange, queries]
  );
  /**
   * delete the conditions
   */
  const handleDeleteConditions = useCallback(
    (query: ColorCustomizationCondition) => {
      const newQueries = queries.filter(item => item.id !== query.id);
      onConditionsChange(newQueries);
    },
    [onConditionsChange, queries]
  );

  const conditions = useMemo(
    () =>
      map(queries, query => {
        const { id, color, conditions } = query;
        return (
          <VerticallyCenteredRow
            className="list-item flex-gap-12"
            id={`widget-${id}`}
            key={id}
          >
            <ChartColorPicker
              color={color}
              hideEditOnHover
              onChange={color => onColorChange(color, query)}
              size={26}
            />

            <GenericFiltersBuilder
              addDisabled={false}
              addEmptyFirstFilter
              data={filters}
              filters={conditions}
              hideActions
              hideInputLabels
              keyLabel="Column"
              label=""
              onChange={condition => onFiltersChange(condition, query)}
              showConditionsDropDown={false}
            />

            <VerticallyCenteredRow className="flex-gap-8">
              <IncToolTip titleText="Clone">
                <IncButton
                  color="link"
                  iconName="clone"
                  onClick={() => handleAddConditions(query)}
                  title=""
                />
              </IncToolTip>

              <IncToolTip
                titleText="Delete"
                variant="error"
              >
                <IncButton
                  className="status-danger"
                  color="link"
                  iconName="trash-can"
                  onClick={() => handleDeleteConditions(query)}
                  title=""
                />
              </IncToolTip>
            </VerticallyCenteredRow>
          </VerticallyCenteredRow>
        );
      }),
    [handleAddConditions, handleDeleteConditions, onColorChange, onFiltersChange, filters, queries]
  );

  return (
    <div className="color-selector-list">
      {conditions}

      <IncButton
        color="link"
        iconName="plus"
        label="Add Row Color"
        onClick={handleAddFilter}
      ></IncButton>
    </div>
  );
};

export const getGeneratedColorCondition: () => ColorCustomizationCondition = () => ({
  color: "#fff",
  id: generateId(),
  conditions: []
});
