import { IncDatePicker, IncModal, IncModalProps, IncSelect, IncSelectOption, IncTimePicker } from "@inception/ui";
import React, { FC, useCallback, useMemo, useState } from "react";
import moment, { Moment } from "moment";

import { VerticallyCenteredRow } from "..";
import { useToggleState } from "../../core";

interface Props {
  show: boolean;
  onClose: () => void;
  onSaveDateTime: (start: number, end: number) => void;
  preSelectedStartandEnd?: { start: number; end: number };
}

export const StartEndTimeSelectorModal: FC<Props> = props => {
  const { onClose, show, onSaveDateTime } = props;

  const [startType, setStartType] = useState<IncSelectOption>(startOrEndOptions[0]);
  const [endType, setEndType] = useState<IncSelectOption>(startOrEndOptions[0]);

  const { isOpen: isCustomStartOpen, open: openCustomStart, close: closeCustomStart } = useToggleState();
  const { isOpen: isCustomEndOpen, open: openCustomEnd, close: closeCustomEnd } = useToggleState();

  const selectedStartType = useMemo(
    () => startOrEndOptions.find(x => x.value === startType.value) || startOrEndOptions[0],
    [startType]
  );

  const selectedEndType = useMemo(
    () => startOrEndOptions.find(x => x.value === endType.value) || startOrEndOptions[0],
    [endType]
  );

  const onStartTypeChange = useCallback(
    (type: IncSelectOption) => {
      setStartType(type);
      if (type.value === "custom") {
        openCustomStart();
      } else {
        closeCustomStart();
      }
    },
    [closeCustomStart, openCustomStart]
  );

  const onEndTypeChange = useCallback(
    (type: IncSelectOption) => {
      setEndType(type);
      if (type.value === "custom") {
        openCustomEnd();
      } else {
        closeCustomEnd();
      }
    },
    [closeCustomEnd, openCustomEnd]
  );

  const [startDate, setStartDate] = useState<Moment>();
  const [endDate, setEndDate] = useState<Moment>();

  const [startTime, setStartTime] = useState<Moment>();
  const [endTime, setEndTime] = useState<Moment>();

  const onStartDateChange = useCallback((date: Moment) => {
    setStartDate(date);
  }, []);

  const onStartTimeChange = useCallback((time: Moment) => {
    setStartTime(time);
  }, []);

  const onEndDateChange = useCallback((date: Moment) => {
    setEndDate(date);
  }, []);

  const onEndTimeChange = useCallback((time: Moment) => {
    setEndTime(time);
  }, []);

  const onSave = useCallback(() => {
    const startDateTime =
      startType === startOrEndOptions[0]
        ? moment()
        : startDate && startTime
          ? moment(startDate).hour(startTime.hour()).minute(startTime.minute()).second(startTime.second())
          : null;

    const endDateTime =
      endType === startOrEndOptions[0]
        ? moment()
        : endDate && endTime
          ? moment(endDate).hour(endTime.hour()).minute(endTime.minute()).second(endTime.second())
          : null;

    const finalTime = {
      startTime: startDateTime ? startDateTime.valueOf() : null,
      endTime: endDateTime ? endDateTime.valueOf() : null
    };

    onSaveDateTime?.(finalTime.startTime, finalTime.endTime);
    onClose();
  }, [startType, startDate, startTime, endType, endDate, endTime, onSaveDateTime, onClose]);

  const actions = useMemo<IncModalProps["actions"]>(
    () => ({
      primary: {
        label: "Save",
        onClick: onSave,
        color: "primary"
      },
      secondary: {
        label: "Cancel",
        onClick: onClose,
        color: "link"
      }
    }),
    [onClose, onSave]
  );

  return (
    <IncModal
      actions={actions}
      contentClassName="start-end-time-selector-modal-content"
      onClose={onClose}
      show={show}
      size="xxlg"
      titleText="Edit start and end time"
      withActionsBorder
      withTitleBorder
    >
      <>
        <VerticallyCenteredRow className="flex-gap-8 width-100">
          <div className="inc-text-body inc-text-inactive">Start date is</div>
          <IncSelect
            className="start-selector-container"
            onChange={onStartTypeChange}
            options={startOrEndOptions}
            value={selectedStartType}
          />
          {isCustomStartOpen && (
            <>
              <IncDatePicker
                onChange={onStartDateChange}
                placeholder="Select Date"
                value={startDate as any}
              />
              <IncTimePicker
                onChange={onStartTimeChange}
                value={startTime as any}
              />
            </>
          )}
        </VerticallyCenteredRow>
        <VerticallyCenteredRow className="flex-gap-8 width-100">
          <div className="inc-text-body inc-text-inactive">End date is</div>
          <IncSelect
            className="end-selector-container"
            onChange={onEndTypeChange}
            options={startOrEndOptions}
            value={selectedEndType}
          />
          {isCustomEndOpen && (
            <>
              <IncDatePicker
                onChange={onEndDateChange}
                placeholder="Select Date"
                value={endDate as any}
              />
              <IncTimePicker
                onChange={onEndTimeChange}
                value={endTime as any}
              />
            </>
          )}
        </VerticallyCenteredRow>
      </>
    </IncModal>
  );
};

const startOrEndOptions: IncSelectOption[] = [
  {
    label: "now",
    value: "now"
  },
  {
    label: "custom",
    value: "custom"
  }
];
