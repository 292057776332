import { pick, size, isArray } from "lodash";
import { EntityWidgetData, SingleStatData, EntityTableStatsData, ChangeMetric } from "../../../../../../biz-entity";
import {
  LimitSpecFunction,
  SelectorSpec,
  SelectorFilter,
  WidgetResponseDTO
} from "../../../../../../services/api/explore";
import { DataFrameType, DataFrame, logger } from "../../../../../../core";
import { getSerieName } from "../common";
import { QUERY_LOOKUP_KEY, EXPRESSION_TAG } from "../../../../../../utils/ExploreUtils";

export type AggLabelVsMatchTags = Record<
  string,
  {
    rawLabel: string;
    matchTags: Record<string, string>;
  }
>;

export const getInitialHeaders = (
  defHeaders: Record<string, string>,
  widgetResponseDTO: WidgetResponseDTO,
  shouldExcludeMetricLabel: boolean
) => {
  defHeaders = defHeaders || {};
  const shouldOverride = isArray(defHeaders) || size(defHeaders) === 0;
  if (shouldOverride && widgetResponseDTO) {
    const { metrics } = widgetResponseDTO.widgetConfig?.dataDefinition || {};

    const nHeaders: Record<string, string> = {};
    Object.keys(metrics || {}).forEach(metricId => {
      if (defHeaders[metricId]) {
        nHeaders[metricId] = defHeaders[metricId];
      } else {
        const { labels, name, sourceType } = metrics[metricId];

        nHeaders[metricId] = getDefaultMetricHeader(name, sourceType, labels, shouldExcludeMetricLabel);
      }
    });

    return nHeaders;
  }

  return {
    ...(defHeaders || {})
  };
};

export const getInitialCompareChangeHeaders = (
  defCompareHeaders: Record<string, Record<ChangeMetric, string>>,
  defMetricHeaders: Record<string, string>,
  compareTimeStr: string
) => {
  const nCompareHeaders: Record<string, Record<ChangeMetric, string>> = {};

  defCompareHeaders = defCompareHeaders || {};

  Object.keys(defMetricHeaders).forEach(metricId => {
    const defMetricHeader = defMetricHeaders[metricId];
    nCompareHeaders[metricId] = {
      all: defMetricHeader,
      current: defMetricHeader,
      delta: `${defMetricHeader} (Change for ${compareTimeStr})`,
      deltaPercentage: `${defMetricHeader} (Change Percentage)`,
      timeShift: `${defMetricHeader} (${compareTimeStr})`,
      bounds: `${defMetricHeader} (Bounds)`,
      trends: `${defMetricHeader} (Trends)`,
      ...(defCompareHeaders?.[metricId] || {})
    };
  });

  return nCompareHeaders;
};

export const getDefaultMetricHeader = (
  name: string,
  sourceType: string,
  labels: Record<string, string>,
  shouldExcludeMetricLabel: boolean
) => {
  const queryId = labels?.[QUERY_LOOKUP_KEY];
  const expression = labels?.[EXPRESSION_TAG];

  if (sourceType === "expression") {
    return expression ? `${name} (${expression})` : name;
  }

  return queryId && !shouldExcludeMetricLabel ? `${name} (${queryId})` : name;
};

export const constructSingleStatData = (
  entityWidgetData: EntityWidgetData,
  extEntityWidgetData: EntityWidgetData[],
  aggLabelVsMatchTags: AggLabelVsMatchTags,
  aggregatedTags: string[],
  limitSpecFunction: LimitSpecFunction,
  metricName: string
) => {
  const data = new Map<string, EntityTableStatsData>();
  const compareData = new Map<string, EntityTableStatsData>();
  const entryKeysToInclude = new Set<string>();
  let dataExists = false;

  const numDataEntries = extEntityWidgetData.length + 1;
  const metricDataIdx = 0;
  const aggLabels = Object.keys(aggLabelVsMatchTags);

  aggLabels.forEach(key => {
    let dataEntry = data.get(key);
    if (!dataEntry) {
      const ssData = new Array(numDataEntries);
      // eslint-disable-next-line no-empty
      for (let i = 0; i < numDataEntries; ssData[i++] = getDefaultData()) {}

      const nEntry = {
        columnData: {},
        ssData
      };

      dataEntry = nEntry;
      data.set(key, nEntry);
    }

    let compareEntry = compareData.get(key);
    if (!compareEntry) {
      const ssData = new Array(numDataEntries);
      // eslint-disable-next-line no-empty
      for (let i = 0; i < numDataEntries; ssData[i++] = getDefaultData()) {}

      const nEntry = {
        columnData: {},
        ssData
      };

      compareEntry = nEntry;
      compareData.set(key, nEntry);
    }
  });

  const allData = [entityWidgetData, ...extEntityWidgetData];
  allData.forEach((wdEntry, idx) => {
    const { postAggResult } = wdEntry;

    const dataFrames = getDataFrames(postAggResult);
    const numDataFrames = dataFrames.length;
    dataExists = dataExists || Boolean(numDataFrames);

    dataFrames.forEach(df => {
      const { fields, meta = {}, labels = {} } = df;

      const { dfType } = meta;
      const includeDf = shouldIncludeDf(df);

      const matchTags = pick(labels, aggregatedTags);
      const aggLabel = aggLabels.find(label => {
        const matchTagsEntry = aggLabelVsMatchTags[label].matchTags;
        return checkIfTagEntryMatches(matchTags, matchTagsEntry);
      });

      if (aggLabel) {
        const dataEntry = data.get(aggLabel);
        const { ssData } = dataEntry;

        const compareEntry = compareData.get(aggLabel);
        const compareSsData = compareEntry.ssData;

        dataEntry.columnData = {
          ...matchTags,
          metricName
        };

        compareEntry.columnData = {
          ...matchTags,
          metricName
        };

        const rawValue = aggLabelVsMatchTags[aggLabel].rawLabel;

        if (includeDf) {
          const values: number[] = fields[1].data;
          const value = values.reduce((acc, curr) => acc + curr, 0);

          if (value !== null && value !== undefined) {
            switch (dfType) {
              case DataFrameType.aggregated: {
                const currEntry = ssData[idx];
                ssData[idx] = {
                  rawName: rawValue,
                  value,
                  percentChange: currEntry?.percentChange || 0,
                  dataFrames: [...(currEntry?.dataFrames || []), df]
                };
                entryKeysToInclude.add(aggLabel);
                break;
              }

              case DataFrameType.aggregatedCompare: {
                const currEntry = compareSsData[idx];
                compareSsData[idx] = {
                  rawName: rawValue,
                  value,
                  percentChange: currEntry?.percentChange || 0,
                  dataFrames: [...(currEntry?.dataFrames || []), df]
                };
                break;
              }

              case DataFrameType.aggregatedPercentage: {
                const currDataEntry = ssData[idx];
                const currCompareEntry = compareSsData[idx];

                ssData[idx] = {
                  rawName: rawValue,
                  value: currDataEntry?.value || 0,
                  percentChange: value,
                  dataFrames: [...(currDataEntry?.dataFrames || []), df]
                };

                compareSsData[idx] = {
                  rawName: rawValue,
                  value: currCompareEntry?.value || 0,
                  percentChange: value,
                  dataFrames: [...(currCompareEntry?.dataFrames || []), df]
                };
                break;
              }

              default:
                break;
            }
          }
        }
      } else {
        logger.warn("TopKRenderer", "No match found for DataFrame with tags", matchTags);
      }
    });
  });

  adjustMapForKeys(data, entryKeysToInclude);
  adjustMapForKeys(compareData, entryKeysToInclude);
  metricDataIdx > -1 && sortData(data, metricDataIdx, limitSpecFunction);

  return {
    data,
    compareData,
    dataExists
  };
};

export const getSelectorSpecAndLabelToTagsMap = (
  entityWidgetData: EntityWidgetData,
  aggregatedTags: string[],
  displayAggregatedTags: string[],
  metricName: string
) => {
  const selectorSpec: SelectorSpec = {
    filters: []
  };
  const aggLabelVsMatchTags: AggLabelVsMatchTags = {};

  if (entityWidgetData) {
    const { postAggResult } = entityWidgetData;
    const dataFrames = getDataFrames(postAggResult, true);
    const filtersRec: Record<string, Set<string>> = {};
    dataFrames.forEach(df => {
      const { labels, eLabels } = df;
      const { aggLabel, rawValue } = getSerieName(
        aggregatedTags,
        displayAggregatedTags,
        labels,
        eLabels,
        metricName,
        false
      );
      const matchTags = pick(labels, aggregatedTags);

      aggLabelVsMatchTags[aggLabel] = {
        matchTags,
        rawLabel: rawValue
      };

      Object.keys(labels).forEach(key => {
        const filSet = filtersRec[key] || new Set();
        filSet.add(labels[key]);
        filtersRec[key] = filSet;
      });
    });

    const tagFilter: SelectorFilter = {
      tags: []
    };
    Object.keys(filtersRec).forEach(key => {
      tagFilter.tags.push({
        key,
        value: Array.from(filtersRec[key])
      });
    });
    selectorSpec.filters.push(tagFilter);
  }

  return {
    selectorSpec,
    aggLabelVsMatchTags
  };
};

const adjustMapForKeys = (map: Map<string, EntityTableStatsData>, keys: Set<string>) => {
  const mapKeys = Array.from(map.keys());
  mapKeys.forEach(key => {
    const shouldInclude = keys.has(key);
    if (!shouldInclude) {
      map.delete(key);
    }
  });
};

const checkIfTagEntryMatches = (tagEntry: Record<string, string>, matchTags: Record<string, string>) => {
  const tagKeys = Object.keys(tagEntry);
  return tagKeys.every(tag => tagEntry[tag] === matchTags[tag]);
};

const getDefaultData = (): SingleStatData => ({
  dataFrames: [],
  percentChange: 0,
  rawName: "",
  value: 0
});

const sortData = (data: Map<string, EntityTableStatsData>, idx: number, limitSpecFunction: LimitSpecFunction) => {
  const sortedEntries = Array.from(data.entries()).sort((entry1, entry2) => {
    const valA = entry1[1].ssData[idx].value;
    const valB = entry2[1].ssData[idx].value;

    return limitSpecFunction === "top" ? valB - valA : valA - valB;
  });
  data.clear();
  sortedEntries.forEach(([k, v]) => data.set(k, v));
};

const getDataFrames = (postAggResult: EntityWidgetData["postAggResult"], skipCompareData = false) => {
  const {
    data: postAggData,
    percentChangeData: postAggPerChangeData,
    timeShiftData: postAggTimeshiftData
  } = postAggResult;

  const refId = Object.keys(postAggData)[0];

  const dataFrames = [...(postAggData[refId]?.data || [])];

  if (!skipCompareData) {
    dataFrames.push(...(postAggPerChangeData[refId]?.data || []));
    dataFrames.push(...(postAggTimeshiftData[refId]?.data || []));
  }

  return dataFrames;
};

const allowedTypes = [DataFrameType.aggregated, DataFrameType.aggregatedCompare, DataFrameType.aggregatedPercentage];

const shouldIncludeDf = (df: DataFrame) => {
  const { dfType } = df?.meta || {};

  return allowedTypes.includes(dfType);
};
