import { groupBy } from "lodash";
import { IncidentSlicesContribution } from "../../../services/api/explore";

export function getCountOfGroupsByTagValue(slicesContribution: IncidentSlicesContribution[], tagName: string) {
  const groupedValues: Record<string, Record<string, number>> = {};
  const contribution = slicesContribution?.[0]?.values || [];

  const groups = groupBy(contribution, contr => contr.values[tagName]);

  Object.entries(groups).forEach(([groupTagVal, groupEntries]) => {
    groupedValues[groupTagVal] = {};

    const keys = Object.keys(groupEntries[0]?.values || {});
    keys.forEach(k => {
      if (k !== tagName) {
        const set = new Set<string>();
        groupEntries.forEach(entry => entry.values[k] && set.add(entry.values[k]));

        groupedValues[groupTagVal][k] = set.size;
      }
    });
  });

  return groupedValues;
}
