import { useEffect, useMemo, useRef } from "react";
import { isEmpty, cloneDeep, groupBy, isEqual } from "lodash";
import { CatalogWidgetFetchDataPayload } from "../../../types";
import {
  CohortEntityFilter,
  WidgetResponseDTO,
  SelectorSpec,
  SliceSpec,
  OverTimePostAgg,
  OverTagPostAgg,
  WidgetQuerySchema,
  WidgetConfigUtils,
  PostAggProjection,
  UserServiceFilterList,
  SliceSet
} from "../../../../../../services/api/explore";
import { TimeRange } from "../../../../../../core";
import { useFetchCatalogWidgetData } from "../../../hooks";
import { EntityWidgetData, ChangeMetric } from "../../../../../../biz-entity";
import { DataQueryError } from "../../../../../../services/api/types";
import { CatalogVizRendererProps } from "../common";
import { getDefaultMetricHeader } from "./utils";

type Props = {
  queryId: string;
  parentDataFetching: boolean;

  userServiceId: string;
  entityTypeName: string;

  metricId: string;
  dataFetchPayload: CatalogWidgetFetchDataPayload;
  widgetResponseDTO: WidgetResponseDTO;
  postAggProjections: PostAggProjection[];

  cohortFilters: CohortEntityFilter[];
  entityFilters: CohortEntityFilter[];
  eventFilters: Record<string, UserServiceFilterList>;

  selectorSpec: SelectorSpec;
  widgetSelectorSpec: CatalogVizRendererProps["widgetSelectorSpec"];

  timeRange: TimeRange;
  dsIntervalStr?: string;

  changeMetric: ChangeMetric;
  changeMetricHeaders: Record<string, string>;
  compareChangeMetricHeaders: Record<string, Record<ChangeMetric, string>>;

  hasChildMetrics: boolean;
  disabledSeries?: Record<string, SliceSet[]>;
};

export const useFetchExtData = (props: Props) => {
  const {
    cohortFilters,
    dataFetchPayload,
    entityFilters,
    entityTypeName,
    eventFilters,
    metricId: parentMetricId,
    queryId,
    selectorSpec,
    timeRange,
    userServiceId,
    widgetResponseDTO,
    parentDataFetching,
    changeMetric,
    changeMetricHeaders: pChangeMetricHeaders,
    compareChangeMetricHeaders: pCompareChangeMetricHeaders,
    dsIntervalStr,
    postAggProjections,
    hasChildMetrics
  } = props;

  const headersRef = useRef(pChangeMetricHeaders || {});
  useMemo(() => {
    headersRef.current = !isEqual(pChangeMetricHeaders || {}, headersRef.current)
      ? pChangeMetricHeaders || {}
      : headersRef.current;
  }, [pChangeMetricHeaders]);

  const compareHeadersRef = useRef(pCompareChangeMetricHeaders || {});
  useMemo(() => {
    compareHeadersRef.current = !isEqual(pCompareChangeMetricHeaders || {}, compareHeadersRef.current)
      ? pCompareChangeMetricHeaders || {}
      : compareHeadersRef.current;
  }, [pCompareChangeMetricHeaders]);

  const changeMetricHeaders = headersRef.current;
  const compareChangeMetricHeaders = compareHeadersRef.current;

  const querySchema = widgetResponseDTO?.querySchema?.querySchema;

  const {
    sliceSpec,
    shouldFetchData,
    changeMetrics,
    displayChangeMetricHeaders,
    displayCompareChangeMetricHeaders,
    id
  } = useMemo(() => {
    const idArr = [queryId, parentMetricId];

    const sliceSpec: SliceSpec[] = [];
    const changeMetrics: ChangeMetric[] = [changeMetric];
    let shouldFetchData = false;

    if (dataFetchPayload?.sliceSpec?.length) {
      const { sliceSpec: presetSliceSpecArr } = dataFetchPayload;

      const querySchemaByMetricId = groupBy(querySchema, qs => qs.metricId);
      const fQuerySchema: WidgetQuerySchema[] = [];

      Object.values(querySchemaByMetricId).forEach(qsArr => {
        const maxSliceQuerySchema = qsArr.reduce((acc, qs) => {
          const currNumSlices = qs.sliceSet.slices.length;
          const prevNumSlices = acc?.sliceSet?.slices?.length || 0;

          if (currNumSlices > prevNumSlices) {
            return qs;
          }
          return acc;
        }, qsArr[0]);

        maxSliceQuerySchema && fQuerySchema.push(maxSliceQuerySchema);
      });

      fQuerySchema.forEach(qs => {
        const { defaultTagAgg, defaultTimeAgg, metricId, sliceSet, labels, metricName, componentSourceFields } = qs;

        const includeSliceSet = metricId !== parentMetricId;
        const sourceType = isEmpty(componentSourceFields) ? "userServiceField" : "expression";
        const compMetricName = getDefaultMetricHeader(metricName, sourceType, labels, hasChildMetrics);

        if (includeSliceSet) {
          idArr.push(metricId);

          const presetSliceSpec = presetSliceSpecArr[0];
          const { postAgg: presetPostAgg } = presetSliceSpec;

          changeMetrics.push(changeMetric);
          changeMetricHeaders[metricId] = changeMetricHeaders?.[metricId] || compMetricName;

          const nPostAgg = cloneDeep(presetPostAgg);
          delete nPostAgg.sortSpec;

          if ((nPostAgg as OverTimePostAgg)?.overTimeAgg) {
            (nPostAgg as OverTimePostAgg).overTimeAgg.aggregator = defaultTimeAgg;
          }

          if ((nPostAgg as OverTagPostAgg)?.overTagAgg) {
            (nPostAgg as OverTagPostAgg).overTagAgg.aggregator = defaultTagAgg;
          }

          // Add only the selectorSpec for those tags that exist in the sliceSet
          const sliceTagNames = WidgetConfigUtils.getTagNamesFromSliceSet(sliceSet);
          const fTags = selectorSpec.filters[0].tags.filter(({ key }) => sliceTagNames.includes(key));
          const nSelectorSpec: SelectorSpec = fTags.length
            ? {
                filters: [
                  {
                    tags: fTags
                  }
                ]
              }
            : {
                filters: []
              };

          nPostAgg.projections = postAggProjections;

          sliceSpec.push({
            selectorSpec: nSelectorSpec,
            sliceSet,
            metricId,
            postAgg: nPostAgg
          });
        }
      });

      shouldFetchData = Boolean(sliceSpec.length);
    }

    const displayChangeMetricHeaders = sliceSpec.map(({ metricId }) => changeMetricHeaders[metricId] || "");
    displayChangeMetricHeaders.unshift(changeMetricHeaders[parentMetricId] || "");

    const displayCompareChangeMetricHeaders = sliceSpec.map(
      ({ metricId }) => compareChangeMetricHeaders[metricId] || {}
    );
    displayCompareChangeMetricHeaders.unshift(compareChangeMetricHeaders[parentMetricId] || {});

    return {
      sliceSpec,
      shouldFetchData,
      changeMetrics,
      displayChangeMetricHeaders,
      displayCompareChangeMetricHeaders: displayCompareChangeMetricHeaders as Array<Record<ChangeMetric, string>>,
      id: idArr.join("-")
    };
  }, [
    changeMetric,
    changeMetricHeaders,
    compareChangeMetricHeaders,
    dataFetchPayload,
    hasChildMetrics,
    parentMetricId,
    postAggProjections,
    queryId,
    querySchema,
    selectorSpec.filters
  ]);

  const { refetch, data, isFetching, error, isError } = useFetchCatalogWidgetData({
    cohortFilters,
    entityFilters,
    entityType: entityTypeName,
    id,
    limit: -1,
    mode: dataFetchPayload?.mode,
    sliceSpec,
    timeRange,
    userServiceId,
    widgetResponseDTO,
    eventFilters,
    dsIntervalStr
  });

  useEffect(() => {
    if (!parentDataFetching && shouldFetchData) {
      refetch();
    }
  }, [parentDataFetching, refetch, shouldFetchData]);

  const { entityWidgetData, dataError } = useMemo(() => {
    const fData = data || [
      {
        data: [] as EntityWidgetData[],
        error: {
          data: null,
          message: ""
        }
      }
    ];

    let dataErrObj: DataQueryError = {
      data: null,
      message: ""
    };

    const entityWidgetData: EntityWidgetData[] = [];

    fData.forEach(d => {
      const { data = [], error } = d;
      dataErrObj = dataErrObj || error;
      entityWidgetData.push(...data);
    });

    const dataError = dataErrObj?.message || "";

    return {
      entityWidgetData,
      dataError
    };
  }, [data]);

  return {
    data: entityWidgetData,
    isFetching,
    isError,
    error: dataError || error?.toString(),
    changeMetrics,
    changeMetricHeaders: headersRef.current,
    compareChangeMetricHeaders: compareHeadersRef.current,
    displayChangeMetricHeaders,
    displayCompareChangeMetricHeaders
  };
};
