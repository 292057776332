import React, { FC, useEffect } from "react";
import { SalesforceTemplate } from "./SalesforceTemplate";
import { SlackTemplate } from "./SlackTemplate";
import { CommonStepProps } from "./types";

interface Props extends CommonStepProps {
  templateToRender: "slack" | "salesforce";
  customTemplateValues?: {
    slack: any[];
    salesforce: any[];
  };
}

export const SubscribeDialogContentStep2: FC<Props> = props => {
  const { setCanProceed, templateToRender, customTemplateValues } = props;

  useEffect(() => {
    setCanProceed(false);
  }, [setCanProceed]);

  return (
    <div className="wizard-step">
      <div className="wizard-step--title">Choose where you would like the insights delivered.</div>

      {templateToRender === "slack" && (
        <SlackTemplate
          customTemplateValues={customTemplateValues?.slack}
          setCanProceed={setCanProceed}
        />
      )}
      {templateToRender === "salesforce" && <SalesforceTemplate setCanProceed={setCanProceed} />}
    </div>
  );
};
