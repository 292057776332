import React, { FC, useMemo, CSSProperties, memo, useCallback } from "react";
import { ISaxIcon, IncFaIcon, IncGenericIcon, IncSmartText } from "@inception/ui";
import { css } from "emotion";
import { LowLevelFragment, LowLevelFragmentType, OpFragment } from "../../services/api/chat";
import { getSourceTypeIcon } from "../../components/business-entity";
import { useToggleState } from "../../core";
import { MarkdownRenderer, VerticallyCenteredRow } from "../../components";
import { OpCreationFragmentEditProps } from "../types";
import { OpCreationConfig } from "../../services/api/operationalise";
import { convertMarkdownToHtml } from "../../utils";
import { OpFragmentEditRenderer } from "./OpFragmentEditRenderer";

interface Props {
  id: string;
  fragmentPath: string;
  lowLevelFragment: LowLevelFragment;
  level?: number;

  // Required in edit mode
  editProps?: OpCreationFragmentEditProps;

  allowCustomFragmentEdit?: boolean;
  onCustomFragmentEdit?: (pathToEdit: string) => void;
  showEditorInModal?: boolean;
}

export const OpFragmentRenderer: FC<Props> = memo(props => {
  const {
    id,
    fragmentPath,
    lowLevelFragment,
    editProps,
    level = 0,
    allowCustomFragmentEdit = false,
    onCustomFragmentEdit,
    showEditorInModal = false
  } = props;

  const { type, name, description, fragmentInfo, isMarkdown, opFragment } = lowLevelFragment;

  const {
    opCreationConfig,
    isEditModeActive,
    onOpCreationConfigChange,
    onSwitchToEditMode: pOnSwitchToEditMode,
    onSwitchToViewMode: pOnSwitchToViewMode,
    demoDataParams
  } = editProps || {};

  const supportsEdit =
    Boolean(onOpCreationConfigChange) && Boolean(opFragment) && !opFragment?.readOnly && !isEditModeActive;

  const { label: labelFragment, bulletItems, headerTags } = fragmentInfo || {};

  const descriptionJsx = useMemo(() => {
    if (!description) {
      return null;
    }

    if (!isMarkdown) {
      return <div className="inc-text-inactive inc-text-subtext-medium">{description}</div>;
    }

    return (
      <MarkdownRenderer
        className="inc-text-inactive inc-text-subtext-medium"
        mdContent={description}
      />
    );
  }, [description, isMarkdown]);

  const iconJsx = useMemo(() => {
    const { iconColor, iconName, iconSvg } = labelFragment || {};

    const className =
      (iconSvg
        ? css`
            margin-bottom: auto;
            margin-top: 2px;
          `
        : css`
            margin: auto 0;
          `) + (iconColor ? "" : " inc-text-inactive");

    if (iconName) {
      const style: CSSProperties = {
        fill: iconColor || "inherit",
        color: iconColor || "inherit",
        fontSize: 16
      };

      return (
        <IncGenericIcon
          className={className}
          iconName={iconName}
          size={16}
          style={style}
          variant="Outline"
        />
      );
    }

    if (iconSvg) {
      return getSourceTypeIcon("", iconSvg, className, 16);
    }

    return null;
  }, [labelFragment]);

  const headerTagsJsx = useMemo(() => {
    if (!headerTags?.length) {
      return null;
    }

    return (
      <VerticallyCenteredRow className="flex-gap-8">
        {headerTags.map((tag, index) => (
          <IncSmartText
            className="header-tag"
            key={index}
            text={tag}
          />
        ))}
      </VerticallyCenteredRow>
    );
  }, [headerTags]);

  const subFragments = useMemo(
    () => lowLevelFragment?.lowLevelFragmentList?.lowLevelFragment,
    [lowLevelFragment?.lowLevelFragmentList?.lowLevelFragment]
  );
  const hasSubFragments = type === LowLevelFragmentType.FRAGMENT_LIST && Boolean(subFragments?.length);

  const fragmentJsx = useMemo(() => {
    const fragmentJsx: JSX.Element[] = [];

    if (hasSubFragments && subFragments?.length) {
      subFragments.forEach((fragment, index) => {
        fragmentJsx.push(
          <OpFragmentRenderer
            editProps={editProps}
            fragmentPath={`${fragmentPath}.lowLevelFragmentList.lowLevelFragment[${index}]`}
            id={`${id}-llf-${fragment.type}-${index}`}
            key={`${id}-llf-${fragment.type}-${index}`}
            level={level + 1}
            lowLevelFragment={fragment}
            showEditorInModal={showEditorInModal}
          />
        );
      });
    }

    if (bulletItems?.length) {
      const liItems = bulletItems.map((item, index) => {
        const { displayText, isMarkdown, tags } = item;

        return (
          <li key={index}>
            <VerticallyCenteredRow className="flex-gap-12 inc-text-inactive inc-text-subtext-medium">
              {isMarkdown ? (
                <span dangerouslySetInnerHTML={{ __html: convertMarkdownToHtml(displayText) }} />
              ) : (
                displayText
              )}

              {Boolean(tags?.length) && (
                <VerticallyCenteredRow className="flex-gap-8">
                  {tags.map((tag, index) => (
                    <IncSmartText
                      className="header-tag"
                      key={index}
                      text={tag}
                    />
                  ))}
                </VerticallyCenteredRow>
              )}
            </VerticallyCenteredRow>
          </li>
        );
      });

      fragmentJsx.push(<ul className="inc-text-inactive inc-text-subtext-medium">{liItems}</ul>);
    }

    return fragmentJsx.length ? fragmentJsx : null;
  }, [bulletItems, editProps, fragmentPath, hasSubFragments, id, level, showEditorInModal, subFragments]);

  const { isOpen: showDetails, toggle: toggleDetails } = useToggleState(true);

  const { isOpen: isEditMode, open: switchToEditMode, close: switchToViewMode } = useToggleState();

  const onSwitchToEditMode = useCallback(() => {
    switchToEditMode();
    pOnSwitchToEditMode && pOnSwitchToEditMode();
  }, [pOnSwitchToEditMode, switchToEditMode]);

  const onSwitchToViewMode = useCallback(() => {
    switchToViewMode();
    pOnSwitchToViewMode && pOnSwitchToViewMode();
  }, [pOnSwitchToViewMode, switchToViewMode]);

  const onOpFragmentChange = useCallback(
    (opFragment: OpFragment, opCreationConfig: OpCreationConfig) => {
      switchToViewMode();
      onOpCreationConfigChange && onOpCreationConfigChange(opCreationConfig);
    },
    [onOpCreationConfigChange, switchToViewMode]
  );

  const headerClassName = `flex-gap-12 ${hasSubFragments ? "inc-cursor-pointer" : ""}`;
  const contentVisible = showDetails && !isEditMode && Boolean(fragmentJsx?.length);

  return (
    <div
      className="op-creation-fragment-v2--low-level-fragment"
      data-edit-mode={isEditMode}
      data-has-content={contentVisible}
      data-level={level}
      data-supports-edit={supportsEdit}
    >
      <div className="header-and-description-wrapper">
        {iconJsx}

        <div className="header-and-description width-100">
          <VerticallyCenteredRow className="header width-100">
            <VerticallyCenteredRow
              className={headerClassName}
              onClick={hasSubFragments ? toggleDetails : null}
            >
              {name}
              {headerTagsJsx}
            </VerticallyCenteredRow>

            {hasSubFragments && (
              <ISaxIcon
                className="collapse-arrow inc-text-inactive inc-cursor-pointer marginLtAuto"
                data-expanded={showDetails}
                iconName="ArrowCircleDown"
                onClick={toggleDetails}
                variant="Outline"
              />
            )}

            {supportsEdit && (
              <ISaxIcon
                className="inc-cursor-pointer status-info marginLtAuto"
                iconName="Edit2"
                onClick={onSwitchToEditMode}
                variant="Outline"
              />
            )}
          </VerticallyCenteredRow>

          {!isEditMode && descriptionJsx}
        </div>
        {allowCustomFragmentEdit && (
          <IncFaIcon
            className="status-info marginLtAuto inc-cursor-pointer"
            iconName="pen-to-square"
            onClick={() => onCustomFragmentEdit(fragmentPath)}
          />
        )}
      </div>

      {contentVisible && (
        <div
          className="op-creation-fragment-v2--low-level-fragment__content"
          data-has-fragments={hasSubFragments}
        >
          {fragmentJsx}
        </div>
      )}

      {isEditMode && Boolean(editProps) && (
        <div className="op-creation-fragment-v2--low-level-fragment__edit">
          <OpFragmentEditRenderer
            demoDataParams={demoDataParams}
            fragmentPath={fragmentPath}
            onChange={onOpFragmentChange}
            onClose={onSwitchToViewMode}
            opCreationConfig={opCreationConfig}
            opFragment={opFragment}
            showEditorInModal={showEditorInModal}
          />
        </div>
      )}
    </div>
  );
});
