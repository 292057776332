import React, { FC, useRef, useCallback, useState, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { IncToolTip, IncPopper, IncCheckbox, IncSelect, IncButton } from "@inception/ui";
import { clone, cloneDeep, groupBy, map, forEach, isEmpty, isEqual, size, zipObject } from "lodash";
import { SliceSet, WidgetResponseDTO } from "../../services/api/explore";
import { CloseIcon, AddCircle, RemoveCircle, CheckSuccessIcon, FilterIcon14 } from "../../core/iconwrapper";
import { LinkButton, MultipleSelects, VerticallyCenteredRow } from "../../components";
import { DataDefinitionFilterProps, TagFilterSelection, TagFilterSelectionValue, TagFilterBySliceSet } from "../types";
import {
  getSelOptionsArr,
  getGroupOptionsForSchema,
  FilterOption,
  sanitizeMap,
  getSelectedFilterOptions,
  getFilterOptions
} from "../utils";
import { Option, ValueContainer } from "./SelectComponents";

interface Props extends DataDefinitionFilterProps {
  widgetResponseDTO: WidgetResponseDTO;
  bizFilter: TagFilterBySliceSet;
  seriesFilters: TagFilterBySliceSet[];
}

export const DataDefinitionFilter: FC<Props> = props => {
  const {
    widgetResponseDTO,
    bizFilter,
    onSeriesFilterChange,
    selectedFilters: pSelectedFilters,
    seriesFilters,
    filterMessage,
    filtersExist,
    preventDisableMetric = false,
    className = "",
    iconClassName = "",
    skipTooltip = false
  } = props;

  const { widgetConfig: widgetConfigDto } = widgetResponseDTO;

  const iconRef = useRef<HTMLDivElement>();
  const containerRef = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState<TagFilterSelection>(cloneDeep(pSelectedFilters));

  const { bizFilter: selectedBizFilter, disabledSeries, filtersBySliceSet: selectedSeriesFilters } = selectedFilters;

  const implicitSliceExists = useMemo(() => !isEmpty(bizFilter), [bizFilter]);

  // Implicit slice -> Biz entity type
  const implicitSliceFilterAppliesToAll = useMemo(() => {
    const bizFilterSlices = bizFilter?.sliceSet?.slices;
    if (!bizFilterSlices || !bizFilterSlices.length) {
      return false;
    }
    const appliesToAll = seriesFilters.reduce((acc, { sliceSet }) => {
      const { slices } = sliceSet;
      const matches = slices.filter(slice => bizFilterSlices.filter(bf => isEqual(bf, slice)).length > 0);
      return acc && matches.length > 0;
    }, true);

    return appliesToAll;
  }, [bizFilter, seriesFilters]);

  const applySeriesFilterChange = useCallback(() => {
    onSeriesFilterChange(selectedFilters);
    setOpen(false);
  }, [onSeriesFilterChange, selectedFilters]);

  const onAddFilter = useCallback(
    (dataDefId: string, seriesId: string) => {
      const nSelectedFilters = clone(selectedFilters);
      const currentSelection = nSelectedFilters.filtersBySliceSet.find(
        sf => sf.dataDefinitionId === dataDefId && sf.resultSeriesId === seriesId
      );
      const seriesFilter = seriesFilters.find(
        sf => sf.dataDefinitionId === dataDefId && sf.resultSeriesId === seriesId
      );

      if (seriesFilter) {
        const { filterValues, ...rest } = seriesFilter;

        const nFilValues = filterValues.map((fv): TagFilterSelectionValue => {
          const { displayTag, tag, slice, dependencyTag } = fv;

          return {
            displayTag,
            tag,
            slice,
            dependencyTag,
            selValToDValArr: [{}]
          };
        });

        if (!currentSelection) {
          nSelectedFilters.filtersBySliceSet.push({
            ...rest,
            selectionValues: nFilValues
          });
        } else {
          nSelectedFilters.filtersBySliceSet.forEach(filter => {
            if (filter.dataDefinitionId === dataDefId && filter.resultSeriesId === seriesId) {
              filter.selectionValues.forEach(sv => {
                sv.selValToDValArr.push({});
              });
            }
          });
        }
        setSelectedFilters(nSelectedFilters);
      }
    },
    [setSelectedFilters, selectedFilters, seriesFilters]
  );

  const onDeleteFilter = useCallback(
    (dataDefId: string, seriesId: string, idx: number) => {
      const nSelectedFilters: TagFilterSelection = {
        ...selectedFilters
      };
      const nSeriesFilterSelection = [...nSelectedFilters.filtersBySliceSet];

      if (dataDefId && seriesId) {
        const serSelectionIdx = nSeriesFilterSelection.findIndex(
          serSel => serSel.dataDefinitionId === dataDefId && serSel.resultSeriesId === seriesId
        );
        const serSelection = nSeriesFilterSelection[serSelectionIdx];

        if (serSelection) {
          const nSelectionValues = serSelection.selectionValues.map(sv => {
            const { selValToDValArr, displayTag, tag, slice: traceField, dependencyTag } = sv;

            const nsv: TagFilterSelectionValue = {
              displayTag,
              tag,
              slice: traceField,
              selValToDValArr,
              dependencyTag
            };

            nsv.selValToDValArr.splice(idx, 1);
            return nsv;
          });

          serSelection.selectionValues = nSelectionValues;

          const valuesExist = nSelectionValues.reduce((vExist, sv) => {
            const subValuesExist = sv.selValToDValArr.reduce((subVExist, subV) => size(subV) > 0 || subVExist, false);
            return subValuesExist || vExist;
          }, false);

          if (!valuesExist) {
            nSeriesFilterSelection.splice(serSelectionIdx);
          }
          nSelectedFilters.filtersBySliceSet = nSeriesFilterSelection;
        }
      }
      setSelectedFilters(nSelectedFilters);
    },
    [setSelectedFilters, selectedFilters]
  );

  const seriesNameMap = useMemo<Record<string, string>>(() => {
    const map: Record<string, string> = {};
    seriesFilters.forEach(sf => (map[sf.resultSeriesId] = sf.name));
    return map;
  }, [seriesFilters]);

  const bizFilterOptionsRec = useMemo<Record<string, FilterOption[]>>(() => getFilterOptions(bizFilter), [bizFilter]);

  const seriesFilterOptionsRec = useMemo<OptionsByDataDef>(() => {
    const opts: OptionsByDataDef = {};
    const filtersByDef = groupBy(seriesFilters, sf => sf.dataDefinitionId);
    forEach(filtersByDef, (serFiltersArr, dataDefId) => {
      opts[dataDefId] = groupBy(serFiltersArr, serFilters => serFilters.resultSeriesId);
    });
    return opts;
  }, [seriesFilters]);

  const selectedBizFilterOptionsRec = useMemo<SelectedFilterOptions>(() => {
    const rec = getSelectedFilterOptions(selectedBizFilter);
    const res: SelectedFilterOptions = {};
    forEach(rec, (v, k) => (res[k] = v[0]));
    return res;
  }, [selectedBizFilter]);

  const onSeriesCheckedChange = useCallback(
    (checked: boolean, metricId: string, sliceSet: SliceSet) => {
      const nSelection = { ...selectedFilters };
      const curr = nSelection.disabledSeries[metricId] || [];

      if (checked) {
        nSelection.disabledSeries[metricId] = curr.filter(ss => !isEqual(ss, sliceSet));
      } else {
        nSelection.disabledSeries[metricId] = [...curr, sliceSet];
      }

      setSelectedFilters(nSelection);
    },
    [setSelectedFilters, selectedFilters]
  );

  const handleClose = useCallback(() => {
    setSelectedFilters(pSelectedFilters);
    setOpen(false);
  }, [pSelectedFilters]);

  const handleOpen = useCallback(() => setOpen(true), []);

  const getMetricDefById = useCallback(
    (metricId: string) => {
      const metricDefs = widgetConfigDto.dataDefinition?.metrics || {};
      return metricDefs[metricId];
    },
    [widgetConfigDto.dataDefinition]
  );

  const onBizFilterSelectionChange = useCallback(
    (newSelection: readonly FilterOption[]) => {
      const { dataDefinitionId, name, resultSeriesId, sliceSet, filterValues } = bizFilter;

      const { displayTag, tag, slice: traceField, dependencyTag } = filterValues[0] || {};

      const nSelectedFilters: TagFilterSelection = {
        ...selectedFilters
      };

      const newValues: string[] = [];
      const newRawValues: string[] = [];

      newSelection.forEach(s => {
        newValues.push(s.value);
        newRawValues.push(s.rawValue);
      });

      const selObj = zipObject(newRawValues, newValues);

      nSelectedFilters.bizFilter = {
        dataDefinitionId,
        name,
        resultSeriesId,
        sliceSet,
        selectionValues: [
          {
            displayTag,
            tag,
            slice: traceField,
            selValToDValArr: [selObj],
            dependencyTag
          }
        ]
      };

      setSelectedFilters(nSelectedFilters);
    },
    [bizFilter, setSelectedFilters, selectedFilters]
  );

  const getBizFilter = useCallback(
    (optionsRec: Record<string, FilterOption[]>) =>
      map(optionsRec, (options, label) => {
        const value = selectedBizFilterOptionsRec[label];
        const displayLabel = implicitSliceFilterAppliesToAll ? label : `${label} *`;

        return (
          <IncSelect
            className="marginRt6 marginBt16"
            closeMenuOnSelect={false}
            components={{
              Option,
              ValueContainer
            }}
            hideSelectedOptions={false}
            isMulti
            isSearchable
            key={displayLabel}
            label={displayLabel}
            maxMenuHeight={200}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            menuPosition="absolute"
            menuShouldScrollIntoView={false}
            onChange={newSelection => onBizFilterSelectionChange(newSelection)}
            options={options}
            value={value}
          />
        );
      }),
    [implicitSliceFilterAppliesToAll, onBizFilterSelectionChange, selectedBizFilterOptionsRec]
  );

  const onSeriesFiltersSelectionChange = useCallback(
    (
      dataDefinitionId: string,
      seriesId: string,
      displayTag: string,
      selected: Map<string, string[]>,
      idx: number,
      displayValueToValueRec: Record<string, string>
    ) => {
      const nSelectedFilters: TagFilterSelection = {
        ...selectedFilters
      };
      const nSeriesFilterSelection = [...nSelectedFilters.filtersBySliceSet];

      /**
       * Since we use the selected map to get the selected values, if no value is selected we set it to an empty array
       * to make sure the new selection's value fo key is deleted after sanitization
       */
      if (!selected.size) {
        selected.set(displayTag, []);
      }

      if (dataDefinitionId && seriesId) {
        const serSelectionIdx = nSeriesFilterSelection.findIndex(
          serSel => serSel.dataDefinitionId === dataDefinitionId && serSel.resultSeriesId === seriesId
        );
        const serSelection = nSeriesFilterSelection[serSelectionIdx];
        const sanitizedSelected = sanitizeMap(selected);

        const prevSelectedIdx = serSelection.selectionValues.findIndex(sv => sv.displayTag === displayTag);
        const prevSelected = serSelection.selectionValues[prevSelectedIdx];
        if (prevSelectedIdx >= 0) {
          const nSelected = { ...prevSelected };
          const nValues = sanitizedSelected.get(displayTag) || [];
          const nValuesRec: Record<string, string> = {};
          nValues.forEach(dv => {
            const v = displayValueToValueRec[dv];
            nValuesRec[v] = dv;
          });
          nSelected.selValToDValArr[idx] = nValuesRec;
          serSelection.selectionValues[prevSelectedIdx] = nSelected;
        }
        nSelectedFilters.filtersBySliceSet = nSeriesFilterSelection;
      }

      setSelectedFilters(nSelectedFilters);
    },
    [setSelectedFilters, selectedFilters]
  );

  const selBizFilterMap = useMemo(() => {
    const selMap: Map<string, string[]> = new Map();
    selectedBizFilter?.selectionValues.forEach(sv => {
      const { tag, selValToDValArr } = sv;
      const vals = Object.keys(selValToDValArr[0]);
      selMap.set(tag, vals);
    });
    return selMap;
  }, [selectedBizFilter]);

  const getSeriesFilters = useCallback(
    (dataDefId: string, seriesId: string) => {
      const selects: JSX.Element[] = [];
      const removeBtns: JSX.Element[] = [];
      const selectedSeriesFilter = selectedSeriesFilters.find(
        sf => sf.dataDefinitionId === dataDefId && sf.resultSeriesId === seriesId
      );
      const seriesFilter = seriesFilters.find(
        sf => sf.dataDefinitionId === dataDefId && sf.resultSeriesId === seriesId
      );

      if (seriesFilter && selectedSeriesFilter) {
        const { dataDefinitionId, matchingSchema, filterValues } = seriesFilter;

        const selOptsMapArr = getSelOptionsArr(selectedSeriesFilter);
        const fvByTag = groupBy(filterValues, "tag");

        forEach(selOptsMapArr, (selOptionsMap, filterIdx) => {
          const { groupOptions, sizeMap, displayValueToValueRec } = getGroupOptionsForSchema(
            matchingSchema,
            fvByTag,
            selOptionsMap,
            selBizFilterMap
          );

          const sizeMapStr: Map<string, string[]> = new Map();
          sizeMap.forEach((sizes, tag) => {
            const szs = sizes.map(sz => sz.toString());
            sizeMapStr.set(tag, szs);
          });

          selects.push(
            <div
              className="filter inc-flex-center-vertical"
              key={filterIdx}
            >
              <MultipleSelects
                groupOptions={groupOptions}
                itemsCount={2}
                key={`${dataDefId}_${seriesId}_${filterIdx}`}
                menuAsPortal
                onSeriesSelectionChanged={(selected, displayTag) =>
                  onSeriesFiltersSelectionChange(
                    dataDefinitionId,
                    seriesId,
                    displayTag,
                    selected,
                    filterIdx,
                    displayValueToValueRec
                  )
                }
                optionsInfo={sizeMapStr}
                parentElmWidth={containerRef.current?.clientWidth ?? 0}
                selectedOptions={selOptionsMap}
              />
            </div>
          );
          removeBtns.push(
            <IncButton
              className="remove-btn"
              color="link"
              iconType="icon"
              key={filterIdx}
              onClick={() => onDeleteFilter(dataDefId, seriesId, filterIdx)}
            >
              <RemoveCircle />
            </IncButton>
          );
        });
      }
      return (
        <div className="inc-flex-row">
          <div className="series-filters">{selects}</div>
          <div className="inc-flex-column">{removeBtns}</div>
        </div>
      );
    },
    [selectedSeriesFilters, seriesFilters, selBizFilterMap, onSeriesFiltersSelectionChange, onDeleteFilter]
  );

  const filterJsx = (
    <VerticallyCenteredRow className={className}>
      <VerticallyCenteredRow
        className="svg-icon-with-overlay inc-cursor-pointer"
        onClick={handleOpen}
        ref={iconRef}
      >
        <FilterIcon14 className={[iconClassName, "base-svg"].join(" ")} />
        {filtersExist && <CheckSuccessIcon className="overlay-svg--bottom-right" />}
      </VerticallyCenteredRow>

      {filtersExist && filterMessage && (
        <VerticallyCenteredRow
          className="status-text marginLt12"
          style={{ whiteSpace: "nowrap" }}
        >
          {filterMessage}
        </VerticallyCenteredRow>
      )}
    </VerticallyCenteredRow>
  );

  return (
    <>
      {skipTooltip && filterJsx}
      {!skipTooltip && (
        <IncToolTip
          placement="top"
          titleText="Filter series"
        >
          {filterJsx}
        </IncToolTip>
      )}

      <IncPopper
        anchorEl={iconRef as any}
        overlay
        show={open}
      >
        <div
          className="entity-series-filter-popper"
          ref={containerRef}
        >
          <div className="entity-series-filter">
            <div className="inc-flex-row filter-header">
              <div className="marginBt16 inc-text-body-semibold">Filter</div>
              <div
                className="close-icon"
                onClick={handleClose}
              >
                <CloseIcon />
              </div>
            </div>
            {/* Render common filters */}
            <div className="inc-flex-row inc-flex-row-wrap">{getBizFilter(bizFilterOptionsRec)}</div>

            {/* Render Series wise filters based on the data defs */}
            {map(seriesFilterOptionsRec, (filtersRec, metricId) => {
              const { name = "Unknown metric" } = getMetricDefById(metricId) || {};
              const seriesFiltersRec: Record<string, TagFilterBySliceSet[]> = cloneDeep(filtersRec || {});

              return (
                <div
                  className="inc-flex-column"
                  key={metricId}
                >
                  {map(seriesFiltersRec, (sFilters, seriesId) => {
                    const sFilter = sFilters[0];
                    const { filterValues, matchingSchema } = sFilter;
                    const nonEmptyFilters = filterValues.length > 0;

                    let label = nonEmptyFilters ? `${name} ${seriesNameMap[seriesId]}` : name;
                    label = `${label} (${matchingSchema.length})`;

                    const disabledSeriesEntry = (disabledSeries[metricId] || []).find(ss =>
                      isEqual(ss, sFilter.sliceSet)
                    );
                    const isEnabled = isEmpty(disabledSeriesEntry);
                    const onCheckedChange = (e: any, checked: boolean) =>
                      onSeriesCheckedChange(checked, metricId, sFilter.sliceSet);

                    return (
                      <div key={`${metricId}_${seriesId}`}>
                        {!preventDisableMetric && (
                          <IncCheckbox
                            checked={isEnabled}
                            className="marginBt16"
                            label={label}
                            labelProps={{ placement: "end" }}
                            name={seriesId}
                            onChange={onCheckedChange}
                          />
                        )}
                        {preventDisableMetric && <div className="inc-label-common marginBt16 marginLt16">{label}</div>}
                        {nonEmptyFilters && (
                          <>
                            <div className="inc-flex-column marginLt8">{getSeriesFilters(metricId, seriesId)}</div>
                            <div className="add-filter-btn marginBt16">
                              <LinkButton
                                icon={AddCircle}
                                onClick={() => onAddFilter(metricId, seriesId)}
                                text="Filter"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {implicitSliceExists && !implicitSliceFilterAppliesToAll && (
              <div className="inc-label-common inc-text-sub-text-medium marginTp8">
                * - Filter is not applicable to all the series.
              </div>
            )}
          </div>
          <div className="footer">
            <IncButton
              color="primary"
              onClick={applySeriesFilterChange}
            >
              <FormattedMessage id="common.actions.done" />
            </IncButton>
          </div>
        </div>
      </IncPopper>
    </>
  );
};

type OptionsByDataDef = Record<string, Record<string, TagFilterBySliceSet[]>>;
type SelectedFilterOptions = Record<string, FilterOption[]>;
