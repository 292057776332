import HighchartsReact from "highcharts-react-official";
import Highcharts, { Options } from "highcharts";
import Sankey from "highcharts/modules/sankey";
import Map from "highcharts/modules/map";
import TreeMap from "highcharts/modules/treemap";
import HeatMap from "highcharts/modules/heatmap";
import Drilldown from "highcharts/modules/drilldown";
import HCMore from "highcharts/highcharts-more";
import Boost from "highcharts/modules/boost";
import addHistogramModule from "highcharts/modules/histogram-bellcurve";
import annotations from "highcharts/modules/annotations";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import Gantt from "highcharts/modules/gantt";

import React, { FC, useEffect, useState, useRef, useMemo } from "react";
import { css, cx } from "emotion";
import AppErrorBoundary from "../../app/AppErrorBoundary";
import { VerticallyCenteredRow } from "./flex-components";

Sankey(Highcharts);
Map(Highcharts);
TreeMap(Highcharts);
HeatMap(Highcharts);
Drilldown(Highcharts);
HCMore(Highcharts);
Boost(Highcharts);
addHistogramModule(Highcharts);
NoDataToDisplay(Highcharts);
annotations(Highcharts);
Gantt(Highcharts);

// Highcharts.error = function (code, msg) {
//   if (code === 18) {
//     // Suppress error #18 or handle it in a custom way
//     console.warn("Highcharts error #18 handled: The requested axis does not exist.");
//   } else {
//     // For other errors, you can either handle them or let Highcharts proceed with default handling
//     console.error(`Highcharts error #${code}: ${msg}`);
//   }
// };

interface Props {
  title?: string;
  constructorType?: HighchartsReact.Props["constructorType"];
  options: Highcharts.Options;
  useParentWidth?: boolean;
}

export const GenericHighchartsRenderer: FC<Props> = props => {
  const { options, title = "", constructorType = "chart", useParentWidth = false } = props;
  const ref = useRef<HTMLDivElement>();

  const [dimensions, setDimensions] = useState<Dimensions>();
  useEffect(() => {
    if (ref.current) {
      const defHeight = options?.chart?.height ? parseInt(String(options?.chart?.height), 10) : 0;
      const defWidth = options?.chart?.width ? parseInt(String(options?.chart?.width), 10) : 0;

      const parentDimensions = ref.current.parentElement.getBoundingClientRect();
      const height = defHeight || Math.max(parentDimensions.height || 0, 360);
      const width = defWidth || useParentWidth ? parentDimensions?.width : Math.max(parentDimensions.width || 0, 720);

      setDimensions({
        height: height - 24 - (height <= 300 && title ? 24 : 0),
        width: width - 24
      });
    }
  }, [options, title, useParentWidth]);

  const optionsToRender = useMemo<Options>(
    () => ({
      ...(options || {}),
      title: null,
      credits: {
        enabled: false
      },
      chart: {
        ...(options?.chart || {}),
        height: dimensions?.height,
        width: dimensions?.width
      },
      tooltip: {
        ...(options?.tooltip || {}),
        enabled: true,
        className: "inc-charts-tooltip",
        useHTML: true
      },
      responsive: {
        rules: [
          {
            condition: {
              maxHeight: 330
            },
            chartOptions: {
              legend: {
                itemStyle: {
                  fontSize: "10px" // Decrease font size for legend text
                },
                symbolHeight: 8, // Reduce the height of the legend color box
                symbolWidth: 8, // Reduce the width of the legend color box
                symbolPadding: 4 // Adjust the padding between text and color box
              },
              yAxis: {
                title: {
                  text: "" // Remove Y axis title for smaller screens
                }
              },
              xAxis: {
                title: {
                  text: "" // Remove X axis title for smaller screens,
                }
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    enabled: false
                  }
                }
              }
            }
          }
        ]
      }
    }),
    [dimensions, options]
  );

  return (
    <div
      className={className}
      ref={ref}
    >
      {Boolean(title) && dimensions?.height >= 336 && <VerticallyCenteredRow>{title}</VerticallyCenteredRow>}
      <div
        className="width-100 height-100"
        style={dimensions}
      >
        {Boolean(dimensions) && (
          <AppErrorBoundary
            fallback={
              <div className="inc-text-subtext status-danger">Something went wrong, please try again later</div>
            }
          >
            <HighchartsReact
              constructorType={constructorType}
              highcharts={Highcharts}
              options={optionsToRender}
            />
          </AppErrorBoundary>
        )}
      </div>
    </div>
  );
};

type Dimensions = {
  width: number;
  height: number;
};

const className = cx(
  "width-100 height-100 inc-flex-column flex-gap-12",
  css`
    padding: 12px;
    border-radius: 8px;
    background-color: #0f1822;

    .highcharts-background {
      fill: none;
    }

    .highcharts-axis-labels,
    .highcharts-legend-item {
      text {
        @extend .inc-text-subtext-medium;
        color: #b7bcc9 !important;
        fill: #b7bcc9 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .highcharts-grid {
      display: none;
    }
  `
);
