import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IncButton, IncFaIcon, IncSmartText } from "@inception/ui";
import { cloneDeep } from "lodash";
import { OpFragmentEditProps } from "../types";
import { LoadingSpinner, VerticallyCenteredRow } from "../../components";
import { generateId, logger, useFetchActionCategoryList, useToggleState } from "../../core";
import { OpCreationFragmentEditType } from "../../services/api/operationalise";
import { BizActionConfigEditor } from "../../operationalise-v2/v3/editors/ActionsEditor/BizActionConfigEditor/BizActionConfigEditor";

type Props = OpFragmentEditProps;

export const BizActionFragmentEditor: FC<Props> = props => {
  const { opFragment, invalidFragmentJsx } = props;

  if (opFragment?.bizAction) {
    return <BizActionFragmentEditorInternal {...props} />;
  }

  return invalidFragmentJsx;
};

const BizActionFragmentEditorInternal: FC<Props> = props => {
  const { opFragment, onChange, onClose, opCreationConfig } = props;

  const { isOpen: isUpdateInProgress, open: startUpdate, close: finishUpdate } = useToggleState();

  const { isError, isFetching, error, sourceTypeInfoByCategory } = useFetchActionCategoryList();

  useEffect(() => {
    if (isError) {
      logger.error("BizActionFragmentEditor", "Failed to fetch action category list", error);
    }
  }, [error, isError]);

  const errorMessageRef = useRef("");

  const defBizActionId = opFragment.bizAction?.bizActionId;
  const bizActionId = useMemo(() => defBizActionId || generateId(), [defBizActionId]);

  const defBizActionConfig = opFragment.bizAction?.bizAction;
  const [bizActionConfig, setBizActionConfig] = useState(cloneDeep(defBizActionConfig));

  const [errors, setErrors] = useState<string[]>([]);

  const resetBizActionConfig = useCallback(() => {
    setBizActionConfig(cloneDeep(defBizActionConfig));
  }, [defBizActionConfig]);

  useEffect(() => {
    resetBizActionConfig();
  }, [resetBizActionConfig]);

  const onCancelChanges = useCallback(() => {
    resetBizActionConfig();
    onClose?.();
  }, [onClose, resetBizActionConfig]);

  const onSaveChanges = useCallback(async () => {
    errorMessageRef.current = "";
    startUpdate();

    const { isError, message } = await onChange(
      {
        ...opFragment,
        bizAction: {
          bizAction: bizActionConfig,
          bizActionId
        }
      },
      OpCreationFragmentEditType.BIZ_ACTIONS
    );

    if (isError) {
      errorMessageRef.current = message;
    }

    finishUpdate();
  }, [startUpdate, onChange, opFragment, bizActionConfig, bizActionId, finishUpdate]);

  return (
    <div className="inc-flex-column width-100 flex-gap-12 op-config-editor-v2">
      {isFetching && (
        <LoadingSpinner
          className="inc-text-subtext-medium"
          titleText="Fetching sources info..."
        />
      )}
      {!isFetching && (
        <>
          {isError && (
            <VerticallyCenteredRow className="inc-text-subtext-medium status-danger flex-gap-12">
              <IncFaIcon iconName="exclamation-triangle" />
              Error fetching sources info
            </VerticallyCenteredRow>
          )}
          {!isError && (
            <BizActionConfigEditor
              bizActionConfig={bizActionConfig}
              bizActionId={bizActionId}
              errors={errors}
              mode="edit"
              onChange={setBizActionConfig}
              onErrors={setErrors}
              op10zeId=""
              opCreationConfig={opCreationConfig}
              skipName
              sourceTypeInfoByCategory={sourceTypeInfoByCategory}
            />
          )}
        </>
      )}

      <VerticallyCenteredRow className="flex-gap-12 marginTp10">
        {!isUpdateInProgress && !isError && (
          <IncButton
            color="primary"
            disabled={Boolean(errors.length)}
            label="Save Changes"
            onClick={onSaveChanges}
            size="small"
          />
        )}

        {isUpdateInProgress && (
          <IncButton
            color="primary"
            loading
            loadingText="Saving..."
            size="small"
          />
        )}

        {Boolean(errorMessageRef.current) && (
          <VerticallyCenteredRow className="status-danger flex-gap-10">
            <IncFaIcon
              className="status-danger"
              iconName="exclamation-triangle"
            />
            <IncSmartText
              className="status-danger"
              text={errorMessageRef.current}
            />
          </VerticallyCenteredRow>
        )}

        {Boolean(errors.length) && (
          <VerticallyCenteredRow className="status-danger flex-gap-10">
            <IncFaIcon
              className="status-danger"
              iconName="exclamation-triangle"
            />
            <IncSmartText
              className="status-danger"
              text={errors.join(", ")}
            />
          </VerticallyCenteredRow>
        )}

        {!isUpdateInProgress && !isError && (
          <IncButton
            color="secondary-blue"
            label="Cancel"
            onClick={onCancelChanges}
            size="small"
          />
        )}
      </VerticallyCenteredRow>
    </div>
  );
};
