import { omit, map } from "lodash";
import { TimeSeries, logger, shouldExcludeTag } from "../../../core";
import { DataQueryRequest, DataQueryResponse } from "../../../services/api/types";
import { useWidgetQuery, QueryOptions, WidgetQueryResult } from "../useQuery";
import { Op10zeDataQuery, Op10zeQueryDataResponse } from "../../../services/datasources/operationalize/types";
import {
  Op10zeMetricIncidentResponse,
  MTSIncidentSummary,
  OpPreviewDataResponse,
  Op10zeMetricResultDTO,
  OpPreviewDataResponseDTO,
  Event,
  EvaluatedDataTime
} from "../../../services/api/operationalise";
import { getCompareMetricNameFromTags, getMetricNameFromTags, isEntity } from "../../../utils";
import { SuppressionInfo, MetricResultData } from "../../../services/api/explore";

export type Op10zeDataQueryResponse = {
  timeseries: TimeSeries[];
  evaluatedDataTime: EvaluatedDataTime[];
  seasonSecs: number;
  suppressionRegions: SuppressionInfo[];
  suppressionReasonInfo: Record<string, string>;
  incidents: Op10zeMetricIncidentResponse;
  incidentsCount: string; // Stringified number
  summary: MTSIncidentSummary[];
  events: Event[];
};

export type Op10zePreviewQueryResponse = Pick<OpPreviewDataResponse, "monitoringFrequency"> &
  Pick<OpPreviewDataResponseDTO, "timeRange"> & {
    timeseries: TimeSeries[];
    seasonSecs: number;
    suppressionRegions: SuppressionInfo[];
  };

export type Op10zeQueryResponse = {
  data: Op10zeDataQueryResponse;
  previewData: Op10zePreviewQueryResponse;
};

export const useOp10zeQuery = (
  dqr: DataQueryRequest<Op10zeDataQuery>,
  datasource?: string,
  queryOptions?: QueryOptions<Op10zeQueryDataResponse[], Op10zeQueryResponse[]>
): WidgetQueryResult<Op10zeQueryResponse[]> => {
  queryOptions = queryOptions || {};

  const isPreviewQuery = Boolean(dqr.targets?.[0]?.previewPayload);
  queryOptions.transformer = {
    name: "Op10ze",
    transform: results => {
      const result = results[0] || {};
      const trResult: DataQueryResponse<Op10zeQueryResponse[]> = {
        ...result,
        data: [
          {
            data: null,
            previewData: null
          }
        ]
      };

      try {
        const resultData = result?.data?.[0];

        let seasonSecs: number;
        let timeseries: TimeSeries[] = [];
        let evaluatedDataTime: EvaluatedDataTime[] = [];
        let suppressionRegions: SuppressionInfo[] = [];
        let suppressionReasonInfo: Record<string, string> = {};

        if (resultData) {
          const dataEntry = isPreviewQuery ? resultData.previewData : resultData.data;
          if (!isPreviewQuery && resultData?.data?.evaluatedDataTime) {
            evaluatedDataTime = resultData?.data?.evaluatedDataTime;
          }

          if (dataEntry) {
            const { compareConfigData, data } = dataEntry;

            enrichData(dataEntry);

            const extractedData = processDataAndCompareData(data, compareConfigData);
            timeseries = extractedData.timeseries;
            seasonSecs = extractedData.seasonSecs;
            suppressionRegions = extractedData.suppressionRegions;
            suppressionReasonInfo = extractedData.suppressionReasonInfo;
          }
        }

        if (isPreviewQuery) {
          if (resultData) {
            const { monitoringFrequency, timeRange } = resultData.previewData;

            trResult.data = [
              {
                previewData: {
                  monitoringFrequency,
                  seasonSecs,
                  suppressionRegions,
                  timeseries,
                  timeRange
                },
                data: null
              }
            ];
          }
        } else {
          if (resultData) {
            let incidentsCount = "0";
            let summary: MTSIncidentSummary[] = [];
            let incidents: Op10zeMetricIncidentResponse = {
              count: 0,
              incidentList: []
            };

            const {
              incidents: resIncidents,
              incidentsCount: resIncidentsCount,
              summary: resSummary,
              events
            } = resultData.data;

            incidents = resIncidents;
            summary = resSummary;
            incidentsCount = resIncidentsCount;

            trResult.data = [
              {
                data: {
                  incidents,
                  incidentsCount,
                  summary,
                  seasonSecs,
                  suppressionRegions,
                  timeseries,
                  suppressionReasonInfo,
                  evaluatedDataTime,
                  events
                },
                previewData: null
              }
            ];
          }
        }
      } catch (error) {
        logger.error("useOp10zeQuery", "Error transforming data", error);
      }

      return Promise.resolve([trResult]);
    }
  };

  logger.debug(
    "Operationalize Widget",
    `Calling useWidgetQuery for  widgetquery-${dqr.requestId} from-${dqr.startTime} to-${dqr.endTime}`
  );
  return useWidgetQuery(dqr, "operationalize", datasource, queryOptions);
};

const processDataAndCompareData = (
  data: Record<string, MetricResultData>,
  compareConfigData: Record<string, MetricResultData>
) => {
  let timeseries: TimeSeries[] = [];
  let seasonSecs: number;
  let suppressionRegions: SuppressionInfo[] = [];
  let suppressionReasonInfo: Record<string, string> = {};

  if (data && compareConfigData) {
    const metricData = Object.values(data)[0];
    const compareData = Object.values(compareConfigData)[0];

    const effCompareConfigData = omit(compareConfigData, "seasonSecs");
    const compareMetricData = Object.values(effCompareConfigData)[0];

    const tsArr = [...((metricData?.data || []) as TimeSeries[]), ...((compareMetricData?.data || []) as TimeSeries[])];

    tsArr.forEach(ts => {
      const { tags, eTags = tags } = ts;

      const isCompareSeries = Boolean(tags["__apptuit__type"]);
      if (isCompareSeries) {
        const incidentSeriesName = getCompareMetricNameFromTags(eTags);
        ts.target = incidentSeriesName;
      } else {
        const fETags: Record<string, string> = {};
        Object.keys(eTags).forEach(key => {
          if (!shouldExcludeTag(key, true)) {
            fETags[key] = eTags[key];
          }
        });

        // Generate metric name from enriched tags
        ts.target = getMetricNameFromTags(fETags, "", true);
      }
    });

    seasonSecs = compareMetricData.seasonSecs;
    suppressionRegions = compareData.suppressionInfo;
    suppressionReasonInfo = compareData.suppressionReasonInfo;
    timeseries = tsArr;
  }

  return {
    timeseries,
    seasonSecs,
    suppressionRegions,
    suppressionReasonInfo
  };
};

const enrichData = (dataEntry: Op10zeMetricResultDTO | OpPreviewDataResponseDTO) => {
  const { compareConfigData, data, entityLookupData } = dataEntry;

  const entityIds = Object.keys(entityLookupData);

  const enrichTimeSeries = (dataRec: Record<string, MetricResultData>) => {
    map(dataRec, dRec => {
      dRec.data.forEach(mr => {
        const ts = mr as TimeSeries;
        const { tags = {} } = ts;

        ts.eTags = { ...tags };
        for (const [key, value] of Object.entries(tags)) {
          const val = value as string;
          if (entityIds.includes(val) && isEntity(val)) {
            const eName = entityLookupData[val];
            ts.eTags[key] = eName || val;
          }
        }
      });
    });
  };

  enrichTimeSeries(data);
  enrichTimeSeries(compareConfigData);
};
