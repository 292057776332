import React, { CSSProperties } from "react";
import { IncFaIcon } from "@inception/ui";
import { widgetRegistry, Dimensions } from "../WidgetRegistry";
import { CatalogWidget } from "./CatalogWidget";
import { CatalogMaximizeView } from "./maximize-view/MaximizeView";

widgetRegistry.register({
  id: "catalog",
  label: "Catalog Widget",
  enableInDashboard: true,
  disableVizSwitch: true,
  supportsComparison: false,
  supportsNativeEdit: false,
  supportsMaximize: true,
  supportsTimeRange: true,
  implementsTimeRangeSwitch: true,
  iconComponent: (): JSX.Element => (
    <IncFaIcon
      iconName="chart-simple"
      style={style}
    />
  ),
  component: props => <CatalogWidget {...props} />,
  maximizeComponent: props => <CatalogMaximizeView {...props} />,
  dimensions: (): Dimensions => ({
    w: 6,
    h: 12,
    minW: 6,
    minH: 6
  })
});

const style: CSSProperties = {
  fontSize: 16
};
