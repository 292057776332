import React, { Dispatch, FC, SetStateAction } from "react";
import { IncFaIcon } from "@inception/ui";
import { VerticallyCenteredRow } from "../../components";
import { useToggleState } from "../../core";
import { Plan, PlanNode, ParameterValue } from "../../services/api/chat";
import { ConditionalNodeEditor } from "./PlanEdgeEditor";

interface Props {
  plan: Plan;
  setPlan: (value: Plan) => void;
  isFetching: boolean;
  isEditingMode?: boolean;
  setSelectedNode?: Dispatch<SetStateAction<PlanNode>>;
  selectedNode?: PlanNode;
}

export const PlanNodesEditor: FC<Props> = props => {
  const { plan, setPlan, isFetching, setSelectedNode, selectedNode } = props;
  const { nodes, suggestedValues } = plan || {};
  const { items } = nodes || {};

  return (
    <div className="plan-nodes">
      {(items || []).map((node, idx) => (
        <PlanNodesRenderer
          isFetching={false}
          key={idx + node?.id || ""}
          node={node}
          selectedNode={selectedNode}
          setPlan={setPlan}
          setSelectedNode={setSelectedNode}
          suggestedValues={suggestedValues}
        />
      ))}
      {isFetching && (
        // skeleton to show nodes loading
        <div className="inc-flex-column marginLt24 flex-gap-6 marginTp12">
          <span
            className="marginLt24"
            data-show-loader={true}
            style={{ maxWidth: 150 }}
          >
            header
          </span>
          <span
            className="marginLt24"
            data-show-loader={true}
            style={{ maxWidth: 300 }}
          >
            loading content...
          </span>
        </div>
      )}
    </div>
  );
};

interface PlanNodesRendererProps {
  setPlan: (value: Plan) => void;
  node: PlanNode;
  isFetching: boolean;
  suggestedValues: ParameterValue[];
  setSelectedNode?: Dispatch<SetStateAction<PlanNode>>;
  selectedNode?: PlanNode;
}

export const PlanNodesRenderer: FC<PlanNodesRendererProps> = props => {
  const { node, setPlan, isFetching, suggestedValues, setSelectedNode, selectedNode } = props;

  const { isOpen, toggle } = useToggleState(true);

  return (
    <div
      className="node paddingBt8 paddingTp8 paddingRt24 paddingLt24 inc-flex-column flex-gap-16"
      data-expanded={isOpen}
    >
      <VerticallyCenteredRow className="flex-gap-16 inc-text-body-medium inc-cursor-pointer">
        <div className="dot" />
        <span data-show-loader={isFetching}>{node?.name || ""}</span>
        <div className="marginLtAuto">
          {
            <IncFaIcon
              className="marginLtAuto marginRt16 collapse-arrow"
              data-expanded={isOpen}
              iconName="circle-chevron-down"
              onClick={toggle}
              variant="regular"
            />
          }
        </div>
      </VerticallyCenteredRow>
      <div className="paddingLt16 marginLt8">
        {isOpen && (
          <ConditionalNodeEditor
            isFetching={isFetching}
            node={node}
            selectedNode={selectedNode}
            setPlan={setPlan}
            setSelectedNode={setSelectedNode}
            suggestedValues={suggestedValues}
          />
        )}
      </div>
    </div>
  );
};
