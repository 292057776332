import React, { FC, memo, useMemo, useState } from "react";
import { ISaxIcon } from "@inception/ui";
import { css, cx } from "emotion";
import { OpCreationFragmentType, OpCreationFragmentV2List } from "../../services/api/operationalise";
import { MarkdownRenderer, VerticallyCenteredRow } from "../../components";

interface Props {
  opFragmentList: OpCreationFragmentV2List;
  selectedSectionType?: OpCreationFragmentType;
  setSelectedSectionType?: (sectionType: OpCreationFragmentType) => void;

  overviewOnlyView?: boolean;
}

export const OpRecipeOverview: FC<Props> = memo(props => {
  const { selectedSectionType, setSelectedSectionType, opFragmentList, overviewOnlyView = false } = props;

  const { opFragments: sections } = opFragmentList || {};
  const [collapseState, setCollapseState] = useState(new Array(sections?.length || 0).fill(true));

  const sectionsJsx = useMemo(
    () =>
      sections.map((section, idx) => {
        const { name, type, summary, shortSummary } = section;

        const key = `${type}-${idx}`;
        const onClick = () => {
          setSelectedSectionType(type);
        };

        const toggleState = () => {
          setCollapseState(prev => {
            const newState = [...prev];
            newState[idx] = !newState[idx];
            return newState;
          });
        };

        const isSelected = type === selectedSectionType;
        const summaryLines = (shortSummary || summary || "").split("\n");
        const summaryContentJsx = summaryLines.map((line, idx) => {
          if (shortSummary) {
            return line;
          }

          return (
            <MarkdownRenderer
              className={summaryLineCx}
              key={idx}
              mdContent={line}
              title={line.split("** ")[1] || line}
            />
          );
        });

        return (
          <div
            className="op-creation-fragment-summary-section inc-cursor-pointer"
            data-selected={isSelected}
            key={key}
            onClick={onClick}
          >
            <div className="op-creation-fragment-summary-section--section-order inc-text-subtext-medium">{idx + 1}</div>

            <div className="op-creation-fragment-summary-section--section-content">
              <div
                className={`inc-flex-column width-100 flex-gap-12 ${overviewOnlyView ? "inc-cursor-pointer" : ""}`}
                onClick={overviewOnlyView ? toggleState : undefined}
              >
                <VerticallyCenteredRow
                  className="inc-text-body-medium flex-gap-12 width-100"
                  style={{ height: 24 }}
                >
                  {name}

                  {overviewOnlyView && (
                    <ISaxIcon
                      className="marginLtAuto collapse-arrow inc-text-inactive"
                      data-expanded={!collapseState[idx]}
                      iconName="ArrowCircleDown"
                      variant="Outline"
                    />
                  )}
                </VerticallyCenteredRow>
              </div>

              {!collapseState[idx] && (
                <div className="inc-flex-column inc-text-subtext-medium inc-text-inactive flex-gap-6">
                  {summaryContentJsx}
                </div>
              )}
            </div>
          </div>
        );
      }),
    [collapseState, overviewOnlyView, sections, selectedSectionType, setSelectedSectionType]
  );

  return <div className="operationalize-recipe--overview">{sectionsJsx}</div>;
});

const summaryLineCss = css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  p {
    margin: 0;
  }

  strong {
    margin-right: 6px;
  }
`;
const summaryLineCx = cx("inc-flex-row inc-flex-vertical-center flex-gap-4", summaryLineCss);
