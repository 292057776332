import React, { FC } from "react";
import { IncFaIcon, IncSelectOption, IncSlimSelect, IncToolTip } from "@inception/ui";
import { VerticallyCenteredRow } from "../..";
import { TimeRangeSelector } from "../../time-range";
import { TimeRange } from "../../../core";
import { ImpactedWidget } from "../../../services/api/explore";

type Props = {
  onChangeImpactWidget: (option: IncSelectOption<ImpactedWidget>) => void;
  impactedWidgetListOptions: Array<IncSelectOption<ImpactedWidget>>;
  impactedWidget: ImpactedWidget;
  shouldShowReset: boolean;
  timeRange: TimeRange;
  setTimeRange: (newTimeRange: TimeRange) => void;
  resetTimeRange: () => void;
};

export const ChangeEventTimelineHeader: FC<Props> = props => {
  const {
    onChangeImpactWidget,
    impactedWidgetListOptions,
    impactedWidget,
    shouldShowReset,
    resetTimeRange,
    setTimeRange,
    timeRange
  } = props;
  return (
    <VerticallyCenteredRow className="timeline-header">
      <div className="timeline-context-select inc-text-subtext-medium status-info ">
        <IncSlimSelect
          className="inc-text-body-medium"
          onChange={onChangeImpactWidget}
          options={impactedWidgetListOptions}
          value={
            impactedWidget
              ? {
                  label: impactedWidget.name,
                  value: impactedWidget.id,
                  data: impactedWidget
                }
              : null
          }
        />
      </div>
      <VerticallyCenteredRow className="marginLtAuto">
        {shouldShowReset && (
          <IncToolTip
            placement="top"
            titleText="Set page time range"
          >
            <VerticallyCenteredRow
              className="inc-cursor-pointer status-info marginRt16"
              onClick={resetTimeRange}
            >
              <IncFaIcon
                className="marginRt4"
                iconName="chevron-left"
                style={{ transform: "scale(0.75)" }}
              />
              <VerticallyCenteredRow>Back</VerticallyCenteredRow>
            </VerticallyCenteredRow>
          </IncToolTip>
        )}
        <TimeRangeSelector
          buttonDisplay
          onTimeSelect={setTimeRange}
          timeRange={timeRange}
        />
      </VerticallyCenteredRow>
    </VerticallyCenteredRow>
  );
};
