import {
  IncClickAwayPopper,
  IncFaIcon,
  IncInModalConfirmation,
  IncMenu,
  IncMenuItemProps,
  IncMenuToggleProps,
  IncRadioButton,
  IncSmartText
} from "@inception/ui";
import { orderBy, size } from "lodash";
import moment from "moment";
import React, { FC, useCallback, useMemo, useRef } from "react";
import { VerticallyCenteredRow } from "../../../components";
import { generateId, useAccessPrivilege, useToggleState } from "../../../core";
import { OpCreationConfig } from "../../../services/api/operationalise";
import { PRIMARY_CONFIG_AS_SIMULATION_KEY } from "../../constants";
import { useOpStore } from "../../context";

interface Props {
  onClone: () => void;
  onEdit: () => void;
  onClick: () => void;
  onOverride: (opCreationConfig: OpCreationConfig) => void;
  disableOpEdit?: boolean;
}

export const PrimaryOpConfigCard: FC<Props> = props => {
  const { onOverride, onClick, onClone, onEdit, disableOpEdit } = props;
  const { state } = useOpStore();

  const { canCreate, canEdit } = useAccessPrivilege();

  const { context, selectedSimulation, primaryOpCreationConfig } = state;
  const { sessionId } = context;

  const isPrimaryOpCreationConfig = selectedSimulation?.simulationId === PRIMARY_CONFIG_AS_SIMULATION_KEY;

  const {
    close: closeOverrideConfirmation,
    isOpen: isOverrideConfirmationOpen,
    open: openOverrideConfirmation
  } = useToggleState();

  const { close: closeHistory, isOpen: isHistoryOpen } = useToggleState();

  const historyBtnRef = useRef<HTMLButtonElement>(null);
  const setFromHistoryConfigRef = useRef<OpCreationConfig>();

  const switchPrimaryOpCreationConfig = useCallback(
    (opCreationConfig: OpCreationConfig) => {
      setFromHistoryConfigRef.current = opCreationConfig;
      openOverrideConfirmation();
    },
    [openOverrideConfirmation]
  );

  const onConfirmOverrideOpCreationConfig = useCallback(() => {
    onOverride(setFromHistoryConfigRef.current);
    setFromHistoryConfigRef.current = null;
    closeOverrideConfirmation();
  }, [closeOverrideConfirmation, onOverride]);

  const onCancelOverrideOpCreationConfig = useCallback(() => {
    setFromHistoryConfigRef.current = undefined;
    closeOverrideConfirmation();
  }, [closeOverrideConfirmation]);

  const overrideConfirmationMessage =
    "This will override current operationalize configuration. Are you sure you want to proceed?";

  const historyExists = size(primaryOpCreationConfig?.history) > 0;

  const historyJsx = useMemo(() => {
    if (historyExists) {
      const uniqId = generateId();

      const historyValues = Object.values(primaryOpCreationConfig?.history);
      const sortedHistoryValues = orderBy(
        historyValues,
        item => {
          const { createdBy, lastUpdatedBy } = item;
          const timeMillisStr = (lastUpdatedBy || createdBy)?.timeMillis;
          return timeMillisStr ? parseInt(timeMillisStr, 10) : 0;
        },
        "desc"
      );

      const historyItems = sortedHistoryValues.map((opCreationConfig, idx) => {
        const { createdBy, lastUpdatedBy, name } = opCreationConfig;

        const timeMillisStr = (lastUpdatedBy || createdBy)?.timeMillis;
        const timeMillis = timeMillisStr ? parseInt(timeMillisStr, 10) : 0;

        const relativeTimeStr = moment(timeMillis).calendar();

        const key = [sessionId, uniqId, timeMillisStr, idx].join("_");

        const onClick = () => {
          switchPrimaryOpCreationConfig(opCreationConfig);
          closeHistory();
        };

        return (
          <VerticallyCenteredRow
            className="history-menu--item inc-cursor-pointer"
            key={key}
            onClick={onClick}
          >
            <div
              className="inc-text-subtext-medium marginRtAuto"
              title={name}
            >
              {name}
            </div>
            <div
              className="inc-text-element-medium inc-text-inactive marginLt24"
              title={relativeTimeStr}
            >
              {relativeTimeStr}
            </div>
          </VerticallyCenteredRow>
        );
      });

      return <div className="history-menu">{historyItems}</div>;
    }

    return <></>;
  }, [closeHistory, historyExists, primaryOpCreationConfig, sessionId, switchPrimaryOpCreationConfig]);

  const menuItems = useCallback(
    (itemProps: IncMenuItemProps) => {
      const { className, closeMenu } = itemProps;
      const headerClassName = `inc-text-subtext-medium ${className}`;

      const lOnClone = (e: any) => {
        e.stopPropagation();
        closeMenu(e);
        onClone();
      };

      const lOnEdit = (e: any) => {
        e.stopPropagation();
        closeMenu(e);
        onEdit();
      };

      // const lOnDelete = (e: any) => {
      //   closeMenu(e);
      //   onDelete();
      // };

      return (
        <>
          {canCreate && (
            <div
              className={headerClassName}
              onClick={lOnClone}
            >
              Duplicate
            </div>
          )}
          {canEdit && (
            <div
              className={headerClassName}
              onClick={lOnEdit}
            >
              Edit
            </div>
          )}
          {/* <div className={headerClassName} onClick={lOnDelete}>
        Delete
      </div> */}
        </>
      );
    },
    [canCreate, canEdit, onClone, onEdit]
  );

  const toggle = useCallback((toggleProps: IncMenuToggleProps) => {
    const { show, toggleMenu } = toggleProps;

    const onClick = (e: any) => {
      e.stopPropagation();
      toggleMenu(!show);
    };

    return (
      <div
        className="action-icon-button display-element"
        onClick={onClick}
      >
        <IncFaIcon
          className="inc-cursor-pointer"
          iconName="ellipsis-h"
        />
      </div>
    );
  }, []);

  const onPrimaryOpCreationConfigClick = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <>
      {Boolean(primaryOpCreationConfig) && (
        <div
          className="primary-simulation visible-on-hover"
          data-selected={isPrimaryOpCreationConfig}
        >
          <VerticallyCenteredRow className="width-100 flex-gap-12">
            <IncRadioButton
              checked={isPrimaryOpCreationConfig}
              onClick={onPrimaryOpCreationConfigClick}
            />

            <div
              className="width-100 inc-cursor-pointer"
              onClick={onPrimaryOpCreationConfigClick}
              style={{ width: "calc(100% - 32px)" }}
            >
              <div className="primary-simulation--card">
                <VerticallyCenteredRow className="marginBt12">
                  <IncSmartText
                    className="marginRtAuto"
                    text={primaryOpCreationConfig.name}
                  />

                  {(canCreate || canEdit) && !disableOpEdit && (
                    <IncMenu
                      items={menuItems}
                      toggle={toggle}
                    />
                  )}
                </VerticallyCenteredRow>
                <div className="primary-simulation--live-data-pill">
                  <div className="status-pill marginRt8" />
                  <div>Live Data</div>
                </div>
              </div>
            </div>
          </VerticallyCenteredRow>

          {/* {historyExists && <IncButton
        className="marginTp12 fit-content marginLt24"
        color="secondary-blue"
        iconType="iconText"
        onClick={openHistory}
        ref={historyBtnRef}
      >
        <IncFaIcon iconName="history" />
          History
      </IncButton>} */}

          {isOverrideConfirmationOpen && (
            <IncInModalConfirmation
              message={overrideConfirmationMessage}
              onCancel={onCancelOverrideOpCreationConfig}
              onConfirm={onConfirmOverrideOpCreationConfig}
            />
          )}

          <IncClickAwayPopper
            anchorEl={historyBtnRef.current}
            onClickAway={closeHistory}
            placement="bottom-start"
            show={isHistoryOpen}
          >
            {historyJsx}
          </IncClickAwayPopper>
        </div>
      )}
    </>
  );
};
