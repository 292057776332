import React, { FC, useMemo, useCallback, useState, useRef, useEffect } from "react";
import {
  IncSelectOption,
  IncPopper,
  IncFaIcon,
  IncSelect,
  IncButton,
  IncCronEditor,
  IncCronError
} from "@inception/ui";
import cronstrue from "cronstrue";
import { CypressConstants } from "@bicycle/tests";
import { OpSchedule, SpecificScheduleConfig } from "../../../../services/api/operationalise";
import { UI_SCHEDULE_KEY, SCHEDULE_TYPES } from "../../../constants";
import { RawTimeRange, useRefState, useToggleState } from "../../../../core";
import { TimezoneSelector, VerticallyCenteredRow } from "../../../../components";
import { getCronExpressionForSpecificSchedule, getScheduleConfigFromCronExpr } from "../../../utils";
import timeRangeUtils from "../../../../utils/TimeRangeUtils";
import IncARDateTimePicker from "../../../../components/relative-date-selector/ARDateTimePicker";
import { scheduleOptions } from "./options";

interface Props {
  opSchedule: OpSchedule;
  onChange: (nSchedule: OpSchedule) => void;
  readOnly?: boolean;
  skipStartEnd?: boolean;
}

export const OpScheduleEditorV2: FC<Props> = props => {
  const { onChange, opSchedule, readOnly = false, skipStartEnd = true } = props;

  const { schedule, labels = {} } = opSchedule || {};

  const opScheduleRef = useRefState(opSchedule);

  const scheduleSelectionValue = labels?.[UI_SCHEDULE_KEY] || SCHEDULE_TYPES.everyMinute;

  const { specificScheduleConfig, timeZone, startTimeEpochSecs, endTimeEpochSecs } = schedule || {};

  const selTimeZone = useMemo(() => timeZone || "UTC", [timeZone]);

  const onTimeZoneChange = useCallback(
    (timeZone: string) => {
      const opSchedule = opScheduleRef.current;

      onChange({
        ...opSchedule,
        schedule: {
          ...schedule,
          timeZone
        }
      });
    },
    [onChange, opScheduleRef, schedule]
  );

  const defScheduleOpt =
    scheduleOptions.find(opt => opt.value === scheduleSelectionValue || opt.label === scheduleSelectionValue) ||
    scheduleOptions[1];
  const [scheduleOpt, setScheduleOpt] = useState(defScheduleOpt);

  const { close, isOpen, open } = useToggleState();

  const editorRef = useRef<HTMLDivElement>();

  const [cronError, setCronError] = useState<IncCronError>();
  const [cronExpr, setCronExpr] = useState(getCronExpressionForSpecificSchedule(specificScheduleConfig));
  useEffect(() => {
    setCronExpr(getCronExpressionForSpecificSchedule(specificScheduleConfig));
  }, [specificScheduleConfig]);

  const resetCronExpr = useCallback(() => {
    setCronExpr(getCronExpressionForSpecificSchedule(specificScheduleConfig));
    close();
  }, [close, specificScheduleConfig]);

  const scheduleLabel = useMemo(() => cronstrue.toString(cronExpr), [cronExpr]);

  const scheduleValueOpt = useMemo(() => {
    if (scheduleOpt.value === SCHEDULE_TYPES.custom) {
      return {
        ...scheduleOpt,
        label: scheduleLabel
      };
    }

    return scheduleOpt;
  }, [scheduleLabel, scheduleOpt]);

  const onScheduleOptionChange = useCallback(
    (nSchConfigItem: IncSelectOption<SpecificScheduleConfig>) => {
      const { value, data } = nSchConfigItem;
      if (value === SCHEDULE_TYPES.custom) {
        setCronExpr(getCronExpressionForSpecificSchedule(data));
        open();
        return;
      }

      const opSchedule = opScheduleRef.current;

      if (scheduleValueOpt.value !== value) {
        onChange({
          ...opSchedule,
          schedule: {
            ...opSchedule.schedule,
            specificScheduleConfig: data
          },
          labels: {
            ...(opSchedule.labels || {}),
            [UI_SCHEDULE_KEY]: value
          }
        });
        setScheduleOpt(nSchConfigItem);
      }
    },
    [onChange, opScheduleRef, open, scheduleValueOpt.value]
  );

  const canApplyCronSchedule = cronError?.type !== "invalid_cron";

  const onApplySchedule = useCallback(() => {
    const opSchedule = opScheduleRef.current;
    const scheduleOption = scheduleOptions.find(x => x.value === SCHEDULE_TYPES.custom);
    setScheduleOpt(scheduleOption);

    const specificScheduleConfig = getScheduleConfigFromCronExpr(cronExpr);
    onChange({
      ...opSchedule,
      schedule: {
        ...opSchedule.schedule,
        specificScheduleConfig
      },
      labels: {
        ...(opSchedule.labels || {}),
        [UI_SCHEDULE_KEY]: SCHEDULE_TYPES.custom
      }
    });
    close();
  }, [close, cronExpr, onChange, opScheduleRef]);

  let numStartTimeEpochSecs = parseInt(String(startTimeEpochSecs), 10);
  numStartTimeEpochSecs = Number.isNaN(numStartTimeEpochSecs) ? 0 : numStartTimeEpochSecs;
  const startTimeMillis = numStartTimeEpochSecs * 1000;
  const startDate = new Date(startTimeMillis);

  let numEndTimeEpochSecs = parseInt(String(endTimeEpochSecs), 10);
  numEndTimeEpochSecs = Number.isNaN(numEndTimeEpochSecs) ? 0 : numEndTimeEpochSecs;
  const endTimeMillis = numEndTimeEpochSecs * 1000;
  const endDate = new Date(endTimeMillis);

  const onStartEndTimeChange = useCallback(
    (timeType: "start" | "end", newDate: string | Date) => {
      const millis =
        typeof newDate === "object"
          ? newDate.getTime()
          : newDate === "now"
            ? timeRangeUtils.getNowMillis()
            : parseInt(newDate as string, 10);

      const opSchedule = opScheduleRef.current;
      onChange({
        ...opSchedule,
        schedule: {
          ...opSchedule.schedule,
          [timeType === "start" ? "startTimeEpochSecs" : "endTimeEpochSecs"]:
            timeRangeUtils.getSecondsFromMillis(millis)
        }
      });
    },
    [onChange, opScheduleRef]
  );

  const trError = endTimeMillis
    ? endTimeMillis < startTimeMillis
      ? "End time cannot be before start time"
      : undefined
    : undefined;

  const startEndOpt = useMemo(
    () => (numStartTimeEpochSecs ? startEndOptions[1] : startEndOptions[0]),
    [numStartTimeEpochSecs]
  );

  const onStartEndOptChange = useCallback(
    (opt: IncSelectOption<RawTimeRange>) => {
      const opSchedule = opScheduleRef.current;

      if (!opt.value) {
        onChange({
          ...opSchedule,
          schedule: {
            ...opSchedule.schedule,
            startTimeEpochSecs: null,
            endTimeEpochSecs: null
          }
        });
      } else {
        onChange({
          ...opSchedule,
          schedule: {
            ...opSchedule.schedule,
            ...getDefaultStartEnd()
          }
        });
      }
    },
    [onChange, opScheduleRef]
  );

  const isValid = !trError && canApplyCronSchedule;

  const { attributes } = CypressConstants.components.Operationalize.ScheduleEditor;

  return (
    <>
      <VerticallyCenteredRow className="width-100 op-schedule-editor-v2 flex-gap-12">
        <VerticallyCenteredRow className="inc-label-common">Check</VerticallyCenteredRow>

        <VerticallyCenteredRow ref={editorRef}>
          <IncSelect
            autoAdjustWidth
            autoSort={false}
            classNamePrefix={attributes.scheduleDropdown}
            data-cy={attributes.scheduleDropdown}
            isSearchable={false}
            onChange={onScheduleOptionChange}
            options={scheduleOptions}
            value={scheduleValueOpt}
          />

          {scheduleOpt.value === SCHEDULE_TYPES.custom && !readOnly && (
            <IncButton
              className="marginLt10"
              color="link"
              iconType="iconText"
              onClick={open}
              size="small"
            >
              <IncFaIcon iconName="edit" />
            </IncButton>
          )}
        </VerticallyCenteredRow>

        {!skipStartEnd && (
          <>
            <VerticallyCenteredRow className="inc-label-common">starting from</VerticallyCenteredRow>
            <IncSelect
              autoAdjustWidth
              autoSort={false}
              isSearchable={false}
              onChange={onStartEndOptChange}
              options={startEndOptions}
              value={startEndOpt}
            />

            {startEndOpt.value === startEndOptions[1].value && (
              <>
                <VerticallyCenteredRow className="time-range-selector--absolute">
                  <IncARDateTimePicker
                    allowFutureDates
                    autoAdjustWidth
                    enableBeginningAndEndTimes={false}
                    hideSetToNow
                    onChange={newDate => onStartEndTimeChange("start", newDate)}
                    popperPlacement="top"
                    value={startDate}
                    withSeconds
                  />
                </VerticallyCenteredRow>

                <VerticallyCenteredRow className="inc-label-common">to</VerticallyCenteredRow>

                <VerticallyCenteredRow className="time-range-selector--absolute">
                  <IncARDateTimePicker
                    allowFutureDates
                    autoAdjustWidth
                    enableBeginningAndEndTimes={false}
                    hideSetToNow
                    id="time-range-selector-absolute-from"
                    onChange={newDate => onStartEndTimeChange("start", newDate)}
                    popperPlacement="top"
                    value={endDate}
                    withSeconds
                  />
                </VerticallyCenteredRow>
              </>
            )}
          </>
        )}
      </VerticallyCenteredRow>

      <IncPopper
        anchorEl={editorRef.current}
        placement="bottom"
        show={isOpen}
      >
        <div
          className="op-v3-editor-popper"
          style={{ minWidth: 480 }}
        >
          <VerticallyCenteredRow className="marginBt12">
            <div className="inc-text-subtext-medium inc-text-inactive marginRt8">Timezone:</div>

            <TimezoneSelector
              onChange={onTimeZoneChange}
              skipIcon
              timeZone={selTimeZone}
            />
          </VerticallyCenteredRow>
          <IncCronEditor
            cronExpr={cronExpr}
            onError={setCronError}
            setCronExpr={setCronExpr}
            showFurtherOccurrances
          />

          <VerticallyCenteredRow className="marginTp12 flex-gap-12">
            <IncButton
              color="primary"
              disabled={!isValid}
              onClick={onApplySchedule}
            >
              Apply
            </IncButton>

            <IncButton
              color="secondary-blue"
              onClick={resetCronExpr}
            >
              Cancel
            </IncButton>

            {!isValid && (
              <VerticallyCenteredRow className="inc-text-subtext-medium status-danger">
                {trError || cronError?.description || "Invalid schedule"}
              </VerticallyCenteredRow>
            )}
          </VerticallyCenteredRow>
        </div>
      </IncPopper>
    </>
  );
};

const getDefaultStartEnd = () => ({
  startTimeEpochSecs: timeRangeUtils.getSecondsFromMillis(
    timeRangeUtils.getMillis("now-1w", {
      alignToDay: true
    }).millis
  ),
  endTimeEpochSecs: timeRangeUtils.getSecondsFromMillis(
    timeRangeUtils.getMillis("now", {
      alignToDay: true
    }).millis
  )
});

const startEndOptions: Array<IncSelectOption<RawTimeRange>> = [
  {
    label: "now",
    value: "now",
    data: null
  },
  {
    label: "custom",
    value: "custom",
    data: {
      from: "now-1w",
      to: "now"
    }
  }
];
