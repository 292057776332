import { getFormattedDateTime, IncSmartText, IncToolTip } from "@inception/ui";
import { forEach } from "lodash";
import React from "react";
import { TimeRange } from "../../../core";
import { MTSIncidentSummaryLine } from "../../../services/api/operationalise";
import { getXAxisLabelsAndFormat } from "../../time-series/ChartOptionsBuilder";

type ganttSeries = {
  tags: Record<string, string>;
  series: MTSIncidentSummaryLine[];
};

export const getGanttHeader = (timeRange: TimeRange, width: number) => {
  const { includeSeconds, labelFormat, labels } = getXAxisLabelsAndFormat(timeRange, width);

  return labels.map(x => {
    const value = x * 1000;
    const format = getFormattedDateTime(value, labelFormat, {
      withSeconds: includeSeconds
    });
    const endtime = timeRange.to.valueOf();
    const starttime = timeRange.from.valueOf();
    const startPercent = ((value - starttime) / (endtime - starttime)) * 100;

    return (
      <IncSmartText
        key={x}
        style={{
          position: "absolute",
          left: `${startPercent < 0 ? 0 : startPercent}%`,
          top: "8px",
          width: "60px"
        }}
        text={format}
      />
    );
  });
};

export const getGanttRows = (
  ganttSerie: ganttSeries,
  timeRange: TimeRange,
  subSeries: ganttSeries[],
  accessorHeaderMap: Record<string, string>,
  tagsToDisplay: string[],
  lookUpData: Record<string, string>,
  groupBy: string
) => {
  const tagsToValueMap: Record<string, string[]> = {};
  subSeries.forEach(serie => {
    if (serie.series.length > 0) {
      tagsToDisplay.forEach(x => {
        const value = serie.tags[x];
        if (value) {
          if (!tagsToValueMap[x]) {
            tagsToValueMap[x] = [value];
          } else if (!tagsToValueMap[x].includes(value)) {
            tagsToValueMap[x].push(value);
          }
        }
      });
    }
  });

  const tagsCountMap: Record<string, number> = {};

  let entityType = "";
  forEach(tagsToValueMap, (value, key) => {
    if (key === groupBy) {
      const val = value[0];
      entityType = lookUpData[val] || val;
    }
    const actualKey = accessorHeaderMap[key];
    tagsCountMap[actualKey] = tagsToValueMap[key].length;
  });

  return (
    <div
      className="gantt-row"
      style={{
        position: "relative",
        width: "100%"
      }}
    >
      {ganttSerie?.series?.map(serie => {
        if (serie.ranges?.length > 0) {
          return serie.ranges.map(range => {
            const isActiveAlert = parseInt(range.endSec?.toString(), 10) === -1;
            const endtime = timeRange.to.valueOf();
            const starttime = timeRange.from.valueOf();
            const incidentStartTime = range.windowStartSec * 1000;
            const incidentEndTime = isActiveAlert ? endtime : range.endSec * 1000;
            const width = ((incidentEndTime - incidentStartTime) / (endtime - starttime)) * 100;
            const leftPos = ((incidentStartTime - starttime) / (endtime - starttime)) * 100;
            const key = `${serie.mtsId}-${range.windowStartSec}`;

            const ganttRow = (
              <div
                className="gantt-bar"
                key={key}
                style={{
                  width: `${width}%`,
                  position: "absolute",
                  left: `${leftPos < 0 ? 0 : leftPos}%`,
                  top: "2px",
                  border: isActiveAlert ? "1px solid rgba(255, 82, 59, 0.5)" : null,
                  borderRadius: "8px"
                }}
              >
                {isActiveAlert ? (
                  <>
                    <span className="inc-text-element">STATUS</span>
                    <span className="inc-text-element-medium marginLt8">Open</span>
                  </>
                ) : null}
              </div>
            );

            return (
              <IncToolTip
                key={key}
                placement="top"
                showArrow={true}
                titleElement={ganttTooltip(incidentStartTime, incidentEndTime, tagsCountMap, timeRange, entityType)}
              >
                {ganttRow}
              </IncToolTip>
            );
          });
        }
        return null;
      })}
    </div>
  );
};

const ganttTooltip = (
  startTime: number,
  endTime: number,
  tagsCountMap: Record<string, number>,
  timeRange: TimeRange,
  entityTypeName: string
) => {
  const { includeSeconds, labelFormat } = getXAxisLabelsAndFormat(timeRange, 600);
  const startTimeFormatted = getFormattedDateTime(startTime, labelFormat, {
    withSeconds: includeSeconds
  });
  const endTimeFormatted = getFormattedDateTime(endTime, labelFormat, {
    withSeconds: includeSeconds
  });

  const tagsInfo: JSX.Element[] = [];

  forEach(tagsCountMap, (value, key) => {
    tagsInfo.push(
      <div className="inc-flex-row inc-flex-space-contents marginTp8">
        <span className="inc-text-subtext">Unique count of {key}</span>
        <span className="inc-text-subtext-medium marginLt12">{value}</span>
      </div>
    );
  });
  return (
    <div className="gantt-tooltip inc-flex-column">
      {entityTypeName && <span className="inc-text-body">{entityTypeName}</span>}
      {tagsInfo}
      <div className="marginTp8">
        <span className="inc-text-element">Error Time</span>
        <span className="marginLt8 inc-text-subtext-medium">
          {startTimeFormatted} - {endTimeFormatted}
        </span>
      </div>
    </div>
  );
};
