import React, { FC, useCallback, useMemo } from "react";
import { ConversationStatus } from "../../services/api/chat";
import {
  LowLevelFragment,
  LowLevelFragmentType,
  InputTextFragment,
  VizOption
} from "../../services/api/chat/types/low-level-fragment";
import { DemoDataParams } from "../../services/api/explore";
import { VerticallyCenteredRow } from "../flex-components";
import { MemoizedChatBotVizRenderer, RawDataRenderer } from "../../chat-bot/components";
import { GenericHighchartsRenderer } from "../GenericHighchartsRenderer";
import { convertMarkdownToHtml } from "../../utils";
import { MarkdownRenderer } from "../markdown";
import { ButtonFragmentRenderer } from "./ButtonFragmentRenderer";
import { InputFragmentRenderer } from "./InputFragmentRenderer";
import { MarkdownRendererWithReferences } from "./MarkdownRendererWithReferences";
import { ObjectFragmentRenderer } from "./ObjectFragmentRenderer";
import { IntermediateFragmentListRenderer } from "./IntermediateFragmentListRenderer";

interface FragmentRendererV2Props {
  subStep: LowLevelFragment;
  status: ConversationStatus;
  demoDataParams?: DemoDataParams;
  onUpdateFragment?: (value: LowLevelFragment) => void;
  onActionClick?: () => void;
  useParentWidth?: boolean;
}

export const FragmentRendererV2: FC<FragmentRendererV2Props> = props => {
  const { subStep, demoDataParams, status, onUpdateFragment, onActionClick, useParentWidth = false } = props;
  const { type, name } = subStep || {};
  const {
    lowLevelFragmentList,
    highchartsFragment,
    vizFragment,
    markdown,
    html,
    objectFragment,
    inputTextFragment,
    buttonFragment,
    displayTextListItem,
    references
  } = subStep || {};

  const onUpdateFragmentList = useCallback(
    (fragment: LowLevelFragment, idx: number) => {
      if (onUpdateFragment) {
        const newFragList = lowLevelFragmentList?.lowLevelFragment || [];
        newFragList[idx] = fragment;
        onUpdateFragment({
          ...subStep,
          lowLevelFragmentList: {
            lowLevelFragment: newFragList
          }
        });
      }
    },
    [lowLevelFragmentList, onUpdateFragment, subStep]
  );

  const children = useMemo(() => {
    let children: JSX.Element | JSX.Element[] = <></>;
    if (type === LowLevelFragmentType.INTERMEDIATE_FRAGMENT_LIST) {
      children = (
        <IntermediateFragmentListRenderer
          demoDataParams={demoDataParams}
          fragment={subStep}
          status={status}
        />
      );
    } else if (type === LowLevelFragmentType.MARKDOWN) {
      const isEditable = markdown?.isEditable || false;
      const mdResponse = markdown?.markdown || "";
      const onUpdateMarkdown = (value: string) => {
        if (onUpdateFragment) {
          onUpdateFragment({
            ...subStep,
            markdown: {
              ...subStep.markdown,
              markdown: value
            }
          });
        }
      };
      children = (
        <MarkdownRendererWithReferences
          isEditable={isEditable}
          markdown={mdResponse}
          onMarkdownChange={onUpdateMarkdown}
          references={references}
        />
      );
    } else if (type === LowLevelFragmentType.DISPLAY_TEXT_LIST_ITEM) {
      const { displayText, isMarkdown, tags } = displayTextListItem;
      const mdResponse = markdown?.markdown || "";
      children = (
        <>
          <MarkdownRenderer
            className="marginBt12 marginRt24"
            mdContent={mdResponse}
          />

          <div className="inc-flex-column flex-gap-8">
            {isMarkdown && <MarkdownRenderer mdContent={displayText || ""} />}
            {!isMarkdown && displayText}

            {tags?.length > 0 && (
              <ul>
                {tags.map((tag, id) => (
                  <>
                    {isMarkdown && (
                      <li
                        dangerouslySetInnerHTML={{ __html: convertMarkdownToHtml(tag || "") }}
                        key={id}
                      />
                    )}
                    {!isMarkdown && <li key={id}>{tag}</li>}
                  </>
                ))}
              </ul>
            )}
          </div>
        </>
      );
    } else if (type === LowLevelFragmentType.VIZ_FRAGMENT) {
      const { dataQuery, fromMillis, toMillis, viz, compareFromOffset, disableCompare, properties } = vizFragment;
      if (viz === VizOption.RAW_DATA) {
        children = (
          <>
            {!dataQuery && (
              <VerticallyCenteredRow className="marginBt6 status-danger width-100 inc-flex-center">
                Invalid raw data request
              </VerticallyCenteredRow>
            )}
            {Boolean(dataQuery) && (
              <RawDataRenderer
                dataQuery={dataQuery}
                fromMillis={fromMillis}
                rawDataProperties={(properties as any).rawDataProperties}
                toMillis={toMillis}
              />
            )}
          </>
        );
      } else {
        children = (
          <>
            {Boolean(dataQuery) && (
              <MemoizedChatBotVizRenderer
                bizDataQuery={dataQuery}
                compareFromOffset={compareFromOffset}
                demoDataParams={demoDataParams}
                disableCompare={disableCompare}
                fromMillis={fromMillis}
                properties={properties}
                title={name}
                toMillis={toMillis}
                vizType={viz}
              />
            )}
            {!dataQuery && (
              <VerticallyCenteredRow className="status-danger width-100 inc-flex-center marginBt6">
                No query configuration exists
              </VerticallyCenteredRow>
            )}
          </>
        );
      }
    } else if (type === LowLevelFragmentType.HIGHCHARTS_FRAGMENT) {
      children = (
        <>
          {!highchartsFragment && (
            <VerticallyCenteredRow className="marginBt6 status-danger width-100 inc-flex-center">
              Invalid highcharts request
            </VerticallyCenteredRow>
          )}
          {Boolean(highchartsFragment) && (
            <div className="marginRt16">
              <GenericHighchartsRenderer
                constructorType={highchartsFragment.constructorType}
                options={highchartsFragment.options}
                title={highchartsFragment.title}
                useParentWidth={useParentWidth}
              />
            </div>
          )}
        </>
      );
    } else if (type === LowLevelFragmentType.HTML) {
      const mdResponse = html?.html || "<></>";
      children = (
        <div
          className="marginBt12"
          dangerouslySetInnerHTML={{ __html: mdResponse }}
        />
      );
    } else if (type === LowLevelFragmentType.FRAGMENT_LIST) {
      children = (
        <div className="low-level-fragment-list inc-flex-column flex-gap-16">
          {(lowLevelFragmentList?.lowLevelFragment || []).map((fragment, idx) => {
            const onUpdateFragmentInternal = (fragment: LowLevelFragment) => {
              onUpdateFragmentList(fragment, idx);
            };
            return (
              <FragmentRendererV2
                demoDataParams={demoDataParams}
                key={idx + (fragment?.id || "")}
                onUpdateFragment={onUpdateFragmentInternal}
                status={status}
                subStep={fragment}
              />
            );
          })}
        </div>
      );
    } else if (type === LowLevelFragmentType.OBJECT_FRAGMENT) {
      children = (
        <ObjectFragmentRenderer
          objFragment={objectFragment}
          status={status}
        />
      );
    } else if (type === LowLevelFragmentType.INPUT_TEXT_FRAGMENT) {
      const onUpdate = (fragment: InputTextFragment) => {
        if (onUpdateFragment) {
          onUpdateFragment({
            ...subStep,
            inputTextFragment: fragment
          });
        }
      };
      children = (
        <InputFragmentRenderer
          inputTextFragment={inputTextFragment}
          onUpdateFragment={onUpdate}
        />
      );
    } else if (type === LowLevelFragmentType.BUTTON_FRAGMENT) {
      children = (
        <ButtonFragmentRenderer
          btnFragment={buttonFragment}
          onActionClick={onActionClick}
        />
      );
    }
    return children;
  }, [
    type,
    demoDataParams,
    subStep,
    status,
    markdown,
    references,
    onUpdateFragment,
    displayTextListItem,
    vizFragment,
    name,
    highchartsFragment,
    useParentWidth,
    html,
    lowLevelFragmentList,
    onUpdateFragmentList,
    objectFragment,
    inputTextFragment,
    buttonFragment,
    onActionClick
  ]);

  return <div className={`marginTp8 paddingLt8`}>{children}</div>;
};
