import { ResponseInfo, MetricResult, SelectorSpec, TimeObj, MetricResultData } from "../../explore";
import { DataType, EntityPropertyValue, FieldPrimType } from "../../../../core";
import { BizImpactConfig } from "../../types";
import { OpFragment } from "../../chat";
import {
  OpCreationConfig,
  AlertCountList,
  OpTriageConfig,
  OpTriageConfigType,
  OpCreationConfigDef
} from "./OperationaliseV2Types";
import { BizActionConfig, SectionElementType } from "./ActionTypesV2";
import { BizIdProps, OpMetaDataV2 } from "./CommonTypesV2";
import { MTSIncidentSummaryLine, MTSIncidentSummary, AlertSnapshot } from "./alerts";
import { OpState, OpStateList } from "./OpState";

interface CommonResponse {
  responseInfo: ResponseInfo;
  statusCode: number;
}

export interface Op10zeSetupRequest {
  opCreationConfig: OpCreationConfig;
  opFragments?: OpFragment[];
  widgetId?: string;
  metricId?: string;
}

export interface RenameOp10zeRequest {
  name: string;
}

export interface BizActionSetupRequest {
  bizActionConfig: BizActionConfig;

  // This is used in test action case where the operationalize is not yet created
  opConfig?: OpCreationConfig;

  // This is used for test action to pick whioch alert to use for test
  alertId?: string;
  alertSnapshot?: AlertSnapshot;
  alertTimeMillis?: number;
}

export interface Op10zeSearchRequest extends Partial<Op10zeAlertParams> {
  //oneOf
  op10zeId?: string;
  widgetId?: string;
  idProps?: BizIdProps;
  widgetIds?: {
    id: string[];
  };
  op10zeIds?: {
    id: string[];
  };

  //oneOf
  incidentId?: string;
  version?: string;
}

export type Op10zeAlertParams = {
  startTimeMillis: number;
  endTimeMillis: number;
  includeAlertCount: boolean;
  populateActionTags: boolean;
};

export interface Op10zeSetupResponse extends CommonResponse {
  op10zeId: string;
  opCreationConfig: OpCreationConfig;
}

export interface BizActionResponse extends CommonResponse {
  actionId: string;
  message: string[];
}

export interface Op10zeSearchResponse extends CommonResponse {
  opCreationConfigs: Record<string, OpCreationConfig>;
  alertCounts: Record<string, AlertCountList>;
  opStatus: Record<string, OpState>;
}

interface CommonDisableResponse extends CommonResponse {
  opId: string;
  disabled: boolean;
}

interface CommonDeleteResponse extends Omit<CommonDisableResponse, "disabled"> {
  opId: string;
  deleted: boolean;
}

export interface OpDisableResponse extends CommonDisableResponse {
  simulationId: string;
}

export interface OpDeleteResponse extends CommonDeleteResponse {
  simulationId: string;
}

export interface ActionDisableResponse extends CommonDisableResponse {
  actionId: string;
}

export interface ActionDeleteResponse extends CommonDeleteResponse {
  actionId: string;
}

export interface OpStateListResponse extends CommonResponse {
  state: OpStateList;
}

export interface FetchIncidentDataRequest {
  startTimeMillis: number;
  endTimeMillis: number;
}

export type Op10zeDataQueryPayload = {
  selectorSpec: SelectorSpec;
  frequency: TimeObj;
  lookBack: TimeObj;
  forCount: boolean;
  mqId?: string;
  offsetData?: Record<string, any>;
  // Used strictly for demo data generation
  downsampleSecs?: number;
  showHistoricalData?: boolean;
};

type ResultDTOPickProps = "entityLookupData" | "dataQueryConfig" | "warnings";
type ResultPickProps = ResultDTOPickProps | "data" | "compareConfigData";

export interface Op10zeMetricResult extends Pick<MetricResult, ResultPickProps> {
  incidents: Op10zeMetricIncidentResponse;
  incidentsCount: string; // Stringified number
  summary: MTSIncidentSummary[];
  subType?: DataType;
}

export interface Event {
  id: string;
  startMillis: number;
  endMillis: number;
  title: string;
}

export interface EvaluatedDataTime {
  fromTimeInSec: string;
  timeInSec: string;
  toTimeInSec: string;
}

export interface Op10zeMetricResultDTO extends Pick<MetricResult, ResultDTOPickProps> {
  data: Record<string, MetricResultData>;
  compareConfigData: Record<string, MetricResultData>;
  incidents: Op10zeMetricIncidentResponse;
  incidentsCount: string; // Stringified number
  summary: MTSIncidentSummary[];
  events?: Event[];
  evaluatedDataTime?: EvaluatedDataTime[];
}

export interface Op10zeMetricIncidentResponse {
  incidentList: MTSIncidentSummaryLine[];
  count: number;
}

type CommonSimulationRequest = {
  name: string;
  description: string;

  executeActions: boolean;
};

export type DraftOpSimulationRequest = CommonSimulationRequest & {
  isDraftOp: boolean;
  draftOpSetupRequest: Op10zeSetupRequest;
};

export type SavedOpSimulationRequest = CommonSimulationRequest & {
  opId: string;
  simulationOpConfig: OpCreationConfig;
};

export type OpSimulationRequest = DraftOpSimulationRequest | SavedOpSimulationRequest;

export interface OpSimulationResponse {
  opId: string;
  simulationId: string;
  responseInfo: ResponseInfo;
  statusCode: number;
}

export enum OpSimulationStatus {
  NA = "na",
  IN_PROGERSS = "in_progress",
  FINISHED = "finished",
  FAILED = "failed"
}

export interface OpSimulationState {
  status: OpSimulationStatus;
  percentCompleted: number;
  estimatedTimeLeft: TimeObj;

  simulationStatus: CompleteSimulationStatus;

  responseInfo: ResponseInfo;
  statusCode: number;
}

export interface SimulationStats {
  total: number;
  completed: number;
  failed: number;
}

export interface CompleteSimulationStatus {
  startTimeMillis: number;
  previousStates: SimulationStatus[];
  currentState: SimulationStatus;
}

export interface SimulationStatus {
  stage: SimulationStage;
  scoringStatus: Record<string, SimulationStats>;
  incidentsStatus: Record<string, SimulationStats>;
  startTimeMillis: number;
  timeTakenMillis: number;
  timestamp: number;
}

export enum SimulationStage {
  DATA_GENERATION = 0,
  ASH_DETECTION = 1,
  SERIES_DETECTION = 2,
  SCORING = 3,
  INCIDENTS = 4,
  COMPLETED = 5
}

export interface OpTriageConfigResponse extends OpTriageConfig {
  responseInfo: ResponseInfo;
  statusCode: number;
}

interface ActionTemplateElementsRequestOpId {
  opId: string;
  bizActionConfig: BizActionConfig;
  opConfig?: never;
}

interface ActionTemplateElementsRequestOpConfig {
  opConfig: OpCreationConfig;
  bizActionConfig: BizActionConfig;
  opId?: never;
}

export type ActionTemplateElementsRequest = ActionTemplateElementsRequestOpId | ActionTemplateElementsRequestOpConfig;

export type PropEditorTypes = "toggle" | "text_box" | "single_select" | "multi_select";

export interface ActionTemplateElementProp {
  propId: string;

  label: string;
  description: string;

  type: FieldPrimType;
  editor: PropEditorTypes;
  fieldOrder: number;
  group: string;

  includeInDisplay: boolean;

  required: boolean;
  defaultValue: EntityPropertyValue;
  ranges: EntityPropertyValue[];

  hasNameLookup: boolean;
  lookupData: Record<string, string>;
}

export interface ActionTemplateSectionElement {
  elementId: "rich-text-section" | "script-section" | "event-groupby-section" | "toolbar-section";

  label: string;
  description: string;
  displayText?: string;

  type: SectionElementType;
  sectionKind?: string;

  icon: string;
  canLoopInEmbeddedMode?: boolean;
  prop: ActionTemplateElementProp[];
}

export type ActionTemplateTextElementDisplayMode =
  | "use_props"
  | "use_label"
  | "use_display_text"
  | "use_props_with_label";

export interface ActionTemplateTextElement {
  icon: string;
  elementId: string;
  description: string;

  type: FieldPrimType;
  subtype: "text" | "url";
  useInLoopOnly: boolean;

  label: string;
  expression: string;
  displayText: string;
  prop: ActionTemplateElementProp[];
  displayMode: ActionTemplateTextElementDisplayMode;

  group: string;
  fieldOrder: number;
}

export interface ActionTemplateElementGroup {
  name: string;
  groupOrder: number;
}

export interface ActionTemplateElementsResponse {
  elementGroup: ActionTemplateElementGroup[];
  sectionElement: ActionTemplateSectionElement[];
  textElement: ActionTemplateTextElement[];
}

export interface UpdateOpTriageRequest extends Partial<OpTriageConfig> {
  opTriageConfigType: OpTriageConfigType[];
}

export type OpMetaDataEditRequest = Partial<OpMetaDataV2>;

export type Op10zeEditResponse = {
  updatedOpCreationConfig: OpCreationConfig;
  version: number;

  responseInfo: ResponseInfo;
  statusCode: number;
};

export type OpEditRequest = Partial<{
  updatedOpCreationConfigDef: OpCreationConfigDef;
  metadataEditRequest: OpMetaDataEditRequest;
}>;

export type GetBizImpactConfigResponse = {
  bizImpactConfig: BizImpactConfig;
  responseInfo: ResponseInfo;
  statusCode: number;
};
