import { IncFaIcon } from "@inception/ui";
import React, { FC } from "react";
import { VerticallyCenteredRow } from "../../../../components";
import { BizDataQuery, ImpactedWidget } from "../../../../services/api/explore";
import { SuppressionConfigDef } from "../../../../services/api/operationalise";
import { OpContext } from "../../../context/types";
import { SuppressionsEditor } from "./SuppresionsEditor";

interface Props {
  suppressions: SuppressionConfigDef[];
  pickerContext: OpContext;
  bizDataQuery: BizDataQuery;
  onChange: (suppressions: SuppressionConfigDef[]) => void;
  readOnly?: boolean;
  compareTimeSeconds?: number;
  getPredefinedMetrics?: () => Promise<ImpactedWidget[]>;
}

export const SuppressionsEditorWrapper: FC<Props> = props => {
  const canRender = props.readOnly ? props.suppressions?.length > 0 : true;

  return canRender ? (
    <div className="op-suppressions-editor marginTp12 marginBt12">
      <VerticallyCenteredRow className="marginBt16">
        <div className="inc-text-body-medium inc-text-inactive marginRt8">Exceptions</div>

        <IncFaIcon
          className="marginRt8"
          iconName="info-circle"
        />
        <div className="inc-text-element-medium inc-text-inactive">
          Add scenarios that you do not want to see, even if the condition is violated
        </div>
      </VerticallyCenteredRow>
      <SuppressionsEditor {...props} />
    </div>
  ) : (
    <></>
  );
};
