import React, { FC } from "react";
import { useOpStore } from "../../context";
import { ScheduleEditorWrapper } from "./ScheduleEditor";
import { BizDataQueryEditorWrapper } from "./BizDataQueryEditor";
import WhatsNewEditorWrapper from "./WhatsNewEditorWrapper";

interface Props {
  showWhatsNewConfig?: boolean;
}

export const ScheduleAndConfigEditorWrapper: FC<Props> = props => {
  const { showWhatsNewConfig } = props;
  const { state } = useOpStore();

  const { readOnly, context } = state;
  const { selectionContext } = context;
  const isFieldOperationalize = selectionContext === "field";

  return (
    <div
      className="op-config-editor-v2"
      data-readonly={readOnly}
    >
      <div className="inc-text-body-medium inc-text-inactive marginBt16">Evaluation</div>
      <ScheduleEditorWrapper />
      <BizDataQueryEditorWrapper />
      {showWhatsNewConfig && !isFieldOperationalize && <WhatsNewEditorWrapper />}
    </div>
  );
};
