import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IncModal, IncModalProps, IncSelect, IncSelectOption } from "@inception/ui";
import { clone } from "lodash";
import { BizDataQuery, DemoDataParams, Slice } from "../../../services/api/explore";
import { UberOperationalizeTypes } from "../../../services/api/operationalise";
import { ENTITY_TAG } from "../../../utils";
import { KPIQueryEditor } from "../../../components";
import { ThresholdContext } from "./types";

type Props = {
  onClose: () => void;
  onSave: (bizDataQuery: BizDataQuery) => void;
  bizDataQuery: BizDataQuery;
  opBizDataQuery: BizDataQuery;
  isOpen: boolean;
  pickerContext: ThresholdContext;
  demoDataParams?: DemoDataParams;
};

const DataQueryEditor: FC<Props> = props => {
  const { bizDataQuery: pBizDataQuery, isOpen, onClose, onSave: pOnSave, opBizDataQuery, pickerContext } = props;

  const { sliceSpec } = opBizDataQuery || {};
  const { sliceSet } = sliceSpec || {};
  const { slices: optionSlices = [] } = sliceSet || {};
  const { entityTypeName, eventTypeName } = pickerContext;
  const [bizDataQuery, setBizDataQuery] = useState<BizDataQuery>(pBizDataQuery);
  const bizDataQueryRef = useRef<BizDataQuery>(pBizDataQuery);

  const { sliceSpec: selectedSliceSpec } = bizDataQuery || {};

  const { sliceSet: selectedSliceSet } = selectedSliceSpec || {};
  const { slices: selectedSlices = [] } = selectedSliceSet || {};

  const sliceOptions = useMemo<Array<IncSelectOption<Slice>>>(() => {
    const slices: Slice[] = optionSlices;
    return slices.map(slice => {
      const label = slice.tagName === ENTITY_TAG ? entityTypeName || eventTypeName : slice.tagName;
      return {
        label: label,
        value: slice.tagName,
        data: slice
      };
    });
  }, [entityTypeName, eventTypeName, optionSlices]);

  const selectedSliceOptions = useMemo<Array<IncSelectOption<Slice>>>(
    () =>
      selectedSlices.map(slice => {
        const label = slice.tagName === ENTITY_TAG ? entityTypeName || eventTypeName : slice.tagName;
        return {
          label: label,
          value: slice.tagName,
          data: slice
        };
      }),
    [entityTypeName, eventTypeName, selectedSlices]
  );

  const onChangeSlices = useCallback((selectedOptions: ReadonlyArray<IncSelectOption<Slice>>) => {
    setBizDataQuery(currentQuery => {
      const nQuery = {
        ...currentQuery,
        sliceSpec: {
          ...currentQuery.sliceSpec,
          sliceSet: {
            ...currentQuery?.sliceSpec?.sliceSet,
            slices: selectedOptions.map(option => option.data)
          }
        }
      };
      return nQuery;
    });
  }, []);

  useEffect(() => {
    bizDataQueryRef.current = bizDataQuery;
  }, [bizDataQuery]);

  const onSave = useCallback(() => {
    pOnSave(bizDataQueryRef.current);
    setBizDataQuery(bizDataQueryRef.current);
    onClose();
  }, [onClose, pOnSave]);

  const actions = useMemo<IncModalProps["actions"]>(
    () => ({
      primary: {
        onClick: onSave,
        label: "Save"
      },
      secondary: {
        onClick: onClose,
        label: "Cancel"
      }
    }),
    [onClose, onSave]
  );

  const onChange = useCallback((isValid: boolean, errorsText: string, kpi: UberOperationalizeTypes.OpBizDataQuery) => {
    if (isValid) {
      bizDataQueryRef.current.widgetConfig = kpi.bizDataQuery.widgetConfig;
    }
  }, []);

  const selectedBizDataQuery = useMemo<UberOperationalizeTypes.OpBizDataQuery>(
    () => ({
      bizDataQuery: clone(bizDataQueryRef.current),
      rollingFreq: null,
      rollingFunction: null
    }),
    []
  );

  return (
    <IncModal
      actions={actions}
      onClose={onClose}
      show={isOpen}
      titleText={"Edit Query Info"}
      withActionsBorder
      withTitleBorder
    >
      {isOpen && (
        <>
          <div className="marginTp16 paddingLt16 paddingRt16">
            <IncSelect
              isMulti
              label="Metrics"
              onChange={onChangeSlices}
              options={sliceOptions}
              value={selectedSliceOptions}
            />
          </div>

          <KPIQueryEditor
            kpiQuery={selectedBizDataQuery}
            onStateChange={onChange}
          />
        </>
      )}
    </IncModal>
  );
};

export default DataQueryEditor;
