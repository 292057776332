import { isEqual } from "lodash";
import { UserServiceFieldWithMeta } from "../../services/api/explore";
import { FieldPickerUtils } from "../../utils";
import VariableModel, { VariableType } from "../models/VariableModel";
import { logger } from "../../core";
import CohortVariableImpl from "./CohortVariableImpl";
import ConstantVariableImpl from "./ConstantVariableImpl";
import CustomVariableImpl from "./CustomVariableImpl";
import EventExpressionVariableImpl from "./EventExpressionVariableImpl";
import EventVariableImpl from "./EventVariableImpl";
import QueryVariableImpl from "./QueryVariableImpl";
import VariableImpl from "./VariableImpl";

export const getVariableImpl = (
  variable: VariableImpl | VariableModel,
  userServiceFieldsList: UserServiceFieldWithMeta[] = []
) => {
  const varType = variable?.type;
  switch (varType) {
    case VariableType.Cohort: {
      return new CohortVariableImpl(variable);
    }

    case VariableType.Event: {
      const field = (variable as EventVariableImpl).userServiceField;
      const fieldLabel = FieldPickerUtils.generateNamefromUSF(field);

      const similarFields = userServiceFieldsList.filter(x => {
        const xFieldLabel = FieldPickerUtils.generateNamefromUSF(x.userServiceField);
        const isEqualLabels = xFieldLabel === fieldLabel;
        const isEqualDataType = x.userServiceField?.dataType === field?.dataType;

        return isEqualLabels && isEqualDataType;
      });

      let simField = null;
      if (similarFields.length > 1) {
        logger.warn(
          "getVariableImpl",
          "muliple fields found with similar names, picking the one with fields. prefix",
          similarFields
        );
        simField = similarFields.find(
          x =>
            x?.userServiceField?.fieldName?.includes("fields.") &&
            x?.userServiceField?.displayBizEntityFieldName === "userService"
        );
      } else if (similarFields?.length) {
        simField = similarFields[0];
      }

      if (simField) {
        const isEqualUserServices = isEqual(simField?.userServiceField?.userServices, field?.userServices);

        if (!isEqualUserServices) {
          (variable as EventVariableImpl).setUserServiceField(simField?.userServiceField);
        }
      }

      return new EventVariableImpl(variable);
    }

    case VariableType.EventExpression: {
      return new EventExpressionVariableImpl(variable);
    }

    case VariableType.Constant: {
      return new ConstantVariableImpl(variable as ConstantVariableImpl);
    }

    case VariableType.Custom: {
      return new CustomVariableImpl(variable as CustomVariableImpl);
    }

    case VariableType.Query: {
      return new QueryVariableImpl(variable as QueryVariableImpl);
    }

    default: {
      return new VariableImpl(variable);
    }
  }
};
