import { IncFaIcon, IncPill } from "@inception/ui";
import React, { useMemo } from "react";
import { FilterIcon8, SliceIcon9 } from "../../core/iconwrapper";

type CriteriaPillProps = {
  readonly?: boolean;
  label: string;
  info?: string;
  onLabelClick: () => void;
  onFilterClick?: () => void;
  onSegmentClick?: () => void;
  onVizClick?: () => void;
  onRemove: () => void;
  onCompareClick?: () => void;
};

export const ConfigPill = React.forwardRef<HTMLDivElement, CriteriaPillProps>((props, ref) => {
  const { readonly = false, info, label, onLabelClick, onFilterClick, onSegmentClick, onVizClick, onRemove } = props;

  const actions = useMemo(() => {
    const acts = [];
    onFilterClick &&
      acts.push({
        icon: <FilterIcon8 />,
        onAction: onFilterClick
      });

    onSegmentClick &&
      acts.push({
        icon: <SliceIcon9 />,
        onAction: onSegmentClick
      });

    onVizClick &&
      acts.push({
        icon: <IncFaIcon iconName="line-chart" />,
        onAction: onVizClick
      });

    return acts;
  }, [onFilterClick, onSegmentClick, onVizClick]);

  return (
    <IncPill
      actions={actions}
      label={label}
      onClick={onLabelClick}
      onRemove={onRemove}
      readonly={readonly}
      ref={ref}
      tooltipText={info}
    />
  );
});
