import { IncTextfield } from "@inception/ui";
import React, { useCallback } from "react";
import { InputTextFragment } from "../../services/api/chat";

interface InputTextFragmentProps {
  inputTextFragment: InputTextFragment;
  onUpdateFragment?: (value: InputTextFragment) => void;
}

export const InputFragmentRenderer: React.FC<InputTextFragmentProps> = (props: InputTextFragmentProps) => {
  const { inputTextFragment, onUpdateFragment } = props;
  const { value, editable, label, placeholder } = inputTextFragment;

  const onChange = useCallback(
    e => {
      const { value } = e.target;
      onUpdateFragment({
        ...inputTextFragment,
        value: value
      });
    },
    [inputTextFragment, onUpdateFragment]
  );

  return (
    <IncTextfield
      disabled={!editable}
      label={label}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
    />
  );
};
