import { IncModal, IncRTable, TableDataColumn } from "@inception/ui";
import { css } from "emotion";
import { duration } from "moment";
import React, { FC, useMemo } from "react";
import { OpSimulationState, SimulationStatus } from "../../../services/api/operationalise";

interface Props {
  show: boolean;
  onClose: () => void;
  simulationState: OpSimulationState;
}

type Column = TableDataColumn<SimulationStatus>;

export const OpSimulationStatusRenderer: FC<Props> = props => {
  const { show, onClose, simulationState } = props;

  const { simulationStatus } = simulationState;

  const { currentState, previousStates } = simulationStatus || {};

  const data = useMemo(
    () =>
      [currentState, ...(previousStates || [])].filter(Boolean).map(state => ({
        ...state,
        timeTakenMillis: parseInt(state.timeTakenMillis?.toString(), 10),
        startTimeMillis: parseInt(state.startTimeMillis?.toString(), 10)
      })),
    [currentState, previousStates]
  );

  return (
    <IncModal
      className={modalClassName}
      onClose={onClose}
      show={show}
      size="lg"
      titleText="Simulation Status"
    >
      <IncRTable
        classNames={{ table: tableHeightClassName }}
        columns={columns}
        data={data}
        sort={sortBy}
      />
    </IncModal>
  );
};

const modalClassName = css`
  height: 80vh;
`;
const tableHeightClassName = css`
  max-height: 70vh;
`;

const sortBy = {
  accessor: "startTimeMillis",
  order: "desc"
} as any;

const columns: Column[] = [
  {
    accessor: "stage",
    header: "Stage",
    sortable: true,
    type: "string"
  },
  {
    accessor: "startTimeMillis",
    header: "Started",
    sortable: true,
    type: "datetime"
  },
  {
    accessor: "timeTakenMillis",
    header: "Time taken",
    sortable: true,
    type: "number",
    renderer: (value: number) => duration(value).humanize()
  }
];
