import React, { FC, useMemo, useCallback } from "react";
import {
  CurrencyType,
  IncGroupSelectOption,
  IncSelect,
  IncSelectOption,
  getAllSupportedCurrencies,
  getPopularCurrencies,
  getSymbolForCurrency
} from "@inception/ui";

interface Props {
  currency: string;
  onChange: (currency: string) => void;
  label?: string;
  width?: string;
  alignment?: "row" | "column";
  autoAdjustWidth?: boolean;
}

export const CurrencyPicker: FC<Props> = props => {
  const { onChange, currency, label, width, alignment = "column", autoAdjustWidth = true } = props;

  const currencyGroupByOptions = getOptionForCurrency(allCurrencies, popularCurrencies);

  const selCurrencyOpt = useMemo(
    () => currencyGroupByOptions.flatMap(group => group.options).find(opt => opt.value === currency),
    [currency, currencyGroupByOptions]
  );

  const onOptChange = useCallback(
    (opt: IncSelectOption) => {
      onChange(opt.value);
    },
    [onChange]
  );

  return (
    <IncSelect
      alignment={alignment}
      autoAdjustWidth={autoAdjustWidth}
      label={label}
      onChange={onOptChange}
      options={currencyGroupByOptions}
      value={selCurrencyOpt}
      width={width}
      wrapperClass="currency-picker"
    />
  );
};

const allCurrencies: CurrencyType[] = getAllSupportedCurrencies();
const popularCurrencies: CurrencyType[] = getPopularCurrencies();

const getOptionForCurrency = (
  allCurrencies: CurrencyType[],
  popularCurrencies: CurrencyType[]
): IncGroupSelectOption[] => {
  const options = allCurrencies.map(currency => ({
    label: `${currency} (${getSymbolForCurrency(currency)})`,
    value: currency
  }));

  const groupedOptions: IncGroupSelectOption[] = [
    {
      groupLabel: "Mostly used",
      options: options.filter(option => popularCurrencies.includes(option.value as CurrencyType))
    },
    {
      groupLabel: "Others",
      options: options.filter(option => !popularCurrencies.includes(option.value as CurrencyType))
    }
  ];

  return groupedOptions.filter(group => group.options.length > 0);
};
