import { generateId, IncButton, IncCheckbox, IncFaIcon, IncPill, IncTextfield } from "@inception/ui";
import { cx } from "emotion";
import { size } from "lodash";
import React, { FC, useState, useMemo, useCallback, useEffect } from "react";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../../components";
import { useToggleState } from "../../../../core";
import { FieldPickerModel } from "../../../../field-picker";
import { ImpactedWidget, UserServiceFieldSlice, UserServiceFieldWithMeta } from "../../../../services/api/explore";
import { OpTriageConfig } from "../../../../services/api/operationalise";
import { FieldPickerUtils } from "../../../../utils";
import { CustomSelectWrapper } from "../common";
import { AddImpactedWidgetModal } from "./AddImpactedWidgetModal";

interface Props {
  fieldPickerModel: FieldPickerModel;
  fieldPickerError: string;
  isLoading: boolean;

  impactedWidgets: OpTriageConfig["impactedWidgets"];
  impactedFields: OpTriageConfig["impactFields"];

  onSave: (impactedWidgets: OpTriageConfig["impactedWidgets"], impactedFields: OpTriageConfig["impactFields"]) => void;
}

type State = {
  impactedWidgets: OpTriageConfig["impactedWidgets"];
  impactedFields: OpTriageConfig["impactFields"];
};

export const ImpactFields: FC<Props> = props => {
  const {
    fieldPickerError,
    fieldPickerModel,
    impactedFields: pImpactedFields,
    impactedWidgets: pImpactedWidgets,
    isLoading,
    onSave
  } = props;

  const uniqId = useMemo(() => `impact-fields-${generateId()}`, []);

  const [state, setState] = useState<State>({
    impactedFields: pImpactedFields,
    impactedWidgets: pImpactedWidgets
  });

  const { impactedFields, impactedWidgets } = state;

  const [searchText, setSearchText] = useState("");

  const onSearchTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    setSearchText(searchText);
  }, []);

  const [impactWidgetToAddOrEdit, setImpactWidgetToAddOrEdit] = useState<ImpactedWidget>();

  const { close: closeMetricModal, isOpen: isMetricModalOpen, open: openMetricModal } = useToggleState();

  const onApplyChanges = useCallback(
    (nextState?: Partial<State>) => {
      const { impactedFields, impactedWidgets } = {
        ...state,
        ...(nextState || {})
      };
      onSave(impactedWidgets, impactedFields);
    },
    [onSave, state]
  );

  const resetState = useCallback(() => {
    setState({
      impactedFields: pImpactedFields,
      impactedWidgets: pImpactedWidgets
    });
  }, [pImpactedFields, pImpactedWidgets]);

  useEffect(() => {
    resetState();
  }, [resetState]);

  const impactedFieldsSelectionMap = useMemo(() => {
    const impactedFieldsSelectionMap = (impactedFields.slices || []).reduce(
      (acc, curr) => {
        acc[curr.tagName] = true;
        return acc;
      },
      {} as Record<string, boolean>
    );

    return impactedFieldsSelectionMap;
  }, [impactedFields]);

  const usFieldsMap = useMemo(() => {
    const fieldsMap: Map<string, UserServiceFieldWithMeta> = new Map();

    const group = (fieldPickerModel?.groupFieldsByUserService() || [])[0];

    if (group) {
      const { usEntityFields, usFields } = group;

      const addFieldsToMap = (usFields: UserServiceFieldWithMeta[]) => {
        usFields
          .sort((fieldA, fieldB) => {
            const a = FieldPickerUtils.getUserServiceFieldLabel(fieldA.userServiceField);
            const b = FieldPickerUtils.getUserServiceFieldLabel(fieldB.userServiceField);

            return a.toLowerCase().localeCompare(b.toLocaleLowerCase());
          })
          .forEach(usField => {
            const id = FieldPickerUtils.getPromSanitizedUSFName(usField.userServiceField);
            fieldsMap.set(id, usField);
          });
      };

      addFieldsToMap(usFields || []);
      addFieldsToMap(usEntityFields || []);
    }

    return fieldsMap;
  }, [fieldPickerModel]);

  const onTogglePrimaryImpactedWidget = useCallback((impactedWidgetId: string) => {
    setState(prevState => {
      const nextImpactedWidgets = prevState.impactedWidgets.impactedWidgets.map(iw => ({
        ...iw,
        isPrimary: iw.id === impactedWidgetId ? !iw.isPrimary : iw.isPrimary
      }));

      return {
        ...prevState,
        impactedWidgets: {
          impactedWidgets: nextImpactedWidgets
        }
      };
    });
  }, []);

  const onRemoveImpactedWidget = useCallback(
    (impactedWidgetIdx: number, saveChanges = false) => {
      const nextImpactedWidgets: State["impactedWidgets"] = {
        impactedWidgets: [...state.impactedWidgets.impactedWidgets]
      };

      nextImpactedWidgets.impactedWidgets.splice(impactedWidgetIdx, 1);

      const nextState: State = {
        ...state,
        impactedWidgets: nextImpactedWidgets
      };

      if (saveChanges) {
        onApplyChanges(nextState);
      } else {
        setState(nextState);
      }
    },
    [onApplyChanges, state]
  );

  const onAddImpactField = useCallback((slice: UserServiceFieldSlice) => {
    setState(prevState => ({
      ...prevState,
      impactedFields: {
        slices: [...prevState.impactedFields.slices, slice]
      }
    }));
  }, []);

  const onRemoveImpactField = useCallback(
    (tagName: string, saveChanges = false) => {
      const nextSlices = state.impactedFields.slices.filter(sl => sl.tagName !== tagName);
      const nextState: State = {
        ...state,
        impactedFields: {
          slices: nextSlices
        }
      };

      if (saveChanges) {
        onApplyChanges(nextState);
      } else {
        setState(nextState);
      }
    },
    [onApplyChanges, state]
  );

  const addImpactedWidget = useCallback(() => {
    setImpactWidgetToAddOrEdit({
      id: generateId(),
      name: "New Impacted Widget",
      bizDataQuery: null,
      dataType: "_str",
      isPrimary: false,
      subType: "none",
      underlyingFilters: null,
      underlyingUserServices: []
    });
    openMetricModal();
  }, [openMetricModal]);

  const onSaveImpactedWidget = useCallback((impactedWidget: ImpactedWidget) => {
    setState(prevState => {
      const existingImpactedWidgets = prevState.impactedWidgets.impactedWidgets;
      const nextImpactedWidgets = [...existingImpactedWidgets, impactedWidget];

      return {
        ...prevState,
        impactedWidgets: {
          impactedWidgets: nextImpactedWidgets
        }
      };
    });
  }, []);

  const renderImpactFields = useMemo(() => {
    if (isLoading) {
      return <LoadingSpinner className="inc-label-common" />;
    }

    const pillsJsx: JSX.Element[] = [];

    const impactedWidgetsArr = pImpactedWidgets?.impactedWidgets || [];
    impactedWidgetsArr.forEach((impactedWidget, idx) => {
      const { id, name } = impactedWidget;

      const key = [uniqId, "iw-pill", id, idx].join("_");
      const onRemove = () => onRemoveImpactedWidget(idx, true);

      pillsJsx.push(
        <IncPill
          className="marginRt4"
          key={key}
          label={name}
          onRemove={onRemove}
          tooltipText={name}
        />
      );
    });

    const impactFieldsArr = pImpactedFields?.slices || [];
    impactFieldsArr.forEach(usSlice => {
      const { tagName, userServiceField } = usSlice;
      const label = FieldPickerUtils.getUserServiceFieldLabel(userServiceField);

      const key = [uniqId, "if-pill", tagName].join("_");
      const onRemove = () => onRemoveImpactField(tagName, true);

      pillsJsx.push(
        <IncPill
          className="marginRt4"
          key={key}
          label={label}
          onRemove={onRemove}
          tooltipText={label}
        />
      );
    });

    return (
      <VerticallyCenteredRow className="marginRtAuto values-wrapper">
        {Boolean(pillsJsx.length) && pillsJsx}
        {!pillsJsx.length && (
          <VerticallyCenteredRow className="inc-text-element-medium">
            Select impact fields or metrics
          </VerticallyCenteredRow>
        )}
      </VerticallyCenteredRow>
    );
  }, [isLoading, onRemoveImpactField, onRemoveImpactedWidget, pImpactedFields, pImpactedWidgets, uniqId]);

  const metricsJsx = useMemo(() => {
    const impactedWidgetsArr = impactedWidgets.impactedWidgets || [];
    const numImpactedWidgets = impactedWidgetsArr.length;

    const impactedWidgetsJsx = impactedWidgetsArr.map((impactedWidget, idx) => {
      const { name, isPrimary, defaultGenerated, id } = impactedWidget;

      const className = idx !== numImpactedWidgets - 1 ? "impact-metric-name marginBt12" : "impact-metric-name";

      const togglePrimary = () => onTogglePrimaryImpactedWidget(id);
      const removeImpactedWidget = () => onRemoveImpactedWidget(idx);

      const key = [uniqId, id, idx].join("_");
      return (
        <VerticallyCenteredRow
          className={className}
          key={key}
        >
          <VerticallyCenteredRow className="name">{name}</VerticallyCenteredRow>
          <IncFaIcon
            className="status-info marginLt8 inc-cursor-pointer"
            iconName="star"
            onClick={togglePrimary}
            regular={!isPrimary}
            title="Primary impact metric"
          />
          {!defaultGenerated && (
            <IncFaIcon
              className="status-danger marginLt8 inc-cursor-pointer"
              iconName="minus-circle"
              onClick={removeImpactedWidget}
              title="Remove metric"
            />
          )}
        </VerticallyCenteredRow>
      );
    });

    return (
      <>
        <VerticallyCenteredRow className="inc-text-subtext marginBt12">
          Custom metrics
          <IncButton
            className="marginLt12"
            color="link"
            iconType="iconText"
            onClick={addImpactedWidget}
            size="small"
          >
            <IncFaIcon iconName="add" />
            Add
          </IncButton>
        </VerticallyCenteredRow>

        <div className="marginBt24 marginLt16">
          {!numImpactedWidgets && (
            <VerticallyCenteredRow className="inc-label-common">No custom metrics found</VerticallyCenteredRow>
          )}
          {Boolean(numImpactedWidgets) && impactedWidgetsJsx}
        </div>
      </>
    );
  }, [
    addImpactedWidget,
    impactedWidgets.impactedWidgets,
    onRemoveImpactedWidget,
    onTogglePrimaryImpactedWidget,
    uniqId
  ]);

  const fieldsJsx = useMemo(() => {
    const fieldsArr: JSX.Element[] = [];
    const numFields = usFieldsMap.size;

    const filteredFieldNames = Array.from(usFieldsMap.keys()).filter(tagName => {
      const usfWithMeta = usFieldsMap.get(tagName);
      const label = FieldPickerUtils.getUserServiceFieldLabel(usfWithMeta.userServiceField) || "";

      const effSearchText = searchText.trim().toLowerCase();
      return label.toLowerCase().includes(effSearchText);
    });

    const numSelected = size(impactedFieldsSelectionMap);
    const numFiltered = filteredFieldNames.length;

    filteredFieldNames.forEach(tagName => {
      const usfWithMeta = usFieldsMap.get(tagName);

      const onToggle = (e: any, checked: boolean) => {
        if (checked) {
          onAddImpactField({
            tagName,
            userServiceField: usfWithMeta.userServiceField
          });
        } else {
          onRemoveImpactField(tagName);
        }
      };

      const key = [uniqId, "field", tagName].join("_");
      const label = FieldPickerUtils.getUserServiceFieldLabel(usfWithMeta.userServiceField);
      const selected = impactedFieldsSelectionMap[tagName] || false;

      fieldsArr.push(
        <VerticallyCenteredRow
          className="inc-text-subtext-medium marginBt12"
          key={key}
        >
          <IncCheckbox
            checked={selected}
            onChange={onToggle}
          />
          {label}
        </VerticallyCenteredRow>
      );
    });

    return (
      <>
        <VerticallyCenteredRow className="marginBt12 inc-text-subtext">
          Impact Fields
          <div className="inc-text-element-medium inc-text-inactive marginLt6">
            ( {numFiltered} / {numFields} )
          </div>
          <div className="status-info inc-text-element-medium marginLt6">{numSelected} selected</div>
        </VerticallyCenteredRow>

        <div className="marginLt16">
          {fieldPickerError && (
            <VerticallyCenteredRow className="inc-text-subtext status-danger">{fieldPickerError}</VerticallyCenteredRow>
          )}
          {!fieldPickerError && (
            <>
              {Boolean(numFields) && fieldsArr}
              {!numFields && <div className="inc-label-common">No fields found</div>}
            </>
          )}
        </div>
      </>
    );
  }, [
    onAddImpactField,
    fieldPickerError,
    impactedFieldsSelectionMap,
    onRemoveImpactField,
    searchText,
    uniqId,
    usFieldsMap
  ]);

  const getImpactFieldsChildren = useCallback(
    (open: () => void, close: () => void) => {
      const onApply = () => {
        onApplyChanges();
        close();
      };

      const onCancel = () => {
        resetState();
        close();
      };

      return (
        <div className="inc-card-layout display-block impact-fields-editor--popper">
          <div className="header">
            <IncTextfield
              onChange={onSearchTextChange}
              placeholder="Search"
              startIcon="search"
              value={searchText}
            />
          </div>

          <div className="content">
            {metricsJsx}
            {fieldsJsx}
          </div>

          <VerticallyCenteredRow className="footer">
            <IncButton
              className="marginRt12"
              color="secondary-blue"
              onClick={onApply}
              size="small"
            >
              Apply
            </IncButton>

            <IncButton
              color="secondary-red"
              onClick={onCancel}
              size="small"
            >
              Cancel
            </IncButton>
          </VerticallyCenteredRow>
        </div>
      );
    },
    [fieldsJsx, metricsJsx, onApplyChanges, onSearchTextChange, resetState, searchText]
  );

  const wrapperClassName = cx("impact-fields-editor width-50", {
    disableClick: isLoading
  });

  return (
    <>
      <CustomSelectWrapper
        className={wrapperClassName}
        label="Impact indicators"
        placeholderText="Select impact indicators"
        placement="bottom-start"
        valueLabel={renderImpactFields}
      >
        {getImpactFieldsChildren}
      </CustomSelectWrapper>

      {isMetricModalOpen && Boolean(impactWidgetToAddOrEdit) && (
        <AddImpactedWidgetModal
          impactedWidget={impactWidgetToAddOrEdit}
          onChange={onSaveImpactedWidget}
          onClose={closeMetricModal}
          open
        />
      )}
    </>
  );
};
