import React from "react";
import { CompareOperator, OpInsightType } from "../../services/api/operationalise";

const getSpikeWithBands = (strokeColor: string) => (
  <svg
    fill="none"
    height="134"
    viewBox="0 0 197 134"
    width="180"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M196.44 0L197 74.689L134 102.111L77.766 104.513L52.089 134L1 116.536V76.5012L50.5 84.3475L77.766 48.1441L134 64.5185L196.44 0Z"
      fill="#39ACFF"
      fillOpacity="0.16"
      fillRule="evenodd"
    />
    <path
      d="M1 107.906L49 85.7502L77.3995 21.8525L132.5 64.8354L196.297 45.0938"
      fill="none"
      stroke="#39ACFF"
      strokeWidth="4"
    />
    <path
      d="M49 85.7515L77.4305 21.7422L132.5 64.8367"
      fill="none"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeWidth="4"
    />
  </svg>
);

const getDropWithBands = (strokeColor: string) => (
  <svg
    fill="none"
    height="138"
    viewBox="0 0 197 138"
    width="180"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M196.44 40.9446L197 95.5373L140 78.7264L95.766 42.9563L49.089 63.5866L1 65.3947V14.7262L47.5 26.8271L95.766 0L140 31.1489L196.44 40.9446Z"
      fill="#39ACFF"
      fillOpacity="0.16"
      fillRule="evenodd"
    />
    <path
      d="M1 38.7725L49 57.8411L91.3995 134.001L138 78.0792L196.297 56.6354"
      fill="none"
      stroke="#39ACFF"
      strokeWidth="4"
    />
    <path
      d="M49.5 58.2725L91.5 133.999L138 78.1668"
      fill="none"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeWidth="4"
    />
  </svg>
);

const getTrendUp = (strokeColor: string) => (
  <svg
    fill="none"
    height="135"
    viewBox="0 0 200 135"
    width="180"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 132.895L46 73.5034H98.5L138 22.711L197.297 2.8418"
      fill="none"
      stroke={strokeColor}
      strokeWidth="4"
    />
    <ellipse
      cx="46"
      cy="73.3016"
      fill={strokeColor}
      rx="3"
      ry="3.02624"
    />
    <ellipse
      cx="3"
      cy="130.974"
      fill={strokeColor}
      rx="3"
      ry="3.02624"
    />
    <circle
      cx="99"
      cy="73.2754"
      fill={strokeColor}
      r="3"
    />
    <ellipse
      cx="138"
      cy="23.5223"
      fill={strokeColor}
      rx="3"
      ry="3.02624"
    />
    <ellipse
      cx="197"
      cy="3.02624"
      fill={strokeColor}
      rx="3"
      ry="3.02624"
    />
  </svg>
);

const getTrendDown = (strokeColor: string) => (
  <svg
    fill="none"
    height="135"
    viewBox="0 0 200 135"
    width="180"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2.10623L46 61.4977H98.5L138 112.29L197.297 132.159"
      fill="none"
      stroke={strokeColor}
      strokeWidth="4"
    />
    <circle
      cx="3"
      cy="3"
      fill={strokeColor}
      r="2.5"
      stroke={strokeColor}
      transform="matrix(1 0 0 -1 43 64.7256)"
    />
    <path
      d="M5.5 4.02649C5.5 2.62717 4.37661 1.50024 3 1.50024C1.62339 1.50024 0.5 2.62717 0.5 4.02649C0.5 5.42581 1.62339 6.55273 3 6.55273C4.37661 6.55273 5.5 5.42581 5.5 4.02649Z"
      fill={strokeColor}
      stroke={strokeColor}
    />
    <circle
      cx="3"
      cy="3"
      fill={strokeColor}
      r="2.5"
      stroke={strokeColor}
      transform="matrix(1 0 0 -1 96 65.7256)"
    />
    <circle
      cx="3"
      cy="3"
      fill={strokeColor}
      r="2.5"
      stroke={strokeColor}
      transform="matrix(1 0 0 -1 135 114.506)"
    />
    <path
      d="M199.5 131.974C199.5 130.574 198.377 129.448 197 129.448C195.623 129.448 194.5 130.574 194.5 131.974C194.5 133.373 195.623 134.5 197 134.5C198.377 134.5 199.5 133.373 199.5 131.974Z"
      fill={strokeColor}
      stroke={strokeColor}
    />
  </svg>
);

const getJourney = (fill: string) => (
  <svg
    height="135"
    version="1.1"
    viewBox="0 0 135 135"
    width="135"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="surface1">
      <path
        d="M 122.960938 13.578125 L 12.039062 13.578125 C 8.347656 13.578125 6.042969 17.601562 7.894531 20.8125 L 42.941406 80.386719 L 42.941406 116.636719 C 42.941406 119.304688 45.082031 121.457031 47.734375 121.457031 L 87.269531 121.457031 C 89.921875 121.457031 92.058594 119.304688 92.058594 116.636719 L 92.058594 80.386719 L 127.121094 20.8125 C 128.957031 17.601562 126.652344 13.578125 122.960938 13.578125 Z M 81.273438 110.609375 L 53.730469 110.609375 L 53.730469 87.105469 L 81.285156 87.105469 L 81.285156 110.609375 Z M 82.71875 74.960938 L 81.285156 77.464844 L 53.714844 77.464844 L 52.28125 74.960938 L 22.40625 24.425781 L 112.597656 24.425781 Z M 82.71875 74.960938"
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill,
          fillOpacity: 1
        }}
      />
    </g>
  </svg>
);

const ANOMALY_COLOR = "#FF523B";
const OPPORTUNITY_COLOR = "#3BB443";
const JOURNEY_COLOR = "rgb(22.352941%,67.45098%,100%)";

const ANOMALY_SPIKE_PREVIEW = getSpikeWithBands(ANOMALY_COLOR);
const ANOMALY_DROP_PREVIEW = getDropWithBands(ANOMALY_COLOR);
const ANOMALY_TREND_UP_PREVIEW = getTrendUp(ANOMALY_COLOR);
const ANOMALY_TREND_DOWN_PREVIEW = getTrendDown(ANOMALY_COLOR);

const OPPORTUNITY_SPIKE_PREVIEW = getSpikeWithBands(OPPORTUNITY_COLOR);
const OPPORTUNITY_DROP_PREVIEW = getDropWithBands(OPPORTUNITY_COLOR);
const OPPORTUNITY_TREND_UP_PREVIEW = getTrendUp(OPPORTUNITY_COLOR);
const OPPORTUNITY_TREND_DOWN_PREVIEW = getTrendDown(OPPORTUNITY_COLOR);
const JOURNEY_PREVIEW = getJourney(JOURNEY_COLOR);

export const getOp10zePreviewSVG = (
  insightType: OpInsightType,
  isOpportunity: boolean,
  spikeDropCompareOperator: CompareOperator
) => {
  if (insightType === OpInsightType.JOURNEY_ANALYSIS) {
    return JOURNEY_PREVIEW;
  }

  const isTrendType = insightType === OpInsightType.TREND_DETECTION;
  const isSpike = spikeDropCompareOperator !== CompareOperator.Below;
  const isSpikePositive =
    (isOpportunity && spikeDropCompareOperator === CompareOperator.Above) ||
    (!isOpportunity && spikeDropCompareOperator === CompareOperator.Below);

  if (isSpike) {
    if (isSpikePositive) {
      return isTrendType ? OPPORTUNITY_TREND_UP_PREVIEW : OPPORTUNITY_SPIKE_PREVIEW;
    } else {
      return isTrendType ? ANOMALY_TREND_UP_PREVIEW : ANOMALY_SPIKE_PREVIEW;
    }
  } else {
    if (isSpikePositive) {
      return isTrendType ? ANOMALY_TREND_DOWN_PREVIEW : ANOMALY_DROP_PREVIEW;
    } else {
      return isTrendType ? OPPORTUNITY_TREND_DOWN_PREVIEW : OPPORTUNITY_DROP_PREVIEW;
    }
  }
};

export const getStatusClassNameForSingleStat = (isSpike: boolean, isSpikePositive: boolean) => {
  if (isSpike) {
    return isSpikePositive ? "status-success" : "status-danger";
  }

  return isSpikePositive ? "status-danger" : "status-success";
};
