import styled from "@emotion/styled";
import { IncCheckbox, IncFaIcon, IncSmartText, IncTextfield } from "@inception/ui";
import { groupBy } from "lodash";
import React, { FC, useState, useMemo, useCallback, memo, Dispatch, SetStateAction } from "react";
import { MY_USE_CASE_ID, useInputState, useToggleState, VerticalUseCaseSummary } from "../../core";
import { VerticallyCenteredRow } from "../flex-components";

export type SimpleUseCase = Pick<VerticalUseCaseSummary, "id" | "name" | "uiVerticalInfo" | "color" | "icon">;

interface Props {
  useCases: SimpleUseCase[];
  memberUseCases?: SimpleUseCase[];
  selectedUseCases?: string[];
  setSelectedUseCases: Dispatch<SetStateAction<string[]>>;

  isMulti?: boolean;
  allowGrouping?: boolean;
}

type GroupBy = "verticalId" | "subVerticalId";

type UseCaseGroup = {
  groupLabel: string;
  groups: UseCaseGroup[];
  useCases: SimpleUseCase[];
};

export const AddToUseCaseContent: FC<Props> = props => {
  const { allowGrouping = true, useCases, memberUseCases, selectedUseCases, setSelectedUseCases, isMulti } = props;

  const memberUseCaseIds = useMemo(() => {
    const memberUseCaseIds = memberUseCases?.map(useCase => useCase.id) || [];
    memberUseCaseIds.push(MY_USE_CASE_ID);
    return memberUseCaseIds;
  }, [memberUseCases]);

  const visibleUseCases = useMemo(
    () => useCases.filter(useCase => !memberUseCaseIds.includes(useCase.id)),
    [memberUseCaseIds, useCases]
  );

  const [groupCriteria, setGroupCriteria] = useState<GroupBy[]>([]);

  const {
    inputValue: searchText,
    debounceInputValue: filterText,
    onInputChange: onSearchTextChange
  } = useInputState("", 500);

  const { allUseCaseGroups, memberUseCaseGroups } = useMemo(() => {
    const allUseCaseGroups = constructGroups(visibleUseCases, groupCriteria, "All Copilots");
    const memberUseCaseGroups = constructGroups(memberUseCases || [], groupCriteria, "My Copilots");

    return {
      allUseCaseGroups,
      memberUseCaseGroups
    };
  }, [groupCriteria, memberUseCases, visibleUseCases]);

  const getJSXForGroup = useCallback(
    (useCaseGroups: UseCaseGroup[]) =>
      useCaseGroups.map(g => (
        <GroupRenderer
          filterText={filterText}
          group={g}
          isMulti={isMulti}
          key={g.groupLabel}
          level={1}
          onSelectionChange={setSelectedUseCases}
          selectedUseCaseIds={selectedUseCases}
        />
      )),
    [filterText, isMulti, selectedUseCases, setSelectedUseCases]
  );

  const allUseCaseGroupsJsx = useMemo(() => getJSXForGroup(allUseCaseGroups), [allUseCaseGroups, getJSXForGroup]);
  const memberUseCaseGroupsJsx = useMemo(
    () => getJSXForGroup(memberUseCaseGroups),
    [getJSXForGroup, memberUseCaseGroups]
  );

  const onVerticalGroupChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setGroupCriteria(prev => (checked ? [...prev, "verticalId"] : prev.filter(c => c !== "verticalId")));
  }, []);

  const onSubVerticalGroupChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setGroupCriteria(prev => (checked ? [...prev, "subVerticalId"] : prev.filter(c => c !== "subVerticalId")));
  }, []);

  return (
    <div className="padding16 inc-flex-column width-100 flex-gap-24">
      <VerticallyCenteredRow className="flex-gap-20">
        <IncTextfield
          containerClassName="inc-flex-grow"
          onChange={onSearchTextChange}
          placeholder="Search"
          startIcon="search"
          value={searchText}
        />

        {allowGrouping && (
          <>
            {Boolean(0) && (
              <IncCheckbox
                checked={groupCriteria.includes("verticalId")}
                label="Group by Vertical"
                labelProps={{ placement: "end" }}
                onChange={onVerticalGroupChange}
              />
            )}

            <IncCheckbox
              checked={groupCriteria.includes("subVerticalId")}
              label="Group by Team"
              labelProps={{ placement: "end" }}
              onChange={onSubVerticalGroupChange}
            />
          </>
        )}
      </VerticallyCenteredRow>

      {memberUseCaseGroupsJsx}
      {allUseCaseGroupsJsx}
    </div>
  );
};

type GroupProps = {
  group: UseCaseGroup;
  level: number;

  selectedUseCaseIds: string[];
  onSelectionChange: React.Dispatch<React.SetStateAction<string[]>>;
  isMulti?: boolean;
  filterText?: string;
};

const GroupRenderer = memo<GroupProps>(props => {
  const { group, level, onSelectionChange, selectedUseCaseIds, isMulti, filterText } = props;

  const { isOpen: isExpanded, toggle: toggleExpanded } = useToggleState(true);

  const { groupLabel, groups, useCases } = group;

  const { groupsChildren, useCasesChildren } = useMemo(() => {
    let groupsChildren: JSX.Element[] = [];
    let useCasesChildren: JSX.Element[] = [];

    if (groups.length) {
      groupsChildren = groups.map(g => (
        <GroupRenderer
          filterText={filterText}
          group={g}
          isMulti={isMulti}
          key={g.groupLabel}
          level={level + 1}
          onSelectionChange={onSelectionChange}
          selectedUseCaseIds={selectedUseCaseIds}
        />
      ));
    } else {
      useCasesChildren = useCases.map(u => (
        <UseCaseCard
          filterText={filterText}
          isMulti={isMulti}
          key={u.id}
          onSelectionChange={onSelectionChange}
          selectedUseCaseIds={selectedUseCaseIds}
          useCase={u}
        />
      ));
    }

    return {
      groupsChildren,
      useCasesChildren
    };
  }, [filterText, groups, isMulti, level, onSelectionChange, selectedUseCaseIds, useCases]);

  return (
    <div className="inc-flex-column flex-gap-12 width-100">
      <VerticallyCenteredRow
        className="inc-text-subtext-medium inc-cursor-pointer flex-gap-12"
        onClick={toggleExpanded}
      >
        <IncFaIcon
          className="collapse-arrow"
          data-expanded={isExpanded}
          iconName="circle-chevron-down"
        />
        <span>{groupLabel}</span>
      </VerticallyCenteredRow>
      {isExpanded && (
        <>
          {Boolean(groupsChildren.length) && (
            <div
              className="inc-flex-column flex-gap-12"
              style={{ marginLeft: `${level * 24}px` }}
            >
              {groupsChildren}
            </div>
          )}
          {Boolean(useCasesChildren.length) && (
            <div
              className="inc-grid inc-grid--grid-3 flex-gap-12"
              style={{ marginLeft: `${level * 24}px` }}
            >
              {useCasesChildren}
            </div>
          )}
        </>
      )}
    </div>
  );
});

type UCProps = {
  useCase: SimpleUseCase;

  selectedUseCaseIds: string[];
  onSelectionChange: React.Dispatch<React.SetStateAction<string[]>>;

  isMulti?: boolean;
  filterText?: string;
};

const UseCaseCard = memo<UCProps>(props => {
  const { useCase, selectedUseCaseIds, onSelectionChange, isMulti, filterText } = props;

  const { id: useCaseId, name: useCaseName, icon, color } = useCase;

  const isSelected = selectedUseCaseIds.includes(useCaseId);

  const onClick = useCallback(() => {
    if (isMulti) {
      onSelectionChange(prev => {
        if (prev.includes(useCaseId)) {
          return prev.filter(id => id !== useCaseId);
        } else {
          return [...prev, useCaseId];
        }
      });
    } else {
      onSelectionChange([useCaseId]);
    }
  }, [isMulti, onSelectionChange, useCaseId]);

  if (filterText && !useCaseName.toLowerCase().includes(filterText.toLowerCase())) {
    return null;
  }

  return (
    <UseCardCardDiv
      className="inc-flex-row inc-flex-center-vertical flex-gap-8 inc-cursor-pointer"
      data-selected={isSelected}
      onClick={onClick}
    >
      {Boolean(icon) && (
        <IncFaIcon
          iconName={icon}
          style={{ color: color || "inherit" }}
        />
      )}
      <IncSmartText
        className="inc-text-subtext-medium width-85"
        text={useCaseName}
      />

      {isSelected && (
        <IncFaIcon
          className="check-icon"
          iconName="check"
        />
      )}
    </UseCardCardDiv>
  );
});

const UseCardCardDiv = styled.div`
  position: relative;
  padding: 12px 14px;
  border-radius: 8px;

  background: #2a343e;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.3);

  .check-icon {
    position: absolute;
    top: 0;
    right: 0;

    background: #39acff;
    padding: 4px;
    border-radius: 2px;
    font-size: 10px;
  }

  &[data-selected="true"] {
    background: linear-gradient(0deg, rgba(57, 172, 255, 0.16) 0%, rgba(57, 172, 255, 0.16) 100%), #15202b;
  }
`;

const constructGroups = (useCases: SimpleUseCase[], groupCriteria: GroupBy[], groupLabel: string): UseCaseGroup[] => {
  if (!groupCriteria.length) {
    return [
      {
        groupLabel,
        groups: [],
        useCases: useCases.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))
      }
    ];
  } else {
    const groups: UseCaseGroup[] = [];

    const groupKey1 = groupCriteria.includes("verticalId") ? "verticalId" : "subVerticalId";
    const groupKey2 = !groupCriteria.includes("subVerticalId") ? "" : groupKey1 === "subVerticalId" ? "" : "";

    const initialGroup = groupBy(useCases, groupKey1);
    Object.keys(initialGroup).forEach(groupKeyL1 => {
      const L1UseCases = initialGroup[groupKeyL1];

      if (groupKey2) {
        const subGroupEntry: UseCaseGroup = {
          groupLabel: groupKeyL1,
          groups: [],
          useCases: []
        };

        const subGroup = groupBy(L1UseCases, groupKey2);
        Object.keys(subGroup).forEach(groupKeyL2 => {
          const L2UseCases = subGroup[groupKeyL2];
          subGroupEntry.groups.push({
            groupLabel: groupKeyL2,
            groups: [],
            useCases: L2UseCases.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))
          });
        });

        groups.push(subGroupEntry);
      } else {
        groups.push({
          groupLabel: groupKeyL1,
          groups: [],
          useCases: L1UseCases.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))
        });
      }
    });

    return [
      {
        groupLabel,
        groups: groups.sort((a, b) => a.groupLabel.toLocaleLowerCase().localeCompare(b.groupLabel.toLocaleLowerCase())),
        useCases: []
      }
    ];
  }
};
