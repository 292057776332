import React, { useCallback, useMemo } from "react";
import { IncCollapsible } from "@inception/ui";
import { WhatsNewConfig } from "../../../services/api/operationalise";
import { setWhatsNewConfig, useOpStore } from "../../context";
import { getDefaultWhatsNewConfig } from "../../utils";
import WhatsNewConfigEditor from "../../uber-operationalize/WhatsNewConfigEditor";
import { ENTITY_TAG } from "../../../utils";

type Props = {
  label?: string;
  openDefault?: boolean;
};

const WhatsNewEditorWrapper: React.FC<Props> = props => {
  const { openDefault = false, label = "Advanced" } = props;
  const { state, dispatch } = useOpStore();
  const { opCreationConfig, context } = state;
  const { opCreationConfigDef } = opCreationConfig || {};
  const { whatsNewConfig: dWhatsNewConfig, bizDataQuery } = opCreationConfigDef || {};

  const { sliceSpec } = bizDataQuery || {};

  const { sliceSet } = sliceSpec || {};
  const { slices = [] } = sliceSet || {};
  const { entityTypeName, eventTypeName } = context || {};

  const whatsNewConfig = useMemo(() => dWhatsNewConfig || getDefaultWhatsNewConfig(), [dWhatsNewConfig]);

  const onWhatsNewConfigChange = useCallback(
    (whatsNewConfig: WhatsNewConfig) => {
      dispatch(setWhatsNewConfig(whatsNewConfig));
    },
    [dispatch]
  );

  const labelText = useMemo<string>(() => {
    const entityArr: string[] = [];
    slices.forEach(slice => {
      if (slice.tagName === ENTITY_TAG) {
        entityArr.push(entityTypeName || eventTypeName);
      } else {
        entityArr.push(slice.tagName);
      }
    });
    return `Track if new combination of ${entityArr.join(", ")} is discovered`;
  }, [entityTypeName, eventTypeName, slices]);

  return (
    <IncCollapsible
      className="op-advanced-options-editor"
      content={
        <WhatsNewConfigEditor
          durationSelectorClassName="op-duration-selector"
          label={labelText}
          onChange={onWhatsNewConfigChange}
          showDurationPrefix
          whatsNewConfig={whatsNewConfig}
        />
      }
      header={label}
      headerClassName="inc-text-subtext-medium status-info op-advanced-options-editor-header"
      iconPosition="right"
      show={openDefault}
    />
  );
};

export default WhatsNewEditorWrapper;
