import React, { FC, memo } from "react";
import {
  SelectorSpec,
  UserServiceFilterExpression,
  UserServiceFilterList,
  WidgetResponseDTO
} from "../../../../services/api/explore";
import { FetchResultMetaProps } from "../hooks";
import { WidgetDataDefinitionFilter } from "./WidgetDataDefinitionFilter";

interface Props {
  widgetResponseDTO: WidgetResponseDTO;
  metricUserServiceFilters: Record<string, UserServiceFilterList>;
  filterMessage: string;
  filtersExist: boolean;
  onFilterChange: (filters: UserServiceFilterExpression[]) => void;
  className?: string;
  iconClassName?: string;
  skipTooltip?: boolean;
  widgetSelectorSpec: SelectorSpec;
  setWidgetSelectorSpec: (selectorSpec: SelectorSpec) => void;
  fetchResultMetaProps: FetchResultMetaProps;
}
export const WidgetFilters: FC<Props> = memo(props => {
  const { widgetResponseDTO, metricUserServiceFilters, widgetSelectorSpec, ...dataDefProps } = props;
  return (
    <WidgetDataDefinitionFilter
      {...dataDefProps}
      metricUserServiceFilters={metricUserServiceFilters}
      widgetResponseDTO={widgetResponseDTO}
      widgetSelectorSpec={widgetSelectorSpec}
    />
  );
});
