import React, { useCallback, useMemo } from "react";
import { useForceUpdate } from "../../../../../core";
import { WidgetCustomAction } from "../../../types";
import { DSIntervalEditor } from "../../components";
import { USFWProps } from "../../types";

type Props = {
  widget: USFWProps["widget"];
  anchorRef: React.RefObject<HTMLDivElement>;
};

export const useDownsampleAction = (props: Props) => {
  const { widget, anchorRef } = props;
  const { properties } = widget;

  const forceUpdate = useForceUpdate();

  const updateDsInterval = useCallback(
    (dsIntervalStr: string) => {
      widget.properties = {
        ...widget.properties,
        downsample: dsIntervalStr
      };
      forceUpdate();
    },
    [forceUpdate, widget]
  );

  const dsIntervalEditAction = useMemo<WidgetCustomAction>(
    () => ({
      actionComponent: (
        <DSIntervalEditor
          anchorRef={anchorRef}
          dsIntervalStr={properties.downsample}
          onChange={updateDsInterval}
        />
      )
    }),
    [anchorRef, properties.downsample, updateDsInterval]
  );

  return dsIntervalEditAction;
};
