import React, { FC, useCallback } from "react";
import { ISaxIcon, IncPopConfirm, IncSmartText, IncToolTip } from "@inception/ui";
import { VerticallyCenteredRow } from "../../components";
import { OpBizDataQuery } from "../../services/api/operationalise";
import { useToggleState } from "../../core";
import { BizDataQuery } from "../../services/api/explore";
import DiagnosticKpiEditorPopper from "./DiagnosticKpiEditorPopper";
import { diagnosticKpiChildClass } from "./context";

type Props = {
  kpi: OpBizDataQuery;
  onClone: () => void;
  onDelete: () => void;
  onChangeQuery: (bizDataQuery: BizDataQuery) => void;
  onChangeName: (name: string) => void;
  eventTypeId: string;
};

const DiagnosticKpiEntry: FC<Props> = props => {
  const { kpi, onClone, onDelete, onChangeName, onChangeQuery, eventTypeId } = props;

  const { close: onCloseDeletePopup, open: onOpenDeletePopup, isOpen: isDeletePopupOpen } = useToggleState();
  const { close: onCloseEditPopup, open: onOpenEditPopup, isOpen: isEditPopupOpen } = useToggleState();

  const onDeleteConfirm = useCallback(() => {
    onDelete();
    onCloseDeletePopup();
  }, [onCloseDeletePopup, onDelete]);

  return (
    <VerticallyCenteredRow className={`flex-gap-12 ${diagnosticKpiChildClass}`}>
      {isEditPopupOpen && (
        <DiagnosticKpiEditorPopper
          eventTypeId={eventTypeId}
          isOpen
          onClose={onCloseEditPopup}
          onUpdateName={onChangeName}
          onUpdateQuery={onChangeQuery}
          opBizDataQuery={kpi}
        />
      )}
      <IncSmartText text={kpi.name || ""} />
      <VerticallyCenteredRow className="marginLtAuto flex-gap-12">
        <IncToolTip titleText="Edit">
          <ISaxIcon
            className="inc-cursor-pointer status-info"
            iconName="Edit"
            onClick={onOpenEditPopup}
            variant="Outline"
          />
        </IncToolTip>
        <IncToolTip titleText="Clone">
          <ISaxIcon
            className="inc-cursor-pointer status-info"
            iconName="Copy"
            onClick={onClone}
          />
        </IncToolTip>
        <IncToolTip titleText="Delete">
          <ISaxIcon
            className="inc-cursor-pointer status-danger"
            iconName="Trash"
            onClick={onOpenDeletePopup}
          />
        </IncToolTip>
        <IncPopConfirm
          buttonProps={{
            okButton: {
              text: "Confirm"
            },
            cancelButton: {
              text: "Cancel"
            }
          }}
          color="secondary-blue"
          iconProps={{
            iconName: "trash",
            className: "status-danger"
          }}
          onCancel={onCloseDeletePopup}
          onConfirm={onDeleteConfirm}
          open={isDeletePopupOpen}
          placement="bottomRight"
          showCancel
          title={"Confirm Delete?"}
        />
      </VerticallyCenteredRow>
    </VerticallyCenteredRow>
  );
};

export default DiagnosticKpiEntry;
