import { IncSelectOption } from "@inception/ui";
import { isEqual } from "lodash";
import {
  UserServiceFieldSliceSet,
  UserServiceFieldSlice,
  SliceSet,
  SliceSpec,
  WidgetConfigUtils,
  WidgetQuerySchema
} from "../../../../../services/api/explore";
import { ENTITY_TAG } from "../../../../../utils";

export const adjustImplicitSliceSet = (sliceSets: UserServiceFieldSliceSet[], implicitSlice: UserServiceFieldSlice) => {
  const newSliceSets = [...sliceSets];

  if (implicitSlice) {
    newSliceSets.forEach(sliceSet => {
      sliceSet.slices.forEach(slice => {
        if (isEqual(slice.userServiceField, implicitSlice.userServiceField)) {
          slice.tagName = ENTITY_TAG;
        }
      });
    });
  }

  return newSliceSets;
};

type SliceSpecAndAgg = {
  rollingFunction: string;
  sliceSpec: SliceSpec;
};

export type SliceSetOption = IncSelectOption<SliceSpecAndAgg>;

export const getSliceSetOptions = (
  sliceSpecs: SliceSpec[],
  querySchemas: WidgetQuerySchema[],
  excludeMetricName = false
) => {
  const opts: SliceSetOption[] = [];

  sliceSpecs.forEach(sliceSpec => {
    const { metricId, sliceSet } = sliceSpec;

    const tagNames = WidgetConfigUtils.getTagNamesFromSliceSet(sliceSet);
    const matchingQuerySchema = WidgetConfigUtils.getMatchingQuerySchema(querySchemas, metricId, tagNames);

    if (matchingQuerySchema) {
      const { defaultTimeAgg, metricName, sliceSet } = matchingQuerySchema;
      const mName = excludeMetricName ? "" : metricName;
      const label = getMetricLabel(mName, sliceSet);

      opts.push({
        label,
        value: label,
        data: {
          rollingFunction: defaultTimeAgg,
          sliceSpec
        }
      });
    }
  });

  return opts;
};

const getMetricLabel = (metricName: string, sliceSet: SliceSet) => {
  let label = metricName;
  if (sliceSet.slices?.length) {
    const slicesToInclude = metricName ? sliceSet.slices.filter(sl => sl.tagName !== ENTITY_TAG) : sliceSet.slices;

    const sliceTags = slicesToInclude.map(slice => {
      const isEntityTag = slice.tagName === ENTITY_TAG;
      return isEntityTag ? slice.entityTypeName : slice.tagName;
    });

    const prefix = metricName ? " by " : "";

    const sliceTagsLabel = sliceTags.join(", ");
    label += sliceTagsLabel ? prefix + sliceTagsLabel : "";
  }
  return label;
};
