import React, { FC, useState, useMemo, memo, useCallback } from "react";
import { IncModal } from "@inception/ui";
import { VerticallyCenteredRow, ShareUrl } from "../../../components";
import { useTimeRange, RawTimeRange } from "../../../core";
import { TimeRangeController } from "../../../components/time-range";
import { noOp } from "../../../utils";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { OpCreationConfig, SeriesFilter } from "../../../services/api/operationalise";
import { TrainingDashboard } from "./TrainingDashboard";

interface Props {
  opId: string;
  opName: string;
  entityTypeName: string;
  simulationId?: string;
  onClose: () => void;
  opCreationConfig?: OpCreationConfig;
  onChangeSeriesFilter?: (seriesFilters: SeriesFilter[]) => void;
  showSnoozeSeries?: boolean;
}

export const TrainingDashboardModal: FC<Props> = memo(props => {
  const {
    opName,
    opId,
    entityTypeName,
    simulationId,
    opCreationConfig,
    onChangeSeriesFilter,
    showSnoozeSeries,
    onClose
  } = props;

  const { timeRange: globalTimeRange, compareTimeRange } = useTimeRange();

  const [timeRange, setTimeRange] = useState(globalTimeRange);

  const updateTimeRange = useCallback((raw: RawTimeRange) => {
    const nTr = timeRangeUtils.getTimeRangeFromRaw(raw);
    setTimeRange(nTr);
  }, []);

  const onRefresh = useCallback(() => {
    const nTr = timeRangeUtils.getTimeRangeFromRaw(timeRange.raw);
    setTimeRange(nTr);
  }, [timeRange.raw]);

  const titleActions = useMemo(
    () => (
      <VerticallyCenteredRow className="marginRt16">
        <TimeRangeController
          compareTimeRange={compareTimeRange}
          refresh={onRefresh}
          setCompareTimeRange={noOp}
          setTimeRange={updateTimeRange}
          showCompareSelector={false}
          showTimeRangeSelector
          timeRange={timeRange}
        />
        <ShareUrl nonHashKeys={["action", "stage", "opId", "from", "to"]} />
      </VerticallyCenteredRow>
    ),
    [compareTimeRange, onRefresh, timeRange, updateTimeRange]
  );

  const title = `Training Dashboard - ${opName}`;

  return (
    <IncModal
      className="training-dashboard-modal"
      disableFocusOnLoad
      onClose={onClose}
      show
      size="full-screen"
      titleActions={titleActions}
      titleText={title}
    >
      <TrainingDashboard
        entityTypeName={entityTypeName}
        onChangeSeriesFilter={onChangeSeriesFilter}
        opCreationConfig={opCreationConfig}
        opId={opId}
        opName={opName}
        showSnoozeSeries={showSnoozeSeries}
        simulationId={simulationId}
        timeRange={timeRange}
      />
    </IncModal>
  );
});
