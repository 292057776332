import {
  Chart,
  YAxisOptions,
  TooltipOptions,
  DataLabelsFormatterCallbackFunction,
  XAxisOptions,
  SeriesSplineOptions,
  SeriesColumnOptions,
  AxisLabelsFormatterContextObject
} from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Highcharts from "../highcharts-common-config";
import ColumnChartOptionsBuilder from "../../column-chart/OptionsBuilder";
import BarChartOptionsBuilder from "../../bar-chart/BarOptionsBuilder";
import { RegionHighlight } from "../plot-bands/RegionHighlights";
import { CommonWidgetOptions } from "../../../dashboard/models/BaseWidgetModel";
import { IncHBarSeriesOptions, IncVBarSeriesOptions } from "../../bar-chart/types";
import { IncColumnSeriesOptions } from "../../column-chart/types";
import ChartOptionsBuilder from "../../time-series/ChartOptionsBuilder";
import BaseOptionsBuilderWithAxes from "../BaseOptionsBuilderWithAxes";
import { TimeRange } from "../../../core";
import { getTimeRange } from "../../../core/hooks/time-range/TimeRangeGetter";

type Options = CommonWidgetOptions & Highcharts.Options;

export interface BarColumnChartProps<B> {
  title: string;
  containerElemClass?: string;

  // Map of series name to data where date is of the form [[timestamp1, value1], [timestamp2, value2]]
  series?: IncHBarSeriesOptions[] | IncVBarSeriesOptions[] | IncColumnSeriesOptions[];

  maxValue?: number;
  usedInNonDashboardWidget?: boolean; // Is this used in standalone screens vs in dashboards with widgets

  showLegend?: boolean;
  seriesColors?: string[];
  regionHighlights?: RegionHighlight[];

  // In case of series which have large number of series use this
  disableInteractions?: boolean;
  options?: Options;

  // options builder
  optionsBuilder: B; //BarChartOptionsBuilder | ColumnChartOptionsBuilder;

  // to show x axis point name
  showAxisNameInTooltip?: boolean;

  // to show x axis point name only and skip series name
  hideSeriesName?: boolean;

  // to show x axis name
  xAxisTitle?: string;
  xAxisAsTimeRange?: boolean;
  timeRange?: TimeRange;
  xAxisOffset?: number;
  hideXAxisLabels?: boolean;
  xAxisCategories?: string[];
  minXAxisIntervalMs?: number;
  xAxisLabelFormatter?: (ctx: AxisLabelsFormatterContextObject) => string;

  // to show y axis name
  yAxisTitle?: string;
  hideYAxis?: boolean;
  yAxisDecimals?: number;
  labelPrecision?: number;
  allowDecimalsInYAxis?: boolean;
  yAxisLabelFormatter?: (ctx: AxisLabelsFormatterContextObject) => string;

  tooltipValueFormatter?: (value: string | number, custom?: Record<string, unknown>) => string;
  tooltipFormatter?: TooltipOptions["formatter"];
  disableSharedTooltip?: boolean;

  dataLabelFormatter?: DataLabelsFormatterCallbackFunction;
  stacked?: boolean;
  splineSeries?: SeriesSplineOptions[];
  disableAreaSelection?: boolean;
}

const BarColumnChart = <OptionsBuilder extends BarChartOptionsBuilder | ColumnChartOptionsBuilder>(
  props: BarColumnChartProps<OptionsBuilder>
) => {
  const [, setChartRendered] = useState(false);
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>(null);
  const [chartInstance, setChartInstance] = useState<Chart>(null);
  const chartContainerRef = useRef<HTMLDivElement>();
  const {
    containerElemClass,
    maxValue,
    regionHighlights,
    series,
    disableInteractions,
    showLegend,
    showAxisNameInTooltip,
    options: hcOptions,
    optionsBuilder: chartOptsBuilder,
    hideSeriesName,
    tooltipFormatter,
    xAxisAsTimeRange,
    yAxisTitle,
    xAxisTitle = "",
    yAxisDecimals,
    labelPrecision,
    hideXAxisLabels,
    dataLabelFormatter,
    disableSharedTooltip = false,
    allowDecimalsInYAxis = true,
    timeRange,
    stacked = false,
    hideYAxis = false,
    xAxisOffset = 0,
    disableAreaSelection,
    splineSeries,
    xAxisCategories,
    xAxisLabelFormatter,
    minXAxisIntervalMs,
    yAxisLabelFormatter,
    tooltipValueFormatter
  } = props;

  const getChartDimensions = useCallback(() => {
    const canvasElem: HTMLElement = chartContainerRef.current;
    if (canvasElem) {
      const widgetItemContainer: Element = canvasElem.closest(`.${containerElemClass}`);
      const dimensions = widgetItemContainer?.getBoundingClientRect();
      if (dimensions) {
        const { height } = dimensions;
        const { width } = dimensions;
        return {
          width,
          height
        };
      }
    }
    return {
      width: -1,
      height: -1
    };
  }, [chartContainerRef, containerElemClass]);

  useEffect(() => {
    if (chartContainerRef.current) {
      setChartRendered(true);
    }
  }, [getChartDimensions, chartContainerRef]);

  // Calculate dimensions on every render
  const { width, height } = getChartDimensions();

  useEffect(() => {
    if (height <= 0 || !width) {
      return;
    }

    const optionsBuilder = chartOptsBuilder as BarChartOptionsBuilder;

    optionsBuilder
      .setChartFields(width, height)
      .setPlotOptions(disableInteractions, stacked)
      .setTitle(null)
      .setXAxis(false, xAxisTitle, hideXAxisLabels, xAxisOffset, xAxisLabelFormatter)
      .setYAxis(
        maxValue,
        hcOptions ? (hcOptions.yAxis as YAxisOptions[]) : [],
        yAxisTitle,
        allowDecimalsInYAxis,
        yAxisLabelFormatter,
        hideYAxis
      )
      .clearSeriesData()
      .setSeriesData(series as any, labelPrecision, dataLabelFormatter)
      .setLegend(showLegend)
      .setPlotBands(regionHighlights);

    (optionsBuilder as BaseOptionsBuilderWithAxes).setToolTip(
      showAxisNameInTooltip,
      hideSeriesName,
      tooltipValueFormatter
    );

    const options = optionsBuilder.build();

    if (disableAreaSelection) {
      options.chart.events = {
        selection: () => false
      };
    }

    if (xAxisAsTimeRange) {
      const xAxisTimeRange = timeRange || getTimeRange();
      const optionsBuilder: ChartOptionsBuilder = new ChartOptionsBuilder()
        .setChartFields("timeline", width, height)
        .setXAxis(xAxisTitle, xAxisTimeRange, minXAxisIntervalMs);

      const chartOptions = optionsBuilder.build();
      const xAxisOptions = chartOptions.xAxis as XAxisOptions;
      chartOptions.xAxis = {
        ...xAxisOptions,
        ...((hcOptions?.xAxis as XAxisOptions) || {}),
        offset: xAxisOffset
      };

      options.xAxis = chartOptions.xAxis;
    }

    if (xAxisCategories) {
      const xAxisOptions = options.xAxis as XAxisOptions;
      xAxisOptions.type = "category";
      xAxisOptions.categories = xAxisCategories;
      delete xAxisOptions.max;
      delete xAxisOptions.min;

      options.series.forEach(serie => {
        const colSerie = serie as SeriesColumnOptions;
        delete colSerie.colorByPoint;
        delete colSerie.getExtremesFromAll;
        delete colSerie.colors;
      });
    }

    if (tooltipFormatter) {
      options.tooltip.formatter = tooltipFormatter;
    }

    options.tooltip.shared = !disableSharedTooltip;

    options.series.push(...(splineSeries || []));

    setChartOptions(options);
  }, [
    maxValue,
    series,
    width,
    height,
    regionHighlights,
    hcOptions,
    showAxisNameInTooltip,
    disableInteractions,
    showLegend,
    chartOptsBuilder,
    hideSeriesName,
    yAxisTitle,
    xAxisAsTimeRange,
    tooltipFormatter,
    yAxisDecimals,
    labelPrecision,
    hideXAxisLabels,
    dataLabelFormatter,
    disableSharedTooltip,
    allowDecimalsInYAxis,
    xAxisTitle,
    timeRange,
    stacked,
    hideYAxis,
    xAxisOffset,
    disableAreaSelection,
    splineSeries,
    xAxisCategories,
    xAxisLabelFormatter,
    minXAxisIntervalMs,
    yAxisLabelFormatter,
    tooltipValueFormatter
  ]);

  useEffect(() => {
    if (chartInstance) {
      chartInstance.redraw();
    }
  }, [chartInstance]);

  const highchartsCallback = useCallback(chart => setChartInstance(chart), []);

  return (
    <div
      className="chart-container"
      ref={chartContainerRef}
    >
      {chartOptions && (
        <HighchartsReact
          callback={highchartsCallback}
          highcharts={Highcharts}
          options={chartOptions}
        />
      )}
    </div>
  );
};

export default BarColumnChart;
