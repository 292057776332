import React, { FC, useMemo, useCallback, useState, useEffect } from "react";
import { IncButton, IncMenuItem, IncMenuV2, IncSelectOption } from "@inception/ui";
import { useLocation } from "react-router";
import { omit, last, cloneDeep, clone } from "lodash";
import { CatalogWidgetImpl } from "../models";
import {
  WidgetResponseDTO,
  CohortEntityFilter,
  MetricUserServiceFilters,
  UserServiceFieldSlice,
  UserServiceFilterExpression
} from "../../../../services/api/explore";
import {
  logger,
  MY_USE_CASE_ID,
  OpConfigListItem,
  useFetchOpConfigs,
  useInceptionRoute,
  useTypedQueryParams,
  useVerticalConfig
} from "../../../../core";
import { OperationaliseModal, Op10zeSourceConfig, VerticallyCenteredRow, LoadingSpinner } from "../../../../components";
import { FieldPickerUtils } from "../../../../utils";
import { getBizFieldPredicatesFromCohortFilters } from "../../utils";
import { CatalogWidgetUtils } from "../CatalogWidgetUtils";
import { extractSliceSetsFromDataDefinition } from "../../../../utils/ExploreUtils";
import { UseCaseSummaryWithSchema, useCaseApiService } from "../../../../services/api";

interface Props {
  widget: CatalogWidgetImpl;
  widgetResponseDTO: WidgetResponseDTO;
  entityFilters?: CohortEntityFilter[];
  eventFilters?: MetricUserServiceFilters;

  buttonText?: string;
  skipExistingCount?: boolean;
  onOpConfigsFetched?: (opConfigs?: OpConfigListItem[]) => void;
}

export const Subscribe: FC<Props> = props => {
  const {
    widget,
    widgetResponseDTO,
    entityFilters,
    eventFilters,
    buttonText = "Operationalize",
    skipExistingCount = false,
    onOpConfigsFetched
  } = props;

  const { verticalConfig, useCaseSchemaApi } = useVerticalConfig();
  const { useCaseId } = verticalConfig || {};
  const { updateUseCaseSchema } = useCaseSchemaApi;

  const onSaveOrUpdate = useCallback(
    async (opConfigId: string) => {
      if (useCaseId && useCaseId !== MY_USE_CASE_ID) {
        try {
          const { data, error, message } = await useCaseApiService.addOp10zeToUseCase(useCaseId, [opConfigId]);

          if (error) {
            logger.fatal("CatalogWidget > Subscribe", "Error adding op10ze to copilot", {
              data,
              message,
              opConfigId,
              useCaseId
            });
          } else {
            updateUseCaseSchema(
              {
                dataQueryConfigs: data.queries,
                id: useCaseId
              } as UseCaseSummaryWithSchema,
              true
            );
            logger.info("CatalogWidget > Subscribe", "Added op10ze to copilot", opConfigId);
          }
        } catch (err) {
          logger.fatal("CatalogWidget > Subscribe", "Error adding op10ze to copilot", {
            error: err,
            opConfigId,
            useCaseId
          });
        }
      }
    },
    [updateUseCaseSchema, useCaseId]
  );

  const {
    entityType: wEntityTypeId,
    userServiceId: wUserserviceId,
    queryConfig,
    widgetConfigRefId,
    bizEntityFieldName
  } = widget;

  const disableAutoFetchOp10ze = skipExistingCount || !widgetConfigRefId;

  const { widgetConfig, querySchema } = widgetResponseDTO;

  const location = useLocation();
  const queryParams = useTypedQueryParams();
  const { navigate } = useInceptionRoute();
  const [selectedMetricId, setSelectedMetricId] = useState<string>();

  const [open, setOpen] = useState(false);
  const openModal = useCallback(() => setOpen(true), []);
  const closeModal = useCallback(() => {
    const newParams = omit(queryParams, "opId", "action");
    navigate(location.pathname, {
      queryParams: newParams,
      replace: true
    });
    setOpen(false);
  }, [location.pathname, navigate, queryParams]);

  const { cohortConfig, sourceQueryConfig } = queryConfig;
  const { cohortId = "all" } = cohortConfig || {};

  const { sourceConfig, entityType, userServiceId } = useMemo(() => {
    let sourceConfig: Op10zeSourceConfig;
    let entityType = wEntityTypeId;
    let userServiceId = wUserserviceId;

    if (sourceQueryConfig?.queryType === "userServiceField") {
      let slices: UserServiceFieldSlice[];

      const metrics = widgetResponseDTO?.widgetConfig?.dataDefinition?.metrics;
      if (metrics) {
        const metricDef = Object.values(metrics)[0];
        if (metricDef?.sourceType === "userServiceField") {
          const { userServiceFieldMetricConfig } = metricDef;
          const { sliceSets } = userServiceFieldMetricConfig || {};
          if (sliceSets?.length) {
            slices = last(sliceSets).slices;
          }
        }
      }

      const baseUSField = sourceQueryConfig.usField.userServiceField;
      const isErrorField = CatalogWidgetUtils.isHasErrorField(baseUSField.fieldName);
      const usField = isErrorField ? cloneDeep(baseUSField) : baseUSField;
      usField.fieldName = isErrorField ? CatalogWidgetUtils.hasErrorFieldName : usField.fieldName;
      usField.dataType = isErrorField ? "STRING" : usField.dataType;

      const filterExpressions: UserServiceFilterExpression[] = isErrorField
        ? [
            {
              field: usField,
              operator: "=",
              value: "true"
            }
          ]
        : null;

      sourceConfig = {
        sourceType: "usField",
        usField,
        filterExpressions,
        widgetConfigRefId,
        slices
      };
      entityType = entityType || usField.displayBizEntityFieldName;
      userServiceId = usField.userServices?.[0]?.userServiceEntityId || userServiceId || "";
    } else {
      const widgetDTO = clone(widgetResponseDTO);
      const qsEntries = querySchema?.querySchema || [];
      const qsEntry = qsEntries.find(qs => qs.metricId === selectedMetricId);
      widgetDTO.querySchema = {
        querySchema: [qsEntry]
      };
      const sourceField = qsEntry?.sourceUserServiceField || qsEntry?.componentSourceFields?.[0];
      entityType = entityType || widgetConfig?.bizEntityType || sourceField?.displayBizEntityFieldName || "";
      userServiceId =
        widgetConfig?.userServiceEntityId || sourceField?.userServices?.[0]?.userServiceEntityId || userServiceId || "";
      sourceConfig = {
        sourceType: "widgetConfig",
        widgetResponseDTO: widgetDTO
      };
    }

    return {
      sourceConfig,
      entityType,
      userServiceId
    };
  }, [
    querySchema,
    selectedMetricId,
    sourceQueryConfig,
    wEntityTypeId,
    wUserserviceId,
    widgetConfig,
    widgetConfigRefId,
    widgetResponseDTO
  ]);

  const exploreEntityFilters = useMemo(() => {
    if (entityFilters?.length > 0) {
      let filterPredicates = getBizFieldPredicatesFromCohortFilters(entityFilters);
      filterPredicates = filterPredicates.filter(
        predicate => FieldPickerUtils.getBizFieldLabel(predicate) !== "cohort.id"
      );
      return [
        {
          filters: filterPredicates
        }
      ];
    }
    return [];
  }, [entityFilters]);

  const {
    data: opConfigs,
    error,
    isError,
    isFetching
  } = useFetchOpConfigs(widgetConfigRefId, null, null, null, null, disableAutoFetchOp10ze);

  useEffect(() => {
    if (!isFetching && onOpConfigsFetched) {
      onOpConfigsFetched(opConfigs || []);
    }
  }, [isFetching, onOpConfigsFetched, opConfigs]);

  const numOpConfigs = useMemo(() => opConfigs?.length || 0, [opConfigs]);

  useEffect(() => {
    if (!isFetching && isError) {
      logger.error("Catalog Widget", "Error fetching opConfigs", {
        widgetId: widgetConfigRefId,
        error
      });
    }
  }, [error, isError, isFetching, widgetConfigRefId]);

  /**
   * metric option population
   */
  const metricOptions = useMemo<IncSelectOption[]>(() => {
    const { widgetConfig, querySchema } = widgetResponseDTO || {};
    if (widgetConfig && querySchema) {
      //get the metrics from widget config
      const metrics = widgetConfig.dataDefinition?.metrics;
      //get the slice set from data definition and query schema
      const slicesByMetric = extractSliceSetsFromDataDefinition(
        widgetConfig.dataDefinition,
        querySchema.querySchema || []
      );
      const opts = Object.keys(slicesByMetric).map(id => {
        const metric = metrics[id];
        return {
          label: metric?.name,
          value: metric?.id.toString()
        } as IncSelectOption;
      });
      //setting the first value as metric id as default
      setSelectedMetricId(opts[0]?.value);
      return opts;
    }
    return [];
  }, [widgetResponseDTO]);

  /**
   * handle selection
   */
  const onMetricSelect = useCallback(
    (menuItem: IncMenuItem) => {
      setSelectedMetricId(menuItem.value);
      openModal();
    },
    [openModal]
  );

  const showMenuButton = metricOptions.length > 1;

  return (
    <>
      {!showMenuButton && (
        <IncButton
          className="subscribe-button"
          color="secondary-blue"
          disabled={!widgetConfigRefId}
          onClick={openModal}
        >
          <VerticallyCenteredRow className="flex-gap-8">
            {(Boolean(numOpConfigs) || isFetching) && !skipExistingCount && (
              <VerticallyCenteredRow className="num-pill">
                {!isFetching && numOpConfigs}
                {isFetching && <LoadingSpinner titleText=" " />}
              </VerticallyCenteredRow>
            )}

            {buttonText}
          </VerticallyCenteredRow>
        </IncButton>
      )}

      {!isFetching && showMenuButton && (
        <IncMenuV2
          label={buttonText}
          menuItems={metricOptions}
          menuLabelClassName="subscribe-button inc-button inc-button-secondary-blue inc-button-regular"
          onMenuItemClick={onMetricSelect}
        ></IncMenuV2>
      )}

      <OperationaliseModal
        bizEntityFieldName={bizEntityFieldName}
        cohortId={cohortId}
        entityFilters={exploreEntityFilters}
        entityTypeId={entityType}
        eventFilters={eventFilters}
        eventTypeId={userServiceId}
        onClose={closeModal}
        onSaveOrUpdate={onSaveOrUpdate}
        onSimulate={onSaveOrUpdate}
        open={open}
        sourceConfig={sourceConfig}
      />
    </>
  );
};
