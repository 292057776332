import { useState, useRef, useMemo, useEffect, useCallback } from "react";
import { SeriesSplineOptions } from "highcharts";
import { isEqual, cloneDeep } from "lodash";
import { inceptionDarkColorPalette } from "@inception/ui-styles";
import { IncSelectOption } from "@inception/ui";
import { CatalogWidgetFetchDataPayload } from "../../../types";
import { HistogramAgg, FilterSpec, PostAggProjection } from "../../../../../../services/api/explore";
import { useCommonRendererFunctionality, CatalogVizRendererProps } from "../common";
import { logger } from "../../../../../../core/logging/Logger";
import { getHistogramBarSeriesFromData } from "./utils";

type Props = CatalogVizRendererProps & {
  sliceBy: string[];
  binsGroupBy: string[];
};

export const useFetchHistogramInsightsData = (props: Props) => {
  const { properties, dataFetchPayload, queryId, metricId, onError, sliceBy, binsGroupBy, entityTypeName } = props;

  const { histogramInsights } = properties || {};

  const [splineSeries, setSplineSeries] = useState<SeriesSplineOptions[]>([]);

  const prevFetchProps = useRef<FetchProps>();
  const prevDataFetchPayload = useRef<CatalogWidgetFetchDataPayload>();

  const binsStartRef = useRef<string[]>([]);

  const isCustomBinSelection = histogramInsights?.binSelection === "custom";
  const defBinSelOpt = isCustomBinSelection ? binSelectionOptions[1] : binSelectionOptions[0];
  const [binType, setBinType] = useState<IncSelectOption>(defBinSelOpt);

  const defBins = isCustomBinSelection ? histogramInsights?.bins || [] : [];
  const [bins, setBins] = useState<number[]>(defBins);

  const updateBins = useCallback((bins: number[]) => {
    setFilters([]);
    setBins(bins);
  }, []);

  useMemo(() => {
    properties.histogramInsights = {
      ...(properties.histogramInsights || ({} as any)),
      bins: binType?.value === "custom" ? bins : [],
      binSelection: binType?.value || "auto"
    };
  }, [binType, bins, properties]);

  const defFilters = properties?.histogramInsights?.filters || [];
  const [filters, setFilters] = useState<FilterSpec[]>(defFilters);
  const resetFilters = useCallback(() => setFilters([]), []);

  useMemo(() => {
    properties.histogramInsights = {
      ...(properties.histogramInsights || ({} as any)),
      filters
    };
  }, [filters, properties]);

  const linesDataFetchPayload = useMemo<CatalogWidgetFetchDataPayload>(() => {
    const fetchPropsChanged = !isEqual(prevFetchProps.current, {
      bins,
      binsGroupBy
    });

    if (fetchPropsChanged && dataFetchPayload) {
      const nDataFetchPayload = cloneDeep(dataFetchPayload);
      const histogramAgg: HistogramAgg = {
        groupBys: binsGroupBy
      };

      if (bins.length) {
        histogramAgg.spec = {
          spec: {
            bins
          }
        };
      }

      nDataFetchPayload.sliceSpec.forEach(ss => {
        const postAgg = ss.postAgg as any;

        postAgg.histogramAgg = histogramAgg;

        postAgg.overTagAgg = {
          aggregator: "avg",
          tagName: []
        };

        postAgg.overTimeAgg = {
          ...(postAgg.overTimeAgg || {}),
          aggregator: "avg"
        };

        postAgg.projections = ["current"];

        postAgg.isSingleStatQuery = true;
      });

      prevFetchProps.current = {
        bins,
        binsGroupBy
      };

      prevDataFetchPayload.current = nDataFetchPayload;
    }

    return prevDataFetchPayload.current;
  }, [bins, binsGroupBy, dataFetchPayload]);

  const {
    data: linesData,
    isFetching: isLinesDataFetching,
    isError: isLinesDataError,
    dataExists: linesDataExists,
    error: linesDataError,
    metricName
  } = useCommonRendererFunctionality({
    ...props,
    queryId: `${queryId}--line`,
    dataFetchPayload: linesDataFetchPayload,
    postAggProjections
  });

  const barsDataFetchPayload = useMemo<CatalogWidgetFetchDataPayload>(() => {
    if (!isLinesDataFetching && linesDataExists && bins.length) {
      const nDataFetchPayload = cloneDeep(linesDataFetchPayload);
      nDataFetchPayload.sliceSpec.forEach(ss => {
        const postAgg = ss.postAgg as any;

        postAgg.overTagAgg = {
          aggregator: "avg",
          tagName: sliceBy
        };

        postAgg.histogramAgg = {
          groupBys: binsGroupBy,
          spec: {
            spec: {
              bins
            }
          }
        };

        postAgg.isSingleStatQuery = true;
      });
      return nDataFetchPayload;
    }

    return null;
  }, [bins, binsGroupBy, isLinesDataFetching, linesDataExists, linesDataFetchPayload, sliceBy]);

  const {
    data: barsData,
    isFetching: isBarsDataFetching,
    isError: isBarsDataError,
    dataExists: barsDataExists
  } = useCommonRendererFunctionality({
    ...props,
    queryId: `${queryId}--bars`,
    dataFetchPayload: barsDataFetchPayload,
    postAggProjections
  });

  useEffect(() => {
    onError("");
    if (!isLinesDataFetching) {
      if (!isLinesDataError) {
        let bins: number[] = [];
        let lineData: Array<[string, number]> = [];

        const datum = Object.values(linesData[0]?.postAggResult?.data || {})[0];

        if (!datum) {
          onError(`Error parsing bins: Bins not found`);
          logger.error("Histogram", "Error parsing data", {
            linesData,
            metricId
          });
        } else {
          const { data } = datum;
          data.forEach(df => {
            const { fields, labels } = df;

            const binStart = parseFloat(labels[BUCKET_TAG]);
            bins.push(binStart);

            const value = fields[1].data?.[0] as number;
            lineData.push([binStart.toString(), value]);
          });

          lineData = lineData.sort((a, b) => parseInt(a[0], 10) - parseInt(b[0], 10));
          bins = bins.sort((a, b) => a - b);

          prevFetchProps.current.bins = bins;
          setBins(bins);
          setSplineSeries([
            {
              type: "spline",
              data: lineData,
              name: metricName,
              connectEnds: false,
              color: inceptionDarkColorPalette.accentYellow,
              lineWidth: 2
            }
          ]);
        }
      } else {
        onError(`Error parsing bins: ${linesDataError}`);
      }
    }
  }, [isLinesDataError, isLinesDataFetching, linesData, linesDataError, metricId, metricName, onError]);

  const isFetching = isLinesDataFetching || isBarsDataFetching;
  const dataExists = linesDataExists;
  const isError = isLinesDataError || isBarsDataError;

  const onBarSelect = useCallback((binEnd: string) => {
    const filters: FilterSpec[] = [];

    const idx = binsStartRef.current?.indexOf(binEnd);
    const binStart = idx >= 1 ? binsStartRef.current?.[idx - 1] : null;

    if (binStart) {
      filters.push({
        op: "ge",
        value: {
          doubleVal: parseFloat(binStart) + 1
        }
      });
    }

    filters.push({
      op: "le",
      value: {
        doubleVal: parseFloat(binEnd)
      }
    });

    setFilters(filters);
  }, []);

  const { series, bins: binStartArr } = useMemo(() => {
    if (barsDataExists) {
      const res = getHistogramBarSeriesFromData(barsData[0], sliceBy, entityTypeName, onBarSelect);
      binsStartRef.current = res.bins;

      return res;
    }

    return {
      series: [],
      bins: []
    };
  }, [barsData, barsDataExists, entityTypeName, onBarSelect, sliceBy]);

  const fSplineSeries = useMemo(
    () =>
      splineSeries.map(ser => ({
        ...ser,
        data: ser.data.filter(p => binStartArr.includes((p as any)[0]))
      })),
    [binStartArr, splineSeries]
  );

  return {
    isFetching,
    dataExists,
    isError,
    splineSeries: fSplineSeries,
    barSeries: series,
    binStartArr,
    filters,
    resetFilters,
    binType,
    setBinType,
    bins,
    setBins: updateBins
  };
};

type FetchProps = {
  bins: number[];
  binsGroupBy: string[];
};

const postAggProjections: PostAggProjection[] = ["current"];

const BUCKET_TAG = "bucket";

export const binSelectionOptions: IncSelectOption[] = [
  {
    label: "Auto",
    value: "auto"
  },
  {
    label: "Custom",
    value: "custom"
  }
];
