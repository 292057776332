import React, { FC, useEffect, useMemo, useCallback, useState } from "react";
import { extend, pick, inRange, round } from "lodash";
import {
  IncRTable,
  TableDataColumn,
  IncRTableProps,
  FilterRendererProps,
  IncToolTip,
  IncFaIcon,
  formatNumber,
  IncButton
} from "@inception/ui";
import { TimeRange, useNotifications, logger, useToggleState, useRefState } from "../../core";
import { EntityWidgetData } from "../../biz-entity";
import { getDisplayTagNameForUSFieldSlice, ForecastProjection, ForecastResultDTO } from "../../services/api/explore";
import { TableSelectFilterRenderer, TableRangeFilterRenderer } from "../table-filter-renderers";
import { VerticallyCenteredRow } from "../flex-components";
import { ChartPoint } from "../../dashboard/widgets/TimeSeries/models/model";
import { dataTypeManager, downloadBlobFile } from "../../utils";
import { getFieldsFromLabels } from "../../dashboard/widgets/USField/components/renderers";
import LoadingSpinner from "../Loading/Loading";
import { MetricTableConfig, MetricTableVariableContext } from "./types";
import { useFetchMetricTableData, useFetchWidgetResponsesMap, useDownloadTableCsv, DEF_SERIES_LIMIT } from "./hooks";
import { getDataFramesFromEntityDataByProjection } from "./queryUtils";
import { DownloadCSVModal } from "./DownloadCSVModal";

interface Props {
  defaultFileName: string;
  metricTableConfig: MetricTableConfig;
  timeRange: TimeRange;
  variablesContext?: MetricTableVariableContext;
}

const FORCASTED_COL_KEY = "forcastedValue";

export const MetricTableRenderer: FC<Props> = props => {
  const { notifyError } = useNotifications();

  const { metricTableConfig: defMetricTableConfig, timeRange, variablesContext, defaultFileName } = props;

  const { close: closeModal, open: openModal, isOpen: isModalOpen } = useToggleState();

  const [fileName, setFileName] = useState<string>(defaultFileName);
  const downloadFileNameRef = useRefState(fileName);
  useEffect(() => {
    setFileName(defaultFileName);
  }, [defaultFileName]);

  const [metricTableConfig, setMetricTableConfig] = useState<MetricTableConfig>(defMetricTableConfig);

  useEffect(() => {
    setMetricTableConfig(defMetricTableConfig);
  }, [defMetricTableConfig]);

  const { metricColumns, slices, properties } = metricTableConfig;

  const {
    // filterable = false,
    pagination: defPagination,
    sort,
    showDisplayStats = false,
    seriesLimit
  } = properties || {};

  const [maxSeries, setMaxSeries] = useState(seriesLimit || DEF_SERIES_LIMIT);
  useEffect(() => {
    setMaxSeries(seriesLimit || DEF_SERIES_LIMIT);
  }, [seriesLimit]);

  const sortBy = useMemo<IncRTableProps["sort"]>(() => {
    if (sort) {
      return sort;
    }

    if (metricColumns.length) {
      return {
        accessor: metricColumns[0].id,
        order: "desc"
      };
    }

    return null;
  }, [metricColumns, sort]);

  const pagination = useMemo<IncRTableProps["pagination"]>(
    () => ({
      enabled: true,
      pageSize: 10,
      ...(defPagination || {}),
      viewMode: "minimal"
    }),
    [defPagination]
  );

  const { loadingStateMap } = variablesContext || {};
  const variablesLoading = Object.values(loadingStateMap || {}).some(loading => loading);

  const {
    data: widgetResponseDTOMap,
    isFetching: isWidgetResponseMapFetching,
    fetchWidgetResponseDTOMap
  } = useFetchWidgetResponsesMap();

  useEffect(() => {
    if (metricColumns) {
      fetchWidgetResponseDTOMap(metricColumns);
    }
  }, [fetchWidgetResponseDTOMap, metricColumns]);

  const { dataFetchState, fetchData, isFetching } = useFetchMetricTableData(
    metricTableConfig,
    timeRange,
    widgetResponseDTOMap,
    variablesContext
  );

  const { downloadInProgress, downloadCsv, csvData, downloadError } = useDownloadTableCsv(
    metricTableConfig,
    timeRange,
    widgetResponseDTOMap,
    variablesContext,
    maxSeries
  );

  useEffect(() => {
    if (!downloadInProgress && downloadError) {
      notifyError(`Error downloading data`);
      logger.error("MetricTableRenderer: Error downloading data", downloadError);
      closeModal();
    }
  }, [closeModal, downloadError, downloadInProgress, notifyError]);

  useEffect(() => {
    if (!downloadInProgress && csvData) {
      const fileName = downloadFileNameRef.current;
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      downloadBlobFile(blob, fileName);
      closeModal();
    }
  }, [closeModal, csvData, downloadFileNameRef, downloadInProgress]);

  const onSortChange = useCallback<IncRTableProps["onSortChange"]>((accessor, order) => {
    setMetricTableConfig(prev => ({
      ...prev,
      properties: {
        ...prev.properties,
        sort: {
          accessor,
          order
        }
      }
    }));
  }, []);

  const columnIds = useMemo(() => metricColumns.map(({ id }) => id), [metricColumns]);
  const tagNames = useMemo(() => slices.map(({ slice }) => slice.tagName), [slices]);

  useEffect(() => {
    if (!variablesLoading && !isWidgetResponseMapFetching) {
      fetchData();
    }
  }, [fetchData, isWidgetResponseMapFetching, variablesLoading, sort, seriesLimit]);

  const { data, entityLookupData, errorsByColumn } = useMemo(() => {
    const data: Array<Record<string, any>> = [];
    const entityLookupData: Record<string, string> = {};
    const errorsByColumn: Record<string, string> = {};

    if (!isFetching && !variablesLoading) {
      const dataByKey: Record<string, string[]> = {};
      const forecastDataByKey: Record<string, string[][]> = {};
      const tagsDataByKey: Record<string, Record<string, string>> = {};
      const numMetrics = columnIds.length;

      dataFetchState.forEach((curr, idx) => {
        const { data, error, isError, queryType, projection = "current", forecastProjections } = curr;

        const columnId = columnIds[idx];
        const numForecastProjections = forecastProjections?.length || 0;

        if (isError) {
          errorsByColumn[columnId] = error || "Error fetching data";
        } else if (queryType === "metric") {
          const entityData = data as EntityWidgetData[];
          entityData.forEach(item => {
            const { postAggResult, forecastResult } = item;
            const { entityLookupData: postAggEntityLookupData } = postAggResult;
            const { entityLookupData: forecastEntityLookupData } = forecastResult;

            extend(entityLookupData, {
              ...postAggEntityLookupData,
              ...forecastEntityLookupData
            });

            const dataFrames = getDataFramesFromEntityDataByProjection(item, projection);

            dataFrames.forEach(frame => {
              const { fields, labels = {} } = frame;

              const forecastValues = !numForecastProjections
                ? []
                : getForecastDatapoints(forecastResult, forecastProjections, labels);

              const tags = pick(labels, tagNames);
              const tagsKey = Object.values(tags).join("_");
              const value = fields[1]?.data?.[0] as number;
              const displayValue = value !== undefined && value !== null ? value.toString() : "-";

              tagsDataByKey[tagsKey] = tags;
              dataByKey[tagsKey] = dataByKey[tagsKey] || Array(numMetrics).fill("-");
              dataByKey[tagsKey][idx] = displayValue;

              if (!forecastDataByKey[tagsKey]) {
                const arr: string[][] = [];
                for (let i = 0; i < numMetrics; i++) {
                  arr.push(Array(numForecastProjections).fill("-"));
                }
                forecastDataByKey[tagsKey] = arr;
              }

              forecastDataByKey[tagsKey][idx] = forecastValues;
            });
          });
        }
      });

      Object.keys(tagsDataByKey).forEach(tagsKey => {
        const columnData = dataByKey[tagsKey];
        const forecastedData = forecastDataByKey[tagsKey];
        const tags = tagsDataByKey[tagsKey];

        const dataEntry = { ...tags };
        columnIds.forEach((colId, cIdx) => {
          dataEntry[colId] = columnData[cIdx] || "";
          const colForecastedData = forecastedData[cIdx];

          colForecastedData.forEach((foreCastedValue, idx) => {
            dataEntry[`${colId}_${FORCASTED_COL_KEY}_${idx}`] = foreCastedValue;
          });
        });

        data.push(dataEntry);
      });
    }

    return {
      data,
      entityLookupData,
      errorsByColumn
    };
  }, [columnIds, dataFetchState, isFetching, tagNames, variablesLoading]);

  const columns = useMemo(() => {
    const columns: TableDataColumn[] = [];

    slices.forEach(metricSlice => {
      const { name, slice } = metricSlice;

      const { tagName } = slice;
      const { displayTagName } = getDisplayTagNameForUSFieldSlice(slice);

      const accessor = tagName;
      const header = name || displayTagName;

      columns.push({
        accessor,
        header,
        renderer: value => {
          const entityName = entityLookupData[value];
          return entityName || value;
        },
        sortable: true,
        type: "alphanumeric",
        filterable: false,
        filterRenderer: (props: FilterRendererProps<string[]>) => {
          const valuesSet: Set<string> = new Set();
          data.forEach(row => {
            const value = row[accessor];
            valuesSet.add(value);
          });

          const options = Array.from(valuesSet).map(value => {
            const label = entityLookupData?.[value] || value;

            return {
              label,
              value
            };
          });

          return (
            <TableSelectFilterRenderer
              {...props}
              label={header}
              options={options}
            />
          );
        },
        filterFn: (rows, filters) => {
          if (!filters?.length) {
            return rows;
          }

          return rows.filter(row => {
            const value = row.original[accessor] as string;
            return filters.includes(value);
          });
        }
      });
    });

    metricColumns.forEach(metricColumn => {
      const { id, name, queryConfig } = metricColumn;

      const { dataType = "_long", subType = "none", metricQueryConfig } = queryConfig;

      const { forecastProjections, enableForecast } = metricQueryConfig || {};

      const accessor = id;
      const header = name;

      columns.push({
        accessor,
        header,
        sortable: true,
        type: "number",
        align: "center",
        renderer: value => {
          const error = errorsByColumn[id];

          if (error) {
            return (
              <IncToolTip
                placement="top"
                titleText={error}
                variant="error"
              >
                <VerticallyCenteredRow className="inc-flex-center">
                  <IncFaIcon
                    className="status-danger"
                    iconName="warning"
                  />
                </VerticallyCenteredRow>
              </IncToolTip>
            );
          }

          let numValue = parseFloat(value);

          if (isNaN(numValue)) {
            return "-";
          }

          numValue = round(numValue, 2);

          const formatter = dataTypeManager.getDataTypeDescriptorByKind(dataType, subType)?.getFormattedValue;
          const formattedValue = formatter
            ? formatter(value, { numberFormatOptions: { compact: true } })
            : formatNumber(value);

          return formattedValue as string;
        },
        filterable: false,
        filterRenderer: (props: FilterRendererProps<ChartPoint>) => {
          let min = Number.POSITIVE_INFINITY;
          let max = Number.NEGATIVE_INFINITY;

          data.forEach(entry => {
            const value = entry?.[id];
            const numValue = parseFloat(value);

            if (!isNaN(numValue)) {
              min = Math.min(min, numValue || 0);
              max = Math.max(max, numValue || 0);
            }
          });

          min = min - 1;
          max = max + 1;

          return (
            <TableRangeFilterRenderer
              {...props}
              label={name}
              range={[min, max]}
            />
          );
        },
        filterFn: (rows, range) => {
          if (!range?.length) {
            return rows;
          }

          return rows.filter(row => {
            const { original } = row;
            const value = original[id];

            const numValue = parseFloat(value);

            if (isNaN(numValue)) {
              return true;
            }

            return inRange(numValue, range[0], range[1]);
          });
        }
      });

      if (forecastProjections?.length && enableForecast) {
        forecastProjections.forEach((fProjection, idx) => {
          const suffix = getSuffixForForecastProjection(fProjection);
          const header = [name, suffix].join(" ");
          const accessor = `${id}_${FORCASTED_COL_KEY}_${idx}`;
          columns.push({
            accessor,
            header,
            sortable: true,
            type: "number",
            align: "center",
            renderer: value => {
              const error = errorsByColumn[id];

              if (error) {
                return (
                  <IncToolTip
                    placement="top"
                    titleText={error}
                    variant="error"
                  >
                    <VerticallyCenteredRow className="inc-flex-center">
                      <IncFaIcon
                        className="status-danger"
                        iconName="warning"
                      />
                    </VerticallyCenteredRow>
                  </IncToolTip>
                );
              }

              let numValue = parseFloat(value);

              if (isNaN(numValue)) {
                return "-";
              }

              numValue = round(numValue, 2);

              const formatter = dataTypeManager.getDataTypeDescriptorByKind(dataType, subType)?.getFormattedValue;
              const formattedValue = formatter
                ? formatter(value, { numberFormatOptions: { compact: true } })
                : formatNumber(value);

              return formattedValue as string;
            }
          });
        });
      }
    });

    return columns;
  }, [data, entityLookupData, errorsByColumn, metricColumns, slices]);

  const isLoading = isFetching || variablesLoading || isWidgetResponseMapFetching;

  return (
    <>
      <div className="marginBt12 width-100">
        <IncButton
          className="width-fit-content marginLtAuto"
          color="link"
          disabled={downloadInProgress}
          iconType="iconText"
          onClick={openModal}
        >
          {downloadInProgress && <LoadingSpinner titleText="Downloading..." />}
          {!downloadInProgress && (
            <VerticallyCenteredRow>
              <IncFaIcon
                className="marginRt6"
                iconName="download"
              />
              Download
            </VerticallyCenteredRow>
          )}
        </IncButton>
      </div>

      <IncRTable
        columns={columns}
        data={data}
        isLoading={isLoading}
        onSortChange={onSortChange}
        pagination={pagination}
        persistPageState
        persistSortState
        resizableColumns
        showDisplayStats={showDisplayStats}
        sort={sortBy as any}
      />

      <DownloadCSVModal
        downloadInProgress={downloadInProgress}
        fileName={fileName}
        onClose={closeModal}
        onConfirmClick={downloadCsv}
        onFileNameChange={setFileName}
        onSeriesLimitChange={setMaxSeries}
        seriesLimit={maxSeries}
        show={isModalOpen}
      />
    </>
  );
};

const getSuffixForForecastProjection = (proj: ForecastProjection) =>
  proj === ForecastProjection.forecastDelta
    ? "(Forecasted change)"
    : proj === ForecastProjection.forecastDeltaPerc
      ? "(Forecasted percentage change)"
      : proj === ForecastProjection.forecastLower
        ? "(Forecasted lower)"
        : proj === ForecastProjection.forecastUpper
          ? "(Forecasted upper)"
          : "(Forecasted)";

const getForecastDatapoints = (
  forecastResult: ForecastResultDTO,
  projections: ForecastProjection[],
  labels: Record<string, string>
) => {
  const { data, lowerBoundData, upperBoundData, percentChangeData, delta } = forecastResult || {};

  const numProjections = projections.length;
  const forecastValues: string[] = Array(numProjections).fill("-");

  projections.forEach((projection, idx) => {
    const dataObj =
      projection === ForecastProjection.forecastDelta
        ? delta
        : projection === ForecastProjection.forecastLower
          ? lowerBoundData
          : projection === ForecastProjection.forecastUpper
            ? upperBoundData
            : projection === ForecastProjection.forecastDeltaPerc
              ? percentChangeData
              : data;

    const dataEntry = Object.values(dataObj)[0];

    const { data: forecastDataFrames = [] } = dataEntry || {};

    const forecastFields = getFieldsFromLabels(forecastDataFrames, labels);
    const forcastValue = forecastFields?.[1]?.data[0] || null;

    if (forcastValue !== null && forcastValue !== undefined) {
      forecastValues[idx] = forcastValue.toFixed(2);
    }
  });

  return forecastValues;
};
