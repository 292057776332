import { SequenceDiagram, SequenceDiagramRequest, SequenceDiagramsUpsertRequest } from "../../onboarding";
import { VerticalIdentifier } from "../../use-case-catalog";
import { CompareOperator } from "../../operationalise";
import { GenerationFragment } from "./generation-fragment";
import { KPIWithSlicesFragment, VizOption } from "./low-level-fragment";
import { ChatBotStrategy, ConversationResponse } from "./common";

export interface ConversationEntry {
  chatId: string;

  message: string;
  response: ConversationResponse;

  timeStampInMillis: string;
  bicycleMessageTimeMillis: string;
}

export interface Conversation {
  chatId: string;
  userId: string;

  conversationEntries: ConversationEntry[];
  strategy: ChatBotStrategy;
  page: number;
  nextPage: number;
}

export interface AllConversationsList {
  conversations: Conversation[];
}

export interface ConversationRequest {
  chatId?: string;
  userId?: string;

  page?: number;
  limit?: number;
  useCaseId?: string;
}

export interface OnboardingRequestContext {
  vertical?: string;
  subVertical?: string;
  companyName?: string;
  useCaseSummary?: string;
}

export interface ChatRequest {
  chatId?: string;
  conversationContext?: ConversationContext;
  message?: string;

  sequenceDiagram?: SequenceDiagram;
  sequenceDiagramRequest?: Partial<SequenceDiagramRequest>;
  sequenceDiagramUpsertRequest?: SequenceDiagramsUpsertRequest;
  insightOnboardingInput?: InsightOnboardingInput;
  useCaseOnboardingInput?: UseCaseOnboardingInput;
  onboardingRequestContext?: OnboardingRequestContext;
  dashboardCreationContext?: DashboardCreationContext;
}

export interface DashboardCreationContext {
  name: string;
  description: string;
  preferredViz: VizOption[];
  compareOperator: CompareOperator;
  kpiFragments: KPIWithSlicesFragment[];
}

export interface UseCaseOnboardingInput {
  useCaseInputType?: UseCaseInputType;
  customUseCaseInput?: CustomUseCaseInput;
}

export interface CustomUseCaseInput {
  customUseCase: string;
  customUseCaseDescription?: string;

  excludeInsights?: boolean;
}

export enum UseCaseInputType {
  SUGGEST_USECASE,
  CUSTOM_USECASE
}

export enum InputType {
  REGENERATE_INSIGHT,
  EXTRA_INSIGHT,
  CUSTOM_INSIGHT
}

export interface InsightOnboardingInput {
  inputType: InputType;
  extraInsightCount?: number;
  customInsightDescription?: string;
}

export type MessageSuggestion = {
  title: string;
  description: string;
  icon: string;
  prompt: string;
  category: string;
  tags: string[];
};

export interface MessageSuggestionResponseEntry {
  category: string;
  suggestions: MessageSuggestion[];
}

export interface MessageSuggestionsResponse {
  suggestions: MessageSuggestionResponseEntry[];
}

export enum ConversationContext {
  UNDEFINED = "UNDEFINED",
  AGENT_CHAT = "AGENT_CHAT",
  ONBOARDING = "ONBOARDING",
  OPERATIONALIZE = "OPERATIONALIZE",
  MODELLING = "MODELLING",
  MODELLING_RECOMMENDATION = "MODELLING_RECOMMENDATION",
  DATA_ONBOARDING = "DATA_ONBOARDING",
  DASHBOARD = "DASHBOARD"
}

export enum InputPromptType {
  NA = "NA",
  OP10ZE = "OP10ZE",
  WIDGET = "WIDGET"
}

export type GenerateFromInputPromptRequest = {
  inputPrompt: string;
  promptType: InputPromptType;

  useCaseId?: string;
  verticalIdentifier?: VerticalIdentifier;

  chatId: string;
  generationFragmentContext?: GenerationFragment;
};
