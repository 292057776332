import React, { FC, useMemo, useCallback } from "react";
import { IncSelectOption, IncMultiSelectCheckBox } from "@inception/ui";
import { last } from "lodash";
import { WidgetQuerySchema } from "../../../../services/api/explore";
import { ENTITY_TAG } from "../../../../utils";
import { USFieldWidgetUtils } from "../USFieldWidgetUtils";

export interface AggregateTagSelectorProps {
  label?: string;
  metricId: string;
  entityType: string;
  aggregateTags: string[];
  cardinality: number;
  onAggregateTagsChange: (nAggTag: string[]) => void;
  querySchema: WidgetQuerySchema[];
}

type Option = IncSelectOption<string>;

export const AggregateTagSelector: FC<AggregateTagSelectorProps> = props => {
  const {
    label = "",
    aggregateTags = [],
    onAggregateTagsChange,
    querySchema,
    metricId,
    entityType,
    cardinality
  } = props;

  const isQuerySchemaLoading = !querySchema.length;
  const aggTagOptions = useMemo<Option[]>(() => {
    const filQuerySchema = querySchema.filter(qs => qs.metricId === metricId);
    const qsEntry = last(filQuerySchema);

    if (qsEntry) {
      const options: Option[] = [];

      const { sliceSet } = qsEntry;
      const { slices } = sliceSet;

      let implicitSliceExists = false;
      slices.forEach(slice => {
        const { entityTypeName, tagName, fieldType } = slice;
        const isNumericField = USFieldWidgetUtils.isHighCardinalityNumericMetric(fieldType, cardinality);

        if (!isNumericField) {
          const isImplicitSlice = tagName === ENTITY_TAG;

          const fTagName = isImplicitSlice ? entityTypeName : tagName;
          const label = fTagName;

          implicitSliceExists = isImplicitSlice || implicitSliceExists;

          options.push({
            label,
            value: fTagName,
            data: tagName
          });
        }
      });

      if (!implicitSliceExists) {
        options.splice(0, 0, {
          label: entityType,
          value: entityType,
          data: ENTITY_TAG
        });
      }

      return options;
    }
    return [];
  }, [cardinality, entityType, metricId, querySchema]);

  const onOptChange = useCallback(
    (opt: Option[]) => {
      const tags = opt.map(o => o.data);
      onAggregateTagsChange(tags);
    },
    [onAggregateTagsChange]
  );

  const values = useMemo(() => {
    const selectedOptions: Array<IncSelectOption<string>> = [];
    aggTagOptions.forEach(opt => {
      if (aggregateTags.includes(opt.data)) {
        selectedOptions.push(opt);
      }
    });
    return selectedOptions;
  }, [aggTagOptions, aggregateTags]);

  return (
    <IncMultiSelectCheckBox
      defaultOptions={values}
      disablePopper
      disabled={isQuerySchemaLoading}
      hideOptionsCount
      label={label}
      onChange={onOptChange}
      options={aggTagOptions}
      placeholder="Select..."
      renderInCard
      showAsPills
      showSelectedOptions
      skipAllOption
      wrapperClass="aggregate-tag-selector"
    />
  );
};
