import React, { CSSProperties, useCallback, useState } from "react";
import { IncFaIcon, IncFaIconName, generateId, IncButton, IncClickAway, IncPopper } from "@inception/ui";
import { transparentize } from "polished";
import { LoadingSpinner, VerticallyCenteredRow } from "..";
import { StatPosition } from "../../services/api/business-process";
import { ActionPositions, ActionProps, NodeDataProps, StatProps, WorkflowNodeProps } from "./types";

export const IncNodeToolTemplate: React.FC<ActionProps> = props => {
  const { id, data, showTitle, actionClass, onClick } = props;

  const onClickHandler = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      onClick(event, id, data);
    },
    [id, data, onClick]
  );

  return (
    <>
      <a
        className={`action-info  d-inline-block ${actionClass}`}
        href={`#${id}_itt`}
        key={`${id}_itt`}
        onClick={onClickHandler}
      >
        {data?.icon && (
          <span className="icon d-inline-block bi">
            <IncFaIcon iconName={data.icon as IncFaIconName} />{" "}
          </span>
        )}
        {showTitle && <span className="text d-inline-block">{data.label}</span>}
      </a>
    </>
  );
};

const IncNodeStatTemplate: React.FC<StatProps> = props => {
  const { label, value, type, icon } = props;
  return (
    <>
      <div className="stat-info d-inline">
        <span className="text d-inline">
          {label}
          {value}
        </span>
        {type === "texticon" && icon && (
          <span className="icon d-inline">
            <IncFaIcon iconName={icon as IncFaIconName} />{" "}
          </span>
        )}
      </div>
    </>
  );
};

/**
 *
 * @param props
 * @returns
 */
const IncNodeTitleTemplate: React.FC<NodeDataProps> = props => {
  const { icon, id, label, stats, actions, labelRenderer } = props;

  const [menuShow, setMenuShow] = useState(false);
  const entityRef = React.createRef<HTMLButtonElement>();
  const [, setAnchorEl] = useState(null);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
    setMenuShow(true);
  };

  const handleMenuClose = () => {
    setMenuShow(false);
    setAnchorEl(null);
  };

  const statistic: StatProps = stats?.find(stat => stat?.position === StatPosition.HEADER);

  const menuActions = actions?.filter(action => {
    if (action.position === ActionPositions.ACTION) {
      return true;
    }

    return false;
  });
  const actionsExist = Boolean(menuActions?.length);

  const renderStatsOrActions = Boolean(statistic) || actionsExist;

  return (
    <>
      <h4 className="card-title d-flex align-items-center justify-content-between">
        <VerticallyCenteredRow className="inc-flex-center px-2 title-block">
          {icon && (
            <span className="icon d-inline">
              <IncFaIcon iconName={icon as IncFaIconName} />
            </span>
          )}
          <VerticallyCenteredRow>{labelRenderer ? labelRenderer(props) : label}</VerticallyCenteredRow>
        </VerticallyCenteredRow>

        {renderStatsOrActions && (
          <div className="align-items-center justify-content-end px-2">
            {statistic && (
              <span className="px-2">
                <IncNodeStatTemplate
                  icon={statistic.icon}
                  type={statistic.type}
                  value={statistic.value}
                />
              </span>
            )}

            {actionsExist && (
              <IncClickAway onClickAway={handleMenuClose}>
                {(ref: any) => (
                  <IncPopper
                    anchorEl={entityRef as any}
                    key={`${id}_pop`}
                    offset={{
                      x: 60,
                      y: -80
                    }}
                    placement="top"
                    ref={ref}
                    show={menuShow}
                  >
                    <div className="actions">
                      {
                        <ul className="dropdown-menu bg-dark d-block position-static mx-0 border-0 shadow w-220px">
                          {actions?.map(
                            (action: ActionProps) =>
                              action?.position === ActionPositions.ACTION && (
                                <li key={generateId()}>
                                  <IncNodeToolTemplate
                                    actionClass="text-light bg-dark dropdown-item d-flex gap-2 align-items-center"
                                    key={`${action.data.label}_act`}
                                    showTitle={true}
                                    {...action}
                                  />
                                </li>
                              )
                          )}
                        </ul>
                      }
                    </div>
                  </IncPopper>
                )}
              </IncClickAway>
            )}
            {actionsExist && (
              <IncButton
                className="action d-inline"
                color="link"
                iconName="ellipsis-v"
                onClick={handleMenuOpen}
                ref={entityRef}
              />
            )}
          </div>
        )}
      </h4>
    </>
  );
};

/**
 *
 * @param props
 * @returns
 */

export const IncNodeTemplate: React.FC<WorkflowNodeProps> = props => {
  const { data, type } = props;

  const { status, stats = [], actions, nodeActions, color, style: pStyle = {} } = data || {};

  const statistics = (stats || []).filter(
    stat => stat.position === StatPosition.STAT_ROW || stat.position === StatPosition.UNSET || !stat.position
  );

  let borderClass = "";
  switch (status) {
    case "error":
      borderClass = "border-danger";
      break;

    default:
      break;
  }
  const nodeRef: string = generateId();

  const cardActions = actions?.filter(action => {
    if (action.position === ActionPositions.ENTITY) {
      return true;
    }

    return false;
  });

  const className = `sequence-node-template card justify-content-between align-items-center ${type} ${borderClass}`;
  const style: CSSProperties = !color
    ? pStyle
    : {
        ...pStyle,
        border: `1px solid ${color}`,
        background: transparentize(0.8, color)
      };

  return (
    <>
      <div
        className={className}
        key={nodeRef}
        style={style}
      >
        <IncNodeTitleTemplate {...props.data} />

        {statistics.length > 0 && (
          <div className="d-flex stats-container">
            <div className="stats d-flex flex-row justify-content-center align-items-center">
              {statistics.map((stat: StatProps) =>
                stat.loading ? (
                  <LoadingSpinner
                    key={stat.id || generateId()}
                    titleText=" "
                  />
                ) : (
                  <IncNodeStatTemplate
                    className={stat.className}
                    icon={stat.icon}
                    key={stat.id || generateId()}
                    type={stat.type}
                    value={stat.value}
                  />
                )
              )}
            </div>
          </div>
        )}

        {cardActions && (
          <div className="d-flex node-toolbar">
            <div className="node-actions d-flex justify-content-center align-items-center">
              {data?.actions?.map(
                (action: ActionProps) =>
                  action?.position === ActionPositions.ENTITY && (
                    <IncNodeToolTemplate
                      key={generateId()}
                      {...action}
                    />
                  )
              )}
            </div>
          </div>
        )}

        {Boolean(nodeActions) && <div className="node-actions-toolbar">{nodeActions}</div>}
      </div>
    </>
  );
};
