import React, { createContext, useReducer, useContext } from "react";
import { DiscoverMappingContextState, DiscoverMappingStateAction } from "../types";
import { EventGroupResponse } from "../../../platform/services/api/auto-discovery/types";
import discoverMappingReducer, { initDiscoverMappingState } from "./discoverMappingReducer";

interface DiscoverMappingContextProps {
  state: DiscoverMappingContextState;
  dispatch: React.Dispatch<DiscoverMappingStateAction>;
}

const DiscoverMappingContext = createContext<DiscoverMappingContextProps>(null);

interface DiscoverMappingProviderProps {
  mappings: EventGroupResponse;
  requestId: string;
  sourceTypeIcons: Record<string, string>;
  children: React.ReactNode;
}

export function DiscoverMappingProvider(props: DiscoverMappingProviderProps) {
  const [state, dispatch] = useReducer(
    discoverMappingReducer,
    initDiscoverMappingState(props?.mappings, props?.requestId, props?.sourceTypeIcons)
  );

  return (
    <DiscoverMappingContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      {props.children}
    </DiscoverMappingContext.Provider>
  );
}

export const useDiscoverMappingStore = () => useContext(DiscoverMappingContext);
