import React, { FC, useEffect, useMemo, useCallback } from "react";
import {
  ExploreEntityFilter,
  TriageConfigDTO,
  TriageImpactedWidgetConfig,
  UserServiceFilterList,
  ImpactedWidgetList,
  TriagePathConfigType,
  BizFieldPredicate,
  UserServiceFilterExpression
} from "../../../services/api/explore";
import { OpSchema } from "../../../services/api/operationalise";
import { TimeRange } from "../../../core";
import { useFetchTriageConfig } from "../../../services/api/explore/hooks";
import { logger } from "../../../core/logging/Logger";
import { ImpactedWidgetsRenderer } from "../impact-widget";
import { ENTITY_TAG, getBizFieldPredicateForEntityField } from "../../../utils";
import LoadingSpinner from "../../Loading/Loading";
import { INCIDENT_STATUS_KEY } from "../types";
import { IncidentDataResponseItem } from "../../../services/api/CommonAlerts";
import { DrilldownSubRendererProps } from "../field-drilldown/types";

interface Props {
  entityType: string;
  cohortId: string;
  widgetId: string;
  opConfigId: string;
  opSchema: OpSchema;
  incidentId: string;
  triageFetchLabels: Record<string, string>;
  op10zeIncidentData?: IncidentDataResponseItem;

  entityFilters?: ExploreEntityFilter[];
  eventFieldFilters?: UserServiceFilterList;

  tagsData: Array<Record<string, string>>;
  accessorHeaderMap: Record<string, string>;

  isLoading: boolean;

  lookupData?: Record<string, string>;
  entityTags?: string[];
  timeRange?: TimeRange;

  impactedWidgetsConfigs?: TriageConfigDTO[];
  onFetchImpactedWidgets?: (impactedWidgetsConfigs: TriageConfigDTO[]) => void;

  onAddEntityFilter?: (entityFilter: BizFieldPredicate) => void;
  onAddEventFilter?: (eventFilter: UserServiceFilterExpression) => void;

  drilldownProps?: DrilldownSubRendererProps;
  generateDemoData?: boolean;
  hideGanttChartRow?: boolean;
}

export const ImpactWidgetWrapper: FC<Props> = props => {
  const {
    cohortId,
    entityType,
    triageFetchLabels,
    widgetId,
    incidentId,
    tagsData,
    accessorHeaderMap,
    isLoading,
    lookupData,
    entityTags,
    opConfigId,
    timeRange,
    onFetchImpactedWidgets,
    impactedWidgetsConfigs: pImpactedWidgetsConfigs,
    entityFilters,
    eventFieldFilters,
    opSchema,
    onAddEntityFilter,
    onAddEventFilter,
    op10zeIncidentData,
    drilldownProps,
    hideGanttChartRow = false,
    generateDemoData
  } = props;

  const tagsToDisplay = useMemo(
    () => Object.keys(tagsData[0] || {}).filter(k => k !== INCIDENT_STATUS_KEY),
    [tagsData]
  );

  const {
    data: fImpactedWidgetsConfigs,
    isFetching: isImpactedWidgetsFetching,
    isError: isImpactedWidgetsFetchError,
    fetchTriageConfigs: fetchImpactedWidgets,
    error: impactedWidgetsFetchError
  } = useFetchTriageConfig();

  const impactedWidgetsConfigs = useMemo(
    () => pImpactedWidgetsConfigs || fImpactedWidgetsConfigs,
    [fImpactedWidgetsConfigs, pImpactedWidgetsConfigs]
  );

  const impactedWidgetsLists = useMemo(() => {
    const configs = (impactedWidgetsConfigs as TriageImpactedWidgetConfig[]) || [];

    const impactedWidgetsLists: ImpactedWidgetList[] = [];
    configs.forEach(({ impactedWidgets }) => {
      if (impactedWidgets) {
        impactedWidgetsLists.push(impactedWidgets);
      }
    });

    return impactedWidgetsLists;
  }, [impactedWidgetsConfigs]);

  useEffect(() => {
    if (!isImpactedWidgetsFetching && isImpactedWidgetsFetchError) {
      logger.error("Alert Summary", "Failed to fetch impacted", impactedWidgetsFetchError);
    }
  }, [impactedWidgetsFetchError, isImpactedWidgetsFetchError, isImpactedWidgetsFetching]);

  useEffect(() => {
    if (!pImpactedWidgetsConfigs?.length) {
      fetchImpactedWidgets(entityType, TriagePathConfigType.impactedWidgets, cohortId, widgetId, triageFetchLabels);
    }
  }, [cohortId, entityType, fetchImpactedWidgets, pImpactedWidgetsConfigs, triageFetchLabels, widgetId]);

  useEffect(() => {
    if (onFetchImpactedWidgets && fImpactedWidgetsConfigs) {
      onFetchImpactedWidgets(fImpactedWidgetsConfigs);
    }
  }, [fImpactedWidgetsConfigs, onFetchImpactedWidgets]);

  const { alertingEventFields, frequency, lookback: lookBack } = opSchema;

  const onAddFilter = useCallback(
    (value: string, tagName: string, displayValue: string) => {
      const usField = (alertingEventFields?.slices || []).find(sl => sl.tagName === tagName);
      if (usField) {
        const { userServiceField } = usField;
        const { entityField } = userServiceField;

        if (tagName === ENTITY_TAG) {
          if (!entityField) {
            logger.error("ImpactedWidget", "Error applying implicit tag filter. EntityField not found", entityField);
          } else {
            onAddEntityFilter && onAddEntityFilter(getBizFieldPredicateForEntityField(entityField, displayValue));
          }
        } else {
          onAddEventFilter &&
            onAddEventFilter({
              field: userServiceField,
              operator: "=",
              value
            });
        }
      } else {
        logger.error(
          "ImpactedWidget",
          "Error applying filter. Matching USField not found in alertingEventFields",
          alertingEventFields
        );
      }
    },
    [alertingEventFields, onAddEntityFilter, onAddEventFilter]
  );

  return (
    <div className="inc-flex-column impact-widget-renderer">
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          {isImpactedWidgetsFetching && <LoadingSpinner titleText="Fetching impacted widgets..." />}
          {!isImpactedWidgetsFetching && (
            <ImpactedWidgetsRenderer
              accessorHeaderMap={accessorHeaderMap}
              drilldownProps={drilldownProps}
              entityFilters={entityFilters}
              entityTags={entityTags}
              eventFieldFilters={eventFieldFilters}
              frequency={frequency}
              generateDemoData={generateDemoData}
              hideGanttChartRow={hideGanttChartRow}
              hideStatusColumn
              impactedWidgetsLists={impactedWidgetsLists}
              incidentId={incidentId}
              lookBack={lookBack}
              lookupData={lookupData}
              onAddFilter={onAddFilter}
              op10zeIncidentData={op10zeIncidentData}
              opConfigId={opConfigId}
              tagsData={tagsData}
              tagsToDisplay={tagsToDisplay}
              timeRange={timeRange}
            />
          )}
        </>
      )}
    </div>
  );
};
