import { IncInfoIcon, IncToolTip } from "@inception/ui";
import React from "react";
import { CypressConstants } from "@bicycle/tests";
import { FieldPickerRow } from "../../field-picker";

function FieldLabelRenderer(fieldLabel: string, rowData: FieldPickerRow<"userServiceField">) {
  const bizFieldName = rowData.field.displayBizEntityFieldName;
  const maxLen = 23;
  const exceedsMaxLen = fieldLabel.length > maxLen;
  const label = exceedsMaxLen ? `${fieldLabel.slice(0, maxLen)}...` : fieldLabel;

  const LabelComp = <span>{label}</span>;
  const { fieldPickerRow } = CypressConstants.components.FieldPickerV1.attributes;
  const dataCy = fieldPickerRow(rowData.field.fieldName);
  return (
    <div
      className="inc-flex-row"
      data-cy={dataCy}
    >
      {exceedsMaxLen ? (
        <IncToolTip
          noWrap
          titleText={fieldLabel}
        >
          {LabelComp}
        </IncToolTip>
      ) : (
        LabelComp
      )}

      {bizFieldName && (
        <IncToolTip titleText={bizFieldName}>
          <IncInfoIcon />
        </IncToolTip>
      )}
    </div>
  );
}

export default FieldLabelRenderer;
