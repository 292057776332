import React, { FC, useCallback, useMemo } from "react";
import { StaticThresholdDef, CompareOperator, PeerThreshold } from "../../../services/api/operationalise";
import {
  FieldPickerContextDTO,
  FieldPickerOptionData,
  UserServiceField,
  UserServiceFieldSlice,
  BizFieldWithMeta,
  UserServiceFieldSliceSet,
  SliceSet,
  PickerFieldType,
  DemoDataParams
} from "../../../services/api/explore";
import { FieldPickerContainer, VerticallyCenteredRow } from "../../../components";
import { FieldPickerUtils } from "../../../utils";
import { PeerSelector } from "../../../operationalise-v2/components";
import { ThresholdContext } from "./types";

interface Props {
  comparator: CompareOperator;
  thresholdDef: StaticThresholdDef;
  onChange: (thresholdDef: StaticThresholdDef) => void;
  pickerContext: ThresholdContext;
  metricOrFieldName: string;
  metricSliceSet: UserServiceFieldSliceSet | SliceSet;
  isEventOperationalize: boolean;
  demoDataParams?: DemoDataParams;
}

export const PeerGroupThresholdEditorV2: FC<Props> = props => {
  const { onChange, thresholdDef, pickerContext, metricSliceSet, demoDataParams } = props;

  const { peerThreshold } = thresholdDef;

  const { entityTypeId, eventTypeId, isFieldOperationalize } = pickerContext;

  const { usFieldSlice, bizFieldSlice, peerOfSlice } = peerThreshold;

  const onSlicesChange = useCallback(
    (selFields: FieldPickerOptionData[]) => {
      const bizFields: BizFieldWithMeta[] = [];
      const usFields: UserServiceField[] = [];

      selFields.forEach(({ type, payload }) => {
        if (type === "bizEntityField") {
          bizFields.push(payload as BizFieldWithMeta);
        } else if (type === "userServiceField") {
          usFields.push(payload as UserServiceField);
        }
      });

      const usFieldSlices = usFields.map(
        (usField): UserServiceFieldSlice => ({
          tagName: FieldPickerUtils.getPromSanitizedUSFName(usField),
          userServiceField: usField
        })
      );

      const nPeerThreshold = {
        ...peerThreshold,
        usFieldSlice: {
          slices: usFieldSlices
        },
        bizFieldSlice: {
          bizFields
        }
      };

      if (isFieldOperationalize) {
        delete nPeerThreshold.bizFieldSlice;
      } else {
        delete nPeerThreshold.usFieldSlice;
      }

      onChange({
        ...thresholdDef,
        peerThreshold: nPeerThreshold
      });
    },
    [isFieldOperationalize, onChange, peerThreshold, thresholdDef]
  );

  const onPeerThresholdChange = useCallback(
    (peerThreshold: PeerThreshold) => {
      onChange({
        ...thresholdDef,
        peerThreshold
      });
    },
    [onChange, thresholdDef]
  );

  const sliceFields = useMemo<FieldPickerOptionData[]>(() => {
    const bizFields = (bizFieldSlice?.bizFields || []).map(
      (bizField): FieldPickerOptionData => ({
        type: "bizEntityField",
        payload: bizField
      })
    );

    const usFields = (usFieldSlice?.slices || []).map(
      (usFieldSlice): FieldPickerOptionData => ({
        type: "userServiceField",
        payload: usFieldSlice.userServiceField
      })
    );

    return isFieldOperationalize ? usFields : bizFields;
  }, [bizFieldSlice?.bizFields, isFieldOperationalize, usFieldSlice?.slices]);

  const contextDto = useMemo<FieldPickerContextDTO>(() => {
    const entityType = isFieldOperationalize ? entityTypeId : peerOfSlice?.entityTypeName || entityTypeId;

    return {
      entityType,
      userServices: eventTypeId
        ? [
            {
              userServiceEntityId: eventTypeId
            }
          ]
        : [],
      entityId: entityType ? null : eventTypeId,
      entityName: null
    };
  }, [entityTypeId, eventTypeId, isFieldOperationalize, peerOfSlice?.entityTypeName]);

  const fieldTypes = useMemo<PickerFieldType[]>(
    () => (isFieldOperationalize ? ["userServiceField"] : ["bizEntityField"]),
    [isFieldOperationalize]
  );

  return (
    <div className="peer-group-threshold">
      <VerticallyCenteredRow className="marginLt8 marginRt8 inc-label-common">for</VerticallyCenteredRow>

      <PeerSelector
        demoDataParams={demoDataParams}
        disableSliceChangeForField
        metricSliceSet={metricSliceSet}
        onChange={onPeerThresholdChange}
        peerThreshold={peerThreshold}
        pickerContext={pickerContext as any}
      />

      <VerticallyCenteredRow className="marginLt8 marginRt8 inc-label-common">with same</VerticallyCenteredRow>

      <FieldPickerContainer
        className="peer-slice-field-selector"
        demoDataParams={demoDataParams}
        fieldPickerContextDto={contextDto}
        fieldTypes={fieldTypes}
        hideLabel
        isMulti
        label=""
        onChange={onSlicesChange}
        selectedOptions={sliceFields}
      />
    </div>
  );
};
