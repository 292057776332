import { useState, useMemo, useRef, useCallback } from "react";
import Axios, { CancelTokenSource } from "axios";
import { isEqual } from "lodash";
import {
  ImpactedWidget,
  ImpactWidgetDataRequest,
  alertApiService,
  ImpactWidgetDataPostAgg,
  PostAggProjection,
  FunnelData,
  FunnelFilters,
  TimeObjUnit
} from "../../../services/api/explore";
import { BizEntityDataResult, bizEntityDataTransformer } from "../../../dashboard/widgets/utils";

export const useFetchImpactedWidgetsData = (
  impactedWidgets: ImpactedWidget[],
  incidentId: string,
  opConfigId: string,
  groupBy: string[],
  entityFilters: ImpactWidgetDataRequest["entityFilters"],
  userServiceFilters: ImpactWidgetDataRequest["metricUserServiceFilters"],
  startTimeMillis: number,
  endTimeMillis: number,
  groupTags: string[],
  overTimeAgg?: ImpactWidgetDataPostAgg,
  intervalSecs?: number,
  projections?: PostAggProjection[],
  compareTimeShiftMillis?: number,
  impactedFunnelFilter?: FunnelFilters,
  generateDemoData?: boolean,
  sortBy: PostAggProjection = "current"
) => {
  const impactedWidgetsIdsRef = useRef<string[]>([]);
  const impactedWidgetIds = useMemo(() => {
    const pIds = impactedWidgetsIdsRef.current;
    const nIds = impactedWidgets.map(imp => imp?.id || "");

    return !isEqual(nIds, pIds) ? nIds : pIds;
  }, [impactedWidgets]);

  const initResult = useMemo<Result>(
    () => getBaseState(impactedWidgetIds, groupBy, groupTags),
    [groupBy, groupTags, impactedWidgetIds]
  );

  const cancelTokenSourceRef = useRef<CancelTokenSource>(Axios.CancelToken.source());

  const [resultsByImpactedWidget, setResultsByImpactedWidget] = useState<Result>(initResult);

  const beforeRefetch = useCallback(() => {
    setResultsByImpactedWidget(getBaseState(impactedWidgetIds, groupBy, groupTags, true));

    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel();
    }

    const cancelTokenSource = Axios.CancelToken.source();
    const cancelToken = cancelTokenSource.token;

    cancelTokenSourceRef.current = cancelTokenSource;

    return cancelToken;
  }, [groupBy, groupTags, impactedWidgetIds]);

  const refetch = useCallback(async () => {
    const cancelToken = beforeRefetch();

    const promisePayloads: PromisePayload[] = getPromisePayloads(
      impactedWidgets,
      incidentId,
      opConfigId,
      groupBy,
      entityFilters,
      userServiceFilters,
      groupTags,
      sortBy,
      overTimeAgg,
      projections,
      compareTimeShiftMillis,
      impactedFunnelFilter,
      generateDemoData
    );

    const promises = promisePayloads.map(async payloadInfo => {
      const { impactWidgetId, payload, resultEntryKey } = payloadInfo;

      let bizEntityData: BizEntityDataResult;
      let funnelData: FunnelData;
      let isError = false;
      let errorMsg = "";
      const isFetching = false;

      try {
        const { data, error, message, cancelled } = await alertApiService.getImpactedWidgetData(
          payload,
          startTimeMillis,
          endTimeMillis,
          intervalSecs,
          cancelToken
        );

        if (!cancelled) {
          isError = error;
          errorMsg = error ? message.toString() : "";

          if (data && !isError) {
            const transformed = bizEntityDataTransformer([
              {
                data: [data]
              }
            ]);
            const { data: transformedDatum = [] } = transformed[0];
            bizEntityData = transformedDatum?.[0];
            funnelData = data.funnelResult;
          }
        }
      } catch (error) {
        isError = true;
        errorMsg = error.toString();
      } finally {
        setResultsByImpactedWidget(prev => ({
          ...prev,
          [impactWidgetId]: {
            ...prev[impactWidgetId],
            [resultEntryKey]: {
              data: bizEntityData,
              funnelData,
              error: errorMsg,
              isError,
              isFetching
            }
          }
        }));
      }
    });

    await Promise.allSettled(promises);
  }, [
    beforeRefetch,
    impactedWidgets,
    incidentId,
    opConfigId,
    groupBy,
    entityFilters,
    userServiceFilters,
    groupTags,
    sortBy,
    overTimeAgg,
    projections,
    compareTimeShiftMillis,
    impactedFunnelFilter,
    generateDemoData,
    startTimeMillis,
    endTimeMillis,
    intervalSecs
  ]);

  return {
    resultsByImpactedWidget,
    refetch
  };
};

type Result = Record<string, ImpactedWidgetsDataEntryMap>;
type PromisePayload = {
  impactWidgetId: string;
  payload: ImpactWidgetDataRequest;
  resultEntryKey: string;
};

export type ImpactedWidgetsDataEntryMap = Record<string, ImpactedWidgetsDataEntry>;

export type ImpactedWidgetsDataEntry = {
  isFetching: boolean;
  isError: boolean;
  error: string;
  data: BizEntityDataResult;
  funnelData?: FunnelData;
};

const getBaseState = (impactedWidgetIds: string[], groupBy: string[], groupTags: string[], isFetching = false) => {
  const groupByStr = getGroupKey(groupBy);
  const groupTagsStr = getGroupKey(groupTags);

  const initResult: Result = {};

  impactedWidgetIds.forEach(id => {
    initResult[id] = {
      [groupByStr]: {
        data: null,
        error: null,
        isError: false,
        isFetching
      },
      [groupTagsStr]: {
        data: null,
        error: null,
        isError: false,
        isFetching
      }
    };
  });

  return initResult;
};

const getPromisePayloads = (
  impactedWidgets: ImpactedWidget[],
  incidentId: string,
  opConfigId: string,
  groupBy: string[],
  entityFilters: ImpactWidgetDataRequest["entityFilters"],
  userServiceFilters: ImpactWidgetDataRequest["metricUserServiceFilters"],
  groupTags: string[],
  sortBy: PostAggProjection,
  overTimeAgg?: ImpactWidgetDataPostAgg,
  projections?: PostAggProjection[],
  compareTimeShiftMillis?: number,
  impactedFunnelFilter?: FunnelFilters,
  generateDemoData?: boolean
) => {
  const promisePayloads: PromisePayload[] = [];

  const groupByKey = getGroupKey(groupBy);
  const groupTagsKey = getGroupKey(groupTags);

  const groupBysUnequal = groupByKey !== groupTagsKey;

  impactedWidgets.forEach(impactedWidget => {
    const { id } = impactedWidget;
    const payload: ImpactWidgetDataRequest = {
      entityFilters: entityFilters || [],
      groupBy,
      impactedWidget,
      incidentId,
      opConfigId,
      metricUserServiceFilters: userServiceFilters || {},
      bins: overTimeAgg?.bins,
      timeDuration: overTimeAgg?.timeDuration,
      sortSpec: {
        sortBy,
        limitSpec: {
          function: "top",
          limit: 1000
        }
      },
      projections: projections?.length ? projections : [],
      timeShiftCompare: compareTimeShiftMillis
        ? {
            unit: TimeObjUnit.seconds,
            value: compareTimeShiftMillis / 1000
          }
        : null,
      impactedFunnelFilter,
      generateDemoData
    };

    promisePayloads.push({
      resultEntryKey: groupByKey,
      impactWidgetId: id,
      payload
    });

    if (groupBysUnequal) {
      promisePayloads.push({
        resultEntryKey: groupTagsKey,
        impactWidgetId: id,
        payload: {
          ...payload,
          groupBy: [...groupTags]
        }
      });
    }
  });

  return promisePayloads;
};

export const getGroupKey = (arr: string[]) => arr.join("-");
