import React, { useCallback } from "react";
import { EdgeProps, useStore } from "react-flow-renderer";
import { getEdgeParams } from "./utils";

type GetStraightPathParams = {
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
};

export const getStraightPath = ({ sourceX, sourceY, targetX, targetY }: GetStraightPathParams) =>
  `M ${sourceX} ${sourceY} Q ${targetX} ${targetY} ${targetX} ${targetY}`;

export default function IncWorkflowConnector({ id, source, target, markerEnd, style }: EdgeProps) {
  const sourceNode = useStore(useCallback(store => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback(store => store.nodeInternals.get(target), [target]));
  if (!sourceNode || !targetNode) {
    return null;
  }
  const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);
  const edgePath = getStraightPath({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty
  });

  return (
    <path
      className="react-flow__edge-path"
      d={edgePath}
      id={id}
      markerEnd={markerEnd}
      style={style}
    />
  );
}
