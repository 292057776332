import { inceptionDarkColorPalette } from "@inception/ui-styles";
import { CurrencyType } from "@inception/ui";
import { escape, isEqual } from "lodash";
import { DataFrame, DataType } from "../../../../../core";
import { UserServiceFieldWithMeta, WidgetResponseDTO } from "../../../../../services/api/explore";
import getChartColor from "../../../../../components/charts/colors";
import { dataTypeManager, FormatterFnOptions, entityEnricherRegistry } from "../../../../../utils";
import { USFieldWidgetUtils } from "../../USFieldWidgetUtils";
import { WidgetTriagePayload } from "../../../../BaseWidgetActions";
import { SeriesTagsDataWithMeta } from "../../../../../components/time-series/types";
import { TagFilterSelectionBySliceSet, TagFilterBySliceSet } from "../../../../../biz-entity";
import { AdhocSummaryContext } from "../../../../../core/data/types/ExploreTriageTypes";

export const getFilteredDataFrames = (
  dataframes: DataFrame[],
  filters: string[],
  aggregatedTag: string,
  areFiltersEnhanced?: boolean
): DataFrame[] =>
  dataframes.filter(df => {
    const { labels, eLabels } = df;

    const rawValue = labels[aggregatedTag];
    const enhancedValue = eLabels[aggregatedTag] || rawValue;

    const filterValue = areFiltersEnhanced ? enhancedValue : rawValue;

    return filters.length ? filters.includes(filterValue) : true;
  });

export const getUSFieldChartColor = (
  dataType: UserServiceFieldWithMeta["userServiceField"]["dataType"],
  serieName: string,
  serieIndex: number,
  negateColors?: boolean
): string => {
  if (dataType !== "BOOLEAN") {
    return getChartColor(serieIndex);
  }

  const { palette } = inceptionDarkColorPalette;
  const { chartNegative, chartPositive } = palette;

  const isTrue = serieName.toString() === "true";
  const isFalse = serieName.toString() === "false";

  if (!(isTrue || isFalse)) {
    return getChartColor(serieIndex);
  }

  const trueColor = negateColors ? chartNegative : chartPositive;
  const falseColor = negateColors ? chartPositive : chartNegative;

  return isTrue ? trueColor : falseColor;
};

export const getFormattedValue = (
  fieldName: string,
  value: string | number,
  dataType: DataType,
  currencyType: CurrencyType
): string => {
  const { getFormattedValue } = dataTypeManager.getDataTypeDescriptor(dataType);
  const formatterOptions: FormatterFnOptions = {
    numberFormatOptions: {
      compact: true,
      currencyType
    },
    percentFormatOptions: {
      precision: 2
    }
  };

  const finValue =
    typeof value === "string" ? value : fieldName === USFieldWidgetUtils.durationFieldName ? value : value;

  const formattedValues = typeof value === "string" ? value : getFormattedValue(finValue, formatterOptions);

  const formattedValue = Array.isArray(formattedValues) ? String(formattedValues[0]) : formattedValues.toString();
  return formattedValue;
};

export const getSummaryContext = (
  triagePayload: WidgetTriagePayload<SeriesTagsDataWithMeta[]>,
  widgetResponse: WidgetResponseDTO,
  widgetId: string,
  selectedSeriesFilters: TagFilterSelectionBySliceSet[],
  selectedBizFilter: TagFilterSelectionBySliceSet,
  seriesFilters: TagFilterBySliceSet[]
) => {
  const { widgetConfig } = widgetResponse;
  const { userServiceEntityId, bizEntityType } = widgetConfig;

  const triageContext: AdhocSummaryContext = {
    widgetResponse: widgetResponse,
    widgetId: widgetId,
    bizEntityIds: [],
    entityId: userServiceEntityId,
    entityType: bizEntityType,
    startTime: triagePayload.start,
    endTime: triagePayload.end,
    entityIdNameMap: {},
    seriesFiltersBySliceSet: [],
    entityFilters: []
  };

  const bizEntityIdRecArr = selectedBizFilter?.selectionValues[0]?.selValToDValArr || [];
  const bizEntityIdsArr: string[] = [];
  bizEntityIdRecArr.forEach(rec => {
    const ids = Object.keys(rec);
    bizEntityIdsArr.push(...ids);
  });
  const bizEntityIdsSet: Set<string> = new Set(bizEntityIdsArr);
  const bizEntityIds = Array.from(bizEntityIdsSet);

  triageContext.bizEntityIds = bizEntityIds;

  const entityIdNameMap: Record<string, string> = Object.fromEntries(
    entityEnricherRegistry.getEnrichmentGlobalEntityMap()
  );
  triageContext.entityIdNameMap = entityIdNameMap;

  const seriesFiltersBySliceSet: TagFilterSelectionBySliceSet[] = seriesFilters.map(sFilter => {
    const { sliceSet, dataDefinitionId, resultSeriesId, name } = sFilter;

    const selection = selectedSeriesFilters.find(
      s => s.dataDefinitionId === dataDefinitionId && s.resultSeriesId === resultSeriesId
    );
    return {
      name,
      dataDefinitionId,
      resultSeriesId,
      sliceSet,
      selectionValues: selection?.selectionValues || []
    };
  });

  triageContext.seriesFiltersBySliceSet = seriesFiltersBySliceSet;
  return triageContext;
};

export const getSerieName = (
  aggregatedTags: string[],
  displayAggregatedTags: string[],
  labels: Record<string, string>,
  eLabels: Record<string, string>,
  metricName: string,
  escapeLabel = true,
  includeTagKeys = false
) => {
  let aggLabel = "";
  let rawValue = "";

  const numAggTags = aggregatedTags.length;
  if (!numAggTags) {
    aggLabel = metricName;
    rawValue = metricName;
  } else if (numAggTags === 1) {
    const aggregatedTag = aggregatedTags[0];
    rawValue = labels[aggregatedTag];

    const tagValue = eLabels[aggregatedTag] || rawValue;
    const escTagValue = escapeLabel ? escape(tagValue) : tagValue;

    aggLabel = escTagValue || metricName;
  } else {
    const aggLabels: string[] = [];
    aggregatedTags.forEach((aggTag, idx) => {
      const key = displayAggregatedTags[idx] || aggTag;

      const tagValue = eLabels[aggTag] || labels[aggTag];
      const escTagValue = escapeLabel ? escape(tagValue) : tagValue;

      const value = escTagValue || metricName;
      const label = includeTagKeys ? `${key}: ${value}` : value;
      aggLabels.push(label);
    });
    aggLabel = aggLabels.join(", ");
    rawValue = aggLabel;
  }

  return {
    aggLabel,
    rawValue
  };
};

export const getFieldsFromLabels = (dataFrames: DataFrame[], labels: Record<string, string>) => {
  const dataFrame = dataFrames.find(df => {
    const { labels: pLabels } = df;
    return isEqual(labels, pLabels);
  });
  return dataFrame ? dataFrame?.fields : [];
};
