import { groupBy, map, max } from "lodash";
import { generateId } from "@inception/ui";
import { HCBarSeries, IncHBarSeriesOptions, IncVBarSeriesOptions } from "../../../components/bar-chart/types";
import { ComparisonType, RelativeDurationType, WidgetComparisonModel } from "../../models/BaseWidgetModel";
import { isCompareResult } from "../WidgetCompareUtils";

export type BarChartLayout = "horizontal" | "vertical";

export class BarChartHelper {
  barSeries: HCBarSeries[];
  static NUM_SERIES_THRESHOLD = 30; // By default only show NUM_SERIES_THRESHOLD series.

  private origSeriesStr = "original";
  private compareSeriesStr = "comparison";
  private layout: BarChartLayout;

  constructor(bs: HCBarSeries[], layout: BarChartLayout) {
    this.barSeries = bs || [];
    this.layout = layout;
  }

  getSeriesSize(): number {
    return this.barSeries.length;
  }

  getMaxValue(): number {
    const values: number[] = [];
    this.barSeries.forEach(bs => {
      values.push(bs.data);
    });
    return max(values);
  }

  /**
   * @param comparisonModel This is used to add the text to the data label for comparison series
   * @returns Series as original and compare
   */
  getSeries(comparisonModel: WidgetComparisonModel): IncHBarSeriesOptions[] | IncVBarSeriesOptions[] {
    const { comparisonType, durationType = -1, durationValue } = comparisonModel || {};

    const unit =
      durationType !== 4
        ? RelativeDurationType[durationType]?.charAt(0)?.toLowerCase()
        : RelativeDurationType[durationType]?.charAt(0);
    let prefix = "",
      suffix = "";

    if (comparisonType === ComparisonType.RELATIVE) {
      suffix = ` - ${durationValue}${unit}`;
    } else if (comparisonType === ComparisonType.CUSTOM) {
      prefix = "ComparedTo: ";
    }

    const dividedSeries = groupBy(this.barSeries, bs =>
      isCompareResult(bs) ? this.compareSeriesStr : this.origSeriesStr
    );

    return map(dividedSeries, (barSeries, type) => {
      const isComparisonSeries = type === this.compareSeriesStr;

      const data: Array<[string, number]> = barSeries.map(bs => [bs.name, bs.data]);

      const canAddColors = barSeries.every(b => b.color);
      const colors = canAddColors ? barSeries.map(b => b.color) : null;
      const compareDataMap: Record<string, number> = {};
      const invertColors = barSeries[0]?.custom?.invertColors;
      barSeries.forEach(x => {
        compareDataMap[x.name] = x.custom?.compareData || null;
      });

      const seriesOptions: IncHBarSeriesOptions | IncVBarSeriesOptions = {
        type: this.layout === "horizontal" ? "bar" : "column",
        id: generateId(),
        data,
        name: type,
        custom: {
          barSeries,
          compareDataMap,
          invertColors
        },
        dashStyle: isComparisonSeries ? "ShortDash" : undefined,
        opacity: isComparisonSeries ? 0.8 : 1,
        getTooltipText: label => (isComparisonSeries ? prefix + label + suffix : label),
        colors
      };

      return seriesOptions;
    }) as IncHBarSeriesOptions[] | IncVBarSeriesOptions[];
  }
}
