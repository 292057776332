import React, { FC, useMemo, useCallback } from "react";
import { CurrencyType } from "@inception/ui";
import { escape } from "lodash";
import { SeriesSplineOptions } from "highcharts";
import TimeSeries from "../../../../../components/time-series/TimeSeries";
import { dataFrameToTimeseries, DataType, DataFrame } from "../../../../../core";
import TimeSeriesWidgetImpl from "../../../TimeSeries/models/impl";
import { TimeSeriesHelper } from "../../../TimeSeries/models/TimeSeriesUtils";
import timeRangeUtils from "../../../../../utils/TimeRangeUtils";
import { TimeSeriesProperties, SeriesOverride } from "../../../TimeSeries/models/model";
import { ENTITY_TAG } from "../../../../../utils";
import { EntityWidgetData } from "../../../../../biz-entity";
import { ActionType, WidgetTriageAction, WidgetTriagePayload } from "../../../../BaseWidgetActions";
import {
  SeriesTagsDataWithMeta,
  ChartSelectionAction,
  IncTimeSeriesOptions
} from "../../../../../components/time-series/types";
import { USFieldWidgetProperties } from "../../models";
import { USFWRendererProps } from "./types";
import { getFormattedValue, getSummaryContext } from "./utils";
import { useCommonRendererFunctionality } from "./common";

export const TimeseriesRenderer: FC<USFWRendererProps> = props => {
  const {
    className,
    dataType,
    aggregator,
    currencyType,
    noDataElement,
    loadingElement,
    widgetResponseDTO,
    selectedFilters,
    seriesFilters,
    onTriage,
    aggregatedTags,
    displayAggregatedTags,
    properties: widgetProperties,
    timeRange,
    compareTimeRange
  } = props;

  const { timeseries: timeSeriesProperties } = widgetProperties;

  const { data, fieldName, metricName, isFetching, isError, dataExists } = useCommonRendererFunctionality(props);

  const compareTimeStr = useMemo(
    () => timeRangeUtils.getCompareTimeStr(timeRange.from.valueOf(), compareTimeRange.from.valueOf()),
    [compareTimeRange.from, timeRange.from]
  );

  const series = useMemo(
    () =>
      constructTimeseries(
        data,
        metricName,
        aggregatedTags,
        displayAggregatedTags,
        compareTimeStr,
        timeSeriesProperties
      ),
    [data, metricName, aggregatedTags, displayAggregatedTags, compareTimeStr, timeSeriesProperties]
  );

  const properties = useMemo<TimeSeriesProperties>(() => {
    const seriesSize = series.length;
    const aggregatorLabel = aggregator?.label;
    return getTimeSeriesProperties(fieldName, dataType, currencyType, aggregatorLabel, seriesSize);
  }, [aggregator, currencyType, dataType, fieldName, series.length]);

  const onAction = useCallback(
    (data: ActionType) => {
      const { bizFilter, filtersBySliceSet } = selectedFilters;
      const triageContext = getSummaryContext(
        (data as WidgetTriageAction<SeriesTagsDataWithMeta[]>).payload,
        widgetResponseDTO,
        widgetResponseDTO.widgetId,
        filtersBySliceSet,
        bizFilter,
        seriesFilters
      );
      onTriage(triageContext);
    },
    [onTriage, selectedFilters, seriesFilters, widgetResponseDTO]
  );

  const onTriageInternal = useCallback(
    (start: number, end: number) => {
      const seriesData: SeriesTagsDataWithMeta[] = [];
      series.forEach(so => {
        if (so.custom?.tagsData) {
          seriesData.push({
            ...so.custom.tagsData,
            meta: so.custom.meta
          });
        }
      });

      const triagePayload: WidgetTriagePayload<SeriesTagsDataWithMeta[]> = {
        source: "timeseries",
        start,
        end,
        data: seriesData
      };

      const action: WidgetTriageAction<SeriesTagsDataWithMeta[]> = {
        type: "triage",
        payload: triagePayload
      };
      if (onAction) {
        onAction(action);
      }
    },
    [onAction, series]
  );

  const chartSelectionActions = useMemo<ChartSelectionAction[]>(
    () => [
      {
        id: "ts-action-triage",
        label: "View analysis",
        actionHandler: onTriageInternal
      }
    ],
    [onTriageInternal]
  );

  if (isFetching) {
    return loadingElement;
  }

  if (!dataExists || isError) {
    return noDataElement;
  }

  return (
    <TimeSeries
      chartSelectionActions={chartSelectionActions}
      containerElemClass={className}
      properties={properties}
      series={series}
      timeRange={timeRange}
      title=""
      type="areaspline"
    />
  );
};

export const constructTimeseries = (
  data: EntityWidgetData[],
  metricName: string,
  aggregatedTags: string[],
  displayAggregatedTags: string[],
  compareTimeStr: string,
  properties: USFieldWidgetProperties["timeseries"]
): IncTimeSeriesOptions[] => {
  const dataframes: DataFrame[] = [];
  const compareDataFrames: DataFrame[] = [];

  data.forEach(datum => {
    const postAggData = datum.postAggResult.data;
    const compareData = datum.postAggResult.timeShiftData || {};

    const refId = Object.keys(postAggData)[0];

    const dfs = postAggData[refId]?.data || [];
    const cDfs = compareData[refId]?.data || [];

    dataframes.push(...dfs);
    compareDataFrames.push(...cDfs);
  });

  const shouldModifyMetricName = data.length < 2;

  const aggregatedSeries = dataframes.length === 1 && !aggregatedTags?.length;

  const timeseries = dataframes.map(df => {
    const { name, labels = {}, eLabels = labels } = df;

    if (shouldModifyMetricName) {
      if (aggregatedSeries) {
        df.name = metricName;
      } else {
        let visibleTags = [...aggregatedTags];
        let visibleDisplayTags = [...displayAggregatedTags];

        const entityTagIdx = visibleTags.findIndex(tag => tag === ENTITY_TAG);

        if (entityTagIdx !== -1) {
          visibleTags.splice(entityTagIdx, 1);
          visibleTags = [ENTITY_TAG, ...visibleTags];

          const entityDisplayTag = visibleDisplayTags[entityTagIdx];
          visibleDisplayTags.splice(entityTagIdx, 1);
          visibleDisplayTags = [entityDisplayTag, ...visibleDisplayTags];
        }

        if (visibleTags.length > 1) {
          const dfNameArr = visibleTags.map((tag, idx) => {
            const key = visibleDisplayTags[idx] || tag;
            const value = eLabels[tag];
            return `${key}: ${value}`;
          });
          df.name = dfNameArr.join(", ");
        } else {
          const tag = visibleTags[0];
          df.name = eLabels[tag] || name;
        }
      }
    }

    df.name = escape(df.name);
    return dataFrameToTimeseries(df);
  });

  const compareTimeseries =
    compareDataFrames.length === 1
      ? compareDataFrames.map(df => {
          df.name = `${timeseries[0]?.target} (${compareTimeStr})`;
          df.name = escape(df.name);
          return dataFrameToTimeseries(df);
        })
      : [];

  const seriesOverrides: SeriesOverride[] = aggregatedSeries
    ? [
        {
          alias: ".*.",
          chartType: "areaspline",
          lineWidth: 2
        }
      ]
    : [];

  const tsWidget = new TimeSeriesWidgetImpl({
    chartType: "areaspline",
    seriesOverrides: seriesOverrides
  });

  const series = [...timeseries, ...compareTimeseries];
  const { seriesOptions } = new TimeSeriesHelper(series, tsWidget);

  const { connectNulls, pointRadius, shape } = properties || {};

  seriesOptions.forEach(so => {
    const splineOptions = so as SeriesSplineOptions;
    splineOptions.connectNulls = connectNulls || false;

    splineOptions.marker = splineOptions.marker || {};
    splineOptions.marker.enabled = true;
    splineOptions.marker.radius = pointRadius || 2;
    splineOptions.marker.symbol = shape || "circle";
  });

  return seriesOptions;
};

export const getTimeSeriesProperties = (
  fieldName: string,
  dataType: DataType,
  currencyType: CurrencyType,
  aggregator: string,
  seriesSize: number
): TimeSeriesProperties => {
  const labelFormatterFn = (value: string | number): string =>
    getFormattedValue(fieldName, value, dataType, currencyType);
  const labelFormatter = skipFormatterAggs.includes(aggregator) ? null : labelFormatterFn;

  return {
    legend: {
      show: seriesSize > 1,
      position: "bottom"
    },
    chartType: "areaspline",
    yAxis: {
      axisProps: [
        {
          labelFormatter
        }
      ]
    },
    tooltip: {
      valueFomatter: labelFormatter,
      shared: true,
      sortBy: "desc"
    }
  };
};

const skipFormatterAggs = ["count", "distinctCount"];
