import { useMemo, useEffect } from "react";
import { pick } from "lodash";
import {
  TriageImpactedWidgetConfig,
  ImpactedWidgetListDTO,
  TriageConfigPayload,
  useFetchTriageConfig
} from "../../../../../platform/services/api/explore";
import { logger } from "../../../../../platform/core";

export const useFetchImpactedWidgets = () => {
  const {
    data: impactedWidgetsConfigs,
    isFetching: isImpactedWidgetsFetching,
    isError: isImpactedWidgetsFetchError,
    fetchTriageConfigs: fetchImpactedWidgets,
    error: impactedWidgetsFetchError
  } = useFetchTriageConfig();

  const impactedWidgetsLists = useMemo(() => {
    const configs = (impactedWidgetsConfigs as TriageImpactedWidgetConfig[]) || [];

    const impactedWidgetsLists: ImpactedWidgetListDTO[] = [];
    const config = configs[0];

    if (config) {
      const { impactedWidgets } = config;
      if (impactedWidgets) {
        const listDto: ImpactedWidgetListDTO = {
          ...impactedWidgets,
          parentConfig: pick(
            config,
            "id",
            "name",
            "idProps",
            "triageLinkLevelEntity",
            "triageLinkLevelEvent"
          ) as TriageConfigPayload
        };
        impactedWidgetsLists.push(listDto);
      }
    }

    return impactedWidgetsLists;
  }, [impactedWidgetsConfigs]);

  useEffect(() => {
    if (!isImpactedWidgetsFetching && isImpactedWidgetsFetchError) {
      logger.error("Alert Summary", "Failed to fetch impacted", impactedWidgetsFetchError);
    }
  }, [impactedWidgetsFetchError, isImpactedWidgetsFetchError, isImpactedWidgetsFetching]);

  return {
    impactedWidgetsLists,
    isImpactedWidgetsFetching,
    isImpactedWidgetsFetchError,
    fetchImpactedWidgets,
    impactedWidgetsFetchError
  };
};
