import React from "react";
import { Handle, Position } from "react-flow-renderer";
import { IncBadge, IncFaIcon, IncFaIconName } from "@inception/ui";
import { cx } from "emotion";
import { WorkflowNodeProps } from "./types";

export const IncWorkflowNode: React.FC<WorkflowNodeProps> = props => {
  const { content, data } = props;

  let badgeIcon: IncFaIconName;

  switch (data?.status) {
    case "error": {
      badgeIcon = "triangle-exclamation";
      break;
    }
    default:
      break;
  }

  const { status = "default", id, badgeCount } = data || {};

  const className = cx("workflow-entity justify-content-center", {
    [status]: Boolean(status)
  });

  const key = `${id}_wfen`;
  const badgeCountJsx = badgeIcon ? <IncFaIcon iconName={badgeIcon as IncFaIconName} /> : badgeCount;

  return (
    <>
      <IncBadge
        count={badgeCountJsx}
        overflowCount={999}
        showZero
        status={status}
      >
        <div
          className={className}
          data-status={status}
          id={key}
          key={key}
        >
          <div className="workflow-entity-wrapper">{content}</div>
          <Handle
            position={Position.Left}
            type="target"
          />
          <Handle
            position={Position.Right}
            type="source"
          />
        </div>
      </IncBadge>
    </>
  );
};
