import React from "react";
import { defaultsDeep } from "lodash";
import { TooltipOptions } from "highcharts";
import { renderToStaticMarkup } from "react-dom/server";
import { IncPercentRenderer, formatNumber } from "@inception/ui";
import BarChartOptionsBuilder from "./BarOptionsBuilder";

class BarColumnChartOptionsBuilder extends BarChartOptionsBuilder {
  setToolTip(
    useSeriesName?: boolean,
    skipVar?: boolean,
    tooltipValueFormatter?: (value: string | number, custom?: Record<string, unknown>) => string
  ) {
    this.chartOptions.tooltip = this.chartOptions.tooltip || {};

    const defaultOptions: TooltipOptions = {
      enabled: true,
      shared: true,
      followPointer: true,
      outside: true,
      useHTML: true,
      formatter: function () {
        if (!this.points) {
          return "";
        }

        let tooltipText = `<div class='inc-charts-tooltip'>`;
        this.points.forEach((pt, i) => {
          // Typing this to any since opacity and options property exist but it still complains that they're not valid
          const { y, series, colorIndex } = pt;
          const { opacity } = series as any;
          const serieOptions = (series as any)?.options || {};
          const seriesName = useSeriesName
            ? series.name
            : serieOptions?.getTooltipText
              ? serieOptions.getTooltipText(pt.x)
              : pt.x;
          const compareDataMap = serieOptions.custom?.compareDataMap;
          const invertColors = serieOptions.custom?.invertColors;
          const compareData = compareDataMap?.[seriesName] || null;

          const isDataPositive = y > compareData;
          const eValue = isDataPositive ? y - compareData : compareData - y;
          const sign = isDataPositive ? "up" : "down";

          const formattedValue = tooltipValueFormatter
            ? tooltipValueFormatter(y, {
                ...(serieOptions?.custom || {}),
                pointIndex: colorIndex
              })
            : formatNumber(y);

          const percentChange = compareData
            ? renderToStaticMarkup(
                <IncPercentRenderer
                  direction={sign}
                  invertColors={invertColors}
                  size="small"
                  total={compareData}
                  value={eValue}
                />
              )
            : "";

          tooltipText += `<div class='inc-charts-tooltip-series-row'>
            <div class="inc-highcharts-square-symbol" style="background-color:${this.points[i].color}; opacity: ${opacity}"></div>
              <div class='inc-charts-tooltip-series-name'>${seriesName}</div> 
                <div class='inc-flex-row inc-charts-tooltip-series-value'> <span>${formattedValue}</span><span class='marginLt8'>${percentChange}</span></div>
                  </div>`;
        });

        tooltipText += "</div>";
        return tooltipText;
      }
    };

    defaultsDeep(this.chartOptions.tooltip, defaultOptions);
    return this;
  }
}

export default BarColumnChartOptionsBuilder;
