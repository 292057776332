import React, { ErrorInfo, FC, createElement } from "react";
import { IncButton, IncResult } from "@inception/ui";
import { logger } from "../platform/core";
import { uiApiService } from "../platform/services/api";
import appConfig, { appData } from "../appConfig";
import { VerticallyCenteredRow } from "../platform/components";

interface AppErrorBoundaryProps {
  fallback?: React.ReactNode;
}

interface AppErrorBoundaryState {
  error: Error;
  errorInfo: ErrorInfo;
  showDetails: boolean;
}

class AppErrorBoundary extends React.Component<AppErrorBoundaryProps, AppErrorBoundaryState> {
  constructor(props: AppErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      showDetails: false
    };
  }

  toggleDetails = () => {
    this.setState(prev => ({
      ...prev,
      showDetails: !prev.showDetails
    }));
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
      showDetails: false
    });

    const errorObj = {
      errorName: error.name,
      errorCause: error.cause,
      errorMessage: error.message,
      errorStack: error.stack,
      componentStack: errorInfo.componentStack,
      ...(appData.build || {}),
      url: window.location.href
    };

    logger.error("AppErrorBoundary", "Error caught:", errorObj);

    if (appConfig.isProductionEnv) {
      uiApiService.logUIErrorStackTrace(errorObj);
    }
  }

  resetPageState = () => {
    this.setState({
      error: null,
      errorInfo: null,
      showDetails: false
    });
  };

  render() {
    const { error } = this.state;

    if (error) {
      return this.props.fallback ? this.props.fallback : <ErrorFallback onReset={this.resetPageState} />;
    }

    // Normally, just render children
    return createElement(React.Fragment, null, this.props.children);
  }
}

export default AppErrorBoundary;

type FBProps = {
  onReset: () => void;
};

export const ErrorFallback: FC<FBProps> = ({ onReset }) => (
  <div className="error-page">
    <IncResult
      extra={
        <VerticallyCenteredRow className="flex-gap-12 inc-flex-center">
          <IncButton
            color="primary"
            onClick={onReset}
          >
            Try again
          </IncButton>

          <IncButton
            color="secondary-blue"
            onClick={() => window.location.reload()}
          >
            Refresh
          </IncButton>
        </VerticallyCenteredRow>
      }
      status="500"
      subTitle="We're sorry for the inconvenience. Please try again, or refresh the page, or contact support if the problem persists."
      title="Something went wrong"
    />
  </div>
);
