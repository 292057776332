import { IncSelectOption, IncSlimSelect } from "@inception/ui";
import React, { FC, useMemo, useCallback, useState, useEffect } from "react";
import { css } from "emotion";
import { VerticallyCenteredRow } from "../../../components";
import IncARDateTimePicker from "../../../components/relative-date-selector/ARDateTimePicker";
import { RawTimeRange, useTenantConfig } from "../../../core";
import timeRangeUtils from "../../../utils/TimeRangeUtils";

interface Props {
  timeFrame: OpTimeFrame;
  onChange: (timeFrame: OpTimeFrame) => void;

  includeStartingNow?: boolean;
  className?: string;
  showSeconds?: boolean;
  allowEmptyEndTime?: boolean;
}

const CUSTOM_OPT_VALUE = "i_custom_tr";

type Option = IncSelectOption<RawTimeRange>;
type EndTimeOption = IncSelectOption<boolean>;

export interface OpTimeFrame {
  from: string;
  to: string;
  label: string;
  isCustom: boolean;
  isStartingNow: boolean;
}

export const OpTimeFrameEditor: FC<Props> = props => {
  const {
    onChange,
    timeFrame,
    className: pClassName = "",
    includeStartingNow = false,
    showSeconds = false,
    allowEmptyEndTime = false
  } = props;

  const { tenantConfigState } = useTenantConfig();
  const { customTimeRanges = [] } = tenantConfigState || {};

  const [isEndTimeEmpty, setIsEndTimeEmpty] = useState(false);

  const { to, isCustom } = timeFrame || {};

  useEffect(() => {
    if (allowEmptyEndTime && isCustom) {
      const { millis } = timeRangeUtils.getMillis(to);
      setIsEndTimeEmpty(millis <= 0);
    } else {
      setIsEndTimeEmpty(false);
    }
  }, [allowEmptyEndTime, isCustom, to]);

  const customTimeRangeOptions: Option[] = useMemo(() => {
    if (!customTimeRanges?.length) {
      return [];
    } else {
      const options: Option[] = customTimeRanges.map(item => ({
        label: item?.label,
        value: `${item?.from} - ${item?.to}`,
        data: {
          from: item?.from,
          to: item?.to
        }
      }));
      return options;
    }
  }, [customTimeRanges]);

  const timeRangeOpts = useMemo(
    () => [...getTimeRangeOpts(includeStartingNow), ...(customTimeRangeOptions || [])],
    [customTimeRangeOptions, includeStartingNow]
  );

  const { endTimeMillis, startTimeMillis, timeRangeOpt } = useMemo(() => {
    const { from, isCustom, isStartingNow, to } = timeFrame;

    const defTrOption = isCustom
      ? customOpt
      : isStartingNow
        ? startingNowOpt
        : timeRangeOpts.find(opt => opt.data?.from === from && opt.data?.to === to) || customOpt;

    const defStartTimeMillis = isCustom
      ? parseInt(from || "0", 10)
      : defTrOption === customOpt
        ? timeRangeUtils.getMillis(from).millis
        : null;
    const defEndTimeMillis = isCustom
      ? parseInt(to || "0", 10)
      : defTrOption === customOpt
        ? timeRangeUtils.getMillis(from).millis
        : null;

    return {
      startTimeMillis: defStartTimeMillis,
      endTimeMillis: defEndTimeMillis,
      timeRangeOpt: defTrOption
    };
  }, [timeFrame, timeRangeOpts]);

  const isCustomTimeRange = timeRangeOpt.value === CUSTOM_OPT_VALUE;
  const isStartingNow = timeRangeOpt.value === startingNowOpt.value;

  const startDate = new Date(startTimeMillis);
  const endDate = new Date(endTimeMillis);

  const onCustomTimeRangeChanged = useCallback(
    (property: "start" | "end", newDate: Date | string) => {
      const millis =
        typeof newDate === "object"
          ? newDate.getTime()
          : newDate === "now"
            ? timeRangeUtils.getNowMillis()
            : parseInt(newDate as string, 10);

      if (property === "start") {
        onChange({
          from: String(millis),
          to: String(endTimeMillis),
          isCustom: isCustomTimeRange,
          isStartingNow,
          label: timeRangeOpt.label
        });
      } else {
        onChange({
          from: String(startTimeMillis),
          to: String(millis),
          isCustom: isCustomTimeRange,
          isStartingNow,
          label: timeRangeOpt.label
        });
      }
    },
    [endTimeMillis, isCustomTimeRange, isStartingNow, onChange, startTimeMillis, timeRangeOpt.label]
  );

  const onTimeRangeOptSelect = useCallback(
    (opt: Option) => {
      const { from, to } = opt.data
        ? timeRangeUtils.getTimeRangeMillisFromRaw(opt.data)
        : {
            from: null,
            to: null
          };

      const isCustom = opt.value === CUSTOM_OPT_VALUE;
      const isStartingNow = opt.value === startingNowOpt.value;

      onChange({
        from: opt.value === CUSTOM_OPT_VALUE ? String(from) : isStartingNow ? null : opt.data?.from,
        to: opt.value === CUSTOM_OPT_VALUE ? String(to) : isStartingNow ? null : opt.data?.to,
        isCustom,
        isStartingNow,
        label: opt.label
      });
    },
    [onChange]
  );

  const selectedEndTimeOption = useMemo<EndTimeOption>(
    () => endTimeOptions.find(e => e.data === isEndTimeEmpty),
    [isEndTimeEmpty]
  );
  const onChangeEndTimeOption = useCallback(
    (option: EndTimeOption) => {
      const millis = timeRangeUtils.getMillis("now");
      const { data: isEndTimeEmpty } = option || {};
      onChange({
        ...timeFrame,
        to: isEndTimeEmpty ? null : String(millis.millis)
      });
    },
    [onChange, timeFrame]
  );

  const endTimeContainerClassName = useMemo(
    () => css`
      margin-top: ${allowEmptyEndTime ? (isEndTimeEmpty ? "-22px" : "22px") : "0px"};
    `,
    [allowEmptyEndTime, isEndTimeEmpty]
  );

  const endTimeSelectorClassName = useMemo(
    () => css`
      margin-top: 4px;
      position: ${isEndTimeEmpty ? "static" : "absolute"};
    `,
    [isEndTimeEmpty]
  );

  const className = `flex-gap-16 ${pClassName}`;
  return (
    <VerticallyCenteredRow className={className}>
      <IncSlimSelect
        alignment="row"
        autoSort={false}
        label="Time frame"
        onChange={onTimeRangeOptSelect}
        options={timeRangeOpts}
        value={timeRangeOpt}
      />

      {isCustomTimeRange && (
        <>
          <div className="time-range-selector--absolute">
            <IncARDateTimePicker
              allowFutureDates
              enableBeginningAndEndTimes={false}
              id="time-range-selector-absolute-from"
              label="Start"
              onChange={newDate => onCustomTimeRangeChanged("start", newDate)}
              popperPlacement="top"
              value={startDate}
              withSeconds={showSeconds}
            />
          </div>
          <div className={endTimeContainerClassName}>
            {allowEmptyEndTime && (
              <div className={endTimeSelectorClassName}>
                <IncSlimSelect
                  allowCreate={false}
                  isSearchable={false}
                  label={"End"}
                  onChange={onChangeEndTimeOption}
                  options={endTimeOptions}
                  value={selectedEndTimeOption}
                />
              </div>
            )}
            {!isEndTimeEmpty && (
              <div className={`time-range-selector--absolute ${allowEmptyEndTime ? "monitor-end-time-select" : null}`}>
                <IncARDateTimePicker
                  allowFutureDates
                  enableBeginningAndEndTimes={false}
                  id="time-range-selector-absolute-to"
                  label={allowEmptyEndTime ? "" : "End"}
                  onChange={newDate => onCustomTimeRangeChanged("end", newDate)}
                  popperPlacement="top"
                  value={endDate}
                  withSeconds={showSeconds}
                />
              </div>
            )}
          </div>
        </>
      )}
    </VerticallyCenteredRow>
  );
};

const endTimeOptions: EndTimeOption[] = [
  {
    label: "Never Ending",
    value: "__never_ending__",
    data: true
  },
  {
    label: "Custom",
    value: "__custom__",
    data: false
  }
];

const startingNowOpt: Option = {
  label: "Starting now",
  value: null,
  data: null
};

const customOpt: Option = {
  label: "Custom",
  value: CUSTOM_OPT_VALUE,
  data: {
    from: "now-2w",
    to: "now"
  }
};

const getTimeRangeOpts = (includeStartingNow: boolean) => {
  const options: Option[] = [
    {
      label: "Last 24 Hours",
      value: "24h",
      data: {
        from: "now-24h",
        to: "now"
      }
    },
    {
      label: "Last week",
      value: "1w",
      data: {
        from: "now-1w",
        to: "now"
      }
    },
    {
      label: "Last month",
      value: "1M",
      data: {
        from: "now-1M",
        to: "now"
      }
    },
    customOpt
  ];

  if (includeStartingNow) {
    options.unshift(startingNowOpt);
  }

  return options;
};
