import React, { FC, useEffect, useState, useCallback, useMemo, ReactNode } from "react";
import { TableDataColumn, IncRTable, IncModal } from "@inception/ui";
import { DestinationMapping, PropertySource } from "../../services/api/entity-mapping";
import { generateId } from "../../core";
import { BizPropertySourceRenderer } from "../business-entity";
import { useFetchDestinationMappings } from "./useFetchDestinationMappings";
import { DestinationData, getDestinationDataRec, enrichDestinationDataRec } from "./utils";

interface Props {
  fieldName: string;
  entityTypeId: string;
  destinationMappings?: DestinationMapping[];
  mockDataIfEmpty?: boolean;
  children: ReactNode;
  className?: string;
}

export const WritableFieldDestinations: FC<Props> = props => {
  const {
    fieldName,
    entityTypeId,
    destinationMappings: pDestinationMappings,
    mockDataIfEmpty,
    children,
    className = ""
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [destinationData, setDesinationData] = useState<DestinationData[]>([]);

  const [open, setOpen] = useState(false);

  const { destinationMappings, isMockData, error, isError, isFetching, refetch } = useFetchDestinationMappings(
    entityTypeId,
    fieldName,
    pDestinationMappings,
    mockDataIfEmpty
  );

  useEffect(() => {
    if (!pDestinationMappings) {
      refetch();
    }
  }, [pDestinationMappings, refetch]);

  const updateDestinationData = useCallback(async () => {
    if (!isFetching && destinationMappings) {
      setIsLoading(true);
      const destDataRec = getDestinationDataRec(destinationMappings, fieldName);
      await enrichDestinationDataRec(destDataRec, isMockData);
      const nDestinationData = Object.values(destDataRec);
      setDesinationData(nDestinationData);
      setIsLoading(false);
    }
  }, [destinationMappings, fieldName, isFetching, isMockData]);

  useEffect(() => {
    updateDestinationData();
  }, [updateDestinationData]);

  const onOpenModal = useCallback(() => setOpen(true), []);
  const onCloseModal = useCallback(() => setOpen(false), []);

  const modalContent = useMemo(
    () => (
      <div className="padding16">
        <IncRTable
          columns={columns}
          data={destinationData}
          errorDataMessage={error}
          hasError={isError}
          isLoading={isLoading}
        />
      </div>
    ),
    [destinationData, error, isError, isLoading]
  );

  const modalTitle = `${fieldName} - ${entityTypeId}`;

  return (
    <>
      <div
        className={className}
        onClick={onOpenModal}
      >
        {children}
      </div>
      <IncModal
        onClose={onCloseModal}
        show={open}
        titleText={modalTitle}
      >
        {modalContent}
      </IncModal>
    </>
  );
};

const columns: Array<TableDataColumn<DestinationData>> = [
  {
    header: "System",
    accessor: "name",
    renderer: (name: string, rowData: DestinationData) => {
      const { type, icon, id } = rowData;

      const propertySource: PropertySource = {
        name,
        type,
        sourceId: id,
        metadata: {
          icon
        }
      };

      return (
        <BizPropertySourceRenderer
          propertySource={propertySource}
          showIcon
        />
      );
    }
  },
  {
    header: "Fields",
    accessor: "fields",
    renderer: (fields: string[]) => {
      const numFields = fields.length;
      if (numFields === 0) {
        return "No fields";
      }

      return (
        <div className="inc-flex-column">
          {fields.map((field, i) => {
            const isLast = i === numFields - 1;
            const className = isLast ? "" : "marginBt6";
            return (
              <div
                className={className}
                key={generateId()}
              >
                {field}
              </div>
            );
          })}
        </div>
      );
    }
  }
];
