import React, { useCallback, useMemo, useState } from "react";
import { CircleProgressBar, IncButton, IncFaIcon, IncModal, IncModalProps } from "@inception/ui";
import { cloneDeep, filter, map } from "lodash";
import { noOp } from "../../../../utils";
import { CatalogWidgetImpl } from "../models";
import { QueryMappingStatus, WidgetResponseDTO } from "../../../../services/api/explore";
import MapFieldToEventModal from "../../../../../features/auto-discovery/components/MapFieldToEventModal";
import { generateId, useTimeRange } from "../../../../core";
import {
  DiscoveredFieldDef,
  EntityFields,
  EventFields,
  FieldMappingOptionsRequest,
  MappingEventTypePreviewResponse,
  VerticalEventField
} from "../../../../services/api/auto-discovery/types";
import { autoDiscoveryUtils } from "../../../../../features/auto-discovery/utils/autoDiscoveryUtils";
import autoDiscoveryApiService from "../../../../services/api/auto-discovery/AutoDiscoveryApiService";
import { ReactComponent as JiraIcon } from "../../../../../images/jira-soft.svg";

const SOURCEMAP_STEP_1 = "sourcemap_step_selection";
const SOURCEMAP_STEP_2 = "sourcemap_step_load";
const SOURCEMAP_STEP_3 = "sourcemap_step_mapper";

type DiscoverFieldResponse = {
  fieldName: string;
  eventTypeEntityId: string;
  discoveredFieldDef?: DiscoveredFieldDef;
};

type Props = {
  title: string;
  onClose: () => void;
  widget: CatalogWidgetImpl;
  widgetResponseDTO: WidgetResponseDTO;
};

interface ScreenProps {
  widget: CatalogWidgetImpl;
  queryMappingStatus: QueryMappingStatus;
  discoveredFieldNameDefs?: DiscoverFieldResponse[];

  setCanProceed: (canProceed: boolean) => void;
  proceedToNextStep: () => void;
  backToPreviousStep: () => void;
  onStepChange: (stepId: string) => void;
}

export const SourceMapScreen1: React.FC<ScreenProps> = props => {
  const { queryMappingStatus } = props;

  const { usFields } = queryMappingStatus;
  const unmappedDimensions = filter(usFields, ["field.isMappingIncomplete", true]);
  const eventTypes = map(usFields, "field.fieldName.userServices[0].userServiceEntityId").join(", ");

  const dimensionList = useMemo(
    () =>
      (unmappedDimensions || []).map(dimension => (
        <>
          <div
            className="d-flex justify-content-between align-items-center dimension-item rounded p-2"
            key={generateId()}
          >
            <div className="d-flex">{dimension.field.fieldName}</div>
            <div className="d-flex  align-items-center">
              <span className="text-muted">unmapped</span>
            </div>
          </div>
        </>
      )),
    [unmappedDimensions]
  );

  return (
    <>
      <div className="sourcemap-dialog-content-step p-4">
        <div className="message">
          <IncFaIcon
            className="text-danger"
            iconName="question-circle"
          />{" "}
          Bicycle can’t find the dimensions associated with this widget
        </div>
        <div className="mt-4">
          Events mapped to this widget <span>{eventTypes}</span>
        </div>
        <div className="mt-4 unmapped-dimension">
          <p>List of dimensions added to the widget that have not been mapped</p>
          {dimensionList}
        </div>
      </div>
    </>
  );
};

export interface MappingProps {
  data: MappingEventTypePreviewResponse;
  entityFields: EntityFields;
  eventFields: EventFields;

  onUpdateEntityFields: (fields: EntityFields) => void;
  onUpdateEventFields: (fields: EventFields) => void;

  eventType: string;
}

// type TableRowDataProp = {
//   rowHeader?: string;
//   propertyInfo: DiscoveredFieldDef;
//   categoryType: FieldTypeCategory;
//   mappingType?: EntityEventFieldMappingType;
//   recommendedField?: VerticalEventField;
//   isChecked?: boolean;
//   customFieldDef?: DiscoveredCustomFieldDef;
//   subRows?: TableRowDataProp[];
//   entityInfo?: EntityTypeSummary;
//   derivedName?: string;
// };

export const SourceMapScreen2: React.FC<ScreenProps> = (props, mappingProps) => {
  const { queryMappingStatus, discoveredFieldNameDefs } = props;

  const [selectedFieldDef, SetSelectedFieldDef] = useState<string>(discoveredFieldNameDefs?.[0]?.fieldName || "");

  const { timeRange } = useTimeRange();
  const { usFields } = queryMappingStatus;
  const unmappedDimensions = filter(usFields, ["field.isMappingIncomplete", true]);
  const { previewData, availableFields, onUpdateEventFields } = mappingProps;
  // const [selectedRowField, setSelectedRowField] = useState<TableRowDataProp>(null);
  const [eventFields, setEventFields] = useState<EventFields>(null);

  const fieldInfo = useMemo(
    () => discoveredFieldNameDefs.find(x => x.fieldName === selectedFieldDef),
    [discoveredFieldNameDefs, selectedFieldDef]
  );

  const selectedRowInfo = useMemo(() => {
    if (!fieldInfo?.discoveredFieldDef) {
      return null;
    }
    return {
      field: fieldInfo.discoveredFieldDef,
      recommendedEvent: null,
      derivedName: fieldInfo.discoveredFieldDef?.predefinedFieldType
    };
  }, [fieldInfo]);

  const selectCurrentField = useCallback(dimension => {
    SetSelectedFieldDef(dimension.field.fieldName);
  }, []);

  const setMappedStatus = useCallback(() => {
    const fieldInfo = discoveredFieldNameDefs.find(x => x.fieldName === selectedFieldDef);
    const unmappedField = unmappedDimensions.find(y => y.field?.fieldName === fieldInfo.fieldName);
    console.log(unmappedField);
  }, [discoveredFieldNameDefs, selectedFieldDef, unmappedDimensions]);

  const onAddEditEventFields = useCallback(
    (updatedField: DiscoveredFieldDef, recommendedEventField: VerticalEventField, predefinedType: string) => {
      const clonedEventFields = cloneDeep(eventFields);
      //first find the field(row) to update and then update preDefinedFieldType
      const fieldToUpdateIndex = clonedEventFields.fields.findIndex(
        (field: DiscoveredFieldDef) =>
          autoDiscoveryUtils.getUniqFieldName(field) ===
          autoDiscoveryUtils.getUniqFieldName(selectedRowInfo?.field as DiscoveredFieldDef)
      );
      updatedField.predefinedFieldType = predefinedType || updatedField.predefinedFieldType;
      // We shouldn't update anything in VerticalEventField*** below code should be removed (later)
      // if (!isEmpty(updatedField?.verticalContextField) && predefinedType) {
      //   updatedField.verticalContextField.overrideName = predefinedType;
      // }
      if (selectedRowInfo?.field && fieldToUpdateIndex !== -1) {
        //Edit Mode
        clonedEventFields.fields[fieldToUpdateIndex] = updatedField;
      } else {
        //Add Mode
        clonedEventFields.fields.push(updatedField);
      }

      if (recommendedEventField) {
        clonedEventFields.recommendedFields = clonedEventFields.recommendedFields.filter(
          field => field.defaultName !== recommendedEventField.defaultName
        );
      }
      setEventFields(clonedEventFields);
      onUpdateEventFields(clonedEventFields);
    },
    [eventFields, onUpdateEventFields, selectedRowInfo?.field]
  );

  const dimensionList = useMemo(
    () =>
      (unmappedDimensions || []).map(dimension => (
        <>
          <li
            className="d-flex  justify-content-between list-item active"
            onClick={() => selectCurrentField(dimension)}
          >
            <div
              className="d-flex"
              style={{ overflow: "hidden" }}
            >
              {
                <IncFaIcon
                  className="icon tick"
                  height={16}
                  iconName="check-circle"
                  width={16}
                ></IncFaIcon>
              }
              <span className="text-ellipsis">{dimension.field.fieldName}</span>
            </div>
            <div className="d-flex">
              <IncFaIcon
                className="icon"
                height={16}
                iconName="angle-right"
                width={16}
              ></IncFaIcon>
            </div>
          </li>
        </>
      )),
    [selectCurrentField, unmappedDimensions]
  );

  const btnCLick = useCallback(() => {
    const url = `https://bicycleio.atlassian.net/jira/secure/CreateIssueDetails!init.jspa?pid=10026&issuetype=10002&summary=Add%20${fieldInfo.fieldName}%20field%20to%20${fieldInfo.eventTypeEntityId}%20event&description=In%20Payment%20topic%20add%20discount%20field`;
    window.open(url);
  }, [fieldInfo]);

  return (
    <>
      <div className="sourcemap-dialog-content-step">
        <div className="d-flex flex-row">
          <div className="d-flex flex-column left-panel">
            <ol className="list-unstyled dimension-list">{dimensionList}</ol>
          </div>
          <div className="d-flex flex-column inc-flex-center configure-pane">
            {selectedRowInfo ? (
              <>
                <div className="map-field-to-entity-modal rounded">
                  <MapFieldToEventModal
                    customObjectFields={[]}
                    getLocationTypes={() => ({})}
                    onAddCustomFieldObj={() => {}}
                    onClose={() => true}
                    onEditCustomFieldObject={() => {}}
                    onUpdate={onAddEditEventFields}
                    pTimeRange={timeRange}
                    previewData={previewData}
                    rawFields={availableFields}
                    selected={selectedRowInfo as any}
                    show={true}
                    variant="inline"
                  ></MapFieldToEventModal>
                </div>
                <div className="d-flex inc-flex-self-end justify-content-end p-2">
                  <IncButton
                    color="primary"
                    label="Confirm"
                    onClick={setMappedStatus}
                  ></IncButton>
                </div>
              </>
            ) : (
              <div className=" inc-flex-column inc-flex-center">
                <span className="message">
                  We were not able to auto discover the field &#34; {selectedFieldDef} &#34;
                </span>
                <div>
                  <IncButton
                    className="width-fit-content"
                    color="secondary-blue"
                    iconType="icon"
                    onClick={btnCLick}
                  >
                    <JiraIcon
                      className="jira-icon marginLt6 inc-cursor-pointer marginLt12"
                      height={24}
                      width={24}
                    />
                    Create a Ticket
                  </IncButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

/********************************************************************************************************************************************/
/********************************************************************************************************************************************/
/**
 * loader Screen
 * @param props
 * @returns
 */
export const SourceMapLoader: React.FC<ScreenProps> = props => {
  const { queryMappingStatus } = props;
  console.log(queryMappingStatus);
  return (
    <>
      <div className="d-flex justify-content-center flex-column justify-content-center">
        <div className="d-flex justify-content-center  text-center p-5">
          <CircleProgressBar
            indicatorColor={"#3BB443"}
            progress={25}
            size={75}
            spinnerMode
          />
        </div>
        <div className="text-center">
          <h4>Data Discovery in progress</h4>
          <p>Bicycle is generating relevant data based on your input.</p>
        </div>
      </div>
    </>
  );
};
/********************************************************************************************************************************************/
/********************************************************************************************************************************************/

export const SourceMapDialog: React.FC<Props> = props => {
  const [stepId, setStepId] = useState(SOURCEMAP_STEP_1);
  const [canProceed, SetCanProceed] = useState(true);
  const [nextLabel, SetNextLabel] = useState("Start Discovery");
  const [discoveredFieldNameDefs, setDiscoveredFieldNamesDefs] = useState<DiscoverFieldResponse[]>([]);

  const { onClose, title, widget, widgetResponseDTO } = props;

  const queryMappingStatus = widgetResponseDTO?.widgetConfig?.mappingStatus;
  const usFields = queryMappingStatus?.usFields || [];
  const unmappedDimensions = filter(usFields, ["field.isMappingIncomplete", true]);

  // console.log(dimensionList);

  const makeDiscoverCall = useCallback(async () => {
    const dimensionsList = unmappedDimensions.map(dimension => ({
      fieldName: dimension?.field?.fieldName,
      // eventType: "Search",
      eventTypeEntityId: dimension?.field.userServices[0]?.userServiceEntityId
    }));
    const promises = dimensionsList.map(dimension => {
      const request: FieldMappingOptionsRequest = {
        fieldName: dimension.fieldName,
        // eventType: dimension.eventType,
        eventTypeEntityId: dimension.eventTypeEntityId
      };
      return autoDiscoveryApiService.getFieldMappingOptions(request);
    });

    const responses = await Promise.all(promises);
    const fieldNameToDiscoveredFieldDef: any[] = [];

    dimensionsList.forEach((x, i) => {
      fieldNameToDiscoveredFieldDef.push({
        fieldName: x.fieldName,
        discoveredFieldDef: responses[i]?.discoveredFieldDef?.[0]
      });
    });
    setDiscoveredFieldNamesDefs(fieldNameToDiscoveredFieldDef);
    setStepId(SOURCEMAP_STEP_3);
    SetNextLabel("Save");
  }, [unmappedDimensions]);

  const onNext = useCallback(() => {
    if (stepId === SOURCEMAP_STEP_1) {
      setStepId(SOURCEMAP_STEP_2);
      makeDiscoverCall();
      SetCanProceed(false);
    } else if (stepId === SOURCEMAP_STEP_2) {
      setStepId(SOURCEMAP_STEP_3);
      SetNextLabel("Save");
      SetCanProceed(true);
    } else {
      onClose();
    }
  }, [makeDiscoverCall, onClose, stepId]);

  const actions: IncModalProps["actions"] = useMemo(
    () => ({
      primary: {
        onClick: onNext,
        color: "primary",
        disabled: !canProceed,
        label: nextLabel
      },
      secondary: {
        onClick: onClose,
        color: "secondary-blue",
        label: "Cancel"
      }
    }),
    [canProceed, nextLabel, onClose, onNext]
  );

  return (
    <IncModal
      actions={actions}
      className="sourcemap-dialog"
      closeOnBackdrop={false}
      closeOnEscape={false}
      onClose={onClose}
      show
      showClose
      size="lg"
      titleText={title}
    >
      <div className="sourcemap-dialog-content">
        {stepId === SOURCEMAP_STEP_1 && (
          <SourceMapScreen1
            backToPreviousStep={noOp}
            onStepChange={noOp}
            proceedToNextStep={onNext}
            queryMappingStatus={queryMappingStatus}
            setCanProceed={SetCanProceed}
            widget={widget}
          />
        )}

        {stepId === SOURCEMAP_STEP_2 && (
          <SourceMapLoader
            backToPreviousStep={noOp}
            onStepChange={noOp}
            proceedToNextStep={onNext}
            queryMappingStatus={queryMappingStatus}
            setCanProceed={SetCanProceed}
            widget={widget}
          ></SourceMapLoader>
        )}

        {stepId === SOURCEMAP_STEP_3 && (
          <SourceMapScreen2
            backToPreviousStep={noOp}
            discoveredFieldNameDefs={discoveredFieldNameDefs}
            onStepChange={noOp}
            proceedToNextStep={onNext}
            queryMappingStatus={queryMappingStatus}
            setCanProceed={SetCanProceed}
            widget={widget}
          />
        )}
      </div>
    </IncModal>
  );
};
