import { IncButton, IncDateTimeFormat, IncFaIcon, IncToolTip } from "@inception/ui";
import React, { useCallback, FC, useState } from "react";
import { TimeRangeSelector, QuickTab } from "../../components/time-range";
import { TimeRange, RawTimeRange } from "../../core/hooks";
import { VerticallyCenteredRow } from "../flex-components";
import { CompareTimeRangeSelector } from "./CompareTimeRangeSelector";

type Props = {
  timeRange: TimeRange;
  setTimeRange: (tr: RawTimeRange) => void;
  showTimeRangeSelector?: boolean;
  restrictedTimeRange?: TimeRange;

  compareTimeRange: TimeRange;
  setCompareTimeRange: (cTr: RawTimeRange) => void;
  showCompareSelector?: boolean;

  refresh?: () => void;
  disableRefresh?: boolean;

  disableTimeRangeSelector?: boolean;
  hideQuickTabs?: boolean;

  className?: string;
  disableTooltip?: boolean;
  size?: "default" | "small";
};

const REFRESH_INTERVAL_DELAY = 5000;

const TimeRangeController: FC<Props> = props => {
  const {
    timeRange,
    setTimeRange,
    showTimeRangeSelector,
    compareTimeRange,
    setCompareTimeRange,
    showCompareSelector,
    refresh,
    disableRefresh,
    disableTimeRangeSelector,
    hideQuickTabs,
    className = "",
    disableTooltip = false,
    size = "default",
    restrictedTimeRange
  } = props;

  const onTimeRangeChange = useCallback(
    (newTimeRange: TimeRange) => {
      setTimeRange(newTimeRange.raw);
    },
    [setTimeRange]
  );

  const tabs: QuickTab[] = ["Last"];

  const [temporaryRefreshDisable, setTemporaryRefreshDisable] = useState(false);

  const onRefresh = useCallback(() => {
    refresh();
    setTemporaryRefreshDisable(true);
    window.setTimeout(() => {
      setTemporaryRefreshDisable(false);
    }, REFRESH_INTERVAL_DELAY);
  }, [refresh]);

  const refreshButton = (
    <IncButton
      className="marginRt12"
      color="link"
      disabled={temporaryRefreshDisable}
      iconType="icon"
      id="time-range-refresh-button"
      onClick={onRefresh}
    >
      <IncFaIcon
        iconName="refresh"
        style={{ transform: "scale(0.85)" }}
      />
    </IncButton>
  );

  return (
    <>
      {showTimeRangeSelector && (
        <VerticallyCenteredRow className={className}>
          {showCompareSelector && (
            <CompareTimeRangeSelector
              compareTimeRange={compareTimeRange}
              setCompareTimeRange={setCompareTimeRange}
              size={size}
            />
          )}
          {!disableRefresh && (
            <>
              {disableTooltip && refreshButton}
              {!disableTooltip && (
                <IncToolTip
                  placement="top"
                  titleId="timerange.refresh"
                >
                  {refreshButton}
                </IncToolTip>
              )}
            </>
          )}
          <TimeRangeSelector
            allowFutureDates={false}
            allowSameTimes={false}
            buttonDisplay
            disableTooltip={disableTooltip}
            disabled={disableTimeRangeSelector}
            enableBeginningAndEndTimes={false}
            format={IncDateTimeFormat.numeric}
            hideQuickTabs={hideQuickTabs}
            hideTimeSelect={false}
            i18nDisabled={false}
            onTimeSelect={onTimeRangeChange}
            quickTabs={tabs}
            restrictedTimeRange={restrictedTimeRange}
            size={size}
            timeRange={timeRange}
            withSeconds={true}
          />
        </VerticallyCenteredRow>
      )}
    </>
  );
};

export default TimeRangeController;
