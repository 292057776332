import { ActionTemplateTextElement, ElementPropertyValues } from "../../../../../services/api/operationalise";
import { getDisplayTextForTextElement } from "./getContentState";

export const getDisplayTextForRichText = (
  richText: string,
  textElements: ActionTemplateTextElement[],
  properties: Record<string, ElementPropertyValues>
) => {
  const varRegex = /<<[\w-]+[\s]*\|[\s]*[\w.-]+>>/g;

  let displayText = "";

  let matchArr: RegExpExecArray;
  let contentStart = 0;

  while ((matchArr = varRegex.exec(richText)) !== null) {
    const match = matchArr[0];
    const matchLen = match.length;
    const matchIdx = matchArr.index;

    const prevWord = richText.substring(contentStart, matchIdx);
    if (prevWord) {
      displayText += prevWord;
    }

    contentStart = matchIdx + matchLen;

    const [elementId, tokenElementId] = match.replace(/&lt;&lt;|&gt;&gt;|<<|>>|[\s]*/g, "").split("|");
    const textEl = textElements.find(el => el.elementId === tokenElementId);

    if (textEl) {
      const elProperties = properties[elementId];
      const elDisplayText = getDisplayTextForTextElement(textEl, elProperties);
      displayText += elDisplayText;
    }
  }

  displayText += richText.substring(contentStart, richText.length);

  return displayText;
};
