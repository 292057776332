import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { generateId, IncCheckboxProps, IncFaIcon } from "@inception/ui";
import salesforceConfigData from "../dataset/salesforce.json";
import { CheckBoxList } from "../../checkbox-list";
import { VerticallyCenteredRow } from "../../flex-components";
import { SalesforceSubscriptionDataTemplate } from "./templates";

interface Props {
  setCanProceed: (canProceed: boolean) => void;
}

export const SalesforceTemplate: FC<Props> = props => {
  const { setCanProceed } = props;

  const defSelection = salesforceConfigData.structure.map(str => str.value);
  const [selectedSections, setSelectedSections] = useState<string[]>(defSelection);

  const onStructureOptionSelection = useCallback((id: string, checked: boolean) => {
    setSelectedSections(prev => {
      if (checked) {
        return [...prev, id];
      } else {
        return prev.filter(x => x !== id);
      }
    });
  }, []);

  const { tasks } = salesforceConfigData;
  const taskElements = useMemo(
    () => (
      <div className="inc-flex-column flex-gap-12">
        {tasks.map(task => (
          <VerticallyCenteredRow
            className="task-row"
            key={generateId()}
          >
            <VerticallyCenteredRow className="task-name">{task.label}</VerticallyCenteredRow>
            <VerticallyCenteredRow className="task-op">=</VerticallyCenteredRow>
            <VerticallyCenteredRow className="task-value">
              {task.value}
              <IncFaIcon
                className="marginLtAuto"
                iconName="chevron-down"
              />
            </VerticallyCenteredRow>
          </VerticallyCenteredRow>
        ))}
      </div>
    ),
    [tasks]
  );

  const channelStructure = useMemo(
    () =>
      salesforceConfigData.structure.map(structure => ({
        ...structure,
        checked: selectedSections.includes(structure.value)
      })),
    [selectedSections]
  );

  const structureElements = useMemo(
    () => (
      <CheckBoxList
        onChange={onStructureOptionSelection}
        options={channelStructure as IncCheckboxProps[]}
      />
    ),
    [channelStructure, onStructureOptionSelection]
  );

  useEffect(() => {
    const canProceed = tasks.length > 0 && selectedSections.length > 0;
    setCanProceed(canProceed);
  }, [selectedSections.length, setCanProceed, tasks.length]);

  return (
    <div className="section-wrapper salesforce-template-editor">
      <div className="section-wrapper--configuration">
        <div className="section p-t-0 border-0">
          <div className="section--title">Create a task</div>

          {taskElements}
        </div>

        <div className="section">
          <div className="section--title">What to include in the task?</div>

          <div className="section--description">
            Pick the content you want to receive when you get an alert for a violation
          </div>

          <div className="inc-flex-column multioptions--checkbox ">{structureElements}</div>
        </div>
      </div>

      <div className="section-wrapper--preview">
        <div className="section--title">Preview</div>
        <div className="preview-container">
          <SalesforceSubscriptionDataTemplate
            data={salesforceConfigData.structure}
            name={"salesforce"}
            selectedSections={selectedSections}
          />
        </div>
      </div>
    </div>
  );
};
