import React, { FC } from "react";
import { useTimeRange } from "../../core";
import TimeRangeController from "./TimeRangeController";

interface Props {}

export const GlobalTimeRangeSelector: FC<Props> = () => {
  const {
    timeRange,
    setTimeRange,
    showTimeRangeSelector,
    compareTimeRange,
    setCompareTimeRange,
    showCompareSelector,
    refresh,
    disableRefresh,
    disableTimeRangeSelector,
    restrictedTimeRange
  } = useTimeRange();

  return (
    <TimeRangeController
      compareTimeRange={compareTimeRange}
      disableRefresh={disableRefresh}
      disableTimeRangeSelector={disableTimeRangeSelector}
      refresh={refresh}
      restrictedTimeRange={restrictedTimeRange}
      setCompareTimeRange={setCompareTimeRange}
      setTimeRange={setTimeRange}
      showCompareSelector={showCompareSelector}
      showTimeRangeSelector={showTimeRangeSelector}
      timeRange={timeRange}
    />
  );
};
