import React, { memo, FC, useMemo, useCallback, useState, useEffect, useRef } from "react";
import { isEmpty } from "lodash";
import { IncFaIcon, getFormattedDateTime, IncDateTimeFormat } from "@inception/ui";
import { CohortConfig, exploreApiService } from "../../services/api/explore";
import { logger, useFetchEntityTypeName } from "../../core";
import { EntityIconRenderer } from "../entity-properties-popover/EntityNameRenderer";
import LoadingSpinner from "../Loading/Loading";
import usersApiService from "../../services/api/Admin/UsersApiService";
import { pluralizeWord } from "../../utils/Utils";
import { VerticallyCenteredRow } from "../flex-components";
import { CohortConfigPills } from "./CohortPillsWrapper";

interface Props {
  cohortDefinition: CohortConfig;
  bizEntityType: string;
  bizEntityTypeName?: string;
  showCreationDetails?: boolean;
  renderOnlyPills?: boolean;
  hideEntityIcon?: boolean;
  hidePills?: boolean;
}

export const CohortDefinitionRenderer: FC<Props> = memo(props => {
  const {
    cohortDefinition: wCohortDefinition,
    bizEntityType,
    bizEntityTypeName: pBizEntityTypeName,
    showCreationDetails = false,
    renderOnlyPills,
    hideEntityIcon = false,
    hidePills = false,
    children
  } = props;

  const [cohortLoading, setCohortLoading] = useState(true);
  const [cohortDefinition, setCohortDefinition] = useState<CohortConfig>(wCohortDefinition);
  const creationRef = useRef({
    createdBy: "Richard Harris",
    createdAt: 1639679400000
  });

  const { name: cName, cohortCreationConfig } = cohortDefinition || {};

  const fetchCohortDetails = useCallback(async () => {
    const shouldFetchCohort =
      !isEmpty(bizEntityType) &&
      !isEmpty(cohortDefinition?.cohortId) &&
      isEmpty(cohortDefinition?.cohortCreationConfig);
    const cohortId = cohortDefinition?.cohortId;

    let cDef: CohortConfig = cohortDefinition;

    setCohortLoading(true);

    if (shouldFetchCohort) {
      try {
        const res = await exploreApiService.getCohortConfig(cohortId, bizEntityType);
        if (res.cohortDefinition) {
          cDef = res.cohortDefinition;
        }
      } catch (e) {
        logger.error("Cohort details", "Error fetching cohort details", e);
      }
    }

    if (cDef && showCreationDetails) {
      const { user: createdByUser, timeMillis: createdAt } = cDef?.cohortCreationConfig?.createdBy || {};

      if (createdByUser) {
        const { data } = await usersApiService.getUserByEmailOrUsername(createdByUser);
        creationRef.current = {
          createdBy: data?.name || createdByUser,
          createdAt: parseInt(createdAt, 10)
        };
      }
    }

    setCohortDefinition(cDef);
    setCohortLoading(false);
  }, [bizEntityType, cohortDefinition, showCreationDetails]);

  useEffect(() => {
    fetchCohortDetails();
  }, [fetchCohortDetails]);

  const { entityTypeName: fBizEntityTypeName, fetchInProgress } = useFetchEntityTypeName(bizEntityType);

  const isVerbFlow = useMemo(() => isEmpty(bizEntityType), [bizEntityType]);

  const cohortName = useMemo(() => {
    let cohortName = cName;
    if (!cohortName) {
      const bizEntityTypeName = fBizEntityTypeName || pBizEntityTypeName;
      cohortName = bizEntityTypeName ? `All ${pluralizeWord(bizEntityTypeName)}` : "";
    }
    return cohortName;
  }, [cName, fBizEntityTypeName, pBizEntityTypeName]);

  const cohortConfigPills = useMemo(() => {
    const cohortConfigExists = !cohortLoading && !isEmpty(cohortCreationConfig);
    return cohortConfigExists && !hidePills ? (
      <div className="filter-pills">
        <CohortConfigPills cohortConfig={cohortDefinition} />
      </div>
    ) : (
      <></>
    );
  }, [cohortCreationConfig, cohortDefinition, cohortLoading, hidePills]);

  // Cohorts are not supported in verb flow
  if (isVerbFlow) {
    return null;
  }

  const createdAtStr = getFormattedDateTime(creationRef.current.createdAt, IncDateTimeFormat.minimal, {
    skipTime: true
  });
  const { createdBy } = creationRef.current;

  return (
    <div className="cohort-details-container">
      {cohortLoading && (
        <LoadingSpinner
          className="inc-text-subtext-medium"
          position="center"
          titleText="Loading cohort details..."
        />
      )}
      {!cohortLoading && !renderOnlyPills && (
        <>
          {!hideEntityIcon && (
            <div className="rectangle-icon marginRt16">
              {bizEntityType && <EntityIconRenderer entityType={bizEntityType} />}
            </div>
          )}
          <div className="cohort-details">
            <VerticallyCenteredRow>
              <div className="cohort-name">
                {!cohortName && fetchInProgress && <LoadingSpinner />}
                {cohortName}
              </div>
              {children}
            </VerticallyCenteredRow>
            {showCreationDetails && (
              <div className="marginTp4 inc-flex-row">
                <div className="cohort-subtext marginLt16">Created by</div>
                <div className="marginLt8 cohort-subtext">
                  <UserRenderer userName={createdBy} />
                </div>
                <div className="cohort-subtext marginLt4">on</div>
                <div className="cohort-subtext marginLt4">{createdAtStr}</div>
              </div>
            )}
            {cohortConfigPills}
          </div>
        </>
      )}
      {!cohortLoading && renderOnlyPills && cohortConfigPills}
    </div>
  );
});

interface UserRendererProps {
  userName: string;
}

const UserRenderer: React.FC<UserRendererProps> = props => {
  const { userName } = props;
  return (
    <div className="inc-flex-row inc-flex-center-vertical">
      <IncFaIcon iconName="user-circle" />
      <span className="marginLt6">{userName}</span>
    </div>
  );
};
