export const ENTITY_RANGE_DATA_TEXT_ELEMENT_KEY = "textEl";
export const ENTITY_RANGE_DATA_MATCH_KEY = "matchStr";
export const ENTITY_RANGE_DATA_DISPLAY_TEXT_KEY = "displayText";
export const ENTITY_RANGE_DATA_PROPERTIES_KEY = "properties";

export const BLOCK_DATA_SECTION_KEY = "section";

export const WEB_LINK_ELEMENT_ID = "web-link";
export const WEB_LINK_DISPLAY_TEXT_PROP_NAME = "prop-display-text";
export const WEB_LINK_URL_PROP_NAME = "prop-url";

export const EVENT_GROUPBY_IMPACTED_WIDGET_PROP_ID = "event-groupby-param-widget";
export const EVENT_GROUPBY_FIELDS_PROP_ID = "event-groupby-param-fields";
export const EVENT_GROUPBY_MAX_ROWS_PROP_ID = "event-groupby-max-rows";
