import { useMemo } from "react";
import { cloneDeep, clone } from "lodash";
import { USFWProps } from "../../types";
import { useAddToDashboardAction } from "../../../widget-components";
import { WidgetResponseDTO } from "../../../../../services/api/explore";

type Props = {
  widget: USFWProps["widget"];
  dbImpl: USFWProps["dbImpl"];
  widgetResponseDTO: WidgetResponseDTO;
  propertiesChanged: boolean;
};

export const useUSFWAddToDashboardAction = (props: Props) => {
  const { widget, dbImpl, widgetResponseDTO, propertiesChanged } = props;

  const { id: widgetId } = widget;

  const saveWidget = useMemo(() => {
    const cloneWidget = cloneDeep(widget);
    cloneWidget.title = widgetResponseDTO?.widgetConfig?.name;
    cloneWidget.renderMode = "view";
    return cloneWidget;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget, widgetResponseDTO, propertiesChanged]);

  const layout = useMemo(() => {
    const layout = clone(dbImpl.getLayoutById(widgetId));
    delete layout.x;
    delete layout.y;

    return layout;
  }, [dbImpl, widgetId]);

  const { addToDashboardAction: addToDashboardActionComponent, addToDashboardActionModal } = useAddToDashboardAction(
    saveWidget,
    dbImpl.id,
    undefined,
    undefined,
    layout
  );

  return {
    addToDashboardActionComponent,
    addToDashboardActionModal
  };
};
