import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IncFaIcon, IncSelect, IncSelectOption } from "@inception/ui";
import { castArray, random } from "lodash";
import filterData from "../dataset/filterData.json";
import { UserServiceFilterExpression } from "../../../services/api/explore";
import { getOperators } from "../../../utils/ExploreUtils";
import { VerticallyCenteredRow } from "../../flex-components";
import { PreviewSubscriptionDataTemplate } from "./templates";
import { CommonStepProps } from "./types";

interface Props extends CommonStepProps {
  customFilters?: {
    sampleValues: Record<string, string[]>;
    filters: UserServiceFilterExpression[];
    dataTypes?: Record<string, string>;
  };
}

export const SubscribeDialogContentStep3: FC<Props> = props => {
  const { setCanProceed, customFilters } = props;

  useEffect(() => {
    setCanProceed(true);
  }, [setCanProceed]);

  const { sampleValues, filters: eFilters, dataTypes } = customFilters || {};

  const fData = filterData.data;

  const ref = useRef<HTMLDivElement>();
  const [tempFilters, setTempFilters] = useState(
    eFilters?.length
      ? eFilters
      : [
          {
            field: null,
            operator: "=",
            value: null
          }
        ]
  );

  const conditions = useMemo(
    () =>
      tempFilters.map((tempFilter, idx) => {
        const { field, operator, value, values } = tempFilter;

        const key = [field?.fieldName, operator, value || "", ...(values || []), idx].join("_");
        const onChange = (filter: UserServiceFilterExpression) =>
          setTempFilters(prev => {
            const next = [...prev];
            next[idx] = filter;
            return next;
          });

        const onRemove = () =>
          setTempFilters(prev => {
            const next = [...prev];
            next.splice(idx, 1);
            return next;
          });

        const onAdd = () =>
          setTempFilters(prev => [
            ...prev,
            {
              field: null,
              operator: "=",
              value: ""
            }
          ]);

        return (
          <FilterRow
            canDelete={tempFilters.length > 1}
            dataTypes={dataTypes}
            filter={tempFilter}
            key={key}
            onAdd={onAdd}
            onChange={onChange}
            onRemove={onRemove}
            sampleValues={sampleValues}
          />
        );
      }),
    [dataTypes, sampleValues, tempFilters]
  );

  const data = useMemo(() => {
    if (sampleValues) {
      const tagKey = Object.keys(sampleValues)[0];
      return {
        name: tagKey,
        data: (sampleValues[tagKey] || []).map(tagValue => ({
          name: tagValue,
          value: `${random(50, 100, true).toFixed(1)}k`
        }))
      };
    }

    return fData;
  }, [fData, sampleValues]);

  return (
    <div
      className="wizard-step"
      ref={ref}
    >
      <div className="wizard-step--title inc-text-subtext-medium inc-text-inactive">
        Choose what dimensions you would like to subscribe to.
      </div>
      <div className="section-wrapper">
        <div className="section-wrapper--configuration">
          <div className="section border-0 p-t-0">
            <div className="section--title">Filter by Dimensions</div>
            {conditions}
          </div>
        </div>
        <div className="section-wrapper--preview">
          <div className="section--title">Preview</div>
          <div className="preview-container">
            <PreviewSubscriptionDataTemplate
              data={data.data}
              name={data.name}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

type FilterRowProps = {
  filter: UserServiceFilterExpression;
  canDelete: boolean;
  sampleValues: Record<string, string[]>;
  dataTypes: Record<string, string>;
  onAdd: () => void;
  onRemove: () => void;
  onChange: (filter: UserServiceFilterExpression) => void;
};

const FilterRow: FC<FilterRowProps> = memo(props => {
  const { canDelete, filter, onAdd, onChange, onRemove, sampleValues, dataTypes } = props;

  const { field, operator, value, values } = filter;

  const tagKey = field?.fieldName;
  const isMultiValue = ["in", "not in"].includes(operator);

  const tagKeyOpts = useMemo<IncSelectOption[]>(
    () =>
      Object.keys(sampleValues || {}).map(key => ({
        label: key,
        value: key
      })),
    [sampleValues]
  );
  const tagKeyOpt = useMemo(() => tagKeyOpts.find(opt => opt.value === tagKey), [tagKey, tagKeyOpts]);

  const tagValueOpts = useMemo<IncSelectOption[]>(() => {
    const values = (sampleValues || {})[tagKey] || [];
    return values.map(value => ({
      label: value,
      value
    }));
  }, [sampleValues, tagKey]);
  const tagValueOpt = useMemo<IncSelectOption | IncSelectOption[]>(() => {
    if (isMultiValue) {
      return (values || []).map(value => ({
        label: value,
        value
      }));
    }

    return value
      ? {
          label: value,
          value
        }
      : null;
  }, [isMultiValue, value, values]);

  const operatorOpts = useMemo(() => getOperators(dataTypes?.[tagKey] as any), [dataTypes, tagKey]);
  const operatorOpt = useMemo(() => operatorOpts.find(op => op.value === operator), [operator, operatorOpts]);

  const onTagKeyChange = useCallback(
    (opt: IncSelectOption) => {
      onChange({
        field: {
          fieldName: opt.value
        } as any,
        operator: "=",
        value: null,
        values: null
      });
    },
    [onChange]
  );

  const onOperatorChange = useCallback(
    (opt: IncSelectOption) => {
      const isMultiValue = ["in", "not in"].includes(opt.value);

      onChange({
        ...filter,
        operator: opt.value,
        value: isMultiValue ? null : filter.value,
        values: isMultiValue ? (filter.values ? filter.values : filter.value ? castArray(filter.value) : []) : []
      });
    },
    [filter, onChange]
  );

  const onValueChange = useCallback(
    (opt: IncSelectOption) => {
      onChange({
        ...filter,
        value: opt.value
      });
    },
    [filter, onChange]
  );

  const onValuesChange = useCallback(
    (opt: readonly IncSelectOption[]) => {
      onChange({
        ...filter,
        values: opt.map(o => o.value)
      });
    },
    [filter, onChange]
  );

  return (
    <VerticallyCenteredRow className="flex-gap-12">
      <IncSelect
        onChange={onTagKeyChange}
        options={tagKeyOpts}
        placeholder="Dimension"
        value={tagKeyOpt}
        wrapperClass="width-30"
      />

      <IncSelect
        onChange={onOperatorChange}
        options={operatorOpts}
        value={operatorOpt}
        wrapperClass="width-20"
      />

      {isMultiValue && (
        <IncSelect
          isMulti
          onChange={onValuesChange}
          options={tagValueOpts}
          placeholder="Values"
          value={tagValueOpt as IncSelectOption[]}
          wrapperClass="width-30"
        />
      )}

      {!isMultiValue && (
        <IncSelect
          onChange={onValueChange}
          options={tagValueOpts}
          placeholder="Value"
          value={tagValueOpt as IncSelectOption}
          wrapperClass="width-30"
        />
      )}

      <IncFaIcon
        className="status-info inc-cursor-pointer"
        iconName="circle-plus"
        onClick={onAdd}
      />

      {canDelete && (
        <IncFaIcon
          className="status-danger inc-cursor-pointer"
          iconName="circle-minus"
          onClick={onRemove}
        />
      )}
    </VerticallyCenteredRow>
  );
});
