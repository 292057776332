import Axios, { CancelTokenSource } from "axios";
import { useCallback, useRef } from "react";
import { useFetchDataStatus } from "../../../platform/core";
import autoDiscoveryApiService from "../../../platform/services/api/auto-discovery/AutoDiscoveryApiService";
import {
  DataTransformationPreviewRequest,
  DataTransformationPreviewResponse
} from "../../../platform/services/api/auto-discovery/types";

export const useFetchDataTransformation = () => {
  const { data, isFetching, isSuccess, isError, setStatus } = useFetchDataStatus<DataTransformationPreviewResponse>();

  const cancelTokenSource = useRef<CancelTokenSource>(null);

  const fetchPreview = useCallback(
    async (payload: DataTransformationPreviewRequest, fromMillis: number, toMillis: number) => {
      let response = null;
      let isAxiosCancelError = false;
      try {
        if (cancelTokenSource.current) {
          cancelTokenSource.current.cancel();
        }
        cancelTokenSource.current = Axios.CancelToken.source();
        setStatus(null, true, false, false, null);
        response = await autoDiscoveryApiService.getDataTransformationPreview(
          payload,
          cancelTokenSource.current.token,
          fromMillis,
          toMillis
        );
      } catch (error) {
        isAxiosCancelError = Axios.isCancel(error);
        if (!isAxiosCancelError) {
          setStatus(null, false, false, true, error);
          return;
        }
      }
      if (!isAxiosCancelError) {
        if (response) {
          setStatus(response, false, true, false, null);
        } else {
          setStatus(null, false, false, true, null);
        }
      }
    },
    [setStatus]
  );

  return {
    data,
    isFetching,
    isSuccess,
    isError,
    fetchPreview
  };
};
