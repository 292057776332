import { IncFaIcon, IncToolTip, generateId } from "@inception/ui";
import { clone, cloneDeep, isUndefined } from "lodash";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import * as PropRenderers from "./renderers";
import { WidgetPropertiesSection, WidgetProperty, WidgetPropertyTypes } from "./types";

interface SectionRendererProps {
  sections: WidgetPropertiesSection[];
  defaultCollapse?: boolean;
  onUpdate?: (newSections: WidgetPropertiesSection[]) => void;
}

interface PropertiesRendererProps {
  properties: WidgetProperty[];
  onUpdate: (newProperties: WidgetProperty[]) => void;
}

interface PropertyRendererProps {
  property: WidgetProperty;
  onUpdate: (newProperty: WidgetProperty) => void;
}

interface ExtWidgetPropertiesSection extends WidgetPropertiesSection {
  collapse: boolean;
}

const WidgetProperties: FC<SectionRendererProps> = props => {
  const { sections: usrSections, defaultCollapse = true, onUpdate = () => {} } = props;

  const [sections, setSections] = useState<Map<string, ExtWidgetPropertiesSection>>(new Map());
  const shouldCallParentUpdate = useRef(false);

  useEffect(() => {
    setSections(oldSections => {
      const newSections = new Map<string, ExtWidgetPropertiesSection>();
      usrSections.forEach((sec, i) => {
        const { name: secName } = sec;

        // Preserve collapse on re-renders
        const oCollapse = oldSections.get(secName)?.collapse;
        const collapse = isUndefined(oCollapse) ? defaultCollapse && i !== 0 : oCollapse;
        const nSec: ExtWidgetPropertiesSection = {
          ...cloneDeep(sec),
          collapse
        };
        newSections.set(secName, nSec);
      });
      shouldCallParentUpdate.current = false;
      return newSections;
    });
  }, [defaultCollapse, usrSections]);

  useEffect(() => {
    if (shouldCallParentUpdate.current) {
      const secArray = Array.from(sections.values());
      onUpdate(secArray);
    }
  }, [onUpdate, sections]);

  const onSectionUpdated = useCallback((newProperties: WidgetProperty[], name: string) => {
    setSections(oldSections => {
      const newSections = clone(oldSections);
      newSections.get(name).properties = newProperties;
      shouldCallParentUpdate.current = true;
      return newSections;
    });
  }, []);

  const onSectionNameClick = useCallback((name: string) => {
    setSections(oldSections => {
      const newSections = clone(oldSections);
      const section = newSections.get(name);
      section.collapse = !section.collapse;
      return newSections;
    });
  }, []);

  const secArray = Array.from(sections.values());
  const sectionElements = secArray.map(section => {
    const { name, info = "", properties, collapse } = section;

    const sectionClassName = `section-name ${collapse ? "collapse" : ""}`;
    const onSectionClick = () => onSectionNameClick(name);
    const onPropsUpdate = (nProps: WidgetProperty[]) => onSectionUpdated(nProps, name);
    const icon = collapse ? "caret-right" : "caret-down";
    const wrapperClassName = `widget-properties--section section-${collapse ? "close" : "open"}`;

    return (
      <div
        className={wrapperClassName}
        key={name}
      >
        {name && (
          <div
            className={sectionClassName}
            onClick={onSectionClick}
          >
            <IncFaIcon
              className="marginRt8"
              iconName={icon}
            />
            {name}
            {info && (
              <IncToolTip
                placement="top-start"
                titleText={info}
              >
                <IncFaIcon
                  className="marginLt8"
                  iconName="info-circle"
                />
              </IncToolTip>
            )}
          </div>
        )}
        {!collapse && (
          <PropertiesRenderer
            onUpdate={onPropsUpdate}
            properties={properties}
          />
        )}
      </div>
    );
  });

  return <div className="widget-properties">{sectionElements}</div>;
};

const PropertiesRenderer: FC<PropertiesRendererProps> = props => {
  const { properties, onUpdate } = props;

  return (
    <div className="widget-properties--properties">
      {properties.map((property, ind) => {
        const onPropertyUpdated = (newProperty: WidgetProperty) => {
          const newProperties = [...properties];
          newProperties[ind] = newProperty;
          onUpdate(newProperties);
        };

        return (
          <PropertyRenderer
            key={generateId()}
            onUpdate={onPropertyUpdated}
            property={property}
          />
        );
      })}
    </div>
  );
};

const PropertyRenderer: FC<PropertyRendererProps> = props => {
  const { property, onUpdate } = props;
  const { type } = property;

  let Component: any;

  switch (type) {
    case WidgetPropertyTypes.color:
      Component = PropRenderers.ColorPropertyRenderer;
      break;

    case WidgetPropertyTypes.datetime:
      Component = PropRenderers.DateTimePropertyRenderer;
      break;

    case WidgetPropertyTypes.number:
      Component = PropRenderers.NumberPropertyRenderer;
      break;

    case WidgetPropertyTypes.radio:
      Component = PropRenderers.RadioPropertyRenderer;
      break;

    case WidgetPropertyTypes.select:
      Component = PropRenderers.SelectPropertyRenderer;
      break;

    case WidgetPropertyTypes.text:
      Component = PropRenderers.TextPropertyRenderer;
      break;

    case WidgetPropertyTypes.toggle:
      Component = PropRenderers.TogglePropertyRenderer;
      break;

    case WidgetPropertyTypes.textarea:
      Component = PropRenderers.TextAreaPropertyRenderer;
      break;

    default:
      Component = PropRenderers.InvalidPropertyRenderer;
      break;
  }

  return (
    <div className="widget-property">
      <Component
        onUpdate={onUpdate}
        property={property}
      />
    </div>
  );
};

export default WidgetProperties;
