import { IncSelect, IncSelectOption, IncTextfield } from "@inception/ui";
import { debounce } from "lodash";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { TypedObjectValue, TypeObjectValKeys } from "../../services/api/operationalise";

interface PropertyValueCompProps {
  config: TypedObjectValue;
  onChange: (propertyValue: TypedObjectValue) => void;
}

export const PropertyValueComp: React.FC<PropertyValueCompProps> = props => {
  const { config, onChange } = props;

  const [textValue, setTextValue] = useState<string>("");
  const [boolValue, setBoolValue] = useState<IncSelectOption>();

  const boolOptions: IncSelectOption[] = useMemo(
    () =>
      ["true", "false"].map(op => ({
        label: op,
        value: op
      })),
    []
  );

  const valKey = useMemo(() => (config ? (Object.keys(config)[0] as TypeObjectValKeys) : null), [config]);

  useEffect(() => {
    if (!config) {
      return;
    }

    const value = config[valKey];
    if (value != null) {
      if (valKey === "booleanVal") {
        const selectedBool = boolOptions.find(op => JSON.parse(op.value) === value);
        setBoolValue(selectedBool);
      } else {
        setTextValue(value.toString());
      }
    }
  }, [config, boolOptions, valKey]);

  const onValueChange = useCallback(
    (value: string) => {
      let val: string | number | boolean = null;
      switch (valKey) {
        case "longVal":
        case "doubleVal": {
          const numVal = Number(value);
          val = isNaN(numVal) ? 0 : numVal;
          break;
        }
        case "booleanVal":
          val = value === "true";
          break;
        case "stringVal":
        case "dateVal":
        case "dateTimeVal":
          val = value;
          break;
        //  case "setValue":
        //  case "mapValue":
        default:
          break;
      }
      onChange({ [valKey]: val });
    },
    [valKey, onChange]
  );

  const debouncedOnchange = useMemo(() => debounce(onValueChange, 500), [onValueChange]);

  const onTextValueChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const textVal = evt.target.value;
      if (valKey === "doubleVal" && !isDoubleStr(textVal)) {
        return;
      }
      setTextValue(textVal);
      debouncedOnchange(textVal);
    },
    [valKey, debouncedOnchange]
  );

  const onBoolValueChange = useCallback(
    (bool: IncSelectOption) => {
      onValueChange(bool.value);
    },
    [onValueChange]
  );

  return (
    <>
      {valKey === "booleanVal" ? (
        <IncSelect
          onChange={onBoolValueChange}
          options={boolOptions}
          value={boolValue}
        />
      ) : (
        <IncTextfield
          label={"Value"}
          onChange={onTextValueChange}
          placeholder={"E.g: Bangalore, 54, 1.2, false"}
          type={valKey === "longVal" ? "number" : "text"}
          value={textValue}
        />
      )}
    </>
  );
};

function isDoubleStr(str: string) {
  const regExp = new RegExp(/^[0-9]+\.?([0-9]+)?$/);
  const x = regExp.test(str);
  return x;
}
