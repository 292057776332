import React, { FC, useState, useMemo, useEffect, useCallback } from "react";
import {
  IncSelectOption,
  useRenderOnVisibility,
  IncSelect,
  TableDataColumn,
  IncToolTip,
  IncFaIcon
} from "@inception/ui";
import {
  ImpactedWidgetList,
  UserServiceFilterList,
  ImpactedWidget,
  ExploreEntityFilter,
  TriagePageConfigPayload,
  ImpactWidgetDataPostAgg,
  TimeObjUnit
} from "../../../services/api/explore";
import { VerticallyCenteredRow } from "../../flex-components";
import { OpSchema } from "../../../services/api/operationalise";
import { TimeRange, useTimeRange, logger } from "../../../core";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import LoadingSpinner from "../../Loading/Loading";
import { ImpactedWidgetsRenderer } from "../impact-widget";
import { getMetricIdForImpactWidget } from "../utils";
import { useFetchImpactedWidgetsData, getGroupKey } from "../impact-widget/hooks";
import TimeSeries from "../../time-series/TimeSeries";
import { IncTimeSeriesOptions } from "../../time-series/types";
import kbn from "../../../services/datasources/core/kbn";
import { TimeRangeSelector } from "../../time-range";
import { getAccessorHeaderMap, getTagsDataAndTimeseries } from "./utils";

interface Props {
  widgetId: string;
  triageFetchLabels: Record<string, string>;
  isLoading: boolean;
  opSchema: OpSchema;

  pageConfig: TriagePageConfigPayload;

  impactedWidgetList: ImpactedWidgetList;
  incidentId: string;
  opConfigId: string;

  entityType: string;
  cohortId: string;

  entityFilters?: ExploreEntityFilter[];
  eventFieldFilters?: UserServiceFilterList;

  timeRange?: TimeRange;
  generateDemoData?: boolean;
}

export const CompareTimeline: FC<Props> = props => {
  const { wasVisibleOnce, ref } = useRenderOnVisibility();

  const {
    impactedWidgetList,
    pageConfig,
    opSchema,
    entityFilters,
    eventFieldFilters,
    incidentId,
    opConfigId,
    timeRange: pTimeRange,
    generateDemoData,
    ...restProps
  } = props;

  const { timeRange: gTimeRange } = useTimeRange();

  const [timeRange, setTimeRange] = useState(pTimeRange || gTimeRange);

  const resetTimeRange = useCallback(() => {
    setTimeRange(gTimeRange);
  }, [gTimeRange]);

  const { startTimeMillis, endTimeMillis } = useMemo(() => {
    const rawTr = timeRange.raw;
    const { from, to } = timeRangeUtils.getTimeRangeMillisFromRaw(rawTr);

    return {
      startTimeMillis: from,
      endTimeMillis: to
    };
  }, [timeRange]);

  const shouldShowReset = useMemo(
    () => startTimeMillis !== gTimeRange.from.valueOf() || endTimeMillis !== gTimeRange.to.valueOf(),
    [startTimeMillis, endTimeMillis, gTimeRange]
  );

  const preset = pageConfig?.compareTimeline;

  const { alertingEventFields, diagnosticEventFields } = opSchema;

  const { impactedWidgets = [] } = impactedWidgetList || {};

  const { impactedWidgetId, title, groupBy: pGroupBy } = preset || {};

  const options = useMemo<Option[]>(
    () =>
      impactedWidgets.map(impactedWidget => ({
        label: impactedWidget.name,
        value: impactedWidget.id,
        data: impactedWidget
      })),
    [impactedWidgets]
  );
  const defSelOpt = options.find(({ value }) => value === impactedWidgetId) || options[0];

  const [selImpactedWidget, setSelImpactedWidget] = useState(defSelOpt);
  const [tsSeries, setTsSeries] = useState<IncTimeSeriesOptions[]>([]);
  const [iwProps, setIWProps] = useState<IWProps>();

  const impactedWidget = selImpactedWidget?.data;
  const qImpactedWidgetId = impactedWidget?.id;
  const displayTitle = title || "Compare";

  const { accessorHeaderMap, entityTags } = useMemo(
    () => getAccessorHeaderMap(alertingEventFields, diagnosticEventFields),
    [alertingEventFields, diagnosticEventFields]
  );

  const impactedWidgetsLists = useMemo(() => [impactedWidgetList], [impactedWidgetList]);

  const userServiceFilters = useMemo(() => {
    const userServiceFilters: Record<string, UserServiceFilterList> = {};

    if (eventFieldFilters && impactedWidget) {
      const metricIdKey = getMetricIdForImpactWidget(impactedWidget);
      if (metricIdKey) {
        userServiceFilters[metricIdKey] = eventFieldFilters;
      }
    }

    return userServiceFilters;
  }, [eventFieldFilters, impactedWidget]);

  const qImpactedWidgets = useMemo(() => [impactedWidget], [impactedWidget]);
  const groupBy = useMemo(
    () => (pGroupBy?.length ? pGroupBy : (alertingEventFields?.slices || []).map(sl => sl.tagName)),
    [alertingEventFields, pGroupBy]
  );
  const groupKey = getGroupKey(groupBy);

  const overTimeAgg = useMemo<ImpactWidgetDataPostAgg>(() => {
    const { intervalMs } = kbn.calculateInterval(
      {
        from: startTimeMillis,
        to: endTimeMillis
      },
      null,
      "60s"
    );

    const intervalSecs = timeRangeUtils.getSecondsFromMillis(intervalMs);

    return {
      timeDuration: {
        unit: TimeObjUnit.seconds,
        value: intervalSecs
      }
    };
  }, [endTimeMillis, startTimeMillis]);

  const { refetch, resultsByImpactedWidget } = useFetchImpactedWidgetsData(
    qImpactedWidgets,
    incidentId,
    opConfigId,
    groupBy,
    entityFilters,
    userServiceFilters,
    startTimeMillis,
    endTimeMillis,
    groupBy,
    overTimeAgg,
    null,
    null,
    null,
    null,
    generateDemoData
  );

  const { data, error, isError, isFetching } = resultsByImpactedWidget[qImpactedWidgetId]?.[groupKey] || {};

  useEffect(() => {
    if (impactedWidget && wasVisibleOnce) {
      setIWProps(null);
      refetch();
    }
  }, [impactedWidget, refetch, startTimeMillis, endTimeMillis, entityFilters, userServiceFilters, wasVisibleOnce]);

  useEffect(() => {
    if (!isFetching) {
      if (isError) {
        logger.error("Compare Timeline", "Error fetching timeline data", error);
      } else {
        const { lookupData, tagsData, tsSeries } = getTagsDataAndTimeseries(data, accessorHeaderMap, groupBy);

        setIWProps({
          lookupData,
          tagsData
        });
        setTsSeries(tsSeries);
      }
    }
  }, [accessorHeaderMap, data, error, groupBy, isError, isFetching]);

  return (
    <div
      className="compare-timeline"
      ref={ref}
    >
      <VerticallyCenteredRow className="compare-timeline--header">{displayTitle}</VerticallyCenteredRow>

      <VerticallyCenteredRow className="transparent-select-container padding12">
        <IncSelect
          alignment="row"
          isSearchable={false}
          label=""
          onChange={setSelImpactedWidget}
          options={options}
          value={selImpactedWidget}
          wrapperClass="impact-widget-select"
        />

        <VerticallyCenteredRow className="marginLtAuto">
          {shouldShowReset && (
            <IncToolTip
              placement="top"
              titleText="Set page time range"
            >
              <VerticallyCenteredRow
                className="inc-cursor-pointer status-info marginRt16"
                onClick={resetTimeRange}
              >
                <IncFaIcon
                  className="marginRt4"
                  iconName="chevron-left"
                  style={{ transform: "scale(0.75)" }}
                />
                <VerticallyCenteredRow>Back</VerticallyCenteredRow>
              </VerticallyCenteredRow>
            </IncToolTip>
          )}
          <TimeRangeSelector
            buttonDisplay
            onTimeSelect={setTimeRange}
            timeRange={timeRange}
          />
        </VerticallyCenteredRow>
      </VerticallyCenteredRow>

      {wasVisibleOnce && (
        <div className="compare-timeline--content">
          <div className="compare-timeline--primary-slice with-padding">
            {isFetching && <LoadingSpinner className="loading-message" />}
            {!isFetching && (
              <>
                {!isError && (
                  <>
                    {Boolean(tsSeries.length) && (
                      <div className="compare-timeline--primary-slice-chart">
                        <TimeSeries
                          containerElemClass="compare-timeline--primary-slice-chart"
                          series={tsSeries}
                          timeRange={timeRange}
                          title=""
                          type="spline"
                        />
                      </div>
                    )}
                    {!tsSeries.length && (
                      <VerticallyCenteredRow className="no-data-message">No data found</VerticallyCenteredRow>
                    )}
                  </>
                )}
                {isError && (
                  <VerticallyCenteredRow className="error-message">Error fetching data</VerticallyCenteredRow>
                )}
              </>
            )}
          </div>

          {Boolean(iwProps) && !isFetching && !isError && (
            <>
              <div className="separator" />

              <div className="compare-timeline--secondary-viz with-padding">
                <ImpactedWidgetsRenderer
                  impactedWidgetsLists={impactedWidgetsLists}
                  {...restProps}
                  {...iwProps}
                  accessorHeaderMap={accessorHeaderMap}
                  addlColumns={addlColumns}
                  entityFilters={entityFilters}
                  entityTags={entityTags}
                  eventFieldFilters={eventFieldFilters}
                  hideGroupBy
                  hideStatusColumn
                  incidentId={incidentId}
                  opConfigId={opConfigId}
                  tagsToDisplay={groupBy}
                  timeRange={timeRange}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

type Option = IncSelectOption<ImpactedWidget>;

type IWProps = {
  tagsData: Array<Record<string, string>>;
  lookupData: Record<string, string>;
};

const addlColumns: TableDataColumn[] = [
  {
    accessor: "color",
    header: "",
    renderer: backgroundColor => (
      <div
        className="status-pill--pill"
        style={{
          backgroundColor
        }}
      />
    ),
    width: 40,
    index: 0,
    className: "status-pill"
  }
];
