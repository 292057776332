import { TypedObjectValue, TypeObjectValKeys } from "../../services/api/operationalise";
import { DataType, FieldPrimType } from "../../core";

export type TransformType =
  | "staticValue"
  | "typeConversion"
  | "conditional"
  | "arithmetic"
  | "regrouping"
  | "arrayReduction"
  | "script"
  | "location"
  | "dateTransformation"
  | "druidTransformationConfig"
  | "none";
export type AggregationOperator =
  | "sum"
  | "avg"
  | "max"
  | "min"
  | "percentile"
  | "count"
  | "not_set"
  | "make_set"
  | "first"
  | "last"
  | "nth";

export const AggregationOperatorMap: Record<AggregationOperator, string> = {
  not_set: "not_set",
  sum: "sum",
  avg: "avg",
  max: "max",
  min: "min",
  percentile: "percentile",
  count: "count",
  first: "first",
  last: "last",
  nth: "nth",
  make_set: "make_set"
};

export const PercentileLabelMap: Record<string, number> = {
  P50: 50,
  P90: 90,
  P95: 95,
  P99: 99
};

export const PropertyValueKeyMap: Record<TypeObjectValKeys, DataType> = {
  longVal: "LONG",
  doubleVal: "DOUBLE",
  stringVal: "STRING",
  booleanVal: "BOOLEAN",
  dateVal: "DATE",
  dateTimeVal: "DATETIME",
  setValue: "SET",
  mapValue: "MAP",
  objectMapVal: "OBJECT_MAP"
};

export const DataTypeToPropertyKeyMap: Record<DataType, TypeObjectValKeys> = {
  ADDRESS: "stringVal",
  BOOLEAN: "booleanVal",
  CURRENCY: "doubleVal",
  DATE: "dateVal",
  DATETIME: "dateTimeVal",
  DATE_STRING: "stringVal",
  DOUBLE: "doubleVal",
  DURATION: "longVal",
  DURATION_MILLIS: "longVal",
  DURATION_SECS: "longVal",
  EMAIL: "stringVal",
  ENTITY: "stringVal",
  EPOCH: "longVal",
  EPOCH_MILLIS: "longVal",
  EPOCH_SECS: "longVal",
  EPOCH_MICROS: "longVal",
  ERROR: "stringVal",
  GEOLOCATION: "stringVal",
  IP_ADDRESS: "stringVal",
  LIST_DOUBLE: "mapValue",
  LIST_ENTITY: "mapValue",
  LIST_STRING: "mapValue",
  LIST_LONG: "mapValue",
  LONG: "longVal",
  MAP: "mapValue",
  NUMBER: "doubleVal",
  OBJECT_MAP: "objectMapVal",
  PERCENT_1: "doubleVal",
  PERCENT_100: "doubleVal",
  SET: "setValue",
  STRING: "stringVal",
  TELEPHONE: "stringVal",
  URL: "stringVal",
  ZIP_CODE: "stringVal",
  DURATION_MINS: "longVal",
  DURATION_HOURS: "longVal",
  DURATION_DAYS: "longVal"
};

export const TransformationTypeList: Record<TransformType, string> = {
  arithmetic: "Arithmetic",
  arrayReduction: "Array Reduction",
  script: "Script",
  staticValue: "Static Value",
  typeConversion: "Type Conversion",
  regrouping: "Regrouping",
  conditional: "Conditional",
  location: "Location",
  dateTransformation: "Date Time",
  druidTransformationConfig: "Druid",
  none: "none"
};

export enum EpochConversionType {
  TO_EPOCH_MILLIS = "TO_EPOCH_MILLIS",
  TO_EPOCH_MICROS = "TO_EPOCH_MICROS"
}

export enum DateFormat {
  NOTSET = "NotSet",
  ISO_DATE_TIME = "ISO_DATE_TIME"
}

export const DateTimeFormatMap: Record<DateFormat, string> = {
  [DateFormat.NOTSET]: "Not set",
  [DateFormat.ISO_DATE_TIME]: "Iso date time"
};

export const EpochConversionTypeMap: Record<EpochConversionType, string> = {
  [EpochConversionType.TO_EPOCH_MICROS]: "To epoch micros",
  [EpochConversionType.TO_EPOCH_MILLIS]: "To epoch millis"
};

export enum DestLocationType {
  Zip = "Zip",
  City = "City",
  State = "State",
  Country = "Country",
  Continent = "Continent"
}

export enum InputType {
  ipV4 = "ipV4",
  ipV6 = "ipV6",
  isoState = "iso_state",
  isoCountry = "iso_country"
}

export interface ArrayReductionConfig {
  op: AggregationOperator;
  params: TypedObjectValue[];
}

export type ArithmeticConfig = {
  expression: string;
};

export type ScriptConfig = {
  multilineScript: string;
};

export interface TypeConversionConfig {
  toType: FieldPrimType;
  function?: string;
  format?: string[];
}
export type RegroupingExp = "regexp" | "splitRegexp";

export type RegroupingConfig = {
  regexp?: string;
  splitRegexp?: string;
  selectGroups: number[];
  mergeStr: string;
};

export interface ConditionalConfig {
  expression: string;
  trueTransformation: FieldTransformationConfig<TransformType>;
  falseTransformation: FieldTransformationConfig<TransformType>;
}

export interface LocationConfig {
  destLocationType: DestLocationType;
  inputType: InputType;
  destEntityType: string;
}

export interface DateTimeConfig {
  dateformat: DateFormat;
  outputValue: EpochConversionType;
  format: string;
}

export enum DruidTransformationInputType {
  script = "script",
  field = "field"
}

export type DruidTransformationConfig = {
  multilineScript: string;
  bicycleField?: string;
  inputType?: DruidTransformationInputType;
};

export type TransformationConfig<T extends TransformType> = T extends "staticValue"
  ? TypedObjectValue
  : T extends "arrayReduction"
    ? ArrayReductionConfig
    : T extends "arithmetic"
      ? ArithmeticConfig
      : T extends "script"
        ? ScriptConfig
        : T extends "staticValue"
          ? TypedObjectValue
          : T extends "typeConversion"
            ? TypeConversionConfig
            : T extends "regrouping"
              ? RegroupingConfig
              : T extends "conditional"
                ? ConditionalConfig
                : T extends "location"
                  ? LocationConfig
                  : T extends "dateTransformation"
                    ? DateTimeConfig
                    : T extends "druidTransformationConfig"
                      ? DruidTransformationConfig
                      : unknown;

export type FieldTransformationConfig<T extends TransformType> = {
  [key in TransformType]?: TransformationConfig<T>;
} & {
  outputType?: FieldPrimType;
};
