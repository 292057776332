import {
  getDateTimeByFormat,
  getDateTimeStringFormat,
  getFormattedDateTime,
  IncButton,
  IncDateTimeFormat,
  IncDurationSelector,
  IncRadioButton,
  IncSelectorDuration,
  IncTextfield
} from "@inception/ui";
import { debounce, isEmpty, range } from "lodash";
import React, { ChangeEvent, FocusEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { dateTime, DateTime } from "../../core";
import { RelativeDurationSuffix, RelativeDurationType } from "../../dashboard/models/BaseWidgetModel";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import ClickAwayContainer from "../content/ClickAwayContainer";
import IncTabBar, { Tab as IncTab } from "../Tabs/TabBar";
import {
  BEGINNING_OF_TIME_LABEL,
  BEGINNING_OF_TIME_VALUE,
  END_OF_TIME_LABEL,
  END_OF_TIME_VALUE
} from "../time-range/TimeRangeConstants";
import { DateFormatType } from "../types";
import { VerticallyCenteredRow } from "../flex-components";
import IncARDateTimeProps from "./relativeTypes";

const classes = {
  input: "inc-date-picker--input",
  calendar: "inc-date-picker--calendar",
  popper: "inc-date-picker--popper"
};

interface RenderCustomHeaderProps {
  date: Date;
  changeYear: (year: number | string) => void;
  changeMonth: (month: number | string) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}

enum CALENDAR_TABS {
  ABSOLUTE = "absolute",
  RELATIVE = "relative"
}

const durationMap: any = {
  m: RelativeDurationType.MINUTES,
  h: RelativeDurationType.HOURS,
  d: RelativeDurationType.DAYS,
  w: RelativeDurationType.WEEKS,
  M: RelativeDurationType.MONTHS
};

const IncARDateTimePicker: React.FC<IncARDateTimeProps> = props => {
  const {
    label,
    calendarClassName: usrCalendar = "",
    className: usrInput = "",
    popperClassName: usrPopper = "",
    withSeconds = false,
    value: pValue,
    onChange: usrOnChange = () => {},
    tab: dTab,
    relVal,
    hideTimeSelect,
    format: pFormat,
    i18nDisabled,
    enableBeginningAndEndTimes,
    timeZone,
    hideRelativeSelection = false,
    hideSetToNow = false,
    autoAdjustWidth = false,
    ...dateTimeProps
  } = props;

  const [value, setValue] = useState<Date | string>(pValue);
  const [calendarTab, setCalendarTab] = useState<string | number>("absolute");
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [typedValue, setTypedValue] = useState<string>(null);
  const [error, setError] = useState<string>(null);
  const extractDuration = useCallback((relStr: string) => {
    const splitSigns = /[+-]/;
    if (typeof relStr === "string" && splitSigns.test(relStr) && relStr !== END_OF_TIME_VALUE) {
      const splitSign = relStr.indexOf("+") > -1 ? "+" : "-";
      const timePart = relStr.split(splitSign)[1];
      const durationNum = timePart.match(/(\d+)/);
      const numVal = durationNum?.length ? durationNum[0] : "1";
      return {
        duration: parseInt(numVal, 10),
        durationType: durationMap[timePart.slice(-1)],
        durationSuffix: timePart.slice(-1).toString() as string
      };
    } else {
      return {
        duration: 0,
        durationType: RelativeDurationType.DAYS,
        durationSuffix: RelativeDurationSuffix.DAYS
      };
    }
  }, []);
  const getDurationValue = useMemo(() => extractDuration(relVal as string), [relVal, extractDuration]);

  const getCalendarTabs = useMemo<IncTab[]>(
    () => [
      {
        id: CALENDAR_TABS.ABSOLUTE,
        labelId: "date.time.picker.absolute"
      },
      {
        id: CALENDAR_TABS.RELATIVE,
        labelId: "date.time.picker.relative"
      }
    ],
    []
  );

  const dateFormat = useMemo(
    () =>
      getDateTimeStringFormat(pFormat || IncDateTimeFormat.numeric, {
        i18nDisabled: i18nDisabled,
        withSeconds: withSeconds
      }),
    [pFormat, i18nDisabled, withSeconds]
  );

  useEffect(() => {
    setValue(prev => (prev?.valueOf() !== pValue.valueOf() ? pValue : prev));
  }, [pValue]);

  const RADIO_TYPE = {
    NOW: "now",
    BEGINNING: BEGINNING_OF_TIME_LABEL,
    END: END_OF_TIME_LABEL,
    LAST: "Last"
  };

  const usrChangeDebounce = useMemo(
    () =>
      debounce((dateObj: Date | string, type: DateFormatType) => {
        if (dateObj === RADIO_TYPE.BEGINNING) {
          dateObj = BEGINNING_OF_TIME_VALUE;
        } else if (dateObj === RADIO_TYPE.END) {
          dateObj = END_OF_TIME_VALUE;
        }
        usrOnChange(dateObj, type);
      }),
    [usrOnChange, RADIO_TYPE.END, RADIO_TYPE.BEGINNING]
  );

  const className = `${classes.input} ${usrInput}`;
  const disabledTimeListClass = hideTimeSelect ? "disable-time-list" : "";
  const calendarClassName = `${classes.calendar} ${usrCalendar} ${disabledTimeListClass}`;
  const popperClassName = `${classes.popper} ${usrPopper}`;

  const onChange = useCallback(
    (newDate: string | DateTime | Date) => {
      let dateObj: Date;
      if (newDate) {
        if (typeof newDate === "string" && timeRangeUtils.isRelativeTime(newDate)) {
          setValue(newDate as string);
          usrChangeDebounce(newDate as string, CALENDAR_TABS.RELATIVE);
          setDuration(extractDuration(newDate) as IncSelectorDuration); // This is to support relative string being typed by user.
        } else {
          if (typeof newDate === "string") {
            dateObj = new Date(newDate);
          } else if (newDate instanceof Date) {
            dateObj = newDate;
          } else {
            dateObj = newDate.toDate();
          }
          setRelativeTimeApplied(false);
          setValue(dateObj);
          usrChangeDebounce(dateObj, CALENDAR_TABS.ABSOLUTE);
        }
        setIsTyping(false);
        setError(null);
        setShowDateTimePicker(false);
      }
    },
    [extractDuration, usrChangeDebounce]
  );

  const onRelativeTimeFromApply = () => {
    onChange(getRelativeTimeString(true));
    setRelativeTimeApplied(true);
    setShowDateTimePicker(false);
  };

  const [showDateTimePicker, setShowDateTimePicker] = useState<boolean>(false);

  const years = range(1990, new Date().getFullYear() + 10, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const [relativeTimeApplied, setRelativeTimeApplied] = useState<boolean>(dTab === CALENDAR_TABS.RELATIVE);
  const [duration, setDuration] = useState<IncSelectorDuration>(getDurationValue as IncSelectorDuration);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars-ts
  const [sign] = useState<string>(relVal && relVal?.indexOf("+") > 0 ? "+" : "-");
  const getRadioType = useCallback(() => {
    if (relVal === BEGINNING_OF_TIME_VALUE) {
      return RADIO_TYPE.BEGINNING;
    } else if (relVal === END_OF_TIME_VALUE) {
      return RADIO_TYPE.END;
    } else {
      return RADIO_TYPE.NOW;
    }
  }, [relVal, RADIO_TYPE.NOW, RADIO_TYPE.BEGINNING, RADIO_TYPE.END]);
  const [radioType, setRadioType] = useState<string>(getRadioType());
  const getRelativeTimeString = useCallback(
    (change?: boolean) => {
      if (radioType === RADIO_TYPE.NOW) {
        const displayStr = !hideTimeSelect || change ? "now" : "today";
        return duration?.duration === 0
          ? displayStr
          : `${displayStr}${sign}${duration.duration}${duration.durationSuffix}`;
      } else {
        return radioType;
      }
    },
    [sign, duration, hideTimeSelect, radioType, RADIO_TYPE.NOW]
  );

  const displayTextValue = useMemo(() => {
    if (value && !isTyping) {
      if (relativeTimeApplied) {
        return getRelativeTimeString();
      }
      if (i18nDisabled) {
        return getDateTimeByFormat(new Date(value), pFormat);
      }
      return getFormattedDateTime(new Date(value), pFormat || IncDateTimeFormat.numeric, { withSeconds: withSeconds });
    }
    return typedValue;
  }, [value, isTyping, typedValue, relativeTimeApplied, i18nDisabled, pFormat, withSeconds, getRelativeTimeString]);

  const handleTabSelection = useCallback(tab => {
    setCalendarTab(tab);
  }, []);

  const onTextChange = useCallback((event: ChangeEvent<HTMLInputElement>, customVal?: string) => {
    event.stopPropagation();
    const val = customVal || event?.target?.value;
    setTypedValue(val);
    setShowDateTimePicker(false);
    setIsTyping(true);
  }, []);

  const onClick = useCallback(
    (event: MouseEvent<HTMLInputElement>) => {
      event.stopPropagation();
      if (!isTyping) {
        setTypedValue(event.currentTarget.value);
      }
    },
    [isTyping]
  );

  const onBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (isTyping) {
        const typedValue = event.target.value;
        setTypedValue(typedValue);
        if (timeRangeUtils.isValidTime(typedValue, dateFormat, timeZone)) {
          if (timeRangeUtils.isRelativeTime(typedValue)) {
            onChange(typedValue);
          } else {
            // To tranform a date string to a valid date format wrapping typed value in dateTime
            // our format is in dd-MM-yyyy but moment understands DD-MM-yyyy, so replacing text.
            onChange(dateTime(typedValue, undefined, dateFormat.replace("dd", "DD")));
          }
        } else {
          setError("Invalid date entered");
        }
      }
    },
    [dateFormat, isTyping, onChange, timeZone]
  );

  const renderCustomHeader: React.ReactNode = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  }: RenderCustomHeaderProps) => (
    <div
      style={{
        margin: "0 15px 0 15px",
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      <button
        className="react-datepicker__inc-datepicker-button"
        disabled={prevMonthButtonDisabled}
        onClick={decreaseMonth}
      >
        {"<"}
      </button>
      <select
        className="react-datepicker__inc-datepicker-select"
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
        value={months[date.getMonth()]}
      >
        {months.map(option => (
          <option
            key={option}
            value={option}
          >
            {option}
          </option>
        ))}
      </select>
      <select
        className="react-datepicker__inc-datepicker-select"
        onChange={({ target: { value } }) => changeYear(value)}
        value={date.getFullYear()}
      >
        {years.map(option => (
          <option
            key={option}
            value={option}
          >
            {option}
          </option>
        ))}
      </select>
      <button
        className="react-datepicker__inc-datepicker-button right"
        disabled={nextMonthButtonDisabled}
        onClick={increaseMonth}
      >
        {">"}
      </button>
    </div>
  );

  const renderAbsoluteCalendar = () => (
    <div className="inc-date-picker-container">
      <ReactDatePicker
        calendarClassName={calendarClassName}
        className={className}
        dateFormat={dateFormat}
        inline
        onChange={onChange}
        popperClassName={popperClassName}
        renderCustomHeader={renderCustomHeader}
        selected={value}
        showPopperArrow={false}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        {...(dateTimeProps as any)}
        endDate={null}
        startDate={null}
      />
    </div>
  );

  const toggleElem = useMemo(
    () => (
      <IncTextfield
        autoAdjustWidth={autoAdjustWidth}
        errorText={error}
        hasError={!isEmpty(error)}
        label={label as string}
        onBlur={onBlur}
        onChange={onTextChange}
        onClick={onClick}
        readOnly={false}
        type="text"
        value={displayTextValue}
      />
    ),
    [autoAdjustWidth, error, label, onBlur, onTextChange, onClick, displayTextValue]
  );

  const onSetToNow = useCallback(() => {
    if (!relativeTimeApplied) {
      onChange("now");
      setRelativeTimeApplied(true);
      setShowDateTimePicker(false);
    }
  }, [onChange, relativeTimeApplied]);

  return (
    <div className="ar-date-time-picker-container">
      {!hideSetToNow && (
        <VerticallyCenteredRow
          className="inc-text-subtext-medium set-to-now-btn inc-cursor-pointer status-info"
          onClick={onSetToNow}
        >
          Set To Now
        </VerticallyCenteredRow>
      )}

      <ClickAwayContainer
        onToggle={setShowDateTimePicker}
        open={showDateTimePicker}
        toggleElem={toggleElem}
      >
        <div>
          <div
            className="relative-date-time-picker"
            style={hideRelativeSelection ? popperStyle : {}}
          >
            {!hideRelativeSelection && (
              <IncTabBar
                disableRouteChange={true}
                handleSelection={handleTabSelection}
                selected={calendarTab}
                tabs={getCalendarTabs}
              />
            )}

            {calendarTab === CALENDAR_TABS.ABSOLUTE ? (
              renderAbsoluteCalendar()
            ) : (
              <div className="duration-selector-area">
                <div className="relative-duration-radios">
                  <div className="inc-flex-row inc-flex-center-vertical">
                    {enableBeginningAndEndTimes ? (
                      <IncRadioButton
                        checked={radioType === RADIO_TYPE.NOW}
                        label={RADIO_TYPE.NOW}
                        onChange={() => setRadioType(RADIO_TYPE.NOW)}
                        value={radioType}
                      />
                    ) : (
                      <div className="marginLt16">now&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;</div>
                    )}
                    <div className="relative-time-radios inc-flex-center-vertical">
                      <IncDurationSelector
                        duration={duration}
                        hideLabel={true}
                        hideTimeValues={hideTimeSelect}
                        onChange={setDuration}
                      />
                    </div>
                  </div>
                  {enableBeginningAndEndTimes && (
                    <IncRadioButton
                      checked={[RADIO_TYPE.BEGINNING, RADIO_TYPE.END].indexOf(radioType) > -1}
                      label={label === "From" ? RADIO_TYPE.BEGINNING : RADIO_TYPE.END}
                      onChange={() => setRadioType(label === "From" ? RADIO_TYPE.BEGINNING : RADIO_TYPE.END)}
                      value={radioType}
                    />
                  )}
                </div>
                <IncButton
                  color="primary"
                  id="relative-time-from-apply"
                  onClick={onRelativeTimeFromApply}
                >
                  Done
                </IncButton>
              </div>
            )}
          </div>
        </div>
      </ClickAwayContainer>
    </div>
  );
};

export default IncARDateTimePicker;

const popperStyle = {
  height: "unset",
  background: "transparent"
};
