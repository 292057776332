import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IncModal, IncModalProps } from "@inception/ui";
import { clone } from "lodash";
import { CatalogWidgetImpl, WidgetQuerySourceConfig } from "../../../models";
import { WidgetResponseDTO } from "../../../../../../services/api/explore";
import { VerticallyCenteredRow } from "../../../../../../components";

interface Props {
  widgetImpl: CatalogWidgetImpl;
  widgetResponseDTO: WidgetResponseDTO;
  onWidgetImplChange: (updaterFn: (widgetImpl: CatalogWidgetImpl) => CatalogWidgetImpl) => void;
}

export const InconsistencyCheckConfirmModal: FC<Props> = props => {
  const { widgetImpl, widgetResponseDTO, onWidgetImplChange } = props;

  const [shouldIgnore, setShouldIgnore] = useState(false);
  const { queryConfig, title } = widgetImpl;

  const { sourceQueryConfig } = queryConfig;
  const { metricId: defMetricId, childMetricIds: defChildMetricIds } = sourceQueryConfig as WidgetQuerySourceConfig;

  const { metricId, childMetricIds, metricsJsx } = useMemo(() => {
    if (widgetResponseDTO && !shouldIgnore) {
      const defMetricIds = [defMetricId, ...(defChildMetricIds || [])].filter(Boolean);

      const { widgetConfig } = widgetResponseDTO;

      const metricIds: string[] = [];
      const metricsJsx: JSX.Element[] = [];

      Object.values(widgetConfig?.dataDefinition?.metrics || {}).forEach(metric => {
        const { id, labels, name } = metric;
        if (labels?.excludeDataFetch !== "true") {
          metricIds.push(id);
          if (!defMetricIds.includes(id)) {
            metricsJsx.push(
              <VerticallyCenteredRow
                className="inc-label-common paddingLt12"
                key={id}
              >
                {name}
              </VerticallyCenteredRow>
            );
          }
        }
      });

      return {
        metricId: metricIds[0],
        childMetricIds: metricIds.slice(1),
        metricsJsx
      };
    }

    return {
      metricId: "",
      childMetricIds: [],
      metricsJsx: []
    };
  }, [defChildMetricIds, defMetricId, shouldIgnore, widgetResponseDTO]);

  const onOverride = useCallback(() => {
    onWidgetImplChange(widgetImpl => {
      const clWidgetImpl = clone(widgetImpl);
      if (
        widgetImpl.queryConfig.sourceQueryConfig.queryType === "widgetConfig" &&
        clWidgetImpl.queryConfig.sourceQueryConfig.queryType === "widgetConfig"
      ) {
        widgetImpl.queryConfig.sourceQueryConfig.metricId = metricId;
        widgetImpl.queryConfig.sourceQueryConfig.childMetricIds = childMetricIds;
      }

      return clWidgetImpl;
    });
    setShouldIgnore(true);
  }, [childMetricIds, metricId, onWidgetImplChange]);

  const onIgnore = useCallback(() => {
    setShouldIgnore(true);
  }, []);

  const actions = useMemo<IncModalProps["actions"]>(
    () => ({
      primary: {
        onClick: onOverride,
        label: "Override"
      },
      secondary: {
        onClick: onIgnore,
        label: "Skip"
      }
    }),
    [onIgnore, onOverride]
  );

  const isInconsistent = useMemo(() => {
    if (shouldIgnore) {
      return {
        isInconsistent: false,
        isMetricsInconsistent: false,
        isTagsInconsistent: false
      };
    }

    const defMetricIds = [defMetricId, ...(defChildMetricIds || [])].filter(Boolean);
    const isConsistent = [metricId, ...childMetricIds].every(metricId => defMetricIds.includes(metricId));
    return !isConsistent;
  }, [childMetricIds, defChildMetricIds, defMetricId, metricId, shouldIgnore]);

  const [shouldShowModal, setShouldShowModal] = useState<boolean>();
  useEffect(() => {
    setShouldShowModal(prev => {
      if (prev === undefined || prev === null) {
        return isInconsistent && !shouldIgnore;
      }

      return prev;
    });
  }, [isInconsistent, shouldIgnore]);

  return (
    <IncModal
      actions={actions}
      className="inc-regular-modal"
      onClose={onIgnore}
      show={shouldShowModal}
      showClose
      size="md"
      titleText={`Warning - ${title}`}
    >
      <div className="inc-flex-column flex-gap-12">
        <VerticallyCenteredRow>
          Following metrics have been added to the configuration recently, do you want to display them?
        </VerticallyCenteredRow>

        <div className="inc-flex-column flex-gap-12">{metricsJsx}</div>
      </div>
    </IncModal>
  );
};
