import { CancelToken } from "axios";
import { isNull } from "lodash";
import appConfig from "../../../../../appConfig";
import { BizService, BizEntityApiResult } from "../../explore/BizServiceCommon";
import { MessageSuggestionsResponse } from "../types";

class AutoCompleteApiService extends BizService {
  async getAutocompleteSuggestions(message: string, context = "", limit = appConfig.defaultSuggestionsLimit) {
    const url = this.getBizEntityUrl("autocomplete");
    const result: BizEntityApiResult<string[]> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };

    const params: Record<string, string> = {};
    !isNull(message) && (params.message = message);
    context && (params.context = context);
    limit && (params.limit = limit.toString());

    try {
      await this.init();
      const response = await this.datasource.post<string[], any>(
        url,
        {},
        {
          params
        }
      );
      result.data = response.data;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getMessageSuggestions(useCaseId?: string, cancelToken?: CancelToken) {
    const url = this.getBizEntityUrl("suggestions");
    const result: BizEntityApiResult<MessageSuggestionsResponse> = {
      data: {
        suggestions: []
      },
      error: false,
      message: "",
      cancelled: false
    };

    try {
      await this.init();
      const response = await this.datasource.get<MessageSuggestionsResponse, any>(url, null, {
        params: { useCaseId },
        cancelToken
      });
      result.data = response.data;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }
}

export const autoCompleteApiService = new AutoCompleteApiService();
