import { set } from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { WidgetPropertiesSection } from "../../widget-properties/types";
import WidgetProperties from "../../widget-properties/WidgetProperties";
import getTextPropertyPanelSections from "./TextProperties";
import TextWidgetImpl from "./impl";

export interface TextWidgetPropertiesPanelProps {
  widget: TextWidgetImpl;
  onUpdate: (properties: Record<string, any>) => void;
}

const TextWidgetPropertiesPanel: React.FC<TextWidgetPropertiesPanelProps> = (props: TextWidgetPropertiesPanelProps) => {
  const { widget, onUpdate } = props;

  const { properties } = widget;
  const [propertiesSections, setPropertySections] = useState<WidgetPropertiesSection[]>([]);

  useEffect(() => {
    const sections = getTextPropertyPanelSections(properties);
    setPropertySections(sections);
  }, [properties]);

  const onSectionUpdate = useCallback(
    (newSections: WidgetPropertiesSection[]) => {
      if (newSections) {
        const propAndVal: Record<string, any> = {};
        newSections.forEach(section => {
          if (section.properties) {
            const { properties } = section;
            properties.forEach(p => {
              set(propAndVal, p.name, p.value);
            });
          }
        });
        onUpdate(propAndVal);
      }
    },
    [onUpdate]
  );

  return (
    <WidgetProperties
      onUpdate={onSectionUpdate}
      sections={propertiesSections}
    />
  );
};

export default TextWidgetPropertiesPanel;
