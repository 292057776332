import { isEmpty } from "lodash";
import React, { useCallback, useState, useMemo, useRef } from "react";
import { IncFaIcon, IncPopper, IncClickAway, IncFaIconName, IncSmartText } from "@inception/ui";
import { cx } from "emotion";
import { CypressConstants } from "@bicycle/tests";
import { useInceptionRoute, getParamFromUrl } from "../../core";
import { getLocalizedString } from "../../utils";

type TAB_ID = string | number;

export type Tab = {
  id: TAB_ID;
  labelId?: string;
  label?: string;
  route?: string;
  paramsToForward?: string[]; // pass url params that needs to be forwarded.
  staticParams?: Record<string, string>;
  customData?: Record<string, any>;
  iconName?: string;
};

type TabProps = {
  tab: Tab;
  isSelected: boolean;
  disableRouteChange?: boolean;
  handleSelection?: (id: TAB_ID) => void;
  renderer?: (tab: Tab, isSelected: boolean) => JSX.Element | null;
};

const IncTab: React.FC<TabProps> = (props: TabProps) => {
  const { disableRouteChange, handleSelection, isSelected, renderer, tab } = props;

  const { id, label, labelId, paramsToForward = [], route, staticParams = {} } = tab;

  const { navigate } = useInceptionRoute();

  const onClick = useCallback(() => {
    if (handleSelection) {
      handleSelection(id);
    }

    if (!disableRouteChange) {
      const toForward: string[] = paramsToForward
        .map(param => {
          const value = getParamFromUrl(param) as string;
          return value ? `${param}=${value}` : null;
        })
        .filter(p => p);

      const staticAdd = Object.keys(staticParams).map(key => `${key}=${staticParams[key]}`);
      const merged = [...toForward, ...staticAdd];
      const appendToRoute = isEmpty(merged) ? "" : `?${merged.join("&")}`;
      navigate(`${route}${appendToRoute}`);
    }
  }, [navigate, paramsToForward, route, staticParams, disableRouteChange, handleSelection, id]);

  const baseTabClass = "tab";
  const appliedTabClass = isSelected ? `${baseTabClass} tab-selected` : baseTabClass;

  return (
    <div
      className={appliedTabClass}
      data-cy={CypressConstants.application.attributes.incTab}
      key={id}
      onClick={onClick}
    >
      {renderer ? (
        renderer(tab, isSelected)
      ) : labelId ? (
        <IncSmartText text={getLocalizedString(labelId)} />
      ) : (
        <IncSmartText text={label} />
      )}
    </div>
  );
};

interface TabsPopperProps {
  tabs: Tab[];
  show: boolean;
  anchorEl: HTMLElement;
  handleClose: () => void;
  onTabClick: (id: TAB_ID) => void;
  renderer?: (tab: Tab, isSelected: boolean) => JSX.Element | null;
}

const TabsPopper: React.FC<TabsPopperProps> = (props: TabsPopperProps) => {
  const { show, tabs, onTabClick, handleClose, anchorEl, renderer } = props;

  const tabsList = useMemo(
    () => (
      <div className="tabs-popper inc-flex-column">
        {tabs.map(tab => {
          const { id, label } = tab;
          const onClick = () => onTabClick(id);
          const className = renderer ? "" : "tab-item";

          return (
            <div
              className={className}
              key={tab.id}
              onClick={onClick}
            >
              {renderer ? renderer(tab, false) : label}
            </div>
          );
        })}
      </div>
    ),
    [onTabClick, renderer, tabs]
  );

  return (
    <>
      <IncClickAway onClickAway={handleClose}>
        {ref => (
          <IncPopper
            anchorEl={anchorEl}
            offset={{
              x: 0,
              y: -15
            }}
            placement="bottom-end"
            ref={ref}
            show={show}
          >
            {tabsList}
          </IncPopper>
        )}
      </IncClickAway>
    </>
  );
};

type TabBarProps = {
  selected: TAB_ID;
  tabs: Tab[];
  handleSelection?: (id: string | number) => void;
  disableRouteChange?: boolean;
  tabRenderer?: (tab: Tab, isSelected: boolean) => JSX.Element | null;
  tabItemRender?: (tab: Tab, isSelected: boolean) => JSX.Element | null;
  scrollLimit?: number;
  className?: string;
};

const IncTabBar: React.FC<TabBarProps> = (props: TabBarProps) => {
  const {
    disableRouteChange,
    handleSelection,
    selected,
    tabs: eTabs,
    tabRenderer,
    tabItemRender,
    scrollLimit,
    className = ""
  } = props;

  const [showPopper, setShowPopper] = useState(false);
  const anchorEl = useRef();

  const isScrollable = useMemo(() => eTabs.length > scrollLimit, [eTabs.length, scrollLimit]);

  const togglePopper = useCallback(() => setShowPopper(prev => !prev), []);
  // adding a seperate handle close for click away since clicking on icon tiggers togglePopper twice resulting in state being true
  const handleClose = useCallback(() => setShowPopper(false), []);

  const { tabs, extraTabs } = useMemo(() => {
    if (scrollLimit) {
      let list: Tab[] = [];
      let eTabsClone = [...eTabs];
      const restrictedLimit = scrollLimit - 1;

      const selectedTab = eTabsClone.find(x => x.id === selected);
      eTabsClone = eTabsClone.filter(x => x.id !== selected);

      list = selectedTab ? [selectedTab] : [];

      const remainingElems = eTabsClone.splice(0, restrictedLimit);
      list = list.concat(remainingElems);

      return {
        tabs: list,
        extraTabs: eTabsClone
      };
    } else {
      return {
        tabs: eTabs,
        extraTabs: []
      };
    }
  }, [eTabs, scrollLimit, selected]);

  const rightIconClassName = cx("paddingLt12", "navigation-icon", "inc-cursor-pointer", "marginBt12");

  const tabContainerClassName = cx("tab-container", {
    "full-width": !isScrollable
  });

  const tabBarClassName = `tab-bar ${className}`;
  const expandIconName: IncFaIconName = showPopper ? "chevron-down" : "chevron-right";

  return (
    <div className={tabBarClassName}>
      <div className={tabContainerClassName}>
        {tabs.map((tab: Tab) => (
          <IncTab
            disableRouteChange={disableRouteChange}
            handleSelection={handleSelection}
            isSelected={tab.id === selected}
            key={tab.id}
            renderer={tabRenderer}
            tab={tab}
          />
        ))}
      </div>
      {Boolean(scrollLimit) && isScrollable && (
        <div className="icon-container">
          <span ref={anchorEl}>
            <IncFaIcon
              className={rightIconClassName}
              iconName={expandIconName}
              onClick={togglePopper}
            />
          </span>
        </div>
      )}
      <TabsPopper
        anchorEl={anchorEl as any}
        handleClose={handleClose}
        onTabClick={handleSelection}
        renderer={tabItemRender}
        show={showPopper}
        tabs={extraTabs}
      />
    </div>
  );
};

export default IncTabBar;
