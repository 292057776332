import { defaults } from "lodash";
import { EventExpressionVariableModel, VariableType } from "../models/VariableModel";
import { LogicalOperator, UserServiceFilterExpressionTree } from "../../services/api/explore";
import { QueryParamDTO } from "../../utils/QueryParamUtils";
import VariableImpl from "./VariableImpl";

const DEFAULTS: EventExpressionVariableModel = {
  id: "",
  name: "",
  multi: false,
  value: null,
  defaultValue: "",
  type: VariableType.EventExpression,
  allValue: "",
  includeAll: false,
  label: "",
  hide: false,
  supportsAggregation: false,
  userServiceId: "",
  userServiceName: "",
  expressionTree: {
    logicalOperator: LogicalOperator.AND,
    filterNodes: []
  }
};

export default class EventExpressionVariableImpl extends VariableImpl implements EventExpressionVariableModel {
  userServiceId: string;
  userServiceName: string;
  expressionTree: UserServiceFilterExpressionTree;

  readonly type = VariableType.EventExpression;

  constructor(model: Partial<EventExpressionVariableModel>) {
    super(model);
    this.assign(model);
  }

  protected assign(model: Partial<EventExpressionVariableModel>) {
    const evModel: Partial<EventExpressionVariableModel> = {};
    defaults(evModel, model, DEFAULTS);
    const { userServiceId, expressionTree } = evModel;

    this.userServiceId = userServiceId;
    this.expressionTree = expressionTree;

    super.assign(evModel);
  }

  update(model: Partial<EventExpressionVariableModel>) {
    this.assign(model);
  }

  setDefaultValue(value: string) {
    this.defaultValue = value;
  }

  getFilterQueryParams(): QueryParamDTO {
    return null;
  }

  getFilterExpressionTree(): UserServiceFilterExpressionTree {
    return this.expressionTree;
  }

  private getQueryParams() {}

  // ovveriding parent class since that functionality is not required
  adjustValueAndDefaultValue() {}
}
