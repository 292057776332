import React from "react";
import { FormattedMessage } from "react-intl";
import { LoadingSpinner } from "../../../components";

const centerStyle = {
  height: "100%",
  width: "100%"
};

const stateClassName =
  "inc-flex-row inc-flex-center-vertical inc-flex-center-horizontal inc-text-subtext-medium widget-state-element";

const loadingElement = (
  <div
    className={stateClassName}
    style={centerStyle}
  >
    <LoadingSpinner
      position="center"
      titleId="common.actions.loading.data.text"
    />
  </div>
);

const variablesLoadingElement = loadingElement;

const noDataElement = (
  <div
    className={stateClassName}
    style={centerStyle}
  >
    <FormattedMessage id="common.no.data" />
  </div>
);

export const BaseWidgetStateElements = {
  loadingElement,
  noDataElement,
  variablesLoadingElement
};
