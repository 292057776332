import React, { FC, useState, useCallback, memo, useRef } from "react";
import { IncFaIcon, CircleProgressBar } from "@inception/ui";
import { transparentize } from "polished";
import { css } from "emotion";
import {
  IncidentSliceContribution,
  getDisplayTagNameForUSFieldSlice,
  ImpactedWidget,
  ImpactWidgetDataRequest,
  UserServiceFilterList
} from "../../../services/api/explore";
import { VerticallyCenteredRow } from "../../flex-components";
import { SliceIndicatorDrawer } from "./SliceIndicatorDrawer";

export type CommonIndicatorProps = {
  primaryImpactedWidget: ImpactedWidget;

  cohortId?: string;

  filtersExist?: boolean;
  lookupData?: Record<string, string>;

  opConfigId: string;
  incidentId: string;
  entityTypeId: string;

  generateDemoData?: boolean;
};

export type SProps = CommonIndicatorProps & {
  sliceContributors: IncidentSliceContribution;
  schemaPerSlice: Record<string, Set<string>>;
  isPinned: boolean;
  isDiagnostic: boolean;

  entityFilters?: ImpactWidgetDataRequest["entityFilters"];
  eventFieldFilters?: UserServiceFilterList;
};

export const SliceIndicator: FC<SProps> = memo(props => {
  const {
    sliceContributors,
    lookupData,
    schemaPerSlice,
    isPinned,
    filtersExist,
    primaryImpactedWidget,
    incidentId,
    opConfigId,
    entityTypeId,
    entityFilters,
    eventFieldFilters,
    isDiagnostic,
    generateDemoData
  } = props;

  const { slice, values } = sliceContributors;

  const { displayTagName, tagName } = getDisplayTagNameForUSFieldSlice(slice);

  const ref = useRef<HTMLDivElement>();

  const [show, setShow] = useState(false);
  const openList = useCallback(() => setShow(true), []);
  const closeList = useCallback(() => setShow(false), []);

  const incidentCount = values.length;
  const totalCount = schemaPerSlice[tagName]?.size || 0;

  const dataClassName = filtersExist ? compressedCSS : "width-100";
  const filterPer = Math.ceil((incidentCount / totalCount) * 100);

  return (
    <VerticallyCenteredRow className="key-indicators--slice">
      {filtersExist && (
        <VerticallyCenteredRow className="marginRt12">
          <CircleProgressBar
            progress={filterPer}
            size={48}
            trackColor={transparentize(0.86, "#FF523B")}
          />
        </VerticallyCenteredRow>
      )}

      <div className={dataClassName}>
        <VerticallyCenteredRow className="marginBt8 width-100">
          <VerticallyCenteredRow
            className="marginRt8"
            ref={ref}
          >
            {displayTagName}
          </VerticallyCenteredRow>

          <IncFaIcon
            className="status-info inc-cursor-pointer"
            iconName="indent"
            onClick={openList}
          />

          {!isDiagnostic && (
            <VerticallyCenteredRow className="marginLt8 inc-label-common">/ Total monitored</VerticallyCenteredRow>
          )}

          <IncFaIcon
            className="marginLtAuto star-icon"
            data-pinned={isPinned}
            iconName="star"
            regular={!isPinned}
          />
        </VerticallyCenteredRow>

        <VerticallyCenteredRow>
          <VerticallyCenteredRow className="marginRt6 inc-text-header">{incidentCount}</VerticallyCenteredRow>

          {!isDiagnostic && <VerticallyCenteredRow className="inc-label-common">/ {totalCount}</VerticallyCenteredRow>}
        </VerticallyCenteredRow>
      </div>

      {Boolean(sliceContributors) && (
        <SliceIndicatorDrawer
          closeList={closeList}
          defSliceContributors={sliceContributors}
          entityFilters={entityFilters}
          entityTypeId={entityTypeId}
          eventFieldFilters={eventFieldFilters}
          generateDemoData={generateDemoData}
          impactedWidget={primaryImpactedWidget}
          incidentId={incidentId}
          lookupData={lookupData}
          opConfigId={opConfigId}
          show={show}
        />
      )}
    </VerticallyCenteredRow>
  );
});

const compressedCSS = css`
  width: calc(100% - 48px - 12px);
`;
