import { useMemo, useEffect } from "react";
import { IncSelectOption } from "@inception/ui";
import { useFetchExploreBizEntityTypes, BizEntityType, EntityMeta } from "../../services/api/explore";
import { logger, useTimeRange } from "../../core";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { useFetchBizEntityUserservices } from "../../field-picker";

export const useFetchOptions = (entityType: BizEntityType, skipBizEntityFetch = false) => {
  const { timeRange } = useTimeRange();

  const { fromMillis, toMillis } = useMemo(() => timeRangeUtils.getMillisFromTimeRange(timeRange), [timeRange]);

  const {
    bizEntityTypes = [],
    isFetching: isBizEntityTypesFetching,
    fetchBizEntityList
  } = useFetchExploreBizEntityTypes();

  const entityTypeOptions = useMemo<EntityTypeOption[]>(
    () =>
      bizEntityTypes.map(entityType => ({
        label: entityType.name,
        value: entityType.entityTypeId,
        data: entityType
      })),
    [bizEntityTypes]
  );

  useEffect(() => {
    if (!skipBizEntityFetch) {
      fetchBizEntityList();
    }
  }, [fetchBizEntityList, skipBizEntityFetch]);

  const bizEntityTypeId = entityType?.entityTypeId;

  const {
    data: userServices,
    error: userServiceError,
    isFetching: isUserservicesFetching,
    isError,
    refetch: fetchUserserviceList
  } = useFetchBizEntityUserservices(bizEntityTypeId, fromMillis, toMillis);

  // Immediate fetch
  useMemo(() => {
    if (bizEntityTypeId) {
      fetchUserserviceList();
    }
  }, [bizEntityTypeId, fetchUserserviceList]);

  const eventTypeOptions = useMemo<EventTypeOption[]>(() => {
    if (isError) {
      logger.error("UseFetchOptions", "Error fetching event types", userServiceError);
    } else {
      if (userServices?.length) {
        return userServices.map(eventType => ({
          label: eventType.userServiceName,
          value: eventType.userServiceId,
          data: {
            name: eventType.userServiceName,
            entityId: eventType.userServiceId,
            entityType: bizEntityTypeId
          }
        }));
      }
    }

    return [];
  }, [bizEntityTypeId, isError, userServiceError, userServices]);

  return {
    eventTypesFetching: isUserservicesFetching,
    eventTypeOptions,
    entityTypesFetching: isBizEntityTypesFetching,
    entityTypeOptions
  };
};

export type EntityTypeOption = IncSelectOption<BizEntityType>;
export type EventTypeOption = IncSelectOption<EntityMeta>;
