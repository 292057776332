import { BizService } from "../explore/BizServiceCommon";
import { SequenceDiagram, SequenceDiagramRequestV2 } from "../onboarding";
import { BizProcessRequest, BizProcessListResponse } from "./types";

class BizProcessApi extends BizService {
  async getBizProcessList(verticalId: string, startTimeMillis: number, endTimeMillis: number, subVerticalId?: string) {
    await this.init();

    const url = this.getBizEntityUrl("bizProcess/data");
    const payload: BizProcessRequest = { verticalId };

    subVerticalId && (payload.subVerticalId = subVerticalId);

    const request = () =>
      this.datasource.post<BizProcessListResponse, BizProcessRequest>(url, payload, {
        params: {
          startTimeMillis,
          endTimeMillis
        }
      });
    return this.getResult(request);
  }

  async getBizProcessSequenceDiagram(sequenceDiagramRequest: SequenceDiagramRequestV2, dryRun = false) {
    await this.init();
    const url = this.getBizEntityUrl(`bizProcess/generate?dryRun=${dryRun}`);

    const request = () => this.datasource.post<SequenceDiagram, SequenceDiagramRequestV2>(url, sequenceDiagramRequest);

    return this.getResult(request);
  }
}

export const bizProcessApi = new BizProcessApi();
