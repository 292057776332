import React, { FC, useEffect, useState, useCallback, useMemo } from "react";
import { isEqual } from "lodash";
import { IncFaIcon } from "@inception/ui";
import { Visualization } from "../../dashboard/widgets/Catalog/maximize-view/components";
import appConfig from "../../../appConfig";
import { Visualisations } from "../../core";
import { catalogWidgetBuilder } from "../../dashboard/builders";
import { CatalogWidgetImpl } from "../../dashboard/widgets/Catalog/models";
import { getWidgetConfigForKPI } from "../../operationalise-v2/uber-operationalize/utils";
import {
  BizDataQuery,
  ExpressionDef,
  FieldPickerContextDTO,
  UserServiceField,
  UserServiceFieldSlice,
  WidgetConfigUtils
} from "../../services/api/explore";
import LoadingSpinner from "../Loading/Loading";
import { FieldPickerUtils, noOp } from "../../utils";
import { convertToSliceSet } from "../../utils/ExploreUtils";
import { VerticallyCenteredRow } from "../flex-components";
import { FieldPickerPopUpContent } from "../field-picker/FieldPickerPopUpContent";

interface Props {
  isValid: boolean;
  errorsText: string;
  bizDataQuery: BizDataQuery;
}

export const KPIPreview: FC<Props> = props => {
  const { errorsText, isValid, bizDataQuery } = props;

  const [selectedSliceOptions, setSelectedSliceOptions] = useState<UserServiceField[]>([]);

  const { slices, sliceAggregateTags } = useMemo(() => {
    const usFields = selectedSliceOptions;

    const slices = usFields.map((usField): UserServiceFieldSlice => {
      const tagName = FieldPickerUtils.getPromSanitizedUSFName(usField);
      return {
        tagName,
        userServiceField: usField
      };
    });
    const sliceAggregateTags = slices.map(slice => slice.tagName);

    return {
      slices,
      sliceAggregateTags
    };
  }, [selectedSliceOptions]);

  const [vizWidgetImpl, setVizWidgetImpl] = useState<CatalogWidgetImpl>();
  const getVizWidgetImpl = useCallback(() => {
    const [widgetConfig, kpiMetricId, aggregateTags] = getWidgetConfigForKPI(bizDataQuery);
    const { eventTypeId } = WidgetConfigUtils.getEntityTypeAndEventTypeFromBizDataQuery(bizDataQuery);

    const catalogModel = catalogWidgetBuilder()
      .setDatasource(appConfig.defaultExploreDsName)
      .setBizEntityFieldName("")
      .setUserserviceId(eventTypeId)
      .setEntityType(null)
      .setQueryConfig({
        sourceQueryConfig: {
          queryType: "widgetConfig",
          metricId: kpiMetricId,
          widgetResponse: {
            querySchema: {
              querySchema: []
            },
            version: 1,
            widgetConfig,
            widgetId: null
          },
          childMetricIds: []
        }
      })
      .setRenderMode("viz-only")
      .setProperties({
        visualisation: Visualisations.timeseries,
        aggregateTags: [...aggregateTags, ...sliceAggregateTags]
      })
      .disableEdit()
      .disableHeader()
      .disableBorder()
      .disableActions()
      .disableBorder()
      .enableTransparent()
      .buildModel();

    const vizWidgetImpl = new CatalogWidgetImpl(catalogModel);
    vizWidgetImpl.renderMode = "viz-only";
    vizWidgetImpl.widgetConfigRefId = "";
    if (vizWidgetImpl.queryConfig.sourceQueryConfig.queryType === "widgetConfig") {
      const { widgetResponse } = vizWidgetImpl.queryConfig.sourceQueryConfig;
      if (widgetResponse) {
        widgetResponse.widgetId = "";
      }
    }

    if (vizWidgetImpl.queryConfig.sourceQueryConfig.queryType === "widgetConfig") {
      vizWidgetImpl.queryConfig.sourceQueryConfig.widgetResponse.querySchema.querySchema.map(
        item => (item.sliceSet = convertToSliceSet({ slices }))
      );

      const { metrics } = vizWidgetImpl.queryConfig.sourceQueryConfig.widgetResponse.widgetConfig.dataDefinition;

      Object.keys(metrics).forEach(key => {
        const metric = metrics[key];
        if (metric.sourceType === "userServiceField") {
          metric.userServiceFieldMetricConfig.sliceSets[0].slices = slices;
        }

        if (metric.sourceType === "expression") {
          updateExpressionSlices(metric.expressionMetricConfig.expression, slices);
        }
      });
    }

    return vizWidgetImpl;
  }, [bizDataQuery, sliceAggregateTags, slices]);

  useEffect(() => {
    setVizWidgetImpl(prev => {
      const nVizWidgetImpl = getVizWidgetImpl();
      if (!isEqual(nVizWidgetImpl, prev)) {
        return nVizWidgetImpl;
      }

      return prev;
    });
  }, [getVizWidgetImpl]);

  const { entityTypeId, eventTypeId } = WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(bizDataQuery?.idProps);
  const fieldPickerContext = useMemo<FieldPickerContextDTO>(
    () => ({
      entityId: entityTypeId ? null : eventTypeId,
      entityName: null,
      entityType: entityTypeId,
      showFields: true,
      showMetrics: false,
      userServices: entityTypeId
        ? [
            {
              userServiceEntityId: eventTypeId
            }
          ]
        : []
    }),
    [entityTypeId, eventTypeId]
  );

  const multiSelectionProps = useMemo(
    () => ({
      selectedFields: selectedSliceOptions,
      onSelectionChange: setSelectedSliceOptions
    }),
    [selectedSliceOptions]
  );

  return (
    <>
      {Boolean(vizWidgetImpl) && (
        <>
          <VerticallyCenteredRow className="kpi-preview-container">
            <FieldPickerPopUpContent
              containerClassName="kpi-preview-dimensions"
              entityTypeId={fieldPickerContext.entityType}
              eventId={fieldPickerContext.entityId}
              excludeAllUserServiceField
              label="Dimensions"
              labelClassName="inc-text-subtext-medium inc-text-inactive"
              multiSelectionProps={multiSelectionProps}
              onSelect={noOp}
              skipUserServiceName
              userServices={fieldPickerContext.userServices}
            />
            <div className="kpi-preview-visualization">
              <VerticallyCenteredRow className="flex-gap-4 inc-text-subtext-medium inc-text-inactive">
                <IncFaIcon
                  iconName="info-circle"
                  variant="solid"
                />
                Changes made in this preview will not affect the final output
              </VerticallyCenteredRow>
              <Visualization
                hideVizSwitcher
                isValid={isValid}
                updateOnSilentChange
                vizErrorMessage={errorsText}
                widgetImpl={vizWidgetImpl}
              />
            </div>
          </VerticallyCenteredRow>
        </>
      )}

      {!vizWidgetImpl && (
        <div className="content__viz inc-flex-column height-100 width-100 flex-gap-12">
          <div className="widget-container">
            <div className="widget-content">
              <LoadingSpinner />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const updateExpressionSlices = (expression: ExpressionDef, slices: UserServiceFieldSlice[]) => {
  if (!expression) {
    return;
  }

  const { leftExpr, rightExpr } = expression;

  // Handle left expression
  if (leftExpr?.expressionMetricConfig) {
    if (leftExpr.expressionMetricConfig.sliceSpec) {
      leftExpr.expressionMetricConfig.sliceSpec.sliceSet = convertToSliceSet({ slices });
    }

    // Recursively update if there is a nested expression
    if (leftExpr.expressionMetricConfig.expression) {
      updateExpressionSlices(leftExpr.expressionMetricConfig.expression, slices);
    }
  }

  // Handle right expression
  if (rightExpr?.expressionMetricConfig) {
    if (rightExpr.expressionMetricConfig.sliceSpec) {
      rightExpr.expressionMetricConfig.sliceSpec.sliceSet = convertToSliceSet({ slices });
    }

    // Recursively update if there is a nested expression
    if (rightExpr.expressionMetricConfig.expression) {
      updateExpressionSlices(rightExpr.expressionMetricConfig.expression, slices);
    }
  }
};
