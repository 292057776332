import {
  CircleProgressBar,
  IncClickAwayPopper,
  IncFaIcon,
  IncRadioButton,
  IncTextfield,
  IncToolTip
} from "@inception/ui";
import { inceptionDarkColorPalette } from "@inception/ui-styles";
import { cx } from "emotion";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import pluralize from "pluralize";
import { CustomSelect } from "..";
import { useTenantConfig } from "../../core";
import { CohortState, ResultCohort, useFetchCohortList } from "../../services/api/explore";
import { FieldPickerUtils, Options, getAllCohortName, getOpFromPredicateOp } from "../../utils";
import { VerticallyCenteredRow } from "../flex-components";

interface CohortPickerProps {
  onCohortChange: (cohort: ResultCohort, isCohortLocked?: boolean) => void;
  entityTypeId: string;
  value: string;
  label?: string;
  containerClass?: string;
  cohortState?: CohortState;
  disabled?: boolean;
  onCohortListFetched?: (cohortList: ResultCohort[]) => void;
  labelClass?: string;
  isCohortPinned?: boolean;
  customActions?: JSX.Element;
}

export const CohortPicker = (props: CohortPickerProps) => {
  const {
    entityTypeId,
    value: selectedCohortId,
    label = "Cohort",
    onCohortChange,
    containerClass,
    cohortState,
    disabled = false,
    onCohortListFetched,
    labelClass,
    customActions
  } = props;

  const [showPopper, setShowPopper] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [debounceSearchVal, setDebouncedSearchVal] = useState(searchText);
  const ref = useRef(null);
  const { data: cohortList, isFetching, refetch } = useFetchCohortList(entityTypeId);
  const prevCohortState = useRef<string>(cohortState);

  useEffect(() => {
    if (cohortState === "saved" && prevCohortState.current !== cohortState) {
      refetch();
    }
    prevCohortState.current = cohortState;
  }, [cohortState, refetch]);

  useEffect(() => {
    if (!isFetching && onCohortListFetched) {
      onCohortListFetched(cohortList);
    }
  }, [cohortList, isFetching, onCohortListFetched]);

  const { tenantConfigState } = useTenantConfig();

  const updatedSearchTextDebounced = useMemo(() => debounce((value: string) => setDebouncedSearchVal(value), 300), []);

  const onSearchTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setSearchText(value);
      updatedSearchTextDebounced(value);
    },
    [updatedSearchTextDebounced]
  );

  const selectedCohortName = useMemo(() => {
    if (isFetching) {
      return "Loading...";
    } else if (cohortList?.length > 0 && selectedCohortId && cohortState === "saved") {
      let name = "";
      cohortList.forEach(x => {
        const nestedCohort = x.segments?.find(seg => seg.id === selectedCohortId);
        if (x.id === selectedCohortId) {
          name = x.name;
        } else if (nestedCohort) {
          const { id } = nestedCohort;
          const cohortSegment =
            nestedCohort.cohortDefinition.cohortDefinition.segmentCreationConfigs?.[0]?.segment.find(
              seg => seg.cohortId === id
            );
          name = `${
            nestedCohort.cohortDefinition.cohortDefinition.name
          }(${cohortSegment.function} ${cohortSegment.range.from} - ${cohortSegment.range.to})`;
        }
      });
      return name;
    } else if (selectedCohortId) {
      return "Draft cohort";
    }
    return getAllCohortName(entityTypeId);
  }, [cohortList, cohortState, entityTypeId, isFetching, selectedCohortId]);

  const onClickCohort = useCallback(
    (id: string, segmentId?: string) => {
      if (id && segmentId) {
        const cohort = cohortList.find(x => x.id === id);
        const segment = cohort.segments.find(x => x.id === segmentId);
        onCohortChange(segment);
      } else {
        onCohortChange(cohortList.find(x => x.id === id));
      }
    },
    [cohortList, onCohortChange]
  );

  const CohortConfigList = useMemo(() => {
    const cohortContentList: JSX.Element[] = [];
    const allCohortLabel = `All ${pluralize(entityTypeId || "")}`;
    if (allCohortLabel.toLowerCase().includes(debounceSearchVal.toLowerCase())) {
      cohortContentList.push(
        <IncRadioButton
          checked={!selectedCohortId}
          className={!selectedCohortId ? "cohort-tab-selected" : "cohort-tab"}
          label={<span className="marginTp8">{allCohortLabel}</span>}
          name={allCohortLabel}
          onClick={() => onCohortChange(null)}
        />
      );
    }
    const filteredList = cohortList?.filter(x => x.name.toLowerCase().includes(debounceSearchVal.toLowerCase()));
    if (filteredList?.length > 0) {
      filteredList?.forEach(cohortInfo => {
        const { id, segments } = cohortInfo;
        const onClick = () => onClickCohort(id);
        const checked = selectedCohortId === id;
        const className = checked ? "cohort-tab-selected" : "cohort-tab";
        const options: Options = { currency: tenantConfigState.currency };
        const labelElement = (
          <CohortTabRenderer
            cohortData={cohortInfo}
            name={cohortInfo.name}
            options={options}
          />
        );
        cohortContentList.push(
          <IncRadioButton
            checked={checked}
            className={className}
            disabled={cohortInfo.computationStatus?.isNotReady}
            key={id}
            label={labelElement}
            name={id}
            onClick={onClick}
          />
        );

        if (segments.length > 0) {
          segments.forEach(segment => {
            const { id: segmentId, cohortDefinition } = segment;
            const onClick = () => onClickCohort(id, segmentId);
            const checked = selectedCohortId === segmentId;
            const className = checked ? "cohort-tab-selected marginLt12" : "cohort-tab marginLt12";
            const cohortSegment = cohortDefinition.cohortDefinition.segmentCreationConfigs[0]?.segment.find(
              x => x.cohortId === segmentId
            );
            let name = `${cohortSegment.function} ${cohortSegment.range.from} - ${cohortSegment.range.to}`;
            if (cohortSegment.range.to === -1) {
              name = `${cohortSegment.function} ${cohortSegment.range.from} onwards`;
            }
            const labelElement = (
              <CohortTabRenderer
                cohortData={cohortInfo}
                name={name}
                options={options}
                showFilterData={false}
              />
            );
            cohortContentList.push(
              <VerticallyCenteredRow
                className="marginLt24 segment-border"
                key={segmentId}
              >
                <IncRadioButton
                  checked={checked}
                  className={className}
                  label={labelElement}
                  name={id}
                  onClick={onClick}
                />
              </VerticallyCenteredRow>
            );
          });
          cohortContentList.push(
            <div
              className="marginBt12"
              key={`${id}-spacer`}
            />
          );
        }
      });
      return cohortContentList;
    } else {
      return cohortContentList?.length ? cohortContentList : <div className="inc-text-subtext">No cohorts found</div>;
    }
  }, [
    cohortList,
    debounceSearchVal,
    selectedCohortId,
    entityTypeId,
    onCohortChange,
    tenantConfigState.currency,
    onClickCohort
  ]);

  const openPopper = useCallback(() => setShowPopper(true), []);
  const closePopper = useCallback(() => setShowPopper(false), []);

  const containerClassName = cx("cohort-picker-container", containerClass);

  return (
    <>
      <CustomSelect
        anchor={ref}
        className={containerClassName}
        customActions={customActions}
        disabled={disabled}
        label={label}
        labelClass={labelClass}
        labelPosition="left"
        onClick={openPopper}
        placeholderText="Select"
        value={selectedCohortName}
      />
      <IncClickAwayPopper
        anchorEl={ref.current}
        className="cohort-selection-popper"
        onClickAway={closePopper}
        placement="bottom-end"
        show={showPopper}
      >
        <div className="inc-text-body-medium marginBt16">Select a cohort</div>
        <div className="inc-flex-column">
          <IncTextfield
            autoFocus={true}
            className="marginBt16 search-field"
            onChange={onSearchTextChange}
            placeholder="Search"
            value={searchText}
          />
          {CohortConfigList}
        </div>
      </IncClickAwayPopper>
    </>
  );
};

interface RendererProps {
  cohortData: ResultCohort;
  options: Options;
  name: string;
  showFilterData?: boolean;
}

const CohortTabRenderer: React.FC<RendererProps> = ({ cohortData, options, name, showFilterData = true }) => {
  const {
    cohortDefinition: { entityLookupData, cohortDefinition },
    computationStatus,
    membersCount
  } = cohortData;
  const {
    cohortCreationConfig: { bizFieldPredicates, metricPredicates }
  } = cohortDefinition;

  const cohortFilterData = useMemo(() => {
    const bizPredicateList = bizFieldPredicates.map(x => {
      const values = x.values?.map(v => entityLookupData[v] || v) || [];
      const value = entityLookupData[x.value] || x.value;
      x.value = value;
      x.values = values;
      const { label } = FieldPickerUtils.getBizFieldPredicatePillLabelAndInfo(x, options);
      return label;
    });
    const metricPredicateList = metricPredicates.map(x => {
      const op = getOpFromPredicateOp(x.op);
      return `${x.metric.name} ${op} ${x.value}`;
    });
    const filterList = bizPredicateList.concat(metricPredicateList);
    const limit = 3;
    const displayList = filterList.slice(0, limit).join(", ");
    const limitExceeds = filterList.length > limit;
    const remainingFilters = filterList.splice(limit);
    const moreFilterString = remainingFilters.join(", ");
    if (!limitExceeds) {
      return <span className="filter-text">{displayList}</span>;
    } else {
      return (
        <span className="filter-text">
          {`${displayList} `}
          <IncToolTip
            placement="top-start"
            showArrow
            titleText={moreFilterString}
          >
            <span className="link">+{remainingFilters.length}</span>
          </IncToolTip>
        </span>
      );
    }
  }, [bizFieldPredicates, entityLookupData, metricPredicates, options]);

  const className = cx("cohort-data-panel", {
    paddingLt8: showFilterData,
    paddingTp8: showFilterData
  });

  return (
    <div className={className}>
      <VerticallyCenteredRow>
        <span className="inc-text-subtext">
          {name} ({membersCount ? membersCount : ""})
        </span>
        {computationStatus?.isNotReady ? (
          <IncToolTip
            titleElement={
              <VerticallyCenteredRow>
                <span className="inc-text-subtext marginLt8 marginRt8">
                  {computationStatus?.percentCompleted || 0}% completed
                </span>
                <CircleProgressBar
                  progress={computationStatus?.percentCompleted || 0}
                  size={30}
                />
              </VerticallyCenteredRow>
            }
          >
            <span className="marginLt8">
              <IncFaIcon iconName="info-circle" />
            </span>
          </IncToolTip>
        ) : (
          <IncFaIcon
            className="marginLt8"
            color={inceptionDarkColorPalette.accentGreen}
            iconName="circle-check"
          />
        )}
      </VerticallyCenteredRow>
      {showFilterData && cohortFilterData}
    </div>
  );
};

// titleText={`Cohort not ready, ${completionStatus?.percentCompleted || "0"}% completed`}
