import React, { FC, useCallback, useMemo } from "react";
import { cloneDeep } from "lodash";
import { IncSelect, IncSelectOption } from "@inception/ui";
import { CatalogVizRendererProps, useFetchFieldNameMetricNames } from "../common";
import { SortAndLimit, VerticallyCenteredRow } from "../../../../../../components";
import { TableRenderer } from "../table";
import SingleStatWrapper from "./SingleStatWrapper";
import BarChartWrapper from "./BarChartWrapper";
import TopNAndSliceSelector from "./TopNAndSliceSelector";
import { getBarChartProps, getTableProps } from "./topNUtils";

export const TopNRenderer: FC<CatalogVizRendererProps> = props => {
  const {
    aggregatedTags,
    seriesLimit,
    dataFetchPayload,
    childrenDataFetchPayload,
    properties,
    displayAggregatedTags,
    onChangeTopNProperties,
    aggregator,
    queryConfig,
    eventTypeName,
    metricId: queryMetricId,
    readonly = false
  } = props;

  const { topN: topNProperties } = properties || {};
  const { showBarChart, metricIds: pMetricIds } = topNProperties || {};

  // topN limit
  const topN: SortAndLimit = useMemo(() => {
    const { sortLimit: pSortLimit } = topNProperties || {};
    const dSortLimit: SortAndLimit = {
      limit: seriesLimit,
      sortType: "top"
    };
    return pSortLimit || dSortLimit;
  }, [topNProperties, seriesLimit]);

  const onSelectTopN = useCallback(
    (val: SortAndLimit) => {
      const dProperties = cloneDeep(topNProperties);
      if (dProperties) {
        dProperties.sortLimit = val;
        if (onChangeTopNProperties) {
          onChangeTopNProperties(dProperties);
        }
      } else {
        const topNProperties = { sortLimit: val };
        if (onChangeTopNProperties) {
          onChangeTopNProperties(topNProperties);
        }
      }
    },
    [topNProperties, onChangeTopNProperties]
  );

  // breakdown dimension slice
  const aggTags = useMemo(() => {
    const { aggregatedTags: pAggTags } = topNProperties || {};
    return pAggTags || aggregatedTags;
  }, [topNProperties, aggregatedTags]);

  const onSelectTag = useCallback(
    (val: string[]) => {
      const dProperties = cloneDeep(topNProperties);
      if (dProperties) {
        dProperties.aggregatedTags = val;
        if (onChangeTopNProperties) {
          onChangeTopNProperties(dProperties);
        }
      } else {
        const topNProperties = {
          aggregatedTags: val
        };
        if (onChangeTopNProperties) {
          onChangeTopNProperties(topNProperties);
        }
      }
    },
    [onChangeTopNProperties, topNProperties]
  );

  const { metricName, childMetricNames } = useFetchFieldNameMetricNames(aggregator, queryConfig, eventTypeName);
  const { metricNamesMap, metricIds } = useMemo(() => {
    const metricNamesMap = {
      [queryMetricId]: metricName,
      ...childMetricNames
    };
    const metricIds = Object.keys(metricNamesMap);

    return {
      metricNamesMap,
      metricIds
    };
  }, [queryMetricId, metricName, childMetricNames]);

  const metricOpts = useMemo(
    () =>
      metricIds.map(id => ({
        value: id,
        label: metricNamesMap[id] || ""
      })),
    [metricIds, metricNamesMap]
  );
  const filteredMetricIds = useMemo(() => {
    if (!pMetricIds) {
      return metricIds;
    } else {
      return pMetricIds?.filter(metricId => metricIds?.includes(metricId));
    }
  }, [metricIds, pMetricIds]);
  const selectedMetricOpt = useMemo(
    () =>
      filteredMetricIds?.map(metricId => ({
        value: metricId,
        label: metricNamesMap[metricId] || ""
      })),
    [filteredMetricIds, metricNamesMap]
  );

  const onChangeMetric = useCallback(
    (metricOpt: readonly IncSelectOption[]) => {
      if (properties.topN) {
        properties.topN.metricIds = metricOpt.map(e => e.value);
      } else {
        properties.topN = {
          metricIds: metricOpt.map(e => e.value)
        };
      }
      onChangeTopNProperties(properties.topN);
    },
    [onChangeTopNProperties, properties]
  );

  // props
  const tableProps = useMemo<
    Pick<
      CatalogVizRendererProps,
      | "queryConfig"
      | "metricId"
      | "childMetricIds"
      | "seriesLimit"
      | "aggregatedTags"
      | "childrenDataFetchPayload"
      | "dataFetchPayload"
    >
  >(
    () => getTableProps(dataFetchPayload, childrenDataFetchPayload, topN, aggTags, filteredMetricIds, queryConfig),
    [aggTags, childrenDataFetchPayload, dataFetchPayload, filteredMetricIds, queryConfig, topN]
  );
  const barChartProps = useMemo<
    Pick<CatalogVizRendererProps, "metricId" | "childMetricIds" | "childrenDataFetchPayload" | "dataFetchPayload">
  >(
    () => getBarChartProps(dataFetchPayload, childrenDataFetchPayload, filteredMetricIds),
    [childrenDataFetchPayload, dataFetchPayload, filteredMetricIds]
  );

  const tableProperties = useMemo(
    () => ({
      ...properties,
      table: null
    }),
    [properties]
  );

  return (
    <div className="topn-wrapper">
      {!readonly && (
        <VerticallyCenteredRow>
          <IncSelect
            alignment="row"
            isMulti
            label="Metrics to show"
            onChange={onChangeMetric}
            options={metricOpts}
            value={selectedMetricOpt}
          />
        </VerticallyCenteredRow>
      )}
      {filteredMetricIds?.length ? (
        <>
          <SingleStatWrapper
            {...props}
            selectedMetricIds={filteredMetricIds}
          />
          {showBarChart && (
            <BarChartWrapper
              {...props}
              {...barChartProps}
            />
          )}
          {!readonly && (
            <TopNAndSliceSelector
              aggTags={aggregatedTags}
              displayTags={displayAggregatedTags}
              onSelectTag={onSelectTag}
              onSelectTopN={onSelectTopN}
              selectedTag={aggTags}
              selectedTopN={topN}
            />
          )}
          <TableRenderer
            {...props}
            {...tableProps}
            properties={tableProperties}
          />
        </>
      ) : (
        <VerticallyCenteredRow className="message inc-flex-center">No metrics found</VerticallyCenteredRow>
      )}
    </div>
  );
};
