import React, { memo, CSSProperties } from "react";
import { GroupedBarEntry } from "./types";

type Props = GroupedBarEntry & {
  widthPer: number;
};

export const GroupedBarsHBar = memo((props: Props) => {
  const { color, name, widthPer, events } = props;

  const barStyle: CSSProperties = {
    height: 20,
    width: `${widthPer}%`,
    backgroundColor: color || "red",
    cursor: events?.click ? "pointer" : "default"
  };

  const onClick = (event: React.MouseEvent) => {
    if (events?.click) {
      events.click(event.nativeEvent, props);
    }
  };

  return (
    <div
      className="grouped-bars--bar"
      onClick={onClick}
      style={barStyle}
      title={name}
    />
  );
});
