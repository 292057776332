import { cx } from "emotion";
import { forEach, isEqual } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { SortAndLimit, VerticallyCenteredRow } from "../../../components";
import { DataType, useForceUpdate, useTenantConfig, Visualisations } from "../../../core";
import { entityApiService } from "../../../services/api";
import { LimitSpec, PostAggProjection } from "../../../services/api/explore";
import { ENTITY_TAG } from "../../../utils";
import { GLOBAL_VARIABLE_SRV_KEY } from "../../variables/constants";
import { WidgetCustomAction } from "../types";
import appConfig from "../../../../appConfig";
import { VizRenderer, VizSwitcher } from "./components";
import { useFetchQueryPayload } from "./components/renderers/common";
import { CatalogVizWrapperProps, VizOption } from "./types";
import { CatalogWidgetUtils } from "./CatalogWidgetUtils";
import { TopNProperties } from "./models";

export const VisualizationWrapper: FC<CatalogVizWrapperProps> = props => {
  const {
    timeRange,
    noDataElement,
    loadingElement,
    widget,
    compareTimeRange,
    variablesLoadingElement,
    onError,
    metricId,
    childMetricIds,
    widgetSelectorSpec,
    seriesFilters,
    aggregator,
    aggregateTags,
    widgetResponseDTO,
    cohortFilters,
    entityFilters,
    eventFilters,
    variablesLoading,
    variableSrvMap,
    onAddAdhocEntityFilter,
    onAddAdhocEventFilter,
    onActionsChange,
    onFetchData,
    edit,
    children,
    demoDataParams,
    onQueryConfigChange,
    onPropertiesChange,
    fetchResultMetaProps
  } = props;

  const {
    entityType,
    userServiceId,
    queryConfig,
    id,
    properties,
    eventTypeName: pEventTypeName,
    renderMode,
    downsample
  } = widget;

  const isEventIDField = useMemo(() => {
    const sourceQueryConfig = queryConfig?.sourceQueryConfig;
    const usField =
      sourceQueryConfig?.queryType === "userServiceField" ? sourceQueryConfig?.usField?.userServiceField : null;
    const fieldName = usField?.fieldName;

    return CatalogWidgetUtils.isHasErrorField(fieldName) || CatalogWidgetUtils.isHasErrorField(fieldName);
  }, [queryConfig]);

  const defEventTypeNameLoading = userServiceId ? isEventIDField && !pEventTypeName : false;
  const shouldFetchEventTypeName = useRef(defEventTypeNameLoading);

  const [eventTypeNameLoading, setEventTypeNameLoading] = useState(defEventTypeNameLoading);
  const [eventTypeName, setEventTypeName] = useState(pEventTypeName);

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (!eventTypeName && isEventIDField && userServiceId && shouldFetchEventTypeName.current) {
      setEventTypeNameLoading(true);
      const entityIds = new Set<string>();
      entityIds.add(userServiceId);

      entityApiService
        .fetchEntityNamesForIds(entityIds)
        .then(res => {
          const eventTypeName = res.get(userServiceId);
          setEventTypeName(eventTypeName);
        })
        .finally(() => {
          shouldFetchEventTypeName.current = false;
          setEventTypeNameLoading(false);
        });
    }
  }, [eventTypeName, isEventIDField, userServiceId]);

  const { visualisation: presetVisualisation, table: presetTable, disableCompare = false, pinnedViz } = properties;
  const { changeMetric = "current", limitSpec: presetLimitSpec } = presetTable || {};
  const postAggProjections = useMemo<PostAggProjection[]>(
    () => (disableCompare ? ["current"] : ["current", "timeShift"]),
    [disableCompare]
  );

  const eventTypeId = userServiceId || widgetResponseDTO?.widgetConfig?.userServiceEntityId;

  const { tenantConfigState } = useTenantConfig();
  const currencyType = tenantConfigState.currency;

  const vizOpts = useMemo(() => CatalogWidgetUtils.getVisualisations(queryConfig), [queryConfig]);
  const pinnedVizOpts = useMemo(() => {
    const pinnedVizOpts = pinnedViz?.length
      ? vizOpts.filter(opt => pinnedViz.includes(opt.visualisation))
      : vizOpts.slice(0, 2);
    const defVizOpt = vizOpts.find(opt => opt.visualisation === presetVisualisation) || pinnedVizOpts[0];

    const shouldAddDefToPinned = !pinnedVizOpts.find(opt => opt.visualisation === defVizOpt?.visualisation);
    if (shouldAddDefToPinned) {
      pinnedVizOpts.unshift(defVizOpt);
    }

    return pinnedVizOpts;
  }, [pinnedViz, presetVisualisation, vizOpts]);

  const vizConfig = useMemo(
    () => vizOpts.find(({ visualisation }) => visualisation === presetVisualisation) || vizOpts[0],
    [presetVisualisation, vizOpts]
  );

  const setVizConfig = useCallback(
    (vizConfig: VizOption) => {
      const { visualisation } = vizConfig;
      widget.properties.visualisation = vizConfig.visualisation;

      const nSortAndLimit = getSortAndLimitByViz(visualisation, presetLimitSpec);
      if (vizConfig.visualisation === Visualisations.insights) {
        widget.properties.insights = {
          ...(widget.properties.insights || ({} as any)),
          limitSpec: {
            function: nSortAndLimit.sortType,
            limit: nSortAndLimit.limit
          }
        };
      }

      if (vizConfig.visualisation === Visualisations.table) {
        widget.properties.table = {
          ...(widget.properties.table || ({} as any)),
          limitSpec: {
            function: nSortAndLimit.sortType,
            limit: nSortAndLimit.limit
          }
        };
      }

      forceUpdate();
    },
    [forceUpdate, presetLimitSpec, widget.properties]
  );

  useMemo(() => {
    if (!presetVisualisation && vizConfig) {
      widget.properties.visualisation = vizConfig.visualisation;
    }
  }, [presetVisualisation, vizConfig, widget.properties]);

  const currViz = vizConfig?.visualisation;

  const sortAndLimit = useMemo(() => getSortAndLimitByViz(currViz, presetLimitSpec), [currViz, presetLimitSpec]);

  const onChangeCustomHeaderMap = useCallback(
    (metricId: string, selectionMap: Record<string, string>) => {
      if (widget.properties.metricConfig) {
        widget.properties.metricConfig = {
          ...widget.properties.metricConfig,
          [metricId]: {
            rawEvents: {
              ...(widget.properties.metricConfig?.[metricId]?.rawEvents || {}),
              columns: selectionMap
            }
          }
        };
      } else {
        widget.properties.metricConfig = {
          [metricId]: {
            rawEvents: {
              columns: selectionMap
            }
          }
        };
      }
      forceUpdate();
      enqueueSnackbar("Saved Successfully", { variant: "success" });
    },
    [forceUpdate, widget]
  );

  const onChangeTopNProperties = useCallback(
    (topNProperties: Partial<TopNProperties>) => {
      if (widget.properties.topN) {
        const { aggregatedTags, metricIds, showBarChart, sortLimit } = widget.properties.topN;
        const {
          aggregatedTags: pAggregatedTags,
          metricIds: pMetricIds,
          showBarChart: pShowBarChart,
          sortLimit: pSortLimit
        } = topNProperties || {};
        if (!isEqual(aggregatedTags, pAggregatedTags) && pAggregatedTags) {
          widget.properties.topN = {
            ...widget.properties.topN,
            aggregatedTags: pAggregatedTags
          };
        }
        if (!isEqual(pMetricIds, metricIds) && pMetricIds) {
          widget.properties.topN = {
            ...widget.properties.topN,
            metricIds: pMetricIds
          };
        }
        if (!isEqual(showBarChart, pShowBarChart) && pShowBarChart) {
          widget.properties.topN = {
            ...widget.properties.topN,
            showBarChart: pShowBarChart
          };
        }
        if (!isEqual(sortLimit, pSortLimit) && pSortLimit) {
          widget.properties.topN = {
            ...widget.properties.topN,
            sortLimit: pSortLimit
          };
        }
      } else {
        widget.properties.topN = topNProperties;
      }
      onPropertiesChange(widget.properties);
      forceUpdate();
    },
    [forceUpdate, onPropertiesChange, widget.properties]
  );

  const [customActions, setCustomActions] = useState<WidgetCustomAction[]>([]);

  useEffect(() => {
    if (vizConfig.visualisation === Visualisations.insights && !presetLimitSpec) {
      widget.properties.insights = {
        ...(widget.properties.insights || ({} as any)),
        limitSpec: {
          function: sortAndLimit.sortType,
          limit: sortAndLimit.limit
        }
      };
    }

    if (vizConfig.visualisation === Visualisations.table && !presetLimitSpec) {
      widget.properties.table = {
        ...(widget.properties.table || ({} as any)),
        limitSpec: {
          function: sortAndLimit.sortType,
          limit: sortAndLimit.limit
        }
      };
    }
  }, [vizConfig.visualisation, sortAndLimit.limit, sortAndLimit.sortType, widget.properties, presetLimitSpec]);

  const { uiDataType, dataTypeByMetricId } = useMemo(
    () => CatalogWidgetUtils.getDataTypeAndAggInfo(queryConfig),
    [queryConfig]
  );

  const actionsClassName = useMemo(
    () =>
      cx({
        disableClick: variablesLoading
      }),
    [variablesLoading]
  );

  const { rLoadingElement, rNoDataElement } = useMemo(
    () => ({
      rLoadingElement: <>{loadingElement}</>,
      rNoDataElement: <>{noDataElement}</>
    }),
    [loadingElement, noDataElement]
  );

  const { limit: seriesLimit, sortType: limitSpecFunction } = sortAndLimit;

  const downsampleStr = downsample || "auto";

  const isTimeseriesQuery = [Visualisations.timeseries, Visualisations.sparkLine].includes(currViz);
  const isSingleStatQuery = !isTimeseriesQuery && downsampleStr === "auto";

  const { dataFetchPayload, childrenDataFetchPayload } = useFetchQueryPayload(
    widgetResponseDTO,
    aggregateTags,
    queryConfig,
    vizConfig,
    timeRange,
    compareTimeRange,
    widgetSelectorSpec,
    metricId,
    childMetricIds,
    changeMetric,
    postAggProjections,
    limitSpecFunction,
    seriesLimit,
    isSingleStatQuery,
    downsampleStr
  );

  const displayAggregatedTags = useMemo(
    () => aggregateTags?.map(aggTag => (aggTag === ENTITY_TAG ? entityType : aggTag)),
    [aggregateTags, entityType]
  );

  const qs = widgetResponseDTO?.querySchema?.querySchema;
  const querySchema = useMemo(() => qs || [], [qs]);

  const aggregateDataType = useMemo<DataType>(() => {
    const { sliceSet } = querySchema?.find(qs => qs.metricId === metricId) || {};
    if (sliceSet) {
      const matchSlices = sliceSet.slices.filter(sl => aggregateTags?.includes(sl.tagName));
      if (matchSlices.length === 1) {
        return matchSlices[0]?.fieldType || "STRING";
      }
    }

    return "STRING";
  }, [aggregateTags, metricId, querySchema]);

  const existingEntityTypeFilters = useMemo(() => {
    const entityFiltersMap: Record<string, boolean> = {};
    forEach(variableSrvMap, (value, key) => {
      const entityType = value.getEntityTypeId();
      if (value.checkIfFilterWidget() && entityType && key !== GLOBAL_VARIABLE_SRV_KEY) {
        entityFiltersMap[entityType] = true;
      }
    });
    return entityFiltersMap;
  }, [variableSrvMap]);

  useEffect(() => {
    const allActions = [...customActions];
    onActionsChange(allActions);

    return () => onActionsChange([]);
  }, [customActions, onActionsChange]);

  const isVizOnlyMode = renderMode === "viz-only";

  const isSharedDashboard = Boolean(appConfig.anomShareId);

  return (
    <>
      {!isVizOnlyMode && Boolean(children) && (
        <VerticallyCenteredRow
          className={actionsClassName}
          style={{ gap: 12 }}
        >
          {!isVizOnlyMode && children}

          {!isVizOnlyMode && Boolean(0) && (
            <>
              <div className="inc-flex-spacer" />

              <VizSwitcher
                onVizChange={setVizConfig}
                renderAsSwitch
                selectedViz={vizConfig}
                vizOptions={pinnedVizOpts}
              />
            </>
          )}
        </VerticallyCenteredRow>
      )}

      {(variablesLoading || eventTypeNameLoading) && variablesLoadingElement}
      {!variablesLoading && !eventTypeNameLoading && (
        <VizRenderer
          aggregateDataType={aggregateDataType}
          aggregatedTags={aggregateTags}
          aggregator={aggregator}
          childMetricIds={childMetricIds}
          childrenDataFetchPayload={childrenDataFetchPayload}
          className="catalog-container--visualisation"
          cohortFilters={cohortFilters}
          compareTimeRange={compareTimeRange}
          currViz={vizConfig}
          currencyType={currencyType}
          dataFetchPayload={dataFetchPayload}
          dataType={uiDataType}
          dataTypeByMetricId={dataTypeByMetricId}
          demoDataParams={demoDataParams}
          displayAggregatedTags={displayAggregatedTags}
          downsample={downsampleStr}
          edit={edit}
          entityFilters={entityFilters}
          entityTypeName={entityType}
          eventFilters={eventFilters}
          eventTypeName={eventTypeName}
          existingEntityTypeFilters={existingEntityTypeFilters}
          fetchResultMetaProps={fetchResultMetaProps}
          limitSpecFunction={limitSpecFunction}
          loadingElement={rLoadingElement}
          metricId={metricId}
          noDataElement={rNoDataElement}
          onAddAdhocEntityFilter={onAddAdhocEntityFilter}
          onAddAdhocEventFilter={onAddAdhocEventFilter}
          onChangeTopNProperties={onChangeTopNProperties}
          onCustomActionsChange={setCustomActions}
          onError={onError}
          onFetchData={onFetchData}
          onPropertiesChange={onPropertiesChange}
          onQueryConfigChange={onQueryConfigChange}
          onSaveCustomHeaderMap={onChangeCustomHeaderMap}
          postAggProjections={postAggProjections}
          properties={properties}
          queryConfig={queryConfig}
          queryId={id}
          readonly={isSharedDashboard}
          renderMode={renderMode}
          seriesFilters={seriesFilters}
          seriesLimit={seriesLimit}
          timeRange={timeRange}
          userServiceId={eventTypeId}
          variablesLoading={variablesLoading}
          widgetResponseDTO={widgetResponseDTO}
          widgetSelectorSpec={widgetSelectorSpec}
          widgetTitle={widget.title}
        />
      )}
    </>
  );
};

const DEFAULT_SERIES_LIMIT = 30;
const DEFAULT_TOP_K_SERIES_LIMIT = 50;

// const getProjectionByViz = (visualisation: Visualisations): PostAggProjection => visualisation === Visualisations.insights ? 'all'
//   : 'current';

const getSortAndLimitByViz = (visualisation: Visualisations, limitSpec: LimitSpec): SortAndLimit => {
  const shouldApplyLimitSpec = visualisation === Visualisations.insights || visualisation === Visualisations.table;

  const sortAndLimit: SortAndLimit = shouldApplyLimitSpec
    ? {
        limit: limitSpec?.limit || DEFAULT_TOP_K_SERIES_LIMIT,
        sortType: limitSpec?.function || "top"
      }
    : {
        limit: DEFAULT_SERIES_LIMIT,
        sortType: null
      };

  return sortAndLimit;
};
