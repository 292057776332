import React, { memo, useMemo, useCallback } from "react";
import { IncSelect, IncTextfield, IncSelectOption, IncToggle, IncEditor } from "@inception/ui";
import {
  ParamValueTemplate,
  ActionConfigUIParam,
  PrimType,
  ParamValue,
  ActionRunMode
} from "../../../../../services/api/operationalise";
import { logger, EntityPropertyMap, EntityPropertySet, ObjectPropertyMap } from "../../../../../core";
import { getLabelFromParamValue, getPropertyValueByDataType, getValueFromParamValue } from "../../../../utils";
import { ActionParamsEditorProps } from "./types";

type ParamProps = {
  uiParam: ActionConfigUIParam;
  onChange: (nValue: ParamValueTemplate) => void;
  value: ParamValueTemplate;
  lookupValuesMap: ActionParamsEditorProps["lookupValuesMap"];
  actionRunMode: ActionRunMode;
  disabled?: boolean;
};

type Option = IncSelectOption<ParamValue>;

export const ParamsEditor = memo<ParamProps>(props => {
  const { onChange, uiParam, value, lookupValuesMap, disabled, actionRunMode } = props;

  const {
    visible: pVisible,
    editable,
    description,
    label,
    required,
    hasValuesLookup,
    name: paramName,
    type,
    unsupportedActionRunModes
  } = uiParam;

  const isDisabled = disabled || !editable;

  const visible = pVisible && !(unsupportedActionRunModes || []).includes(actionRunMode);

  const paramSuggestionValues = useMemo(
    () => (lookupValuesMap?.paramValues || {})[paramName]?.value || [],
    [lookupValuesMap, paramName]
  );

  const options = useMemo<Option[]>(
    () =>
      (paramSuggestionValues || []).map(paramValue => ({
        label: paramValue.name,
        value: paramValue.name,
        data: paramValue
      })),
    [paramSuggestionValues]
  );

  const selectedOpt = getSelectedOption(options, value, type);

  const onValChange = useCallback(
    (value: string | boolean | number | EntityPropertyMap | EntityPropertySet | ObjectPropertyMap | any) => {
      let strVal = value;
      let label = "";
      let propertyValue = null;
      if (value["id"]) {
        strVal = value["id"];
        label = value["name"];
      }
      propertyValue = getPropertyValueByDataType(strVal, type);
      onChange({
        label: label,
        value: propertyValue
      });
    },
    [onChange, type]
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = type === "_long" || type === "_double" ? e.target.valueAsNumber : e.target.value;
      onValChange(value);
    },
    [onValChange, type]
  );

  const onBoolChange = useCallback(
    (nBool: boolean) => {
      onValChange(nBool);
    },
    [onValChange]
  );

  const onSelectChange = useCallback(
    (opt: IncSelectOption) => {
      const { data } = opt;
      onValChange(data);
    },
    [onValChange]
  );

  const onCustomTextChange = useCallback(
    (data: string) => {
      let val = data;
      try {
        val = JSON.parse(data);
      } catch (e) {
        if (type === "_map" || type === "_set" || type === "_objectmap") {
          logger.error("Integration params editor", "Error parsing JSON", data);
        }
      }

      onValChange(val);
    },
    [onValChange, type]
  );

  if (!visible) {
    return <></>;
  }

  const primVal = getValueFromParamValue(value, type);

  if (hasValuesLookup) {
    const placeholder = `Select ${label}`;

    return (
      <IncSelect
        allowCreate
        helpText={description}
        isDisabled={disabled || !editable}
        label={label}
        onChange={onSelectChange}
        options={options}
        placeholder={placeholder}
        required={required}
        value={selectedOpt}
        wrapperClass="marginBt12"
      />
    );
  }

  if (type === "_str") {
    const strVal = primVal as string;

    return (
      <IncTextfield
        containerClassName="marginBt12"
        disabled={isDisabled}
        helpText={description}
        label={label}
        onChange={onTextChange}
        required={required}
        value={strVal}
      />
    );
  }

  if (type === "_double" || type === "_long") {
    const numVal = primVal as number;
    return (
      <IncTextfield
        containerClassName="marginBt12"
        disabled={isDisabled}
        helpText={description}
        label={label}
        onChange={onTextChange}
        required={required}
        type="number"
        value={numVal}
      />
    );
  }

  // TO-DO: Add dateTime picker
  if (type === "_datetime" || type === "_date") {
    const dateTimeVal = primVal as string;
    return (
      <IncTextfield
        containerClassName="marginBt12"
        disabled={isDisabled}
        helpText={description}
        label={label}
        onChange={onTextChange}
        placeholder={label}
        required={required}
        value={dateTimeVal}
      />
    );
  }

  if (type === "_bool") {
    const boolVal = primVal ? (primVal as boolean) : false;
    return (
      <IncToggle
        checked={boolVal}
        className="marginBt12"
        disabled={isDisabled}
        helpText={description}
        label={label}
        onChange={onBoolChange}
        required={required}
      />
    );
  }

  const jsonVal = typeof primVal === "object" ? JSON.stringify(primVal || "{}") : (primVal || "").toString();

  return (
    <IncEditor
      helpText={description}
      label={label}
      maxLines={4}
      onChange={onCustomTextChange}
      value={jsonVal}
    />
  );
});

const getSelectedOption = (options: Option[], value: ParamValueTemplate, dataType: PrimType) => {
  let selOpt = options.find(opt => {
    const { data: optData } = opt;
    return (
      optData.id === getValueFromParamValue(value, dataType) || optData.name === getValueFromParamValue(value, dataType)
    );
  });
  const defValue = getValueFromParamValue(value, dataType) as string;
  const defLabel = getLabelFromParamValue(value);

  selOpt = selOpt || {
    label: defLabel,
    value: defValue
  };

  return selOpt;
};
