import { sortBy } from "lodash";
import BaseWidgetImpl from "../model-impl/BaseWidgetImpl";
import { WidgetExtProps, WidgetMaximizeProps } from "./types";

interface PropertiesPanelProps {
  widget: BaseWidgetImpl & any;
  onUpdate: (newModel: any) => void;
}
class WidgetRegistry {
  private readonly registry: Map<string, WidgetConfig> = new Map();

  register(widgetConfig: WidgetConfig) {
    this.registry.set(widgetConfig.id, widgetConfig);
  }

  getPropsByWidgetId(type: string): WidgetConfig {
    return this.registry.get(type);
  }

  getAllWidgetConfigs(): WidgetConfig[] {
    let configs: WidgetConfig[] = [];
    this.registry.forEach(config => {
      configs.push(config);
    });
    configs = sortBy(configs, "label");
    return configs;
  }
}

export const widgetRegistry = new WidgetRegistry();

export interface WidgetConfig {
  id: string;
  label: string;

  enableInDashboard: boolean;
  supportsComparison: boolean;
  supportsNativeEdit: boolean;

  supportsTimeRange?: boolean;
  implementsTimeRangeSwitch?: boolean;
  disableVizSwitch?: boolean;
  supportsMaximize?: boolean;

  iconComponent: () => JSX.Element;
  component: (props: WidgetExtProps & any) => JSX.Element;
  dimensions: () => Dimensions;

  propertiesPanelComponent?: (props: PropertiesPanelProps) => JSX.Element;
  maximizeComponent?: (props: WidgetMaximizeProps & any) => JSX.Element;
}

export interface Dimensions {
  w: number;
  h: number;
  minW: number;
  minH: number;
}
