import { IncTextArea, IncButton } from "@inception/ui";
import React, { useCallback, useEffect } from "react";
import { VerticallyCenteredRow } from "../../../../../../platform/components";
import { EntityPropertyValue, useToggleState } from "../../../../../../platform/core";
import { GoogleSearchToolSpec, ToolParam } from "../../../../../../platform/services/api/explore";
import ParamsEditor from "./ParamsEditor";

type Props = {
  googleSearchToolSpec: GoogleSearchToolSpec;
  onChange: (googleSearchToolSpec: GoogleSearchToolSpec) => void;
  params: ToolParam[];
  children?: React.ReactNode;
  updateHeight: () => void;
};

const GoogleSearchToolSpecEditor: React.FC<Props> = props => {
  const { googleSearchToolSpec, onChange, params, children, updateHeight } = props;
  const { querySource, paramValues } = googleSearchToolSpec || {};

  const onChangeDescription = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...googleSearchToolSpec,
        querySource: e.target.value
      });
    },
    [googleSearchToolSpec, onChange]
  );
  const onChangeParamValues = useCallback(
    (key: string, value: EntityPropertyValue) => {
      onChange({
        ...googleSearchToolSpec,
        paramValues: {
          ...googleSearchToolSpec?.paramValues,
          [key]: value
        }
      });
    },
    [googleSearchToolSpec, onChange]
  );

  const { isOpen: isParamsEditorVisible, toggle: toggleParamsEditor } = useToggleState();

  useEffect(() => {
    updateHeight();
  }, [isParamsEditorVisible, updateHeight]);

  return (
    <>
      <IncTextArea
        errorText="Query source is required"
        hasError={!querySource}
        label="Query Source"
        name="querySource"
        onChange={onChangeDescription}
        placeholder="Enter query source"
        value={querySource}
      />
      <VerticallyCenteredRow>
        {children}
        {!!params?.length && (
          <IncButton
            className="marginLtAuto"
            color="link"
            onClick={toggleParamsEditor}
            size="small"
          >
            {isParamsEditorVisible ? "Hide additional params" : "Show additional params"}
          </IncButton>
        )}
      </VerticallyCenteredRow>
      {isParamsEditorVisible && (
        <div className="width-100 inc-flex-column flex-gap-12">
          {params.map((toolSpecParam, key) => (
            <ParamsEditor
              key={key}
              onChange={onChangeParamValues}
              paramValueRecord={paramValues}
              toolParam={toolSpecParam}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default GoogleSearchToolSpecEditor;
