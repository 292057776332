import React, { useMemo, useCallback } from "react";
import { IncFaIcon } from "@inception/ui";
import { USFWProps } from "../../types";
import { routePaths } from "../../../../../../app/RoutePaths";
import { useInceptionRoute } from "../../../../../core";

type Props = {
  widget: USFWProps["widget"];
};

export const useViewConfigurationAction = (props: Props) => {
  const { widget } = props;
  const { widgetConfigRefId } = widget;

  const { navigate } = useInceptionRoute();

  const onConfigClick = useCallback(() => {
    const path = `${routePaths.configuration}/edit?id=${widgetConfigRefId}&configType=c_widget_config`;
    navigate(path, { newTab: true });
  }, [navigate, widgetConfigRefId]);

  const viewConfigurationAction = useMemo(() => {
    if (widgetConfigRefId) {
      return {
        actionComponent: (
          <div
            className="inc-flex-row inc-flex-center-vertical"
            onClick={onConfigClick}
          >
            <IncFaIcon iconName="code" />
            View configuration
          </div>
        )
      };
    } else {
      return null;
    }
  }, [onConfigClick, widgetConfigRefId]);

  return viewConfigurationAction;
};
