import React, { createContext, useReducer, useContext } from "react";
import { NEW_CONVERSATION_ID } from "../constants";
import chatBotReducer from "./reducer";
import { ChatBotState, ChatBotStateAction } from "./types";

interface ChatBotContextProps {
  state: ChatBotState;
  dispatch: React.Dispatch<ChatBotStateAction>;
}

interface Props {
  initState?: ChatBotState;
  children?: React.ReactNode;
}

const ChatBotContext = createContext<ChatBotContextProps>(null);

export function ChatBotProvider(props: Props) {
  const { children, initState } = props;

  const [state, dispatch] = useReducer(chatBotReducer, initChatBotState(initState));

  return (
    <ChatBotContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      {children}
    </ChatBotContext.Provider>
  );
}

export const useChatBotStore = () => useContext(ChatBotContext);

const initChatBotState = (initState: Partial<ChatBotState>): ChatBotState => ({
  conversationsList: initState?.conversationsList || [],
  conversationsMap: initState?.conversationsMap || {},
  selectedConversationId: initState?.selectedConversationId || NEW_CONVERSATION_ID,
  showHistory: false
});
