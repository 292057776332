import { IncFaIcon, IncFaIconName, IncModal, IncModalProps } from "@inception/ui";
import React, { CSSProperties, FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { VerticallyCenteredRow } from "../../components";
import { useToggleState, Visualisations } from "../../core";
import { TreeMapIcon } from "../../core/iconwrapper";
import { featureFlagService } from "../../services/feature-flags";
import { AddMetricModalV2, AddMetricModalV2Props } from "./AddMetricModalV2";

type Props = AddMetricModalV2Props;

export const AddMetricModalWithVizSelection: FC<Props> = props => {
  const { presetViz, open } = props;

  const {
    isOpen: isAddMetricModalOpen,
    close: closeAddMetricModal,
    open: openAddMetricModal
  } = useToggleState(Boolean(presetViz));

  const { isOpen: isVizModalOpen, close: closeVizModal, open: openVizModal } = useToggleState(!presetViz);

  const visualisationRef = useRef(presetViz || Visualisations.table);

  const onCloseModal = useCallback(() => {
    closeVizModal();
    closeAddMetricModal();
    props.onClose?.();
  }, [closeAddMetricModal, closeVizModal, props]);

  const onAddWidget = useCallback(
    (visualisation: Visualisations) => {
      visualisationRef.current = visualisation;
      closeVizModal();
      openAddMetricModal();
    },
    [closeVizModal, openAddMetricModal]
  );

  useEffect(() => {
    if (open && !presetViz) {
      openVizModal();
    }
  }, [openVizModal, presetViz, open]);

  return (
    <>
      {isVizModalOpen && (
        <VizSelectionModal
          onAddWidget={onAddWidget}
          onClose={onCloseModal}
        />
      )}

      {isAddMetricModalOpen && (
        <AddMetricModalV2
          {...props}
          onClose={onCloseModal}
          open
          presetViz={visualisationRef.current}
        />
      )}
    </>
  );
};

type VizSelectionModalProps = {
  onAddWidget: (visualisation: Visualisations) => void;
  onClose: () => void;
};

const VizSelectionModal: FC<VizSelectionModalProps> = memo(props => {
  const { onAddWidget, onClose } = props;

  const [visualisation, setVisualisation] = useState(Visualisations.table);

  const actions = useMemo<IncModalProps["actions"]>(
    () => ({
      primary: {
        onClick: () => {
          onAddWidget(visualisation);
        },
        label: "Add Widget",
        icon: "plus"
      }
    }),
    [onAddWidget, visualisation]
  );

  const { popularWidgetOptions, allWidgetOptions } = useMemo(() => {
    let popularIsSelected = false;

    const popularWidgetOptions = popularViz.map(viz => {
      const key = `popular-${viz}`;
      const { iconName, style: partIconStyle, tooltip } = iconInfoMap[viz];

      const isSelected = viz === visualisation;
      popularIsSelected = popularIsSelected || isSelected;

      const onClick = () => setVisualisation(viz);

      const iconStyle = {
        ...partIconStyle,
        height: 20
      };

      const style: CSSProperties = {
        ...vizCardStyle,
        boxShadow: isSelected ? "0 0 0 2px #39ACFF" : "none"
      };

      return (
        <div
          className="inc-cursor-pointer"
          key={key}
          onClick={onClick}
          style={style}
        >
          <VerticallyCenteredRow
            className="inc-flex-center"
            style={vizIconStyle}
          >
            {viz === Visualisations.treeMap ? (
              <TreeMapIcon style={iconStyle} />
            ) : (
              <IncFaIcon
                iconName={iconName}
                style={iconStyle}
              />
            )}
          </VerticallyCenteredRow>

          <VerticallyCenteredRow className="inc-label-common">{tooltip}</VerticallyCenteredRow>
        </div>
      );
    });

    const allWidgetOptions = allViz.map(viz => {
      const key = `all-${viz}`;
      const { iconName, style: partIconStyle, tooltip } = iconInfoMap[viz];

      const isSelected = !popularIsSelected && viz === visualisation;

      const onClick = () => setVisualisation(viz);

      const iconStyle = {
        ...partIconStyle,
        height: 20
      };

      const style: CSSProperties = {
        ...vizCardStyle,
        boxShadow: isSelected ? "0 0 0 2px #39ACFF" : "none"
      };

      return (
        <div
          className="inc-cursor-pointer"
          key={key}
          onClick={onClick}
          style={style}
        >
          <VerticallyCenteredRow
            className="inc-flex-center"
            style={vizIconStyle}
          >
            {viz === Visualisations.treeMap ? (
              <TreeMapIcon style={iconStyle} />
            ) : (
              <IncFaIcon
                iconName={iconName}
                style={iconStyle}
              />
            )}
          </VerticallyCenteredRow>

          <VerticallyCenteredRow className="inc-label-common">{tooltip}</VerticallyCenteredRow>
        </div>
      );
    });

    return {
      popularWidgetOptions,
      allWidgetOptions
    };
  }, [visualisation]);

  return (
    <IncModal
      actions={actions}
      className="inc-regular-modal"
      onClose={onClose}
      show
      showClose
      size="lg"
      titleText="Add Widget"
    >
      <div className="inc-flex-column flex-gap-24 paddingBt6">
        <div className="inc-flex-column flex-gap-16">
          <VerticallyCenteredRow className="inc-text-body">Popular Widgets</VerticallyCenteredRow>

          <VerticallyCenteredRow className="flex-gap-24 inc-flex-row-wrap">
            {popularWidgetOptions}
          </VerticallyCenteredRow>
        </div>

        <div className="inc-flex-column flex-gap-16">
          <VerticallyCenteredRow className="inc-text-body">All Widgets</VerticallyCenteredRow>

          <VerticallyCenteredRow className="flex-gap-24 inc-flex-row-wrap">{allWidgetOptions}</VerticallyCenteredRow>
        </div>
      </div>
    </IncModal>
  );
});

const vizCardStyle: CSSProperties = {
  display: "flex",
  width: 90,
  padding: 12,
  flexDirection: "column",
  alignItems: "center",
  gap: 8,
  borderRadius: 8,
  border: "1px solid #4A505C"
};

const vizIconStyle: CSSProperties = {
  padding: 10,
  background: "#3B444D",
  borderRadius: 8,
  width: 42,
  height: 42
};

type IconInfo = {
  iconName: IncFaIconName;
  tooltip: string;
  style?: CSSProperties;
};

const iconInfoMap: Record<Visualisations, IconInfo> = {
  [Visualisations.barChart]: {
    iconName: "chart-column",
    tooltip: "Bar chart"
  },
  [Visualisations.donut]: {
    iconName: "circle-notch",
    tooltip: "Donut"
  },
  [Visualisations.gauge]: {
    iconName: "circle-notch",
    tooltip: "Gauge",
    style: {
      clipPath: "polygon(0 0, 100% 0%, 100% 50%, 0 50%)",
      transform: "translateY(4px)"
    }
  },
  [Visualisations.geoMap]: {
    iconName: "map-location-dot",
    tooltip: "Geo map"
  },
  [Visualisations.pieChart]: {
    iconName: "chart-pie",
    tooltip: "Pie chart"
  },
  [Visualisations.timeseries]: {
    iconName: "chart-line",
    tooltip: "Line Chart"
  },
  [Visualisations.treeMap]: {
    iconName: "chart-tree-map",
    tooltip: "Tree map",
    style: {
      height: 16,
      marginLeft: -4,
      marginRight: -2,
      transform: "scale(1.2)"
    }
  },
  [Visualisations.singleStat]: {
    iconName: "hashtag",
    tooltip: "Single Stat",
    style: {
      width: 16,
      alignItems: "center"
    }
  },
  [Visualisations.heatMap]: {
    iconName: "diagram-cells",
    tooltip: "Heat map"
  },
  [Visualisations.stackedBarChart]: {
    iconName: "bars-progress",
    tooltip: "Stacked bar chart"
  },
  [Visualisations.insights]: {
    iconName: "table-list",
    tooltip: "Insights"
  },
  [Visualisations.histogram]: {
    iconName: "square-poll-vertical",
    tooltip: "Histogram"
  },
  [Visualisations.sparkLine]: {
    iconName: "hashtag",
    tooltip: "Stat"
  },
  [Visualisations.table]: {
    iconName: "table",
    tooltip: "Table"
  }
};

const popularViz = [Visualisations.table, Visualisations.timeseries, Visualisations.barChart, Visualisations.sparkLine];

const allViz = [
  Visualisations.barChart,
  Visualisations.donut,
  Visualisations.gauge,
  Visualisations.timeseries,
  Visualisations.treeMap,
  Visualisations.sparkLine,
  Visualisations.table
];

if (featureFlagService.isDebugMode()) {
  allViz.push(Visualisations.geoMap);
  allViz.push(Visualisations.histogram);
}

allViz.sort((a, b) => {
  const nameA = iconInfoMap[a].tooltip;
  const nameB = iconInfoMap[b].tooltip;

  return nameA.toLowerCase().localeCompare(nameB.toLowerCase());
});
