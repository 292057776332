import React, { FC, useEffect, useState, useMemo, useCallback, useRef } from "react";
import {
  TableDataColumn,
  IncToolTip,
  IncFaIcon,
  IncButton,
  IncSelectOption,
  IncSelect,
  IncRTableProps
} from "@inception/ui";
import { ImpactedWidgetsDataEntry, useFetchImpactedWidgetsData, getGroupKey } from "../impact-widget/hooks";
import { getMetricIdForImpactWidget } from "../utils";
import { getFormattedValueForImpactedWidget } from "../impact-widget/utils";
import { MISSING, downloadBlobFile } from "../../../utils";
import entityStoreApiService from "../../../services/api/EntityStoreApiService";
import { EntitySearchResultEntry, PropValue } from "../../../services/api/types";
import { Property, useNotifications, useTimeRange, TimeRange } from "../../../core";
import { logger } from "../../../core/logging/Logger";
import { getDtoFromWidgetConfig } from "../../../utils/ExploreUtils";
import {
  DownloadOptions,
  SliceSpec,
  CohortEntityFilter,
  exploreApiService,
  getDisplayTagNameForUSFieldSlice,
  UserServiceFilterList,
  ImpactedWidget,
  IncidentSliceContribution,
  ExploreEntityFilter,
  WidgetQuerySchema,
  WidgetConfigUtils
} from "../../../services/api/explore";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { EntityDetails } from "../../entity-properties-popover";
import { BizEntityApiResult } from "../../../services/api/explore/BizServiceCommon";
import { EntityListData, EntitiesPanelProps, EntitiesPanel } from "../../entity-list";
import { VerticallyCenteredRow } from "../../flex-components";
import LoadingSpinner from "../../Loading/Loading";

interface Props {
  show: boolean;
  closeList: () => void;

  eventFieldFilters?: UserServiceFilterList;
  entityFilters?: ExploreEntityFilter[];

  entityTypeId: string;
  incidentId: string;
  opConfigId: string;

  timeRange?: TimeRange;
  lookupData?: Record<string, string>;

  impactedWidget: ImpactedWidget;
  defSliceContributors: IncidentSliceContribution;
  sliceContributorsArr?: IncidentSliceContribution[];

  generateDemoData?: boolean;
}

export const SliceIndicatorDrawer: FC<Props> = props => {
  const {
    impactedWidget,
    defSliceContributors,
    eventFieldFilters,
    entityFilters,
    show,
    closeList,
    entityTypeId,
    incidentId,
    opConfigId,
    sliceContributorsArr,
    lookupData: pLookupData,
    timeRange: pTimeRange,
    generateDemoData
  } = props;

  const { name, bizDataQuery } = impactedWidget;

  const { id: widgetId, widgetConfig, sliceSpec: bSliceSpec } = bizDataQuery;

  const [querySchema, setQuerySchema] = useState<WidgetQuerySchema[]>([]);
  const [qsLoading, setQsLoading] = useState(false);

  const fetchQuerySchema = useCallback(async () => {
    setQsLoading(true);
    let response: BizEntityApiResult<WidgetQuerySchema[]>;
    if (widgetConfig) {
      const widgetConfigDto = getDtoFromWidgetConfig(widgetConfig);
      response = await exploreApiService.getQuerySchemaForDraftWidgetConfig(widgetConfigDto);
    } else if (widgetId) {
      const { data, ...rest } = await exploreApiService.getWidgetConfig(null, entityTypeId, widgetId);
      const querySchema = data?.querySchema?.querySchema || [];

      response = {
        data: querySchema,
        ...rest
      };
    }

    if (response) {
      const { data, error, message } = response;

      if (error || !data) {
        logger.error("SliceIndicatorDrawer", "Error fetching query schema", {
          data,
          message
        });
      } else {
        setQuerySchema(data || []);
      }
    }

    setQsLoading(false);
  }, [entityTypeId, widgetConfig, widgetId]);

  useEffect(() => {
    fetchQuerySchema();
  }, [fetchQuerySchema]);

  const { timeRange: gTimeRange } = useTimeRange();
  const { notifyError } = useNotifications();

  const dSlContr = defSliceContributors || sliceContributorsArr?.[0];
  const [sliceContributors, setSliceContributors] = useState<IncidentSliceContribution>(dSlContr);
  const sliceOptions = useMemo<Option[]>(
    () =>
      (sliceContributorsArr || []).map(slContr => ({
        label: getDisplayTagNameForUSFieldSlice(slContr.slice).displayTagName,
        value: slContr.slice.tagName,
        data: slContr
      })),
    [sliceContributorsArr]
  );

  const { fromMillis, toMillis } = useMemo(
    () => timeRangeUtils.getMillisFromTimeRange(pTimeRange || gTimeRange),
    [gTimeRange, pTimeRange]
  );

  const { slice, values } = sliceContributors;

  const { displayTagName, isEntityTag, tagName } = getDisplayTagNameForUSFieldSlice(slice);

  const entityDetailsDataRef = useRef<Record<string, EntityDetails>>();
  const dataRef = useRef<ImpactedWidgetsDataEntry>();

  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const { impactedWidgets, groupBy, userServiceFilters } = useMemo(() => {
    const impactedWidgets = [impactedWidget];
    const groupBy = [tagName];
    const metricId = getMetricIdForImpactWidget(impactedWidget);

    dataRef.current = null;
    entityDetailsDataRef.current = null;

    return {
      impactedWidgets,
      groupBy,
      userServiceFilters: eventFieldFilters
        ? {
            [metricId]: eventFieldFilters
          }
        : {}
    };
  }, [eventFieldFilters, impactedWidget, tagName]);

  const { refetch, resultsByImpactedWidget } = useFetchImpactedWidgetsData(
    impactedWidgets,
    incidentId,
    opConfigId,
    groupBy,
    entityFilters,
    userServiceFilters,
    fromMillis,
    toMillis,
    groupBy,
    null,
    null,
    null,
    null,
    null,
    generateDemoData
  );

  useEffect(() => {
    if (show && !dataRef.current?.data) {
      refetch();
    }
  }, [refetch, show, groupBy]);

  const impactWidgetDataEntry = useMemo(() => {
    if (dataRef.current?.data) {
      return dataRef.current;
    }

    const groupKey = getGroupKey(groupBy);
    const impactedWidgetId = impactedWidget?.id;

    const dataEntry = resultsByImpactedWidget?.[impactedWidgetId]?.[groupKey];
    dataRef.current = !dataEntry?.isFetching ? dataEntry : dataRef.current;

    return dataEntry;
  }, [groupBy, impactedWidget, resultsByImpactedWidget]);

  const lookupData = useMemo(() => {
    if (pLookupData) {
      return pLookupData;
    }

    let lookupData: Record<string, string> = {};

    if (!impactWidgetDataEntry) {
      return lookupData;
    }

    const { data } = impactWidgetDataEntry;

    if (!data) {
      return lookupData;
    }

    const { postAggEntityLookupData } = data;
    lookupData = {
      ...(postAggEntityLookupData || {})
    };

    return lookupData;
  }, [impactWidgetDataEntry, pLookupData]);

  const addlColumns = useMemo<TableDataColumn[]>(() => {
    if (!impactedWidget) {
      return [];
    }

    const { name } = impactedWidget;
    const accessor = "entityId";

    return [
      {
        accessor: `_${accessor}`,
        header: name,
        align: "center",
        sortable: true,
        sortFn: (itemA, itemB) => {
          const entityIdA = itemA[accessor];
          const entityIdB = itemB[accessor];

          const { value: dataValueA = 0 } = getData(impactWidgetDataEntry, tagName, entityIdA);

          const { value: dataValueB = 0 } = getData(impactWidgetDataEntry, tagName, entityIdB);

          return dataValueA < dataValueB ? "lesser" : dataValueA > dataValueB ? "greater" : "equal";
        },
        renderer: (_value, rowData) => {
          const value = rowData[accessor];

          const { value: dataValue, error, isError, isFetching } = getData(impactWidgetDataEntry, tagName, value);

          if (isFetching) {
            return <LoadingSpinner titleText=" " />;
          }

          if (isError) {
            return (
              <IncToolTip titleText={String(error)}>
                <VerticallyCenteredRow>
                  <IncFaIcon
                    className="status-danger"
                    iconName="warning"
                  />
                </VerticallyCenteredRow>
              </IncToolTip>
            );
          }

          return getFormattedValueForImpactedWidget(impactedWidget, dataValue);
        },
        width: 150
      }
    ];
  }, [impactWidgetDataEntry, impactedWidget, tagName]);

  const initialData = useMemo<EntityListData>(
    () => ({
      entityIds: values.map(v => v.value),
      entityLookupData: lookupData || {}
    }),
    [lookupData, values]
  );

  const onSearch = useCallback<EntitiesPanelProps["onSearch"]>(
    async (searchStr: string) => {
      const fKeys = initialData.entityIds.filter(k => {
        const fValue = lookupData?.[k] || k;
        return fValue.toLowerCase().includes(searchStr.toLowerCase());
      });

      let entityDetails = entityDetailsDataRef.current;

      if (!entityDetails) {
        entityDetails = {};

        const entityIds = initialData.entityIds.filter(id => id !== MISSING);

        try {
          const entityDetailsPromise = isEntityTag
            ? entityStoreApiService.searchEntities(
                displayTagName,
                fromMillis,
                toMillis,
                null,
                null,
                null,
                ["*"],
                499,
                entityIds
              )
            : Promise.resolve([] as EntitySearchResultEntry[]);

          const entityDetailsOptions = await entityDetailsPromise;
          (entityDetailsOptions || []).forEach(({ entity }) => {
            const { entityId } = entity;
            entityDetails[entityId] = entity;

            const dataTypes: string[] = ["stringVal", "longVal", "doubleVal", "dateVal", "setValue", "booleanVal"];
            const properties: Property[] = [];

            if (entity.props) {
              for (const [propName, propValueWrapper] of Object.entries(entity.props)) {
                let propertyValue = null;

                // We don't know in the response what is the data type of the field, so iterate over the
                // datatypes array to see if there is a value

                for (let i = 0; i < dataTypes.length; ++i) {
                  const key: keyof PropValue = dataTypes[i] as unknown as keyof PropValue;

                  if (propValueWrapper[key]) {
                    if (key === "setValue") {
                      const setValues: any[] = (propValueWrapper[key] as any).values as any[];
                      propertyValue = setValues.join(",");
                    } else {
                      propertyValue = propValueWrapper[key];
                    }
                    break;
                  }
                }

                properties.push({
                  name: propName,
                  value: propertyValue as string
                });
              }
            }

            (entity as any).properties = properties;
          });

          entityDetailsDataRef.current = entityDetails;
        } catch (error) {
          logger.error("Key Indicators", "Error fetching entity details", error);
        }
      }

      return {
        entityIds: fKeys,
        entityLookupData: lookupData,
        entityDetailsData: entityDetails
      };
    },
    [displayTagName, fromMillis, initialData.entityIds, isEntityTag, lookupData, toMillis]
  );

  const downloadCsv = useCallback(async () => {
    const widgetConfigDto = widgetConfig ? getDtoFromWidgetConfig(widgetConfig) : null;

    const downloadOptions: DownloadOptions = {
      fileName: `${name} by ${displayTagName}.xlsx`,
      metricToHeaderName: {
        [bSliceSpec.metricId]: name
      },
      tagToHeaderName: {
        [tagName]: displayTagName
      }
    };

    setDownloadInProgress(true);

    const { defaultTagAgg = "sum", defaultTimeAgg = "sum" } = (querySchema || []).find(qs => {
      const { metricId, sliceSet } = qs;
      return bSliceSpec.metricId === metricId && WidgetConfigUtils.compareSliceSets(bSliceSpec.sliceSet, sliceSet);
    });

    const sliceSpec: SliceSpec[] = [
      {
        ...bSliceSpec,
        selectorSpec: {
          filters: [
            {
              tags: [
                {
                  key: tagName,
                  value: values.map(({ value }) => value)
                }
              ]
            }
          ]
        },
        postAgg: {
          overTagAgg: {
            tagName: [tagName],
            aggregator: defaultTagAgg
          },
          overTimeAgg: {
            aggregator: defaultTimeAgg,
            timeInSeconds: timeRangeUtils.getSecondsFromMillis(toMillis - fromMillis)
          },
          isSingleStatQuery: true,
          projections: ["current"]
        }
      }
    ];

    const cohortFilters: CohortEntityFilter[] = [];
    entityFilters?.forEach(({ filters }) => {
      filters.forEach(predicate =>
        cohortFilters.push({
          fieldType: "userServiceField",
          predicate
        })
      );
    });

    const usFilters = eventFieldFilters
      ? {
          [bSliceSpec.metricId]: eventFieldFilters
        }
      : {};

    const intervalSecs = timeRangeUtils.getSecondsFromMillis(toMillis - fromMillis);

    const { data, error, message } = await exploreApiService.downloadWidgetDataByConfig(
      entityTypeId,
      null,
      widgetId,
      widgetConfigDto,
      fromMillis,
      toMillis,
      intervalSecs,
      sliceSpec,
      cohortFilters,
      downloadOptions,
      true,
      -1,
      usFilters
    );
    setDownloadInProgress(false);

    if (!error) {
      downloadBlobFile(data, downloadOptions.fileName);
    } else {
      notifyError("Error while downloading file");
      logger.error("Events Table", "Error while downloading file", message);
    }
  }, [
    bSliceSpec,
    displayTagName,
    entityFilters,
    entityTypeId,
    eventFieldFilters,
    fromMillis,
    name,
    notifyError,
    querySchema,
    tagName,
    toMillis,
    values,
    widgetConfig,
    widgetId
  ]);

  const selSliceOpt = useMemo<Option>(
    () => ({
      label: getDisplayTagNameForUSFieldSlice(slice).displayTagName,
      value: slice.tagName,
      data: sliceContributors
    }),
    [slice, sliceContributors]
  );

  const onSliceChange = useCallback((opt: Option) => {
    setSliceContributors(opt.data);
  }, []);

  const sortBy = useMemo<IncRTableProps["sort"]>(
    () => ({
      accessor: "_entityId",
      order: "desc"
    }),
    []
  );

  return (
    <EntitiesPanel
      addlColumns={addlColumns}
      bizEntityType={displayTagName}
      disableDownload
      onClose={closeList}
      onSearch={onSearch}
      renderAsNonEntity={!isEntityTag}
      searchable
      show={show}
      showDefaultColumnsOnly
      showEntityInfoOnHover={isEntityTag}
      skipIdColumn
      sortBy={sortBy}
    >
      {Boolean(impactedWidget) && (
        <VerticallyCenteredRow className="marginBt16">
          {Boolean(sliceOptions.length) && (
            <IncSelect
              label="Field"
              onChange={onSliceChange}
              options={sliceOptions}
              value={selSliceOpt}
              wrapperClass="width-30"
            />
          )}
          <IncButton
            className="marginLtAuto marginBt8 marginTpAuto"
            color="link"
            disabled={downloadInProgress || qsLoading}
            iconType="iconText"
            onClick={downloadCsv}
          >
            {!qsLoading && (
              <>
                {!downloadInProgress && (
                  <IncFaIcon
                    className="marginRt6"
                    iconName="download"
                  />
                )}
                {downloadInProgress && (
                  <IncFaIcon
                    className="marginRt6"
                    iconName="spinner"
                    spin
                  />
                )}
                Download CSV
              </>
            )}
            {qsLoading && (
              <>
                <LoadingSpinner titleText="Fetching info..." />
              </>
            )}
          </IncButton>
        </VerticallyCenteredRow>
      )}
    </EntitiesPanel>
  );
};

type Option = IncSelectOption<IncidentSliceContribution>;

const getData = (resultEntryRec: ImpactedWidgetsDataEntry, tagName: string, tagValue: string) => {
  const { data, error, isError, isFetching } = resultEntryRec || {
    data: null,
    error: null,
    isError: false,
    isFetching: true
  };

  const dataFrames = Object.values(data?.postAggData || {})[0]?.data || [];
  const selDataFrame = dataFrames.find(df => {
    const { labels } = df;
    return labels[tagName] === tagValue;
  });

  const numData = selDataFrame?.fields?.[1]?.data?.[0] as number;

  return {
    value: numData,
    error,
    isError,
    isFetching
  };
};
