import React, { FC, useEffect, useCallback, useState, useMemo, memo, useRef } from "react";
import { IncFaIcon, IncToggle } from "@inception/ui";
import { cx, css } from "emotion";
import { isEmpty } from "lodash";
import {
  ImpactedWidget,
  BizDataQuery,
  UserServiceFieldSliceSet,
  exploreApiService,
  WidgetConfigUtils
} from "../../../services/api/explore";
import { VerticallyCenteredRow } from "../../flex-components";
import EditableLabelInput from "../../editable-input-label/EditableInputLabel";
import { useToggleState, FieldSubType, FieldPrimType } from "../../../core";
import { DerivedNodeEditor } from "../../../biz-flow/nodes-and-edges/DerivedNodeEditor";
import { UIBizFlowNode } from "../../../biz-flow/types";
import { DataTypeOption, MappingType, getAllDataTypeOptions } from "../../data-type/utils";
import { IncDataTypeSelect, PrimKindMap } from "../../data-type";
import { getWidgetConfigFromDto } from "../../../utils/ExploreUtils";
import LoadingSpinner from "../../Loading/Loading";

interface Props {
  impactedWidget: ImpactedWidget;
  onRemove: () => void;
  onChange: (impactedWidget: ImpactedWidget) => void;
  onClone?: () => void;

  disableSliceSelection?: boolean;
  disablePrimaryToggle?: boolean;
  disableEntityTypeChange?: boolean;

  defaultSliceSet?: UserServiceFieldSliceSet;
  extConfigureJsx?: JSX.Element | JSX.Element[];
}

export const ImpactWidgetEditorRow: FC<Props> = memo(props => {
  const {
    impactedWidget,
    onChange,
    onRemove,
    onClone,
    disableSliceSelection = false,
    disablePrimaryToggle = false,
    disableEntityTypeChange = false,
    extConfigureJsx = <></>,
    defaultSliceSet
  } = props;

  const impactedWidgetRef = useRef(impactedWidget);
  useMemo(() => {
    impactedWidgetRef.current = impactedWidget;
  }, [impactedWidget]);

  const { open: openQueryEdit, close: closeQueryEdit, isOpen: isQueryEdit } = useToggleState();

  const { open: openConfigureEdit, close: closeConfigureEdit, isOpen: isConfigureEdit } = useToggleState();

  const allDataTypeOptions = useMemo(() => getAllDataTypeOptions(MappingType.event), []);

  const { name, isPrimary, bizDataQuery: pBizDataQuery, dataType = "_str", subType = "none" } = impactedWidget;

  const [bizDataQuery, setBizDataQuery] = useState<BizDataQuery>(pBizDataQuery);
  const [isWidgetConfigFetching, setIsWidgetConfigFetching] = useState<boolean>(false);

  const [node, setNode] = useState<UIBizFlowNode>(getNode(bizDataQuery));
  const [error, setError] = useState<string>();

  const [dataTypeOption, setDataTypeOption] = useState(
    getDataTypeSelectionOption(dataType, subType, allDataTypeOptions)
  );
  const [isSpikePositive, setIsSpikePositive] = useState(bizDataQuery?.isSpikePositive || false);

  const fetchWidgetConfigOnEdit = useCallback(async () => {
    const shouldFetchWidgetConfig = isQueryEdit && bizDataQuery && !bizDataQuery.widgetConfig && bizDataQuery.id;
    if (shouldFetchWidgetConfig) {
      const { entityTypeId, eventTypeId } = WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(
        bizDataQuery.idProps
      );
      setIsWidgetConfigFetching(true);
      const { data, error } = await exploreApiService.getWidgetConfig(
        eventTypeId || bizDataQuery.labels?.eventTypeId,
        entityTypeId || bizDataQuery.labels?.entityTypeId,
        bizDataQuery.id
      );
      if (!error) {
        setBizDataQuery(prev => ({
          ...prev,
          widgetConfig: getWidgetConfigFromDto(data.widgetConfig)
        }));
      }
      setIsWidgetConfigFetching(false);
    }
  }, [isQueryEdit, bizDataQuery]);

  useEffect(() => {
    fetchWidgetConfigOnEdit();
  }, [fetchWidgetConfigOnEdit]);

  useEffect(() => {
    setNode(getNode(bizDataQuery));
  }, [bizDataQuery]);

  const onPropertyChange = useCallback(
    (property: keyof ImpactedWidget, value: any) => {
      const impactedWidget = impactedWidgetRef.current;
      const nImpactedWidget = {
        ...impactedWidget,
        [property]: value
      };

      const shouldUpdateBizDataQuery = property === "name" || property === "bizDataQuery";
      if (shouldUpdateBizDataQuery && nImpactedWidget?.bizDataQuery?.widgetConfig) {
        nImpactedWidget.bizDataQuery.widgetConfig.name = nImpactedWidget.name;
      }

      onChange(nImpactedWidget);
    },
    [onChange]
  );

  const onNameChange = useCallback(
    (value: string) => {
      onPropertyChange("name", value);
    },
    [onPropertyChange]
  );

  const onIsPrimaryChange = useCallback(
    (value: boolean) => {
      onPropertyChange("isPrimary", value);
    },
    [onPropertyChange]
  );

  const saveBizDataQuery = useCallback(() => {
    onPropertyChange("bizDataQuery", node.bizDataQuery);
    closeQueryEdit();
  }, [closeQueryEdit, node, onPropertyChange]);

  const saveConfiguration = useCallback(() => {
    const impactedWidget = impactedWidgetRef.current;

    const { value = "_str", kindDescriptor } = dataTypeOption || {};

    onChange({
      ...impactedWidget,
      bizDataQuery: {
        ...impactedWidget.bizDataQuery,
        isSpikePositive
      },
      dataType: value as FieldPrimType,
      subType: kindDescriptor?.type || "none"
    });
    closeConfigureEdit();
  }, [closeConfigureEdit, dataTypeOption, isSpikePositive, onChange]);

  const queryError = useMemo(() => {
    const metrics = node?.bizDataQuery?.widgetConfig?.dataDefinition?.metrics || {};

    if (isEmpty(metrics)) {
      return "Invalid metric";
    }

    return error;
  }, [error, node]);

  const canSave = !queryError;

  const bizQueryClassName = useMemo(
    () =>
      cx("inc-cursor-pointer status-info", {
        disableClick: !canSave,
        "status-danger": !canSave
      }),
    [canSave]
  );

  const removeClassName = cx("inc-cursor-pointer status-danger marginLt10", {
    disableClick: isPrimary
  });

  const removeTitle = isPrimary ? "Primary widget cannot be removed" : "Remove";

  return (
    <div className={className}>
      <VerticallyCenteredRow className="width-100">
        <EditableLabelInput
          change={onNameChange}
          editingDisabled={false}
          initialValue={name}
          save={onNameChange}
        />

        <VerticallyCenteredRow className="marginLtAuto">
          {!isQueryEdit && !isConfigureEdit && (
            <>
              {!disablePrimaryToggle && (
                <IncToggle
                  checked={isPrimary}
                  label="Primary"
                  onChange={onIsPrimaryChange}
                />
              )}

              {Boolean(onClone) && (
                <IncFaIcon
                  className="inc-cursor-pointer marginLt10"
                  iconName="clone"
                  onClick={onClone}
                  title="Clone"
                />
              )}

              <IncFaIcon
                className="inc-cursor-pointer status-info marginLt10"
                iconName="edit"
                onClick={openQueryEdit}
                title="Edit"
              />

              <IncFaIcon
                className="inc-cursor-pointer status-info marginLt10"
                iconName="gear"
                onClick={openConfigureEdit}
                title="Configure"
              />

              <span
                className="inc-flex-row"
                title={removeTitle}
              >
                <IncFaIcon
                  className={removeClassName}
                  iconName="minus-circle"
                  onClick={onRemove}
                />
              </span>
            </>
          )}

          {isConfigureEdit && (
            <>
              <IncFaIcon
                className="inc-cursor-pointer"
                iconName="save"
                onClick={saveConfiguration}
                title="Save"
              />
              <IncFaIcon
                className="inc-cursor-pointer marginLt10"
                iconName="close"
                onClick={closeConfigureEdit}
                title="Cancel"
              />
            </>
          )}

          {isQueryEdit && (
            <>
              <VerticallyCenteredRow title={canSave ? "Save" : queryError}>
                <IncFaIcon
                  className={bizQueryClassName}
                  iconName="save"
                  onClick={saveBizDataQuery}
                />
              </VerticallyCenteredRow>

              <IncFaIcon
                className="inc-cursor-pointer marginLt10 status-warning"
                iconName="close"
                onClick={closeQueryEdit}
                style={{ fontSize: 18 }}
                title="Cancel"
              />
            </>
          )}
        </VerticallyCenteredRow>
      </VerticallyCenteredRow>

      {isQueryEdit && (
        <div className="width-100 marginTp12">
          {isWidgetConfigFetching ? (
            <LoadingSpinner titleText="Fetching configuration..." />
          ) : (
            <DerivedNodeEditor
              defaultSliceSet={defaultSliceSet}
              disableSliceSelection={disableSliceSelection}
              hideEntityTypeSelector={disableEntityTypeChange}
              node={node}
              setError={setError}
              setNode={setNode}
            />
          )}
        </div>
      )}

      {isConfigureEdit && (
        <div className="width-100 inc-flex-column flex-gap-8 marginTp12">
          <IncDataTypeSelect
            label=""
            onSelect={setDataTypeOption}
            selected={dataTypeOption}
          />

          <IncToggle
            checked={isSpikePositive}
            label="Treat spike as good"
            onChange={setIsSpikePositive}
          />

          {extConfigureJsx}
        </div>
      )}
    </div>
  );
});

const cssClassName = css`
  background: #2a343e !important;

  .ui-biz-flow--derived-node-editor {
    background: transparent;
  }
`;
const className = cx("inc-card-layout display-block width-100 marginBt12", cssClassName);

const getNode = (bizDataQuery: BizDataQuery): UIBizFlowNode => ({
  id: "",
  label: "",
  position: {
    x: 0,
    y: 0
  },
  renderType: "single",
  type: "derived",
  bizDataQuery
});

const getDataTypeSelectionOption = (
  dataType: FieldPrimType,
  subType: FieldSubType,
  allDataTypeOptions: DataTypeOption[]
): DataTypeOption => {
  if (dataType) {
    // Doing this to support STRING and _str
    dataType = dataType.startsWith("_") ? dataType : PrimKindMap[dataType]?.kind || dataType;

    //if fieldDef has subtype, then DataTypeSelector should show corresponding value/label
    const selectedDataType = allDataTypeOptions.find(o => {
      if (subType && subType !== "none") {
        return o.value === dataType && o?.kindDescriptor?.type === subType;
      }
      return o.value === dataType;
    });
    return selectedDataType ?? allDataTypeOptions[0];
  }

  return null;
};
