import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IncButton, IncFaIcon, IncRadioButton, IncSmartText } from "@inception/ui";
import { cloneDeep } from "lodash";
import { OpFragmentEditProps } from "../types";
import { VerticallyCenteredRow } from "../../components";
import { useToggleState } from "../../core";
import { HistoryToConsider, OpCreationFragmentEditType } from "../../services/api/operationalise";
import { RollingFrequencyEditorV2 } from "../../operationalise-v2/v3/editors/common";
import { TimeObj, TimeObjUnit } from "../../services/api/explore";
import { getLabelForTimeObj } from "../../utils/DurationUtils";

type Props = OpFragmentEditProps;

export const HistoryToConsiderFragmentEditor: FC<Props> = props => {
  const { opFragment, invalidFragmentJsx } = props;

  if (opFragment?.historyToConsider) {
    return <FragmentEditorInternal {...props} />;
  }

  return invalidFragmentJsx;
};

const FragmentEditorInternal: FC<Props> = props => {
  const { className, opFragment, onChange, onClose } = props;

  const { isOpen: isUpdateInProgress, open: startUpdate, close: finishUpdate } = useToggleState();

  const errorMessageRef = useRef("");

  const { automatic: pAutomatic, historyToConsider: pHistoryToConsider } = opFragment.historyToConsider;

  const defAutomatic = useMemo<boolean>(() => pAutomatic || !pHistoryToConsider, [pAutomatic, pHistoryToConsider]);
  const defHistoryToConsider = useMemo<TimeObj>(
    () =>
      pHistoryToConsider
        ? cloneDeep(pHistoryToConsider)
        : {
            unit: TimeObjUnit.weeks,
            value: 12
          },
    [pHistoryToConsider]
  );

  const [automatic, setAutomatic] = useState(defAutomatic);
  const [historyToConsider, setHistoryToConsider] = useState(defHistoryToConsider);

  const resetHistoryToConsider = useCallback(() => {
    setAutomatic(defAutomatic);
    setHistoryToConsider(defHistoryToConsider);
  }, [defAutomatic, defHistoryToConsider]);

  useEffect(() => {
    resetHistoryToConsider();
  }, [resetHistoryToConsider]);

  const onCancelChanges = useCallback(() => {
    resetHistoryToConsider();
    onClose?.();
  }, [onClose, resetHistoryToConsider]);

  const onSaveChanges = useCallback(async () => {
    errorMessageRef.current = "";
    startUpdate();

    const historyToConsiderObj: HistoryToConsider = {};
    if (automatic) {
      historyToConsiderObj.automatic = true;
    } else {
      historyToConsiderObj.historyToConsider = historyToConsider;
    }

    const { isError, message } = await onChange(
      {
        ...opFragment,
        historyToConsider: historyToConsiderObj
      },
      OpCreationFragmentEditType.HISTORY_TO_CONSIDER
    );

    if (isError) {
      errorMessageRef.current = message;
    }

    finishUpdate();
  }, [automatic, finishUpdate, historyToConsider, onChange, opFragment, startUpdate]);

  return (
    <div className={className}>
      <VerticallyCenteredRow className="flex-gap-4">
        <IncRadioButton
          checked={automatic}
          onChange={(e, checked) => setAutomatic(checked)}
        />

        <VerticallyCenteredRow className="inc-label-common">
          Automatically compute a time period to determine a trend
        </VerticallyCenteredRow>
      </VerticallyCenteredRow>

      <VerticallyCenteredRow className="flex-gap-4">
        <IncRadioButton
          checked={!automatic}
          onChange={(e, checked) => setAutomatic(!checked)}
        />

        <VerticallyCenteredRow className="inc-label-common">Consider data over a period of</VerticallyCenteredRow>

        {!automatic && (
          <RollingFrequencyEditorV2
            onChange={setHistoryToConsider}
            rollingFreq={historyToConsider}
            skipPrefix
          />
        )}

        <VerticallyCenteredRow className="inc-label-common">
          {automatic && `${getLabelForTimeObj(historyToConsider, "md", true)} `}
          to determine a trend
        </VerticallyCenteredRow>
      </VerticallyCenteredRow>

      <VerticallyCenteredRow className="flex-gap-12 marginTp10">
        {!isUpdateInProgress && (
          <IncButton
            color="primary"
            label="Save Changes"
            onClick={onSaveChanges}
            size="small"
          />
        )}

        {isUpdateInProgress && (
          <IncButton
            color="primary"
            loading
            loadingText="Saving..."
            size="small"
          />
        )}

        {Boolean(errorMessageRef.current) && (
          <VerticallyCenteredRow className="status-danger flex-gap-10">
            <IncFaIcon
              className="status-danger"
              iconName="exclamation-triangle"
            />
            <IncSmartText
              className="status-danger"
              text={errorMessageRef.current}
            />
          </VerticallyCenteredRow>
        )}

        {!isUpdateInProgress && (
          <IncButton
            color="secondary-blue"
            label="Cancel"
            onClick={onCancelChanges}
            size="small"
          />
        )}
      </VerticallyCenteredRow>
    </div>
  );
};
