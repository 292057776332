import React, { FC, useMemo, useEffect } from "react";
import {
  IncidentSliceContribution,
  CompareQuerySchemaResponse,
  ImpactedWidgetList,
  UserServiceFilterList,
  ImpactWidgetDataRequest,
  TriagePageConfigPayload,
  ImpactedWidget
} from "../../../services/api/explore";
import { TimeRange } from "../../../core";
import { OpSchema } from "../../../services/api/operationalise";
import { FieldPickerUtils } from "../../../utils";
import { KeyIndicator } from "./KeyIndicator";
import { ImpactedWidgetIndicator } from "./ImpactedWidgetIndicator";
import { CommonIndicatorProps } from "./SliceIndicator";

interface Props extends Omit<CommonIndicatorProps, "primaryImpactedWidget"> {
  keyContributors: IncidentSliceContribution[];
  compareSchema: CompareQuerySchemaResponse;
  opSchema: OpSchema;

  impactedWidgetsLists: ImpactedWidgetList[];

  entityFilters?: ImpactWidgetDataRequest["entityFilters"];
  eventFieldFilters?: UserServiceFilterList;
  timeRange?: TimeRange;

  preset?: TriagePageConfigPayload["keyIndicators"];

  showAll?: boolean;
  setNumExtraItems?: (numHiddenItems: number) => void;

  hideDrawers?: boolean;
}

export const KeyIndicatorsWithImpactedWidgets: FC<Props> = props => {
  const {
    compareSchema,
    impactedWidgetsLists,
    incidentId,
    keyContributors,
    opConfigId,
    cohortId,
    entityFilters,
    lookupData,
    preset,
    eventFieldFilters,
    timeRange,
    showAll,
    setNumExtraItems,
    entityTypeId,
    hideDrawers = false,
    opSchema,
    generateDemoData
  } = props;

  const filtersExist = useMemo(() => {
    let filtersExist = false;
    filtersExist = filtersExist || entityFilters?.[0]?.filters?.length > 0;
    filtersExist =
      filtersExist ||
      (eventFieldFilters?.userServiceFilters || []).reduce(
        (acc, filters) => acc || Boolean(filters.userServiceFilterExpressions.length),
        false as boolean
      );

    return filtersExist;
  }, [entityFilters, eventFieldFilters]);

  const { impactedWidgets: pinnedImpactedWidgets, sliceTagNames: pinnedSliceTagNames } = preset || {};

  const alertingSlices = useMemo(() => opSchema?.alertingEventFields?.slices || [], [opSchema]);

  const { impactedWidgets, selectedFieldsById } = useMemo(() => {
    const impactedWidgetsById: Record<string, ImpactedWidget> = {};
    const selectedFieldsById: Record<string, string[]> = {};

    impactedWidgetsLists.forEach(list => {
      const { diagnosticSlices, impactedWidgets } = list;

      const selectedFieldNames: string[] = keyContributors.map(({ slice }) => {
        const usField = slice.userServiceField;
        return FieldPickerUtils.generateNamefromUSF(usField);
      });

      // alertingSlices?.forEach(tagName => {
      //   const usField = keyContributors.find(({ slice }) => slice.tagName === tagName)?.slice?.userServiceField;
      //   if (usField) {
      //     const formattedKey = FieldPickerUtils.generateNamefromUSF(usField);
      //     selectedFieldNames.push(formattedKey);
      //   }
      // });

      diagnosticSlices?.forEach(({ userServiceField }) => {
        const formattedKey = FieldPickerUtils.generateNamefromUSF(userServiceField);
        selectedFieldNames.push(formattedKey);
      });

      impactedWidgets.forEach(iw => {
        impactedWidgetsById[iw.id] = iw;
        selectedFieldsById[iw.id] = selectedFieldNames;
      });
    });

    const impactedWidgets = Object.values(impactedWidgetsById);

    const primaryWidgetExists = impactedWidgets.some(iw => iw.isPrimary);
    if (!primaryWidgetExists && impactedWidgets.length) {
      impactedWidgets[0].isPrimary = true;
    }

    return {
      impactedWidgets,
      selectedFieldsById
    };
  }, [impactedWidgetsLists, keyContributors]);

  const { indicatorsJsx, numExtraItems } = useMemo(() => {
    let primaryImpactedWidget: ImpactedWidget;

    const pinnedIndicatorsJsx: JSX.Element[] = [];

    const pinnedImpactedWidgetsSet = new Set(pinnedImpactedWidgets || []);
    impactedWidgets.forEach(iw => iw.isPrimary && pinnedImpactedWidgetsSet.add(iw.id));

    const pinnedImpactedWidgetsIds = Array.from(pinnedImpactedWidgetsSet);

    pinnedImpactedWidgetsIds.forEach((iwId, idx) => {
      const impactedWidget = impactedWidgets.find(({ id }) => id === iwId);
      if (impactedWidget) {
        const { id, isPrimary } = impactedWidget;
        const key = ["key-indicators", id, idx].join("-");

        primaryImpactedWidget = isPrimary && !primaryImpactedWidget ? impactedWidget : primaryImpactedWidget;

        const selectedFieldNames = selectedFieldsById[id];

        const jsx = (
          <ImpactedWidgetIndicator
            alertingSlices={alertingSlices}
            cohortId={cohortId}
            entityFilters={entityFilters}
            entityTypeId={entityTypeId}
            eventFieldFilters={eventFieldFilters}
            filtersExist={filtersExist}
            generateDemoData={generateDemoData}
            hideDrawer={hideDrawers}
            impactedWidget={impactedWidget}
            incidentId={incidentId}
            key={key}
            opConfigId={opConfigId}
            preselectFieldNames={selectedFieldNames}
            preset={preset}
            sliceContributorsArr={keyContributors}
            timeRange={timeRange}
          />
        );

        pinnedIndicatorsJsx.push(jsx);
      }
    });

    (pinnedSliceTagNames || []).forEach((tagName, idx) => {
      const keyContributor = keyContributors.find(({ slice }) => slice.tagName === tagName);
      if (keyContributor) {
        const { diagnosticEventFields } = opSchema || {};
        const isDiagnostic = Boolean(diagnosticEventFields?.slices?.find(s => s.tagName === tagName));

        const key = [opConfigId, tagName, idx].join("_");
        pinnedIndicatorsJsx.push(
          <KeyIndicator
            compareSchema={compareSchema}
            entityTypeId={entityTypeId}
            filtersExist={filtersExist}
            generateDemoData={generateDemoData}
            incidentId={incidentId}
            isDiagnostic={isDiagnostic}
            key={key}
            keyContributor={keyContributor}
            lookupData={lookupData}
            opConfigId={opConfigId}
            pinnedSliceTagNames={pinnedSliceTagNames}
            primaryImpactedWidget={primaryImpactedWidget}
          />
        );
      }
    });

    const numPinnedIndicators = pinnedIndicatorsJsx.length;

    if (!numPinnedIndicators) {
      return {
        indicatorsJsx: <div className="inc-text-subtext-medium">No key indicators found</div>,
        numExtraItems: 0
      };
    }

    const indicatorsJsx: JSX.Element[] = [];

    let numExtraItems = numPinnedIndicators - MAX_INDICATORS;
    numExtraItems = numExtraItems > 0 ? numExtraItems : 0;

    if (showAll) {
      indicatorsJsx.push(...pinnedIndicatorsJsx);
    } else {
      const slicedIndicatorsJsx = pinnedIndicatorsJsx.slice(0, MAX_INDICATORS);
      indicatorsJsx.push(...slicedIndicatorsJsx);
    }

    return {
      indicatorsJsx,
      numExtraItems
    };
  }, [
    alertingSlices,
    cohortId,
    compareSchema,
    entityFilters,
    entityTypeId,
    eventFieldFilters,
    filtersExist,
    generateDemoData,
    hideDrawers,
    impactedWidgets,
    incidentId,
    keyContributors,
    lookupData,
    opConfigId,
    opSchema,
    pinnedImpactedWidgets,
    pinnedSliceTagNames,
    preset,
    selectedFieldsById,
    showAll,
    timeRange
  ]);

  useEffect(() => {
    setNumExtraItems && setNumExtraItems(numExtraItems);
  }, [numExtraItems, setNumExtraItems]);

  return (
    <div className="key-indicators">
      <div className="key-indicators--slices-wrapper">{indicatorsJsx}</div>
    </div>
  );
};

const MAX_INDICATORS = 4;
