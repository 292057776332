import { IncSmartText } from "@inception/ui";
import React, { FC, memo } from "react";
import { LoadingSpinner } from "../../../components";
import { OpRunHistoryRenderer } from "./OpRunHistoryRenderer";
import { useFetchOpHistory } from "./useFetchOpHistoryV2";

interface Props {
  opId: string;
}

export const OpRunHistoryWrapper: FC<Props> = memo(props => {
  const { opId } = props;
  const { data, isFetching, isError } = useFetchOpHistory(opId);

  return (
    <div className="padding24">
      {isFetching && <LoadingSpinner />}
      {!isFetching && (
        <>
          {isError && <IncSmartText text="Error fetching history" />}
          {!isError && (
            <>
              {!data && <IncSmartText text="No history exists for this operationalize" />}
              {data && (
                <OpRunHistoryRenderer
                  opStateList={data}
                  showRunHistoryLoading={isFetching}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
});
