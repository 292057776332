import { Visualisations } from "../../../../../../../core";
import { SingleStatUICustomization } from "./SingleStatUICustomization";
import { SparkLineUICustomization } from "./SparkLineUICustomization";
import { TableUICustomization } from "./TableUICustomization";
import { TimeSeriesUICustomization } from "./TimeseriesUICustomization";
import { BaseUICustomization } from "./BaseUICustomization";
import { TopNUICustomization } from "./TopNUICustomization";
import { BarChartUICustomization } from "./BarChartUICustomization";

export * from "./types";
export * from "./DataTypeCustomization";

export const UICustomizations = {
  [Visualisations.pieChart]: BaseUICustomization,
  [Visualisations.timeseries]: TimeSeriesUICustomization,
  [Visualisations.table]: TableUICustomization,
  [Visualisations.barChart]: BarChartUICustomization,
  [Visualisations.gauge]: BaseUICustomization,
  [Visualisations.geoMap]: BaseUICustomization,
  [Visualisations.treeMap]: BaseUICustomization,
  [Visualisations.donut]: BaseUICustomization,
  [Visualisations.heatMap]: BaseUICustomization,
  [Visualisations.stackedBarChart]: BaseUICustomization,
  [Visualisations.singleStat]: SingleStatUICustomization,
  [Visualisations.insights]: TopNUICustomization,
  [Visualisations.histogram]: BaseUICustomization,
  [Visualisations.sparkLine]: SparkLineUICustomization
};
