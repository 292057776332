import React, { FC } from "react";
import { IncButton } from "@inception/ui";
import { VerticallyCenteredRow } from "../../components";

interface Props {
  onImplementPlan: () => void;
  saveWorkflow: () => void;
  showSave?: boolean;
}

export const PlanEditorActions: FC<Props> = props => {
  const { onImplementPlan, saveWorkflow, showSave = true } = props;

  return (
    <VerticallyCenteredRow className="flex-gap-16 paddingLt24 paddingRt24 paddingTp16 paddingBt16 plan-editor-actions">
      <IncButton
        color="primary"
        label="Execute plan"
        onClick={onImplementPlan}
      />
      <div className="marginLtAuto" />
      {showSave && (
        <IncButton
          className="marginRt8"
          color="link"
          iconName="plus"
          iconType="iconText"
          label="Save workflow"
          onClick={saveWorkflow}
        />
      )}
    </VerticallyCenteredRow>
  );
};
