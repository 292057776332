import React from "react";
import {
  DateTimeOptions,
  getFormattedDateTime,
  IncDateTimeFormat,
  IncHighchartsDateTimeFormat,
  IncSmartText,
  TableDataColumn
} from "@inception/ui";
import { isNumber, pick } from "lodash";
import { isEntity } from "../../../../../../utils";
import { SimpleEntityNameRenderer } from "../../../../../../components";
import { DataFrame, TimeRange } from "../../../../../../core";
import { USFieldWidgetSpecProperties } from "../../../models";
import { AUTO_DS_INTERVAL } from "../../../../utils";
import timeRangeUtils from "../../../../../../utils/TimeRangeUtils";
import ChartOptionsBuilder from "../../../../../../components/time-series/ChartOptionsBuilder";

const TIMESTAMP_ACCESSOR = "@timestamp";
const TIMESTAMP_HEADER = "Timestamp";

const VALUE_ACCESSOR = "__value__";

type Datum = Record<string, string | number> & {
  [TIMESTAMP_ACCESSOR]: number;
};

type Column = TableDataColumn<Datum>;

export const getTableColumns = (
  aggregatedTags: string[],
  displayAggregatedTags: string[],
  metricName: string,
  downsample: string,
  timeRange: TimeRange,
  properties: USFieldWidgetSpecProperties["table"],
  entityLookup: Record<string, string>,
  valueFormatterFn: (value: number) => string
) => {
  const {
    formatterOptions,
    dateTimeFormat,
    metricHeader = metricName
    //alignment,
    //groupingDownsample = 'hourly'
  } = properties || {};

  //console.log(dateTimeFormat,groupingDownsample,downsample," not of use variables - ",alignment);
  const timeColumn: Column = {
    accessor: TIMESTAMP_ACCESSOR,
    header: TIMESTAMP_HEADER,
    sortable: true,
    type: "datetime",
    align: "center",
    renderer: (value: number) => {
      const timeFormatter = getTimeFormatter(dateTimeFormat, formatterOptions, downsample, timeRange);
      //console.log(timeFormatter(value));
      return timeFormatter(value);
    }
  };

  const tagColumns = aggregatedTags.map(
    (aggTag, idx): Column => ({
      accessor: aggTag,
      header: displayAggregatedTags[idx] || aggTag,
      align: "center",
      filterable: true,
      filterFn: (rows, filter) => {
        if (filter) {
          const lowerFilter = filter.toString().toLowerCase();
          return rows.filter(row => {
            const rawValue = row.original[aggTag];
            const value = String(entityLookup?.[rawValue] || rawValue || "");

            return value.toLowerCase().includes(lowerFilter);
          });
        }

        return rows;
      },
      sortable: true,
      sortFn: (a: Datum, b: Datum) => {
        let aVal = a[aggTag];
        aVal = String(entityLookup?.[aVal] || aVal || "");

        let bVal = b[aggTag];
        bVal = String(entityLookup?.[bVal] || bVal || "");

        const compareVal = aVal.localeCompare(bVal);
        return compareVal === 0 ? "equal" : compareVal < 0 ? "lesser" : "greater";
      },
      renderer: (value: string) => {
        const renderAsEntity = isEntity(value);

        if (renderAsEntity) {
          return (
            <SimpleEntityNameRenderer
              id={value}
              minimalLoader
              showPropertiesPopover
            />
          );
        }

        return <IncSmartText text={value} />;
      }
    })
  );

  const valueColumn: Column = {
    accessor: VALUE_ACCESSOR,
    header: metricHeader,
    sortable: true,
    align: "center",
    renderer: valueFormatterFn,
    sortFn: (a: Datum, b: Datum) => {
      const aVal = a[VALUE_ACCESSOR];
      const bVal = b[VALUE_ACCESSOR];

      return aVal === bVal ? "equal" : aVal < bVal ? "lesser" : "greater";
    }
  };

  const columns = [timeColumn, ...tagColumns, valueColumn];

  if (!downsample || downsample === AUTO_DS_INTERVAL) {
    columns.splice(0, 1);
  }

  return columns;
};

type DataMapEntry = {
  tags: Record<string, string>;
  value: number;
};

export const getTableData = (dataframes: DataFrame[], aggregatedTags: string[]): Datum[] => {
  const data: Datum[] = [];
  const tsToDataMap = new Map<number, DataMapEntry[]>();
  dataframes.forEach(df => {
    const { labels = {}, fields } = df;
    fields[0].data.forEach((ts, idx) => {
      const value = fields[1].data[idx];
      const entry = tsToDataMap.get(ts) || [];
      entry.push({
        tags: labels,
        value
      });

      tsToDataMap.set(ts, entry);
    });
  });
  const sortedTs = Array.from(tsToDataMap.keys()).sort();
  sortedTs.forEach(ts => {
    const entries = tsToDataMap.get(ts);
    entries.forEach(entry => {
      const { tags, value } = entry;

      if (isNumber(value)) {
        const subData = pick(tags, aggregatedTags);
        data.push({
          ...subData,
          [TIMESTAMP_ACCESSOR]: ts,
          [VALUE_ACCESSOR]: value
        });
      }
    });
  });

  return data;
};

const getTimeFormatter = (
  dateTimeFormat: IncDateTimeFormat,
  formatterOptions: DateTimeOptions,
  downsample: string,
  timeRange: TimeRange
) => {
  const options: DateTimeOptions = {
    withMilliSeconds: false,
    dateSeparator: "/",
    dateTimeSeparator: " ",
    i18nDisabled: false,
    relative: false,
    skipTime: false,
    withSeconds: true,
    ...(formatterOptions || {})
  };

  let format: IncDateTimeFormat | IncHighchartsDateTimeFormat = dateTimeFormat;

  if (!format) {
    const dsMillis = timeRangeUtils.getMillisFromOffset(downsample);
    const { includeSeconds, labelFormat } = new ChartOptionsBuilder().getXAxisLabelsAndFormat(timeRange, dsMillis);

    format = labelFormat;
    options.withSeconds = includeSeconds;
  }

  return (value: number) => getFormattedDateTime(value, format, options);
};
