import React, { FC, useEffect, useMemo } from "react";
import { VerticallyCenteredRow, LoadingSpinner } from "../../../components";
import { logger } from "../../../core";
import {
  OpCreationConfig,
  useFetchIncidentsCount,
  useFetchIncidentsCountForMultipleOps,
  useFetchIncidentsCountForOpConfig
} from "../../../services/api/operationalise";
import { ResultsHeatMapV2 } from "./heat-map-v2";
import { ExtAlertCountResponseEntry } from "./heat-map-v2/types";

interface Props {
  startTimeMillis: number;
  endTimeMillis: number;
  timeIntervalMillis: number;

  opId?: string;
  opIds?: string[];
  opCreationConfig?: OpCreationConfig;

  simulationId?: string;
  generateDemoData?: boolean;
}

export const ResultsViz: FC<Props> = props => {
  const {
    endTimeMillis,
    opId,
    opIds,
    opCreationConfig,
    startTimeMillis,
    timeIntervalMillis,
    simulationId,
    generateDemoData
  } = props;

  const {
    data: dataForOp,
    error: errorForOp,
    isError: isErrorForOp,
    isFetching: isFetchingForOp,
    refetch: fetchCountForOp
  } = useFetchIncidentsCount(
    opId,
    startTimeMillis,
    endTimeMillis,
    timeIntervalMillis,
    simulationId,
    generateDemoData,
    true
  );

  const {
    data: dataForOps,
    error: errorForOps,
    isError: isErrorForOps,
    isFetching: isFetchingForOps,
    refetch: fetchCountForOps
  } = useFetchIncidentsCountForMultipleOps(
    opIds,
    startTimeMillis,
    endTimeMillis,
    timeIntervalMillis,
    generateDemoData,
    true
  );

  const {
    data: dataForOpConfig,
    error: errorForOpConfig,
    isError: isErrorForOpConfig,
    isFetching: isFetchingForOpConfig,
    refetch: fetchCountForOpConfig
  } = useFetchIncidentsCountForOpConfig(
    opCreationConfig,
    startTimeMillis,
    endTimeMillis,
    timeIntervalMillis,
    generateDemoData,
    true
  );

  useEffect(() => {
    if (opId) {
      fetchCountForOp();
    }
  }, [fetchCountForOp, opId]);

  useEffect(() => {
    if (opIds?.length) {
      fetchCountForOps();
    }
  }, [fetchCountForOps, opIds]);

  const numOpIds = opIds?.length;
  useEffect(() => {
    if (!opId && !numOpIds && opCreationConfig) {
      fetchCountForOpConfig();
    }
  }, [fetchCountForOpConfig, numOpIds, opCreationConfig, opId]);

  const error = errorForOp || errorForOps || errorForOpConfig;
  const isError = isErrorForOp || isErrorForOps || isErrorForOpConfig;
  const isFetching = isFetchingForOp || isFetchingForOps || isFetchingForOpConfig;

  const { data, fetchError } = useMemo(() => {
    const data: ExtAlertCountResponseEntry[] = [];
    const fetchErrors: string[] = [];

    if (dataForOp?.length || dataForOpConfig?.length) {
      const dataArr = dataForOp?.length ? dataForOp : dataForOpConfig?.length ? dataForOpConfig : [];
      const entries: ExtAlertCountResponseEntry[] = dataArr.map(d => ({
        ...d,
        opName: ""
      }));
      data.push(...entries);
    } else {
      const countByGroup = new Map<string, number>();

      Object.values(dataForOps).forEach(dataForOp => {
        const { alertCountResponse, errorMessage } = dataForOp || {};

        (alertCountResponse?.count || []).forEach(d => {
          const { count: countStr, groupBy } = d;

          const { startTimeMillis, endTimeMillis } = groupBy || {};

          const count = parseInt(countStr.toString(), 10);
          const key = `${startTimeMillis}-${endTimeMillis}`;

          countByGroup.set(key, (countByGroup.get(key) || 0) + count);
        });

        if (errorMessage) {
          fetchErrors.push(errorMessage);
        }
      });

      countByGroup.forEach((count, key) => {
        const [startTimeMillis, endTimeMillis] = key.split("-");

        const entry: ExtAlertCountResponseEntry = {
          count,
          groupBy: {
            startTimeMillis,
            endTimeMillis
          },
          opName: "",
          url: "",
          primaryGroupBy: ""
        };
        data.push(entry);

        countByGroup.delete(key);
      });
    }

    const fetchError = fetchErrors.join(", ");
    return {
      data,
      fetchError
    };
  }, [dataForOp, dataForOpConfig, dataForOps]);

  useEffect(() => {
    if (isError && fetchError) {
      logger.error("ResultsViz", "Error fetching incidents count", fetchError);
    }
  }, [fetchError, isError]);

  const showOpNameInTooltip = Boolean(opIds?.length);

  return (
    <div className="results-viz">
      {isFetching && <LoadingSpinner className="inc-text-subtext-medium" />}
      {!isFetching && (
        <>
          {isError && (
            <VerticallyCenteredRow className="inc-flex-center status-danger inc-text-subtext-medium">
              Error fetching incidents: {error?.toString()}
            </VerticallyCenteredRow>
          )}
          {!isError && (
            <>
              {Boolean(data?.length) && (
                <ResultsHeatMapV2
                  data={data}
                  endTimeMillis={endTimeMillis}
                  isTwoDimensional={false}
                  showOpNameInTooltip={showOpNameInTooltip}
                  startTimeMillis={startTimeMillis}
                  timeIntervalMillis={timeIntervalMillis}
                />
              )}
              {!data?.length && (
                <VerticallyCenteredRow className="inc-flex-center inc-text-subtext-medium">
                  No alerts found
                </VerticallyCenteredRow>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
