import {
  generateId,
  IncFaIcon,
  IncSelectOption,
  IncSlimSelect,
  IncSmartText,
  IncToolTip,
  ISaxIcon
} from "@inception/ui";
import React, { FC, ReactNode, useCallback, useEffect, useMemo } from "react";
import { startCase } from "lodash";
import { VerticallyCenteredRow } from "../../../components";
import EditableLabelInput from "../../../components/editable-input-label/EditableInputLabel";
import BaseWidgetModel from "../../models/BaseWidgetModel";
import { useViewMaximizeAction } from "../hooks";
import { BaseWidgetProps, WidgetCustomAction } from "../types";
import { widgetRegistry } from "../WidgetRegistry";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { RawTimeRange, TimeRange, useTimeRange } from "../../../core/hooks";
import { TimeRangeController } from "../../../components/time-range";
import WidgetActions from "./WidgetActions";
import WidgetVizSwitcher from "./WidgetVizSwitcher";

interface Props extends Omit<BaseWidgetProps, "onMaximize"> {
  onChange: (updateDashboard: boolean, newModel?: BaseWidgetModel) => void;
  customActionItems: WidgetCustomAction[];
  headerRef: React.RefObject<HTMLDivElement>;
  setTimeRange: (timeRange: TimeRange) => void;
  onMaximize: () => void;
  setHasSubTitle: (hasSubTitle: boolean) => void;
}

const WidgetHeader: FC<Props> = props => {
  const noOp = () => {};

  const {
    onChange,
    onAction: actionsHandler,
    customActionItems,
    timeRange,
    headerRef,
    setTimeRange,
    setHasSubTitle,
    dbImpl,
    ...baseWidgetProps
  } = props;

  const {
    meta: { edit }
  } = dbImpl;

  const { widget, onVizChange = noOp } = baseWidgetProps;

  const { timeRange: widgetTimeRange, downsample, meta } = widget;

  const { edit: metaEdit, hideActions, hideTimeRange } = meta;

  const widgetConf = widgetRegistry.getPropsByWidgetId(widget.type);
  const {
    enableInDashboard = false,
    supportsTimeRange = false,
    disableVizSwitch = false,
    supportsMaximize = false,
    implementsTimeRangeSwitch = false
  } = widgetConf || {};

  const hasCustomTimeRangeOrDs = Boolean(widgetTimeRange) || (downsample && downsample !== "auto");

  const shouldShowTitleEdit = metaEdit;
  const shouldShowTimeRangeEdit =
    !hideTimeRange && supportsTimeRange && hasCustomTimeRangeOrDs && !implementsTimeRangeSwitch;
  const shouldEnableVizSwitch = enableInDashboard && !disableVizSwitch;
  const shouldShowMaximize = supportsMaximize;
  const showMoreActions = edit;

  const { compareTimeRange, timeRange: gTimeRange } = useTimeRange();

  const onTitleChange = useCallback(
    (newTitle: string) => {
      if (newTitle !== widget.title) {
        widget.title = newTitle;
        onChange(false);
      }
    },
    [onChange, widget]
  );

  const updateTimeRange = useCallback(
    (raw: RawTimeRange) => {
      const nTr = raw ? timeRangeUtils.getTimeRangeFromRaw(raw) : gTimeRange;

      const newModel = widget.getSaveModel();
      newModel.timeRange = raw ? nTr : null;
      onChange(true, newModel);

      setTimeRange(nTr);
    },
    [gTimeRange, onChange, setTimeRange, widget]
  );

  const { menuActionItems, headerActionItems, titleActionItems } = useMemo(() => {
    const menuActionItems: ReactNode[] = [];
    const headerActionItems: ReactNode[] = [];
    const titleActionItems: ReactNode[] = [];

    (customActionItems || []).forEach(actionItem => {
      const { actionComponent, showInHeader, tooltipText, isTitleAction } = actionItem;

      const key = generateId();
      const ttKey = `tooltip-${key}`;

      const actionComponentWrapper = <VerticallyCenteredRow key={key}>{actionComponent}</VerticallyCenteredRow>;

      const renderComponent = tooltipText ? (
        <IncToolTip
          key={ttKey}
          placement="top"
          titleText={tooltipText}
        >
          {actionComponentWrapper}
        </IncToolTip>
      ) : (
        actionComponentWrapper
      );

      const actionArr = isTitleAction ? titleActionItems : showInHeader ? headerActionItems : menuActionItems;
      actionArr.push(renderComponent);
    });

    return {
      menuActionItems,
      headerActionItems,
      titleActionItems
    };
  }, [customActionItems]);

  const { actionComponent: maximizeActionComponent, tooltipText: maximizeActionTooltip } = useViewMaximizeAction(props);

  const titleActionsExist = Boolean(titleActionItems.length);

  const trOpt = useMemo<IncSelectOption>(() => (widgetTimeRange ? customOption : autoOption), [widgetTimeRange]);

  const onTimeRangeOptChange = useCallback(
    (option: IncSelectOption) => {
      if (option.value === "custom") {
        updateTimeRange(gTimeRange.raw);
      } else {
        updateTimeRange(null);
      }
    },
    [gTimeRange.raw, updateTimeRange]
  );

  const dsOpt = useMemo<IncSelectOption>(() => {
    const { downsample } = widget.properties;
    if (downsample) {
      const dsOpt = downsampleOptions.find(opt => opt.value === downsample);
      return (
        dsOpt || {
          label: startCase(downsample),
          value: downsample
        }
      );
    }

    return autoOption;
  }, [widget.properties]);

  const onDownSampleChange = useCallback(
    (option: IncSelectOption) => {
      widget.properties = {
        ...widget.properties,
        downsample: option.value
      };

      const newModel = widget.getSaveModel();
      onChange(true, newModel);
    },
    [onChange, widget]
  );

  const subTitle = useMemo(() => {
    if (widgetTimeRange) {
      const { from, to } = widgetTimeRange.raw;
      return timeRangeUtils.getLabelByTimeRange(from, to, null, null, null, widgetTimeRange.timeZone);
    }

    return null;
  }, [widgetTimeRange]);

  const hasSubTitle = Boolean(subTitle);
  useEffect(() => {
    setHasSubTitle(hasSubTitle);
  }, [hasSubTitle, setHasSubTitle]);

  return (
    <div
      className="widget-header"
      ref={headerRef}
    >
      <VerticallyCenteredRow className="width-100">
        {/* Title actions */}
        {!hideActions && titleActionsExist && (
          <div className="inc-flex-row inc-flex-center-vertical title-actions marginRt8">{titleActionItems}</div>
        )}

        {/* Title */}
        <VerticallyCenteredRow className="title-wrapper inc-flex-grow">
          {shouldShowTitleEdit && (
            <EditableLabelInput
              change={onTitleChange}
              className="widget-title"
              editingDisabled={!edit}
              initialValue={widget.title}
            />
          )}
          {!shouldShowTitleEdit && (
            <IncSmartText
              text={widget.title}
              textClass="widget-title"
            />
          )}
          {!!widget.description && (
            <IncToolTip
              className="marginLt8"
              placement="top"
              titleText={widget.description}
            >
              <VerticallyCenteredRow className="marginLt8 cursor-pointer">
                <ISaxIcon
                  className="fa-rotate-180"
                  iconName="InfoCircle"
                  size={12}
                  variant="Outline"
                />
              </VerticallyCenteredRow>
            </IncToolTip>
          )}
        </VerticallyCenteredRow>

        {/* Actions */}
        {(shouldShowTimeRangeEdit || !hideActions) && (
          <VerticallyCenteredRow className="flex-gap-10">
            {shouldShowTimeRangeEdit && (
              <>
                <VerticallyCenteredRow className="flex-gap-4">
                  <IncToolTip
                    placement="top"
                    titleText="Downsample"
                  >
                    <VerticallyCenteredRow>
                      <IncFaIcon
                        className="status-info"
                        iconName="clock"
                      />
                    </VerticallyCenteredRow>
                  </IncToolTip>
                  <IncSlimSelect
                    autoAdjustWidth
                    autoAdjustWidthBuffer={-20}
                    autoSort={false}
                    className="time-select"
                    isSearchable={false}
                    onChange={onDownSampleChange}
                    options={downsampleOptions}
                    value={dsOpt}
                  />
                </VerticallyCenteredRow>

                <VerticallyCenteredRow className="flex-gap-4">
                  <IncToolTip
                    placement="top"
                    titleText="Time Range"
                  >
                    <VerticallyCenteredRow>
                      <IncFaIcon
                        className="status-info"
                        iconName="calendar"
                      />
                    </VerticallyCenteredRow>
                  </IncToolTip>

                  <IncSlimSelect
                    autoAdjustWidth
                    autoAdjustWidthBuffer={-20}
                    autoSort={false}
                    className="time-select"
                    isSearchable={false}
                    onChange={onTimeRangeOptChange}
                    options={timeRangeOptions}
                    value={trOpt}
                  />

                  {trOpt.value !== autoOption.value && (
                    <TimeRangeController
                      compareTimeRange={compareTimeRange}
                      disableRefresh={true}
                      setCompareTimeRange={noOp}
                      setTimeRange={updateTimeRange}
                      showCompareSelector={false}
                      showTimeRangeSelector
                      timeRange={timeRange}
                    />
                  )}
                </VerticallyCenteredRow>
              </>
            )}

            {!hideActions && (
              <>
                {/* Header actions */}
                {headerActionItems}

                {/* Viz switcher */}
                {shouldEnableVizSwitch && (
                  <WidgetVizSwitcher
                    onVizChange={onVizChange}
                    variant="menu"
                    widgetType={widget.type}
                  />
                )}

                {/* Maximize */}
                {shouldShowMaximize && (
                  <IncToolTip
                    placement="top"
                    titleText={maximizeActionTooltip}
                  >
                    <VerticallyCenteredRow>{maximizeActionComponent}</VerticallyCenteredRow>
                  </IncToolTip>
                )}

                {/* More actions */}
                {showMoreActions && (
                  <WidgetActions
                    dbImpl={dbImpl}
                    timeRange={timeRange}
                    {...baseWidgetProps}
                    onAction={actionsHandler}
                  >
                    {menuActionItems}
                  </WidgetActions>
                )}
              </>
            )}
          </VerticallyCenteredRow>
        )}
      </VerticallyCenteredRow>

      {hasSubTitle && (
        <VerticallyCenteredRow className="width-100 inc-text-subtext-medium inc-text-inactive">
          {subTitle}
        </VerticallyCenteredRow>
      )}
    </div>
  );
};

export default WidgetHeader;

const autoOption: IncSelectOption = {
  label: "Auto",
  value: "auto"
};

const customOption: IncSelectOption = {
  label: "Custom",
  value: "custom"
};

const downsampleOptions: IncSelectOption[] = [
  autoOption,
  {
    label: "Hourly",
    value: "1h"
  },
  {
    label: "Daily",
    value: "1d"
  },
  {
    label: "Weekly",
    value: "1w"
  }
];

const timeRangeOptions: IncSelectOption[] = [autoOption, customOption];
