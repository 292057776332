import { IncButton, IncFaIcon, IncGenericIcon, IncSmartText, IncToolTip, RenderWithMore } from "@inception/ui";
import React, { useMemo, PropsWithRef, forwardRef, CSSProperties } from "react";
import { CypressConstants } from "@bicycle/tests";
import { useToggleState } from "../../core";
import { AgentChatTypes } from "../../services/api/chat";
import { TriageSummaryTypes } from "../../services/api/triage-v2";
import getChartColor from "../charts/colors";
import { VerticallyCenteredRow } from "../flex-components";
import LoadingSpinner from "../Loading/Loading";
import { MarkdownRenderer } from "../markdown";
import { CauseSummaryInfo } from "./types";

interface Props {
  causeSummaryInfo: CauseSummaryInfo;
  onAnalyze: (stepId: string) => void;
  onViewSource: (source: AgentChatTypes.Source) => void;

  isIconsLoading: boolean;
  iconsBySourceType: Record<string, JSX.Element>;

  defaultOpen?: boolean;
  onToggleView?: () => void;
}

type FProps = PropsWithRef<Props>;

export const CauseSummaryCard = forwardRef<HTMLDivElement, FProps>((props, ref) => {
  const {
    causeSummaryInfo,
    onAnalyze,
    iconsBySourceType,
    isIconsLoading,
    onViewSource,
    defaultOpen = false,
    onToggleView
  } = props;

  const { causeTypeInfo, summaries: causeSummaries, color } = causeSummaryInfo;

  const { icon, typeHeader, possibleActions: fallbackPossibleActions } = causeTypeInfo;

  const iconStyle: CSSProperties = {
    color,
    fill: color,
    fontSize: 22
  };

  const { isOpen, toggle: onToggle } = useToggleState(defaultOpen);

  const toggle = () => {
    onToggle();
    onToggleView && onToggleView();
  };

  const { actionsTakenByType, possibleActions, sources, summaries, numActionsTaken } = useMemo(() => {
    const actionsTakenByType: Record<TriageSummaryTypes.Actions["actionType"], TriageSummaryTypes.Actions[]> = {};
    const summaries: Array<[string, string, TriageSummaryTypes.CauseSummary]> = [];
    const possibleActions: TriageSummaryTypes.Actions[] = [];
    const sources: AgentChatTypes.Source[] = [];
    let numActionsTaken = 0;

    causeSummaries.forEach(causeSummary => {
      const {
        cause: causeDescription,
        causeTitle,
        actionsTaken = [],
        possibleActions: pActions = [],
        source
      } = causeSummary;

      summaries.push([causeTitle, causeDescription, causeSummary]);
      possibleActions.push(...pActions);
      source && sources.push(source);

      numActionsTaken += actionsTaken.length;
      actionsTaken.forEach(action => {
        const { actionType } = action;
        actionsTakenByType[actionType] = actionsTakenByType[actionType] || [];
        actionsTakenByType[actionType].push(action);
      });
    });

    if (!causeSummaries.length) {
      possibleActions.push(...(fallbackPossibleActions || []));
    }

    return {
      actionsTakenByType,
      summaries,
      possibleActions,
      sources,
      numActionsTaken
    };
  }, [causeSummaries, fallbackPossibleActions]);

  const actionsTakenJsx = useMemo(() => {
    const actionsTakenByTypeKeys = Object.keys(actionsTakenByType) as Array<TriageSummaryTypes.Actions["actionType"]>;
    if (actionsTakenByTypeKeys.length === 0) {
      return <VerticallyCenteredRow className="inc-text-subtext-medium">No actions taken</VerticallyCenteredRow>;
    }

    const actionsTakenJsx = actionsTakenByTypeKeys.map((actionType, idx) => {
      const actions = actionsTakenByType[actionType];
      const numActions = actions.length;

      const { displayText, description, faIcon } = actions[0];

      const icon = isIconsLoading ? (
        <LoadingSpinner titleText=" " />
      ) : (
        iconsBySourceType[actionType] ||
        (faIcon ? (
          <IncGenericIcon
            iconName={faIcon}
            size={16}
            style={{
              fill: getChartColor(idx + 1),
              color: getChartColor(idx + 1),
              fontSize: 16
            }}
          />
        ) : (
          <></>
        ))
      );

      return (
        <VerticallyCenteredRow
          className="action-taken-pill width-fit-content"
          key={idx}
        >
          <IncToolTip
            placement="top"
            titleText={description}
          >
            <VerticallyCenteredRow>{icon}</VerticallyCenteredRow>
          </IncToolTip>
          <span className="inc-text-subtext-medium">
            {displayText} ({numActions})
          </span>
        </VerticallyCenteredRow>
      );
    });

    return <VerticallyCenteredRow className="flex-gap-10 inc-flex-row-wrap">{actionsTakenJsx}</VerticallyCenteredRow>;
  }, [actionsTakenByType, iconsBySourceType, isIconsLoading]);

  const possibleActionsJsx = useMemo(() => {
    if (possibleActions.length === 0) {
      return <VerticallyCenteredRow className="inc-text-subtext-medium">No possible actions</VerticallyCenteredRow>;
    }

    const possibleActionsJsx = possibleActions.map((action, idx) => {
      const { description, displayText, faIcon, actionType } = action;

      const icon = isIconsLoading ? (
        <LoadingSpinner titleText=" " />
      ) : (
        iconsBySourceType[actionType] ||
        (faIcon ? (
          <IncGenericIcon
            iconName={faIcon}
            size={16}
            style={{
              fill: getChartColor(idx + 1),
              color: getChartColor(idx + 1),
              fontSize: 16
            }}
          />
        ) : (
          <></>
        ))
      );

      return (
        <VerticallyCenteredRow
          className="possible-action-pill width-fit-content"
          key={idx}
        >
          <IncToolTip
            placement="top"
            titleText={description}
          >
            <VerticallyCenteredRow>{icon}</VerticallyCenteredRow>
          </IncToolTip>
          <IncSmartText
            className="inc-text-subtext-medium"
            key={idx}
            text={displayText}
          />
        </VerticallyCenteredRow>
      );
    });

    return (
      <RenderWithMore
        className="inc-flex-center-vertical flex-gap-8"
        limit={5}
        noChildrenWrapper
      >
        {possibleActionsJsx}
      </RenderWithMore>
    );
  }, [iconsBySourceType, isIconsLoading, possibleActions]);

  const sourcesJsx = useMemo(() => {
    if (sources.length === 0) {
      return <VerticallyCenteredRow className="inc-text-subtext-medium">No sources</VerticallyCenteredRow>;
    }

    const sourcesJsx = sources.map((source, idx) => {
      const { id, description } = source;

      const onViewSourceInternal = () => onViewSource(source);

      return (
        <div
          className="cause-summary--source"
          key={id}
        >
          <div className="inc-flex-row flex-gap-8">
            <div className="source-number marginBtAuto marginTp8">{idx + 1}</div>

            <IncSmartText
              className="inc-text-subtext-medium"
              numLines={3}
              text={description}
            />
          </div>

          <IncButton
            className="width-fit-content marginTpAuto"
            color="link"
            label="View Source"
            onClick={onViewSourceInternal}
            size="small"
          />
        </div>
      );
    });

    return <VerticallyCenteredRow className="inc-flex-row-wrap flex-gap-12">{sourcesJsx}</VerticallyCenteredRow>;
  }, [onViewSource, sources]);

  const summariesJsx = useMemo(() => {
    const numSummaries = summaries.length;
    const summariesJsx: JSX.Element[] = [];

    summaries.forEach(([title, mdSummary, causeObj], idx) => {
      const onAnalyzeCause = () => onAnalyze(causeObj.source?.id);
      const shortSummaryMarkdown = causeObj?.shortSummary || title;
      const causeJsx = (
        <div
          className="width-100 inc-flex-column flex-gap-4"
          key={idx}
        >
          <div className="inc-flex-row">
            <span className="inc-text-subtext-medium marginRt6">{idx + 1}) </span>
            <MarkdownRenderer
              className="inc-text-subtext-medium"
              mdContent={shortSummaryMarkdown}
            />
          </div>

          <MarkdownRenderer
            className="inc-text-subtext inc-text-inactive marginLt22"
            mdContent={mdSummary}
          />

          <IncButton
            className="width-fit-content marginTp4"
            color="secondary-blue"
            onClick={onAnalyzeCause}
            size="small"
          >
            View Analysis
          </IncButton>
        </div>
      );

      summariesJsx.push(causeJsx);
      idx !== numSummaries - 1 &&
        summariesJsx.push(
          <div
            className="triage-v2-summary--divider"
            key={`${idx}-hr`}
          />
        );
    });

    return summariesJsx;
  }, [onAnalyze, summaries]);

  const headerNumToShow = causeSummaries.length;

  const readOnly = !causeSummaries.length && !possibleActions.length;

  const renderSummaries = causeSummaries.length ? true : Boolean(summariesJsx.length);
  const renderActions = causeSummaries.length ? true : Boolean(numActionsTaken);
  const renderSources = causeSummaries.length ? true : Boolean(sources.length);
  const className = `cause-summary--section paddingLt8 cause-summary--possible-actions x-scroll ${
    renderSources ? "color-border" : ""
  }`;

  return (
    <div
      className="triage-v2-summary--cause-summary"
      data-collapsed={!isOpen}
      data-cy={CypressConstants.features.Triage.attributes.causeSummary}
      data-no-entries={readOnly}
      ref={ref}
    >
      <VerticallyCenteredRow className="cause-summary--title flex-gap-12">
        <IncGenericIcon
          iconName={icon}
          size={22}
          style={iconStyle}
        />

        <VerticallyCenteredRow className="inc-text-body-medium flex-gap-4">
          {typeHeader}
          <span className="inc-text-inactive">({headerNumToShow})</span>
        </VerticallyCenteredRow>

        {Boolean(numActionsTaken) && (
          <VerticallyCenteredRow className="actions-taken-pill">
            Actions taken ({numActionsTaken})
          </VerticallyCenteredRow>
        )}

        {!readOnly && (
          <IncFaIcon
            className="collapse-arrow inc-cursor-pointer marginLtAuto"
            data-expanded={isOpen}
            iconName="chevron-circle-down"
            onClick={toggle}
          />
        )}
      </VerticallyCenteredRow>

      {isOpen && (
        <div className="cause-summary--content">
          {renderSummaries && <div className="cause-summary--section cause-summary--summary">{summariesJsx}</div>}

          <div className="inc-flex-row">
            {renderActions && (
              <div className="cause-summary--section cause-summary--actions-taken x-scroll">
                <VerticallyCenteredRow>Actions Taken</VerticallyCenteredRow>
                {actionsTakenJsx}
              </div>
            )}

            <div className={className}>
              <VerticallyCenteredRow>Other actions that you can take</VerticallyCenteredRow>
              {possibleActionsJsx}
            </div>
          </div>
          {renderSources && (
            <div className="cause-summary--section cause-summary--sources">
              <VerticallyCenteredRow>Sources</VerticallyCenteredRow>
              {sourcesJsx}
            </div>
          )}
        </div>
      )}
    </div>
  );
});
