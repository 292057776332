import styled from "@emotion/styled";
import { getInceptionTheme, IncClickAway, IncPopper, IncTextfield, IncToolTip } from "@inception/ui";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ColorResult, SketchPicker } from "react-color";
import { ReactComponent as ResetIcon } from "../../../images/icons/Reset.svg";

interface ColorPickerProps {
  color: string;
  defaultColor?: string;
  onUpdate(newColor: string): void;
  className?: string;
  label?: string;
  placeholder?: string;
  mode: "hex" | "rgb";
}

const defaultColor: string = getInceptionTheme().inceptionColors.palette.BL900;

const ColorPicker: React.FC<ColorPickerProps> = (props: ColorPickerProps) => {
  const previewRef = useRef();
  const [showPicker, setShowPicker] = useState(false);
  const [color, setColor] = useState<string>("");

  const {
    color: usrColor,
    mode,
    onUpdate: usrOnUpdate,
    defaultColor: usrDefaultColor,
    className = "",
    label = "Color",
    placeholder = "Color"
  } = props;

  useEffect(() => {
    setColor(usrColor || usrDefaultColor || defaultColor);
  }, [usrColor, usrDefaultColor]);

  const onTogglePicker = useCallback(() => {
    setShowPicker(oldShowPicker => !oldShowPicker);
  }, []);

  const onColorUpdate = useCallback(
    (colorResult: ColorResult) => {
      let newColor: string;
      if (mode === "hex") {
        newColor = colorResult.hex;
      } else {
        const { rgb } = colorResult;
        const { r, g, b, a } = rgb;
        newColor = `rgba(${r},${g},${b},${a || 100})`;
      }
      setShowPicker(false);
      usrOnUpdate(newColor);
    },
    [mode, usrOnUpdate]
  );

  const onColorChange = useCallback(
    (evt: any) => {
      const newColor: string = evt.target.value;
      const regex = mode === "hex" ? /^#[0-9A-Fa-f]{6}$/ : /rgba([0-255],[0-255],[0-255],[0-100])/;
      if (newColor) {
        if (regex.test(newColor)) {
          usrOnUpdate(newColor);
        } else {
          setColor(newColor);
        }
      }
    },
    [mode, usrOnUpdate]
  );

  const onRemoveColor = useCallback(() => {
    usrOnUpdate(usrDefaultColor || defaultColor);
  }, [usrDefaultColor, usrOnUpdate]);

  return (
    <>
      {color && (
        <Wrapper className={className}>
          <IncTextfield
            label={label}
            onChange={onColorChange}
            placeholder={placeholder}
            value={color}
          />
          <IncToolTip
            placement="top"
            titleText="Open/close color picker"
          >
            <div style={{ display: "flex" }}>
              <ColorPreview
                onClick={onTogglePicker}
                ref={previewRef}
                style={{ background: color }}
              />
              <IncClickAway onClickAway={() => setShowPicker(false)}>
                {refFn => (
                  <IncPopper
                    anchorEl={previewRef as any}
                    placement="bottom"
                    ref={refFn}
                    show={showPicker}
                  >
                    <SketchColorPicker
                      color={color}
                      disableAlpha={mode === "hex"}
                      onChangeComplete={onColorUpdate}
                      styles={{ default: { picker: { margin: 8 } } }}
                    />
                  </IncPopper>
                )}
              </IncClickAway>
            </div>
          </IncToolTip>

          <IncToolTip
            placement="top"
            titleText="Reset default"
          >
            <ResetIcon
              className="icon-btn"
              onClick={onRemoveColor}
              style={{ marginBottom: 6 }}
            />
          </IncToolTip>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: flex-end;
`;

const ColorPreview = styled.span`
  width: 36px;
  height: 36px;
  margin: 0 12px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #829bb5;
  position: relative;
`;

const SketchColorPicker = styled(SketchPicker)`
  box-shadow: none !important;
  background: transparent !important;

  span {
    color: ${getInceptionTheme().inceptionColors.primary1} !important;
  }

  input {
    box-shadow: none !important;
    border-radius: 4px;
    background: rgb(59, 69, 78);
  }
`;

export default ColorPicker;
