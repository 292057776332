import React, { FC, useEffect } from "react";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../components";
import { logger } from "../../../core";
import { useSaveWidgetConfigRef } from "./hooks";
import { CatalogWidgetProps } from "./types";
import { CatalogWidgetWrapper } from "./CatalogWidgetWrapper";

export const CatalogWidget: FC<CatalogWidgetProps> = props => {
  const { isInitialising, initialisationError, demoParams, isValidWidget } = useSaveWidgetConfigRef(
    props.widget,
    props.timeRange
  );

  useEffect(() => {
    if (initialisationError) {
      logger.error("CatalogWidget", "Error initialising widget config ref", initialisationError);
    }
  }, [initialisationError]);

  return (
    <>
      {isInitialising && <LoadingSpinner className="inc-text-subtext-medium" />}
      {!isInitialising && (
        <>
          {Boolean(initialisationError) && (
            <VerticallyCenteredRow className="status-danger inc-text-subtext-medium width-100 height-100 inc-flex-center">
              {initialisationError}
            </VerticallyCenteredRow>
          )}
          {!initialisationError && (
            <CatalogWidgetWrapper
              {...props}
              demoParams={demoParams}
              isValidWidget={isValidWidget}
            />
          )}
        </>
      )}
    </>
  );
};
