import React, { FC, useMemo, useEffect, useRef, useCallback, useState } from "react";
import { TooltipOptions, DataLabelsFormatterCallbackFunction, AxisLabelsFormatterContextObject } from "highcharts";
import { isEqual } from "lodash";
import { IncidentTimelineBin } from "../../../services/api/explore";
import BarColumnChartOptionsBuilder from "../../bar-chart/BarColumnChartOptionsBuilder";
import { BarColumnChart } from "../../charts/common";
import { useForceUpdate, TimeRange, dateTime } from "../../../core";
import {
  getBarSeriesFromBins,
  incidentTimelineTooltipFormatter,
  incidentTimelineDataLabelFormatter,
  incidentTimelineAxisLabelFormatter
} from "./utils";

interface Props {
  bins: IncidentTimelineBin[];
  timeRange: TimeRange;

  accessorHeaderMap?: Record<string, string>;
  onSelect?: (bins: IncidentTimelineBin[]) => void;

  impactMetricName?: string;
  isMetricMode?: boolean;
  minXAxisIntervalMs?: number;
}

export const IncidentTimeline: FC<Props> = props => {
  const {
    bins,
    accessorHeaderMap,
    isMetricMode = false,
    onSelect: pOnSelect,
    impactMetricName,
    minXAxisIntervalMs
  } = props;

  const ref = useRef<HTMLDivElement>();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver(forceUpdate);
      observer.observe(ref.current);
    }
  }, [forceUpdate]);

  const [selectedBins, setSelectedBins] = useState<IncidentTimelineBin[]>([]);
  const selectedBinsRef = useRef<IncidentTimelineBin[]>([]);

  const onSelect = useCallback(
    (bin: IncidentTimelineBin) => {
      const selectedBins = selectedBinsRef.current;
      const selectionIdx = selectedBins.findIndex(b => isEqual(b, bin));
      const shouldRemoveSelection = selectionIdx !== -1;

      // const nSelectedBins = shouldRemoveSelection ? selectedBins.filter(b => bin !== b)
      //   : [...selectedBins, bin];

      const nSelectedBins = shouldRemoveSelection ? [] : [bin];

      selectedBinsRef.current = nSelectedBins;
      setSelectedBins(nSelectedBins);
      pOnSelect && pOnSelect(nSelectedBins);
    },
    [pOnSelect]
  );

  const { series, timestamps } = useMemo(
    () => getBarSeriesFromBins(bins, onSelect, selectedBins, isMetricMode),
    [bins, isMetricMode, onSelect, selectedBins]
  );

  const optionsBuilder = useMemo(
    () =>
      new BarColumnChartOptionsBuilder({ barDimension: 16 })
        .setLayout("vertical")
        .setShowDataLabels(true)
        .setDataLabelsOptions({
          align: "center",
          verticalAlign: "top",
          inside: false,
          position: "center",
          y: -25
        }),
    []
  );

  const tooltipFormatter = useCallback<TooltipOptions["formatter"]>(
    function () {
      return incidentTimelineTooltipFormatter.bind(this)(accessorHeaderMap, isMetricMode, impactMetricName);
    },
    [accessorHeaderMap, impactMetricName, isMetricMode]
  );

  const dataLabelFormatter = useCallback<DataLabelsFormatterCallbackFunction>(function () {
    return incidentTimelineDataLabelFormatter.bind(this)();
  }, []);

  const timeRange = useMemo(() => {
    const fromMillis = timestamps[0] * 1000;
    const toMillis = timestamps.slice(-1)[0] * 1000;
    const timeRange: TimeRange = {
      from: dateTime(fromMillis),
      to: dateTime(toMillis),
      raw: {
        from: String(fromMillis),
        to: String(toMillis)
      }
    };
    return timeRange;
  }, [timestamps]);

  const xAxisLabelFormatter = useCallback(
    (ctx: AxisLabelsFormatterContextObject) => incidentTimelineAxisLabelFormatter(ctx, timestamps, timeRange),
    [timeRange, timestamps]
  );

  return (
    <div
      className="incident-timeline"
      ref={ref}
    >
      <BarColumnChart
        allowDecimalsInYAxis={false}
        containerElemClass="incident-timeline"
        dataLabelFormatter={dataLabelFormatter}
        disableAreaSelection
        hideYAxis
        minXAxisIntervalMs={minXAxisIntervalMs}
        options={options}
        optionsBuilder={optionsBuilder}
        series={series}
        showLegend={false}
        stacked
        timeRange={timeRange}
        title=""
        tooltipFormatter={tooltipFormatter}
        xAxisLabelFormatter={xAxisLabelFormatter}
      />
    </div>
  );
};

const options: Highcharts.Options = {
  yAxis: [
    {
      gridLineWidth: 0
    }
  ]
};
