import React, { useCallback, useMemo, useState } from "react";
import { IncButton, IncModal, IncModalProps, IncStepProps, IncSteps } from "@inception/ui";
import { CypressConstants } from "@bicycle/tests";
import { UserServiceFilterExpression } from "../../services/api/explore";
import { noOp } from "../../utils";
import { VerticallyCenteredRow } from "../flex-components";
import {
  SUBSCRIBE_STEP_1,
  SUBSCRIBE_STEP_2,
  SUBSCRIBE_STEP_3,
  SubscribeDialogContentStep1,
  SubscribeDialogContentStep2,
  SubscribeDialogContentStep3
} from "./components";

const steps: IncStepProps[] = [
  {
    id: SUBSCRIBE_STEP_1,
    title: "Insight Destination"
  },
  {
    id: SUBSCRIBE_STEP_2,
    title: "Insight Content"
  },
  {
    id: SUBSCRIBE_STEP_3,
    title: "Insight Scope"
  }
];

type Props = {
  title?: string;
  onClose?: () => void;
  noModal?: boolean;

  customTemplateValues?: {
    slack: any[];
    salesforce: any[];
  };
  customFilters?: {
    sampleValues: Record<string, string[]>;
    filters: UserServiceFilterExpression[];
    dataTypes?: Record<string, string>;
  };
};

export const SubscribeDialogV2: React.FC<Props> = props => {
  const [stepId, setStepId] = useState(SUBSCRIBE_STEP_1);

  const [canProceed, setCanProceed] = useState(false);
  const [templateToRender, setTemplateToRender] = useState<"slack" | "salesforce">(null);

  const { onClose = noOp, title, noModal, customTemplateValues, customFilters } = props;

  const onNext = useCallback(() => {
    if (stepId === SUBSCRIBE_STEP_1) {
      setStepId(SUBSCRIBE_STEP_2);
    } else if (stepId === SUBSCRIBE_STEP_2) {
      setStepId(SUBSCRIBE_STEP_3);
    } else {
      onClose();
    }
  }, [onClose, stepId]);

  const onPrevious = useCallback(() => {
    if (stepId === SUBSCRIBE_STEP_2) {
      setStepId(SUBSCRIBE_STEP_1);
    } else if (stepId === SUBSCRIBE_STEP_3) {
      setStepId(SUBSCRIBE_STEP_2);
    }
  }, [stepId]);

  const actions: IncModalProps["actions"] = useMemo(
    () => ({
      primary: {
        onClick: onNext,
        color: "primary",
        disabled: !canProceed,
        label: "Next"
      },
      secondary: {
        onClick: onClose,
        color: "secondary-blue",
        label: "Cancel"
      }
    }),
    [canProceed, onClose, onNext]
  );

  const content = (
    <>
      <div className="subscribe-dialog-content">
        <IncSteps
          className="marginBt32 marginLtAuto marginRtAuto width-75"
          onChange={noOp}
          selectedStepId={stepId}
          steps={steps}
        />
        {stepId === SUBSCRIBE_STEP_1 && (
          <SubscribeDialogContentStep1
            setCanProceed={setCanProceed}
            setTemplateToRender={setTemplateToRender}
          />
        )}
        {stepId === SUBSCRIBE_STEP_2 && (
          <SubscribeDialogContentStep2
            customTemplateValues={customTemplateValues}
            setCanProceed={setCanProceed}
            templateToRender={templateToRender}
          />
        )}
        {stepId === SUBSCRIBE_STEP_3 && (
          <SubscribeDialogContentStep3
            customFilters={customFilters}
            setCanProceed={setCanProceed}
          />
        )}
      </div>

      {noModal && (
        <VerticallyCenteredRow className="flex-gap-12 marginTp16">
          {stepId !== SUBSCRIBE_STEP_1 && (
            <IncButton
              color="secondary-blue"
              onClick={onPrevious}
            >
              Previous
            </IncButton>
          )}

          {stepId !== SUBSCRIBE_STEP_3 && (
            <IncButton
              color="primary"
              data-cy={CypressConstants.features.CreateCopilot.attributes.subscribeNextAction}
              disabled={!canProceed}
              onClick={onNext}
            >
              Next
            </IncButton>
          )}
        </VerticallyCenteredRow>
      )}
    </>
  );

  if (noModal) {
    return content;
  }

  return (
    <IncModal
      actions={actions}
      className="subscribe-dialog"
      closeOnBackdrop={false}
      closeOnEscape={false}
      onClose={onClose}
      show
      showClose
      size="xxlg"
      titleText={title}
    >
      {content}
    </IncModal>
  );
};
