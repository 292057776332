import { defaultsDeep, isUndefined } from "lodash";
import { BaseWidgetImpl } from "../../..";
import { FilterWidgetModel } from "./model";

const getDefaults = (): Partial<FilterWidgetModel> => ({
  title: "Filter Widget",
  type: "filter",
  appliedWidgets: [],
  entityTypeId: "",
  userServiceId: ""
});

export class FilterWidgetImpl extends BaseWidgetImpl implements FilterWidgetModel {
  appliedWidgets: string[];
  entityTypeId: string;
  userServiceId: string;
  cohortId: string;
  isCohortPinned: boolean;
  isCohortLocked: boolean;
  cohortVisible: boolean;
  genericEventFilter: boolean;

  constructor(model: Partial<FilterWidgetModel>, meta?: Record<string, any>) {
    super(model, meta);
    this.assign(model, meta);
  }

  assign(model: Partial<FilterWidgetModel>, meta?: Record<string, any>) {
    const filtersModel: FilterWidgetModel = defaultsDeep({ type: "filter" }, model, getDefaults());
    super.assign(filtersModel, meta);

    this.appliedWidgets = filtersModel.appliedWidgets;
    this.entityTypeId = filtersModel.entityTypeId;
    this.userServiceId = filtersModel.userServiceId;
    this.cohortId = filtersModel.cohortId;
    this.isCohortPinned = filtersModel.isCohortPinned;
    this.isCohortLocked = filtersModel.isCohortLocked;
    this.cohortVisible = !isUndefined(filtersModel.cohortVisible)
      ? filtersModel.cohortVisible
      : filtersModel.cohortId
        ? Boolean(filtersModel.cohortId)
        : false;

    this.genericEventFilter = filtersModel.genericEventFilter;
  }
}
