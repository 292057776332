import React, { useMemo, useCallback } from "react";
import { isArray } from "lodash";
import {
  IncSelectOption,
  IncCollapsible,
  IncFaIconName,
  IncDateRangePicker,
  ButtonGroupOption,
  ButtonSelectionGroup,
  IncMultiSelectCheckBox,
  IncFaIcon,
  getInceptionTheme
} from "@inception/ui";
import { cx } from "emotion";
import { DataType } from "../../core";
import { EntityOperation, EntityOpToTraceQueryOpMap } from "../../services/api/explore";
import { FieldCardinality, EntityAggregationMeta, EntityAggregationValue } from "../../services/api/types";
import { getKeyByValue, Options } from "../../utils";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { KindDescriptor } from "../data-type";
import { EntityPropertySelect } from "../EntityPropertySelect";
import { NumericRangeSlider, NumericRangeSliderOperators } from "../NumericRangeSlider";
import { NumericRangeProps } from "../numeric-range/types";

export interface GenericFilterField {
  id: string;
  fieldName: string;
  fieldType: DataType | "NA";
  op: EntityOperation;
  value: string | string[];
  isHighCardinalityField?: boolean;
  kindDescriptor?: KindDescriptor;
  entityTypeId?: string;
  cohortId?: string;
  cardinality: FieldCardinality;
  aggregationMeta?: EntityAggregationMeta;
  aggregationValues?: EntityAggregationValue[];
  isPinned?: boolean;
  isLocked?: boolean;
}

const collapseIconName: IncFaIconName = "chevron-circle-up";
const expandIconName: IncFaIconName = "chevron-circle-down";

interface Props {
  fields: GenericFilterField[];
  onChange: (fieldName: string, value: string | string[], operator: EntityOperation, isPinned?: boolean) => void;
  className?: string;
  filterClassName?: string;
  options?: Options;
  supportPinning?: boolean;
}

export const QuickFiltersPicker: React.FC<Props> = (props: Props) => {
  const { fields, onChange, options, supportPinning } = props;

  const filters = useMemo(
    () =>
      fields.map((field: GenericFilterField) => {
        const { isPinned } = field;

        let headerActions = null;

        if (supportPinning) {
          const { fieldName, op, value } = field;
          const onClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            event.stopPropagation();
            onChange(fieldName, value, op, !isPinned);
          };
          headerActions = (
            <IncFaIcon
              className="marginLt6 inc-cursor-pointer"
              color={isPinned ? getInceptionTheme().inceptionColors.accentBlue : ""}
              iconName="thumb-tack"
              onClick={onClick}
            />
          );
        }

        const { isHighCardinalityField, fieldType } = field;
        if (isHighCardinalityField) {
          return (
            <EntityTypeSelector
              key={field.id}
              {...field}
              headerActions={headerActions}
              onChange={onChange}
            />
          );
        } else if (fieldType === "DATE" || fieldType === "DATETIME") {
          return (
            <DateRangePickerWrapper
              key={field.id}
              {...field}
              headerActions={headerActions}
              onChange={onChange}
            />
          );
        } else if (fieldType === "LONG") {
          return (
            <NumericRangeSliderWrapper
              key={field.id}
              {...field}
              headerActions={headerActions}
              onChange={onChange}
              options={options}
            />
          );
        } else if (fieldType === "BOOLEAN") {
          return (
            <BooleanFieldWrapper
              key={field.id}
              {...field}
              headerActions={headerActions}
              onChange={onChange}
            />
          );
        } else {
          return (
            <StringFieldsWrapper
              key={field.id}
              {...field}
              headerActions={headerActions}
              onChange={onChange}
            />
          );
        }
      }),
    [fields, onChange, options, supportPinning]
  );

  return <div className="filters-container">{filters}</div>;
};

interface WrapperComponentProps extends GenericFilterField {
  onChange: Props["onChange"];
  headerActions?: JSX.Element;
  className?: string;
}

export const EntityTypeSelector: React.FC<WrapperComponentProps> = (props: WrapperComponentProps) => {
  const { onChange, fieldName, entityTypeId, cohortId, value, className, headerActions } = props;

  const onSelectionChange = useCallback(
    (opts: IncSelectOption[]) => {
      const values = opts.map(x => x.value);
      const op = values.length > 1 ? "in" : "eq";
      const eValue = op === "eq" ? values[0] || "" : values;
      onChange(fieldName, eValue, op);
    },
    [fieldName, onChange]
  );

  const values = isArray(value) ? value : value ? [value] : [];

  const selectedOptions = values.map(x => ({
    label: x,
    value: x
  }));

  const classes = cx("filter-component", className);

  return (
    <IncCollapsible
      className={classes}
      collapseIconName={collapseIconName}
      content={
        <EntityPropertySelect
          applySelectionOnBlur
          cohortId={cohortId}
          displayLabel={""}
          entityTypeId={entityTypeId}
          onSelectionChange={onSelectionChange}
          propName={fieldName}
          selection={selectedOptions}
        />
      }
      expandIconName={expandIconName}
      header={fieldName}
      headerActions={headerActions}
    />
  );
};

interface NumericRangeSliderWrapperProps extends WrapperComponentProps {
  options: Options;
}

const NumericRangeSliderWrapper: React.FC<NumericRangeSliderWrapperProps> = (props: NumericRangeSliderWrapperProps) => {
  const {
    fieldName,
    aggregationMeta,
    value: eValue,
    op,
    onChange,
    kindDescriptor,
    options,
    className,
    headerActions
  } = props;

  const stats = aggregationMeta?.stats;
  const minValue = stats?.min || 0;
  const maxValue = stats?.max || 100;

  const onValChange = useCallback(
    (value: number | number[], operator: NumericRangeSliderOperators) => {
      const op =
        operator !== "range" ? (getKeyByValue(operator, EntityOpToTraceQueryOpMap) as EntityOperation) : operator;
      const strVal = isArray(value) ? value.map(x => x.toString()) : value.toString();
      onChange(fieldName, strVal, op);
    },
    [fieldName, onChange]
  );

  const fieldValue = isArray(eValue) ? eValue.map(x => parseInt(x, 10)) : eValue ? parseInt(eValue, 10) : null;

  const operator = op && op === "range" ? op : EntityOpToTraceQueryOpMap[op] || "<";

  const { type = "not_set" } = kindDescriptor || {};

  const isCurrencyRange = type === "currency";
  const currencyType = isCurrencyRange ? options.currency : null;
  const subType: NumericRangeProps["subType"] = isCurrencyRange
    ? {
        kind: "currency",
        subType: currencyType
      }
    : null;

  const classes = cx("filter-component", className);
  const finalFieldValue: [number, number] = isArray(fieldValue)
    ? (fieldValue as [number, number])
    : [fieldValue, fieldValue];

  return (
    <IncCollapsible
      className={classes}
      collapseIconName={collapseIconName}
      content={
        <NumericRangeSlider
          label={fieldName}
          max={maxValue}
          min={minValue}
          onChange={onValChange as any}
          operator={operator as NumericRangeSliderOperators}
          subType={subType}
          value={finalFieldValue}
        />
      }
      expandIconName={expandIconName}
      header={fieldName}
      headerActions={headerActions}
    />
  );
};

const DateRangePickerWrapper: React.FC<WrapperComponentProps> = (props: WrapperComponentProps) => {
  const { fieldName, onChange, value: eValue, className, headerActions } = props;

  const onTimeRangeChange = useCallback(
    (from: Date | null, to: Date | null) => {
      if (from && to) {
        const valueFrom = from.toISOString();
        const valueTo = to.toISOString();
        const value = [valueFrom, valueTo];
        onChange(fieldName, value, "range");
      } else {
        onChange(fieldName, [], "range");
      }
    },
    [fieldName, onChange]
  );

  const value = isArray(eValue) ? eValue : eValue || "";

  const getRawTime = (datetime: string) =>
    timeRangeUtils.isRelativeTime(datetime) ? datetime : Date.parse(datetime).toString();

  const timeRange = value
    ? timeRangeUtils.getTimeRangeFromRaw({
        from: getRawTime(value[0]),
        to: getRawTime(value[1])
      })
    : null;

  const classes = cx("filter-component", className);

  return (
    <IncCollapsible
      className={classes}
      collapseIconName={collapseIconName}
      content={
        <IncDateRangePicker
          from={timeRange?.from?.toDate() || null}
          isClearable={true}
          key={fieldName}
          onChange={onTimeRangeChange}
          to={timeRange?.to?.toDate() || null}
        />
      }
      expandIconName={expandIconName}
      header={fieldName}
      headerActions={headerActions}
    />
  );
};

export const StringFieldsWrapper: React.FC<WrapperComponentProps> = (props: WrapperComponentProps) => {
  const { fieldName, value: eValue, aggregationValues, cardinality, onChange, className, headerActions } = props;

  const selectedOptions = useMemo(() => {
    const predicateValues = isArray(eValue) ? eValue : eValue ? [eValue] : [];
    return predicateValues.map(x => ({
      id: x,
      label: x
    }));
  }, [eValue]);

  const onValueChange = useCallback(
    (values: string[]) => {
      const operator = values.length > 1 ? "in" : "eq";
      const value = operator === "eq" ? values[0] : values;
      onChange(fieldName, value, operator);
    },
    [fieldName, onChange]
  );

  const classes = cx("filter-component", className);

  const displayComponent = useMemo(() => {
    const cardinalityVal = parseInt(cardinality?.value, 10) || 5;
    if (cardinalityVal <= 10) {
      const onSelection = (selection: ButtonGroupOption | ButtonGroupOption[]) => {
        onValueChange((selection as ButtonGroupOption[]).map(x => x.id));
      };
      const options: ButtonGroupOption[] = (aggregationValues || []).map(x => ({
        id: x.key,
        label: x.key
      }));

      const component =
        options.length > 0 ? (
          <ButtonSelectionGroup
            classNames={{
              button: "grp-btn",
              selected: "grp-btn-selected"
            }}
            isMulti={true}
            key={fieldName}
            onChange={onSelection}
            options={options}
            selectedOptions={selectedOptions}
          />
        ) : (
          <span className="inc-text-subtext">No data found</span>
        );

      return (
        <IncCollapsible
          className={classes}
          collapseIconName={collapseIconName}
          content={component}
          expandIconName={expandIconName}
          header={fieldName}
          headerActions={headerActions}
        />
      );
    } else {
      const onSelection = (opts: IncSelectOption[]) => onValueChange(opts.map(x => x.value));
      const selectOptions = aggregationValues.map(x => ({
        label: x.key,
        value: x.key
      }));
      const selected = selectedOptions.map(x => ({
        label: x.label,
        value: x.id
      }));
      const numSelected = selected.length;

      const filtersToShow = selected
        .slice(0, 2)
        .map(f => f.label)
        .join(", ");
      const diff = numSelected - 2;
      const suffix = diff > 0 ? `& ${diff} more` : "";
      const placeholder = filtersToShow + suffix;

      return (
        <IncCollapsible
          className={classes}
          collapseIconName={collapseIconName}
          content={
            <IncMultiSelectCheckBox
              defaultOptions={selected}
              hideOptionsCount
              onChange={onSelection}
              options={selectOptions}
              placeholder={placeholder}
              renderInCard
              skipAllOption
            />
          }
          expandIconName={expandIconName}
          header={fieldName}
          headerActions={headerActions}
        />
      );
    }
  }, [aggregationValues, cardinality?.value, classes, fieldName, headerActions, onValueChange, selectedOptions]);

  return displayComponent;
};

const BooleanFieldWrapper: React.FC<WrapperComponentProps> = (props: WrapperComponentProps) => {
  const { fieldName, value: eValue, onChange, className, headerActions } = props;
  const classes = cx("filter-component", className);
  const options: ButtonGroupOption[] = ["true", "false"].map(x => ({
    id: x,
    label: x
  }));

  const selectedOptions = useMemo(() => {
    const predicateValues = isArray(eValue) ? eValue : eValue ? [eValue] : [];
    return predicateValues.map(x => ({
      id: x,
      label: x
    }));
  }, [eValue]);

  const onSelection = useCallback(
    (selection: ButtonGroupOption | ButtonGroupOption[]) => {
      const selOption = selection as ButtonGroupOption;
      onChange(fieldName, selOption.id, "eq");
    },
    [fieldName, onChange]
  );

  return (
    <IncCollapsible
      className={classes}
      collapseIconName={collapseIconName}
      content={
        <ButtonSelectionGroup
          classNames={{
            button: "grp-btn",
            selected: "grp-btn-selected"
          }}
          key={fieldName}
          onChange={onSelection}
          options={options}
          selectedOptions={selectedOptions}
        />
      }
      expandIconName={expandIconName}
      header={fieldName}
      headerActions={headerActions}
    />
  );
};
