export const getRollingFunctionForAgg = (aggregator: string) => {
  if (aggregator === "count") {
    return "sum";
  }

  if (aggregator === "distinctCount") {
    return "sum";
  }

  return aggregator;
};
