import { IncModal } from "@inception/ui";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import DashboardImpl from "../../model-impl/DashboardImpl";
import { AddMetricModalWithVizSelection } from "../../../biz-entity/AddMetric/AddMetricWithVizSelection";
import { LoadingSpinner } from "../../../components";
import {
  logger,
  useFetchEventTypesForAddWidget,
  useTenantConfig,
  useTimeRange,
  useVerticalConfig,
  Visualisations
} from "../../../core";
import {
  BizEntityType,
  DemoDataParams,
  EntityMeta,
  WidgetConfigDTO,
  WidgetConfigUtils
} from "../../../services/api/explore";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { CatalogWidgetImpl } from "../../widgets/Catalog/models";
import { widgetRegistry } from "../../widgets/WidgetRegistry";
import { dashboardUtils } from "../DashboardUtils";

interface Props {
  open: boolean;
  onClose: () => void;
  dbImpl: DashboardImpl;
  onAddWidget: () => void;

  canAddWidget?: boolean;
  cloneWidgetId?: string;
}

export const AddCatalogWidget: FC<Props> = props => {
  const { onClose, open, dbImpl, onAddWidget, canAddWidget: pCanAddWidget, cloneWidgetId } = props;

  const onAddWidgetInternal = useCallback(
    (widgetImpl: CatalogWidgetImpl) => {
      const model = widgetImpl.getSaveModel();
      const defLayout =
        dbImpl.getLayoutById(cloneWidgetId) || widgetRegistry.getPropsByWidgetId("catalog").dimensions();
      const layout = dashboardUtils.getBestFitLayout(dbImpl.layout, {
        h: defLayout.h,
        w: defLayout.w
      });
      dbImpl.addWidget(model, layout);
      onAddWidget();
    },
    [cloneWidgetId, dbImpl, onAddWidget]
  );

  const canAddWidget = pCanAddWidget ?? dbImpl?.meta?.edit ?? false;

  const { timeRange } = useTimeRange();
  const { fromMillis, toMillis } = useMemo(() => timeRangeUtils.getMillisFromTimeRange(timeRange), [timeRange]);

  const { tenantConfigState } = useTenantConfig();
  const { demoTenant } = tenantConfigState || {};

  const { verticalConfig } = useVerticalConfig();
  const { companyName, useCaseId, verticalId, subVerticalId } = verticalConfig || {};
  const demoDataParams = useMemo<DemoDataParams>(
    () => ({
      generateDemoData: demoTenant,
      companyName,
      usecase: useCaseId,
      subVertical: subVerticalId,
      vertical: verticalId
    }),
    [companyName, demoTenant, subVerticalId, useCaseId, verticalId]
  );

  const { error, exploreUserservices, fetchExploreUserservices, isError, isFetching } =
    useFetchEventTypesForAddWidget(demoDataParams);

  const eventTypeInfos = useMemo(
    () =>
      (exploreUserservices || []).map(
        (entry): EntityMeta => ({
          ...entry
        })
      ),
    [exploreUserservices]
  );

  useEffect(() => {
    if (canAddWidget) {
      fetchExploreUserservices(fromMillis, toMillis);
    }
  }, [canAddWidget, fetchExploreUserservices, fromMillis, toMillis]);

  useEffect(() => {
    if (isError) {
      logger.error("AddCatalogWidgetModal", "Error fetching userservices", error);
    }
  }, [error, isError]);

  const { defWidgetConfigDto, presetViz, metricsVisualOrder, defWidgetName, defWidgetDescription } = useMemo(() => {
    let defWidgetConfigDto: WidgetConfigDTO;
    let presetViz: Visualisations;
    let metricsVisualOrder: string[];
    let defWidgetName: string;
    let defWidgetDescription: string;

    if (cloneWidgetId) {
      const cloneWidget = dbImpl.widgets.find(x => x.id === cloneWidgetId);
      defWidgetName = `[CLONE] ${cloneWidget.title}`;
      defWidgetDescription = cloneWidget.description || "";

      if (cloneWidget.type === "catalog") {
        const catalogWidget = cloneWidget as CatalogWidgetImpl;
        if (catalogWidget.queryConfig.sourceQueryConfig.queryType === "widgetConfig") {
          const { widgetConfig } = catalogWidget.queryConfig.sourceQueryConfig.widgetResponse;
          if (widgetConfig) {
            const { widgetConfigDto: clWidgetConfigDto, metricIdLookup } =
              WidgetConfigUtils.getWidgetConfigClone(widgetConfig);
            defWidgetConfigDto = clWidgetConfigDto;
            presetViz = catalogWidget.properties.visualisation || Visualisations.table;
            metricsVisualOrder = catalogWidget.metricsVisualOrder?.map(metricId => metricIdLookup[metricId]) || [];
          }
        }
      }
    }

    return {
      defWidgetConfigDto,
      metricsVisualOrder,
      presetViz,
      defWidgetName,
      defWidgetDescription
    };
  }, [cloneWidgetId, dbImpl.widgets]);

  const entityTypeId = defWidgetConfigDto?.bizEntityType || "";
  const entityTypeInfo = useMemo<BizEntityType>(
    () => ({
      count: 0,
      entityTypeId,
      metadata: {},
      name: entityTypeId
    }),
    [entityTypeId]
  );

  return (
    <>
      {canAddWidget && open && (
        <>
          {!isFetching && (
            <AddMetricModalWithVizSelection
              defWidgetConfigDto={defWidgetConfigDto}
              defWidgetDescription={defWidgetDescription}
              defWidgetName={defWidgetName}
              disableAddToDashboard
              entityTypeInfo={entityTypeInfo}
              eventTypeInfos={eventTypeInfos}
              metricsVisualOrder={metricsVisualOrder}
              onAddWidget={onAddWidgetInternal}
              onClose={onClose}
              open
              presetViz={presetViz}
            />
          )}
          {isFetching && (
            <IncModal
              onClose={onClose}
              show
              size="sm"
              titleText="Add Widget"
            >
              <LoadingSpinner
                className="padding24 inc-text-subtext-medium"
                titleText="Fetching Event Types..."
              />
            </IncModal>
          )}
        </>
      )}
    </>
  );
};
