import { defaultsDeep, isEqual } from "lodash";
import { SelectorSpec, UsageJourneyConfig } from "../../../../services/api/explore";
import BaseWidgetImpl from "../../../model-impl/BaseWidgetImpl";
import FunnelWidgetModel, { FunnelWidgetProperties } from "./model";

const getDefaults = (): Partial<FunnelWidgetModel> => ({
  title: "New Funnel",
  properties: {
    sliceTags: null,
    selectedFilters: [],
    disableCompare: true
  }
});

export default class FunnelWidgetImpl extends BaseWidgetImpl implements FunnelWidgetModel {
  type: "funnel";
  funnelId: string;
  properties: FunnelWidgetProperties;

  private funnelConfig: UsageJourneyConfig;
  private bizEntityType: string;

  constructor(model: Partial<FunnelWidgetModel>, meta?: Record<string, any>) {
    super(model, meta);
    this.assign(model);
  }

  assign(model: Partial<FunnelWidgetModel>) {
    const bcModel: Partial<FunnelWidgetModel> = defaultsDeep({ type: "funnel" }, model, getDefaults());
    super.assign(bcModel);

    const { properties, funnelId } = bcModel;

    this.properties = {
      ...properties
    };
    this.funnelId = funnelId;
  }

  setFunnelConfig(funnelConfig: UsageJourneyConfig) {
    if (funnelConfig) {
      this.funnelConfig = funnelConfig;
      this.bizEntityType = funnelConfig.bizEntityType;
    }
  }

  getFunnelConfig() {
    return this.funnelConfig;
  }

  getBizEntityType() {
    return this.bizEntityType;
  }

  setSelectedFilters(selectedFilters: SelectorSpec["filters"]) {
    this.properties.selectedFilters = !isEqual(this.properties.selectedFilters, selectedFilters)
      ? selectedFilters
      : this.properties.selectedFilters;
  }

  setSliceTags(sliceTags: string[]) {
    this.properties.sliceTags = !isEqual(this.properties.sliceTags, sliceTags) ? sliceTags : this.properties.sliceTags;
  }
}
