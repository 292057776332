import React, { useEffect, useMemo, useState } from "react";
import { IncLoadingSpinner, IncPill } from "@inception/ui";
import { CohortConfig, exploreApiService } from "../../services/api/explore";
import { VerticallyCenteredRow } from "../flex-components";
import { logger } from "../../core";

interface CohortPillProps {
  cohortConfig?: CohortConfig;
  cohortId: string;
  entityTypeId: string;
}

export const CohortPill = (props: CohortPillProps) => {
  const { entityTypeId, cohortId: selectedCohortId, cohortConfig: pCohortConfig } = props;

  const [isFetching, setIsFetching] = useState(!pCohortConfig);
  const [cohortConfig, setCohortConfig] = useState<CohortConfig>(pCohortConfig);

  useEffect(() => {
    async function fetchData() {
      if (!pCohortConfig) {
        setIsFetching(true);

        try {
          const cohortConfigResponse = await exploreApiService.getCohortConfig(selectedCohortId, entityTypeId);
          if (cohortConfigResponse) {
            setCohortConfig(cohortConfigResponse?.cohortDefinition);
          }
        } catch (error) {
          logger.error("CohortPill", "Error fetching cohort config:", error);
        } finally {
          setIsFetching(false);
        }
      }
    }

    fetchData();
  }, [entityTypeId, pCohortConfig, selectedCohortId]);

  const selectedCohortName = useMemo(() => {
    if (isFetching) {
      return <IncLoadingSpinner label="" />;
    }

    if (cohortConfig) {
      return cohortConfig?.name;
    }

    return selectedCohortId;
  }, [cohortConfig, isFetching, selectedCohortId]);

  const selectedCohortLabel = useMemo(
    () => (
      <VerticallyCenteredRow>
        {entityTypeId}.cohort = {selectedCohortName}
      </VerticallyCenteredRow>
    ),
    [entityTypeId, selectedCohortName]
  );

  return <IncPill label={selectedCohortLabel} />;
};
