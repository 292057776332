import { cloneDeep, defaults, isArray, set } from "lodash";
import BaseImpl from "../../core/BaseImpl";
import BaseWidgetModel, {
  CommonWidgetOptions,
  CommonWidgetProperties,
  getDefaultComparisonModel,
  Query,
  WidgetComparisonModel,
  WidgetMeta,
  WidgetType
} from "../models/BaseWidgetModel";
import appConfig from "../../../appConfig";
import { generateId, TimeRange } from "../../core";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import VariableModel from "../models/VariableModel";
import { variableUtils } from "../variables";
import VariableImpl from "./VariableImpl";

const getDefaults = (): BaseWidgetModel => ({
  id: generateId(),
  title: "New timeseries",
  type: "timeseries",
  description: "",
  datasource: appConfig.prometheusDatasource,
  queries: [],
  background: "",
  meta: {
    edit: true,
    compare: false,
    hideHeader: false,
    resizable: true
  },
  comparisonModel: getDefaultComparisonModel(),
  properties: {
    background: "#ffffff",
    description: "",
    borderLess: false,
    transparent: false
  },
  downsample: "auto"
});

export default class BaseWidgetImpl extends BaseImpl<BaseWidgetModel> implements BaseWidgetModel {
  id: string;
  title: string;
  type: WidgetType;
  description: string;
  properties: CommonWidgetProperties;

  datasource: string;
  queries: Query[];
  options: CommonWidgetOptions;
  background: string;
  variables: VariableImpl[];

  timeRange: TimeRange;
  downsample: string;

  meta: WidgetMeta;
  comparisonModel: WidgetComparisonModel;

  style: React.CSSProperties;

  constructor(model: Partial<BaseWidgetModel>, metaOverride?: Partial<WidgetMeta>) {
    super();
    this.assign(model, metaOverride);
  }

  protected assign(model: Partial<BaseWidgetModel>, metaOverride?: Partial<WidgetMeta>) {
    const wModel: BaseWidgetModel = {} as BaseWidgetModel;
    defaults(wModel, model, getDefaults());

    const {
      id,
      title,
      type,
      datasource,
      queries,
      meta,
      options,
      style,
      comparisonModel,
      properties,
      variables,
      timeRange,
      downsample,
      description,
      background
    } = wModel;

    const {
      edit = true,
      compare = false,
      hideHeader = false,
      hideActions = false,
      resizable = true,
      hideTimeRange = false
    } = {
      ...meta,
      ...(metaOverride || {})
    };

    this.options = options;

    this.id = id;
    this.title = title;
    this.type = type;
    this.description = description || "";

    this.datasource = datasource;
    this.queries = queries;

    this.variables = [];
    variables?.map(v => this.addVariable(v));

    this.timeRange = timeRange?.raw ? timeRangeUtils.getTimeRangeFromRaw(timeRange.raw) : null;
    this.downsample = downsample || "auto";
    this.comparisonModel = comparisonModel;

    this.style = style;
    this.properties = properties;
    this.background = background;

    this.meta = {
      edit,
      compare,
      hideHeader,
      resizable,
      hideActions,
      hideTimeRange
    };
  }

  update(model: Partial<BaseWidgetModel>) {
    this.assign(model);
  }

  setQueries(queries: Query[]) {
    this.queries = queries;
  }

  setDatasource(datasource: string) {
    if (this.datasource !== datasource) {
      this.datasource = datasource;
      this.queries = [];
    }
  }

  addVariable(variable: VariableModel) {
    const typeVariable = variableUtils.getVariableByType(variable?.type);
    typeVariable.update(variable);
    this.variables.push(typeVariable);
  }

  setVariables(variables: VariableImpl[]) {
    this.variables = variables;
  }

  setTimeRange(timeRange: TimeRange) {
    this.timeRange = timeRange;
  }

  getSaveModel(): BaseWidgetModel {
    const model: any = {};
    const getModelValue = (value: any) =>
      value.getSaveModel ? value.getSaveModel() : typeof value === "object" ? cloneDeep(value) : value;

    for (const key in this) {
      const value = this[key] as any;
      if (this.hasOwnProperty(key) && value) {
        let v: any;
        if (isArray(value)) {
          v = value.map(val => getModelValue(val));
        } else {
          v = getModelValue(value);
        }

        set(model, key, v);
      }
    }

    if (model.options) {
      delete model.options.syncTooltip;
    }

    return model as BaseWidgetModel;
  }

  getSafeDefaults(): BaseWidgetModel {
    return this.getSaveModel();
  }
}
