import { cx } from "emotion";
import React, { useMemo } from "react";
import { CurrencyType } from "@inception/ui/src/utils";
import { IncPercentRenderer } from "@inception/ui/src/components/ChangeRenderer";
import timeRangeUtils from "../utils/TimeRangeUtils";
import { DataType } from "../core";
import { dataTypeManager } from "../utils";

function singleStatFormatter(value: number, dataType: DataType, compact: boolean, currencyType: CurrencyType): string {
  const { getFormattedValue } = dataTypeManager.getDataTypeDescriptor(dataType) || {};

  if (getFormattedValue) {
    getFormattedValue(value, {
      numberFormatOptions: {
        compact,
        currencyType
      }
    });
  }

  return value?.toFixed(2) || "";
}

interface Props {
  data: number;

  // All Optional parameters below this line
  dataType?: DataType;
  currencyType?: CurrencyType;
  compareData?: number;
  compareTimeMillis?: number;
  compareTimeStr?: string;
  dataClass?: string;
  prettyData?: boolean;
  dataFormatter?: (data: number, type: DataType) => string;
  helpComponent?: JSX.Element;
  details?: string;
  alwaysShowCompare?: boolean;
}

export const SingleStatWithCompare: React.FC<Props> = props => {
  const {
    data,
    dataType = "NUMBER",
    currencyType = "USD",
    dataFormatter,
    prettyData = false,
    dataClass = "inc-text-header",
    compareData = data,
    compareTimeMillis = 0,
    helpComponent,
    compareTimeStr: pCompareTimeStr = "",
    alwaysShowCompare = false
  } = props;

  const dataStr = useMemo(
    () =>
      dataFormatter ? dataFormatter(data, dataType) : singleStatFormatter(data, dataType, prettyData, currencyType),
    [dataFormatter, data, dataType, prettyData, currencyType]
  );

  const compareStr = useMemo(
    () =>
      dataFormatter
        ? dataFormatter(compareData, dataType)
        : singleStatFormatter(compareData, dataType, prettyData, currencyType),
    [dataFormatter, compareData, dataType, prettyData, currencyType]
  );

  const compareTimeStr = useMemo(() => {
    if (pCompareTimeStr) {
      return pCompareTimeStr;
    }
    let compareStr = timeRangeUtils.humanizeDuration(compareTimeMillis, true);
    compareStr = `last ${compareStr}`;
    return compareStr;
  }, [compareTimeMillis, pCompareTimeStr]);

  return (
    <div className={cx("inc-flex-column inc-flex-grow", dataClass)}>
      <div className="inc-flex-row">
        <div className="inc-text-lead-bold">{dataStr}</div>
        <div className="inc-flex-row marginLt16 inc-flex-self-center">
          {/* TODO: Replace the below arrow related code with IncUpArrow and IncDownArrow */}

          {data > compareData && (
            <IncPercentRenderer
              direction={"up"}
              total={compareData}
              value={data - compareData}
            />
          )}

          {data < compareData && (
            <IncPercentRenderer
              direction={"down"}
              total={compareData}
              value={compareData - data}
            />
          )}

          {data === compareData && <>-</>}
        </div>
        {helpComponent}
      </div>
      <div className="comparison-sub-text">
        {(compareData !== data || alwaysShowCompare) && `Compared to ${compareStr} ${compareTimeStr}`}
      </div>
    </div>
  );
};
