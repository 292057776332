import React, { FC } from "react";
import { BizDataQuery, FieldPickerContextDTO, ImpactedWidget } from "../../../../../../platform/services/api/explore";
import QueryEditorPopper from "./QueryEditorPopper";

type Props = {
  bizDataQuery: BizDataQuery;
  onChange: (bizDataQuery: BizDataQuery) => void;
  impactedWidgets?: ImpactedWidget[];
  pickerContextDto: FieldPickerContextDTO;
  onLoadMetrics?: (loading: boolean) => void;
};

const QueryEditor: FC<Props> = props => {
  const { bizDataQuery, onChange, impactedWidgets, pickerContextDto, onLoadMetrics } = props;
  return (
    <div>
      <QueryEditorPopper
        bizDataQuery={bizDataQuery}
        impactedWidgets={impactedWidgets}
        onChange={onChange}
        onLoadMetrics={onLoadMetrics}
        pickerContextDto={pickerContextDto}
      />
    </div>
  );
};

export default QueryEditor;
