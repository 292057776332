import React, { FC } from "react";
import { OpCreationConfig } from "../../../services/api/operationalise";
import { noOp } from "../../../utils";
import { OpSimulationsList } from "../simulation";
import { OpV3Editor } from "./OpV3Editor";
import { OpV3Results } from "./OpV3Results";
import { OpV3TabBar } from "./OpV3TabBar";

interface Props {
  switchToActionsView: () => void;
  onSaveOrUpdate: (opConfigId: string, opCreationConfig: OpCreationConfig) => void;
  onSimulate: (opConfigId: string) => void;
  onOverrideOpCreationConfig: (opConfig: OpCreationConfig) => void;
  setSaveOrUpdateInProgress: (inProgress: boolean) => void;

  opConfigExists: boolean;
  defaultNewConfig: OpCreationConfig;
  skipSaveOrUpdate?: boolean;

  readOnly: boolean;
  hideListView: boolean;
  numActions: number;

  monitorBtnLabel?: string;

  children?: JSX.Element;
}

export const OpV3ConditionsWrapper: FC<Props> = props => {
  const {
    setSaveOrUpdateInProgress,
    switchToActionsView,
    defaultNewConfig,
    onSaveOrUpdate,
    onSimulate,
    onOverrideOpCreationConfig,
    opConfigExists,
    readOnly,
    hideListView,
    numActions,
    skipSaveOrUpdate = false,
    children,
    monitorBtnLabel
  } = props;

  return (
    <>
      <OpV3TabBar
        isActionsView={false}
        isConditionsView
        numActions={numActions}
        switchToActionsView={switchToActionsView}
        switchToConditionsView={noOp}
      />

      {children}

      <div className="operationalise-v3--content">
        {!hideListView && (
          <div className="operationalise-v3--condition-list">
            <OpSimulationsList
              defaultNewConfig={defaultNewConfig}
              onOverride={onOverrideOpCreationConfig}
            />
          </div>
        )}
        <div
          className="operationalise-v3--editor"
          data-list-hidden={hideListView}
        >
          {opConfigExists && (
            <>
              {!readOnly && (
                <OpV3Editor
                  monitorBtnLabel={monitorBtnLabel}
                  onSaveOrUpdate={onSaveOrUpdate}
                  onSimulate={onSimulate}
                  setSaveOrUpdateInProgress={setSaveOrUpdateInProgress}
                  skipSaveOrUpdate={skipSaveOrUpdate}
                />
              )}
              {readOnly && (
                <OpV3Results
                  onSaveOrUpdate={onSaveOrUpdate}
                  setSaveOrUpdateInProgress={setSaveOrUpdateInProgress}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
