import { IncButton, IncFaIcon, IncModal, IncModalProps, ISaxIcon } from "@inception/ui";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { uniq } from "lodash";
import {
  getBizEventSourceRouteStringToNavigate,
  getBizKnowledgeBaseRouteStringToNavigate,
  getBizSystemRouteStringToNavigate
} from "../../../features/configuration/integrations/home/utils/utils";
import { LoadingSpinner, VerticallyCenteredRow } from "..";
import { getSourceTypeIcon } from "../business-entity";
import { useInceptionRoute } from "../../core";
import { routePaths } from "../../../app/RoutePaths";
import { UseCaseConfig } from "../../services/api";
import { isEntity } from "../../utils";
import { WidgetConfigUtils } from "../../services/api/explore";
import displayData from "./data.json";

interface Props {
  isUseCasesLoading: boolean;
  useCases: UseCaseConfig[];
  isConnectYourDataModalOpen: boolean;
  closeConnectYourDataModal: () => void;
}

export const ConnectYourDataModal: FC<Props> = props => {
  const { closeConnectYourDataModal, isConnectYourDataModalOpen, useCases, isUseCasesLoading } = props;

  const [events, setEvents] = useState<string[]>([]);
  const [eventToDimensionsMap, setEventToDimensionsMap] = useState<Record<string, string[]>>({});

  const eventsAndDimensions = useMemo(() => {
    const eventToDimensionsMap: Record<string, string[]> = {};
    const events: string[] = [];
    const dimensions: string[] = [];

    useCases.forEach(useCase => {
      const { dataQueryConfigs, dataPreferences } = useCase;

      dataQueryConfigs?.forEach(dataQueryConfig => {
        if (dataQueryConfig) {
          const { underlyingInfo } = dataQueryConfig;

          const itemEvents: string[] = underlyingInfo?.involvedEventTypes || [];
          const itemDims: string[] = underlyingInfo?.involvedDimensions || [];

          itemEvents?.forEach(event => {
            eventToDimensionsMap[event] = [...new Set([...(eventToDimensionsMap[event] || []), ...(itemDims || [])])];
          });

          events.push(...itemEvents);
          dimensions.push(...itemDims);
        }
      });

      dataPreferences?.topKPIs?.bizDataQuery?.forEach(bizDataQuery => {
        // This can happen during onboarding/ create copilot flow
        if (bizDataQuery) {
          const { widgetConfig, idProps } = bizDataQuery;

          const { eventTypeId } = WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(idProps);
          const { entityFields, usFields } = widgetConfig?.mappingStatus || {};

          let kpiDimensions: string[] = [];
          entityFields?.forEach(field => field?.field?.propName && kpiDimensions.push(field?.field?.propName));
          usFields?.forEach(field => field?.field?.fieldName && kpiDimensions.push(field?.field?.fieldName));

          kpiDimensions = kpiDimensions.filter(item => Boolean(item) && item !== "eventID");

          if (eventTypeId && !isEntity(eventTypeId)) {
            events.push(eventTypeId);
            dimensions.push(...kpiDimensions);
            eventToDimensionsMap[eventTypeId] = [...(eventToDimensionsMap[eventTypeId] || []), ...kpiDimensions];
          }
        }
      });
    });

    Object.keys(eventToDimensionsMap).forEach(event => {
      eventToDimensionsMap[event] = uniq(eventToDimensionsMap[event]);
    });

    return {
      events: uniq(events),
      dimensions: uniq(dimensions),
      eventToDimensionsMap: eventToDimensionsMap
    };
  }, [useCases]);

  useEffect(() => {
    setEvents(eventsAndDimensions.events);
    setEventToDimensionsMap(eventsAndDimensions.eventToDimensionsMap);
  }, [eventsAndDimensions, isConnectYourDataModalOpen]);

  const { navigate } = useInceptionRoute();

  const onConnectSource = useCallback(
    (sourceType: string, sourceTypeId: string) => {
      if (sourceType === "BUSINESS_SYSTEM") {
        const routeStr = getBizSystemRouteStringToNavigate(sourceTypeId, "add");
        navigate(routeStr);
      } else if (sourceType === "EVENT") {
        const routeStr = getBizEventSourceRouteStringToNavigate(sourceTypeId, "add");
        navigate(routeStr);
      } else {
        const routeStr = getBizKnowledgeBaseRouteStringToNavigate(sourceTypeId, "add");
        navigate(routeStr);
      }
    },
    [navigate]
  );

  const onConnectSourceClick = useCallback(
    (sourceType: string, sourceTypeId: string) => {
      if (!sourceTypeId.length) {
        return;
      }
      onConnectSource(sourceType, sourceTypeId);
      closeConnectYourDataModal();
    },
    [closeConnectYourDataModal, onConnectSource]
  );

  const onNavigateToIntegrations = useCallback(() => {
    navigate(routePaths.integrationsAvailable, {
      reload: true
    });
    closeConnectYourDataModal();
  }, [closeConnectYourDataModal, navigate]);

  const onNavigateToSandbox = useCallback(() => {
    navigate(routePaths.sandboxDiscovery);
    closeConnectYourDataModal();
  }, [closeConnectYourDataModal, navigate]);

  const iconDataForStreaming = useMemo(
    () => (
      <div className="inc-flex-row-wrap flex-gap-20">
        {displayData?.streaming?.map((source, idx) => (
          <div
            className="inc-cursor-pointer inc-flex-row flex-gap-8"
            key={idx}
            onClick={() => onConnectSourceClick(source?.sourceType, source?.sourceTypeId)}
          >
            {getSourceTypeIcon(source?.sourceTypeId, source?.icon, "source-type-img", 22, null, null)}
            {source?.displayName}
          </div>
        ))}
      </div>
    ),
    [onConnectSourceClick]
  );

  const iconDataForLogs = useMemo(
    () => (
      <div className="inc-flex-row-wrap flex-gap-20">
        {displayData?.logs?.map((source, idx) => (
          <div
            className="inc-cursor-pointer inc-flex-row flex-gap-8"
            key={idx}
            onClick={() => onConnectSourceClick(source?.sourceType, source?.sourceTypeId)}
          >
            {getSourceTypeIcon(source?.sourceTypeId, source?.icon, "source-type-img", 25, null, null)}
            {source?.displayName}
          </div>
        ))}
      </div>
    ),
    [onConnectSourceClick]
  );

  const iconDataForOthers = useMemo(
    () => (
      <div className="inc-flex-row-wrap flex-gap-20">
        {displayData?.others?.map((source, idx) => (
          <div
            className="inc-cursor-pointer inc-flex-row flex-gap-8"
            key={idx}
            onClick={() => onConnectSourceClick(source?.sourceType, source?.sourceTypeId)}
          >
            {getSourceTypeIcon(source?.sourceTypeId, source?.icon, "source-type-img", 22, null, null)}
            {source?.displayName}
          </div>
        ))}
      </div>
    ),
    [onConnectSourceClick]
  );

  const iconDataForDimensions = useMemo(
    () => (
      <div className="inc-flex-row-wrap flex-gap-48">
        {displayData?.dimensions?.map((source, idx) => (
          <div
            className="inc-cursor-pointer inc-flex-row flex-gap-8"
            key={idx}
            onClick={() => onConnectSourceClick(source?.sourceType, source?.sourceTypeId)}
          >
            {getSourceTypeIcon(source?.sourceTypeId, source?.icon, "source-type-img", 22, null, null)}
            {source?.displayName}
          </div>
        ))}
      </div>
    ),
    [onConnectSourceClick]
  );

  const eventToDimensionsJsx = useMemo(() => {
    const eventDimMap = Object.entries(eventToDimensionsMap);

    const jsx = eventDimMap?.map((eventInfo, idx) => {
      const eventName = eventInfo?.[0];
      const dimensions = eventInfo?.[1]?.join(", ");
      const isLastElement = eventDimMap.length === idx + 1;
      const cx = `inc-flex-row flex-gap-12 paddingBt12${isLastElement ? "" : " event-dimensions-border"}`;

      return (
        <VerticallyCenteredRow
          className={cx}
          key={idx}
        >
          <VerticallyCenteredRow className="inc-flex-row flex-gap-8">
            <div className="inc-text-subtext inc-text-inactive">For the event</div>
            <div className="event-pill inc-flex-center-vertical paddingLt6 paddingTp4 paddingBt4 paddingRt6 inc-text-subtext-medium">
              {eventName}
            </div>
          </VerticallyCenteredRow>
          <div className="inc-text-subtext-medium">{dimensions}</div>
        </VerticallyCenteredRow>
      );
    });

    return jsx;
  }, [eventToDimensionsMap]);

  const actions = useMemo<IncModalProps["actions"]>(
    () => ({
      primary: {
        onClick: onNavigateToIntegrations,
        label: "Connect Your Data",
        color: "primary"
      },
      secondary: {
        onClick: onNavigateToSandbox,
        label: "Customize Data Instead",
        color: "secondary-blue"
      }
    }),
    [onNavigateToIntegrations, onNavigateToSandbox]
  );

  const titleText = useMemo(
    () => (
      <VerticallyCenteredRow>
        <VerticallyCenteredRow className="inc-text-lead flex-gap-16">
          <IncFaIcon
            className="status-brand"
            iconName="bicycle-symbol-red-rgb"
          />
          Ready to swap out sample data for the real deal?
        </VerticallyCenteredRow>
        <VerticallyCenteredRow className="marginLtAuto flex-gap-16">
          <IncButton
            color="link"
            label="Explore all integrations"
            onClick={onNavigateToIntegrations}
          />
          <ISaxIcon
            className="marginLt2 status-info"
            iconName="ArrowRight2"
            variant="Outline"
          />
        </VerticallyCenteredRow>
      </VerticallyCenteredRow>
    ),
    [onNavigateToIntegrations]
  );

  return (
    <IncModal
      actions={actions}
      className="connect-your-data-modal"
      closeOnEscape
      onClose={closeConnectYourDataModal}
      show={isConnectYourDataModalOpen}
      showClose={false}
      size="xlg"
      titleClassName="connect-your-data-modal-header"
      titleText={titleText}
    >
      <div className="connect-data-content padding24 inc-flex-column width-100 flex-gap-32">
        <div className="inc-flex-column width-100 flex-gap-16">
          <VerticallyCenteredRow className="inc-text-body">
            Events needed to bring this Copilot to life
          </VerticallyCenteredRow>

          <div className="inc-text-subtext-medium inc-flex-row flex-gap-4 marginLt12">
            {!isUseCasesLoading &&
              events.map((eventName, idx) => (
                <div
                  className="inc-text-inactive event-pill inc-flex-center-vertical paddingLt6 paddingTp4 paddingBt4 paddingRt6"
                  key={idx}
                >
                  {eventName}
                </div>
              ))}

            {isUseCasesLoading && (
              <div className="inc-text-inactive event-pill inc-flex-center-vertical paddingLt6 paddingTp4 paddingBt4 paddingRt6">
                <LoadingSpinner titleText="Fetching Events" />
              </div>
            )}
          </div>
        </div>

        <div className="inc-flex-column width-100 flex-gap-16">
          <VerticallyCenteredRow className="inc-text-body">
            Complex dimensions needed for each event
          </VerticallyCenteredRow>
          <div className="inc-flex-column marginLt16 flex-gap-10">{eventToDimensionsJsx}</div>
        </div>

        <div className="inc-flex-column width-100 flex-gap-16">
          <VerticallyCenteredRow className="flex-gap-8">
            <VerticallyCenteredRow className="inc-text-body">
              Integrations to bring your events to life
            </VerticallyCenteredRow>
            <VerticallyCenteredRow className="inc-text-subtext inc-text-inactive">
              Bicycle will automatically map above events and dimensions if you point it to the source
            </VerticallyCenteredRow>
          </VerticallyCenteredRow>

          <div className="inc-flex-row flex-gap-24">
            <div className="connect-source-container align-items-start inc-flex-row flex-gap-16 inc-flex-column">
              <VerticallyCenteredRow className="inc-text-subtext">Streaming</VerticallyCenteredRow>
              {iconDataForStreaming}
            </div>

            <div className="connect-source-container align-items-start inc-flex-row flex-gap-16 inc-flex-column">
              <VerticallyCenteredRow className="inc-text-subtext">Logs</VerticallyCenteredRow>
              {iconDataForLogs}
            </div>

            <div className="connect-source-container align-items-start inc-flex-row flex-gap-16 inc-flex-column">
              <VerticallyCenteredRow className="inc-text-subtext">Others</VerticallyCenteredRow>
              {iconDataForOthers}
            </div>
          </div>
        </div>

        <div className="inc-flex-column width-100 flex-gap-16">
          <VerticallyCenteredRow className="inc-text-body">
            Integrations to bring your complex dimensions to life
          </VerticallyCenteredRow>
          <div className="connect-source-container align-container-items inc-flex flex-gap-16">
            <div className="inc-text-inactive inc-text-subtext marginBt16">
              Complex dimensions are dimensions augmented with additional metadata.
            </div>
            {iconDataForDimensions}
          </div>
        </div>
      </div>
    </IncModal>
  );
};
