import { IncButton, IncClickAwayPopper, IncLoadingSpinner } from "@inception/ui";
import { isArray } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { NumericRangeSlider, NumericRangeSliderOperators, VerticallyCenteredRow } from "../../../../components";
import { fieldPickerApiService, UserServiceField } from "../../../../services/api/explore";
import { EntityAggregationMeta } from "../../../../services/api/types";
import { FieldPickerUtils } from "../../../../utils";

interface Props {
  anchorEl: HTMLElement;
  onClose: () => void;
  show: boolean;
  values: string[];
  field: UserServiceField;
  op: string;
  onChange: (values: string[], op: string) => void;
}

export const EventFieldNumericRangePopper = (props: Props) => {
  const { anchorEl, show, values: eValues, op: initOp, onChange, onClose, field } = props;

  const [aggregationInfo, setAggregationInfo] = useState<EntityAggregationMeta>();
  const [fetchingAggStats, setFetchingAggStats] = useState(false);

  const fetchAggStats = useCallback(async () => {
    setFetchingAggStats(true);
    const aggStatResponse = await fieldPickerApiService.getFieldAggregations([field]);
    if (aggStatResponse.data) {
      const aggregation = aggStatResponse?.data?.fieldAggregation?.[0]?.aggregation;
      setAggregationInfo(aggregation);
    }
    setFetchingAggStats(false);
  }, [field]);

  useEffect(() => {
    fetchAggStats();
  }, [fetchAggStats]);

  const { stats } = aggregationInfo || {};
  const { min = 1, max = 1000 } = stats || {};

  const fieldValue = isArray(eValues) ? eValues.map(x => parseFloat(x)) : eValues ? parseFloat(eValues) : null;

  const [localValue, setLocalValue] = useState(fieldValue);
  const [localOp, setLocalOp] = useState<NumericRangeSliderOperators>(initOp as NumericRangeSliderOperators);

  const onValChange = useCallback((value: number | number[], operator: NumericRangeSliderOperators) => {
    setLocalValue(value);
    setLocalOp(operator);
  }, []);

  const fieldName = FieldPickerUtils.getUserServiceFieldLabel(field);

  const applyFilters = useCallback(() => {
    const values = isArray(localValue) ? localValue : [localValue];
    onChange(
      values.map(x => x.toString()),
      localOp
    );
  }, [localOp, localValue, onChange]);

  const onClear = useCallback(() => {
    onChange([], "=");
  }, [onChange]);

  return (
    <IncClickAwayPopper
      anchorEl={anchorEl}
      onClickAway={onClose}
      placement="bottom-end"
      show={show}
    >
      <div className="field-value-picker inc-flex-column flex-gap-12">
        {fetchingAggStats && <IncLoadingSpinner />}
        {!fetchingAggStats && (
          <NumericRangeSlider
            label={fieldName}
            max={max}
            min={min}
            onChange={onValChange}
            operator={localOp as NumericRangeSliderOperators}
            subType={null}
            value={(localValue || min) as [number, number]}
          />
        )}
        <VerticallyCenteredRow className="lower-section flex-gap-8">
          <IncButton
            color="primary"
            onClick={applyFilters}
          >
            Apply Filters
          </IncButton>
          <IncButton
            color="secondary-red"
            onClick={onClear}
          >
            Clear
          </IncButton>
        </VerticallyCenteredRow>
      </div>
    </IncClickAwayPopper>
  );
};
