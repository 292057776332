import { TemplateCanvasSection } from "../../../../../services/api/operationalise";
import { BLOCK_DATA_SECTION_KEY, isSectionValid } from "../utils";
import { CustomBlockState, CustomBlockProps } from "./types";

type State = Pick<
  CustomBlockState,
  "section" | "sectionElement" | "sectionProperties" | "displayErrorMessage" | "errors" | "isValid"
>;
type ValidateState = Pick<CustomBlockState, "errors" | "displayErrorMessage" | "isValid">;
type ValidateStateArgs = Pick<CustomBlockState, "section" | "sectionProperties">;

export const getCommonStateFromProps = (props: CustomBlockProps): State => {
  const section = getSectionFromProps(props);

  const { template } = props.blockProps;
  const sectionElement = template?.sectionElementsMap?.[section?.sectionElementId];

  const sectionProperties = sectionElement?.prop || [];

  const errorState = getErrorStateForSection({
    section,
    sectionProperties
  });

  return {
    section,
    sectionElement,
    sectionProperties,
    ...errorState
  };
};

export const getErrorStateForSection = (partState: ValidateStateArgs): ValidateState => {
  const { section, sectionProperties } = partState;

  const { isValid, errors } = isSectionValid(section, sectionProperties);

  const errText = errors.join("\n");

  return {
    displayErrorMessage: errText,
    errors,
    isValid
  };
};

const getSectionFromProps = (props: CustomBlockProps) => {
  const blockData = props.block.getData();
  const section = blockData.get(BLOCK_DATA_SECTION_KEY) as TemplateCanvasSection;
  return section;
};
