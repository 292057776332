import { cloneDeep, size } from "lodash";
import { OpCreationConfig, BizIdProps, Schedule, OpSchedule } from "../../services/api/operationalise";
import { getDefaultOpConfig, getDefaultThreshold } from "../../operationalise-v2/utils";
import {
  WidgetResponseDTO,
  SliceSpec,
  UserServiceField,
  BuildingBlockConfig,
  BizDataQuery,
  MetricUserServiceFilters,
  ExploreEntityFilter,
  UserServiceFieldSlice,
  UserServiceFilterExpression
} from "../../services/api/explore";
import {
  defRollingFreq,
  defRollingFunction,
  UI_SCHEDULE_KEY,
  SCHEDULE_TYPES,
  UI_ENTITY_AGGREGATED_KEY
} from "../../operationalise-v2/constants";
import { shouldIncludeMetricInDataFetch, convertToSliceSet, getWidgetConfigFromDto } from "../../utils/ExploreUtils";
import { isValidCohortId } from "../../utils";
import { MetricAggregatorExpanded } from "../../core";
import { USFieldWidgetUtils } from "../../dashboard/widgets/USField/USFieldWidgetUtils";
import { getRollingFunctionForAgg } from "../../operationalise-v2/components/utils";
import { Op10zeSourceConfig } from "./types";

export const getOpConfig = (
  sourceConfig: Op10zeSourceConfig,
  eventTypeId: string,
  eventTypeName: string,
  entityTypeId: string,
  buildingBlockConfigId: string,
  cohortId: string,
  entityFilters: ExploreEntityFilter[],
  eventFilters: MetricUserServiceFilters,
  implicitSlice: UserServiceFieldSlice
): OpCreationConfig => {
  if (sourceConfig.sourceType === "usField") {
    return getOpConfigForUSFieldSource(
      sourceConfig.usField,
      sourceConfig.slices,
      sourceConfig.filterExpressions,
      eventTypeId,
      eventTypeName,
      entityTypeId,
      buildingBlockConfigId,
      cohortId,
      entityFilters,
      eventFilters,
      implicitSlice
    );
  } else if (sourceConfig.sourceType === "widgetConfig") {
    return getOpConfigForWidgetSource(
      sourceConfig.widgetResponseDTO,
      eventTypeId,
      entityTypeId,
      cohortId,
      entityFilters,
      eventFilters
    );
  } else if (sourceConfig.sourceType === "opConfig") {
    return getOpConfigForOpConfigSource(sourceConfig.opCreationConfig);
  }

  return getDefaultOpConfig();
};

const getOpConfigForWidgetSource = (
  widgetResponseDTO: WidgetResponseDTO,
  eventTypeId: string,
  entityTypeId: string,
  cohortId: string,
  entityFilters: ExploreEntityFilter[],
  eventFilters: MetricUserServiceFilters
) => {
  const opConfig = getDefaultOpConfig();
  const rollingFreq = {
    ...defRollingFreq
  };

  let rollingFunction = defRollingFunction;
  const opSchedule = {
    ...opConfig.opCreationConfigDef.schedule
  };

  if (widgetResponseDTO) {
    const { widgetConfig: widgetConfigDto, widgetId, querySchema } = widgetResponseDTO;
    const { name } = widgetConfigDto;

    opConfig.name = `New Operationalize - ${name}`;
    opConfig.description = "";

    const filQuerySchema = querySchema.querySchema.filter(qs => shouldIncludeMetricInDataFetch(qs));
    const firstQuerySchema = filQuerySchema[0];
    const { sliceSet, metricId, metricName, defaultTimeAgg } = firstQuerySchema || {};

    if (defaultTimeAgg) {
      rollingFunction = defaultTimeAgg;
    }

    const sliceSpec: SliceSpec = {
      selectorSpec: {
        filters: []
      },
      sliceSet,
      metricId
    };

    opConfig.opCreationConfigDef.bizDataQuery = {
      id: widgetId,
      sliceSpec,
      labels: {
        name: metricName,
        entityTypeId: entityTypeId
      }
    };

    //delete the widget id from data query when the widget id doesn't exist & set the widgetConfig from Dto
    if (!widgetId) {
      delete opConfig.opCreationConfigDef.bizDataQuery.id;
      opConfig.opCreationConfigDef.bizDataQuery.widgetConfig = getWidgetConfigFromDto(widgetConfigDto);
    }

    if (size(entityFilters || [])) {
      opConfig.opCreationConfigDef.bizDataQuery = {
        ...opConfig.opCreationConfigDef.bizDataQuery,
        entityFilters
      };
    }

    if (size(eventFilters || {})) {
      opConfig.opCreationConfigDef.bizDataQuery = {
        ...opConfig.opCreationConfigDef.bizDataQuery,
        metricUserServiceFilters: eventFilters
      };
    }

    const idProps: BizIdProps = {
      primary: {
        bizEntityTypeId: entityTypeId,
        eventTypes: {
          userServiceInfo: [
            {
              userServiceEntityId: eventTypeId
            }
          ]
        }
      },
      secondary: {}
    };

    if (isValidCohortId(cohortId)) {
      idProps.secondary.cohortId = cohortId;
    }
    opConfig.idProps = idProps;
  }

  opConfig.opCreationConfigDef = {
    ...opConfig.opCreationConfigDef,
    rollingFreq,
    rollingFunction,
    schedule: opSchedule,
    threshold: getDefaultThreshold()
  };

  return opConfig;
};

const getOpConfigForUSFieldSource = (
  usField: UserServiceField,
  defaultSlices: UserServiceFieldSlice[],
  filterExpressions: UserServiceFilterExpression[],
  eventTypeId: string,
  eventTypeName: string,
  entityTypeId: string,
  buildingBlockConfigId: string,
  cohortId: string,
  entityFilters: ExploreEntityFilter[],
  eventFilters: MetricUserServiceFilters,
  implicitSlice: UserServiceFieldSlice
) => {
  const skipImplicitSlice = !implicitSlice;

  const sliceSpec: SliceSpec = {
    buildingBlockConfigId,
    selectorSpec: {
      filters: []
    },
    sliceSet: null
  };

  const usFieldCopy = cloneDeep(usField);
  usFieldCopy.userServices = [{ userServiceEntityId: eventTypeId }];
  const displayFieldName = USFieldWidgetUtils.getDisplayFieldName(usFieldCopy, eventTypeName);

  const { dataType, fieldName } = usFieldCopy;

  const isStringField = dataType === "STRING";
  const isEventIDField = USFieldWidgetUtils.isEventIDField(fieldName);
  const isHasErrorField = USFieldWidgetUtils.isHasErrorField(fieldName);

  if (isHasErrorField) {
    usFieldCopy.fieldName = USFieldWidgetUtils.eventIDFieldName;
    usFieldCopy.dataType = "STRING";
  }

  const aggregator: MetricAggregatorExpanded = isStringField ? "count" : "avg";
  const metricName =
    isEventIDField || isHasErrorField
      ? displayFieldName
      : isStringField
        ? `${displayFieldName} count`
        : displayFieldName;

  const opConfig = getDefaultOpConfig();

  const name = `New Operationalize - ${displayFieldName}`;
  const description = "";

  const defSlices: UserServiceFieldSlice[] = skipImplicitSlice ? [] : [implicitSlice];
  const slices: UserServiceFieldSlice[] = defSlices;
  if (aggregator) {
    const usfSlices = isEventIDField ? defSlices : defaultSlices || defSlices;

    const nonImplicitSlices = usfSlices.filter(slice => slice.tagName !== implicitSlice?.tagName);
    slices.push(...nonImplicitSlices);

    sliceSpec.sliceSet = convertToSliceSet({ slices: usfSlices });
  }

  const schedule: Schedule = isStringField
    ? {
        ...opConfig.opCreationConfigDef.schedule.schedule
      }
    : {
        startTimeEpochSecs: null,
        endTimeEpochSecs: null,
        specificScheduleConfig: {
          minute: {
            step: 1
          }
        }
      };
  const labels: OpSchedule["labels"] = isStringField
    ? { ...(opConfig.opCreationConfigDef.schedule.labels || {}) }
    : { [UI_SCHEDULE_KEY]: SCHEDULE_TYPES.everyMinute };

  const opSchedule: OpSchedule = {
    ...opConfig.opCreationConfigDef.schedule,
    schedule,
    labels
  };

  const rollingFreq = {
    ...defRollingFreq
  };

  const rollingFunction = getRollingFunctionForAgg(aggregator);

  const buildingBlockConfig: BuildingBlockConfig = {
    id: buildingBlockConfigId,
    name: metricName,
    buildingBlockDef: {
      aggregator,
      name: metricName,
      fieldConfig: {
        userServiceField: usFieldCopy
      },
      filters: {
        filterExpressions: filterExpressions || [],
        bizFieldPredicates: []
      },
      sliceDef: {
        sliceSets: aggregator
          ? [
              {
                slices
              }
            ]
          : [],
        bizFields: []
      }
    },
    bizIdProps: {
      primary: {
        bizEntityTypeId: entityTypeId,
        eventTypes: {
          userServiceInfo: [
            {
              userServiceEntityId: eventTypeId
            }
          ]
        }
      },
      secondary: {}
    }
  };

  const idProps: BizIdProps = {
    primary: {
      bizEntityTypeId: entityTypeId,
      eventTypes: {
        userServiceInfo: [
          {
            userServiceEntityId: eventTypeId
          }
        ]
      }
    },
    secondary: {}
  };
  let bizDataQuery: BizDataQuery = {
    sliceSpec,
    buildingBlockConfig,
    labels: {
      name: metricName,
      entityTypeId: entityTypeId
    }
  };

  if (size(entityFilters || [])) {
    bizDataQuery = {
      ...bizDataQuery,
      entityFilters
    };
  }

  if (size(eventFilters || {})) {
    const metricUserServiceFilters: MetricUserServiceFilters = {
      [buildingBlockConfigId]: {
        expressionTree: Object.values(eventFilters)?.[0].expressionTree,
        userServiceFilters: Object.values(eventFilters)?.[0].userServiceFilters
      }
    };

    bizDataQuery = {
      ...bizDataQuery,
      metricUserServiceFilters
    };
  }

  if (isValidCohortId(cohortId)) {
    idProps.secondary.cohortId = cohortId;
    buildingBlockConfig.cohortId = cohortId;
    buildingBlockConfig.bizIdProps.secondary.cohortId = cohortId;
    bizDataQuery = {
      ...bizDataQuery,
      buildingBlockConfig,
      cohortId
    };
  }

  opConfig.name = name;
  opConfig.description = description;
  opConfig.opCreationConfigDef = {
    ...opConfig.opCreationConfigDef,
    rollingFreq,
    rollingFunction,
    bizDataQuery,
    schedule: opSchedule,
    threshold: getDefaultThreshold()
  };
  opConfig.idProps = idProps;
  opConfig.labels = {
    [UI_ENTITY_AGGREGATED_KEY]: "false"
  };

  return opConfig;
};

const getOpConfigForOpConfigSource = (opConfig: OpCreationConfig) => {
  const nOpConfig = cloneDeep(opConfig);

  const { idProps, opCreationConfigDef } = nOpConfig;
  if (!idProps?.primary?.eventTypes) {
    const { userServices } =
      opCreationConfigDef?.bizDataQuery?.buildingBlockConfig?.buildingBlockDef?.fieldConfig?.userServiceField || {};

    if (userServices) {
      nOpConfig.idProps = {
        ...(idProps || {}),
        primary: {
          ...(idProps?.primary || {}),
          eventTypes: {
            userServiceInfo: userServices.slice(0, 1)
          }
        }
      } as BizIdProps;
    }
  }
  return nOpConfig;
};
