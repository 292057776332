import { KindDescriptor, PrimKindMap } from "../../../components/data-type";
import { Error } from "../../../core";
import { FieldPrimType } from "../../../core/data/types/DataTypes";
import { FieldTransformationConfig, TransformType } from "../../../transformation/transform-config/TransformConfig";
import { ConfiguredStream, UserServiceFieldDef } from "../event-mapping";
import { TypedObjectValue, PrimType } from "../operationalise";

export type EntityMasterMappingResponse = {
  mappings: EntityMasterMapping[];
};

export type EntityMasterMapping = {
  primaryType: EntityType;
  relatedTypes: RelatedType[];
  sourceMappings: BizFieldSourceMapping[];
  primaryEntityTypeId: string;
  userServiceMappings: UserServiceMapping[];
  destinationMappings: DestinationMapping[];
};

export type EntityType = {
  typeReference: TypeRef;
  baseTypeId: string;
  properties: Record<string, any>;
  idPropSet: IdPropSet[];
  propertiesMeta: Record<string, PropMeta>;

  // This is not returned by the backend. This is an UI only contruct generated for convenience
  fieldMappings: FieldMappings[];
};

export type FieldMappings = {
  propName: string;
  propMeta: PropMeta;
};

export type PropMeta = {
  disabled?: boolean;
  dynamic?: boolean;
  fts?: boolean;
  indexed?: boolean;
  kind: string; // this should probably be a set of items like "_str", "_number", etc
  sources?: PropertySource[];
  versioned?: boolean;
  kindDescriptor: KindDescriptor;
  writable?: boolean;
  destinations?: PropertySource[];
};

export type PropertySource = {
  type: string; // typeId e.g. connector_salesforce
  name: string; // typeName: e.g. uuid for connector_salesforce
  sourceId: string; // connector instance id
  metadata?: {
    icon?: string;
  };
};

export type IdPropSet = {
  disabled: boolean;
  prop: string[];
};

export type TypeRef = {
  category: string;
  createdTime?: string; // backend returns this a string, whoud lit be date time?
  desc: string;
  domain: string;
  id: string;
  kindId: "_entity" | "_rel" | "_config";
  typeName: string;
  updatedTime?: string; // backend returns this a string, whoud lit be date time?
  metadata?: {
    icon?: string; // currently this is the font-awesome icon name
  };
};

export type RelatedType = {
  entityType: EntityType;
  relType: RelationType;
  baseTypeRelation: boolean;
};

export type RelationType = {
  typeReference: TypeRef;
  defaultLeftEntityId: string;
  defaultRightEntityId: string;
  leftCardinality: string;
  leftEntityTypeId: string;
  leftToRightNavName: string;
  relCardinality: string;
  rightCardinality: string;
  rightEntityTypeId: string;
  rightToLeftNavName: string;
};

export type BizFieldSourceMapping = {
  sourceId: string;
  typeMapping: TypeMapping;
};

export interface DestinationMapping {
  destId: string;
  typeMapping: DestinationTypeMapping;
  lookup: Partial<LookupTypeMapping>;
}

export interface DestinationTypeMapping {
  entityTypeId: string;
  fieldMappings: Record<string, DestFieldMapping>;
}

export interface DestFieldMapping {
  valueMapping: DestValueMapping;
}

export interface DestValueMapping {
  fieldPaths: FieldPath[];
  dataTransformations: Array<FieldTransformationConfig<TransformType>>;
  ignoreError: boolean;
  type: PrimType;
}

export interface LookupTypeMapping {
  entityTypeId: string;
  streamFieldToEntityFieldMappings: Record<string, string>;
  sourceFieldToEntityFieldMappings: Record<string, string>;
}

export type TypeMapping = {
  entityTypeId: string;
  payloadPointer: PayloadPointer;
  displayNameMapping: SourceValueMapping;
  fieldMappings: Record<string, SourceFieldMapping>;
  relTypeMappings?: Record<
    string,
    {
      relTypeId: string;
      typeMapping: TypeMapping;
    }
  >;
};

export type SourceValueMapping = {
  payloadPointer?: PayloadPointer;
  fieldPaths: FieldPath[];
  transform: Transform;
  dataTransformations: Array<FieldTransformationConfig<TransformType>>;
  jsonPath: string;
  returnType: FieldPrimType;
};

export type FieldPath = {
  segment: string;
  filterExpr?: string;
  type?: FieldPrimType;
};

export type PayloadPointer = {
  paths: FieldPath[];
};

export type Transform = {
  format: string;
};

//references in un-used files, so refactoring needed when made mandatory
export type SourceFieldMapping = {
  valueMapping: SourceValueMapping;
};

export type UserServiceMapping = {
  userServiceId: string;

  // the key here coresponds to id property
  fieldMappings: Record<string, TraceFieldMapping>;
};

export type TraceFieldMapping = {
  traceFieldId: string;
  fieldTransform?: FieldTransform;
  relatedConfigurationId?: string;
};

export type FieldTransform = {
  format: string;
};

export type UserServiceField = {
  id: string;
  name: string;
  displayName: string;
  dataType: string;
};

// this is something like salesforce, gitlab, etc
export type BizSourceType = {
  typeId: string;
  typeName: string;
  typeReference: TypeRef;
};

// this is connector instances for one of the above Source type
export type BizSource = {
  sourceId: string;
  name: string;
};

export interface UiBizfieldDetails extends SourceFieldMapping {
  rootPayloadPointer: PayloadPointer;
  fieldName: string;
  displayName?: string;
}

export interface UiBizfieldDetailsWithType extends UiBizfieldDetails {
  type: keyof typeof PrimKindMap;
}

// This is not the same as the Entity object
export type UserServiceEntity = {
  discoveryTs: string;
  displayName: string;
  entityId: string;
  name: string;
};

export type ConnectorPreviewData = {
  previewData: PreviewDataObj;
};

export type PreviewDataObj = {
  sourceSchema: SourceSchema;
  jsonRecords: string[];
};

export type SourceSchema = {
  payloadPointer?: PayloadPointer;
  fields: Record<string, PreviewField>;
};

export type PreviewField = {
  payloadPointer: PayloadPointer;
  type: FieldPrimType;
  paths: FieldPath[];
  fields: Record<string, PreviewField>;
  returnType: FieldPrimType;
  jsonPath: string;
  dataTransformations?: Array<FieldTransformationConfig<TransformType>>;
};

export type TestAndDiscoverRequest = {
  sourceTypeId: string;
  connectionConfiguration: string;
};

export type TestAndDiscoverEditRequest = {
  connectionId: string;
};

export type SyncMode = "full_refresh" | "incremental" | "";

export type PrimaryKey = {
  strings: string[];
};

export type Stream = {
  name: string;
  jsonSchema: string;
  supportedSyncModes: SyncMode[];
  sourceDefinedCursor: boolean;
  defaultCursorField: string[];
  sourceDefinedPrimaryKey: PrimaryKey[];
  namespace: string;
  additionalProperties: Record<string, string>;
};

export type PreviewDataRequestV2 = {
  streamDetails: {
    connectionId: string;
    configuredStream: {
      stream: Stream;
    };
  };
};

export type ConnectorInstanceData = {
  advanceConfigurations: AdvancedConfiguration[];
  clusterContext?: Cluster;
  isDemo: boolean;
  connectionId: string;
  name: string;
  sourceType: "EVENT" | "BUSINESS_SYSTEM" | "KNOWLEDGE_BASE";
  sourceTypeId: string;
  connectionConfiguration: string;
  configuredConnectorStreamId?: string;
};

export interface ConnectorInstanceDataWithIconInfo extends ConnectorInstanceData {
  icon: string;
}

export type SaveBizSystemConnectorRequestV2 = {
  connectionId?: string;
  name: string;
  sourceTypeId: string;
  connectionConfiguration: string;
  advanceConfigurations: AdvancedConfiguration[];
  clusterContext?: Cluster;
  isDemo: boolean;
};

export type SaveBizKnowledgeBaseConnectorRequestV2 = {
  configuredConnectorStreamId: string;
  connectionId: string;
  configuredConnection: SaveKnowledgeBaseSourceConfigureConnection;
  configuredStream: ConfiguredStream;
  name?: string;

  additionalProperties?: Map<string, string>;
  scheduleJson?: string;
  sourceType?: string; // BUSINESS_SYSTEM / EVENT
  readState?: {
    data: string;
  };
};

export type SaveKnowledgeBaseSourceConfigureConnection = {
  name: string;
  sourceTypeId: string;
  connectionConfiguration: string;
  advanceConfigurations: AdvancedConfiguration[];

  connectionId?: string;
  clusterContext?: Cluster;
  scheduleCronExpr?: string;
  scheduleTimeoutMillis?: number;
  isDemo?: boolean;
  spec?: string;
  state?: string;
};

export type AdvancedConfiguration = {
  name: string;
  configuration: string;
};

export type PreviewEntitiesData = {
  typeId: string;
  entityId: string;
  props: Record<
    string,
    {
      value: {
        stringVal?: string;
        longVal?: number;
        doubleVal?: number;
        booleanVal?: boolean;
      };
      sourceVal: string;
      error: string;
    }
  >;
  displayName: {
    error: string;
    sourceVal: string;
    value: {
      stringVal: string;
    };
  };
};

export type StreamsTypeBatchData = {
  configuredConnectorStream: ConfiguredConnectorStream;
  sourceTypeDef: any; // TODO: change this to SourceTypeDef. move SourceTypeDef from event-mapping/types here to avoid circular dependency
};

export type ConfiguredConnectorStream = {
  configuredConnectorStreamId: string;
  connectionId: string;
  configuredConnection: ConfiguredConnection;
  configuredStream: ConfiguredStream;
  name?: string;

  additionalProperties?: Map<string, string>;
  scheduleJson?: string;
  sourceType?: string; // BUSINESS_SYSTEM / EVENT
  readState?: {
    data: string;
  };
};

export interface ConfiguredConnectorStreamList {
  configuredConnectorStream: ConfiguredConnectorStream[];
}

export type ConfiguredConnection = {
  advanceConfigurations: AdvancedConfiguration[];
  clusterContext?: Cluster;
  isDemo: boolean;
  connectionId: string;
  name: string;
  sourceType: "BUSINESS_SYSTEM" | "EVENT" | "KNOWLEDGE_BASE"; //move SourceType from event-mapping/types here to avoid circular dependency
  sourceTypeId: string;
  connectionConfiguration: string; // JSON
};

export interface UserServiceFieldPreviewRequest {
  fieldDef: UserServiceFieldDef;
  previewData: PreviewDataObj;
}
export interface UserServiceFieldPreviewResponse {
  previewResponses: PreviewDataResponse[];
}

export interface PreviewDataResponse {
  rawFieldValue: string;
  transformedValue: TypedObjectValue;
  error: string;
}
/*
  {
    op: percentile,
    params: [ {longVal: 95}]
  }
  { op: make_set }
 */

export type Cluster = {
  name: string;
  labels: Record<string, string>;
};

export enum ConnectorStatus {
  RUNNING = "RUNNING",
  STOPPED = "STOPPED",
  UNKNOWN = "UNKNOWN"
}

export type EntityConnectorStreamDetail = {
  streamDetail: ConfiguredConnectorStream;
  lastRunTimestampInMillis: string;
  nextRunTimestampInMillis: string;
  connectorStatus: ConnectorStatus;
  rowCounts: string;
};

export type EntityConnectorStreamDetailList = {
  streamDetails: EntityConnectorStreamDetail[];
};

export interface EntityConnectorStatusResponse {
  connectionStreamDetails: Record<string, EntityConnectorStreamDetailList>;
}

export enum EntityConnectorActions {
  START = "START",
  STOP = "STOP",
  RESTART = "RESTART",
  EDIT_SCHEDULE = "EDIT_SCHEDULE",
  SYNC = "SYNC",
  VIEW_GENERATED_SUMMARY = "VIEW_GENERATED_SUMMARY",
  SYNC_STATUS = "SYNC_STATUS"
}

export interface EntityConnectorActionRequestPayload {
  traceInfo: {
    traceId: string;
  };
  //one of below is required
  streamDetails?: ConfiguredConnectorStream;
  streamId?: string;

  connectorAction: EntityConnectorActions;
  //only used if action is EDIT_SCHEDULE
  scheduleJson?: string;

  retry: number;
  sourceType: string;
}
export interface GenerateSummaryResponse {
  fileNameToSummary: Record<string, string>;
  traceInfo?: {
    traceId: string;
  };
  fileNameToKnowledgeSummary: Record<string, KnowledgeSummaryTextResponse>;
  latestSummary?: KnowledgeSummaryTextResponse;
}

export interface EntityConnectorActionResponse {
  streamDetails?: EntityConnectorStreamDetail;
  streamId?: string;

  status: boolean;
  message: string; // message in case of failure
  error?: string;
  response?: GenerateSummaryResponse;
  knowledgeSummaryStatusResponse?: DiscoverKnowledgeSummaryStatus;
  knowledgeSummaryResponse?: ViewGeneratedKnowledgeSummaryResponse;
}

export interface DiscoverKnowledgeSummaryStatus {
  status: Status;
  knowledgeBaseIdToDiscoveryStatus: Record<string, DiscoveryStatus>;
  knowledgeBaseIdToKnowledgeBaseName: Record<string, string>;
}

export enum Status {
  UNKNOWN = "UNKNOWN",
  STARTED = "STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
  CANCELLED = "CANCELLED"
}

export interface DiscoveryStatus {
  status: Status;
  progress: number;
  message: string;
  error: Error;
}

export interface ViewGeneratedKnowledgeSummaryResponse {
  traceInfo?: {
    traceId: string;
  };
  fileNameToKnowledgeSummary: Record<string, KnowledgeSummaryTextResponse>;
  latestSummary?: KnowledgeSummaryTextResponse;
}

export interface KnowledgeSummaryTextResponse {
  sourceId: string;
  uploadId: string;
  fileName: string;
  upsertTimestamp: string;
  summaryJson: string;
  error: string;
}

export type BlobObject = {
  id: any;
  name: string;
  mimeType: string;
  metadata: any;
  size: number;
  signedUrl: string;
  createdAt: number;
  lastUpdatedAt: number;
};
