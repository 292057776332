import Highcharts, { Options } from "highcharts";
import HCMore from "highcharts/highcharts-more";
import Boost from "highcharts/modules/boost";
import Annotations from "highcharts/modules/annotations";
import React, { FC, useMemo, useCallback, useState, useRef, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import { css, cx } from "emotion";
import { IncToolTip, IncFaIcon } from "@inception/ui";
import { VerticallyCenteredRow } from "../../flex-components";
import LoadingSpinner from "../../Loading/Loading";
import { FunnelData } from "../../../services/api/explore";
import { getFunnelChartOptions } from "../../../dashboard/widgets/Funnel/renderer/utils";
import { useFetchJourneyFunnelData } from "./useFetchJourneyFunnelData";

HCMore(Highcharts);
Boost(Highcharts);
Annotations(Highcharts);

Highcharts.AST.bypassHTMLFiltering = true;

Highcharts.setOptions({
  time: {
    useUTC: false
  }
});

interface Props {
  funnelId: string;
  compareTimeInSeconds: number;

  currentStr: string;
  compareStr: string;

  startTimeMillis: number;
  endTimeMillis: number;
  funnelData?: FunnelData;

  disableCompare?: boolean;
  showImpactRegions?: boolean;
  highlightOnlyMonitoredNode?: boolean;
  disabledXAxisLabels?: boolean;
  showLegends?: boolean;
}

export const JourneyFunnelRenderer: FC<Props> = props => {
  const {
    compareStr,
    currentStr,
    funnelId,
    compareTimeInSeconds,
    startTimeMillis,
    endTimeMillis,
    funnelData,
    disableCompare = false,
    showImpactRegions = false,
    highlightOnlyMonitoredNode = false,
    disabledXAxisLabels = false,
    showLegends = false
  } = props;

  const { data, error, isError, isFetching } = useFetchJourneyFunnelData(
    funnelId,
    compareTimeInSeconds,
    startTimeMillis,
    endTimeMillis,
    funnelData
  );

  const dataExists = Boolean(data?.stages?.length);

  const [, setChartRendered] = useState(false);

  const chartContainerRef = useRef<HTMLDivElement>();

  const getChartDimensions = useCallback(() => {
    const canvasElem: HTMLElement = chartContainerRef.current;
    if (canvasElem) {
      const dimensions = canvasElem?.getBoundingClientRect();
      if (dimensions) {
        const { height } = dimensions;
        const { width } = dimensions;
        return {
          width,
          height
        };
      }
    }
    return {
      width: -1,
      height: -1
    };
  }, [chartContainerRef]);

  useEffect(() => {
    if (chartContainerRef.current) {
      setChartRendered(true);
    }
  }, [chartContainerRef]);

  // Calculate dimensions on every render
  const { width, height } = getChartDimensions();
  const chartOptions = useMemo<Options>(
    () =>
      dataExists
        ? getFunnelChartOptions(
            data,
            width,
            height,
            "",
            currentStr,
            compareStr,
            compareStr,
            disableCompare,
            disabledXAxisLabels,
            showImpactRegions,
            highlightOnlyMonitoredNode
          )
        : null,
    [
      compareStr,
      currentStr,
      data,
      dataExists,
      disableCompare,
      disabledXAxisLabels,
      height,
      highlightOnlyMonitoredNode,
      showImpactRegions,
      width
    ]
  );

  const legends = useMemo(
    () => (
      <VerticallyCenteredRow className="marginTp8">
        <div className="current-legend-box" />
        <span className="inc-text-element marginLt12">{currentStr}</span>
        {!disableCompare && (
          <>
            <div className="compare-legend-box marginLt16" />
            <span className="inc-text-element marginLt12">{compareStr}</span>
          </>
        )}
      </VerticallyCenteredRow>
    ),
    [compareStr, currentStr, disableCompare]
  );

  return (
    <div
      className="biz-journey-funnel--chart"
      ref={chartContainerRef}
    >
      {isFetching && <LoadingSpinner className={getClassName()} />}
      {!isFetching && (
        <>
          {isError && (
            <div className={getClassName(true)}>
              <IncToolTip
                placement="top"
                titleText={error}
                variant="error"
              >
                <VerticallyCenteredRow>
                  <IncFaIcon
                    className="marginRt6"
                    iconName="warning"
                  />
                </VerticallyCenteredRow>
              </IncToolTip>
              Error fetching data
            </div>
          )}

          {!isError && (
            <>
              {!dataExists && <div className={getClassName()}>No data found</div>}
              {dataExists && (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              )}
            </>
          )}
        </>
      )}
      {showLegends ? legends : null}
    </div>
  );
};

const wrapperClass = css`
  height: 100%;
  width: 100%;
`;

const getClassName = (isError = false) =>
  cx("inc-text-subtext-medium", "inc-flex-row", "inc-flex-center", wrapperClass, {
    "status-danger": isError
  });
