import React, { useState, useEffect, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { IncModal, IncModalProps } from "@inception/ui";

interface ConfirmationModalProps {
  open: boolean; // show hide the modal

  headerStringId?: string;
  headerStringValues?: Record<string, string>;
  headerString?: string;

  bodyStringId?: string;
  bodyStringValues?: Record<string, string>;
  bodyString?: string | JSX.Element;
  onClose: () => void;

  // Primary button
  confirmBtnStringId?: string;
  onConfirm: () => void;

  // Cancel/ secondary button;
  cancelBtnStringId?: string;
  onCancel?: () => void;

  confirmIsDanger?: boolean;
  disableActions?: boolean;
  closeFromParent?: boolean;
  inProgress?: boolean;
  className?: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = (props: ConfirmationModalProps) => {
  const {
    bodyStringId,
    bodyStringValues = {},
    bodyString,
    confirmBtnStringId = "common.actions.confirm",
    headerStringId,
    headerStringValues = {},
    headerString,
    onClose,
    onConfirm,
    open: usrOpen,
    cancelBtnStringId = "common.actions.cancel",
    onCancel,
    confirmIsDanger = false,
    disableActions,
    closeFromParent = false,
    inProgress = false,
    className = ""
  } = props;

  const [open, setOpen] = useState(false);

  const onCloseModal = useCallback(() => {
    if (!disableActions) {
      setOpen(false);
      onClose();
    }
  }, [disableActions, onClose]);

  const onClickConfirm = useCallback(() => {
    if (!disableActions) {
      onConfirm();
      !closeFromParent && onCloseModal();
    }
  }, [closeFromParent, disableActions, onCloseModal, onConfirm]);

  const onClickCancel = useCallback(() => {
    if (!disableActions) {
      if (onCancel) {
        onCancel();
      }
      onCloseModal();
    }
  }, [disableActions, onCancel, onCloseModal]);

  useEffect(() => {
    setOpen(usrOpen);
  }, [onCloseModal, usrOpen]);

  const modalActions = useMemo<IncModalProps["actions"]>(
    () => ({
      primary: {
        id: confirmBtnStringId,
        onClick: onClickConfirm,
        color: confirmIsDanger ? "danger" : "primary",
        disabled: disableActions,
        showLoader: inProgress
      },
      secondary: inProgress
        ? null
        : {
            id: cancelBtnStringId,
            onClick: onClickCancel,
            disabled: disableActions,
            color: "secondary-blue"
          }
    }),
    [cancelBtnStringId, confirmBtnStringId, confirmIsDanger, disableActions, inProgress, onClickCancel, onClickConfirm]
  );

  return (
    <IncModal
      actions={modalActions}
      onClose={onCloseModal}
      show={open}
      showClose={!disableActions}
      size="sm"
      titleId={headerStringId}
      titleText={headerString}
      titleValues={headerStringValues}
    >
      <p className={className}>
        {!bodyString && (
          <FormattedMessage
            id={bodyStringId}
            values={bodyStringValues}
          />
        )}
        {bodyString}
      </p>
    </IncModal>
  );
};

export default ConfirmationModal;
