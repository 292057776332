import { cloneDeep, difference, forEach, isEqual, map, merge, values } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IncButton, IncFaIcon, IncModal } from "@inception/ui";
import { DeepPartial } from "redux";
import { TagFilterBySliceSet } from "../../../biz-entity";
import { VerticallyCenteredRow } from "../../../components";
import {
  AggregationUIOptions,
  OpConfigListItem,
  useAccessPrivilege,
  useForceUpdate,
  useTimeRange,
  useToggleState
} from "../../../core";
import { Feature } from "../../../permissions/features";
import {
  MetricResultDataDTO,
  OverallMappingStatus,
  SliceSet,
  UserServiceFieldSlice,
  WidgetConfigUtils,
  DemoDataParams,
  UserServiceFilter,
  UserServiceFilterExpression,
  UserServiceFilterNode,
  SelectorSpec
} from "../../../services/api/explore";
import { ExploreQueryType } from "../../../services/datasources/explore/types";
import { ENTITY_TAG } from "../../../utils";
import { WidgetCustomAction } from "../types";
import { useVariables } from "../useVariables";
import { ReactComponent as JiraIcon } from "../../../../images/jira-soft.svg";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import appConfig from "../../../../appConfig";
import { Subscribe } from "./components";
import { VisualizationWrapper } from "./VisualizationWrapper";
import { CustomActionProps, FetchResultMetaProps, useCustomActions } from "./hooks";
import { CatalogVizWrapperProps, CatalogWidgetProps, DataState } from "./types";
import { CatalogWidgetUtils } from "./CatalogWidgetUtils";
import { SourceMapDialog } from "./components/SourceMapDialog";
import { CatalogQuerySourceConfig, CatalogWidgetProperties, WidgetQuerySourceConfig } from "./models";

type Props = CatalogWidgetProps & {
  isValidWidget: boolean;
  demoParams: DemoDataParams;
};

export const CatalogWidgetWrapper: FC<Props> = props => {
  const {
    widget,
    onAction,
    setCustomActions,
    dbImpl,
    variableLoadingStateMap,
    variableSrvMap,
    eventTypeToFieldsMap,
    headerRef,
    isValidWidget,
    timeRange,
    demoParams,
    onUpdate,
    isStandalone,
    onMaximizeWithAddlContext,
    edit
  } = props;

  const {
    entityType,
    userServiceId,
    queryConfig,
    properties,
    id: widgetId,
    title,
    renderMode,
    hideMappingControls,
    metricUserServiceFilters,
    widgetSelectorSpec: pWidgetSelectorSpec
  } = widget;

  const { compareTimeRange: gCompareTimeRange } = useTimeRange();
  const { raw: compareRawTimeRange } = gCompareTimeRange || {};
  const { from: dbCompareFrom } = compareRawTimeRange || {};

  const compareInterval = useMemo(() => widget.getCompareInterval(dbCompareFrom), [dbCompareFrom, widget]);

  const isAdhocView = renderMode === "adhoc-view";
  const isVizOnlyView = renderMode === "viz-only";

  const { aggregator: presetAggregator, aggregateTags: presetAggregateTags } = properties;

  const prevProperties = useRef({ ...(properties || {}) });
  const propertiesChanged = !isEqual(prevProperties.current, properties);
  prevProperties.current = {
    ...(properties || {})
  };

  const isWidgetSourceQuery =
    queryConfig.sourceQueryConfig?.queryType === "widgetConfig" &&
    Boolean(queryConfig.sourceQueryConfig?.widgetResponse?.widgetId);

  const { aggOpts: aggOptions, defaultAgg } = useMemo(
    () => CatalogWidgetUtils.getAggregators(queryConfig),
    [queryConfig]
  );

  const forceUpdate = useForceUpdate();

  const aggregatorOpt = useMemo(() => {
    if (presetAggregator) {
      const defAgg = aggOptions.find(({ value }) => value === presetAggregator);
      return defAgg || defaultAgg;
    }
    return defaultAgg;
  }, [aggOptions, defaultAgg, presetAggregator]);

  const defaultAggregateTags = useMemo(() => {
    if (presetAggregateTags) {
      return presetAggregateTags;
    } else {
      const defSlices = CatalogWidgetUtils.getDefaultSlicesBasedOnQueryConfig(queryConfig, {
        tagName: ENTITY_TAG,
        userServiceField: null
      });

      return defSlices.map(({ tagName }) => tagName);
    }
  }, [presetAggregateTags, queryConfig]);

  const [vizActions, setVizActions] = useState<WidgetCustomAction[]>([]);
  const [disabledSeries, setDisabledSeries] = useState<Record<string, SliceSet[]>>();
  const sourceMapDialogTitle = `Map to Sources:  ${widget.title}`;

  const aggregator = aggregatorOpt?.value;

  const [aggregateTags, setAggregateTags] = useState<string[]>(defaultAggregateTags);

  const setAggregator = useCallback(
    (aggOpt: AggregationUIOptions) => {
      widget.properties.aggregator = aggOpt.value;
      forceUpdate();
    },
    [forceUpdate, widget.properties]
  );

  useMemo(() => {
    if (!presetAggregator && aggregator) {
      widget.properties.aggregator = aggregator;
    }
  }, [aggregator, presetAggregator, widget.properties]);

  useEffect(() => {
    if (presetAggregateTags) {
      setAggregateTags(prev => (!isEqual(presetAggregateTags, prev) ? presetAggregateTags : prev));
    }
  }, [aggregateTags, presetAggregateTags, widget]);

  useMemo(() => {
    if (!presetAggregateTags && aggregateTags) {
      widget.properties.aggregateTags = aggregateTags;
    }
  }, [aggregateTags, presetAggregateTags, widget.properties]);

  const [, setResultMeta] = useState<MetricResultDataDTO[]>([]);
  const [widgetSelectorSpec, setWidgetSelectorSpec] = useState<SelectorSpec>(cloneDeep(pWidgetSelectorSpec));

  const [, setDataQueryConfig] = useState<Record<string, any>>({});
  const [seriesFilters, setSeriesFilters] = useState<TagFilterBySliceSet[]>([]);

  const [dataState, setDataState] = useState<DataState>();
  const updateDataState = useCallback<CatalogVizWrapperProps["onFetchData"]>((isLoading, isError, dataResponse) => {
    const fetchCount = dataResponse?.[0]?.data?.[0]?.postAggDataSize || 0;
    const totalCount =
      Object.values(dataResponse?.[0]?.data?.[0]?.postAggResult?.data || {})?.[0]?.preLimitSelectionCount || 0;

    setDataState({
      isLoading,
      fetchCount,
      totalCount
    });
  }, []);

  const { sourceQueryConfig } = queryConfig;
  const {
    widgetResponse: widgetResponseDTO,
    metricId,
    childMetricIds: qChildMetricIds
  } = sourceQueryConfig as WidgetQuerySourceConfig;

  const childMetricIds = useMemo(() => qChildMetricIds || [], [qChildMetricIds]);

  const onQueryConfigChange = useCallback(
    (sourceQueryConfig: Partial<CatalogQuerySourceConfig>) => {
      if (widget.queryConfig.sourceQueryConfig.queryType === sourceQueryConfig.queryType) {
        widget.queryConfig.sourceQueryConfig = {
          ...widget.queryConfig.sourceQueryConfig,
          ...sourceQueryConfig
        } as CatalogQuerySourceConfig;
      }
      forceUpdate();

      if (isStandalone && onUpdate) {
        onUpdate();
      }
    },
    [forceUpdate, isStandalone, onUpdate, widget.queryConfig]
  );

  const onPropertiesChange = useCallback(
    (properties: DeepPartial<CatalogWidgetProperties>) => {
      merge(widget.properties, properties);
      forceUpdate();
      if (isStandalone && onUpdate) {
        onUpdate();
      }
    },
    [forceUpdate, isStandalone, onUpdate, widget.properties]
  );

  useEffect(() => {
    if (!isWidgetSourceQuery) {
      properties.insights = {
        ...(properties.insights || {}),
        changeMetricHeaders: {}
      } as any;
    }
  }, [isWidgetSourceQuery, aggregator, properties]);

  const isQueryMappingDone = useMemo(() => {
    if (demoParams) {
      return true;
    }

    if (!isValidWidget) {
      return false;
    }

    const useCaseQueryConfig = sourceQueryConfig.queryType === "useCase" ? sourceQueryConfig.dataQueryConfig : null;
    if (useCaseQueryConfig) {
      const { mappingStatus } = useCaseQueryConfig;
      if (mappingStatus) {
        if (mappingStatus.overallStatus) {
          return mappingStatus.overallStatus === OverallMappingStatus.COMPLETE;
        } else {
          return !mappingStatus.isIncomplete;
        }
      }
    }

    if (widgetResponseDTO) {
      const { mappingStatus } = widgetResponseDTO?.widgetConfig || {};

      if (mappingStatus) {
        if (mappingStatus.overallStatus) {
          return mappingStatus.overallStatus === OverallMappingStatus.COMPLETE;
        } else {
          return !mappingStatus.isIncomplete;
        }
      }

      return true;
    }

    return true;
  }, [demoParams, isValidWidget, sourceQueryConfig, widgetResponseDTO]);

  const onFiltersExtracted = useCallback((bizFilter: TagFilterBySliceSet, seriesFilters: TagFilterBySliceSet[]) => {
    setSeriesFilters(seriesFilters);
  }, []);

  const {
    entityFilters: eEntityFilters,
    allEntityFilters: eAllEntityFilters,
    cohortFilters,
    variablesLoading,
    eventFilters
  } = useVariables(variableSrvMap, widget, variableLoadingStateMap, widgetResponseDTO, eventTypeToFieldsMap, true);

  const { entityFilters: widgetEntityFilters, eventFilters: widgetEventFilters } = useVariables(
    variableSrvMap,
    widget,
    variableLoadingStateMap,
    widgetResponseDTO,
    eventTypeToFieldsMap
  );

  const isEntityWidget = useMemo(() => {
    let isEntityWidget = true;
    const metrics = widgetResponseDTO?.widgetConfig?.dataDefinition?.metrics;
    if (metrics) {
      values(metrics).forEach(x => {
        isEntityWidget = isEntityWidget && x.sourceType === "entity";
      });
    } else {
      isEntityWidget = false;
    }

    return isEntityWidget;
  }, [widgetResponseDTO]);

  const entityFilters = useMemo(() => {
    if (!isEntityWidget) {
      return eEntityFilters;
    } else {
      return eAllEntityFilters;
    }
  }, [eAllEntityFilters, eEntityFilters, isEntityWidget]);

  const allEventFilters = useMemo(
    () => ({
      ...widgetEventFilters,
      ...eventFilters
    }),
    [eventFilters, widgetEventFilters]
  );

  const allEntityFilters = useMemo(
    () => [...(widgetEntityFilters || []), ...(entityFilters || [])],
    [entityFilters, widgetEntityFilters]
  );

  const querySchemaExists = Boolean(widgetResponseDTO?.querySchema?.querySchema?.length);

  const fetchResultMetaProps: FetchResultMetaProps = useMemo(
    () => ({
      cohortFilters,
      entityFilters,
      entityType,
      limit: 30,
      timeRange,
      userServiceId,
      widgetResponseDTO,
      eventFilters,
      id: widgetId,
      mode: isWidgetSourceQuery ? ExploreQueryType.saved : ExploreQueryType.adhoc,
      widgetName: title,
      aggregateTags: properties?.aggregateTags,
      sourceType: queryConfig.sourceQueryConfig?.queryType,
      isQueryMappingDone,
      demoParams,
      renderMode
    }),
    [
      cohortFilters,
      demoParams,
      entityFilters,
      entityType,
      eventFilters,
      isQueryMappingDone,
      isWidgetSourceQuery,
      properties?.aggregateTags,
      queryConfig.sourceQueryConfig?.queryType,
      renderMode,
      timeRange,
      title,
      userServiceId,
      widgetId,
      widgetResponseDTO
    ]
  );

  const { permissions } = useAccessPrivilege();
  const canSubscribe =
    permissions?.hasAccess(Feature.operationalize, "addOperationalize") &&
    permissions?.hasAccess(Feature.operationalize, "editOperationalize") &&
    permissions?.hasAccess(Feature.operationalize, "deleteOperationalize") &&
    !isAdhocView &&
    !isVizOnlyView &&
    !appConfig.anomShareId;

  const updateAggregateTags = useCallback(
    (aggregateTags: string[]) => {
      widget.properties.aggregateTags = [...(aggregateTags || [])];
      setAggregateTags(aggregateTags);
    },
    [widget.properties]
  );

  const updateAggregator = useCallback(
    (aggregator: AggregationUIOptions) => {
      widget.properties.aggregator = aggregator.value;
      setAggregator(aggregator);
    },
    [setAggregator, widget.properties]
  );

  const [error, setError] = useState("");
  const [op10zeExist, setOp10zeExist] = useState(false);

  const onError = useCallback(
    (error: string) => {
      onAction({
        type: "onError",
        error
      });
    },
    [onAction]
  );

  useEffect(() => {
    onError(error);
  }, [error, onError]);

  const allowPartialMatch = useMemo(() => {
    const metricDef = WidgetConfigUtils.getMetricDefinition(widgetResponseDTO?.widgetConfig, metricId);
    const { usFieldMetricDef } = WidgetConfigUtils.getMetricDefinitions(metricDef);

    const sliceTagsSet = (usFieldMetricDef?.userServiceFieldMetricConfig?.sliceSets || [])
      .reduce((acc, ss) => [...acc, ...ss.slices], [] as UserServiceFieldSlice[])
      .reduce((acc, { tagName }) => {
        acc.add(tagName);
        return acc;
      }, new Set<string>());
    const sliceTags = Array.from(sliceTagsSet).sort();
    const sortedAggTags = aggregateTags.sort();

    return !isEqual(sliceTags, sortedAggTags);
  }, [aggregateTags, metricId, widgetResponseDTO]);

  /**
   * handle the widget filters and update the widget
   */
  const handleWidgetSelectorSpecChange = useCallback(
    (widgetSelectorSpec: SelectorSpec) => {
      widget.widgetSelectorSpec = cloneDeep(widgetSelectorSpec);
      setWidgetSelectorSpec(widgetSelectorSpec);
    },
    [widget]
  );

  /**
   * reset filters
   */
  const resetFilters = useCallback(() => {
    widget.widgetSelectorSpec = {
      filters: [
        {
          tags: []
        }
      ]
    };

    setWidgetSelectorSpec({
      filters: [
        {
          tags: []
        }
      ]
    });
  }, [widget]);

  const onEdit = useCallback(() => {
    onMaximizeWithAddlContext({
      mode: "edit"
    });
  }, [onMaximizeWithAddlContext]);

  const customActionProps = useMemo<CustomActionProps>(
    () => ({
      aggOptions,
      aggregator: aggregatorOpt,
      allowPartialMatch,
      cohortFilters,
      dbImpl,
      entityFilters,
      eventFilters,
      onFiltersExtracted,
      propertiesChanged,
      widgetSelectorSpec,
      setAggregator: updateAggregator,
      setWidgetSelectorSpec: handleWidgetSelectorSpecChange,
      timeRange,
      vizActions,
      widget,
      widgetResponseDTO,
      widgetResponseFetchInProgress: false,
      headerRef,
      op10zeExist,
      fetchResultMetaProps,
      dataState
    }),
    [
      aggOptions,
      aggregatorOpt,
      allowPartialMatch,
      cohortFilters,
      dataState,
      dbImpl,
      entityFilters,
      eventFilters,
      fetchResultMetaProps,
      handleWidgetSelectorSpecChange,
      headerRef,
      onFiltersExtracted,
      op10zeExist,
      propertiesChanged,
      timeRange,
      updateAggregator,
      vizActions,
      widget,
      widgetResponseDTO,
      widgetSelectorSpec
    ]
  );

  const { addToDashboardActionModal, customActions } = useCustomActions(customActionProps);

  useEffect(() => {
    if (edit) {
      customActions.splice(0, 0, {
        actionComponent: (
          <VerticallyCenteredRow
            className="width-100"
            onClick={onEdit}
          >
            <IncFaIcon iconName="edit" />
            Edit
          </VerticallyCenteredRow>
        )
      });
    }

    setCustomActions(customActions);
  }, [customActions, edit, onEdit, setCustomActions]);

  const querySchema = useMemo(() => widgetResponseDTO?.querySchema?.querySchema || [], [widgetResponseDTO]);

  useMemo(() => {
    if (querySchema && metricId && isWidgetSourceQuery) {
      const disabledSeries: Record<string, SliceSet[]> = {};
      let addDisabledSeries = false;

      querySchema.forEach(qsSliceSet => {
        const { metricId: qMetricId, sliceSet } = qsSliceSet;

        const metricIds = [...childMetricIds, metricId];
        if (!metricIds.includes(qMetricId)) {
          disabledSeries[qMetricId] = disabledSeries[qMetricId] || [];
          disabledSeries[qMetricId].push(sliceSet);
          addDisabledSeries = true;
        }
      });

      if (addDisabledSeries) {
        setDisabledSeries(disabledSeries);
      }
    }
  }, [childMetricIds, isWidgetSourceQuery, metricId, querySchema]);

  const compareTimeRange = useMemo(() => {
    if (compareInterval) {
      return timeRangeUtils.getCompareTimeRangeFromRaw(timeRange, compareInterval);
    }

    return props.compareTimeRange;
  }, [compareInterval, props.compareTimeRange, timeRange]);

  /**
   * merge the userServiceFilters of eventFilters and metricUserServiceFilters
   */
  const mergedEventFilters = useMemo(() => {
    //NOTE: deep clone is required here since the event filters are getting affected if we do simple clone.
    const commonEventFilters = cloneDeep(eventFilters);
    const filterKeys = Object.keys(commonEventFilters);
    const widgetFilterKeys: string[] = metricUserServiceFilters ? Object.keys(metricUserServiceFilters) : [];
    //handle the scenario when the event filters doesn't exist
    const missingMetricKeys = difference(widgetFilterKeys, filterKeys);

    //check if the widget Filter keys are present then start the merge
    if (widgetFilterKeys.length > 0) {
      // handle all of the filters present in the eventFilters
      filterKeys.forEach((key: string) => {
        const filter = commonEventFilters[key];
        const metFilter = metricUserServiceFilters[key];
        const isExpression = Boolean(filter.expressionTree);
        const { userServiceFilters } = metFilter;
        const { expressionTree, userServiceFilters: fUserServiceFilters } = filter;
        //check if expression tree exists
        if (isExpression) {
          userServiceFilters.forEach(fi => {
            fi.userServiceFilterExpressions.forEach((fiexp: UserServiceFilterExpression) => {
              const exp: UserServiceFilterNode = {
                expression: fiexp
              };
              expressionTree.filterNodes.push(exp);
            });
          });
          //case of userServiceFilterExpressions
        } else {
          fUserServiceFilters.forEach(fi => {
            forEach(userServiceFilters || {}, (userServiceFilter: UserServiceFilter) => {
              const userServiceFilterExpressions = userServiceFilter?.userServiceFilterExpressions;
              if (userServiceFilterExpressions) {
                fi.userServiceFilterExpressions.push(...userServiceFilterExpressions);
              }
            });
          });
        }
      });
    }
    //handle the metrics which are only present on widgets
    const restFilters = missingMetricKeys.length > 0 ? metricUserServiceFilters : {};
    return {
      ...commonEventFilters,
      ...restFilters
    };
  }, [eventFilters, metricUserServiceFilters]);

  /**
   * Reset the filter when the event filters are updated
   */
  useEffect(() => {
    if (!pWidgetSelectorSpec) {
      resetFilters();
    }
  }, [pWidgetSelectorSpec, resetFilters]);

  const rendererProps: CatalogVizWrapperProps = useMemo(
    () => ({
      ...props,
      compareTimeRange,
      onError: setError,
      onResultMetaChange: setResultMeta,
      onDataQueryConfigChange: setDataQueryConfig,
      widgetSelectorSpec,
      seriesFilters,
      aggregator: aggregatorOpt,
      aggregateTags,
      setAggregateTags: updateAggregateTags,
      querySchema,
      widgetResponseDTO,
      entityFilters,
      cohortFilters,
      eventFilters: mergedEventFilters,
      variablesLoading,
      resetFilters,
      metricId,
      childMetricIds,
      onActionsChange: setVizActions,
      onFetchData: updateDataState,
      children: null,
      demoDataParams: demoParams,
      onQueryConfigChange,
      onPropertiesChange,
      fetchResultMetaProps,
      disabledSeries
    }),
    [
      props,
      compareTimeRange,
      widgetSelectorSpec,
      seriesFilters,
      aggregatorOpt,
      aggregateTags,
      updateAggregateTags,
      querySchema,
      widgetResponseDTO,
      entityFilters,
      cohortFilters,
      mergedEventFilters,
      variablesLoading,
      resetFilters,
      metricId,
      childMetricIds,
      updateDataState,
      demoParams,
      onQueryConfigChange,
      onPropertiesChange,
      fetchResultMetaProps,
      disabledSeries
    ]
  );

  const onOpConfigsFetched = useCallback((opConfigs: OpConfigListItem[]) => {
    setOp10zeExist(opConfigs?.length > 0);
  }, []);

  const { isOpen: isSourceMapDialogOpen, open: openSourceMapDialog, close: closeSourceMapDialog } = useToggleState();

  const { isOpen: isReportModalOpen, open: openReportModal, close: closeReportModal } = useToggleState();

  const reportActionJsx = useMemo(() => {
    const usecaseQuery: any = widget.queryConfig.sourceQueryConfig;
    const usFields = usecaseQuery?.dataQueryConfig?.query?.widgetConfig?.mappingStatus?.usFields;
    const fieldNames = map(usFields, "field.fieldName").join(", ");
    const eventTypes = map(usFields, "field.fieldName.userServices[0].userServiceEntityId").join(", ");

    const url = `https://bicycleio.atlassian.net/jira/secure/CreateIssueDetails!init.jspa?pid=10026&issuetype=10002&summary=Add%20${fieldNames}%20field%20to%20${eventTypes}%20event&description=In%20Payment%20topic%20add%20discount%20field`;
    const openUrl = () => {
      window.open(url, "_blank");
    };

    const message = fieldNames
      ? `We were not able to auto discover the field " ${fieldNames} "`
      : `We were not able to auto discover the fields for " ${title} "`;

    return (
      <div className="inc-flex-column width-100 flex-gap-16 inc-flex-center">
        <VerticallyCenteredRow>{message}</VerticallyCenteredRow>

        <IncButton
          className="flex-gap-8"
          color="secondary-blue"
          onClick={openUrl}
        >
          <JiraIcon
            className="jira-icon marginLt6 inc-cursor-pointer"
            height={24}
            width={24}
          />
          Create a ticket
        </IncButton>
      </div>
    );
  }, [title, widget.queryConfig.sourceQueryConfig]);

  return (
    <>
      {isQueryMappingDone && (
        <>
          {querySchemaExists && (
            <>
              <VisualizationWrapper {...rendererProps} />
              {addToDashboardActionModal}
              {canSubscribe && (
                <Subscribe
                  entityFilters={allEntityFilters}
                  eventFilters={allEventFilters}
                  onOpConfigsFetched={onOpConfigsFetched}
                  widget={widget}
                  widgetResponseDTO={widgetResponseDTO}
                />
              )}
            </>
          )}
          {!querySchemaExists && (
            <VerticallyCenteredRow className="inc-text-subtext-medium status-warning inc-flex-center height-100 width-100">
              Error while fetching widget configuration
            </VerticallyCenteredRow>
          )}
        </>
      )}
      {!isQueryMappingDone && (
        <div className="inc-flex-column height-100 width-100">
          <VerticallyCenteredRow className="inc-text-subtext-medium inc-text-inactive marginBtAuto">
            Connect to a data source to view preview
          </VerticallyCenteredRow>

          {!hideMappingControls && (
            <VerticallyCenteredRow className="flex-gap-16">
              <IncButton
                color="secondary-blue"
                onClick={openSourceMapDialog}
              >
                Map to sources
              </IncButton>

              <IncButton
                color="secondary-blue"
                onClick={openReportModal}
              >
                Request Data
              </IncButton>
            </VerticallyCenteredRow>
          )}
        </div>
      )}

      {isSourceMapDialogOpen && (
        <SourceMapDialog
          onClose={closeSourceMapDialog}
          title={sourceMapDialogTitle}
          widget={widget}
          widgetResponseDTO={widgetResponseDTO}
        />
      )}

      {isReportModalOpen && (
        <IncModal
          onClose={closeReportModal}
          show
          size="lg"
          titleText={`Request Data - ${widget.title}`}
        >
          {reportActionJsx}
        </IncModal>
      )}
    </>
  );
};
