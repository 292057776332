import { isBoolean, isEmpty } from "lodash";
import { generateUUID } from "@inception/ui";
import { EntityPropertyMap, EntityPropertySet, ObjectPropertyMap } from "../../../../../core";
import {
  ActionTemplateElementProp,
  ActionTemplateSectionElement,
  ActionTemplateTextElement,
  ElementPropertyValues,
  SectionElementType,
  TemplateCanvasSection
} from "../../../../../services/api/operationalise";
import { getTypedObjValueKeyBasedOnType } from "../../../../../utils";

export const getSectionForSectionElement = (sectionElement: ActionTemplateSectionElement, text?: string) => {
  const sectionId = generateUUID();

  const { elementId, type, prop, canLoopInEmbeddedMode } = sectionElement;

  const section: TemplateCanvasSection = {
    sectionId,
    sectionElementId: elementId,
    type,
    prop: getProperties(prop),
    isEmbeddedLoop: canLoopInEmbeddedMode,
    hasTwoColumn: type === SectionElementType.TABLE // Currently defaulting to true for table. In future, this can change to user-input
  };

  if (text) {
    section.content = text;
  }

  return section;
};

export const getPropertiesForTextElement = (textEl: ActionTemplateTextElement): ElementPropertyValues => {
  const { prop } = textEl;
  return getProperties(prop);
};

const getProperties = (props: ActionTemplateElementProp[]) => {
  const properties: ElementPropertyValues = {
    propValue: {}
  };

  props?.forEach(p => {
    const { defaultValue, propId } = p;

    if (defaultValue) {
      properties.propValue[propId] = defaultValue;
    }
  });

  return properties;
};

export const isSectionValid = (section: TemplateCanvasSection, sectionProperties: ActionTemplateElementProp[]) => {
  let isValid = true;
  const errors: string[] = [];

  const { prop } = section;

  isValid = isValid && validateSectionProperties(prop, sectionProperties, errors);
  // isValid = hasTwoColumn ? isValid && validateSectionProperties(prop2, sectionProperties, errors) : isValid;

  return {
    isValid,
    errors
  };
};

const validateSectionProperties = (
  prop: ElementPropertyValues,
  sectionProperties: ActionTemplateElementProp[],
  errors: string[]
) => {
  let isValid = true;

  const { propValue = {} } = prop || {};

  sectionProperties.forEach(sectionProp => {
    const { required, propId, type, label } = sectionProp;

    if (required && isValid) {
      const propKey = getTypedObjValueKeyBasedOnType(type as any);
      const ePropValue = propValue[propId]?.[propKey];

      let valueIsValid = true;
      switch (propKey) {
        case "booleanVal": {
          valueIsValid = isBoolean(ePropValue);
          break;
        }

        case "dateTimeVal":
        case "stringVal": {
          valueIsValid = !isEmpty(ePropValue);
          break;
        }

        case "longVal":
        case "doubleVal": {
          valueIsValid = Boolean(ePropValue);
          break;
        }

        case "mapValue": {
          valueIsValid = !isEmpty((ePropValue as EntityPropertyMap)?.entries);
          break;
        }

        case "objectMapVal": {
          valueIsValid = !isEmpty((ePropValue as ObjectPropertyMap)?.entries);
          break;
        }

        case "setValue": {
          valueIsValid = !isEmpty((ePropValue as EntityPropertySet)?.values);
          break;
        }

        default:
          return false;
      }

      isValid = isValid && valueIsValid;

      if (!valueIsValid) {
        errors.push(`Property: ${label} has an invalid value`);
      }
    }
  });

  return isValid;
};
