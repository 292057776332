import React from "react";
import { isEmpty } from "lodash";
import { FormattedMessage } from "react-intl";

type PopOverInfoProps = {
  headerText?: string;
  headerId?: string;
  list: Map<string, string[]>;
};

const PopOverInfo: React.FC<PopOverInfoProps> = (props: PopOverInfoProps) => {
  const { headerText, headerId, list } = props;
  if (isEmpty(list)) {
    return null;
  }
  const keys = Array.from(list.keys());
  return (
    <div className={"explore-viz-info"}>
      {(headerText || headerId) && (
        <div className={"info-header"}>{headerText ? headerText : <FormattedMessage id={headerId} />}</div>
      )}
      <ul>
        {keys?.map((x, id) => (
          <div key={id}>
            <li className="info-item">{x}</li>
            <>
              {list.get(x)?.length > 0 && (
                <ul key={`${x}-sub-list`}>
                  {list.get(x).map((subItem, i) => (
                    <li
                      className="info-item"
                      key={`${i}-sub-item`}
                    >
                      {subItem}
                    </li>
                  ))}
                </ul>
              )}
            </>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default React.memo(PopOverInfo);
