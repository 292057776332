import React, { useMemo, useCallback } from "react";
import { TreeMapOptions, IncTreeMap } from "../../../../../components";
import { eventFieldUtils } from "../../../../../utils";
import { USFWRendererProps } from "./types";
import { constructPieSeries } from "./PieChartRenderer";
import { useCommonRendererFunctionality } from "./common";
import { getFormattedValue } from "./utils";

export const TreeMapRenderer = (props: USFWRendererProps) => {
  const {
    className,
    dataType,
    loadingElement,
    noDataElement,
    aggregatedTags: aggregatedTag,
    displayAggregatedTags,
    currencyType
  } = props;

  const { data, fieldName, metricName, isFetching, isError, dataExists } = useCommonRendererFunctionality(props);

  const { series, drillDownSeries } = useMemo(
    () => constructPieSeries(data, aggregatedTag, displayAggregatedTags, dataType, fieldName, metricName),
    [aggregatedTag, data, dataType, fieldName, metricName, displayAggregatedTags]
  );

  const valueFormatterFn = useCallback(
    (value: string | number) => getFormattedValue(fieldName, value, dataType, currencyType),
    [fieldName, dataType, currencyType]
  );
  const options: TreeMapOptions = useMemo(
    () => ({
      tooltipValueFormatter: valueFormatterFn
    }),
    [valueFormatterFn]
  );

  if (isFetching) {
    return loadingElement;
  }

  if (!dataExists || isError) {
    return noDataElement;
  }

  return (
    <IncTreeMap
      containerElemClass={className}
      drillDownSeries={drillDownSeries}
      options={options}
      series={series}
      seriesName={eventFieldUtils.removeFieldsPrefix(fieldName)}
    />
  );
};
