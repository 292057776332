import { useCallback, useRef, useMemo } from "react";
import { forEach, isEqual, union, isEmpty, cloneDeep } from "lodash";
import { VariableSrv } from "../variables";
import {
  CohortEntityFilter,
  MetricUserServiceFilters,
  UserServiceFilterExpression,
  WidgetQuerySchemaResponse,
  WidgetConfigUtils,
  UserServiceFieldWithMeta,
  UserServiceField,
  BizFieldPredicate,
  WidgetResponseDTO,
  UserServiceFilterExpressionTree,
  LogicalOperator,
  UserServiceFilterNode,
  BizField
} from "../../services/api/explore";
import { ScopedVars } from "../../services/api/types";
import { GLOBAL_VARIABLE_SRV_KEY } from "../variables/constants";
import CohortVariableImpl from "../model-impl/CohortVariableImpl";
import { DataType, useForceUpdate } from "../../core";
import { BaseWidgetImpl } from "..";
import { ALL_USERSERVICES_ENTITY_TYPE_ID } from "../../utils/ExploreUtils";
import { USFieldWidgetImpl } from "./USField/models";
import FunnelWidgetImpl from "./Funnel/models/impl";

export type UseVariablesState = {
  cohortFilters: CohortEntityFilter[];
  entityFilters: CohortEntityFilter[];
  allEntityFilters: CohortEntityFilter[];
  variables: ScopedVars;
  entityVariables: CohortVariableImpl[];
  variablesLoading: boolean;
  eventFilters: MetricUserServiceFilters;
};

export const useVariables = (
  variableSrvMap: Record<string, VariableSrv>,
  widget?: BaseWidgetImpl,
  loadingStateMap?: Record<string, boolean>,
  widgetResponseDTO?: WidgetResponseDTO,
  eventTypeIdToFieldsMap?: Record<string, UserServiceFieldWithMeta[]>,
  getAllVariables = false
): UseVariablesState => {
  const entityFilters = useRef<CohortEntityFilter[]>([]);
  const allEntityFilters = useRef<CohortEntityFilter[]>([]);
  const cohortFilters = useRef<CohortEntityFilter[]>([]);
  const variables = useRef<ScopedVars>({});
  const entityVariables = useRef<CohortVariableImpl[]>([]);
  const eventFilters = useRef<MetricUserServiceFilters>({});
  const variablesLoading = useRef<boolean>(true);

  const forceUpdate = useForceUpdate();

  const updateStates = useCallback(
    (
      eCohortFilters: CohortEntityFilter[],
      eEntityFilters: CohortEntityFilter[],
      eVariables: ScopedVars,
      eEntityVariables: CohortVariableImpl[],
      eVariablesLoading: boolean,
      eAllEntityFilters: CohortEntityFilter[],
      eEventFilters?: MetricUserServiceFilters
    ) => {
      let isChanged = false;

      if (!isEqual(cohortFilters.current, eCohortFilters)) {
        cohortFilters.current = eCohortFilters;
        isChanged = true;
      }

      if (!isEqual(entityFilters.current, eEntityFilters)) {
        entityFilters.current = eEntityFilters;
        isChanged = true;
      }

      if (!isEqual(variables.current, eVariables)) {
        variables.current = eVariables;
        isChanged = true;
      }

      if (!isEqual(entityVariables.current, eEntityVariables)) {
        entityVariables.current = eEntityVariables;
        isChanged = true;
      }

      if (!isEqual(eAllEntityFilters, allEntityFilters.current)) {
        allEntityFilters.current = eAllEntityFilters;
        isChanged = true;
      }

      if (eEventFilters && !isEqual(eventFilters.current, eEventFilters)) {
        eventFilters.current = eEventFilters;
        isChanged = true;
      }

      if (variablesLoading.current !== eVariablesLoading) {
        variablesLoading.current = eVariablesLoading;
        isChanged = true;
      }

      if (isChanged) {
        forceUpdate();
      }
    },
    [forceUpdate]
  );

  // need to figure out a better way to evaluate variables
  useMemo(() => {
    if (variableSrvMap) {
      const { eCohortFilters, eEntityFilters, eEntityVariables, eVariables, eVariablesLoading, eEventFilters } =
        getVariablesBasedOnWidget(
          variableSrvMap,
          loadingStateMap,
          widget,
          widgetResponseDTO,
          eventTypeIdToFieldsMap,
          getAllVariables
        );

      // getAllEntityFilters for entity data widgets
      const eAllEntityFilters: CohortEntityFilter[] = [];
      forEach(variableSrvMap, varSrv => {
        if (varSrv.checkIfFilterWidget() && Boolean(varSrv.getEntityTypeId())) {
          const entityFilters = varSrv.getEntityFilters();
          eAllEntityFilters.push(...entityFilters);
        }
      });

      updateStates(
        eCohortFilters,
        eEntityFilters,
        eVariables,
        eEntityVariables,
        eVariablesLoading,
        eAllEntityFilters,
        eEventFilters
      );
    }
  }, [
    eventTypeIdToFieldsMap,
    getAllVariables,
    loadingStateMap,
    updateStates,
    variableSrvMap,
    widget,
    widgetResponseDTO
  ]);

  return {
    entityFilters: entityFilters.current,
    allEntityFilters: allEntityFilters.current,
    cohortFilters: cohortFilters.current,
    entityVariables: entityVariables.current,
    variables: variables.current,
    variablesLoading: variablesLoading.current,
    eventFilters: eventFilters.current
  };
};

export const getVariablesBasedOnWidget = (
  variableSrvMap: Record<string, VariableSrv>,
  loadingStateMap: Record<string, boolean>,
  widget: BaseWidgetImpl,
  widgetResponseDTO: WidgetResponseDTO,
  eventTypeIdToFieldsMap: Record<string, UserServiceFieldWithMeta[]>,
  getAllVariables = false
) => {
  const globalVariableSrv = variableSrvMap[GLOBAL_VARIABLE_SRV_KEY];
  const { type } = widget || {};
  const allUserServices = Object.keys(eventTypeIdToFieldsMap || {}).filter(x => Boolean(x)) || [];

  const applyEventFiltersFromQuerySchema = (
    widgetQuerySchemaResponse: WidgetQuerySchemaResponse,
    eventIdToFilterTree: Record<string, UserServiceFilterExpressionTree>,
    eventFilters: MetricUserServiceFilters
  ) => {
    (widgetQuerySchemaResponse?.querySchema || []).forEach(querySchema => {
      const {
        metricId,
        sourceUserServiceField: userServiceField,
        componentSourceFields: userServiceFieldsMap,
        bizField
      } = querySchema;

      let userServices: string[] = [];
      if (userServiceField) {
        const { allUserService } = userServiceField;
        userServices = WidgetConfigUtils.getUserServiceListFromUserServiceField(userServiceField);
        if (allUserService) {
          userServices = union(userServices, allUserServices);
        }
      }

      forEach(userServiceFieldsMap, usf => {
        const { allUserService } = usf;
        const tUserServices = WidgetConfigUtils.getUserServiceListFromUserServiceField(usf);
        userServices = union(userServices, tUserServices);
        if (allUserService) {
          userServices = union(userServices, allUserServices);
        }
      });

      forEach(eventIdToFilterTree, (filterTree, eventId) => {
        applyEventFilters(eventFilters, userServices, filterTree, eventId, metricId, Boolean(bizField));
      });
    });
  };

  const querySchemaResponse = widgetResponseDTO?.querySchema;

  switch (type) {
    case "us-field":
    case "catalog": {
      const usfWidget = widget as USFieldWidgetImpl;
      const { entityType, id: widgetId } = usfWidget;
      const {
        cohortFilters: eCohortFilters,
        entityFilters: eEntityFilters,
        entityVariables: eEntityVariables,
        variables: eVariables,
        eventIdToFilters,
        variablesLoading: eVariablesLoading,
        entityEventFilters
      } = getAllVariableValues(
        variableSrvMap,
        loadingStateMap,
        entityType,
        widgetId,
        querySchemaResponse,
        eventTypeIdToFieldsMap,
        getAllVariables
      );

      const eEventFilters: MetricUserServiceFilters = {};

      applyEventFiltersFromQuerySchema(querySchemaResponse, eventIdToFilters, eEventFilters);
      if (entityEventFilters) {
        applyEntityEventFilters(eEventFilters, entityEventFilters);
      }

      return {
        eCohortFilters,
        eEntityFilters,
        eVariables,
        eEntityVariables,
        eVariablesLoading,
        eEventFilters
      };
    }

    case "funnel": {
      const funnelWidget = widget as FunnelWidgetImpl;
      const { id } = funnelWidget;
      const entityType = funnelWidget.getBizEntityType();

      const {
        cohortFilters: eCohortFilters,
        entityFilters: eEntityFilters,
        entityVariables: eEntityVariables,
        eventIdToFilters,
        variables: eVariables,
        variablesLoading: eVariablesLoading,
        entityEventFilters
      } = getAllVariableValues(
        variableSrvMap,
        loadingStateMap,
        entityType,
        id,
        querySchemaResponse,
        eventTypeIdToFieldsMap,
        getAllVariables
      );

      const eEventFilters: MetricUserServiceFilters = {};

      applyEventFiltersFromQuerySchema(querySchemaResponse, eventIdToFilters, eEventFilters);
      if (entityEventFilters) {
        applyEntityEventFilters(eEventFilters, entityEventFilters);
      }

      return {
        eCohortFilters,
        eEntityFilters,
        eVariables,
        eEntityVariables,
        eVariablesLoading,
        eEventFilters
      };
    }

    case "bar-chart":
    case "customer-journey":
    case "entity-critical-stats":
    case "entity-table-widget":
    case "request-count-widget":
    case "single-stat-compare":
    case "usage-events":
    case "timeseries":
    case "entity-activity":
    default: {
      const {
        entityFilters: eEntityFilters,
        cohortFilters: eCohortFilters,
        variables: eVariables,
        entityVariables: eEntityVariables
      } = getVariableValues(globalVariableSrv);
      const eVariablesLoading = loadingStateMap?.[GLOBAL_VARIABLE_SRV_KEY] || false;

      return {
        eCohortFilters,
        eEntityFilters,
        eVariables,
        eEntityVariables,
        eVariablesLoading
      };
    }
  }
};

// todo: try to evaluate use memo approach for different filters
export const getAllVariableValues = (
  variableSrvMap: Record<string, VariableSrv>,
  loadingStateMap: Record<string, boolean>,
  entityType: string,
  widgetId: string,
  querySchemaResponse?: WidgetQuerySchemaResponse,
  eventTypeIdToFieldsMap?: Record<string, UserServiceFieldWithMeta[]>,
  getAllVariables = false
) => {
  let allEntityFilters: CohortEntityFilter[] = [];
  let allCohortFilters: CohortEntityFilter[] = [];
  let scopedVariables: ScopedVars = {};
  let allEntityVariables: CohortVariableImpl[] = [];

  const allFiltersByEventType: Record<string, UserServiceFilterExpressionTree> = {};
  const entityEventFilters: Record<string, UserServiceFilterExpressionTree> = {};
  const variableStateExist = Object.values(variableSrvMap).length > 0;
  let eVariablesLoading = variableStateExist ? false : true;

  forEach(variableSrvMap, (varSrv, key) => {
    const isWidgetVarSrvMap = key === widgetId;
    const shouldApplyFilters = getAllVariables ? varSrv.checkIfFilterWidget() || isWidgetVarSrvMap : isWidgetVarSrvMap;
    if (!shouldApplyFilters) {
      return;
    }
    const appliedWidgets = varSrv.getAppliedWidgets();
    const { entityFilters, cohortFilters, variables, filtersByEventType, entityVariables, filterTreeByEventType } =
      getVariableValues(varSrv);

    const pushToEntityEventFilters = (metricId: string, userServiceFilterExpr: UserServiceFilterExpression) => {
      if (entityEventFilters[metricId]) {
        entityEventFilters[metricId].filterNodes.push({
          expression: userServiceFilterExpr
        });
      } else {
        entityEventFilters[metricId] = {
          filterNodes: [
            {
              expression: userServiceFilterExpr
            }
          ],
          logicalOperator: LogicalOperator.AND
        };
      }
    };

    const findMatchingUserFilterAndPushToEntityFilters = (filter: CohortEntityFilter, filterEntityType: string) => {
      const matchEntityTypeAndPushToEventFilters = (userServices: string[], metricId: string, bizField: BizField) => {
        if (bizField && filter.predicate?.bizField?.entityField) {
          const usField = getUsFieldFromBizField(filter.predicate.bizField);
          const userServiceFilterExpr = getUserServiceExpressionFromBizFieldPredicate(usField, filter.predicate);
          pushToEntityEventFilters(metricId, userServiceFilterExpr);
        } else {
          userServices.forEach(us => {
            const entityTypeMatch = (eventTypeIdToFieldsMap?.[us] || []).find(x =>
              checkIfEntityTypeMatches(x.userServiceField, filterEntityType)
            );
            if (entityTypeMatch) {
              const userServiceFilterExpr = getUserServiceExpressionFromBizFieldPredicate(
                entityTypeMatch,
                filter.predicate
              );
              pushToEntityEventFilters(metricId, userServiceFilterExpr);
            }
          });
        }
      };

      (querySchemaResponse?.querySchema || []).forEach(qs => {
        const {
          metricId,
          sourceUserServiceField: userServiceField,
          componentSourceFields: userServiceFieldsMap,
          bizField
        } = qs;

        if (bizField) {
          matchEntityTypeAndPushToEventFilters([], metricId, bizField);
        } else {
          if (userServiceField) {
            let userServices: string[] = [];
            if (userServiceField.allUserService) {
              userServices = Object.keys(eventTypeIdToFieldsMap);
            } else {
              userServices = WidgetConfigUtils.getUserServiceListFromUserServiceField(userServiceField);
            }
            matchEntityTypeAndPushToEventFilters(userServices, metricId, null);
          }

          if (!isEmpty(userServiceFieldsMap)) {
            forEach(userServiceFieldsMap, (usf, cMetricId) => {
              let userServices: string[] = [];
              if (usf.allUserService) {
                userServices = Object.keys(eventTypeIdToFieldsMap);
              } else {
                userServices = WidgetConfigUtils.getUserServiceListFromUserServiceField(usf);
              }
              matchEntityTypeAndPushToEventFilters(userServices, cMetricId, null);
            });
          }
        }
      });
    };

    const variablesLoading = loadingStateMap?.[key] === undefined ? true : loadingStateMap[key];
    if (appliedWidgets?.length > 0 && appliedWidgets.includes(widgetId)) {
      allEntityFilters = allEntityFilters.concat(entityFilters);
      allCohortFilters = allCohortFilters.concat(cohortFilters);
      allEntityVariables = allEntityVariables.concat(entityVariables);
    } else {
      entityFilters.forEach(filter => {
        const filterEntityType = filter.predicate?.bizField?.entityField?.entityType || "";
        if (filterEntityType === entityType) {
          allEntityFilters.push(filter);
        } else {
          findMatchingUserFilterAndPushToEntityFilters(filter, filterEntityType);
        }
      });
      cohortFilters.forEach(filter => {
        const filterEntityType = filter.predicate?.bizField?.entityField?.entityType;
        const entityTypeMatch = filter.predicate?.bizField?.entityField?.entityType === entityType;
        if (entityTypeMatch) {
          allCohortFilters.push(filter);
        } else {
          findMatchingUserFilterAndPushToEntityFilters(filter, filterEntityType);
        }
      });

      entityVariables.forEach((eVar: CohortVariableImpl) => {
        const entityTypeMatch = eVar.entityTypeId === entityType;
        if (entityTypeMatch) {
          allEntityVariables.push(eVar);
        }
      });
    }

    const allEventTypes = new Set([...Object.keys(filtersByEventType), ...Object.keys(filterTreeByEventType)]);
    allEventTypes.forEach(eventType => {
      const filterExpressions = filtersByEventType[eventType] || [];
      const filterTree = filterTreeByEventType[eventType];

      if (filterExpressions.length > 0 || filterTree) {
        let usFilterTree: UserServiceFilterExpressionTree = {
          filterNodes: [],
          logicalOperator: LogicalOperator.AND
        };

        if (filterTree) {
          if (filterExpressions.length) {
            usFilterTree.filterNodes.push({
              expressionTree: filterTree
            });
          } else {
            usFilterTree = cloneDeep(filterTree);
          }
        }

        filterExpressions.forEach(expression => {
          usFilterTree.filterNodes.push({
            expression
          });
        });

        allFiltersByEventType[eventType] = usFilterTree;
      }
    });

    scopedVariables = {
      ...scopedVariables,
      ...variables
    };
    eVariablesLoading = eVariablesLoading || variablesLoading;
  });

  return {
    entityFilters: allEntityFilters,
    cohortFilters: allCohortFilters,
    variables: scopedVariables,
    entityVariables: allEntityVariables,
    eventIdToFilters: allFiltersByEventType,
    variablesLoading: eVariablesLoading,
    entityEventFilters
  };
};

const checkIfEntityTypeMatches = (usField: UserServiceField, bizEntityType: string) => {
  if (usField.dataType === "ENTITY" && usField.entityField.entityType === bizEntityType) {
    return true;
  } else {
    return false;
  }
};

export const getVariableValues = (variableSrv: VariableSrv) => {
  const entityFilters = variableSrv.getEntityFilters();
  const cohortFilters = variableSrv.getCohortFilters();
  const scopedVars = variableSrv.getVariableValues();
  const entityVariables = variableSrv.getEntityCohortVariables();
  const filtersByEventType = variableSrv.getEventFiltersWithEventId();
  const filterTreeByEventType = variableSrv.getEventFiltersTreeWithEventId();

  return {
    entityFilters,
    cohortFilters,
    variables: scopedVars,
    entityVariables,
    filtersByEventType,
    filterTreeByEventType
  };
};

const applyAllFilters = (
  eventFilters: MetricUserServiceFilters,
  metricId: string,
  filterExprTree: UserServiceFilterExpressionTree
) => {
  if (eventFilters[metricId]) {
    const existingFilterNodes = eventFilters[metricId].expressionTree.filterNodes || [];
    eventFilters[metricId].expressionTree = {
      filterNodes: [...existingFilterNodes, ...filterExprTree.filterNodes],
      logicalOperator: LogicalOperator.AND
    };
  } else {
    eventFilters[metricId] = {
      expressionTree: filterExprTree
    };
  }
};

const applyEventFilters = (
  eventFilters: MetricUserServiceFilters,
  userServices: string[],
  filterExprTree: UserServiceFilterExpressionTree,
  eventId: string,
  metricId: string,
  isEntityMetric: boolean
) => {
  if (isEntityMetric) {
    applyAllFilters(eventFilters, metricId, filterExprTree);
  } else {
    if (eventId !== ALL_USERSERVICES_ENTITY_TYPE_ID) {
      if (userServices.find(x => x === eventId)) {
        applyAllFilters(eventFilters, metricId, filterExprTree);
      }
    } else {
      if (filterExprTree.logicalOperator === LogicalOperator.OR) {
        filterExprTree.filterNodes = [
          {
            expressionTree: {
              ...filterExprTree
            }
          }
        ];
        filterExprTree.logicalOperator = LogicalOperator.AND;
      }

      // currently only handling simple filter nodes for generic user service filters
      if (filterExprTree.logicalOperator === LogicalOperator.AND) {
        const currFilterNodes = filterExprTree.filterNodes;

        const filteredFilterNodes: UserServiceFilterNode[] = [];
        currFilterNodes.forEach(filterNode => {
          const userServicesSet = new Set<string>();
          extractUserServicesFromFilterNode(filterNode, userServicesSet);
          let userServiceIdExists = false;
          userServicesSet.forEach(cus => {
            userServices.forEach(us => {
              if (cus === us) {
                userServiceIdExists = true;
              }
            });
          });

          if (userServiceIdExists) {
            filteredFilterNodes.push(filterNode);
          }
        });
        const newFilterExprTree: UserServiceFilterExpressionTree = {
          filterNodes: filteredFilterNodes,
          logicalOperator: LogicalOperator.AND
        };
        applyAllFilters(eventFilters, metricId, newFilterExprTree);
      }
    }
  }
};

const applyEntityEventFilters = (
  eventFilters: MetricUserServiceFilters,
  entityEventExprTrees: Record<string, UserServiceFilterExpressionTree>
) =>
  forEach(entityEventExprTrees, (filterExprTree, metricId) => {
    applyAllFilters(eventFilters, metricId, filterExprTree);
  });

const getUserServiceExpressionFromBizFieldPredicate = (
  userServiceField: UserServiceFieldWithMeta,
  bizFieldPredicate: BizFieldPredicate
): UserServiceFilterExpression => {
  const usField: UserServiceField = {
    ...userServiceField.userServiceField,
    entityField: bizFieldPredicate?.bizField?.entityField
  };

  const usFieldExpr = {
    field: usField,
    operator: bizFieldPredicate.op,
    value: bizFieldPredicate.value,
    values: bizFieldPredicate.values
  };

  return usFieldExpr;
};

const extractUserServicesFromFilterNode = (usFilterNode: UserServiceFilterNode, userServicesSet: Set<string>) => {
  if (!usFilterNode) {
    return;
  }

  const { expression, expressionTree } = usFilterNode;

  if (expression) {
    const { userServices } = expression.field || {};
    userServices?.forEach(us => userServicesSet.add(us.userServiceEntityId));
  }

  if (expressionTree) {
    const { filterNodes } = expressionTree;
    filterNodes?.forEach(filterNode => extractUserServicesFromFilterNode(filterNode, userServicesSet));
  }
};

const getUsFieldFromBizField = (bizField: BizField): UserServiceFieldWithMeta => {
  const usField: UserServiceFieldWithMeta = {
    userServiceField: {
      bizEntityFieldName: "",
      dataType: bizField.entityField.propType as DataType,
      displayBizEntityFieldName: "",
      fieldName: bizField.entityField.propName,
      userServices: [],
      entityField: bizField.entityField
    },
    userServiceMetadata: {}
  };

  return usField;
};
