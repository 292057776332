import React, { FC, useState, useCallback, useEffect, useMemo } from "react";
import {
  ActionConfigUIParam,
  ActionCategoryType,
  ActionSourceTypes,
  BizActionConfig,
  OpCreationConfig,
  TemplateCanvas,
  operationaliseV2ApiService,
  ActionTemplateElementsResponse,
  ActionTemplateTextElement
} from "../../../../../../services/api/operationalise";
import { MultiLineTemplateEditor } from "../../../../actions/template-editor-v2/editors";
import { TemplateParamEditorWrapper } from "./TemplateParamEditorWrapper";

interface Props {
  uiParam: ActionConfigUIParam;
  categoryId: ActionCategoryType;
  sourceTypeId: ActionSourceTypes;
  paramName: string;
  bizActionConfig: BizActionConfig;
  opId?: string;
  opConfig?: OpCreationConfig;

  templateCanvas: TemplateCanvas;
  onChange: (templateCanvas: TemplateCanvas) => void;

  templateParams: string[];
  templateParamTokens: Record<string, string>;
  onTemplateParamTokensChange: (templateParamTokens: Record<string, string>) => void;

  onErrors?: (errors: string[], sectionKey: string) => void;
  filterOutLoopElements?: boolean;
  readOnly?: boolean;
}

export const MultiLineTemplateEditorWrapper: FC<Props> = props => {
  const {
    categoryId,
    sourceTypeId,
    paramName,
    bizActionConfig,
    opId,
    opConfig,
    onChange,
    uiParam,
    templateCanvas,
    templateParams,
    templateParamTokens,
    onTemplateParamTokensChange,
    filterOutLoopElements = false,
    onErrors,
    readOnly = false
  } = props;

  const { editable = true } = uiParam || {};

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [designerTemplate, setDesignerTemplate] = useState<ActionTemplateElementsResponse>(null);

  const fetchTemplateElements = useCallback(async () => {
    setIsFetching(true);
    const { data } = await operationaliseV2ApiService.getTemplateElements(
      categoryId,
      sourceTypeId,
      paramName,
      bizActionConfig,
      opId,
      opConfig
    );
    setDesignerTemplate(data);
    setIsFetching(false);
  }, [bizActionConfig, categoryId, opConfig, opId, paramName, sourceTypeId]);

  useEffect(() => {
    if (!designerTemplate) {
      fetchTemplateElements();
    }
  }, [designerTemplate, fetchTemplateElements]);

  const designerTemplateWithTemplateTokens = useMemo(
    () => handleTemplateParamTokenInDesignerTemplate(designerTemplate, templateParams),
    [designerTemplate, templateParams]
  );

  return (
    <>
      {Boolean(templateParams?.length) && (
        <TemplateParamEditorWrapper
          designerTemplate={designerTemplate}
          filterOutLoopElements={filterOutLoopElements}
          isLoading={isFetching}
          onTemplateParamTokensChange={onTemplateParamTokensChange}
          templateParamTokens={templateParamTokens}
          templateParams={templateParams}
        />
      )}

      <div className="template-editor-wrapper marginTp8">
        <MultiLineTemplateEditor
          designerTemplate={designerTemplateWithTemplateTokens}
          filterOutLoopElements={filterOutLoopElements}
          isLoading={isFetching}
          onChange={onChange}
          onErrors={onErrors}
          readOnly={!editable || readOnly}
          templateCanvas={templateCanvas}
        />
      </div>
    </>
  );
};

const handleTemplateParamTokenInDesignerTemplate = (
  designerTemplate: ActionTemplateElementsResponse,
  templateParams: string[]
): ActionTemplateElementsResponse => {
  if (!designerTemplate) {
    return;
  }

  const newTextElements = [
    ...(designerTemplate.textElement || []),
    ...templateParams.map(param => ({
      ...textElementTemplate,
      elementId: param,
      label: param,
      description: param,
      useInLoopOnly: true
    }))
  ];

  return {
    ...designerTemplate,
    textElement: newTextElements
  };
};

const textElementTemplate: ActionTemplateTextElement = {
  icon: "",
  elementId: "custom-text",
  label: "Custom Text",
  description: "Custom Text",
  type: "_str",
  subtype: "text",
  expression: "prop-display-text",
  displayText: "",
  displayMode: "use_label",
  prop: [],
  useInLoopOnly: false,
  group: "General",
  fieldOrder: 1
};
