import React, { FC, useMemo, useCallback, memo } from "react";
import { IncTextfield, IncFaIcon, IncToolTip } from "@inception/ui";
import { CypressConstants } from "@bicycle/tests";
import { VerticallyCenteredRow } from "../../../../components";
import { TimeObj } from "../../../../services/api/explore";
import { AccumulatorBasedTrigger } from "../../../../services/api/operationalise";
import { RollingFrequencyEditorV2 } from "../common";

type ATProps = {
  trigger: AccumulatorBasedTrigger;
  onChange: (trigger: AccumulatorBasedTrigger) => void;

  comparatorText: string;
  metricName: string;
  readOnly?: boolean;
  isV2?: boolean;
};

export const AccumTrigger: FC<ATProps> = memo(props => {
  const { onChange, trigger, comparatorText, metricName, readOnly, isV2 } = props;

  const { unitDiff, time } = trigger;

  const isValidCondition = Boolean(unitDiff) && (isV2 ? true : Boolean(time));
  const errorText = !unitDiff
    ? `Number of ${metricName} must be specified`
    : isV2
      ? ""
      : !time
        ? `Time must be specified`
        : "";

  const onUnitDiffChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.valueAsNumber;
      const nTrigger = {
        ...trigger,
        unitDiff: value
      };

      onChange(nTrigger);
    },
    [onChange, trigger]
  );

  const onTimeChange = useCallback(
    (timeObj: TimeObj) => {
      const nTrigger = {
        ...trigger,
        time: timeObj
      };

      onChange(nTrigger);
    },
    [onChange, trigger]
  );

  const formattedComparatorText = useMemo(() => comparatorText.split("/").join(" or "), [comparatorText]);

  const { attributes } = CypressConstants.components.Operationalize.TriggersEditor;

  return (
    <>
      <VerticallyCenteredRow className="inc-label-common">
        if there {unitDiff <= 1 ? "is" : "are"} atleast
      </VerticallyCenteredRow>

      <IncTextfield
        className="violation-count-textfield"
        data-cy={attributes.violationCountInput}
        onChange={onUnitDiffChange}
        type="number"
        value={unitDiff}
      />

      <VerticallyCenteredRow className="marginLt8 marginRt8 inc-label-common">
        {metricName} {formattedComparatorText} than usual {!isV2 && "in the last"}
      </VerticallyCenteredRow>

      {!isV2 && (
        <RollingFrequencyEditorV2
          onChange={onTimeChange}
          readOnly={readOnly}
          rollingFreq={time}
          skipPrefix
        />
      )}

      {!isValidCondition && (
        <IncToolTip
          placement="top"
          titleText={errorText}
          variant="error"
        >
          <VerticallyCenteredRow
            className="status-danger marginLt10"
            data-cy={attributes.errorMessage}
          >
            <IncFaIcon iconName="warning" />
          </VerticallyCenteredRow>
        </IncToolTip>
      )}
    </>
  );
});
