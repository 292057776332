import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IncButton, IncFaIcon, IncModal, IncModalProps, IncSmartText } from "@inception/ui";
import { cloneDeep } from "lodash";
import { OpFragmentEditProps } from "../../types";
import { VerticallyCenteredRow } from "../../../components";
import { useToggleState } from "../../../core";
import { OpCreationFragmentEditType } from "../../../services/api/operationalise";
import { ThresholdEditorWrapper } from "./ThresholdEditorWrapper";

type Props = OpFragmentEditProps;

export const ThresholdFragmentEditor: FC<Props> = props => {
  const { opFragment, invalidFragmentJsx } = props;

  if (opFragment?.thresholdWithContext?.opThreshold) {
    return <ThresholdFragmentEditorInternal {...props} />;
  }

  return invalidFragmentJsx;
};

const ThresholdFragmentEditorInternal: FC<Props> = props => {
  const { className, opFragment, onChange, onClose, demoDataParams, showEditorInModal = false } = props;

  const { isOpen: isUpdateInProgress, open: startUpdate, close: finishUpdate } = useToggleState();

  const errorMessageRef = useRef("");

  const {
    baseKpi,
    opSchedule,
    opThreshold: defThreshold,
    rollingFreq: baseMetricRollingFreq,
    rollingFunction: baseMetricRollingFunction
  } = opFragment.thresholdWithContext || {};
  const [threshold, setThreshold] = useState(cloneDeep(defThreshold));

  const resetThreshold = useCallback(() => {
    setThreshold(cloneDeep(defThreshold));
  }, [defThreshold]);

  useEffect(() => {
    resetThreshold();
  }, [resetThreshold]);

  const onCancelChanges = useCallback(() => {
    resetThreshold();
    onClose?.();
  }, [onClose, resetThreshold]);

  const onSaveChanges = useCallback(async () => {
    errorMessageRef.current = "";
    startUpdate();

    const { isError, message } = await onChange(
      {
        ...opFragment,
        thresholdWithContext: {
          ...opFragment.thresholdWithContext,
          opThreshold: threshold
        }
      },
      OpCreationFragmentEditType.THRESHOLD
    );

    if (isError) {
      errorMessageRef.current = message;
    }

    finishUpdate();
  }, [finishUpdate, onChange, opFragment, threshold, startUpdate]);

  const jsx = useMemo(
    () => (
      <div className={className}>
        <ThresholdEditorWrapper
          baseMetricRollingFreq={baseMetricRollingFreq}
          baseMetricRollingFunction={baseMetricRollingFunction}
          bizDataQuery={baseKpi}
          demoDataParams={demoDataParams}
          onChange={setThreshold}
          schedule={opSchedule}
          threshold={threshold}
        />

        {!showEditorInModal && (
          <VerticallyCenteredRow className="flex-gap-12 marginTp10">
            {!isUpdateInProgress && (
              <IncButton
                color="primary"
                label="Save Changes"
                onClick={onSaveChanges}
                size="small"
              />
            )}

            {isUpdateInProgress && (
              <IncButton
                color="primary"
                loading
                loadingText="Saving..."
                size="small"
              />
            )}

            {Boolean(errorMessageRef.current) && (
              <VerticallyCenteredRow className="status-danger flex-gap-10">
                <IncFaIcon
                  className="status-danger"
                  iconName="exclamation-triangle"
                />
                <IncSmartText
                  className="status-danger"
                  text={errorMessageRef.current}
                />
              </VerticallyCenteredRow>
            )}

            {!isUpdateInProgress && (
              <IncButton
                color="secondary-blue"
                label="Cancel"
                onClick={onCancelChanges}
                size="small"
              />
            )}
          </VerticallyCenteredRow>
        )}
      </div>
    ),
    [
      baseKpi,
      baseMetricRollingFreq,
      baseMetricRollingFunction,
      className,
      demoDataParams,
      isUpdateInProgress,
      onCancelChanges,
      onSaveChanges,
      opSchedule,
      showEditorInModal,
      threshold
    ]
  );

  const { close: closeModal, isOpen: IsModalOpen } = useToggleState(true);

  const actions: IncModalProps["actions"] = useMemo(
    () => ({
      primary: {
        label: "Save Changes",
        onClick: () => {
          closeModal();
          onSaveChanges();
        },
        color: "primary",
        showLoader: isUpdateInProgress
      },
      secondary: {
        label: "Cancel",
        onClick: () => {
          closeModal();
          onCancelChanges();
        },
        color: "secondary"
      }
    }),
    [closeModal, isUpdateInProgress, onCancelChanges, onSaveChanges]
  );

  return (
    <>
      {!showEditorInModal && jsx}
      {showEditorInModal && (
        <IncModal
          actions={actions}
          contentClassName="op-fragment-editors-modal-content padding16"
          onClose={closeModal}
          show={IsModalOpen}
          size="xxlg"
          titleText="Determine how to find anomalies"
          withActionsBorder
          withTitleBorder
        >
          {jsx}
        </IncModal>
      )}
    </>
  );
};
