export enum WidgetPropertyTypes {
  color = "color",
  datetime = "datetime",
  radio = "radio",
  select = "select",
  text = "text",
  toggle = "toggle",
  number = "number",
  textarea = "textarea"
}

interface Option {
  label: string;
  value: string;
}

interface WidgetBaseProperty {
  name: string;
  value: string | number | boolean;
  label?: string;
  disabled?: boolean;
  info?: string;
  type: WidgetPropertyTypes;
}

export interface WidgetRadioProperty extends WidgetBaseProperty {
  type: WidgetPropertyTypes.radio;
  options: Option[];
}

export interface WidgetToggleProperty extends WidgetBaseProperty {
  type: WidgetPropertyTypes.toggle;
}

export interface WidgetSelectProperty extends WidgetBaseProperty {
  type: WidgetPropertyTypes.select;
  options: Option[];
  placeholder?: string;
}

export interface WidgetTextProperty extends WidgetBaseProperty {
  type: WidgetPropertyTypes.text;
  updateOnBlur?: boolean;
  placeholder?: string;
}

export interface WidgetTextAreaProperty extends Omit<WidgetTextProperty, "type"> {
  type: WidgetPropertyTypes.textarea;
  rows?: number;
  resize?: boolean;
}

export interface WidgetNumberProperty extends WidgetBaseProperty {
  type: WidgetPropertyTypes.number;
  updateOnBlur?: boolean;
  defaultValue?: number;
  placeholder?: string;
}

export interface WidgetColorProperty extends WidgetBaseProperty {
  type: WidgetPropertyTypes.color;
  placeholder?: string;
  defaultValue?: string;
  mode: "hex" | "rgb";
}

export interface WidgetDateTimeProperty extends WidgetBaseProperty {
  type: WidgetPropertyTypes.datetime;
  updateOnBlur?: boolean;
  date: Date;
  placeholder?: string;
}

export type WidgetProperty =
  | WidgetRadioProperty
  | WidgetToggleProperty
  | WidgetSelectProperty
  | WidgetTextProperty
  | WidgetTextAreaProperty
  | WidgetColorProperty
  | WidgetDateTimeProperty
  | WidgetNumberProperty;

export interface WidgetPropertiesSection {
  name: string;
  info?: string;
  properties: WidgetProperty[];
}
