import {
  IncButton,
  IncModal,
  IncTextfield,
  IncLoadingSpinner,
  IncErrorIcon,
  IncToolTip,
  IncEditor
} from "@inception/ui";
import { cloneDeep, set } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { ConfirmationModal } from "../../../../../../platform/components/modals";
import { PreviewDataObj } from "../../../../../../platform/services/api/entity-mapping";
import { UserServiceFieldDef } from "../../../../../../platform/services/api/event-mapping";
import { useTimeRange, FieldPrimType, FieldSubType } from "../../../../../../platform/core";
import { useFetchTransformationPreview } from "../hooks";
import { getFieldsNameFromFieldValueMapping } from "../../../../../../platform/components/business-entity/utils/previewUtils";
import {
  FieldTransformationConfig,
  TransformType
} from "../../../../../../platform/transformation/transform-config/TransformConfig";
import { FieldTransformationForm } from "../../../../../../platform/transformation/FieldTransformationForm";
import { getUniqueFieldName } from "../../../../../../platform/transformation/transform-config/utils";
import { TransformationPreview } from "./TransformationPreview";

type Props = {
  configuredStreamId: string;
  previewData: PreviewDataObj;
  fieldDef: UserServiceFieldDef;
  show: boolean;
  onClose: () => void;
  onSave: (fieldDef: UserServiceFieldDef, fieldTransformationConfig: FieldTransformationConfig<TransformType>) => void;
};

const FieldTransformationModal: React.FC<Props> = (props: Props) => {
  const { show, fieldDef, onClose } = props;

  const onCloseDialog = useCallback(() => {
    onClose();
  }, [onClose]);

  const fieldHasConfig = Boolean(getTransformationConfig(fieldDef));

  return (
    <IncModal
      className="transformations-dialog-modal"
      onClose={onCloseDialog}
      show={show}
      titleText={`${fieldHasConfig ? "Edit" : "Add"} Transformation`}
    >
      <FieldTransformationDialog {...props} />
    </IncModal>
  );
};

const FieldTransformationDialog: React.FC<Props> = props => {
  const { fieldDef, onSave, configuredStreamId, previewData } = props;

  const [fieldTransformationConfig, setFieldTransformationConfig] =
    useState<FieldTransformationConfig<TransformType>>();
  const [showConfirmRemove, setShowConfirmRemove] = useState<boolean>(false);
  const [save, setSave] = useState<boolean>(false);
  const [isSaveError, setIsSaveError] = useState<boolean>(false);
  const [customInput, setCustomInput] = useState<string | Record<string, any>>();
  const [isCustomInputError, setCustomInputError] = useState<boolean>(false);

  const {
    timeRange: { from, to }
  } = useTimeRange();

  const removeConfig = useCallback(() => {
    onSave(fieldDef, null);
  }, [fieldDef, onSave]);

  const onRemoveClick = useCallback(() => {
    setShowConfirmRemove(true);
  }, []);

  const {
    data: usfieldPreviewResponse,
    isFetching,
    isError,
    isSuccess,
    fetchPreview
  } = useFetchTransformationPreview();
  const isTryEnabled = fieldTransformationConfig && configuredStreamId && fieldDef && previewData;

  const tryTranformation = useCallback(
    (save: boolean) => {
      setSave(save);
      const clonedPreview = cloneDeep(previewData);
      if (customInput) {
        const fieldName = getFieldsNameFromFieldValueMapping(fieldDef.fieldMapping.valueMapping);
        const parsedJSONRecord = JSON.parse(clonedPreview.jsonRecords[0]);
        set(parsedJSONRecord, fieldName, customInput);
        clonedPreview.jsonRecords = [JSON.stringify(parsedJSONRecord)];
      }
      const fieldWithTransformation = upsertTransformation(cloneDeep(fieldDef), fieldTransformationConfig);
      const payload = {
        fieldDef: fieldWithTransformation,
        previewData: clonedPreview
      };
      fetchPreview(configuredStreamId, payload, from.valueOf(), to.valueOf());
    },
    [customInput, fieldDef, fieldTransformationConfig, previewData, fetchPreview, configuredStreamId, from, to]
  );

  const onSaveClick = useCallback(() => {
    setIsSaveError(false);
    tryTranformation(true);
  }, [tryTranformation]);

  const onTryClick = useCallback(() => {
    tryTranformation(false);
  }, [tryTranformation]);

  useEffect(() => {
    if (!save || isFetching) {
      return;
    }
    if (isSuccess) {
      onSave(fieldDef, fieldTransformationConfig);
    } else if (isError) {
      setIsSaveError(true);
    }
  }, [isFetching, isError, isSuccess, save, fieldDef, onSave, fieldTransformationConfig]);

  useEffect(() => {
    setIsSaveError(false);
  }, [fieldTransformationConfig]);

  useEffect(() => {
    if (fieldDef) {
      const obj = getTransformationConfig(fieldDef);
      setFieldTransformationConfig(obj);
    }
  }, [fieldDef]);

  const fieldHasConfig = Boolean(getTransformationConfig(fieldDef));

  const hasFieldToSubmit = Boolean(fieldTransformationConfig);

  const saveErrorMessage = !hasFieldToSubmit
    ? "Valid transformation is required"
    : isSaveError
      ? "Transformation try failed"
      : "";

  const onCustomInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCustomInput(value);
  }, []);

  const onCustomJSONInputChange = useCallback(
    (value: string) => {
      if (value) {
        if (fieldDef.datatype === "_map" || fieldDef.datatype === "_set") {
          try {
            value = JSON.parse(value);
            setCustomInputError(false);
          } catch (e) {
            setCustomInputError(true);
          }
        }
      }
      setCustomInput(value ?? "");
    },
    [fieldDef]
  );

  return (
    <div>
      <IncTextfield
        className="text-field"
        disabled={true}
        helpText="copy/use this path while adding an expression"
        label="Json Path"
        value={fieldDef?.fieldMapping?.valueMapping?.jsonPath ?? ""}
      />
      {
        <FieldTransformationForm
          config={fieldTransformationConfig}
          fieldDataType={fieldDef?.datatype as FieldPrimType}
          fieldSubType={fieldDef?.subtype as FieldSubType}
          onChange={setFieldTransformationConfig}
          showWarning
          uniqueFieldName={getUniqueFieldName(fieldDef)}
        />
      }
      {hasFieldToSubmit && (
        <div className="marginBt16">
          <details>
            <summary>
              <span className="inc-text-subtext-bold">Try with custom input</span>
            </summary>
            <div className="marginLt16 marginTp8">
              {fieldDef.datatype === "_set" || fieldDef.datatype === "_map" ? (
                <IncEditor
                  label="Input"
                  maxLines={10}
                  mode="json"
                  onChange={onCustomJSONInputChange}
                />
              ) : (
                <IncTextfield
                  label="Input"
                  onChange={onCustomInputChange}
                />
              )}
              {isCustomInputError && (
                <div className="inc-text-subtext marginTp8 status-danger">Incorrect input. Please check syntax</div>
              )}
              <div className="inc-text-subtext marginTp8 status-warning">
                NOTE: Remove/clear above input to try transformation with actual response
              </div>
            </div>
          </details>
        </div>
      )}
      <div className="marginBt16 marginTp16">
        <div className="inc-flex-row">
          <IncButton
            className="marginRt16"
            color="primary"
            disabled={!isTryEnabled}
            onClick={onTryClick}
          >
            Try
          </IncButton>
          <IncButton
            color="primary"
            disabled={!hasFieldToSubmit}
            onClick={onSaveClick}
          >
            Save
          </IncButton>
          {saveErrorMessage && (
            <IncToolTip
              placement="right"
              titleText={saveErrorMessage}
              variant="error"
            >
              <IncErrorIcon className="status-danger" />
            </IncToolTip>
          )}
          {save && isFetching && (
            <span className="marginLt4">
              <IncLoadingSpinner label="" />
            </span>
          )}
          {fieldHasConfig && (
            <IncButton
              className="m-l-auto"
              color="danger"
              onClick={onRemoveClick}
            >
              Delete
            </IncButton>
          )}
          <ConfirmationModal
            bodyStringId="common.actions.delete.confirm"
            confirmBtnStringId="common.actions.delete"
            headerStringId="common.actions.delete"
            onCancel={() => setShowConfirmRemove(false)}
            onClose={() => setShowConfirmRemove(false)}
            onConfirm={removeConfig}
            open={showConfirmRemove}
          />
        </div>
        {!save && (
          <div className="marginTp16">
            {isFetching && <IncLoadingSpinner label="Fetching preview" />}
            {!isFetching && isError && (
              <span className="status-danger inc-flex-center">
                <IncErrorIcon className="status-danger" />
                Transformation failed
              </span>
            )}
            {!isFetching && isSuccess && usfieldPreviewResponse && (
              <TransformationPreview previewData={usfieldPreviewResponse} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FieldTransformationModal;

function getTransformationConfig(fieldDef: UserServiceFieldDef) {
  const dataTransformations: Array<FieldTransformationConfig<TransformType>> =
    fieldDef?.fieldMapping?.valueMapping?.dataTransformations;
  return dataTransformations?.length ? dataTransformations[0] : null;
}

function upsertTransformation(
  fieldDef: UserServiceFieldDef,
  fieldTransformationConfig: FieldTransformationConfig<TransformType>
) {
  fieldDef.fieldMapping.valueMapping.dataTransformations = fieldTransformationConfig ? [fieldTransformationConfig] : [];
  fieldDef.datatype = fieldTransformationConfig?.outputType ?? fieldDef.datatype;
  fieldDef.subtype = null;
  return fieldDef;
}
