export const NODE_HEIGHT = 50;
export const TITLE_NODE_START_POS = 100;
export const TITLE_NODE_HEIGHT = 40;
export const NODE_BUFFER_HEIGHT = 15;
export const SEARCH_NODE_START_POS = TITLE_NODE_START_POS + TITLE_NODE_HEIGHT + NODE_BUFFER_HEIGHT;
export const SEARCH_NODE_HEIGHT = TITLE_NODE_HEIGHT;
export const GROUP_BUFFER_HEIGHT = 30;
export const MAPPING_GROUP_X_POS = 100;
export const MAPPING_GROUP_Y_POS = SEARCH_NODE_START_POS + SEARCH_NODE_HEIGHT + NODE_BUFFER_HEIGHT;
export const MAPPING_NODE_X_POS = 10;

export const MAPPING_NODE_Y_POS = 10;

export const NODE_WIDTH = 200;
export const NODE_BUFFER_WIDTH = 150;
export const DUMMY_NODE_BUFFER = -1;
export const EVENT_NODE_X_POS = MAPPING_GROUP_X_POS + NODE_WIDTH + NODE_BUFFER_WIDTH;
export const DUMMY_NODE_X_POS = MAPPING_GROUP_X_POS + NODE_WIDTH + NODE_BUFFER_HEIGHT / 2;

export const MAPPING_GROUP_NODE = "mappingGroupNode";
export const MAPPING_SOURCE_NODE = "mappingSourceNode";
export const MAPPING_EVENT_NODE = "mappingEventNode";
export const MAPPING_DOT_NODE = "mappingDotNode";
export const MAPPING_ACTIONS_NODE = "mappingActionsNode";
export const SPLIT_EVENT_TYPE_NODE = "splitEventTypeNode";
export const FIELD_CATEGORY_NODE = "fieldCategoryNode";
export const ENTITY_NODE = "entityTypeNode";
export const GROUP_NODE = "groupTypeNode";
export const BUTTON_NODE = "buttonTypeNode";
export const NESTED_GROUP_NODE = "nestedGroupNode";
export const TITLE_NODE = "mappingTitleNode";
export const SEARCH_NODE = "mappingSearchNode";
export const METRIC_NODE = "metricNode";
export const OPERATIONALIZE_NODE = "operationalizeNode";

export const MAPPING_EDGE_STYLE = {
  stroke: "#4A505C",
  strokeWidth: 1
};

export const MAPPING_EDGE_STYLE_UNVERIFIED = {
  stroke: "#8D8F92",
  strokeWidth: 1
};

export const MAPPING_EDGE_HIGHLIGHT_STYLE = {
  stroke: "#39ACFF",
  strokeWidth: 1
};
