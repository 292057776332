import { KPI } from "../../services/api";
import { KPIDTO } from "./types";

export const getKPIDTOForKPI = (kpi: KPI): KPIDTO => ({
  ...kpi,
  workflowDTOs: kpi.workflows.map(w => ({
    ...w,
    isSavedAsConfig: true
  }))
});

export const getKPIForKPIDTO = (kpi: KPIDTO): KPI => ({
  ...kpi,
  workflows: kpi.workflowDTOs.map(w => ({ ...w }))
});
