import React, { FC, useCallback, useMemo, useState } from "react";
import { CurrencyType, IncModal, IncSelect, IncSelectOption } from "@inception/ui";
import { Datum } from "../utils";
import { DataType, TimeRange } from "../../../../../../../core";
import { CatalogWidgetProperties } from "../../../../models";
import {
  CohortEntityFilter,
  MetricUserServiceFilters,
  WidgetResponseDTO
} from "../../../../../../../services/api/explore";
import { VerticallyCenteredRow } from "../../../../../../../components";
import RawEventTable from "./RawEventTable";
import TableInfo, { formatLabel } from "./TableInfo";
import ChangeEventTimeLine from "./ChangeEventTimeLine";

type Props = {
  tableInfo?: Datum;
  timeRange: TimeRange;
  metricNames?: Record<string, string>;
  matricIds: string[];
  aggTags: string[];
  displayAggTags: string[];
  fieldName: string;
  dataType: DataType;
  widgetTitle: string;
  currencyType: CurrencyType;
  widgetResponseDto: WidgetResponseDTO;
  widgetProperties: CatalogWidgetProperties;
  entityType: string;
  eventTypeId?: string;
  version?: number;
  entityLookup: Record<string, string>;
  open: boolean;
  downsample: string;
  tagsDataType: Record<string, DataType>;
  closeDrawer: () => void;
  edit?: boolean;
  entityFilters?: CohortEntityFilter[];
  cohortFilters?: CohortEntityFilter[];
  metricUserServiceFilters?: MetricUserServiceFilters;
  onSaveCustomHeaderMap?: (metricId: string, selectionMap: Record<string, string>) => void;
};

type ChartType = "none" | "column" | "gantt";

const getExploreEntityFiltersFromCohortFilters = (cohortEntityFilters: CohortEntityFilter[]) => {
  const predicates = cohortEntityFilters
    ?.filter(({ fieldType }) => fieldType === "bizEntityField")
    .map(({ predicate }) => predicate);
  return predicates?.length
    ? [
        {
          filters: predicates
        }
      ]
    : [];
};

const ChangeEventDrawer: FC<Props> = props => {
  const {
    tableInfo,
    timeRange,
    metricNames,
    aggTags,
    edit,
    widgetTitle,
    matricIds,
    currencyType,
    dataType,
    fieldName,
    widgetProperties,
    widgetResponseDto,
    eventTypeId: pEventId,
    entityType: pEntityType,
    displayAggTags,
    version,
    downsample,
    entityLookup,
    open,
    closeDrawer,
    onSaveCustomHeaderMap,
    tagsDataType,
    entityFilters: pEntityFilter,
    cohortFilters,
    metricUserServiceFilters
  } = props;

  const { bizEntityType, userServiceEntityId } = widgetResponseDto?.widgetConfig || {};
  const entityType = bizEntityType || pEntityType;
  const eventId = entityType ? null : userServiceEntityId || pEventId;

  const { metrics = {} } = widgetResponseDto?.widgetConfig?.dataDefinition || {};
  const metricOptions = useMemo(() => {
    const options: IncSelectOption[] = [];
    matricIds.forEach(e => {
      const metric = metrics?.[e];
      if (metric?.sourceType !== "expression") {
        options.push({
          label: formatLabel(e, metricNames),
          value: e
        });
      }
    });
    return options;
  }, [matricIds, metricNames, metrics]);

  const [selectedMetricOption, setSelectedMetricOption] = useState<IncSelectOption>(metricOptions?.[0]);

  const onSelect = useCallback((option: IncSelectOption) => {
    setSelectedMetricOption(option);
  }, []);
  const metricId = useMemo(() => selectedMetricOption?.value, [selectedMetricOption?.value]);

  const { columnType, metricType } = useMemo(() => {
    const metric = metrics?.[metricId];
    return {
      metricType: metric?.sourceType,
      columnType: metric?.subType
    };
  }, [metricId, metrics]);

  const formattedLabel = useMemo(() => widgetTitle, [widgetTitle]);

  const chartType: ChartType = useMemo(() => {
    const type = columnType || dataType;

    if (metricType !== "userServiceField") {
      return "none";
    } else {
      if (type === "BOOLEAN") {
        return "gantt";
      }
      return "column";
    }
  }, [columnType, dataType, metricType]);
  const filters = useMemo(() => [...(pEntityFilter || []), ...(cohortFilters || [])], [cohortFilters, pEntityFilter]);
  const entityFilters = useMemo(() => getExploreEntityFiltersFromCohortFilters(filters), [filters]);

  const onSave = useCallback(
    (selectionMap: Record<string, string>) => {
      onSaveCustomHeaderMap(metricId, selectionMap);
    },
    [metricId, onSaveCustomHeaderMap]
  );

  return (
    open && (
      <IncModal
        className="fields-drilldown-drawer-modal widget-event-drawer"
        onClose={closeDrawer}
        show
        size="side-pane"
        titleText={`${formattedLabel}`}
        withTitleBorder
      >
        <div className="paddingTp12 inc-flex-column height-100">
          <TableInfo
            aggTags={aggTags}
            currencyType={currencyType}
            data={tableInfo}
            dataType={dataType}
            displayAggTags={displayAggTags}
            entityLookup={entityLookup}
            fieldName={fieldName}
            matricIds={matricIds}
            metricNames={metricNames}
            tagsDataType={tagsDataType}
            widgetProperties={widgetProperties}
            widgetResponseDto={widgetResponseDto}
          />
          {metricOptions?.length ? (
            <>
              <div className="marginTp16 inc-flex-row inc-flex-space-contents">
                <IncSelect
                  autoAdjustWidth
                  onChange={onSelect}
                  options={metricOptions}
                  value={selectedMetricOption}
                />
              </div>
              {metricId ? (
                <>
                  {chartType !== "none" && (
                    <ChangeEventTimeLine
                      aggTags={aggTags}
                      chartLabel={`${formattedLabel} counts`}
                      chartType={chartType}
                      cohortFilters={filters}
                      downsample={downsample}
                      entityType={entityType}
                      eventTypeId={eventId}
                      metricId={metricId}
                      metricUserServiceFilters={metricUserServiceFilters}
                      tableInfo={tableInfo}
                      timeRange={timeRange}
                      version={version}
                      widgetProperties={widgetProperties}
                      widgetResponseDto={widgetResponseDto}
                    />
                  )}
                  <RawEventTable
                    aggTags={aggTags}
                    chartType={chartType}
                    currencyType={currencyType}
                    downsample={downsample}
                    edit={edit}
                    entityFilters={entityFilters}
                    entityType={entityType}
                    eventTypeId={eventId}
                    metricId={metricId}
                    metricUserServiceFilters={metricUserServiceFilters}
                    onSaveCustomHeaderMap={onSave}
                    tableInfo={tableInfo}
                    timeRange={timeRange}
                    widgetProperties={widgetProperties}
                    widgetResponseDto={widgetResponseDto}
                  />
                </>
              ) : (
                <VerticallyCenteredRow>No metrics found</VerticallyCenteredRow>
              )}
            </>
          ) : (
            <VerticallyCenteredRow>No metrics found</VerticallyCenteredRow>
          )}
        </div>
      </IncModal>
    )
  );
};

export default ChangeEventDrawer;
