import React, { FC, useMemo, useState } from "react";
import { DemoDataParams, WidgetConfigUtils } from "../../services/api/explore";
import { OpCreationConfig } from "../../services/api/operationalise";
import { OpResultsRenderer } from "../../operationalise-v2/v3/results/OpResultsRenderer";

interface Props {
  widgetId: string;
  opConfigId: string;
  opCreationConfig: OpCreationConfig;

  demoDataParams?: DemoDataParams;
}

export const OpRecipeSimulationList: FC<Props> = props => {
  const { opConfigId, opCreationConfig: parentOpCreationConfig, demoDataParams, widgetId } = props;

  const simulations = useMemo(() => parentOpCreationConfig?.simulations || [], [parentOpCreationConfig]);
  const [selectedSimulation, setSelectedSimulation] = useState(simulations[0]);
  const { opCreationConfig, labels } = selectedSimulation || {};
  const { idProps } = opCreationConfig || {};
  const isPrimarySimulationOrOp = labels?.isPrimary === "true";
  const { entityTypeId: entityTypeName } = useMemo(
    () => WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(idProps),
    [idProps]
  );

  const simulationItems = useMemo(
    () =>
      simulations.map(simulation => {
        const { simulationId, name } = simulation;
        const isSelected = selectedSimulation?.simulationId === simulationId;
        return (
          <div
            className="simulation-item inc-cursor-pointer"
            data-selected={isSelected}
            key={simulationId}
            onClick={() => setSelectedSimulation(simulation)}
          >
            {name}
          </div>
        );
      }),
    [selectedSimulation, simulations]
  );

  return (
    <div className="op-simulation-history">
      <div className="op-simulation-history--list">{simulationItems}</div>
      <div className="op-simulation-history--results op-v3-results">
        {Boolean(opCreationConfig) && (
          <OpResultsRenderer
            demoDataParams={demoDataParams}
            entityTypeId={entityTypeName}
            entityTypeName={entityTypeName}
            isInProgress={false}
            isPrimarySimulationOrOp={isPrimarySimulationOrOp}
            opConfigId={opConfigId}
            opCreationConfig={opCreationConfig}
            showRecipe
            widgetId={widgetId}
          />
        )}
      </div>
    </div>
  );
};
