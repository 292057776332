import { UsecaseDefinitionSummary } from "../../use-case-catalog/types/use-case-v2";
import {
  BizDataQuery,
  ResponseInfo,
  ExploreEntityFilter,
  UserServiceField,
  UserServiceFieldSliceSet,
  UserServiceFilterList,
  CorrelatedEventsRequest,
  CorrelatedEventsResponse,
  WidgetAlertSummaryRequest2,
  TriageClustersResponse,
  FunnelData
} from "../../explore";
import { SequenceDiagram } from "../../onboarding";
import { BizIdProps, OpCreationConfig } from "../../operationalise";
import { HighChartsFragment, LowLevelFragment, Plan, PlanExecutionResponse, VizFragment } from "./low-level-fragment";
import { ConversationStatus } from "./common2";
import { GenerationFragment } from "./generation-fragment";

export interface ConversationResponse {
  fragment: ConversationFragment[];
  message: string;
  chatRecommendations?: ConversationRecommendation;

  messageId?: string;
  companyName?: string;
  status?: ConversationStatus;
  title?: string;
  references?: ConversationFragment[];

  /**
   * @deprecated use chatRecommendations instead
   */
  recommendations?: string[];
}

export type ConversationRecommendation = {
  recommendations: LowLevelFragment[];
  status: ConversationStatus;
};

export interface ConversationFragment {
  type: ConversationFragmentType;
  fragment?: Fragment;
  title: string;

  bicycleQuery?: string;
  bicycleResponse?: string;
  responseInfo?: ResponseInfo;
  entityLookupData?: Record<string, string>;
  uiExtraInfo?: Record<string, string | boolean | number>;

  // one Of
  plan?: Plan;
  lowLevelFragment?: LowLevelFragment;
  planExecutionResponse?: PlanExecutionResponse;
  generationFragment?: GenerationFragment;
}

export enum ConversationFragmentType {
  NA = "NA",
  MARKDOWN = "MARKDOWN",
  WIDGET = "WIDGET",
  QUERY = "QUERY",
  FLOW = "FLOW",
  DRILLDOWN = "DRILLDOWN",
  OP10ZE = "OP10ZE",
  RECOMMENDATIONS = "RECOMMENDATIONS",
  // Onboarding specific fragments
  BIZ_PROCESS = "BIZ_PROCESS",
  OPTIONS = "OPTIONS",
  NEXT_REQUEST = "NEXT_REQUEST",
  GENERATE_SEQUENCE_DIAGRAM = "GENERATE_SEQUENCE_DIAGRAM", // UI only
  START_DEMO_DATA_GENERATION = "START_DEMO_DATA_GENERATION", // UI only
  AGENT_OUTPUT = "AGENT_OUTPUT",
  STEP = "STEP",
  TRIAGE = "TRIAGE",
  TRIAGE_REQUEST_PAYLOAD = "TRIAGE_REQUEST_PAYLOAD",
  CORRELATED_EVENTS = "CORRELATED_EVENTS",
  TRIAGE_CLUSTERS_RESPONSE = "TRIAGE_CLUSTERS_RESPONSE",
  CORRELATED_EVENTS_RESPONSE = "CORRELATED_EVENTS_RESPONSE",
  CORRELATED_EVENTS_WIDGET_PAYLOAD = "CORRELATED_EVENTS_WIDGET_PAYLOAD",
  FUNNEL_TRIAGE_PAYLOAD = "FUNNEL_TRIAGE_PAYLOAD",
  HIGHCHARTS = "HIGHCHARTS"
}

export interface Fragment {
  response?: string; // Markdown
  dataQuery?: BizDataQuery;
  vizFragment?: VizFragment;
  queryFragment?: QueryFragment;
  opCreationConfig?: OpCreationConfig;
  bizDataOperationalize?: {
    opCreationConfig?: OpCreationConfig;
  };

  // Onboarding specific fragments
  optionsFragment?: OptionsFragment;
  nextRequestFragment?: NextRequestFragment;
  sequenceDiagram?: SequenceDiagram;
  useCaseSummary?: UsecaseDefinitionSummary;
  useCaseSummaryList?: UseCaseSummaryList;

  // Step and agent related fragments
  agentOutput?: AgentOutput;
  stepOutput?: StepOutput;

  // Triage specific fragments
  triageRequestPayload?: WidgetAlertSummaryRequest2;
  triageClustersResponse?: TriageClustersResponse;
  correlatedEventsRequest?: CorrelatedEventsRequest;
  correlatedEventSearchResponse?: CorrelatedEventsResponse;
  correlatedEventWidgetPayload?: CorrelatedEventWidgetPayload;
  funnelTriagePayload?: FunnelTriagePayload;

  // HighCharts
  highchartsFragment?: HighChartsFragment;
}

export interface FunnelTriagePayload {
  funnelData: FunnelData;

  //oneOf
  triageRequestPayload?: WidgetAlertSummaryRequest2;
  triageClustersResponse?: TriageClustersResponse;
}

export interface CorrelatedEventWidgetPayload {
  correlatedEventSearchRequest: CorrelatedEventsRequest;
  vizFragment: VizFragment[];
}

export interface StepOutput {
  stepName: string;
  icon: string;
  conversationResponse: ConversationResponse;
}

export interface AgentOutput {
  stepOutput: StepOutput[];
}

export interface UseCaseSummaryList {
  useCaseSummaryList?: UsecaseDefinitionSummary[];
}

export interface QueryFragment {
  bizIdProps: BizIdProps;
  entityFilters: ExploreEntityFilter[];
  eventTypeFilters: UserServiceFilterList;
  userServiceField: UserServiceField;
  sliceSet: UserServiceFieldSliceSet;
}

export type RawDataProperties = {
  preselectedDims?: string[];
};

export type VizFragmentProperties = {
  rawDataProperties?: RawDataProperties;
};

export interface OnboardingContext {
  companyName: string;
  subVertical: string;
  vertical: string;
  useCaseSummary: string;
}

export interface NextRequestFragment {
  onboardingContext?: OnboardingContext;
  dataOnboardingContext?: DataOnboardingContext;
  createCopilotContext?: CreateCopilotContext;
}

export interface CreateCopilotContext {
  companyName: string;
  vertical: string;
  subVertical: string;
  subVerticals: string[];
}

export interface DataOnboardingContext {
  companyName: string;
  entitySourceTypeId: string;
  eventSourceTypeId: string;
  eventSourceTypeName: string;
  entitySourceTypeName: string;
}

export interface OptionsFragment {
  option: OptionsFragmentOption[];
}

export type OptionsFragmentOption = {
  textOption: TextFragment;
};

export type TextFragment = {
  icon: string;
  text: string;
  iconType: string;
};

export enum ChatBotStrategy {
  DEFAULT = "DEFAULT",
  GPT4 = "GPT-4",
  GPT3_5 = "GPT-3.5"
}

export interface FeedbackInput {
  category: string;
  isPositive: boolean;
  comment: string;
}
