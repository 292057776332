import React, { FC } from "react";
import { IncDateTimeFormat } from "@inception/ui";
import { TimeRangeSelector, QuickTab } from "../../../components/time-range";
import { TimeRange } from "../../../core";

interface Props {
  timeRange: TimeRange;
  onChange: (nTr: TimeRange) => void;
}

export const AddMetricTimeRangeSelector: FC<Props> = props => {
  const { onChange, timeRange } = props;

  return (
    <TimeRangeSelector
      allowFutureDates={false}
      allowSameTimes={false}
      buttonDisplay
      enableBeginningAndEndTimes={false}
      format={IncDateTimeFormat.numeric}
      hideTimeSelect={false}
      i18nDisabled={false}
      onTimeSelect={onChange}
      quickTabs={tabs}
      timeRange={timeRange}
      withSeconds={true}
    />
  );
};

const tabs = ["Last"] as QuickTab[];
