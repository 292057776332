import React, { useEffect, useCallback, FC, useMemo, useState } from "react";
import { IncButton, IncRTable, IncRTableProps, TableDataItem } from "@inception/ui";
import { useInceptionRoute, useFetchDashboards } from "../core";
import { routePaths } from "../../app/RoutePaths";
import { DashboardListItem } from "./api";

interface Props {
  onSelectionChanged: (selectedDb: DashboardListItem[]) => void;
  allowCreation?: boolean;
  ignoreList?: string[];
  pagination?: {
    enabled: boolean;
    pageSize?: number;
  };
  globalFilter?: {
    label?: string;
    placeholder?: string;
  };
  variant?: "transparent" | "default";
  preSelectedDashboardIds?: string[];
}

type DataItem = DashboardListItem & TableDataItem;

const DashboardSelector: FC<Props> = props => {
  const {
    onSelectionChanged,
    allowCreation,
    pagination,
    ignoreList,
    variant = "default",
    preSelectedDashboardIds,
    globalFilter
  } = props;

  const { navigate } = useInceptionRoute();
  const [dashboardsList, setDashboardsList] = useState<DataItem[]>([]);

  const { data, error, isError, isFetching } = useFetchDashboards();

  const updateDashboards = useCallback(() => {
    if (!isFetching && !error) {
      const shouldInclude = (db: DashboardListItem) => !ignoreList?.includes(db.id);

      const dbList: DataItem[] = (data || []).filter(shouldInclude).map(db => ({
        ...db,
        rowSelected: false
      }));
      if (preSelectedDashboardIds?.length) {
        const selDbs = dbList.filter(db => preSelectedDashboardIds?.includes(db.id));
        selDbs.forEach(db => (db.rowSelected = true));
        onSelectionChanged(selDbs);
      }

      setDashboardsList(dbList);
    } else {
      setDashboardsList([]);
    }
  }, [isFetching, error, data, preSelectedDashboardIds, ignoreList, onSelectionChanged]);

  const onCreate = useCallback(() => {
    const route = `${routePaths.dashboards}/add`;
    navigate(route, {
      newTab: true
    });
  }, [navigate]);

  useEffect(() => {
    updateDashboards();
  }, [updateDashboards]);

  const onRowClick = useCallback(() => {}, []);

  const globalFilterConfig = useMemo<IncRTableProps["globalFilter"]>(
    () => ({
      enabled: true,
      align: "left",
      hideStatus: true,
      placeholder: globalFilter?.placeholder || "Search dashboards",
      label: globalFilter?.label || "",
      hideLabel: !globalFilter?.label
    }),
    [globalFilter]
  );

  return (
    <>
      {allowCreation && (
        <IncButton
          color="secondary"
          onClick={onCreate}
          style={{ margin: "0 0 8px auto" }}
        >
          Create dashboard
        </IncButton>
      )}
      <IncRTable
        addRowSelectionColumn
        classNames={{
          table: "save-metric-table",
          row: "save-metric-row",
          header: "save-metric-header--multi-row",
          body: "dash-select-table-body"
        }}
        columns={[
          {
            accessor: "name",
            header: "Name",
            type: "string"
          }
        ]}
        data={dashboardsList}
        errorDataMessage={error as string}
        globalFilter={globalFilterConfig}
        hasError={isError}
        hideHeaders
        isLoading={isFetching}
        noDataMessage="No dashboards found."
        onRowClick={onRowClick}
        onSelectionChange={onSelectionChanged}
        pagination={pagination}
        variant={variant}
      />
    </>
  );
};

export default DashboardSelector;
