import {
  getSelectOptionsFromStringArray,
  IncButton,
  IncFaIcon,
  IncModal,
  IncMultiSelectCheckBox,
  IncRTable,
  IncRTableProps,
  IncSelectOption,
  IncSmartText,
  IncTextfield,
  TableDataColumn,
  TableDataItem
} from "@inception/ui";
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SimpleEntityNameRenderer } from "..";
import { useTimeRange } from "../../core";
import { useFieldPicker } from "../../field-picker";
import entityStoreApiService from "../../services/api/EntityStoreApiService";
import { BizField, FieldPickerContextDTO } from "../../services/api/explore";
import { pluralizeWord } from "../../utils";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { EntityIconRenderer } from "../entity-properties-popover/EntityNameRenderer";
import LoadingSpinner from "../Loading/Loading";
import { EntityListData } from "./EntityList";

export interface EntitiesPanelProps {
  bizEntityType: string;
  show: boolean;
  onClose: () => void;

  initialData?: EntityListData;
  title?: string;

  searchable?: boolean;
  debounceTimeSecs?: number;
  onSearch?: (searchStr: string) => Promise<EntityListData>;

  showEntityInfoOnHover?: boolean;
  renderAsNonEntity?: boolean;
  cohortId?: string;
  showDefaultColumnsOnly?: boolean;
  skipIdColumn?: boolean;
  restrictAddingColumns?: boolean;

  addlColumns?: TableDataColumn[];
  sortBy?: IncRTableProps["sort"];
  disableDownload?: boolean;
  children?: JSX.Element;
}

const defaultColumns = ["Name", "Id"];

export const EntitiesPanel: React.FC<EntitiesPanelProps> = props => {
  const {
    bizEntityType,
    onClose,
    show,
    debounceTimeSecs = 300,
    initialData: pInitialData,
    onSearch: pOnSearch,
    searchable,
    title,
    renderAsNonEntity = false,
    showEntityInfoOnHover = true,
    cohortId,
    restrictAddingColumns = false,
    showDefaultColumnsOnly = false,
    skipIdColumn = false,
    addlColumns,
    disableDownload = false,
    children,
    sortBy
  } = props;

  const [fetchingEntities, setFetchingEntities] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [columns, setColumns] = useState<string[]>([]);

  const initialData = useMemo<EntityListData>(
    () =>
      pInitialData
        ? {
            entityIds: pInitialData.entityIds || [],
            entityLookupData: pInitialData.entityLookupData || {},
            entityDetailsData: pInitialData.entityDetailsData || {}
          }
        : null,
    [pInitialData]
  );

  const dataInitialisedRef = useRef(Boolean(initialData));
  const columnsInitialisedRef = useRef(false);
  const prevCohortId = useRef(cohortId);
  const [data, setData] = useState<EntityListData>(initialData);
  const [fileHref, setFileHref] = useState("");

  const { data: fieldPickerModel, isFetching, isSuccess, getFields } = useFieldPicker();

  const { timeRange } = useTimeRange();

  useEffect(() => {
    if (getFields && bizEntityType && !renderAsNonEntity) {
      const { fromMillis, toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);
      const fieldPickerContext: FieldPickerContextDTO = {
        entityId: "",
        entityType: bizEntityType,
        entityName: bizEntityType,
        showFields: true
      };
      getFields(fieldPickerContext, fromMillis, toMillis);
      setColumns([]);
      columnsInitialisedRef.current = false;
    }
  }, [bizEntityType, getFields, renderAsNonEntity, timeRange]);

  const bizFieldsList = useMemo(() => {
    if (fieldPickerModel && isSuccess && !isFetching) {
      const bizFieldsInfo = fieldPickerModel.getBizEntityFieldsTableData([]);
      return bizFieldsInfo;
    }
    return [];
  }, [fieldPickerModel, isFetching, isSuccess]);

  const defaultOnSearch = useCallback(
    (searchStr: string): Promise<EntityListData> => {
      const { entityIds: iEntityIds = [], entityLookupData = {}, entityDetailsData = {} } = data || {};

      if (!searchStr) {
        return Promise.resolve({
          entityIds: iEntityIds,
          entityLookupData,
          entityDetailsData
        });
      }

      const filteredLookup: Record<string, string> = {};

      const entityIds = Object.keys(entityLookupData);
      const filteredEntityIds = entityIds.filter(id =>
        entityLookupData[id].toLowerCase().includes(searchStr.toLowerCase())
      );
      filteredEntityIds.forEach(id => (filteredLookup[id] = entityLookupData[id]));

      return Promise.resolve({
        entityIds: filteredEntityIds,
        entityLookupData: filteredLookup,
        entityDetailsData
      });
    },
    [data]
  );

  const onSearch = useCallback(
    async (searchStr: string) => {
      setFetchingEntities(true);

      const searchPromise = pOnSearch ?? defaultOnSearch;
      const entityListData = await searchPromise(searchStr);

      setFetchingEntities(false);
      setData(entityListData);
    },
    [pOnSearch, defaultOnSearch]
  );

  const columnOptions = useMemo(() => {
    const entityPropertiesList: string[] = [];

    if (bizFieldsList.length) {
      bizFieldsList.forEach(field => {
        if (!defaultColumns.includes(field.fieldLabel) && field.datatype !== "ENTITY") {
          entityPropertiesList.push(field.fieldLabel);
        }
      });
    }

    if (!columnsInitialisedRef.current && entityPropertiesList.length && !showDefaultColumnsOnly) {
      setColumns(entityPropertiesList);
      columnsInitialisedRef.current = true;
    }

    return getSelectOptionsFromStringArray(entityPropertiesList);
  }, [bizFieldsList, showDefaultColumnsOnly]);

  const tableColumns = useMemo(() => {
    const tableColumns: TableDataColumn[] = [];

    let allColumns = renderAsNonEntity ? defaultColumns.slice(0, 1) : defaultColumns.concat(columns);

    if (skipIdColumn) {
      allColumns = allColumns.filter(property => property !== "Id");
    }

    allColumns.forEach(x => {
      if (x === "Name") {
        const header = (
          <div className="inc-flex-center-vertical">
            {!renderAsNonEntity && <EntityIconRenderer entityType={bizEntityType} />}
            <span className="marginLt6 inc-text-table">{pluralizeWord(bizEntityType || "")}</span>
          </div>
        );

        tableColumns.push({
          accessor: "entityId",
          header,
          renderer: (entityId: string) => {
            if (renderAsNonEntity) {
              const entityName = data?.entityLookupData?.[entityId] || entityId;
              return <IncSmartText text={entityName} />;
            } else {
              const entityDetails = data?.entityDetailsData?.[entityId];
              const entityName = entityDetails?.displayName || "";

              return (
                <SimpleEntityNameRenderer
                  entityDetails={entityDetails}
                  hideIcon
                  id={entityId}
                  minimalLoader
                  name={entityName}
                  showPropertiesPopover={showEntityInfoOnHover}
                />
              );
            }
          },
          type: "alphanumeric",
          sortable: true
        });
      } else {
        tableColumns.push({
          accessor: x,
          header: x,
          type: "alphanumeric",
          sortable: true
        });
      }
    });

    addlColumns?.length && tableColumns.push(...addlColumns);

    return tableColumns;
  }, [addlColumns, bizEntityType, columns, data, renderAsNonEntity, showEntityInfoOnHover, skipIdColumn]);

  useEffect(() => {
    if ((!data && !dataInitialisedRef.current) || cohortId !== prevCohortId.current) {
      onSearch("");
      dataInitialisedRef.current = true;
      prevCohortId.current = cohortId;
    }
  }, [cohortId, data, onSearch]);

  useEffect(() => {
    dataInitialisedRef.current = false;
    setData(null);
  }, [bizEntityType]);

  const tableData: TableDataItem[] = useMemo(
    () =>
      (data?.entityIds || []).map(entityId => {
        const entityDetails = data?.entityDetailsData?.[entityId];
        const properties: Record<string, string> = {};
        entityDetails?.properties?.forEach(x => {
          properties[x.name] = x.value;
        });
        return {
          entityId,
          ...properties
        };
      }),
    [data]
  );

  const updateDebouncedValue = useMemo(
    () => debounce((value: string) => onSearch(value), debounceTimeSecs),
    [debounceTimeSecs, onSearch]
  );

  const onSearchTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSearchText(value);
      updateDebouncedValue(value);
    },
    [updateDebouncedValue]
  );

  const titleText = title || pluralizeWord(bizEntityType || "");

  const closeModal = useCallback(() => {
    setSearchText("");
    onClose();
  }, [onClose]);

  const onChangeSelectOptions = useCallback((selectOptions: IncSelectOption[]) => {
    const options = selectOptions.map(op => op.value);
    setColumns(options);
  }, []);

  const defaultSelectOptions = useMemo(() => getSelectOptionsFromStringArray(columns), [columns]);

  const downloadFileName = `entities-${moment().format("YYYY-MM-DD_HH-mm-ss")}`;

  useEffect(() => {
    const getEntitiesUrl = async () => {
      const bizFields: BizField[] = [];
      const allColumnProperties = defaultColumns.concat(columns);
      allColumnProperties.forEach(x => {
        const bf = bizFieldsList.find(bizField => bizField.fieldLabel === x);
        if (bf) {
          bizFields.push(bf.field.bizField);
        }
      });
      const url = await entityStoreApiService.getEntitiesCsvUrl(bizEntityType, bizFields, downloadFileName, cohortId);
      setFileHref(url);
    };
    if (bizEntityType) {
      getEntitiesUrl();
    }
  }, [bizEntityType, bizFieldsList, cohortId, columns, downloadFileName]);

  const downloadFile = useCallback(() => window.open(fileHref), [fileHref]);

  return (
    <IncModal
      className="entities-panel"
      onClose={closeModal}
      show={show}
      size="side-pane"
      titleText={titleText}
    >
      <div>
        {(!renderAsNonEntity || searchable) && (
          <div className="inc-flex-row inc-flex-space-contents marginBt16 marginTp4">
            {/* Search bar */}
            {searchable && (
              <div className="search-field">
                <IncTextfield
                  autoFocus
                  onChange={onSearchTextChange}
                  placeholder="Search"
                  value={searchText}
                />
              </div>
            )}

            {/* Column selector */}
            {!renderAsNonEntity && !restrictAddingColumns && (
              <IncMultiSelectCheckBox
                className="marginLt16"
                defaultOptions={defaultSelectOptions}
                hideOptionsCount
                onChange={onChangeSelectOptions}
                options={columnOptions}
                placeholder="Add columns..."
                showAsPills
                showSelectedOptions
                skipAllOption
              />
            )}
          </div>
        )}

        {fileHref && !renderAsNonEntity && !disableDownload && (
          <div className="inc-text-subtext-medium marginBt12">
            <IncButton
              color="link"
              onClick={downloadFile}
            >
              <IncFaIcon
                className="marginRt6"
                iconName="download"
              />
              Download
            </IncButton>
          </div>
        )}

        {children}

        {isFetching ? (
          <LoadingSpinner titleText="Loading fields..." />
        ) : (
          <IncRTable
            classNames={{
              table: "table"
            }}
            columns={tableColumns}
            data={tableData}
            isLoading={fetchingEntities}
            pagination={{
              enabled: true,
              pageSize: 50
            }}
            sort={sortBy}
          />
        )}
      </div>
    </IncModal>
  );
};
