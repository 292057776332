import { useCallback, useMemo } from "react";
import { InitialState, FetchCallBackResult, useDataFetch } from "../../../../core";
import { FunnelResultSchema, journeyApiService, UserServiceFieldSliceSet } from "../../../../services/api/explore";

export const useFetchFunnelResultSchema = (
  funnelId: string,
  startTimeMillis: number,
  endTimeMillis: number,
  sliceSet: UserServiceFieldSliceSet
) => {
  const initialState = useMemo<InitialState<FunnelResultSchema, string>>(
    () => ({
      data: {
        entityLookupData: {},
        resultSchema: []
      },
      error: "",
      isError: false,
      isFetching: false,
      isSuccess: false
    }),
    []
  );

  const fetchFn = useCallback(async () => {
    const result: FetchCallBackResult<FunnelResultSchema, string> = {
      data: {
        entityLookupData: {},
        resultSchema: []
      },
      error: "",
      isError: false,
      isSuccess: false
    };

    const { data, error, message } = await journeyApiService.getFunnelResultSchema(
      funnelId,
      startTimeMillis,
      endTimeMillis,
      sliceSet
    );

    if (error) {
      result.isError = true;
      result.error = message;
    } else {
      result.data = data;
      result.isSuccess = true;
    }

    return result;
  }, [endTimeMillis, funnelId, sliceSet, startTimeMillis]);

  return useDataFetch(fetchFn, initialState, true);
};
