import React, { FC, useState, useRef, useMemo, CSSProperties } from "react";
import { IncPill, IncFaIcon, IncClickAwayPopper } from "@inception/ui";
import { ImpactedWidget, WidgetConfigUtils } from "../../../services/api/explore";
import { generateId } from "../../../core";
import { VerticallyCenteredRow } from "../../flex-components";

type IWProps = {
  impactedWidget: ImpactedWidget;
};

export const ImpactedWidgetFilter: FC<IWProps> = ({ impactedWidget }) => {
  const [open, setOpen] = useState(false);
  const openPopper = () => setOpen(true);
  const closePopper = () => setOpen(false);

  const ref = useRef<HTMLDivElement>();

  const { popperContent, actions, name } = useMemo(() => {
    const { underlyingFilters, name } = impactedWidget || {};

    const numFilters = (underlyingFilters?.userServiceFilters || []).length;
    const popperContent = (underlyingFilters?.userServiceFilters || []).map((fes, idx) => {
      const key = generateId();

      const numFilterExprs = fes.userServiceFilterExpressions.length;
      const divs = fes.userServiceFilterExpressions.map((fe, fIdx) => {
        const label = WidgetConfigUtils.getUsFilterExpressionLabel(fe);
        const isLast = fIdx === numFilterExprs - 1;

        const nKey = `${key} -${fIdx}`;

        return (
          <VerticallyCenteredRow
            className="marginTp4"
            key={nKey}
          >
            <IncPill label={label} />
            {!isLast && <div className="marginLt6 marginRt6">AND</div>}
          </VerticallyCenteredRow>
        );
      });

      const isLast = numFilters - 1 === idx;
      return (
        <div
          className="marginTp6"
          key={key}
        >
          <VerticallyCenteredRow className="inc-flex-row-wrap">{divs}</VerticallyCenteredRow>

          {!isLast && <div className="marginTp6">OR</div>}
        </div>
      );
    });

    const actions = popperContent.length
      ? [
          {
            icon: (
              <VerticallyCenteredRow ref={ref}>
                <IncFaIcon iconName="circle-info" />
              </VerticallyCenteredRow>
            ),
            onAction: openPopper,
            tooltipText: "View details"
          }
        ]
      : [];

    return {
      popperContent,
      actions,
      name
    };
  }, [impactedWidget]);

  return impactedWidget ? (
    <>
      <IncPill
        actions={actions}
        className="primary-impact-widget-filters"
        label={name}
      />

      <IncClickAwayPopper
        anchorEl={ref.current}
        onClickAway={closePopper}
        placement="top"
        show={open}
      >
        <div
          className="inc-card-layout display-block"
          style={popperStyle}
        >
          {popperContent}
        </div>
      </IncClickAwayPopper>
    </>
  ) : (
    <></>
  );
};

const popperStyle: CSSProperties = {
  maxHeight: 200,
  maxWidth: "50vw",
  overflow: "auto"
};
