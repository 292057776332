import React, { FC, useMemo } from "react";
import { CurrencyType } from "@inception/ui";
import { escape } from "lodash";
import { SeriesSplineOptions } from "highcharts";
import TimeSeries from "../../../../../components/time-series/TimeSeries";
import { dataFrameToTimeseries, DataType, DataFrame } from "../../../../../core";
import TimeSeriesWidgetImpl from "../../../TimeSeries/models/impl";
import { TimeSeriesHelper } from "../../../TimeSeries/models/TimeSeriesUtils";
import timeRangeUtils from "../../../../../utils/TimeRangeUtils";
import { TimeSeriesProperties, SeriesOverride } from "../../../TimeSeries/models/model";
import { ENTITY_TAG } from "../../../../../utils";
import { EntityWidgetData } from "../../../../../biz-entity";
import { IncTimeSeriesOptions } from "../../../../../components/time-series/types";
import {
  CatalogWidgetProperties,
  NegationColors,
  PlotAxisProperties,
  PlotAxisScaleType,
  PlotMetricsProperties
} from "../../models";
import getChartColor from "../../../../../components/charts/colors";
import {
  CatalogVizRendererProps,
  getFormattedValue,
  getMetricIdFromDataFrameId,
  useCommonRendererFunctionality
} from "./common";

export const TimeseriesRenderer: FC<CatalogVizRendererProps> = props => {
  const {
    className,
    dataType: defDataType,
    dataTypeByMetricId,
    aggregator,
    currencyType,
    noDataElement,
    loadingElement,
    aggregatedTags,
    displayAggregatedTags,
    properties: widgetProperties,
    timeRange,
    compareTimeRange
  } = props;

  const { timeseries: timeSeriesProperties, useNegationColors, negationColors } = widgetProperties;

  const { axes } = timeSeriesProperties;

  const { data, fieldName, metricName, childMetricNames, isFetching, isError, dataExists, hasChildMetrics } =
    useCommonRendererFunctionality(props);

  const compareTimeStr = useMemo(
    () => timeRangeUtils.getCompareTimeStr(timeRange.from.valueOf(), compareTimeRange.from.valueOf()),
    [compareTimeRange.from, timeRange.from]
  );

  const series = useMemo(
    () =>
      constructTimeseries(
        data,
        metricName,
        childMetricNames,
        aggregatedTags,
        displayAggregatedTags,
        compareTimeStr,
        timeSeriesProperties,
        hasChildMetrics,
        false,
        useNegationColors,
        negationColors,
        dataTypeByMetricId
      ),
    [
      data,
      metricName,
      childMetricNames,
      aggregatedTags,
      displayAggregatedTags,
      compareTimeStr,
      timeSeriesProperties,
      hasChildMetrics,
      useNegationColors,
      negationColors,
      dataTypeByMetricId
    ]
  );

  const aggregatorLabel = aggregator?.label;
  const properties = useMemo<TimeSeriesProperties>(() => {
    const seriesSize = series.length;
    return getTimeSeriesProperties(fieldName, defDataType, currencyType, aggregatorLabel, seriesSize, axes);
  }, [aggregatorLabel, axes, currencyType, defDataType, fieldName, series.length]);

  const chartTimeRange = useMemo(() => timeRangeUtils.getTimeRangeFromRaw(timeRange.raw), [timeRange.raw]);

  if (isFetching) {
    return loadingElement;
  }

  if (!dataExists || isError) {
    return noDataElement;
  }

  return (
    <TimeSeries
      containerElemClass={className}
      properties={properties}
      series={series}
      timeRange={chartTimeRange}
      title=""
      type="areaspline"
    />
  );
};

export const constructTimeseries = (
  data: EntityWidgetData[],
  metricName: string,
  childMetricNames: Record<string, string>,
  aggregatedTags: string[],
  displayAggregatedTags: string[],
  compareTimeStr: string,
  properties: CatalogWidgetProperties["timeseries"],
  hasChildMetrics: boolean,
  isSparkline = false,
  useNegationColors = false,
  negationColors: NegationColors = null,
  dataTypeByMetricId?: Record<string, DataType>
): IncTimeSeriesOptions[] => {
  const dataframes: DataFrame[] = [];
  const compareDataFrames: DataFrame[] = [];

  const { metrics: metricsCustomization } = properties || {};

  data.forEach(datum => {
    const postAggData = datum.postAggResult.data;
    const compareData = datum.postAggResult.timeShiftData || {};

    const refId = Object.keys(postAggData)[0] || Object.keys(compareData)[0] || "";

    const dfs = postAggData[refId]?.data || [];
    const cDfs = compareData[refId]?.data || [];

    dataframes.push(...dfs);
    compareDataFrames.push(...cDfs);
  });

  const shouldAppendMetricName = hasChildMetrics && data.length >= 2;
  const isAggregatedMetric = isSparkline || (dataframes.length === 1 && !aggregatedTags?.length);

  const timeseries = dataframes.map(df => {
    formatDataFrame(
      df,
      metricName,
      childMetricNames,
      isAggregatedMetric,
      aggregatedTags,
      displayAggregatedTags,
      shouldAppendMetricName,
      metricsCustomization
    );

    const metricId = getMetricIdFromDataFrameId(df.id);
    const timeserie = dataFrameToTimeseries(df);
    timeserie.meta = {
      ...(timeserie.meta || {}),
      dataType: dataTypeByMetricId?.[metricId] || "STRING"
    };

    return timeserie;
  });

  const compareTimeSuffix = ` (${compareTimeStr})`;
  const compareTimeseries = (compareDataFrames || []).map(df => {
    formatDataFrame(
      df,
      metricName,
      childMetricNames,
      isAggregatedMetric,
      aggregatedTags,
      displayAggregatedTags,
      shouldAppendMetricName,
      metricsCustomization
    );

    df.name = df.name + compareTimeSuffix;

    const metricId = getMetricIdFromDataFrameId(df.id);
    const timeserie = dataFrameToTimeseries(df);
    timeserie.meta = {
      ...(timeserie.meta || {}),
      dataType: dataTypeByMetricId?.[metricId] || "STRING"
    };
    return timeserie;
  });

  const seriesOverrides: SeriesOverride[] = isAggregatedMetric
    ? [
        {
          alias: ".*.",
          chartType: "areaspline",
          lineWidth: 2
        }
      ]
    : [];

  const tsWidget = new TimeSeriesWidgetImpl({
    chartType: "areaspline",
    seriesOverrides: seriesOverrides
  });

  const timeSeriesOptions = new TimeSeriesHelper(timeseries, tsWidget).seriesOptions;
  const compareTimeSeriesOptions = new TimeSeriesHelper(compareTimeseries, tsWidget).seriesOptions;

  const colorsMap: Record<string, string> = {};

  useNegationColors = useNegationColors && dataframes.length <= 2;

  timeSeriesOptions.forEach((so, idx) => {
    so.color = useNegationColors ? negationColors?.[idx] || getChartColor(idx) : getChartColor(idx);
    colorsMap[so.name] = so.color;
  });

  compareTimeSeriesOptions.forEach((so, idx) => {
    const colorKey = so.name?.replace(compareTimeSuffix, "") || "";
    so.color = colorsMap[colorKey] || getChartColor(timeseries.length + idx);
    so.dashStyle = "ShortDash";
  });

  const { connectNulls, pointRadius, shape } = properties || {};

  const seriesOptions = [...timeSeriesOptions, ...compareTimeSeriesOptions];
  seriesOptions.forEach(so => {
    const splineOptions = so as SeriesSplineOptions;
    splineOptions.connectNulls = connectNulls || false;
    splineOptions.marker = splineOptions.marker || {};
    splineOptions.marker.enabled = true;
    splineOptions.marker.radius = pointRadius || 2;
    splineOptions.marker.symbol = shape || "circle";
  });

  return seriesOptions;
};

export const getTimeSeriesProperties = (
  fieldName: string,
  defDataType: DataType,
  currencyType: CurrencyType,
  aggregator: string,
  seriesSize: number,
  axes?: PlotAxisProperties[]
): TimeSeriesProperties => {
  let leftLabel = "";
  let rightLabel = "";
  let leftScale: PlotAxisScaleType = "linear";
  let rightScale: PlotAxisScaleType = "linear";
  /**
   * handle the axes
   */
  if (axes && axes.length > 0) {
    const leftVAxis = axes.find(axis => axis.position === "left");
    const rightVAxis = axes.find(axis => axis.position === "right");
    leftLabel = leftVAxis?.label;
    leftScale = leftVAxis?.scale as PlotAxisScaleType;
    rightLabel = rightVAxis?.label;
    rightScale = rightVAxis?.scale as PlotAxisScaleType;
  }

  const labelFormatterFn = (value: string | number, dataType = defDataType): string =>
    getFormattedValue(fieldName, value, dataType, currencyType);
  const labelFormatter = skipFormatterAggs.includes(aggregator) ? null : labelFormatterFn;

  return {
    legend: {
      show: seriesSize > 1,
      position: "bottom"
    },
    chartType: "areaspline",
    yAxis: {
      axisProps: [
        {
          labelFormatter,
          label: leftLabel,
          type: leftScale
        },
        {
          labelFormatter,
          label: rightLabel,
          type: rightScale
        }
      ]
    },
    tooltip: {
      valueFomatter: (value, custom) => labelFormatter(value, custom?.dataType as DataType),
      shared: true,
      sortBy: "desc"
    }
  };
};

const formatDataFrame = (
  df: DataFrame,
  metricName: string,
  childMetricNames: Record<string, string>,
  isAggregatedMetric: boolean,
  aggregatedTags: string[],
  displayAggregatedTags: string[],
  shouldAppendMetricName: boolean,
  metricsCustomization: PlotMetricsProperties[]
) => {
  const { name, labels = {}, eLabels = labels, id } = df;

  // TODO: Hacky way to get metricId. Need to fix this
  const metricId = getMetricIdFromDataFrameId(id);
  const appliedMetricName = childMetricNames[metricId] || metricName;

  if (isAggregatedMetric) {
    df.name = appliedMetricName;
  } else {
    let visibleTags = [...aggregatedTags];
    let visibleDisplayTags = [...displayAggregatedTags];

    const entityTagIdx = visibleTags.findIndex(tag => tag === ENTITY_TAG);

    if (entityTagIdx !== -1) {
      visibleTags.splice(entityTagIdx, 1);
      visibleTags = [ENTITY_TAG, ...visibleTags];

      const entityDisplayTag = visibleDisplayTags[entityTagIdx];
      visibleDisplayTags.splice(entityTagIdx, 1);
      visibleDisplayTags = [entityDisplayTag, ...visibleDisplayTags];
    }

    if (visibleTags.length > 1) {
      const dfNameArr = visibleTags.map((tag, idx) => {
        const key = visibleDisplayTags[idx] || tag;
        const value = eLabels[tag];
        return `${key}: ${value}`;
      });
      df.name = dfNameArr.join(", ");
    } else {
      const tag = visibleTags[0];
      df.name = eLabels[tag] || name;
    }

    if (shouldAppendMetricName) {
      df.name = `${appliedMetricName} - ${df.name}`;
    }
  }

  /**
   * handle metrics
   */
  if (metricsCustomization && metricsCustomization.length > 0) {
    //TODO the metric id is not same as in the widget config to match so matching with the name
    const seriesCustomization = metricsCustomization.find(
      customOption => customOption.metricName === appliedMetricName
    );
    df.meta.yAxis = seriesCustomization?.verticalAxisPosition === "right" ? 1 : 0;
  }
  df.name = escape(df.name);
};

const skipFormatterAggs = ["count", "distinctCount"];
