import React, { useMemo, useCallback } from "react";
import { IncMenuItemProps, IncMenuToggleProps, IncFaIcon, IncMenu, IncSelectOption } from "@inception/ui";
import { forEach, isEmpty } from "lodash";
import { WidgetCustomAction } from "../../../types";
import { TimeRange, useInceptionRoute } from "../../../../../core";
import { getEventsURL, getBizFilter, getSliceQuery } from "../../widgetActionsUtils";
import { WidgetResponseDTO, getWidgetTraceQueryLinks } from "../../../../../services/api/explore";
import { TagFilterSelection } from "../../../../../biz-entity";

type Props = {
  selectedFilters: TagFilterSelection;
  timeRange: TimeRange;
  dataQueryConfig: Record<any, any>;
  widgetResponseDTO: WidgetResponseDTO;
};

export const useViewEventsAction = (props: Props) => {
  const { widgetResponseDTO, dataQueryConfig, selectedFilters, timeRange } = props;

  const widgetTraceQueryLinks: IncSelectOption[] = useMemo(
    () =>
      dataQueryConfig && !isEmpty(widgetResponseDTO?.widgetConfig)
        ? getWidgetTraceQueryLinks(dataQueryConfig, widgetResponseDTO.widgetConfig)
        : [],
    [dataQueryConfig, widgetResponseDTO]
  );

  const { navigate } = useInceptionRoute();

  const { bizFilter, sliceQuery } = useMemo(() => {
    let bizFilter = "";
    let sliceQuery = "";

    if (selectedFilters) {
      if (selectedFilters?.bizFilter) {
        bizFilter = getBizFilter(selectedFilters.bizFilter);
      }
      if (selectedFilters?.filtersBySliceSet?.length) {
        const sliceQueries: string[] = [];
        const sliceSets = selectedFilters.filtersBySliceSet;
        for (let i = 0; i < sliceSets.length; i++) {
          const query = getSliceQuery(sliceSets[i]);
          if (query) {
            sliceQueries.push(query);
          }
        }
        if (sliceQueries.length) {
          sliceQuery = sliceQueries.join(" AND ");
        }
      }
    }

    return {
      bizFilter,
      sliceQuery
    };
  }, [selectedFilters]);

  const navigateToEvents = useCallback(
    (link: string) => {
      const path = getEventsURL(link, bizFilter, sliceQuery, timeRange.from.valueOf(), timeRange.to.valueOf());
      navigate(path, { newTab: true });
    },
    [bizFilter, navigate, sliceQuery, timeRange.from, timeRange.to]
  );

  const menuItems = useCallback(
    (itemProps: IncMenuItemProps) => {
      const { closeMenu, className } = itemProps;
      const items: JSX.Element[] = [];
      const onClick = (e: React.MouseEvent, link: string) => {
        navigateToEvents(link);
        closeMenu(e as any);
      };

      forEach(widgetTraceQueryLinks, link => {
        items.push(
          <div
            className={className}
            onClick={e => onClick(e, link.value)}
          >
            <span className="list-item">{link.label}</span>
          </div>
        );
      });
      return items;
    },
    [navigateToEvents, widgetTraceQueryLinks]
  );

  const toggle = useCallback((toggleProps: IncMenuToggleProps) => {
    const { show, toggleMenu } = toggleProps;

    return (
      <div
        className="inc-flex-row inc-flex-center-vertical"
        onClick={() => {
          toggleMenu(!show);
        }}
      >
        <IncFaIcon iconName="list-ul" />
        View events
      </div>
    );
  }, []);

  const viewEventsAction = useMemo<WidgetCustomAction>(() => {
    if (widgetTraceQueryLinks?.length) {
      return {
        actionComponent:
          widgetTraceQueryLinks.length > 1 ? (
            <div className="view-events-container">
              <IncMenu
                dropType="left"
                items={menuItems}
                offset={{
                  x: 20,
                  y: -20
                }}
                toggle={toggle}
              />
            </div>
          ) : (
            <div
              className="inc-flex-row inc-flex-center-vertical"
              onClick={() => navigateToEvents(widgetTraceQueryLinks[0].value)}
            >
              <IncFaIcon iconName="list-ul" />
              View events
            </div>
          )
      };
    } else {
      return null;
    }
  }, [menuItems, navigateToEvents, toggle, widgetTraceQueryLinks]);

  return viewEventsAction;
};
