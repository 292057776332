import { IncFaIcon } from "@inception/ui";
import React, { CSSProperties, FC, useMemo } from "react";
import { EventFilterSelector, VerticallyCenteredRow } from "../../../../../../../components";
import { FieldPickerContextDTO, UserServiceFilterExpression } from "../../../../../../../services/api/explore";
import { ALL_USERSERVICES_ENTITY_TYPE_ID } from "../../../../../../../utils/ExploreUtils";

interface Props {
  bizEntityType: string;
  eventTypeIds: string[];
  eventFilters: UserServiceFilterExpression[];
  onEventFiltersChange: (eventFilters: UserServiceFilterExpression[]) => void;
}

export const WidgetFiltersEditor: FC<Props> = props => {
  const { bizEntityType, eventTypeIds, eventFilters, onEventFiltersChange } = props;

  const fieldPickerContextDto = useMemo<FieldPickerContextDTO>(
    () => ({
      entityId: "",
      entityType: bizEntityType || ALL_USERSERVICES_ENTITY_TYPE_ID,
      entityName: "",
      showFields: true,
      userServices: eventTypeIds.map(eventTypeId => ({
        userServiceEntityId: eventTypeId
      }))
    }),
    [bizEntityType, eventTypeIds]
  );

  return (
    <div
      className="inc-flex-column flex-gap-12 paddingTp12 paddingBt12"
      style={style}
    >
      <VerticallyCenteredRow className="inc-text-body flex-gap-12">
        Widget Filters
        <IncFaIcon iconName="filter" />
      </VerticallyCenteredRow>

      <EventFilterSelector
        fieldPickerContext={fieldPickerContextDto}
        filterExpressions={eventFilters}
        hideLabel
        noToggle
        onChange={onEventFiltersChange}
      />
    </div>
  );
};

const style: CSSProperties = {
  border: "1px solid #4a505c",
  borderLeft: "none",
  borderRight: "none"
};
