import React, { FC, useCallback, useMemo } from "react";
import { IncCheckbox, IncDurationSelector, IncSelectorDuration, IncSmartText } from "@inception/ui";
import { WhatsNewConfig } from "../../services/api/operationalise";
import { VerticallyCenteredRow } from "../../components";
import { getDurationFromTimeObj, getTimeObjFromDuration } from "../../utils/DurationUtils";

type Props = {
  whatsNewConfig: WhatsNewConfig;
  onChange: (config: WhatsNewConfig) => void;
  label?: string;
  durationSelectorClassName?: string;
  showDurationPrefix?: boolean;
};

const WhatsNewConfigEditor: FC<Props> = props => {
  const {
    whatsNewConfig,
    onChange,
    label = "Enable New Series monitoring",
    durationSelectorClassName,
    showDurationPrefix = false
  } = props;

  const { doSetup, lookbackPeriod } = whatsNewConfig;

  const onChangeToggle = useCallback(
    (_: any, checked: boolean) => {
      onChange({
        ...whatsNewConfig,
        doSetup: checked
      });
    },
    [onChange, whatsNewConfig]
  );
  const onChangeDuration = useCallback(
    (duration: IncSelectorDuration) => {
      const timObj = getTimeObjFromDuration(Number(duration.duration), duration.durationType);
      onChange({
        ...whatsNewConfig,
        lookbackPeriod: timObj
      });
    },
    [onChange, whatsNewConfig]
  );
  const duration = useMemo(() => getDurationFromTimeObj(lookbackPeriod), [lookbackPeriod]);

  return (
    <VerticallyCenteredRow className="flex-gap-4">
      <IncCheckbox
        checked={doSetup}
        className="inc-text-color-white"
        label={label}
        labelProps={{
          className: "inc-text-color-white",
          placement: "end"
        }}
        onChange={onChangeToggle}
      />
      {doSetup && (
        <VerticallyCenteredRow className="flex-gap-4">
          {showDurationPrefix && (
            <IncSmartText
              className="input-label inc-text-subtext"
              text="in the last"
            />
          )}
          <IncDurationSelector
            className={durationSelectorClassName}
            duration={duration}
            hideLabel
            onChange={onChangeDuration}
          />
        </VerticallyCenteredRow>
      )}
    </VerticallyCenteredRow>
  );
};

export default WhatsNewConfigEditor;
