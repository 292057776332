import { map } from "lodash";
import { DataFrame, DataFrameType, logger } from "../../core";
import {
  MetricDefinition,
  MetricResultDataDTO,
  UserServiceFieldMetricConfigDefinition,
  ExpressionMetricConfig,
  SliceSet
} from "../../services/api/explore";
import { ENTITY_TAG } from "../../utils/MetricNameUtils";
import { EntityWidgetData } from "./types";

export const getBizTagFromMetricDef = (dataDef: MetricDefinition, bizEntityName: string) => {
  let bizTag: string = null;

  if (dataDef?.sourceType === "bizEntityMetric" || dataDef?.sourceType === "userServiceField") {
    bizTag = ENTITY_TAG; // For bizmetrics and userServiceFields, we use the entity as the business tag
  } else if (dataDef?.sourceType === "userServiceMetric") {
    // Get the tag name from the slice set whose entityTypeName equals the widgetConfig's entityTypeName
    bizTag =
      dataDef.userServiceMetricConfig.sliceSet.slices.find(slice => slice.entityTypeName === bizEntityName)?.tagName ||
      ENTITY_TAG;
  } else if (dataDef?.sourceType === "expression") {
    const allSliceSets = getSliceSetsForExprMetric(dataDef.expressionMetricConfig);
    allSliceSets.forEach(sliceSet => {
      bizTag = bizTag || sliceSet.slices.find(slice => slice.entityTypeName === bizEntityName)?.tagName;
    });
    bizTag = bizTag || ENTITY_TAG;
  } else {
    logger.error("Entity Data Utils", "Invalid sourceType hit while extracting bizTag");
    bizTag = null;
  }

  return bizTag;
};

export const entityWidgetDataToDataFrames = (fetchedData: EntityWidgetData[]): DataFrame[] => {
  const dataFrames: DataFrame[] = [];

  const extractDataFrames = (data: Record<string, MetricResultDataDTO>, dfType: DataFrameType) =>
    map(data, dt => {
      const { resultSeriesId, data, bizTag, implicitSliceTag } = dt;
      const dfs = data.map(d => ({
        ...d,
        meta: {
          ...(d.meta || {}),
          resultSeriesId,
          bizTag,
          implicitSliceTag,
          dfType
        }
      }));
      dataFrames.push(...dfs);
    });

  fetchedData.forEach(fData => {
    const { compareConfigData, data, postAggResult } = fData;

    const {
      data: postAggData = {},
      percentChangeData: postAggPercentChangeData = {},
      timeShiftData: postAggTimeShiftData = {}
    } = postAggResult || {};

    extractDataFrames(data, DataFrameType.original);
    extractDataFrames(compareConfigData, DataFrameType.compare);
    extractDataFrames(postAggData, DataFrameType.aggregated);
    extractDataFrames(postAggPercentChangeData, DataFrameType.aggregatedPercentage);
    extractDataFrames(postAggTimeShiftData, DataFrameType.aggregatedCompare);
  });

  return dataFrames;
};

export const getDataTypeAndSubType = (metricDefinition: MetricDefinition) => {
  let dataType: DataFrame["dataType"] = "NA";
  let subType: DataFrame["subType"] = "not_set";

  const usFieldMetricDef = metricDefinition as UserServiceFieldMetricConfigDefinition;

  if (usFieldMetricDef?.userServiceFieldMetricConfig) {
    const usField = usFieldMetricDef.userServiceFieldMetricConfig.userServiceField;
    dataType = usField?.dataType || dataType;
    subType = usField?.entityField?.kindDescriptor?.type || subType;
  }

  return {
    dataType,
    subType
  };
};

const getSliceSetsForExprMetric = (dataDef: ExpressionMetricConfig) => {
  const sliceSets: SliceSet[] = [];
  extractSliceSetFromExprRecur(dataDef, sliceSets);
  return sliceSets;
};

const extractSliceSetFromExprRecur = (dataDef: ExpressionMetricConfig, sliceSets: SliceSet[]) => {
  if (!dataDef) {
    return;
  }

  const { expression, sliceSpec } = dataDef;
  if (sliceSpec) {
    sliceSets.push(sliceSpec.sliceSet);
  }

  if (expression) {
    const { expressionMetricConfig: leftExprConfig } = expression.leftExpr || {};
    leftExprConfig && extractSliceSetFromExprRecur(leftExprConfig, sliceSets);

    const { expressionMetricConfig: rightExprConfig } = expression.rightExpr || {};
    rightExprConfig && extractSliceSetFromExprRecur(rightExprConfig, sliceSets);
  }
};
