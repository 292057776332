import {
  Options,
  Point,
  SeriesSplineOptions,
  SeriesBoxplotOptions,
  AxisLabelsFormatterContextObject,
  YAxisOptions,
  XAxisOptions
} from "highcharts";
import { formatPercent, getInceptionTheme } from "@inception/ui";
import { inRange, merge } from "lodash";
import { FunnelData, FunnelStageData } from "../../../../services/api/explore";
import { BaseOptionsBuilderWithAxes } from "../../../../components/charts";
import getChartColor from "../../../../components/charts/colors";
import { getChangePercent } from "../../../../core";

export const getFunnelChartOptions = (
  funnelData: FunnelData,
  width: number,
  height: number,
  dataLabel: string,
  currentTimeStr: string,
  compareTimeStr: string,
  displayCompareTimeStr: string,
  disableCompare: boolean,
  disableChangeLabels?: boolean,
  showImpactRegions?: boolean,
  highlightOnlyMonitoredNode?: boolean
) => {
  const { stages, impactedRegions } = funnelData;
  const stageNames = stages.map(stage => stage.name);
  const nodeIds = stages.map(stage => stage.nodeId);

  const impactedRegion = impactedRegions?.[0];

  const impactStart = impactedRegion?.impactedNodes?.[0];
  const impactEnd = impactedRegion?.impactedNodes?.[1];

  let impactFrom = nodeIds.indexOf(impactStart);
  impactFrom = impactFrom === -1 ? stageNames.indexOf(impactStart) : impactFrom;

  let impactTo = nodeIds.indexOf(impactEnd);
  impactTo = impactTo === -1 ? stageNames.indexOf(impactEnd) : impactTo;

  const eventsSeries: SeriesBoxplotOptions = {
    name: "Events",
    data: [],
    type: "boxplot",
    className: "journey-funnel--box-plot",
    maxPointWidth: 16,
    custom: {
      stages
    },
    label: {
      enabled: false
    },
    dataLabels: {
      enabled: false
    },
    allowPointSelect: false,
    enableMouseTracking: false,
    tooltip: {
      pointFormatter: function () {
        return "";
      }
    },
    whiskerLength: "100%"
  };

  const originalSeries = getCommonSplineOptions(
    dataLabel,
    stages,
    currentTimeStr,
    compareTimeStr,
    displayCompareTimeStr,
    disableCompare
  );
  originalSeries.name = "Current";
  originalSeries.color = getChartColor(0);

  const compareSeries = getCommonSplineOptions(
    dataLabel,
    stages,
    compareTimeStr,
    currentTimeStr,
    displayCompareTimeStr,
    disableCompare
  );
  compareSeries.name = "Compare";
  compareSeries.color = getChartColor(1);

  const options: Options = {
    xAxis: {
      categories: stageNames,
      labels: {
        formatter: function () {
          const getLabel = (ctx: AxisLabelsFormatterContextObject, index: number) => {
            const { chart, pos, isFirst } = ctx;

            const serie = chart.series[index] as any;
            const { color } = serie;

            const serData: number[] = serie.yData;
            const splineValue = serData[pos];
            const prevSplineValue = pos > 0 ? serData[pos - 1] : null;

            const diff = prevSplineValue !== null ? splineValue - prevSplineValue : null;
            const magnitude = Math.sign(diff);

            const iconName = magnitude === 1 ? "caret-up" : magnitude === -1 ? "caret-down" : null;

            const diffPercentStr = diff ? formatPercent(Math.abs(diff), prevSplineValue) : "-";

            const className = magnitude === 1 ? "status-success" : magnitude === -1 ? "status-danger" : "";

            const diffDiv = isFirst
              ? ""
              : `<div class="inc-change-renderer ${className} marginLt8">
                ${
                  iconName
                    ? `<div class="inc-change-renderer--icon-container inc-text-subtext">
                  <i class="fa fa-${iconName} marginRt6"></i>
                </div>`
                    : ""
                }
                <span class="inc-text-subtext">${diffPercentStr}</span>
              </div>`;

            return `<div class="marginBt6 inc-flex-row inc-flex-center">
                <div class="inc-highcharts-square-symbol" style="background-color: ${color}"></div>
                <div class="inc-text-subtext-medium" title="${splineValue}">${splineValue}</div>
                ${diffDiv}
              </div>`;
          };

          const isImpacted = stages[this.pos]?.analysis?.isImpacted;
          const impactedIcon = isImpacted
            ? `<div class="status-danger marginLt6" title="Event type has been impacted">
            <i class="fa fa-warning"></i>
          </div>`
            : "";

          const tooltipText = `<div class="inc-highcharts-xaxis-label" title="${this.value}">
            <div class="inc-flex-row inc-flex-center marginBt6">
              ${this.value} ${impactedIcon}
            </div>
            ${disableChangeLabels ? "" : getLabel(this, 1)}
            ${disableChangeLabels || disableCompare ? "" : getLabel(this, 2)}
          </div>`;

          return tooltipText;
        }
      }
    },
    yAxis: {
      title: {
        text: ""
      }
    },
    series: [eventsSeries, originalSeries]
  };

  if (showImpactRegions && impactFrom !== -1 && impactTo !== -1) {
    (options.xAxis as XAxisOptions).plotBands = [
      {
        color: "rgba(248, 72, 94, 0.14)",
        from: impactFrom,
        to: impactTo
      }
    ];
  }

  if (!disableCompare) {
    options.series.push(compareSeries);
  }

  stages.forEach(stage => {
    const { count, countForComparison, analysis } = stage;

    const { lowerBoundForCount, upperBoundForCount, isImpacted, isMonitored } = analysis || {};

    const eventCount = parseInt(String(count), 10);
    const eventCountForComparison = parseInt(String(countForComparison), 10);
    const lowerBoundForEventCount = lowerBoundForCount ? parseInt(String(lowerBoundForCount), 10) : null;
    const upperBoundForEventCount = upperBoundForCount ? parseInt(String(upperBoundForCount), 10) : null;

    const classNameOrig = inRange(eventCount, lowerBoundForEventCount, upperBoundForEventCount)
      ? ""
      : "highcharts-negative";
    const classNameCompare = inRange(eventCountForComparison, lowerBoundForEventCount, upperBoundForEventCount)
      ? ""
      : "highcharts-negative";

    if (highlightOnlyMonitoredNode) {
      if (isMonitored) {
        eventsSeries.data.push({
          low: lowerBoundForEventCount,
          q1: lowerBoundForEventCount,
          q3: upperBoundForEventCount,
          high: upperBoundForEventCount
        });
      } else {
        eventsSeries.data.push(null);
      }
    } else if (showImpactRegions) {
      if (isImpacted || isMonitored) {
        eventsSeries.data.push({
          low: lowerBoundForEventCount,
          q1: lowerBoundForEventCount,
          q3: upperBoundForEventCount,
          high: upperBoundForEventCount
        });
      } else {
        eventsSeries.data.push(null);
      }
    } else if (lowerBoundForCount && upperBoundForCount) {
      eventsSeries.data.push({
        low: lowerBoundForEventCount,
        q1: lowerBoundForEventCount,
        q3: upperBoundForEventCount,
        high: upperBoundForEventCount
      });
    } else {
      eventsSeries.data.push(null);
    }

    originalSeries.data.push({
      className: classNameOrig,
      y: eventCount
    });

    compareSeries.data.push({
      className: classNameCompare,
      y: eventCountForComparison
    });
  });

  let chartOptions = new BaseOptionsBuilderWithAxes({})
    .setChartFields(width, height)
    .setTitle("")
    .setYAxis(null, null)
    .setXAxis()
    .setLegend(false)
    .build();

  merge(chartOptions, options);

  const yAxis = (chartOptions.yAxis as YAxisOptions[])[0];
  yAxis.visible = true;
  yAxis.grid = {
    enabled: false
  };

  const xAxis = chartOptions.xAxis as XAxisOptions;
  xAxis.lineWidth = 0;
  xAxis.offset = 8;
  xAxis.plotLines = stages.map((stage, idx) => ({
    color: "#FFFFFF",
    value: idx,
    width: 2,
    className: "journey-funnel--plot-line"
  }));

  chartOptions = {
    ...chartOptions,
    tooltip: {
      enabled: true,
      useHTML: true
    }
  };

  return chartOptions;
};

const getCommonSplineOptions = (
  dataLabel: string,
  stages: FunnelStageData[],
  currentTimeStr: string,
  compareTimeStr: string,
  displayCompareTimeStr: string,
  disableCompare: boolean
): SeriesSplineOptions => ({
  type: "spline",
  data: [],
  marker: {
    enabled: true,
    radius: 3,
    symbol: "circle"
  },
  states: {
    hover: {
      lineWidth: 0
    }
  },
  lineWidth: 2,
  tooltip: {
    pointFormatter: function () {
      return getTooltipFormatter(
        this,
        dataLabel,
        stages,
        currentTimeStr,
        compareTimeStr,
        displayCompareTimeStr,
        disableCompare
      );
    },
    pointFormat: "",
    nodeFormat: "",
    headerFormat: "",
    nullFormat: "",
    footerFormat: ""
  },
  custom: {
    stages
  },
  negativeColor: getInceptionTheme().inceptionColors.accentRed
});

const getTooltipFormatter = (
  point: Point,
  dataLabel: string,
  stages: FunnelStageData[],
  currentTimeStr: string,
  compareTimeStr: string,
  displayCompareTimeStr: string,
  disableCompare: boolean
) => {
  const idx = point.x;
  const stage = stages[idx];
  const { count: eventCount, countForComparison: eventCountForComparison, name } = stage;

  const changePercent = Math.round(getChangePercent(eventCount, eventCountForComparison).changePercent);

  const changeInfoDiv = ` <div class="marginTp6">
      <span class="inc-text-subtext">Change: ${changePercent}%</span>
    </div>`;

  const compareDivHtml = disableCompare
    ? ""
    : `<div class="paddingLt16" style="border-left: 1px solid #696D77;">
    <div class="inc-text-inactive inc-text-subtext marginBt8">
      ${displayCompareTimeStr}
    </div>

    <div class="inc-text-inactive inc-text-subtext marginBt8">
      ${dataLabel}
    </div>

    <div class="inc-text-subtext-medium">
      ${eventCountForComparison}
    </div>`;

  return `<div class="inc-card-layout journey-funnel--tooltip display-block">
    <div class="inc-text-body marginBt16">
      ${name}
    </div>

    <div class="inc-flex-row">
      <div class="paddingRt16">
        <div class="inc-text-inactive inc-text-subtext marginBt8">
          Current
        </div>

        <div class="inc-text-inactive inc-text-subtext marginBt8">
          ${dataLabel}
        </div>

        <div class="inc-text-subtext-medium">
          ${eventCount}
        </div>
      </div>
      ${compareDivHtml}
      </div>
    </div>

    ${!disableCompare ? changeInfoDiv : ""}
  </div>`;
};
