import React, { useCallback, useMemo } from "react";
import { IncFaIcon } from "@inception/ui";
import { WidgetCustomAction } from "../../../types";
import { VerticallyCenteredRow } from "../../../../../components";
import { useForceUpdate, useTimeRange } from "../../../../../core";
import { USFWProps } from "../../types";

type Props = {
  widget: USFWProps["widget"];
};
export const useTimeRangeAction = (props: Props) => {
  const { widget } = props;

  const { timeRange } = widget || {};

  const forceUpdate = useForceUpdate();
  const { timeRange: gTimeRange } = useTimeRange();

  const toggleTimeRange = useCallback(() => {
    if (timeRange) {
      widget.timeRange = null;
    } else {
      widget.timeRange = gTimeRange;
    }
    forceUpdate();
  }, [forceUpdate, widget, gTimeRange, timeRange]);

  const tooltipText = timeRange ? "Use global time range" : "Use custom time range";

  const timeRangeAction = useMemo<WidgetCustomAction>(
    () => ({
      actionComponent: (
        <VerticallyCenteredRow onClick={toggleTimeRange}>
          <IncFaIcon iconName="calendar-week" />
          <span>{tooltipText}</span>
        </VerticallyCenteredRow>
      ),
      showInHeader: false
    }),
    [toggleTimeRange, tooltipText]
  );

  return timeRangeAction;
};

export default useTimeRangeAction;
