import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import {
  ISaxIcon,
  IncButton,
  IncFaIcon,
  IncFaIconName,
  IncInModalConfirmation,
  IncSelect,
  IncSelectOption,
  IncTextfield,
  IncToggle,
  IncToolTip
} from "@inception/ui";
import { cloneDeep } from "lodash";
import { CypressConstants } from "@bicycle/tests";
import { DrilldownTypes } from "../../../../../../platform/services/api/triage-v2";
import { VerticallyCenteredRow } from "../../../../../../platform/components";
import {
  generateId,
  useForceUpdate,
  useRefState,
  useTenantConfig,
  useToggleState
} from "../../../../../../platform/core";
import {
  CorrelatedEventToolSpec,
  DimensionalAnalysisToolSpec,
  FieldPickerContextDTO,
  GoogleSearchToolSpec,
  ImpactedWidget,
  ToolMetaData,
  ToolMetaDataList,
  ToolType
} from "../../../../../../platform/services/api/explore";
import { getSourceTypeIcon } from "../../../../../../platform/components/business-entity";
import { IncidentSummaryCauseType } from "../../../../../../platform/services/api";
import { addChildIdPrefix, cloneChildIdPrefix, cloneChildNamePrefix, validateWorkflowStep } from "./utils";
import DimensionalAnalysisToolSpecEditor from "./DimensionAnalysisToolSpecEditor";
import CorrelatedEventToolSpecEditor from "./CorrelatedEventToolSpecEditor";
import GoogleSearchToolSpecEditor from "./GoogleSearchToolSpecEditor";

type Props = {
  drillDownStep: DrilldownTypes.DrillDownStep;
  onUpdateStep: (step: DrilldownTypes.DrillDownStep) => void;
  addClonedDrillDownStep: (step: DrilldownTypes.DrillDownStep) => void;
  onDeleteDrillDownStep: (stepId: string) => void;
  onChangeLayout: (id: string, hRatio: number) => void;
  baseHeight: number;
  setError?: (stepId: string, errors: string[]) => void;
  toolSpec: ToolMetaDataList;
  isToolSpecFetching: boolean;
  isToolSpecError: boolean;
  toolSpecError: string;
  impactedWidgets?: ImpactedWidget[];
  pickerContextDto: FieldPickerContextDTO;
};

const formatOptionLabel = (option: IncSelectOption<ToolMetaData>) => {
  const icon = option.data?.svgIcon ? (
    getSourceTypeIcon(option.value, option.data.svgIcon)
  ) : option.data?.icon ? (
    <IncFaIcon iconName={option.data?.icon as IncFaIconName} />
  ) : (
    <></>
  );
  return (
    <VerticallyCenteredRow className="flex-gap-12">
      {icon}
      {option.label}
    </VerticallyCenteredRow>
  );
};

const { attributes } = CypressConstants.components.TriageCypressConstants.WorkflowConstants;

const DrillDownStepChild: FC<Props> = props => {
  const {
    drillDownStep,
    impactedWidgets,
    isToolSpecFetching,
    onDeleteDrillDownStep,
    pickerContextDto: pContextDto,
    toolSpec,
    addClonedDrillDownStep,
    onUpdateStep,
    setError,
    baseHeight,
    onChangeLayout
  } = props;

  const drillDownStepRef = useRefState<DrilldownTypes.DrillDownStep>(drillDownStep);

  const { tenantConfigState } = useTenantConfig();
  const { demoTenant } = tenantConfigState || {};

  const { predicate, uiKey: drillDownStepId, name = "", isDemoSuccessStep: isMarkedAsDemo, causeType } = drillDownStep;

  // cause type
  const selectedCausTypeOption = useMemo<IncSelectOption<IncidentSummaryCauseType>>(
    () => causeTypeOptions.find(option => option.value === causeType),
    [causeType]
  );
  const onChangeCauseType = useCallback(
    (option: IncSelectOption<IncidentSummaryCauseType>) => {
      if (drillDownStepRef.current) {
        onUpdateStep({
          ...drillDownStepRef.current,
          causeType: option.value as IncidentSummaryCauseType
        });
      }
    },
    [drillDownStepRef, onUpdateStep]
  );

  const isInDraftMode =
    !drillDownStepId || drillDownStepId?.includes(cloneChildIdPrefix) || drillDownStepId?.includes(addChildIdPrefix);

  const { correlatedEventToolSpec, dimensionalAnalysisToolSpec, googleSearchToolSpec } = predicate || {};
  // tool spec option
  const toolSpecOptions = useMemo(() => {
    const options: Array<IncSelectOption<ToolMetaData>> = [];

    const { tools = [] } = toolSpec || {};
    tools.forEach(tool => {
      options.push({
        label: tool.name,
        value: tool.type,
        data: tool
      });
    });
    return options;
  }, [toolSpec]);
  const selectedOption = useMemo<IncSelectOption<ToolMetaData>>(() => {
    if (correlatedEventToolSpec) {
      const selectedOption = toolSpecOptions.find(option => option.value === ToolType.CORRELATED_EVENT);
      return selectedOption;
    }
    if (dimensionalAnalysisToolSpec) {
      const selectedOption = toolSpecOptions.find(option => option.value === ToolType.DIMENSION_ANALYSIS);
      return selectedOption;
    }
    if (googleSearchToolSpec) {
      const selectedOption = toolSpecOptions.find(option => option.value === ToolType.GOOGLE_SEARCH);
      return selectedOption;
    }
    return null;
  }, [correlatedEventToolSpec, dimensionalAnalysisToolSpec, googleSearchToolSpec, toolSpecOptions]);
  const forceUpdate = useForceUpdate();
  const onChangeOption = useCallback(
    (option: IncSelectOption<ToolMetaData>) => {
      if (drillDownStepRef.current) {
        const drillDownStep = drillDownStepRef.current;
        const { data } = option;
        const { type } = data || {};
        if (type === ToolType.CORRELATED_EVENT) {
          const newStep: DrilldownTypes.DrillDownStep = {
            ...drillDownStep,
            predicate: {
              correlatedEventToolSpec: {
                paramValues: {},
                querySource: "",
                filters: {}
              }
            }
          };
          onUpdateStep(newStep);
        } else if (type === ToolType.GOOGLE_SEARCH) {
          const newStep: DrilldownTypes.DrillDownStep = {
            ...drillDownStep,
            predicate: {
              googleSearchToolSpec: {
                querySource: "",
                query: null,
                paramValues: {},
                keywords: []
              }
            }
          };
          onUpdateStep(newStep);
        } else if (type === ToolType.DIMENSION_ANALYSIS) {
          const newStep: DrilldownTypes.DrillDownStep = {
            ...drillDownStep,
            predicate: {
              dimensionalAnalysisToolSpec: {
                querySource: "",
                query: null,
                paramValues: {},
                dimensions: {
                  slices: []
                }
              }
            }
          };
          onUpdateStep(newStep);
        }
        forceUpdate();
      }
    },
    [drillDownStepRef, forceUpdate, onUpdateStep]
  );

  const { data: selectedToolSpec } = selectedOption || {};
  const { params = [] } = selectedToolSpec || {};

  // name and description
  const onChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (drillDownStepRef.current) {
        onUpdateStep({
          ...drillDownStepRef.current,
          name: e.target.value
        });
      }
    },
    [drillDownStepRef, onUpdateStep]
  );

  const onChangeDimensionalAnalysisToolSpec = useCallback(
    (dimensionalAnalysisToolSpec: DimensionalAnalysisToolSpec) => {
      if (drillDownStepRef.current) {
        onUpdateStep({
          ...drillDownStepRef.current,
          predicate: {
            dimensionalAnalysisToolSpec
          }
        });
      }
      setTimeout(() => forceUpdate(), 10);
      forceUpdate();
    },
    [drillDownStepRef, forceUpdate, onUpdateStep]
  );
  const onChangeCorrelatedEventToolSpec = useCallback(
    (correlatedEventToolSpec: CorrelatedEventToolSpec) => {
      if (drillDownStepRef.current) {
        onUpdateStep({
          ...drillDownStepRef.current,
          predicate: {
            correlatedEventToolSpec
          }
        });
        forceUpdate();
      }
    },
    [drillDownStepRef, forceUpdate, onUpdateStep]
  );
  const onChangeGoogleSearchToolSpec = useCallback(
    (googleSearchToolSpec: GoogleSearchToolSpec) => {
      if (drillDownStepRef.current) {
        onUpdateStep({
          ...drillDownStepRef.current,
          predicate: {
            googleSearchToolSpec
          }
        });
        forceUpdate();
      }
      // }
    },
    [drillDownStepRef, forceUpdate, onUpdateStep]
  );
  const onChangeToggle = useCallback(
    (checked?: boolean) => {
      if (drillDownStepRef.current) {
        onUpdateStep({
          ...drillDownStepRef.current,
          isDemoSuccessStep: checked
        });
      }
    },
    [drillDownStepRef, onUpdateStep]
  );

  // save
  const errorMessageList = useMemo(() => validateWorkflowStep(drillDownStep), [drillDownStep]);

  useEffect(() => {
    if (setError) {
      setError(drillDownStepId, errorMessageList);
    }
  }, [errorMessageList, setError, drillDownStepId]);
  // delete
  const { isOpen, close, open } = useToggleState();
  const onConfirmDelete = useCallback(() => {
    close();
    onDeleteDrillDownStep(drillDownStep.uiKey);
  }, [close, drillDownStep.uiKey, onDeleteDrillDownStep]);

  const onClone = useCallback(() => {
    if (drillDownStepRef.current) {
      const clonedStep = cloneDeep(drillDownStepRef.current);
      const generatedId = generateId();
      clonedStep.id = `${cloneChildIdPrefix}-${generatedId}`;
      clonedStep.uiKey = `${cloneChildIdPrefix}-${generatedId}`;
      clonedStep.name = `${cloneChildNamePrefix}-${clonedStep.name}`;
      addClonedDrillDownStep(clonedStep);
    }
  }, [addClonedDrillDownStep, drillDownStepRef]);

  const wrapperRef = useRef<HTMLDivElement>();

  const getDimensions = useCallback(() => {
    const canvasElem: HTMLElement = wrapperRef.current;
    if (canvasElem) {
      const dimensions = canvasElem?.getBoundingClientRect();
      if (dimensions) {
        const { height } = dimensions;
        const { width } = dimensions;
        return {
          width,
          height
        };
      }
    }
    return {
      width: -1,
      height: -1
    };
  }, [wrapperRef]);

  const { height } = getDimensions();

  useEffect(() => {
    const ratio = height / baseHeight;
    if (ratio > 0) {
      onChangeLayout(drillDownStepId, ratio);
    }
  }, [baseHeight, drillDownStepId, height, onChangeLayout]);

  const onLoadMetrics = useCallback(() => {
    setTimeout(() => forceUpdate(), 10);
  }, [forceUpdate]);

  const isDemoJsx = useMemo(
    () =>
      !isInDraftMode &&
      demoTenant && (
        <IncToggle
          checked={isMarkedAsDemo}
          label={"Mark as demo"}
          onChange={onChangeToggle}
        />
      ),
    [demoTenant, isInDraftMode, isMarkedAsDemo, onChangeToggle]
  );

  return (
    <div
      className="inc-card-layout inc-flex-column flex-gap-12 width-100"
      id={`widget-${drillDownStepId}`}
      ref={wrapperRef}
      style={{ border: "1px solid rgba(255, 255, 255, 0.18)" }}
    >
      <span
        className="list-item-handle"
        style={{ cursor: "move" }}
      >
        <IncFaIcon iconName="grip-dots-vertical"></IncFaIcon>
      </span>
      {isOpen && (
        <IncInModalConfirmation
          message={isInDraftMode ? "Are you sure you want to remove step?" : "Are you sure you want to delete step?"}
          onCancel={close}
          onConfirm={onConfirmDelete}
        />
      )}

      <IncSelect
        classNamePrefix={attributes.toolSpecDropDown}
        data-cy={attributes.toolSpecDropDown}
        errorText="ToolSpec is required"
        formatOptionLabel={formatOptionLabel}
        hasError={!selectedOption}
        isLoading={isToolSpecFetching}
        label="Tool Spec"
        onChange={onChangeOption}
        options={toolSpecOptions}
        placeholder="Select tool spec"
        value={selectedOption}
      />

      <IncTextfield
        className="width-100 "
        errorText="Name is required"
        hasError={!name}
        label="Name"
        name="name"
        onChange={onChangeName}
        placeholder="Enter step name"
        value={name}
      />

      <IncSelect
        label="Cause Type"
        onChange={onChangeCauseType}
        options={causeTypeOptions}
        placeholder="Select cause type"
        value={selectedCausTypeOption}
      />

      {selectedOption?.value === ToolType.DIMENSION_ANALYSIS && (
        <DimensionalAnalysisToolSpecEditor
          dimensionalAnalysisToolSpec={dimensionalAnalysisToolSpec}
          impactedWidgets={impactedWidgets}
          onChange={onChangeDimensionalAnalysisToolSpec}
          onLoadMetrics={onLoadMetrics}
          params={params}
          pickerContextDto={pContextDto}
          updateHeight={forceUpdate}
        >
          {isDemoJsx}
        </DimensionalAnalysisToolSpecEditor>
      )}
      {selectedOption?.value === ToolType.CORRELATED_EVENT && (
        <CorrelatedEventToolSpecEditor
          correlatedEventToolSpec={correlatedEventToolSpec}
          onChange={onChangeCorrelatedEventToolSpec}
          params={params}
          updateHeight={forceUpdate}
        >
          {isDemoJsx}
        </CorrelatedEventToolSpecEditor>
      )}
      {selectedOption?.value === ToolType.GOOGLE_SEARCH && (
        <GoogleSearchToolSpecEditor
          googleSearchToolSpec={googleSearchToolSpec}
          onChange={onChangeGoogleSearchToolSpec}
          params={params}
          updateHeight={forceUpdate}
        >
          {isDemoJsx}
        </GoogleSearchToolSpecEditor>
      )}

      <VerticallyCenteredRow className="flex-gap-12 width-100 inc-flex-end ">
        {
          <IncToolTip titleText="Clone">
            <ISaxIcon
              className="inc-cursor-pointer"
              iconName="Copy"
              onClick={onClone}
            />
          </IncToolTip>
        }
        {!isInDraftMode && (
          <IncButton
            color="secondary-red"
            iconName="trash"
            onClick={open}
          >
            Delete
          </IncButton>
        )}
        {isInDraftMode && (
          <IncButton
            color="secondary-red"
            iconName="close"
            onClick={open}
          >
            Remove
          </IncButton>
        )}
      </VerticallyCenteredRow>
    </div>
  );
};

const causeTypeOptions: Array<IncSelectOption<IncidentSummaryCauseType>> = [
  {
    label: "Biz Event",
    value: IncidentSummaryCauseType.biz_event,
    data: IncidentSummaryCauseType.biz_event
  },
  {
    label: "Change Event",
    value: IncidentSummaryCauseType.change_event,
    data: IncidentSummaryCauseType.change_event
  },
  {
    label: "Technical",
    value: IncidentSummaryCauseType.technical,
    data: IncidentSummaryCauseType.technical
  }
];

export default DrillDownStepChild;
