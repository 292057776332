import React from "react";
import { IncFaIcon } from "@inception/ui";
import { widgetRegistry, Dimensions } from "../WidgetRegistry";
import { FiltersWidget } from "./FiltersWidget";

widgetRegistry.register({
  id: "filter",
  label: "Filters",
  enableInDashboard: false,
  supportsComparison: false,
  supportsNativeEdit: false,
  disableVizSwitch: true,
  iconComponent: (): JSX.Element => (
    <IncFaIcon
      iconName="filter"
      width={24}
    />
  ),
  component: props => <FiltersWidget {...props} />,
  dimensions: (): Dimensions => ({
    w: 6,
    h: 4,
    minW: 4,
    minH: 1
  })
});
