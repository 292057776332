import React, { useEffect, useMemo } from "react";
import { isNil, values } from "lodash";
import { IncButton, IncFaIcon, IncModal, IncSmartText } from "@inception/ui";
import { IncGradientLoadingSpinner } from "@inception/ui/src/components/GradientLoadingSpinner/GradientLoadingSpinner";
import {
  ConversationStatus,
  LowLevelFragment,
  ParameterValue,
  PlanExecutionResponse,
  PlanNode,
  PlanNodeExecution
} from "../../services/api/chat";
import { VerticallyCenteredRow } from "../../components";
import { FragmentRendererV2, SubStepsRenderer } from "../../components/fragments";
import { useToggleState } from "../../core";
import { TypingAnimation } from "../../chat-bot/components/TypingAnimation";
import { FeebackPanel } from "../../chat-bot/components/FeedbackPanel";
import { magicButtonIcon } from "./PlanEditor";

interface Props {
  planExecutionResponse: PlanExecutionResponse;
  status: ConversationStatus;
  messageId: string;
}

// Execution Flow Nodes construction flow
/*
  - PlanNodeExecution
    - Plan EdgeExecution - accordian
      - PlanNodeExecution - accordian (execute recursion)
      -lowLevelFragment
    - LowLevelFragment - accordian
*/

export const PlanExecutionRenderer: React.FC<Props> = (props: Props) => {
  const { planExecutionResponse, status, messageId } = props;
  const {
    name,
    lowLevelFragment,
    planNodeExecutions,
    status: planExecutionStatus,
    suggestedValues
  } = planExecutionResponse || {};
  const { isOpen, toggle } = useToggleState();

  const isPlanOngoing = status === ConversationStatus.ONGOING;

  const planNodesJsx = useMemo(() => {
    const filteredPlanNodeExecutions: PlanNodeExecution[] = [];
    let ongoingFound = false;
    planNodeExecutions.forEach(x => {
      if (!ongoingFound) {
        filteredPlanNodeExecutions.push(x);
      }
      if (x.status === ConversationStatus.ONGOING) {
        ongoingFound = true;
      }
    });
    return (
      <div className="inc-flex-column flex-gap-24 padding16">
        {!filteredPlanNodeExecutions?.length && <TypingAnimation className="typing-background" />}
        {filteredPlanNodeExecutions?.map((planNodeExecution, index) => (
          <PlanNodeRenderer
            isOpen={index === filteredPlanNodeExecutions?.length - 1}
            key={index}
            planNodeExecution={planNodeExecution}
            status={planExecutionStatus}
          />
        ))}
      </div>
    );
  }, [planExecutionStatus, planNodeExecutions]);

  const planSkeleton = useMemo(
    () => (
      <div className="plan-skeleton-container inc-flex-column">
        <VerticallyCenteredRow className="header inc-flex-row flex-gap-12 padding16">
          {magicButtonIcon}
          <IncSmartText
            className="inc-text-header"
            text={name || "Your Plan"}
          />
        </VerticallyCenteredRow>
        {planNodeExecutions.map((x, idx) => (
          <PlanNodeSkeletonRenderer
            key={idx}
            planNode={x.planNode}
            status={x.status}
            suggestedValues={suggestedValues}
          />
        ))}
      </div>
    ),
    [name, planNodeExecutions, suggestedValues]
  );

  const onGoingView = useMemo(
    () => (
      <div className="plan-execution-grid">
        {planSkeleton}
        {planNodesJsx}
      </div>
    ),
    [planNodesJsx, planSkeleton]
  );

  return (
    <div
      className="plan-execution-renderer"
      data-complete={!isPlanOngoing}
    >
      {isPlanOngoing ? (
        onGoingView
      ) : (
        <div className="inc-flex-column flex-gap-16 padding16 height-100">
          <span className="inc-text-lead-bold">{name}</span>
          <FragmentRendererV2
            status={status}
            subStep={lowLevelFragment}
          />

          <VerticallyCenteredRow className="marginTpAuto">
            <FeebackPanel messageId={messageId} />
            <IncButton
              className="marginLtAuto"
              color="link"
              onClick={toggle}
            >
              {magicButtonIcon} Explore AI’s reasoning
            </IncButton>
          </VerticallyCenteredRow>
        </div>
      )}
      {!isPlanOngoing && (
        <IncModal
          className="explore-steps-modal"
          onClose={toggle}
          show={isOpen}
          size="side-pane"
          titleText={"Explore AI’s reasoning"}
          withTitleBorder
        >
          {planNodesJsx}
        </IncModal>
      )}
    </div>
  );
};

interface PlanNodeRendererProps {
  planNodeExecution: PlanNodeExecution;
  status: ConversationStatus;
  isOpen: boolean;
}

export const PlanNodeRenderer: React.FC<PlanNodeRendererProps> = (props: PlanNodeRendererProps) => {
  const { planNodeExecution, isOpen: defIsOpen } = props;
  const { status, planNode } = planNodeExecution;
  const { name } = planNode || {};
  const { isOpen, close, toggle } = useToggleState(defIsOpen);

  useEffect(() => {
    if (status === ConversationStatus.COMPLETED && !defIsOpen) {
      close();
    }
  }, [close, defIsOpen, status]);

  const allLowLevelFragments = useMemo(() => {
    const list: LowLevelFragmentWithStatus[] = [];
    appendAllLowLevelFragmentsFromPlanNodeExecution(planNodeExecution, list);
    return list;
  }, [planNodeExecution]);

  // create a master array and add all low level fragments with status completed stop once you get a fragment with onging status
  const filteredLowLevelFragments = useMemo(() => {
    const list: LowLevelFragmentWithStatus[] = [];
    let ongoingFound = false;
    allLowLevelFragments.forEach(x => {
      if (!ongoingFound) {
        list.push(x);
      }
      if (x.status === ConversationStatus.ONGOING) {
        ongoingFound = true;
      }
    });

    return list;
  }, [allLowLevelFragments]);

  const isLoading = status === ConversationStatus.ONGOING;

  const lowLevelFragmentsJsx = useMemo(
    () =>
      filteredLowLevelFragments.map((x, idx) => (
        <SubStepsRenderer
          idx={idx}
          keepLastOpen={true}
          key={idx}
          status={x.status}
          subStep={x.lowLevelFragment}
          totalSteps={filteredLowLevelFragments?.length || 0}
        />
      )),
    [filteredLowLevelFragments]
  );

  return (
    <div className="plan-node-container inc-flex-row flex-gap-12">
      <div className="loading-parent">
        <div className="loading-icon-container">
          {isLoading ? (
            <IncGradientLoadingSpinner className="padding5" />
          ) : (
            <IncFaIcon
              className="status-success"
              iconName="check-circle"
              style={{ padding: 8 }}
              variant="regular"
            />
          )}
        </div>
      </div>
      <div className="plan-node-renderer inc-flex-column">
        <VerticallyCenteredRow
          className="header-step flex-gap-16 cursor-pointer"
          data-expanded={isOpen}
          onClick={toggle}
        >
          <span className="inc-text-body-medium">{name}</span>
          {isLoading && (
            <div className="header-step--live-pill inc-text-subtext">
              <div className="dot"></div>
              Live
            </div>
          )}
          <IncFaIcon
            className="marginLtAuto"
            iconName={isOpen ? "chevron-circle-up" : "chevron-circle-down"}
            variant="regular"
          />
        </VerticallyCenteredRow>
        {isOpen && lowLevelFragmentsJsx}
      </div>
    </div>
  );
};

interface PlanNodeSkeletonRendererProps {
  planNode: PlanNode;
  status: ConversationStatus;
  suggestedValues: ParameterValue[];
}

const PlanNodeSkeletonRenderer = (props: PlanNodeSkeletonRendererProps) => {
  const { planNode, status, suggestedValues } = props;
  const { name, edges } = planNode;

  const otherView = useMemo(() => {
    const commonTextClass = status === ConversationStatus.NA ? "inc-text-inactive" : "";

    return (
      <div className="inc-flex-column flex-gap-8">
        <span className={`inc-text-body` + ` ${commonTextClass}`}>{name}</span>
        {edges?.map(edge => {
          const nodes = edge?.true?.items?.length > 0 ? edge.true.items : edge.false.items;

          return nodes.map((x, idx) => {
            const questionString =
              x?.toolSpec?.dataAnalystToolSpec?.question || x?.toolSpec?.dataFetcherToolSpec?.nlpQuery || "";

            const pattern = /\$\{([^\\}]+)\}/g;
            const matches = [...questionString.matchAll(pattern)].map(match => match[1]);
            const paramKeys = matches;
            const paramValMap: Record<string, string> = {};

            paramKeys.forEach(x => {
              const paramValue = (suggestedValues || []).find(sv => sv.paramId === x);
              paramValMap[x] = values(paramValue?.primValue)?.[0]?.toString() || "";
            });

            const finalString = `<span class="inc-text-subtext ${commonTextClass}">${questionString.replace(pattern, (match, p1) => `<u>${paramValMap[p1]?.toString() || match}</u>`)}</span>`;

            return (
              <div
                className="inc-flex-row flex-gap-12"
                key={`${x.name}+${idx}`}
              >
                <div className="dot marginTp6" />
                <div dangerouslySetInnerHTML={{ __html: finalString }} />
              </div>
            );
          });
        })}
      </div>
    );
  }, [edges, name, status, suggestedValues]);

  return (
    <div className="node-skeleton inc-flex-row flex-gap-12">
      {status === ConversationStatus.ONGOING ? (
        <div>
          <IncGradientLoadingSpinner />
        </div>
      ) : status === ConversationStatus.COMPLETED ? (
        <IncFaIcon
          className="status-success"
          iconName="check-circle"
          variant="regular"
        />
      ) : (
        <div className="dot" />
      )}
      <>
        {status === ConversationStatus.COMPLETED ? (
          <span className="inc-text-subtext status-success">{name}</span>
        ) : (
          otherView
        )}
      </>
    </div>
  );
};

interface LowLevelFragmentWithStatus {
  lowLevelFragment: LowLevelFragment;
  status: ConversationStatus;
}

const appendAllLowLevelFragmentsFromPlanNodeExecution = (
  planNodeExecution: PlanNodeExecution,
  lowLevelFragmentWithStatusList: LowLevelFragmentWithStatus[] = []
) => {
  const { edgeExecution, lowLevelFragment, status } = planNodeExecution;

  if (edgeExecution?.length > 0) {
    edgeExecution.forEach(edgeEx => {
      const planNodeExecutions = edgeEx?.true?.length > 0 ? edgeEx.true : edgeEx?.false?.length > 0 ? edgeEx.false : [];
      planNodeExecutions.forEach(planNodeEx => {
        appendAllLowLevelFragmentsFromPlanNodeExecution(planNodeEx, lowLevelFragmentWithStatusList);
      });
      if (edgeEx.lowLevelFragment) {
        lowLevelFragmentWithStatusList.push({
          lowLevelFragment: edgeEx.lowLevelFragment,
          status: edgeEx.status
        });
      }
    });
  }

  if (!isNil(lowLevelFragment)) {
    lowLevelFragmentWithStatusList.push({
      lowLevelFragment,
      status
    });
  }
};
