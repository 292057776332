import { clone } from "lodash";
import React, { ElementType, FC, useCallback, useMemo } from "react";
import { CatalogWidgetProperties } from "../../../models";
import { WidgetConfigurationTabProps } from "../types";
import { CatalogVizSwitcher } from "../VizSwitcher";
import { CatalogWidgetUtils } from "../../../CatalogWidgetUtils";
import { Visualisations } from "../../../../../../core/data/types/VizTypes";
import { DataTypeCustomization, UICustomizations, WidgetCustomizationProps } from "./components";

interface Props extends WidgetConfigurationTabProps {}

export const CustomizationTab: FC<Props> = props => {
  const { widgetImpl, widgetResponseDTO, onWidgetImplChange: setWidgetImpl } = props;

  const metricTypes = useMemo(() => {
    const { widgetConfig } = widgetResponseDTO || {};
    const metrics = widgetConfig?.dataDefinition?.metrics || {};
    const metricTypes: string[] = [];
    Object.values(metrics).forEach(metric => {
      if (metric.subType) {
        metricTypes.push(metric.subType);
      }

      if (metric.sourceType === "userServiceField") {
        const { sliceSets } = metric.userServiceFieldMetricConfig;
        sliceSets.forEach(slSet =>
          slSet.slices.forEach(sl => {
            if (sl.userServiceField?.dataType) {
              metricTypes.push(sl.userServiceField.dataType);
            }
          })
        );
      } else if (metric.sourceType === "bizEntityMetric") {
        const { sliceSet } = metric.bizEntityMetricConfig;
        (sliceSet?.slices || []).forEach(sl => {
          if (sl.fieldType) {
            metricTypes.push(sl.fieldType);
          }
        });
      }
    });

    return metricTypes;
  }, [widgetResponseDTO]);

  const { properties, queryConfig } = widgetImpl;

  const { visualisation } = properties || {};

  const vizOptions = useMemo(() => CatalogWidgetUtils.getVisualisations(queryConfig), [queryConfig]);
  const onVisualisationChange = useCallback(
    (visualisation: Visualisations) => {
      setWidgetImpl(pWidgetImpl => {
        const nWidgetImpl = clone(pWidgetImpl);
        nWidgetImpl.properties.visualisation = visualisation;
        return nWidgetImpl;
      });
    },
    [setWidgetImpl]
  );

  const selVizOpt = useMemo(
    () => vizOptions.find(({ visualisation: viz }) => viz === visualisation) || vizOptions[0],
    [visualisation, vizOptions]
  );

  const onPropertiesChange = useCallback(
    (widgetProps: CatalogWidgetProperties) => {
      setWidgetImpl(pWidgetImpl => {
        const nWidgetImpl = clone(pWidgetImpl);
        nWidgetImpl.properties = widgetProps;
        return nWidgetImpl;
      });
    },
    [setWidgetImpl]
  );

  const uiCustomizationConfig = useMemo(() => {
    const selectedWidget = UICustomizations[selVizOpt?.visualisation || Visualisations.pieChart];
    const CustomComponent = selectedWidget as ElementType<WidgetCustomizationProps>;
    return (
      <CustomComponent
        onPropertiesChange={onPropertiesChange}
        properties={properties}
        widgetImpl={widgetImpl}
        widgetResponseDTO={widgetResponseDTO}
      />
    );
  }, [onPropertiesChange, properties, selVizOpt?.visualisation, widgetImpl, widgetResponseDTO]);

  return (
    <div className="customization-tab">
      <div className="customization-tab--content">
        <div className="section-header">
          <CatalogVizSwitcher
            onVisualisationChange={onVisualisationChange}
            visualisation={visualisation}
            vizOptions={vizOptions}
          />
        </div>

        <DataTypeCustomization
          metricTypes={metricTypes}
          onPropertiesChange={onPropertiesChange}
          properties={properties}
          widgetImpl={widgetImpl}
          widgetResponseDTO={widgetResponseDTO}
        />

        {uiCustomizationConfig}
      </div>
    </div>
  );
};
