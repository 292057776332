import { IncSelect } from "@inception/ui";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { logger, useFetchDataStatus, useSchemaStore } from "../../../core";
import { schemaApiService } from "../../../services/api";
import { EntityTypeWrapper } from "../../../services/api/types";

export type FetchEntityTypeResponse<T> = {
  data?: T;
  error?: any;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  resetStates: () => void;
  fetchEntityTypes: () => void;
};

export const useEntityTypeSelector = (
  prefetched?: EntityTypeWrapper[],
  bizEntitiesOnly = false
): FetchEntityTypeResponse<EntityTypeWrapper[]> => {
  const dataStatus = useFetchDataStatus<EntityTypeWrapper[], unknown>();
  const { rawEntityTypesData } = useSchemaStore();

  const { setStatus } = dataStatus;

  const fetchEntityTypes = useCallback(async () => {
    let types;

    try {
      if (!isEmpty(rawEntityTypesData)) {
        types = rawEntityTypesData;
      } else {
        const r = await schemaApiService.getEntityTypes();
        types = r.data ? r.data.types : [];
      }

      if (bizEntitiesOnly) {
        types = types.filter(t => t.entityType.typeReference.category === "biz");
      }
      setStatus(types, false, true, false, null);
    } catch (error) {
      logger.error("useEntityTypeSelector", "Error while fetching entity types", error);
      setStatus(null, false, false, true, error);
    }
  }, [rawEntityTypesData, bizEntitiesOnly, setStatus]);

  useEffect(() => {
    if (!prefetched) {
      fetchEntityTypes();
    } else {
      const prefetchFiltered =
        bizEntitiesOnly && !isEmpty(prefetched)
          ? prefetched.filter(t => t.entityType.typeReference.category === "biz")
          : prefetched;
      setStatus(prefetchFiltered, false, true, false, null);
    }
  }, [bizEntitiesOnly, fetchEntityTypes, prefetched, setStatus]);

  return {
    ...dataStatus,
    fetchEntityTypes
  };
};

type WrappedSelectionOption<T> = {
  label: string;
  value: string;
  payload: T | null;
};

type EntityTypeSelectionOption = WrappedSelectionOption<EntityTypeWrapper>;

const wrapToSelectedOption = (entityTypes: EntityTypeWrapper[]): EntityTypeSelectionOption[] => {
  const wrapped: EntityTypeSelectionOption[] = !isEmpty(entityTypes)
    ? entityTypes.map(e => ({
        payload: e,
        label: e.entityType.typeReference.typeName,
        value: e.entityType.typeReference.id
      }))
    : [];
  return wrapped;
};

interface EntityTypeSelectorProps {
  prefetched?: EntityTypeWrapper[];
  onChange?: (e: EntityTypeWrapper) => void;
  preselect?: EntityTypeWrapper;
  filterEntityTypes?: (e: EntityTypeWrapper[]) => EntityTypeWrapper[];
  label?: string;
  hideLabel?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  bizEntitiesOnly?: boolean;
  isClearable?: boolean;
}

const EntitytypeSelector: React.FC<EntityTypeSelectorProps> = (props: EntityTypeSelectorProps) => {
  const {
    onChange,
    preselect,
    prefetched,
    label,
    hideLabel,
    filterEntityTypes,
    isDisabled,
    isLoading,
    bizEntitiesOnly,
    isClearable
  } = props;
  const { isFetching, isSuccess, isError, data } = useEntityTypeSelector(prefetched, bizEntitiesOnly);
  const [entityTypeOpts, setEntityTypeOpts] = useState<EntityTypeSelectionOption[]>();
  const [preselected, setPreselected] = useState<EntityTypeSelectionOption>();
  const { formatMessage } = useIntl();

  const placeHolderi18n = useMemo(() => formatMessage({ id: "entity.single.selector.label" }), [formatMessage]);

  const setPreselect = useCallback(
    (options: EntityTypeSelectionOption[]) => {
      if (preselect) {
        const match = options.find(o => {
          const { id, typeName } = o.payload.entityType.typeReference;
          const { id: preselectId, typeName: preselectTypeName } = preselect.entityType.typeReference;
          return id === preselectId && typeName === preselectTypeName;
        });
        if (match) {
          setPreselected(match);
        }
      } else {
        setPreselected(null);
      }
    },
    [preselect]
  );

  useEffect(() => {
    if (!isFetching && isSuccess && data) {
      const filteredData = filterEntityTypes ? filterEntityTypes(data) : data;
      const options = wrapToSelectedOption(filteredData);
      setEntityTypeOpts(options);
      setPreselect(options);
    }
  }, [isFetching, isSuccess, isError, data, preselect, setPreselect, filterEntityTypes]);

  const onEntityTypeChange = useCallback(
    (e: EntityTypeSelectionOption) => {
      if (onChange) {
        onChange(e?.payload);
      }
    },
    [onChange]
  );

  const labelFinal = useMemo(() => (hideLabel === true ? "" : label ? label : "Entity Selection"), [hideLabel, label]);

  return (
    <IncSelect<EntityTypeSelectionOption, false>
      className="select-input"
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={isFetching || isLoading}
      isMulti={false}
      label={labelFinal}
      onChange={onEntityTypeChange}
      options={entityTypeOpts}
      placeholder={placeHolderi18n}
      value={preselected}
    />
  );
};

export default EntitytypeSelector;
