import React, { FC, useMemo, useEffect, useState, useCallback } from "react";
import { extend } from "lodash";
import { IncRTable, IncToolTip, IncFaIcon, IncRTableProps, TableDataColumn, IncButton } from "@inception/ui";
import {
  UserServiceField,
  ExploreEntityFilter,
  UserServiceFilterExpression,
  DownloadOptions,
  UserServiceFilterList,
  ImpactedWidget,
  alertApiService,
  ImpactWidgetViewRequest,
  SortSpec
} from "../../services/api/explore";
import { TimeRange, generateId, logger, useNotifications } from "../../core";
import { FieldPickerUtils, eventFieldUtils, downloadBlobFile } from "../../utils";
import { VerticallyCenteredRow } from "..";
import { useFetchEventsTableDataV2 } from "./useFetchEventsTableData";

interface Props {
  entityTypeId: string;
  selectedUSFields: UserServiceField[];

  timeRange: TimeRange;
  entityFilters?: ExploreEntityFilter[];
  eventFilters?: UserServiceFilterList;
  extendedFilters?: UserServiceFilterExpression[];
  impactedWidget?: ImpactedWidget;
  opConfigId?: string;
  incidentId?: string;
}

export const TableRenderer: FC<Props> = props => {
  const {
    selectedUSFields,
    timeRange,
    entityFilters,
    eventFilters,
    extendedFilters,
    impactedWidget,
    incidentId,
    opConfigId
  } = props;

  const { notifyError } = useNotifications();

  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const uniqId = useMemo(() => generateId(), []);
  const { data, error, isError, isFetching, payload, refetch } = useFetchEventsTableDataV2({
    uniqId,
    extendedFilters,
    entityFilters,
    eventFilters,
    timeRange,
    impactedWidget,
    incidentId,
    opConfigId,
    selectedUSFields
  });

  const downloadCsv = useCallback(async () => {
    const downloadOptions: DownloadOptions = {
      fileName: `Events.xlsx`,
      metricToHeaderName: {},
      tagToHeaderName: {}
    };

    setDownloadInProgress(true);

    const fieldIds = payload.sliceSpec.map(ss => ss.fieldId);
    const eventFiltersRec: Record<string, UserServiceFilterList> = {};
    if (eventFilters) {
      fieldIds.forEach(fieldId => (eventFiltersRec[fieldId] = eventFilters));
    }

    const sortSpec: SortSpec = {
      sortBy: "current",
      limitSpec: {
        function: "top",
        limit: 30
      }
    };
    const payloadV2: ImpactWidgetViewRequest = {
      entityFilters,
      eventFilters,
      sortSpec,
      incidentId,
      opConfigId,
      impactedWidget,
      userServiceFields: selectedUSFields,
      downloadOptions
    };
    const { data, error, message } = await alertApiService.downloadWidgetDataByConfig(
      payloadV2,
      timeRange.from.valueOf(),
      timeRange.to.valueOf()
    );
    setDownloadInProgress(false);

    if (!error) {
      downloadBlobFile(data, downloadOptions.fileName);
    } else {
      notifyError("Error while downloading file");
      logger.error("Events Table", "Error while downloading file", message);
    }
  }, [
    payload.sliceSpec,
    eventFilters,
    entityFilters,
    incidentId,
    opConfigId,
    impactedWidget,
    selectedUSFields,
    timeRange.from,
    timeRange.to,
    notifyError
  ]);

  useEffect(() => {
    if (selectedUSFields.length) {
      refetch();
    }
  }, [refetch, entityFilters, eventFilters, timeRange, selectedUSFields.length]);

  const { tableData } = useMemo(() => {
    const entityLookupData: Record<string, string> = {};
    const tableData: Array<Record<string, any>> = [];
    const fieldResult = data?.fieldResult?.[0];

    if (fieldResult) {
      const { data: fieldsData = {}, entityLookupData: lookup = {} } = fieldResult;

      extend(entityLookupData, lookup);
      const keys = Object.keys(fieldsData);
      const uniqIdToFind = keys.length > 0 ? keys[0] : uniqId;
      const events = fieldsData[uniqIdToFind] || [];
      events.forEach(event => {
        const { fields, ...rest } = event;

        const entry: Record<string, any> = { ...rest };

        fields.forEach(field => {
          const { key: fieldKey, value, values } = field;

          let key = eventFieldUtils.addFieldsPrefix(fieldKey);
          key = (key as any).replaceAll(".", "_"); // Doing this since table assumes . in the key as nesting

          const isMultiValue = Boolean(values?.length);
          const valuesArr = isMultiValue ? values : [value];
          const displayValue = (valuesArr || []).map(v => entityLookupData[v] || v).join(", ");

          entry[key] = displayValue;
        });

        tableData.push(entry);
      });
    }

    return {
      tableData
    };
  }, [data, uniqId]);

  const columns = useMemo<TableDataColumn[]>(
    () =>
      selectedUSFields.map(usField => ({
        accessor: (usField.fieldName as any).replaceAll(".", "_"), // Doing this since table assumes . in the key as nesting
        header: FieldPickerUtils.generateNamefromUSF(usField),
        sortable: true,
        align: "center",
        type: "alphanumeric"
      })),
    [selectedUSFields]
  );

  const errorDiv = useMemo(
    () =>
      error ? (
        <VerticallyCenteredRow className="flex-gap-6">
          <IncToolTip
            titleText={String(error)}
            variant="error"
          >
            <IncFaIcon iconName="exclamation-triangle" />
          </IncToolTip>
          <div>Error fetching fields</div>
        </VerticallyCenteredRow>
      ) : (
        <></>
      ),
    [error]
  );

  return (
    <IncRTable
      columns={columns}
      data={tableData}
      errorDataMessage={errorDiv}
      globalFilter={globalFilter}
      hasError={isError}
      isLoading={isFetching}
      pagination={pagination}
      resizableColumns
      showDisplayStats
    >
      <IncButton
        className="marginLtAuto"
        color="link"
        disabled={downloadInProgress}
        iconType="iconText"
        onClick={downloadCsv}
      >
        {!downloadInProgress && (
          <IncFaIcon
            className="marginRt6"
            iconName="download"
          />
        )}
        {downloadInProgress && (
          <IncFaIcon
            className="marginRt6"
            iconName="spinner"
            spin
          />
        )}
        Download CSV
      </IncButton>
    </IncRTable>
  );
};

const globalFilter: IncRTableProps["globalFilter"] = {
  enabled: true,
  align: "left",
  hideLabel: true,
  hideStatus: true,
  placeholder: "Type a keyword"
};

const pagination: IncRTableProps["pagination"] = {
  enabled: true,
  viewMode: "minimal",
  pageSize: 50
};
