import React, { Dispatch, FC, SetStateAction, useCallback, useMemo } from "react";
import { isNil, values } from "lodash";
import { PlanEdge, Plan, ParameterValue, PlanNode, ToolType } from "../../services/api/chat";

interface Props {
  edge: PlanEdge;
  setPlan: (value: Plan) => void;
  edgeIndex: number;
  isLastEdge: boolean;
  isFetching: boolean;
  suggestedValues: ParameterValue[];
  selectedNode?: PlanNode;
  setSelectedNode?: Dispatch<SetStateAction<PlanNode>>;
}

export const PlanEdgeEditor: FC<Props> = props => {
  const { edge, setPlan, isFetching, suggestedValues, setSelectedNode, selectedNode } = props;
  const trueNodes = edge?.true?.items || [];
  const falseNodes = edge?.false?.items || [];
  return (
    <div
      className="plan-edge-editor inc-flex-column flex-gap-4"
      data-show-loader={isFetching}
    >
      {(trueNodes || []).map((node, idx) => (
        <ConditionalNodeEditor
          isFetching={isFetching}
          key={node?.id + idx}
          node={node}
          selectedNode={selectedNode}
          setPlan={setPlan}
          setSelectedNode={setSelectedNode}
          suggestedValues={suggestedValues}
        />
      ))}
      {(falseNodes || []).map((node, idx) => (
        <ConditionalNodeEditor
          isFetching={isFetching}
          key={node?.id + idx}
          node={node}
          selectedNode={selectedNode}
          setPlan={setPlan}
          setSelectedNode={setSelectedNode}
          suggestedValues={suggestedValues}
        />
      ))}
    </div>
  );
};

interface ConditionalNodeEditorProps {
  node: PlanNode;
  setPlan: (value: Plan) => void;
  isFetching: boolean;
  suggestedValues: ParameterValue[];
  setSelectedNode?: Dispatch<SetStateAction<PlanNode>>;
  selectedNode?: PlanNode;
}

export const ConditionalNodeEditor: FC<ConditionalNodeEditorProps> = props => {
  const { node, setPlan, isFetching, suggestedValues, setSelectedNode, selectedNode } = props;
  const { edges } = node || {};

  return (
    <div className="conditional-node-editor inc-flex-column flex-gap-4">
      <ToolsSpecRenderer
        isFetching={isFetching}
        node={node}
        selectedNode={selectedNode}
        setSelectedNode={setSelectedNode}
        suggestedValues={suggestedValues}
      />
      {(edges || []).map((edge, idx) => (
        <PlanEdgeEditor
          edge={edge}
          edgeIndex={idx}
          isFetching={isFetching}
          isLastEdge={idx === (edges || []).length - 1}
          key={idx}
          selectedNode={selectedNode}
          setPlan={setPlan}
          setSelectedNode={setSelectedNode}
          suggestedValues={suggestedValues}
        />
      ))}
    </div>
  );
};

interface ToolsSpecRendererProps {
  node: PlanNode;
  isFetching: boolean;
  suggestedValues: ParameterValue[];
  setSelectedNode?: Dispatch<SetStateAction<PlanNode>>;
  selectedNode?: PlanNode;
}

export const ToolsSpecRenderer: FC<ToolsSpecRendererProps> = props => {
  const { node, isFetching, suggestedValues, setSelectedNode, selectedNode } = props;
  const { toolSpec } = node || {};

  const { toolType } = toolSpec;

  const isDataAnalyst = toolType === ToolType.DATA_ANALYST_TOOL;
  const isDataFetcher = toolType === ToolType.DATA_FETCHER_TOOL;

  const toolSpecString = useMemo(() => {
    let text = "";
    if (toolSpec?.dataAnalystToolSpec?.question) {
      text = toolSpec?.dataAnalystToolSpec?.question;
    } else if (toolSpec?.dataFetcherToolSpec?.nlpQuery) {
      text = toolSpec?.dataFetcherToolSpec?.nlpQuery;
    }
    const pattern = /\$\{([^\\}]+)\}/g;
    const matches = [...text.matchAll(pattern)].map(match => match[1]);
    const paramKeys = matches;
    const paramValMap: Record<string, string> = {};

    paramKeys.forEach(x => {
      const paramValue = suggestedValues.find(sv => sv.paramId === x);
      paramValMap[x] = values(paramValue?.primValue)?.[0]?.toString() || "";
    });

    const result = `<span>${text.replace(pattern, (match, p1) => `<u>${paramValMap[p1]?.toString() || match}</u>`)}</span>`;
    return result;
  }, [suggestedValues, toolSpec]);

  const isNodeSelected = useMemo(() => {
    if (selectedNode && node) {
      return selectedNode?.id === node?.id;
    }
  }, [node, selectedNode]);

  const className = isNodeSelected ? "selected-node width-100 marginTp8 marginBt8" : "common-node width-100";
  const dotClassName = isNodeSelected ? "blue-dot marginTp16" : "dot marginTp6";

  const onSelectNode = useCallback(() => {
    if (setSelectedNode) {
      setSelectedNode(node);
    }
  }, [node, setSelectedNode]);

  return (
    <>
      {isDataAnalyst && (
        <div
          className="inc-text-subtext inc-flex-column flex-gap-4"
          data-show-loader={isFetching}
        >
          {!isNil(toolSpec?.dataAnalystToolSpec?.question) && (
            <div
              className="question inc-flex-row flex-gap-8 inc-cursor-pointer "
              onClick={onSelectNode}
            >
              <div className={dotClassName} />
              <div
                className={className}
                dangerouslySetInnerHTML={{ __html: toolSpecString }}
              />
            </div>
          )}
        </div>
      )}
      {isDataFetcher && (
        <div
          className="inc-text-subtext inc-flex-column flex-gap-4"
          data-show-loader={isFetching}
        >
          {!isNil(toolSpec?.dataFetcherToolSpec?.nlpQuery) && (
            <div
              className="nlp-query inc-flex-row flex-gap-8 inc-cursor-pointer"
              onClick={onSelectNode}
            >
              <div className={dotClassName} />
              <div
                className={className}
                dangerouslySetInnerHTML={{ __html: toolSpecString }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
