import { useEffect, useMemo, useRef } from "react";
import { useFetchEventTypesForAddWidget, useForceUpdate, useTimeRange } from "../../../../../../core";
import { useFetchBizEntityUserservices } from "../../../../../../field-picker";
import { DemoDataParams, ExploreUserService } from "../../../../../../services/api/explore";
import timeRangeUtils from "../../../../../../utils/TimeRangeUtils";

export const useFetchEventTypesList = (entityTypeId = "", eventTypeId = "", demoDataParams?: DemoDataParams) => {
  const { timeRange } = useTimeRange();

  const { fromMillis, toMillis } = useMemo(() => timeRangeUtils.getMillisFromTimeRange(timeRange), [timeRange]);

  const isFetchingRef = useRef(true);
  const forceUpdate = useForceUpdate();

  const {
    error: eUSError,
    exploreUserservices: expUserServices,
    fetchExploreUserservices,
    isError: isEUSError,
    isFetching: isEUSFetching
  } = useFetchEventTypesForAddWidget(demoDataParams);

  const entityTypeExists = Boolean(entityTypeId);

  useEffect(() => {
    if (!entityTypeExists) {
      fetchExploreUserservices(fromMillis, toMillis);
      window.setTimeout(() => {
        isFetchingRef.current = false;
        forceUpdate();
      }, 100);
    }
  }, [entityTypeExists, fetchExploreUserservices, forceUpdate, fromMillis, toMillis]);

  const {
    data: beUserServices,
    error: beUSError,
    isError: isBeUSError,
    isFetching: isBeUSFetching,
    refetch: fetchBEUserservices
  } = useFetchBizEntityUserservices(entityTypeId, fromMillis, toMillis);

  useEffect(() => {
    if (entityTypeExists) {
      fetchBEUserservices();
      window.setTimeout(() => (isFetchingRef.current = false), 100);
    }
  }, [fetchBEUserservices, fromMillis, toMillis, entityTypeExists]);

  const isFetching =
    (!entityTypeId && isEUSFetching) || (Boolean(entityTypeId) && isBeUSFetching) || isFetchingRef.current;
  const isError = isEUSError || isBeUSError;
  const error = eUSError || beUSError;
  const exploreUserservices = useMemo<ExploreUserService[]>(() => {
    if (expUserServices?.length) {
      return expUserServices;
    }

    if (beUserServices?.length) {
      return beUserServices.map(bEUs => ({
        entityId: bEUs.userServiceId,
        entityType: entityTypeId,
        name: bEUs.userServiceName
      }));
    }

    return [];
  }, [beUserServices, entityTypeId, expUserServices]);

  const generateDemoData = demoDataParams?.generateDemoData;
  const userServices = useMemo<ExploreUserService[]>(() => {
    if (generateDemoData && eventTypeId) {
      if (!exploreUserservices.find(us => us.entityId === eventTypeId)) {
        return [
          ...exploreUserservices,
          {
            entityId: eventTypeId,
            entityType: "",
            name: eventTypeId
          }
        ];
      }
    }

    return exploreUserservices;
  }, [eventTypeId, exploreUserservices, generateDemoData]);

  return {
    isFetching: isFetching || isFetchingRef.current,
    isError,
    error,
    exploreUserservices: userServices
  };
};
