import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IncCheckbox, IncSelect, IncSelectOption, IncTextfield, IncToggle, generateId } from "@inception/ui";
import { clone, debounce } from "lodash";
import { MetricDefinition } from "../../../../../../../services/api/explore/types/exploreTypes";
import { PlotAxisProperties, PlotMetricsProperties } from "../../../../models";
import { WidgetCustomizationProps } from "./types";
import { useCommonCustomisationExtraction } from "./common";

const CheckboxStyleProps: any = { placement: "end" };
const verticalAxisPositions = ["left", "right"];

interface MetricCustomizationRowProps {
  onDataChange: (value: MetricCustomizationRowProps) => void;
  id: string;
  label: string;
  customizationProps: PlotMetricsProperties;
}

interface AxisCustomizationRowProps {
  onDataChange: (value: AxisCustomizationRowProps) => void;
  axis: PlotAxisProperties;
  id: string;
}

const axisOptions: IncSelectOption[] = verticalAxisPositions.map(val => ({
  label: val.toString(),
  value: val.toString(),
  data: val
}));

const defaultPlotAxisProperties: PlotAxisProperties[] = [
  {
    position: "left",
    label: "",
    scale: "linear"
  },
  {
    position: "right",
    label: "",
    scale: "linear"
  }
];

const defaultMetricCustomizationProps: PlotMetricsProperties = {
  verticalAxisPosition: "left",
  metricId: "",
  metricName: "",
  showAsTab: false
};

export const TimeSeriesUICustomization: FC<WidgetCustomizationProps> = props => {
  const { properties, widgetResponseDTO, onPropertiesChange, widgetImpl } = props;

  const { timeseries } = properties || {};

  const { axes: pAxes, metrics: pMetricsCustomizationProps } = timeseries || {};

  const [axes, setAxes] = useState(pAxes || defaultPlotAxisProperties);

  const [metricsCustomizationProps, setMetricsCustomizationProps] = useState<PlotMetricsProperties[]>([]);

  const { widgetConfig } = widgetResponseDTO;

  const { metricId, childMetricIds } = useCommonCustomisationExtraction(widgetImpl);
  const allMetrics = useMemo(() => [metricId, ...childMetricIds], [childMetricIds, metricId]);

  const { dataDefinition } = widgetConfig;
  const { metrics: pMetrics = {} } = dataDefinition || {};

  const metrics = useMemo(() => allMetrics.map(metricId => pMetrics[metricId]), [allMetrics, pMetrics]);

  const updateWidgetRenderProp = useCallback(() => {
    onPropertiesChange(properties);
  }, [onPropertiesChange, properties]);

  useEffect(() => {
    // populate the defaults metric props
    const customProps: PlotMetricsProperties[] = pMetricsCustomizationProps ? clone(pMetricsCustomizationProps) : [];
    metrics?.forEach((metric: MetricDefinition) => {
      const existingProp = pMetricsCustomizationProps?.find(m => m.metricId === metric.id);
      if (!existingProp) {
        const prop: PlotMetricsProperties = {
          ...defaultMetricCustomizationProps,
          metricId: metric.id, //TODO the metric id is not same in the timeseries to match
          metricName: metric.name
        };
        customProps.push(prop);
      }
    });
    setMetricsCustomizationProps(customProps);
  }, [pMetricsCustomizationProps, widgetConfig, pAxes, metrics]);
  /**
   * handle on Metric row change
   */
  const onMetricDataChange = useCallback(
    (props: MetricCustomizationRowProps) => {
      const { customizationProps } = props;
      const nMetricssProp = clone(metricsCustomizationProps);
      nMetricssProp?.find(custProp => {
        if (custProp.metricId === customizationProps.metricId) {
          return customizationProps;
        } else {
          return nMetricssProp;
        }
      });
      timeseries.metrics = nMetricssProp;
      updateWidgetRenderProp();
    },
    [metricsCustomizationProps, timeseries, updateWidgetRenderProp]
  );

  /**
   * handle on axis data change
   */
  const onAxisDataChange = useCallback(
    (axisProps: AxisCustomizationRowProps) => {
      const nTimeSeriesProp = clone(axes);
      nTimeSeriesProp?.find((axis: PlotAxisProperties) => {
        if (axis.position === axisProps.axis.position) {
          return axisProps.axis;
        }
        return false;
      });
      timeseries.axes = nTimeSeriesProp;
      setAxes(nTimeSeriesProp);
      updateWidgetRenderProp();
    },
    [axes, timeseries, updateWidgetRenderProp]
  );
  /**
   * metric customization
   */
  const metricCustomization = useMemo(
    () =>
      metrics?.map((metric: MetricDefinition, idx: number) => {
        const customizationProp =
          metricsCustomizationProps?.find(m => m.metricId === metric.id) || defaultMetricCustomizationProps;
        customizationProp.metricId = metric.id;
        return (
          <MetricCustomizationRow
            customizationProps={customizationProp}
            id={generateId()}
            key={idx}
            label={metric.name}
            onDataChange={onMetricDataChange}
          />
        );
      }),

    [metrics, metricsCustomizationProps, onMetricDataChange]
  );

  /**
   *
   */
  return (
    <div className="customization-content">
      <div className="query-editor-wrapper--content">
        <section className="section">
          <div className="ordered-list">{metricCustomization}</div>
        </section>
        <section className="section pt-4">
          {axes &&
            axes.map((axis: PlotAxisProperties, idx: number) => (
              <AxisCustomizationRow
                axis={axis}
                id={generateId()}
                key={idx}
                onDataChange={onAxisDataChange}
              />
            ))}
        </section>
      </div>
    </div>
  );
};

/**
 * Metric customization row
 */

const MetricCustomizationRow: FC<MetricCustomizationRowProps> = (props: MetricCustomizationRowProps) => {
  const { id, label, customizationProps, onDataChange } = props;

  const { verticalAxisPosition: pVerticalAxisPosition, showAsTab: pShowAsTab } = customizationProps;

  const [showAsTab, setShowAsTab] = useState(pShowAsTab || false);
  const [verticalAxisPosition, setVeticalAxisPosition] = useState(pVerticalAxisPosition || false);
  /**
   * set the default axis position value
   */
  const axisPosition = useMemo(() => {
    const vAxisPosition = axisOptions.find(axis => axis.value === verticalAxisPosition);
    return vAxisPosition || axisOptions[0];
  }, [verticalAxisPosition]);
  /**
   * update widget props
   */
  const onWidgetDataChange = useCallback(() => {
    onDataChange(props);
  }, [onDataChange, props]);

  useEffect(() => {
    if (axisPosition) {
      axisOptions.find(axis => axis.value === verticalAxisPosition);
    }
  }, [axisPosition, verticalAxisPosition]);
  /**
   * update the axis value change and also call the update on the parent
   */
  const onAxisValueChange = useCallback(
    value => {
      const pos = value.value;
      customizationProps.verticalAxisPosition = pos;
      setVeticalAxisPosition(pos);
      onWidgetDataChange();
    },
    [customizationProps, onWidgetDataChange]
  );
  /**
   * update the tab value change and also call the update on the parent
   */
  const onTabValueChange = useCallback(
    value => {
      setShowAsTab(value);
      customizationProps.showAsTab = value;
      onWidgetDataChange();
    },
    [customizationProps, onWidgetDataChange]
  );

  return (
    <li
      className="list-item"
      id={`metric-${id}`}
      key={id}
    >
      <div className="d-flex flex-column width-100 ">
        <div className="d-flex justify-content-between width-100 align-items-center">
          <span className="title">{label}</span>
        </div>
        <div className="d-flex justify-content-between width-100 align-items-center">
          <div className="width-50">
            <IncToggle
              checked={showAsTab}
              label="Show Individual Tab"
              onChange={onTabValueChange}
            />
          </div>
          <div className="width-50">
            <IncSelect
              alignment="row"
              autoSort={false}
              className="axis-control"
              isSearchable={false}
              label="Vertical Axis"
              onChange={onAxisValueChange}
              options={axisOptions}
              value={axisPosition}
            ></IncSelect>
          </div>
        </div>
      </div>
    </li>
  );
};

/**
 * Axis customization row
 */

const AxisCustomizationRow: FC<AxisCustomizationRowProps> = (props: AxisCustomizationRowProps) => {
  const { onDataChange, axis, id } = props;
  const { label, scale } = axis;
  const text = AxisCustomizationLabel[axis?.position];
  const textLabel = `${text} Vertical Axis Title (Optional)`;
  const scaleLabel = `Log Scale for ${text} Vertical Axis`;
  /**
   * update the widget data
   */
  const onWidgetDataChange = useCallback(() => {
    onDataChange(props);
  }, [onDataChange, props]);

  /**
   * handle scale change
   */
  const onAxisScaleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const scale = checked ? "logarithmic" : "linear";
      axis.scale = scale;
      onWidgetDataChange();
    },
    [axis, onWidgetDataChange]
  );
  /**
   * update the tab value change and also call the update on the parent
   */

  const updateLabel = useMemo(
    () =>
      debounce((newLabel: string) => {
        axis.label = newLabel;
        onWidgetDataChange();
      }, 1000),
    [axis, onWidgetDataChange]
  );

  const onAxisLabelChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      updateLabel(value);
    },
    [updateLabel]
  );

  return (
    <div
      className="d-flex flex-row py-2 flex-gap-16 width-100 align-items-end form-group"
      id={`widget-${id}`}
      key={id}
    >
      <div className="width-40">
        <IncTextfield
          defaultValue={label}
          label={textLabel}
          onChange={onAxisLabelChange}
          placeholder="E.g. Total bookings"
          wrapperClass="form-group"
        />
      </div>
      <div>
        <IncCheckbox
          checked={scale === "logarithmic"}
          defaultChecked={false}
          label={scaleLabel}
          labelProps={CheckboxStyleProps}
          onChange={onAxisScaleChange}
        />
      </div>
    </div>
  );
};

const AxisCustomizationLabel = {
  left: "Left",
  right: "Right",
  top: "Top",
  bottom: "Bottom"
};
