import { isArray, forEach } from "lodash";
import { EntityOperation } from "../services/api/explore";

export interface QueryParamDTO {
  queryKey: string;
  queryValue: string | string[];
}

const joinChar = ",";

export interface QueryFilterExpression {
  key: string;
  operator: EntityOperation;
  value: string | string[];
}

export const getFilterExprQueryParam = (
  name: string,
  operator: EntityOperation,
  value: string | string[],
  paramPrefix = ""
): QueryParamDTO => {
  const queryKey = paramPrefix ? `${paramPrefix}${name}` : name;
  let queryValue: string | string[] = "";
  if (operator === "eq" && !isArray(value)) {
    queryValue = value;
  } else if (operator === "in") {
    queryValue = value;
  } else {
    const stringVal = isArray(value) ? value.join(joinChar) : value;
    queryValue = `${operator}(${stringVal})`;
  }
  return {
    queryKey,
    queryValue
  };
};

export const resolveFiltersFromQueryParams = (queryParams: Record<string, string | string[]>, prefix = "") => {
  const filteredQueryParams: Record<string, string | string[]> = {};

  forEach(queryParams, (val, key) => {
    if (key.startsWith(prefix)) {
      filteredQueryParams[key] = val;
    }
  });

  const queryFilterExpressions: QueryFilterExpression[] = [];
  forEach(filteredQueryParams, (queryValue, queryKey) => {
    const key = prefix ? queryKey.replace(`${prefix}`, "") : queryKey;

    if (isArray(queryValue)) {
      queryFilterExpressions.push({
        key,
        value: queryValue,
        operator: "in"
      });
    } else {
      const regex = /\(([^)]+)\)/;
      const regexMatch = queryValue.match(regex);
      if (regexMatch) {
        const values = regexMatch[1];
        const operator = queryValue.replace(regexMatch[0], "");
        const queryValues = values.split(joinChar);
        if (
          operator === "lt" ||
          operator === "gt" ||
          operator === "neq" ||
          operator === "ge" ||
          operator === "le" ||
          operator === "range" ||
          operator === "contains" ||
          operator === "regex" ||
          operator === "startsWith" ||
          operator === "exists" ||
          operator === "doesNotExist"
        ) {
          queryFilterExpressions.push({
            key,
            operator: operator as EntityOperation,
            value: queryValues.length > 1 ? queryValues : queryValues[0]
          });
        } else {
          queryFilterExpressions.push({
            key,
            value: queryValue,
            operator: "eq"
          });
        }
      } else {
        queryFilterExpressions.push({
          key,
          value: queryValue,
          operator: "eq"
        });
      }
    }
  });

  return queryFilterExpressions;
};
