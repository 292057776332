import { ContentBlock } from "draft-js";

export function getMatchRichTextToken() {
  return (contentBlock: ContentBlock, callback: any) => {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity();
      return entityKey !== null;
    }, callback);
  };
}
