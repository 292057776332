import { IncCheckbox } from "@inception/ui";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import IncLabelProps from "@inception/ui/src/components/Label/types";
import { uniqBy } from "lodash";
import appConfig from "../../../appConfig";
import { useForceUpdate, useRefState, useTenantConfig, Visualisations } from "../../core";
import { catalogWidgetBuilder } from "../../dashboard/builders";
import { DataTab } from "../../dashboard/widgets/Catalog/maximize-view/components";
import { CatalogWidgetImpl, WidgetQuerySourceConfig } from "../../dashboard/widgets/Catalog/models";
import { BizDataQuery, OverallMappingStatus, UserServiceTuple, WidgetConfigUtils } from "../../services/api/explore";
import { UberOperationalizeTypes } from "../../services/api/operationalise";
import { getDtoFromWidgetConfig, getWidgetConfigFromDto } from "../../utils/ExploreUtils";
import { getWidgetConfigForKPI } from "../../operationalise-v2/uber-operationalize/utils";
import { getSliceSpec } from "../../biz-flow/utils";
import { PredefinedFilters } from "./PredefinedFilters";

export interface KPIQueryEditorProps {
  kpiId?: string;
  kpiQuery: UberOperationalizeTypes.OpBizDataQuery;
  onStateChange: (isValid: boolean, errorsText: string, kpi: UberOperationalizeTypes.OpBizDataQuery) => void;
  onLoadMetrics?: (loading: boolean) => void;

  showIsSpikePositive?: boolean;
  skipKpiMetricOptions?: boolean;
  allowSliceSelection?: boolean;

  showPredefinedFilters?: boolean;
}

export const KPIQueryEditor: FC<KPIQueryEditorProps> = props => {
  const {
    kpiId,
    kpiQuery,
    onStateChange,
    showIsSpikePositive = false,
    skipKpiMetricOptions = false,
    allowSliceSelection = false,
    showPredefinedFilters = false,
    onLoadMetrics,
    children
  } = props;

  const { tenantConfigState } = useTenantConfig();
  const { demoTenant } = tenantConfigState || {};

  // validState will trigger onStateChange so we store update kpiQuery in ref always
  const kpiQueryRef = useRef(kpiQuery);
  const forceUpdate = useForceUpdate();

  const { bizDataQuery } = kpiQuery;
  const { buildingBlockConfig, idProps: bdqIdProps, isSpikePositive } = bizDataQuery;

  const idProps = bdqIdProps || buildingBlockConfig?.bizIdProps;
  const { eventTypeId } = WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(idProps);

  const widgetImpl = useMemo(() => {
    const [widgetConfig, metricId, aggregateTags] = getWidgetConfigForKPI(bizDataQuery);
    const metric = widgetConfig.dataDefinition.metrics[metricId];
    if (metric && kpiId && metric?.kpiId !== kpiId) {
      metric.kpiId = kpiId;
    }

    const generateDemoData = demoTenant
      ? widgetConfig?.mappingStatus?.isIncomplete ||
        widgetConfig?.mappingStatus?.overallStatus === OverallMappingStatus.PENDING
      : false;

    const catalogModel = catalogWidgetBuilder()
      .setDatasource(appConfig.defaultExploreDsName)
      .setBizEntityFieldName("")
      .setUserserviceId(eventTypeId)
      .setEntityType(null)
      .setQueryConfig({
        sourceQueryConfig: {
          queryType: "widgetConfig",
          metricId,
          widgetResponse: {
            querySchema: {
              querySchema: []
            },
            version: 1,
            widgetConfig,
            widgetId: null
          },
          childMetricIds: []
        }
      })
      .setGenerateDemoData(generateDemoData)
      .setRenderMode("viz-only")
      .setProperties({
        visualisation: Visualisations.timeseries,
        aggregateTags
      })
      .disableEdit()
      .disableHeader()
      .disableBorder()
      .disableActions()
      .disableBorder()
      .enableTransparent()
      .buildModel();

    return new CatalogWidgetImpl(catalogModel);
  }, [bizDataQuery, demoTenant, eventTypeId, kpiId]);

  const widgetImplRef = useRefState(widgetImpl);

  const widgetResponseDTO = (widgetImpl?.queryConfig.sourceQueryConfig as WidgetQuerySourceConfig)?.widgetResponse;

  const getKPIFromWidgetImpl = useCallback(
    (widgetImpl: CatalogWidgetImpl, isSpikePositive?: boolean) => {
      const { queryConfig } = widgetImpl;

      const { metricId, widgetResponse } = queryConfig.sourceQueryConfig as WidgetQuerySourceConfig;

      const widgetConfig = getWidgetConfigFromDto(widgetResponse.widgetConfig);
      const { metrics } = widgetConfig.dataDefinition;

      const userServiceEntityIdsSet = new Set<string>();
      Object.values(metrics).forEach(metric => {
        if (metric.sourceType === "userServiceField") {
          const { userServices = [] } = metric.userServiceFieldMetricConfig.userServiceField || {};
          userServices.forEach(usTuple => {
            if (usTuple.userServiceEntityId) {
              userServiceEntityIdsSet.add(usTuple.userServiceEntityId);
            }
          });
        }
      });

      if (userServiceEntityIdsSet.size === 0 && widgetConfig.userServiceEntityId) {
        userServiceEntityIdsSet.add(widgetConfig.userServiceEntityId);
      }

      const userServiceInfo: UserServiceTuple[] = [];
      userServiceEntityIdsSet.forEach(userServiceEntityId => {
        userServiceInfo.push({
          userServiceEntityId
        });
      });

      widgetConfig.userServiceEntityId = userServiceInfo[0]?.userServiceEntityId || "";

      const prevBizDataQuery = kpiQueryRef.current?.bizDataQuery || {};

      const widgetConfigDto = getDtoFromWidgetConfig(widgetConfig);
      const widgetSliceSpec = getSliceSpec(widgetConfigDto, metricId);
      const widgetSlices = widgetSliceSpec?.sliceSet?.slices || [];
      const currentSlices = kpiQueryRef.current?.bizDataQuery?.sliceSpec?.sliceSet?.slices || [];
      const nSlices = uniqBy([...widgetSlices, ...currentSlices], e => e.tagName);

      const metric = widgetConfigDto ? WidgetConfigUtils.getMetricDefinition(widgetConfigDto, metricId) : null;

      const bizDataQuery: BizDataQuery = {
        ...prevBizDataQuery,
        sliceSpec: {
          selectorSpec: null,
          sliceSet: {
            slices: nSlices
          },
          metricId
        },
        idProps: {
          primary: {
            bizEntityTypeId: "",
            eventTypes: {
              userServiceInfo
            }
          },
          secondary: {}
        },
        labels: {
          ...((prevBizDataQuery as BizDataQuery)?.labels || {}),
          name: metric?.name || widgetConfig?.name
        },
        widgetConfig
      };

      if (showIsSpikePositive && isSpikePositive !== null && isSpikePositive !== undefined) {
        bizDataQuery.isSpikePositive = isSpikePositive;
      }

      return {
        bizDataQuery,
        rollingFreq: null,
        rollingFunction: null
      };
    },
    [showIsSpikePositive]
  );

  const kpiDirtyRef = useRef(false);
  const [validState, setValidState] = useState<[boolean, string]>([true, ""]);
  const updateValidState = useCallback((isValid: boolean, errorsText: string) => {
    kpiDirtyRef.current = true;
    setValidState([isValid, errorsText]);
  }, []);

  const onUpdateKPI = useCallback(
    (widgetImpl: CatalogWidgetImpl, isSpikePositive?: boolean) => {
      const updatedKpi = getKPIFromWidgetImpl(widgetImpl, isSpikePositive);
      kpiQueryRef.current = updatedKpi;
      forceUpdate();
    },
    [forceUpdate, getKPIFromWidgetImpl]
  );

  const onWidgetImplChange = useCallback(
    (updaterFn: (widgetImpl: CatalogWidgetImpl) => CatalogWidgetImpl) => {
      const widgetImpl = widgetImplRef.current;
      const nWidgetImpl = updaterFn(widgetImpl);
      onUpdateKPI(nWidgetImpl);
    },
    [onUpdateKPI, widgetImplRef]
  );

  const onChangeIsSpikePositive = useCallback(
    (_: any, checked: boolean) => {
      onUpdateKPI(widgetImplRef.current, checked);
    },
    [onUpdateKPI, widgetImplRef]
  );

  const updatedKpiQuery = kpiQueryRef.current;
  useEffect(() => {
    const [isValid, errorsText] = validState;
    onStateChange(isValid, errorsText, updatedKpiQuery);
  }, [onStateChange, updatedKpiQuery, validState]);

  return (
    <div className="uber-operationalize--add-kpi-editor">
      {children}

      {showPredefinedFilters && <PredefinedFilters bizDataQuery={kpiQuery?.bizDataQuery} />}

      <DataTab
        hideBreakdownSelection
        hideComponentMetricsGroupBy={!allowSliceSelection}
        hideMetricCustomisation
        hideTimeRangeSelection
        hideVizSelection
        hideWidgetFilterSelection
        noCollapseMetrics
        onLoadMetrics={onLoadMetrics}
        onWidgetImplChange={onWidgetImplChange}
        setValidState={updateValidState}
        singleMetricMode
        skipKpiMetricOptions={skipKpiMetricOptions}
        widgetImpl={widgetImpl}
        widgetResponseDTO={widgetResponseDTO}
      />

      {showIsSpikePositive && (
        <IncCheckbox
          checked={isSpikePositive}
          label={"Is Spike Good"}
          labelProps={labelProps}
          onChange={onChangeIsSpikePositive}
        />
      )}
    </div>
  );
};

const labelProps: IncLabelProps = {
  placement: "end"
};
