import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { IncButton, IncTextfield } from "@inception/ui";
import { TimeRange, useToggleState } from "../../../core";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { noOp } from "../../../utils";
import { VerticallyCenteredRow } from "../../../components";

interface Props {
  timeRange: TimeRange;
  onEditToggle: (isEditMode: boolean) => void;
}

export const SeasonalityCalendar: FC<Props> = props => {
  const { timeRange, onEditToggle } = props;

  const [seasonalityName, setSeasonalityName] = useState("");
  const onNameChange = useCallback((e: any) => {
    const nName = e.target.value || "";
    setSeasonalityName(nName);
  }, []);

  const [threshold, setThreshold] = useState(2);
  const onThresholdChange = useCallback((e: any) => {
    let nThreshold = e.target.valueAsNumber;
    nThreshold = isNaN(nThreshold) ? 2 : nThreshold;
    setThreshold(nThreshold);
  }, []);

  const {
    close: closeCustomSeasonality,
    isOpen: isCustomSeasonalityOpen,
    open: openCustomSeasonality
  } = useToggleState();

  useEffect(() => {
    onEditToggle(isCustomSeasonalityOpen);
  }, [isCustomSeasonalityOpen, onEditToggle]);

  const { endDate, startDate } = useMemo(() => {
    const { fromMillis, toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);

    const startDate = new Date(fromMillis);
    const endDate = new Date(toMillis);

    return {
      startDate,
      endDate
    };
  }, [timeRange]);

  const getDayClassName = useCallback((date: Date) => {
    const week = getWeek(date);

    const idx = week % 4;
    const seasonalityStr = seasonalities[idx];

    return `seasonality-calendar--${seasonalityStr}`;
  }, []);

  return (
    <VerticallyCenteredRow className="seasonality-calendar">
      <div>
        <ReactDatePicker
          dayClassName={getDayClassName}
          disabled
          endDate={endDate}
          inline
          onChange={noOp}
          startDate={startDate}
        />

        {!isCustomSeasonalityOpen && (
          <IncButton
            className="marginTp12"
            color="link"
            onClick={openCustomSeasonality}
            size="small"
          >
            Add Custom Seasonality
          </IncButton>
        )}
      </div>
      {isCustomSeasonalityOpen && (
        <div className="seasonality-calendar--custom-seasonality">
          <VerticallyCenteredRow className="marginBt18">Add Custom Seasonality</VerticallyCenteredRow>

          <IncTextfield
            containerClassName="marginBt18 name"
            label="Name"
            onChange={onNameChange}
            value={seasonalityName}
          />

          <VerticallyCenteredRow>
            <div className="inc-label-common marginRt8">Threshold</div>

            <IncTextfield
              containerClassName="threshold"
              onChange={onThresholdChange}
              type="number"
              value={threshold}
            />
            <div className="inc-label-common marginLt4">x</div>

            <div className="inc-label-common marginLt8 marginRt8">of usual</div>
          </VerticallyCenteredRow>

          <VerticallyCenteredRow className="marginTpAuto">
            <IncButton
              color="link"
              onClick={closeCustomSeasonality}
              size="small"
            >
              Apply
            </IncButton>

            <IncButton
              className="marginLt10"
              color="link"
              onClick={closeCustomSeasonality}
              size="small"
            >
              Cancel
            </IncButton>
          </VerticallyCenteredRow>
        </div>
      )}
    </VerticallyCenteredRow>
  );
};

const seasonalities = ["low", "medium", "high", "severe"];

const getWeek = (date: Date) => {
  const onejan = new Date(date.getFullYear(), 0, 1).valueOf();
  const today = new Date(date.getFullYear(), date.getMonth(), date.getDate()).valueOf();

  const dayOfYear = (today - onejan + 86400000) / 86400000;

  return Math.ceil(dayOfYear / 7);
};
