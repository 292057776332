import { ImpactedWidget } from "../../services/api/explore";

export const getMetricIdForImpactWidget = (impactedWidget: ImpactedWidget) => {
  const { bizDataQuery } = impactedWidget;
  const { fieldId, metricId, buildingBlockConfigId } = bizDataQuery?.sliceSpec || {};

  const metricIdKey = fieldId || metricId || buildingBlockConfigId;

  return metricIdKey;
};
