import React, { useCallback, useEffect, useState } from "react";
import { IncSelect, IncSelectOption, IncTextfield } from "@inception/ui";
import { CompareOperator, StaticThresholdDef, ThresholdOpType } from "../../../services/api/operationalise";
import { VerticallyCenteredRow } from "../../../components";
import { BizDataQuery, DemoDataParams } from "../../../services/api/explore";
import CustomThresholdDataQuery from "./CustomThresholdDataQuery";
import { ThresholdContext } from "./types";

type Props = {
  thresholdDef: StaticThresholdDef;
  opBizDataQuery: BizDataQuery;
  onChange: (thresholdDef: StaticThresholdDef) => void;
  context: ThresholdContext;
  comparator: CompareOperator;
  demoDataParams?: DemoDataParams;
};

const CustomThresholdEditorV2: React.FC<Props> = props => {
  const { context, onChange, opBizDataQuery, thresholdDef, demoDataParams } = props;

  const { entityTypeId, eventTypeId } = context;
  const { dataQueryInfo: pDataQueryInfo, thresholdOp } = thresholdDef || {};

  const [dataQueryInfo, setDataQueryInfo] = useState(pDataQueryInfo);
  useEffect(() => {
    setDataQueryInfo(pDataQueryInfo);
  }, [pDataQueryInfo]);

  const thresholdScalar = thresholdOp?.value;

  const isPercentScalarType = thresholdOp?.opType === ThresholdOpType.PERCENTAGE;
  const scalarTypeOpt = isPercentScalarType ? scalarTypeOptions[0] : scalarTypeOptions[1];

  const onThresholdScalarChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let nScalar = e.target.valueAsNumber;
      nScalar = isNaN(nScalar) ? 0 : nScalar;
      const nThresholdDef: StaticThresholdDef = {
        ...thresholdDef,
        thresholdOp: {
          ...thresholdDef.thresholdOp,
          value: nScalar
        }
      };
      onChange(nThresholdDef);
    },
    [onChange, thresholdDef]
  );

  const onScalarTypeOptChange = useCallback(
    (opt: IncSelectOption<ThresholdOpType>) => {
      const nThresholdDef: StaticThresholdDef = {
        ...thresholdDef,
        thresholdOp: {
          ...thresholdDef.thresholdOp,
          opType: opt.data,
          value: opt.data === ThresholdOpType.PERCENTAGE ? 40 : 2
        }
      };
      onChange(nThresholdDef);
    },
    [onChange, thresholdDef]
  );

  const onChangeBizDataQuery = useCallback(
    (bizDataQuery: BizDataQuery) => {
      const nThresholdDef: StaticThresholdDef = {
        ...thresholdDef,
        dataQueryInfo: bizDataQuery
      };
      onChange(nThresholdDef);
    },
    [onChange, thresholdDef]
  );

  return (
    <>
      <IncTextfield
        autoAdjustWidth
        className="marginRt8"
        onChange={onThresholdScalarChange}
        type="number"
        value={thresholdScalar}
      />

      <IncSelect
        autoAdjustWidth
        isSearchable={false}
        onChange={onScalarTypeOptChange}
        options={scalarTypeOptions}
        value={scalarTypeOpt}
      />

      <VerticallyCenteredRow className="marginLt8 marginRt8 inc-label-common">of</VerticallyCenteredRow>

      <CustomThresholdDataQuery
        bizDataQuery={dataQueryInfo}
        context={context}
        demoDataParams={demoDataParams}
        entityTypeId={entityTypeId}
        eventTypeId={eventTypeId}
        onSave={onChangeBizDataQuery}
        opBizDataQuery={opBizDataQuery}
      />
    </>
  );
};

export default CustomThresholdEditorV2;

const SCALAR_TYPE_PERCENT = "percent";
const SCALAR_TYPE_STD_DEVS = "std_devs";

const PERCENT_LABEL = "%";
const STD_DEVS_LABEL = "std dev";

const scalarTypeOptions: Array<IncSelectOption<ThresholdOpType>> = [
  {
    label: PERCENT_LABEL,
    value: SCALAR_TYPE_PERCENT,
    data: ThresholdOpType.PERCENTAGE
  },
  {
    label: STD_DEVS_LABEL,
    value: SCALAR_TYPE_STD_DEVS,
    data: ThresholdOpType.NUM_STD_DEVS
  }
];
