import React, { FC, useEffect, useRef, useState } from "react";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../components";
import { logger, useRefState } from "../../../core";
import { dashboardUtils } from "../../dashboard-grid";
import { CatalogWidget } from "../Catalog/CatalogWidget";
import { CatalogWidgetImpl } from "../Catalog/models";
import { WidgetExtProps } from "../types";
import { QueryCatalogWidgetImpl } from "./models";
import { useFetchDataQueryConfig } from "./useFetchDataQueryConfig";

type Props = WidgetExtProps & {
  widget: QueryCatalogWidgetImpl;
};

export const QueryCatalogWidget: FC<Props> = props => {
  const { companyName, verticalId, subVerticalId, dataQuery, useCaseName, properties } = props.widget;

  const metaRef = useRefState(props.widget.meta);

  const {
    data: dataQueryConfig,
    error: dqcFetchError,
    isError: isDqcFetchError,
    isFetching: isDqcFetching,
    refetch: fetchDataQueryConfig
  } = useFetchDataQueryConfig({
    dataQuery,
    companyName,
    verticalId,
    subVerticalId,
    useCaseName
  });

  const [isInitialising, setInitialising] = useState(true);
  const [catalogWidgetImpl, setCatalogWidgetImpl] = useState<CatalogWidgetImpl>();
  const initialisationErrorRef = useRef<string>("");

  useEffect(() => {
    if (dataQuery) {
      fetchDataQueryConfig();
    } else {
      initialisationErrorRef.current = "Invalid data query";
      setInitialising(false);
    }
  }, [dataQuery, fetchDataQueryConfig]);

  useEffect(() => {
    if (dqcFetchError) {
      initialisationErrorRef.current = "Error fetching widget configuration";
      setInitialising(false);
    }
  }, [dqcFetchError]);

  useEffect(() => {
    if (isDqcFetchError) {
      logger.error("QueryCatalogWidget", "Error fetching widget configuration", dqcFetchError);
    }
  }, [dqcFetchError, isDqcFetchError]);

  useEffect(() => {
    if (!isDqcFetching && !isDqcFetchError) {
      if (dataQueryConfig) {
        const { name, id, query, querySchema, mappingStatus } = dataQueryConfig;

        const widgetImpl = dashboardUtils.getCatalogWidgetForBizDataQuery(
          id,
          name,
          query,
          querySchema,
          mappingStatus,
          metaRef.current
        );
        widgetImpl.properties = {
          ...widgetImpl.properties,
          ...properties,
          aggregateTags: widgetImpl.properties.aggregateTags
        };
        setCatalogWidgetImpl(widgetImpl);
        setInitialising(false);
      } else {
        initialisationErrorRef.current = "Error fetching widget configuration";
        setInitialising(false);
      }
    }
  }, [dataQueryConfig, isDqcFetchError, isDqcFetching, metaRef, properties]);

  const initialisationError = initialisationErrorRef.current;

  return (
    <>
      {isInitialising && <LoadingSpinner className="inc-text-subtext-medium" />}
      {!isInitialising && (
        <>
          {Boolean(initialisationError) && (
            <VerticallyCenteredRow className="status-danger inc-text-subtext-medium width-100 height-100 inc-flex-center">
              {initialisationError}
            </VerticallyCenteredRow>
          )}
          {!initialisationError && (
            <>
              {Boolean(catalogWidgetImpl) && (
                <CatalogWidget
                  {...props}
                  widget={catalogWidgetImpl}
                />
              )}
              {!catalogWidgetImpl && (
                <VerticallyCenteredRow className="status-danger inc-text-subtext-medium width-100 height-100 inc-flex-center">
                  Error fetching widget configuration
                </VerticallyCenteredRow>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
