import React, { FC, useCallback } from "react";
import { logger, useNotifications } from "../../../core";
import { OpCreationConfig, OpMetaDataV2, operationaliseV2ApiService } from "../../../services/api/operationalise";
import { setOpMetaData, setOperationaliseConfig, setPrimaryOpCreationConfig, useOpStore } from "../../context";
import { getOpMetaDataFromOp } from "../../utils";
import { NameAndDescriptionModal } from "./NameAndDescriptionModal";

interface Props {
  open: boolean;
  onClose: () => void;

  saveOrUpdateInProgress: boolean;
  setSaveOrUpdateInProgress: (inProgress: boolean) => void;
  onSaveOrUpdate: (opConfigId: string, opCreationConfig: OpCreationConfig, isSimulation: boolean) => void;
}

export const NameAndDescriptionModalWrapper: FC<Props> = props => {
  const { open, onClose, saveOrUpdateInProgress, setSaveOrUpdateInProgress, onSaveOrUpdate } = props;

  const { notifyError, notifySuccess } = useNotifications();

  const { dispatch, state } = useOpStore();
  const { context, metaData, op10zeId, opCreationConfig } = state;

  const { mode } = context;

  const onApply = useCallback(
    async (opMeta: OpMetaDataV2) => {
      const { name, description, causes, icon, importance, isOpportunity, labelFragment, actionsContext } = opMeta;

      if (mode === "edit" && op10zeId) {
        setSaveOrUpdateInProgress(true);

        const { data, error, message } = await operationaliseV2ApiService.editOpConfigMetaV2(op10zeId, opMeta);

        if (error) {
          notifyError("Error updated operationalize");
          logger.error("Operationalize V3", "Error updated operationalize", {
            data,
            message
          });
        } else {
          const nOpCreationConfig = data.updatedOpCreationConfig;

          if (nOpCreationConfig) {
            dispatch(setOpMetaData(getOpMetaDataFromOp(nOpCreationConfig)));
            notifySuccess("Successfully updated operationalize");
            onSaveOrUpdate(op10zeId, nOpCreationConfig, false);

            if (!nOpCreationConfig.isDraft) {
              dispatch(setPrimaryOpCreationConfig(nOpCreationConfig));
            }
          } else {
            notifyError("Error updating operationalize");
          }
        }
        setSaveOrUpdateInProgress(false);
      } else {
        const nOpCreationConfig: OpCreationConfig = {
          ...opCreationConfig,
          name,
          description,
          opCreationConfigMetadata: {
            insightType: null,
            spikeDropCompareOperator: null,
            ...(opCreationConfig.opCreationConfigMetadata || {}),
            isOpportunity,
            causeMetaData: {
              actionsContext,
              causeGraphContext: null,
              ...(opCreationConfig.opCreationConfigMetadata?.causeMetaData || {}),
              causes
            },
            labelFragments: labelFragment,
            icon,
            importance
          }
        };

        dispatch(setOpMetaData(opMeta));
        dispatch(setOperationaliseConfig(nOpCreationConfig));
      }
      onClose();
    },
    [
      dispatch,
      mode,
      notifyError,
      notifySuccess,
      onClose,
      onSaveOrUpdate,
      op10zeId,
      opCreationConfig,
      setSaveOrUpdateInProgress
    ]
  );

  return (
    <NameAndDescriptionModal
      defMetaData={metaData}
      onClose={onClose}
      onSaveOrUpdate={onApply}
      open={open}
      saveOrUpdateInProgress={saveOrUpdateInProgress}
    />
  );
};
