import { defaultsDeep } from "lodash";
import BaseWidgetImpl from "../../../model-impl/BaseWidgetImpl";
import { AUTO_DS_INTERVAL } from "../../utils";
import { USFieldWidgetModel, USFWQueryConfig, USFieldWidgetProperties, USFWMode, USFWRenderMode } from "./model";

const getDefaults = (): Partial<USFieldWidgetModel> => ({
  title: "New custom widget",
  type: "us-field",
  entityType: null,
  properties: {
    downsample: AUTO_DS_INTERVAL,
    disableCompare: true,
    timeseries: {
      connectNulls: false,
      pointRadius: 2,
      shape: "circle"
    }
  }
});

export class USFieldWidgetImpl extends BaseWidgetImpl implements USFieldWidgetModel {
  userServiceId: string;
  entityType: string;
  bizEntityFieldName: string;
  widgetConfigRefId: string;

  queryConfig: USFWQueryConfig;
  entityLookUp: Record<string, string>;
  eventTypeName: string;

  properties: USFieldWidgetProperties;
  mode: USFWMode;
  renderMode: USFWRenderMode;

  numOpConfigs: number;

  constructor(model: Partial<USFieldWidgetModel>, meta?: Record<string, any>) {
    super(model, meta);
    this.assign(model, meta);
  }

  assign(model: Partial<USFieldWidgetModel>, meta?: Record<string, any>) {
    const usfModel: USFieldWidgetModel = defaultsDeep({ type: "us-field" }, model, getDefaults());
    super.assign(usfModel, meta);

    this.userServiceId = usfModel.userServiceId;
    this.entityType = usfModel.entityType;
    this.bizEntityFieldName = usfModel.bizEntityFieldName;
    this.widgetConfigRefId = usfModel.widgetConfigRefId;

    this.queryConfig = usfModel.queryConfig;
    this.mode = usfModel.mode || "detailed";
    this.renderMode = usfModel.renderMode || "view";
    this.entityLookUp = usfModel.entityLookUp || {};
    this.eventTypeName = usfModel.eventTypeName;
    this.numOpConfigs = usfModel.numOpConfigs || 0;

    let queryConfig: USFWQueryConfig = this.queryConfig || ({} as USFWQueryConfig);

    if (usfModel.usField && !queryConfig?.queryType) {
      queryConfig = {
        ...queryConfig,
        usField: usfModel.usField,
        queryType: "userServiceField"
      };
    }

    // Backwards compatibility
    if (!queryConfig.sourceQueryConfig) {
      queryConfig.sourceQueryConfig = {
        queryType: "userServiceField",
        usField: queryConfig.usField
      };
    }

    this.queryConfig = queryConfig;
  }

  getMetricId() {
    const sourceQueryConfig = this.queryConfig?.sourceQueryConfig;
    if (sourceQueryConfig?.queryType === "widgetConfig") {
      return sourceQueryConfig.metricId;
    }

    return "";
  }

  getSaveModel() {
    const model = super.getSaveModel() as USFieldWidgetModel;

    // Empty the querySchema before saving the model. Since this needs to be fetched every time
    if (
      model?.queryConfig?.sourceQueryConfig &&
      model.queryConfig.sourceQueryConfig.queryType === "widgetConfig" &&
      model.queryConfig.sourceQueryConfig.widgetResponse
    ) {
      model.queryConfig.sourceQueryConfig.widgetResponse.querySchema = {
        querySchema: []
      };
    }

    return model;
  }
}
