import { IncFaIcon, IncToolTip } from "@inception/ui";
import React, { useCallback, useMemo } from "react";
import { cx } from "emotion";
import { CohortState, ResultCohort } from "../../services/api/explore";
import { CohortPicker } from "../cohort";

interface Props {
  canEdit?: boolean;
  cohortState?: CohortState;
  containerClass?: string;
  entityTypeId: string;
  isCohortPinned?: boolean;
  isCohortLocked?: boolean;
  isCohortVisible?: boolean;
  isInternalUser?: boolean;
  label?: string;
  labelClass?: string;
  onCohortChange: (cohort: ResultCohort, isCohortLocked?: boolean, isCohortVisible?: boolean) => void;
  value: string;
}

export const CohortFilterWrapper: React.FC<Props> = (props: Props) => {
  const {
    canEdit = false,
    cohortState,
    containerClass,
    entityTypeId,
    isCohortLocked,
    isCohortPinned,
    isCohortVisible,
    isInternalUser,
    label,
    labelClass,
    onCohortChange,
    value: selectedCohortId
  } = props;

  const [cohortList, setCohortList] = React.useState<ResultCohort[]>([]);
  const onCohortListFetched = useCallback((cohortList: ResultCohort[]) => {
    setCohortList(cohortList);
  }, []);

  const isLocked =
    isCohortLocked !== undefined ? isCohortLocked : isCohortPinned !== undefined ? !isCohortPinned : true;
  const containerClassName = cx(
    "cohort-picker-container",
    !canEdit && isLocked ? (isInternalUser ? "visible-locked-filter" : "hidden-locked-filter") : "",
    containerClass
  );

  const removeCohort = useCallback(
    (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      e.stopPropagation();
      onCohortChange(null, isLocked, false);
    },
    [isLocked, onCohortChange]
  );

  const handleToggleLock = useCallback(
    (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      e.stopPropagation();
      const isCohortLocked = !isLocked;
      const resultCohort = cohortList.find(x => x.id === selectedCohortId);
      onCohortChange(resultCohort, isCohortLocked, true);
    },
    [cohortList, isLocked, onCohortChange, selectedCohortId]
  );

  const lockActions = useMemo(
    () => (
      <>
        {!canEdit && isLocked && (
          <IncToolTip titleText="Locked filters will be read-only for Viewers and hidden for Guest users">
            <IncFaIcon
              className="cohort-lock-btn-containter marginLt6 paddingLt6 inc-cursor-pointer"
              iconName="lock"
              style={{ fontSize: 12 }}
            />
          </IncToolTip>
        )}
        {canEdit && (
          <IncToolTip titleText="Locked filters will be read-only for Viewers and hidden for Guest users">
            <IncFaIcon
              className="cohort-lock-btn-containter marginLt6 inc-cursor-pointer"
              iconName={isLocked ? "lock" : "lock-open"}
              onClick={handleToggleLock}
              style={{ fontSize: 12 }}
            />
          </IncToolTip>
        )}
        {canEdit && (
          <IncFaIcon
            className="cohort-lock-btn-containter close-btn-containter marginLt6 inc-cursor-pointer"
            iconName="xmark"
            onClick={removeCohort}
          />
        )}
      </>
    ),
    [canEdit, handleToggleLock, isLocked, removeCohort]
  );

  return (
    <>
      {isCohortVisible && (
        <CohortPicker
          cohortState={cohortState}
          containerClass={containerClassName}
          customActions={lockActions}
          disabled={!canEdit && isLocked}
          entityTypeId={entityTypeId}
          isCohortPinned={isCohortPinned}
          label={label}
          labelClass={labelClass}
          onCohortChange={onCohortChange}
          onCohortListFetched={onCohortListFetched}
          value={selectedCohortId}
        />
      )}
    </>
  );
};
