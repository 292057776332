import { IncFaIcon } from "@inception/ui";
import React from "react";
import { useToggleState } from "../../core";
import { LowLevelFragment, ConversationStatus } from "../../services/api/chat";
import { VerticallyCenteredRow } from "../flex-components";
import { FragmentRendererV2 } from "./FragmentRendererV2";

interface ReferenceFragmentProps {
  fragment: LowLevelFragment;
  index: number;
  isOpen: boolean;
}

export const ReferenceFragment: React.FC<ReferenceFragmentProps> = (props: ReferenceFragmentProps) => {
  const { fragment, index, isOpen: defIsOpen } = props;
  const { isOpen, toggle } = useToggleState(defIsOpen);
  const { name } = fragment;

  return (
    <div className="reference-fragment inc-flex-row flex-gap-16">
      <div className="reference-icon-container">
        <span className="inc-text-subtext status-info">{index}</span>
      </div>
      <div className="inc-flex-column flex-gap-16 width-100">
        <VerticallyCenteredRow
          className="inc-cursor-pointer"
          onClick={toggle}
        >
          <span className="inc-text-body-medium">{name || "name of the reference"}</span>
          <IncFaIcon
            className="marginLtAuto"
            iconName={isOpen ? "circle-chevron-up" : "circle-chevron-down"}
            variant="regular"
          />
        </VerticallyCenteredRow>
        {isOpen && (
          <FragmentRendererV2
            status={ConversationStatus.COMPLETED}
            subStep={fragment}
          />
        )}
      </div>
    </div>
  );
};
