import { IncSelectOption } from "@inception/ui";
import { isEqual } from "lodash";
import { DataType } from "../../core";
import {
  ConditionWithPickerType,
  FieldPickerOptionData,
  LogicalOperator,
  UserServiceField
} from "../../services/api/explore";
import { FieldPickerUtils } from "../../utils";
import { getOperators } from "../../utils/ExploreUtils";
import {
  ConditionWithPickerTypeExpressionTree,
  ConditionWithPickerTypeNode,
  QueryBuilderRuleGroup,
  QueryBuilderRule,
  SliceContext
} from "./types";

export const getInitialCondition = (
  filter: ConditionWithPickerType | null,
  sliceContext: SliceContext
): ConditionWithPickerType => {
  if (filter) {
    return {
      ...filter,
      tag: filter.tag
        ? filter.tag
        : filter?.field
          ? FieldPickerUtils.getPromSanitizedUSFName(filter?.field?.payload as UserServiceField) || null
          : null
    };
  }

  return {
    field: sliceContext?.payload || null,
    operator: null,
    value: null,
    values: [],
    tag: null
  };
};

export const getConditionTreeForRuleGroup = (query: QueryBuilderRuleGroup): ConditionWithPickerTypeExpressionTree => {
  const { combinator, rules } = query;

  const conditionTree: ConditionWithPickerTypeExpressionTree = {
    filterNodes: [],
    logicalOperator: combinator
  };

  rules.forEach(rule => {
    const conditionNode = getConditionForRule(rule);
    if (conditionNode) {
      conditionTree.filterNodes.push(conditionNode);
    }
  });

  return conditionTree;
};

export const getConditionForRule = (rule: QueryBuilderRuleGroup | QueryBuilderRule): ConditionWithPickerTypeNode => {
  const ruleGroup = rule as QueryBuilderRuleGroup;
  const singleRule = rule as QueryBuilderRule;

  if (ruleGroup.combinator) {
    return {
      expressionTree: getConditionTreeForRuleGroup(ruleGroup)
    };
  }

  if (singleRule) {
    // The builder doesn't support editing specific properties. So FieldPickerOptionData is stored as `field` and values/value is stored as `values`
    const { field: rField, operator: rOperator, value: rValue } = singleRule;

    const isDoesNotExist = rOperator === ConditionsBuilderUtils.nullOperatorOpt.value;

    const field = typeof rField === "string" ? null : (rField as unknown as FieldPickerOptionData);
    const operator = isDoesNotExist ? "=" : rOperator;
    const value = isDoesNotExist ? null : Array.isArray(rValue) ? null : rValue;
    const values = isDoesNotExist ? null : Array.isArray(rValue) ? rValue : null;
    const tag = field ? FieldPickerUtils.getPromSanitizedUSFName(field.payload as UserServiceField) : null;

    return {
      expression: {
        field,
        operator,
        value,
        tag,
        values
      }
    };
  }

  return;
};

export const getRuleGroupForConditionTree = (
  conditionTree: ConditionWithPickerTypeExpressionTree
): QueryBuilderRuleGroup => {
  const { filterNodes = [], logicalOperator = LogicalOperator.AND } = conditionTree || {};

  const ruleGroup: QueryBuilderRuleGroup = {
    combinator: logicalOperator,
    rules: []
  };

  filterNodes.forEach(filterNode => {
    const rule = getRuleForConditionNode(filterNode);
    if (rule) {
      ruleGroup.rules.push(rule);
    }
  });

  return ruleGroup;
};

const getRuleForConditionNode = (filterNode: ConditionWithPickerTypeNode): QueryBuilderRuleGroup | QueryBuilderRule => {
  const { expression, expressionTree } = filterNode;

  if (expression) {
    const { field, operator, value, values } = expression;

    const isDoesNotExist = operator === "=" && value === null;
    const rule: QueryBuilderRule = {
      field: field as any,
      operator,
      value: isDoesNotExist ? EXPR_TREE_DOES_NOT_EXIST_VALUE : value || values
    };
    return rule;
  }

  if (expressionTree) {
    return getRuleGroupForConditionTree(expressionTree);
  }

  return;
};

export class ConditionsBuilderUtils {
  static nullOperatorOpt: IncSelectOption = {
    label: "Does not exist",
    value: "does not exist"
  };

  static isEqualOptionData(op1: FieldPickerOptionData, op2: FieldPickerOptionData) {
    // if any one is null/undefined return false
    if ((!op1 && op2) || (op1 && !op2)) {
      return false;
    }
    // if both are null/undefined return true
    if (!op1 && !op2) {
      return true;
    }
    if (op1.type !== op2.type) {
      return false;
    }
    if (op1.type === "userServiceField") {
      if (!this.isEqualField(op1.payload as UserServiceField, op2.payload as UserServiceField)) {
        return false;
      }
    }
    if (op1.type !== "userServiceField") {
      if (!isEqual(op1, op2)) {
        return false;
      }
    }
    return true;
  }

  static getFilterOperators(filterKey: FieldPickerOptionData) {
    const usf = filterKey?.payload as UserServiceField;
    const keyDataType = (usf?.entityField ? (usf.entityField.propType as DataType) : usf?.dataType) || null;
    const operators = getOperators(keyDataType);
    operators.push(this.nullOperatorOpt);
    return operators;
  }

  static getDefaultOperatorValue(filterKey: FieldPickerOptionData, value: string) {
    const selOptValue = this.getFilterOperators(filterKey).find(x => x.label === "=").value || null;

    if (selOptValue && value === null) {
      return this.nullOperatorOpt.value;
    }

    return selOptValue;
  }

  static getSelectedOperator(
    operator: string,
    value: string,
    filterOptions: IncSelectOption[],
    doesNotExistValue: string = null
  ) {
    const selOpt = operator ? filterOptions.find(op => op.value === operator) : null;

    if (selOpt?.value === "=" && value === doesNotExistValue) {
      return this.nullOperatorOpt;
    }

    return selOpt;
  }

  // Dont do deep equal since entity ids will change with field picker context
  private static isEqualField(thisField: UserServiceField, thatField: UserServiceField) {
    // if any one is null/undefined return false
    if ((!thisField && thatField) || (thisField && !thatField)) {
      return false;
    }
    // if both are null/undefined return true
    if (!thisField && !thatField) {
      return true;
    }
    if (
      thisField.fieldName === thatField.fieldName &&
      thisField.bizEntityFieldName === thatField.bizEntityFieldName &&
      thisField.dataType === thatField.dataType
    ) {
      return true;
    }
    return false;
  }
}

export const EXPR_TREE_DOES_NOT_EXIST_VALUE = "$__null__";
