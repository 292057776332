import React, { useCallback } from "react";
import { cx } from "emotion";
import { IncFaIcon, IncSmartText, IncPill } from "@inception/ui";
import { VerticallyCenteredRow } from "./flex-components";

interface Props {
  label?: string;
  labelActions?: JSX.Element;
  customActions?: JSX.Element;
  value?: JSX.Element | string;

  className?: string;
  placeholderText?: string;

  anchor?: React.LegacyRef<HTMLElement>;
  disabled?: boolean;
  labelPosition?: "left" | "top";
  labelClass?: string;

  hideSelectIconWhenDisabled?: boolean;
  showClearButton?: boolean;

  onClick: () => void;
  onClearClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

export const CustomSelect = (props: Props) => {
  const {
    label,
    labelActions,
    customActions,
    className,
    onClick,
    anchor,
    value,
    showClearButton,
    onClearClick,
    placeholderText,
    disabled = false,
    labelPosition = "top",
    hideSelectIconWhenDisabled = true,
    labelClass = ""
  } = props;

  // const hasLabel = Boolean(label);
  const labelPositionedLeft = labelPosition === "left";
  const labelPositionedTop = labelPosition === "top";

  const containerClassName = cx("custom-select-container", className, {
    "inc-flex-column": labelPositionedTop,
    "inc-flex-row": labelPositionedLeft
  });
  const valueFieldClass = cx("value-container", "inc-text-subtext", {
    pointer: !disabled,
    "varied-borders": labelPositionedLeft
  });
  const labelClassName = cx("inc-text-element-medium", labelClass, {
    "label-borders": labelPositionedLeft
  });

  const onClickWrapper = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onClick();
    },
    [onClick]
  );

  const showSelectIcon = hideSelectIconWhenDisabled ? !disabled : true;

  return (
    <div
      className={containerClassName}
      ref={anchor as React.LegacyRef<HTMLDivElement>}
    >
      {label && (
        <VerticallyCenteredRow className={labelClassName}>
          <IncSmartText text={label} />
          {labelActions}
        </VerticallyCenteredRow>
      )}
      <div
        className={valueFieldClass}
        onClick={!disabled ? onClickWrapper : null}
      >
        <span className="inc-text-subtext">{value ? value : placeholderText || ""}</span>

        <div className="custom-select-container--icons marginLtAuto">
          <div className="inc-flex-row">
            {showClearButton && !disabled && (
              <IncFaIcon
                className="icon"
                iconName="close"
                onClick={onClearClick}
              />
            )}

            {showSelectIcon && (
              <IncFaIcon
                className="icon"
                iconName="caret-down"
              />
            )}
            {customActions}
          </div>
        </div>
      </div>
    </div>
  );
};

export const InfoPopper = (labels: string[]) => (
  <div className="infoPopper">
    {labels.map((x, i) => (
      <IncPill
        className="pill-container"
        key={i}
        label={x}
        labelClass={"pill-text"}
      />
    ))}
  </div>
);
