import React, { FC, useMemo, useCallback, useState, useEffect } from "react";
import { cx } from "emotion";
import { IncSelect, IncSelectOption } from "@inception/ui";
import { FieldPickerContainer } from "../../field-picker";
import {
  DemoDataParams,
  FieldPickerOptionData,
  UserServiceField,
  UserServiceFieldSlice,
  UserServiceFieldSliceSet
} from "../../../services/api/explore";
import { FieldPickerModel, useFieldPicker } from "../../../field-picker";
import { logger } from "../../../core";
import { isImplicitUSFSlice } from "../../../utils/ExploreUtils";
import { FieldPickerUtils } from "../../../utils";
import { BaseProps } from "./types";

interface Props extends BaseProps {
  sliceSets: UserServiceFieldSliceSet[];
  onChange: (sliceSets: UserServiceFieldSliceSet[]) => void;
  fieldPickerModel?: FieldPickerModel;
  onFieldPickerModelChange?: (nModel: FieldPickerModel) => void;

  displayImplicitSlice?: boolean;
  canRemoveImplicitSlice?: boolean;
  sliceOptions?: Array<IncSelectOption<UserServiceFieldSlice>>;

  disabled?: boolean;
  hideLabel?: boolean;
  demoDataParams?: DemoDataParams;
  isLoading?: boolean;

  pickerContainer?: HTMLElement;
  readOnly?: boolean;
}

export const EventGroupBySelector: FC<Props> = props => {
  const {
    fieldPickerContext,
    sliceSets,
    onChange,
    fieldPickerModel: pFieldPickerModel,
    demoDataParams = null,
    onFieldPickerModelChange,
    getLatestTimeRange,
    displayImplicitSlice,
    canRemoveImplicitSlice,
    disabled,
    pickerContainer,
    hideLabel,
    readOnly,
    sliceOptions,
    isLoading
  } = props;

  const [fieldPickerModel, setFieldPickerModel] = useState<FieldPickerModel>(pFieldPickerModel);

  const { data, isFetching: isFetchingFieldsData, isError, isSuccess, error, getFields } = useFieldPicker();

  const fetchFields = useCallback(() => {
    const { from, to } = getLatestTimeRange();
    if (fieldPickerContext) {
      logger.debug("Calling picker with ", "", fieldPickerContext);
      getFields(fieldPickerContext, from.valueOf(), to.valueOf());
    }
  }, [fieldPickerContext, getFields, getLatestTimeRange]);

  useEffect(() => {
    if (!isFetchingFieldsData) {
      if (isSuccess && data) {
        setFieldPickerModel(data);
        onFieldPickerModelChange && onFieldPickerModelChange(data);
      }
      if (isError && error) {
        logger.error("GroupBySelector", "Error while fetching entity fields api");
      }
    }
  }, [data, isError, isSuccess, error, isFetchingFieldsData, onFieldPickerModelChange]);

  const sliceOptionsExist = Boolean(sliceOptions?.length);
  useEffect(() => {
    if (!fieldPickerModel && !sliceOptionsExist) {
      fetchFields();
    }
  }, [fetchFields, fieldPickerModel, sliceOptionsExist]);

  const numSliceSets = sliceSets.length;
  const sliceSetIdx = useMemo(() => (numSliceSets > 0 ? numSliceSets - 1 : 0), [numSliceSets]);

  const selectedGroupByOpts = useMemo(() => {
    const options: FieldPickerOptionData[] = [];
    const sliceSet = sliceSets[sliceSetIdx] || {
      slices: []
    };

    const { slices } = sliceSet;
    slices.forEach(sl => {
      const isImplicitSlice = isImplicitUSFSlice(sl);
      const shouldSkip = isImplicitSlice ? !displayImplicitSlice : false;
      const readOnly = isImplicitSlice && displayImplicitSlice ? !canRemoveImplicitSlice : false;

      if (!shouldSkip) {
        options.push({
          payload: sl.userServiceField,
          type: "userServiceField",
          readOnly
        });
      }
    });

    return options;
  }, [canRemoveImplicitSlice, displayImplicitSlice, sliceSetIdx, sliceSets]);

  const selectedSliceOptions = useMemo(() => {
    const options: Array<IncSelectOption<UserServiceFieldSlice>> = [];
    const sliceSet = sliceSets[sliceSetIdx] || {
      slices: []
    };

    const { slices } = sliceSet;

    slices.forEach(sl => {
      const isImplicitSlice = isImplicitUSFSlice(sl);
      const shouldSkip = isImplicitSlice ? !displayImplicitSlice : false;

      if (!shouldSkip) {
        const label = FieldPickerUtils.getPromSanitizedUSFName(sl.userServiceField);
        options.push({
          label,
          value: label,
          data: sl
        });
      }
    });

    return options;
  }, [displayImplicitSlice, sliceSetIdx, sliceSets]);

  const onGroupByChange = useCallback(
    (nPickerOpts: FieldPickerOptionData[]) => {
      const nSliceSets: UserServiceFieldSliceSet[] = [...sliceSets];

      if (fieldPickerModel) {
        const slices: UserServiceFieldSlice[] = nPickerOpts.map(op => {
          const userServiceField = op.payload as UserServiceField;
          const tagName = FieldPickerUtils.getPromSanitizedUSFName(userServiceField);
          return {
            tagName,
            userServiceField
          };
        });

        nSliceSets[sliceSetIdx] = {
          slices
        };
      }

      onChange(nSliceSets);
    },
    [fieldPickerModel, onChange, sliceSetIdx, sliceSets]
  );

  const onSlicesChange = useCallback(
    (options: Array<IncSelectOption<UserServiceFieldSlice>>) => {
      const nSliceSets: UserServiceFieldSliceSet[] = [...sliceSets];
      const slices = options.map(opt => opt.data);
      nSliceSets[sliceSetIdx] = {
        slices
      };

      onChange(nSliceSets);
    },
    [onChange, sliceSetIdx, sliceSets]
  );

  const className = useMemo(
    () =>
      cx("event-field-group-by-selector", {
        disableClick: sliceOptionsExist ? false : !fieldPickerModel
      }),
    [fieldPickerModel, sliceOptionsExist]
  );

  return (
    <div className={className}>
      {!hideLabel && <div className="label">Group by</div>}
      {!sliceOptionsExist && (
        <FieldPickerContainer
          container={pickerContainer}
          demoDataParams={demoDataParams}
          disabled={disabled}
          fieldPickerContextDto={fieldPickerContext}
          fieldTypes={["userServiceField"]}
          getLatestTimeRange={getLatestTimeRange}
          hideEventIdField
          hideLabel
          isLoading={isLoading}
          isMulti
          label=""
          onChange={onGroupByChange}
          onDataFetch={setFieldPickerModel}
          readOnly={readOnly}
          selectedOptions={selectedGroupByOpts}
        />
      )}
      {sliceOptionsExist && (
        <IncSelect
          isDisabled={disabled || readOnly}
          isLoading={isLoading}
          isMulti
          onChange={onSlicesChange as any}
          options={sliceOptions}
          value={selectedSliceOptions}
        />
      )}
    </div>
  );
};
