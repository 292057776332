import { IncButton, IncEditor, IncModal } from "@inception/ui";
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import marked from "marked";
import { clone, keys } from "lodash";
import { LowLevelFragmentList } from "../../services/api/chat/types/low-level-fragment";
import { generateId, useToggleState } from "../../core";
import { convertMarkdownToHtml, noOp } from "../../utils";
import { ReferenceFragment } from "./ReferenceFragment";

interface MarkdownRendererProps {
  markdown: string;
  isEditable: boolean;
  references: LowLevelFragmentList;
  onMarkdownChange?: (value: string) => void;
}

export const MarkdownRendererWithReferences: FC<MarkdownRendererProps> = props => {
  const { markdown = "", isEditable = false, references, onMarkdownChange = noOp } = props;

  const { open, isOpen, close } = useToggleState();
  const referenceIdMapRef = useRef<Record<string, number>>({});

  const [activeRefId, setActiveRefId] = useState<number>();
  const [mdText, setMdText] = useState("");

  useLayoutEffect(() => {
    const customRenderer = new marked.Renderer();

    // Override the paragraph method
    customRenderer.paragraph = text => {
      let newText = text;
      const regex = /\[bicycle_ai_\d+\]/g;
      const matches = text.match(regex);

      (matches || []).forEach(x => {
        const bicycleId = clone(x);
        const referenceId = bicycleId.replace("[", "").replace("]", "").replace("bicycle_ai_", "");
        const id = generateId();
        referenceIdMapRef.current[id] = parseInt(referenceId, 10);
        newText = newText.replace(x, `<span id=${id} class="reference-container"><span>${referenceId}</span></span>`);
      });

      return `
        <p>${newText}</p>
      `;
    };

    const mdText = convertMarkdownToHtml(markdown || "", { renderer: customRenderer });
    setMdText(mdText);
  }, [markdown]);

  useEffect(() => {
    const referenceIds = keys(referenceIdMapRef.current) || [];

    referenceIds.forEach(x => {
      const spanElement = document.getElementById(x);
      const value = referenceIdMapRef.current?.[x];
      if (spanElement) {
        const onClick = () => {
          setActiveRefId(value);
        };
        spanElement.addEventListener("click", onClick);
        return () => {
          spanElement.removeEventListener("click", onClick);
        };
      }
    });
  }, [mdText]);

  return (
    <>
      {isOpen && (
        <div
          className="inc-flex-column flex-gap-8"
          style={{ background: " #0f1822" }}
        >
          <IncButton
            className="width-fit-content marginLtAuto marginRt16 marginTp16"
            color="link"
            label="Save"
            onClick={close}
          />
          <IncEditor
            className="width-100 height-100"
            highlightActiveLine
            minLines={5}
            mode="text"
            onChange={onMarkdownChange}
            required
            showGutter={false}
            style={{ background: " #0f1822" }}
            value={markdown}
            wrapEnabled={true}
          />
        </div>
      )}
      {!isOpen && (
        <div
          className="marginBt12 marginRt24 inc-markdown-content"
          dangerouslySetInnerHTML={{ __html: mdText }}
          onClick={isEditable ? open : noOp}
        />
      )}
      {activeRefId && (
        <IncModal
          className="references-modal"
          onClose={() => setActiveRefId(null)}
          show={Boolean(activeRefId)}
          size="side-pane"
          titleText="Our Sources"
          withTitleBorder
        >
          <div className="inc-flex-column flex-gap-12">
            {(references?.lowLevelFragment || []).map((fragment, idx) => (
              <ReferenceFragment
                fragment={fragment}
                index={idx + 1}
                isOpen={idx === activeRefId - 1}
                key={idx}
              />
            ))}
          </div>
        </IncModal>
      )}
    </>
  );
};
