import { zipObject, intersection } from "lodash";
import { useEffect, useState } from "react";
import {
  MetricResultDataDTO,
  Slice,
  WidgetConfigDTO,
  WidgetConfigUtils,
  WidgetQuerySchema
} from "../../services/api/explore";
import { entityEnricherRegistry } from "../../utils/EntityEnricher";
import { shouldExcludeTag } from "../../core";
import { ENTITY_TAG } from "../../utils";
import { FilterValuesByTag, TagFilterBySliceSet } from "../types";

export const useSeriesSelection = (
  resultMeta: MetricResultDataDTO[],
  widgetConfig: WidgetConfigDTO,
  querySchemas: WidgetQuerySchema[],
  isPostAggResultMeta = false,
  allowPartialMatch = false
) => {
  const [bizFilter, setBizFilter] = useState<TagFilterBySliceSet>(null);
  const [seriesFilters, setSeriesFilters] = useState<TagFilterBySliceSet[]>([]);
  const skipComparisonSeries = true;

  useEffect(() => {
    if (widgetConfig && querySchemas && resultMeta.length) {
      const seriesFiltersBySliceSet: TagFilterBySliceSet[] = [];
      const { dataDefinition } = widgetConfig;

      const implicitSliceTagSet: Set<string> = new Set();

      resultMeta.forEach(metaEntry => {
        const {
          implicitSliceTag,
          resultSeriesName,
          schema,
          bizTag = "Userservice",
          resultSeriesId,
          dataDefinitionId
        } = metaEntry;

        const schEntry = schema[0] || {};
        const tagNames = Object.keys(schEntry).filter(shouldIncludeTag);

        const metricDef = dataDefinition.metrics[dataDefinitionId];

        if (!metricDef) {
          // possible that in explore some metrics were removed or modified so metric Def doesn't exist.
          return;
        }

        const matchingQuerySchema = WidgetConfigUtils.getMatchingQuerySchema(
          querySchemas,
          metricDef.id,
          tagNames,
          allowPartialMatch
        );

        /**
         * For postAgg it is possible that the entity is aggregated away.
         * In that case we're trying to add the tag and check if match exists in query schema
         */
        const tagNames2 = isPostAggResultMeta ? [ENTITY_TAG, ...tagNames] : tagNames;
        const matchingQuerySchema2 = matchingQuerySchema
          ? matchingQuerySchema
          : WidgetConfigUtils.getMatchingQuerySchema(querySchemas, metricDef.id, tagNames2);

        const fMatchingQuerySchema = matchingQuerySchema || matchingQuerySchema2;
        const matchSliceSet = fMatchingQuerySchema?.sliceSet;

        if (matchSliceSet) {
          const matchSliceTagNames = matchSliceSet.slices.map(s => s.tagName);
          const sliceTagNames = intersection(matchSliceTagNames, tagNames);

          const depTagNames = [...sliceTagNames];
          const depTagMap: Record<string, string> = {};
          depTagNames.forEach((tagName, idx) => {
            const prevTag = depTagNames[idx - 1];
            if (prevTag) {
              depTagMap[tagName] = prevTag;
            }
          });

          const filterValuesMap: Map<string, Set<string>> = new Map();

          const valuesAsArr: string[][] = [];

          schema.forEach(schEntry => {
            const valuesArr: string[] = [];
            sliceTagNames.forEach(tagK => {
              const value = schEntry[tagK];
              const mapEntry = filterValuesMap.get(tagK) || new Set();
              mapEntry.add(value);
              filterValuesMap.set(tagK, mapEntry);
              valuesArr.push(value);
            });
            valuesAsArr.push(valuesArr);
          });

          const filterValues: FilterValuesByTag[] = [];

          filterValuesMap.forEach((v, k) => {
            const values = Array.from(v);
            const displayValues = values.map(v => entityEnricherRegistry.lookupEntityCache(v) || v);
            const displayTag = bizTag && k === implicitSliceTag ? bizTag : k;
            let slice: Slice;
            matchSliceSet.slices.forEach(sl => {
              if (sl.tagName === k) {
                slice = sl;
              }
            });

            const validValues = values.filter(v => v !== undefined);
            const validDisplayValues = displayValues.filter(x => x !== undefined);
            const valuesToDisplayValueMap: Record<string, string> = zipObject(validValues, validDisplayValues);

            const filterValue: FilterValuesByTag = {
              tag: k,
              valToDValRec: valuesToDisplayValueMap,
              displayTag,
              slice,
              dependencyTag: depTagMap[k]
            };
            filterValues.push(filterValue);
          });

          const sFilterBySliceSet: TagFilterBySliceSet = {
            name: resultSeriesName,
            filterValues,
            sliceSet: matchSliceSet,
            resultSeriesId,
            dataDefinitionId,
            matchingSchema: schema
          };

          seriesFiltersBySliceSet.push(sFilterBySliceSet);
        }

        implicitSliceTagSet.add(implicitSliceTag);
      });

      const bizFiltersMap: Map<string, TagFilterBySliceSet> = new Map();
      const implicitTagKeys = Array.from(implicitSliceTagSet);

      implicitTagKeys.forEach(commonTagKey => {
        seriesFiltersBySliceSet.forEach(filBySS => {
          const { matchingSchema } = filBySS;
          filBySS.filterValues.forEach(filter => {
            const { tag, valToDValRec } = filter;

            if (tag === commonTagKey) {
              const bizFilterEntry = bizFiltersMap.get(commonTagKey) || {
                name: filter.displayTag,
                filterValues: [],
                sliceSet: filBySS.sliceSet,
                resultSeriesId: "",
                dataDefinitionId: "",
                matchingSchema: []
              };

              const nFilterValue = bizFilterEntry.filterValues[0] || {
                ...filter
              };

              nFilterValue.valToDValRec = {
                ...(nFilterValue.valToDValRec || {}),
                ...valToDValRec
              };

              bizFilterEntry.matchingSchema = [...bizFilterEntry.matchingSchema, ...matchingSchema];
              bizFilterEntry.filterValues = [nFilterValue];
              bizFiltersMap.set(commonTagKey, bizFilterEntry);
            }
          });
          filBySS.filterValues = filBySS.filterValues.filter(filter => filter.tag !== commonTagKey);
        });
      });

      // Ideally this will never exceed size of 1
      const bizFilters = Array.from(bizFiltersMap.values());

      setBizFilter(bizFilters[0]);
      setSeriesFilters(seriesFiltersBySliceSet);
    }
  }, [resultMeta, skipComparisonSeries, querySchemas, widgetConfig, isPostAggResultMeta, allowPartialMatch]);

  return {
    bizFilter,
    seriesFilters
  };
};

const shouldIncludeTag = (tag: string): boolean => !shouldExcludeTag(tag);
