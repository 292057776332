import React, { FC, useEffect, useState, useCallback, useRef, memo } from "react";
import Highcharts, { Chart, Options, MapView, MapChart, EventCallbackFunction } from "highcharts/highmaps";
import Maps from "highcharts/modules/map";
import HighchartsReact from "highcharts-react-official";
import { GeoSeriesLatLon } from "./types";
import { GeoMapOptionsBuilder } from "./OptionsBuilder";

Maps(Highcharts);
Highcharts.AST.bypassHTMLFiltering = true;

interface Props {
  series: GeoSeriesLatLon[];
  containerElemClass: string;
  autoAdjustZoom?: boolean;
}

export const GeoMap: FC<Props> = props => {
  const { series, containerElemClass, autoAdjustZoom = false } = props;

  const [, setChartRendered] = useState(false);
  const [chartOptions, setChartOptions] = useState<Options>(null);
  const [chartInstance, setChartInstance] = useState<MapChart>(null);
  const [zoomCoordinates, setZoomCoordinates] = useState<[number, number]>();
  const [zoom, setZoom] = useState(1);
  const chartContainerRef = useRef<HTMLDivElement>();

  const getChartDimensions = useCallback(() => {
    const canvasElem: HTMLElement = chartContainerRef.current;
    if (canvasElem) {
      const widgetItemContainer: Element = canvasElem.closest(`.${containerElemClass}`);
      const dimensions = widgetItemContainer?.getBoundingClientRect();
      if (dimensions) {
        const { height } = dimensions;
        const { width } = dimensions;
        return {
          width,
          height
        };
      }
    }
    return {
      width: -1,
      height: -1
    };
  }, [containerElemClass]);

  useEffect(() => {
    if (chartContainerRef.current) {
      setChartRendered(true);
    }
  }, [getChartDimensions, chartContainerRef]);

  // Calculate dimensions on every render
  const { width, height } = getChartDimensions();

  const updateZoom = useCallback<EventCallbackFunction<MapView>>(e => {
    const mapView = e.target as MapView;
    const { zoom } = mapView;
    if (zoom !== undefined) {
      setZoom(zoom);
    }
  }, []);

  useEffect(() => {
    if (height <= 0 || !width) {
      return;
    }

    const builder = new GeoMapOptionsBuilder().setChartFields(width, height).setSeriesData(series);

    const options = builder.build();
    const zoomCoordinates = builder.getZoomCoordinates();

    setChartOptions(options);
    setZoomCoordinates(zoomCoordinates);
  }, [autoAdjustZoom, height, series, width]);

  useEffect(() => {
    if (!chartInstance) {
      return;
    }
    chartInstance.redraw();
  }, [chartInstance]);

  // UseEffect to adjust zoom on first render to focus most of the points
  useEffect(() => {
    if (autoAdjustZoom && chartInstance && zoomCoordinates) {
      const [lat, lon] = zoomCoordinates;
      const mapView = getMapView(chartInstance);
      mapView?.zoomBy(1.25, [lon, lat]);
    }
  }, [autoAdjustZoom, chartInstance, zoomCoordinates]);

  // UseEffect to listen to zoom to adjust the fill pattern
  useEffect(() => {
    let cleanup = () => {};

    const mapView = getMapView(chartInstance);
    if (mapView) {
      Highcharts.addEvent(mapView, "afterSetView", updateZoom);
      cleanup = () => Highcharts.removeEvent(mapView, "afterSetView");
    }

    return cleanup;
  }, [chartInstance, updateZoom]);

  const highchartsCallback = useCallback(chart => setChartInstance(chart), []);

  return (
    <div
      className="inc-geo-map"
      ref={chartContainerRef}
    >
      {/* This is a fill pattern (dots) in the map to match the mocks. */}
      <svg style={{ height: 0 }}>
        <defs>
          <PatternSVG zoom={zoom} />
        </defs>
      </svg>
      {chartOptions && (
        <HighchartsReact
          callback={highchartsCallback}
          constructorType="mapChart"
          highcharts={Highcharts}
          options={chartOptions}
        />
      )}
    </div>
  );
};

const getMapView = (chartInstance: Chart) => (chartInstance as any)?.mapView as MapView;

const PatternSVG = memo(({ zoom }: any) => {
  let zoomCoeff = 1 / (zoom || 1);
  zoomCoeff = Math.min(zoomCoeff, 1);

  const height = zoomCoeff * 3;
  const width = zoomCoeff * 3;

  const cx = zoomCoeff * 1;
  const cy = zoomCoeff * 1;
  const r = zoomCoeff * 1;

  return (
    <pattern
      height={height}
      id="fillPattern"
      patternUnits="userSpaceOnUse"
      width={width}
    >
      <circle
        className="geo-map-fill-pattern"
        cx={cx}
        cy={cy}
        r={r}
      />
    </pattern>
  );
});
