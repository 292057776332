import { isEqual } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TagFilterBySliceSet, TagFilterSelection } from "../../../biz-entity";
import {
  AggregationUIOptions,
  generateId,
  logger,
  useAccessPrivilege,
  useForceUpdate,
  useTimeRange
} from "../../../core";
import { Feature } from "../../../permissions/features";
import {
  exploreApiService,
  MetricResultDataDTO,
  SliceSet,
  UserServiceFieldSlice,
  WidgetConfigDTO,
  WidgetResponseDTO
} from "../../../services/api/explore";
import { ExploreQueryType } from "../../../services/datasources/explore/types";
import { WidgetCustomAction } from "../types";
import { useVariables } from "../useVariables";
import { Subscribe } from "./components";
import { useCustomActions, useGetResultMetaAndDQConfig, useSaveWidgetConfigRef } from "./hooks";
import { USFWModeRendererProps, USFWProps } from "./types";
import { USFieldWidgetUtils } from "./USFieldWidgetUtils";
import { USFWVizWrapper } from "./VisualisationWrapper";

export const USFieldWidget: FC<USFWProps> = props => {
  const {
    widget,
    onAction,
    setCustomActions,
    dbImpl,
    variableLoadingStateMap,
    variableSrvMap,
    eventTypeToFieldsMap,
    headerRef
  } = props;

  const { entityType, userServiceId, queryConfig, properties, renderMode, id: widgetId, title } = widget;

  const forceUpdate = useForceUpdate();

  const isAdhocView = renderMode === "adhoc-view";

  const { timeRange } = useTimeRange();

  const querySchemaFetchCounter = useRef(0);

  const { aggregator: presetAggregator } = properties;
  const prevProperties = useRef({ ...(properties || {}) });
  const propertiesChanged = !isEqual(prevProperties.current, properties);
  prevProperties.current = {
    ...(properties || {})
  };

  const prevWidgetResponseParams = useRef<Record<string, any>>();

  const isWidgetSourceQuery =
    queryConfig.sourceQueryConfig?.queryType === "widgetConfig" &&
    Boolean(queryConfig.sourceQueryConfig?.widgetResponse?.widgetId);

  const { aggOpts: aggOptions, defaultAgg } = useMemo(
    () => USFieldWidgetUtils.getAggregators(queryConfig),
    [queryConfig]
  );

  const aggregatorOpt = useMemo(() => {
    if (presetAggregator) {
      const defAgg = aggOptions.find(({ value }) => value === presetAggregator);
      return defAgg || defaultAgg;
    }
    return defaultAgg;
  }, [aggOptions, defaultAgg, presetAggregator]);

  const aggregator = aggregatorOpt?.value;
  const setAggregator = useCallback(
    (aggOpt: AggregationUIOptions) => {
      widget.properties.aggregator = aggOpt.value;
      forceUpdate();
    },
    [forceUpdate, widget.properties]
  );

  const [vizActions, setVizActions] = useState<WidgetCustomAction[]>([]);

  const [widgetResponseDTO, setWidgetResponseDTO] = useState<WidgetResponseDTO>();
  const [widgetResponseFetchInProgress, setWidgetResponseFetchInProgress] = useState(false);
  const [widgetResponseFetchError, setWidgetResponseFetchError] = useState("");
  const [allowPartialMatch, setAllowPartialMatch] = useState(false);

  const [, setResultMeta] = useState<MetricResultDataDTO[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<TagFilterSelection>(getDefaultFilterSelection());
  const [, setDataQueryConfig] = useState<Record<string, any>>({});
  const [seriesFilters, setSeriesFilters] = useState<TagFilterBySliceSet[]>([]);
  const [additionalSlices, setAdditionalSlices] = useState<UserServiceFieldSlice[]>(properties.additionalSlices || []);

  const prevFilWidgetConfig = useRef<WidgetConfigDTO>();

  const { sourceQueryConfig } = queryConfig;
  const configMetricId = sourceQueryConfig.queryType === "widgetConfig" ? sourceQueryConfig.metricId : null;

  const metricId = useMemo(() => configMetricId || generateId(), [configMetricId]);

  useEffect(() => {
    if (!isWidgetSourceQuery) {
      properties.insights = {
        ...(properties.insights || {}),
        changeMetricHeaders: {}
      } as any;
    }
  }, [isWidgetSourceQuery, properties]);

  useMemo(() => {
    const currentWidgetResponseParams = {
      queryConfig,
      aggregator,
      additionalSlices,
      title,
      entityType,
      userServiceId
    };

    // Doing this since sometimes a shallow clone to update the model is triggering this
    const paramsChanged = !isEqual(prevWidgetResponseParams.current, currentWidgetResponseParams);

    if (paramsChanged) {
      prevWidgetResponseParams.current = currentWidgetResponseParams;
      const nWidgetResponseDTO = USFieldWidgetUtils.getWidgetResponseDTO(
        entityType,
        userServiceId,
        aggregator,
        queryConfig,
        metricId,
        additionalSlices || [],
        title
      );
      setWidgetResponseDTO(nWidgetResponseDTO);
    }
  }, [additionalSlices, aggregator, entityType, metricId, queryConfig, title, userServiceId]);

  const onFiltersExtracted = useCallback((bizFilter: TagFilterBySliceSet, seriesFilters: TagFilterBySliceSet[]) => {
    setSeriesFilters(seriesFilters);
  }, []);

  const { entityFilters, cohortFilters, variablesLoading, eventFilters } = useVariables(
    variableSrvMap,
    widget,
    variableLoadingStateMap,
    widgetResponseDTO,
    eventTypeToFieldsMap,
    true
  );

  const { entityFilters: widgetEntityFilters, eventFilters: widgetEventFilters } = useVariables(
    variableSrvMap,
    widget,
    variableLoadingStateMap,
    widgetResponseDTO,
    eventTypeToFieldsMap
  );

  const allEventFilters = useMemo(
    () => ({
      ...widgetEventFilters,
      ...eventFilters
    }),
    [eventFilters, widgetEventFilters]
  );

  const allEntityFilters = useMemo(
    () => [...(widgetEntityFilters || []), ...(entityFilters || [])],
    [entityFilters, widgetEntityFilters]
  );

  const fetchWidgetResponseDto = useCallback(async () => {
    if (widgetResponseDTO) {
      const { querySchema, widgetId } = widgetResponseDTO;
      const querySchemaArr = querySchema?.querySchema || [];
      const querySchemaExists = Boolean(querySchemaArr.length);

      if (!querySchemaExists && isWidgetSourceQuery) {
        setWidgetResponseFetchInProgress(true);
        try {
          const { data, error, message } = await exploreApiService.getWidgetConfig(null, entityType, widgetId);

          if (error) {
            logger.error("USFieldWidget", "Error fetching widget", message);
            setWidgetResponseFetchError("Error fetching configuration");
          } else {
            setWidgetResponseDTO(data);
          }
        } catch (error) {
          logger.error("USFieldWidget", "Error fetching widget", error);
          setWidgetResponseFetchError("Error fetching configuration");
        } finally {
          setWidgetResponseFetchInProgress(false);
        }
      }
    }
  }, [entityType, isWidgetSourceQuery, widgetResponseDTO]);

  const fetchQuerySchema = useCallback(async () => {
    if (widgetResponseDTO) {
      const { querySchema, widgetConfig } = widgetResponseDTO;
      const querySchemaArr = querySchema?.querySchema || [];
      const querySchemaExists = Boolean(querySchemaArr.length);

      if (!querySchemaExists && !isWidgetSourceQuery) {
        setWidgetResponseFetchInProgress(true);
        try {
          const { data, error, message } = await exploreApiService.getQuerySchemaForDraftWidgetConfig(widgetConfig);

          if (error) {
            logger.error("USFieldWidget", "Error fetching querySchema", message);
          } else {
            setWidgetResponseDTO({
              ...widgetResponseDTO,
              querySchema: {
                querySchema: data
              }
            });
          }
        } catch (error) {
          logger.error("USFieldWidget", "Error fetching querySchema", error);
        } finally {
          setWidgetResponseFetchInProgress(false);
        }
      }
    }
  }, [isWidgetSourceQuery, widgetResponseDTO]);

  useMemo(() => {
    const widgetConfig = widgetResponseDTO?.widgetConfig;
    const updateSelection = Boolean(widgetConfig) && !isEqual(widgetConfig, prevFilWidgetConfig.current);
    if (updateSelection && !isWidgetSourceQuery) {
      setSelectedFilters(getDefaultFilterSelection());
      prevFilWidgetConfig.current = widgetConfig;
      // Reset counter when widget config changes
      querySchemaFetchCounter.current = 0;
    }
  }, [isWidgetSourceQuery, widgetResponseDTO]);

  const querySchemaExists = Boolean(widgetResponseDTO?.querySchema?.querySchema?.length);

  useMemo(() => {
    if (querySchemaFetchCounter.current < 4 && !querySchemaExists) {
      fetchWidgetResponseDto();
      fetchQuerySchema();
      querySchemaFetchCounter.current = querySchemaFetchCounter.current + 1;
    }
  }, [fetchQuerySchema, fetchWidgetResponseDto, querySchemaExists]);

  const { dataQueryConfig, isResultMetaError, resultMeta, resultMetaError, isResultMetaFetching } =
    useGetResultMetaAndDQConfig({
      cohortFilters,
      entityFilters,
      entityType,
      limit: 30,
      timeRange,
      userServiceId,
      widgetResponseDTO,
      eventFilters,
      id: widgetId,
      mode: isWidgetSourceQuery ? ExploreQueryType.saved : ExploreQueryType.adhoc,
      widgetName: title,
      aggregateTags: properties?.aggregateTags,
      sourceType: queryConfig.sourceQueryConfig?.queryType
    });

  useEffect(() => {
    if (isResultMetaError) {
      logger.error("USFieldWidget", "Error fetching result meta data", resultMetaError);
    }
  }, [isResultMetaError, resultMetaError]);

  const { permissions } = useAccessPrivilege();
  const canSubscribe =
    permissions.hasAccess(Feature.operationalize, "addOperationalize") &&
    permissions.hasAccess(Feature.operationalize, "editOperationalize") &&
    permissions.hasAccess(Feature.operationalize, "deleteOperationalize") &&
    !isAdhocView &&
    !widgetResponseFetchError;

  useSaveWidgetConfigRef(widget);

  const [error, setError] = useState("");

  const onError = useCallback(
    (error: string) => {
      onAction({
        type: "onError",
        error
      });
    },
    [onAction]
  );

  useEffect(() => {
    onError(error);
  }, [error, onError]);

  const onAggOrSliceTagsChange = useCallback((aggTags: string[], sliceTags: string[]) => {
    setAllowPartialMatch(!isEqual(aggTags, sliceTags));
  }, []);

  const resetFilters = useCallback(() => {
    setSelectedFilters(getDefaultFilterSelection());
  }, []);

  const { addToDashboardActionModal, customActions } = useCustomActions({
    aggOptions,
    aggregator: aggregatorOpt,
    allowPartialMatch,
    cohortFilters,
    dataQueryConfig,
    dbImpl,
    entityFilters,
    eventFilters,
    isResultMetaFetching,
    onFiltersExtracted,
    propertiesChanged,
    resultMeta,
    selectedFilters,
    setAggregator,
    setSelectedFilters,
    timeRange,
    widget,
    widgetResponseDTO,
    widgetResponseFetchInProgress,
    headerRef
  });

  useEffect(() => {
    setCustomActions([...vizActions, ...customActions]);
  }, [customActions, setCustomActions, vizActions]);

  const querySchema = useMemo(() => widgetResponseDTO?.querySchema?.querySchema || [], [widgetResponseDTO]);

  useMemo(() => {
    if (querySchema && metricId && isWidgetSourceQuery) {
      const disabledSeries: Record<string, SliceSet[]> = {};
      let addDisabledSeries = false;

      querySchema.forEach(({ metricId: qMetricId, sliceSet }) => {
        if (qMetricId !== metricId) {
          disabledSeries[qMetricId] = disabledSeries[qMetricId] || [];
          disabledSeries[qMetricId].push(sliceSet);
          addDisabledSeries = true;
        }
      });

      if (addDisabledSeries) {
        setSelectedFilters(prev => ({
          ...prev,
          disabledSeries
        }));
      }
    }
  }, [isWidgetSourceQuery, metricId, querySchema]);

  const rendererProps: USFWModeRendererProps = useMemo(
    () => ({
      ...props,
      onError: setError,
      onResultMetaChange: setResultMeta,
      onDataQueryConfigChange: setDataQueryConfig,
      onWidgetResponseChange: setWidgetResponseDTO,
      selectedFilters,
      seriesFilters,
      aggregator: aggregatorOpt,
      querySchema,
      widgetResponseDTO,
      onAggOrSliceTagsChange,
      entityFilters,
      cohortFilters,
      eventFilters,
      variablesLoading,
      resetFilters,
      metricId,
      onActionsChange: setVizActions,
      additionalSlices,
      setAdditionalSlices
    }),
    [
      props,
      selectedFilters,
      seriesFilters,
      aggregatorOpt,
      querySchema,
      widgetResponseDTO,
      onAggOrSliceTagsChange,
      entityFilters,
      cohortFilters,
      eventFilters,
      variablesLoading,
      resetFilters,
      metricId,
      additionalSlices
    ]
  );

  return (
    <>
      {!widgetResponseFetchError && (
        <>
          <USFWVizWrapper {...rendererProps} />
          {addToDashboardActionModal}
          {canSubscribe && (
            <Subscribe
              entityFilters={allEntityFilters}
              eventFilters={allEventFilters}
              widget={widget}
              widgetResponseDTO={widgetResponseDTO}
            />
          )}
        </>
      )}
      {Boolean(widgetResponseFetchError) && (
        <div
          className="inc-flex-row inc-flex-center width-100 status-danger"
          style={{ height: "100%" }}
        >
          {widgetResponseFetchError}
        </div>
      )}
    </>
  );
};

const getDefaultFilterSelection = (): TagFilterSelection => ({
  bizFilter: null,
  disabledSeries: {},
  filtersBySliceSet: []
});
