import { IncSelect, IncSelectOption } from "@inception/ui";
import React, { FC, useCallback, useMemo } from "react";
import { SortAndLimit, SortAndLimitSelector, VerticallyCenteredRow } from "../../../../../../components";

type Props = {
  aggTags: string[];
  displayTags: string[];
  selectedTag: string[];
  onSelectTag: (val: string[]) => void;
  selectedTopN: SortAndLimit;
  onSelectTopN: (val: SortAndLimit) => void;
};

const TopNAndSliceSelector: FC<Props> = props => {
  const {
    aggTags,
    displayTags,
    selectedTag,
    onSelectTag: pOnSelectTag,
    onSelectTopN: pOnSelectTopN,
    selectedTopN
  } = props;

  const tagOptions: Array<IncSelectOption<string>> = useMemo(() => {
    const options: Array<IncSelectOption<string>> = aggTags.map((e, i) => ({
      label: displayTags?.[i] || e,
      value: e,
      data: e
    }));
    return options;
  }, [aggTags, displayTags]);

  const selectedTagOptions: Array<IncSelectOption<string>> = useMemo(
    () => tagOptions.filter(e => selectedTag.includes(e.value)),
    [selectedTag, tagOptions]
  );

  const onSelectTags = useCallback(
    (val: ReadonlyArray<IncSelectOption<string>>) => {
      const values = val.map(e => e.value);
      pOnSelectTag(values);
    },
    [pOnSelectTag]
  );

  const onSelectTopN = useCallback(
    (val: SortAndLimit) => {
      pOnSelectTopN(val);
    },
    [pOnSelectTopN]
  );

  return (
    <div className="topn-filter-wrapper">
      <SortAndLimitSelector
        onChange={onSelectTopN}
        sortAndLimit={selectedTopN}
      />
      <VerticallyCenteredRow>
        <IncSelect<IncSelectOption<string>, true>
          isMulti={true}
          onChange={onSelectTags}
          options={tagOptions}
          value={selectedTagOptions}
        />
      </VerticallyCenteredRow>
    </div>
  );
};

export default TopNAndSliceSelector;
