import React, { useMemo, useState, useCallback } from "react";
import { isEqual, uniq } from "lodash";
import { BizFieldPredicate, UserServiceFilterExpression } from "../../../services/api/explore";
import { getUniqBizFieldPredicatesByPropName } from "../../../utils";
import { EntityAndEventFiltersRenderer } from "./EntityAndEventFiltersRenderer";

interface Props {
  addToGlobalEntityFilters?: (entityFilter: BizFieldPredicate) => void;
  addToGlobalEventFilters?: (eventFilter: UserServiceFilterExpression) => void;
  lookupData?: Record<string, string>;
}

export const useLocalToGlobalFilters = (props: Props) => {
  const { addToGlobalEntityFilters, addToGlobalEventFilters, lookupData } = props;

  const [bizFieldPredicates, setBizFieldPredicates] = useState<BizFieldPredicate[]>([]);
  const [usFilterExprs, setUsFilterExprs] = useState<UserServiceFilterExpression[]>([]);

  const onAddLocalEntityFilter = useCallback((predicate: BizFieldPredicate) => {
    setBizFieldPredicates(prev => {
      const next = getUniqBizFieldPredicatesByPropName([...prev, predicate]);
      const hasChanged = !isEqual(next, prev);

      return hasChanged ? next : prev;
    });
  }, []);

  const onAddLocalEventFilter = useCallback((filExpr: UserServiceFilterExpression) => {
    setUsFilterExprs(prev => {
      const next = uniq([...prev, filExpr]);
      const hasChanged = !isEqual(next, prev);

      return hasChanged ? next : prev;
    });
  }, []);

  const onRemoveEntityFilter = useCallback((idx: number) => {
    setBizFieldPredicates(prev => {
      const next = [...prev];
      next.splice(idx, 1);
      return next;
    });
  }, []);

  const onRemoveEventFilter = useCallback((idx: number) => {
    setUsFilterExprs(prev => {
      const next = [...prev];
      next.splice(idx, 1);
      return next;
    });
  }, []);

  const onAddEntityFilter = useCallback(
    (idx: number) => {
      const predicate = bizFieldPredicates[idx];
      if (addToGlobalEntityFilters) {
        addToGlobalEntityFilters(predicate);
        onRemoveEntityFilter(idx);
      }
    },
    [addToGlobalEntityFilters, bizFieldPredicates, onRemoveEntityFilter]
  );

  const onAddEventFilter = useCallback(
    (idx: number) => {
      const usFilterExpr = usFilterExprs[idx];
      if (addToGlobalEventFilters) {
        addToGlobalEventFilters(usFilterExpr);
        onRemoveEventFilter(idx);
      }
    },
    [addToGlobalEventFilters, onRemoveEventFilter, usFilterExprs]
  );

  const filtersJsx = useMemo(
    () => (
      <EntityAndEventFiltersRenderer
        bizFieldPredicates={bizFieldPredicates}
        lookupData={lookupData}
        onAddEntityFilter={addToGlobalEntityFilters ? onAddEntityFilter : null}
        onAddEventFilter={addToGlobalEventFilters ? onAddEventFilter : null}
        onRemoveEntityFilter={onRemoveEntityFilter}
        onRemoveEventFilter={onRemoveEventFilter}
        usFilterExpressions={usFilterExprs}
      />
    ),
    [
      addToGlobalEntityFilters,
      addToGlobalEventFilters,
      bizFieldPredicates,
      lookupData,
      onAddEntityFilter,
      onAddEventFilter,
      onRemoveEntityFilter,
      onRemoveEventFilter,
      usFilterExprs
    ]
  );

  return {
    bizFieldPredicates,
    usFilterExprs,
    filtersJsx,
    addLocalEntityFilter: onAddLocalEntityFilter,
    addLocalEventFilter: onAddLocalEventFilter
  };
};
