import React, { useCallback, useState } from "react";
import { IncButton, IncModalProps, IncModal, IncTextfield } from "@inception/ui";

interface SaveCohortModalProps extends Omit<IncModalProps, "children"> {
  saveCohort: (name: string) => void;
}

export const SaveCohortModal: React.FC<SaveCohortModalProps> = (props: SaveCohortModalProps) => {
  const { saveCohort, ...rest } = props;
  const [name, setName] = useState("");

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value), []);
  const onSaveClick = useCallback(() => saveCohort(name), [name, saveCohort]);
  const isSaveDisabled = name === "";

  return (
    <IncModal {...rest}>
      <div className="inc-flex-column">
        <IncTextfield
          className="marginBt16"
          label="Name"
          onChange={onChange}
          value={name}
        />
        <IncButton
          className="save-cohort-button"
          color="primary"
          disabled={isSaveDisabled}
          onClick={onSaveClick}
        >
          Save
        </IncButton>
      </div>
    </IncModal>
  );
};
