import { IncButton } from "@inception/ui";
import React, { ComponentPropsWithRef } from "react";

interface LinkButtonProps extends React.HTMLAttributes<HTMLElement>, ComponentPropsWithRef<any> {
  icon?: React.ComponentType;
  text: string;
  disabled?: boolean;
}

const LinkButton: React.FC<LinkButtonProps> = ({ icon: Icon, text, disabled, ...buttonProps }) => (
  <IncButton
    {...buttonProps}
    color="link"
    disabled={disabled}
  >
    <div className="link-button">
      {Icon && (
        <div className="link-button-icon">
          <Icon />
        </div>
      )}
      <div className="link-text">{text}</div>
    </div>
  </IncButton>
);
export default LinkButton;
