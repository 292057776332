import { IncCheckboxProps } from "@inception/ui";
import { groupBy } from "lodash";
import React from "react";
import { dataTypeManager } from "../../utils";
import { USFieldInfoWithId, USMetricInfoWithId } from "./types";

export const generateFieldsCheckboxOptions = (
  list: USFieldInfoWithId[],
  selectionMap: Record<string, boolean>,
  searchText: string
): IncCheckboxProps[] => {
  const groups = groupBy(list, ({ isPredefinedField }) => isPredefinedField);

  const predefinedFields = groups["true"]?.sort((a, b) => a.displayName.localeCompare(b.displayName)) || [];
  const otherFields = groups["false"]?.sort((a, b) => a.displayName.localeCompare(b.displayName)) || [];

  const sortedList = [...predefinedFields, ...otherFields];
  const filteredList = sortedList.filter(x => x.displayName.toLowerCase().includes(searchText));

  return filteredList.map(usInfo => {
    const { id, usField, displayName } = usInfo;

    const { userServiceField } = usField;
    const { dataType } = userServiceField;

    const dataTypeIcon = dataTypeManager.getDataTypeDescriptor(dataType).getIcon({ height: 16 });

    const label = (
      <div className="inc-flex-row inc-flex-center-vertical">
        <div
          className="icon-container"
          title={dataType}
        >
          {dataTypeIcon}
        </div>
        {displayName}
      </div>
    );

    return {
      id,
      checked: selectionMap[id] || false,
      label,
      labelProps: {
        placement: "end"
      },
      noImplicitHeight: true,
      name: displayName
    };
  });
};

export const generateMetricsCheckboxOptions = (
  list: USMetricInfoWithId[],
  selectionMap: Record<string, boolean>,
  searchText: string
): IncCheckboxProps[] => {
  const filteredList = list.filter(x => x.metricName.toLowerCase().includes(searchText));

  return filteredList.map(usInfo => {
    const { metricId, metricName } = usInfo;

    const label = <div className="inc-flex-row inc-flex-center-vertical">{metricName}</div>;

    return {
      id: metricId,
      checked: selectionMap[metricId] || false,
      label,
      labelProps: {
        placement: "end"
      },
      noImplicitHeight: true,
      name: metricName
    };
  });
};
