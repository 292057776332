import { IncToggle } from "@inception/ui";
import { capitalize } from "lodash";
import React, { FC } from "react";
import { WidgetToggleProperty } from "../types";

interface Props {
  property: WidgetToggleProperty;
  onUpdate: (newState: WidgetToggleProperty) => void;
}

const TogglePropertyRenderer: FC<Props> = props => {
  const { onUpdate: usrUpdate, property } = props;

  const { name, value, label = capitalize(name), disabled, info = "" } = property;

  const onChange = (newChecked: boolean) => {
    const newProperty: WidgetToggleProperty = {
      ...property,
      value: newChecked
    };
    usrUpdate(newProperty);
  };

  return (
    <IncToggle
      checked={value as boolean}
      disabled={disabled}
      helpText={info}
      label={label}
      onChange={onChange}
    />
  );
};

export default TogglePropertyRenderer;
