import {
  getFormattedDateTime,
  IncButton,
  IncCheckbox,
  IncDateTimeFormat,
  IncFaIcon,
  IncTextfield,
  IncToolTip
} from "@inception/ui";
import { debounce } from "lodash";
import React, { FC, useState, useCallback, useMemo, useEffect } from "react";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../components";
import { useToggleState } from "../../../core";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { setSimulationConfigName, useOpStore } from "../../context";
import { OpTimeFrame, OpTimeFrameEditor } from "./OpTimeFrameEditor";

interface Props {
  allowSimulationOnly?: boolean;
  onSimulate: (startTimeMillis: number, endTimeMillis: number, trLabel: string, executeActions: boolean) => void;
  canMonitorOrSimulate: boolean;
  saveOrUpdateInProgress: boolean;
  error: string;
  onMonitor: (startTimeMillis: number, endTimeMillis: number, trLabel: string, shouldUpdateTimeFrame?: boolean) => void;

  monitorBtnLabel?: string;
}

export const OpSimulation: FC<Props> = props => {
  const {
    canMonitorOrSimulate,
    error,
    onMonitor,
    onSimulate,
    saveOrUpdateInProgress,
    monitorBtnLabel = "Monitor",
    allowSimulationOnly = false
  } = props;

  const { state, dispatch } = useOpStore();
  const { selectedSimulation, readOnly, opCreationConfig } = state;
  const { opAnalysisConfig } = opCreationConfig || {};

  const { endTimeEpochSecs, startTimeEpochSecs } =
    opCreationConfig?.opCreationConfigDef?.schedule?.schedule ||
    opCreationConfig?.outlierConfig?.schedule?.schedule ||
    opCreationConfig?.opAnalysisConfig?.schedule?.schedule ||
    {};

  let numStartTimeEpochSecs = parseInt(String(startTimeEpochSecs), 10);
  numStartTimeEpochSecs = Number.isNaN(numStartTimeEpochSecs) ? 0 : numStartTimeEpochSecs;

  let numEndTimeEpochSecs = parseInt(String(endTimeEpochSecs), 10);
  numEndTimeEpochSecs = Number.isNaN(numEndTimeEpochSecs) ? 0 : numEndTimeEpochSecs;

  const [simulationName, setSimulationName] = useState(selectedSimulation?.name || "");

  const updateSimulationName = useMemo(
    () =>
      debounce((newName: string) => {
        dispatch(setSimulationConfigName(newName));
      }, 1000),
    [dispatch]
  );

  const onSimulationNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const nName = e.target.value || "";
      setSimulationName(nName);
      updateSimulationName(nName);
    },
    [updateSimulationName]
  );

  const defTimeFrame = useMemo(
    () => ({
      from: numStartTimeEpochSecs ? String(numStartTimeEpochSecs * 1000) : null,
      to: numEndTimeEpochSecs ? String(numEndTimeEpochSecs * 1000) : null,
      label: null,
      isCustom: Boolean(numStartTimeEpochSecs || numEndTimeEpochSecs),
      isStartingNow: !(numStartTimeEpochSecs || numEndTimeEpochSecs)
    }),
    [numEndTimeEpochSecs, numStartTimeEpochSecs]
  );

  const [timeFrame, setTimeFrame] = useState<OpTimeFrame>(defTimeFrame);

  useEffect(() => {
    setTimeFrame(defTimeFrame);
  }, [defTimeFrame]);

  const { from: startTimeMillis, to: endTimeMillis } = useMemo(
    () => ({
      from: timeFrame.from ? timeRangeUtils.getMillis(timeFrame.from).millis : 0,
      to: timeFrame.to ? timeRangeUtils.getMillis(timeFrame.to).millis : 0
    }),
    [timeFrame.from, timeFrame.to]
  );

  const { isOpen: shouldSimulate, toggle: toggleSimulate } = useToggleState(false);

  useEffect(() => {
    if (shouldSimulate) {
      setTimeFrame(prev => {
        if (prev) {
          const { to } = prev;
          const { millis } = timeRangeUtils.getMillis(to);
          if (millis <= 0) {
            return {
              ...prev,
              to: String(timeRangeUtils.getMillis("now").millis)
            };
          }
        }
        return prev;
      });
    }
  }, [shouldSimulate]);

  const { isOpen: executeActions, toggle: onToggleExecuteActions } = useToggleState(false);

  const onToggleSimulate = useCallback(() => {
    if (timeFrame.isStartingNow) {
      setTimeFrame({
        from: "now-1M",
        to: "now",
        label: "",
        isCustom: false,
        isStartingNow: false
      });
    }
    toggleSimulate();
  }, [timeFrame.isStartingNow, toggleSimulate]);

  useEffect(() => {
    if (allowSimulationOnly) {
      onToggleSimulate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowSimulationOnly]);

  const monitorOrSimulate = useCallback(() => {
    const { from: startTimeMillis, to: endTimeMillis } = timeFrame.isStartingNow
      ? {
          from: 0,
          to: 0
        }
      : timeRangeUtils.getTimeRangeMillisFromRaw({
          from: timeFrame.from,
          to: timeFrame.to
        });

    const endLabel = timeFrame.to ? `- ${getFormattedDateTime(endTimeMillis, IncDateTimeFormat.minimal)}` : "";

    const trLabel = timeFrame.isCustom
      ? `${getFormattedDateTime(startTimeMillis, IncDateTimeFormat.minimal)} ${endLabel}`
      : timeFrame.label;

    if (shouldSimulate) {
      onSimulate(startTimeMillis, endTimeMillis, trLabel, executeActions);
    } else {
      const endMillis = timeFrame.to ? endTimeMillis : null;
      onMonitor(startTimeMillis, endMillis, trLabel, true);
    }
  }, [
    executeActions,
    onMonitor,
    onSimulate,
    shouldSimulate,
    timeFrame.from,
    timeFrame.isCustom,
    timeFrame.isStartingNow,
    timeFrame.label,
    timeFrame.to
  ]);

  const simulationNameError = shouldSimulate && simulationName.length === 0 ? "Name is required" : "";
  const simulationError = !endTimeMillis
    ? "End time cannot be empty"
    : endTimeMillis < startTimeMillis
      ? "End time cannot be before start time"
      : undefined;
  const liveDataError = endTimeMillis
    ? endTimeMillis < startTimeMillis
      ? "End time cannot be before start time"
      : undefined
    : undefined;
  const trError = shouldSimulate ? simulationError : liveDataError;
  const displayError = useMemo(
    () => [error, trError, simulationNameError].filter(Boolean).join("\n"),
    [error, simulationNameError, trError]
  );

  const disableSimulateOrMonitor = !canMonitorOrSimulate || Boolean(trError) || Boolean(simulationNameError);

  return (
    !readOnly &&
    Boolean(selectedSimulation) && (
      <div className="op-simulation-v3">
        <div className="op-simulation-v3--card">
          {!allowSimulationOnly && (
            <>
              <div className="inc-text-body marginBt4">Monitor with Live Data</div>
              <div className="inc-text-subtext-medium inc-text-inactive">
                Stay informed in real-time by monitoring live data and receiving immediate alerts on critical events.
              </div>
            </>
          )}

          {allowSimulationOnly && (
            <>
              <div className="inc-text-body marginBt4">Simulate with Past Data</div>
              <div className="inc-text-subtext-medium inc-text-inactive">
                Analyze historical trends and simulate alerts using past data to gain insights and make informed
                decisions based on historical patterns.
              </div>
            </>
          )}
          {!opAnalysisConfig && (
            <OpTimeFrameEditor
              allowEmptyEndTime={!shouldSimulate}
              className="marginTp12"
              includeStartingNow={!shouldSimulate}
              onChange={setTimeFrame}
              timeFrame={timeFrame}
            />
          )}

          <div className="inc-flex-row marginBt16 marginTp16">
            {!allowSimulationOnly && (
              <IncCheckbox
                checked={shouldSimulate}
                disabled={allowSimulationOnly}
                onClick={onToggleSimulate}
              />
            )}
            <div className="marginLt8">
              {!allowSimulationOnly && (
                <>
                  <div className="inc-text-body marginBt4">Simulate with Past Data</div>
                  <div className="inc-text-subtext-medium inc-text-inactive">
                    Analyze historical trends and simulate alerts using past data to gain insights and make informed
                    decisions based on historical patterns.
                  </div>
                </>
              )}

              {shouldSimulate && (
                <VerticallyCenteredRow className="width-100 marginTp16">
                  <VerticallyCenteredRow>
                    <div className="inc-label-common marginRt6">Name</div>
                    <IncTextfield
                      className="simulation-name-tf"
                      onChange={onSimulationNameChange}
                      required
                      value={simulationName}
                    />
                  </VerticallyCenteredRow>

                  <div className="vertical-separator" />

                  <IncCheckbox
                    checked={executeActions}
                    label="Execute actions"
                    labelProps={{ placement: "end" }}
                    onChange={onToggleExecuteActions}
                  />
                </VerticallyCenteredRow>
              )}
            </div>
          </div>
        </div>

        <VerticallyCenteredRow className="marginTp16">
          <IncButton
            color="primary"
            disabled={disableSimulateOrMonitor || saveOrUpdateInProgress}
            onClick={monitorOrSimulate}
          >
            {!saveOrUpdateInProgress && (shouldSimulate ? "Start" : monitorBtnLabel)}
            {saveOrUpdateInProgress && <LoadingSpinner titleText="In progress..." />}
          </IncButton>

          {disableSimulateOrMonitor && (
            <IncToolTip
              placement="top"
              titleText={displayError}
              variant="error"
            >
              <VerticallyCenteredRow>
                <IncFaIcon
                  className="status-danger marginLt10"
                  iconName="exclamation-triangle"
                />
              </VerticallyCenteredRow>
            </IncToolTip>
          )}
        </VerticallyCenteredRow>
      </div>
    )
  );
};
