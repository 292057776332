import { IncSelect, IncTextfield, IncToggle } from "@inception/ui";
import { isEqual } from "lodash";
import React, { FC, useEffect, useCallback, memo, CSSProperties, useState } from "react";
import { VerticallyCenteredRow } from "../../../../../../../components";
import { useInputState } from "../../../../../../../core";
import { CatalogWidgetProperties } from "../../../../models";
import { DurationPrecision, WidgetCustomizationProps } from "./types";

type Props = WidgetCustomizationProps & {
  metricTypes: string[];
};

export const DataTypeCustomization: FC<Props> = props => {
  const { properties, onPropertiesChange, metricTypes } = props;

  const { dataTypeCustomisation } = properties || {};
  const { boolean: booleanProperties, duration: durationProperties } = dataTypeCustomisation || {};

  const shouldShowBooleanCustomization = metricTypes.includes("BOOLEAN");
  const shouldShowDurationCustomization =
    metricTypes.includes("DURATION_SECS") ||
    metricTypes.includes("DURATION_MILLIS") ||
    metricTypes.includes("DURATION");

  const onBooleanPropertiesChange = useCallback(
    (booleanProperties: CatalogWidgetProperties["dataTypeCustomisation"]["boolean"]) => {
      onPropertiesChange({
        ...properties,
        dataTypeCustomisation: {
          ...dataTypeCustomisation,
          boolean: booleanProperties
        }
      });
    },
    [dataTypeCustomisation, onPropertiesChange, properties]
  );

  const onDurationPropertiesChange = useCallback(
    (durationProperties: CatalogWidgetProperties["dataTypeCustomisation"]["duration"]) => {
      onPropertiesChange({
        ...properties,
        dataTypeCustomisation: {
          ...dataTypeCustomisation,
          duration: durationProperties
        }
      });
    },
    [dataTypeCustomisation, onPropertiesChange, properties]
  );

  const shouldRender = shouldShowBooleanCustomization || shouldShowDurationCustomization;

  return (
    <>
      {shouldRender && (
        <div
          className="inc-flex-column flex-gap-8 paddingBt16 width-100"
          style={style}
        >
          <VerticallyCenteredRow>Metric Type Specific Overrides</VerticallyCenteredRow>

          {shouldShowBooleanCustomization && (
            <BooleanCustomization
              booleanProperties={booleanProperties}
              onChange={onBooleanPropertiesChange}
            />
          )}

          {shouldShowDurationCustomization && (
            <DurationCustomization
              durationProperties={durationProperties}
              onChange={onDurationPropertiesChange}
            />
          )}
        </div>
      )}
    </>
  );
};

type BProps = {
  booleanProperties: CatalogWidgetProperties["dataTypeCustomisation"]["boolean"];
  onChange: (next: CatalogWidgetProperties["dataTypeCustomisation"]["boolean"]) => void;
};

const BooleanCustomization: FC<BProps> = memo(props => {
  const { booleanProperties, onChange } = props;
  const { truthy: trueProperties, falsy: falseProperties } = booleanProperties || {};

  const defTrueLabel = trueProperties?.label || "";
  const defFalseLabel = falseProperties?.label || "";

  const defTrueColor = trueProperties?.color || "#3BB443";
  const defFalseColor = falseProperties?.color || "#FF523B";

  const showTrueAsPill = trueProperties?.showAsPill || false;
  const showFalseAsPill = falseProperties?.showAsPill || false;

  const showTrueStatus = trueProperties?.showStatusDot || false;
  const showFalseStatus = falseProperties?.showStatusDot || false;

  const {
    inputValue: trueLabel,
    onInputChange: onTrueLabelChange,
    setInputValue: setTrueLabel
  } = useInputState(defTrueLabel);

  const {
    inputValue: falseLabel,
    onInputChange: onFalseLabelChange,
    setInputValue: setFalseLabel
  } = useInputState(defFalseLabel);

  useEffect(() => {
    setTrueLabel(defTrueLabel);
    setFalseLabel(defFalseLabel);
  }, [defFalseLabel, defTrueLabel, setFalseLabel, setTrueLabel]);

  const onApplyChanges = useCallback(
    (
      nTrueLabel: string,
      nFalseLabel: string,
      nShowTrueAsPill: boolean,
      nShowFalseAsPill: boolean,
      nShowTrueStatus: boolean,
      nShowFalseStatus: boolean
    ) => {
      const next: CatalogWidgetProperties["dataTypeCustomisation"]["boolean"] = {
        truthy: {
          label: nTrueLabel === null ? trueLabel : nTrueLabel,
          color: defTrueColor,
          showAsPill: nShowTrueAsPill === null ? showTrueAsPill : nShowTrueAsPill,
          showStatusDot: nShowTrueStatus === null ? showTrueStatus : nShowTrueStatus
        },
        falsy: {
          label: nFalseLabel === null ? falseLabel : nFalseLabel,
          color: defFalseColor,
          showAsPill: nShowFalseAsPill === null ? showFalseAsPill : nShowFalseAsPill,
          showStatusDot: nShowFalseStatus === null ? showFalseStatus : nShowFalseStatus
        }
      };

      if (!isEqual(next, booleanProperties)) {
        onChange(next);
      }
    },
    [
      booleanProperties,
      defFalseColor,
      defTrueColor,
      falseLabel,
      onChange,
      showFalseAsPill,
      showFalseStatus,
      showTrueAsPill,
      showTrueStatus,
      trueLabel
    ]
  );

  const onTrueLabelBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const { value } = e.target;
      onApplyChanges(value, null, null, null, null, null);
    },
    [onApplyChanges]
  );

  const onFalseLabelBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const { value } = e.target;
      onApplyChanges(null, value, null, null, null, null);
    },
    [onApplyChanges]
  );

  const onTruePillToggleChange = useCallback(
    (showTrueAsPill: boolean) => {
      onApplyChanges(null, null, showTrueAsPill, null, showTrueAsPill, null);
    },
    [onApplyChanges]
  );

  const onFalsePillToggleChange = useCallback(
    (showFalseAsPill: boolean) => {
      onApplyChanges(null, null, null, showFalseAsPill, null, showFalseAsPill);
    },
    [onApplyChanges]
  );

  const onTrueStatusDotToggleChange = useCallback(
    (showTrueStatus: boolean) => {
      onApplyChanges(null, null, null, null, showTrueStatus, null);
    },
    [onApplyChanges]
  );

  const onFalseStatusDotToggleChange = useCallback(
    (showFalseStatus: boolean) => {
      onApplyChanges(null, null, null, null, null, showFalseStatus);
    },
    [onApplyChanges]
  );

  return (
    <>
      <VerticallyCenteredRow className="flex-gap-10">
        <IncTextfield
          helpText="The label to show for true values. Default: 1"
          label="Show true value as"
          onBlur={onTrueLabelBlur}
          onChange={onTrueLabelChange}
          value={trueLabel}
        />

        <IncToggle
          checked={showTrueAsPill}
          className="marginTp24"
          label="Show as pill"
          onChange={onTruePillToggleChange}
        />

        {showTrueAsPill && (
          <IncToggle
            checked={showTrueStatus}
            className="marginTp24"
            label="Show status dot"
            onChange={onTrueStatusDotToggleChange}
          />
        )}
      </VerticallyCenteredRow>

      <VerticallyCenteredRow className="flex-gap-10">
        <IncTextfield
          helpText="The label to show for false values. Default: 0"
          label="Show false value as"
          onBlur={onFalseLabelBlur}
          onChange={onFalseLabelChange}
          value={falseLabel}
        />

        <IncToggle
          checked={showFalseAsPill}
          className="marginTp24"
          label="Show as pill"
          onChange={onFalsePillToggleChange}
        />

        {showFalseAsPill && (
          <IncToggle
            checked={showFalseStatus}
            className="marginTp24"
            label="Show status dot"
            onChange={onFalseStatusDotToggleChange}
          />
        )}
      </VerticallyCenteredRow>
    </>
  );
});

type DProps = {
  durationProperties: CatalogWidgetProperties["dataTypeCustomisation"]["duration"];
  onChange: (next: CatalogWidgetProperties["dataTypeCustomisation"]["duration"]) => void;
};

type PrecisionOption = {
  label: string;
  value: DurationPrecision;
};

const precisionOptions: PrecisionOption[] = [
  {
    label: "DAYS",
    value: "DAYS"
  },
  {
    label: "HOURS",
    value: "HOURS"
  },
  {
    label: "MINUTES",
    value: "MINUTES"
  },
  {
    label: "SECONDS",
    value: "SECONDS"
  }
];

const DurationCustomization: FC<DProps> = memo(props => {
  const { durationProperties, onChange } = props;
  const { maxTerms: defMaxTerms = 2, precision: defPrecison = "MINUTES" } = durationProperties || {};

  const [maxTermsInput, setMaxTermsInput] = useState<number>(defMaxTerms);
  const [precisionInput, setPrecisionInput] = useState<PrecisionOption>(null);

  const MaxTermErrorMsg = maxTermsInput < 1 ? "Max Term cant be less than 1" : "";

  useEffect(() => {
    const selectedPrecison = precisionOptions.find(option => option.value === defPrecison);
    setPrecisionInput(selectedPrecison);
  }, [defPrecison]);

  const onApplyChanges = useCallback(
    (nmaxTerms: number, nprecision: DurationPrecision) => {
      const next: CatalogWidgetProperties["dataTypeCustomisation"]["duration"] = {
        maxTerms: nmaxTerms !== null ? nmaxTerms : maxTermsInput,
        precision: nprecision !== null ? nprecision : precisionInput?.value
      };

      if (!isEqual(next, durationProperties)) {
        onChange(next);
      }
    },
    [durationProperties, maxTermsInput, onChange, precisionInput]
  );

  const onMaxTermsInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const maxTermValue = e.target.value;
    setMaxTermsInput(Number(maxTermValue));
  }, []);

  const onMaxTermsInputBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const maxTermValue = e.target.value;
      if (!MaxTermErrorMsg) {
        onApplyChanges(Number(maxTermValue), null);
      }
    },
    [MaxTermErrorMsg, onApplyChanges]
  );

  const onPrecisonSelectChange = useCallback(
    (option: PrecisionOption) => {
      setPrecisionInput(option);
      onApplyChanges(null, option.value);
    },
    [onApplyChanges]
  );

  return (
    <>
      <VerticallyCenteredRow className="flex-gap-10">
        <IncTextfield
          errorText={MaxTermErrorMsg}
          hasError={!!MaxTermErrorMsg}
          helpText="Maximum number of terms to be shown while showing durations. This might cause loss in precision for high values."
          label="Maximum terms for duration"
          onBlur={onMaxTermsInputBlur}
          onChange={onMaxTermsInputChange}
          type="number"
          value={maxTermsInput}
        />
        <IncSelect
          helpText="Least time unit to display while formatting"
          label="Duration precision"
          onChange={onPrecisonSelectChange}
          options={precisionOptions}
          value={precisionInput}
        />
      </VerticallyCenteredRow>
    </>
  );
});

const style: CSSProperties = {
  borderBottom: "1px solid #313B45"
};
