import React, { FC, useMemo, useCallback } from "react";
import {
  IncSelectOption,
  IncSlimSelect,
  IncToggle,
  IncCollapsible,
  IncSelectorDuration,
  IncDurationSelector
} from "@inception/ui";
import { getDisplayDataTypes } from "../../data-type/utils";
import { TimeObj, TimeObjUnit } from "../../../services/api/explore";
import { getDurationFromTimeObj, getTimeObjFromDuration } from "../../../utils/DurationUtils";
import { VerticallyCenteredRow } from "../../flex-components";

export interface AdvancedPropertiesEditorProps {
  subType?: string;
  onSubTypeChange?: (subType: string) => void;
  enableSubTypeSelection?: boolean;

  isSpikePositive?: boolean;
  onIsSpikePositiveChange?: (isSpikePositive: boolean) => void;
  enableSpikeConfiguration?: boolean;

  lookBack?: TimeObj;
  onLookBackChange?: (lookBack: TimeObj) => void;
  enableLookBackConfiguration?: boolean;

  propertiesReadOnly?: boolean;
  queryReadOnly?: boolean;
  menuPlacement?: "top" | "bottom" | "auto";
}

export const EventFieldPropertiesEditor: FC<AdvancedPropertiesEditorProps> = props => {
  const {
    subType,
    enableSubTypeSelection = false,
    onSubTypeChange,
    isSpikePositive,
    enableSpikeConfiguration = false,
    onIsSpikePositiveChange,
    lookBack,
    enableLookBackConfiguration = false,
    onLookBackChange,
    queryReadOnly = false,
    propertiesReadOnly = false,
    menuPlacement
  } = props;

  const subTypeOptions = useMemo(() => {
    if (enableSubTypeSelection) {
      const options = getDisplayDataTypes().map(opt => ({
        label: opt as string,
        value: opt as string
      }));

      return options;
    }
    return;
  }, [enableSubTypeSelection]);

  const selectedSubTypeOpt = useMemo(
    () => subTypeOptions.find(opt => opt.value === subType),
    [subType, subTypeOptions]
  );

  const onSubTypeOptChange = useCallback(
    (opt: IncSelectOption) => {
      onSubTypeChange(opt.value);
    },
    [onSubTypeChange]
  );

  const duration = useMemo<IncSelectorDuration>(() => (lookBack ? getDurationFromTimeObj(lookBack) : null), [lookBack]);

  const onDurationChange = useCallback(
    (nDuration: IncSelectorDuration) => {
      if (onLookBackChange) {
        const { duration, durationType } = nDuration;
        const numDuration = parseInt(duration === "" ? "1" : duration.toString(), 10);
        const nTimeObj = getTimeObjFromDuration(numDuration, durationType);
        onLookBackChange(nTimeObj);
      }
    },
    [onLookBackChange]
  );

  const onToggleLookBackEdit = useCallback(
    (supportCustomLookBack: boolean) => {
      if (onLookBackChange) {
        const lookBack = supportCustomLookBack ? getDefaultLookBack() : null;
        onLookBackChange(lookBack);
      }
    },
    [onLookBackChange]
  );

  const isLookBackEditable = Boolean(lookBack);

  return (
    <IncCollapsible
      className="p-a-0"
      collapsible
      content={
        <div className="event-field-advanced-section">
          {enableLookBackConfiguration && (
            <VerticallyCenteredRow className="look-back-editor flex-gap-12">
              <IncToggle
                checked={isLookBackEditable}
                disabled={queryReadOnly}
                label="Use Custom Time Range"
                onChange={onToggleLookBackEdit}
              />

              {isLookBackEditable && (
                <>
                  <VerticallyCenteredRow className="inc-text-subtext-medium">Last</VerticallyCenteredRow>

                  <IncDurationSelector
                    duration={duration}
                    hideLabel
                    isDisabled={queryReadOnly}
                    onChange={onDurationChange}
                  />
                </>
              )}
            </VerticallyCenteredRow>
          )}

          {enableSpikeConfiguration && (
            <IncToggle
              checked={isSpikePositive}
              disabled={propertiesReadOnly}
              label="Spike is Good"
              onChange={onIsSpikePositiveChange}
              variant="success"
            />
          )}

          {enableSubTypeSelection && (
            <IncSlimSelect
              alignment="row"
              autoAdjustWidth
              isDisabled={!onSubTypeOptChange || propertiesReadOnly}
              label="Metric Type"
              menuPlacement={menuPlacement}
              onChange={onSubTypeOptChange}
              options={subTypeOptions}
              value={selectedSubTypeOpt}
            />
          )}
        </div>
      }
      header="Advanced"
      headerClassName="inc-text-subtext-medium status-info"
      iconPosition="right"
    />
  );
};

const getDefaultLookBack = (): TimeObj => ({
  unit: TimeObjUnit.minutes,
  value: 1
});
