import { IncTextfield } from "@inception/ui";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { TypeConversionConfig } from "../transform-config/TransformConfig";

interface TypeConversionCompProps {
  config: TypeConversionConfig;
  onChange: (config: TypeConversionConfig) => void;
}

export const TypeConversionComp: React.FC<TypeConversionCompProps> = props => {
  const { config, onChange } = props;

  const [fn, setFn] = useState<string>("");
  const [formatList, setFormatList] = useState<string>("");

  const toType = config?.toType;

  useEffect(() => {
    if (!isEqual(config?.function, fn)) {
      setFn(config.function ?? "");
    }
  }, [config, fn]);

  useEffect(() => {
    const formatStr = (config?.format || []).join(",");
    if (!isEqual(formatStr, formatList)) {
      setFormatList(formatStr);
    }
  }, [config, formatList]);

  const updateConfig = useCallback(
    (fn: string, formats: string) => {
      const newConfig: TypeConversionConfig = {
        toType: toType
      };

      if (fn) {
        newConfig.function = fn;
      }
      if (formats) {
        newConfig.format = formats.split(/\s*,\s*/);
      }
      onChange(newConfig);
    },
    [toType, onChange]
  );

  const onFnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setFn(value);
      updateConfig(value, formatList);
    },
    [formatList, updateConfig]
  );

  const onFormatChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setFormatList(value);
      updateConfig(fn, value);
    },
    [fn, updateConfig]
  );

  return (
    <>
      <IncTextfield
        className="marginBt8"
        label={"Function"}
        onChange={onFnChange}
        value={fn}
      />

      <IncTextfield
        helpText={"Comma separated list of formats"}
        label={"Formats"}
        onChange={onFormatChange}
        placeholder="yyyy-MM-dd"
        value={formatList}
      />
    </>
  );
};
