import { isEmpty, has, omit, isEqual, isEqualWith } from "lodash";
import { getInceptionTheme, generateId } from "@inception/ui";
import { Layout } from "react-grid-layout";
import { clipboardUtils } from "../../core/hooks/useClipboard";
import BaseWidgetImpl from "../model-impl/BaseWidgetImpl";
import BaseWidgetModel, { WidgetMeta } from "../models/BaseWidgetModel";
import { DashboardModelDto } from "../models";
import DashboardModel, { PartialWidgetLayout } from "../models/DashboardModel";
import TextWidgetImpl from "../widgets/Text/impl";
import { getTimeRange, getCompareTimeRange } from "../../core/hooks/time-range/TimeRangeGetter";
import { USFieldWidgetImpl, USFieldWidgetModel } from "../widgets/USField/models";
import { FilterWidgetImpl } from "../widgets/FilterWidget/models";
import { widgetRegistry } from "../widgets/WidgetRegistry";
import { Visualisations } from "../../core";
import Op10zeWidgetImpl from "../widgets/Operationalize/models/impl";
import { MetricTableWidgetImpl } from "../widgets/MetricTable/models/impl";
import FunnelWidgetImpl from "../widgets/Funnel/models/impl";
import { CatalogWidgetImpl } from "../widgets/Catalog/models/impl";
import {
  BizDataQuery,
  QueryMappingStatus,
  WidgetConfigUtils,
  WidgetQuerySchema,
  WidgetQuerySchemaResponse,
  WidgetResponseDTO
} from "../../services/api/explore";
import { getDtoFromWidgetConfig } from "../../utils/ExploreUtils";
import { catalogWidgetBuilder } from "../builders";
import appConfig from "../../../appConfig";
import { QueryCatalogWidgetImpl } from "../widgets/QueryCatalogWidget/models";

import BizOpQueryWidgetImpl from "../widgets/BizOpQueryWidget/models/impl";
import { compareObjectsWithSameProperties } from "../../utils";

class DashboardUtils {
  readonly layoutcolumns: number = 24;

  readonly GLOBAL_EVENT_EXPRESSION_FILTER_NAME: string = "__GLOBAL_EVENT_EXPRESSION_FILTER__";

  createGuid(): string {
    return generateId();
  }

  isWidgetCopyData(str: string): boolean {
    let valid = false;
    try {
      const obj = JSON.parse(str);
      valid = !isEmpty(obj) && has(obj, "id") && has(obj, "datasource") && has(obj, "queries");
    } catch (e) {
      // do-nothing
    }

    return valid;
  }

  handleWidgetCopy(widget: BaseWidgetImpl): void {
    const widgetCopyData = widget.getSaveModel();
    const widgetClipboardData = JSON.stringify(widgetCopyData);
    clipboardUtils.addToClipboard(widgetClipboardData);
  }

  async handleWidgetPaste(dashboard: DashboardModelDto): Promise<void> {
    const selectedWidget = await clipboardUtils.readFromClipboard();
    return this.handleWidgetPasteInternal(dashboard, selectedWidget);
  }

  private handleWidgetPasteInternal(dashboard: DashboardModelDto, copyWidgetStr: string): void {
    if (!dashboard || !dashboard.meta.edit) {
      throw Error("Cannot add widget. Dashboard is not editable.");
    }

    // check if the clipboard item is actually widget copy related.
    if (!copyWidgetStr || copyWidgetStr.length === 0 || !dashboardUtils.isWidgetCopyData(copyWidgetStr)) {
      console.log("Pasted text is not a widget");
      throw Error("Invalid widget configuration. Check clipboard contents.");
    }

    const widgetCopyData: BaseWidgetModel = JSON.parse(copyWidgetStr);
    const layout = dashboard.getLayoutById(widgetCopyData.id);
    widgetCopyData.id = this.createGuid();
    dashboard.addWidget(widgetCopyData, layout);
  }

  getDefaultWidgetImplByType(type: string): BaseWidgetImpl {
    let state;
    switch (type) {
      case "plain-text":
        state = new TextWidgetImpl({ type });
        break;

      case "us-field": {
        state = new USFieldWidgetImpl({ type });
        break;
      }

      case "filter": {
        state = new FilterWidgetImpl({ type });
        break;
      }

      case "operationalize": {
        state = new Op10zeWidgetImpl({ type });
        break;
      }

      case "metric-table": {
        state = new MetricTableWidgetImpl({ type });
        break;
      }

      case "funnel":
        state = new FunnelWidgetImpl({ type });
        break;

      case "catalog":
        state = new CatalogWidgetImpl({ type });
        break;

      case "query-catalog":
        state = new QueryCatalogWidgetImpl({ type });
        break;

      case "biz-op-query":
        state = new BizOpQueryWidgetImpl({ type });
        break;

      default:
        state = new BaseWidgetImpl({ type });
    }

    return state;
  }

  getBaseWidgetModel<T extends BaseWidgetModel>(model: T, type: string) {
    return {
      datasource: model.datasource,
      id: model.id,
      queries: model.queries,
      title: model.title,
      type,
      background: model.background,
      comparisonModel: model.comparisonModel,
      meta: model.meta,
      properties: {
        background: model.properties?.background || getInceptionTheme().inceptionColors.primary1,
        description: model.properties?.description || ""
      }
    };
  }

  getNewDashboard = (): DashboardModel => {
    // Get current chosen time range and compare time range and set them to the new dashboard
    const { from, raw, to, timeZone } = getTimeRange();
    const { from: cFrom, raw: cRaw, to: cTo } = getCompareTimeRange();

    return {
      id: generateId(),
      name: "New dashboard",
      variables: [],
      widgets: [],
      layout: [],
      type: "default",
      timeRange: {
        from: from.valueOf(),
        to: to.valueOf(),
        raw: {
          ...raw
        },
        timeZone
      },
      compareTimeRange: {
        from: cFrom.valueOf(),
        to: cTo.valueOf(),
        raw: {
          ...cRaw
        }
      },
      autoRefreshInterval: 0,
      version: 1,
      meta: {
        edit: true,
        compare: false,
        resizable: true,
        syncTooltip: true,
        useSavedTimeRange: false
      },
      presetTimeInfo: {
        compareTimeRange: null,
        shouldOverrideCompareTimeRange: false,
        shouldOverrideTimeRange: false,
        timeRange: null
      }
    };
  };

  getCloneDashboard(dashboard: DashboardModel, name: string): DashboardModel {
    dashboard.id = generateId();
    dashboard.name = name ? name : `Clone - ${dashboard.name}`;
    return dashboard;
  }

  getNewCohortDashboard(
    entityTypeId: string,
    entityTypeName: string,
    cohortId: string,
    cohortName: string
  ): DashboardModel {
    const newDash = this.getNewDashboard();
    newDash.type = "cohort";
    newDash.entityTypeId = entityTypeId;
    newDash.entityTypeName = entityTypeName;
    newDash.cohortId = cohortId;
    newDash.cohortName = cohortName;
    return newDash;
  }

  compareDashboardModels(dbModel1: DashboardModel, dbModel2: DashboardModel) {
    const omitProperties: Array<keyof DashboardModel> = ["timeRange", "compareTimeRange", "meta"];
    const compareModel1 = omit(dbModel1, omitProperties);
    const compareModel2 = omit(dbModel2, omitProperties);

    const widgetsEqual = this.compareWidgets(dbModel1, dbModel2);
    this.skipWidgets(compareModel1);
    this.skipWidgets(compareModel2);

    const layoutsEqual = this.compareLayouts(dbModel1, dbModel2);
    this.skipLayouts(compareModel1);
    this.skipLayouts(compareModel2);

    return widgetsEqual && layoutsEqual && isEqual(compareModel1, compareModel2);
  }

  getDefaultLayoutForWidget(widgetModel: BaseWidgetModel): PartialWidgetLayout {
    const { type, id } = widgetModel;
    const defaultDimensions = widgetRegistry.getPropsByWidgetId(type)?.dimensions() || {
      h: 0,
      minH: 0,
      minW: 0,
      w: 0
    };
    const layout: PartialWidgetLayout = {
      ...defaultDimensions,
      i: id
    };

    if (type === "us-field") {
      const usFieldWidget = widgetModel as any as USFieldWidgetModel;
      const currViz = usFieldWidget?.properties?.visualisation;
      const isTimeseriesViz = currViz === Visualisations.timeseries;

      if (isTimeseriesViz) {
        layout.w = 24;
      }
    }

    return layout;
  }

  getBestFitLayout(layouts: Layout[], partLayout: Pick<Layout, "w" | "h">): Layout {
    const { w, h } = partLayout;

    let bestFit: Layout;

    for (let row = 0; !bestFit && row < Number.MAX_SAFE_INTEGER; row++) {
      for (let col = 0; col + w <= 24; col++) {
        const candidate: Layout = {
          x: col,
          y: row,
          w,
          h
        };

        if (!layouts.some(item => this.doLayoutsOverlap(item, candidate))) {
          bestFit = candidate;
          break;
        }
      }
    }

    return (
      bestFit || {
        x: 0,
        y: 0,
        w,
        h
      }
    );
  }

  getCatalogWidgetForBizDataQuery(
    id: string,
    name: string,
    query: BizDataQuery,
    querySchema?: WidgetQuerySchema,
    mappingStatus?: QueryMappingStatus,
    meta?: WidgetMeta
  ): CatalogWidgetImpl {
    const {
      sliceSpec,
      buildingBlockConfig,
      idProps,
      widgetConfig,
      metricUserServiceFilters,
      cohortId,
      entityFilters,
      id: widgetId,
      labels
    } = query;

    let {
      entityTypeId,
      eventTypeId,
      widgetId: widgetConfigId
    } = WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(idProps);

    entityTypeId = entityTypeId || labels?.entityTypeId;
    eventTypeId = eventTypeId || labels?.eventTypeId;

    widgetConfigId = widgetId || widgetConfigId;

    let widgetResponseDto: WidgetResponseDTO;
    const querySchemaResponse: WidgetQuerySchemaResponse = {
      querySchema: querySchema ? [querySchema] : []
    };

    if (buildingBlockConfig) {
      widgetResponseDto = {
        querySchema: querySchemaResponse,
        version: 1,
        widgetConfig: WidgetConfigUtils.getWidgetConfigDtoFromBuildingBlockConfig(buildingBlockConfig),
        widgetId
      };
    } else if (widgetConfigId) {
      widgetResponseDto = {
        querySchema: querySchemaResponse,
        version: 1,
        widgetConfig: null,
        widgetId: widgetConfigId
      };
    } else if (widgetConfig) {
      widgetResponseDto = {
        querySchema: querySchemaResponse,
        widgetId: "",
        version: 1,
        widgetConfig: getDtoFromWidgetConfig(widgetConfig)
      };
    }

    if (widgetResponseDto?.widgetConfig) {
      widgetResponseDto.widgetConfig.metricUserServiceFilters = metricUserServiceFilters;
      widgetResponseDto.widgetConfig.cohortDefinition = cohortId
        ? {
            cohortId,
            name: ""
          }
        : null;
      widgetResponseDto.widgetConfig.entityFilters = entityFilters;
      widgetResponseDto.widgetConfig.bizEntityType = entityTypeId;
      widgetResponseDto.widgetConfig.userServiceEntityId = eventTypeId;
      widgetResponseDto.widgetConfig.mappingStatus = mappingStatus;
    }

    const model = catalogWidgetBuilder()
      .setDatasource(appConfig.defaultExploreDsName)
      .setEntityType(entityTypeId)
      .setUserserviceId(eventTypeId)
      .setId(id)
      .setTitle(name)
      .setQueryConfig({
        sourceQueryConfig: {
          queryType: "widgetConfig",
          metricId: sliceSpec.metricId,
          widgetResponse: widgetResponseDto
        }
      })
      .setProperties({
        aggregateTags: sliceSpec?.sliceSet?.slices?.map(s => s.tagName) || []
      })
      .buildModel();

    return new CatalogWidgetImpl(model, meta);
  }

  // Helper function to check if layouts overlap
  private doLayoutsOverlap(layout1: Layout, layout2: Layout): boolean {
    return (
      layout1.x < layout2.x + layout2.w &&
      layout1.x + layout1.w > layout2.x &&
      layout1.y < layout2.y + layout2.h &&
      layout1.y + layout1.h > layout2.y
    );
  }

  private skipWidgets(model: Partial<DashboardModel>) {
    delete model.widgets;
  }

  private compareWidgets(dbModel1: Partial<DashboardModel>, dbModel2: Partial<DashboardModel>) {
    let widgetsEqual = true;

    if (dbModel1.widgets && dbModel2.widgets) {
      dbModel1.widgets.forEach((widgetModel1, idx) => {
        const widgetModel2 = dbModel2.widgets[idx];

        if (!widgetModel1 || !widgetModel2) {
          widgetsEqual = false;
        }

        if (widgetsEqual) {
          const widgetModelsEqual = compareObjectsWithSameProperties(
            widgetModel1 || {},
            widgetModel2 || {},
            WIDGET_PROPS_TO_SKIP
          );

          const widgetPropertiesEqual = compareObjectsWithSameProperties(
            widgetModel1?.properties,
            widgetModel2?.properties
          );

          const timeRangeEqual = compareObjectsWithSameProperties(
            widgetModel1?.timeRange?.raw,
            widgetModel2?.timeRange?.raw
          );

          widgetsEqual = widgetModelsEqual && widgetPropertiesEqual && timeRangeEqual;
        }
      });
    }

    return widgetsEqual;
  }

  private compareLayouts(dbModel1: Partial<DashboardModel>, dbModel2: Partial<DashboardModel>) {
    /**
     * Ignore filter widgetIds since, they have a dummy layout added during runtime,
     * which is actually not important and doesn't need comparison
     */
    const filterWidgetIds: string[] = [];
    dbModel1.widgets?.forEach(w => {
      if (w.type === "filter") {
        filterWidgetIds.push(w.id);
      }
    });
    dbModel2.widgets?.forEach(w => {
      if (w.type === "filter") {
        filterWidgetIds.push(w.id);
      }
    });

    const compareLayouts1 = (dbModel1.layout || [])
      .filter(l => !filterWidgetIds.includes(l.i))
      .sort((a, b) => a.i?.localeCompare(b.i));
    const compareLayouts2 = (dbModel2.layout || [])
      .filter(l => !filterWidgetIds.includes(l.i))
      .sort((a, b) => a.i?.localeCompare(b.i));

    return isEqualWith(compareLayouts1, compareLayouts2, (value1, value2) =>
      compareObjectsWithSameProperties(value1, value2)
    );
  }

  private skipLayouts(model: Partial<DashboardModel>) {
    delete model.layout;
  }
}

const WIDGET_PROPS_TO_SKIP = ["meta", "version", "properties", "timeRange"];

export const dashboardUtils = new DashboardUtils();
