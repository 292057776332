import { SeriesMappointOptions, SeriesMapOptions, Options, SeriesMappointDataOptions } from "highcharts";
import worldTopoJson from "@highcharts/map-collection/custom/world-highres2.topo.json";
import { inceptionDarkColorPalette } from "@inception/ui-styles";
import { formatNumber } from "@inception/ui";
import { BaseOptionsBuilder } from "../charts";
import getChartColor from "../charts/colors";
import { GeoSeriesLatLon } from "./types";

export class GeoMapOptionsBuilder extends BaseOptionsBuilder {
  emptySeries: SeriesMapOptions = {
    type: "map",
    data: [],
    showInLegend: false,
    showInNavigator: false
  };

  private zoomCoordinates: [number, number];

  constructor() {
    super(defaultOptions);
  }

  getZoomCoordinates(): [number, number] {
    return this.zoomCoordinates;
  }

  setChartFields(width: number, height: number) {
    this.chartOptions.chart.width = width;
    this.chartOptions.chart.height = height;
    return this;
  }

  setSeriesData(series: GeoSeriesLatLon[]) {
    const seriesOption = this.getLatLonSeriesOptions();

    let max = Number.NEGATIVE_INFINITY;
    const seriesDataPoints: SeriesMappointDataOptions[] = [];

    series.forEach((serie, i) => {
      const { lat, lon, name, value } = serie;

      const serieColor = getChartColor(i);

      max = Math.max(value, max);

      seriesDataPoints.push({
        lat,
        lon,
        name,
        color: serieColor,
        y: value
      });
    });

    seriesOption.data = seriesDataPoints;
    seriesOption.data.forEach(d => {
      const op = d as SeriesMappointDataOptions;
      op.x = max;
    });

    this.setZoomCoordinates(seriesDataPoints);

    this.chartOptions.series = [this.emptySeries, seriesOption];

    return this;
  }

  private getMarker(pointColor: string, ratio: number) {
    let size = Math.floor(20 * ratio);
    size = Math.max(size, 8);
    // This is the SVG for location dot extracted from font-awesome(v5) since we cannot render react components here.
    return `<div style="height: ${size}px; width: ${size}px;">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style="fill: ${pointColor}">
        <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z" fill='inherit' />
      </svg>
    </div>`;
  }

  private setZoomCoordinates(seriesOptionData: SeriesMappointDataOptions[]) {
    let minLat = Number.POSITIVE_INFINITY;
    let maxLat = Number.NEGATIVE_INFINITY;
    let minLon = Number.POSITIVE_INFINITY;
    let maxLon = Number.NEGATIVE_INFINITY;

    seriesOptionData.forEach(({ lat, lon }) => {
      minLat = Math.min(minLat, lat);
      maxLat = Math.max(maxLat, lat);

      minLon = Math.min(minLon, lon);
      maxLon = Math.max(maxLon, lon);
    });

    const latCenter = isFinite(minLat) && isFinite(maxLat) ? (maxLat + minLat) * 0.5 : 0;
    const lonCenter = isFinite(minLon) && isFinite(maxLon) ? (maxLon + minLon) * 0.5 : 0;

    this.zoomCoordinates = [latCenter, lonCenter];
  }

  private getLatLonSeriesOptions(): SeriesMappointOptions {
    const getMarker = this.getMarker.bind(this);
    return {
      type: "mappoint",
      name: "",
      data: [],
      dataLabels: {
        enabled: true,
        useHTML: true,
        allowOverlap: true,
        formatter: function () {
          const { color: pointColor, y: value, x: maxValue } = this.point as any;
          const ratio = value / maxValue;
          return getMarker(pointColor, ratio);
        }
      },
      className: "geomap-series",
      tooltip: {
        headerFormat: "",
        pointFormatter: function (this) {
          const { color, name, y } = this;

          const tooltipText = `<div class='inc-charts-tooltip'>
            <div class='inc-charts-tooltip-series-row'>
              <div class="inc-highcharts-square-symbol" style="background-color:${color};"></div>
                <div class='inc-charts-tooltip-series-name'>
                  ${name}
                </div> 
                <div class='inc-charts-tooltip-series-value'> <span>${formatNumber(y)}</span></div>
              </div>
            </div>`;
          return tooltipText;
        }
      }
    };
  }
}

const defaultOptions: Options = {
  accessibility: {
    enabled: false
  },
  chart: {
    backgroundColor: "transparent",
    map: worldTopoJson
  },
  mapNavigation: {
    enabled: true,
    enableButtons: false
  },
  mapView: {
    projection: {
      name: "Miller",
      rotation: [-9, 0, 0]
    }
  },
  plotOptions: {
    map: {
      allAreas: true,
      affectsMapView: true,
      borderWidth: 1,
      borderColor: inceptionDarkColorPalette.palette.B300
    }
  },
  title: {
    text: ""
  },
  tooltip: {
    useHTML: true,
    className: "highcharts-tooltip"
  }
};
