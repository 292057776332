import { useMemo } from "react";
import { CatalogWidgetUtils } from "../../../CatalogWidgetUtils";
import { CatalogQueryConfig } from "../../../models";
import { AggregationUIOptions } from "../../../../../../core";
import { eventFieldUtils } from "../../../../../../utils";

export const useFetchFieldNameMetricNames = (
  aggregator: AggregationUIOptions,
  queryConfig: CatalogQueryConfig,
  eventTypeName: string
) => {
  const { sourceQueryConfig } = queryConfig || {};

  const { fieldName, metricName, childMetricNames } = useMemo(() => {
    const usFieldWithMeta = sourceQueryConfig?.queryType === "userServiceField" ? sourceQueryConfig?.usField : null;
    const userServiceField = usFieldWithMeta?.userServiceField;
    const isPredefinedField = usFieldWithMeta ? CatalogWidgetUtils.isPredefinedField(usFieldWithMeta) : false;

    const fieldName = CatalogWidgetUtils.getFieldName({ sourceQueryConfig });
    const displayFieldName = eventFieldUtils.removeFieldsPrefix(fieldName);

    const aggLabel = aggregator?.label;
    const aggFieldName = aggLabel ? `${aggLabel} ${displayFieldName}` : displayFieldName;

    let metricName = "";
    const childMetricNames: Record<string, string> = {};

    if (sourceQueryConfig?.queryType === "widgetConfig") {
      metricName = fieldName;
      const { widgetResponse, childMetricIds } = sourceQueryConfig;
      const { widgetConfig } = widgetResponse || {};
      if (childMetricIds?.length) {
        const metrics = widgetConfig.dataDefinition?.metrics || {};
        childMetricIds.forEach(metricId => {
          const metricName = metrics[metricId]?.name;
          metricName && (childMetricNames[metricId] = metricName);
        });
      }
    } else {
      metricName =
        usFieldWithMeta && isPredefinedField
          ? CatalogWidgetUtils.getDisplayFieldName(userServiceField, eventTypeName)
          : CatalogWidgetUtils.getMetricName(fieldName, aggFieldName);
    }

    return {
      fieldName,
      metricName,
      childMetricNames
    };
  }, [aggregator?.label, eventTypeName, sourceQueryConfig]);

  return {
    fieldName,
    metricName,
    childMetricNames
  };
};
