import React, { FC, useCallback, useMemo } from "react";
import { BizDataQuery } from "../../services/api/explore";
import { OpBizDataQuery } from "../../services/api/operationalise";
import {
  addAnalysisKpi,
  cloneAnalysisKpi,
  deleteAnalysisKpi,
  setAnalysisKpiName,
  setAnalysisKpiQuery,
  useOpAnalysisStore
} from "./context";
import KPIEntiresEditor from "./KPIEntiresEditor";

type Props = {
  eventTypeId: string;
  isFetchingEventTypeList: boolean;
};

const AnalysisKpiEditor: FC<Props> = props => {
  const { eventTypeId, isFetchingEventTypeList } = props;
  const { dispatch, state } = useOpAnalysisStore();
  const { opAnalysisImpl } = state;
  const kpiRecord = useMemo(() => opAnalysisImpl.getKpis(), [opAnalysisImpl]);

  const addKpi = useCallback(() => {
    dispatch(addAnalysisKpi(eventTypeId));
  }, [dispatch, eventTypeId]);
  const cloneKpi = useCallback(
    (kpiToCloneId: string) => {
      dispatch(cloneAnalysisKpi(kpiToCloneId));
    },
    [dispatch]
  );
  const setKpiName = useCallback(
    (key: string, name: string) => {
      dispatch(
        setAnalysisKpiName({
          key,
          payload: name
        })
      );
    },
    [dispatch]
  );
  const setKpiQuery = useCallback(
    (key: string, bizDataQuery: BizDataQuery) => {
      dispatch(
        setAnalysisKpiQuery({
          key,
          payload: bizDataQuery
        })
      );
    },
    [dispatch]
  );
  const deleteKpi = useCallback(
    (kpiToDeleteId: string) => {
      dispatch(deleteAnalysisKpi(kpiToDeleteId));
    },
    [dispatch]
  );

  return (
    <KPIEntiresEditor
      addKpi={addKpi}
      cloneKpi={cloneKpi}
      deleteKpi={deleteKpi}
      eventTypeId={eventTypeId}
      isFetchingEventTypeList={isFetchingEventTypeList}
      kpiRecordMap={kpiRecord as Record<string, OpBizDataQuery>}
      setKpiName={setKpiName}
      setKpiQuery={setKpiQuery}
      title="Key Performance Indicators"
    />
  );
};

export default AnalysisKpiEditor;
