import React, { FC, useState, useRef, useCallback, useMemo, useEffect, memo } from "react";
import { keys, forEach, isEqual } from "lodash";
import { useLocation } from "react-router-dom";
import { SelectorTag, TimeObj } from "../../../services/api/explore";
import { logger } from "../../../core/logging/Logger";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../components";
import { CompareSchemaRenderer } from "../../components";
import { BaseWidgetImpl, DashboardGrid } from "../../../dashboard";
import {
  MonitoredDataSchema,
  Op10zeDataQueryPayload,
  OpCreationConfig,
  SeriesFilter,
  operationaliseV2ApiService
} from "../../../services/api/operationalise";
import { getSchemaCombinationsV2 } from "../../utils";
import { TimeRange, useQueryParams, useInceptionRoute, shouldExcludeTag } from "../../../core";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { Op10zeDataQuery } from "../../../services/datasources/operationalize/types";
import { PayloadType } from "../../../../features/playground/types";
import { WidgetCustomAction } from "../../../dashboard/widgets/types";
import Op10zeWidgetModel from "../../../dashboard/widgets/Operationalize/models/model";
import { getTrainingDashboardImpl } from "./utils";
import SnoozeModalWrapper from "./SnoozeModalWrapper";

interface Props {
  entityTypeName: string;

  opId: string;
  opName?: string;
  version?: string;
  simulationId?: string;

  timeRange: TimeRange;
  disableFiltersInUrlParams?: boolean;

  className?: string;

  children?: JSX.Element | JSX.Element[];
  originalPayload?: string;
  getCustomPayload?: (tags: SelectorTag[]) => Record<string, any>;
  onPayloadsChange?: (payloads: PayloadType[]) => void;
  opCreationConfig?: OpCreationConfig;
  showSnoozeSeries?: boolean;
  onChangeSeriesFilter?: (seriesFilters: SeriesFilter[]) => void;

  companyName?: string;
}

const MAX_PARAMS = 50;

export const TrainingDashboard: FC<Props> = memo(props => {
  const {
    entityTypeName,
    opId,
    className: pClassName = "",
    timeRange,
    version,
    simulationId,
    disableFiltersInUrlParams = false,
    children,
    originalPayload,
    getCustomPayload,
    onPayloadsChange,
    opCreationConfig,
    onChangeSeriesFilter,
    showSnoozeSeries
  } = props;

  const { actionEvalSpec } = opCreationConfig || {};
  const { excludeSeriesFilterList } = actionEvalSpec || {};
  const { seriesFilters } = excludeSeriesFilterList || {};

  const getCustomWidgetActions = useCallback(
    (widget: BaseWidgetImpl): WidgetCustomAction[] => {
      const model = widget.getSaveModel() as Op10zeWidgetModel;
      const selectorSpec = model?.queries?.[0]?.payload?.selectorSpec;
      return showSnoozeSeries
        ? [
            {
              actionComponent: (
                <SnoozeModalWrapper
                  onChangeSeriesFilter={onChangeSeriesFilter}
                  selectorSpec={selectorSpec}
                  seriesFilters={seriesFilters}
                />
              ),
              showInHeader: true,
              tooltipText: "Snooze Series"
            }
          ]
        : [];
    },
    [onChangeSeriesFilter, seriesFilters, showSnoozeSeries]
  );

  const compareSchemaFetched = useRef(false);
  const { pathname } = useLocation();
  const { navigate } = useInceptionRoute();
  const params = useQueryParams();

  const [schemaLoading, setSchemaLoading] = useState(true);
  const [opDataSchema, setOpDataSchema] = useState<MonitoredDataSchema>(null);
  const [compareSchemaError, setCompareSchemaError] = useState("");
  const [selectedSchema, setSelectedSchema] = useState<Map<string, string[]>>(new Map());
  const [selectedFrequency, setSelectedFrequency] = useState<TimeObj[]>([]);
  const combinationVsFrequencyRef = useRef<Record<string, TimeObj[]>>({});
  const paramsInit = useRef(false);

  const schemaKeys = useMemo(() => {
    if (opDataSchema) {
      const allKeys = keys(opDataSchema.series?.[0]?.timeSeries?.label || {});
      return allKeys.filter(key => !shouldExcludeTag(key, true));
    }
    return [];
  }, [opDataSchema]);

  useEffect(() => {
    if (schemaKeys.length && !paramsInit.current) {
      const selectionSchema: Map<string, string[]> = new Map();
      forEach(params, (value, key) => {
        if (schemaKeys.includes(key)) {
          if (Array.isArray(value)) {
            selectionSchema.set(key, value);
          } else {
            selectionSchema.set(key, [value]);
          }
        }
      });
      paramsInit.current = true;
      setSelectedSchema(selectionSchema);
    }
  }, [params, schemaKeys]);

  const fetchCompareSchema = useCallback(async () => {
    setSchemaLoading(true);
    setCompareSchemaError("");
    try {
      const { data, error, message } = await operationaliseV2ApiService.getOp10zeSchemaV2(
        opId,
        null,
        version,
        simulationId
      );
      if (error) {
        setCompareSchemaError(message);
      } else {
        const { combinationVsFreqArr } = getSchemaCombinationsV2(data);

        combinationVsFrequencyRef.current = combinationVsFreqArr;
        setOpDataSchema(data);
      }
    } catch (err) {
      logger.error("Training dashboard", "Error fetching compare schema", err);
      setCompareSchemaError((err as any).message);
    } finally {
      compareSchemaFetched.current = true;
      setSchemaLoading(false);
    }
  }, [opId, simulationId, version]);

  const trainingInProgress = !schemaLoading && !opDataSchema?.series?.length;
  const { entityLookupData, series } = opDataSchema || {};

  const [payload, setPayload] = useState<Op10zeDataQueryPayload>(null);
  const { dbImpl, numMatchSeries } = useMemo(
    () =>
      !schemaLoading && !trainingInProgress
        ? getTrainingDashboardImpl(
            opId,
            simulationId,
            selectedSchema,
            selectedFrequency,
            entityTypeName,
            entityLookupData || {},
            combinationVsFrequencyRef.current,
            series,
            getCustomPayload
          )
        : {
            dbImpl: null,
            numMatchSeries: 0
          },
    [
      entityLookupData,
      entityTypeName,
      getCustomPayload,
      opId,
      schemaLoading,
      selectedFrequency,
      selectedSchema,
      series,
      simulationId,
      trainingInProgress
    ]
  );

  useEffect(() => {
    const newPayload = dbImpl?.widgets[0]?.queries[0] as Op10zeDataQuery;
    setPayload(newPayload?.payload);
    if (originalPayload && !isEqual(JSON.parse(originalPayload), newPayload?.payload)) {
      (dbImpl?.widgets[0]?.queries[0] as Op10zeDataQuery).payload = JSON.parse(originalPayload);
    }
    if (dbImpl?.timeRange && payload && onPayloadsChange) {
      const startSecs = timeRangeUtils.getSecondsFromMillis(dbImpl?.compareTimeRange?.from.valueOf());
      const endSecs = timeRangeUtils.getSecondsFromMillis(dbImpl?.compareTimeRange?.to.valueOf());
      const qPayload = {
        ...newPayload?.payload,
        startSecs,
        endSecs
      };
      const payloadParam: PayloadType[] = [
        {
          payload: qPayload,
          tags: null
        }
      ];
      onPayloadsChange(payloadParam);
    }
  }, [dbImpl, onPayloadsChange, payload, originalPayload]);

  useEffect(() => {
    compareSchemaFetched.current = false;
    paramsInit.current = false;
  }, [simulationId]);

  useEffect(() => {
    if (!compareSchemaFetched.current) {
      fetchCompareSchema();
    }
  }, [fetchCompareSchema]);

  const numSeries = dbImpl?.widgets?.length || 0;
  const filterStr = numSeries > 1 ? `Displaying ${numSeries} of ${numMatchSeries} series` : "";

  const className = `training-dashboard ${pClassName}`;
  const hasError = Boolean(compareSchemaError);

  const canRenderDashboard = !schemaLoading && Boolean(dbImpl);

  const updateQueryParams = useCallback(
    (selection: Map<string, string[]>) => {
      if (!disableFiltersInUrlParams) {
        const newParams: Record<string, string[]> = {};

        const numKeys = selection.size;
        let paramsPerKey = Math.floor(MAX_PARAMS / numKeys);
        paramsPerKey = Math.max(paramsPerKey, 1);

        selection.forEach((value, key) => {
          const values = value.slice(0, paramsPerKey);
          newParams[key] = values;
        });

        const allParams = {
          ...params,
          ...newParams
        };
        navigate(pathname, { queryParams: allParams });
      }
    },
    [disableFiltersInUrlParams, navigate, params, pathname]
  );

  const onSchemaChange = useCallback(
    (selection: Map<string, string[]>) => {
      updateQueryParams(selection);
      setSelectedSchema(selection);
    },
    [updateQueryParams]
  );

  return (
    <div className={className}>
      {schemaLoading && <LoadingSpinner />}
      {!schemaLoading && (
        <>
          {hasError && <div className="status-danger">{String(compareSchemaError)}</div>}
          {!hasError && !trainingInProgress && (
            <>
              <VerticallyCenteredRow className="inc-flex-grow width-100 compare-schema-renderer-wrapper">
                <CompareSchemaRenderer
                  entityTypeName={entityTypeName}
                  onFrequenciesChange={setSelectedFrequency}
                  onSelectionChange={onSchemaChange}
                  opDataSchema={opDataSchema}
                  schemaLoading={schemaLoading}
                  selectedFrequencies={selectedFrequency}
                  selectedSchema={selectedSchema}
                />
                {children}
              </VerticallyCenteredRow>
              {Boolean(filterStr) && (
                <div className="marginBt16 inc-text-body-semibold training-dashboard--filtered-series">{filterStr}</div>
              )}
              {canRenderDashboard && (
                <>
                  {numSeries > 0 && (
                    <DashboardGrid
                      dashboard={dbImpl}
                      embedded
                      getCustomWidgetActions={getCustomWidgetActions}
                      presetTimeRange={timeRange}
                    />
                  )}
                  {!numSeries && (
                    <VerticallyCenteredRow className="inc-flex-center inc-text-subtext-medium height-100 width-100">
                      No series found for the provided frequency and filters combination
                    </VerticallyCenteredRow>
                  )}
                </>
              )}
            </>
          )}
          {!hasError && trainingInProgress && (
            <LoadingSpinner
              className="status-info"
              titleText="Training in progress..."
            />
          )}
        </>
      )}
    </div>
  );
});
