import React, { FC, useCallback, useMemo } from "react";
import { useOpStore, setSchedule } from "../../../context";
import { OpSchedule } from "../../../../services/api/operationalise";
import { OpScheduleEditorV2 } from "../ScheduleEditor/ScheduleEditorV2";

interface Props {}

export const ScheduleEditorWrapper: FC<Props> = () => {
  const { state, dispatch } = useOpStore();

  const { opCreationConfig, readOnly } = state;

  const { opCreationConfigDef, outlierConfig, opAnalysisConfig } = opCreationConfig;
  const { schedule: opSchedule } = opCreationConfigDef || {};
  const { schedule: outlierSchedule } = outlierConfig || {};
  const { schedule: analysisSchedule } = opAnalysisConfig || {};
  const schedule = useMemo(
    () => opSchedule || outlierSchedule || analysisSchedule,
    [analysisSchedule, opSchedule, outlierSchedule]
  );
  const onScheduleChange = useCallback(
    (schedule: OpSchedule) => {
      dispatch(setSchedule(schedule));
    },
    [dispatch]
  );

  return (
    <div className="op-schedule-editor">
      <OpScheduleEditorV2
        onChange={onScheduleChange}
        opSchedule={schedule}
        readOnly={readOnly}
      />
    </div>
  );
};
