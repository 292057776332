import React, { FC, useEffect, useMemo, useState, useCallback, useRef, forwardRef } from "react";
import {
  IncFaIcon,
  IncToolTip,
  IncModal,
  CircleProgressBar,
  IncPopper,
  IncSmartText,
  useRenderOnVisibility
} from "@inception/ui";
import { css } from "emotion";
import { transparentize } from "polished";
import {
  TriagePageConfigPayload,
  ExploreEntityFilter,
  IncidentSliceContribution,
  UserServiceFilterList,
  UserServiceFieldSlice
} from "../../../services/api/explore";
import { useTimeRange } from "../../../core";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { useFetchImpactedWidgetsData, ImpactedWidgetsDataEntry } from "../impact-widget/hooks";
import { VerticallyCenteredRow } from "../../flex-components";
import LoadingSpinner from "../../Loading/Loading";
import { getMetricIdForImpactWidget } from "../utils";
import { getFormattedValueForImpactedWidget } from "../impact-widget/utils";
import { ImpactedWidgetDrawer, ImpactedWidgetDrawerProps } from "./ImpactedWidgetDrawer";
import { SliceIndicatorDrawer } from "./SliceIndicatorDrawer";

interface Props extends ImpactedWidgetDrawerProps {
  incidentId: string;
  opConfigId: string;
  alertingSlices: UserServiceFieldSlice[];

  preset?: TriagePageConfigPayload["keyIndicators"];
  hideDrawer?: boolean;
  filtersExist?: boolean;

  sliceContributorsArr: IncidentSliceContribution[];
}

export const ImpactedWidgetIndicator: FC<Props> = props => {
  const {
    incidentId,
    opConfigId,
    impactedWidget,
    entityFilters,
    eventFieldFilters,
    preset,
    timeRange: pTimeRange,
    cohortId,
    entityTypeId,
    hideDrawer = false,
    filtersExist = false,
    generateDemoData,
    ...restProps
  } = props;

  const { impactedWidgets: presetImpactedWidgets } = preset || {};

  const { timeRange } = useTimeRange();

  const { startTimeMillis, endTimeMillis } = useMemo(() => {
    const tr = pTimeRange || timeRange;
    const rawTr = tr.raw;
    const { from, to } = timeRangeUtils.getTimeRangeMillisFromRaw(rawTr);

    return {
      startTimeMillis: from,
      endTimeMillis: to
    };
  }, [pTimeRange, timeRange]);

  const { impactedWidgets, userServiceFilters } = useMemo(() => {
    const metricIdKey = getMetricIdForImpactWidget(impactedWidget);

    return {
      impactedWidgets: [impactedWidget],
      userServiceFilters:
        eventFieldFilters && metricIdKey
          ? {
              [metricIdKey]: eventFieldFilters
            }
          : {}
    };
  }, [eventFieldFilters, impactedWidget]);

  const emptyFilters = useMemo(
    () => ({
      entityFilters: [] as ExploreEntityFilter[],
      userServiceFilters: {} as Record<string, UserServiceFilterList>
    }),
    []
  );

  const { ref, wasVisibleOnce } = useRenderOnVisibility();

  const { refetch: refetchNonFilteredData, resultsByImpactedWidget: nonFilteredResultsByImpactedWidget } =
    useFetchImpactedWidgetsData(
      impactedWidgets,
      incidentId,
      opConfigId,
      groupByTags,
      emptyFilters.entityFilters,
      emptyFilters.userServiceFilters,
      startTimeMillis,
      endTimeMillis,
      groupByTags,
      null,
      null,
      null,
      null,
      null,
      generateDemoData
    );

  const { refetch: refetchFilteredData, resultsByImpactedWidget: filteredResultsByImpactedWidget } =
    useFetchImpactedWidgetsData(
      impactedWidgets,
      incidentId,
      opConfigId,
      groupByTags,
      entityFilters,
      userServiceFilters,
      startTimeMillis,
      endTimeMillis,
      groupByTags,
      null,
      null,
      null,
      null,
      null,
      generateDemoData
    );

  useEffect(() => {
    if (impactedWidget && incidentId && wasVisibleOnce) {
      refetchFilteredData();
    }
  }, [impactedWidget, incidentId, refetchFilteredData, wasVisibleOnce]);

  useEffect(() => {
    if (impactedWidget && incidentId && filtersExist && wasVisibleOnce) {
      refetchNonFilteredData();
    }
  }, [filtersExist, impactedWidget, incidentId, refetchNonFilteredData, wasVisibleOnce]);

  const isPinned = impactedWidget?.isPrimary || presetImpactedWidgets?.includes(impactedWidget?.id);

  return impactedWidget ? (
    <Renderer
      cohortId={cohortId}
      entityFilters={entityFilters}
      entityTypeId={entityTypeId}
      eventFieldFilters={eventFieldFilters}
      filteredResultsByImpactedWidget={filteredResultsByImpactedWidget}
      filtersExist={filtersExist}
      hideDrawer={hideDrawer}
      impactedWidget={impactedWidget}
      isPinned={isPinned}
      nonFilteredResultsByImpactedWidget={nonFilteredResultsByImpactedWidget}
      ref={ref}
      {...restProps}
      incidentId={incidentId}
      opConfigId={opConfigId}
    />
  ) : (
    <></>
  );
};

type IProps = ImpactedWidgetDrawerProps & {
  incidentId: string;
  opConfigId: string;
  sliceContributorsArr: IncidentSliceContribution[];
  filteredResultsByImpactedWidget: Record<string, Record<string, ImpactedWidgetsDataEntry>>;
  nonFilteredResultsByImpactedWidget: Record<string, Record<string, ImpactedWidgetsDataEntry>>;
  isPinned: boolean;
  hideDrawer: boolean;
  filtersExist: boolean;
};

const Renderer = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const {
    impactedWidget,
    filteredResultsByImpactedWidget,
    nonFilteredResultsByImpactedWidget,
    isPinned,
    hideDrawer,
    filtersExist,
    sliceContributorsArr,
    ...drawerProps
  } = props;

  const { isPrimary } = impactedWidget;

  const tooltipRef = useRef<HTMLDivElement>();

  const [showTooltip, setShowTooltip] = useState(false);
  const openTooltip = useCallback((e: any) => {
    e.stopPropagation();
    setShowTooltip(true);
  }, []);
  const closeTooltip = useCallback((e: any) => {
    e.stopPropagation();
    setShowTooltip(false);
  }, []);

  const [open, setOpen] = useState(false);
  const openDrawer = useCallback(() => setOpen(true), []);
  const closeDrawer = useCallback(() => setOpen(false), []);

  const { id, name } = impactedWidget;

  const filteredResultEntryRec = filteredResultsByImpactedWidget?.[id] || {};
  const nonFilteredResultEntryRec = nonFilteredResultsByImpactedWidget?.[id] || {};

  const {
    error: fError,
    isError: fIsError,
    isFetching: fIsFetching,
    value: filValue
  } = getData(filteredResultEntryRec);

  const {
    error: nFError,
    isError: nFIsError,
    isFetching: nFIsFetching,
    value: nFilValue
  } = getData(nonFilteredResultEntryRec);

  const dataClassName = filtersExist ? compressedCSS : "width-100";

  const isFetching = fIsFetching || (filtersExist && nFIsFetching);
  const isError = fIsError || (filtersExist && nFIsError);
  const error = fError || (filtersExist ? nFError : "");

  const filterPer = isFetching ? 50 : Math.ceil((filValue / nFilValue) * 100);

  const { formattedFilValue, formattedNFilValue } = useMemo(() => {
    const formattedFilValue = getFormattedValueForImpactedWidget(impactedWidget, filValue);
    const formattedNFilValue = getFormattedValueForImpactedWidget(impactedWidget, nFilValue);

    return {
      formattedFilValue,
      formattedNFilValue
    };
  }, [filValue, impactedWidget, nFilValue]);

  const keyContributorsExist = sliceContributorsArr?.length > 0;

  return (
    <VerticallyCenteredRow
      className="key-indicators--slice"
      ref={ref}
    >
      {filtersExist && (
        <VerticallyCenteredRow
          className="marginRt12"
          onMouseEnter={openTooltip}
          onMouseLeave={closeTooltip}
          ref={tooltipRef}
        >
          <CircleProgressBar
            progress={filterPer}
            size={48}
            spinnerMode={isFetching}
            trackColor={transparentize(0.86, "#FF523B")}
          />
        </VerticallyCenteredRow>
      )}

      <div className={dataClassName}>
        <VerticallyCenteredRow className="marginBt8 width-100">
          <IncSmartText text={name} />

          {!hideDrawer && (
            <IncFaIcon
              className="marginLt12 status-info inc-cursor-pointer marginRt12"
              iconName="indent"
              onClick={openDrawer}
            />
          )}

          <IncFaIcon
            className="marginLtAuto star-icon"
            data-pinned={isPinned}
            iconName="star"
            regular={!isPinned}
          />
        </VerticallyCenteredRow>

        <VerticallyCenteredRow>
          {isFetching && <LoadingSpinner titleText=" " />}
          {!isFetching && (
            <>
              {isError && (
                <IncToolTip
                  placement="top"
                  titleText={error}
                  variant="error"
                >
                  <VerticallyCenteredRow className="status-danger">
                    <IncFaIcon
                      className="marginRt8 status-danger"
                      iconName="warning"
                    />
                    <VerticallyCenteredRow>Error fetching data</VerticallyCenteredRow>
                  </VerticallyCenteredRow>
                </IncToolTip>
              )}
              {!isError && (
                <>
                  <VerticallyCenteredRow>
                    <VerticallyCenteredRow className="marginRt6 inc-text-header">
                      {formattedFilValue}
                    </VerticallyCenteredRow>

                    {filtersExist && (
                      <VerticallyCenteredRow className="inc-label-common">/ {formattedNFilValue}</VerticallyCenteredRow>
                    )}
                  </VerticallyCenteredRow>
                </>
              )}
            </>
          )}
        </VerticallyCenteredRow>
      </div>

      {isPrimary && (
        <IncModal
          className="impacted-widget-drawer-modal"
          onClose={closeDrawer}
          show={open}
          showClose
          size="side-pane"
          titleText={name}
        >
          <ImpactedWidgetDrawer
            impactedWidget={impactedWidget}
            {...drawerProps}
          />
        </IncModal>
      )}

      {!isPrimary && keyContributorsExist && (
        <SliceIndicatorDrawer
          {...drawerProps}
          closeList={closeDrawer}
          defSliceContributors={null}
          impactedWidget={impactedWidget}
          lookupData={null}
          show={open}
          sliceContributorsArr={sliceContributorsArr}
        />
      )}

      <IncPopper
        anchorEl={tooltipRef.current}
        show={showTooltip && !isFetching}
      >
        <div className="inc-card-layout display-block">
          <VerticallyCenteredRow style={{ width: 200 }}>
            <IncSmartText
              className="inc-label-common width-80 marginRt12"
              text={`${name} (Filtered)`}
            />

            <div className="inc-text-subtext-medium width-20">{filValue}</div>
          </VerticallyCenteredRow>

          <VerticallyCenteredRow style={{ width: 200 }}>
            <IncSmartText
              className="inc-label-common width-80 marginRt12"
              text={name}
            />

            <div className="inc-text-subtext-medium width-20">{nFilValue}</div>
          </VerticallyCenteredRow>
        </div>
      </IncPopper>
    </VerticallyCenteredRow>
  );
});

const groupByTags: string[] = [];

const compressedCSS = css`
  width: calc(100% - 48px - 12px);
`;

const getData = (resultEntryRec: Record<string, ImpactedWidgetsDataEntry>) => {
  const { data, error, isError, isFetching } = Object.values(resultEntryRec)[0] || {
    data: null,
    error: null,
    isError: false,
    isFetching: true
  };

  const dataFrames = Object.values(data?.postAggData || {})[0]?.data || [];
  const numData = dataFrames[0]?.fields?.[1]?.data?.[0] as number;

  return {
    value: numData,
    error,
    isError,
    isFetching
  };
};
