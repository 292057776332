import { IncToolTip, IncButton, IncFaIcon } from "@inception/ui";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IncTabBar, VerticallyCenteredRow } from "../../../components";
import { Tab } from "../../../components/Tabs/TabBar";
import { TimeRangeSelector } from "../../../components/time-range";
import { dateTime, RawTimeRange, TimeRange, useInceptionRoute, useTypedQueryParams } from "../../../core";
import { OpCreationConfig, OpCreationFragmentType, SeriesFilter } from "../../../services/api/operationalise";
import { featureFlagService, FEATURE_FLAGS } from "../../../services/feature-flags";
import timeRangeUtils, { TimeAlignOptions } from "../../../utils/TimeRangeUtils";
import { TrainingDashboard } from "../../screens/training-dashboard";
import { DemoDataParams } from "../../../services/api/explore";
import { OpRecipeDetails } from "../../../operationalize-recipe/wrappers";
import { ALL_USERSERVICES_ENTITY_TYPE_ID } from "../../../utils/ExploreUtils";
import { ResultsCard } from "./ResultsCard";

interface Props {
  isInProgress: boolean;
  opConfigId: string;
  opCreationConfig: OpCreationConfig;

  widgetId: string;
  entityTypeId: string;
  entityTypeName?: string;
  simulationId?: string;
  isPrimarySimulationOrOp?: boolean;
  showRecipe?: boolean;

  demoDataParams?: DemoDataParams;
  onChangeSeriesFilter?: (seriesFilter: SeriesFilter[]) => void;
}

type Params = {
  resultsType: string;
};

export const OpResultsRenderer: FC<Props> = props => {
  const {
    isInProgress,
    opConfigId,
    opCreationConfig,
    onChangeSeriesFilter,
    demoDataParams,
    simulationId: pSimulationId,
    entityTypeId,
    entityTypeName = entityTypeId,
    widgetId,
    isPrimarySimulationOrOp = false,
    showRecipe
  } = props;

  const tabs = useMemo(() => getTabs(showRecipe), [showRecipe]);

  const { companyName } = demoDataParams || {};

  const { opCreationConfigDef, name, trendDetectionConfigDef } = opCreationConfig || {};
  const { schedule } = opCreationConfigDef?.schedule || {};
  const { endTimeEpochSecs, startTimeEpochSecs } = schedule || {};

  const buildingBlockConfig = (opCreationConfigDef?.bizDataQuery || trendDetectionConfigDef?.bizDataQuery?.bizDataQuery)
    ?.buildingBlockConfig;
  const isEventOperationalize = buildingBlockConfig ? !buildingBlockConfig?.buildingBlockDef?.aggregator : false;

  const defTimeRange = useMemo<TimeRange>(() => {
    const startTimeSecs = startTimeEpochSecs ? parseInt(startTimeEpochSecs.toString(), 10) : 0;
    const endTimeSecs = endTimeEpochSecs ? parseInt(endTimeEpochSecs.toString(), 10) : 0;

    if (startTimeSecs && endTimeSecs) {
      const startMillis = startTimeSecs * 1000;
      const endMillis = endTimeSecs * 1000;

      return {
        from: dateTime(startMillis),
        to: dateTime(endMillis),
        raw: {
          from: startMillis.toString(),
          to: endMillis.toString()
        }
      };
    }

    const defTimeRange = timeRangeUtils.getTimeRangeFromRaw(
      {
        from: "now-28d",
        to: "now"
      },
      {
        alignToDay: true,
        alignToNext: true
      }
    );
    defTimeRange.from.add(1, "ms");

    return defTimeRange;
  }, [endTimeEpochSecs, startTimeEpochSecs]);

  const defTdTimeRange = useMemo<TimeRange>(() => {
    const startTimeSecs = startTimeEpochSecs ? parseInt(startTimeEpochSecs.toString(), 10) : 0;
    const endTimeSecs = endTimeEpochSecs ? parseInt(endTimeEpochSecs.toString(), 10) : 0;

    if (startTimeSecs && endTimeSecs) {
      const startMillis = startTimeSecs * 1000;
      const endMillis = endTimeSecs * 1000;

      return {
        from: dateTime(startMillis),
        to: dateTime(endMillis),
        raw: {
          from: startMillis.toString(),
          to: endMillis.toString()
        }
      };
    }

    return timeRangeUtils.getTimeRangeFromRaw({
      from: "now-3h",
      to: "now"
    });
  }, [endTimeEpochSecs, startTimeEpochSecs]);

  const [simulationId, setSimulationId] = useState(pSimulationId);
  const [timeRange, setTimeRange] = useState<TimeRange>(defTimeRange);
  const [tdTimeRange, setTdTimeRange] = useState<TimeRange>(defTdTimeRange);

  useEffect(() => {
    setSimulationId(pSimulationId);
    setTimeRange(defTimeRange);
    setTdTimeRange(defTdTimeRange);
  }, [defTdTimeRange, defTimeRange, pSimulationId]);

  const onTimeRangeChange = useCallback((rawTimeRange: RawTimeRange) => {
    const alignToDay =
      rawTimeRange.from.endsWith("M") || rawTimeRange.from.endsWith("w") || rawTimeRange.from.endsWith("d");

    const alignToHr = rawTimeRange.from.endsWith("h");
    const alignToMin = rawTimeRange.from.endsWith("m");

    const alignOpts: Partial<TimeAlignOptions> = {
      alignToDay,
      alignToHr,
      alignToMin,
      alignToNext: true
    };

    const timeRange = timeRangeUtils.getTimeRangeFromRaw(rawTimeRange, alignOpts);
    timeRange.from.add(1, "ms");

    setTimeRange(timeRange);
  }, []);

  const onRefresh = useCallback(() => {
    setTdTimeRange(prev => timeRangeUtils.getTimeRangeFromRaw(prev.raw));
  }, []);

  const { resultsType = RESULTS_TAB_ID } = useTypedQueryParams<Params>();

  const [selectedTabId, setSelectedTabId] = useState(resultsType);
  const handleTabSelection = useCallback((tabId: string | number) => setSelectedTabId(tabId as string), []);

  const { navigate } = useInceptionRoute();

  const navigateToPlayground = useCallback(() => {
    navigate(`/playground/opId/${opConfigId}/${entityTypeName || ALL_USERSERVICES_ENTITY_TYPE_ID}`, {
      newTab: true
    });
  }, [entityTypeName, navigate, opConfigId]);
  const showPlayGround = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.playGround);

  return (
    <>
      {showPlayGround && (
        <IncButton
          color="primary"
          label="Try it in Playground"
          onClick={navigateToPlayground}
        />
      )}
      {isInProgress && (
        <VerticallyCenteredRow className="inc-flex-center in-progress-card">
          <div>
            <svg
              className="marginLt6"
              fill="none"
              height="51"
              viewBox="0 0 50 51"
              width="50"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M47.785 8.45854C47.7538 8.40229 47.71 8.34604 47.66 8.30229C47.3475 7.98979 46.8413 7.98979 46.5288 8.30229L35.76 19.071L31.5163 14.8273L42.2913 4.05229C42.3413 4.00229 42.3788 3.95229 42.4163 3.89604C42.6413 3.51479 42.5163 3.02729 42.135 2.80229C35.9975 -0.822708 27.96 0.00854105 22.6788 5.28354C18.4913 9.47104 17.1038 15.4085 18.5225 20.746L0.210006 39.0585C0.0225057 39.246 0.0350054 39.5523 0.228755 39.746L10.835 50.3523C11.0288 50.546 11.3413 50.5585 11.5225 50.371L29.8288 32.0648C35.1725 33.4898 41.11 32.1085 45.3038 27.9148C50.5788 22.6335 51.41 14.596 47.785 8.45854ZM42.1225 24.7273C38.4913 28.3585 33.0413 29.0585 28.71 26.8273L28.16 27.3773L28.1538 27.371L11.0288 44.5023L6.07876 39.5523L20.46 25.171C20.46 25.171 20.46 25.1773 20.4663 25.1773L23.7663 21.8773C21.535 17.546 22.235 12.096 25.8663 8.46479C27.0374 7.29259 28.4478 6.38701 30.0011 5.80993C31.5543 5.23285 33.2138 4.99787 34.8663 5.12104L26.56 13.421C26.1848 13.7966 25.9741 14.3058 25.9741 14.8367C25.9741 15.3675 26.1848 15.8767 26.56 16.2523L34.3413 24.0335C34.7169 24.4087 35.226 24.6194 35.7569 24.6194C36.2878 24.6194 36.7969 24.4087 37.1725 24.0335L45.4725 15.7335C45.7038 18.971 44.5725 22.2835 42.1225 24.7273Z"
                fill="#4A505C"
              />
            </svg>
            <div
              className="marginTp10 inc-text-subtext-medium inc-text-inactive"
              style={{ color: "#696D77" }}
            >
              Simulating...
            </div>
          </div>
        </VerticallyCenteredRow>
      )}
      {!isInProgress && (
        <>
          <IncTabBar
            className="marginBt16"
            disableRouteChange
            handleSelection={handleTabSelection}
            selected={selectedTabId}
            tabs={tabs}
          />

          {selectedTabId === RECIPE_TAB_ID && (
            <OpRecipeDetails
              opCreationConfig={opCreationConfig}
              opCreationFragmentType={OpCreationFragmentType.INSIGHT_GENERATION}
              readOnly
            />
          )}

          {selectedTabId === RESULTS_TAB_ID && (
            <ResultsCard
              entityTypeId={entityTypeId}
              entityTypeName={entityTypeName}
              isEventOperationalize={isEventOperationalize}
              onTimeRangeChange={onTimeRangeChange}
              opConfigId={opConfigId}
              opName={name}
              shouldRenderInfo={!isPrimarySimulationOrOp}
              simulationId={simulationId}
              timeRange={timeRange}
              widgetId={widgetId}
            />
          )}

          {selectedTabId === TRAINING_DASHBOARD_ID && (
            <TrainingDashboard
              companyName={companyName}
              disableFiltersInUrlParams
              entityTypeName={entityTypeName}
              onChangeSeriesFilter={onChangeSeriesFilter}
              opCreationConfig={opCreationConfig}
              opId={opConfigId}
              opName={name}
              showSnoozeSeries={Boolean(onChangeSeriesFilter)}
              simulationId={simulationId}
              timeRange={tdTimeRange}
            >
              <VerticallyCenteredRow className="marginLt24">
                <IncToolTip
                  placement="top"
                  titleId="timerange.refresh"
                >
                  <IncButton
                    className="marginRt12"
                    color="link"
                    iconType="icon"
                    id="time-range-refresh-button"
                    onClick={onRefresh}
                    size="small"
                  >
                    <IncFaIcon
                      iconName="refresh"
                      style={{ transform: "scale(0.85)" }}
                    />
                  </IncButton>
                </IncToolTip>

                <TimeRangeSelector
                  buttonDisplay
                  onTimeSelect={setTdTimeRange}
                  timeRange={tdTimeRange}
                />
              </VerticallyCenteredRow>
            </TrainingDashboard>
          )}
        </>
      )}
    </>
  );
};

const RESULTS_TAB_ID = "results";
const TRAINING_DASHBOARD_ID = "trainingDashboard";
const RECIPE_TAB_ID = "recipe";

const getTabs = (showRecipe = false) => {
  const tabs: Tab[] = [
    {
      id: RESULTS_TAB_ID,
      label: "Results"
    },
    {
      id: TRAINING_DASHBOARD_ID,
      label: "Training Dashboard"
    }
  ];

  if (showRecipe) {
    tabs.unshift({
      id: RECIPE_TAB_ID,
      label: "Recipe"
    });
  }

  return tabs;
};
