import { useState, useMemo, useEffect, useRef, useCallback } from "react";
import { isEqual } from "lodash";
import { USFieldWidgetUtils } from "../../USFieldWidgetUtils";
import {
  WidgetResponseDTO,
  PostAggProjection,
  CohortEntityFilter,
  LimitSpecFunction,
  UserServiceFilterList
} from "../../../../../services/api/explore";
import { USFWQueryConfig } from "../../models";
import { VizToQueryConfig, TimeRange, AggregationUIOptions, Visualisations, logger } from "../../../../../core";
import { TagFilterSelection, EntityWidgetData, ChangeMetric } from "../../../../../biz-entity";
import { eventFieldUtils, getDownSampleInterval } from "../../../../../utils";
import { useFetchUSFWData } from "../../hooks";
import { USFieldDataPayload } from "../../types";
import { DataQueryError } from "../../../../../services/api/types";
import timeRangeUtils from "../../../../../utils/TimeRangeUtils";
import { DashboardTimeRange } from "../../../../models";
import { USFWRendererProps } from "./types";

const MAX_DATA_POINTS = 50;

export const useFetchQueryPayload = (
  widgetResponseDTO: WidgetResponseDTO,
  presetAggregateTags: string[],
  queryConfig: USFWQueryConfig,
  vizConfig: VizToQueryConfig,
  timeRange: TimeRange,
  compareTimeRange: TimeRange,
  selectedFilters: TagFilterSelection,
  metricId: string,
  metricType: ChangeMetric,
  postAggProjection: PostAggProjection,
  limitSpecFunction: LimitSpecFunction,
  seriesLimit: number,
  resetFilters: () => void,
  entityFilters: CohortEntityFilter[],
  cohortFilters: CohortEntityFilter[],
  eventFilters: Record<string, UserServiceFilterList>,
  isSingleStatQuery?: boolean
) => {
  const [aggregatedTags, setAggregatedTags] = useState<string[]>(presetAggregateTags || []);
  const [dataFetchPayload, setDataFetchPayload] = useState<USFieldDataPayload>();
  const prevAggTags = useRef(presetAggregateTags || []);
  const aggregatedTagsInitialized = useRef(false);

  const querySchemaExists = Boolean(widgetResponseDTO?.querySchema?.querySchema?.length);

  const updateAggregatedTags = useCallback((tags: string[]) => {
    setAggregatedTags(tags);
    if (!aggregatedTagsInitialized.current) {
      aggregatedTagsInitialized.current = true;
    }
  }, []);

  useMemo(() => {
    if (querySchemaExists) {
      let fSelectedFilters = selectedFilters;

      const pAggTags = prevAggTags.current || [];
      const aggTagsChanged = !isEqual(pAggTags, aggregatedTags);
      if (aggTagsChanged) {
        prevAggTags.current = [...(aggregatedTags || [])];
        window.setTimeout(() => resetFilters());
        fSelectedFilters = {
          bizFilter: null,
          disabledSeries: {},
          filtersBySliceSet: []
        };
      }

      const nPayload = USFieldWidgetUtils.getQueryDataPayload(
        widgetResponseDTO,
        queryConfig,
        vizConfig,
        timeRange,
        compareTimeRange,
        fSelectedFilters,
        aggregatedTags,
        metricId,
        metricType,
        postAggProjection,
        limitSpecFunction,
        seriesLimit,
        isSingleStatQuery
      );
      setDataFetchPayload(nPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    aggregatedTags,
    compareTimeRange,
    limitSpecFunction,
    metricId,
    metricType,
    postAggProjection,
    queryConfig,
    querySchemaExists,
    resetFilters,
    selectedFilters,
    seriesLimit,
    timeRange,
    vizConfig,
    widgetResponseDTO,
    entityFilters,
    cohortFilters,
    eventFilters
  ]);

  // const defAggregatedTags = useMemo(() => USFieldWidgetUtils.getAggTagFromPayload(dataFetchPayload), [dataFetchPayload]);

  // useEffect(() => {
  //   if (dataFetchPayload) {
  //     setAggregatedTags(defAggregatedTags);
  //   }
  // }, [dataFetchPayload, defAggregatedTags]);

  useEffect(() => {
    if (presetAggregateTags.length && !aggregatedTags.length && !aggregatedTagsInitialized.current) {
      setAggregatedTags(presetAggregateTags);
      aggregatedTagsInitialized.current = true;
      prevAggTags.current = [...presetAggregateTags];
    }
  }, [aggregatedTags.length, presetAggregateTags]);

  return {
    aggregatedTags,
    dataFetchPayload,
    updateAggregatedTags
  };
};

export const useFetchFieldNameMetricNames = (
  aggregator: AggregationUIOptions,
  queryConfig: USFWQueryConfig,
  eventTypeName: string
) => {
  const { fieldName, metricName } = useMemo(() => {
    const { sourceQueryConfig } = queryConfig || {};
    const { queryType } = sourceQueryConfig || {};

    const usFieldWithMeta = sourceQueryConfig?.queryType === "userServiceField" ? sourceQueryConfig?.usField : null;
    const userServiceField = usFieldWithMeta?.userServiceField;
    const isPredefinedField = usFieldWithMeta ? USFieldWidgetUtils.isPredefinedField(usFieldWithMeta) : false;

    const fieldName = USFieldWidgetUtils.getFieldName(queryConfig);
    const displayFieldName = eventFieldUtils.removeFieldsPrefix(fieldName);

    const aggLabel = aggregator?.label;
    const aggFieldName = aggLabel ? `${aggLabel} ${displayFieldName}` : displayFieldName;

    const metricName =
      queryType === "widgetConfig"
        ? fieldName
        : usFieldWithMeta && isPredefinedField
          ? USFieldWidgetUtils.getDisplayFieldName(userServiceField, eventTypeName)
          : USFieldWidgetUtils.getMetricName(fieldName, aggFieldName);

    return {
      fieldName,
      metricName
    };
  }, [aggregator?.label, eventTypeName, queryConfig]);

  return {
    fieldName,
    metricName
  };
};

export const useFetchCommonData = (
  queryId: string,
  cohortFilters: CohortEntityFilter[],
  entityFilters: CohortEntityFilter[],
  eventFilters: Record<string, UserServiceFilterList>,
  entityType: string,
  dataFetchPayload: USFieldDataPayload,
  timeRange: TimeRange,
  compareTimeRange: TimeRange,
  userServiceId: string,
  widgetResponseDTO: WidgetResponseDTO,
  seriesLimit: number,
  limitSpecFunction: LimitSpecFunction,
  variablesLoading: boolean,
  dsIntervalStr?: string,
  visualisation?: Visualisations
) => {
  const finDsIntervalStr = useMemo(() => {
    const { fromMillis, toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);

    const dbTimeRange: DashboardTimeRange = {
      from: fromMillis,
      to: toMillis
    };

    return getDownSampleInterval(dsIntervalStr, dbTimeRange, MAX_DATA_POINTS);
  }, [dsIntervalStr, timeRange]);

  const { isFetching, data, error, isError, refetch, cancelTokenSource } = useFetchUSFWData({
    id: queryId,
    cohortFilters,
    entityFilters,
    entityType,
    ...(dataFetchPayload || ({} as any)),
    timeRange,
    userServiceId,
    eventFilters,
    widgetResponseDTO,
    limit: seriesLimit,
    dsIntervalStr: finDsIntervalStr,
    visualisation
  });

  const querySchema = useMemo(() => widgetResponseDTO?.querySchema?.querySchema || [], [widgetResponseDTO]);
  const querySchemaExists = querySchema?.length > 0;

  const dataParamsRef = useRef<Record<string, any>>({});

  useEffect(() => {
    if (!variablesLoading && querySchemaExists && dataFetchPayload) {
      const nextParams = {
        cohortFilters,
        eventFilters,
        entityFilters,
        dataFetchPayload,
        seriesLimit,
        dsIntervalStr,
        timeRange,
        compareTimeRange,
        limitSpecFunction
      };

      if (!isEqual(dataParamsRef.current, nextParams)) {
        dataParamsRef.current = nextParams;
        refetch();
      } else {
        logger.info("USFieldWidget", "Skipping refetch", nextParams);
      }
    }
  }, [
    refetch,
    timeRange,
    compareTimeRange,
    cancelTokenSource,
    entityFilters,
    limitSpecFunction,
    cohortFilters,
    eventFilters,
    seriesLimit,
    variablesLoading,
    dataFetchPayload,
    querySchemaExists,
    dsIntervalStr
  ]);

  return {
    isFetching: isFetching || !querySchemaExists,
    isError,
    error,
    data
  };
};

export type USFWCommonFunctionalityProps = USFWRendererProps & {
  metricType?: ChangeMetric;
  visualisation?: Visualisations;
};

export const useCommonRendererFunctionality = (props: USFWCommonFunctionalityProps) => {
  const {
    aggregator,
    cohortFilters,
    compareTimeRange,
    entityFilters,
    entityTypeName,
    eventFilters,
    queryConfig,
    queryId,
    seriesLimit,
    timeRange,
    variablesLoading,
    widgetResponseDTO,
    metricId,
    userServiceId,
    onError,
    limitSpecFunction = null,
    dataFetchPayload,
    properties,
    eventTypeName,
    visualisation
  } = props;

  const { fieldName, metricName } = useFetchFieldNameMetricNames(aggregator, queryConfig, eventTypeName);

  const downsampleStr = properties.downsample || "auto";

  const { data, error, isError, isFetching } = useFetchCommonData(
    queryId,
    cohortFilters,
    entityFilters,
    eventFilters,
    entityTypeName,
    dataFetchPayload,
    timeRange,
    compareTimeRange,
    userServiceId,
    widgetResponseDTO,
    seriesLimit,
    limitSpecFunction,
    variablesLoading,
    downsampleStr,
    visualisation
  );

  const { entityWidgetData, dataError } = useMemo(() => {
    const fData = data || [];

    let dataErrObj: DataQueryError = {
      data: null,
      message: ""
    };

    const entityWidgetData: EntityWidgetData[] = [];

    fData.forEach(d => {
      const { data = [], error } = d;
      dataErrObj = dataErrObj || error;
      entityWidgetData.push(...data);
    });

    const dataError = dataErrObj?.message || "";

    return {
      entityWidgetData,
      dataError
    };
  }, [data]);

  const dataExists = !isError && entityWidgetData?.length > 0 && entityWidgetData[0]?.data;

  useEffect(() => {
    if (isError || dataError) {
      const err = error || dataError;
      onError(err.toString());
    } else {
      onError("");
    }
  }, [dataError, error, isError, onError]);

  const fData = useMemo<EntityWidgetData[]>(
    () =>
      entityWidgetData?.length
        ? entityWidgetData
        : [
            {
              compareConfigData: {},
              compareDataSize: 0,
              data: {},
              dataDefinitionId: metricId,
              dataQueryConfig: null,
              dataSize: 0,
              postAggDataSize: 0,
              postAggResult: {
                data: {},
                entityLookupData: {},
                percentChangeData: {},
                timeShiftData: {}
              },
              warnings: []
            }
          ],
    [entityWidgetData, metricId]
  );

  return {
    data: fData,
    dataExists,
    error,
    isError,
    isFetching,
    fieldName,
    metricName
  };
};
