import { defaultsDeep } from "lodash";
import { Visualisations } from "../../../../core";
import { WidgetMeta } from "../../../models/BaseWidgetModel";
import { CatalogWidgetImpl } from "../../Catalog/models";
import { AUTO_DS_INTERVAL } from "../../utils";
import { QueryCatalogWidgetModel } from "./model";

const getDefaults = (): Partial<QueryCatalogWidgetModel> => ({
  title: "New Query Catalog widget",
  type: "query-catalog",
  entityType: null,
  hideMappingControls: false,
  downsample: AUTO_DS_INTERVAL,
  disableUpdate: false,
  hashLabelsOnSave: false,
  compareInterval: null,
  properties: {
    disableCompare: true,
    timeseries: {
      connectNulls: false,
      pointRadius: 2,
      shape: "circle"
    },
    table: {
      metricHeaders: undefined,
      dateTimeFormat: undefined,
      formatterOptions: undefined,
      limitSpec: {
        function: "top",
        limit: 50
      },
      changeMetric: "current"
    },
    pinnedViz: [Visualisations.insights, Visualisations.timeseries],
    dataTypeCustomisation: {
      boolean: {
        falsy: {
          color: "#FF523B",
          label: "0",
          showAsPill: false,
          showStatusDot: false
        },
        truthy: {
          color: "#3BB443",
          label: "1",
          showAsPill: false,
          showStatusDot: false
        }
      },
      duration: {
        maxTerms: 2,
        precision: "MINUTES"
      }
    }
  }
});

export class QueryCatalogWidgetImpl extends CatalogWidgetImpl implements QueryCatalogWidgetModel {
  dataQuery: string;
  verticalId: string;
  subVerticalId: string;
  useCaseName: string;
  companyName: string;

  type: "catalog" | "query-catalog";

  constructor(model: Partial<QueryCatalogWidgetModel>, meta?: Partial<WidgetMeta>) {
    super(model, meta);
    this.assign(model, meta);

    this.type = "query-catalog";
  }

  assign(model: Partial<QueryCatalogWidgetModel>, meta?: Partial<WidgetMeta>) {
    const qcwModel: QueryCatalogWidgetModel = defaultsDeep({ type: "query-catalog" }, model, getDefaults());
    super.assign(qcwModel, meta);

    this.dataQuery = qcwModel.dataQuery || "";
    this.verticalId = qcwModel.verticalId || "";
    this.subVerticalId = qcwModel.subVerticalId || "";
    this.useCaseName = qcwModel.useCaseName || "";
    this.companyName = qcwModel.companyName || "";
  }

  update(model: Partial<QueryCatalogWidgetModel>) {
    this.assign(model);
    this.type = "query-catalog";
  }

  getSaveModel() {
    const saveModel = super.getSaveModel() as QueryCatalogWidgetModel;
    saveModel.type = "query-catalog";

    return saveModel;
  }
}
