import React, { useMemo } from "react";
import { WidgetCustomAction } from "../../../types";
import { USFWProps } from "../../types";
import { AggregationSelector } from "../../components";
import { AggregationUIOptions } from "../../../../../core";

type Props = {
  widget: USFWProps["widget"];
  aggOptions: AggregationUIOptions[];
  aggregator: AggregationUIOptions;
  setAggregator: (aggregator: AggregationUIOptions) => void;
};

export const useAggSelectAction = (props: Props) => {
  const { widget, aggOptions, aggregator, setAggregator } = props;

  const { renderMode, queryConfig, properties } = widget;

  const disableAggregator = properties?.disableAggregator;
  const isEditMode = renderMode === "edit";

  const isWidgetSourceQuery =
    queryConfig.sourceQueryConfig?.queryType === "widgetConfig" &&
    Boolean(queryConfig.sourceQueryConfig?.widgetResponse?.widgetId);

  const aggSelectAction = useMemo<WidgetCustomAction>(() => {
    const showAggSelect = !isWidgetSourceQuery && isEditMode;
    if (showAggSelect && !disableAggregator) {
      const actionComponent = (
        <AggregationSelector
          aggregations={aggOptions}
          onAggChange={setAggregator}
          selectedAggregation={aggregator}
        />
      );

      return {
        actionComponent,
        isTitleAction: true,
        showInHeader: true
      };
    }
    return null;
  }, [aggOptions, aggregator, disableAggregator, isEditMode, isWidgetSourceQuery, setAggregator]);

  return aggSelectAction;
};
