import { generateId } from "@inception/ui";
import { useCallback, useEffect } from "react";
import { useForceUpdate } from "../../../../core";
import { exploreApiService, WidgetConfigDTO } from "../../../../services/api/explore";
import { logger } from "../../../../core/logging/Logger";
import { eventFieldUtils } from "../../../../utils";
import { getDefaultWidgetConfigDto, getUSFieldWidgetLabels } from "../../../../utils/ExploreUtils";
import { USFieldWidgetImpl, WidgetQuerySourceConfig } from "../models";

export const useSaveWidgetConfigRef = (widget: USFieldWidgetImpl) => {
  const { widgetConfigRefId, queryConfig, entityType, userServiceId } = widget;

  const forceUpdate = useForceUpdate();

  const saveWidgetConfig = useCallback(async () => {
    const widgetConfigDto = getWidgetConfigDto(queryConfig, entityType, userServiceId);
    try {
      const { data, error, message } = await exploreApiService.saveWidgetConfig(widgetConfigDto, {}, true);

      if (error) {
        logger.error("USFieldWidget", "Error saving widget config: ", message);
      } else {
        widget.widgetConfigRefId = data?.widgetId;
        forceUpdate();
      }
    } catch (err) {
      logger.error("USFieldWidget", "Error saving widget config: ", err);
    }
  }, [entityType, forceUpdate, queryConfig, userServiceId, widget]);

  const updateWidgetConfigRefId = useCallback(() => {
    const { sourceQueryConfig } = queryConfig;
    widget.widgetConfigRefId = (sourceQueryConfig as WidgetQuerySourceConfig).widgetResponse?.widgetId;
    forceUpdate();
  }, [forceUpdate, queryConfig, widget]);

  useEffect(() => {
    const { sourceQueryConfig } = queryConfig;
    const isUSFieldSource = sourceQueryConfig.queryType === "userServiceField";
    const isWidgetSource = sourceQueryConfig.queryType === "widgetConfig";

    if (!widgetConfigRefId) {
      if (isUSFieldSource) {
        saveWidgetConfig();
      } else if (isWidgetSource) {
        updateWidgetConfigRefId();
      }
    }
  }, [queryConfig, saveWidgetConfig, updateWidgetConfigRefId, widgetConfigRefId]);
};

const getWidgetConfigDto = (
  queryConfig: USFieldWidgetImpl["queryConfig"],
  entityType: string,
  userServiceId: string
): WidgetConfigDTO => {
  const widgetConfigDto = getDefaultWidgetConfigDto(entityType, null);

  const { sourceQueryConfig } = queryConfig;

  if (sourceQueryConfig.queryType === "userServiceField") {
    const fieldId = generateId();

    const { usField } = sourceQueryConfig;
    const { userServiceField } = usField;
    const { fieldName, userServices } = userServiceField;

    const displayName = eventFieldUtils.removeFieldsPrefix(fieldName);
    const eventTypeId = userServiceId || userServices[0]?.userServiceEntityId || "";

    widgetConfigDto.name = [entityType, displayName].join(" - ");
    widgetConfigDto.dataDefinition = {
      fields: {
        [fieldId]: {
          sourceType: "eventField",
          eventFieldQueryConfig: {
            userServiceField,
            filterExpressions: []
          },
          id: fieldId
        }
      },
      metrics: {}
    };

    widgetConfigDto.labels = getUSFieldWidgetLabels(entityType, eventTypeId, fieldName);
  }

  return widgetConfigDto;
};
