import { produce, setAutoFreeze } from "immer";
import { isEqual } from "lodash";
import { USFieldWidgetUtils } from "../../dashboard/widgets/USField/USFieldWidgetUtils";
import { BizDataQuery, TimeObj } from "../../services/api/explore";
import { ThresholdType, OpSchedule, OpThreshold, CompareOperator } from "../../services/api/operationalise";
import { SCHEDULE_TYPES, defRollingFunction, UI_SCHEDULE_KEY, UI_THRESHOLD_KEY, THRESHOLD_TYPES } from "../constants";
import { getDefaultStartTriggerConditions, getDefaultStaticThreshold, getDefaultThreshold } from "../utils";
import { getRollingFreqFromSchedule } from "./reducerUtils";
import { OpState } from "./types";

type PickOpState = Pick<OpState, "opCreationConfig" | "context">;

setAutoFreeze(false);
class ReducerTransformations {
  updateOpSchedule(state: PickOpState, nextSchedule: OpSchedule) {
    return produce(state, draftState => {
      const prevUIScheduleType = (draftState.opCreationConfig.opCreationConfigDef.schedule.labels || {})[
        UI_SCHEDULE_KEY
      ];

      draftState.opCreationConfig.opCreationConfigDef.schedule = nextSchedule;

      const uiScheduleType = nextSchedule.labels[UI_SCHEDULE_KEY];

      // On ScheduleType change
      if (prevUIScheduleType !== uiScheduleType) {
        this.onScheduleChange(draftState as PickOpState, uiScheduleType);
      }
    });
  }

  updateBizDataQuery(state: PickOpState, nextBizDataQuery: BizDataQuery) {
    return produce(state, draftState => {
      const prevAggregator =
        draftState.opCreationConfig.opCreationConfigDef?.bizDataQuery?.buildingBlockConfig?.buildingBlockDef
          ?.aggregator ||
        draftState.opCreationConfig.outlierConfig?.bizDataQuery?.bizDataQuery?.buildingBlockConfig?.buildingBlockDef
          ?.aggregator;
      const nextAggregator = nextBizDataQuery?.buildingBlockConfig?.buildingBlockDef?.aggregator;
      if (draftState.opCreationConfig.opCreationConfigDef) {
        draftState.opCreationConfig.opCreationConfigDef.bizDataQuery = nextBizDataQuery;
      } else if (draftState.opCreationConfig.outlierConfig) {
        draftState.opCreationConfig.outlierConfig.bizDataQuery.bizDataQuery = nextBizDataQuery;
      }

      const thresholdDef =
        draftState.opCreationConfig.opCreationConfigDef?.threshold?.aboveThresholdDef ||
        draftState.opCreationConfig.opCreationConfigDef?.threshold?.belowThresholdDef;
      const dataQueryInfo = thresholdDef?.dataQueryInfo;
      if (dataQueryInfo) {
        const slices = dataQueryInfo?.sliceSpec?.sliceSet?.slices || [];
        const bizDataQuerySlices = nextBizDataQuery?.sliceSpec?.sliceSet?.slices || [];
        const slicesToKeep = slices.filter(slice =>
          bizDataQuerySlices.find(bizSlice => bizSlice.tagName === slice.tagName)
        );
        if (slicesToKeep.length !== slices.length) {
          const isAboveChange =
            draftState.opCreationConfig.opCreationConfigDef.threshold.comparator === CompareOperator.Above ||
            draftState.opCreationConfig.opCreationConfigDef.threshold.comparator === CompareOperator.AboveOrBelow;
          const isBelowChange =
            draftState.opCreationConfig.opCreationConfigDef.threshold.comparator === CompareOperator.Below;
          if (isAboveChange) {
            // eslint-disable-next-line max-len
            draftState.opCreationConfig.opCreationConfigDef.threshold.aboveThresholdDef.dataQueryInfo.sliceSpec.sliceSet.slices =
              slicesToKeep;
          }
          if (isBelowChange) {
            // eslint-disable-next-line max-len
            draftState.opCreationConfig.opCreationConfigDef.threshold.belowThresholdDef.dataQueryInfo.sliceSpec.sliceSet.slices =
              slicesToKeep;
          }
        }
      }

      const buildingBlockDef = nextBizDataQuery?.buildingBlockConfig?.buildingBlockDef;
      const isEventIDField = USFieldWidgetUtils.isEventIDField(
        buildingBlockDef?.fieldConfig?.userServiceField?.fieldName
      );
      draftState.context.selectionContext = buildingBlockDef?.aggregator || nextBizDataQuery?.id ? "metric" : "field";

      // Adjust threshold when aggregator changes.
      if (prevAggregator !== nextAggregator) {
        if (isEventIDField && !nextAggregator) {
          if (draftState.opCreationConfig.opCreationConfigDef) {
            draftState.opCreationConfig.opCreationConfigDef.threshold = null;
            draftState.opCreationConfig.opCreationConfigDef.startTrigger = null;
            delete draftState.opCreationConfig.opCreationConfigDef.triggerCondition;
          }
        } else {
          if (draftState.opCreationConfig.opCreationConfigDef) {
            const nextThreshold = nextAggregator
              ? draftState.opCreationConfig.opCreationConfigDef.threshold || getDefaultThreshold()
              : getDefaultStaticThreshold();
            draftState.opCreationConfig.opCreationConfigDef.threshold = nextThreshold;
            const prevStartTrigger = draftState.opCreationConfig.opCreationConfigDef.startTrigger;
            const triggerConditionExists = Boolean(
              prevStartTrigger?.allMatchStartTrigger || prevStartTrigger?.anyMatchStartTrigger
            );
            const defaultTriggerConditions = getDefaultStartTriggerConditions();
            draftState.opCreationConfig.opCreationConfigDef.startTrigger = nextAggregator
              ? triggerConditionExists
                ? prevStartTrigger
                : {
                    allMatchStartTrigger: {
                      triggerConditions:
                        !thresholdDef || thresholdDef?.currentQuery
                          ? defaultTriggerConditions
                          : defaultTriggerConditions.slice(0, 1)
                    }
                  }
              : null;
            delete draftState.opCreationConfig.opCreationConfigDef.triggerCondition;
          }
        }
      }
    });
  }

  updateRollingFunction(state: PickOpState, nextRollingFunction: string) {
    return produce(state, draftState => {
      if (draftState.opCreationConfig.opCreationConfigDef) {
        draftState.opCreationConfig.opCreationConfigDef.rollingFunction = nextRollingFunction;
      } else if (draftState.opCreationConfig.outlierConfig) {
        draftState.opCreationConfig.outlierConfig.bizDataQuery.rollingFunction = nextRollingFunction;
      }
    });
  }

  updateRollingFrequency(state: PickOpState, nextRollingFreq: TimeObj) {
    return produce(state, draftState => {
      if (draftState.opCreationConfig.opCreationConfigDef) {
        draftState.opCreationConfig.opCreationConfigDef.rollingFreq = nextRollingFreq;
      } else if (draftState.opCreationConfig.outlierConfig) {
        draftState.opCreationConfig.outlierConfig.bizDataQuery.rollingFreq = nextRollingFreq;
      }
    });
  }

  updateThreshold(state: PickOpState, nextThreshold: OpThreshold) {
    return produce(state, draftState => {
      if (draftState.opCreationConfig.opCreationConfigDef) {
        draftState.opCreationConfig.opCreationConfigDef.threshold = nextThreshold;
      }

      if (nextThreshold) {
        const allMatchStartTrigger = draftState.opCreationConfig.opCreationConfigDef.startTrigger?.allMatchStartTrigger;
        const anyMatchStartTrigger = draftState.opCreationConfig.opCreationConfigDef.startTrigger?.anyMatchStartTrigger;
        const triggerConditions = (
          allMatchStartTrigger?.triggerConditions ||
          anyMatchStartTrigger?.triggerConditions ||
          []
        ).map(draft => {
          const value = deepAssign({}, draft);
          delete value.id;
          return value;
        });
        const defaultTriggerConditions = getDefaultStartTriggerConditions();

        const { aboveThresholdDef, belowThresholdDef, thresholdType } = nextThreshold;
        const thresholdDef = aboveThresholdDef || belowThresholdDef;

        if (thresholdType === ThresholdType.STATIC && !thresholdDef?.currentQuery) {
          // If new threshold type is non-self static threshold, we remove accum trigger

          if (isEqual(defaultTriggerConditions, triggerConditions)) {
            // Check if triggers have changed, if not, remove accum trigger

            if (allMatchStartTrigger) {
              draftState.opCreationConfig.opCreationConfigDef.startTrigger = {
                allMatchStartTrigger: {
                  triggerConditions: defaultTriggerConditions.slice(0, 1)
                }
              };
            } else if (anyMatchStartTrigger) {
              draftState.opCreationConfig.opCreationConfigDef.startTrigger = {
                anyMatchStartTrigger: {
                  triggerConditions: defaultTriggerConditions.slice(0, 1)
                }
              };
            }
          }
        } else if (thresholdType === ThresholdType.AUTO) {
          // If new threshold type is automatic threshold, we add accum trigger

          if (isEqual(defaultTriggerConditions.slice(0, 1), triggerConditions)) {
            if (allMatchStartTrigger) {
              draftState.opCreationConfig.opCreationConfigDef.startTrigger = {
                allMatchStartTrigger: {
                  triggerConditions: defaultTriggerConditions
                }
              };
            } else if (anyMatchStartTrigger) {
              draftState.opCreationConfig.opCreationConfigDef.startTrigger = {
                anyMatchStartTrigger: {
                  triggerConditions: defaultTriggerConditions
                }
              };
            }
          }
        }
        delete draftState.opCreationConfig.opCreationConfigDef.triggerCondition;
      }
    });
  }

  updateEntityAggregated(state: PickOpState, nextEntityAggregated: boolean) {
    return produce(state, draftState => {
      if (draftState.opCreationConfig.opCreationConfigDef) {
        const isPeerThreshold =
          draftState.opCreationConfig.opCreationConfigDef?.threshold?.labels?.[UI_THRESHOLD_KEY] ===
          THRESHOLD_TYPES.peerGroup;
        if (nextEntityAggregated) {
          if (isPeerThreshold) {
            draftState.opCreationConfig.opCreationConfigDef.threshold = getDefaultThreshold();
          }
        }
      }

      draftState.opCreationConfig.labels = {
        ...(draftState.opCreationConfig.labels || {}),
        entityAggregated: nextEntityAggregated.toString()
      };
    });
  }

  private onScheduleChange(state: PickOpState, uiScheduleType: string) {
    const { opCreationConfigDef, outlierConfig } = state.opCreationConfig;
    const { schedule, bizDataQuery, startTrigger, threshold } = opCreationConfigDef || {};
    const { buildingBlockConfig: buildingBlocksConfig } =
      bizDataQuery || outlierConfig?.bizDataQuery?.bizDataQuery || {};

    const isContinuousSchedule = uiScheduleType === SCHEDULE_TYPES.everyMinute;
    const isCustomThreshold = uiScheduleType === SCHEDULE_TYPES.custom;

    if (uiScheduleType === SCHEDULE_TYPES.whenEventOccurs) {
      state.context.scheduleSelectionContext = "field";
      state.opCreationConfig = {
        ...state.opCreationConfig,
        opCreationConfigDef: opCreationConfigDef
          ? {
              ...opCreationConfigDef,
              rollingFreq: null,
              rollingFunction: null,
              schedule: {
                ...schedule,
                schedule: {
                  ...schedule.schedule,
                  endTimeEpochSecs: 0,
                  startTimeEpochSecs: 0
                },
                endOffset: null,
                startOffset: null
              },
              startTrigger: {
                ...(startTrigger || {
                  allMatchStartTrigger: {
                    triggerConditions: [
                      {
                        windowTrigger: {
                          howManyTimesToViolate: 1,
                          outOfPoints: 1,
                          outOfTime: null
                        }
                      }
                    ]
                  }
                })
              }
            }
          : opCreationConfigDef,
        outlierConfig: outlierConfig
          ? {
              ...outlierConfig,
              schedule: {
                ...outlierConfig.schedule,
                schedule: {
                  ...outlierConfig.schedule.schedule,
                  endTimeEpochSecs: 0,
                  startTimeEpochSecs: 0
                },
                endOffset: null,
                startOffset: null
              }
            }
          : outlierConfig
      };

      if (buildingBlocksConfig) {
        buildingBlocksConfig.aggregator = null;
        if (buildingBlocksConfig.buildingBlockDef) {
          buildingBlocksConfig.buildingBlockDef.aggregator = null;
        }
      }
    } else {
      const isFieldOperationalise = buildingBlocksConfig ? !buildingBlocksConfig?.buildingBlockDef?.aggregator : false;

      state.context.scheduleSelectionContext = buildingBlocksConfig ? "fieldOrMetric" : "metric";
      state.context.selectionContext = isFieldOperationalise ? "field" : "metric";

      opCreationConfigDef.schedule.endOffset = null;
      opCreationConfigDef.schedule.startOffset = null;

      opCreationConfigDef.rollingFreq = isFieldOperationalise
        ? null
        : getRollingFreqFromSchedule(opCreationConfigDef.schedule);
      opCreationConfigDef.rollingFunction = isFieldOperationalise
        ? null
        : opCreationConfigDef.rollingFunction || defRollingFunction;
      opCreationConfigDef.startTrigger = opCreationConfigDef.startTrigger || {
        allMatchStartTrigger: {
          triggerConditions: [
            {
              windowTrigger: {
                howManyTimesToViolate: 3,
                outOfPoints: 5,
                outOfTime: null
              }
            }
          ]
        }
      };

      if (threshold) {
        if ((isCustomThreshold || isContinuousSchedule) && threshold.thresholdType === ThresholdType.AUTO) {
          opCreationConfigDef.threshold = getDefaultStaticThreshold();
        }
      }
    }
  }
}

export const opReducerTransformations = new ReducerTransformations();

function deepAssign(target: Record<string, any>, sources: Record<string, any>) {
  Object.keys(sources).forEach(key => {
    if (typeof sources[key] === "object" && sources[key] !== null) {
      if (target[key] === undefined || target[key] === null) {
        target[key] = {};
      }
      deepAssign(target[key], sources[key]);
    } else {
      target[key] = sources[key];
    }
  });
  return target;
}
