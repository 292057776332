import React, { memo, useMemo, useCallback } from "react";
import { isEqual } from "lodash";
import { GroupTypeBase } from "react-select";
import { IncSelect, IncSelectOption, IncEditor } from "@inception/ui";
import {
  ParamValueTemplate,
  ActionConfigUIParam,
  ParamValue,
  TemplateCanvas,
  SectionElementType
} from "../../../../../../services/api/operationalise";
import { logger, EntityPropertyMap, EntityPropertySet, ObjectPropertyMap, generateId } from "../../../../../../core";
import { getPropertyValueByDataType, getValueFromParamValue } from "../../../../../utils";
import { ActionParamsEditorProps } from "../types";
import { MultiLineTemplateEditorWrapper } from "./MultiLineTemplateEditorWrapper";

type ParamProps = ActionParamsEditorProps & {
  uiParam: ActionConfigUIParam;
  onChange: (nValue: ParamValueTemplate) => void;
  value: ParamValueTemplate;

  onErrors?: (errors: string[], sectionKey: string) => void;
  filterOutLoopElements?: boolean;
  disableCustomTemplates?: boolean;

  useNewTemplateEditor?: boolean;
};

type Option = IncSelectOption<ParamValue>;

export const TemplateEditor = memo<ParamProps>(props => {
  const {
    onChange,
    uiParam,
    value,
    opCreationConfig,
    actionCategoryType,
    sourceTypeId,
    lookupValuesMap,
    bizActionConfig,
    op10zeId,
    filterOutLoopElements = false,
    onErrors,
    useNewTemplateEditor = false,
    disableCustomTemplates: supportCustomTemplate = false
  } = props;

  const { visible, editable, description, label, required, hasValuesLookup, name: paramName, type } = uiParam;

  const paramSuggestionValues = useMemo(
    () => (lookupValuesMap?.paramValues || {})[paramName]?.value || [],
    [lookupValuesMap, paramName]
  );

  const {
    templateCanvas: vTemplateCanvas,
    templateId: vTemplateId,
    value: vTemplateTextValue,
    templateParamTokens: vTemplateParamTokens
  } = value || {};
  const vTemplateText = vTemplateTextValue?.stringVal;

  const { templateCanvas, templateId, templateText, templateParams } = useMemo(() => {
    let templateId = "";
    let templateText = "";
    let templateCanvas: TemplateCanvas = null;
    let templateParams: string[] = [];

    if (value) {
      if (vTemplateId) {
        templateId = vTemplateId;

        const paramValue = paramSuggestionValues?.find(pv => pv.id === templateId);
        if (paramValue) {
          const { alertTemplateDef } = paramValue;
          const {
            templateCanvas: aDefTemplateCanvas,
            templateText: aDefTemplateText,
            templateParams: aDefTemplateParams
          } = alertTemplateDef || {};

          if (aDefTemplateCanvas && useNewTemplateEditor) {
            templateCanvas = aDefTemplateCanvas;
            templateParams = aDefTemplateParams;
          } else if (aDefTemplateText) {
            templateText = aDefTemplateText;
          }
        } else {
          logger.error("TemplateEditor", "Error getting preset template value", {
            paramSuggestionValues,
            templateId
          });
        }
      } else if (vTemplateText) {
        templateText = vTemplateText;
        templateId = CUSTOM_TEMPLATE_TEXT;
      } else if (vTemplateCanvas) {
        templateCanvas = vTemplateCanvas;
        templateId = CUSTOM_TEMPLATE_CANVAS;
      }
    }

    return {
      templateId,
      templateText,
      templateCanvas,
      templateParams
    };
  }, [paramSuggestionValues, useNewTemplateEditor, vTemplateCanvas, vTemplateId, vTemplateText, value]);

  const templateParamTokens = useMemo(
    () => Object.fromEntries(templateParams.map(param => [param, vTemplateParamTokens?.[param] || ""])),
    [templateParams, vTemplateParamTokens]
  );

  const onTemplateChange = useCallback(
    (templateId: string, templateCanvas: TemplateCanvas, templateText: string) => {
      if (templateId === CUSTOM_TEMPLATE_CANVAS) {
        onChange({
          templateCanvas,
          templateId: null,
          value: null
        });
      } else if (templateId === CUSTOM_TEMPLATE_TEXT) {
        onChange({
          templateCanvas: null,
          templateId: null,
          value: {
            stringVal: templateText
          }
        });
      } else {
        onChange({
          templateCanvas: null,
          templateId,
          value: null,
          templateParamTokens: {}
        });
      }
    },
    [onChange]
  );

  const groupOptions = useMemo<Array<GroupTypeBase<Option>>>(() => {
    const predefinedOpts = (paramSuggestionValues || []).map(paramValue => ({
      label: paramValue.name,
      value: paramValue.id,
      data: paramValue
    }));

    const customOpts: Option[] = [
      {
        data: {
          id: CUSTOM_TEMPLATE_TEXT,
          name: "Custom Script",
          alertTemplateDef: {
            templateCanvas: null,
            templateText: predefinedOpts[0]?.data?.alertTemplateDef?.templateText || "",
            templateSection: null
          },
          props: {}
        },
        value: CUSTOM_TEMPLATE_TEXT,
        label: "Custom Script"
      }
    ];

    if (useNewTemplateEditor) {
      customOpts.unshift({
        data: {
          id: CUSTOM_TEMPLATE_CANVAS,
          name: "Custom Template",
          alertTemplateDef: {
            templateCanvas: predefinedOpts[0]?.data?.alertTemplateDef?.templateCanvas || {
              footer: [],
              header: [],
              section: [
                {
                  sectionElementId: "rich-text-section",
                  sectionId: generateId(),
                  type: SectionElementType.RICH_TEXT,
                  content: ""
                }
              ]
            },
            templateSection: null,
            templateText: null
          },
          props: {}
        },
        value: CUSTOM_TEMPLATE_CANVAS,
        label: "Custom Template"
      });
    }

    const opts: Array<GroupTypeBase<Option>> = [
      {
        options: predefinedOpts,
        groupLabel: "Saved"
      }
    ];

    if (!supportCustomTemplate) {
      opts.push({
        options: customOpts,
        groupLabel: "Custom"
      });
    }

    return opts;
  }, [paramSuggestionValues, supportCustomTemplate, useNewTemplateEditor]);

  const selectedOpt = useMemo(() => {
    const options = groupOptions.reduce((acc, grp) => acc.concat(grp.options), []);
    return options.find(opt => opt.value === templateId);
  }, [groupOptions, templateId]);

  const onValChange = useCallback(
    (value: string | boolean | number | EntityPropertyMap | EntityPropertySet | ObjectPropertyMap | any) => {
      let strVal = value;
      let label = "";
      let propertyValue = null;
      if (value["id"]) {
        strVal = value["id"];
        label = value["name"];
      }
      propertyValue = getPropertyValueByDataType(strVal, type);
      onChange({
        label: label,
        value: propertyValue
      });
    },
    [onChange, type]
  );

  const onCustomTextChange = useCallback(
    (data: string) => {
      let val = data;
      if (type !== "_str") {
        try {
          val = JSON.parse(data);
        } catch (e) {
          if (type === "_map" || type === "_set" || type === "_objectmap") {
            logger.error("Integration params editor", "Error parsing JSON", data);
          }
        }
      }

      onValChange(val);
    },
    [onValChange, type]
  );

  const onTemplateSelectChange = useCallback(
    (opt: Option) => {
      const { data, value } = opt;
      onTemplateChange(value, data.alertTemplateDef.templateCanvas, data.alertTemplateDef.templateText);
    },
    [onTemplateChange]
  );

  const onTemplateCanvasChange = useCallback(
    (templateCanvas: TemplateCanvas) => {
      const matchingPredefOpt = groupOptions[0].options.find(opt => {
        const oTemplateCanvas = opt.data?.alertTemplateDef?.templateCanvas;
        return isEqual(oTemplateCanvas, templateCanvas);
      });

      const nextTemplateValue = matchingPredefOpt?.value || CUSTOM_TEMPLATE_CANVAS;
      onTemplateChange(nextTemplateValue, templateCanvas, null);
    },
    [groupOptions, onTemplateChange]
  );

  const onTemplateParamTokensChange = useCallback(
    (templateParamTokens: Record<string, string>) => {
      const nvalue = {
        ...value,
        templateParamTokens
      };
      onChange(nvalue);
    },
    [onChange, value]
  );

  const onTemplateTextChange = useCallback(
    (templateText: string) => {
      onTemplateChange(CUSTOM_TEMPLATE_TEXT, null, templateText);
    },
    [onTemplateChange]
  );

  if (!visible) {
    return <></>;
  }

  const disableTemplateEdit = !editable || supportCustomTemplate;

  if (hasValuesLookup) {
    const placeholder = `Select ${label}`;
    return (
      <div className="inc-flex-column marginBt12">
        <IncSelect
          allowCreate
          autoSort={false}
          helpText={description}
          isDisabled={!editable}
          isSearchable={false}
          label={label}
          onChange={onTemplateSelectChange}
          options={groupOptions}
          placeholder={placeholder}
          required={required}
          value={selectedOpt}
          wrapperClass="marginBt8"
        />

        {Boolean(templateCanvas) && (
          <MultiLineTemplateEditorWrapper
            bizActionConfig={bizActionConfig}
            categoryId={actionCategoryType}
            filterOutLoopElements={filterOutLoopElements}
            onChange={onTemplateCanvasChange}
            onErrors={onErrors}
            onTemplateParamTokensChange={onTemplateParamTokensChange}
            opConfig={opCreationConfig}
            opId={op10zeId}
            paramName={paramName}
            readOnly={disableTemplateEdit}
            sourceTypeId={sourceTypeId}
            templateCanvas={templateCanvas}
            templateParamTokens={templateParamTokens}
            templateParams={templateParams}
            uiParam={uiParam}
          />
        )}

        {!templateCanvas && (
          <IncEditor
            maxLines={15}
            mode="text"
            onChange={onTemplateTextChange}
            readOnly={disableTemplateEdit}
            value={templateText}
          />
        )}
      </div>
    );
  }

  logger.warn("Template Editor", "Encountered a param without lookup values", paramName);

  const primVal = getValueFromParamValue(value, type);

  if (type === "_str") {
    const strVal = primVal as string;
    return (
      <IncEditor
        className="marginBt12"
        helpText={description}
        label={label}
        maxLines={10}
        onChange={onCustomTextChange}
        value={strVal}
      />
    );
  }

  const jsonVal = typeof primVal === "object" ? JSON.stringify(primVal || "{}") : (primVal || "").toString();

  return (
    <IncEditor
      className="marginBt12"
      helpText={description}
      label={label}
      maxLines={4}
      onChange={onCustomTextChange}
      value={jsonVal}
    />
  );
});

const CUSTOM_TEMPLATE_CANVAS = "__custom_template_canvas__";
const CUSTOM_TEMPLATE_TEXT = "__custom_template_text__";
