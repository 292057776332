import React, { FC, useState, useCallback, useEffect, useMemo, useRef } from "react";
import { Editor, EditorProps } from "react-draft-wysiwyg";
import { Map } from "immutable";
import { ContentState, DraftDecorator, DraftEditorCommand, DraftHandleValue, EditorState, Modifier } from "draft-js";
import { isEqual } from "lodash";
import { cx } from "emotion";
import { LoadingSpinner } from "../../../../../components";
import {
  TemplateCanvas,
  ActionTemplateElementsResponse,
  TemplateCanvasSection,
  ActionTemplateTextElement,
  ActionTemplateElementGroup
} from "../../../../../services/api/operationalise";
import { noOp } from "../../../../../utils";
import { useToggleState } from "../../../../../core";
import {
  addTextElementToEditorState,
  getCanvasFromEditorState,
  getMatchRichTextToken,
  getUpdatedEditorState,
  removeLinkFromEditorState,
  CompareEditorState,
  getCompareEditorState
} from "../utils";
import { TextElementRenderer } from "../renderers";
import { SectionGroupElementsSelector } from "./SectionGroupElementsSelector";

interface Props {
  section: TemplateCanvasSection;
  textElements: ActionTemplateElementsResponse["textElement"];
  elementGroups: ActionTemplateElementGroup[];
  onChange: (section: TemplateCanvasSection) => void;
  isLoading?: boolean;
  filterOutLoopElements?: boolean;
  readOnly?: boolean;
}

export const SingleLineTemplateEditor: FC<Props> = props => {
  const {
    section: pSection,
    textElements,
    elementGroups,
    onChange,
    readOnly = false,
    isLoading = false,
    filterOutLoopElements = false
  } = props;

  const { close: onBlur, isOpen: isFocussed, open: onFocus } = useToggleState();

  const designerTemplate = useMemo<ActionTemplateElementsResponse>(
    () => ({
      sectionElement: [],
      textElement: textElements,
      elementGroup: elementGroups
    }),
    [elementGroups, textElements]
  );

  const initedRef = useRef(false);

  const defEditorState = useMemo(() => {
    const contentState = ContentState.createFromBlockArray([], Map({}));
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }, []);
  const [editorState, setEditorState] = useState<EditorState>(defEditorState);
  const saveEditorStateRef = useRef<CompareEditorState>(getCompareEditorState(editorState));

  const [section, setSection] = useState<TemplateCanvasSection>(pSection);

  useEffect(() => {
    setSection(pSection);
  }, [pSection]);

  useEffect(() => {
    if (initedRef.current) {
      /**
       * EditorState state will change everytime we select, deselect, move cursor, etc. since this information
       * is stored as part of EditorState. We don't want to call on change in all these cases. So we pick just the
       * states that are required and contribute to the sections and compare those and conditionally call onChange.
       */
      const prevSaveEditorState = saveEditorStateRef.current;
      const nextSaveEditorState = getCompareEditorState(editorState);

      const hasUpdated = !isEqual(prevSaveEditorState, nextSaveEditorState);

      if (hasUpdated) {
        const templateCanvas = getCanvasFromEditorState(editorState);
        const section = templateCanvas.section[0];
        setSection(section);
        onChange(section);

        saveEditorStateRef.current = nextSaveEditorState;
      }
    }
  }, [editorState, onChange]);

  useEffect(() => {
    if (!initedRef.current && !isLoading) {
      setEditorState(prevEditorState => {
        const templateCanvas: TemplateCanvas = {
          footer: [],
          header: [],
          section: [section]
        };
        const nextEditorState = getUpdatedEditorState(prevEditorState, templateCanvas, textElements);
        return nextEditorState;
      });
      initedRef.current = true;
    }
  }, [isLoading, section, textElements]);

  const onAddTextElement = useCallback(
    (el: ActionTemplateTextElement) => {
      setEditorState(prevEditorState => addTextElementToEditorState(prevEditorState, el, textElements));
    },
    [textElements]
  );

  const onRemoveLink = useCallback((blockKey: string, entityKey: string) => {
    setEditorState(prevEditorState => removeLinkFromEditorState(prevEditorState, blockKey, entityKey));
  }, []);

  const onEditorStateChangeInternal = useCallback((updaterFn: (editorState: EditorState) => EditorState) => {
    setEditorState(prevEditorState => {
      const nextEditorState = updaterFn(prevEditorState);
      return nextEditorState;
    });
  }, []);

  const customDecorators = useMemo<DraftDecorator[]>(
    () => [
      {
        strategy: getMatchRichTextToken(),
        component: TextElementRenderer,
        props: {
          unLink: onRemoveLink,
          onChangeReadOnly: noOp,
          onEditorStateChange: onEditorStateChangeInternal
        }
      }
    ],
    [onEditorStateChangeInternal, onRemoveLink]
  );

  const handleKeyCommand = useCallback((command: DraftEditorCommand): DraftHandleValue => {
    if (command === "split-block") {
      return "handled";
    }

    return "not-handled";
  }, []);

  const handlePastedText = useCallback<EditorProps["handlePastedText"]>((text, html, editorState, onChange) => {
    if (text?.length) {
      const newContent = Modifier.insertText(editorState.getCurrentContent(), editorState.getSelection(), text);

      onChange(EditorState.push(editorState, newContent, "insert-characters"));
    }

    return true;
  }, []);

  const placeholder = "Start typing text or choose a token";
  const elementsExist = Boolean(textElements?.length);

  const className = cx("template-editor-v2 single-line-template-editor", {
    readonly: readOnly
  });

  return (
    <div
      className={className}
      data-elements-exist={elementsExist}
      data-focussed={isFocussed && !readOnly}
    >
      {isLoading && <LoadingSpinner titleText="Fetching metadata..." />}

      {!isLoading && Boolean(editorState) && (
        <Editor
          customDecorators={customDecorators}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          handlePastedText={handlePastedText}
          handleReturn={() => false}
          onBlur={onBlur}
          onEditorStateChange={setEditorState}
          onFocus={onFocus}
          placeholder={placeholder}
          readOnly={readOnly}
          toolbarHidden
        />
      )}

      {elementsExist && !readOnly && (
        <div className="add-item-button">
          <SectionGroupElementsSelector
            designerTemplate={designerTemplate}
            filterOutLoopElements={filterOutLoopElements}
            onAddSection={noOp}
            onAddTextElement={onAddTextElement}
            onClose={onBlur}
            onOpen={onFocus}
          />
        </div>
      )}
    </div>
  );
};
