import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { IncFaIcon, IncLoadingSpinner, IncModal, IncTextArea } from "@inception/ui";
import IncButton, { IncButtonColor } from "@inception/ui/src/components/Button/Button";
import { clone, isNil } from "lodash";
import { ParameterValue, Plan, PlanActionContext, PlanNode, ToolType, chatApi } from "../../services/api/chat";
import { VerticallyCenteredRow } from "../../components";
import { logger, useInputState, useNotifications, useVerticalConfig } from "../../core";
import { PlanNodesEditor } from "./PlanNodesEditor";

interface Props {
  plan: Plan;
  show: boolean;
  setPlan: (plan: Plan) => void;
  closePlanEditorModal: () => void;
  chatId: string;
  onImplementPlan: (planId: string, paramValues: ParameterValue[], id?: string) => void;
  isFetchingPlan?: boolean;
}

export const PlanEditorModal: FC<Props> = props => {
  const { plan, show, closePlanEditorModal, setPlan, chatId, onImplementPlan, isFetchingPlan = false } = props;
  const [selectedNode, setSelectedNode] = useState<PlanNode>(null);

  const implementPlanInternal: () => void = useCallback(() => {
    onImplementPlan(plan?.id, plan?.suggestedValues, chatId);
    closePlanEditorModal();
    // isPlanImplementedRef.current = true;
  }, [chatId, closePlanEditorModal, onImplementPlan, plan]);

  useEffect(() => {
    if (plan?.nodes?.items?.[0]) {
      setSelectedNode(plan?.nodes?.items?.[0]);
    }
  }, [plan]);

  const actions = {
    primary: {
      label: "Run",
      onClick: implementPlanInternal,
      color: "primary" as IncButtonColor,
      showLoader: isFetchingPlan
    },
    secondary: {
      label: "Cancel",
      onClick: closePlanEditorModal,
      color: "secondary" as IncButtonColor,
      showLoader: isFetchingPlan
    }
  };

  const header = useMemo(
    () => (
      <VerticallyCenteredRow className="flex-gap-8">
        <IncFaIcon
          color="#F8485E"
          iconName="sparkles"
          variant="regular"
        />
        <div className="inc-text-header">Edit code for your plan</div>
      </VerticallyCenteredRow>
    ),
    []
  );
  return (
    <IncModal
      actions={actions}
      className="plan-editor-modal"
      closeOnBackdrop
      closeOnEscape
      contentClassName="plan-editor-content"
      disableFocusOnLoad
      onClose={closePlanEditorModal}
      show={show}
      size="xxlg"
      titleClassName="plan-editor-modal-header"
      titleText={header}
      withActionsBorder
      withTitleBorder
    >
      {!isFetchingPlan && (
        <div className="plan-editor-content-grid height-100">
          {
            <>
              <div className="plan-editor-nodes padding24">
                <div className="plan-editor">
                  <PlanNodesEditor
                    isEditingMode={true}
                    isFetching={false}
                    plan={plan}
                    selectedNode={selectedNode}
                    setPlan={setPlan}
                    setSelectedNode={setSelectedNode}
                  />
                </div>
              </div>
              <div className="plan-editor-area padding24">
                <Editor
                  chatId={chatId}
                  plan={plan}
                  selectedNode={selectedNode}
                  setPlan={setPlan}
                />
              </div>
            </>
          }
        </div>
      )}
      {isFetchingPlan && <IncLoadingSpinner />}
    </IncModal>
  );
};

interface EditorProps {
  setPlan: (plan: Plan) => void;
  selectedNode: PlanNode;
  chatId: string;
  plan: Plan;
}
const Editor: FC<EditorProps> = props => {
  const { selectedNode, chatId, plan, setPlan } = props;
  const [node, setNode] = useState(selectedNode);
  const { notifyError } = useNotifications();

  useEffect(() => {
    setNode(selectedNode);
  }, [selectedNode]);

  const { verticalConfig } = useVerticalConfig();
  const { useCaseId, companyName } = verticalConfig;

  const { toolSpec } = node || {};

  const { toolType } = toolSpec || {};

  const isDataAnalyst = toolType === ToolType.DATA_ANALYST_TOOL;
  const isDataFetcher = toolType === ToolType.DATA_FETCHER_TOOL;

  const toolSpecString = useMemo(() => {
    let text = "";
    if (toolSpec?.dataAnalystToolSpec?.question) {
      text = toolSpec?.dataAnalystToolSpec?.question;
    } else if (toolSpec?.dataFetcherToolSpec?.nlpQuery) {
      text = toolSpec?.dataFetcherToolSpec?.nlpQuery;
    }
    return text;
  }, [toolSpec]);

  const { inputValue, setInputValue } = useInputState(toolSpecString);

  useEffect(() => {
    setInputValue(toolSpecString);
  }, [setInputValue, toolSpecString]);

  const onToolSpecChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setInputValue(evt.target.value);
      if (isDataAnalyst) {
        const clonedNode = clone(node);
        if (clonedNode?.toolSpec?.dataAnalystToolSpec?.question) {
          clonedNode.toolSpec.dataAnalystToolSpec.question = evt.target.value;
        }
        setNode(clonedNode);
      } else if (isDataFetcher) {
        const clonedNode = clone(node);
        if (clonedNode?.toolSpec?.dataFetcherToolSpec?.nlpQuery) {
          clonedNode.toolSpec.dataFetcherToolSpec.nlpQuery = evt.target.value;
        }
        if (clonedNode?.toolSpec?.dataFetcherToolSpec?.bicycleQuery?.queryConversionResponse?.query) {
          clonedNode.toolSpec.dataFetcherToolSpec.bicycleQuery.queryConversionResponse.query = inputValue;
        }
        setNode(clonedNode);
      }
    },
    [isDataAnalyst, isDataFetcher, node, setInputValue, inputValue]
  );

  const [isSaving, setIsSaving] = useState(false);

  const onSave = useCallback(async () => {
    const clonedNode = clone(node);
    if (isDataFetcher) {
      if (clonedNode?.toolSpec?.dataFetcherToolSpec?.bicycleQuery?.queryConversionResponse?.query) {
        clonedNode.toolSpec.dataFetcherToolSpec.bicycleQuery.queryConversionResponse.query = inputValue;
      }
      setNode(clonedNode);
    }
    try {
      setIsSaving(true);
      const payload: PlanActionContext = {
        planId: plan?.id || "",
        paramInputs: plan?.paramInputs,
        suggestedValues: plan?.suggestedValues,
        planNode: clonedNode
      };
      const response = await chatApi.editPlan(chatId, useCaseId, companyName, payload);
      if (response.data) {
        const plan = response?.data?.fragment?.[0]?.plan;
        setPlan(plan);
      }
    } catch (e) {
      logger.error("Failed to edit plan node", e);
      notifyError("Failed to edit plan node");
    } finally {
      setIsSaving(false);
    }
  }, [chatId, companyName, isDataFetcher, node, notifyError, plan, setPlan, useCaseId, inputValue]);

  return (
    <div className="inc-flex-column flex-gap-4">
      {!isNil(selectedNode) && (
        <VerticallyCenteredRow className="flex-gap-8 width-100">
          <div className="inc-text-body">Command</div>
          <div className="inc-text-subtext inc-text-inactive">Any changes will reflect in the code as well</div>
        </VerticallyCenteredRow>
      )}
      {isDataAnalyst && (
        <div className="inc-text-subtext inc-flex-column flex-gap-4">
          {!isNil(toolSpec?.dataAnalystToolSpec?.question) && (
            <div className="question inc-flex-row flex-gap-8">
              <IncTextArea
                className="content-editor"
                containerClassName="inc-flex-grow"
                disabled={isSaving}
                onChange={onToolSpecChange}
                rows={30}
                value={inputValue}
              />
            </div>
          )}
        </div>
      )}
      {isDataFetcher && (
        <div className="inc-text-subtext inc-flex-column flex-gap-4">
          {!isNil(toolSpec?.dataFetcherToolSpec?.nlpQuery) && (
            <>
              <div className="nlp-query inc-flex-row flex-gap-8">
                <IncTextArea
                  className="content-editor"
                  containerClassName="inc-flex-grow"
                  disabled={isSaving}
                  onChange={onToolSpecChange}
                  rows={30}
                  value={inputValue}
                />
              </div>
            </>
          )}
        </div>
      )}
      {!isNil(selectedNode) && (
        <IncButton
          className="status-info width-fit-content marginTp12"
          color="secondary"
          label="Save"
          loading={isSaving}
          loadingText="Saving"
          onClick={onSave}
        />
      )}
    </div>
  );
};
