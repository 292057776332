import React, { ReactNode, useCallback } from "react";
import { PropertySource } from "../../../services/api/entity-mapping";
import { getSourceTypeIcon, getSourceTypeName } from "./BizSourceUtils";

type BizSourceRendererProps = {
  propertySource: PropertySource;

  // Default is true. Set this to false if you don't want to show the icon
  showIcon?: boolean;
};

const BizPropertySourceRenderer: React.FC<BizSourceRendererProps> = props => {
  const { propertySource, showIcon = true } = props;

  const getIconElem = useCallback((): ReactNode => {
    if (!propertySource || !propertySource.type) {
      return <></>;
    }

    const iconElem: ReactNode = getSourceTypeIcon(
      propertySource.type.toLowerCase(),
      propertySource.metadata?.icon,
      null,
      16
    );
    return iconElem;
  }, [propertySource]);

  const getTypeNameElem = useCallback((): ReactNode => {
    if (!propertySource || !propertySource.type) {
      return <></>;
    }

    const typeName: string = getSourceTypeName(propertySource.type.toLowerCase(), propertySource.name);

    return <>{typeName}</>;
  }, [propertySource]);

  return (
    <div style={{ display: "flex" }}>
      {showIcon ? <div style={{ marginRight: 6 }}>{getIconElem()}</div> : null}

      <div className="entity-node-source-name">{getTypeNameElem()}</div>
    </div>
  );
};

export default BizPropertySourceRenderer;
