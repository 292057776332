import React, { isValidElement } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { Options, AnnotationsOptions, AnnotationsLabelsOptions, XAxisOptions } from "highcharts";
import { render } from "react-dom";
import { getFormattedDateTime, IncToolTip, IncDateTimeFormat, generateId } from "@inception/ui";

export enum IncChartAnnotationType {
  info = "info",
  error = "error",
  normal = "normal",
  warning = "warning"
}

/**
 * xAxisValue is made generic to make sure this annotation works with otehr viz too. For timeseries this will be timestamp;
 * xAxisType is added to make formatting easier while showing tooltip. For timeseries this will be datetime.
 */

export interface IncChartAnnotation {
  enabled: boolean;
  xAxisValue: number;
  xAxisType: "datetime" | "value";
  type?: IncChartAnnotationType;
  info?: string;
  id?: string;
  element?: JSX.Element;
  offset?: {
    x?: number;
    y?: number;
  };
}

const addAnnotations = (chartOptions: Options, annotations: IncChartAnnotation[], maxValue: number) => {
  const highchartsAnnotations: AnnotationsOptions[] = chartOptions.annotations || [];

  const xAxis = chartOptions.xAxis as XAxisOptions;

  if (xAxis) {
    annotations.forEach(ann => {
      const { enabled, xAxisValue, xAxisType, id = generateId(), type, info, element, offset } = ann;

      if (enabled) {
        const tooltipVariant: "info" | "error" | "warning" | "success" =
          type === IncChartAnnotationType.normal ? "success" : type;
        const className = `${IconClassName} ${IconClassName}-${type}`;
        let tooltip = "";

        switch (xAxisType) {
          case "datetime":
            tooltip = `${getFormattedDateTime(xAxisValue, IncDateTimeFormat.full, { withSeconds: true })}\n${info}`;
            break;

          case "value":
            tooltip = `${xAxisValue}\n${info}`;
            break;

          default:
            break;
        }

        const annotationLabel: AnnotationsLabelsOptions = {
          point: {
            x: xAxisType === "datetime" ? xAxisValue / 1000 : xAxisValue,
            y: maxValue,
            xAxis: xAxis.id,
            yAxis: "yAxis1"
          },
          align: "left",
          useHTML: true,
          formatter: function () {
            if (isValidElement(element)) {
              return renderToStaticMarkup(element);
            }
            return `<div class="${AnnotationClassName}" id="${id}">
            <span class="${className}" />
          </div>
          `;
          },
          overflow: "allow",
          allowOverlap: true,
          backgroundColor: "transparent",
          padding: 0,
          shadow: false,
          borderWidth: 0,
          x: offset?.x,
          y: offset?.y,
          verticalAlign: "top"
        };

        const annotation: AnnotationsOptions = {
          draggable: "",
          labels: [annotationLabel],
          id,
          events: !tooltipVariant
            ? null
            : ({
                mouseover: (anntEv: MouseEvent) => {
                  // Get the annotations div
                  const anntDiv = (anntEv.currentTarget as HTMLDivElement).getElementsByClassName(
                    AnnotationClassName
                  )[0];
                  // Get thre react tooltip element
                  const tooltipEl = anntDiv.getElementsByClassName(TooltipClassName)[0];

                  // Add the tooltip element only if the element is not added
                  if (anntDiv && !tooltipEl) {
                    const el = anntDiv.getElementsByClassName(IconClassName)[0];
                    if (el) {
                      const ttEl = (
                        <IncToolTip
                          placement="top"
                          titleText={tooltip}
                          variant={tooltipVariant}
                        >
                          <span
                            className={TooltipClassName}
                            dangerouslySetInnerHTML={{
                              __html: anntDiv.innerHTML
                            }}
                          />
                        </IncToolTip>
                      );

                      anntDiv.removeChild(el);
                      render(ttEl, anntDiv);
                    }
                  }
                }
              } as any)
        };

        highchartsAnnotations.push(annotation);
      }
    });
  }

  chartOptions.annotations = highchartsAnnotations;
};

const AnnotationClassName = "inc-chart-annotation";
const TooltipClassName = "inc-chart-annotation--tooltip";
const IconClassName = "inc-chart-annotation--icon";

export default addAnnotations;
