import React, { FC, useMemo } from "react";
import { cloneDeep } from "lodash";
import { getInceptionTheme } from "@inception/ui";
import { css, cx } from "emotion";
import timeRangeUtils from "../../../../../utils/TimeRangeUtils";
import { TimeSeriesProperties } from "../../../TimeSeries/models/model";
import SparkLine from "../../../../../components/sparkline/SparkLine";
import { OverTagPostAgg } from "../../../../../services/api/explore";
import { Visualisations } from "../../../../../core";
import { dataTypeManager } from "../../../../../utils";
import { USFWRendererProps } from "./types";
import { useCommonRendererFunctionality } from "./common";
import { getTimeSeriesProperties, constructTimeseries } from "./TimeseriesRenderer";

export const SparkLineRenderer: FC<USFWRendererProps> = props => {
  const {
    className,
    dataType,
    aggregator,
    currencyType,
    noDataElement,
    loadingElement,
    aggregatedTags,
    displayAggregatedTags,
    properties: widgetProperties,
    timeRange,
    compareTimeRange,
    dataFetchPayload
  } = props;

  const { sparkLine: sparkLineProperties } = widgetProperties;

  const { data, fieldName, metricName, isFetching, isError, dataExists } = useCommonRendererFunctionality(props);

  const singleStatInfoSliceSpec = useMemo(() => {
    if (dataFetchPayload?.sliceSpec?.[0]) {
      const sliceSpec = cloneDeep(dataFetchPayload.sliceSpec[0]);
      const { aggregator } = (sliceSpec.postAgg as OverTagPostAgg).overTagAgg;

      sliceSpec.postAgg = {
        ...sliceSpec.postAgg,
        overTagAgg: {
          aggregator,
          tagName: []
        },
        overTimeAgg: {
          aggregator,
          timeInSeconds: timeRangeUtils.getSecondsFromMillis(timeRangeUtils.getDiffMillisFromTimeRange(timeRange))
        }
      };
      return sliceSpec;
    }
    return null;
  }, [dataFetchPayload, timeRange]);

  const singleStatProps = useMemo(
    () => ({
      ...props,
      dataFetchPayload: {
        ...props.dataFetchPayload,
        sliceSpec: [singleStatInfoSliceSpec]
      },
      visualisation: Visualisations.singleStat
    }),
    [props, singleStatInfoSliceSpec]
  );

  const {
    data: singleStatData,
    isFetching: isFetchingSingleStatData,
    isError: isErrorSingleStat,
    dataExists: isSingleStatDataExists
  } = useCommonRendererFunctionality(singleStatProps);

  const compareTimeStr = useMemo(
    () => timeRangeUtils.getCompareTimeStr(timeRange.from.valueOf(), compareTimeRange.from.valueOf()),
    [compareTimeRange.from, timeRange.from]
  );

  const series = useMemo(() => {
    const series = constructTimeseries(
      data,
      metricName,
      aggregatedTags,
      displayAggregatedTags,
      compareTimeStr,
      sparkLineProperties
    );
    series.forEach(serie => (serie.type = "area"));

    return series;
  }, [data, metricName, aggregatedTags, displayAggregatedTags, compareTimeStr, sparkLineProperties]);

  const properties = useMemo<TimeSeriesProperties>(() => {
    const seriesSize = series.length;
    const aggregatorLabel = aggregator?.label;
    return getTimeSeriesProperties(fieldName, dataType, currencyType, aggregatorLabel, seriesSize);
  }, [aggregator, currencyType, dataType, fieldName, series.length]);

  const chartTimeRange = useMemo(() => timeRangeUtils.getTimeRangeFromRaw(timeRange.raw), [timeRange.raw]);

  const singleStatValue = useMemo(() => {
    const datum = singleStatData?.[0];
    if (datum) {
      const postAggData = datum.postAggResult.data;

      const refId = Object.keys(postAggData)[0];

      const dataFrame = postAggData[refId]?.data || [];
      const val = dataFrame[0]?.fields?.[1]?.data?.[0] || null;

      return dataTypeManager.getDataTypeDescriptor(dataType)?.getFormattedValue(val, {
        numberFormatOptions: {
          currencyType,
          compact: true
        },
        percentFormatOptions: {
          precision: 2
        }
      });
    }
    return "";
  }, [currencyType, dataType, singleStatData]);

  if (isFetching || isFetchingSingleStatData) {
    return loadingElement;
  }

  if (!dataExists || isError || !isSingleStatDataExists || isErrorSingleStat) {
    return noDataElement;
  }

  const textClass = css`
    color: ${getInceptionTheme().inceptionColors.palette.BL25};
    font-size: 40px;
    line-height: 40px;
    font-weight: 600;
  `;

  const textClasses = cx(textClass, "inc-flex-self-center");

  return (
    <div className="inc-flex-column">
      <span className={textClasses}>{singleStatValue}</span>
      <SparkLine
        containerElemClass={className}
        properties={properties}
        series={series}
        timeRange={chartTimeRange}
      />
    </div>
  );
};
