import { createAction } from "@reduxjs/toolkit";
import { Conversation, ConversationEntry } from "../../services/api/chat/types";
import {
  SET_CONVERSATIONS_LIST,
  SET_SELECTED_CONVERSATION_ID,
  SET_CONVERSATION,
  ADD_TO_CONVERSATION,
  SET_CONVERSATIONS,
  TOGGLE_HISTORY,
  REPLACE_CONVERSATION
} from "./action_names";

export const setConversationsList = createAction<ConversationEntry[]>(SET_CONVERSATIONS_LIST);
export const setSelectedConversationId = createAction<string>(SET_SELECTED_CONVERSATION_ID);
export const setConversations = createAction<Conversation[]>(SET_CONVERSATIONS);
export const setConversation = createAction<Conversation>(SET_CONVERSATION);
export const addToConversation = createAction<ConversationEntry>(ADD_TO_CONVERSATION);
export const replaceConversation = createAction<ConversationEntry>(REPLACE_CONVERSATION);
export const toggleHistory = createAction<undefined>(TOGGLE_HISTORY);
