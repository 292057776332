import { IncFaIcon, IncPill, IncPillAction } from "@inception/ui";
import React, { FC, useMemo } from "react";
import { VerticallyCenteredRow } from "../..";
import { generateId } from "../../../core";
import { BizFieldPredicate, UserServiceFilterExpression } from "../../../services/api/explore";
import { getFilterLabelsFromEntityFilters, getFilterLabelsFromEventFilters } from "../../../utils";

export interface EntityAndEventFiltersProps {
  usFilterExpressions?: UserServiceFilterExpression[];
  bizFieldPredicates?: BizFieldPredicate[];

  onAddEntityFilter?: (predicateIdx: number) => void;
  onAddEventFilter?: (filExprIdx: number) => void;

  onRemoveEntityFilter?: (predicateIdx: number) => void;
  onRemoveEventFilter?: (filExprIdx: number) => void;

  lookupData?: Record<string, string>;
}

export const EntityAndEventFiltersRenderer: FC<EntityAndEventFiltersProps> = props => {
  const {
    bizFieldPredicates,
    onAddEntityFilter,
    onAddEventFilter,
    onRemoveEntityFilter,
    onRemoveEventFilter,
    usFilterExpressions,
    lookupData
  } = props;

  const eventFieldFiltersJsx = useMemo(
    () =>
      getFilterLabelsFromEventFilters(usFilterExpressions || [], lookupData || {}).map((label, idx) => {
        const key = generateId();
        const actions: IncPillAction[] = [];

        if (onAddEventFilter) {
          actions.push({
            icon: (
              <IncFaIcon
                className="display-element status-info"
                iconName="plus-circle"
              />
            ),
            onAction: () => onAddEventFilter(idx),
            tooltipText: "Add as global filter"
          });
        }

        if (onRemoveEventFilter) {
          actions.push({
            icon: (
              <IncFaIcon
                className="display-element status-danger"
                iconName="circle-xmark"
              />
            ),
            onAction: () => onRemoveEventFilter(idx),
            tooltipText: "Remove filter"
          });
        }

        return (
          <IncPill
            actions={actions}
            className="visible-on-hover marginRt12"
            key={key}
            label={label}
          />
        );
      }),
    [lookupData, onAddEventFilter, onRemoveEventFilter, usFilterExpressions]
  );

  const entityFiltersJsx = useMemo(
    () =>
      getFilterLabelsFromEntityFilters(bizFieldPredicates || []).map((label, idx) => {
        const key = generateId();
        const actions: IncPillAction[] = [];

        if (onAddEntityFilter) {
          actions.push({
            icon: (
              <IncFaIcon
                className="display-element status-info"
                iconName="plus-circle"
              />
            ),
            onAction: () => onAddEntityFilter(idx),
            tooltipText: "Add as global filter"
          });
        }

        if (onRemoveEntityFilter) {
          actions.push({
            icon: (
              <IncFaIcon
                className="display-element status-danger"
                iconName="circle-xmark"
              />
            ),
            onAction: () => onRemoveEntityFilter(idx),
            tooltipText: "Remove filter"
          });
        }

        return (
          <IncPill
            actions={actions}
            className="visible-on-hover marginRt12"
            key={key}
            label={label}
          />
        );
      }),
    [bizFieldPredicates, onAddEntityFilter, onRemoveEntityFilter]
  );

  return (
    <VerticallyCenteredRow>
      {eventFieldFiltersJsx}
      {entityFiltersJsx}
    </VerticallyCenteredRow>
  );
};
