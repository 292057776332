import { Position, MarkerType } from "react-flow-renderer";
import { intersectionBy } from "lodash";
import {
  WidgetConfigDTO,
  SliceSpec,
  UserServiceFieldSlice,
  UserServiceFieldSliceSet,
  BizDataQuery
} from "../services/api/explore";
import { convertUSFieldSliceSetToTagSlice } from "../utils/ExploreUtils";
import getChartColor from "../components/charts/colors";
import { UIBizFlowConfig, FlowNode, FlowEdge } from "./types";

export const getSliceSpec = (widgetConfigDto: WidgetConfigDTO, metricId: string): SliceSpec => {
  const sliceSpec: SliceSpec = {
    selectorSpec: {
      filters: []
    },
    sliceSet: {
      slices: []
    },
    metricId
  };

  const { metrics } = widgetConfigDto.dataDefinition;
  const slicesMatrix: UserServiceFieldSlice[][] = [];

  Object.keys(metrics).forEach(metricId => {
    const metricDef = metrics[metricId];
    if (metricDef.sourceType === "userServiceField") {
      const { userServiceFieldMetricConfig } = metricDef;
      const { sliceSets } = userServiceFieldMetricConfig;
      const lastSliceSet = sliceSets.slice(-1)[0];
      const { slices: sliceSetSlices } = lastSliceSet;
      slicesMatrix.push(sliceSetSlices);
    }
  });

  const slices = intersectionBy(...slicesMatrix, "tagName");
  const usfSliceSet: UserServiceFieldSliceSet = {
    slices
  };
  const sliceSet = convertUSFieldSliceSetToTagSlice(usfSliceSet);
  sliceSpec.sliceSet = sliceSet;

  return sliceSpec;
};

export const ACTUAL_NODE_TYPE = "actual_node";
export const PLACEHOLDER_NODE_TYPE = "placeholder_node";
export const ACTUAL_EDGE_TYPE = "actual_edge";
export const PLACEHOLDER_EDGE_TYPE = "placeholder_edge";

export const ACTUAL_NODE_WIDTH = 240;
export const ACTUAL_NODE_HEIGHT = 80;
const PLACEHOLDER_NODE_WIDTH = 10;
const PLACEHOLDER_NODE_HEIGHT = 10;
const EDGE_GAP = 50;
const NODE_GAP = 30;

export const getNodesAndEdges = (
  flowConfig: UIBizFlowConfig,
  onEditNode: (nodeId: string) => void,
  onDeleteNode: (nodeId: string) => void,
  onEditEdge: (edgeId: string) => void,
  onDeleteEdge: (edgeId: string) => void,
  onEdgeQueryUpdate?: (edgeId: string, bizDataQuery: BizDataQuery) => void
) => {
  const nodes: FlowNode[] = [];
  const edges: FlowEdge[] = [];

  const { nodes: configNodes = [], connections: configConnections = [], properties } = flowConfig || {};

  const { entityFilters = [], eventFieldFilters = [], errorsOnly = false } = properties || {};

  const numEdges = configConnections.length;

  const sequenceNodeIds = new Set<string>();

  let x = 0;

  configNodes.forEach((node, idx) => {
    if (!node.color) {
      node.color = getChartColor(idx);
    }

    const { id, label, bizDataQuery, shape, infoText, renderType = "sequence", icon, color } = node;

    const isSequenceNode = renderType === "sequence";

    const onEdit = onEditNode ? () => onEditNode(id) : null;
    const onDelete = onDeleteNode ? () => onDeleteNode(id) : null;

    if (isSequenceNode) {
      sequenceNodeIds.add(id);

      const topNodeId = [id, "top"].join("_");
      const bottomNodeId = [id, "bottom"].join("_");

      let y = 0;
      let prevId = topNodeId;

      // Top-most node
      nodes.push({
        id: prevId,
        data: {
          label,
          bizDataQuery,
          shape,
          entityFilters,
          eventFieldFilters,
          infoText,
          onDelete,
          onEdit,
          renderType,
          icon,
          color,
          errorsOnly
        },
        position: {
          x,
          y
        },
        width: ACTUAL_NODE_WIDTH,
        height: ACTUAL_NODE_HEIGHT,
        sourcePosition: Position.Bottom,
        type: ACTUAL_NODE_TYPE
      });

      y += ACTUAL_NODE_HEIGHT;

      for (let nIdx = 0; nIdx < numEdges; nIdx++) {
        y += EDGE_GAP;
        const currId = [id, nIdx].join("_");

        // Placeholder nodes for joining edges
        nodes.push({
          id: currId,
          data: {
            label,
            renderType,
            color
          },
          position: {
            x: x + 0.5 * ACTUAL_NODE_WIDTH,
            y
          },
          width: PLACEHOLDER_NODE_WIDTH,
          height: PLACEHOLDER_NODE_HEIGHT,
          sourcePosition: Position.Bottom,
          targetPosition: Position.Top,
          type: PLACEHOLDER_NODE_TYPE
        });

        // Placeholder edges to achieve a straight line
        edges.push({
          id: [prevId, "edge", currId].join("_"),
          data: {
            label: "",
            renderType
          },
          source: prevId,
          target: currId,
          type: PLACEHOLDER_EDGE_TYPE
        });

        prevId = currId;
      }

      y += EDGE_GAP;

      // Bottom-most node
      nodes.push({
        id: bottomNodeId,
        data: {
          label,
          bizDataQuery,
          shape,
          entityFilters,
          eventFieldFilters,
          infoText,
          onDelete,
          onEdit,
          renderType,
          icon,
          color,
          errorsOnly
        },
        position: {
          x,
          y
        },
        width: ACTUAL_NODE_WIDTH,
        height: ACTUAL_NODE_HEIGHT,
        targetPosition: Position.Top,
        type: ACTUAL_NODE_TYPE
      });

      // Placeholder edge to achieve a straight line
      edges.push({
        id: [prevId, "edge", bottomNodeId].join("_"),
        data: {
          label: "",
          renderType
        },
        source: prevId,
        target: bottomNodeId,
        type: PLACEHOLDER_EDGE_TYPE
      });
    } else {
      const yNum = sequenceNodeIds.size
        ? NODE_GAP * (numEdges + 1) + ACTUAL_NODE_HEIGHT * 2
        : NODE_GAP * (numEdges + 1);
      const y = Math.ceil(yNum * 0.5);

      nodes.push({
        id,
        data: {
          label,
          bizDataQuery,
          shape,
          entityFilters,
          eventFieldFilters,
          infoText,
          onDelete,
          onEdit,
          renderType,
          icon,
          color,
          errorsOnly
        },
        position: {
          x,
          y
        },
        width: ACTUAL_NODE_WIDTH,
        height: ACTUAL_NODE_HEIGHT,
        targetPosition: Position.Bottom,
        sourcePosition: Position.Top,
        type: ACTUAL_NODE_TYPE
      });
    }

    x += ACTUAL_NODE_WIDTH + NODE_GAP;
  });

  configConnections.forEach((connection, idx) => {
    const { label, bizDataQuery, targetNodeId, sourceNodeId, id, color, shape } = connection;

    const isSourceSequenceNode = sequenceNodeIds.has(sourceNodeId);
    const isTargetSequenceNode = sequenceNodeIds.has(targetNodeId);

    const isSequentialEdge = isSourceSequenceNode && isTargetSequenceNode;
    const renderType = isSequentialEdge ? "sequence" : "single";

    let source = sourceNodeId;
    let target = targetNodeId;

    if (isSourceSequenceNode && isTargetSequenceNode) {
      source = [sourceNodeId, idx].join("_");
      target = [targetNodeId, idx].join("_");
    } else if (isSourceSequenceNode) {
      source = [sourceNodeId, "bottom"].join("_");
    } else if (isTargetSequenceNode) {
      target = [targetNodeId, "top"].join("_");
    }

    const onEdit = onEditEdge ? () => onEditEdge(id) : null;
    const onDelete = onDeleteEdge ? () => onDeleteEdge(id) : null;

    edges.push({
      id: [source, "edge", target].join("_"),
      data: {
        edgeId: id,
        onEdgeQueryUpdate,
        label,
        bizDataQuery,
        entityFilters,
        eventFieldFilters,
        onEdit,
        onDelete,
        renderType,
        color,
        shape,
        errorsOnly
      },
      source,
      target,
      type: ACTUAL_EDGE_TYPE,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        strokeWidth: 2,
        height: 12,
        width: 12,
        orient: "0"
      },
      markerStart: {
        type: MarkerType.ArrowClosed,
        strokeWidth: 2,
        height: 12,
        width: 12,
        orient: "180"
      }
    });
  });

  return {
    nodes,
    edges
  };
};
