import React, { FC, useCallback, useState } from "react";
import { IncButton, IncFaIcon, IncLoadingSpinner, IncSmartText, getInceptionTheme } from "@inception/ui";
import { useFetchToolSpec } from "../../../../../../platform/services/api/operationalise";
import { FieldPickerContextDTO, ImpactedWidget, Workflow } from "../../../../../../platform/services/api/explore";
import { JSonConfigEditorModel, VerticallyCenteredRow } from "../../../../../../platform/components";
import { DrilldownTypes } from "../../../../../../platform/services/api/triage-v2";
import { logger, useNotifications, useRefState, useToggleState } from "../../../../../../platform/core";
import { featureFlagService } from "../../../../../../platform/services/feature-flags";
import DrillDownStepList from "./DrillDownStepList";

type Props = {
  pickerContextDto: FieldPickerContextDTO;
  workflow: Workflow;
  onChangeWorkflow: (workFlow: Workflow) => void;
  isFetching: boolean;
  isError: boolean;

  opId?: string;
  impactedWidgets?: ImpactedWidget[];
  setError?: (stepId: string, errors: string[]) => void;
  getDefaultWorkflowToOverride?: () => Promise<Workflow>;
};

export const WorkflowEditor: FC<Props> = props => {
  const {
    opId,
    impactedWidgets,
    pickerContextDto,
    isFetching,
    isError,
    onChangeWorkflow,
    workflow,
    getDefaultWorkflowToOverride,
    setError
  } = props;

  const {
    data: toolSpec,
    isError: isToolSpecError,
    isFetching: fetchingToolSpec,
    error: toolSpecError
  } = useFetchToolSpec(opId);

  const workflowRef = useRefState(workflow);

  const { workflowDef } = workflow || {};
  const { causeGraphWorkFlow } = workflowDef || {};
  const { causeGraph } = causeGraphWorkFlow || {};
  const { steps: drillDownSteps = [] } = causeGraph || {};

  const onChangeSteps = useCallback(
    (steps: DrilldownTypes.DrillDownStep[]) => {
      if (workflowRef.current) {
        const workflow = workflowRef.current;
        onChangeWorkflow({
          ...workflow,
          workflowDef: {
            ...workflow.workflowDef,
            causeGraphWorkFlow: {
              ...workflow.workflowDef?.causeGraphWorkFlow,
              causeGraph: {
                ...workflow.workflowDef?.causeGraphWorkFlow?.causeGraph,
                steps
              }
            }
          }
        });
      }
    },
    [onChangeWorkflow, workflowRef]
  );

  const { notifyError, notifySuccess } = useNotifications();
  const { close: stopOverriding, isOpen: isOverridingInProgress, open: startOverriding } = useToggleState();

  const onOverrideWorkflow = useCallback(async () => {
    try {
      if (getDefaultWorkflowToOverride) {
        startOverriding();
        const workflowConfig = await getDefaultWorkflowToOverride();
        if (workflowConfig) {
          notifySuccess("Configuration overridden successfully");
          onChangeWorkflow(workflowConfig);
        } else {
          notifyError("No configuration found to override");
          logger.warn("WorkflowEditor", "No workflow found");
        }
      }
    } catch (error) {
      notifyError("Error overriding configuration");
      logger.error("WorkflowEditor", "Error overriding workflow", error);
    } finally {
      stopOverriding();
    }
  }, [getDefaultWorkflowToOverride, notifyError, notifySuccess, onChangeWorkflow, startOverriding, stopOverriding]);

  const shouldOverrideShowWorkflow = Boolean(getDefaultWorkflowToOverride);

  const isDebugMode = featureFlagService.isDebugMode();

  const { isOpen: isWorkflowConfigOpen, open: openWorkflowConfig, close: closeWorkflowConfig } = useToggleState();

  const [isWorkflowListVisible, setIsWorkflowListVisible] = useState(true);

  const onSaveConfig = useCallback(
    (workflow: Workflow) => {
      setIsWorkflowListVisible(false);
      onChangeWorkflow(workflow);
      setTimeout(() => {
        setIsWorkflowListVisible(true);
      }, 0);
    },
    [onChangeWorkflow]
  );

  return (
    <div className="width-100 inc-flex-column">
      {isFetching && (
        <IncLoadingSpinner
          className="marginTp24"
          label="Fetching workflow"
        />
      )}
      {isWorkflowConfigOpen && (
        <JSonConfigEditorModel<Workflow>
          isOpen
          jsonValue={workflow}
          onClose={closeWorkflowConfig}
          onSave={onSaveConfig}
          title={"Edit Workflow Configuration"}
        />
      )}
      {!isFetching && (
        <>
          {isError && (
            <VerticallyCenteredRow className="marginTp24 flex-gap-12 inc-flex-center">
              <IncFaIcon
                color={getInceptionTheme().inceptionColors.accentRed}
                fontSize={20}
                iconName="warning"
              />
              <IncSmartText text={`Error Fetching Workflow`} />
            </VerticallyCenteredRow>
          )}
          {!isError && (
            <>
              {!workflow && (
                <VerticallyCenteredRow className="marginTp24 flex-gap-12 inc-flex-center">
                  <IncFaIcon
                    color={getInceptionTheme().inceptionColors.accentRed}
                    fontSize={20}
                    iconName="warning"
                  />
                  <IncSmartText text={"Workflow not available"} />
                </VerticallyCenteredRow>
              )}
              {!!workflow && isWorkflowListVisible && (
                <DrillDownStepList
                  drillDownSteps={drillDownSteps}
                  impactedWidgets={impactedWidgets}
                  isOverridingInProgress={isOverridingInProgress}
                  isToolSpecError={isToolSpecError}
                  isToolSpecFetching={fetchingToolSpec}
                  onChangeSteps={onChangeSteps}
                  onOverride={onOverrideWorkflow}
                  pickerContextDto={pickerContextDto}
                  setError={setError}
                  shouldShowOverrideWorkflow={shouldOverrideShowWorkflow}
                  toolSpec={toolSpec}
                  toolSpecError={toolSpecError}
                >
                  {isDebugMode && !!workflow && (
                    <IncButton
                      color="link"
                      onClick={openWorkflowConfig}
                    >
                      View Configuration
                    </IncButton>
                  )}
                </DrillDownStepList>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
