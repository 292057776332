import { TimeRange, Visualisations } from "../../../../core";
import {
  CohortEntityFilter,
  DemoDataParams,
  MetricUserServiceFilters,
  OverTagPostAgg,
  WidgetResponseDTO
} from "../../../../services/api/explore";
import { CatalogWidgetFetchDataPayload } from "../types";
import { useEntityDataQuery } from "../../../../biz-entity";

type Props = CatalogWidgetFetchDataPayload & {
  widgetResponseDTO: WidgetResponseDTO;
  entityType: string;
  cohortFilters: CohortEntityFilter[];
  entityFilters: CohortEntityFilter[];
  timeRange: TimeRange;
  id: string;
  userServiceId: string;
  limit: number;
  eventFilters?: MetricUserServiceFilters;
  includeQueryConfig?: boolean;
  dsIntervalStr?: string;
  demoParams?: DemoDataParams;
  visualisation?: Visualisations;
};

export const useFetchCatalogWidgetData = (props: Props) => {
  const {
    entityType,
    timeRange,
    cohortFilters,
    entityFilters,
    mode,
    sliceSpec,
    widgetResponseDTO: widgetResponse,
    id,
    userServiceId,
    eventFilters,
    limit,
    includeQueryConfig,
    dsIntervalStr,
    demoParams,
    visualisation
  } = props;

  const { widgetConfig, widgetId } = widgetResponse || {};
  const tagNames = (sliceSpec || []).map(x => (x?.postAgg as OverTagPostAgg)?.overTagAgg?.tagName.join(",")).join(",");

  const queryKeySuffix = `catalog-widget-${id}-${userServiceId}-${tagNames}-${visualisation ? visualisation : ""}`;

  const entityId = entityType ? null : userServiceId;

  return useEntityDataQuery({
    cohortFilters,
    entityFilters,
    entityId,
    entityType,
    id: queryKeySuffix,
    widgetId,
    widgetConfig,
    excludeWidgetData: true,
    mode,
    sliceSpec,
    timeRange,
    version: 1,
    eventFilters,
    compareQuery: null,
    compareSelection: null,
    limit,
    skipEnabledDataDefCheck: true,
    includeQueryConfig,
    dsIntervalStr,
    demoParams
  });
};
