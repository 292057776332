import { useState, useMemo, useRef, useCallback } from "react";
import Axios, { CancelTokenSource } from "axios";
import { BizEntityDataResult, bizEntityDataTransformer } from "../../../../dashboard/widgets/utils";
import {
  ImpactedWidget,
  ImpactWidgetDataRequest,
  ImpactWidgetDataPostAgg,
  alertApiService,
  ForecastSpec
} from "../../../../services/api/explore";

export const useFetchImpactedWidgetDataForIncidents = (
  impactedWidget: ImpactedWidget,
  incidentIds: string[],
  opConfigId: string,
  groupBy: string[],
  entityFilters: ImpactWidgetDataRequest["entityFilters"],
  userServiceFilters: ImpactWidgetDataRequest["metricUserServiceFilters"],
  groupTags: string[],
  overTimeAgg?: ImpactWidgetDataPostAgg,
  forecastSpec?: ForecastSpec,
  generateDemoData?: boolean
) => {
  const initResult = useMemo<Result>(
    () => getBaseState(incidentIds, groupBy, groupTags),
    [groupBy, groupTags, incidentIds]
  );

  const cancelTokenSourceRef = useRef<CancelTokenSource>(Axios.CancelToken.source());

  const [resultsByIncidentId, setResultsByIncidentId] = useState<Result>(initResult);

  const beforeRefetch = useCallback(() => {
    setResultsByIncidentId(getBaseState(incidentIds, groupBy, groupTags));

    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel();
    }

    const cancelTokenSource = Axios.CancelToken.source();
    const cancelToken = cancelTokenSource.token;

    cancelTokenSourceRef.current = cancelTokenSource;

    return cancelToken;
  }, [groupBy, groupTags, incidentIds]);

  const refetch = useCallback(async () => {
    const cancelToken = beforeRefetch();

    const promisePayloads: PromisePayload[] = getPromisePayloads(
      impactedWidget,
      incidentIds,
      opConfigId,
      groupBy,
      entityFilters,
      userServiceFilters,
      groupTags,
      overTimeAgg,
      forecastSpec,
      generateDemoData
    );

    const promises = promisePayloads.map(async payloadInfo => {
      const { incidentId, payload, resultEntryKey } = payloadInfo;

      let bizEntityData: BizEntityDataResult;
      let isError = false;
      let errorMsg = "";
      let isFetching = false;

      try {
        const { data, error, message, cancelled } = await alertApiService.getImpactedWidgetData(
          payload,
          null,
          null,
          null,
          cancelToken
        );
        if (!cancelled) {
          isError = error;
          errorMsg = error ? message.toString() : "";

          if (data && !isError) {
            const transformed = bizEntityDataTransformer([
              {
                data: [data]
              }
            ]);
            const { data: transformedDatum = [] } = transformed[0];
            bizEntityData = transformedDatum?.[0];
          }
        } else {
          isFetching = true;
        }
      } catch (error) {
        isError = true;
        errorMsg = error.toString();
      } finally {
        setResultsByIncidentId(prev => ({
          ...prev,
          [incidentId]: {
            ...prev[incidentId],
            [resultEntryKey]: {
              data: bizEntityData,
              error: errorMsg,
              isError,
              isFetching
            }
          }
        }));
      }
    });

    await Promise.allSettled(promises);
  }, [
    beforeRefetch,
    entityFilters,
    forecastSpec,
    generateDemoData,
    groupBy,
    groupTags,
    impactedWidget,
    incidentIds,
    opConfigId,
    overTimeAgg,
    userServiceFilters
  ]);

  return {
    resultsByIncidentId,
    refetch
  };
};

type Result = Record<string, ImpactedWidgetsDataEntryMap>;
type PromisePayload = {
  incidentId: string;
  payload: ImpactWidgetDataRequest;
  resultEntryKey: string;
};

export type ImpactedWidgetsDataEntryMap = Record<string, ImpactedWidgetsDataEntry>;

export type ImpactedWidgetsDataEntry = {
  isFetching: boolean;
  isError: boolean;
  error: string;
  data: BizEntityDataResult;
};

const getBaseState = (incidentIds: string[], groupBy: string[], groupTags: string[]) => {
  const groupByStr = getGroupKey(groupBy);
  const groupTagsStr = getGroupKey(groupTags);

  const initResult: Result = {};

  incidentIds.forEach(id => {
    initResult[id] = {
      [groupByStr]: {
        data: null,
        error: null,
        isError: false,
        isFetching: true
      },
      [groupTagsStr]: {
        data: null,
        error: null,
        isError: false,
        isFetching: true
      }
    };
  });

  return initResult;
};

const getPromisePayloads = (
  impactedWidget: ImpactedWidget,
  incidentIds: string[],
  opConfigId: string,
  groupBy: string[],
  entityFilters: ImpactWidgetDataRequest["entityFilters"],
  userServiceFilters: ImpactWidgetDataRequest["metricUserServiceFilters"],
  groupTags: string[],
  overTimeAgg?: ImpactWidgetDataPostAgg,
  forecastSpec?: ForecastSpec,
  generateDemoData?: boolean
) => {
  const promisePayloads: PromisePayload[] = [];

  const groupByKey = getGroupKey(groupBy);
  const groupTagsKey = getGroupKey(groupTags);

  const groupBysUnequal = groupByKey !== groupTagsKey;

  incidentIds.forEach(incidentId => {
    const payload: ImpactWidgetDataRequest = {
      entityFilters: entityFilters || [],
      groupBy,
      impactedWidget,
      incidentId,
      opConfigId,
      metricUserServiceFilters: userServiceFilters || {},
      bins: overTimeAgg?.bins,
      timeDuration: overTimeAgg?.timeDuration,
      sortSpec: {
        sortBy: "current",
        limitSpec: {
          function: "top",
          limit: 1000
        }
      },
      forecastSpec,
      generateDemoData
    };

    promisePayloads.push({
      resultEntryKey: groupByKey,
      incidentId,
      payload
    });

    if (groupBysUnequal) {
      promisePayloads.push({
        resultEntryKey: groupTagsKey,
        incidentId,
        payload: {
          ...payload,
          groupBy: [...groupTags]
        }
      });
    }
  });

  return promisePayloads;
};

export const getGroupKey = (arr: string[]) => arr.join("-");
