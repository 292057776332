import { clone } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IncSelect, IncSelectOption } from "@inception/ui";
import { VerticallyCenteredRow } from "../../../../../../components";
import { StandAloneWidget } from "../../../../../../components/StandaloneWidget";
import { RawTimeRange, useTimeRange, Visualisations } from "../../../../../../core";
import { ENTITY_TAG, getPromSanitizedName, getTagNameFromFieldName, noOp } from "../../../../../../utils";
import { CatalogVizSwitcher } from "../VizSwitcher";
import { CatalogMaximizeTabProps } from "../types";
import { CatalogWidgetUtils } from "../../../CatalogWidgetUtils";
import { TimeRangeController } from "../../../../../../components/time-range";
import timeRangeUtils from "../../../../../../utils/TimeRangeUtils";

export const VisualizationTab: FC<CatalogMaximizeTabProps> = props => {
  const {
    widgetImpl,
    onWidgetImplChange,
    widgetResponseDTO,
    dbImpl,
    variableSrvMap,
    readOnly,
    eventTypeToFieldsMap,
    hideSliceSelection = false
  } = props;

  const sliceOptions = useMemo<IncSelectOption[]>(() => {
    const { querySchema: qsObj, widgetConfig } = widgetResponseDTO || {};

    const querySchema = qsObj?.querySchema || [];
    const querySchemaEntry = querySchema[0];
    const slices = querySchemaEntry?.sliceSet?.slices || [];

    const entityTypeName = widgetConfig?.bizEntityType || "";

    return slices.map(slice => {
      //check if the tagname is ENTITY_TAG, then get the tag name from the fieldname else set to the provided tag name.
      const sLabel =
        slice.tagName === ENTITY_TAG
          ? entityTypeName || getPromSanitizedName(getTagNameFromFieldName(slice.fieldName || ""))
          : slice.tagName;
      return {
        label: sLabel,
        value: slice.tagName
      };
    });
  }, [widgetResponseDTO]);

  const { timeRange: widgetTimeRange, properties, queryConfig } = widgetImpl;

  const { timeRange: globalTimeRange, compareTimeRange } = useTimeRange();

  const [timeRange, setTimeRange] = useState(widgetTimeRange || globalTimeRange);
  useEffect(() => {
    setTimeRange(widgetTimeRange || globalTimeRange);
  }, [globalTimeRange, widgetTimeRange]);

  const selectedSliceOptions = useMemo<IncSelectOption[]>(() => {
    const aggregatedTags = widgetImpl.properties.aggregateTags || [];
    return sliceOptions.filter(slice => aggregatedTags.includes(slice.value));
  }, [sliceOptions, widgetImpl.properties.aggregateTags]);

  const onSliceChange = useCallback(
    (sliceOpts: readonly IncSelectOption[]) => {
      const aggregateTags = sliceOpts.map(slice => slice.value);

      onWidgetImplChange(widgetImpl => {
        const newModel = clone(widgetImpl);
        newModel.properties.aggregateTags = aggregateTags;
        return newModel;
      });
    },
    [onWidgetImplChange]
  );

  const onWidgetUpdate = useCallback(() => {
    onWidgetImplChange(prev => prev);
  }, [onWidgetImplChange]);

  const { visualisation } = properties || {};

  const vizOptions = useMemo(() => CatalogWidgetUtils.getVisualisations(queryConfig), [queryConfig]);
  const onVisualisationChange = useCallback(
    (visualisation: Visualisations) => {
      onWidgetImplChange(widgetImpl => {
        const newModel = clone(widgetImpl);
        newModel.properties.visualisation = visualisation;
        return newModel;
      });
    },
    [onWidgetImplChange]
  );

  const onTimeRangeChange = useCallback((rawTimeRange: RawTimeRange) => {
    const timeRange = timeRangeUtils.getTimeRangeFromRaw(rawTimeRange);
    setTimeRange(timeRange);
  }, []);

  return (
    <div className="visualization-tab-content">
      <VerticallyCenteredRow className="panel-toolbar flex-gap-16">
        {!hideSliceSelection && (
          <VerticallyCenteredRow>
            {sliceOptions?.length > 0 && (
              <IncSelect
                alignment="row"
                isDisabled={readOnly}
                isMulti
                label="Breakdown By Dimensions"
                onChange={onSliceChange}
                options={sliceOptions}
                placeholder="Select Dimensions"
                value={selectedSliceOptions}
                wrapperClass="aggregate-tag-selector"
              />
            )}
          </VerticallyCenteredRow>
        )}
      </VerticallyCenteredRow>

      <div className="inc-flex-column flex-gap-12 height-100 width-100 viz-area">
        <VerticallyCenteredRow className="inc-text-subtext-medium inc-text-inactive flex-gap-6">
          <CatalogVizSwitcher
            onVisualisationChange={onVisualisationChange}
            visualisation={visualisation}
            vizOptions={vizOptions}
          />

          <VerticallyCenteredRow className="inc-text-subtext-medium inc-text-inactive flex-gap-6 marginLtAuto">
            Preview for
            <TimeRangeController
              className="inc-text-subtext-medium"
              compareTimeRange={compareTimeRange}
              disableRefresh
              disableTooltip
              setCompareTimeRange={noOp}
              setTimeRange={onTimeRangeChange}
              showTimeRangeSelector
              size="small"
              timeRange={timeRange}
            />
          </VerticallyCenteredRow>
        </VerticallyCenteredRow>

        <StandAloneWidget
          compareTimeRange={compareTimeRange}
          dbImpl={dbImpl}
          eventTypeToFieldsMap={eventTypeToFieldsMap}
          onUpdate={onWidgetUpdate}
          timeRange={timeRange}
          variableSrvMap={variableSrvMap}
          widget={widgetImpl}
        />
      </div>
    </div>
  );
};
