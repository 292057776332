import React, { FC, useMemo } from "react";
import { IncButton, IncFaIcon, IncSmartText, IncToolTip } from "@inception/ui";
import { OpBizDataQuery } from "../../services/api/operationalise";
import { BizDataQuery } from "../../services/api/explore";
import { VerticallyCenteredRow } from "../../components";
import KpiEditorWrapper from "./KpiEditorWrapper";
import { validateAnalysisKPI } from "./context";

type Props = {
  kpiRecordMap: Record<string, OpBizDataQuery>;
  addKpi: () => void;
  cloneKpi: (kpiToCloneId: string) => void;
  setKpiName: (key: string, name: string) => void;
  setKpiQuery: (key: string, bizDataQuery: BizDataQuery) => void;
  deleteKpi: (kpiToDeleteId: string) => void;
  title: string;
  eventTypeId: string;
  isFetchingEventTypeList: boolean;
};

const KPIEntiresEditor: FC<Props> = props => {
  const {
    addKpi,
    deleteKpi,
    kpiRecordMap,
    setKpiName,
    setKpiQuery,
    title,
    eventTypeId,
    isFetchingEventTypeList,
    cloneKpi
  } = props;
  const errors = useMemo(() => validateAnalysisKPI(kpiRecordMap), [kpiRecordMap]);
  const kpiEntriesJsx = useMemo(
    () =>
      Object.keys(kpiRecordMap).map(key => {
        const kpi = kpiRecordMap[key];
        const onDelete = () => deleteKpi(key);
        const onClone = () => cloneKpi(key);
        const onChangeName = (name: string) => setKpiName(key, name);
        const onChangeBizDataQuery = (bizDataQuery: BizDataQuery) => setKpiQuery(key, bizDataQuery);
        return (
          <KpiEditorWrapper
            eventTypeId={eventTypeId}
            id={key}
            key={key}
            onChangeBizDataQuery={onChangeBizDataQuery}
            onChangeName={onChangeName}
            onClone={onClone}
            onDelete={onDelete}
            opBizDataQuery={kpi}
          />
        );
      }),
    [cloneKpi, deleteKpi, eventTypeId, kpiRecordMap, setKpiName, setKpiQuery]
  );
  const isValid = !Object.keys(errors).length;

  const errorElement = useMemo(() => {
    const jsxList: JSX.Element[] = [];
    Object.keys(errors).forEach((title, i) => {
      const jsx = (
        <span
          className="inc-flex-row"
          key={i}
        >
          {title}: {errors[title]}
        </span>
      );
      jsxList.push(jsx);
    });
    if (jsxList.length) {
      return <div className="inc-flex-column padding8 flex-gap-8">{jsxList}</div>;
    }
    return <></>;
  }, [errors]);

  return (
    <div className="analysis-editor--kpi-editor">
      <VerticallyCenteredRow className="flex-gap-12">
        <IncSmartText text={title} />
        {!isValid && (
          <IncToolTip
            showArrow
            titleElement={errorElement}
            variant="error"
          >
            <IncFaIcon
              className="status-danger inc-cursor-pointer"
              iconName="warning"
            />
          </IncToolTip>
        )}
        <IncButton
          className="marginLtAuto"
          color="secondary-blue"
          iconName="plus"
          loading={isFetchingEventTypeList}
          onClick={addKpi}
        >
          Add New
        </IncButton>
      </VerticallyCenteredRow>
      {kpiEntriesJsx}
    </div>
  );
};

export default KPIEntiresEditor;
