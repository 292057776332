import { cx } from "emotion";
import React, { useCallback, useMemo, useState, useRef, useEffect } from "react";
import { defaults, pick } from "lodash";
import { BaseWidgetActionHandler } from "../../BaseWidgetActions";
import { widgetRegistry } from "../WidgetRegistry";
import { BaseWidgetProps, WidgetExtProps, WidgetCustomAction } from "../types";
import { useIsWidgetVisible } from "../useIsWidgetVisible";
import { VerticallyCenteredRow } from "../../../components";
import { BaseWidgetStateElements } from "./BaseWidgetStateElements";
import { WidgetInfoCorner } from "./WidgetInfoCorner";

interface Props extends BaseWidgetProps {
  header: React.ReactNode;
  headerRef: React.RefObject<HTMLDivElement>;
  hasSubTitle?: boolean;

  setCustomActions?: (actions: WidgetCustomAction[]) => void;
  onWidgetPropsChange?: (props: WidgetExtProps) => void;
  onMaximizeWithAddlContext?: (addlContext: Record<string, any>) => void;
}

const BaseWidget: React.FC<Props> = props => {
  const widgetContentRef = useRef<HTMLDivElement>(null);
  const widgetRendered = useRef(false);
  const [error, setError] = useState<string>("");
  const [warning, setWarning] = useState<string>("");

  const [containerBackground, setContainerBackground] = useState<string>(null);

  const { widget, onAction: pOnAction, header, headerRef, onWidgetPropsChange, hasSubTitle = false } = props;

  const isInView = useIsWidgetVisible(widgetContentRef);
  const shouldRenderWidgetContent = isInView || widgetRendered.current;

  useEffect(() => {
    widgetRendered.current = widgetRendered.current || shouldRenderWidgetContent;
  }, [shouldRenderWidgetContent]);

  const onAction: BaseWidgetActionHandler = useCallback(
    action => {
      switch (action.type) {
        case "onError":
          setError(action.error);
          break;

        case "onWarning":
          setWarning(action.warning);
          break;

        case "backgroundColor":
          setContainerBackground(action.color);
          break;

        default:
          break;
      }
      if (pOnAction) {
        pOnAction(action);
      }
    },
    [pOnAction]
  );

  const { type, properties, style, options, meta } = widget;

  const widgetConf = widgetRegistry.getPropsByWidgetId(type);
  const WidgetComponent = widgetConf?.component || null;

  const backgroundCss: React.CSSProperties = useMemo(
    () =>
      style || {
        background: !properties?.transparent ? containerBackground : "transparent"
      },
    [containerBackground, properties, style]
  );

  // Based on the background, we need to change the text color to white or black for legibility
  if (backgroundCss.background) {
    //backgroundCss.color = getContrastYIQ(backgroundCss.background as string);
  }

  const { description = "", borderLess = false, transparent = false, viewState = "" } = properties || {};

  const { noPadding = false } = options || {};
  const { hideHeader = false } = meta || {};

  const extProps: Partial<WidgetExtProps> = useMemo(
    () => ({
      ...BaseWidgetStateElements,
      headerRef,
      onAction
    }),
    [headerRef, onAction]
  );

  const widgetPickProps: Partial<WidgetExtProps> = useMemo(() => {
    const pickProps: Array<keyof Props> = [
      "variableSrvMap",
      "timeRange",
      "edit",
      "compare",
      "widget",
      "compareTimeRange",
      "setCustomActions",
      "dbImpl",
      "onTriage",
      "onVariablesUpdate",
      "onVariablesValueUpdate",
      "onUpdateCohortId",
      "onMaximizeWithAddlContext",
      "variableLoadingStateMap",
      "eventTypeToFieldsMap",
      "onAddAdhocEntityFilter",
      "onAddAdhocEventFilter",
      "onMaximize",
      "onUpdate",
      "isStandalone",
      "customActions"
    ];
    return pick(props, pickProps);
  }, [props]);

  const widgetProps = useMemo(
    () => defaults({}, widgetPickProps, extProps) as WidgetExtProps,
    [extProps, widgetPickProps]
  );
  useMemo(() => {
    if (onWidgetPropsChange) {
      onWidgetPropsChange(widgetProps);
    }
  }, [onWidgetPropsChange, widgetProps]);

  const widgetTypeClassName = useMemo(() => `${type}-container`, [type]);
  const className = useMemo(
    () =>
      cx("widget-container", widgetTypeClassName, viewState, {
        "borderless no-shadow": borderLess,
        transparent: transparent,
        "no-padding": noPadding,
        "no-header": hideHeader,
        "with-subtitle": hasSubTitle
      }),
    [widgetTypeClassName, viewState, borderLess, transparent, noPadding, hideHeader, hasSubTitle]
  );

  return (
    <div
      className={className}
      style={backgroundCss}
    >
      {error && (
        <WidgetInfoCorner
          infoText={error}
          variant="error"
        />
      )}
      {warning && (
        <WidgetInfoCorner
          infoText={warning}
          variant="warning"
        />
      )}
      {description && (
        <WidgetInfoCorner
          infoText={description}
          variant="info"
        />
      )}
      {!meta.hideHeader && header}
      <div
        className="widget-content"
        ref={widgetContentRef}
      >
        {shouldRenderWidgetContent && (
          <>
            {Boolean(WidgetComponent) && <WidgetComponent {...widgetProps} />}
            {!WidgetComponent && (
              <VerticallyCenteredRow className="height-100 width-100 inc-flex-center">
                Widget not found
              </VerticallyCenteredRow>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const getContrastYIQ = (hexcolor: string): string => {
  hexcolor = hexcolor.replace("#", "");
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#222" : "#FFF";
};

export default BaseWidget;
