import { useMemo, useCallback } from "react";
import { InitialState, useDataFetch, FetchFn, FetchCallBackResult } from "../../core";
import { ConversationEntry, chatApi } from "../../services/api/chat";

export const useFetchConversation = (chatId: string, userId: string, useCaseId?: string) => {
  const initialState = useMemo<InitialState<ConversationEntry[], string>>(
    () => ({
      data: [],
      error: null,
      isError: false,
      isFetching: false,
      isSuccess: false
    }),
    []
  );

  const fetchFn = useCallback<FetchFn<ConversationEntry[], string>>(async () => {
    const result: FetchCallBackResult<ConversationEntry[], string> = {
      data: [],
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const { data, error, message } = await chatApi.getConversations(chatId, userId, useCaseId);
      if (error) {
        result.error = message;
        result.isError = true;
      } else {
        result.data = data?.conversationEntries || [];
        result.isSuccess = true;
      }
    } catch (err) {
      result.error = err.toString();
      result.isError = true;
    }

    return result;
  }, [chatId, useCaseId, userId]);

  return useDataFetch(fetchFn, initialState, true);
};
