import React, { FC, useCallback, useMemo, useRef } from "react";
import { IncSelectOption, IncRadioButton, IncSwitch, IncTextfield, IncSwitchOption, IncSelect } from "@inception/ui";
import { clone, isNull, isUndefined } from "lodash";
import {
  OpThreshold,
  ThresholdType,
  StaticThresholdDef,
  CompareOperator,
  LookBackThreshold,
  PeriodicOffsetThreshold,
  ThresholdOpType
} from "../../../../services/api/operationalise";
import { UI_THRESHOLD_KEY, THRESHOLD_TYPES, defRollingFreq } from "../../../constants";
import { OpContext } from "../../../context/types";
import { UserServiceFieldSliceSet, SliceSet, TimeObj, BizDataQuery } from "../../../../services/api/explore";
import { VerticallyCenteredRow } from "../../../../components";
import { getSafeNumber } from "../../../../core";
import { LiteralUtils } from "../../../../utils/ExpressionEvaluator/utils";
import { RollingFrequencyEditorV2 } from "../common";
import {
  getDefaultDownsampleFunction,
  getDefaultNonSeasonalRollingFreq,
  getDefaultSeasonalDataConfig,
  getThresholdResult
} from "./utils";
import { ThresholdOffsetEditor } from "./ThresholdOffsetEditor";
import { PeerGroupThresholdEditorV2 } from "./PeerGroupThresholdV2";
import { ManualThresholdEditorV2 } from "./ManualThresholdEditorV2";
import CustomThresholdEditorV2 from "./CustomThresholdEditorV2";

interface Props {
  opThreshold: OpThreshold;
  onChange: (nOpThreshold: OpThreshold) => void;
  metricOrFieldName: string;
  pickerContext: OpContext;
  metricSliceSet: UserServiceFieldSliceSet | SliceSet;
  baseMetricRollingFreq: TimeObj;
  baseMetricRollingFunction: string;
  baseMetricAggFunction: string;
  isEventOperationalize: boolean;
  isEventIDOperationalize: boolean;
  enableAutomaticThreshold: boolean;
  hidePeerGroupThreshold?: boolean;
  bizDataQuery?: BizDataQuery;
  readonly?: boolean;
}

export const ThresholdEditorV2: FC<Props> = props => {
  const {
    opThreshold,
    onChange,
    metricOrFieldName,
    pickerContext,
    metricSliceSet,
    isEventOperationalize,
    isEventIDOperationalize,
    enableAutomaticThreshold,
    baseMetricRollingFreq,
    baseMetricAggFunction,
    baseMetricRollingFunction,
    bizDataQuery,
    hidePeerGroupThreshold,
    readonly = false
  } = props;

  const enablePeerGroupThreshold =
    isEventIDOperationalize || isEventOperationalize || metricSliceSet?.slices?.length > 0;

  const opThresholdRef = useRef(opThreshold);
  useMemo(() => {
    opThresholdRef.current = opThreshold;
  }, [opThreshold]);

  const { aboveThresholdDef, belowThresholdDef, labels, comparator } = opThreshold;

  const uiThresholdType = labels?.[UI_THRESHOLD_KEY];
  const onThresholdTypeChange = useCallback(
    (thresholdType: ThresholdType, uiThresholdType: string) => {
      const opThreshold = opThresholdRef.current;

      const { aboveThresholdDef, belowThresholdDef, labels: prevLabels } = opThreshold;

      const prevUiThresholdType = prevLabels?.[UI_THRESHOLD_KEY];
      const isPrevPeerGroupThreshold = prevUiThresholdType === THRESHOLD_TYPES.peerGroup;
      const isPrevStaticThreshold = prevUiThresholdType === THRESHOLD_TYPES.static;
      const isPrevTimeShiftThreshold = prevUiThresholdType === THRESHOLD_TYPES.timeShift;
      const isPrevAutomaticThreshold = prevUiThresholdType === THRESHOLD_TYPES.automatic;
      const isPrevSelfThreshold = prevUiThresholdType === THRESHOLD_TYPES.self;

      const isSelfSeasonalThreshold = uiThresholdType === THRESHOLD_TYPES.selfSeasonal;
      const isSelfNonSeasonalThreshold = uiThresholdType === THRESHOLD_TYPES.selfNonSeasonal;

      const thresholdDef = aboveThresholdDef || belowThresholdDef;
      const { rollingFunction, rollingFreq, peerThreshold, thresholdOp, scalar } = thresholdDef || {};

      const nRollingFunction = isPrevPeerGroupThreshold
        ? peerThreshold?.peerAgg || rollingFunction
        : baseMetricRollingFunction || "P50";

      const nRollingFreq = isPrevPeerGroupThreshold ? rollingFreq : baseMetricRollingFreq || defRollingFreq;

      const nScalar = isPrevAutomaticThreshold
        ? null
        : isPrevStaticThreshold
          ? scalar || 1000
          : isPrevTimeShiftThreshold || isPrevPeerGroupThreshold || isPrevSelfThreshold
            ? getSafeNumber(thresholdOp?.value, 1000)
            : 1000;

      const nLookBackDef: LookBackThreshold = isSelfNonSeasonalThreshold
        ? {
            rollingFreq: getDefaultNonSeasonalRollingFreq(),
            downSampleFunction: getDefaultDownsampleFunction()
          }
        : null;

      const nPeriodicDef: PeriodicOffsetThreshold = isSelfSeasonalThreshold
        ? {
            baselineDataConfig: {
              seasonalDataConfig: getDefaultSeasonalDataConfig()
            },
            downSampleFunction: getDefaultDownsampleFunction()
          }
        : null;

      const nOpThreshold = getThresholdResult(
        opThreshold,
        thresholdType,
        uiThresholdType,
        nScalar,
        nLookBackDef,
        nPeriodicDef,
        nRollingFreq,
        nRollingFunction,
        bizDataQuery
      );
      onChange(nOpThreshold);
    },
    [baseMetricRollingFreq, baseMetricRollingFunction, bizDataQuery, onChange]
  );

  const comparatorText =
    comparator === CompareOperator.Above
      ? "more than"
      : comparator === CompareOperator.Below
        ? "less than"
        : "more/less than";

  const thresholdDef = comparator === CompareOperator.Above ? aboveThresholdDef : belowThresholdDef;
  const { rollingFreq } = thresholdDef || {};

  const onThresholdDefChange = useCallback(
    (nThresholdDef: StaticThresholdDef) => {
      const opThreshold = opThresholdRef.current;

      const nAboveThresholdDef =
        comparator === CompareOperator.Above || comparator === CompareOperator.AboveOrBelow ? nThresholdDef : null;
      const nBelowThresholdDef =
        comparator === CompareOperator.Below || comparator === CompareOperator.AboveOrBelow ? nThresholdDef : null;

      onChange({
        ...opThreshold,
        aboveThresholdDef: nAboveThresholdDef,
        belowThresholdDef: nBelowThresholdDef,
        labels: {
          ...(opThreshold.labels || {}),
          [UI_THRESHOLD_KEY]: nThresholdDef?.dataQueryInfo
            ? THRESHOLD_TYPES.custom
            : nThresholdDef?.offsetThreshold
              ? THRESHOLD_TYPES.timeShift
              : nThresholdDef?.peerThreshold
                ? THRESHOLD_TYPES.peerGroup
                : !isNull(nThresholdDef?.scalar) && !isUndefined(nThresholdDef?.scalar)
                  ? THRESHOLD_TYPES.static
                  : nThresholdDef?.lookBackDef
                    ? THRESHOLD_TYPES.selfNonSeasonal
                    : nThresholdDef?.periodicDef
                      ? THRESHOLD_TYPES.selfSeasonal
                      : THRESHOLD_TYPES.self
        }
      });
    },
    [comparator, onChange]
  );

  const isPeerGroupThreshold = uiThresholdType === THRESHOLD_TYPES.peerGroup;
  const isStaticThreshold = uiThresholdType === THRESHOLD_TYPES.static;
  const isCustomThreshold = uiThresholdType === THRESHOLD_TYPES.custom;
  const isAutomaticThreshold = uiThresholdType === THRESHOLD_TYPES.automatic;
  const isSelfThreshold = uiThresholdType === THRESHOLD_TYPES.self;
  const isTimeShiftThreshold = uiThresholdType === THRESHOLD_TYPES.timeShift;
  const isSelfSeasonalThreshold = uiThresholdType === THRESHOLD_TYPES.selfSeasonal;
  const isSelfNonSeasonalThreshold = uiThresholdType === THRESHOLD_TYPES.selfNonSeasonal;

  const onRollingFreqChange = useCallback(
    (nRollingFreq: TimeObj) => {
      if (isPeerGroupThreshold) {
        const nOpThreshold = clone(opThresholdRef.current);

        if (nOpThreshold.aboveThresholdDef) {
          nOpThreshold.aboveThresholdDef.rollingFreq = nRollingFreq;
        }

        if (nOpThreshold.belowThresholdDef) {
          nOpThreshold.belowThresholdDef.rollingFreq = nRollingFreq;
        }

        onChange(nOpThreshold);
      }
    },
    [isPeerGroupThreshold, onChange]
  );

  const thresholdScalar = useMemo(() => {
    const { scalar, thresholdOp } = thresholdDef || {};

    const isScalarValid = LiteralUtils.isNumber(scalar?.toString());

    return isScalarValid ? scalar : thresholdOp?.value;
  }, [thresholdDef]);

  const onThresholdScalarChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let nScalar = e.target.valueAsNumber;
      nScalar = isNaN(nScalar) ? 0 : nScalar;

      let nThresholdDef: StaticThresholdDef;
      if (isStaticThreshold) {
        nThresholdDef = {
          ...thresholdDef,
          scalar: nScalar
        };
      } else {
        nThresholdDef = {
          ...thresholdDef,
          thresholdOp: {
            ...thresholdDef.thresholdOp,
            value: nScalar
          }
        };
      }

      onThresholdDefChange(nThresholdDef);
    },
    [isStaticThreshold, onThresholdDefChange, thresholdDef]
  );

  const { isPercentScalarType, isStdDevsScalarType, scalarTypeOpt } = useMemo(() => {
    const { thresholdOp } = thresholdDef || {};

    const isPercentScalarType = thresholdOp?.opType === ThresholdOpType.PERCENTAGE;
    const isStdDevsScalarType = thresholdOp?.opType === ThresholdOpType.NUM_STD_DEVS;

    const scalarTypeOpt = isPercentScalarType ? scalarTypeOptions[0] : scalarTypeOptions[1];

    return {
      isPercentScalarType,
      isStdDevsScalarType,
      scalarTypeOpt
    };
  }, [thresholdDef]);

  const onScalarTypeOptChange = useCallback(
    (opt: IncSelectOption<ThresholdOpType>) => {
      const nThresholdDef: StaticThresholdDef = {
        ...thresholdDef,
        thresholdOp: {
          ...thresholdDef.thresholdOp,
          opType: opt.data,
          value: opt.data === ThresholdOpType.PERCENTAGE ? 40 : 2
        }
      };

      onThresholdDefChange(nThresholdDef);
    },
    [onThresholdDefChange, thresholdDef]
  );

  const onAutomaticThresholdSelected = useCallback(
    () => onThresholdTypeChange(ThresholdType.AUTO, THRESHOLD_TYPES.automatic),
    [onThresholdTypeChange]
  );

  const onManualThresholdSelected = useCallback(() => {
    const thresholdType = THRESHOLD_TYPES.selfSeasonal;
    onThresholdTypeChange(ThresholdType.STATIC, thresholdType);
  }, [onThresholdTypeChange]);

  const onThresholdOptionChange = useCallback(
    (opt: IncSelectOption) => {
      if (opt.value === manualThresholdOption.value) {
        onManualThresholdSelected();
      } else {
        onAutomaticThresholdSelected();
      }
    },
    [onAutomaticThresholdSelected, onManualThresholdSelected]
  );

  const onStaticThresholdSelected = useCallback(
    () => onThresholdTypeChange(ThresholdType.STATIC, THRESHOLD_TYPES.static),
    [onThresholdTypeChange]
  );

  const onPeerGroupThresholdSelected = useCallback(
    () => onThresholdTypeChange(ThresholdType.STATIC, THRESHOLD_TYPES.peerGroup),
    [onThresholdTypeChange]
  );

  const onCustomThresholdSelected = useCallback(
    () => onThresholdTypeChange(ThresholdType.STATIC, THRESHOLD_TYPES.custom),
    [onThresholdTypeChange]
  );

  const onSelfThresholdSelected = useCallback(() => {
    if (isEventOperationalize || !enableAutomaticThreshold) {
      onManualThresholdSelected();
    } else {
      onAutomaticThresholdSelected();
    }
  }, [enableAutomaticThreshold, isEventOperationalize, onAutomaticThresholdSelected, onManualThresholdSelected]);

  const thresholdOptions = useMemo<IncSwitchOption[]>(() => {
    const options: IncSwitchOption[] = [];

    options.push({
      ...automaticThresholdOpt,
      isDisabled: isEventOperationalize,
      infoText: isEventOperationalize ? "Automatic threshold is not allowed for event operationalize" : ""
    });
    options.push(manualThresholdOption);

    return options;
  }, [isEventOperationalize]);

  const selThresholdOption = useMemo(
    () => (isAutomaticThreshold ? automaticThresholdOpt : manualThresholdOption),
    [isAutomaticThreshold]
  );

  const showThresholdSwitch =
    thresholdOptions.length > 1 && !isPeerGroupThreshold && !isStaticThreshold && enableAutomaticThreshold;
  const showThresholdOpSelector = isPercentScalarType || isStdDevsScalarType;

  const peerGroupThresholdHelpText = enablePeerGroupThreshold
    ? ""
    : "Peer group threshold cannot be used when no slices available";

  return (
    <div className="threshold-config-editor width-100">
      <div className="threshold-editor width-100">
        <VerticallyCenteredRow className="marginBt12 width-100">
          <VerticallyCenteredRow className="inc-text-subtext-medium inc-text-inactive marginRt8">
            Compare {metricOrFieldName} with
          </VerticallyCenteredRow>

          <IncRadioButton
            checked={
              isSelfThreshold ||
              isAutomaticThreshold ||
              isTimeShiftThreshold ||
              isSelfSeasonalThreshold ||
              isSelfNonSeasonalThreshold
            }
            className="marginRt8"
            label="Self"
            onChange={onSelfThresholdSelected}
          />

          {!hidePeerGroupThreshold && (
            <IncRadioButton
              checked={isPeerGroupThreshold}
              className="marginRt8"
              disabled={!enablePeerGroupThreshold}
              helpText={peerGroupThresholdHelpText}
              label="Peers"
              onChange={onPeerGroupThresholdSelected}
            />
          )}

          <IncRadioButton
            checked={isStaticThreshold}
            className="marginRt8"
            label="Static"
            onChange={onStaticThresholdSelected}
          />

          <IncRadioButton
            checked={isCustomThreshold}
            className="marginRt8"
            label="Custom"
            onChange={onCustomThresholdSelected}
          />
        </VerticallyCenteredRow>

        {isCustomThreshold && (
          <VerticallyCenteredRow>
            <CustomThresholdEditorV2
              comparator={comparator}
              context={pickerContext}
              onChange={onThresholdDefChange}
              opBizDataQuery={bizDataQuery}
              readonly={readonly}
              thresholdDef={thresholdDef}
            />
          </VerticallyCenteredRow>
        )}
        {!isCustomThreshold && (
          <VerticallyCenteredRow className="width-100 flex-gap-8">
            {showThresholdSwitch && (
              <IncSwitch
                onChange={onThresholdOptionChange}
                options={thresholdOptions}
                value={selThresholdOption}
              />
            )}

            {!isAutomaticThreshold && (
              <>
                {!showThresholdOpSelector && (
                  <>
                    <VerticallyCenteredRow className="inc-label-common">{comparatorText}</VerticallyCenteredRow>

                    <IncTextfield
                      onChange={onThresholdScalarChange}
                      type="number"
                      value={thresholdScalar}
                    />
                  </>
                )}

                {showThresholdOpSelector && (
                  <>
                    <IncTextfield
                      autoAdjustWidth
                      onChange={onThresholdScalarChange}
                      type="number"
                      value={thresholdScalar}
                    />

                    {!isPeerGroupThreshold && (
                      <IncSelect
                        autoAdjustWidth
                        isSearchable={false}
                        onChange={onScalarTypeOptChange}
                        options={scalarTypeOptions}
                        value={scalarTypeOpt}
                      />
                    )}

                    <VerticallyCenteredRow className="inc-label-common">
                      {isPeerGroupThreshold ? " % " : ""}
                      {`  ${comparatorText}`}
                    </VerticallyCenteredRow>

                    {(isSelfThreshold || isPeerGroupThreshold || isTimeShiftThreshold) && (
                      <ThresholdOffsetEditor
                        isEventOperationalize={isEventOperationalize}
                        isPeerGroupThreshold={isPeerGroupThreshold}
                        onChange={onThresholdDefChange}
                        thresholdDef={thresholdDef}
                      />
                    )}

                    {(isSelfNonSeasonalThreshold || isSelfSeasonalThreshold) && (
                      <ManualThresholdEditorV2
                        baseMetricAggFunction={baseMetricAggFunction}
                        baseMetricRollingFreq={baseMetricRollingFreq}
                        isRateMetric={pickerContext.isRateMetric}
                        metricName={metricOrFieldName}
                        onChange={onThresholdDefChange}
                        thresholdDef={thresholdDef}
                      />
                    )}
                  </>
                )}

                {isPercentScalarType && isPeerGroupThreshold && (
                  <>
                    <div className="peer-threshold-editor">
                      <PeerGroupThresholdEditorV2
                        comparator={comparator}
                        isEventOperationalize={isEventOperationalize}
                        metricOrFieldName={metricOrFieldName}
                        metricSliceSet={metricSliceSet}
                        onChange={onThresholdDefChange}
                        pickerContext={pickerContext}
                        thresholdDef={thresholdDef}
                      />
                    </div>

                    <VerticallyCenteredRow className="inc-label-common">in the last</VerticallyCenteredRow>

                    <RollingFrequencyEditorV2
                      onChange={onRollingFreqChange}
                      rollingFreq={rollingFreq}
                      skipPrefix
                    />
                  </>
                )}
              </>
            )}
          </VerticallyCenteredRow>
        )}
      </div>
    </div>
  );
};

const automaticThresholdOpt: IncSelectOption = {
  label: "Automatic",
  value: "automatic"
};

const manualThresholdOption: IncSelectOption = {
  label: "Manual",
  value: "manual"
};

const SCALAR_TYPE_PERCENT = "percent";
const SCALAR_TYPE_STD_DEVS = "std_devs";

const PERCENT_LABEL = "%";
const STD_DEVS_LABEL = "std dev";

const scalarTypeOptions: Array<IncSelectOption<ThresholdOpType>> = [
  {
    label: PERCENT_LABEL,
    value: SCALAR_TYPE_PERCENT,
    data: ThresholdOpType.PERCENTAGE
  },
  {
    label: STD_DEVS_LABEL,
    value: SCALAR_TYPE_STD_DEVS,
    data: ThresholdOpType.NUM_STD_DEVS
  }
];
