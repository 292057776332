import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getDateBasedOnTimeZone } from "@inception/ui";
import { cloneDeep } from "lodash";
import { OpTimeFrame, OpTimeFrameEditor } from "../v3/simulation";
import { TimezoneSelector } from "../../components";
import { TimeZone } from "../../core";
import timeRangeUtils from "../../utils/TimeRangeUtils";

type Props = {
  opTimeFrame: OpTimeFrame;
  onChange: (opTimeFrame: OpTimeFrame, timeZone?: TimeZone) => void;
  defTimeZone?: TimeZone;
};

const OpTimeFrameEditorWrapper: FC<Props> = props => {
  const { onChange: pOnChange, opTimeFrame, defTimeZone = "browser" } = props;
  const { isCustom } = opTimeFrame || {};

  const [timeZone, setTimeZone] = useState<TimeZone>(defTimeZone);
  const prevTimeZoneRef = useRef<TimeZone>(defTimeZone);

  useEffect(() => {
    setTimeZone(defTimeZone);
    prevTimeZoneRef.current = defTimeZone;
  }, [defTimeZone]);

  const timeFrame = useMemo<OpTimeFrame>(() => {
    const { from, to, isCustom } = opTimeFrame;
    if (isCustom) {
      const nFrom = getDateBasedOnTimeZone(Number(from), timeZone);
      const nTo = getDateBasedOnTimeZone(Number(to), timeZone);
      return {
        ...opTimeFrame,
        from: nFrom.getTime().toString(),
        to: nTo.getTime().toString()
      };
    }

    return opTimeFrame;
  }, [opTimeFrame, timeZone]);

  const onChange = useCallback(
    (opTimeFrame: OpTimeFrame, timeZone: TimeZone, convertFromTimeZone?: boolean) => {
      const nTimeFrame = cloneDeep(opTimeFrame);

      if (convertFromTimeZone && nTimeFrame.isCustom) {
        const fromDate = new Date(Number(nTimeFrame.from));
        const toDate = new Date(Number(nTimeFrame.to));
        nTimeFrame.from = timeRangeUtils.getMillisFromTimeZone(fromDate, timeZone).toString();
        nTimeFrame.to = timeRangeUtils.getMillisFromTimeZone(toDate, timeZone).toString();
      }

      pOnChange(nTimeFrame, timeZone);
    },
    [pOnChange]
  );

  const setAbsoluteTimeRangeOnTimeZoneChange = useCallback(
    (timeZone: TimeZone, pPrevTimeZone?: TimeZone) => {
      const { from, to } = opTimeFrame;

      const prevTimeZone = pPrevTimeZone || prevTimeZoneRef.current;

      const fromMillis = Number(from);
      const toMillis = Number(to);
      const newFrom = timeRangeUtils.getConvertedMillisFromTimeZone(fromMillis, timeZone, prevTimeZone);
      const newTo = timeRangeUtils.getConvertedMillisFromTimeZone(toMillis, timeZone, prevTimeZone);

      onChange(
        {
          ...opTimeFrame,
          from: newFrom.toString(),
          to: newTo.toString(),
          isCustom: true
        },
        timeZone
      );
    },
    [onChange, opTimeFrame]
  );

  const onChangeTimeZone = useCallback(
    (timeZone: TimeZone) => {
      setTimeZone(prev => {
        prevTimeZoneRef.current = prev;
        return timeZone;
      });
      setAbsoluteTimeRangeOnTimeZoneChange(timeZone);
    },
    [setAbsoluteTimeRangeOnTimeZoneChange]
  );

  const onTimeFrameChange = useCallback(
    (opTimeFrame: OpTimeFrame) => {
      onChange(opTimeFrame, timeZone, true);
    },
    [onChange, timeZone]
  );

  return (
    <>
      <OpTimeFrameEditor
        includeStartingNow
        onChange={onTimeFrameChange}
        showSeconds
        timeFrame={timeFrame}
      />
      {isCustom && (
        <TimezoneSelector
          autoAdjustWidth
          includeUtc
          label="Timezone"
          onChange={onChangeTimeZone}
          skipIcon
          timeZone={timeZone}
        />
      )}
    </>
  );
};

export default OpTimeFrameEditorWrapper;
