import {
  ISaxIcon,
  IncButton,
  IncCheckbox,
  IncFaIcon,
  IncGenericIcon,
  IncPopConfirm,
  IncSmartText
} from "@inception/ui";
import { cx } from "emotion";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useFetchWidgetConfigForBizDataQuery, useTenantConfig, useToggleState } from "../../core";
import { OverallMappingStatus } from "../../services/api/explore";
import { VerticallyCenteredRow } from "../flex-components";
import { IconInfoRenderer } from "../IconInfoRenderer";
import { Icon } from "../../services/api";
import { DisplayKPI } from "./types";

type KPICardProps = {
  kpi: DisplayKPI;

  isLoading?: boolean;
  className?: string;
  hideDataNotConnectedBanner?: boolean;
  iconSize?: number;

  onClick?: () => void;
  onEdit?: () => void;
  onClone?: () => void;
  onToggleSelection?: () => void;
  onRemove?: () => void;
};

export const KPICard = memo<KPICardProps>(props => {
  const {
    kpi,
    onClick: pOnClick,
    onToggleSelection,
    isLoading: defIsLoading = false,
    hideDataNotConnectedBanner = false,
    iconSize = 40,
    onEdit,
    onRemove,
    onClone
  } = props;

  const { color, description, icon, name, bizDataQuery, isSelected } = defIsLoading ? mockKpi : kpi;

  const modIcon = useMemo<Icon>(
    () => ({
      iconColor: isSelected === false ? "rgba(255, 255, 255, 0.53)" : icon?.iconColor || color,
      iconName: icon?.iconName,
      iconSvg: icon?.iconSvg
    }),
    [color, icon, isSelected]
  );

  const onEditKpi = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onEdit();
    },
    [onEdit]
  );

  const onCloneKpi = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onClone();
    },
    [onClone]
  );

  const { widgetConfigDto, fetchWidgetConfig, widgetConfigFetchInProgress } = useFetchWidgetConfigForBizDataQuery(
    bizDataQuery,
    true
  );
  useEffect(() => {
    if (!defIsLoading && bizDataQuery) {
      fetchWidgetConfig();
    }
  }, [bizDataQuery, defIsLoading, fetchWidgetConfig]);

  const { mappingStatus } = widgetConfigDto || {};
  const { isIncomplete, overallStatus } = mappingStatus || {};
  const { tenantConfigState } = useTenantConfig();
  const isDemoTenant = tenantConfigState?.demoTenant || false;
  const showDataNotConnectedBanner =
    (hideDataNotConnectedBanner
      ? false
      : bizDataQuery
        ? isIncomplete || overallStatus === OverallMappingStatus.PENDING
        : false) && !isDemoTenant;

  const isLoading = defIsLoading || widgetConfigFetchInProgress;
  const onClick = showDataNotConnectedBanner || isLoading ? null : pOnClick;

  const {
    isOpen: isDeleteConfirmationOpen,
    open: openDeleteConfirmation,
    close: closeDeleteConfirmation
  } = useToggleState();

  const removeKpiButton = useMemo(() => {
    if (showDataNotConnectedBanner || !onRemove) {
      return <></>;
    }

    return (
      <IncPopConfirm
        buttonProps={{
          okButton: {
            text: "Delete",
            danger: true
          },
          cancelButton: {
            text: "Cancel"
          }
        }}
        onCancel={closeDeleteConfirmation}
        onConfirm={onRemove}
        open={isDeleteConfirmationOpen}
        title={`Are you sure you want to delete ${name}?`}
      >
        <IncFaIcon
          className="inc-cursor-pointer status-danger"
          data-show-loader={isLoading}
          iconName="trash-alt"
          onClick={openDeleteConfirmation}
        />
      </IncPopConfirm>
    );
  }, [
    closeDeleteConfirmation,
    isDeleteConfirmationOpen,
    isLoading,
    name,
    onRemove,
    openDeleteConfirmation,
    showDataNotConnectedBanner
  ]);

  const className = cx("kpi-card visible-on-hover", {
    readonly: isLoading,
    "inc-cursor-pointer": !isLoading && !showDataNotConnectedBanner && Boolean(onToggleSelection)
  });

  return (
    <VerticallyCenteredRow
      className={className}
      data-selected={isSelected}
      onClick={onClick}
    >
      {!isLoading && Boolean(onToggleSelection) && (
        <IncCheckbox
          checked={isSelected}
          noImplicitHeight
          onChange={e => {
            e.stopPropagation();
            onToggleSelection();
          }}
          onClick={e => e.stopPropagation()}
        />
      )}
      {isLoading && (
        <IncGenericIcon
          data-show-loader={isLoading}
          iconName="info"
          size={20}
        />
      )}
      <div className="kpi-card--icon">
        <IconInfoRenderer
          color="auto"
          icon={modIcon}
          isLoading={isLoading}
          size={iconSize}
        />
      </div>
      <div className="kpi-card--content">
        <VerticallyCenteredRow className="flex-gap-16">
          <IncSmartText
            className="inc-text-body-medium"
            data-show-loader={isLoading}
            text={name}
          />
        </VerticallyCenteredRow>

        <IncSmartText
          className="inc-text-subtext-medium inc-text-inactive"
          data-show-loader={isLoading}
          numLines={2}
          text={description}
        />
      </div>
      <VerticallyCenteredRow className="height-100 flex-gap-12">
        {!showDataNotConnectedBanner && Boolean(onEdit) && (
          <IncFaIcon
            className="inc-cursor-pointer marginLtAuto status-info"
            data-show-loader={isLoading}
            iconName="edit"
            onClick={onEditKpi}
          />
        )}
        {Boolean(onClone) && (
          <IncFaIcon
            className="inc-cursor-pointer status-info"
            data-show-loader={isLoading}
            iconName="clone"
            onClick={onCloneKpi}
          />
        )}
        {removeKpiButton}
      </VerticallyCenteredRow>

      {showDataNotConnectedBanner && Boolean(onEdit) && (
        <VerticallyCenteredRow className="flex-gap-8">
          <ISaxIcon
            className="status-brand"
            iconName="Magicpen"
          />
          <IncButton
            className="marginRt10"
            color="link"
            label="Connect data and unlock KPI"
            onClick={onEditKpi}
          />
        </VerticallyCenteredRow>
      )}
    </VerticallyCenteredRow>
  );
});

const mockKpi: DisplayKPI = {
  bizDataQuery: null,
  id: "mock",
  icon: {
    iconName: "Setting2",
    iconColor: "#2a343e"
  },
  name: "On-time Performance (OTP)",
  color: "#39acff",
  description: "This measures the percentage of flights that depart and arrive on time.",
  isSelected: true,
  relatedKpis: [],
  workflows: [],
  isSpikePositive: false,
  subType: null
};
