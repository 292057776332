import React, { FC, useCallback } from "react";
import { IncTextfield } from "@inception/ui";
import { values } from "lodash";
import { ParameterValue, PlanSchema } from "../../services/api/chat";

interface Props {
  paramInputs: PlanSchema;
  suggestedValues: ParameterValue[];
  onChange: (values: ParameterValue[]) => void;
}

export const PlanParamsEditor: FC<Props> = props => {
  const { paramInputs, onChange, suggestedValues } = props;

  // Handler for input changes in the paramInputs section
  const handleParamChange = useCallback(
    (value: string, name: string, type: string) => {
      const updatedSuggestedValues = (suggestedValues || [])?.map(item => {
        if (item.paramId === name) {
          let newValue;
          if (type === "_str") {
            newValue = { stringVal: value };
          } else if (type === "_long") {
            newValue = { longVal: value as unknown as number };
          } else if (type === "_double") {
            newValue = { doubleVal: value as unknown as number };
          } else if (type === "_bool") {
            newValue = { booleanVal: value as unknown as boolean };
          } else if (type === "_datetime") {
            newValue = { dateTimeVal: value };
          } else if (type === "_date") {
            newValue = { dateVal: value };
          } else {
            newValue = { stringVal: value };
          }
          return {
            ...item,
            primValue: newValue
          };
        }
        return item;
      });
      onChange(updatedSuggestedValues);
    },
    [onChange, suggestedValues]
  );

  return (
    <div className="plan-params-editor paddingLt24">
      {/* <div className="text-bold marginBt12 mrginTp8">
      {plan?.name|| ''}
    </div> */}
      <div className="inc-flex-row-wrap marginBt10">
        {(paramInputs?.schema || []).map((param, idx) => {
          const suggestedValue = (suggestedValues || []).find(item => item?.paramId === param?.id);
          const paramValue = values(suggestedValue?.primValue)?.[0].toString() || "";
          const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            handleParamChange(value, param?.id, param?.type?.primKind);
          };
          return (
            <IncTextfield
              key={param?.id + idx}
              label={param?.param}
              onChange={handleChange}
              value={paramValue}
            />
          );
        })}
      </div>
    </div>
  );
};
