export const SET_OPERATIONALISE_CONTEXT = "operationalise/context/set";
export const SET_PARTIAL_OPERATIONALISE_CONTEXT = "operationalise/context/set/partial";
export const SET_UI_INTEGRATION_CONFIGS_CONTEXT = "operationalise/context/set/uiIntegrationConfigs";
export const SET_OPERATIONALISE_CONFIG = "operationalise/state/set/opCreationConfig";

export const SET_OPERATIONALISE_STATE = "operationalise/state/set";
export const SET_OP_ANALYSIS_CONFIG = "operationalise/state/opAnalysis";
export const SET_SHOULD_RELOAD_EDITORS = "operationalise/state/reload/editors";
export const SET_WHATS_NEW_CONFIG = "operationalise/state/set/whatsnewconfig";

export const SET_OP_META_DATA = "operationalise/state/set/opMetaData";
export const SET_SCHEDULE = "operationalise/state/set/opCreationConfig/opSchedule/schedule";
export const SET_BIZ_DATA_QUERY = "operationalise/state/set/opCreationConfig/bizDataQuery";
export const SET_ROLLING_FUNCTION = "operationalise/state/set/opCreationConfig/rollingFunction";
export const SET_ROLLING_FREQ = "operationalise/state/set/opCreationConfig/rollingFreq";
export const SET_THRESHOLD = "operationalise/state/set/opCreationConfig/threshold";
export const SET_START_TRIGGER = "operationalise/state/set/opCreationConfig/triggerCondition/startTriggerCondition";
export const SET_END_TRIGGER = "operationalise/state/set/opCreationConfig/triggerCondition/endTriggerCondition";
export const SET_ENTITY_AGGREGATED = "operationalise/state/set/opCreationConfig/labels/entityAggregated";
export const SET_ACTION_CONFIG = "operationalise/state/set/opCreationConfig/bizActions";
export const SET_IMPACT_WIDGETS = "operationalise/state/set/opCreationConfig/impactedWidgets";
export const SET_DIAGNOSTIC_FIELDS = "operationalise/state/set/opCreationConfig/diagnosticFields";
export const DELETE_ACTION_CONFIG = "operationalise/state/delete/bizActions";
export const DISABLE_ACTION_CONFIG = "operationalise/state/disable/bizActions";
export const ENABLE_ACTION_CONFIG = "operationalise/state/enable/bizActions";
export const SNOOZE_ACTION_CONFIG = "operationalise/state/snooze/bizActions";
export const SNOOZE_SERIES_CONFIG = "operationalise/state/snooze/series";
export const SET_SUPPRESSION_CONFIGS = "operationalise/state/set/suppression";

export const SET_TEMPORARY_ACTIONS = "operationalise/state/set/temporaryActions";
export const SET_TEMPORARY_ACTION = "operationalise/state/set/temporaryActions/action";
export const DELETE_TEMPORARY_ACTION = "operationalise/state/delete/temporaryAction/action";

export const SET_COHORT_CONFIG = "operationalise/state/set/cohort";
export const RESET_COHORT_CONFIG = "operationalise/state/reset/cohort";

export const SET_SIMULATIONS = "operationalise/state/set/simulations";
export const SET_SIMULATION_CONFIG = "operationalise/state/set/simulations/simulationConfig";
export const SET_SIMULATION_CONFIG_NAME = "operationalise/state/set/simulations/simulationConfig/name";
export const SET_SIMULATION_STATE = "operationalise/state/set/simulations/simulationConfigMap/state";

export const SET_STAGE = "operationalise/state/set/stage";
export const SET_READ_ONLY = "operationalise/state/set/readOnly";
export const SET_OP10ZE_ID = "operationalise/state/set/op10zeId";
export const SET_IS_DRAFT = "operationalise/state/set/isDraft";
export const SET_HISTORY = "operationalise/state/set/history";
export const SET_OP_MODE = "operationalise/state/set/context/mode";

export const SET_PRIMARY_OP_CREATION_CONFIG = "operationalise/state/set/primaryOpCreationConfig";
export const SET_SAVE_OP_CREATION_CONFIG = "operationalise/state/set/saveOpCreationConfig";

export const SET_OP_TRIAGE_CONFIG = "operationalise/state/set/opTriageConfig";

export const UPDATE_ENTITY_FILTERS = "operationalise/state/update/entityFilters";
export const UPDATE_EVENT_FILTERS = "operationalise/state/update/eventFilters";

export const SET_ACTION_CATEGORIES = "operationalise/state/set/actionCategories";
