import React, { FC, useMemo, useCallback, useRef, useState, useEffect } from "react";
import {
  IncClickAway,
  IncPopper,
  TableDataColumn,
  IncRTable,
  IncButton,
  CurrencyType,
  IncSmartText,
  IncToolTip,
  IncFaIcon
} from "@inception/ui";
import { css, cx } from "emotion";
import { LoadingSpinner, EntityList, SimpleEntityNameRenderer } from "../../components";
import { ENTITY_TAG } from "../../utils";
import { DataType } from "../../core";
import { SingleStatData, ChangeMetric } from "../types";
import { ChangeDataRenderer } from "./ChangeRenderer";

interface Props {
  data: Map<string, SingleStatData[]>;
  dataExists: boolean;
  compareData: Map<string, SingleStatData[]>;
  isFetching: boolean;
  changeMetrics: ChangeMetric[];
  changeMetricHeaders: string[];
  aggColumnHeader: string;
  compareTimeStr: string;
  dataType: DataType;
  aggTag: string | string[];
  currencyType: CurrencyType;
  fieldEntityTypeName: string;
  isEntityFieldAgg: boolean;
  isError: boolean;

  valueFormatter?: (value: number) => string;
  onPopperOpenChange?: (open: boolean) => void;
  hideHeader?: boolean;
  extColumnKeys?: string[];
  dataSortFn?: () => void;
  useTitleAsTooltip?: boolean;
  skipPopper?: boolean;
  onDataKeyClick?: (value: string) => void;
}

export const BizEntityListWithStats: FC<Props> = props => {
  const {
    data,
    dataExists,
    compareData,
    isFetching,
    changeMetrics,
    compareTimeStr,
    dataType,
    aggTag,
    currencyType,
    fieldEntityTypeName,
    isEntityFieldAgg,
    aggColumnHeader,
    changeMetricHeaders,
    isError,
    onPopperOpenChange,
    onDataKeyClick,
    hideHeader = false,
    valueFormatter,
    useTitleAsTooltip,
    skipPopper = false
  } = props;

  const iconRef = useRef(null);
  const [show, setShow] = useState(false);

  const toggleShow = useCallback(() => setShow(prev => !prev), []);
  const close = useCallback(() => setShow(false), []);

  useEffect(() => {
    onPopperOpenChange && onPopperOpenChange(show);
  }, [onPopperOpenChange, show]);

  const { entityLookupData, entityChangeData, entityData } = useMemo(() => {
    const entityChangeData: Record<string, number[]> = {};
    const entityLookupData: Record<string, string> = {};
    const entityData: Record<string, Array<[number, number]>> = {};

    const dataArr = [...data.entries()];
    dataArr.forEach(([k, v]) => {
      const numEntries = v.length;
      v.forEach((ve, idx) => {
        if (ve) {
          const { percentChange, rawName: entityId, value: currValue } = ve;
          if (entityId) {
            const compareValue = compareData.get(k)?.[idx]?.value || 0;

            const changeMetric = changeMetrics?.[idx];
            const change =
              changeMetric === "deltaPercentage"
                ? percentChange
                : changeMetric === "delta"
                  ? currValue - compareValue
                  : changeMetric === "timeShift"
                    ? compareValue
                    : currValue;

            entityChangeData[entityId] = entityChangeData[entityId] || new Array(numEntries).fill(0);
            entityChangeData[entityId][idx] = change;

            entityData[entityId] = entityData[entityId] || new Array(numEntries).fill([0, 0]);
            entityData[entityId].splice(idx, 1, [currValue, compareValue]);

            entityLookupData[entityId] = k;
          }
        }
      });
    });
    return {
      entityChangeData,
      entityLookupData,
      entityData
    };
  }, [changeMetrics, compareData, data]);

  const numEntries = Object.values(entityData)[0]?.length || 0;
  const entityIds = useMemo(() => Object.keys(entityChangeData), [entityChangeData]);
  const numEntityIds = entityIds.length;
  const sliceIdx = skipPopper ? numEntityIds : 5;
  const tableEntityIds = useMemo(() => entityIds.slice(0, sliceIdx), [entityIds, sliceIdx]);
  const popperEntityIds = useMemo(() => entityIds.slice(sliceIdx), [entityIds, sliceIdx]);

  const initData = useMemo(
    () => ({
      entityIds: popperEntityIds,
      entityLookupData,
      entityChangeData
    }),
    [entityChangeData, entityLookupData, popperEntityIds]
  );

  const elRowRenderer = useCallback(
    (entityId: string, entityName: string, entityNameEl: JSX.Element) => {
      const changeArr = entityChangeData[entityId];
      const currentCompareArr = entityData[entityId];

      const entryWidth = Math.min(20, Math.ceil(100 / numEntries));
      const nameWidth = Math.max(80, 100 - entryWidth);

      const css1 = css`
        width: ${nameWidth}%;
      `;
      const css2 = css`
        width: ${entryWidth}%;
      `;

      const entriesDivs = changeArr.map((changeEntry, idx) => {
        const changeMetric = changeMetrics?.[idx];
        const changeType =
          changeMetric === "deltaPercentage" ? "percent" : changeMetric === "delta" ? "absolute" : "none";

        const change = changeEntry;
        const [current, compare] = currentCompareArr[idx];
        const key = `${entityId}-${entityName}-${idx}`;

        return (
          <div
            className={css2}
            key={key}
          >
            <ChangeDataRenderer
              change={change}
              changeType={changeType}
              compare={compare}
              compareTimeStr={compareTimeStr}
              currencyType={currencyType}
              current={current}
              dataType={dataType}
              valueFormatter={valueFormatter}
            />
          </div>
        );
      });

      return (
        <>
          <div
            className={css1}
            title={entityName}
          >
            {entityNameEl}
          </div>

          {entriesDivs}
        </>
      );
    },
    [changeMetrics, compareTimeStr, currencyType, dataType, entityChangeData, entityData, numEntries, valueFormatter]
  );

  const entityList = useMemo(() => {
    if (isFetching) {
      return (
        <div
          style={{
            width: 300,
            height: 300
          }}
        >
          <LoadingSpinner />
        </div>
      );
    } else if (isError) {
      return <div className="status-danger">Error fetching data</div>;
    } else {
      return dataExists ? (
        <EntityList
          entityTypeName={fieldEntityTypeName}
          initialData={initData}
          rowRenderer={elRowRenderer}
          searchable
          showEntityInfoOnHover={isEntityFieldAgg}
          size="md"
          sortData
        />
      ) : (
        <>No data found</>
      );
    }
  }, [dataExists, elRowRenderer, fieldEntityTypeName, initData, isEntityFieldAgg, isError, isFetching]);

  const entityListPopper = useMemo(
    () => (
      <IncClickAway onClickAway={close}>
        {ref => (
          <IncPopper
            anchorEl={iconRef.current}
            offset={{
              x: 0,
              y: 0
            }}
            placement="right-end"
            ref={ref}
            show={show}
          >
            {entityList}
          </IncPopper>
        )}
      </IncClickAway>
    ),
    [close, entityList, show]
  );

  const tableData = useMemo(
    () =>
      tableEntityIds.map(entityId => {
        const entry: Record<string, any> = {
          entityId
        };

        const entityDataArr = entityData[entityId];
        entityDataArr.forEach((ed, idx) => {
          const key1 = `percentChange${idx}`;
          const key2 = `data${idx}`;

          entry[key1] = entityChangeData[entityId]?.[idx] || 0;
          entry[key2] = ed;
        });

        return entry;
      }),
    [entityChangeData, entityData, tableEntityIds]
  );

  const tableColumns = useMemo<TableDataColumn[]>(() => {
    const columns: TableDataColumn[] = [];
    const entryWidth = Math.floor(100 / (numEntries + 2));
    const entryWidthPer = `${entryWidth}%`;
    const nameWidthPer = `${2 * entryWidth}%`;

    columns.push({
      accessor: "entityId",
      header: aggColumnHeader,
      width: nameWidthPer,
      renderer: (entityId: string) => {
        const entityName = entityLookupData[entityId];
        const onClick = () => onDataKeyClick(entityName);
        const tooltipElement = <IncFaIcon iconName="plus-circle" />;
        return isEntityFieldAgg ? (
          onDataKeyClick ? (
            <IncToolTip
              placement="right"
              titleElement={tooltipElement}
            >
              <div
                className="cursor-pointer width-fit-content"
                onClick={onClick}
              >
                <SimpleEntityNameRenderer
                  id={entityId}
                  minimalLoader
                  name={entityName}
                  showPropertiesPopover
                />
              </div>
            </IncToolTip>
          ) : (
            <SimpleEntityNameRenderer
              id={entityId}
              minimalLoader
              name={entityName}
              showPropertiesPopover
            />
          )
        ) : (
          <IncSmartText text={entityName} />
        );
      }
    });

    for (let idx = 0; idx < numEntries; idx++) {
      const accessor = `percentChange${idx}`;
      const changeMetricHeaderStr = changeMetricHeaders?.[idx] || "";
      const changeMetricHeader = useTitleAsTooltip ? (
        <IncToolTip
          placement="top"
          showArrow
          titleText={changeMetricHeaderStr}
        >
          <div className="width-100">
            <IncSmartText text={changeMetricHeaderStr} />
          </div>
        </IncToolTip>
      ) : (
        changeMetricHeaderStr
      );

      columns.push({
        accessor,
        header: changeMetricHeader,
        renderer: (value, rowData) => {
          const key = `data${idx}`;
          const [current, compare] = rowData[key];
          const changeMetric = changeMetrics?.[idx];
          const changeType =
            changeMetric === "deltaPercentage" ? "percent" : changeMetric === "delta" ? "absolute" : "none";
          return (
            <ChangeDataRenderer
              change={value}
              changeType={changeType}
              compare={compare}
              compareTimeStr={compareTimeStr}
              currencyType={currencyType}
              current={current}
              dataType={dataType}
              valueFormatter={valueFormatter}
            />
          );
        },
        width: entryWidthPer,
        align: "center"
      });
    }

    return columns;
  }, [
    aggColumnHeader,
    changeMetricHeaders,
    changeMetrics,
    compareTimeStr,
    currencyType,
    onDataKeyClick,
    dataType,
    entityLookupData,
    isEntityFieldAgg,
    numEntries,
    useTitleAsTooltip,
    valueFormatter
  ]);

  const popperEntityLength = useMemo(() => popperEntityIds.length, [popperEntityIds]);
  const slices = (Array.isArray(aggTag) ? aggTag : [aggTag]).filter(slice => slice !== ENTITY_TAG);
  const sliceStr = slices.length ? `by ${slices.join(", ")}` : "";

  const className = useMemo(
    () =>
      cx("ecs-entity-list", {
        "ecs-entity-list--with-table-header": Boolean(changeMetricHeaders)
      }),
    [changeMetricHeaders]
  );

  const hideTableHeaders = !changeMetricHeaders && !aggColumnHeader;

  return (
    <div className={className}>
      {!hideHeader && (
        <div className="ecs-entity-list--header">
          <div className="inc-text-body-medium">Top Insights</div>
          {Boolean(sliceStr) && <div className="inc-text-subtext marginLt8 subtext">{sliceStr}</div>}
        </div>
      )}

      <IncRTable
        columns={tableColumns}
        data={tableData}
        hideHeaders={hideTableHeaders}
        isLoading={isFetching}
        variant="minimal"
      />

      {popperEntityLength > 0 && (
        <IncButton
          color="link"
          onClick={toggleShow}
          ref={iconRef}
        >
          View {popperEntityLength} more
        </IncButton>
      )}

      {entityListPopper}
    </div>
  );
};
