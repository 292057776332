import React, { useMemo, useState, useEffect, useCallback } from "react";
import { cx } from "emotion";
import { DepGraph } from "dependency-graph";
import { zipObject } from "lodash";
import { IncCheckbox, IncCheckboxProps } from "@inception/ui";
import { logger } from "../../core";
import { DownCircleIcon, UpCircleIcon } from "../../core/iconwrapper";
import { GroupedCheckboxItem } from "./types";

type BaseType = Record<string, any>;

interface Props<T = BaseType> {
  items: Array<GroupedCheckboxItem<T>>;
  onChange: (nSelection: Record<string, boolean>) => void;
  selection: Record<string, boolean>;

  className?: string;
  maxLevels?: number;
  warnOnLevelExceed?: boolean;
  skipChangeOnLabelClick?: boolean;
}

export const GroupedCheckboxList = <T extends BaseType = BaseType>(props: Props<T>) => {
  const { className, maxLevels = 10, items, skipChangeOnLabelClick = true, ...restProps } = props;

  // Dependency graph to store the parent child relationship
  const [depGraph, setDepGraph] = useState<DepGraph<T>>();

  // Update the dependency graph everytime the items change
  useEffect(() => {
    const nDepGraph = new DepGraph<T>();
    // Current level is 0 because we're not iterating any items here
    updateDependencyGraph(nDepGraph, items, 0, maxLevels, "");
    setDepGraph(nDepGraph);
  }, [items, maxLevels]);

  // First level items
  const baseLevelItems = useMemo(
    () =>
      depGraph ? (
        <RecursiveRenderer
          {...restProps}
          currentLevel={1}
          depGraph={depGraph}
          items={items}
          maxLevels={maxLevels}
          parentId=""
          skipChangeOnLabelClick={skipChangeOnLabelClick}
        />
      ) : null,
    [depGraph, items, maxLevels, restProps, skipChangeOnLabelClick]
  );

  const wrapperClass = cx("grouped-checkbox-list", "inc-flex-column", className);
  return <div className={wrapperClass}>{baseLevelItems}</div>;
};

type RendererProps<T = BaseType> = Props<T> & {
  currentLevel: number;
  depGraph: DepGraph<T>;
  parentId: string;
};

const RecursiveRenderer = <T extends BaseType = BaseType>(props: RendererProps<T>) => {
  const { items, maxLevels, currentLevel, warnOnLevelExceed, ...restProps } = props;

  const levelExceeds = currentLevel > maxLevels;
  const groupsExist = items?.length > 0;
  const shouldRender = !levelExceeds && groupsExist;

  //const wrapperCssClass = currentLevel > 1 ? css`margin-left: ${(currentLevel - 1 * 14)}px;` : ``;
  const wrapperClass = cx("grouped-checkbox-list--list", "inc-flex-column");

  const currentLevelItems = useMemo(() => {
    const levelItems = shouldRender ? items : [];
    return levelItems.map((item, idx) => {
      const key = `${item.id}-${idx}`;
      return (
        <LevelItemRenderer
          {...restProps}
          currentLevel={currentLevel}
          item={item}
          key={key}
          maxLevels={maxLevels}
        />
      );
    });
  }, [currentLevel, items, maxLevels, restProps, shouldRender]);

  if (!shouldRender) {
    if (groupsExist && warnOnLevelExceed) {
      return <span className="inc-text-element-medium status-warning">*Level exceeded while rendering items</span>;
    }
    return <></>;
  }

  return <div className={wrapperClass}>{currentLevelItems}</div>;
};

type LevelRendererProps<T = Record<string, any>> = Omit<RendererProps<T>, "items"> & {
  currentLevel: number;
  depGraph: DepGraph<T>;
  item: GroupedCheckboxItem<T>;
};

const LevelItemRenderer = <T extends BaseType = BaseType>(props: LevelRendererProps<T>) => {
  const { currentLevel, depGraph, item, onChange, selection, maxLevels, parentId, skipChangeOnLabelClick } = props;

  const { dataType, id, name, className: iClassName = "", labelFormatter, subGroups } = item;

  const subGroupsExist = subGroups?.length > 0;
  const levelExceeds = currentLevel >= maxLevels;
  const renderSubGroups = subGroupsExist && !levelExceeds;

  useEffect(() => {
    if (subGroupsExist && levelExceeds) {
      const message = `Levels beyond ${maxLevels} not allowed. Skipped items for ${id}`;
      logger.warn("GroupedCheckboxList", message);
    }
  }, [id, levelExceeds, maxLevels, subGroupsExist]);

  const checked = selection[id] || false;

  const childrenIds = useMemo(() => (depGraph.hasNode(id) ? depGraph.dependantsOf(id) : []), [depGraph, id]);

  const indeterminate = useMemo(() => {
    const numChildrenSelected = childrenIds.filter(i => selection[i]).length;
    const partialSelection = numChildrenSelected > 0 && numChildrenSelected !== childrenIds.length;
    return partialSelection;
  }, [childrenIds, selection]);

  const [collapsed, setCollapsed] = useState(!checked || !indeterminate);

  useEffect(() => {
    if (checked || indeterminate) {
      setCollapsed(false);
    }
  }, [checked, indeterminate]);

  const toggleCollapse = useCallback(() => {
    if (renderSubGroups) {
      setCollapsed(prev => !prev);
    }
  }, [renderSubGroups]);

  const onChangeItem = useCallback(
    (e: any, checked: boolean) => {
      const depIds = [...childrenIds, id];
      const numDeps = depIds.length;
      const values = new Array(numDeps).fill(checked);
      const nCurrSelection = zipObject(depIds, values);
      const nSelection = {
        ...(selection || {}),
        ...nCurrSelection
      };

      /**
       * Check dependants of parent and see if all of them are selected.
       * If not, deselect parent too.
       */
      if (parentId) {
        const parentDeps = depGraph.dependantsOf(parentId);
        const parentDepsSelectionExists = parentDeps.some(i => nSelection[i]);
        if (!parentDepsSelectionExists) {
          nSelection[parentId] = false;
        }
      }

      onChange(nSelection);
    },
    [childrenIds, depGraph, id, onChange, parentId, selection]
  );

  const appliedClassName = useMemo(
    () => cx("grouped-checkbox-list--list-item", "inc-flex-column", iClassName),
    [iClassName]
  );

  const label = useMemo(() => {
    const formattedLabel = labelFormatter ? labelFormatter(name as string, dataType) : name;

    const labelClassName = cx("inc-flex-row", "inc-flex-center-vertical", "inc-flex-space-contents", {
      "inc-cursor-pointer": renderSubGroups
    });

    const Icon = collapsed ? <DownCircleIcon className="marginLt8" /> : <UpCircleIcon className="marginLt8" />;
    return (
      <div
        className={labelClassName}
        style={{ width: "100%" }}
      >
        <>{formattedLabel}</>
        {renderSubGroups && <div onClick={toggleCollapse}>{Icon}</div>}
      </div>
    );
  }, [collapsed, dataType, labelFormatter, name, renderSubGroups, toggleCollapse]);

  return (
    <div className={appliedClassName}>
      <div className="paddingLt12 checkbox-list-item">
        <div style={{ marginLeft: (currentLevel - 1) * 14 }}>
          <IncCheckbox
            checked={checked}
            indeterminate={indeterminate}
            label={label}
            labelProps={checkboxLabelProps}
            onChange={onChangeItem}
            skipChangeOnLabelClick={skipChangeOnLabelClick}
          />
        </div>
      </div>
      {!collapsed && (
        <RecursiveRenderer
          {...props}
          currentLevel={currentLevel + 1}
          items={subGroups}
          parentId={id}
        />
      )}
    </div>
  );
};

const checkboxLabelProps: IncCheckboxProps["labelProps"] = {
  placement: "end"
};

const updateDependencyGraph = <T extends BaseType = BaseType>(
  depGraph: DepGraph<T>,
  groups: Array<GroupedCheckboxItem<T>>,
  currentLevel: number,
  maxLevels: number,
  parentId?: string
) => {
  const canContinue = groups?.length && currentLevel < maxLevels;

  if (!canContinue) {
    return;
  }

  groups.forEach(({ data, id, subGroups }) => {
    depGraph.addNode(id, data);
    if (parentId) {
      depGraph.addDependency(id, parentId);
    }

    updateDependencyGraph(depGraph, subGroups, currentLevel + 1, maxLevels, id);
  });
};
