import React, { FC, useCallback, useMemo } from "react";
import { IncButton, IncFaIcon, IncSelect, IncSelectOption } from "@inception/ui";
import { cloneDeep, last } from "lodash";
import { VerticallyCenteredRow } from "../../../../components";
import { LogicalOperator } from "../../../../services/api/explore";
import {
  AccumulatorBasedTrigger,
  OpStartTrigger,
  TriggerCondition,
  TriggerType,
  WindowTriggerCondition
} from "../../../../services/api/operationalise";
import { generateId } from "../../../../core";
import { AccumTrigger } from "./AccumTrigger";
import { WindowTrigger } from "./WindowTrigger";

interface Props {
  startTrigger: OpStartTrigger;
  onChange: (trigger: OpStartTrigger) => void;

  altComparatorText: string;
  comparatorText: string;
  scheduleText: string;
  metricName: string;

  editorOnly?: boolean;
  readOnly?: boolean;
}

export const TriggersEditorWrapper: FC<Props> = props => {
  const {
    onChange,
    startTrigger,
    comparatorText,
    altComparatorText = "",
    scheduleText,
    metricName,
    readOnly = false,
    editorOnly = false
  } = props;

  const { allMatchStartTrigger, anyMatchStartTrigger } = startTrigger;

  const triggerConditions = allMatchStartTrigger?.triggerConditions || anyMatchStartTrigger?.triggerConditions;
  useMemo(() => {
    if (triggerConditions) {
      triggerConditions.forEach(cond => (cond.id = cond.id || generateId()));
    }
  }, [triggerConditions]);

  const logicalOperator = anyMatchStartTrigger ? LogicalOperator.OR : LogicalOperator.AND;
  const logicalOperatorOpt = logicalOperatorOpts.find(({ value }) => value === logicalOperator);
  const onLogicalOperatorChange = useCallback(
    (opt: LogicalOperatorOpt) => {
      const operator = opt.data;
      const nTrigger: OpStartTrigger = {
        allMatchStartTrigger:
          operator === LogicalOperator.AND
            ? {
                triggerConditions
              }
            : null,
        anyMatchStartTrigger:
          operator === LogicalOperator.OR
            ? {
                triggerConditions
              }
            : null
      };

      onChange(nTrigger);
    },
    [onChange, triggerConditions]
  );

  const onConditionsChange = useCallback(
    (triggerConditions: TriggerCondition[]) => {
      onChange({
        allMatchStartTrigger:
          logicalOperator === LogicalOperator.AND
            ? {
                triggerConditions
              }
            : null,
        anyMatchStartTrigger:
          logicalOperator === LogicalOperator.OR
            ? {
                triggerConditions
              }
            : null
      });
    },
    [logicalOperator, onChange]
  );

  const triggerTypeOpts = useMemo(() => getTriggerTypeOpts(metricName), [metricName]);

  const onAddTrigger = useCallback(() => {
    const nextTriggerConditions = [...triggerConditions];
    const lastCondition = last(nextTriggerConditions);
    nextTriggerConditions.push({
      ...cloneDeep(lastCondition),
      id: generateId()
    });

    onConditionsChange(nextTriggerConditions);
  }, [onConditionsChange, triggerConditions]);

  const onRemoveTrigger = useCallback(
    (idx: number) => {
      const nextTriggerConditions = triggerConditions.filter((_, i) => i !== idx);
      onConditionsChange(nextTriggerConditions);
    },
    [onConditionsChange, triggerConditions]
  );

  const onTriggerTypeChange = useCallback(
    (opt: TriggerTypeOption, idx: number, id: string) => {
      const type = opt.data;
      const nextTriggerCondition: TriggerCondition = {
        id,
        accumulatorTriggerV2:
          type === TriggerType.ACCUMULATOR
            ? {
                unitDiff: 30
              }
            : null,
        windowTrigger:
          type === TriggerType.WINDOW
            ? {
                howManyTimesToViolate: 3,
                outOfPoints: 5
              }
            : null,
        accumulatorTrigger: null
      };

      const nextTriggerConditions = [...triggerConditions];
      nextTriggerConditions[idx] = nextTriggerCondition;

      onConditionsChange(nextTriggerConditions);
    },
    [onConditionsChange, triggerConditions]
  );

  const onTriggerConditionChange = useCallback(
    (condition: TriggerCondition, idx: number) => {
      const nextTriggerConditions = [...triggerConditions];
      nextTriggerConditions[idx] = condition;
      onConditionsChange(nextTriggerConditions);
    },
    [onConditionsChange, triggerConditions]
  );

  const canRemove = triggerConditions.length > 1 && !readOnly;
  const triggerConditionsJSX = useMemo(
    () =>
      triggerConditions.map((condition, idx) => {
        const { id, accumulatorTrigger, accumulatorTriggerV2, windowTrigger } = condition;

        const logicalOperatorJsx = (
          <>
            {idx === 1 && (
              <IncSelect
                autoAdjustWidth
                isSearchable={false}
                onChange={onLogicalOperatorChange}
                options={logicalOperatorOpts}
                value={logicalOperatorOpt}
              />
            )}
            {idx > 1 && (
              <VerticallyCenteredRow className="inc-label-common">{logicalOperatorOpt.label}</VerticallyCenteredRow>
            )}
          </>
        );

        const onTriggerTypeChangeInternal = (opt: TriggerTypeOption) => onTriggerTypeChange(opt, idx, id);
        const triggerTypeOpt = triggerTypeOpts.find(
          ({ value }) =>
            (accumulatorTrigger && value === TriggerType.ACCUMULATOR) ||
            (windowTrigger && value === TriggerType.WINDOW) ||
            (accumulatorTriggerV2 && value === TriggerType.ACCUMULATOR)
        );
        const triggerTypeJsx = (
          <>
            <VerticallyCenteredRow className="inc-label-common">
              {idx === 0 ? "Based on" : "based on"}
            </VerticallyCenteredRow>

            <IncSelect
              autoAdjustWidth
              isSearchable={false}
              onChange={onTriggerTypeChangeInternal}
              options={triggerTypeOpts}
              value={triggerTypeOpt}
            />
          </>
        );

        const onAccumTriggerChange = (accumulatorTrigger: AccumulatorBasedTrigger) =>
          onTriggerConditionChange(
            {
              accumulatorTrigger,
              windowTrigger: null,
              accumulatorTriggerV2: null,
              id
            },
            idx
          );

        const onAccumTriggerV2Change = (accumulatorTriggerV2: AccumulatorBasedTrigger) =>
          onTriggerConditionChange(
            {
              accumulatorTriggerV2,
              windowTrigger: null,
              accumulatorTrigger: null,
              id
            },
            idx
          );

        const onWindowTriggerChange = (windowTrigger: WindowTriggerCondition) =>
          onTriggerConditionChange(
            {
              windowTrigger,
              accumulatorTrigger: null,
              accumulatorTriggerV2: null,
              id
            },
            idx
          );

        const onRemove = () => onRemoveTrigger(idx);

        return (
          <VerticallyCenteredRow
            className="flex-gap-6"
            key={id}
          >
            {logicalOperatorJsx}
            {triggerTypeJsx}

            {Boolean(accumulatorTrigger) && (
              <AccumTrigger
                comparatorText={altComparatorText}
                metricName={metricName}
                onChange={onAccumTriggerChange}
                readOnly={readOnly}
                trigger={accumulatorTrigger}
              />
            )}

            {Boolean(accumulatorTriggerV2) && (
              <AccumTrigger
                comparatorText={altComparatorText}
                isV2
                metricName={metricName}
                onChange={onAccumTriggerV2Change}
                readOnly={readOnly}
                trigger={accumulatorTriggerV2}
              />
            )}

            {Boolean(windowTrigger) && (
              <WindowTrigger
                comparatorText={comparatorText}
                onChange={onWindowTriggerChange}
                scheduleText={scheduleText}
                trigger={windowTrigger}
              />
            )}

            {canRemove && (
              <IncFaIcon
                className="status-danger inc-cursor-pointer"
                iconName="minus-circle"
                onClick={onRemove}
                title="Remove"
              />
            )}
          </VerticallyCenteredRow>
        );
      }),
    [
      altComparatorText,
      canRemove,
      comparatorText,
      logicalOperatorOpt,
      metricName,
      onLogicalOperatorChange,
      onRemoveTrigger,
      onTriggerConditionChange,
      onTriggerTypeChange,
      readOnly,
      scheduleText,
      triggerConditions,
      triggerTypeOpts
    ]
  );

  const editorOnlyJsx = (
    <div className="triggers-editor inc-flex-column width-100 flex-gap-8">
      {triggerConditionsJSX}
      {!readOnly && (
        <IncButton
          className="marginTp10 width-fit-content"
          color="link"
          iconName="plus"
          iconType="iconText"
          label="Add Condition"
          onClick={onAddTrigger}
          size="regular"
        />
      )}
    </div>
  );

  return (
    <>
      {editorOnly && editorOnlyJsx}
      {!editorOnly && (
        <div className="op-triggers-editor">
          <VerticallyCenteredRow className="inc-text-body-medium inc-text-inactive marginBt16">
            Trigger
          </VerticallyCenteredRow>

          {editorOnlyJsx}
        </div>
      )}
    </>
  );
};

type LogicalOperatorOpt = IncSelectOption<LogicalOperator>;

type TriggerTypeOption = IncSelectOption<TriggerType>;

const getTriggerTypeOpts = (metricName: string): TriggerTypeOption[] => [
  {
    label: "evaluation period",
    value: TriggerType.WINDOW,
    data: TriggerType.WINDOW
  },
  {
    label: metricName,
    value: TriggerType.ACCUMULATOR,
    data: TriggerType.ACCUMULATOR
  }
];

const logicalOperatorOpts: LogicalOperatorOpt[] = [
  {
    label: "AND",
    value: LogicalOperator.AND,
    data: LogicalOperator.AND
  },
  {
    label: "OR",
    value: LogicalOperator.OR,
    data: LogicalOperator.OR
  }
];
