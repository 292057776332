import React, { FC, useCallback } from "react";
import { logger, useNotifications } from "../../../core";
import { OpSimulationStatus, SeriesFilter, operationaliseV2ApiService } from "../../../services/api/operationalise";
import { PRIMARY_CONFIG_AS_SIMULATION_KEY, TEMPORARY_SIMULATION_KEY } from "../../constants";
import { snoozeSeries, useOpStore } from "../../context";
import { OpResultsRenderer } from "./OpResultsRenderer";

interface Props {}

export const OpResultsRendererWrapper: FC<Props> = () => {
  const { state, dispatch } = useOpStore();

  const { context, op10zeId, selectedSimulation, simulationStateMap, opCreationConfig } = state;

  const { entityTypeInfo, entityTypeId, demoDataParams, widgetId } = context;
  const entityTypeName = entityTypeInfo?.name || entityTypeId;

  const { simulationId } = selectedSimulation || {};
  const fSimulationId =
    simulationId === TEMPORARY_SIMULATION_KEY
      ? null
      : simulationId === PRIMARY_CONFIG_AS_SIMULATION_KEY
        ? null
        : simulationId;

  const simulationState = simulationStateMap[fSimulationId];
  const { status } = simulationState || {};
  const isInProgress = status === OpSimulationStatus.IN_PROGERSS;

  const { notifySuccess, notifyError } = useNotifications();
  const onChangeSeriesFilter = useCallback(
    async (seriesFilters: SeriesFilter[]) => {
      try {
        let canUpdateState = true;
        const { error, message } = await operationaliseV2ApiService.snoozeSeriesConfig(op10zeId, {
          excludeSeriesFilterList: {
            seriesFilters
          }
        });
        if (error) {
          canUpdateState = false;
          notifyError("Error while snoozing series");
          logger.error("Training Dashboard", "Error while snoozing series", message);
        } else {
          notifySuccess("Series Snoozed successfully");
        }
        if (canUpdateState) {
          dispatch(snoozeSeries({ seriesFilters }));
        }
      } catch (error) {
        notifyError("Error while snoozing series");
        logger.error("Training Dashboard", "Error while snoozing series", error);
      }
    },
    [dispatch, notifyError, notifySuccess, op10zeId]
  );

  return (
    <OpResultsRenderer
      demoDataParams={demoDataParams}
      entityTypeId={entityTypeId}
      entityTypeName={entityTypeName}
      isInProgress={isInProgress}
      isPrimarySimulationOrOp={selectedSimulation?.isPrimary}
      onChangeSeriesFilter={onChangeSeriesFilter}
      opConfigId={op10zeId}
      opCreationConfig={opCreationConfig}
      simulationId={fSimulationId}
      widgetId={widgetId}
    />
  );
};
