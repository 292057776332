import React, { FC, memo } from "react";
import { cx } from "emotion";

interface Props {
  className?: string;
}

export const TypingAnimation: FC<Props> = memo(({ className = "" }) => (
  <div className={cx(className, "typing")}>
    <div className="typing__dot"></div>
    <div className="typing__dot"></div>
    <div className="typing__dot"></div>
  </div>
));
