import React, { ChangeEvent, FC, useCallback, useMemo, useState } from "react";
import { CorrelatedEventList } from "../../../../services/api/explore";
import EventTable from "./EventTable";
import ChangeEventCriteria from "./ChangeEventCriteria";
import { FieldsToFilter, convertCriteriaFieldsFromCorrelatedEvents } from "./utils";

type Props = {
  correlatedEvents: CorrelatedEventList[];
  isFetching: boolean;
  isError: boolean;
  error: string;
};

export const ChangeEventTable: FC<Props> = props => {
  const { correlatedEvents, isFetching, isError, error } = props;
  const [selectionMap, setSelectionMap] = useState<Record<string, boolean>>({});
  const [searchText, setSearchText] = useState("");

  const onSearchTextChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    setSearchText(evt.target.value);
  }, []);

  const fieldsToFilter: FieldsToFilter[] = useMemo(
    () => [
      {
        name: "bicycle",
        checkAbsolute: false
      },
      {
        name: "inception",
        checkAbsolute: false
      }
    ],
    []
  );

  const fieldsToSelect = useMemo(
    () => convertCriteriaFieldsFromCorrelatedEvents(correlatedEvents, searchText, fieldsToFilter, ["description"]),
    [correlatedEvents, fieldsToFilter, searchText]
  );

  const onSelectionChange = useCallback((key: string) => {
    setSelectionMap(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  }, []);

  const onSelectAll = useCallback(() => {
    const selectionMap: Record<string, boolean> = {};
    fieldsToSelect.forEach(ele => {
      selectionMap[ele.field] = true;
    });
    setSelectionMap(selectionMap);
  }, [fieldsToSelect]);

  const onRemoveAll = useCallback(() => {
    setSelectionMap({});
  }, []);

  return (
    <div className="events-table">
      <ChangeEventCriteria
        fieldsToSelect={fieldsToSelect}
        isFetching={isFetching}
        onRemoveAll={onRemoveAll}
        onSearchTextChange={onSearchTextChange}
        onSelectAll={onSelectAll}
        onSelectionChange={onSelectionChange}
        searchText={searchText}
        selectionMap={selectionMap}
      />
      <EventTable
        correlatedEvents={correlatedEvents}
        error={error}
        isError={isError}
        isFetching={isFetching}
        selectionMap={selectionMap}
      />
    </div>
  );
};
