import React, { FC, useMemo, useState, useCallback } from "react";
import { groupBy, zipObject, map } from "lodash";
import { IncFaIconName, IncCheckboxProps, IncFaIcon, IncCheckbox } from "@inception/ui";
import { css, cx } from "emotion";
import { CheckBoxList } from "../checkbox-list";
import { EntityIconRenderer } from "../entity-properties-popover/EntityNameRenderer";
import { dataTypeManager } from "../../utils";
import { USFieldInfoWithId } from "./types";

const MAX_LEVELS = 2;

type Props = {
  onSelectionChange: (fieldSelection: Record<string, boolean>) => void;
  fieldSelection: Record<string, boolean>;
  fields: USFieldInfoWithId[];
  searchText?: string;
  show?: boolean;
};

export const USEntityFieldsRenderer: FC<Props> = props => {
  const { fieldSelection, fields, onSelectionChange, searchText, show = false } = props;

  const groups = useMemo(() => groupBy(fields, f => f.usField.userServiceField.entityField.entityType), [fields]);
  const groupsChildren = useMemo(() => {
    const groupKeys = Object.keys(groups).sort();
    return groupKeys.map((groupKey, idx) => {
      const groupOptions = groups[groupKey];
      const key = `${groupKey}-${idx}`;

      return (
        <GroupRenderer
          fieldSelection={fieldSelection}
          fields={groupOptions}
          groupKey={groupKey}
          key={key}
          level={1}
          onSelectionChange={onSelectionChange}
          searchText={searchText}
          show={show}
        />
      );
    });
  }, [fieldSelection, groups, onSelectionChange, searchText, show]);

  return <>{groupsChildren}</>;
};

type GProps = Props & {
  level: number;
  groupKey: string; // This will be entityType
  groupLabel?: string;
};

const GroupRenderer: FC<GProps> = props => {
  const {
    fieldSelection,
    fields,
    groupKey,
    groupLabel = groupKey,
    level,
    onSelectionChange,
    searchText,
    show: tShow
  } = props;

  const [show, setShow] = useState(tShow);
  const [showField, setShowField] = useState(true);
  const iconName = useMemo<IncFaIconName>(() => (show ? "caret-up" : "caret-down"), [show]);

  const toggleShow = useCallback(() => setShow(prev => !prev), []);

  const { groupFields, nonGroupFields } = useMemo(() => separateFieldsByType(fields, level), [fields, level]);

  const entityField = useMemo(() => getEntityField(nonGroupFields), [nonGroupFields]);
  const entityFieldId = entityField?.id;
  const nonEntityFieldIds = useMemo(
    () => fields.map(({ id }) => id).filter(id => id !== entityFieldId),
    [entityFieldId, fields]
  );

  const onClearAll = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      const statusArr = new Array(nonEntityFieldIds.length).fill(false);
      const nSelection = zipObject(nonEntityFieldIds, statusArr);
      onSelectionChange(nSelection);
    },
    [nonEntityFieldIds, onSelectionChange]
  );

  const onChange = useCallback(
    (id: string, checked: boolean) => {
      const nSelection = { [id]: checked };
      if (checked) {
        nSelection[entityFieldId] = true;
      }
      onSelectionChange(nSelection);
    },
    [entityFieldId, onSelectionChange]
  );

  const entityFieldSelectionChanged = useCallback(
    (e: React.ChangeEvent, checked: boolean) => {
      const entityIds = [...nonEntityFieldIds, entityFieldId];
      const statusArr = new Array(entityIds.length + 1).fill(checked);
      const nSelection = zipObject(entityIds, statusArr);
      onSelectionChange(nSelection);
    },
    [entityFieldId, nonEntityFieldIds, onSelectionChange]
  );

  const nonGroupCheckboxProps = useMemo(() => {
    const filteredFields = (nonGroupFields || []).filter(field => field.displayName.toLowerCase().includes(searchText));
    if (filteredFields.length > 0) {
      setShowField(true);
    } else if (searchText) {
      setShowField(false);
    }
    return getCheckboxProps(filteredFields, onChange, fieldSelection);
  }, [fieldSelection, nonGroupFields, onChange, searchText]);

  const checkboxList = useMemo(() => {
    const sortedOpts = sortOptions(nonGroupCheckboxProps);
    return sortedOpts.length > 0 ? (
      <CheckBoxList
        onChange={onChange}
        options={sortedOpts}
      />
    ) : null;
  }, [nonGroupCheckboxProps, onChange]);

  let idx = 0;
  const groupsChildren = useMemo(
    () =>
      map(groupFields, (groupOptions, groupKey) => {
        const key = `${groupKey}-${idx++}`;
        return (
          <GroupRenderer
            fieldSelection={fieldSelection}
            fields={groupOptions}
            groupKey={groupKey}
            key={key}
            level={level + 1}
            onSelectionChange={onSelectionChange}
            searchText={searchText}
          />
        );
      }),
    [fieldSelection, groupFields, idx, level, onSelectionChange, searchText]
  );

  const fieldsExist = useMemo(
    () => Boolean(checkboxList) || groupsChildren.length > 0,
    [checkboxList, groupsChildren.length]
  );

  const label = useMemo(
    () => (
      <div
        className="inc-flex-row inc-flex-center-vertical"
        onClick={toggleShow}
      >
        <EntityIconRenderer
          className="icon-container"
          entityType={groupKey}
        />
        <span>{groupLabel}</span>
        {fieldsExist && (
          <>
            <IncFaIcon
              className="marginLt8 inc-cursor-pointer"
              iconName={iconName}
            />
            <div
              className="marginLt12 inc-cursor-pointer status-danger inc-text-element-medium"
              onClick={onClearAll}
            >
              Clear all
            </div>
          </>
        )}
      </div>
    ),
    [fieldsExist, groupKey, groupLabel, iconName, onClearAll, toggleShow]
  );

  const className = useMemo(
    () =>
      cx(
        "inc-flex-column",
        "flex-gap-12",
        css`
          margin-left: ${level * 20}px;
        `
      ),
    [level]
  );
  const checked = fieldSelection[entityFieldId] || false;

  return (
    <>
      {showField && (
        <>
          <IncCheckbox
            checked={checked}
            label={label}
            labelProps={{
              placement: "end"
            }}
            noImplicitHeight
            onChange={entityFieldSelectionChanged}
            skipChangeOnLabelClick
          />
          {show && (Boolean(groupsChildren?.length) || Boolean(checkboxList)) && (
            <div className={className}>
              {checkboxList}
              {groupsChildren}
            </div>
          )}
        </>
      )}
    </>
  );
};

const separateFieldsByType = (fields: USFieldInfoWithId[], level: number) => {
  if (level > MAX_LEVELS) {
    return {
      groupFields: {},
      nonGroupFields: fields
    };
  } else {
    const relLevel = level - 1;
    const groups = groupBy(fields, f => f.usField.userServiceField.entityField?.relNames?.[relLevel]?.entityType);
    const nonGroupFields = groups["undefined"];
    delete groups["undefined"];

    return {
      groupFields: groups,
      nonGroupFields
    };
  }
};

const getEntityField = (fields: USFieldInfoWithId[]) =>
  (fields || []).find(field => {
    const { usField } = field;
    const { userServiceField } = usField;
    const { entityField } = userServiceField;
    const { propName } = entityField || {};

    return isEntityProp(field) && !propName;
  });

const getCheckboxProps = (
  fields: USFieldInfoWithId[],
  onChange: (id: string, checked: boolean) => void,
  fieldSelection: Record<string, boolean>
): IncCheckboxProps[] => {
  const propsArr: IncCheckboxProps[] = [];
  fields.forEach(field => {
    const { usField, id } = field;
    const { userServiceField } = usField;
    const { entityField } = userServiceField;
    const { propType, propName } = entityField;
    const entityProp = isEntityProp(field);

    if (!entityProp) {
      const label = (
        <div className="inc-flex-row inc-flex-center-vertical">
          {propName}
          {propType !== "NA" && (
            <div
              className="marginLt6"
              title={propType}
            >
              {dataTypeManager.getDataTypeDescriptor(propType as any).getIcon({ height: 16 })}
            </div>
          )}
        </div>
      );

      const cbProps: IncCheckboxProps = {
        label,
        id,
        name: id,
        onChange: (e, checked) => onChange(id, checked),
        labelProps: {
          placement: "end"
        },
        checked: fieldSelection[id] || false,
        noImplicitHeight: true
      };
      propsArr.push(cbProps);
    }
  });
  return propsArr;
};

export const sortOptions = (options: IncCheckboxProps[]) => options.sort(sortFn);

const sortFn = (usfA: IncCheckboxProps, usfB: IncCheckboxProps) => {
  const { name: nameA } = usfA;
  const { name: nameB } = usfB;

  return nameA.localeCompare(nameB);
};

const isEntityProp = (field: USFieldInfoWithId) => {
  const { usField } = field;
  const { userServiceField } = usField;
  const { entityField } = userServiceField;
  const { propType } = entityField;

  return propType === "NA";
};
