import { generateId, IncInfoIcon, IncTextfield, IncToolTip } from "@inception/ui";
import { isEqual } from "lodash";
import React, { memo, useCallback, useState, useEffect, useMemo } from "react";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../../../../components";
import {
  ParamValueTemplate,
  ActionConfigUIParam,
  operationaliseV2ApiService,
  ActionTemplateElementsResponse,
  TemplateCanvasSection,
  SectionElementType
} from "../../../../../../services/api/operationalise";
import { SingleLineTemplateEditor } from "../../../../actions/template-editor-v2";
import { ActionParamsEditorProps } from "../types";

type ParamProps = ActionParamsEditorProps & {
  uiParam: ActionConfigUIParam;
  onChange: (nValue: ParamValueTemplate) => void;
  value: ParamValueTemplate;

  disabled?: boolean;
  filterOutLoopElements?: boolean;

  useNewTemplateEditor: boolean;
};

export const SingleLineTemplateEditorWrapper = memo<ParamProps>(props => {
  const {
    onChange,
    uiParam,
    value,
    opCreationConfig,
    actionCategoryType,
    sourceTypeId,
    bizActionConfig,
    op10zeId,
    filterOutLoopElements = false,
    useNewTemplateEditor,
    disabled = false
  } = props;

  const { visible, editable, description, label, name: paramName, required } = uiParam;

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [designerTemplate, setDesignerTemplate] = useState<ActionTemplateElementsResponse>(null);

  const fetchTemplateElements = useCallback(async () => {
    setIsFetching(true);
    const { data } = await operationaliseV2ApiService.getTemplateElements(
      actionCategoryType,
      sourceTypeId,
      paramName,
      bizActionConfig,
      op10zeId,
      opCreationConfig
    );
    setDesignerTemplate(data);
    setIsFetching(false);
  }, [actionCategoryType, bizActionConfig, op10zeId, opCreationConfig, paramName, sourceTypeId]);

  useEffect(() => {
    if (!designerTemplate) {
      if (useNewTemplateEditor) {
        fetchTemplateElements();
      } else {
        setIsFetching(false);
      }
    }
  }, [designerTemplate, fetchTemplateElements, isFetching, useNewTemplateEditor]);

  const { templateSection, value: valueObj } = value || {};
  const strVal = valueObj?.stringVal;

  const section = useMemo<TemplateCanvasSection>(
    () =>
      templateSection || {
        sectionElementId: "rich-text-section",
        sectionId: generateId(),
        type: SectionElementType.RICH_TEXT,
        content: ""
      },
    [templateSection]
  );

  const { textElements, elementGroups } = useMemo(
    () => ({
      textElements: designerTemplate?.textElement || [],
      elementGroups: designerTemplate?.elementGroup || []
    }),
    [designerTemplate]
  );

  const onSectionChange = useCallback(
    (section: TemplateCanvasSection) => {
      if (!isEqual(section, templateSection)) {
        onChange({
          templateCanvas: null,
          templateId: null,
          value: null,
          templateSection: section
        });
      }
    },
    [onChange, templateSection]
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      onChange({
        templateCanvas: null,
        templateId: null,
        templateSection: null,
        value: {
          stringVal: value
        }
      });
    },
    [onChange]
  );

  if (!visible) {
    return <></>;
  }

  const isDisabled = disabled || !editable;

  return (
    <div className="inc-flex-column marginBt12">
      {isFetching && (
        <LoadingSpinner
          className="inc-label-common inc-text-subtext-medium"
          titleText="Loading metadata..."
        />
      )}
      {!isFetching && (
        <>
          {useNewTemplateEditor && (
            <>
              <VerticallyCenteredRow className="inc-label-common marginBt8">
                {label}
                <IncToolTip
                  placement="top"
                  showArrow
                  titleText={description}
                >
                  <VerticallyCenteredRow>
                    <IncInfoIcon />
                  </VerticallyCenteredRow>
                </IncToolTip>
              </VerticallyCenteredRow>

              <SingleLineTemplateEditor
                elementGroups={elementGroups}
                filterOutLoopElements={filterOutLoopElements}
                onChange={onSectionChange}
                readOnly={isDisabled}
                section={section}
                textElements={textElements}
              />
            </>
          )}
          {!useNewTemplateEditor && (
            <IncTextfield
              containerClassName="marginBt12"
              disabled={isDisabled}
              helpText={description}
              label={label}
              onChange={onTextChange}
              required={required}
              value={strVal}
            />
          )}
        </>
      )}
    </div>
  );
});
