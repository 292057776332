import React, { FC, useMemo, useCallback } from "react";
import { IncRTable, IncRTableProps } from "@inception/ui";
import { DataFrame } from "../../../../../../core";
import { getFormattedValue } from "../utils";
import { useCommonRendererFunctionality } from "../common";
import { USFWRendererProps } from "..";
import { getTableColumns, getTableData } from "./utils";

export const TableRenderer: FC<USFWRendererProps> = props => {
  const {
    dataType,
    currencyType,
    noDataElement,
    loadingElement,
    aggregatedTags,
    displayAggregatedTags,
    properties: widgetProperties,
    timeRange
  } = props;

  const { table: tableProperties, downsample } = widgetProperties;

  const { data, fieldName, metricName, isFetching, isError, dataExists } = useCommonRendererFunctionality(props);

  const valueFormatter = useCallback(
    (value: number) => getFormattedValue(fieldName, value || "", dataType, currencyType),
    [fieldName, dataType, currencyType]
  );

  const { dataFrames, entityLookup } = useMemo(() => {
    const dataFrames: DataFrame[] = [];
    const entityLookup: Record<string, string> = {};

    data?.forEach(datum => {
      const postAggData = datum.postAggResult.data;
      const refId = Object.keys(postAggData)[0];
      const dfs = postAggData[refId]?.data || [];

      dataFrames.push(...dfs);
    });

    dataFrames.forEach(df => {
      const { labels = {}, eLabels = labels } = df;

      const keys = Object.keys(labels);
      keys.forEach(key => {
        const rawValue = labels[key];
        entityLookup[rawValue] = eLabels[key];
      });
    });

    return {
      dataFrames,
      entityLookup
    };
  }, [data]);

  const tableColumns = useMemo(
    () =>
      getTableColumns(
        aggregatedTags,
        displayAggregatedTags,
        metricName,
        downsample,
        timeRange,
        tableProperties,
        entityLookup,
        valueFormatter
      ),
    [
      aggregatedTags,
      displayAggregatedTags,
      downsample,
      entityLookup,
      metricName,
      tableProperties,
      timeRange,
      valueFormatter
    ]
  );

  const tableData = useMemo(() => getTableData(dataFrames, aggregatedTags), [aggregatedTags, dataFrames]);

  if (isFetching) {
    return loadingElement;
  }

  if (!dataExists || isError) {
    return noDataElement;
  }

  return (
    <IncRTable
      columns={tableColumns}
      data={tableData}
      density="normal"
      pagination={pagination}
      resizableColumns
      showDisplayStats
    />
  );
};

const pagination: IncRTableProps["pagination"] = {
  pageSize: 20,
  enabled: true,
  viewMode: "minimal"
};
