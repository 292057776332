import React, { FC, useMemo, useCallback, useRef, useState, memo } from "react";
import { IncPopper, CurrencyType, getCurrencyFormatter, IncChangeRenderer, Card } from "@inception/ui";
import { cx } from "emotion";
import { SingleStatWithCompare, VerticallyCenteredRow } from "../../components";

type CRProps = {
  change: number;
  changeType: "absolute" | "percent" | "none";
  current: number;
  compare: number;
  compareTimeStr: string;
  dataType: any;
  currencyType: CurrencyType;
  valueFormatter?: (value: number) => string;
  onClick?: () => void;
  hideCompare?: boolean;
};

export const ChangeDataRenderer: FC<CRProps> = memo(props => {
  const {
    change,
    changeType,
    compare,
    current,
    compareTimeStr,
    dataType,
    currencyType,
    valueFormatter,
    hideCompare = false
    // onClick: pOnClick
  } = props;

  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement>();

  const absChange = Math.abs(change);
  const changeDirection = change === 0 || changeType === "none" ? "none" : change > 0 ? "up" : "down";

  const onMouseEnter = useCallback(() => {
    if (!hideCompare) {
      setShow(true);
    }
  }, [hideCompare]);

  const onMouseLeave = useCallback(() => {
    if (!hideCompare) {
      setShow(false);
    }
  }, [hideCompare]);

  // const onClick = useCallback(() => {
  //   if (pOnClick) {
  //     pOnClick();
  //   }
  // }, [pOnClick]);

  const chType = changeType === "percent" ? "percent" : "absolute";

  const currencyFormatter = useMemo(() => getCurrencyFormatter(currencyType, true), [currencyType]);
  const formatChangeValue = useCallback((value: number) => currencyFormatter.format(value), [currencyFormatter]);
  const formatter = valueFormatter
    ? valueFormatter
    : chType === "absolute" && dataType === "CURRENCY"
      ? formatChangeValue
      : null;

  const className = cx("inc-flex-row inc-flex-center-horizontal", []);

  return (
    <>
      <VerticallyCenteredRow
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref}
      >
        <IncChangeRenderer
          changeType={chType}
          direction={changeDirection}
          formatter={formatter}
          value={absChange}
        />
        {/* {pOnClick &&
        <span className="inc-cursor-pointer marginLt8" onClick={onClick}>
          <IncFaIcon iconName="chart-line" />
        </span>
      } */}
      </VerticallyCenteredRow>
      <IncPopper
        anchorEl={ref.current}
        placement="top"
        show={show}
      >
        <Card classNames={["ecs-single-stat ecs-single-stat--with-padding"]}>
          <SingleStatWithCompare
            alwaysShowCompare
            compareData={compare}
            compareTimeStr={compareTimeStr}
            currencyType={currencyType}
            data={current}
            dataFormatter={valueFormatter}
            dataType={dataType}
          />
        </Card>
      </IncPopper>
    </>
  );
});
