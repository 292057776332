import { IncModal } from "@inception/ui";
import React, { FC, memo } from "react";
import { OpRunHistoryWrapper } from "./OpRunHistoryWrapper";

interface Props {
  opId: string;
  isOpen: boolean;
  onClose: () => void;
  title?: string | JSX.Element;
}

export const OpRunHistoryModal: FC<Props> = memo(props => {
  const { opId, onClose, isOpen, title = "Op History" } = props;

  return (
    <IncModal
      onClose={onClose}
      show={isOpen}
      titleText={title}
      withTitleBorder
    >
      <OpRunHistoryWrapper opId={opId} />
    </IncModal>
  );
});
