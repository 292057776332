import React, { ReactElement } from "react";
import { ValueContainerProps, components, OptionProps } from "react-select";
import { FilterOption } from "../utils";

export const Option = React.memo((props: OptionProps<FilterOption, true>) => (
  <div>
    <components.Option {...props}>
      <input
        checked={props.isSelected}
        onChange={() => null}
        type="checkbox"
      />
      <span style={{ paddingLeft: 6 }}>{props.label}</span>
    </components.Option>
  </div>
));

export const ValueContainer = React.memo(({ children, ...props }: ValueContainerProps<FilterOption, true>) => {
  const values = props.getValue().map(op => op.value);
  const diff = values.length - 2;

  const valsStr = values.slice(0, 2).join(", ");
  const addStr = diff > 0 ? ` & ${diff} more` : "";

  let str = valsStr + addStr;
  str = str || (props.selectProps.menuIsOpen ? "" : "Select");

  return (
    <>
      <span className="inc-text-subtext-medium marginLt8">{str}</span>
      {React.Children.map(children as ReactElement[], (child: ReactElement) =>
        child && child.props.id && child.props.id.indexOf("-input") !== -1 ? child : null
      )}
    </>
  );
});
