import { FieldPickerOptionData, PickerFieldType } from "../../services/api/explore";
import { FieldPickerRow } from "../../field-picker";

export const metricCompareFunction = (
  original: FieldPickerRow<"bizEntityMetric" | "userServiceMetric">,
  option: FieldPickerRow<"bizEntityMetric" | "userServiceMetric">
): boolean => original.fieldLabel === option.fieldLabel;

export function getFieldPickerOptionData(row: FieldPickerRow<PickerFieldType>): FieldPickerOptionData {
  return {
    type: row.fieldType,
    payload: row.field,
    readOnly: row.readOnly || false
  };
}
