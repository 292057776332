import { clone, cloneDeep, isEqual, lowerCase, startCase, upperCase } from "lodash";
import React, { FC, useEffect, useState, useCallback, memo, useMemo, useRef } from "react";
import {
  IncButton,
  IncClickAwayPopper,
  IncNumericField,
  IncRadioButton,
  IncSelect,
  IncSelectOption,
  IncTextfield
} from "@inception/ui";
import { CypressConstants } from "@bicycle/tests";
import { VerticallyCenteredRow } from "../../../../components";
import { MetricAggregations, useToggleState } from "../../../../core";
import { TimeObj } from "../../../../services/api/explore";
import {
  OpThresholdSeasonality,
  OpThresholdSimpleFunction,
  StaticThresholdDef,
  ThresholdDownSampleFunc
} from "../../../../services/api/operationalise";
import { getLabelForTimeObj } from "../../../../utils/DurationUtils";
import { RollingFrequencyEditorV2 } from "../common";
import { getDefaultDownsampleFunction, getDefaultNonSeasonalRollingFreq, getSelfThreholdDefLabel } from "./utils";

interface Props {
  thresholdDef: StaticThresholdDef;
  onChange: (thresholdDef: StaticThresholdDef) => void;
  baseMetricRollingFreq: TimeObj;
  baseMetricAggFunction: string;

  isRateMetric: boolean;
  metricName: string;
}

type State = Pick<StaticThresholdDef, "lookBackDef" | "periodicDef">;

export const ManualThresholdEditorV2: FC<Props> = props => {
  const {
    isRateMetric,
    metricName,
    onChange: pOnChange,
    thresholdDef,
    baseMetricRollingFreq,
    baseMetricAggFunction
  } = props;

  const ref = useRef<HTMLDivElement>();

  const { close: onClose, open: onOpen, isOpen } = useToggleState();

  const onChange = useCallback(
    (state: State) => {
      pOnChange({
        ...thresholdDef,
        ...state
      });
    },
    [pOnChange, thresholdDef]
  );

  const { attributes } = CypressConstants.components.Operationalize.ThresholdEditor;

  return (
    <div className="manual-threshold-editor">
      <VerticallyCenteredRow
        className="inc-cursor-pointer"
        data-cy={attributes.manualThresholdTextToggle}
        onClick={onOpen}
        ref={ref}
      >
        <IncTextfield
          autoAdjustWidth
          className="inc-cursor-pointer"
          readOnly
          value={getSelfThreholdDefLabel(thresholdDef, metricName, baseMetricRollingFreq)}
        />
      </VerticallyCenteredRow>

      {isOpen && (
        <ManualThresholdPopper
          anchorEl={ref.current}
          baseMetricAggFunction={baseMetricAggFunction}
          baseMetricRollingFreq={baseMetricRollingFreq}
          isRateMetric={isRateMetric}
          metricName={metricName}
          onChange={onChange}
          onClose={onClose}
          thresholdDef={thresholdDef}
        />
      )}
    </div>
  );
};

type CProps = Omit<Props, "onChange"> & {
  onChange: (state: State) => void;

  anchorEl: HTMLDivElement;
  onClose: () => void;
};

const ManualThresholdPopper: FC<CProps> = memo(cProps => {
  const {
    anchorEl,
    onClose,
    baseMetricRollingFreq,
    metricName,
    isRateMetric,
    baseMetricAggFunction: baseMetricRollingFunction,
    onChange,
    thresholdDef
  } = cProps;

  const supportsTrueLookBack =
    isRateMetric || (baseMetricRollingFunction !== "count" && baseMetricRollingFunction !== "sum");

  const { attributes } = CypressConstants.components.Operationalize.ThresholdEditor;

  const { lookBackDef: pLookBackDef, periodicDef: pPeriodicDef } = thresholdDef;

  const [state, setState] = useState<State>(
    cloneDeep({
      lookBackDef: pLookBackDef,
      periodicDef: pPeriodicDef
    })
  );

  useEffect(() => {
    setState(prev => {
      const nextState: State = {
        lookBackDef: pLookBackDef,
        periodicDef: pPeriodicDef
      };

      return isEqual(prev, nextState) ? prev : cloneDeep(nextState);
    });
  }, [pLookBackDef, pPeriodicDef]);

  const { lookBackDef, periodicDef } = state;

  const { toggle: toggleShowMore, isOpen: showMore } = useToggleState();

  const {
    rollingFreq: lookBackRollingFreq,
    downSampleFunction: lookBackDownSampleFunction,
    trueLookBack = false
  } = lookBackDef || {};

  const { baselineDataConfig, downSampleFunction: periodicDownSampleFunction } = periodicDef || {};

  const downsampleFunctionStr = useMemo(() => {
    const downsampleFunc = periodicDownSampleFunction || lookBackDownSampleFunction;
    return downsampleFunc ? getSelectedDownsampleFunctionName(downsampleFunc) : null;
  }, [lookBackDownSampleFunction, periodicDownSampleFunction]);

  const { seasonalDataConfig } = baselineDataConfig || {};
  const { numPeriods, seasonality } = seasonalDataConfig || {};

  const seasonalityOpt = useMemo(() => {
    if (lookBackDef) {
      return clone(nonSeasonalOption);
    }

    return seasonalityOptions.find(option => option.value === seasonality) || nonSeasonalOption;
  }, [lookBackDef, seasonality]);

  const onSeasonalityChange = useCallback((seasonalityOpt: SeasonalityOption) => {
    const { data } = seasonalityOpt;

    if (data === OpThresholdSeasonality.NONE) {
      setState(prev => {
        const next = clone(prev);
        next.lookBackDef = {
          rollingFreq: getDefaultNonSeasonalRollingFreq(),
          downSampleFunction: getDefaultDownsampleFunction()
        };
        next.periodicDef = null;

        return next;
      });
    } else {
      setState(prev => {
        const next = clone(prev);
        next.lookBackDef = null;
        next.periodicDef = {
          downSampleFunction: getDefaultDownsampleFunction(),
          ...(prev.periodicDef || {}),
          baselineDataConfig: {
            ...(prev.periodicDef?.baselineDataConfig || {}),
            seasonalDataConfig: {
              numPeriods: 4,
              ...(prev.periodicDef?.baselineDataConfig?.seasonalDataConfig || {}),
              seasonality: data
            }
          }
        };

        return next;
      });
    }
  }, []);

  const onPeriodsChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { valueAsNumber } = e.target;
    const nextPeriods = isNaN(valueAsNumber) ? 1 : valueAsNumber;

    setState(prev => {
      const next = clone(prev);
      next.periodicDef = {
        downSampleFunction: getDefaultDownsampleFunction(),
        ...(prev.periodicDef || {}),
        baselineDataConfig: {
          ...(prev.periodicDef?.baselineDataConfig || {}),
          seasonalDataConfig: {
            seasonality: OpThresholdSeasonality.WEEKLY,
            ...(prev.periodicDef?.baselineDataConfig?.seasonalDataConfig || {}),
            numPeriods: nextPeriods
          }
        }
      };

      return next;
    });
  }, []);

  const isPeriodEdit = Boolean(periodicDef);

  const baseMetricName = useMemo(() => {
    const rollingFreqPrefix = baseMetricRollingFreq ? getLabelForTimeObj(baseMetricRollingFreq, "lg", true) : "";

    return `${metricName} in the last ${rollingFreqPrefix}`;
  }, [baseMetricRollingFreq, metricName]);

  const trueLookBackMetricName = useMemo(() => {
    if (!lookBackDef) {
      return `${metricName} in last ${numPeriods} ${lowerCase(seasonalityOpt?.label || "")} periods`;
    } else {
      const rollingFreqPrefix = lookBackDef.rollingFreq ? getLabelForTimeObj(lookBackDef.rollingFreq, "lg", true) : "";

      return `${metricName} in last ${rollingFreqPrefix}`;
    }
  }, [lookBackDef, metricName, numPeriods, seasonalityOpt]);

  const onTrueLookBackChange = useCallback((e: any, checked: boolean) => {
    if (checked) {
      setState(prev => {
        const next = clone(prev);
        next.periodicDef = null;
        next.lookBackDef = {
          rollingFreq: getDefaultNonSeasonalRollingFreq(),
          ...(prev.lookBackDef || {}),
          trueLookBack: true
        };

        delete next.lookBackDef.downSampleFunction;

        return next;
      });
    }
  }, []);

  const onBaseMetricChange = useCallback((e: any, checked: boolean) => {
    if (checked) {
      setState(prev => {
        const next = clone(prev);
        next.periodicDef = null;
        next.lookBackDef = {
          rollingFreq: getDefaultNonSeasonalRollingFreq(),
          ...(prev.lookBackDef || {}),
          downSampleFunction: getDefaultDownsampleFunction()
        };

        delete next.lookBackDef.trueLookBack;

        return next;
      });
    }
  }, []);

  const onLookBackRollingFreqChange = useCallback((rollingFreq: TimeObj) => {
    setState(prev => {
      const next = clone(prev);
      next.lookBackDef = {
        ...(prev.lookBackDef || {}),
        rollingFreq
      };

      return next;
    });
  }, []);

  const onAggregatorFunctionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;

    setState(prev => {
      const next = clone(prev);

      const downsampleFunction = getDownsampleFunction(name);

      if (next.periodicDef) {
        next.periodicDef.downSampleFunction = downsampleFunction;
      } else if (next.lookBackDef) {
        next.lookBackDef.downSampleFunction = downsampleFunction;
        delete next.lookBackDef.trueLookBack;
      }

      return next;
    });
  }, []);

  const aggregatorRadioButtons = useMemo(() => {
    const attrs = attributes.manualAggregatorRadioButtons as Record<string, string>;
    const aggregators = showMore ? [...popularAggregators, ...moreAggregators] : popularAggregators;

    const aggregatorRadioButtons = aggregators.map(aggregator => {
      const label = aggregator.startsWith("P") ? aggregator : startCase(aggregator);

      return (
        <IncRadioButton
          checked={downsampleFunctionStr === aggregator}
          data-cy={attrs[aggregator]}
          key={aggregator}
          label={label}
          name={aggregator}
          onChange={onAggregatorFunctionChange}
        />
      );
    });

    return (
      <VerticallyCenteredRow className="inc-flex-row-wrap flex-gap-12 flex-row-gap-0">
        {aggregatorRadioButtons}
      </VerticallyCenteredRow>
    );
  }, [attributes.manualAggregatorRadioButtons, downsampleFunctionStr, onAggregatorFunctionChange, showMore]);

  const onApply = useCallback(() => {
    onChange(state);
    onClose();
  }, [onChange, onClose, state]);

  const onCancel = useCallback(() => {
    onChange({
      lookBackDef: pLookBackDef,
      periodicDef: pPeriodicDef
    });
    onClose();
  }, [onChange, pLookBackDef, pPeriodicDef, onClose]);

  const summary = useMemo(() => {
    const lThresholdDef = {
      ...thresholdDef,
      ...state
    };

    return getSelfThreholdDefLabel(lThresholdDef, metricName, baseMetricRollingFreq);
  }, [baseMetricRollingFreq, metricName, state, thresholdDef]);

  return (
    <IncClickAwayPopper
      anchorEl={anchorEl}
      className="manual-threshold-v2-popper"
      onClickAway={onCancel}
      show
    >
      <div className="manual-threshold-v2-popper--header">Manual Baseline</div>

      <div
        className="manual-threshold-v2-popper--body"
        data-cy={attributes.manualThresholdWrapper}
      >
        <div className="manual-threshold-v2-popper--section">
          <VerticallyCenteredRow className="width-100 flex-gap-12">
            <IncSelect
              alignment="row"
              autoAdjustWidth
              autoSort={false}
              classNamePrefix={attributes.manualSeasonalityDropdown}
              data-cy={attributes.manualSeasonalityDropdown}
              isSearchable={false}
              label="Seasonality"
              onChange={onSeasonalityChange}
              options={seasonalityOptions}
              value={seasonalityOpt}
            />

            <div className="vertical-line" />

            <VerticallyCenteredRow className="inc-label-common">Time Period</VerticallyCenteredRow>

            <VerticallyCenteredRow className="flex-gap-12">
              <VerticallyCenteredRow className="inc-text-subtext-medium">Last</VerticallyCenteredRow>

              {isPeriodEdit && (
                <>
                  <IncNumericField
                    containerClassName="periods-editor"
                    data-cy={attributes.manualPeriodsInput}
                    onChange={onPeriodsChange}
                    value={numPeriods}
                  />

                  <VerticallyCenteredRow className="inc-text-subtext-medium">
                    {lowerCase(seasonalityOpt.label || "")} periods
                  </VerticallyCenteredRow>
                </>
              )}

              {!isPeriodEdit && (
                <RollingFrequencyEditorV2
                  onChange={onLookBackRollingFreqChange}
                  rollingFreq={lookBackRollingFreq}
                  skipPrefix
                  skipQuickOptions
                />
              )}
            </VerticallyCenteredRow>
          </VerticallyCenteredRow>
        </div>

        {supportsTrueLookBack && Boolean(lookBackDef) && (
          <div
            className="manual-threshold-v2-popper--section"
            data-cy={attributes.manualLookbackRadios}
          >
            <div className="inc-text-body-medium">Metric</div>
            <VerticallyCenteredRow className="flex-gap-12">
              <IncRadioButton
                checked={trueLookBack}
                data-cy={attributes.manualTrueLookbackRadio}
                label={trueLookBackMetricName}
                onChange={onTrueLookBackChange}
              />

              <IncRadioButton
                checked={!trueLookBack}
                data-cy={attributes.manualBaseMetricRadio}
                label={baseMetricName}
                onChange={onBaseMetricChange}
              />
            </VerticallyCenteredRow>
          </div>
        )}

        {!trueLookBack && (
          <div className="manual-threshold-v2-popper--section">
            <div className="inc-text-body-medium">Function</div>
            <div className="aggregators-editor">
              <VerticallyCenteredRow className="inc-text-subtext-medium">Most popular</VerticallyCenteredRow>

              {aggregatorRadioButtons}

              <IncButton
                color="link"
                data-cy={attributes.manualAggregatorsShowMore}
                onClick={toggleShowMore}
                size="small"
              >
                {showMore ? "Show less" : "Show more"}
              </IncButton>
            </div>
          </div>
        )}

        <div className="manual-threshold-v2-popper--section">
          <div className="inc-text-body-medium">Summary</div>
          <div className="inc-label-common">{summary}</div>
        </div>
      </div>

      <div className="manual-threshold-v2-popper--footer">
        <IncButton
          color="primary"
          data-cy={attributes.manualApplyButton}
          onClick={onApply}
        >
          Apply
        </IncButton>

        <IncButton
          color="secondary-blue"
          onClick={onCancel}
        >
          Cancel
        </IncButton>
      </div>
    </IncClickAwayPopper>
  );
});

type SeasonalityOption = IncSelectOption<OpThresholdSeasonality>;

const nonSeasonalOption: SeasonalityOption = {
  label: "None",
  value: OpThresholdSeasonality.NONE,
  data: OpThresholdSeasonality.NONE
};

const seasonalityOptions: SeasonalityOption[] = [
  {
    label: "Hourly",
    value: OpThresholdSeasonality.HOURLY,
    data: OpThresholdSeasonality.HOURLY
  },
  {
    label: "Daily",
    value: OpThresholdSeasonality.DAILY,
    data: OpThresholdSeasonality.DAILY
  },
  {
    label: "Weekly",
    value: OpThresholdSeasonality.WEEKLY,
    data: OpThresholdSeasonality.WEEKLY
  },
  nonSeasonalOption
];

const dsFuncUpdaterMap = {
  average: (dsFunc: ThresholdDownSampleFunc) => (dsFunc.simpleFunction = OpThresholdSimpleFunction.AVG),
  min: (dsFunc: ThresholdDownSampleFunc) => (dsFunc.simpleFunction = OpThresholdSimpleFunction.MIN),
  max: (dsFunc: ThresholdDownSampleFunc) => (dsFunc.simpleFunction = OpThresholdSimpleFunction.MAX),
  distinctCount: (dsFunc: ThresholdDownSampleFunc) =>
    (dsFunc.simpleFunction = OpThresholdSimpleFunction.DISTINCT_COUNT),
  linearRegression: (dsFunc: ThresholdDownSampleFunc) => (dsFunc.linearRegression = true),
  simpleMovingAvg: (dsFunc: ThresholdDownSampleFunc) => (dsFunc.simpleMovingAvg = true),
  exponentialWeightedMovingAvg: (dsFunc: ThresholdDownSampleFunc) => (dsFunc.exponentialWeightedMovingAvg = true)
};

const popularAggregators = [
  "linearRegression",
  "average",
  MetricAggregations.P25,
  MetricAggregations.P50,
  MetricAggregations.P90
];

const moreAggregators = [
  MetricAggregations.P95,
  MetricAggregations.P99,
  "max",
  "min",
  "simpleMovingAvg",
  "exponentialWeightedMovingAvg"
];

const getSelectedDownsampleFunctionName = (downsampleFunc: ThresholdDownSampleFunc): string => {
  if (downsampleFunc.simpleFunction) {
    switch (downsampleFunc.simpleFunction) {
      case OpThresholdSimpleFunction.AVG:
        return "average";
      case OpThresholdSimpleFunction.MIN:
        return "min";
      case OpThresholdSimpleFunction.MAX:
        return "max";
      case OpThresholdSimpleFunction.DISTINCT_COUNT:
        return "distinctCount";
      default:
        if (downsampleFunc.simpleFunction === upperCase(OpThresholdSimpleFunction.AVG)) {
          return "average";
        }

        if (downsampleFunc.simpleFunction === upperCase(OpThresholdSimpleFunction.MIN)) {
          return "min";
        }

        if (downsampleFunc.simpleFunction === upperCase(OpThresholdSimpleFunction.MAX)) {
          return "max";
        }

        if (downsampleFunc.simpleFunction === upperCase(OpThresholdSimpleFunction.DISTINCT_COUNT)) {
          return "distinctCount";
        }

        return downsampleFunc.simpleFunction;
    }
  }

  if (downsampleFunc.linearRegression) {
    return "linearRegression";
  }

  if (downsampleFunc.simpleMovingAvg) {
    return "simpleMovingAvg";
  }

  if (downsampleFunc.exponentialWeightedMovingAvg) {
    return "exponentialWeightedMovingAvg";
  }

  return downsampleFunc.quantile;
};

const getDownsampleFunction = (name: string): ThresholdDownSampleFunc => {
  const downsampleFunction: ThresholdDownSampleFunc = {};

  const updaterFunction = (dsFuncUpdaterMap as any)[name];
  if (updaterFunction) {
    updaterFunction(downsampleFunction);
  } else {
    downsampleFunction.quantile = name;
  }

  return downsampleFunction;
};
