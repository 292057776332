import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { cloneDeep } from "lodash";
import { IncButton } from "@inception/ui";
import { BizDataQuery, DemoDataParams, WidgetConfigUtils, exploreApiService } from "../../../services/api/explore";
import { getWidgetConfigFromDto } from "../../../utils/ExploreUtils";
import { logger, useToggleState } from "../../../core";
import { ENTITY_TAG } from "../../../utils";
import DataQueryEditor from "./DataQueryEditor";
import { getMetricName } from "./utils";
import { ThresholdContext } from "./types";

type Props = {
  bizDataQuery: BizDataQuery;
  opBizDataQuery: BizDataQuery;
  entityTypeId?: string;
  eventTypeId?: string;
  onSave: (bizDataQuery: BizDataQuery) => void;
  context: ThresholdContext;
  demoDataParams?: DemoDataParams;
};

const CustomThresholdDataQuery: FC<Props> = props => {
  const {
    bizDataQuery: pBizDataQuery,
    entityTypeId,
    eventTypeId,
    onSave,
    opBizDataQuery,
    context,
    demoDataParams
  } = props;

  const { entityTypeName, eventTypeName } = context || {};
  const [bizDataQuery, setBizDataQuery] = useState<BizDataQuery>(null);

  const { close: stopFetching, isOpen: isFetching, open: startFetching } = useToggleState(true);

  const { close, isOpen, open } = useToggleState();

  const fetchOrCreateWidgetConfig = useCallback(async () => {
    if (pBizDataQuery) {
      const dBizDataQuery = cloneDeep(pBizDataQuery);
      const { id: widgetId, widgetConfig, buildingBlockConfig } = dBizDataQuery;
      startFetching();
      if (widgetConfig) {
        delete dBizDataQuery["id"];
        delete dBizDataQuery["buildingBlockConfig"];
      } else if (buildingBlockConfig) {
        delete dBizDataQuery["id"];
        const widgetConfigDto = WidgetConfigUtils.getWidgetConfigDtoFromBuildingBlockConfig(buildingBlockConfig);
        dBizDataQuery.widgetConfig = getWidgetConfigFromDto(widgetConfigDto);
        dBizDataQuery.sliceSpec.metricId = dBizDataQuery?.sliceSpec?.buildingBlockConfigId;
        delete dBizDataQuery?.sliceSpec?.buildingBlockConfigId;
        delete dBizDataQuery["buildingBlockConfig"];
      } else if (widgetId) {
        try {
          const { data, error, message } = await exploreApiService.getWidgetConfig(eventTypeId, entityTypeId, widgetId);
          const { widgetConfig: widgetConfigDto } = data || {};
          if (!error && widgetConfigDto) {
            dBizDataQuery.widgetConfig = getWidgetConfigFromDto(widgetConfigDto);
            delete dBizDataQuery["buildingBlockConfig"];
            delete dBizDataQuery["id"];
          } else {
            logger.error("CustomThresholdQueryEditor", "Error fetching widgetConfig", message);
          }
        } catch (error) {
          logger.error("CustomThresholdQueryEditor", "Error fetching widgetConfig", error);
        }
      }
      setBizDataQuery(dBizDataQuery);
      stopFetching();
    }
  }, [entityTypeId, eventTypeId, pBizDataQuery, startFetching, stopFetching]);

  useEffect(() => {
    fetchOrCreateWidgetConfig();
  }, [fetchOrCreateWidgetConfig]);

  const { sliceSpec, widgetConfig } = bizDataQuery || {};
  const { dataDefinition } = widgetConfig || {};
  const { metrics = {} } = dataDefinition || {};
  const { sliceSet, metricId } = sliceSpec || {};
  const { slices = [] } = sliceSet || {};
  const label = useMemo(() => {
    const sliceString = slices.length
      ? slices.map(slice => (slice.tagName === ENTITY_TAG ? entityTypeName || eventTypeName : slice.tagName)).join(", ")
      : "";
    const aggString = getMetricName(metrics, metricId);
    return sliceString ? `${aggString} by ${sliceString}` : aggString;
  }, [entityTypeName, eventTypeName, metricId, metrics, slices]);

  return (
    <>
      <IncButton
        color="link"
        loading={isFetching}
        loadingText="Fetching Config..."
        onClick={open}
      >
        {label}
      </IncButton>

      {isOpen && (
        <DataQueryEditor
          bizDataQuery={bizDataQuery}
          demoDataParams={demoDataParams}
          isOpen
          onClose={close}
          onSave={onSave}
          opBizDataQuery={opBizDataQuery}
          pickerContext={context}
        />
      )}
    </>
  );
};

export default CustomThresholdDataQuery;
