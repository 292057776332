import { IncModal } from "@inception/ui";
import { isNil, pick, random, uniq, uniqBy } from "lodash";
import React, { FC, useEffect, useCallback, useRef, useMemo } from "react";
import { css } from "emotion";
import { logger, useToggleState } from "../../core";
import { useCaseApiService, VerticalIdentifier } from "../../services/api";
import { SliceSet, UserServiceFieldSliceSet } from "../../services/api/explore";
import LoadingSpinner from "../Loading/Loading";
import { CustomSubscribeFilters, CustomSubscribeTemplateValues, PrefetchedTemplateInfo } from "../types";
import { SubscribeDialogV2 } from "./SubscribeDialog";

export interface SubscribePreviewProps {
  useCaseId: string;
  verticalIdentifier: VerticalIdentifier;

  opName: string;
  diagnosticSlices: UserServiceFieldSliceSet["slices"];
  alertingSlices: SliceSet["slices"];
  prefetchedTemplateInfo?: PrefetchedTemplateInfo;
}

export const SubscribePreview: FC<SubscribePreviewProps> = props => {
  const {
    useCaseId,
    verticalIdentifier: verticalInfo,
    alertingSlices: alertingSlices,
    diagnosticSlices: diagnosticSlices,
    opName: name,
    prefetchedTemplateInfo
  } = props;

  const { isUseCaseDiscoveryFetching = false, customTemplateValues, customFilters } = prefetchedTemplateInfo || {};

  const { companyName } = verticalInfo || {};

  const { causeTagNames, dimensions, impactTagNames, dataTypesByTagKey } = useMemo(() => {
    const dataTypesByTagKey: Record<string, string> = {};

    const impactTagNames = (alertingSlices || []).map(sl => {
      dataTypesByTagKey[sl.tagName] = sl.fieldType;
      return sl.tagName;
    });

    const causeTagNames = (diagnosticSlices || [])
      .map(sl => {
        dataTypesByTagKey[sl.tagName] = sl.userServiceField?.dataType || "STRING";
        return sl.tagName;
      })
      .filter(tagName => !impactTagNames.includes(tagName));

    return {
      dimensions: [...impactTagNames, ...causeTagNames].join(","),
      impactTagNames,
      causeTagNames,
      dataTypesByTagKey
    };
  }, [alertingSlices, diagnosticSlices]);

  const {
    isOpen: isLoadingValues,
    open: startLoadingValues,
    close: finishLoadingValues
  } = useToggleState(isNil(prefetchedTemplateInfo));

  const customTemplateValuesRef = useRef<CustomSubscribeTemplateValues>();

  const customFiltersRef = useRef<CustomSubscribeFilters>();

  const fetchSampleValues = useCallback(async () => {
    if (dimensions.length) {
      startLoadingValues();

      try {
        const sampleValues: Record<string, string[]> = {};

        const { data, error, message } = await useCaseApiService.getDimensions(useCaseId, {
          companyName,
          dimensions,
          sampleValueCount: 5,
          uniqueValueCount: 5
        });

        if (error) {
          logger.error("SubscribePreview", "Error fetching sample values", message);
        } else {
          (data?.dimensions?.dimensionResult || []).forEach(
            entry => {
              const { dimension } = entry;
              const { name, sampleValues: entrySampleValues } = dimension;

              sampleValues[name] = (entrySampleValues?.values || entrySampleValues?.value || [])
                .map(propValue => propValue.stringVal || String(propValue.longVal))
                .filter(Boolean);
              sampleValues[name] = uniq(sampleValues[name]);
            },
            {} as Record<string, string[]>
          );
        }

        const impactSampleValues = pick(sampleValues, impactTagNames);
        const impactCombinations = uniqBy(generateUniqueCombinations(impactSampleValues), JSON.stringify);

        const causeSampleValues = pick(sampleValues, causeTagNames);
        const causeCombinations = uniqBy(generateUniqueCombinations(causeSampleValues), JSON.stringify);

        customTemplateValuesRef.current = {
          salesforce: null,
          slack: [
            {
              title: name
            },
            {
              rows: impactCombinations.map(combination => ({
                tagKey: impactTagNames[0],
                tagValue: combination[impactTagNames[0]],
                impactPer: random(20, 60, false).toString()
              }))
            },
            {
              rows: causeCombinations.map(record => {
                const combinationValues = Object.values(record);

                return {
                  tagCombination: combinationValues.join(" + "),
                  value: random(20, 60, false).toString()
                };
              })
            }
          ]
        };

        customFiltersRef.current = {
          filters: [],
          sampleValues,
          dataTypes: dataTypesByTagKey
        };
      } catch (err) {
        logger.error("SubscribePreview", "JS Error fetching sample values", err);
      }
      finishLoadingValues();
    } else {
      finishLoadingValues();
    }
  }, [
    causeTagNames,
    companyName,
    dataTypesByTagKey,
    dimensions,
    finishLoadingValues,
    impactTagNames,
    name,
    startLoadingValues,
    useCaseId
  ]);

  useEffect(() => {
    if (!isUseCaseDiscoveryFetching && isNil(customTemplateValues) && isNil(customFilters)) {
      fetchSampleValues();
    } else {
      customFiltersRef.current = { ...customFilters };
      customTemplateValuesRef.current = { ...customTemplateValues };
    }
  }, [customFilters, customTemplateValues, fetchSampleValues, isUseCaseDiscoveryFetching]);

  return (
    <>
      {isLoadingValues && (
        <LoadingSpinner
          className={heightCx}
          titleText="Fetching actions..."
        />
      )}
      {!isLoadingValues && (
        <SubscribeDialogV2
          customFilters={customFiltersRef.current}
          customTemplateValues={customTemplateValuesRef.current}
          noModal
        />
      )}
    </>
  );
};

const heightCx = css`
  height: 350px !important;
`;

export type SubscribePreviewModalProps = SubscribePreviewProps & {
  onClose: () => void;
  title?: string;
};

export const SubscribePreviewModal: FC<SubscribePreviewModalProps> = props => {
  const { title, opName, onClose } = props;
  const titleText = title || `Subscribe - ${opName}`;

  return (
    <IncModal
      onClose={onClose}
      show
      size="xxlg"
      titleText={titleText}
    >
      <div className="width-100 paddingBt24">
        <SubscribePreview {...props} />
      </div>
    </IncModal>
  );
};

function generateUniqueCombinations(input: Record<string, string[]>, maxItems = 5): Array<Record<string, string>> {
  const keys = Object.keys(input);
  const combinations: Array<Record<string, string>> = [];

  let numGenerated = 0;

  // Recursive function to generate combinations
  function generate(index: number, currentCombination: Record<string, string>): void {
    if (numGenerated >= maxItems) {
      return;
    }

    if (index === keys.length) {
      combinations.push({ ...currentCombination });
      numGenerated++;
      return;
    }

    const key = keys[index];
    const values = input[key];

    for (const value of values) {
      currentCombination[key] = value;
      generate(index + 1, currentCombination);
    }
  }

  generate(0, {});

  return combinations;
}
