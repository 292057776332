import React, { FC, useState, useCallback, useEffect, useMemo, useRef } from "react";
import { Editor, SyntheticKeyboardEvent } from "react-draft-wysiwyg";
import { Map } from "immutable";
import {
  ContentBlock,
  ContentState,
  convertToRaw,
  DraftDecorator,
  DraftEditorCommand,
  DraftHandleValue,
  EditorState,
  RawDraftContentState
} from "draft-js";
import { isEqual } from "lodash";
import { cx } from "emotion";
import {
  TemplateCanvas,
  ActionTemplateElementsResponse,
  ActionTemplateSectionElement,
  SectionElementType,
  ActionTemplateTextElement
} from "../../../../../services/api/operationalise";
import { LoadingSpinner } from "../../../../../components";
import { useToggleState } from "../../../../../core";
import { ActionTemplateElements, templateBlockRendererFn, TextElementRenderer } from "../renderers";
import {
  addSectionToEditorState,
  addTextElementToEditorState,
  deleteSectionFromEditorState,
  getCanvasFromEditorState,
  getMatchRichTextToken,
  removeLinkFromEditorState,
  getSectionForSectionElement,
  splitSectionInEditorState,
  getUpdatedEditorState
} from "../utils";
import { SectionGroupElementsSelector } from "./SectionGroupElementsSelector";

interface Props {
  designerTemplate: ActionTemplateElementsResponse;

  templateCanvas: TemplateCanvas;
  onChange: (templateCanvas: TemplateCanvas) => void;
  overrideLastSavedWithIncoming?: boolean;

  onErrors?: (errors: string[], sectionKey: string) => void;
  filterOutLoopElements?: boolean;
  readOnly?: boolean;

  isLoading?: boolean;
}

export const MultiLineTemplateEditor: FC<Props> = props => {
  const {
    designerTemplate: pDesignerTemplate,
    onChange,
    templateCanvas: pTemplateCanvas,
    filterOutLoopElements = false,
    onErrors,
    readOnly: pReadOnly = false,
    isLoading = false,
    overrideLastSavedWithIncoming
  } = props;

  const { close: onBlur, isOpen: isFocussed, open: onFocus } = useToggleState();

  const [readOnly, setReadOnly] = useState(pReadOnly);
  const onChangeReadOnly = useCallback(
    (readOnly: boolean) => {
      if (!pReadOnly) {
        setReadOnly(readOnly);
      } else {
        setReadOnly(true);
      }
    },
    [pReadOnly]
  );

  const editorRef = useRef<Editor>();
  const initedRef = useRef(false);

  const defEditorState = useMemo(() => {
    const contentState = ContentState.createFromBlockArray([], Map({}));
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }, []);
  const [editorState, setEditorState] = useState<EditorState>(defEditorState);
  const saveEditorStateRef = useRef<RawDraftContentState>(convertToRaw(editorState.getCurrentContent()));
  const contentEditedRef = useRef(false);

  const lastUsedTemplateCanvasRef = useRef<TemplateCanvas>();
  const [templateCanvas, setTemplateCanvas] = useState<TemplateCanvas>(pTemplateCanvas);
  const [designerTemplate, setDesignerTemplate] = useState(pDesignerTemplate);

  useEffect(() => {
    initedRef.current = false;
    setDesignerTemplate(pDesignerTemplate);
  }, [pDesignerTemplate]);

  useMemo(() => {
    if (overrideLastSavedWithIncoming) {
      lastUsedTemplateCanvasRef.current = pTemplateCanvas;
    }
  }, [overrideLastSavedWithIncoming, pTemplateCanvas]);

  useEffect(() => {
    const lastUsedTemplateCanvas = lastUsedTemplateCanvasRef.current;
    const hasCanvasChanged = !isEqual(lastUsedTemplateCanvas, pTemplateCanvas);

    if (hasCanvasChanged) {
      initedRef.current = false;
      lastUsedTemplateCanvasRef.current = pTemplateCanvas;
      setTemplateCanvas(pTemplateCanvas);
    }
  }, [pTemplateCanvas]);

  const checkIfEditorStateUpdated = useCallback((editorState: EditorState) => {
    const prevSaveEditorState = saveEditorStateRef.current;
    const nextSaveEditorState = convertToRaw(editorState.getCurrentContent());

    const hasUpdated = !isEqual(prevSaveEditorState, nextSaveEditorState);

    return {
      hasEditorStateUpdated: hasUpdated,
      nextSaveEditorState
    };
  }, []);

  useEffect(() => {
    if (initedRef.current && contentEditedRef.current) {
      /**
       * EditorState state will change everytime we select, deselect, move cursor, etc. since this information
       * is stored as part of EditorState. We don't want to call on change in all these cases. So we pick just the
       * states that are required and contribute to the sections and compare those and conditionally call onChange.
       */
      const { hasEditorStateUpdated, nextSaveEditorState } = checkIfEditorStateUpdated(editorState);

      if (hasEditorStateUpdated) {
        const templateCanvas = getCanvasFromEditorState(editorState);
        lastUsedTemplateCanvasRef.current = templateCanvas;
        setTemplateCanvas(templateCanvas);
        onChange(templateCanvas);

        saveEditorStateRef.current = nextSaveEditorState;
      }

      contentEditedRef.current = false;
    }
  }, [checkIfEditorStateUpdated, editorState, onChange]);

  const textElements = useMemo(() => designerTemplate?.textElement || [], [designerTemplate]);

  useEffect(() => {
    if (!isLoading && designerTemplate && !initedRef.current) {
      const nextEditorState = getUpdatedEditorState(defEditorState, templateCanvas, designerTemplate.textElement || []);
      setEditorState(nextEditorState);
      saveEditorStateRef.current = convertToRaw(nextEditorState.getCurrentContent());

      initedRef.current = true;
    }
  }, [defEditorState, designerTemplate, isLoading, templateCanvas]);

  const onSectionDelete = useCallback((blockKey: string) => {
    contentEditedRef.current = true;
    setEditorState(prevEditorState => {
      const nEditorState = deleteSectionFromEditorState(prevEditorState, blockKey);
      return nEditorState;
    });
  }, []);

  const onAddSection = useCallback(
    (el: ActionTemplateSectionElement, text?: string) => {
      contentEditedRef.current = true;
      setEditorState(prevEditorState => {
        const section = getSectionForSectionElement(el, text);
        const nextEditorState = addSectionToEditorState(prevEditorState, section, textElements);
        return nextEditorState;
      });
    },
    [textElements]
  );

  const onSplitSection = useCallback(() => {
    contentEditedRef.current = true;
    setEditorState(prevEditorState => splitSectionInEditorState(prevEditorState, textElements));
  }, [textElements]);

  const onAddTextElement = useCallback(
    (el: ActionTemplateTextElement) => {
      contentEditedRef.current = true;
      setEditorState(prevEditorState => addTextElementToEditorState(prevEditorState, el, textElements));
    },
    [textElements]
  );

  const processedDesignerTemplate = useMemo(() => {
    const processedDesignerTemplate: ActionTemplateElements = {
      sectionElementsMap: {},
      textElements: [],
      elementGroups: []
    };

    if (designerTemplate) {
      const { sectionElement = [], textElement = [], elementGroup = [] } = designerTemplate;

      processedDesignerTemplate.sectionElementsMap = sectionElement.reduce(
        (acc, sectionElement) => {
          const { elementId } = sectionElement;
          acc[elementId] = sectionElement;

          return acc;
        },
        {} as ActionTemplateElements["sectionElementsMap"]
      );

      processedDesignerTemplate.textElements = textElement;
      processedDesignerTemplate.elementGroups = elementGroup;
    }

    return processedDesignerTemplate;
  }, [designerTemplate]);

  const beforeEditorStateChange = useCallback(() => {
    contentEditedRef.current = true;
  }, []);

  const customBlockRenderFunc = useCallback(
    (block: ContentBlock) =>
      templateBlockRendererFn(
        block,
        processedDesignerTemplate,
        onChangeReadOnly,
        beforeEditorStateChange,
        setEditorState,
        onSectionDelete,
        onErrors
      ),
    [beforeEditorStateChange, onChangeReadOnly, onErrors, onSectionDelete, processedDesignerTemplate]
  );

  const onRemoveLink = useCallback((blockKey: string, entityKey: string) => {
    contentEditedRef.current = true;
    setEditorState(prevEditorState => removeLinkFromEditorState(prevEditorState, blockKey, entityKey));
  }, []);

  const onEditorStateChange = useCallback(
    (editorState: EditorState) => {
      const { hasEditorStateUpdated } = checkIfEditorStateUpdated(editorState);
      contentEditedRef.current = hasEditorStateUpdated;
      setEditorState(editorState);
    },
    [checkIfEditorStateUpdated]
  );

  const onEditorStateChangeInternal = useCallback(
    (updaterFn: (editorState: EditorState) => EditorState) => {
      setEditorState(prevEditorState => {
        const nextEditorState = updaterFn(prevEditorState);

        const { hasEditorStateUpdated } = checkIfEditorStateUpdated(editorState);
        contentEditedRef.current = hasEditorStateUpdated;

        return hasEditorStateUpdated ? nextEditorState : prevEditorState;
      });
    },
    [checkIfEditorStateUpdated, editorState]
  );

  const customDecorators = useMemo<DraftDecorator[]>(
    () => [
      {
        strategy: getMatchRichTextToken(),
        component: TextElementRenderer,
        props: {
          unLink: onRemoveLink,
          onEditorStateChange: onEditorStateChangeInternal,
          onChangeReadOnly
        }
      }
    ],
    [onChangeReadOnly, onEditorStateChangeInternal, onRemoveLink]
  );

  const handleKeyCommand = useCallback(
    (command: DraftEditorCommand, editorState: EditorState): DraftHandleValue => {
      const selection = editorState.getSelection();
      const blockKey = selection.getStartKey();
      const block = editorState.getCurrentContent().getBlockForKey(blockKey);
      const blockType = block.getType();

      const isCustomBlock = customBlockTypes.includes(blockType as any);

      if (command === "backspace" && isCustomBlock) {
        onSectionDelete(blockKey);
        return "handled";
      }

      if (command === "split-block" && isCustomBlock) {
        onAddSection(getRichTextSectionElement());
        return "handled";
      }

      return "not-handled";
    },
    [onAddSection, onSectionDelete]
  );

  const handleReturn = useCallback(
    (e: SyntheticKeyboardEvent) => {
      e.preventDefault();
      onSplitSection();

      return true;
    },
    [onSplitSection]
  );

  const handlePastedText = useCallback(
    (text: string) => {
      onAddSection(getRichTextSectionElement(), text);
      return true;
    },
    [onAddSection]
  );

  const placeholder = "Start typing text or add a pre-defined section";
  const elementsExist = Boolean(designerTemplate?.sectionElement?.length || designerTemplate?.textElement?.length);

  const className = cx("template-editor-v2 multi-line-template-editor", {
    readonly: pReadOnly
  });

  return (
    <div
      className={className}
      data-elements-exist={elementsExist}
      data-focussed={isFocussed && !pReadOnly}
      data-loading={isLoading}
    >
      {isLoading && <LoadingSpinner titleText="Fetching metadata..." />}
      {!isLoading && Boolean(editorState) && (
        <>
          <Editor
            customBlockRenderFunc={customBlockRenderFunc}
            customDecorators={customDecorators}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            handlePastedText={handlePastedText}
            handleReturn={handleReturn}
            onBlur={onBlur}
            onEditorStateChange={onEditorStateChange}
            onFocus={onFocus}
            placeholder={placeholder}
            readOnly={readOnly}
            ref={editorRef}
            stripPastedStyles
            toolbarHidden
          />

          {elementsExist && !pReadOnly && (
            <div className="add-item-button">
              <SectionGroupElementsSelector
                designerTemplate={designerTemplate}
                filterOutLoopElements={filterOutLoopElements}
                onAddSection={onAddSection}
                onAddTextElement={onAddTextElement}
                onClose={onBlur}
                onOpen={onFocus}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const customBlockTypes = [SectionElementType.EVENT_GROUP_BY, SectionElementType.TABLE, SectionElementType.TOOLBAR];

const getRichTextSectionElement = (): ActionTemplateSectionElement => ({
  description: "",
  elementId: "rich-text-section",
  icon: "",
  label: "",
  prop: [],
  type: SectionElementType.RICH_TEXT
});
