import React, { FC } from "react";
import { WidgetProperty } from "../types";

interface Props {
  property: WidgetProperty;
  onUpdate: (newState: WidgetProperty) => void;
}

const InvalidPropertyRenderer: FC<Props> = props => {
  const { property } = props;

  const { type } = property;

  return <div className="invalid-property">Invalid property type: {type}</div>;
};

export default InvalidPropertyRenderer;
