import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { IncButton, IncFaIcon, IncModal, IncSmartText } from "@inception/ui";
import { css, cx } from "emotion";
import { OpFragmentEditProps } from "../types";
import { EventCriteriaEditor, VerticallyCenteredRow } from "../../components";
import { useRefState, useToggleState } from "../../core";
import { OpCreationFragmentEditType } from "../../services/api/operationalise";
import {
  FieldPickerContextDTO,
  LogicalOperator,
  UserServiceFilterExpression,
  UserServiceFilterExpressionTree,
  UserServiceFilterList,
  WidgetConfigUtils
} from "../../services/api/explore";
import { FEATURE_FLAGS, featureFlagService } from "../../services/feature-flags";

type Props = OpFragmentEditProps;

export const EventCriteriaFragmentEditor: FC<Props> = props => {
  const { opFragment, invalidFragmentJsx } = props;

  if (opFragment?.eventCriteria?.metricUserServiceFilters) {
    return <EventCriteriaFragmentEditorInternal {...props} />;
  }

  return invalidFragmentJsx;
};

const EventCriteriaFragmentEditorInternal: FC<Props> = props => {
  const {
    className: wrapperClassName,
    opFragment,
    onChange,
    onClose,
    demoDataParams,
    showEditorInModal = false
  } = props;

  const useExpressionTree = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.useComplexExpressions);

  const { isOpen: isUpdateInProgress, open: startUpdate, close: finishUpdate } = useToggleState();

  const errorMessageRef = useRef("");

  const { idProps, metricUserServiceFilters: defMetricUserServiceFilters } = opFragment.eventCriteria || {};

  const fieldPickerContext = useMemo<FieldPickerContextDTO>(() => {
    const { eventTypeId, entityTypeId } = WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(idProps);

    return {
      entityId: entityTypeId ? null : eventTypeId,
      entityName: entityTypeId,
      entityType: entityTypeId,
      showFields: true,
      showMetrics: false,
      userServices:
        entityTypeId && eventTypeId
          ? [
              {
                userServiceEntityId: eventTypeId
              }
            ]
          : []
    };
  }, [idProps]);

  const metricUserServiceFiltersRef = useRefState(
    defMetricUserServiceFilters || {
      expressionTree: {
        filterNodes: [],
        logicalOperator: LogicalOperator.AND
      },
      userServiceFilters: []
    }
  );

  const resetMetricUserServiceFilters = useCallback(() => {
    metricUserServiceFiltersRef.current = defMetricUserServiceFilters || {
      expressionTree: {
        filterNodes: [],
        logicalOperator: LogicalOperator.AND
      },
      userServiceFilters: []
    };
  }, [defMetricUserServiceFilters, metricUserServiceFiltersRef]);

  useEffect(() => {
    resetMetricUserServiceFilters();
  }, [resetMetricUserServiceFilters]);

  const onCancelChanges = useCallback(() => {
    resetMetricUserServiceFilters();
    onClose?.();
  }, [onClose, resetMetricUserServiceFilters]);

  const metricUserServiceFilters = metricUserServiceFiltersRef.current;
  const { expressionTree, filterExpressions } = useMemo(
    () => ({
      expressionTree: metricUserServiceFilters.expressionTree,
      filterExpressions: metricUserServiceFilters.userServiceFilters?.[0]?.userServiceFilterExpressions || []
    }),
    [metricUserServiceFilters]
  );

  const onFiltersChange = useCallback(
    async (filterExpressions: UserServiceFilterExpression[], expressionTree: UserServiceFilterExpressionTree) => {
      errorMessageRef.current = "";
      startUpdate();

      let metricUserServiceFilters: UserServiceFilterList;

      if (useExpressionTree) {
        metricUserServiceFilters = {
          expressionTree,
          userServiceFilters: null
        };
      } else {
        metricUserServiceFilters = {
          expressionTree: null,
          userServiceFilters: [
            {
              userServiceFilterExpressions: filterExpressions
            }
          ]
        };
      }

      const { isError, message } = await onChange(
        {
          ...opFragment,
          eventCriteria: {
            idProps: opFragment.eventCriteria.idProps,
            metricUserServiceFilters: metricUserServiceFilters
          }
        },
        OpCreationFragmentEditType.EVENT_CRITERIA
      );

      if (isError) {
        errorMessageRef.current = message;
      }

      finishUpdate();
    },
    [finishUpdate, onChange, opFragment, startUpdate, useExpressionTree]
  );

  const className = cx(
    wrapperClassName,
    showEditorInModal ? "custom-event-criteria-height" : "height-100",
    css`
      .event-filters-container {
        margin-top: 0px;
      }
    `
  );

  const { close: closeModal, isOpen: IsModalOpen } = useToggleState(true);

  const jsx = useMemo(
    () => (
      <div className={className}>
        <EventCriteriaEditor
          demoDataParams={demoDataParams}
          fieldPickerContext={fieldPickerContext}
          filterExpressionTree={expressionTree}
          filters={filterExpressions}
          onCancel={onCancelChanges}
          onChange={onFiltersChange}
          skipHeader
          useExpressionTree={useExpressionTree}
        >
          {(onSave, onCancel, filtersValid) => (
            <>
              <VerticallyCenteredRow className="flex-gap-12 marginTpAuto">
                {!isUpdateInProgress && (
                  <IncButton
                    color="primary"
                    disabled={!filtersValid}
                    label="Save Changes"
                    onClick={() => {
                      onSave();
                      closeModal();
                    }}
                    size="small"
                  />
                )}

                {isUpdateInProgress && (
                  <IncButton
                    color="primary"
                    loading
                    loadingText="Saving..."
                    size="small"
                  />
                )}

                {Boolean(errorMessageRef.current) && (
                  <VerticallyCenteredRow className="status-danger flex-gap-10">
                    <IncFaIcon
                      className="status-danger"
                      iconName="exclamation-triangle"
                    />
                    <IncSmartText
                      className="status-danger"
                      text={errorMessageRef.current}
                    />
                  </VerticallyCenteredRow>
                )}

                {!filtersValid && (
                  <VerticallyCenteredRow className="status-danger flex-gap-10">
                    <IncFaIcon
                      className="status-danger"
                      iconName="exclamation-triangle"
                    />
                    <IncSmartText
                      className="status-danger"
                      text="There are some invalid filters, please fix them to save changes"
                    />
                  </VerticallyCenteredRow>
                )}

                {!isUpdateInProgress && (
                  <IncButton
                    color="secondary-blue"
                    label="Cancel"
                    onClick={() => {
                      onCancel();
                      closeModal();
                    }}
                    size="small"
                  />
                )}
              </VerticallyCenteredRow>
            </>
          )}
        </EventCriteriaEditor>
      </div>
    ),
    [
      className,
      closeModal,
      demoDataParams,
      expressionTree,
      fieldPickerContext,
      filterExpressions,
      isUpdateInProgress,
      onCancelChanges,
      onFiltersChange,
      useExpressionTree
    ]
  );

  return (
    <>
      {!showEditorInModal && jsx}
      {showEditorInModal && (
        <IncModal
          contentClassName="op-fragment-editors-modal-content padding24"
          onClose={closeModal}
          show={IsModalOpen}
          size="xxlg"
          titleText="Cohort of KPI to track"
          withTitleBorder
        >
          {jsx}
        </IncModal>
      )}
    </>
  );
};
