import { IncFaIcon, IncFaIconName, IncSmartText, ISaxIcon } from "@inception/ui";
import React, { FC, useEffect, useMemo } from "react";
import { css, cx } from "emotion";
import { ConversationStatus } from "../../services/api/chat";
import { LowLevelFragment } from "../../services/api/chat/types/low-level-fragment";
import { DemoDataParams } from "../../services/api/explore";
import { updateSvgColor, useToggleState } from "../../core";
import { VerticallyCenteredRow } from "../flex-components";
import { getSourceTypeIcon } from "../business-entity";
import { FragmentRendererV2 } from "./FragmentRendererV2";

interface SubStepsRendererProps {
  subStep: LowLevelFragment;
  totalSteps: number;
  idx: number;
  demoDataParams?: DemoDataParams;
  status: ConversationStatus;
  keepLastOpen?: boolean;
}

export const SubStepsRenderer: FC<SubStepsRendererProps> = props => {
  const { subStep, totalSteps, idx, demoDataParams, status = ConversationStatus.NA, keepLastOpen = false } = props;
  const { name, description, fragmentInfo } = subStep || {};
  const { label, additionalHeaders } = fragmentInfo || {};
  const { iconName, iconColor, backgroundColor } = label || {};

  const isLastItem = idx === totalSteps - 1;
  const isLoading = status !== ConversationStatus.COMPLETED;

  const tempHeaderClassName = isLoading && isLastItem ? "--last" : isLastItem ? "--no-border" : "";
  const headerClassName = cx(`padding16 inc-cursor-pointer inc-flex-column flex-gap-12 sub-step${tempHeaderClassName}`);

  const { toggle, isOpen, close, open } = useToggleState(isLastItem);

  useEffect(() => {
    if (status === ConversationStatus.COMPLETED) {
      close();
    }
  }, [close, status]);

  useEffect(() => {
    if ((keepLastOpen || isLoading) && isLastItem) {
      open();
    } else {
      close();
    }
  }, [close, isLastItem, isLoading, keepLastOpen, open, status]);

  const iconContainerClassName = css`{
    background-color: ${backgroundColor ? `${backgroundColor} !important` : ""};
    border: 1px solid ${iconColor ? `${iconColor} !important` : ""};
    padding: 8px;
  }`;

  const additionalHeadersJsx = useMemo(
    () =>
      (additionalHeaders || []).map(header => {
        const { label, backgroundColor, iconSvg, iconName, iconColor } = header;
        const icon = iconSvg ? (
          getSourceTypeIcon("", iconColor ? updateSvgColor(iconSvg, iconColor) : iconSvg, "", 12)
        ) : iconName ? (
          <IncFaIcon
            className="padding8"
            iconName={iconName as IncFaIconName}
            style={iconColor ? { color: iconColor } : {}}
          />
        ) : null;

        const headerClassName = css`{
          padding: 4px 8px;
          border-radius: 8px;
          background: ${backgroundColor ? backgroundColor : ""};
          max-width: 120px;
        }`;
        const textClass = css`{
          color: ${iconColor ? iconColor : ""};
        }`;
        const textClasses = cx(textClass, "inc-text-subtext");
        return (
          <VerticallyCenteredRow
            className={`${headerClassName} flex-gap-8`}
            key={header?.label}
          >
            {icon}
            <IncSmartText
              text={label}
              textClass={textClasses}
            />
          </VerticallyCenteredRow>
        );
      }),
    [additionalHeaders]
  );

  return (
    <div
      className={headerClassName}
      data-expanded={isOpen}
      onClick={toggle}
    >
      <VerticallyCenteredRow className={`flex-gap-12 width-100`}>
        <div className={`icon ${iconContainerClassName}`}>
          <IncFaIcon
            className="small-icon"
            iconName={(iconName as IncFaIconName) || "info"}
            style={iconColor ? { color: iconColor } : {}}
          />
        </div>
        {additionalHeadersJsx}
        <IncSmartText
          text={name}
          textClass={`inc-text-body-medium`}
        />
        <ISaxIcon
          className="collapse-arrow marginLtAuto"
          color="#B7BCC9"
          data-expanded={isOpen}
          iconName="ArrowCircleDown"
          variant="Outline"
        />
      </VerticallyCenteredRow>
      {!isOpen && Boolean(description) && (
        <span className="inc-text-subtext inc-text-color-secondary">{description || "description of fragment"}</span>
      )}
      {isOpen && (
        <FragmentRendererV2
          demoDataParams={demoDataParams}
          key={idx + (subStep?.id || "")}
          status={status}
          subStep={subStep}
        />
      )}
    </div>
  );
};
