import { IncSelect, IncTextfield } from "@inception/ui";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RegroupingConfig, RegroupingExp } from "../transform-config/TransformConfig";

interface RegroupingCompProps {
  config: RegroupingConfig;
  onChange: (propertyValue: RegroupingConfig) => void;
}

type RegroupingOption = {
  label: string;
  value: RegroupingExp;
};

const REGEXP = "Regular Expression";
const SPLITREGEXP = "Split Regular Expression";

export const RegroupingComp: React.FC<RegroupingCompProps> = (props: RegroupingCompProps) => {
  const { config, onChange } = props;
  const [regroupExpOption, setRegroupExpOption] = useState<RegroupingOption>(null);
  const [expression, setExpression] = useState<string>("");
  const [mergeString, setMergeString] = useState<string>("");
  const [groupValue, setGroupValue] = useState<string>("");
  const [groupValError, setGroupValError] = useState<boolean>(false);

  const regroupingOptions: RegroupingOption[] = useMemo(
    () => [
      {
        label: REGEXP,
        value: "regexp"
      },
      {
        label: SPLITREGEXP,
        value: "splitRegexp"
      }
    ],
    []
  );

  const getSelectGroups = useCallback((value: string) => {
    const numArray: number[] = [];
    const valArray = value.split(",");
    valArray.forEach(val => val && numArray.push(Number(val)));
    return numArray;
  }, []);

  const getGroupArr: number[] = useMemo(() => getSelectGroups(groupValue), [groupValue, getSelectGroups]);

  useEffect(() => {
    if (config && config.regexp) {
      setRegroupExpOption({
        label: REGEXP,
        value: "regexp"
      });
      setExpression(config.regexp);
    } else if (config && config.splitRegexp) {
      setRegroupExpOption({
        label: SPLITREGEXP,
        value: "splitRegexp"
      });
      setExpression(config.splitRegexp);
    }
    if (config?.mergeStr || config?.selectGroups) {
      setGroupValue(config?.selectGroups?.toString());
      setMergeString(config?.mergeStr);
    }
  }, [config]);

  const onChangeConfig = useCallback(
    (expression: string, groupVal: number[], mergeString: string) => {
      if (expression && !isEmpty(groupVal) && mergeString) {
        const key = regroupExpOption?.value;
        const newConfig: RegroupingConfig = {
          [key]: expression,
          selectGroups: groupVal,
          mergeStr: mergeString
        };
        onChange(newConfig);
      }
    },
    [onChange, regroupExpOption]
  );

  const onSelectGroupsChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const regex = /^[0-9,]+$/;
      setGroupValue(value);
      if (regex.test(value) && !value.endsWith(",")) {
        setGroupValError(false);
        onChangeConfig(expression, getSelectGroups(value), mergeString);
      } else {
        if (isEmpty(value)) {
          setGroupValue("");
        } else {
          setGroupValError(true);
        }
      }
    },
    [expression, mergeString, onChangeConfig, getSelectGroups]
  );

  const onChangeExpression = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setExpression(value);
      onChangeConfig(value, getGroupArr, mergeString);
    },
    [mergeString, onChangeConfig, getGroupArr]
  );

  const onMergeStringChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setMergeString(value);
      onChangeConfig(expression, getGroupArr, value);
    },
    [expression, onChangeConfig, getGroupArr]
  );

  const onRegroupingOptionChange = useCallback((regroupingOption: RegroupingOption) => {
    setRegroupExpOption(regroupingOption);
    setExpression("");
    setGroupValue("");
    setMergeString("");
  }, []);

  return (
    <div className="regrouping-transform-component marginBt16">
      <IncSelect<RegroupingOption>
        label="Select Regrouping"
        onChange={onRegroupingOptionChange}
        options={regroupingOptions}
        required
        value={regroupExpOption}
      />
      <IncTextfield
        disabled={!regroupExpOption}
        helpText="Ex: splitRegexp and regexp values can be like '/' or '(.*)/(.*)/(.*)'"
        label="Expression"
        onChange={onChangeExpression}
        placeholder="(.*)/(.*)/(.*) (or) '/'"
        required
        value={expression}
      />
      <IncTextfield
        disabled={!regroupExpOption}
        errorText="Only numbers seperated by commas allowed Ex: 2,3"
        hasError={groupValError}
        helpText="Ex: selectGroups value can be like 2,3"
        label="Groups"
        onChange={onSelectGroupsChange}
        placeholder="2,3"
        value={groupValue}
      />
      <IncTextfield
        disabled={!regroupExpOption}
        helpText="Ex: mergeString value can be like '/'"
        label="Merge String"
        onChange={onMergeStringChange}
        placeholder="/"
        required
        value={mergeString}
      />
    </div>
  );
};
