import React, { CSSProperties } from "react";
import { IncFaIcon } from "@inception/ui";
import { widgetRegistry, Dimensions } from "../WidgetRegistry";
import MetricTableWidget from "./MetricTableWidget";

widgetRegistry.register({
  id: "metric-table",
  label: "Data Table",
  enableInDashboard: true,
  supportsComparison: false,
  supportsNativeEdit: false,
  disableVizSwitch: true,
  iconComponent: (): JSX.Element => (
    <IncFaIcon
      iconName="table-cells"
      style={style}
    />
  ),
  component: props => <MetricTableWidget {...props} />,
  dimensions: (): Dimensions => ({
    w: 12,
    h: 12,
    minW: 6,
    minH: 6
  })
});

const style: CSSProperties = {
  fontSize: 16
};
