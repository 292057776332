import React from "react";
import { WorkflowNodeProps } from "./types";
import { IncWorkflowNode } from "./Node";
import { IncNodeTemplate } from "./Templates";

export const IncActivityNode: React.FC<WorkflowNodeProps> = props => {
  const { onClick } = props;
  const template = <IncNodeTemplate {...props} />;

  return (
    <>
      <IncWorkflowNode
        {...props}
        content={template}
        onClick={onClick}
      ></IncWorkflowNode>
    </>
  );
};
