import { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { CurrencyType, isCurrencyTypeValid } from "@inception/ui";
import { isNil } from "lodash";
import { ApplicationState } from "../../../../store/configureStore";
import { tenantApiService } from "../../../../features/admin/tenants/TenantApiService";
import { logger } from "../../logging/Logger";
import appConfig, { isPreviewRelease } from "../../../../appConfig";
import { useTypedQueryParams } from "../useQueryParamsHook";
import { useAuth } from "../../../login/state/useAuth";
import { FEATURE_FLAGS, featureFlagService } from "../../../services/feature-flags";
import { TCCustomTimeRange } from "../types";
import { TenantConfig, TenantDrilldownVersion, UITenantConfig } from "./types";
import { setTenantConfig } from "./reducer";

const useTenantConfig = () => {
  const dispatch = useDispatch();
  const {
    authState: { user }
  } = useAuth();
  const tenantConfigState = useSelector((state: ApplicationState) => state.tenantConfig, shallowEqual);
  const userRole = user?.role;

  const { useOldNavigation: urlUseOldNavigation } = useTypedQueryParams();

  const fetchTenantConfig = useCallback(async () => {
    try {
      const response = await tenantApiService.getTenantConfig(userRole);
      const config = getConfigWithDefaults(response);

      const { data: tenantTimeZone } = await tenantApiService.getTenantDefaultTimezone(userRole);
      const { data: defaultCurrency } = await tenantApiService.getTenantDefaultCurrency(userRole);

      if (isPreviewRelease) {
        config.useNewNavigation = isNil(urlUseOldNavigation) ? true : urlUseOldNavigation !== "true";
      } else {
        config.useNewNavigation = !isNil(urlUseOldNavigation)
          ? urlUseOldNavigation !== "true"
          : config.useNewNavigation;
      }

      if (isPreviewRelease) {
        config.enableRecipeInOp10zePreview = config.enableRecipeInOp10zePreview !== false;
      }

      config.enableRecipeInOp10zePreview =
        config.enableRecipeInOp10zePreview ||
        featureFlagService.isFeatureEnabled(FEATURE_FLAGS.enableNewRecipeInOperationalize);

      config.enableNewTriageSummary = config.enableNewTriageSummary === false ? false : config.useNewNavigation;

      config.useNewWidgetInBusinessCatalog = featureFlagService.isFeatureEnabled(
        FEATURE_FLAGS.showNewWidgetsInBusinessCatalog
      );

      config.enableChatInterfaceForDashboardCreate =
        config.enableChatInterfaceForDashboardCreate ||
        featureFlagService.isFeatureEnabled(FEATURE_FLAGS.enableChatInterfaceForDashboardCreate);

      dispatch(
        setTenantConfig({
          tenantConfig: {
            enableBizFlow: false,
            ...config,
            defaultTimezone:
              typeof tenantTimeZone === "object" && Object.keys(tenantTimeZone).length === 0 ? "UTC" : tenantTimeZone,
            currency: (defaultCurrency as CurrencyType) || config.currency || "USD"
          },
          isFetching: false
        })
      );
    } catch (e) {
      logger.error("Error while fetching tenant config from tenant service", e as any);
      dispatch(
        setTenantConfig({
          tenantConfig: {
            currency: appConfig.defaultCurrencyType as CurrencyType,
            defaultTimezone: "UTC",
            demoTenant: false,
            pocTenant: false,
            enableBizFlow: false,
            defaultDrilldownVersion: TenantDrilldownVersion.v5,
            useBizProcessHome: false,
            useOnboardingHome: false,
            showDemoInsights: false,
            suggestionsLimit: appConfig.defaultSuggestionsLimit,
            showSubVerticalSwitcher: false,
            useNewNavigation: true,
            useNewWidgetInBusinessCatalog: featureFlagService.isFeatureEnabled(
              FEATURE_FLAGS.showNewWidgetsInBusinessCatalog
            )
          },
          isFetching: false
        })
      );
    }
  }, [dispatch, urlUseOldNavigation, userRole]);

  const onAddCustomTimeRange = useCallback(
    async (customTimeRange: TCCustomTimeRange) => {
      const tenantConfig = tenantConfigState?.tenantConfig;

      if (tenantConfig) {
        const newCustomTimeRanges = [...(tenantConfig.customTimeRanges || []), customTimeRange];
        const newConfig = getTenantConfigFromUITenantConfig({
          ...tenantConfig,
          customTimeRanges: newCustomTimeRanges
        });

        if (customTimeRange.isDefault) {
          newCustomTimeRanges.forEach(cr => (cr.isDefault = false));
          customTimeRange.isDefault = true;
        }

        try {
          const message = await tenantApiService.updateTenantConfig(newConfig);
          dispatch(
            setTenantConfig({
              tenantConfig: getUITenantConfigFromTenantConfig(newConfig),
              isFetching: false
            })
          );
          logger.info("useTenantConfig", message);
        } catch (e) {
          logger.error("Error while updating tenant config", e as any);
        }
      }
    },
    [dispatch, tenantConfigState?.tenantConfig]
  );

  const isTenantConfigFetched = !tenantConfigState?.isFetching;

  return {
    fetchTenantConfig,
    onAddCustomTimeRange,
    tenantConfigState: tenantConfigState?.tenantConfig,
    isTenantConfigFetched
  };
};

export { useTenantConfig };

const getConfigWithDefaults = (config: TenantConfig): UITenantConfig => {
  let currency: CurrencyType = config.currency || appConfig.defaultCurrencyType;
  const isValidCurrency = isCurrencyTypeValid(currency);
  currency = isValidCurrency ? currency : appConfig.defaultCurrencyType;
  return {
    demoTenant: false,
    pocTenant: false,
    enableBizFlow: false,
    defaultDrilldownVersion: TenantDrilldownVersion.v5,
    useBizProcessHome: false,
    useOnboardingHome: false,
    showDemoInsights: false,
    suggestionsLimit: appConfig.defaultSuggestionsLimit,
    showSubVerticalSwitcher: false,
    blobStoreFileUploadTimeoutMins: 15,
    ...(config || {}),
    currency,
    useNewNavigation: !config?.useOldNavigation,
    useNewWidgetInBusinessCatalog: featureFlagService.isFeatureEnabled(FEATURE_FLAGS.showNewWidgetsInBusinessCatalog)
  };
};

const getUITenantConfigFromTenantConfig = (config: TenantConfig): UITenantConfig => ({
  ...config,
  useNewNavigation: !config?.useOldNavigation,
  useNewWidgetInBusinessCatalog: featureFlagService.isFeatureEnabled(FEATURE_FLAGS.showNewWidgetsInBusinessCatalog)
});

const getTenantConfigFromUITenantConfig = (config: UITenantConfig): TenantConfig => ({
  ...config,
  useOldNavigation: !config?.useNewNavigation
});
