import { useCallback, useMemo } from "react";
import { InitialState, FetchFn, FetchCallBackResult, useDataFetch } from "../../../core";
import { OpStateList, operationaliseV2ApiService } from "../../../services/api/operationalise";

export const useFetchOpHistory = (opId: string, lazyFetch = false) => {
  const initialState = useMemo<InitialState<OpStateList, string>>(
    () => ({
      data: {
        opStatus: []
      },
      error: null,
      isError: false,
      isFetching: !lazyFetch,
      isSuccess: false
    }),
    [lazyFetch]
  );

  const fetchFn = useCallback<FetchFn<OpStateList, string>>(async () => {
    const result: FetchCallBackResult<OpStateList, string> = {
      data: null,
      error: null,
      isError: false,
      isSuccess: false
    };
    try {
      const { data, error, message } = await operationaliseV2ApiService.fetchRunHistory(opId);
      result.data = data?.state || {
        opStatus: []
      };
      result.isError = error;
      result.isSuccess = !error;
      result.error = error ? message : "";
    } catch (err) {
      result.isError = true;
      result.error = err.message || err.toString();
    }
    return result;
  }, [opId]);

  return useDataFetch(fetchFn, initialState, lazyFetch);
};
