import {
  Options,
  SeriesHeatmapOptions,
  TooltipFormatterCallbackFunction,
  XAxisOptions,
  YAxisOptions,
  CSSObject
} from "highcharts";
import { defaultsDeep } from "lodash";
import { getInceptionTheme } from "@inception/ui";
import { BaseOptionsBuilder } from "../charts";

const FONT_STYLE: CSSObject = {
  color: getInceptionTheme().inceptionColors.palette.BL50,
  fontSize: "12px",
  height: 16,
  fontWeight: "500",
  fontFamily: "'Outfit', sans-serif"
};

export class HeatMapOptionsBuilder extends BaseOptionsBuilder {
  constructor(defOptions: Options) {
    const fDefOptions = defaultsDeep(defOptions || {}, defaultOptions);
    super(fDefOptions);
  }

  setChartFields(width: number, height: number) {
    this.chartOptions.chart.width = width;
    this.chartOptions.chart.height = height;
    return this;
  }

  setSeriesData(series: SeriesHeatmapOptions[]) {
    this.chartOptions.series = series;
    return this;
  }

  setTooltip(tooltipFormatter: TooltipFormatterCallbackFunction) {
    this.chartOptions.tooltip.formatter = tooltipFormatter;
    return this;
  }

  setXAxis(xAxisOptions: Partial<XAxisOptions>) {
    this.chartOptions.xAxis = defaultsDeep(xAxisOptions || {}, this.chartOptions.xAxis);
    return this;
  }

  setYAxis(yAxisOptions: Partial<YAxisOptions>) {
    this.chartOptions.yAxis = defaultsDeep(yAxisOptions || {}, this.chartOptions.yAxis);
    return this;
  }
}

const defaultOptions: Options = {
  accessibility: {
    enabled: false
  },
  chart: {
    backgroundColor: "transparent"
  },
  plotOptions: {
    heatmap: {
      pointPadding: 12,
      borderRadius: 6
    }
  },
  title: {
    text: ""
  },
  tooltip: {
    useHTML: true,
    className: "highcharts-tooltip"
  },
  xAxis: {
    title: {
      text: ""
    },
    grid: {
      enabled: false,
      borderColor: "transparent",
      borderWidth: 0
    },
    gridLineWidth: 0,
    labels: {
      enabled: true,
      style: FONT_STYLE
      // useHTML: true
    }
  },
  yAxis: {
    title: {
      text: ""
    },
    grid: {
      enabled: false,
      borderColor: "transparent",
      borderWidth: 0
    },
    gridLineWidth: 0,
    labels: {
      style: FONT_STYLE,
      enabled: true
    }
  },
  legend: {
    enabled: false
  }
};
