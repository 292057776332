import { IncRTable, IncSlimSelect, IncFaIcon, IncMenuV2, IncCheckbox, IncButton } from "@inception/ui";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../../components";
import {
  getURLwithPreviewHost,
  logger,
  shouldExcludeTag,
  useInceptionRoute,
  useNotifications,
  useToggleState
} from "../../../../core";
import { TriagePathConfigType } from "../../../../services/api/explore";
import { useFetchAlertResults, useFetchImpactedWidgets } from "../hooks";
import { noOp } from "../../../../utils";
import { ImpactedWidgetSelection } from "./types";
import {
  getImpactedWidgetsOptions,
  ImpactedWidgetMenuOption,
  getResultsTableData,
  getResultsTableColumns
} from "./ResultsTableUtils";
import { GROUP_BY_NONE_OPT, resultsTableGroupByOpts } from "./constants";
import { downloadResultsAsCsv } from "./DownloadUtils";

interface Props {
  entityTypeName: string;
  entityTypeId: string;
  opOrSimulationName: string;

  startTimeMillis: number;
  endTimeMillis: number;
  opId: string;

  simulationId?: string;
  widgetId: string;
  isEventOperationalize: boolean;
}

export const ResultsTable: FC<Props> = props => {
  const {
    endTimeMillis,
    opId,
    startTimeMillis,
    entityTypeName,
    simulationId,
    entityTypeId,
    widgetId,
    opOrSimulationName,
    isEventOperationalize
  } = props;

  const { notifyError } = useNotifications();
  const { navigate } = useInceptionRoute();

  const { close: markDownloadComplete, isOpen: downloadInProgress, open: markDownloadInProgress } = useToggleState();

  const {
    data: alertResponseItems,
    error,
    isError,
    isFetching
  } = useFetchAlertResults(opId, startTimeMillis, endTimeMillis, simulationId, true, !isEventOperationalize);

  const firstEntry = alertResponseItems?.[0]?.additionalData?.[0];
  const additionalTags = useMemo(() => {
    const additionalTags = Object.keys(firstEntry || {});
    const validAdditionalTags = additionalTags.filter(shouldIncludeTag);
    return validAdditionalTags;
  }, [firstEntry]);

  const {
    fetchImpactedWidgets,
    impactedWidgetsFetchError,
    impactedWidgetsLists,
    isImpactedWidgetsFetchError,
    isImpactedWidgetsFetching
  } = useFetchImpactedWidgets();

  useEffect(() => {
    if (isImpactedWidgetsFetchError) {
      logger.error("Results Table", "Error fetching impacted widgets", impactedWidgetsFetchError);
    }
  }, [impactedWidgetsFetchError, isImpactedWidgetsFetchError]);

  useEffect(() => {
    const labels: Record<string, string> = {};
    opId && (labels.opConfigId = opId);

    fetchImpactedWidgets(entityTypeId, TriagePathConfigType.impactedWidgets, null, widgetId, labels);
  }, [entityTypeId, fetchImpactedWidgets, opId, simulationId, widgetId]);

  const [groupByOpt, setGroupByOpt] = useState(GROUP_BY_NONE_OPT);
  const [impactedWidgetSelection, setImpactedWidgetSelection] = useState<ImpactedWidgetSelection[]>([]);
  const [tempImpactedWidgetSelection, setTempImpactedWidgetSelection] = useState<ImpactedWidgetSelection[]>([]);

  useEffect(() => {
    setTempImpactedWidgetSelection(prev => (!isEqual(prev, impactedWidgetSelection) ? impactedWidgetSelection : prev));
  }, [impactedWidgetSelection]);

  const saveImpactWidgetSelection = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        setTimeout(() => {
          setImpactedWidgetSelection(prev =>
            !isEqual(prev, tempImpactedWidgetSelection) ? tempImpactedWidgetSelection : prev
          );
        }, 0);
      }
    },
    [tempImpactedWidgetSelection]
  );

  const impactedWidgetMenuOptions = useMemo<ImpactedWidgetMenuOption[]>(
    () => getImpactedWidgetsOptions(impactedWidgetsLists, tempImpactedWidgetSelection),
    [impactedWidgetsLists, tempImpactedWidgetSelection]
  );

  const onMenuItemClick = useCallback((menuItem: ImpactedWidgetMenuOption) => {
    const { value: key, data: impactedWidget, checked } = menuItem;

    const { id: impactedWidgetId } = impactedWidget;

    setTempImpactedWidgetSelection(prev => {
      let next = [...prev];
      const index = next.findIndex(item => item.impactedWidget.id === impactedWidgetId);
      if (index === -1) {
        next.push({
          impactedWidget,
          data: false,
          forecast: false,
          forecastDelta: false,
          forecastDeltaPerc: false,
          [key]: checked
        });
      } else {
        const nextObj = {
          ...next[index],
          [key]: checked
        };

        if (key === "data" && !checked) {
          nextObj.forecast = false;
          nextObj.forecastDelta = false;
          nextObj.forecastDeltaPerc = false;
        }

        next[index] = nextObj;
      }

      next = next.filter(item => item.data || item.forecast || item.forecastDelta || item.forecastDeltaPerc);
      return next;
    });
  }, []);

  const menuItemRenderer = useCallback(
    (menuItem: ImpactedWidgetMenuOption) => {
      const { label, checked = false } = menuItem;

      const onChange = (e: any, checked: boolean) => {
        menuItem.checked = checked;
        onMenuItemClick(menuItem);
      };

      return (
        <IncCheckbox
          checked={checked}
          label={label}
          labelProps={{ placement: "end" }}
          onChange={onChange}
        />
      );
    },
    [onMenuItemClick]
  );

  const data = useMemo(() => getResultsTableData(alertResponseItems, groupByOpt), [alertResponseItems, groupByOpt]);

  const navigateToTriages = useCallback(
    (urls: string[]) => {
      urls.forEach(url => {
        navigate(getURLwithPreviewHost(url), { newTab: true });
      });
    },
    [navigate]
  );

  const columns = useMemo(
    () =>
      getResultsTableColumns(
        entityTypeName,
        additionalTags,
        opId,
        simulationId,
        startTimeMillis,
        endTimeMillis,
        data,
        groupByOpt,
        impactedWidgetSelection,
        navigateToTriages
      ),
    [
      additionalTags,
      data,
      endTimeMillis,
      entityTypeName,
      groupByOpt,
      impactedWidgetSelection,
      navigateToTriages,
      opId,
      simulationId,
      startTimeMillis
    ]
  );

  const numImpactedWidgets = useMemo(() => impactedWidgetMenuOptions.length, [impactedWidgetMenuOptions]);

  const menuLabel = useMemo(
    () => (
      <VerticallyCenteredRow className="inc-cursor-pointer status-info inc-text-subtext-medium">
        Impact Indicators
        <IncFaIcon
          className="marginLt8"
          iconName="caret-down"
        />
      </VerticallyCenteredRow>
    ),
    []
  );

  const dataFetchInProgress = useMemo(
    () =>
      data.reduce((acc, curr) => {
        let isFetching = acc;
        const { dataState } = curr;
        Object.values(dataState || {}).forEach(state => {
          Object.values(state || {}).forEach(value => {
            isFetching = isFetching || value.isFetching;
          });
        });

        return isFetching;
      }, false),
    [data]
  );

  const onDownloadData = useCallback(async () => {
    markDownloadInProgress();
    const { success, message } = await downloadResultsAsCsv(columns, data, opOrSimulationName);

    if (!success) {
      notifyError(message);
    }

    markDownloadComplete();
  }, [columns, data, markDownloadComplete, markDownloadInProgress, notifyError, opOrSimulationName]);

  return (
    <>
      <VerticallyCenteredRow className="marginBt24 width-100">
        <VerticallyCenteredRow className="marginRt24">Alerts</VerticallyCenteredRow>

        <IncSlimSelect
          alignment="row"
          autoSort={false}
          label="Group by"
          onChange={setGroupByOpt}
          options={resultsTableGroupByOpts}
          value={groupByOpt}
          wrapperClass="marginRt24"
        />

        {numImpactedWidgets > 0 && (
          <IncMenuV2
            label={menuLabel}
            menuItemRenderer={menuItemRenderer}
            menuItems={impactedWidgetMenuOptions}
            onMenuChange={saveImpactWidgetSelection}
            onMenuItemClick={noOp}
            skipCloseOnMenuSelect
          />
        )}
        {isImpactedWidgetsFetching && <LoadingSpinner titleText=" " />}

        {!isImpactedWidgetsFetching && (
          <IncButton
            className="marginLtAuto"
            color="link"
            disabled={dataFetchInProgress}
            iconType="iconText"
            onClick={onDownloadData}
          >
            {downloadInProgress && <LoadingSpinner titleText="Processing..." />}
            {!downloadInProgress && (
              <>
                <IncFaIcon iconName="download" />
                Download
              </>
            )}
          </IncButton>
        )}
      </VerticallyCenteredRow>
      <IncRTable
        classNames={{ table: "results-table" }}
        columns={columns}
        data={data}
        density="compact"
        errorDataMessage={error}
        hasError={isError}
        isLoading={isFetching}
        resizableColumns
      />
    </>
  );
};

const tagsToIgnore = ["lastSeenSec", "mtsId", "metricName", "summarySpan"];
const shouldIncludeTag = (tagName: string) => !shouldExcludeTag(tagName, true) && !tagsToIgnore.includes(tagName);
