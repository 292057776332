import { useMemo, useState, useCallback } from "react";
import { parse, unparse } from "papaparse";
import { TimeRange, logger } from "../../../core";
import { getMillisFromTimeObj, dataTypeManager } from "../../../utils";
import {
  UserServiceFieldSlice,
  SelectorSpec,
  SortSpec,
  WidgetResponseDTO,
  WidgetAdhocDataRequest,
  ForecastProjection
} from "../../../services/api/explore";
import {
  MetricTableConfig,
  MetricTableVariableContext,
  MetricTableColumnInfo,
  TableWidgetColumnDataRequest
} from "../types";
import { getMetricDataRequest } from "../queryUtils";
import { DataQueryRequest } from "../../../services/api/types";
import { ExploreQuery } from "../../../services/datasources/explore/types";
import datasourceApiManager from "../../../services/api/DatasourceApiService";
import appConfig from "../../../../appConfig";
import { ExploreDatasource } from "../../../services/datasources/explore/datasource";
import { uiApiService } from "../../../services/api";

export const useDownloadTableCsv = (
  metricTableConfig: MetricTableConfig,
  timeRange: TimeRange,
  widgetResponseDTOMap: Record<string, WidgetResponseDTO>,
  variablesContext: MetricTableVariableContext,
  seriesLimit: number
) => {
  const { metricColumns, slices, properties } = metricTableConfig;

  const sort = useMemo(() => properties?.sort, [properties]);

  const usfSlices = useMemo(() => slices.map(slice => slice.slice), [slices]);

  const [csvData, setCsvData] = useState<string>("");
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [error, setError] = useState("");

  const downloadCsv = useCallback(async () => {
    setCsvData("");
    setDownloadInProgress(true);
    setError("");

    let sortColumnIdx = metricColumns.findIndex(c => c.id === sort?.accessor);
    sortColumnIdx = sortColumnIdx === -1 ? 0 : sortColumnIdx;

    const sortColumn = metricColumns[sortColumnIdx];

    const sortSpec: SortSpec = {
      limitSpec: {
        function: sort?.order === "asc" ? "bottom" : "top",
        limit: seriesLimit
      },
      sortBy: sortColumn.queryConfig.metricQueryConfig?.projection || "current"
    };

    const widgetResponseDTO = widgetResponseDTOMap[sortColumn.id];
    const baseQueryRequest = getMetricColumnDataQuery(
      sortColumn,
      timeRange,
      usfSlices,
      sortColumnIdx,
      widgetResponseDTO,
      sortSpec,
      null,
      variablesContext
    );

    const payloads: TableWidgetColumnDataRequest[] = [];

    const baseColumnRequest = getColumnRequest(baseQueryRequest, sortColumn);
    payloads.push(baseColumnRequest);

    metricColumns.forEach((column, idx) => {
      if (idx !== sortColumnIdx) {
        const widgetResponseDTO = widgetResponseDTOMap[column.id];
        const queryRequest = getMetricColumnDataQuery(
          column,
          timeRange,
          usfSlices,
          sortColumnIdx,
          widgetResponseDTO,
          null,
          null,
          variablesContext
        );

        const columnRequest = getColumnRequest(queryRequest, column);
        payloads.push(columnRequest);
      }
    });

    const { data: nCsvData, error, message } = await uiApiService.downloadTableWidgetCsv(payloads);

    const formattedData = getFormattedCsvData(nCsvData, payloads);

    setCsvData(formattedData);
    setDownloadInProgress(false);
    setError(error ? message : "");
  }, [metricColumns, seriesLimit, sort, timeRange, usfSlices, variablesContext, widgetResponseDTOMap]);

  return {
    downloadCsv,
    csvData,
    downloadInProgress,
    downloadError: error
  };
};

const getColumnRequest = (
  queryRequest: DataQueryRequest<ExploreQuery>,
  column: MetricTableColumnInfo
): TableWidgetColumnDataRequest => {
  const { id, name, queryConfig } = column;

  const { dataType, subType } = queryConfig;

  const datasource = datasourceApiManager.get(appConfig.defaultExploreDsName) as ExploreDatasource;
  const queryPayloads = datasource.getQueryPayload(queryRequest);

  if (queryPayloads.length) {
    const { intervalSecs, ...payload } = queryPayloads[0];

    const colReq: TableWidgetColumnDataRequest = {
      id,
      name,
      downSampleSeconds: intervalSecs,
      payload: payload as WidgetAdhocDataRequest,
      endTimeMillis: queryRequest.endTime,
      startTimeMillis: queryRequest.startTime,
      entityTypeId: queryRequest.targets[0].payload.entityType,
      eventTypeId: queryRequest.targets[0].payload.entityId,
      dataType,
      subType
    };

    return colReq;
  }

  return;
};

const getMetricColumnDataQuery = (
  column: MetricTableColumnInfo,
  timeRange: TimeRange,
  usfSlices: UserServiceFieldSlice[],
  idx: number,
  widgetResponseDTO: WidgetResponseDTO,
  sortSpec?: SortSpec,
  selectorSpec?: SelectorSpec,
  variablesContext?: MetricTableVariableContext
) => {
  const { id, queryConfig, name: metricName } = column;

  const { metricQueryConfig, queryType, timeRange: cTimeRange, timeOffset } = queryConfig;

  let qTimeRange = timeRange;

  if (cTimeRange) {
    qTimeRange = cTimeRange;
  } else if (timeOffset) {
    const millis = getMillisFromTimeObj(timeOffset);
    const from = timeRange.to.clone().subtract(millis);
    const to = timeRange.to.clone();

    qTimeRange = {
      from,
      to,
      raw: {
        from: from.valueOf().toString(),
        to: to.valueOf().toString()
      }
    };
  }

  let partResult: DataQueryRequest<ExploreQuery>;

  if (queryType === "metric") {
    partResult = getMetricDataRequest(
      metricName,
      metricQueryConfig,
      usfSlices,
      qTimeRange,
      id,
      idx,
      widgetResponseDTO,
      sortSpec,
      selectorSpec,
      variablesContext
    ).request;
  }

  return partResult;
};

const getFormattedCsvData = (csvData: string, columnRequests: TableWidgetColumnDataRequest[]) => {
  if (!csvData) {
    return csvData;
  }

  const parsedCsv = parse(csvData, { header: true });
  const { data, errors } = parsedCsv;

  if (errors.length) {
    logger.error("DownloadTableCsv", "Error parsing csv data", errors);
    return csvData;
  }

  data.forEach(row => {
    const typedRow = row as Record<string, string>;

    columnRequests.forEach(colReq => {
      const { dataType, subType, payload, name } = colReq;

      const key = name;
      const forecastProjections = payload.sliceSpec[0].postAgg.forecastSpec?.projections || [];

      const keysToProcess = [key];
      forecastProjections.forEach(proj => {
        const suffix =
          proj === ForecastProjection.forecastDelta
            ? "(Forecasted change)"
            : proj === ForecastProjection.forecastDeltaPerc
              ? "(Forecasted percentage change)"
              : proj === ForecastProjection.forecastLower
                ? "(Forecasted lower)"
                : proj === ForecastProjection.forecastUpper
                  ? "(Forecasted upper)"
                  : "(Forecasted)";
        keysToProcess.push(`${key} ${suffix}`);
      });

      keysToProcess.forEach(key => {
        const value = typedRow[key];
        const numValue = parseFloat(value);

        if (!isNaN(numValue) && dataType) {
          const formatter = dataTypeManager.getDataTypeDescriptorByKind(dataType, subType)?.getFormattedValue;
          const formattedValue = formatter
            ? formatter(value, { numberFormatOptions: { compact: false } })
            : numValue.toString();

          typedRow[key] = formattedValue as string;
        }
      });
    });
  });

  const nCsvData = unparse(data, {
    quotes: true,
    quoteChar: '"',
    header: true
  });

  return nCsvData;
};
