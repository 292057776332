import React, { FC, useState, useCallback, useEffect } from "react";
import { IncButton, IncFaIcon } from "@inception/ui";
import { AddMetricModal } from "./AddMetricModal";
import { AddMetricModalWithVizSelection } from "./AddMetricWithVizSelection";
import { AddMetricModalProps } from "./types";

type Props = Omit<AddMetricModalProps, "open" | "onClose"> & {
  onClose?: () => void;
  openModal?: boolean;
};

export const AddMetricModalWithTrigger: FC<Props> = props => {
  const { onClose: pOnClose, openModal: pOpen, ...restProps } = props;

  const showCatalogDataUI = true;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (pOpen !== undefined && pOpen !== null) {
      setOpen(pOpen);
    }
  }, [pOpen]);

  const openModal = useCallback(() => setOpen(true), []);
  const closeModal = useCallback(() => {
    pOnClose && pOnClose();
    setOpen(false);
  }, [pOnClose]);

  const label = showCatalogDataUI ? "Add widget" : "Create a custom metric";

  return (
    <>
      <IncButton
        color="primary"
        iconType="iconText"
        onClick={openModal}
      >
        <IncFaIcon iconName="plus" />
        {label}
      </IncButton>

      {open && (
        <>
          {!showCatalogDataUI && (
            <AddMetricModal
              {...restProps}
              onClose={closeModal}
              open
            />
          )}
          {showCatalogDataUI && (
            <AddMetricModalWithVizSelection
              {...restProps}
              onClose={closeModal}
              open
            />
          )}
        </>
      )}
    </>
  );
};
