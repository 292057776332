import { IncCheckbox, IncTextfield, IncButton } from "@inception/ui";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { isEmpty } from "lodash";
import BaseWidgetImpl from "../../../model-impl/BaseWidgetImpl";
import { DashboardListItem } from "../../../api";
import DashboardSelector from "../../../DashboardSelector";
import { useNotifications } from "../../../../core";
import { PartialWidgetLayout } from "../../../models";
import { useAddToDashboard } from "./useAddToDashboard";

interface Props<T extends BaseWidgetImpl> {
  widgets: T[];
  layouts?: PartialWidgetLayout[];

  onAddSuccess: () => void;
  onSelectionChange?: (selectionCount: number) => void;
  onProgressChange?: (inProgress: boolean) => void;

  activeDashboardId?: string;
  preselectDashboardIds?: string[];
}

export const AddToDashboardModal = <T extends BaseWidgetImpl>(props: Props<T>) => {
  const {
    widgets,
    activeDashboardId,
    onAddSuccess,
    onSelectionChange,
    onProgressChange,
    preselectDashboardIds: preSelection,
    layouts
  } = props;

  const [openDashboardsAfterAdd, setOpenDashboardsAfterSave] = useState(false);
  const [newDashboardName, setNewDashboardName] = useState("");
  const [selection, setSelection] = useState<DashboardListItem[]>([]);
  const [widgetName, setWidgetName] = useState(widgets.length === 1 ? widgets[0].title : "");
  const onDashboardNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setNewDashboardName(e.target.value),
    []
  );
  const onCheckedChange = useCallback((e: any, checked: boolean) => setOpenDashboardsAfterSave(checked), []);

  const { notifyError, notifySuccess } = useNotifications();

  const { addInProgress, addToDashboardCallback } = useAddToDashboard({
    newDashboardName,
    selection,
    widgets,
    openDashboardsAfterAdd,
    onAddSuccess,
    layouts
  });

  useEffect(() => {
    if (onProgressChange) {
      onProgressChange(addInProgress);
    }
  }, [addInProgress, onProgressChange]);

  const onAddToDashboard = useCallback(async () => {
    const [error, addedToDashboard] = await addToDashboardCallback();
    if (error) {
      notifyError(error);
    }

    if (addedToDashboard) {
      notifySuccess("Widget added to dashboard successfully");
    }
  }, [addToDashboardCallback, notifyError, notifySuccess]);

  useEffect(() => {
    if (onSelectionChange) {
      const selectionLength = selection.length + (newDashboardName ? 1 : 0);
      onSelectionChange(selectionLength);
    }
  }, [newDashboardName, onSelectionChange, selection]);

  const ignoreList = useMemo(() => [activeDashboardId], [activeDashboardId]);
  const preselectDashboardIds = useMemo(() => preSelection || [], [preSelection]);

  const selectionExists = selection?.length > 0 || !isEmpty(newDashboardName);
  const widgetNameExists = widgets.length === 1 && isEmpty(widgetName);
  const disableAdd = addInProgress || !selectionExists || widgetNameExists;

  const onWidgetNameChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setWidgetName(value);
      widgets[0].title = value;
    },
    [widgets]
  );

  return (
    <div className="add-to-dashboard-selector">
      {widgets.length === 1 && (
        <IncTextfield
          containerClassName="marginBt12"
          errorText="Widget name cannot be empty"
          hasError={isEmpty(widgetName)}
          label="Widget name"
          onChange={onWidgetNameChanged}
          value={widgetName}
        />
      )}

      <DashboardSelector
        allowCreation={false}
        globalFilter={{
          label: "Add to existing dashboard",
          placeholder: "Search dashboards to add"
        }}
        ignoreList={ignoreList}
        onSelectionChanged={setSelection}
        preSelectedDashboardIds={preselectDashboardIds}
        variant="transparent"
      />

      <div className="divider-line" />

      <IncTextfield
        containerClassName="marginTp8"
        helpText="Create a new dashboard with the title, and add the widget to the created dashboard"
        label="Create new dashboard"
        onChange={onDashboardNameChange}
        style={{ maxWidth: 350 }}
        value={newDashboardName}
      />

      <div className="inc-flex-row inc-flex-center-vertical marginTp8">
        <IncCheckbox
          checked={openDashboardsAfterAdd}
          label="Open dashboards after adding widget"
          labelProps={{
            placement: "end"
          }}
          onChange={onCheckedChange}
        />
      </div>

      <IncButton
        className="marginTp8"
        color="primary"
        disabled={disableAdd}
        onClick={onAddToDashboard}
      >
        Done
      </IncButton>
    </div>
  );
};
