import React, { FC, useMemo, useCallback, useRef, useState } from "react";
import { IncFaIconName, IncFaIcon, IncToolTip, IncSelectOption, IncSlimSelect } from "@inception/ui";
import { isEqual } from "lodash";
import {
  UserServiceFieldMetricConfig,
  FieldPickerContextDTO,
  UserServiceFieldSliceSet,
  UserServiceFilterExpression,
  UserServiceFieldSlice,
  USEntityMeta,
  MetricDefinition,
  UserServiceField,
  DemoDataParams
} from "../../services/api/explore";
import { FieldPickerModel } from "../../field-picker";
import { ALL_USERSERVICES_EVENT_TYPE_ID, getImplicitSlice } from "../../utils/ExploreUtils";
import { TimeRange } from "../../core";
import { ENTITY_TAG, FieldPickerUtils, getTagNameFromFieldName } from "../../utils";
import { VerticallyCenteredRow } from "../flex-components";
import { USFieldWidgetUtils } from "../../dashboard/widgets/USField/USFieldWidgetUtils";
import {
  EventFieldSelector,
  EventFilterSelector,
  EventGroupBySelector,
  AdvancedPropertiesEditorProps,
  EventFieldPropertiesEditor
} from "./components";

export interface EventFieldQueryEditorProps extends AdvancedPropertiesEditorProps {
  eventTypeInfo: USEntityMeta;
  usFieldMetricConfig: UserServiceFieldMetricConfig;
  etcMetricDef?: MetricDefinition;

  entityTypeId?: string;

  getLatestTimeRange: () => TimeRange;
  onChange?: (nMetricConfig: UserServiceFieldMetricConfig, metricName?: string) => void;
  onRemove?: () => void;
  onDuplicate?: () => void;
  queryReadOnly?: boolean;
  propertiesReadOnly?: boolean;

  queryId?: string;
  error?: string;
  fieldLabel?: string;

  demoDataParams?: DemoDataParams;
  displayImplicitSlice?: boolean;
  canRemoveImplicitSlice?: boolean;
  disableSliceSelection?: boolean;
  sliceOptions?: Array<IncSelectOption<UserServiceFieldSlice>>;

  minimalView?: boolean;
  nonCollapsibleFilters?: boolean;

  skipKpiMetricOptions?: boolean;
  addRateMetricOptions?: boolean;
  onRateMetricSelect?: (
    isSuccessfulRateMetric: boolean,
    eventIdField: UserServiceField,
    hasErrorField: UserServiceField,
    metricName: string
  ) => void;

  children?: JSX.Element | JSX.Element[];
}

export const EventFieldQueryEditor: FC<EventFieldQueryEditorProps> = React.memo(props => {
  const { usFieldMetricConfig, etcMetricDef } = props;

  if (usFieldMetricConfig) {
    return <EventFieldQueryEditorInternal {...props} />;
  }

  if (etcMetricDef) {
    return (
      <div className="event-field-query-editor">
        <VerticallyCenteredRow className="inc-text-subtext-medium ">
          Metric configuration not supported: {etcMetricDef.name}
        </VerticallyCenteredRow>
      </div>
    );
  }

  return <div className="event-field-query-editor inc-text-subtext-bold">No metric configuration found</div>;
});

const EventFieldQueryEditorInternal: FC<EventFieldQueryEditorProps> = React.memo(props => {
  const {
    usFieldMetricConfig,
    onChange,
    eventTypeInfo,
    queryId,
    error,
    entityTypeId,
    onRemove,
    onDuplicate,
    getLatestTimeRange,
    displayImplicitSlice = false,
    canRemoveImplicitSlice = false,
    disableSliceSelection = false,
    sliceOptions,
    minimalView = false,
    queryReadOnly = false,
    propertiesReadOnly = false,
    demoDataParams = null,
    nonCollapsibleFilters = false,
    children: eventTypeSelector,
    fieldLabel,
    addRateMetricOptions,
    onRateMetricSelect,
    ...advancedPropertiesEditorProps
  } = props;

  const { entityId: eventTypeId, name: eventTypeName, metadata, beFieldNames } = eventTypeInfo;

  const [fieldPickerModel, setFieldPickerModel] = useState<FieldPickerModel>();
  const [selectedBizEntityFieldName, setSelectedBizEntityFieldName] = useState<string>(beFieldNames?.[0]);

  const iconName = metadata?.icon as IncFaIconName;
  const dirtyRef = useRef(false);

  const fieldPickerContext = useMemo<FieldPickerContextDTO>(() => {
    const userServiceToBizEntityFieldName =
      selectedBizEntityFieldName && eventTypeId !== ALL_USERSERVICES_EVENT_TYPE_ID
        ? {
            [eventTypeId]: selectedBizEntityFieldName
          }
        : {};
    return FieldPickerUtils.getFieldPickerContext(entityTypeId, [eventTypeId], userServiceToBizEntityFieldName);
  }, [entityTypeId, eventTypeId, selectedBizEntityFieldName]);

  const usFieldMetricConfigRef = useRef(usFieldMetricConfig);
  useMemo(() => {
    if (usFieldMetricConfig?.filterExpressions?.length) {
      const nMetricConfig = { ...usFieldMetricConfig };
      nMetricConfig.eventFilters = {
        userServiceFilters: [{ userServiceFilterExpressions: nMetricConfig.filterExpressions }]
      };
      delete nMetricConfig.filterExpressions;
      usFieldMetricConfigRef.current = nMetricConfig;
    } else if (usFieldMetricConfig?.filterExpressions && usFieldMetricConfig?.eventFilters) {
      const nMetricConfig = { ...usFieldMetricConfig };
      delete nMetricConfig.filterExpressions;
      usFieldMetricConfigRef.current = nMetricConfig;
    } else {
      usFieldMetricConfigRef.current = usFieldMetricConfig;
    }
  }, [usFieldMetricConfig]);

  const onConfigChange = useCallback(
    (partConfig: Partial<UserServiceFieldMetricConfig>, metricName?: string) => {
      const usFieldMetricConfig = usFieldMetricConfigRef.current;

      dirtyRef.current = true;
      const nMetricConfig = {
        ...usFieldMetricConfig,
        ...partConfig
      };

      if (nMetricConfig.eventFilters && nMetricConfig.filterExpressions) {
        nMetricConfig.eventFilters = {
          userServiceFilters: [{ userServiceFilterExpressions: nMetricConfig.filterExpressions }]
        };
        delete nMetricConfig.filterExpressions;
      }

      adjustSliceSets(nMetricConfig, fieldPickerModel, selectedBizEntityFieldName);
      onChange(nMetricConfig, metricName);
    },
    [fieldPickerModel, onChange, selectedBizEntityFieldName, usFieldMetricConfigRef]
  );

  const onSliceSetsChange = useCallback(
    (sliceSets: UserServiceFieldSliceSet[]) => {
      onConfigChange({ sliceSets });
    },
    [onConfigChange]
  );

  const onFiltersChange = useCallback(
    (filterExpressions: UserServiceFilterExpression[]) => {
      onConfigChange({
        eventFilters: {
          userServiceFilters: [
            {
              userServiceFilterExpressions: filterExpressions
            }
          ]
        }
      });
    },
    [onConfigChange]
  );

  const beFieldNameOpts = useMemo(
    () =>
      (beFieldNames || []).map(beFieldName => ({
        label: getTagNameFromFieldName(beFieldName),
        value: beFieldName
      })),
    [beFieldNames]
  );

  const selectedBeFieldNameOpt = useMemo(
    () => ({
      label: getTagNameFromFieldName(selectedBizEntityFieldName || ""),
      value: selectedBizEntityFieldName
    }),
    [selectedBizEntityFieldName]
  );

  const onBizEntityFieldNameChange = useCallback(
    (opt: IncSelectOption) => {
      const nextBEFieldName = opt.value;
      setSelectedBizEntityFieldName(nextBEFieldName);
      onConfigChange({
        userServiceField: null
      });
    },
    [onConfigChange]
  );

  const queryIdExists = Boolean(queryId);
  const iconExists = Boolean(iconName);
  const displayError = Boolean(error) && dirtyRef.current;
  const canRemove = Boolean(onRemove) && !queryReadOnly;
  const canDuplicate = Boolean(onDuplicate) && !queryReadOnly;

  const {
    aggregator,
    userServiceField,
    filterExpressions: defFilterExpressions,
    eventFilters,
    sliceSets,
    lookBack
  } = usFieldMetricConfig;

  const filterExpressions = useMemo(() => {
    if (eventFilters?.userServiceFilters?.[0]) {
      return eventFilters.userServiceFilters[0].userServiceFilterExpressions || [];
    }

    if (defFilterExpressions) {
      return defFilterExpressions;
    }

    return [];
  }, [defFilterExpressions, eventFilters]);

  const actionsJsx = useMemo(
    () => (
      <>
        {displayError && (
          <IncToolTip
            titleText={error}
            variant="error"
          >
            <div className="inc-flex-row marginLt10">
              <IncFaIcon
                className="status-danger"
                iconName="warning"
              />
            </div>
          </IncToolTip>
        )}

        {(canDuplicate || canRemove) && (
          <div className="marginLtAuto">
            {canDuplicate && (
              <IncFaIcon
                className="inc-cursor-pointer marginRt12"
                iconName="clone"
                onClick={onDuplicate}
                title="Clone metric"
              />
            )}

            {canRemove && (
              <IncFaIcon
                className="inc-cursor-pointer status-danger"
                iconName="trash-alt"
                onClick={onRemove}
                title="Remove metric"
              />
            )}
          </div>
        )}
      </>
    ),
    [canDuplicate, canRemove, displayError, error, onDuplicate, onRemove]
  );

  const showBeFieldNameDD = beFieldNames?.length > 1;

  const { enableSpikeConfiguration, enableSubTypeSelection } = advancedPropertiesEditorProps;
  const showAdvancedSection = enableSubTypeSelection || enableSpikeConfiguration;

  const isEventIDFieldSelection =
    USFieldWidgetUtils.isEventIDField(userServiceField?.fieldName) && aggregator === "count";
  const { isFailedEventsSelection, isSuccessEventsSelection } = useMemo(() => {
    let isSuccessEventsSelection = false;
    let isFailedEventsSelection = false;

    if (isEventIDFieldSelection) {
      const hasErrorFilter = filterExpressions.find(f => USFieldWidgetUtils.isHasErrorField(f.field.fieldName));

      if (hasErrorFilter?.value === "true") {
        isFailedEventsSelection = true;
      } else if (hasErrorFilter?.value === "false") {
        isSuccessEventsSelection = true;
      }
    }

    return {
      isSuccessEventsSelection,
      isFailedEventsSelection
    };
  }, [filterExpressions, isEventIDFieldSelection]);

  return (
    <div className="event-field-query-editor">
      <div className="event-field-query-editor--header">
        {!minimalView && (
          <VerticallyCenteredRow className="inc-flex-row inc-flex-center-vertical flex-gap-12">
            {queryIdExists && <div className="query-id">{queryId}</div>}

            {Boolean(eventTypeName) && !eventTypeSelector && (
              <VerticallyCenteredRow className="event-type-info flex-gap-12">
                {iconExists && (
                  <IncFaIcon
                    className="event-type-icon"
                    iconName={iconName}
                  />
                )}
                <VerticallyCenteredRow className="event-type-name">{eventTypeName}</VerticallyCenteredRow>
              </VerticallyCenteredRow>
            )}

            {eventTypeSelector}

            {showBeFieldNameDD && (
              <IncSlimSelect
                autoAdjustWidth
                autoAdjustWidthBuffer={20}
                onChange={onBizEntityFieldNameChange}
                options={beFieldNameOpts}
                readOnly={queryReadOnly}
                value={selectedBeFieldNameOpt}
              />
            )}

            {actionsJsx}
          </VerticallyCenteredRow>
        )}

        <VerticallyCenteredRow className="width-100 flex-gap-12">
          {minimalView && queryIdExists && <div className="query-id">{queryId}</div>}

          {minimalView && showBeFieldNameDD && (
            <IncSlimSelect
              autoAdjustWidth
              autoAdjustWidthBuffer={20}
              onChange={onBizEntityFieldNameChange}
              options={beFieldNameOpts}
              readOnly={queryReadOnly}
              value={selectedBeFieldNameOpt}
            />
          )}

          {minimalView && eventTypeSelector}

          <EventFieldSelector
            addRateMetricOptions={addRateMetricOptions}
            aggregator={aggregator}
            demoDataParams={demoDataParams}
            eventTypeName={eventTypeName}
            fieldLabel={fieldLabel}
            fieldPickerContext={fieldPickerContext}
            filterExpressions={filterExpressions}
            getLatestTimeRange={getLatestTimeRange}
            hideLookBack={minimalView}
            lookBack={lookBack}
            onChange={onConfigChange}
            onRateMetricSelect={onRateMetricSelect}
            readOnly={queryReadOnly}
            userServiceField={userServiceField}
          />

          {minimalView && actionsJsx}
        </VerticallyCenteredRow>
      </div>

      <div className="event-field-query-editor--content">
        <EventFilterSelector
          demoDataParams={demoDataParams}
          fieldPickerContext={fieldPickerContext}
          filterExpressions={filterExpressions}
          getLatestTimeRange={getLatestTimeRange}
          hideHasErrorFilter={isFailedEventsSelection || isSuccessEventsSelection}
          noToggle={nonCollapsibleFilters}
          onChange={onFiltersChange}
          readOnly={queryReadOnly}
        />
      </div>

      {showAdvancedSection && (
        <EventFieldPropertiesEditor
          {...advancedPropertiesEditorProps}
          propertiesReadOnly={propertiesReadOnly}
          queryReadOnly={queryReadOnly}
        />
      )}

      {!disableSliceSelection && (
        <div className="event-field-query-editor--footer">
          <EventGroupBySelector
            canRemoveImplicitSlice={canRemoveImplicitSlice}
            demoDataParams={demoDataParams}
            disabled={!userServiceField}
            displayImplicitSlice={displayImplicitSlice}
            fieldPickerContext={fieldPickerContext}
            getLatestTimeRange={getLatestTimeRange}
            onChange={onSliceSetsChange}
            onFieldPickerModelChange={setFieldPickerModel}
            readOnly={queryReadOnly}
            sliceOptions={sliceOptions}
            sliceSets={sliceSets}
          />
        </div>
      )}
    </div>
  );
});

const adjustSliceSets = (
  metricConfig: UserServiceFieldMetricConfig,
  fieldPickerModel: FieldPickerModel,
  selectedBizEntityFieldName: string
) => {
  const { sliceSets } = metricConfig;

  const usField = fieldPickerModel?.getEntityUSField(selectedBizEntityFieldName);
  const implicitSlice = usField ? getImplicitSlice(usField) : null;

  const nSliceSets = [...sliceSets];

  if (!nSliceSets.length) {
    if (implicitSlice) {
      nSliceSets.push({
        slices: [implicitSlice]
      });
    }
  }

  /**
   * Adjusting sliceSet's slices here. For implicit slice, the tagName needs to be changed.
   * So we check if slice.USF === implicitSlice.USF, based on which we update tagName
   */
  nSliceSets.forEach(sliceSet => {
    const { slices } = sliceSet;
    const nSlices: typeof slices = [];
    let nImplicitSlice: UserServiceFieldSlice;
    slices.forEach(slice => {
      const isImplicitSliceUSF = isEqual(slice.userServiceField, implicitSlice?.userServiceField);
      if (isImplicitSliceUSF) {
        nImplicitSlice = {
          ...slice,
          tagName: ENTITY_TAG
        };
      } else {
        nSlices.push(slice);
      }
    });
    nImplicitSlice && nSlices.unshift(nImplicitSlice);
    sliceSet.slices = nSlices;
  });

  metricConfig.sliceSets = nSliceSets;
};
