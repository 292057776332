import { cloneDeep, isEmpty, omit } from "lodash";
import { v4 as uuidV4 } from "uuid";
import { FieldPrimType, logger } from "../../../core";
import datasourceApiManager from "../DatasourceApiService";
import { SALESFORCE_CONFIG_TYPE } from "../../../components/business-entity/source-constants";
import { routePaths } from "../../../../app/RoutePaths";
import { ApptuitDatasource } from "../../datasources/apptuit/ApptuitDatasource";
import {
  UiBizfieldDetails,
  BizSource,
  BizSourceType,
  ConnectorPreviewData,
  EntityMasterMapping,
  EntityMasterMappingResponse,
  PropertySource,
  Transform,
  TypeRef,
  UserServiceEntity,
  UserServiceField,
  PreviewEntitiesData,
  EntityConnectorStatusResponse,
  EntityConnectorActionRequestPayload,
  EntityConnectorActionResponse,
  PayloadPointer,
  UserServiceMapping
} from "./types";

/**
 * Enitity mapping API V1 calls
 */
interface EntityMappingApiServiceInterface {
  getBusinessEntitiesAndRelationship: (entityTypeIds?: string[]) => Promise<EntityMasterMappingResponse>;
  getUserServicesForEntity: (entityType: string, from: number, to: number) => Promise<UserServiceEntity[]>;
  getPotentialUserServices(entityType: string, from: number, to: number): Promise<PotentialUserService[]>;
  getFieldsForUserService: (
    userServiceIds: string[],
    from: number,
    to: number
  ) => Promise<Map<string, UserServiceField[]>>;
  upsertEntity(entityMapping: EntityMasterMapping): Promise<EntityMasterMapping>;
  getSourceTypes(): Promise<BizSourceType[]>;
  getSourcesForType(typeId: string): Promise<BizSource[]>;
  getSchemaForSource(sourceId: string): Promise<Map<string, UiBizfieldDetails>>;
  getBizFieldsPreviewData(
    sourceId: string,
    entityTypeId: string,
    propertyNames: string[],
    mapping: EntityMasterMapping
  ): Promise<PreviewEntitiesData[]>;
  getUserServicePreviewData(
    entityTypeId: string,
    userServiceMapping: UserServiceMapping
  ): Promise<PreviewEntitiesData[]>;
  getTypeForSources(sourceIds: Set<string>): Promise<Map<string, PropertySource>>;
  deleteBusinessFields(entityType: string, fields: string[]): Promise<EntityMasterMapping>;
  salesforceRedirect(configId: string): void;

  getConnectorPreviewData(connectorSourceId: string, limit: number): Promise<ConnectorPreviewData>;
  getConnectorStatus(connectorSourceId: string): Promise<EntityConnectorStatusResponse>;
  performConnectorAction(
    payload: Omit<EntityConnectorActionRequestPayload, "traceInfo">
  ): Promise<EntityConnectorActionResponse>;
}

class EntityMappingApiService implements EntityMappingApiServiceInterface {
  readonly ENTITY_MAPPING_BASE_URL: string = "/entity-mapping/api/v2";
  private datasource: ApptuitDatasource;

  async getBusinessEntitiesAndRelationship(entityTypeIds?: string[]): Promise<EntityMasterMappingResponse> {
    try {
      await this.init();
      const url = this.getUrl("/mappings/search");

      const payload = {
        category: "biz",
        entityTypeIds
      };

      if (!entityTypeIds || !entityTypeIds?.length) {
        delete payload.entityTypeIds;
      }

      const response = await this.datasource.post(url, payload);
      const mappingData: EntityMasterMappingResponse = (response as any).data;
      return mappingData;
    } catch (e) {
      logger.error("EntityMappingApiService", "Error while fetching entity mapping data for biz types", e);
      throw e;
    }
  }

  async getPotentialUserServices(entityType: string, from: number, to: number): Promise<PotentialUserService[]> {
    try {
      await this.init();
      const subUrl = `/entity-types/${entityType}/potential-user-services2?st=${from}&et=${to}`;
      const url = this.getUrl(subUrl);

      const data: PotentialUserServiceResponse = await this.datasource.get(url);

      let retData: PotentialUserService[] = [];

      if (data && data.data && !isEmpty(data.data.userServices)) {
        retData = data.data.userServices.map(potentialUsrSrv => {
          const fieldMapping: UserServiceFieldMapping = potentialUsrSrv.fieldMappings &&
            potentialUsrSrv.fieldMappings[0] && {
              traceFieldEntityId: potentialUsrSrv.fieldMappings[0].entityId,
              displayName: potentialUsrSrv.fieldMappings[0].displayName,
              lastSeenTs: parseInt(potentialUsrSrv.fieldMappings[0].lastSeenTs, 10)
            };

          const usrSrv: PotentialUserService = {
            fieldMappings: [fieldMapping],
            userService: potentialUsrSrv.userService
          };

          return usrSrv;
        });
      }

      return retData;
    } catch (e) {
      logger.error("EntityMappingApiService", "Error while fetching user services for entity", e);
      throw e;
    }
  }

  async getUserServicesForEntity(entityType: string, from: number, to: number): Promise<UserServiceEntity[]> {
    try {
      await this.init();
      const subUrl = `/entity-types/${entityType}/user-services?st=${from}&et=${to}`;
      const url = this.getUrl(subUrl);

      const response: UserServiceEntityResponse = await this.datasource.get(url);
      return response.data.entities;
    } catch (e) {
      logger.error("EntityMappingApiService", "Error while fetching user services for entity", e);
      throw e;
    }
  }

  async getFieldsForUserService(
    userServiceIds: string[],
    from: number,
    to: number
  ): Promise<Map<string, UserServiceField[]>> {
    try {
      await this.init();
      const subUrl = `/entities/user-services/fields?st=${from}&et=${to}`;
      const url = this.getUrl(subUrl);

      const payload = { entityIds: userServiceIds };

      const response: UserServiceFieldsResponse = await this.datasource.post(url, payload);

      const userServiceToFields: Map<string, UserServiceField[]> = new Map();
      const { results } = response.data;
      if (!results) {
        return userServiceToFields;
      }

      for (const [userServiceId, fields] of Object.entries(results)) {
        if (fields.entities && fields.entities.length > 0) {
          const userFields: UserServiceField[] = fields.entities.map(
            (fieldEntity): UserServiceField => ({
              id: fieldEntity.entityId,
              name: fieldEntity.props.name.stringVal,
              displayName: fieldEntity.displayName,
              dataType: fieldEntity.props.data_type.stringVal
            })
          );

          userServiceToFields.set(userServiceId, userFields);
        }
      }

      return userServiceToFields;
    } catch (e) {
      logger.error("EntityMappingApiService", "Error while fetching user services for entity", e);
      throw e;
    }
  }

  async upsertEntity(entityMapping: EntityMasterMapping): Promise<EntityMasterMapping> {
    try {
      await this.init();
      const url = this.getUrl("/mappings");

      const payload = omit(entityMapping, ["primaryType.fieldMappings"]);

      const response: CreateEntityResponse = await this.datasource.post(url, payload);
      return response.data;
    } catch (e) {
      logger.error("EntityMappingApiService", "Error while fetching entity mapping data for biz types", e);
      throw e;
    }
  }

  async getSourceTypes(): Promise<BizSourceType[]> {
    try {
      await this.init();
      const url = this.getUrl("/source-types");

      const response: { data: { types: BizSourceType[] } } = await this.datasource.get(url);
      return response.data.types;
    } catch (e) {
      logger.error("EntityMappingApiService", "Error while fetching source types", e);
      throw e;
    }
  }

  async getSourcesForType(typeId: string): Promise<BizSource[]> {
    try {
      await this.init();
      const subUrl = `/source-types/${typeId}`;
      const url = this.getUrl(subUrl);

      const response: { data: { sources: BizSource[] } } = await this.datasource.get(url);
      return response.data.sources;
    } catch (e) {
      logger.error("EntityMappingApiService", "Error while fetching source types", e);
      throw e;
    }
  }

  async getSchemaForSource(sourceId: string): Promise<Map<string, UiBizfieldDetails>> {
    try {
      await this.init();
      const subUrl = `/sources/${sourceId}/schema`;
      const url = this.getUrl(subUrl);

      //schema response will not have dataTransformations property,
      //so it is defaulted to empty to support valueMapping type
      const response: SchemaSourceResponse = await this.datasource.get(url);
      const retMap: Map<string, UiBizfieldDetails> = new Map();

      if (response && response.data) {
        for (const [fieldName, fieldDetails] of Object.entries(response.data.fields)) {
          // If we have fields also, iterate over the sub fields and add ro return object
          if (!isEmpty(fieldDetails.fields)) {
            const { payloadPointer } = fieldDetails;

            for (const [subFieldName, subFieldDetails] of Object.entries(fieldDetails.fields)) {
              const name = `${payloadPointer.paths[0].segment}.${subFieldName}`;
              retMap.set(name, {
                rootPayloadPointer: response.data.payloadPointer,
                fieldName: name,
                valueMapping: {
                  fieldPaths: subFieldDetails.paths,
                  transform: subFieldDetails.transform,
                  payloadPointer,
                  jsonPath: subFieldDetails.jsonPath,
                  returnType: subFieldDetails.returnType,
                  dataTransformations: []
                }
              });
            }
          } else {
            retMap.set(fieldName, {
              rootPayloadPointer: response.data.payloadPointer,
              fieldName,
              valueMapping: {
                fieldPaths: fieldDetails.paths,
                transform: fieldDetails.transform,
                payloadPointer: fieldDetails.payloadPointer,
                jsonPath: fieldDetails.jsonPath,
                returnType: fieldDetails.returnType,
                dataTransformations: []
              }
            });
          }
        }
      }

      return retMap;
    } catch (e) {
      logger.error("EntityMappingApiService", "Error while fetching source types", e);
      throw e;
    }
  }

  async getBizFieldsPreviewData(
    sourceId: string,
    entityTypeId: string,
    propertyNames: string[],
    mapping: EntityMasterMapping
  ): Promise<PreviewEntitiesData[]> {
    try {
      await this.init();
      const url = this.getUrl("/mappings/preview");

      const cloneMapping = cloneDeep(mapping);
      delete cloneMapping.primaryType.fieldMappings;

      const payload = {
        sourceId,
        entityTypeId,
        propertyNames,
        masterMapping: cloneMapping,
        limit: 100
      };

      const response: { data: { entities: PreviewEntitiesData[] } } = await this.datasource.post(url, payload);
      return response.data.entities;
    } catch (e) {
      logger.error("EntityMappingApiService", "Error while fetching source types", e);
      throw e;
    }
  }

  async getUserServicePreviewData(
    entityTypeId: string,
    userServiceMapping: UserServiceMapping
  ): Promise<PreviewEntitiesData[]> {
    try {
      await this.init();
      const url = this.getUrl("/user-service-mappings/preview");
      const payload = {
        userServiceMapping,
        entityTypeId,
        limit: 50
      };

      const response: { data: { entities: PreviewEntitiesData[] } } = await this.datasource.post(url, payload);
      return response.data.entities;
    } catch (e) {
      logger.error("EntityMappingApiService", "Error while fetching source types", e);
      throw e;
    }
  }

  async getTypeForSources(sourceIds: Set<string>): Promise<Map<string, PropertySource>> {
    try {
      await this.init();
      const url = this.getUrl("/sources/type-batch");
      const payload = {
        values: [...sourceIds]
      };

      const response: {
        data: { sourceTypes: Record<string, SourceTypeInfo> };
      } = await this.datasource.post(url, payload);

      const retMap: Map<string, PropertySource> = new Map();

      if (response && response.data && response.data.sourceTypes) {
        for (const [sourceId, metadata] of Object.entries(response.data.sourceTypes)) {
          retMap.set(sourceId, {
            type: metadata.sourceType.typeId,
            name: metadata.sourceType.typeName,
            sourceId,
            metadata: metadata.typeRef.metadata
          });
        }
      }

      return retMap;
    } catch (e) {
      logger.error("EntityMappingApiService", "Error while fetching source info for source ids", e);
      throw e;
    }
  }

  async deleteBusinessFields(entityType: string, fields: string[]): Promise<EntityMasterMapping> {
    try {
      await this.init();
      const subUrl = `/entity-types/${entityType}/delete-properties`;
      const url = this.getUrl(subUrl);
      const payload = {
        data: {
          values: fields
        }
      };

      const response: { data: EntityMasterMapping } = await this.datasource.delete(url, payload);

      return response && response.data;
    } catch (e) {
      logger.error("EntityMappingApiService", "Error while deleting business fields", e);
      throw e;
    }
  }

  async salesforceRedirect(configId: string) {
    const { protocol } = window.location;
    const { host } = window.location;

    // Need to get the proxy url which will be in this format: "/apptuit/api/datasources/proxy/18"
    const { proxyUrl } = datasourceApiManager.getDefault();

    const bicyclePrefix = `${protocol}//${host}${proxyUrl}`;
    const uiRedirectUrl = `${protocol}//${host}${routePaths.integrationsSalesforce}/edit?id=${configId}`;

    const payload = {
      config_type: SALESFORCE_CONFIG_TYPE,
      config_id: configId,
      ui_redirect: uiRedirectUrl,
      bicycle_prefix: bicyclePrefix,
      response_type: 200
    };

    try {
      await this.init();
      const url = this.getUrl("/salesforce/redirect");
      // The response for this will be redirect 302 status code which will
      // take user to the salesforce login page
      const response: { data: string } = await this.datasource.post(url, payload);
      window.location.href = response.data;
    } catch (e) {
      logger.error("EntityMappingApiService", "Error while calling salesforce redirect", e);
    }
  }

  async getConnectorPreviewData(connectorSourceId: string, limit: number): Promise<ConnectorPreviewData> {
    try {
      await this.init();
      const url = this.getUrl("/mappings/data-preview");
      const response: { data: ConnectorPreviewData } = await this.datasource.post(url, {
        connectorSourceId: connectorSourceId,
        limit: limit
      });

      return response && response.data;
    } catch (e) {
      logger.error("EntityMappingApiService", "Error while fetching preview data", e);
    }

    return null;
  }

  async getConnectorStatus(connectorSourceId: string): Promise<EntityConnectorStatusResponse> {
    try {
      await this.init();
      const url = this.getUrl("/connectors/status");
      const response: { data: EntityConnectorStatusResponse } = await this.datasource.post(url, {
        connectionId: [connectorSourceId],
        traceInfo: {
          traceId: uuidV4()
        }
      });
      return response && response.data;
    } catch (e) {
      logger.error("EntityMappingApiService", "Error fetch connection status", e);
    }

    return null;
  }

  async performConnectorAction(
    payload: Omit<EntityConnectorActionRequestPayload, "traceInfo">
  ): Promise<EntityConnectorActionResponse> {
    try {
      await this.init();

      const url = this.getUrl("/connectors/action");
      const response: { data: EntityConnectorActionResponse } = await this.datasource.post(url, {
        ...payload,
        traceInfo: {
          traceId: uuidV4()
        }
      });
      return response && response.data;
    } catch (e) {
      logger.error("EntityMappingApiService", "Error performing connector action", e);
    }

    return null;
  }

  private getUrl(subUrl: string): string {
    return `${this.ENTITY_MAPPING_BASE_URL}${subUrl}`;
  }

  private async init() {
    if (!this.datasource) {
      this.datasource = await datasourceApiManager.getDefault();
    }
  }
}

const entityMappingApiService: EntityMappingApiServiceInterface = new EntityMappingApiService();
export default entityMappingApiService;

type UserServiceEntityResponse = {
  data: {
    entities: UserServiceEntity[];
  };
};

type UserServiceFieldsResponse = {
  data: {
    results: Record<string, FieldEntity>;
  };
};

type FieldEntity = {
  entities: Array<{
    entityId: string;
    displayName: string;
    props: {
      data_type: {
        stringVal: string;
      };
      name: {
        stringVal: string;
      };
    };
  }>;
};

type CreateEntityResponse = {
  data: EntityMasterMapping;
};

type SchemaSourceResponse = {
  data: {
    payloadPointer: PayloadPointer;
    fields: Record<string, SchemaField>;
  };
};

type SchemaField = {
  payloadPointer?: any;
  paths: Array<{
    segment: string;
  }>;
  transform: Transform;
  fields: Record<string, SchemaField>;
  jsonPath: string;
  returnType: FieldPrimType;
};

type PotentialUserServiceResponse = {
  data: {
    userServices: Array<{
      fieldMappings: Array<{
        typeId: string;
        displayName: string;
        lastSeenTs: string;
        entityId: string;
      }>;
      userService: UserServiceEntity;
    }>;
  };
};

export type UserServiceFieldMapping = {
  traceFieldEntityId: string;
  displayName: string;
  lastSeenTs: number;
};

export type PotentialUserService = {
  fieldMappings: UserServiceFieldMapping[];
  userService: UserServiceEntity;
};

type SourceType = {
  typeId: string;
  typeName: string;
};

type SourceTypeInfo = {
  sourceType: SourceType;
  typeRef: TypeRef;
};
