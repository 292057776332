import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { clone } from "lodash";
import { VerticallyCenteredRow } from "../../../../../../components";
import { StandAloneWidget } from "../../../../../../components/StandaloneWidget";
import { RawTimeRange, useForceUpdate, useTimeRange, Visualisations } from "../../../../../../core";
import { CatalogWidgetImpl, WidgetQuerySourceConfig } from "../../../models";
import { CatalogVizSwitcher } from "../VizSwitcher";
import DashboardImpl from "../../../../../model-impl/DashboardImpl";
import { CatalogWidgetUtils } from "../../../CatalogWidgetUtils";
import timeRangeUtils from "../../../../../../utils/TimeRangeUtils";
import { TimeRangeController } from "../../../../../../components/time-range";
import { noOp } from "../../../../../../utils";
import { VariableSrv } from "../../../../../variables";

interface Props {
  dbImpl?: DashboardImpl;
  widgetImpl: CatalogWidgetImpl;
  onWidgetImplChange?: (widgetImpl: CatalogWidgetImpl) => void;
  isValid: boolean;
  hideVizSwitcher?: boolean;
  updateOnSilentChange?: boolean;
  vizErrorMessage?: string;
  variableSrvMap?: Record<string, VariableSrv>;
}

export const Visualization: FC<Props> = props => {
  const {
    onWidgetImplChange = noOp,
    widgetImpl,
    dbImpl,
    isValid,
    hideVizSwitcher,
    updateOnSilentChange,
    vizErrorMessage,
    variableSrvMap
  } = props;

  /**
   * This is a hacky fix to prevent immediate re-render of the standalone widget.
   * Immediate re-render is causing an unneccessary data fetch with old payload.
   */
  const canRenderRef = useRef(true);
  const hasRenderedRef = useRef(false);
  const forceUpdate = useForceUpdate();

  const canRender = useMemo(() => {
    canRenderRef.current = false;

    setTimeout(() => {
      canRenderRef.current = true;
      forceUpdate();
    }, 500);

    if (!hasRenderedRef.current) {
      hasRenderedRef.current = true;
      return true;
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate, widgetImpl]);

  const { timeRange: gTimeRange, compareTimeRange: gCompareTimeRange } = useTimeRange();
  const { raw: compareRawTimeRange } = gCompareTimeRange || {};
  const { from: dbCompareFrom } = compareRawTimeRange || {};
  const dTimeRange = useMemo(() => widgetImpl.timeRange || gTimeRange, [gTimeRange, widgetImpl.timeRange]);

  const [timeRange, setTimeRange] = useState(dTimeRange);
  const compareTimeRange = useMemo(
    () => timeRangeUtils.getCompareTimeRangeFromRaw(timeRange, widgetImpl.getCompareInterval(dbCompareFrom) || "1h"),
    [dbCompareFrom, timeRange, widgetImpl]
  );

  const onTimeRangeChange = useCallback((rawTimeRange: RawTimeRange) => {
    const timeRange = timeRangeUtils.getTimeRangeFromRaw(rawTimeRange);
    setTimeRange(timeRange);
  }, []);

  const dataQueryExists = useMemo(() => {
    const { sourceQueryConfig } = widgetImpl.queryConfig;
    const { widgetResponse, metricId } = (sourceQueryConfig as WidgetQuerySourceConfig) || {};

    const metricDef = widgetResponse?.widgetConfig?.dataDefinition?.metrics?.[metricId];
    if (metricDef) {
      return metricDef.sourceType === "userServiceField"
        ? Boolean(metricDef.userServiceFieldMetricConfig.userServiceField)
        : metricDef.sourceType === "expression"
          ? Boolean(metricDef.expressionMetricConfig.expression)
          : true;
    }

    return true;
  }, [widgetImpl]);

  const onVizImplUpdate = useCallback(() => {
    if (updateOnSilentChange) {
      onWidgetImplChange(widgetImpl);
      forceUpdate();
    }
  }, [forceUpdate, onWidgetImplChange, updateOnSilentChange, widgetImpl]);

  const { properties, queryConfig } = widgetImpl;

  const { visualisation } = properties || {};

  const vizOptions = useMemo(() => CatalogWidgetUtils.getVisualisations(queryConfig), [queryConfig]);
  const onVisualisationChange = useCallback(
    (visualisation: Visualisations) => {
      const nWidgetImpl = clone(widgetImpl);
      nWidgetImpl.properties.visualisation = visualisation;
      onWidgetImplChange(nWidgetImpl);
    },
    [onWidgetImplChange, widgetImpl]
  );

  return (
    <div
      className="content__viz inc-flex-column height-100 width-100 flex-gap-12"
      data-show-switch={!hideVizSwitcher}
    >
      <VerticallyCenteredRow className="inc-text-subtext-medium inc-text-inactive flex-gap-6 marginLtAuto preview-time-controller">
        Preview for
        <TimeRangeController
          className="inc-text-subtext-medium"
          compareTimeRange={compareTimeRange}
          disableRefresh
          disableTooltip
          setCompareTimeRange={noOp}
          setTimeRange={onTimeRangeChange}
          showTimeRangeSelector
          size="small"
          timeRange={timeRange}
        />
      </VerticallyCenteredRow>

      <div
        className="inc-flex-column width-100 viz-wrapper"
        style={{ height: "calc(100% - 24px)" }}
      >
        {!isValid && (
          <VerticallyCenteredRow className="width-100 height-100 inc-flex-center inc-text-subtext-medium status-warning">
            {vizErrorMessage || "Invalid datapoint(s) configuration"}
          </VerticallyCenteredRow>
        )}
        {isValid && (
          <>
            {!dataQueryExists && (
              <VerticallyCenteredRow className="width-100 height-100 inc-flex-center inc-text-subtext-medium inc-text-inactive">
                Add metrics to preview
              </VerticallyCenteredRow>
            )}
            {dataQueryExists && (
              <>
                {!hideVizSwitcher && (
                  <VerticallyCenteredRow className="flex-gap-16">
                    <VerticallyCenteredRow className="inc-text-subtext inc-text-inactive">
                      Preview
                    </VerticallyCenteredRow>

                    <CatalogVizSwitcher
                      onVisualisationChange={onVisualisationChange}
                      visualisation={visualisation}
                      vizOptions={vizOptions}
                    />
                  </VerticallyCenteredRow>
                )}
                {(canRenderRef.current || canRender) && (
                  <StandAloneWidget
                    compareTimeRange={compareTimeRange}
                    dbImpl={dbImpl}
                    onUpdate={onVizImplUpdate}
                    timeRange={timeRange}
                    variableSrvMap={variableSrvMap}
                    widget={widgetImpl}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
