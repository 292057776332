import { IncClickAwayPopper, IncFaIcon, IncInfoIcon, IncTextfield, IncToolTip } from "@inception/ui";
import { groupBy } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { VerticallyCenteredRow } from "../../../../../components";
import { generateId, useToggleState } from "../../../../../core";
import {
  ActionTemplateElementGroup,
  ActionTemplateElementsResponse,
  ActionTemplateSectionElement,
  ActionTemplateTextElement,
  SectionElementType
} from "../../../../../services/api/operationalise";

interface Props {
  designerTemplate: ActionTemplateElementsResponse;

  onAddSection: (section: ActionTemplateSectionElement) => void;
  onAddTextElement: (textElement: ActionTemplateTextElement) => void;

  onOpen: () => void;
  onClose: () => void;

  filterOutLoopElements?: boolean;
}

export const SectionGroupElementsSelector: FC<Props> = props => {
  const { designerTemplate, onAddSection, onAddTextElement, onOpen, onClose, filterOutLoopElements } = props;

  const uniqId = useMemo(() => generateId(), []);

  const anchorRef = useRef<HTMLDivElement>();

  const { close: closePopper, isOpen: isPopperOpen, open: openPopper } = useToggleState();

  useEffect(() => {
    if (isPopperOpen) {
      onOpen();
    } else {
      onClose();
      setSearchText("");
    }
  }, [isPopperOpen, onClose, onOpen]);

  const [searchText, setSearchText] = useState("");

  const onSearchTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const nSearchText = e.target.value;
    setSearchText(nSearchText);
  }, []);

  const { sectionElement: sectionElements, textElement, elementGroup } = designerTemplate || {};

  const textElements = useMemo(
    () =>
      (textElement || []).filter(el => {
        const labelExists = Boolean(el.label);
        const shouldInclude = filterOutLoopElements ? !el.useInLoopOnly : true;

        return labelExists && shouldInclude;
      }),
    [filterOutLoopElements, textElement]
  );

  const groupMap = useMemo(() => {
    const groupOrderMap: Record<string, ActionTemplateElementGroup> = {};

    elementGroup?.forEach(group => {
      groupOrderMap[group.name] = group;
    });

    return groupOrderMap;
  }, [elementGroup]);

  const groupedTextElements = useMemo(() => groupBy(textElements, "group"), [textElements]);
  const filteredSectionElements = useMemo(
    () => (sectionElements || []).filter(el => el.type !== SectionElementType.RICH_TEXT),
    [sectionElements]
  );
  const numFilteredSectionElements = filteredSectionElements?.length || 0;

  const groupKeys = useMemo(() => {
    const textGroupKeys = Object.keys(groupedTextElements);

    const sortedGroupKeys = textGroupKeys.sort((groupKeyA, groupKeyB) => {
      const groupOrderA = groupMap[groupKeyA]?.groupOrder || Number.MAX_SAFE_INTEGER;
      const groupOrderB = groupMap[groupKeyB]?.groupOrder || Number.MAX_SAFE_INTEGER;

      return groupOrderA - groupOrderB;
    });

    return numFilteredSectionElements ? [...sortedGroupKeys, SECTION_GROUP_KEY] : sortedGroupKeys;
  }, [numFilteredSectionElements, groupMap, groupedTextElements]);

  const [selectedGroupKey, setSelectedGroupKey] = useState(groupKeys[0]);

  const groupsJsx = useMemo(
    () =>
      groupKeys.map((groupKey, idx) => {
        const key = [uniqId, "group", groupKey, idx].join("_");
        const onClick = () => setSelectedGroupKey(groupKey);
        const isSelected = selectedGroupKey === groupKey;

        return (
          <VerticallyCenteredRow
            className="selection-item"
            data-selected={isSelected}
            key={key}
            onClick={onClick}
          >
            {groupKey}
          </VerticallyCenteredRow>
        );
      }),
    [groupKeys, selectedGroupKey, uniqId]
  );

  const filteredEntriesJsx = useMemo(() => {
    const isSectionGroupSelection = selectedGroupKey === SECTION_GROUP_KEY;

    const filteredSections =
      isSectionGroupSelection && searchText
        ? filteredSectionElements.filter(el =>
            (el.label || "").toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
          )
        : filteredSectionElements;

    if (isSectionGroupSelection) {
      return filteredSections.map(section => {
        const { elementId, icon, label, description } = section;
        const key = [uniqId, "section", elementId].join("_");

        const onClick = () => {
          onAddSection(section);
          closePopper();
        };

        return (
          <VerticallyCenteredRow
            className="selection-item"
            key={key}
            onClick={onClick}
          >
            {Boolean(icon) && (
              <div
                className="icon marginRt2"
                dangerouslySetInnerHTML={{ __html: icon }}
              />
            )}

            {label}

            <IncToolTip
              placement="top"
              showArrow
              titleText={description}
            >
              <VerticallyCenteredRow className="marginLt2">
                <IncInfoIcon />
              </VerticallyCenteredRow>
            </IncToolTip>
          </VerticallyCenteredRow>
        );
      });
    }

    const unfilteredEntries = groupedTextElements[selectedGroupKey] || [];
    const filteredTextElements = searchText
      ? unfilteredEntries.filter(el => el.label.includes(searchText))
      : unfilteredEntries;
    const sortedTextElements = filteredTextElements.sort((a, b) => a.fieldOrder - b.fieldOrder);

    return sortedTextElements.map(textElement => {
      const { elementId, icon, label, description } = textElement;
      const key = [uniqId, "section", elementId].join("_");

      const onClick = () => {
        onAddTextElement(textElement);
        closePopper();
      };

      return (
        <VerticallyCenteredRow
          className="selection-item"
          key={key}
          onClick={onClick}
        >
          {Boolean(icon) && (
            <div
              className="icon marginRt2"
              dangerouslySetInnerHTML={{ __html: icon }}
            />
          )}

          {label}

          <IncToolTip
            placement="top"
            showArrow
            titleText={description}
          >
            <VerticallyCenteredRow className="marginLt2">
              <IncInfoIcon />
            </VerticallyCenteredRow>
          </IncToolTip>
        </VerticallyCenteredRow>
      );
    });
  }, [
    closePopper,
    filteredSectionElements,
    groupedTextElements,
    onAddSection,
    onAddTextElement,
    searchText,
    selectedGroupKey,
    uniqId
  ]);

  return (
    <VerticallyCenteredRow className="section-group--elements-selector">
      <VerticallyCenteredRow
        className="inc-button inc-button-primary inc-button-small"
        color="primary"
        onClick={openPopper}
        ref={anchorRef}
      >
        <IncFaIcon
          iconName="plus"
          size="sm"
        />
      </VerticallyCenteredRow>

      <IncClickAwayPopper
        anchorEl={anchorRef.current}
        className="section-group--elements-selector__popper"
        onClickAway={closePopper}
        placement="bottom-end"
        show={isPopperOpen}
      >
        <div>
          <VerticallyCenteredRow className="header">
            <div className="group-list">
              <div className="selection-item">Groups</div>
            </div>
            <div className="group-values">
              <div className="selection-item">
                <IncTextfield
                  onChange={onSearchTextChange}
                  placeholder="Search"
                  startIcon="search"
                  value={searchText}
                />
              </div>
            </div>
          </VerticallyCenteredRow>

          <div className="inc-flex-row">
            <div className="group-list">
              <div className="values">
                {Boolean(groupsJsx.length) && groupsJsx}
                {!groupsJsx.length && <div className="inc-label-common">No groups found</div>}
              </div>
            </div>
            <div className="group-values">
              <div className="values">
                {Boolean(filteredEntriesJsx.length) && filteredEntriesJsx}
                {!filteredEntriesJsx.length && <div className="inc-label-common">No matching elements found</div>}
              </div>
            </div>
          </div>
        </div>
      </IncClickAwayPopper>
    </VerticallyCenteredRow>
  );
};

const SECTION_GROUP_KEY = "Sections";
