import React, { FC, useCallback, useMemo, useEffect, useState, useRef } from "react";
import { IncSelectOption, IncSelect, IncSwitch, IncToggle } from "@inception/ui";
import { DeepPartial } from "redux";
import { isNull, isUndefined, orderBy, isArray, mergeWith, isObject, isEqual, forEach } from "lodash";
import {
  BizActionConfig,
  ActionSourceTypes,
  AlertActionDef,
  UIIntegrationActionConfig,
  ParamValueTemplate,
  OpCreationConfig,
  operationaliseV2ApiService,
  ActionSourceType,
  BizActionParamValuesResponse,
  ActionRunMode,
  ActionTriggerMode
} from "../../../../../services/api/operationalise";
import { useFetchUIIntegrationConfigs, logger, useToggleState } from "../../../../../core";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../../../components";
import { getPropertyKeyForDataType } from "../../../../../utils";
import { OpMode } from "../../../../types";
import EditableLabelInput from "../../../../../components/editable-input-label/EditableInputLabel";
import { IntegrationConfigParamsEditor } from "./IntegrationConfigParamsEditor";
import { TestActionRenderer } from "./TestActionRenderer";
import { checkIfTemplate, checkIfTemplateSection } from "./utils";

interface Props {
  bizActionId: string;
  bizActionConfig: BizActionConfig;
  onChange: (bizAction: BizActionConfig, bizActionId: string) => void;
  op10zeId: string;
  opCreationConfig: OpCreationConfig;
  sourceTypeInfoByCategory: Record<string, Record<string, ActionSourceType>>;

  errors: string[];
  onErrors: (errors: string[]) => void;

  onIntegrationsFetched?: (uiIntegrationConfigs: UIIntegrationActionConfig[]) => void;
  mode: OpMode;

  readOnly?: boolean;
  supportsTestActionWithRealAlertOnly?: boolean;
  skipName?: boolean;
}

export const BizActionConfigEditor: FC<Props> = props => {
  const {
    bizActionId,
    bizActionConfig,
    onChange,
    op10zeId,
    opCreationConfig,
    onIntegrationsFetched,
    errors: pErrors,
    onErrors,
    children,
    sourceTypeInfoByCategory,
    mode,
    supportsTestActionWithRealAlertOnly,
    readOnly = false,
    skipName = false
  } = props;

  const { alertActionId, alertActionConfig } = bizActionConfig;

  const [lookupValuesFetching, setLookupValuesFetching] = useState(false);
  const [lookupValuesMap, setLookupValuesMap] = useState<BizActionParamValuesResponse>({
    paramDefValue: {},
    paramValues: {}
  });

  const [errorsRecord, setErrorsRecord] = useState<Record<string, string[]>>({
    __base_action_config__: pErrors
  });
  useEffect(() => {
    setErrorsRecord(prevErrors => {
      const nextErrors = {
        ...prevErrors,
        __base_action_config__: pErrors
      };

      if (!isEqual(nextErrors, prevErrors)) {
        return nextErrors;
      }

      return prevErrors;
    });
  }, [pErrors]);

  const onParamsError = useCallback((errors: string[], sectionKey: string) => {
    setErrorsRecord(prev => {
      const next = {
        ...prev,
        [sectionKey]: errors
      };

      if (!errors.length) {
        delete next[sectionKey];
      }

      return next;
    });
  }, []);

  const errors = useMemo(() => Object.values(errorsRecord).reduce((acc, cur) => acc.concat(cur), []), [errorsRecord]);
  useEffect(() => {
    onErrors(errors);
  }, [errors, onErrors]);

  const { isOpen: updateInProgress, open: setUpdateInProgress, close: unSetUpdateInProgress } = useToggleState();

  const { alertActionConfigDef } = alertActionConfig;
  const { alertActionDef } = alertActionConfigDef || {};

  const {
    actionCategoryType,
    sourceTypeId,
    integrationActionConfigId,
    connectorId,
    params,
    actionRunMode,
    actionTriggerMode: alertActionTriggerMode
  } = alertActionDef || {};

  const connectionIdEmpty = isNull(connectorId) || isUndefined(connectorId);
  const initedRef = useRef(connectionIdEmpty);

  useMemo(() => {
    const connectionIdEmpty = isNull(connectorId) || isUndefined(connectorId);
    initedRef.current = connectionIdEmpty;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectorId, bizActionId]);

  const fetchLookupValues = useCallback(
    async (integrationActionConfigId: string, actionRunMode: ActionRunMode) => {
      setLookupValuesFetching(true);
      let nLookupValuesMap: BizActionParamValuesResponse = {
        paramDefValue: {},
        paramValues: {}
      };

      try {
        const { data, error, message } = await operationaliseV2ApiService.getParamSuggestions(
          actionCategoryType,
          sourceTypeId as ActionSourceTypes,
          integrationActionConfigId,
          op10zeId,
          opCreationConfig,
          actionRunMode
        );

        nLookupValuesMap = data || nLookupValuesMap;
        if (error) {
          logger.error("BizActionConfigEditor", "Error fetching params", message);
        }
      } catch (err) {
        logger.error("BizActionConfigEditor", "Error fetching params", err);
      } finally {
        setLookupValuesMap(nLookupValuesMap);
        setLookupValuesFetching(false);
      }

      return nLookupValuesMap;
    },
    [actionCategoryType, op10zeId, opCreationConfig, sourceTypeId]
  );

  useEffect(() => {
    if (!initedRef.current) {
      if (integrationActionConfigId && actionRunMode) {
        fetchLookupValues(integrationActionConfigId, actionRunMode).then(() => {
          initedRef.current = true;
        });
      } else {
        logger.warn("BizActionConfigEditor", "Error initializing values due to incomplete params", {
          integrationActionConfigId,
          actionRunMode
        });
      }
    }
  }, [actionRunMode, fetchLookupValues, integrationActionConfigId]);

  const paramValues = useMemo(() => params || {}, [params]);

  const onBizActionConfigChange = useCallback(
    (partialConfig: DeepPartial<BizActionConfig>) => {
      const configClone = { ...bizActionConfig };
      mergeWith(configClone, partialConfig, mergeCustomizer);

      const params = configClone.alertActionConfig?.alertActionConfigDef?.alertActionDef?.params || {};
      forEach(params, paramValue => {
        if (paramValue.templateId) {
          paramValue.templateCanvas = null;
          paramValue.templateSection = null;
        } else if (paramValue.templateCanvas) {
          paramValue.templateId = null;
          paramValue.templateSection = null;
        } else if (paramValue.templateSection) {
          paramValue.templateId = null;
          paramValue.templateCanvas = null;
        }
      });

      onChange(configClone, alertActionId);
    },
    [alertActionId, bizActionConfig, onChange]
  );

  const onConfigParamValueChange = useCallback(
    (paramValue: ParamValueTemplate, paramId: string) => {
      const nAlertActionDef = {
        params: {
          [paramId]: paramValue
        }
      } as AlertActionDef;

      const partActionConfig = {
        alertActionConfig: {
          alertActionConfigDef: {
            alertActionDef: nAlertActionDef
          }
        }
      };

      onBizActionConfigChange(partActionConfig);
    },
    [onBizActionConfigChange]
  );

  const onChangeDigestAction = useCallback(
    (isDigestAction: boolean) => {
      const nAlertActionDef = {
        actionTriggerMode: isDigestAction ? ActionTriggerMode.on_schedule : ActionTriggerMode.on_incident
      } as AlertActionDef;

      const partActionConfig = {
        alertActionConfig: {
          alertActionConfigDef: {
            alertActionDef: nAlertActionDef
          }
        }
      };

      onBizActionConfigChange(partActionConfig);
    },
    [onBizActionConfigChange]
  );

  const {
    data: integrationConfigsList,
    error: integrationConfigsError,
    isError: isIntegrationsConfigsFetchError,
    isFetching: isIntegrationsConfigsFetching,
    refetch
  } = useFetchUIIntegrationConfigs(actionCategoryType, sourceTypeId as ActionSourceTypes, opCreationConfig);

  // useEffect is async so takes time.
  // We need this effect immediately so using useMemo
  useMemo(() => {
    if (actionCategoryType && sourceTypeId) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionCategoryType, refetch, sourceTypeId, bizActionId]);

  useEffect(() => {
    if (!isIntegrationsConfigsFetching && onIntegrationsFetched) {
      onIntegrationsFetched(integrationConfigsList?.uiIntegrationActionConfigs || []);
    }
  }, [integrationConfigsList, isIntegrationsConfigsFetching, onIntegrationsFetched]);

  const { integrationConfigsByConnection, connectionIdToNameMap, integrationIdToConfigMap, actionRunModeLabels } =
    useMemo(() => {
      const connectionIdToNameMap: Record<string, string> = {};
      const integrationConfigsByConnection: Record<string, UIIntegrationActionConfig[]> = {};
      const integrationIdToConfigMap: Record<string, UIIntegrationActionConfig> = {};

      const { actionRunModeLabels, uiIntegrationActionConfigs = [] } = integrationConfigsList;

      uiIntegrationActionConfigs.forEach(integrationConfig => {
        const { connectionId, connectionName, id } = integrationConfig;
        connectionIdToNameMap[connectionId] = connectionName;

        const entries = integrationConfigsByConnection[connectionId] || [];
        integrationConfigsByConnection[connectionId] = [...entries, integrationConfig];
        integrationIdToConfigMap[id] = integrationConfig;
      });

      return {
        connectionIdToNameMap,
        integrationConfigsByConnection,
        integrationIdToConfigMap,
        actionRunModeLabels
      };
    }, [integrationConfigsList]);

  const connectionOptions = useMemo<IncSelectOption[]>(
    () =>
      Object.keys(connectionIdToNameMap).map(connectionId => ({
        label: connectionIdToNameMap[connectionId],
        value: connectionId
      })),
    [connectionIdToNameMap]
  );

  const selectedConnectionOpt = useMemo<IncSelectOption>(() => {
    let selOpt = connectionOptions.find(({ value }) => value === connectorId);
    selOpt = selOpt || connectionOptions[0];
    return selOpt;
  }, [connectorId, connectionOptions]);

  const integrationOptions = useMemo<IncSelectOption[]>(() => {
    let selectedConnectorId = connectorId;
    //check connection id if empty else set the first object
    if (selectedConnectorId === "" && Object.keys(integrationConfigsByConnection).length > 0) {
      selectedConnectorId = Object.keys(integrationConfigsByConnection)[0];
    }
    const integrationConfigs = integrationConfigsByConnection[selectedConnectorId] || [];

    const opts = integrationConfigs.map(uiConfig => {
      const { name, id, isDefault } = uiConfig;
      const iconName = name === "Basic" ? "wrench" : "gear";
      return {
        label: name,
        value: id,
        icon: iconName,
        isSelected: isDefault
      };
    });
    return opts;
  }, [connectorId, integrationConfigsByConnection]);

  const orderedIntegrationOptions = orderBy(integrationOptions, ["isSelected"], "desc");
  const updateIntegrationId = useCallback(
    async (integrationActionConfigId: string, connectorId?: string, actionRunMode?: ActionRunMode) => {
      setUpdateInProgress();

      const nAlertActionDef = {
        staticContext: {},
        params: {},
        integrationActionConfigId
      } as AlertActionDef;

      if (integrationIdToConfigMap?.[integrationActionConfigId]) {
        const integrationConfig = integrationIdToConfigMap[integrationActionConfigId];

        const uiParams = integrationConfig?.uiParams || {};

        const { paramDefValue: paramDefaultValues, paramValues: paramLookupValues } = await fetchLookupValues(
          integrationActionConfigId,
          actionRunMode
        );

        const nParamValues: Record<string, ParamValueTemplate> = {};
        for (const key in uiParams) {
          const lookupValues = paramLookupValues?.[key]?.value || [];

          const { type, subtype } = uiParams[key];

          const defValue = paramDefaultValues[key];

          const vKey = getPropertyKeyForDataType(type);
          const oValueObj = paramValues[key];

          const isTemplate = checkIfTemplate(subtype);
          const isTemplateSection = checkIfTemplateSection(subtype);

          if (isTemplate || isTemplateSection) {
            const shouldResetValue = isTemplateSection
              ? Boolean(oValueObj?.templateSection)
              : Boolean(oValueObj?.templateCanvas);
            if (!shouldResetValue) {
              nParamValues[key] = oValueObj || defValue;
            } else {
              if (defValue?.templateCanvas || defValue?.templateSection) {
                nParamValues[key] = defValue;
              }
            }
          } else {
            const oValue = oValueObj?.value?.[vKey];
            const mValue = lookupValues?.find(v => v.name === oValue)?.name;

            nParamValues[key] = mValue ? oValueObj : defValue;
          }
        }
        nAlertActionDef.params = nParamValues;
      }

      /**
       * "" is valid since some configs will not have any connectors
       * Ex: Email, ReverseETL
       */
      const connectionIdEmpty = isNull(connectorId) || isUndefined(connectorId);
      if (!connectionIdEmpty) {
        nAlertActionDef.connectorId = connectorId;
      }

      const actionRunModeEmpty = isNull(connectorId) || isUndefined(connectorId);
      if (!actionRunModeEmpty) {
        nAlertActionDef.actionRunMode = actionRunMode;
      }

      const partActionConfig = {
        alertActionConfig: {
          alertActionConfigDef: {
            alertActionDef: nAlertActionDef
          }
        }
      };

      onBizActionConfigChange(partActionConfig);

      unSetUpdateInProgress();
    },
    [
      fetchLookupValues,
      integrationIdToConfigMap,
      onBizActionConfigChange,
      paramValues,
      setUpdateInProgress,
      unSetUpdateInProgress
    ]
  );

  const onUIIntegrationConfigChanged = useCallback(
    (opt: IncSelectOption) => {
      const { value: nIntegrationActionConfigId } = opt;
      updateIntegrationId(nIntegrationActionConfigId, null, actionRunMode);
    },
    [actionRunMode, updateIntegrationId]
  );

  const onConnectionIdChange = useCallback(
    (opt: IncSelectOption) => {
      const connectionId = opt.value;
      const integrations = integrationConfigsByConnection[connectionId];
      //checking if the isDefault is set to true
      const is = integrations.find(({ isDefault }) => isDefault === true);
      const nIntegrationId = is?.id;
      updateIntegrationId(nIntegrationId, connectionId, actionRunMode);
    },
    [actionRunMode, integrationConfigsByConnection, updateIntegrationId]
  );

  const onNameChange = useCallback(
    (inputValue: string) => {
      const partActionConfig = {
        alertActionConfig: {
          name: inputValue
        }
      };
      onBizActionConfigChange(partActionConfig);
    },
    [onBizActionConfigChange]
  );

  const onBulkModeToggleChange = useCallback(
    (isIndividualMode: boolean) => {
      const actionRunMode = isIndividualMode ? ActionRunMode.loop : ActionRunMode.bulk;
      updateIntegrationId(integrationActionConfigId, connectorId, actionRunMode);
    },
    [connectorId, integrationActionConfigId, updateIntegrationId]
  );

  useEffect(() => {
    if (!isIntegrationsConfigsFetching && connectionOptions.length && connectionIdEmpty) {
      const opt = connectionOptions[0];
      onConnectionIdChange(opt);
    }
  }, [
    connectorId,
    connectionOptions,
    isIntegrationsConfigsFetching,
    onConnectionIdChange,
    connectionIdEmpty,
    alertActionId
  ]);

  const selectedIntegrationConfigOpt = useMemo(() => {
    let selOpt = integrationOptions.find(({ value }) => value === integrationActionConfigId);
    selOpt = selOpt || integrationOptions[0];
    return selOpt;
  }, [integrationActionConfigId, integrationOptions]);

  const selectedIntegrationConfig = useMemo(
    () => integrationIdToConfigMap?.[integrationActionConfigId],
    [integrationActionConfigId, integrationIdToConfigMap]
  );

  const sourceTypeCategoryInfo = sourceTypeInfoByCategory[actionCategoryType]?.[sourceTypeId];

  const supportedActionTriggerModes = sourceTypeCategoryInfo?.supportedActionTriggerModes;
  const defaultActionTriggerMode = sourceTypeCategoryInfo?.defaultActionTriggerMode;
  const actionTriggerMode = alertActionTriggerMode || defaultActionTriggerMode;

  const displayConnectionsDD = !sourceTypeCategoryInfo?.noConnector;
  const displayIntegrationsDD = integrationOptions?.length > 1;

  const shouldShowRunModeToggle = sourceTypeCategoryInfo?.supportedActionRunModes?.length > 1;

  const isIndividualMode = actionRunMode === ActionRunMode.loop;
  const runModeToggleLabel = actionRunModeLabels?.loop || "Loop alert";

  return (
    <div className="biz-action-config-editor">
      <VerticallyCenteredRow className="marginBt12">
        {!skipName && (
          <div className="inc-flex-grow display-block name-input">
            <EditableLabelInput
              autoAdjustWidth
              change={onNameChange}
              editingDisabled={false}
              initialValue={alertActionConfig?.name || ""}
              showEditIcon
            />
          </div>
        )}
        {!readOnly && (
          <>
            <TestActionRenderer
              bizActionConfig={bizActionConfig}
              errors={errors}
              mode={mode}
              op10zeId={op10zeId}
              opCreationConfig={opCreationConfig}
              supportsTestActionWithRealAlertOnly={supportsTestActionWithRealAlertOnly}
            />
            {children}
          </>
        )}
      </VerticallyCenteredRow>
      <div className="editor-content">
        {isIntegrationsConfigsFetching && <LoadingSpinner titleText="Fetching integrations..." />}
        {!isIntegrationsConfigsFetching && (
          <>
            {!isIntegrationsConfigsFetchError && (
              <>
                {(displayConnectionsDD || shouldShowRunModeToggle) && (
                  <VerticallyCenteredRow className="marginBt16 integration-config-selector">
                    {displayConnectionsDD && (
                      <IncSelect
                        isDisabled={updateInProgress}
                        isLoading={isIntegrationsConfigsFetching}
                        label="Connection"
                        noOptionsMessage={connectionsNoOpMessage}
                        onChange={onConnectionIdChange}
                        options={connectionOptions}
                        value={selectedConnectionOpt}
                        wrapperClass="marginRt16 width-25"
                      />
                    )}

                    {shouldShowRunModeToggle && (
                      <IncToggle
                        checked={isIndividualMode}
                        className={displayConnectionsDD ? "marginTp18" : ""}
                        label={runModeToggleLabel}
                        onChange={onBulkModeToggleChange}
                      />
                    )}
                  </VerticallyCenteredRow>
                )}
                {supportedActionTriggerModes?.length > 1 && (
                  <IncToggle
                    checked={actionTriggerMode !== ActionTriggerMode.on_incident}
                    className={"marginBt18"}
                    label={"Digest Action"}
                    onChange={onChangeDigestAction}
                  />
                )}
                {displayIntegrationsDD && (
                  <VerticallyCenteredRow className="marginBt16 integration-config-selector">
                    <IncSwitch
                      label="Configuration"
                      onChange={onUIIntegrationConfigChanged}
                      options={orderedIntegrationOptions}
                      value={selectedIntegrationConfigOpt}
                    />
                  </VerticallyCenteredRow>
                )}

                {!selectedIntegrationConfig && (lookupValuesFetching || updateInProgress) && <LoadingSpinner />}
                {Boolean(selectedIntegrationConfig) && (
                  <>
                    {!lookupValuesFetching && !updateInProgress && (
                      <IntegrationConfigParamsEditor
                        actionCategoryType={actionCategoryType}
                        actionRunMode={actionRunMode}
                        bizActionConfig={bizActionConfig}
                        integrationConfig={selectedIntegrationConfig}
                        lookupValuesMap={lookupValuesMap}
                        onErrors={onParamsError}
                        onParamValueChange={onConfigParamValueChange}
                        op10zeId={op10zeId}
                        opCreationConfig={opCreationConfig}
                        paramValues={paramValues}
                        sourceTypeId={sourceTypeId as ActionSourceTypes}
                      />
                    )}
                    {(lookupValuesFetching || updateInProgress) && <LoadingSpinner />}
                  </>
                )}
              </>
            )}
            {isIntegrationsConfigsFetchError && <div className="status-danger">{integrationConfigsError}</div>}
          </>
        )}
      </div>
    </div>
  );
};

const connectionsNoOpMessage = () => "No connections found for this category and source";

function mergeCustomizer(objValue: any, srcValue: any) {
  if (isArray(objValue)) {
    return srcValue;
  }

  if (isObject(objValue)) {
    const keys = Object.keys(objValue);
    if (keys.includes("templateCanvas") && keys.includes("templateId") && keys.includes("value")) {
      return srcValue;
    }
  }
}
