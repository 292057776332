import React, { Component, createRef, RefObject } from "react";
import { cx } from "emotion";
import { IncClickAwayPopper, IncFaIcon, IncModal, IncModalProps, IncToolTip } from "@inception/ui";
import { SelectionState } from "draft-js";
import {
  ENTITY_RANGE_DATA_DISPLAY_TEXT_KEY,
  ENTITY_RANGE_DATA_TEXT_ELEMENT_KEY,
  getDisplayTextForTextElement,
  TemplateEntityData,
  updateEntityDataInEditorState,
  WEB_LINK_DISPLAY_TEXT_PROP_NAME,
  WEB_LINK_ELEMENT_ID,
  WEB_LINK_URL_PROP_NAME
} from "../../utils";
import { VerticallyCenteredRow } from "../../../../../../components";
import { CustomDecoratorProps } from "../types";
import { ActionTemplateTextElement, ElementPropertyValues } from "../../../../../../services/api/operationalise";
import { LinkPropertiesRenderer } from "./LinkPropertiesRenderer";
import { TextPropertiesRenderer } from "./TextPropertiesRenderer";

type State = {
  isPropertiesOpen: boolean;
  isSettingsOpen: boolean;
  textEl: ActionTemplateTextElement;
  isLink: boolean;
  displayText: string;
  properties: ElementPropertyValues;
};

export class TextElementRenderer extends Component<CustomDecoratorProps, State> {
  private ref: RefObject<HTMLDivElement> = createRef();

  constructor(props: CustomDecoratorProps) {
    super(props);

    const { textEl, displayText } = getPartStateFromProps(props);

    this.state = {
      isPropertiesOpen: false,
      isSettingsOpen: false,
      properties: getPropertiesFromProps(props),
      textEl,
      displayText,
      isLink: textEl.elementId === WEB_LINK_ELEMENT_ID
    };
  }

  setReadOnly = () => {
    const { onChangeReadOnly } = this.props;
    onChangeReadOnly(true);
  };

  unSetReadOnly = () => {
    const { onChangeReadOnly } = this.props;
    onChangeReadOnly(false);
  };

  openSettings = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({
      isSettingsOpen: true
    });
  };

  closeSettings = () => {
    this.setState({
      isSettingsOpen: false
    });
  };

  openProperties = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({
      isPropertiesOpen: true,
      isSettingsOpen: false
    });

    this.setReadOnly();
  };

  closeProperties = () => {
    this.setState({
      isPropertiesOpen: false,
      isSettingsOpen: false,
      properties: getPropertiesFromProps(this.props)
    });

    this.unSetReadOnly();
  };

  // unLink = () => {
  //   const {
  //     entityKey,
  //     unLink,
  //     blockKey
  //   } = this.props;

  //   unLink(blockKey, entityKey);

  //   this.setState({
  //     isPropertiesOpen: false,
  //     isSettingsOpen: false
  //   });
  // };

  openLink = () => {
    const properties = getPropertiesFromProps(this.props);

    const url = properties?.propValue?.[WEB_LINK_URL_PROP_NAME]?.stringVal || "";
    if (url) {
      window.open(url, "_blank");
    }
  };

  updateProperties = (properties: ElementPropertyValues) => {
    this.setState({
      properties
    });
  };

  onApplyChanges = () => {
    this.unSetReadOnly();

    const { onEditorStateChange, entityKey, blockKey, start, end } = this.props;

    const { properties, textEl } = this.state;

    const entityText = getDisplayTextForTextElement(textEl, properties);

    let selectionState = SelectionState.createEmpty(blockKey);
    selectionState = selectionState.merge({
      anchorOffset: start,
      focusOffset: end
    });

    onEditorStateChange(prevEditorState =>
      updateEntityDataInEditorState(
        prevEditorState,
        entityKey,
        " ",
        {
          properties,
          displayText: entityText
        },
        selectionState
      )
    );

    this.setState({
      isPropertiesOpen: false,
      isSettingsOpen: false,
      displayText: entityText,
      properties
    });
  };

  render() {
    const { children } = this.props;

    const { isPropertiesOpen, isSettingsOpen, properties, textEl, isLink, displayText } = this.state;

    if (!textEl) {
      return children;
    }

    const url = properties?.propValue?.[WEB_LINK_URL_PROP_NAME]?.stringVal || "";
    const customText = properties?.propValue?.[WEB_LINK_DISPLAY_TEXT_PROP_NAME]?.stringVal || displayText;

    const className = cx("display-inline-flex", {
      "inc-pill": !isLink,
      "inc-link": isLink
    });

    const onClick = isLink || textEl?.prop?.length ? this.openSettings : null;

    const actions: IncModalProps["actions"] = {
      primary: {
        id: "common.actions.apply",
        color: "secondary-blue",
        onClick: this.onApplyChanges
      },
      secondary: {
        id: "common.actions.cancel",
        color: "secondary-red",
        onClick: this.closeProperties
      }
    };

    return (
      <div className="template-editor-v2--text-element">
        <div
          className={className}
          contentEditable="false"
          onClick={onClick}
          ref={this.ref}
          style={{ cursor: onClick ? "pointer" : "default" }}
          suppressContentEditableWarning
        >
          {customText}
        </div>

        <div
          className="editable-content"
          contentEditable="true"
          suppressContentEditableWarning
        >
          {children}
        </div>

        <IncClickAwayPopper
          anchorEl={this.ref.current}
          className="section-settings"
          onClickAway={this.closeSettings}
          placement="bottom-start"
          show={isSettingsOpen}
        >
          {isLink && (
            <VerticallyCenteredRow>
              <IncToolTip
                placement="top"
                titleText="Edit link"
              >
                <div
                  className="section-settings--option"
                  onClick={this.openProperties}
                >
                  <IncFaIcon
                    className="marginRt6"
                    iconName="edit"
                  />
                  Edit link
                </div>
              </IncToolTip>

              {Boolean(url) && (
                <>
                  <div className="section-settings--separator" />

                  <IncToolTip
                    placement="top"
                    titleText="Open link"
                  >
                    <div
                      className="section-settings--option"
                      onClick={this.openLink}
                    >
                      <IncFaIcon
                        className="marginRt6"
                        iconName="up-right-from-square"
                      />
                    </div>
                  </IncToolTip>
                </>
              )}

              {/* <IncToolTip placement="top" titleText="Unlink">
            <div className="section-settings--option" onClick={this.unLink} title="Unlink">
              <IncFaIcon className="status-danger" iconName="unlink" />
            </div>
          </IncToolTip> */}
            </VerticallyCenteredRow>
          )}

          {!isLink && (
            <VerticallyCenteredRow>
              <IncToolTip
                placement="top"
                titleText="Edit"
              >
                <div
                  className="section-settings--option"
                  onClick={this.openProperties}
                >
                  <IncFaIcon
                    className="marginRt6"
                    iconName="edit"
                  />
                  Edit
                </div>
              </IncToolTip>
            </VerticallyCenteredRow>
          )}
        </IncClickAwayPopper>

        <IncModal
          actions={actions}
          className="section-properties"
          disableFocusOnLoad
          onClose={this.closeProperties}
          show={isPropertiesOpen}
          size="md"
          titleText="Edit"
        >
          {isLink && (
            <LinkPropertiesRenderer
              onChange={this.updateProperties}
              properties={properties}
            />
          )}
          {!isLink && (
            <TextPropertiesRenderer
              onChange={this.updateProperties}
              properties={properties}
              textElementProperties={textEl.prop}
            />
          )}
        </IncModal>
      </div>
    );
  }
}

const getPropertiesFromProps = (props: CustomDecoratorProps): ElementPropertyValues => {
  const { entityKey, contentState } = props;

  const entity = contentState.getEntity(entityKey);
  const entityData = entity.getData() as TemplateEntityData;
  const { properties } = entityData;

  return properties;
};

const getPartStateFromProps = (props: CustomDecoratorProps) => {
  const { entityKey, contentState } = props;

  const entity = contentState.getEntity(entityKey);
  const entityData = entity.getData() as TemplateEntityData;

  const textEl = entityData[ENTITY_RANGE_DATA_TEXT_ELEMENT_KEY];
  const displayText = entityData[ENTITY_RANGE_DATA_DISPLAY_TEXT_KEY];

  return {
    textEl,
    displayText
  };
};
