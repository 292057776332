import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IncButton, IncFaIcon, IncMenuItem, IncMenuV2, IncModal, IncModalProps, IncSmartText } from "@inception/ui";
import { cloneDeep, isEmpty } from "lodash";
import { OpFragmentEditProps } from "../types";
import { VerticallyCenteredRow } from "../../components";
import { RawTimeRange, dateTime, generateId, logger, useTimeRange, useToggleState } from "../../core";
import { OpCreationFragmentEditType, SeasonalityOverrideDef } from "../../services/api/operationalise";
import { BizImpactConfigEditorModal, SeasonalityOverrideEditor } from "../../operationalise-v2/components";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { BaselineChangeType, BizImpactConfig, ImpactDefImpactType } from "../../services/api/types";
import { calendarEventsApiService } from "../../services/api";

type Props = OpFragmentEditProps;

export const SeasonalityOverridesFragmentEditor: FC<Props> = props => {
  const { opFragment, invalidFragmentJsx } = props;

  if (!isEmpty(opFragment?.seasonalityOverrides)) {
    return <SeasonalityOverridesFragmentEditorInternal {...props} />;
  }

  return invalidFragmentJsx;
};

const SeasonalityOverridesFragmentEditorInternal: FC<Props> = props => {
  const { className, opFragment, onChange, onClose, showEditorInModal = false } = props;

  const { isOpen: isUpdateInProgress, open: startUpdate, close: finishUpdate } = useToggleState();

  const [calendarEvents, setCalendarEvents] = useState<CalendarEventOpt[]>([]);

  const { timeRange } = useTimeRange();
  const { from: fromMillis, to: toMillis } = useMemo(
    () => timeRangeUtils.getTimeRangeMillisFromRaw(timeRange.raw),
    [timeRange]
  );

  const {
    isOpen: isCalendarEventsLoading,
    open: startCalendarEventsFetch,
    close: finishCalendarEventsFetch
  } = useToggleState(true);

  useEffect(() => {
    (async () => {
      startCalendarEventsFetch();
      try {
        const { data, error, message } = await calendarEventsApiService.getCalenderEvents(fromMillis, toMillis);

        if (error || !data) {
          logger.error("SeasonalityOverridesFragmentEditor", "Error fetching calendar events", {
            error,
            message,
            data
          });
        } else {
          const calendarEvents = (data.events || []).map(
            (event): CalendarEventOpt => ({
              label: event.name,
              value: event.name,
              data: {
                from: String(event.startTimeMillis),
                to: String(event.endTimeMillis)
              }
            })
          );

          if (calendarEvents.length) {
            calendarEvents.push({
              label: "Custom override",
              value: "custom"
            });
          }

          setCalendarEvents(calendarEvents);
        }
      } catch (err) {
        logger.error("SeasonalityOverridesFragmentEditor", "Error fetching calendar events", {
          err
        });
      }

      finishCalendarEventsFetch();
    })();
  }, [finishCalendarEventsFetch, fromMillis, startCalendarEventsFetch, toMillis]);

  const errorMessageRef = useRef("");
  const [errors, setErrors] = useState<string[]>([]);

  const defOverrides = opFragment.seasonalityOverrides?.seasonalityOverrides;
  const [overrides, setOverrides] = useState(cloneDeep(defOverrides || []));

  const resetOverrides = useCallback(() => {
    setOverrides(cloneDeep(defOverrides || []));
  }, [defOverrides]);

  useEffect(() => {
    resetOverrides();
  }, [resetOverrides]);

  const overrideEditors = useMemo(
    () =>
      overrides.map((override, idx) => {
        const onChange = (nOverride: SeasonalityOverrideDef, error: string) => {
          setOverrides(prev => {
            const next = [...prev];
            next[idx] = nOverride;
            return next;
          });

          setErrors(prev => {
            const next = [...prev];
            next[idx] = error;
            return next;
          });
        };

        const onRemoveOverride = () => {
          setOverrides(prev => {
            const next = [...prev];
            next.splice(idx, 1);
            return next;
          });
        };

        return (
          <VerticallyCenteredRow
            className="flex-gap-12"
            key={override.id}
          >
            <SeasonalityOverrideEditor
              onChange={onChange}
              seasonalityOverride={override}
            />

            <IncFaIcon
              className="paddingLt4 paddingRt4 inc-cursor-pointer status-danger inc-text-subtext-medium"
              iconName="trash-alt"
              onClick={onRemoveOverride}
            />
          </VerticallyCenteredRow>
        );
      }),
    [overrides]
  );

  const onCancelChanges = useCallback(() => {
    resetOverrides();
    onClose?.();
  }, [onClose, resetOverrides]);

  const isValid = errors.filter(Boolean).length === 0;
  const onSaveChanges = useCallback(async () => {
    if (isValid) {
      errorMessageRef.current = "";
      startUpdate();

      const { isError, message } = await onChange(
        {
          ...opFragment,
          seasonalityOverrides: {
            seasonalityOverrides: overrides
          }
        },
        OpCreationFragmentEditType.SEASONALITY_OVERRIDES
      );

      if (isError) {
        errorMessageRef.current = message;
      }

      finishUpdate();
    }
  }, [finishUpdate, isValid, onChange, opFragment, overrides, startUpdate]);

  const [bizImpactConfig, setBizImpactConfig] = useState<BizImpactConfig>();

  const onTriggerAddOverride = useCallback(() => {
    setBizImpactConfig(getDefaultImpactConfig());
  }, []);

  const onTriggerAddCalendarEvent = useCallback((calendarEventOpt: CalendarEventOpt) => {
    setBizImpactConfig(getDefaultImpactConfig(calendarEventOpt.data));
  }, []);

  const onAddOverride = useCallback((bizImpactConfig: BizImpactConfig) => {
    setOverrides(prev => [
      ...prev,
      {
        description: bizImpactConfig.name,
        id: generateId(),
        impactConfig: bizImpactConfig
      }
    ]);
    setBizImpactConfig(null);
  }, []);

  const jsx = useMemo(
    () => (
      <div className={className}>
        <div className="inc-flex-column flex-gap-12 width-100">
          {overrideEditors}

          {isCalendarEventsLoading && (
            <VerticallyCenteredRow className="disableClick width-fit-content flex-gap-8 inc-text-subtext-medium status-info">
              <IncFaIcon
                iconName="spinner"
                spin
                style={{ height: 12 }}
              />
              Add
            </VerticallyCenteredRow>
          )}

          {!isCalendarEventsLoading && (
            <>
              {calendarEvents.length > 0 && (
                <IncMenuV2
                  label={
                    <VerticallyCenteredRow className="width-fit-content flex-gap-8 inc-text-subtext-medium status-info inc-cursor-pointer">
                      <IncFaIcon
                        iconName="circle-plus"
                        style={{ height: 12 }}
                      />
                      Add
                    </VerticallyCenteredRow>
                  }
                  menuItems={calendarEvents}
                  onMenuItemClick={onTriggerAddCalendarEvent}
                />
              )}
              {calendarEvents.length === 0 && (
                <IncButton
                  color="link"
                  iconName="plus"
                  iconType="iconText"
                  label="Add Condition"
                  onClick={onTriggerAddOverride}
                  size="small"
                ></IncButton>
              )}
            </>
          )}

          {Boolean(bizImpactConfig) && (
            <BizImpactConfigEditorModal
              bizImpactConfig={bizImpactConfig}
              onCancel={() => setBizImpactConfig(null)}
              onChange={onAddOverride}
            />
          )}
        </div>

        {!showEditorInModal && (
          <VerticallyCenteredRow className="flex-gap-12 marginTp10">
            {!isUpdateInProgress && (
              <IncButton
                color="primary"
                disabled={!isValid}
                label="Save Changes"
                onClick={onSaveChanges}
                size="small"
              />
            )}

            {isUpdateInProgress && (
              <IncButton
                color="primary"
                loading
                loadingText="Saving..."
                size="small"
              />
            )}

            {Boolean(errorMessageRef.current) && (
              <VerticallyCenteredRow className="status-danger flex-gap-10">
                <IncFaIcon
                  className="status-danger"
                  iconName="exclamation-triangle"
                />
                <IncSmartText
                  className="status-danger"
                  text={errorMessageRef.current}
                />
              </VerticallyCenteredRow>
            )}

            {!isUpdateInProgress && (
              <IncButton
                color="secondary-blue"
                label="Cancel"
                onClick={onCancelChanges}
                size="small"
              />
            )}
          </VerticallyCenteredRow>
        )}
      </div>
    ),
    [
      bizImpactConfig,
      calendarEvents,
      className,
      isCalendarEventsLoading,
      isUpdateInProgress,
      isValid,
      onAddOverride,
      onCancelChanges,
      onSaveChanges,
      onTriggerAddCalendarEvent,
      onTriggerAddOverride,
      overrideEditors,
      showEditorInModal
    ]
  );

  const { close: closeModal, isOpen: IsModalOpen } = useToggleState(true);

  const actions: IncModalProps["actions"] = useMemo(
    () => ({
      primary: {
        label: "Save Changes",
        onClick: () => {
          closeModal();
          onSaveChanges();
        },
        color: "primary",
        showLoader: isUpdateInProgress
      },
      secondary: {
        label: "Cancel",
        onClick: () => {
          closeModal();
          onCancelChanges();
        },
        color: "secondary"
      }
    }),
    [closeModal, isUpdateInProgress, onCancelChanges, onSaveChanges]
  );

  return (
    <>
      {!showEditorInModal && jsx}
      {showEditorInModal && (
        <IncModal
          actions={actions}
          contentClassName="op-fragment-editors-modal-content padding16"
          onClose={closeModal}
          show={IsModalOpen}
          size="xxlg"
          titleText="Overrides for Special Days"
          withActionsBorder
          withTitleBorder
        >
          {jsx}
        </IncModal>
      )}
    </>
  );
};

const getDefaultImpactConfig = (rawTimeRange?: RawTimeRange): BizImpactConfig => ({
  description: "Add custom behaviour for data during this period",
  name: "Custom data behaviour",
  endTimeInSec: timeRangeUtils.getSecondsFromMillis(
    rawTimeRange ? parseInt(rawTimeRange.from, 10) : dateTime().startOf("day").valueOf()
  ),
  startTimeInSec: timeRangeUtils.getSecondsFromMillis(
    rawTimeRange ? parseInt(rawTimeRange.to, 10) : dateTime().subtract(1, "day").startOf("day").valueOf()
  ),
  id: generateId(),
  impact: {
    baseLineChangeConfig: {
      lowerBoundChange: 30,
      upperBoundChange: 30,
      type: BaselineChangeType.PERCENTAGE
    },
    type: [ImpactDefImpactType.BASELINE_CHANGE]
  }
});

type CalendarEventOpt = IncMenuItem<RawTimeRange>;
