import React, { FC, HTMLAttributes, memo } from "react";
import { convertMarkdownToHtml } from "../../utils";

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, "dangerouslySetInnerHTML"> {
  mdContent: string;
}

export const MarkdownRenderer: FC<Props> = memo(props => {
  const { mdContent, className: pClassName = "", ...restProps } = props;

  const className = `inc-markdown-content ${pClassName}`;
  const htmlContent = convertMarkdownToHtml(mdContent || "");

  return (
    <div
      {...restProps}
      className={className}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
});
