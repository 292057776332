import { useMemo, useCallback } from "react";
import { forEach, isEqual, trim, isEmpty, values } from "lodash";
import { logger, DataFrame, useSchemaStore } from "../../core";
import {
  WidgetConfigDTO,
  WidgetCompareDataRequest,
  SliceSpec,
  MetricDefinition,
  MetricResultDataDTO,
  WidgetConfigUtils,
  CommonMetricProperties,
  getOpdCompareConfig,
  CohortEntityFilter,
  MetricUserServiceFilters,
  DemoDataParams
} from "../../services/api/explore";
import { DataQueryResponse } from "../../services/api/types";
import { ExploreQueryType } from "../../services/datasources/explore/types";
import { WidgetExtProps } from "../../dashboard/widgets/types";
import { BizEntityDataResultEntry } from "../../dashboard/widgets/utils";
import { ENTITY_TAG, getMetricNameFromTags, getCompareMetricNameFromTags } from "../../utils/MetricNameUtils";
import { shouldExcludeTag } from "../../core/utils";
import { templateSrv } from "../../dashboard/variables";
import { useBizEntityDataQuery, BizEntityDataFetchResult } from "../../dashboard/widgets/hooks";
import featureFlagService from "../../services/feature-flags/service/FeatureFlagService";
import { getDefaultLegendFormat } from "../../utils";
import { isSystemCreatedMetric } from "../../utils/ExploreUtils";
import { getBizTagFromMetricDef, getDataTypeAndSubType } from "./utils";
import { EntityWidgetData } from "./types";

// note: memoise fields which get updated on render
export interface EntityDataQueryProps {
  id: string;
  entityType: string;
  entityId: string;
  widgetId: string;
  widgetConfig: WidgetConfigDTO;
  mode: ExploreQueryType;
  timeRange: WidgetExtProps["timeRange"];
  version: number;
  compareSelection: any;
  compareQuery: boolean;
  sliceSpec: SliceSpec[];
  excludeWidgetData: boolean;

  entityFilters?: CohortEntityFilter[];
  cohortFilters?: CohortEntityFilter[];
  eventFilters?: MetricUserServiceFilters;

  limit?: number;
  useV2ApiForCompare?: boolean;
  skipEnabledDataDefCheck?: boolean;
  includeQueryConfig?: boolean;
  dsIntervalStr?: string;
  demoParams?: DemoDataParams;
}

export const useEntityDataQuery = (props: EntityDataQueryProps) => {
  const {
    id: panelId,
    entityId = null,
    entityType = null,
    widgetId,
    widgetConfig,
    mode,
    timeRange,
    version,
    compareSelection,
    compareQuery,
    sliceSpec,
    limit,
    entityFilters: pEntityFilters,
    excludeWidgetData,
    useV2ApiForCompare,
    eventFilters: pEventFilters,
    cohortFilters: pCohortFilters,
    skipEnabledDataDefCheck = false,
    includeQueryConfig = featureFlagService.isDebugMode(),
    dsIntervalStr,
    demoParams
  } = props;

  const { entityTypes } = useSchemaStore();
  const entityTypeMap = useMemo(() => {
    const entityTypeMap: Record<string, string> = {};
    forEach(entityTypes, typeInfo => {
      const { typeReference } = typeInfo;
      const { id, typeName } = typeReference;
      entityTypeMap[id] = typeName;
    });
    return entityTypeMap;
  }, [entityTypes]);

  const compareInfo = useMemo(() => {
    if (compareQuery && !compareSelection) {
      logger.error("Entity data widget", "Compare enabled but got invalid compare selection", compareSelection);
    }
    return compareQuery ? getCompareInfo(compareSelection) : null;
  }, [compareQuery, compareSelection]);

  const dataTransformer = useCallback(
    async (fetchedData: BizEntityDataFetchResult) => {
      const data = await entityDataTransformer(widgetConfig, fetchedData, entityTypeMap, skipEnabledDataDefCheck);
      return data;
    },
    [entityTypeMap, skipEnabledDataDefCheck, widgetConfig]
  );

  const cohortFilters = useMemo(() => pCohortFilters || [], [pCohortFilters]);
  const eventFilters = useMemo(() => pEventFilters || {}, [pEventFilters]);
  const entityFilters = useMemo(() => pEntityFilters || [], [pEntityFilters]);

  return useBizEntityDataQuery({
    cohortFilters,
    compareInfo,
    entityFilters,
    entityId,
    entityType,
    id: panelId,
    mode,
    sliceSpec,
    timeRange,
    version,
    widgetConfig,
    widgetId,
    excludeWidgetData,
    limit,
    transformer: dataTransformer,
    useRawTimeRange: true,
    includeQueryConfig,
    useV2ApiForCompare,
    eventFilters,
    dsIntervalStr,
    demoParams
  });
};

export const entityDataTransformer = (
  widgetConfig: WidgetConfigDTO,
  exploreWidgetResponses: BizEntityDataFetchResult,
  entityTypeMap: Record<string, string>,
  skipEnabledDataDefCheck: boolean
): Promise<Array<DataQueryResponse<EntityWidgetData[]>>> => {
  const metricDefs = widgetConfig?.dataDefinition?.metrics || {};
  const bizEntityType = widgetConfig?.bizEntityType;
  const commonMetricProperties = widgetConfig?.commonMetricProperties;

  const enabledDataDefIds = (((widgetConfig?.visualizations || [])[0] || {}).dataDefs || [])
    .filter(dd => dd.enabled)
    .map(dd => dd.id);

  const transformed = exploreWidgetResponses.map((partialTransform): DataQueryResponse<EntityWidgetData[]> => {
    const { data: exploreWidgetData, error } = partialTransform;

    const transformedData: EntityWidgetData[] = [];

    exploreWidgetData.forEach(exploreWidgetDatum => {
      const {
        data,
        compareConfigData: compareData,
        compareDataSize,
        dataDefinitionId,
        dataQueryConfig,
        dataSize,
        postAggData,
        postAggEntityLookupData,
        postAggPerChangeData: PAPCData,
        postAggTimeshiftData: PATSData,
        warnings,
        postAggDataSize,
        fieldResult,
        forecastAggData = {},
        forecastAggDeltaData = {},
        forecastEntityLookupData = {},
        forecastLowerBoundData = {},
        forecastUpperBoundData = {},
        forecastAggPerChangeData = {}
      } = exploreWidgetDatum;

      if (fieldResult) {
        transformedData.push({
          fieldResult: fieldResult,
          dataDefinitionId: "",
          data: undefined,
          compareConfigData: undefined,
          postAggResult: undefined,
          forecastResult: undefined,
          dataSize: 0,
          postAggDataSize: 0,
          compareDataSize: 0,
          dataQueryConfig: undefined,
          warnings: []
        });
        return;
      }

      const isDataDefEnabled = skipEnabledDataDefCheck ? true : enabledDataDefIds.includes(dataDefinitionId);

      if (isDataDefEnabled) {
        const pData = getPostProcessedData(
          data,
          metricDefs,
          entityTypeMap,
          dataDefinitionId,
          bizEntityType,
          commonMetricProperties
        );
        const pCompareData = getPostProcessedData(
          compareData,
          metricDefs,
          entityTypeMap,
          dataDefinitionId,
          bizEntityType,
          commonMetricProperties
        );
        const pPAData = getPostProcessedData(
          postAggData,
          metricDefs,
          entityTypeMap,
          dataDefinitionId,
          bizEntityType,
          commonMetricProperties
        );
        const pPAPerChangeData = getPostProcessedData(
          PAPCData,
          metricDefs,
          entityTypeMap,
          dataDefinitionId,
          bizEntityType,
          commonMetricProperties
        );
        const pPATimeshiftData = getPostProcessedData(
          PATSData,
          metricDefs,
          entityTypeMap,
          dataDefinitionId,
          bizEntityType,
          commonMetricProperties
        );

        const fData = getPostProcessedData(
          forecastAggData,
          metricDefs,
          entityTypeMap,
          dataDefinitionId,
          bizEntityType,
          commonMetricProperties
        );
        const fDeltaData = getPostProcessedData(
          forecastAggDeltaData,
          metricDefs,
          entityTypeMap,
          dataDefinitionId,
          bizEntityType,
          commonMetricProperties
        );
        const fPerChangeData = getPostProcessedData(
          forecastAggPerChangeData,
          metricDefs,
          entityTypeMap,
          dataDefinitionId,
          bizEntityType,
          commonMetricProperties
        );
        const fLBData = getPostProcessedData(
          forecastLowerBoundData,
          metricDefs,
          entityTypeMap,
          dataDefinitionId,
          bizEntityType,
          commonMetricProperties
        );
        const fUBData = getPostProcessedData(
          forecastUpperBoundData,
          metricDefs,
          entityTypeMap,
          dataDefinitionId,
          bizEntityType,
          commonMetricProperties
        );

        transformedData.push({
          compareConfigData: pCompareData,
          compareDataSize,
          data: pData,
          dataDefinitionId,
          dataQueryConfig,
          dataSize,
          postAggResult: {
            data: pPAData,
            entityLookupData: postAggEntityLookupData,
            percentChangeData: pPAPerChangeData,
            timeShiftData: pPATimeshiftData
          },
          forecastResult: {
            data: fData,
            delta: fDeltaData,
            entityLookupData: forecastEntityLookupData,
            lowerBoundData: fLBData,
            percentChangeData: fPerChangeData,
            upperBoundData: fUBData
          },
          postAggDataSize,
          warnings,
          fieldResult
        });
      }
    });

    return {
      data: transformedData,
      error
    };
  });

  return Promise.resolve(transformed);
};

type CompareInfo = { enabled: boolean } & WidgetCompareDataRequest;

const getCompareInfo = (compareSelection: any): CompareInfo => {
  const { compareId, metricId, selectorSpec, tagFilters, frequency, forCount } = compareSelection;

  return compareId && metricId
    ? {
        compareId,
        metricId,
        selectorSpec,
        tagFilters,
        frequency,
        forCount,
        enabled: true
      }
    : null;
};

const getPostProcessedData = (
  entryRec: Record<string, BizEntityDataResultEntry>,
  metricDefs: Record<string, MetricDefinition>,
  entityTypeMap: Record<string, string>,
  dataDefinitionId: string,
  bizEntityType: string,
  commonMetricProperties: CommonMetricProperties
) => {
  const bizTag = entityTypeMap[bizEntityType];
  const metricDef = metricDefs[dataDefinitionId];
  const implicitSliceTag = getBizTagFromMetricDef(metricDef, bizTag);

  const processedJson: Record<string, MetricResultDataDTO> = {};

  forEach(entryRec, (entry, key) => {
    const { data: dataframes, ...rest } = entry;

    let resultSeriesName = "";

    dataframes.forEach(dataFrame => {
      const { eLabels: eTags, labels: tags, metricName, meta } = dataFrame;

      const isCompareSeries = meta?.isCompareDF;
      const curDataDef = metricDefs?.[dataDefinitionId];
      const isSystemMetric = curDataDef ? isSystemCreatedMetric(curDataDef) : false;

      const tagKeys = Object.keys(tags);

      // Omit i_entity tag
      const legendKeys: string[] = tagKeys.filter(k => k !== ENTITY_TAG && !shouldExcludeTag(k));

      const metricDefProperties = commonMetricProperties?.properties[metricDef.id];
      const metricSliceProperties = metricDefProperties?.sliceProperties.find(x =>
        isEqual(
          x.slices
            .map(x => x.tagName)
            .sort()
            .toString(),
          legendKeys.sort().toString()
        )
      );

      let prefix = "";
      let entityTag = ENTITY_TAG;
      let dataType: DataFrame["dataType"] = "NA";
      let subType: DataFrame["subType"] = "not_set";

      if (!isEmpty(metricDefs) && curDataDef) {
        const { dataType: dt, subType: st } = getDataTypeAndSubType(curDataDef);
        dataType = dt;
        subType = st;

        if (
          values(metricDefs).length > 1 ||
          !bizEntityType ||
          (values(metricDefs).length > 1 && legendKeys.length === 0) ||
          (values(metricDefs).length === 1 && !eTags[ENTITY_TAG] && legendKeys.length === 0)
        ) {
          prefix = `${curDataDef?.name} ${eTags[ENTITY_TAG] && bizEntityType ? "-" : ""} `;
        }
      }

      // if biz entity type does not exist we skip displaying name of the user service
      if (!bizEntityType || !eTags[ENTITY_TAG]) {
        entityTag = "";
      }

      let legendFormat = "";
      if (metricSliceProperties?.legendsFormat) {
        legendFormat = prefix + metricSliceProperties?.legendsFormat;
      } else {
        legendFormat = getDefaultLegendFormat(legendKeys, prefix, entityTag);
      }

      let seriesName = metricName;
      let target = "";

      if (!isEmpty(legendFormat) && !isCompareSeries) {
        let legend = templateSrv.formatLabel(legendFormat, {}, eTags);
        legend = trim(legend);

        if (!isSystemMetric) {
          seriesName = legendKeys.length ? `by ${legendKeys.join(", ")}` : "";
        }

        target = legend;
      } else if (isCompareSeries) {
        const incidentSeriesName = getCompareMetricNameFromTags(eTags);
        const compareConfig = getOpdCompareConfig(metricDefs[dataDefinitionId]);
        const compareDisplayName = WidgetConfigUtils.getCompareTypeDisplayName(compareConfig.type);
        target = incidentSeriesName
          ? `${incidentSeriesName}`
          : `${compareDisplayName} - ${getMetricNameFromTags(eTags)}`;
      } else {
        // Generate metric name from enriched tags
        target = getMetricNameFromTags(eTags, metricName);
      }

      resultSeriesName = seriesName || "";

      dataFrame.dataType = dataType;
      dataFrame.subType = subType;
      dataFrame.metricName = seriesName;
      dataFrame.name = target;
    });

    processedJson[key] = {
      bizTag,
      dataDefinitionId,
      implicitSliceTag,
      ...rest,
      resultSeriesId: key,
      resultSeriesName,
      data: dataframes
    };
  });

  return processedJson;
};
