import {
  IncButton,
  IncCheckbox,
  IncLabel,
  IncRadioButton,
  IncSelect,
  IncSelectOption,
  IncTextfield
} from "@inception/ui";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import LoadingSpinner from "../Loading/Loading";
import { TriageConfigModalProps } from "./type";

const selectOption: IncSelectOption = {
  label: "Select Dashboard",
  value: ""
};

export default function TriageConfigModal(props: TriageConfigModalProps) {
  const {
    isLoading,
    addTriageConfig,
    onClose,
    deleteTriageConfig,
    triageConfig = {},
    editMode = false,
    canEmbed = false,
    triageConfigProps,
    hideEmbedCheckbox = false,
    mode,
    createOrUpdateInProgress,
    deleteInProgress
  } = props;

  const isDashboardsMode = mode === "dashboards";

  const [selDashboardOpt, setSelDashboardOpt] = useState<IncSelectOption>(selectOption);
  const [saveAgainst, setSaveAgainst] = useState<string>(triageConfig.saveAgainst as string);
  const [configName, setConfigName] = useState<string>("");
  const [isEmbed, setIsEmbed] = useState<boolean>(canEmbed);
  const [configId, setConfigId] = useState<string>("");

  useEffect(() => {
    if (!isEmpty(triageConfig) && editMode && !isLoading) {
      const init = () => {
        setSaveAgainst(triageConfig.saveAgainst as string);
        const selDash = triageConfigProps.dbList.find(d => d.value === triageConfig.dashboardId);

        setSelDashboardOpt(selDash || selectOption);
        setConfigName(triageConfig.name as string);
        setConfigId(triageConfig.id as string);
      };

      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triageConfig, isLoading]);

  const onCheckedChange = useCallback(() => {
    setIsEmbed(!isEmbed);
  }, [isEmbed]);

  const onRadioChange = useCallback((value: string) => {
    setSaveAgainst(value);
  }, []);

  const onDashboardSelectChange = useCallback((verticalOpt: IncSelectOption) => {
    const { value } = verticalOpt;
    if (value) {
      setSelDashboardOpt(verticalOpt);
      setConfigName(verticalOpt.label);
    }
  }, []);

  const onConfigNameChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    setConfigName(evt.target.value);
  }, []);

  const handleSave = useCallback(() => {
    const data: Record<string, string | boolean> = {};

    data["saveAgainst"] = saveAgainst || "";
    data["configId"] = configId || "";
    data["configName"] = configName || "";
    data["dashboardId"] = selDashboardOpt ? selDashboardOpt.value : "";

    if (!isEmpty(Object.keys(data))) {
      addTriageConfig && addTriageConfig(data);
    }
  }, [addTriageConfig, configId, configName, saveAgainst, selDashboardOpt]);

  const handleDelete = useCallback(() => {
    if (configId) {
      deleteTriageConfig(configId);
    }
  }, [configId, deleteTriageConfig]);

  const disableUpdateOrCreate =
    !configName ||
    (isDashboardsMode && !selDashboardOpt.value) ||
    !saveAgainst ||
    isLoading ||
    createOrUpdateInProgress;

  const { cohortName, compareName, dbList, entityTypeId } = triageConfigProps;

  return (
    <>
      {isDashboardsMode && (
        <div className="inc-flex-column  marginTp8">
          <IncLabel
            className="marginRt16"
            width="250"
          >
            Select dashboard
          </IncLabel>
          <div className="inc-flex-row marginTp8">
            <IncSelect
              isLoading={isLoading}
              onChange={onDashboardSelectChange}
              options={dbList}
              value={selDashboardOpt}
              width={300}
            />
            {canEmbed && !hideEmbedCheckbox && (
              <IncCheckbox
                checked={isEmbed}
                className={"marginLt8"}
                label="Embed"
                labelProps={{
                  placement: "end"
                }}
                onChange={onCheckedChange}
              />
            )}
          </div>
        </div>
      )}
      <div className="inc-flex-row marginTp8">
        <IncTextfield
          label={"Name"}
          onChange={onConfigNameChange}
          required={true}
          style={{ width: "300px" }}
          value={configName}
        />
      </div>
      <div className="inc-flex-column  marginTp8">
        <IncLabel
          className="marginRt16"
          width="250"
        >
          Save Against
        </IncLabel>
        <div className="inc-flex-column  m-l-1">
          {entityTypeId && (
            <IncRadioButton
              checked={saveAgainst === "entityTypeId"}
              className="inc-fit-content-modal"
              label={`Entity: ${entityTypeId}`}
              name="saveAg"
              onClick={() => {
                onRadioChange("entityTypeId");
              }}
            />
          )}

          {cohortName && (
            <IncRadioButton
              checked={saveAgainst === "cohortId"}
              className="inc-fit-content-modal"
              label={`Cohort: ${cohortName}`}
              name="saveAg"
              onClick={() => {
                onRadioChange("cohortId");
              }}
            />
          )}

          {compareName && (
            <IncRadioButton
              checked={saveAgainst === "compareId"}
              className="inc-fit-content-modal"
              label={`Operationalize: ${compareName}`}
              name={"saveAg"}
              onClick={() => {
                onRadioChange("compareId");
              }}
            />
          )}
        </div>
      </div>

      <div className="inc-flex-row inc-flex-center-vertical marginTp8"></div>
      <div className="inc-flex-row inc-flex-center-vertical marginBt16 inc-flex-end">
        {editMode && (
          <IncButton
            className="marginLt8 marginTp8"
            color="danger"
            disabled={deleteInProgress}
            onClick={handleDelete}
          >
            {deleteInProgress && <LoadingSpinner titleText="Delete" />}
            {!deleteInProgress && "Delete"}
          </IncButton>
        )}
        <IncButton
          className="marginLt8 marginTp8"
          color="primary"
          disabled={disableUpdateOrCreate}
          onClick={handleSave}
        >
          {createOrUpdateInProgress && <LoadingSpinner titleText={editMode ? "Update" : "Create"} />}
          {!createOrUpdateInProgress && (editMode ? "Update" : "Create")}
        </IncButton>
        <IncButton
          className="marginLt8 marginTp8"
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </IncButton>
      </div>
    </>
  );
}
