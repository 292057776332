import { IncEditor, IncSelect, IncSelectOption } from "@inception/ui";
import React, { useCallback, useEffect, useMemo } from "react";
import { DruidTransformationConfig, DruidTransformationInputType } from "../transform-config/TransformConfig";
import { DiscoveredFieldDef } from "../../services/api/auto-discovery/types";

interface ScriptCompProps {
  config: DruidTransformationConfig;
  onChange: (config: DruidTransformationConfig) => void;
  rawOptions: Array<IncSelectOption<DiscoveredFieldDef>>;
}

export const DruidScriptComp: React.FC<ScriptCompProps> = (props: ScriptCompProps) => {
  const { config, onChange, rawOptions } = props;
  const { multilineScript, bicycleField, inputType } = config || {};

  const onScriptValueChange = useCallback(
    script => {
      onChange({
        ...config,
        inputType: DruidTransformationInputType.script,
        multilineScript: script
      });
    },
    [config, onChange]
  );

  const selectedBicycleFieldOption = useMemo(
    () => rawOptions?.find(e => e.label === bicycleField),
    [bicycleField, rawOptions]
  );

  const selectedTypeOption = useMemo(
    () =>
      inputType
        ? {
            label: inputType === DruidTransformationInputType.field ? "Bicycle Field" : "Script",
            value: inputType
          }
        : null,
    [inputType]
  );
  useEffect(() => {
    // if both multiline script and bicycle field both empty will show bicycle field as selected
    if (!inputType && !multilineScript && !bicycleField) {
      onChange({
        inputType: DruidTransformationInputType.field,
        multilineScript: "",
        bicycleField: ""
      });
      // if both inputType is not present script will be selected automatically
    } else if (!inputType) {
      onChange({
        inputType: DruidTransformationInputType.script,
        multilineScript: "",
        bicycleField: ""
      });
    }
  }, [bicycleField, inputType, multilineScript, onChange]);

  const onChangeType = useCallback(
    (option: IncSelectOption) => {
      const { value } = option || {};
      const selectedType = value as DruidTransformationConfig["inputType"];
      onChange({
        inputType: selectedType,
        multilineScript: "",
        bicycleField: ""
      });
    },
    [onChange]
  );
  const onChangeBicycleField = useCallback(
    (option: IncSelectOption<DiscoveredFieldDef>) => {
      const { label } = option || {};
      onChange({
        ...config,
        inputType: DruidTransformationInputType.field,
        bicycleField: label
      });
    },
    [config, onChange]
  );

  return (
    <div className="inc-flex-column flex-gap-12">
      <IncSelect
        isClearable={false}
        isSearchable={false}
        label="Type"
        onChange={onChangeType}
        options={druidTypeOptions}
        placeholder="Select Type"
        value={selectedTypeOption}
      />
      {inputType === DruidTransformationInputType.field && (
        <IncSelect
          label="Bicycle Field"
          onChange={onChangeBicycleField}
          options={rawOptions}
          placeholder="Select Bicycle Field"
          value={selectedBicycleFieldOption}
        />
      )}
      {inputType !== DruidTransformationInputType.field && (
        <IncEditor
          focus
          maxLines={10}
          onChange={onScriptValueChange}
          setOptions={{
            showGutter: false
          }}
          value={multilineScript || ""}
        />
      )}
    </div>
  );
};

const druidTypeOptions: IncSelectOption[] = [
  {
    label: "Bicycle Field",
    value: DruidTransformationInputType.field
  },
  {
    label: "Script",
    value: DruidTransformationInputType.script
  }
];
