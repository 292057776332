import TextWidgetModel, { TextWidgetCaseStyle, TextWidgetCenterAlign } from "../../widgets/Text/model";
import TextWidgetImpl from "../../widgets/Text/impl";
import { BaseWidgetBuilder, WidgetBuilderCallbackResult } from "./BaseWidgetBuilder";

const textWidgetBuilder = (
  model: Partial<TextWidgetModel> = {}
): WidgetBuilderCallbackResult<TextWidgetModel, TextWidgetBuilder> => {
  const tsModel: TextWidgetModel = new TextWidgetImpl(model).getSafeDefaults() as TextWidgetModel;

  // Return a method that ensures a datasource is set before setting any other properties
  return {
    setDatasource: (dsName: string) => {
      tsModel.datasource = dsName;

      return new TextWidgetBuilder(tsModel);
    }
  };
};

class TextWidgetBuilder extends BaseWidgetBuilder<TextWidgetModel> {
  constructor(model: TextWidgetModel) {
    super(model);
    this.model = model;
    this.model.properties = this.model.properties || ({} as any);
    this.model.options = this.model.options || {};
  }

  setText(text: string) {
    this.model.text = text;
    return this;
  }

  setFontSize(size: number) {
    this.model.properties.size = size;
    return this;
  }

  setFontColor(color: string) {
    this.model.properties.color = color;
    return this;
  }

  setFontWeight(weight: number) {
    this.model.properties.weight = weight;
    return this;
  }

  setCaseStyle(caseStyle: TextWidgetCaseStyle) {
    this.model.properties.caseStyle = caseStyle;
    return this;
  }

  setCenterAlign(centerAlign: TextWidgetCenterAlign) {
    this.model.properties.centerAlign = centerAlign;
    return this;
  }
}

export default textWidgetBuilder;
