import React, { FC, useRef, useCallback, useMemo } from "react";
import { IncFaIcon, IncSelect, IncSelectOption, IncTextfield } from "@inception/ui";
import { SuppressionConfigDef } from "../../../../services/api/operationalise";
import { BizDataQuery, ImpactedWidget } from "../../../../services/api/explore";
import { LogicalOperation } from "../../../../core";
import { VerticallyCenteredRow } from "../../../../components";
import { noOp } from "../../../../utils";
import { defRollingFreq } from "../../../constants";
import { SuppressionQueryEditorWrapper } from "./SuppressionQueryEditorWrapper";
import { SuppressionEditorPickerContext } from "./types";

interface Props {
  suppressionConfigDef: SuppressionConfigDef;
  onChange: (suppressionConfigDef: SuppressionConfigDef) => void;
  pickerContext: SuppressionEditorPickerContext;
  onDelete?: () => void;
  compareTimeSeconds?: number;
  getPredefinedMetrics?: () => Promise<ImpactedWidget[]>;
}

export const SuppressionEditorRow: FC<Props> = props => {
  const { suppressionConfigDef, pickerContext, onChange, onDelete, compareTimeSeconds, getPredefinedMetrics } = props;

  const { bizDataQuery, operator, value } = suppressionConfigDef;

  const suppressionConfigDefRef = useRef(suppressionConfigDef);
  useMemo(() => {
    suppressionConfigDefRef.current = suppressionConfigDef;
  }, [suppressionConfigDef]);

  const operatorOpt = useMemo(() => operatorOptions.find(opt => opt.data === operator), [operator]);

  const onBizDataQueryChange = useCallback(
    (bizDataQuery: BizDataQuery) => {
      onChange({
        ...suppressionConfigDefRef.current,
        bizDataQuery
      });
    },
    [onChange]
  );

  const onOperatorChange = useCallback(
    (opt: IncSelectOption<LogicalOperation>) => {
      onChange({
        ...suppressionConfigDefRef.current,
        operator: opt.data
      });
    },
    [onChange]
  );

  const onSuppressionValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.valueAsNumber;
      if (isNaN(value)) {
        value = 0;
      }

      onChange({
        ...suppressionConfigDefRef.current,
        value
      });
    },
    [onChange]
  );

  return (
    <VerticallyCenteredRow className="suppression-editor-row marginBt12">
      <SuppressionQueryEditorWrapper
        bizDataQuery={bizDataQuery}
        compareTimeSeconds={compareTimeSeconds}
        getPredefinedMetrics={getPredefinedMetrics}
        onChange={onBizDataQueryChange}
        onRollingFreqChange={noOp}
        onRollingFunctionChange={noOp}
        pickerContext={pickerContext}
        rollingFreq={defRollingFreq}
        rollingFunction="avg"
        showBizDataQueryOnly
      />

      <IncSelect
        autoSort={false}
        isSearchable={false}
        onChange={onOperatorChange}
        options={operatorOptions}
        placeholder="Operator"
        value={operatorOpt}
        wrapperClass="marginLt8 marginRt8 suppression-editor-row--op-select"
      />

      <IncTextfield
        containerClassName="marginLt8 marginRt8"
        onChange={onSuppressionValueChange}
        placeholder="Value"
        type="number"
        value={value || ""}
      />

      {Boolean(onDelete) && (
        <IncFaIcon
          className="status-danger inc-cursor-pointer"
          iconName="trash-alt"
          onClick={onDelete}
        />
      )}
    </VerticallyCenteredRow>
  );
};

const operatorOptions: Array<IncSelectOption<LogicalOperation>> = [
  {
    label: "equals",
    value: "eq",
    data: "eq"
  },
  {
    label: "not equals",
    value: "neq",
    data: "neq"
  },
  {
    label: "less than",
    value: "lt",
    data: "lt"
  },
  {
    label: "less than or equals",
    value: "le",
    data: "le"
  },
  {
    label: "greater than",
    value: "gt",
    data: "gt"
  },
  {
    label: "greater than or equals",
    value: "ge",
    data: "ge"
  }
];
