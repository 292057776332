import { IncButton, IncCheckbox, IncTextfield } from "@inception/ui";
import React, { ChangeEvent, FC, MouseEvent, useCallback, useMemo } from "react";
import LoadingSpinner from "../../../Loading/Loading";

export type ChangeEventCriteriaField = {
  field: string;
  defaultSelected: boolean;
};

type Props = {
  isFetching: boolean;
  onSearchTextChange: (evt: ChangeEvent<HTMLInputElement>) => void;
  onSelectAll: () => void;
  onRemoveAll: () => void;
  onSelectionChange: (filed: string) => void;
  searchText: string;
  fieldsToSelect: ChangeEventCriteriaField[];
  selectionMap: Record<string, boolean>;
};

const ChangeEventCriteria: FC<Props> = props => {
  const {
    isFetching,
    onRemoveAll,
    onSearchTextChange,
    onSelectAll,
    searchText,
    fieldsToSelect,
    selectionMap,
    onSelectionChange
  } = props;

  const onTextFieldClick = useCallback((evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
  }, []);

  const fields = useMemo(
    () =>
      fieldsToSelect?.map((key, index) => {
        const label = <div className="inc-flex-row inc-flex-center-vertical">{key.field}</div>;

        return (
          <IncCheckbox
            checked={selectionMap[key.field] || key.defaultSelected}
            disabled={key.defaultSelected}
            key={index}
            label={label}
            labelProps={{
              placement: "end"
            }}
            noImplicitHeight={true}
            onChange={() => onSelectionChange(key.field)}
          />
        );
      }),
    [fieldsToSelect, onSelectionChange, selectionMap]
  );
  return (
    <div className="events-table--field-selector">
      <div className="fields-side-panel">
        {isFetching && <LoadingSpinner titleText="Loading fields..." />}
        {!isFetching && (
          <div className="fields-panel">
            <div className="fields-section">
              <div className="search-bar">
                <IncTextfield
                  autoFocus={true}
                  onChange={onSearchTextChange}
                  onClick={onTextFieldClick}
                  placeholder={"Search by name"}
                  value={searchText}
                />
              </div>

              <div className="inc-flex-row inc-flex-space-contents inc-flex-center-vertical marginBt8">
                <IncButton
                  color="link"
                  onClick={onSelectAll}
                >
                  Select All
                </IncButton>
                <IncButton
                  className="clear-button marginRt12"
                  color="link"
                  onClick={onRemoveAll}
                >
                  Clear
                </IncButton>
              </div>

              <div className="inc-flex-column marginBt12 fields-list flex-gap-12">{fields}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChangeEventCriteria;
