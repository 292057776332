import { IncSelect, IncSelectOption, IncTextfield } from "@inception/ui";
import { inRange, isEqual } from "lodash";
import React, { FC, useEffect, useState, useCallback, useMemo, useRef } from "react";
import { VerticallyCenteredRow } from "../../../../../../components";
import { EntityPropertyValue, useToggleState } from "../../../../../../core";
import {
  ActionTemplateElementProp,
  SectionElementType,
  TemplateCanvasSection
} from "../../../../../../services/api/operationalise";
import { SectionPropertiesEditor } from "../../section-properties";
import {
  EVENT_GROUPBY_FIELDS_PROP_ID,
  EVENT_GROUPBY_IMPACTED_WIDGET_PROP_ID,
  EVENT_GROUPBY_MAX_ROWS_PROP_ID
} from "../../utils";

interface Props {
  diagFieldsOpts: IncSelectOption[];
  selDiagFieldOpts: IncSelectOption[];

  impactedWidgetOpts: IncSelectOption[];
  selImpactedWidgetOpt: IncSelectOption;

  maxRows: number;
  maxRowsMin: string;
  maxRowsMax: string;

  secondaryProperties: ActionTemplateElementProp[];
  propValues: Record<string, EntityPropertyValue>;

  onChange: (propValues: Record<string, EntityPropertyValue>) => void;
  setHasError: (hasError: boolean) => void;

  maxRowsProperty: ActionTemplateElementProp;
  diagnosticFieldsProperty: ActionTemplateElementProp;
  impactedWidgetsProperty: ActionTemplateElementProp;
}

export const EventGroupByPropertiesEditor: FC<Props> = props => {
  const {
    diagFieldsOpts,
    impactedWidgetOpts,
    maxRows: pMaxRows,
    maxRowsMax: maxRowsMaxStr,
    maxRowsMin: maxRowsMinStr,
    propValues: pPropValues,
    secondaryProperties,
    selDiagFieldOpts: pSelDiagFieldOpts,
    selImpactedWidgetOpt: pSelImpactedWidgetOpt,
    onChange,
    setHasError,
    maxRowsProperty,
    diagnosticFieldsProperty,
    impactedWidgetsProperty
  } = props;

  const { isOpen: isMaxRowsError, setIsOpen: setMaxRowsHasError } = useToggleState();

  const maxRowsMin = parseInt(maxRowsMinStr, 10);
  const maxRowsMax = parseInt(maxRowsMaxStr, 10);

  const [maxRows, setMaxRows] = useState<number>(parseInt(pMaxRows.toString(), 10));
  const [maxRowsStr, setMaxRowsStr] = useState<string>(pMaxRows.toString());

  const [selDiagFieldOpts, setSelDiagFieldOpts] = useState<IncSelectOption[]>(pSelDiagFieldOpts);
  const [selImpactedWidgetOpt, setSelImpactedWidgetOpt] = useState<IncSelectOption>(pSelImpactedWidgetOpt);

  const [propValues, setPropValues] = useState(pPropValues || {});
  const ignorePropsChangeCheckRef = useRef(false);

  useEffect(() => {
    setPropValues(pPropValues || {});
  }, [pMaxRows, pPropValues]);

  useEffect(() => {
    setMaxRowsStr(pMaxRows.toString());
  }, [pMaxRows]);

  useEffect(() => {
    setMaxRows(parseInt(pMaxRows.toString(), 10));
  }, [pMaxRows]);

  useEffect(() => {
    setSelDiagFieldOpts(pSelDiagFieldOpts);
  }, [pSelDiagFieldOpts]);

  useEffect(() => {
    setSelImpactedWidgetOpt(pSelImpactedWidgetOpt);
  }, [pSelImpactedWidgetOpt]);

  const onMaxRowsChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const maxRowsStr = e.target.value;

    setMaxRows(parseInt(maxRowsStr, 10));
    setMaxRowsStr(maxRowsStr);
  }, []);

  const onMaxRowsBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, HTMLInputElement>) => {
      const maxRowsStr = e.target.value;

      let maxRows = parseInt(maxRowsStr, 10);
      maxRows = isNaN(maxRows) ? maxRowsMin : maxRows;

      setMaxRows(maxRows);
      setMaxRowsStr(maxRows.toString());
    },
    [maxRowsMin]
  );

  const section = useMemo<TemplateCanvasSection>(
    () => ({
      sectionElementId: "event-groupby-section",
      sectionId: "",
      type: SectionElementType.EVENT_GROUP_BY,
      prop: {
        propValue: {
          ...(propValues || {})
        }
      }
    }),
    [propValues]
  );

  useEffect(() => {
    if (propValues && selImpactedWidgetOpt && selDiagFieldOpts) {
      const nextPropValues: Props["propValues"] = {
        ...propValues
      };

      nextPropValues[EVENT_GROUPBY_IMPACTED_WIDGET_PROP_ID] = {
        stringVal: selImpactedWidgetOpt.value
      };

      nextPropValues[EVENT_GROUPBY_FIELDS_PROP_ID] = {
        setValue: {
          values: selDiagFieldOpts.map(opt => opt.value)
        }
      };

      const fMaxRows = isNaN(maxRows) ? maxRowsMin : maxRows;
      nextPropValues[EVENT_GROUPBY_MAX_ROWS_PROP_ID] = {
        longVal: fMaxRows
      };

      if (ignorePropsChangeCheckRef.current || !isEqual(nextPropValues, propValues)) {
        ignorePropsChangeCheckRef.current = false;
        onChange(nextPropValues);
      }
    }
  }, [maxRows, maxRowsMin, onChange, propValues, selDiagFieldOpts, selImpactedWidgetOpt]);

  const onPropValuesChange = useCallback((section: TemplateCanvasSection) => {
    ignorePropsChangeCheckRef.current = true;

    const propValues = section.prop?.propValue || {};
    setPropValues(propValues);
  }, []);

  useEffect(() => {
    const isMaxRowsInRange = inRange(maxRows, maxRowsMin, maxRowsMax + 1);
    setMaxRowsHasError(!isMaxRowsInRange);
    setHasError(!isMaxRowsInRange);
  }, [maxRows, maxRowsMax, maxRowsMin, setHasError, setMaxRowsHasError]);

  const maxRowsError = `Max rows should be in the range ${maxRowsMin} - ${maxRowsMax}`;

  return (
    <>
      <VerticallyCenteredRow className="paddingTp4">
        <VerticallyCenteredRow className="marginRt8">Show Top</VerticallyCenteredRow>

        <IncTextfield
          containerClassName="marginRt8 inc-flex-row"
          errorText={maxRowsError}
          hasError={isMaxRowsError}
          max={maxRowsMax}
          min={maxRowsMin}
          onBlur={onMaxRowsBlur}
          onChange={onMaxRowsChange}
          placeholder={maxRowsProperty?.label || ""}
          type="number"
          value={maxRowsStr}
        />

        <IncSelect
          autoAdjustWidth
          autoAdjustWidthBuffer={110}
          isMulti
          onChange={setSelDiagFieldOpts as any}
          options={diagFieldsOpts}
          placeholder={diagnosticFieldsProperty?.label || ""}
          value={selDiagFieldOpts}
        />

        <VerticallyCenteredRow className="marginLt8 marginRt8">for</VerticallyCenteredRow>

        <IncSelect
          autoAdjustWidth
          onChange={setSelImpactedWidgetOpt}
          options={impactedWidgetOpts}
          placeholder={impactedWidgetsProperty?.label || ""}
          value={selImpactedWidgetOpt}
        />
      </VerticallyCenteredRow>

      <div className="marginTp12">
        <SectionPropertiesEditor
          onChange={onPropValuesChange}
          properties={secondaryProperties}
          section={section}
        />
      </div>
    </>
  );
};
