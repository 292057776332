import { IncSelectOption, IncSlimSelect } from "@inception/ui";
import { pick } from "lodash";
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { AlertEventRecord, AlertStatus, OpSchema } from "../../services/api/operationalise";
import { ENTITY_TAG } from "../../utils";
import { Op10zeQueryWidgetWrapper } from "../Op10zeQueryWidgetWrapper";

interface Props {
  incident: AlertEventRecord;
  opSchema: OpSchema;
  generateDemoData: boolean;
  isFetching: boolean;

  asSparkline?: boolean;
}

export const InsightPreviewGraph: FC<Props> = memo(props => {
  const { incident, opSchema, generateDemoData, isFetching, asSparkline = false } = props;

  const {
    startTimeMillis,
    endTimeMillis,
    opConfigId,
    id: incidentId,
    additionalData: metricsInfo,
    alertParams,
    entityType,
    lookupData,
    status
  } = incident || {};

  const {
    rollingFrequencyMillis,
    alertingEventFields,
    frequency,
    lookback: lookBack,
    isSpikePositive,
    spikeCase
  } = opSchema || {};
  const isIncidentActive = status === AlertStatus.active;

  const metricTagsToPick = useMemo(
    () => (alertingEventFields?.slices || []).map(slice => slice.tagName),
    [alertingEventFields]
  );
  const tagCombinations = useMemo(
    () => (metricsInfo || []).map(info => pick(info, metricTagsToPick)),
    [metricTagsToPick, metricsInfo]
  );
  const isOpportunity = spikeCase ? isSpikePositive : !isSpikePositive;

  const metricName = metricTagsToPick?.length ? "" : lookupData?.metricName || lookupData?.[opConfigId] || "";
  const simulationId = alertParams?.simulationId?.stringVal || "";

  const tagOptions = useMemo(() => {
    const options: IncSelectOption[] = [];
    tagCombinations.forEach(tagCombination => {
      const label = Object.keys(tagCombination)
        .map(tagName => {
          const displayTagName = tagName === ENTITY_TAG ? entityType || "" : tagName;
          let displayTagValue = tagCombination[tagName];
          displayTagValue = lookupData?.[displayTagValue] || displayTagValue;
          return displayTagName ? `${displayTagName}: ${displayTagValue}` : displayTagValue;
        })
        .join(", ");
      options.push({
        label,
        value: label,
        data: tagCombination
      });
    });

    if (options.length === 1 && options[0].value === "" && metricName) {
      options[0].label = metricName;
      options[0].value = metricName;
    }
    return options;
  }, [entityType, lookupData, metricName, tagCombinations]);

  const [selectedTagCombination, setSelectedTagCombination] = useState(tagOptions[0]);
  useEffect(() => {
    setSelectedTagCombination(tagOptions[0]);
  }, [tagOptions]);

  const onSelectTagOption = useCallback((tagOption: IncSelectOption) => {
    setSelectedTagCombination(tagOption);
  }, []);

  return (
    <div className="preview-graph width-100">
      {isFetching && <LoadingPills />}
      {!isFetching && (
        <IncSlimSelect
          className={tagOptions.length > 0 ? "" : "visibility-hidden disableClick"}
          onChange={onSelectTagOption}
          options={tagOptions}
          value={selectedTagCombination}
        />
      )}

      {!isFetching && (
        <Op10zeQueryWidgetWrapper
          asSparkline={asSparkline}
          enableBufferTimeRange
          endTimeMillis={endTimeMillis}
          frequency={frequency}
          generateDemoData={generateDemoData}
          hideLegends
          ignoreEndBuffer={isIncidentActive}
          incidentId={incidentId}
          isOpportunity={isOpportunity}
          lookBack={lookBack}
          metricName={metricName}
          noPointMarker
          opConfigId={opConfigId}
          rollingFrequencyMillis={rollingFrequencyMillis ? parseInt(rollingFrequencyMillis, 10) : null}
          simulationId={simulationId}
          startTimeMillis={startTimeMillis}
          tagCombination={selectedTagCombination?.data || {}}
        />
      )}
      {isFetching && (
        <div
          className="widget-container"
          data-show-loader="true"
        />
      )}
    </div>
  );
});

export const LoadingPills = () => (
  <div
    className="series-pill inc-cursor-pointer w-25"
    data-show-loader={true}
  >
    Title
  </div>
);
