import { defaults } from "lodash";
import { getInceptionTheme } from "@inception/ui";
import BaseWidgetImpl from "../../model-impl/BaseWidgetImpl";
import TextWidgetModel, { TextWidgetProperties } from "./model";

const getDefaults = (): Partial<TextWidgetModel> => ({
  title: "New Text",
  properties: {
    color: getInceptionTheme().inceptionColors.palette.B800,
    size: 16,
    caseStyle: "none",
    weight: 500
  },
  type: "plain-text"
});

export default class TextWidgetImpl extends BaseWidgetImpl implements TextWidgetModel {
  text: string;
  properties: TextWidgetProperties;
  type = "plain-text" as TextWidgetModel["type"];

  constructor(model: Partial<TextWidgetModel>, meta?: Record<string, any>) {
    super(model, meta);
    this.assign(model);
  }

  assign(model: Partial<TextWidgetModel>) {
    const bcModel: any = defaults({ type: "plain-text" }, model, getDefaults());
    super.assign(bcModel);

    const { properties, text } = bcModel;

    this.properties = { ...properties };
    this.text = text;
  }
}
