import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IncButton, IncFaIcon, IncFaIconName, IncLoadingSpinner, IncModal, IncSmartText } from "@inception/ui";
import {
  ConversationEntry,
  ConversationFragment,
  ConversationResponse,
  ConversationStatus,
  ParameterValue
} from "../../services/api/chat";
import { getChatTimeStampStr } from "../utils";
import { Op10zeSourceConfig, OperationaliseModal, VerticallyCenteredRow } from "../../components";
import { ReactComponent as LogoSVG } from "../../../images/logo-bicycle.svg";
import { OpCreationConfig } from "../../services/api/operationalise";
import { logger, useVerticalConfig } from "../../core";
import { DemoDataParams, OverallMappingStatus } from "../../services/api/explore";
import { TypingAnimation } from "./TypingAnimation";
import { getResponseJsx } from "./StepsRenderer";

type CEProps = {
  conversation: ConversationEntry;
  inProgress: boolean;
  userAvatar: JSX.Element;
  isLast: boolean;
  id: string;
  onApplyRecommendation: (recommendation: string) => void;

  hideEmptyMessage?: boolean;
  useDemoParamsFromVerticalConfig?: boolean;
  getResponseJsx?: (response: ConversationResponse, userMessageStr: string, key: string) => [JSX.Element, boolean];
  hideUserDialogue?: boolean;
  onImplementPlan?: (planId: string, paramValues: ParameterValue[]) => void;
  chatId?: string;
  skipTimestamps?: boolean;
};

export const ConversationEntryRenderer = memo<CEProps>(ceProps => {
  const {
    conversation,
    inProgress,
    userAvatar,
    id,
    isLast,
    onApplyRecommendation,
    hideEmptyMessage = false,
    getResponseJsx: uGetResponseJsx,
    useDemoParamsFromVerticalConfig = false,
    hideUserDialogue = false,
    onImplementPlan,
    chatId = "",
    skipTimestamps = false
  } = ceProps;

  const { verticalConfig } = useVerticalConfig();
  const { companyName, subVerticalId, verticalId, useCaseId } = verticalConfig;

  const responseRef = useRef<HTMLDivElement>(null);
  const getResponseCallback = uGetResponseJsx || getResponseJsx;

  useEffect(() => {
    if (isLast && responseRef.current) {
      responseRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end"
      });
    }
  }, [isLast]);

  const { message, response, timeStampInMillis, bicycleMessageTimeMillis } = conversation;

  const userMessageTsStr = skipTimestamps ? "" : getChatTimeStampStr(timeStampInMillis);
  const bicycleMessageTsStr = skipTimestamps ? "" : getChatTimeStampStr(bicycleMessageTimeMillis);

  const [opCreationConfig, setOpCreationConfig] = useState<OpCreationConfig>();
  const onOperationalize = useCallback(
    (config: OpCreationConfig) => {
      if (!opCreationConfig) {
        setOpCreationConfig(config);
      }

      logger.error("ConversationEntryRenderer", "Tried to operationalize invalid configuration", opCreationConfig);
    },
    [opCreationConfig]
  );
  const resetOpContext = useCallback(() => setOpCreationConfig(null), []);

  const [referenceFragment, setReferenceFragment] = useState<ConversationFragment>();
  const onCloseReferenceModal = useCallback(() => setReferenceFragment(null), []);

  const demoDataParams = useMemo<DemoDataParams>(
    () => ({
      companyName,
      generateDemoData: false,
      purpose: null,
      subVertical: subVerticalId,
      usecase: useCaseId,
      vertical: verticalId
    }),
    [companyName, subVerticalId, useCaseId, verticalId]
  );

  const { responseJSX, skipRecommendations } = useMemo(() => {
    if (inProgress) {
      return {
        responseJSX: (
          <div className="dialogue-text width-fit-content">
            <div className="padding16">
              <TypingAnimation className="typing-loader" />
            </div>
          </div>
        ),
        skipRecommendations: true
      };
    }

    const [children, skipRecommendations] = getResponseCallback(
      response,
      message,
      id,
      demoDataParams,
      onOperationalize,
      onApplyRecommendation,
      "",
      "",
      undefined,
      undefined,
      onImplementPlan,
      chatId
    );

    if (!children) {
      return {
        responseJSX: null,
        skipRecommendations
      };
    }

    return {
      responseJSX: <VerticallyCenteredRow className="response-jsx">{children}</VerticallyCenteredRow>,
      skipRecommendations
    };
  }, [
    chatId,
    demoDataParams,
    getResponseCallback,
    id,
    inProgress,
    message,
    onApplyRecommendation,
    onImplementPlan,
    onOperationalize,
    response
  ]);

  const recommendationsJsx = useMemo(() => {
    if (inProgress || !isLast || skipRecommendations) {
      return null;
    }

    return getRecommendationsJsx(response, onApplyRecommendation, id);
  }, [id, inProgress, isLast, onApplyRecommendation, response, skipRecommendations]);

  const responseStatus = response?.status;
  const actionsJsx = useMemo(() => {
    if (responseStatus !== ConversationStatus.ONGOING && !inProgress) {
      return (
        <VerticallyCenteredRow className="marginTp24 width-100">
          <IncButton
            color="secondary-blue"
            iconType="iconText"
          >
            <IncFaIcon iconName="clone" />
            Copy
          </IncButton>

          <VerticallyCenteredRow className="marginLtAuto message-actions">
            <IncFaIcon
              className="marginRt16 inc-cursor-pointer "
              iconName="thumbs-up"
              regular
            />
            <IncFaIcon
              className="marginRt16 inc-cursor-pointer"
              iconName="thumbs-down"
              regular
            />
            <IncFaIcon
              className="inc-cursor-pointer status-danger"
              iconName="flag"
              regular
            />
          </VerticallyCenteredRow>
        </VerticallyCenteredRow>
      );
    }

    return <></>;
  }, [inProgress, responseStatus]);

  const referenceFragmentDetailsJSX = useMemo(() => {
    if (referenceFragment) {
      referenceFragment.uiExtraInfo = {
        defaultOpen: true
      };
      const response: ConversationResponse = {
        fragment: [referenceFragment],
        message: "",
        recommendations: [],
        status: ConversationStatus.COMPLETED
      };
      const [children] = getResponseCallback(response, companyName);
      return <>{children}</>;
    }

    return <></>;
  }, [companyName, getResponseCallback, referenceFragment]);

  const shouldDisplayUserDialogue = hideEmptyMessage ? Boolean(message) : true;
  const shouldDisplayBotDialogue = hideEmptyMessage ? Boolean(responseJSX) : true;

  const { entityTypeId, eventTypeId, sourceConfig, cohortId, generateDemoData } = useMemo(() => {
    let sourceConfig: Op10zeSourceConfig;
    let eventTypeId: string;
    let entityTypeId: string;
    let cohortId: string;
    let generateDemoData = false;

    if (opCreationConfig) {
      const { idProps } = opCreationConfig;
      if (!idProps) {
        logger.error("ConversationEntryRenderer", "Invalid operationalise config. idProps not found", opCreationConfig);
      } else {
        const { primary, secondary } = idProps;
        entityTypeId = primary?.bizEntityTypeId || "";
        eventTypeId = primary?.eventTypes?.userServiceInfo?.[0]?.userServiceEntityId || "";
        cohortId = secondary?.cohortId || "";

        sourceConfig = {
          sourceType: "opConfig",
          opCreationConfig,
          widgetConfigRefId: secondary?.widgetId || ""
        };

        generateDemoData =
          opCreationConfig?.opMappingStatus?.queryMappingStatus?.isIncomplete ||
          opCreationConfig?.opMappingStatus?.queryMappingStatus?.overallStatus === OverallMappingStatus.PENDING;

        if (!opCreationConfig.demoDataGenInfo && generateDemoData && useDemoParamsFromVerticalConfig) {
          opCreationConfig.demoDataGenInfo = {
            companyName,
            purpose: "",
            subVertical: subVerticalId,
            usecase: useCaseId,
            vertical: verticalId
          };
        }
      }
    }

    return {
      sourceConfig,
      eventTypeId,
      entityTypeId,
      cohortId,
      generateDemoData
    };
  }, [companyName, useDemoParamsFromVerticalConfig, opCreationConfig, subVerticalId, useCaseId, verticalId]);

  if (!shouldDisplayUserDialogue && !shouldDisplayBotDialogue && !recommendationsJsx) {
    return <></>;
  }

  return (
    <div className="conversation-entry">
      {shouldDisplayUserDialogue && !hideUserDialogue && (
        <>
          <VerticallyCenteredRow className="user-dialogue inc-flex-grow inc-flex-end marginLtAuto">
            <div className="user-dialogue--dialogue marginRt12">
              <VerticallyCenteredRow>{message}</VerticallyCenteredRow>
            </div>
            {userAvatar}
          </VerticallyCenteredRow>
          {!skipTimestamps && <div className="timestamp user-dialogue--timestamp">{userMessageTsStr}</div>}
        </>
      )}

      {shouldDisplayBotDialogue && (
        <>
          <VerticallyCenteredRow
            className="bot-dialogue inc-flex-grow"
            ref={responseRef}
          >
            <LogoSVG className="bot-logo" />
            <div
              className="bot-dialogue--dialogue marginLt12"
              data-loading={inProgress}
            >
              {responseJSX}
              {Boolean(0) && actionsJsx}
            </div>
          </VerticallyCenteredRow>
          {!skipTimestamps && <div className="timestamp bot-dialogue--timestamp">{bicycleMessageTsStr}</div>}
          {recommendationsJsx}
        </>
      )}

      {Boolean(sourceConfig) && (
        <OperationaliseModal
          cohortId={cohortId}
          entityTypeId={entityTypeId}
          eventTypeId={eventTypeId}
          generateDemoData={generateDemoData}
          hideList
          mode="create"
          onClose={resetOpContext}
          open
          sourceConfig={sourceConfig}
        />
      )}

      {Boolean(referenceFragment) && (
        <IncModal
          onClose={onCloseReferenceModal}
          show
          titleText={referenceFragment.title || "View Reference"}
        >
          <div className="inc-flex-column width-100 paddingBt24">{referenceFragmentDetailsJSX}</div>
        </IncModal>
      )}
    </div>
  );
});

const getRecommendationsJsx = (
  response: ConversationResponse,
  onApplyRecommendation: (recommendation: string) => void,
  key: string
) => {
  const { chatRecommendations } = response || {};
  const { status, recommendations } = chatRecommendations || {};

  if (status === ConversationStatus.ONGOING) {
    return (
      <IncLoadingSpinner
        className="marginTp24"
        label="Fetching Recommendations..."
      />
    );
  }

  return (
    Boolean(recommendations?.length) && (
      <div className="chat-recommendations inc-flex-column">
        <VerticallyCenteredRow className="inc-label-common">Recommendations</VerticallyCenteredRow>

        <div className="suggestions">
          {recommendations.slice(0, 4).map((recommendation, idx) => {
            const eKey = [key, "recommendation", idx].join("_");
            const { markdown, fragmentInfo } = recommendation || {};
            const { label } = fragmentInfo || {};
            const { iconName } = label || {};
            const onClick = () => onApplyRecommendation(markdown?.markdown || "");

            return (
              <VerticallyCenteredRow
                className={"recommendation-container inc-cursor-pointer flex-gap-12"}
                key={eKey}
                onClick={onClick}
              >
                <IncFaIcon
                  className="icon"
                  iconName={(iconName as IncFaIconName) || "info"}
                />
                <IncSmartText
                  text={markdown?.markdown}
                  textClass="width-100"
                />
                <div className="marginLtAuto" />
                {PaperPlaneRight}
              </VerticallyCenteredRow>
            );
          })}
        </div>
      </div>
    )
  );
};

const PaperPlaneRight = (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.18442 7.24978L2.0563 2.99665L11.9813 7.24978H4.18442ZM4.18442 8.74978H11.9813L2.0563 13.0029L4.1813 8.74978H4.18442ZM1.3938 1.08103C1.00942 0.915403 0.559425 1.00915 0.271925 1.31228C-0.0155753 1.6154 -0.0812003 2.07165 0.1063 2.44665L2.8813 7.99978L0.1063 13.5529C-0.0812003 13.9279 -0.0155753 14.381 0.271925 14.6873C0.559425 14.9935 1.0063 15.0842 1.3938 14.9217L15.3938 8.92165C15.7625 8.7654 16 8.4029 16 8.0029C16 7.6029 15.7625 7.2404 15.3938 7.08415L1.3938 1.08415V1.08103Z"
      fill="#39ACFF"
    />
  </svg>
);
