import { Layout } from "react-grid-layout";
import { useMemo } from "react";
import { cloneDeep } from "lodash";
import { dashboardUtils } from "../DashboardUtils";

export const useDashboardLayout = (layout: Layout[]) => {
  const lCols = dashboardUtils.layoutcolumns;

  const lgColsFactor = 1;
  const mdColsFactor = 1;
  const smColsFactor = 0.5;
  const xsColsFactor = 0.5;
  const xxsColsFactor = 0.25;

  const respLayouts = useMemo(() => {
    const lg = cloneDeep(layout);
    const md = lg.map(l => ({
      ...l,
      x: Math.floor(l.x * mdColsFactor),
      w: Math.floor(l.w * mdColsFactor)
    }));
    const sm = lg.map(l => ({
      ...l,
      x: Math.floor(l.x * smColsFactor),
      w: Math.floor(l.w * smColsFactor)
    }));
    const xs = lg.map(l => ({
      ...l,
      x: Math.floor(l.x * xsColsFactor),
      w: Math.floor(l.w * xsColsFactor)
    }));
    const xxs = lg.map(l => ({
      ...l,
      x: Math.floor(l.x * xxsColsFactor),
      w: Math.floor(l.w * xxsColsFactor)
    }));

    return {
      lg,
      md,
      sm,
      xs,
      xxs
    };
  }, [layout]);

  const cols = useMemo(
    () => ({
      lg: lCols * lgColsFactor,
      md: lCols * mdColsFactor,
      sm: lCols * smColsFactor,
      xs: lCols * xsColsFactor,
      xxs: lCols * xxsColsFactor
    }),
    [lCols]
  );

  return {
    respLayouts,
    cols
  };
};
