import { ConnectorSchema, ConnectorSchemaResponse } from "@inception/connector-config-ui/src/schema-types";
import { CancelToken } from "axios";
import { isEmpty } from "lodash";
import { v4 as uuidV4 } from "uuid";
import { BicycleEventPreviewRequestV2, EventGroup, MappingWorkflowOutput } from "../auto-discovery/types";
import datasourceApiManager from "../DatasourceApiService";
import { UserServiceFieldPreviewRequest, UserServiceFieldPreviewResponse, PreviewDataObj } from "../entity-mapping";
import { logger } from "../../../core";
import {
  BicycleEventPreviewRequest,
  CheckConnectionResponse,
  ClonedMappingRulesResponse,
  ConfiguredSources,
  EventPreviewData,
  PreviewDataRequest,
  PreviewDataResponse,
  SaveSourceConfig,
  SourceListResponse,
  SourceType,
  SourceTypeDef,
  UserServiceMappingRule,
  UserServiceMappingRulesSummary,
  UserServiceNameResponse,
  UserServicePreviewRequest,
  EventConnectorStatusResponse,
  SaveEventSourceConfigResponse
} from "./types";

interface EventMappingApiServiceInterface {
  getSourceList: (type: SourceType) => Promise<SourceTypeDef[]>;
  getConnectionSpec: (sourceTypeId: string) => Promise<ConnectorSchema>;
  saveConnectionSpec: (spec: ConnectorSchema) => Promise<ConnectorSchema>;
  checkConnection: (connectionConfig: string, sourceTypeId: string) => Promise<CheckConnectionResponse>;
  getPreviewData: (request: PreviewDataRequest, from: number, to: number) => Promise<PreviewDataResponse>;
  //: (connectionConfig: string, sourceTypeId: string) => Promise<DiscoverSourceResponse>;

  getConfiguredSources: (from: number, to: number) => Promise<ConfiguredSources>;
  saveSource: (request: SaveSourceConfig) => Promise<SaveSourceConfig>;
  getConfiguredSourceDetails: (connectorSourceId: string) => Promise<SaveSourceConfig>;
  deleteConfiguredSource: (connectorSourceId: string) => Promise<any>;

  // User service mapping related API's
  getUserServiceMappingRules: (sourceId: string, from: number, to: number) => Promise<UserServiceMappingRulesSummary[]>;
  getUserServiceMappingRuleDetails: (sourceId: string, ruleId: string) => Promise<UserServiceMappingRule>;
  deleteUserServiceMappingRule: (ruleId: string) => Promise<void>;
  getUserServicePreview: (
    request: UserServicePreviewRequest,
    from: number,
    to: number,
    cancelToken: CancelToken,
    enableLiveData?: boolean
  ) => Promise<EventPreviewData>;
  getUserServiceNames: (
    request: UserServicePreviewRequest,
    from: number,
    to: number,
    cancelToken?: CancelToken
  ) => Promise<any>;
  getBicycleEventPreview: (
    previewData: PreviewDataObj,
    previewRequest: BicycleEventPreviewRequest,
    from: number,
    to: number
  ) => Promise<any>;
  saveUserServiceMapping: (configuredStreamId: string, request: UserServiceMappingRule) => Promise<void>;
  getDataTransformationPreview: (
    connectorSourceId: string,
    payload: UserServiceFieldPreviewRequest,
    from: number,
    to: number
  ) => Promise<UserServiceFieldPreviewResponse>;
  cloneMappingRules: (connectorSourceId: string, fromConnectorId: string) => Promise<ClonedMappingRulesResponse>;
  getConnectorStatus: (
    connectorSourceId: string,
    startTimeMillis: number,
    endTimeMillis: number
  ) => Promise<EventConnectorStatusResponse>;
  downloadEventGroupPreview: (EventGroup: EventGroup, startTimeMillis: number, endTimeMillis: number) => Promise<Blob>;
  saveEventConnectionStreamV2: (data: SaveSourceConfig, companyName: string) => Promise<SaveEventSourceConfigResponse>;
  getBicycleEventsPreviewV2(payload: Omit<BicycleEventPreviewRequestV2, "traceInfo">): Promise<string>;
  getMappingWorkflowStatus(connectorSourceId: string): Promise<MappingWorkflowOutput>;
}

class EventMappingApiService implements EventMappingApiServiceInterface {
  readonly SOURCE_TYPES_BASE_URL: string = "/event-mapping/api/v1/config/source-types";

  async getSourceList(type: SourceType): Promise<SourceTypeDef[]> {
    try {
      const response: { data: SourceListResponse } = await datasourceApiManager
        .getDefault()
        .post(`${this.SOURCE_TYPES_BASE_URL}`, {
          sourceType: "EVENT"
        });

      if (response && response.data) {
        return response.data.sourceTypes;
      }
    } catch (e) {
      logger.error("EventMappingApiService", `Error while fetching source list for type: ${type}`, e);
    }

    return [];
  }

  async getConnectionSpec(sourceTypeId: string): Promise<ConnectorSchema> {
    try {
      const response: {
        data: ConnectorSchemaResponse;
      } = await datasourceApiManager.getDefault().get(`${this.SOURCE_TYPES_BASE_URL}/${sourceTypeId}/connector-spec`);

      if (response && response.data) {
        return {
          ...response.data,
          connectionSpecification: JSON.parse(response.data.connectionSpecification)
        };
      }
    } catch (e) {
      logger.error("EventMappingApiService", `Error while fetching connection spec for: ${sourceTypeId}`, e);
    }

    return null;
  }

  async saveConnectionSpec(spec: ConnectorSchema): Promise<ConnectorSchema> {
    try {
      const response: {
        data: CheckConnectionResponse;
      } = await datasourceApiManager.getDefault().post(`${this.SOURCE_TYPES_BASE_URL}/event-source`, spec);

      if (response && response.data) {
        return spec;
      }
    } catch (e) {
      logger.error("EventMappingApiService", `Error while saving connector spec`, e);
    }

    return null;
  }

  async checkConnection(connectionConfig: string, sourceTypeId: string): Promise<CheckConnectionResponse> {
    try {
      const response: {
        data: CheckConnectionResponse;
      } = await datasourceApiManager.getDefault().post(`${this.SOURCE_TYPES_BASE_URL}/check-connection`, {
        connectionConfiguration: connectionConfig,
        sourceTypeId
      });

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      logger.error("EventMappingApiService", `Error while testing connection for: ${sourceTypeId}`, e);
    }

    return null;
  }

  async getConfiguredSources(from: number, to: number): Promise<ConfiguredSources> {
    try {
      const response: {
        data: ConfiguredSources;
      } = await datasourceApiManager
        .getDefault()
        .get(`${this.SOURCE_TYPES_BASE_URL}/configured-sources?st=${from}&et=${to}`);

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      logger.error("EventMappingApiService", `Error while fetching configured sources`, e);
    }

    return null;
  }

  async getConfiguredSourceDetails(connectorSourceId: string): Promise<SaveSourceConfig> {
    try {
      const response: {
        data: SaveSourceConfig;
      } = await datasourceApiManager.getDefault().get(`${this.SOURCE_TYPES_BASE_URL}/${connectorSourceId}/config`);

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      logger.error("EventMappingApiService", `Error while configured source with id ${connectorSourceId}`, e);
    }

    return null;
  }

  async saveSource(request: SaveSourceConfig): Promise<SaveSourceConfig> {
    try {
      const response: {
        data: SaveSourceConfig;
      } = await datasourceApiManager.getDefault().post(`${this.SOURCE_TYPES_BASE_URL}/event-source`, request);

      return response.data;
      // console.log(request);
      // return request;
    } catch (e) {
      logger.error("EventMappingApiService", `Error while saving source`, e);
      throw e;
    }
  }

  async saveEventConnectionStreamV2(
    data: SaveSourceConfig,
    companyName: string
  ): Promise<SaveEventSourceConfigResponse> {
    try {
      const response = await datasourceApiManager
        .getDefault()
        .post(`/event-mapping/api/v1/data-onboarding/save-event-connector`, {
          traceInfo: {
            traceId: uuidV4()
          },
          companyName: companyName,
          connectorStream: data
        });

      return response.data as SaveEventSourceConfigResponse;
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error while saving connection stream");
      throw e;
    }
  }

  async getMappingWorkflowStatus(sourceId: string): Promise<MappingWorkflowOutput> {
    const url = `/event-mapping/api/v1/data-onboarding/get-mapping-workflow-status?configuredConnectorStreamId=${sourceId}`;

    try {
      const response = await datasourceApiManager.getDefault().get(url);

      return response.data as MappingWorkflowOutput;
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error while fetching workflow status");
      throw e;
    }
  }

  async deleteConfiguredSource(connectorSourceId: string): Promise<any> {
    try {
      await datasourceApiManager.getDefault().delete(`${this.SOURCE_TYPES_BASE_URL}/${connectorSourceId}`);
    } catch (e) {
      logger.error("EventMappingApiService", `Error while deleting connector ${connectorSourceId}`, e);
    }
  }

  async getPreviewData(request: PreviewDataRequest, from: number, to: number): Promise<PreviewDataResponse> {
    const eventSourceId = "";

    try {
      const response: {
        data: PreviewDataResponse;
      } = await datasourceApiManager
        .getDefault()
        .post(`${this.SOURCE_TYPES_BASE_URL}/${eventSourceId}/preview?st=${from}&et=${to}`, request);

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      logger.error("EventMappingApiService", `Error while getting preview data`, e);
    }

    return null;
  }

  async getUserServicePreview(
    request: UserServicePreviewRequest,
    from: number,
    to: number,
    cancelToken: CancelToken,
    enableLiveData?: boolean
  ): Promise<EventPreviewData> {
    const eventSourceId = request.previewDataRequest?.connectorSourceId;
    const liveDataEnabled = enableLiveData ?? true;

    try {
      const response: {
        data: EventPreviewData;
      } = await datasourceApiManager
        .getDefault()
        .post(
          `${this.SOURCE_TYPES_BASE_URL}/${eventSourceId}/preview?st=${from}&et=${to}&enableLiveData=${liveDataEnabled}`,
          request.previewDataRequest,
          {
            cancelToken: cancelToken
          }
        );

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      logger.error("EventMappingApiService", `Error while fetching user service preview data`, e);
      throw e;
    }

    return null;
  }

  async saveUserServiceMapping(configuredStreamId: string, request: UserServiceMappingRule): Promise<void> {
    try {
      await datasourceApiManager
        .getDefault()
        .post(`${this.SOURCE_TYPES_BASE_URL}/${configuredStreamId}/user-service-mapping`, request);
    } catch (e) {
      logger.error("EventMappingApiService", `Error while saving user service mapping`, e);
      throw e;
    }
  }

  async getUserServiceMappingRules(
    sourceId: string,
    from: number,
    to: number
  ): Promise<UserServiceMappingRulesSummary[]> {
    try {
      const response: {
        data: {
          rules: UserServiceMappingRulesSummary[];
        };
      } = await datasourceApiManager
        .getDefault()
        .get(`${this.SOURCE_TYPES_BASE_URL}/${sourceId}/mapping-rules?st=${from}&et=${to}`);

      if (response && response.data && response.data.rules) {
        return response.data.rules;
      }
    } catch (e) {
      logger.error("EventMappingApiService", `Error while fetching configured sources`, e);
    }

    return [];
  }

  async getUserServiceMappingRuleDetails(sourceId: string, ruleId: string): Promise<UserServiceMappingRule> {
    try {
      const response: {
        data: UserServiceMappingRule;
      } = await datasourceApiManager
        .getDefault()
        .get(`${this.SOURCE_TYPES_BASE_URL}/${sourceId}/mapping-rules/${ruleId}`);

      if (response && response.data) {
        //set default value on userServiceFields if not exists or empty
        //this happens especially on OOTB rules
        if (isEmpty(response.data.userServiceFields)) {
          response.data.userServiceFields = {
            commonFields: [],
            userServiceFields: {}
          };
        }
        return response.data;
      }
    } catch (e) {
      logger.error("EventMappingApiService", `Error while fetching configured sources`, e);
    }

    return null;
  }

  deleteUserServiceMappingRule(ruleId: string): Promise<void> {
    try {
      datasourceApiManager.getDefault().delete(`${this.SOURCE_TYPES_BASE_URL}/mapping-rules/${ruleId}`);
    } catch (e) {
      logger.error("EventMappingApiService", `Error while deleting rule`, e);
      throw e;
    }

    return null;
  }

  async getUserServiceNames(
    request: UserServicePreviewRequest,
    from: number,
    to: number,
    cancelToken?: CancelToken
  ): Promise<any> {
    const eventSourceId = request.previewDataRequest?.connectorSourceId;

    try {
      const response: {
        data: UserServiceNameResponse;
      } = await datasourceApiManager.getDefault().post(
        `${this.SOURCE_TYPES_BASE_URL}/${eventSourceId}/user-service-preview?st=${from}&et=${to}&enableLiveData=false`,
        {
          ...request,
          userServiceMappingRule: {
            userServiceNaming: request.userServiceMappingRule
          }
        },
        {
          cancelToken: cancelToken
        }
      );

      if (response && response.data) {
        //return { userServiceToPreviewData: null };
        return response.data;
      }
    } catch (e) {
      logger.error("EventMappingApiService", `Error while fetching user service preview data`, e);
      throw e;
    }

    return null;
  }

  async getDataTransformationPreview(
    connectorSourceId: string,
    payload: UserServiceFieldPreviewRequest,
    from: number,
    to: number
  ) {
    try {
      const response = await datasourceApiManager
        .getDefault()
        .post<
          UserServiceFieldPreviewResponse,
          UserServiceFieldPreviewRequest
        >(`${this.SOURCE_TYPES_BASE_URL}/${connectorSourceId}/data-transformation-preview?st=${from}&et=${to}`, payload);

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      logger.error("EventMappingApiService", `Error while fetching data transformation preview data`, e);
    }

    return null;
  }

  async getBicycleEventPreview(
    previewData: PreviewDataObj,
    previewRequest: BicycleEventPreviewRequest,
    from: number,
    to: number
  ): Promise<any> {
    const connectorSourceId = previewRequest?.previewDataRequest?.connectorSourceId;
    try {
      const response = await datasourceApiManager
        .getDefault()
        .post(`${this.SOURCE_TYPES_BASE_URL}/${connectorSourceId}/bicycle-event-preview?st=${from}&et=${to}`, {
          previewRequest: previewRequest,
          previewData: previewData
        });
      if (response && response?.data) {
        return response.data;
      }
    } catch (e) {
      logger.error("EventMappingApiService", "Error fetching bicycle event preview data", e);
      throw e;
    }

    return null;
  }

  async cloneMappingRules(connectorSourceId: string, fromConnectorId: string) {
    ///event-mapping/api/v1/config/source-types/{connectorSourceId}/clone-mapping-rules?fromConnectorId=<fromConnectorId>
    try {
      const response = await datasourceApiManager
        .getDefault()
        .get(
          `${this.SOURCE_TYPES_BASE_URL}/${connectorSourceId}/clone-mapping-rules?fromConnectorId=${fromConnectorId}`
        );
      if (response && response?.data) {
        return response.data as ClonedMappingRulesResponse;
      }
    } catch (e) {
      logger.error("EventMappingApiService", `Error while cloning configured source`, e);
      throw e;
    }

    return null;
  }

  async getConnectorStatus(
    connectorSourceId: string,
    startTimeMillis: number,
    endTimeMillis: number
  ): Promise<EventConnectorStatusResponse> {
    try {
      const url = `/event-mapping/api/v1/connectors/status?st=${startTimeMillis}&et=${endTimeMillis}`;
      const response: { data: EventConnectorStatusResponse } = await datasourceApiManager.getDefault().post(url, {
        streamId: [connectorSourceId],
        traceInfo: {
          traceId: uuidV4()
        }
      });
      return response && response.data;
    } catch (e) {
      logger.error("EventMappingApiService", "Error fetch connection status", e);
    }

    return null;
  }

  async downloadEventGroupPreview(
    eventGroup: EventGroup,
    startTimeMillis: number,
    endTimeMillis: number
  ): Promise<Blob> {
    try {
      const url = `/event-mapping/api/v1/auto-discovery/event-group-export-html?st=${startTimeMillis}&et=${endTimeMillis}`;
      const response = await datasourceApiManager.getDefault().post<Blob, EventGroup>(url, eventGroup);
      if (response.data) {
        return new Blob([response.data], { type: "plain/text" });
      }
    } catch (e) {
      logger.error("EventMappingApiService", "failed to download event group preview data", e);
    }
    return null;
  }

  async getBicycleEventsPreviewV2(payload: Omit<BicycleEventPreviewRequestV2, "traceInfo">): Promise<string> {
    try {
      const url = "/event-mapping/api/v1/data-onboarding/get-bicycle-preview-events-html";
      const response = await datasourceApiManager.getDefault().post<string, BicycleEventPreviewRequestV2>(url, {
        ...payload,
        traceInfo: {
          traceId: uuidV4()
        }
      });

      if (response) {
        return response.data;
      }
    } catch (e) {
      logger.error("EventMappingApiService", "failed to fetch events preview");
      throw e;
    }
    return null;
  }
}

const eventMappingApiService: EventMappingApiServiceInterface = new EventMappingApiService();
export default eventMappingApiService;
