import { IncSelectOption } from "@inception/ui";
import { PostAggProjection, ForecastProjection } from "../../../services/api/explore";

export const customTrOption: IncSelectOption = {
  label: "Specific",
  value: "custom"
};

export const offsetTrOption: IncSelectOption = {
  label: "Offset",
  value: "offset"
};

export const automaticTrOption: IncSelectOption = {
  label: "Automatic",
  value: "automatic"
};

export const trOptions = [customTrOption, offsetTrOption, automaticTrOption];

export const customDsOption: IncSelectOption = {
  ...customTrOption
};

export const automaticDsOption: IncSelectOption = {
  label: "Automatic",
  value: "automatic"
};

export const dsOptions = [customDsOption, automaticDsOption];

export const currentProjOption: IncSelectOption<PostAggProjection> = {
  label: "Current",
  value: "current",
  data: "current"
};

export const timeShiftProjOption: IncSelectOption<PostAggProjection> = {
  label: "Time shift",
  value: "timeShift",
  data: "timeShift"
};

export const perChangeProjOption: IncSelectOption<PostAggProjection> = {
  label: "Percent change",
  value: "deltaPercentage",
  data: "deltaPercentage"
};

export const projOptions = [currentProjOption, timeShiftProjOption, perChangeProjOption];

export const getForecastDataOption = (): IncSelectOption<ForecastProjection> => ({
  label: "Current",
  value: ForecastProjection.forecast,
  data: ForecastProjection.forecast
});

export const getForecastDeltaOption = (): IncSelectOption<ForecastProjection> => ({
  label: "Delta",
  value: ForecastProjection.forecastDelta,
  data: ForecastProjection.forecastDelta
});

export const getForecastPerDeltaOption = (): IncSelectOption<ForecastProjection> => ({
  label: "Delta percentage",
  value: ForecastProjection.forecastDeltaPerc,
  data: ForecastProjection.forecastDeltaPerc
});

export const getForecastLowerOption = (): IncSelectOption<ForecastProjection> => ({
  label: "Lower",
  value: ForecastProjection.forecastLower,
  data: ForecastProjection.forecastLower
});

export const getForecastUpperOption = (): IncSelectOption<ForecastProjection> => ({
  label: "Upper",
  value: ForecastProjection.forecastUpper,
  data: ForecastProjection.forecastUpper
});

export const getForecastProjOptions = () => [
  getForecastDataOption(),
  getForecastDeltaOption(),
  getForecastPerDeltaOption(),
  getForecastLowerOption(),
  getForecastUpperOption()
];
