import { IncAsyncSelect, IncSelectOption } from "@inception/ui";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { logger, useTimeRange } from "../../core";
import { FieldPickerOptionData, ConditionWithPickerType } from "../../services/api/explore";
import { entityEnricherRegistry } from "../../utils";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { getFieldValueCompletion } from "./ExploreAutocompleterHook";

interface AutocompleteProps {
  context: FieldPickerOptionData;
  tag: string;
  showLabel?: boolean;
  dependentFilters?: ConditionWithPickerType[];
  selected?: string | string[];
  onChange: (selected: string | string[]) => void;
  isMulti?: boolean;
  placeholder?: string;
  debounceIntervalMillis?: number;
  readOnly?: boolean;
}

type Callback = (options: IncSelectOption[]) => void;

const wrapInQuotesFn = (s: string) => `"${s}"`;
const LIMIT = 100;

export const AutoCompleter = (props: AutocompleteProps) => {
  const {
    showLabel = true,
    onChange,
    selected = undefined,
    context,
    tag = null,
    isMulti = false,
    dependentFilters,
    placeholder,
    debounceIntervalMillis = 500,
    readOnly = false
  } = props;

  const shouldLoadOptionsAsyncRef = useRef(true);
  const optionsInitialisedRef = useRef(false);
  const typedValue = useRef("");

  const [defaultOptions, setDefaultOptions] = useState<IncSelectOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<IncSelectOption>(undefined);
  const [selectedOptions, setSelectedOptions] = useState<IncSelectOption[]>([]);

  const { timeRange } = useTimeRange();
  const { fromMillis, toMillis } = useMemo(() => timeRangeUtils.getMillisFromTimeRange(timeRange), [timeRange]);

  const [isFetching, setIsFetching] = useState(false);

  const fetchOptions = useCallback(
    async (searchText: string) => {
      if (!readOnly) {
        setIsFetching(true);
        try {
          const { data, error, message } = await getFieldValueCompletion(
            context,
            tag,
            fromMillis,
            toMillis,
            searchText,
            LIMIT,
            dependentFilters
          );

          if (!error) {
            const {
              fieldValues: { entityLookupData, values },
              wrapValueInQuotes
            } = data;
            const options: IncSelectOption[] = values.map(s => ({
              value: context?.type !== "bizEntityField" && wrapValueInQuotes && s !== "null" ? wrapInQuotesFn(s) : s,
              label: entityLookupData[s] ? entityLookupData[s] : s
            }));

            setIsFetching(false);
            return options;
          } else {
            logger.error("Explore AutoCompleter", "Error fetching autocomplete values", message);
            setIsFetching(false);
            return [];
          }
        } catch (e) {
          logger.error("Explore AutoCompleter", "Error fetching autocomplete values", e);
          setIsFetching(false);
          return [];
        }
      } else {
        return [];
      }
    },
    [context, dependentFilters, fromMillis, readOnly, tag, toMillis]
  );

  const initialiseOptions = useCallback(async () => {
    const defOptions = await fetchOptions("");
    shouldLoadOptionsAsyncRef.current = defOptions.length >= LIMIT;
    setDefaultOptions(defOptions);
  }, [fetchOptions]);

  useMemo(() => {
    if (tag) {
      setDefaultOptions(prev => (prev.length ? [] : prev));
      optionsInitialisedRef.current = false;
    }
  }, [tag]);

  useEffect(() => {
    if (!defaultOptions?.length && context && tag && !optionsInitialisedRef.current) {
      initialiseOptions();
      optionsInitialisedRef.current = true;
    } else if (defaultOptions?.length) {
      optionsInitialisedRef.current = true;
    }
  }, [context, defaultOptions, initialiseOptions, tag]);

  const removeQuotes = useCallback(
    (value: string) => {
      let withoutQuotes = value; // initialize default
      if (context?.type !== "bizEntityField") {
        const match = new RegExp('^\\"(.*)\\"$', "g").exec(value as string);
        if (match) {
          withoutQuotes = match[1];
        }
      }
      return withoutQuotes;
    },
    [context]
  );

  useEffect(() => {
    if (selected && (!selectedOption || selectedOption.value !== selected) && !isFetching) {
      if (Array.isArray(selected)) {
        const selectedOptions: IncSelectOption[] = [];
        const newOptions: IncSelectOption[] = [];
        selected.forEach(value => {
          const selectOption = defaultOptions.find(o => o.value === value);
          if (selectOption) {
            selectedOptions.push(selectOption);
          } else {
            const withoutQuotes = removeQuotes(value);
            const selectIfEmpty: IncSelectOption = {
              value: value as string,
              label: entityEnricherRegistry.lookupEntityCache(withoutQuotes as string)
            };
            newOptions.push(selectIfEmpty);
            selectedOptions.push(selectIfEmpty);
          }
        });

        setSelectedOptions(selectedOptions);
      } else {
        const withoutQuotes = removeQuotes(selected);
        const selectOption = defaultOptions.find(o => o.value === selected);
        const selectIfEmpty: IncSelectOption = {
          value: selected as string,
          label: entityEnricherRegistry.lookupEntityCache(withoutQuotes as string)
        };

        setSelectedOption(selectOption || selectIfEmpty);
      }
    }

    if (!selected) {
      setSelectedOption(null);
    }
  }, [context, isFetching, selected, selectedOption, removeQuotes, defaultOptions]);

  const allOptions = useMemo(() => {
    const allOptions = [...defaultOptions];

    if (shouldLoadOptionsAsyncRef.current) {
      selectedOption && allOptions.push(selectedOption);
      selectedOptions?.length && allOptions.push(...selectedOptions);
    }

    return allOptions;
  }, [defaultOptions, selectedOption, selectedOptions]);

  const debouncedOnChange = useMemo(() => debounce(onChange, 100), [onChange]);
  const onSelectionChange = useCallback(
    (option: IncSelectOption | IncSelectOption[]) => {
      if (Array.isArray(option)) {
        debouncedOnChange(option.map(x => x.value));
      } else {
        debouncedOnChange(option?.value);
      }
    },
    [debouncedOnChange]
  );

  const debouncedLoadOptions = useMemo(
    () =>
      debounce((inputValue: string, callback: Callback) => {
        //comparing the value from ref and forcing the fetch options
        if (inputValue !== typedValue.current) {
          fetchOptions(inputValue).then((options: IncSelectOption[]) => {
            callback(options);
            typedValue.current = inputValue;
          });
        } else {
          const filOptions = allOptions.filter(x => x.label.toLowerCase().startsWith(inputValue.toLowerCase()));
          callback(filOptions);
        }
      }, debounceIntervalMillis),
    [allOptions, debounceIntervalMillis, fetchOptions]
  );

  const loadOptions = useCallback(
    (inputValue: string, callback: Callback) => {
      debouncedLoadOptions(inputValue, callback);
    },
    [debouncedLoadOptions]
  );

  return (
    <IncAsyncSelect
      allowCreate
      defaultOptions={allOptions}
      isClearable={!readOnly}
      isLoading={isFetching}
      isMulti={isMulti}
      label={showLabel ? "Value" : ""}
      loadOptions={loadOptions}
      menuPortalTarget={document.body}
      onChange={onSelectionChange as any}
      placeholder={placeholder ? "Select" : "Choose value"}
      readOnly={readOnly}
      value={isMulti ? selectedOptions : selectedOption}
      wrapperClass="width-100"
    />
  );
};
