import { IncModal } from "@inception/ui";
import React, { useCallback } from "react";
import { LowLevelFragmentList, ConversationStatus, LowLevelFragment } from "../../services/api/chat";
import { FragmentRendererV2 } from "./FragmentRendererV2";

interface ModalProps {
  show: boolean;
  onClose: () => void;
  objectFragmentList: LowLevelFragmentList;
  header: string;
  onUpdateFragmentList: (objectFragmentList: LowLevelFragmentList) => void;
  onActionClick: () => void;
  status: ConversationStatus;
}

export const ObjectActionModal: React.FC<ModalProps> = (props: ModalProps) => {
  const { show, onClose, objectFragmentList, header, onActionClick, onUpdateFragmentList, status } = props;
  const { lowLevelFragment } = objectFragmentList;

  const onUpdateFragment = useCallback(
    (fragment: LowLevelFragment, idx: number) => {
      const newFragmentList = [...(lowLevelFragment || [])];
      newFragmentList[idx] = fragment;
      onUpdateFragmentList({
        lowLevelFragment: newFragmentList
      });
    },
    [lowLevelFragment, onUpdateFragmentList]
  );

  return (
    <IncModal
      className="object-fragment-modal"
      onClose={onClose}
      show={show}
      size="side-pane"
      titleText={header}
      withTitleBorder
    >
      <div className="inc-flex-column flex-gap-16 height-100">
        {lowLevelFragment.map((x, idx) => {
          const onUpdate = (fragment: LowLevelFragment) => onUpdateFragment(fragment, idx);
          return (
            <FragmentRendererV2
              key={idx}
              onActionClick={onActionClick}
              onUpdateFragment={onUpdate}
              status={status}
              subStep={x}
            />
          );
        })}
      </div>
    </IncModal>
  );
};
