/* eslint-disable @typescript-eslint/no-namespace */
import { IncFaIconName, ISaxIconProps } from "@inception/ui";
import { AgentChatTypes } from "../../chat";
import { IncidentSummaryCauseType } from "../../use-case-catalog";
import { DrilldownTypes } from "./drilldown";

export namespace TriageSummaryTypes {
  export interface InsightSummary {
    insightMeta: InsightMeta;
    overallSummary: string;
    impactSummary: string;
    causeSummary: CauseSummary[];
    insightVisualStory: InsightVisualStory;
    allowedCauseTypeInfo: CauseTypeInfo[];

    id?: string;
    causeGraphInternal?: DrilldownTypes.CauseGraph;
    causeGraphExecutionResult?: DrilldownTypes.CauseGraphExecutionResult;
  }

  export interface InsightVisualStory {}

  export interface CauseSummary {
    cause: string;
    causeTitle: string;
    causeTypeInfo: CauseTypeInfo;
    icon: IncFaIconName | ISaxIconProps["iconName"];
    actionsTaken: Actions[];
    possibleActions: Actions[];
    source: AgentChatTypes.Source;
    shortSummary: string;
  }

  export interface CauseTypeInfo {
    typeHeader: string;
    causeType: IncidentSummaryCauseType;
    icon: IncFaIconName | ISaxIconProps["iconName"];
    possibleActions: Actions[];
  }

  export interface Actions {
    actionType: string;
    description: string;
    displayText: string;
    faIcon: IncFaIconName | ISaxIconProps["iconName"];
  }

  export enum TriageStatus {
    status_unknown = "status_unknown",
    completed = "completed",
    in_progress = "in_progress"
  }

  export interface InsightMeta {
    insightId: string;
    opId: string;
    lookup: Record<string, string>;
  }

  export interface TriageStatusResponse {
    status: TriageStatus;
    insightSummary: InsightSummary;
    pollId?: string;
  }
}
