import { useMemo, useEffect, useRef, useState } from "react";
import { groupBy, isEqual } from "lodash";
import {
  WidgetResponseDTO,
  CohortEntityFilter,
  MetricUserServiceFilters,
  SliceSpec,
  SliceSet,
  MetricResultDataDTO
} from "../../../../services/api/explore";
import { TimeRange, logger } from "../../../../core";
import { ExploreQueryType } from "../../../../services/datasources/explore/types";
import { DataQueryError, DataQueryResponse } from "../../../../services/api/types";
import { EntityWidgetData, extractMetricInfoFromEntityWidgetData } from "../../../../biz-entity";
import { USFWQueryConfigSourceType } from "../models";
import timeRangeUtils from "../../../../utils/TimeRangeUtils";
import { AUTO_DS_INTERVAL } from "../../utils";
import { useFetchUSFWData } from "./useFetchData";

type Props = {
  id: string;
  widgetResponseDTO: WidgetResponseDTO;
  entityType: string;
  cohortFilters: CohortEntityFilter[];
  entityFilters: CohortEntityFilter[];
  timeRange: TimeRange;
  userServiceId: string;
  limit: number;
  eventFilters?: MetricUserServiceFilters;
  mode: ExploreQueryType;
  filtersExist?: boolean;
  widgetName: string;
  aggregateTags: string[];
  sourceType: USFWQueryConfigSourceType;
};

const MAX_RETRIES = 0;

export const useGetResultMetaAndDQConfig = (props: Props) => {
  const {
    cohortFilters,
    entityFilters,
    entityType,
    timeRange,
    eventFilters,
    widgetResponseDTO,
    mode,
    id,
    widgetName,
    sourceType,
    aggregateTags
  } = props;

  const isFieldSourceQuery = sourceType === "userServiceField";

  const [state, setState] = useState<State>({
    dataQueryConfig: {},
    resultMeta: [],
    dataError: ""
  });

  const timeInSeconds = useMemo(() => {
    const diffMillis = (timeRange?.to?.valueOf() || 0) - (timeRange?.from?.valueOf() || 0);
    return timeRangeUtils.getSecondsFromMillis(diffMillis);
  }, [timeRange]);

  const prevSliceSpecRef = useRef<SliceSpec[]>();
  const prevTimeRangeRef = useRef(timeRange);
  const retryCounterRef = useRef(0);

  const nameRef = useRef(widgetName);
  useMemo(() => {
    nameRef.current = widgetName;
  }, [widgetName]);

  const { querySchema } = widgetResponseDTO || {};

  const sliceSpec = useMemo(() => {
    const qsArr = querySchema?.querySchema || [];
    if (qsArr.length) {
      const sliceSpec: SliceSpec[] = [];
      const qsByMetricId = groupBy(qsArr, "metricId");

      const metricIds = Object.keys(qsByMetricId);
      metricIds.forEach(metricId => {
        let sliceSet: SliceSet;
        let timeAgg = "avg";
        let tagAgg = "avg";

        const entries = qsByMetricId[metricId];
        entries.forEach(qs => {
          const { sliceSet: qsSliceSet, defaultTagAgg, defaultTimeAgg } = qs;

          const currSliceCount = sliceSet?.slices?.length || 0;
          const qsSliceCount = qsSliceSet?.slices?.length || 0;

          if (qsSliceCount > currSliceCount) {
            sliceSet = qsSliceSet;
            tagAgg = defaultTagAgg;
            timeAgg = defaultTimeAgg;
          }
        });

        const tagNames = isFieldSourceQuery ? aggregateTags || [] : sliceSet?.slices?.map(sl => sl.tagName) || [];

        sliceSpec.push({
          selectorSpec: {
            filters: []
          },
          sliceSet,
          metricId,
          postAgg: {
            overTagAgg: {
              aggregator: tagAgg,
              tagName: tagNames
            },
            overTimeAgg: {
              aggregator: timeAgg,
              timeInSeconds
            },
            projections: ["current"],
            isSingleStatQuery: true,
            isSchemaQuery: true
          }
        });
      });

      return sliceSpec;
    }

    return null;
  }, [aggregateTags, isFieldSourceQuery, querySchema?.querySchema, timeInSeconds]);

  const { isFetching, isError, data, refetch, error } = useFetchUSFWData({
    ...props,
    id: `result-meta-dqconfig-${id}`,
    limit: 1,
    mode,
    sliceSpec,
    includeQueryConfig: true,
    dsIntervalStr: AUTO_DS_INTERVAL
  });

  const nCompareSS = (sliceSpec || []).map(ss => {
    const cSS = {
      ...ss
    };
    delete cSS.postAgg;

    return cSS;
  });

  const pCompareSS = (prevSliceSpecRef.current || []).map(ss => {
    const cSS = {
      ...ss
    };
    delete cSS.postAgg;

    return cSS;
  });

  const sliceSpecChanged = !isEqual(nCompareSS, pCompareSS);
  const timeRangeChanged = !isEqual(prevTimeRangeRef.current, timeRange);
  const shouldFetch =
    (sliceSpecChanged && Boolean(sliceSpec)) ||
    !state?.resultMeta?.length ||
    timeRangeChanged ||
    (isFieldSourceQuery ? Boolean(aggregateTags) : true);

  useEffect(() => {
    if (shouldFetch && Boolean(sliceSpec)) {
      refetch();
    }
  }, [refetch, sliceSpec, cohortFilters, entityFilters, entityType, timeRange, eventFilters, shouldFetch]);

  useEffect(() => {
    if (shouldFetch && !isFetching) {
      let setResultMetaSuccess = false;

      if (data?.length) {
        const { entityWidgetData, dataError } = getParsedData(data);

        const dataExists =
          !isError &&
          entityWidgetData?.length > 0 &&
          (entityWidgetData[0]?.dataSize > 0 || entityWidgetData[0]?.postAggDataSize > 0);

        const resultMeta = dataExists ? extractMetricInfoFromEntityWidgetData(entityWidgetData, true) : [];
        const dataQueryConfig = entityWidgetData?.length ? entityWidgetData[0].dataQueryConfig : {};

        if (resultMeta.length) {
          prevSliceSpecRef.current = sliceSpec;
          setResultMetaSuccess = true;

          if (retryCounterRef.current) {
            logger.info(
              "Result meta for filters",
              `Result meta found for ${nameRef.current} in retry ${retryCounterRef.current}`,
              resultMeta
            );
            retryCounterRef.current = 0;
          }
        } else {
          logger.warn("Result meta for filters", `No result meta found for ${nameRef.current}`, data);
        }

        if (isFieldSourceQuery && resultMeta) {
          resultMeta.forEach(rm => (rm.resultSeriesName = widgetName));
        }

        setState({
          resultMeta,
          dataQueryConfig,
          dataError
        });
      }

      if (retryCounterRef.current < MAX_RETRIES && !setResultMetaSuccess) {
        retryCounterRef.current += 1;
        logger.warn(
          "Result meta for filters",
          `No result meta found for ${nameRef.current}. Retry ${retryCounterRef.current}`,
          data
        );
        setState(prev => ({
          ...prev
        }));
      }
    }
  }, [data, isError, isFetching, isFieldSourceQuery, shouldFetch, sliceSpec, widgetName]);

  return {
    resultMeta: state.resultMeta,
    dataQueryConfig: state.dataQueryConfig,
    isResultMetaFetching: isFetching,
    isResultMetaError: isError,
    resultMetaError: state.dataError || error
  };
};

type State = {
  resultMeta: MetricResultDataDTO[];
  dataQueryConfig: Record<string, any>;
  dataError: string;
};

const getParsedData = (data: Array<DataQueryResponse<EntityWidgetData[]>>) => {
  const fData = data || [];

  let dataErrObj: DataQueryError = {
    data: null,
    message: ""
  };

  const entityWidgetData: EntityWidgetData[] = [];

  fData.forEach(d => {
    const { data = [], error } = d;
    dataErrObj = dataErrObj || error;
    entityWidgetData.push(...data);
  });

  const dataError = dataErrObj?.message || "";

  return {
    entityWidgetData,
    dataError
  };
};
