import {
  generateId,
  IncButton,
  IncCollapsible,
  IncDuration,
  IncFaIcon,
  IncSelect,
  IncSelectOption,
  IncToolTip
} from "@inception/ui";
import IncDurationSelector, {
  durationToString,
  IncSelectorDuration,
  stringToDuration
} from "@inception/ui/src/components/DurationSelector/DurationSelector";
import { cloneDeep, isEqual, last, uniqBy } from "lodash";
import React, { FC, useMemo, useCallback, useEffect, useState, MutableRefObject, CSSProperties, useRef } from "react";
import { css, cx } from "emotion";
import { getDefaultMetricWithEventTypeAndId } from "../../../../../../../biz-entity";
import { VerticallyCenteredRow, FieldPickerContainer } from "../../../../../../../components";
import { logger, useRefState, useTimeRange, useVerticalConfig } from "../../../../../../../core";
import { FieldPickerModel, useFieldPicker } from "../../../../../../../field-picker";
import {
  FieldPickerOptionData,
  FieldPickerContextDTO,
  UserServiceFieldSlice,
  UserServiceField,
  compareUSFields,
  ExploreUserService,
  DemoDataParams,
  WidgetConfigDTO,
  WidgetConfigUtils
} from "../../../../../../../services/api/explore";
import { ENTITY_TAG, FieldPickerUtils } from "../../../../../../../utils";
import timeRangeUtils from "../../../../../../../utils/TimeRangeUtils";
import { RelativeDurationType } from "../../../../../../models/BaseWidgetModel";
import { DataEditorState, DataEditorStateEntry } from "../types";
import { TableProperties } from "../../../../models";
import { ALL_USERSERVICES_ENTITY_TYPE_ID, getDtoFromWidgetConfig } from "../../../../../../../utils/ExploreUtils";
import { KPI } from "../../../../../../../services/api";
import { getDataEditorStateFromWidgetConfigDto } from "../utils";
import { QueryEditor } from "./QueryEditor";

interface Props {
  showBreakdown: boolean;
  eventTypes: ExploreUserService[];
  bizEntityType?: string;
  tableProperties: TableProperties;
  updateTableProperties: (tableProperties: TableProperties) => void;

  metricsState: DataEditorState;
  setMetricsState: (setStateFn: (metricsState: DataEditorState) => DataEditorState) => void;

  downsample: string;
  onDownsampleChange: (downsample: string) => void;

  isEditable: boolean;
  addMetricLabel?: string;
  metricNameLabel?: string;
  metricDescriptionLabel?: string;
  metricLabel?: string;

  demoDataParams?: DemoDataParams;
  disableUSFieldCheckRef: MutableRefObject<boolean>;
  noCollapseMetrics?: boolean;
  hideComponentMetricsGroupBy?: boolean;
  singleMetricMode?: boolean;
  hideMetricCustomisation?: boolean;
  skipKpiMetricOptions?: boolean;

  children?: JSX.Element | JSX.Element[];
}

export const QueryEditorWrapper: FC<Props> = props => {
  const {
    showBreakdown,
    eventTypes,
    bizEntityType = "",
    metricsState,
    setMetricsState,
    isEditable,
    demoDataParams,
    children,
    addMetricLabel = "Add Metric",
    metricNameLabel = "Metric Name",
    metricDescriptionLabel = "Metric Description",
    metricLabel = "Metrics",
    downsample,
    onDownsampleChange: updateDownsample,
    noCollapseMetrics = false,
    hideComponentMetricsGroupBy = false,
    hideMetricCustomisation = false,
    singleMetricMode = false,
    tableProperties,
    updateTableProperties,
    skipKpiMetricOptions = false
  } = props;

  const { useCaseApi } = useVerticalConfig();
  const { getKpisForSelectedUseCases } = useCaseApi;

  const kpis = useMemo(() => {
    if (skipKpiMetricOptions) {
      return [];
    }

    return getKpisForSelectedUseCases();
  }, [getKpisForSelectedUseCases, skipKpiMetricOptions]);

  const { timeRange } = useTimeRange();

  const eventTypeIds = useMemo(() => eventTypes.map(us => us.entityId), [eventTypes]);

  const [selectedSliceOptions, setSelectedSliceOptions] = useState<FieldPickerOptionData[]>();

  const fieldPickerContextDto = useMemo<FieldPickerContextDTO>(() => {
    if (bizEntityType) {
      return {
        entityId: "",
        entityName: "",
        entityType: bizEntityType,
        showFields: true,
        userServices: eventTypeIds.map(eventTypeId => ({
          userServiceEntityId: eventTypeId
        }))
      };
    } else if (eventTypeIds.length === 1) {
      return {
        entityId: eventTypeIds[0],
        entityName: "",
        entityType: "",
        showFields: true,
        userServices: []
      };
    } else {
      return {
        entityId: "",
        entityName: ALL_USERSERVICES_ENTITY_TYPE_ID,
        entityType: ALL_USERSERVICES_ENTITY_TYPE_ID,
        showFields: true,
        userServices: []
      };
    }
  }, [bizEntityType, eventTypeIds]);

  const { data: fieldPickerModel, error, getFields, isError, isFetching } = useFieldPicker();

  useEffect(() => {
    const { from, to } = timeRangeUtils.getTimeRangeMillisFromRaw(timeRange.raw);
    getFields(fieldPickerContextDto, from, to, false, demoDataParams);
  }, [demoDataParams, fieldPickerContextDto, getFields, timeRange.raw]);

  useEffect(() => {
    if (!isFetching && isError) {
      logger.error("QueryEditorWrapper", "Error fetching fields", error);
    }
  }, [error, isError, isFetching]);

  useEffect(() => {
    setSelectedSliceOptions(prev => {
      if (!prev) {
        let selectedSliceOptions: FieldPickerOptionData[] = [];

        metricsState.forEach(metricState => {
          const { metricsWithEventTypeAndId } = metricState;
          const lastSliceSets = metricsWithEventTypeAndId
            .map(({ metricConfig }) => last(metricConfig?.sliceSets))
            .filter(Boolean);
          const sliceSet = lastSliceSets.reduce(
            (acc, sliceSet) => (acc?.slices?.length > sliceSet?.slices?.length ? acc : sliceSet),
            null
          );
          const slices = sliceSet?.slices || [];

          slices.forEach(slice => {
            selectedSliceOptions.push({
              type: "userServiceField",
              payload: slice.userServiceField
            });
          });
        });

        selectedSliceOptions = uniqBy(selectedSliceOptions, option => {
          const usField = option.payload as UserServiceField;
          return FieldPickerUtils.getPromSanitizedUSFName(usField);
        });

        return selectedSliceOptions;
      }

      return prev;
    });
  }, [metricsState]);

  const updateHeaderDescriptions = useCallback(
    (metricId: string, description: string) => {
      updateTableProperties({
        ...tableProperties,
        metricHeaderDescriptions: {
          ...tableProperties.metricHeaderDescriptions,
          [metricId]: description
        }
      });
    },
    [tableProperties, updateTableProperties]
  );

  const sliceOptionsRef = useRefState(selectedSliceOptions);
  const onSlicesChange = useCallback(
    (nextSliceOptions: FieldPickerOptionData[], saveChanges: boolean) => {
      if (saveChanges) {
        const prevSliceOptions = sliceOptionsRef.current;
        if (!isEqual(prevSliceOptions, nextSliceOptions)) {
          const newOptions = nextSliceOptions.filter(
            option =>
              option.type === "userServiceField" &&
              !prevSliceOptions.find(
                o =>
                  o.type === "userServiceField" &&
                  compareUSFields(o.payload as UserServiceField, option.payload as UserServiceField)
              )
          );

          const entityUSField = fieldPickerModel?.getEntityUSField();
          const slices = getSlicesFromOptions(nextSliceOptions, entityUSField);
          const newSlices = getSlicesFromOptions(newOptions, entityUSField);
          const tagNamesSet = slices.reduce((acc, curr) => acc.add(curr.tagName), new Set<string>());

          setMetricsState(prev => {
            const next = prev.map(metricState => {
              const nextMetricState = { ...metricState };
              nextMetricState.metricsWithEventTypeAndId = metricState.metricsWithEventTypeAndId.map(metricInfo => {
                const nextSliceSets = [...metricInfo.metricConfig.sliceSets];
                const lastIdx = Math.max(nextSliceSets.length - 1, 0);

                if (!metricState.hasExpression) {
                  nextSliceSets[lastIdx] = {
                    slices
                  };
                } else {
                  nextSliceSets[lastIdx] = nextSliceSets[lastIdx] || {
                    slices
                  };
                  nextSliceSets[lastIdx] = {
                    slices: nextSliceSets[lastIdx].slices.filter(sl => tagNamesSet.has(sl.tagName))
                  };
                  nextSliceSets[lastIdx] = {
                    slices: [...nextSliceSets[lastIdx].slices, ...newSlices]
                  };
                }

                return {
                  ...metricInfo,
                  metricConfig: {
                    ...metricInfo.metricConfig,
                    sliceSets: nextSliceSets
                  }
                };
              });

              return nextMetricState;
            });

            return isEqual(next, prev) ? prev : next;
          });
        }
      }

      setSelectedSliceOptions(nextSliceOptions);
    },
    [fieldPickerModel, setMetricsState, sliceOptionsRef]
  );

  const updateMetricState = useCallback(
    (metricState: DataEditorStateEntry) => {
      setMetricsState(prev => {
        const next = [...prev];
        const idx = next.findIndex(m => m.uniqId === metricState.uniqId);
        next[idx] = metricState;
        return next;
      });
    },
    [setMetricsState]
  );

  const onDeleteMetric = useCallback(
    (idx: number) => {
      setMetricsState(prev => {
        const next = [...prev];
        next.splice(idx, 1);
        return next;
      });
    },
    [setMetricsState]
  );

  const onCloneMetric = useCallback(
    (idx: number) => {
      setMetricsState(prev => {
        const next = [...prev];

        const newMetric = cloneDeep(next[idx]);
        newMetric.exprMetricId = newMetric.exprMetricId ? generateId() : newMetric.exprMetricId;
        newMetric.metricsWithEventTypeAndId.forEach(m => (m.configId = generateId()));
        newMetric.uniqId = generateId();
        newMetric.metricName = `[CLONE] ${newMetric.metricName}`;

        next.splice(idx + 1, 0, newMetric);
        return next;
      });
    },
    [setMetricsState]
  );

  const kpiIdToWidgetConfigRef = useRef<Record<string, WidgetConfigDTO>>({});
  const getDataEditorStateEntryForKpi = useCallback(async (kpi: KPI) => {
    let widgetConfigDto: WidgetConfigDTO = kpiIdToWidgetConfigRef.current[kpi.id];
    try {
      if (!widgetConfigDto) {
        const nBizDataQuery = await WidgetConfigUtils.getWidgetConfigBasedBizDataQuery(kpi.bizDataQuery);
        widgetConfigDto = getDtoFromWidgetConfig(nBizDataQuery.widgetConfig);
        kpiIdToWidgetConfigRef.current[kpi.id] = widgetConfigDto;
      }

      /**
       * TODO: Figure out a way to fix this.
       * Currently we do a clone since the metricIds will match if same KPI is added twice and thus the final widgetConfig will have only 1 entry.
       * Clone will give new metricIds so they won't match resulting in multiple entries of same KPI
       */
      widgetConfigDto = WidgetConfigUtils.getWidgetConfigClone(widgetConfigDto).widgetConfigDto;
    } catch (error) {
      logger.error("QueryEditorWrapper", "Failed to get widget config for kpi", {
        kpi,
        error
      });
    }

    const { eventTypeId } = WidgetConfigUtils.getEntityTypeAndEventTypeFromBizDataQuery(kpi.bizDataQuery);

    if (widgetConfigDto) {
      const nMetric = getDataEditorStateFromWidgetConfigDto(widgetConfigDto, eventTypeId, kpi.id);
      nMetric.metricName = kpi.name;
      nMetric.subType = kpi.subType;
      nMetric.isSpikePositive = kpi.isSpikePositive;

      return nMetric;
    }

    return null;
  }, []);

  const onSelectKPIForMetric = useCallback(
    async (kpi: KPI, idx: number) => {
      const newMetric = await getDataEditorStateEntryForKpi(kpi);

      if (newMetric) {
        if (sliceOptionsRef.current?.length) {
          const entityUSField = fieldPickerModel?.getEntityUSField();
          const slices = getSlicesFromOptions(sliceOptionsRef.current, entityUSField);

          newMetric.metricsWithEventTypeAndId = newMetric.metricsWithEventTypeAndId.map(metricInfo => {
            const nextSliceSets = [...metricInfo.metricConfig.sliceSets];
            const lastIdx = Math.max(nextSliceSets.length - 1, 0);

            if (!newMetric.hasExpression) {
              nextSliceSets[lastIdx] = {
                slices
              };
            } else {
              nextSliceSets[lastIdx] = {
                slices
              };
            }

            return {
              ...metricInfo,
              metricConfig: {
                ...metricInfo.metricConfig,
                sliceSets: nextSliceSets
              }
            };
          });
        }

        setMetricsState(prev => {
          const next = [...prev];
          next[idx] = newMetric;
          return next;
        });
      }
    },
    [fieldPickerModel, getDataEditorStateEntryForKpi, setMetricsState, sliceOptionsRef]
  );

  const onSelectCustomMetric = useCallback(
    (idx: number) => {
      setMetricsState(prev => {
        const next = [...prev];
        const newMetric = getNewMetricFromState(prev, selectedSliceOptions, fieldPickerModel);
        next[idx] = newMetric;

        return next;
      });
    },
    [fieldPickerModel, selectedSliceOptions, setMetricsState]
  );

  const [isAddMetricInProgress, setIsAddMetricInProgress] = useState(false);
  const onAddMetric = useCallback(async () => {
    setIsAddMetricInProgress(true);

    let newMetric: DataEditorStateEntry;
    if (kpis.length) {
      const kpi = kpis[0];
      newMetric = await getDataEditorStateEntryForKpi(kpi);
    }

    setMetricsState(prev => {
      if (!newMetric) {
        newMetric = getNewMetricFromState(prev, selectedSliceOptions, fieldPickerModel);
      }
      return [...prev, newMetric];
    });
    setIsAddMetricInProgress(false);
  }, [fieldPickerModel, getDataEditorStateEntryForKpi, kpis, selectedSliceOptions, setMetricsState]);

  const duration = useMemo(
    () =>
      !downsample || downsample === autoOption.value
        ? {
            duration: 1,
            durationType: RelativeDurationType.DAYS
          }
        : stringToDuration(downsample),
    [downsample]
  );
  const isCustomDownsample = useMemo(
    () => !downsampleOptions.find(opt => opt.value === String(downsample)),
    [downsample]
  );

  const dsOpt = useMemo<IncSelectOption>(() => {
    if (isCustomDownsample) {
      return customOption;
    }

    if (!downsample || downsample === autoOption.value) {
      return autoOption;
    }

    return downsampleOptions.find(opt => opt.value === downsample);
  }, [downsample, isCustomDownsample]);

  const onDownsampleChange = useCallback(
    (duration: IncSelectorDuration) => {
      const nDownsample = durationToString(duration as unknown as IncDuration);
      updateDownsample(nDownsample);
    },
    [updateDownsample]
  );

  const onDownsampleOptChange = useCallback(
    (option: IncSelectOption) => {
      const selectedValue = option.value;

      if (selectedValue === customOption.value) {
        return updateDownsample("2d");
      } else {
        return updateDownsample(selectedValue);
      }
    },
    [updateDownsample]
  );

  const expressionMetricsExist = useMemo(() => metricsState.some(m => m.hasExpression), [metricsState]);

  const queryEditorsJsx = useMemo(() => {
    const canDeleteDataPoint = isEditable && metricsState.length > 1 && !singleMetricMode;
    return metricsState.map((metricState, idx) => {
      const { exprMetricId, uniqId } = metricState;

      const { metricHeaderDescriptions } = tableProperties || {};

      const key = ["query-editor", "metric", exprMetricId, uniqId].join("_");
      const onDelete = () => onDeleteMetric(idx);
      const onClone = () => onCloneMetric(idx);
      const onSelectKpi = (kpi: KPI) => onSelectKPIForMetric(kpi, idx);
      const onAddCustomMetric = () => onSelectCustomMetric(idx);

      return (
        <QueryEditor
          canClone={!singleMetricMode}
          canDelete={canDeleteDataPoint}
          demoDataParams={demoDataParams}
          entityTypeId={bizEntityType}
          entityUsField={fieldPickerModel?.getEntityUSField()}
          eventTypes={eventTypes}
          hideHeader={singleMetricMode}
          hideMetricCustomisation={hideMetricCustomisation}
          hideSliceSelection={hideComponentMetricsGroupBy}
          isEditable={isEditable}
          key={key}
          kpis={kpis}
          metricDescriptionLabel={metricDescriptionLabel}
          metricHeaderDescriptions={metricHeaderDescriptions}
          metricNameLabel={metricNameLabel}
          metricsState={metricState}
          onAddCustomMetric={onAddCustomMetric}
          onClone={onClone}
          onDelete={onDelete}
          onSelectKPI={onSelectKpi}
          setMetricsState={updateMetricState}
          sliceOptions={selectedSliceOptions}
          updateMetricHeaderDescriptions={updateHeaderDescriptions}
        />
      );
    });
  }, [
    isEditable,
    metricsState,
    singleMetricMode,
    tableProperties,
    demoDataParams,
    bizEntityType,
    fieldPickerModel,
    eventTypes,
    hideMetricCustomisation,
    hideComponentMetricsGroupBy,
    kpis,
    metricDescriptionLabel,
    metricNameLabel,
    updateMetricState,
    selectedSliceOptions,
    updateHeaderDescriptions,
    onDeleteMetric,
    onCloneMetric,
    onSelectKPIForMetric,
    onSelectCustomMetric
  ]);

  return (
    <div className="query-editor-wrapper--content">
      {showBreakdown && (
        <VerticallyCenteredRow
          className="width-100 flex-gap-12 paddingTp12 paddingBt12 marginTp12"
          style={breakdownStyle}
        >
          <div className={breakdownWrapperCx}>
            <VerticallyCenteredRow className="inc-label-common flex-gap-8">
              Breakdown by Dimensions
              {expressionMetricsExist && (
                <IncToolTip
                  titleText="Expressions can have custom breakdown. By default any breakdown added is added to expressions as well, but please watch out for overrides (if any) for an expression using the 'Group By' option."
                  variant="info"
                >
                  <IncFaIcon
                    className="inc-text-subtext-medium status-info"
                    iconName="exclamation-triangle"
                  />
                </IncToolTip>
              )}
            </VerticallyCenteredRow>
            <FieldPickerContainer
              demoDataParams={demoDataParams}
              disabled={!isEditable && !fieldPickerModel}
              fieldPickerContextDto={fieldPickerContextDto}
              fieldPickerModel={fieldPickerModel}
              fieldTypes={["userServiceField"]}
              hideLabel
              isMulti
              label=""
              onChange={onSlicesChange}
              readOnly={!isEditable}
              selectedOptions={selectedSliceOptions || []}
            />
          </div>
          <VerticallyCenteredRow className="flex-gap-12">
            <IncSelect
              autoAdjustWidth
              autoSort={false}
              isSearchable={false}
              label="Breakdown by Time"
              onChange={onDownsampleOptChange}
              options={downsampleOptions}
              value={dsOpt}
            />
            {isCustomDownsample && (
              <IncDurationSelector
                className="marginTp20"
                duration={duration}
                hideLabel={true}
                onChange={onDownsampleChange}
              />
            )}
          </VerticallyCenteredRow>
        </VerticallyCenteredRow>
      )}

      {noCollapseMetrics && queryEditorsJsx}
      {!noCollapseMetrics && (
        <IncCollapsible
          collapsible
          content={
            <>
              {queryEditorsJsx}

              {isEditable && Boolean(eventTypes.length || kpis?.length) && (
                <IncButton
                  className="marginRtAuto"
                  color="secondary-blue"
                  disabled={isAddMetricInProgress}
                  loading={isAddMetricInProgress}
                  loadingText={addMetricLabel}
                  onClick={onAddMetric}
                >
                  {addMetricLabel}
                </IncButton>
              )}
            </>
          }
          header={metricLabel}
          show
        />
      )}

      {children}
    </div>
  );
};

const getSlicesFromOptions = (options: FieldPickerOptionData[], entityUSField: UserServiceField) => {
  const slices: UserServiceFieldSlice[] = [];

  options.forEach(option => {
    if (option.type === "userServiceField") {
      const usField = option.payload as UserServiceField;
      const isImplicitSliceField = compareUSFields(usField, entityUSField);
      const tagName = isImplicitSliceField ? ENTITY_TAG : FieldPickerUtils.getPromSanitizedUSFName(usField);

      slices.push({
        tagName,
        userServiceField: usField
      });
    }
  });

  return slices;
};

const breakdownStyle: CSSProperties = {
  border: "1px solid #4a505c",
  borderLeft: "none",
  borderRight: "none"
};

const autoOption: IncSelectOption = {
  label: "None",
  value: "auto"
};

const customOption: IncSelectOption = {
  label: "Custom",
  value: "custom"
};

const downsampleOptions: IncSelectOption[] = [
  autoOption,
  {
    label: "1 Hour",
    value: "1h"
  },
  {
    label: "1 Day",
    value: "1d"
  },
  {
    label: "1 Week",
    value: "1w"
  },
  customOption
];

const breakdownWrapperCx = cx(
  "inc-flex-column flex-gap-8 width-60",
  css`
    .field-picker {
      .field-picker-container {
        min-height: 32px;
      }
    }
  `
);

const getNewMetricFromState = (
  metricsState: DataEditorState,
  selectedSliceOptions: FieldPickerOptionData[],
  fieldPickerModel: FieldPickerModel
): DataEditorStateEntry => {
  const lastEventTypeId = last(metricsState).eventTypeId;
  const eventTypeId = lastEventTypeId;

  const metricNum = metricsState.length + 1;

  const metric = getDefaultMetricWithEventTypeAndId(eventTypeId);
  const entityUSField = fieldPickerModel?.getEntityUSField();
  metric.metricConfig.sliceSets = [
    {
      slices: getSlicesFromOptions(selectedSliceOptions || [], entityUSField)
    }
  ];

  return {
    exprMetricId: "",
    expression: "",
    metricIdLookup: {},
    metricName: `Metric ${metricNum}`,
    metricsWithEventTypeAndId: [metric],
    eventTypeId,
    hasExpression: false,
    uniqId: generateId(),
    isSpikePositive: null,
    subType: null,
    lookBack: null,
    kpiId: null
  };
};
