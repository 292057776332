import { IncTextfield } from "@inception/ui";
import React, { ChangeEvent, useCallback, useEffect, useState, useMemo } from "react";
import { isEqual, isEmpty } from "lodash";
import { ArithmeticConfig } from "../transform-config/TransformConfig";

interface ArithemticCompProps {
  config: ArithmeticConfig;
  onChange: (propertyValue: ArithmeticConfig) => void;
}

export const ArithmeticComp: React.FC<ArithemticCompProps> = (props: ArithemticCompProps) => {
  const { config, onChange } = props;
  const [value, setValue] = useState<string>("");

  const onTextValueChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setValue(evt.target.value);
      onChange({
        expression: evt.target.value
      });
    },
    [onChange]
  );

  useEffect(() => {
    if (!isEqual(config?.expression, value)) {
      setValue(config?.expression ?? "");
    }
  }, [config, value]);

  const errorText = useMemo(() => {
    const regex = /["]/;
    if (regex.test(value)) {
      return "Double quotes are not supported.";
    }
    return "";
  }, [value]);

  return (
    <div className="arithmetic-transform-component">
      <IncTextfield
        errorText={errorText}
        hasError={!isEmpty(errorText)}
        helpText={"Ex: $['jsonPath'] * 1000"}
        label="expression"
        onChange={onTextValueChange}
        value={value}
      />
    </div>
  );
};
