import React, { FC, useEffect, useState, useCallback, useRef } from "react";
import Highcharts, {
  Options,
  MapChart,
  TooltipFormatterCallbackFunction,
  SeriesHeatmapOptions,
  XAxisOptions,
  YAxisOptions
} from "highcharts/highmaps";
import HighchartsReact from "highcharts-react-official";
import { HeatMapOptionsBuilder } from "./OptionsBuilder";

Highcharts.AST.bypassHTMLFiltering = true;

interface Props {
  series: SeriesHeatmapOptions[];
  containerElemClass: string;

  tooltipFormatter?: TooltipFormatterCallbackFunction;
  xAxisOptions?: Partial<XAxisOptions>;
  yAxisOptions?: Partial<YAxisOptions>;

  heightRatio?: number;

  options?: Options;
}

export const HeatMap: FC<Props> = props => {
  const {
    series,
    containerElemClass,
    tooltipFormatter,
    xAxisOptions,
    yAxisOptions,
    heightRatio = 0.315,
    options: defOptions
  } = props;

  const [, setChartRendered] = useState(false);
  const [chartOptions, setChartOptions] = useState<Options>(null);
  const [chartInstance, setChartInstance] = useState<MapChart>(null);
  const chartContainerRef = useRef<HTMLDivElement>();

  const getChartDimensions = useCallback(() => {
    const canvasElem: HTMLElement = chartContainerRef.current;
    if (canvasElem) {
      const widgetItemContainer: Element = canvasElem.closest(`.${containerElemClass}`);
      const dimensions = widgetItemContainer?.getBoundingClientRect();
      if (dimensions) {
        const { width } = dimensions;
        return {
          width,
          height: heightRatio * width
        };
      }
    }
    return {
      width: -1,
      height: -1
    };
  }, [containerElemClass, heightRatio]);

  useEffect(() => {
    if (chartContainerRef.current) {
      setChartRendered(true);
    }
  }, [getChartDimensions, chartContainerRef]);

  // Calculate dimensions on every render
  const { width, height } = getChartDimensions();

  useEffect(() => {
    if (height <= 0 || !width) {
      return;
    }

    const builder = new HeatMapOptionsBuilder(defOptions)
      .setChartFields(width, height)
      .setSeriesData(series)
      .setTooltip(tooltipFormatter)
      .setXAxis(xAxisOptions)
      .setYAxis(yAxisOptions);

    const options = builder.build();

    setChartOptions(options);
  }, [defOptions, height, series, tooltipFormatter, width, xAxisOptions, yAxisOptions]);

  useEffect(() => {
    if (!chartInstance) {
      return;
    }
    chartInstance.redraw();
  }, [chartInstance]);

  const highchartsCallback = useCallback(chart => setChartInstance(chart), []);

  return (
    <div
      className="inc-heatmap"
      ref={chartContainerRef}
    >
      {chartOptions && (
        <HighchartsReact
          callback={highchartsCallback}
          highcharts={Highcharts}
          options={chartOptions}
        />
      )}
    </div>
  );
};
