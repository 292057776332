import React, { FC, useState, useMemo, useCallback, useRef, useEffect } from "react";
import { generateId, IncModal, IncEditor, IncInModalConfirmation } from "@inception/ui";
import { OpMode, OperationaliseHandlers } from "../../operationalise-v2/types";
import { featureFlagService } from "../../services/feature-flags";
import { OpCreationConfig } from "../../services/api/operationalise";
import { logger } from "../../core/logging/Logger";
import {
  ExploreEntityFilter,
  MetricUserServiceFilters,
  FieldPickerContextDTO,
  UserServiceFieldSlice,
  DemoDataParams
} from "../../services/api/explore";
import { useFieldPicker } from "../../field-picker";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { useTimeRange } from "../../core";
import { getImplicitSlice } from "../../utils/ExploreUtils";
import { ENTITY_TAG } from "../../utils";
import { OperationaliseV3Modal } from "../../operationalise-v2/v3";
import { VerticallyCenteredRow } from "..";
import { entityApiService } from "../../services/api";
import { Op10zeSourceConfig } from "./types";
import { getOpConfig } from "./utils";

export interface OpModalProps {
  eventTypeId: string;
  entityTypeId: string;
  bizEntityFieldName?: string;
  generateDemoData?: boolean;

  cohortId: string;
  sourceConfig: Op10zeSourceConfig;
  open: boolean;
  onClose: () => void;
  opCreationConfigId?: string;
  mode?: OpMode;
  hideList?: boolean;
  isMetaDataLoading?: boolean;
  entityFilters?: ExploreEntityFilter[];
  eventFilters?: MetricUserServiceFilters;

  defaultTab?: "configuration" | "actions";
  onSaveOrUpdate?: (opConfigId: string, opCreationConfig: OpCreationConfig) => void;
  onSimulate?: (opConfigId: string) => void;
  skipSaveOrUpdate?: boolean;
  ignoreActionConnectionErrors?: boolean;
  monitorBtnLabel?: string;
}

export const OperationaliseModal: FC<OpModalProps> = props => {
  const {
    entityTypeId,
    eventTypeId,
    bizEntityFieldName,
    generateDemoData,
    cohortId,
    sourceConfig,
    open,
    onClose,
    opCreationConfigId,
    mode = "create",
    entityFilters,
    eventFilters,
    isMetaDataLoading,
    defaultTab,
    onSaveOrUpdate,
    onSimulate,
    skipSaveOrUpdate,
    ignoreActionConnectionErrors,
    monitorBtnLabel
  } = props;

  const fetchEventTypeName = useCallback(async () => {
    setEventTypeInfoFetching(true);
    if (eventTypeId) {
      try {
        const eventTypes = await entityApiService.fetchEntityForIds([eventTypeId]);
        const eventType = (eventTypes || [])[0];
        if (eventType) {
          const eventTypeName = eventType.displayName || eventType.name || eventTypeId;
          setEventTypeName(eventTypeName);
        }
      } catch (err) {
        logger.error("Subscribe", "Error fetching eventTypeName", err);
      }
    }
    setEventTypeInfoFetching(false);
  }, [eventTypeId]);

  useEffect(() => {
    if (!isMetaDataLoading) {
      fetchEventTypeName();
    }
  }, [fetchEventTypeName, isMetaDataLoading]);

  const { timeRange } = useTimeRange();
  const buildingBlocksConfigId = useMemo(() => generateId(), []);
  const widgetConfigRefId = useMemo(
    () =>
      sourceConfig?.sourceType === "opConfig"
        ? sourceConfig?.widgetConfigRefId
        : sourceConfig?.sourceType === "usField"
          ? sourceConfig?.widgetConfigRefId
          : sourceConfig?.widgetResponseDTO?.widgetId,
    [sourceConfig]
  );

  const viewOperationaliseConfig = featureFlagService.isDebugMode();

  const [operationInProgress, setOperationInProgress] = useState(false);
  const [saveResponse, setSaveResponse] = useState<any>({});
  const [opCreationConfig, setOperationCreationConfig] = useState<OpCreationConfig>();
  const [opHandlers, setOpHandlers] = useState<OperationaliseHandlers>();
  const [eventTypeInfoFetching, setEventTypeInfoFetching] = useState(true);
  const [eventTypeName, setEventTypeName] = useState(eventTypeId);

  const [configJSON, setConfigJSON] = useState("{}");

  const [configViewerOpen, setConfigViewerOpen] = useState(false);
  const openConfigViewer = useCallback(() => setConfigViewerOpen(true), []);
  const closeConfigViewer = useCallback(() => {
    try {
      const nParsedJson = JSON.parse(configJSON);
      const nOpCreationConfig = nParsedJson.opCreationConfig;
      opHandlers?.updateOpCreationConfig(nOpCreationConfig);
    } catch (e) {
      logger.error("OperationaliseModal", "Error parsing JSON, ignoring changes", e);
      const nConfigJson = JSON.stringify(
        {
          opCreationConfig,
          widgetId: widgetConfigRefId
        },
        null,
        4
      );
      setConfigJSON(nConfigJson);
    }
    setConfigViewerOpen(false);
  }, [configJSON, opCreationConfig, opHandlers, widgetConfigRefId]);

  const [responseViewerOpen, setResponseViewerOpen] = useState(false);
  const openResponseViewer = useCallback(() => setResponseViewerOpen(true), []);
  const closeResponseViewer = useCallback(() => setResponseViewerOpen(false), []);

  const opConfigExists = Boolean(opCreationConfig);
  const showViewResponseRef = useRef(false);
  const showViewConfigRef = useRef(viewOperationaliseConfig);

  useEffect(() => {
    const nConfigJson = JSON.stringify(
      {
        opCreationConfig,
        widgetId: widgetConfigRefId
      },
      null,
      4
    );
    setConfigJSON(nConfigJson);
  }, [opCreationConfig, widgetConfigRefId]);

  const saveResponseJSON = useMemo(() => JSON.stringify(saveResponse || {}, null, 4), [saveResponse]);

  const showViewConfig = showViewConfigRef.current && opConfigExists;
  const showViewResponse = showViewResponseRef.current && opConfigExists && !operationInProgress;
  const addFooterChildren = showViewResponse || showViewConfig;

  const footerChildren = useMemo(() => {
    if (addFooterChildren) {
      return (
        <VerticallyCenteredRow className="inc-text-subtext-medium status-info">
          {showViewConfig && (
            <div
              className="marginLt12 inc-cursor-pointer"
              data-disabled={operationInProgress}
              onClick={openConfigViewer}
            >
              View configuration
            </div>
          )}
          {showViewResponse && (
            <div
              className="marginLt12 inc-cursor-pointer"
              onClick={openResponseViewer}
            >
              View save response
            </div>
          )}
        </VerticallyCenteredRow>
      );
    }

    return <></>;
  }, [addFooterChildren, openConfigViewer, openResponseViewer, operationInProgress, showViewConfig, showViewResponse]);

  const fieldPickerContext = useMemo<FieldPickerContextDTO>(
    () => ({
      entityId: entityTypeId ? null : eventTypeId,
      entityName: entityTypeId,
      entityType: entityTypeId,
      bizEntityType: entityTypeId,
      userServices: eventTypeId ? [{ userServiceEntityId: eventTypeId }] : [],
      showFields: true,
      userServiceToBizEntityFieldName:
        eventTypeId && bizEntityFieldName
          ? {
              [eventTypeId]: bizEntityFieldName
            }
          : {}
    }),
    [bizEntityFieldName, entityTypeId, eventTypeId]
  );

  const [isFetchingFields, setIsFetchingFields] = useState(true);

  const { data, getFields } = useFieldPicker();

  const fetchFields = useCallback(async () => {
    let shouldFetchFieldPicker = open;
    let demoParams: DemoDataParams;
    if (sourceConfig?.sourceType === "opConfig") {
      const implicitSlice = getImplicitSliceFromOpConfig(sourceConfig.opCreationConfig);
      shouldFetchFieldPicker = !implicitSlice;
      demoParams = {
        generateDemoData: generateDemoData || Boolean(sourceConfig.opCreationConfig?.demoDataGenInfo),
        companyName: sourceConfig.opCreationConfig?.demoDataGenInfo?.companyName,
        purpose: sourceConfig.opCreationConfig?.demoDataGenInfo?.purpose,
        subVertical: sourceConfig.opCreationConfig?.demoDataGenInfo?.subVertical,
        usecase: sourceConfig.opCreationConfig?.demoDataGenInfo?.usecase,
        vertical: sourceConfig.opCreationConfig?.demoDataGenInfo?.vertical
      } as DemoDataParams;
    }

    setIsFetchingFields(true);
    if (shouldFetchFieldPicker && !isMetaDataLoading) {
      const { fromMillis, toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);
      await getFields(fieldPickerContext, fromMillis, toMillis, false, demoParams);
    }
    setIsFetchingFields(false);
  }, [fieldPickerContext, generateDemoData, getFields, isMetaDataLoading, open, sourceConfig, timeRange]);

  useEffect(() => {
    fetchFields();
  }, [fetchFields]);

  const implicitSlice = useMemo(() => {
    let implicitSlice: UserServiceFieldSlice;
    if (!isFetchingFields && data) {
      const usField = data.getEntityUSField(bizEntityFieldName);
      if (usField) {
        implicitSlice = getImplicitSlice(usField);
      }
    }

    if (sourceConfig?.sourceType === "opConfig") {
      implicitSlice = getImplicitSliceFromOpConfig(sourceConfig.opCreationConfig);
    }

    return implicitSlice;
  }, [bizEntityFieldName, data, isFetchingFields, sourceConfig]);

  const opCreationContext = useMemo(
    () => ({
      widgetId: widgetConfigRefId,
      generateDemoData
    }),
    [generateDemoData, widgetConfigRefId]
  );

  const defaultNewConfig = useMemo(() => {
    if (!isMetaDataLoading) {
      return getOpConfig(
        sourceConfig,
        eventTypeId,
        eventTypeName,
        entityTypeId,
        buildingBlocksConfigId,
        cohortId,
        entityFilters,
        eventFilters,
        implicitSlice
      );
    }
    return null;
  }, [
    buildingBlocksConfigId,
    cohortId,
    entityFilters,
    entityTypeId,
    eventFilters,
    eventTypeId,
    eventTypeName,
    implicitSlice,
    isMetaDataLoading,
    sourceConfig
  ]);

  const getConfirmationComponent = useCallback(
    (onConfirm: () => void, onCancel: () => void) => (
      <IncInModalConfirmation
        message="Changes will be discarded. Do you want to proceed?"
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    ),
    []
  );

  const isLoading = eventTypeInfoFetching || isFetchingFields || isMetaDataLoading;
  const hasInitError = isLoading ? false : mode === "create" && !defaultNewConfig;

  return (
    <>
      {open && (
        <OperationaliseV3Modal
          bizEntityFieldName={bizEntityFieldName}
          cohortId={cohortId}
          confirmBeforeModeSwitch
          defaultMode={mode}
          defaultNewConfig={defaultNewConfig}
          defaultOp10zeSelectionId={opCreationConfigId}
          defaultTab={defaultTab}
          entityTypeId={entityTypeId}
          error="Failed to initialise operationalize. Reason: Couldn't fetch entity field."
          eventTypeId={eventTypeId}
          footerChildren={footerChildren}
          generateDemoData={generateDemoData}
          getConfirmationComponent={getConfirmationComponent}
          hasError={hasInitError}
          ignoreActionConnectionErrors={ignoreActionConnectionErrors}
          implicitSlice={implicitSlice}
          isMetaDataLoading={isLoading}
          monitorBtnLabel={monitorBtnLabel}
          onClose={onClose}
          onConfigChange={setOperationCreationConfig}
          onConfigSaveResponse={setSaveResponse}
          onHandlersChange={setOpHandlers}
          onOperationInProgress={setOperationInProgress}
          onSaveOrUpdate={onSaveOrUpdate}
          onSimulate={onSimulate}
          opCreationContext={opCreationContext}
          skipSaveOrUpdate={skipSaveOrUpdate}
        />
      )}

      {/* Config viewer */}
      <IncModal
        className="operationalise-config-viewer-modal"
        onClose={closeConfigViewer}
        show={configViewerOpen}
        size="md"
        titleText="View configuration"
      >
        <IncEditor
          mode="json"
          onChange={setConfigJSON}
          value={configJSON}
        />
      </IncModal>

      {/* Response viewer */}
      <IncModal
        className="operationalise-config-viewer-modal"
        disableFocusOnLoad
        onClose={closeResponseViewer}
        show={responseViewerOpen}
        size="md"
        titleText="Save config response"
      >
        <IncEditor
          mode="json"
          readOnly
          value={saveResponseJSON}
        />
      </IncModal>
    </>
  );
};

const getImplicitSliceFromOpConfig = (opCreationConfig: OpCreationConfig) => {
  let implicitSlice: UserServiceFieldSlice;

  const { bizDataQuery } = opCreationConfig?.opCreationConfigDef || {};
  const { sliceSets = [] } = bizDataQuery?.buildingBlockConfig?.buildingBlockDef?.sliceDef || {};
  sliceSets.forEach(sliceSet => {
    const { slices } = sliceSet;
    if (!implicitSlice) {
      implicitSlice = slices?.find(slice => slice.tagName === ENTITY_TAG);
    }
  });

  return implicitSlice;
};
