import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IncClickAwayPopper, IncSelect, IncSelectOption, IncSkeleton, IncSmartText, IncTextfield } from "@inception/ui";
import { isEqual } from "lodash";
import {
  BizEntityType,
  BizField,
  FieldPickerContextDTO,
  useFetchExploreBizEntityTypes
} from "../../../services/api/explore";
import { FieldPickerRow, useFieldPicker } from "../../../field-picker";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { DataType, useTimeRange } from "../../../core";
import { VerticallyCenteredRow } from "../../../components";
import { dataTypeManager } from "../../../utils";
import CohortVariableImpl from "../../model-impl/CohortVariableImpl";

interface Props {
  anchorEl: HTMLElement;
  show: boolean;
  onClose: () => void;
  onSelect: (entityTypeId: BizEntityType["entityTypeId"], field: BizField) => void;
  onSelectCohort: (entityTypeId: BizEntityType["entityTypeId"]) => void;
  selectedCohortVariables: CohortVariableImpl[];
}

export const EntityFilterSelectorPopup: React.FC<Props> = (props: Props) => {
  const { anchorEl, onClose, show, onSelect, onSelectCohort, selectedCohortVariables } = props;

  const { timeRange } = useTimeRange();

  const [searchText, setSearchText] = useState("");

  const onSearchTextChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchText(value);
  }, []);

  const {
    bizEntityTypes = [],
    isFetching: isEntityFetching,
    fetchBizEntityList,
    error: entityError,
    isError: isEntityError
  } = useFetchExploreBizEntityTypes();

  const isLoading = isEntityFetching;

  useEffect(() => {
    fetchBizEntityList();
  }, [fetchBizEntityList]);

  const entityOptions = useMemo<IncSelectOption[]>(
    () =>
      bizEntityTypes.map(bizEntityType => ({
        label: bizEntityType.name,
        value: bizEntityType.entityTypeId
      })),
    [bizEntityTypes]
  );

  const [entityTypeId, setEntityTypeId] = useState<IncSelectOption>(entityOptions[0]);

  useEffect(() => {
    setEntityTypeId(entityOptions[0]);
  }, [entityOptions]);

  const {
    data: fieldPickerModel,
    getFields,
    error: bizFieldsError,
    isError: isBizFieldsError,
    isFetching: isBizFieldsFetching
  } = useFieldPicker();

  const fieldPickerContext = useMemo<FieldPickerContextDTO>(
    () => ({
      entityId: "",
      entityType: entityTypeId?.value,
      entityName: entityTypeId?.label,
      showFields: true
    }),
    [entityTypeId]
  );

  useEffect(() => {
    if (entityTypeId && fieldPickerContext) {
      const { fromMillis, toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);
      getFields(fieldPickerContext, fromMillis, toMillis);
    }
  }, [entityTypeId, fieldPickerContext, getFields, timeRange]);

  const bizFieldsList = useMemo(() => {
    if (fieldPickerModel && !isBizFieldsFetching) {
      const bizFieldsInfo = fieldPickerModel.getBizEntityFieldsTableData([]);
      return bizFieldsInfo;
    }
    return [];
  }, [fieldPickerModel, isBizFieldsFetching]);

  const bizFieldsListJsx = useMemo(() => {
    const cohortField: FieldPickerRow<"bizEntityField"> = {
      fieldLabel: "cohort",
      field: null,
      datatype: "STRING",
      fieldType: "bizEntityField"
    };

    const fieldsWithoutRelatedNames = bizFieldsList.filter(
      bizField => !bizField.field.bizField.entityField.relNames.length
    );

    const unselectedFields = fieldsWithoutRelatedNames.filter(
      bizField =>
        !selectedCohortVariables.some(cohortVariable => isEqual(bizField.field.bizField, cohortVariable.bizField))
    );

    const allFields = [cohortField, ...unselectedFields];

    const filteredBizFieldsList = allFields.filter(bizField =>
      bizField.fieldLabel.toLowerCase().includes(searchText.toLowerCase())
    );

    if (filteredBizFieldsList.length === 0) {
      return (
        <IncSmartText
          className="inc-text-subtext marginRtAuto"
          text="No data found"
        />
      );
    }

    return filteredBizFieldsList.map(bizField => {
      const { fieldLabel, field, datatype } = bizField;
      const dataTypeIcon = dataTypeManager.getDataTypeDescriptor(datatype as DataType).getIcon({ height: 16 });
      const onClick = () => {
        if (fieldLabel === "cohort") {
          onSelectCohort(entityTypeId?.value);
        } else {
          onSelect(entityTypeId?.value, field?.bizField);
        }
      };
      return (
        <VerticallyCenteredRow
          className="field-item flex-gap-6 width-100 inc-cursor-pointer"
          key={fieldLabel}
          onClick={onClick}
        >
          {dataTypeIcon}

          <IncSmartText
            className="inc-text-subtext marginRtAuto"
            style={{ width: "calc(100% - 100px - 16px)" }}
            text={fieldLabel}
          />
        </VerticallyCenteredRow>
      );
    });
  }, [bizFieldsList, entityTypeId?.value, onSelect, onSelectCohort, searchText, selectedCohortVariables]);

  return (
    <IncClickAwayPopper
      anchorEl={anchorEl}
      onClickAway={onClose}
      placement="bottom-end"
      show={show}
    >
      <div className="bizfield-picker-pop-up inc-flex-column flex-gap-12">
        <div className="upper-section inc-flex-column flex-gap-12">
          {isLoading && (
            <IncSkeleton
              active
              paragraph={{ rows: 6 }}
            />
          )}
          {!isLoading && (
            <>
              {isEntityError && <span className="status-danger">{entityError || "Failed to fetch entities"}</span>}
              {!isEntityError && (
                <>
                  <IncSelect
                    label="Select Entity Type"
                    onChange={setEntityTypeId}
                    options={entityOptions}
                    placeholder="Select Entity"
                    value={entityTypeId}
                  />

                  {isBizFieldsFetching && (
                    <IncSkeleton
                      active
                      paragraph={{ rows: 4 }}
                    />
                  )}

                  {!isBizFieldsFetching && (
                    <>
                      <IncTextfield
                        autoFocus
                        endIcon="magnifying-glass"
                        onChange={onSearchTextChange}
                        placeholder={"Search"}
                        value={searchText}
                      />
                      {isBizFieldsError && (
                        <span className="status-danger">{bizFieldsError || "Failed to fetch fields"}</span>
                      )}
                      {!isBizFieldsError && (
                        <div className="inc-flex-column field-items-container flex-gap-12 paddingRt4 paddingBt12">
                          {bizFieldsListJsx}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </IncClickAwayPopper>
  );
};
