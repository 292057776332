import React, { FC, useMemo, memo, useCallback } from "react";
import { TableDataColumn, IncRTable, IncRTableProps, IncSmartText } from "@inception/ui";
import { round } from "lodash";
import { getDisplayTagNameForUSFieldSlice, ImpactedWidget } from "../../../services/api/explore";
import { getFormattedValueForImpactedWidget } from "../impact-widget/utils";
import { getDefaultFilters, getGroupedBarRows, getPropsFromPreset } from "./common";
import { DrilldownVizRenderer, DrilldownVizRendererProps } from "./DrilldownVizRenderer";
import { TableRowRenderer } from "./TableRowRenderer";
import { DrilldownSubRendererProps } from "./types";

export const FieldDrilldownTable: FC<DrilldownSubRendererProps> = memo(props => {
  const { preset, impactedWidget, presetKey } = props;

  const { slice, title } = getPropsFromPreset(preset, presetKey || "table");
  const { tagName } = slice;

  const impactedWidgetName = impactedWidget.name;
  const { displayTagName } = getDisplayTagNameForUSFieldSlice(slice);

  const displayTitle = title || displayTagName;
  const rawValueTagKey = `${tagName}__raw`;

  const payloadProps = useMemo(
    () => ({
      groupBy: [tagName],
      ...getDefaultFilters()
    }),
    [tagName]
  );

  const rowRenderer = useCallback(
    (value: string, rowData: Record<string, string>) => {
      const rawValue = rowData[rawValueTagKey] || value;

      return (
        <TableRowRenderer
          {...props}
          rawValue={rawValue}
          slice={slice}
          value={value}
        />
      );
    },
    [props, rawValueTagKey, slice]
  );

  const vizRenderer = useCallback<DrilldownVizRendererProps["vizRenderer"]>(
    (state, ref) => {
      const { data, error, isError, isFetching } = state;

      const { bars } = getGroupedBarRows(data, tagName);

      const columns = getColumns(displayTagName, displayTitle, impactedWidget, rowRenderer);
      const tableData = bars.map(({ name, value, custom }) => ({
        [displayTagName]: name,
        [rawValueTagKey]: custom?.rawValue || name,
        [impactedWidgetName]: round(value, 2)
      }));

      const errorDataMessage = `Error fetching data: ${error}`;

      const sortBy: IncRTableProps["sort"] = {
        accessor: impactedWidgetName,
        order: "desc"
      };

      return (
        <div
          className="field-drilldown-table"
          ref={ref}
        >
          <div className="marginBt10 inc-text-subtext">{displayTitle}</div>
          <IncRTable
            columns={columns}
            data={tableData}
            density="compact"
            errorDataMessage={errorDataMessage}
            hasError={isError}
            isLoading={isFetching}
            pagination={pagination}
            showDisplayStats
            sort={sortBy}
          />
        </div>
      );
    },
    [displayTagName, displayTitle, impactedWidget, impactedWidgetName, rawValueTagKey, rowRenderer, tagName]
  );

  return (
    <DrilldownVizRenderer
      {...payloadProps}
      {...props}
      vizRenderer={vizRenderer}
    />
  );
});

const getColumns = (
  tagName: string,
  displayTitle: string,
  impactedWidget: ImpactedWidget,
  rowRenderer: (value: string, rowData: Record<string, string>) => JSX.Element
): TableDataColumn[] => [
  {
    accessor: tagName,
    header: displayTitle,
    sortable: true,
    type: "string",
    width: "70%",
    renderer: rowRenderer
  },
  {
    accessor: impactedWidget.name,
    header: impactedWidget.name,
    sortable: true,
    type: "number",
    width: "30%",
    renderer: value => {
      const formattedValue = getFormattedValueForImpactedWidget(impactedWidget, value);

      return <IncSmartText text={formattedValue} />;
    }
  }
];

const pagination: IncRTableProps["pagination"] = {
  enabled: true,
  pageSize: 20,
  viewMode: "minimal"
};
