import React, { useCallback, FC, useState, useEffect } from "react";
import { IncButton, IncFaIcon, IncInModalConfirmation, IncToolTip } from "@inception/ui";
import { useOpStore, setUIIntegrationConfigsContext } from "../../../context";
import { BizActionConfig, UIIntegrationActionConfig } from "../../../../services/api/operationalise";
import { VerticallyCenteredRow, LoadingSpinner } from "../../../../components";
import { OpMode, SourceTypeInfoByCategory } from "../../../types";
import { useAccessPrivilege, useToggleState } from "../../../../core";
import { BizActionConfigEditor } from "./BizActionConfigEditor/BizActionConfigEditor";

type ActionsEditorProps = {
  mode: OpMode;
  bizActionId: string;
  bizActionConfig: BizActionConfig;
  onSaveOrUpdateBizAction: (bizActionId: string, bizActionConfig: BizActionConfig) => void;
  onChangeBizAction: (bizActionId: string, bizActionConfig: BizActionConfig) => void;
  onCancel: () => void;
  saveOrUpdateInProgress: boolean;
  sourceTypeInfoByCategory: SourceTypeInfoByCategory;
  isSavedAction?: boolean;
  changesExist?: boolean;
  actionErrors?: string[];
  supportsTestActionWithRealAlertOnly?: boolean;
};

export const ActionsEditor: FC<ActionsEditorProps> = props => {
  const {
    actionErrors,
    bizActionConfig,
    bizActionId,
    onSaveOrUpdateBizAction: onUpdateAction,
    onChangeBizAction: onChangeAction,
    onCancel,
    saveOrUpdateInProgress,
    isSavedAction,
    mode,
    sourceTypeInfoByCategory,
    supportsTestActionWithRealAlertOnly,
    changesExist
  } = props;

  const { close: closeConfirmation, isOpen: isConfirmationOpen, open: openConfirmation } = useToggleState();

  const { canCreate } = useAccessPrivilege();
  const isReadOnly = !canCreate;

  const { state, dispatch } = useOpStore();

  const { opCreationConfig, op10zeId } = state;

  const [errors, setErrors] = useState(actionErrors);
  useEffect(() => {
    setErrors(actionErrors);
  }, [actionErrors]);

  const saveOrUpdateAction = useCallback(
    (bizActionConfig: BizActionConfig) => {
      onUpdateAction(bizActionId, bizActionConfig);
    },
    [bizActionId, onUpdateAction]
  );

  const onActionEdit = useCallback(
    (bizActionConfig: BizActionConfig) => {
      onChangeAction(bizActionId, bizActionConfig);
    },
    [bizActionId, onChangeAction]
  );

  const onUIIntegrationConfigsFetched = useCallback(
    (uiIntegrationActionConfigs: UIIntegrationActionConfig[]) => {
      dispatch(setUIIntegrationConfigsContext(uiIntegrationActionConfigs));
    },
    [dispatch]
  );

  const onCancelTriggered = useCallback(() => {
    if (!isSavedAction || changesExist) {
      openConfirmation();
    } else {
      onCancel();
    }
  }, [changesExist, isSavedAction, onCancel, openConfirmation]);

  const onClick = useCallback(() => {
    saveOrUpdateAction(bizActionConfig);
  }, [bizActionConfig, saveOrUpdateAction]);

  const buttonText = isSavedAction ? "Update" : "Save";
  const errorsExist = errors?.length > 0;
  const disableSaveOrUpdate = saveOrUpdateInProgress || errorsExist;
  const error = errors?.join("\n") || "";

  const onConfirmCancel = useCallback(() => {
    closeConfirmation();
    onCancel();
  }, [closeConfirmation, onCancel]);

  return (
    <div
      className="actions-editor"
      data-readonly={isReadOnly}
    >
      {!bizActionConfig && (
        <VerticallyCenteredRow>Add an action or select an existing one to edit</VerticallyCenteredRow>
      )}
      {Boolean(bizActionConfig) && (
        <>
          <BizActionConfigEditor
            bizActionConfig={bizActionConfig}
            bizActionId={bizActionId}
            errors={actionErrors}
            mode={mode}
            onChange={onActionEdit}
            onErrors={setErrors}
            onIntegrationsFetched={onUIIntegrationConfigsFetched}
            op10zeId={op10zeId}
            opCreationConfig={opCreationConfig}
            readOnly={isReadOnly}
            sourceTypeInfoByCategory={sourceTypeInfoByCategory}
            supportsTestActionWithRealAlertOnly={supportsTestActionWithRealAlertOnly}
          >
            <VerticallyCenteredRow>
              {errorsExist && (
                <IncToolTip
                  placement="top"
                  showArrow
                  titleText={error}
                  variant="error"
                >
                  <VerticallyCenteredRow className="marginLt12">
                    <IncFaIcon
                      className="status-danger"
                      iconName="exclamation-triangle"
                    />
                  </VerticallyCenteredRow>
                </IncToolTip>
              )}

              <IncButton
                className="marginLt22"
                color="secondary-red"
                onClick={onCancelTriggered}
              >
                Cancel
              </IncButton>

              <IncButton
                className="marginLt22"
                color="primary"
                disabled={disableSaveOrUpdate}
                onClick={onClick}
              >
                {saveOrUpdateInProgress && <LoadingSpinner titleText=" " />}
                <VerticallyCenteredRow>{buttonText}</VerticallyCenteredRow>
              </IncButton>
            </VerticallyCenteredRow>
          </BizActionConfigEditor>

          {isConfirmationOpen && (
            <IncInModalConfirmation
              message="Do you want to discard your changes?"
              onCancel={closeConfirmation}
              onConfirm={onConfirmCancel}
            />
          )}
        </>
      )}
    </div>
  );
};
