import React, { useMemo, useCallback } from "react";
import {
  FieldPickerContextDTO,
  UserServiceFilterExpression,
  UserServiceFilterExpressionTree,
  LogicalOperator,
  EntityOperation
} from "../../../services/api/explore";
import { VariableSrv } from "../../variables";
import { EventExpressionVariableModel, EventVariableModel, VariableType } from "../../models/VariableModel";
import VariableImpl from "../../model-impl/VariableImpl";
import { EventCriteriaPicker } from "../../../components";
import EventExpressionVariableImpl from "../../model-impl/EventExpressionVariableImpl";
import { logger } from "../../../core";
import { featureFlagService, FEATURE_FLAGS } from "../../../services/feature-flags";
import EventVariableImpl from "../../model-impl/EventVariableImpl";
import { FieldPickerUtils } from "../../../utils";
import { FilterWidgetImpl } from "../FilterWidget/models";

interface Props {
  userServiceId: string;
  variableSrv: VariableSrv;
  widget: FilterWidgetImpl;
  onVariablesUpdate: (variables: VariableImpl[], widgetId: string) => void;
}

export const EventFilters: React.FC<Props> = (props: Props) => {
  const shouldUseExpressionTree = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.useComplexExpressions);

  const { userServiceId, onVariablesUpdate, widget, variableSrv } = props;
  const { id: widgetId } = widget;

  const filterExpressions = useMemo(() => {
    if (!shouldUseExpressionTree) {
      const allVariables = variableSrv.getVariables();
      const eventVars = allVariables.filter(x => x.type === VariableType.Event) as EventVariableImpl[];
      const usFilterExpressions: UserServiceFilterExpression[] = eventVars.map((ev: EventVariableImpl) =>
        ev.getFilterExpression()
      );
      return usFilterExpressions;
    }

    return null;
  }, [shouldUseExpressionTree, variableSrv]);

  const filtersExpressionTree = useMemo<UserServiceFilterExpressionTree>(() => {
    const allVariables = variableSrv.getVariables();
    if (shouldUseExpressionTree) {
      const eventExprVars = allVariables.filter(
        x => x.type === VariableType.EventExpression
      ) as EventExpressionVariableImpl[];

      if (eventExprVars.length === 0) {
        return {
          filterNodes: [],
          logicalOperator: LogicalOperator.AND
        };
      } else {
        if (eventExprVars.length > 1) {
          logger.warn(
            "EventFilters",
            "Encountered more than one event expression variable. Picking 1st one by default",
            eventExprVars
          );
        }
        const eventExprVar = eventExprVars[0];
        return eventExprVar.expressionTree;
      }
    }

    return null;
  }, [shouldUseExpressionTree, variableSrv]);

  const onFilterChange = useCallback(
    (filters: UserServiceFilterExpression[], filtersExpressionTree: UserServiceFilterExpressionTree) => {
      let variables: VariableImpl[];

      if (shouldUseExpressionTree) {
        const exprVariableModel: Partial<EventExpressionVariableModel> = {
          userServiceId,
          value: [],
          name: userServiceId,
          expressionTree: filtersExpressionTree,
          type: VariableType.EventExpression
        };
        variables = [new EventExpressionVariableImpl(exprVariableModel)];
      } else {
        variables = filters.map(x => {
          const fieldLabel = FieldPickerUtils.getUserServiceFieldLabel(x.field);
          const eVarModel: Partial<EventVariableModel> = {
            userServiceId,
            userServiceField: x.field,
            operator: x.operator as EntityOperation,
            value: x.value || x.values,
            name: fieldLabel,
            type: VariableType.Event
          };
          return new EventVariableImpl(eVarModel);
        });
      }

      onVariablesUpdate(variables, widgetId);
    },
    [onVariablesUpdate, shouldUseExpressionTree, userServiceId, widgetId]
  );

  const fieldPickerContext: FieldPickerContextDTO = useMemo(
    () => ({
      entityId: userServiceId,
      entityType: null,
      entityName: ""
    }),
    [userServiceId]
  );

  return (
    <div className="event-filters">
      <EventCriteriaPicker
        fieldPickerContext={fieldPickerContext}
        filterExpressionTree={filtersExpressionTree}
        filters={filterExpressions}
        onChange={onFilterChange}
        pillLimit={3}
        useExpressionTree={shouldUseExpressionTree}
      />
    </div>
  );
};
