import React, { FC, useMemo } from "react";
import { IncidentSliceContribution, CompareQuerySchemaResponse } from "../../../services/api/explore";
import { CommonIndicatorProps, SliceIndicator } from "./SliceIndicator";

export interface Props extends CommonIndicatorProps {
  keyContributor: IncidentSliceContribution;
  compareSchema: CompareQuerySchemaResponse;
  isDiagnostic: boolean;

  pinnedSliceTagNames: string[];
}

export const KeyIndicator: FC<Props> = props => {
  const { keyContributor, pinnedSliceTagNames, compareSchema, ...restProps } = props;

  const { tagName } = keyContributor.slice;

  const schemaPerSlice = useMemo(() => {
    const set = new Set<string>();

    (compareSchema?.resultSchema || []).forEach(sch => {
      const tagValue = sch[tagName];
      tagValue && set.add(tagValue);
    });

    return {
      [tagName]: set
    };
  }, [compareSchema, tagName]);

  const isPinned = pinnedSliceTagNames?.includes(tagName);

  return (
    <SliceIndicator
      isPinned={isPinned}
      schemaPerSlice={schemaPerSlice}
      sliceContributors={keyContributor}
      {...restProps}
    />
  );
};
