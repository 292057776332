import { union, isEmpty, isEqual } from "lodash";
import { useState, useRef, useEffect, useCallback } from "react";
import { UserServiceFieldWithMeta, fieldPickerApiService } from "../../../services/api/explore";
import { logger } from "../../../core/logging/Logger";
import { ALL_USERSERVICES_ENTITY_TYPE_ID } from "../../../utils/ExploreUtils";
import { TimeRange } from "../../../core";

interface PropsV2 {
  timeRange: TimeRange;
  shouldWaitForInit?: boolean;
}

export const useFetchEventTypeFieldsMapV2 = (props: PropsV2) => {
  const { shouldWaitForInit, timeRange } = props;
  const [eventTypeFieldsMaps, setEventTypeFieldsMap] = useState<Record<string, UserServiceFieldWithMeta[]>>({});
  const [isEventTypeFieldsMapInitialized, setIsEventTypeFieldsMapInitialized] = useState(false);
  const prevEventTypeTriggerRef = useRef({
    timeRange: timeRange,
    eventTypeFieldsMaps
  });

  const getEventTypeToFieldsMap = useCallback(async () => {
    let eventTypeList: string[] = [];

    const pTimeRange = prevEventTypeTriggerRef.current.timeRange;
    const pEventTypeMap = prevEventTypeTriggerRef.current.eventTypeFieldsMaps;
    const eventTypeFieldsMap: Record<string, UserServiceFieldWithMeta[]> = pEventTypeMap;
    const isTimeRangeChanged = !isEqual(pTimeRange, timeRange);

    if (isEmpty(eventTypeFieldsMap) || isTimeRangeChanged) {
      try {
        const fieldPickerResponse = await fieldPickerApiService.getAllUserServiceFields(0, timeRange.to.valueOf());
        if (fieldPickerResponse) {
          const { userServiceFields } = fieldPickerResponse;
          eventTypeFieldsMap[ALL_USERSERVICES_ENTITY_TYPE_ID] = fieldPickerResponse.userServiceFields;
          userServiceFields.forEach(x => {
            const usList =
              x?.userServiceField?.userServices?.map(x => x.userServiceEntityId)?.filter(x => Boolean(x)) || [];
            eventTypeList = union(eventTypeList, usList);
          }, []);

          eventTypeList.forEach(x => {
            eventTypeFieldsMap[x] = [];

            userServiceFields.forEach(usf => {
              if (usf?.userServiceField?.userServices?.find(us => us.userServiceEntityId === x)) {
                eventTypeFieldsMap[x].push(usf);
              }
            });
          });

          setEventTypeFieldsMap(eventTypeFieldsMap);
        }
      } catch (e) {
        logger.debug("Dashboards", `failed to fetch picker response ${e.message}`);
      }
    }
    setIsEventTypeFieldsMapInitialized(true);
  }, [timeRange]);

  useEffect(() => {
    if (!shouldWaitForInit) {
      getEventTypeToFieldsMap();
    }
  }, [getEventTypeToFieldsMap, shouldWaitForInit]);

  return {
    eventTypeToFieldsMap: eventTypeFieldsMaps,
    isEventTypeFieldsMapInitialized
  };
};
