import { IncButton, IncMenu, IncMenuItemProps, IncMenuToggleProps } from "@inception/ui";
import React, { forwardRef } from "react";
import { FormattedMessage } from "react-intl";
import { WidgetConfig, widgetRegistry } from "../widgets/WidgetRegistry";
import { ReactComponent as AddIcon } from "../../../images/icons/Add.svg";
import { featureFlagService, FEATURE_FLAGS } from "../../services/feature-flags";

export interface AddWidgetMenuProps {
  add(type: string): void;
  disabled?: boolean;
}

const AddWidgetMenu = forwardRef<HTMLButtonElement, AddWidgetMenuProps>((props, ref) => {
  // Temporarily disabling this since we want new UX for this
  const enableAddWidget = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.enableAddWidget);

  const onWidgetSelection = (type: string): void => {
    props.add(type);
  };

  const menuItems = (itemProps: IncMenuItemProps) => {
    const { closeMenu, className } = itemProps;
    const widgetOptions: JSX.Element[] = [];
    widgetRegistry.getAllWidgetConfigs().forEach((config: WidgetConfig) => {
      const { iconComponent, id, enableInDashboard, label } = config;

      const addToMenu = enableInDashboard && (enableAddWidget ? true : DEFAULT_ENABLED_WIDGETS.includes(id));

      if (addToMenu) {
        widgetOptions.push(
          <div
            className={className}
            key={id}
            onClick={e => {
              onWidgetSelection(id);
              closeMenu(e as any);
            }}
            tabIndex={1}
          >
            {iconComponent()}
            <span className="marginLt8">{label}</span>
          </div>
        );
      }
    });

    return widgetOptions;
  };

  const toggle = (toggleProps: IncMenuToggleProps) => {
    const { show, toggleMenu } = toggleProps;
    return (
      <IncButton
        color="link"
        disabled={props.disabled}
        iconType="iconText"
        onClick={(e: any) => {
          toggleMenu(!props.disabled && !show, e);
        }}
        ref={ref}
      >
        <AddIcon />
        <FormattedMessage id="dashboards.toolbar.widget.add" />
      </IncButton>
    );
  };

  return (
    <IncMenu
      defaultFocus
      items={menuItems}
      toggle={toggle}
    />
  );
});

export default AddWidgetMenu;

const DEFAULT_ENABLED_WIDGETS = ["metric-table", "catalog"];
