import { zipObject } from "lodash";
import { SourceTypeDef } from "../../services/api/event-mapping";
import entityMappingApiServiceV2 from "../../services/api/entity-mapping/EntityMappingApiServiceV2";
import { DestinationMapping } from "../../services/api/entity-mapping";
import { logger } from "../../core";
import { mockStreamData } from "./mockData";

export type DestinationData = {
  id: string;
  name: string;
  type: string;
  fields: string[];
  icon: string;
};

export const getDestinationDataRec = (destinationMappings: DestinationMapping[], fieldName: string) => {
  const destData: Record<string, DestinationData> = {};

  destinationMappings.forEach(mapping => {
    const { destId, typeMapping } = mapping;

    const destEntry = destData[destId] || {
      id: destId,
      name: "",
      fields: [] as string[],
      type: "",
      icon: ""
    };

    const { fieldMappings = {} } = typeMapping;
    const destFieldMapping = fieldMappings[fieldName];
    if (destFieldMapping) {
      const { valueMapping } = destFieldMapping;
      const { fieldPaths = [] } = valueMapping || {};
      if (fieldPaths) {
        fieldPaths.forEach(fieldPath => {
          const { segment } = fieldPath;
          segment && destEntry.fields.push(segment);
        });
      }
    }

    destData[destId] = destEntry;
  });

  return destData;
};

export const enrichDestinationDataRec = async (
  destinationData: Record<string, DestinationData>,
  isMockData: boolean
) => {
  const destIds = Object.keys(destinationData);
  const fDestIds = destIds.map(destId => {
    // if the destId is of the form c_connector_stream:<guid>, we need to only use the guid part
    if (destId.includes(":")) {
      const splitStr = destId.split(":");
      return splitStr[1];
    } else {
      return destId;
    }
  });
  const idsToFIds = zipObject(fDestIds, destIds);
  try {
    const dataPromise = isMockData
      ? Promise.resolve(mockStreamData)
      : entityMappingApiServiceV2.getConnectionStreamByIds(fDestIds);
    const destDetailsById = await dataPromise;
    Object.keys(destDetailsById).forEach(fDestId => {
      const destId = idsToFIds[fDestId];
      const { sourceTypeDef } = destDetailsById[fDestId];
      const sTypeDef = sourceTypeDef as SourceTypeDef;
      destinationData[destId].name = sTypeDef.displayName;
      destinationData[destId].type = sTypeDef.sourceTypeId;
      destinationData[destId].icon = sTypeDef.icon;
    });
  } catch (e) {
    logger.error("WritableFieldDestinations: Error fetching destination details", e);
  }
};
