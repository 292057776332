import React, { FC, useMemo } from "react";
import { OpSchema } from "../../../services/api/operationalise";
import { UserServiceFilterExpression } from "../../../services/api/explore";
import { TimeRange } from "../../../core";
import { CorrelatedEventsTimeline } from "../CorrelatedEventsTimeline";
import { CorrelatedEventsSource } from "../types";

interface Props {
  opSchema: OpSchema;
  incidentId: string;
  userServiceFilterExpressions: UserServiceFilterExpression[];
  generateDemoData?: boolean;
  timeRange?: TimeRange;
}

export const ChangeEventDrawer: FC<Props> = props => {
  const source = useMemo<CorrelatedEventsSource>(
    () => ({
      type: "payload",
      payload: {
        bins: 0
      }
    }),
    []
  );

  return (
    <div>
      <CorrelatedEventsTimeline
        impactedWidgets={props.opSchema.impactedWidgets?.impactedWidgets}
        opConfigId={props.opSchema.opId}
        source={source}
        {...props}
      />
    </div>
  );
};
