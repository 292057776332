import React, { useEffect, useMemo, useState } from "react";
import { IncSelect } from "@inception/ui";
import { components, createFilter } from "react-select";
import { capitalize } from "lodash";
import { getDataTypeIconElem } from "../simple-renderer/SimpleDataTypeRenderer";
import { DataTypeOption, getAllDataTypeOptions, MappingType } from "../utils";

type IncDataTypeSelectProps = {
  label: string;
  onSelect: (selection: DataTypeOption) => void;
  selected: DataTypeOption;
  disabled?: boolean;
  category?: "all" | "primitive";
  onLoad?: (options: DataTypeOption[]) => void;
  type?: MappingType;
};

//this is where IncSelect option list is generated,
//value of the list is always prim_type/base_type ["_str","_long","_bool"..etc]
// export const dataTypeOptions: DataTypeOption[] = Object.entries(PrimKindMap).map((values) => ({
//   label: values[0] as keyof typeof PrimKindMap,
//   value: values[1].kind,
//   kindDescriptor: values[1].kindDescriptor
// }));

const IncDataTypeSelect: React.FC<IncDataTypeSelectProps> = props => {
  const { label, onSelect, selected, disabled = false, category = "all", onLoad, type = MappingType.event } = props;

  const [dataTypeSelected, setDataTypeSelected] = useState<DataTypeOption>(null);
  const [dataTypeOptions, setDataTypeOptions] = useState<DataTypeOption[]>([]);

  const allDataTypeOptions = useMemo(() => getAllDataTypeOptions(type), [type]);

  useEffect(() => {
    let optionsToLoad = allDataTypeOptions;
    if (category === "primitive") {
      optionsToLoad = allDataTypeOptions.filter(op => op?.supportedDataType?.isPrimitive);
    }
    setDataTypeOptions(optionsToLoad);

    if (onLoad) {
      onLoad(optionsToLoad);
    }
  }, [allDataTypeOptions, category, onLoad]);

  useEffect(() => {
    if (selected) {
      const selectedOption = dataTypeOptions.find(
        o => o.value === selected.value && o.kindDescriptor?.type === selected.kindDescriptor?.type
      );

      setDataTypeSelected(selectedOption);
    }
  }, [selected, dataTypeOptions]);

  const setStateAndCallBack = (selection: DataTypeOption) => {
    setDataTypeSelected(selection);
    onSelect(selection);
  };

  const onSingleSelection = (selections: DataTypeOption | null) => {
    if (selections) {
      setStateAndCallBack(selections);
    }
  };

  const commonProps = {
    getOptionLabel: (option: DataTypeOption) => option.label,
    getOptionValue: (option: DataTypeOption) => {
      if (option.kindDescriptor === null) {
        return option.value;
      } else {
        return `${option.value} ${option.kindDescriptor.type}`;
      }
    },
    options: dataTypeOptions,
    label: label,
    placeholder: "Select",
    value: dataTypeSelected,
    isDisabled: disabled,
    components: { Option: IconOption }
  };

  return (
    <IncSelect<DataTypeOption, false>
      {...commonProps}
      autoSort={false}
      filterOption={createFilter({ ignoreAccents: false })}
      isSearchable={true}
      onChange={onSingleSelection}
    ></IncSelect>
  );
};

export default IncDataTypeSelect;

const { Option } = components;
const IconOption = (props: any) => {
  const { data } = props;

  return (
    <Option {...props}>
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: 6 }}>{getDataTypeIconElem(data.value, data.kindDescriptor)}</div>
        <div>{capitalize(data.label)}</div>
      </div>
    </Option>
  );
};
