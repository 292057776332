import { defaults, isArray } from "lodash";
import { EventVariableModel, VariableType, EVENT_VAR_URL_PREFIX } from "../models/VariableModel";
import {
  UserServiceField,
  EntityOperation,
  UserServiceFilterExpression,
  EntityOpToTraceQueryOpMap
} from "../../services/api/explore";
import { DataType } from "../../core";
import { getFinalValuesAndOperatorForDataType } from "../widgets/utils";
import { QueryParamDTO, getFilterExprQueryParam } from "../../utils/QueryParamUtils";
import { FieldPickerUtils, getKeyByValue } from "../../utils";
import VariableImpl from "./VariableImpl";

const DEFAULTS: EventVariableModel = {
  id: "",
  name: "",
  multi: false,
  value: null,
  defaultValue: "",
  type: VariableType.Event,
  allValue: "",
  includeAll: false,
  label: "",
  hide: false,
  supportsAggregation: false,
  userServiceId: "",
  userServiceName: "",
  userServiceField: null,
  isLocked: false
};

export default class EventVariableImpl extends VariableImpl implements EventVariableModel {
  userServiceId: string;
  userServiceName: string;
  userServiceField: UserServiceField;
  operator?: string;
  dataType?: DataType;

  readonly type = VariableType.Event;

  constructor(model: Partial<EventVariableModel>) {
    super(model);
    this.assign(model);
  }
  isPinned?: boolean;
  isLocked: boolean;

  protected assign(model: Partial<EventVariableModel>) {
    const evModel: Partial<EventVariableModel> = {};
    defaults(evModel, model, DEFAULTS);
    const { userServiceId, userServiceField, operator = "=", value, isLocked } = evModel;

    this.userServiceId = userServiceId;
    this.userServiceField = userServiceField;
    this.operator = operator;
    this.dataType = userServiceField?.dataType;

    if (isLocked) {
      this.isLocked = isLocked;
    }

    if (value && !operator) {
      const values = Array.isArray(value) ? value : [value];
      const { operator, values: fValues } = getFinalValuesAndOperatorForDataType(values, this.dataType);
      this.operator = operator;
      this.value = fValues;
    }
    super.assign(evModel);
  }

  update(model: Partial<EventVariableModel>) {
    this.assign(model);
  }

  setDefaultValue(value: string) {
    this.defaultValue = value;
  }

  setOperator(operator: EntityOperation) {
    this.operator = operator;
  }

  setValue(value: string | string[]) {
    this.value = value;
  }

  setUserServiceField(field: UserServiceField) {
    this.userServiceField = field;
  }

  getFilterExpression(): UserServiceFilterExpression {
    return {
      field: this.userServiceField,
      operator: this.operator,
      value: isArray(this.value) ? "" : this.value,
      values: isArray(this.value) ? this.value : []
    };
  }

  getFilterQueryParams(): QueryParamDTO {
    if (isArray(this.value) ? this.value.length > 0 : Boolean(this.value)) {
      const fieldLabel = FieldPickerUtils.getUserServiceFieldLabel(this.userServiceField);
      const name = this.userServiceId ? `${this.userServiceId}.${fieldLabel}` : fieldLabel;
      const entityOperatorKey = getKeyByValue(this.operator, EntityOpToTraceQueryOpMap) as EntityOperation;
      return getFilterExprQueryParam(name, entityOperatorKey, this.value, EVENT_VAR_URL_PREFIX);
    }
    return null;
  }

  // ovveriding parent class since that functionality is not required
  adjustValueAndDefaultValue() {}
}
