import React, { FC, useMemo, useState, useCallback } from "react";
import { IncSelect, IncSelectOption, IncFaIcon } from "@inception/ui";
import cloneDeep from "lodash/cloneDeep";
import { AxisLabelsFormatterContextObject } from "highcharts";
import BarColumnChartOptionsBuilder from "../../../../../../components/bar-chart/BarColumnChartOptionsBuilder";
import { BarColumnChart } from "../../../../../../components/charts/common";
import { USFWRendererProps } from "../types";
import { VerticallyCenteredRow, SortAndLimitSelector, SortAndLimit } from "../../../../../../components";
import { ENTITY_TAG, pluralizeWord } from "../../../../../../utils";
import { InsightsRenderer } from "../insights";
import { EntityOpToTraceQueryOpMap } from "../../../../../../services/api/explore";
import { useFetchHistogramInsightsData } from "./useFetchData";
import { BinSelector } from "./BinSelector";

export const HistogramInsightsRenderer: FC<USFWRendererProps> = props => {
  const { loadingElement, noDataElement, aggregatedTags, dataFetchPayload, properties, widgetTitle } = props;

  const { histogramInsights } = properties || {};

  const defSortAndLimit: SortAndLimit = {
    limit: histogramInsights?.insightsLimit?.limit || 5,
    sortType: histogramInsights?.insightsLimit?.function || "top"
  };
  const [sortAndLimit, setSortAndLimit] = useState<SortAndLimit>(defSortAndLimit);

  const defGroupBy = histogramInsights?.binsGroupBy || aggregatedTags.slice(0, 1);
  const [binsGroupBy, setBinsGroupBy] = useState(defGroupBy);

  const defSliceBy = histogramInsights?.binsSliceBy || [aggregatedTags[1] || aggregatedTags[0]];
  const [sliceBy, setSliceBy] = useState(defSliceBy);

  useMemo(() => {
    properties.histogramInsights = {
      ...(properties.histogramInsights || ({} as any)),
      binsGroupBy
    };
  }, [binsGroupBy, properties]);

  useMemo(() => {
    properties.histogramInsights = {
      ...(properties.histogramInsights || ({} as any)),
      binsSliceBy: sliceBy
    };
  }, [properties, sliceBy]);

  useMemo(() => {
    properties.histogramInsights = {
      ...(properties.histogramInsights || ({} as any)),
      insightsLimit: {
        limit: sortAndLimit.limit,
        function: sortAndLimit.sortType
      }
    };
  }, [properties, sortAndLimit]);

  const optionsBuilder = useMemo(
    () => new BarColumnChartOptionsBuilder({ barDimension: 25 }).setLayout("vertical").setToolTip(true),
    []
  );

  const groupByOpts = useMemo(
    () =>
      (dataFetchPayload?.sliceSpec?.[0]?.sliceSet?.slices || []).map(slice => {
        const { tagName, entityTypeName } = slice;

        return {
          label: tagName === ENTITY_TAG ? entityTypeName : tagName,
          value: tagName
        };
      }),
    [dataFetchPayload]
  );

  const sliceByOpt = useMemo(() => groupByOpts.find(opt => sliceBy.includes(opt.value)), [groupByOpts, sliceBy]);
  const groupByOpt = useMemo(
    () => groupByOpts.find(opt => binsGroupBy.includes(opt.value)),
    [binsGroupBy, groupByOpts]
  );

  const onSliceByChange = useCallback((opt: IncSelectOption) => {
    const { value } = opt;
    setSliceBy([value]);
  }, []);

  const onGroupByChange = useCallback((opt: IncSelectOption) => {
    const { value } = opt;
    setBinsGroupBy([value]);
  }, []);

  const {
    barSeries,
    binStartArr,
    dataExists,
    isError,
    isFetching,
    splineSeries,
    filters,
    resetFilters,
    ...binSelectorProps
  } = useFetchHistogramInsightsData({
    ...props,
    sliceBy,
    binsGroupBy
  });

  const filtersStr = useMemo(() => {
    const filterStrs = filters.map(filter => {
      const { op, value } = filter;
      return `${EntityOpToTraceQueryOpMap[op]} ${value.doubleVal}`;
    });

    const filtersStr = filterStrs.join(" AND ");

    if (filtersStr) {
      return `Showing data for bin ${filtersStr}`;
    }

    return null;
  }, [filters]);

  const iDataFetchPayload = useMemo(() => {
    const nDataFetchPayload = cloneDeep(dataFetchPayload);
    if (nDataFetchPayload) {
      nDataFetchPayload.sliceSpec.forEach(ss => {
        ss.postAgg.filters = filters;

        ss.postAgg.sortSpec = {
          limitSpec: {
            function: sortAndLimit.sortType,
            limit: sortAndLimit.limit
          },
          sortBy: "current"
        };

        ss.postAgg.isSingleStatQuery = true;
      });
      return nDataFetchPayload;
    }

    return null;
  }, [dataFetchPayload, filters, sortAndLimit.limit, sortAndLimit.sortType]);

  const xAxisTitle = widgetTitle;
  const yAxisTitle = `No. of ${pluralizeWord(binsGroupBy[0])}`;

  return (
    <div className="us-field-container--visualisation--histogram">
      <VerticallyCenteredRow className="marginBt12 group-selectors">
        <IncSelect
          isSearchable={false}
          label="Histogram by"
          onChange={onGroupByChange}
          options={groupByOpts}
          value={groupByOpt}
        />

        <IncSelect
          isSearchable={false}
          label="Slice by"
          onChange={onSliceByChange}
          options={groupByOpts}
          value={sliceByOpt}
          wrapperClass="marginLt10 marginRt10"
        />

        <BinSelector {...binSelectorProps} />
      </VerticallyCenteredRow>

      <div className="histogram-container">
        {isFetching && loadingElement}
        {!isFetching && (
          <>
            {(!dataExists || isError) && noDataElement}
            {dataExists && !isError && (
              <BarColumnChart
                containerElemClass="histogram-container"
                optionsBuilder={optionsBuilder}
                series={barSeries}
                showLegend={false}
                splineSeries={splineSeries}
                stacked
                title=""
                xAxisCategories={binStartArr}
                xAxisLabelFormatter={xAxisLabelFormatter}
                xAxisTitle={xAxisTitle}
                yAxisTitle={yAxisTitle}
              />
            )}
          </>
        )}
      </div>

      <VerticallyCenteredRow className="marginBt8">
        <SortAndLimitSelector
          onChange={setSortAndLimit}
          sortAndLimit={sortAndLimit}
        />

        {filtersStr && (
          <>
            <div className="inc-text-subtext-medium">{filtersStr}</div>

            <IncFaIcon
              className="inc-cursor-pointer marginLt10"
              iconName="undo"
              onClick={resetFilters}
              title="Reset filters"
            />
          </>
        )}
      </VerticallyCenteredRow>

      <InsightsRenderer
        {...props}
        dataFetchPayload={iDataFetchPayload}
        seriesLimit={-1}
      />
    </div>
  );
};

const xAxisLabelFormatter = function (ctx: AxisLabelsFormatterContextObject) {
  const { value, isFirst, axis, pos } = ctx;

  const binLabels = axis.categories;

  if (isFirst) {
    return `<= ${value}`;
  }

  const prev = binLabels[pos - 1];
  const prevNum = parseInt(prev, 10);

  if (prev && !isNaN(prevNum)) {
    const start = (prevNum + 1).toString();
    return `${start} - ${value}`;
  }

  return value.toString();
};
