import { HeatMap } from "@nivo/heatmap";
import React, { FC, useEffect, useState, useMemo, useCallback, useRef, memo } from "react";
import { heatMapColorsByRange } from "../../operationalise-v2/v3/results/heat-map-v2/utils";
import getChartColor from "../charts/colors";
import { getHeatMapAxes } from "./axes";
import { HeatMapTooltip } from "./HeatMapTooltip";
import { IncHeatMapAxisTickPropsFunction, IncHeatMapSerie, IncHeatMapTooltipFormatter } from "./types";

interface Props {
  series: IncHeatMapSerie[];
  containerElemClass: string;
  getColorByValue?: (value: number) => string;
  tooltipFormatter?: IncHeatMapTooltipFormatter;
  getXAxisValue?: IncHeatMapAxisTickPropsFunction;

  forceSquare?: boolean;
  xInnerPadding?: number;
  yInnerPadding?: number;
  borderRadius?: number;
  xAxisOnBottom?: boolean;
  verticalMargin?: number;
}

export const HeatMapV2: FC<Props> = memo(props => {
  const {
    series,
    containerElemClass,
    tooltipFormatter,
    getColorByValue,
    getXAxisValue,
    forceSquare,
    xInnerPadding = 0.25,
    yInnerPadding = 0.25,
    borderRadius = 6,
    xAxisOnBottom = false,
    verticalMargin = 16
  } = props;

  const [dimensions, setDimensions] = useState<Pick<DOMRect, "width" | "height">>({
    height: 0,
    width: 0
  });

  const { height, width } = dimensions;

  const chartContainerRef = useRef<HTMLDivElement>();

  const getChartDimensions = useCallback(() => {
    const canvasElem: HTMLElement = chartContainerRef.current;
    if (canvasElem) {
      const widgetItemContainer: Element = canvasElem.closest(`.${containerElemClass}`);
      const dimensions = widgetItemContainer?.getBoundingClientRect();
      if (dimensions) {
        const { width } = dimensions;
        const { height } = dimensions;
        return {
          width,
          height
        };
      }
    }
    return {
      width: 0,
      height: 0
    };
  }, [containerElemClass]);

  useEffect(() => {
    if (!width && !height) {
      // Calculate dimensions on every render
      const dimensions = getChartDimensions();
      setDimensions(dimensions);
    }
  }, [dimensions.height, dimensions.width, getChartDimensions, height, width]);

  const { axisLeft, axisTop, axisBottom } = useMemo(
    () => getHeatMapAxes(getXAxisValue, xAxisOnBottom),
    [getXAxisValue, xAxisOnBottom]
  );

  const getColor = useMemo(() => getColorByValue || getChartColor, [getColorByValue]);

  const { colors, domain } = useMemo(() => {
    let min = Number.POSITIVE_INFINITY;
    let max = Number.NEGATIVE_INFINITY;

    const colorsSet = new Set<string>();
    series.forEach(s => {
      s.data.forEach(d => {
        const datum = d.y;

        min = Math.min(min, datum);
        max = Math.max(max, datum);

        const color = getColor(datum);
        colorsSet.add(color);
      });
    });

    const colors = Array.from(colorsSet);
    const allColors = Object.values(heatMapColorsByRange);
    const colorIndexMap = new Map();
    allColors.forEach((element, index) => {
      colorIndexMap.set(element, index);
    });
    colors.sort((a, b) => {
      const indexA = colorIndexMap.get(a);
      const indexB = colorIndexMap.get(b);
      if (indexA < indexB) {
        return -1;
      } else if (indexA > indexB) {
        return 1;
      }
      return 0;
    });

    return {
      colors: colors?.length > 0 ? colors : [heatMapColorsByRange["0"]],
      domain: [min === Number.POSITIVE_INFINITY ? 0 : min, max === Number.NEGATIVE_INFINITY ? 0 : max] as [
        number,
        number
      ]
    };
  }, [getColor, series]);

  return (
    <div
      className="inc-heatmap-v2"
      ref={chartContainerRef}
    >
      <HeatMap
        activeOpacity={1}
        animate={false}
        axisBottom={axisBottom}
        axisLeft={axisLeft}
        axisTop={axisTop}
        borderColor="transparent"
        borderRadius={borderRadius}
        borderWidth={0}
        colors={{
          type: "quantize",
          colors,
          domain
        }}
        data={series}
        emptyColor="transparent"
        enableLabels={false}
        forceSquare={forceSquare}
        height={height}
        hoverTarget="cell"
        inactiveOpacity={0.5}
        isInteractive
        margin={{
          top: axisTop ? verticalMargin : 0,
          left: 32,
          bottom: axisBottom ? verticalMargin : 0
        }}
        tooltip={props => (
          <HeatMapTooltip
            {...props}
            formatter={tooltipFormatter}
          />
        )}
        width={width}
        xInnerPadding={xInnerPadding}
        yInnerPadding={yInnerPadding}
      />
    </div>
  );
});
