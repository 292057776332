import { IncFaIcon, IncClickAwayPopper, IncRTable, IncRTableProps, TableDataColumn, getDuration } from "@inception/ui";
import { camelCase } from "lodash";
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { VerticallyCenteredRow } from "../../../../../components";
import { logger, TimeRange, useInceptionRoute, useTenantConfig, useToggleState } from "../../../../../core";
import { TenantDrilldownVersion } from "../../../../../core/hooks/tenant-config";
import commonAlertsApiService, {
  AlertAction,
  CommonAlertsResponseItem,
  FilterField
} from "../../../../../services/api/CommonAlerts";
import timeRangeUtils from "../../../../../utils/TimeRangeUtils";
import { WidgetCustomAction } from "../../../types";
import { useIsWidgetVisible } from "../../../useIsWidgetVisible";

export const useAlertsAction = (
  widgetId: string,
  title: string,
  timeRange: TimeRange,
  headerRef: React.RefObject<HTMLDivElement>,
  op10zeExist: boolean
) => {
  const { close: stopLoading, open: startLoading, isOpen: isAlertsLoading } = useToggleState(false);

  const { close: closeAlertsPopper, isOpen: isAlertsPopperOpen, open: openAlertsPopper } = useToggleState();

  const shouldRefetch = useRef(true);
  const iconRef = useRef<HTMLDivElement>();

  const isInView = useIsWidgetVisible(iconRef);

  const { fromMillis, toMillis } = useMemo(() => timeRangeUtils.getMillisFromTimeRange(timeRange), [timeRange]);

  // Update this ref to refetch the data when popper is open since either of widgetId, fromMillis, toMillis have changed
  useMemo(() => {
    shouldRefetch.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromMillis, toMillis, widgetId]);

  const [alerts, setAlerts] = useState<CommonAlertsResponseItem[]>([]);
  const [errorMessage, setErrorMessage] = useState("");

  const numAlerts = alerts?.length || 0;

  const fetchFilteredAlerts = useCallback(async () => {
    if (widgetId && isInView && shouldRefetch.current) {
      startLoading();
      setAlerts([]);

      try {
        const filters: FilterField[] = [
          {
            filterField: "alertParams.widgetId",
            value: {
              stringVal: widgetId
            }
          },
          {
            filterField: "alertStatus",
            value: {
              stringVal: "active"
            }
          }
        ];

        const { alertResponse } = await commonAlertsApiService.fetchFilteredAlerts(
          "incident",
          fromMillis,
          toMillis,
          filters,
          {
            includeIncidentPayload: true,
            actions: true,
            lookupData: true
          }
        );

        const { alertEventRecords } = alertResponse;
        setAlerts(alertEventRecords);
      } catch (err) {
        logger.error("Use Alerts Action", `Failed to fetch alerts for widget: ${title}`, err);
        setErrorMessage("Failed to fetch alerts");
      } finally {
        stopLoading();
        shouldRefetch.current = false;
      }
    }
  }, [fromMillis, isInView, startLoading, stopLoading, title, toMillis, widgetId]);

  useEffect(() => {
    fetchFilteredAlerts();
  }, [fetchFilteredAlerts]);

  const actionRenderer = useMemo(
    () => (
      <>
        <VerticallyCenteredRow
          className="catalog-alerts-action"
          data-alerts-exist={numAlerts > 0}
        >
          <VerticallyCenteredRow
            className="inc-cursor-pointer"
            onClick={openAlertsPopper}
          >
            <VerticallyCenteredRow
              className="catalog-alerts-action--alerts-pill"
              ref={iconRef}
            >
              <IncFaIcon
                className="bell-icon"
                fade={isAlertsLoading}
                iconName="bell"
                regular
              />

              <VerticallyCenteredRow className="marginLt6 inc-text-subtext-medium">
                {numAlerts > 9 ? "9+" : numAlerts}
              </VerticallyCenteredRow>
            </VerticallyCenteredRow>
          </VerticallyCenteredRow>
        </VerticallyCenteredRow>

        <IncClickAwayPopper
          anchorEl={headerRef.current}
          className="catalog-alerts-popper"
          offset={offset}
          onClickAway={closeAlertsPopper}
          overlay
          placement="bottom"
          show={isAlertsPopperOpen}
        >
          <div className="marginBt10">Alerts</div>
          <IncRTable
            classNames={{
              table: "catalog-alerts-table"
            }}
            columns={columns}
            data={alerts}
            density="compact"
            errorDataMessage={errorMessage}
            hasError={Boolean(errorMessage)}
            isLoading={isAlertsLoading}
            noDataMessage="No alerts found"
            pagination={pagination}
            showDisplayStats
            sort={sort}
          />
        </IncClickAwayPopper>
      </>
    ),
    [
      alerts,
      closeAlertsPopper,
      errorMessage,
      headerRef,
      isAlertsLoading,
      isAlertsPopperOpen,
      numAlerts,
      openAlertsPopper
    ]
  );

  return useMemo<WidgetCustomAction>(
    () =>
      widgetId && op10zeExist
        ? {
            actionComponent: actionRenderer,
            showInHeader: true,
            tooltipText: ""
          }
        : null,
    [actionRenderer, op10zeExist, widgetId]
  );
};

const offset = {
  x: 10,
  y: 10
};

const pagination: IncRTableProps["pagination"] = {
  enabled: true,
  viewMode: "minimal",
  pageSize: 5
};

const sort: any = {
  accessor: "startTimeMillis",
  order: "desc"
};

const columns: Array<TableDataColumn<CommonAlertsResponseItem>> = [
  {
    accessor: "status",
    align: "center",
    header: "",
    width: 35,
    renderer: (status: string) => <div className={`status ${status}`} />
  },
  {
    accessor: "startTimeMillis",
    align: "center",
    header: "Start Time",
    sortable: true,
    type: "datetime"
  },
  {
    accessor: "duration",
    align: "center",
    header: "Duration",
    sortFn: (a, b) => {
      const durA = parseInt(a.duration, 10);
      const durB = parseInt(b.duration, 10);

      return durA > durB ? "greater" : durA < durB ? "lesser" : "equal";
    },
    renderer: (durationMillisStr: string) => getDuration(durationMillisStr),
    sortable: true
  },
  {
    accessor: "opConfigId",
    align: "center",
    header: "Operationalize",
    sortable: true,
    sortFn: (a, b) => {
      const opConfigIdA = a.opConfigId;
      const opConfigIdB = b.opConfigId;

      const opNameA = a.lookupData?.[opConfigIdA] || opConfigIdA;
      const opNameB = b.lookupData?.[opConfigIdB] || opConfigIdB;

      const sortRes = opNameA.localeCompare(opNameB);

      return sortRes === -1 ? "greater" : sortRes === 1 ? "lesser" : "equal";
    },
    renderer: (opConfigId: string, rowData: CommonAlertsResponseItem) => {
      const { lookupData } = rowData;
      return lookupData?.[opConfigId] || opConfigId;
    }
  },
  {
    accessor: "actions",
    align: "center",
    header: "",
    width: "50",
    renderer: (actions: CommonAlertsResponseItem["actions"]) => <AlertActions actions={actions} />
  }
];

type ActionsProps = {
  actions: AlertAction[];
};

export const AlertActions: FC<ActionsProps> = memo(props => {
  const { tenantConfigState } = useTenantConfig();

  const { navigate } = useInceptionRoute();
  const { actions } = props;

  const { defaultDrilldownVersion = TenantDrilldownVersion.v5 } = tenantConfigState || {};

  const { primaryAction } = useMemo(() => {
    let primaryAction: AlertAction;

    actions.forEach(action => {
      const { priority } = action;

      if (priority === 1) {
        primaryAction = action;
      }
    });

    return {
      primaryAction
    };
  }, [actions]);

  const onActionItemClick = useCallback(
    (event: React.MouseEvent, actionUrl: string, isPrimaryAction: boolean) => {
      const queryParams: Record<string, any> = {
        newTab: true
      };
      if (isPrimaryAction) {
        queryParams[defaultDrilldownVersion] = true;
      }

      navigate(actionUrl, queryParams);
    },
    [defaultDrilldownVersion, navigate]
  );

  const getActionItem = useCallback(
    (action: AlertAction, isPrimaryAction: boolean, className: string) => {
      if (action) {
        const { actionType, actionUrl } = action;

        const onClick = (evt: React.MouseEvent) => onActionItemClick(evt, actionUrl, isPrimaryAction);

        return (
          <div
            className={className}
            key={camelCase(actionType)}
            onClick={onClick}
          >
            {!isPrimaryAction && actionType}
            {isPrimaryAction && (
              <div className="inc-button inc-button-link">
                <IncFaIcon
                  iconName="external-link"
                  title={actionType}
                />
              </div>
            )}
          </div>
        );
      }

      return <></>;
    },
    [onActionItemClick]
  );

  return <div className="action-menu">{getActionItem(primaryAction, true, "action-menu-atem-primary")}</div>;
});
