import { AxiosError } from "axios";
import { useCallback, useMemo } from "react";
import { FetchCallBackResult, FetchFn, InitialState, useDataFetch } from "../../../../../../../../core";
import { BizDataQuery, RawDataResponse, exploreApiService } from "../../../../../../../../services/api/explore";

export const useFetchRawEvents = (
  bizDataQuery: BizDataQuery,
  entityId: string,
  entityType: string,
  startMillis: number,
  endMillis: number,
  limit?: number
) => {
  const initialState = useMemo<InitialState<RawDataResponse, string>>(
    () => ({
      data: null,
      error: null,
      isError: false,
      isFetching: false
    }),
    []
  );

  const fetchFn = useCallback<FetchFn<RawDataResponse, string>>(async () => {
    const result: FetchCallBackResult<RawDataResponse, string> = {
      data: null,
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const { data, error, message } = await exploreApiService.getWidgetRawEvents(
        entityId,
        entityType,
        startMillis,
        endMillis,
        bizDataQuery,
        limit
      );

      if (error) {
        result.isError = true;
        result.error = message;
      } else {
        result.isSuccess = true;
        result.data = data;
      }
      result.isSuccess = true;
      result.data = data;
    } catch (err) {
      result.isError = true;
      const axiosError = err as AxiosError;
      if (axiosError.isAxiosError) {
        result.error = axiosError.message;
      } else {
        result.error = err.message?.toString() || err.toString();
      }
    }

    return result;
  }, [bizDataQuery, endMillis, entityId, entityType, limit, startMillis]);

  return useDataFetch(fetchFn, initialState, true);
};
