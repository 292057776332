import { isNumber } from "lodash";
import { formatNumber } from "@inception/ui";
import { checkIfTagEntryMatches, dataTypeManager } from "../../../utils";
import { BizEntityDataResult } from "../../../dashboard/widgets/utils";
import { ImpactedWidget } from "../../../services/api/explore";

export const getDataValue = (data: BizEntityDataResult, tagsData: Record<string, string>) => {
  const dataArr = Object.values(data?.postAggData || {})[0]?.data || [];

  if (dataArr.length) {
    const match = dataArr.find(entry => {
      const { labels } = entry;
      const tagsMatch = checkIfTagEntryMatches(tagsData, labels);
      return tagsMatch;
    });

    if (match) {
      const { fields } = match;
      const dataValue = (fields[1]?.data?.[0] as number)?.toFixed(2) ?? "-";

      let numDataValue = parseFloat(dataValue);
      numDataValue = !isNaN(numDataValue) ? numDataValue : null;

      return numDataValue;
    }

    return null;
  }

  return null;
};

export const getFormattedValueForImpactedWidget = (impactedWidget: ImpactedWidget, value: number) => {
  const { dataType, subType } = impactedWidget;

  let formattedValue = isNumber(value) ? formatNumber(value) : "-";

  if (dataType) {
    const { getFormattedValue } = dataTypeManager.getDataTypeDescriptorByKind(dataType, subType) || {};

    if (isNumber(value) && getFormattedValue) {
      formattedValue = getFormattedValue(value, {
        appendUnitAtFirst: subType === "currency",
        numberFormatOptions: {
          compact: true
        }
      }) as string;
    }
  }

  return formattedValue;
};
