import { IncButton, IncPopper } from "@inception/ui";
import { css } from "emotion";
import { clone, isEqual, uniq } from "lodash";
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { LoadingSpinner, MultipleSelects, VerticallyCenteredRow } from "../../../../../components";
import { logger, TimeRange, useToggleState } from "../../../../../core";
import { CheckSuccessIcon, FilterIcon14 } from "../../../../../core/iconwrapper";
import { SelectorSpec, UserServiceFieldSlice, UserServiceFieldSliceSet } from "../../../../../services/api/explore";
import { ENTITY_TAG } from "../../../../../utils";
import timeRangeUtils from "../../../../../utils/TimeRangeUtils";
import { WidgetCustomAction } from "../../../types";
import { useFetchFunnelResultSchema } from "../../hooks";

type Props = {
  funnelId: string;
  sliceTags: string[];
  sliceSets: UserServiceFieldSliceSet[];

  timeRange: TimeRange;

  selectedFilters: SelectorSpec["filters"];
  setSelectedFilters: (nextFilters: SelectorSpec["filters"]) => void;

  isFunnelConfigFetching: boolean;
  bizEntityType: string;
};

export const useSelectFiltersAction = (props: Props) => {
  const {
    funnelId,
    isFunnelConfigFetching,
    selectedFilters,
    setSelectedFilters,
    sliceSets,
    sliceTags,
    timeRange,
    bizEntityType
  } = props;

  const { fromMillis: startTimeMillis, toMillis: endTimeMillis } = useMemo(
    () => timeRangeUtils.getMillisFromTimeRange(timeRange),
    [timeRange]
  );

  const prevSliceSet = useRef<UserServiceFieldSliceSet>();

  const sliceSet = useMemo<UserServiceFieldSliceSet>(() => {
    if (sliceTags) {
      const allSlices = (sliceSets || []).reduce(
        (acc, curr) => [...acc, ...curr.slices],
        [] as UserServiceFieldSlice[]
      );

      const slicesRec: Record<string, UserServiceFieldSlice> = {};

      (sliceTags || []).forEach(sliceTag => {
        const slice = allSlices.find(slice => slice.tagName === sliceTag);
        if (!slice) {
          logger.error("Funnel Filters", "Error getting slice for tagName: ", sliceTag);
        } else {
          slicesRec[sliceTag] = slice;
        }
      });

      const nextSliceSet: UserServiceFieldSliceSet = {
        slices: Object.values(slicesRec)
      };

      if (!isEqual(prevSliceSet.current, nextSliceSet)) {
        prevSliceSet.current = nextSliceSet;
      }

      return prevSliceSet.current;
    }

    return null;
  }, [sliceSets, sliceTags]);

  const slicesExist = sliceSet?.slices?.length;

  const {
    data: resultSchemaResponse,
    error: resultSchemaFetchError,
    isError: isResultSchemaError,
    isFetching: isResultSchemaFetching,
    refetch: fetchResultSchema
  } = useFetchFunnelResultSchema(funnelId, startTimeMillis, endTimeMillis, sliceSet);

  useEffect(() => {
    if (!isFunnelConfigFetching && slicesExist) {
      fetchResultSchema();
    }
  }, [fetchResultSchema, isFunnelConfigFetching, sliceSet, slicesExist]);

  useEffect(() => {
    if (isResultSchemaError) {
      logger.error("Funnel Filters", "Error fetching result schema: ", resultSchemaFetchError);
    }
  }, [isResultSchemaError, resultSchemaFetchError]);

  const { entityLookupData: entityLookUpData, resultSchema } = resultSchemaResponse || {};

  const { schemaOptionsMap, optionsLabelLookup, skipAllOption } = useMemo(() => {
    const schemaOptionsMapSet = new Map<string, Set<string>>();
    const optionsLabelLookup: Record<string, Record<string, string>> = {};
    const skipAllOption: Record<string, boolean> = {};

    if (resultSchema && sliceTags) {
      resultSchema.forEach(schemaEntry => {
        sliceTags.forEach(sliceTag => {
          const sliceValuesSet = schemaOptionsMapSet.get(sliceTag) || new Set<string>();
          const sliceValue = schemaEntry[sliceTag];
          sliceValuesSet.add(sliceValue);

          schemaOptionsMapSet.set(sliceTag, sliceValuesSet);
        });
      });
    }

    const schemaOptionsMap = new Map<string, string[]>();
    schemaOptionsMapSet.forEach((valuesSet, sliceTag) => {
      const valuesArr = Array.from(valuesSet).sort((a, b) =>
        a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())
      );
      schemaOptionsMap.set(sliceTag, valuesArr);

      optionsLabelLookup[sliceTag] = entityLookUpData;
      skipAllOption[sliceTag] = true;
    });

    return {
      schemaOptionsMap,
      optionsLabelLookup,
      skipAllOption
    };
  }, [entityLookUpData, resultSchema, sliceTags]);

  const viewFiltersAction = useMemo(() => {
    let viewFiltersAction: WidgetCustomAction = {
      actionComponent: <LoadingSpinner titleText=" " />,
      showInHeader: true,
      tooltipText: "Loading filters..."
    };

    if (!isFunnelConfigFetching && !isResultSchemaFetching) {
      viewFiltersAction.actionComponent = (
        <FilterSelector
          bizEntityType={bizEntityType}
          optionsLabelLookup={optionsLabelLookup}
          schemaFetchError={resultSchemaFetchError}
          schemaOptionsMap={schemaOptionsMap}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          skipAllOption={skipAllOption}
        />
      );
      viewFiltersAction.tooltipText = "Filters";
    }

    if (!slicesExist) {
      viewFiltersAction = null;
    }

    return viewFiltersAction;
  }, [
    bizEntityType,
    isFunnelConfigFetching,
    isResultSchemaFetching,
    optionsLabelLookup,
    resultSchemaFetchError,
    schemaOptionsMap,
    selectedFilters,
    setSelectedFilters,
    skipAllOption,
    slicesExist
  ]);

  return viewFiltersAction;
};

type FilSelectorProps = Pick<Props, "selectedFilters" | "setSelectedFilters" | "bizEntityType"> & {
  optionsLabelLookup: Record<string, Record<string, string>>;
  schemaOptionsMap: Map<string, string[]>;
  schemaFetchError: string;
  skipAllOption: Record<string, boolean>;
};

const FilterSelector: FC<FilSelectorProps> = memo(props => {
  const {
    optionsLabelLookup,
    schemaOptionsMap,
    selectedFilters: pSelectedFilters,
    setSelectedFilters,
    schemaFetchError,
    bizEntityType,
    skipAllOption
  } = props;

  const iconRef = useRef<HTMLDivElement>();

  const { close, isOpen, open } = useToggleState();

  const filtersExist = useMemo(() => {
    let filtersExist = false;

    pSelectedFilters?.forEach(selFilter => {
      if (!filtersExist) {
        selFilter.tags.forEach(selTag => {
          filtersExist = filtersExist || Boolean(selTag?.value?.length);
        });
      }
    });

    return filtersExist;
  }, [pSelectedFilters]);

  const defSelectedOptions = useMemo(() => getMapFromSelection(pSelectedFilters), [pSelectedFilters]);
  const [selectedOptions, setSelectedOptions] = useState(defSelectedOptions);

  useEffect(() => {
    setSelectedOptions(defSelectedOptions);
  }, [defSelectedOptions]);

  const onSelectedOptionsChange = useCallback((selMap: Map<string, string[]>, id: string) => {
    setSelectedOptions(prevSelection => {
      const nextSelection = clone(prevSelection);

      const keysToUpdate = id ? [id] : Array.from(selMap.keys());
      keysToUpdate.forEach(id => {
        nextSelection.set(id, selMap.get(id));
      });

      return nextSelection;
    });
  }, []);

  const updateSelection = useCallback(() => {
    const nSelection = getSelectionFromMap(selectedOptions);
    setSelectedFilters(nSelection);
    close();
  }, [close, selectedOptions, setSelectedFilters]);

  const discardSelection = useCallback(() => {
    setSelectedOptions(defSelectedOptions);
    close();
  }, [close, defSelectedOptions]);

  return (
    <>
      <VerticallyCenteredRow ref={iconRef}>
        <VerticallyCenteredRow
          className="inc-flex-center-vertical svg-icon-with-overlay inc-cursor-pointer"
          onClick={open}
        >
          <FilterIcon14 className="base-svg" />
          {filtersExist && <CheckSuccessIcon className="overlay-svg--bottom-right" />}
        </VerticallyCenteredRow>
      </VerticallyCenteredRow>

      <IncPopper
        anchorEl={iconRef.current}
        className="padding16"
        show={isOpen}
      >
        <VerticallyCenteredRow className="marginBt12">Filters</VerticallyCenteredRow>

        <div className={wrapperClassName}>
          {!schemaFetchError && (
            <MultipleSelects
              groupOptions={schemaOptionsMap}
              hideOptionsCount
              labels={{ [ENTITY_TAG]: bizEntityType }}
              menuAsPortal
              onSeriesSelectionChanged={onSelectedOptionsChange}
              optionsLabelLookup={optionsLabelLookup}
              parentElmWidth={500}
              selectedOptions={selectedOptions}
              skipAllOption={skipAllOption}
            />
          )}
          {Boolean(schemaFetchError) && (
            <VerticallyCenteredRow className="inc-flex-center status-danger">
              Error fetching result schema: {schemaFetchError}
            </VerticallyCenteredRow>
          )}
        </div>

        <VerticallyCenteredRow className="marginTp12">
          <IncButton
            className="marginRt12"
            color="primary"
            onClick={updateSelection}
          >
            Apply
          </IncButton>

          <IncButton
            color="link"
            onClick={discardSelection}
          >
            Cancel
          </IncButton>
        </VerticallyCenteredRow>
      </IncPopper>
    </>
  );
});

const getMapFromSelection = (selection: SelectorSpec["filters"]): Map<string, string[]> => {
  const selectionMap = new Map<string, string[]>();

  selection.forEach(selFilter => {
    selFilter.tags.forEach(selTag => {
      const { key, value } = selTag;

      const existingSelectionValues = selectionMap.get(key) || [];
      selectionMap.set(key, uniq([...(value || []), ...existingSelectionValues]));
    });
  });

  return selectionMap;
};

const getSelectionFromMap = (selectionMap: Map<string, string[]>): SelectorSpec["filters"] => {
  const selection: SelectorSpec["filters"] = [
    {
      tags: []
    }
  ];

  selectionMap.forEach((selValues, tagKey) => {
    if (selValues?.length) {
      selection[0].tags.push({
        key: tagKey,
        value: selValues
      });
    }
  });

  return selection;
};

const wrapperClassName = css`
  padding: 16px;
  height: 240px;
  width: 300px;
  overflow: auto;
`;
