import React, { FC, useEffect, useMemo, useState } from "react";
import { IncPill } from "@inception/ui";
import { uniqBy } from "lodash";
import {
  BizDataQuery,
  BizFieldPredicate,
  UserServiceFilterExpression,
  UserServiceFilterExpressionTree,
  WidgetConfigUtils
} from "../../services/api/explore";
import {
  FieldPickerUtils,
  Options,
  fetchEntityPropertiesForIds,
  getFilterLabelsFromEntityFilters,
  isValidCohortId
} from "../../utils";
import { VerticallyCenteredRow } from "../flex-components";
import { useTenantConfig } from "../../core";
import LoadingSpinner from "../Loading/Loading";
import { CohortPill } from "../cohort";

type Props = {
  bizDataQuery: BizDataQuery;
  containerClassName?: string;
};

export const PredefinedFilters: FC<Props> = props => {
  const { bizDataQuery, containerClassName } = props;

  const { tenantConfigState } = useTenantConfig();
  const options: Options = useMemo(() => ({ currency: tenantConfigState?.currency || "USD" }), [tenantConfigState]);

  const [entityLookUp, setEntityLookUp] = useState<Record<string, string>>({});
  const [isFetchingLookUp, setIsFetchingLookUp] = useState(true);

  const entityFilters = useMemo(() => {
    const filters1 = bizDataQuery?.entityFilters || [];
    const filters2 = bizDataQuery?.widgetConfig?.entityFilters || [];
    return [...filters1, ...filters2];
  }, [bizDataQuery]);

  const entityFilterPills = useMemo(() => {
    let filters: BizFieldPredicate[] = [];
    entityFilters?.forEach(x => {
      filters = [...filters, ...x.filters];
    });
    if (filters.length > 0) {
      const entityFilterLabels = getFilterLabelsFromEntityFilters(filters, options);
      const pills = entityFilterLabels.map((label, idx) => (
        <IncPill
          key={idx}
          label={label}
        />
      ));

      return pills;
    }
    return null;
  }, [entityFilters, options]);

  const eventFilters = useMemo(() => {
    const sliceSpecMetricId = bizDataQuery?.sliceSpec?.metricId;
    const filters1 = bizDataQuery?.metricUserServiceFilters?.[sliceSpecMetricId] || {};
    const filters2 = bizDataQuery?.widgetConfig?.metricUserServiceFilters?.[sliceSpecMetricId] || {};

    return [filters1, filters2];
  }, [bizDataQuery]);

  const eventFilterList = useMemo(() => {
    const eFilters: UserServiceFilterExpression[] = [];
    eventFilters.forEach(ef => {
      ef.userServiceFilters?.forEach(x => {
        eFilters.push(...x.userServiceFilterExpressions);
      });
    });
    return uniqBy(
      eFilters,
      filter =>
        `${FieldPickerUtils.getUserServiceFieldLabel(filter.field)}-${filter.operator}-${filter.value ? filter.value : (filter.values || []).join(",")}`
    );
  }, [eventFilters]);

  const eventExpressionTrees = useMemo(() => {
    const eventExpressionTrees: UserServiceFilterExpressionTree[] = [];
    eventFilters.forEach(ef => {
      if (ef.expressionTree) {
        eventExpressionTrees.push(ef.expressionTree);
      }
    });
    return eventExpressionTrees;
  }, [eventFilters]);

  useEffect(() => {
    const fetchEntityLookup = async (entityIds: string[]) => {
      setIsFetchingLookUp(true);

      const entityPropertiesMap = await fetchEntityPropertiesForIds(new Set(entityIds), 0, Date.now());
      const entityLookUp: Record<string, string> = {};

      entityPropertiesMap.forEach((value, key) => {
        entityLookUp[key] = value.name;
      });
      setEntityLookUp(entityLookUp);
      setIsFetchingLookUp(false);
    };

    let entityIds: string[];
    if (eventExpressionTrees?.length) {
      entityIds = eventExpressionTrees.reduce(
        (a: string[], b) => [...a, ...WidgetConfigUtils.getEntityIdsFromFilterTree(b)],
        []
      );
    } else {
      entityIds = WidgetConfigUtils.getEntityIdsFromFilterExpressions(eventFilterList);
    }
    if (entityIds?.length > 0) {
      fetchEntityLookup(entityIds);
    } else {
      setIsFetchingLookUp(false);
    }
  }, [eventExpressionTrees, eventFilterList]);

  const eventFilterPills = useMemo(() => {
    if (isFetchingLookUp) {
      return <LoadingSpinner titleText="Fetching..." />;
    } else if (eventFilterList.length > 0 && !isFetchingLookUp) {
      const eventFilterLabels = eventFilterList.map(expr =>
        WidgetConfigUtils.getUsFilterExpressionLabel(expr, entityLookUp)
      );

      const pills = eventFilterLabels.map((label, idx) => (
        <IncPill
          key={idx}
          label={label}
        />
      ));

      return pills;
    } else if (eventExpressionTrees.length > 0 && !isFetchingLookUp) {
      const eventFilterLabels = eventExpressionTrees.map(exprTree =>
        WidgetConfigUtils.getUsFilterExpressionTreeLabel(exprTree, entityLookUp)
      );

      const pills = eventFilterLabels.map((label, idx) => (
        <IncPill
          key={idx}
          label={label}
        />
      ));

      return pills;
    }

    return null;
  }, [entityLookUp, eventExpressionTrees, eventFilterList, isFetchingLookUp]);

  const entityTypeId = bizDataQuery?.idProps?.primary?.bizEntityTypeId;
  const cohortId = isValidCohortId(bizDataQuery?.cohortId) ? bizDataQuery.cohortId : "";
  const cohortPills = useMemo(() => {
    if (entityTypeId && cohortId) {
      return (
        <CohortPill
          cohortId={cohortId}
          entityTypeId={entityTypeId}
        />
      );
    }
    return null;
  }, [cohortId, entityTypeId]);

  return (
    <VerticallyCenteredRow className={containerClassName}>
      {(Boolean(cohortPills) || Boolean(entityFilterPills) || Boolean(eventFilterPills)) && (
        <div className="inc-flex-column flex-gap-8">
          Filters:
          <VerticallyCenteredRow className="inc-flex-row-wrap flex-gap-8">
            {cohortPills} {entityFilterPills} {eventFilterPills}
          </VerticallyCenteredRow>
        </div>
      )}
    </VerticallyCenteredRow>
  );
};
