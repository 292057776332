import React from "react";

interface Props {
  className?: string;
}

export const IncGradientLoadingSpinner: React.FC<Props> = ({ className = "" }) => (
  <div className={`gradient-loading-spinner ${className}`}>{gradientLoadingIcon}</div>
);

const gradientLoadingIcon = (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 1.12C8 0.501441 8.50386 -0.00805173 9.11637 0.0782664C10.4067 0.260101 11.639 0.755331 12.7023 1.52786C14.0691 2.52089 15.0864 3.92112 15.6085 5.52786C16.1305 7.13461 16.1305 8.86539 15.6085 10.4721C15.0864 12.0789 14.0691 13.4791 12.7023 14.4721C11.3355 15.4652 9.68943 16 8 16C6.31056 16 4.6645 15.4652 3.29772 14.4721C1.93094 13.4791 0.913611 12.0789 0.391547 10.4721C-0.0145969 9.22215 -0.104776 7.8971 0.121007 6.61378C0.228189 6.00458 0.868447 5.68282 1.45673 5.87396C2.04502 6.06511 2.35509 6.69797 2.28122 7.31211C2.18187 8.13806 2.26211 8.98033 2.52191 9.77994C2.8978 10.9368 3.63027 11.945 4.61436 12.6599C5.59844 13.3749 6.78361 13.76 8 13.76C9.21639 13.76 10.4016 13.3749 11.3856 12.6599C12.3697 11.945 13.1022 10.9368 13.4781 9.77994C13.854 8.62308 13.854 7.37692 13.4781 6.22006C13.1022 5.0632 12.3697 4.05504 11.3856 3.34006C10.7055 2.84588 9.92921 2.5093 9.11298 2.34855C8.50608 2.22902 8 1.73856 8 1.12Z"
      fill="url(#paint0_linear_2023_6990)"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_2023_6990"
        x1="12"
        x2="3.9777e-07"
        y1="1.14286"
        y2="11.4286"
      >
        <stop stopColor="#39ACFF" />
        <stop
          offset="1"
          stopColor="#39ACFF"
          stopOpacity="0.25"
        />
      </linearGradient>
    </defs>
  </svg>
);
