import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { cloneDeep } from "lodash";
import { IncButton, IncTextfield } from "@inception/ui";
import { BizDataQuery, WidgetConfigUtils, exploreApiService } from "../../../../services/api/explore";
import { getWidgetConfigFromDto } from "../../../../utils/ExploreUtils";
import { logger, useToggleState } from "../../../../core";
import { OpContext } from "../../../context/types";
import { ENTITY_TAG } from "../../../../utils";
import DataQueryEditor from "./DataQueryEditor";
import { getMetricName } from "./utils";

type Props = {
  bizDataQuery: BizDataQuery;
  opBizDataQuery: BizDataQuery;
  entityTypeId?: string;
  eventTypeId?: string;
  onSave: (bizDataQuery: BizDataQuery) => void;
  pickerContext: OpContext;
  readonly?: boolean;
};

const CustomThresholdDataQuery: FC<Props> = props => {
  const {
    bizDataQuery: pBizDataQuery,
    entityTypeId,
    eventTypeId,
    onSave,
    pickerContext,
    opBizDataQuery,
    readonly = false
  } = props;
  const { entityTypeName, eventTypeName } = pickerContext || {};
  const [bizDataQuery, setBizDataQuery] = useState<BizDataQuery>(null);
  const { isOpen: isFetching, open: startFetching, close: stopFetching } = useToggleState(true);
  const { isOpen, open, close } = useToggleState();

  const fetchOrCreateWidgetConfig = useCallback(async () => {
    if (pBizDataQuery) {
      const dBizDataQuery = cloneDeep(pBizDataQuery);
      const { id: widgetId, widgetConfig, buildingBlockConfig } = dBizDataQuery;
      startFetching();
      if (widgetConfig) {
        delete dBizDataQuery["id"];
        delete dBizDataQuery["buildingBlockConfig"];
      } else if (buildingBlockConfig) {
        delete dBizDataQuery["id"];
        const widgetConfigDto = WidgetConfigUtils.getWidgetConfigDtoFromBuildingBlockConfig(buildingBlockConfig);
        dBizDataQuery.widgetConfig = getWidgetConfigFromDto(widgetConfigDto);
        dBizDataQuery.sliceSpec.metricId = dBizDataQuery?.sliceSpec?.buildingBlockConfigId;
        delete dBizDataQuery?.sliceSpec?.buildingBlockConfigId;
        delete dBizDataQuery["buildingBlockConfig"];
      } else if (widgetId) {
        try {
          const { data, error, message } = await exploreApiService.getWidgetConfig(eventTypeId, entityTypeId, widgetId);
          const { widgetConfig: widgetConfigDto } = data || {};
          if (!error && widgetConfigDto) {
            dBizDataQuery.widgetConfig = getWidgetConfigFromDto(widgetConfigDto);
            delete dBizDataQuery["buildingBlockConfig"];
            delete dBizDataQuery["id"];
          } else {
            logger.error("CustomThresholdQueryEditor", "Error fetching widgetConfig", message);
          }
        } catch (error) {
          logger.error("CustomThresholdQueryEditor", "Error fetching widgetConfig", error);
        }
      }
      setBizDataQuery(dBizDataQuery);
      stopFetching();
    }
  }, [entityTypeId, eventTypeId, pBizDataQuery, startFetching, stopFetching]);

  useEffect(() => {
    fetchOrCreateWidgetConfig();
  }, [fetchOrCreateWidgetConfig]);

  const { sliceSpec, widgetConfig } = bizDataQuery || {};
  const { dataDefinition } = widgetConfig || {};
  const { metrics = {} } = dataDefinition || {};
  const { sliceSet, metricId } = sliceSpec || {};
  const { slices = [] } = sliceSet || {};
  const label = useMemo(() => {
    const sliceString = slices.length
      ? slices.map(slice => (slice.tagName === ENTITY_TAG ? entityTypeName || eventTypeName : slice.tagName)).join(", ")
      : "";
    const aggString = getMetricName(metrics, metricId);
    return sliceString ? `${aggString} by ${sliceString}` : aggString;
  }, [entityTypeName, eventTypeName, metricId, metrics, slices]);

  return (
    <>
      {readonly && (
        <IncTextfield
          autoAdjustWidth
          className="width-100"
          readOnly
          value={label}
        />
      )}
      {!readonly && (
        <IncButton
          color="link"
          loading={isFetching}
          loadingText="Fetching Config..."
          onClick={open}
        >
          {label}
        </IncButton>
      )}
      {isOpen && (
        <DataQueryEditor
          bizDataQuery={bizDataQuery}
          isOpen
          onClose={close}
          onSave={onSave}
          opBizDataQuery={opBizDataQuery}
          pickerContext={pickerContext}
        />
      )}
    </>
  );
};

export default CustomThresholdDataQuery;
