import React from "react";
import { IncFaIcon } from "@inception/ui";
import { widgetRegistry, Dimensions } from "../WidgetRegistry";
import FunnelWidget from "./FunnelWidget";

widgetRegistry.register({
  id: "funnel",
  label: "Funnel",
  enableInDashboard: false,
  supportsComparison: false,
  supportsNativeEdit: false,
  iconComponent: (): JSX.Element => <IncFaIcon iconName="map" />,
  component: props => <FunnelWidget {...props} />,
  dimensions: (): Dimensions => ({
    w: 24,
    h: 12,
    minW: 12,
    minH: 8
  }),
  propertiesPanelComponent: () => <></>
});
