import React, { FC, useCallback, useMemo } from "react";
import { IncDurationSelector, IncSelect, IncSelectOption, IncSelectorDuration } from "@inception/ui";
import { isEqual } from "lodash";
import { CypressConstants } from "@bicycle/tests";
import { getDurationFromTimeObj, getTimeObjFromDuration } from "../../../../utils/DurationUtils";
import { TimeObj, TimeObjUnit } from "../../../../services/api/explore";

interface Props {
  rollingFreq: TimeObj;
  onChange: (timeObj: TimeObj, isPeriodOpt: boolean) => void;
  skipPrefix?: boolean;
  addPeriods?: boolean;
  isPeriodOpt?: boolean;
  readOnly?: boolean;
  skipQuickOptions?: boolean;
}

export const RollingFrequencyEditorV2: FC<Props> = props => {
  const {
    rollingFreq = {
      unit: TimeObjUnit.unset,
      value: 0
    },
    onChange,
    skipPrefix = false,
    addPeriods = false,
    isPeriodOpt = false,
    readOnly = false,
    skipQuickOptions = false
  } = props;

  const quickPeriodOpts = useMemo(() => getQuickPeriodOptions(), []);
  const quickOpts = useMemo(() => {
    const quickOpts = getQuickOptions(skipPrefix);

    if (addPeriods) {
      quickOpts.push(...quickPeriodOpts);
    }

    quickOpts.push(customOpt);

    return quickOpts;
  }, [addPeriods, quickPeriodOpts, skipPrefix]);

  const durationOpt = useMemo(() => {
    if (isPeriodOpt) {
      return quickPeriodOpts.find(opt => isEqual(opt.data, rollingFreq)) || customOpt;
    }

    return quickOpts.find(opt => isEqual(opt.data, rollingFreq)) || customOpt;
  }, [isPeriodOpt, quickOpts, quickPeriodOpts, rollingFreq]);

  const isCustomDuration = durationOpt.value === "custom";

  const duration = useMemo(() => getDurationFromTimeObj(rollingFreq), [rollingFreq]);

  const onDurationChange = useCallback(
    (selDuration: IncSelectorDuration) => {
      const { duration, durationType } = selDuration;
      const timeObj = getTimeObjFromDuration(duration as number, durationType);
      onChange(timeObj, false);
    },
    [onChange]
  );

  const onDurationOptChange = useCallback(
    (opt: IncSelectOption<TimeObj>) => {
      onChange(
        opt.data || {
          unit: TimeObjUnit.days,
          value: 2
        },
        opt.value?.includes(PERIOD_STR)
      );
    },
    [onChange]
  );

  const showSelect = readOnly ? !isCustomDuration : !skipQuickOptions;
  const showCustomDuration = skipQuickOptions ? true : isCustomDuration;

  const { attributes } = CypressConstants.components.Operationalize.Common;

  return (
    <>
      {showSelect && (
        <IncSelect
          autoAdjustWidth
          autoSort={false}
          classNamePrefix={attributes.rollingFrequencySelector}
          data-cy={attributes.rollingFrequencySelector}
          isSearchable={false}
          onChange={onDurationOptChange}
          options={quickOpts}
          value={durationOpt}
          wrapperClass="marginRt8 preset-duration-select"
        />
      )}

      {showCustomDuration && (
        <>
          <IncDurationSelector
            duration={duration}
            hideLabel
            onChange={onDurationChange}
          />
        </>
      )}
    </>
  );
};

const customOpt: IncSelectOption<TimeObj> = {
  label: "Custom",
  value: "custom",
  data: null
};

const PERIOD_STR = "_i_period";

const getQuickOptions = (skipPrefix = false): Array<IncSelectOption<TimeObj>> => [
  {
    label: skipPrefix ? "1 minute" : "past 1 minute",
    value: "1m",
    data: {
      unit: TimeObjUnit.minutes,
      value: 1
    }
  },
  {
    label: skipPrefix ? "5 minutes" : "past 5 minutes",
    value: "5m",
    data: {
      unit: TimeObjUnit.minutes,
      value: 5
    }
  },
  {
    label: skipPrefix ? "15 minutes" : "past 15 minutes",
    value: "15m",
    data: {
      unit: TimeObjUnit.minutes,
      value: 15
    }
  },
  {
    label: skipPrefix ? "30 minutes" : "past 30 minutes",
    value: "30m",
    data: {
      unit: TimeObjUnit.minutes,
      value: 30
    }
  },
  {
    label: skipPrefix ? "1 hour" : "past 1 hour",
    value: "1h",
    data: {
      unit: TimeObjUnit.hours,
      value: 1
    }
  },
  {
    label: skipPrefix ? "1 day" : "past 1 day",
    value: "1d",
    data: {
      unit: TimeObjUnit.days,
      value: 1
    }
  },
  {
    label: skipPrefix ? "1 week" : "past 1 week",
    value: "1w",
    data: {
      unit: TimeObjUnit.weeks,
      value: 1
    }
  },
  {
    label: skipPrefix ? "2 weeks" : "past 2 weeks",
    value: "2w",
    data: {
      unit: TimeObjUnit.weeks,
      value: 2
    }
  }
];

const getQuickPeriodOptions = (): Array<IncSelectOption<TimeObj>> => [
  {
    label: "last 1 daily period",
    value: `1d${PERIOD_STR}`,
    data: {
      unit: TimeObjUnit.days,
      value: 1
    }
  },
  {
    label: "last 1 weekly period",
    value: `1w${PERIOD_STR}`,
    data: {
      unit: TimeObjUnit.weeks,
      value: 1
    }
  }
];
