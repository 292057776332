import React, { FC, useMemo, useCallback, useState, useRef } from "react";
import { IncButton, IncFaIcon, IncMenuItem, IncMenuV2, IncSmartText, IncToolTip } from "@inception/ui";
import { cloneDeep } from "lodash";
import { KPI } from "../../services/api";
import { generateId, logger, useToggleState, useVerticalConfig } from "../../core";
import { VerticallyCenteredRow } from "../flex-components";
import { WidgetConfigUtils } from "../../services/api/explore";
import LoadingSpinner from "../Loading/Loading";
import { AddKPIEditor } from "./AddKPIEditor";
import { KPIEditModalWithPreview } from "./KPIEditModalWithPreview";
import { KPIEditorSection } from "./types";

interface Props {
  parentKpiId: string;
  relatedKPIs: KPI[];
  onChange: (relatedKPIs: KPI[]) => void;
}

export const RelatedKPIsEditor: FC<Props> = props => {
  const { onChange, relatedKPIs, parentKpiId } = props;

  const { useCaseApi } = useVerticalConfig();
  const { getKpisForSelectedUseCases } = useCaseApi;
  const existingKpisSet = useMemo(() => {
    const existingKpis = new Set(relatedKPIs.map(kpi => kpi.id));
    existingKpis.add(parentKpiId);
    return existingKpis;
  }, [parentKpiId, relatedKPIs]);

  const kpiOptions = useMemo<KPIOption[]>(() => {
    const useCaseKpis = getKpisForSelectedUseCases();
    const kpiOptions = useCaseKpis
      .filter(kpi => !existingKpisSet.has(kpi.id))
      .map(kpi => ({
        label: kpi.name,
        value: kpi.id,
        data: kpi
      }));
    kpiOptions.push({
      data: null,
      label: "Custom",
      value: null
    });

    return kpiOptions;
  }, [existingKpisSet, getKpisForSelectedUseCases]);

  const [kpiToClone, setKpiToClone] = useState<KPI>(null);
  const [isCloneConfigFetchInProgress, setIsCloneConfigFetchInProgress] = useState(false);
  const kpiIdToCloneRef = useRef<string>();

  const { isOpen: isAddKPIModalOpen, close: closeAddKPIModal, open: openAddKPIModal } = useToggleState();

  const onAddKPI = useCallback(
    // eslint-disable-next-line require-await
    async (kpi: KPI) => {
      onChange([...relatedKPIs, kpi]);
      closeAddKPIModal();
      setKpiToClone(null);
    },
    [closeAddKPIModal, onChange, relatedKPIs]
  );

  const onAddKPIFromOption = useCallback(
    (item: KPIOption) => {
      if (item.data) {
        onAddKPI(item.data);
      } else {
        openAddKPIModal();
      }
    },
    [onAddKPI, openAddKPIModal]
  );

  const addKpiButton = useMemo(() => {
    if (kpiOptions.length > 1) {
      return (
        <IncMenuV2
          label={
            <VerticallyCenteredRow className="inc-button inc-button-regular inc-button-link flex-gap-8 width-fit-content">
              <IncFaIcon iconName="plus-circle" />
              Add
            </VerticallyCenteredRow>
          }
          menuItemClassName="inc-text-subtext-medium"
          menuItems={kpiOptions}
          onMenuItemClick={onAddKPIFromOption}
        />
      );
    }

    return (
      <IncButton
        color="link"
        iconName="plus-circle"
        label="Add"
        onClick={openAddKPIModal}
      />
    );
  }, [kpiOptions, onAddKPIFromOption, openAddKPIModal]);

  const onRemoveRelatedKPI = useCallback(
    (kpiId: string) => {
      onChange(relatedKPIs.filter(kpi => kpi.id !== kpiId));
    },
    [onChange, relatedKPIs]
  );

  const onCloneRelatedKPI = useCallback(async (kpi: KPI) => {
    kpiIdToCloneRef.current = kpi.id;
    setIsCloneConfigFetchInProgress(true);

    const cloneKPI = cloneDeep(kpi);
    cloneKPI.id = generateId();
    cloneKPI.name = `[CLONE] ${cloneKPI.name}`;

    try {
      cloneKPI.bizDataQuery = await WidgetConfigUtils.getWidgetConfigBasedBizDataQuery(cloneKPI.bizDataQuery);
    } catch (err) {
      logger.error("RelatedKPIsEditor", "Error while getting clone config for KPI", err);
    } finally {
      kpiIdToCloneRef.current = null;
      setIsCloneConfigFetchInProgress(false);
      setKpiToClone(cloneKPI);
    }
  }, []);

  const relatedKPICards = useMemo(
    () =>
      relatedKPIs.map(kpi => {
        const onRemove = () => onRemoveRelatedKPI(kpi.id);
        const onClone = () => onCloneRelatedKPI(kpi);

        const showCloneButton = kpiIdToCloneRef.current !== kpi.id;

        return (
          <VerticallyCenteredRow
            className="related-kpis--entry"
            key={kpi.id}
          >
            <IncSmartText
              className="width-100"
              text={kpi.name}
            />

            <IncToolTip
              titleText="Clone"
              variant="info"
            >
              <VerticallyCenteredRow>
                {showCloneButton && (
                  <IncFaIcon
                    className={`paddingLt4 paddingRt4 status-info inc-cursor-pointer ${isCloneConfigFetchInProgress ? "disableClick" : ""}`}
                    iconName="clone"
                    onClick={onClone}
                  />
                )}
                {!showCloneButton && (
                  <LoadingSpinner
                    className="inc-text-subtext-medium disableClick"
                    titleText=" "
                  />
                )}
              </VerticallyCenteredRow>
            </IncToolTip>

            <IncToolTip
              titleText="Remove"
              variant="error"
            >
              <IncFaIcon
                className="paddingLt4 paddingRt4 status-danger inc-cursor-pointer"
                iconName="trash-alt"
                onClick={onRemove}
              />
            </IncToolTip>
          </VerticallyCenteredRow>
        );
      }),
    [isCloneConfigFetchInProgress, onCloneRelatedKPI, onRemoveRelatedKPI, relatedKPIs]
  );

  const onCancelKPIClone = useMemo(() => () => setKpiToClone(null), []);

  return (
    <div className="related-kpis">
      {relatedKPICards}
      {addKpiButton}

      {isAddKPIModalOpen && (
        <AddKPIEditor
          modalProps={{ onClose: closeAddKPIModal }}
          onAddKPI={onAddKPI}
        />
      )}

      {Boolean(kpiToClone) && (
        <KPIEditModalWithPreview
          kpi={kpiToClone}
          onClose={onCancelKPIClone}
          onKPIUpdate={onAddKPI}
          sectionsToShow={sectionsToShow}
          updateBtnLabel="Add"
        />
      )}
    </div>
  );
};

type KPIOption = IncMenuItem<KPI>;
const sectionsToShow: KPIEditorSection[] = ["meta", "preview", "query"];
