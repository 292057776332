import {
  IncDateTimeFormat,
  IncRTableProps,
  IncSmartText,
  TableDataColumn,
  getFormattedDateTime,
  IncRTable
} from "@inception/ui";
import React, { FC, useMemo } from "react";
import { VerticallyCenteredRow } from "../../..";
import { CorrelatedEventList } from "../../../../services/api/explore";

type Props = {
  isError: boolean;
  isFetching: boolean;
  error: string;
  selectionMap: Record<string, boolean>;
  correlatedEvents: CorrelatedEventList[];
};

type ChangeEventTableData = {
  timestamp: number;
  description: string;
  [key: string]: any;
};

const getSortResult = (value1: string | number, value2: string | number) =>
  value1 === value2 ? "equal" : value1 < value2 ? "lesser" : "greater";

const EventTable: FC<Props> = props => {
  const { selectionMap, isError, isFetching, error, correlatedEvents } = props;
  const columns = useMemo<Array<TableDataColumn<ChangeEventTableData>>>(() => {
    const selectedColumns: Array<TableDataColumn<ChangeEventTableData>> = [];
    Object.keys(selectionMap).forEach(e => {
      if (selectionMap[e]) {
        const column: TableDataColumn<ChangeEventTableData> = {
          accessor: e as keyof ChangeEventTableData,
          header: e,
          sortable: true,
          sortFn: (datumA, datumB, columnId) => {
            const name1 = String(datumA?.[columnId] || "");
            const name2 = String(datumB?.[columnId] || "");
            return getSortResult(name1, name2);
          },
          defaultSort: "desc",
          renderer: (cellData: any, rowData: ChangeEventTableData) => (
            <VerticallyCenteredRow>
              <IncSmartText
                className="marginRt6"
                text={rowData[e]}
              />
            </VerticallyCenteredRow>
          )
        };
        selectedColumns.push(column);
      }
    });
    const columns: Array<TableDataColumn<ChangeEventTableData>> = [
      {
        accessor: "timestamp",
        header: "Timestamp",
        sortable: true,
        sortFn: (datumA, datumB, columnId) => {
          const name1 = String(datumA?.[columnId] || "");
          const name2 = String(datumB?.[columnId] || "");
          return getSortResult(name1, name2);
        },
        defaultSort: "desc",
        width: "30%",
        renderer: (cellData, rowData: ChangeEventTableData) => {
          const timeStampInfo = getFormattedDateTime(rowData.timestamp, IncDateTimeFormat.minimal);
          return (
            <VerticallyCenteredRow>
              <IncSmartText
                className="marginRt6"
                text={timeStampInfo}
              />
            </VerticallyCenteredRow>
          );
        }
      },
      {
        accessor: "description",
        header: "Description",
        sortable: true
      }
    ];
    columns.push(...selectedColumns);
    return columns;
  }, [selectionMap]);

  const tableData = useMemo(() => {
    const rows: ChangeEventTableData[] = [];
    correlatedEvents?.[0]?.correlatedEventList?.forEach(val => {
      val?.correlatedEvents?.forEach(correlatedEvent => {
        const description = correlatedEvent?.entityChangeEvent?.description || "";
        const tags = correlatedEvent?.entityChangeEvent?.tags;
        const enrichedTags: Record<string, any> = {};

        if (description) {
          if (tags) {
            const tagKeys = Object.keys(tags);
            tagKeys.forEach(key => {
              const value = tags[key]?.stringVal;
              const effectiveValue = correlatedEvents?.[0]?.entityLookupData?.[value] || value;
              enrichedTags[key] = effectiveValue;
            });
          }
          rows.push({
            timestamp: parseInt(correlatedEvent?.timeStampMillis, 10),
            description,
            ...enrichedTags
          });
        }
      });
    });

    return rows;
  }, [correlatedEvents]);

  return (
    <div className="events-table--fields-table">
      <IncRTable
        columns={columns}
        data={tableData}
        errorDataMessage={error}
        globalFilter={globalFilter}
        hasError={isError}
        isLoading={isFetching}
        pagination={pagination}
        sort={{
          accessor: "timestamp",
          order: "desc"
        }}
      />
    </div>
  );
};

export default EventTable;

const globalFilter: IncRTableProps["globalFilter"] = {
  enabled: true,
  align: "left",
  hideLabel: true,
  hideStatus: true,
  placeholder: "Type a keyword"
};

const pagination: IncRTableProps["pagination"] = {
  enabled: true,
  viewMode: "minimal",
  pageSize: 50
};
