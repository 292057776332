import {
  IncidentSliceContribution,
  ImpactedWidgetList,
  ImpactedWidget,
  ImpactedWidgetListDTO,
  TimeObj,
  SelectorTag,
  CorrelatedEventsRequest,
  CorrelatedEventsResponse
} from "../../services/api/explore";

import { TimeRange } from "../../core";

export type KeyContributorsFetchState = {
  isFetching: boolean;
  error: string;
  keyContributors: IncidentSliceContribution[];
  lookupData: Record<string, string>;
};

export const INCIDENT_STATUS_KEY = "__incident_status__";

export interface IncidentTimelineContext {
  type: "incident" | "impactedWidget" | "monitoredSeries";
  uniqueAlerts: boolean;
  incidentId: string;
  impactedWidgetContext: IncidentTimelineImpactWidgetContext;
  opConfigId: string;
  timeRange: TimeRange;
  monitoredSeriesContext: IncidentTimelineMonitoredSeriesContext;
}

export interface IncidentTimelineImpactWidgetContext {
  impactedWidget: ImpactedWidget;
  alertingSlices: ImpactedWidgetList["alertingSlices"];
  diagnosticSlices: ImpactedWidgetList["diagnosticSlices"];
}

export interface IncidentTimelineMonitoredSeriesContext {
  tags: SelectorTag[];
  title: string;
  frequency: TimeObj;
  lookBack: TimeObj;
  mqId?: string;
  isActive?: boolean;
}

export type ImpactedWidgetsState = {
  impactedWidgetsLists: ImpactedWidgetListDTO[];
  isImpactedWidgetsFetching: boolean;
  isImpactedWidgetsFetchError: boolean;
  impactedWidgetsFetchError: string;
};

export type CorrelatedEventsSource = CorrelatedEventsPayloadSource | CorrelatedEventsDataSource;
export type CorrelatedEventsPayloadSource = {
  type: "payload";
  payload: CorrelatedEventsRequest;
};

export type CorrelatedEventsDataSource = {
  type: "data";
  data: CorrelatedEventsResponse;
};
