import { upperFirst } from "lodash";
import {
  BizDataQuery,
  EntitySlice,
  MetricDefinition,
  Slice,
  TimeObj,
  TimeObjUnit,
  WidgetConfigUtils
} from "../../../services/api/explore";
import {
  OpThreshold,
  ThresholdType,
  StaticThresholdDef,
  LookBackThreshold,
  PeriodicOffsetThreshold,
  CompareOperator,
  ThresholdDownSampleFunc,
  OpThresholdSimpleFunction,
  OpThresholdSeasonality,
  BaselineSeasonalDataConfig,
  ThresholdOpType
} from "../../../services/api/operationalise";
import { FieldPickerUtils } from "../../../utils";
import { getLabelForTimeObj } from "../../../utils/DurationUtils";
import { logger } from "../../../core";
import { THRESHOLD_TYPES, defThresholdOp, UI_THRESHOLD_KEY } from "../../../operationalise-v2/constants";
import { getThresholdDefByComparator } from "../../../operationalise-v2/utils";

export const getThresholdResult = (
  prevThreshold: OpThreshold,
  thresholdType: ThresholdType,
  uiThresholdType: string,
  scalar: number,
  lookBackDef: LookBackThreshold,
  periodicDef: PeriodicOffsetThreshold,
  rollingFreq: TimeObj,
  rollingFunction: string,
  dataQueryInfo?: BizDataQuery
): OpThreshold => {
  const { labels = {}, comparator } = prevThreshold;

  let nextThreshold: OpThreshold;

  switch (uiThresholdType) {
    case THRESHOLD_TYPES.custom: {
      const thresholdDef: StaticThresholdDef = {
        thresholdOp: defThresholdOp,
        peerThreshold: null,
        dataQueryInfo,
        rollingFreq,
        rollingFunction,
        offsetThreshold: null
      };
      nextThreshold = getNextOpThreshold(thresholdType, uiThresholdType, comparator, thresholdDef, labels);
      break;
    }
    case THRESHOLD_TYPES.automatic: {
      nextThreshold = {
        labels: {
          ...labels,
          [UI_THRESHOLD_KEY]: uiThresholdType
        },
        aboveThresholdDef: null,
        belowThresholdDef: null,
        comparator,
        thresholdType
      };

      break;
    }

    case THRESHOLD_TYPES.timeShift: {
      const thresholdDef: StaticThresholdDef = {
        offsetThreshold: {
          offset: {
            unit: TimeObjUnit.days,
            value: 1
          }
        },
        peerThreshold: null,
        rollingFreq: null,
        rollingFunction: null,
        thresholdOp: {
          ...defThresholdOp,
          value: scalar || defThresholdOp.value
        },
        currentQuery: true
      };

      nextThreshold = getNextOpThreshold(thresholdType, uiThresholdType, comparator, thresholdDef, labels);

      break;
    }

    case THRESHOLD_TYPES.static: {
      const thresholdDef: StaticThresholdDef = {
        scalar: scalar || 1000,
        offsetThreshold: null,
        peerThreshold: null,
        rollingFreq: null,
        rollingFunction: null,
        thresholdOp: null
      };

      nextThreshold = getNextOpThreshold(thresholdType, uiThresholdType, comparator, thresholdDef, labels);

      break;
    }

    case THRESHOLD_TYPES.peerGroup: {
      const thresholdDef: StaticThresholdDef = {
        offsetThreshold: null,
        peerThreshold: {
          peerAgg: rollingFunction || "P50",
          usFieldSlice: {
            slices: []
          },
          peerOfField: null
        },
        rollingFreq,
        rollingFunction: rollingFunction || "P50",
        thresholdOp: {
          opType: ThresholdOpType.PERCENTAGE,
          value: scalar || defThresholdOp.value
        },
        currentQuery: true
      };

      nextThreshold = getNextOpThreshold(thresholdType, uiThresholdType, comparator, thresholdDef, labels);

      break;
    }

    case THRESHOLD_TYPES.selfNonSeasonal:
    case THRESHOLD_TYPES.selfSeasonal: {
      const thresholdDef: StaticThresholdDef = {
        offsetThreshold: null,
        peerThreshold: null,
        rollingFreq: null,
        rollingFunction: null,
        thresholdOp: {
          ...defThresholdOp,
          value: scalar || defThresholdOp.value
        },
        lookBackDef,
        periodicDef,
        currentQuery: true
      };

      nextThreshold = getNextOpThreshold(thresholdType, uiThresholdType, comparator, thresholdDef, labels);

      break;
    }

    default: {
      nextThreshold = { ...prevThreshold };
    }
  }

  return nextThreshold;
};

const getNextOpThreshold = (
  thresholdType: ThresholdType,
  uiThresholdType: string,
  comparator: CompareOperator,
  thresholdDef: StaticThresholdDef,
  labels: Record<string, string>
) => {
  const { aboveThresholdDef, belowThresholdDef } = getThresholdDefByComparator(comparator, thresholdDef);

  return {
    labels: {
      ...labels,
      [UI_THRESHOLD_KEY]: uiThresholdType
    },
    aboveThresholdDef,
    belowThresholdDef,
    comparator,
    thresholdType
  };
};

export const getThresholdDefLabel = (
  thresholdDef: StaticThresholdDef,
  isPeerGroupThreshold: boolean,
  isTimeShiftThreshold: boolean,
  isStaticThreshold: boolean,
  isSelfThreshold: boolean
) => {
  const { peerThreshold, thresholdOp, scalar } = thresholdDef;

  const { value: thresholdValue } = thresholdOp || {};

  if (isStaticThreshold) {
    return String(scalar);
  }

  if (isPeerGroupThreshold) {
    if (!peerThreshold) {
      return "manual peer threshold";
    }

    const { peerOfField, usFieldSlice } = peerThreshold;

    if (!peerOfField) {
      return "manual peer threshold";
    } else {
      const fieldName = FieldPickerUtils.getUserServiceFieldLabel(peerOfField);
      const slicesNamesArr = usFieldSlice?.slices?.map(slice =>
        FieldPickerUtils.getUserServiceFieldLabel(slice.userServiceField)
      );
      const slicesName = slicesNamesArr?.length ? `with same ${slicesNamesArr.join(", ")}` : "";
      const queryStr = `${fieldName} ${slicesName}`;

      return `${thresholdValue}% of ${queryStr}`;
    }
  }

  if (isTimeShiftThreshold) {
    return "manual timeshift threshold";
  }

  if (isSelfThreshold) {
    return `${thresholdValue}%`;
  }

  return "manual threshold";
};

export const getSelfThreholdDefLabel = (
  thresholdDef: StaticThresholdDef,
  metricName: string,
  baseMetricRollingFreq: TimeObj
) => {
  const { lookBackDef, periodicDef } = thresholdDef;

  let label = "";

  const baseRollingFreqStr = baseMetricRollingFreq ? getLabelForTimeObj(baseMetricRollingFreq, "lg", true) : "";

  if (lookBackDef) {
    const { rollingFreq, trueLookBack, downSampleFunction } = lookBackDef;

    const freqLabel = getLabelForTimeObj(rollingFreq, "lg", true);
    const baseMetricName = [metricName, "in the last", baseRollingFreqStr, "over the past", freqLabel].join(" ");

    if (trueLookBack) {
      label = baseMetricName;
    } else {
      const dsFunctionLabel = getDisplayThresholdDownsample(downSampleFunction);
      label = `${upperFirst(dsFunctionLabel)} of ${baseMetricName}`;
    }
  } else if (periodicDef) {
    const { baselineDataConfig, downSampleFunction } = periodicDef;

    const { seasonalDataConfig } = baselineDataConfig;

    if (seasonalDataConfig) {
      const { numPeriods, seasonality } = seasonalDataConfig;

      const seasonalitySuffixStr = getDurationStrFromSeasonality(seasonality);
      const dsFunctionLabel = getDisplayThresholdDownsample(downSampleFunction);

      label = [
        upperFirst(dsFunctionLabel),
        "of",
        metricName,
        "in the last",
        baseRollingFreqStr,
        "over the past",
        numPeriods,
        seasonalitySuffixStr,
        "periods"
      ].join(" ");
    }
  }

  return label;
};

export const getDisplayThresholdDownsample = (downsampleFunction: ThresholdDownSampleFunc) => {
  if (downsampleFunction) {
    const { exponentialWeightedMovingAvg, simpleMovingAvg, linearRegression, quantile, simpleFunction } =
      downsampleFunction;

    if (exponentialWeightedMovingAvg) {
      return "exponential weighted moving average";
    } else if (simpleMovingAvg) {
      return "simple moving average";
    } else if (linearRegression) {
      return "linear regression";
    } else if (quantile) {
      return quantile;
    } else if (simpleFunction) {
      if (simpleFunction === OpThresholdSimpleFunction.AVG) {
        return "average";
      } else if (simpleFunction === OpThresholdSimpleFunction.MIN) {
        return "minimum";
      } else if (simpleFunction === OpThresholdSimpleFunction.MAX) {
        return "maximum";
      } else if (simpleFunction === OpThresholdSimpleFunction.DISTINCT_COUNT) {
        return "distinct count";
      }
    }
  }

  return "";
};

export const getDurationStrFromSeasonality = (seasonality: OpThresholdSeasonality) => {
  switch (seasonality) {
    case OpThresholdSeasonality.HOURLY: {
      return "hourly";
    }
    case OpThresholdSeasonality.DAILY: {
      return "daily";
    }
    case OpThresholdSeasonality.WEEKLY: {
      return "weekly";
    }

    default: {
      return "";
    }
  }
};

export const getSeasonalityFromRollingFreq = (rollingFreq: TimeObj): OpThresholdSeasonality => {
  const { unit } = rollingFreq || {};

  switch (unit) {
    case TimeObjUnit.hours:
      return OpThresholdSeasonality.HOURLY;
    case TimeObjUnit.days:
      return OpThresholdSeasonality.DAILY;
    case TimeObjUnit.weeks:
      return OpThresholdSeasonality.WEEKLY;
    default:
      return OpThresholdSeasonality.WEEKLY;
  }
};

export const getDefaultDownsampleFunction = (): ThresholdDownSampleFunc => ({
  linearRegression: true
});

export const getDefaultNonSeasonalRollingFreq = (): TimeObj => ({
  unit: TimeObjUnit.weeks,
  value: 1
});

export const getDefaultSeasonalDataConfig = (): BaselineSeasonalDataConfig => ({
  numPeriods: 4,
  seasonality: OpThresholdSeasonality.WEEKLY
});

export const getSlicesFromMetrics = (metrics: Record<string, MetricDefinition>): Slice[] => {
  try {
    const metric = Object.values(metrics)?.[0];
    const slices: Slice[] = [];
    if (metric?.sourceType === "userServiceField") {
      const sliceSets = metric?.userServiceFieldMetricConfig?.sliceSets?.map(sliceSet =>
        WidgetConfigUtils.convertUSFieldSliceSetToTagSlice(sliceSet)
      );
      slices.push(...(sliceSets?.[0]?.slices || []));
    } else if (metric?.sourceType === "bizEntityMetric") {
      const sliceSet = metric?.bizEntityMetricConfig?.sliceSet;
      slices.push(...(sliceSet?.slices || []));
    } else if (metric?.sourceType === "entity") {
      const nSlices = metric?.entityMetricConfig?.slices?.map(slice => convertTagSliceFromEntitySlice(slice));
      slices.push(...(nSlices || []));
    } else if (metric?.sourceType === "expression") {
      const nSlices = metric?.expressionMetricConfig?.sliceSpec?.sliceSet?.slices;
      slices.push(...(nSlices || []));
    } else if (metric?.sourceType === "userServiceMetric") {
      const nSlices = metric?.userServiceMetricConfig?.sliceSet?.slices;
      slices.push(...(nSlices || []));
    }
    return slices;
  } catch (error) {
    logger.warn("getSlicesFromMetrics", "Error converting slices from metrics", error);
    return [];
  }
};

export const getMetricName = (metrics: Record<string, MetricDefinition>, metricId: string): string => {
  try {
    const metric = metrics?.[metricId];
    const fieldName = metric?.name || "";
    return `${fieldName}`;
  } catch (error) {
    logger.warn("getSlicesFromMetrics", "Error converting slices from metrics", error);
    return "";
  }
};

export const convertTagSliceFromEntitySlice = (entitySlice: EntitySlice): Slice => {
  const { slice, tagName } = entitySlice;
  const { entityField } = slice;
  const { entityType, propType, propName } = entityField;
  return {
    entityTypeName: entityType,
    fieldName: propName,
    fieldType: propType === "NA" ? null : propType,
    tagName
  };
};
