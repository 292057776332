import React, { FC, useMemo, useCallback } from "react";
import { useTimeRange, Property } from "../../core";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { logger } from "../../core/logging/Logger";
import { EntitySearchResultEntry } from "../../services/api/types";
import { EntityDetails } from "../entity-properties-popover";
import { searchEntities } from "../../utils";
import { EntitiesPanel } from "./EntitiesPanel";
import { EntityListData } from "./EntityList";

interface Props {
  bizEntityType: string;
  cohortId: string;
  show: boolean;
  onClose: () => void;
  showEntityInfoOnHover?: boolean;
}

export const AsyncEntitiesPanel: FC<Props> = props => {
  const { bizEntityType, cohortId, onClose, show, showEntityInfoOnHover = false } = props;

  const { timeRange } = useTimeRange();
  const { from, to } = useMemo(() => timeRangeUtils.getTimeRangeMillisFromRaw(timeRange.raw), [timeRange.raw]);

  const fetchEntities = useCallback(
    async (searchText: string) => {
      const fetchOptions = searchEntities(searchText, "Name", bizEntityType, from, to, cohortId, 499, "contains", [
        "*"
      ]);

      const result: EntityListData = {
        entityIds: [],
        entityLookupData: {},
        entityDetailsData: {}
      };

      try {
        const options = await fetchOptions;
        const entities: Array<EntitySearchResultEntry["entity"]> = options.map(x => x.data);
        entities.forEach(entity => {
          const { props = {}, entityId, displayName } = entity;

          const properties: EntityDetails["properties"] = [];
          const propNames = Object.keys(props);
          propNames.forEach(propName => {
            const propValue = props[propName];
            properties.push({
              name: propName,
              value: propValue?.stringVal || "",
              ...(propValue || {})
            } as any as Property);
          });

          const entityDetails: EntityDetails = {
            ...entity,
            properties
          };

          result.entityIds.push(entityId);
          result.entityLookupData[entityId] = displayName;
          result.entityDetailsData[entityId] = entityDetails;
        });
      } catch (e) {
        logger.error("failed to fetch entities ", e);
      }

      return result;
    },
    [bizEntityType, cohortId, from, to]
  );

  return (
    <EntitiesPanel
      bizEntityType={bizEntityType}
      cohortId={cohortId}
      onClose={onClose}
      onSearch={fetchEntities}
      searchable
      show={show}
      showEntityInfoOnHover={showEntityInfoOnHover}
    />
  );
};
