import React, { useMemo, memo, useRef, useCallback } from "react";
import { AddCircle } from "../../core/iconwrapper";
import { CohortConfig, CohortPredicateType } from "../../services/api/explore";
import { ConfigPill } from "../pills/ConfigPill";
import { FieldPickerUtils, Options } from "../../utils/FieldPickerUtils";
import { useTenantConfig } from "../../core/hooks/tenant-config/useTenantConfig";
import { getOpFromPredicateOp } from "../../utils/PredicateUtils";
import LinkButton from "../LinkButton";

interface PillsProps {
  cohortConfig: CohortConfig;
  pillRefs: React.MutableRefObject<any>;
  readonly?: boolean;
  onCriteriaEdit: (predicateType: CohortPredicateType, idx: number) => void;
  onCriteriaDelete: (predicateType: CohortPredicateType, idx: number) => void;
}

interface CohortPillWrapperProps extends PillsProps {
  infoText?: string | JSX.Element;
  onCriteriaCreate: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

type CohortConfigPillsProps = Pick<PillsProps, "cohortConfig">;

export const CohortPillsWrapper: React.FC<CohortPillWrapperProps> = props => {
  const {
    readonly = false,
    cohortConfig,
    pillRefs,
    onCriteriaCreate,
    onCriteriaDelete,
    onCriteriaEdit,
    infoText
  } = props;

  return (
    <div data-cy="filter-criteria">
      <div className="inc-flex-row">
        <div className="criteria-pills-wrapper-label">Filter Criteria</div>
        <div className="criteria-pills-wrapper-info">{infoText}</div>
      </div>
      <div
        className={`criteria-pills-wrapper ${readonly ? "criteria-pills-wrapper--readonly" : ""}`}
        onClick={onCriteriaCreate}
      >
        <Pills
          cohortConfig={cohortConfig}
          onCriteriaDelete={onCriteriaDelete}
          onCriteriaEdit={onCriteriaEdit}
          pillRefs={pillRefs}
          readonly={readonly}
        />
        {!readonly && (
          <LinkButton
            icon={AddCircle}
            text={"Criteria"}
          />
        )}
      </div>
    </div>
  );
};

export const CohortConfigPills = memo<CohortConfigPillsProps>(({ cohortConfig }) => {
  const pillRefs = useRef<any>({});
  const noOp = useCallback(() => {}, []);
  return (
    <Pills
      cohortConfig={cohortConfig}
      onCriteriaDelete={noOp}
      onCriteriaEdit={noOp}
      pillRefs={pillRefs}
      readonly
    />
  );
});

const Pills = memo<PillsProps>(props => {
  const { readonly = false, cohortConfig, pillRefs, onCriteriaDelete, onCriteriaEdit } = props;

  const { tenantConfigState } = useTenantConfig();

  const pills = useMemo(() => {
    const pills: JSX.Element[] = [];
    const options: Options = { currency: tenantConfigState.currency };
    cohortConfig.cohortCreationConfig.bizFieldPredicates.forEach((predicate, i) => {
      const { label, info } = FieldPickerUtils.getBizFieldPredicatePillLabelAndInfo(predicate, options);
      pills.push(
        <ConfigPill
          info={info}
          key={`${i}-biz`}
          label={label}
          onLabelClick={() => onCriteriaEdit("bizField", i)}
          onRemove={() => onCriteriaDelete("bizField", i)}
          readonly={readonly}
          ref={el => (pillRefs.current[i] = el)}
        />
      );
    });
    cohortConfig.cohortCreationConfig.metricPredicates.forEach((predicate, i) => {
      const op = getOpFromPredicateOp(predicate.op);
      pills.push(
        <ConfigPill
          key={`${i}-metric`}
          label={`${predicate.metric.name} ${op} ${predicate.value}`}
          onLabelClick={() => onCriteriaEdit("metric", i)}
          onRemove={() => onCriteriaDelete("metric", i)}
          readonly={readonly}
          ref={el => (pillRefs.current[i] = el)}
        />
      );
    });
    return pills;
  }, [cohortConfig, onCriteriaDelete, onCriteriaEdit, pillRefs, readonly, tenantConfigState.currency]);

  return <>{pills}</>;
});
