import { useMemo, useCallback } from "react";
import { AxiosError } from "axios";
import { InitialState, FetchFn, FetchCallBackResult, useDataFetch } from "../../../core";
import {
  alertApiService,
  IncidentTimelineResponse,
  UserServiceFieldSlice,
  KeyContributorsResponse,
  ExploreEntityFilter,
  UserServiceFilterList
} from "../../../services/api/explore";

export const useFetchIncidentTimeline = (
  incidentId: string,
  numBins: number,
  eventFieldFilters: UserServiceFilterList,
  entityFilters: ExploreEntityFilter[],
  startTimeMillis: number,
  endTimeMillis: number,
  includeActionThreads = false,
  generateDemoData = false
) => {
  const initialState = useMemo<InitialState<IncidentTimelineResponse, string>>(
    () => ({
      data: null,
      error: null,
      isError: false,
      isFetching: false
    }),
    []
  );

  const fetchFn = useCallback<FetchFn<IncidentTimelineResponse, string>>(async () => {
    const result: FetchCallBackResult<IncidentTimelineResponse, string> = {
      data: null,
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const numBinsByTr = Math.floor((endTimeMillis - startTimeMillis) / MINS_MILLIS_15);
      const fNumBins = Math.min(numBinsByTr, numBins);

      const { data, error, message } = await alertApiService.getIncidentTimeline(
        incidentId,
        fNumBins,
        entityFilters,
        eventFieldFilters,
        startTimeMillis,
        endTimeMillis,
        includeActionThreads,
        generateDemoData
      );

      if (error) {
        result.isError = true;
        result.error = message;
      } else {
        result.isSuccess = true;
        result.data = data;
      }
    } catch (err) {
      result.isError = true;
      const axiosError = err as AxiosError;
      if (axiosError.isAxiosError) {
        result.error = axiosError.message;
      } else {
        result.error = err.message?.toString() || err.toString();
      }
    }

    return result;
  }, [
    endTimeMillis,
    startTimeMillis,
    numBins,
    incidentId,
    entityFilters,
    eventFieldFilters,
    includeActionThreads,
    generateDemoData
  ]);

  return useDataFetch(fetchFn, initialState, true);
};

export const useFetchKeyContributors = (
  incidentId: string,
  opConfigId: string,
  eventFieldFilters: UserServiceFilterList,
  entityFilters: ExploreEntityFilter[],
  startTimeMillis: number,
  endTimeMillis: number,
  diagnosticFields?: UserServiceFieldSlice[],
  alertingFields?: UserServiceFieldSlice[]
) => {
  const initialState = useMemo<InitialState<KeyContributorsResponse, string>>(
    () => ({
      data: null,
      error: null,
      isError: false,
      isFetching: false
    }),
    []
  );

  const fetchFn = useCallback<FetchFn<KeyContributorsResponse, string>>(async () => {
    const result: FetchCallBackResult<KeyContributorsResponse, string> = {
      data: null,
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const { data, error, message } = await alertApiService.getKeyContributors(
        incidentId,
        opConfigId,
        entityFilters,
        eventFieldFilters,
        startTimeMillis,
        endTimeMillis,
        diagnosticFields,
        alertingFields
      );
      if (error) {
        result.isError = true;
        result.error = message;
      } else {
        result.isSuccess = true;
        result.data = data;
      }
    } catch (err) {
      result.isError = true;
      const axiosError = err as AxiosError;
      if (axiosError.isAxiosError) {
        result.error = axiosError.message;
      } else {
        result.error = err.message?.toString() || err.toString();
      }
    }

    return result;
  }, [
    alertingFields,
    diagnosticFields,
    endTimeMillis,
    entityFilters,
    eventFieldFilters,
    incidentId,
    opConfigId,
    startTimeMillis
  ]);

  return useDataFetch(fetchFn, initialState, true);
};

const MINS_MILLIS_15 = 15 * 60 * 1000;
