import {
  IncSelectOption,
  IncSlimSelect,
  IncClickAwayPopper,
  IncDurationSelector,
  IncSelectorDuration,
  IncButton,
  IncSelect
} from "@inception/ui";
import React, { useMemo, useCallback, FC, useRef, useState, useEffect } from "react";
import { cloneDeep } from "lodash";
import { TimeRange, RawTimeRange, useToggleState } from "../../core";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { VerticallyCenteredRow } from "../flex-components";
import {
  getDurationFromTimeObj,
  getTimeObjFromDuration,
  getTimeObjFromInterval,
  getTimeObjUnitSuffixMap
} from "../../utils/DurationUtils";
import { TimeObjUnit } from "../../services/api/explore";
import { DASHBOARD_DEFAULT_COMPARE_INTERVAL } from "../../dashboard/widgets/Catalog/models";

type Props = {
  compareTimeRange: TimeRange;
  setCompareTimeRange: (timeRangeRaw: RawTimeRange) => void;
  compareLabel?: string;

  includeNoneOption?: boolean;
  noneOptionLabel?: string;
  includeDbDefaultOption?: boolean;
  alignment?: "row" | "column";
  renderer?: "default" | "slim";
  disablePopper?: boolean;
  size?: "default" | "small";
};

export const CompareTimeRangeSelector: FC<Props> = props => {
  const {
    setCompareTimeRange,
    compareTimeRange,
    compareLabel = "Compare to:",
    includeNoneOption = false,
    noneOptionLabel = "None",
    alignment = "row",
    renderer = "slim",
    disablePopper = false,
    includeDbDefaultOption = false,
    size = "default"
  } = props;
  const noneOpt = useMemo(() => getNoneOpt(noneOptionLabel), [noneOptionLabel]);
  const textClassName = size === "small" ? "inc-text-subtext-medium" : "inc-text-body-medium";

  const { raw } = compareTimeRange || {};

  const optionsRef = useRef([...presetOptions]);
  const labelRef = useRef<HTMLDivElement>();

  const { isOpen: isCustomPopperOpen, open: openCustomPopper, close: closeCustomPopper } = useToggleState();

  const compareStr =
    raw?.from === DASHBOARD_DEFAULT_COMPARE_INTERVAL
      ? includeDbDefaultOption
        ? null
        : "1w"
      : includeNoneOption
        ? raw?.from
        : raw?.from || "1w";

  const [duration, setDuration] = useState<IncSelectorDuration>(compareStr ? getDurationForInterval(compareStr) : null);

  useEffect(() => {
    if (compareStr) {
      setDuration(getDurationForInterval(compareStr));
    } else {
      setDuration(null);
    }
  }, [compareStr, includeNoneOption, isCustomPopperOpen]);

  const { selectedOption, isCustomOption } = useMemo(() => {
    if (!raw) {
      return {
        selectedOption: noneOpt,
        isCustomOption: false
      };
    }

    const options = optionsRef.current;

    let isCustomOption = false;
    let selOpt = options.find(opt => opt.timeRange?.from === raw?.from);
    if (!selOpt) {
      const isDbDefaultOption = dbDefaultOpt.value === raw.from && includeDbDefaultOption;
      if (isDbDefaultOption) {
        selOpt = cloneDeep(dbDefaultOpt);
      } else if (!disablePopper) {
        const { from } = raw;
        const compareTimeShiftMillis = timeRangeUtils.getMillisFromOffset(from);
        const magnitude = from.slice(0, from.length - 1);
        const includePrefix = Number(magnitude) <= 1;
        const prefix = includePrefix ? "Previous" : "";
        const suffix = !includePrefix ? "ago" : "";
        selOpt = {
          label: timeRangeUtils.getCompareStringFromTimeShiftMillis(compareTimeShiftMillis, prefix, suffix),
          value: from,
          timeRange: raw
        };
        isCustomOption = true;
      } else {
        selOpt = customOpt;
        isCustomOption = true;
      }
    }

    return {
      selectedOption: selOpt,
      isCustomOption
    };
  }, [disablePopper, includeDbDefaultOption, noneOpt, raw]);

  const onTimeRangeChange = useCallback(
    (opt: Option) => {
      const isCustomOpt = opt.value === customOpt.value;
      const isNoneOpt = opt.value === noneOpt.value;

      if (!isCustomOpt && !isNoneOpt) {
        setCompareTimeRange(opt.timeRange);
      } else if (isCustomOpt) {
        if (disablePopper) {
          setCompareTimeRange({
            from: "2d",
            to: ""
          });
        } else {
          openCustomPopper();
        }
      } else {
        setCompareTimeRange(null);
      }
    },
    [disablePopper, noneOpt.value, openCustomPopper, setCompareTimeRange]
  );

  const resetDurationAndClosePopper = useCallback(() => {
    setDuration(
      getDurationFromTimeObj({
        value: 1,
        unit: TimeObjUnit.weeks
      })
    );
    closeCustomPopper();
  }, [closeCustomPopper]);

  const updateCompareTime = useCallback(() => {
    if (duration) {
      const { duration: durationValue, durationType } = duration;
      const timeObj = getTimeObjFromDuration(durationValue as number, durationType);

      const { unit, value } = timeObj;
      const unitStr = getTimeObjUnitSuffixMap()[unit]?.sm || "w";
      const compareStr = `${value}${unitStr}`;

      setCompareTimeRange({
        from: compareStr,
        to: ""
      });
      closeCustomPopper();
    }
  }, [closeCustomPopper, duration, setCompareTimeRange]);

  const options = useMemo(() => {
    let options: Option[];

    if (isCustomOption && !disablePopper) {
      options = [...presetOptions, selectedOption, customOpt];
    } else {
      options = [...presetOptions, customOpt];
    }

    if (includeDbDefaultOption) {
      options.unshift(dbDefaultOpt);
    }
    if (includeNoneOption) {
      options.unshift(noneOpt);
    }

    return options;
  }, [disablePopper, includeDbDefaultOption, includeNoneOption, isCustomOption, noneOpt, selectedOption]);

  useMemo(() => {
    optionsRef.current = options;
  }, [options]);

  useEffect(() => {
    if (disablePopper) {
      updateCompareTime();
    }
  }, [disablePopper, updateCompareTime]);

  const className = alignment === "row" ? "inc-flex-row marginRt16 flex-gap-6" : "inc-flex-column flex-gap-6";
  return (
    <div className={className}>
      {Boolean(compareLabel) && (
        <div
          className="inc-text-body-medium inc-text-no-wrap inc-text-inactive"
          ref={labelRef}
        >
          {compareLabel}
        </div>
      )}

      {renderer === "slim" && (
        <IncSlimSelect<Option>
          autoSort={false}
          className={textClassName}
          onChange={onTimeRangeChange}
          options={options}
          value={selectedOption}
        />
      )}

      {renderer === "default" && (
        <IncSelect<Option>
          autoAdjustWidth
          autoAdjustWidthBuffer={16}
          autoSort={false}
          isSearchable={false}
          onChange={onTimeRangeChange}
          options={options}
          value={selectedOption}
        />
      )}

      {disablePopper && isCustomOption && (
        <IncDurationSelector
          duration={duration}
          hideLabel
          onChange={setDuration}
        />
      )}

      <IncClickAwayPopper
        anchorEl={labelRef.current}
        className="inc-card-layout"
        onClickAway={resetDurationAndClosePopper}
        show={isCustomPopperOpen}
      >
        <div className="inc-flex-column flex-gap-16">
          <VerticallyCenteredRow className="inc-text-subtext-medium">Custom compare time</VerticallyCenteredRow>

          <IncDurationSelector
            duration={duration}
            hideLabel
            onChange={setDuration}
          />

          <IncButton
            className="width-fit-content"
            color="secondary-blue"
            onClick={updateCompareTime}
            size="small"
          >
            Update
          </IncButton>
        </div>
      </IncClickAwayPopper>
    </div>
  );
};

type Option = IncSelectOption & {
  timeRange: TimeRange["raw"];
};

const presetOptions: Option[] = [
  {
    label: "Previous hour",
    value: "1h",
    timeRange: {
      from: "1h",
      to: ""
    }
  },
  {
    label: "Previous day",
    value: "1d",
    timeRange: {
      from: "1d",
      to: ""
    }
  },
  {
    label: "Previous week",
    value: "1w",
    timeRange: {
      from: "1w",
      to: ""
    }
  },
  {
    label: "Previous month",
    value: "30d",
    timeRange: {
      from: "30d",
      to: ""
    }
  }
];

const customOpt: Option = {
  label: "Custom",
  value: "custom",
  timeRange: {
    from: "",
    to: ""
  }
};

const getNoneOpt = (label: string): Option => ({
  label,
  value: "none",
  timeRange: null
});

const dbDefaultOpt: Option = {
  label: "Dashboard Default",
  value: DASHBOARD_DEFAULT_COMPARE_INTERVAL,
  timeRange: {
    from: DASHBOARD_DEFAULT_COMPARE_INTERVAL,
    to: ""
  }
};

const getDurationForInterval = (interval: string) => {
  const timeObj = getTimeObjFromInterval(interval);
  return getDurationFromTimeObj(timeObj);
};
