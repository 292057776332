import { useMemo, useEffect, useRef } from "react";
import { groupBy, isEqual } from "lodash";
import {
  WidgetResponseDTO,
  CohortEntityFilter,
  MetricUserServiceFilters,
  SliceSpec,
  SliceSet,
  MetricResultDataDTO,
  DemoDataParams
} from "../../../../services/api/explore";
import { TimeRange, logger } from "../../../../core";
import { ExploreQueryType } from "../../../../services/datasources/explore/types";
import { DataQueryError, DataQueryResponse } from "../../../../services/api/types";
import { EntityWidgetData, extractMetricInfoFromEntityWidgetData } from "../../../../biz-entity";
import { CatalogQuerySourceType, CatalogWidgetRenderMode } from "../models";
import timeRangeUtils from "../../../../utils/TimeRangeUtils";
import { AUTO_DS_INTERVAL } from "../../utils";
import { useFetchCatalogWidgetData } from "./useFetchData";

export type FetchResultMetaProps = {
  id: string;
  widgetResponseDTO: WidgetResponseDTO;
  entityType: string;
  cohortFilters: CohortEntityFilter[];
  entityFilters: CohortEntityFilter[];
  timeRange: TimeRange;
  userServiceId: string;
  limit: number;
  eventFilters?: MetricUserServiceFilters;
  mode: ExploreQueryType;
  filtersExist?: boolean;
  widgetName: string;
  aggregateTags: string[];
  sourceType: CatalogQuerySourceType;
  isQueryMappingDone: boolean;
  demoParams: DemoDataParams;
  renderMode: CatalogWidgetRenderMode;
};

const MAX_RETRIES = 0;

export const useGetResultMetaAndDQConfig = (props: FetchResultMetaProps, lazy?: boolean) => {
  const {
    cohortFilters,
    entityFilters,
    entityType,
    timeRange,
    eventFilters,
    widgetResponseDTO,
    mode,
    id,
    widgetName,
    sourceType,
    aggregateTags,
    isQueryMappingDone,
    demoParams,
    renderMode
  } = props;

  const isFieldSourceQuery = sourceType === "userServiceField";

  const timeInSeconds = useMemo(() => {
    const diffMillis = (timeRange?.to?.valueOf() || 0) - (timeRange?.from?.valueOf() || 0);
    return timeRangeUtils.getSecondsFromMillis(diffMillis);
  }, [timeRange]);

  const prevSliceSpecRef = useRef<SliceSpec[]>();
  const prevTimeRangeRef = useRef(timeRange);
  const retryCounterRef = useRef(0);

  const nameRef = useRef(widgetName);
  useMemo(() => {
    nameRef.current = widgetName;
  }, [widgetName]);

  const { querySchema } = widgetResponseDTO || {};

  const sliceSpec = useMemo(() => {
    const qsArr = querySchema?.querySchema || [];
    if (qsArr.length) {
      const sliceSpec: SliceSpec[] = [];
      const qsByMetricId = groupBy(qsArr, "metricId");

      const metricIds = Object.keys(qsByMetricId);
      metricIds.forEach(metricId => {
        let sliceSet: SliceSet;
        let timeAgg = "avg";
        let tagAgg = "avg";

        const entries = qsByMetricId[metricId];
        entries.forEach(qs => {
          const { sliceSet: qsSliceSet, defaultTagAgg, defaultTimeAgg } = qs;

          const currSliceCount = sliceSet?.slices?.length || 0;
          const qsSliceCount = qsSliceSet?.slices?.length || 0;

          if (qsSliceCount > currSliceCount) {
            sliceSet = qsSliceSet;
            tagAgg = defaultTagAgg;
            timeAgg = defaultTimeAgg;
          }
        });

        const tagNames = isFieldSourceQuery ? aggregateTags || [] : sliceSet?.slices?.map(sl => sl.tagName) || [];

        sliceSpec.push({
          selectorSpec: {
            filters: []
          },
          sliceSet,
          metricId,
          postAgg: {
            overTagAgg: {
              aggregator: tagAgg,
              tagName: tagNames
            },
            overTimeAgg: {
              aggregator: timeAgg,
              timeInSeconds
            },
            projections: ["current"],
            isSingleStatQuery: true,
            isSchemaQuery: true
          }
        });
      });

      return sliceSpec;
    }

    return null;
  }, [aggregateTags, isFieldSourceQuery, querySchema?.querySchema, timeInSeconds]);

  const { isFetching, isError, data, refetch, error } = useFetchCatalogWidgetData({
    ...props,
    id: `result-meta-dqconfig-${id}`,
    limit: 1,
    mode,
    sliceSpec,
    includeQueryConfig: true,
    dsIntervalStr: AUTO_DS_INTERVAL,
    demoParams
  });

  const nCompareSS = (sliceSpec || []).map(ss => {
    const cSS = {
      ...ss
    };
    delete cSS.postAgg;

    return cSS;
  });

  const pCompareSS = (prevSliceSpecRef.current || []).map(ss => {
    const cSS = {
      ...ss
    };
    delete cSS.postAgg;

    return cSS;
  });

  const sliceSpecChanged = !isEqual(nCompareSS, pCompareSS);
  const timeRangeChanged = !isEqual(prevTimeRangeRef.current, timeRange);
  const shouldFetch =
    (sliceSpecChanged && Boolean(sliceSpec)) ||
    timeRangeChanged ||
    (isFieldSourceQuery ? Boolean(aggregateTags) : true);

  useEffect(() => {
    if (shouldFetch && Boolean(sliceSpec) && isQueryMappingDone && !lazy) {
      refetch();
    }
  }, [
    refetch,
    sliceSpec,
    cohortFilters,
    entityFilters,
    entityType,
    timeRange,
    eventFilters,
    shouldFetch,
    isQueryMappingDone,
    renderMode,
    lazy
  ]);

  const { dataError, dataQueryConfig, entityLookupData, resultMeta } = useMemo<State>(() => {
    if (shouldFetch && !isFetching) {
      let setResultMetaSuccess = false;

      if (data?.length) {
        const { entityWidgetData, dataError } = getParsedData(data);

        const dataExists =
          !isError &&
          entityWidgetData?.length > 0 &&
          (entityWidgetData[0]?.dataSize > 0 || entityWidgetData[0]?.postAggDataSize > 0);

        const resultMeta = dataExists ? extractMetricInfoFromEntityWidgetData(entityWidgetData, true) : [];
        const dataQueryConfig = entityWidgetData?.length ? entityWidgetData[0].dataQueryConfig : {};

        if (resultMeta.length) {
          prevSliceSpecRef.current = sliceSpec;
          setResultMetaSuccess = true;

          if (retryCounterRef.current) {
            logger.info(
              "Result meta for filters",
              `Result meta found for ${nameRef.current} in retry ${retryCounterRef.current}`,
              resultMeta
            );
            retryCounterRef.current = 0;
          }
        } else {
          logger.warn("Result meta for filters", `No result meta found for ${nameRef.current}`, data);
        }

        if (isFieldSourceQuery && resultMeta) {
          resultMeta.forEach(rm => (rm.resultSeriesName = widgetName));
        }
        //to populate the entity lookup
        let entityLookupData = {};
        entityWidgetData.forEach(datum => {
          entityLookupData = {
            ...entityLookupData,
            ...datum?.postAggResult.entityLookupData
          };
        });

        return {
          resultMeta,
          entityLookupData,
          dataQueryConfig,
          dataError
        };
      }

      if (retryCounterRef.current < MAX_RETRIES && !setResultMetaSuccess) {
        retryCounterRef.current += 1;
        logger.warn(
          "Result meta for filters",
          `No result meta found for ${nameRef.current}. Retry ${retryCounterRef.current}`,
          data
        );
      }
      return {
        dataError: "No result found",
        dataQueryConfig: {},
        entityLookupData: {},
        resultMeta: []
      };
    }
    return {
      dataError: null,
      dataQueryConfig: {},
      entityLookupData: {},
      resultMeta: []
    };
  }, [data, isError, isFetching, isFieldSourceQuery, shouldFetch, sliceSpec, widgetName]);

  return {
    resultMeta,
    dataQueryConfig,
    isResultMetaFetching: isFetching,
    isResultMetaError: isError,
    resultMetaError: dataError || error,
    refetch,
    entityLookupData: entityLookupData || {}
  };
};

type State = {
  resultMeta: MetricResultDataDTO[];
  dataQueryConfig: Record<string, any>;
  dataError: string;
  entityLookupData: Record<string, string>;
};

const getParsedData = (data: Array<DataQueryResponse<EntityWidgetData[]>>) => {
  const fData = data || [];

  let dataErrObj: DataQueryError = {
    data: null,
    message: ""
  };

  const entityWidgetData: EntityWidgetData[] = [];

  fData.forEach(d => {
    const { data = [], error } = d;
    dataErrObj = dataErrObj || error;
    entityWidgetData.push(...data);
  });

  const dataError = dataErrObj?.message || "";

  return {
    entityWidgetData,
    dataError
  };
};
