import { useMemo, useCallback } from "react";
import { InitialState, FetchFn, useDataFetch, FetchCallBackResult } from "../../../core";
import { journeyApiService, FunnelData, TimeObjUnit } from "../../../services/api/explore";

export const useFetchJourneyFunnelData = (
  funnelId: string,
  compareTimeInSeconds: number,
  startTimeMillis: number,
  endTimeMillis: number,
  funnelData?: FunnelData
) => {
  const initialData = useMemo<InitialState<FunnelData, string>>(
    () => ({
      data: null,
      error: null,
      isError: false,
      isFetching: true,
      isSuccess: false
    }),
    []
  );

  const fetchFn = useCallback<FetchFn<FunnelData, string>>(async () => {
    const result: FetchCallBackResult<FunnelData, string> = {
      data: null,
      error: null,
      isError: false,
      isSuccess: false
    };

    if (funnelData) {
      result.data = funnelData;
      result.isSuccess = true;
    } else {
      const { data, error, message } = await journeyApiService.getJourneyFunnelData(
        funnelId,
        {
          unit: TimeObjUnit.minutes,
          value: compareTimeInSeconds / 60
        },
        startTimeMillis,
        endTimeMillis
      );
      result.data = data;
      result.isError = error;
      result.isSuccess = !error;
      result.error = error ? message : null;
    }

    return result;
  }, [compareTimeInSeconds, endTimeMillis, funnelId, startTimeMillis, funnelData]);

  return useDataFetch(fetchFn, initialData);
};
