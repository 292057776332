import { useCallback, useRef, useState } from "react";
import { useRefState } from "./useRefState";
import { useForceUpdate } from "./useForceUpdateHook";

export type PollResult<T> = {
  data: T;
  error: boolean;
  message: string;
};

export type PollCallback<T> = () => Promise<PollResult<T>>;

export type OnPollResultCallBack<T> = (data: T) => {
  isComplete: boolean;
  nextCallback?: PollCallback<T>;
};

type Props<T> = {
  callback: PollCallback<T>;
  onResult: OnPollResultCallBack<T>;
  pollInterval: number;
};

export const usePollForStatus = <T,>(props: Props<T>) => {
  const { callback, onResult, pollInterval } = props;

  const timeoutRef = useRef<number>();
  const pollingStoppedRef = useRef(false);
  const isPollingRef = useRef(false);

  const forceUpdate = useForceUpdate();

  const [response, setResponse] = useState<PollResult<T>>({
    data: null,
    error: false,
    message: ""
  });

  const callbackRef = useRefState(callback);

  const poll = useCallback(async () => {
    const response = await callbackRef.current();
    const { isComplete, nextCallback = callbackRef.current } = onResult(response.data);
    callbackRef.current = nextCallback;

    if (!isComplete) {
      timeoutRef.current = window.setTimeout(() => {
        if (!pollingStoppedRef.current) {
          poll();
        }
      }, pollInterval);
    } else {
      isPollingRef.current = false;
      pollingStoppedRef.current = true;
    }
    setResponse(response);
  }, [callbackRef, onResult, pollInterval]);

  const startPolling = useCallback(() => {
    isPollingRef.current = true;
    pollingStoppedRef.current = false;
    forceUpdate();
    poll();
  }, [forceUpdate, poll]);

  const stopPolling = useCallback(() => {
    pollingStoppedRef.current = true;
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
    setResponse(prev => ({ ...prev }));
  }, []);

  return {
    ...response,
    startPolling,
    stopPolling,
    isPolling: isPollingRef.current
  };
};
