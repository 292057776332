import React, { FC, useEffect, useState, useMemo, useRef, useCallback } from "react";
import { IncTextfield, IncButton } from "@inception/ui";
import { WidgetConfigDTO } from "../../../services/api/explore";
import { QUERY_LOOKUP_KEY } from "../../../utils/ExploreUtils";
import { VerticallyCenteredRow } from "../../../components";

interface Props {
  widgetConfig: WidgetConfigDTO;
  onSave: (metricNamesById: Record<string, string>) => void;
  onCancel: () => void;
}

export const MetricRenamer: FC<Props> = props => {
  const { onSave, onCancel, widgetConfig } = props;

  const [metricNamesById, setMetricNamesById] = useState<Record<string, string>>({});
  const queryIdVsMetricIdRef = useRef<Record<string, string>>({});

  useEffect(() => {
    const { metrics } = widgetConfig.dataDefinition;
    const metricNamesVsId: Record<string, string> = {};
    const queryIdVsMetricId: Record<string, string> = {};

    Object.keys(metrics).forEach(metricId => {
      const { name: metricName, labels, sourceType } = metrics[metricId];

      if (sourceType !== "expression") {
        metricNamesVsId[metricId] = metricName;

        const queryId = labels[QUERY_LOOKUP_KEY];
        queryIdVsMetricId[metricId] = queryId;
      }
    });

    queryIdVsMetricIdRef.current = queryIdVsMetricId;
    setMetricNamesById(metricNamesVsId);
  }, [widgetConfig]);

  const textFields = useMemo(
    () =>
      Object.keys(metricNamesById).map(metricId => {
        const metricName = metricNamesById[metricId];
        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const nMetricName = e.target.value || "";
          setMetricNamesById(prev => ({
            ...prev,
            [metricId]: nMetricName
          }));
        };

        const label = queryIdVsMetricIdRef.current[metricId];
        const key = `${metricId}-name-editor`;

        return (
          <IncTextfield
            containerClassName="marginBt10 inc-flex-grow"
            errorText="Metric anme cannot be empty"
            hasError={!metricName}
            key={key}
            label={label}
            onChange={onChange}
            required
            value={metricName}
          />
        );
      }),
    [metricNamesById]
  );

  const disableSave = useMemo(
    () =>
      Object.keys(metricNamesById).reduce((prev, metricId) => {
        const metricName = metricNamesById[metricId];
        return prev || !metricName;
      }, false),
    [metricNamesById]
  );

  const onSaveClick = useCallback(() => {
    if (!disableSave) {
      onSave(metricNamesById);
    }
  }, [disableSave, metricNamesById, onSave]);

  return (
    <>
      {textFields}

      <VerticallyCenteredRow className="marginTp10 marginBt16">
        <IncButton
          className="marginRt12"
          color="primary"
          disabled={disableSave}
          onClick={onSaveClick}
        >
          Save
        </IncButton>

        <IncButton
          color="secondary"
          onClick={onCancel}
        >
          Cancel
        </IncButton>
      </VerticallyCenteredRow>
    </>
  );
};
