import { getInceptionTheme } from "@inception/ui";
import { capitalize } from "lodash";
import React, { FC } from "react";
import ColorPicker from "../../../components/color-picker/ColorPicker";
import { WidgetColorProperty } from "../types";

interface Props {
  property: WidgetColorProperty;
  onUpdate: (newState: WidgetColorProperty) => void;
}

const whiteColor = getInceptionTheme().inceptionColors.primary1;

const ColorPropertyRenderer: FC<Props> = props => {
  const { onUpdate: usrUpdate, property } = props;

  const { name, value, label = capitalize(name), placeholder = whiteColor, mode, defaultValue = whiteColor } = property;

  const onUpdate = (newColor: string) => {
    const newProperty = {
      ...property,
      value: newColor
    };
    usrUpdate(newProperty);
  };

  return (
    <ColorPicker
      color={value as string}
      defaultColor={defaultValue}
      label={label}
      mode={mode}
      onUpdate={onUpdate}
      placeholder={placeholder}
    />
  );
};

export default ColorPropertyRenderer;
