import { isEmpty } from "lodash";
import { Entity, logger } from "../../core";
import { TimeRange } from "../../core/hooks/time-range/types";
import { entityApiService } from "../../services/api";
import { EntityOption, EntitySelectorSupportedTypes } from "./types";

const entityOptionsFromEntity = (entities: Entity[]) => {
  if (!isEmpty(entities)) {
    const entityOptions: EntityOption[] = entities.map((entity: Entity) => ({
      label: entity.name,
      value: entity.id,
      entity: entity
    }));
    return entityOptions;
  } else {
    return [];
  }
};

export const fetchRootApis = async (timeRange?: TimeRange): Promise<EntityOption[]> => {
  try {
    let rootApis: Entity[];
    if (timeRange) {
      const { from, to } = timeRange;
      rootApis = await entityApiService.fetchRootApis(from, to);
    } else {
      // List all root API's as options if no time range is specified
      rootApis = await entityApiService.fetchAllRootApi();
    }
    return entityOptionsFromEntity(rootApis);
  } catch (error) {
    logger.error("RootApiSelector", "Error fetching root api's", error);
  }
  return [];
};

export const getEntityOptions = async (
  entityType: EntitySelectorSupportedTypes,
  timeRange: TimeRange
): Promise<EntityOption[]> => {
  if (isEmpty(entityType)) {
    logger.error("EntitySelector", "No entities fetched for generic type 'entity'");
    return [];
  }

  if (entityType === "root-api") {
    try {
      const apis = await fetchRootApis(timeRange);
      logger.info("EntitySelector", `Fetched ${apis.length} root api's`);
      return apis;
    } catch (error) {
      logger.error("EntitySelector", "Error fetching root api's", error);
    }
  } else if (entityType === "user-services") {
    try {
      const userServices = await entityApiService.fetchUserServices(timeRange.from, timeRange.to);
      logger.info("EntitySelector", `Fetched ${userServices.length} root api's`);
      return entityOptionsFromEntity(userServices);
    } catch (error) {
      logger.error("EntitySelector", "Error fetching root api's", error);
    }
  } else {
    try {
      const result = await entityApiService.fetchEntitiesByTypeId(entityType, timeRange.from, timeRange.to);
      const entityOptions = entityOptionsFromEntity(result.data.entities);
      logger.info("EntitySelector", `Fetched ${entityOptions.length} root api's`);
      return entityOptions;
    } catch (error) {
      logger.error("EntitySelector", "Error fetching root api's", error);
    }
  }

  return [];
};

/**
 * Get localized id of string to be used for label
 */
export function getEntitySelectorLabelId(entityType: EntitySelectorSupportedTypes, isMulti: boolean) {
  if (entityType === "root-api" || entityType === "user-services") {
    return "user.service.selector.label";
  } else {
    return isMulti ? "entity.multi.selector.label" : "entity.single.selector.label";
  }
}
