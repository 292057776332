import {
  getFormattedDateTime,
  IncButton,
  IncDateTimeFormat,
  IncFaIcon,
  IncInModalConfirmation,
  IncToolTip
} from "@inception/ui";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { cloneDeep } from "lodash";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../components";
import {
  Op10zeSetupResponse,
  OpCreationConfig,
  operationaliseV2ApiService,
  OpSimulationStatus
} from "../../../services/api/operationalise";
import {
  setIsDraft,
  setOp10zeId,
  setOpMode,
  setPrimaryOpCreationConfig,
  setReadOnly,
  setSaveOpCreationConfig,
  setSimulationConfig,
  useOpStore
} from "../../context";
import { logger, useAccessPrivilege, useNotifications, useToggleState } from "../../../core";
import { PRIMARY_CONFIG_AS_SIMULATION_KEY } from "../../constants";
import { getLabelForOpCreationConfig } from "../../utils";
import { noOp } from "../../../utils";
import { featureFlagService } from "../../../services/feature-flags";
import { OpRunHistoryModal, OpSimulationStatusRenderer } from "../simulation";
import { OpRunHistoryWrapper } from "../simulation/OpRunHistoryWrapper";
import { OpResultsRendererWrapper } from "../results/OpResultsRendererWrapper";
import { OpV3Editor } from "./OpV3Editor";

interface Props {
  setSaveOrUpdateInProgress: (inProgress: boolean) => void;
  onSaveOrUpdate: (opConfigId: string, opCreationConfig: OpCreationConfig) => void;
}

export const OpV3Results: FC<Props> = props => {
  const { setSaveOrUpdateInProgress: pSetSaveOrUpdateInProgress, onSaveOrUpdate: pOnSaveOrUpdate } = props;

  const enableShowSimulationStatus = featureFlagService.isDebugMode();

  const { close: closeSimulationStatus, isOpen: isSimulationStatusOpen, open: openSimulationStatus } = useToggleState();

  const { notifyError } = useNotifications();
  const { canEdit } = useAccessPrivilege();

  const { state, dispatch } = useOpStore();

  const [saveOrUpdateInProgress, setSaveOrUpdateInProgress] = useState(false);
  const [saveOrUpdateError, setSaveOrUpdateError] = useState("");

  useEffect(() => {
    pSetSaveOrUpdateInProgress(saveOrUpdateInProgress);
  }, [pSetSaveOrUpdateInProgress, saveOrUpdateInProgress]);

  const { close: closeConfirmation, isOpen: isConfirmationOpen, open: openConfirmation } = useToggleState();

  const { isOpen: isExpanded, toggle: toggleExpand } = useToggleState();

  useEffect(() => {
    pSetSaveOrUpdateInProgress(saveOrUpdateInProgress);
  }, [pSetSaveOrUpdateInProgress, saveOrUpdateInProgress]);

  useEffect(() => {
    if (saveOrUpdateError) {
      notifyError(saveOrUpdateError);
    }
  }, [notifyError, saveOrUpdateError]);

  const { opCreationConfig, context, op10zeId, selectedSimulation, simulationStateMap } = state;

  const { opCreationConfigDef, outlierConfig, disableOpEdit, opAnalysisConfig } = opCreationConfig || {};
  const showOpHistoryAsPrimary = Boolean(opAnalysisConfig);
  const { startTimeEpochSecs, endTimeEpochSecs } =
    opCreationConfigDef?.schedule?.schedule || outlierConfig?.schedule?.schedule || {};

  const trLabel = useMemo(() => {
    let startTimeMillis = parseInt(String(startTimeEpochSecs), 10);
    startTimeMillis = Number.isNaN(startTimeMillis) || !startTimeMillis ? 0 : startTimeMillis * 1000;

    let endTimeMillis = parseInt(String(endTimeEpochSecs), 10);
    endTimeMillis = Number.isNaN(endTimeMillis) || !endTimeMillis ? 0 : endTimeMillis * 1000;

    return startTimeMillis && endTimeMillis
      ? `${getFormattedDateTime(startTimeMillis, IncDateTimeFormat.minimal)} - ${getFormattedDateTime(endTimeMillis, IncDateTimeFormat.minimal)}`
      : "";
  }, [endTimeEpochSecs, startTimeEpochSecs]);

  const { widgetId } = context;

  const { name, description, isPrimary, simulationId } = selectedSimulation;

  const simulationState = simulationStateMap[simulationId];
  const { status } = simulationState || {};
  const isInProgress = status === OpSimulationStatus.IN_PROGERSS;

  const [label, setLabel] = useState("");
  useEffect(() => {
    const asyncFn = async () => {
      const label = await getLabelForOpCreationConfig(opCreationConfig);
      setLabel(label);
    };
    asyncFn();
  }, [opCreationConfig]);

  const onSaveOrUpdatePrimaryConfig = useCallback(
    (data: Op10zeSetupResponse) => {
      const { op10zeId, opCreationConfig } = data;
      pOnSaveOrUpdate(op10zeId, opCreationConfig);

      dispatch(setPrimaryOpCreationConfig(opCreationConfig));
      dispatch(setSaveOpCreationConfig(cloneDeep(opCreationConfig)));
      dispatch(
        setSimulationConfig({
          createdBy: opCreationConfig.createdBy,
          description: opCreationConfig.description,
          isPrimary: true,
          labels: {
            isPrimary: "true"
          },
          name: opCreationConfig.name,
          opCreationConfig,
          simulationId: PRIMARY_CONFIG_AS_SIMULATION_KEY
        })
      );
      dispatch(setReadOnly(true));
      dispatch(setOp10zeId(op10zeId));
      dispatch(setIsDraft(false));
      dispatch(setOpMode("edit"));
    },
    [dispatch, pOnSaveOrUpdate]
  );

  const onMonitor = useCallback(async () => {
    setSaveOrUpdateInProgress(true);
    setSaveOrUpdateError("");

    if (!op10zeId) {
      const { data, error, message } = await operationaliseV2ApiService.createOpConfig(opCreationConfig, widgetId);
      if (error) {
        setSaveOrUpdateError("Error saving configuration");
        logger.error("OpV3Editor", "Error creating operationalization", message);
      } else {
        onSaveOrUpdatePrimaryConfig(data);
      }
      setSaveOrUpdateInProgress(false);
    } else {
      openConfirmation();
    }
  }, [onSaveOrUpdatePrimaryConfig, op10zeId, opCreationConfig, openConfirmation, widgetId]);

  const onCancelOverride = useCallback(() => {
    closeConfirmation();
  }, [closeConfirmation]);

  const onConfirmOverride = useCallback(async () => {
    const { data, error, message } = await operationaliseV2ApiService.overrideOpWithSimulation(op10zeId, simulationId);
    if (error) {
      setSaveOrUpdateError("Error editing configuration");
      logger.error("OpV3Editor", "Error editing operationalization", message);
    } else {
      onSaveOrUpdatePrimaryConfig(data);
    }
    setSaveOrUpdateInProgress(false);
    closeConfirmation();
  }, [closeConfirmation, onSaveOrUpdatePrimaryConfig, op10zeId, simulationId]);

  const { isOpen: isHistoryOpen, open: openRunHistory, close: closeRunHistory } = useToggleState();

  return (
    <div className="op-v3-results">
      <VerticallyCenteredRow className="marginBt24 flex-gap-16">
        <div className="inc-text-body-medium">{name}</div>
        {Boolean(description) && (
          <IncToolTip
            placement="top"
            showArrow
            titleText={description}
          >
            <VerticallyCenteredRow>
              <IncFaIcon
                className="inc-text-inactive"
                iconName="info-circle"
              />
            </VerticallyCenteredRow>
          </IncToolTip>
        )}

        {Boolean(trLabel) && (
          <VerticallyCenteredRow>
            <div className="inc-text-subtext-medium inc-text-inactive marginRt8">Time Range</div>
            <div className="inc-text-subtext-medium">{trLabel}</div>
          </VerticallyCenteredRow>
        )}

        {isHistoryOpen && (
          <OpRunHistoryModal
            isOpen
            onClose={closeRunHistory}
            opId={op10zeId}
          />
        )}

        {!isPrimary && canEdit && (
          <IncButton
            className="marginLtAuto"
            color="primary"
            disabled={disableOpEdit}
            onClick={onMonitor}
          >
            Monitor with live data
          </IncButton>
        )}

        {isPrimary && (
          <>
            {!showOpHistoryAsPrimary && (
              <IncButton
                className="marginLtAuto"
                color="link"
                iconName="history"
                onClick={openRunHistory}
              >
                Run History
              </IncButton>
            )}
            <IncButton
              className={`readonly ${showOpHistoryAsPrimary ? "marginLtAuto" : ""}`}
              color="secondary-green"
              iconType="iconText"
            >
              <IncFaIcon iconName="check" />
              Primary Condition
            </IncButton>
          </>
        )}

        {enableShowSimulationStatus && Boolean(simulationState) && (
          <IncButton
            color="link"
            onClick={openSimulationStatus}
          >
            View Simulation Status
          </IncButton>
        )}
      </VerticallyCenteredRow>

      {isInProgress && showSimulationActions && (
        <VerticallyCenteredRow className="marginBt18">
          <IncButton
            className="marginRt16"
            color="link"
            iconType="iconText"
          >
            <IncFaIcon iconName="pause" />
            Pause Simulation
          </IncButton>

          <IncButton
            className="status-danger"
            color="link"
            iconType="iconText"
          >
            <IncFaIcon iconName="ban" />
            Stop Simulation
          </IncButton>
        </VerticallyCenteredRow>
      )}

      <div className="marginBt16 conditions-info">
        <VerticallyCenteredRow className="inc-text-subtext-medium inc-text-inactive marginBt6">
          <IncFaIcon
            className="conditions-info--arrow"
            data-expanded={isExpanded}
            iconName="circle-chevron-down"
            onClick={toggleExpand}
          />

          <div className="marginLt8">Conditions</div>
        </VerticallyCenteredRow>
        {!isExpanded && (
          <div className="inc-text-subtext-medium">
            {Boolean(label) && label}
            {!label && <LoadingSpinner titleText=" " />}
          </div>
        )}
        {isExpanded && (
          <OpV3Editor
            onSaveOrUpdate={noOp}
            onSimulate={noOp}
            setSaveOrUpdateInProgress={noOp}
          />
        )}
      </div>
      {showOpHistoryAsPrimary && <OpRunHistoryWrapper opId={op10zeId} />}
      {!showOpHistoryAsPrimary && <OpResultsRendererWrapper />}

      {isSimulationStatusOpen && (
        <OpSimulationStatusRenderer
          onClose={closeSimulationStatus}
          show={isSimulationStatusOpen}
          simulationState={simulationState}
        />
      )}

      {isConfirmationOpen && (
        <IncInModalConfirmation
          message="This will override the current operationalization configuration. Are you sure?"
          onCancel={onCancelOverride}
          onConfirm={onConfirmOverride}
        />
      )}
    </div>
  );
};

const showSimulationActions = false;
