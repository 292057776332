import React, { useCallback, useMemo, useRef } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { cx } from "emotion";
import { IncButton, IncFaIcon } from "@inception/ui";
import EmptyState from "../../components/EmptyState";
import DashboardImpl from "../model-impl/DashboardImpl";
import DashToolbar from "../toolbar/DashToolbar";
import { DashboardWidgetActionHandler } from "../BaseWidgetActions";
import { AdhocSummaryContext } from "../../core/data/types/ExploreTriageTypes";
import { DashboardMode } from "../types";
import {} from "../widgets";
import { LoadingSpinner } from "../../components";
import { GLOBAL_VARIABLE_SRV_KEY } from "../variables/constants";
import BaseWidgetImpl from "../model-impl/BaseWidgetImpl";
import { TimeRange, useRefState, useTenantConfig, useToggleState } from "../../core";
import { noOp } from "../../utils";
import appConfig from "../../../appConfig";
import { WidgetCustomAction } from "../widgets/types";
import { DashboardPresetTimeInfo } from "../models";
import { useDashboardLayout, useDashboardState, useDashboardVariables, useDashboardWidgets } from "./hooks";
import { AddCatalogWidget, DashboardFilterPanel, DashboardMaximizeView, SettingsModal } from "./components";

const ResponsiveGridLayout = WidthProvider(Responsive);

export type DashboardGridStyles = {
  spacing: number;
  rowHeight: number;
};

interface DashboardGridProps {
  dashboard: DashboardImpl;
  embedded?: boolean;
  onDashboardUpdate?: (dbImpl: DashboardImpl) => void;
  onDashboardSave?: (dbImpl: DashboardImpl) => void;
  onWidgetAction?: DashboardWidgetActionHandler;
  toolbarInfoComponent?: JSX.Element;
  onTriage?: (data: AdhocSummaryContext) => void;

  onWidgetClone?: (widget: BaseWidgetImpl, defaultCallback: () => void) => void;
  onWidgetEdit?: (widget: BaseWidgetImpl, onSave: (nImpl: BaseWidgetImpl) => void) => void;
  onWidgetDelete?: (widget: BaseWidgetImpl, defaultCallback: () => void) => void;
  onWidgetMaximize?: (widget: BaseWidgetImpl, defaultCallback: () => void) => void;
  onWidgetMinimize?: (widget: BaseWidgetImpl, defaultCallback: () => void) => void;
  getCustomWidgetActions?: (widget: BaseWidgetImpl) => WidgetCustomAction[];

  emptyStateLabel?: string;
  mode?: DashboardMode;
  redirectOnAction?: boolean;
  hideEmptyStateImage?: boolean;
  styleOptions?: DashboardGridStyles;
  presetTimeRange?: TimeRange;
  ignoreUrlChanges?: boolean;
  addWidgetIfEmpty?: boolean;
}

const DashboardGrid: React.FC<DashboardGridProps> = (props: DashboardGridProps) => {
  const {
    dashboard: usrDbImpl,
    onDashboardUpdate: usrOnDashboardUpdate = noOp,
    onDashboardSave: usrOnDashboardSave = noOp,
    embedded,
    toolbarInfoComponent,
    emptyStateLabel = null,
    onWidgetAction,
    onTriage,
    mode,
    redirectOnAction,
    hideEmptyStateImage = false,
    styleOptions = {
      rowHeight: 30,
      spacing: 12
    },
    onWidgetClone: pOnWidgetClone,
    onWidgetEdit: pOnWidgetEdit,
    onWidgetDelete: pOnWidgetDelete,
    onWidgetMaximize: pOnWidgetMaximize,
    onWidgetMinimize: pOnWidgetMinimize,
    getCustomWidgetActions,
    presetTimeRange,
    ignoreUrlChanges,
    addWidgetIfEmpty
  } = props;

  const { close: closeCatalogWidgetModal, isOpen: catalogWidgetModalOpen, open: onAddCatalogWidget } = useToggleState();
  const { close: onCloseSettings, isOpen: isSettingsOpen, open: onOpenSettings } = useToggleState();

  const { tenantConfigState } = useTenantConfig();

  const {
    dbImpl,
    maximizeWidgetProps,
    onDashboardWidgetAction,
    onLayoutChanged,
    onWidgetClone,
    onWidgetDelete,
    onWidgetMaximize,
    onWidgetLockToggle,
    onWidgetTypeUpdate,
    onWidgetUpdate,
    refreshAndUpdateDashboard,
    refreshDashboard,
    updateDashboardVariables,
    updateDashboardTime,
    updateDashboardTimeInfo,
    onDashboardUpdate
  } = useDashboardState({
    dbImpl: usrDbImpl,
    onDashboardUpdate: usrOnDashboardUpdate,
    onWidgetAction,
    onWidgetClone: pOnWidgetClone,
    onWidgetDelete: pOnWidgetDelete,
    onWidgetMaximize: pOnWidgetMaximize,
    onWidgetMinimize: pOnWidgetMinimize
  });

  const {
    widgetVariableSrvMap,
    onVariablesUpdated,
    onVariablesValueUpdated,
    onUpdateCohortId,
    widgetVariableSrvLoadingState,
    eventTypeToFieldsMap,
    isEventTypeFieldsMapInitialized,
    onAddAdhocEntityFilter,
    onAddAdhocEventFilter,
    refreshVariableSrvMap,
    updateFilterQueryParams
  } = useDashboardVariables({
    dbImpl,
    updateDashboardVariables,
    updateDashboardTime,
    refreshDashboard,
    onDashboardUpdate,
    timeRange: presetTimeRange,
    ignoreUrlChanges
  });

  const { presetTimeInfo } = dbImpl;
  const onSaveSettings = useCallback(
    (presetTimeInfo: DashboardPresetTimeInfo) => {
      updateDashboardTimeInfo(presetTimeInfo);
    },
    [updateDashboardTimeInfo]
  );

  const cloneWidgetIdRef = useRef<string>();
  const dbImplRef = useRefState(dbImpl);
  const onCloneInternal = useCallback(
    (id: string) => {
      const dbImpl = dbImplRef.current;
      if (dbImpl) {
        const widget = dbImpl.getWidgetById(id);

        if (widget?.type === "catalog") {
          cloneWidgetIdRef.current = id;
          onAddCatalogWidget();
        } else {
          onWidgetClone(id);
        }
      }
    },
    [dbImplRef, onAddCatalogWidget, onWidgetClone]
  );

  const onCloseCatalogWidgetModal = useCallback(() => {
    cloneWidgetIdRef.current = null;
    closeCatalogWidgetModal();
  }, [closeCatalogWidgetModal]);

  const { onAddWidget, widgets } = useDashboardWidgets({
    dbImpl,
    onDashboardWidgetAction,
    onWidgetClone: onCloneInternal,
    onWidgetDelete,
    onWidgetMaximize,
    onWidgetLockToggle,
    onWidgetTypeUpdate,
    onWidgetUpdate,
    getCustomWidgetActions,
    refreshAndUpdateDashboard,
    onAddCatalogWidget,
    onTriage,
    onAddAdhocEventFilter,
    onVariablesUpdate: onVariablesUpdated,
    onVariablesValueUpdate: onVariablesValueUpdated,
    widgetVariableSrvMap,
    widgetVariableSrvLoadingState,
    eventTypeToFieldsMap,
    onUpdateCohortId,
    onAddAdhocEntityFilter,
    hasCustomClone: Boolean(pOnWidgetClone),
    hasCustomDelete: Boolean(pOnWidgetDelete),
    presetTimeRange,
    onWidgetEdit: pOnWidgetEdit,
    embedded
  });

  const onDashboardSave = useCallback(() => usrOnDashboardSave(dbImpl), [dbImpl, usrOnDashboardSave]);
  const onAddWidgetToDashboard = useCallback(() => {
    refreshAndUpdateDashboard();
    refreshVariableSrvMap();
    closeCatalogWidgetModal();
    setTimeout(() => updateFilterQueryParams(), 0); // setTimeOut makes sure variableSrv is defined and then gets executed
    cloneWidgetIdRef.current = "";
  }, [closeCatalogWidgetModal, refreshAndUpdateDashboard, refreshVariableSrvMap, updateFilterQueryParams]);

  const layout = dbImpl && dbImpl.layout ? dbImpl.layout : [];
  const { cols, respLayouts } = useDashboardLayout(layout);

  const isDbLoading = !dbImpl || !widgetVariableSrvMap[GLOBAL_VARIABLE_SRV_KEY] || !isEventTypeFieldsMapInitialized;

  const className = useMemo(
    () =>
      cx("dashboard-grid", {
        "cohort-dashboard-grid": dbImpl?.isCohortDashboard(),
        "dashboard-grid--loading": isDbLoading
      }),
    [dbImpl, isDbLoading]
  );

  const margin = useMemo<[number, number]>(() => [styleOptions.spacing, styleOptions.spacing], [styleOptions.spacing]);

  const showFilterPanel = useMemo(() => {
    const enableDashboardFilterPanel = !tenantConfigState.disableNewDashboardFilterPanel;
    const show = !embedded;
    // let filtersExist = false;
    // dbImpl?.widgets?.forEach((widget) => {
    //   if (widget.type === "filter" && (widget as FilterWidgetImpl).entityTypeId) {
    //     const pinnedFilter = (widget as FilterWidgetImpl).variables.find(x => (x as CohortVariableImpl).isPinned);
    //     const isCohortPinned = (widget as FilterWidgetImpl).isCohortPinned;
    //     if (pinnedFilter || isCohortPinned) {
    //       filtersExist = true;
    //     }
    //   }
    // });
    return show && enableDashboardFilterPanel;
  }, [embedded, tenantConfigState]);

  if (isDbLoading) {
    return (
      <div className={className}>
        <LoadingSpinner
          className="inc-text-body-medium"
          titleText="Loading dashboard..."
        />
      </div>
    );
  }

  const isMaximizeView = Boolean(maximizeWidgetProps);

  const isAnomShareDashboard = Boolean(appConfig.anomShareId);

  return (
    <div
      className={className}
      data-maximize-view={isMaximizeView}
    >
      {!embedded && dbImpl.isDefaultDashboard() && !isAnomShareDashboard && (
        <>
          <DashToolbar
            dbImpl={dbImpl}
            infoComponent={toolbarInfoComponent}
            mode={mode}
            onAddWidget={onAddWidget}
            onAddWidgetCustom={onAddCatalogWidget}
            onOpenSettings={onOpenSettings}
            onSave={onDashboardSave}
            onUpdate={refreshAndUpdateDashboard}
            onVariablesUpdate={onVariablesUpdated}
            redirectOnAction={redirectOnAction}
            variableSrvMap={widgetVariableSrvMap}
          />
        </>
      )}

      {isSettingsOpen && (
        <SettingsModal
          isOpen
          onClose={onCloseSettings}
          onSave={onSaveSettings}
          presetTimeInfo={presetTimeInfo}
        />
      )}

      {(showFilterPanel || isAnomShareDashboard) && (
        <DashboardFilterPanel
          dbImpl={dbImpl}
          onAddWidgetToDashboard={onAddWidgetToDashboard}
          onUpdateCohortId={onUpdateCohortId}
          onVariablesUpdate={onVariablesUpdated}
          variableLoadingStateMap={widgetVariableSrvLoadingState}
          variableSrvMap={widgetVariableSrvMap}
        />
      )}

      <div className="dashboard-grid--grid paddingRt4">
        {dbImpl.widgets.length === 0 && (
          <div className="dashboard-empty-state inc-flex-column inc-flex-center flex-gap-12">
            <EmptyState
              className="empty-state"
              hideImage={hideEmptyStateImage}
              messageId={emptyStateLabel || "dashboards.empty.state.message"}
            />
            {addWidgetIfEmpty && (
              <IncButton
                color="secondary-blue"
                iconType="iconText"
                onClick={onAddCatalogWidget}
              >
                <IncFaIcon iconName="plus" />
                Add Widget
              </IncButton>
            )}
          </div>
        )}

        {!isMaximizeView && dbImpl.widgets.length > 0 && (
          <ResponsiveGridLayout
            cols={cols}
            containerPadding={[0, 0]}
            draggableHandle=".title-wrapper"
            isDraggable={dbImpl.meta.resizable}
            isResizable={dbImpl.meta.resizable}
            layouts={respLayouts}
            margin={margin}
            onLayoutChange={onLayoutChanged}
            onWidthChange={refreshDashboard}
            rowHeight={styleOptions.rowHeight}
            // This cannot be changed since the menu items will be hidden due to stacking context due to transform property
            useCSSTransforms={false}
          >
            {widgets}
          </ResponsiveGridLayout>
        )}

        <AddCatalogWidget
          canAddWidget={widgets?.length ? null : addWidgetIfEmpty}
          cloneWidgetId={cloneWidgetIdRef.current}
          dbImpl={dbImpl}
          onAddWidget={onAddWidgetToDashboard}
          onClose={onCloseCatalogWidgetModal}
          open={catalogWidgetModalOpen}
        />
      </div>

      {isMaximizeView && (
        <DashboardMaximizeView
          {...maximizeWidgetProps}
          dbImpl={dbImpl}
        />
      )}
    </div>
  );
};

export default DashboardGrid;
