import React, { useMemo, useCallback, FC, memo } from "react";
import { IncGroupSelectWithIconOption, IncSelectWithIcon, IncSelectWithIconOption, IncFaIcon } from "@inception/ui";
import { groupBy } from "lodash";
import {
  ActionSourceTypes,
  ActionCategoryType,
  ActionCategory,
  ActionRunMode,
  ActionSourceType
} from "../../services/api/operationalise";
import { getSourceTypeIcon } from "../../components/business-entity";

interface Props {
  actionCategories: ActionCategory[];
  onAlertSourceTypeSelected: (
    categoryId: ActionCategoryType,
    sourceType: ActionSourceTypes,
    runMode: ActionRunMode,
    supportsTestActionWithRealAlertOnly?: boolean
  ) => void;
  isLoading?: boolean;
}

export type ActionInfo = ActionSourceType & {
  categoryId: ActionCategoryType;
};

type OptionData = {
  categoryId: ActionCategoryType;
  sourceType: ActionSourceTypes;
  runMode: ActionRunMode;
  supportsTestActionWithRealAlertOnly?: boolean;
};

export const AddActionSelect: FC<Props> = memo(props => {
  const { actionCategories, onAlertSourceTypeSelected, isLoading } = props;

  const addActionOptions = useMemo(() => getActionsOptions(actionCategories || []), [actionCategories]);

  const onAddAlertType = useCallback(
    (opt: IncSelectWithIconOption<OptionData>) => {
      const { data } = opt;
      const { categoryId, sourceType, runMode, supportsTestActionWithRealAlertOnly } = data;
      onAlertSourceTypeSelected(categoryId, sourceType, runMode, supportsTestActionWithRealAlertOnly);
    },
    [onAlertSourceTypeSelected]
  );

  return (
    <div
      className="add-actions-container"
      data-loading={isLoading}
    >
      <IncSelectWithIcon
        autoAdjustWidth
        autoAdjustWidthBuffer={16}
        isLoading={isLoading}
        isSearchable={false}
        onChange={onAddAlertType}
        options={addActionOptions}
        value={addIconOpt}
        wrapperClass="add-action-wrapper inc-cursor-pointer"
      />
    </div>
  );
});

const addIconOpt: IncSelectWithIconOption = {
  icon: <IncFaIcon iconName="plus" />,
  label: "Add action",
  value: "addAction"
};

const getActionsOptions = (data: ActionCategory[]): Array<IncGroupSelectWithIconOption<OptionData>> => {
  const groupOptions: Array<IncGroupSelectWithIconOption<OptionData>> = [];
  const groupByType = groupBy(data, "actionCategoryType");
  const notificationTypes = Object.keys(groupByType);

  notificationTypes.forEach(notificationType => {
    const actCategories = groupByType[notificationType];

    actCategories.forEach(actCategory => {
      const { actionCategoryType, actionSourceTypes, name } = actCategory;

      const options = actionSourceTypes.map((sourceType): IncSelectWithIconOption<OptionData> => {
        const { name, sourceTypeId, icon, defaultRunMode, supportsTestActionWithRealAlertOnly } = sourceType;

        return {
          label: name,
          value: sourceTypeId,
          data: {
            sourceType: sourceTypeId as ActionSourceTypes,
            categoryId: actionCategoryType,
            runMode: defaultRunMode,
            supportsTestActionWithRealAlertOnly
          },
          icon: getSourceTypeIcon(sourceTypeId, icon, "action-icon marginRt6", 18)
        };
      });

      groupOptions.push({
        groupLabel: name,
        options
      });
    });
  });

  return groupOptions;
};
