import React from "react";
import { IncFaIcon } from "@inception/ui";
import { widgetRegistry, Dimensions } from "../WidgetRegistry";
import { USFieldWidget } from "./USFieldWidget";

widgetRegistry.register({
  id: "us-field",
  label: "Bicycle - Userservice Field widget",
  enableInDashboard: false,
  supportsComparison: false,
  supportsNativeEdit: false,
  supportsTimeRange: true,
  iconComponent: (): JSX.Element => <IncFaIcon iconName="chart-waterfall" />,
  component: props => <USFieldWidget {...props} />,
  dimensions: (): Dimensions => ({
    w: 6,
    h: 12,
    minW: 6,
    minH: 6
  })
});
