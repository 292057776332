import React, { FC, useMemo } from "react";
import { IncToolTip, IncFaIcon } from "@inception/ui";
import LoadingSpinner from "../../Loading/Loading";
import { VerticallyCenteredRow } from "../../flex-components";
import { ImpactedWidget } from "../../../services/api/explore";
import { ImpactedWidgetsDataEntry } from "./hooks";
import { getDataValue, getFormattedValueForImpactedWidget } from "./utils";

interface Props {
  dataEntry: ImpactedWidgetsDataEntry;
  impactedWidget: ImpactedWidget;
  tagsData: Record<string, string>;
}

export const ResultRenderer: FC<Props> = props => {
  const { dataEntry, tagsData, impactedWidget } = props;

  const { data, error, isError, isFetching = true } = dataEntry || {};

  const value = useMemo(() => {
    const value = getDataValue(data, tagsData);

    const formattedValue = getFormattedValueForImpactedWidget(impactedWidget, value);
    return formattedValue;
  }, [data, impactedWidget, tagsData]);

  return (
    <>
      {isFetching && <LoadingSpinner titleText=" " />}
      {!isFetching && (
        <>
          {isError && (
            <IncToolTip
              placement="top"
              titleText={error}
              variant="error"
            >
              <VerticallyCenteredRow className="flex-grow inc-flex-center">
                <IncFaIcon
                  className="status-danger"
                  iconName="warning"
                />
              </VerticallyCenteredRow>
            </IncToolTip>
          )}
          {!isError && (
            <VerticallyCenteredRow className="flex-grow inc-flex-center height-100">{value}</VerticallyCenteredRow>
          )}
        </>
      )}
    </>
  );
};
