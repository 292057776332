import React, { FC, useState, useMemo } from "react";
import { IncSelectOption, IncSelect } from "@inception/ui";
import {
  getDisplayTagNameForUSFieldSlice,
  ImpactedWidget,
  ExploreEntityFilter,
  UserServiceFilterList
} from "../../../services/api/explore";
import { VerticallyCenteredRow } from "../../flex-components";
import { getMetricIdForImpactWidget } from "../utils";
import { useLocalToGlobalFilters } from "../filters";
import { SliceDistribution } from "./SliceDistribution";
import { FieldDrilldownStats } from "./FieldDrilldownStats";
import { FieldDrilldownTable } from "./FieldDrilldownTable";
import { getPropsFromPreset } from "./common";
import { FieldDrilldownProps, DrilldownSubRendererProps } from "./types";

export const FieldDrillDown: FC<FieldDrilldownProps> = props => {
  const {
    impactedWidgetList,
    preset,
    eventFieldFilters: pEventFieldFilters,
    entityFilters: pEntityFilters,
    onAddEntityFilter,
    onAddEventFilter,
    keyContributorsFetchState,
    opSchema
  } = props;

  const { alertingSlices, diagnosticSlices } = useMemo(() => {
    const alertingSlices = opSchema?.alertingEventFields?.slices || [];
    const diagnosticSlices = opSchema?.diagnosticEventFields?.slices || [];

    return {
      alertingSlices,
      diagnosticSlices
    };
  }, [opSchema]);

  const { impactedWidgets, ...restIwListProps } = impactedWidgetList;

  const entityFilters = useMemo(() => pEntityFilters || [], [pEntityFilters]);
  const eventFieldFilters = useMemo(
    () =>
      pEventFieldFilters || {
        userServiceFilters: []
      },
    [pEventFieldFilters]
  );

  const { addLocalEntityFilter, addLocalEventFilter, bizFieldPredicates, filtersJsx, usFilterExprs } =
    useLocalToGlobalFilters({
      addToGlobalEntityFilters: onAddEntityFilter,
      addToGlobalEventFilters: onAddEventFilter,
      lookupData: keyContributorsFetchState?.lookupData || {}
    });

  const fEntityFilters = useMemo<ExploreEntityFilter[]>(() => {
    const predicates = [...(entityFilters?.[0]?.filters || [])];
    predicates.push(...bizFieldPredicates);
    return [
      {
        filters: predicates
      }
    ];
  }, [bizFieldPredicates, entityFilters]);

  const fEventFieldFilters = useMemo<UserServiceFilterList>(() => {
    const filExprs = [...(eventFieldFilters?.userServiceFilters?.[0]?.userServiceFilterExpressions || [])];
    filExprs.push(...usFilterExprs);
    return {
      userServiceFilters: [
        {
          userServiceFilterExpressions: filExprs
        }
      ]
    };
  }, [eventFieldFilters, usFilterExprs]);

  const userServiceFilters = useMemo(() => {
    const userServiceFilters: Record<string, UserServiceFilterList> = {};

    if (fEventFieldFilters) {
      impactedWidgets.forEach(iw => {
        const metricIdKey = getMetricIdForImpactWidget(iw);
        if (metricIdKey) {
          userServiceFilters[metricIdKey] = fEventFieldFilters;
        }
      });
    }

    return userServiceFilters;
  }, [fEventFieldFilters, impactedWidgets]);

  const { impactedWidgetId, title, slice } = preset;

  const options = useMemo<Option[]>(
    () =>
      impactedWidgets
        .filter(impactedWidget => {
          const metricId = impactedWidget?.bizDataQuery?.sliceSpec?.metricId;
          const metrics = impactedWidget.bizDataQuery?.widgetConfig?.dataDefinition?.metrics || {};
          const metric = metrics[metricId];
          const isExpressionMetric = metric?.sourceType === "expression";
          return !isExpressionMetric;
        })
        .map(impactedWidget => ({
          label: impactedWidget.name,
          value: impactedWidget.id,
          data: impactedWidget
        })),
    [impactedWidgets]
  );
  const defSelOpt = options.find(({ value }) => value === impactedWidgetId) || options[0];

  const [selImpactedWidget, setSelImpactedWidget] = useState(defSelOpt);
  const impactedWidget = selImpactedWidget.data;

  const { displayTagName } = getDisplayTagNameForUSFieldSlice(slice);
  const dTitle = title || `${displayTagName} Drill-Down`;

  const distributionTitle = useMemo(() => {
    const { slice, title } = getPropsFromPreset(preset, "distribution");
    const { displayTagName } = getDisplayTagNameForUSFieldSlice(slice);

    return title || displayTagName;
  }, [preset]);

  const vizProps: DrilldownSubRendererProps = {
    ...props,
    ...restIwListProps,
    alertingSlices,
    diagnosticSlices,
    impactedWidget,
    userServiceFilters,
    entityFilters: fEntityFilters,
    onAddEntityFilter: addLocalEntityFilter,
    onAddEventFilter: addLocalEventFilter
  };

  return (
    <div className="field-drilldown">
      <VerticallyCenteredRow className="field-drilldown--header">
        {dTitle}

        <VerticallyCenteredRow className="marginLtAuto">{filtersJsx}</VerticallyCenteredRow>
      </VerticallyCenteredRow>

      <div className="field-drilldown--content">
        <div className="field-drilldown--primary-slice with-padding">
          <VerticallyCenteredRow className="marginBt12">
            <VerticallyCenteredRow>{distributionTitle}</VerticallyCenteredRow>
            <VerticallyCenteredRow className="marginLtAuto transparent-select-container">
              <IncSelect
                alignment="row"
                isSearchable={false}
                label="Distribution by"
                onChange={setSelImpactedWidget}
                options={options}
                value={selImpactedWidget}
                wrapperClass="impact-widget-select"
              />
            </VerticallyCenteredRow>
          </VerticallyCenteredRow>

          <SliceDistribution {...vizProps} />
        </div>

        <div className="separator" />

        <div className="field-drilldown--secondary-viz with-padding">
          <div className="inc-card-layout horizontal-bars-and-table width-100">
            <FieldDrilldownStats
              {...vizProps}
              impactedWidgets={impactedWidgets}
            />

            <FieldDrilldownTable
              {...vizProps}
              presetKey="treeMap"
            />
          </div>

          <FieldDrilldownTable {...vizProps} />
        </div>
      </div>
    </div>
  );
};

type Option = IncSelectOption<ImpactedWidget>;
