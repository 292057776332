import React, { FC, memo, useEffect } from "react";
import { AlertEventRecord, useFetchInsightMembership } from "../../services/api/operationalise";
import { logger } from "../../core/logging/Logger";
import { InsightStats } from "./InsightStats";

interface Props {
  incident: AlertEventRecord;
  generateDemoData: boolean;

  durationLabel: string;
  isLoading?: boolean;
}

export const InsightStatsWithDataFetch: FC<Props> = memo(props => {
  const { incident, generateDemoData, durationLabel, isLoading } = props;

  const { opConfigId, id: incidentId, startTimeMillis, endTimeMillis } = incident || {};

  const { data, error, isError, isFetching, refetch } = useFetchInsightMembership(
    opConfigId,
    incidentId,
    startTimeMillis,
    endTimeMillis,
    generateDemoData,
    true
  );

  useEffect(() => {
    if (isError) {
      logger.error("InsightStats", "Error fetching insight stats", error);
    }
  }, [error, isError]);

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, [isLoading, refetch]);

  return (
    <InsightStats
      durationLabel={durationLabel}
      insightMembershipData={data}
      isLoading={isFetching || isLoading || (!isError && !data)}
    />
  );
});
