import { logger } from "../core";

const pointWKTFormat = /POINT\s*\((-?\d+.?\d+) (-?\d+.?\d+)\)/;

export const getPointWKTLocationFromJSONString = (jsonStr: string) => {
  let wktLocationStr = "";
  try {
    const { wktLocation } = JSON.parse(jsonStr);
    wktLocationStr = wktLocation;
  } catch (err) {
    const isPointWKT = pointWKTFormat.test(jsonStr);
    if (isPointWKT) {
      wktLocationStr = jsonStr;
    } else {
      logger.error("Parse POINT WKT location", "Error parsing POINT WKT location string", jsonStr);
    }
  }

  return wktLocationStr;
};

export const getLatLonFromPointWKT = (pointWKT: string) => {
  const isPointWKT = pointWKTFormat.test(pointWKT);
  if (isPointWKT) {
    const [completeMatch, lonStr, latStr] = pointWKTFormat.exec(pointWKT);
    if (completeMatch === pointWKT) {
      const lat = parseFloat(latStr);
      const lon = parseFloat(lonStr);
      return {
        lat,
        lon
      };
    }
    throw Error("Invalid string for PointWKT. Expected: POINT(-100.23 97.54)");
  }
  throw Error("Invalid string for PointWKT. Expected: POINT(-100.23 97.54)");
};
