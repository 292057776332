import { IncSmartText } from "@inception/ui";
import React, { memo } from "react";
import { useToggleState } from "../../core";
import { ConnectYourDataModal } from "./ConnectYourDataModal";
import { useConnectDataBanner } from "./useConnectDataBanner";

export const ConnectYourDataBanner = memo(() => {
  const { connectDataBannerState } = useConnectDataBanner();
  const { isConnectDataBannerVisible, useCases } = connectDataBannerState;

  const { isOpen: isConnectDataModalOpen, open: openConnectDataModal, close: closeConnectDataModal } = useToggleState();

  return (
    <>
      {isConnectDataBannerVisible && (
        <div className="inc-flex-row-reverse marginLt30">
          <IncSmartText
            className="inc-cursor-pointer inc-text-header-regular connect-banner"
            onClick={openConnectDataModal}
            text="You are seeing sample  data, Click here to connect"
          />
        </div>
      )}

      <ConnectYourDataModal
        closeConnectYourDataModal={closeConnectDataModal}
        isConnectYourDataModalOpen={isConnectDataModalOpen}
        isUseCasesLoading={false}
        useCases={useCases}
      />
    </>
  );
});
