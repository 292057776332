import React, { FC, useMemo, useEffect, useState, useCallback, useRef } from "react";
import { IncFaIcon } from "@inception/ui";
import { isEqual } from "lodash";
import {
  TriagePageConfigPayload,
  KeyContributorsResponse,
  ExploreEntityFilter,
  UserServiceFilterList,
  UserServiceFilterExpression,
  BizFieldPredicate,
  UserServiceFieldSlice,
  WidgetConfigUtils
} from "../../../services/api/explore";
import { VerticallyCenteredRow } from "../../flex-components";
import LoadingSpinner from "../../Loading/Loading";
import { useFetchKeyContributors } from "../IncidentTimeline/hooks";
import { useTimeRange } from "../../../core";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { useLocalToGlobalFilters } from "../filters";
import { ENTITY_DATA_TYPE } from "../../../field-picker/constants";
import { KeyContributors } from "./KeyContributors";

interface Props {
  opConfigId: string;
  incidentId: string;
  primaryMetricName: string;

  diagnosticFields: UserServiceFieldSlice[];
  alertingFields: UserServiceFieldSlice[];

  eventFieldFilters?: UserServiceFilterList;
  entityFilters?: ExploreEntityFilter[];

  numKeyContributors?: number;
  onDataFetch?: (data: KeyContributorsResponse, isLoading: boolean, error: string) => void;

  preset?: TriagePageConfigPayload["keyContributors"];
  indicatorsPreset?: TriagePageConfigPayload["keyIndicators"];

  onAddEntityFilter?: (entityFilter: BizFieldPredicate) => void;
  onAddEventFilter?: (eventFilter: UserServiceFilterExpression) => void;
}

export const KeyContributorsWrapper: FC<Props> = props => {
  const {
    incidentId,
    opConfigId,
    entityFilters: pEntityFilters,
    eventFieldFilters: pEventFieldFilters,
    onDataFetch,
    preset,
    numKeyContributors = 3,
    onAddEntityFilter,
    onAddEventFilter,
    primaryMetricName,
    diagnosticFields,
    alertingFields,
    indicatorsPreset
  } = props;

  const { timeRange } = useTimeRange();
  const { fromMillis, toMillis } = useMemo(() => timeRangeUtils.getMillisFromTimeRange(timeRange), [timeRange]);

  const prevSlicesToFetch = useRef<string[]>([]);

  const { sliceTagNames: presetSliceTagNames } = preset || {};

  const [lookupData, setLookupData] = useState<Record<string, string>>({});

  const entityFilters = useMemo(() => pEntityFilters || [], [pEntityFilters]);
  const eventFieldFilters = useMemo(
    () =>
      pEventFieldFilters || {
        userServiceFilters: []
      },
    [pEventFieldFilters]
  );

  const { addLocalEntityFilter, addLocalEventFilter, bizFieldPredicates, filtersJsx, usFilterExprs } =
    useLocalToGlobalFilters({
      addToGlobalEntityFilters: onAddEntityFilter,
      addToGlobalEventFilters: onAddEventFilter,
      lookupData
    });

  const fEntityFilters = useMemo<ExploreEntityFilter[]>(() => {
    const predicates = [...(entityFilters?.[0]?.filters || [])];
    predicates.push(...bizFieldPredicates);
    return [
      {
        filters: predicates
      }
    ];
  }, [bizFieldPredicates, entityFilters]);

  const fEventFieldFilters = useMemo<UserServiceFilterList>(() => {
    const filExprs = [...(eventFieldFilters?.userServiceFilters?.[0]?.userServiceFilterExpressions || [])];
    filExprs.push(...usFilterExprs);
    return {
      userServiceFilters: [
        {
          userServiceFilterExpressions: filExprs
        }
      ]
    };
  }, [eventFieldFilters, usFilterExprs]);

  const sliceTagNamesToFetch = useMemo<string[]>(() => {
    const sliceTagNamesToFetch: string[] = [...(presetSliceTagNames || []), ...(indicatorsPreset?.sliceTagNames || [])];

    const sortedSliceTagNames = sliceTagNamesToFetch.sort();
    const tagNamesChanged = !isEqual(sortedSliceTagNames, prevSlicesToFetch.current);

    prevSlicesToFetch.current = tagNamesChanged ? sortedSliceTagNames : prevSlicesToFetch.current;

    return prevSlicesToFetch.current;
  }, [indicatorsPreset, presetSliceTagNames]);

  const diagnosticFieldsToFetch = useMemo<UserServiceFieldSlice[]>(
    () =>
      diagnosticFields?.filter(slice => {
        const { tagName, userServiceField } = slice;
        const presetExists = Boolean(sliceTagNamesToFetch.length);
        return presetExists
          ? sliceTagNamesToFetch.includes(tagName)
          : WidgetConfigUtils.getUSFieldDataType(userServiceField) === ENTITY_DATA_TYPE;
      }),
    [diagnosticFields, sliceTagNamesToFetch]
  );

  const alertingFieldsToFetch = useMemo<UserServiceFieldSlice[]>(
    () =>
      alertingFields?.filter(slice => {
        const { tagName, userServiceField } = slice;
        const presetExists = Boolean(sliceTagNamesToFetch.length);
        return presetExists
          ? sliceTagNamesToFetch.includes(tagName)
          : WidgetConfigUtils.getUSFieldDataType(userServiceField) === ENTITY_DATA_TYPE;
      }),
    [alertingFields, sliceTagNamesToFetch]
  );

  const { data, error, isError, isFetching, refetch } = useFetchKeyContributors(
    incidentId,
    opConfigId,
    fEventFieldFilters,
    fEntityFilters,
    fromMillis,
    toMillis,
    diagnosticFieldsToFetch,
    alertingFieldsToFetch
  );

  useEffect(() => {
    if (refetch && !isFetching && !data) {
      refetch();
    }
  }, [data, isFetching, refetch]);

  useEffect(() => {
    if (onDataFetch) {
      onDataFetch(data, isFetching, error);
    }
  }, [data, error, isFetching, onDataFetch]);

  const [expanded, setExpanded] = useState(true);
  const toggleExpand = useCallback(() => {
    if (!isFetching) {
      setExpanded(prev => !prev);
    }
  }, [isFetching]);

  const [numExtraItems, setNumExtraItems] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(prev => !prev);

  const {
    keyContributors,
    multiSliceContribution: values,
    lookupData: slLookupData,
    deepLink: deepLinkData
  } = data || {};

  useEffect(() => {
    setLookupData(slLookupData);
  }, [slLookupData]);

  return (
    <div
      className="key-contributors-wrapper"
      data-expanded={expanded}
    >
      <VerticallyCenteredRow className="header-wrapper">
        <VerticallyCenteredRow>
          <VerticallyCenteredRow
            className="inc-flex-grow inc-cursor-pointer"
            onClick={toggleExpand}
          >
            {!isFetching && (
              <IncFaIcon
                className="arrow-icon"
                iconName="chevron-circle-up"
              />
            )}
            <div className="inc-text-body-medium marginRt12">Key Contributors</div>

            {Boolean(numExtraItems) && <div className="inc-label-common marginRt12">+ {numExtraItems} more</div>}
          </VerticallyCenteredRow>

          {filtersJsx}

          {Boolean(numExtraItems) && (
            <div
              className="marginLtAuto status-info inc-text-subtext-medium inc-cursor-pointer"
              onClick={toggleShowMore}
            >
              {showMore ? "Show less" : "Show more"}
            </div>
          )}
        </VerticallyCenteredRow>
      </VerticallyCenteredRow>

      {isFetching && <LoadingSpinner />}

      {!isFetching && (
        <>
          {isError && (
            <VerticallyCenteredRow className="status-danger">
              <IncFaIcon
                className="status-danger marginRt10"
                iconName="warning"
              />
              <VerticallyCenteredRow>Error fetching key contributors: {error}</VerticallyCenteredRow>
            </VerticallyCenteredRow>
          )}

          {!isError && expanded && (
            <>
              <KeyContributors
                deepLinkData={deepLinkData}
                keyContributors={keyContributors}
                lookupData={lookupData}
                nonGroupedKeyContributors={values}
                numContributors={numKeyContributors}
                onAddEntityFilter={addLocalEntityFilter}
                onAddEventFilter={addLocalEventFilter}
                presetTagNames={presetSliceTagNames}
                primaryMetricName={primaryMetricName}
                setNumExtraItems={setNumExtraItems}
                showAll={showMore}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};
