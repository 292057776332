import { IncFaIconName, ISaxIconName } from "@inception/ui";
import { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { IncButtonColor } from "@inception/ui/src/components/Button/Button";
import { EntityPropertyValue, EQPredicateOperation, PrimKind, RawTimeRange } from "../../../../core";
import {
  BizDataQuery,
  ImpactedWidget,
  ImpactedWidgetList,
  LogicalOperator,
  ResponseInfo,
  SliceSet,
  TimeObj,
  UserServiceFieldSlice,
  UserServiceFieldSliceSet,
  UserServiceFilterList
} from "../../explore";
import {
  BizActionConfig,
  BizIdProps,
  CauseMetaData,
  CompareOperator,
  HistoryToConsider,
  OpBizDataQuery,
  OpCreationConfig,
  OpInsightType,
  OpSchedule,
  OpStartTrigger,
  OpThreshold,
  OpType,
  SeasonalityOverrideDef,
  SpecificScheduleConfig,
  SuppressionConfigDef,
  Suppressions,
  TrendType
} from "../../operationalise";
import { DrilldownTypes } from "../../triage-v2";
import { CatalogWidgetProperties } from "../../../../dashboard/widgets/Catalog/models";
import { KPI, UseCaseDataQueryConfig, VerticalIdentifier } from "../../use-case-catalog";
import { ConversationStatus } from "./common2";

export enum LowLevelFragmentType {
  OP_FRAGMENT = "OP_FRAGMENT",
  BIZ_ACTION = "BIZ_ACTION",
  FRAGMENT_LIST = "FRAGMENT_LIST",
  USER_SERVICE_FIELD_SLICE = "USER_SERVICE_FIELD_SLICE_SET",
  KPI = "KPI",
  HIGHCHARTS_FRAGMENT = "HIGHCHARTS_FRAGMENT",
  TRIAGE_CLUSTERS_RESPONSE = "TRIAGE_CLUSTERS_RESPONSE",
  INTERMEDIATE_FRAGMENT_LIST = "INTERMEDIATE_FRAGMENT_LIST",
  DISPLAY_TEXT_LIST_ITEM = "DISPLAY_TEXT_LIST_ITEM",
  MARKDOWN = "MARKDOWN",
  VIZ_FRAGMENT = "VIZ_FRAGMENT",
  HTML = "HTML",
  QUERY_CONVERSION_RESPONSE = "QUERY_CONVERSION_RESPONSE",
  BICYCLE_OP_QUERY = "BICYCLE_OP_QUERY",
  SLICES_SET = "SLICES_SET",
  ENUM_FRAGMENT = "ENUM_FRAGMENT",
  SEED_INPUT = "SEED_INPUT",
  DRILLDOWN_STEP = "DRILLDOWN_STEP",
  OBJECT_FRAGMENT = "OBJECT_FRAGMENT",
  INPUT_TEXT_FRAGMENT = "INPUT_TEXT_FRAGMENT",
  BUTTON_FRAGMENT = "BUTTON_FRAGMENT",
  RADIO_OPTION = "RADIO_OPTIONS",
  SELECT_OPTION = "SELECT_OPTION",
  KPI_WITH_SLICES = "KPI_WITH_SLICES",
  DASHBOARD = "DASHBOARD"
}

export type ParameterType = {
  primKind?: PrimKind;
  lowLevelFragmentType?: LowLevelFragmentType;
};

export type LowLevelFragment = {
  id: string;
  name: string;
  description: string;
  isMarkdown: boolean;
  fragmentInfo: FragmentInfo;
  type: LowLevelFragmentType;
  references: LowLevelFragmentList;

  opFragment?: OpFragment;
  bizAction?: BizActionConfig;
  userServiceFieldSlice?: UserServiceFieldSlice;
  userServiceFieldSliceSet?: UserServiceFieldSliceSet;
  bizDataQuery?: BizDataQuery;
  highchartsFragment?: HighChartsFragment;
  intermediateFragmentList?: LowLevelFragmentList;
  lowLevelFragmentList?: LowLevelFragmentList;
  displayTextListItem?: DisplayTextListItem;
  markdown?: MarkdownFragment;
  html?: HtmlFragment;
  vizFragment?: VizFragment;
  seedInput?: SeedInput;
  slicesSet?: SliceSet;
  enumFragment?: EnumFragment;
  drilldownStep?: DrilldownTypes.DrillDownStep;
  objectFragment?: ObjectFragment;
  inputTextFragment?: InputTextFragment;
  buttonFragment?: ButtonFragment;
  queryConversionResponse?: QueryConversionResponse;
  radioOptions?: RadioOptions;
  selectOptions?: SelectOptions;
  kpi?: KPI;
  kpiWithSlicesFragment?: KPIWithSlicesFragment;
  dashboardFragment?: DashboardFragment;
};

export type KPIWithSlicesFragment = {
  id: string;
  kpi: KPI;
  userServiceFieldSliceSet: UserServiceFieldSliceSet;
};

export type DashboardFragment = {
  name: string;
  description: string;
  widgets: DashboardFragmentWidget[];
  layouts: DashboardFragmentLayout[];
  filters: UserServiceFilterList;
  defaultTimeRange?: RawTimeRange;
  defaultCompareTime?: TimeObj;
};

export type DashboardFragmentWidget = {
  id: string;
  title: string;
  description: string;
  kpi: KPI;
  properties: CatalogWidgetProperties;
  viz: VizOption;
};

export type DashboardFragmentLayout = {
  widgetId: string;
  height: number;
  width: number;
  posX: number;
  posY: number;
};

export interface RadioOptions {
  options: RadioOption[];
  selectedOptionId: string;
}

export interface RadioOption {
  id: string;
  label: string;
  description: string;
  icon: string;
  fragment?: LowLevelFragment;
}

export interface SelectOptions {
  options: SelectOption[];
}

export interface SelectOption {
  id: string;
  label: string;
  icon: string;
  description: string;
  isSelected: boolean;
  fragment?: LowLevelFragment;
}

export type EnumFragment = {
  options: Option[];
  selectedOptions: string[];
  fragment?: LowLevelFragment;
  clickCounterAllowed?: boolean;
  optionToClickCount?: Record<string, number>;
};

export type QueryConversionResponse = {
  query: string;
  message: string;
  suggestions: string[];
  matchedFields: string[];
};

export type Option = {
  displayName: string;
  value: string;
};

export type HtmlFragment = {
  html: string;
};

export interface MarkdownFragment {
  isEditable: boolean;
  markdown: string;
}

export type OpEventCriteria = {
  metricUserServiceFilters: UserServiceFilterList;
  idProps: BizIdProps;
};

export enum OpFragmentType {
  OP_SCHEDULE = "OP_SCHEDULE",
  OP_THRESHOLD = "OP_THRESHOLD",
  OP_START_TRIGGER = "OP_START_TRIGGER",
  OP_KPI_WITH_COMPARATOR = "OP_KPI_WITH_COMPARATOR",
  OP_SUPPRESSIONS = "OP_SUPPRESSIONS",
  OP_EVENT_CRITERIA = "OP_EVENT_CRITERIA",
  OP_LOOKBACK = "OP_LOOKBACK",
  OP_BIZ_ACTION = "OP_BIZ_ACTION",
  OP_IMPACTED_WIDGETS = "OP_IMPACTED_WIDGETS",
  OP_DIAGNOSTIC_FIELDS = "OP_DIAGNOSTIC_FIELDS",
  OP_CAUSE_META_DATA = "OP_CAUSE_META_DATA",
  USER_SERVICE_FIELD_SLICE_SET = "USER_SERVICE_FIELD_SLICE_SET",
  OP_SEASONALITY_OVERRIDES = "OP_SEASONALITY_OVERRIDES",
  OP_KPI_WITH_COMPARATOR_AND_TREND = "OP_KPI_WITH_COMPARATOR_AND_TREND",
  OP_HISTORY_TO_CONSIDER = "OP_HISTORY_TO_CONSIDER",
  OP_KPI = "OP_KPI",
  OP_SLICES = "OP_SLICES",
  OP_TYPE_INFO = "OP_TYPE_INFO",
  OP_COMPARATOR = "OP_COMPARATOR",
  OP_START_TRIGGER_V2 = "OP_START_TRIGGER_V2",
  OP_THRESHOLD_V2 = "OP_THRESHOLD_V2",
  OP_EVALUATION_WINDOW = "OP_EVALUATION_WINDOW",
  OP_CAUSE_GRAPH = "OP_CAUSE_GRAPH",
  IMPACTED_WIDGETS = "IMPACTED_WIDGETS",
  DIAGNOSTIC_FIELDS = "DIAGNOSTIC_FIELDS"
}

export type OpFragment = {
  opSchedule?: OpSchedule;
  thresholdWithContext?: ThresholdWithContext;
  startTriggerWithContext?: StartTriggerWithContext;
  kpiWithComparator?: KpiWithComparator;
  suppressionsWithContext?: SuppressionsWithContext;
  eventCriteria?: OpEventCriteria;
  lookBack?: TimeObj;
  bizAction?: OpBizAction;
  impactedWidgets?: ImpactedWidgetList;
  diagnosticFields?: UserServiceFieldSliceSet;
  causeMetaData?: CauseMetaData;
  seasonalityOverrides?: SeasonalityOverrides;
  kpiWithComparatorAndTrend?: KpiWithComparatorAndTrend;
  historyToConsider?: HistoryToConsider;
  kpi?: KPI;
  userServiceFieldSliceSet?: UserServiceFieldSliceSet;
  patternType?: OpTypeInfo;
  compareOperator?: CompareOperator;
  startTriggerV2?: OpStartTriggerV2;
  thresholdV2?: OpThresholdV2;
  evaluationWindow?: OpEvaluationWindow;
  causeGraph?: DrilldownTypes.CauseGraph;

  opFragmentType: OpFragmentType;
  readOnly: boolean;
};

export type OpEvaluationWindow = {
  startTimeEpochSecs: number;
  endTimeEpochSecs: number;
  timeZone: string;
};

export type OpTypeInfo = {
  opType: OpType;
  supportedOpTypes: OpType[];
  recommendedOpType: OpType;
};

export type StartTriggerWithLabel = {
  label: string;
  startTrigger: OpStartTrigger;
  isRecommended: boolean;
  isCustom: boolean;

  specificScheduleConfig?: SpecificScheduleConfig;
  lookBack?: TimeObj;
  suppressions?: Suppressions;
};

export type OpStartTriggerV2 = {
  startTrigger: StartTriggerWithLabel;
  suggestions: StartTriggerWithLabel[];
};

export type ThresholdWithLabel = {
  label: string;
  thresholdWithContext: ThresholdWithContext;
  isRecommended: boolean;
  isCustom: boolean;
};

export type OpThresholdV2 = {
  threshold: ThresholdWithLabel;
  suggestions: ThresholdWithLabel[];
};

export type ThresholdWithContext = {
  opThreshold: OpThreshold;
  rollingFreq: TimeObj;
  rollingFunction: string;
  baseKpi: BizDataQuery;
  opSchedule: OpSchedule;
};

export type StartTriggerWithContext = {
  startTrigger: OpStartTrigger;
  opSchedule: OpSchedule;
  comparator: CompareOperator;
  baseKpi: BizDataQuery;
};

export interface SeedInput {
  inputPrompt: string;
  lowLevelFragmentList: LowLevelFragmentList;
  seedFragmentType: SeedFragmentType;
  useCaseId: string;
  chatId: string;
}

export interface InputFragmentGenerationResponse {
  seedInput: SeedInput;
}

export interface GetInputFragmentRequest {
  seedFragmentType: SeedFragmentType;
  usecaseId: string;
  kpiId?: string[];
  patternType?: OpInsightType[];
  dimensions?: UserServiceFieldSliceSet;
}

export interface OpBizAction {
  bizAction: BizActionConfig;
  bizActionId: string;
}

export type SeasonalityOverrides = {
  seasonalityOverrides: SeasonalityOverrideDef[];
};

export interface SeedInput {
  inputPrompt: string;
  lowLevelFragmentList: LowLevelFragmentList;
  seedFragmentType: SeedFragmentType;
  useCaseId: string;
  chatId: string;
  verticalIdentifier?: VerticalIdentifier;
}

export enum SeedFragmentType {
  SEED_FRAGMENT_NA = "SEED_FRAGMENT_NA",
  OP10ZE = "OP10ZE",
  COPILOT_RESEARCH = "COPILOT_RESEARCH",
  REGENERATE_PATTERNS = "REGENERATE_PATTERNS"
}

export interface InputFragmentGenerationResponse {
  seedInput: SeedInput;
}

export interface GetInputFragmentRequest {
  seedFragmentType: SeedFragmentType;
  usecaseId: string;
}

export interface OpBizAction {
  bizAction: BizActionConfig;
  bizActionId: string;
}

export type SuppressionsWithContext = {
  suppressions: SuppressionConfigDef[];
  baseKpi: BizDataQuery;
};

export type KpiWithComparator = {
  bizDataQuery: BizDataQuery;
  comparator: CompareOperator;
};

export type KpiWithComparatorAndTrend = {
  bizDataQuery: OpBizDataQuery;
  comparator: CompareOperator;
  trendType: TrendType;
};

export type FragmentInfo = {
  bulletItems: DisplayTextListItem[];
  label: LabelFragment;
  headerTags: string[];
  additionalHeaders: LabelFragment[];
};

export type LowLevelFragmentList = {
  lowLevelFragment: LowLevelFragment[];
};

export type DisplayTextListItem = {
  displayText: string;
  isMarkdown: boolean;
  tags: string[];
};

export type HighChartsFragment = {
  options: Options;
  constructorType?: HighchartsReact.Props["constructorType"];
  title: string;
};

export type VizFragment = {
  // oneOf
  dataQuery?: BizDataQuery;
  impactWidget?: ImpactedWidget;

  viz: VizOption;
  fromMillis: string;
  toMillis: string;
  compareFromOffset: string;
  disableCompare: boolean;
  properties: CatalogWidgetProperties;
};

export enum VizOption {
  VIZ_NA = "VIZ_NA",
  TEXT = "TEXT",
  TIME_SERIES = "TIME_SERIES",
  BAR_CHART = "BAR_CHART",
  DONUT = "DONUT",
  GAUGE = "GAUGE",
  SINGLE_STAT = "SINGLE_STAT",
  TREE_MAP = "TREE_MAP",
  INSIGHTS = "INSIGHTS",
  TABLE = "TABLE",
  RAW_DATA = "RAW_DATA"
}

export type LabelFragment = {
  label: string;

  iconName?: ISaxIconName | IncFaIconName;
  iconSvg?: string;

  iconColor: string;
  backgroundColor?: string;
};

export type ObjectFragment = {
  name: string;
  icon: IncFaIconName | ISaxIconName;
  objectFragmentList: LowLevelFragmentList;
  objectId: string;
};

export type InputTextFragment = {
  label: string;
  placeholder: string;
  editable: boolean;
  // one of value or fragment exists;
  value?: string;
  fragment?: LowLevelFragment;
};

export type ButtonFragment = {
  id: string;
  displayText: string;
  type: IncButtonColor;
  tooltip: string;
};

export type ActionContext = {
  actionBody: ObjectFragment;
};

export interface GenerateOp10zeRequest {
  seedInput: SeedInput;
}

export interface GenerateFromSeedResponse {
  pollId: string;
  planExecutionResponse: PlanExecutionResponse;
  status: ConversationStatus;
  dataQueryConfig?: UseCaseDataQueryConfig;
}

export interface PlanExecutionResponse {
  id: string;
  name: string;
  planNodeExecutions: PlanNodeExecution[];
  status: ConversationStatus;
  lowLevelFragment: LowLevelFragment;
  suggestedValues?: ParameterValue[];
}

export interface PlanNodeExecution {
  id: string;
  planNode: PlanNode;
  lowLevelFragment: LowLevelFragment;
  edgeExecution: PlanEdgeExecution[];
  status: ConversationStatus;
}

export interface PlanEdgeExecution {
  id: string;
  condition: Condition;
  lowLevelFragment: LowLevelFragment;
  true: PlanNodeExecution[];
  false: PlanNodeExecution[];
  status: ConversationStatus;
}

export interface Plan {
  paramInputs: PlanSchema;
  suggestedValues: ParameterValue[];
  nodes: PlanNodes;
  planType: PlanType;
  id: string;
  name: string;
  description: string;
  plan_text: string;
}

export interface PlanActionContext {
  planId: string;
  paramInputs: PlanSchema;
  suggestedValues: ParameterValue[];
  planNode: PlanNode;
}

enum PlanType {
  OP10ZE_PLAN = "OP10ZE_PLAN",
  DATA_ANALYST = "DATA_ANALYST",
  GENERIC = "GENERIC"
}

export interface PlanNodes {
  items: PlanNode[];
}

export interface PlanNode {
  id: string;
  name: string;
  toolSpec: ToolSpec;
  outputType: OutputType;
  lowLevelFragment: LowLevelFragment; // handle later
  edges: PlanEdge[];
}

export interface PlanEdge {
  id: string;
  condition: Condition;
  true: PlanNodes;
  false: PlanNodes;
}

export interface OutputType {
  primKind?: PrimKind;
  lowLevelFragmentType?: LowLevelFragmentType;
}

export enum ToolType {
  DATA_FETCHER_TOOL = "DATA_FETCHER_TOOL",
  DATA_ANALYST_TOOL = "DATA_ANALYST_TOOL"
}

export interface DataFetcherToolSpec {
  bicycleQuery: LowLevelFragment;
  nlpQuery: string;
  outputSchema: PlanSchema;
}

export interface DataAnalystToolSpec {
  question: string;
  outputSchema: PlanSchema;
}

export interface PlanSchema {
  schema: ParameterSchema[];
}

interface ParameterSchema {
  id: string;
  param: string;
  type: {
    primKind: PrimKind;
  };
  description: string;
  paramDisplayName: string;
}

export interface ParameterValue {
  paramId: string;
  primValue?: EntityPropertyValue;
}

export interface ToolParam {
  param: string;
  lowLevelFragment: LowLevelFragment;
  description: string;
  paramDisplayName: string;
}

export interface ToolSpec {
  toolType: ToolType;
  dataFetcherToolSpec?: DataFetcherToolSpec;
  dataAnalystToolSpec?: DataAnalystToolSpec;
}

export interface Condition {
  predicateTree: PredicateTree;
}

export interface Predicate {
  jsonPath: string;
  operator: EQPredicateOperation;
  value: EntityPropertyValue;
  lowLevelFragment: LowLevelFragment;
}

export interface PredicateTree {
  logicalOperator: LogicalOperator;
  predicates: Predicate[];
}

export interface SuggestExplanationsRequest {
  useCaseId: string;
  verticalIdentifier: VerticalIdentifier;
  opCreationConfig: OpCreationConfig;
}

export interface SuggestExplanationsResponse {
  causeGraph: DrilldownTypes.CauseGraph;
  responseInfo: ResponseInfo;
  statusCode: number;
}
