import { IncRTable, TableDataColumn, IncSmartText } from "@inception/ui";
import { orderBy } from "lodash";
import React, { useMemo } from "react";
import {
  PreviewDataResponse,
  UserServiceFieldPreviewResponse
} from "../../../../../../platform/services/api/entity-mapping";
import { TypedObjectValue } from "../../../../../../platform/services/api/operationalise";

interface TransformationPreviewProps {
  previewData: UserServiceFieldPreviewResponse;
}

export const TransformationPreview: React.FC<TransformationPreviewProps> = props => {
  const {
    previewData: { previewResponses }
  } = props;

  const uniqePreviewResponses = useMemo(() => {
    const inputFields: Set<string> = new Set();
    const responsesToDisplay = previewResponses.filter(resp => {
      const { rawFieldValue } = resp;
      const shouldDisplay = !inputFields.has(rawFieldValue);
      if (shouldDisplay) {
        inputFields.add(rawFieldValue);
      }
      return shouldDisplay;
    });
    return orderBy(responsesToDisplay, rep => rep.rawFieldValue);
  }, [previewResponses]);

  const cols = useMemo(() => {
    const tableCols: Array<TableDataColumn<PreviewDataResponse>> = [
      {
        accessor: "rawFieldValue",
        header: "Incoming Value",
        renderer: celldata => <IncSmartText text={celldata} />
      },
      {
        accessor: "transformedValue",
        header: "Transformed Value",
        renderer: (celldata, rowdata) => {
          const { error, transformedValue } = rowdata;
          const key = transformedValue ? (Object.keys(transformedValue)[0] as keyof TypedObjectValue) : null;
          const value = key ? transformedValue[key].toString() : "";
          const className = error ? "status-danger" : "";
          return (
            <IncSmartText
              text={error ? error : value}
              textClass={className}
            />
          );
          // return <span className={className}>{error ? error : value}</span>;
        }
      }
    ];
    return tableCols;
  }, []);

  return (
    <>
      <IncRTable
        columns={cols}
        data={uniqePreviewResponses}
        pagination={{
          enabled: true,
          pageSize: 10
        }}
      />
    </>
  );
};
