import {
  CurrencyType,
  getCurrencyInfo,
  IncButton,
  IncClickAwayPopper,
  IncDateRangePicker,
  IncFaIcon,
  IncMultiSelectCheckBox,
  IncMultiSelectClearIndicator,
  IncSelectOption,
  IncSelectProps,
  IncSmartText,
  IncMultiSelectDropdownIndicator,
  IncToolTip,
  getSymbolForCurrency
} from "@inception/ui";
import { isArray, uniqWith } from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import appConfig from "../../../appConfig";
import { EntityOperation, EntityOpToTraceQueryOpMap } from "../../services/api/explore";
import { getKeyByValue, Options } from "../../utils";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { EntityPropertySelect } from "../EntityPropertySelect";
import { VerticallyCenteredRow } from "../flex-components";
import { NumericRangeProps } from "../numeric-range/types";
import { NumericRangeSlider, NumericRangeSliderOperators } from "../NumericRangeSlider";
import { GenericFilterField } from "./QuickFiltersPicker";

interface Props {
  field: GenericFilterField;
  onChange: (fieldName: string, value: string | string[], operator: EntityOperation) => void;
  onRemove?: (fieldName: string) => void;
  onToggleLock?: (fieldName: string) => void;
  canEdit?: boolean;
  isInternalUser?: boolean;
  options?: Options;
}

export const GenericFilter: React.FC<Props> = (props: Props) => {
  const { field, onChange, options, onRemove: pOnRemove, onToggleLock, canEdit, isInternalUser } = props;

  const fieldName = `${field.entityTypeId}.${field.fieldName}`;

  const isLocked = field?.isLocked || (field?.isLocked === undefined && !field?.isPinned);

  const onRemove = useMemo(() => {
    if (pOnRemove) {
      return () => pOnRemove(field.fieldName);
    }

    return null;
  }, [field.fieldName, pOnRemove]);

  const filter = useMemo(() => {
    let valueContainer = null;

    const { fieldType, isHighCardinalityField } = field;

    if (isHighCardinalityField) {
      valueContainer = (
        <EntityTypeSelector
          key={field.id}
          {...field}
          onChange={onChange}
        />
      );
    } else if (fieldType === "DATE" || fieldType === "DATETIME") {
      valueContainer = (
        <DateRangePickerWrapper
          key={field.id}
          {...field}
          onChange={onChange}
        />
      );
    } else if (fieldType === "LONG") {
      valueContainer = (
        <NumericRangeSliderWrapper
          key={field.id}
          {...field}
          onChange={onChange}
          options={options}
        />
      );
    } else {
      valueContainer = (
        <StringFieldsWrapper
          key={field.id}
          {...field}
          onChange={onChange}
        />
      );
    }

    return valueContainer;
  }, [field, onChange, options]);

  const handleToggleLock = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (field.fieldName) {
        onToggleLock(field.fieldName);
      }
    },
    [field, onToggleLock]
  );

  const onDelete = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onRemove();
    },
    [onRemove]
  );

  return (
    <VerticallyCenteredRow
      className={`generic-filter ${!canEdit && isLocked ? (isInternalUser ? "visible-locked-filter" : "hidden-locked-filter") : ""}`}
    >
      <IncSmartText
        className="label inc-text-color-secondary inc-text-subtext"
        text={fieldName}
      />
      <VerticallyCenteredRow className="values-container paddingLt4 flex-gap-6">
        {filter}
        {!canEdit && isLocked && (
          <IncToolTip titleText="Locked filters will be read-only for Viewers and hidden for Guest users">
            <IncFaIcon
              className="lock-btn-containter inc-cursor-pointer marginRt4 inc-text-subtext-medium"
              iconName="lock"
            />
          </IncToolTip>
        )}
        {canEdit && (
          <IncToolTip titleText="Locked filters will be read-only for Viewers and hidden for Guest users">
            <IncFaIcon
              className="lock-btn-containter inc-cursor-pointer inc-text-subtext-medium"
              iconName={isLocked ? "lock" : "lock-open"}
              onClick={handleToggleLock}
            />
          </IncToolTip>
        )}

        {canEdit && (
          <IncFaIcon
            className="close-btn-containter inc-cursor-pointer inc-text-subtext-medium"
            iconName="xmark"
            onClick={onDelete}
          />
        )}
      </VerticallyCenteredRow>
    </VerticallyCenteredRow>
  );
};

interface WrapperComponentProps extends GenericFilterField {
  onChange: Props["onChange"];
  onRemove?: () => void;
}

const EntityTypeSelector: React.FC<WrapperComponentProps> = props => {
  const { cohortId, entityTypeId, fieldName, onChange, value } = props;

  const onSelectionChange = useCallback(
    (opts: IncSelectOption[]) => {
      const values = opts.map(x => x.value);
      const op = values.length > 1 ? "in" : "eq";
      const eValue = op === "eq" ? values[0] || "" : values;
      onChange(fieldName, eValue, op);
    },
    [fieldName, onChange]
  );

  const values = isArray(value) ? value : value ? [value] : [];

  const selectedOptions = values.map(x => ({
    label: x,
    value: x
  }));

  const components = useMemo<IncSelectProps<IncSelectOption, true>["components"]>(
    () => ({
      ClearIndicator: props => (
        <IncMultiSelectClearIndicator
          {...props}
          clearTextJsx={<div className="status-info inc-text-element-medium display-inline-flex marginRt6">Clear</div>}
        />
      ),
      DropdownIndicator: props => <IncMultiSelectDropdownIndicator {...props} />
    }),
    []
  );

  return (
    <EntityPropertySelect
      applySelectionOnBlur
      cohortId={cohortId}
      components={components}
      displayLabel={""}
      entityTypeId={entityTypeId}
      onSelectionChange={onSelectionChange}
      propName={fieldName}
      selection={selectedOptions}
    />
  );
};

const StringFieldsWrapper: React.FC<WrapperComponentProps> = props => {
  const { fieldName, value: eValue, aggregationValues, onChange } = props;

  const selectedOptions = useMemo(() => {
    const predicateValues = isArray(eValue) ? eValue : eValue ? [eValue] : [];
    return predicateValues.map(x => ({
      id: x,
      label: x
    }));
  }, [eValue]);

  const onValueChange = useCallback(
    (values: string[]) => {
      const operator = values.length > 1 ? "in" : "eq";
      const value = operator === "eq" ? values[0] : values;
      onChange(fieldName, value, operator);
    },
    [fieldName, onChange]
  );

  const displayComponent = useMemo(() => {
    const onSelection = (opts: IncSelectOption[]) => onValueChange(opts.map(x => x.value));

    const options = aggregationValues.map(x => ({
      label: x.key,
      value: x.key
    }));

    const selected = selectedOptions.map(x => ({
      label: x.label,
      value: x.id
    }));

    const allOptions = [...selected, ...options];
    const uniqAllOptions = uniqWith(allOptions, (a, b) => a.value === b.value);

    const components: IncSelectProps<IncSelectOption, true>["components"] = {
      ClearIndicator: props => (
        <IncMultiSelectClearIndicator
          {...props}
          clearTextJsx={<div className="status-info inc-text-element-medium display-inline-flex marginRt6">Clear</div>}
        />
      ),
      DropdownIndicator: props => <IncMultiSelectDropdownIndicator {...props} />
    };

    return (
      <IncMultiSelectCheckBox
        components={components}
        defaultOptions={selected}
        hideOptionsCount
        onChange={onSelection}
        options={uniqAllOptions}
        placeholder={"Select"}
        renderInCard
        showAsPills
        showSelectedOptions
        skipAllOption
      />
    );
  }, [aggregationValues, onValueChange, selectedOptions]);

  return displayComponent;
};

const DateRangePickerWrapper: React.FC<WrapperComponentProps> = (props: WrapperComponentProps) => {
  const { fieldName, onChange, value: eValue } = props;

  const onTimeRangeChange = useCallback(
    (from: Date | null, to: Date | null) => {
      if (from && to) {
        const valueFrom = from.toISOString();
        const valueTo = to.toISOString();
        const value = [valueFrom, valueTo];
        onChange(fieldName, value, "range");
      } else {
        onChange(fieldName, [], "range");
      }
    },
    [fieldName, onChange]
  );

  const value = isArray(eValue) ? eValue : eValue || "";

  const getRawTime = (datetime: string) =>
    timeRangeUtils.isRelativeTime(datetime) ? datetime : Date.parse(datetime).toString();

  const timeRange = value
    ? timeRangeUtils.getTimeRangeFromRaw({
        from: getRawTime(value[0]),
        to: getRawTime(value[1])
      })
    : null;

  return (
    <IncDateRangePicker
      from={timeRange?.from?.toDate() || null}
      isClearable={true}
      onChange={onTimeRangeChange}
      to={timeRange?.to?.toDate() || null}
    />
  );
};

interface NumericRangeSliderWrapperProps extends WrapperComponentProps {
  options: Options;
}

const NumericRangeSliderWrapper: React.FC<NumericRangeSliderWrapperProps> = (props: NumericRangeSliderWrapperProps) => {
  const [isOpen, setOpen] = useState(false);

  const { aggregationMeta, fieldName, kindDescriptor, onChange, op, options, value: eValue } = props;

  const anchorEl = useRef(null);

  const fieldValue = isArray(eValue) ? eValue.map(x => parseInt(x, 10)) : eValue ? parseInt(eValue, 10) : null;

  const initOp = useMemo(() => (op && op === "range" ? op : EntityOpToTraceQueryOpMap[op] || "<"), [op]);

  const [localValue, setLocalValue] = useState(fieldValue);
  const [localOp, setLocalOp] = useState(initOp);

  const closePopper = useCallback(() => setOpen(false), []);
  const openPopper = useCallback(() => setOpen(true), []);

  const stats = aggregationMeta?.stats;
  const minValue = stats?.min || 0;
  const maxValue = stats?.max || 100;

  const onValChange = useCallback((value: number | number[], operator: NumericRangeSliderOperators) => {
    setLocalValue(value);
    setLocalOp(operator);
  }, []);

  const applyChange = useCallback(() => {
    const strVal = isArray(localValue) ? localValue.map(x => x.toString()) : localValue.toString();

    const op = localOp !== "range" ? (getKeyByValue(localOp, EntityOpToTraceQueryOpMap) as EntityOperation) : localOp;

    onChange(fieldName, strVal, op);
    closePopper();
  }, [fieldName, localValue, onChange, closePopper, localOp]);

  const resetFields = useCallback(() => {
    setLocalValue(fieldValue);
    setLocalOp(initOp);
    closePopper();
  }, [fieldValue, initOp, closePopper]);

  const { type = "not_set" } = kindDescriptor || {};

  const isCurrencyRange = type === "currency";
  const currencyType = isCurrencyRange ? options.currency : null;
  const subType: NumericRangeProps["subType"] = isCurrencyRange
    ? {
        kind: "currency",
        subType: currencyType
      }
    : null;

  const valueLabel = useMemo(() => {
    const currencyType = options?.currency ? options.currency : (appConfig.defaultCurrencyType as CurrencyType);
    const currencySymbol = getSymbolForCurrency(currencyType);
    if (op === "range") {
      return `between ${currencySymbol}${getCurrencyInfo(eValue[0], currencyType, false).abbreviatedValue} and
      ${currencySymbol}${getCurrencyInfo(eValue[1], currencyType, false).abbreviatedValue}`;
    } else if (op === "lt") {
      return `< ${currencySymbol}${getCurrencyInfo(eValue as string, currencyType, false).abbreviatedValue}`;
    } else if (op === "gt") {
      return `> ${currencySymbol}${getCurrencyInfo(eValue as string, currencyType, false).abbreviatedValue}`;
    } else {
      return `${currencySymbol}${getCurrencyInfo(eValue as string, currencyType, false).abbreviatedValue}`;
    }
  }, [eValue, op, options]);

  return (
    <>
      <VerticallyCenteredRow
        className="values-container flex-gap-8"
        onClick={openPopper}
        ref={anchorEl}
      >
        <IncSmartText
          text={valueLabel}
          textClass="inc-text-color-secondary inc-text-subtext value-label"
        />
        <IncFaIcon
          className="paddingRt8 marginLtAuto caret-down-icon"
          iconName="caret-down"
        />
      </VerticallyCenteredRow>
      <IncClickAwayPopper
        anchorEl={anchorEl.current}
        onClickAway={closePopper}
        placement="bottom"
        show={isOpen}
      >
        <div className="padding24 inc-flex-column flex-gap-8">
          <NumericRangeSlider
            label={fieldName}
            max={maxValue}
            min={minValue}
            onChange={onValChange}
            operator={localOp as NumericRangeSliderOperators}
            subType={subType}
            value={localValue as any}
          />
          <VerticallyCenteredRow className="flex-gap-8">
            <IncButton
              color="primary"
              onClick={applyChange}
            >
              Apply
            </IncButton>
            <IncButton
              color="secondary-blue"
              onClick={resetFields}
            >
              Cancel
            </IncButton>
          </VerticallyCenteredRow>
        </div>
      </IncClickAwayPopper>
    </>
  );
};
