import React, { useMemo, useCallback } from "react";
import { IncFaIcon, IncFaIconName } from "@inception/ui";
import { WidgetCustomAction } from "../../../types";
import { useForceUpdate } from "../../../../../core";
import { VerticallyCenteredRow } from "../../../../../components";
import FunnelWidgetImpl from "../../models/impl";

type Props = {
  widget: FunnelWidgetImpl;
};

export const useDisableCompareAction = (props: Props) => {
  const { widget } = props;
  const { properties } = widget;

  const { disableCompare = true } = properties || {};

  const forceUpdate = useForceUpdate();

  const toggleDisableCompare = useCallback(() => {
    properties.disableCompare = !disableCompare;
    forceUpdate();
  }, [disableCompare, forceUpdate, properties]);

  const tooltipText = disableCompare ? "Enable comparison" : "Disable comparison";
  const stackIconName: IncFaIconName = disableCompare ? "slash" : null;

  const disableCompareAction = useMemo<WidgetCustomAction>(
    () => ({
      actionComponent: (
        <VerticallyCenteredRow onClick={toggleDisableCompare}>
          <IncFaIcon
            iconName="circle-half-stroke"
            stackIconName={stackIconName}
          />
          <span>{tooltipText}</span>
        </VerticallyCenteredRow>
      )
    }),
    [stackIconName, toggleDisableCompare, tooltipText]
  );

  return disableCompareAction;
};
