import { last } from "lodash";
import React, { FC, useCallback, useMemo } from "react";
import { EventGroupBySelector } from "../../../../../components";
import { useRefState, useTimeRange } from "../../../../../core";
import {
  BizDataQuery,
  BuildingBlockConfig,
  BuildingBlockDef,
  FieldPickerContextDTO,
  UserServiceFieldSliceSet
} from "../../../../../services/api/explore";
import { featureFlagService } from "../../../../../services/feature-flags";
import { convertUSFieldSliceSetToTagSlice } from "../../../../../utils/ExploreUtils";
import { OpContext } from "../../../../context/types";
import { adjustImplicitSliceSet } from "../../SuppressionsEditor/biz-data-query/utils";

interface Props {
  bizDataQuery: BizDataQuery;
  onChange: (sliceSet: UserServiceFieldSliceSet[], bizDataQuery: BizDataQuery) => void;

  pickerContext: OpContext;
}

export const BuildingBlockSliceSelector: FC<Props> = props => {
  const { bizDataQuery, onChange, pickerContext } = props;

  const { getLatestTimeRange } = useTimeRange();

  const { buildingBlockConfig } = bizDataQuery;
  const { buildingBlockDef } = buildingBlockConfig;
  const { aggregator } = buildingBlockDef;

  const { entityTypeId, eventTypeId, entityTypeName, bizEntityFieldName, implicitSlice, mode } = pickerContext;

  const isOpEdit = mode === "edit";
  const isDemoMode = featureFlagService.isDemoMode();

  const isReadOnly = isOpEdit ? false : isDemoMode;

  const bizDataQueryRef = useRefState(bizDataQuery);

  const fieldPickerContext = useMemo<FieldPickerContextDTO>(
    () => ({
      entityId: entityTypeId ? null : eventTypeId,
      entityName: null,
      entityType: entityTypeId,
      bizEntityType: entityTypeName,
      userServices: entityTypeId && eventTypeId ? [{ userServiceEntityId: eventTypeId }] : [],
      showFields: true,
      userServiceToBizEntityFieldName:
        bizEntityFieldName && eventTypeId
          ? {
              [eventTypeId]: bizEntityFieldName
            }
          : {}
    }),
    [bizEntityFieldName, entityTypeId, entityTypeName, eventTypeId]
  );

  const onSliceSetsChange = useCallback(
    (sliceSets: UserServiceFieldSliceSet[]) => {
      const bizDataQuery = bizDataQueryRef.current;
      const { buildingBlockConfig } = bizDataQuery;
      const { buildingBlockDef } = buildingBlockConfig;

      const adjustedSlicesSets = adjustImplicitSliceSet(sliceSets, implicitSlice);
      const nBuildingBlockDef: BuildingBlockDef = {
        ...buildingBlockDef
      };

      nBuildingBlockDef.sliceDef.sliceSets = adjustedSlicesSets;
      const nBuildingBlockConfig: BuildingBlockConfig = {
        ...buildingBlockConfig,
        buildingBlockDef: nBuildingBlockDef
      };

      const sliceSet = convertUSFieldSliceSetToTagSlice(last(adjustedSlicesSets));

      onChange(adjustedSlicesSets, {
        ...bizDataQuery,
        buildingBlockConfig: nBuildingBlockConfig,
        sliceSpec: {
          ...bizDataQuery.sliceSpec,
          sliceSet
        }
      });
    },
    [bizDataQueryRef, implicitSlice, onChange]
  );

  const sliceSets = useMemo(() => buildingBlockDef.sliceDef.sliceSets, [buildingBlockDef]);

  return aggregator ? (
    <EventGroupBySelector
      canRemoveImplicitSlice
      displayImplicitSlice
      fieldPickerContext={fieldPickerContext}
      getLatestTimeRange={getLatestTimeRange}
      hideLabel
      onChange={onSliceSetsChange}
      readOnly={isReadOnly}
      sliceSets={sliceSets}
    />
  ) : (
    <></>
  );
};
