import { map, sortBy } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { AdhocSummaryContext } from "../../../core/data/types/ExploreTriageTypes";
import { ActionType } from "../../BaseWidgetActions";
import BaseWidgetImpl from "../../model-impl/BaseWidgetImpl";
import DashboardImpl from "../../model-impl/DashboardImpl";
import { DashboardModelMeta } from "../../models";
import BaseWidgetModel from "../../models/BaseWidgetModel";
import { VariableSrv } from "../../variables";
import { BaseWidgetWithHeader, BaseWidgetStateElements } from "../../widgets/BaseWidget";
import { WidgetExtProps, BaseWidgetProps, WidgetCustomAction } from "../../widgets/types";
import { dashboardUtils } from "../DashboardUtils";
import VariableImpl from "../../model-impl/VariableImpl";
import { UserServiceFieldWithMeta } from "../../../services/api/explore";
import { TimeRange, useTenantConfig } from "../../../core";
import appConfig from "../../../../appConfig";
import { USFieldWidgetImpl } from "../../widgets/USField/models";
import { CatalogWidgetImpl } from "../../widgets/Catalog/models";

interface Props {
  dbImpl: DashboardImpl;
  widgetVariableSrvMap: Record<string, VariableSrv>;
  widgetVariableSrvLoadingState: Record<string, boolean>;
  eventTypeToFieldsMap: Record<string, UserServiceFieldWithMeta[]>;
  hasCustomClone?: boolean;
  hasCustomDelete?: boolean;
  presetTimeRange?: TimeRange;

  onWidgetLockToggle: (id: string) => void;
  onWidgetDelete: (id: string) => void;
  onWidgetMaximize: (maximizeProps: WidgetExtProps, addlContext: Record<string, any>) => void;
  onWidgetClone: (id: string) => void;
  onWidgetEdit: (widget: BaseWidgetImpl, onSave: (nImpl: BaseWidgetImpl) => void) => void;
  onWidgetUpdate: () => void;
  onWidgetTypeUpdate: (id: string, type: string, newModel: BaseWidgetModel) => void;
  onDashboardWidgetAction: (action: ActionType, id: string) => void;
  getCustomWidgetActions: (widget: BaseWidgetImpl) => WidgetCustomAction[];

  refreshAndUpdateDashboard: () => void;

  onAddCatalogWidget: () => void;

  onTriage: (data: AdhocSummaryContext) => void;

  onVariablesValueUpdate: (name: string, value: string | string[], widgetId: string) => void;
  onVariablesUpdate: (variables: VariableImpl[], widgetId: string) => void;

  onAddAdhocEntityFilter: (key: string, value: string, type: string) => Promise<void>;
  onUpdateCohortId: (cohortId: string, widgetId: string, isCohortLocked?: boolean) => void;
  embedded: boolean;
  onAddAdhocEventFilter: (usField: string, value: string) => void;
}

export const useDashboardWidgets = (props: Props) => {
  const [addWidgetProps, setAddWidgetProps] = useState<WidgetExtProps>(undefined);

  const {
    onDashboardWidgetAction,
    onWidgetClone,
    onWidgetDelete,
    onWidgetMaximize,
    onWidgetLockToggle,
    onWidgetEdit,
    onWidgetTypeUpdate,
    onWidgetUpdate,
    refreshAndUpdateDashboard,
    onAddCatalogWidget,
    dbImpl,
    onTriage,
    onVariablesUpdate,
    onVariablesValueUpdate,
    onUpdateCohortId,
    widgetVariableSrvMap,
    widgetVariableSrvLoadingState,
    eventTypeToFieldsMap,
    onAddAdhocEntityFilter,
    hasCustomClone = false,
    hasCustomDelete = false,
    presetTimeRange,
    getCustomWidgetActions,
    onAddAdhocEventFilter
    // embedded
  } = props;

  const { timeRange, compareTimeRange } = dbImpl;

  const appliedTimeRange = presetTimeRange || timeRange;

  const { tenantConfigState } = useTenantConfig();

  const getWidgetProps = useCallback(
    (widget: BaseWidgetImpl, locked: boolean, dbMeta: DashboardModelMeta): BaseWidgetProps => {
      const { edit, compare } = widget.meta || dbMeta;
      const { id } = widget;

      const onSave = (widget: BaseWidgetImpl) => {
        dbImpl.updateWidget(widget.id, widget.getSaveModel());
        onWidgetUpdate();
      };

      const widgetToggle = () => onWidgetLockToggle(id);
      const widgetDelete = () => onWidgetDelete(id);
      const widgetClone = () => onWidgetClone(id);
      const widgetUpdate = () => onWidgetUpdate();
      const widgetEdit = onWidgetEdit ? () => onWidgetEdit(widget, onSave) : null;
      const onVizChange = (newVizType: string, newModel: BaseWidgetModel) =>
        onWidgetTypeUpdate(widget.id, newVizType, newModel);

      // Set sync tooltip option based on dashboard's sync tooltip option
      widget.options = {
        ...(widget.options || {}),
        syncTooltip: dbMeta.syncTooltip
      };

      return {
        compare,
        compareTimeRange,
        edit: edit && dbMeta.edit,
        locked,
        onEdit: widgetEdit,
        onClone: widgetClone,
        onDelete: widgetDelete,
        onLockToggle: widgetToggle,
        onUpdate: widgetUpdate,
        onMaximize: onWidgetMaximize,
        onAction: (action: ActionType) => onDashboardWidgetAction(action, id),
        timeRange: appliedTimeRange,
        variableSrvMap: widgetVariableSrvMap,
        widget,
        onVizChange,
        dbImpl,
        onTriage,
        onVariablesUpdate,
        onVariablesValueUpdate,
        onUpdateCohortId,
        variableLoadingStateMap: widgetVariableSrvLoadingState,
        eventTypeToFieldsMap,
        onAddAdhocEntityFilter,
        onAddAdhocEventFilter,
        hasCustomClone,
        hasCustomDelete,
        customActions: getCustomWidgetActions?.(widget)
      };
    },
    [
      onWidgetEdit,
      compareTimeRange,
      onWidgetMaximize,
      appliedTimeRange,
      widgetVariableSrvMap,
      dbImpl,
      onTriage,
      onVariablesUpdate,
      onVariablesValueUpdate,
      onUpdateCohortId,
      widgetVariableSrvLoadingState,
      eventTypeToFieldsMap,
      onAddAdhocEntityFilter,
      onAddAdhocEventFilter,
      hasCustomClone,
      hasCustomDelete,
      getCustomWidgetActions,
      onWidgetUpdate,
      onWidgetLockToggle,
      onWidgetDelete,
      onWidgetClone,
      onWidgetTypeUpdate,
      onDashboardWidgetAction
    ]
  );

  const onAction = useCallback(() => {}, []);

  const addWidget = useCallback(
    (wModel: BaseWidgetModel) => {
      dbImpl.addWidget(wModel);
      refreshAndUpdateDashboard();
      setAddWidgetProps(undefined);
    },
    [dbImpl, refreshAndUpdateDashboard]
  );

  const onAddWidget = useCallback(
    (type: string) => {
      if (type === "metric-table") {
        const widget = dashboardUtils.getDefaultWidgetImplByType(type);
        addWidget(widget.getSaveModel());
      } else if (type === "catalog") {
        onAddCatalogWidget();
      } else {
        const widget = dashboardUtils.getDefaultWidgetImplByType(type);
        const baseWidgetProps = getWidgetProps(widget, true, dbImpl.meta);
        const newWidgetProps: WidgetExtProps = {
          compare: baseWidgetProps.compare,
          edit: baseWidgetProps.edit,
          timeRange: baseWidgetProps.timeRange,
          variableSrvMap: baseWidgetProps.variableSrvMap,
          compareTimeRange: baseWidgetProps.compareTimeRange,
          widget: baseWidgetProps.widget,
          ...BaseWidgetStateElements,
          onAction,
          dbImpl
        };
        setAddWidgetProps(newWidgetProps);
      }
    },
    [addWidget, dbImpl, getWidgetProps, onAction, onAddCatalogWidget]
  );

  const onAddWidgetCancel = useCallback(() => setAddWidgetProps(undefined), []);

  const widgets = useMemo(() => {
    let filteredWidgets = dbImpl.widgets;
    const enableDashboardFilterPanel = !tenantConfigState.disableNewDashboardFilterPanel;
    if (enableDashboardFilterPanel || appConfig.anomShareId) {
      filteredWidgets = filteredWidgets.filter(widget => widget.type !== "filter");
    }

    // hack to fix the filter widgets at the top when switching between view and edit
    // todo: remove this after implementing filter addition from dashboard filter panel
    filteredWidgets = sortBy(filteredWidgets, widget => (widget.type === "filter" ? "a" : "b"));

    return map(filteredWidgets, w => {
      const { id } = w;

      if ((w.type === "us-field" || w.type === "catalog") && Boolean(appConfig.anomShareId)) {
        (w as USFieldWidgetImpl | CatalogWidgetImpl).properties = {
          ...w.properties,
          showAlerts: false
        };
      }

      const layout = dbImpl.getLayoutById(id);
      const locked = layout.static;
      const bwProps = getWidgetProps(w, locked, dbImpl.meta);
      const widgetComponent = (
        <BaseWidgetWithHeader
          key={id}
          {...bwProps}
        />
      );

      return (
        <div
          data-grid={layout}
          id={`widget-${id}`}
          key={id}
        >
          {widgetComponent}
        </div>
      );
    });
  }, [dbImpl, getWidgetProps, tenantConfigState.disableNewDashboardFilterPanel]);

  return {
    widgets,
    addWidget,
    onAddWidgetCancel,
    onAddWidget,
    widgetProps: addWidgetProps
  };
};

export type WidgetVariableContext = Pick<
  Props,
  "eventTypeToFieldsMap" | "widgetVariableSrvLoadingState" | "widgetVariableSrvMap"
> & {
  variableSrvMap: Record<string, VariableSrv>;
  variableLoadingStateMap: Record<string, boolean>;
};
