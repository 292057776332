import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IncButton, IncFaIcon, IncSelect, IncSelectOption, IncSmartText } from "@inception/ui";
import { last } from "lodash";
import {
  BizDataQuery,
  BuildingBlockConfig,
  BuildingBlockDef,
  SliceSet,
  UserServiceFieldSliceSet,
  WidgetConfigUtils,
  fieldPickerApiService
} from "../../../services/api/explore";
import { USFieldWidgetUtils } from "../../../dashboard/widgets/USField/USFieldWidgetUtils";
import {
  PresetQueryOption,
  getPresetQueryOptions,
  getSelectedPresetOption
} from "../../../operationalise-v2/v3/editors/BizDataQueryEditor/utils";
import { getFieldOrMetricNameOptions, trendTypeOptions } from "../../../operationalise-v2/constants";
import { convertUSFieldSliceSetToTagSlice } from "../../../utils/ExploreUtils";
import { OpFragmentEditProps } from "../../types";
import { VerticallyCenteredRow } from "../../../components";
import { logger, useToggleState } from "../../../core";
import { OpBizDataQuery, OpCreationFragmentEditType, TrendType } from "../../../services/api/operationalise";
import { getTimeRange } from "../../../core/hooks/time-range/TimeRangeGetter";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { BizDataQuerySliceSetEditor } from "./slice-selector/BizDataQuerySliceSetEditor";

type Props = OpFragmentEditProps;

export const KPIWithComparatorAndTrendFragmentEditor: FC<Props> = props => {
  const { opFragment, invalidFragmentJsx } = props;

  const bizDataQuery = opFragment?.kpiWithComparatorAndTrend?.bizDataQuery?.bizDataQuery;
  const isValid = bizDataQuery?.buildingBlockConfig || bizDataQuery?.widgetConfig || bizDataQuery?.id;
  if (isValid) {
    return <KPIWithComparatorAndTrendFragmentEditorInternal {...props} />;
  }

  return invalidFragmentJsx;
};

const KPIWithComparatorAndTrendFragmentEditorInternal: FC<Props> = props => {
  const { className, opFragment, onChange, onClose, demoDataParams } = props;

  const { isOpen: isUpdateInProgress, open: startUpdate, close: finishUpdate } = useToggleState();

  const {
    isOpen: isEventTypeInfoLoading,
    open: startEventTypeInfoLoading,
    close: finishEventTypeInfoLoading
  } = useToggleState(true);

  const errorMessageRef = useRef("");

  const { kpiWithComparatorAndTrend } = opFragment;
  const {
    bizDataQuery: defBizDataQuery,
    comparator: defComparator,
    trendType: defTrendType
  } = kpiWithComparatorAndTrend || {};

  const [bizDataQuery, setBizDataQuery] = useState(defBizDataQuery?.bizDataQuery);
  const [comparator, setComparator] = useState(defComparator);
  const [trendType, setTrendType] = useState(defTrendType);

  const resetKpiWithComparator = useCallback(() => {
    setBizDataQuery(defBizDataQuery?.bizDataQuery);
    setComparator(defComparator);
  }, [defBizDataQuery, defComparator, setBizDataQuery, setComparator]);

  useEffect(() => {
    resetKpiWithComparator();
  }, [resetKpiWithComparator]);

  const { buildingBlockConfig, id: widgetId, widgetConfig } = bizDataQuery || {};
  const { buildingBlockDef } = buildingBlockConfig || {};
  const { fieldConfig, aggregator, sliceDef } = buildingBlockDef || {};

  const defLastSelSlices = sliceDef?.sliceSets;
  const lastSelectedSlicesRef = useRef<UserServiceFieldSliceSet[]>(defLastSelSlices || []);

  const fieldName = fieldConfig?.userServiceField?.fieldName;
  const showAggAndMetric =
    comparator &&
    fieldConfig &&
    fieldConfig.userServiceField &&
    !USFieldWidgetUtils.isEventIDField(fieldName) &&
    !USFieldWidgetUtils.isHasErrorField(fieldName);

  const showSlices = widgetId || widgetConfig ? true : Boolean(aggregator);

  const { eventTypeId } = useMemo(
    () => WidgetConfigUtils.getEntityTypeAndEventTypeFromBizDataQuery(bizDataQuery),
    [bizDataQuery]
  );

  const [eventTypeName, setEventTypeName] = useState(eventTypeId || "");
  const fetchEventTypeInfo = useCallback(async () => {
    startEventTypeInfoLoading();

    const timeRange = getTimeRange();
    const { fromMillis, toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);

    if (eventTypeId) {
      const { data, error, message } = await fieldPickerApiService.getUserserviceInfo(
        eventTypeId,
        fromMillis,
        toMillis
      );

      if (error) {
        logger.error("KPIWithComparator", "Error fetching event type info", message);
      } else {
        setEventTypeName(data?.name || eventTypeId);
      }

      finishEventTypeInfoLoading();
    } else {
      finishEventTypeInfoLoading();
    }
  }, [eventTypeId, finishEventTypeInfoLoading, startEventTypeInfoLoading]);

  useEffect(() => {
    fetchEventTypeInfo();
  }, [fetchEventTypeInfo]);

  const presetQueryOptions = useMemo(
    () => getPresetQueryOptions(bizDataQuery, eventTypeName),
    [bizDataQuery, eventTypeName]
  );
  const selectedPresetQueryOption = useMemo(
    () => getSelectedPresetOption(bizDataQuery, eventTypeName, comparator, presetQueryOptions),
    [bizDataQuery, comparator, eventTypeName, presetQueryOptions]
  );
  const fieldOrMetricOptions = useMemo(
    () => (fieldConfig ? getFieldOrMetricNameOptions(fieldConfig) : []),
    [fieldConfig]
  );
  const selectedFieldOrMetricOpt = useMemo(
    () => fieldOrMetricOptions.find(({ value }) => value === (aggregator || "")),
    [aggregator, fieldOrMetricOptions]
  );

  const selectedTrendTypeOpt = useMemo(() => trendTypeOptions.find(({ value }) => value === trendType), [trendType]);

  const onAggChange = useCallback((aggOpt: IncSelectOption) => {
    setBizDataQuery(prevBizDataQuery => {
      const { value: aggregator, label } = aggOpt;

      const { buildingBlockConfig } = prevBizDataQuery;
      const bBlockDef = buildingBlockConfig?.buildingBlockDef || ({} as BuildingBlockDef);
      const nBuildingBlockDef: BuildingBlockDef = {
        ...bBlockDef,
        aggregator,
        name: label
      };

      const nBuildingBlockConfig: BuildingBlockConfig = {
        ...buildingBlockConfig,
        buildingBlockDef: nBuildingBlockDef
      };

      let sliceSet: SliceSet;

      // Reset rolling function and frequency if no aggregator exists
      if (!aggregator) {
        nBuildingBlockConfig.aggregator = null;
        nBuildingBlockDef.sliceDef = {
          bizFields: [],
          sliceSets: []
        };
      } else {
        if (!nBuildingBlockDef.sliceDef.sliceSets?.length) {
          nBuildingBlockDef.sliceDef.sliceSets = [...lastSelectedSlicesRef.current];
        }
        sliceSet = convertUSFieldSliceSetToTagSlice(last(nBuildingBlockDef.sliceDef.sliceSets));
      }

      return {
        ...prevBizDataQuery,
        buildingBlockConfig: nBuildingBlockConfig,
        sliceSpec: {
          ...prevBizDataQuery.sliceSpec,
          sliceSet
        }
      };
    });
  }, []);

  const onTrendTypeChange = useCallback((opt: IncSelectOption) => {
    setTrendType(opt.value as TrendType);
  }, []);

  const onSliceSetsChange = useCallback((sliceSets: UserServiceFieldSliceSet[], bizDataQuery: BizDataQuery) => {
    lastSelectedSlicesRef.current = sliceSets;
    setBizDataQuery(bizDataQuery);
  }, []);

  const onPresetQueryOptChange = useCallback(
    (opt: PresetQueryOption) => {
      const { data, label } = opt;
      setComparator(data.comparator);

      if (!data.comparator) {
        onAggChange({
          label,
          value: null
        });
      } else if (data.aggregator) {
        onAggChange({
          label,
          value: data.aggregator
        });
      }
    },
    [onAggChange]
  );

  const onCancelChanges = useCallback(() => {
    resetKpiWithComparator();
    onClose?.();
  }, [onClose, resetKpiWithComparator]);

  const onSaveChanges = useCallback(async () => {
    errorMessageRef.current = "";
    startUpdate();

    const { isError, message } = await onChange(
      {
        ...opFragment,
        kpiWithComparatorAndTrend: {
          bizDataQuery: {
            ...(defBizDataQuery || {}),
            bizDataQuery
          } as OpBizDataQuery,
          comparator,
          trendType
        }
      },
      OpCreationFragmentEditType.KPI_WITH_COMPARATOR_AND_TREND
    );

    if (isError) {
      errorMessageRef.current = message;
    }

    finishUpdate();
  }, [bizDataQuery, comparator, defBizDataQuery, finishUpdate, onChange, opFragment, startUpdate, trendType]);

  return (
    <div className={className}>
      <VerticallyCenteredRow className="flex-gap-6">
        <IncSelect
          autoAdjustWidth
          autoSort={false}
          isLoading={isEventTypeInfoLoading}
          isSearchable={false}
          onChange={onPresetQueryOptChange}
          options={presetQueryOptions}
          value={selectedPresetQueryOption}
          wrapperClass="op-condition-editor--opt-select"
        />

        {showAggAndMetric && (
          <>
            <VerticallyCenteredRow className="marginLt8 marginRt8 inc-label-common">in</VerticallyCenteredRow>

            <IncSelect
              autoAdjustWidth
              autoSort={false}
              isLoading={isEventTypeInfoLoading}
              isSearchable={false}
              onChange={onAggChange}
              options={fieldOrMetricOptions}
              value={selectedFieldOrMetricOpt}
              wrapperClass="field-or-metric-selector"
            />
          </>
        )}

        {showSlices && (
          <>
            <VerticallyCenteredRow className="marginLt8 marginRt8 inc-label-common">for each</VerticallyCenteredRow>

            <BizDataQuerySliceSetEditor
              bizDataQuery={bizDataQuery}
              demoDataParams={demoDataParams}
              isLoading={isEventTypeInfoLoading}
              onChange={onSliceSetsChange}
            />
          </>
        )}

        <VerticallyCenteredRow className="marginLt8 marginRt8 inc-label-common">on a</VerticallyCenteredRow>

        <IncSelect
          autoAdjustWidth
          autoSort={false}
          isLoading={isEventTypeInfoLoading}
          isSearchable={false}
          onChange={onTrendTypeChange}
          options={trendTypeOptions}
          value={selectedTrendTypeOpt}
        />

        <VerticallyCenteredRow className="marginLt8 marginRt8 inc-label-common">basis</VerticallyCenteredRow>
      </VerticallyCenteredRow>

      <VerticallyCenteredRow className="flex-gap-12 marginTp10">
        {!isUpdateInProgress && (
          <IncButton
            color="primary"
            label="Save Changes"
            onClick={onSaveChanges}
            size="small"
          />
        )}

        {isUpdateInProgress && (
          <IncButton
            color="primary"
            loading
            loadingText="Saving..."
            size="small"
          />
        )}

        {Boolean(errorMessageRef.current) && (
          <VerticallyCenteredRow className="status-danger flex-gap-10">
            <IncFaIcon
              className="status-danger"
              iconName="exclamation-triangle"
            />
            <IncSmartText
              className="status-danger"
              text={errorMessageRef.current}
            />
          </VerticallyCenteredRow>
        )}

        {!isUpdateInProgress && (
          <IncButton
            color="secondary-blue"
            label="Cancel"
            onClick={onCancelChanges}
            size="small"
          />
        )}
      </VerticallyCenteredRow>
    </div>
  );
};
