import React, { useMemo } from "react";
import { css } from "emotion";
import { CurrencyType, getInceptionTheme } from "@inception/ui";
import { DataType } from "../../core";
import { dataTypeManager } from "../../utils";

interface Props {
  data: number;
  dataType?: DataType;
  formatter?: (data: number, type: DataType) => string;
  textColor?: string;
  backgroundColor?: string;
  currencyType?: CurrencyType;
  prettyData?: boolean;
  fontSize?: number;
}

export const SingleStatVisualization: React.FC<Props> = (props: Props) => {
  const {
    data,
    backgroundColor,
    formatter,
    textColor,
    dataType = "NUMBER",
    currencyType = "USD",
    fontSize = 40,
    prettyData = false
  } = props;

  const displayString = useMemo(
    () => (formatter ? formatter(data, dataType) : singleStatCustomFormatter(data, dataType, prettyData, currencyType)),
    [currencyType, data, dataType, formatter, prettyData]
  );

  const containerClass = css`
    background-color: ${backgroundColor ? backgroundColor : "transparent"};
  `;

  const textClass = css`
    color: ${textColor ? textColor : getInceptionTheme().inceptionColors.palette.BL25};
    font-size: ${fontSize}px;
    line-height: ${fontSize + 2}px;
    font-weight: 600;
  `;

  return (
    <div className={`single-stat-widget inc-flex-center ${containerClass}`}>
      <span className={`${textClass}`}>{displayString}</span>
    </div>
  );
};

function singleStatCustomFormatter(
  value: number,
  dataType: DataType,
  compact: boolean,
  currencyType: CurrencyType
): string {
  const { getFormattedValue } = dataTypeManager.getDataTypeDescriptor(dataType) || {};

  if (getFormattedValue) {
    getFormattedValue(value, {
      numberFormatOptions: {
        compact,
        currencyType
      }
    });
  }

  return parseFloat(String(value || ""))?.toFixed(2) || "";
}
