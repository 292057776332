export const checkIfTemplate = (subType: string) => {
  const isSubTypeTemplate =
    subType === "template-canvas" || subType === "twig-template" || subType === "template-section";
  return isSubTypeTemplate;
};

export const checkIfTwigTemplate = (subType: string) => subType === "twig-template";

export const checkIfTemplateSection = (subType: string) => {
  const isSubTypeTemplateSection = subType === "template-section";
  return isSubTypeTemplateSection;
};
