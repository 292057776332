import { union, uniq } from "lodash";
import { Edge, MarkerType, Position } from "react-flow-renderer";
import { Layout } from "./Config";
import { NodeDataProps, WorkflowNodeProps, WorkflowNodeType } from "./types";

export const getInitialNodesAndEdges = (
  nodes: NodeDataProps[],
  connections: NodeDataProps[],
  noColors = false,
  colorsMap: Record<string, string> = {}
) => {
  const initialEdges: Edge[] = [];
  const entityPositions = new Map<string, WorkflowNodeProps>();
  const entityOrder = new Map<string, number>();

  const entityNodes = getInitialEntityNodes(nodes, entityPositions, entityOrder);
  const eventNodes = getInitialEventNodes(connections, initialEdges, entityPositions, entityOrder, noColors, colorsMap);

  entityNodes.forEach(node => (node.data.numActivities = connections.length));

  const initialNodes = uniq(union(entityNodes, eventNodes));

  return {
    initialNodes,
    initialEdges
  };
};

/**
 * initialEntityNodes
 * @returns
 */
const getInitialEntityNodes = (
  entityNodes: NodeDataProps[],
  entityPositions: Map<string, WorkflowNodeProps>,
  entityOrder: Map<string, number>
): WorkflowNodeProps[] => {
  const etns: WorkflowNodeProps[] = [];
  let count = 0;

  entityNodes &&
    entityNodes.forEach((entity: NodeDataProps) => {
      const counter = count++;
      const etn: WorkflowNodeProps = getNodeObject(
        entity.id,
        entity.label,
        "entity",
        Layout.originX + counter * (Layout.entity.width + Layout.entity.spacing),
        Layout.originY,
        entity,
        0
      );
      entityPositions.set(entity.id, etn);
      entityOrder.set(entity.id, counter);
      etns.push(etn);
    });

  return etns;
};

/**
 * Event Nodes
 * @returns
 */
const getInitialEventNodes = (
  eventNodes: NodeDataProps[],
  initialEdges: Edge[],
  entityPositions: Map<string, WorkflowNodeProps>,
  entityOrder: Map<string, number>,
  noColor: boolean,
  colorsMap: Record<string, string>
): WorkflowNodeProps[] => {
  const evtns: WorkflowNodeProps[] = [];
  let prevProcessPosition = {
    x: 0,
    y: Layout.group.marginTop
  };

  const events = getGroupedNodes(eventNodes, entityOrder);
  let prevProcessId: string;
  events &&
    events.forEach(process => {
      let prevNode: any;
      let isPrevNodeFwdDirection = false;
      const prevNodePosition = {
        x: 0,
        y: 0
      };

      if (process.id !== prevProcessId) {
        prevNodePosition.y = prevProcessPosition.y + Layout.group.spacing;
      }

      let idx = initialEdges?.length || 0;
      const activities = process.activities as NodeDataProps[];
      activities.forEach(node => {
        const activity = node ? (node as NodeDataProps) : null;
        const { id } = node;
        const sourceNode = activity && activity.sourceNodeId ? entityPositions?.get(activity.sourceNodeId) : null;
        const targetNode = activity && activity.targetNodeId ? entityPositions?.get(activity?.targetNodeId) : null;
        const sourcePos = sourceNode?.position;
        const targetPos = targetNode?.position;

        const color = noColor ? "#8D8F92" : colorsMap?.[id] || getRandomColor();

        //set the x coordinates
        const xSpace = (targetPos ? targetPos.x : 0) - (sourcePos ? sourcePos.x : 0);
        const isFwdDirection: boolean = xSpace > 0 ? true : false;

        const refNodePos = isFwdDirection ? sourcePos : targetPos;
        const x =
          (refNodePos ? refNodePos.x : 0) + (Layout.entity.width + Math.abs(xSpace) - Layout.activity.width) / 2;
        if (sourceNode && targetNode) {
          const edgeId = `${node.sourceNodeId}_${node.targetNodeId}_${id}_${idx++}`;
          initialEdges.push({
            id: `${edgeId}_f`,
            source: sourceNode.id,
            target: id,
            style: {
              stroke: color
            }
          });

          initialEdges.push({
            id: `${edgeId}_b`,
            source: id,
            target: targetNode.id,
            style: {
              stroke: color
            },
            markerEnd: {
              type: MarkerType.ArrowClosed,
              color: color
            }
          });
        }

        let { y } = prevNodePosition;
        //in the reverse order
        if (isFwdDirection !== isPrevNodeFwdDirection && prevNode?.targetNodeId === activity?.sourceNodeId) {
          y = y + Layout.activity.spacing;
        }

        if (activity) {
          activity.style = {
            borderColor: color
          };
          activity.sourcePosition = Position.Left;
          activity.targetPosition = Position.Right;
        }

        prevNodePosition.x = x;
        prevNodePosition.y = y;
        prevProcessPosition = prevNodePosition;
        isPrevNodeFwdDirection = isFwdDirection;

        const evt: WorkflowNodeProps = getNodeObject(id, node.label, "activity", x, y, activity, 0);
        evtns.push(evt);
        prevNode = activity;
      });
      prevProcessId = process.id;
    });
  return evtns;
};

/**
 * Get the node object
 * @param id
 * @param type
 * @param xPos
 * @param yPos
 * @param data
 * @returns
 */
const getNodeObject = (
  id: string,
  label: string,
  type: WorkflowNodeType,
  xPos: number,
  yPos: number,
  data: any,
  numActivities: number
) => {
  const obj: WorkflowNodeProps = {
    data: {
      ...data,
      numActivities
    },
    draggable: false,
    id: id,
    label: label,
    position: {
      x: xPos,
      y: yPos
    },
    sourcePosition: Position.Left,
    type: type,
    targetPosition: Position.Right
  };
  return obj;
};

/**
 * REturn the grouped events
 * @param eventNodes
 * @returns
 */
const getGroupedNodes = (eventNodes: NodeDataProps[], entityOrder: Map<string, number>) => {
  const events: NodeGroup[] = [];
  let group: NodeGroup = {} as NodeGroup;
  let lastNode: NodeDataProps | undefined;

  for (let i = 0; i < eventNodes.length; i++) {
    const nEvent = eventNodes[i];
    const eventSourceId = nEvent.sourceNodeId;

    if (!lastNode || lastNode.targetNodeId !== eventSourceId || entityOrder?.get(eventSourceId ?? "") === 0) {
      group = {
        id: `grp_${nEvent.id}`,
        activities: []
      };

      events.push(group);
    }

    group.activities.push(nEvent);

    lastNode = nEvent;
  }

  return events;
};

type NodeGroup = {
  id: string;
  activities: NodeDataProps[];
};

const getRandomColor = () => `#${(0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6)}`;
