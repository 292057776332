import { IncButton, IncModal, IncModalProps } from "@inception/ui";
import { css, cx } from "emotion";
import React, { FC, useState, useMemo, useCallback, useEffect } from "react";
import { VerticallyCenteredRow } from "../flex-components";
import LoadingSpinner from "../Loading/Loading";
import { AddToUseCaseContent, SimpleUseCase } from "./AddToUseCaseContent";

interface Props {
  title: string;
  actionName?: string;

  useCases: SimpleUseCase[];
  memberUseCases?: SimpleUseCase[];
  preselectedUseCaseIds?: string[];
  onAdd: (useCaseIds: string[]) => void;
  onClose: () => void;

  isMulti?: boolean;
  allowGrouping?: boolean;
  isOpInProgress?: boolean;
}

export const AddToUseCaseModal: FC<Props> = props => {
  const {
    title,
    actionName = "Add",
    allowGrouping = true,
    isOpInProgress = false,
    onAdd,
    onClose,
    useCases,
    memberUseCases,
    preselectedUseCaseIds,
    isMulti
  } = props;

  const [selectedUseCases, setSelectedUseCases] = useState<string[]>(preselectedUseCaseIds || []);

  useEffect(() => {
    if (preselectedUseCaseIds) {
      setSelectedUseCases(preselectedUseCaseIds);
    }
  }, [preselectedUseCaseIds]);

  const onAddClick = useCallback(() => onAdd(selectedUseCases), [onAdd, selectedUseCases]);

  const className = useMemo(
    () =>
      cx(
        "add-to-use-case-modal",
        css`
          max-height: 85vh;
        `
      ),
    []
  );
  const actions = useMemo<IncModalProps["actions"]>(
    () => ({
      preJSX: (
        <VerticallyCenteredRow className="flex-gap-12 inc-label-common">
          <IncButton
            color="primary"
            disabled={isOpInProgress}
            onClick={onAddClick}
          >
            {!isOpInProgress && actionName}
            {isOpInProgress && <LoadingSpinner titleText="In progress..." />}
          </IncButton>

          {!isOpInProgress && (
            <IncButton
              color="secondary-blue"
              onClick={onClose}
            >
              Cancel
            </IncButton>
          )}

          {selectedUseCases.length ? `${selectedUseCases.length} Copilots selected` : ""}
        </VerticallyCenteredRow>
      )
    }),
    [actionName, isOpInProgress, onAddClick, onClose, selectedUseCases.length]
  );

  return (
    <IncModal
      actions={actions}
      className={className}
      onClose={onClose}
      show
      showClose={!isOpInProgress}
      size="lg"
      titleText={title}
    >
      <AddToUseCaseContent
        allowGrouping={allowGrouping}
        isMulti={isMulti}
        memberUseCases={memberUseCases}
        selectedUseCases={selectedUseCases}
        setSelectedUseCases={setSelectedUseCases}
        useCases={useCases}
      />
    </IncModal>
  );
};
