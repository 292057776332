import { FilterRendererProps, IncButton, IncSelectOption, IncSelect } from "@inception/ui";
import React, { FC, memo, useCallback, useMemo } from "react";

type FProps = FilterRendererProps<string[]> & {
  label: string;
  options: IncSelectOption[];
};

export const TableSelectFilterRenderer: FC<FProps> = memo(props => {
  const { filter, setFilter, label, options } = props;

  const updateFilter = useCallback(
    (opts: readonly IncSelectOption[]) => {
      const selValues = opts.map(({ value }) => value);
      setFilter(selValues);
    },
    [setFilter]
  );

  const resetFilter = useCallback(
    (evt: React.MouseEvent) => {
      evt.stopPropagation();
      setFilter(null);
    },
    [setFilter]
  );

  const selOpts = useMemo<IncSelectOption[]>(
    () =>
      (filter || []).map(value => {
        const eOpt = options.find(opt => opt.value === value);

        return (
          eOpt || {
            label: value,
            value
          }
        );
      }),
    [filter, options]
  );

  return (
    <>
      <IncButton
        className="marginLtAuto marginBt16"
        color="link"
        onClick={resetFilter}
        size="small"
      >
        Clear all
      </IncButton>

      <IncSelect
        isMulti
        label={label}
        menuIsOpen
        onChange={updateFilter}
        options={options}
        value={selOpts}
      />
    </>
  );
});
