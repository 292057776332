import { ContentBlock, EditorState } from "draft-js";

export type CompareEditorState = {
  blocksArr: ContentBlock[];
  entityMap: Record<string, any>;
};

export const getCompareEditorState = (editorState: EditorState): CompareEditorState => {
  const contentState = editorState.getCurrentContent();

  const blocksArr = contentState.getBlocksAsArray();
  const entityMap = contentState.getAllEntities().toObject();

  return {
    blocksArr,
    entityMap
  };
};
