import Highcharts from "highcharts";
import IncTabBar, { Tab } from "./Tabs/TabBar";
import LoadingSpinner from "./Loading/Loading";
import ScreenContainerRow from "./layouts/ScreenContainer";
import { EntitytypeSelector, CohortSelector } from "./selectors";
import EntitySelector from "./entity-selector/EntitySelector";
import ErrorText from "./ErrorText";
import PopOverInfo from "./PopOverInfo";
import GroupedSelect from "./GroupedSelect";
import LinkButton from "./LinkButton";
import TriageConfigModal from "./TriageConfigModal/TriageConfigModal";
import CounterSelect, { Counter } from "./counter-select/CounterSelect";

// TODO: find a better place for this
Highcharts.setOptions({
  time: {
    useUTC: false
  },
  plotOptions: {
    series: {
      animation: false
    }
  },
  chart: {
    style: {
      fontFamily: `'Outfit', sans- serif;`
    }
  }
});
Highcharts.AST.bypassHTMLFiltering = true;

export type IncTab = Tab;
export {
  IncTabBar,
  LoadingSpinner,
  ScreenContainerRow,
  LinkButton,
  EntitytypeSelector,
  CohortSelector,
  EntitySelector,
  ErrorText,
  GroupedSelect,
  PopOverInfo,
  TriageConfigModal,
  CounterSelect,
  Counter
};

export * from "./cohort";
export * from "./pills";
export * from "./entity-list";
export * from "./writable-entity-field/WritableFieldDestinations";
export * from "./pie-chart";
export * from "./geo-map";
export * from "./checkbox-list";
export * from "./field-picker";
export * from "./explore";
export * from "./event-field-query-editor";
export * from "./expression-editor";
export * from "./flex-components";
export * from "./AuditInfoRenderer";
export * from "./NumericRangeSlider";
export * from "./filters";
export * from "./SortAndLimitSelector";
export * from "./series-select";
export * from "./tree-map";
export * from "./operationalize-modal";
export * from "./event-filters";
export * from "./bar-group";
export * from "./fields-side-panel";
export * from "./table-filter-renderers";
export * from "./share-url";
export * from "./heatmap";
export * from "./TimeZoneSelector";
export * from "./sparkline";
export * from "./add-to-use-case";
export * from "./CauseSummaryCard";
export * from "./Op10zeQueryWidgetWrapper";
export * from "./incident-card-v2";
export * from "./insight-card";
export * from "./triage-summary";
export * from "./MappingIncompleteOverlay";
export * from "./connect-data";
export * from "./sparkline";
export * from "./sequence-visualization";
export * from "./events-table/EventsTable";
export * from "./explore/ExploreTabButtons";
export * from "./entity-properties-popover";
export * from "./subscribe-dialog";
export * from "./kpi";

export * from "./CallStackRenderer";
export * from "./TimeZoneSelector";
export * from "./CurrencyPicker";
export * from "./UploadIconPopover";
export * from "./AuditInfoRenderer";
export * from "./NumericRangeSlider";
export * from "./SortAndLimitSelector";
export * from "./SimplePill";
export * from "./EntityPropertySelect";
export * from "./EventNavigateRenderer";
export * from "./AggregationSelect";
export * from "./RenderWithMorePopper";
export * from "./CopyToClipboard";
export * from "./CustomSelect";
export * from "./GenericHighchartsRenderer";
export * from "./OperationalizeCard";
export * from "./json-config-editor-modal";
export * from "./SingleStatWithCompare";
export * from "./IconInfoRenderer";
export * from "./markdown";
export * from "./fragments";
export * from "./start-end-time-selector-modal/StartEndTimeSelectorModal";
