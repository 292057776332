import { IncFaIcon, IncSmartText, IncTextfield } from "@inception/ui";
import { isEmpty, debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { VerticallyCenteredRow } from "../flex-components";

interface EditableLabelInputProps {
  initialValue: string;
  className?: string;
  textFieldClassName?: string;
  textFieldContainerClassName?: string;
  change: (value: string) => void;
  save?: (value: string) => void;
  editingDisabled?: boolean;
  placeholder?: string;
  restrictedValues?: string[];
  showEditIcon?: boolean;
  autoAdjustWidth?: boolean;
}

const EditableLabelInput: React.FC<EditableLabelInputProps> = (props: EditableLabelInputProps) => {
  const {
    change,
    save = () => {},
    editingDisabled,
    initialValue,
    className = "",
    textFieldClassName = "",
    textFieldContainerClassName = "",
    placeholder = "Title",
    restrictedValues,
    showEditIcon = false,
    autoAdjustWidth = false
  } = props;

  const [showInput, setShowInput] = useState<boolean>(false);
  const [originalValue, setOriginalValue] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const errorRef = useRef<string>("");

  useEffect(() => {
    setOriginalValue(initialValue);
    setValue(initialValue);
  }, [initialValue]);

  const saveValueDebounced = useMemo(() => debounce((value: string) => save(value), 500), [save]);

  const validateValue = useCallback(
    (value: string) => {
      const isInvalid = restrictedValues?.includes(value);
      errorRef.current = isInvalid ? `${value} is not allowed` : "";
      return !isInvalid;
    },
    [restrictedValues]
  );

  const setStateAndSave = useCallback(
    (newValue: string): Promise<void> => {
      setValue(newValue);
      const isValid = validateValue(newValue);
      isValid && saveValueDebounced(newValue);
      return Promise.resolve();
    },
    [saveValueDebounced, validateValue]
  );

  const setStateAndChange = useCallback(
    (newValue: string): Promise<void> => {
      setValue(newValue);
      const isValid = validateValue(newValue);
      isValid && change(newValue);
      return Promise.resolve();
    },
    [change, validateValue]
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setStateAndChange(e.target.value);
    },
    [setStateAndChange]
  );

  const switchToInput = useCallback(() => {
    setShowInput(true);
  }, []);

  const switchToLabel = useCallback(() => {
    if (!errorRef.current) {
      setShowInput(false);
    }
  }, []);

  const onBlur = (newValue: string): void => {
    setStateAndSave(newValue);
    switchToLabel();
  };

  // The type should be something like KeyboardEvent<HTMLFormElement> but it doesn't work
  // since it can't find value in e.target.value
  const onKeyUp = useCallback(
    (e: any) => {
      if (e.key === "Escape") {
        setStateAndSave(originalValue);
        switchToLabel();
      } else if (e.key === "Enter") {
        const nValue = e.target.value;
        setStateAndSave(nValue);
        switchToLabel();
      }
    },
    [originalValue, setStateAndSave, switchToLabel]
  );

  const onClick = useCallback(
    (e: React.MouseEvent<any>) => {
      if (!editingDisabled) {
        switchToInput();
      }
      e.stopPropagation();
    },
    [editingDisabled, switchToInput]
  );

  const onTextClick = useCallback(
    (e: React.MouseEvent<any>) => {
      if (!showEditIcon) {
        onClick(e);
      }
    },
    [onClick, showEditIcon]
  );

  const error = errorRef.current;
  const hasError = !isEmpty(error);

  const label = value || `Enter ${placeholder}`;

  return (
    <VerticallyCenteredRow className="editable-label-input">
      {showInput && (
        <IncTextfield
          autoAdjustWidth={autoAdjustWidth}
          autoFocus
          className={textFieldClassName}
          containerClassName={textFieldContainerClassName}
          errorText={error}
          hasError={hasError}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => onBlur(event.target.value)}
          onChange={onChange}
          onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          value={value}
        />
      )}

      {!showInput && (
        <IncSmartText
          onClick={onTextClick}
          text={label}
          textClass={className}
        />
      )}

      {showEditIcon && (
        <IncFaIcon
          className="inc-cursor-pointer marginLt12 status-info"
          iconName="edit"
          onClick={onClick}
        />
      )}
    </VerticallyCenteredRow>
  );
};

export default EditableLabelInput;
