import { IncFaIcon, IncRadioButton, IncToolTip, generateId } from "@inception/ui";
import { capitalize } from "lodash";
import React, { FC } from "react";
import { WidgetRadioProperty } from "../types";

interface Props {
  property: WidgetRadioProperty;
  onUpdate: (newState: WidgetRadioProperty) => void;
}

const RadioPropertyRenderer: FC<Props> = props => {
  const { onUpdate: usrUpdate, property } = props;

  const { name, label = capitalize(name), info = "", disabled = false, options, value } = property;

  const onChange = (newValue: string) => {
    const newProperty: WidgetRadioProperty = {
      ...property,
      value: newValue
    };
    usrUpdate(newProperty);
  };

  const radioButtons = options.map(opt => {
    const { label: optLabel, value: optVal } = opt;
    return (
      <IncRadioButton
        checked={value === optVal}
        disabled={disabled}
        key={generateId()}
        label={optLabel}
        onChange={() => onChange(optVal)}
      />
    );
  });

  return (
    <div className="radio-property">
      <div className="input=label">
        {label}
        {info && (
          <IncToolTip titleText={info}>
            <IncFaIcon iconName="info-circle" />
          </IncToolTip>
        )}
      </div>
      {radioButtons}
    </div>
  );
};

export default RadioPropertyRenderer;
