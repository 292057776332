import { Edge, Node } from "react-flow-renderer";
import { CSSProperties } from "react";
import { isEmpty } from "lodash";
import { generateId } from "@inception/ui";
import { getIntegrationTagColor } from "../../../platform/components/charts/colors";
import {
  DiscoveredFieldDef,
  EntityTypeStats,
  EventDetails,
  EventGroup,
  EventGroupList,
  FieldCategoryStats,
  MappingGroupNode,
  SourceSummary,
  DiscoveredCustomFieldDef,
  FieldTypeCategory,
  MappingType
} from "../../../platform/services/api/auto-discovery/types";
import {
  EventsAndEntityStats,
  EventTypeFieldMappingDetails,
  TitleNodeProps,
  VerifyFieldType,
  SearchNodeProps
} from "../types";
import { PreviewField } from "../../../platform/services/api/entity-mapping/types";
import { TreeNode } from "../../../platform/components/tree-view/types";
import { dateTime, FieldPrimType, FieldSubType } from "../../../platform/core";
import {
  NODE_BUFFER_HEIGHT,
  GROUP_BUFFER_HEIGHT,
  MAPPING_GROUP_X_POS,
  MAPPING_GROUP_Y_POS,
  NODE_HEIGHT,
  MAPPING_NODE_X_POS,
  EVENT_NODE_X_POS,
  DUMMY_NODE_X_POS,
  MAPPING_GROUP_NODE,
  MAPPING_SOURCE_NODE,
  MAPPING_EVENT_NODE,
  MAPPING_DOT_NODE,
  MAPPING_EDGE_STYLE,
  DUMMY_NODE_BUFFER,
  MAPPING_ACTIONS_NODE,
  SPLIT_EVENT_TYPE_NODE,
  GROUP_NODE,
  FIELD_CATEGORY_NODE,
  BUTTON_NODE,
  ENTITY_NODE,
  NODE_WIDTH,
  NESTED_GROUP_NODE,
  TITLE_NODE,
  TITLE_NODE_START_POS,
  MAPPING_EDGE_STYLE_UNVERIFIED,
  MAPPING_EDGE_HIGHLIGHT_STYLE,
  METRIC_NODE,
  OPERATIONALIZE_NODE,
  SEARCH_NODE_START_POS,
  SEARCH_NODE
} from "./constants";

class AutoDiscoveryUtils {
  getMappingTitleNode(props: TitleNodeProps, xPos: number, nodeId?: string) {
    const xPosition = xPos || TITLE_NODE_START_POS;
    return {
      id: nodeId || generateId(),
      type: TITLE_NODE,
      draggable: false,
      data: { ...props },
      position: {
        x: xPosition + MAPPING_NODE_X_POS,
        y: TITLE_NODE_START_POS
      }
    };
  }

  getMappingSearchNode(props: SearchNodeProps, xPos: number, nodeId?: string) {
    const xPosition = xPos || SEARCH_NODE_START_POS;
    return {
      id: nodeId || generateId(),
      type: SEARCH_NODE,
      draggable: false,
      data: { ...props },
      position: {
        x: xPosition + MAPPING_NODE_X_POS,
        y: SEARCH_NODE_START_POS
      }
    };
  }

  getMappingGroupNodes(eventGroup: Record<string, EventGroup>, groupStartPos?: number) {
    let nextYPos = groupStartPos || MAPPING_GROUP_Y_POS;
    let groupNodes: Array<Node<MappingGroupNode>> = [];
    let lastGroupYPosHeight = 0;

    const nonDeletedGroups = Object.values(eventGroup).filter(mapping => !mapping.isDeleted);

    if (Object.keys(eventGroup).length) {
      groupNodes = nonDeletedGroups.map(mapping => {
        const currYPos = nextYPos;
        const sourcesLength = mapping.eventSources.length;
        const eventTypesLength = mapping.eventTypes.length;
        const nodesLength = sourcesLength > eventTypesLength ? sourcesLength : eventTypesLength;
        const actionBtnBuffer = mapping.isVerified ? NODE_BUFFER_HEIGHT : NODE_BUFFER_HEIGHT + NODE_HEIGHT;
        const groupHeight = nodesLength * NODE_HEIGHT + nodesLength * NODE_BUFFER_HEIGHT + actionBtnBuffer;
        nextYPos = currYPos + groupHeight + GROUP_BUFFER_HEIGHT;
        lastGroupYPosHeight = currYPos + groupHeight;
        return {
          id: `${mapping.id}---unverifiedGroup`,
          type: MAPPING_GROUP_NODE,
          draggable: false,
          data: {
            mapping: mapping,
            height: groupHeight
          },
          position: {
            x: MAPPING_GROUP_X_POS,
            y: currYPos
          },
          style: {
            opacity: mapping.isVerified ? "" : "0.8"
          }
        };
      });
    }

    return {
      groupNodes: groupNodes,
      nextGroupStartYPos: lastGroupYPosHeight + GROUP_BUFFER_HEIGHT
    };
  }

  getNodesInGroup(
    mapping: EventGroup,
    groupXPos: number,
    groupYPos: number,
    mappingGroupId: string,
    isSplitModal?: boolean,
    isUnverified?: boolean
  ) {
    let nextSourceNodeYPos = groupYPos + NODE_BUFFER_HEIGHT;
    let nextEventNodeYPos: number = null;
    const dummyNodeId = generateId();
    const mappingData = mapping;

    const sourceNodes = mapping.eventSources.map(source => {
      const currYPos = nextSourceNodeYPos;
      nextSourceNodeYPos = currYPos + NODE_HEIGHT + NODE_BUFFER_HEIGHT;
      return {
        id: `${source.sourceId}${mapping.id}---unverified`,
        type: MAPPING_SOURCE_NODE,
        data: {
          mappings: [mappingData], //source can have multiple mappings
          source: source
        },
        parent: mappingGroupId,
        draggable: false,
        position: {
          x: groupXPos + MAPPING_NODE_X_POS,
          y: currYPos
        }
      };
    });

    nextEventNodeYPos = groupYPos + NODE_BUFFER_HEIGHT;
    const eventNodes = mapping.eventTypes.map(event => {
      const currYPos = nextEventNodeYPos;
      nextEventNodeYPos = currYPos + NODE_HEIGHT + NODE_BUFFER_HEIGHT;
      return {
        id: `${event.eventType}${mapping.id}---unverified`,
        type: !isSplitModal ? MAPPING_EVENT_NODE : SPLIT_EVENT_TYPE_NODE,
        data: {
          mapping: mappingData,
          name: event.displayName
        },
        parent: mappingGroupId,
        draggable: false,
        position: {
          x: groupXPos + EVENT_NODE_X_POS,
          y: currYPos
        }
      };
    });

    let divisor: any[] = sourceNodes;
    let higherSizeNodes = "sourceNodes";
    if (sourceNodes.length > eventNodes.length) {
      divisor = sourceNodes;
      higherSizeNodes = "sourceNodes";
    } else if (eventNodes.length > sourceNodes.length) {
      divisor = eventNodes;
      higherSizeNodes = "eventNodes";
    }

    let dummyNodeYPos = groupYPos;
    if (divisor.length % 2 === 0) {
      const middleIndex = divisor.length / 2;
      const firstVal = divisor[middleIndex].position.y;
      const secondVal = divisor[middleIndex - 1].position.y;
      dummyNodeYPos = (firstVal + secondVal) / 2;
    } else {
      const middleIndex = Math.round(divisor.length / 2);
      dummyNodeYPos = divisor[middleIndex - 1].position.y;
    }

    const dummyNode = [
      {
        id: `${dummyNodeId}---unverified`,
        type: MAPPING_DOT_NODE,
        data: {},
        parent: mappingGroupId,
        draggable: false,
        position: {
          x: groupXPos + DUMMY_NODE_X_POS,
          y: dummyNodeYPos - DUMMY_NODE_BUFFER + 23
        }
      }
    ];

    if (sourceNodes.length !== eventNodes.length) {
      const dummyYPos = dummyNode[0].position.y;

      if (higherSizeNodes === "sourceNodes") {
        //change yPOS for eventNodes
        if (eventNodes.length > 1) {
          const diffHeight = sourceNodes[sourceNodes.length - 1].position.y - sourceNodes[0].position.y;
          const diffVal = diffHeight / sourceNodes.length;

          eventNodes.forEach(node => {
            const currNodeYPos = node.position.y + diffVal + NODE_BUFFER_HEIGHT + 10;
            node.position.y = currNodeYPos;
          });
        } else {
          eventNodes[0].position.y = dummyYPos + DUMMY_NODE_BUFFER - 23;
        }
      } else {
        //change yPOS for sourceNodes
        if (sourceNodes.length > 1) {
          const diffHeight = eventNodes[eventNodes.length - 1].position.y - eventNodes[0].position.y;
          const diffVal = diffHeight / eventNodes.length;
          sourceNodes.forEach(node => {
            const currNodeYPos = node.position.y + diffVal + NODE_BUFFER_HEIGHT + 10;
            node.position.y = currNodeYPos;
          });
        } else {
          sourceNodes[0].position.y = dummyYPos + DUMMY_NODE_BUFFER - 23;
        }
      }
    }

    const sourceNodeEdges = sourceNodes.map(node => ({
      id: `${generateId()}---unverified`,
      source: node.id,
      target: `${dummyNodeId}---unverified`,
      style: isUnverified ? MAPPING_EDGE_STYLE_UNVERIFIED : MAPPING_EDGE_STYLE
    }));

    const eventTypeEdges = eventNodes.map(node => ({
      id: `${generateId()}---unverified`,
      source: `${dummyNodeId}---unverified`,
      target: node.id,
      style: isUnverified ? MAPPING_EDGE_STYLE_UNVERIFIED : MAPPING_EDGE_STYLE
    }));

    const actionNode = [];

    if (!mapping.isVerified && !isSplitModal) {
      const groupLastNode = divisor[divisor.length - 1];
      const actionNodeYPos = groupLastNode.position.y + NODE_HEIGHT + NODE_BUFFER_HEIGHT;
      const actionNodeXPos = EVENT_NODE_X_POS + MAPPING_GROUP_X_POS;
      actionNode.push({
        id: `${generateId()}---unverified`,
        type: MAPPING_ACTIONS_NODE,
        data: {
          mapping: mappingData
        },
        draggable: false,
        position: {
          x: actionNodeXPos - 10,
          y: actionNodeYPos
        }
      });
    }

    return {
      nodes: [...sourceNodes, ...dummyNode, ...eventNodes, ...actionNode],
      edges: [...sourceNodeEdges, ...eventTypeEdges]
    };
  }

  findUniqueGroups(data: Record<string, EventGroupList>) {
    const uniqueEventGroupMap: Record<string, EventGroup> = {};

    // eslint-disable-next-line unused-imports/no-unused-vars-ts, @typescript-eslint/no-unused-vars
    for (const [sourceId, eventGroupList] of Object.entries(data)) {
      eventGroupList.eventGroups.forEach(eventGroup => {
        if (!uniqueEventGroupMap[eventGroup.id]) {
          uniqueEventGroupMap[eventGroup.id] = eventGroup;
        }
      });
    }

    return Object.values(uniqueEventGroupMap);
  }

  getEventTypeGroupNodes(
    groups: EventGroup[],
    eventTypeDetails: Record<string, EventDetails>,
    initialGroupYPos: number
  ) {
    const dummyNodes: Node[] = [];
    const edges: Edge[] = [];
    const eventTypeGroupNodes: Node[] = [];

    let nextGroupYPos = initialGroupYPos;

    groups.forEach(group => {
      let nextEventNodeYPos = nextGroupYPos + NODE_BUFFER_HEIGHT;

      const eventNodes = group.eventTypes.map(event => {
        const currYPos = nextEventNodeYPos;
        nextEventNodeYPos = currYPos + NODE_HEIGHT + NODE_BUFFER_HEIGHT;

        const eventTypeNodeID = event.eventType + group.id;
        //Below is very important
        //eventTypeDetails is a record of <groupId_eventName, details>
        const eventTypeDetailsID = `${group.id}_${event.eventType}`;
        edges.push({
          id: generateId(),
          source: group.id,
          target: eventTypeNodeID,
          style: MAPPING_EDGE_STYLE
        });

        return {
          id: eventTypeNodeID,
          type: MAPPING_EVENT_NODE,
          data: {
            mapping: group,
            eventDetails: eventTypeDetails[eventTypeDetailsID] || null,
            name: event.displayName,
            eventType: event.eventType
          },
          draggable: false,
          position: {
            x: MAPPING_GROUP_X_POS + EVENT_NODE_X_POS,
            y: currYPos
          }
        };
      });

      let groupDummyNodeYPos = nextGroupYPos + NODE_BUFFER_HEIGHT;
      const divisor = eventNodes;
      if (divisor.length % 2 === 0) {
        const middleIndex = divisor.length / 2;
        const firstVal = divisor[middleIndex].position.y;
        const secondVal = divisor[middleIndex - 1].position.y;
        groupDummyNodeYPos = (firstVal + secondVal) / 2;
      } else {
        const middleIndex = Math.round(divisor.length / 2);
        groupDummyNodeYPos = divisor[middleIndex - 1].position.y;
      }

      //very calculated values based on look and feel
      //Need to move them in to some constants
      //after doing entities nodes
      const dummyNode = {
        id: group.id,
        type: MAPPING_DOT_NODE,
        data: {
          showSplitIcon: Boolean(group.eventCriteria?.criteria?.namingFilters?.length)
        },
        draggable: false,
        position: {
          x: MAPPING_GROUP_X_POS + DUMMY_NODE_X_POS + DUMMY_NODE_BUFFER + 50,
          y: groupDummyNodeYPos - DUMMY_NODE_BUFFER + (group.eventCriteria?.criteria?.namingFilters?.length ? 15 : 23)
        }
      };

      dummyNodes.push(dummyNode);
      eventTypeGroupNodes.push(...eventNodes);

      nextGroupYPos =
        nextGroupYPos +
        group.eventTypes.length * NODE_HEIGHT +
        group.eventTypes.length * NODE_BUFFER_HEIGHT +
        NODE_BUFFER_HEIGHT;
    });

    return {
      eventTypeGroupNodes: eventTypeGroupNodes,
      eventTypeGroupEdges: edges,
      eventGroupDummyNodes: dummyNodes
    };
  }

  getSourceNodes(data: Record<string, EventGroupList>, initialYPos: number, refetchMappings: () => void) {
    const sourceNodes: Node[] = [];
    const sourceEdges: Edge[] = [];
    let nextSourceNodeYPos = initialYPos + NODE_BUFFER_HEIGHT;

    for (const [sourceId, eventGroupList] of Object.entries(data)) {
      let sourceSummary: SourceSummary = null;
      eventGroupList.eventGroups.forEach(group => {
        sourceSummary = group.eventSources.find(source => source.sourceId === sourceId);
        sourceEdges.push({
          id: generateId(),
          source: sourceId,
          target: group.id,
          style: MAPPING_EDGE_STYLE
        });
      });
      const currYPos = nextSourceNodeYPos;
      nextSourceNodeYPos = currYPos + NODE_HEIGHT + NODE_BUFFER_HEIGHT;
      sourceNodes.push({
        id: sourceId,
        type: MAPPING_SOURCE_NODE,
        data: {
          mappings: eventGroupList.eventGroups,
          source: sourceSummary,
          refetchMappings: refetchMappings
        },
        draggable: false,
        position: {
          x: MAPPING_GROUP_X_POS + MAPPING_NODE_X_POS,
          y: currYPos
        }
      });
    }

    return {
      sourceNodes,
      sourceEdges
    };
  }

  getEmptySummaryGroupNode(onClick?: (type: VerifyFieldType) => void, xPos?: number, yPos?: number): any {
    const NODE_HEIGHT = 50;
    const GROUP_X_POS = xPos || 10;
    const GROUP_Y_POS = yPos || 10;
    const GROUP_NODE_ID = "empty-summary-group-node"; //can generate a randomId and pass it down

    const nextNodeYPos = NODE_HEIGHT; //yPos

    const emptyNode: Node = {
      id: GROUP_NODE_ID,
      type: BUTTON_NODE,
      data: {
        verified: false,
        type: VerifyFieldType.EVENT_FIELDS,
        onClick: onClick && onClick
      },
      draggable: false,
      position: {
        x: GROUP_X_POS,
        y: GROUP_Y_POS
      }
    };

    return {
      emptySummaryNode: [emptyNode],
      nextStartYPos: nextNodeYPos
    };
  }

  getFieldGroupNodes(
    data: FieldCategoryStats[],
    fieldsVerified: boolean,
    onClick?: (type: VerifyFieldType) => void,
    xPos?: number,
    yPos?: number
  ): any {
    const NODE_HEIGHT = 50;
    const GROUP_X_POS = xPos || 10;
    const GROUP_Y_POS = yPos || 10;
    const GROUP_NODE_ID = "field-group-node"; //can generate a randomId and pass it down

    const fieldCategoryMap: any = {};

    data?.forEach(stat => {
      fieldCategoryMap[stat.category] = stat.count;
    });

    const fieldCatNodes: Node[] = [];
    let totalNodesHeight = NODE_BUFFER_HEIGHT;
    let nextNodeYPos = NODE_HEIGHT; //yPos

    if (Object.keys(fieldCategoryMap)?.length) {
      const nextNodeXPos = 10;

      for (const category in fieldCategoryMap) {
        const currentNodeYPos = nextNodeYPos;
        const nodeItem: Node = {
          id: generateId(),
          type: FIELD_CATEGORY_NODE,
          data: {
            count: fieldCategoryMap[category],
            category: category === "BUSINESS" ? "Business" : "Diagnostic"
          },
          draggable: false,
          parentNode: GROUP_NODE_ID,
          position: {
            x: nextNodeXPos,
            y: currentNodeYPos
          }
        };
        fieldCatNodes.push(nodeItem);
        totalNodesHeight = totalNodesHeight + NODE_HEIGHT + NODE_BUFFER_HEIGHT;
        nextNodeYPos = currentNodeYPos + NODE_HEIGHT + NODE_BUFFER_HEIGHT;
      }

      // fieldCatNodes.push({
      //   id: generateId(),
      //   type: BUTTON_NODE,
      //   data: {
      //     verified: fieldsVerified,
      //     type: VerifyFieldType.EVENT_FIELDS,
      //     onClick: onClick && onClick
      //   },
      //   draggable: false,
      //   parentNode: GROUP_NODE_ID,
      //   position: {
      //     x: nextNodeXPos,
      //     y: nextNodeYPos
      //   },
      // });
      // totalNodesHeight = totalNodesHeight + NODE_BUFFER_HEIGHT;
      // nextNodeYPos = nextNodeYPos + NODE_BUFFER_HEIGHT;
    }

    const fieldGroupNode: Node = {
      id: GROUP_NODE_ID,
      type: GROUP_NODE,
      data: {
        title: "Fields"
      },
      draggable: false,
      position: {
        x: GROUP_X_POS,
        y: GROUP_Y_POS
      },
      style: {
        height: totalNodesHeight + 40
      }
    };

    return {
      fieldGroupNodes: [fieldGroupNode, ...fieldCatNodes],
      nextStartYPos: nextNodeYPos
    };
  }

  getEntityGroupNodes(
    data: EntityTypeStats[],
    entitiesVerified: boolean,
    onClick?: (type: VerifyFieldType) => void,
    xPos?: number,
    yPos?: number
  ): any {
    const NODE_HEIGHT = 50;
    const GROUP_X_POS = xPos || 10;
    const GROUP_Y_POS = yPos || 10;
    const GROUP_NODE_ID = "entity-group-node"; //can generate a randomId and pass it down

    const entities = data?.map((entityStat, index) => ({
      showHandle: false,
      stats: entityStat,
      color: getIntegrationTagColor(index)
    }));

    const entityNodes: Node[] = [];
    let totalNodesHeight = NODE_BUFFER_HEIGHT;
    let nextNodeYPos = NODE_HEIGHT; //yPos

    if (entities?.length) {
      const nextNodeXPos = 10;

      entities.forEach((entityItem: any) => {
        const currentNodeYPos = nextNodeYPos;
        const nodeItem: Node = {
          id: generateId(),
          type: ENTITY_NODE,
          data: entityItem,
          draggable: false,
          parentNode: GROUP_NODE_ID,
          extent: "parent",
          position: {
            x: nextNodeXPos,
            y: currentNodeYPos
          }
        };
        entityNodes.push(nodeItem);
        totalNodesHeight = totalNodesHeight + NODE_HEIGHT + NODE_BUFFER_HEIGHT;
        nextNodeYPos = currentNodeYPos + NODE_HEIGHT + NODE_BUFFER_HEIGHT;
      });

      // entityNodes.push({
      //   id: generateId(),
      //   type: BUTTON_NODE,
      //   data: {
      //     verified:entitiesVerified,
      //     type: VerifyFieldType.ENTITY_FIELDS,
      //     onClick: onClick && onClick
      //   },
      //   draggable: false,
      //   parentNode: GROUP_NODE_ID,
      //   extent: 'parent',
      //   position: {
      //     x: nextNodeXPos,
      //     y: nextNodeYPos
      //   },
      // });
      // totalNodesHeight = totalNodesHeight + NODE_BUFFER_HEIGHT;
      // nextNodeYPos = nextNodeYPos + NODE_BUFFER_HEIGHT;
    }

    const entityGroupNode: Node = {
      id: GROUP_NODE_ID,
      type: GROUP_NODE,
      data: {
        title: "Entities"
      },
      draggable: false,
      position: {
        x: GROUP_X_POS,
        y: GROUP_Y_POS
      },
      style: {
        height: totalNodesHeight + 40
      }
    };

    return {
      entityGroupNodes: [entityGroupNode, ...entityNodes],
      nextStartYPos: nextNodeYPos + NODE_BUFFER_HEIGHT
    };
  }

  getSummaryNodes(eventTypeSummaryDetails: EventTypeFieldMappingDetails) {
    const nodes: Node[] = [];
    const edges: Edge[] = [];
    let fieldNodes = [];
    let entityNodes = [];
    let fieldNodesHeight = 0;
    let entityNodesHeight = 0;

    const { details, nodeYPos, nodeId, onVerifyClick } = eventTypeSummaryDetails;

    let verifyButtonNodeYPos = 10;

    if (details?.fieldCategoryStats?.length) {
      const { fieldGroupNodes, nextStartYPos: fieldsGroupNodeHeight } = this.getFieldGroupNodes(
        details.fieldCategoryStats,
        details.fieldsVerified,
        onVerifyClick,
        10,
        10
      );
      fieldNodes = fieldGroupNodes;
      fieldNodesHeight = fieldsGroupNodeHeight;

      verifyButtonNodeYPos += fieldNodesHeight + 10;
    }

    if (details?.entityTypeStats?.length) {
      const { entityGroupNodes, nextStartYPos: entitiesGroupNodeHeight } = this.getEntityGroupNodes(
        details.entityTypeStats,
        details.entitiesVerified,
        onVerifyClick,
        10,
        fieldNodesHeight ? fieldNodesHeight + 30 : 10
      );
      entityNodes = entityGroupNodes;
      entityNodesHeight = entitiesGroupNodeHeight;

      verifyButtonNodeYPos += entityNodesHeight + 10;
    }

    //if (!details?.fieldCategoryStats?.length && !details?.entityTypeStats?.length) {
    const { emptySummaryNode } = this.getEmptySummaryGroupNode(onVerifyClick, 10, verifyButtonNodeYPos);
    //}

    const nestedGroupNodeId = generateId();
    const bufferHeight = fieldNodesHeight && entityNodesHeight ? 80 : 70;
    nodes.push({
      id: nestedGroupNodeId,
      data: {
        children: [...fieldNodes, ...entityNodes, ...emptySummaryNode],
        height: entityNodesHeight + fieldNodesHeight + 100
      },
      draggable: false,
      position: {
        x: 1000,
        y: nodeYPos - 30
      },
      style: {
        height: entityNodesHeight + fieldNodesHeight + bufferHeight,
        width: NODE_WIDTH + 70
      },
      type: NESTED_GROUP_NODE
    });
    if (fieldNodes?.length && entityNodes?.length) {
      edges.push({
        id: generateId(),
        source: nodeId,
        target: nestedGroupNodeId,
        targetHandle: "nested-handle-top",
        style: MAPPING_EDGE_HIGHLIGHT_STYLE
      });
      edges.push({
        id: generateId(),
        source: nodeId,
        target: nestedGroupNodeId,
        targetHandle: "nested-handle-bottom",
        style: MAPPING_EDGE_HIGHLIGHT_STYLE
      });
    } else {
      edges.push({
        id: generateId(),
        source: nodeId,
        target: nestedGroupNodeId,
        style: MAPPING_EDGE_HIGHLIGHT_STYLE
      });
    }

    return {
      nodes,
      edges
    };
  }

  getNestedTreeNodes(fieldVal: PreviewField, renderer: (data: TreeNode<PreviewField>, style: CSSProperties) => any) {
    const allSubGroups: Array<TreeNode<PreviewField>> = [];
    if (fieldVal.payloadPointer) {
      for (const [key, field] of Object.entries(fieldVal.fields)) {
        const id = field.jsonPath as string;
        const title = key;
        const data = field;
        const children = this.getNestedTreeNodes(field, renderer);
        allSubGroups.push({
          id,
          title,
          data,
          children,
          renderer,
          draggable: false,
          selectable: false
          //onSelect: onClickField
        });
      }
      return allSubGroups;
    }
    return allSubGroups;
  }

  getTreeNodes(
    allFields: Record<string, PreviewField>,
    renderer: (data: TreeNode<PreviewField>, style: CSSProperties) => JSX.Element
  ) {
    const checkboxItems: Array<TreeNode<PreviewField>> = [];
    if (allFields) {
      for (const [key, fieldVal] of Object.entries(allFields)) {
        const id = fieldVal.jsonPath;
        const title = key;
        const data = fieldVal;
        const children = this.getNestedTreeNodes(fieldVal, renderer);
        checkboxItems.push({
          id,
          title,
          data,
          children,
          renderer,
          draggable: false,
          selectable: false
        });
      }
    }
    return checkboxItems;
  }

  getUnverifiedGroupsStartPos(sourceNodes: Node[], eventTypeGroupNodes: Node[]) {
    const sourceNodesLastYPos = sourceNodes?.length ? sourceNodes[sourceNodes.length - 1].position.y : 0;
    const eventTypeNodesLastYPos = eventTypeGroupNodes?.length
      ? eventTypeGroupNodes[eventTypeGroupNodes.length - 1].position.y
      : 0;
    const higherYPos = sourceNodesLastYPos > eventTypeNodesLastYPos ? sourceNodesLastYPos : eventTypeNodesLastYPos;
    return higherYPos + NODE_HEIGHT + GROUP_BUFFER_HEIGHT;
  }

  getUniqFieldName(field: DiscoveredFieldDef) {
    const fieldNames = [];
    if (field?.compositeFields?.length) {
      field.compositeFields.forEach(field => {
        fieldNames.push(field?.name || "");
      });
    } else {
      fieldNames.push(field?.name || "");
    }
    return `${fieldNames.join("~")}-${field?.predefinedFieldType || ""}`;
  }

  getEntityNodes(eventTypeDetails: Record<string, EventDetails>) {
    const entityToEventAndStatDetails: Record<string, EventsAndEntityStats> = {};

    //let nextGroupYPos = MAPPING_GROUP_Y_POS;
    const entityNodes: Node[] = [];
    const entityEdges: Edge[] = [];
    let totalNodesHeight = NODE_BUFFER_HEIGHT;
    let nextNodeYPos = MAPPING_GROUP_Y_POS + 10; //yPos

    for (const [key, details] of Object.entries(eventTypeDetails)) {
      if (details?.entityTypeStats?.length) {
        details.entityTypeStats.forEach(entityStat => {
          const groupIdEventName = key;
          if (entityStat?.isVerified) {
            if (entityToEventAndStatDetails?.[entityStat.entityType.entityTypeId]) {
              entityToEventAndStatDetails[entityStat.entityType.entityTypeId].eventTypeNameKeys.push(groupIdEventName);
            } else {
              entityToEventAndStatDetails[entityStat.entityType.entityTypeId] = {
                entityTypeStats: entityStat,
                eventTypeNameKeys: [groupIdEventName]
              };
            }
          }
        });
      }
    }

    if (!isEmpty(entityToEventAndStatDetails)) {
      Object.values(entityToEventAndStatDetails).forEach((detail, index) => {
        const currentNodeYPos = nextNodeYPos;
        const currentNodeId = generateId();
        const nodeItem: Node = {
          id: currentNodeId,
          type: ENTITY_NODE,
          data: {
            stats: detail.entityTypeStats,
            color: getIntegrationTagColor(index)
          },
          draggable: false,
          position: {
            x: 1020,
            y: currentNodeYPos
          }
        };
        entityNodes.push(nodeItem);
        totalNodesHeight = totalNodesHeight + NODE_HEIGHT + NODE_BUFFER_HEIGHT;
        nextNodeYPos = currentNodeYPos + NODE_HEIGHT + NODE_BUFFER_HEIGHT;

        detail.eventTypeNameKeys.forEach(nameKey => {
          const nameKeySplit = nameKey.split(/_(.*)/s);
          const eventGroupId = nameKeySplit[0];
          const eventTypeName = nameKeySplit[1];
          entityEdges.push({
            id: generateId(),
            source: eventTypeName + eventGroupId,
            target: currentNodeId,
            style: MAPPING_EDGE_STYLE
          });
        });
      });
    }

    return {
      entityNodes: entityNodes,
      entityEdges: entityEdges
    };
  }

  getMetricGroupNodes(entityType: string, xPos?: number) {
    const NODE_HEIGHT = 50;
    const GROUP_X_POS = xPos || 10;
    const GROUP_Y_POS = 10;
    const GROUP_NODE_ID = "metric-group-node";
    let nextNodeYPos = NODE_HEIGHT;

    const listOfMetrics = [
      {
        metricName: "Total Revenue",
        fieldName: "fields.Amount"
      },
      {
        metricName: "Orders Placed",
        fieldName: "eventID"
      },
      {
        metricName: "Failed Orders",
        fieldName: "hasError"
      }
    ];
    let totalNodesHeight = NODE_BUFFER_HEIGHT;

    const metricNodes: Node[] = [];
    listOfMetrics.forEach(metric => {
      metricNodes.push({
        id: generateId(),
        data: {
          metricName: metric.metricName,
          fieldName: metric.fieldName,
          entityType
        },
        type: METRIC_NODE,
        draggable: false,
        parentNode: GROUP_NODE_ID,
        extent: "parent",
        position: {
          x: 10,
          y: nextNodeYPos
        }
      });
      totalNodesHeight = totalNodesHeight + NODE_HEIGHT + NODE_BUFFER_HEIGHT;
      nextNodeYPos = nextNodeYPos + NODE_HEIGHT + NODE_BUFFER_HEIGHT;
    });

    metricNodes.push({
      id: generateId(),
      type: BUTTON_NODE,
      data: {
        verified: false,
        onClick: () => {},
        label: "ACCEPT"
      },
      draggable: false,
      parentNode: GROUP_NODE_ID,
      position: {
        x: 10,
        y: nextNodeYPos
      },
      style: {
        width: 228
      }
    });
    nextNodeYPos = nextNodeYPos + NODE_HEIGHT + NODE_BUFFER_HEIGHT;

    const metricGroupNode: Node = {
      id: GROUP_NODE_ID,
      type: GROUP_NODE,
      data: {
        title: "Suggested KPIs",
        showActions: true
      },
      draggable: false,
      position: {
        x: GROUP_X_POS,
        y: GROUP_Y_POS
      },
      style: {
        height: totalNodesHeight + 80,
        width: 250
      }
    };

    return {
      metricGroupNodes: [metricGroupNode, ...metricNodes],
      nextStartYPos: nextNodeYPos + NODE_BUFFER_HEIGHT
    };
  }

  getOperationalizeGroupNodes(entityType: string, xPos?: number, yPos?: number) {
    const NODE_HEIGHT = 50;
    const GROUP_X_POS = xPos || 10;
    const GROUP_Y_POS = yPos || 10;
    const GROUP_NODE_ID = "op10ze-group-node";
    let nextNodeYPos = NODE_HEIGHT;

    const listOfOpConfigs = [
      {
        opName: "Drop in Revenue",
        metricName: "Total Revenue",
        comparator: "drop"
      },
      {
        opName: "Drop in Orders Placed",
        metricName: "Orders Placed",
        comparator: "drop"
      },
      {
        opName: "Spike in Failed Orders",
        metricName: "Failed Orders",
        comparator: "spike"
      }
    ];
    let totalNodesHeight = NODE_BUFFER_HEIGHT;

    const opNodes: Node[] = [];
    listOfOpConfigs.forEach(opConfig => {
      opNodes.push({
        id: generateId(),
        data: {
          entityType,
          opName: opConfig.opName,
          metricName: opConfig.metricName,
          comparator: opConfig.comparator
        },
        draggable: false,
        type: OPERATIONALIZE_NODE,
        parentNode: GROUP_NODE_ID,
        extent: "parent",
        position: {
          x: 10,
          y: nextNodeYPos
        }
      });
      totalNodesHeight = totalNodesHeight + NODE_HEIGHT + NODE_BUFFER_HEIGHT;
      nextNodeYPos = nextNodeYPos + NODE_HEIGHT + NODE_BUFFER_HEIGHT;
    });

    opNodes.push({
      id: generateId(),
      type: BUTTON_NODE,
      data: {
        verified: false,
        onClick: () => {},
        label: "ACCEPT"
      },
      draggable: false,
      parentNode: GROUP_NODE_ID,
      position: {
        x: 10,
        y: nextNodeYPos
      },
      style: {
        width: 228
      }
    });
    nextNodeYPos = nextNodeYPos + NODE_HEIGHT + NODE_BUFFER_HEIGHT;

    const opGroupNode: Node = {
      id: GROUP_NODE_ID,
      type: GROUP_NODE,
      data: {
        title: "Suggested Operationalize",
        showActions: true
      },
      draggable: false,
      position: {
        x: GROUP_X_POS,
        y: GROUP_Y_POS
      },
      style: {
        height: totalNodesHeight + 80,
        width: 250
      }
    };

    return {
      opGroupNodes: [opGroupNode, ...opNodes],
      nextStartYPos: nextNodeYPos + NODE_BUFFER_HEIGHT
    };
  }

  getEntitySummaryNodes(entityNodeId: string, entityType: string) {
    const { metricGroupNodes, nextStartYPos: metricGroupHeight } = this.getMetricGroupNodes(entityType, 10);
    const { opGroupNodes, nextStartYPos: opNodesHeight } = this.getOperationalizeGroupNodes(
      entityType,
      10,
      metricGroupHeight + 60
    );

    const nodes: Node[] = [];
    const edges: Edge[] = [];
    const nestedGroupNodeId = generateId();
    const bufferHeight = metricGroupHeight && opNodesHeight ? 120 : 70;
    nodes.push({
      id: nestedGroupNodeId,
      data: {
        children: [...metricGroupNodes, ...opGroupNodes],
        height: opNodesHeight + metricGroupHeight + 100
      },
      draggable: false,
      position: {
        x: 1350,
        y: 120
      },
      style: {
        height: opNodesHeight + metricGroupHeight + bufferHeight,
        width: NODE_WIDTH + 100
      },
      type: NESTED_GROUP_NODE
    });
    edges.push({
      id: generateId(),
      source: entityNodeId,
      target: nestedGroupNodeId,
      targetHandle: "nested-handle-top",
      style: MAPPING_EDGE_HIGHLIGHT_STYLE
    });
    edges.push({
      id: generateId(),
      source: entityNodeId,
      target: nestedGroupNodeId,
      targetHandle: "nested-handle-bottom",
      style: MAPPING_EDGE_HIGHLIGHT_STYLE
    });

    return {
      nodes,
      edges
    };
  }

  getAllPossibleEventDetailKeys(groups: Record<string, EventGroupList>) {
    const keys: string[] = [];

    for (const [, groupList] of Object.entries(groups)) {
      const { eventGroups } = groupList;

      eventGroups.forEach(grp => {
        const grpId = grp.id;
        grp.eventTypes.forEach(x => keys.push(`${grpId}_${x.eventType}`));
      });
    }

    return keys;
  }

  getValidCustomRawFieldObjects(
    fields: Record<string, PreviewField>,
    fieldJsonPath: string,
    jsonPathList: string[] = []
  ): string[] {
    const keys = Object.keys(fields);
    const { length } = keys;
    for (let i = 0; i < length; i++) {
      const key = keys[i];
      const currField = fields[key];
      if (currField.jsonPath === fieldJsonPath) {
        return jsonPathList;
      } else if (!isEmpty(currField.fields)) {
        const newList = [...jsonPathList];
        if (currField.type === "_array" || currField.type === "_set" || currField.type === "_array_map") {
          newList.push(currField.jsonPath);
        }
        const jsonPaths = this.getValidCustomRawFieldObjects(currField.fields, fieldJsonPath, newList);
        if (jsonPaths.length) {
          return jsonPaths;
        }
      }
    }
    return [];
  }

  getCustomObjSubFieldJsonPath(field: DiscoveredFieldDef, customObjJsonPath: string) {
    if (field.fieldJsonPath.includes(customObjJsonPath)) {
      const jsonPathWithAsk = `${customObjJsonPath}[*]`;
      const jsonPath = field.fieldJsonPath;
      return jsonPath.replace(jsonPathWithAsk, "@");
    } else {
      return field.fieldJsonPath;
    }
  }

  getCustomFieldObjectDefFromFieldDef(
    fieldDef: DiscoveredFieldDef,
    customObjType: string,
    predefinedFieldType: string
  ): DiscoveredCustomFieldDef {
    const customFieldDef: DiscoveredCustomFieldDef = {
      name: fieldDef.name,
      fieldJsonPath: fieldDef.fieldJsonPath, //"$.data.items"
      dataType: fieldDef.dataType,
      subtype: fieldDef.subtype,
      //  repeated schema.types.PropertyValue sampleValues = 5,
      selected: fieldDef.selected,
      cardinalityResponse: fieldDef.cardinalityResponse,
      statsResponse: fieldDef.statsResponse,
      customObjectType: customObjType,
      fieldCategory: FieldTypeCategory.BUSINESS,
      predefinedFieldType,
      dataTransformations: fieldDef.dataTransformations,
      distribution: fieldDef.distribution,
      verticalContextField: fieldDef.verticalContextField,
      mappingType: MappingType.MAPPED,
      isCommonField: fieldDef.isCommonField,
      fieldDef: [],
      entityDef: []
    };
    return customFieldDef;
  }

  getFormattedValue(value: string, dataType: FieldPrimType, subtype: FieldSubType) {
    if (subtype === "epoch_millis" || subtype === "epoch_micros" || subtype === "epoch_secs") {
      const updatedVal = subtype === "epoch_micros" ? Math.round(parseInt(value, 10) / 1000) : parseInt(value, 10);
      return value ? dateTime(updatedVal).format("DD-MM-YYYY hh:mm:ss") : "";
    }
    return value ? value : "missing";
  }
}

export const autoDiscoveryUtils = new AutoDiscoveryUtils();
