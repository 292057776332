import { QueryCatalogWidgetImpl, QueryCatalogWidgetModel } from "../../widgets/QueryCatalogWidget/models";
import { WidgetBuilderCallbackResult } from "./BaseWidgetBuilder";
import { CatalogWidgetBuilder } from "./CatalogWidgetBuilder";

const queryCatalogWidgetBuilder = (
  model: Partial<QueryCatalogWidgetModel> = {}
): WidgetBuilderCallbackResult<QueryCatalogWidgetModel, QueryCatalogWidgetBuilder> => {
  const catalogModel = new QueryCatalogWidgetImpl(model).getSafeDefaults() as QueryCatalogWidgetModel;

  // Return a method that ensures a datasource is set before setting any other properties
  return {
    setDatasource: (dsName: string) => {
      catalogModel.datasource = dsName;

      return new QueryCatalogWidgetBuilder(catalogModel);
    }
  };
};

class QueryCatalogWidgetBuilder extends CatalogWidgetBuilder<QueryCatalogWidgetModel> {
  constructor(model: QueryCatalogWidgetModel) {
    super(model);
  }

  setQueryString(queryString: string) {
    this.model.dataQuery = queryString;
    return this;
  }

  setUseCaseName(useCaseName: string) {
    this.model.useCaseName = useCaseName;
    return this;
  }

  setCompanyName(companyName: string) {
    this.model.companyName = companyName;
    return this;
  }

  setVerticalId(verticalId: string) {
    this.model.verticalId = verticalId;
    return this;
  }

  setSubVerticalId(subVerticalId: string) {
    this.model.subVerticalId = subVerticalId;
    return this;
  }
}

export default queryCatalogWidgetBuilder;
