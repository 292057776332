import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IncButton, IncDateRangePicker, IncFaIcon, IncTextfield } from "@inception/ui";
import { BaselineChangeType, BizImpactConfig, ImpactDefImpactType } from "../../services/api/types";
import { dateTime, generateId, useInputState } from "../../core";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { VerticallyCenteredRow } from "../../components";

interface Props {
  bizImpactConfig: BizImpactConfig;
  onChange: (bizImpactConfig: BizImpactConfig) => void;
}

export const BizImpactConfigEditor: FC<Props> = props => {
  const { bizImpactConfig, onChange } = props;

  const {
    name: defName = "",
    description: defDescription = "",
    endTimeInSec: defEndTimeInSec = 0,
    startTimeInSec: defStartTimeInSec = 0,
    id: defId,
    impact: defImpact
  } = bizImpactConfig || {};

  const bizImpactId = useMemo(() => defId || generateId(), [defId]);

  const { inputValue: name, onInputChange: onNameChange, setInputValue: setName } = useInputState(defName);

  // const {
  //   inputValue: description,
  //   onInputChange: onDescriptionChange,
  //   setInputValue: setDescription
  // } = useInputState(defDescription);

  useEffect(() => {
    setName(defName);
  }, [defName, setName]);

  // useEffect(() => {
  //   setDescription(defDescription);
  // }, [defDescription, setDescription]);

  const defImpactChange = useMemo(() => {
    let lowerBoundChange = defImpact?.baseLineChangeConfig?.lowerBoundChange;
    lowerBoundChange = parseFloat(String(lowerBoundChange));
    lowerBoundChange = isNaN(lowerBoundChange) ? 30 : lowerBoundChange;
    return lowerBoundChange;
  }, [defImpact]);
  const [impactChange, setImpactChange] = useState<number>(defImpactChange);

  const defStartTime = useMemo(() => {
    let startTimeSec = defStartTimeInSec;
    startTimeSec = parseInt(String(startTimeSec), 10);
    startTimeSec = isNaN(startTimeSec)
      ? timeRangeUtils.getSecondsFromMillis(dateTime().subtract(1, "d").startOf("d").valueOf())
      : startTimeSec;
    return new Date(startTimeSec * 1000);
  }, [defStartTimeInSec]);
  const [startTime, setStartTime] = useState(defStartTime);

  const defEndTime = useMemo(() => {
    let endTimeSec = defEndTimeInSec;
    endTimeSec = parseInt(String(endTimeSec), 10);
    endTimeSec = isNaN(endTimeSec)
      ? timeRangeUtils.getSecondsFromMillis(dateTime().startOf("d").valueOf())
      : endTimeSec;
    return new Date(endTimeSec * 1000);
  }, [defEndTimeInSec]);
  const [endTimeSecs, setEndTimeSecs] = useState(defEndTime);

  const onDateRangeChange = useCallback((from: Date, to: Date) => {
    setStartTime(from);
    setEndTimeSecs(to);
  }, []);

  const { isValid, error } = useMemo(() => {
    let isValid = true;
    let error = "";

    if (!name) {
      isValid = false;
      error = "Name is required";
    }

    // if (!description) {
    //   isValid = false;
    //   error = 'Description is required';
    // }

    if (!impactChange) {
      isValid = false;
      error = "Impact change is required";
    }

    return {
      isValid,
      error
    };
  }, [impactChange, name]);

  const impactType = useMemo(() => defImpact?.type || [ImpactDefImpactType.BASELINE_CHANGE], [defImpact]);
  const onSave = useCallback(() => {
    if (isValid) {
      onChange({
        id: bizImpactId,
        description: defDescription,
        name,
        endTimeInSec: timeRangeUtils.getSecondsFromMillis(endTimeSecs.valueOf()),
        startTimeInSec: timeRangeUtils.getSecondsFromMillis(startTime.valueOf()),
        impact: {
          type: impactType,
          baseLineChangeConfig: {
            type: BaselineChangeType.PERCENTAGE,
            lowerBoundChange: impactChange,
            upperBoundChange: impactChange
          }
        }
      });
    }
  }, [isValid, onChange, bizImpactId, defDescription, name, endTimeSecs, startTime, impactType, impactChange]);

  return (
    <div className="inc-flex-column flex-gap-12">
      <IncTextfield
        containerClassName="width-100"
        errorText="Name is required"
        hasError={!name}
        label="Name"
        onChange={onNameChange}
        required
        value={name}
      />

      {/* <IncTextArea
      containerClassName="width-100"
      errorText="Description is required"
      hasError={!description}
      label='Description'
      onChange={onDescriptionChange}
      required
      rows={3}
      value={description}
    /> */}

      <IncDateRangePicker
        from={startTime}
        inline
        onChange={onDateRangeChange}
        to={endTimeSecs}
      />

      <VerticallyCenteredRow className="flex-gap-4">
        <IncTextfield
          autoAdjustWidth
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setImpactChange(e.target.valueAsNumber || 0)}
          type="number"
          value={impactChange}
        />

        <VerticallyCenteredRow className="inc-label-common">% more than usual base line</VerticallyCenteredRow>
      </VerticallyCenteredRow>

      <VerticallyCenteredRow className="flex-gap-8">
        <IncButton
          color="primary"
          disabled={!isValid}
          label="Save"
          onClick={onSave}
        />

        {!isValid && (
          <VerticallyCenteredRow className="flex-gap-8 status-danger inc-text-subtext-medium">
            <IncFaIcon iconName="exclamation-triangle" />
            {error}
          </VerticallyCenteredRow>
        )}
      </VerticallyCenteredRow>
    </div>
  );
};
