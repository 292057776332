import Highcharts, { Chart } from "highcharts";
import HCMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import Boost from "highcharts/modules/boost";
import Annotations from "highcharts/modules/annotations";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { max } from "lodash";
import { IncTimeSeriesOptions } from "../time-series/types";
import { CommonWidgetOptions } from "../../dashboard/models/BaseWidgetModel";
import { generateId, TimeRange } from "../../core";
import { TimeSeriesProperties } from "../../dashboard/widgets/TimeSeries/models/model";
import { FEATURE_FLAGS, featureFlagService } from "../../services/feature-flags";
import SparkLineOptionsBuilder from "./OptionsBuilder";

interface SparkLineProps {
  id?: string;
  containerElemClass: string;

  // Map of series name to data where date is of the form [[timestamp1, value1], [timestamp2, value2]]
  series?: IncTimeSeriesOptions[];

  maxValue?: number;
  usedInNonDashboardWidget?: boolean; // Is this used in standalone screens vs in dashboards with widgets

  seriesColors?: string[];
  properties?: TimeSeriesProperties;

  // In case of series which have large number of series use this
  disableInteractions?: boolean;
  options?: Options;

  timeRange?: TimeRange;
  onSelectTimeRange?: (start: number, end: number) => boolean | void;
  onResetSelectedTimeRange?: () => void;
  disableToolTip?: boolean;
}

HCMore(Highcharts);
Boost(Highcharts);
Annotations(Highcharts);

Highcharts.AST.bypassHTMLFiltering = true;

Highcharts.setOptions({
  time: {
    useUTC: false
  }
});

const SparkLine: React.FC<SparkLineProps> = (props: SparkLineProps) => {
  const {
    id,
    maxValue: pMaxValue,
    containerElemClass,
    options: hcOptions,
    series,
    timeRange,
    properties,
    seriesColors,
    disableToolTip,
    disableInteractions
  } = props;
  const [, setChartRendered] = useState(false);
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>(null);
  const [, setChartInstance] = useState<Chart>(null);

  const chartContainerRef = useRef<HTMLDivElement>();
  const [maxValue, setMaxValue] = useState(pMaxValue);

  const getChartDimensions = useCallback(() => {
    const canvasElem: HTMLElement = chartContainerRef.current;
    if (canvasElem) {
      const widgetItemContainer: Element = canvasElem.closest(`.${containerElemClass}`);
      const dimensions = widgetItemContainer?.getBoundingClientRect();
      if (dimensions) {
        const { height } = dimensions;
        const { width } = dimensions;
        return {
          width,
          height
        };
      }
    }
    return {
      width: -1,
      height: -1
    };
  }, [chartContainerRef, containerElemClass]);

  const { width, height } = getChartDimensions();

  const showTimeZone = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.enableTimezone);

  useEffect(() => {
    if (height <= 0 || !width) {
      return;
    }

    const optionsBuilder: SparkLineOptionsBuilder = new SparkLineOptionsBuilder(hcOptions)
      .setChartFields(width, height)
      .setPlotOptions(disableInteractions)
      .setXAxis(null, timeRange)
      .setYAxis(maxValue, hcOptions?.yAxis)
      .clearSeriesData()
      .setSeriesData(series)
      .setToolTip(
        0,
        properties?.tooltip,
        disableToolTip,
        showTimeZone ? timeRange.timeZone || timeRange.raw.timeZone : null
      )
      .setPlotOptions(false);

    const options = optionsBuilder.build();

    //set the first series color
    if (options.series.length && seriesColors?.length) {
      options.series[0].color = seriesColors[0];
    }

    setChartOptions(options);
  }, [
    maxValue,
    series,
    width,
    height,
    hcOptions,
    timeRange,
    properties?.tooltip,
    disableToolTip,
    disableInteractions,
    seriesColors,
    showTimeZone
  ]);

  const highchartsCallback = useCallback(
    (chart: Chart) => {
      const shouldUpdateMaxValue = isNaN(Number(maxValue));

      /**
       * Update yMax when annotations are set.
       * It is needed for the annotations to appear on top.
       */
      if (shouldUpdateMaxValue) {
        const maxValues = chart.yAxis.map(axis => axis.getExtremes().max);
        const yMax = max(maxValues);

        setMaxValue(yMax);
      }

      setChartInstance(chart);
    },
    [maxValue]
  );

  const chartId = useMemo(() => id || generateId(), [id]);

  useEffect(() => {
    if (chartContainerRef.current) {
      setChartRendered(true);
    }
  }, [chartContainerRef]);

  return (
    <div
      className="chart-container"
      id={chartId}
      ref={chartContainerRef}
    >
      {chartOptions && (
        <HighchartsReact
          callback={highchartsCallback}
          highcharts={Highcharts}
          options={chartOptions}
        />
      )}
    </div>
  );
};

export default SparkLine;

type Options = CommonWidgetOptions & Highcharts.Options;
