import { IncFaIcon, IncPill, IncSelectOption, IncToolTip } from "@inception/ui";
import React, { FC, useCallback, useMemo, useState } from "react";
import { EventsTable, VerticallyCenteredRow } from "../..";
import { generateId, useTimeRange } from "../../../core";
import {
  BizFieldPredicate,
  CohortConfig,
  UserServiceFilterExpression,
  UserServiceFilterList,
  WidgetConfigUtils
} from "../../../services/api/explore";
import { ENTITY_TAG, FieldPickerUtils, MISSING } from "../../../utils";
import { ImpactedWidgetFilter } from "../impact-widget";
import { IncidentTimelineAndImpactWidget } from "../incident-timeline-impact-widget";
import { KeyIndicatorsWrapper, TriageCriteriaRenderer } from "../key-indicators";
import { ImpactedWidgetsState, IncidentTimelineContext, IncidentTimelineMonitoredSeriesContext } from "../types";
import { TimeRangeSelector } from "../../time-range";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { DrilldownSubRendererProps } from "./types";

interface Props extends DrilldownSubRendererProps {
  viewOnlyUSFilters: UserServiceFilterExpression[];
  lookupData?: Record<string, string>;
  monitoredSeriesCtx?: IncidentTimelineMonitoredSeriesContext;
  enableContextSelection?: boolean;
  generateDemoData?: boolean;
}

export const FieldDrilldownDrawer: FC<Props> = props => {
  const {
    entityFilters,
    viewOnlyUSFilters,
    impactedWidget,
    keyContributorsFetchState,
    userServiceFilters,
    cohortId,
    entityType,
    alertingSlices,
    diagnosticSlices,
    incidentId,
    opConfigId,
    timeRange: pTimeRange,
    impactedWidgetList,
    preset,
    lookupData,
    monitoredSeriesCtx,
    enableContextSelection = false
  } = props;
  const { timeRange: gTimeRange } = useTimeRange();
  const dTimeRange = useMemo(() => pTimeRange || gTimeRange, [gTimeRange, pTimeRange]);

  const alertingSliceTagNames = useMemo(
    () => (alertingSlices || []).map(sl => sl.tagName).filter(Boolean),
    [alertingSlices]
  );

  const uniqId = useMemo(() => generateId(), []);

  const [timeRange, setTimeRange] = useState(dTimeRange);

  const shouldShowReset = useMemo(() => {
    const { fromMillis: gFrom, toMillis: gTo } = timeRangeUtils.getMillisFromTimeRange(dTimeRange);

    const { fromMillis: lFrom, toMillis: lTo } = timeRangeUtils.getMillisFromTimeRange(timeRange);

    return gFrom !== lFrom || gTo !== lTo;
  }, [dTimeRange, timeRange]);

  const resetTimeRange = useCallback(() => {
    setTimeRange(dTimeRange);
  }, [dTimeRange]);

  const { extEventFieldFilters } = useMemo(() => {
    const extEntityFilters: BizFieldPredicate[] = [];
    const extEventFieldFilters: UserServiceFilterExpression[] = [];

    keyContributorsFetchState?.keyContributors?.forEach(contributor => {
      const { slice, values } = contributor;

      const { tagName, userServiceField } = slice;

      if (alertingSliceTagNames.includes(tagName)) {
        const filterValues = values.map(v => v.value).filter(v => v !== MISSING);

        if (filterValues.length) {
          if (tagName === ENTITY_TAG) {
            const entityField = userServiceField?.entityField;
            if (entityField) {
              const predicate: BizFieldPredicate = {
                bizField: {
                  entityField
                },
                op: "in",
                value: null,
                values: filterValues
              };
              extEntityFilters.push(predicate);
            }
          } else {
            const usFilterExpr: UserServiceFilterExpression = {
              field: userServiceField,
              operator: "in",
              values: filterValues,
              value: null
            };
            extEventFieldFilters.push(usFilterExpr);
          }
        }
      }
    });

    return {
      extEntityFilters,
      extEventFieldFilters
    };
  }, [alertingSliceTagNames, keyContributorsFetchState?.keyContributors]);

  const allEntityFilters = useMemo(
    () => [
      {
        filters: [...(entityFilters[0]?.filters || [])]
      }
    ],
    [entityFilters]
  );

  const entityCriteria = useMemo(() => entityFilters[0]?.filters || [], [entityFilters]);

  const eventCriteria = useMemo(() => {
    const eventFieldFilters = userServiceFilters?.[impactedWidget.id];
    if (eventFieldFilters?.userServiceFilters) {
      const parentFilters = eventFieldFilters.userServiceFilters[0]?.userServiceFilterExpressions || [];
      return [...parentFilters, ...extEventFieldFilters];
    }

    return extEventFieldFilters;
  }, [extEventFieldFilters, impactedWidget.id, userServiceFilters]);

  const extEventFilters = useMemo<UserServiceFilterList>(
    () => ({
      userServiceFilters: [
        {
          userServiceFilterExpressions: [...eventCriteria, ...viewOnlyUSFilters]
        }
      ]
    }),
    [eventCriteria, viewOnlyUSFilters]
  );

  const cohortDefinition = useMemo<CohortConfig>(
    () => ({
      cohortId,
      name: ""
    }),
    [cohortId]
  );

  const impactedWidgetFilter = useMemo(
    () => (
      <div className="marginRt12">
        <ImpactedWidgetFilter impactedWidget={impactedWidget} />
      </div>
    ),
    [impactedWidget]
  );

  const impactedWidgetsState = useMemo<ImpactedWidgetsState>(
    () => ({
      impactedWidgetsFetchError: null,
      impactedWidgetsLists: [
        {
          ...impactedWidgetList,
          parentConfig: null
        }
      ],
      isImpactedWidgetsFetchError: false,
      isImpactedWidgetsFetching: false
    }),
    [impactedWidgetList]
  );

  const usField = viewOnlyUSFilters[0]?.field;
  const label = usField ? FieldPickerUtils.generateNamefromUSF(usField) : "";

  const eventTypeId = usField?.userServices?.[0]?.userServiceEntityId;

  const preselectedFields = useMemo(() => {
    const preselectedFieldsSet = (impactedWidget.underlyingFilters?.userServiceFilters || []).reduce(
      (labelsSet, filter) => {
        const { userServiceFilterExpressions } = filter;
        userServiceFilterExpressions.forEach(({ field }) => {
          const label = FieldPickerUtils.generateNamefromUSF(field);
          labelsSet.add(label);
        });
        return labelsSet;
      },
      new Set<string>()
    );

    // Temporarily clearing all filter fields
    preselectedFieldsSet.clear();

    Object.values(preset?.vizConfigs || {}).forEach(({ slice }) => {
      const { userServiceField } = slice;
      const label = FieldPickerUtils.generateNamefromUSF(userServiceField);
      preselectedFieldsSet.add(label);
    });

    diagnosticSlices?.forEach(({ userServiceField }) => {
      const key = FieldPickerUtils.generateNamefromUSF(userServiceField);
      preselectedFieldsSet.add(key);
    });

    keyContributorsFetchState?.keyContributors?.forEach(({ slice }) => {
      const usField = slice.userServiceField;
      const key = FieldPickerUtils.generateNamefromUSF(usField);
      preselectedFieldsSet.add(key);
    });

    return Array.from(preselectedFieldsSet);
  }, [diagnosticSlices, impactedWidget.underlyingFilters, keyContributorsFetchState, preset]);

  const impactWidgetFilters = useMemo(() => {
    const extendedFilters: UserServiceFilterExpression[] = [];
    (impactedWidget.underlyingFilters?.userServiceFilters || []).forEach(filter =>
      extendedFilters.push(...filter.userServiceFilterExpressions)
    );

    return extendedFilters;
  }, [impactedWidget.underlyingFilters]);

  const monitoredSeriesCtxOpt = useMemo<IncSelectOption<IncidentTimelineContext>>(
    () => ({
      label: "Monitored series",
      value: "monitoredSeries",
      data: {
        impactedWidgetContext: null,
        incidentId,
        opConfigId,
        timeRange,
        type: "monitoredSeries",
        uniqueAlerts: false,
        monitoredSeriesContext: monitoredSeriesCtx
      }
    }),
    [incidentId, monitoredSeriesCtx, opConfigId, timeRange]
  );

  const timelineContext = useMemo<IncidentTimelineContext>(
    () =>
      monitoredSeriesCtx
        ? monitoredSeriesCtxOpt.data
        : {
            impactedWidgetContext: {
              alertingSlices: alertingSliceTagNames,
              diagnosticSlices,
              impactedWidget
            },
            incidentId,
            opConfigId,
            timeRange,
            type: "impactedWidget",
            uniqueAlerts: false,
            monitoredSeriesContext: null
          },
    [
      alertingSliceTagNames,
      diagnosticSlices,
      impactedWidget,
      incidentId,
      monitoredSeriesCtx,
      monitoredSeriesCtxOpt.data,
      opConfigId,
      timeRange
    ]
  );

  const extCtxOptions = useMemo<Array<IncSelectOption<IncidentTimelineContext>>>(
    () => [monitoredSeriesCtxOpt],
    [monitoredSeriesCtxOpt]
  );

  const userServiceToBizEntityFieldName = useMemo(() => {
    const bizEntityFieldName = alertingSlices?.[0]?.userServiceField?.bizEntityFieldName || "";
    return eventTypeId && bizEntityFieldName
      ? {
          [eventTypeId]: bizEntityFieldName
        }
      : {};
  }, [alertingSlices, eventTypeId]);

  return (
    <div className="field-drilldown-drawer">
      <TriageCriteriaRenderer
        className="width-100 marginBt16"
        cohortDefinition={cohortDefinition}
        entityCriteria={entityCriteria}
        entityType={entityType}
        eventCriteria={eventCriteria}
        readOnly
        sectionChildren={{
          eventCriteria: impactedWidgetFilter
        }}
      >
        {Boolean(usField) && (
          <div className="marginLt16 extended-filters">
            <div className="marginBt6 inc-label-common">{label}</div>
            <VerticallyCenteredRow>
              {viewOnlyUSFilters.map((fe, idx) => {
                const key = [uniqId, idx].join("_");
                const label = WidgetConfigUtils.getUsFilterExpressionLabel(fe, lookupData || {}, true);

                return (
                  <IncPill
                    className="marginRt8"
                    key={key}
                    label={label}
                    readonly
                  />
                );
              })}
            </VerticallyCenteredRow>
          </div>
        )}
      </TriageCriteriaRenderer>

      <KeyIndicatorsWrapper
        entityTypeId={entityType}
        {...keyContributorsFetchState}
        {...props}
        eventFieldFilters={extEventFilters}
        hideDrawers
        impactedWidgetsState={impactedWidgetsState}
        preset={props.pageConfig?.keyIndicators}
      />

      <div className="inc-flex-row marginBt16">
        <VerticallyCenteredRow className="marginLtAuto">
          {shouldShowReset && (
            <IncToolTip
              placement="top"
              titleText="Set page time range"
            >
              <VerticallyCenteredRow
                className="inc-cursor-pointer status-info marginRt16"
                onClick={resetTimeRange}
              >
                <IncFaIcon
                  className="marginRt4"
                  iconName="chevron-left"
                  style={{ transform: "scale(0.75)" }}
                />
                <VerticallyCenteredRow>Back</VerticallyCenteredRow>
              </VerticallyCenteredRow>
            </IncToolTip>
          )}
          <TimeRangeSelector
            buttonDisplay
            onTimeSelect={setTimeRange}
            timeRange={timeRange}
          />
        </VerticallyCenteredRow>
      </div>

      <IncidentTimelineAndImpactWidget
        {...(props as any)}
        defaultContext={timelineContext}
        disableContextSelection={!enableContextSelection}
        eventFieldFilters={extEventFilters}
        extCtxOptions={extCtxOptions}
        hideImpactedWidgets
        hideTimeRange={true}
        impactedWidgetsLists={impactedWidgetsState.impactedWidgetsLists}
        timeRange={timeRange}
      />

      {!keyContributorsFetchState?.isFetching && !impactedWidgetsState?.isImpactedWidgetsFetching && (
        <EventsTable
          entityFilters={allEntityFilters}
          entityTypeId={entityType}
          eventFilters={extEventFilters}
          eventTypeId={eventTypeId}
          extendedFilters={impactWidgetFilters}
          incidentId={incidentId}
          opConfigId={opConfigId}
          preselectedFields={preselectedFields}
          timeRange={timeRange}
          userServiceToBizEntityFieldName={userServiceToBizEntityFieldName}
        />
      )}
    </div>
  );
};
