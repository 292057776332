import React, { FC, useEffect, useState, useMemo, useCallback, useRef } from "react";
import { ISaxIcon, IncFaIcon, IncSmartText, IncToolTip, getStringPossibleWidth } from "@inception/ui";
import { BizEntityType, UserServiceField, UserServiceTuple } from "../../services/api/explore";
import { FieldPickerModel } from "../../field-picker";
import { VerticallyCenteredRow } from "../flex-components";
import { FieldPickerUtils } from "../../utils";
import { FieldPickerPopUpV2 } from "./FieldPickerPopUpV2";

interface Props {
  eventId?: string;
  entityTypeId?: string;
  userServices?: UserServiceTuple[];

  isMetaLoading?: boolean;
  skipCohortFields?: boolean;
  skipEntityIcons?: boolean;

  fieldPickerModel?: FieldPickerModel;
  onFieldsFetched?: (model: FieldPickerModel) => void;

  selection: UserServiceField[];
  onSelectionChange: (fields: UserServiceField[]) => void;
  isMulti?: boolean;

  placeholder?: string;
  label?: string;
  infoText?: string;
  errorText?: string;
  readOnly?: boolean;

  bizEntityTypes?: BizEntityType[];
  onBizEntityTypesFetched?: (beTypes: BizEntityType[]) => void;
  fieldPickerPopupClassName?: string;
  fieldPickerPopupContainerClassName?: string;
  excludeAllUserServiceField?: boolean;
}

export const FieldPickerV2: FC<Props> = props => {
  const {
    isMulti,
    onSelectionChange,
    selection,
    label = "",
    infoText = "",
    errorText = "",
    placeholder: defPlaceholder = "",
    readOnly = false,
    children,
    fieldPickerPopupClassName = "",
    fieldPickerPopupContainerClassName = "",
    ...restProps
  } = props;

  const [selectedFields, setSelectedFields] = useState(selection);
  useEffect(() => {
    setSelectedFields(selection);
  }, [selection]);

  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => {
    onSelectionChange(selectedFields);
    setOpen(false);
  }, [onSelectionChange, selectedFields]);

  // For single field select, close as soon as a field is selected
  const onSelect = useCallback(
    (usField: UserServiceField) => {
      onSelectionChange([usField]);
      setOpen(false);
    },
    [onSelectionChange]
  );

  const multiSelectionProps = useMemo(
    () => ({
      selectedFields,
      onSelectionChange: setSelectedFields
    }),
    [selectedFields]
  );

  const onRemoveField = useCallback(
    (fieldIdx: number) => {
      const newFields = selectedFields.filter((_, idx) => idx !== fieldIdx);
      onSelectionChange(newFields);
      setSelectedFields(newFields);
      setOpen(false);
    },
    [onSelectionChange, selectedFields]
  );

  const ref = useRef<HTMLDivElement>();
  const selectionAreaRef = useRef<HTMLDivElement>();

  const maxSelectionWidth = selectionAreaRef?.current?.getBoundingClientRect()?.width;

  const selectionJsx = useMemo(() => {
    if (!selectedFields?.length) {
      const placeholder = defPlaceholder ? defPlaceholder : isMulti ? "Select Fields" : "Select Field";
      return <VerticallyCenteredRow className="inc-label-common">{placeholder}</VerticallyCenteredRow>;
    }

    if (!isMulti) {
      return <>{FieldPickerUtils.getUserServiceFieldLabel(selectedFields[0])}</>;
    }

    let leftOverWidth = maxSelectionWidth;
    const pillsJsxArr: JSX.Element[] = [];

    const numSelectedFields = selectedFields.length;
    for (let idx = 0; idx < numSelectedFields; idx++) {
      const field = selectedFields[idx];

      const label = FieldPickerUtils.getUserServiceFieldLabel(field);
      const key = `${label}-${idx}`;
      const currentPillSize = 16 + getStringPossibleWidth(label, 12).width + (readOnly ? 24 : 0);

      if (currentPillSize > leftOverWidth) {
        pillsJsxArr.push(
          <VerticallyCenteredRow
            className="field-picker-v2--pill flex-gap-8"
            key={key}
          >
            <IncSmartText text={`+${numSelectedFields - idx} more`} />
          </VerticallyCenteredRow>
        );
        break;
      } else {
        pillsJsxArr.push(
          <VerticallyCenteredRow
            className="field-picker-v2--pill flex-gap-8"
            key={key}
          >
            {label}
            {!readOnly && (
              <IncFaIcon
                className="inc-cursor-pointer"
                iconName="x"
                onClick={() => onRemoveField(idx)}
              />
            )}
          </VerticallyCenteredRow>
        );
        leftOverWidth -= currentPillSize;
      }
    }

    return pillsJsxArr;
  }, [defPlaceholder, isMulti, maxSelectionWidth, onRemoveField, readOnly, selectedFields]);
  return (
    <>
      <div className="field-picker-v2">
        {Boolean(label) && (
          <VerticallyCenteredRow className="flex-gap-8 inc-label-common">
            {label}

            {Boolean(infoText) && (
              <IncToolTip
                titleText={infoText}
                variant="default"
              >
                <ISaxIcon iconName="InfoCircle" />
              </IncToolTip>
            )}

            {Boolean(errorText) && (
              <IncToolTip
                titleText={errorText}
                variant="error"
              >
                <IncFaIcon
                  className="status-danger"
                  iconName="exclamation-triangle"
                />
              </IncToolTip>
            )}
          </VerticallyCenteredRow>
        )}

        <VerticallyCenteredRow
          className="flex-gap-8 field-picker-v2--fields"
          onClick={() => setOpen(true)}
          ref={ref}
        >
          <VerticallyCenteredRow
            className="field-picker-v2--selection"
            ref={selectionAreaRef}
          >
            {selectionJsx}
          </VerticallyCenteredRow>

          {!readOnly && (
            <IncFaIcon
              className="collapse-arrow"
              data-expanded={open}
              iconName="caret-down"
            />
          )}
        </VerticallyCenteredRow>

        {children}
      </div>

      {open && (
        <FieldPickerPopUpV2
          anchorEl={ref.current}
          className={fieldPickerPopupClassName}
          containerClassName={fieldPickerPopupContainerClassName}
          multiSelectionProps={multiSelectionProps}
          onClose={onClose}
          onSelect={onSelect}
          show
          {...restProps}
        />
      )}
    </>
  );
};
