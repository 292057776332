import { BizService } from "../explore/BizServiceCommon";
import { DrilldownTypes, TriageSummaryTypes } from "./types";

class TriageV2ApiService extends BizService {
  fetchTriageSummary(opId: string, incidentId: string, generateDemoData = false, forceRetry = false) {
    this.init();

    let subUrl = `triage/auto/${opId}/${incidentId}?generateDemoData=${generateDemoData}`;
    if (forceRetry) {
      subUrl = `${subUrl}&forceRetry=${forceRetry}`;
    }
    const url = this.getBizEntityUrl(subUrl);

    const request = () => this.datasource.post<TriageSummaryTypes.TriageStatusResponse, never>(url);
    return this.getResult(request);
  }

  fetchTriageSummaryStatus(opId: string, incidentId: string, pollId = "", generateDemoData = false) {
    this.init();
    let subUrl = `triage/auto/status/${opId}/${incidentId}?generateDemoData=${generateDemoData}`;
    if (pollId) {
      const encodedPollId = encodeURIComponent(pollId);
      subUrl += `&pollId=${encodedPollId}`;
    }
    const url = this.getBizEntityUrl(subUrl);

    const request = () => this.datasource.post<TriageSummaryTypes.TriageStatusResponse, never>(url);
    return this.getResult(request);
  }

  fetchCauseGraph(opId: string, incidentId: string) {
    this.init();

    const subUrl = `triage/causeGraph/${opId}/${incidentId}`;
    const url = this.getBizEntityUrl(subUrl);

    const request = () => this.datasource.post<DrilldownTypes.CauseGraph, never>(url);
    return this.getResult(request);
  }
}

export const triageV2ApiService = new TriageV2ApiService();
