import React, { useMemo, CSSProperties } from "react";
import { uniqWith, isEqual } from "lodash";
import { MonitoringFreqForPeriod } from "../../../services/api/operationalise";
import { TimeRange, generateId } from "../../../core";
import { getMillisFromTimeObj } from "../../../utils";
import { TimeObj } from "../../../services/api/explore";
import { getLabelForTimeObj } from "../../../utils/DurationUtils";
import { VerticallyCenteredRow } from "../../../components";

export const useMonitoringFrequencyRenderer = (
  monitoringFrequencies: MonitoringFreqForPeriod[],
  timeRange: TimeRange
) => {
  monitoringFrequencies = monitoringFrequencies || [];

  const uniqId = useMemo(() => generateId(), []);

  const sortedFrequencyRanges = useMemo(
    () => monitoringFrequencies.sort((a, b) => a.startTimeMillis - b.startTimeMillis),
    [monitoringFrequencies]
  );
  const { colorScale, frequencies } = useMemo(() => {
    let minFrequency = Number.POSITIVE_INFINITY;
    let maxFrequency = Number.NEGATIVE_INFINITY;

    let frequencies: TimeObj[] = [];

    monitoringFrequencies.forEach(freqEntry => {
      const { frequency } = freqEntry;
      const freqMillis = getMillisFromTimeObj(frequency);
      minFrequency = Math.min(minFrequency, freqMillis);
      maxFrequency = Math.max(maxFrequency, freqMillis);

      frequencies.push(frequency);
    });

    const colorScale = scaleLinear()
      .domain([minFrequency, maxFrequency])
      .range(["#D25D6C", "#F9A471", "#FFCF72", "#EFEBAB"] as any);

    frequencies = uniqWith(frequencies, isEqual);
    frequencies = frequencies.sort((a, b) => getMillisFromTimeObj(b) - getMillisFromTimeObj(a));

    return {
      colorScale,
      frequencies
    };
  }, [monitoringFrequencies]);

  const bars = useMemo(() => {
    const minMillis = timeRange.from.valueOf();
    const maxMillis = timeRange.to.valueOf();

    return sortedFrequencyRanges.map((freqEntry, idx) => {
      const { frequency, endTimeMillis, startTimeMillis } = freqEntry;

      const end = endTimeMillis > -1 ? endTimeMillis : maxMillis;
      const diff = end - startTimeMillis;
      const widthPer = (diff / (maxMillis - minMillis)) * 100;

      const freqMillis = getMillisFromTimeObj(frequency);
      const color = colorScale(freqMillis).toString();

      const style: CSSProperties = {
        ...baseStyles,
        backgroundColor: color,
        width: `${widthPer}%`
      };

      const key = [uniqId, startTimeMillis, idx].join("_");
      return (
        <div
          key={key}
          style={style}
        />
      );
    });
  }, [colorScale, sortedFrequencyRanges, timeRange.from, timeRange.to, uniqId]);

  const legends = useMemo(
    () =>
      frequencies.map((freq, idx) => {
        const freqMillis = getMillisFromTimeObj(freq);

        const label = getLabelForTimeObj(freq, "md", true);
        const color = colorScale(freqMillis).toString();

        const style: CSSProperties = {
          ...baseStyles,
          backgroundColor: color,
          width: 8,
          marginRight: 6
        };

        const key = [uniqId, freqMillis, idx].join("_");
        return (
          <VerticallyCenteredRow
            className="marginRt10"
            key={key}
          >
            <VerticallyCenteredRow style={style} />
            <VerticallyCenteredRow className="inc-text-inactive inc-text-element-medium">{label}</VerticallyCenteredRow>
          </VerticallyCenteredRow>
        );
      }),
    [colorScale, frequencies, uniqId]
  );

  return {
    bars,
    legends
  };
};

const baseStyles: CSSProperties = {
  height: 8,
  borderRadius: 2,
  marginRight: 2
};

const scaleLinear = function () {
  let _domain: [number, number] = [0, 0];
  let _range: string[] = [];

  return {
    domain: function (nDomain: [number, number]) {
      _domain = nDomain;
      return this;
    },
    range: function (nRange: string[]) {
      _range = nRange;
      return this.getColor;
    },
    getColor: function (value: number) {
      const diff = _domain[1] - _domain[0];
      const relativeValue = (value - _domain[0]) / diff;
      const idx = Math.floor(relativeValue * (_range.length - 1));
      return _range[idx] || _range[_range.length - 1];
    }
  };
};
