import React, { FC, useCallback, useMemo } from "react";
import { generateId } from "@inception/ui";
import {
  TemplateCanvasSection,
  SectionElementType,
  ActionTemplateElementsResponse
} from "../../../../../../services/api/operationalise";
import { SingleLineTemplateEditor } from "../../../../actions/template-editor-v2/editors";
import { VerticallyCenteredRow } from "../../../../../../components";

interface TemplateParamEditorWrapperProps {
  templateParams: string[];
  templateParamTokens: Record<string, string>;
  designerTemplate: ActionTemplateElementsResponse;
  filterOutLoopElements: boolean;
  isLoading: boolean;
  onTemplateParamTokensChange: (templateParamTokens: Record<string, string>) => void;
}

export const TemplateParamEditorWrapper: FC<TemplateParamEditorWrapperProps> = props => {
  const {
    templateParams,
    templateParamTokens,
    designerTemplate,
    isLoading,
    filterOutLoopElements,
    onTemplateParamTokensChange
  } = props;

  const sections = useMemo(() => {
    const sectionMap: Record<string, TemplateCanvasSection> = {};
    Object.keys(templateParamTokens).forEach(param => {
      sectionMap[param] = {
        sectionElementId: "rich-text-section",
        sectionId: generateId(),
        type: SectionElementType.RICH_TEXT,
        content: templateParamTokens[param] || ""
      };
    });
    return sectionMap;
  }, [templateParamTokens]);

  const onSectionChange = useCallback(
    (param: string, section: TemplateCanvasSection) => {
      if (templateParamTokens[param] === section.content) {
        return;
      }

      onTemplateParamTokensChange({
        ...templateParamTokens,
        [param]: section.content
      });
    },
    [onTemplateParamTokensChange, templateParamTokens]
  );

  const { textElements, elementGroups } = useMemo(
    () => ({
      textElements: designerTemplate?.textElement || [],
      elementGroups: designerTemplate?.elementGroup || []
    }),
    [designerTemplate]
  );

  return (
    <>
      {templateParams?.map(param => (
        <div
          className="marginBt4 paddingLt8"
          key={param}
        >
          <VerticallyCenteredRow className="inc-label-common marginBt4">{param}</VerticallyCenteredRow>
          <SingleLineTemplateEditor
            elementGroups={elementGroups}
            filterOutLoopElements={filterOutLoopElements}
            isLoading={isLoading}
            onChange={section => onSectionChange(param, section)}
            section={sections[param]}
            textElements={textElements}
          />
        </div>
      ))}
    </>
  );
};
