import React, { useCallback, useMemo } from "react";
import { IncButton, IncModal, IncSmartText, IncTextArea } from "@inception/ui";
import { VerticallyCenteredRow } from "../../components";
import { useToggleState } from "../../core";
import { setAnalysisTemplateFormat, useOpAnalysisStore } from "./context";
import StoryTemplateEditor from "./StoryTemplateEditor";

const TemplateFormatEditor = () => {
  const { state, dispatch } = useOpAnalysisStore();
  const { opAnalysisImpl } = state;
  const templateFormat = useMemo(() => opAnalysisImpl.getTemplateFormat(), [opAnalysisImpl]);
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setAnalysisTemplateFormat(e.target.value));
    },
    [dispatch]
  );

  const { close, isOpen, open } = useToggleState();

  return (
    <div className="analysis-editor--template-editor">
      <VerticallyCenteredRow>
        <IncSmartText
          className="marginRt12"
          text="Template Format"
        />
        <IncButton
          className={"marginLtAuto"}
          color={"link"}
          onClick={open}
        >
          See Advance Options
        </IncButton>
      </VerticallyCenteredRow>
      <IncTextArea
        containerClassName={"width-100"}
        onChange={onChange}
        placeholder={"Enter template format"}
        value={templateFormat || ""}
      />
      <IncModal
        contentClassName="flex-gap-16 paddingLt16"
        onClose={close}
        show={isOpen}
        size="lg"
        titleText={"Story Templates Editor"}
        withTitleBorder
      >
        {isOpen && <StoryTemplateEditor />}
      </IncModal>
    </div>
  );
};

export default TemplateFormatEditor;
