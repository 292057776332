import React, { FC, useCallback, useState, useEffect, useRef, useMemo } from "react";
import {
  IncButton,
  IncToolTip,
  IncFaIcon,
  IncPopper,
  IncSelect,
  IncSelectOption,
  IncTabButtons,
  IncDateTimePicker,
  Tab,
  IncSmartText
} from "@inception/ui";
import moment from "moment";
import { isArray } from "lodash";
import {
  AlertSnapshot,
  BizActionConfig,
  OpCreationConfig,
  operationaliseV2ApiService,
  useFetchIncidentsData
} from "../../../../../services/api/operationalise";
import { VerticallyCenteredRow, LoadingSpinner } from "../../../../../components";
import { OpMode } from "../../../../types";
import { dateTime, logger } from "../../../../../core";
import timeRangeUtils from "../../../../../utils/TimeRangeUtils";
import { TimeRangeSelector } from "../../../../../components/time-range";

interface Props {
  errors: string[];
  bizActionConfig: BizActionConfig;
  opCreationConfig: OpCreationConfig;
  op10zeId: string;
  mode: OpMode;
  supportsTestActionWithRealAlertOnly?: boolean;
}

function sortTime(keyName: string, reverse?: boolean) {
  const multiplier = reverse ? -1 : 1;
  return (a: any, b: any) => {
    const aValue = a[keyName] as unknown as string;
    const bValue = b[keyName] as unknown as string;
    return multiplier * (parseInt(bValue, 10) - parseInt(aValue, 10));
  };
}

type AlertSnapshotOption = IncSelectOption<AlertSnapshot>;

type TestActionType = "existing" | "generated";
const defaultGeneratedDate = () => new Date(Date.now() - 60 * 60 * 1000);

export const TestActionRenderer: FC<Props> = props => {
  const { bizActionConfig, errors, opCreationConfig, op10zeId, supportsTestActionWithRealAlertOnly, mode } = props;

  const [showPopover, setShowPopover] = useState(false);
  const [testInProgress, setTestInProgress] = useState(false);
  const [testActionType, setTestActionType] = useState<TestActionType>("existing");
  const isExistingIncidentTest = useMemo(() => testActionType === "existing", [testActionType]);
  const isGeneratedIncidentTest = useMemo(() => testActionType === "generated", [testActionType]);
  const testActionTypeTabs: Tab[] = useMemo(
    () => [
      {
        id: "existing",
        label: "Existing",
        disable: mode === "create"
      },
      {
        id: "generated",
        label: "Generated",
        disable: supportsTestActionWithRealAlertOnly
      }
    ],
    [mode, supportsTestActionWithRealAlertOnly]
  );

  useEffect(() => {
    if (showPopover) {
      if (mode === "create" && !supportsTestActionWithRealAlertOnly) {
        setTestActionType("generated");
      } else {
        setTestActionType("existing");
      }
    }
  }, [mode, showPopover, supportsTestActionWithRealAlertOnly]);

  const [generatedTestActionDate, setGeneratedTestActionDate] = useState<Date>(defaultGeneratedDate());
  const onChangeGeneratedDate = useCallback((date: Date) => {
    setGeneratedTestActionDate(date);
  }, []);
  const onChangeRawGeneratedDate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (timeRangeUtils.isValidTime(val, "dd-MM-yyyy HH:mm:ss")) {
      setGeneratedTestActionDate(dateTime(val, undefined, "DD-MM-yyyy HH:mm:ss").toDate());
    }
  }, []);

  const onChangeActionType = useCallback((actionType: string) => {
    setTestActionType(actionType as TestActionType);
  }, []);

  const [testMessage, setTestMessage] = useState("");
  const [testError, setTestError] = useState("");
  const [incidentOptions, setIncidentOptions] = useState<IncSelectOption[]>([]);
  const [snapshotsOptions, setSnapshotsOptions] = useState<AlertSnapshotOption[]>([]);
  const [selectedAlertOpt, setSelectedAlertOpt] = useState<IncSelectOption>();
  const [selectedSnapshotOpt, setSelectedSnapshotOpt] = useState<AlertSnapshotOption>();

  const { value: selectedAlertValue } = selectedAlertOpt || {};
  const { data: selectedSnapshot } = selectedSnapshotOpt || {};

  const defTimeRange = useMemo(() => {
    const { opCreationConfigDef } = opCreationConfig || {};
    const { schedule } = opCreationConfigDef?.schedule || {};
    const { startTimeEpochSecs, endTimeEpochSecs } = schedule || {};
    const startTimeEpoch = Number(startTimeEpochSecs) * 1000;
    const endTimeEpoch = Number(endTimeEpochSecs) * 1000;

    const rawStartTime = startTimeEpoch ? String(startTimeEpoch) : "now-1M";
    const rawEndTime = endTimeEpoch ? String(endTimeEpoch) : "now";
    return timeRangeUtils.getTimeRangeFromRaw({
      from: rawStartTime,
      to: rawEndTime
    });
  }, [opCreationConfig]);

  const [timeRange, setTimeRange] = useState(defTimeRange);
  const { toMillis: endTimeMillis, fromMillis: startTimeMillis } = useMemo(
    () => timeRangeUtils.getMillisFromTimeRange(timeRange),
    [timeRange]
  );

  const popUpRef = useRef<HTMLButtonElement>(null);

  const { data, error, isError, isFetching, isSuccess, refetch } = useFetchIncidentsData(
    op10zeId,
    startTimeMillis,
    endTimeMillis,
    true,
    true
  );

  useEffect(() => {
    if (isError) {
      logger.error("TestActionRenderer", "Error fetching incidents", error);
    }
  }, [error, isError]);

  useEffect(() => {
    if (op10zeId && mode === "edit" && isExistingIncidentTest) {
      refetch();
    }
  }, [mode, op10zeId, refetch, isExistingIncidentTest, startTimeMillis, endTimeMillis]);

  useEffect(() => {
    setTestMessage("");
    setTestError("");
  }, [bizActionConfig]);

  const testAlert = useCallback(async () => {
    setTestInProgress(true);
    setTestMessage("");
    setTestError("");

    try {
      const alertId = isExistingIncidentTest ? selectedAlertValue : undefined;
      const snapshot = isExistingIncidentTest ? selectedSnapshot : undefined;
      const alertMillis = isGeneratedIncidentTest ? generatedTestActionDate.getTime() : undefined;

      const { data, error, message } = await operationaliseV2ApiService.testBizAction(
        op10zeId,
        opCreationConfig,
        bizActionConfig,
        alertId,
        snapshot,
        alertMillis
      );

      if (error) {
        const msgObject = message as unknown as Record<string, any>;

        let errMessage = message;
        if (msgObject.message) {
          const msgArray = isArray(msgObject.message) ? msgObject.message : [msgObject.message];
          errMessage = msgArray.filter(Boolean).join("\n");
        } else if (isArray(message) && message.join) {
          errMessage = message.join("\n");
        }

        setTestError(errMessage.toString());
      } else {
        const { message } = data;
        setTestMessage(message.join("\n"));
      }
    } catch (err) {
      setTestError(err.message.toString());
    } finally {
      setTestInProgress(false);
    }
  }, [
    isExistingIncidentTest,
    selectedAlertValue,
    selectedSnapshot,
    isGeneratedIncidentTest,
    generatedTestActionDate,
    op10zeId,
    opCreationConfig,
    bizActionConfig
  ]);

  useEffect(() => {
    if (!isFetching && isSuccess && data) {
      const alertEventRecords = (data.alertResponse?.alertEventRecords || []).sort(sortTime("startTimeMillis")) || [];
      const options = alertEventRecords.map(record => {
        const startTimeMillis = record.startTimeMillis as unknown as string;
        const date = moment(parseInt(startTimeMillis, 10)).format("YYYY-MM-DD, HH:mm");
        return {
          label: `${date} ${record.status}`,
          value: record.id
        };
      });
      const snapshotOptions = getSnapshotOptions(alertEventRecords[0]?.snapshot?.snapshot);
      setSelectedAlertOpt(options[0]);
      setSelectedSnapshotOpt(snapshotOptions[0]);
      setIncidentOptions(options);
      setSnapshotsOptions(snapshotOptions);
    }
  }, [data, isSuccess, isFetching]);

  const onPopoverToggle = () => {
    setShowPopover(prev => !prev);
  };

  const getSnapshotOptions = (snapshots: AlertSnapshot[] = []) =>
    snapshots.sort(sortTime("alertLifecycleTimeMillis", true)).map(ss => {
      const date = moment(parseInt(ss.alertLifecycleTimeMillis, 10)).format("YYYY-MM-DD, HH:mm");
      const label = ss.alertLifecycleState === "updated" ? `${date} ${ss.alertLifecycleState}` : ss.alertLifecycleState;
      return {
        value: ss.alertLifecycleTimeMillis,
        label: label,
        data: ss
      };
    });

  const onIncidentSelect = useCallback(
    (value: IncSelectOption) => {
      const alertEventRecords = data?.alertResponse?.alertEventRecords || [];
      const incident = alertEventRecords.find(record => record.id === value.value);
      const snapshots = incident?.snapshot?.snapshot || [];
      const snapshotOptions = getSnapshotOptions(snapshots);
      setSnapshotsOptions(snapshotOptions);
      setSelectedSnapshotOpt(snapshotOptions[0]);
      setSelectedAlertOpt(value);
    },
    [data]
  );

  const canTest = !errors?.length && !testInProgress && (!supportsTestActionWithRealAlertOnly || mode === "edit");

  return (
    <VerticallyCenteredRow className="test-action-renderer">
      {Boolean(testError) && (
        <IncToolTip
          placement="top"
          titleText={testError}
          variant="error"
        >
          <VerticallyCenteredRow className="status-danger">
            <IncFaIcon iconName="warning" />
          </VerticallyCenteredRow>
        </IncToolTip>
      )}

      {Boolean(testMessage) && (
        <IncToolTip
          placement="top"
          titleText={testMessage}
          variant="success"
        >
          <VerticallyCenteredRow className="status-success">
            <IncFaIcon iconName="check-circle" />
          </VerticallyCenteredRow>
        </IncToolTip>
      )}

      <IncButton
        className="marginLt12"
        color="primary"
        disabled={!canTest}
        onClick={onPopoverToggle}
        ref={popUpRef}
      >
        {testInProgress && !showPopover && <LoadingSpinner titleText="Test in progress..." />}
        {(!testInProgress || showPopover) && "Test action"}
      </IncButton>

      <IncPopper
        anchorEl={popUpRef as any}
        className="test-action-incident-popover"
        placement="bottom-end"
        show={showPopover}
      >
        <>
          <VerticallyCenteredRow className="marginBt12 flex-gap-12">
            <VerticallyCenteredRow className="inc-label-common">Incidents for</VerticallyCenteredRow>

            <TimeRangeSelector
              buttonDisplay
              onTimeSelect={setTimeRange}
              size="small"
              timeRange={timeRange}
            />
          </VerticallyCenteredRow>
          <IncTabButtons
            onChange={onChangeActionType}
            selectedTabId={testActionType}
            tabs={testActionTypeTabs}
          />
          <div className="marginTp24 marginBt24 width-100">
            {isExistingIncidentTest && (
              <>
                <IncSmartText
                  className="marginBt16"
                  text="Test with existing incident"
                />
                <IncSelect
                  autoSort={false}
                  hasError={isError}
                  isLoading={isFetching}
                  label="Select Incident"
                  onChange={onIncidentSelect}
                  options={incidentOptions}
                  value={selectedAlertOpt}
                />

                <IncSelect
                  autoSort={false}
                  hasError={isError}
                  isLoading={isFetching}
                  label="Select Lifecycle state"
                  onChange={setSelectedSnapshotOpt}
                  options={snapshotsOptions}
                  value={selectedSnapshotOpt}
                />
              </>
            )}
            {isGeneratedIncidentTest && (
              <div style={{ minWidth: "280px" }}>
                <IncSmartText
                  className="marginBt16"
                  text="Test with generated incident"
                />
                <IncDateTimePicker
                  label={"Select Incident Time"}
                  maxDate={new Date()}
                  onChangeRaw={onChangeRawGeneratedDate}
                  onSelect={onChangeGeneratedDate}
                  portalId="root-portal"
                  value={generatedTestActionDate}
                />
              </div>
            )}
          </div>

          <VerticallyCenteredRow className="inc-flex-space-contents">
            <IncButton
              className="inc-flex-center width-50 marginRt16"
              color="primary"
              disabled={testInProgress}
              onClick={testAlert}
            >
              {testInProgress && <LoadingSpinner titleText="Testing..." />}
              {!testInProgress && "Test action"}
            </IncButton>
            <IncButton
              className="inc-flex-center width-50"
              color="secondary"
              disabled={testInProgress}
              onClick={() => setShowPopover(false)}
            >
              Close
            </IncButton>
          </VerticallyCenteredRow>
        </>
      </IncPopper>
    </VerticallyCenteredRow>
  );
};
