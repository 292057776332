import { IncButton } from "@inception/ui";
import React, { FC, memo } from "react";
import { useToggleState } from "../../core";
import { OpCreationConfig } from "../../services/api/operationalise";
import { SubscribeDialogV2 } from "./SubscribeDialog";
import { SubscribeDialogForOperationalize } from "./SubscribeDialogForOperationalize";

interface Props {
  title: string;

  buttonColor?: "secondary-blue" | "primary" | "secondary-green";
  buttonText?: string;
  buttonClassName?: string;

  opCreationConfig?: OpCreationConfig;
  useCaseId?: string;
}

export const SubscribeDialogV2WithButton: FC<Props> = memo(props => {
  const {
    buttonColor = "secondary-blue",
    buttonText = "Subscribe",
    buttonClassName = "",
    title,
    opCreationConfig,
    useCaseId
  } = props;

  const { close, isOpen, open } = useToggleState();

  return (
    <>
      <IncButton
        className={buttonClassName}
        color={buttonColor}
        onClick={open}
      >
        {buttonText}
      </IncButton>

      {isOpen && (
        <>
          {!opCreationConfig && (
            <SubscribeDialogV2
              onClose={close}
              title={title}
            />
          )}

          {Boolean(opCreationConfig) && (
            <SubscribeDialogForOperationalize
              onClose={close}
              opCreationConfig={opCreationConfig}
              title={title}
              useCaseId={useCaseId}
            />
          )}
        </>
      )}
    </>
  );
});
