import { getFormattedDateTime, IncDateTimeFormat, IncMenu, IncMenuItemProps } from "@inception/ui";
import { Chart, ChartSelectionContextObject } from "highcharts";
import React, { FC, useCallback, useEffect, useState } from "react";
import { dateTime, useTimeRange } from "../../core";
import { RawTimeRange } from "../../core/hooks/time-range/types";
import { ChartSelectionAction } from "./types";

export interface TimeseriesSelectionData {
  chart: Chart;
  event: ChartSelectionContextObject;
  from: number;
  to: number;
  isActive: boolean;
  x: number;
  y: number;
}

export const defaultSelectionData: TimeseriesSelectionData = {
  isActive: false,
  chart: undefined,
  event: undefined,
  from: -1,
  to: -1,
  x: 0,
  y: 0
};

interface Props {
  selectionData: TimeseriesSelectionData;
  onSelectionDataChange: (nSelection: TimeseriesSelectionData) => void;
  chartSelectionActions: ChartSelectionAction[];
}

const TimeseriesChartSelectionMenu: FC<Props> = props => {
  const { selectionData, onSelectionDataChange, chartSelectionActions = [] } = props;

  const [showMenu, setShowMenu] = useState(false);
  const { setTimeRange } = useTimeRange();

  useEffect(() => {
    setShowMenu(selectionData.isActive);
  }, [selectionData.isActive]);

  const formatTime = useCallback(
    (millis: number) =>
      getFormattedDateTime(dateTime(millis).startOf("minute").valueOf(), IncDateTimeFormat.minimal, {
        withSeconds: true
      }),
    []
  );

  const resetSelectionData = useCallback(() => {
    onSelectionDataChange({ ...defaultSelectionData });
    setShowMenu(false);
  }, [onSelectionDataChange]);

  const onZoom = useCallback(() => {
    const { isActive, chart, event } = selectionData;
    if (isActive && chart && event) {
      (chart as any).zoom(event);
      resetSelectionData();
    }
  }, [resetSelectionData, selectionData]);

  const actionHandler = useCallback(
    (actHandler: (start: number, end: number) => void) => {
      const { isActive, from, to } = selectionData;
      if (isActive && from > 0 && to > 0) {
        if (actHandler) {
          actHandler(dateTime(from).startOf("minute").valueOf(), dateTime(to).startOf("minute").valueOf());
        }
        resetSelectionData();
      }
    },
    [resetSelectionData, selectionData]
  );

  const changeGlobalTimeRange = useCallback(() => {
    const { isActive, from, to } = selectionData;
    if (isActive && from > 0 && to > 0) {
      const tr: RawTimeRange = {
        from: from.toString(),
        to: to.toString()
      };
      setTimeRange(tr, true);
      resetSelectionData();
    }
  }, [resetSelectionData, selectionData, setTimeRange]);

  const selectionToggleElement = useCallback(() => <div style={{ visibility: "hidden" }} />, []);

  const selectionMenuItems = useCallback(
    (itemProps: IncMenuItemProps) => {
      const { closeMenu, className } = itemProps;

      const { from, to } = selectionData;

      const timeStr = `${formatTime(from)} - ${formatTime(to)}`;

      const userActions = chartSelectionActions.map(selAct => {
        const { actionHandler: actHandler, id, label } = selAct;
        return (
          <div
            className={className}
            key={id}
            onClick={e => {
              actionHandler(actHandler);
              closeMenu(e as any);
            }}
            tabIndex={1}
          >
            <span>{label}</span>
          </div>
        );
      });

      return (
        <>
          <div className="selected-time-range">
            <div className="inc-button-link">{timeStr}</div>
          </div>
          {userActions}
          <div
            className={className}
            onClick={e => {
              changeGlobalTimeRange();
              closeMenu(e as any);
            }}
            tabIndex={1}
          >
            <span>Change global time range</span>
          </div>
          <div
            className={className}
            onClick={e => {
              onZoom();
              closeMenu(e as any);
            }}
            tabIndex={1}
          >
            <span>Zoom this series only</span>
          </div>
        </>
      );
    },
    [actionHandler, changeGlobalTimeRange, chartSelectionActions, formatTime, onZoom, selectionData]
  );

  return (
    <IncMenu
      className="inc-text-subtext-medium chart-selection-menu"
      items={selectionMenuItems}
      onToggle={resetSelectionData}
      show={showMenu}
      style={{
        left: selectionData.x,
        zIndex: 1050
      }}
      toggle={selectionToggleElement}
    />
  );
};

export default TimeseriesChartSelectionMenu;
