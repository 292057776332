import { defaultsDeep } from "lodash";
import { Visualisations } from "../../../../core";
import BaseWidgetImpl from "../../../model-impl/BaseWidgetImpl";
import { WidgetMeta } from "../../../models/BaseWidgetModel";
import { AUTO_DS_INTERVAL } from "../../utils";
import { DemoDataParams, SelectorSpec, UserServiceFilterList } from "../../../../services/api/explore";
import { CatalogWidgetModel, CatalogQueryConfig, CatalogWidgetProperties, CatalogWidgetRenderMode } from "./model";

const getDefaults = (): Partial<CatalogWidgetModel> => ({
  title: "New Catalog widget",
  type: "catalog",
  entityType: null,
  hideMappingControls: false,
  downsample: AUTO_DS_INTERVAL,
  disableUpdate: false,
  hashLabelsOnSave: false,
  compareInterval: null,
  metricsVisualOrder: [],
  properties: {
    disableCompare: true,
    timeseries: {
      connectNulls: false,
      pointRadius: 2,
      shape: "circle"
    },
    table: {
      metricHeaders: undefined,
      dateTimeFormat: undefined,
      formatterOptions: undefined,
      limitSpec: {
        function: "top",
        limit: 50
      },
      changeMetric: "current"
    },
    bar: {
      stacked: false,
      barsOrder: []
    },
    pinnedViz: [Visualisations.insights, Visualisations.timeseries],
    dataTypeCustomisation: {
      boolean: {
        falsy: {
          color: "#FF523B",
          label: "0",
          showAsPill: false,
          showStatusDot: false
        },
        truthy: {
          color: "#3BB443",
          label: "1",
          showAsPill: false,
          showStatusDot: false
        }
      },
      duration: {
        maxTerms: 2,
        precision: "MINUTES"
      }
    },
    negationColors: ["#3BB443", "#FF523B"]
  }
});

export const DASHBOARD_DEFAULT_COMPARE_INTERVAL = "__db_default_interval__";

export class CatalogWidgetImpl extends BaseWidgetImpl implements CatalogWidgetModel {
  userServiceId: string;
  entityType: string;
  bizEntityFieldName: string;
  widgetConfigRefId: string;
  widgetSelectorSpec: SelectorSpec;

  queryConfig: CatalogQueryConfig;
  dataQualityQueryConfig: CatalogQueryConfig;
  hideMappingControls: boolean;

  entityLookUp: Record<string, string>;
  eventTypeName: string;

  properties: CatalogWidgetProperties;
  renderMode: CatalogWidgetRenderMode;
  generateDemoData: boolean;
  disableUpdate: boolean;
  compareInterval: string;
  hashLabelsOnSave: boolean;
  metricUserServiceFilters?: Record<string, UserServiceFilterList>;
  demoDataParams: DemoDataParams;

  metricsVisualOrder: string[];

  type: "catalog" | "query-catalog";

  constructor(model: Partial<CatalogWidgetModel>, meta?: Partial<WidgetMeta>) {
    super(model, meta);
    this.assign(model, meta);
  }

  getCompareInterval(dbCompareInterval: string): string {
    if (this.compareInterval === DASHBOARD_DEFAULT_COMPARE_INTERVAL) {
      return dbCompareInterval;
    }
    return this.compareInterval;
  }

  assign(model: Partial<CatalogWidgetModel>, meta?: Partial<WidgetMeta>) {
    const cwModel: CatalogWidgetModel = defaultsDeep({ type: "catalog" }, model, getDefaults());
    super.assign(cwModel, meta);

    this.userServiceId = cwModel.userServiceId;
    this.entityType = cwModel.entityType;
    this.bizEntityFieldName = cwModel.bizEntityFieldName;
    this.widgetConfigRefId = cwModel.widgetConfigRefId;

    this.hideMappingControls = cwModel.hideMappingControls;

    this.queryConfig = cwModel.queryConfig;
    this.entityLookUp = cwModel.entityLookUp || {};
    this.eventTypeName = cwModel.eventTypeName;
    this.queryConfig = cwModel.queryConfig;
    this.renderMode = cwModel.renderMode || "view";
    this.disableUpdate = cwModel.disableUpdate || false;

    this.compareInterval = cwModel.compareInterval || null;
    this.generateDemoData = cwModel.generateDemoData || false;
    this.hashLabelsOnSave = cwModel.hashLabelsOnSave || false;

    const modelDsExists = this.downsample && this.downsample !== AUTO_DS_INTERVAL;
    const propDsExists = cwModel.properties?.downsample && cwModel.properties?.downsample !== AUTO_DS_INTERVAL;
    if (!modelDsExists && propDsExists) {
      this.downsample = cwModel.properties?.downsample;
    }

    if (this.properties.visualisation === Visualisations.singleStat) {
      this.properties.visualisation = Visualisations.sparkLine;
    }

    this.widgetSelectorSpec = cwModel.widgetSelectorSpec || {
      filters: [
        {
          tags: []
        }
      ]
    };

    this.metricsVisualOrder = cwModel.metricsVisualOrder || [];
  }

  setDemoDataParams(params: DemoDataParams) {
    this.demoDataParams = params;
  }

  getMetricId() {
    const sourceQueryConfig = this.queryConfig?.sourceQueryConfig;
    if (sourceQueryConfig?.queryType === "widgetConfig") {
      return sourceQueryConfig.metricId;
    }

    return "";
  }

  getSaveModel() {
    const model = super.getSaveModel() as CatalogWidgetModel;

    // Empty the querySchema before saving the model. Since this needs to be fetched every time
    if (
      model?.queryConfig?.sourceQueryConfig &&
      model.queryConfig.sourceQueryConfig.queryType === "widgetConfig" &&
      model.queryConfig.sourceQueryConfig.widgetResponse
    ) {
      model.queryConfig.sourceQueryConfig.widgetResponse.querySchema = {
        querySchema: []
      };

      if (model.queryConfig.sourceQueryConfig.widgetResponse.widgetId) {
        delete model.queryConfig.sourceQueryConfig.widgetResponse.widgetConfig;
      }
    }

    delete model.disableUpdate;

    return model;
  }
}
