import { IncInfoIcon, IncToolTip } from "@inception/ui";
import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

interface ScreenHeaderProps {
  titleId: string;
  subTitleId?: string;
  subTitleHelpTextId?: string;
  titleValues?: Array<string | number>;
  formattedMessage?: ReactElement;
}

const ScreenHeader: React.FC<ScreenHeaderProps> = (props: ScreenHeaderProps) => {
  const { formattedMessage, titleId, titleValues, subTitleId, subTitleHelpTextId } = props;

  const values: Record<string, string | number> = {};
  (titleValues || []).map((value, index) => (values[index] = value));

  return (
    <>
      {formattedMessage || (
        <div className="inc-flex-column">
          <div className="inc-text-header-medium">
            <FormattedMessage
              defaultMessage={"Missing Screen Header"}
              id={titleId}
              values={values}
            />
          </div>
          <div className="inc-flex-row">
            {subTitleId && (
              <div className="inc-text-subtext-medium">
                <FormattedMessage id={subTitleId} />
              </div>
            )}
            {subTitleHelpTextId && (
              <IncToolTip
                placement="top-start"
                titleId={subTitleHelpTextId}
              >
                <IncInfoIcon />
              </IncToolTip>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export { ScreenHeader };
