import { getInceptionTheme, IncSelectOption } from "@inception/ui";
import {
  WidgetPropertiesSection,
  WidgetPropertyTypes,
  WidgetColorProperty,
  WidgetNumberProperty,
  WidgetSelectProperty
} from "../../widget-properties/types";
import { TextWidgetProperties } from "./model";

const getTextPropertyPanelSections = (properties: TextWidgetProperties): WidgetPropertiesSection[] => {
  const {
    color = getInceptionTheme().inceptionColors.palette.N800,
    size = 14,
    caseStyle = "none",
    weight = 500,
    centerAlign: center = "none"
  } = properties;

  const colorProperty: WidgetColorProperty = {
    name: "color",
    label: "Color",
    value: color,
    type: WidgetPropertyTypes.color,
    placeholder: getInceptionTheme().inceptionColors.palette.N800,
    mode: "hex",
    defaultValue: getInceptionTheme().inceptionColors.palette.N800
  };

  const sizeProperty: WidgetNumberProperty = {
    name: "size",
    label: "Size (in px)",
    value: size,
    type: WidgetPropertyTypes.number,
    defaultValue: 14,
    placeholder: "14"
  };

  const weightProperty: WidgetNumberProperty = {
    name: "weight",
    label: "Weight",
    value: weight,
    type: WidgetPropertyTypes.number,
    defaultValue: 500,
    placeholder: "500"
  };

  const caseStyleProperty: WidgetSelectProperty = {
    name: "caseStyle",
    label: "Case",
    value: caseStyle,
    type: WidgetPropertyTypes.select,
    options: caseOptions
  };

  const centerProperty: WidgetSelectProperty = {
    name: "center",
    label: "Center align text",
    value: center,
    type: WidgetPropertyTypes.select,
    options: centerLayoutOptions
  };

  const section: WidgetPropertiesSection = {
    name: "Visualization",
    info: "Configure properties like color, size, weight, center-align and case style",
    properties: [colorProperty, sizeProperty, weightProperty, caseStyleProperty, centerProperty]
  };

  return [section];
};

export default getTextPropertyPanelSections;

const caseOptions: IncSelectOption[] = [
  {
    label: "None",
    value: "none"
  },
  {
    label: "Camel case",
    value: "camel"
  },
  {
    label: "Capitalize",
    value: "capitalize"
  },
  {
    label: "Lowercase",
    value: "lower"
  },
  {
    label: "Snake case",
    value: "snake"
  },
  {
    label: "Uppercase",
    value: "upper"
  }
];

const centerLayoutOptions: IncSelectOption[] = [
  {
    label: "None",
    value: "none"
  },
  {
    label: "Both",
    value: "both"
  },
  {
    label: "Horizontal",
    value: "horizontal"
  },
  {
    label: "Vertical",
    value: "vertical"
  }
];
