import { OpCreationConfig } from "../../operationalise";
import { LowLevelFragmentList, VizFragment } from "./low-level-fragment";

export enum GenerationFragmentType {
  UNSET = "UNSET",
  OP10ZE = "OP10ZE",
  WIDGET = "WIDGET"
}

export type GenerationFragment = {
  fragmentType: GenerationFragmentType;

  // oneOf
  opCreationConfig?: OpCreationConfig;
  vizFragment?: VizFragment;

  // KPIs, dimensions context
  lowLevelFragmentList?: LowLevelFragmentList;
};
