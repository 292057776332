import React, { FC, useMemo, useCallback, useEffect, useState, useRef } from "react";
import { isEqual, forEach } from "lodash";
import timeRangeUtils from "../../../../../../utils/TimeRangeUtils";
import { BizEntityTableWithStats, ChangeMetric } from "../../../../../../biz-entity";
import {
  ENTITY_TAG,
  eventFieldUtils,
  NameEntityFieldLabel,
  downloadBlobFile,
  getDownSampleInterval
} from "../../../../../../utils";
import { CatalogVizRendererProps, getFormattedValue, useCommonRendererFunctionality } from "../common";
import { logger } from "../../../../../../core/logging/Logger";
import { WidgetCustomAction } from "../../../../types";
import {
  exploreApiService,
  DownloadOptions,
  OverTagPostAgg,
  LimitSpecFunction
} from "../../../../../../services/api/explore";
import kbn from "../../../../../../services/datasources/core/kbn";
import { ExploreQueryType } from "../../../../../../services/datasources/explore/types";
import {
  getSelectorSpecAndLabelToTagsMap,
  constructSingleStatData,
  getInitialHeaders,
  getInitialCompareChangeHeaders
} from "./utils";
import { useFetchExtData } from "./hooks";
import { ChangeMetricsRenamer } from "./ChangeMetricsRenamer";
import { TimeseriesModal } from "./TimeseriesModal";
import { DownloadCSVModel } from "./DownloadCSVModel";

export const InsightsRenderer: FC<CatalogVizRendererProps> = props => {
  const {
    dataType,
    loadingElement,
    noDataElement,
    timeRange,
    compareTimeRange,
    entityTypeName,
    currencyType,
    properties,
    aggregatedTags,
    displayAggregatedTags,
    limitSpecFunction,
    aggregateDataType,
    existingEntityTypeFilters,
    onAddAdhocEntityFilter,
    onCustomActionsChange,
    renderMode,
    widgetResponseDTO,
    postAggProjections,
    entityFilters,
    eventFilters,
    downsample,
    cohortFilters
  } = props;

  const { widgetConfig, widgetId } = widgetResponseDTO || {};

  const compareTimeStr = useMemo(
    () => timeRangeUtils.getCompareTimeStr(timeRange.from.valueOf(), compareTimeRange.from.valueOf()),
    [compareTimeRange.from, timeRange.from]
  );

  const hideCompareData = postAggProjections?.length === 1 && postAggProjections[0] === "current";

  const {
    changeMetric: pChangeMetric = "current",
    changeMetricHeaders: pChangeMetricHeaders,
    compareChangeMetricHeaders: pCompareChangeMetricHeaders
  } = properties?.insights || {};

  const changeMetric: ChangeMetric = hideCompareData ? pChangeMetric : "deltaPercentage";
  const widgetName = widgetResponseDTO.widgetConfig.name;
  const fileName = `${widgetName}`.replace("/", "-");

  const {
    data: entityWidgetData,
    isFetching,
    isError,
    fieldName,
    metricName,
    hasChildMetrics,
    downloadDataPayload
  } = useCommonRendererFunctionality({
    ...props,
    metricType: changeMetric
  });

  const initChangeMetricHeaders = getInitialHeaders(pChangeMetricHeaders, widgetResponseDTO, hasChildMetrics);
  const initCompareChangeMetricHeaders = getInitialCompareChangeHeaders(
    pCompareChangeMetricHeaders,
    initChangeMetricHeaders,
    compareTimeStr
  );

  const [changeMetricHeaders, setChangeMetricHeaders] = useState<Record<string, string>>(initChangeMetricHeaders);
  const [compareChangeMetricHeaders, setCompareChangeMetricHeaders] = useState(initCompareChangeMetricHeaders);

  const [tags, setTags] = useState<Record<string, string>>();
  const [showDownloadCSV, setShowDownloadCSV] = useState(false);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const openDownloadCSVModel = useCallback(() => setShowDownloadCSV(true), []);
  const closeDownloadCSVModal = useCallback(() => setShowDownloadCSV(false), []);
  const titleRef = useRef("");

  useEffect(() => {
    const initChangeMetricHeaders = getInitialHeaders(pChangeMetricHeaders, widgetResponseDTO, hasChildMetrics);

    setChangeMetricHeaders(prevHeaders =>
      !isEqual(prevHeaders, initChangeMetricHeaders) ? initChangeMetricHeaders : prevHeaders
    );
  }, [hasChildMetrics, pChangeMetricHeaders, widgetResponseDTO]);

  useEffect(() => {
    const initChangeMetricHeaders = getInitialHeaders(pChangeMetricHeaders, widgetResponseDTO, hasChildMetrics);
    const initCompareChangeMetricHeaders = getInitialCompareChangeHeaders(
      pCompareChangeMetricHeaders,
      initChangeMetricHeaders,
      compareTimeStr
    );

    setCompareChangeMetricHeaders(prevHeaders =>
      !isEqual(prevHeaders, initCompareChangeMetricHeaders) ? initCompareChangeMetricHeaders : prevHeaders
    );
  }, [compareTimeStr, hasChildMetrics, pChangeMetricHeaders, pCompareChangeMetricHeaders, widgetResponseDTO]);

  const resetTags = useCallback(() => setTags(null), []);
  const onChangeRendererClick = useCallback((tags: Record<string, string>, label: string) => {
    titleRef.current = label;
    setTags(tags);
  }, []);

  const isAggregatedSeries = aggregatedTags?.length === 0;
  const isSingleAgg = aggregatedTags?.length === 1;
  const isImplicitSliceAgg = isSingleAgg && aggregatedTags[0] === ENTITY_TAG;
  const isEntityFieldAgg = isSingleAgg && aggregateDataType === "ENTITY";
  const entityColumnKeys = useMemo(
    () => (isEntityFieldAgg ? [aggregatedTags[0]] : []),
    [aggregatedTags, isEntityFieldAgg]
  );

  const displayFieldName = eventFieldUtils.removeFieldsPrefix(fieldName);
  const { columnHeaders, columnAccessors } = useMemo(() => {
    const columnHeaders = isAggregatedSeries
      ? []
      : isEntityFieldAgg
        ? isImplicitSliceAgg
          ? [entityTypeName]
          : [displayFieldName]
        : displayAggregatedTags?.length
          ? displayAggregatedTags
          : [displayFieldName];
    const columnAccessors = isAggregatedSeries ? ["metricName"] : aggregatedTags;

    return {
      columnHeaders,
      columnAccessors
    };
  }, [
    aggregatedTags,
    displayAggregatedTags,
    displayFieldName,
    entityTypeName,
    isAggregatedSeries,
    isEntityFieldAgg,
    isImplicitSliceAgg
  ]);

  const { selectorSpec, aggLabelVsMatchTags } = useMemo(
    () => getSelectorSpecAndLabelToTagsMap(entityWidgetData[0], aggregatedTags, displayAggregatedTags, metricName),
    [aggregatedTags, displayAggregatedTags, entityWidgetData, metricName]
  );

  const parentDataFetching = isFetching || !entityWidgetData?.length || !entityWidgetData?.[0]?.postAggDataSize;

  const {
    isFetching: isExtDataFetching,
    data: extData,
    isError: isExtDataError,
    error: extDataError,
    changeMetricHeaders: fChangeMetricHeaders,
    compareChangeMetricHeaders: fCompareChangeMetricHeaders,
    displayCompareChangeMetricHeaders,
    changeMetrics,
    displayChangeMetricHeaders
  } = useFetchExtData({
    ...props,
    selectorSpec,
    parentDataFetching,
    changeMetricHeaders,
    compareChangeMetricHeaders,
    changeMetric,
    hasChildMetrics
  });

  useEffect(() => {
    properties.insights = {
      ...(properties.insights || ({} as any)),
      changeMetricHeaders
    };
  }, [changeMetricHeaders, properties]);

  useEffect(() => {
    properties.insights = {
      ...(properties.insights || ({} as any)),
      compareChangeMetricHeaders
    };
  }, [compareChangeMetricHeaders, properties]);

  useEffect(() => {
    if (!isEqual(fChangeMetricHeaders, changeMetricHeaders)) {
      setChangeMetricHeaders(fChangeMetricHeaders);
    }
  }, [fChangeMetricHeaders, changeMetricHeaders]);

  useEffect(() => {
    if (!isEqual(fCompareChangeMetricHeaders, compareChangeMetricHeaders)) {
      setCompareChangeMetricHeaders(fCompareChangeMetricHeaders);
    }
  }, [fCompareChangeMetricHeaders, compareChangeMetricHeaders]);

  const renameMetricsAction = useMemo(
    () => (
      <ChangeMetricsRenamer
        changeMetricHeaders={changeMetricHeaders}
        onChange={setChangeMetricHeaders}
        querySchemaResponse={widgetResponseDTO?.querySchema}
      />
    ),
    [changeMetricHeaders, widgetResponseDTO]
  );

  useEffect(() => {
    if (renderMode === "view") {
      const renameHeadersAction: WidgetCustomAction = {
        showInHeader: true,
        actionComponent: renameMetricsAction,
        tooltipText: "Edit metric headers"
      };

      onCustomActionsChange([renameHeadersAction]);

      return () => {
        onCustomActionsChange([]);
      };
    }
  }, [onCustomActionsChange, renameMetricsAction, renderMode]);

  useEffect(() => {
    if (extDataError) {
      logger.error("InsightsRenderer", "Error fetching component metrics data", extDataError);
    }
  }, [extDataError]);

  const { data, compareData, dataExists } = useMemo(
    () =>
      constructSingleStatData(
        entityWidgetData[0],
        extData,
        aggLabelVsMatchTags,
        aggregatedTags,
        limitSpecFunction,
        metricName
      ),
    [aggLabelVsMatchTags, aggregatedTags, entityWidgetData, extData, limitSpecFunction, metricName]
  );

  const valueFormatter = useCallback(
    (value: number, changeType: "absolute" | "percent" | "none") => {
      const formattedValue = getFormattedValue(fieldName, value, dataType, currencyType);

      if (changeType === "percent") {
        return `${formattedValue} %`;
      }

      return formattedValue;
    },
    [currencyType, dataType, fieldName]
  );

  const onAggTagClick = useCallback(
    (value: string, entityType: string) => {
      onAddAdhocEntityFilter(NameEntityFieldLabel, value, entityType);
    },
    [onAddAdhocEntityFilter]
  );

  const downloadCSV = useCallback(
    async (func: LimitSpecFunction, value: number, fileName: string) => {
      closeDownloadCSVModal();
      setDownloadInProgress(true);
      const timeRangeMillis = {
        from: timeRange.from.valueOf(),
        to: timeRange.to.valueOf()
      };
      const intervalStr = getDownSampleInterval(downsample, timeRangeMillis);
      const intervalSecs = kbn.interval_to_seconds(intervalStr);
      const fEntityFilters = [...entityFilters, ...cohortFilters];
      const metricIdToName: Record<string, string> = {};

      forEach(widgetConfig?.dataDefinition?.metrics || {}, (val, key) => {
        metricIdToName[key] = val.name.replace("/", "-");
      });

      const tagToHeaderName: Record<string, string> = {};
      (downloadDataPayload.sliceSpec[0]?.postAgg as OverTagPostAgg)?.overTagAgg?.tagName?.forEach(x => {
        if (x === ENTITY_TAG) {
          tagToHeaderName[x] = entityTypeName;
        } else {
          tagToHeaderName[x] = x;
        }
      });

      const sliceSpecs = downloadDataPayload.sliceSpec;
      if (sliceSpecs?.[0]?.postAgg.sortSpec) {
        sliceSpecs[0].postAgg.sortSpec.limitSpec = {
          function: func,
          limit: value
        };
      }

      const downloadOptions: DownloadOptions = {
        fileName: `${fileName}.xlsx`,
        metricToHeaderName: metricIdToName,
        tagToHeaderName
      };

      const isSavedMetric = downloadDataPayload.mode === ExploreQueryType.saved;
      const response = await exploreApiService.downloadWidgetDataByConfig(
        entityTypeName,
        null,
        isSavedMetric ? widgetId : "",
        isSavedMetric ? null : widgetConfig,
        timeRange.from.valueOf(),
        timeRange.to.valueOf(),
        intervalSecs,
        downloadDataPayload.sliceSpec,
        fEntityFilters,
        downloadOptions,
        true,
        -1,
        eventFilters
      );
      setDownloadInProgress(false);
      downloadBlobFile(response.data, downloadOptions.fileName);
    },
    [
      closeDownloadCSVModal,
      timeRange.from,
      timeRange.to,
      downsample,
      entityFilters,
      cohortFilters,
      widgetConfig,
      downloadDataPayload.sliceSpec,
      downloadDataPayload.mode,
      entityTypeName,
      widgetId,
      eventFilters
    ]
  );

  const [defaultFunction, initialValue] = useMemo(() => {
    const { dataFetchPayload } = props;
    const limitSpec = dataFetchPayload?.sliceSpec[0]?.postAgg?.sortSpec?.limitSpec;
    return [limitSpec?.function || "top", limitSpec?.limit || 5];
  }, [props]);

  if (isFetching || isExtDataFetching) {
    return loadingElement;
  }

  if (!dataExists || isError || isExtDataError) {
    return noDataElement;
  }

  return (
    <>
      <BizEntityTableWithStats
        addCompareInfoAsColumns={!hideCompareData}
        aggTag={displayAggregatedTags}
        changeMetricHeaders={displayChangeMetricHeaders}
        changeMetrics={changeMetrics}
        columnAccessors={columnAccessors}
        columnHeaders={columnHeaders}
        compareChangeMetricHeaders={displayCompareChangeMetricHeaders}
        compareData={compareData}
        compareTimeStr={compareTimeStr}
        currencyType={currencyType}
        data={data}
        dataType={dataType}
        downloadInProgress={downloadInProgress}
        entityColumnKeys={entityColumnKeys}
        entityType={entityTypeName}
        existingEntityFilters={existingEntityTypeFilters}
        hideCompare={hideCompareData}
        hideHeader
        isError={false}
        isFetching={false}
        onChangeDataClick={onChangeRendererClick}
        onDataKeyClick={onAggTagClick}
        onDownloadClick={openDownloadCSVModel}
        useTitleAsTooltip
        valueFormatter={valueFormatter}
      />

      {Boolean(tags) && (
        <TimeseriesModal
          onClose={resetTags}
          rendererProps={props}
          tags={tags}
          title={titleRef.current}
        />
      )}

      <DownloadCSVModel
        defaultFunction={defaultFunction}
        fileName={fileName}
        initialValue={initialValue}
        onClose={closeDownloadCSVModal}
        onConfirmClick={downloadCSV}
        show={showDownloadCSV}
      />
    </>
  );
};
