import { HeatMapSvgProps } from "@nivo/heatmap";
import React from "react";
import { IncHeatMapAxisTickPropsFunction, IncHeatMapDatum } from "./types";

export const getHeatMapAxes = (getXAxisValue: IncHeatMapAxisTickPropsFunction, xAxisOnBottom: boolean) => {
  const yAxis: HeatMapSvgProps<IncHeatMapDatum, Record<string, string>>["axisLeft"] = {
    tickRotation: 0,
    ariaHidden: false,
    tickSize: 0
  };

  const xAxis: HeatMapSvgProps<IncHeatMapDatum, Record<string, string>>["axisTop"] = {
    tickRotation: 0,
    ariaHidden: false,
    tickSize: 0,
    renderTick:
      getXAxisValue ||
      (props => {
        const { textAnchor, textBaseline, x, value } = props;

        const transformStr = `translate(${x}, -6)`;

        return (
          <text
            dominantBaseline={textBaseline}
            fontSize={9}
            textAnchor={textAnchor}
            transform={transformStr}
          >
            {getXAxisValue ? getXAxisValue(value) : value}
          </text>
        );
      })
  };

  return {
    axisLeft: yAxis,
    axisTop: xAxisOnBottom ? null : xAxis,
    axisBottom: xAxisOnBottom ? xAxis : null
  };
};
