import React, { useCallback, useMemo } from "react";
import { IncSelect } from "@inception/ui";
import { AggregationUIOptions, MetricAggregationsRecord, MetricAggregations } from "../core";
import { TraceUIAggregation } from "../services/api/explore";

interface AggrSelectorProps {
  value: TraceUIAggregation;
  onChange: (aggr: TraceUIAggregation) => void;
  label?: string;
  hideLabel?: boolean;
  allowNone?: boolean;
  isDisabled?: boolean;
  className?: string;
  exclude?: MetricAggregations[];
  readOnly?: boolean;
}

const NoneOption: AggregationUIOptions = {
  label: "None",
  value: "" as any,
  type: null,
  fractions: null,
  longDisplayName: "None"
};

export const AggregationSelect: React.FC<AggrSelectorProps> = props => {
  const { value, onChange, allowNone, exclude, readOnly, isDisabled, className, hideLabel, label: pLabel } = props;

  const aggrOptions = useMemo(() => {
    const options = Object.values(MetricAggregationsRecord).filter(
      aggr => !exclude?.includes(aggr.label as MetricAggregations)
    );
    if (allowNone) {
      options.push(NoneOption);
    }
    return options;
  }, [allowNone, exclude]);

  const selectedAggr = useMemo(
    () =>
      aggrOptions.find(op => op.type === value?.aggrType && op.fractions === value?.fraction) ||
      (allowNone && NoneOption),
    [aggrOptions, value, allowNone]
  );

  const onAggrChange = useCallback(
    (op: AggregationUIOptions) => {
      onChange({
        aggrType: op.type,
        fraction: op.fractions
      });
    },
    [onChange]
  );

  const label = pLabel ?? "Aggregation";
  const menuPortalTarget = useMemo(() => document.body, []);

  return (
    <>
      <IncSelect<AggregationUIOptions, false>
        autoAdjustWidth
        autoSort={false}
        className={`${className} aggregation-select`}
        isDisabled={isDisabled}
        isSearchable={false}
        label={hideLabel ? null : label}
        menuPlacement="auto"
        menuPortalTarget={menuPortalTarget}
        onChange={onAggrChange}
        options={aggrOptions}
        placeholder=""
        readOnly={readOnly}
        value={selectedAggr}
      />
    </>
  );
};
