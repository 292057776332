import { useMemo, useRef } from "react";
import { CatalogWidgetUtils } from "../../../CatalogWidgetUtils";
import {
  WidgetResponseDTO,
  PostAggProjection,
  LimitSpecFunction,
  SliceSpec,
  SelectorSpec
} from "../../../../../../services/api/explore";
import { CatalogQueryConfig } from "../../../models";
import { VizToQueryConfig, TimeRange, useForceUpdate } from "../../../../../../core";
import { ChangeMetric } from "../../../../../../biz-entity";
import { CatalogWidgetFetchDataPayload } from "../../../types";

type DataFetchPayloadState = {
  dataFetchPayload: CatalogWidgetFetchDataPayload;
  childrenDataFetchPayload: CatalogWidgetFetchDataPayload;
};

export const useFetchQueryPayload = (
  widgetResponseDTO: WidgetResponseDTO,
  aggregatedTags: string[],
  queryConfig: CatalogQueryConfig,
  vizConfig: VizToQueryConfig,
  timeRange: TimeRange,
  compareTimeRange: TimeRange,
  widgetSelectorSpec: SelectorSpec,
  metricId: string,
  childMetricIds: string[],
  metricType: ChangeMetric,
  postAggProjections: PostAggProjection[],
  limitSpecFunction: LimitSpecFunction,
  seriesLimit: number,
  isSingleStatQuery?: boolean,
  downsample?: string
) => {
  const forceUpdate = useForceUpdate();
  const stateRef = useRef<DataFetchPayloadState>({
    dataFetchPayload: null,
    childrenDataFetchPayload: null
  });
  const querySchemaExists = Boolean(widgetResponseDTO?.querySchema?.querySchema?.length);

  useMemo(() => {
    stateRef.current = {
      dataFetchPayload: null,
      childrenDataFetchPayload: null
    };

    if (querySchemaExists) {
      const dataFetchPayload = CatalogWidgetUtils.getQueryDataPayload(
        widgetResponseDTO,
        queryConfig,
        vizConfig,
        timeRange,
        compareTimeRange,
        widgetSelectorSpec,
        aggregatedTags,
        metricId,
        metricType,
        postAggProjections,
        limitSpecFunction,
        seriesLimit,
        isSingleStatQuery,
        downsample
      );

      const childDataFetchPayloads = childMetricIds.map(chMetricId =>
        CatalogWidgetUtils.getQueryDataPayload(
          widgetResponseDTO,
          queryConfig,
          vizConfig,
          timeRange,
          compareTimeRange,
          widgetSelectorSpec,
          aggregatedTags,
          chMetricId,
          metricType,
          postAggProjections,
          limitSpecFunction,
          seriesLimit,
          isSingleStatQuery,
          downsample
        )
      );

      const childrenDataFetchPayload: CatalogWidgetFetchDataPayload = childDataFetchPayloads?.length
        ? {
            mode: childDataFetchPayloads[0].mode,
            sliceSpec: childDataFetchPayloads.reduce((acc, curr) => [...acc, ...curr.sliceSpec], [] as SliceSpec[])
          }
        : null;

      stateRef.current = {
        dataFetchPayload,
        childrenDataFetchPayload
      };
    }

    forceUpdate();
  }, [
    querySchemaExists,
    forceUpdate,
    widgetResponseDTO,
    queryConfig,
    vizConfig,
    timeRange,
    compareTimeRange,
    widgetSelectorSpec,
    aggregatedTags,
    metricId,
    metricType,
    postAggProjections,
    limitSpecFunction,
    seriesLimit,
    isSingleStatQuery,
    downsample,
    childMetricIds
  ]);

  return stateRef.current;
};
