import React, { FC, useCallback, useMemo } from "react";
import { IncButton, IncRadioGroup, IncSmartText, RadioGroupOption } from "@inception/ui";
import { cloneDeep, isEqual, uniqBy } from "lodash";
import { BizDataQuery, PostAggProjection, TimeObj, TimeObjUnit } from "../../../../services/api/explore";
import { UberOperationalizeTypes } from "../../../../services/api/operationalise";
import timeRangeUtils from "../../../../utils/TimeRangeUtils";
import { getMillisFromTimeObj, getTimeObjFromMillis } from "../../../../utils";
import { KPIQueryEditor, VerticallyCenteredRow } from "../../../../components";
import { RollingFrequencyEditorV2 } from "../common";
import { useToggleState } from "../../../../core";
import { getSliceSpec } from "../../../../biz-flow/utils";
import { getDtoFromWidgetConfig } from "../../../../utils/ExploreUtils";

type Props = {
  bizDataQuery: BizDataQuery;
  compareTimeSeconds?: number;
  onChange: (bizDataQuery: BizDataQuery) => void;
  readonly?: boolean;
};

const SuppressionQueryEditorV2: FC<Props> = props => {
  const { bizDataQuery, onChange, readonly, compareTimeSeconds } = props;
  const { labels: bizDataQueryLabels } = bizDataQuery || {};
  const { name: widgetName } = bizDataQuery?.widgetConfig || {};
  const { projections, timeShiftCompareSeconds } = bizDataQuery?.sliceSpec?.postAgg || {};

  const { timeShiftCompareUnit } = bizDataQueryLabels || {};

  const timeShiftObj = useMemo<TimeObj>(() => {
    const millis = (timeShiftCompareSeconds || 0) * 1000;
    const timeObj = getTimeObjFromMillis(millis, timeShiftCompareUnit || TimeObjUnit.minutes);
    return timeObj;
  }, [timeShiftCompareSeconds, timeShiftCompareUnit]);

  const onChangeTimeShiftSeconds = useCallback(
    (timeObj: TimeObj) => {
      const millis = getMillisFromTimeObj(timeObj);
      const seconds = timeRangeUtils.getSecondsFromMillis(millis);
      onChange({
        ...bizDataQuery,
        labels: {
          ...(bizDataQuery?.labels || {}),
          name: bizDataQuery?.labels?.name || "",
          timeShiftCompareUnit: timeObj.unit
        },
        sliceSpec: {
          ...(bizDataQuery?.sliceSpec || {}),
          selectorSpec: bizDataQuery?.sliceSpec?.selectorSpec || {
            filters: []
          },
          sliceSet: bizDataQuery?.sliceSpec?.sliceSet || {
            slices: []
          },
          postAgg: {
            ...(bizDataQuery?.sliceSpec?.postAgg || {}),
            timeShiftCompareSeconds: seconds
          }
        }
      });
    },
    [bizDataQuery, onChange]
  );

  const onChangeProjections = useCallback(
    (selectedProjection: string) => {
      const projection = selectedProjection as PostAggProjection;
      onChange({
        ...bizDataQuery,
        labels: {
          ...(bizDataQuery?.labels || {}),
          name: bizDataQuery?.labels?.name || "",
          timeShiftCompareUnit: TimeObjUnit.minutes
        },
        sliceSpec: {
          ...(bizDataQuery?.sliceSpec || {}),
          selectorSpec: bizDataQuery?.sliceSpec?.selectorSpec || {
            filters: []
          },
          sliceSet: bizDataQuery?.sliceSpec?.sliceSet || {
            slices: []
          },
          postAgg: {
            ...(bizDataQuery?.sliceSpec?.postAgg || {}),
            projections: [projection],
            timeShiftCompareSeconds:
              projection === "delta" || projection === "deltaPercentage"
                ? compareTimeSeconds || 0
                : bizDataQuery?.sliceSpec?.postAgg?.timeShiftCompareSeconds
          }
        }
      });
    },
    [bizDataQuery, onChange, compareTimeSeconds]
  );

  const showTimeShiftCompareEditor = projections?.includes("delta") || projections?.includes("deltaPercentage");

  const onChangeQuery = useCallback(
    (pBizDataQuery: BizDataQuery) => {
      if (!bizDataQuery) {
        onChange(pBizDataQuery);
      } else if (!isEqual(bizDataQuery.widgetConfig, pBizDataQuery.widgetConfig)) {
        const nBizDataQuery = cloneDeep(pBizDataQuery);
        modifyBizDataQuery(nBizDataQuery, pBizDataQuery);
        onChange(nBizDataQuery);
      }
    },
    [bizDataQuery, onChange]
  );

  const radioOptions = useMemo(() => getRadioGroupOption(widgetName), [widgetName]);

  const { isOpen: isAdvanceOptionsEnabled, toggle: onToggleAdvanceOptions } =
    useToggleState(showTimeShiftCompareEditor);

  return (
    <div className="inc-flex-column flex-gap-12">
      <KpiEditorWrapper
        bizDataQuery={bizDataQuery}
        onChange={onChangeQuery}
        readonly={readonly}
      />
      <IncButton
        color="link"
        onClick={onToggleAdvanceOptions}
      >
        {isAdvanceOptionsEnabled ? "Hide Advanced Settings" : "Show Advanced Settings"}
      </IncButton>
      {isAdvanceOptionsEnabled && (
        <>
          <IncRadioGroup
            layout="column"
            onChange={onChangeProjections}
            options={radioOptions}
            value={projections?.[0]}
          />
          {showTimeShiftCompareEditor && (
            <VerticallyCenteredRow className="flex-gap-12">
              <IncSmartText
                className="inc-text-color-secondary inc-text-body-medium"
                text="Change compared to"
              />
              <RollingFrequencyEditorV2
                onChange={onChangeTimeShiftSeconds}
                rollingFreq={timeShiftObj}
              />
            </VerticallyCenteredRow>
          )}
        </>
      )}
    </div>
  );
};

const kpiStyle: React.CSSProperties = {
  maxHeight: "40vh",
  overflowY: "auto"
};

const getRadioGroupOption = (metricName: string): RadioGroupOption[] => [
  {
    label: `Use the value of ${metricName} to suppress`,
    value: "current"
  },
  {
    label: `Use the change in ${metricName} to suppress`,
    value: "delta"
  },
  {
    label: `Use the percentage change in ${metricName} to suppress`,
    value: "deltaPercentage"
  }
];

type KpiEditorWrapperProps = {
  bizDataQuery: BizDataQuery;
  onChange: (bizDataQuery: BizDataQuery) => void;
  readonly?: boolean;
};

const KpiEditorWrapper: FC<KpiEditorWrapperProps> = props => {
  const { bizDataQuery, onChange, readonly = false } = props;

  const kpiQuery = useMemo<UberOperationalizeTypes.OpBizDataQuery>(
    () =>
      bizDataQuery
        ? {
            bizDataQuery,
            rollingFreq: null,
            rollingFunction: null
          }
        : null,
    [bizDataQuery]
  );

  const onChangeKpi = useCallback(
    (isValid: boolean, errorsText: string, kpi: UberOperationalizeTypes.OpBizDataQuery) => {
      if (isValid) {
        onChange(kpi.bizDataQuery);
      }
    },
    [onChange]
  );

  const wrapperClassName = `${readonly ? "disableClick" : ""}`;

  return (
    <div
      className={wrapperClassName}
      style={kpiStyle}
    >
      {kpiQuery && (
        <KPIQueryEditor
          kpiQuery={kpiQuery}
          onStateChange={onChangeKpi}
          showPredefinedFilters
        />
      )}
    </div>
  );
};

const modifyBizDataQuery = (nBizDataQuery: BizDataQuery, cBizDataQuery: BizDataQuery) => {
  const widgetConfigDto = getDtoFromWidgetConfig(nBizDataQuery.widgetConfig);
  if (nBizDataQuery.sliceSpec) {
    nBizDataQuery.sliceSpec.postAgg = cBizDataQuery.sliceSpec?.postAgg;
    const widgetSliceSpec = getSliceSpec(widgetConfigDto, nBizDataQuery.sliceSpec.metricId);
    const nSlices = [...(cBizDataQuery?.sliceSpec?.sliceSet?.slices || []), ...widgetSliceSpec.sliceSet.slices];
    nBizDataQuery.sliceSpec.sliceSet = {
      slices: uniqBy(nSlices, e => e.tagName)
    };
  }
};

export default SuppressionQueryEditorV2;
