import { IncButton, IncFaIcon } from "@inception/ui";
import { isEqual } from "lodash";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  MetricTable,
  MetricTableConfig,
  MetricTableVariableContext,
  MetricTableWidgetEditor
} from "../../../components/metric-table";
import { useToggleState } from "../../../core";
import { WidgetCustomAction, WidgetExtProps } from "../types";
import { getDefaultMetricTableConfig, MetricTableWidgetImpl } from "./models/impl";

interface MetricTableWidgetProps extends WidgetExtProps {
  widget: MetricTableWidgetImpl;
}

const MetricTableWidget: React.FC<MetricTableWidgetProps> = memo(props => {
  const {
    widget,
    timeRange,
    edit,
    setCustomActions,
    variableSrvMap: pVariableSrvMap,
    variableLoadingStateMap: pVariableLoadingStateMap,
    eventTypeToFieldsMap
  } = props;

  const variableLoadingStateMapRef = useRef(pVariableLoadingStateMap);
  useMemo(() => {
    const prevLoadingMap = variableLoadingStateMapRef.current;
    if (!isEqual(prevLoadingMap, pVariableLoadingStateMap)) {
      variableLoadingStateMapRef.current = pVariableLoadingStateMap;
    }
  }, [pVariableLoadingStateMap]);

  const variableSrvMapRef = useRef(pVariableSrvMap);
  useMemo(() => {
    const prevVarSrvMap = variableSrvMapRef.current;
    if (!isEqual(prevVarSrvMap, pVariableSrvMap)) {
      variableSrvMapRef.current = pVariableSrvMap;
    }
  }, [pVariableSrvMap]);

  const variableLoadingStateMap = variableLoadingStateMapRef.current;
  const variableSrvMap = variableSrvMapRef.current;

  const { tableConfig: wTableConfig, title, id } = widget;

  const { isOpen, open: openEditor, close: closeEditor } = useToggleState();

  useEffect(() => {
    if (edit) {
      const editAction: WidgetCustomAction = {
        actionComponent: (
          <IncFaIcon
            className="inc-cursor-pointer"
            iconName="edit"
            onClick={openEditor}
          />
        ),
        showInHeader: true,
        tooltipText: "Edit"
      };
      setCustomActions([editAction]);
    }
  }, [edit, openEditor, setCustomActions]);

  const [tableConfig, setTableConfig] = useState(wTableConfig || getDefaultMetricTableConfig());

  useEffect(() => {
    setTableConfig(wTableConfig || getDefaultMetricTableConfig());
  }, [wTableConfig]);

  useEffect(() => {
    widget.tableConfig = tableConfig;
  }, [tableConfig, widget]);

  const onConfigChange = useCallback(
    (newConfig: MetricTableConfig) => {
      setTableConfig(newConfig);
      closeEditor();
    },
    [closeEditor]
  );

  const configExists = Boolean(tableConfig?.metricColumns?.length);

  const variablesContext = useMemo<MetricTableVariableContext>(
    () => ({
      variableSrvMap,
      loadingStateMap: variableLoadingStateMap,
      eventTypeIdToFieldsMap: eventTypeToFieldsMap,
      dashboardWidgetId: id
    }),
    [eventTypeToFieldsMap, id, variableLoadingStateMap, variableSrvMap]
  );

  return (
    <>
      {configExists && (
        <MetricTable
          defaultFileName={title}
          metricTableConfig={tableConfig}
          timeRange={timeRange}
          variablesContext={variablesContext}
        />
      )}

      {!configExists && (
        <div
          className="inc-flex-row inc-flex-center width-100"
          style={{ height: "100%" }}
        >
          <IncButton
            color="secondary-blue"
            disabled={!edit}
            iconType="iconText"
            onClick={openEditor}
          >
            <IncFaIcon iconName="edit" />
            Configure
          </IncButton>
        </div>
      )}

      <MetricTableWidgetEditor
        metricTableConfig={tableConfig}
        onChange={onConfigChange}
        onClose={closeEditor}
        open={isOpen}
        title={title}
      />
    </>
  );
});

export default MetricTableWidget;
