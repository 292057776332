import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IncSelectOption } from "@inception/ui";
import {
  ImpactWidgetDataPostAgg,
  ImpactedWidget,
  TimeObjUnit,
  UserServiceFilterExpression,
  UserServiceFilterList
} from "../../services/api/explore";
import { TimeRange, useTimeRange } from "../../core";
import { EventCriteriaPicker, LoadingSpinner, VerticallyCenteredRow } from "..";
import kbn from "../../services/datasources/core/kbn";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { getGroupKey } from "../../operationalise-v2/v3/results/hooks";
import { useFetchCorrelatedEvents } from "../../services/api/explore/hooks/useFetchCorrelatedEvents";
import { getMetricIdForImpactWidget } from "./utils";
import { useFetchImpactedWidgetsData } from "./impact-widget/hooks";
import { getBinsFromImpactedWidgetData } from "./IncidentTimeline/utils";
import { ImpactedWidgetFilter } from "./impact-widget";
import { CorrelatedEventsSource } from "./types";
import { ChangeEventTimeline, ChangeEventTimelineHeader, ChangeEventTable } from "./ChangeEventDrawer";

interface Props {
  source: CorrelatedEventsSource;
  opConfigId: string;
  incidentId: string;
  impactedWidgets: ImpactedWidget[];
  userServiceFilterExpressions?: UserServiceFilterExpression[];

  timeRange?: TimeRange;
  generateDemoData?: boolean;

  hideEventCriteria?: boolean;
  hideChangeEventsTable?: boolean;
}

export const CorrelatedEventsTimeline: FC<Props> = props => {
  const {
    source,
    incidentId,
    opConfigId,
    impactedWidgets,
    generateDemoData,
    userServiceFilterExpressions,
    timeRange: pTimeRange,
    hideEventCriteria = false,
    hideChangeEventsTable = false
  } = props;

  const ref = useRef<HTMLDivElement>();

  const eventFieldFilters = useMemo<UserServiceFilterList>(() => {
    const fUserServiceFilterExpressions = userServiceFilterExpressions || [];
    if (!fUserServiceFilterExpressions?.length && source.type === "payload") {
      source.payload.eventFieldSearchList?.requests?.forEach(req =>
        fUserServiceFilterExpressions.push(...(req.userServiceFilterExpression || []))
      );
    }

    return {
      userServiceFilters: fUserServiceFilterExpressions?.length
        ? [
            {
              userServiceFilterExpressions: fUserServiceFilterExpressions
            }
          ]
        : []
    };
  }, [source, userServiceFilterExpressions]);

  const [impactedWidget, setImpactedWidget] = useState(impactedWidgets.find(x => x.isPrimary) || impactedWidgets[0]);
  const impactedWidgetListOptions = useMemo<Array<IncSelectOption<ImpactedWidget>>>(
    () =>
      (impactedWidgets || []).map(x => ({
        label: x.name,
        value: x.id,
        data: x
      })),
    [impactedWidgets]
  );

  const onChangeImpactWidget = useCallback((option: IncSelectOption<ImpactedWidget>) => {
    setImpactedWidget(option.data);
  }, []);

  // time range
  const { timeRange: gTimeRange } = useTimeRange();
  const defaultTimeRange = useMemo(() => pTimeRange || gTimeRange, [gTimeRange, pTimeRange]);
  const [timeRange, setTimeRange] = useState(defaultTimeRange);
  const { from, to } = useMemo(() => timeRangeUtils.getTimeRangeMillisFromRaw(timeRange.raw), [timeRange.raw]);
  const resetTimeRange = useCallback(() => {
    setTimeRange(defaultTimeRange);
  }, [defaultTimeRange]);

  const shouldShowReset = useMemo(() => {
    const { fromMillis: gFrom, toMillis: gTo } = timeRangeUtils.getMillisFromTimeRange(defaultTimeRange);

    const { fromMillis: lFrom, toMillis: lTo } = timeRangeUtils.getMillisFromTimeRange(timeRange);

    return gFrom !== lFrom || gTo !== lTo;
  }, [defaultTimeRange, timeRange]);

  const pNumBins = source.type === "payload" ? source.payload.bins : 0;
  const eventFieldSearchList = source.type === "payload" ? source.payload.eventFieldSearchList : undefined;

  const [numBins, setNumBins] = useState(pNumBins);
  useEffect(() => {
    if (ref.current && !numBins) {
      const width = ref.current.getBoundingClientRect()?.width;
      if (width) {
        const numBins = Math.ceil(width / 60);
        setNumBins(numBins);
      } else {
        setNumBins(20);
      }
    }
  }, [numBins]);

  const {
    groupBy: groupByTags,
    groupKey,
    impactedWidgetId,
    buildingBlockConfigId
  } = useMemo(() => {
    const groupBy: string[] = [];
    const buildingBlockConfigId = getMetricIdForImpactWidget(impactedWidget) || "";
    return {
      groupBy,
      groupKey: getGroupKey(groupBy),
      impactedWidgetId: impactedWidget.id,
      buildingBlockConfigId
    };
  }, [impactedWidget]);

  const userServiceFilters = useMemo<Record<string, UserServiceFilterList>>(() => {
    const userServiceFilters: Record<string, UserServiceFilterList> = {};
    if (eventFieldFilters) {
      userServiceFilters[buildingBlockConfigId] = eventFieldFilters;
    }
    return userServiceFilters;
  }, [eventFieldFilters, buildingBlockConfigId]);

  const { overTimeAgg, intervalSecs } = useMemo(() => {
    const intervalMs = Math.ceil(to - from) / (numBins || 1);
    const roundedIntervalMs = kbn.round_interval(intervalMs);
    const intervalSecs = timeRangeUtils.getSecondsFromMillis(roundedIntervalMs);
    const overTimeAgg: ImpactWidgetDataPostAgg = {
      bins: null,
      timeDuration: {
        unit: TimeObjUnit.seconds,
        value: intervalSecs
      }
    };
    return {
      overTimeAgg,
      intervalSecs
    };
  }, [from, numBins, to]);

  // correlated events
  const prefetchedData = source.type === "data" ? source.data : undefined;
  const {
    data: changeEventData,
    isFetching: changeEventFetching,
    isError: isChangeEvenetError,
    error: changeEventError,
    refetch: fetchCorrelatedEvents
  } = useFetchCorrelatedEvents(
    incidentId,
    opConfigId,
    from,
    to,
    userServiceFilterExpressions,
    numBins,
    eventFieldSearchList,
    prefetchedData
  );

  useEffect(() => {
    if (numBins > 0 && !prefetchedData) {
      fetchCorrelatedEvents();
    }
  }, [fetchCorrelatedEvents, numBins, prefetchedData]);

  // impact widget data
  const entityFilters = useMemo(() => [], []);
  const selectedImpactedWidgets = useMemo(() => [impactedWidget], [impactedWidget]);
  const { refetch: fetchImpactedWidgetData, resultsByImpactedWidget } = useFetchImpactedWidgetsData(
    selectedImpactedWidgets,
    incidentId,
    opConfigId,
    groupByTags,
    entityFilters,
    userServiceFilters,
    from,
    to,
    groupByTags,
    overTimeAgg,
    intervalSecs,
    null,
    null,
    null,
    generateDemoData
  );

  const {
    data: impactedWidgetData,
    error,
    isError,
    isFetching: isImpactedWidgetFetching
  } = resultsByImpactedWidget?.[impactedWidgetId]?.[groupKey] || {};

  useEffect(() => {
    if (impactedWidgetId) {
      fetchImpactedWidgetData();
    }
  }, [fetchImpactedWidgetData, impactedWidgetId]);

  // transforming bins
  const bins = useMemo(
    () => getBinsFromImpactedWidgetData(impactedWidgetData, from, to),
    [impactedWidgetData, from, to]
  );

  const isLoading = !numBins || isImpactedWidgetFetching;

  // event Crieteria
  const eventCriteria = useMemo(() => {
    const eventFieldFilters = userServiceFilters?.[impactedWidget.id];
    if (eventFieldFilters?.userServiceFilters) {
      const parentFilters = eventFieldFilters.userServiceFilters[0]?.userServiceFilterExpressions || [];
      return [...parentFilters];
    }
    return [];
  }, [impactedWidget.id, userServiceFilters]);

  const impactedWidgetFilter = useMemo(
    () => (
      <div className="marginRt12">
        <ImpactedWidgetFilter impactedWidget={impactedWidget} />
      </div>
    ),
    [impactedWidget]
  );

  const timeSeriesAxisLabels = useMemo(
    () => Object.values(impactedWidgetData?.postAggData || {})?.[0]?.data?.[0].fields?.[0]?.data,
    [impactedWidgetData]
  );

  return (
    <>
      {!hideEventCriteria && (
        <VerticallyCenteredRow className="triage-criteria-renderer marginBt12">
          <VerticallyCenteredRow className="event-criteria">
            <VerticallyCenteredRow>
              <div className="inc-label-common marginRt12">Event Criteria</div>
              <VerticallyCenteredRow>
                {impactedWidgetFilter}
                <EventCriteriaPicker
                  disabled={true}
                  fieldPickerContext={null}
                  filters={eventCriteria}
                  label=""
                  onChange={() => {}}
                  pillLimit={3}
                  placeholder=""
                />
              </VerticallyCenteredRow>
            </VerticallyCenteredRow>
          </VerticallyCenteredRow>
        </VerticallyCenteredRow>
      )}
      <div
        className="incident-timeline-and-impact-widget"
        ref={ref}
      >
        <div className="incident-timeline-and-impact-widget--content inc-flex-column correlated-events-timeline-with-key-contributors">
          <ChangeEventTimelineHeader
            impactedWidget={impactedWidget}
            impactedWidgetListOptions={impactedWidgetListOptions}
            onChangeImpactWidget={onChangeImpactWidget}
            resetTimeRange={resetTimeRange}
            setTimeRange={setTimeRange}
            shouldShowReset={shouldShowReset}
            timeRange={timeRange}
          />
          {isLoading && <LoadingSpinner />}
          {!isLoading && (
            <>
              {isError && (
                <VerticallyCenteredRow className="error-message">
                  Error fetching data for timeline: {error}
                </VerticallyCenteredRow>
              )}
              {Boolean(bins?.length) && (
                <ChangeEventTimeline
                  bins={bins}
                  correlatedEventsResponse={changeEventData}
                  timeSeriesAxisLabels={timeSeriesAxisLabels}
                />
              )}
              {!bins?.length && (
                <VerticallyCenteredRow className="message inc-flex-center">
                  No incidents exist for the selected time range
                </VerticallyCenteredRow>
              )}
            </>
          )}
        </div>
      </div>
      {!hideChangeEventsTable && (
        <div className="field-drilldown change-event-table-wrapper">
          <ChangeEventTable
            correlatedEvents={changeEventData?.resultList || []}
            error={changeEventError}
            isError={isChangeEvenetError}
            isFetching={changeEventFetching}
          />
        </div>
      )}
    </>
  );
};
