import { IncModal, IncModalProps } from "@inception/ui";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  ConditionWithPickerTypeExpressionTree,
  ConditionsBuilder,
  VerticallyCenteredRow
} from "../../../../components";
import {
  ConditionWithPickerType,
  FieldPickerContextDTO,
  LogicalOperator,
  UserServiceFilterExpressionTree
} from "../../../../services/api/explore";
import { ExploreQueryUtils, USERSERVICE_TAG } from "../../../../utils";
import { FEATURE_FLAGS, featureFlagService } from "../../../../services/feature-flags";

interface Props {
  onChange: (expressionTree: UserServiceFilterExpressionTree) => void;
  onClose: () => void;

  fieldPickerContext: FieldPickerContextDTO;
  filterExprTree: UserServiceFilterExpressionTree;
}

export const AdvancedFilterModal: React.FC<Props> = props => {
  const { onChange, onClose, fieldPickerContext: pFieldPickerContext, filterExprTree: pFilterExprTree } = props;

  const shouldUseExpressionTree = useMemo(
    () =>
      pFilterExprTree?.filterNodes?.some(node => Boolean(node.expressionTree)) ||
      featureFlagService.isFeatureEnabled(FEATURE_FLAGS.useComplexExpressions),
    [pFilterExprTree]
  );

  const isValidRef = useRef(false);
  const isValid = isValidRef.current;

  const fieldPickerContext = useMemo<FieldPickerContextDTO>(
    () =>
      pFieldPickerContext || {
        entityId: null,
        entityType: USERSERVICE_TAG,
        entityName: "",
        showFields: true,
        userServices: []
      },
    [pFieldPickerContext]
  );

  const getDefExprTree = useCallback(() => {
    if (pFilterExprTree) {
      return ExploreQueryUtils.getAdvancedConditionFilters(pFilterExprTree);
    }

    return {
      filterNodes: [],
      logicalOperator: LogicalOperator.AND
    };
  }, [pFilterExprTree]);

  const getDefFilters = useCallback(() => {
    if (pFilterExprTree && pFilterExprTree.logicalOperator === LogicalOperator.AND) {
      const expressions = pFilterExprTree.filterNodes.map(node => node.expression);
      return ExploreQueryUtils.getConditionFilters(expressions);
    }
    return [];
  }, [pFilterExprTree]);

  const [filterExprTree, setFilterExprTree] = useState(getDefExprTree());
  useEffect(() => {
    setFilterExprTree(getDefExprTree());
  }, [getDefExprTree]);

  const [filters, setFilters] = useState<ConditionWithPickerType[]>(getDefFilters());
  useEffect(() => {
    setFilters(getDefFilters());
  }, [getDefFilters]);

  const onApplyChange = useCallback(() => {
    let exprTree;

    if (shouldUseExpressionTree) {
      exprTree = ExploreQueryUtils.getUserServiceFilterExpressionTree(filterExprTree);
    } else {
      const constructedfilterExprTree = {
        logicalOperator: LogicalOperator.AND,
        filterNodes: filters.map(filter => ({
          expression: filter
        }))
      };
      exprTree = ExploreQueryUtils.getUserServiceFilterExpressionTree(constructedfilterExprTree);
    }

    onChange(exprTree);
    onClose();
  }, [filterExprTree, filters, onChange, onClose, shouldUseExpressionTree]);

  const onCancelChange = useCallback(() => {
    setFilterExprTree(getDefExprTree());
    onClose();
  }, [getDefExprTree, onClose]);

  const actions = useMemo<IncModalProps["actions"]>(
    () => ({
      primary: {
        onClick: onApplyChange,
        label: "Apply",
        color: "primary",
        disabled: !isValid
      },
      secondary: {
        onClick: onCancelChange,
        color: "secondary",
        label: "Cancel"
      }
    }),
    [isValid, onApplyChange, onCancelChange]
  );

  const onFiltersChange = useCallback(
    (filters: ConditionWithPickerType[], isValid: boolean, pfilterExprTree: ConditionWithPickerTypeExpressionTree) => {
      const isEmptyFilter = shouldUseExpressionTree ? pfilterExprTree.filterNodes.length === 0 : filters.length === 0;

      isValidRef.current = isValid && !isEmptyFilter;
      setFilterExprTree(pfilterExprTree);
      setFilters(filters);
    },
    [shouldUseExpressionTree]
  );

  return (
    <IncModal
      actions={actions}
      className="inc-regular-modal"
      disableFocusOnLoad
      onClose={onClose}
      show
      showClose
      size="lg"
      titleText="Advanced Filter"
    >
      <div className="inc-flex-column flex-gap-12">
        <VerticallyCenteredRow className="inc-label-common">Add your criteria</VerticallyCenteredRow>
        <div className="event-filters-container">
          <ConditionsBuilder
            fieldPickerContext={fieldPickerContext}
            fieldTypes={["userServiceField"]}
            filterExpressionTree={filterExprTree}
            filterJoin="AND"
            filters={filters}
            label="Where"
            labelAlign="horizontal"
            onChange={onFiltersChange}
            useExpressionTree={shouldUseExpressionTree}
          />
        </div>
      </div>
    </IncModal>
  );
};
