import { getFormattedDateTime, IncDateTimeFormat, IncDateTimePicker } from "@inception/ui";
import { capitalize, debounce, isUndefined } from "lodash";
import React, { FC, useEffect, useState } from "react";
import { WidgetDateTimeProperty } from "../types";

interface Props {
  property: WidgetDateTimeProperty;
  onUpdate: (newState: WidgetDateTimeProperty) => void;
}

const DateTimePropertyRenderer: FC<Props> = props => {
  const { onUpdate: usrUpdate, property } = props;

  const {
    name,
    date: usrDate,
    label = capitalize(name),
    updateOnBlur = true,
    placeholder = getFormattedDateTime(new Date(), IncDateTimeFormat.minimal, {
      withSeconds: true
    }),
    info = ""
  } = property;

  const [date, setDate] = useState<Date>(undefined);

  const onChange = (newDate: Date) => {
    setDate(newDate as Date);
    if (!updateOnBlur) {
      onUpdate(newDate);
    }
  };

  const onUpdate = debounce((newDate = date) => {
    const newProperty: WidgetDateTimeProperty = {
      ...property,
      date: newDate
    };
    usrUpdate(newProperty);
  }, 500);

  const onBlur = updateOnBlur ? () => onUpdate() : () => {};

  useEffect(() => {
    setDate(usrDate);
  }, [usrDate]);

  return (
    !isUndefined(date) && (
      <IncDateTimePicker
        helpText={info}
        label={label}
        onBlur={onBlur}
        onChange={onChange}
        placeholderText={placeholder}
        value={date}
      />
    )
  );
};

export default DateTimePropertyRenderer;
