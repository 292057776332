import { IncModal, IncRTable, IncRTableProps, IncSmartText, TableDataColumn } from "@inception/ui";
import { isArray, isEmpty, isObject, uniq, sortBy } from "lodash";
import React, { useMemo } from "react";
import { SampleValueType, SelectorGridFieldDef } from "./types";

interface FieldPreviewDataModalProps {
  previewField: SelectorGridFieldDef;
  show: boolean;
  onClose: () => void;
}

export const FieldPreviewDataModal: React.FC<FieldPreviewDataModalProps> = (props: FieldPreviewDataModalProps) => {
  const { show, previewField, onClose } = props;

  const { fieldName, sampleVals } = previewField || {};

  type TableRecord = Record<"sampleVal", SampleValueType>;

  const columns = useMemo(() => {
    const cols: Array<TableDataColumn<TableRecord>> = [];

    cols.push({
      header: "Sample Values",
      accessor: "sampleVal",
      renderer: (cellData: any, rowData: TableRecord) => <IncSmartText text={rowData.sampleVal} />
    });

    return cols;
  }, []);

  const pagination: IncRTableProps["pagination"] = useMemo(
    () => ({
      enabled: true,
      pageSize: 25
    }),
    []
  );

  const tableData: TableRecord[] = useMemo(() => {
    const vals = sampleVals?.map(getDisplayString).filter(Boolean);
    const dataVals = sortBy(uniq(vals || []));
    return dataVals.map(x => ({
      sampleVal: x
    }));
  }, [sampleVals]);

  return (
    <IncModal
      className="paddingBt16"
      onClose={onClose}
      show={show}
      titleText={`${fieldName}`}
    >
      {tableData.length > 0 ? (
        <IncRTable
          classNames={{ table: "field-selector-table" }}
          columns={columns}
          data={tableData}
          noDataMessage="No fields found"
          pagination={pagination}
        />
      ) : (
        <span className="inc-flex-center-horizontal inc-text-body">no preview data found</span>
      )}
    </IncModal>
  );
};

function getDisplayString(val: SampleValueType) {
  if (!val) {
    return null;
  }
  if (isObject(val) || isArray(val)) {
    return !isEmpty(val) ? JSON.stringify(val) : null;
  }
  return val.toString();
}
