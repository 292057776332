import Highcharts, { Axis, Chart, Point, PointerEventObject } from "highcharts";
import { inRange, isArray, isUndefined, isNull } from "lodash";

/**
 * Synchronize zooming through the setExtremes event handler.
 * This should be added to event handlers of all the xAxis' setExtremes event.
 */
export function syncExtremes(this: Axis, e: any) {
  const thisChart = this.chart;

  if (e.trigger !== "syncExtremes") {
    // Prevent feedback loop
    Highcharts.each(Highcharts.charts, (chart: Chart) => {
      if (chart && chart !== thisChart) {
        if (chart.xAxis[0]?.setExtremes) {
          // It is null while updating
          chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
            trigger: "syncExtremes"
          });
        }
      }
    });
  }
}

const addSynchronousTooltip = (chartInstance: Chart) => {
  /**
   * In order to synchronize tooltips and crosshairs, override the
   * built-in events with handlers defined on the container element.
   * This will enable events to be fired on hover over legend.
   * We'll handle that in the listener.
   */

  const widgetCanvasElement = chartInstance.container;

  const eventsDefined = widgetCanvasElement?.getAttribute(HC_EVENT_ATTR) === "true";
  const pointerResetDecorated = widgetCanvasElement?.getAttribute(HC_SYNC_POINTER_RESET_ATTR) === "true";

  // Add the events only if they're not defined on the canvas element already
  if (widgetCanvasElement && !eventsDefined) {
    /**
     * Override the positioning function to avoid caching and always calculate position.
     * This is needed since the tooltip' position is not reset when the page is scrolled.
     */
    chartInstance.tooltip.options.positioner = function (this, w, h, point) {
      (this.chart.pointer as any).chartPosition = null;
      return (this as any).getPosition(w, h, point);
    };

    const eventListener = (e: Event) => {
      const evt = e as MouseEvent;

      /**
       * Check if the the target of the event is an element of the series group.
       * If not skip showing tooltip.
       */
      const { plotHeight, plotLeft, plotTop, plotWidth } = chartInstance;

      // Adding offset of 2px to actual plot area range
      const yRange = [plotTop, plotTop + plotHeight + 2];
      const xRange = [plotLeft, plotLeft + plotWidth + 2];

      const shouldTriggerTooltip =
        inRange(evt.offsetX, xRange[0], xRange[1]) && inRange(evt.offsetY, yRange[0], yRange[1]);

      if (shouldTriggerTooltip) {
        const pointerEventObj = chartInstance.pointer.normalize(evt);
        const point: Point =
          chartInstance.hoverPoint || (chartInstance.series[0] as any)?.searchPoint(pointerEventObj, true);

        if (point) {
          const timestamp = point.x;

          for (let i = 0; i < Highcharts.charts.length; i = i + 1) {
            const chart = Highcharts.charts[i];
            const pointsToHighlight: Point[] = [];
            const isChartVisible = isChartInViewport(chart);

            if (chart && chart.series && isChartVisible) {
              // Iterate all the series and get the points with same timestamp
              chart.series.forEach(series => {
                const points = series.data.filter(p => p.x === timestamp && !isNull(p.y));
                pointsToHighlight.push(...points);
              });
              const eventObj = chart.pointer.normalize(evt);
              eventObj.chartX = evt.offsetX;
              eventObj.chartY = evt.offsetY;

              highlightPoints(chart, pointsToHighlight, eventObj);
            }
          }
        }
      } else {
        chartInstance.pointer.reset(false, 100);
      }

      e.stopPropagation();
    };

    widgetCanvasElement.addEventListener("mousemove", eventListener);
    widgetCanvasElement.setAttribute(HC_EVENT_ATTR, "true");
    widgetCanvasElement.setAttribute(HC_SYNC_TT_ATTR, "true");
  }

  // Add logic to reset all tooltips on reset of pointer
  if (widgetCanvasElement && !pointerResetDecorated) {
    decoratePointerReset(chartInstance);
    widgetCanvasElement.setAttribute(HC_SYNC_POINTER_RESET_ATTR, "true");
  }
};

/**
 * Override the reset function, we need to hide the tooltips and crosshairs for all series.
 * We use the simulatedReset flag to make sure we don't trigger resets cyclically
 */
const decoratePointerReset = (chartInstance: Chart) => {
  const curReset = chartInstance.pointer.reset.bind(chartInstance.pointer);

  chartInstance.pointer.reset = function (this, allowMove, delay, simulatedReset?: boolean) {
    curReset(allowMove, delay);
    if (!simulatedReset) {
      for (let i = 0; i < Highcharts.charts.length; i = i + 1) {
        const chart = Highcharts.charts[i];
        // After destruction the chart entry is undefined. So we need this check.
        if (chart && chart !== chartInstance) {
          (chart.pointer.reset as any)(allowMove, delay, true);
        }
      }
    }
  };
};

/**
 * Highlight a point by showing tooltip, setting hover state and draw crosshair.
 */
const highlightPoints = (chart: Chart, points: Point[], eventObj: PointerEventObject) => {
  if (chart && points.length > 0) {
    const chartContainer = chart.container;
    const syncTooltipEnabled = chartContainer.getAttribute(HC_SYNC_TT_ATTR) === "true";

    const highlightPoint = (point: Point | Point[]) => {
      if (point) {
        const p = isArray(point) ? point[0] : point;
        chart.tooltip.refresh(point); // Show the tooltip
        chart.xAxis[0].drawCrosshair(eventObj, p); // Show the crosshair
      }
    };

    if (syncTooltipEnabled) {
      if (chart.tooltip.shared) {
        // Highlight all points if the tooltip is shared
        const visiblePoints = (points || []).filter(p => {
          const uVisible = p.series.options?.custom?.visible;
          return isUndefined(uVisible) ? true : uVisible;
        });
        highlightPoint(visiblePoints);
      } else {
        // Search for the point that belongs to hoveredSeries (if any) else use the 1st point in the points list
        const filPoints = points.filter(p => (chart.hoverSeries ? chart.hoverSeries === p.series : true));
        const point = filPoints[0] || points[0];
        highlightPoint(point);
      }
    }
  }
};

const HC_EVENT_ATTR = "data-hc-sync-tt-event-defined";
const HC_SYNC_TT_ATTR = "data-hc-sync-tt-defined";
const HC_SYNC_POINTER_RESET_ATTR = "data-hc-sync-pointer-reset-defined";

export default addSynchronousTooltip;

const isChartInViewport = (chart: Highcharts.Chart) => {
  const chartContainer = chart?.container;
  const boundingRect = chartContainer?.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;

  return boundingRect?.top <= windowHeight && boundingRect?.bottom >= 0;
};
