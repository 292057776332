import { useCallback, useEffect } from "react";
import { useForceUpdate } from "../../core";
import { UseCaseConfig } from "../../services/api";

export type ConnectDataBannerState = {
  isConnectDataBannerVisible: boolean;
  useCases: UseCaseConfig[];
};

const connectDataBannerState: ConnectDataBannerState = {
  isConnectDataBannerVisible: false,
  useCases: []
};

const CONNECT_DATA_BANNER_EVENT_NAME = "__connect_data_banner_event__";

export const useConnectDataBanner = () => {
  const forceUpdate = useForceUpdate();

  const triggerUpdate = useCallback(() => {
    document.dispatchEvent(new Event(CONNECT_DATA_BANNER_EVENT_NAME));
  }, []);

  const showConnectDataBanner = useCallback(() => {
    connectDataBannerState.isConnectDataBannerVisible = true;
    triggerUpdate();
  }, [triggerUpdate]);

  const hideConnectDataBanner = useCallback(() => {
    connectDataBannerState.isConnectDataBannerVisible = false;
    triggerUpdate();
  }, [triggerUpdate]);

  const setConnectDataBannerUseCases = useCallback(
    (useCases: UseCaseConfig[]) => {
      connectDataBannerState.useCases = useCases;
      triggerUpdate();
    },
    [triggerUpdate]
  );

  useEffect(() => {
    const eventListener = () => forceUpdate();

    document.addEventListener(CONNECT_DATA_BANNER_EVENT_NAME, eventListener);
    return () => document.removeEventListener(CONNECT_DATA_BANNER_EVENT_NAME, eventListener);
  }, [forceUpdate]);

  return {
    connectDataBannerState,
    showConnectDataBanner,
    hideConnectDataBanner,
    setConnectDataBannerUseCases
  };
};
