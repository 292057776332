import { defaults, intersection, isArray, isNull, isUndefined, set } from "lodash";
import BaseImpl from "../../core/BaseImpl";
import { ScopedVars, EntityAggregationResponse, EntityAggregationRequest } from "../../services/api/types";
import { VariableResolverResult } from "../dashboard-variables/resolvers/CustomVariableResolver";
import VariableModel, { VariableType } from "../models/VariableModel";
import { getVariableRegex } from "../variables";
import { TimeRange } from "../../core";
import { QueryParamDTO } from "../../utils/QueryParamUtils";

export const VARIABLE_ALL_VALUE = "$__all";
export const VARIABLE_ALL_VALUE_FALLBACK = "*";

const DEFAULTS: VariableModel = {
  id: "",
  name: "",
  multi: false,
  value: null,
  defaultValue: "",
  type: VariableType.Constant,
  allValue: "",
  includeAll: false,
  label: "",
  hide: false,
  supportsAggregation: false
};

interface ValidateResponse {
  hasError: boolean;
  messages: Record<string, string>;
}

export interface VariableAggRequestPayload {
  aggRequest: EntityAggregationRequest;
  variableName: string;
  cohortId: string;
  entityTypeId: string;
  mockData: boolean;
  startMillis: number;
  endMillis: number;
}

export default class VariableImpl extends BaseImpl<VariableModel> implements VariableModel {
  id: string;
  label: string;
  name: string;
  hide: boolean;
  supportsAggregation: boolean;

  value: string | string[];
  defaultValue?: string;

  includeAll: boolean;
  multi: boolean;
  allValue: string;

  //These properties are not stored in the backend and hence are not part of model
  helpText: string;
  optionsData: VariableResolverResult; // Possible values for the variable
  dependencies: string[]; // Variable names that this variable depends on
  lock: boolean; // Control the state of variable loading while we have dependencies to fetch data

  readonly type: VariableType = VariableType.Constant;

  protected omitProperties = ["helpText", "optionsData", "dependencies", "lock", "omitProperties"];

  constructor(model: Partial<VariableModel>) {
    super();
    this.assign(model);
  }
  protected assign(model: Partial<VariableModel>) {
    const vModel: Partial<VariableModel> = {};
    defaults(vModel, model, DEFAULTS);
    const { id, name, multi, value, defaultValue, allValue, includeAll, label, hide, supportsAggregation } = vModel;

    this.id = id;
    this.name = name;
    this.multi = multi;
    this.value = value;
    this.defaultValue = defaultValue;
    this.allValue = allValue;
    this.includeAll = includeAll;
    this.label = label;
    this.hide = hide;
    this.supportsAggregation = supportsAggregation;

    this.dependencies = [];
    this.optionsData = {
      error: "",
      data: []
    };
    this.helpText = "A list of comma separated values";
    this.lock = true; // This is reset by VariableSrv or resolveValues
  }

  update(vModel: Partial<VariableModel>) {
    this.assign(vModel);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars-ts
  resolveValues(timeRange: TimeRange, depValues?: ScopedVars): Promise<VariableResolverResult> {
    this.lock = false;
    return Promise.resolve({
      data: [],
      error: ""
    });
  }

  // Called after resolving values. This is to ensure the default value and current value are always valid
  adjustValueAndDefaultValue() {
    if (this.value !== this.allValue && this.value !== VARIABLE_ALL_VALUE) {
      const valueArr = isArray(this.value) ? this.value : [this.value];
      // Persist selection of valid values
      const newValidValues = intersection(this.optionsData.data, valueArr);
      const shouldChangeValue = newValidValues.length === 0;
      if (shouldChangeValue) {
        this.value = this.optionsData.data[0] || this.defaultValue;
      } else {
        this.value = newValidValues.length === 1 ? newValidValues[0] : newValidValues;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars-ts
  updateValues(aggResponse: EntityAggregationResponse, error: string) {
    // Do nothing. The variables will implement this
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars-ts
  getAggregationRequest(timeRange: TimeRange): Promise<VariableAggRequestPayload> {
    // The variables that support aggregation should override this method and return right request
    return Promise.resolve({
      variableName: this.name
    } as VariableAggRequestPayload);
  }

  getSaveModel(): VariableModel {
    const model = {};
    const getModelValue = (value: any) => (value.getSaveModel ? value.getSaveModel() : value);

    for (const key in this) {
      const value = this[key] as any;
      const shouldAddToModel =
        this.hasOwnProperty(key) && !isUndefined(value) && !isNull(value) && this.omitProperties.indexOf(key) === -1;

      if (shouldAddToModel) {
        let v: any;
        if (isArray(value)) {
          v = value.map(val => getModelValue(val));
        } else {
          v = getModelValue(value);
        }

        set(model, key, v);
      }
    }
    return model as VariableModel;
  }

  getFilterQueryParams(): QueryParamDTO {
    return null;
  }

  protected processMatches(matchStr: string) {
    const variableRegex = getVariableRegex();
    const matches = matchStr.matchAll(variableRegex);
    const matchArr = Array.from(matches);
    // Each match's 2nd item would be the name of the variable name
    const matchVariableNames = matchArr.map(matchItem => matchItem[1]);
    return matchVariableNames;
  }

  validate(): ValidateResponse {
    let hasError = false;
    const messages: Record<string, string> = {};

    if (!this.name) {
      hasError = true;
      messages["name"] = "Variable name cannot be empty";
    }

    return {
      hasError,
      messages
    };
  }
}
