import { useState, useEffect, MutableRefObject } from "react";
import { useIsVisible } from "@inception/ui";

export const useIsWidgetVisible = (widgetContentRef: MutableRefObject<HTMLDivElement>) => {
  const [widgetRendered, setWidgetRendered] = useState(false);

  const isWidgetVisible = useIsVisible(widgetContentRef, { threshold: 0.1 });

  useEffect(() => {
    setWidgetRendered(prev => prev || isWidgetVisible);
  }, [isWidgetVisible]);

  return widgetRendered;
};
