import { IncToggle } from "@inception/ui";
import React, { FC, memo, useCallback } from "react";
import { ActionTemplatePropertyEditorProps } from "./types";

export const ToggleEditor: FC<ActionTemplatePropertyEditorProps> = memo(props => {
  const { onPropsChange, property, value } = props;

  const { description, label, propId, required } = property;

  const checked = value?.booleanVal || false;

  const onChange = useCallback(
    checked => {
      onPropsChange(propId, { booleanVal: checked });
    },
    [onPropsChange, propId]
  );

  return (
    <IncToggle
      checked={checked}
      helpText={description}
      label={label}
      onChange={onChange}
      required={required}
    />
  );
});
