import { ContentBlock, EditorState } from "draft-js";
import { SectionElementType } from "../../../../../services/api/operationalise";
import { EventGroupByRenderer } from "./event-groupby/EventGroupByRenderer";
import { TableRenderer } from "./table/TableRenderer";
import { ToolbarRenderer } from "./toolbar/ToolbarRenderer";
import { ActionTemplateElements } from "./types";

export const templateBlockRendererFn = (
  block: ContentBlock,
  template: ActionTemplateElements,
  onChangeReadOnly: (readOnly: boolean) => void,
  beforeEditorStateChange: () => void,
  onEditorStateChange: (editorState: EditorState) => void,
  onSectionDelete: (blockKey: string) => void,
  onErrors: (errors: string[], sectionKey: string) => void
) => {
  const type = block.getType();

  if (type === SectionElementType.TOOLBAR) {
    return {
      props: {
        block,
        template,
        onChangeReadOnly,
        onEditorStateChange,
        beforeEditorStateChange,
        onSectionDelete,
        onErrors
      },
      component: ToolbarRenderer
    };
  }

  if (type === SectionElementType.EVENT_GROUP_BY) {
    return {
      props: {
        block,
        template,
        onChangeReadOnly,
        onEditorStateChange,
        beforeEditorStateChange,
        onSectionDelete,
        onErrors
      },
      component: EventGroupByRenderer
    };
  }

  if (type === SectionElementType.TABLE) {
    return {
      props: {
        block,
        template,
        onChangeReadOnly,
        onEditorStateChange,
        beforeEditorStateChange,
        onSectionDelete,
        onErrors
      },
      component: TableRenderer
    };
  }

  return undefined;
};
