import { uniqBy } from "lodash";
import { CorrelatedEventList } from "../../../../services/api/explore";
import { ChangeEventCriteriaField } from "./ChangeEventCriteria";

export type FieldsToFilter = {
  name: string;
  checkAbsolute: boolean;
};

export const convertCriteriaFieldsFromCorrelatedEvents = (
  correlatedEvents: CorrelatedEventList[],
  searchText: string,
  fieldsToFilter: FieldsToFilter[],
  defaultSelectedTag: string[]
): ChangeEventCriteriaField[] => {
  const selectedKeys: ChangeEventCriteriaField[] = [];
  correlatedEvents?.[0]?.correlatedEventList?.forEach(val => {
    val?.correlatedEvents?.forEach(e => {
      if (e.entityChangeEvent?.tags) {
        const tags = Object.keys(e.entityChangeEvent?.tags);
        selectedKeys.push(
          ...tags
            .filter(
              tag =>
                !checkFieldsIncludesTag(tag, fieldsToFilter) && tag.toLowerCase().includes(searchText.toLowerCase())
            )
            .map(tag => ({
              field: tag,
              defaultSelected: defaultSelectedTag.includes(tag.toLowerCase())
            }))
        );
      }
    });
  });
  return uniqBy(selectedKeys, c => c.field);
};

const checkFieldsIncludesTag = (tag: string, fieldsToFilter: FieldsToFilter[]) =>
  fieldsToFilter.findIndex(field => {
    if (field.checkAbsolute) {
      return tag === field.name;
    } else {
      return tag.toLowerCase().replaceAll("_", "").includes(field.name.toLowerCase());
    }
  }) !== -1;
