import {
  CurrencyType,
  IncNumericKind,
  IncNumericKindDescriptor,
  IncSelect,
  IncSelectOption,
  IncRangeSlider,
  IncRangeSliderProps,
  IncTextfield
} from "@inception/ui";
import { isArray, throttle } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getNumericDataInfo } from "./numeric-range/utils";

const NumericSliderOperators = ["=", "<", ">", "range"];
export type NumericRangeSliderOperators = "=" | ">" | "<" | "range";

interface Props extends Omit<IncRangeSliderProps, "onChange" | "range"> {
  operator: NumericRangeSliderOperators;
  onChange: (val: number | number[], operator: NumericRangeSliderOperators) => void;
  subType: IncNumericKindDescriptor<IncNumericKind>;
  onlyRange?: boolean;
  disableOperator?: boolean;
  sliderLabel?: string;
  hideLabel?: boolean;
  reverse?: boolean;
}

export const NumericRangeSlider: React.FC<Props> = (props: Props) => {
  const {
    sliderLabel,
    operator,
    subType,
    value,
    label,
    onChange,
    min,
    max,
    onlyRange,
    disableOperator,
    hideLabel,
    dots = false,
    included = true,
    reverse = false,
    ...rest
  } = props;

  const [selectedOperator, setSelectedOperator] = useState<NumericRangeSliderOperators>(operator ? operator : "<");
  const [sliderValue, setSliderValue] = useState<IncRangeSliderProps["value"]>(value ? value : [min, min]);

  useEffect(() => {
    if (value) {
      setSliderValue(value);
    }
  }, [value]);

  const currencyType = subType?.kind === "currency" ? (subType.subType as CurrencyType) || "USD" : undefined;
  const durationType = subType?.kind === "duration" ? subType.subType : undefined;

  const operatorVal = useMemo(
    () => ({
      label: selectedOperator === "range" ? "is between" : selectedOperator,
      value: selectedOperator
    }),
    [selectedOperator]
  );

  const throttledOnChange = useMemo(() => throttle(onChange, 300), [onChange]);

  const operatorsList: IncSelectOption[] = useMemo(() => {
    if (onlyRange) {
      return [
        {
          label: "is between",
          value: "range"
        }
      ];
    }

    return NumericSliderOperators.map(x => ({
      label: x === "range" ? "is between" : x,
      value: x
    }));
  }, [onlyRange]);

  const onOperatorChange = useCallback(
    (opt: IncSelectOption) => {
      const { value: opVal } = opt;

      const [sliderMin] = sliderValue;

      if (opVal === "range" && selectedOperator !== opVal) {
        setSelectedOperator(opVal);
        setSliderValue([sliderMin, max]);
        onChange([], opVal);
        return;
      }

      setSelectedOperator(opVal as any);
      const val = opVal === "<" ? max : min;
      setSliderValue([val, val]);
      onChange(val, opVal as any);
    },
    [max, min, onChange, selectedOperator, sliderValue]
  );

  const valueString = useMemo(() => {
    if (selectedOperator === "range") {
      if (isArray(sliderValue)) {
        if (sliderValue[0] && sliderValue[1]) {
          return `${sliderValue[0]} - ${sliderValue[1]}`;
        } else if (sliderValue[0]) {
          return `${sliderValue[0]} - `;
        }
        return "";
      }
    }
    return isArray(sliderValue) ? `${sliderValue[0]}` : sliderValue;
  }, [selectedOperator, sliderValue]);

  const onSliderValChange = useCallback<IncRangeSliderProps["onChange"]>(
    value => {
      setSliderValue(value);
      throttledOnChange(value, selectedOperator);
    },
    [selectedOperator, throttledOnChange]
  );

  const onValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const strVal = event.target.value;
      if (selectedOperator === "range") {
        const valArray = strVal.split("-");
        const minVal = parseFloat(valArray[0]);
        const maxVal = parseFloat(valArray[1]);
        const minIntVal = isNaN(minVal) ? 0 : minVal;
        const maxIntVal = isNaN(maxVal) ? max : maxVal;
        const range: [number, number] = [minIntVal, maxIntVal];
        setSliderValue(range);
        throttledOnChange(range, selectedOperator);
      } else {
        const inputVal = parseFloat(strVal);
        const intVal = isNaN(inputVal) ? 0 : inputVal;
        setSliderValue([intVal, intVal]);
        throttledOnChange(intVal, selectedOperator);
      }
    },
    [throttledOnChange, max, selectedOperator]
  );

  const marks = useMemo<IncRangeSliderProps["marks"]>(() => {
    const minAbValue = getNumericDataInfo(min.toString(), false, {
      currencyType,
      durationType,
      withSymbol: true
    }).abbreviatedValue;

    const maxAbValue = getNumericDataInfo(max.toString(), false, {
      currencyType,
      durationType,
      withSymbol: true
    }).abbreviatedValue;

    return {
      [min]: minAbValue,
      [max]: maxAbValue
    };
  }, [currencyType, durationType, max, min]);

  return (
    <div className="numeric-range-slider">
      {Boolean(sliderLabel) && <div className="inc-label-common marginBt10">{sliderLabel}</div>}

      <IncRangeSlider
        className="marginRt12"
        dots={dots}
        included={included}
        marks={marks}
        max={max === min ? max + 1 : max}
        min={min}
        onChange={onSliderValChange}
        range={operator === "range" ? true : undefined}
        reverse={reverse}
        value={sliderValue}
        {...rest}
      />
      <div className="inc-flex-row inc-flex-center-vertical paddingLt12 paddingRt12">
        {!hideLabel && (
          <div className="text-container">
            <IncTextfield
              readOnly
              value={label || "Value"}
            />
          </div>
        )}

        {!disableOperator && (
          <IncSelect
            autoSort={false}
            className="select-container"
            menuPlacement="auto"
            onChange={onOperatorChange}
            options={operatorsList}
            placeholder="Condition"
            value={operatorVal}
          />
        )}

        <div className="text-container">
          <IncTextfield
            onChange={onValueChange}
            type="number"
            value={valueString}
          />
        </div>
      </div>
    </div>
  );
};
