import { IncFaIcon, IncFaIconName } from "@inception/ui";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { featureFlagService, FEATURE_FLAGS } from "../../../../../services/feature-flags";
import { WidgetCustomAction } from "../../../types";
import { USFWProps } from "../../types";

type Props = {
  widget: USFWProps["widget"];
};

export const useViewModeSwitchAction = (props: Props) => {
  const { widget } = props;

  const { renderMode, mode } = widget;

  const enableViewModeSwitch = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.enableViewModeSwitchInBizCatalog);

  const isAdhocView = renderMode === "adhoc-view";

  const [viewMode, setViewMode] = useState(mode);

  const toggleViewMode = useCallback(() => {
    setViewMode(prev => (prev === "detailed" ? "overview" : "detailed"));
  }, []);

  useEffect(() => {
    setViewMode(mode);
  }, [mode]);

  useEffect(() => {
    widget.mode = viewMode;
  }, [viewMode, widget]);

  const viewModeSwitchAction = useMemo<WidgetCustomAction>(() => {
    if (enableViewModeSwitch && !isAdhocView) {
      const tooltipText = "Switch view";
      const faIconName: IncFaIconName = "diagram-next";
      const actionComponent = (
        <IncFaIcon
          className="view-mode-switch"
          data-mode={viewMode}
          iconName={faIconName}
          onClick={toggleViewMode}
        />
      );

      return {
        actionComponent,
        showInHeader: true,
        tooltipText
      };
    }

    return null;
  }, [enableViewModeSwitch, isAdhocView, toggleViewMode, viewMode]);

  return {
    viewMode,
    viewModeSwitchAction
  };
};
