import React from "react";
import { ReactComponent as TimeseriesWidgetIcon } from "../../../../images/icons/Line chart.svg";
import { widgetRegistry, Dimensions } from "../WidgetRegistry";
import BizOpQueryWidget from "./BizOpQueryWidget";

export { default as BizOpQueryWidget } from "./BizOpQueryWidget";

widgetRegistry.register({
  id: "biz-op-query",
  label: "BizOpQuery Widget",
  enableInDashboard: false,
  supportsComparison: false,
  supportsNativeEdit: false,
  iconComponent: (): JSX.Element => <TimeseriesWidgetIcon />,
  component: props => <BizOpQueryWidget {...props} />,
  dimensions: (): Dimensions => ({
    w: 12,
    h: 8,
    minW: 10,
    minH: 8
  }),
  propertiesPanelComponent: (): JSX.Element => <></>
});
