import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { generateId, IncRadioGroup, RadioGroupOption } from "@inception/ui";
import { CypressConstants } from "@bicycle/tests";
import channelData from "../dataset/channel.json";
import codeData from "../dataset/code.json";
import systemData from "../dataset/system.json";
import { VerticallyCenteredRow } from "../../flex-components";
import { CommonStepProps } from "./types";

interface Props extends CommonStepProps {
  setTemplateToRender: (templateToRender: "slack" | "salesforce") => void;
}

export const SubscribeDialogContentStep1: FC<Props> = props => {
  const { setCanProceed, setTemplateToRender } = props;

  const ref = useRef<HTMLDivElement>();
  const [destination, setDestination] = useState<string>();

  const channels = channelData.data;
  const codes = codeData.data;
  const systems = systemData.data;

  useEffect(() => {
    setCanProceed(Boolean(destination));
    setTemplateToRender(destination === "slack" ? "slack" : "salesforce");
  }, [destination, setCanProceed, setTemplateToRender]);

  const channelElements = useMemo(
    () => (
      <IncRadioGroup
        onChange={setDestination}
        options={channels as RadioGroupOption[]}
        value={destination}
      />
    ),
    [channels, destination]
  );

  const codeElements = useMemo(
    () => (
      <IncRadioGroup
        onChange={setDestination}
        options={codes as RadioGroupOption[]}
        value={destination}
      />
    ),
    [codes, destination]
  );

  const systemElements = useMemo(() => {
    const uniqId = generateId();

    return systems.map(option => {
      const { icon, label, tasks } = option;

      const key = [uniqId, "radio-option", option.label].join("-");

      return (
        <div
          className="inc-flex-column multioptions--container flex-gap-12"
          key={key}
        >
          <VerticallyCenteredRow className="multioptions-container--title flex-gap-12">
            <VerticallyCenteredRow dangerouslySetInnerHTML={{ __html: icon }} />
            {label}
          </VerticallyCenteredRow>

          <IncRadioGroup
            onChange={setDestination}
            options={tasks as RadioGroupOption[]}
            value={destination}
          />
        </div>
      );
    });
  }, [destination, systems]);

  return (
    <div
      className="wizard-step"
      ref={ref}
    >
      <div className="wizard-step--title">Choose where you would like the insights delivered.</div>

      <div className="section">
        <div
          className="section--title inc-text-subtext-medium"
          data-cy={CypressConstants.features.CreateCopilot.attributes.subscribeWizardStep}
        >
          Notifications
        </div>
        {channelElements}
      </div>

      <div className="section">
        <div className="section--title inc-text-subtext-medium">Code</div>
        {codeElements}
      </div>

      <div className="section">
        <div className="section--title inc-text-subtext-medium">System</div>
        {systemElements}
      </div>
    </div>
  );
};
