import { useMemo } from "react";
import { CatalogWidgetProps, DataState } from "../../types";
import { AggregationUIOptions, TimeRange } from "../../../../../core";
import {
  CohortEntityFilter,
  WidgetResponseDTO,
  UserServiceFilterList,
  SelectorSpec
} from "../../../../../services/api/explore";
import { BizEntityFiltersProps } from "../../../../../biz-entity";
import { WidgetCustomAction } from "../../../types";
import { featureFlagService } from "../../../../../services/feature-flags";
import { FetchResultMetaProps } from "../useGetResultMeta";
import { useCatalogAddToDashboardAction } from "./useCatalogAddToDashboardAction";
import { useViewEventsAction } from "./useViewEventsAction";
import { useViewDashboardFilters } from "./useViewDashboardFilters";
import { useSelectFiltersAction } from "./useSelectFiltersAction";
import { useViewConfigurationAction } from "./useViewConfigurationAction";
import { useAlertsAction } from "./useAlertsAction";

export type CustomActionProps = {
  dbImpl: CatalogWidgetProps["dbImpl"];
  widget: CatalogWidgetProps["widget"];

  aggOptions: AggregationUIOptions[];
  aggregator: AggregationUIOptions;
  setAggregator: (aggregator: AggregationUIOptions) => void;

  allowPartialMatch: boolean;
  onFiltersExtracted: BizEntityFiltersProps["onFiltersExtracted"];
  widgetSelectorSpec: SelectorSpec;
  setWidgetSelectorSpec: (selectorSpec: SelectorSpec) => void;
  entityFilters: CohortEntityFilter[];
  cohortFilters: CohortEntityFilter[];
  eventFilters: Record<string, UserServiceFilterList>;

  widgetResponseDTO: WidgetResponseDTO;
  widgetResponseFetchInProgress: boolean;

  propertiesChanged: boolean;
  op10zeExist: boolean;
  timeRange: TimeRange;

  vizActions: WidgetCustomAction[];

  headerRef: React.RefObject<HTMLDivElement>;
  dataState: DataState;
  fetchResultMetaProps: FetchResultMetaProps;
};

export const useCustomActions = (props: CustomActionProps) => {
  const { vizActions, widget, headerRef, timeRange, op10zeExist } = props;

  const isDebugMode = featureFlagService.isDebugMode();

  const {
    title,
    widgetConfigRefId,
    properties: { showAlerts = true }
  } = widget;

  const isAdhocView = widget.renderMode === "adhoc-view";

  const { addToDashboardActionComponent, addToDashboardActionModal } = useCatalogAddToDashboardAction(props);

  const viewEventsAction = useViewEventsAction(props);
  const viewConfigurationAction = useViewConfigurationAction(props);
  const viewDashboardFiltersAction = useViewDashboardFilters(props);
  const viewFiltersAction = useSelectFiltersAction(props);
  const alertsAction = useAlertsAction(widgetConfigRefId, title, timeRange, headerRef, op10zeExist);

  const customActions = useMemo(() => {
    const customActions: WidgetCustomAction[] = [...vizActions];

    const addToDashboardAction =
      addToDashboardActionComponent && !isAdhocView
        ? {
            actionComponent: addToDashboardActionComponent
          }
        : null;

    addToDashboardAction && customActions.push(addToDashboardAction);
    viewDashboardFiltersAction && customActions.push(viewDashboardFiltersAction);
    alertsAction && showAlerts && customActions.push(alertsAction);
    viewFiltersAction && customActions.push(viewFiltersAction);

    isDebugMode && viewEventsAction && customActions.push(viewEventsAction);
    isDebugMode && viewConfigurationAction && customActions.push(viewConfigurationAction);

    return customActions;
  }, [
    vizActions,
    addToDashboardActionComponent,
    isAdhocView,
    isDebugMode,
    viewEventsAction,
    viewConfigurationAction,
    viewDashboardFiltersAction,
    alertsAction,
    showAlerts,
    viewFiltersAction
  ]);

  return {
    customActions,
    addToDashboardActionModal
  };
};
