import { IncEditor, IncModal, IncModalProps } from "@inception/ui";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { logger, useNotifications } from "../../core";

type Props<T> = {
  jsonValue: T;
  onClose: () => void;
  isOpen: boolean;

  onSave?: (config: T) => void; // If this is absent skip the button
  saveOrUpdateBtnLabel?: string;
  title?: string | JSX.Element;
  readOnly?: boolean;
};

export const JSonConfigEditorModel = <T extends Record<string, any>>(props: Props<T>) => {
  const {
    jsonValue: pJsonValue,
    onSave: pOnSave,
    onClose,
    isOpen,
    readOnly = false,
    saveOrUpdateBtnLabel = "Save",
    title
  } = props;

  const [jsonValue, setJsonValue] = useState<string>(JSON.stringify(pJsonValue, null, 4));

  useEffect(() => {
    setJsonValue(JSON.stringify(pJsonValue, null, 4));
  }, [pJsonValue]);

  const { notifyError } = useNotifications();

  const onSave = useCallback(() => {
    if (!readOnly && pOnSave) {
      try {
        const parsedValue = JSON.parse(jsonValue) as T;
        pOnSave(parsedValue);
        onClose();
      } catch (error) {
        notifyError("Failed to update: JSON parse error");
        logger.warn("JSonConfigEditorModel", "Error converting from JSON", error);
      }
    }
  }, [jsonValue, notifyError, onClose, pOnSave, readOnly]);
  const showActions = Boolean(pOnSave) && !readOnly;

  const actions = useMemo<IncModalProps["actions"]>(
    () =>
      showActions
        ? {
            primary: {
              onClick: onSave,
              label: saveOrUpdateBtnLabel
            },
            secondary: {
              onClick: onClose,
              label: "Cancel"
            }
          }
        : null,
    [onClose, onSave, saveOrUpdateBtnLabel, showActions]
  );

  const titleJsx = useMemo(() => title || (readOnly ? "View Configuration" : "Edit Configuration"), [readOnly, title]);

  return (
    <IncModal
      actions={actions}
      onClose={onClose}
      show={isOpen}
      size="lg"
      titleText={titleJsx}
      withActionsBorder
      withTitleBorder
    >
      <div className="padding24">
        <IncEditor
          maxLines={30}
          minLines={20}
          mode="json"
          onChange={setJsonValue}
          readOnly={readOnly}
          value={jsonValue}
        />
      </div>
    </IncModal>
  );
};
