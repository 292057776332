import { IncFaIcon, IncPill } from "@inception/ui";
import React, { useEffect, useMemo, useState } from "react";
import { VerticallyCenteredRow } from "../../../../components";
import { useToggleState } from "../../../../core";
import {
  FieldPickerContextDTO,
  UserServiceFilterExpressionTree,
  WidgetConfigUtils
} from "../../../../services/api/explore";
import { fetchEntityPropertiesForIds } from "../../../../utils";
import { AdvancedFilterModal } from "./AdvancedFilterModal";

interface EVFVContainerProps {
  filterExprTree: UserServiceFilterExpressionTree;
  onChange: (exprTree: UserServiceFilterExpressionTree) => void;
  onDelete: () => void;
  canEdit?: boolean;
  fieldPickerContext?: FieldPickerContextDTO;
}

export const EventFilterExpressionContainer: React.FC<EVFVContainerProps> = props => {
  const { filterExprTree, onChange, onDelete: onDeleteField, fieldPickerContext, canEdit: canRemove } = props;

  const { close, isOpen, open } = useToggleState();

  const [entityLookUp, setEntityLookUp] = useState<Record<string, string>>({});
  const [isFetchingLookUp, setIsFetchingLookUp] = useState<boolean>(false);

  useEffect(() => {
    const fetchEntityLookup = async (entityIds: string[]) => {
      const entityPropertiesMap = await fetchEntityPropertiesForIds(
        new Set(entityIds.map(x => x?.replace(/['"]+/g, ""))),
        0,
        Date.now()
      );
      const entityLookUp: Record<string, string> = {};
      setIsFetchingLookUp(true);

      entityPropertiesMap.forEach((value, key) => {
        entityLookUp[key] = value.name;
      });
      setEntityLookUp(entityLookUp);
      setIsFetchingLookUp(false);
    };

    const entityIds = WidgetConfigUtils.getEntityIdsFromFilterTree(filterExprTree).filter(Boolean);
    if (entityIds.length > 0) {
      fetchEntityLookup(entityIds);
    }
  }, [filterExprTree]);

  const filterLabel = useMemo(() => {
    const label = WidgetConfigUtils.getUsFilterExpressionTreeLabel(filterExprTree, entityLookUp);
    return label ? (
      <IncPill
        label={label}
        onClick={open}
      />
    ) : null;
  }, [entityLookUp, filterExprTree, open]);

  return (
    <>
      <VerticallyCenteredRow
        className="values-container paddingLt4 flex-gap-6 advanced-filter-container"
        onClick={open}
      >
        {isFetchingLookUp ? "Loading..." : filterLabel}
        <div className="marginLtAuto" />

        <IncFaIcon
          className="inc-cursor-pointer marginRt4"
          iconName="caret-down"
        />
        {canRemove && (
          <IncFaIcon
            className="close-btn-containter inc-cursor-pointer"
            iconName="xmark"
            onClick={onDeleteField}
          />
        )}
      </VerticallyCenteredRow>

      {isOpen && (
        <AdvancedFilterModal
          fieldPickerContext={fieldPickerContext}
          filterExprTree={filterExprTree}
          onChange={onChange}
          onClose={close}
        />
      )}
    </>
  );
};
