import React, { FC, useMemo, useCallback, useState } from "react";
import { IncButton, IncMenuItem, IncMenuV2, IncSelectOption } from "@inception/ui";
import { useLocation } from "react-router";
import { omit, last, cloneDeep, clone } from "lodash";
import { USFieldWidgetImpl } from "../models";
import {
  WidgetResponseDTO,
  CohortEntityFilter,
  MetricUserServiceFilters,
  UserServiceFieldSlice,
  UserServiceFilterExpression
} from "../../../../services/api/explore";
import { useInceptionRoute, useTypedQueryParams } from "../../../../core";
import { OperationaliseModal, Op10zeSourceConfig } from "../../../../components";
import { FieldPickerUtils } from "../../../../utils";
import { getBizFieldPredicatesFromCohortFilters } from "../../utils";
import { USFieldWidgetUtils } from "../USFieldWidgetUtils";
import { extractSliceSetsFromDataDefinition } from "../../../../utils/ExploreUtils";

interface Props {
  widget: USFieldWidgetImpl;
  widgetResponseDTO: WidgetResponseDTO;
  entityFilters?: CohortEntityFilter[];
  eventFilters?: MetricUserServiceFilters;
}

export const Subscribe: FC<Props> = props => {
  const { widget, widgetResponseDTO, entityFilters, eventFilters } = props;

  const { entityType, userServiceId, queryConfig, widgetConfigRefId, bizEntityFieldName, numOpConfigs } = widget;

  const { querySchema } = widgetResponseDTO;

  const location = useLocation();
  const queryParams = useTypedQueryParams();
  const { navigate } = useInceptionRoute();
  const [selectedMetricId, setSelectedMetricId] = useState<string>();

  const [open, setOpen] = useState(false);
  const openModal = useCallback(() => setOpen(true), []);
  const closeModal = useCallback(() => {
    const newParams = omit(queryParams, "opId", "action");
    navigate(location.pathname, {
      queryParams: newParams,
      replace: true
    });
    setOpen(false);
  }, [location.pathname, navigate, queryParams]);

  const { cohortConfig, sourceQueryConfig } = queryConfig;
  const { cohortId = "all" } = cohortConfig || {};

  const sourceConfig = useMemo<Op10zeSourceConfig>(() => {
    if (sourceQueryConfig?.queryType === "userServiceField") {
      let slices: UserServiceFieldSlice[];

      const metrics = widgetResponseDTO?.widgetConfig?.dataDefinition?.metrics;
      if (metrics) {
        const metricDef = Object.values(metrics)[0];
        if (metricDef?.sourceType === "userServiceField") {
          const { userServiceFieldMetricConfig } = metricDef;
          const { sliceSets } = userServiceFieldMetricConfig || {};
          if (sliceSets?.length) {
            slices = last(sliceSets).slices;
          }
        }
      }

      const baseUSField = sourceQueryConfig.usField.userServiceField;
      const isErrorField = USFieldWidgetUtils.isHasErrorField(baseUSField.fieldName);
      const usField = isErrorField ? cloneDeep(baseUSField) : baseUSField;
      usField.fieldName = isErrorField ? USFieldWidgetUtils.hasErrorFieldName : usField.fieldName;
      usField.dataType = isErrorField ? "STRING" : usField.dataType;

      const filterExpressions: UserServiceFilterExpression[] = isErrorField
        ? [
            {
              field: usField,
              operator: "=",
              value: "true"
            }
          ]
        : null;

      return {
        sourceType: "usField",
        usField,
        filterExpressions,
        widgetConfigRefId,
        slices
      };
    } else {
      const widgetDTO = clone(widgetResponseDTO);
      const qsEntries = querySchema?.querySchema || [];
      const qsEntry = qsEntries.find(qs => qs.metricId === selectedMetricId);
      widgetDTO.querySchema = {
        querySchema: [qsEntry]
      };
      return {
        sourceType: "widgetConfig",
        widgetResponseDTO: widgetDTO
      };
    }
  }, [selectedMetricId, querySchema, sourceQueryConfig, widgetConfigRefId, widgetResponseDTO]);

  const exploreEntityFilters = useMemo(() => {
    if (entityFilters.length > 0) {
      let filterPredicates = getBizFieldPredicatesFromCohortFilters(entityFilters);
      filterPredicates = filterPredicates.filter(
        predicate => FieldPickerUtils.getBizFieldLabel(predicate) !== "cohort.id"
      );
      return [
        {
          filters: filterPredicates
        }
      ];
    }
    return [];
  }, [entityFilters]);

  /**
   * metric option population
   */
  const metricOptions = useMemo<IncSelectOption[]>(() => {
    const { widgetConfig, querySchema } = widgetResponseDTO || {};
    if (widgetConfig && querySchema) {
      //get the metrics from widget config
      const metrics = widgetConfig.dataDefinition?.metrics;
      //get the slice set from data definition and query schema
      const slicesByMetric = extractSliceSetsFromDataDefinition(
        widgetConfig.dataDefinition,
        querySchema.querySchema || []
      );
      const opts = Object.keys(slicesByMetric).map(id => {
        const metric = metrics[id];
        return {
          label: metric?.name,
          value: metric?.id.toString()
        } as IncSelectOption;
      });
      //setting the first value as metric id as default
      setSelectedMetricId(opts[0]?.value);
      return opts;
    }
    return [];
  }, [widgetResponseDTO]);

  /**
   * handle selection
   */
  const onMetricSelect = useCallback(
    (menuItem: IncMenuItem) => {
      setSelectedMetricId(menuItem.value);
      openModal();
    },
    [openModal]
  );

  const buttonText = "Operationalize";
  const showMenuButton = metricOptions.length > 1;

  return (
    <>
      {!showMenuButton && (
        <IncButton
          className="subscribe-button"
          color="secondary"
          disabled={!widgetConfigRefId}
          onClick={openModal}
        >
          {buttonText} {numOpConfigs ? `(${numOpConfigs})` : ""}
        </IncButton>
      )}

      {showMenuButton && (
        <IncMenuV2
          label={buttonText}
          menuItems={metricOptions}
          menuLabelClassName="subscribe-button inc-button inc-button-secondary-blue inc-button-regular"
          onMenuItemClick={onMetricSelect}
        ></IncMenuV2>
      )}

      <OperationaliseModal
        bizEntityFieldName={bizEntityFieldName}
        cohortId={cohortId}
        entityFilters={exploreEntityFilters}
        entityTypeId={entityType}
        eventFilters={eventFilters}
        eventTypeId={userServiceId}
        onClose={closeModal}
        open={open}
        sourceConfig={sourceConfig}
      />
    </>
  );
};
