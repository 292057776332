import { useMemo, useEffect } from "react";
import { cloneDeep } from "lodash";
import { SelectorSpec, MetricResultDataDTO, SelectorTag } from "../../../../../../services/api/explore";
import { DataType, logger, shouldExcludeTag, useForceUpdate } from "../../../../../../core";
import { EntityWidgetData } from "../../../../../../biz-entity";
import { DataQueryError, DataQueryResponse } from "../../../../../../services/api/types";
import { CatalogCommonFunctionalityProps } from "./types";
import { useFetchFieldNameMetricNames } from "./useFetchFieldNameMetricNames";
import { useFetchCommonData } from "./useFetchCommonData";

export const useCommonRendererFunctionality = (props: CatalogCommonFunctionalityProps) => {
  const {
    aggregator,
    cohortFilters,
    compareTimeRange,
    entityFilters,
    entityTypeName,
    eventFilters,
    queryConfig,
    queryId,
    seriesLimit,
    timeRange,
    variablesLoading,
    widgetResponseDTO,
    metricId,
    userServiceId,
    onError,
    limitSpecFunction = null,
    dataFetchPayload,
    childrenDataFetchPayload,
    eventTypeName,
    onFetchData,
    demoDataParams,
    visualisation,
    downsample: downsampleStr,
    skipDataFetch = false
  } = props;

  const forceUpdate = useForceUpdate();

  const dataSeriesLimit = useMemo(() => {
    if (dataFetchPayload?.sliceSpec?.[0]?.postAgg?.sortSpec) {
      return -1;
    }

    const numMetrics = (childrenDataFetchPayload?.sliceSpec?.length || 0) + 1;
    return Math.max(Math.floor(seriesLimit / numMetrics), 1);
  }, [childrenDataFetchPayload, dataFetchPayload, seriesLimit]);

  const childrenSeriesLimit = useMemo(() => {
    const selectorSpecsExist = (childrenDataFetchPayload?.sliceSpec || []).every(ss => Boolean(ss.selectorSpec));
    if (selectorSpecsExist) {
      return -1;
    }

    if (!childrenDataFetchPayload) {
      return seriesLimit;
    }

    const numMetrics = childrenDataFetchPayload.sliceSpec.length;
    return Math.max(Math.floor(seriesLimit / numMetrics), 1);
  }, [childrenDataFetchPayload, seriesLimit]);

  const { fieldName, metricName, childMetricNames } = useFetchFieldNameMetricNames(
    aggregator,
    queryConfig,
    eventTypeName
  );

  const parentQueryId = `parent-${queryId}`;
  const {
    data: parentData,
    error: parentDataError,
    isError: parentDataIsError,
    isFetching: parentDataIsFetching,
    queryTimeRange
  } = useFetchCommonData(
    parentQueryId,
    cohortFilters,
    entityFilters,
    eventFilters,
    entityTypeName,
    dataFetchPayload,
    timeRange,
    compareTimeRange,
    userServiceId,
    widgetResponseDTO,
    dataSeriesLimit,
    limitSpecFunction,
    variablesLoading,
    downsampleStr,
    demoDataParams,
    visualisation,
    skipDataFetch
  );

  const fChildrenDataFetchPayload = useMemo(() => {
    if (parentDataIsFetching || !childrenDataFetchPayload) {
      return null;
    }

    if (parentData) {
      const { entityWidgetData, dataError } = getWidgetDataWithError(parentData);

      if (dataError) {
        logger.warn(
          "CatalogWidget",
          "Error fetching parent data, fetching children data without any filters",
          parentData
        );
        return childrenDataFetchPayload;
      }

      const selectorSpec: SelectorSpec = {
        filters: []
      };

      entityWidgetData.forEach(widgetData => {
        const addToFilters = (data: Record<string, MetricResultDataDTO>) => {
          const metricResults = Object.values(data);
          metricResults.forEach(metricResult => {
            const { data: metricResultData } = metricResult;
            metricResultData.forEach(datum => {
              const { labels = {} } = datum;
              const tagKeys = Object.keys(labels).filter(key => !shouldExcludeTag(key));
              const selectorTags: SelectorTag[] = [];

              tagKeys.forEach(key => {
                const value = labels[key];
                selectorTags.push({
                  key,
                  value: [value]
                });
              });

              selectorSpec.filters.push({
                tags: selectorTags
              });
            });
          });
        };

        selectorSpec.filters.forEach(filter => {
          filter.tags = filter.tags.filter(tag => Boolean(tag.key));
        });

        addToFilters(widgetData.postAggResult.data);
      });

      childrenDataFetchPayload.sliceSpec.forEach(sliceSpec => {
        sliceSpec.selectorSpec = selectorSpec;

        if (sliceSpec.postAgg.sortSpec) {
          delete sliceSpec.postAgg.sortSpec;
        }
      });
    }

    return childrenDataFetchPayload;
  }, [childrenDataFetchPayload, parentData, parentDataIsFetching]);

  const childrenQueryId = `children-${queryId}`;
  const {
    data: childData,
    error: childDataError,
    isError: childDataIsError,
    isFetching: childDataIsFetching
  } = useFetchCommonData(
    childrenQueryId,
    cohortFilters,
    entityFilters,
    eventFilters,
    entityTypeName,
    fChildrenDataFetchPayload,
    queryTimeRange,
    compareTimeRange,
    userServiceId,
    widgetResponseDTO,
    childrenSeriesLimit,
    limitSpecFunction,
    variablesLoading,
    downsampleStr,
    demoDataParams,
    visualisation,
    skipDataFetch
  );

  useEffect(() => {
    forceUpdate();
  }, [parentDataIsFetching, childDataIsFetching, forceUpdate]);

  const downloadDataPayload = useMemo(() => {
    const downloadDataPayload = cloneDeep(dataFetchPayload);
    if (fChildrenDataFetchPayload) {
      downloadDataPayload.sliceSpec.push(...fChildrenDataFetchPayload.sliceSpec);
    }
    return downloadDataPayload;
  }, [dataFetchPayload, fChildrenDataFetchPayload]);

  const isChildDataFetching = fChildrenDataFetchPayload ? childDataIsFetching || !childData : false;
  const isParentDataFetching = parentDataIsFetching || !parentData;
  const isFetching = isParentDataFetching || isChildDataFetching;
  const isError = parentDataIsError || childDataIsError;
  const error = parentDataError || childDataError;
  const data = useMemo(() => {
    if (isFetching) {
      return parentData || [];
    }

    return [...(parentData || []), ...(childData || [])];
  }, [childData, isFetching, parentData]);

  useEffect(() => {
    onFetchData(isFetching, isError, data);
  }, [data, isError, isFetching, onFetchData]);

  const { entityWidgetData, dataError } = useMemo(() => getWidgetDataWithError(data), [data]);

  const dataExists = !isError && !dataError && entityWidgetData?.length > 0 && Boolean(entityWidgetData[0]?.data);

  useEffect(() => {
    if (isError || dataError) {
      const err = error || dataError;
      onError(err.toString());
    } else {
      onError("");
    }
  }, [dataError, error, isError, onError]);

  const fData = useMemo<EntityWidgetData[]>(
    () =>
      entityWidgetData?.length
        ? entityWidgetData
        : [
            {
              compareConfigData: {},
              compareDataSize: 0,
              data: {},
              dataDefinitionId: metricId,
              dataQueryConfig: null,
              dataSize: 0,
              postAggDataSize: 0,
              postAggResult: {
                data: {},
                entityLookupData: {},
                percentChangeData: {},
                timeShiftData: {}
              },
              warnings: []
            }
          ],
    [entityWidgetData, metricId]
  );

  const parentSliceSpec = dataFetchPayload?.sliceSpec;
  const childSliceSpec = fChildrenDataFetchPayload?.sliceSpec;

  const { tagVsDataTypeMap, tagVsEntityTypeMap } = useMemo(() => {
    const tagVsDataTypeMap: Record<string, DataType> = {};
    const tagVsEntityTypeMap: Record<string, string> = {};

    const sliceSpec = [...(parentSliceSpec || []), ...(childSliceSpec || [])];
    sliceSpec.forEach(sliceSpec => {
      (sliceSpec.sliceSet?.slices || []).forEach(sl => {
        tagVsDataTypeMap[sl.tagName] = sl.fieldType;
        tagVsEntityTypeMap[sl.tagName] = sl.entityTypeName;
      });
    });

    return {
      tagVsEntityTypeMap,
      tagVsDataTypeMap
    };
  }, [childSliceSpec, parentSliceSpec]);

  return {
    data: fData,
    dataExists,
    error,
    isError,
    isFetching,
    fieldName,
    metricName,
    childMetricNames,
    hasChildMetrics: Boolean(fChildrenDataFetchPayload),
    downloadDataPayload,
    tagVsDataTypeMap,
    tagVsEntityTypeMap
  };
};

const getWidgetDataWithError = (data: Array<DataQueryResponse<EntityWidgetData[]>>) => {
  let dataErrObj: DataQueryError = {
    data: null,
    message: ""
  };

  const entityWidgetData: EntityWidgetData[] = [];

  data.forEach(d => {
    const { data = [], error } = d;

    dataErrObj = dataErrObj || error;
    entityWidgetData.push(...data);
  });

  const dataError = dataErrObj?.message || "";

  return {
    entityWidgetData,
    dataError
  };
};
