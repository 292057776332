import { isEqual } from "lodash";
import React, { FC, useEffect, useState } from "react";
import {
  ActionTemplateElementProp,
  ElementPropertyValues,
  SectionElementType,
  TemplateCanvasSection
} from "../../../../../../services/api/operationalise";
import { SectionPropertiesEditor } from "../../section-properties";

interface Props {
  textElementProperties: ActionTemplateElementProp[];
  properties: ElementPropertyValues;
  onChange: (properties: ElementPropertyValues) => void;
}

export const TextPropertiesRenderer: FC<Props> = props => {
  const { properties, textElementProperties, onChange } = props;

  const [section, setSection] = useState(getSection(properties));

  useEffect(() => {
    setSection(prevSection => {
      const nextSection = getSection(properties);
      return isEqual(prevSection, nextSection) ? prevSection : nextSection;
    });
  }, [properties]);

  useEffect(() => {
    const nProperties = section.prop;
    onChange(nProperties);
  }, [onChange, section]);

  return (
    <SectionPropertiesEditor
      onChange={setSection}
      properties={textElementProperties}
      section={section}
    />
  );
};

const getSection = (properties: ElementPropertyValues): TemplateCanvasSection => ({
  sectionElementId: "rich-text-section",
  sectionId: "rich-text-section",
  type: SectionElementType.RICH_TEXT,
  prop: properties
});
