import React, { FC, useMemo } from "react";
import { OpResultsRenderer } from "../../operationalise-v2/v3/results/OpResultsRenderer";
import { OpCreationConfig } from "../../services/api/operationalise";
import { DemoDataParams, WidgetConfigUtils } from "../../services/api/explore";

interface Props {
  widgetId: string;
  opConfigId: string;
  opCreationConfig: OpCreationConfig;

  demoDataParams?: DemoDataParams;
}

export const OpRecipeResults: FC<Props> = props => {
  const { opConfigId, opCreationConfig, demoDataParams, widgetId } = props;
  const { entityTypeId: entityTypeName } = useMemo(
    () => WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(opCreationConfig.idProps),
    [opCreationConfig.idProps]
  );

  return (
    <div className="op-v3-results inc-flex-column width-100 flex-gap-12">
      <OpResultsRenderer
        demoDataParams={demoDataParams}
        entityTypeId={entityTypeName}
        entityTypeName={entityTypeName}
        isInProgress={false}
        isPrimarySimulationOrOp
        opConfigId={opConfigId}
        opCreationConfig={opCreationConfig}
        widgetId={widgetId}
      />
    </div>
  );
};
