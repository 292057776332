import { IncButton, IncClickAwayPopper, IncFaIcon, IncPill, IncToolTip } from "@inception/ui";
import { cloneDeep, isArray } from "lodash";
import React, { useEffect, useMemo, useState, useCallback, useRef } from "react";
import { CustomSelect, QuickFiltersPicker } from "../../../components";
import { InfoPopper } from "../../../components/CustomSelect";
import { useTenantConfig, useTimeRange } from "../../../core";
import { BizFieldInfo, BizFieldPredicate, EntityOperation } from "../../../services/api/explore";
import { EntityAggregationBuckets, EntityAggregationSuggestion } from "../../../services/api/types";
import {
  FieldPickerUtils,
  getBizFieldsWithAggregations,
  HighCardinalityEntityFieldNames,
  noOp,
  Options
} from "../../../utils";
import CohortVariableImpl from "../../model-impl/CohortVariableImpl";
import VariableImpl from "../../model-impl/VariableImpl";
import { BizFieldPredicateWithVarProperies } from "../../models/VariableModel";
import { VariableSrv } from "../../variables";
import { getEntityVariableModel } from "../../variables/CohortVariableUtils";
import { processAggregationsResponse } from "../utils";

interface Props {
  entityTypeId: string;
  variableSrv: VariableSrv;
  onVariablesUpdate: (variables: VariableImpl[], widgetId: string) => void;
  cohortId: string;
  widgetId: string;
  pillLimit?: number;
  disabled?: boolean;
  label?: string;
  containerClassName?: string;
  placeholder?: string;
}

export const EntityFieldPicker: React.FC<Props> = (props: Props) => {
  const {
    cohortId,
    entityTypeId,
    variableSrv,
    pillLimit = 2,
    disabled = false,
    widgetId,
    label = "Entity Criteria",
    containerClassName,
    placeholder = "Select",
    onVariablesUpdate
  } = props;

  const bizFieldPredicatesWithProperties = useMemo(
    () => variableSrv.getCohortVariablePredicatesWithProperties(true),
    [variableSrv]
  );

  const [bizFields, setBizFields] = useState<BizFieldInfo[]>([]);
  const [suggAggregationsMap, setSuggAggregationsMap] = useState<Record<string, EntityAggregationSuggestion>>({});
  const [aggregations, setAggregations] = useState<Record<string, Record<string, EntityAggregationBuckets>>>({});
  const [bizFieldPredicates, setBizFieldPredicates] = useState<BizFieldPredicateWithVarProperies[]>(
    bizFieldPredicatesWithProperties
  );
  const [loadingBizField, setLoadingBizFields] = useState(false);
  const [showPopper, setShowPopper] = useState(false);
  const ref = useRef(null);

  const { tenantConfigState } = useTenantConfig();
  const options: Options = useMemo(() => ({ currency: tenantConfigState?.currency || "USD" }), [tenantConfigState]);

  const openPopper = useCallback(() => setShowPopper(true), []);
  const closePopper = useCallback(() => setShowPopper(false), []);

  const {
    timeRange: { from, to }
  } = useTimeRange();

  const getBizFields = useCallback(async () => {
    if (entityTypeId) {
      setLoadingBizFields(true);
      const { bizFields, suggAggregationsMap, aggregations } = await getBizFieldsWithAggregations(
        cohortId,
        entityTypeId,
        from.valueOf(),
        to.valueOf()
      );
      // const filteredBizFields = bizFields.filter(x => x.bizField?.entityField?.propName !== "Id");
      setBizFields(bizFields);
      setSuggAggregationsMap(suggAggregationsMap);
      setAggregations(aggregations);
      setLoadingBizFields(false);
    }
  }, [cohortId, entityTypeId, from, to]);

  useEffect(() => {
    if (!bizFields.length) {
      getBizFields();
    }
  }, [bizFields.length, getBizFields]);

  const removePredicate = useCallback(
    (propName: string) => {
      const tBizFieldPredicates = bizFieldPredicatesWithProperties.filter(
        x => x.predicate.bizField.entityField.propName !== propName
      );
      const entityVariables: CohortVariableImpl[] = [];
      tBizFieldPredicates.forEach(bizField => {
        const varImpl = getEntityVariableModelFromBizFieldPredicate(
          bizField.predicate,
          entityTypeId,
          bizField.isPinned
        );
        if (varImpl) {
          entityVariables.push(varImpl);
        }
      });
      onVariablesUpdate(entityVariables, widgetId);

      // onUpdateFilters(tBizFieldPredicates);
    },
    [bizFieldPredicatesWithProperties, entityTypeId, onVariablesUpdate, widgetId]
  );

  const applyFilters = useCallback(() => {
    closePopper();
    const entityVariables: CohortVariableImpl[] = [];
    bizFieldPredicates.forEach(bizField => {
      const varImpl = getEntityVariableModelFromBizFieldPredicate(bizField.predicate, entityTypeId, bizField.isPinned);
      if (varImpl) {
        entityVariables.push(varImpl);
      }
    });
    onVariablesUpdate(entityVariables, widgetId);
  }, [bizFieldPredicates, closePopper, entityTypeId, onVariablesUpdate, widgetId]);

  const onCancel = useCallback(() => {
    setBizFieldPredicates(cloneDeep(bizFieldPredicatesWithProperties));
    closePopper();
  }, [bizFieldPredicatesWithProperties, closePopper]);

  const onChange = useCallback(
    (fieldName: string, value: string | string[], operator: EntityOperation, isPinned: boolean) => {
      const tBizFieldPredicates = [...bizFieldPredicates];
      let fieldExists = false;
      tBizFieldPredicates.forEach(x => {
        if (x.predicate?.bizField?.entityField?.propName === fieldName) {
          x.predicate.op = operator;
          if (isArray(value)) {
            x.predicate.value = "";
            x.predicate.values = value;
          } else {
            x.predicate.value = value;
            x.predicate.values = [];
          }
          x.isPinned = isPinned;
          fieldExists = true;
        }
      });
      if (!fieldExists) {
        const bizField = bizFields.find(x => x.bizField?.entityField?.propName === fieldName);
        const predicate: BizFieldPredicate = {
          bizField: bizField.bizField,
          op: operator,
          value: isArray(value) ? "" : value,
          values: isArray(value) ? value : []
        };
        tBizFieldPredicates.push({
          predicate,
          isPinned
        });
      }
      // tBizFieldPredicates = tBizFieldPredicates.filter(x => x.predicate.value || x.predicate.values.length > 0);
      setBizFieldPredicates(tBizFieldPredicates);
    },
    [bizFieldPredicates, bizFields]
  );

  const quickFilterFields = useMemo(
    () =>
      bizFields
        .map(bizField => {
          const { propName, propType, kindDescriptor } = bizField.bizField.entityField;
          const aggregationMeta = suggAggregationsMap[propName];
          const cardinality = suggAggregationsMap[propName]?.aggregationMeta?.cardinality;
          const fieldPredicate = bizFieldPredicates.find(
            x => x.predicate?.bizField?.entityField?.propName === propName
          );
          const predicate = fieldPredicate?.predicate;
          const op = predicate?.op || null;
          const value = predicate?.values.length > 0 ? predicate.values : predicate?.value || "";
          const isHighCardinalityField = HighCardinalityEntityFieldNames.includes(propName);

          const { aggValues } = processAggregationsResponse(
            {
              aggregations,
              entityType: entityTypeId
            },
            null,
            propName
          );
          const aggregationValues = aggValues || [];

          return {
            id: propName,
            fieldName: propName,
            fieldType: propType,
            op: op,
            value: value,
            isHighCardinalityField,
            kindDescriptor,
            entityTypeId,
            cohortId,
            cardinality,
            aggregationMeta: aggregationMeta?.aggregationMeta,
            aggregationValues,
            isPinned: fieldPredicate?.isPinned
          };
        })
        .sort(a => (a.fieldName === "Name" ? -1 : 0)),
    [aggregations, bizFieldPredicates, bizFields, entityTypeId, suggAggregationsMap, cohortId]
  );

  const valueLabel = useMemo(() => {
    const filteredPredicates =
      bizFieldPredicatesWithProperties?.filter(x => Boolean(x?.predicate?.value) || x?.predicate?.values?.length > 0) ||
      [];
    const labels = filteredPredicates
      .map(bizField => {
        if (bizField?.predicate?.bizField) {
          const {
            bizField: {
              entityField: { propName }
            }
          } = bizField?.predicate || {};
          const { label } = FieldPickerUtils.getBizFieldPredicatePillLabelAndInfo(bizField?.predicate, options);
          return {
            label,
            propName,
            onRemove: () => removePredicate(propName)
          };
        }
        return null;
      })
      .filter(x => x !== null);

    if (loadingBizField) {
      return <span className="inc-text-subtext">Loading...</span>;
    } else if (labels.length) {
      const displayLabels = labels.splice(0, pillLimit);
      const pills = displayLabels.map((x, i) => {
        const { label } = x;
        const { propName } = x;

        return (
          <IncPill
            className="pill-container"
            key={`${propName}-${i}`}
            label={label}
            labelClass={"text-ellipsis"}
            onClick={openPopper}
            onRemove={disabled ? null : x.onRemove}
            readonly={disabled}
          />
        );
      });

      const diff = labels.length;
      const hoverElement = InfoPopper(labels.map(x => x.label));

      return (
        <div className="inc-flex-row cohort-biz-picker-pills">
          {pills}
          {labels.length > 0 && (
            <IncToolTip
              placement="bottom"
              titleElement={hoverElement}
            >
              <span className="inc-text-subtext inc-link marginTp4">{`+${diff}`}</span>
            </IncToolTip>
          )}
        </div>
      );
    }
    return null;
  }, [bizFieldPredicatesWithProperties, loadingBizField, options, removePredicate, pillLimit, openPopper, disabled]);

  const supportPinning = tenantConfigState.disableNewDashboardFilterPanel;

  const selectDisabled = loadingBizField || disabled;
  return (
    <>
      <CustomSelect
        anchor={ref}
        className={containerClassName}
        disabled={selectDisabled}
        hideSelectIconWhenDisabled
        label={label}
        labelPosition="left"
        onClick={openPopper}
        placeholderText={placeholder}
        value={valueLabel}
      />
      <IncClickAwayPopper
        anchorEl={ref.current}
        onClickAway={noOp}
        overlay
        placement="bottom"
        show={showPopper}
      >
        <div className="cohort-biz-fields-popper">
          <div className="header-container">
            <span className="inc-text-header-medium">Business Criteria</span>
            <IncFaIcon
              className="icon"
              iconName="close"
              onClick={onCancel}
            />
          </div>
          <div className="content">
            <QuickFiltersPicker
              fields={quickFilterFields}
              onChange={onChange}
              options={options}
              supportPinning={supportPinning}
            />
          </div>
          <div className="footer-container">
            <IncButton
              className="marginRt12"
              color="primary"
              onClick={applyFilters}
            >
              Apply
            </IncButton>
            <IncButton
              className="red-link"
              color="link"
              onClick={onCancel}
            >
              Cancel
            </IncButton>
          </div>
        </div>
      </IncClickAwayPopper>
    </>
  );
};

export const getEntityVariableModelFromBizFieldPredicate = (
  predicate: BizFieldPredicate,
  entityTypeId: string,
  isPinned = false,
  isLocked?: boolean
) => {
  const { bizField, value, values, op } = predicate;
  if (bizField && bizField.entityField) {
    const { entityField } = bizField;
    const { propName } = entityField;
    const varModel = getEntityVariableModel(entityTypeId, "", propName, bizField);
    const varImpl = new CohortVariableImpl(varModel);
    varImpl.isPinned = isPinned;
    varImpl.isLocked = isLocked;
    varImpl.operator = op;
    varImpl.value = values.length > 0 ? values : value;
    return varImpl;
  }
};
