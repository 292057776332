import React, { FC, useMemo, memo, useCallback } from "react";
import { GroupedBars } from "../../bar-group";
import LoadingSpinner from "../../Loading/Loading";
import { ENTITY_TAG, getBizFieldPredicateForEntityField } from "../../../utils";
import { UserServiceFilterExpression } from "../../../services/api/explore";
import { getDefaultFilters, getGroupedBarRows, getPropsFromPreset } from "./common";
import { DrilldownVizRenderer, DrilldownVizRendererProps } from "./DrilldownVizRenderer";
import { DrilldownSubRendererProps } from "./types";

export const SliceDistribution: FC<DrilldownSubRendererProps> = memo(props => {
  const { preset, onAddEntityFilter, onAddEventFilter } = props;

  const { slice } = getPropsFromPreset(preset, "distribution");
  const { tagName, userServiceField } = slice;

  const { entityField } = userServiceField;

  const isValidEntityFilter = tagName === ENTITY_TAG && Boolean(entityField);
  const isValidEventFieldFilter = tagName !== ENTITY_TAG;

  const shouldShowAddFilter = Boolean(onAddEntityFilter) || Boolean(onAddEventFilter);
  const onAddFilter = useCallback(
    (value: string, rawValue: string) => {
      if (shouldShowAddFilter) {
        if (isValidEntityFilter) {
          const predicate = getBizFieldPredicateForEntityField(entityField, value);
          onAddEntityFilter(predicate);
        }

        if (isValidEventFieldFilter) {
          const filExpr: UserServiceFilterExpression = {
            field: userServiceField,
            operator: "=",
            value: rawValue
          };
          onAddEventFilter(filExpr);
        }
      }
    },
    [
      entityField,
      isValidEntityFilter,
      isValidEventFieldFilter,
      onAddEntityFilter,
      onAddEventFilter,
      shouldShowAddFilter,
      userServiceField
    ]
  );

  const payloadProps = useMemo(
    () => ({
      groupBy: [tagName],
      ...getDefaultFilters()
    }),
    [tagName]
  );

  const vizRenderer = useCallback<DrilldownVizRendererProps["vizRenderer"]>(
    (state, ref) => {
      const { data, error, isError, isFetching } = state;

      const { bars, totalValue } = getGroupedBarRows(data, tagName);

      bars.forEach(bar => {
        const { name, custom } = bar;
        const rawValue = custom?.rawValue || name;

        bar.events = {
          click: () => onAddFilter(name, rawValue)
        };
      });

      return (
        <div
          className="slice-distribution width-100"
          ref={ref}
        >
          {isFetching && <LoadingSpinner />}
          {!isFetching && (
            <>
              {isError && <div className="error-message">Error fetching data: {error}</div>}
              {!isError && (
                <>
                  {!bars.length && <div className="no-data-message">No data found</div>}
                  {Boolean(bars.length) && (
                    <GroupedBars
                      entries={bars}
                      totalValue={totalValue}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      );
    },
    [onAddFilter, tagName]
  );

  return (
    <DrilldownVizRenderer
      {...payloadProps}
      {...props}
      vizRenderer={vizRenderer}
    />
  );
});
