import { IncSmartText } from "@inception/ui";
import React, { useCallback, useMemo } from "react";
import { VerticallyCenteredRow } from "../../../../components";
import { UserServiceFilterExpressionTree } from "../../../../services/api/explore";
import { FieldPickerUtils } from "../../../../utils";
import EventVariableImpl from "../../../model-impl/EventVariableImpl";
import VariableImpl from "../../../model-impl/VariableImpl";
import { EventExpressionVariableModel, EventVariableModel, VariableType } from "../../../models/VariableModel";
import { VariableSrv } from "../../../variables";
import EventExpressionVariableImpl from "../../../model-impl/EventExpressionVariableImpl";
import { EventFilterExpressionContainer } from "./EventFilterExpressionContainer";
import { EventFilterValuesContainer } from "./EventFilterValuesContainer";

interface EVFProps {
  widgetId: string;
  variableSrv: VariableSrv;
  onVariablesUpdated: (variables: VariableImpl[], widgetId: string) => void;
  canEdit?: boolean;
  isInternalUser?: boolean;
}

export const EventFiltersContainer: React.FC<EVFProps> = (props: EVFProps) => {
  const { onVariablesUpdated, variableSrv, widgetId, canEdit = false, isInternalUser } = props;

  const { eventExprVars, eventVars, restVariables } = useMemo(() => {
    const restVariables: VariableImpl[] = [];
    const eventVars: EventVariableImpl[] = [];
    const eventExprVars: EventExpressionVariableImpl[] = [];

    variableSrv.getVariables().forEach(x => {
      if (x.type === VariableType.Event) {
        eventVars.push(x as EventVariableImpl);
      } else if (x.type === VariableType.EventExpression) {
        eventExprVars.push(x as EventExpressionVariableImpl);
      } else {
        restVariables.push(x);
      }
    });

    return {
      restVariables,
      eventVars,
      eventExprVars
    };
  }, [variableSrv]);

  const onEventVariablesChange = useCallback(
    (eventVars: VariableImpl[]) => {
      const nextVariables = [...restVariables, ...eventVars, ...eventExprVars];
      onVariablesUpdated(nextVariables, widgetId);
    },
    [eventExprVars, onVariablesUpdated, restVariables, widgetId]
  );

  const onExprVarChange = useCallback(
    (index: number, selectedValues: string[], op: string) => {
      const nextVariables = eventVars.map((variable, idx) => {
        const evModel = variable.getSaveModel() as EventVariableModel;

        if (index === idx) {
          const operator = op ? op : selectedValues?.length > 1 ? "in" : "=";
          const value = selectedValues?.length > 1 ? "" : selectedValues[0] || "";
          const values = selectedValues?.length > 1 ? selectedValues : [];
          evModel.operator = operator;
          evModel.value = value ? value : values;
        }
        return new EventVariableImpl(evModel);
      });

      onEventVariablesChange(nextVariables);
    },
    [eventVars, onEventVariablesChange]
  );

  const onDeleteExprField = useCallback(
    (index: number) => {
      const nextVariables = eventVars
        .filter((variable, idx) => idx !== index)
        .map(variable => {
          const evModel = variable.getSaveModel() as EventVariableModel;
          return new EventVariableImpl(evModel);
        });
      onEventVariablesChange(nextVariables);
    },
    [eventVars, onEventVariablesChange]
  );

  const onToggleLockExprField = useCallback(
    (index: number) => {
      const nextVariables = eventVars.map((variable, idx) => {
        const evModel = variable.getSaveModel() as EventVariableModel;
        if (index === idx) {
          evModel.isLocked = !evModel.isLocked;
        }
        return new EventVariableImpl(evModel);
      });
      onEventVariablesChange(nextVariables);
    },
    [eventVars, onEventVariablesChange]
  );

  const simpleFilters = useMemo(
    () =>
      eventVars.map((variable, idx) => {
        const expr = variable.getFilterExpression();
        const isVariableLocked = variable.isLocked;
        const key = `filter-${idx}`;

        const { field } = expr;
        const fieldLabel = field ? FieldPickerUtils.getUserServiceFieldLabel(field) : "";
        const onChangeInternal = (selectedValues: string[], op: string) => onExprVarChange(idx, selectedValues, op);
        const onDeleteInternal = () => onDeleteExprField(idx);
        const onToggleLockInternal = () => onToggleLockExprField(idx);

        return (
          <VerticallyCenteredRow
            className={`generic-filter ${!canEdit && isVariableLocked ? (isInternalUser ? "visible-locked-filter" : "hidden-locked-filter") : ""}`}
            key={key}
          >
            <IncSmartText
              className="label inc-text-color-secondary inc-text-subtext"
              text={fieldLabel}
            />
            <EventFilterValuesContainer
              canEdit={canEdit}
              filterExpr={expr}
              isInternalUser={isInternalUser}
              isLocked={isVariableLocked}
              onChange={onChangeInternal}
              onDelete={onDeleteInternal}
              onToggleLock={onToggleLockInternal}
            />
          </VerticallyCenteredRow>
        );
      }),
    [eventVars, canEdit, isInternalUser, onExprVarChange, onDeleteExprField, onToggleLockExprField]
  );

  const onEventExprVariablesChange = useCallback(
    (eventExprVars: VariableImpl[]) => {
      const nextVariables = [...restVariables, ...eventExprVars, ...eventVars];
      onVariablesUpdated(nextVariables, widgetId);
    },
    [eventVars, onVariablesUpdated, restVariables, widgetId]
  );

  const onExprTreeVarChange = useCallback(
    (variableId: string, expressionTree: UserServiceFilterExpressionTree) => {
      const nextVariables = eventExprVars.map(variable => {
        const evModel = variable.getSaveModel() as EventExpressionVariableModel;

        if (variableId === variable.id) {
          evModel.expressionTree = expressionTree;
        }
        return new EventExpressionVariableImpl(evModel);
      });

      onEventExprVariablesChange(nextVariables);
    },
    [eventExprVars, onEventExprVariablesChange]
  );

  const onDeleteExprTreeField = useCallback(
    (variableId: string) => {
      const nextVariables = eventExprVars
        .filter(variable => variable.id !== variableId)
        .map(variable => {
          const evModel = variable.getSaveModel() as EventExpressionVariableModel;
          return new EventExpressionVariableImpl(evModel);
        });
      onEventExprVariablesChange(nextVariables);
    },
    [eventExprVars, onEventExprVariablesChange]
  );

  const exprFilters = useMemo(
    () =>
      eventExprVars.map(variable => {
        const { id } = variable;
        const exprTree = variable.getFilterExpressionTree();
        const key = `filter-expr-${id}`;

        const onChangeInternal = (exprTree: UserServiceFilterExpressionTree) => onExprTreeVarChange(id, exprTree);
        const onDeleteInternal = () => onDeleteExprTreeField(id);

        return (
          <VerticallyCenteredRow
            className="generic-filter"
            key={key}
          >
            <EventFilterExpressionContainer
              canEdit={canEdit}
              filterExprTree={exprTree}
              onChange={onChangeInternal}
              onDelete={onDeleteInternal}
            />
          </VerticallyCenteredRow>
        );
      }),
    [eventExprVars, canEdit, onExprTreeVarChange, onDeleteExprTreeField]
  );

  return (
    <>
      {simpleFilters}
      {exprFilters}
    </>
  );
};
