import {
  IncButton,
  IncClickAwayPopper,
  IncErrorIcon,
  IncFaIcon,
  IncModal,
  IncModalProps,
  IncToolTip
} from "@inception/ui";
import React, { Component, createRef, RefObject } from "react";
import { VerticallyCenteredRow } from "../../../../../../components";
import { TemplateCanvasSection } from "../../../../../../services/api/operationalise";
import { updateBlockDataInEditorState } from "../../utils";
import { getCommonStateFromProps, getErrorStateForSection } from "../propToState";
import { CustomBlockProps, CustomBlockState } from "../types";
import { ToolbarPropertiesRenderer } from "./ToolbarPropertiesRenderer";

export class ToolbarRenderer extends Component<CustomBlockProps, CustomBlockState> {
  private ref: RefObject<HTMLDivElement> = createRef();

  constructor(props: CustomBlockProps) {
    super(props);

    const partState = getCommonStateFromProps(props);
    this.state = {
      isSettingsOpen: false,
      isPropertiesOpen: false,
      ...partState
    };

    props.blockProps.onErrors?.(partState.errors, partState.section.sectionId);
  }

  setReadOnly = () => {
    const { onChangeReadOnly } = this.props.blockProps;
    onChangeReadOnly(true);
  };

  unSetReadOnly = () => {
    const { onChangeReadOnly } = this.props.blockProps;
    onChangeReadOnly(false);
  };

  openSettings = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({
      isSettingsOpen: true
    });
  };

  closeSettings = () => {
    this.setState({
      isSettingsOpen: false
    });
  };

  openProperties = (e: React.MouseEvent) => {
    this.setReadOnly();

    e.stopPropagation();
    this.setState({
      isPropertiesOpen: true,
      isSettingsOpen: false
    });
  };

  closeProperties = () => {
    this.unSetReadOnly();

    this.setState({
      isPropertiesOpen: false,
      isSettingsOpen: false
    });
  };

  onSectionChange = (section: TemplateCanvasSection) => {
    this.setState({
      section
    });
  };

  onApplyChanges = () => {
    this.unSetReadOnly();

    const { block, blockProps } = this.props;
    const { onEditorStateChange, onErrors, beforeEditorStateChange } = blockProps;

    const { section } = this.state;

    const blockKey = block.getKey();
    onEditorStateChange(prevEditorState => {
      beforeEditorStateChange();

      const nextEditorState = updateBlockDataInEditorState(prevEditorState, blockKey, {
        section
      });
      return nextEditorState;
    });

    const errorState = getErrorStateForSection(this.state);
    this.setState({
      isPropertiesOpen: false,
      isSettingsOpen: false,
      ...errorState
    });

    onErrors(errorState.errors, section.sectionId);
  };

  actions: IncModalProps["actions"] = {
    primary: {
      id: "common.actions.apply",
      color: "secondary-blue",
      onClick: this.onApplyChanges
    },
    secondary: {
      id: "common.actions.cancel",
      color: "secondary-red",
      onClick: this.closeProperties
    }
  };

  render() {
    const { blockProps, block, children } = this.props;
    const { onSectionDelete, onErrors } = blockProps;

    const { isSettingsOpen, isPropertiesOpen, sectionProperties, section, isValid, displayErrorMessage } = this.state;

    const onDelete = () => {
      onSectionDelete(block.getKey());
      onErrors([], section.sectionId);
    };

    const { prop, sectionId, sectionElementId } = section;

    const propsExist = sectionProperties?.length > 0;

    const buttons: JSX.Element[] = [];

    Object.keys(prop?.propValue || {}).forEach(propId => {
      const matchingProp = sectionProperties.find(prop => prop.propId === propId);
      const propType = matchingProp?.type;

      if (propType === "_bool" || propType === "_boolean") {
        const label = (matchingProp.label || "").replace("Show ", "");
        const shouldShow = prop?.propValue[propId]?.booleanVal;

        const key = [sectionElementId, sectionId, propId].join("-");

        if (shouldShow) {
          buttons.push(
            <IncButton
              className="marginRt12"
              color="secondary-blue"
              disabled
              key={key}
            >
              {label}
            </IncButton>
          );
        }
      }
    });

    const numButtons = buttons.length;

    return (
      <div
        className="template-editor-v2--section toolbar-section"
        ref={this.ref}
      >
        <div
          className="section-content"
          contentEditable="false"
          onClick={this.openSettings}
          suppressContentEditableWarning
        >
          <VerticallyCenteredRow className="inc-text-subtext-medium">
            {Boolean(numButtons) && buttons}
            {!numButtons && "Click to enable actions to view them here"}

            {!isValid && (
              <IncToolTip
                placement="top"
                showArrow
                titleText={displayErrorMessage}
                variant="error"
              >
                <VerticallyCenteredRow className="error-icon">
                  <IncErrorIcon />
                </VerticallyCenteredRow>
              </IncToolTip>
            )}
          </VerticallyCenteredRow>
        </div>

        <div
          className="marginLt6 editable-content"
          suppressContentEditableWarning
        >
          {children}
        </div>

        <IncClickAwayPopper
          anchorEl={this.ref.current}
          className="section-settings"
          onClickAway={this.closeSettings}
          placement="bottom-start"
          show={isSettingsOpen}
        >
          <VerticallyCenteredRow>
            {propsExist && (
              <>
                <div
                  className="section-settings--option"
                  onClick={this.openProperties}
                >
                  <IncFaIcon
                    className="marginRt6"
                    iconName="edit"
                  />
                  Edit
                </div>

                <div className="section-settings--separator"></div>
              </>
            )}

            <div
              className="section-settings--option status-danger"
              onClick={onDelete}
            >
              <IncFaIcon
                className="marginRt6"
                iconName="minus-circle"
              />
              Delete
            </div>
          </VerticallyCenteredRow>
        </IncClickAwayPopper>

        <IncModal
          actions={this.actions}
          className="section-properties"
          disableFocusOnLoad
          onClose={this.closeProperties}
          show={isPropertiesOpen}
          size="md"
          titleText="Edit"
        >
          <ToolbarPropertiesRenderer
            onChange={this.onSectionChange}
            properties={sectionProperties}
            section={section}
          />
        </IncModal>
      </div>
    );
  }
}
