import React from "react";
import { IncFaIcon } from "@inception/ui";
import { widgetRegistry, Dimensions } from "../WidgetRegistry";
import TextWidget from "./TextWidget";
import TextWidgetPropertiesPanel from "./TextPropertiesPanel";

widgetRegistry.register({
  id: "plain-text",
  label: "Plain Text",
  enableInDashboard: false,
  supportsComparison: false,
  supportsNativeEdit: false,
  iconComponent: (): JSX.Element => <IncFaIcon iconName="text" />,
  component: props => <TextWidget {...props} />,
  dimensions: (): Dimensions => ({
    w: 24,
    h: 2,
    minW: 8,
    minH: 2
  }),
  propertiesPanelComponent: props => <TextWidgetPropertiesPanel {...props} />
});
