import React, { FC, useMemo } from "react";
import { OpCreationConfig } from "../../services/api/operationalise";
import { useVerticalConfig } from "../../core";
import { SubscribePreviewModal } from "./SubscribePreview";

interface Props {
  title: string;
  opCreationConfig: OpCreationConfig;
  onClose: () => void;

  useCaseId?: string;
}

export const SubscribeDialogForOperationalize: FC<Props> = props => {
  const { title, opCreationConfig, onClose, useCaseId: defUseCaseId } = props;

  const { verticalConfig } = useVerticalConfig();
  const { companyName, subVerticalId, verticalId, useCaseId } = verticalConfig;

  const verticalInfo = useMemo(
    () => ({
      vertical: verticalId,
      subVertical: subVerticalId,
      companyName
    }),
    [companyName, subVerticalId, verticalId]
  );

  const { name: opName, diagnosticFields, opCreationConfigDef, impactFields } = opCreationConfig || {};

  const { alertingFields: alertingSlices, diagnosticFields: diagnosticSlices } = useMemo(
    () => ({
      alertingFields: opCreationConfigDef?.bizDataQuery?.sliceSpec?.sliceSet?.slices || [],
      diagnosticFields: diagnosticFields?.slices?.length ? diagnosticFields.slices : impactFields?.slices
    }),
    [diagnosticFields, impactFields, opCreationConfigDef]
  );

  return (
    <SubscribePreviewModal
      alertingSlices={alertingSlices}
      diagnosticSlices={diagnosticSlices}
      onClose={onClose}
      opName={opName}
      title={title}
      useCaseId={defUseCaseId || useCaseId}
      verticalIdentifier={verticalInfo}
    />
  );
};
