import React, { FC, useCallback, useMemo } from "react";
import {
  useOpStore,
  setBizDataQuery,
  setRollingFunction,
  setRollingFreq,
  setThreshold,
  setSchedule
} from "../../../context";
import { BizDataQuery, TimeObj } from "../../../../services/api/explore";
import { VerticallyCenteredRow } from "../../../../components";
import { CompareOperator, OpSchedule } from "../../../../services/api/operationalise";
import { getDefaultStaticThreshold } from "../../../utils";
import { USFieldWidgetUtils } from "../../../../dashboard/widgets/USField/USFieldWidgetUtils";
import { BizDataQueryEditorV2 } from "./BizDataQueryEditorV2";

interface Props {}

export const BizDataQueryEditorWrapper: FC<Props> = () => {
  const { state, dispatch } = useOpStore();

  const { opCreationConfig, context, readOnly } = state;

  const { opCreationConfigDef, outlierConfig } = opCreationConfig;
  const {
    bizDataQuery: opBizDataQuery,
    rollingFreq: opRollingFreq,
    rollingFunction: opRollingFunc,
    threshold,
    schedule: opSchedule
  } = opCreationConfigDef || {};

  const { bizDataQuery: outlierOpBizDataQuery, schedule: outlierSchedule } = outlierConfig || {};

  const {
    bizDataQuery: outlierBizDataQuery,
    rollingFreq: outlierRollingFreq,
    rollingFunction: outlierRollingFunc
  } = outlierOpBizDataQuery || {};

  const bizDataQuery = useMemo(() => opBizDataQuery || outlierBizDataQuery, [opBizDataQuery, outlierBizDataQuery]);
  const schedule = useMemo(() => opSchedule || outlierSchedule, [opSchedule, outlierSchedule]);
  const rollingFreq = useMemo(() => opRollingFreq || outlierRollingFreq, [opRollingFreq, outlierRollingFreq]);
  const rollingFunction = useMemo(() => opRollingFunc || outlierRollingFunc, [opRollingFunc, outlierRollingFunc]);

  const { fieldConfig } = bizDataQuery?.buildingBlockConfig?.buildingBlockDef || {};

  const fieldName = fieldConfig?.userServiceField?.fieldName;
  const isEventIDOperationalize =
    USFieldWidgetUtils.isEventIDField(fieldName) || USFieldWidgetUtils.isHasErrorField(fieldName);

  const comparator = threshold?.comparator;

  const onComparatorChange = useCallback(
    (comparator: CompareOperator) => {
      let nThreshold = threshold;
      if (!comparator && isEventIDOperationalize) {
        nThreshold = null;
      } else if (!threshold) {
        nThreshold = getDefaultStaticThreshold();
      }

      if (nThreshold) {
        const { aboveThresholdDef, belowThresholdDef } = nThreshold;
        const thresholdDef = aboveThresholdDef || belowThresholdDef;
        const nAboveThresholdDef =
          comparator === CompareOperator.Above || comparator === CompareOperator.AboveOrBelow ? thresholdDef : null;
        const nBelowThresholdDef =
          comparator === CompareOperator.Below || comparator === CompareOperator.AboveOrBelow ? thresholdDef : null;

        nThreshold = {
          ...nThreshold,
          comparator,
          aboveThresholdDef: nAboveThresholdDef,
          belowThresholdDef: nBelowThresholdDef
        };
      }

      dispatch(setThreshold(nThreshold));
    },
    [dispatch, isEventIDOperationalize, threshold]
  );

  const onBizDataQueryChange = useCallback(
    (nBizDataQuery: BizDataQuery) => {
      dispatch(setBizDataQuery(nBizDataQuery));
    },
    [dispatch]
  );

  const onRollingFunctionChange = useCallback(
    (rollingFunc: string) => {
      dispatch(setRollingFunction(rollingFunc));
    },
    [dispatch]
  );

  const onRollingFreqChange = useCallback(
    (rollingFreq: TimeObj) => {
      dispatch(setRollingFreq(rollingFreq));
    },
    [dispatch]
  );

  const onScheduleChange = useCallback(
    (schedule: OpSchedule) => {
      dispatch(setSchedule(schedule));
    },
    [dispatch]
  );

  return (
    <VerticallyCenteredRow className="op-condition-editor">
      <VerticallyCenteredRow className="marginRt8 inc-label-common">Automate tracking for</VerticallyCenteredRow>

      <BizDataQueryEditorV2
        bizDataQuery={bizDataQuery}
        comparator={comparator}
        onChange={onBizDataQueryChange}
        onComparatorChange={onComparatorChange}
        onRollingFreqChange={onRollingFreqChange}
        onRollingFunctionChange={onRollingFunctionChange}
        onScheduleChange={onScheduleChange}
        pickerContext={context}
        readOnly={readOnly}
        rollingFreq={rollingFreq}
        rollingFunction={rollingFunction}
        schedule={schedule}
      />
    </VerticallyCenteredRow>
  );
};
