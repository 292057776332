import React from "react";
import {
  DateTimeOptions,
  getFormattedDateTime,
  IncDateTimeFormat,
  IncFaIcon,
  IncHighchartsDateTimeFormat,
  IncInfoIcon,
  IncSmartText,
  IncToolTip,
  TableDataColumn
} from "@inception/ui";
import { isEqual, isNil, pick, slice, sortBy } from "lodash";
import { CatalogWidgetSpecProperties } from "../../../models";
import { DataFrame, DataType, TimeRange } from "../../../../../../core";
import ChartOptionsBuilder from "../../../../../../components/time-series/ChartOptionsBuilder";
import timeRangeUtils from "../../../../../../utils/TimeRangeUtils";
import { AUTO_DS_INTERVAL } from "../../../../utils";
import { Condition, SimpleEntityNameRenderer, VerticallyCenteredRow } from "../../../../../../components";
import appConfig from "../../../../../../../appConfig";
import { isEntity } from "../../../../../../utils";
import { ChangeMetric } from "../../../../../../biz-entity";

const TIMESTAMP_ACCESSOR = "@timestamp";
const TIMESTAMP_HEADER = "Timestamp";

const VALUE_ACCESSOR = "__value__";
const COMPARE_ACCESSOR = "__compare__";

export type Datum = Record<string, string | number> & {
  [TIMESTAMP_ACCESSOR]: number;
  subRows?: Datum[];
};

type Column = TableDataColumn<Datum>;

export const getTableColumns = (
  aggregatedTags: string[],
  displayAggregatedTags: string[],
  metricIds: string[],
  metricNames: Record<string, string>,
  dataTypeByMetricId: Record<string, DataType>,
  downsample: string,
  timeRange: TimeRange,
  properties: CatalogWidgetSpecProperties["table"],
  entityLookup: Record<string, string>,
  valueFormatterFn: (dataType: DataType, value: number | string) => string,
  tagVsDataTypeMap: Record<string, DataType>,
  tagVsEntityTypeMap: Record<string, string>,
  showCompareData: boolean,
  compareStr: string,
  hideAggParentRowInfo?: boolean,
  onClickAddFilterValue?: (fieldName: string, value: string) => void
) => {
  const { formatterOptions, dateTimeFormat, metricHeaders, metricHeaderDescriptions, columnOrder } = properties || {};

  const timeColumn: Column = {
    accessor: TIMESTAMP_ACCESSOR,
    header: TIMESTAMP_HEADER,
    sortable: true,
    type: "datetime",
    align: "center",
    renderer: (value: number) => {
      const timeFormatter = getTimeFormatter(dateTimeFormat, formatterOptions, downsample, timeRange);
      return timeFormatter(value);
    }
  };

  const tagColumns = aggregatedTags.map(
    (aggTag, idx): Column => ({
      accessor: aggTag,
      header: displayAggregatedTags[idx] || aggTag,
      align: "left",
      className: properties?.groupBy?.includes(aggTag) ? "grouped-cell dimension-cell" : "dimension-cell",
      filterable: false,
      filterFn: (rows, filter) => {
        if (filter) {
          const lowerFilter = filter.toString().toLowerCase();
          return rows.filter(row => {
            const rawValue = row.original[aggTag];
            const value = String(entityLookup?.[rawValue] || rawValue || "");

            return value.toLowerCase().includes(lowerFilter);
          });
        }

        return rows;
      },
      sortable: true,
      sortFn: (a: Datum, b: Datum) => {
        let aVal = a[aggTag];
        aVal = String(entityLookup?.[aVal] || aVal || "");

        let bVal = b[aggTag];
        bVal = String(entityLookup?.[bVal] || bVal || "");

        const compareVal = aVal.localeCompare(bVal);
        return compareVal === 0 ? "equal" : compareVal < 0 ? "lesser" : "greater";
      },
      renderer: (value: string, rowData: any, isSubRow: boolean, depth: number) => {
        const renderAsEntity = isEntity(value);
        const isHidden = slice(properties?.groupBy || [], 0, depth).includes(aggTag);
        if (depth > 0 && isHidden && isSubRow && hideAggParentRowInfo) {
          return <></>;
        }

        let lookupValue = entityLookup[value];
        lookupValue = isNil(lookupValue) ? value : lookupValue;
        // todo: add a better way to handle this instead of directly accessing bootConfig.anomShareId here
        if (renderAsEntity && !appConfig.anomShareId) {
          return (
            <SimpleEntityNameRenderer
              entityType={tagVsEntityTypeMap[aggTag]}
              id={value}
              minimalLoader
              name={lookupValue}
              showPropertiesPopover
            />
          );
        }
        const dataType = tagVsDataTypeMap[aggTag];
        const formattedValue = valueFormatterFn(dataType, lookupValue);

        const isHTML = formattedValue?.startsWith("<") && formattedValue?.endsWith(">");
        return isHTML ? (
          <VerticallyCenteredRow className="visible-on-hover flex-gap-8">
            <span dangerouslySetInnerHTML={{ __html: formattedValue }} />
            {Boolean(onClickAddFilterValue) && (
              <IncFaIcon
                className="status-info display-element "
                iconName="circle-plus"
                onClick={() => onClickAddFilterValue(aggTag, formattedValue)}
              />
            )}
          </VerticallyCenteredRow>
        ) : (
          <VerticallyCenteredRow className="visible-on-hover flex-gap-8">
            <IncSmartText text={formattedValue} />
            {Boolean(onClickAddFilterValue) && (
              <IncFaIcon
                className="status-info display-element "
                iconName="circle-plus"
                onClick={() => onClickAddFilterValue(aggTag, formattedValue)}
              />
            )}
          </VerticallyCenteredRow>
        );
      }
    })
  );

  const valueColumns: Column[] = [];
  metricIds.forEach(metricId => {
    const accessor = getValueAccessorForTableColumn(metricId);
    const metricHeaderString = metricHeaders?.[metricId] || metricNames[metricId];

    const metricHeaderDescription = metricHeaderDescriptions?.[metricId];

    const header = (
      <VerticallyCenteredRow className="flex-gap-4">
        <IncSmartText text={metricHeaderString} />
        {metricHeaderDescription && (
          <IncToolTip
            placement="top"
            showArrow
            titleText={metricHeaderDescription}
          >
            <IncInfoIcon />
          </IncToolTip>
        )}
      </VerticallyCenteredRow>
    );

    const dataType = dataTypeByMetricId?.[metricId] || "DOUBLE";
    const metricCellClassName = "metric-cell";
    const valueColumn: Column = {
      accessor,
      header,
      defaultSort: "desc",
      sortable: true,
      align: "right",
      className: metricCellClassName,
      renderer: value => {
        if (value === undefined || value === null) {
          return "-";
        }

        const formattedValue = valueFormatterFn(dataType, value);
        const isHTML = formattedValue?.startsWith("<") && formattedValue?.endsWith(">");
        return isHTML ? (
          <VerticallyCenteredRow
            className="marginLtAuto width-fit-content"
            dangerouslySetInnerHTML={{ __html: formattedValue }}
          />
        ) : (
          <IncSmartText text={formattedValue} />
        );
      },
      sortFn: (a: Datum, b: Datum) => {
        const aVal = a[accessor];
        const bVal = b[accessor];

        return aVal === bVal ? "equal" : aVal < bVal ? "lesser" : "greater";
      }
    };
    valueColumns.push(valueColumn);

    if (showCompareData) {
      const compareAccessor = getCompareValueAccessorForTableColumn(metricId);
      const compareHeaderString = `${metricHeaderString} (${compareStr})`;

      const compareHeader = (
        <VerticallyCenteredRow className="flex-gap-4">
          <IncSmartText text={compareHeaderString} />
          {metricHeaderDescription && (
            <IncToolTip
              placement="top"
              showArrow
              titleText={metricHeaderDescription}
            >
              <IncInfoIcon />
            </IncToolTip>
          )}
        </VerticallyCenteredRow>
      );
      const compareColumn: Column = {
        accessor: compareAccessor,
        header: compareHeader,
        sortable: true,
        align: "center",
        className: metricCellClassName,
        renderer: value => {
          if (value === undefined || value === null) {
            return "-";
          }

          const formattedValue = valueFormatterFn(dataType, value);
          const isHTML = formattedValue?.startsWith("<") && formattedValue?.endsWith(">");
          return isHTML ? <span dangerouslySetInnerHTML={{ __html: formattedValue }} /> : formattedValue;
        },
        sortFn: (a: Datum, b: Datum) => {
          const aVal = a[compareAccessor];
          const bVal = b[compareAccessor];

          return aVal === bVal ? "equal" : aVal < bVal ? "lesser" : "greater";
        }
      };
      valueColumns.push(compareColumn);
    }
  });

  const sortedTagColumns = sortBy(tagColumns, tagColumn => {
    const matchIdx = columnOrder?.findIndex((columnId: string) => columnId === tagColumn.accessor);
    return matchIdx > -1 ? matchIdx : Number.MAX_SAFE_INTEGER;
  });

  const sortedValueColumns = sortBy(valueColumns, column => {
    const matchIdx = columnOrder?.findIndex((columnId: string) => {
      const valueAccessor = getValueAccessorForTableColumn(columnId);
      const compareValueAccessor = getCompareValueAccessorForTableColumn(columnId);

      return [compareValueAccessor, valueAccessor].includes(column.accessor);
    });
    return matchIdx > -1 ? matchIdx : Number.MAX_SAFE_INTEGER;
  });

  const rearrangedTagColumns = [timeColumn, ...sortedTagColumns, ...sortedValueColumns];

  if (!downsample || downsample === AUTO_DS_INTERVAL) {
    rearrangedTagColumns.splice(0, 1);
  }

  return rearrangedTagColumns;
};

const DEFAULT_TS = -1;

export const getTableData = (
  metricIds: string[],
  metricDataFramesMap: Record<string, DataFrame[]>,
  compareDataFramesMap: Record<string, DataFrame[]>,
  aggregatedTags: string[],
  shouldBreakDownByTime: boolean,
  changeMetric: ChangeMetric
): Datum[] => {
  const data: Datum[] = [];

  const tsToDataMap = new Map<number, DataMapEntry[]>();
  const tsToCompareDataMap = new Map<number, DataMapEntry[]>();
  metricIds.forEach(metricId => {
    const valueAccessor = getValueAccessorForTableColumn(metricId);
    const dataframes = metricDataFramesMap[metricId];

    const compareValueAccessor = getCompareValueAccessorForTableColumn(metricId);
    const compareDataframes = compareDataFramesMap[metricId] || [];

    const isCompareBasedData =
      changeMetric === "timeShift" || changeMetric === "delta" || changeMetric === "deltaPercentage";
    const primaryDataframes = isCompareBasedData ? compareDataframes : dataframes;
    const secondaryDataframes = isCompareBasedData ? dataframes : compareDataframes;
    const primaryValueAccessor = isCompareBasedData ? compareValueAccessor : valueAccessor;
    const secondaryValueAccessor = isCompareBasedData ? valueAccessor : compareValueAccessor;

    (primaryDataframes || []).forEach(df => {
      const { labels = {}, fields } = df;

      fields[0].data.forEach((ts, idx) => {
        const timestamp = shouldBreakDownByTime ? ts : DEFAULT_TS;
        const value = fields[1].data[idx];
        const entry = tsToDataMap.get(timestamp) || [];

        const visibleLabels = pick(labels, aggregatedTags);

        const labelsEntry = entry.find(e => isEqual(e.tags, visibleLabels));
        if (labelsEntry) {
          labelsEntry.valueMap[primaryValueAccessor] = value;
        } else {
          entry.push({
            tags: visibleLabels,
            valueMap: {
              [primaryValueAccessor]: value
            }
          });
        }

        tsToDataMap.set(timestamp, entry);
      });
    });

    (secondaryDataframes || []).forEach(df => {
      const { labels = {}, fields } = df;

      fields[0].data.forEach((ts, idx) => {
        const timestamp = shouldBreakDownByTime ? ts : DEFAULT_TS;
        const value = fields[1].data[idx];
        const entry = tsToCompareDataMap.get(timestamp) || [];

        const visibleLabels = pick(labels, aggregatedTags);

        const labelsEntry = entry.find(e => isEqual(e.tags, visibleLabels));
        if (labelsEntry) {
          labelsEntry.valueMap[secondaryValueAccessor] = value;
        } else {
          entry.push({
            tags: visibleLabels,
            valueMap: {
              [secondaryValueAccessor]: value
            }
          });
        }

        tsToCompareDataMap.set(timestamp, entry);
      });
    });
  });

  const sortedTs = Array.from(tsToDataMap.keys()).sort();
  sortedTs.forEach(ts => {
    const entries = tsToDataMap.get(ts);
    const compareEntries = tsToCompareDataMap.get(ts) || [];

    entries.forEach(entry => {
      const { tags, valueMap } = entry;

      const subData = pick(tags, aggregatedTags);
      const compareValueMap = compareEntries.find(e => isEqual(e.tags, subData))?.valueMap || {};

      data.push({
        ...subData,
        [TIMESTAMP_ACCESSOR]: ts,
        ...valueMap,
        ...compareValueMap
      });
    });
  });

  return data;
};

type DataMapEntry = {
  tags: Record<string, string>;
  valueMap: Record<string, number>;
};

const getTimeFormatter = (
  dateTimeFormat: IncDateTimeFormat,
  formatterOptions: DateTimeOptions,
  downsample: string,
  timeRange: TimeRange
) => {
  const options: DateTimeOptions = {
    withMilliSeconds: false,
    dateSeparator: "/",
    dateTimeSeparator: " ",
    i18nDisabled: false,
    relative: false,
    skipTime: false,
    withSeconds: true,
    ...(formatterOptions || {})
  };

  let format: IncDateTimeFormat | IncHighchartsDateTimeFormat = dateTimeFormat;

  if (!format) {
    const dsMillis = timeRangeUtils.getMillisFromOffset(downsample);
    const { includeSeconds, labelFormat } = new ChartOptionsBuilder().getXAxisLabelsAndFormat(timeRange, dsMillis);

    format = labelFormat;
    options.withSeconds = includeSeconds;
  }

  return (value: number) => getFormattedDateTime(value, format, options);
};

/**
 * method returns the boolean based on the condition - determines whether styling to apply or not
 */
export const isRowMatchingConditions = (row: Record<string, string>, conditions: Condition[]) => {
  if (!row || !conditions?.length) {
    return false;
  }

  const areEqual = (a: any, b: any): boolean => {
    if (isDate(a) && isDate(b)) {
      return a.getTime() === b.getTime();
    } else if (isString(a) && isString(b)) {
      return a.toLowerCase() === b.toLowerCase();
    } else if (isNumber(a) && isNumber(b)) {
      return Number(a) === Number(b);
    }
    return a === b;
  };

  const isNumber = (value: any): boolean =>
    (typeof value === "number" && !isNaN(value)) || (typeof value === "string" && !isNaN(parseFloat(value)));
  const isString = (value: any): boolean => typeof value === "string";
  const isDate = (value: any): boolean => value instanceof Date && !isNaN(value.getTime());
  // const isArray = (value: any): boolean => Array.isArray(value);
  const { key, value: pValue, opValue } = conditions[0] || {};

  if (!key) {
    return false;
  }
  const rVal = row[key];
  const rowVal = isString(rVal) ? rVal.toLowerCase() : rVal || "";
  const value = isString(pValue) ? pValue.trim().toLowerCase() : pValue || "";

  switch (opValue) {
    case "eq":
      return areEqual(rowVal, value);
    case "neq":
      return !areEqual(rowVal, value);
    case "exists":
      return rowVal !== undefined && rowVal !== null;
    case "doesNotExist":
      return rowVal === undefined || rowVal === null;
    case "in":
      return rowVal.includes(value);
    case "notContains":
      return !rowVal.includes(value);
    case "contains":
      return rowVal.includes(value);
    case "endsWith":
      return rowVal.endsWith(value);
    case "startsWith":
      return rowVal.startsWith(value);
    case "gt":
      return Number(rowVal) > Number(value);
    case "ge":
      return Number(rowVal) >= Number(value);
    case "lt":
      return Number(rowVal) < Number(value);
    case "le":
      return Number(rowVal) <= Number(value);
    default:
      return false;
  }
};
export const getValueAccessorForTableColumn = (metricId: string) => `${metricId}_${VALUE_ACCESSOR}`;
export const getCompareValueAccessorForTableColumn = (metricId: string) =>
  `${metricId}_${VALUE_ACCESSOR}_${COMPARE_ACCESSOR}`;
export const getMetricIdForTableColumn = (accessor: string) =>
  accessor.replace(`_${VALUE_ACCESSOR}`, "").replace(`_${COMPARE_ACCESSOR}`, "");
export const isMetricColumn = (accessor: string) => accessor.endsWith(VALUE_ACCESSOR);
export const isMetricCompareColumn = (accessor: string) => accessor.endsWith(COMPARE_ACCESSOR);
