import React, { CSSProperties, FC, useCallback, useEffect, useState } from "react";
import { IncCheckbox } from "@inception/ui";
import { clone } from "lodash";
import { BarsOrder, NegationColors } from "../../../../models";
import { VerticallyCenteredRow } from "../../../../../../../components";
import ColorPicker from "../../../../../../../components/color-picker/ColorPicker";
import { WidgetQuerySchema } from "../../../../../../../services/api/explore";
import { BarsOptions, WidgetCustomizationProps } from "./types";
import { UIOrderedList } from "./UIOrderedList";

export const BarChartUICustomization: FC<WidgetCustomizationProps> = props => {
  const { properties, onPropertiesChange, widgetResponseDTO } = props;
  const { useNegationColors: pUseNegationColors = false, negationColors: pNegationColors = [] } = properties || {};

  const firstColor = pNegationColors[0] || "#3BB443";
  const secondColor = pNegationColors[1] || "#F44336";

  const onNegationColorsChange = useCallback(
    (negationColors: NegationColors) => {
      if (pUseNegationColors) {
        properties.negationColors = negationColors;
        onPropertiesChange(properties);
      }
    },
    [onPropertiesChange, pUseNegationColors, properties]
  );

  const onFirstColorChange = useCallback(
    (firstColor: string) => {
      onNegationColorsChange([firstColor, secondColor]);
    },
    [secondColor, onNegationColorsChange]
  );

  const onSecondColorChange = useCallback(
    (secondColor: string) => {
      onNegationColorsChange([firstColor, secondColor]);
    },
    [onNegationColorsChange, firstColor]
  );

  const onUseNegationColorsChange = useCallback(
    (e: any, useNegationColors: boolean) => {
      properties.useNegationColors = useNegationColors;
      onPropertiesChange(properties);
    },
    [onPropertiesChange, properties]
  );

  const [barsOptions, setBarsOptions] = useState<BarsOptions>({
    barOptions: [],
    barsOrder: []
  });

  useEffect(() => {
    if (!widgetResponseDTO?.querySchema?.querySchema) {
      return;
    }

    const updatedBarOptions: BarsOptions = {
      barOptions: [] as BarsOrder[],
      barsOrder: [] as string[]
    };

    (widgetResponseDTO?.querySchema?.querySchema || []).forEach((qs: WidgetQuerySchema) => {
      updatedBarOptions.barOptions.push({
        id: qs.metricName,
        label: qs.metricName
      });
      updatedBarOptions.barsOrder.push(qs.metricName);
    });

    setBarsOptions(updatedBarOptions);
  }, [widgetResponseDTO]);

  const onBarsReorder = useCallback(
    (items: BarsOrder[]) => {
      const clonedProp = clone(properties);
      clonedProp.bar.barsOrder = items || [];
      setBarsOptions(prevBarsOptions => ({
        ...prevBarsOptions,
        barOptions: items,
        barsOrder: items.map(item => item.id)
      }));
      onPropertiesChange(clonedProp);
    },
    [onPropertiesChange, properties]
  );

  return (
    <div className="inc-flex-column width-100 flex-gap-12">
      <div
        className="inc-flex-column flex-gap-8 paddingBt16 width-100"
        style={style}
      >
        <VerticallyCenteredRow>Metric Color Overrides</VerticallyCenteredRow>
        <IncCheckbox
          checked={pUseNegationColors}
          infoText="Useful when you have only two series that indicate status. Eg: Failed-Success Rate"
          label="Use Negation Colors"
          noImplicitHeight
          onChange={onUseNegationColorsChange}
        />
        {pUseNegationColors && (
          <>
            <ColorPicker
              color={firstColor}
              defaultColor="#3BB443"
              label="First Serie Color"
              mode="hex"
              onUpdate={onFirstColorChange}
            />

            <ColorPicker
              color={secondColor}
              defaultColor="#F44336"
              label="Second Serie Color"
              mode="hex"
              onUpdate={onSecondColorChange}
            />
          </>
        )}
      </div>

      <section className="section">
        <div className="section-heading inc-flex inc-flex-row inc-flex-space-contents">
          <VerticallyCenteredRow>Bars Order</VerticallyCenteredRow>
        </div>

        <UIOrderedList
          hideAction={true}
          itemOrder={barsOptions.barsOrder}
          items={barsOptions.barOptions}
          onListChange={onBarsReorder}
        />
      </section>
    </div>
  );
};

const style: CSSProperties = {
  borderBottom: "1px solid #313B45"
};
