import { IncFaIcon, IncPillProps, IncPill } from "@inception/ui";
import React, { useMemo } from "react";
import { CancelIcon8 } from "../core/iconwrapper";

type CondensedPillRendererProps = {
  label: string;
  onRemove?: () => void;
  onEdit?: (e: React.MouseEvent) => void;
};

export const CondensedPillRenderer: React.FC<CondensedPillRendererProps> = props => {
  const { label: name, onEdit, onRemove } = props;

  const actions = useMemo<IncPillProps["actions"]>(() => {
    const actions: IncPillProps["actions"] = [];
    if (onEdit) {
      actions.push({
        icon: <IncFaIcon iconName="pencil" />,
        onAction: onEdit
      });
    }
    if (onRemove) {
      actions.push({
        icon: <CancelIcon8 />,
        onAction: onRemove
      });
    }
    return actions;
  }, [onEdit, onRemove]);

  return (
    <IncPill
      actions={actions}
      label={name}
    />
  );
};
