import { SupportedDataType } from "./types";

export const SupportedDataTypes: SupportedDataType[] = [
  {
    label: "STRING",
    kind: "_str",
    primTypes: [],
    defaultPrimType: "",
    description: "Ex: any strings",
    isPrimitive: true
  },
  {
    label: "LONG",
    kind: "_long",
    primTypes: [],
    defaultPrimType: "",
    description: "Ex: long data types",
    isPrimitive: true
  },
  {
    label: "BOOLEAN",
    kind: "_bool",
    primTypes: [],
    defaultPrimType: "",
    description: "boolean values",
    isPrimitive: true
  },
  {
    label: "DOUBLE",
    kind: "_double",
    primTypes: [],
    defaultPrimType: "",
    description: "Ex: long data types",
    isPrimitive: true
  },
  {
    label: "SET",
    kind: "_set",
    primTypes: [],
    defaultPrimType: "",
    description: "Ex: set",
    isPrimitive: true
  },
  {
    label: "EMAIL",
    kind: "email",
    primTypes: ["_str"],
    defaultPrimType: "_str",
    description: "Ex: email address",
    isPrimitive: false
  },
  {
    label: "URL",
    kind: "url",
    primTypes: ["_str"],
    defaultPrimType: "_str",
    description: "Ex: URLs",
    isPrimitive: false
  },
  {
    label: "GEOLOCATION",
    kind: "geolocation",
    primTypes: ["_str"],
    defaultPrimType: "_str",
    description: "Ex: Latitude/Longitude/Altitude details",
    isPrimitive: false
  },
  {
    label: "TELEPHONE",
    kind: "tel",
    primTypes: ["_str"],
    defaultPrimType: "_str",
    description: "Ex: Telephone/Landline",
    isPrimitive: false
  },
  {
    label: "ADDRESS",
    kind: "address",
    primTypes: ["_str"],
    defaultPrimType: "_str",
    description: "Ex: Address",
    isPrimitive: false
  },
  {
    label: "ZIP_CODE",
    kind: "zip_code",
    primTypes: ["_str"],
    defaultPrimType: "_str",
    description: "Ex: zip codes",
    isPrimitive: false
  },
  {
    label: "IP_ADDRESS",
    kind: "ip_address",
    primTypes: ["_str"],
    defaultPrimType: "_str",
    description: "Ex: IP Address",
    isPrimitive: false
  },
  {
    label: "CURRENCY",
    kind: "currency",
    primTypes: ["_long", "_double"],
    defaultPrimType: "_long",
    description: "Ex: currency",
    isPrimitive: false
  },
  {
    label: "EPOCH_SECS",
    kind: "epoch_secs",
    primTypes: ["_long"],
    defaultPrimType: "_long",
    description: "Number of seconds that have elapsed since Jan 1, 1970 (midnight UTC/GMT)",
    isPrimitive: false
  },
  {
    label: "EPOCH_MILLIS",
    kind: "epoch_millis",
    primTypes: ["_long"],
    defaultPrimType: "_long",
    description: "Number of milliseconds that have elapsed since Jan 1, 1970 (midnight UTC/GMT)",
    isPrimitive: false
  },
  {
    label: "EPOCH_MICROS",
    kind: "epoch_micros",
    primTypes: ["_long"],
    defaultPrimType: "_long",
    description: "Number of microseconds that have elapsed since Jan 1, 1970 (midnight UTC/GMT)",
    isPrimitive: false
  },
  {
    label: "DURATION_MILLIS",
    kind: "duration_millis",
    primTypes: ["_long"],
    defaultPrimType: "_long",
    description: "Expressed in milliseconds",
    isPrimitive: false
  },
  {
    label: "DURATION_SECS",
    kind: "duration_secs",
    primTypes: ["_long"],
    defaultPrimType: "_long",
    description: "Expressed in seconds",
    isPrimitive: false
  },
  {
    label: "PERCENT_1",
    kind: "percent_1",
    primTypes: ["_double"],
    defaultPrimType: "_double",
    description: "percent expressed as a fraction between 0 and 1",
    isPrimitive: false
  },
  {
    label: "PERCENT_100",
    kind: "percent_100",
    primTypes: ["_double"],
    defaultPrimType: "_double",
    description: "percent expressed as a number between 0 and 100",
    isPrimitive: false
  },
  {
    label: "ERROR",
    kind: "error",
    primTypes: ["_str"],
    defaultPrimType: "_str",
    description: "Ex: Any error",
    isPrimitive: false
  }
];
