import React, { FC, useRef, useCallback, useState, useMemo, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import {
  IncToolTip,
  IncPopper,
  IncButton,
  generateId,
  IncSelectOption,
  IncSelect,
  IncMultiSelectCheckBox,
  IncSelectProps,
  IncMultiSelectClearIndicator,
  IncMultiSelectDropdownIndicator,
  IncFaIcon,
  ISaxIcon,
  ISaxIconProps,
  IncSmartText
} from "@inception/ui";
import { clone, cloneDeep, every, get, includes, isEmpty, some } from "lodash";
import { UserServiceFilterList, WidgetResponseDTO } from "../../../../services/api/explore/types/exploreTypes";
import { SelectorFilter, SelectorSpec } from "../../../../services/api/explore";
import { FilterWithKey, VerticallyCenteredRow } from "../../../../components";
import { FieldPrimType } from "../../../../core";
import { ENTITY_TAG } from "../../../../utils";
import { FetchResultMetaProps, useGetResultMetaAndDQConfig } from "../hooks";

interface Props {
  widgetResponseDTO: WidgetResponseDTO;
  metricUserServiceFilters: Record<string, UserServiceFilterList>;
  filterMessage: string;
  filtersExist: boolean;
  className?: string;
  iconClassName?: string;
  skipTooltip?: boolean;
  widgetSelectorSpec: SelectorSpec;
  setWidgetSelectorSpec: (selectorSpec: SelectorSpec) => void;
  fetchResultMetaProps: FetchResultMetaProps;
}
type ToolTipVariant = "info" | "success" | "error" | "warning" | "default";

/*******
 * Widget datadefinition filter
 ******/
export const WidgetDataDefinitionFilter: FC<Props> = props => {
  const {
    metricUserServiceFilters,
    setWidgetSelectorSpec: pSetWidgetSelectorSpec,
    filterMessage,
    widgetSelectorSpec,
    filtersExist,
    className = "",
    iconClassName = "",
    skipTooltip = false,
    fetchResultMetaProps: pFetchResultMetaProps
  } = props;

  const fetchResultMetaProps = useMemo<FetchResultMetaProps>(() => {
    const nProps = clone(pFetchResultMetaProps);
    nProps.id = `${nProps.id}-data-definition-filter`;
    return nProps;
  }, [pFetchResultMetaProps]);

  const {
    resultMeta,
    isResultMetaFetching,
    entityLookupData,
    refetch: refetchFilters
  } = useGetResultMetaAndDQConfig(fetchResultMetaProps, true);

  const iconRef = useRef<HTMLDivElement>();
  const containerRef = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);
  const { schema, bizTag } = resultMeta?.[0] || {};

  const { filters: widgetFilters } = widgetSelectorSpec || {};
  const defaultSelectorSpec: SelectorSpec = useMemo(
    () => ({
      filters: [
        {
          tags: []
        }
      ]
    }),
    []
  );

  /**
   * default filter expression
   */
  const defaultFilters = useMemo(() => {
    const conditions: Condition[] = [];
    widgetFilters?.forEach(filters => {
      filters?.tags?.forEach(tag => {
        const { key, value } = tag;

        const hasError = !every(value, val => some(schema, metricInfo => metricInfo[key] === val));

        const condition: Condition = {
          key,
          op: "in",
          values: value,
          label: key === ENTITY_TAG ? bizTag : key,
          hasError
        };
        conditions.push(condition);
      });
    });
    return conditions;
  }, [bizTag, schema, widgetFilters]);

  const [filters, setFilters] = useState<Condition[]>();
  const [selectorSpec, setSpelectorSpec] = useState<SelectorSpec>(widgetSelectorSpec);

  const applySeriesFilterChange = useCallback(() => {
    pSetWidgetSelectorSpec(clone(selectorSpec));
    setOpen(false);
  }, [pSetWidgetSelectorSpec, selectorSpec]);

  /**
   * handle filter change and set the selector spec
   */
  const handleFilterChange = useCallback(
    (conditions: Condition[]) => {
      //check if the conditions are there then process
      if (conditions?.length > 0) {
        const sFilters: SelectorFilter[] = [];
        const sFilter: SelectorFilter = {
          tags: conditions
            .filter((condition: Condition) => condition?.key) // Filter out conditions with no key
            .map((condition: Condition) => ({
              key: condition.key,
              value: condition.values
            })) // Map conditions to SelectorTags
        };
        if (sFilter.tags.length > 0) {
          // Check if there are any valid tags
          sFilters.push(sFilter);
          const selectorSpec: SelectorSpec = {
            filters: sFilters
          };
          setSpelectorSpec(selectorSpec);
        }
      } else {
        setSpelectorSpec(defaultSelectorSpec);
      }
      setFilters(conditions);
    },
    [defaultSelectorSpec]
  );

  /**
   * set the initial filters
   */
  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  /**
   * handle the close action. update the defFilters.
   */
  const handleClose = useCallback(() => {
    setFilters(defaultFilters);
    setOpen(false);
  }, [defaultFilters]);

  /**
   * handle the opening of popper
   */
  const handleOpen = useCallback(() => {
    if (!schema?.length) {
      refetchFilters();
    }
    setOpen(true);
  }, [refetchFilters, schema]);

  const showFilterMessage = filtersExist || Boolean(metricUserServiceFilters);

  //Checking if the mapping values exists in the schema or not else shows the ember icon
  const filterStatus = useMemo(() => {
    const { filters } = widgetSelectorSpec;
    //check in the schema if there is mapping of the value
    const hasProblemInFilter = schema?.length
      ? filters.some(filter => {
          const { tags } = filter;
          return tags?.some(tag => {
            const { key, value } = tag;
            return !every(value, val => some(schema, metricInfo => metricInfo[key] === val));
          });
        })
      : false;

    return {
      className: hasProblemInFilter ? "status-warning" : showFilterMessage ? "status-info" : iconClassName,
      toolTipText: hasProblemInFilter
        ? "Some filters are not valid for the series, please fix them to view data."
        : "Filter Data",
      toolTipVariant: hasProblemInFilter ? "warning" : ("default" as ToolTipVariant),
      icon: hasProblemInFilter ? "FilterRemove" : ("FilterTick" as ISaxIconProps["iconName"]),
      hasProblemInFilter
    };
  }, [widgetSelectorSpec, showFilterMessage, iconClassName, schema]);

  const { className: filterClassName, toolTipText, toolTipVariant, icon: filterIcon } = filterStatus;

  /**
   *
   */
  const filterJsx = (
    <VerticallyCenteredRow
      className={className}
      onClick={handleOpen}
    >
      <VerticallyCenteredRow
        className="svg-icon-with-overlay inc-cursor-pointer"
        ref={iconRef}
      >
        <IncToolTip
          placement="top"
          titleText={toolTipText}
          variant={toolTipVariant}
        >
          <div>
            {!showFilterMessage && (
              <ISaxIcon
                className={filterClassName}
                iconName="Filter"
                size={15}
                variant="Bold"
              />
            )}
            {showFilterMessage && (
              <ISaxIcon
                className={filterClassName}
                iconName={filterIcon}
                size={15}
                variant="Bold"
              />
            )}
          </div>
        </IncToolTip>
      </VerticallyCenteredRow>

      {showFilterMessage && filterMessage && (
        <VerticallyCenteredRow
          className="status-text marginLt6 inc-text-subtext-medium"
          style={{ whiteSpace: "nowrap" }}
        >
          <span className={filterClassName}>{filterMessage}</span>
        </VerticallyCenteredRow>
      )}
    </VerticallyCenteredRow>
  );

  const data = useMemo(() => {
    const fieldsInfoList: Record<string, GenericFilterDataOption> = {};
    //
    schema?.forEach(field => {
      for (const [key, value] of Object.entries(field)) {
        if (!fieldsInfoList[key]) {
          const label = key === ENTITY_TAG ? bizTag : key;
          fieldsInfoList[key] = {
            label: label,
            value: [value],
            type: "_unset"
          };
        } else {
          // Check if value is not already included in fieldsInfoList[key].value array
          if (!includes(fieldsInfoList[key]?.value, value)) {
            // If value is not present, push it into the array
            get(fieldsInfoList, [key, "value"], []).push(value);
          }
        }
      }
    });
    return fieldsInfoList;
  }, [bizTag, schema]);

  const schemaExist = !isEmpty(data);

  return (
    <>
      {skipTooltip && filterJsx}
      {!skipTooltip && (
        <IncToolTip
          placement="top"
          titleText="Filter series"
        >
          {filterJsx}
        </IncToolTip>
      )}

      <IncPopper
        anchorEl={iconRef as any}
        overlay
        show={open}
      >
        <div
          className="entity-series-filter-popper"
          ref={containerRef}
        >
          <div className="entity-series-filter">
            <div className="inc-flex-row filter-header">
              <div className="marginBt16 inc-text-body-semibold">Filter</div>
              <div
                className="close-icon"
                onClick={handleClose}
              >
                <IncFaIcon iconName="close" />
              </div>
            </div>
            {isResultMetaFetching && (
              <div
                className="width-100 height-80"
                data-show-loader={true}
              ></div>
            )}
            {!isResultMetaFetching && (
              <>
                {!schemaExist && <IncSmartText text="No filters available.." />}
                {schemaExist && (
                  <div className="inc-flex-row inc-flex-row-wrap">
                    <div className="editable-event-filters width-100">
                      <FilterConditionBuilder
                        data={data}
                        entityLookupData={entityLookupData}
                        filters={filters}
                        onChange={handleFilterChange}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="footer">
            <IncButton
              color="primary"
              onClick={applySeriesFilterChange}
            >
              <FormattedMessage id="common.actions.done" />
            </IncButton>
          </div>
        </div>
      </IncPopper>
    </>
  );
};
/**
 *
 * @param filters
 * @param prevFiltersWithKeys
 * @returns
 */
const constructFiltersWithKeys = (
  filters: Condition[],
  prevFiltersWithKeys: Array<FilterWithKey<Condition>> = []
): Array<FilterWithKey<Condition>> => {
  if (!isEmpty(filters)) {
    return filters.map(filter => {
      const prevKey = prevFiltersWithKeys?.find(pf => filter && pf.filter === filter)?.key;
      const key = prevKey || generateId();

      return {
        key,
        filter
      };
    });
  }
  return [];
};

interface ConditionsBuilderProps {
  data: Record<string, GenericFilterDataOption>;
  filters: Condition[];
  onChange: (filters: Condition[]) => void;
  entityLookupData: Record<string, string>;
}

/**
 *
 * @param props
 * @returns
 */
const FilterConditionBuilder: React.FC<ConditionsBuilderProps> = props => {
  const { data, filters, onChange, entityLookupData } = props;

  const [filtersWithKey, setFiltersWithKey] = useState(constructFiltersWithKeys(filters));

  useEffect(() => {
    setFiltersWithKey(prev => constructFiltersWithKeys(filters, prev));
  }, [filters]);
  /**
   *
   */
  const updateFilters = useCallback(
    (filtersWithKey: Array<FilterWithKey<Condition>>) => {
      setFiltersWithKey(filtersWithKey);
      const filters = filtersWithKey.map(({ filter }) => filter);
      onChange(filters);
    },
    [onChange]
  );
  /**
   *
   */
  const onFilterChange = useCallback(
    (filter: Condition, key: string) => {
      const _filters = cloneDeep(filtersWithKey);
      const currFilter = _filters.find(x => x.key === key);
      currFilter.filter = filter;
      updateFilters(_filters);
    },
    [filtersWithKey, updateFilters]
  );
  /**
   *
   */
  const addRows = useCallback(() => {
    const _filters = cloneDeep(filtersWithKey);
    const nFilter: FilterWithKey<Condition> = {
      key: generateId(),
      filter: null
    };
    _filters.push(nFilter);
    updateFilters(_filters);
  }, [filtersWithKey, updateFilters]);
  /**
   *
   */
  const removeRow = useCallback(
    (key: string) => {
      let _filters = cloneDeep(filtersWithKey);

      _filters = _filters.filter(x => x.key !== key);

      updateFilters(_filters);
    },
    [filtersWithKey, updateFilters]
  );
  /**
   *
   */
  useEffect(() => {
    if (isEmpty(filtersWithKey)) {
      addRows();
    }
  }, [addRows, filters, filtersWithKey]);

  return (
    <div className="inc-flex-column inc-flex-grow generic-filters-builder width-100">
      {filtersWithKey.map((FilterWithKey, i) => (
        <div
          className="marginBt8 width-100"
          key={FilterWithKey.key}
        >
          <FilterConditionBuilderRow
            data={data}
            entityLookupData={entityLookupData}
            filter={FilterWithKey.filter}
            index={i}
            onAdd={addRows}
            onChange={filter => onFilterChange(filter, FilterWithKey.key)}
            onDelete={() => removeRow(FilterWithKey.key)}
            showAdd={filtersWithKey?.length - 1 === i}
          />
        </div>
      ))}
    </div>
  );
};

interface ConditionsBuilderRowProps {
  data: Record<string, GenericFilterDataOption>;
  filter: Condition | null;
  onChange: (filter: Condition) => void;
  onDelete: () => void;
  onAdd: () => void;
  entityLookupData: Record<string, string>;
  index?: number;
  showAdd: boolean;
}

/**
 *
 * @param props
 * @returns
 */
const FilterConditionBuilderRow: React.FC<ConditionsBuilderRowProps> = props => {
  const { data, filter, onChange, onDelete, onAdd, index, showAdd, entityLookupData } = props;

  const { hasError } = filter || {};
  const [filterKey, setFilterKey] = useState<FilterOption>();
  const [label, setLabel] = useState<string>(filter?.label);

  // This is used when showing the select options for value
  const [filterValues, setFilterValues] = useState<IncSelectOption[]>();

  // This is used when showing the text box for value

  const fieldNames = useMemo(() => {
    const fields = [];
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        fields.push({
          label: value.label,
          value: key,
          type: value.type
        });
      }
    }
    return fields;
  }, [data]);

  const getOptionFromString = useCallback(
    (s: string) => {
      const option: IncSelectOption = {
        label: entityLookupData[s] || s,
        value: s,
        data: s
      };
      return option;
    },
    [entityLookupData]
  );

  const fieldValues = useMemo(() => {
    if (!filterKey) {
      return [];
    }
    const values = data[filterKey.value];
    return values?.value?.map(value => getOptionFromString(value));
  }, [data, filterKey, getOptionFromString]);

  /**
   *
   */
  const onFilterKeyChange = useCallback((option: FilterOption) => {
    setFilterValues([]);
    setLabel(option.label);
    setFilterKey(option);
  }, []);

  /**
   * updatefilter values
   */
  const onValueChange = useCallback(
    (options: IncSelectOption[]) => {
      if (options?.length > 0) {
        const values: string[] = [];

        options?.forEach((filter: IncSelectOption) => {
          values.push(filter.value);
        });

        const newFilter: Condition = {
          key: filterKey?.value,
          op: "in",
          values: values || null,
          label: label
        };
        //
        setFilterValues(options);
        onChange(newFilter);
      }
      setFilterValues(options);
    },
    [filterKey, label, onChange]
  );

  /**
   * set the filter label
   */
  useEffect(() => {
    if (filter && filter?.key) {
      const filterKey: FilterOption = {
        type: "_unset",
        label: filter.label,
        value: filter.key
      };

      setFilterKey(filterKey);

      const filterValues = filter?.values?.map(value => ({
        label: entityLookupData[value] || value,
        value
      }));
      setFilterValues(filterValues);
      setLabel(filter.label);
    }
  }, [entityLookupData, filter, index]);

  /**
   * handle remove button
   */
  const onRemove = useCallback(() => {
    setFilterValues([]);
    return false;
  }, []);

  const keyLabel = "";

  const components: IncSelectProps<IncSelectOption, true>["components"] = useMemo(
    () => ({
      ClearIndicator: props => (
        <IncMultiSelectClearIndicator
          {...props}
          clearTextJsx={<div className="status-info inc-text-element-medium display-inline-flex marginRt6">Clear</div>}
        />
      ),
      DropdownIndicator: props => (
        <IncMultiSelectDropdownIndicator
          {...props}
          extJsx={
            onRemove ? (
              <IncFaIcon
                className="inc-cursor-pointer"
                iconName="remove"
                onClick={e => {
                  e.bubbles = false;
                  e.preventDefault();
                  e.stopPropagation();
                  onRemove();
                }}
                style={{ fontSize: 14 }}
              />
            ) : null
          }
        />
      )
    }),
    [onRemove]
  );

  const conditionLabel = index === 0 ? "WHERE" : "AND";
  const valueContainerClassName = hasError ? "status-border-warning" : "";
  return (
    <>
      <div className="condition-builder-row inc-flex-row align-center width-100 inc-flex-center-vertical">
        <div className="inc-label-common width-10">{conditionLabel}</div>
        <div className="key-container width-20">
          <IncSelect<FilterOption>
            label={""}
            onChange={onFilterKeyChange}
            options={fieldNames}
            placeholder={`Choose ${keyLabel}`}
            value={filterKey}
          />
        </div>

        <div className="op-containers m-2">in</div>
        <div className="inc-flex-grow value-container">
          <IncMultiSelectCheckBox
            className={valueContainerClassName}
            components={components}
            defaultOptions={filterValues}
            hideOptionsCount
            onChange={onValueChange}
            options={fieldValues}
            placeholder={"Choose Value"}
            renderInCard
            showAsPills
            showSelectedOptions
            skipAllOption
          />
        </div>
        <div className="inc-flex-column width-10">
          <div className="inc-flex-row inc-flex-center-vertical">
            <IncFaIcon
              className="action-remove status-danger marginLt4"
              height={20}
              iconName="trash-alt"
              onClick={onDelete}
              width={20}
            />
            {showAdd && (
              <IncFaIcon
                className="action-add-circle text-info marginLt4"
                height={20}
                iconName="plus-circle"
                onClick={onAdd}
                width={20}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

type FilterOption = {
  label: string;
  value: string;
  type: FieldPrimType;
};

type GenericFilterDataOption = {
  label: string;
  value: string[];
  type: FieldPrimType;
};

interface Condition {
  key: string;
  op: string;
  values?: string[];
  label?: string;
  hasError?: boolean;
}
