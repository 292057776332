import React, { ElementType, FC, useCallback, useEffect, useMemo, useState } from "react";
import { cloneDeep, extend, isEqual } from "lodash";
import { IncTabBar, VerticallyCenteredRow } from "../../../../../../components";
import { CatalogWidgetImpl, WidgetQuerySourceConfig } from "../../../models";
import { WidgetConfigurationTabProps } from "../types";
import { CustomizationTab } from "../Customization/CustomizationTab";
import { DataTab } from "../Data/DataTab";
import { FEATURE_FLAGS, featureFlagService } from "../../../../../../services/feature-flags";
import { Visualization } from "./Visualization";
import { InconsistencyCheckConfirmModal } from "./InconsistencyCheckConfirmModal";

const customizationTabs = [
  {
    id: "query",
    label: "Configure",
    value: "query",
    component: DataTab
  },
  {
    id: "customization",
    label: "Customize",
    value: "customization",
    component: CustomizationTab
  }
];

interface Props extends WidgetConfigurationTabProps {
  getSaveButtonJSX?: (isValid: boolean, invalidMessage: string) => JSX.Element;
  vizErrorMessage?: string;
}

export const WidgetConfiguration: FC<Props> = props => {
  const {
    onWidgetImplChange,
    widgetImpl,
    widgetResponseDTO,
    compareWidgetConfigDto,
    dbImpl,
    onUnsavedChangesExist,
    getSaveButtonJSX,
    readOnly = false,
    vizErrorMessage = "",
    children,
    variableSrvMap
  } = props;

  const [selectedTabId, setSelectedTabId] = useState<string | number>();
  const [validState, setValidState] = useState<[boolean, string]>([true, ""]);

  const updateValidState = useCallback((isValid: boolean, validationMessage: string) => {
    setValidState([isValid, validationMessage]);
  }, []);

  const saveButtonJsx = useMemo(() => {
    if (getSaveButtonJSX) {
      const [isValid, validMessage] = validState;
      return getSaveButtonJSX(isValid, validMessage);
    }

    return null;
  }, [getSaveButtonJSX, validState]);

  useEffect(() => {
    if (!selectedTabId) {
      setSelectedTabId(customizationTabs[0].id);
    }
  }, [selectedTabId]);

  const tabContent = useMemo(() => {
    const selectedTab = customizationTabs.find(opt => opt.value === selectedTabId) || customizationTabs[0];

    const TabComponent = selectedTab.component as ElementType<WidgetConfigurationTabProps>;

    const fWidgetImpl = cloneDeep(widgetImpl);
    fWidgetImpl.renderMode = "viz-only";
    fWidgetImpl.widgetConfigRefId = "";

    const { queryConfig } = fWidgetImpl;
    const { sourceQueryConfig } = queryConfig;

    if (sourceQueryConfig.queryType === "widgetConfig") {
      sourceQueryConfig.widgetResponse = widgetResponseDTO;
    } else {
      queryConfig.sourceQueryConfig = {
        queryType: "widgetConfig",
        metricId: Object.keys(widgetResponseDTO?.widgetConfig?.dataDefinition?.metrics || {})[0] || "",
        widgetResponse: widgetResponseDTO
      };
    }

    return (
      <TabComponent
        compareWidgetConfigDto={compareWidgetConfigDto}
        dbImpl={dbImpl}
        onUnsavedChangesExist={onUnsavedChangesExist}
        onWidgetImplChange={onWidgetImplChange}
        readOnly={readOnly}
        setValidState={updateValidState}
        widgetImpl={fWidgetImpl}
        widgetResponseDTO={widgetResponseDTO}
      />
    );
  }, [
    compareWidgetConfigDto,
    dbImpl,
    onUnsavedChangesExist,
    onWidgetImplChange,
    readOnly,
    selectedTabId,
    updateValidState,
    widgetImpl,
    widgetResponseDTO
  ]);

  const [vizWidgetImpl, setVizWidgetImpl] = useState(getVizWidgetImpl(widgetImpl));
  useEffect(() => {
    setVizWidgetImpl(prev => {
      if (!isEqual(widgetImpl, prev)) {
        return getVizWidgetImpl(widgetImpl);
      }

      return prev;
    });
  }, [widgetImpl]);

  const updateWidgetImpl = useCallback(
    (clWidgetImpl: CatalogWidgetImpl) => {
      onWidgetImplChange(widgetImpl => {
        extend(widgetImpl.properties, clWidgetImpl.properties);
        if (clWidgetImpl.timeRange) {
          widgetImpl.timeRange = clWidgetImpl.timeRange;
        }

        if (
          widgetImpl.queryConfig.sourceQueryConfig.queryType === "widgetConfig" &&
          clWidgetImpl.queryConfig.sourceQueryConfig.queryType === "widgetConfig"
        ) {
          widgetImpl.queryConfig.sourceQueryConfig.metricId = clWidgetImpl.queryConfig.sourceQueryConfig.metricId;
          widgetImpl.queryConfig.sourceQueryConfig.childMetricIds =
            clWidgetImpl.queryConfig.sourceQueryConfig.childMetricIds;
        }

        return widgetImpl;
      });
      setVizWidgetImpl(clWidgetImpl);
    },
    [onWidgetImplChange]
  );

  const canRenderViz = useMemo(() => {
    const isValid = validState[0];
    const widgetConfigDTO = (vizWidgetImpl.queryConfig.sourceQueryConfig as WidgetQuerySourceConfig)?.widgetResponse
      ?.widgetConfig;
    if (isValid && Boolean(widgetConfigDTO)) {
      const { metrics } = widgetConfigDTO.dataDefinition;
      const usFieldsExist = Object.values(metrics).every(metric => {
        if (metric.sourceType === "userServiceField") {
          return Boolean(metric.userServiceFieldMetricConfig?.userServiceField?.fieldName);
        }

        return true;
      });
      return usFieldsExist;
    }

    return false;
  }, [validState, vizWidgetImpl]);

  const showInconsistencyModal = featureFlagService.isFeatureEnabled(
    FEATURE_FLAGS.showInconsistencyModalForCatalogWidget
  );

  return (
    <div
      className="widget-configuration-content height-100"
      data-edit={Boolean(getSaveButtonJSX)}
    >
      <VerticallyCenteredRow
        className="width-100 height-100"
        style={{ overflow: "auto" }}
      >
        <div className="widget-configuration-content--queries">
          <VerticallyCenteredRow className="width-100">
            <IncTabBar
              className="widget-customization-tabs inc-flex-grow"
              disableRouteChange={true}
              handleSelection={setSelectedTabId}
              selected={selectedTabId}
              tabRenderer={tab => (
                <VerticallyCenteredRow className="inc-text-subtext-medium">{tab.label}</VerticallyCenteredRow>
              )}
              tabs={customizationTabs}
            />

            {Boolean(children) && (
              <VerticallyCenteredRow className="flex-gap-12 marginLt24">{children}</VerticallyCenteredRow>
            )}
          </VerticallyCenteredRow>
          {tabContent}
          {showInconsistencyModal && (
            <InconsistencyCheckConfirmModal
              onWidgetImplChange={onWidgetImplChange}
              widgetImpl={widgetImpl}
              widgetResponseDTO={widgetResponseDTO}
            />
          )}
        </div>

        <div className="widget-configuration-content--visualisation">
          <Visualization
            dbImpl={dbImpl}
            hideVizSwitcher
            isValid={canRenderViz}
            onWidgetImplChange={updateWidgetImpl}
            updateOnSilentChange
            variableSrvMap={variableSrvMap}
            vizErrorMessage={vizErrorMessage}
            widgetImpl={vizWidgetImpl}
          />
        </div>
      </VerticallyCenteredRow>

      {saveButtonJsx}
    </div>
  );
};

const getVizWidgetImpl = (widgetImpl: CatalogWidgetImpl): CatalogWidgetImpl => {
  const vizWidgetImpl = cloneDeep(widgetImpl);

  vizWidgetImpl.renderMode = "viz-only";
  vizWidgetImpl.widgetConfigRefId = "";
  if (vizWidgetImpl.queryConfig.sourceQueryConfig.queryType === "widgetConfig") {
    const { widgetResponse } = vizWidgetImpl.queryConfig.sourceQueryConfig;
    if (widgetResponse) {
      widgetResponse.widgetId = "";
    }
  }

  return vizWidgetImpl;
};
