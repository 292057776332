import { FC, useEffect, useMemo, memo, MutableRefObject } from "react";
import { useRenderOnVisibility } from "@inception/ui";
import { useFetchImpactedWidgetsData, ImpactedWidgetsDataEntry } from "../impact-widget/hooks";
import { useTimeRange } from "../../../core";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { DrilldownSubRendererProps } from "./types";

export interface DrilldownVizRendererProps extends DrilldownSubRendererProps {
  groupBy: string[];
  vizRenderer: (dataState: ImpactedWidgetsDataEntry, ref: MutableRefObject<HTMLDivElement>) => JSX.Element;
}

export const DrilldownVizRenderer: FC<DrilldownVizRendererProps> = memo(props => {
  const {
    impactedWidget,
    opConfigId,
    incidentId,
    groupBy,
    vizRenderer,
    entityFilters,
    userServiceFilters,
    generateDemoData
  } = props;

  const { wasVisibleOnce, ref } = useRenderOnVisibility();

  const { timeRange } = useTimeRange();
  const { fromMillis, toMillis } = useMemo(() => timeRangeUtils.getMillisFromTimeRange(timeRange), [timeRange]);

  const impactedWidgets = useMemo(() => [impactedWidget], [impactedWidget]);
  const selImpactWidgetId = impactedWidget.id;

  const { refetch, resultsByImpactedWidget } = useFetchImpactedWidgetsData(
    impactedWidgets,
    incidentId,
    opConfigId,
    groupBy,
    entityFilters,
    userServiceFilters,
    fromMillis,
    toMillis,
    groupBy,
    null,
    null,
    null,
    null,
    null,
    generateDemoData
  );

  useEffect(() => {
    if (wasVisibleOnce) {
      refetch();
    }
  }, [refetch, wasVisibleOnce]);

  const impactedWidgetDataState = useMemo(() => {
    const dataRec = resultsByImpactedWidget?.[selImpactWidgetId] || {};
    const datum = Object.values(dataRec)[0] || {
      data: null,
      error: null,
      isError: false,
      isFetching: false
    };

    return datum;
  }, [resultsByImpactedWidget, selImpactWidgetId]);

  return useMemo(() => vizRenderer(impactedWidgetDataState, ref), [impactedWidgetDataState, ref, vizRenderer]);
});
