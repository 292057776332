import React, { FC } from "react";
import { cx } from "emotion";
import { VerticallyCenteredRow } from "../../flex-components";
import { CohortDefinitionRenderer, CohortBizFieldPicker } from "../../cohort";
import { EventCriteriaPicker } from "../../event-filters";
import {
  CohortConfig,
  BizFieldPredicate,
  UserServiceFilterExpression,
  FieldPickerContextDTO
} from "../../../services/api/explore";

interface Props {
  entityType: string;

  className?: string;
  readOnly?: boolean;

  cohortDefinition?: CohortConfig;

  entityCriteria?: BizFieldPredicate[];
  onEntityCriteriaChange?: (entityCriteria: BizFieldPredicate[]) => void;

  eventCriteria?: UserServiceFilterExpression[];
  eventFieldPickerContext?: FieldPickerContextDTO;
  onEventCriteriaChange?: (eventCriteria: UserServiceFilterExpression[]) => void;

  sectionChildren?: {
    cohortDef?: JSX.Element | JSX.Element[];
    entityCriteria?: JSX.Element | JSX.Element[];
    eventCriteria?: JSX.Element | JSX.Element[];
  };

  children?: JSX.Element | JSX.Element[];
}

export const TriageCriteriaRenderer: FC<Props> = props => {
  const {
    entityType,
    className: pClassName = "",
    cohortDefinition,
    entityCriteria,
    onEntityCriteriaChange,
    eventCriteria,
    eventFieldPickerContext,
    onEventCriteriaChange,
    readOnly,
    sectionChildren,
    children
  } = props;

  const className = cx("triage-criteria-renderer", {
    [pClassName]: Boolean(pClassName)
  });

  return (
    <VerticallyCenteredRow className={className}>
      {Boolean(cohortDefinition) && (
        <VerticallyCenteredRow className="cohort-criteria">
          <VerticallyCenteredRow>
            <div className="inc-label-common marginRt12">Cohort</div>

            <VerticallyCenteredRow>
              {Boolean(sectionChildren?.cohortDef) && sectionChildren.cohortDef}

              <CohortDefinitionRenderer
                bizEntityType={entityType}
                cohortDefinition={cohortDefinition}
                hideEntityIcon
                hidePills
                showCreationDetails={false}
              />
            </VerticallyCenteredRow>
          </VerticallyCenteredRow>
        </VerticallyCenteredRow>
      )}

      {Boolean(entityCriteria) && (
        <VerticallyCenteredRow className="entity-criteria">
          <VerticallyCenteredRow>
            <div className="inc-label-common marginRt12">Entity Criteria</div>

            <VerticallyCenteredRow>
              {Boolean(sectionChildren?.entityCriteria) && sectionChildren.entityCriteria}

              <CohortBizFieldPicker
                bizFieldPredicates={entityCriteria}
                cohortId={cohortDefinition?.cohortId}
                disabled={readOnly}
                entityTypeId={entityType}
                label=""
                onChange={onEntityCriteriaChange}
                placeholder={readOnly ? "No filters" : ""}
              />
            </VerticallyCenteredRow>
          </VerticallyCenteredRow>
        </VerticallyCenteredRow>
      )}

      {Boolean(eventCriteria) && (
        <VerticallyCenteredRow className="event-criteria">
          <VerticallyCenteredRow>
            <div className="inc-label-common marginRt12">Event Criteria</div>

            <VerticallyCenteredRow>
              {Boolean(sectionChildren?.eventCriteria) && sectionChildren.eventCriteria}

              <EventCriteriaPicker
                disabled={readOnly}
                fieldPickerContext={eventFieldPickerContext}
                filters={eventCriteria}
                label=""
                onChange={onEventCriteriaChange}
                pillLimit={3}
                placeholder=""
              />
            </VerticallyCenteredRow>
          </VerticallyCenteredRow>
        </VerticallyCenteredRow>
      )}

      {children}
    </VerticallyCenteredRow>
  );
};
