import { getFormattedDateTime, IncDateTimeFormat } from "@inception/ui";
import { last } from "lodash";
import { Visualisations } from "../core";
import { ConversationEntry, VizOption, Conversation } from "../services/api/chat";

export const getConversationListFromConversations = (conversations: Conversation[]) => {
  const conversationEntriesList = conversations.map(conversation => last(conversation.conversationEntries));
  return sortConversationEntries(conversationEntriesList);
};

export const sortConversationEntries = (conversationEntries: ConversationEntry[]) =>
  conversationEntries.sort((a, b) => {
    const tsMillisStrA = a.timeStampInMillis || a.bicycleMessageTimeMillis;
    const tsMillisStrB = b.timeStampInMillis || b.bicycleMessageTimeMillis;

    return parseInt(tsMillisStrB, 10) - parseInt(tsMillisStrA, 10);
  });

export const getChatTimeStampStr = (tsMillisStr: string) => {
  const tsMillis = parseInt(tsMillisStr, 10);
  const tsStr = getFormattedDateTime(tsMillis, IncDateTimeFormat.numeric, {
    dateSeparator: ".",
    dateTimeSeparator: " , ",
    relative: true
  });

  return tsStr;
};

export const getVisualisationFromVizOption = (vizOption: VizOption) => vizMap[vizOption];

export const vizMap: Record<VizOption, Visualisations> = {
  [VizOption.BAR_CHART]: Visualisations.barChart,
  [VizOption.DONUT]: Visualisations.donut,
  [VizOption.GAUGE]: Visualisations.gauge,
  [VizOption.SINGLE_STAT]: Visualisations.singleStat,
  [VizOption.TEXT]: null,
  [VizOption.TIME_SERIES]: Visualisations.timeseries,
  [VizOption.TREE_MAP]: Visualisations.treeMap,
  [VizOption.INSIGHTS]: Visualisations.insights,
  [VizOption.TABLE]: Visualisations.table,
  [VizOption.VIZ_NA]: Visualisations.barChart,
  [VizOption.RAW_DATA]: Visualisations.table // not valid
};
