import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { CurrencyType, IncButton } from "@inception/ui";
import { cloneDeep } from "lodash";
import {
  ExploreEntityFilter,
  MetricUserServiceFilters,
  WidgetResponseDTO
} from "../../../../../../../../services/api/explore";
import { Datum } from "../../utils";
import { useFetchRawEvents } from "../hooks";
import { CatalogWidgetProperties } from "../../../../../models";
import { TimeRange, useInputState } from "../../../../../../../../core";
import timeRangeUtils from "../../../../../../../../utils/TimeRangeUtils";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../../../../../../components";
import { EventChartType } from "../eventTimelineUtils";
import EventTable from "./EventTable";
import RawEventCriteria from "./RawEventCriteria";
import { FieldsToFilter, convertCriteriaFieldsFromRawEvents, getBizDataQuery } from "./utils";

type Props = {
  widgetResponseDto: WidgetResponseDTO;
  metricId: string;
  tableInfo?: Datum;
  aggTags: string[];
  currencyType?: CurrencyType;
  widgetProperties?: CatalogWidgetProperties;
  timeRange: TimeRange;
  eventTypeId: string;
  entityType: string;
  downsample: string;
  edit?: boolean;
  entityFilters?: ExploreEntityFilter[];
  metricUserServiceFilters?: MetricUserServiceFilters;
  chartType?: EventChartType | "none";
  onSaveCustomHeaderMap?: (selectionMap: Record<string, string>) => void;
};

const RawEventTable: FC<Props> = props => {
  const {
    aggTags,
    metricId,
    widgetResponseDto,
    tableInfo,
    widgetProperties,
    currencyType,
    edit: editMode,
    timeRange,
    eventTypeId,
    entityType,
    downsample,
    onSaveCustomHeaderMap,
    entityFilters,
    metricUserServiceFilters
  } = props;

  const { metricConfig } = widgetProperties;

  const { fromMillis, toMillis } = useMemo(() => timeRangeUtils.getMillisFromTimeRange(timeRange), [timeRange]);
  const bizDataQuery = useMemo(
    () =>
      getBizDataQuery({
        aggTags,
        metricId,
        widgetResponseDto,
        tableInfo,
        fromMillis,
        toMillis,
        downsample,
        entityFilters,
        metricUserServiceFilters
      }),
    [
      aggTags,
      downsample,
      entityFilters,
      fromMillis,
      metricId,
      metricUserServiceFilters,
      tableInfo,
      toMillis,
      widgetResponseDto
    ]
  );
  const { data, error, isError, isFetching, refetch } = useFetchRawEvents(
    bizDataQuery,
    eventTypeId,
    entityType,
    fromMillis,
    toMillis
  );
  useEffect(() => {
    if (metricId && widgetResponseDto) {
      refetch();
    }
  }, [metricId, refetch, widgetResponseDto]);
  const { inputValue, onInputChange } = useInputState("");
  const fieldsToFilter: FieldsToFilter[] = useMemo(
    () => [
      {
        name: "bicycle",
        checkAbsolute: false
      },
      {
        name: "inception",
        checkAbsolute: false
      }
    ],
    []
  );
  const customHeaderMap = useMemo(() => metricConfig?.[metricId]?.rawEvents?.columns || {}, [metricConfig, metricId]);

  const fieldsToSelect = useMemo(
    () => convertCriteriaFieldsFromRawEvents(data?.data || [], inputValue, fieldsToFilter, [], customHeaderMap),
    [data?.data, fieldsToFilter, inputValue, customHeaderMap]
  );
  const [selectionMap, setSelectionMap] = useState<Record<string, string>>(customHeaderMap);

  useEffect(() => {
    setSelectionMap(customHeaderMap);
  }, [customHeaderMap]);

  const onSave = useCallback(() => {
    if (onSaveCustomHeaderMap) {
      onSaveCustomHeaderMap(selectionMap);
    }
  }, [onSaveCustomHeaderMap, selectionMap]);

  const onCustomColumnNameChange = useCallback((key: string, value: string) => {
    setSelectionMap(prev => ({
      ...prev,
      [key]: value
    }));
  }, []);

  const onSelectionChange = useCallback(
    (key: string) => {
      setSelectionMap(prev => {
        if (prev[key]) {
          const clonedKeys = cloneDeep(prev);
          delete clonedKeys[key];
          return clonedKeys;
        } else {
          const customValue = customHeaderMap[key] || key;
          return {
            ...prev,
            [key]: customValue
          };
        }
      });
    },
    [customHeaderMap]
  );

  const onSelectAll = useCallback(() => {
    const selectionMap: Record<string, string> = {};
    fieldsToSelect.forEach(ele => {
      selectionMap[ele.field] = ele.field;
    });
    setSelectionMap(selectionMap);
  }, [fieldsToSelect]);
  const onRemoveAll = useCallback(() => {
    setSelectionMap({});
  }, []);

  const dataAvailable = useMemo(() => Boolean(data?.data?.length), [data?.data?.length]);

  return (
    <div className="change-event-table-wrapper paddingTp16 paddingBt16 inc-flex-column">
      <VerticallyCenteredRow className="inc-flex inc-flex-space-contents">
        {editMode && (
          <IncButton
            className="marginBt16 marginLtAuto"
            color="secondary-blue"
            disabled={!dataAvailable}
            iconName="save"
            onClick={onSave}
          >
            Save
          </IncButton>
        )}
      </VerticallyCenteredRow>
      <div style={{ flex: 1 }}>
        {isFetching && <LoadingSpinner titleText="Loading..." />}
        {!isFetching && isError && (
          <VerticallyCenteredRow className="message inc-flex-center">
            Error fetching data: {error}
          </VerticallyCenteredRow>
        )}
        {!isFetching && !isError && dataAvailable && (
          <div className="events-table">
            {editMode && (
              <RawEventCriteria
                fieldsToSelect={fieldsToSelect}
                isFetching={isFetching}
                onRemoveAll={onRemoveAll}
                onSearchTextChange={onInputChange}
                onSelectAll={onSelectAll}
                onSelectionChange={onSelectionChange}
                searchText={inputValue}
                selectionMap={selectionMap}
              />
            )}
            <EventTable
              currencyType={currencyType}
              edit={editMode}
              error={error}
              isError={isError}
              isFetching={isFetching}
              onColumnNameChange={onCustomColumnNameChange}
              rawEventsData={data}
              selectionMap={selectionMap}
              widgetProperties={widgetProperties}
            />
          </div>
        )}
        {!isFetching && !isError && !dataAvailable && (
          <VerticallyCenteredRow className="message inc-flex-center">No data found</VerticallyCenteredRow>
        )}
      </div>
    </div>
  );
};

export default RawEventTable;
