import {
  clone,
  defaults,
  defaultsDeep,
  find,
  findIndex,
  indexOf,
  isArray,
  map,
  set,
  cloneDeep,
  isEmpty,
  isNumber
} from "lodash";
import { Layout } from "react-grid-layout";
import { generateId, logger, TimeRange } from "../../core";
import BaseImpl from "../../core/BaseImpl";
import { dashboardUtils } from "../dashboard-grid/DashboardUtils";
import BaseWidgetModel, { WidgetViewState } from "../models/BaseWidgetModel";
import DashboardModel, {
  DashboardModelMeta,
  DashboardModelDto,
  DashboardType,
  DashboardPresetTimeInfo
} from "../models/DashboardModel";
import VariableModel from "../models/VariableModel";
import { variableUtils } from "../variables";
import { widgetRegistry } from "../widgets/WidgetRegistry";
import { getTimeRange, getCompareTimeRange } from "../../core/hooks/time-range/TimeRangeGetter";
import BaseWidgetImpl from "./BaseWidgetImpl";
import VariableImpl from "./VariableImpl";

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

const StateOnlyProps = ["layoutMap", "widgetMap"];

const getDefaults = () => dashboardUtils.getNewDashboard();

const getDefaultLayout = (type: string): Layout => {
  const defaultLayout: Layout = {
    x: 0,
    y: 0,
    h: 8,
    w: 8,
    minH: 8,
    minW: 3,
    isDraggable: true,
    isResizable: true,
    static: false,
    moved: false
  };

  const widgetDefaultLayout = widgetRegistry.getPropsByWidgetId(type)?.dimensions();
  return defaults(widgetDefaultLayout, defaultLayout);
};

export default class DashboardImpl extends BaseImpl<DashboardModel> implements DashboardModelDto {
  id: string;
  name: string;
  description: string;
  type: DashboardType;
  variables: VariableImpl[];
  widgets: BaseWidgetImpl[];
  layout: Layout[];
  timeRange: TimeRange;
  compareTimeRange: TimeRange;
  autoRefreshInterval: number;
  version: number;
  entityTypeId: string;
  entityTypeName: string;
  cohortId: string;
  cohortName: string;
  isDynamic: boolean;
  apptuitDbId: number;

  // State only props, shouldn't be saved in the backend
  private layoutMap: Record<string, Layout>;
  private widgetMap: Record<string, BaseWidgetImpl>;

  meta: DashboardModelMeta;
  presetTimeInfo: DashboardPresetTimeInfo;

  /**
   * onWidgetAction is to intercept all actions triggered from a widget to dashboard initiator
   * Default is a no op
   */

  constructor(model: DeepPartial<DashboardModel>, apptuitDbId?: number) {
    super();
    this.apptuitDbId = apptuitDbId;
    this.assign(model);
  }

  protected assign(model: DeepPartial<DashboardModel>) {
    const dbModel: Partial<DashboardModel> = {};
    defaults(dbModel, model, getDefaults());

    this.layoutMap = {};
    this.widgetMap = {};

    const {
      id,
      name,
      description,
      variables,
      widgets,
      layout,
      autoRefreshInterval,
      version,
      type,
      entityTypeId,
      entityTypeName,
      cohortId,
      cohortName,
      presetTimeInfo: timeInfo,
      isDynamic = false
    } = dbModel;

    this.presetTimeInfo = timeInfo || {};

    this.meta = {
      edit: true,
      compare: false,
      resizable: true,
      syncTooltip: true,
      tags: [],
      ...(model.meta || {})
    } as DashboardModelMeta;

    this.id = id;
    this.name = name || "";
    this.description = description || "";
    this.type = type ?? "default";
    this.variables = [];
    this.widgets = [];
    this.layout = [];
    this.autoRefreshInterval = autoRefreshInterval;
    this.version = version;
    this.entityTypeId = entityTypeId;
    this.entityTypeName = entityTypeName;
    this.cohortId = cohortId;
    this.cohortName = cohortName;
    this.isDynamic = isDynamic;
    map(variables, (v: VariableModel) => this.addVariable(v));
    map(widgets, (w: BaseWidgetModel) => {
      const wLayout = find(layout, { i: w.id });
      this.addWidget(w, wLayout, true);
    });

    this.initTimeRange();
    this.initCompareTimeRange();
  }

  isCohortDashboard(): boolean {
    return this.type === "cohort";
  }

  isDefaultDashboard(): boolean {
    return this.type === "default";
  }

  isDynamicDashboard(): boolean {
    return this.isDynamic || this.isCohortDashboard();
  }

  onMaximize(widgetId: string) {
    console.log(widgetId, "+++++++");
  }

  addWidget(widgetModel: Partial<BaseWidgetModel>, layout?: Partial<Layout>, skipLayoutAdjustment = false) {
    const { type } = widgetModel;
    const widget = dashboardUtils.getDefaultWidgetImplByType(type);
    widget.update(widgetModel);
    this.addWidgetInternal(widget, layout, skipLayoutAdjustment);
  }

  addWidgetByType(type: string) {
    const widget = dashboardUtils.getDefaultWidgetImplByType(type);
    this.addWidgetInternal(widget);
  }

  removeWidget(widgetId: string) {
    this.widgets = this.widgets.filter(({ id }) => id !== widgetId);
    this.layout = this.layout.filter(({ i }) => i !== widgetId);
    delete this.layoutMap[widgetId];
    delete this.widgetMap[widgetId];
  }

  updateWidget(id: string, newWidgetModel: BaseWidgetModel, asClone = false) {
    const widgetIdx = this.widgets.findIndex(w => w.id === id);
    if (widgetIdx >= 0) {
      const widget = this.widgets[widgetIdx];
      widget.update(newWidgetModel);
      if (asClone) {
        const clonedWidget = clone(widget);
        this.widgetMap[id] = clonedWidget;
        this.widgets[widgetIdx] = clonedWidget;
      }
    } else {
      logger.error("Dashboard Widget Update", "Error while updating widget, widget not found", id);
    }
  }

  updateWidgetType(widgetId: string, newType: string, newWidgetModel: BaseWidgetModel) {
    const widget = this.widgetMap[widgetId];
    if (widget) {
      const idx = indexOf(this.widgets, widget);
      if (idx !== -1) {
        const saveModel = widget.getSaveModel();
        const model = defaultsDeep({}, newWidgetModel || {}, saveModel);
        const newWidget = dashboardUtils.getDefaultWidgetImplByType(newType);
        const newModel = dashboardUtils.getBaseWidgetModel(model, newType);
        newWidget.update(newModel);
        this.widgets[idx] = newWidget;
        this.widgetMap[widgetId] = newWidget;
      }
    }
  }

  addVariable(variable: VariableModel) {
    const typeVariable = variableUtils.getVariableByType(variable?.type);
    typeVariable.update(variable);
    this.variables.push(typeVariable);
  }

  cloneWidget(widgetId: string) {
    const cloneModel = this.widgetMap[widgetId].getSaveModel();
    cloneModel.id = generateId();
    const layout = this.layoutMap[widgetId];
    this.addWidget(cloneModel, layout);
  }

  toggleWidgetLock(widgetId: string) {
    const widgetLayout = this.layoutMap[widgetId];
    if (widgetLayout) {
      const newWidgetLayout: Layout = {
        ...widgetLayout,
        isDraggable: widgetLayout.static,
        isResizable: widgetLayout.static,
        static: !widgetLayout.static
      };
      const newLayout = [...this.layout];
      const layoutIndex = findIndex(newLayout, { i: widgetId });
      if (layoutIndex >= 0) {
        newLayout[layoutIndex] = newWidgetLayout;
        this.updateLayout(newLayout);
      }
    }
  }

  toggleMaximizeWidget(widgetId: string) {
    const widget = this.widgetMap[widgetId];
    if (widget) {
      const isCurrentFullScreen = widget.properties.viewState === WidgetViewState.FULLSCREEN;
      widget.properties.viewState = isCurrentFullScreen ? WidgetViewState.MINIMIZE : WidgetViewState.FULLSCREEN;
    }
  }

  getLayoutById(id: string): Layout {
    return this.layoutMap[id] || (getDefaultLayout(this.widgetMap[id]?.type || "") as Layout);
  }

  getWidgetById(id: string): BaseWidgetImpl {
    return this.widgetMap[id];
  }

  getCohortDetails() {
    if (this.isCohortDashboard()) {
      return {
        cohortId: this.cohortId,
        cohortName: this.cohortName,
        entityTypeId: this.entityTypeId,
        entityTypeName: this.entityTypeName
      };
    }
    return null;
  }

  updateLayout(layout: Layout[]) {
    this.layout = layout;
    this.updateLayoutMap();
  }

  update(model: Partial<DashboardModel>) {
    this.assign(model);
  }

  setEditable(edit: boolean) {
    this.meta.edit = edit;
    this.meta.resizable = edit;
  }

  setCompare(compare: boolean) {
    this.meta.compare = compare;
  }

  setCompareTimeRange(timeRange: TimeRange) {
    this.compareTimeRange = timeRange;
  }

  setTimeRange(timeRange: TimeRange) {
    this.timeRange = timeRange;
  }

  getEntityTypeId() {
    return this.entityTypeId;
  }

  getEntityTypeName() {
    return this.entityTypeName;
  }

  getCohortName() {
    return this.cohortName;
  }

  getCohortId() {
    return this.cohortId;
  }

  setTimeInfo(timeInfo: DashboardPresetTimeInfo) {
    if (timeInfo) {
      this.presetTimeInfo = timeInfo;
    }
  }

  private initTimeRange() {
    const globalTimeRange = getTimeRange();

    const timeRange = cloneDeep(globalTimeRange);

    this.setTimeRange(timeRange);
  }

  private initCompareTimeRange() {
    const globalCompareTimeRange = getCompareTimeRange();

    const timeRange = cloneDeep(globalCompareTimeRange);

    this.setCompareTimeRange(timeRange);
  }

  private updateLayoutMap() {
    this.layoutMap = {};
    map(this.layout, layout => {
      this.layoutMap[layout.i] = layout;
    });
  }

  private addWidgetInternal(widget: BaseWidgetImpl, layout?: Partial<Layout>, skipLayoutAdjustment = false) {
    this.widgets = [...this.widgets, widget];
    this.widgetMap[widget.id] = widget;

    if (widget.type !== "filter") {
      const widgetLayout: Layout = defaults({}, layout, getDefaultLayout(widget.type));
      widgetLayout.i = widget.id;

      if (!skipLayoutAdjustment) {
        // Set Y to max only if the layout for this widget is not provided
        const overrideY = isEmpty(layout) || !isNumber(layout?.y);
        if (overrideY) {
          const maxY = this.getMaxLayoutY();
          widgetLayout.y = maxY;
        }

        const widgetsAt0CornerExist = this.layout.some(l => l.x === 0 && l.y === 0);
        const currEntryAt0 = layout?.x === 0 && layout?.y === 0;
        const shouldAdjustLayout = currEntryAt0 && widgetsAt0CornerExist;

        if (shouldAdjustLayout) {
          this.layout = this.layout.map(l => {
            if (l.x === 0) {
              return {
                y: l.y++,
                ...l
              };
            }
            return l;
          });
        }
      }

      this.layout.push(widgetLayout);
      this.layoutMap[widget.id] = widgetLayout;
    }
  }

  private getMaxLayoutY() {
    let maxY = 0;
    let h = 0;

    map(this.layout, layout => {
      maxY = Math.max(maxY, layout.y);
      if (maxY === layout.y) {
        h = layout.h;
      }
    });

    return maxY + h;
  }

  getSaveModel(): DashboardModel {
    const model = {};
    const getModelValue = (value: any) => (value.getSaveModel ? value.getSaveModel() : value);

    for (const key in this) {
      const value = this[key] as any;
      if (this.hasOwnProperty(key) && value && StateOnlyProps.indexOf(key) === -1) {
        let v: any;
        if (isArray(value)) {
          v = value.map(val => getModelValue(val));
        } else {
          v = getModelValue(value);
        }

        set(model, key, v);
      }
    }
    return model as DashboardModel;
  }
}
