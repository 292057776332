import { routePaths } from "../../../../../app/RoutePaths";
import {
  CSV_CONFIG_TYPE,
  GITLAB_CONFIG_TYPE,
  JDBC_CONFIG_TYPE,
  SALESFORCE_CONFIG_TYPE,
  WEBHOOK_CONFIG_TYPE,
  ZUORA_CONFIG_TYPE
} from "../../../../../platform/components/business-entity/source-constants";
import {
  EntityConnectorActionRequestPayload,
  EntityConnectorActions,
  EntityConnectorStreamDetail
} from "../../../../../platform/services/api/entity-mapping";
import { Mode } from "../../types/types";

export const getBizSystemRouteStringToNavigate = (
  sourceTypeId: string,
  mode: "add" | "edit",
  configId?: string
): string => {
  let searchParam = "";
  if (mode === "edit") {
    searchParam = `/edit?id=${configId}`;
  } else {
    searchParam = `/add`;
  }

  switch (sourceTypeId) {
    case SALESFORCE_CONFIG_TYPE:
      //case SALESFORCE_CONFIG_TYPE_2:
      return `${routePaths.integrationsSalesforce}${searchParam}`;

    case ZUORA_CONFIG_TYPE:
      return `${routePaths.integrationsZuora}${searchParam}`;

    case JDBC_CONFIG_TYPE:
      return `${routePaths.integrationsDatabase}${searchParam}`;

    case WEBHOOK_CONFIG_TYPE:
      return `${routePaths.integrationsWebhook}${searchParam}`;

    case GITLAB_CONFIG_TYPE:
      return `${routePaths.integrationsGitlab}${searchParam}`;

    case CSV_CONFIG_TYPE:
      return `${routePaths.integrationsCsv}${searchParam}`;

    default: {
      let trailingPathParam = "";
      if (mode === "edit") {
        trailingPathParam = `${configId}/edit`;
      } else {
        trailingPathParam = `add`;
      }

      return `${routePaths.integrationsGeneric}/BUSINESS_SYSTEM/${sourceTypeId}/${trailingPathParam}`;
    }
  }
};

/**
 * Returns the route with search param to navigate to based on source type and if its add or edit mode
 * @param sourceType e.g. connector_salesforce
 * @param mode Either 'add' or 'edit
 * @param configId If its edit mode then config id needs to be passed
 */
export const getBizEventSourceRouteStringToNavigate = (
  eventSourceTypeId: string,
  mode: Mode,
  configId?: string
): string => {
  let trailingPathParam = "";
  if (mode === "edit") {
    trailingPathParam = `${configId}/edit`;
  } else if (mode === "clone") {
    trailingPathParam = `${configId}/clone`;
  } else {
    trailingPathParam = "add";
  }

  switch (eventSourceTypeId) {
    // case 'elastic':
    //   return `${routePaths.integrationsElastic}${searchParam}`;

    default:
      return `${routePaths.integrationsGeneric}/EVENT/${eventSourceTypeId}/${trailingPathParam}`;
  }
};

export const getBizKnowledgeBaseRouteStringToNavigate = (
  eventSourceTypeId: string,
  mode: Mode,
  streamId?: string
): string => {
  let trailingPathParam = "";
  if (mode === "edit") {
    trailingPathParam = `${streamId}/edit`;
  } else if (mode === "clone") {
    trailingPathParam = `${streamId}/clone`;
  } else {
    trailingPathParam = "add";
  }

  switch (eventSourceTypeId) {
    default:
      return `${routePaths.integrationsGeneric}/KNOWLEDGE_BASE/${eventSourceTypeId}/${trailingPathParam}`;
  }
};

export const getEntityConnectorActionPayload = (
  details: EntityConnectorStreamDetail,
  action: EntityConnectorActions,
  expr = ""
): Omit<EntityConnectorActionRequestPayload, "traceInfo"> => {
  let updatedScheduleJson: any = null;
  try {
    const scheduleJson = JSON.parse(details.streamDetail.scheduleJson);
    updatedScheduleJson = {
      ...scheduleJson,
      cronExpr: expr ? expr : scheduleJson.cronExpr
    };
  } catch (error) {
    updatedScheduleJson = {
      cronExpr: expr || ""
    };
  }
  if (action === EntityConnectorActions.EDIT_SCHEDULE) {
    return {
      connectorAction: EntityConnectorActions.EDIT_SCHEDULE,
      retry: 1,
      sourceType: details.streamDetail.sourceType,
      scheduleJson: JSON.stringify(updatedScheduleJson),
      streamDetails: details.streamDetail
    };
  } else {
    return {
      connectorAction: action,
      retry: 1,
      sourceType: details.streamDetail.sourceType,
      streamId: details.streamDetail.configuredConnectorStreamId
    };
  }
};
