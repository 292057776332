import { ReactNode } from "react";
import { NodeProps, XYPosition } from "react-flow-renderer";
import { IncBadgeProps, IncFaIconName } from "@inception/ui";

import { ConfigurationModel } from "../../services/api/configuration/types/configuration";
import { BizDataQuery, ExploreEntityFilter, UserServiceFilter } from "../../services/api/explore/types";
import { StatPosition } from "../../services/api/business-process";

export type IncEntity = {
  label: string;
  icon: string;
};

export type IncActivity = {
  id: string;
  label: string;
  source: string;
  target: string;
};

export type IncProcess = {
  id: string;
  activities: IncActivity[];
};

export type IncWorkflow = {
  entities: IncEntity[];
  processes: IncProcess[];
};

export type WorkflowNodeType = "static" | "derived" | "entity" | "activity";
export type WorkflowNodeShape = "rectangle" | "square" | "circle" | "ellipse";
export type WorkflowNodeRenderType = "single" | "sequence";

export interface IncEntityFilter extends ExploreEntityFilter {
  cohortId: string;
  entityTypeId: string;
  entityTypeName: string;
  disabled: boolean;
}

export interface IncActivityFilter extends UserServiceFilter {
  eventTypeId: string;
  eventTypeName: string;
  disabled: boolean;
}

export interface WorkflowProperties {
  entityFilters?: IncEntityFilter[];
  eventFieldFilters?: IncActivityFilter[];
  errorsOnly?: boolean;
}

export interface WorkflowConfig {
  id: string;
  name: string;
  nodes: NodeDataProps[];
  connections: NodeDataProps[];
  properties: WorkflowProperties;

  payloadProps?: Pick<ConfigurationModel, "createdTime" | "tags" | "version" | "enabled">;
}

export const ActionPositions = {
  SEPARATOR: "separator",
  ENTITY: "entity",
  ACTION: "action"
};

export type ActionProps = {
  data: {
    label: string;
    value: any;
    icon?: IncFaIconName | string;
  };
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, id: string, data: any) => void;
  id: string;
  showTitle?: boolean;
  actionClass?: string;
  position?: string;
};

export type StatProps = {
  id?: string;

  label?: string;
  value?: string;
  type?: string;

  icon?: IncFaIconName;
  loading?: boolean;
  tooltip?: string;

  className?: string;
  style?: any;

  position?: StatPosition;
};

export type NodeDataProps = {
  id: string;
  label: string;
  type: WorkflowNodeType;

  infoText?: string;
  icon?: IncFaIconName;
  shape?: WorkflowNodeShape;

  color?: string;
  renderType?: WorkflowNodeRenderType;
  status?: IncBadgeProps["status"];
  style?: any;

  nodeActions?: JSX.Element | JSX.Element[];
  labelRenderer?: (props: NodeDataProps) => JSX.Element;

  stats?: StatProps[];
  actions?: ActionProps[];
  bizDataQuery?: BizDataQuery;
  data?: any;
  badgeCount?: number;

  sourceNodeId?: string;
  targetNodeId?: string;
  sourcePosition?: NodeProps["sourcePosition"];
  targetPosition?: NodeProps["targetPosition"];
  position?: XYPosition;
};

export type WorkflowNodeProps = {
  id: string;
  type: WorkflowNodeType;

  content?: ReactNode;
  sourcePosition?: any;
  targetPosition?: any;
  label: string;
  draggable?: boolean;
  style?: any;
  position: {
    x: number;
    y: number;
  };

  data: NodeDataProps & {
    numActivities?: number;
  };
  onClick?: any;
};

/*******
 * Biz flow props
 */

export type ConnectionType = "static" | "derived";
export type ConnectionColor = "default" | "source" | "target";
export type ConnectionShape = "default" | "dashed";

export interface WorkFlowConnectionProps {
  id: string;
  label: string;
  type: ConnectionType;

  color?: ConnectionColor;
  shape?: ConnectionShape;

  sourceNodeId: string;
  targetNodeId: string;

  bizDataQuery?: BizDataQuery;
}
