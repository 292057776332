import {
  getCurrencyInfo,
  CurrencyType,
  getNumberInfo,
  NumericDataInfo,
  getDurationInfo,
  getDuration,
  getSymbolForCurrency
} from "@inception/ui";

export interface BinSize {
  numberOfBins: number;
  pointWidth: number;
}

export const getLabel = (
  minValue: string,
  maxValue: string,
  disableAbbreviations: boolean,
  currencyType?: CurrencyType,
  durationType?: string
): string => {
  if (durationType) {
    return getDurationLabel(minValue, maxValue);
  }

  const symbol = getSymbolForCurrency(currencyType);
  const minAbValue = getNumericDataInfo(minValue, disableAbbreviations, {
    currencyType
  }).abbreviatedValue;
  const maxAbValue = getNumericDataInfo(maxValue, disableAbbreviations, {
    currencyType
  }).abbreviatedValue;

  if (!minValue && !maxValue) {
    return `> ${symbol}0`;
  } else if (!minValue && maxValue) {
    return `<= ${symbol}${maxAbValue}`;
  } else if (minValue && !maxValue) {
    return `>= ${symbol}${minAbValue}`;
  } else if (minValue === maxValue) {
    return `= ${symbol}${minAbValue}`;
  } else {
    return `between ${symbol}${minAbValue} and ${symbol}${maxAbValue}`;
  }
};

export const getDurationLabel = (minValue: string, maxValue: string): string => {
  const minValueDurationStr = getDuration(minValue);
  const maxValueDurationStr = getDuration(maxValue);

  if (!minValue && !maxValue) {
    return `> 0`;
  } else if (!minValue && maxValue) {
    return `<= ${maxValueDurationStr}`;
  } else if (minValue && !maxValue) {
    return `>= ${minValueDurationStr}`;
  } else if (minValue === maxValue) {
    return `= ${minValueDurationStr}`;
  } else {
    return `between ${minValueDurationStr} and ${maxValueDurationStr}`;
  }
};

export const isMaxSmallerThanMin = (
  min: string,
  max: string,
  disableAbbreviations: boolean,
  currencyType?: CurrencyType,
  durationType?: string
) => {
  if (!min || !max || min === "No Min" || max === "No Max") {
    return false;
  }

  const minAbsValue = getNumericDataInfo(min, disableAbbreviations, {
    currencyType,
    durationType
  }).absoluteValue;
  const maxAbsValue = getNumericDataInfo(max, disableAbbreviations, {
    currencyType,
    durationType
  }).absoluteValue;

  return Number(maxAbsValue) < Number(minAbsValue);
};

export const roundOffNumInZeros = (num: number): number => {
  const roundedNum = Math.round(num);
  const len = roundedNum.toString().length;
  if (len >= 6) {
    const result = roundedNum.toString().split("").fill("0", 3, len);
    return parseInt(result.join(""), 10);
  } else if (len === 4 || len === 5) {
    const result = roundedNum.toString().split("").fill("0", 2, len);
    return parseInt(result.join(""), 10);
  } else {
    return roundedNum;
  }
};

export const getBinSize = (
  chartWidth: number,
  minPointWidth: number,
  maxPointWidth: number,
  dataLen: number
): BinSize => {
  const maxPossibleBins = Math.floor(chartWidth / minPointWidth);
  if (dataLen >= maxPossibleBins) {
    return {
      numberOfBins: maxPossibleBins,
      pointWidth: minPointWidth
    };
  } else {
    const pointWidth = Math.floor(chartWidth / dataLen);
    return {
      numberOfBins: dataLen,
      pointWidth: pointWidth <= maxPointWidth ? pointWidth : maxPointWidth
    };
  }
};

export const getNumericDataInfo = (
  strNumValue: string,
  disableAbbreviations: boolean,
  options?: {
    currencyType: CurrencyType;
    durationType?: string;
    withSymbol?: boolean;
  }
): NumericDataInfo => {
  const isCurrency = Boolean(options?.currencyType);
  const isDuration = !!options?.durationType;

  if (isCurrency) {
    return getCurrencyInfo(strNumValue, options.currencyType, disableAbbreviations, options?.withSymbol);
  }

  if (isDuration) {
    return getDurationInfo(strNumValue, disableAbbreviations);
  }

  return getNumberInfo(strNumValue, disableAbbreviations);
};
