import React, { useCallback, useEffect, useState } from "react";
import { isEqual } from "lodash";
import AnalysisOperationalize from "../../anaysis-operationalize/AnalysisOperationalize";
import { setOpAnalysisConfig, setShouldReloadEditors, useOpStore } from "../../context";
import { OpAnalysisCreationConfig } from "../../../services/api/operationalise";

const OpAnalysisEditorWrapper = () => {
  const { state, dispatch } = useOpStore();
  const { opCreationConfig, context, shouldReloadEditors } = state;
  const { eventTypeId } = context;
  const { opAnalysisConfig } = opCreationConfig;
  const [defAnalysisConfig, setDefAnalysisConfig] = useState<OpAnalysisCreationConfig>(null);

  useEffect(() => {
    if (shouldReloadEditors) {
      dispatch(setShouldReloadEditors(false));
      setDefAnalysisConfig(prev => {
        if (isEqual(prev, opAnalysisConfig)) {
          return prev;
        }
        return opAnalysisConfig;
      });
    } else {
      setDefAnalysisConfig(prev => prev || opAnalysisConfig);
    }
  }, [dispatch, opAnalysisConfig, shouldReloadEditors]);

  const onChangeConfig = useCallback(
    (config: OpAnalysisCreationConfig) => {
      dispatch(setOpAnalysisConfig(config));
    },
    [dispatch]
  );

  if (!defAnalysisConfig) {
    return <></>;
  }

  return (
    <AnalysisOperationalize
      eventTypeId={eventTypeId}
      hideConfigView
      hideDryRun
      hideTitle
      onStateChange={onChangeConfig}
      opAnalysisCreationConfig={defAnalysisConfig}
    />
  );
};

export default OpAnalysisEditorWrapper;
