import { IncButton, IncModal, IncToggle } from "@inception/ui";
import React, { FC, useCallback, useMemo } from "react";
import { ConversationStatus } from "../../services/api/chat";
import { LowLevelFragment } from "../../services/api/chat/types/low-level-fragment";
import { DemoDataParams } from "../../services/api/explore";
import { useLocalStorage, useToggleState } from "../../core";
import { VerticallyCenteredRow } from "../flex-components";
import { magicButtonIcon } from "../../recipe/plan-generation/PlanEditor";
import { FeebackPanel } from "../../chat-bot/components";
import { FragmentRendererV2 } from "./FragmentRendererV2";
import { IntermediateFragmentListRenderer } from "./IntermediateFragmentListRenderer";

interface InitialFragmentRendererProps {
  lowLevelFragment: LowLevelFragment;
  status: ConversationStatus;
  messageId: string;
  title: string;
  demoDataParams?: DemoDataParams;
  hideFoooterActions?: boolean;
  useParentWidth?: boolean;
}

const chatStatusViewKey = "CHAT_STATUS_VIEW";

export const InitialFragmentRenderer: FC<InitialFragmentRendererProps> = (props: InitialFragmentRendererProps) => {
  const {
    lowLevelFragment,
    demoDataParams,
    status,
    messageId,
    title,
    hideFoooterActions = false,
    useParentWidth = false
  } = props;
  const isLoading = status === ConversationStatus.ONGOING;
  const { getItem, setItem } = useLocalStorage();

  const inititalChatView = useMemo(() => getItem(chatStatusViewKey) === "true", [getItem]);
  const { isOpen: isExpanded, toggle: toggleExpand } = useToggleState(inititalChatView);
  const { isOpen, toggle, close } = useToggleState(false);

  const toggleExpandWrapper = useCallback(() => {
    toggleExpand();
    setItem(chatStatusViewKey, String(!isExpanded));
  }, [isExpanded, setItem, toggleExpand]);

  const ongoingView = useMemo(() => {
    const intermediateFragment = lowLevelFragment?.lowLevelFragmentList?.lowLevelFragment?.[0];
    return (
      <IntermediateFragmentListRenderer
        demoDataParams={demoDataParams}
        fragment={intermediateFragment}
        isExpanded={isLoading ? isExpanded : true}
        status={status}
        useParentWidth={useParentWidth}
      />
    );
  }, [lowLevelFragment, demoDataParams, isLoading, isExpanded, status, useParentWidth]);

  const completedView = useMemo(() => {
    const resultFragment = lowLevelFragment?.lowLevelFragmentList?.lowLevelFragment?.[1] || lowLevelFragment;

    return (
      <FragmentRendererV2
        demoDataParams={demoDataParams}
        status={status}
        subStep={resultFragment}
        useParentWidth={useParentWidth}
      />
    );
  }, [demoDataParams, lowLevelFragment, status, useParentWidth]);

  const showHeaderBar = isLoading ? true : Boolean(title);

  return (
    <div
      className="low-level-fragment inc-flex-column"
      data-loading={isLoading}
    >
      {showHeaderBar && (
        <VerticallyCenteredRow className={`flex-gap-16 padding16 ${isLoading ? "border-bottom-generic" : "p-b-0"}`}>
          {title && <div className="inc-text-lead-semibold">{title}</div>}
          {isLoading && (
            <IncToggle
              checked={isExpanded}
              className="marginLtAuto"
              label="Show details"
              onChange={toggleExpandWrapper}
            />
          )}
        </VerticallyCenteredRow>
      )}
      <div className="padding16">
        {isLoading ? ongoingView : completedView}
        {!isLoading && !hideFoooterActions && (
          <VerticallyCenteredRow className="marginTpAuto">
            <FeebackPanel messageId={messageId} />
            <IncButton
              className="marginLtAuto"
              color="link"
              onClick={toggle}
            >
              {magicButtonIcon}
              <span className="marginLt4">Explore AI’s Reasoning</span>
            </IncButton>
          </VerticallyCenteredRow>
        )}
        {isOpen && (
          <IncModal
            className="explore-reason-modal"
            onClose={close}
            show={isOpen}
            size="side-pane"
            titleText={"Explore AI’s Reasoning"}
            withTitleBorder
          >
            <div className="paddingTp16">{ongoingView}</div>
          </IncModal>
        )}
      </div>
    </div>
  );
};
