import { merge, range, isNumber, defaultsDeep } from "lodash";
import Highcharts, {
  XAxisOptions,
  SeriesColumnOptions,
  SeriesBarOptions,
  DataLabelsFormatterCallbackFunction,
  YAxisOptions,
  TooltipOptions,
  TooltipFormatterContextObject,
  Options,
  AxisLabelsFormatterContextObject
} from "highcharts";
import { formatNumber, getInceptionTheme } from "@inception/ui";
import addRegionHighlights, { RegionHighlight } from "./plot-bands/RegionHighlights";
import BaseOptionsBuilder, { FONT_STYLE, tickColor } from "./BaseOptionsBuilder";

const X_AXIS_ID = "X-Axis";

export class BaseOptionsBuilderWithAxes extends BaseOptionsBuilder {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(options: Options) {
    super(options);
  }

  setXAxis(
    hideAxis = false,
    title = "",
    hideLabels = false,
    xAxisOffset = 0,
    getLabelValue?: (ctx: AxisLabelsFormatterContextObject) => string
  ) {
    const xAxisOptions = merge(this.chartOptions.xAxis || {}, {
      tickmarkPlacement: "on",
      grid: {
        enabled: false
      },
      offset: xAxisOffset,
      min: 0,
      title: {
        text: title,
        style: {
          color: getInceptionTheme().plugins.graph.legendTextHover,
          marginTop: 16
        },
        useHTML: true
      },
      labels: {
        align: "center",
        formatter: function () {
          const { value, chart } = this;
          const formattedLabel = getLabelValue ? getLabelValue(this) : value;

          const maxLabelWidth = Math.max(chart.plotWidth / 5, 75);
          return `<div class="inc-highcharts-xaxis-label" title="${value}" style="max-width: ${maxLabelWidth}px; max-height: 50px;">
            ${formattedLabel}
          </div>`;
        },
        useHTML: true,
        enabled: !hideLabels,
        autoRotationLimit: 0
      },
      visible: !hideAxis,
      crosshair: true,
      minorTickLength: 0,
      tickLength: 0,
      lineColor: `#C3D1DF`,
      lineWidth: hideLabels ? 3 : 1,
      id: X_AXIS_ID
    } as XAxisOptions);

    this.chartOptions.xAxis = xAxisOptions;
    return this;
  }

  setYAxis(
    maxValue: number,
    yAxisOptions: YAxisOptions[],
    yAxisTitle = "",
    allowDecimals = true,
    getLabelValue?: (ctx: AxisLabelsFormatterContextObject) => string
  ) {
    const defYAxis: YAxisOptions[] = [
      {
        id: "yAxis1",
        endOnTick: true,
        gridLineWidth: 1,
        gridLineColor: getInceptionTheme().inceptionColors.palette.BL400,
        visible: false,
        minTickInterval: 0.25,
        tickPositioner: function () {
          const { tickPositions } = this;
          let nTickPositions = [...tickPositions];
          // In only 0 case, we want to atleast 4 ticks
          if (nTickPositions.length === 1) {
            nTickPositions = range(0, 5).map(n => n * 0.25);
          }
          return nTickPositions;
        },
        tickColor,
        allowDecimals,
        title: {
          text: yAxisTitle,
          style: {
            color: getInceptionTheme().plugins.graph.legendTextHover,
            marginTop: 16,
            marginBottom: 16
          },
          useHTML: true
        },
        tickLength: 0,
        maxRange: maxValue,
        opposite: false,
        labels: {
          enabled: true,
          style: FONT_STYLE,
          formatter: function (this) {
            if (getLabelValue) {
              return getLabelValue(this);
            }

            if (isNumber(this.value)) {
              return formatNumber(this.value, false, true);
            }
            return this.value;
          }
        }
      }
    ];

    this.chartOptions.yAxis = merge(defYAxis, yAxisOptions);
    return this;
  }

  setPlotBands(regionHighlights: RegionHighlight[]) {
    addRegionHighlights(this.chartOptions, regionHighlights);
    return this;
  }

  setSeriesData(
    series: SeriesColumnOptions[] | SeriesBarOptions[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars-ts
    labelPrecision?: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars-ts
    formatter?: DataLabelsFormatterCallbackFunction
  ) {
    this.chartOptions.series = series;
    return this;
  }

  setToolTip(
    showAxisName?: boolean,
    hideSeriesName?: boolean,
    tooltipValueFormatter?: (value: string | number, custom?: Record<string, unknown>) => string
  ): BaseOptionsBuilderWithAxes {
    const tooltipOptions: TooltipOptions = {
      enabled: true,
      shared: true,
      followPointer: true,
      outside: true,
      useHTML: true,
      formatter: function () {
        if (!this.points) {
          return "";
        }

        const getInfoText = (pt: TooltipFormatterContextObject) =>
          hideSeriesName ? pt.x : `${pt.series.name} ${showAxisName ? ` (${pt.x})` : ""}`;

        let tooltipText = `<div class='inc-charts-tooltip'>`;
        this.points.forEach((pt, i) => {
          const { y, series } = pt;
          const customSerieData = (series as any)?.options?.custom || {};
          const formattedValue = tooltipValueFormatter ? tooltipValueFormatter(y, customSerieData) : formatNumber(y);
          tooltipText += `<div class='inc-charts-tooltip-series-row' >
            <div class="inc-highcharts-square-symbol" style="background-color:${this.points[i].color}; opacity: ${(series as any).opacity - 0.3}"></div>
              <div class='inc-charts-tooltip-series-name'>
                ${getInfoText(pt)}
              </div> 
                <div class='inc-charts-tooltip-series-value'> <span>${formattedValue}</span></div >
                  </div>`;
        });

        tooltipText += "</div>";
        return tooltipText;
      }
    };
    defaultsDeep(this.chartOptions.tooltip, tooltipOptions);
    return this;
  }

  build(): Highcharts.Options {
    return {
      ...this.chartOptions
    };
  }
}

export default BaseOptionsBuilderWithAxes;
