import React, { useRef } from "react";
import { IncFaIcon, IncFaIconName, IncPopper, IncClickAway } from "@inception/ui";
import { IncNodeTemplate, IncNodeToolTemplate } from "./Templates";
import { NodeDataProps, WorkflowNodeProps, ActionProps, ActionPositions } from "./types";
import { IncWorkflowNode } from "./Node";

/**
 *
 * @param props
 * @returns
 */
export const IncEntityNodeSeparator: React.FC<NodeDataProps> = props => {
  const { icon } = props;
  return (
    <div className="d-flex flex-column separator-container align-items-top align-items-center justify-content-center">
      <div className="separator-line"></div>
      <div className="separator-block flex-row d-flex align-items-top justify-content-center">
        {icon && (
          <span className="icon d-inline">
            <IncFaIcon iconName={icon as IncFaIconName} />{" "}
          </span>
        )}
      </div>
    </div>
  );
};

/**
 *
 * @param props
 * @returns
 */
export const IncEntityNodeTemplate: React.FC<WorkflowNodeProps> = props => {
  const { data } = props;

  const { actions, numActivities } = data || {};

  const style = numActivities
    ? {
        height: `${2 * 80 + (numActivities + 2) * 80}px`
      }
    : null;

  const [entityEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(entityEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const entityRef = useRef<HTMLDivElement>();

  const actionsExist = Boolean(actions?.length);

  return (
    <div className="content">
      <div className="d-flex flex-row align-items-top justify-content-center">
        <IncNodeTemplate {...props} />
      </div>

      <div
        className="d-flex flex-row align-items-center justify-content-center"
        style={{ pointerEvents: "none" }}
      >
        <div
          className="d-flex flex-column separator-container align-items-top align-items-center justify-content-center position-relative"
          style={style}
        >
          <div className="separator-line"></div>
          <div
            className="separator-block flex-row d-flex align-items-top justify-content-center"
            onClick={handleClick}
            ref={entityRef}
          >
            <IncClickAway onClickAway={handleClose}>
              {(ref: any) => (
                <IncPopper
                  anchorEl={entityRef as any}
                  offset={{
                    x: 60,
                    y: -80
                  }}
                  placement="top"
                  ref={ref}
                  show={open}
                >
                  <div className="actions">
                    {actionsExist && (
                      <ul className="dropdown-menu bg-dark d-block position-static mx-0 border-0 shadow w-220px">
                        {actions.map((action: ActionProps) => {
                          const { position, data } = action;

                          if (position === ActionPositions.SEPARATOR) {
                            const { label } = data;
                            const key = `${label}_act`;

                            return (
                              <li key={key}>
                                <IncNodeToolTemplate
                                  actionClass="text-light bg-dark dropdown-item d-flex gap-2 align-items-center"
                                  showTitle={true}
                                  {...action}
                                />
                              </li>
                            );
                          }

                          return null;
                        })}
                      </ul>
                    )}
                  </div>
                </IncPopper>
              )}
            </IncClickAway>

            {actionsExist && (
              <div className="d-flex toolbar ">
                <div className="actions">
                  {actions?.map((action: ActionProps) => {
                    const { position, data } = action;

                    if (position === ActionPositions.SEPARATOR) {
                      const { label } = data;
                      const key = `${label}_action`;

                      return (
                        <IncNodeToolTemplate
                          key={key}
                          {...action}
                        />
                      );
                    }

                    return null;
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex flex-row align-items-bottom justify-content-center">
        <IncNodeTemplate {...props} />
      </div>
    </div>
  );
};
/**
 *
 * @param props
 * @returns
 */
export const IncEntityNode: React.FC<WorkflowNodeProps> = props => {
  const template = <IncEntityNodeTemplate {...props} />;

  return (
    <IncWorkflowNode
      content={template}
      {...props}
    />
  );
};
