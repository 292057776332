import React, { FC, useMemo, useEffect, useState, useCallback } from "react";
import { IncToolTip, IncFaIcon, IncMenu, IncMenuItemProps, IncMenuToggleProps } from "@inception/ui";
import { forEach } from "lodash";
import { cx } from "emotion";
import { BaseEventNavigation, WidgetQuerySchema, WidgetResponseDTO } from "../services/api/explore";
import { TimeRange, useInceptionRoute } from "../core";
import { routePaths } from "../../app/RoutePaths";
import { AnchorLink } from "./content/Links";

interface Props {
  timeRange: TimeRange;
  widgetResponse?: WidgetResponseDTO;
  showLabel?: boolean;
  renderAsLink?: boolean;
}

interface ChildMetricTraceLink {
  link: string;
  label: string;
}

export const EventNavigateRenderer: FC<Props> = props => {
  const { timeRange, showLabel = false, renderAsLink = false, widgetResponse } = props;

  const {
    raw: { from, to }
  } = timeRange;

  const { navigate } = useInceptionRoute();
  const [eventsLink, setEventsLink] = useState<string>("");
  const [childMetricsLinks, setChildMetricsLinks] = useState<Record<string, ChildMetricTraceLink>>({});

  const setLink = useCallback(
    (keys: string[], componentNavigation: Record<string, BaseEventNavigation>) => {
      const paramsStr = componentNavigation[keys[0]].eventFilter || "";
      const url = paramsStr ? `${routePaths.traces}?query=${paramsStr}&mode=events&from=${from}&to=${to}` : "";
      setEventsLink(url);
    },
    [from, to]
  );

  const setChildLinks = useCallback(
    (keys: string[], componentNavigation: Record<string, BaseEventNavigation>) => {
      const links: Record<string, ChildMetricTraceLink> = {};
      for (let index = 0; index < keys.length; index++) {
        const element = keys[index];
        const paramsStr = componentNavigation[element].eventFilter || "";
        const allMetrics = widgetResponse.widgetConfig.dataDefinition.metrics;
        links[element] = {
          link: paramsStr ? `${routePaths.traces}?query=${paramsStr}&mode=events&from=${from}&to=${to}` : "",
          label: allMetrics[element].name
        };
      }
      setChildMetricsLinks(links);
    },
    [from, to, widgetResponse.widgetConfig.dataDefinition.metrics]
  );

  useEffect(() => {
    const querySchemas = widgetResponse?.querySchema?.querySchema;
    const componentNavigation = generateNavigations(querySchemas);
    const keys = Object.keys(componentNavigation);
    if (keys.length > 1) {
      setChildLinks(keys, componentNavigation);
    } else {
      if (keys.length) {
        setLink(keys, componentNavigation);
      }
    }
  }, [widgetResponse, from, to, setLink, setChildLinks]);

  const navigateToEvents = useCallback(
    (link: string) => {
      navigate(link, { newTab: true });
    },
    [navigate]
  );

  const link = useMemo(
    () => (
      <>
        <AnchorLink
          className="inc-flex-row inc-flex-center-vertical"
          newWindow
          rel="noopener noreferrer"
          to={eventsLink}
        >
          <IncFaIcon iconName="list-ul" />
          {showLabel && <div className="marginLt6 inc-action-label">View events</div>}
        </AnchorLink>
      </>
    ),
    [eventsLink, showLabel]
  );

  const nonLink = useMemo(
    () => (
      <>
        <div onClick={() => navigateToEvents(eventsLink)}>
          <IncFaIcon iconName="list-ul" />
          {showLabel && <div className="marginLt6 inc-action-label">View events</div>}
        </div>
      </>
    ),
    [eventsLink, navigateToEvents, showLabel]
  );

  const menuItems = useCallback(
    (itemProps: IncMenuItemProps) => {
      const { closeMenu, className } = itemProps;
      const items: JSX.Element[] = [];
      const onClick = (e: React.MouseEvent, link: string) => {
        navigateToEvents(link);
        closeMenu(e as any);
      };

      forEach(childMetricsLinks, childMetric => {
        const { label, link } = childMetric;
        const jsx = (
          <div
            className={className}
            key={link}
            onClick={e => onClick(e, link)}
          >
            <span className="list-item">{label}</span>
          </div>
        );
        items.push(jsx);
      });
      return items;
    },
    [childMetricsLinks, navigateToEvents]
  );

  const toggle = (toggleProps: IncMenuToggleProps) => {
    const { show, toggleMenu } = toggleProps;
    const className = cx("flex inc-flex-center-vertical", {
      "link-icon": renderAsLink
    });

    return (
      <div
        className={className}
        onClick={() => {
          toggleMenu(!show);
        }}
      >
        <IncToolTip
          placement="top"
          titleText="View events"
        >
          <>
            <IncFaIcon iconName="list-ul" />
            {showLabel && <div className="marginLt6 inc-action-label">View events</div>}
          </>
        </IncToolTip>
      </div>
    );
  };

  return (
    <div className="event-navigate-renderer">
      {eventsLink || childMetricsLinks ? (
        eventsLink ? (
          <IncToolTip
            placement="top"
            titleText="View events"
          >
            <div className="inc-flex-row inc-flex-center-vertical">{renderAsLink ? link : nonLink}</div>
          </IncToolTip>
        ) : (
          <IncMenu
            dropType="left"
            items={menuItems}
            offset={{
              x: 20,
              y: -20
            }}
            toggle={toggle}
          />
        )
      ) : (
        <></>
      )}
    </div>
  );
};

const generateNavigations = (querySchemas: WidgetQuerySchema[]) => {
  let componentNavigation: Record<string, BaseEventNavigation> = {};
  if (querySchemas) {
    for (let index = 0; index < querySchemas.length; index++) {
      const element = querySchemas[index];
      if (element.componentsEventNavigation && Object.keys(element.componentsEventNavigation).length) {
        componentNavigation = { ...element.componentsEventNavigation };
      } else {
        if (element.baseEventNavigation) {
          componentNavigation = {
            ...componentNavigation,
            ...{ [element.metricId]: element.baseEventNavigation }
          };
        }
      }
    }
  }
  return componentNavigation;
};
