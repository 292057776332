import { IncPill, IncToolTip } from "@inception/ui";
import { uniq, uniqBy } from "lodash";
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  CohortBizFieldPicker,
  CohortPicker,
  EventCriteriaPicker,
  InfoPopper,
  LoadingSpinner,
  VerticallyCenteredRow
} from "../../../components";
import { logger, useTenantConfig, useToggleState } from "../../../core";
import {
  BizFieldPredicate,
  exploreApiService,
  ExploreEntityFilter,
  FieldPickerContextDTO,
  LogicalOperator,
  MetricUserServiceFilters,
  ResultCohort,
  ResultCohortStateResponse,
  UserServiceFilterExpression,
  UserServiceFilterExpressionTree,
  WidgetConfigDTO,
  WidgetConfigUtils
} from "../../../services/api/explore";
import {
  fetchEntityPropertiesForIds,
  FieldPickerUtils,
  getFilterLabelsFromEntityFilters,
  isValidCohortId,
  Options,
  pluralizeWord
} from "../../../utils";
import { getDtoFromWidgetConfig } from "../../../utils/ExploreUtils";
import { resetCohortConfig, setCohortConfig, updateEntityFilters, updateEventFilters, useOpStore } from "../../context";
import { FEATURE_FLAGS, featureFlagService } from "../../../services/feature-flags";

type Props = {
  saveOrUpdateInProgress: boolean;
  setSaveOrUpdateInProgress: (inProgress: boolean) => void;
};

export const OverviewRendererV3: FC<Props> = memo(() => {
  const { dispatch, state } = useOpStore();
  const { context, opCreationConfig, readOnly } = state;

  const entityFilters = useMemo(
    () => opCreationConfig?.opCreationConfigDef?.bizDataQuery?.entityFilters || [],
    [opCreationConfig]
  );
  const bizFieldPredicates = useMemo(() => entityFilters?.[0]?.filters || [], [entityFilters]);

  const eventFilters = useMemo(
    () => opCreationConfig?.opCreationConfigDef?.bizDataQuery?.metricUserServiceFilters || {},
    [opCreationConfig]
  );

  const bizDataQuery = opCreationConfig?.opCreationConfigDef?.bizDataQuery;

  const { eventTypeId: wEventTypeId, entityTypeId: wEntityTypeId } =
    WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(bizDataQuery?.idProps);
  const [widgetConfig, setWidgetConfig] = useState<WidgetConfigDTO>();

  const { id: widgetId, widgetConfig: bdqWidgetConfig } = bizDataQuery || {};
  const isOpWidgetBased = Boolean(widgetId || bdqWidgetConfig);
  const {
    isOpen: isWidgetContextLoading,
    open: startWidgetContextLoad,
    close: finishWidgetContextLoad
  } = useToggleState(isOpWidgetBased);

  useEffect(() => {
    if (isOpWidgetBased) {
      startWidgetContextLoad();
      if (widgetId) {
        exploreApiService
          .getWidgetConfig(wEventTypeId, wEntityTypeId, widgetId)
          .then(response => {
            const { data, error, message } = response;

            if (error) {
              throw new Error(message);
            } else {
              setWidgetConfig(data.widgetConfig);
            }
          })
          .catch(error => {
            logger.error("OverviewRendererV3", "Error fetching widgetConfig", {
              error,
              widgetId,
              entityTypeId: wEntityTypeId,
              eventTypeId: wEventTypeId
            });
          })
          .finally(() => {
            finishWidgetContextLoad();
          });
      } else {
        setWidgetConfig(getDtoFromWidgetConfig(bdqWidgetConfig));
        finishWidgetContextLoad();
      }
    }
  }, [
    bdqWidgetConfig,
    finishWidgetContextLoad,
    isOpWidgetBased,
    startWidgetContextLoad,
    wEntityTypeId,
    wEventTypeId,
    widgetId
  ]);

  const sliceSpec = bizDataQuery?.sliceSpec;
  const metricId = sliceSpec?.metricId || sliceSpec?.buildingBlockConfigId;
  const metrics = widgetConfig?.dataDefinition?.metrics;
  const metricIds = useMemo(() => {
    const metricIds = Object.keys(eventFilters);
    if (!metricIds.length) {
      metricIds.push(metricId);
    }

    const finalMetricIds = [...metricIds];
    metricIds.forEach(metricId => {
      const metricDef = metrics?.[metricId];
      if (metricDef?.sourceType === "expression") {
        const sliceSpecs = WidgetConfigUtils.extractSliceSpecsFromExpressionMetric(metricDef.expressionMetricConfig);
        const allMetricIds = sliceSpecs.map(x => x.metricId).filter(Boolean);
        finalMetricIds.push(...allMetricIds);
      }
    });

    return uniq(finalMetricIds);
  }, [eventFilters, metricId, metrics]);

  const eventFilterList = useMemo(() => {
    const eFilters: UserServiceFilterExpression[] = [];
    Object.values(eventFilters || {}).forEach(ef => {
      ef.userServiceFilters?.forEach(x => {
        eFilters.push(...(x?.userServiceFilterExpressions || []));
      });
    });
    return uniqBy(
      eFilters,
      filter =>
        `${FieldPickerUtils.getUserServiceFieldLabel(filter.field)}-${filter.operator}-${filter.value ? filter.value : filter.values.join(",")}`
    );
  }, [eventFilters]);

  const eventExpressionTrees = useMemo(() => {
    const eventExpressionTrees: UserServiceFilterExpressionTree[] = [];
    Object.values(eventFilters || {}).forEach(ef => {
      if (ef.expressionTree) {
        eventExpressionTrees.push(ef.expressionTree);
      }
    });
    return uniqBy(eventExpressionTrees, exprTree => WidgetConfigUtils.getUsFilterExpressionTreeLabel(exprTree));
  }, [eventFilters]);

  const shouldUseExpressionTree =
    eventFilterList?.length === 0 && featureFlagService.isFeatureEnabled(FEATURE_FLAGS.useComplexExpressions);

  const eventExpressionTree = useMemo(() => {
    if (eventExpressionTrees.length) {
      return eventExpressionTrees[0];
    }

    if (shouldUseExpressionTree) {
      return {
        filterNodes: [],
        logicalOperator: LogicalOperator.AND
      };
    }

    return null;
  }, [eventExpressionTrees, shouldUseExpressionTree]);

  const { cohortDefinition, cohortState: eCohortState, entityTypeInfo, eventTypeInfo, bizEntityFieldName } = context;

  const [cohortState, setCohortState] = useState<ResultCohortStateResponse>(null);
  const [fetchingCohortState, setFetchingCohortState] = useState(false);
  const { tenantConfigState } = useTenantConfig();
  const [entityLookUp, setEntityLookUp] = useState<Record<string, string>>({});
  const [isFetchingLookUp, setIsFetchingLookUp] = useState(false);

  const options: Options = useMemo(() => ({ currency: tenantConfigState?.currency || "USD" }), [tenantConfigState]);

  const { entityTypeId, name: entityTypeName } = entityTypeInfo || {};

  const { name: eventTypeName, entityId: eventTypeId } = eventTypeInfo || {};

  const fetchCohortState = useCallback(async () => {
    if (cohortDefinition?.cohortId) {
      setFetchingCohortState(true);
      const cohortState = await exploreApiService.getCohortState(cohortDefinition.cohortId, entityTypeId);
      setCohortState(cohortState);
      setFetchingCohortState(false);
    }
  }, [cohortDefinition, entityTypeId]);

  useEffect(() => {
    if (!eCohortState) {
      fetchCohortState();
    } else {
      setCohortState(eCohortState);
    }
  }, [fetchCohortState, eCohortState]);

  const { membersCount, totalCount } = cohortState || {};

  const cohortId = isValidCohortId(cohortDefinition?.cohortId) ? cohortDefinition.cohortId : "";

  const memberCountInfo = useMemo(() => {
    if (totalCount) {
      return (
        <VerticallyCenteredRow className="cohort-status inc-text-subtext-bold">
          <div className="marginRt4">{membersCount}</div>
          <div
            className="inc-text-inactive"
            style={{ textWrap: "nowrap" } as any}
          >
            / {totalCount}
          </div>
        </VerticallyCenteredRow>
      );
    }
    return null;
  }, [totalCount, membersCount]);

  const isOpConfigDisabled = readOnly;

  const onChangeEntityFilters = useCallback(
    (bizFieldPredicates: BizFieldPredicate[]) => {
      const entityFilters: ExploreEntityFilter[] = [
        {
          filters: bizFieldPredicates
        }
      ];
      dispatch(updateEntityFilters(entityFilters));
    },
    [dispatch]
  );

  const onChangeEventFilters = useCallback(
    (userServiceFilterExprs: UserServiceFilterExpression[], expressionTree: UserServiceFilterExpressionTree) => {
      const metricUserServiceFilters: MetricUserServiceFilters = {};
      metricIds.forEach(metricId => {
        metricUserServiceFilters[metricId] = shouldUseExpressionTree
          ? {
              expressionTree
            }
          : {
              userServiceFilters: [
                {
                  userServiceFilterExpressions: userServiceFilterExprs
                }
              ]
            };
      });
      dispatch(updateEventFilters(metricUserServiceFilters));
    },
    [dispatch, metricIds, shouldUseExpressionTree]
  );

  useEffect(() => {
    const fetchEntityLookup = async (entityIds: string[]) => {
      setIsFetchingLookUp(true);
      const entityPropertiesMap = await fetchEntityPropertiesForIds(new Set(entityIds), 0, Date.now());
      const entityLookUp: Record<string, string> = {};

      entityPropertiesMap.forEach((value, key) => {
        entityLookUp[key] = value.name;
      });
      setEntityLookUp(entityLookUp);
      setIsFetchingLookUp(false);
    };
    if (eventExpressionTrees?.length) {
      const entityIds = eventExpressionTrees.reduce(
        (a: string[], b) => [...a, ...WidgetConfigUtils.getEntityIdsFromFilterTree(b)],
        []
      );
      if (entityIds.length > 0) {
        fetchEntityLookup(entityIds);
      }
    } else {
      const entityIds = WidgetConfigUtils.getEntityIdsFromFilterExpressions(eventFilterList);
      if (entityIds.length > 0) {
        fetchEntityLookup(entityIds);
      }
    }
  }, [eventExpressionTrees, eventFilterList]);

  const entityFilterPills = useMemo(() => {
    let filters: BizFieldPredicate[] = [];
    entityFilters?.forEach(x => {
      filters = filters.concat(x.filters);
    });
    if (filters.length > 0) {
      const entityFilterLabels = getFilterLabelsFromEntityFilters(filters, options);
      return (
        <VerticallyCenteredRow className="marginTp8">
          {entityFilterLabels.map((label, idx) => (
            <IncPill
              key={idx}
              label={label}
            />
          ))}
        </VerticallyCenteredRow>
      );
    }
    return null;
  }, [entityFilters, options]);

  const eventFilterPills = useMemo(() => {
    if (isFetchingLookUp) {
      return <LoadingSpinner titleText="Fetching..." />;
    } else if (eventFilterList.length > 0 && !isFetchingLookUp) {
      const eventFilterLabels = eventFilterList.map(expr =>
        WidgetConfigUtils.getUsFilterExpressionLabel(expr, entityLookUp)
      );
      const displayLabels = eventFilterLabels.splice(0, 3);
      const diff = eventFilterLabels.length;

      const pills = displayLabels.map((label, idx) => (
        <IncPill
          className="marginLt4 marginRt4"
          key={idx}
          label={label}
        />
      ));

      let hoverElement = null;
      if (eventFilterLabels.length > 0) {
        hoverElement = InfoPopper(eventFilterLabels.map(x => x));
      }

      return (
        <div className="inc-flex-row inc-flex-center-vertical">
          {pills}
          {hoverElement && (
            <IncToolTip
              placement="bottom"
              titleElement={hoverElement}
            >
              <span className="inc-text-subtext inc-link marginTp4">{`+${diff}`}</span>
            </IncToolTip>
          )}
        </div>
      );
    } else if (eventExpressionTrees.length > 0 && !isFetchingLookUp) {
      const eventFilterLabels = eventExpressionTrees.map(exprTree =>
        WidgetConfigUtils.getUsFilterExpressionTreeLabel(exprTree, entityLookUp)
      );
      const displayLabels = eventFilterLabels.splice(0, 3);
      const diff = eventFilterLabels.length;

      const pills = displayLabels.map((label, idx) => (
        <IncPill
          className="marginLt4 marginRt4"
          key={idx}
          label={label}
        />
      ));

      let hoverElement = null;
      if (eventFilterLabels.length > 0) {
        hoverElement = InfoPopper(eventFilterLabels.map(x => x));
      }

      return (
        <div className="inc-flex-row inc-flex-center-vertical">
          {pills}
          {hoverElement && (
            <IncToolTip
              placement="bottom"
              titleElement={hoverElement}
            >
              <span className="inc-text-subtext inc-link marginTp4">{`+${diff}`}</span>
            </IncToolTip>
          )}
        </div>
      );
    }

    return null;
  }, [entityLookUp, eventExpressionTrees, eventFilterList, isFetchingLookUp]);

  const onCohortChange = useCallback(
    (cohort: ResultCohort) => {
      if (cohort) {
        dispatch(setCohortConfig(cohort.cohortDefinition.cohortDefinition));
      } else {
        dispatch(resetCohortConfig());
      }
    },
    [dispatch]
  );

  const fieldPickerContext: FieldPickerContextDTO = useMemo(
    () => ({
      entityId: eventTypeId,
      entityType: null,
      entityName: "",
      userServiceToBizEntityFieldName: eventTypeId && bizEntityFieldName ? { [eventTypeId]: bizEntityFieldName } : {}
    }),
    [bizEntityFieldName, eventTypeId]
  );

  return (
    <>
      <VerticallyCenteredRow className="overview-renderer-v3">
        {Boolean(entityTypeName) && (
          <div
            className="entity-overview"
            style={{ width: eventTypeName ? "45%" : "100%" }}
          >
            <div className="inc-label-common">Entity</div>
            <VerticallyCenteredRow>
              <VerticallyCenteredRow className="marginRt12">{pluralizeWord(entityTypeName)}</VerticallyCenteredRow>

              <VerticallyCenteredRow className="marginRt16">
                {fetchingCohortState && <LoadingSpinner titleText=" " />}
                {!fetchingCohortState && memberCountInfo}
              </VerticallyCenteredRow>

              <VerticallyCenteredRow>
                <CohortPicker
                  cohortState="saved"
                  containerClass="cohort-list-container marginRt12"
                  disabled={isOpConfigDisabled}
                  entityTypeId={entityTypeId}
                  onCohortChange={onCohortChange}
                  value={cohortId}
                />

                {isOpConfigDisabled ? (
                  Boolean(entityFilterPills) && entityFilterPills
                ) : (
                  <CohortBizFieldPicker
                    bizFieldPredicates={bizFieldPredicates}
                    className="entity-criteria-picker marginBt12"
                    cohortId={cohortId}
                    entityTypeId={entityTypeId}
                    onChange={onChangeEntityFilters}
                    pillLimit={2}
                  />
                )}
              </VerticallyCenteredRow>
            </VerticallyCenteredRow>
          </div>
        )}

        {Boolean(entityTypeName) && Boolean(eventTypeName) && <div className="vertical-separator" />}

        {Boolean(eventTypeName) && (
          <div
            className="event-overview"
            style={{ width: entityTypeName ? "45%" : "100%" }}
          >
            <div className="inc-label-common">Event</div>
            <VerticallyCenteredRow>
              <VerticallyCenteredRow className="marginRt12">{eventTypeName}</VerticallyCenteredRow>

              {isOpConfigDisabled && eventFilterPills}

              {!isOpConfigDisabled && (
                <EventCriteriaPicker
                  className="event-criteria-picker marginBt12"
                  disabled={isWidgetContextLoading}
                  fieldPickerContext={fieldPickerContext}
                  filterExpressionTree={eventExpressionTree}
                  filters={eventFilterList}
                  onChange={onChangeEventFilters}
                  pillLimit={2}
                  useExpressionTree={shouldUseExpressionTree}
                />
              )}
            </VerticallyCenteredRow>
          </div>
        )}
      </VerticallyCenteredRow>
    </>
  );
});
