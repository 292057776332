import React, { FC, useMemo } from "react";
import { IncSmartText } from "@inception/ui";
import { BarChartRenderer } from "../BarChartRenderer";
import { CatalogVizRendererProps, useFetchFieldNameMetricNames } from "../common";
import { getWidgetTitleBasedOnTimeRange } from "./topNUtils";

const className = "topn-bar-chart";
const BarChartWrapper: FC<CatalogVizRendererProps> = props => {
  const { timeRange, aggregator, eventTypeName, queryConfig } = props;
  const { metricName } = useFetchFieldNameMetricNames(aggregator, queryConfig, eventTypeName);
  const title = useMemo(() => getWidgetTitleBasedOnTimeRange(timeRange, metricName, "bar"), [timeRange, metricName]);

  return (
    <div className="bar-chart-wrapper">
      <div className="bar-chart-container">
        <IncSmartText
          className="inc-text-color-secondary"
          text={title}
        />
        <div
          className={className}
          style={{ height: "100px" }}
        >
          <BarChartRenderer
            {...props}
            className={className}
            showAllDataPoints
          />
        </div>
      </div>
    </div>
  );
};

export default BarChartWrapper;
