import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IncCheckboxProps, IncRadioGroup, IncSelect, IncSelectOption } from "@inception/ui";
import { omit } from "lodash";
import { CypressConstants } from "@bicycle/tests";
import slackConfigData from "../dataset/slack.json";
import { CheckBoxList } from "../../checkbox-list";
import { SlackSubscriptionDataTemplate } from "./templates";

interface Props {
  setCanProceed: (canProceed: boolean) => void;
  customTemplateValues?: any[];
}

export const SlackTemplate: FC<Props> = props => {
  const { setCanProceed, customTemplateValues } = props;

  const [recipient, setRecipient] = useState<string>();
  const defSelection = slackConfigData.structure.map(str => str.value);
  const [selectedSections, setSelectedSections] = useState<string[]>(defSelection);

  const onStructureOptionSelection = useCallback((id: string, checked: boolean) => {
    setSelectedSections(prev => {
      if (checked) {
        return [...prev, id];
      } else {
        return prev.filter(x => x !== id);
      }
    });
  }, []);

  const channelRecipients = slackConfigData.recipient;
  const channelStructure = useMemo(
    () =>
      slackConfigData.structure.map(structure => ({
        ...structure,
        checked: selectedSections.includes(structure.value)
      })),
    [selectedSections]
  );

  const recipientElements = useMemo(
    () => (
      <IncRadioGroup
        className="width-45"
        layout="column"
        onChange={setRecipient}
        options={channelRecipients as IncSelectOption[]}
        value={recipient}
      />
    ),
    [channelRecipients, recipient]
  );

  const structureElements = useMemo(() => {
    const options = channelStructure?.map(
      structure => omit(structure, ["templateStr", "templateValues"]) as IncCheckboxProps
    );
    return (
      <CheckBoxList
        onChange={onStructureOptionSelection}
        options={options}
      />
    );
  }, [channelStructure, onStructureOptionSelection]);

  useEffect(() => {
    const canProceed = Boolean(recipient) && selectedSections.length > 0;
    setCanProceed(canProceed);
  }, [recipient, selectedSections.length, setCanProceed]);

  return (
    <div
      className="section-wrapper slack-template-editor"
      data-cy={CypressConstants.features.CreateCopilot.attributes.slackTemplate}
    >
      <div className="section-wrapper--configuration">
        <div className="section p-t-0 border-0">
          <div className="section--title">Who to notify on Slack?</div>

          {recipientElements}

          <IncSelect
            isDisabled={false}
            options={channelOptions}
            placeholder="Select Channels"
            wrapperClass="width-60"
          />
        </div>

        <div className="section">
          <div className="section--title">What to include in the notification?</div>

          <div className="section--description">
            Pick the content you want to receive when you get an alert for a violation
          </div>

          <div className="inc-flex-column multioptions--checkbox ">{structureElements}</div>
        </div>
      </div>

      <div className="section-wrapper--preview">
        <div className="section--title">Preview</div>
        <div className="preview-container">
          <SlackSubscriptionDataTemplate
            customValues={customTemplateValues}
            data={slackConfigData.structure}
            name={"slack"}
            selectedSections={selectedSections}
          />
        </div>
      </div>
    </div>
  );
};

const channelOptions: IncSelectOption[] = [
  {
    label: "Engineering",
    value: "engineering"
  },
  {
    label: "Revenue Operations",
    value: "revenue-operations"
  }
];
