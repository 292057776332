import React, { FC, useMemo, useCallback, CSSProperties } from "react";
import { IncFaIconName, IncFaIcon, IncSelectWithIcon, IncSelectWithIconOption, IncSwitch } from "@inception/ui";
import { Visualisations } from "../../../../core";
import { VizOption } from "../types";
import { TreeMapIcon } from "../../../../core/iconwrapper";
import { VerticallyCenteredRow } from "../../../../components";

interface Props {
  vizOptions: VizOption[];
  selectedViz: VizOption;
  onVizChange: (nVIz: VizOption) => void;
  renderAsSwitch?: boolean;
  label?: string;
}

export const VizSwitcher: FC<Props> = props => {
  const { onVizChange, selectedViz, vizOptions, renderAsSwitch, label } = props;

  const selectOpts = useMemo<IncSelectWithIconOption[]>(
    () =>
      vizOptions.map(vizOpt => {
        const { id, visualisation } = vizOpt;
        const vizInfo = iconInfoMap[visualisation];
        const { iconName, tooltip, style = {} } = vizInfo;

        const css = {
          ...style,
          fontSize: renderAsSwitch ? 13 : 16
        };

        const icon =
          visualisation === Visualisations.treeMap ? (
            <TreeMapIcon style={css} />
          ) : (
            <IncFaIcon
              iconName={iconName}
              style={css}
              title={tooltip}
            />
          );

        return {
          label: renderAsSwitch ? "" : tooltip,
          value: id,
          icon,
          data: vizOpt
        };
      }),
    [renderAsSwitch, vizOptions]
  );

  const selectedVizOpt = useMemo(
    () => selectOpts.find(({ value }) => selectedViz?.id === value),
    [selectOpts, selectedViz]
  );

  const onChange = useCallback(
    (selVizOpt: IncSelectWithIconOption) => {
      const { value: viz, data } = selVizOpt;
      if (viz) {
        onVizChange(data);
      }
    },
    [onVizChange]
  );

  return (
    <VerticallyCenteredRow className="viz-switcher">
      {!renderAsSwitch && (
        <IncSelectWithIcon
          autoAdjustWidth
          autoAdjustWidthBuffer={40}
          isSearchable={false}
          label={label}
          menuPlacement="bottom"
          onChange={onChange}
          options={selectOpts}
          value={selectedVizOpt}
        />
      )}

      {renderAsSwitch && (
        <IncSwitch
          onChange={onChange as any}
          options={selectOpts}
          value={selectedVizOpt}
        />
      )}
    </VerticallyCenteredRow>
  );
};

type IconInfo = {
  iconName: IncFaIconName;
  tooltip: string;
  style?: CSSProperties;
};

const iconInfoMap: Record<Visualisations, IconInfo> = {
  [Visualisations.barChart]: {
    iconName: "chart-column",
    tooltip: "Bar chart"
  },
  [Visualisations.donut]: {
    iconName: "circle-notch",
    tooltip: "Donut"
  },
  [Visualisations.gauge]: {
    iconName: "circle-notch",
    tooltip: "Gauge",
    style: {
      clipPath: "polygon(0 0, 100% 0%, 100% 50%, 0 50%)",
      transform: "translateY(4px)"
    }
  },
  [Visualisations.geoMap]: {
    iconName: "map-location-dot",
    tooltip: "Geo map"
  },
  [Visualisations.pieChart]: {
    iconName: "chart-pie",
    tooltip: "Pie chart"
  },
  [Visualisations.timeseries]: {
    iconName: "chart-line",
    tooltip: "Line Chart"
  },
  [Visualisations.treeMap]: {
    iconName: "chart-tree-map",
    tooltip: "Tree map",
    style: {
      height: 16,
      marginLeft: -4,
      marginRight: -2,
      transform: "scale(1.2)"
    }
  },
  [Visualisations.singleStat]: {
    iconName: "hashtag",
    tooltip: "Single Stat",
    style: {
      width: 16,
      alignItems: "center"
    }
  },
  [Visualisations.heatMap]: {
    iconName: "diagram-cells",
    tooltip: "Heat map"
  },
  [Visualisations.stackedBarChart]: {
    iconName: "bars-progress",
    tooltip: "Stacked bar chart"
  },
  [Visualisations.insights]: {
    iconName: "table-list",
    tooltip: "Insights"
  },
  [Visualisations.histogram]: {
    iconName: "square-poll-vertical",
    tooltip: "Histogram"
  },
  [Visualisations.sparkLine]: {
    iconName: "hashtag",
    tooltip: "Stat",
    style: {
      width: 16,
      alignItems: "center"
    }
  },
  [Visualisations.table]: {
    iconName: "table",
    tooltip: "Table"
  }
};
