import { generateId } from "@inception/ui";
import { useMemo } from "react";
import { ENTITY_TAG } from "../../../../../../../utils";
import { CatalogWidgetUtils } from "../../../../CatalogWidgetUtils";
import { useFetchFieldNameMetricNames } from "../../../../components/renderers/common";
import { CatalogWidgetImpl } from "../../../../models";

export const useCommonCustomisationExtraction = (widgetImpl: CatalogWidgetImpl) => {
  const { eventTypeName, entityType: entityTypeName, properties, queryConfig } = widgetImpl;

  const { aggregateTags, aggregator: presetAggregator } = properties;

  const aggregator = useMemo(() => {
    const aggOptions = CatalogWidgetUtils.getAggregators(queryConfig).aggOpts;
    return aggOptions.find(({ value }) => value === presetAggregator);
  }, [presetAggregator, queryConfig]);

  const { sourceQueryConfig } = queryConfig;
  const configMetricId =
    sourceQueryConfig.queryType === "widgetConfig"
      ? sourceQueryConfig.metricId
      : sourceQueryConfig.queryType === "useCase"
        ? sourceQueryConfig.dataQueryConfig?.query?.sliceSpec?.metricId
        : null;

  const metricId = useMemo(() => configMetricId || generateId(), [configMetricId]);
  const childMetricIds = useMemo(
    () => (sourceQueryConfig.queryType === "widgetConfig" ? sourceQueryConfig.childMetricIds || [] : []),
    [sourceQueryConfig]
  );

  const { childMetricNames, metricName } = useFetchFieldNameMetricNames(aggregator, queryConfig, eventTypeName);

  const displayAggregateTags = useMemo(
    () => aggregateTags.map(tagName => (tagName === ENTITY_TAG ? entityTypeName || eventTypeName || tagName : tagName)),
    [aggregateTags, entityTypeName, eventTypeName]
  );

  return {
    aggregator,
    aggregateTags,
    displayAggregateTags,
    metricId,
    childMetricIds,
    metricName,
    childMetricNames,
    queryConfig,
    eventTypeName,
    entityTypeName
  };
};
