import { generateId, generateUUID } from "@inception/ui";
import { EditorState, ContentState, ContentBlock, genKey, CharacterMetadata, Modifier, SelectionState } from "draft-js";
import { Map, List } from "immutable";
import { cloneDeep, last } from "lodash";
import { logger } from "../../../../../core";
import {
  ActionTemplateTextElement,
  ElementPropertyValues,
  SectionElementType,
  TemplateCanvas,
  TemplateCanvasSection
} from "../../../../../services/api/operationalise";
import { getTypedObjValueKeyBasedOnType } from "../../../../../utils";
import {
  BLOCK_DATA_SECTION_KEY,
  ENTITY_RANGE_DATA_DISPLAY_TEXT_KEY,
  ENTITY_RANGE_DATA_MATCH_KEY,
  ENTITY_RANGE_DATA_PROPERTIES_KEY,
  ENTITY_RANGE_DATA_TEXT_ELEMENT_KEY
} from "./constants";
import { getPropertiesForTextElement, getSectionForSectionElement } from "./sectionUtils";
import { TemplateBlockData, TemplateEntityData } from "./types";

export const getUpdatedEditorState = (
  editorState: EditorState,
  templateCanvas: TemplateCanvas,
  textElements: ActionTemplateTextElement[]
) => {
  templateCanvas.section.forEach(section => {
    editorState = addSectionToEditorState(editorState, section, textElements);
  });

  const contentState = editorState.getCurrentContent();

  const blocksArr = contentState.getBlocksAsArray();
  const entityMap = contentState.getEntityMap();

  const firstBlock = blocksArr[0];
  if (!firstBlock?.getText()) {
    blocksArr.splice(0, 1);
  }

  const nContentState = ContentState.createFromBlockArray(blocksArr, entityMap);

  const nEditorState = EditorState.push(editorState, nContentState, "insert-fragment");

  return nEditorState;
};

export const addSectionToEditorState = (
  editorState: EditorState,
  section: TemplateCanvasSection,
  textElements: ActionTemplateTextElement[]
) => {
  let nEditorState = editorState;

  if (section) {
    const contentState = editorState.getCurrentContent();
    const blocksArr = contentState.getBlocksAsArray();

    const selectionState = editorState.getSelection();
    const focusBlockKey = selectionState.getFocusKey() || selectionState.getAnchorKey();

    let idx = blocksArr.length;
    let removeFocusBlock = false;

    if (focusBlockKey) {
      idx = blocksArr.findIndex(block => block.getKey() === focusBlockKey);

      const focusBlock = blocksArr[idx];
      const blockText =
        focusBlock?.getType() === SectionElementType.RICH_TEXT ? focusBlock?.getText() : "NON_RICH_TEXT_BLOCK_TEXT";
      removeFocusBlock = section.type === SectionElementType.RICH_TEXT ? false : focusBlock ? !blockText : false;
      idx = removeFocusBlock ? idx : idx + 1;
    }

    const newBlocks = getBlocksForSection(section, textElements, contentState);
    const newBlockId = last(newBlocks)?.getKey();

    const newEntityMap = contentState.getEntityMap();

    const numItemsToRemove = removeFocusBlock ? 1 : 0;
    const newBlocksArr = [...blocksArr];
    newBlocksArr.splice(idx, numItemsToRemove, ...newBlocks);

    const nContentState = ContentState.createFromBlockArray(newBlocksArr, newEntityMap);

    nEditorState = EditorState.push(editorState, nContentState, "insert-characters");

    if (newBlockId) {
      nEditorState = EditorState.forceSelection(nEditorState, SelectionState.createEmpty(newBlockId));
    }
  }

  return nEditorState;
};

export const splitSectionInEditorState = (editorState: EditorState, textElements: ActionTemplateTextElement[]) => {
  const contentState = editorState.getCurrentContent();
  const blocksArr = contentState.getBlocksAsArray();

  const selectionState = editorState.getSelection();
  const focusBlockKey = selectionState.getFocusKey() || selectionState.getAnchorKey();

  const nRichTextSection = getSectionForSectionElement({
    description: "",
    elementId: "rich-text-section",
    icon: "",
    label: "",
    prop: [],
    type: SectionElementType.RICH_TEXT
  });

  if (focusBlockKey) {
    const focusBlockIdx = blocksArr.findIndex(block => block.getKey() === focusBlockKey);
    const focusBlock = blocksArr[focusBlockIdx];

    if (focusBlock) {
      const isRichTextSection = focusBlock?.getType() === SectionElementType.RICH_TEXT;

      if (isRichTextSection) {
        const start = selectionState.getFocusOffset();
        const end = focusBlock.getCharacterList().size - 1;

        // Handle only when not end of line case
        if (start !== end) {
          const currentText = focusBlock.getText().slice(0, start);
          const currentCharacters = focusBlock.getCharacterList().slice(0, start);

          const nextText = focusBlock.getText().slice(start);
          const nextCharacters = focusBlock.getCharacterList().slice(start);

          const newFocusBlockKey = genKey();
          const newFocusBlock = new ContentBlock({
            type: SectionElementType.RICH_TEXT,
            text: currentText,
            key: newFocusBlockKey,
            characterList: currentCharacters,
            data: Map({
              [BLOCK_DATA_SECTION_KEY]: nRichTextSection
            })
          });

          const nextBlockKey = genKey();
          const nextBlock = new ContentBlock({
            type: SectionElementType.RICH_TEXT,
            text: nextText,
            key: nextBlockKey,
            characterList: nextCharacters,
            data: Map({
              [BLOCK_DATA_SECTION_KEY]: nRichTextSection
            })
          });

          const newEntityMap = contentState.getEntityMap();
          const nextBlocksArr = [...blocksArr];
          nextBlocksArr.splice(focusBlockIdx, 1, newFocusBlock, nextBlock);

          const nContentState = ContentState.createFromBlockArray(nextBlocksArr, newEntityMap);

          let nEditorState = EditorState.push(editorState, nContentState, "insert-characters");

          let selectionState = SelectionState.createEmpty(nextBlockKey) as SelectionState;
          selectionState = selectionState.set("focusOffset", 0) as SelectionState;
          selectionState = selectionState.set("anchorOffset", 0) as SelectionState;

          nEditorState = EditorState.forceSelection(nEditorState, selectionState);

          return nEditorState;
        }
      }
    }
  }

  return addSectionToEditorState(editorState, nRichTextSection, textElements);
};

export const addTextElementToEditorState = (
  editorState: EditorState,
  textElement: ActionTemplateTextElement,
  textElements: ActionTemplateTextElement[]
) => {
  const contentState = editorState.getCurrentContent();

  const selectionState = editorState.getSelection();

  const selOffset = selectionState.getFocusOffset();
  const blockKey = selectionState.getEndKey();
  const block = contentState.getBlockForKey(blockKey);

  if (block) {
    let nSelectionState = cloneDeep(selectionState);

    const contentId = generateId();
    const contentToInsert = `<<${contentId} | ${textElement.elementId}>>`;

    const type = block.getType();
    const key = block.getKey();
    const blockData = block.getData().toObject() as TemplateBlockData;

    if (type === SectionElementType.RICH_TEXT) {
      const displayText = getDisplayTextForTextElement(textElement);
      const textToInsert = " ";

      const properties = getPropertiesForTextElement(textElement);

      createEntityRangeForTextElement(contentState, textElement, contentToInsert, displayText, properties);
      const entityKey = contentState.getLastCreatedEntityKey();

      const existingEntityAtSel = block.getEntityAt(selOffset);
      if (existingEntityAtSel) {
        let hasStarted = false;
        let position = -1;

        const characterList = block.getCharacterList().toArray();
        const numCharacters = characterList.length;
        for (let idx = 0; idx < numCharacters; idx++) {
          const ch = characterList[idx];

          const entityKeyMatches = ch.getEntity() === existingEntityAtSel;

          if (entityKeyMatches) {
            hasStarted = true;
          }

          if (hasStarted && !entityKeyMatches) {
            position = idx;
            break;
          }
        }

        position = position === -1 ? numCharacters : position;

        nSelectionState = nSelectionState.merge({
          focusOffset: position,
          anchorOffset: position
        });
      }

      const newContentState = Modifier.insertText(contentState, nSelectionState, textToInsert, null, entityKey);
      let nEditorState = EditorState.push(editorState, newContentState, "insert-characters");

      const { section } = blockData;
      if (section) {
        section.childElementProp = {
          ...(section.childElementProp || {}),
          [contentId]: properties
        };
        const nBlockData: TemplateBlockData = {
          ...blockData,
          section
        };

        nEditorState = updateBlockDataInEditorState(nEditorState, key, nBlockData);
      }

      return nEditorState;
    } else {
      const properties = getPropertiesForTextElement(textElement);

      return addSectionToEditorState(
        editorState,
        {
          sectionElementId: "rich-text-section",
          sectionId: generateUUID(),
          type: SectionElementType.RICH_TEXT,
          content: contentToInsert.substring(1), // Omitting space if we are inserting it as a new block,
          childElementProp: {
            [contentId]: properties
          }
        },
        textElements
      );
    }
  }

  return editorState;
};

export const deleteSectionFromEditorState = (editorState: EditorState, blockKey: string) => {
  const contentState = editorState.getCurrentContent();
  const blocksArr = contentState.getBlocksAsArray();

  const newBlocksArr = blocksArr.filter(block => block.getKey() !== blockKey);

  const newEntityMap = contentState.getEntityMap();

  const nContentState = ContentState.createFromBlockArray(newBlocksArr, newEntityMap);

  const nEditorState = EditorState.push(editorState, nContentState, "insert-fragment");

  return nEditorState;
};

export const removeLinkFromEditorState = (
  editorState: EditorState,
  blockKey: string,
  entityKey: string,
  removeText = false
) => {
  const contentState = editorState.getCurrentContent();

  const block = contentState.getBlockForKey(blockKey);
  const entity = contentState.getAllEntities().get(entityKey);
  const characterListArr = block.getCharacterList().toArray();

  let nContentState: ContentState = contentState;

  if (removeText) {
    const blockText = block.getText();
    const selection = SelectionState.createEmpty(blockKey);

    const blockSelection = selection.merge({
      anchorOffset: 0,
      focusOffset: blockText.length
    });

    nContentState = Modifier.applyEntity(contentState, blockSelection, null);
    let nBlock = block.set("text", "") as ContentBlock;
    nBlock = block.set("characterList", List([])) as ContentBlock;

    const blockMap = nContentState.getBlockMap().set(blockKey, nBlock);
    const entityMap = nContentState.getEntityMap();

    const newBlocksArr = blockMap.toArray();
    nContentState = ContentState.createFromBlockArray(newBlocksArr, entityMap);
  } else {
    let entityStart = -1;

    characterListArr.forEach((ch, idx) => {
      const chEntityKey = ch.getEntity();
      if (entityKey === chEntityKey) {
        entityStart = entityStart === -1 ? idx : entityStart;
      }
    });

    const entityData = entity.getData() as TemplateEntityData;
    const displayText = entityData[ENTITY_RANGE_DATA_DISPLAY_TEXT_KEY];

    if (entityStart !== -1) {
      const selection = SelectionState.createEmpty(blockKey);

      let blockSelection = selection.merge({
        anchorOffset: entityStart,
        focusOffset: entityStart + 1
      });

      nContentState = Modifier.applyEntity(nContentState, blockSelection, null);

      if (displayText) {
        blockSelection = blockSelection.merge({
          anchorOffset: entityStart,
          focusOffset: entityStart
        });
        nContentState = Modifier.insertText(nContentState, blockSelection, displayText);
      }
    }
  }

  const nEditorState = EditorState.push(editorState, nContentState, "insert-characters");

  return nEditorState;
};

export const updateBlockDataInEditorState = (
  editorState: EditorState,
  blockKey: string,
  newBlockData: TemplateBlockData
) => {
  const selection = SelectionState.createEmpty(blockKey);

  const newData = Map(newBlockData);
  const newContent = Modifier.setBlockData(editorState.getCurrentContent(), selection, newData);

  const nEditorState = EditorState.push(editorState, newContent, "change-block-data");
  return nEditorState;
};

export const updateEntityDataInEditorState = (
  editorState: EditorState,
  entityKey: string,
  entityText: string,
  newEntityData: Partial<TemplateEntityData>,
  selectionState: SelectionState
) => {
  const contentState = editorState.getCurrentContent();

  let nContentState = contentState.mergeEntityData(entityKey, newEntityData);
  nContentState = Modifier.replaceText(
    contentState,
    selectionState,
    entityText,
    editorState.getCurrentInlineStyle(),
    entityKey
  );

  let newEditorState = EditorState.push(editorState, nContentState, "change-block-data");

  newEditorState = EditorState.forceSelection(newEditorState, selectionState);
  return newEditorState;
};

export const getDisplayTextForTextElement = (textEl: ActionTemplateTextElement, properties?: ElementPropertyValues) => {
  const { displayText, displayMode, label, prop, type } = textEl;

  const getDisplayPropValue = () => {
    const displayProp = prop?.find(p => p.includeInDisplay);
    if (!displayProp) {
      logger.warn(
        "getContentState",
        "Encountered textElement with use_props as displayMode with no prop having includeInDisplay: true. Falling back to label",
        textEl
      );
      return label;
    }

    const displayPropRawValue = properties?.propValue?.[displayProp.propId] || displayProp?.defaultValue;
    const valueKey = getTypedObjValueKeyBasedOnType(type as any);
    const displayPropValue = displayPropRawValue?.[valueKey] || "";

    return displayPropValue as string;
  };

  switch (displayMode) {
    case "use_display_text": {
      return displayText;
    }

    case "use_label": {
      return label;
    }

    case "use_props": {
      return getDisplayPropValue();
    }

    case "use_props_with_label": {
      const displayPropValue = getDisplayPropValue();
      return [label, displayPropValue].join(" ");
    }

    default:
      return "";
  }
};

const getBlocksForSection = (
  section: TemplateCanvasSection,
  textElements: ActionTemplateTextElement[],
  contentState: ContentState
) => {
  const blocks: ContentBlock[] = [];

  const { type: newType } = section;

  if (newType === SectionElementType.RICH_TEXT) {
    const contentBlocks = getBlocksForRichTextSection(section, textElements, contentState);
    blocks.push(...contentBlocks);
  } else {
    const newBlock = new ContentBlock({
      type: newType,
      text: "",
      key: genKey(),
      data: Map({
        [BLOCK_DATA_SECTION_KEY]: section
      })
    });
    blocks.push(newBlock);
  }

  return blocks;
};

function getBlocksForRichTextSection(
  section: TemplateCanvasSection,
  textElements: ActionTemplateTextElement[],
  contentState: ContentState
) {
  const { content: sectionContent = "" } = section;

  const blocks: ContentBlock[] = [];

  const lines = sectionContent.includes("\\n")
    ? sectionContent.split("\\n")
    : sectionContent.includes("\n")
      ? sectionContent.split("\n")
      : [sectionContent];

  lines.forEach(content => {
    const varRegex = /<<[\w-]+[\s]*\|[\s]*[\w.-]+>>/g;

    let block = new ContentBlock({
      type: SectionElementType.RICH_TEXT,
      text: content,
      key: genKey(),
      data: Map({
        [BLOCK_DATA_SECTION_KEY]: section
      })
    });

    const entityRangeList: string[] = [];

    let textStr = "";

    let matchArr: RegExpExecArray;

    let contentStart = 0;
    while ((matchArr = varRegex.exec(content)) !== null) {
      const match = matchArr[0];
      const matchLen = match.length;
      const matchIdx = matchArr.index;

      const prevWord = content.substring(contentStart, matchIdx);
      if (prevWord) {
        textStr += prevWord;

        const prevWordLen = prevWord.length;
        const emptyArray = new Array(prevWordLen).fill(null);
        entityRangeList.push(...emptyArray);
      }

      contentStart = matchIdx + matchLen;

      const tokens = match.replace(/&lt;&lt;|&gt;&gt;|<<|>>|[\s]*/g, "").split("|");
      const tokenId = tokens[0];
      const tokenElementId = tokens[1];
      const textEl = textElements.find(el => el.elementId === tokenElementId);

      const properties = section.childElementProp?.[tokenId];

      if (textEl) {
        const displayText = getDisplayTextForTextElement(textEl, properties);
        createEntityRangeForTextElement(contentState, textEl, match, displayText, properties);

        // const displayText = getDisplayTextForTextElement(textEl, properties);
        const textToAdd = " ";
        const textToAddLen = textToAdd.length;

        const start = textStr.length;
        const end = start + textToAddLen;

        const entityId = contentState.getLastCreatedEntityKey();

        for (let index = start; index < end; index++) {
          entityRangeList.push(entityId);
        }

        textStr += textToAdd;
      }
    }

    const prevWord = content.substring(contentStart, content.length);
    const prevWordLen = prevWord.length;
    const emptyArray = new Array(prevWordLen).fill(null);
    entityRangeList.push(...emptyArray);

    textStr += prevWord;

    const charactersArr = entityRangeList.map(entityKey =>
      CharacterMetadata.create({
        entity: entityKey
      })
    );
    const characterList = List(charactersArr);

    block = block.set("characterList", characterList) as ContentBlock;
    block = block.set("text", textStr) as ContentBlock;

    blocks.push(block);
  });

  return blocks;
}

const createEntityRangeForTextElement = (
  contentState: ContentState,
  textElement: ActionTemplateTextElement,
  matchStr: string,
  displayText: string,
  properties: ElementPropertyValues
) => {
  contentState.createEntity(SectionElementType.RICH_TEXT, "IMMUTABLE", {
    [ENTITY_RANGE_DATA_TEXT_ELEMENT_KEY]: textElement,
    [ENTITY_RANGE_DATA_MATCH_KEY]: matchStr,
    [ENTITY_RANGE_DATA_PROPERTIES_KEY]: properties,
    [ENTITY_RANGE_DATA_DISPLAY_TEXT_KEY]: displayText
  });
};
