import { cx } from "emotion";
import { forEach, isEqual, last } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SortAndLimit, SortAndLimitSelector } from "../../../components";
import { DataType, useForceUpdate, useTenantConfig, Visualisations } from "../../../core";
import { useFieldPicker } from "../../../field-picker";
import { entityApiService } from "../../../services/api";
import {
  FieldPickerContextDTO,
  LimitSpec,
  OverTagPostAgg,
  PostAggProjection,
  UserServiceFieldSlice,
  UserServiceFieldSliceSet
} from "../../../services/api/explore";
import { logger } from "../../../core/logging/Logger";
import { ENTITY_TAG } from "../../../utils";
import { getImplicitSlice } from "../../../utils/ExploreUtils";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { GLOBAL_VARIABLE_SRV_KEY } from "../../variables/constants";
import { WidgetCustomAction } from "../types";
import { VizRenderer, VizSwitcher } from "./components";
import { useFetchQueryPayload } from "./components/renderers/common";
import { GroupBySelector } from "./components/renderers/GroupBySelector";
import { USFWModeRendererProps, VizOption } from "./types";
import { USFieldWidgetUtils } from "./USFieldWidgetUtils";

export const USFWVizWrapper: FC<USFWModeRendererProps> = props => {
  const {
    timeRange,
    noDataElement,
    loadingElement,
    widget,
    compareTimeRange,
    variablesLoadingElement,
    onError,
    metricId,
    selectedFilters,
    onTriage,
    seriesFilters,
    aggregator,
    widgetResponseDTO,
    onAggOrSliceTagsChange,
    cohortFilters,
    entityFilters,
    eventFilters,
    variablesLoading,
    resetFilters,
    variableSrvMap,
    onAddAdhocEntityFilter,
    onActionsChange,
    additionalSlices,
    edit,
    setAdditionalSlices
  } = props;

  const {
    entityType,
    userServiceId,
    queryConfig,
    id,
    properties,
    renderMode,
    eventTypeName: pEventTypeName,
    bizEntityFieldName
  } = widget;

  const forceUpdate = useForceUpdate();

  const isEventIDField = useMemo(() => {
    const sourceQueryConfig = queryConfig?.sourceQueryConfig;
    const usField =
      sourceQueryConfig?.queryType === "userServiceField" ? sourceQueryConfig?.usField?.userServiceField : null;
    const fieldName = usField?.fieldName;

    return USFieldWidgetUtils.isHasErrorField(fieldName) || USFieldWidgetUtils.isHasErrorField(fieldName);
  }, [queryConfig]);

  const defEventTypeNameLoading = userServiceId ? isEventIDField && !pEventTypeName : false;
  const shouldFetchEventTypeName = useRef(defEventTypeNameLoading);

  const [eventTypeNameLoading, setEventTypeNameLoading] = useState(defEventTypeNameLoading);
  const [eventTypeName, setEventTypeName] = useState(pEventTypeName);

  useEffect(() => {
    if (!eventTypeName && isEventIDField && userServiceId && shouldFetchEventTypeName.current) {
      setEventTypeNameLoading(true);
      const entityIds = new Set<string>();
      entityIds.add(userServiceId);

      entityApiService
        .fetchEntityNamesForIds(entityIds)
        .then(res => {
          const eventTypeName = res.get(userServiceId);
          setEventTypeName(eventTypeName);
        })
        .finally(() => {
          shouldFetchEventTypeName.current = false;
          setEventTypeNameLoading(false);
        });
    }
  }, [eventTypeName, isEventIDField, userServiceId]);

  const {
    visualisation: presetVisualisation,
    insights: presetInsights,
    aggregateTags: presetAggregateTags,
    disableCompare = false
  } = properties;

  const postAggProjection = useMemo<PostAggProjection>(() => (disableCompare ? "current" : "all"), [disableCompare]);

  const isAdhocView = renderMode === "adhoc-view";
  const isViewMode = renderMode === "view";

  const eventTypeId = userServiceId || widgetResponseDTO?.widgetConfig?.userServiceEntityId;

  const { changeMetric = "current", limitSpec: presetLimitSpec } = presetInsights || {};

  const { tenantConfigState } = useTenantConfig();
  const currencyType = tenantConfigState.currency;

  const vizOpts = useMemo(() => USFieldWidgetUtils.getVisualisations(queryConfig), [queryConfig]);

  const vizConfig = useMemo(
    () => vizOpts.find(({ visualisation }) => visualisation === presetVisualisation) || vizOpts[0],
    [presetVisualisation, vizOpts]
  );

  const setVizConfig = useCallback(
    (vizConfig: VizOption) => {
      const { visualisation } = vizConfig;
      widget.properties.visualisation = vizConfig.visualisation;

      const nSortAndLimit = getSortAndLimitByViz(visualisation, presetLimitSpec);
      if (vizConfig.visualisation === Visualisations.insights) {
        widget.properties.insights = {
          ...(widget.properties.insights || ({} as any)),
          limitSpec: {
            function: nSortAndLimit.sortType,
            limit: nSortAndLimit.limit
          }
        };
      }

      forceUpdate();
    },
    [forceUpdate, presetLimitSpec, widget.properties]
  );

  const { visualisation: defViz } = vizConfig || {};

  const sortAndLimit = useMemo(() => getSortAndLimitByViz(defViz, presetLimitSpec), [defViz, presetLimitSpec]);
  const setSortAndLimit = useCallback(
    (sortAndLimit: SortAndLimit) => {
      if (vizConfig.visualisation === Visualisations.insights) {
        widget.properties.insights = {
          ...(widget.properties.insights || ({} as any)),
          limitSpec: {
            function: sortAndLimit.sortType,
            limit: sortAndLimit.limit
          }
        };
      }
      forceUpdate();
    },
    [forceUpdate, vizConfig.visualisation, widget.properties]
  );

  const [customActions, setCustomActions] = useState<WidgetCustomAction[]>([]);
  const [groupByAction, setGroupByAction] = useState<WidgetCustomAction>();
  const tagsInitialisedRef = useRef(false);

  const [selectedSlices, setSelectedSlices] = useState<UserServiceFieldSlice[]>([]);
  const additionalSlicesRef = useRef<UserServiceFieldSlice[]>(additionalSlices);
  useMemo(() => {
    additionalSlicesRef.current = additionalSlices;
  }, [additionalSlices]);

  const presetAggregateTagsRef = useRef(presetAggregateTags);
  useMemo(() => {
    presetAggregateTagsRef.current = presetAggregateTags;
  }, [presetAggregateTags]);

  const fieldPickerContext: FieldPickerContextDTO = useMemo(
    () => ({
      entityId: null,
      entityName: entityType,
      entityType,
      showFields: true,
      userServices: eventTypeId
        ? [
            {
              userServiceEntityId: eventTypeId
            }
          ]
        : [],
      userServiceToBizEntityFieldName: eventTypeId && bizEntityFieldName ? { [eventTypeId]: bizEntityFieldName } : {}
    }),
    [bizEntityFieldName, entityType, eventTypeId]
  );

  const { data: fieldPickerModel, isFetching: isFetchingFieldsData, isSuccess, getFields } = useFieldPicker();

  const getLatestTimeRange = useCallback(() => {
    const latestTr = timeRangeUtils.getTimeRangeFromRaw(timeRange.raw);
    return latestTr;
  }, [timeRange.raw]);

  const implicitSlice = useMemo(() => {
    if (!isFetchingFieldsData && isSuccess && fieldPickerModel) {
      const entityUSField = fieldPickerModel.getEntityUSField(bizEntityFieldName);
      if (entityUSField) {
        return getImplicitSlice(entityUSField);
      }
    } else {
      return null;
    }
  }, [bizEntityFieldName, fieldPickerModel, isFetchingFieldsData, isSuccess]);

  const fetchFields = useCallback(() => {
    const { from, to } = getLatestTimeRange();
    if (fieldPickerContext && queryConfig?.sourceQueryConfig?.queryType === "userServiceField") {
      logger.debug("Calling picker with ", "");
      getFields(fieldPickerContext, from.valueOf(), to.valueOf());
    }
  }, [fieldPickerContext, getFields, getLatestTimeRange, queryConfig]);

  useEffect(() => {
    if (!fieldPickerModel) {
      fetchFields();
    }
  }, [fetchFields, fieldPickerModel]);

  useEffect(() => {
    widget.properties.visualisation = vizConfig.visualisation;
  }, [vizConfig, widget]);

  useEffect(() => {
    if (vizConfig.visualisation === Visualisations.insights) {
      widget.properties.insights = {
        ...(widget.properties.insights || ({} as any)),
        limitSpec: {
          function: sortAndLimit.sortType,
          limit: sortAndLimit.limit
        }
      };
    }
  }, [vizConfig.visualisation, sortAndLimit.limit, sortAndLimit.sortType, widget.properties]);

  const { uiDataType, cardinality } = useMemo(
    () => USFieldWidgetUtils.getDataTypeAndAggInfo(queryConfig),
    [queryConfig]
  );

  const actionsClassName = useMemo(
    () =>
      cx("inc-flex-row inc-flex-center-vertical", {
        disableClick: variablesLoading
      }),
    [variablesLoading]
  );

  const { rLoadingElement, rNoDataElement } = useMemo(
    () => ({
      rLoadingElement: <>{loadingElement}</>,
      rNoDataElement: <>{noDataElement}</>
    }),
    [loadingElement, noDataElement]
  );

  const currViz = vizConfig?.visualisation;
  const showLimitSelect = currViz === Visualisations.insights;

  const { limit: seriesLimit, sortType: limitSpecFunction } = sortAndLimit;

  const defaultAggregatedTags = useMemo(() => {
    if (presetAggregateTags) {
      return presetAggregateTags;
    } else if (widgetResponseDTO?.querySchema?.querySchema?.length) {
      const querySchema = last(widgetResponseDTO.querySchema.querySchema);
      const tagNames = querySchema.sliceSet.slices.map(s => s.tagName);
      return tagNames;
    } else {
      return [];
    }
  }, [presetAggregateTags, widgetResponseDTO]);

  const isSingleStatQuery = vizConfig.visualisation === Visualisations.insights;

  const { aggregatedTags, dataFetchPayload, updateAggregatedTags } = useFetchQueryPayload(
    widgetResponseDTO,
    defaultAggregatedTags,
    queryConfig,
    vizConfig,
    timeRange,
    compareTimeRange,
    selectedFilters,
    metricId,
    changeMetric,
    postAggProjection,
    limitSpecFunction,
    seriesLimit,
    resetFilters,
    entityFilters,
    cohortFilters,
    eventFilters,
    isSingleStatQuery
  );

  useEffect(() => {
    if (dataFetchPayload) {
      const { sliceSpec } = dataFetchPayload || {};
      const { postAgg, sliceSet } = sliceSpec?.[0] || {};

      let sliceTags = sliceSet?.slices?.map(sl => sl.tagName) || [];
      sliceTags = [...sliceTags].sort();

      let aggTags = (postAgg as OverTagPostAgg)?.overTagAgg?.tagName || [];
      aggTags = [...aggTags].sort();
      onAggOrSliceTagsChange(aggTags, sliceTags);
    }
  }, [dataFetchPayload, onAggOrSliceTagsChange]);

  useEffect(() => {
    if (widget.properties.aggregateTags !== undefined || aggregatedTags?.length) {
      widget.properties.aggregateTags = [...(aggregatedTags || [])];
    }
  }, [aggregatedTags, widget]);

  const displayAggregatedTags = useMemo(
    () => aggregatedTags?.map(aggTag => (aggTag === ENTITY_TAG ? entityType : aggTag)),
    [aggregatedTags, entityType]
  );

  const qs = widgetResponseDTO?.querySchema?.querySchema;
  const querySchema = useMemo(() => qs || [], [qs]);

  const aggregateDataType = useMemo<DataType>(() => {
    const { sliceSet } = querySchema?.find(qs => qs.metricId === metricId) || {};
    if (sliceSet) {
      const matchSlices = sliceSet.slices.filter(sl => aggregatedTags?.includes(sl.tagName));
      if (matchSlices.length === 1) {
        return matchSlices[0]?.fieldType || "STRING";
      }
    }

    return "STRING";
  }, [aggregatedTags, metricId, querySchema]);

  const defaultSlices = useMemo(() => {
    if (implicitSlice) {
      const slices = USFieldWidgetUtils.getDefaultSlicesBasedOnQueryConfig(queryConfig, implicitSlice);
      if (!isViewMode) {
        const additionalSlices = additionalSlicesRef.current || [];
        const presetAggregateTags = presetAggregateTagsRef.current;

        const tagSlices = [...slices, ...additionalSlices].filter(s => {
          if (presetAggregateTags) {
            return presetAggregateTags.includes(s.tagName);
          }

          return true;
        });

        const tagNames = tagSlices.map(s => {
          if (isEqual(s, implicitSlice)) {
            return implicitSlice.tagName;
          } else {
            return s.tagName;
          }
        });
        updateAggregatedTags(tagNames);
        setSelectedSlices(tagSlices);
        tagsInitialisedRef.current = true;
      }

      return slices;
    }
    return [];
  }, [implicitSlice, isViewMode, queryConfig, updateAggregatedTags]);

  const onSliceSetsChange = useCallback(
    (sliceSets: UserServiceFieldSliceSet[]) => {
      // const implicitSliceAddedByDefault = !featureFlagService.isFeatureEnabled(FEATURE_FLAGS.skipImplicitSlice);
      const implicitSliceAddedByDefault = true;

      const slices = sliceSets?.[0].slices;
      setSelectedSlices(slices);

      slices.forEach(slice => {
        const isImplicitSliceUSF = isEqual(slice.userServiceField, implicitSlice?.userServiceField);
        if (isImplicitSliceUSF) {
          slice.tagName = implicitSlice.tagName;
        }
      });

      const tagNames = slices.map(s => s.tagName);
      updateAggregatedTags(tagNames);

      const addSlices = slices.filter(slice => {
        const existsInDefaultSlices = defaultSlices.find(
          ds =>
            ds.userServiceField.fieldName === slice.userServiceField.fieldName &&
            isEqual(ds.userServiceField.entityField, slice.userServiceField.entityField)
        );

        const fieldNamesMatch = slice.userServiceField.fieldName === implicitSlice?.userServiceField?.fieldName;
        const canAddSlice = implicitSliceAddedByDefault
          ? !fieldNamesMatch || !isEqual(slice.userServiceField.entityField, implicitSlice.userServiceField.entityField)
          : true;

        return !existsInDefaultSlices && canAddSlice;
      });
      widget.properties.additionalSlices = addSlices || [];
      setAdditionalSlices(addSlices);
    },
    [defaultSlices, implicitSlice, setAdditionalSlices, updateAggregatedTags, widget.properties]
  );

  useEffect(() => {
    if (!isAdhocView) {
      const action: WidgetCustomAction = {
        actionComponent: (
          <GroupBySelector
            aggregateTags={aggregatedTags}
            cardinality={cardinality}
            entityType={entityType}
            fieldPickerContext={fieldPickerContext}
            getLatestTimeRange={getLatestTimeRange}
            isViewMode={isViewMode}
            metricId={metricId}
            onAggregateTagsChange={updateAggregatedTags}
            onSliceSetsChange={onSliceSetsChange}
            queryConfigType={queryConfig.sourceQueryConfig.queryType}
            querySchema={querySchema}
            sliceSets={[{ slices: selectedSlices }]}
          />
        ),
        showInHeader: true,
        tooltipText: "Group by"
      };
      setGroupByAction(action);

      return () => {
        setGroupByAction(null);
      };
    }
  }, [
    entityType,
    fieldPickerContext,
    getLatestTimeRange,
    isViewMode,
    metricId,
    onSliceSetsChange,
    querySchema,
    selectedSlices,
    queryConfig.sourceQueryConfig.queryType,
    aggregatedTags,
    cardinality,
    updateAggregatedTags,
    isAdhocView
  ]);

  const existingEntityTypeFilters = useMemo(() => {
    const entityFiltersMap: Record<string, boolean> = {};
    forEach(variableSrvMap, (value, key) => {
      const entityType = value.getEntityTypeId();
      if (value.checkIfFilterWidget() && entityType && key !== GLOBAL_VARIABLE_SRV_KEY) {
        entityFiltersMap[entityType] = true;
      }
    });
    return entityFiltersMap;
  }, [variableSrvMap]);

  const vizSwitchAction = useMemo<WidgetCustomAction>(
    () => ({
      actionComponent: (
        <VizSwitcher
          onVizChange={setVizConfig}
          selectedViz={vizConfig}
          vizOptions={vizOpts}
        />
      ),
      showInHeader: true,
      tooltipText: "Switch visualisation"
    }),
    [setVizConfig, vizConfig, vizOpts]
  );

  useEffect(() => {
    const allActions = [...customActions];
    groupByAction && allActions.unshift(groupByAction);
    allActions.unshift(vizSwitchAction);

    onActionsChange(allActions);

    return () => onActionsChange([]);
  }, [customActions, groupByAction, onActionsChange, vizSwitchAction]);

  const isUSFieldSourceTypeAndViewMode =
    queryConfig?.sourceQueryConfig?.queryType === "userServiceField" && !isViewMode;

  return (
    <div
      className="us-field-container--detailed-mode"
      data-actions-exist={showLimitSelect}
      data-full-height={isAdhocView}
    >
      {showLimitSelect && (
        <div className={actionsClassName}>
          <SortAndLimitSelector
            onChange={setSortAndLimit}
            sortAndLimit={sortAndLimit}
          />
        </div>
      )}

      {(variablesLoading || eventTypeNameLoading) && variablesLoadingElement}
      {!variablesLoading &&
        !eventTypeNameLoading &&
        (isUSFieldSourceTypeAndViewMode ? tagsInitialisedRef.current : true) && (
          <VizRenderer
            aggregateDataType={aggregateDataType}
            aggregatedTags={aggregatedTags}
            aggregator={aggregator}
            className="us-field-container--visualisation"
            cohortFilters={cohortFilters}
            compareTimeRange={compareTimeRange}
            currViz={vizConfig}
            currencyType={currencyType}
            dataFetchPayload={dataFetchPayload}
            dataType={uiDataType}
            displayAggregatedTags={displayAggregatedTags}
            edit={edit}
            entityFilters={entityFilters}
            entityTypeName={entityType}
            eventFilters={eventFilters}
            eventTypeName={eventTypeName}
            existingEntityTypeFilters={existingEntityTypeFilters}
            limitSpecFunction={limitSpecFunction}
            loadingElement={rLoadingElement}
            metricId={metricId}
            noDataElement={rNoDataElement}
            onAddAdhocEntityFilter={onAddAdhocEntityFilter}
            onCustomActionsChange={setCustomActions}
            onError={onError}
            onTriage={onTriage}
            postAggProjection={postAggProjection}
            properties={properties}
            queryConfig={queryConfig}
            queryId={id}
            renderMode={renderMode}
            selectedFilters={selectedFilters}
            seriesFilters={seriesFilters}
            seriesLimit={seriesLimit}
            timeRange={timeRange}
            userServiceId={eventTypeId}
            variablesLoading={variablesLoading}
            widgetResponseDTO={widgetResponseDTO}
            widgetTitle={widget.title}
          />
        )}
    </div>
  );
};

const DEFAULT_SERIES_LIMIT = 30;
const DEFAULT_TOP_K_SERIES_LIMIT = 5;

// const getProjectionByViz = (visualisation: Visualisations): PostAggProjection => visualisation === Visualisations.insights ? 'all'
//   : 'current';

const getSortAndLimitByViz = (visualisation: Visualisations, limitSpec: LimitSpec): SortAndLimit => {
  const isInsightsViz = visualisation === Visualisations.insights;
  const sortAndLimit: SortAndLimit = isInsightsViz
    ? {
        limit: limitSpec?.limit || DEFAULT_TOP_K_SERIES_LIMIT,
        sortType: limitSpec?.function || "top"
      }
    : {
        limit: DEFAULT_SERIES_LIMIT,
        sortType: null
      };

  return sortAndLimit;
};
