import { IncTextfield } from "@inception/ui";
import { capitalize, debounce, isNull } from "lodash";
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { WidgetTextProperty } from "../types";

interface Props {
  property: WidgetTextProperty;
  onUpdate: (newState: WidgetTextProperty) => void;
}

const TextPropertyRenderer: FC<Props> = props => {
  const { onUpdate: usrUpdate, property } = props;

  const {
    name,
    value: usrValue,
    label = capitalize(name),
    updateOnBlur = true,
    placeholder = capitalize(name),
    info = ""
  } = property;

  const [value, setValue] = useState<string>(null);

  const onUpdate = useMemo(
    () =>
      debounce((property: WidgetTextProperty, newValue: string) => {
        const newProperty: WidgetTextProperty = {
          ...property,
          value: newValue
        };
        usrUpdate(newProperty);
      }, 500),
    [usrUpdate]
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value || "";
    setValue(newValue);
    if (!updateOnBlur) {
      onUpdate(property, newValue);
    }
  };

  const noOp = useCallback(() => {}, []);
  const onBlur = updateOnBlur ? () => onUpdate(property, value) : noOp;

  useEffect(() => {
    setValue(usrValue as string);
  }, [usrValue]);

  return (
    !isNull(value) && (
      <IncTextfield
        helpText={info}
        label={label}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        type="text"
        value={value}
      />
    )
  );
};

export default TextPropertyRenderer;
