import React, { FC, useCallback, useMemo } from "react";
import { IncButton, IncFaIcon, IncPopConfirm, IncSmartText, IncToolTip } from "@inception/ui";
import { BizDataQuery } from "../../services/api/explore";
import { VerticallyCenteredRow } from "../../components";
import { useNotifications, useToggleState } from "../../core";
import {
  addAnalysisDiagnosticKpi,
  cloneAnalysisDiagnosticKpi,
  deleteAnalysisDiagnosticKpi,
  overrideAnalysisDiagnosticKpi,
  overrideBizDataQuery,
  setAnalysisDiagnosticKpiName,
  setAnalysisDiagnosticKpiQuery,
  useOpAnalysisStore,
  validateAnalysisDiagnosticKPI
} from "./context";
import DiagnosticKpiEntry from "./DiagnosticKPIEntry";

type Props = {
  relatedKpiKey: string;
  eventTypeId: string;
};

const AnalysisDiagnosticKpiEditor: FC<Props> = props => {
  const { relatedKpiKey, eventTypeId } = props;
  const { dispatch, state } = useOpAnalysisStore();
  const { opAnalysisImpl } = state;
  const kpiRecord = useMemo(() => opAnalysisImpl.getDiagnosticKpis(relatedKpiKey), [opAnalysisImpl, relatedKpiKey]);
  const { kpi: kpis = [] } = kpiRecord || {};
  const kpiRecordToOverride = useMemo(() => opAnalysisImpl.getKpis()?.[relatedKpiKey], [opAnalysisImpl, relatedKpiKey]);
  const errors = useMemo(
    () =>
      validateAnalysisDiagnosticKPI({
        [relatedKpiKey]: kpiRecord
      }),
    [kpiRecord, relatedKpiKey]
  );

  const onAddKpi = useCallback(() => {
    dispatch(
      addAnalysisDiagnosticKpi({
        key: relatedKpiKey,
        payload: eventTypeId
      })
    );
  }, [dispatch, eventTypeId, relatedKpiKey]);
  const onCloneKpi = useCallback(
    (index: number) => {
      dispatch(
        cloneAnalysisDiagnosticKpi({
          key: relatedKpiKey,
          payload: index
        })
      );
    },
    [dispatch, relatedKpiKey]
  );

  const onChangeKpiName = useCallback(
    (name: string, index: number) => {
      dispatch(
        setAnalysisDiagnosticKpiName({
          key: relatedKpiKey,
          payload: {
            index,
            payload: name
          }
        })
      );
    },
    [dispatch, relatedKpiKey]
  );

  const onChangeKpiQuery = useCallback(
    (bizDataQuery: BizDataQuery, index: number) => {
      dispatch(
        setAnalysisDiagnosticKpiQuery({
          key: relatedKpiKey,
          payload: {
            index,
            payload: bizDataQuery
          }
        })
      );
    },
    [dispatch, relatedKpiKey]
  );
  const onDeleteKpi = useCallback(
    (index: number) => {
      dispatch(
        deleteAnalysisDiagnosticKpi({
          key: relatedKpiKey,
          payload: index
        })
      );
    },
    [dispatch, relatedKpiKey]
  );

  const { isOpen: isOverrideInProgress, close: stopOverrideProgress, open: startOverrideProgress } = useToggleState();
  const {
    isOpen: isOverrideConfirmationPopupOpen,
    close: closeOverrideConfirmationPopup,
    open: openOverrideConfirmationPopup
  } = useToggleState();
  const { notifySuccess, notifyError } = useNotifications();
  const onOverride = useCallback(async () => {
    closeOverrideConfirmationPopup();
    if (kpiRecordToOverride) {
      startOverrideProgress();
      const overridenEntries = await overrideBizDataQuery(kpiRecordToOverride);
      if (typeof overridenEntries == "string") {
        notifyError(overridenEntries);
      } else {
        if (overridenEntries?.kpi?.length) {
          dispatch(
            overrideAnalysisDiagnosticKpi({
              key: relatedKpiKey,
              payload: overridenEntries
            })
          );
          notifySuccess("KPIs override successfully");
        }
      }
      stopOverrideProgress();
    }
  }, [
    closeOverrideConfirmationPopup,
    dispatch,
    kpiRecordToOverride,
    notifyError,
    notifySuccess,
    relatedKpiKey,
    startOverrideProgress,
    stopOverrideProgress
  ]);

  const onOverrideOrConfirm = useCallback(() => {
    if (kpis.length) {
      if (kpiRecordToOverride) {
        openOverrideConfirmationPopup();
      }
    } else {
      onOverride();
    }
  }, [kpiRecordToOverride, kpis.length, onOverride, openOverrideConfirmationPopup]);

  const onOverrideConfirm = useCallback(() => {
    onOverride();
  }, [onOverride]);

  const diagnosticKpisJsx = useMemo(
    () =>
      kpis.map((kpi, index) => {
        const onDelete = () => onDeleteKpi(index);
        const onClone = () => onCloneKpi(index);
        const onChangeName = (name: string) => onChangeKpiName(name, index);
        const onChangeQuery = (bizDataQuery: BizDataQuery) => onChangeKpiQuery(bizDataQuery, index);
        return (
          <DiagnosticKpiEntry
            eventTypeId={eventTypeId}
            key={index}
            kpi={kpi}
            onChangeName={onChangeName}
            onChangeQuery={onChangeQuery}
            onClone={onClone}
            onDelete={onDelete}
          />
        );
      }),
    [eventTypeId, kpis, onChangeKpiName, onChangeKpiQuery, onCloneKpi, onDeleteKpi]
  );

  const isValid = !Object.keys(errors).length;

  const errorElement = useMemo(() => {
    const jsxList: JSX.Element[] = [];
    Object.keys(errors).forEach(title => {
      const jsx = (
        <span className="inc-flex-row">
          {title}: {errors[title]}
        </span>
      );
      jsxList.push(jsx);
    });
    if (jsxList.length) {
      return <div className="inc-flex-column flex-gap-8">{jsxList}</div>;
    }
    return <></>;
  }, [errors]);

  return (
    <div className="inc-flex-column flex-gap-16">
      <VerticallyCenteredRow className="flex-gap-12">
        <IncSmartText text="Diagnostic Key Performance Indicators" />
        {!isValid && (
          <IncToolTip
            showArrow
            titleElement={errorElement}
            variant="error"
          >
            <IncFaIcon
              className="status-danger inc-cursor-pointer"
              iconName="warning"
            />
          </IncToolTip>
        )}
        <IncButton
          color={"link"}
          loading={isOverrideInProgress}
          loadingText={"Override is in progress"}
          onClick={onOverrideOrConfirm}
        >
          Override From Parent
        </IncButton>
        <IncPopConfirm
          buttonProps={{
            okButton: {
              text: "Confirm"
            },
            cancelButton: {
              text: "Cancel"
            }
          }}
          color="secondary-blue"
          iconProps={{
            iconName: "trash",
            className: "status-danger"
          }}
          onCancel={closeOverrideConfirmationPopup}
          onConfirm={onOverrideConfirm}
          open={isOverrideConfirmationPopupOpen}
          placement="bottomRight"
          showCancel
          title="Confirm Override?"
        />
      </VerticallyCenteredRow>
      <div className="inc-flex-column flex-gap-12">{diagnosticKpisJsx}</div>
      <IncButton
        color={"link"}
        iconName={"plus-circle"}
        onClick={onAddKpi}
      >
        Add
      </IncButton>
    </div>
  );
};

export default AnalysisDiagnosticKpiEditor;
