import React, { memo, useEffect, useCallback, useState, useMemo } from "react";
import { IncSelectOption, IncSelect } from "@inception/ui";
import { Op10zeStage } from "../../services/api/operationalise";

type SProps = {
  stage: Op10zeStage;
  setStage: (stage: Op10zeStage) => void;

  className?: string;
  alignment?: "row" | "column";
  label?: string;
  beforeSetStage?: (stage: Op10zeStage) => Promise<boolean>;

  addDraftStage?: boolean;
  isLoading?: boolean;
};

export const StageSwitcher = memo<SProps>(props => {
  const {
    stage,
    setStage,
    beforeSetStage,
    alignment,
    label = "Stage",
    className = "",
    addDraftStage = false,
    isLoading = false
  } = props;

  const [checkInProgress, setCheckInProgress] = useState(false);

  const options = useMemo(() => {
    if (addDraftStage) {
      return [...stageOptions];
    }
    return stageOptions.slice(0, 2);
  }, [addDraftStage]);

  const selOption = options.filter(opt => opt.data === stage);

  useEffect(() => {
    if (!selOption?.length) {
      setStage(Op10zeStage.production);
    }
  }, [selOption, setStage]);

  const updateStage = useCallback(
    async (stage: Op10zeStage) => {
      setCheckInProgress(true);
      const promise = beforeSetStage ? beforeSetStage(stage) : Promise.resolve(true);
      const canUpdate = await promise;

      if (canUpdate) {
        setStage(stage);
      }

      setCheckInProgress(false);
    },
    [beforeSetStage, setStage]
  );

  const onChange = useCallback(
    (opt: IncSelectOption<Op10zeStage>) => {
      const stages = opt.data;
      updateStage(stages);
    },
    [updateStage]
  );

  const fClassName = ["stage-switcher", className].filter(Boolean).join(" ");

  const loading = isLoading || checkInProgress;

  return (
    <IncSelect
      alignment={alignment}
      isDisabled={loading}
      isLoading={loading}
      isSearchable={false}
      label={label}
      onChange={onChange}
      options={options}
      value={selOption}
      wrapperClass={fClassName}
    />
  );
});

const stageOptions: Array<IncSelectOption<Op10zeStage>> = [
  {
    label: "Production",
    value: Op10zeStage.production,
    data: Op10zeStage.production
  },
  {
    label: "Staging",
    value: Op10zeStage.staging,
    data: Op10zeStage.staging
  },
  {
    label: "Draft",
    value: Op10zeStage.draft,
    data: Op10zeStage.draft
  }
];
