import { IncButton, IncToolTip } from "@inception/ui";
import React, { useMemo } from "react";
import { ButtonFragment } from "../../services/api/chat";

interface ButtonFragmentProps {
  btnFragment: ButtonFragment;
  onActionClick: () => void;
}

export const ButtonFragmentRenderer = (props: ButtonFragmentProps) => {
  const { btnFragment, onActionClick } = props;
  const { type, displayText, tooltip } = btnFragment;

  const jsx = useMemo(() => {
    const btnJsx = (
      <IncButton
        className="marginTpAuto"
        color={type || "primary"}
        onClick={onActionClick}
      >
        {displayText}
      </IncButton>
    );
    if (tooltip) {
      return <IncToolTip titleText={tooltip}>{btnJsx}</IncToolTip>;
    }
    return btnJsx;
  }, [displayText, onActionClick, tooltip, type]);

  return jsx;
};
