import React, { FC, useCallback, useMemo, useRef } from "react";
import { StaticThresholdDef } from "../../../../services/api/operationalise";
import { AggregationSelect } from "../../../../components";
import {
  getAggregationFromAggrValue,
  getAggregationUIOption,
  MetricAggregations,
  MetricAggregatorExpanded
} from "../../../../core";
import { TraceUIAggregation } from "../../../../services/api/explore";

interface Props {
  thresholdDef: StaticThresholdDef;
  onChange: (thresholdDef: StaticThresholdDef, isPeriodOpt?: boolean) => void;
  isEventOperationalize: boolean;
  isPeerGroupThreshold: boolean;
  readOnly?: boolean;
}

export const ThresholdOffsetEditor: FC<Props> = props => {
  const { thresholdDef, onChange, isPeerGroupThreshold, isEventOperationalize, readOnly } = props;

  const { rollingFunction, peerThreshold } = thresholdDef;

  const aggFunc = isPeerGroupThreshold ? peerThreshold.peerAgg : rollingFunction;

  const rollingFuncOption = useMemo(() => getUIAggregation(aggFunc), [aggFunc]);

  const thresholdDefRef = useRef(thresholdDef);
  useMemo(() => {
    thresholdDefRef.current = thresholdDef;
  }, [thresholdDef]);

  const onRollingFuncChange = useCallback(
    (aggrOpt: TraceUIAggregation) => {
      const thresholdDef = thresholdDefRef.current;
      const aggUiOpt = getAggregationUIOption(aggrOpt.aggrType, aggrOpt.fraction);

      if (isPeerGroupThreshold) {
        onChange({
          ...thresholdDef,
          peerThreshold: {
            ...thresholdDef.peerThreshold,
            peerAgg: aggUiOpt.value
          },
          rollingFunction: isEventOperationalize ? aggUiOpt.value : rollingFunction
        });
      } else {
        onChange({
          ...thresholdDef,
          rollingFunction: null
        });
      }
    },
    [isEventOperationalize, isPeerGroupThreshold, onChange, rollingFunction]
  );

  return (
    <>
      <AggregationSelect
        exclude={[MetricAggregations.Contribution]}
        hideLabel
        onChange={onRollingFuncChange}
        readOnly={readOnly}
        value={rollingFuncOption}
      />
    </>
  );
};

function getUIAggregation(agg: string): TraceUIAggregation {
  const aggr = getAggregationFromAggrValue(agg as MetricAggregatorExpanded);
  const uiAggr: TraceUIAggregation = {
    aggrType: aggr.type,
    fraction: aggr.fractions
  };
  return uiAggr;
}
