import React, { FC, useCallback, useMemo, useState } from "react";
import {
  ImpactedWidget,
  ExploreEntityFilter,
  CohortConfig,
  UserServiceFilterExpression,
  BizFieldPredicate,
  UserServiceFilterList,
  UserServiceFieldSlice
} from "../../../services/api/explore";
import { logger, RawTimeRange, TimeRange, useLocalStorage, useTimeRange } from "../../../core";
import { ImpactedWidgetFilter } from "../impact-widget";
import { EventsTable } from "../../events-table/EventsTable";
import { useAuth } from "../../../login/state/useAuth";
import { TimeRangeController } from "../../time-range";
import { noOp } from "../../../utils";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { TriageCriteriaRenderer } from "./TriageCriteriaRenderer";

export interface ImpactedWidgetDrawerProps {
  entityTypeId: string;
  impactedWidget: ImpactedWidget;
  alertingSlices: UserServiceFieldSlice[];

  cohortId?: string;
  entityFilters?: ExploreEntityFilter[];
  eventFieldFilters?: UserServiceFilterList;
  timeRange?: TimeRange;
  incidentId?: string;
  opConfigId?: string;
  preselectFieldNames?: string[];

  generateDemoData?: boolean;
}

export const ImpactedWidgetDrawer: FC<ImpactedWidgetDrawerProps> = props => {
  const {
    impactedWidget,
    entityFilters,
    eventFieldFilters,
    cohortId = "",
    entityTypeId,
    timeRange: pTimeRange,
    preselectFieldNames,
    opConfigId,
    incidentId,
    alertingSlices
  } = props;

  const { timeRange: gTimeRange, compareTimeRange } = useTimeRange();
  const dTimeRange = useMemo(() => pTimeRange || gTimeRange, [gTimeRange, pTimeRange]);
  const [timeRange, setTimeRange] = useState(dTimeRange);

  const onChangeTimeRange = useCallback((rawTimeRange: RawTimeRange) => {
    const timeRange = timeRangeUtils.getTimeRangeFromRaw(rawTimeRange);
    setTimeRange(timeRange);
  }, []);

  const { underlyingFilters } = impactedWidget;

  const { authState } = useAuth();
  const userId = authState?.user?.email || String(authState?.user?.id);

  const preSelectionStorageKey = getFieldsStorageKey(userId, opConfigId);

  const entityCriteria = useMemo(() => {
    const entityCriteria: BizFieldPredicate[] = [];
    entityCriteria.push(...(entityFilters?.[0]?.filters || []));
    return entityCriteria;
  }, [entityFilters]);

  const eventCriteria = useMemo(() => {
    const eventCriteria: UserServiceFilterExpression[] = [];
    eventCriteria.push(...(eventFieldFilters?.userServiceFilters[0]?.userServiceFilterExpressions || []));
    return eventCriteria;
  }, [eventFieldFilters]);

  const cohortDefinition = useMemo<CohortConfig>(
    () => ({
      cohortId,
      name: ""
    }),
    [cohortId]
  );

  const impactedWidgetFilter = useMemo(
    () => (
      <div className="marginRt12">
        <ImpactedWidgetFilter impactedWidget={impactedWidget} />
      </div>
    ),
    [impactedWidget]
  );

  const impactWidgetFilters = useMemo(() => {
    const extendedFilters: UserServiceFilterExpression[] = [];
    (underlyingFilters?.userServiceFilters || []).forEach(filter =>
      extendedFilters.push(...filter.userServiceFilterExpressions)
    );

    return extendedFilters;
  }, [underlyingFilters]);
  const eventTypeId = impactedWidget.underlyingUserServices?.[0]?.userServiceEntityId;

  const { getItem, setItem } = useLocalStorage();

  const preselectedFields = useMemo(() => {
    const preselectedFieldsSet = new Set<string>();

    // (impactedWidget.underlyingFilters?.userServiceFilters || []).forEach(filter => {
    //   const { userServiceFilterExpressions } = filter;
    //   userServiceFilterExpressions.forEach(({ field }) => {
    //     const label = FieldPickerUtils.generateNamefromUSF(field);
    //     preselectedFieldsSet.add(label);
    //   });
    // });

    // // Temporarily clearing all filter fields
    // preselectedFieldsSet.clear();

    (preselectFieldNames || []).forEach(fieldName => preselectedFieldsSet.add(fieldName));

    const localPreselectionStr = getItem(preSelectionStorageKey);
    if (localPreselectionStr) {
      try {
        const preselection = JSON.parse(localPreselectionStr || "[]") as string[];
        preselection.forEach(fieldName => preselectedFieldsSet.add(fieldName));
      } catch (err) {
        logger.error("ImpactedWidgetDrawer", "Failed to parse preselection", err);
      }
    }

    return Array.from(preselectedFieldsSet);
  }, [getItem, preSelectionStorageKey, preselectFieldNames]);

  const onSelectionChange = useCallback(
    (selectionMap: Record<string, boolean>) => {
      const preselectedFields = Object.keys(selectionMap).filter(label => selectionMap[label]);
      setItem(preSelectionStorageKey, JSON.stringify(preselectedFields));
    },
    [preSelectionStorageKey, setItem]
  );

  const bizEntityFieldName = useMemo(
    () => alertingSlices?.[0]?.userServiceField?.bizEntityFieldName || "",
    [alertingSlices]
  );
  const userServiceToBizEntityFieldName = useMemo<Record<string, string>>(
    () =>
      eventTypeId && bizEntityFieldName
        ? {
            [eventTypeId]: bizEntityFieldName
          }
        : {},
    [bizEntityFieldName, eventTypeId]
  );

  return (
    <div className="impacted-widget-drawer">
      <TriageCriteriaRenderer
        className="marginBt16 width-100"
        cohortDefinition={cohortDefinition}
        entityCriteria={entityCriteria}
        entityType={entityTypeId}
        eventCriteria={eventCriteria}
        readOnly
        sectionChildren={{
          eventCriteria: impactedWidgetFilter
        }}
      />
      <div className="d-flex marginBt16">
        <TimeRangeController
          className="marginLtAuto"
          compareTimeRange={compareTimeRange}
          disableRefresh
          setCompareTimeRange={noOp}
          setTimeRange={onChangeTimeRange}
          showTimeRangeSelector
          timeRange={timeRange}
        />
      </div>
      <EventsTable
        entityFilters={entityFilters}
        entityTypeId={entityTypeId}
        eventFilters={eventFieldFilters}
        eventTypeId={eventTypeId}
        extendedFilters={impactWidgetFilters}
        impactedWidget={impactedWidget}
        incidentId={incidentId}
        onSelectionChange={onSelectionChange}
        opConfigId={opConfigId}
        preselectedFields={preselectedFields}
        timeRange={timeRange}
        userServiceToBizEntityFieldName={userServiceToBizEntityFieldName}
      />
    </div>
  );
};

const getFieldsStorageKey = (userId: string, opConfigId: string) => `impact_drawer_fields_sel_${userId}_${opConfigId}`;
