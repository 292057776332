import React, { FC, useEffect, useState, useCallback, useMemo } from "react";
import { IncSelectOption, IncSelect, IncTextfield } from "@inception/ui";
import { LimitSpecFunction } from "../services/api/explore";
import { VerticallyCenteredRow } from "./flex-components";

export type SortAndLimit = {
  sortType: LimitSpecFunction;
  limit: number;
};

interface Props {
  sortAndLimit: SortAndLimit;
  onChange: (sortAndLimit: SortAndLimit) => void;
  hideBottom?: boolean;
  hideTop?: boolean;
}

type Option = IncSelectOption<SortAndLimit>;

export const SortAndLimitSelector: FC<Props> = props => {
  const { onChange, sortAndLimit, hideBottom = false, hideTop = false } = props;

  const options = useMemo(
    () => (hideBottom ? [...topOptions] : hideTop ? [...bottomOptions] : [...topOptions, ...bottomOptions]),
    [hideBottom, hideTop]
  );

  const [numStr, setNStr] = useState<string>("5");
  const [selectedOption, setSelectedOption] = useState<Option>();

  const applySeriesLimitChange = useCallback(
    (seriesLimit: string) => {
      const nCount = parseInt(seriesLimit, 10);
      onChange({
        ...sortAndLimit,
        limit: nCount
      });
    },
    [onChange, sortAndLimit]
  );

  const onNStrChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const nCountStr = e.target.value.toString();
    setNStr(nCountStr);
  }, []);

  const onNValChange = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const nCountStr = e.target.value.toString() || "5";
      applySeriesLimitChange(nCountStr);
    },
    [applySeriesLimitChange]
  );

  const onOptChange = useCallback(
    (opt: Option) => {
      setSelectedOption(opt);

      const { data } = opt;

      const pLimit = sortAndLimit.limit;
      const nLimit = data.limit;

      if (nLimit !== -1) {
        onChange(data);
      } else {
        onChange({
          ...data,
          limit: pLimit
        });
      }

      const nLimitStr = pLimit.toString();
      setNStr(nLimitStr);
    },
    [onChange, sortAndLimit.limit]
  );

  useEffect(() => {
    if (!selectedOption) {
      const { limit, sortType } = sortAndLimit;
      const nLimit = limit || 5;

      let nOpt = options.find(opt => {
        const { limit: oLimit, sortType: oSortType } = opt.data;
        return oSortType === sortType && oLimit === nLimit;
      });

      nOpt = nOpt ? nOpt : sortType === "bottom" ? bottomNOpt : topNOpt;

      setNStr(nLimit.toString());
      setSelectedOption(nOpt);
    }
  }, [options, selectedOption, sortAndLimit]);

  const isCustomValue = selectedOption?.data?.limit === -1;

  return (
    <VerticallyCenteredRow className="sort-and-limit-selector">
      <IncSelect
        autoAdjustWidth
        autoSort={false}
        className="sort-by-selector"
        isMulti={false}
        isSearchable={false}
        onChange={onOptChange}
        options={options}
        value={selectedOption}
      />
      {isCustomValue && (
        <IncTextfield
          className="n-str-input"
          onBlur={onNValChange}
          onChange={onNStrChange}
          value={numStr}
        />
      )}
    </VerticallyCenteredRow>
  );
};

const topNOpt: Option = {
  label: "Top N",
  value: "Top N",
  data: {
    limit: -1,
    sortType: "top"
  }
};

const bottomNOpt: Option = {
  label: "Bottom N",
  value: "Bottom N",
  data: {
    limit: -1,
    sortType: "bottom"
  }
};

const presetLimitOptions = [5, 10, 25, 50];

const presetTopLimitOptions = presetLimitOptions.map(limit => {
  const label = `Top ${limit}`;
  const data: SortAndLimit = {
    limit,
    sortType: "top"
  };

  return {
    label,
    value: label,
    data
  };
});
const topOptions = [...presetTopLimitOptions, topNOpt];

const presetBottomLimitOptions = presetLimitOptions.map(limit => {
  const label = `Bottom ${limit}`;
  const data: SortAndLimit = {
    limit,
    sortType: "bottom"
  };

  return {
    label,
    value: label,
    data
  };
});
const bottomOptions = [...presetBottomLimitOptions, bottomNOpt];
