import {
  IncButton,
  IncCheckbox,
  IncClickAwayPopper,
  IncSelectOption,
  IncTextfield,
  IncSkeleton,
  IncSmartText
} from "@inception/ui";
import { capitalize, debounce, uniqWith } from "lodash";
import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";
import { getFieldValueCompletion, VerticallyCenteredRow } from "../../../../components";
import { logger, useTimeRange } from "../../../../core";
import { FieldPickerOptionData, useFetchCohortList, UserServiceField } from "../../../../services/api/explore";
import timeRangeUtils from "../../../../utils/TimeRangeUtils";
import { FieldPickerUtils } from "../../../../utils";

interface ValuesPickerProps {
  anchorEl: HTMLElement;
  context: FieldPickerOptionData;
  tag: string;
  onChange: (selected: string[], isCohortPicked: boolean) => void;
  selectedValues: string[];
  show: boolean;
  onClose: () => void;
  showCohortSection?: boolean;
  isCohortPicked?: boolean;
}
const LIMIT = 100;

export const EventFieldValuePopper = (props: ValuesPickerProps) => {
  const {
    context,
    tag,
    onChange,
    show,
    onClose,
    selectedValues: pSelectedValues,
    anchorEl,
    showCohortSection = false,
    isCohortPicked = false
  } = props;
  const selectedValues = useMemo(() => pSelectedValues || [], [pSelectedValues]);

  const userServiceField = context.payload as UserServiceField;

  const [searchText, setSearchText] = useState("");
  const [isFetching, setFetching] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(selectedValues);
  const [pickerOptions, setPickerOptions] = useState<IncSelectOption[]>([]);
  const { timeRange } = useTimeRange();
  const isCohortPickedRef = useRef(isCohortPicked);

  const { isEntityTypeField, entityTypeId } = useMemo(() => {
    const isEntityTypeField = FieldPickerUtils.isEntityField(userServiceField);

    const entityTypeId = isEntityTypeField ? userServiceField.entityField?.entityType : "";
    return {
      isEntityTypeField,
      entityTypeId
    };
  }, [userServiceField]);

  const { data: cohortList, isFetching: isCohortFetching, refetch } = useFetchCohortList(entityTypeId);

  const shouldShowHeader = showCohortSection && isEntityTypeField;

  useEffect(() => {
    if (isEntityTypeField && showCohortSection) {
      refetch();
    }
  }, [isEntityTypeField, refetch, showCohortSection]);

  const fetchOptions = useCallback(
    async (searchText: string) => {
      const searchOption: ValueOption = {
        value: searchText,
        label: searchText,
        data: true
      };

      const { fromMillis, toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);
      setFetching(true);

      try {
        const { data, error, message } = await getFieldValueCompletion(
          context,
          tag,
          fromMillis,
          toMillis,
          searchText,
          LIMIT,
          null,
          true
        );

        if (!error) {
          const {
            fieldValues: { entityLookupData, values }
          } = data;

          const filteredSelectedValues = selectedValues.filter(value =>
            value.toLowerCase().includes(searchText.toLowerCase())
          );
          const fValues = [...filteredSelectedValues, ...values];
          const nValues = uniqWith(fValues, (a, b) => a === b);

          const options: ValueOption[] = nValues
            .map(s => ({
              value: s,
              label: (entityLookupData[s] || s || "").trim()
            }))
            .filter(s => Boolean(s.label));

          const numberOptions = options
            .filter(opt => !isNaN(Number(opt.label)))
            .sort((a, b) => Number(a.label) - Number(b.label));
          const stringOptions = options
            .filter(opt => isNaN(Number(opt.label)))
            .sort((a, b) => a.label.localeCompare(b.label));
          const sortedOptions = [...stringOptions, ...numberOptions];

          setFetching(false);
          if (!searchText || sortedOptions.find(x => x.label.toLowerCase() === searchText.toLowerCase())) {
            setPickerOptions(sortedOptions);
          } else {
            setPickerOptions([searchOption, ...sortedOptions]);
          }
        } else {
          logger.error("Event Type Picker Values", "Error fetching field values", message);
          setFetching(false);
          setPickerOptions(searchText ? [searchOption] : []);
        }
      } catch (e) {
        logger.error("Event Type Picker Values", "Error fetching field values", e);
        setFetching(false);
        setPickerOptions(searchText ? [searchOption] : []);
      }
    },
    [context, selectedValues, tag, timeRange]
  );

  useEffect(() => {
    // initial fetch
    if (fetchOptions) {
      fetchOptions("");
    }
  }, [fetchOptions]);

  const debouncedFetchOptions = useMemo(() => debounce(fetchOptions, 500), [fetchOptions]);

  const onChangeSelection = useCallback(
    (value: string, isChecked: boolean, isCohortSelected: boolean, isCreateOpt = false) => {
      setSelectedOptions(prev => {
        let values = [...prev];
        if (isCohortSelected) {
          if (isCohortPickedRef.current) {
            // const wrappedValue = `"${value}"`;
            if (isChecked) {
              values = [...values, value];
            } else {
              values = values.filter(x => x !== value);
            }
          } else {
            values = [value];
            isCohortPickedRef.current = true;
          }
        } else {
          if (!isCohortPickedRef.current) {
            if (isChecked) {
              values = [...values, value];
            } else {
              values = values.filter(x => x !== value);
            }
          } else {
            values = [value];
            isCohortPickedRef.current = false;
          }
        }

        return values;
      });

      if (isCreateOpt) {
        setSearchText("");
      }
    },
    []
  );

  const onChangeSearchText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSearchText(value);
      debouncedFetchOptions(value);
    },
    [debouncedFetchOptions]
  );

  const applyFilters = useCallback(() => {
    onChange(selectedOptions, isCohortPickedRef.current);
  }, [onChange, selectedOptions]);

  const onClearClick = useCallback(() => {
    setSelectedOptions([]);
    onChange([], isCohortPickedRef.current);
  }, [onChange]);

  const cohortSection = useMemo(() => {
    if (cohortList) {
      const filteredCohortList = cohortList.filter(x => x.name.toLowerCase().includes(searchText.toLowerCase()));
      return (
        <div className="cohort-section inc-flex-column">
          <span className="inc-text-body">{entityTypeId} Cohorts</span>
          {isCohortFetching && (
            <IncSkeleton
              active
              paragraph={{ rows: 2 }}
            />
          )}
          {!isCohortFetching &&
            Boolean(cohortList.length) &&
            filteredCohortList.map(cohort => {
              const isChecked = selectedOptions.includes(cohort.cohortEntityId);
              const toggleChecked = () => onChangeSelection(cohort.cohortEntityId, !isChecked, true);
              return (
                <VerticallyCenteredRow
                  className="field-item"
                  key={cohort.cohortEntityId}
                  onClick={toggleChecked}
                >
                  <IncCheckbox checked={isChecked} />
                  <span
                    className="marginLt12 inc-text-subtext text-ellipsis"
                    title={cohort.name}
                  >
                    {cohort.name}
                  </span>
                </VerticallyCenteredRow>
              );
            })}
        </div>
      );
    }
    return null;
  }, [cohortList, entityTypeId, isCohortFetching, onChangeSelection, searchText, selectedOptions]);

  const valuesSection = useMemo(
    () => (
      <div className="values-section inc-flex-column flex-gap-12">
        {shouldShowHeader && <span className="inc-text-body">{capitalize(entityTypeId)} Values</span>}
        {isFetching && (
          <IncSkeleton
            active
            paragraph={{ rows: 6 }}
          />
        )}
        {!isFetching &&
          Boolean(pickerOptions.length) &&
          pickerOptions.map(pickerOption => {
            const { label, value, data: isCreateOpt = false } = pickerOption;
            const isChecked = selectedOptions.includes(value);
            const isCustomOpt = isCreateOpt && !isChecked;

            const toggleChecked = () => onChangeSelection(value, !isChecked, false, isCreateOpt);

            const labelClassName = isCustomOpt ? "inc-text-subtext-semibold status-info" : "inc-text-subtext-medium";
            const displayLabel = isCustomOpt ? `Add ${label}` : label;

            return (
              <VerticallyCenteredRow
                className="field-item flex-gap-12"
                key={value}
                onClick={toggleChecked}
              >
                {!isCustomOpt && (
                  <IncCheckbox
                    checked={isChecked}
                    noImplicitHeight
                  />
                )}
                <IncSmartText
                  className={labelClassName}
                  text={displayLabel}
                />
              </VerticallyCenteredRow>
            );
          })}
      </div>
    ),
    [entityTypeId, isFetching, onChangeSelection, pickerOptions, selectedOptions, shouldShowHeader]
  );

  return (
    <IncClickAwayPopper
      anchorEl={anchorEl}
      onClickAway={onClose}
      placement="bottom-end"
      show={show}
    >
      <div className="field-value-picker inc-flex-column flex-gap-12">
        <div className="upper-section inc-flex-column flex-gap-12">
          <IncTextfield
            autoFocus
            containerClassName="marginBt12"
            endIcon={"magnifying-glass"}
            onChange={onChangeSearchText}
            placeholder="Search"
            value={searchText}
          />
          {shouldShowHeader && cohortSection}
          {valuesSection}
        </div>
        <VerticallyCenteredRow className="lower-section flex-gap-8">
          <IncButton
            color="primary"
            onClick={applyFilters}
          >
            Apply Filters
          </IncButton>
          <IncButton
            color="secondary-red"
            onClick={onClearClick}
          >
            Clear
          </IncButton>
        </VerticallyCenteredRow>
      </div>
    </IncClickAwayPopper>
  );
};

// Add isCustom as data to the options for custom formatting
type ValueOption = IncSelectOption<boolean>;
