import { IncButton, IncFaIcon, IncInModalConfirmation } from "@inception/ui";
import { cloneDeep } from "lodash";
import React, { FC, useCallback, useMemo, useRef } from "react";
import { LoadingSpinner } from "../../../components";
import { useAccessPrivilege, useNotifications, useToggleState } from "../../../core";
import { OpCreationConfig, operationaliseV2ApiService } from "../../../services/api/operationalise";
import { logger } from "../../../core/logging/Logger";
import { getAllCohortDefinition, noOp } from "../../../utils";
import { PRIMARY_CONFIG_AS_SIMULATION_KEY, TEMPORARY_SIMULATION_KEY } from "../../constants";
import {
  setSimulations,
  useOpStore,
  setOperationaliseConfig,
  setSimulationConfig,
  setReadOnly,
  setCohortConfig
} from "../../context";
import { SimulationConfig } from "../../context/types";
import { CohortConfig } from "../../../services/api/explore";
import { OpSimulationCard } from "./OpSimulationCard";
import { PrimaryOpConfigCard } from "./PrimaryOpConfigCard";

interface Props {
  defaultNewConfig: OpCreationConfig;
  onOverride: (opCreationConfig: OpCreationConfig) => void;
}

export const OpSimulationsList: FC<Props> = props => {
  const { defaultNewConfig, onOverride } = props;

  const { notifyError, notifySuccess } = useNotifications();
  const { canCreate } = useAccessPrivilege();

  const { state, dispatch } = useOpStore();

  const {
    simulationConfigs: pSimulationConfigs,
    context,
    selectedSimulation,
    primaryOpCreationConfig,
    op10zeId
  } = state;
  const { disableOpEdit = false } = defaultNewConfig || {};
  const { sessionId, mode } = context;

  const isEditMode = mode === "edit";

  const simulationConfigsRef = useRef(pSimulationConfigs);
  useMemo(() => {
    simulationConfigsRef.current = pSimulationConfigs;
  }, [pSimulationConfigs]);

  const selectedSimulationId = selectedSimulation?.simulationId;
  const isTemporarySimulation = selectedSimulationId === TEMPORARY_SIMULATION_KEY;

  const {
    close: closeDeleteConfirmation,
    isOpen: isDeleteConfirmationOpen,
    open: openDeleteConfirmation
  } = useToggleState();

  const {
    close: onDeleteSimulationDone,
    isOpen: isDeleteSimulationInProgress,
    open: onDeleteSimulationTriggered
  } = useToggleState();

  const {
    close: closeSwitchConfirmation,
    isOpen: isSwitchConfirmationOpen,
    open: openSwitchConfirmation
  } = useToggleState();

  const {
    close: closeCloneConfirmation,
    isOpen: isCloneConfirmationOpen,
    open: openCloneConfirmation
  } = useToggleState();

  const deleteSimulationIdxRef = useRef(-1);
  const switchSimulationIdxRef = useRef(-1);
  const cloneSimulationConfigRef = useRef<SimulationConfig>();
  const primaryConfigReadOnlyRef = useRef(true);

  const onAddSimulation = useCallback(() => {
    const simulationConfigs = simulationConfigsRef.current;
    const numSimulationConfigs = simulationConfigs.length;

    const opCreationConfig = cloneDeep(defaultNewConfig);
    opCreationConfig.simulations = [];
    opCreationConfig.history = {};

    const nSimulationConfig: SimulationConfig = {
      createdBy: null,
      description: "",
      isPrimary: false,
      name: `Condition ${numSimulationConfigs + 1}`,
      simulationId: TEMPORARY_SIMULATION_KEY,
      opCreationConfig,
      labels: {
        isPrimary: "false"
      }
    };

    dispatch(setSimulationConfig(nSimulationConfig));
    dispatch(setOperationaliseConfig(opCreationConfig));
    dispatch(setReadOnly(false));
  }, [defaultNewConfig, dispatch]);

  const onDeleteSimulation = useCallback(async () => {
    if (deleteSimulationIdxRef.current > -1) {
      const simulationConfigs = simulationConfigsRef.current;
      const simulationToDelete = simulationConfigs[deleteSimulationIdxRef.current];
      const deleteSimulationId = simulationToDelete?.simulationId;

      let canProceed = false;

      if (isEditMode) {
        onDeleteSimulationTriggered();

        const { data, error, message } = await operationaliseV2ApiService.deleteSimulation(
          op10zeId,
          deleteSimulationId
        );

        if (error) {
          notifyError("Error deleting simulation");
          logger.error("OpSimulationList", "Error deleting simulation", {
            data,
            message
          });
        } else {
          notifySuccess("Simulation deleted successfully");
          canProceed = true;
        }

        onDeleteSimulationDone();
      } else {
        canProceed = true;
      }

      if (canProceed) {
        const nSimulationConfigs = [...simulationConfigs];
        nSimulationConfigs.splice(deleteSimulationIdxRef.current, 1);
        deleteSimulationIdxRef.current = -1;

        dispatch(setSimulations(nSimulationConfigs));

        if (selectedSimulationId === deleteSimulationId) {
          const nextSimulation = nSimulationConfigs[0];
          if (nextSimulation) {
            dispatch(setSimulationConfig(nextSimulation));
          } else {
            onAddSimulation();
          }
        }
      }

      closeDeleteConfirmation();
    }
  }, [
    closeDeleteConfirmation,
    dispatch,
    isEditMode,
    notifyError,
    notifySuccess,
    onAddSimulation,
    onDeleteSimulationDone,
    onDeleteSimulationTriggered,
    op10zeId,
    selectedSimulationId
  ]);

  const onSimulationClone = useCallback(
    async (simulationConfig: SimulationConfig) => {
      try {
        if (isTemporarySimulation) {
          cloneSimulationConfigRef.current = simulationConfig;
          openCloneConfirmation();
        } else {
          // only for TEMP solution until backend is fixed
          if (simulationConfig.simulationId === PRIMARY_CONFIG_AS_SIMULATION_KEY) {
            const cloneApiResponse = await operationaliseV2ApiService.clonePrimaryOpSimulationConfig(op10zeId);
            if (cloneApiResponse.error) {
              notifyError("Error while cloning primary simulation");
              logger.error("OpSimulationList", "Error while cloning primary simulation", cloneApiResponse.message);
            } else {
              const nSimulationConfig = cloneApiResponse.data;
              nSimulationConfig.simulationId = TEMPORARY_SIMULATION_KEY;
              dispatch(setSimulationConfig(nSimulationConfig));
              dispatch(setOperationaliseConfig(nSimulationConfig.opCreationConfig));
              dispatch(setReadOnly(false));
            }
          } else {
            // api call for cloning simulation
            const cloneApiResponse = await operationaliseV2ApiService.cloneOpSimulationConfig(
              op10zeId,
              simulationConfig.simulationId
            );
            if (cloneApiResponse.error) {
              notifyError("Error while cloning simulation");
              logger.error("OpSimulationList", "Error while cloning simulation", cloneApiResponse.message);
            } else {
              const nSimulationConfig = cloneApiResponse.data;
              nSimulationConfig.simulationId = TEMPORARY_SIMULATION_KEY;
              dispatch(setSimulationConfig(nSimulationConfig));
              dispatch(setOperationaliseConfig(nSimulationConfig.opCreationConfig));
              dispatch(setReadOnly(false));
            }
          }
        }
      } catch (error) {
        notifyError("Error while cloning simulation");
        logger.error("OpSimulationList", "Error while cloning simulation", error);
      }
    },
    [dispatch, isTemporarySimulation, notifyError, op10zeId, openCloneConfirmation]
  );

  const onTriggerDeleteSimulation = useCallback(
    (idx: number) => {
      deleteSimulationIdxRef.current = idx;
      openDeleteConfirmation();
    },
    [openDeleteConfirmation]
  );

  const onCancelDeleteCondition = useCallback(() => {
    deleteSimulationIdxRef.current = -1;
    closeDeleteConfirmation();
  }, [closeDeleteConfirmation]);

  const onSimulationSwitch = useCallback(
    (idx: number) => {
      if (isTemporarySimulation) {
        switchSimulationIdxRef.current = idx;
        openSwitchConfirmation();
      } else {
        const simulationConfigs = simulationConfigsRef.current;
        const selectedSimulation = simulationConfigs[idx];

        const cohortId = selectedSimulation.opCreationConfig.idProps?.secondary?.cohortId;
        if (cohortId) {
          const cohortDefinition: CohortConfig = {
            cohortId,
            name: ""
          };
          dispatch(setCohortConfig(cohortDefinition));
        } else {
          const cohortDefinition: CohortConfig = getAllCohortDefinition(
            selectedSimulation.opCreationConfig.idProps?.primary?.bizEntityTypeId
          );
          dispatch(setCohortConfig(cohortDefinition));
        }

        dispatch(setSimulationConfig(selectedSimulation));
        dispatch(setOperationaliseConfig(selectedSimulation.opCreationConfig));
        dispatch(setReadOnly(true));
      }
    },
    [dispatch, isTemporarySimulation, openSwitchConfirmation]
  );

  const onConfirmSwitchPrimaryOpCreationConfig = useCallback(() => {
    switchSimulationIdxRef.current = -1;

    const selectedSimulation = getSimulationConfigForPrimaryOpConfig(primaryOpCreationConfig);

    const simulationConfigs = simulationConfigsRef.current;
    const nSimulationConfigs = simulationConfigs.filter(sc => sc.simulationId !== TEMPORARY_SIMULATION_KEY);

    const cohortId = selectedSimulation.opCreationConfig.idProps?.secondary?.cohortId;
    if (cohortId) {
      const cohortDefinition: CohortConfig = {
        cohortId,
        name: ""
      };
      dispatch(setCohortConfig(cohortDefinition));
    } else {
      const cohortDefinition: CohortConfig = {
        cohortId: "",
        name: ""
      };
      dispatch(setCohortConfig(cohortDefinition));
    }

    dispatch(setSimulations(nSimulationConfigs));
    dispatch(setSimulationConfig(selectedSimulation));
    dispatch(setOperationaliseConfig(selectedSimulation.opCreationConfig));

    const readOnly = primaryConfigReadOnlyRef.current;
    dispatch(setReadOnly(readOnly));

    closeSwitchConfirmation();
  }, [closeSwitchConfirmation, dispatch, primaryOpCreationConfig]);

  const onPrimaryOpCreationConfigClick = useCallback(
    (readOnly = true) => {
      primaryConfigReadOnlyRef.current = readOnly;
      if (isTemporarySimulation) {
        switchSimulationIdxRef.current = primaryConfigIdx;
        openSwitchConfirmation();
      } else {
        onConfirmSwitchPrimaryOpCreationConfig();
      }
    },
    [isTemporarySimulation, onConfirmSwitchPrimaryOpCreationConfig, openSwitchConfirmation]
  );

  const onCancelSwitchSimulation = useCallback(() => {
    switchSimulationIdxRef.current = -1;
    closeSwitchConfirmation();
  }, [closeSwitchConfirmation]);

  const onConfirmSwitchSimulation = useCallback(() => {
    if (switchSimulationIdxRef.current > -1) {
      const simulationConfigs = simulationConfigsRef.current;

      const nSimulationConfigs = simulationConfigs.filter(sc => sc.simulationId !== TEMPORARY_SIMULATION_KEY);
      const selectedSimulation = simulationConfigs[switchSimulationIdxRef.current];

      dispatch(setSimulations(nSimulationConfigs));
      dispatch(setSimulationConfig(selectedSimulation));
      dispatch(setOperationaliseConfig(selectedSimulation.opCreationConfig));
      dispatch(setReadOnly(true));

      switchSimulationIdxRef.current = -1;
      closeSwitchConfirmation();
    } else if (switchSimulationIdxRef.current === primaryConfigIdx) {
      onConfirmSwitchPrimaryOpCreationConfig();
      closeCloneConfirmation();
    }
  }, [closeCloneConfirmation, closeSwitchConfirmation, dispatch, onConfirmSwitchPrimaryOpCreationConfig]);

  const onConfirmCloneSimulation = useCallback(() => {
    if (cloneSimulationConfigRef.current) {
      const simulationConfigs = simulationConfigsRef.current;

      const selectedSimulation = cloneSimulationConfigRef.current;
      const nSimulationConfig = cloneDeep(selectedSimulation);
      nSimulationConfig.simulationId = TEMPORARY_SIMULATION_KEY;

      const nSimulationConfigs = [...simulationConfigs, nSimulationConfig];

      dispatch(setSimulations(nSimulationConfigs));
      dispatch(setSimulationConfig(selectedSimulation));
      dispatch(setOperationaliseConfig(selectedSimulation.opCreationConfig));
      dispatch(setReadOnly(false));

      cloneSimulationConfigRef.current = null;
      closeCloneConfirmation();
    }
  }, [closeCloneConfirmation, dispatch]);

  const onPrimaryOpCreationConfigClone = useCallback(() => {
    const selectedSimulation = getSimulationConfigForPrimaryOpConfig(primaryOpCreationConfig);
    onSimulationClone({ ...selectedSimulation });
  }, [onSimulationClone, primaryOpCreationConfig]);

  const onPrimaryOpCreationConfigEdit = useCallback(() => {
    onPrimaryOpCreationConfigClick(false);
  }, [onPrimaryOpCreationConfigClick]);

  const onCancelCloneSimulation = useCallback(() => {
    cloneSimulationConfigRef.current = null;
    closeCloneConfirmation();
  }, [closeCloneConfirmation]);

  const simulationConfigs = simulationConfigsRef.current;

  const simulationsJsx = useMemo(() => {
    const fSimulationConfigs = isTemporarySimulation ? [...simulationConfigs, selectedSimulation] : simulationConfigs;
    return fSimulationConfigs.map((simulationConfig, idx) => {
      const { simulationId } = simulationConfig;

      const isTemporarySimulation = simulationId === TEMPORARY_SIMULATION_KEY;

      const isSelected = simulationId === selectedSimulation.simulationId;
      const onClick = isTemporarySimulation ? noOp : () => onSimulationSwitch(idx);
      const onDelete = isTemporarySimulation ? noOp : () => onTriggerDeleteSimulation(idx);
      const onClone = isTemporarySimulation ? noOp : () => onSimulationClone(simulationConfig);

      const key = [sessionId, idx, simulationId].join("_");

      return (
        <OpSimulationCard
          disableOpEdit={disableOpEdit}
          id={key}
          isSelected={isSelected}
          key={key}
          onClick={onClick}
          onClone={onClone}
          onDelete={onDelete}
          op10zeId={op10zeId}
          simulationConfig={simulationConfig}
        />
      );
    });
  }, [
    disableOpEdit,
    isTemporarySimulation,
    onSimulationClone,
    onSimulationSwitch,
    onTriggerDeleteSimulation,
    op10zeId,
    selectedSimulation,
    sessionId,
    simulationConfigs
  ]);

  const deleteConfirmationMessage = useMemo(
    () =>
      isDeleteSimulationInProgress ? (
        <LoadingSpinner titleText="Deleteing simulation..." />
      ) : (
        `Are you sure you want to delete "${selectedSimulation?.name}"?`
      ),
    [isDeleteSimulationInProgress, selectedSimulation?.name]
  );
  const switchConfirmationMessage = "Current changes will be lost. Are you sure you want to proceed?";

  return (
    <>
      <PrimaryOpConfigCard
        disableOpEdit={disableOpEdit}
        onClick={onPrimaryOpCreationConfigClick}
        onClone={onPrimaryOpCreationConfigClone}
        onEdit={onPrimaryOpCreationConfigEdit}
        onOverride={onOverride}
      />

      <div className="simulations-list">
        <div className="inc-label-common marginBt12">Simulations</div>
        {simulationsJsx}
        {!isTemporarySimulation && canCreate && (
          <IncButton
            className="marginTp12"
            color="link"
            disabled={disableOpEdit}
            iconType="iconText"
            onClick={onAddSimulation}
          >
            <IncFaIcon iconName="plus" />
            Add condition
          </IncButton>
        )}
      </div>

      {isDeleteConfirmationOpen && (
        <IncInModalConfirmation
          message={deleteConfirmationMessage}
          noOperation={isDeleteSimulationInProgress}
          onCancel={onCancelDeleteCondition}
          onConfirm={onDeleteSimulation}
        />
      )}

      {isSwitchConfirmationOpen && (
        <IncInModalConfirmation
          message={switchConfirmationMessage}
          onCancel={onCancelSwitchSimulation}
          onConfirm={onConfirmSwitchSimulation}
        />
      )}

      {isCloneConfirmationOpen && (
        <IncInModalConfirmation
          message={switchConfirmationMessage}
          onCancel={onCancelCloneSimulation}
          onConfirm={onConfirmCloneSimulation}
        />
      )}
    </>
  );
};

const getSimulationConfigForPrimaryOpConfig = (primaryOpCreationConfig: OpCreationConfig): SimulationConfig => ({
  createdBy: null,
  description: primaryOpCreationConfig.description,
  isPrimary: true,
  labels: {
    isPrimary: "true"
  },
  name: primaryOpCreationConfig.name,
  opCreationConfig: primaryOpCreationConfig,
  simulationId: PRIMARY_CONFIG_AS_SIMULATION_KEY
});

const primaryConfigIdx = -10;
