import { IncFaIcon } from "@inception/ui";
import React, { useMemo } from "react";
import { BaseWidgetImpl } from "../..";
import { WidgetCustomAction } from "../types";

type Props = {
  widget: BaseWidgetImpl;
  onMaximize?: () => void;
};

export const useViewMaximizeAction = (props: Props) => {
  const { onMaximize } = props;

  const viewMaximizeAction = useMemo<WidgetCustomAction>(() => {
    const actionComponent = (
      <IncFaIcon
        className="inc-cursor-pointer"
        iconName="expand"
        onClick={onMaximize}
      />
    );

    return {
      actionComponent,
      showInHeader: true,
      tooltipText: "Maximize"
    };
  }, [onMaximize]);

  return viewMaximizeAction;
};
