import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IncButton, IncFaIcon, IncModal } from "@inception/ui";
import { generateId, logger, useFetchEventTypesForAddWidget, useTimeRange } from "../../core";
import { KPI } from "../../services/api";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { DemoDataParams } from "../../services/api/explore";
import { VerticallyCenteredRow } from "../flex-components";
import { getDefaultWidgetConfigDto, getWidgetConfigFromDto } from "../../utils/ExploreUtils";
import { KPIState, KPIEditorSection } from "./types";
import { KPIEditor } from "./KPIEditor";
import { getKPIForKPIDTO } from "./utils";

interface Props {
  defEventType?: string;
  demoDataParams?: DemoDataParams;

  onAddKPI: (kpi: KPI) => void;

  modalProps?: {
    onClose: () => void;
  };
  skipCollapse?: boolean;
}

export const AddKPIEditor: FC<Props> = props => {
  const { onAddKPI, skipCollapse = false, defEventType, demoDataParams, modalProps } = props;

  const asModal = Boolean(modalProps);
  const { onClose } = modalProps || {};

  const [kpiState, setKPIState] = useState<KPIState>({
    isValid: false,
    errorsText: "",
    kpiDTO: {
      bizDataQuery: null,
      description: "",
      icon: {
        iconColor: "",
        iconName: "chart-mixed-up-circle-currency"
      },
      id: generateId(),
      name: "",
      relatedKpis: [],
      workflowDTOs: [],
      isSpikePositive: false,
      subType: null
    }
  });

  const [isFetching, setIsFetching] = useState(!defEventType);

  const { timeRange } = useTimeRange();
  const { fromMillis, toMillis } = useMemo(() => timeRangeUtils.getMillisFromTimeRange(timeRange), [timeRange]);

  const {
    error,
    exploreUserservices,
    fetchExploreUserservices,
    isError,
    isFetching: isUsFetching
  } = useFetchEventTypesForAddWidget(demoDataParams);

  useEffect(() => {
    if (!defEventType) {
      setTimeout(() => setIsFetching(false), 100);
      fetchExploreUserservices(fromMillis, toMillis);
    }
  }, [defEventType, fetchExploreUserservices, fromMillis, toMillis]);

  useEffect(() => {
    if (isError && !isUsFetching) {
      logger.error("AddKPIQueryEditor", "Error fetching userservices", error);
    }
  }, [error, isError, isUsFetching]);

  const eventTypeId = defEventType || exploreUserservices?.[0]?.entityId;

  useEffect(() => {
    if (eventTypeId) {
      const widgetConfigDto = getDefaultWidgetConfigDto(null, eventTypeId, null, null, null);
      const metricId = generateId();
      widgetConfigDto.dataDefinition.metrics = {
        [metricId]: {
          id: metricId,
          name: "",
          sourceType: "userServiceField",
          userServiceFieldMetricConfig: {
            aggregator: "count",
            eventFilters: {
              userServiceFilters: []
            },
            sliceSets: [
              {
                slices: []
              }
            ],
            userServiceField: null
          }
        }
      };

      setKPIState(prev => ({
        ...prev,
        kpiDTO: {
          ...prev.kpiDTO,
          bizDataQuery: {
            widgetConfig: getWidgetConfigFromDto(widgetConfigDto),
            sliceSpec: {
              selectorSpec: {
                filters: []
              },
              sliceSet: {
                slices: []
              },
              metricId
            },
            idProps: {
              primary: {
                bizEntityTypeId: "",
                eventTypes: {
                  userServiceInfo: [{ userServiceEntityId: eventTypeId }]
                }
              },
              secondary: {}
            }
          }
        }
      }));
    }
  }, [eventTypeId]);

  const eventTypesExist = defEventType ? true : exploreUserservices.length > 0;

  const { errorsText, isValid, kpiDTO } = kpiState;

  const onSaveInternal = useCallback(() => {
    const kpi = getKPIForKPIDTO(kpiDTO);
    const { widgetConfig, sliceSpec } = kpi.bizDataQuery;
    const { metricId } = sliceSpec || {};
    if (widgetConfig) {
      widgetConfig.name = kpi.name;
      const metric = widgetConfig.dataDefinition?.metrics?.[metricId];
      if (metric) {
        metric.name = kpi.name;
      }
    }
    onAddKPI(kpi);
  }, [kpiDTO, onAddKPI]);

  const saveAndCancelActionsJsx = (
    <VerticallyCenteredRow className={asModal ? "" : "flex-gap-12"}>
      <IncButton
        color="primary"
        disabled={!isValid}
        label="Add"
        onClick={onSaveInternal}
      />

      {asModal && (
        <IncButton
          color="secondary"
          label="Cancel"
          onClick={onClose}
        />
      )}

      {!isValid && Boolean(errorsText) && (
        <VerticallyCenteredRow className="flex-gap-8 inc-text-subtext-medium status-danger">
          <IncFaIcon iconName="exclamation-triangle" />
          {errorsText}
        </VerticallyCenteredRow>
      )}
    </VerticallyCenteredRow>
  );

  const isLoading = isFetching || isUsFetching;
  const editorJsx = (
    <div className="inc-flex-column flex-gap-12 width-100">
      {isLoading && (
        <VerticallyCenteredRow
          className="width-100"
          data-show-loader={true}
          style={{ height: 150 }}
        />
      )}
      {!isLoading && (
        <>
          {!eventTypesExist && (
            <VerticallyCenteredRow className="width-100">No Event Types exist</VerticallyCenteredRow>
          )}

          {eventTypesExist && Boolean(kpiDTO?.bizDataQuery) && (
            <KPIEditor
              kpiState={kpiState}
              onChange={setKPIState}
              sectionsToShow={sectionsToShow}
              skipCollapse={skipCollapse}
            />
          )}
        </>
      )}

      {!asModal && saveAndCancelActionsJsx}
    </div>
  );

  return (
    <>
      {asModal && (
        <IncModal
          className="inc-regular-modal"
          footerChildren={saveAndCancelActionsJsx}
          onClose={onClose}
          show
          showClose
          size="lg"
          titleText="Add Key Performance Indicator"
        >
          {editorJsx}
        </IncModal>
      )}
      {!asModal && editorJsx}
    </>
  );
};

const sectionsToShow: KPIEditorSection[] = ["meta", "preview", "query"];
