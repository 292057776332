import { UserServiceFieldWithMeta } from "../../services/api/explore";
import { FieldPickerUtils, isEntity, pluralizeWord } from "../../utils";
import { USFieldWidgetUtils } from "../../dashboard/widgets/USField/USFieldWidgetUtils";
import { USFieldInfoWithId, FieldOrMetricTypes, USMetricInfoWithId } from "./types";

export const getUsFieldInfoWithId = (usfm: UserServiceFieldWithMeta, userServiceName: string): USFieldInfoWithId => {
  const { userServiceField } = usfm;
  const { fieldName } = userServiceField;
  const id = FieldPickerUtils.getUserServiceFieldLabel(userServiceField);

  const isEventIDField = USFieldWidgetUtils.isEventIDField(fieldName);
  const isHasErrorField = USFieldWidgetUtils.isHasErrorField(fieldName);

  const shouldReturnEventTypeName = isEventIDField && userServiceName && !isEntity(userServiceName);
  const shouldReturnFailedEventTypeName = isHasErrorField && userServiceName && !isEntity(userServiceName);

  const displayEventTypeName = pluralizeWord(userServiceName || "");
  const displayName = shouldReturnEventTypeName
    ? `Total ${displayEventTypeName}`
    : shouldReturnFailedEventTypeName
      ? `Failed ${displayEventTypeName}`
      : USFieldWidgetUtils.getMetricName(fieldName, id);

  const isPredefinedField = USFieldWidgetUtils.isPredefinedField(usfm);

  return {
    id,
    displayName,
    usField: usfm,
    isPredefinedField,
    isMetricField: isHasErrorField || isEventIDField
  };
};

export const getLabel = (fieldType: FieldOrMetricTypes) => {
  switch (fieldType) {
    case FieldOrMetricTypes.BUSINESS_FIELDS:
      return "Business Fields";

    case FieldOrMetricTypes.PERFORMANCE_FIELDS:
      return "Performance Fields";

    case FieldOrMetricTypes.JOURNEY_FIELDS:
      return "Journey Fields";

    case FieldOrMetricTypes.METRICS:
      return "Custom Metrics";

    case FieldOrMetricTypes.USAGE_METRICS:
      return "Usage Metrics";

    default:
      return "Fields";
  }
};

export const getUsEntityFields = (
  usEntityFields: UserServiceFieldWithMeta[],
  userServiceName: string,
  propsNamesToSkip: string[] = []
) => {
  const fields: USFieldInfoWithId[] = [];

  usEntityFields.forEach(usfm => {
    const { userServiceField } = usfm;
    const { entityField } = userServiceField;
    const skipProp = propsNamesToSkip.includes(entityField?.propName);

    if (!skipProp) {
      fields.push(getUsFieldInfoWithId(usfm, userServiceName));
    }
  });

  return fields;
};

export const addToFieldsSelection = (fields: USFieldInfoWithId[], selection: Record<string, boolean>) => {
  let selCount = 0;
  (fields || []).forEach(field => {
    const { id } = field;
    const isSelected = selection[id] || false;
    selCount += Number(isSelected);
  });

  return selCount;
};

export const addToMetricsSelection = (metrics: USMetricInfoWithId[], selection: Record<string, boolean>) => {
  let selCount = 0;
  (metrics || []).forEach(({ metricId }) => {
    const isSelected = selection[metricId] || false;
    selCount += Number(isSelected);
  });

  return selCount;
};
