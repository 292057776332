import { createAction } from "@reduxjs/toolkit";
import {
  OpCreationConfig,
  WindowTriggerCondition,
  OpSchedule,
  OpThreshold,
  UIIntegrationActionConfig,
  BizActionConfig,
  Op10zeStage,
  SuppressionConfigDef,
  OpTriageConfig,
  OpStartTrigger,
  WhatsNewConfig,
  OpMetaDataV2,
  OpAnalysisCreationConfig
} from "../../services/api/operationalise";
import {
  TimeObj,
  BizDataQuery,
  CohortConfig,
  UserServiceFieldSlice,
  ImpactedWidget,
  MetricUserServiceFilters,
  ExploreEntityFilter
} from "../../services/api/explore";
import { OpMode } from "../types";
import {
  OpContext,
  SetActionConfigPayload,
  SimulationConfig,
  SetSimulationStatePayload,
  OpState,
  SetActionSnoozePayload,
  SetActionCategoriesPayload,
  SetSeriesSnoozePayload
} from "./types";

import {
  SET_OPERATIONALISE_CONTEXT,
  SET_OPERATIONALISE_CONFIG,
  SET_END_TRIGGER,
  SET_ROLLING_FREQ,
  SET_ROLLING_FUNCTION,
  SET_SCHEDULE,
  SET_START_TRIGGER,
  SET_BIZ_DATA_QUERY,
  SET_THRESHOLD,
  SET_ENTITY_AGGREGATED,
  SET_ACTION_CONFIG,
  DELETE_ACTION_CONFIG,
  SET_UI_INTEGRATION_CONFIGS_CONTEXT,
  SET_PARTIAL_OPERATIONALISE_CONTEXT,
  DISABLE_ACTION_CONFIG,
  ENABLE_ACTION_CONFIG,
  SET_TEMPORARY_ACTION,
  DELETE_TEMPORARY_ACTION,
  SET_TEMPORARY_ACTIONS,
  SET_COHORT_CONFIG,
  RESET_COHORT_CONFIG,
  SET_STAGE,
  SET_IMPACT_WIDGETS,
  SET_DIAGNOSTIC_FIELDS,
  SET_SUPPRESSION_CONFIGS,
  SET_SIMULATIONS,
  SET_HISTORY,
  SET_SIMULATION_CONFIG,
  SET_SIMULATION_CONFIG_NAME,
  SET_READ_ONLY,
  SET_OP10ZE_ID,
  SET_IS_DRAFT,
  SET_PRIMARY_OP_CREATION_CONFIG,
  SET_SIMULATION_STATE,
  SET_OP_TRIAGE_CONFIG,
  UPDATE_ENTITY_FILTERS,
  UPDATE_EVENT_FILTERS,
  SET_OPERATIONALISE_STATE,
  SNOOZE_ACTION_CONFIG,
  SNOOZE_SERIES_CONFIG,
  SET_ACTION_CATEGORIES,
  SET_WHATS_NEW_CONFIG,
  SET_SAVE_OP_CREATION_CONFIG,
  SET_OP_MODE,
  SET_OP_META_DATA,
  SET_OP_ANALYSIS_CONFIG,
  SET_SHOULD_RELOAD_EDITORS
} from "./action_names";

export const setOperationaliseContext = createAction<OpContext>(SET_OPERATIONALISE_CONTEXT);
export const setPartialOperationaliseContext = createAction<Partial<OpContext>>(SET_PARTIAL_OPERATIONALISE_CONTEXT);
export const setUIIntegrationConfigsContext = createAction<UIIntegrationActionConfig[]>(
  SET_UI_INTEGRATION_CONFIGS_CONTEXT
);
export const setOperationaliseConfig = createAction<OpCreationConfig>(SET_OPERATIONALISE_CONFIG);
export const setOpAnalysisConfig = createAction<OpAnalysisCreationConfig>(SET_OP_ANALYSIS_CONFIG);
export const setShouldReloadEditors = createAction<boolean>(SET_SHOULD_RELOAD_EDITORS);

export const updateOperationaliseState = createAction<Partial<OpState>>(SET_OPERATIONALISE_STATE);

export const setOpMetaData = createAction<OpMetaDataV2>(SET_OP_META_DATA);
export const setSchedule = createAction<OpSchedule>(SET_SCHEDULE);
export const setBizDataQuery = createAction<BizDataQuery>(SET_BIZ_DATA_QUERY);
export const setRollingFunction = createAction<string>(SET_ROLLING_FUNCTION);
export const setRollingFreq = createAction<TimeObj>(SET_ROLLING_FREQ);
export const setThreshold = createAction<OpThreshold>(SET_THRESHOLD);
export const setStartTrigger = createAction<OpStartTrigger>(SET_START_TRIGGER);
export const setEndTrigger = createAction<WindowTriggerCondition>(SET_END_TRIGGER);
export const setEntityAggregated = createAction<boolean>(SET_ENTITY_AGGREGATED);
export const setBizAction = createAction<SetActionConfigPayload>(SET_ACTION_CONFIG);
export const deleteBizAction = createAction<string>(DELETE_ACTION_CONFIG);
export const disableBizAction = createAction<string>(DISABLE_ACTION_CONFIG);
export const enableBizAction = createAction<string>(ENABLE_ACTION_CONFIG);
export const snoozeBizAction = createAction<SetActionSnoozePayload>(SNOOZE_ACTION_CONFIG);
export const snoozeSeries = createAction<SetSeriesSnoozePayload>(SNOOZE_SERIES_CONFIG);
export const setSuppressions = createAction<SuppressionConfigDef[]>(SET_SUPPRESSION_CONFIGS);
export const setWhatsNewConfig = createAction<WhatsNewConfig>(SET_WHATS_NEW_CONFIG);

export const setSimulations = createAction<SimulationConfig[]>(SET_SIMULATIONS);
export const setSimulationConfig = createAction<SimulationConfig>(SET_SIMULATION_CONFIG);
export const setSimulationConfigName = createAction<string>(SET_SIMULATION_CONFIG_NAME);
export const setSimulationState = createAction<SetSimulationStatePayload>(SET_SIMULATION_STATE);

export const setImpactWidgets = createAction<ImpactedWidget[]>(SET_IMPACT_WIDGETS);
export const setDiagnosticFields = createAction<UserServiceFieldSlice[]>(SET_DIAGNOSTIC_FIELDS);

export const setTemporaryActions = createAction<Record<string, BizActionConfig>>(SET_TEMPORARY_ACTIONS);
export const setTemporaryAction = createAction<SetActionConfigPayload>(SET_TEMPORARY_ACTION);
export const deleteTemporaryAction = createAction<string>(DELETE_TEMPORARY_ACTION);

export const setCohortConfig = createAction<CohortConfig>(SET_COHORT_CONFIG);
export const resetCohortConfig = createAction(RESET_COHORT_CONFIG);

export const setStage = createAction<Op10zeStage>(SET_STAGE);
export const setHistory = createAction<OpCreationConfig["history"]>(SET_HISTORY);

export const setReadOnly = createAction<boolean>(SET_READ_ONLY);
export const setOp10zeId = createAction<string>(SET_OP10ZE_ID);
export const setIsDraft = createAction<boolean>(SET_IS_DRAFT);
export const setOpMode = createAction<OpMode>(SET_OP_MODE);

export const setPrimaryOpCreationConfig = createAction<OpCreationConfig>(SET_PRIMARY_OP_CREATION_CONFIG);
export const setSaveOpCreationConfig = createAction<OpCreationConfig>(SET_SAVE_OP_CREATION_CONFIG);

export const setOpTriageConfig = createAction<OpTriageConfig>(SET_OP_TRIAGE_CONFIG);

export const updateEntityFilters = createAction<ExploreEntityFilter[]>(UPDATE_ENTITY_FILTERS);
export const updateEventFilters = createAction<MetricUserServiceFilters>(UPDATE_EVENT_FILTERS);

export const setActionCategories = createAction<SetActionCategoriesPayload>(SET_ACTION_CATEGORIES);
