import React, { useMemo, useCallback, useState, useRef } from "react";
import { isEqual, uniqBy } from "lodash";
import {
  UserServiceFilterExpression,
  FieldPickerContextDTO,
  ConditionWithPickerType,
  UserServiceFilterExpressionTree,
  LogicalOperator,
  DemoDataParams
} from "../../services/api/explore";
import { ExploreQueryUtils, FieldPickerUtils } from "../../utils";
import { ConditionsBuilder } from "..";
import { ConditionWithPickerTypeExpressionTree } from "../explore";

interface Props {
  fieldPickerContext: FieldPickerContextDTO;
  skipHeader?: boolean;

  filters?: UserServiceFilterExpression[];
  filterExpressionTree?: UserServiceFilterExpressionTree;
  useExpressionTree?: boolean;
  onChange: (
    filterExpressions: UserServiceFilterExpression[],
    expressionTree: UserServiceFilterExpressionTree,
    filtersValid: boolean
  ) => void;
  onCancel: () => void;
  demoDataParams: Partial<DemoDataParams>;

  children: (onSave: () => void, onCancel: () => void, filtersValid: boolean) => React.ReactNode;
}

export const EventCriteriaEditor: React.FC<Props> = props => {
  const {
    fieldPickerContext,
    filters: eFilters,
    filterExpressionTree: eFilterExpressionTree,
    onChange,
    onCancel,
    useExpressionTree = false,
    skipHeader = false,
    demoDataParams,
    children
  } = props;

  const filters = useMemo<ConditionWithPickerType[]>(() => {
    if (eFilters && eFilters.length > 0 && !useExpressionTree) {
      return ExploreQueryUtils.getConditionFilters(eFilters);
    }
    return [null];
  }, [eFilters, useExpressionTree]);

  const filtersExpressionTree = useMemo<ConditionWithPickerTypeExpressionTree>(() => {
    if (eFilterExpressionTree) {
      return ExploreQueryUtils.getAdvancedConditionFilters(eFilterExpressionTree);
    }

    if (useExpressionTree && eFilters && eFilters.length > 0) {
      return {
        filterNodes: eFilters.map(ef => ({
          expression: ExploreQueryUtils.getConditionFilters([ef])[0]
        })),
        logicalOperator: LogicalOperator.AND
      };
    }

    return {
      filterNodes: [],
      logicalOperator: LogicalOperator.AND
    };
  }, [eFilterExpressionTree, eFilters, useExpressionTree]);

  const [tempFilters, setTempFilters] = useState(filters);
  const [tempFilterExprTree, setTempFilterExprTree] = useState(filtersExpressionTree);

  const filtersValidRef = useRef(true);

  const onFiltersChange = useCallback(
    (filters: ConditionWithPickerType[], filtersValid: boolean, filterExpr: ConditionWithPickerTypeExpressionTree) => {
      filtersValidRef.current = filtersValid;
      setTempFilters(filters);
      setTempFilterExprTree(filterExpr);
    },
    []
  );

  const onSave = useCallback(() => {
    if (useExpressionTree) {
      const eFilterExprTree = ExploreQueryUtils.getUserServiceFilterExpressionTree(tempFilterExprTree);
      onChange(null, eFilterExprTree, filtersValidRef.current);
    } else {
      const eFilters: ConditionWithPickerType[] = tempFilters.map(filter => {
        if (!ExploreQueryUtils.validateFilter(filter)) {
          return undefined;
        }
        return filter;
      });

      const validFilters = eFilters
        .filter(x => x !== undefined)
        .map(x => ExploreQueryUtils.getUserServiceFilterExpression(x));
      const uniqFilters = uniqBy(
        validFilters,
        filter =>
          `${FieldPickerUtils.getUserServiceFieldLabel(filter.field)}-${filter.operator}-${filter.value ? filter.value : filter.values.join(",")}`
      );
      if (!isEqual(uniqFilters, filters)) {
        onChange(uniqFilters, null, filtersValidRef.current);
      }
    }
  }, [filters, onChange, tempFilterExprTree, tempFilters, useExpressionTree]);

  const onCancelClick = useCallback(() => {
    setTempFilterExprTree(filtersExpressionTree);
    setTempFilters(filters);
    onCancel();
  }, [filtersExpressionTree, filters, onCancel]);

  return (
    <>
      {!skipHeader && <span className="inc-text-subtext">Add your criteria</span>}
      <div className="event-filters-container marginTp12">
        <ConditionsBuilder
          demoDataParams={demoDataParams}
          fieldPickerContext={fieldPickerContext}
          fieldTypes={["userServiceField"]}
          filterExpressionTree={filtersExpressionTree}
          filterJoin="AND"
          filters={filters}
          label="Where"
          labelAlign="horizontal"
          onChange={onFiltersChange}
          useExpressionTree={useExpressionTree}
        />
      </div>

      {children(onSave, onCancelClick, filtersValidRef.current)}
    </>
  );
};
