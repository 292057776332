import { IncFaIcon, IncSelectOption, IncToolTip, useRenderOnVisibility } from "@inception/ui";
import { pick, uniqBy } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { LoadingSpinner, VerticallyCenteredRow } from "../..";
import { TimeRange, generateId, shouldExcludeTag, useTimeRange } from "../../../core";
import {
  BizFieldPredicate,
  ExploreEntityFilter,
  getDisplayTagNameForUSFieldSlice,
  ImpactedWidgetList,
  IncidentTimelineResponse,
  TriageConfigDTO,
  UserServiceFilterExpression,
  TriagePathConfigType,
  UserServiceFilterList
} from "../../../services/api/explore";
import { TimeSeries, OpSchema } from "../../../services/api/operationalise";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { IncidentTimelineWrapper } from "../IncidentTimeline";
import { useLocalToGlobalFilters } from "../filters/useLocalToGlobalFilters";
import { INCIDENT_STATUS_KEY, IncidentTimelineContext } from "../types";
import { IncidentDataResponseItem } from "../../../services/api/CommonAlerts";
import { DrilldownSubRendererProps } from "../field-drilldown/types";
import Op10zeWidgetImpl from "../../../dashboard/widgets/Operationalize/models/impl";
import { StandAloneWidget } from "../../StandaloneWidget";
import Op10zeWidgetModel from "../../../dashboard/widgets/Operationalize/models/model";
import { getCompareSeriesOverrides } from "../../incidentTimeSeries/TimeSeriesWidgetHelper";
import { Op10zeDataQuery } from "../../../services/datasources/operationalize/types";
import { TimeRangeSelector } from "../../time-range";
import { useFetchIncidentTimeline } from "../IncidentTimeline/hooks";
import appConfig from "../../../../appConfig";
import { ImpactWidgetWrapper } from "./ImpactWidgetWrapper";
import { TimelineHeaderContext, TimelineHeader } from "./TimelineHeader";

export interface TimelineWithImpactWidgetProps {
  entityType: string;
  cohortId: string;
  widgetId: string;
  opConfigId: string;
  incidentId: string;
  triageFetchLabels: Record<string, string>;
  isLoading: boolean;
  opSchema: OpSchema;
  impactedWidgetsLists: ImpactedWidgetList[];
  entityFilters: ExploreEntityFilter[];
  eventFieldFilters: UserServiceFilterList;

  hideImpactedWidgets?: boolean;
  hideIncidentTimeline?: boolean;
  defaultContext?: IncidentTimelineContext;
  disableContextSelection?: boolean;
  extCtxOptions?: Array<IncSelectOption<IncidentTimelineContext>>;
  op10zeIncidentData?: IncidentDataResponseItem;

  onAddEntityFilter?: (entityFilter: BizFieldPredicate) => void;
  onAddEventFilter?: (eventFilter: UserServiceFilterExpression) => void;

  drilldownProps?: DrilldownSubRendererProps;
  generateDemoData?: boolean;
  showImpactWidgetTable?: boolean;
  hideTimeRange?: false;
  timeRange?: TimeRange;
}

export const IncidentTimelineAndImpactWidget: FC<TimelineWithImpactWidgetProps> = props => {
  const {
    cohortId,
    entityType,
    incidentId,
    isLoading,
    triageFetchLabels,
    widgetId,
    opConfigId,
    opSchema,
    impactedWidgetsLists,
    entityFilters,
    eventFieldFilters,
    hideImpactedWidgets,
    hideIncidentTimeline,
    defaultContext,
    onAddEntityFilter: pOnAddEntityFilter,
    onAddEventFilter: pOnAddEventFilter,
    op10zeIncidentData,
    disableContextSelection = false,
    drilldownProps,
    extCtxOptions,
    generateDemoData,
    showImpactWidgetTable = false,
    hideTimeRange,
    timeRange: pTimeRange
  } = props;

  const { ref, wasVisibleOnce } = useRenderOnVisibility();

  const { timeRange: gTimeRange } = useTimeRange();
  const [timeRange, setTimeRange] = useState(pTimeRange || gTimeRange);
  useEffect(() => {
    if (pTimeRange) {
      setTimeRange(pTimeRange);
    }
  }, [pTimeRange]);
  const [impactWidgetTimeRange, setImpactWidgetTimeRange] = useState(timeRange);

  const compareTimeRange = useMemo(() => timeRangeUtils.getCompareTimeRangeFromRaw(timeRange, "1d"), [timeRange]);

  useEffect(() => {
    setImpactWidgetTimeRange(timeRange);
  }, [timeRange]);

  const {
    data: eIncidentTimelineResponse,
    error: incidentTimelineError,
    isError: isIncidentTimelineError,
    isFetching: isIncidentTimelineFetching,
    refetch: fetchIncidentTimeline
  } = useFetchIncidentTimeline(
    incidentId,
    1,
    eventFieldFilters,
    entityFilters,
    timeRange.from.valueOf(),
    timeRange.to.valueOf(),
    generateDemoData
  );

  useEffect(() => {
    if (incidentId) {
      fetchIncidentTimeline();
    }
  }, [fetchIncidentTimeline, incidentId]);

  const shouldShowReset = useMemo(() => {
    const { fromMillis: gFrom, toMillis: gTo } = timeRangeUtils.getMillisFromTimeRange(gTimeRange);

    const { fromMillis: lFrom, toMillis: lTo } = timeRangeUtils.getMillisFromTimeRange(timeRange);

    return gFrom !== lFrom || gTo !== lTo;
  }, [gTimeRange, timeRange]);

  const resetTimeRange = useCallback(() => {
    setTimeRange(gTimeRange);
  }, [gTimeRange]);

  const impactedWidgetsConfigs = useMemo<TriageConfigDTO[]>(
    () =>
      impactedWidgetsLists.map(iwList => ({
        id: "",
        idProps: null,
        name: "",
        triageConfig: null,
        type: TriagePathConfigType.impactedWidgets,
        impactedWidgets: iwList,
        triageLinkLevelEntity: null,
        triageLinkLevelEvent: null
      })),
    [impactedWidgetsLists]
  );

  const [timelineContext, setTimelineContext] = useState<IncidentTimelineContext>(defaultContext);

  const updateTimelineContext = useCallback(
    (ctxt: TimelineHeaderContext) => {
      setTimelineContext({
        ...ctxt,
        opConfigId,
        timeRange
      });
    },
    [opConfigId, timeRange]
  );

  useEffect(() => {
    setTimelineContext(prev => ({
      ...prev,
      timeRange
    }));
  }, [timeRange]);

  const { alertingEventFields } = opSchema;

  const [incidentTimelineResponse, setIncidentTimelineResponse] = useState<State>({
    data: null,
    error: null,
    isFetching: false
  });

  useEffect(() => {
    if (eIncidentTimelineResponse) {
      setIncidentTimelineResponse({
        data: eIncidentTimelineResponse,
        error: "",
        isFetching: false
      });
    } else if (incidentTimelineError || isIncidentTimelineError) {
      setIncidentTimelineResponse({
        data: null,
        error: incidentTimelineError,
        isFetching: false
      });
    } else if (isIncidentTimelineFetching) {
      setIncidentTimelineResponse({
        data: null,
        error: "",
        isFetching: true
      });
    }
  }, [eIncidentTimelineResponse, incidentTimelineError, isIncidentTimelineError, isIncidentTimelineFetching]);

  const { data, error, isFetching } = incidentTimelineResponse;

  const { accessorHeaderMap, tagsData, lookupData, entityTags } = useMemo(() => {
    const accessorHeaderMap: Record<string, string> = {};
    const entityTags: string[] = [];

    let lookupData: Record<string, string> = {};
    let tagsData: Array<Record<string, string>> = [];

    if (data) {
      const { bins, alert } = data;

      const activeCountMap: Record<string, number> = {};
      const stoppedCountMap: Record<string, number> = {};

      bins.forEach(bin => {
        const { activeMTSLines, stoppedNowMTSLines } = bin;

        activeMTSLines.forEach(({ ts }) => {
          const tags = getTagsObj(ts);

          const key = getKeyForTags(tags);
          const activeCount = activeCountMap[key] || 0;
          activeCountMap[key] = activeCount + 1;

          tagsData.push(tags);
        });

        stoppedNowMTSLines.forEach(({ ts }) => {
          const tags = getTagsObj(ts);

          const key = getKeyForTags(tags);
          const closedCount = stoppedCountMap[key] || 0;
          stoppedCountMap[key] = closedCount + 1;

          tagsData.push(tags);
        });
      });

      tagsData = uniqBy(tagsData, getKeyForTags);

      tagsData.forEach(tags => {
        const key = getKeyForTags(tags);
        const activeCount = activeCountMap[key] || 0;
        const stoppedCount = stoppedCountMap[key] || 0;

        const isActive = activeCount && !stoppedCount;
        tags[INCIDENT_STATUS_KEY] = isActive ? "active" : stoppedCount ? "stopped" : "NA";
      });

      alertingEventFields.slices.forEach(slice => {
        const { tagName } = slice;

        const { displayTagName, isEntityTag } = getDisplayTagNameForUSFieldSlice(slice);

        isEntityTag && entityTags.push(tagName);
        accessorHeaderMap[tagName] = displayTagName;
      });

      lookupData = {
        ...(alert?.lookupData || {})
      };
    }

    return {
      accessorHeaderMap,
      tagsData,
      lookupData,
      entityTags
    };
  }, [alertingEventFields.slices, data]);

  const updateState = useCallback((data: IncidentTimelineResponse, isFetching: boolean, error: string) => {
    setIncidentTimelineResponse({
      data,
      error,
      isFetching
    });
  }, []);

  const { addLocalEntityFilter, addLocalEventFilter, bizFieldPredicates, filtersJsx, usFilterExprs } =
    useLocalToGlobalFilters({
      addToGlobalEntityFilters: pOnAddEntityFilter,
      addToGlobalEventFilters: pOnAddEventFilter,
      lookupData
    });

  const fEntityFilters = useMemo<ExploreEntityFilter[]>(() => {
    const predicates = [...(entityFilters?.[0]?.filters || [])];
    predicates.push(...bizFieldPredicates);
    return [
      {
        filters: predicates
      }
    ];
  }, [bizFieldPredicates, entityFilters]);

  const fEventFieldFilters = useMemo<UserServiceFilterList>(() => {
    const filExprs = [...(eventFieldFilters?.userServiceFilters?.[0]?.userServiceFilterExpressions || [])];
    filExprs.push(...usFilterExprs);
    return {
      userServiceFilters: [
        {
          userServiceFilterExpressions: filExprs
        }
      ]
    };
  }, [eventFieldFilters, usFilterExprs]);

  const op10zeWidgetImpl = useMemo<Op10zeWidgetImpl>(() => {
    const { tags, title, frequency, lookBack } = timelineContext?.monitoredSeriesContext || {};
    const id = generateId();

    const model: Op10zeWidgetModel = {
      datasource: appConfig.defaultOp10zeDsName,
      id,
      queries: [
        {
          payload: {
            selectorSpec: {
              filters: [
                {
                  tags
                }
              ]
            },
            forCount: false,
            frequency,
            lookBack
          },
          previewPayload: null,
          opId: opConfigId,
          simulationId: null,
          incidentId,
          refId: "A",
          query: ""
        } as Op10zeDataQuery
      ],
      title,
      type: "operationalize",
      mode: "data",
      seriesOverrides: getCompareSeriesOverrides(),
      chartType: "spline",
      meta: {
        compare: false,
        edit: false,
        hideHeader: true,
        resizable: false,
        hideActions: true
      }
    };

    const impl = new Op10zeWidgetImpl(model);
    return impl;
  }, [incidentId, opConfigId, timelineContext?.monitoredSeriesContext]);

  const shouldRenderTimeline = timelineContext?.type === "impactedWidget" || timelineContext?.type === "incident";
  const shouldRenderOp10zeWidget = timelineContext?.type === "monitoredSeries";

  if (showImpactWidgetTable) {
    return (
      <>
        {isLoading && (
          <LoadingSpinner
            className="loading-state inc-text-subtext-medium"
            position="center"
          />
        )}
        {!isFetching && !hideImpactedWidgets && (
          <ImpactWidgetWrapper
            accessorHeaderMap={accessorHeaderMap}
            cohortId={cohortId}
            drilldownProps={drilldownProps}
            entityFilters={fEntityFilters}
            entityTags={entityTags}
            entityType={entityType}
            eventFieldFilters={fEventFieldFilters}
            hideGanttChartRow
            impactedWidgetsConfigs={impactedWidgetsConfigs}
            incidentId={incidentId}
            isLoading={isLoading}
            lookupData={lookupData}
            onAddEntityFilter={addLocalEntityFilter}
            onAddEventFilter={addLocalEventFilter}
            op10zeIncidentData={op10zeIncidentData}
            opConfigId={opConfigId}
            opSchema={opSchema}
            tagsData={tagsData}
            timeRange={impactWidgetTimeRange}
            triageFetchLabels={triageFetchLabels}
            widgetId={widgetId}
          />
        )}
      </>
    );
  }

  return (
    <div
      className="incident-timeline-and-impact-widget"
      ref={ref}
    >
      <VerticallyCenteredRow className="incident-timeline-and-impact-widget--header">
        Incident Timeline
      </VerticallyCenteredRow>

      {wasVisibleOnce && (
        <div className="incident-timeline-and-impact-widget--content inc-flex-column flex-gap-12">
          {isLoading && (
            <LoadingSpinner
              className="loading-state inc-text-subtext-medium"
              position="center"
            />
          )}
          {!isLoading && (
            <>
              {!error && (
                <>
                  {!op10zeIncidentData ? (
                    <TimelineHeader
                      accessorHeaderMap={accessorHeaderMap}
                      addlFilterJsx={filtersJsx}
                      defContext={timelineContext}
                      disableContextSelection={disableContextSelection}
                      entityTags={entityTags}
                      error={error}
                      extCtxOptions={extCtxOptions}
                      hideTimeRange={hideTimeRange}
                      impactedWidgetsList={impactedWidgetsLists}
                      incidentId={incidentId}
                      isLoading={isLoading}
                      lookupData={lookupData}
                      onContextChange={updateTimelineContext}
                      resetTimeRange={resetTimeRange}
                      setTimeRange={setTimeRange}
                      shouldShowReset={shouldShowReset}
                      tagsData={tagsData}
                      timeRange={timeRange}
                    />
                  ) : (
                    <div className="inc-flex-row inc-flex-self-end paddingLt24 paddingRt24">
                      {shouldShowReset && (
                        <IncToolTip
                          placement="top"
                          titleText="Set page time range"
                        >
                          <VerticallyCenteredRow
                            className="inc-cursor-pointer status-info marginRt16"
                            onClick={resetTimeRange}
                          >
                            <IncFaIcon
                              className="marginRt4"
                              iconName="chevron-left"
                              style={{ transform: "scale(0.75)" }}
                            />
                            <VerticallyCenteredRow>Back</VerticallyCenteredRow>
                          </VerticallyCenteredRow>
                        </IncToolTip>
                      )}
                      <TimeRangeSelector
                        buttonDisplay
                        onTimeSelect={setTimeRange}
                        timeRange={timeRange}
                      />
                    </div>
                  )}

                  {shouldRenderTimeline && (
                    <IncidentTimelineWrapper
                      accessorHeaderMap={accessorHeaderMap}
                      entityFilters={fEntityFilters}
                      eventFieldFilters={fEventFieldFilters}
                      generateDemoData={generateDemoData}
                      hideHeader
                      hideTimeline={hideIncidentTimeline}
                      onDataFetch={updateState}
                      timelineContext={timelineContext}
                      updateTimeRangeFromBins={setImpactWidgetTimeRange}
                    />
                  )}

                  {shouldRenderOp10zeWidget && (
                    <StandAloneWidget
                      compareTimeRange={compareTimeRange}
                      timeRange={timeRange}
                      widget={op10zeWidgetImpl}
                    />
                  )}

                  {!isFetching && !hideImpactedWidgets && (
                    <ImpactWidgetWrapper
                      accessorHeaderMap={accessorHeaderMap}
                      cohortId={cohortId}
                      drilldownProps={drilldownProps}
                      entityFilters={fEntityFilters}
                      entityTags={entityTags}
                      entityType={entityType}
                      eventFieldFilters={fEventFieldFilters}
                      impactedWidgetsConfigs={impactedWidgetsConfigs}
                      incidentId={incidentId}
                      isLoading={isLoading}
                      lookupData={lookupData}
                      onAddEntityFilter={addLocalEntityFilter}
                      onAddEventFilter={addLocalEventFilter}
                      op10zeIncidentData={op10zeIncidentData}
                      opConfigId={opConfigId}
                      opSchema={opSchema}
                      tagsData={tagsData}
                      timeRange={impactWidgetTimeRange}
                      triageFetchLabels={triageFetchLabels}
                      widgetId={widgetId}
                    />
                  )}
                </>
              )}
              {Boolean(error) && <div className="error-message">{error}</div>}
            </>
          )}
        </div>
      )}
    </div>
  );
};

type State = {
  data: IncidentTimelineResponse;
  isFetching: boolean;
  error: string;
};

const getKeyForTags = (tags: Record<string, string>) =>
  Object.keys(tags).reduce((str, k) => (str += `${k}-${tags[k]}`), "");

const getTagsObj = (ts: TimeSeries) => {
  const keys = Object.keys(ts.tags);
  const includeKeys = keys.filter(k => !shouldExcludeTag(k));
  const tags = pick(ts.tags, includeKeys);

  return tags;
};
