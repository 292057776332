import React, { FC, useState, useCallback, useEffect, useMemo } from "react";
import {
  IncClickAwayPopper,
  IncDurationSelect,
  IncDuration,
  durationToDurationString,
  RelativeDurationType,
  IncRadioButton,
  IncFaIcon
} from "@inception/ui";
import { VerticallyCenteredRow } from "../../../../components";
import { AUTO_DS_INTERVAL } from "../../utils";

interface Props {
  dsIntervalStr: string;
  onChange: (nDsInterval: string) => void;

  anchorRef: React.RefObject<HTMLElement>;
}

export const DSIntervalEditor: FC<Props> = props => {
  const { dsIntervalStr: pDsIntervalStr, onChange, anchorRef } = props;

  const [dsIntervalStr, setDsIntervalStr] = useState(pDsIntervalStr);

  const isAutoDS = dsIntervalStr === AUTO_DS_INTERVAL;

  useEffect(() => {
    setDsIntervalStr(pDsIntervalStr);
  }, [pDsIntervalStr]);

  const [open, setOpen] = useState(false);

  const openPopper = useCallback(() => setOpen(true), []);
  const closePopper = useCallback(() => setOpen(false), []);

  const setCustomInterval = useCallback(() => {
    setDsIntervalStr("1m");
  }, []);

  const setAutoInterval = useCallback(() => {
    setDsIntervalStr(AUTO_DS_INTERVAL);
  }, []);

  const updateInterval = useCallback((dur: IncDuration) => {
    const dsIntervalStr = durationToDurationString(dur);
    setDsIntervalStr(dsIntervalStr);
  }, []);

  const duration = useMemo<IncDuration>(() => {
    if (dsIntervalStr !== AUTO_DS_INTERVAL) {
      const magnitude = parseInt(dsIntervalStr, 10);
      const unit = dsIntervalStr.replace(magnitude.toString(), "");

      const relDurUnit = durationMap[unit];

      if (relDurUnit !== undefined) {
        return {
          duration: magnitude,
          durationType: relDurUnit
        };
      } else {
        window.setTimeout(() => {
          setDsIntervalStr("1m");
        });
      }
    }
  }, [dsIntervalStr]);

  const saveDsIntervalStr = useCallback(() => {
    onChange(dsIntervalStr);
    closePopper();
  }, [closePopper, dsIntervalStr, onChange]);

  return (
    <>
      <VerticallyCenteredRow
        className="inc-cursor-pointer"
        onClick={openPopper}
      >
        <IncFaIcon iconName="clock" />
        Downsample
      </VerticallyCenteredRow>

      <IncClickAwayPopper
        anchorEl={anchorRef.current}
        onClickAway={saveDsIntervalStr}
        overlay
        placement="auto"
        show={open}
      >
        <div className="ds-interval-editor">
          <div className="inc-text-subtext-medium marginBt8">Downsample</div>

          <IncRadioButton
            checked={isAutoDS}
            label="Auto"
            onChange={setAutoInterval}
          />

          <IncRadioButton
            checked={!isAutoDS}
            className="marginBt6"
            label="Custom"
            onChange={setCustomInterval}
          />

          {!isAutoDS && (
            <IncDurationSelect
              duration={duration}
              includeSeconds={false}
              onChange={updateInterval}
            />
          )}
        </div>
      </IncClickAwayPopper>
    </>
  );
};

const durationMap: Record<string, RelativeDurationType> = {
  m: RelativeDurationType.MINUTES,
  h: RelativeDurationType.HOURS,
  d: RelativeDurationType.DAYS,
  w: RelativeDurationType.WEEKS,
  M: RelativeDurationType.MONTHS
};
