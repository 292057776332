import React, { FC, useState, useCallback, useEffect, useMemo } from "react";
import { IncModal, IncFaIcon, IncButton } from "@inception/ui";
import { clone } from "lodash";
import IncTabBar from "../../Tabs/TabBar";
import { MetricTableConfig, MetricTableColumnInfo } from "../types";
import { IncTab } from "../..";
import { featureFlagService, FEATURE_FLAGS } from "../../../services/feature-flags";
import LoadingSpinner from "../../Loading/Loading";
import { getDtoFromWidgetConfig } from "../../../utils/ExploreUtils";
import { exploreApiService, WidgetUpsertResponse } from "../../../services/api/explore";
import { logger } from "../../../core";
import { BizEntityApiResult } from "../../../services/api/explore/BizServiceCommon";
import { MetricsTab } from "./MetricsTab";
import { PropertiesTab } from "./PropertiesTab";
import { getCloneMetricColumn } from "./utils";

interface Props {
  metricTableConfig: MetricTableConfig;
  onChange: (tableConfig: MetricTableConfig) => void;

  open: boolean;
  onClose: () => void;

  title?: string;
}

export const MetricTableWidgetEditor: FC<Props> = props => {
  const { metricTableConfig, onChange, open, onClose, title } = props;

  const [config, setConfig] = useState(clone(metricTableConfig));

  const enableSave = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.enableSavedWidgetQueryInDataTable);
  const [saveInProgress, setSaveInProgress] = useState(false);

  useEffect(() => {
    setConfig(clone(metricTableConfig));
  }, [metricTableConfig]);

  const [selectedTab, setSelectedTab] = useState<string | number>(TABS.metrics);

  const onConfigChange = useCallback((config: Partial<MetricTableConfig>) => {
    setConfig(prev => ({
      ...prev,
      ...config
    }));
  }, []);

  const onSave = useCallback(async () => {
    setSaveInProgress(true);

    if (enableSave) {
      const promises = config.metricColumns.map(saveWidgetForColumn);
      await Promise.allSettled(promises);
    }

    setSaveInProgress(false);
    onChange(config);
  }, [config, enableSave, onChange]);

  const titleActions = useMemo(
    () => (
      <IncButton
        className="marginRt12"
        color="primary"
        disabled={saveInProgress}
        iconType="iconText"
        onClick={onSave}
      >
        {!saveInProgress && (
          <>
            <IncFaIcon iconName="save" />
            Save
          </>
        )}

        {saveInProgress && <LoadingSpinner titleText="Saving..." />}
      </IncButton>
    ),
    [onSave, saveInProgress]
  );

  const titleText = title ? `Edit ${title}` : "Edit";

  return (
    <IncModal
      disableFocusOnLoad
      onClose={onClose}
      show={open}
      size="side-pane"
      titleActions={titleActions}
      titleText={titleText}
    >
      <IncTabBar
        className="marginBt12"
        disableRouteChange
        handleSelection={setSelectedTab}
        selected={selectedTab}
        tabs={tabs}
      />

      {selectedTab === TABS.metrics && (
        <MetricsTab
          metricTableConfig={config}
          onChange={onConfigChange}
        />
      )}

      {selectedTab === TABS.properties && (
        <PropertiesTab
          metricTableConfig={config}
          onChange={onConfigChange}
        />
      )}
    </IncModal>
  );
};

const TABS = {
  metrics: "metrics",
  properties: "properties"
};

const tabs: IncTab[] = [
  {
    id: TABS.metrics,
    label: "Metrics"
  },
  {
    id: TABS.properties,
    label: "Properties"
  }
];

const saveWidgetForColumn = (columnInfo: MetricTableColumnInfo) => {
  const { queryConfig, id } = columnInfo;
  const { queryType, metricQueryConfig } = queryConfig;
  const { bizDataQuery, entityTypeId } = metricQueryConfig || {};

  if (queryType === "metric" && bizDataQuery) {
    const { id: widgetId, widgetConfig } = bizDataQuery;

    let promise: Promise<BizEntityApiResult<WidgetUpsertResponse>>;

    // Overwrite case
    if (widgetId) {
      const cloneConfig = getCloneMetricColumn(columnInfo);

      const nBizDataQuery = cloneConfig.queryConfig.metricQueryConfig.bizDataQuery;
      const nWidgetConfig = nBizDataQuery.widgetConfig;
      nWidgetConfig.name = nWidgetConfig.name || `${entityTypeId} - MetricColumn - ${id}`;

      const widgetConfigDto = getDtoFromWidgetConfig(nWidgetConfig);

      columnInfo.queryConfig.metricQueryConfig.bizDataQuery = nBizDataQuery;

      promise = exploreApiService.updateWidgetConfig(widgetConfigDto, widgetId, 1);
    } else if (widgetConfig) {
      const widgetConfigDto = getDtoFromWidgetConfig(widgetConfig);
      widgetConfigDto.name = widgetConfigDto.name || `${entityTypeId} - MetricColumn - ${id}`;

      promise = exploreApiService.saveWidgetConfig(widgetConfigDto);
    }

    return promise
      ?.then(res => {
        const { data, error, message } = res;

        if (error) {
          throw new Error(message);
        } else {
          columnInfo.queryConfig.metricQueryConfig.bizDataQuery.id = data.widgetId;
        }
      })
      .catch(err => {
        logger.error("Save/Overwrite Widget Config", "Error occurred while saving/overwriting widget config", err);
      });
  }

  return Promise.resolve();
};
