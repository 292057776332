import { IncFaIcon, IncSmartText } from "@inception/ui";
import React, { FC, memo } from "react";
import { VerticallyCenteredRow } from "../flex-components";
import { GroupedBarEntry } from "./types";

export const GroupedBarsLegend: FC<GroupedBarEntry> = memo(props => {
  const { color, name } = props;

  return (
    <VerticallyCenteredRow className="grouped-bars--legend-entry">
      <IncFaIcon
        iconName="square"
        style={{ color: color }}
      />
      {Boolean(name) && (
        <div className="label">
          <IncSmartText text={name} />
        </div>
      )}
    </VerticallyCenteredRow>
  );
});
