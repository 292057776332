import { IncFaIcon } from "@inception/ui";
import { isEqual, omit } from "lodash";
import React, { FC, useEffect, useCallback, useMemo, CSSProperties, useState } from "react";
import { VerticallyCenteredRow } from "../../../components";
import { useToggleState } from "../../../core";
import { useOpStore } from "../../context";
import { PreviewRenderer } from "./PreviewRenderer";

interface Props {
  show?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  error?: string;
}

export const OpPreview: FC<Props> = props => {
  const { onClose: pOnClose, onOpen: pOnOpen, show: pShow = false, error = "" } = props;

  const { state } = useOpStore();
  const { context, opCreationConfig } = state;
  const { opCreationConfigDef, outlierConfig } = opCreationConfig;

  const { entityTypeInfo, demoDataParams } = context;

  const [seriesSelection, setSeriesSelection] = useState<Record<string, string>>();
  const [previewOpCreationConfig, setPreviewOpCreationConfig] = useState(opCreationConfig);

  useEffect(() => {
    setPreviewOpCreationConfig(prevOpCreationConfig => {
      if (error) {
        return prevOpCreationConfig;
      }
      const prevCompareConfig = omit(prevOpCreationConfig.opCreationConfigDef, ["suppression", "triggerCondition"]);
      const nextCompareConfig = omit(opCreationConfig.opCreationConfigDef, ["suppression", "triggerCondition"]);

      const shouldUpdatePreview = !isEqual(prevCompareConfig, nextCompareConfig);
      if (shouldUpdatePreview) {
        return opCreationConfig;
      }

      return prevOpCreationConfig;
    });
  }, [error, opCreationConfig]);

  const { close, isOpen, open, setIsOpen } = useToggleState();

  useEffect(() => {
    if (typeof pShow === "boolean") {
      setIsOpen(pShow);
    }
  }, [pShow, setIsOpen]);

  const onOpen = useCallback(() => {
    if (pOnOpen) {
      pOnOpen();
    } else {
      open();
    }
  }, [open, pOnOpen]);

  const onClose = useCallback(() => {
    if (pOnClose) {
      pOnClose();
    } else {
      close();
    }
  }, [close, pOnClose]);

  const onToggle = useCallback(() => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  }, [isOpen, onClose, onOpen]);

  const iconStyle = useMemo<CSSProperties>(
    () => ({
      transition: "all 0.25s ease-in-out",
      transform: `rotate(${isOpen ? 0 : 180}deg)`,
      marginRight: 8
    }),
    [isOpen]
  );

  const className = `inc-text-body inc-text-inactive marginTp16 ${error ? "disableClick" : ""}`;

  return (
    <>
      <VerticallyCenteredRow className={className}>
        <VerticallyCenteredRow
          className="width-fit-content inc-cursor-pointer"
          onClick={onToggle}
        >
          <IncFaIcon
            iconName="chevron-circle-down"
            style={iconStyle}
          />
          Preview
        </VerticallyCenteredRow>
        {!opCreationConfigDef && Boolean(outlierConfig) && (
          <VerticallyCenteredRow className="marginLt10 status-danger">
            <IncFaIcon
              className="marginRt8"
              iconName="exclamation-triangle"
            />
            Preview not available on type Outlier Config
          </VerticallyCenteredRow>
        )}
        {error && (
          <VerticallyCenteredRow className="marginLt10 status-danger">
            <IncFaIcon
              className="marginRt8"
              iconName="exclamation-triangle"
            />
            {error}
          </VerticallyCenteredRow>
        )}
      </VerticallyCenteredRow>
      {isOpen && Boolean(opCreationConfigDef) && (
        <PreviewRenderer
          demoDataParams={demoDataParams}
          entityTypeInfo={entityTypeInfo}
          onSeriesSelectionChange={setSeriesSelection}
          opCreationConfig={previewOpCreationConfig}
          seriesSelection={seriesSelection}
        />
      )}
    </>
  );
};
