import React, { FC, useState, useCallback, useEffect } from "react";
import { IncButton, IncFaIcon, IncModal, IncEditor } from "@inception/ui";
import { LoadingSpinner } from "../../../components";
import { WidgetConfigDTO } from "../../../services/api/explore";
import { featureFlagService, FEATURE_FLAGS } from "../../../services/feature-flags";
import { logger } from "../../../core/logging/Logger";
import { MetricRenamer } from "./MetricRenamer";

interface Props {
  showPreview: boolean;
  canSave: boolean;
  newMetric: boolean;
  canPreview: boolean;
  error: string;
  saveInProgress: boolean;
  widgetConfigDto: WidgetConfigDTO;
  onWidgetConfigChange: (widgetConfig: WidgetConfigDTO) => void;

  addMetric: (saveAs: boolean) => void;
  preOnClose: () => void;
  openPreview: () => void;
  closePreview: () => void;
}

export const FooterChildren: FC<Props> = props => {
  const {
    addMetric,
    canSave,
    newMetric,
    error,
    openPreview,
    closePreview,
    preOnClose,
    showPreview,
    saveInProgress,
    canPreview,
    widgetConfigDto: widgetConfig,
    onWidgetConfigChange
  } = props;

  const showSaveAs = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.allowSaveInAddMetric);

  const [widgetConfigJsonStr, setWidgetConfigJsonStr] = useState(JSON.stringify(widgetConfig, null, 2));

  useEffect(() => {
    const configStr = JSON.stringify(widgetConfig, null, 2);
    setWidgetConfigJsonStr(configStr);
  }, [widgetConfig]);

  const debugMode = featureFlagService.isDebugMode();

  const [showConfigPreview, setShowConfigPreview] = useState(false);
  const openConfigPreview = useCallback(() => setShowConfigPreview(true), []);
  const closeConfigPreview = useCallback(() => {
    try {
      const nWidgetConfigDto = JSON.parse(widgetConfigJsonStr);
      nWidgetConfigDto && onWidgetConfigChange(nWidgetConfigDto);
    } catch (e) {
      logger.error("AddMetricModal", "Error parsing JSON, ignoring changes", e);
      const configStr = JSON.stringify(widgetConfig, null, 2);
      setWidgetConfigJsonStr(configStr);
    }
    setShowConfigPreview(false);
  }, [onWidgetConfigChange, widgetConfig, widgetConfigJsonStr]);

  const [showMetricRenamer, setShowMetricRenamer] = useState(false);
  const [saveAs, setSaveAs] = useState(false);
  const closeMetricRenamer = useCallback(() => setShowMetricRenamer(false), []);

  const onSaveMetric = useCallback(() => {
    setSaveAs(false);
    const { metrics } = widgetConfig.dataDefinition;
    const hasExprMetric = Object.values(metrics).findIndex(metric => metric.sourceType === "expression") !== -1;
    if (hasExprMetric) {
      setShowMetricRenamer(true);
    } else {
      addMetric(false);
    }
  }, [addMetric, widgetConfig.dataDefinition]);

  const onSaveAsMetric = useCallback(() => {
    setSaveAs(true);
    const { metrics } = widgetConfig.dataDefinition;
    const hasExprMetric = Object.values(metrics).findIndex(metric => metric.sourceType === "expression") !== -1;
    if (hasExprMetric) {
      setShowMetricRenamer(true);
    } else {
      addMetric(true);
    }
  }, [addMetric, widgetConfig.dataDefinition]);

  const onMetricsRenameSave = useCallback(
    (metricNamesById: Record<string, string>) => {
      Object.keys(metricNamesById).forEach(metricId => {
        const metricName = metricNamesById[metricId];
        widgetConfig.dataDefinition.metrics[metricId].name = metricName;
      });

      closeMetricRenamer();
      addMetric(saveAs);
    },
    [addMetric, closeMetricRenamer, saveAs, widgetConfig.dataDefinition.metrics]
  );

  const disableSave = !canSave || saveInProgress;
  const disablePreview = !canPreview || saveInProgress;

  const allowOverwrite = !newMetric && showSaveAs;
  const cloneActionText = allowOverwrite ? "Save As" : "Save";

  return (
    <div className="inc-flex-row inc-flex-center-vertical">
      {!showPreview && (
        <>
          <IncButton
            color="primary"
            disabled={disablePreview}
            onClick={openPreview}
          >
            View Preview
          </IncButton>
        </>
      )}

      {showPreview && (
        <>
          {allowOverwrite && (
            <IncButton
              color="primary"
              disabled={disableSave}
              onClick={onSaveMetric}
            >
              Save
            </IncButton>
          )}

          <IncButton
            color="primary"
            disabled={disableSave}
            onClick={onSaveAsMetric}
          >
            {cloneActionText}
          </IncButton>

          <IncButton
            className="marginLt10"
            color="link"
            disabled={saveInProgress}
            onClick={closePreview}
          >
            Back
          </IncButton>
        </>
      )}

      {debugMode && (
        <IncButton
          className="marginLt10"
          color="link"
          onClick={openConfigPreview}
        >
          View configuration
        </IncButton>
      )}

      <IncButton
        className="marginLt10 status-danger"
        color="link"
        disabled={saveInProgress}
        onClick={preOnClose}
      >
        Cancel
      </IncButton>

      {Boolean(error) && (
        <div className="inc-flex-row inc-flex-center-vertical status-danger">
          <IncFaIcon iconName="warning" />
          <div className="inc-text-subtext-medium inc-flex-row marginLt12">{error}</div>
        </div>
      )}

      {saveInProgress && <LoadingSpinner titleText="Saving metric configuration..." />}

      {/* Config viewer */}
      <IncModal
        className="add-metric-config-viewer-modal"
        onClose={closeConfigPreview}
        show={showConfigPreview}
        size="md"
        titleText="View configuration"
      >
        <IncEditor
          mode="json"
          onChange={setWidgetConfigJsonStr}
          value={widgetConfigJsonStr}
        />
      </IncModal>

      <IncModal
        onClose={closeMetricRenamer}
        show={showMetricRenamer}
        size="sm"
        titleText="Rename metrics"
      >
        <MetricRenamer
          onCancel={closeMetricRenamer}
          onSave={onMetricsRenameSave}
          widgetConfig={widgetConfig}
        />
      </IncModal>
    </div>
  );
};
