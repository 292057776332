import React, { useEffect } from "react";
import Prism from "prismjs";
import "prismjs/components/prism-javastacktrace";
import "prismjs/components/prism-javascript";

export type CallStackRendererProps = {
  text: string;

  // Every time a language is added which is to be supported please import it above
  language: "javastacktrace" | "javascript";
};

const IncCallStackRenderer: React.FC<CallStackRendererProps> = props => {
  const { text, language } = props;

  useEffect(() => {
    if (text && language) {
      Prism.highlightAll();
    }
  }, [text, language]);

  if (!text) {
    return <></>;
  }

  return (
    <div className="inc-callstack">
      <pre>
        <code className={`language-${language}`}>{text}</code>
      </pre>
    </div>
  );
};

export default IncCallStackRenderer;
