import React, { useState, useRef, useCallback, useEffect, FC, cloneElement, MouseEvent } from "react";

interface ClickAwayContainerProps {
  toggleElem: JSX.Element;
  children: JSX.Element | JSX.Element[];
  open?: boolean;
  onToggle?: (show: boolean) => void;
}

const ClickAwayContainer: FC<ClickAwayContainerProps> = (props: ClickAwayContainerProps) => {
  const { toggleElem: usrToggleElem, children, open = false, onToggle = () => {} } = props;

  const wrapperRef = useRef(null);
  const [show, setShow] = useState(false);

  const toggleOpenClose = useCallback((): void => {
    setShow(prevShow => {
      const newShow = !prevShow;
      onToggle(newShow);
      return newShow;
    });
  }, [setShow, onToggle]);

  useEffect(() => {
    setShow(open);
  }, [open]);

  useEffect(() => {
    const handleClickOutside = (event: any): void => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (!event.target.classList.contains("inception-react-select__option")) {
          setShow(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, setShow]);

  const toggleElemOnClick = useCallback(
    (evt: MouseEvent<Element>) => {
      const usrOnClick = usrToggleElem?.props?.onClick ? usrToggleElem.props.onClick : () => {};
      toggleOpenClose();
      usrOnClick(evt);
    },
    [usrToggleElem, toggleOpenClose]
  );

  const toggleElem = cloneElement(usrToggleElem, {
    onClick: toggleElemOnClick
  });

  return (
    <div
      className="click-away-container"
      data-cy="click-away-container"
      ref={wrapperRef}
    >
      {toggleElem}
      {show && <>{children}</>}
    </div>
  );
};

export default ClickAwayContainer;
