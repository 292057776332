import React from "react";
import { capitalize } from "lodash";
import { TableDataColumn } from "@inception/ui";
import { DataType } from "../../core";
import { BizPropertySourceRenderer } from "../business-entity";
import { PropertySource } from "../../services/api/entity-mapping";
import { FieldPickerRow } from "../../field-picker";
import FieldLabelRenderer from "./FieldLabelRenderer";
import SourcesRenderer from "./SourceRenderer";

export class FieldPickerColumnUtils {
  static getBizEntityFieldsColumns() {
    const cols: Array<TableDataColumn<FieldPickerRow<"bizEntityField">>> = [
      {
        accessor: "fieldLabel",
        header: "Attribute Name",
        sortable: true
      },
      {
        accessor: "datatype",
        header: "Type",
        renderer: dataTypeRenderer,
        sortable: true,
        width: 100,
        sortFn: dataTypeSortFn
      },
      {
        accessor: "propertySources",
        header: "Source",
        renderer: (propertySource: PropertySource[]) => (
          <BizPropertySourceRenderer propertySource={propertySource[0]} />
        ),
        width: 160
      }
    ];
    return cols;
  }

  static getUserServiceFieldsTableColumns() {
    const cols: Array<TableDataColumn<FieldPickerRow<"userServiceField">>> = [
      {
        accessor: "fieldLabel",
        header: "Field Name",
        renderer: FieldLabelRenderer,
        sortable: true
      },
      {
        accessor: "datatype",
        header: "Type",
        renderer: dataTypeRenderer,
        sortable: true,
        width: 100,
        sortFn: dataTypeSortFn
      },
      {
        accessor: "sources",
        header: "Event Types",
        renderer: sources => SourcesRenderer(sources),
        width: 160
      }
    ];
    return cols;
  }

  static getMetricsTableColumns() {
    const cols: Array<TableDataColumn<FieldPickerRow<"bizEntityMetric" | "userServiceMetric">>> = [
      {
        accessor: "fieldLabel",
        header: "Metric Name",
        sortable: true
      }
    ];
    return cols;
  }
}

const dataTypeRenderer = (dataType: DataType, rowData: FieldPickerRow<"bizEntityField" | "userServiceField">) => {
  const eDataType = rowData.customDataType && rowData.customDataType !== "not_set" ? rowData.customDataType : dataType;
  return capitalize(eDataType);
};

const dataTypeSortFn = (
  a: FieldPickerRow<"bizEntityField" | "userServiceField">,
  b: FieldPickerRow<"bizEntityField" | "userServiceField">
) => {
  const aDataType = Boolean(a.customDataType) && a.customDataType !== "not_set" ? a.customDataType : a.datatype;
  const bDataType = Boolean(b.customDataType) && b.customDataType !== "not_set" ? b.customDataType : b.datatype;

  if (bDataType.toLowerCase() > aDataType.toLowerCase()) {
    return "lesser";
  } else if (bDataType.toLowerCase() < aDataType.toLowerCase()) {
    return "greater";
  }
  return "equal";
};
