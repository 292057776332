import React, { FC, useMemo, useCallback, memo } from "react";
import { sortBy, toPairs } from "lodash";
import {
  UIIntegrationActionConfig,
  ParamValueTemplate,
  ActionConfigUIParam,
  ActionRunMode
} from "../../../../../services/api/operationalise";
import { getValueFromParamValue } from "../../../../utils";
import { ActionParamsEditorProps } from "./types";
import { ParamsEditor } from "./ParamsEditor";
import { SingleLineTemplateEditorWrapper, TemplateEditor } from "./template-editor";
import { checkIfTemplate, checkIfTemplateSection } from "./utils";

interface Props extends ActionParamsEditorProps {
  integrationConfig: UIIntegrationActionConfig;
  paramValues: Record<string, ParamValueTemplate>;
  onParamValueChange: (paramValue: ParamValueTemplate, paramId: string) => void;
  onErrors: (errors: string[], sectionKey: string) => void;
}

type ParamEntries = Array<[string, ActionConfigUIParam]>;

export const IntegrationConfigParamsEditor: FC<Props> = memo(props => {
  const {
    integrationConfig,
    onParamValueChange,
    paramValues,
    actionCategoryType,
    opCreationConfig,
    sourceTypeId,
    lookupValuesMap,
    bizActionConfig,
    actionRunMode,
    op10zeId,
    onErrors
  } = props;

  const isBulkActionRunMode = actionRunMode === ActionRunMode.bulk;

  const { uiParams, actionDefConfigId, connectionId, id: integrationConfigId } = integrationConfig;

  const updateStateOfDependent = useCallback((parentData, parentType, dependent) => {
    const fParentData: any = parentType === "_bool" && !parentData ? false : true;
    const attributeMap = dependent.dependsOnValuesMap;
    for (const property in attributeMap) {
      const dependentPropValue = attributeMap[property];
      let attributeValue = dependentPropValue.valueToPropValue[fParentData as string];
      //need to check for the true false string value and cast as boolean
      if (attributeValue && ["editable", "visible"].includes(property)) {
        attributeValue = JSON.parse(attributeValue?.toString()?.toLowerCase());
      }
      dependent[property] = attributeValue;
    }
    return dependent;
  }, []);

  const paramEditors = useMemo(() => {
    const paramEntries: ParamEntries = toPairs(uiParams);
    const orderedParamEntries = sortBy(paramEntries, entry => {
      const paramConfig = entry[1];
      return paramConfig.fieldOrder;
    }).filter(p => p[1].visible);

    return orderedParamEntries.map(([paramId, uiParam]) => {
      const { dependsOn, subtype, disableCustomTemplates } = uiParam;

      const key = [actionDefConfigId, connectionId, integrationConfigId, paramId].join("_");
      const value = paramValues[paramId];
      const onChange = (value: ParamValueTemplate) => onParamValueChange(value, paramId);
      //check for the dependency
      if (uiParam.dependsOn) {
        const dependsOnFieldIndex = orderedParamEntries.findIndex(([pId]) => pId === dependsOn);
        const dependOnUIParam = orderedParamEntries[dependsOnFieldIndex][1];
        const dependOnValue = paramValues[dependOnUIParam.name];
        const dependOnType = dependOnUIParam.type;
        const primVal = getValueFromParamValue(dependOnValue, dependOnType);
        uiParam = updateStateOfDependent(primVal, dependOnType, uiParam);
      }

      const isTemplate = checkIfTemplate(subtype);
      const isTemplateSection = checkIfTemplateSection(subtype);

      return isTemplateSection ? (
        <SingleLineTemplateEditorWrapper
          actionCategoryType={actionCategoryType}
          actionRunMode={actionRunMode}
          bizActionConfig={bizActionConfig}
          disabled={disableCustomTemplates}
          filterOutLoopElements={isBulkActionRunMode}
          key={key}
          lookupValuesMap={lookupValuesMap}
          onChange={onChange}
          op10zeId={op10zeId}
          opCreationConfig={opCreationConfig}
          sourceTypeId={sourceTypeId}
          uiParam={uiParam}
          useNewTemplateEditor
          value={value}
        />
      ) : isTemplate ? (
        <TemplateEditor
          actionCategoryType={actionCategoryType}
          actionRunMode={actionRunMode}
          bizActionConfig={bizActionConfig}
          disableCustomTemplates={disableCustomTemplates}
          filterOutLoopElements={isBulkActionRunMode}
          key={key}
          lookupValuesMap={lookupValuesMap}
          onChange={onChange}
          onErrors={onErrors}
          op10zeId={op10zeId}
          opCreationConfig={opCreationConfig}
          sourceTypeId={sourceTypeId}
          uiParam={uiParam}
          useNewTemplateEditor
          value={value}
        />
      ) : (
        <ParamsEditor
          actionRunMode={actionRunMode}
          disabled={disableCustomTemplates}
          key={key}
          lookupValuesMap={lookupValuesMap}
          onChange={onChange}
          uiParam={uiParam}
          value={value}
        />
      );
    });
  }, [
    actionCategoryType,
    actionDefConfigId,
    actionRunMode,
    bizActionConfig,
    connectionId,
    integrationConfigId,
    isBulkActionRunMode,
    lookupValuesMap,
    onErrors,
    onParamValueChange,
    op10zeId,
    opCreationConfig,
    paramValues,
    sourceTypeId,
    uiParams,
    updateStateOfDependent
  ]);

  const paramsExist = paramEditors?.length > 0;

  return paramsExist ? <div className="integration-config-params-editor">{paramEditors}</div> : <></>;
});
