import React, { useState, useRef, useMemo, useCallback, useEffect } from "react";
import { isEqual, uniqBy } from "lodash";
import { IncClickAwayPopper, IncButton, IncToolTip, IncPill, IncSmartText, IncFaIcon } from "@inception/ui";
import {
  FieldPickerContextDTO,
  UserServiceFilterExpression,
  UserServiceFilterExpressionTree,
  ConditionWithPickerType,
  WidgetConfigUtils,
  LogicalOperator
} from "../../../services/api/explore";
import { ExploreQueryUtils, fetchEntityPropertiesForIds, FieldPickerUtils, noOp } from "../../../utils";
import { InfoPopper } from "../../CustomSelect";
import { ConditionWithPickerTypeExpressionTree, ConditionsBuilder } from "../../explore";

interface EventCriteriaPickerProps {
  fieldPickerContext: FieldPickerContextDTO;

  filters?: UserServiceFilterExpression[];
  filterExpressionTree?: UserServiceFilterExpressionTree;
  useExpressionTree?: boolean;
  onChange: (
    filterExpressions: UserServiceFilterExpression[],
    expressionTree?: UserServiceFilterExpressionTree
  ) => void;

  label?: string;
  pillLimit?: number;
  placeholder?: string;

  className?: string;
  disabled?: boolean;
}

export const EventCriteriaPickerV2: React.FC<EventCriteriaPickerProps> = props => {
  const {
    fieldPickerContext,
    filters: eFilters,
    filterExpressionTree: eFilterExpressionTree,
    onChange,
    pillLimit = 2,
    disabled = false,
    useExpressionTree = false
  } = props;

  const anchorEl = useRef(null);
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [entityLookUp, setEntityLookUp] = useState<Record<string, string>>({});
  const [isFetchingLookUp, setIsFetchingLookUp] = useState<boolean>(false);

  const filters = useMemo<ConditionWithPickerType[]>(() => {
    if (eFilters && eFilters.length > 0) {
      return ExploreQueryUtils.getConditionFilters(eFilters);
    }
    return [null];
  }, [eFilters]);

  const filtersExpressionTree = useMemo<ConditionWithPickerTypeExpressionTree>(() => {
    if (eFilterExpressionTree) {
      return ExploreQueryUtils.getAdvancedConditionFilters(eFilterExpressionTree);
    }

    return {
      filterNodes: [],
      logicalOperator: LogicalOperator.AND
    };
  }, [eFilterExpressionTree]);

  const openPopper = useCallback(() => setOpen(true), []);
  const closePopper = useCallback(() => setOpen(false), []);

  const [tempFilters, setTempFilters] = useState(filters);
  const [tempFilterExprTree, setTempFilterExprTree] = useState(filtersExpressionTree);

  const onFiltersChange = useCallback(
    (filters: ConditionWithPickerType[], filtersValid: boolean, filterExpr: ConditionWithPickerTypeExpressionTree) => {
      setTempFilters(filters);
      setTempFilterExprTree(filterExpr);
    },
    []
  );

  useEffect(() => {
    const fetchEntityLookup = async (entityIds: string[]) => {
      const entityPropertiesMap = await fetchEntityPropertiesForIds(
        new Set(entityIds.map(x => x.replace(/['"]+/g, ""))),
        0,
        Date.now()
      );
      const entityLookUp: Record<string, string> = {};
      setIsFetchingLookUp(true);

      entityPropertiesMap.forEach((value, key) => {
        entityLookUp[key] = value.name;
      });
      setEntityLookUp(entityLookUp);
      setIsFetchingLookUp(false);
    };

    const entityIds = useExpressionTree
      ? WidgetConfigUtils.getEntityIdsFromFilterTree(eFilterExpressionTree)
      : WidgetConfigUtils.getEntityIdsFromFilterExpressions(eFilters);
    if (entityIds.length > 0) {
      fetchEntityLookup(entityIds);
    }
  }, [eFilterExpressionTree, eFilters, useExpressionTree]);

  const onSave = useCallback(() => {
    if (useExpressionTree) {
      const eFilterExprTree = ExploreQueryUtils.getUserServiceFilterExpressionTree(tempFilterExprTree);
      onChange(null, eFilterExprTree);
    } else {
      const eFilters: ConditionWithPickerType[] = tempFilters.map(filter => {
        if (!ExploreQueryUtils.validateFilter(filter)) {
          return undefined;
        }
        return filter;
      });

      const validFilters = eFilters
        .filter(x => x !== undefined)
        .map(x => ExploreQueryUtils.getUserServiceFilterExpression(x));
      const uniqFilters = uniqBy(
        validFilters,
        filter =>
          `${FieldPickerUtils.getUserServiceFieldLabel(filter.field)}-${filter.operator}-${filter.value ? filter.value : filter.values.join(",")}`
      );
      if (!isEqual(uniqFilters, filters)) {
        onChange(uniqFilters, null);
      }
    }
    closePopper();
  }, [closePopper, filters, onChange, tempFilterExprTree, tempFilters, useExpressionTree]);

  const onCancelClick = useCallback(() => {
    setTempFilterExprTree(filtersExpressionTree);
    setTempFilters(filters);

    closePopper();
  }, [filtersExpressionTree, filters, closePopper]);

  const stopPropagation = useCallback(e => {
    e.stopPropagation();
  }, []);

  const onRemoveFilter = useCallback(
    (idx: number) => {
      const tFilters = [...eFilters];
      tFilters.splice(idx, 1);
      onChange(tFilters);
    },
    [eFilters, onChange]
  );

  const filterPills = useMemo(() => {
    if (useExpressionTree) {
      const filterLabel = WidgetConfigUtils.getUsFilterExpressionTreeLabel(eFilterExpressionTree, entityLookUp);
      return filterLabel ? (
        <div className="inc-flex-row inc-flex-center-vertical width-100">
          <IncSmartText text={filterLabel} />
        </div>
      ) : null;
    } else {
      if (eFilters.length > 0 && !isFetchingLookUp) {
        const filterLabels = eFilters.map(filter => WidgetConfigUtils.getUsFilterExpressionLabel(filter, entityLookUp));
        const displayLabels = filterLabels.splice(0, pillLimit);
        const diff = filterLabels.length;

        const pills = displayLabels.map((label, idx) => (
          <IncPill
            className="marginLt4 marginRt4 event-critieria-picker-v2-pill"
            key={idx}
            label={label}
            onClick={openPopper}
            onRemove={() => onRemoveFilter(idx)}
          />
        ));

        let hoverElement = null;
        if (filterLabels.length > 0) {
          hoverElement = InfoPopper(filterLabels.map(x => x));
        }

        return (
          <div className="inc-flex-row inc-flex-center-vertical">
            {pills}
            {hoverElement && (
              <IncToolTip
                placement="bottom"
                titleElement={hoverElement}
              >
                <span className="inc-text-subtext inc-link marginTp4">{`+${diff}`}</span>
              </IncToolTip>
            )}
          </div>
        );
      }
    }

    return null;
  }, [
    useExpressionTree,
    eFilterExpressionTree,
    entityLookUp,
    eFilters,
    isFetchingLookUp,
    pillLimit,
    openPopper,
    onRemoveFilter
  ]);

  const className = !open ? "status-info marginTp4" : "inc-text-inactive marginTp4";

  return (
    <div
      className="width-100"
      ref={containerRef}
    >
      <div
        className="inc-flex-row-wrap flex-gap-8"
        ref={anchorEl}
      >
        <>
          {filterPills}

          {!eFilters?.length && (
            <IncButton
              color="secondary"
              iconName="pen-to-square"
              iconType="iconText"
              label="Add filter"
              onClick={openPopper}
            />
          )}

          {Boolean(eFilters?.length) && (
            <>
              <IncFaIcon
                className={className}
                iconName="circle-plus"
                onClick={openPopper}
              />
              <IncFaIcon
                className={className}
                iconName="pen-to-square"
                onClick={!disabled ? noOp : openPopper}
              />
            </>
          )}
        </>
      </div>

      <IncClickAwayPopper
        anchorEl={anchorEl.current}
        onClickAway={onCancelClick}
        overlay={false}
        placement="bottom"
        show={open}
      >
        <div
          className="event-filter-popper-v2 inc-flex-column"
          onClick={stopPropagation}
        >
          <div className="event-filters-container marginTp12">
            <ConditionsBuilder
              fieldPickerContext={fieldPickerContext}
              fieldTypes={["userServiceField"]}
              filterExpressionTree={filtersExpressionTree}
              filterJoin="AND"
              filters={filters}
              label="Where"
              labelAlign="horizontal"
              onChange={onFiltersChange}
              useExpressionTree={useExpressionTree}
            />
          </div>
          <div className="inc-flex-row inc-flex-center-vertical marginTt12">
            <IncButton
              color="primary"
              onClick={onSave}
            >
              Save
            </IncButton>
            <IncButton
              className="cancel-btn marginLt12"
              color="link"
              onClick={onCancelClick}
            >
              Cancel
            </IncButton>
          </div>
        </div>
      </IncClickAwayPopper>
    </div>
  );
};
