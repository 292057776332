import {
  getFormattedDateTime,
  IncDateTimeFormat,
  FilterRendererProps,
  IncFaIcon,
  IncMenuItem,
  IncSelectOption,
  IncHighchartsDateTimeFormat
} from "@inception/ui";
import { inRange } from "lodash";
import React from "react";
import { LoadingSpinner, TableRangeFilterRenderer } from "../../../../components";
import { dateTime } from "../../../../core";
import { ChartPoint } from "../../../../dashboard/widgets/TimeSeries/models/model";
import { CommonAlertsResponseItem } from "../../../../services/api/CommonAlerts";
import { ForecastSpec, ForecastProjection, ImpactedWidget, ImpactedWidgetList } from "../../../../services/api/explore";
import { ENTITY_TAG } from "../../../../utils";
import timeRangeUtils from "../../../../utils/TimeRangeUtils";
import { getTileColorByCount } from "../utils";
import { GROUP_BY_NONE_OPT } from "./constants";
import { ImpactedWidgetCellRenderer } from "./ImpactedWidgetCellRenderer";
import { RenderRowCellWithMore } from "./RenderRowCellWithMore";
import { ResultsTableDataColumn, ImpactedWidgetSelection, ResultsTableDataEntry } from "./types";

export type ImpactedWidgetMenuOption = IncMenuItem<ImpactedWidget>;

export const getResultsTableColumns = (
  entityTypeName: string,
  additionalTags: string[],
  opId: string,
  simulationId: string,
  startTimeMillis: number,
  endTimeMillis: number,
  data: ResultsTableDataEntry[],
  groupByOpt: IncSelectOption,
  impactedWidgetSelection: ImpactedWidgetSelection[],
  navigateToTriages: (urls: string[]) => void
) => {
  const columns: ResultsTableDataColumn[] = [];

  // Status column
  columns.push({
    accessor: "alertResponses",
    header: "",
    width: 40,
    renderer: value => {
      const numAlerts = value.length;
      const color = getTileColorByCount(numAlerts);

      return (
        <div
          className="results-table--chip"
          style={{ background: color }}
        />
      );
    },
    excludeInDownload: true
  });

  const hasGroupBy = groupByOpt.value !== GROUP_BY_NONE_OPT.value;

  if (hasGroupBy) {
    // Date column
    columns.push({
      accessor: "formattedStartTimeMillis",
      header: "Date",
      width: 100,
      align: "center"
    });

    // Day column
    columns.push({
      accessor: "startTimeMillis",
      header: "Day",
      width: 125,
      renderer: value => {
        const weekdayIdx = dateTime(value).isoWeekday() as number;
        return days[weekdayIdx];
      },
      getDataFn: value => {
        const weekdayIdx = dateTime(value).isoWeekday() as number;
        return days[weekdayIdx];
      },
      align: "center"
    });

    // Alerts column
    columns.push({
      accessor: "alertResponses",
      header: "Number of Alerts",
      width: 150,
      renderer: value => value.length,
      getDataFn: value => value.length,
      align: "center"
    });
  }

  // Start and end time columns when no groupBy
  if (!hasGroupBy) {
    columns.push({
      accessor: "startTimeMillis",
      header: "Start Time",
      width: 150,
      renderer: (startTimeMillis: number) => getFormattedDateTime(startTimeMillis, IncDateTimeFormat.minimal),
      getDataFn: (startTimeMillis: number) => getFormattedDateTime(startTimeMillis, IncDateTimeFormat.minimal),
      align: "center",
      sortable: true,
      type: "datetime"
    });

    columns.push({
      accessor: "endTimeMillis",
      header: "End Time",
      width: 150,
      renderer: (endTimeMillis: number) =>
        endTimeMillis > 0 ? getFormattedDateTime(endTimeMillis, IncDateTimeFormat.minimal) : "ACTIVE",
      getDataFn: (endTimeMillis: number) =>
        endTimeMillis < 0 ? new Date().valueOf() : getFormattedDateTime(endTimeMillis, IncDateTimeFormat.minimal),
      align: "center",
      sortable: true,
      type: "datetime"
    });
  }

  additionalTags.forEach(tagName => {
    const isEntity = tagName === ENTITY_TAG;
    const header = isEntity ? entityTypeName : tagName;

    columns.push({
      accessor: "alertResponses",
      header,
      renderer: (alertResponses: CommonAlertsResponseItem[]) => {
        const tagValuesSet = new Set<string>();
        let lookupData: Record<string, string> = {};

        alertResponses.forEach(alertResponse => {
          const { additionalData, lookupData: alertLookupData } = alertResponse;

          additionalData.forEach(addData => tagValuesSet.add(addData[tagName] as string));
          lookupData = {
            ...lookupData,
            ...alertLookupData
          };
        });

        const tagValues = Array.from(tagValuesSet);
        const nTagValues =
          header === "discoveryTimeSec"
            ? tagValues.map(e => getFormattedDateTime(parseInt(e, 10) * 1000, IncDateTimeFormat.minimal))
            : tagValues;
        return (
          <RenderRowCellWithMore
            entityIds={nTagValues}
            entityTypeId={header}
            lookupData={lookupData}
          />
        );
      },
      getDataFn: (alertResponses: CommonAlertsResponseItem[]) => {
        const tagValuesSet = new Set<string>();

        alertResponses.forEach(alertResponse => {
          const { additionalData, lookupData } = alertResponse;

          additionalData.forEach(addData => {
            const rawValue = addData[tagName] as string;
            const displayValue = lookupData?.[rawValue] || rawValue;
            tagValuesSet.add(displayValue);
          });
        });

        const tagValues = Array.from(tagValuesSet);
        return tagValues.join(", ");
      }
    });
  });

  impactedWidgetSelection.forEach(impactedWidgetSel => {
    const { impactedWidget, forecast, forecastDelta, forecastDeltaPerc: forecastPercent } = impactedWidgetSel;

    const forecastSpec: ForecastSpec = {
      projections: []
    };

    forecast && forecastSpec.projections.push(ForecastProjection.forecast);
    forecastDelta && forecastSpec.projections.push(ForecastProjection.forecastDelta);
    forecastPercent && forecastSpec.projections.push(ForecastProjection.forecastDeltaPerc);

    const { id: impactedWidgetId, name } = impactedWidget;

    const impactedWidgetOp10zeId = opId;

    const keysToInclude = Object.keys(impactedWidgetSel).filter(
      key => key !== "impactedWidget" && (impactedWidgetSel as any)[key]
    );

    keysToInclude.forEach(key => {
      const dataKey = key as keyof ImpactedWidgetSelection;
      const suffix = getSuffixForForecastProjection(dataKey);
      const header = [name, suffix].join(" ");

      columns.push({
        accessor: "incidentIds",
        header,
        renderer: (incidentIds: string[], rowData: ResultsTableDataEntry) => (
          <ImpactedWidgetCellRenderer
            dataKey={dataKey}
            endTimeMillis={endTimeMillis}
            forecastSpec={forecastSpec}
            groupBy={groupByTags}
            impactedWidget={impactedWidget}
            incidentIds={incidentIds}
            opConfigId={impactedWidgetOp10zeId}
            rowDataObj={rowData}
            startTimeMillis={startTimeMillis}
          />
        ),
        align: "center",
        filterable: true,
        filterRenderer: (props: FilterRendererProps<ChartPoint>) => {
          let isLoading = false;
          let min = Number.POSITIVE_INFINITY;
          let max = Number.NEGATIVE_INFINITY;

          data.forEach(entry => {
            const result = entry?.dataState?.[impactedWidgetId]?.[dataKey];

            if (result) {
              const { dataValue: value, isFetching } = result;

              isLoading = isLoading || isFetching;
              min = Math.min(min, value || 0);
              max = Math.max(max, value || 0);
            }
          });

          if (isLoading) {
            return <LoadingSpinner />;
          }

          min = min - 1;
          max = max + 1;

          return (
            <TableRangeFilterRenderer
              {...props}
              label={name}
              range={[min, max]}
            />
          );
        },
        filterFn: (rows, range) => {
          if (!range?.length) {
            return rows;
          }

          return rows.filter(row => {
            const { original } = row;

            const result = original?.dataState?.[impactedWidgetId]?.[dataKey];

            if (result) {
              const { dataValue: value, isFetching } = result;

              if (isFetching) {
                return true;
              }

              return inRange(value, range[0], range[1]);
            }

            return true;
          });
        },
        sortable: true,
        sortFn: (a, b) => {
          const aValue = a?.dataState?.[impactedWidgetId]?.[dataKey]?.dataValue || 0;
          const bValue = b?.dataState?.[impactedWidgetId]?.[dataKey]?.dataValue || 0;

          if (aValue < bValue) {
            return "lesser";
          } else if (aValue > bValue) {
            return "greater";
          }

          return "equal";
        },
        getDataFn: (value, entry) => entry?.dataState?.[impactedWidgetId]?.[dataKey]?.formattedDataValue ?? "-"
      });
    });
  });

  // Navigate to triage
  columns.push({
    accessor: "alertResponses",
    header: "",
    width: 50,
    renderer: (alertResponses: CommonAlertsResponseItem[]) => {
      const urlsSet = new Set<string>();

      alertResponses.forEach(alertResponse => {
        const { actions } = alertResponse;
        const { actionUrl } = actions.find(act => act.actionType === "Drill Down") || {};
        if (actionUrl) {
          urlsSet.add(actionUrl);
        }
      });

      const urls = Array.from(urlsSet);
      const openTriages = () => navigateToTriages(urls);

      return (
        urls.length > 0 && (
          <IncFaIcon
            className="inc-cursor-pointer status-info"
            iconName="up-right-from-square"
            onClick={openTriages}
          />
        )
      );
    },
    align: "center",
    excludeInDownload: true
  });

  return columns;
};

export const getResultsTableData = (alertResponseItems: CommonAlertsResponseItem[], groupByOpt: IncSelectOption) => {
  const dataRecord: Record<string, ResultsTableDataEntry> = {};

  (alertResponseItems || []).forEach(alertResponseItem => {
    const { startTimeMillis: startTimeMillisStr, endTimeMillis: endTimeMillisStr, id: alertId } = alertResponseItem;

    const startTimeMillis = parseInt(startTimeMillisStr, 10);
    const formattedStartTimeMillis = getFormattedDateTime(
      startTimeMillis,
      IncHighchartsDateTimeFormat.monthDayDescriptive,
      { skipTime: true }
    );

    const endTimeMillis = parseInt(endTimeMillisStr, 10);
    const isOngoing = endTimeMillis === -1;
    const durEndTimeMillis = isOngoing ? timeRangeUtils.getNowMillis() : endTimeMillis;

    const formattedEndTimeMillis = isOngoing
      ? "ACTIVE"
      : getFormattedDateTime(endTimeMillis, IncHighchartsDateTimeFormat.monthDayDescriptive, { skipTime: true });

    const durationMillis = durEndTimeMillis - startTimeMillis;

    const key = groupByOpt.value === GROUP_BY_NONE_OPT.value ? alertId : formattedStartTimeMillis;

    const entry: ResultsTableDataEntry = dataRecord[key] || {
      alertResponses: [],
      incidentIds: [],
      startTimeMillis,
      formattedStartTimeMillis,
      endTimeMillis,
      formattedEndTimeMillis,
      durationMillis,
      dataState: {}
    };

    entry.alertResponses.push(alertResponseItem);
    entry.incidentIds.push(alertId);

    dataRecord[key] = entry;
  });

  const data = Object.values(dataRecord).sort((a, b) => b.startTimeMillis - a.startTimeMillis);
  return data;
};

export const getImpactedWidgetsOptions = (
  impactedWidgetsLists: ImpactedWidgetList[],
  impactedWidgetSelection: ImpactedWidgetSelection[]
) => {
  const options: ImpactedWidgetMenuOption[] = [];

  (impactedWidgetsLists || []).forEach(impactedWidgetsList => {
    impactedWidgetsList.impactedWidgets.forEach(impactedWidget => {
      const { id, name } = impactedWidget;

      const selectionEntry = impactedWidgetSelection.find(entry => entry.impactedWidget.id === id);

      const subMenuItems: ImpactedWidgetMenuOption[] = selectionEntry?.data
        ? [
            {
              label: "Forecasted",
              value: "forecast",
              data: impactedWidget,
              asCheckBox: false,
              checked: selectionEntry?.forecast
            },
            {
              label: "Forecasted change",
              value: "forecastDelta",
              data: impactedWidget,
              asCheckBox: false,
              checked: selectionEntry?.forecastDelta
            },
            {
              label: "Forecasted percentage change",
              value: "forecastDeltaPerc",
              data: impactedWidget,
              asCheckBox: false,
              checked: selectionEntry?.forecastDeltaPerc
            }
          ]
        : [];

      options.push({
        label: name,
        value: "data",
        data: impactedWidget,
        checked: selectionEntry?.data,
        asCheckBox: false,
        subMenuItems
      });
    });
  });
  return options;
};

const getSuffixForForecastProjection = (proj: string) =>
  proj === "forecastDelta"
    ? "(Forecasted change)"
    : proj === "forecastDeltaPerc"
      ? "(Forecasted percentage change)"
      : proj === "forecastLower"
        ? "(Forecasted lower)"
        : proj === "forecastUpper"
          ? "(Forecasted upper)"
          : proj === "forecast"
            ? "(Forecasted)"
            : "";

const days = [null, "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const groupByTags: string[] = [];
