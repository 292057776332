import {
  BROWSER_TIME_ZONE,
  getDateBasedOnTimeZone,
  IncButton,
  IncClickAway,
  IncDurationSelector,
  IncFaIcon,
  IncPopper,
  IncSelectorDuration
} from "@inception/ui";
import { clone, map } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { css, cx } from "emotion";
import IncARDateTimePicker from "../../../platform/components/relative-date-selector/ARDateTimePicker";
import { RelativeDurationSuffix, RelativeDurationType } from "../../dashboard/models/BaseWidgetModel";
import { IncTab, IncTabBar, TimezoneSelector } from "../../components";
import {
  RawTimeRange,
  TCCustomTimeRange,
  TimeRange,
  TimeZone,
  useAccessPrivilege,
  useNotifications,
  useTenantConfig,
  useToggleState
} from "../../core";
import timeRangeUtils, { TimeAlignOptions, TimeRangeOption } from "../../utils/TimeRangeUtils";
import { VerticallyCenteredRow } from "../flex-components";
import { DateFormatType } from "../types";
import { BEGINNING_OF_TIME_VALUE, END_OF_TIME_VALUE } from "./TimeRangeConstants";
import { QuickTab, SelectorTimeRange, TimeRangeSelectorProps } from "./types";
import { SaveCustomTimeRangeModal } from "./SaveCustomTimeRangeModal";

type TimeRangeSelectorPopperProps = TimeRangeSelectorProps & {
  onClose: () => void;
  open: boolean;
  anchorEl: HTMLElement;
  showTimeZone?: boolean;
};

enum QUICK_TABS {
  NEXT = "Next",
  LAST = "Last"
}

export const TimeRangeSelectorPopper: FC<TimeRangeSelectorPopperProps> = props => {
  const {
    onTimeSelect: usrOnTimeSelect,
    timeRange: pTimeRange,
    restrictedTimeRange,
    quickTabs: pTabs,
    hideTimeSelect,
    allowSameTimes,
    allowFutureDates,
    enableBeginningAndEndTimes,
    format: pFormat,
    i18nDisabled,
    withSeconds,
    onClose,
    open,
    anchorEl,
    hideQuickTabs = false,
    hideCustomTimeRangeList = false,
    alignTimeToBeginning,
    showTimeZone,
    disableSavingCustomTimeRange = false
  } = props;

  const { canEdit, canCreate } = useAccessPrivilege();
  const canSaveCustomTimeRange = !disableSavingCustomTimeRange && (canCreate || canEdit);

  const pTimeZone = useMemo(
    () => timeRangeUtils.getTimeZoneFromTimeRange(pTimeRange) || BROWSER_TIME_ZONE,
    [pTimeRange]
  );

  const [timeZone, setTimeZone] = useState(pTimeZone);
  const prevTimeZoneRef = useRef(timeZone);

  const setAbsoluteTimeRangeOnTimeZoneChange = useCallback((timeZone: TimeZone) => {
    setAbsFrom(prev => timeRangeUtils.getConvertedMillisFromTimeZone(prev, timeZone, prevTimeZoneRef.current));
    setAbsTo(prev => timeRangeUtils.getConvertedMillisFromTimeZone(prev, timeZone, prevTimeZoneRef.current));
  }, []);

  const onChangeTimeZone = useCallback(
    (timeZone: TimeZone) => {
      setTimeZone(prev => {
        prevTimeZoneRef.current = prev;
        return timeZone;
      });
      setTimeout(() => setAbsoluteTimeRangeOnTimeZoneChange(timeZone));
    },
    [setAbsoluteTimeRangeOnTimeZoneChange]
  );

  useEffect(() => {
    setTimeZone(prev => {
      prevTimeZoneRef.current = prev;
      return pTimeZone;
    });
    setTimeout(() => setAbsoluteTimeRangeOnTimeZoneChange(pTimeZone));
  }, [pTimeZone, setAbsoluteTimeRangeOnTimeZoneChange]);

  const timeRange: SelectorTimeRange = useMemo(
    () => ({
      from: pTimeRange.from.valueOf(),
      to: pTimeRange.to.valueOf(),
      fromLabel: pTimeRange.raw.from,
      toLabel: pTimeRange.raw.to
    }),
    [pTimeRange]
  );

  const getSelectedQuickTab = () => {
    if (pTabs?.length) {
      if (timeRangeUtils.isFutureRelativeTime(timeRange.fromLabel, timeRange.toLabel)) {
        return QUICK_TABS.NEXT;
      } else if (timeRangeUtils.isPastRelativeTime(timeRange.fromLabel, timeRange.toLabel)) {
        return QUICK_TABS.LAST;
      } else {
        return pTabs[0];
      }
    } else {
      return timeRange.fromLabel === "now" ? QUICK_TABS.NEXT : QUICK_TABS.LAST;
    }
  };

  const tabs = useMemo<QuickTab[]>(() => pTabs || ["Last"], [pTabs]);
  const [absFrom, setAbsFrom] = useState<number>(null);
  const [absTo, setAbsTo] = useState<number>(null);
  const [relFrom, setRelFrom] = useState<string>(null);
  const [relTo, setRelTo] = useState<string>(null);
  const [fromType, setFromType] = useState<DateFormatType>(null);
  const [toType, setToType] = useState<DateFormatType>(null);
  const [selectedTab, setSelectedTab] = useState<string | number>(getSelectedQuickTab());

  useEffect(() => {
    setAbsFrom(timeRange.from);
    setAbsTo(timeRange.to);
    setFromType(timeRangeUtils.isRelativeTime(timeRange.fromLabel) ? "relative" : "absolute");
    setToType(timeRangeUtils.isRelativeTime(timeRange.toLabel) ? "relative" : "absolute");
  }, [timeRange, hideTimeSelect, open]);

  const setRelValuesAndTypes = useCallback(() => {
    if (timeRangeUtils.isRelativeTime(timeRange.fromLabel)) {
      setRelFrom(timeRange.fromLabel);
      setFromType("relative");
    }
    if (timeRangeUtils.isRelativeTime(timeRange.toLabel)) {
      setRelTo(timeRange.toLabel);
      setToType("relative");
    }
  }, [timeRange.fromLabel, timeRange.toLabel]);

  useEffect(() => {
    setRelValuesAndTypes();
  }, [setRelValuesAndTypes]);

  const { tenantConfigState, onAddCustomTimeRange } = useTenantConfig();
  const { customTimeRanges: usrCustomTimeRanges = [] } = tenantConfigState || {};

  const { notifyError } = useNotifications();
  const timeRangeOptionsForLast = useMemo(
    () => timeRangeUtils.getTimeRangeOptionsForLast(hideTimeSelect),
    [hideTimeSelect]
  );
  const timeRangeOptionsForNext = useMemo(
    () => timeRangeUtils.getTimeRangeOptionsForNext(hideTimeSelect),
    [hideTimeSelect]
  );

  const onTimeSelect = useCallback(
    (from: string, to: string, fromType?: DateFormatType, toType?: DateFormatType) => {
      let alignOpts: Partial<TimeAlignOptions> = {};

      if (alignTimeToBeginning) {
        const alignToDay = from.endsWith("M") || from.endsWith("w") || from.endsWith("d");

        const alignToHr = from.endsWith("h");
        const alignToMin = from.endsWith("m");

        alignOpts = {
          alignToDay,
          alignToHr,
          alignToMin,
          alignToNext: true
        };
      }

      const newTimeRange = timeRangeUtils.getTimeRangeFromRaw(
        {
          from,
          to,
          fromType,
          toType,
          timeZone
        },
        alignOpts
      );

      usrOnTimeSelect && usrOnTimeSelect(newTimeRange);
      onClose();
    },
    [alignTimeToBeginning, onClose, timeZone, usrOnTimeSelect]
  );

  const setRelativeFromAndToValues = useCallback(
    (from: string, to: string) => {
      setRelFrom(from);
      setRelTo(to);
      setFromType("relative");
      setToType("relative");
    },
    [setRelTo, setRelFrom]
  );

  const setAbsRelFromAndToValues = useCallback(
    (from: number, to: string) => {
      setAbsFrom(from);
      setRelTo(to);
      setFromType("absolute");
      setToType("relative");
    },
    [setRelTo]
  );

  const relativeTimeRangeSelected = useCallback(
    (range: TimeRangeOption) => {
      const { from, to } = range;
      if (range?.type === "abs_rel") {
        setAbsRelFromAndToValues(parseInt(from, 10), to);
      } else {
        setRelativeFromAndToValues(from, to);
      }
      onTimeSelect(from, to);
    },
    [onTimeSelect, setAbsRelFromAndToValues, setRelativeFromAndToValues]
  );

  const relativeDurationTimeSelected = useCallback(
    (value: IncSelectorDuration) => {
      const { duration, durationSuffix } = value;

      let from;
      let to;
      if (selectedTab === QUICK_TABS.LAST) {
        from = `now-${duration}${durationSuffix}`;
        to = "now";
      } else {
        from = "now";
        to = `now+${duration}${durationSuffix}`;
      }
      setRelativeFromAndToValues(from, to);
      onTimeSelect(from, to);
    },
    [onTimeSelect, selectedTab, setRelativeFromAndToValues]
  );

  const onCustomTimeRangeChanged = useCallback(
    (property: "from" | "to", newDate: Date | string, type: DateFormatType) => {
      if (typeof newDate === "object") {
        const millis = timeRangeUtils.getMillisFromTimeZone(newDate, timeZone);
        if (property === "from") {
          setAbsFrom(millis);
          setFromType(type);
        } else {
          setAbsTo(millis);
          setToType(type);
        }
      } else {
        if (property === "from") {
          setRelFrom(newDate);
          setFromType(type);
        } else {
          setRelTo(newDate);
          setToType(type);
        }
      }
    },
    [timeZone]
  );

  const getIncDate = useCallback(
    (dateVal: number | string, label: string, getMillis?: boolean) => {
      if (dateVal?.toString() === BEGINNING_OF_TIME_VALUE || dateVal?.toString() === END_OF_TIME_VALUE) {
        return new Date();
      }
      if (dateVal && typeof dateVal === "number") {
        return getDateBasedOnTimeZone(dateVal, timeZone);
      } else if (dateVal && typeof dateVal === "string") {
        const range = label === "from" ? timeRangeUtils.getFrom(dateVal) : timeRangeUtils.getTo(dateVal);
        if (getMillis) {
          return range.millis;
        }
        return getDateBasedOnTimeZone(range.millis, timeZone);
      } else {
        return null;
      }
    },
    [timeZone]
  );

  const isSpecialCase = useCallback(
    () => relFrom === BEGINNING_OF_TIME_VALUE || relTo === END_OF_TIME_VALUE,
    [relFrom, relTo]
  );

  const onCustomTimeRangeApply = useCallback(() => {
    let fromStr = "";
    let toStr = "";
    if (fromType === "relative" && toType === "absolute" && !isSpecialCase()) {
      notifyError("End time cannot be absolute when Start time is relative");
    } else {
      if (fromType && toType) {
        fromStr =
          fromType === "absolute"
            ? absFrom.toString()
            : relFrom === BEGINNING_OF_TIME_VALUE
              ? relFrom
              : getIncDate(relFrom, "from", true).toString();
        toStr =
          toType === "absolute"
            ? absTo.toString()
            : relTo === END_OF_TIME_VALUE
              ? relTo
              : getIncDate(relTo, "to", true).toString();
      } else {
        fromStr = absFrom.toString();
        toStr = absTo.toString();
      }
      if (fromStr === BEGINNING_OF_TIME_VALUE || toStr === END_OF_TIME_VALUE) {
        onTimeSelect(
          fromType === "absolute" ? fromStr : relFrom,
          toType === "absolute" ? toStr : relTo,
          fromType,
          toType
        );
      } else {
        if (fromStr > toStr) {
          notifyError("Start time cannot be greater than end time");
        } else if (!allowSameTimes && fromStr === toStr) {
          notifyError("Start time cannot be same as end time");
        } else {
          onTimeSelect(
            fromType === "absolute" ? fromStr : relFrom,
            toType === "absolute" ? toStr : relTo,
            fromType,
            toType
          );
        }
      }
    }
  }, [
    absFrom,
    absTo,
    allowSameTimes,
    fromType,
    getIncDate,
    isSpecialCase,
    notifyError,
    onTimeSelect,
    relFrom,
    relTo,
    toType
  ]);

  const fromDate = useMemo(() => getIncDate(absFrom, "from") as Date, [absFrom, getIncDate]);
  const toDate = useMemo(() => getIncDate(absTo, "to") as Date, [absTo, getIncDate]);

  const getDateTabs = useMemo<IncTab[]>(() => {
    const quickTabs: Record<QuickTab, IncTab> = {
      Last: {
        id: QUICK_TABS.LAST,
        labelId: "time.range.selector.last"
      },
      Next: {
        id: QUICK_TABS.NEXT,
        labelId: "time.range.selector.next"
      }
    };
    return tabs.map(tab => quickTabs[tab]);
  }, [tabs]);

  const [duration, setDuration] = useState<IncSelectorDuration>({
    duration: 1,
    durationType: RelativeDurationType.DAYS,
    durationSuffix: RelativeDurationSuffix.DAYS
  });

  const getRelativeTimeRangeOptions = useCallback(
    (timeRangeOptions: Record<number, TimeRangeOption[]>, tabs: QuickTab[]) => {
      const wrapperClassName =
        tabs.length > 1
          ? "options-container inc-flex-row-wrap with-inc-tab-bar flex-gap-12"
          : "options-container inc-flex-row-wrap flex-gap-12";

      const clonedTimeRangeOptions = clone(timeRangeOptions);

      const filteredTimeRanges: Record<number, TimeRangeOption[]> = {};
      if (restrictedTimeRange) {
        const restrictedFromMillis = new Date(restrictedTimeRange?.from.toDate()).getTime();
        Object.keys(clonedTimeRangeOptions).forEach(key => {
          const numericKey = parseInt(key, 10);
          filteredTimeRanges[numericKey] = clonedTimeRangeOptions[numericKey].filter(range => {
            const { from } = timeRangeUtils.getTimeRangeMillisFromRaw(range);
            return from >= restrictedFromMillis;
          });
        });
      }

      const quickTimeRangeOptions = restrictedTimeRange ? filteredTimeRanges : clonedTimeRangeOptions;

      return (
        <div className={wrapperClassName}>
          {map(quickTimeRangeOptions, (rangeOptions, key) =>
            rangeOptions.map((rangeOption, index) => {
              const className = cx("inc-cursor-pointer padding2", styleClass, {
                active: rangeOption.from === timeRange.fromLabel && rangeOption.to === timeRange.toLabel
              });
              const renderKey = [key, index].join("-");

              return (
                <VerticallyCenteredRow
                  className={className}
                  key={renderKey}
                  onClick={() => relativeTimeRangeSelected(rangeOption)}
                >
                  {rangeOption.label}
                </VerticallyCenteredRow>
              );
            })
          )}
        </div>
      );
    },
    [relativeTimeRangeSelected, restrictedTimeRange, timeRange.fromLabel, timeRange.toLabel]
  );

  const customTimeRangeList = useMemo(() => {
    if (!usrCustomTimeRanges?.length) {
      return null;
    }

    const clonedTimeRangeOptions: Record<number, TimeRangeOption[]> = {};
    const customTimeRangeOptions: TimeRangeOption[] =
      usrCustomTimeRanges?.map(item => ({
        from: item?.from,
        to: item?.to,
        label: item?.label
      })) || [];

    if (customTimeRangeOptions?.length) {
      const lengthTimeRangeOptions = Object.keys(clonedTimeRangeOptions)?.length;
      clonedTimeRangeOptions[lengthTimeRangeOptions] = customTimeRangeOptions;
    }

    const filteredTimeRanges: Record<number, TimeRangeOption[]> = {};
    if (restrictedTimeRange) {
      const restrictedFromMillis = new Date(restrictedTimeRange?.from.toDate()).getTime();
      Object.keys(clonedTimeRangeOptions).forEach(key => {
        const numericKey = parseInt(key, 10);
        filteredTimeRanges[numericKey] = clonedTimeRangeOptions[numericKey].filter(range => {
          const { from } = timeRangeUtils.getTimeRangeMillisFromRaw(range);
          return from >= restrictedFromMillis;
        });
      });
    }

    const options = restrictedTimeRange ? filteredTimeRanges : clonedTimeRangeOptions;

    return (
      <div className="options-container inc-flex-row-wrap paddingBt8 paddingTp8 flex-gap-12">
        {map(options, (rangeOptions, key) =>
          rangeOptions.map((rangeOption, index) => {
            const className = cx("inc-cursor-pointer padding2", styleClass, {
              active: rangeOption.from === timeRange.fromLabel && rangeOption.to === timeRange.toLabel
            });
            const renderKey = [key, index].join("-");

            return (
              <VerticallyCenteredRow
                className={className}
                key={renderKey}
                onClick={() => relativeTimeRangeSelected(rangeOption)}
              >
                {rangeOption.label}
              </VerticallyCenteredRow>
            );
          })
        )}
      </div>
    );
  }, [usrCustomTimeRanges, relativeTimeRangeSelected, restrictedTimeRange, timeRange.fromLabel, timeRange.toLabel]);

  const {
    isOpen: isSaveCustomTrModalOpen,
    open: onOpenSaveCustomTrModal,
    close: onCloseSaveCustomTrModal
  } = useToggleState();

  const onSaveCustomTimeRange = useCallback(
    (customTrToSave: TimeRange, customTrLabel: string, isCustomTrDefault: boolean, shouldApply: boolean) => {
      const customTimeRange: TCCustomTimeRange = {
        from: customTrToSave.raw.from,
        to: customTrToSave.raw.to,
        label: customTrLabel,
        isDefault: isCustomTrDefault
      };
      onAddCustomTimeRange(customTimeRange);
      onCloseSaveCustomTrModal();

      if (shouldApply) {
        onTimeSelect(customTrToSave.raw.from, customTrToSave.raw.to);
      }
    },
    [onAddCustomTimeRange, onCloseSaveCustomTrModal, onTimeSelect]
  );

  const isCustomTr = useMemo(() => {
    if (isNaN(absFrom) || isNaN(absTo)) {
      return true;
    }

    if (!usrCustomTimeRanges) {
      return false;
    }

    return Boolean(usrCustomTimeRanges.find(item => item.from === String(absFrom) && item.to === String(absTo)));
  }, [absFrom, absTo, usrCustomTimeRanges]);

  const isQuickTr = useMemo(() => {
    if (isNaN(absFrom) || isNaN(absTo)) {
      return true;
    }

    const lastTrOpts = Object.values(timeRangeOptionsForLast).flat();
    const nextTrOpts = Object.values(timeRangeOptionsForNext).flat();

    return Boolean([...lastTrOpts, ...nextTrOpts].find(item => item.from === relFrom && item.to === relTo));
  }, [absFrom, absTo, relFrom, relTo, timeRangeOptionsForLast, timeRangeOptionsForNext]);

  const currentTimeRange = useMemo<TimeRange>(() => {
    if (isNaN(absFrom) || isNaN(absTo)) {
      return null;
    }

    const rawTimeRange: RawTimeRange = {
      from: fromType === "absolute" ? String(absFrom) : relFrom,
      to: toType === "absolute" ? String(absTo) : relTo,
      fromType,
      toType
    };

    return timeRangeUtils.getTimeRangeFromRaw(rawTimeRange);
  }, [absFrom, absTo, fromType, relFrom, relTo, toType]);

  return (
    <>
      <IncClickAway onClickAway={onClose}>
        {el => (
          <IncPopper
            anchorEl={anchorEl}
            className="time-range-selector-popper"
            offset={{
              x: 0,
              y: 8
            }}
            placement={"bottom"}
            ref={el}
            show={open}
          >
            <div className="time-range-selector-wrapper">
              {showTimeZone && (
                <div className="time-zone-selector">
                  <TimezoneSelector
                    includeUtc
                    label="Time zone"
                    onChange={onChangeTimeZone}
                    skipIcon
                    timeZone={timeZone}
                    width="240px"
                  />
                </div>
              )}

              <div
                className="time-range-selector"
                data-cy="time-range-selector-content"
              >
                <div
                  className="time-range-selector--absolute"
                  data-cy="time-range-selector-absolute"
                >
                  <div className="title">Custom</div>
                  {!isNaN(absFrom) && !isNaN(absTo) && (
                    <div className="time-range-selector-from-to-section">
                      <div className="marginBt16">
                        <IncARDateTimePicker
                          allowFutureDates={allowFutureDates}
                          enableBeginningAndEndTimes={enableBeginningAndEndTimes}
                          endDate={toDate}
                          format={pFormat}
                          hideRelativeSelection={Boolean(restrictedTimeRange)}
                          hideTimeSelect={hideTimeSelect}
                          i18nDisabled={i18nDisabled}
                          id="time-range-selector-absolute-from"
                          label="From"
                          maxDate={restrictedTimeRange?.to?.toDate()}
                          minDate={restrictedTimeRange?.from?.toDate()}
                          onChange={(newDate, type) => onCustomTimeRangeChanged("from", newDate, type)}
                          relVal={relFrom}
                          startDate={fromDate}
                          tab={fromType}
                          timeZone={timeZone}
                          value={fromDate}
                          withSeconds={withSeconds}
                        />
                      </div>

                      <div className="marginBt16">
                        <IncARDateTimePicker
                          allowFutureDates={allowFutureDates}
                          enableBeginningAndEndTimes={enableBeginningAndEndTimes}
                          endDate={toDate}
                          format={pFormat}
                          hideRelativeSelection={Boolean(restrictedTimeRange)}
                          hideTimeSelect={hideTimeSelect}
                          i18nDisabled={i18nDisabled}
                          id="time-range-selector-absolute-to"
                          label="To"
                          maxDate={restrictedTimeRange?.to?.toDate()}
                          minDate={restrictedTimeRange?.from?.toDate()}
                          onChange={(newDate, type) => onCustomTimeRangeChanged("to", newDate, type)}
                          relVal={relTo}
                          selectsEnd
                          startDate={fromDate}
                          tab={toType}
                          timeZone={timeZone}
                          value={toDate}
                          withSeconds={withSeconds}
                        />
                      </div>
                      <VerticallyCenteredRow className="flex-gap-12">
                        <IncButton
                          color="primary"
                          id="time-range-selector-absolute-apply"
                          onClick={onCustomTimeRangeApply}
                        >
                          Apply
                        </IncButton>

                        {!restrictedTimeRange && canSaveCustomTimeRange && !isCustomTr && !isQuickTr && (
                          <IncButton
                            color="link"
                            onClick={onOpenSaveCustomTrModal}
                            size="small"
                          >
                            Save as custom
                          </IncButton>
                        )}
                      </VerticallyCenteredRow>
                    </div>
                  )}

                  {!restrictedTimeRange && (
                    <>
                      <div className="duration-selector-area">
                        <div className="label-text">{selectedTab}</div>
                        <IncDurationSelector
                          duration={duration}
                          hideLabel={true}
                          hideTimeValues={hideTimeSelect}
                          onChange={setDuration}
                        />
                      </div>

                      <IncButton
                        className="relative-duration-time-selector"
                        color="primary"
                        onClick={() => relativeDurationTimeSelected(duration)}
                      >
                        Apply
                      </IncButton>
                    </>
                  )}

                  {absFrom?.toString() !== BEGINNING_OF_TIME_VALUE && (!absFrom || !absTo) && (
                    <span>
                      <IncFaIcon
                        className="marginRt8"
                        iconName="spinner"
                        spin
                      />
                      <FormattedMessage id="common.actions.loading.data.text" />
                    </span>
                  )}
                </div>

                {!hideQuickTabs && (
                  <div
                    className="time-range-selector--relative"
                    data-cy="time-range-selector-relative"
                  >
                    <div className="title">Quick</div>

                    {tabs.length > 1 && (
                      <IncTabBar
                        disableRouteChange={true}
                        handleSelection={setSelectedTab}
                        selected={selectedTab}
                        tabs={getDateTabs}
                      />
                    )}

                    {!hideCustomTimeRangeList && customTimeRangeList}

                    {selectedTab === QUICK_TABS.LAST
                      ? getRelativeTimeRangeOptions(timeRangeOptionsForLast, tabs)
                      : getRelativeTimeRangeOptions(timeRangeOptionsForNext, tabs)}
                  </div>
                )}
              </div>
            </div>
          </IncPopper>
        )}
      </IncClickAway>

      {isSaveCustomTrModalOpen && (
        <SaveCustomTimeRangeModal
          defaultTimeRange={currentTimeRange}
          onClose={onCloseSaveCustomTrModal}
          onSaveCustomTimeRange={onSaveCustomTimeRange}
        />
      )}
    </>
  );
};

const styleClass = css`
  width: calc(33% - 8px);
`;
