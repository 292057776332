import {
  IncClickAwayPopper,
  IncErrorIcon,
  IncFaIcon,
  IncModal,
  IncModalProps,
  IncSelectOption,
  IncToolTip
} from "@inception/ui";
import React, { Component, createRef, RefObject } from "react";
import { VerticallyCenteredRow } from "../../../../../../components";
import { EntityPropertyValue } from "../../../../../../core";
import { ActionTemplateElementProp } from "../../../../../../services/api/operationalise";
import {
  EVENT_GROUPBY_FIELDS_PROP_ID,
  EVENT_GROUPBY_IMPACTED_WIDGET_PROP_ID,
  EVENT_GROUPBY_MAX_ROWS_PROP_ID,
  updateBlockDataInEditorState
} from "../../utils";
import { getCommonStateFromProps, getErrorStateForSection } from "../propToState";
import { CustomBlockProps, CustomBlockState } from "../types";
import { EventGroupByPropertiesEditor } from "./EventGroupByPropertiesEditor";

type State = CustomBlockState & {
  diagnosticFieldsProperty: ActionTemplateElementProp;
  impactedWidgetsProperty: ActionTemplateElementProp;
  maxRowsProperty: ActionTemplateElementProp;
  secondaryProperties: ActionTemplateElementProp[];
  hasError: boolean;
};

export class EventGroupByRenderer extends Component<CustomBlockProps, State> {
  private ref: RefObject<HTMLDivElement> = createRef();

  constructor(props: CustomBlockProps) {
    super(props);

    const partState = getStateFromProps(props);

    this.state = {
      isSettingsOpen: false,
      isPropertiesOpen: false,
      hasError: false,
      ...partState
    };

    props.blockProps.onErrors?.(partState.errors, partState.section.sectionId);
  }

  setReadOnly = () => {
    const { onChangeReadOnly } = this.props.blockProps;
    onChangeReadOnly(true);
  };

  unSetReadOnly = () => {
    const { onChangeReadOnly } = this.props.blockProps;
    onChangeReadOnly(false);
  };

  openSettings = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({
      isSettingsOpen: true
    });
  };

  closeSettings = () => {
    this.setState({
      isSettingsOpen: false
    });
  };

  openProperties = (e: React.MouseEvent) => {
    this.setReadOnly();

    e.stopPropagation();
    this.setState({
      isPropertiesOpen: true,
      isSettingsOpen: false
    });
  };

  closeProperties = () => {
    this.unSetReadOnly();

    this.setState({
      isPropertiesOpen: false,
      isSettingsOpen: false,
      section: getCommonStateFromProps(this.props).section
    });
  };

  onApplyChanges = () => {
    this.unSetReadOnly();

    const { block, blockProps } = this.props;
    const { onEditorStateChange, onErrors, beforeEditorStateChange } = blockProps;

    const { section } = this.state;

    const blockKey = block.getKey();
    onEditorStateChange(prevEditorState => {
      beforeEditorStateChange();

      const nextEditorState = updateBlockDataInEditorState(prevEditorState, blockKey, {
        section
      });
      return nextEditorState;
    });

    const errorState = getErrorStateForSection(this.state);
    this.setState({
      isPropertiesOpen: false,
      isSettingsOpen: false,
      ...errorState
    });

    onErrors(errorState.errors, section.sectionId);
  };

  setHasError = (hasError: boolean) => {
    this.setState({ hasError });
  };

  render() {
    const { blockProps, block, children } = this.props;
    const { onSectionDelete, onErrors } = blockProps;

    const {
      isSettingsOpen,
      isPropertiesOpen,
      section,
      diagnosticFieldsProperty,
      impactedWidgetsProperty,
      maxRowsProperty,
      secondaryProperties,
      sectionProperties,
      hasError,
      isValid,
      displayErrorMessage
    } = this.state;

    const onDelete = () => {
      onSectionDelete(block.getKey());
      onErrors([], section.sectionId);
    };

    const propsExist = sectionProperties?.length > 0;
    const { prop } = section;

    const propValue = prop?.propValue || {};

    const onPropertiesChange = (propValues: Record<string, EntityPropertyValue>) => {
      this.setState(prevState => ({
        section: {
          ...prevState.section,
          prop: {
            propValue: propValues
          }
        }
      }));
    };

    const impactedWidgetOpts = getOptionsForProperty(impactedWidgetsProperty);
    const diagnosticFieldsOpts = getOptionsForProperty(diagnosticFieldsProperty);

    const impactedWidgetValue = propValue?.[EVENT_GROUPBY_IMPACTED_WIDGET_PROP_ID]?.stringVal;
    const selImpactedWidgetOpt = impactedWidgetOpts.find(opt => opt.value === impactedWidgetValue);

    const diagFieldValues = propValue?.[EVENT_GROUPBY_FIELDS_PROP_ID]?.setValue?.values || [];
    const selDiagFieldsOpts = diagnosticFieldsOpts.filter(opt => diagFieldValues.includes(opt.value));

    const [min, max] = maxRowsProperty?.ranges || [];
    const maxRowsMin = (min?.longVal || 1)?.toString();
    const maxRowsMax = max?.longVal ? max?.longVal.toString() : undefined;

    const impactedWidgetName = selImpactedWidgetOpt?.label || "Select Impacted Widget";
    const diagFieldNames = selDiagFieldsOpts.map(opt => opt.label).join(", ");
    const maxRows = propValue?.[EVENT_GROUPBY_MAX_ROWS_PROP_ID]?.longVal || maxRowsProperty?.defaultValue?.longVal || 0;

    const displayText = `Top ${maxRows} ${diagFieldNames} for ${impactedWidgetName}`;

    const actions: IncModalProps["actions"] = {
      primary: {
        id: "common.actions.apply",
        color: "secondary-blue",
        disabled: hasError,
        onClick: this.onApplyChanges
      },
      secondary: {
        id: "common.actions.cancel",
        color: "secondary-red",
        onClick: this.closeProperties
      }
    };

    return (
      <div
        className="template-editor-v2--section event-group-by-section"
        ref={this.ref}
      >
        <div
          className="section-content"
          contentEditable="false"
          suppressContentEditableWarning
        >
          <VerticallyCenteredRow
            className="inc-text-subtext-medium inc-cursor-pointer"
            onClick={this.openSettings}
          >
            {displayText}

            {!isValid && (
              <IncToolTip
                placement="top"
                showArrow
                titleText={displayErrorMessage}
                variant="error"
              >
                <VerticallyCenteredRow className="error-icon">
                  <IncErrorIcon />
                </VerticallyCenteredRow>
              </IncToolTip>
            )}
          </VerticallyCenteredRow>
        </div>

        <div
          className="marginLt6 editable-content"
          contentEditable="true"
          suppressContentEditableWarning
        >
          {children}
        </div>

        <IncClickAwayPopper
          anchorEl={this.ref.current}
          className="section-settings"
          onClickAway={this.closeSettings}
          placement="bottom-start"
          show={isSettingsOpen}
        >
          <VerticallyCenteredRow>
            {propsExist && (
              <>
                <div
                  className="section-settings--option"
                  onClick={this.openProperties}
                >
                  <IncFaIcon
                    className="marginRt6"
                    iconName="edit"
                  />
                  Edit
                </div>

                <div className="section-settings--separator" />
              </>
            )}

            <div
              className="section-settings--option status-danger"
              onClick={onDelete}
            >
              <IncFaIcon
                className="marginRt6"
                iconName="minus-circle"
              />
              Delete
            </div>
          </VerticallyCenteredRow>
        </IncClickAwayPopper>

        <IncModal
          actions={actions}
          className="section-properties"
          disableFocusOnLoad
          onClose={this.closeProperties}
          show={isPropertiesOpen}
          size="md"
          titleText="Edit"
        >
          <EventGroupByPropertiesEditor
            diagFieldsOpts={diagnosticFieldsOpts}
            diagnosticFieldsProperty={diagnosticFieldsProperty}
            impactedWidgetOpts={impactedWidgetOpts}
            impactedWidgetsProperty={impactedWidgetsProperty}
            maxRows={maxRows}
            maxRowsMax={maxRowsMax}
            maxRowsMin={maxRowsMin}
            maxRowsProperty={maxRowsProperty}
            onChange={onPropertiesChange}
            propValues={propValue}
            secondaryProperties={secondaryProperties}
            selDiagFieldOpts={selDiagFieldsOpts}
            selImpactedWidgetOpt={selImpactedWidgetOpt}
            setHasError={this.setHasError}
          />
        </IncModal>
      </div>
    );
  }
}

const getStateFromProps = (props: CustomBlockProps) => {
  const { sectionProperties, ...restState } = getCommonStateFromProps(props);

  const classifiedProperties = classifyProperties(sectionProperties);

  return {
    ...restState,
    ...classifiedProperties,
    sectionProperties
  };
};

const classifyProperties = (sectionProperties: ActionTemplateElementProp[]) => {
  let impactedWidgetsProperty: ActionTemplateElementProp;
  let diagnosticFieldsProperty: ActionTemplateElementProp;
  let maxRowsProperty: ActionTemplateElementProp;
  const secondaryProperties: ActionTemplateElementProp[] = [];

  sectionProperties.forEach(prop => {
    if (prop.propId === EVENT_GROUPBY_IMPACTED_WIDGET_PROP_ID) {
      impactedWidgetsProperty = prop;
    } else if (prop.propId === EVENT_GROUPBY_FIELDS_PROP_ID) {
      diagnosticFieldsProperty = prop;
    } else if (prop.propId === EVENT_GROUPBY_MAX_ROWS_PROP_ID) {
      maxRowsProperty = prop;
    } else {
      secondaryProperties.push(prop);
    }
  });

  return {
    impactedWidgetsProperty,
    diagnosticFieldsProperty,
    maxRowsProperty,
    secondaryProperties
  };
};

const getOptionsForProperty = (property: ActionTemplateElementProp) => {
  const opts: IncSelectOption[] = [];

  const { ranges, lookupData } = property || {};

  ranges.forEach(rangeValue => {
    const { stringVal } = rangeValue;
    const label = lookupData?.[stringVal] || stringVal;

    if (label) {
      opts.push({
        value: stringVal,
        label
      });
    }
  });

  return opts;
};
