import React, { FC, useMemo, useCallback, useState, useEffect, useRef } from "react";
import {
  IncAsyncSelect,
  IncSelectOption,
  IncMultiSelectOptionRenderer,
  IncMultiSelectValueContainerRenderer,
  IncSelectProps
} from "@inception/ui";
import { isEqual, uniqWith, debounce } from "lodash";
import { InputActionMeta } from "react-select";
import { TimeRange, useTimeRange } from "../core";
import { searchEntities2, sortEntityOptions } from "../utils";

interface Props {
  entityTypeId: string;
  propName?: string;
  selection: IncSelectOption[];
  onSelectionChange: (nSelection: IncSelectOption[]) => void;

  initialOptions?: IncSelectOption[];
  displayLabel?: string;
  className?: string;
  applySelectionOnBlur?: boolean;

  cohortId?: string;
  maxEntries?: number;
  timeRange?: TimeRange;
  debounceIntervalMillis?: number;
  fetchEntitiesCallback?: (inputValue: string) => Promise<IncSelectOption[]>;
  matchType?: "startsWith" | "contains";
  isDisabled?: boolean;

  components?: IncSelectProps<IncSelectOption, true>["components"];
}

const DEFAULT_DEBOUNCE_INTERVAL = 500;

export const EntityPropertySelect: FC<Props> = props => {
  const {
    entityTypeId,
    // propName,
    displayLabel = "",
    // maxEntries = 50,
    timeRange: pTimeRange,
    cohortId,
    initialOptions,
    selection,
    onSelectionChange,
    className,
    applySelectionOnBlur = false,
    debounceIntervalMillis = DEFAULT_DEBOUNCE_INTERVAL,
    fetchEntitiesCallback,
    // matchType = 'contains',
    isDisabled = false,
    components: pComponents
  } = props;

  const optsInitialised = useRef(false);
  const prevAppliedSelectionRef = useRef<IncSelectOption[]>(initialOptions || []);
  const [defaultOptions, setDefaultOptions] = useState<IncSelectOption[]>(initialOptions || []);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(selection);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    prevAppliedSelectionRef.current = selection;
    setSelectedOptions(selection);
  }, [selection]);

  const { timeRange: gTimeRange } = useTimeRange();

  const timeRange = pTimeRange || gTimeRange;

  const { startTime, endTime } = useMemo(() => {
    const { from, to } = timeRange;
    return {
      startTime: from.valueOf(),
      endTime: to.valueOf()
    };
  }, [timeRange]);

  const fetchOptions = useCallback(
    async (inputValue: string) => {
      if (fetchEntitiesCallback) {
        const options = await fetchEntitiesCallback(inputValue);

        return inputValue ? options : sortEntityOptions(options);
      }

      return searchEntities2(entityTypeId, inputValue, startTime, endTime, cohortId);
    },
    [cohortId, endTime, entityTypeId, fetchEntitiesCallback, startTime]
  );

  const initialiseOptions = useCallback(async () => {
    setOptionsLoading(true);
    if (initialOptions?.length) {
      setDefaultOptions(initialOptions);
      setOptionsLoading(false);
    } else {
      const opts = await fetchOptions("");
      setDefaultOptions(opts);
      setOptionsLoading(false);
    }
  }, [fetchOptions, initialOptions]);

  const onChange = useCallback(
    (opts: readonly IncSelectOption[]) => {
      const nOpts = opts as IncSelectOption[];
      if (applySelectionOnBlur) {
        setSelectedOptions(nOpts);
      } else {
        onSelectionChange(nOpts);
      }
    },
    [applySelectionOnBlur, onSelectionChange]
  );

  const onBlur = useCallback(() => {
    const shouldApplySelection = !isEqual(prevAppliedSelectionRef.current, selectedOptions) && applySelectionOnBlur;
    if (shouldApplySelection) {
      prevAppliedSelectionRef.current = selectedOptions;
      onSelectionChange(selectedOptions);
    }
  }, [applySelectionOnBlur, onSelectionChange, selectedOptions]);

  useEffect(() => {
    if (!optsInitialised.current && entityTypeId) {
      initialiseOptions();
    }
  }, [cohortId, entityTypeId, initialOptions, initialiseOptions]);

  const wrapperClassName = `entity-property-selector ${className || ""}`;
  const appliedPlaceholder = `Select ${displayLabel}`;

  const ValueContainer = useCallback(
    props => (
      <IncMultiSelectValueContainerRenderer
        {...props}
        hideOptionsCount
        showAsPills
        showSelectedOptions
      />
    ),
    []
  );

  const fDefaultOptions = useMemo(() => {
    const nDefaultOptions = [...selectedOptions, ...defaultOptions];
    return uniqWith(nDefaultOptions, (a, b) => a.value === b.value);
  }, [defaultOptions, selectedOptions]);

  const components = useMemo(
    () => ({
      Option: IncMultiSelectOptionRenderer,
      ValueContainer,
      ...pComponents
    }),
    [ValueContainer, pComponents]
  );

  const debouncedLoadOptions = useMemo(
    () =>
      debounce(
        (inputValue: string, callback: (options: IncSelectOption[]) => void) =>
          fetchOptions(inputValue).then((options: IncSelectOption[]) => {
            callback(options);
          }),
        debounceIntervalMillis
      ),
    [debounceIntervalMillis, fetchOptions]
  );

  const loadOptions = useCallback(
    (inputValue: string, callback: (options: IncSelectOption[]) => void) => {
      debouncedLoadOptions(inputValue, callback);
    },
    [debouncedLoadOptions]
  );

  const onInputChange = useCallback(
    (newValue: string, actionMeta: InputActionMeta) => {
      if (actionMeta.action !== "set-value") {
        setInputValue(newValue);
        // value is returned to retain the previous search results after selection
        return newValue;
      }
      // value is returned to retain the previous search results after selection
      return inputValue;
    },
    [inputValue]
  );

  return (
    <IncAsyncSelect
      autoSort={false}
      blurInputOnSelect={false}
      closeMenuOnSelect={!applySelectionOnBlur}
      components={components}
      defaultOptions={fDefaultOptions}
      hideSelectedOptions={false}
      inputValue={inputValue}
      isDisabled={isDisabled}
      isLoading={optionsLoading}
      isMulti
      label={displayLabel}
      loadOptions={loadOptions}
      onBlur={onBlur}
      onChange={onChange}
      onInputChange={onInputChange}
      placeholder={appliedPlaceholder}
      value={selectedOptions}
      wrapperClass={wrapperClassName}
    />
  );
};
