import { forEach, sortBy, map, cloneDeep, size, values } from "lodash";
import { IncSelectOption } from "@inception/ui";
import { MetricInfo } from "../services/datasources/prometheus/core/types";
import { MetricResultDataDTO } from "../services/api/explore";
import { TagFilterSelectionBySliceSet, FilterValuesByTag, TagFilterBySliceSet, TagFilterSelection } from "./types";
import { EntityWidgetData } from "./hooks";

export interface FilterOption extends IncSelectOption {
  rawValue: string;
  displayTag: string;
  tag: string;
}

export const getSelOptionsArr = (selectedSeriesFilter: TagFilterSelectionBySliceSet) => {
  const selOptsMapArr: Array<Map<string, string[]>> = [];

  selectedSeriesFilter.selectionValues.forEach(sv => {
    const { displayTag, selValToDValArr } = sv;

    selValToDValArr.forEach((valsRec, sIdx) => {
      const dVals = Object.values(valsRec);
      const selMap = selOptsMapArr[sIdx] || new Map();
      selMap.set(displayTag, dVals);

      selOptsMapArr[sIdx] = selMap;
    });
  });

  return selOptsMapArr;
};

export const getFilterValuesOpts = (filterValues: FilterValuesByTag[]) => {
  const groupOptionsMap: Map<string, string[]> = new Map();
  const displayValueToValueRec: Record<string, string> = {};
  const fvMap: Record<string, FilterValuesByTag> = {};

  filterValues.forEach(fv => {
    const { displayTag, valToDValRec } = fv;

    const displayValues = Object.values(valToDValRec);
    const sortedDisplayValues = sortBy(displayValues, dv => dv.toLowerCase());
    groupOptionsMap.set(displayTag, sortedDisplayValues);
    forEach(valToDValRec, (dv, v) => (displayValueToValueRec[dv] = v));

    fvMap[displayTag] = fv;
  });

  return {
    groupOptionsMap,
    displayValueToValueRec,
    fvMap
  };
};

export const getGroupOptionsForSchema = (
  schema: MetricInfo[],
  fvMap: Record<string, FilterValuesByTag[]>,
  selection: Map<string, string[]>,
  selBizFilterMap: Map<string, string[]>
) => {
  const groupOptionsInfoMap: Map<string, Map<string, number>> = new Map();
  const displayValueToValueRec: Record<string, string> = {};
  const sizeMap: Map<string, number[]> = new Map();
  const groupOptionsMap: Map<string, string[]> = new Map();

  const filterValues = Object.values(fvMap).map(fv => fv[0]);

  filterValues.forEach(fv => {
    const { displayTag, valToDValRec } = fv;

    const dvToCountMap = new Map<string, number>();
    forEach(valToDValRec, (dv, v) => {
      dvToCountMap.set(dv, 0);
      displayValueToValueRec[dv] = v;
    });
    groupOptionsInfoMap.set(displayTag, dvToCountMap);
  });

  const selMap = cloneDeep(selBizFilterMap);
  let filteredSchema = schema;

  forEach(fvMap, (fvArr, tag) => {
    const fv = (fvArr || [])[0];
    if (fv) {
      const { valToDValRec, displayTag, dependencyTag } = fv;

      if (dependencyTag) {
        const selVals = selection.get(dependencyTag);
        if (selVals) {
          selMap.set(dependencyTag, selVals);
        }
      }

      filteredSchema = getFilteredSchema(filteredSchema, selMap);

      filteredSchema.forEach(sch => {
        const v = sch[tag];
        const displayValue = valToDValRec[v] || v;

        const hasEntry = groupOptionsInfoMap.has(displayTag);
        if (hasEntry) {
          const infoEntry = groupOptionsInfoMap.get(displayTag);
          const hasCountEntry = infoEntry.has(displayValue);
          if (hasCountEntry) {
            const countEntry = infoEntry.get(displayValue) || 0;
            infoEntry.set(displayValue, countEntry + 1);
          }
          groupOptionsInfoMap.set(displayTag, infoEntry);
        }
      });
    }
  });

  // const filteredSchema = getFilteredSchema(schema, selBizFilterMap);

  // filteredSchema.forEach(sch => {
  //   forEach(sch, (v, k) => {
  //     const fv = (fvMap[k] || [])[0];
  //     if (fv) {
  //       const {
  //         valToDValRec,
  //         displayTag
  //       } = fv;

  //       const displayValue = valToDValRec[v] || v;

  //       const hasEntry = groupOptionsInfoMap.has(displayTag);
  //       if (hasEntry) {
  //         const infoEntry = groupOptionsInfoMap.get(displayTag);
  //         const hasCountEntry = infoEntry.has(displayValue);
  //         if (hasCountEntry) {
  //           const countEntry = infoEntry.get(displayValue) || 0;
  //           infoEntry.set(displayValue, countEntry + 1);
  //         }
  //         groupOptionsInfoMap.set(displayTag, infoEntry);
  //       }
  //     }
  //   });
  // });

  groupOptionsInfoMap.forEach((info, displayTag) => {
    const displayValues = Array.from(info.keys());
    const sortedDisplayValues = sortBy(displayValues, dv => dv.toLowerCase());
    const sizeArr = sortedDisplayValues.map(dv => info.get(dv));

    groupOptionsMap.set(displayTag, sortedDisplayValues);
    sizeMap.set(displayTag, sizeArr);
  });

  return {
    sizeMap,
    groupOptions: groupOptionsMap,
    displayValueToValueRec
  };
};

export const getFilterOptions = (filterBySliceSet: TagFilterBySliceSet): Record<string, FilterOption[]> => {
  const options: Record<string, FilterOption[]> = {};
  filterBySliceSet?.filterValues.forEach(fv => {
    const { displayTag, valToDValRec, tag } = fv;

    const opts = map(
      valToDValRec,
      (ev, rv): FilterOption => ({
        label: ev,
        value: ev,
        rawValue: rv,
        displayTag,
        tag
      })
    );
    const sortedOpts = sortBy(opts, opt => opt.label?.toLowerCase());
    options[displayTag] = sortedOpts;
  });
  return options;
};

export const getSelectedFilterOptions = (
  filterSelection: TagFilterSelectionBySliceSet
): Record<string, FilterOption[][]> => {
  const options: Record<string, FilterOption[][]> = {};
  filterSelection?.selectionValues.forEach(fv => {
    const { displayTag, selValToDValArr, tag } = fv;

    const opts: FilterOption[][] = [];

    selValToDValArr.forEach(svRec => {
      const optsSet = map(
        svRec,
        (ev, rv): FilterOption => ({
          label: ev,
          value: ev,
          rawValue: rv,
          displayTag,
          tag
        })
      );
      opts.push(optsSet);
    });

    options[displayTag] = opts;
  });
  return options;
};

export const sanitizeMap = (prevMap: Map<string, string[]>): Map<string, string[]> => {
  const nMap = cloneDeep(prevMap);
  const keys = Array.from(prevMap.keys());
  keys.forEach(key => {
    const value = nMap.get(key);
    if (!value.length) {
      nMap.delete(key);
    }
  });
  return nMap;
};

export const checkIfFiltersExist = (selectedFilters: TagFilterSelection) => {
  const { filtersBySliceSet, bizFilter: eBizFilter, disabledSeries } = selectedFilters;

  const filterBySliceSetHasValues = (fBySS: TagFilterSelectionBySliceSet) => {
    const selExists = (fBySS.selectionValues || []).reduce((sAcc, sCurr) => {
      const sValsExist = sCurr.selValToDValArr.reduce((svAcc, svCurr) => svAcc || size(svCurr) > 0, false);
      return sAcc || sValsExist;
    }, false);
    return selExists;
  };

  const bizFilterExists = eBizFilter ? filterBySliceSetHasValues(eBizFilter) : false;
  const filtersBySliceSetExists = filtersBySliceSet.reduce(
    (fAcc, curr) => fAcc || filterBySliceSetHasValues(curr),
    false
  );
  const disabledSeriesExists = values(disabledSeries).reduce((acc, cur) => acc || cur.length > 0, false);

  return bizFilterExists || filtersBySliceSetExists || disabledSeriesExists;
};

export const extractMetricInfoFromEntityWidgetData = (
  dataResponse: EntityWidgetData[],
  usePostAggResultMeta = false
): MetricResultDataDTO[] => {
  const nSchema: MetricResultDataDTO[] = [];
  dataResponse.forEach(dr => {
    const data = (usePostAggResultMeta ? dr.postAggResult?.data : dr.data) || {};
    forEach(data, d => {
      const sch: MetricResultDataDTO = {
        ...d,
        data: []
      };
      nSchema.push(sch);
    });
  });
  return nSchema;
};

const getFilteredSchema = (schema: MetricInfo[], sel: Map<string, string[]>): MetricInfo[] =>
  (schema || []).filter(sch => {
    let shouldInclude = true;
    forEach(sch, (v, k) => {
      const selVals = sel.get(k);
      if (selVals?.length > 0) {
        shouldInclude = shouldInclude && selVals.includes(v);
      }
    });
    return shouldInclude;
  });
