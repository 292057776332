import { useCallback } from "react";
import { MetricTableColumnInfo } from "../types";
import { WidgetResponseDTO, exploreApiService } from "../../../services/api/explore";
import { useFetchDataStatus } from "../../../core";

type WidgetResponseDTOMap = Record<string, WidgetResponseDTO>;

export const useFetchWidgetResponsesMap = () => {
  const { data, error, isError, isFetching, isSuccess, setStatus } = useFetchDataStatus<WidgetResponseDTOMap, string>({
    data: null,
    isFetching: true
  });

  const fetchWidgetResponseDTOMap = useCallback(
    async (metricColumns: MetricTableColumnInfo[]) => {
      setStatus(null, true, false, false, null);
      try {
        const widgetConfigList = await Promise.all(
          metricColumns.map(async column => {
            const { queryConfig, id } = column;
            const { queryType, metricQueryConfig } = queryConfig;
            const { entityTypeId, bizDataQuery } = metricQueryConfig || {};
            if (queryType === "metric" && bizDataQuery) {
              const { id: widgetId, widgetConfig } = bizDataQuery;

              if (widgetId) {
                const data = await exploreApiService.getWidgetConfig(null, entityTypeId, widgetId);
                return {
                  key: id,
                  value: data.data
                };
              } else if (widgetConfig) {
                const widgetConfigDto = exploreApiService.getDtoFromWidgetConfig(widgetConfig);
                const data = await exploreApiService.getQuerySchemaForDraftWidgetConfig(widgetConfigDto);
                return {
                  key: id,
                  value: {
                    querySchema: {
                      querySchema: data.data
                    },
                    version: 1,
                    widgetConfig: widgetConfigDto,
                    widgetId: ""
                  }
                };
              }
              return {};
            }
            return {};
          })
        );

        const widgetResponseDTOMap = widgetConfigList.reduce((acc, data) => {
          if (data.key) {
            acc[data.key] = data.value;
          }
          return acc;
        }, {} as WidgetResponseDTOMap);
        setStatus(widgetResponseDTOMap, false, true, false, null);
      } catch (e) {
        setStatus(null, false, false, true, (e as Error).message);
      }
    },
    [setStatus]
  );

  return {
    data,
    error,
    isError,
    isFetching,
    isSuccess,
    fetchWidgetResponseDTOMap
  };
};
