import { IncButton, IncFaIcon, IncModal, IncTextfield, generateId } from "@inception/ui";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { KPIQueryEditor, KPIQueryEditorProps, VerticallyCenteredRow } from "..";
import { UberOperationalizeTypes } from "../../services/api/operationalise";
import { useTimeRange, useFetchEventTypesForAddWidget, logger } from "../../core";
import { getDefaultWidgetConfigDto, getWidgetConfigFromDto } from "../../utils/ExploreUtils";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { DemoDataParams, WidgetConfigUtils } from "../../services/api/explore";

interface Props extends Omit<KPIQueryEditorProps, "kpiQuery" | "onStateChange"> {
  defaultName?: string;
  demoDataParams?: DemoDataParams;
  defEventType?: string;
  saveBtnLabel?: string;
  onSaveKPI: (opBizDataQuery: UberOperationalizeTypes.OpBizDataQuery) => void;
  modalProps?: {
    onClose: () => void;
  };
}

export const AddKPIQueryEditor: FC<Props> = props => {
  const {
    onSaveKPI,
    saveBtnLabel = "Save",
    defEventType,
    demoDataParams,
    modalProps,
    defaultName,
    ...addKPIProps
  } = props;

  const asModal = Boolean(modalProps);
  const { onClose } = modalProps || {};

  const [isFetching, setIsFetching] = useState(!defEventType);

  const { timeRange } = useTimeRange();
  const { fromMillis, toMillis } = useMemo(() => timeRangeUtils.getMillisFromTimeRange(timeRange), [timeRange]);

  const {
    error,
    exploreUserservices,
    fetchExploreUserservices,
    isError,
    isFetching: isUsFetching
  } = useFetchEventTypesForAddWidget(demoDataParams);

  useEffect(() => {
    if (!defEventType) {
      setTimeout(() => setIsFetching(false), 100);
      fetchExploreUserservices(fromMillis, toMillis);
    }
  }, [defEventType, fetchExploreUserservices, fromMillis, toMillis]);

  useEffect(() => {
    if (isError && !isUsFetching) {
      logger.error("AddKPIQueryEditor", "Error fetching userservices", error);
    }
  }, [error, isError, isUsFetching]);

  const eventTypeId = defEventType || exploreUserservices?.[0]?.entityId;

  useEffect(() => {
    if (eventTypeId) {
      const widgetConfigDto = getDefaultWidgetConfigDto(null, eventTypeId, null, null, null);
      const metricId = generateId();
      widgetConfigDto.name = defaultName || "";
      widgetConfigDto.dataDefinition.metrics = {
        [metricId]: {
          id: metricId,
          name: defaultName || "",
          sourceType: "userServiceField",
          userServiceFieldMetricConfig: {
            aggregator: "count",
            eventFilters: {
              userServiceFilters: []
            },
            sliceSets: [
              {
                slices: []
              }
            ],
            userServiceField: null
          }
        }
      };

      setKPIState(prev => ({
        ...prev,
        kpiQuery: {
          bizDataQuery: {
            widgetConfig: getWidgetConfigFromDto(widgetConfigDto),
            sliceSpec: {
              selectorSpec: {
                filters: []
              },
              sliceSet: {
                slices: []
              },
              metricId
            },
            idProps: {
              primary: {
                bizEntityTypeId: "",
                eventTypes: {
                  userServiceInfo: [{ userServiceEntityId: eventTypeId }]
                }
              },
              secondary: {}
            }
          },
          rollingFreq: null,
          rollingFunction: null
        }
      }));
    }
  }, [defaultName, eventTypeId]);

  const eventTypesExist = defEventType ? true : exploreUserservices.length > 0;

  const [kpiState, setKPIState] = useState<KPIQueryState>({
    isValid: true,
    errorsText: "",
    kpiQuery: null
  });

  const onStateChangeInternal = useCallback(
    (isValid: boolean, errorsText: string, kpi: UberOperationalizeTypes.OpBizDataQuery) => {
      setKPIState({
        isValid,
        errorsText,
        kpiQuery: kpi
      });
    },
    []
  );

  const { kpiQuery, isValid, errorsText } = kpiState;
  const onSaveInternal = useCallback(() => {
    onSaveKPI(kpiQuery);
  }, [kpiQuery, onSaveKPI]);

  const saveAndCancelActionsJsx = (
    <VerticallyCenteredRow className="flex-gap-12">
      <IncButton
        color="primary"
        disabled={!isValid}
        label={saveBtnLabel}
        onClick={onSaveInternal}
      />

      {asModal && (
        <IncButton
          color="secondary-red"
          label="Cancel"
          onClick={onClose}
        />
      )}

      {!isValid && Boolean(errorsText) && (
        <VerticallyCenteredRow className="flex-gap-8 inc-text-subtext-medium status-danger">
          <IncFaIcon iconName="exclamation-triangle" />
          {errorsText}
        </VerticallyCenteredRow>
      )}
    </VerticallyCenteredRow>
  );

  const kpiBizDataQuery = kpiQuery?.bizDataQuery;
  const name = useMemo(() => WidgetConfigUtils.getNameFromBizDataQuery(kpiBizDataQuery), [kpiBizDataQuery]);

  const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setKPIState(prev => {
      if (prev) {
        const nextBizDataQuery = {
          ...prev.kpiQuery.bizDataQuery
        };
        WidgetConfigUtils.setNameForBizDataQuery(nextBizDataQuery, e.target.value);
        return {
          ...prev,
          kpiQuery: {
            ...prev.kpiQuery,
            bizDataQuery: nextBizDataQuery
          }
        };
      }

      return prev;
    });
  }, []);

  const queryEditorJsx = (
    <div className="inc-flex-column flex-gap-12 width-100">
      {isFetching && (
        <VerticallyCenteredRow
          className="width-100"
          data-show-loader={true}
          style={{ height: 150 }}
        />
      )}
      {!isFetching && (
        <>
          {!eventTypesExist && (
            <VerticallyCenteredRow className="width-100">No Event Types exist</VerticallyCenteredRow>
          )}

          {eventTypesExist && kpiQuery && (
            <KPIQueryEditor
              kpiQuery={kpiQuery}
              onStateChange={onStateChangeInternal}
              {...addKPIProps}
            >
              <IncTextfield
                onChange={onNameChange}
                placeholder="Key Performance Indicator"
                value={name}
              />
            </KPIQueryEditor>
          )}
        </>
      )}

      {!asModal && saveAndCancelActionsJsx}
    </div>
  );

  return (
    <>
      {asModal && (
        <IncModal
          className="inc-regular-modal"
          footerChildren={saveAndCancelActionsJsx}
          onClose={onClose}
          show
          showClose
          size="lg"
        >
          {queryEditorJsx}
        </IncModal>
      )}
      {!asModal && queryEditorJsx}
    </>
  );
};

type KPIQueryState = {
  isValid: boolean;
  errorsText: string;
  kpiQuery: UberOperationalizeTypes.OpBizDataQuery;
};
