import { isEqual, sortBy } from "lodash";
import { generateId } from "@inception/ui";
import { DashboardBuilder } from "../../../dashboard/dashboard-builder/DashboardBuilder";
import { TimeObj, SelectorTag } from "../../../services/api/explore";
import { DashboardImpl, WidgetLayout } from "../../../dashboard";
import { getCompareSeriesOverrides } from "../../../components/incidentTimeSeries/TimeSeriesWidgetHelper";
import { ENTITY_TAG, getMillisFromTimeObj } from "../../../utils";
import { Op10zeDataQuery } from "../../../services/datasources/operationalize/types";
import Op10zeWidgetModel from "../../../dashboard/widgets/Operationalize/models/model";
import appConfig from "../../../../appConfig";
import { Label, MonitoredSeriesDef } from "../../../services/api/operationalise";
import { shouldExcludeTag } from "../../../core";

export const getTrainingDashboardImpl = (
  op10zeId: string,
  simulationId: string,
  selectedFilters: Map<string, string[]>,
  selectedFrequencies: TimeObj[],
  entityTypeName: string,
  entityLookUpData: Record<string, string>,
  combinationVsFreq: Record<string, TimeObj[]>,
  series: MonitoredSeriesDef[],
  getCustomPayload?: (tags: SelectorTag[]) => Record<string, any>
) => {
  const dashboardBuilder = DashboardBuilder("Training Dashboard").dynamic(true);
  let y = 0;

  const validCombinationsForFrequency = new Set(Object.keys(combinationVsFreq));
  if (selectedFrequencies.length) {
    const selFreqMillis = getMillisFromTimeObj(selectedFrequencies[0]);
    validCombinationsForFrequency.forEach(combinationStr => {
      const arr = combinationVsFreq[combinationStr] || [];
      const selectedTimeObj = arr.find(timeObj => {
        const combFreqMillis = getMillisFromTimeObj(timeObj);
        return combFreqMillis === selFreqMillis;
      });
      if (!selectedTimeObj) {
        validCombinationsForFrequency.delete(combinationStr);
      }
    });
  }

  const nSeries = getSortedSelectedCombinationSeries(series, selectedFilters, selectedFrequencies);
  nSeries.forEach(serie => {
    const { timeSeries, freq } = serie;
    const { label } = timeSeries;
    const { frequency, lookBack, seasonality } = freq;
    const filterLabel = label.filter(e => !shouldExcludeTag(e.name, true));
    const { tags: selectorTags, title } = getTagCombination(
      filterLabel.map(e => e.value),
      filterLabel.map(e => e.name),
      entityTypeName,
      entityLookUpData
    );

    let customPayload = {};
    if (getCustomPayload) {
      customPayload = getCustomPayload(selectorTags);
    }
    const id = generateId();

    const model: Op10zeWidgetModel = {
      datasource: appConfig.defaultOp10zeDsName,
      id,
      queries: [
        {
          payload: {
            selectorSpec: {
              filters: [
                {
                  tags: selectorTags
                }
              ]
            },
            forCount: false,
            frequency,
            lookBack,
            ...(customPayload || {})
          },
          opId: op10zeId,
          simulationId,
          refId: "A"
        } as Op10zeDataQuery
      ],
      title,
      type: "operationalize",
      mode: "data",
      seriesOverrides: getCompareSeriesOverrides(),
      chartType: "spline",
      seasonality: seasonality,
      lookBack: lookBack,
      meta: {
        resizable: false,
        compare: false,
        edit: false
      }
    };

    const layout: WidgetLayout = {
      w: 24,
      h: 10,
      x: 0,
      y
    };

    y += 10;

    dashboardBuilder.addWidget(model, layout);
  });

  const dbModel = dashboardBuilder.build();
  dbModel.meta.syncTooltip = false;
  return {
    dbImpl: new DashboardImpl(dbModel),
    numMatchSeries: validCombinationsForFrequency.size
  };
};

const getSortedSelectedCombinationSeries = (
  pSeries: MonitoredSeriesDef[],
  selectedFilters: Map<string, string[]>,
  selectedFrequencies: TimeObj[]
): MonitoredSeriesDef[] => {
  const series = sortBy(pSeries, serie => {
    const serieFreqMillis = serie?.freq?.frequency ? getMillisFromTimeObj(serie?.freq?.frequency) : 0;
    return serieFreqMillis;
  });
  const nSeries: MonitoredSeriesDef[] = [];

  for (let index = 0; index < series.length; index++) {
    if (nSeries.length >= 30) {
      break;
    }
    const serie = series[index];
    const { frequency } = serie?.freq || {};
    const { label } = serie?.timeSeries || {};
    const tagsMatch = selectedFilters?.size
      ? [...selectedFilters.keys()].every(key => {
          const values = selectedFilters.get(key) || [];
          const currValue = label?.find(e => e.name === key)?.value;
          return values.includes(currValue);
        })
      : true;
    const frequencyMatch = selectedFrequencies?.length
      ? selectedFrequencies?.some(freq => isEqual(freq, frequency))
      : true;

    const tagComboStr = getTagCombinationStr(label || []);
    const freqMillis = frequency ? getMillisFromTimeObj(frequency) : 0;
    const alreadyExists = nSeries.find(nSerie => {
      const nComboStr = getTagCombinationStr(nSerie?.timeSeries?.label || []);
      const nFreqMillis = nSerie?.freq?.frequency ? getMillisFromTimeObj(nSerie?.freq?.frequency) : 0;
      if (tagComboStr && nComboStr && nFreqMillis && freqMillis) {
        return tagComboStr === nComboStr && freqMillis === nFreqMillis;
      }
      return false;
    });

    if (frequencyMatch && tagsMatch && !alreadyExists) {
      nSeries.push(serie);
    }
  }
  return nSeries;
};

const getTagCombinationStr = (labels: Label[]): string =>
  labels.reduce((a, b) => {
    if (!shouldExcludeTag(b.name, true)) {
      return `${a}${b.name}:${b.value}`;
    }
    return a;
  }, "");

const getTagCombination = (
  combination: string[],
  keys: string[],
  entityTypeName: string,
  entityLookupData: Record<string, string>
): TagCombination => {
  let title = "";

  const { length } = combination;

  const selectorTags: SelectorTag[] = combination.map((value, idx) => {
    const key = keys[idx];

    const displayKey = key === ENTITY_TAG ? entityTypeName : key;
    const displayValue = entityLookupData[value] || value;

    title += `${displayKey}: ${displayValue}`;
    title += idx === length - 1 ? "" : " , ";

    return {
      key,
      value: [value]
    };
  });

  return {
    title,
    tags: selectorTags
  };
};

type TagCombination = {
  title: string;
  tags: SelectorTag[];
};
