import { TimeObj, TimeObjUnit } from "../../services/api/explore";
import { BizIdProps, OpSchedule } from "../../services/api/operationalise";
import { isValidCohortId } from "../../utils";
import { defRollingFreq } from "../constants";
import { OpContext } from "./types";

export const getIdProps = (context: OpContext): BizIdProps => {
  const idProps: BizIdProps = {
    primary: {},
    secondary: {}
  };

  const { cohortDefinition, entityTypeId, widgetId, eventTypeId } = context;

  if (entityTypeId) {
    idProps.primary.bizEntityTypeId = entityTypeId;
  }

  if (eventTypeId) {
    idProps.primary.eventTypes = {
      userServiceInfo: [
        {
          userServiceEntityId: eventTypeId
        }
      ]
    };
  }

  const { cohortId = "" } = cohortDefinition || {};

  if (isValidCohortId(cohortId)) {
    idProps.secondary.cohortId = cohortId;
  }

  idProps.secondary.widgetId = widgetId;

  return idProps;
};

export const getRollingFreqFromSchedule = (opSchedule: OpSchedule): TimeObj => {
  let rollingFreq = {
    ...defRollingFreq
  };

  const specSchedule = opSchedule?.schedule?.specificScheduleConfig;
  if (specSchedule) {
    const { dayOfMonth, dayOfWeek, hour, minute, month } = specSchedule;

    if (month?.step || month?.range || month?.list) {
      rollingFreq = {
        unit: TimeObjUnit.months,
        value: month?.step || 1
      };
    } else if (dayOfWeek?.step || dayOfWeek?.range || dayOfWeek?.list) {
      rollingFreq = {
        unit: TimeObjUnit.days,
        value: 1
      };
    } else if (dayOfMonth?.step || dayOfMonth?.range || dayOfMonth?.list) {
      rollingFreq = {
        unit: TimeObjUnit.days,
        value: 1
      };
    } else if (hour?.step || hour?.range || hour?.list) {
      rollingFreq = {
        unit: TimeObjUnit.hours,
        value: hour?.step || 1
      };
    } else if (minute?.step) {
      rollingFreq = {
        unit: TimeObjUnit.minutes,
        value: minute?.step
      };
    }
  }

  return rollingFreq;
};
