import React, { useMemo, useCallback } from "react";
import { TreeMapOptions, IncTreeMap } from "../../../../../components";
import { eventFieldUtils } from "../../../../../utils";
import { DataType } from "../../../../../core";
import { constructPieSeries } from "./PieChartRenderer";
import { CatalogVizRendererProps, getFormattedValue, useCommonRendererFunctionality } from "./common";

export const TreeMapRenderer = (props: CatalogVizRendererProps) => {
  const {
    className,
    dataType: defDataType,
    dataTypeByMetricId,
    loadingElement,
    noDataElement,
    aggregatedTags: aggregatedTag,
    displayAggregatedTags,
    currencyType,
    properties
  } = props;

  const { useNegationColors, negationColors } = properties;

  const { data, fieldName, metricName, isFetching, isError, dataExists, childMetricNames, hasChildMetrics } =
    useCommonRendererFunctionality(props);

  const { series, drillDownSeries } = useMemo(
    () =>
      constructPieSeries(
        data,
        aggregatedTag,
        displayAggregatedTags,
        defDataType,
        dataTypeByMetricId,
        fieldName,
        metricName,
        childMetricNames,
        hasChildMetrics,
        useNegationColors,
        negationColors
      ),
    [
      data,
      aggregatedTag,
      displayAggregatedTags,
      defDataType,
      dataTypeByMetricId,
      fieldName,
      metricName,
      childMetricNames,
      hasChildMetrics,
      useNegationColors,
      negationColors
    ]
  );

  const valueFormatterFn = useCallback(
    (value: string | number, dataType = defDataType) => getFormattedValue(fieldName, value, dataType, currencyType),
    [fieldName, defDataType, currencyType]
  );

  const options: TreeMapOptions = useMemo(
    () => ({
      tooltipValueFormatter: (value, custom) => valueFormatterFn(value, custom?.dataType as DataType)
    }),
    [valueFormatterFn]
  );

  if (isFetching) {
    return loadingElement;
  }

  if (!dataExists || isError) {
    return noDataElement;
  }

  return (
    <IncTreeMap
      containerElemClass={className}
      drillDownSeries={drillDownSeries}
      options={options}
      series={series}
      seriesName={eventFieldUtils.removeFieldsPrefix(fieldName)}
    />
  );
};
