import React, { FC, useCallback, useMemo, useEffect, useRef } from "react";
import { IncSelectOption, IncSelect } from "@inception/ui";
import { isEqual } from "lodash";
import { cx } from "emotion";
import { CypressConstants } from "@bicycle/tests";
import { PeerThreshold } from "../../services/api/operationalise";
import { OpContext } from "../context/types";
import {
  FieldPickerOptionData,
  UserServiceField,
  Slice,
  FieldPickerContextDTO,
  UserServiceFieldSliceSet,
  SliceSet,
  compareUSFields,
  DemoDataParams
} from "../../services/api/explore";
import { VerticallyCenteredRow, FieldPickerContainer } from "../../components";
import { convertToSliceSet } from "../../utils/ExploreUtils";
import { ENTITY_TAG, FieldPickerUtils, pluralizeWord } from "../../utils";
import { useFieldPicker } from "../../field-picker";
import { useTimeRange } from "../../core";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { isFieldOperationalise } from "../utils";

interface Props {
  peerThreshold: PeerThreshold;
  onChange: (peerThreshold: PeerThreshold) => void;
  pickerContext: OpContext;
  metricSliceSet: UserServiceFieldSliceSet | SliceSet;

  disableSliceChangeForField?: boolean;
  readOnly?: boolean;
  demoDataParams?: DemoDataParams;
}

type Option = IncSelectOption<Slice>;

export const PeerSelector: FC<Props> = props => {
  const {
    onChange,
    peerThreshold,
    pickerContext,
    metricSliceSet,
    disableSliceChangeForField = false,
    readOnly,
    demoDataParams
  } = props;

  const { getLatestTimeRange } = useTimeRange();

  const initialisedRef = useRef(false);

  const { peerOfField, peerOfSlice } = peerThreshold;

  const {
    entityTypeId,
    eventTypeId,
    entityTypeName = entityTypeId,
    eventTypeName = eventTypeId,
    bizEntityFieldName
  } = pickerContext;

  const contextDto = useMemo<FieldPickerContextDTO>(
    () => ({
      entityType: entityTypeId,
      bizEntityType: entityTypeId,
      userServices:
        entityTypeId && eventTypeId
          ? [
              {
                userServiceEntityId: eventTypeId
              }
            ]
          : [],
      userServiceToBizEntityFieldName: bizEntityFieldName && eventTypeId ? { [eventTypeId]: bizEntityFieldName } : {},
      entityId: !entityTypeId ? eventTypeId : null,
      entityName: null,
      showFields: true
    }),
    [bizEntityFieldName, entityTypeId, eventTypeId]
  );

  const isMetricOperationalise = !isFieldOperationalise(pickerContext);

  const peerFieldSelection = useMemo<FieldPickerOptionData[]>(
    () =>
      peerOfField
        ? [
            {
              payload: peerOfField,
              type: "userServiceField"
            }
          ]
        : [],
    [peerOfField]
  );

  const { data: fieldPickerModel, getFields, isFetching, isError } = useFieldPicker();

  const fieldPickerClassName = cx("inc-flex-grow", {
    disableClick: isFetching
  });

  // SliceSet is changed when the metric/field selection changes
  useEffect(() => {
    initialisedRef.current = false;
  }, [metricSliceSet]);

  useEffect(() => {
    if (!isMetricOperationalise) {
      const timeRange = getLatestTimeRange();
      const { fromMillis, toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);
      getFields(contextDto, fromMillis, toMillis);
    }
  }, [contextDto, getFields, getLatestTimeRange, isMetricOperationalise]);

  useEffect(() => {
    if (!isMetricOperationalise && !peerOfField && fieldPickerModel && !isFetching && !initialisedRef.current) {
      initialisedRef.current = true;
      onChange({
        ...peerThreshold,
        peerOfField: fieldPickerModel.getEventIDField() || fieldPickerModel.getEntityUSField(bizEntityFieldName)
      });
    }
  }, [bizEntityFieldName, fieldPickerModel, isFetching, isMetricOperationalise, onChange, peerOfField, peerThreshold]);

  const onPeerFieldChange = useCallback(
    (fieldSel: FieldPickerOptionData[]) => {
      const selField = fieldSel[0];

      if (selField && selField.type === "userServiceField") {
        const peerOfField = selField.payload as UserServiceField;
        const nPeerThreshold = {
          ...peerThreshold,
          peerOfField
        };

        delete nPeerThreshold.peerOfSlice;

        onChange(nPeerThreshold);
      }
    },
    [onChange, peerThreshold]
  );

  const onPeerSliceChange = useCallback(
    (sliceOpt: Option) => {
      const { data: peerOfSlice } = sliceOpt;
      const nPeerThreshold = {
        ...peerThreshold,
        peerOfSlice
      };

      delete nPeerThreshold.peerOfField;

      onChange(nPeerThreshold);
    },
    [onChange, peerThreshold]
  );

  const sliceOptions = useMemo<Option[]>(() => {
    if (metricSliceSet) {
      const { slices } = convertToSliceSet(metricSliceSet);
      const options = slices.map((slice): Option => {
        const { tagName } = slice;
        const label = tagName === ENTITY_TAG ? entityTypeName : tagName;
        return {
          label,
          value: tagName,
          data: slice
        };
      });
      return options;
    }

    return [];
  }, [entityTypeName, metricSliceSet]);

  const selSliceOption = useMemo(
    () => sliceOptions.find(({ data }) => isEqual(data, peerOfSlice)),
    [peerOfSlice, sliceOptions]
  );

  useEffect(() => {
    if (!peerOfSlice && sliceOptions.length && isMetricOperationalise && !initialisedRef.current) {
      initialisedRef.current = true;
      onPeerSliceChange(sliceOptions[0]);
    }
  }, [isMetricOperationalise, onPeerSliceChange, peerOfSlice, sliceOptions]);

  const displayPeerOfField = useMemo(() => {
    if (peerOfField) {
      const entityUSField = fieldPickerModel?.getEntityUSField(bizEntityFieldName);
      const eventIDField = fieldPickerModel?.getEventIDField();

      if (compareUSFields(entityUSField, peerOfField)) {
        return `All ${pluralizeWord(entityTypeName)}`;
      } else if (compareUSFields(eventIDField, peerOfField)) {
        return `All ${pluralizeWord(eventTypeName)}`;
      }

      return FieldPickerUtils.getPromSanitizedUSFName(peerOfField);
    }

    return "";
  }, [bizEntityFieldName, entityTypeName, eventTypeName, fieldPickerModel, peerOfField]);

  const { attributes } = CypressConstants.components.Operationalize.ThresholdEditor;

  return (
    <VerticallyCenteredRow className="peer-field-selector">
      {isMetricOperationalise && (
        <>
          {(sliceOptions.length > 1 || !selSliceOption) && (
            <IncSelect
              classNamePrefix={attributes.peerSliceSelector}
              data-cy={attributes.peerSliceSelector}
              label=""
              onChange={onPeerSliceChange}
              options={sliceOptions}
              placeholder="Choose slice"
              readOnly={readOnly}
              value={selSliceOption}
            />
          )}
          {sliceOptions.length === 1 && selSliceOption && (
            <div className="inc-text-subtext-medium inc-text-inactive">{selSliceOption.label}</div>
          )}
        </>
      )}

      {!isMetricOperationalise && (
        <VerticallyCenteredRow className={fieldPickerClassName}>
          {!isError && (
            <>
              {!disableSliceChangeForField && (
                <FieldPickerContainer
                  data-cy={attributes.peerFieldSelector}
                  demoDataParams={demoDataParams}
                  fieldPickerContextDto={contextDto}
                  fieldPickerModel={fieldPickerModel}
                  fieldTypes={["userServiceField"]}
                  hideLabel
                  label=""
                  onChange={onPeerFieldChange}
                  readOnly={readOnly}
                  selectedOptions={peerFieldSelection}
                />
              )}
              {disableSliceChangeForField && (
                <div className="inc-text-subtext-medium inc-text-inactive">{displayPeerOfField}</div>
              )}
            </>
          )}
          {isError && <div className="status-danger">Error fetching fields</div>}
        </VerticallyCenteredRow>
      )}
    </VerticallyCenteredRow>
  );
};
