import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IncButton, IncFaIcon, IncMultiSelectCheckBox, IncPopper, IncSelectOption } from "@inception/ui";
import { isEqual } from "lodash";
import { css } from "emotion";
import { WidgetCustomAction } from "../../../types";
import { UserServiceFieldSliceSet } from "../../../../../services/api/explore";
import { useToggleState } from "../../../../../core";
import { VerticallyCenteredRow } from "../../../../../components";
import { ENTITY_TAG } from "../../../../../utils";

type Props = {
  isFunnelConfigFetching: boolean;
  sliceSets: UserServiceFieldSliceSet[];

  sliceTags: string[];
  setSliceTags: (sliceTags: string[]) => void;

  bizEntityType: string;
};

export const useSelectAggregateTags = (props: Props) => {
  const { isFunnelConfigFetching } = props;

  const viewFiltersAction = useMemo<WidgetCustomAction | null>(() => {
    if (isFunnelConfigFetching) {
      const tooltipText = "Loading slices...";
      const actionComponent = (
        <IncFaIcon
          fade
          iconName="pie-chart"
        />
      );

      return {
        actionComponent,
        showInHeader: true,
        tooltipText
      };
    }

    const tooltipText = "Group by";
    const actionComponent = <GroupBySelector {...props} />;

    return {
      actionComponent,
      showInHeader: true,
      tooltipText
    };
  }, [isFunnelConfigFetching, props]);

  return viewFiltersAction;
};

const GroupBySelector: FC<Props> = memo(props => {
  const { setSliceTags, sliceSets, sliceTags, bizEntityType } = props;

  const defSelectionOpts = useMemo<IncSelectOption[]>(() => {
    const sliceOptions: IncSelectOption[] = (sliceTags || []).map(tagName => ({
      label: tagName === ENTITY_TAG ? bizEntityType : tagName,
      value: tagName
    }));

    return sliceOptions;
  }, [bizEntityType, sliceTags]);

  const [selectedOptions, setSelectedOptions] = useState(defSelectionOpts);
  useEffect(() => {
    setSelectedOptions(prevSelection => (!isEqual(defSelectionOpts, prevSelection) ? defSelectionOpts : prevSelection));
  }, [defSelectionOpts]);

  const sliceOptions = useMemo<IncSelectOption[]>(() => {
    const sliceTagsSet = new Set<string>();

    (sliceSets || []).forEach(sliceSet => {
      sliceSet.slices.forEach(slice => {
        sliceTagsSet.add(slice.tagName);
      });
    });

    const sliceOptions: IncSelectOption[] = Array.from(sliceTagsSet).map(tagName => ({
      label: tagName === ENTITY_TAG ? bizEntityType : tagName,
      value: tagName
    }));

    return sliceOptions;
  }, [bizEntityType, sliceSets]);

  const iconRef = useRef<HTMLDivElement>();

  const { close, isOpen, open } = useToggleState();

  const updateSelection = useCallback(() => {
    const nSelection = selectedOptions.map(opt => opt.value);
    setSliceTags(nSelection);
    close();
  }, [close, selectedOptions, setSliceTags]);

  const discardSelection = useCallback(() => {
    setSelectedOptions(defSelectionOpts);
    close();
  }, [close, defSelectionOpts]);

  return (
    <>
      <VerticallyCenteredRow ref={iconRef}>
        <VerticallyCenteredRow
          className="inc-flex-center-vertical svg-icon-with-overlay inc-cursor-pointer"
          onClick={open}
        >
          <IncFaIcon iconName="pie-chart" />
        </VerticallyCenteredRow>
      </VerticallyCenteredRow>

      <IncPopper
        anchorEl={iconRef.current}
        className="padding16"
        placement="bottom-end"
        show={isOpen}
      >
        <div className="inc-text-inactive marginBt8">Group by</div>

        <IncMultiSelectCheckBox
          defaultOptions={selectedOptions}
          hideOptionsCount
          onChange={setSelectedOptions}
          options={sliceOptions}
          placeholder="Select"
          showAsPills
          showSelectedOptions
          skipAllOption
          wrapperClass={widthClassName}
        />

        <VerticallyCenteredRow className="marginTp12">
          <IncButton
            className="marginRt12"
            color="primary"
            onClick={updateSelection}
          >
            Save
          </IncButton>

          <IncButton
            color="link"
            onClick={discardSelection}
          >
            Cancel
          </IncButton>
        </VerticallyCenteredRow>
      </IncPopper>
    </>
  );
});

const widthClassName = css`
  min-width: 300px;
`;
