import React, { useMemo, useState, useEffect } from "react";
import { WidgetExtProps } from "../types";
import { VariableType, CohortFilterType } from "../../models/VariableModel";
import CohortVariableImpl from "../../model-impl/CohortVariableImpl";
import { ExploreType } from "../../../services/api/explore";
import { LoadingSpinner } from "../../../components";
import { EventFilters } from "../widget-components/EventFilters";
import { EntityFilters } from "../widget-components/EntityFilters";
import { FilterWidgetImpl } from "./models";

interface Props extends WidgetExtProps {
  widget: FilterWidgetImpl;
}

export const FiltersWidget: React.FC<Props> = (props: Props) => {
  const { variableSrvMap, onVariablesUpdate, onUpdateCohortId, widget, variableLoadingStateMap } = props;

  const { id: widgetId, entityTypeId, userServiceId } = widget;
  const [filterType, setFilterType] = useState<ExploreType>(entityTypeId ? "bizEntity" : "userService");
  const [entityOrUserServiceId, setEntityOrUserServiceId] = useState<string>(
    entityTypeId ? entityTypeId : userServiceId
  );

  const variableSrvLoading = useMemo(() => variableLoadingStateMap[widgetId], [variableLoadingStateMap, widgetId]);

  const variableSrv = useMemo(() => variableSrvMap[widgetId], [variableSrvMap, widgetId]);

  const entityVariables = useMemo(() => {
    if (!variableSrvLoading) {
      const cohortVariables = variableSrv
        .getVariables()
        .filter(v => v.type === VariableType.Cohort) as CohortVariableImpl[];
      const entityVariables = cohortVariables.filter(cv => cv.filterType === CohortFilterType.Entity);
      if (entityVariables.length > 0) {
        setFilterType("bizEntity");
        setEntityOrUserServiceId(variableSrv.getEntityTypeId());
      }
      return entityVariables;
    }
    return [];
  }, [variableSrv, variableSrvLoading]);

  const eventVariables = useMemo(() => variableSrv.getEventVariables(), [variableSrv]);

  // this logic is depricated and not removed to support backward compatibility
  useEffect(() => {
    if (entityVariables.length > 0) {
      setEntityOrUserServiceId(variableSrv.getEntityTypeId());
      setFilterType("bizEntity");
    } else if (eventVariables.length > 0) {
      setEntityOrUserServiceId(eventVariables[0].userServiceId);
      setFilterType("userService");
    }
  }, [entityVariables.length, eventVariables, variableSrv]);

  const entityFilterComponent = useMemo(
    () => (
      <EntityFilters
        entityTypeId={entityOrUserServiceId}
        onUpdateCohortId={onUpdateCohortId}
        onVariablesUpdate={onVariablesUpdate}
        variableLoadingStateMap={variableLoadingStateMap}
        variableSrv={variableSrv}
        widget={widget}
      />
    ),
    [entityOrUserServiceId, onVariablesUpdate, onUpdateCohortId, variableLoadingStateMap, variableSrv, widget]
  );

  const eventFilterComponent = useMemo(
    () => (
      <EventFilters
        onVariablesUpdate={onVariablesUpdate}
        userServiceId={entityOrUserServiceId}
        variableSrv={variableSrv}
        widget={widget}
      />
    ),
    [entityOrUserServiceId, onVariablesUpdate, variableSrv, widget]
  );

  return (
    <div className="filters-widget">
      {variableSrvLoading && <LoadingSpinner />}
      {filterType === "bizEntity" && entityFilterComponent}
      {filterType === "userService" && eventFilterComponent}
    </div>
  );
};
