import Highcharts, { Options } from "highcharts";
import HCMore from "highcharts/highcharts-more";
import Boost from "highcharts/modules/boost";
import Annotations from "highcharts/modules/annotations";
import React, { FC, useMemo, useCallback, useState, useRef, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import { css, cx } from "emotion";
import { IncToolTip, IncFaIcon, IncLoadingSpinner } from "@inception/ui";
import { useFetchFunnelData } from "../hooks";
import { VerticallyCenteredRow } from "../../../../components";
import { ExploreEntityFilter, SliceSpec, UserServiceFilterList } from "../../../../services/api/explore";
import { useRefState } from "../../../../core";
import { getFunnelChartOptions } from "./utils";

HCMore(Highcharts);
Boost(Highcharts);
Annotations(Highcharts);

Highcharts.AST.bypassHTMLFiltering = true;

Highcharts.setOptions({
  time: {
    useUTC: false
  }
});

interface Props {
  funnelId: string;
  displayLabel: string;

  currentStr: string;
  compareStr: string;
  displayCompareStr: string;
  disableCompare: boolean;

  startTimeMillis: number;
  endTimeMillis: number;
  compareTimeInSeconds: number;

  sliceSpec: SliceSpec[];
  entityFilters: ExploreEntityFilter[];
  eventFilters: UserServiceFilterList;

  variablesLoading: boolean;

  noDataElement?: string | JSX.Element;
}

export const FunnelRenderer: FC<Props> = props => {
  const {
    compareStr,
    displayCompareStr,
    currentStr,
    funnelId,
    compareTimeInSeconds,
    disableCompare,
    startTimeMillis,
    endTimeMillis,
    entityFilters,
    eventFilters,
    sliceSpec,
    noDataElement,
    variablesLoading,
    displayLabel: dataLabel
  } = props;

  const { data, error, isError, isFetching, refetch } = useFetchFunnelData(
    funnelId,
    sliceSpec,
    disableCompare ? null : compareTimeInSeconds,
    startTimeMillis,
    endTimeMillis,
    entityFilters,
    eventFilters
  );

  const refetchFnRef = useRefState(refetch);
  useEffect(() => {
    if (!variablesLoading) {
      const refetch = refetchFnRef.current;
      refetch();
    }
  }, [
    refetchFnRef,
    variablesLoading,
    eventFilters,
    entityFilters,
    sliceSpec,
    startTimeMillis,
    endTimeMillis,
    disableCompare
  ]);

  const dataExists = Boolean(data?.stages?.length);

  const [, setChartRendered] = useState(false);

  const chartContainerRef = useRef<HTMLDivElement>();

  const getChartDimensions = useCallback(() => {
    const canvasElem: HTMLElement = chartContainerRef.current;
    if (canvasElem) {
      const dimensions = canvasElem?.getBoundingClientRect();
      if (dimensions) {
        const { height } = dimensions;
        const { width } = dimensions;
        return {
          width,
          height
        };
      }
    }
    return {
      width: -1,
      height: -1
    };
  }, [chartContainerRef]);

  useEffect(() => {
    if (chartContainerRef.current) {
      setChartRendered(true);
    }
  }, [chartContainerRef]);

  // Calculate dimensions on every render
  const { width, height } = getChartDimensions();
  const chartOptions = useMemo<Options>(
    () =>
      dataExists
        ? getFunnelChartOptions(
            data,
            width,
            height,
            dataLabel,
            currentStr,
            compareStr,
            displayCompareStr,
            disableCompare
          )
        : null,
    [compareStr, currentStr, data, dataExists, dataLabel, disableCompare, displayCompareStr, height, width]
  );

  return (
    <div
      className="biz-journey-funnel--chart"
      ref={chartContainerRef}
    >
      {isFetching && <IncLoadingSpinner className={getClassName()} />}
      {!isFetching && (
        <>
          {isError && (
            <div className={getClassName(true)}>
              <IncToolTip
                placement="top"
                titleText={error}
                variant="error"
              >
                <VerticallyCenteredRow>
                  <IncFaIcon
                    className="marginRt6"
                    iconName="warning"
                  />
                </VerticallyCenteredRow>
              </IncToolTip>
              Error fetching data
            </div>
          )}

          {!isError && (
            <>
              {!dataExists && <div className={getClassName()}>{noDataElement || "No data found"}</div>}
              {dataExists && (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const wrapperClass = css`
  height: 100%;
  width: 100%;
`;

const getClassName = (isError = false) =>
  cx("inc-text-subtext-medium", "inc-flex-row", "inc-flex-center", wrapperClass, {
    "status-danger": isError
  });
