import { logger } from "../../../core";
import { ApptuitDatasource } from "../../datasources/apptuit/ApptuitDatasource";
import datasourceApiManager from "../DatasourceApiService";
import {
  Segment,
  FilteredAlertsApiResponse,
  FilterField,
  CompletionValuesResponseItem,
  CompletionValuesApiResponse,
  DependentFields,
  AlertsFilter,
  CommonAlertsApiParams
} from "./types";

class CommonAlertsApiService {
  private datasource: ApptuitDatasource;
  private readonly alertsUrl = "/api/alert/v2/data";
  private readonly alertsValuesCompletionUrl = "/api/alert/data";

  async fetchAlertResults(
    opId: string,
    startTimeMillis: number,
    endTimeMillis: number,
    simulationId = "",
    additionalData = false,
    lookupData = true
  ): Promise<FilteredAlertsApiResponse> {
    await this.init();

    const urlParams = {
      startTimeMillis,
      endTimeMillis,
      additionalData,
      lookupData
    };

    const url = simulationId
      ? `/api/bizEntity/op10ze/${opId}/incidents/simulation/${simulationId}`
      : `/api/bizEntity/op10ze/${opId}/incidents/live`;

    try {
      const res = await this.datasource.post<FilteredAlertsApiResponse, any>(url, null, { params: urlParams });
      if (res?.data) {
        return res.data;
      } else {
        logger.info("Alert API service", `Empty response for filtered alerts for ${simulationId || "live"}`);
        return;
      }
    } catch (err) {
      logger.error("Alert API service", `Error fetching filtered alerts ${simulationId || "live"}`, err);
      throw new Error(`Error fetching filtered alerts: ${err} ${simulationId || "live"}`);
    }
  }

  async fetchFilteredAlerts(
    segment: Segment,
    startTimeMillis: number,
    endTimeMillis: number,
    filterPayload: FilterField[],
    params?: Partial<CommonAlertsApiParams>
  ): Promise<FilteredAlertsApiResponse> {
    await this.init();

    const alertQueryPredicate = filterPayload.map(filter => {
      const { filterField, value, ...restProps } = filter;

      if (filterField.includes(".")) {
        const [baseKey, subKey] = filterField.split(".");

        return {
          filterField: baseKey as any,
          ...restProps,
          jsonbObject: {
            entries: {
              [subKey]: value
            }
          }
        };
      }

      return filter;
    });

    const payload = {
      alertFilter: [
        {
          alertQueryPredicate
        }
      ]
    };

    const urlParams = {
      ...params,
      startTimeMillis,
      endTimeMillis
    };
    const url = `${this.alertsUrl}/${segment}`;

    try {
      const res = await this.datasource.post<FilteredAlertsApiResponse, any>(url, payload, { params: urlParams });
      if (res?.data) {
        return res.data;
      } else {
        logger.info("Alert API service", `Empty response for filtered alerts for segment ${segment}`);
        return;
      }
    } catch (err) {
      logger.error("Alert API service", `Error fetching filtered alerts for segment ${segment}`, err);
      throw new Error(`Error fetching filtered alerts for segment ${segment}: ${err}`);
    }
  }

  async fetchAlertFilterCompletionValues(
    segment: Segment,
    startTimeMillis: number,
    endTimeMillis: number,
    fieldType: AlertsFilter,
    dependentFields: DependentFields,
    fieldValue?: string
  ): Promise<CompletionValuesResponseItem[]> {
    await this.init();

    const bodyPayload = {
      fieldType,
      fieldValue,
      dependentFields
    };

    const urlParams = {
      startTimeMillis,
      endTimeMillis,
      segment
    };
    const subUrl = "/values";
    const url = `${this.alertsValuesCompletionUrl}${subUrl}`;

    try {
      const { data } = await this.datasource.post<CompletionValuesApiResponse, any>(url, bodyPayload, {
        params: urlParams
      });
      if (data?.values) {
        return data?.values;
      } else {
        logger.info("Alert API service", `Empty completion values response for segment ${segment}`);
        return [];
      }
    } catch (err) {
      logger.error("Alert API service", `Error fetching completion values for segment ${segment}`, err);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(`Error fetching completion values for segment ${segment}: ${err}`);
    }
  }

  private async init() {
    this.datasource = await datasourceApiManager.getDefault();
  }

  private getTimeParams(startTimeMillis: number, endTimeMillis: number) {
    const timeParams = `?startTimeMillis=${startTimeMillis}&endTimeMillis=${endTimeMillis}`;
    return timeParams;
  }
}

const commonAlertsApiService = new CommonAlertsApiService();
export default commonAlertsApiService;
