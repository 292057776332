import {
  IncFaIconName,
  IncGenericIcon,
  IncModal,
  IncModalProps,
  IncSelect,
  IncSelectOption,
  IncTextArea,
  IncTextfield,
  IncToggle
} from "@inception/ui";
import { isEmpty } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { UploadIconPopover, VerticallyCenteredRow } from "../../../components";
import { OpMetaDataV2 } from "../../../services/api/operationalise";

interface Props {
  open: boolean;
  onClose: () => void;
  saveOrUpdateInProgress: boolean;
  onSaveOrUpdate: (opMetaData: OpMetaDataV2) => void;
  defMetaData: OpMetaDataV2;
  hideImportance?: boolean;
  hideCauses?: boolean;
  hideOpportunity?: boolean;
  hideLabels?: boolean;
  hideIcons?: boolean;
}

export const NameAndDescriptionModal: FC<Props> = props => {
  const {
    open,
    defMetaData,
    onClose,
    saveOrUpdateInProgress,
    onSaveOrUpdate,
    hideCauses = false,
    hideIcons = false,
    hideImportance = false,
    hideLabels = false,
    hideOpportunity = false
  } = props;

  const [opMeta, setOpMeta] = useState(defMetaData);

  const resetState = useCallback(() => {
    setOpMeta(defMetaData);
    onClose();
  }, [defMetaData, onClose]);

  useEffect(() => {
    resetState();
  }, [resetState]);

  const { name, description, causes, icon, importance, isOpportunity, labelFragment } = opMeta;

  const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setOpMeta(prev => ({
      ...prev,
      name: e.target.value || ""
    }));
  }, []);

  const onDescriptionChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOpMeta(prev => ({
      ...prev,
      description: e.target.value || ""
    }));
  }, []);

  const onImportanceChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOpMeta(prev => ({
      ...prev,
      importance: e.target.value
    }));
  }, []);

  const onCausesChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOpMeta(prev => ({
      ...prev,
      causes: (e.target.value || "").split("\n")
    }));
  }, []);

  const onIsOpportunityChange = useCallback((checked: boolean) => {
    setOpMeta(prev => ({
      ...prev,
      isOpportunity: checked
    }));
  }, []);

  const onLabelsChange = useCallback((opts: readonly IncSelectOption[]) => {
    setOpMeta(prev => ({
      ...prev,
      labelFragment: opts.map(o => ({
        label: o.label,
        iconColor: ""
      }))
    }));
  }, []);

  const onIconChange = useCallback(
    (icon: IncFaIconName) =>
      setOpMeta(prev => ({
        ...prev,
        icon
      })),
    []
  );

  const onApply = useCallback(() => {
    onSaveOrUpdate(opMeta);
  }, [onSaveOrUpdate, opMeta]);

  const nameHasError = isEmpty(name);
  const disableSave = nameHasError;

  const actions = useMemo<IncModalProps["actions"]>(
    () => ({
      primary: {
        id: "common.actions.save",
        onClick: onApply,
        disabled: disableSave || saveOrUpdateInProgress,
        showLoader: saveOrUpdateInProgress
      },
      secondary: {
        id: "common.actions.cancel",
        onClick: resetState,
        color: "link",
        disabled: saveOrUpdateInProgress
      }
    }),
    [disableSave, onApply, resetState, saveOrUpdateInProgress]
  );

  const iconElem = useMemo(
    () => (
      <IncGenericIcon
        iconName={icon || "image"}
        size={16}
      />
    ),
    [icon]
  );
  const causesStr = useMemo(() => (causes || []).join("\n"), [causes]);
  const labelOptions = useMemo(
    () =>
      (labelFragment || []).map(label => ({
        label: label.label,
        value: label.label
      })),
    [labelFragment]
  );

  return (
    <IncModal
      actions={actions}
      className="inc-regular-modal"
      closeOnBackdrop={!saveOrUpdateInProgress}
      disableFocusOnLoad
      onClose={resetState}
      show={open}
      showBackdrop
      showClose={!saveOrUpdateInProgress}
      size="sm"
      titleText="Edit"
    >
      <div className="inc-flex-column flex-gap-16 width-100 paddingBt12">
        <VerticallyCenteredRow className="flex-gap-12 width-100">
          {!hideIcons && (
            <UploadIconPopover
              iconElem={iconElem}
              noEditText
              onSaveIcon={onIconChange}
              wrapperStyle={{ marginTop: 22 }}
            />
          )}

          <IncTextfield
            errorText="Title is required"
            hasError={nameHasError}
            label="Title"
            onChange={onNameChange}
            placeholder="Type title here"
            required
            value={name}
          />
        </VerticallyCenteredRow>

        <IncTextArea
          label="Description"
          onChange={onDescriptionChange}
          placeholder="Type description here"
          resize="vertical"
          value={description}
        />

        {!hideImportance && (
          <IncTextArea
            label="Importance"
            onChange={onImportanceChange}
            placeholder="Type importance here"
            rows={4}
            value={importance}
          />
        )}

        {!hideCauses && (
          <IncTextArea
            label="Causes"
            onChange={onCausesChange}
            placeholder="Type causes here"
            resize="vertical"
            rows={Math.max(2, (causes?.length || 0) + 1)}
            value={causesStr}
          />
        )}

        {!hideOpportunity && (
          <IncToggle
            checked={isOpportunity}
            label="Opportunity"
            onChange={onIsOpportunityChange}
            variant="success"
          />
        )}

        {!hideLabels && (
          <IncSelect
            allowCreate
            asTagsEditor
            disablePopper
            isMulti
            label="Labels"
            onChange={onLabelsChange}
            placeholder="Labels"
            value={labelOptions}
          />
        )}
      </div>
    </IncModal>
  );
};
