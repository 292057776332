import { DrilldownTypes } from "../../../../../../platform/services/api/triage-v2";

export const cloneChildIdPrefix = "cloned";
export const addChildIdPrefix = "new";
export const cloneChildNamePrefix = "[CLONE]";

export const validateWorkflowStep = (step: DrilldownTypes.DrillDownStep): string[] => {
  const { name, predicate } = step || {};
  const { correlatedEventToolSpec, dimensionalAnalysisToolSpec, googleSearchToolSpec } = predicate || {};

  let availableToolSpecLength = 0;
  if (correlatedEventToolSpec) {
    availableToolSpecLength++;
  }
  if (dimensionalAnalysisToolSpec) {
    availableToolSpecLength++;
  }
  if (googleSearchToolSpec) {
    availableToolSpecLength++;
  }

  const errorMessageList: string[] = [];
  if (!availableToolSpecLength) {
    errorMessageList.push("Tool spec is required");
  } else if (availableToolSpecLength > 1) {
    errorMessageList.push("Tool spec cannot be more than one");
  } else {
    if (!name) {
      errorMessageList.push("Name is required");
    }
    if (dimensionalAnalysisToolSpec) {
      const { query, querySource } = dimensionalAnalysisToolSpec;
      if (!querySource && !query) {
        errorMessageList.push("Query source or Query is required");
      }
    }
    if (googleSearchToolSpec || correlatedEventToolSpec) {
      const { querySource: gQuerySource } = googleSearchToolSpec || {};
      const { querySource: cQuerySource } = correlatedEventToolSpec || {};
      if (!gQuerySource && !cQuerySource) {
        errorMessageList.push("Query source is required");
      }
    }
  }
  return errorMessageList;
};
