import React, { FC, useMemo, useCallback, memo } from "react";
import { IncTextfield, IncSelectOption, IncFaIcon, IncToolTip } from "@inception/ui";
import { CypressConstants } from "@bicycle/tests";
import { VerticallyCenteredRow } from "../../../../components";
import { TimeObjUnit } from "../../../../services/api/explore";
import { WindowTriggerCondition } from "../../../../services/api/operationalise";
import { pluralizeWord } from "../../../../utils";

type WTProps = {
  trigger: WindowTriggerCondition;
  onChange: (trigger: WindowTriggerCondition) => void;

  comparatorText: string;
  scheduleText: string;
};

export const WindowTrigger: FC<WTProps> = memo(props => {
  const { onChange, trigger, comparatorText, scheduleText } = props;

  const { howManyTimesToViolate, outOfPoints, outOfTime } = trigger;

  const { isTimeViolation } = useMemo(() => {
    const violationType: ViolationType =
      outOfTime?.unit === TimeObjUnit.minutes
        ? "mins"
        : outOfTime?.unit === TimeObjUnit.hours
          ? "hrs"
          : outOfTime?.unit === TimeObjUnit.days
            ? "days"
            : "times";
    const violationTypeOpt = violationTypes.find(({ value }) => value === violationType);
    const isTimeViolation = violationType === "mins" || violationType === "hrs" || violationType === "days";
    return {
      isTimeViolation,
      violationTypeOpt
    };
  }, [outOfTime]);

  const onViolationCountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.valueAsNumber;
      const nCondition = {
        ...trigger,
        howManyTimesToViolate: value
      };

      onChange(nCondition);
    },
    [onChange, trigger]
  );

  const onTotalTimeOrPointsChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.valueAsNumber;
      const numValue = isNaN(value) ? 0 : value;

      const outOfPoints = isTimeViolation ? null : numValue;
      const outOfTime = isTimeViolation
        ? {
            value: numValue,
            unit: TimeObjUnit.minutes
          }
        : null;

      const nCondition = {
        ...trigger,
        outOfPoints,
        outOfTime
      };

      onChange(nCondition);
    },
    [isTimeViolation, onChange, trigger]
  );

  const totalTimeOrPoints = isTimeViolation ? outOfTime?.value || 0 : outOfPoints || 0;
  const isValidCondition = isTimeViolation
    ? howManyTimesToViolate > 0
    : howManyTimesToViolate > 0 && howManyTimesToViolate <= totalTimeOrPoints;

  const formattedComparatorText = useMemo(
    () =>
      comparatorText
        .split("/")
        .map(compText => pluralizeWord(compText, howManyTimesToViolate, false))
        .join(" or "),
    [comparatorText, howManyTimesToViolate]
  );

  const { attributes } = CypressConstants.components.Operationalize.TriggersEditor;

  return (
    <>
      <VerticallyCenteredRow className="inc-label-common">
        if there {howManyTimesToViolate <= 1 ? "is" : "are"} atleast
      </VerticallyCenteredRow>

      <IncTextfield
        className="violation-count-textfield"
        data-cy={attributes.violationCountInput}
        onChange={onViolationCountChange}
        type="number"
        value={howManyTimesToViolate}
      />

      <VerticallyCenteredRow className="inc-label-common">{formattedComparatorText} in</VerticallyCenteredRow>

      <IncTextfield
        className="violation-count-textfield"
        data-cy={attributes.totalTimeOrPointsInput}
        onChange={onTotalTimeOrPointsChange}
        type="number"
        value={totalTimeOrPoints}
      />

      <VerticallyCenteredRow className="inc-label-common">
        periods {scheduleText ? `of ${scheduleText} each` : ""}
      </VerticallyCenteredRow>

      {!isValidCondition && (
        <IncToolTip
          placement="top"
          titleText="Times to violate should be less than or equal to the total time or points"
          variant="error"
        >
          <VerticallyCenteredRow
            className="status-danger marginLt10"
            data-cy={attributes.errorMessage}
          >
            <IncFaIcon iconName="warning" />
          </VerticallyCenteredRow>
        </IncToolTip>
      )}
    </>
  );
});

type ViolationType = "times" | "hrs" | "mins" | "days";

const violationTypes: IncSelectOption[] = [
  {
    label: "mins",
    value: "mins"
  },
  {
    label: "hrs",
    value: "hrs"
  },
  {
    label: "days",
    value: "days"
  },
  {
    label: "times",
    value: "times"
  }
];
