import { IncFaIcon, IncFaIconName } from "@inception/ui";
import React, { FC, useCallback, useMemo } from "react";
import { css } from "emotion";
import { IncGradientLoadingSpinner } from "@inception/ui/src/components/GradientLoadingSpinner/GradientLoadingSpinner";
import { ConversationStatus } from "../../services/api/chat";
import { LowLevelFragment } from "../../services/api/chat/types/low-level-fragment";
import { DemoDataParams } from "../../services/api/explore";
import { VerticallyCenteredRow } from "../flex-components";
import { SubStepsRenderer } from "./SubStepsRenderer";

interface IntermediateFragmentListRendererProps {
  fragment: LowLevelFragment;
  demoDataParams?: DemoDataParams;
  status: ConversationStatus;
  isExpanded?: boolean;
  useParentWidth?: boolean;
}

export const IntermediateFragmentListRenderer: FC<IntermediateFragmentListRendererProps> = props => {
  const { fragment, demoDataParams, status, isExpanded } = props || {};
  const { intermediateFragmentList } = fragment || {};
  const { lowLevelFragment: subSteps } = intermediateFragmentList || {};

  const isLoading = status !== ConversationStatus.COMPLETED;

  const getSubStepFragmentJsx = useCallback(
    (subStep: LowLevelFragment, id: number, isLastItem: boolean) => (
      <div
        className="intermediate-fragment inc-flex-row flex-gap-16"
        key={id + (subStep?.id || "")}
      >
        <div className="loading-parent">
          <div className="loading-icon-container">
            {isLoading && isLastItem ? (
              <IncGradientLoadingSpinner />
            ) : (
              <IncFaIcon
                className="status-success"
                iconName={"check-circle"}
                variant="regular"
              />
            )}
          </div>
        </div>
        <SubStepsRenderer
          demoDataParams={demoDataParams}
          idx={id}
          keepLastOpen
          status={status}
          subStep={subStep}
          totalSteps={subSteps?.length}
        />
      </div>
    ),
    [demoDataParams, isLoading, status, subSteps]
  );

  const compressedView = useMemo(() => {
    const stepsCompleted = subSteps.length - 1;
    const iconsJsx = subSteps.map((item, id) => {
      const { fragmentInfo } = item;
      const { label } = fragmentInfo;
      const { iconName, iconColor, backgroundColor } = label;

      const containerClass = css`{
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        border-radius: 34px;
        border: 1px solid ${iconColor || "#4a505c"};
        background: ${backgroundColor || "transparent"};
        margin-left: ${id > 0 ? "-4px" : "0px"};
      }`;

      const iconClass = css`{
        color: ${iconColor ? `${iconColor} !important` : ""};
        width: 12px;
        height: 12px;
      }`;

      return (
        <div
          className={containerClass}
          key={id}
        >
          <IncFaIcon
            className={iconClass}
            iconName={(iconName as IncFaIconName) || "info-circle"}
          />
        </div>
      );
    }, []);

    return (
      <>
        {stepsCompleted > 0 && (
          <VerticallyCenteredRow>
            {iconsJsx}
            <span className="marginLt12 inc-text-medium inc-text-color-secondary">
              {stepsCompleted} steps completed
            </span>
          </VerticallyCenteredRow>
        )}
        {getSubStepFragmentJsx(subSteps[subSteps.length - 1], subSteps.length - 1, true)}
      </>
    );
  }, [getSubStepFragmentJsx, subSteps]);

  const expandedView = useMemo(
    () => (
      <div className="width-100 inc-flex-column flex-gap-24">
        {(subSteps || []).map((item, id) => {
          const isLastItem = id === subSteps?.length - 1;
          return getSubStepFragmentJsx(item, id, isLastItem);
        })}
      </div>
    ),
    [subSteps, getSubStepFragmentJsx]
  );

  return (
    <div className="low-level-fragment-item inc-flex-column flex-gap-16">
      {isExpanded ? expandedView : compressedView}
    </div>
  );
};
