import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { IncDivider, IncSelect, IncSelectOption, IncTextfield } from "@inception/ui";
import { useVerticalConfig } from "../../core";
import { KPI } from "../../services/api";
import { FieldPickerV2 } from "../../components";
import { FieldPickerUtils, noOp } from "../../utils";
import { UserServiceField } from "../../services/api/explore";
import { DrilldownTypes } from "../../services/api/triage-v2";

interface Props {
  drilldownStep: DrilldownTypes.DrillDownStep;
  setDrilldownStep: (drilldownStep: DrilldownTypes.DrillDownStep) => void;
}

export const OpInsightsExplanationEditor: FC<Props> = memo(props => {
  const { drilldownStep, setDrilldownStep } = props;

  const { name } = drilldownStep || {};

  const [fragmentName, setFragmentName] = useState(name);

  useEffect(() => {
    setFragmentName(name);
  }, [name, setFragmentName]);

  const onFragmentNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (drilldownStep) {
        const updatedDrilldownStep: DrilldownTypes.DrillDownStep = {
          ...drilldownStep,
          name: e.target.value
        };

        setDrilldownStep(updatedDrilldownStep);
      }
    },
    [drilldownStep, setDrilldownStep]
  );

  const { useCaseApi } = useVerticalConfig();
  const { getKpisForSelectedUseCases } = useCaseApi;

  const kpiOptions = useMemo<KPIOption[]>(
    () =>
      getKpisForSelectedUseCases().map(kpi => ({
        label: kpi.name,
        value: kpi.id,
        data: kpi
      })),
    [getKpisForSelectedUseCases]
  );

  const selectedKpiOpt = useMemo<KPIOption>(() => {
    const opt = kpiOptions.find(
      kpiOpt => kpiOpt.value === drilldownStep?.predicate?.dimensionalAnalysisToolSpec?.query?.kpiId
    );
    if (opt) {
      return opt;
    }
  }, [drilldownStep, kpiOptions]);

  const onKPIChange = useCallback(
    (kpiOpt: KPIOption) => {
      if (drilldownStep) {
        const updatedDrilldownStep: DrilldownTypes.DrillDownStep = {
          ...drilldownStep,
          predicate: {
            ...drilldownStep?.predicate,
            dimensionalAnalysisToolSpec: {
              ...drilldownStep?.predicate?.dimensionalAnalysisToolSpec,
              query: {
                ...drilldownStep?.predicate?.dimensionalAnalysisToolSpec?.query,
                kpiId: kpiOpt.value
              }
            }
          }
        };

        setDrilldownStep(updatedDrilldownStep);
      }
    },
    [drilldownStep, setDrilldownStep]
  );

  const dimensions = useMemo(() => {
    if (drilldownStep) {
      const slices = drilldownStep?.predicate?.dimensionalAnalysisToolSpec?.dimensions?.slices || [];
      return (slices || []).map(slice => slice.userServiceField);
    }
  }, [drilldownStep]);

  const onDimensionsChange = useCallback(
    (dimensions: UserServiceField[]) => {
      const newSlices = dimensions.map(userServiceField => ({
        tagName: FieldPickerUtils.generateNamefromUSF(userServiceField),
        userServiceField
      }));

      if (drilldownStep) {
        const updatedDrilldownStep: DrilldownTypes.DrillDownStep = {
          ...drilldownStep,
          predicate: {
            ...drilldownStep?.predicate,
            dimensionalAnalysisToolSpec: {
              ...drilldownStep?.predicate?.dimensionalAnalysisToolSpec,
              dimensions: {
                ...drilldownStep?.predicate?.dimensionalAnalysisToolSpec?.dimensions,
                slices: newSlices
              }
            }
          }
        };

        setDrilldownStep(updatedDrilldownStep);
      }
    },
    [drilldownStep, setDrilldownStep]
  );

  return (
    <>
      <div className="op-insights-explanation-editor inc-flex-column flex-gap-24">
        <div className="inc-flex-column flex-gap-8">
          <div className="inc-text-body-medium">Edit Name of your configuration</div>
          <IncTextfield
            containerClassName="width-100"
            onChange={onFragmentNameChange}
            required
            value={fragmentName}
          />
        </div>
        <div className="inc-flex-column flex-gap-8">
          <div className="inc-text-body-medium">Select how you want to investigate insights</div>
          <IncSelect
            className="kpi-selector width-50"
            onChange={noOp}
            options={modeOptions}
            placeholder="Select how you want to investigate insights"
            value={modeOptions[2]}
          />
        </div>
        <IncDivider />
        <div className="inc-flex-column flex-gap-8">
          <div className="inc-text-subheader-medium">Use Dimensional Analysis to check for issues</div>
          <div className="inc-text-subtext-medium inc-text-inactive">
            Categorize data into different dimensions for easier analysis and decision-making.
          </div>
        </div>

        <div className="inc-flex-column flex-gap-8">
          <div className="inc-text-body-medium">Select a KPI to track</div>
          <IncSelect
            className="kpi-selector width-50"
            onChange={onKPIChange}
            options={kpiOptions}
            placeholder="Select a KPI to track"
            value={selectedKpiOpt}
          />
        </div>

        <div className="inc-flex-column flex-gap-8">
          <div className="inc-text-body-medium">Dimensions to analyze</div>
          <FieldPickerV2
            isMulti
            onSelectionChange={onDimensionsChange}
            selection={dimensions}
          />
        </div>
      </div>
      <div className="preview width-100 height-100 inc-flex-center-horizontal inc-flex-center-vertical">
        <div className="inc-text-inactive ">Add details to view a preview</div>
      </div>
    </>
  );
});

type KPIOption = IncSelectOption<KPI>;

const modeOptions: IncSelectOption[] = [
  {
    label: "Related KPI",
    value: "relatedKPIPredicate"
  },
  {
    label: "Correlated Event",
    value: "correlatedEventToolSpec"
  },
  {
    label: "Dimensional Analysis",
    value: "dimensionalAnalysisToolSpec"
  },
  {
    label: "Google Search",
    value: "googleSearchToolSpec"
  }
];
