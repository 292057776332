import { useMemo, useCallback } from "react";
import { InitialState, FetchCallBackResult, useDataFetch } from "../../../core";
import { useCaseApiService, UseCaseDataQueryConfig, WidgetDescription } from "../../../services/api";

type Result = UseCaseDataQueryConfig;

type Props = {
  dataQuery: string;
  verticalId: string;
  subVerticalId: string;
  useCaseName: string;
  companyName: string;
};

export const useFetchDataQueryConfig = (props: Props) => {
  const { dataQuery, subVerticalId, verticalId, companyName, useCaseName } = props;

  const initialData = useMemo<InitialState<Result, string>>(
    () => ({
      data: null,
      error: null,
      isError: false,
      isFetching: true,
      isSuccess: false
    }),
    []
  );

  const fetchDataQueryDef = useCallback(async () => {
    const result: FetchCallBackResult<Result, string> = {
      data: null,
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const widgetDesc: WidgetDescription = {
        name: dataQuery,
        description: ""
      };
      const { data, error, message } = await useCaseApiService.generateDataQueryDef(
        verticalId,
        subVerticalId,
        widgetDesc,
        null,
        useCaseName,
        companyName
      );

      if (error) {
        result.error = message;
        result.isError = true;
      } else {
        result.data = data?.query;
        result.isSuccess = true;
      }
    } catch (e) {
      result.error = e;
      result.isError = true;
    }
    return result;
  }, [companyName, dataQuery, subVerticalId, useCaseName, verticalId]);

  return useDataFetch(fetchDataQueryDef, initialData, true);
};
