import { IncMenuItem } from "@inception/ui";
import { IncSelectOption } from "@inception/ui/src/components/Select";
import { SCHEDULE_TYPES } from "../../../constants";
import { SpecificScheduleConfig } from "../../../../services/api/operationalise";

export const scheduleOptions: Array<IncMenuItem<SpecificScheduleConfig>> = [
  {
    label: "continuously",
    value: SCHEDULE_TYPES.everyMinute,
    data: {
      minute: {
        step: 1
      }
    }
  },
  {
    label: "every 5 mins",
    value: SCHEDULE_TYPES.every5Minutes,
    data: {
      minute: {
        step: 5
      }
    }
  },
  {
    label: "every 15 mins",
    value: SCHEDULE_TYPES.every15Minutes,
    data: {
      minute: {
        step: 15
      }
    }
  },
  {
    label: "every 30 mins",
    value: SCHEDULE_TYPES.every30Minutes,
    data: {
      minute: {
        step: 30
      }
    }
  },
  {
    label: "every 1 hour",
    value: SCHEDULE_TYPES.everyHour,
    data: {
      hour: {
        step: 1
      },
      minute: {
        list: {
          list: [0]
        }
      }
    }
  },
  {
    label: "every 1 day",
    value: SCHEDULE_TYPES.everyDay,
    data: {
      dayOfMonth: {
        step: 1
      },
      hour: {
        list: {
          list: [0]
        }
      },
      minute: {
        list: {
          list: [0]
        }
      }
    }
  },
  {
    label: "Custom",
    value: SCHEDULE_TYPES.custom,
    data: {
      dayOfMonth: {
        step: 1
      },
      hour: {
        list: {
          list: [0]
        }
      },
      minute: {
        list: {
          list: [0]
        }
      }
    }
  }
];

export const CUSTOM_OPT_VALUE = "custom";

export const startTimeOptions: IncSelectOption[] = [
  {
    label: "now",
    value: "now"
  },
  {
    label: "custom",
    value: CUSTOM_OPT_VALUE
  }
];

export const endTimeOptions: IncSelectOption[] = [
  {
    label: "forever",
    value: "forever"
  },
  {
    label: "custom",
    value: CUSTOM_OPT_VALUE
  }
];
