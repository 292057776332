import { formatNumber, IncPercentRenderer, IncHighchartsDateTimeFormat } from "@inception/ui";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { TimeRange } from "../../core";
import { getTimeRange } from "../../core/hooks/time-range/TimeRangeGetter";

const XAxisTickSize = 100;

// common tool tip function for different visualizations
export const getCommonToolTipForCompare = (
  name: string,
  value: number,
  color: string,
  series: any,
  custom?: Record<string, any>,
  toolTipFormatter?: (value: number | string, custom?: Record<string, unknown>) => string
): string => {
  const { isOthersSeries, compareData, invertColors } = custom || {};
  const formattedValue = toolTipFormatter ? toolTipFormatter(value, custom) : formatNumber(value);

  const isDataPositive = value > compareData;
  const eValue = isDataPositive ? value - compareData : compareData - value;
  const sign = isDataPositive ? "up" : "down";
  const percentChange = compareData
    ? renderToStaticMarkup(
        <IncPercentRenderer
          direction={sign}
          invertColors={invertColors}
          size="small"
          total={compareData}
          value={eValue}
        />
      )
    : "";

  const tooltipText = `<div class='inc-charts-tooltip'>
    <div class='inc-charts-tooltip-series-row'>
      <div class="inc-highcharts-square-symbol" style="background-color:${color}; opacity: ${series.opacity - 0.3}"></div>
        <div class='inc-charts-tooltip-series-name'>
          ${name}
        </div>
        <div class='inc-flex-row inc-charts-tooltip-series-value'>
          <span>${!isOthersSeries ? formattedValue : ""}</span>
          <span class='marginLt8'>${percentChange}</span>
        </div>
      </div>
    </div>`;
  return tooltipText;
};

export const getDateTimeFormatForCharts = (timeRange: TimeRange, chartWidth: number) => {
  let format: IncHighchartsDateTimeFormat = IncHighchartsDateTimeFormat.timeOnly;
  let withSeconds = false;
  const globalTimeRange = getTimeRange();
  const fTimeRange = timeRange || globalTimeRange;
  const { from, to } = fTimeRange;

  const getTSWithoutSecs = (ts: number) => new Date(ts).setSeconds(0).valueOf();

  const min = getTSWithoutSecs(from.valueOf());
  const max = getTSWithoutSecs(to.valueOf());

  const range = max - min;
  const ticks = Math.floor(chartWidth / XAxisTickSize);

  const secPerTick = range / ticks / 1000;

  const oneHrSec = 60 * 60;
  const twoHrsSec = 2 * oneHrSec;
  const twentyTwoHoursSec = twoHrsSec * 11;

  const oneDaySec = oneHrSec * 24;
  const twentyEightDaysSec = oneDaySec * 28;

  const oneDayMs = oneDaySec * 1000;
  const oneYearMs = 365 * oneDayMs;

  if (secPerTick <= 45) {
    withSeconds = true;
  } else if (secPerTick <= twoHrsSec || range <= oneDayMs) {
    format = IncHighchartsDateTimeFormat.timeOnly;

    if (range <= oneDayMs) {
      const minDate = new Date(min).getDate();
      const maxDate = new Date(max).getDate();
      if (minDate !== maxDate) {
        format = IncHighchartsDateTimeFormat.monthDayTime;
      }
    }
  } else if (secPerTick <= twentyTwoHoursSec) {
    format = IncHighchartsDateTimeFormat.monthDayTime;
  } else if (secPerTick <= twentyEightDaysSec || range <= oneYearMs) {
    format = IncHighchartsDateTimeFormat.monthDay;
  } else {
    format = IncHighchartsDateTimeFormat.monthDayYearTime;
  }

  return {
    format,
    withSeconds,
    ticks,
    range,
    min,
    max
  };
};
