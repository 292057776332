import { Edge, Node } from "react-flow-renderer";
import { EntityPropertyValue } from "../../platform/core/data/types/EntityQueryTypes";
import {
  EntityTypeStats,
  EventDetails,
  EventGroup,
  EventGroupResponse,
  EventSummary,
  FieldTypeCategory,
  MappingSourceNodeDetails,
  SourceSummary
} from "../../platform/services/api/auto-discovery/types";

export interface MappingEventNodeProps {
  mapping: EventGroup;
  eventDetails: EventDetails;
  name: string;
  eventType: string;
}

export interface DiscoverMappingStateAction {
  type: string;
  payload: unknown;
}

export interface EventTypeFieldMappingDetails {
  eventName: string;
  details: EventDetails;
  nodeYPos: number;
  nodeId: string;
  onVerifyClick: (type: VerifyFieldType) => void;
}

export interface MappingDetailsPaneData {
  eventTypeName: string;
  eventGroup: EventGroup;
  eventSources: Record<string, SourceSummary>;
}
export interface MappingGroupDetailsData {
  showMappingDetailsPane: boolean;
  mappingGroupData: MappingDetailsPaneData;
}

export interface MappingSourceDetailsData {
  showSourceDetailsPane: boolean;
  sourceData: MappingSourceNodeDetails;
}

export interface EventEntityDetailsPaneData {
  requestId: string;
  eventGroupId: string;
  eventSummary: EventSummary;
  sources?: SourceSummary[];
  tabId: VerifyFieldType;
}
export interface EventEntityDetailsData {
  showEventEntityDetailsPane: boolean;
  eventEntityFieldDetails: EventEntityDetailsPaneData;
}

export interface EventTypeSummaryNodes {
  nodes: Node[];
  edges: Edge[];
}

export interface DiscoverMappingContextState {
  requestId: string;
  mappingState: EventGroupResponse;
  sourceTypeIcons: Record<string, string>;
  eventTypeSummaryNodes: EventTypeSummaryNodes;
  mappingSourceDetails?: MappingSourceDetailsData;
  mappingGroupDetails?: MappingGroupDetailsData;
  eventEntityFieldDetails?: EventEntityDetailsData;
  summaryNodesOpenId?: string; //Ex: groupId_eventTypeName
}

export enum VerifyFieldType {
  EVENT_FIELDS = "EVENT_FIELDS",
  ENTITY_FIELDS = "ENTITY_FIELDS",
  CUSTOM_OBJ_FIELDS = "CUSTOM_OBJ_FIELDS"
}

export enum EntityEventFieldMappingType {
  RecommendedFields = "RecommendedFields",
  EntityFields = "EntityFields",
  EventFields = "EventFields",
  CustomObjectFields = "CustomObjectFields"
}

export interface ButtonNodeProps {
  verified: boolean;
  type: VerifyFieldType;
  onClick: (type: VerifyFieldType) => void;
  label?: string;
}

export interface TitleNodeProps {
  title: string;
  onAdd: () => void;
}

export interface SearchNodeProps {
  searchText: string;
  onChange: (searchText: string) => void;
}

export const fieldTypeToValType: Record<string, keyof EntityPropertyValue> = {
  _str: "stringVal",
  _bool: "booleanVal",
  _long: "longVal",
  _double: "doubleVal",
  _set: "setValue",
  _map: "mapValue",
  _date: "dateVal",
  _datetime: "dateTimeVal",
  _objectmap: "objectMapVal"
};

export type FieldCategoryOption = {
  label: string;
  value: FieldTypeCategory;
};

export type EventsAndEntityStats = {
  entityTypeStats: EntityTypeStats;
  eventTypeNameKeys: string[];
};

export enum FieldTimeConfig {
  IngestTime = "Ingest Time",
  QueryTime = "Query Time"
}
