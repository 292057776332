import { IncButton, IncFaIcon, ISaxIcon } from "@inception/ui";
import { groupBy, isNil } from "lodash";
import React, { FC, useEffect, useState, useCallback, useMemo, useRef } from "react";
import { getBezierPath, Position } from "react-flow-renderer";
import { CypressConstants } from "@bicycle/tests";
import { logger, useForceUpdate } from "../../core";
import { ChartPoint } from "../../dashboard/widgets/TimeSeries/models/model";
import { AgentChatTypes } from "../../services/api/chat";
import entityMappingApiServiceV2 from "../../services/api/entity-mapping/EntityMappingApiServiceV2";
import { TriageSummaryTypes, useFetchInsightSummary } from "../../services/api/triage-v2";
import { getSourceTypeIcon } from "../business-entity";
import getChartColor from "../charts/colors";
import { VerticallyCenteredRow } from "../flex-components";
import LoadingSpinner from "../Loading/Loading";
import { MarkdownRenderer } from "../markdown";
import { CauseSummaryCard } from "./CauseSummaryCard";
import { CauseSummaryInfo, TriageSummarySource } from "./types";

interface Props {
  source: TriageSummarySource;
  triageSummary?: TriageSummaryTypes.InsightSummary;
  setShowSummary?: (val: boolean) => void;
  onViewAnalysis?: (stepId: string) => void;
}

export const TriageSummary: FC<Props> = props => {
  const { setShowSummary, source, triageSummary, onViewAnalysis } = props;

  const triageSummaryFromCauseGraph = useMemo(() => !isNil(triageSummary), [triageSummary]);

  const defInsightSummary = source.type === "data" ? source.insightSummary : undefined;
  const opId = source.type === "payload" ? source.opId : undefined;
  const incidentId = source.type === "payload" ? source.incidentId : undefined;
  const generateDemoData = source.type === "payload" ? source.generateDemoData : undefined;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const summaryCardRef = useRef<HTMLDivElement>(null);
  const causeSummaryCardRefs = useRef<HTMLDivElement[]>([]);

  const forceUpdate = useForceUpdate();
  const onToggleCauseSummaryView = useCallback(() => setTimeout(() => forceUpdate()), [forceUpdate]);

  const {
    data: insightSummary,
    error,
    isError,
    isFetching,
    refetch: fetchInsightSummary
  } = useFetchInsightSummary(opId, incidentId, defInsightSummary, generateDemoData);

  useEffect(() => {
    if (!defInsightSummary && !triageSummaryFromCauseGraph) {
      fetchInsightSummary();
    }
  }, [defInsightSummary, fetchInsightSummary, triageSummaryFromCauseGraph]);

  useEffect(() => {
    if (isError) {
      logger.error("Error fetching Insight Summary", error);
    }
  }, [error, isError]);

  const [iconsBySourceType, setIconsBySourceType] = useState<Record<string, JSX.Element>>({});
  const [isFetchingIcons, setIsFetchingSourceIcons] = useState(false);

  const fetchActionIcons = useCallback(async (sourceTypes: string[]) => {
    setIsFetchingSourceIcons(true);
    let iconsBySourceType: Record<string, JSX.Element> = {};

    try {
      const iconMap = await entityMappingApiServiceV2.getSourceTypeIcons(sourceTypes);

      iconsBySourceType = Object.keys(iconMap).reduce(
        (iconsBySourceType, sourceTypeId) => {
          const icon = iconMap[sourceTypeId];

          return {
            ...iconsBySourceType,
            [sourceTypeId]: getSourceTypeIcon(sourceTypeId, icon, "", 16)
          };
        },
        {} as Record<string, JSX.Element>
      );
    } catch (err) {
      logger.error("TriageSummary", "Error fetching sourceType icons", err);
    }

    setIconsBySourceType(iconsBySourceType);
    setIsFetchingSourceIcons(false);
  }, []);

  const { causeSummary, overallSummary, allowedCauseTypeInfo } = triageSummaryFromCauseGraph
    ? triageSummary || {}
    : insightSummary?.insightSummary || {};

  useEffect(() => {
    const sourceTypesSet: Set<string> = new Set();
    causeSummary?.forEach(summ => {
      summ.actionsTaken.forEach(action => {
        sourceTypesSet.add(action.actionType);
      });
      summ.possibleActions.forEach(action => {
        sourceTypesSet.add(action.actionType);
      });
    });
    const sourceTypes = Array.from(sourceTypesSet).filter(Boolean);

    allowedCauseTypeInfo?.forEach(typeInfo => {
      typeInfo.possibleActions?.forEach(action => {
        sourceTypesSet.add(action.actionType);
      });
    });

    if (sourceTypes.length) {
      fetchActionIcons(sourceTypes);
    }
  }, [allowedCauseTypeInfo, causeSummary, fetchActionIcons]);

  const causeSummaryJSX = useMemo(() => {
    if (allowedCauseTypeInfo?.length) {
      const causeSummaryByType = groupBy(causeSummary, summ => summ.causeTypeInfo.causeType);
      const causeSummaryInfos = allowedCauseTypeInfo
        .map((causeTypeInfo, idx) => ({
          causeTypeInfo,
          summaries: causeSummaryByType[causeTypeInfo.causeType] || [],
          color: getChartColor(idx)
        }))
        .sort((a, b) => b.summaries.length - a.summaries.length) as CauseSummaryInfo[];

      let openCardExists = false;
      return causeSummaryInfos.map((causeSummaryInfo, idx) => {
        const { causeTypeInfo, summaries } = causeSummaryInfo;
        const shouldOpen = !openCardExists && causeSummaryInfo.summaries.length > 0;
        openCardExists = openCardExists || shouldOpen;

        const onViewSource = (source: AgentChatTypes.Source) => {
          if (source.id) {
            onViewAnalysis(source.id);
          } else {
            logger.error("TriageSummary", "Could not find matching summary for source", {
              source,
              summaries
            });
          }
        };

        const ref = (el: HTMLDivElement) => {
          causeSummaryCardRefs.current[idx] = el;
          setTimeout(forceUpdate);
        };

        return (
          <CauseSummaryCard
            causeSummaryInfo={causeSummaryInfo}
            defaultOpen={shouldOpen}
            iconsBySourceType={iconsBySourceType}
            isIconsLoading={isFetchingIcons}
            key={causeTypeInfo.causeType}
            onAnalyze={onViewAnalysis}
            onToggleView={onToggleCauseSummaryView}
            onViewSource={onViewSource}
            ref={ref}
          />
        );
      });
    }

    return [];
  }, [
    allowedCauseTypeInfo,
    causeSummary,
    forceUpdate,
    iconsBySourceType,
    isFetchingIcons,
    onToggleCauseSummaryView,
    onViewAnalysis
  ]);

  const wrapperRect = wrapperRef.current?.getBoundingClientRect();
  const wrapperHeight = wrapperRef.current?.scrollHeight - 48;
  const wrapperWidth = wrapperRect?.width - 48;
  const wrapperLeft = wrapperRect?.left;
  const wrapperTop = wrapperRect?.top;

  const causeSummaryCoordinates = causeSummaryCardRefs.current.filter(Boolean).map(el => {
    const rect = el.getBoundingClientRect();
    return [rect.left - wrapperLeft - 24, rect.top - wrapperTop] as ChartPoint;
  });

  const summaryRect = summaryCardRef.current?.getBoundingClientRect();
  const { right: summaryRectRight, top: summaryRectTop, height: summaryRectHeight } = summaryRect || {};

  const summaryCoordinates = useMemo<ChartPoint>(
    () =>
      summaryRect
        ? [summaryRectRight - wrapperLeft - 24, summaryRectTop - wrapperTop + summaryRectHeight * 0.5 - 24]
        : null,
    [summaryRect, summaryRectHeight, summaryRectRight, summaryRectTop, wrapperLeft, wrapperTop]
  );

  const prevCoordinatesStr = useRef("");
  const prevBezierPaths = useRef<JSX.Element[]>([]);
  const bezierPaths = useMemo(() => {
    if (summaryCoordinates) {
      const nextCoordinatesStr = JSON.stringify([summaryCoordinates, ...causeSummaryCoordinates]);
      const shouldUpdate = prevCoordinatesStr.current !== nextCoordinatesStr;

      if (shouldUpdate) {
        prevCoordinatesStr.current = nextCoordinatesStr;
        prevBezierPaths.current = generateBezierPaths(summaryCoordinates, causeSummaryCoordinates);
      }

      return prevBezierPaths.current;
    }

    return null;
  }, [causeSummaryCoordinates, summaryCoordinates]);

  useEffect(() => {
    if (wrapperRect && summaryRect && !bezierPaths) {
      forceUpdate();
    }
  }, [bezierPaths, forceUpdate, summaryRect, wrapperRect]);

  const isLoading = useMemo(
    () => (triageSummaryFromCauseGraph ? false : isFetching),
    [isFetching, triageSummaryFromCauseGraph]
  );

  return (
    <>
      {!isNil(setShowSummary) && (
        <div className="inc-flex-row inc-flex-row-reverse marginTp16 marginLt16 marginRt16">
          <IncButton
            color="secondary"
            onClick={() => setShowSummary(false)}
          >
            <IncFaIcon
              className="fa-flip-horizontal status-info marginRt6"
              iconName="clock-rotate-left"
            />
            <div className="status-info">Review Steps</div>
          </IncButton>
        </div>
      )}

      <div
        className="triage-v2-summary"
        ref={wrapperRef}
      >
        {isLoading && <LoadingSpinner className="inc-text-subtext-medium" />}
        {!isLoading && (
          <>
            {isError && (
              <VerticallyCenteredRow className="inc-text-subtext-medium status-danger width-100 height-100 inc-flex-center">
                Error fetching Insight Summary
              </VerticallyCenteredRow>
            )}
            {!isError && (
              <>
                <svg
                  className="triage-v2-summary--bezier"
                  height={wrapperHeight}
                  width={wrapperWidth}
                >
                  {bezierPaths}
                </svg>

                <div
                  className="triage-v2-summary--summary"
                  data-cy={CypressConstants.features.Triage.attributes.triageSummary}
                  ref={summaryCardRef}
                >
                  <VerticallyCenteredRow className="summary--title flex-gap-12">
                    <ISaxIcon
                      iconName="Note"
                      size={24}
                      style={{ fill: "#E0DDAA" }}
                    />
                    Summary
                  </VerticallyCenteredRow>
                  <MarkdownRenderer
                    className="summary--content inc-text-subtext-medium"
                    mdContent={overallSummary}
                  />
                </div>

                <div
                  className="triage-v2-summary--cause-summary-wrapper"
                  data-cy={CypressConstants.features.Triage.attributes.causeSummaryWrapper}
                >
                  {causeSummaryJSX}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

const generateBezierPaths = (summaryCoordinates: ChartPoint, causeSummaryCoordinates: ChartPoint[]) => {
  const bezierPaths: JSX.Element[] = [];
  const [x, y] = summaryCoordinates;

  causeSummaryCoordinates.forEach(causeSummaryCoordinate => {
    const [cx, cy] = causeSummaryCoordinate;
    const key = [x, y, cx, cy].join("-");

    const path = getBezierPath({
      sourceX: x,
      sourceY: y,
      targetX: cx,
      targetY: cy,
      sourcePosition: Position.Right,
      targetPosition: Position.Left
    });

    bezierPaths.push(
      <path
        className="triage-v2-summary--connector-line"
        d={path}
        key={key}
      />
    );
  });

  return bezierPaths;
};
