import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { IncCheckbox, IncSelect, IncSelectOption, generateId } from "@inception/ui";
import { clone, difference, findIndex, sortBy, union } from "lodash";
import { useFetchFieldNameMetricNames } from "../../../../components/renderers/common";
import { GenericFilterDataOption } from "../../../../../../../components/explore/GenericFilterBuilder";
import { ColorCustomizationCondition, TableProperties } from "../../../../models";
import { ENTITY_TAG } from "../../../../../../../utils/MetricNameUtils";
import { VerticallyCenteredRow } from "../../../../../../../components";
import { UIOrderedList } from "./UIOrderedList";
import { UIColorCondition } from "./UIColorCondition";
import { WidgetCustomizationProps } from "./types";
import { useCommonCustomisationExtraction } from "./common";

export interface TableUIColumnDef {
  id: string;
  label: string;
  type: string;
  grouped: boolean;
}

const defaultFilterOptions: ColorCustomizationCondition = {
  color: "#fff",
  id: generateId(),
  conditions: [
    {
      key: null,
      op: null,
      value: null,
      opValue: null
    }
  ]
};

export const TableUICustomization: FC<WidgetCustomizationProps> = props => {
  const { widgetImpl, properties, onPropertiesChange } = props;

  const { metricId, aggregateTags, queryConfig, aggregator, eventTypeName, entityTypeName } =
    useCommonCustomisationExtraction(widgetImpl);

  const defaultTableProps: TableProperties = {
    metricHeaders: null,
    dateTimeFormat: null,
    formatterOptions: undefined,
    columnOrder: [],
    groupBy: []
  };

  if (!properties.table) {
    properties.table = defaultTableProps;
  }

  const { table: tableProperties } = properties || {};

  const { columnOrder, groupBy = [], colorCustomizations, pageSize, limitSpec } = tableProperties || {};

  const { metricName, childMetricNames } = useFetchFieldNameMetricNames(aggregator, queryConfig, eventTypeName);

  const [colorQueries, setColorQueries] = useState<ColorCustomizationCondition[]>(clone(colorCustomizations));
  const [filters, setFilters] = useState<Record<string, GenericFilterDataOption>>();
  const [subColComparison, setSubColComparison] = useState<boolean>(false);

  /**
   * set the default value of record size
   */
  const defaultRecordSize: IncSelectOption = useMemo(() => {
    const defaultOption = recordSizeOptions.find(s => s.value === limitSpec?.limit?.toString());
    return defaultOption || recordSizeOptions[0];
  }, [limitSpec]);

  /**
   * set the default value of page size
   */
  const defaultPageSize: IncSelectOption = useMemo(() => {
    const pDefaultOption = pageSizeOptions.find(s => s.value === pageSize?.toString());
    return pDefaultOption || defaultOption;
  }, [pageSize]);

  const { metricNamesMap, metricIds } = useMemo(() => {
    const metricNamesMap = {
      [metricId]: metricName,
      ...childMetricNames
    };
    const metricIds = Object.keys(metricNamesMap);

    return {
      metricNamesMap,
      metricIds
    };
  }, [childMetricNames, metricId, metricName]);

  const setDataFilter = useCallback((tableColumns: TableUIColumnDef[]) => {
    const filterOptions: Record<string, GenericFilterDataOption> = {};
    tableColumns.forEach((column: TableUIColumnDef) => {
      const type = column.type === METRIC ? "_long" : "_str"; //TODO change the logic to set based on data type
      const fData: GenericFilterDataOption = {
        value: [],
        type
      };
      filterOptions[column.label] = fData;
    });
    setFilters(filterOptions);
  }, []);

  const tableColumns = useMemo(() => {
    const uiColumns: TableUIColumnDef[] = [];
    aggregateTags.forEach(aggregateTag => {
      const label = aggregateTag === ENTITY_TAG ? entityTypeName || eventTypeName || aggregateTag : aggregateTag;
      const groupId = aggregateTag === ENTITY_TAG ? ENTITY_TAG : label;
      const grouped = tableProperties?.groupBy?.includes(groupId) ? true : false;
      uiColumns.push({
        id: aggregateTag,
        label: label.toString(),
        type: DIMENSION,
        grouped
      });
    });

    metricIds.forEach(metricId => {
      uiColumns.push({
        id: metricId,
        label: metricNamesMap[metricId],
        type: METRIC,
        grouped: false
      });
    });

    const sortedUIColumns = sortBy(uiColumns, (column: TableUIColumnDef) => {
      const index = findIndex(tableProperties?.columnOrder, (o: string) => o === column.id);
      return index !== -1 ? index : Number.MAX_SAFE_INTEGER;
    });

    setDataFilter(sortedUIColumns);
    return sortedUIColumns;
  }, [
    aggregateTags,
    metricIds,
    setDataFilter,
    entityTypeName,
    eventTypeName,
    tableProperties?.groupBy,
    tableProperties?.columnOrder,
    metricNamesMap
  ]);

  const metricTableColumns: TableUIColumnDef[] = useMemo(
    () => tableColumns.filter(item => item.type !== DIMENSION),
    [tableColumns]
  );
  const dimensionTableColumns: TableUIColumnDef[] = useMemo(
    () => tableColumns.filter(item => item.type === DIMENSION),
    [tableColumns]
  );

  const updateWidgetRenderProp = useCallback(() => {
    onPropertiesChange(properties);
  }, [onPropertiesChange, properties]);

  useEffect(() => {
    if (tableColumns && metricIds?.length > 0 && metricIds[0] !== "undefined") {
      setColorQueries(colorCustomizations || [defaultFilterOptions]);
    }
  }, [colorCustomizations, metricIds, tableColumns]);

  const onConditionsChange = useCallback(
    (conditions: ColorCustomizationCondition[]) => {
      // Get the filters from condition.
      setColorQueries(conditions);
      tableProperties.colorCustomizations = conditions;
      updateWidgetRenderProp();
    },
    [tableProperties, updateWidgetRenderProp]
  );

  const getColumnOrder = useCallback((items: TableUIColumnDef[]) => items.map((item: TableUIColumnDef) => item.id), []);

  const updateTableColumnOrder = useCallback(
    (dimensionTableColumns, metricTableColumns) => {
      const tableColumns = [...dimensionTableColumns, ...metricTableColumns];
      const columnDefs = getColumnOrder(tableColumns);
      tableProperties.columnOrder = columnDefs;
      updateWidgetRenderProp();
    },
    [getColumnOrder, tableProperties, updateWidgetRenderProp]
  );

  const onDimensionListChange = useCallback(
    (items: TableUIColumnDef[]) => {
      if (items) {
        updateTableColumnOrder(items, metricTableColumns);
      }
    },
    [metricTableColumns, updateTableColumnOrder]
  );

  /**
   *handle the checkbox for group by
   */
  const onGroupByClick = useCallback(
    (column: TableUIColumnDef, checked: boolean) => {
      column.grouped = checked;
      const columnId = column.id === ENTITY_TAG ? ENTITY_TAG : column.label;

      if (checked && groupBy && !groupBy.includes(columnId)) {
        groupBy.push(columnId);
      } else {
        const index: number = groupBy.indexOf(columnId);
        if (index > -1) {
          groupBy.splice(index, 1);
        }
      }
      const organizedColumns = union(groupBy, difference(columnOrder, groupBy));
      tableProperties.groupBy = clone(groupBy);
      tableProperties.columnOrder = clone(organizedColumns);
      updateWidgetRenderProp();
    },
    [columnOrder, groupBy, tableProperties, updateWidgetRenderProp]
  );

  /**
   *
   */
  const onMetricListChange = useCallback(
    (items: TableUIColumnDef[]) => {
      if (items) {
        updateTableColumnOrder(dimensionTableColumns, items);
      }
    },
    [dimensionTableColumns, updateTableColumnOrder]
  );

  /**
   * handle show events in metrics column
   */
  const onCheckShowEvents = useCallback(
    (e: ChangeEvent<HTMLInputElement>, check: boolean) => {
      tableProperties.showEvents = check;
      updateWidgetRenderProp();
    },
    [tableProperties, updateWidgetRenderProp]
  );

  /**
   * handle sub group toggle change
   */
  const handleSubColComparisonToggle = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSubColComparison(checked);
  }, []);
  /**
   * set page size
   */
  const handlePageSizeChange = useCallback(
    (option: IncSelectOption) => {
      if (isNaN(Number(option.value)) || Number(option.label) <= 0) {
        return;
      }

      const pageOption = pageSizeOptions.find(s => s.value === option.value);
      if (!pageOption) {
        //set the new size in options
        if (!isNaN(parseFloat(option.value))) {
          option.data = Number(option.value);
          pageSizeOptions.push(option);
          pageSizeOptions.sort((p, n) => p.data - n.data);
        }
      }
      tableProperties.pageSize = Number(option.value);
      updateWidgetRenderProp();
    },
    [tableProperties, updateWidgetRenderProp]
  );
  /**
   * set page size
   */
  const handleRecordSizeChange = useCallback(
    (option: IncSelectOption) => {
      //check if the user has typed the new value
      const sizeOption = recordSizeOptions.find(s => s.value === option.value);
      if (!sizeOption) {
        //set the new size in options
        if (!isNaN(parseFloat(option.value))) {
          option.data = Number(option.value);
          recordSizeOptions.push(option);
          recordSizeOptions.sort((p, n) => p.data - n.data);
        }
      }

      tableProperties.limitSpec = {
        function: tableProperties.limitSpec?.function || "top",
        limit: Number(option.value)
      };

      updateWidgetRenderProp();
    },
    [tableProperties, updateWidgetRenderProp]
  );

  return (
    <div className="customization-content">
      <div className="d-flex flex-column">
        <div className="d-flex">
          <div className="w-50">
            <IncSelect
              allowCreate
              autoSort={false}
              className="w-50"
              label="Rows Per Page"
              onChange={handlePageSizeChange}
              options={pageSizeOptions}
              value={defaultPageSize}
            />
          </div>
          <div className="w-50">
            <IncSelect
              allowCreate
              autoSort={false}
              className="w-50"
              label="Total Record Size"
              onChange={handleRecordSizeChange}
              options={recordSizeOptions}
              value={defaultRecordSize}
            />
          </div>
        </div>

        <section className="section">
          <div className="section-heading">Breakdown Order</div>
          <UIOrderedList
            actionLabel="Group By"
            actionValueProp="grouped"
            itemOrder={tableProperties?.groupBy}
            items={dimensionTableColumns}
            onActionChange={onGroupByClick}
            onListChange={onDimensionListChange}
          />
        </section>

        <section className="section">
          <div className="section-heading inc-flex inc-flex-row inc-flex-space-contents">
            <VerticallyCenteredRow>Column Order</VerticallyCenteredRow>
            <IncCheckbox
              checked={tableProperties.showEvents}
              className="list-action-checkbox"
              label={"Show events"}
              labelProps={CheckboxStyleProps}
              onChange={onCheckShowEvents}
            ></IncCheckbox>
          </div>

          <UIOrderedList
            hideAction={true}
            items={metricTableColumns}
            onListChange={onMetricListChange}
          />

          <div className="d-flex">
            <IncCheckbox
              checked={subColComparison}
              className="show-comparison-action"
              label="Show Comparison in Sub-Columns"
              labelProps={CheckboxStyleProps}
              onChange={handleSubColComparisonToggle}
            />
          </div>
        </section>
        <section className="section">
          <div className="section-heading">Row Colors</div>
          {Boolean(colorQueries) && (
            <UIColorCondition
              filters={filters}
              onConditionsChange={onConditionsChange}
              queries={colorQueries}
            />
          )}
        </section>
      </div>
    </div>
  );
};

const pageSizes = [20, 50, 100, 200];
const recordSizes = [50, 100, 500, 1000];
const DIMENSION = "dimension";
const METRIC = "metric";
const CheckboxStyleProps: any = { placement: "end" };

export const ALL_ENTRIES_PAGE_SIZE = -1;
export const AUTO_PAGE_SIZE_VALUE = 0;

/**
 *
 */
const pageSizeOptions: IncSelectOption[] = [
  {
    label: "All",
    value: ALL_ENTRIES_PAGE_SIZE.toString(),
    data: ALL_ENTRIES_PAGE_SIZE
  },
  {
    label: "Auto",
    value: AUTO_PAGE_SIZE_VALUE.toString(),
    data: AUTO_PAGE_SIZE_VALUE
  },
  ...pageSizes.map(val => ({
    label: val.toString(),
    value: val.toString(),
    data: val
  }))
];

const defaultOption = pageSizeOptions.find(s => s.value === "20");

/**
 *
 */
const recordSizeOptions: IncSelectOption[] = recordSizes.map(val => ({
  label: val.toString(),
  value: val.toString(),
  data: val
}));
