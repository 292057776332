import { IncSelect, IncSelectOption, IncSmartText } from "@inception/ui";
import React, { FC, useMemo, useCallback } from "react";
import { VerticallyCenteredRow } from "../../../../../../../components";
import { CompareTimeRangeSelector, TimeRangeController } from "../../../../../../../components/time-range";
import { RawTimeRange, TimeRange, useTimeRange } from "../../../../../../../core";
import { noOp } from "../../../../../../../utils";
import timeRangeUtils from "../../../../../../../utils/TimeRangeUtils";
import { DASHBOARD_DEFAULT_COMPARE_INTERVAL } from "../../../../models";

interface Props {
  timeRange: TimeRange;
  compareInterval: string;
  onTimeRangeChange: (timeRange: RawTimeRange) => void;
  onCompareIntervalChange: (compareInterval: string) => void;
}

export const TimePropertiesEditor: FC<Props> = props => {
  const {
    onTimeRangeChange: updateTimeRange,
    timeRange: widgetTimeRange,
    compareInterval,
    onCompareIntervalChange: updateCompareInterval
  } = props;

  const { timeRange: globalTimeRange, compareTimeRange: gCompareTimeRange } = useTimeRange();

  const trOpt = useMemo<IncSelectOption>(() => (widgetTimeRange ? customTrOption : dbTrOption), [widgetTimeRange]);

  const onTimeRangeOptChange = useCallback(
    (option: IncSelectOption) => {
      if (option.value === customTrOption.value) {
        updateTimeRange(globalTimeRange.raw);
      } else {
        updateTimeRange(null);
      }
    },
    [globalTimeRange.raw, updateTimeRange]
  );

  const compareTimeRange = useMemo<TimeRange>(() => {
    if (compareInterval === DASHBOARD_DEFAULT_COMPARE_INTERVAL) {
      return {
        from: null,
        to: null,
        raw: {
          from: DASHBOARD_DEFAULT_COMPARE_INTERVAL,
          to: ""
        }
      };
    }
    return compareInterval ? timeRangeUtils.getCompareTimeRangeFromRaw(globalTimeRange, compareInterval) : null;
  }, [compareInterval, globalTimeRange]);

  const onCompareIntervalChange = useCallback(
    (compareTimeRange: RawTimeRange) => {
      const { from } = compareTimeRange || {};
      updateCompareInterval(from || null);
    },
    [updateCompareInterval]
  );

  return (
    <div className="properties-edit-section paddingLt4 paddingBt6">
      <VerticallyCenteredRow className="flex-gap-10">
        <div className="inc-flex-column flex-gap-8">
          <VerticallyCenteredRow className="flex-gap-12">
            <IncSelect
              autoAdjustWidth
              autoAdjustWidthBuffer={20}
              autoSort={false}
              className="time-range-select"
              isSearchable={false}
              label="Widget Time Range"
              onChange={onTimeRangeOptChange}
              options={timeRangeOptions}
              value={trOpt}
            />

            {Boolean(widgetTimeRange) && (
              <TimeRangeController
                className="marginTp16"
                compareTimeRange={gCompareTimeRange}
                disableRefresh={true}
                disableTooltip
                setCompareTimeRange={noOp}
                setTimeRange={updateTimeRange}
                showCompareSelector={false}
                showTimeRangeSelector
                timeRange={widgetTimeRange}
              />
            )}
          </VerticallyCenteredRow>
          <VerticallyCenteredRow className="inc-text-element-medium inc-text-inactive">
            {"Selected time range will override dashboard's default time range for this particular widget"}
          </VerticallyCenteredRow>
        </div>
      </VerticallyCenteredRow>
      <IncSmartText
        className="inc-text-subtext-medium inc-text-inactive"
        text="Show Comparison with"
      />
      <CompareTimeRangeSelector
        compareLabel=""
        compareTimeRange={compareTimeRange}
        disablePopper
        includeDbDefaultOption
        includeNoneOption
        renderer="default"
        setCompareTimeRange={onCompareIntervalChange}
      />
    </div>
  );
};

const dbTrOption: IncSelectOption = {
  label: "Dashboard Default",
  value: "auto"
};

const customTrOption: IncSelectOption = {
  label: "Custom Time Range",
  value: "custom"
};

const timeRangeOptions: IncSelectOption[] = [dbTrOption, customTrOption];
