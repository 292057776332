import { cloneDeep, isNil } from "lodash";
import Highcharts from "highcharts";
import { IncDateTimeFormat, formatNumber, getFormattedDateTime, getInceptionTheme } from "@inception/ui";
import {
  MetricResultDTO,
  SelectorTag,
  SliceSpec,
  WidgetConfigDTO,
  WidgetResponseDTO
} from "../../../../../../../services/api/explore";
import { Datum } from "../utils";
import { CatalogWidgetProperties } from "../../../../models";
import { TimeRange, TimeSeries } from "../../../../../../../core";
import { getXAxisLabelsAndFormat } from "../../../../../../../components/time-series/ChartOptionsBuilder";
import { getDownSampleInterval } from "../../../../../../../utils";
import kbn from "../../../../../../../services/datasources/core/kbn";
import { FONT_STYLE } from "../../../../../../../components/charts";
import timeRangeUtils from "../../../../../../../utils/TimeRangeUtils";

const VALUE_ACCESSOR = "___value__";
const X_AXIS_ID = "X-Axis";
const ACTIVE_COLOR = "#FF4D4F";
export type EventChartType = "column" | "gantt";

export const formatWidgetConfigBasedOnMetric = (widgetConfig: WidgetConfigDTO, metricId: string): WidgetConfigDTO => {
  const temp = cloneDeep(widgetConfig);
  if (temp?.dataDefinition?.metrics?.[metricId]) {
    const widgetMetrics = { [metricId]: temp.dataDefinition.metrics[metricId] };
    temp.dataDefinition.metrics = widgetMetrics;
    return temp;
  }
  return widgetConfig;
};

export const getIntervalSecs = (
  startMillis: number,
  endMillis: number,
  downsample: string,
  numBins: number,
  chartType: EventChartType
) => {
  if (!chartType || !numBins) {
    const interval = getIntervalSecsForEventTimeline(startMillis, endMillis, downsample);
    return parseInt(interval.toString(), 10);
  }
  if (chartType === "gantt") {
    const interval = getIntervalSecsForEventTimeline(startMillis, endMillis, downsample);
    return parseInt(interval.toString(), 10);
  } else {
    const interval = (endMillis - startMillis) / 1000 / numBins;
    return parseInt(interval.toString(), 10);
  }
};

export const generateSliceSpecBasedOnMetric = (
  widgetResponseDto: WidgetResponseDTO,
  metricId: string,
  startMillis: number,
  endMillis: number,
  downsample: string,
  tableInfo?: Datum,
  aggTags?: string[],
  numBins?: number,
  chartType?: EventChartType
): SliceSpec[] => {
  const metricQuerySchema = widgetResponseDto?.querySchema?.querySchema?.find(e => e.metricId === metricId);
  const tableKeys = Object.keys(tableInfo || {})
    .map(e => ({
      key: e.replaceAll(VALUE_ACCESSOR, "").trim(),
      rawKey: e
    }))
    .filter(e => (aggTags ? aggTags?.includes(e.key) : true));
  const interval = getIntervalSecs(startMillis, endMillis, downsample, numBins, chartType);
  const selectorsSpecs: SelectorTag[] = [];
  tableKeys.forEach(e => {
    let value: string[] = [];
    const dValue = tableInfo?.[e?.rawKey].toString();
    if (!dValue.includes("unique")) {
      value = [dValue];
      selectorsSpecs.push({
        key: e.key,
        value: value
      });
    }
  });
  const sliceSpec: SliceSpec[] = [
    {
      sliceSet: metricQuerySchema.sliceSet,
      selectorSpec: {
        filters: [
          {
            tags: selectorsSpecs
          }
        ]
      },
      postAgg: {
        projections: ["current"],
        overTagAgg: {
          aggregator: metricQuerySchema.defaultTagAgg,
          tagName: aggTags || []
        },
        overTimeAgg: {
          aggregator: metricQuerySchema.defaultTimeAgg,
          timeInSeconds: interval
        }
      },
      metricId
    }
  ];
  return sliceSpec;
};

export type EventSeries = {
  timeEpoch: number;
  value: boolean | number;
  color: string;
};

// TODO remove override once changes are verified
const OVERRIDE = true;

export const generateSeriesDataFromResponse = (
  metricResults: MetricResultDTO[],
  widgetProperties: CatalogWidgetProperties,
  chartType: EventChartType,
  chartLabel?: string
) => {
  const dataFrame = metricResults?.[0]?.postAggResult?.data || {};

  const key = Object.keys(dataFrame)?.[0];
  const { dataTypeCustomisation } = widgetProperties;
  const falseColor = OVERRIDE ? "rgba(248,72,94,0.8)" : dataTypeCustomisation?.boolean?.falsy?.color;
  const truthColor = OVERRIDE ? "rgba(56,180,67,0.8)" : dataTypeCustomisation?.boolean?.truthy?.color;
  const dfs = dataFrame?.[key]?.data?.[0] as TimeSeries;

  const seriesData: EventSeries[] = [];

  dfs?.datapoints?.forEach(e => {
    const dp = e;
    const value = chartType === "gantt" ? Boolean(dp?.[0]) : dp?.[0];
    if (chartType === "gantt") {
      if (!isNil(dp?.[0])) {
        seriesData.push({
          timeEpoch: dp?.[1] || 0,
          value,
          color: value ? truthColor : falseColor
        });
      }
    } else {
      if (Number(dp?.[0])) {
        seriesData.push({
          timeEpoch: dp?.[1] || 0,
          value,
          color: value ? truthColor : falseColor
        });
      }
    }
  });

  const legends =
    chartType === "gantt"
      ? [
          {
            color: truthColor,
            label: dataTypeCustomisation?.boolean?.truthy?.label
          },
          {
            color: falseColor,
            label: dataTypeCustomisation?.boolean?.falsy?.label
          }
        ]
      : [
          {
            color: ACTIVE_COLOR,
            label: chartLabel || "",
            opacity: 0.75
          }
        ];

  return {
    seriesData: chartType === "gantt" ? seriesData : seriesData.slice(0, 10),
    legends,
    truthColor,
    falseColor
  };
};

export const getChartOptions = (
  timeRange: TimeRange,
  width: number,
  height: number,
  series: EventSeries[],
  truthColor: string,
  chartType: EventChartType
): Highcharts.Options => {
  const chartOptions: Highcharts.Options = {
    chart: {
      type: chartType,
      width: width,
      height: height,
      backgroundColor: "transparent"
    },
    tooltip: {
      className: "highcharts-tooltip",
      enabled: true,
      shared: false,
      outside: true,
      useHTML: true,
      formatter: function () {
        return tooltipFormatter(this.point, truthColor, chartType);
      }
    },
    title: {
      text: ""
    },
    credits: {
      enabled: false
    },
    xAxis: getXAxisOptionsBasedOnChartType(series, timeRange, chartType, width),
    yAxis: getYAxisOptionsBasedOnChartType(chartType),
    series: getSeriesFromChartType(series, timeRange, chartType)
  };
  return chartOptions;
};

const getXAxisOptionsBasedOnChartType = (
  series: EventSeries[],
  timeRange: TimeRange,
  chartType: EventChartType,
  width: number
): Highcharts.XAxisOptions => {
  const { toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);
  const { labelFormat: xAxisLabelFormat } = getXAxisLabelsAndFormat(timeRange, width);
  if (chartType === "gantt") {
    const xAxisOptions: Highcharts.XAxisOptions = {
      type: "linear",
      min: series[0].timeEpoch,
      max: toMillis,
      labels: {
        formatter: ctx => {
          const timeEpoch = parseInt(ctx.value.toString(), 10);
          return getFormattedDateTime(timeEpoch, xAxisLabelFormat, {
            withSeconds: false
          });
        }
      }
    };
    return xAxisOptions;
  } else {
    const xAxisOptions: Highcharts.XAxisOptions = {
      type: "datetime",
      grid: {
        enabled: false
      },
      offset: 0,
      title: {
        text: "",
        style: {
          color: getInceptionTheme().plugins.graph.legendTextHover,
          marginTop: 26
        }
      },
      tickPositions: series?.map(e => e.timeEpoch),
      tickLength: 0,
      labels: {
        enabled: true,
        align: "center",
        style: FONT_STYLE,
        x: 0,
        y: 20,
        useHTML: true,
        formatter: that => {
          const intVal = parseInt(that.value.toString(), 10);
          const value = intVal;

          const formattedString = getFormattedDateTime(value, xAxisLabelFormat, {
            withSeconds: false
          });
          return `<span class="inc-text-subtext-medium inc-text-color-secondary">${formattedString}</span>`;
        }
      },
      id: X_AXIS_ID
    };
    return xAxisOptions;
  }
};

const getYAxisOptionsBasedOnChartType = (chartType: EventChartType) =>
  chartType === "gantt"
    ? {
        categories: [""],
        visible: false
      }
    : [
        {
          gridLineWidth: 0,
          visible: false,
          title: {
            text: ""
          }
        }
      ];

const getSeriesFromChartType = (series: EventSeries[], timeRange: TimeRange, chartType: "gantt" | "column") => {
  const { toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);
  if (chartType === "gantt") {
    const seriesOptions: Highcharts.SeriesOptionsType[] = [
      {
        name: "",
        type: chartType,
        borderWidth: 0,
        borderRadius: 0,
        pointPadding: 0.2,
        borderColor: "transparent",
        showInLegend: false,
        data: series?.map((e, i) => ({
          name: "",
          y: 0,
          start: e.timeEpoch,
          end: series?.[i + 1]?.timeEpoch || toMillis,
          color: e.color
        }))
      }
    ];
    return seriesOptions;
  } else {
    const seriesOptions: Highcharts.SeriesOptionsType[] = [
      {
        name: "",
        type: "column",
        borderColor: "transparent",
        color: ACTIVE_COLOR,
        opacity: 0.75,
        pointWidth: 16,
        groupPadding: 0.05,
        pointPadding: 0,
        borderRadius: 2,
        getExtremesFromAll: true,
        dataLabels: {
          align: "center",
          verticalAlign: "top",
          inside: false,
          position: "center",
          className: "inc-data-label",
          y: -25,
          enabled: true,
          useHTML: true,
          color: "#ffffff",
          allowOverlap: true,
          formatter: function () {
            return this.y
              ? `<span style="">
            ${formatNumber(this.y)}
          </span>`
              : "";
          }
        },
        showInLegend: false,
        data: series?.map(e => ({
          y: Number(e.value),
          x: e.timeEpoch
        }))
      }
    ];
    return seriesOptions;
  }
};

const tooltipFormatter = (point: any, truthColor: string, chartType: EventChartType) => {
  let valueString = "";
  const startTime = chartType === "gantt" ? point.start : point.x;
  const formatedTime = getFormattedDateTime(Number(startTime), IncDateTimeFormat.full);
  if (chartType === "gantt") {
    const { color } = point;
    const isActive = color === truthColor;
    valueString = `<div class="inc-text-subtext" style="color:${color}">${isActive ? "On" : "Off"}</div>`;
  } else {
    valueString = `<div class="inc-text-subtext" style="color:${truthColor}">${point.y}</div>`;
  }
  return `<div class="inc-charts-tooltip">
            <div class="marginTp6 inc-flex-column">
                ${valueString}
                <div class="inc-flex-row inc-flex-center-vertical">
                    <div class="inc-text-element inc-text-color-secondary">${formatedTime}</div>
                  </div>
                  </div>
              </div>`;
};

export const getIntervalSecsForEventTimeline = (fromMillis: number, toMillis: number, downsample: string) => {
  const timeObj = {
    from: fromMillis,
    to: toMillis
  };

  const intervalStr = getDownSampleInterval(downsample, timeObj);
  const intervalSecs = kbn.interval_to_seconds(intervalStr);

  return intervalSecs;
};
