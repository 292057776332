import {
  SeriesPieOptions,
  SeriesPieDataLabelsOptionsObject,
  TooltipOptions,
  PointOptionsObject,
  DrilldownOptions
} from "highcharts";
import { defaultsDeep } from "lodash";
import BaseOptionsBuilder from "../charts/BaseOptionsBuilder";
import getChartColor from "../charts/colors";
import { generateId } from "../../core";
import { getCommonToolTipForCompare } from "../charts/utils";
import { HighChartsDrilldownSeries } from "../charts";
import { PieSeries, PieChartOptions } from "./types";

export class PieChartOptionsBuilder extends BaseOptionsBuilder {
  setSeries(pieSeries: PieSeries[], options?: PieChartOptions) {
    const { dataLabelFormatter, showDataLabels = false, onSeriesClick, seriesName = "" } = options || {};

    const pieSeriesData = pieSeries.map((pieSerie, i) => {
      const { name, value, custom = {}, color = getChartColor(i), id = generateId() } = pieSerie;

      const ptObj: PointOptionsObject = {
        color,
        name,
        custom,
        id,
        x: name as any,
        y: value,
        className: onSeriesClick ? "inc-cursor-pointer" : "",
        events: {
          click: function (this) {
            const { name, y } = this;
            onSeriesClick && onSeriesClick(name, y);
          }
        },
        drilldown: pieSerie.drillDownId,
        borderColor: color
      };

      return ptObj;
    });

    const pieSeriesOpt: SeriesPieOptions = {
      type: "pie",
      data: pieSeriesData,
      dataLabels: {
        format: "{point.name}: {point.y}",
        useHTML: true,
        verticalAlign: "bottom",
        enabled: showDataLabels,
        color: "white",
        className: "inc-data-label",
        distance: "10%"
      },
      name: seriesName,
      borderWidth: 0
    };

    if (dataLabelFormatter) {
      const dataLabels = pieSeriesOpt.dataLabels as SeriesPieDataLabelsOptionsObject;
      delete dataLabels.format;
      dataLabels.formatter = function (this) {
        return dataLabelFormatter(this);
      };
    }

    this.chartOptions.series = [pieSeriesOpt];
    return this;
  }

  setDrillDownSeries(drillDownSeries: Array<HighChartsDrilldownSeries<PieSeries>>) {
    this.setDrillDownSeriesOptions({});
    if (drillDownSeries?.length > 0) {
      const pieDDSeries: DrilldownOptions["series"] = drillDownSeries.map((ddSerie, i) => ({
        name: ddSerie.name,
        id: ddSerie.id,
        type: "pie",
        dataLabels: {
          enabled: false
        },
        data: ddSerie.series.map(serie => ({
          name: serie.name,
          x: serie.name as any,
          y: serie.value,
          color: serie.color || getChartColor(i),
          borderColor: serie.color || getChartColor(i),
          drilldown: serie.drillDownId,
          custom: serie.custom
        }))
      }));
      this.chartOptions.drilldown = {
        series: pieDDSeries
      };
    }
    return this;
  }

  setToolTip(tooltipValueFormatter: PieChartOptions["tooltipValueFormatter"]) {
    this.chartOptions.tooltip = this.chartOptions.tooltip || {};

    const tooltipOptions: TooltipOptions = {
      enabled: true,
      shared: true,
      followPointer: true,
      outside: true,
      useHTML: true,
      className: "highcharts-tooltip",
      formatter: function () {
        if (!this.point) {
          return "";
        }

        const { series, y, name, color, custom } = this.point as any;

        return getCommonToolTipForCompare(name, y, color, series, custom, tooltipValueFormatter);
      }
    };
    defaultsDeep(this.chartOptions.tooltip, tooltipOptions);
    return this;
  }

  setLegend(showLegend: boolean) {
    super.setLegend(showLegend);
    this.chartOptions.legend.width = "25%";
    return this;
  }

  setMode(mode: "gauge" | "donut") {
    if (mode) {
      const isGauge = mode === "gauge";
      const startAngle = isGauge ? -90 : -180;
      const endAngle = isGauge ? 90 : 180;

      this.chartOptions.plotOptions.pie = {
        ...(this.chartOptions.plotOptions.pie || {}),
        startAngle,
        endAngle,
        innerSize: "50%",
        center: ["50%", "50%"],
        size: "100%"
      };

      if (isGauge && typeof this.chartOptions.chart.height === "number") {
        this.chartOptions.chart.height *= 1.5;
      }
    }
    return this;
  }

  setStats(stats: string) {
    this.chartOptions.title = {
      verticalAlign: "middle",
      floating: true,
      text: stats,
      useHTML: true
    };
  }
}
