import { IncFaIcon, IncInModalConfirmation, IncToolTip } from "@inception/ui";
import { forEach } from "lodash";
import React, { FC, useCallback, useMemo, useRef } from "react";
import { VerticallyCenteredRow } from "../../../components";
import { useAccessPrivilege, useRefState, useToggleState } from "../../../core";
import {
  ActionCategory,
  ActionCategoryType,
  ActionRunMode,
  ActionSourceTypes,
  BizActionConfig,
  MuteTimeRange
} from "../../../services/api/operationalise";
import { useOpStore } from "../../context";
import { ActionInfo, AddActionSelect } from "../../components";
import { ActionCard } from "./ActionCard";

interface Props {
  onSelectAction: (bizActionId: string) => void;
  onDeleteAction: (bizActionId: string) => void;
  onCloneAction: (bizActionId: string) => void;
  onDisableAction: (bizActionId: string) => void;
  onEnableAction: (bizActionId: string) => void;
  onSnoozeAction: (bizActionId: string, timeRange: MuteTimeRange) => void;

  temporaryAction: BizActionConfig;
  selectedActionId: string;
  onCreateAction: (categoryId: ActionCategoryType, sourceType: ActionSourceTypes, runMode: ActionRunMode) => void;

  actionCategories: ActionCategory[];
  sourceTypeInfo: Record<string, Record<string, ActionInfo>>;

  errorsByActionId: Record<string, string>;
}

export const OpActionsList: FC<Props> = props => {
  const {
    onCloneAction: pOnCloneAction,
    onDeleteAction: pOnDeleteAction,
    onSelectAction: pOnSelectAction,
    onSnoozeAction,
    temporaryAction,
    selectedActionId,
    onCreateAction,
    onDisableAction,
    onEnableAction,
    errorsByActionId,
    sourceTypeInfo,
    actionCategories
  } = props;

  const { state } = useOpStore();

  const { canCreate } = useAccessPrivilege();

  const { opCreationConfig } = state;
  const { bizActions: pBizActions } = opCreationConfig;

  const bizActionsRef = useRefState(pBizActions);

  const isTemporaryActionActive = Boolean(temporaryAction);

  const {
    close: closeDeleteConfirmation,
    isOpen: isDeleteConfirmationOpen,
    open: openDeleteConfirmation
  } = useToggleState();

  const {
    close: closeSwitchConfirmation,
    isOpen: isSwitchConfirmationOpen,
    open: openSwitchConfirmation
  } = useToggleState();

  const {
    close: closeCloneConfirmation,
    isOpen: isCloneConfirmationOpen,
    open: openCloneConfirmation
  } = useToggleState();

  const switchActionIdRef = useRef("");
  const deleteActionIdRef = useRef("");
  const cloneActionIdRef = useRef("");

  const sourceTypeIconRef = useRef<Record<string, any>>({});

  const onTriggerDeleteAction = useCallback(
    (actionId: string) => {
      deleteActionIdRef.current = actionId;
      openDeleteConfirmation();
    },
    [openDeleteConfirmation]
  );

  const onConfirmDeleteAction = useCallback(() => {
    const deleteActionId = deleteActionIdRef.current;
    if (deleteActionId) {
      pOnDeleteAction(deleteActionId);
      closeDeleteConfirmation();
      deleteActionIdRef.current = null;
    }
  }, [closeDeleteConfirmation, pOnDeleteAction]);

  const onCancelDeleteAction = useCallback(() => {
    deleteActionIdRef.current = null;
    closeDeleteConfirmation();
  }, [closeDeleteConfirmation]);

  const onTriggerCloneAction = useCallback(
    (actionId: string) => {
      if (isTemporaryActionActive) {
        cloneActionIdRef.current = actionId;
        openCloneConfirmation();
      } else {
        pOnCloneAction(actionId);
      }
    },
    [isTemporaryActionActive, openCloneConfirmation, pOnCloneAction]
  );

  const onConfirmCloneAction = useCallback(() => {
    const cloneActionId = cloneActionIdRef.current;
    if (cloneActionId) {
      pOnCloneAction(cloneActionId);
      closeCloneConfirmation();
      cloneActionIdRef.current = null;
    }
  }, [closeCloneConfirmation, pOnCloneAction]);

  const onCancelCloneAction = useCallback(() => {
    cloneActionIdRef.current = null;
    closeCloneConfirmation();
  }, [closeCloneConfirmation]);

  const onTriggerSwitchAction = useCallback(
    (actionId: string) => {
      if (isTemporaryActionActive) {
        switchActionIdRef.current = actionId;
        openSwitchConfirmation();
      } else {
        pOnSelectAction(actionId);
      }
    },
    [isTemporaryActionActive, openSwitchConfirmation, pOnSelectAction]
  );

  const onConfirmSwitchAction = useCallback(() => {
    const switchActionId = switchActionIdRef.current;
    if (switchActionId) {
      pOnSelectAction(switchActionId);
      closeSwitchConfirmation();
      switchActionIdRef.current = null;
    }
  }, [closeSwitchConfirmation, pOnSelectAction]);

  const onCancelSwitchAction = useCallback(() => {
    switchActionIdRef.current = null;
    closeSwitchConfirmation();
  }, [closeSwitchConfirmation]);

  const bizActions = bizActionsRef.current;

  const bizActionsJsx = useMemo(() => {
    const bizActionDivs: JSX.Element[] = [];

    const getBizActionDiv = (bizAction: BizActionConfig, bizActionId: string, isUnsavedAction: boolean) => {
      const { alertActionConfig, isDisabled } = bizAction;
      const { actionCategoryType, sourceTypeId, actionMuteSpec } =
        alertActionConfig?.alertActionConfigDef?.alertActionDef || {};

      const muteTimeRange = actionMuteSpec?.muteTimeRange;

      if (sourceTypeId && actionCategoryType) {
        const key = [actionCategoryType, sourceTypeId, bizActionId || ""].join("-");
        const error = errorsByActionId[bizActionId] || "";
        const errorsArr = error ? [error] : [];
        const name = alertActionConfig?.name;

        const onClone = bizActionId !== TEMPORARY_ACTION_ID ? () => onTriggerCloneAction(bizActionId) : null;
        const onDelete = bizActionId !== TEMPORARY_ACTION_ID ? () => onTriggerDeleteAction(bizActionId) : null;
        const onSelect = bizActionId !== TEMPORARY_ACTION_ID ? () => onTriggerSwitchAction(bizActionId) : null;
        const onDisable = bizActionId !== TEMPORARY_ACTION_ID ? () => onDisableAction(bizActionId) : null;
        const onEnable = bizActionId !== TEMPORARY_ACTION_ID ? () => onEnableAction(bizActionId) : null;
        const onSnooze =
          bizActionId !== TEMPORARY_ACTION_ID
            ? (timeRange: MuteTimeRange) => onSnoozeAction(bizActionId, timeRange)
            : null;
        const bizActionComponent = (
          <ActionCard
            actionCategoryType={actionCategoryType}
            bizActionId={bizActionId}
            errors={errorsArr}
            isDisabled={isDisabled}
            isUnsavedAction={isUnsavedAction}
            key={key}
            name={name}
            onClone={onClone}
            onDelete={onDelete}
            onDisable={onDisable}
            onEnable={onEnable}
            onSelect={onSelect}
            onSnooze={onSnooze}
            selectedActionId={selectedActionId}
            snoozeTimeRange={muteTimeRange}
            sourceTypeIconRef={sourceTypeIconRef}
            sourceTypeId={sourceTypeId}
            sourceTypeInfo={sourceTypeInfo}
          />
        );

        bizActionDivs.push(bizActionComponent);
      } else if (bizActionId !== TEMPORARY_ACTION_ID) {
        const onDelete = () => onTriggerDeleteAction(bizActionId);

        bizActionDivs.push(
          <VerticallyCenteredRow
            className="actions-list--card marginBt12 visible-on-hover"
            style={{
              borderRadius: 8,
              background: "#2F3842",
              padding: "20px 16px"
            }}
          >
            <VerticallyCenteredRow className="inc-flex-grow marginRt12 inc-cursor-pointer">
              <IncToolTip
                placement="top"
                titleText="Invalid source or action category"
                variant="error"
              >
                <div className="error-message marginRt12">
                  <IncFaIcon
                    className="status-danger"
                    iconName="warning"
                  />
                </div>
              </IncToolTip>

              <VerticallyCenteredRow className="inc-text-subtext-medium">Invalid action</VerticallyCenteredRow>
            </VerticallyCenteredRow>

            <VerticallyCenteredRow
              className="inc-cursor-pointer"
              onClick={onDelete}
            >
              <IncFaIcon
                className="status-danger"
                iconName="minus-circle"
              />
            </VerticallyCenteredRow>
          </VerticallyCenteredRow>
        );
      }
    };

    forEach(bizActions, (bizAction, bizActionId) => getBizActionDiv(bizAction, bizActionId, false));

    if (temporaryAction) {
      getBizActionDiv(temporaryAction, TEMPORARY_ACTION_ID, true);
    }

    return bizActionDivs;
  }, [
    bizActions,
    errorsByActionId,
    onDisableAction,
    onEnableAction,
    onSnoozeAction,
    onTriggerCloneAction,
    onTriggerDeleteAction,
    onTriggerSwitchAction,
    selectedActionId,
    sourceTypeInfo,
    temporaryAction
  ]);

  const deleteConfirmationMessage = useMemo(() => `Are you sure you want to delete this action?`, []);
  const switchConfirmationMessage = "Current changes will be lost. Are you sure you want to proceed?";

  return (
    <>
      <div className="actions-list">
        {bizActionsJsx}
        {!temporaryAction && canCreate && (
          <VerticallyCenteredRow className="marginTp12">
            <AddActionSelect
              actionCategories={actionCategories}
              isLoading={false}
              onAlertSourceTypeSelected={onCreateAction}
            />
          </VerticallyCenteredRow>
        )}
      </div>

      {isDeleteConfirmationOpen && (
        <IncInModalConfirmation
          message={deleteConfirmationMessage}
          onCancel={onCancelDeleteAction}
          onConfirm={onConfirmDeleteAction}
        />
      )}

      {isSwitchConfirmationOpen && (
        <IncInModalConfirmation
          message={switchConfirmationMessage}
          onCancel={onCancelSwitchAction}
          onConfirm={onConfirmSwitchAction}
        />
      )}

      {isCloneConfirmationOpen && (
        <IncInModalConfirmation
          message={switchConfirmationMessage}
          onCancel={onCancelCloneAction}
          onConfirm={onConfirmCloneAction}
        />
      )}
    </>
  );
};

export const TEMPORARY_ACTION_ID = `__i_temporary_action`;
