import { IncSelect, IncTextfield, IncSelectOption } from "@inception/ui";
import React, { useCallback, useMemo, useState, useEffect, ChangeEvent } from "react";
import { DestLocationType, InputType, LocationConfig } from "../transform-config/TransformConfig";

interface LocationCompProps {
  config: LocationConfig;
  onChange: (config: LocationConfig) => void;
  getLocationEntityTypes: () => Record<string, string>;
  setEntityTypeValidity: (valid: boolean) => void;
  fieldName: string;
}
export const LocationComp: React.FC<LocationCompProps> = props => {
  const { config, onChange, getLocationEntityTypes, setEntityTypeValidity, fieldName: pFieldName } = props;

  const [locationType, setLocationType] = useState<IncSelectOption>(null);
  const [inputType, setInputType] = useState<IncSelectOption>(null);
  const [entityType, setEntityType] = useState("");
  const [fieldName, setFieldName] = useState("");

  const checkEntityTypeExists = useCallback(() => {
    const locationEntityTypes = getLocationEntityTypes();
    for (const k in locationEntityTypes) {
      if (k !== pFieldName && entityType === locationEntityTypes[k]) {
        setFieldName(k);
        return true;
      }
    }
    return false;
  }, [entityType, getLocationEntityTypes, pFieldName]);
  const isEntityTypeInvalid = useMemo(() => checkEntityTypeExists(), [checkEntityTypeExists]);

  const getOption = useCallback(
    (value: string): IncSelectOption => ({
      label: value,
      value: value
    }),
    []
  );

  const getLocationTypeOptions = useMemo(
    () => Object.values(DestLocationType).map(role => getOption(role)),
    [getOption]
  );
  const getInputTypeOptions = useMemo(() => Object.values(InputType).map(role => getOption(role)), [getOption]);

  useEffect(() => {
    if (config && config?.destLocationType) {
      const pLocationTypeOption = getOption(config.destLocationType);
      setLocationType(pLocationTypeOption);
    }
    if (config && config?.inputType) {
      const pInputType = getOption(config.inputType);
      setInputType(pInputType);
    }
    if (config && config?.destEntityType) {
      setEntityType(config.destEntityType);
    }
  }, [config, getOption]);

  useEffect(() => {
    if (isEntityTypeInvalid) {
      setEntityTypeValidity(false);
    } else {
      setEntityTypeValidity(true);
    }
  }, [isEntityTypeInvalid, setEntityTypeValidity]);

  const onChangeConfig = useCallback(
    (locationType: IncSelectOption, inputType: IncSelectOption, entityType: string) => {
      if (locationType && inputType) {
        const newConfig: LocationConfig = {
          destLocationType: locationType.value as DestLocationType,
          inputType: inputType.value as InputType,
          destEntityType: entityType
        };
        onChange(newConfig);
      }
    },
    [onChange]
  );

  const onLocationTypeChange = useCallback(
    (option: IncSelectOption) => {
      setLocationType(option);
      onChangeConfig(option, inputType, entityType);
    },
    [entityType, inputType, onChangeConfig]
  );

  const onInputTypeChange = useCallback(
    (option: IncSelectOption) => {
      setInputType(option);
      onChangeConfig(locationType, option, entityType);
    },
    [entityType, locationType, onChangeConfig]
  );

  const onEntityTypeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setEntityType(value);
      onChangeConfig(locationType, inputType, value);
    },
    [inputType, locationType, onChangeConfig]
  );

  return (
    <div className="location-transform">
      <IncSelect
        label="Location Type"
        onChange={onLocationTypeChange}
        options={getLocationTypeOptions}
        required
        value={locationType}
      />
      <IncSelect
        label="Input Type"
        onChange={onInputTypeChange}
        options={getInputTypeOptions}
        required
        value={inputType}
      />
      <IncTextfield
        errorText={`This entity type is already selected for field ${fieldName}`}
        hasError={isEntityTypeInvalid}
        label="Entity Type"
        onChange={onEntityTypeChange}
        value={entityType}
      />
    </div>
  );
};
