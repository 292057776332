import { useMemo } from "react";
import appConfig from "../../../../appConfig";
import {
  WidgetDataDTO,
  WidgetConfigDTO,
  SliceSpec,
  TagFilter,
  CohortEntityFilter,
  MetricUserServiceFilters,
  DemoDataParams
} from "../../../services/api/explore";
import { DataQueryRequest, DataQueryResponse } from "../../../services/api/types";
import { ExploreQuery, ExploreQueryType, ExploreQueryPayload } from "../../../services/datasources/explore/types";
import { QueryOptions, useWidgetQuery } from "../useQuery";
import { WidgetExtProps } from "../types";
import { bizEntityDataTransformer, BizEntityDataResult } from "../utils";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { getDownSampleInterval } from "../../../utils";

export const DISPLAY_NAME_TAG = "__display_name__";

export type BizEntityDataFetchResult = Array<DataQueryResponse<BizEntityDataResult[]>>;

export type BizEntityDataFetchProps<R> = {
  id: string;
  entityType: string;
  entityId: string;
  widgetId: string;
  widgetConfig: WidgetConfigDTO;
  mode: ExploreQueryType;
  timeRange: WidgetExtProps["timeRange"];
  version: number;
  compareInfo: ExploreQueryPayload["compareInfo"];
  sliceSpec: SliceSpec[];
  entityFilters: CohortEntityFilter[];
  cohortFilters: CohortEntityFilter[];
  transformer: (data: BizEntityDataFetchResult) => Promise<Array<DataQueryResponse<R>>>;
  tagFilters?: TagFilter[];
  excludeWidgetData?: boolean;
  limit?: number;
  queryKeySuffix?: string;
  datasource?: string;
  useRawTimeRange?: boolean;
  includeQueryConfig?: boolean;
  useV2ApiForCompare?: boolean;
  eventFilters?: MetricUserServiceFilters;
  dsIntervalStr?: string;
  demoParams?: DemoDataParams;
};

export const useBizEntityDataQuery = <R,>(props: BizEntityDataFetchProps<R>) => {
  const {
    id: panelId,
    entityId = null,
    entityType = null,
    widgetId,
    widgetConfig,
    timeRange,
    entityFilters,
    cohortFilters,
    tagFilters,
    version,
    mode,
    transformer,
    compareInfo,
    sliceSpec,
    limit,
    queryKeySuffix,
    excludeWidgetData,
    datasource = appConfig.defaultExploreDsName,
    eventFilters = {},
    useRawTimeRange = false,
    includeQueryConfig = false,
    useV2ApiForCompare = false,
    dsIntervalStr,
    demoParams
  } = props;

  const { from, to, raw } = timeRange;

  const dashboardTimeRange = useMemo(
    () => ({
      from: from.valueOf(),
      to: to.valueOf()
    }),
    [from, to]
  );

  const interval = useMemo(
    () => getDownSampleInterval(dsIntervalStr, dashboardTimeRange),
    [dashboardTimeRange, dsIntervalStr]
  );
  const { dqr, queryTimeRange } = useMemo(() => {
    const latestTimeRange = timeRangeUtils.getTimeRangeFromRaw(raw);
    const { from: latestFrom, to: latestTo } = latestTimeRange;

    const startMillis = useRawTimeRange ? latestFrom.valueOf() : from.valueOf();
    const endMillis = useRawTimeRange ? latestTo.valueOf() : to.valueOf();

    const queryTimeRange = timeRangeUtils.getTimeRangeFromRaw({
      from: String(startMillis),
      to: String(endMillis)
    });

    const nEntityFilters = [...entityFilters, ...cohortFilters];

    const target: ExploreQuery = {
      query: "",
      refId: widgetId,
      type: mode,
      payload: {
        entityId,
        entityType,
        widgetConfig,
        widgetId,
        version,
        compareInfo,
        sliceSpec,
        limit,
        entityFilters: nEntityFilters,
        tagFilters: tagFilters ?? [],
        excludeWidgetData,
        includeQueryConfig,
        useV2ApiForCompare,
        metricUserServiceFilters: eventFilters,
        demoParams
      },
      datasource
    };

    const dqr: DataQueryRequest<ExploreQuery> = {
      dashboardId: "1",
      panelId,
      interval,
      requestId: panelId,
      startTime: startMillis,
      targets: [target],
      endTime: endMillis
    };

    return {
      dqr,
      queryTimeRange
    };
  }, [
    raw,
    useRawTimeRange,
    from,
    to,
    entityFilters,
    cohortFilters,
    widgetId,
    mode,
    entityId,
    entityType,
    widgetConfig,
    version,
    compareInfo,
    sliceSpec,
    limit,
    tagFilters,
    excludeWidgetData,
    includeQueryConfig,
    useV2ApiForCompare,
    eventFilters,
    demoParams,
    datasource,
    panelId,
    interval
  ]);

  const queryOptions: QueryOptions<WidgetDataDTO[], R> = useMemo(
    () => ({
      enricherType: "explore",
      transformer: {
        transform: async function (exploreWidgetResponses: Array<DataQueryResponse<WidgetDataDTO[]>>) {
          const partTransformed = bizEntityDataTransformer(exploreWidgetResponses);
          const transformed = await transformer(partTransformed);
          return transformed;
        }
      }
    }),
    [transformer]
  );

  const queryType = `biz-entity-query${queryKeySuffix || ""}`;
  const queryResult = useWidgetQuery(dqr, queryType, datasource, queryOptions);

  return {
    ...queryResult,
    queryTimeRange
  };
};
