import React, { FC, useEffect, useState, useMemo, useCallback } from "react";
import { IncToolTip, IncFaIcon, IncRTable } from "@inception/ui";
import {
  FieldPickerContextDTO,
  UserServiceFieldWithMeta,
  UserServiceField,
  ExploreEntityFilter,
  UserServiceFilterExpression,
  UserServiceFilterList,
  ImpactedWidget
} from "../../services/api/explore";
import { useFieldPicker } from "../../field-picker";
import { TimeRange, useTimeRange } from "../../core";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import {
  getUsEntityFields,
  getUsFieldInfoWithId,
  FieldsAndMetricsSidePanel,
  USFieldInfoWithId,
  USMetricInfoWithId
} from "../fields-side-panel";
import { VerticallyCenteredRow } from "../flex-components";
import { TableRenderer } from "./TableRenderer";

interface Props {
  entityTypeId: string;
  eventTypeId: string;
  userServiceToBizEntityFieldName?: Record<string, string>;

  preselectedFields?: string[];

  timeRange?: TimeRange;
  entityFilters?: ExploreEntityFilter[];
  eventFilters?: UserServiceFilterList;
  extendedFilters?: UserServiceFilterExpression[];
  impactedWidget?: ImpactedWidget;
  incidentId?: string;
  opConfigId?: string;

  onSelectionChange?: (selectionMap: Record<string, boolean>) => void;
}

export const EventsTable: FC<Props> = props => {
  const {
    entityTypeId,
    eventTypeId,
    timeRange: pTimeRange,
    preselectedFields,
    userServiceToBizEntityFieldName,
    onSelectionChange: pOnSelectionChange,
    ...restProps
  } = props;

  const { timeRange: gTimeRange } = useTimeRange();

  const { fromMillis, toMillis, timeRange } = useMemo(() => {
    const fTimeRange = pTimeRange || gTimeRange;
    const result = timeRangeUtils.getMillisFromTimeRange(fTimeRange);
    return {
      ...result,
      timeRange: fTimeRange
    };
  }, [gTimeRange, pTimeRange]);

  const defSelectionMap: Record<string, boolean> = (preselectedFields || []).reduce(
    (acc, fieldLabel) => ({
      ...acc,
      [fieldLabel]: true
    }),
    {}
  );
  const [selectionMap, setSelectionMap] = useState<Record<string, boolean>>(defSelectionMap);

  const fieldPickerContext = useMemo<FieldPickerContextDTO>(
    () => ({
      entityId: entityTypeId ? null : eventTypeId,
      entityName: null,
      entityType: entityTypeId,
      showFields: true,
      userServices:
        entityTypeId && eventTypeId
          ? [
              {
                userServiceEntityId: eventTypeId
              }
            ]
          : [],
      userServiceToBizEntityFieldName
    }),
    [entityTypeId, eventTypeId, userServiceToBizEntityFieldName]
  );

  const { data, error, getFields, isError, isFetching } = useFieldPicker();

  useEffect(() => {
    if (eventTypeId || entityTypeId) {
      getFields(fieldPickerContext, fromMillis, toMillis);
    }
  }, [entityTypeId, eventTypeId, fieldPickerContext, fromMillis, getFields, toMillis]);

  const { usBusinessFields, usEntityFields, usPerformanceFields, usJourneyFields } = useMemo(() => {
    let entityFields: UserServiceFieldWithMeta[] = [];
    let businessFields: UserServiceFieldWithMeta[] = [];
    let performanceFields: UserServiceFieldWithMeta[] = [];
    let eventTypeName = eventTypeId;

    if (data) {
      const { businessFields: bFields, entityFields: eFields, perfFields: pFields } = data.getUserServiceFieldsByType();

      entityFields = eFields;
      performanceFields = pFields;
      businessFields = bFields;
      eventTypeName = data.getUsName(eventTypeId);
    }

    const usEntityFields = getUsEntityFields(entityFields, eventTypeName, entitySkipProps);
    const usBusinessFields = businessFields.map(field => getUsFieldInfoWithId(field, eventTypeName));
    const usPerformanceFields = performanceFields
      .map(field => getUsFieldInfoWithId(field, eventTypeName))
      .filter(({ displayName }) => !skipFields.includes(displayName));

    return {
      usEntityFields,
      usBusinessFields,
      usPerformanceFields,
      usJourneyFields: [] as USFieldInfoWithId[]
    };
  }, [data, eventTypeId]);

  const errorDiv = useMemo(
    () =>
      error ? (
        <VerticallyCenteredRow className="flex-gap-6">
          <IncToolTip
            titleText={String(error)}
            variant="error"
          >
            <IncFaIcon iconName="exclamation-triangle" />
          </IncToolTip>
          <div>Error fetching fields</div>
        </VerticallyCenteredRow>
      ) : (
        <></>
      ),
    [error]
  );

  const selectedUSFields = useMemo(() => {
    const selectedUSFields: UserServiceField[] = [];
    [...usBusinessFields, ...usPerformanceFields, ...usEntityFields].forEach(fieldWithInfo => {
      const { id, usField } = fieldWithInfo;
      const isSelected = selectionMap[id] || false;
      if (isSelected) {
        selectedUSFields.push(usField.userServiceField);
      }
    });

    return selectedUSFields;
  }, [selectionMap, usBusinessFields, usEntityFields, usPerformanceFields]);

  const onSelectionChange = useCallback(
    (selectionMap: Record<string, boolean>) => {
      pOnSelectionChange && pOnSelectionChange(selectionMap);
      setSelectionMap(selectionMap);
    },
    [pOnSelectionChange]
  );

  return (
    <div className="events-table">
      {!isError && (
        <>
          <div className="events-table--field-selector">
            <FieldsAndMetricsSidePanel
              businessFields={usBusinessFields}
              entityFields={usEntityFields}
              fieldsSelectionMap={selectionMap}
              isLoading={isFetching}
              journeyFields={usJourneyFields}
              metrics={metrics}
              metricsSelectionMap={metricSelectionMap}
              onSelectionChange={onSelectionChange}
              performanceFields={usPerformanceFields}
            />
          </div>
          <div className="events-table--fields-table">
            <TableRenderer
              entityTypeId={entityTypeId}
              selectedUSFields={selectedUSFields}
              timeRange={timeRange}
              {...restProps}
            />
          </div>
        </>
      )}

      {isError && (
        <>
          <div className="events-table--field-selector">{errorDiv}</div>
          <div className="events-table--fields-table">
            <IncRTable
              columns={[]}
              data={[]}
              errorDataMessage={errorDiv}
              hasError
            />
          </div>
        </>
      )}
    </div>
  );
};

const entitySkipProps = ["Id", "Name"];
const skipFields = ["userService", "groupingID"];

const metrics: USMetricInfoWithId[] = [];
const metricSelectionMap: Record<string, boolean> = {};
