import React, { FC, useEffect, useMemo, useState, useCallback, useRef } from "react";
import { IncTextfield, IncButton, generateId, IncFaIcon, IncSelect, IncSelectOption, IncAvatar } from "@inception/ui";
import { useChatBotStore, setConversation, addToConversation } from "../context";
import { useFetchConversation } from "../hooks";
import { NEW_CONVERSATION_ID } from "../constants";
import { LoadingSpinner, VerticallyCenteredRow } from "../../components";
import { ChatBotStrategy, ConversationEntry, chatApi } from "../../services/api/chat";
import { useLoggedInUserInfo } from "../../core";
import { ReactComponent as EmptyState } from "../../../images/chat-bot/chat-empty-2.svg";
import { ConversationEntryRenderer } from "./ConversationEntryRenderer";

interface Props {}

export const ConversationRenderer: FC<Props> = () => {
  const ref = useRef<HTMLDivElement>();

  const { dispatch, state } = useChatBotStore();
  const { email, name } = useLoggedInUserInfo();

  const [strategy, setStrategy] = useState<StrategyOption>({
    value: "DEFAULT",
    label: "Default",
    data: ChatBotStrategy.DEFAULT
  });

  const userAvatar = useMemo(() => <IncAvatar>{name.charAt(0).toUpperCase()}</IncAvatar>, [name]);

  const { selectedConversationId, conversationsMap } = state;
  const isNewConversation = selectedConversationId === NEW_CONVERSATION_ID;

  const { conversationEntries, conversationInitialised } = useMemo(() => {
    let conversationEntries: ConversationEntry[] = [];
    let conversationInitialised = true;

    if (!isNewConversation) {
      const conversation = conversationsMap[selectedConversationId];
      conversationEntries = conversation?.conversationEntries || [];
      conversationInitialised = Boolean(conversation);
    }

    return {
      conversationEntries,
      conversationInitialised
    };
  }, [conversationsMap, isNewConversation, selectedConversationId]);

  const { data, error, isError, isFetching, refetch } = useFetchConversation(selectedConversationId, email);

  useEffect(() => {
    if (!isNewConversation && selectedConversationId && !conversationInitialised) {
      refetch();
    }
  }, [conversationInitialised, isNewConversation, refetch, selectedConversationId]);

  useEffect(() => {
    if (!isFetching && !isError && !isNewConversation) {
      dispatch(
        setConversation({
          chatId: selectedConversationId,
          conversationEntries: data,
          nextPage: -1,
          page: 1,
          strategy: ChatBotStrategy.DEFAULT,
          userId: email
        })
      );
    }
  }, [data, dispatch, email, isError, isFetching, isNewConversation, selectedConversationId]);

  const [message, setMessage] = useState("");
  const disableSend = !message;

  const [tempConversation, setTempConversation] = useState<ConversationEntry>();
  const [opInProgress, setOpInProgress] = useState(false);

  const onMessageChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value || "";
    setMessage(value);
  }, []);

  const sendMessageAsync = useCallback(
    async (chatId: string, message: string, conversation: ConversationEntry) => {
      try {
        const { data, error, message: apiMessage } = await chatApi.sendMessage(chatId, message, strategy.data);

        if (error) {
          conversation.response = {
            fragment: [],
            message: apiMessage,
            recommendations: []
          };
        } else {
          conversation.response = data;
        }
      } catch (err) {
        conversation.response = {
          fragment: [],
          message: err.toString(),
          recommendations: []
        };
      } finally {
        const nowMillis = new Date().valueOf();
        conversation.bicycleMessageTimeMillis = nowMillis.toString();

        dispatch(addToConversation(conversation));
        setOpInProgress(false);
        setTempConversation(null);
      }
    },
    [dispatch, strategy.data]
  );

  const sendMessage = useCallback(
    (customMessage?: string) => {
      if (!disableSend || customMessage) {
        const chatId = isNewConversation ? generateId() : selectedConversationId;
        const nowMillis = new Date().valueOf();

        const conversation: ConversationEntry = {
          chatId,
          message: customMessage || message,
          response: null,
          timeStampInMillis: nowMillis.toString(),
          bicycleMessageTimeMillis: nowMillis.toString()
        };

        setTempConversation({ ...conversation });
        setOpInProgress(true);
        setMessage("");
      }
    },
    [disableSend, isNewConversation, message, selectedConversationId]
  );

  useEffect(() => {
    if (tempConversation) {
      const { chatId, message } = tempConversation;
      sendMessageAsync(chatId, message, { ...tempConversation });
    }
  }, [sendMessageAsync, tempConversation]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Escape") {
        e.preventDefault();
        setMessage("");
      } else if (e.key === "Enter") {
        e.preventDefault();
        !disableSend && sendMessage();
      }
    },
    [disableSend, sendMessage]
  );

  const conversationEntriesJsx = useMemo(() => {
    if (!selectedConversationId) {
      return <></>;
    }

    if (isFetching) {
      return (
        <div className="empty-message">
          <LoadingSpinner titleText="Loading conversation..." />
        </div>
      );
    }

    if (isError) {
      return <div className="empty-message status-danger">Error fetching conversations: {error}</div>;
    }

    const numConversationEntries = conversationEntries.length;
    if (numConversationEntries || tempConversation) {
      const allConversations = tempConversation ? [...conversationEntries, tempConversation] : [...conversationEntries];

      return allConversations.map((entry, idx) => {
        const { chatId } = entry;
        const key = `${chatId}-${idx}`;
        const inProgress = entry === tempConversation && opInProgress;
        const isLast = idx === numConversationEntries - 1;

        return (
          <ConversationEntryRenderer
            conversation={entry}
            id={key}
            inProgress={inProgress}
            isLast={isLast}
            key={key}
            onApplyRecommendation={sendMessage}
            userAvatar={userAvatar}
          />
        );
      });
    }

    return (
      <div className="empty-message">
        <div className="inc-flex-column inc-flex-center">
          <EmptyState className="empty-state-svg" />
          <div className="marginTp16">This is the beginning of your conversation</div>
        </div>
      </div>
    );
  }, [
    conversationEntries,
    error,
    isError,
    isFetching,
    opInProgress,
    selectedConversationId,
    sendMessage,
    tempConversation,
    userAvatar
  ]);

  const onStrategyChange = useCallback((op: IncSelectOption) => {
    setStrategy(op);
  }, []);

  useEffect(() => {
    if (ref.current) {
      const listDiv = ref.current;
      listDiv.scrollTop = listDiv.scrollHeight;
    }
  }, [tempConversation, conversationEntries]);

  // the strategy select list should appear in center of conversation screen

  return (
    <div className="conversation">
      {Boolean(selectedConversationId) && (
        <>
          <VerticallyCenteredRow className="conversation-header">
            {userAvatar}
            <div className="marginLt10">{name}</div>
            <div className="marginLtAuto strategy-selector">
              <IncSelect
                autoSort={false}
                className="inc-select"
                isMulti={false}
                isSearchable={false}
                label="Select Strategy"
                menuPlacement="auto"
                menuPortalTarget={document.body}
                onChange={onStrategyChange as any}
                options={strategyOptions}
                value={strategy}
              />
            </div>
          </VerticallyCenteredRow>
          <div
            className="conversation-entries"
            ref={ref}
          >
            {conversationEntriesJsx}
          </div>

          <VerticallyCenteredRow className="chat-actions inc-flex-grow">
            <IncTextfield
              autoFocus
              className="inc-flex-grow"
              onChange={onMessageChange}
              onKeyDown={onKeyDown}
              placeholder="Message"
              value={message}
            />

            <IncButton
              className="new-conversation"
              color="primary"
              disabled={disableSend}
              iconType="icon"
              onClick={() => sendMessage()}
            >
              <IncFaIcon iconName="paper-plane" />
            </IncButton>
          </VerticallyCenteredRow>
        </>
      )}
    </div>
  );
};

type StrategyOption = IncSelectOption<ChatBotStrategy>;
// list of strategies GPT-4, GPT-3.5, DEFAULT
const strategyOptions: StrategyOption[] = [
  {
    label: "Default",
    value: "DEFAULT",
    data: ChatBotStrategy.DEFAULT
  },
  {
    label: "GPT-4",
    value: "GPT-4",
    data: ChatBotStrategy.GPT4
  },
  {
    label: "GPT-3.5",
    value: "GPT-3.5",
    data: ChatBotStrategy.GPT3_5
  }
];
