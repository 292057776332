import { defaultsDeep } from "lodash";
import BaseWidgetImpl from "../../../model-impl/BaseWidgetImpl";
import { MetricTableConfig } from "../../../../components/metric-table";
import { MetricTableWidgetModel } from "./model";

const getDefaults = (): Partial<MetricTableWidgetModel> => ({
  title: "New data table",
  properties: {},
  type: "metric-table",
  tableConfig: getDefaultMetricTableConfig(),
  datasource: ""
});
export class MetricTableWidgetImpl extends BaseWidgetImpl implements MetricTableWidgetModel {
  type: "metric-table";
  tableConfig: MetricTableConfig;

  constructor(model: Partial<MetricTableWidgetModel>, meta?: Record<string, any>) {
    super(model, meta);
    this.assign(model);
  }

  assign(model: Partial<MetricTableWidgetModel>) {
    const mnModel: MetricTableWidgetModel = defaultsDeep({ type: "metric-table" }, model, getDefaults());
    super.assign(mnModel);

    const { properties, tableConfig } = mnModel;

    this.properties = { ...properties };
    this.tableConfig = tableConfig;
  }
}

export const getDefaultMetricTableConfig = (): MetricTableConfig => ({
  entityTypeId: "",
  metricColumns: [],
  slices: [],
  properties: {
    filterable: false,
    pagination: {
      enabled: true,
      pageSize: 10
    },
    showDisplayStats: true
  }
});
