import { closeSnackbar, useSnackbar } from "notistack";
import React, { useCallback, useMemo } from "react";
import { VerticallyCenteredRow } from "../../components";

type NotifierType = "error" | "warning" | "success" | "info" | "default";

export type IncNotificationOptions = {
  persist?: boolean;
  action?: (closeSnackbar: () => void) => JSX.Element;
};

const useNotifications = () => {
  const { enqueueSnackbar } = useSnackbar();

  const notify = useCallback(
    (message: string, variant: NotifierType, options?: IncNotificationOptions) => {
      const { action: defAction, persist = false, ...restOptions } = options || {};

      const action = defAction
        ? (snackbarId: string) => {
            const onClose = () => closeSnackbar(snackbarId);
            return (
              <VerticallyCenteredRow className="inc-notification-container--action">
                {defAction(onClose)}
              </VerticallyCenteredRow>
            );
          }
        : undefined;

      const className = `inc-notification-container inc-notification-container--${variant}`;
      if (message) {
        enqueueSnackbar(message, {
          ...restOptions,
          persist,
          action,
          variant,
          className
        });
      }
    },
    [enqueueSnackbar]
  );

  const notifyError = useCallback(
    (message: string, options?: IncNotificationOptions) => notify(message, "error", options),
    [notify]
  );
  const notifyWarning = useCallback(
    (message: string, options?: IncNotificationOptions) => notify(message, "warning", options),
    [notify]
  );
  const notifySuccess = useCallback(
    (message: string, options?: IncNotificationOptions) => notify(message, "success", options),
    [notify]
  );
  const notifyInfo = useCallback(
    (message: string, options?: IncNotificationOptions) => notify(message, "info", options),
    [notify]
  );
  const notifyLog = useCallback(
    (message: string, options?: IncNotificationOptions) => notify(message, "default", options),
    [notify]
  );

  const notifier = useMemo(
    () => ({
      notifyError,
      notifyWarning,
      notifySuccess,
      notifyLog,
      notifyInfo
    }),
    [notifyError, notifyInfo, notifyLog, notifySuccess, notifyWarning]
  );

  return notifier;
};

export { useNotifications };
