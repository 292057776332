import { useMemo, useCallback } from "react";
import { InitialState, useDataFetch, FetchFn, FetchCallBackResult } from "../../core";
import { Conversation, chatApi } from "../../services/api/chat";

export const useFetchAllConversations = () => {
  const initialState = useMemo<InitialState<Conversation[], string>>(
    () => ({
      data: [],
      error: null,
      isError: false,
      isFetching: true,
      isSuccess: false
    }),
    []
  );

  const fetchFn = useCallback<FetchFn<Conversation[], string>>(async () => {
    const result: FetchCallBackResult<Conversation[], string> = {
      data: [],
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const { data, error, message } = await chatApi.getAllConversations();
      if (error) {
        result.error = message;
        result.isError = true;
      } else {
        result.data = data?.conversations || [];
        result.isSuccess = true;
      }
    } catch (err) {
      result.error = err.toString();
      result.isError = true;
    }

    return result;
  }, []);

  return useDataFetch(fetchFn, initialState);
};
