import { useCallback, useMemo, useState } from "react";
import {
  UserServiceField,
  ExploreEntityFilter,
  WidgetConfigDTO,
  SliceSpec,
  CohortEntityFilter,
  UserServiceFilterExpression,
  UserServiceFilterList,
  SortSpec,
  ImpactedWidget,
  alertApiService,
  ImpactWidgetViewRequest
} from "../../services/api/explore";
import { ExploreQueryType } from "../../services/datasources/explore/types";
import { EntityWidgetData, useEntityDataQuery } from "../../biz-entity/hooks";
import { TimeRange } from "../../core";

type Props = {
  uniqId: string;
  entityTypeId: string;
  selectedUSFields: UserServiceField[];

  entityFilters: ExploreEntityFilter[];
  eventFilters: UserServiceFilterList;
  extendedFilters: UserServiceFilterExpression[];

  timeRange: TimeRange;
};

export const useFetchEventsTableData = (props: Props) => {
  const { entityFilters, selectedUSFields, eventFilters, entityTypeId, uniqId, extendedFilters, ...rest } = props;

  const payload = useMemo(() => {
    const widgetConfig: WidgetConfigDTO = {
      dataDefinition: {
        fields: {
          [uniqId]: {
            id: uniqId,
            sourceType: "eventField",
            eventFieldQueryConfig: {
              filterExpressions: extendedFilters || [],
              userServiceFields: selectedUSFields
            }
          }
        },
        metrics: {}
      },
      isStatic: false,
      name: "EventsTableDataFetch",
      visualizations: []
    };

    const sliceSpec: SliceSpec[] = [
      {
        selectorSpec: {
          filters: []
        },
        sliceSet: {
          slices: []
        },
        fieldId: uniqId
      }
    ];

    return {
      widgetConfig,
      sliceSpec
    };
  }, [extendedFilters, selectedUSFields, uniqId]);

  const filters = useMemo(() => {
    const fieldIds = payload.sliceSpec.map(ss => ss.fieldId);

    const cEntityFilters: CohortEntityFilter[] = [];
    entityFilters?.forEach(entityFilter => {
      entityFilter.filters?.forEach(predicate => {
        cEntityFilters.push({
          fieldType: "bizEntityField",
          predicate
        });
      });
    });

    const eventFiltersRec: Record<string, UserServiceFilterList> = {};
    if (eventFilters) {
      fieldIds.forEach(fieldId => (eventFiltersRec[fieldId] = eventFilters));
    }

    return {
      cohortFilters: [] as CohortEntityFilter[],
      entityFilters: cEntityFilters,
      eventFilters: eventFiltersRec
    };
  }, [entityFilters, eventFilters, payload.sliceSpec]);

  const result = useEntityDataQuery({
    ...filters,
    ...rest,
    ...payload,
    entityId: null,
    id: `events-table-data-query-${uniqId}`,
    mode: ExploreQueryType.adhoc,
    version: 0,
    widgetId: null,
    entityType: entityTypeId,
    skipEnabledDataDefCheck: true,
    compareQuery: null,
    compareSelection: null,
    excludeWidgetData: true,
    includeQueryConfig: true
  });

  return {
    ...result,
    payload
  };
};

type PropsV2 = {
  uniqId: string;
  opConfigId?: string;
  incidentId?: string;
  selectedUSFields: UserServiceField[];
  entityFilters: ExploreEntityFilter[];
  eventFilters: UserServiceFilterList;
  extendedFilters: UserServiceFilterExpression[];
  timeRange: TimeRange;
  impactedWidget?: ImpactedWidget;
};

export const useFetchEventsTableDataV2 = (props: PropsV2) => {
  const {
    entityFilters,
    selectedUSFields,
    eventFilters,
    uniqId,
    extendedFilters,
    incidentId,
    opConfigId,
    impactedWidget,
    ...rest
  } = props;

  const payload = useMemo(() => {
    const widgetConfig: WidgetConfigDTO = {
      dataDefinition: {
        fields: {
          [uniqId]: {
            id: uniqId,
            sourceType: "eventField",
            eventFieldQueryConfig: {
              filterExpressions: extendedFilters || [],
              userServiceFields: selectedUSFields
            }
          }
        },
        metrics: {}
      },
      isStatic: false,
      name: "EventsTableDataFetch",
      visualizations: []
    };

    const sliceSpec: SliceSpec[] = [
      {
        selectorSpec: {
          filters: []
        },
        sliceSet: {
          slices: []
        },
        fieldId: uniqId
      }
    ];

    return {
      widgetConfig,
      sliceSpec
    };
  }, [extendedFilters, selectedUSFields, uniqId]);

  const [result, setResult] = useState<{
    error: any;
    data: EntityWidgetData;
    isFetching: boolean;
    isError: boolean;
  }>({
    error: null,
    data: null,
    isFetching: false,
    isError: false
  });

  const payloadV2: ImpactWidgetViewRequest = useMemo(() => {
    const sortSpec: SortSpec = {
      sortBy: "current",
      limitSpec: {
        function: "top",
        limit: 30
      }
    };
    return {
      entityFilters,
      eventFilters,
      sortSpec,
      incidentId,
      opConfigId,
      impactedWidget,
      userServiceFields: selectedUSFields
    };
  }, [entityFilters, eventFilters, impactedWidget, incidentId, opConfigId, selectedUSFields]);

  const { from, to } = rest.timeRange;

  const startMillis = from.valueOf();
  const endMillis = to.valueOf();

  const refetch = useCallback(async () => {
    setResult(prev => ({
      ...prev,
      isFetching: true
    }));
    const result = await alertApiService.getImpactedWidgetDrawerData(payloadV2, startMillis, endMillis);
    setResult(prev => ({
      ...prev,
      isFetching: false,
      data: result.data,
      error: result.error,
      isError: result.error
    }));
  }, [payloadV2, startMillis, endMillis]);

  return {
    ...result,
    refetch,
    payload
  };
};
