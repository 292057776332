import React, { FC, useEffect, useMemo, useCallback, useRef } from "react";
import { IncSelectOption, IncSelect, IncButton, IncFaIcon, IncInModalConfirmation } from "@inception/ui";
import { isEqual } from "lodash";
import {
  useFetchExploreBizEntityTypes,
  FieldPickerOptionData,
  FieldPickerContextDTO,
  UserServiceFieldSlice,
  UserServiceField,
  getDisplayTagNameForUSFieldSlice
} from "../../../services/api/explore";
import { MetricTableConfig, MetricTableColumnInfo } from "../types";
import { FieldPickerContainer } from "../../field-picker";
import { useToggleState, useTimeRange, logger } from "../../../core";
import { FieldPickerUtils, ENTITY_TAG } from "../../../utils";
import { useFieldPicker } from "../../../field-picker";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { MetricColumnEditor } from "./MetricColumnEditor";
import {
  getNewMetricColumn,
  getUpdatedMetricColumns,
  getUsFieldSlicesFromTableSlices,
  getCloneMetricColumn
} from "./utils";

interface Props {
  metricTableConfig: MetricTableConfig;
  onChange: (tableConfig: Partial<MetricTableConfig>) => void;
}

export const MetricsTab: FC<Props> = props => {
  const { metricTableConfig, onChange } = props;

  const { bizEntityTypes, error, fetchBizEntityList, isError, isFetching } = useFetchExploreBizEntityTypes();

  useEffect(() => {
    fetchBizEntityList();
  }, [fetchBizEntityList]);

  const { entityTypeId, metricColumns, slices } = metricTableConfig;

  const tableConfigRef = useRef<MetricTableConfig>(metricTableConfig);
  useMemo(() => {
    tableConfigRef.current = metricTableConfig;
  }, [metricTableConfig]);

  const { timeRange } = useTimeRange();

  const fieldPickerContextDto = useMemo<FieldPickerContextDTO>(
    () => ({
      entityId: null,
      entityType: entityTypeId,
      showFields: true,
      showMetrics: false,
      entityName: entityTypeId
    }),
    [entityTypeId]
  );

  const {
    isFetching: isFieldsFetching,
    data: fieldPickerModel,
    isError: isFieldsError,
    error: fieldsError,
    getFields
  } = useFieldPicker();

  useEffect(() => {
    if (entityTypeId) {
      const { from, to } = timeRangeUtils.getTimeRangeMillisFromRaw(timeRange.raw);
      getFields(fieldPickerContextDto, from, to);
    }
  }, [entityTypeId, fieldPickerContextDto, getFields, timeRange.raw]);

  useEffect(() => {
    if (isFieldsError) {
      logger.error("MetricTableEditor", "MetricsTab: Error fetching fields", fieldsError);
    }
  }, [fieldsError, isFieldsError]);

  const implicitSlice = useMemo<UserServiceFieldSlice>(() => {
    const entityUsField = fieldPickerModel?.getEntityUSField();
    if (entityUsField) {
      return {
        tagName: ENTITY_TAG,
        userServiceField: entityUsField
      };
    }

    return null;
  }, [fieldPickerModel]);

  useEffect(() => {
    if (implicitSlice) {
      const { tagName } = implicitSlice;
      const slice = slices.find(({ slice }) => slice.tagName === tagName);

      if (!slice) {
        const { displayTagName } = getDisplayTagNameForUSFieldSlice(implicitSlice);

        const nSlices = [
          {
            name: displayTagName,
            slice: implicitSlice
          },
          ...slices
        ];

        onChange({
          slices: nSlices
        });
      }
    }
  }, [implicitSlice, onChange, slices]);

  const { open, close, isOpen } = useToggleState();

  const entityTypeIdRef = useRef<string>();

  const entityTypeOpts = useMemo<IncSelectOption[]>(
    () =>
      bizEntityTypes.map(bizEntityType => ({
        label: bizEntityType.name,
        value: bizEntityType.entityTypeId
      })),
    [bizEntityTypes]
  );

  const selEntityTypeOpt = useMemo(
    () => entityTypeOpts.find(opt => opt.value === entityTypeId),
    [entityTypeOpts, entityTypeId]
  );

  const onConfirmEntityTypeChange = useCallback(() => {
    if (entityTypeIdRef.current) {
      onChange({
        entityTypeId: entityTypeIdRef.current,
        metricColumns: []
      });
      close();
    }
  }, [close, onChange]);

  const onEntityTypeChange = useCallback(
    (opt: IncSelectOption) => {
      entityTypeIdRef.current = opt.value;
      if (metricColumns?.length) {
        open();
      } else {
        onConfirmEntityTypeChange();
      }
    },
    [metricColumns, onConfirmEntityTypeChange, open]
  );

  const onCancelEntityTypeChange = useCallback(() => {
    close();
    entityTypeIdRef.current = null;
  }, [close]);

  const onFieldsSelectionChange = useCallback(
    (optionData: FieldPickerOptionData[]) => {
      const { slices: existingSlices, metricColumns } = tableConfigRef.current;

      const fieldNameToNameMap = existingSlices.reduce(
        (acc, slice) => {
          const { name, slice: usfSlice } = slice;

          const fieldName = FieldPickerUtils.generateNamefromUSF(usfSlice.userServiceField);
          acc[fieldName] = name;

          return acc;
        },
        {} as Record<string, string>
      );

      const usFields = optionData
        .filter(opt => opt.type === "userServiceField")
        .map(opt => opt.payload as any as UserServiceField);

      const slices = usFields.map((usField): UserServiceFieldSlice => {
        const tagName = FieldPickerUtils.getPromSanitizedUSFName(usField);
        return {
          tagName,
          userServiceField: usField
        };
      });

      const entityUsField = fieldPickerModel.getEntityUSField();
      slices.forEach(slice => {
        const { userServiceField } = slice;
        if (isEqual(userServiceField, entityUsField)) {
          slice.tagName = ENTITY_TAG;
        }
      });

      const nSlices = slices.map(slice => {
        const { displayTagName } = getDisplayTagNameForUSFieldSlice(slice);
        const { fieldName } = slice.userServiceField;

        return {
          name: fieldNameToNameMap[fieldName] || displayTagName,
          slice
        };
      });

      const nMetricColumns = getUpdatedMetricColumns(metricColumns, nSlices);

      onChange({
        slices: nSlices,
        metricColumns: nMetricColumns
      });
    },
    [fieldPickerModel, onChange]
  );

  const addMetricColumn = useCallback(() => {
    const { slices } = tableConfigRef.current;

    onChange({
      metricColumns: [...metricColumns, getNewMetricColumn(entityTypeId, slices)]
    });
  }, [entityTypeId, metricColumns, onChange]);

  const onRemoveMetricColumn = useCallback(
    (idx: number) => {
      onChange({
        metricColumns: metricColumns.filter((_, i) => i !== idx)
      });
    },
    [metricColumns, onChange]
  );

  const onCloneMetricColumn = useCallback(
    (idx: number) => {
      const { metricColumns } = tableConfigRef.current;

      const metricColumn = metricColumns[idx];
      const clMetricColumn = getCloneMetricColumn(metricColumn);

      const nMetricColumns = [...metricColumns];
      nMetricColumns.splice(idx + 1, 0, clMetricColumn);

      onChange({
        metricColumns: nMetricColumns
      });
    },
    [onChange]
  );

  const onChangeMetricColumn = useCallback(
    (metricColumn: MetricTableColumnInfo, idx: number) => {
      const { metricColumns } = tableConfigRef.current;

      const nMetricColumns = [...metricColumns];
      nMetricColumns[idx] = metricColumn;

      onChange({
        metricColumns: nMetricColumns
      });
    },
    [onChange]
  );

  const selectedSliceOptions = useMemo<FieldPickerOptionData[]>(() => {
    if (!slices) {
      return [];
    }

    return slices.map(({ slice }) => ({
      payload: slice.userServiceField,
      type: "userServiceField",
      readOnly: slice.tagName === ENTITY_TAG
    }));
  }, [slices]);

  const usfSliceSet = useMemo(() => getUsFieldSlicesFromTableSlices(slices).usFieldSliceSet, [slices]);

  const fieldsInitialised = !isFieldsFetching && !isFieldsError;

  return (
    <>
      <IncSelect
        errorText={error}
        hasError={isError}
        isDisabled={isError}
        isLoading={isFetching}
        label="Entity type"
        onChange={onEntityTypeChange}
        options={entityTypeOpts}
        value={selEntityTypeOpt}
        wrapperClass="marginBt12"
      />

      {Boolean(entityTypeId) && (
        <>
          <FieldPickerContainer
            className="marginBt12"
            disabled={!fieldsInitialised}
            fieldPickerContextDto={fieldPickerContextDto}
            fieldPickerModel={fieldPickerModel}
            fieldTypes={["userServiceField"]}
            isMulti
            label="Slices"
            onChange={onFieldsSelectionChange}
            selectedOptions={selectedSliceOptions}
          />

          <IncButton
            className="marginBt12 marginLtAuto"
            color="secondary-blue"
            disabled={!fieldsInitialised}
            iconType="iconText"
            onClick={addMetricColumn}
          >
            <IncFaIcon iconName="plus" />
            Add Column
          </IncButton>

          {metricColumns.map((column, idx) => {
            const onRemove = () => onRemoveMetricColumn(idx);
            const onClone = () => onCloneMetricColumn(idx);
            const onChange = (metricColumn: MetricTableColumnInfo) => onChangeMetricColumn(metricColumn, idx);

            const key = [column.id, idx].join("_");

            return (
              <MetricColumnEditor
                defaultSliceSet={usfSliceSet}
                key={key}
                metricColumn={column}
                onChange={onChange}
                onClone={onClone}
                onRemove={onRemove}
              />
            );
          })}
        </>
      )}

      {isOpen && (
        <IncInModalConfirmation
          message="This will remove all the metrics associated with this entity type. Are you sure?"
          onCancel={onCancelEntityTypeChange}
          onConfirm={onConfirmEntityTypeChange}
        />
      )}
    </>
  );
};
