import { IncTimeSeriesOptions } from "../time-series/types";

export const getSparklineSeriesData = (
  data: number[][],
  seriesName: string,
  color = "#CF5EDA",
  isAreaSpline = true
) => {
  const seriesProperties: IncTimeSeriesOptions = {
    lineWidth: 2,
    zIndex: 0,
    yAxis: 0,
    showInLegend: true,
    visible: true,
    connectNulls: false,
    type: "line",
    marker: {
      enabled: true,
      radius: 2,
      symbol: "circle"
    },
    id: seriesName,
    data: [...data],
    refId: seriesName,
    name: seriesName,
    color
  };

  if (isAreaSpline) {
    (seriesProperties as any).type = "area";
    (seriesProperties as any).chartType = "areaspline";
  }

  return seriesProperties as IncTimeSeriesOptions;
};

export const getSparklineConfidenceBands = (data: number[][], color = "#9CCCFC") => {
  const seriesProperties = {
    lineWidth: 0,
    zIndex: 0,
    yAxis: 0,
    showInLegend: true,
    alias: ".*lower.*",
    alias2: ".*upper.*",
    name: "Confidence Band",
    chartType: "arearange",
    type: "arearange",
    linkedTo: ":previous",
    color,
    fillOpacity: 0.3,
    marker: {
      enabled: false,
      radius: 1,
      symbol: "circle"
    },
    states: {
      hover: {
        marker: {
          enabled: true,
          radius: 1
        }
      }
    },
    refId: "refId",
    id: "upper",
    data: [...data]
  };

  return seriesProperties as IncTimeSeriesOptions;
};
