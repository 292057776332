import { getInceptionTheme } from "@inception/ui";
import { transparentize } from "polished";
import { SeriesOverride } from "../../dashboard/widgets/TimeSeries/models/model";

const seasonalityRegex = ".*\\d+(d|w|m|h|M)";

export const INCIDENT_COLORS = {
  band: getInceptionTheme().inceptionColors.palette.N50, //"#F6F7F8"
  confidenceband: getInceptionTheme().inceptionColors.palette.B200, //"#C7E3FE"
  overriddenband: "#A186F0",
  anomaly: "#F8485E",
  opportunity: "#3BB443",
  original: getInceptionTheme().inceptionColors.palette.B350, // "#60BFFF"
  scored: getInceptionTheme().inceptionColors.palette.B350, // "#60BFFF"
  incidents: "rgba(233, 104, 89, 0.1)",
  opportunityHighlight: transparentize(0.85, "#3BB443"),
  suppression: getInceptionTheme().inceptionColors.palette.Y300,
  seasonality: getInceptionTheme().inceptionColors.palette.O400,
  suppressionRegion: "#fae7ae66",
  trendPositive: transparentize(0.35, "#3BB443"),
  trendNegative: transparentize(0.35, "#F8485E"),
  trendNeutral: getInceptionTheme().inceptionColors.palette.B200 //"#C7E3FE"
};

export function getCompareSeriesOverrides(asSparkline?: boolean, isOpportunity?: boolean) {
  return getSeriesOverrides(".*anomaly.*", null, asSparkline, isOpportunity);
}

function getSeriesOverrides(
  anomaliesSeriesAlias: string,
  defaultColor?: string,
  asSparkline?: boolean,
  isOpportunity?: boolean
): SeriesOverride[] {
  const anomalyPointRadius = asSparkline ? 1 : 2;
  const pointRadius = asSparkline ? 0 : 2;
  const anomalyColor = isOpportunity ? INCIDENT_COLORS.opportunity : INCIDENT_COLORS.anomaly;
  return [
    {
      alias: anomaliesSeriesAlias,
      showInLegend: true,
      visible: true,
      zIndex: 2,
      color: anomalyColor,
      lineWidth: 1,
      chartType: "line",
      connectNulls: false,
      marker: {
        fillColor: anomalyColor,
        lineWidth: 1,
        lineColor: anomalyColor,
        radius: anomalyPointRadius,
        enabled: true,
        symbol: "circle"
      }
    },
    {
      alias: ".*lower.*",
      alias2: ".*upper.*",
      name: "Confidence Band",
      chartType: "arearange",
      lineWidth: 0,
      type: "timeseries",
      showInLegend: true,
      connectNulls: true,
      visible: true,
      linkedTo: ":previous",
      color: INCIDENT_COLORS.confidenceband,
      fillOpacity: 0.3,
      zIndex: 0,
      marker: {
        enabled: false,
        radius: pointRadius,
        symbol: "circle"
      },
      states: {
        hover: {
          marker: {
            enabled: false,
            radius: 2
          }
        }
      }
    },
    {
      alias: ".*trend_positive.*",
      name: "Trend",
      chartType: "spline",
      lineWidth: 0,
      dashStyle: "Dash",
      type: "timeseries",
      showInLegend: true,
      connectNulls: true,
      visible: true,
      color: INCIDENT_COLORS.trendPositive,
      fillOpacity: 0.3,
      zIndex: 0,
      marker: {
        enabled: false,
        radius: pointRadius,
        symbol: "circle"
      },
      states: {
        hover: {
          marker: {
            enabled: false,
            radius: 2
          }
        }
      }
    },
    {
      alias: ".*trend_negative.*",
      name: "Trend line",
      chartType: "spline",
      lineWidth: 0,
      dashStyle: "Dash",
      type: "timeseries",
      showInLegend: true,
      connectNulls: true,
      visible: true,
      color: INCIDENT_COLORS.trendNegative,
      fillOpacity: 0.3,
      zIndex: 0,
      marker: {
        enabled: false,
        radius: pointRadius,
        symbol: "circle"
      },
      states: {
        hover: {
          marker: {
            enabled: false,
            radius: 2
          }
        }
      }
    },
    {
      alias: ".*trend_neutral.*",
      name: "Trend line",
      chartType: "spline",
      lineWidth: 0,
      dashStyle: "Dash",
      type: "timeseries",
      showInLegend: true,
      connectNulls: true,
      visible: true,
      color: INCIDENT_COLORS.trendNeutral,
      fillOpacity: 0.3,
      zIndex: 0,
      marker: {
        enabled: false,
        radius: pointRadius,
        symbol: "circle"
      },
      states: {
        hover: {
          marker: {
            enabled: false,
            radius: 2
          }
        }
      }
    },
    {
      alias: ".*overridden_lower.*",
      alias2: ".*overridden_upper.*",
      name: "Overriden Confidence Band",
      chartType: "arearange",
      lineWidth: 0,
      type: "timeseries",
      showInLegend: true,
      connectNulls: true,
      visible: true,
      linkedTo: ":previous",
      color: INCIDENT_COLORS.overriddenband,
      fillOpacity: 0.5,
      zIndex: 0,
      marker: {
        enabled: false,
        radius: pointRadius,
        symbol: "circle"
      },
      states: {
        hover: {
          marker: {
            enabled: false,
            radius: 2
          }
        }
      }
    },
    {
      alias: ".*anom-score.*",
      visible: false,
      showInLegend: false,
      chartType: "line",
      connectNulls: false,
      yAxis: 1,
      zIndex: 1,
      color: "#f9e2d2",
      lineWidth: 0,
      marker: {
        fillColor: "#f9e2d2",
        lineWidth: 0,
        lineColor: "#f9e2d2",
        radius: pointRadius,
        symbol: "circle",
        enabled: true
      }
    },
    {
      alias: ".*predicted.*",
      visible: false,
      showInLegend: false,
      chartType: "line",
      yAxis: 0,
      zIndex: 1,
      color: "#DEDEDE",
      lineWidth: 0,
      connectNulls: true,
      marker: {
        fillColor: "#DEDEDE",
        lineWidth: 0,
        lineColor: "#DEDEDE",
        radius: pointRadius,
        symbol: "circle",
        enabled: false
      }
    },
    {
      alias: ".*original.*",
      visible: false,
      showInLegend: false,
      chartType: "line",
      yAxis: 0,
      zIndex: 1,
      color: defaultColor ?? INCIDENT_COLORS.original,
      lineWidth: 1,
      connectNulls: true,
      marker: {
        fillColor: defaultColor ?? INCIDENT_COLORS.original,
        lineWidth: 0,
        lineColor: defaultColor ?? INCIDENT_COLORS.original,
        radius: pointRadius,
        symbol: "circle",
        enabled: true
      }
    },
    {
      alias: ".*suppression.*",
      visible: true,
      showInLegend: true,
      chartType: "line",
      yAxis: 0,
      zIndex: 1,
      color: INCIDENT_COLORS.suppression,
      lineWidth: 1,
      connectNulls: false,
      marker: {
        fillColor: INCIDENT_COLORS.suppression,
        lineWidth: 1,
        lineColor: INCIDENT_COLORS.suppression,
        radius: pointRadius,
        symbol: "circle",
        enabled: true
      }
    },
    {
      alias: seasonalityRegex,
      visible: true,
      showInLegend: true,
      chartType: "line",
      yAxis: 0,
      zIndex: 1,
      color: defaultColor ?? INCIDENT_COLORS.seasonality,
      lineWidth: 1,
      connectNulls: true,
      marker: {
        fillColor: defaultColor ?? INCIDENT_COLORS.seasonality,
        lineWidth: 1,
        lineColor: defaultColor ?? INCIDENT_COLORS.seasonality,
        radius: pointRadius,
        symbol: "circle",
        enabled: true
      }
    },
    {
      alias: seasonalityRegex,
      visible: true,
      showInLegend: true,
      chartType: "line",
      yAxis: 0,
      zIndex: 1,
      color: defaultColor ?? INCIDENT_COLORS.seasonality,
      lineWidth: 1,
      connectNulls: true,
      marker: {
        fillColor: defaultColor ?? INCIDENT_COLORS.seasonality,
        lineWidth: 1,
        lineColor: defaultColor ?? INCIDENT_COLORS.seasonality,
        radius: pointRadius,
        symbol: "circle",
        enabled: true
      }
    },
    {
      alias: seasonalityRegex,
      visible: true,
      showInLegend: true,
      chartType: "line",
      yAxis: 0,
      zIndex: 1,
      color: defaultColor ?? INCIDENT_COLORS.seasonality,
      lineWidth: 1,
      connectNulls: true,
      marker: {
        fillColor: defaultColor ?? INCIDENT_COLORS.seasonality,
        lineWidth: 1,
        lineColor: defaultColor ?? INCIDENT_COLORS.seasonality,
        radius: pointRadius,
        symbol: "circle",
        enabled: true
      }
    },
    {
      alias: ".*",
      visible: true,
      showInLegend: true,
      chartType: "line",
      yAxis: 0,
      zIndex: 1,
      color: defaultColor ?? INCIDENT_COLORS.original,
      lineWidth: 1,
      connectNulls: true,
      marker: {
        fillColor: defaultColor ?? INCIDENT_COLORS.original,
        lineWidth: 1,
        lineColor: defaultColor ?? INCIDENT_COLORS.original,
        radius: pointRadius,
        symbol: "circle",
        enabled: true
      }
    }
  ];
}
