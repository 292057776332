import { ReactNode } from "react";
import { dataTypeManager } from "../../utils";
import { DataType, FieldDataType, FieldSubType, displayDataTypes } from "../../core";
import { PrimKindMap } from "./constants";
import { KindDescriptor, SupportedDataType } from "./types";
import { SupportedDataTypes } from "./data-types";

export const getPrimKindMapKeyByValue = (value: any) =>
  Object.keys(PrimKindMap).find(key => PrimKindMap[key].kind === value);

export const getDataTypeNameAndIcon = (
  kindType: string,
  kindDescriptor: KindDescriptor,
  iconStyles?: { width: number; height: number }
): {
  name: string;
  iconElem: ReactNode;
} => {
  const kindDescriptorType = kindDescriptor ? kindDescriptor.type : "";
  const shouldApplyIconStyles =
    !skipIconStylesTypes.includes(kindDescriptorType as FieldDataType) &&
    !skipIconStylesTypes.includes(kindType as FieldDataType);
  iconStyles = shouldApplyIconStyles ? iconStyles : undefined;

  const { displayName: name, getIcon } = dataTypeManager.getDataTypeDescriptorByKind(
    kindType,
    kindDescriptorType as any
  );

  const iconElem = getIcon(iconStyles);

  return {
    name,
    iconElem
  };
};

const skipIconStylesTypes: FieldDataType[] = [
  "_list_double",
  "_list_str",
  "_list_entity",
  "_list_long",
  "tel",
  "address",
  "_map"
];

export type DataTypeOption = {
  label: keyof typeof PrimKindMap | string;
  value: string;
  kindDescriptor: KindDescriptor;
  supportedDataType?: SupportedDataType;
};

//Ideally this should be the list consumed by data type selector while  displaying options in entity config screen
export const getEntityDataTypeOptions = (): DataTypeOption[] =>
  Object.entries(PrimKindMap).map(values => ({
    label: values[0] as keyof typeof PrimKindMap,
    value: values[1].kind,
    kindDescriptor: values[1].kindDescriptor
  }));

export enum MappingType {
  "entity" = "entity",
  "event" = "event"
}

export const getSupportedDateTypes = (mappingType: MappingType): SupportedDataType[] => {
  if (mappingType === MappingType.entity) {
    return [
      {
        label: "DATETIME",
        kind: "_datetime",
        primTypes: [],
        defaultPrimType: "",
        description: "Ex: datetime fields",
        isPrimitive: true
      },
      {
        label: "DATE",
        kind: "_date",
        primTypes: [],
        defaultPrimType: "",
        description: "Ex: date fields expressed in ISO-8601 format",
        isPrimitive: true
      }
    ];
  } else {
    return [
      {
        label: "DATETIME",
        kind: "_datetime",
        primTypes: ["_str"],
        defaultPrimType: "_str",
        description: "Ex: datetime fields",
        isPrimitive: false
      },
      {
        label: "DATE",
        kind: "_date",
        primTypes: ["_str"],
        defaultPrimType: "_str",
        description: "Ex: date fields expressed in ISO-8601 format",
        isPrimitive: false
      }
    ];
  }
};

export const getAllDataTypeOptions = (mappingType?: MappingType, ignorePrimTypes?: boolean): DataTypeOption[] => {
  // if (mappingType && (mappingType === MappingType.entity)) {
  //   return getEntityDataTypeOptions();
  // }

  const supportedPrimTypes: SupportedDataType[] = [];
  const supportedSubTypes: SupportedDataType[] = [];
  let allDataTypes: SupportedDataType[] = SupportedDataTypes;

  if (mappingType && mappingType === MappingType.entity) {
    allDataTypes = SupportedDataTypes.concat(getSupportedDateTypes(MappingType.entity));
  } else {
    allDataTypes = SupportedDataTypes.concat(getSupportedDateTypes(MappingType.event));
  }

  allDataTypes.forEach(option => {
    if (option.isPrimitive) {
      supportedPrimTypes.push(option);
    } else {
      supportedSubTypes.push(option);
    }
  });

  const extractedSubTypes: SupportedDataType[] = [];

  supportedSubTypes.forEach(option => {
    if (option.primTypes.length > 1 && !ignorePrimTypes) {
      option.primTypes.forEach(primType => {
        extractedSubTypes.push({
          ...option,
          defaultPrimType: primType
        });
      });
    } else {
      extractedSubTypes.push(option);
    }
  });

  const supportedDataTypes: SupportedDataType[] = [...supportedPrimTypes, ...extractedSubTypes];

  return supportedDataTypes.map(option => ({
    label:
      option.primTypes.length > 1 && !ignorePrimTypes
        ? `${option.label}[${option.defaultPrimType}]`
        : (option.label as string),
    value: option.defaultPrimType || option.kind,
    kindDescriptor: option.isPrimitive
      ? null
      : {
          type: option.kind as FieldSubType,
          customTypeName: option.label
        },
    supportedDataType: option
  }));
};

export function getDisplayDataTypes(): DataType[] {
  return displayDataTypes;
}
