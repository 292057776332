import React, { FC, useMemo, useCallback, memo, useRef } from "react";
import { ImpactedWidget, UserServiceFieldSliceSet } from "../../../services/api/explore";
import { MetricTableColumnInfo, MetricTableColumnQueryConfig } from "../types";
import { ImpactWidgetEditorRow } from "../../triage/triage-config-editors/ImpactWidgetEditorRow";
import { useTimeRange } from "../../../core";
import { QueryConfigEditor } from "./QueryConfigEditor";

type EProps = {
  metricColumn: MetricTableColumnInfo;
  onChange: (metricColumn: MetricTableColumnInfo) => void;
  onRemove: () => void;
  onClone: () => void;

  defaultSliceSet: UserServiceFieldSliceSet;
};

export const MetricColumnEditor: FC<EProps> = memo(props => {
  const { metricColumn, onChange, onRemove, onClone, defaultSliceSet } = props;

  const { timeRange } = useTimeRange();

  const columnRef = useRef(metricColumn);
  useMemo(() => {
    columnRef.current = metricColumn;
  }, [metricColumn]);

  const columnInfo = columnRef.current;

  const { queryConfig } = columnInfo;

  const onColumnInfoChange = useCallback(
    (impactedWidget: ImpactedWidget) => {
      const { id, name, bizDataQuery, dataType, subType } = impactedWidget;

      onChange({
        ...columnInfo,
        id,
        name,
        queryConfig: {
          ...columnInfo.queryConfig,
          metricQueryConfig: {
            ...columnInfo.queryConfig.metricQueryConfig,
            bizDataQuery
          },
          dataType,
          subType
        }
      });
    },
    [columnInfo, onChange]
  );

  const onTimePropertiesChanged = useCallback(
    (partQueryConfig: Partial<MetricTableColumnQueryConfig>) => {
      onChange({
        ...columnInfo,
        queryConfig: {
          ...columnInfo.queryConfig,
          ...partQueryConfig
        }
      });
    },
    [columnInfo, onChange]
  );

  const extConfigureJsx = (
    <QueryConfigEditor
      curTimeRange={timeRange}
      onChange={onTimePropertiesChanged}
      queryConfig={queryConfig}
    />
  );

  const impactedWidgetRef = useRef(getImpactedWidget(columnInfo));
  useMemo(() => {
    impactedWidgetRef.current = getImpactedWidget(columnInfo);
  }, [columnInfo]);

  const impactedWidget = impactedWidgetRef.current;

  return (
    <ImpactWidgetEditorRow
      defaultSliceSet={defaultSliceSet}
      disableEntityTypeChange
      disablePrimaryToggle
      disableSliceSelection
      extConfigureJsx={extConfigureJsx}
      impactedWidget={impactedWidget}
      onChange={onColumnInfoChange}
      onClone={onClone}
      onRemove={onRemove}
    />
  );
});

const getImpactedWidget = (columnInfo: MetricTableColumnInfo): ImpactedWidget => {
  const { id, name, queryConfig } = columnInfo;

  const { metricQueryConfig, dataType = "_str", subType = "none" } = queryConfig;

  return {
    id,
    dataType,
    isPrimary: false,
    name,
    subType,
    bizDataQuery: metricQueryConfig?.bizDataQuery,
    underlyingFilters: {
      userServiceFilters: []
    },
    underlyingUserServices: []
  };
};
