import React, { createContext, useReducer, useContext } from "react";
import { OpCreationConfig } from "../../services/api/operationalise";
import { getDefaultOpConfig, getOpMetaDataFromOp } from "../utils";
import { generateId } from "../../core";
import operationaliseReducer from "./reducer";
import { OpState, OpStateAction, OpContext } from "./types";

interface OperationaliseContextProps {
  state: OpState;
  dispatch: React.Dispatch<OpStateAction>;
}

interface Props {
  opCreationConfig?: OpCreationConfig;
  opContext?: OpContext;

  readOnly?: boolean;
  onDefineContext?: (state: OpState, dispatch: React.Dispatch<OpStateAction>) => void;

  children?: React.ReactNode;
}

const OperationaliseContext = createContext<OperationaliseContextProps>(null);

export function OpProvider(props: Props) {
  const { children, ...initState } = props;

  const [state, dispatch] = useReducer(operationaliseReducer, initOperationaliseState(initState));

  return (
    <OperationaliseContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      {children}
    </OperationaliseContext.Provider>
  );
}

export const useOpStore = () => useContext(OperationaliseContext);

const initOperationaliseState = (initState: Partial<OpState>) => {
  const opState: OpState = {
    context: initState?.context || {
      widgetId: "",
      entityTypeId: "",
      eventTypeId: "",
      bizEntityFieldName: "",
      cohortDefinition: null,
      cohortState: null,
      entityTypeInfo: null,
      eventTypeInfo: null,
      cohortName: null,
      entityTypeName: null,
      eventTypeName: null,
      implicitSlice: null,
      uiIntegrationActionConfigsMap: {},
      mode: "create",
      sessionId: generateId()
    },
    opCreationConfig:
      initState?.opCreationConfig ||
      getDefaultOpConfig(initState?.context?.entityTypeId, initState?.context?.eventTypeId),
    temporaryActions: {},
    simulationConfigs: initState?.simulationConfigs || [],
    history: {},
    selectedSimulation: null,
    op10zeId: null,
    readOnly: initState.readOnly || false,
    isDraft: initState.readOnly || false,
    primaryOpCreationConfig: null,
    saveOpCreationConfig: null,
    simulationStateMap: {},
    actionCategories: [],
    sourceTypeInfoByCategory: {},
    metaData: null
  };

  opState.metaData = initState?.metaData || getOpMetaDataFromOp(opState.opCreationConfig);

  return opState;
};
