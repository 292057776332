import React, { FC, useEffect, useState, useCallback, useRef } from "react";
import Highcharts, { Chart, Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Drilldown from "highcharts/modules/drilldown";
import { css, cx } from "emotion";
import { HighChartsDrilldownSeries } from "../charts";
import { PieSeries, PieChartOptions } from "./types";
import { PieChartOptionsBuilder } from "./OptionsBuilder";

Drilldown(Highcharts);

interface PieChartProps {
  containerElemClass: string;
  series: PieSeries[];
  disableInteractions?: boolean;
  options?: PieChartOptions;
  drillDownSeries?: Array<HighChartsDrilldownSeries<PieSeries>>;
  title?: string;
}

export const PieChart: FC<PieChartProps> = props => {
  const { series, options: pOptions, containerElemClass, disableInteractions, drillDownSeries, title } = props;

  const [, setChartRendered] = useState(false);
  const [chartOptions, setChartOptions] = useState<Options>(null);
  const [chartInstance, setChartInstance] = useState<Chart>(null);
  const chartContainerRef = useRef<HTMLDivElement>();
  const containerCxRef = useRef("chart-container");

  const getChartDimensions = useCallback(() => {
    const canvasElem: HTMLElement = chartContainerRef.current;
    if (canvasElem) {
      const widgetItemContainer: Element = canvasElem.closest(`.${containerElemClass}`);
      const dimensions = widgetItemContainer?.getBoundingClientRect();
      if (dimensions) {
        const { height } = dimensions;
        const { width } = dimensions;
        return {
          width,
          height
        };
      }
    }
    return {
      width: -1,
      height: -1
    };
  }, [chartContainerRef, containerElemClass]);

  useEffect(() => {
    if (chartContainerRef.current) {
      setChartRendered(true);
    }
  }, [getChartDimensions, chartContainerRef]);

  // Calculate dimensions on every render
  const { width, height } = getChartDimensions();

  useEffect(() => {
    if (height <= 0 || !width) {
      return;
    }

    const { hcOptions, showLegend, customMode, stats, tooltipValueFormatter, ...seriesOptions } = pOptions || {};

    const optionsBuilder = new PieChartOptionsBuilder(hcOptions);

    optionsBuilder
      .setChartFields(width, height)
      .setPlotOptions(disableInteractions)
      .setTitle(title)
      .clearSeriesData()
      .setSeries(series, seriesOptions)
      .setDrillDownSeries(drillDownSeries)
      .setToolTip(tooltipValueFormatter)
      .setLegend(showLegend)
      .setMode(customMode)
      .setStats(stats);

    containerCxRef.current = getContainerClassName(height, customMode === "gauge");

    const nChartOptions = optionsBuilder.build();
    setChartOptions(nChartOptions);
  }, [disableInteractions, drillDownSeries, height, pOptions, series, title, width]);

  useEffect(() => {
    if (!chartInstance) {
      return;
    }
    chartInstance.redraw();
  }, [chartInstance]);

  const highchartsCallback = useCallback(chart => setChartInstance(chart), []);

  return (
    <div
      className={containerCxRef.current}
      ref={chartContainerRef}
    >
      {chartOptions && (
        <HighchartsReact
          callback={highchartsCallback}
          highcharts={Highcharts}
          options={chartOptions}
        />
      )}
    </div>
  );
};

const getContainerClassName = (height: number, isGauge: boolean) =>
  cx(
    "chart-container",
    css`
      .highcharts-title {
        margin-top: ${height * 0.15 * (isGauge ? -1 : 1)}px !important;
      }
    `
  );
