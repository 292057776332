import { last, uniqBy, clone, cloneDeep, uniq } from "lodash";
import {
  UserServiceFieldWithMeta,
  SliceSpec,
  UserServiceFieldMetricConfigDefinition,
  PostAgg,
  OverTagPostAgg,
  WidgetConfigUtils,
  UserServiceFilterExpression,
  PostAggProjection,
  LimitSpecFunction,
  OverTagAgg,
  OverTimeAgg,
  SliceSet,
  SelectorSpec,
  WidgetResponseDTO,
  SortSpecSortBy,
  WidgetQuerySchema,
  UserServiceFieldSlice,
  UserServiceField,
  OverTimePostAgg,
  OverTimeAggregators,
  OverTagAggregators,
  UserServiceFilterList,
  UserServiceFilterExpressionTree,
  LogicalOperator,
  compareUSFields
} from "../../../services/api/explore";
import {
  TimeRange,
  DataTypeVisualisationManager,
  VizToQueryConfig,
  QueryType,
  DataTypeAggregationManager,
  generateId,
  VisualisationConfig,
  Visualisations,
  FieldSubType,
  DataType,
  numericDataTypes,
  logger
} from "../../../core";
import { dataTypeManager, ENTITY_TAG, eventFieldUtils, FieldPickerUtils, pluralizeWord } from "../../../utils";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { ExploreQueryType } from "../../../services/datasources/explore/types";
import { ExploreQueryUtils } from "../../../utils/ExploreQueryUtils";
import { EntityAggregationMeta } from "../../../services/api/types";
import { ChangeMetric } from "../../../biz-entity";
import { getCatalogWidgetLabels } from "../../../utils/ExploreUtils";
import { DataTypeManagerUtils } from "../../../core/datatype-viz-agg-manager/ManagerUtils";
import kbn from "../../../services/datasources/core/kbn";
import { featureFlagService, FEATURE_FLAGS } from "../../../services/feature-flags";
import {
  CatalogQueryConfig,
  UseCaseQuerySourceConfig,
  USFieldQuerySourceConfig,
  WidgetQuerySourceConfig
} from "./models";
import { CatalogWidgetFetchDataPayload, VizOption } from "./types";

export class CatalogWidgetUtils {
  static durationFieldName = "duration";
  static hasErrorFieldName = "hasError";
  static eventIDFieldName = "eventID";

  static durationFieldDisplayName = "latency";
  static hasErrorFieldDisplayName = "errors";
  static eventIDFieldDisplayName = "requests";

  static getMetricName(fieldName: string, aggregatedFieldName: string): string {
    switch (fieldName) {
      case this.durationFieldName:
        return "Requests Latency";

      case this.hasErrorFieldName:
        return "Errors Count";

      case this.eventIDFieldName:
        return "Requests Count";

      default:
        return aggregatedFieldName;
    }
  }

  static getDisplayFieldName(userServiceField: UserServiceField, eventTypeName?: string) {
    const fieldName = eventFieldUtils.removeFieldsPrefix(userServiceField?.fieldName || "");

    switch (fieldName) {
      case CatalogWidgetUtils.durationFieldName:
        return CatalogWidgetUtils.durationFieldDisplayName;

      case CatalogWidgetUtils.eventIDFieldName:
        return eventTypeName ? `Total ${pluralizeWord(eventTypeName)}` : CatalogWidgetUtils.eventIDFieldDisplayName;

      case CatalogWidgetUtils.hasErrorFieldName:
        return eventTypeName ? `Failed ${pluralizeWord(eventTypeName)}` : CatalogWidgetUtils.hasErrorFieldDisplayName;

      default:
        return fieldName;
    }
  }

  static isEventIDField(fieldName: string): boolean {
    return fieldName === this.eventIDFieldName;
  }

  static getFieldName(queryConfig: CatalogQueryConfig): string {
    const { sourceQueryConfig } = queryConfig;

    if (sourceQueryConfig.queryType === "userServiceField") {
      const { usField } = sourceQueryConfig;
      return usField.userServiceField.fieldName;
    }

    if (sourceQueryConfig.queryType === "widgetConfig") {
      const { widgetResponse, metricId } = sourceQueryConfig;
      const { widgetConfig } = widgetResponse;
      const metricDef = widgetConfig?.dataDefinition?.metrics[metricId];
      return metricDef?.name || "";
    }
    // Add implementation for other source types

    return "";
  }

  static isPredefinedField(usfm: UserServiceFieldWithMeta) {
    const { userServiceField, userServiceMetadata } = usfm;
    const { fieldName } = userServiceField;
    const { diagnostic } = userServiceMetadata;
    return diagnostic && this.isPredefinedFieldName(fieldName);
  }

  static getVisualisations(queryConfig: CatalogQueryConfig) {
    const queryType = queryConfig?.sourceQueryConfig?.queryType;
    const isWidgetQuerySource = queryType === "widgetConfig";
    let preferTimeseries = false;

    let vizConfig: VisualisationConfig;

    if (isWidgetQuerySource) {
      preferTimeseries = true;
      vizConfig = DataTypeVisualisationManager.getAllVisualisations();
    } else {
      const fieldName = this.getFieldName(queryConfig);
      const ootbMetricName = DataTypeVisualisationManager.getOOTBMetricNameForFieldName(fieldName);

      if (ootbMetricName) {
        vizConfig = DataTypeVisualisationManager.getVisualisationsForOOTBMetrics(ootbMetricName);
        preferTimeseries = false;
      } else {
        const { cardinality, uiDataType } = this.getDataTypeAndAggInfo(queryConfig);

        preferTimeseries = this.isHighCardinalityNumericMetric(uiDataType, cardinality);
        vizConfig = DataTypeVisualisationManager.getVisualisationsByUIDataType(uiDataType, cardinality);
      }
    }

    return this.getVisualisationOptions(vizConfig, preferTimeseries);
  }

  static getAggregators(queryConfig: CatalogQueryConfig) {
    const fieldName = this.getFieldName(queryConfig);

    const ootbMetricName = DataTypeVisualisationManager.getOOTBMetricNameForFieldName(fieldName);

    if (ootbMetricName) {
      return DataTypeAggregationManager.getAggregationsForOOTBMetrics(ootbMetricName);
    } else {
      const { cardinality, uiDataType } = this.getDataTypeAndAggInfo(queryConfig);

      return DataTypeAggregationManager.getAggregationsByUIDataType(uiDataType, cardinality);
    }
  }

  static getQueryDataPayload(
    widgetResponseDTO: WidgetResponseDTO,
    queryConfig: CatalogQueryConfig,
    vizConfig: VizToQueryConfig,
    timeRange: TimeRange,
    compareTimeRange: TimeRange,
    widgetSelectorSpec: SelectorSpec,
    aggregatedTags?: string[],
    metricId?: string,
    metricType: ChangeMetric = "current",
    postAggProjections: PostAggProjection[] = ["current"],
    limitSpecFunction: LimitSpecFunction = null,
    limit = 30,
    isSingleStatQuery = false,
    downsample = "auto"
  ): CatalogWidgetFetchDataPayload {
    const { sourceQueryConfig, sliceSet, selectorSpec } = queryConfig;

    switch (sourceQueryConfig.queryType) {
      case "userServiceField": {
        const usfMetricDef = widgetResponseDTO?.widgetConfig?.dataDefinition?.metrics?.[
          metricId
        ] as UserServiceFieldMetricConfigDefinition;
        return this.getPayloadForUSFieldSource(
          widgetResponseDTO,
          usfMetricDef,
          queryConfig,
          vizConfig,
          timeRange,
          compareTimeRange,
          selectorSpec,
          aggregatedTags,
          metricType,
          postAggProjections,
          limitSpecFunction,
          limit,
          isSingleStatQuery,
          downsample
        );
      }

      case "widgetConfig": {
        return this.getPayloadForWidgetSource(
          widgetResponseDTO,
          queryConfig,
          metricId,
          vizConfig,
          selectorSpec,
          sliceSet,
          timeRange,
          compareTimeRange,
          widgetSelectorSpec,
          aggregatedTags,
          metricType,
          postAggProjections,
          limitSpecFunction,
          limit,
          isSingleStatQuery,
          downsample
        );
      }

      case "useCase": {
        return this.getPayloadForUseCaseSource(
          widgetResponseDTO,
          queryConfig,
          vizConfig,
          selectorSpec,
          sliceSet,
          timeRange,
          compareTimeRange,
          widgetSelectorSpec,
          aggregatedTags,
          metricType,
          postAggProjections,
          limitSpecFunction,
          limit,
          isSingleStatQuery,
          downsample
        );
      }

      default: {
        return {
          mode: ExploreQueryType.adhoc,
          sliceSpec: []
        };
      }
    }
  }

  static getWidgetResponseDTO(
    entityType: string,
    userServiceId: string,
    aggregator: string,
    queryConfig: CatalogQueryConfig,
    usfSlices: UserServiceFieldSlice[],
    metricId: string,
    defMetricName: string
  ): WidgetResponseDTO {
    const { sourceQueryConfig } = queryConfig;

    switch (sourceQueryConfig.queryType) {
      case "userServiceField": {
        return this.getWidgetResponseDTOForUSFieldSource(
          entityType,
          userServiceId,
          aggregator,
          queryConfig,
          metricId,
          usfSlices,
          defMetricName
        );
      }

      case "widgetConfig": {
        return this.getWidgetResponseDTOForWidgetSource(queryConfig);
      }

      case "useCase": {
        return this.getWidgetResponseDTOForUseCaseSource(queryConfig);
      }

      default: {
        return {
          querySchema: {
            querySchema: []
          },
          version: 1,
          widgetConfig: ExploreQueryUtils.getDefaultWidgetConfig(),
          widgetId: generateId()
        };
      }
    }
  }

  static getAggTagFromPayload(payload: CatalogWidgetFetchDataPayload): string[] {
    const { sliceSpec } = payload || {};
    const { postAgg } = sliceSpec?.[0] || {};
    const { overTagAgg } = (postAgg as OverTagPostAgg) || {};
    const { tagName } = overTagAgg || {};
    if (tagName) {
      return tagName;
    }
    return [ENTITY_TAG];
  }

  static isHasErrorField(fieldName: string) {
    return fieldName === this.hasErrorFieldName;
  }

  static getDataTypeAndAggInfo(queryConfig: CatalogQueryConfig) {
    let aggInfo: EntityAggregationMeta = {
      cardinality: {
        ratio: 0,
        value: "0"
      }
    };
    let uiDataType: DataType = "STRING";
    const dataTypeByMetricId: Record<string, DataType> = {};

    const { sourceQueryConfig } = queryConfig;

    switch (sourceQueryConfig.queryType) {
      case "userServiceField": {
        const { usField } = sourceQueryConfig;
        const {
          dataType: uDataType,
          subType: uSubType,
          aggInfo: uAggInfo
        } = this.getDataTypeAndAggInfoFromUSField(usField);

        uiDataType = dataTypeManager.getDataTypeWithNameFromKind(uDataType, uSubType)[0] as DataType;
        aggInfo = uAggInfo || aggInfo;

        break;
      }

      case "widgetConfig": {
        const { widgetResponse, metricId, childMetricIds } = sourceQueryConfig as WidgetQuerySourceConfig;
        const allMetricIds = [metricId, ...(childMetricIds || [])].filter(Boolean);

        allMetricIds.forEach(vizMetricId => {
          let dataType: DataType = "STRING";

          const metricDef = widgetResponse?.widgetConfig?.dataDefinition?.metrics?.[vizMetricId];
          if (metricDef?.sourceType === "userServiceField") {
            const { userServiceField } = metricDef.userServiceFieldMetricConfig;
            const usField: UserServiceFieldWithMeta = { userServiceField };

            const {
              dataType: uDataType,
              subType: uSubType,
              aggInfo: uAggInfo
            } = this.getDataTypeAndAggInfoFromUSField(usField);

            dataType = metricDef.subType
              ? dataTypeManager.getDataTypeDescriptor(metricDef.subType as DataType).dataType
              : (dataTypeManager.getDataTypeWithNameFromKind(uDataType, uSubType)[0] as DataType);
            aggInfo = uAggInfo || aggInfo;
          } else if (metricDef?.sourceType === "expression") {
            dataType = dataTypeManager.getDataTypeDescriptor((metricDef.subType as DataType) || "STRING").dataType;
          }

          dataTypeByMetricId[vizMetricId] = dataType;
        });

        let dataTypes = Object.values(dataTypeByMetricId).filter(dataType => {
          const dataTypeStr = dataType as string;
          return Boolean(dataTypeStr) && dataTypeStr !== "NA" && dataTypeStr !== "NOT_SET";
        });
        dataTypes = uniq(dataTypes);
        uiDataType = dataTypes.length > 1 ? "STRING" : dataTypes[0] || "STRING";
        break;
      }

      default:
        break;
    }

    const { cardinality } = aggInfo || {};
    const { ratio = 0, value = "0" } = cardinality || {};
    const numCardinality = parseFloat(value);

    return {
      aggInfo,
      cardinality: numCardinality,
      cardinalityRatio: ratio,
      uiDataType,
      dataTypeByMetricId
    };
  }

  static getDataTypeAndAggInfoFromUSField(usField: UserServiceFieldWithMeta) {
    const { userServiceMetadata, userServiceField } = usField;
    const { dataType: uDataType, entityField, fieldName } = userServiceField || {};
    const { subType: uSubType } = userServiceMetadata || {};
    const { kindDescriptor, propType } = entityField || {};

    const dataType = propType && propType !== "NA" ? propType : uDataType;
    const subType = uSubType
      ? (uSubType as FieldSubType)
      : fieldName === this.durationFieldName
        ? "duration"
        : kindDescriptor?.type || "not_set";
    const aggInfo = userServiceMetadata?.aggregationResponse;

    return {
      dataType,
      subType,
      aggInfo
    };
  }

  static getDefaultSlicesBasedOnQueryConfig(
    queryConfig: CatalogQueryConfig,
    implicitSlice: UserServiceFieldSlice
  ): UserServiceFieldSlice[] {
    if (queryConfig.sourceQueryConfig.queryType === "userServiceField") {
      const usFieldWithMeta = queryConfig.sourceQueryConfig.usField;
      const usField = usFieldWithMeta.userServiceField;
      const cardinality = parseInt(
        usFieldWithMeta?.userServiceMetadata?.aggregationResponse?.cardinality?.value || "1",
        10
      );
      const isVeryHighCardinalityField = DataTypeManagerUtils.getCardinalityCategory(cardinality) === "very-high";

      const fieldName = this.getFieldName(queryConfig);
      const ootbMetricName = DataTypeVisualisationManager.getOOTBMetricNameForFieldName(fieldName);

      if (ootbMetricName) {
        return [implicitSlice];
      } else if (isVeryHighCardinalityField) {
        return [];
      } else if (CatalogWidgetUtils.isNumericMetric(usField.dataType)) {
        return [implicitSlice];
      } else {
        const usFieldSlice: UserServiceFieldSlice = {
          tagName: FieldPickerUtils.getPromSanitizedUSFName(usField),
          userServiceField: usField
        };
        return [usFieldSlice];
      }
    }
    return [];
  }

  static isHighCardinalityNumericMetric(uiDataType: DataType, cardinality: number): boolean {
    const isNumericType = this.isNumericMetric(uiDataType);
    const cardinalityCategory = DataTypeVisualisationManager.getCardinalityCategory(cardinality);
    const isHighCardinality = cardinalityCategory === "high" || cardinalityCategory === "very-high";

    return isNumericType && isHighCardinality;
  }

  static isNumericMetric(uiDataType: DataType) {
    return numericDataTypes.includes(uiDataType);
  }

  static getEventFiltersToApply(
    variablesEventFilters: Record<string, UserServiceFilterList>,
    metricIds: string[],
    wEventFilters: UserServiceFilterExpression[],
    wEventFilterTree: UserServiceFilterExpressionTree
  ) {
    let allEventFilters: Record<string, UserServiceFilterList> = variablesEventFilters;

    if (wEventFilterTree || wEventFilters?.length) {
      allEventFilters = cloneDeep(allEventFilters);

      metricIds.forEach(metricId => {
        if (!allEventFilters[metricId]) {
          allEventFilters[metricId] = {
            userServiceFilters: []
          };
        }
      });

      if (featureFlagService.isFeatureEnabled(FEATURE_FLAGS.useComplexExpressions)) {
        Object.keys(allEventFilters).forEach(metricKey => {
          const filterList = allEventFilters[metricKey];
          const nFilters: UserServiceFilterList = {
            expressionTree: {
              filterNodes: [],
              logicalOperator: LogicalOperator.AND
            }
          };

          // Create an expression tree and push to filter nodes
          if (filterList.userServiceFilters?.length) {
            const exprTree: UserServiceFilterExpressionTree = {
              filterNodes: [],
              logicalOperator: LogicalOperator.OR
            };

            filterList.userServiceFilters.forEach(usfList => {
              const subExprTree: UserServiceFilterExpressionTree = {
                filterNodes: [],
                logicalOperator: LogicalOperator.AND
              };

              usfList.userServiceFilterExpressions.forEach(expr => {
                subExprTree.filterNodes.push({
                  expression: expr
                });
              });

              exprTree.filterNodes.push({
                expressionTree: subExprTree
              });
            });

            nFilters.expressionTree.filterNodes.push({
              expressionTree: exprTree
            });
          }

          // Push existing tree to the filterNodes
          if (filterList.expressionTree) {
            nFilters.expressionTree.filterNodes.push({
              expressionTree: filterList.expressionTree
            });
          }

          // Add widget level tree to the filterNodes
          if (wEventFilterTree) {
            nFilters.expressionTree.filterNodes.push({
              expressionTree: wEventFilterTree
            });
          }

          // Create an AND expression tree and add it to the filterNodes
          if (wEventFilters?.length) {
            const exprTree: UserServiceFilterExpressionTree = {
              filterNodes: [],
              logicalOperator: LogicalOperator.AND
            };

            wEventFilters.forEach(expr => {
              exprTree.filterNodes.push({
                expression: expr
              });
            });

            nFilters.expressionTree.filterNodes.push({
              expressionTree: exprTree
            });
          }

          allEventFilters[metricKey] = nFilters;
        });
      } else if (wEventFilters?.length) {
        Object.keys(allEventFilters).forEach(metricKey => {
          const filterList = allEventFilters[metricKey];
          if (!filterList.userServiceFilters?.length) {
            filterList.userServiceFilters = [
              {
                userServiceFilterExpressions: cloneDeep(wEventFilters)
              }
            ];
          } else {
            filterList.userServiceFilters?.forEach(usfList => {
              usfList.userServiceFilterExpressions.push(...cloneDeep(wEventFilters || []));
            });
          }
        });
      }
    }

    return allEventFilters;
  }

  private static getVisualisationOptions(vizConfig: VisualisationConfig, preferTimeseries = false): VizOption[] {
    const { basic, timeseries } = vizConfig;

    const first = preferTimeseries ? timeseries : basic;
    const next = preferTimeseries ? basic : timeseries;

    let vizArray = [first[0], next[0], ...first.slice(1), ...next.slice(1)].filter(v =>
      existingViz.includes(v?.visualisation)
    );

    if (vizArray.length < 2) {
      vizArray.push({
        queryType: QueryType.aggregatedTimeseriesOverTag,
        visualisation: Visualisations.timeseries,
        options: {
          aggregateBySliceTag: true
        }
      });
    }

    vizArray = uniqBy(vizArray, v => v.visualisation);

    return vizArray.map((viz, idx) => {
      const id = idx.toString();
      return {
        id,
        ...viz
      };
    });
  }

  private static isPredefinedFieldName(fieldName: string) {
    return [this.durationFieldName, this.hasErrorFieldName, this.eventIDFieldName].includes(fieldName);
  }

  private static getQueryAggregators(
    fieldName: string,
    aggregator: string,
    queryConfig: CatalogQueryConfig,
    matchingQuerySchema: WidgetQuerySchema
  ) {
    const { overTagAgg: presetOverTagAgg, overTimeAggFunc } = queryConfig || {};

    let metricAggregator = aggregator;

    if (fieldName === this.eventIDFieldName || fieldName === this.hasErrorFieldName) {
      metricAggregator = "count";
    }

    const overTimeAgg =
      overTimeAggFunc ||
      matchingQuerySchema?.defaultTimeAgg ||
      ExploreQueryUtils.getOverTimeAggregatorForAggregator(aggregator);
    const overTagAgg =
      presetOverTagAgg?.aggregator ||
      matchingQuerySchema?.defaultTagAgg ||
      ExploreQueryUtils.getOverTagAggregatorForAggregator(aggregator);

    return {
      metricAggregator,
      overTimeAgg,
      overTagAgg
    };
  }

  private static getWidgetResponseDTOForUSFieldSource(
    entityType: string,
    userServiceId: string,
    aggregator: string,
    queryConfig: CatalogQueryConfig,
    metricId: string,
    slices: UserServiceFieldSlice[],
    defMetricName: string
  ): WidgetResponseDTO {
    const {
      usField,
      usfSliceSet,
      filterExpressions: presetFilterExpressions,
      extraLabels = {}
    } = queryConfig.sourceQueryConfig as USFieldQuerySourceConfig;

    const usFieldCopy = cloneDeep(usField);
    const { userServiceField } = usFieldCopy;
    const { fieldName, dataType } = userServiceField;

    const isHasErrorField = this.isHasErrorField(fieldName);

    usFieldCopy.userServiceField.fieldName = isHasErrorField ? this.eventIDFieldName : fieldName;
    usFieldCopy.userServiceField.dataType = isHasErrorField ? "STRING" : dataType;
    usFieldCopy.userServiceField.userServices = usFieldCopy.userServiceField.userServices.filter(
      usTuple => usTuple.userServiceEntityId === userServiceId
    );

    const isPredefinedField = this.isPredefinedFieldName(fieldName);
    const fieldSliceExists = slices.some(slice =>
      compareUSFields(slice.userServiceField, usFieldCopy.userServiceField)
    );

    const eMetricId = metricId || generateId();
    const widgetConfigDto = ExploreQueryUtils.getUSFieldWidgetConfig(
      entityType,
      userServiceField,
      aggregator,
      !this.isNumericMetric(dataType) && !isPredefinedField && !fieldSliceExists,
      false,
      eMetricId,
      defMetricName,
      slices
    );

    const usfMetricDef = widgetConfigDto.dataDefinition.metrics[eMetricId] as UserServiceFieldMetricConfigDefinition;
    usfMetricDef.userServiceFieldMetricConfig.sliceSets =
      usfSliceSet || usfMetricDef.userServiceFieldMetricConfig.sliceSets;
    usfMetricDef.doNotSave = true;

    const filterExpressions: UserServiceFilterExpression[] = isHasErrorField
      ? [
          {
            field: usField.userServiceField,
            operator: "=",
            value: "true"
          }
        ]
      : [];
    filterExpressions.push(...(presetFilterExpressions || []));
    usfMetricDef.userServiceFieldMetricConfig.eventFilters = {
      userServiceFilters: [
        {
          userServiceFilterExpressions: filterExpressions
        }
      ]
    };

    const displayFieldName = isHasErrorField
      ? "hasError"
      : FieldPickerUtils.getPromSanitizedUSFName(usFieldCopy.userServiceField);
    widgetConfigDto.labels = getCatalogWidgetLabels(entityType, userServiceId, displayFieldName);
    Object.assign(widgetConfigDto.labels, extraLabels);

    return {
      querySchema: {
        querySchema: []
      },
      version: 1,
      widgetConfig: widgetConfigDto,
      widgetId: ""
    };
  }

  private static getPayloadForUSFieldSource(
    widgetResponseDTO: WidgetResponseDTO,
    usfMetricDef: UserServiceFieldMetricConfigDefinition,
    queryConfig: CatalogQueryConfig,
    vizConfig: VizToQueryConfig,
    timeRange: TimeRange,
    compareTimeRange: TimeRange,
    selectorSpec: SelectorSpec,
    aggregatedTags: string[],
    metricType: ChangeMetric,
    postAggProjections: PostAggProjection[],
    limitSpecFunction: LimitSpecFunction,
    limit: number,
    isSingleStatQuery: boolean,
    downsample: string
  ): CatalogWidgetFetchDataPayload {
    const querySchema = widgetResponseDTO?.querySchema?.querySchema;

    if (!usfMetricDef || !querySchema.length) {
      return {
        sliceSpec: [],
        mode: ExploreQueryType.adhoc
      };
    }

    const { usfSliceSet } = queryConfig.sourceQueryConfig as USFieldQuerySourceConfig;

    const { queryType, options } = vizConfig;
    const { aggregateBySliceTag = false, aggregateByEntityTag = false } = options || {};

    aggregatedTags =
      vizConfig.visualisation === Visualisations.sparkLine || vizConfig.visualisation === Visualisations.singleStat
        ? []
        : aggregatedTags;

    usfMetricDef.userServiceFieldMetricConfig.sliceSets =
      usfSliceSet || usfMetricDef.userServiceFieldMetricConfig.sliceSets;
    const sliceSpec = this.getUSFieldSliceSpecAndFilters(
      querySchema,
      usfMetricDef,
      queryConfig,
      vizConfig,
      timeRange,
      compareTimeRange,
      queryType,
      aggregateBySliceTag,
      aggregateByEntityTag,
      selectorSpec,
      aggregatedTags,
      metricType,
      postAggProjections,
      limitSpecFunction,
      limit,
      isSingleStatQuery,
      downsample
    );

    return {
      sliceSpec,
      mode: ExploreQueryType.adhoc
    };
  }

  private static getUSFieldSliceSpecAndFilters(
    querySchema: WidgetQuerySchema[],
    metricDef: UserServiceFieldMetricConfigDefinition,
    queryConfig: CatalogQueryConfig,
    vizConfig: VizToQueryConfig,
    timeRange: TimeRange,
    compareTimeRange: TimeRange,
    queryType: QueryType,
    aggregateByCurrentField: boolean,
    aggregateByEntityTag: boolean,
    widgetSelectorSpec: SelectorSpec,
    aggregatedTags: string[],
    metricType: ChangeMetric,
    postAggProjections: PostAggProjection[],
    limitSpecFunction: LimitSpecFunction,
    limit: number,
    isSingleStatQuery: boolean,
    downsample: string
  ) {
    const {
      sliceSet: querySliceSet,
      selectorSpec: querySelectorSpec,
      overTagAgg: presetOverTagAgg,
      overTimeAggFunc
    } = queryConfig;

    const { userServiceFieldMetricConfig, id: metricId } = metricDef;
    const { sliceSets, userServiceField, aggregator } = userServiceFieldMetricConfig;
    const { fieldName } = userServiceField;

    const selectImplicitSliceSet =
      aggregateByEntityTag && aggregatedTags.length === 1 && aggregatedTags.includes(ENTITY_TAG);
    const usfwSliceSet = selectImplicitSliceSet ? sliceSets[0] : last(sliceSets);
    const sliceSet = querySliceSet || WidgetConfigUtils.convertUSFieldSliceSetToTagSlice(usfwSliceSet);

    const tagNames = sliceSet.slices.map(s => s.tagName);
    const matchingQuerySchema = WidgetConfigUtils.getMatchingQuerySchema(querySchema, metricId, tagNames);

    const { metricAggregator, overTagAgg, overTimeAgg } = this.getQueryAggregators(
      fieldName,
      aggregator,
      queryConfig,
      matchingQuerySchema
    );

    userServiceFieldMetricConfig.aggregator = metricAggregator;

    const aggregateOverTime =
      queryType === QueryType.aggregatedTimeseries || queryType === QueryType.aggregatedTimeseriesOverTime;
    const aggregateOverTag =
      queryType === QueryType.aggregatedTimeseries || queryType === QueryType.aggregatedTimeseriesOverTag;

    const aggTags = this.getOverTagAggTags(sliceSet, aggregatedTags, aggregateByEntityTag, aggregateByCurrentField);

    const { aggTime, timeShiftCompareSeconds } = this.getAggTimeAndTimeShift(timeRange, compareTimeRange);

    const dsAggTime = this.getDsAggTime(downsample, aggTime, vizConfig.visualisation);

    const overTagAggPayload: OverTagAgg = {
      aggregator: presetOverTagAgg?.aggregator || overTagAgg,
      tagName: presetOverTagAgg?.tagName || aggTags
    };

    const overTimeAggPayload: OverTimeAgg = {
      aggregator: overTimeAggFunc || overTimeAgg,
      timeInSeconds: dsAggTime
    };

    const postAgg: PostAgg = {
      overTagAgg: overTagAggPayload,
      overTimeAgg: overTimeAggPayload,
      projections: postAggProjections,
      timeShiftCompareSeconds,
      sortSpec: {
        limitSpec: {
          function: limitSpecFunction,
          limit: limit
        },
        sortBy: this.getSortByBasedOnMetricType(metricType)
      },
      isSingleStatQuery
    };

    if (!aggregateOverTime) {
      delete postAgg.overTimeAgg;
      delete postAgg.sortSpec;
    }

    if (!aggregateOverTag) {
      delete postAgg.overTagAgg;
    }

    if (!limitSpecFunction) {
      delete postAgg.sortSpec;
    }

    //merging the query and widget selectorsepcs
    const querySpecFilters = querySelectorSpec?.filters ?? [];
    const widgetSpecFilters = widgetSelectorSpec?.filters ?? [];
    const combinedSelectorSpec: SelectorSpec = {
      filters: [...querySpecFilters, ...widgetSpecFilters].filter(filter => filter.tags.length > 0)
    };

    const sliceSpec: SliceSpec[] = [
      {
        metricId,
        sliceSpecId: generateId(),
        sliceSet,
        postAgg,
        selectorSpec: combinedSelectorSpec
      }
    ];

    // this.adjustSliceSpecForViz(sliceSpec, visualisation);

    return sliceSpec;
  }

  private static getPayloadForWidgetSource(
    widgetResponseDTO: WidgetResponseDTO,
    queryConfig: CatalogQueryConfig,
    metricId: string,
    vizConfig: VizToQueryConfig,
    selectorSpec: SelectorSpec,
    sliceSet: SliceSet,
    timeRange: TimeRange,
    compareTimeRange: TimeRange,
    widgetSelectorSpec: SelectorSpec,
    aggregatedTags: string[],
    metricType: ChangeMetric,
    postAggProjections: PostAggProjection[],
    limitSpecFunction: LimitSpecFunction,
    limit: number,
    isSingleStatQuery: boolean,
    downsample: string,
    disabledSeries?: Record<string, SliceSet[]>
  ): CatalogWidgetFetchDataPayload {
    let sliceSpec: SliceSpec[] = [];
    let mode = ExploreQueryType.adhoc;

    if (widgetResponseDTO) {
      try {
        const { sourceQueryConfig } = queryConfig;
        metricId = metricId || (sourceQueryConfig as WidgetQuerySourceConfig).metricId;

        const {
          querySchema: { querySchema },
          widgetId
        } = widgetResponseDTO || {
          querySchema: {
            querySchema: [] as WidgetQuerySchema[]
          }
        };
        const matchingQuerySchema = querySchema.filter(qs => qs.metricId === metricId);
        const qsSliceSet = matchingQuerySchema[1]?.sliceSet || matchingQuerySchema[0]?.sliceSet;

        mode = widgetId ? ExploreQueryType.saved : ExploreQueryType.adhoc;

        sliceSet = clone(
          sliceSet ||
            qsSliceSet || {
              slices: []
            }
        );

        const { queryType, options, visualisation } = vizConfig;
        const { aggregateBySliceTag = false, aggregateByEntityTag = false } = options || {};

        aggregatedTags =
          vizConfig.visualisation === Visualisations.sparkLine || vizConfig.visualisation === Visualisations.singleStat
            ? []
            : aggregatedTags;
        //check if matching query schema exists then set slicespec
        sliceSpec = !matchingQuerySchema.length
          ? []
          : this.getWidgetSliceSpec(
              querySchema,
              queryConfig,
              sliceSet,
              selectorSpec,
              metricId,
              timeRange,
              compareTimeRange,
              queryType,
              aggregateBySliceTag,
              aggregateByEntityTag,
              visualisation,
              widgetSelectorSpec,
              aggregatedTags,
              metricType,
              postAggProjections,
              limitSpecFunction,
              limit,
              isSingleStatQuery,
              downsample,
              disabledSeries
            );
      } catch (e) {
        logger.error("CatalogWidgetDataPayload", "Error construction payload", e);
        sliceSpec = [];
      }
    }

    return {
      mode,
      sliceSpec
    };
  }

  private static getPayloadForUseCaseSource(
    widgetResponseDTO: WidgetResponseDTO,
    queryConfig: CatalogQueryConfig,
    vizConfig: VizToQueryConfig,
    selectorSpec: SelectorSpec,
    sliceSet: SliceSet,
    timeRange: TimeRange,
    compareTimeRange: TimeRange,
    widgetSelectorSpec: SelectorSpec,
    aggregatedTags: string[],
    metricType: ChangeMetric,
    postAggProjections: PostAggProjection[],
    limitSpecFunction: LimitSpecFunction,
    limit: number,
    isSingleStatQuery: boolean,
    downsample: string
  ): CatalogWidgetFetchDataPayload {
    const { sourceQueryConfig } = queryConfig;
    if (sourceQueryConfig.queryType === "useCase") {
      const { dataQueryConfig } = sourceQueryConfig;
      const { sliceSpec, id } = dataQueryConfig.query;

      if (!sliceSpec.metricId) {
        delete sliceSpec.buildingBlockConfigId;

        const metrics = widgetResponseDTO?.widgetConfig?.dataDefinition?.metrics || {};
        sliceSpec.metricId = Object.keys(metrics)[0];
      }

      sliceSet = sliceSet ||
        sliceSpec.sliceSet || {
          slices: []
        };

      const { visualisation } = vizConfig;

      if (sliceSpec) {
        if (!sliceSpec.postAgg) {
          logger.info("getPayloadForUseCaseSource", "Adding postAgg to sliceSpec", sliceSpec);
          sliceSpec.postAgg = {};
        }

        const overTimePostAgg = sliceSpec.postAgg as OverTimePostAgg;
        const overTagPostAgg = sliceSpec.postAgg as OverTagPostAgg;

        const matchingSlice = (widgetResponseDTO?.querySchema?.querySchema || []).find(qs => {
          const { sliceSet: qsSliceSet } = qs;
          const { sliceSet } = sliceSpec;

          return WidgetConfigUtils.compareSliceSets(qsSliceSet, sliceSet);
        });

        let overTimeAgg: OverTimeAggregators = "avg";
        let overTagAgg: OverTagAggregators = "avg";

        if (!matchingSlice) {
          logger.warn(
            "getPayloadForUseCaseSource",
            "Couldn't find matching query schema for sliceSet. Using avg as defaults",
            {
              sliceSet: sliceSpec.sliceSet,
              querySchema: widgetResponseDTO?.querySchema?.querySchema
            }
          );
        } else {
          overTimeAgg = matchingSlice.defaultTimeAgg as OverTimeAggregators;
          overTagAgg = matchingSlice.defaultTagAgg as OverTagAggregators;
        }

        if (visualisation !== Visualisations.timeseries && visualisation !== Visualisations.sparkLine) {
          if (!overTimePostAgg.overTimeAgg) {
            logger.info("getPayloadForUseCaseSource", "Adding overTimeAgg to sliceSpec.postAgg", overTimePostAgg);
            overTimePostAgg.overTimeAgg = {
              aggregator: overTimeAgg,
              timeInSeconds: 0
            };
          }

          const { aggTime, timeShiftCompareSeconds } = this.getAggTimeAndTimeShift(timeRange, compareTimeRange);

          const dsAggTime = this.getDsAggTime(downsample, aggTime, visualisation);

          overTimePostAgg.overTimeAgg.timeInSeconds = dsAggTime;
          overTimePostAgg.timeShiftCompareSeconds = timeShiftCompareSeconds;
        } else {
          delete overTimePostAgg.overTimeAgg;
        }

        if (!overTagPostAgg.overTagAgg) {
          logger.info("getPayloadForUseCaseSource", "Adding overTagAgg to sliceSpec.postAgg", overTagPostAgg);
          overTagPostAgg.overTagAgg = {
            aggregator: overTagAgg,
            tagName: []
          };
        }

        overTagPostAgg.overTagAgg.tagName = aggregatedTags;

        sliceSpec.postAgg.isSingleStatQuery = isSingleStatQuery;
        sliceSpec.postAgg.projections = postAggProjections;
        sliceSpec.postAgg.sortSpec = {
          limitSpec: {
            function: limitSpecFunction,
            limit
          },
          sortBy: this.getSortByBasedOnMetricType(metricType)
        };

        if (visualisation === Visualisations.singleStat || visualisation === Visualisations.sparkLine) {
          const overTagPostAgg = (sliceSpec.postAgg as OverTagPostAgg).overTagAgg;
          if (overTagPostAgg) {
            overTagPostAgg.tagName = [];
          }
        }

        sliceSpec.selectorSpec = widgetSelectorSpec;
      }

      return {
        mode: id ? ExploreQueryType.saved : ExploreQueryType.adhoc,
        sliceSpec: [sliceSpec]
      };
    }
  }

  private static getWidgetResponseDTOForWidgetSource(queryConfig: CatalogQueryConfig): WidgetResponseDTO {
    try {
      const { sourceQueryConfig } = queryConfig;
      const { widgetResponse } = sourceQueryConfig as WidgetQuerySourceConfig;
      return widgetResponse;
    } catch (e) {
      logger.error("CatalogWidgetDataPayload", "Error construction payload", e);
      const widgetConfig = ExploreQueryUtils.getDefaultWidgetConfig();
      return {
        querySchema: {
          querySchema: []
        },
        version: 1,
        widgetConfig,
        widgetId: generateId()
      };
    }
  }

  private static getWidgetResponseDTOForUseCaseSource(queryConfig: CatalogQueryConfig): WidgetResponseDTO {
    try {
      const { sourceQueryConfig } = queryConfig;
      const { dataQueryConfig } = sourceQueryConfig as UseCaseQuerySourceConfig;
      const { query, name } = dataQueryConfig;
      const { widgetId, widgetConfigDto } = WidgetConfigUtils.getWidgetConfigDtoFromBizDataQuery(query);

      const widgetResponseDTO: WidgetResponseDTO = {
        querySchema: {
          querySchema: []
        },
        widgetConfig: widgetConfigDto,
        version: 1,
        widgetId,
        widgetName: name
      };
      return widgetResponseDTO;
    } catch (e) {
      logger.error("CatalogWidgetDataPayload", "Error construction payload", e);
      const widgetConfig = ExploreQueryUtils.getDefaultWidgetConfig();
      return {
        querySchema: {
          querySchema: []
        },
        version: 1,
        widgetConfig,
        widgetId: generateId()
      };
    }
  }

  private static getWidgetSliceSpec(
    querySchema: WidgetQuerySchema[],
    queryConfig: CatalogQueryConfig,
    sliceSet: SliceSet,
    querySelectorSpec: SelectorSpec,
    metricId: string,
    timeRange: TimeRange,
    compareTimeRange: TimeRange,
    queryType: QueryType,
    aggregateByCurrentField: boolean,
    aggregateByEntityTag: boolean,
    visualisation: Visualisations,
    widgetSelectorSpec: SelectorSpec,
    aggregatedTags: string[],
    metricType: ChangeMetric,
    postAggProjections: PostAggProjection[],
    limitSpecFunction: LimitSpecFunction,
    limit: number,
    isSingleStatQuery: boolean,
    downsample: string,
    disabledSeries?: Record<string, SliceSet[]>
  ) {
    const { overTagAgg: presetOverTagAgg, overTimeAggFunc } = queryConfig;

    const metricQuerySchemas: Record<string, WidgetQuerySchema> =
      WidgetConfigUtils.getMaxSlicesSliceSetByMetricId(querySchema);

    let metricIds = Object.keys(metricQuerySchemas).filter(metricId => !disabledSeries?.[metricId]?.length);
    metricIds = metricIds.includes(metricId) ? [metricId] : metricIds;

    const { aggTime, timeShiftCompareSeconds } = this.getAggTimeAndTimeShift(timeRange, compareTimeRange);

    const dsAggTime = this.getDsAggTime(downsample, aggTime, visualisation);

    const sliceSpec: SliceSpec[] = metricIds.map(metricId => {
      const qsEntry = metricQuerySchemas[metricId];

      const { overTagAgg, overTimeAgg } = this.getQueryAggregators("", "", queryConfig, qsEntry);

      const aggregateOverTime =
        queryType === QueryType.aggregatedTimeseries || queryType === QueryType.aggregatedTimeseriesOverTime;
      const aggregateOverTag =
        queryType === QueryType.aggregatedTimeseries || queryType === QueryType.aggregatedTimeseriesOverTag;

      const aggTags = this.getOverTagAggTags(sliceSet, aggregatedTags, aggregateByEntityTag, aggregateByCurrentField);

      //merging the query and widget selectorsepcs
      const querySpecFilters = querySelectorSpec?.filters ?? [];
      const widgetSpecFilters = widgetSelectorSpec?.filters ?? [];
      const combinedSelectorSpec: SelectorSpec = {
        filters: [...querySpecFilters, ...widgetSpecFilters].filter(filter => filter.tags.length > 0)
      };

      const sliceSpec: SliceSpec = {
        metricId,
        sliceSet,
        sliceSpecId: generateId(),
        postAgg: this.getWidgetQueryPostAgg(
          presetOverTagAgg,
          overTagAgg,
          aggTags,
          overTimeAggFunc,
          overTimeAgg,
          dsAggTime,
          postAggProjections,
          timeShiftCompareSeconds,
          limitSpecFunction,
          limit,
          metricType,
          aggregateOverTime,
          aggregateOverTag,
          isSingleStatQuery
        ),
        selectorSpec: combinedSelectorSpec
      };

      if (visualisation === Visualisations.singleStat) {
        const overTagPostAgg = (sliceSpec.postAgg as OverTagPostAgg)?.overTagAgg;
        if (overTagPostAgg) {
          overTagPostAgg.tagName = [];
        }
      }

      return sliceSpec;
    });

    return sliceSpec;
  }

  private static getWidgetQueryPostAgg(
    presetOverTagAgg: OverTagAgg,
    overTagAgg: string,
    aggTags: string[],
    overTimeAggFunc: string,
    overTimeAgg: string,
    aggTime: number,
    postAggProjections: PostAggProjection[],
    timeShiftCompareSeconds: number,
    limitSpecFunction: LimitSpecFunction,
    limit: number,
    metricType: ChangeMetric,
    aggregateOverTime: boolean,
    aggregateOverTag: boolean,
    isSingleStatQuery: boolean
  ) {
    const overTagAggPayload: OverTagAgg = {
      aggregator: presetOverTagAgg?.aggregator || overTagAgg,
      tagName: presetOverTagAgg?.tagName || aggTags
    };

    const overTimeAggPayload: OverTimeAgg = {
      aggregator: overTimeAggFunc || overTimeAgg,
      timeInSeconds: aggTime
    };

    const postAgg: PostAgg = {
      overTagAgg: overTagAggPayload,
      overTimeAgg: overTimeAggPayload,
      projections: postAggProjections,
      timeShiftCompareSeconds,
      sortSpec: {
        limitSpec: {
          function: limitSpecFunction,
          limit: limit
        },
        sortBy: this.getSortByBasedOnMetricType(metricType)
      },
      isSingleStatQuery
    };

    if (!aggregateOverTime) {
      delete postAgg.overTimeAgg;
      delete postAgg.sortSpec;
    }

    if (!aggregateOverTag) {
      delete postAgg.overTagAgg;
    }

    if (!limitSpecFunction) {
      delete postAgg.sortSpec;
    }

    return postAgg;
  }

  private static getOverTagAggTags(
    sliceSet: SliceSet,
    aggregatedTags: string[],
    aggregateByEntityTag: boolean,
    aggregateByCurrentField: boolean
  ) {
    const aggTags = aggregatedTags
      ? aggregatedTags
      : aggregateByEntityTag
        ? sliceSet.slices[0]?.tagName
          ? [sliceSet.slices[0]?.tagName]
          : []
        : aggregateByCurrentField
          ? last(sliceSet.slices)?.tagName
            ? [last(sliceSet.slices)?.tagName]
            : []
          : [];

    return aggTags;
  }

  private static getSortByBasedOnMetricType(metricType: ChangeMetric): SortSpecSortBy {
    return metricType === "delta" ? "current" : metricType;
  }

  private static getAggTimeAndTimeShift(timeRange: TimeRange, compareTimeRange: TimeRange) {
    const { raw: trRaw } = timeRange;
    const { raw: cTrRaw } = compareTimeRange;
    const { from, to } = timeRangeUtils.getTimeRangeMillisFromRaw(trRaw);

    const aggTime = millisToSeconds(to.valueOf()) - millisToSeconds(from.valueOf());
    const timeShiftCompareSeconds = millisToSeconds(timeRangeUtils.getMillisFromOffset(cTrRaw.from));

    return {
      aggTime,
      timeShiftCompareSeconds
    };
  }

  private static getDsAggTime(downsample: string, aggTime: number, visualisation: Visualisations) {
    if (visualisation !== Visualisations.timeseries && visualisation !== Visualisations.sparkLine) {
      return downsample === "auto" || !downsample ? aggTime : kbn.interval_to_seconds(downsample);
    } else if (downsample && downsample !== "auto") {
      return kbn.interval_to_seconds(downsample);
    }

    return aggTime;
  }
}

const existingViz = [
  Visualisations.barChart,
  Visualisations.donut,
  Visualisations.gauge,
  Visualisations.geoMap,
  Visualisations.timeseries,
  Visualisations.treeMap,
  Visualisations.histogram,
  Visualisations.sparkLine,
  Visualisations.insights,
  Visualisations.table
];

const millisToSeconds = (millis: number) => timeRangeUtils.getSecondsFromMillis(millis);
