import { IncTextfield } from "@inception/ui";
import { isEqual } from "lodash";
import React, { FC, useCallback, useEffect, useState } from "react";
import { ElementPropertyValues } from "../../../../../../services/api/operationalise";
import { WEB_LINK_DISPLAY_TEXT_PROP_NAME, WEB_LINK_URL_PROP_NAME } from "../../utils";

interface Props {
  properties: ElementPropertyValues;
  onChange: (properties: ElementPropertyValues) => void;
}

export const LinkPropertiesRenderer: FC<Props> = props => {
  const { properties, onChange } = props;

  const initDisplayText = properties?.propValue?.[WEB_LINK_DISPLAY_TEXT_PROP_NAME]?.stringVal || "";
  const [displayText, setDisplayText] = useState(initDisplayText);

  const initUrl = properties?.propValue?.[WEB_LINK_URL_PROP_NAME]?.stringVal || "";
  const [url, setUrl] = useState(initUrl);

  const onDisplayTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const displayText = e.target.value || url || "URL";
      setDisplayText(displayText);
    },
    [url]
  );

  const onURLChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    setUrl(url);
  }, []);

  useEffect(() => {
    const nProperties: ElementPropertyValues = {
      propValue: {
        ...(properties?.propValue || {}),
        [WEB_LINK_DISPLAY_TEXT_PROP_NAME]: {
          stringVal: displayText
        },
        [WEB_LINK_URL_PROP_NAME]: {
          stringVal: url
        }
      }
    };

    if (!isEqual(properties, nProperties)) {
      onChange(nProperties);
    }
  }, [displayText, onChange, properties, url]);

  return (
    <>
      <div className="inc-flex-column marginBt12">
        <IncTextfield
          className="marginBt8"
          label="Display text"
          onChange={onDisplayTextChange}
          placeholder="Display text"
          value={displayText}
        />

        <IncTextfield
          label="URL"
          onChange={onURLChange}
          placeholder="URL"
          value={url}
        />
      </div>
    </>
  );
};
