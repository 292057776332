import React, { useCallback, useMemo, useState } from "react";
import {
  IncSelectOption,
  IncSelect,
  IncTextfield,
  IncDurationSelector,
  IncSelectorDuration,
  IncCollapsible,
  IncButton,
  IncFaIcon
} from "@inception/ui";
import { inceptionDarkColorPalette } from "@inception/ui-styles";
import {
  MetricPredicate,
  UserServiceField,
  FieldPickerContextDTO,
  UserServiceFieldMetricConfigDefinition,
  ConditionWithPickerType,
  PredicateOp
} from "../../services/api/explore";
import { RelativeDurationType } from "../../dashboard/models/BaseWidgetModel";
import { getDefaultUsageEventMetricPredicate, pluralizeWord } from "../../utils";
import { getTimeObjFromDuration } from "../../utils/DurationUtils";
import { ConditionsBuilder } from "../explore";
import { getDurationFromPredicate, getShiftSecs } from "../../utils/ExploreUtils";

type UsageEventsFilterProps = {
  metricPredicates: MetricPredicate[];
  eventTypeId: string;
  onChange: (predicates: MetricPredicate[]) => void;
  usField: UserServiceField;
  eventTypeList: IncSelectOption[];
  fieldPickerContext: FieldPickerContextDTO;
  isCollapsible?: boolean;
};

const usageEventsOperators: IncSelectOption[] = [
  {
    label: "Atleast",
    value: "ge"
  },
  {
    label: "Atmost",
    value: "le"
  }
];

export const UsageEventFilter: React.FC<UsageEventsFilterProps> = (props: UsageEventsFilterProps) => {
  const {
    eventTypeId,
    metricPredicates,
    onChange,
    usField,
    isCollapsible = false,
    eventTypeList,
    fieldPickerContext
  } = props;

  const [showFilters, setShowFilters] = useState(false);
  const toggleFilters = useCallback(() => setShowFilters(prev => !prev), []);

  const onAddClick = useCallback(() => {
    const field = usField;
    field.userServices = [
      {
        userServiceEntityId: eventTypeId
      }
    ];
    const predicate = getDefaultUsageEventMetricPredicate(usField);
    onChange([...metricPredicates, predicate]);
  }, [eventTypeId, metricPredicates, onChange, usField]);

  const removeClick = useCallback(
    (idx: number) => {
      const predicates = [...metricPredicates];
      predicates.splice(idx, 1);
      onChange(predicates);
    },
    [metricPredicates, onChange]
  );

  const onOperatorChange = useCallback(
    (option: IncSelectOption, idx: number) => {
      const predicates = [...metricPredicates];
      predicates.forEach((x, i) => {
        if (i === idx) {
          x.op = option.value as PredicateOp;
        }
      });
      onChange(predicates);
    },
    [metricPredicates, onChange]
  );

  const pluralizedEventTypeList = useMemo(() => {
    const pluralizedEventTypeList = eventTypeList.map(x => ({
      label: pluralizeWord(x.label),
      value: x.value
    }));
    return pluralizedEventTypeList;
  }, [eventTypeList]);

  const onValueChange = useCallback(
    (value: string, idx) => {
      const predicates = [...metricPredicates];
      predicates.forEach((x, i) => {
        if (i === idx) {
          x.value = value;
        }
      });
      onChange(predicates);
    },
    [metricPredicates, onChange]
  );

  const onDurationChange = useCallback(
    (duration: IncSelectorDuration, idx: number) => {
      const predicates = [...metricPredicates];
      predicates.forEach((predicate, i) => {
        if (i === idx) {
          predicate.aggregateDuration = getTimeObjFromDuration(duration.duration as number, duration.durationType);
          predicate.aggregateDurationSeconds = getShiftSecs(duration.duration as number, duration.durationType);
        }
      });
      onChange(predicates);
    },
    [metricPredicates, onChange]
  );

  const onChangeEventType = useCallback(
    (op: IncSelectOption, idx: number) => {
      const predicates = [...metricPredicates];
      predicates.forEach((predicate, i) => {
        if (idx === i) {
          const metric = predicate.metric as UserServiceFieldMetricConfigDefinition;
          const userServices = [
            {
              userServiceEntityId: op.value
            }
          ];
          const usField = {
            ...metric?.userServiceFieldMetricConfig?.userServiceField
          };
          usField.userServices = userServices;
          const defPredicate = getDefaultUsageEventMetricPredicate(usField);
          const defMetric = defPredicate.metric as UserServiceFieldMetricConfigDefinition;
          (predicate.metric as UserServiceFieldMetricConfigDefinition).userServiceFieldMetricConfig =
            defMetric.userServiceFieldMetricConfig;
        }
      });
      onChange(predicates);
    },
    [metricPredicates, onChange]
  );

  const onChangeFilters = useCallback(
    (filters: ConditionWithPickerType[], idx: number) => {
      const filterExpressions = filters.map(f => ({
        field: f.field.payload as UserServiceField,
        operator: f.operator,
        value: f.value,
        values: f.values
      }));
      const predicates = metricPredicates;
      predicates.forEach((predicate, i) => {
        if (i === idx) {
          const metric = predicate.metric as UserServiceFieldMetricConfigDefinition;
          predicate = {
            ...predicate,
            metric: {
              ...metric,
              userServiceFieldMetricConfig: {
                ...metric.userServiceFieldMetricConfig,
                eventFilters: {
                  userServiceFilters: [
                    {
                      userServiceFilterExpressions: filterExpressions
                    }
                  ]
                }
              }
            } as UserServiceFieldMetricConfigDefinition
          };
        }
      });
      onChange(predicates);
    },
    [metricPredicates, onChange]
  );

  const filterComponents = useMemo(
    () => (
      <>
        {metricPredicates.map((metricPredicate, idx) => {
          const { value = "100", metric } = metricPredicate || {};
          const {
            userServiceFieldMetricConfig: { filterExpressions: defFilterExpressions, eventFilters }
          } = metric as UserServiceFieldMetricConfigDefinition;

          const operatorVal = usageEventsOperators.find(x => x.value === metricPredicate.op) || usageEventsOperators[0];
          const onOpChange = (op: IncSelectOption) => onOperatorChange(op, idx);

          const filterExpressions =
            defFilterExpressions || eventFilters?.userServiceFilters?.[0]?.userServiceFilterExpressions || [];
          const filters = filterExpressions.map(f => {
            if (!f) {
              return null;
            }
            const filter: ConditionWithPickerType = {
              field: {
                type: "userServiceField",
                payload: f.field
              },
              operator: f.operator,
              value: f.value,
              values: f.values || []
            };
            return filter;
          });
          const cEventTypeId = (metricPredicate.metric as UserServiceFieldMetricConfigDefinition)
            .userServiceFieldMetricConfig.userServiceField.userServices[0].userServiceEntityId;
          const selectedEventType =
            pluralizedEventTypeList.find(x => x.value === cEventTypeId) || pluralizedEventTypeList[0];

          const onFiltersChange = (filters: ConditionWithPickerType[]) => onChangeFilters(filters, idx);
          const onValChange = (event: React.ChangeEvent<HTMLInputElement>) => onValueChange(event.target.value, idx);
          const onEventTypeChangeLocal = (op: IncSelectOption) => onChangeEventType(op, idx);
          const onDurationChangeLocal = (duration: IncSelectorDuration) => onDurationChange(duration, idx);

          const duration = getDurationFromPredicate(metricPredicate) || {
            duration: 1,
            durationType: RelativeDurationType.HOURS
          };
          const onDelete = () => removeClick(idx);

          const labelComp = (
            <div
              className="inc-flex-column"
              key={idx}
            >
              <div className="inc-flex-row">
                <IncSelect
                  className="operator-container"
                  onChange={onOpChange}
                  options={usageEventsOperators}
                  value={operatorVal}
                />
                <div className="text-container">
                  <IncTextfield
                    onChange={onValChange}
                    value={value}
                  />
                </div>
                <IncSelect
                  className="operator-container"
                  onChange={onEventTypeChangeLocal}
                  options={pluralizedEventTypeList}
                  value={selectedEventType}
                />
                <span className="inc-text-body paddingTp16 paddingRt8">over</span>
                <IncDurationSelector
                  duration={duration}
                  hideLabel
                  onChange={onDurationChangeLocal}
                />
                <IncFaIcon
                  className="inc-cursor-pointer marginLt12 marginTp18"
                  color={inceptionDarkColorPalette.accentRed}
                  iconName="trash"
                  onClick={onDelete}
                />
              </div>
              <div className="inc-flex-column">
                <IncButton
                  color="link"
                  onClick={toggleFilters}
                >
                  Advanced config
                  <IncFaIcon
                    className="paddingLt6"
                    iconName={showFilters ? "chevron-up" : "chevron-down"}
                  />
                </IncButton>
                {showFilters && (
                  <ConditionsBuilder
                    fieldPickerContext={fieldPickerContext}
                    fieldTypes={["userServiceField"]}
                    filters={filters}
                    onChange={onFiltersChange}
                  />
                )}
              </div>
            </div>
          );
          return labelComp;
        })}
      </>
    ),
    [
      metricPredicates,
      pluralizedEventTypeList,
      toggleFilters,
      showFilters,
      fieldPickerContext,
      onOperatorChange,
      onChangeFilters,
      onValueChange,
      onChangeEventType,
      onDurationChange,
      removeClick
    ]
  );

  const root = useMemo(
    () => (
      <div className="inc-flex-column">
        {filterComponents}
        <IncButton
          className="marginTp8"
          color="link"
          onClick={onAddClick}
        >
          Add
          <IncFaIcon
            className="marginLt6"
            iconName="circle-plus"
          />
        </IncButton>
      </div>
    ),
    [filterComponents, onAddClick]
  );

  return (
    <div className="usage-events-picker">
      {isCollapsible ? (
        <IncCollapsible
          collapseIconName="chevron-circle-up"
          content={root}
          expandIconName={"chevron-circle-down"}
          header="Usage"
        />
      ) : (
        root
      )}
    </div>
  );
};
