import { IncButton, IncSmartText, useIsVisibleOnce } from "@inception/ui";
import React, { CSSProperties, FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CypressConstants } from "@bicycle/tests";
import { dateTime, getURLwithPreviewHost, useFetchOpConfigs, useInceptionRoute } from "../../core";
import { alertApiService, OverallMappingStatus } from "../../services/api/explore";
import { AlertEventRecord, AlertStatus, OpSchema, useFetchOpSchema } from "../../services/api/operationalise";
import { logger } from "../../core/logging/Logger";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { VerticallyCenteredRow } from "../flex-components";
import { InsightStatsWithDataFetch } from "./InsightStatsWithDataFetch";
import { InsightPreviewGraph, LoadingPills } from "./PreviewGraph";
import { InsightStats } from "./InsightStats";

export type IncidentCardSource =
  | {
      incident: AlertEventRecord;
      type: "incident";
    }
  | {
      opConfigId: string;
      incidentId: string;
      type: "incidentId";
    };

interface Props {
  title?: string;
  description?: string;
  noCard?: boolean;
  skipDrilldown?: boolean;

  source: IncidentCardSource;
  generateDemoData?: boolean;

  asSparkline?: boolean;
  hideIncidentViz?: boolean;
  opSchema?: OpSchema;
  isMetaDataLoading?: boolean;
  hideTimeRange?: boolean;

  extActions?: JSX.Element | JSX.Element[];
  titleActionsJsx?: JSX.Element | JSX.Element[];
  children?: JSX.Element | JSX.Element[];
}

export const IncidentCardV2: FC<Props> = props => {
  const {
    title,
    source,
    generateDemoData: pGenerateDemoData = false,
    noCard = false,
    extActions,
    titleActionsJsx,
    skipDrilldown = false,
    asSparkline = false,
    hideIncidentViz = false,
    opSchema: defOpSchema,
    isMetaDataLoading = false,
    hideTimeRange = false,
    children
  } = props;

  const isLoadingRef = useRef(true);
  const errorRef = useRef("");

  const [incident, setIncident] = useState<AlertEventRecord>();
  useEffect(() => {
    if (source.type === "incident") {
      isLoadingRef.current = false;
      setIncident(getModifiedIncident(source.incident));
    } else {
      const { incidentId } = source;

      let incident: AlertEventRecord;
      alertApiService
        .getIncidentDetails(incidentId, pGenerateDemoData)
        .then(response => {
          const { data, error, message } = response;

          if (error) {
            throw Error(message);
          } else {
            incident = getModifiedIncident(data as unknown as AlertEventRecord);
          }
        })
        .catch(err => {
          errorRef.current = err;
          logger.error("IncidentCardV2", "Error fetching incident", err);
          incident = {} as AlertEventRecord;
        })
        .finally(() => {
          isLoadingRef.current = false;
          setIncident(incident);
        });
    }
  }, [pGenerateDemoData, source]);

  const { actions, startTimeMillis, endTimeMillis, status } = incident || {};

  const opConfigId = source.type === "incidentId" ? source.opConfigId : incident?.opConfigId;
  const incidentId = source.type === "incidentId" ? source.incidentId : incident?.id;

  const ref = useRef<HTMLDivElement>();
  const { wasVisibleOnce } = useIsVisibleOnce(ref);

  const isActive = status === AlertStatus.active;

  const { durationLabel, timeRangeLabel } = useMemo(() => {
    let durationLabel = "";
    let timeRangeLabel = "";

    if (isActive) {
      const durationStr = dateTime(startTimeMillis).fromNow(true);
      durationLabel = `Last ${durationStr}`;
      timeRangeLabel = timeRangeUtils.getGroupLabels(Number(startTimeMillis), Number(endTimeMillis));
    } else {
      durationLabel = dateTime(startTimeMillis).from(dateTime(endTimeMillis), true);
      timeRangeLabel = timeRangeUtils.getGroupLabels(Number(startTimeMillis), Number(endTimeMillis));
    }

    return {
      durationLabel,
      timeRangeLabel
    };
  }, [isActive, startTimeMillis, endTimeMillis]);

  const { navigate } = useInceptionRoute();

  const drilldownUrl = actions?.[0]?.actionUrl;
  const navigateToDrilldown = useCallback(() => {
    if (drilldownUrl) {
      navigate(getURLwithPreviewHost(drilldownUrl), { newTab: true });
    }
  }, [drilldownUrl, navigate]);

  const { data: opSchema, error, isError, isFetching, refetch, setData } = useFetchOpSchema(opConfigId, incidentId);

  const isLoading = isLoadingRef.current || isMetaDataLoading;

  useEffect(() => {
    if (wasVisibleOnce && !isLoading && !defOpSchema) {
      refetch();
    }
  }, [defOpSchema, isLoading, refetch, wasVisibleOnce]);

  useEffect(() => {
    if (!isMetaDataLoading && defOpSchema) {
      setData(() => defOpSchema);
    }
  }, [defOpSchema, isMetaDataLoading, setData]);

  useEffect(() => {
    if (isError) {
      logger.error("IncidentCardV2", "Error fetching opSchema", error);
    }
  }, [error, isError]);

  const {
    data: opConfigs,
    error: opConfigsError,
    isError: isOpConfigsError,
    isFetching: isOpConfigsFetching,
    refetch: fetchOpConfigs
  } = useFetchOpConfigs(null, opConfigId, null, null, null, true);

  useEffect(() => {
    if (opConfigId && wasVisibleOnce && !isLoading) {
      fetchOpConfigs();
    }
  }, [fetchOpConfigs, isLoading, opConfigId, wasVisibleOnce]);

  useEffect(() => {
    if (isOpConfigsError) {
      logger.error("InsightFeedCard", "Error fetching opConfig", opConfigsError);
    }
  }, [isOpConfigsError, opConfigsError]);

  const { opCreationConfig } = opConfigs?.find(opConfig => opConfig.op10zeId === opConfigId) || {};
  const { opMappingStatus } = opCreationConfig || {};
  const isOpMappingPending =
    opMappingStatus?.queryMappingStatus?.isIncomplete ||
    opMappingStatus?.queryMappingStatus?.overallStatus === OverallMappingStatus.PENDING;

  const actionsExist = !skipDrilldown || Boolean(extActions);

  const otherContentHeight = (actionsExist ? 34 : 0) + (title ? 20 : 0);
  const contentStyle = useMemo<CSSProperties>(
    () => ({
      maxHeight: `calc(100% - ${otherContentHeight}px)`,
      overflowY: "auto",
      paddingRight: "0px"
    }),
    [otherContentHeight]
  );

  const shouldShowLoader = isLoading || isOpConfigsFetching;
  const generateDemoData = pGenerateDemoData || isOpMappingPending;

  const graphWrapperStyle = useMemo<CSSProperties>(() => {
    const numCols = 1 + (Array.isArray(children) ? children.filter(Boolean).length : children ? 1 : 0);
    return {
      display: "grid",
      gridTemplateColumns: `repeat(${numCols}, minmax(0, 1fr))`
    };
  }, [children]);

  return (
    <div
      className="incident-card-v2 height-100"
      data-no-card={noCard}
      ref={ref}
    >
      <div
        className="incident-card-v2--content inc-flex-column"
        style={contentStyle}
      >
        <div className="inc-flex-row flex-gap-16 p-a-1">
          <div className="inc-flex-column flex-gap-8 width-100">
            <VerticallyCenteredRow className="flex-gap-12 width-100">
              {Boolean(title) && (
                <IncSmartText
                  className="inc-text-body-medium"
                  data-show-loader={shouldShowLoader}
                  text={title}
                />
              )}
              {!hideTimeRange && (
                <IncSmartText
                  className="inc-text-subtext inc-text-color-secondary marginLtAuto width-25 text-right"
                  text={timeRangeLabel}
                />
              )}
              {titleActionsJsx}
            </VerticallyCenteredRow>

            <InsightStatsWithDataFetch
              durationLabel={durationLabel}
              generateDemoData={generateDemoData}
              incident={incident}
              isLoading={shouldShowLoader}
            />
          </div>
        </div>
        <div
          className="preview-graph-wrapper flex-gap-32"
          style={graphWrapperStyle}
        >
          {!hideIncidentViz && (
            <InsightPreviewGraph
              asSparkline={asSparkline}
              generateDemoData={generateDemoData}
              incident={incident}
              isFetching={isFetching || shouldShowLoader}
              opSchema={opSchema}
            />
          )}
          {children}
        </div>
      </div>

      {actionsExist && (
        <VerticallyCenteredRow
          className="flex-gap-10 padding12"
          style={footerStyle}
        >
          {!skipDrilldown && (
            <IncButton
              className="width-fit-content"
              color="secondary-blue"
              data-cy={CypressConstants.features.Triage.attributes.drilldownTriageButton}
              onClick={navigateToDrilldown}
            >
              Drilldown
            </IncButton>
          )}

          {extActions}
        </VerticallyCenteredRow>
      )}
    </div>
  );
};

const footerStyle: React.CSSProperties = {
  background: "rgba(57, 172, 255, 0.05)",
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8
};

const loadingContentStyle: React.CSSProperties = {
  maxHeight: "calc(100% - 34px)",
  overflowY: "auto",
  paddingRight: "0px"
};

type LIProps = {
  skipActions?: boolean;
};

export const LoadingIncidentCard = memo(({ skipActions }: LIProps) => (
  <div
    className="incident-card-v2 height-100"
    data-no-card={true}
  >
    <div
      className="incident-card-v2--content inc-flex-column"
      style={loadingContentStyle}
    >
      <div className="inc-flex-row flex-gap-16 p-a-1">
        <div className="inc-flex-column flex-gap-8">
          <IncSmartText
            className="inc-text-body-medium inc-flex-grow"
            data-show-loader={true}
            text="Title Text for incedent card"
          />

          <InsightStats
            durationLabel=""
            insightMembershipData={null}
            isLoading
          />
        </div>
        <IncSmartText
          className="inc-text-subtext inc-text-color-secondary marginLtAuto width-25"
          data-show-loader={true}
          style={{
            height: 20
          }}
          text={"Oct 11, 23"}
        />
      </div>
      <div className="preview-graph-wrapper">
        <div className="preview-graph">
          <LoadingPills />
          <div
            className="widget-container"
            data-show-loader="true"
          />
        </div>
      </div>
    </div>
    {!skipActions && (
      <VerticallyCenteredRow
        className="flex-gap-10 padding12"
        style={{ background: "rgba(57, 172, 255, 0.05)" }}
      >
        <IncButton
          className="width-fit-content"
          color="secondary-blue"
          data-show-loader={true}
        >
          Drilldown
        </IncButton>
      </VerticallyCenteredRow>
    )}
  </div>
));

export const getModifiedIncident = (incident: AlertEventRecord) => ({
  ...incident,
  endTimeMillis:
    String(incident.endTimeMillis) === "-1" ? new Date().valueOf() : parseInt(String(incident.endTimeMillis), 10),
  startTimeMillis: parseInt(String(incident.startTimeMillis), 10)
});
