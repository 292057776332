import React, { FC, memo } from "react";
import { IncModal } from "@inception/ui";
import { css } from "emotion";
import { BizImpactConfig } from "../../services/api/types";
import { BizImpactConfigEditor } from "./BizImpactConfigEditor";

interface Props {
  bizImpactConfig: BizImpactConfig;
  onChange: (bizImpactConfig: BizImpactConfig) => void;
  onCancel: () => void;
}

export const BizImpactConfigEditorModal: FC<Props> = memo(props => {
  const { bizImpactConfig, onCancel, onChange } = props;

  return (
    <IncModal
      className={modalClassName}
      contentClassName="paddingBt24"
      onClose={onCancel}
      show
      titleText="Override for Special Day"
    >
      <BizImpactConfigEditor
        bizImpactConfig={bizImpactConfig}
        onChange={onChange}
      />
    </IncModal>
  );
});

const modalClassName = css`
  max-height: 80vh !important;
  width: 660px !important;

  .react-datepicker {
    width: 100%;
    justify-content: center;
    display: flex;
  }
`;
