import { cloneDeep, extend, isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { generateId, logger, TimeRange, useForceUpdate, useRefState } from "../../../../core";
import {
  compareUSFields,
  exploreApiService,
  UserServiceFieldSlice,
  WidgetResponseDTO
} from "../../../../services/api/explore";
import { ENTITY_TAG, FieldPickerUtils } from "../../../../utils";
import { CatalogWidgetUtils } from "../CatalogWidgetUtils";
import { CatalogWidgetImpl } from "../models";
import { useInitialiseFields } from "./useInitialiseFields";

export const useSaveWidgetConfigRef = (widget: CatalogWidgetImpl, timeRange: TimeRange) => {
  const {
    widgetConfigRefId,
    queryConfig,
    entityType,
    userServiceId,
    properties,
    generateDemoData,
    title,
    bizEntityFieldName,
    id: widgetId,
    renderMode,
    hashLabelsOnSave: hashLabels = false,
    disableUpdate,
    demoDataParams
  } = widget;

  const widgetRef = useRefState(widget);

  const forceUpdate = useForceUpdate();

  const { sourceQueryConfig } = queryConfig;
  const configMetricId =
    sourceQueryConfig.queryType === "widgetConfig"
      ? sourceQueryConfig.metricId
      : sourceQueryConfig.queryType === "useCase"
        ? sourceQueryConfig.dataQueryConfig?.query?.sliceSpec?.metricId
        : null;

  const metricId = useMemo(() => configMetricId || generateId(), [configMetricId]);

  const [isInitialising, setIsInitialising] = useState(true);
  const initialisationErrorRef = useRef<string>("");
  const initialisationCycleDoneRef = useRef(false);
  const [widgetResponseDTO, setWidgetResponseDTO] = useState<WidgetResponseDTO>();

  const eventTypeId =
    userServiceId ||
    (sourceQueryConfig.queryType === "widgetConfig"
      ? sourceQueryConfig.widgetResponse?.widgetConfig?.userServiceEntityId
      : null);
  const {
    fieldPickerModel,
    initialiseInProgress,
    initialiseSuccess,
    initialiseError,
    isValidWidget,
    demoParams: qcDemoParams
  } = useInitialiseFields(entityType, eventTypeId, queryConfig, timeRange, generateDemoData);

  const demoParams = useMemo(() => demoDataParams || qcDemoParams, [demoDataParams, qcDemoParams]);

  useEffect(() => {
    if (!initialiseSuccess && !initialiseInProgress) {
      setIsInitialising(false);
    }
  }, [initialiseInProgress, initialiseSuccess]);

  useEffect(() => {
    if (!initialiseInProgress && isValidWidget && initialiseSuccess) {
      const { aggregateTags: pAggregateTags, aggregator: pAggregator } = properties;

      let aggregator = pAggregator;
      let aggregateTags = pAggregateTags;

      if (!aggregator) {
        const { defaultAgg } = CatalogWidgetUtils.getAggregators(queryConfig);
        aggregator = defaultAgg.value;
      }

      if (!aggregateTags) {
        const defSlices = CatalogWidgetUtils.getDefaultSlicesBasedOnQueryConfig(queryConfig, {
          tagName: ENTITY_TAG,
          userServiceField: null
        });
        aggregateTags = defSlices.map(({ tagName }) => tagName);
      }

      const implicitSliceField = fieldPickerModel.getEntityUSField(bizEntityFieldName);
      const usfSlices: UserServiceFieldSlice[] = [];
      aggregateTags.forEach(tagName => {
        const usField =
          tagName === ENTITY_TAG
            ? implicitSliceField
            : fieldPickerModel
                .getAllUserServiceFields()
                .find(usfm => FieldPickerUtils.getPromSanitizedUSFName(usfm.userServiceField) === tagName)
                ?.userServiceField;
        if (!usField) {
          logger.warn("CatalogWidget", "No USField found for tag", {
            widgetId,
            tagName,
            fieldPickerModel
          });
        } else {
          const canAddSlice = tagName !== ENTITY_TAG ? !compareUSFields(usField, implicitSliceField) : true;
          if (canAddSlice) {
            usfSlices.push({
              tagName,
              userServiceField: usField
            });
          }
        }
      });

      const nWidgetResponseDTO = CatalogWidgetUtils.getWidgetResponseDTO(
        entityType,
        userServiceId,
        aggregator,
        queryConfig,
        usfSlices,
        metricId,
        title
      );
      setWidgetResponseDTO(nWidgetResponseDTO);
    }
  }, [
    bizEntityFieldName,
    entityType,
    fieldPickerModel,
    forceUpdate,
    initialiseInProgress,
    initialiseSuccess,
    isValidWidget,
    metricId,
    properties,
    queryConfig,
    title,
    userServiceId,
    widgetId
  ]);

  const updateWidgetSourceConfig = useCallback(
    async (widgetResponseDTO: WidgetResponseDTO) => {
      const widget = widgetRef.current;

      // Preserve the sourceConfig in the widget
      widget.queryConfig.rawSourceQueryConfig =
        widget.queryConfig.rawSourceQueryConfig ||
        (widget.queryConfig.sourceQueryConfig.queryType !== "widgetConfig"
          ? cloneDeep(widget.queryConfig.sourceQueryConfig)
          : null);

      const widgetConfigRefId = widgetResponseDTO.widgetId;
      const metricId = Object.keys(widgetResponseDTO.widgetConfig?.dataDefinition?.metrics || {})[0];

      if (!widgetResponseDTO.querySchema?.querySchema?.length) {
        try {
          const { data, error, message } = await exploreApiService.getQuerySchemaForDraftWidgetConfig(
            widgetResponseDTO.widgetConfig
          );

          if (error) {
            logger.error("CatalogWidget", "Error updating widget source config with query schema", message);
            initialisationErrorRef.current = "Error initialising widget configuration";
          } else {
            widgetResponseDTO.querySchema = {
              querySchema: data
            };
          }
        } catch (err) {
          logger.error("CatalogWidget", "Error updating widget source config with query schema", err);
          initialisationErrorRef.current = "Error initialising widget configuration";
        }
      }

      if (widget.queryConfig.sourceQueryConfig.queryType !== "widgetConfig") {
        widget.queryConfig.sourceQueryConfig = {
          queryType: "widgetConfig",
          metricId,
          widgetResponse: {
            ...widgetResponseDTO,
            widgetId: widgetConfigRefId
          }
        };
      } else {
        widget.queryConfig.sourceQueryConfig.widgetResponse = widgetResponseDTO;
        if (!widget.queryConfig.sourceQueryConfig.metricId) {
          widget.queryConfig.sourceQueryConfig.metricId = metricId;
        }
      }

      widget.widgetConfigRefId = widgetConfigRefId;
      initialisationCycleDoneRef.current = true;
      setIsInitialising(false);
    },
    [widgetRef]
  );

  const saveOrUpdateWidgetConfig = useCallback(
    async (widgetResponseDTO: WidgetResponseDTO) => {
      if (renderMode === "viz-only" || renderMode === "adhoc-view" || disableUpdate) {
        updateWidgetSourceConfig(widgetResponseDTO);
      } else {
        try {
          const { widgetConfig: widgetConfigDto, widgetId } = widgetResponseDTO;

          if (widgetId) {
            logger.info("CatalogWidget", "Skipping save op for existing widget config", widgetResponseDTO);
            updateWidgetSourceConfig(widgetResponseDTO);
            return;
          }

          const { data, error, message } = await exploreApiService.saveWidgetConfig(widgetConfigDto, {}, hashLabels);

          if (error) {
            logger.error("CatalogWidget", "Error saving widget config: ", message);
            initialisationErrorRef.current = `Error saving widget config: ${message}`;
            updateWidgetSourceConfig(widgetResponseDTO);
          } else {
            widgetResponseDTO.widgetId = data.widgetId;
            widgetResponseDTO.querySchema = data.querySchema || widgetResponseDTO.querySchema;

            updateWidgetSourceConfig(widgetResponseDTO);
          }
        } catch (err) {
          logger.error("CatalogWidget", "Error saving widget config: ", err);
          initialisationErrorRef.current = `Error saving widget config: ${err}`;
          updateWidgetSourceConfig(widgetResponseDTO);
        }
      }
    },
    [disableUpdate, hashLabels, renderMode, updateWidgetSourceConfig]
  );

  const getAndSaveWidgetConfig = useCallback(
    async (widgetResponseDTO: WidgetResponseDTO) => {
      const { widgetId } = widgetResponseDTO;

      try {
        const eventTypeId = entityType ? "" : userServiceId;
        const { data, error, message } = await exploreApiService.getWidgetConfig(eventTypeId, entityType, widgetId);

        if (error) {
          logger.error("CatalogWidget", "Error fetching widget config: ", message);
          initialisationErrorRef.current = `Error saving widget config: ${message}`;
          saveOrUpdateWidgetConfig(widgetResponseDTO);
        } else {
          if (!widgetResponseDTO.widgetConfig && widgetResponseDTO.widgetId) {
            widgetResponseDTO.widgetConfig = data.widgetConfig;
          }

          if (data?.widgetConfig?.labels?.querySource === "userServiceFieldWidget") {
            widgetResponseDTO.widgetId = "";
            widgetResponseDTO.widgetConfig.labels.querySource = "catalogWidget";
            saveOrUpdateWidgetConfig(widgetResponseDTO);
          } else {
            if (isEmpty(data?.widgetConfig?.dataDefinition?.metrics)) {
              // Copying important information from existing configuration so we don't miss the labels and name
              widgetResponseDTO.version = data?.version || widgetResponseDTO.version;
              widgetResponseDTO.widgetConfig.name = data?.widgetConfig?.name || widgetResponseDTO.widgetConfig.name;
              widgetResponseDTO.widgetConfig.labels =
                data?.widgetConfig?.labels || widgetResponseDTO.widgetConfig.labels;

              saveOrUpdateWidgetConfig(widgetResponseDTO);
            } else {
              extend(widgetResponseDTO, data || {});
              updateWidgetSourceConfig(widgetResponseDTO);
            }
          }
        }
      } catch (err) {
        logger.error("CatalogWidget", "Error saving widget config: ", err);
        initialisationErrorRef.current = `Error saving widget config: ${err}`;
        updateWidgetSourceConfig(widgetResponseDTO);
      }
    },
    [entityType, saveOrUpdateWidgetConfig, updateWidgetSourceConfig, userServiceId]
  );

  useEffect(() => {
    if (widgetResponseDTO && !initialisationCycleDoneRef.current) {
      if (widgetConfigRefId) {
        widgetResponseDTO.widgetId = widgetConfigRefId;
        getAndSaveWidgetConfig(widgetResponseDTO);
      } else {
        saveOrUpdateWidgetConfig(widgetResponseDTO);
      }
    }
  }, [getAndSaveWidgetConfig, saveOrUpdateWidgetConfig, widgetConfigRefId, widgetResponseDTO]);

  return {
    isInitialising,
    initialisationError: initialisationErrorRef.current || initialiseError,
    demoParams,
    isValidWidget
  };
};
