import { IncButton, IncSmartText, IncModal } from "@inception/ui";
import React, { useState, useCallback, useMemo } from "react";
import { VerticallyCenteredRow } from "../flex-components";

export interface DistributionBarProp {
  title: string;
  value: number;
}

type Props = {
  values: DistributionBarProp[];
  limit?: number;
};

const DistributionBars = (props: Props) => {
  const { values, limit } = props;
  const [showAll, setShowAll] = useState(false);
  const barsLimit = limit ? limit : values.length;
  const sortedValues = useMemo(() => values.sort((a, b) => b.value - a.value), [values]);

  const openShowAll = useCallback(() => setShowAll(true), []);
  const closeShowAll = useCallback(() => setShowAll(false), []);

  const distributionBars = useCallback(
    (showAll?: boolean) =>
      sortedValues?.map((data, key) => {
        if (key < barsLimit || showAll) {
          // const color = getIntegrationTagColor(key);
          return (
            <div
              className="distribution-row inc-flex-row marginBt16"
              key={key}
            >
              <IncSmartText
                className="title inc-text-subtext-medium"
                text={data.title || "_missing"}
              />
              <VerticallyCenteredRow className="value">
                <progress
                  className="distribution-bars-progress-bar inc-text-subtext"
                  max="100"
                  value={Math.round(data.value * 100) / 100}
                ></progress>
                <span className="inc-text-element marginLt4">{Math.round(data.value)}%</span>
              </VerticallyCenteredRow>
            </div>
          );
        }

        return null;
      }),
    [barsLimit, sortedValues]
  );

  return (
    <div className="distribution-progress-bars inc-flex-column">
      {distributionBars().filter(Boolean)}
      {barsLimit < sortedValues.length ? (
        <IncButton
          color="link"
          onClick={openShowAll}
        >
          View More
        </IncButton>
      ) : null}
      <IncModal
        onClose={closeShowAll}
        show={showAll}
        size="md"
        titleText="Distribution"
      >
        <div className="distribution-progress-bars distribution-modal-height inc-flex-column">
          {distributionBars(true).filter(Boolean)}
        </div>
      </IncModal>
    </div>
  );
};

export default DistributionBars;
