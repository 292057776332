import React, { FC, memo, useCallback, useEffect, useMemo } from "react";
import { OpCreationConfig, OpCreationFragmentType } from "../../services/api/operationalise";
import { OpCreationFragmentV2Renderer } from "../fragments";
import { OpCreationFragmentEditProps } from "../types";
import { useToggleState } from "../../core";
import { DemoDataParams } from "../../services/api/explore";

interface Props {
  opCreationConfig: OpCreationConfig;
  opCreationFragmentType: OpCreationFragmentType;
  onOpCreationConfigChange?: (opCreationConfig: OpCreationConfig) => void;
  readOnly?: boolean;

  demoDataParams?: DemoDataParams;
  children?: JSX.Element;
  titleActionsJsx?: JSX.Element;
  onClose?: () => void;
  hideSummary?: boolean;
  summaryJsx?: JSX.Element;
  summaryClassName?: string;

  allowCustomFragmentEdit?: boolean;
  onCustomFragmentEdit?: (pathToEdit: string) => void;
  showEditorInModal?: boolean;
}

export const OpRecipeDetails: FC<Props> = memo(props => {
  const {
    opCreationConfig,
    opCreationFragmentType,
    children,
    titleActionsJsx,
    onOpCreationConfigChange,
    demoDataParams,
    readOnly = false,
    onClose,
    hideSummary = true,
    summaryJsx,
    summaryClassName = "",
    showEditorInModal = false,
    allowCustomFragmentEdit = false,
    onCustomFragmentEdit: defOnCustomFragmentEdit
  } = props;

  const { isOpen: isEditModeActive, open: activateEditMode, close: deactivateEditMode } = useToggleState();

  const editProps = useMemo<OpCreationFragmentEditProps>(
    () =>
      onOpCreationConfigChange && !readOnly
        ? {
            opCreationConfig,
            onOpCreationConfigChange: (opCreationConfig: OpCreationConfig) => {
              onOpCreationConfigChange(opCreationConfig);
              deactivateEditMode();
            },
            isEditModeActive,
            onSwitchToEditMode: activateEditMode,
            onSwitchToViewMode: deactivateEditMode,
            demoDataParams
          }
        : null,
    [
      activateEditMode,
      deactivateEditMode,
      demoDataParams,
      isEditModeActive,
      onOpCreationConfigChange,
      opCreationConfig,
      readOnly
    ]
  );

  useEffect(() => {
    deactivateEditMode();
  }, [deactivateEditMode, opCreationFragmentType]);

  const { opCreationFragmentV2List } = opCreationConfig || {};
  const fragmentToViewIdx = opCreationFragmentV2List?.opFragments?.findIndex(
    fragment => fragment.type === opCreationFragmentType
  );
  const fragmentToView = opCreationFragmentV2List?.opFragments?.[fragmentToViewIdx];
  const fragmentPath = `opFragments[${fragmentToViewIdx}]`;

  const onCustomFragmentEdit = useCallback(
    (pathToEdit: string) => {
      defOnCustomFragmentEdit(`opCreationFragmentV2List.${pathToEdit}`);
    },
    [defOnCustomFragmentEdit]
  );

  return (
    <>
      {Boolean(fragmentToView) && (
        <div className="operationalize-recipe--details height-100">
          {titleActionsJsx}
          <OpCreationFragmentV2Renderer
            allowCustomFragmentEdit={allowCustomFragmentEdit}
            editProps={editProps}
            fragmentPath={fragmentPath}
            hideSummary={hideSummary}
            onClose={onClose}
            onCustomFragmentEdit={onCustomFragmentEdit}
            opCreationFragment={fragmentToView}
            showEditorInModal={showEditorInModal}
            summaryClassName={summaryClassName}
            summaryJsx={summaryJsx}
          >
            {children}
          </OpCreationFragmentV2Renderer>
        </div>
      )}
    </>
  );
});
