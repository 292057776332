import React, { useCallback, useEffect } from "react";
import ReactFlow, {
  Controls,
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
  FitViewOptions,
  ReactFlowProps
} from "react-flow-renderer";
import { ConnectionLineStyle, DefaultEdgeOptions, EdgeTypes, NodeTypes } from "./Config";
import { NodeDataProps, WorkflowConfig } from "./types";
import { getInitialNodesAndEdges } from "./computeNodesAndEdges";

type Props = WorkflowConfig & {
  noColors?: boolean;
  colorsMap?: Record<string, string>;

  fitView?: boolean;
  minZoom?: number;
  maxZoom?: number;
  onNodeClick?: (node: NodeDataProps) => void;
};

export const IncSequenceDiagram: React.FC<Props> = props => {
  const {
    id,
    connections,
    name,
    nodes: pNodes,
    noColors,
    colorsMap,
    fitView,
    minZoom = 0.1,
    maxZoom = 2,
    onNodeClick
  } = props;

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    const { initialEdges, initialNodes } = getInitialNodesAndEdges(pNodes, connections, noColors, colorsMap);

    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [colorsMap, connections, noColors, pNodes, setEdges, setNodes]);

  const onConnect = useCallback((params: any) => {
    console.log(params);
  }, []);

  const handleNodeClick = useCallback<ReactFlowProps["onNodeClick"]>(
    (ev, node) => {
      const nodeDataProps = node.data;
      if (onNodeClick) {
        onNodeClick(nodeDataProps as NodeDataProps);
      }
    },
    [onNodeClick]
  );

  return (
    <>
      <div
        className="sequence-container"
        data-name={name}
        data-node-click={Boolean(onNodeClick)}
        id={id}
      >
        <ReactFlowProvider>
          <ReactFlow
            connectionLineStyle={ConnectionLineStyle}
            defaultEdgeOptions={DefaultEdgeOptions}
            edgeTypes={EdgeTypes}
            edges={edges}
            fitView={fitView}
            fitViewOptions={fitViewOptions}
            maxZoom={maxZoom}
            minZoom={minZoom}
            nodeTypes={NodeTypes}
            nodes={nodes}
            nodesDraggable={false}
            onConnect={onConnect}
            onEdgesChange={onEdgesChange}
            onNodeClick={handleNodeClick}
            onNodesChange={onNodesChange}
          >
            <Controls showInteractive={false} />
          </ReactFlow>
        </ReactFlowProvider>
      </div>
    </>
  );
};

const fitViewOptions: FitViewOptions = {
  minZoom: 0.1,
  maxZoom: 2,
  includeHiddenNodes: true
};
