import { isEmpty } from "lodash";
import React, { FC, memo } from "react";
import { SimpleEntityNameRenderer, EntityList, RenderWithMorePopper } from "../../../../components";

type RCProps = {
  entityTypeId: string;
  entityIds: string[];
  lookupData: Record<string, string>;
};

export const RenderRowCellWithMore: FC<RCProps> = memo(props => {
  const { entityTypeId, entityIds, lookupData } = props;

  if (isEmpty(entityIds)) {
    return null;
  }
  const maxEntries = 3;
  const totalEntries = entityIds.length;
  const rowValues = [...entityIds];

  const toShow = rowValues.length > maxEntries ? rowValues.splice(0, maxEntries) : rowValues;

  const valueNodes = toShow.map((v: string, idx: number) => (
    <SimpleEntityNameRenderer
      hideIcon
      id={v}
      key={`${idx}-alert-col-${entityTypeId}-${entityIds.length}`}
      name={lookupData[v] || v}
    />
  ));

  const more = (
    <EntityList
      entityTypeName={entityTypeId}
      initialData={{
        entityIds: rowValues,
        entityLookupData: lookupData
      }}
      searchable={true}
      showEntityInfoOnHover={false}
    />
  );

  return (
    <RenderWithMorePopper
      delimiter=", "
      maxEntries={3}
      moreDetailsComponent={more}
      skipMoreInMoreStr
      totalEntries={totalEntries}
    >
      {valueNodes}
    </RenderWithMorePopper>
  );
});
