import React, { FC, useMemo, useCallback, useState, useEffect, memo } from "react";
import { IncButton, IncFaIcon, IncRTable, IncRTableProps, TableDataItem, IncSkeleton } from "@inception/ui";
import { clone, filter, forEach, fromPairs, isMatch, map, size, uniq, cloneDeep } from "lodash";
import { transparentize } from "polished";
import ReactDOMServer from "react-dom/server";
import { DataFrame, DataType, useRefState } from "../../../../../../core";
import {
  CatalogVizRendererProps,
  useCommonRendererFunctionality,
  getMetricIdFromDataFrameId,
  getFormattedValue
} from "../common";
import { getDownSampleInterval } from "../../../../../../utils/EntityUtils";
import {
  ColumnDef,
  ColumnDefList,
  LimitSpecFunction,
  OverTagPostAgg,
  SheetDef,
  WorkBookDef
} from "../../../../../../services/api/explore/types/common";
import { ExploreQueryType } from "../../../../../../services/datasources/explore/types";
import { DataSortSpec, DownloadOptions } from "../../../../../../services/api/explore/types/exploreTypes";
import { ENTITY_TAG } from "../../../../../../utils/MetricNameUtils";
import kbn from "../../../../../../services/datasources/core/kbn";
import { downloadBlobFile, getPromSanitizedName, getTagNameFromFieldName } from "../../../../../../utils";
import { SelectorTag, Slice, exploreApiService, SliceSpec } from "../../../../../../services/api/explore";
import { WidgetCustomAction } from "../../../../types";
import LoadingSpinner from "../../../../../../components/Loading/Loading";
import { ChangeMetric } from "../../../../../../biz-entity";
import timeRangeUtils from "../../../../../../utils/TimeRangeUtils";
import { CatalogWidgetFetchDataPayload } from "../../../types";
import { MetricInfo } from "../../../../../../services/datasources/prometheus/core/types";
import { AUTO_DS_INTERVAL } from "../../../../utils";
import {
  ALL_ENTRIES_PAGE_SIZE,
  AUTO_PAGE_SIZE_VALUE
} from "../../../maximize-view/components/Customization/components/TableUICustomization";
import { TableProperties } from "../../../models";
import { FetchResultMetaProps, useGetResultMetaAndDQConfig } from "../../../hooks";
import ChangeEventDrawer from "./changeEventDrawer";

import { DownloadCSVModal } from "./DownloadCSVModal";
import {
  Datum,
  getMetricIdForTableColumn,
  getTableColumns,
  getTableData,
  getValueAccessorForTableColumn,
  isMetricColumn,
  isMetricCompareColumn,
  isRowMatchingConditions
} from "./utils";

type ExpandedTableRow = {
  row: TableDataItem;
  slices: string[];
  filterTags: SelectorTag[];
  level: number;
  rowId: string;
};

const LoadingComponent = memo(() => (
  <IncSkeleton.Input
    active={true}
    size="small"
    style={{
      height: 16,
      width: "100%"
    }}
  />
));

export const TableRenderer: FC<CatalogVizRendererProps> = props => {
  const {
    dataType: defDataType,
    dataTypeByMetricId,
    currencyType,
    entityTypeName,
    aggregatedTags,
    displayAggregatedTags,
    properties: widgetProperties,
    timeRange,
    compareTimeRange,
    dataFetchPayload,
    childrenDataFetchPayload,
    eventFilters,
    entityFilters,
    cohortFilters,
    widgetResponseDTO,
    userServiceId,
    downsample,
    onCustomActionsChange,
    onSaveCustomHeaderMap,
    renderMode,
    postAggProjections: postAggProjections,
    metricId,
    onQueryConfigChange,
    edit,
    widgetTitle,
    fetchResultMetaProps: pFetchResultMetaProps,
    onAddAdhocEventFilter
  } = props;

  const fetchResultMetaProps = useMemo<FetchResultMetaProps>(() => {
    const nProps = clone(pFetchResultMetaProps);
    nProps.id = `${nProps.id}-table-renderer`;
    return nProps;
  }, [pFetchResultMetaProps]);

  const { resultMeta, refetch, isResultMetaFetching } = useGetResultMetaAndDQConfig(fetchResultMetaProps, true);

  const compareStr = useMemo(() => {
    const fromMillis = timeRange.from.valueOf();
    const compareFromMillis = compareTimeRange.from.valueOf();
    return timeRangeUtils.getCompareStringFromTimeShiftMillis(fromMillis - compareFromMillis);
  }, [compareTimeRange.from, timeRange.from]);

  const showDownloadDataAction = renderMode === "viz-only";

  const [showDownloadCSV, setShowDownloadCSV] = useState(false);

  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const openDownloadCSVModel = useCallback(() => setShowDownloadCSV(true), []);
  const closeDownloadCSVModal = useCallback(() => setShowDownloadCSV(false), []);
  const widgetName = widgetResponseDTO.widgetConfig.name;

  const fileName = `${widgetName}`.replace("/", "-");

  const { table: tableProperties, dataTypeCustomisation } = widgetProperties;

  const hideCompareData = postAggProjections?.length === 1 && postAggProjections[0] === "current";

  const { changeMetric: pChangeMetric = "current", limitSpec, groupBy, columnOrder } = tableProperties || {};

  const [sort, setSort] = useState<any>({
    accessor: getValueAccessorForTableColumn(metricId),
    order: limitSpec?.function || "top" === "top" ? "desc" : "asc"
  });

  useMemo(() => {
    if (dataFetchPayload && childrenDataFetchPayload && groupBy?.length) {
      const tags = [groupBy[0]];
      const updateTags = (sliceSpec: SliceSpec) => {
        const postAgg: OverTagPostAgg = sliceSpec?.postAgg as OverTagPostAgg;
        postAgg.overTagAgg.tagName = tags;
      };

      dataFetchPayload?.sliceSpec?.forEach(updateTags);
      childrenDataFetchPayload?.sliceSpec?.forEach(updateTags);
    }
  }, [childrenDataFetchPayload, dataFetchPayload, groupBy]);

  const changeMetric: ChangeMetric = hideCompareData ? pChangeMetric : "deltaPercentage";
  const [expandedRows, setExpandedRows] = useState<ExpandedTableRow[]>([]);
  const [expandedRowsToIgnore, setExpandedRowsToIgnore] = useState<string[]>([]);

  const propsRefState = useRefState(props);
  const [expandedRowDataMap, setExpandedRowDataMap] = useState<Record<string, DataResult>>({});
  const [expandedRowJSXMap, setExpandedRowJSXMap] = useState<Record<string, JSX.Element>>({});
  useEffect(() => {
    setExpandedRows([]);
    setExpandedRowsToIgnore([]);
    setExpandedRowDataMap({});
    setExpandedRowJSXMap({});
  }, [dataFetchPayload]);

  useEffect(() => {
    const props = propsRefState.current;
    setExpandedRowJSXMap(prev => {
      const next: typeof prev = {};
      expandedRows.forEach(row => {
        const { filterTags, level, rowId, slices } = row;
        const onDataFetch = (dataResult: DataResult) => {
          setExpandedRowDataMap(prev => ({
            ...prev,
            [rowId]: dataResult
          }));
        };

        const { dataFetchPayload, childrenDataFetchPayload } = props;
        const dfPayload = getPreparedPayload(cloneDeep(dataFetchPayload), slices, filterTags);
        const cDfPayload = getPreparedPayload(cloneDeep(childrenDataFetchPayload), slices, filterTags);
        next[row.rowId] = prev[row.rowId] || (
          <FetchDataComponent
            {...props}
            childrenDataFetchPayload={cDfPayload}
            dataFetchPayload={dfPayload}
            onDataFetch={onDataFetch}
            prefetchState={expandedRowDataMap[row.rowId]}
            queryId={[rowId, level, ...slices].join("-")}
          />
        );
      });
      return next;
    });
  }, [expandedRowDataMap, expandedRows, propsRefState]);

  const {
    data,
    fieldName,
    metricName,
    isFetching,
    childMetricNames,
    downloadDataPayload,
    tagVsDataTypeMap,
    tagVsEntityTypeMap
  } = useCommonRendererFunctionality({
    ...props,
    dataFetchPayload,
    childrenDataFetchPayload,
    metricType: changeMetric
  });

  const valueFormatter = useCallback(
    (overrideDataType: DataType, value: number | string) => {
      value = value === null || value === undefined ? "" : value;
      return getFormattedValue(fieldName, value, overrideDataType || defDataType, currencyType, dataTypeCustomisation);
    },
    [fieldName, defDataType, currencyType, dataTypeCustomisation]
  );

  const {
    dataFramesMap,
    compareDataFramesMap,
    entityLookup: parentEntityLookup
  } = useMemo(() => {
    const dataFramesMap: Record<string, DataFrame[]> = {};
    const compareDataFramesMap: Record<string, DataFrame[]> = {};
    const entityLookup: Record<string, string> = {};

    data?.forEach(datum => {
      const dataFrames: DataFrame[] = [];
      const compareDataFrames: DataFrame[] = [];

      const postAggData = datum.postAggResult.data;
      const compareData = datum.postAggResult.timeShiftData || {};
      const refId = Object.keys(postAggData)[0] || Object.keys(compareData)[0] || "";
      const dfs = postAggData[refId]?.data || [];
      const compareDfs = compareData[refId]?.data || [];

      dataFrames.push(...dfs);
      compareDataFrames.push(...compareDfs);

      [...dataFrames, ...compareDataFrames].forEach(df => {
        const { labels = {}, eLabels = labels } = df;

        const keys = Object.keys(labels);
        keys.forEach(key => {
          const rawValue = labels[key];
          entityLookup[rawValue] = eLabels[key];
        });
      });

      const metricId = getMetricIdFromDataFrameId(refId);
      dataFramesMap[metricId] = dataFrames;
      compareDataFramesMap[metricId] = compareDataFrames;
    });

    return {
      dataFramesMap,
      compareDataFramesMap,
      entityLookup
    };
  }, [data]);

  const entityLookup = useMemo(() => {
    const entityLookup = { ...parentEntityLookup };
    Object.values(expandedRowDataMap).forEach(result => {
      if (!result.isFetching && result.entityLookup) {
        Object.assign(entityLookup, result.entityLookup);
      }
    });

    return entityLookup;
  }, [expandedRowDataMap, parentEntityLookup]);

  const { metricNamesMap, metricIds } = useMemo(() => {
    const metricNamesMap = {
      [metricId]: metricName,
      ...childMetricNames
    };
    const metricIds = Object.keys(metricNamesMap);

    return {
      metricNamesMap,
      metricIds
    };
  }, [childMetricNames, metricId, metricName]);

  useEffect(() => {
    const dataDownloadAction: WidgetCustomAction = {
      showInHeader: true,
      actionComponent: (
        <IncFaIcon
          className={`inc-cursor-pointer ${downloadInProgress ? "disableClick" : ""}`}
          iconName={downloadInProgress ? "spinner" : "download"}
          onClick={openDownloadCSVModel}
          spin={downloadInProgress}
        />
      ),
      tooltipText: "Download"
    };
    onCustomActionsChange([dataDownloadAction]);
  }, [downloadInProgress, onCustomActionsChange, openDownloadCSVModel]);

  const [defaultSortFunc, defaultLimit] = useMemo(() => {
    const limitSpec = dataFetchPayload?.sliceSpec[0]?.postAgg?.sortSpec?.limitSpec;
    return [limitSpec?.function || "top", limitSpec?.limit || 5];
  }, [dataFetchPayload]);

  const downloadCSV = useCallback(
    async (func: LimitSpecFunction, value: number, fileName: string) => {
      closeDownloadCSVModal();
      setDownloadInProgress(true);
      const timeRangeMillis = {
        from: timeRange.from.valueOf(),
        to: timeRange.to.valueOf()
      };

      const downsampleStr = downsample || "auto";
      const intervalStr = getDownSampleInterval(downsampleStr, timeRangeMillis);
      const intervalSecs = kbn.interval_to_seconds(intervalStr);
      const allEntityFilters = [...entityFilters, ...cohortFilters];
      const { widgetId } = widgetResponseDTO;
      const { widgetConfig } = widgetResponseDTO;
      const metricIdToName: Record<string, string> = {};

      forEach(widgetConfig.dataDefinition.metrics, (val, key) => {
        metricIdToName[key] = val.name.replace("/", "-");
      });

      const tagToHeaderName: Record<string, string> = {};

      const downloadSliceSpecs = downloadDataPayload.sliceSpec;
      const basePostAgg = downloadSliceSpecs.find(ss => ss.metricId === metricId)?.postAgg as OverTagPostAgg;
      if (basePostAgg) {
        basePostAgg?.overTagAgg?.tagName?.forEach(x => {
          if (x === ENTITY_TAG) {
            tagToHeaderName[x] = entityTypeName;
          } else {
            tagToHeaderName[x] = x;
          }
        });

        if (basePostAgg.sortSpec) {
          basePostAgg.sortSpec.limitSpec = {
            function: func,
            limit: value
          };
        }
      }

      downloadDataPayload.sliceSpec = downloadDataPayload.sliceSpec.sort((ssA, ssB) => {
        const indexA = columnOrder?.indexOf(ssA.metricId);
        const indexB = columnOrder?.indexOf(ssB.metricId);

        return Math.max(indexA, 0) - Math.max(indexB, 0);
      });

      const downloadOptions: DownloadOptions = {
        fileName: fileName,
        metricToHeaderName: metricIdToName,
        tagToHeaderName
      };

      const isSavedMetric = dataFetchPayload.mode === ExploreQueryType.saved;
      const sheets: SheetDef[] = [];
      const columnDefList: ColumnDefList = { columnDefs: [] };
      let colIdx = 0;

      const dataSortSpec: DataSortSpec = {
        sliceSpecId:
          downloadSliceSpecs?.find(ss => ss.metricId === metricId)?.sliceSpecId || downloadSliceSpecs?.[0]?.sliceSpecId
      };
      const sliceNameSet: Map<string, Slice> = new Map<string, Slice>();
      const sliceSpecIdVsMetricId: Map<string, string> = new Map<string, string>();

      // Assuming sliceSpecs is an array of objects
      downloadSliceSpecs.forEach(ss => {
        // Adding slices to sliceNameSet
        ss.sliceSet.slices.forEach((slice: Slice) => {
          sliceNameSet.set(slice.tagName, slice);
        });
        // Populating sliceSpecIdVsMetricId
        sliceSpecIdVsMetricId.set(ss.sliceSpecId, ss.metricId);
      });

      //set the name of slice columns
      const orderedSlices = Array.from(sliceNameSet.values()).sort((a, b) => {
        const aIndex = columnOrder?.indexOf(a.tagName);
        const bIndex = columnOrder?.indexOf(b.tagName);

        return Math.max(aIndex, 0) - Math.max(bIndex, 0);
      });
      orderedSlices.forEach((slice: Slice) => {
        const sLabel =
          slice.tagName === ENTITY_TAG
            ? entityTypeName || getPromSanitizedName(getTagNameFromFieldName(slice.fieldName || ""))
            : slice.tagName;
        const columnDef: ColumnDef = {
          columnName: sLabel,
          tagName: slice.tagName
        };
        columnDefList.columnDefs.push(columnDef);
      });

      sliceSpecIdVsMetricId.forEach((metricId: string, sliceSpecId: string) => {
        //get the metric name
        const metricName: string = metricIdToName[metricId] || "";
        //check if the metricname exists
        const metricColumnDef: ColumnDef = {
          columnName: metricName ? metricName : `Column-${colIdx++}`,
          sliceSpecId: sliceSpecId
        };
        columnDefList.columnDefs.push(metricColumnDef);
      });

      sheets.push({
        sheetName: "data",
        columns: columnDefList,
        sortSliceSpecId: dataSortSpec?.sliceSpecId
      });

      const workBookDef: WorkBookDef = {
        sheets,
        fileName
      };

      const response = await exploreApiService.downloadWidgetDataByConfigV2(
        entityTypeName,
        userServiceId,
        isSavedMetric ? widgetId : "",
        isSavedMetric ? null : widgetConfig,
        timeRange.from.valueOf(),
        timeRange.to.valueOf(),
        intervalSecs,
        downloadSliceSpecs,
        allEntityFilters,
        true,
        value,
        eventFilters,
        dataSortSpec,
        workBookDef
      );
      setDownloadInProgress(false);
      downloadBlobFile(response.data, downloadOptions.fileName);
    },
    [
      closeDownloadCSVModal,
      timeRange.from,
      timeRange.to,
      downsample,
      entityFilters,
      cohortFilters,
      widgetResponseDTO,
      downloadDataPayload,
      dataFetchPayload.mode,
      entityTypeName,
      userServiceId,
      eventFilters,
      metricId,
      columnOrder
    ]
  );

  const onSortChange = useCallback(
    (accessor: string, order: "asc" | "desc") => {
      if (isMetricColumn(accessor) || isMetricCompareColumn(accessor)) {
        const metricId = getMetricIdForTableColumn(accessor);
        const childMetricIds = metricIds.filter(id => id !== metricId);

        widgetProperties.table = {
          ...((tableProperties || {}) as TableProperties),
          limitSpec: {
            limit: tableProperties.limitSpec?.limit || 50,
            function: order === "asc" ? "bottom" : "top"
          },
          changeMetric: isMetricColumn(accessor) ? "current" : "timeShift"
        };

        onQueryConfigChange({
          queryType: "widgetConfig",
          metricId,
          childMetricIds
        });
      }

      setSort({
        accessor,
        order
      });
    },
    [metricIds, onQueryConfigChange, tableProperties, widgetProperties]
  );

  // change event drawer state
  const [open, setOpen] = useState(false);
  const closeDrawer = useCallback(() => {
    setOpen(false);
  }, []);
  const [drawerContext, setDrawerContext] = useState<DrawerContext>();

  const metricsExist = metricIds?.length > 0;
  const showEvents = widgetProperties?.table?.showEvents && metricsExist;
  const onChangeEventClick = useCallback(
    (rowData: Datum) => {
      if (showEvents) {
        setOpen(true);
        setDrawerContext(prev => ({
          ...prev,
          tableRow: rowData
        }));
      }
    },
    [showEvents]
  );

  const onClickAddFilterValue = useMemo(
    () =>
      onAddAdhocEventFilter ? (fieldName: string, value: string) => onAddAdhocEventFilter(fieldName, value) : null,
    [onAddAdhocEventFilter]
  );

  const tableColumns = useMemo(
    () =>
      getTableColumns(
        aggregatedTags,
        displayAggregatedTags,
        metricIds,
        metricNamesMap,
        dataTypeByMetricId,
        downsample,
        timeRange,
        tableProperties,
        entityLookup,
        valueFormatter,
        tagVsDataTypeMap,
        tagVsEntityTypeMap,
        !hideCompareData,
        compareStr,
        false,
        onClickAddFilterValue
      ),
    [
      aggregatedTags,
      compareStr,
      dataTypeByMetricId,
      displayAggregatedTags,
      downsample,
      entityLookup,
      hideCompareData,
      metricIds,
      metricNamesMap,
      onClickAddFilterValue,
      tableProperties,
      tagVsDataTypeMap,
      tagVsEntityTypeMap,
      timeRange,
      valueFormatter
    ]
  );

  useMemo(() => {
    if (tableProperties) {
      tableProperties.columnOrder = tableColumns.map(col =>
        isMetricColumn(col.accessor) ? getMetricIdForTableColumn(col.accessor) : col.accessor
      );
    }
  }, [tableColumns, tableProperties]);

  const pagination = useMemo(() => {
    let pageSize;
    let autoPaginateProps;

    if (tableProperties) {
      if (tableProperties.pageSize === ALL_ENTRIES_PAGE_SIZE) {
        pageSize = tableProperties.limitSpec?.limit;
      } else if (tableProperties.pageSize === AUTO_PAGE_SIZE_VALUE) {
        pageSize = 20;
        autoPaginateProps = {
          approxRowHeight: 45
        };
      } else {
        pageSize = tableProperties.pageSize;
      }
    }

    return pageSize
      ? {
          ...defaultPagination,
          pageSize,
          externalControl: true,
          autoPaginateProps
        }
      : {
          ...defaultPagination,
          externalControl: true
        };
  }, [tableProperties]);

  /**
   * get the row value mapped from the row.original object
   */
  const getRowValueMapped = useCallback(
    (row: Datum) => {
      const rowMap: Record<string, string> = {};
      for (const [key, value] of Object.entries(row)) {
        let columnValue = value;
        let columnName = key;

        if (!isMetricColumn(key) && value) {
          columnName = key === ENTITY_TAG ? entityTypeName : key;
          columnValue = entityLookup[value.toString()];
        } else {
          const metricId = getMetricIdForTableColumn(key);
          columnName = metricNamesMap[metricId] ?? key;
        }
        rowMap[columnName] = columnValue as string;
      }
      return rowMap;
    },
    [entityLookup, entityTypeName, metricNamesMap]
  );

  const dataLoadingRow = useMemo(() => {
    const loadingRow: TableDataItem = {};
    tableColumns?.forEach(column => {
      loadingRow[column.accessor] = ReactDOMServer.renderToString(<LoadingComponent />);
    });
    return [loadingRow];
  }, [tableColumns]);

  const errorRow = useMemo(() => {
    const errorRow: TableDataItem = {};
    tableColumns?.forEach(column => {
      errorRow[column.accessor] = ReactDOMServer.renderToString(
        <IncFaIcon
          className="status-danger"
          iconName="exclamation-triangle"
        />
      );
    });
    return [errorRow];
  }, [tableColumns]);

  const getUniqueRecordCount = useCallback(
    (data: MetricInfo[], conditions: Record<string, any>, queryString: string): number => {
      const filteredRecords = filter(data, conditions);
      if (queryString) {
        const uniqueValues = uniq(map(filteredRecords, queryString));
        return size(uniqueValues);
      }
      return size(filteredRecords);
    },
    []
  );

  const numGroupBy = groupBy?.length || 0;

  useEffect(() => {
    if (numGroupBy) {
      refetch();
    }
  }, [numGroupBy, refetch]);

  const applyDataAggregation = useCallback(
    (data: Datum[], depth: number) => {
      //check for the data, result schema and groupby for the aggregated values to be evaluated
      if (data?.length > 0 && resultMeta[0]?.schema && groupBy?.length > 0) {
        //find the dimensions which doesn't have values
        const emptyProps = aggregatedTags.filter((key: string) => !(key in data[0]));

        data.forEach((d: Datum) => {
          const conditions: Record<string, any> = {};
          //get the tags for the current row
          const rowTags = groupBy?.slice(0, depth + 1);
          //finr the values of the keys
          rowTags?.forEach((tag: string) => (conditions[tag] = d[tag]));

          emptyProps?.forEach((key: string) => {
            const count: number = getUniqueRecordCount(resultMeta[0].schema, conditions, key);
            d[key] = `${count} unique`;
          });

          if (depth < groupBy.length) {
            d.subRows = dataLoadingRow as Datum[];
          }
        });
      }
    },
    [aggregatedTags, dataLoadingRow, getUniqueRecordCount, groupBy, resultMeta]
  );

  const shouldBreakDownByTime = Boolean(downsample) && downsample !== AUTO_DS_INTERVAL;
  const tableData = useMemo(() => {
    const data =
      getTableData(
        metricIds,
        dataFramesMap,
        compareDataFramesMap,
        aggregatedTags,
        shouldBreakDownByTime,
        pChangeMetric
      ) || [];

    applyDataAggregation(data, 0);

    expandedRows.forEach(expandedRow => {
      if (!expandedRowsToIgnore.includes(expandedRow.rowId)) {
        const { rowId, filterTags, level } = expandedRow;

        const {
          compareDataFramesMap = {},
          dataFramesMap = {},
          isError,
          isFetching
        } = expandedRowDataMap[rowId] || { isFetching: true };

        const rowData =
          getTableData(
            metricIds,
            dataFramesMap,
            compareDataFramesMap,
            aggregatedTags,
            shouldBreakDownByTime,
            pChangeMetric
          ) || [];
        applyDataAggregation(rowData, level + 1);

        const matchedObj = fromPairs(filterTags.map(item => [item.key, item.value[0]])) || {};
        const matchedRow = findRowWithMatchedProp(data, matchedObj as Datum);
        if (matchedRow) {
          matchedRow.rowExpanded = true;
          if (isFetching) {
            matchedRow.subRows = dataLoadingRow;
          } else if (isError) {
            matchedRow.subRows = errorRow;
          } else {
            matchedRow.subRows = rowData;
          }
        }
      }
    });

    return data;
  }, [
    aggregatedTags,
    applyDataAggregation,
    compareDataFramesMap,
    dataFramesMap,
    dataLoadingRow,
    errorRow,
    expandedRowDataMap,
    expandedRows,
    expandedRowsToIgnore,
    metricIds,
    pChangeMetric,
    shouldBreakDownByTime
  ]);

  const handleExpansionChange = useCallback<IncRTableProps["onExpansionChange"]>(
    (rows, currentRow, level, rowId) => {
      if (currentRow?.rowExpanded) {
        let slices: string[] = [];

        const leafRowLevel = level + 2;
        if (groupBy.length < leafRowLevel) {
          slices = aggregatedTags;
        } else {
          slices = groupBy.slice(0, leafRowLevel);
        }

        const filterTags: SelectorTag[] = [];
        const rowTags = groupBy.slice(0, level + 1);
        rowTags.forEach((key: string) => {
          if (currentRow[key]) {
            filterTags.push({
              key,
              value: [currentRow[key]]
            });
          }
        });

        setExpandedRows(prev => [
          ...prev,
          {
            row: currentRow,
            slices,
            filterTags,
            level,
            rowId
          }
        ]);
        setExpandedRowsToIgnore(prev => prev.filter(id => id !== rowId));
      } else {
        setExpandedRowsToIgnore(prev => [...prev, rowId]);
      }
    },
    [aggregatedTags, groupBy]
  );

  const getRowStyle = useCallback(
    row => {
      let pStyle = {};
      if (tableProperties?.colorCustomizations) {
        // getting effective style for a specific row
        tableProperties?.colorCustomizations?.forEach(colorCustomization => {
          const conditions = colorCustomization.conditions || [];
          // dynamic styling based on colorCustomization
          const rowData = getRowValueMapped(row);
          if (rowData && conditions.length > 0) {
            const match = isRowMatchingConditions(getRowValueMapped(row), conditions);
            const style = {
              backgroundColor: transparentize(0.6, colorCustomization.color),
              border: `1px solid ${transparentize(0.25, colorCustomization.color)}`
            };
            if (match) {
              pStyle = match ? style : {};
            }
          }
        });
      }
      return pStyle;
    },
    [getRowValueMapped, tableProperties?.colorCustomizations]
  );

  const onSave = useCallback(
    (metricId: string, selectionMap: Record<string, string>) => {
      if (metricId) {
        onSaveCustomHeaderMap(metricId, selectionMap);
      }
    },
    [onSaveCustomHeaderMap]
  );

  const maxExpandedLevel = useMemo(
    () =>
      expandedRows.reduce((acc, row) => {
        if (expandedRowsToIgnore.includes(row.rowId)) {
          return acc;
        }

        const appliedLevel = row.level === numGroupBy - 1 ? row.level - 1 : row.level;
        return Math.max(acc, appliedLevel);
      }, 0),
    [expandedRows, expandedRowsToIgnore, numGroupBy]
  );
  const expansionColumnWidth = maxExpandedLevel ? (maxExpandedLevel + 1) * 20 + 20 : 40;
  const dataFetchComponents = useMemo(() => Object.values(expandedRowJSXMap), [expandedRowJSXMap]);

  const isLoading = isFetching || (numGroupBy ? isResultMetaFetching : false);
  return (
    <>
      {showDownloadDataAction && (
        <div className="flex inc-flex-end width-100 marginBt6 download-data-action">
          <IncButton
            color="link"
            disabled={downloadInProgress}
            onClick={openDownloadCSVModel}
          >
            {!downloadInProgress && (
              <>
                <IncFaIcon
                  className="marginBt4 marginRt4"
                  iconName="download"
                />
                Download
              </>
            )}
            {downloadInProgress && <LoadingSpinner titleText="Downloading..." />}
          </IncButton>
        </div>
      )}

      <IncRTable
        classNames={{
          table: "catalog-data-grid"
        }}
        columns={tableColumns}
        data={tableData}
        density="normal"
        expansionColumnWidth={expansionColumnWidth}
        getRowStyle={isLoading ? null : getRowStyle}
        isLoading={isLoading}
        noDataMessage="No data found"
        onExpansionChange={handleExpansionChange}
        onRowClick={onChangeEventClick}
        onSortChange={onSortChange}
        pagination={pagination}
        resizableColumns
        showDisplayStats
        sort={sort}
      />

      <DownloadCSVModal
        defaultFileName={fileName}
        defaultLimit={defaultLimit}
        defaultSortFunction={defaultSortFunc}
        onClose={closeDownloadCSVModal}
        onConfirmClick={downloadCSV}
        show={showDownloadCSV}
      />

      <ChangeEventDrawer
        aggTags={aggregatedTags}
        closeDrawer={closeDrawer}
        cohortFilters={cohortFilters}
        currencyType={currencyType}
        dataType={defDataType}
        displayAggTags={displayAggregatedTags}
        downsample={downsample}
        edit={edit}
        entityFilters={entityFilters}
        entityLookup={entityLookup}
        entityType={entityTypeName}
        eventTypeId={userServiceId}
        fieldName={fieldName}
        matricIds={metricIds}
        metricNames={metricNamesMap}
        metricUserServiceFilters={eventFilters}
        onSaveCustomHeaderMap={onSave}
        open={open}
        tableInfo={drawerContext?.tableRow}
        tagsDataType={tagVsDataTypeMap}
        timeRange={timeRange}
        widgetProperties={widgetProperties}
        widgetResponseDto={widgetResponseDTO}
        widgetTitle={widgetTitle}
      />

      {dataFetchComponents}
    </>
  );
};

type DrawerContext = {
  tableRow?: Datum;
};

const defaultPagination: IncRTableProps["pagination"] = {
  pageSize: 20,
  enabled: true,
  viewMode: "minimal"
};

/**
 * Get the prepared payload for the datapayload and child data payload
 */
const getPreparedPayload = (
  dataFetchPayload: CatalogWidgetFetchDataPayload,
  slices: string[],
  filterTags: SelectorTag[]
): CatalogWidgetFetchDataPayload => {
  (dataFetchPayload?.sliceSpec || []).forEach(sliceSpec => {
    const { selectorSpec, postAgg } = sliceSpec || {};
    (postAgg as OverTagPostAgg).overTagAgg.tagName = slices;
    selectorSpec.filters = [
      {
        tags: filterTags
      }
    ];
  });
  return clone(dataFetchPayload);
};

type DataResult = {
  dataFramesMap: Record<string, DataFrame[]>;
  compareDataFramesMap: Record<string, DataFrame[]>;
  entityLookup: Record<string, string>;
  isFetching: boolean;
  isError: boolean;
  isDataFetched: boolean;
};

type DFProps = CatalogVizRendererProps & {
  metricType?: ChangeMetric;
  prefetchState: DataResult;
  onDataFetch: (dataResult: DataResult) => void;
};

const FetchDataComponent = memo<DFProps>(props => {
  const { onDataFetch, prefetchState } = props;
  const { data, isFetching, isError } = useCommonRendererFunctionality({
    ...props,
    skipDataFetch: prefetchState?.isFetching || prefetchState?.isDataFetched
  });

  useEffect(() => {
    const dataFramesMap: Record<string, DataFrame[]> = {};
    const compareDataFramesMap: Record<string, DataFrame[]> = {};
    const entityLookup: Record<string, string> = {};

    data?.forEach(datum => {
      const dataFrames: DataFrame[] = [];
      const compareDataFrames: DataFrame[] = [];

      const postAggData = datum.postAggResult.data;
      const compareData = datum.postAggResult.timeShiftData || {};
      const refId = Object.keys(postAggData)[0];
      const dfs = postAggData[refId]?.data || [];
      const compareDfs = compareData[refId]?.data || [];

      dataFrames.push(...dfs);
      compareDataFrames.push(...compareDfs);

      [...dataFrames, ...compareDataFrames].forEach(df => {
        const { labels = {}, eLabels = labels } = df;

        const keys = Object.keys(labels);
        keys.forEach(key => {
          const rawValue = labels[key];
          entityLookup[rawValue] = eLabels[key];
        });
      });

      const metricId = getMetricIdFromDataFrameId(refId);
      dataFramesMap[metricId] = dataFrames;
      compareDataFramesMap[metricId] = compareDataFrames;
    });

    onDataFetch({
      dataFramesMap,
      compareDataFramesMap,
      entityLookup,
      isFetching,
      isError,
      isDataFetched: !isFetching && !isError
    });
  }, [data, isError, isFetching, onDataFetch]);

  return <></>;
});

const findRowWithMatchedProp = (data: Datum[], propsToMatch: Datum): TableDataItem => {
  let result: TableDataItem = null;
  const traverse = (row: TableDataItem) => {
    if (!result && row && isMatch(row, propsToMatch)) {
      result = row;
    }

    if (!result && row && Array.isArray(row.subRows)) {
      row.subRows.forEach((subrow: TableDataItem) => traverse(subrow));
    }
  };
  data.forEach((row: TableDataItem) => traverse(row));
  return result;
};
