import React, { FC, useMemo, memo } from "react";
import { IncModal } from "@inception/ui";
import { CatalogVizRendererProps } from "../common";
import { TimeseriesRenderer } from "../TimeseriesRenderer";
import { SelectorSpec, SliceSpec } from "../../../../../../services/api/explore";
import { Visualisations, VizToQueryConfig, QueryType } from "../../../../../../core";

interface Props {
  rendererProps: CatalogVizRendererProps;
  tags: Record<string, string>;
  title: string;
  onClose: () => void;
}

export const TimeseriesModal: FC<Props> = memo(props => {
  const { rendererProps, tags, onClose, title } = props;

  const fRendererProps = useMemo(() => {
    const { dataFetchPayload, currViz } = rendererProps;
    const nCurrViz: VizToQueryConfig = {
      ...currViz,
      visualisation: Visualisations.timeseries,
      queryType: QueryType.aggregatedTimeseriesOverTag
    };

    const nSliceSpec = dataFetchPayload.sliceSpec.map((sSpec): SliceSpec => {
      const selectorSpec: SelectorSpec = {
        filters: [
          {
            tags: []
          }
        ]
      };

      Object.keys(tags).forEach(key => {
        const value = tags[key];
        selectorSpec.filters[0].tags.push({
          key,
          value: [value]
        });
      });

      const nPostAgg = {
        ...(sSpec.postAgg || {})
      };
      delete (nPostAgg as any).overTimeAgg;

      return {
        ...sSpec,
        selectorSpec,
        postAgg: nPostAgg
      };
    });

    const nRendererProps: CatalogVizRendererProps = {
      ...rendererProps,
      currViz: nCurrViz,
      dataFetchPayload: {
        ...dataFetchPayload,
        sliceSpec: nSliceSpec
      },
      seriesLimit: 1
    };

    return nRendererProps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IncModal
      className="insights-ts-modal"
      onClose={onClose}
      show
      size="lg"
      titleText={title}
    >
      <div className={rendererProps.className}>
        <TimeseriesRenderer {...fRendererProps} />
      </div>
    </IncModal>
  );
});
