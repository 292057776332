import React, { FC, memo, CSSProperties, useMemo } from "react";
import { IncGenericIcon } from "@inception/ui";
import { Icon } from "../services/api";
import { VerticallyCenteredRow } from "./flex-components";
import { getRandomColor } from "./charts/colors";

interface Props {
  icon: Icon;
  className?: string;
  color?: string;
  size?: number;
  isLoading?: boolean;
}

export const IconInfoRenderer: FC<Props> = memo(props => {
  const { icon, size = 24, isLoading, color: pColor, className = "" } = props;
  const color = useMemo(() => (pColor === "auto" ? getRandomColor() : pColor), [pColor]);

  if (!icon) {
    return <></>;
  }

  const { iconColor, iconName, iconSvg } = icon || {};
  const iconStyle: CSSProperties = {
    color: color || iconColor,
    fill: color || iconColor,
    fontSize: size,
    minWidth: size
  };

  if (iconName) {
    return (
      <IncGenericIcon
        className={className}
        data-show-loader={isLoading}
        fontSize={size}
        iconName={iconName}
        size={size}
        style={iconStyle}
      />
    );
  }

  return (
    <VerticallyCenteredRow
      className={className}
      dangerouslySetInnerHTML={{ __html: iconSvg }}
      data-show-loader={isLoading}
    />
  );
});
