import React, { FC, useCallback } from "react";
import { IncLabel } from "@inception/ui";
import { ChartColorPicker } from "../../../../../../../biz-flow/ChartColorPicker";
import { ColorRangeCustomization, UIColorRangeCondition, getGeneratedThreshold } from "./UIColorRangeCondition";
import { WidgetCustomizationProps } from "./types";

const defaultSingleStatProp = {
  defaultColor: "#fff",
  defaultBackground: "transparent",
  thresholds: [getGeneratedThreshold() as any]
};

export const SingleStatUICustomization: FC<WidgetCustomizationProps> = props => {
  const { properties, onPropertiesChange } = props;

  const { singleStat: pSingleStat } = properties || {};

  const singleStat = pSingleStat || defaultSingleStatProp;

  const { defaultColor, defaultBackground, thresholds } = singleStat;

  const updateWidgetRenderProp = useCallback(() => {
    properties.singleStat = singleStat;
    onPropertiesChange(properties);
  }, [onPropertiesChange, properties, singleStat]);

  const onLineColorChange = useCallback(
    (color: string) => {
      singleStat.defaultColor = color;
      updateWidgetRenderProp();
    },
    [singleStat, updateWidgetRenderProp]
  );

  const onFillColorChange = useCallback(
    (color: string) => {
      singleStat.defaultBackground = color;
      updateWidgetRenderProp();
    },
    [singleStat, updateWidgetRenderProp]
  );

  const handleThresholdChange = useCallback(
    (thresholds: ColorRangeCustomization[]) => {
      singleStat.thresholds = thresholds;
      updateWidgetRenderProp();
    },
    [singleStat, updateWidgetRenderProp]
  );

  return (
    <div className="customization-content">
      <div className="query-editor-wrapper--content">
        <UIColorRangeCondition
          onThresholdChange={handleThresholdChange}
          thresholds={thresholds as ColorRangeCustomization[]}
        />
        <div className="d-flex list-item  width-100 align-items-center justify-content-start">
          <div className="d-flex align-items-center mr-4">
            <div className="mr-2 color-picker-container">
              <ChartColorPicker
                color={defaultColor}
                onChange={onLineColorChange}
                size={24}
              />
            </div>
            <IncLabel className="input-label">Default Text Color</IncLabel>
          </div>

          <div className="d-flex align-items-center mr-4 left-border">
            <div className="mr-2 color-picker-container">
              <ChartColorPicker
                color={defaultBackground}
                onChange={onFillColorChange}
                size={24}
              />
            </div>
            <IncLabel className="input-label">Background Color</IncLabel>
          </div>
        </div>
      </div>
    </div>
  );
};
