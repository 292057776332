import React, { FC, useMemo } from "react";
import { Visualisations } from "../../../../core";
import {
  BarChartRenderer,
  PieChartRenderer,
  TimeseriesRenderer,
  GeoMapRenderer,
  CatalogVizRendererProps,
  TreeMapRenderer,
  HistogramInsightsRenderer,
  SparkLineRenderer,
  TableRenderer,
  TopNRenderer,
  SingleStatWrapper
} from "./renderers";

type Props = CatalogVizRendererProps;

export const VizRenderer: FC<Props> = rendererProps => {
  const { currViz, className } = rendererProps;

  const containerClassName = className.split(" ")[0];
  const props = useMemo(
    () => ({
      ...rendererProps,
      className: containerClassName
    }),
    [containerClassName, rendererProps]
  );

  const viz = currViz.visualisation;

  const renderedViz = useMemo(() => {
    switch (viz) {
      case Visualisations.barChart:
        return <BarChartRenderer {...props} />;

      case Visualisations.pieChart:
        return <PieChartRenderer {...props} />;

      case Visualisations.timeseries:
        return <TimeseriesRenderer {...props} />;

      case Visualisations.geoMap:
        return <GeoMapRenderer {...props} />;

      case Visualisations.gauge:
        return (
          <PieChartRenderer
            {...props}
            showAsGauge
          />
        );

      case Visualisations.donut:
        return (
          <PieChartRenderer
            {...props}
            showAsDonut
          />
        );

      case Visualisations.treeMap:
        return <TreeMapRenderer {...props} />;

      case Visualisations.insights:
        return <TopNRenderer {...props} />;

      case Visualisations.histogram:
        return <HistogramInsightsRenderer {...props} />;

      case Visualisations.singleStat:
        return <SingleStatWrapper {...props} />;

      case Visualisations.sparkLine:
        return <SparkLineRenderer {...props} />;

      case Visualisations.table:
        return <TableRenderer {...props} />;

      default:
        return <div className="inc-text-subtext-medium marginTp16">Cannot visualise data</div>;
    }
  }, [props, viz]);

  return <div className={className}>{renderedViz}</div>;
};
