import React, { useState, useRef, useMemo, useCallback, useEffect } from "react";
import { isEqual, uniqBy } from "lodash";
import { IncClickAwayPopper, IncFaIcon, IncButton, IncToolTip, IncPill, IncSmartText } from "@inception/ui";
import { cx } from "emotion";
import {
  UserServiceFilterExpression,
  FieldPickerContextDTO,
  WidgetConfigUtils,
  ConditionWithPickerType,
  UserServiceFilterExpressionTree,
  LogicalOperator
} from "../../services/api/explore";
import { fetchEntityPropertiesForIds, ExploreQueryUtils, noOp, FieldPickerUtils } from "../../utils";
import { InfoPopper } from "../CustomSelect";
import { CustomSelect, ConditionsBuilder } from "..";
import { ConditionWithPickerTypeExpressionTree } from "../explore";

interface EventCriteriaPickerProps {
  fieldPickerContext: FieldPickerContextDTO;

  filters?: UserServiceFilterExpression[];
  filterExpressionTree?: UserServiceFilterExpressionTree;
  useExpressionTree?: boolean;
  onChange: (
    filterExpressions: UserServiceFilterExpression[],
    expressionTree?: UserServiceFilterExpressionTree
  ) => void;

  label?: string;
  pillLimit?: number;
  placeholder?: string;

  className?: string;
  disabled?: boolean;
}

export const EventCriteriaPicker: React.FC<EventCriteriaPickerProps> = props => {
  const {
    fieldPickerContext,
    filters: eFilters,
    filterExpressionTree: eFilterExpressionTree,
    onChange,
    pillLimit = 2,
    className = "",
    label = "Event Criteria",
    placeholder = "Select",
    disabled = false,
    useExpressionTree = false
  } = props;

  const anchorEl = useRef(null);
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [entityLookUp, setEntityLookUp] = useState<Record<string, string>>({});
  const [isFetchingLookUp, setIsFetchingLookUp] = useState<boolean>(false);

  const filters = useMemo<ConditionWithPickerType[]>(() => {
    if (eFilters && eFilters.length > 0) {
      return ExploreQueryUtils.getConditionFilters(eFilters);
    }
    return [null];
  }, [eFilters]);

  const filtersExpressionTree = useMemo<ConditionWithPickerTypeExpressionTree>(() => {
    if (eFilterExpressionTree) {
      return ExploreQueryUtils.getAdvancedConditionFilters(eFilterExpressionTree);
    }

    return {
      filterNodes: [],
      logicalOperator: LogicalOperator.AND
    };
  }, [eFilterExpressionTree]);

  const openPopper = useCallback(() => setOpen(true), []);
  const closePopper = useCallback(() => setOpen(false), []);

  const [tempFilters, setTempFilters] = useState(filters);
  const [tempFilterExprTree, setTempFilterExprTree] = useState(filtersExpressionTree);

  const onFiltersChange = useCallback(
    (filters: ConditionWithPickerType[], filtersValid: boolean, filterExpr: ConditionWithPickerTypeExpressionTree) => {
      setTempFilters(filters);
      setTempFilterExprTree(filterExpr);
    },
    []
  );

  useEffect(() => {
    const fetchEntityLookup = async (entityIds: string[]) => {
      const entityPropertiesMap = await fetchEntityPropertiesForIds(
        new Set(entityIds.map(x => x.replace(/['"]+/g, ""))),
        0,
        Date.now()
      );
      const entityLookUp: Record<string, string> = {};
      setIsFetchingLookUp(true);

      entityPropertiesMap.forEach((value, key) => {
        entityLookUp[key] = value.name;
      });
      setEntityLookUp(entityLookUp);
      setIsFetchingLookUp(false);
    };

    const entityIds = useExpressionTree
      ? WidgetConfigUtils.getEntityIdsFromFilterTree(eFilterExpressionTree)
      : WidgetConfigUtils.getEntityIdsFromFilterExpressions(eFilters);
    if (entityIds.length > 0) {
      fetchEntityLookup(entityIds);
    }
  }, [eFilterExpressionTree, eFilters, useExpressionTree]);

  const onSave = useCallback(() => {
    if (useExpressionTree) {
      const eFilterExprTree = ExploreQueryUtils.getUserServiceFilterExpressionTree(tempFilterExprTree);
      onChange(null, eFilterExprTree);
    } else {
      const eFilters: ConditionWithPickerType[] = tempFilters.map(filter => {
        if (!ExploreQueryUtils.validateFilter(filter)) {
          return undefined;
        }
        return filter;
      });

      const validFilters = eFilters
        .filter(x => x !== undefined)
        .map(x => ExploreQueryUtils.getUserServiceFilterExpression(x));
      const uniqFilters = uniqBy(
        validFilters,
        filter =>
          `${FieldPickerUtils.getUserServiceFieldLabel(filter.field)}-${filter.operator}-${filter.value ? filter.value : filter.values.join(",")}`
      );
      if (!isEqual(uniqFilters, filters)) {
        onChange(uniqFilters, null);
      }
    }
    closePopper();
  }, [closePopper, filters, onChange, tempFilterExprTree, tempFilters, useExpressionTree]);

  const onCancelClick = useCallback(() => {
    setTempFilterExprTree(filtersExpressionTree);
    setTempFilters(filters);

    closePopper();
  }, [filtersExpressionTree, filters, closePopper]);

  const stopPropagation = useCallback(e => {
    e.stopPropagation();
  }, []);

  const onRemoveFilter = useCallback(
    (idx: number) => {
      const tFilters = [...eFilters];
      tFilters.splice(idx, 1);
      onChange(tFilters);
    },
    [eFilters, onChange]
  );

  const filterPills = useMemo(() => {
    if (useExpressionTree) {
      const filterLabel = WidgetConfigUtils.getUsFilterExpressionTreeLabel(eFilterExpressionTree, entityLookUp);
      return filterLabel ? (
        <div className="inc-flex-row inc-flex-center-vertical width-100">
          <IncSmartText text={filterLabel} />
        </div>
      ) : null;
    } else {
      if (eFilters.length > 0 && !isFetchingLookUp) {
        const filterLabels = eFilters.map(filter => WidgetConfigUtils.getUsFilterExpressionLabel(filter, entityLookUp));
        const displayLabels = filterLabels.splice(0, pillLimit);
        const diff = filterLabels.length;

        const pills = displayLabels.map((label, idx) => (
          <IncPill
            className="marginLt4 marginRt4"
            key={idx}
            label={label}
            onClick={openPopper}
            onRemove={() => onRemoveFilter(idx)}
          />
        ));

        let hoverElement = null;
        if (filterLabels.length > 0) {
          hoverElement = InfoPopper(filterLabels.map(x => x));
        }

        return (
          <div className="inc-flex-row inc-flex-center-vertical">
            {pills}
            {hoverElement && (
              <IncToolTip
                placement="bottom"
                titleElement={hoverElement}
              >
                <span className="inc-text-subtext inc-link marginTp4">{`+${diff}`}</span>
              </IncToolTip>
            )}
          </div>
        );
      }
    }

    return null;
  }, [
    useExpressionTree,
    eFilterExpressionTree,
    entityLookUp,
    eFilters,
    isFetchingLookUp,
    pillLimit,
    openPopper,
    onRemoveFilter
  ]);

  const classes = cx("width-100", className);
  const placeholderText = isFetchingLookUp ? "Loading..." : placeholder;

  return (
    <div
      className="width-100"
      ref={containerRef}
    >
      <CustomSelect
        anchor={anchorEl}
        className={classes}
        disabled={disabled}
        hideSelectIconWhenDisabled
        label={label}
        labelPosition={"left"}
        onClick={openPopper}
        placeholderText={placeholderText}
        value={filterPills}
      />

      <IncClickAwayPopper
        anchorEl={anchorEl.current}
        onClickAway={noOp}
        overlay
        placement="bottom"
        show={open}
      >
        <div
          className="event-filter-popper inc-flex-column"
          onClick={stopPropagation}
        >
          <div className="header-container inc-flex-row inc-flex-center-vertical inc-flex-space-contents">
            <span className="inc-text-header-medium">Event Criteria</span>
            <IncFaIcon
              className="inc-cursor"
              iconName="close"
              onClick={onCancelClick}
            />
          </div>
          <span className="inc-text-subtext">Add your criteria</span>
          <div className="event-filters-container marginTp12">
            <ConditionsBuilder
              fieldPickerContext={fieldPickerContext}
              fieldTypes={["userServiceField"]}
              filterExpressionTree={filtersExpressionTree}
              filterJoin="AND"
              filters={filters}
              label="Where"
              labelAlign="horizontal"
              onChange={onFiltersChange}
              useExpressionTree={useExpressionTree}
            />
          </div>
          <div className="inc-flex-row inc-flex-center-vertical marginTt12">
            <IncButton
              color="primary"
              onClick={onSave}
            >
              Save
            </IncButton>
            <IncButton
              className="cancel-btn marginLt12"
              color="link"
              onClick={onCancelClick}
            >
              Cancel
            </IncButton>
          </div>
        </div>
      </IncClickAwayPopper>
    </div>
  );
};
