import { IncTextfield } from "@inception/ui";
import { capitalize, debounce, isUndefined } from "lodash";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { WidgetNumberProperty } from "../types";

interface Props {
  property: WidgetNumberProperty;
  onUpdate: (newState: WidgetNumberProperty) => void;
}

const NumberPropertyRenderer: FC<Props> = props => {
  const { onUpdate: usrUpdate, property } = props;

  const {
    name,
    value: usrValue,
    defaultValue = 0,
    label = capitalize(name),
    updateOnBlur = true,
    placeholder = name,
    info = ""
  } = property;

  const [value, setValue] = useState<number>(undefined);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value ? parseInt(e.target.value, 10) : defaultValue;
    setValue(newValue);
    if (!updateOnBlur) {
      onUpdate(newValue);
    }
  };

  const onUpdate = debounce((newValue = value) => {
    const newProperty: WidgetNumberProperty = {
      ...property,
      value: newValue
    };
    usrUpdate(newProperty);
  }, 500);

  const onBlur = updateOnBlur ? () => onUpdate() : () => {};

  useEffect(() => {
    setValue(usrValue as number);
  }, [usrValue]);

  return (
    !isUndefined(value) && (
      <IncTextfield
        helpText={info}
        label={label}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        type="number"
        value={value}
      />
    )
  );
};

export default NumberPropertyRenderer;
