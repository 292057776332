import { getInceptionTheme } from "@inception/ui";
import React, { AnchorHTMLAttributes, MouseEvent } from "react";
import styled from "@emotion/styled";
import { useInceptionRoute } from "../../core/hooks";

const StyledLinkInternal = styled.a`
  &:hover {
    color: ${getInceptionTheme().inceptionColors.accentBlue};
    text-decoration: none;
  }
  color: ${getInceptionTheme().inceptionColors.accentBlue} !important;
  cursor: pointer;
`;

interface AnchorLinkProps extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href" | "target"> {
  isExternalLink?: boolean;
  to: string;
  newWindow?: boolean;
}

/**
 * Custom anchor tag element. The component takes any property which the
 * HTML anchor element takes OR any which the Link component in react-router-dom takes.
 * Use to attribute instead of native href since its a required property even for external links.
 * @param isExternalLink This is used to denote a non app link which navigates to external site like docs,
 *                       and other third party sites.
 */
const AnchorLink: React.FC<AnchorLinkProps> = ({
  children,
  isExternalLink = false,
  to,
  onClick: usrOnClick = () => {},
  newWindow = false,
  ...anchorProps
}) => {
  const { getRoute, navigate } = useInceptionRoute();

  const href = isExternalLink ? to : getRoute(to);
  const target = newWindow ? "_blank" : "";
  const onClick = (evt: MouseEvent<HTMLAnchorElement>) => {
    // Prevent default navigation behaviour and navigate via the app's navigation logic
    evt.preventDefault();

    if (!isExternalLink) {
      if (newWindow) {
        navigate(to, { newTab: true });
      } else {
        navigate(to);
      }
    } else {
      navigate(to, { newTab: true });
    }
    usrOnClick(evt);
  };

  return (
    <StyledLinkInternal
      {...anchorProps}
      href={href}
      onClick={onClick}
      target={target}
    >
      {children}
    </StyledLinkInternal>
  );
};

export { AnchorLink };
