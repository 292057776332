/* eslint-disable @typescript-eslint/no-namespace */
import { IncFaIconName, ISaxIconProps } from "@inception/ui";
import { AgentChatTypes, ConversationFragmentType, Fragment } from "../../chat";
import {
  BizDataQuery,
  CorrelatedEventsRequest,
  CorrelatedEventsResponse,
  CorrelatedEventToolSpec,
  DimensionalAnalysisToolSpec,
  FunnelData,
  GoogleSearchToolSpec,
  TimeObj,
  TriageClustersResponse,
  UserServiceFieldSliceSet,
  UserServiceFilterList
} from "../../explore";
import { IncidentSummaryCauseType } from "../../use-case-catalog";

export namespace DrilldownTypes {
  export interface CauseGraph {
    id: string;
    name: string;
    description: string;
    steps: DrillDownStep[];
    insightFilters: UserServiceFilterList;
    faIcon: string;
  }

  export interface DimensionAnalysisPredicate {
    // oneOf
    querySource?: string;
    query?: BizDataQuery;

    defaultDrillDownFieldsSet: UserServiceFieldSliceSet;
    predicateMeta: PredicateMeta;
  }

  export interface PredicateMeta {
    excludeIncidentFilters: string;
    override: {
      lookForSpike: boolean;
      lookForDrop: boolean;
      lookForSpikeDrop: boolean;
      lookBackTimeOverride: TimeObj;
    };
    threshold: number;
  }

  export interface CorrelationPredicate {
    querySource?: string;
    searchRequest?: CorrelatedEventsRequest;
    insightMeta?: InsightMeta;
    lookBackTime: TimeObj;
  }

  export interface InsightMeta {
    insightId: string;
    opId: string;
  }

  export interface RelatedKPIPredicate {
    query: BizDataQuery;
    predicateMeta: PredicateMeta;
  }

  export interface DrillDownPredicate {
    dimensionAnalysisPredicate?: DimensionAnalysisPredicate;
    correlationPredicate?: CorrelationPredicate;
    relatedKPIPredicate?: RelatedKPIPredicate;
    correlatedEventToolSpec?: CorrelatedEventToolSpec;
    dimensionalAnalysisToolSpec?: DimensionalAnalysisToolSpec;
    googleSearchToolSpec?: GoogleSearchToolSpec;
  }

  export interface DrillDownStepList {
    steps: DrillDownStep[];
  }

  export interface DrillDownStep {
    uiKey?: string; // specific for UI since id is being duplicated sometimes
    id: string;
    name: string;
    description: string;
    predicate: DrillDownPredicate;
    steps: DrillDownStep[];
    faIcon: string;
    source: AgentChatTypes.Source;
    /**
     * @deprecated Use for demo tennants only
     */
    isDemoSuccessStep?: boolean;
    drillDownQueryGenerationText?: string;
    fallbackSteps?: DrillDownStep[];
    causeType?: IncidentSummaryCauseType;
    /**
     * @deprecated use causeType instead
     */
    causeTypeString?: string;
    outputMetaTags?: string[];
  }

  export interface CauseGraphExecutionResult {
    id: string;
    name: string;
    status: ExecutionStatus;
    message: string;
    summary: string;
    stepsExecutionResults: DrillDownStepExecutionResult[];
  }

  export interface DrillDownStepExecutionResult {
    id: string;
    name: string;
    faIcon: IncFaIconName | ISaxIconProps["iconName"];
    status: ExecutionStatus;
    message: string;
    summary: string;
    causeType: string;
    causeTypeString: string;
    shortSummary: string;
    statusExplanation: string;
    predicateExecutionResult: DrillDownPredicateExecutionResult;
    childStepExecutionResults: DrillDownStepExecutionResult[];
    internalSource: AgentChatTypes.Source;
    referenceSource: AgentChatTypes.Source;
    isCause: boolean;
    additionalData: AdditionalData[];

    entityLookupData?: Record<string, string>;

    // oneOf
    drillDownResultFragment: DrilldownExecutionResultFragment;
  }

  export interface AdditionalData {
    additionalDataMap: Record<string, string>;
  }

  export interface DrilldownExecutionResultFragment {
    fragment: Fragment;
    fragmentType: ConversationFragmentType;
    entityLookupData?: Record<string, string>;
  }

  export interface DrillDownPredicateExecutionResult {
    type: PredicateType;
    status: ExecutionStatus;
    result: ExecutionResult;
    message: string;

    // oneOf
    dimensionAnalysisResult?: DimensionAnalysisResult;
    correlatedEventSearchResponse?: CorrelatedEventsResponse;
    journeyPredicateExecutionResult?: JourneyPredicateExecutionResult;
    correlationPredicateExecutionResult?: CorrelationPredicateExecutionResult;

    predicate: DrillDownPredicate;
  }

  export interface JourneyPredicateExecutionResult {
    funnelData: FunnelData;
    message: string;
    triageClustersResponse: TriageClustersResponse;
  }

  export interface CorrelationPredicateExecutionResult {
    correlatedEventSearchResponse: CorrelatedEventsResponse;
    message: string;
  }

  export interface DimensionAnalysisResult {
    filterList: UserServiceFilterList;
    triageClustersResponse: TriageClustersResponse;
    message: string;
  }

  export enum ExecutionStatus {
    UNKNOWN = "UNKNOWN",
    COMPLETED = "COMPLETED",
    FAILURE = "FAILURE",
    IN_PROGRESS = "IN_PROGRESS",
    PENDING = "PENDING"
  }

  export enum ExecutionResult {
    UNKNOWN_RESULT = "UNKNOWN_RESULT",
    TRUE = "TRUE",
    FALSE = "FALSE"
  }

  export enum PredicateType {
    DIMENSION_ANALYSIS = "DIMENSION_ANALYSIS",
    CORRELATION = "CORRELATION",
    RELATED_KPI = "RELATED_KPI",
    JOURNEY = "JOURNEY"
  }
}
