import { useMemo } from "react";
import { TimeRange } from "../../../../core";
import { SelectorSpec, UserServiceFieldSliceSet } from "../../../../services/api/explore";
import { WidgetCustomAction } from "../../types";
import FunnelWidgetImpl from "../models/impl";
import { useSelectFiltersAction, useDisableCompareAction } from "./hooks";

type Props = {
  widget: FunnelWidgetImpl;

  funnelId: string;
  sliceSets: UserServiceFieldSliceSet[];

  timeRange: TimeRange;

  selectedFilters: SelectorSpec["filters"];
  setSelectedFilters: (nextFilters: SelectorSpec["filters"]) => void;

  isFunnelConfigFetching: boolean;

  sliceTags: string[];
  setSliceTags: (sliceTags: string[]) => void;
  bizEntityType: string;
};

export const useCustomActions = (props: Props) => {
  const viewFiltersAction = useSelectFiltersAction(props);
  // const aggTagsAction = useSelectAggregateTags(props);
  const compareAction = useDisableCompareAction(props);

  const customActions = useMemo(() => {
    const customActions: WidgetCustomAction[] = [];

    compareAction && customActions.push(compareAction);
    // aggTagsAction && customActions.push(aggTagsAction);
    viewFiltersAction && customActions.push(viewFiltersAction);

    return customActions;
  }, [compareAction, viewFiltersAction]);

  return {
    customActions
  };
};
