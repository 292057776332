import { IncInModalConfirmation } from "@inception/ui";
import { cloneDeep, isEqual, pick, startCase } from "lodash";
import React, { FC, useCallback, useMemo, useState } from "react";
import { VerticallyCenteredRow } from "../../../components";
import { logger, useNotifications, generateId, useToggleState } from "../../../core";
import {
  BizActionConfig,
  ActionCategoryType,
  ActionSourceTypes,
  operationaliseV2ApiService,
  OpCreationConfig,
  ActionRunMode,
  MuteTimeRange
} from "../../../services/api/operationalise";
import { noOp } from "../../../utils";
import { ActionInfo } from "../../components";
import {
  deleteBizAction,
  disableBizAction,
  enableBizAction,
  setBizAction,
  snoozeBizAction,
  useOpStore
} from "../../context";
import { SetActionConfigPayload } from "../../context/types";
import { OP_ACTION_ERROR_PREFIX } from "../../types";
import { OpActionsList, TEMPORARY_ACTION_ID } from "../actions";
import { ActionsEditor } from "../editors";
import { validateOpConfig } from "../../utils";
import { OpV3TabBar } from "./OpV3TabBar";

interface Props {
  saveOrUpdateInProgress: boolean;
  switchToConditionsView: () => void;
  setSaveOrUpdateInProgress: (inProgress: boolean) => void;
  opConfigExists: boolean;
  numActions: number;

  children?: JSX.Element;
}

export const OpV3ActionsWrapper: FC<Props> = props => {
  const {
    switchToConditionsView,
    opConfigExists,
    setSaveOrUpdateInProgress,
    saveOrUpdateInProgress,
    numActions,
    children
  } = props;

  const { close: closeConfirmation, isOpen: isConfirmationOpen, open: openConfirmation } = useToggleState();

  const { notifySuccess, notifyError } = useNotifications();

  const { dispatch, state } = useOpStore();

  const { op10zeId, opCreationConfig, context, actionCategories } = state;
  const { bizActions } = opCreationConfig;
  const { mode, uiIntegrationActionConfigsMap } = context;
  const isNewOperationalise = !op10zeId;

  const [bizActionState, setBizActionState] = useState<BizActionState>();

  const resetState = useCallback(() => {
    setBizActionState(null);
  }, []);

  const tempOpCreationConfig = useMemo(() => {
    const nBizActions = {
      ...bizActions
    };
    const tempOpCreationConfig: OpCreationConfig = {
      ...opCreationConfig,
      bizActions: nBizActions
    };

    if (bizActionState) {
      const { bizActionConfig, bizActionId } = bizActionState;

      nBizActions[bizActionId] = bizActionConfig;
    }

    return tempOpCreationConfig;
  }, [bizActionState, bizActions, opCreationConfig]);

  const selectedActionId = bizActionState?.bizActionId;
  const selectedBizActionConfig = bizActionState?.bizActionConfig;
  const supportsTestActionWithRealAlertOnly = bizActionState?.supportsTestActionWithRealAlertOnly;
  const isTemporaryAction = selectedActionId === TEMPORARY_ACTION_ID;
  const temporaryAction = isTemporaryAction ? selectedBizActionConfig : null;

  const onSelectAction = useCallback(
    (actionId: string) => {
      const bizActionConfig = cloneDeep(opCreationConfig.bizActions[actionId]);
      const sourceType = bizActionConfig?.alertActionConfig?.alertActionConfigDef?.alertActionDef?.sourceTypeId;
      const categoryType = bizActionConfig?.alertActionConfig?.alertActionConfigDef?.alertActionDef?.actionCategoryType;
      const category = actionCategories?.find(e => e.actionCategoryType === categoryType);
      const source = category?.actionSourceTypes?.find(e => e.sourceTypeId === sourceType);
      setBizActionState({
        bizActionId: actionId,
        bizActionConfig,
        supportsTestActionWithRealAlertOnly: source?.supportsTestActionWithRealAlertOnly
      });
    },
    [actionCategories, opCreationConfig.bizActions]
  );

  const onChangeAction = useCallback(
    (bizActionId: string, bizActionConfig: BizActionConfig) => {
      const sourceType = bizActionConfig?.alertActionConfig?.alertActionConfigDef?.alertActionDef?.sourceTypeId;
      const categoryType = bizActionConfig?.alertActionConfig?.alertActionConfigDef?.alertActionDef?.actionCategoryType;
      const category = actionCategories?.find(e => e.actionCategoryType === categoryType);
      const source = category?.actionSourceTypes?.find(e => e.sourceTypeId === sourceType);
      setBizActionState({
        bizActionId,
        bizActionConfig,
        supportsTestActionWithRealAlertOnly: source?.supportsTestActionWithRealAlertOnly
      });
    },
    [actionCategories]
  );

  const onCreateAction = useCallback(
    (categoryId: ActionCategoryType, sourceType: ActionSourceTypes, runMode: ActionRunMode) => {
      const bizActionConfig = getBizActionConfig(categoryId, sourceType, runMode);
      const category = actionCategories?.find(e => e.actionCategoryType === categoryId);
      const source = category?.actionSourceTypes?.find(e => e.sourceTypeId === sourceType);
      setBizActionState({
        bizActionId: TEMPORARY_ACTION_ID,
        bizActionConfig,
        supportsTestActionWithRealAlertOnly: source?.supportsTestActionWithRealAlertOnly
      });
    },
    [actionCategories]
  );

  const onCloneAction = useCallback(
    async (actionId: string) => {
      try {
        setSaveOrUpdateInProgress(true);
        const cloneApiResponse = await operationaliseV2ApiService.cloneOpActionConfig(op10zeId, actionId);
        if (cloneApiResponse.error) {
          notifyError("Error while cloning action");
          logger.error("OperationaliseV3", "Error while cloning action", cloneApiResponse.message);
        } else {
          const bizActionConfig = cloneApiResponse.data;
          const sourceType = bizActionConfig?.alertActionConfig?.alertActionConfigDef?.alertActionDef?.sourceTypeId;
          const categoryType =
            bizActionConfig?.alertActionConfig?.alertActionConfigDef?.alertActionDef?.actionCategoryType;
          const category = actionCategories?.find(e => e.actionCategoryType === categoryType);
          const source = category?.actionSourceTypes?.find(e => e.sourceTypeId === sourceType);
          bizActionConfig.alertActionConfig.actionId = "";
          setBizActionState({
            bizActionId: TEMPORARY_ACTION_ID,
            bizActionConfig: bizActionConfig,
            supportsTestActionWithRealAlertOnly: source.supportsTestActionWithRealAlertOnly
          });
        }
      } catch (error) {
        notifyError("Error while cloning action");
        logger.error("OperationaliseV3", "Error while cloning action", error);
      } finally {
        setSaveOrUpdateInProgress(false);
      }
    },
    [actionCategories, notifyError, op10zeId, setSaveOrUpdateInProgress]
  );

  const onDeleteAction = useCallback(
    async (bizActionId: string) => {
      setSaveOrUpdateInProgress(true);

      let canRemoveFromState = true;

      if (!isNewOperationalise) {
        const { data, error, message } = await operationaliseV2ApiService.deleteBizActionConfig(op10zeId, bizActionId);
        const { deleted } = data || {};
        if (error || !deleted) {
          canRemoveFromState = false;
          notifyError("Error deleting action");
          logger.error("OperationaliseV3", "Error deleting action to existing op10ze", message);
        } else {
          notifySuccess("Action deleted successfully");
          if (bizActionId === selectedActionId) {
            resetState();
          }
        }
      }

      if (canRemoveFromState) {
        dispatch(deleteBizAction(bizActionId));
      }

      setSaveOrUpdateInProgress(false);
    },
    [
      dispatch,
      isNewOperationalise,
      notifyError,
      notifySuccess,
      op10zeId,
      resetState,
      selectedActionId,
      setSaveOrUpdateInProgress
    ]
  );

  const onDisableAction = useCallback(
    async (bizActionId: string) => {
      setSaveOrUpdateInProgress(true);

      let canUpdateState = true;

      if (!isNewOperationalise) {
        const { data, error, message } = await operationaliseV2ApiService.disableBizActionConfig(op10zeId, bizActionId);
        const { disabled } = data || {};
        if (error || !disabled) {
          canUpdateState = false;
          notifyError("Error disabling action");
          logger.error("OperationaliseV3", "Error disabling action to existing op10ze", message);
        } else {
          notifySuccess("Action disabled successfully");
        }
      }

      if (canUpdateState) {
        dispatch(disableBizAction(bizActionId));
      }

      setSaveOrUpdateInProgress(false);
    },
    [dispatch, isNewOperationalise, notifyError, notifySuccess, op10zeId, setSaveOrUpdateInProgress]
  );

  const onEnableAction = useCallback(
    async (bizActionId: string) => {
      setSaveOrUpdateInProgress(true);

      let canUpdateState = true;

      if (!isNewOperationalise) {
        const { data, error, message } = await operationaliseV2ApiService.enableBizActionConfig(op10zeId, bizActionId);
        const timeRange = {};
        await operationaliseV2ApiService.snoozeBizActionConfig(op10zeId, bizActionId, timeRange);
        const { disabled } = data || {};
        if (error || disabled) {
          canUpdateState = false;
          notifyError("Error enabling action");
          logger.error("OperationaliseV3", "Error enabling action to existing op10ze", message);
        } else {
          notifySuccess("Action Enabled successfully");
        }
      }

      if (canUpdateState) {
        dispatch(enableBizAction(bizActionId));
        dispatch(
          snoozeBizAction({
            bizActionId,
            timeRange: {}
          })
        );
      }

      setSaveOrUpdateInProgress(false);
    },
    [dispatch, isNewOperationalise, notifyError, notifySuccess, op10zeId, setSaveOrUpdateInProgress]
  );

  const onSnoozeAction = useCallback(
    async (bizActionId: string, timeRange: MuteTimeRange) => {
      setSaveOrUpdateInProgress(true);

      let canUpdateState = true;

      if (!isNewOperationalise) {
        const { error, message } = await operationaliseV2ApiService.snoozeBizActionConfig(
          op10zeId,
          bizActionId,
          timeRange
        );
        if (error) {
          canUpdateState = false;
          notifyError("Error enabling action");
          logger.error("OperationaliseV3", "Error enabling action to existing op10ze", message);
        } else {
          notifySuccess("Action Snoozed successfully");
        }
      }

      if (canUpdateState) {
        dispatch(
          snoozeBizAction({
            bizActionId,
            timeRange
          })
        );
      }

      setSaveOrUpdateInProgress(false);
    },
    [dispatch, isNewOperationalise, notifyError, notifySuccess, op10zeId, setSaveOrUpdateInProgress]
  );

  const onSaveOrUpdateBizAction = useCallback(
    async (bizActionId: string, bizActionConfig: BizActionConfig) => {
      setSaveOrUpdateInProgress(true);

      let canAddToPayload = true;
      const isTemporaryAction = bizActionId === TEMPORARY_ACTION_ID;

      bizActionId = isTemporaryAction ? generateId() : bizActionId;

      if (!isNewOperationalise) {
        const { data, error, message } = await operationaliseV2ApiService.addBizAction(
          bizActionConfig,
          op10zeId,
          bizActionId
        );
        const { actionId } = data || {};
        if (error || !actionId) {
          canAddToPayload = false;
          bizActionId = isTemporaryAction ? TEMPORARY_ACTION_ID : bizActionId;

          const errMessage = isTemporaryAction ? "Error adding action" : "Error updating action";
          notifyError(errMessage);
          logger.error("OperationaliseV3", errMessage, message);
        } else {
          bizActionId = actionId;

          const message = isTemporaryAction ? "Action added successfully" : "Action updated successfully";
          notifySuccess(message);
        }
      }

      if (canAddToPayload) {
        const addActionPayload: SetActionConfigPayload = {
          bizAction: bizActionConfig,
          bizActionId
        };

        dispatch(setBizAction(addActionPayload));
      }

      if (isTemporaryAction) {
        setBizActionState({
          bizActionConfig,
          bizActionId
        });
      }

      setSaveOrUpdateInProgress(false);
    },
    [dispatch, isNewOperationalise, notifyError, notifySuccess, op10zeId, setSaveOrUpdateInProgress]
  );

  const sourceTypeInfoByCategory = useMemo(() => {
    const sourceTypeInfo: Record<string, Record<string, ActionInfo>> = {};
    actionCategories.forEach(actCat => {
      const { actionSourceTypes, actionCategoryType } = actCat;

      const entries: Record<string, ActionInfo> = {};
      actionSourceTypes.forEach(actionSourceType => {
        const { sourceTypeId } = actionSourceType;
        entries[sourceTypeId] = {
          ...actionSourceType,
          categoryId: actionCategoryType
        };
      });

      sourceTypeInfo[actionCategoryType] = entries;
    });
    return sourceTypeInfo;
  }, [actionCategories]);

  const { errors, isValid } = useMemo(
    () => validateOpConfig(tempOpCreationConfig, uiIntegrationActionConfigsMap, sourceTypeInfoByCategory),
    [sourceTypeInfoByCategory, tempOpCreationConfig, uiIntegrationActionConfigsMap]
  );

  const actionsErrors = useMemo(() => {
    let actionsErrors: Record<string, string> = {};

    if (!isValid) {
      const allKeys = Object.keys(errors);
      const actionsErrorKeys = allKeys.filter(key => key.startsWith(OP_ACTION_ERROR_PREFIX));
      actionsErrors = pick(errors, actionsErrorKeys);
    }

    return actionsErrors;
  }, [errors, isValid]);

  const actionErrors = useMemo(() => {
    const errorKeys = Object.keys(actionsErrors).filter(key => {
      const prefix = [OP_ACTION_ERROR_PREFIX, TEMPORARY_ACTION_ID].join(".");
      return key.startsWith(prefix);
    });

    const pickErrors = pick(actionsErrors, errorKeys);
    return Object.values(pickErrors);
  }, [actionsErrors]);

  const onConfirmSwitchToConditionsView = useCallback(() => {
    switchToConditionsView();
  }, [switchToConditionsView]);

  const onCancelSwitchToConditionsView = useCallback(() => {
    closeConfirmation();
  }, [closeConfirmation]);

  const onSwitchToConditionsView = useCallback(() => {
    if (isTemporaryAction) {
      openConfirmation();
    } else {
      onConfirmSwitchToConditionsView();
    }
  }, [isTemporaryAction, onConfirmSwitchToConditionsView, openConfirmation]);

  const changesExist = useMemo(() => {
    const savedBizActionConfig = bizActions[selectedActionId];
    return !isEqual(savedBizActionConfig, selectedBizActionConfig);
  }, [bizActions, selectedActionId, selectedBizActionConfig]);

  return (
    <>
      <OpV3TabBar
        isActionsView
        isConditionsView={false}
        numActions={numActions}
        switchToActionsView={noOp}
        switchToConditionsView={onSwitchToConditionsView}
      />

      {children}

      <div className="operationalise-v3--content">
        {opConfigExists && (
          <div className="operationalise-v3--action-editor-wrapper inc-flex-column inc-flex-grow width-100">
            <VerticallyCenteredRow className="width-100 inc-flex-grow">
              <div className="operationalise-v3--actions-list">
                <OpActionsList
                  actionCategories={actionCategories}
                  errorsByActionId={actionsErrors}
                  onCloneAction={onCloneAction}
                  onCreateAction={onCreateAction}
                  onDeleteAction={onDeleteAction}
                  onDisableAction={onDisableAction}
                  onEnableAction={onEnableAction}
                  onSelectAction={onSelectAction}
                  onSnoozeAction={onSnoozeAction}
                  selectedActionId={selectedActionId}
                  sourceTypeInfo={sourceTypeInfoByCategory}
                  temporaryAction={temporaryAction}
                />
              </div>
              <div className="operationalise-v3--action-editor">
                <ActionsEditor
                  actionErrors={actionErrors}
                  bizActionConfig={selectedBizActionConfig}
                  bizActionId={selectedActionId}
                  changesExist={changesExist}
                  isSavedAction={!isTemporaryAction}
                  mode={mode}
                  onCancel={resetState}
                  onChangeBizAction={onChangeAction}
                  onSaveOrUpdateBizAction={onSaveOrUpdateBizAction}
                  saveOrUpdateInProgress={saveOrUpdateInProgress}
                  sourceTypeInfoByCategory={sourceTypeInfoByCategory}
                  supportsTestActionWithRealAlertOnly={supportsTestActionWithRealAlertOnly}
                />
              </div>
            </VerticallyCenteredRow>

            {isConfirmationOpen && (
              <IncInModalConfirmation
                message="You have unsaved changes. Are you sure you want to leave this page?"
                onCancel={onCancelSwitchToConditionsView}
                onConfirm={onConfirmSwitchToConditionsView}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

const getBizActionConfig = (
  categoryId: ActionCategoryType,
  sourceType: ActionSourceTypes,
  runMode: ActionRunMode
): BizActionConfig => ({
  actionVariablesLookUp: {},
  alertActionConfig: {
    name: startCase(sourceType?.split("-")?.[1] || sourceType),
    actionId: null,
    alertActionConfigDef: {
      alertActionDef: {
        actionCategoryType: categoryId,
        sourceTypeId: sourceType,
        integrationActionConfigId: null,
        connectorId: null,
        params: {},
        staticContext: {},
        actionRunMode: runMode
      },
      idProps: null,
      uberAlertActionDef: null
    }
  }
});

type BizActionState = {
  bizActionId: string;
  bizActionConfig: BizActionConfig;
  supportsTestActionWithRealAlertOnly?: boolean;
};
