import React, { FC, useCallback, useMemo, useRef } from "react";
import { IncSelect, IncSelectOption } from "@inception/ui";
import { last } from "lodash";
import {
  BizDataQuery,
  BuildingBlockConfig,
  BuildingBlockDef,
  SliceSet,
  TimeObj,
  UserServiceFieldSliceSet
} from "../../../../services/api/explore";
import { OpContext } from "../../../context/types";
import { CompareOperator, OpSchedule } from "../../../../services/api/operationalise";
import { VerticallyCenteredRow } from "../../../../components";
import { USFieldWidgetUtils } from "../../../../dashboard/widgets/USField/USFieldWidgetUtils";
import { getRollingFunctionForAgg } from "../../../components/utils";
import { useRefState } from "../../../../core";
import { SCHEDULE_TYPES, UI_SCHEDULE_KEY, getFieldOrMetricNameOptions } from "../../../constants";
import { convertUSFieldSliceSetToTagSlice } from "../../../../utils/ExploreUtils";
import { featureFlagService } from "../../../../services/feature-flags";
import { getRollingFreqFromSchedule } from "../../../context/reducerUtils";
import { getPresetQueryOptions, getSelectedPresetOption, PresetQueryOption } from "./utils";
import { BizDataQuerySliceSetEditor } from "./slice-selector/BizDataQuerySliceSetEditor";
import { OffsetOrRollingFrequencyEditor } from "./OffsetOrRollingFrequencyEditor";

interface Props {
  comparator: CompareOperator;
  onComparatorChange: (comparator: CompareOperator) => void;

  bizDataQuery: BizDataQuery;
  onChange: (bizDataQuery: BizDataQuery) => void;

  pickerContext: OpContext;

  rollingFunction: string;
  onRollingFunctionChange: (rollingFunction: string) => void;

  rollingFreq: TimeObj;
  onRollingFreqChange: (freq: TimeObj) => void;

  schedule: OpSchedule;
  onScheduleChange: (schedule: OpSchedule) => void;

  readOnly: boolean;
}

export const BizDataQueryEditorV2: FC<Props> = props => {
  const {
    bizDataQuery,
    onChange,
    pickerContext,
    comparator,
    onComparatorChange,
    rollingFreq,
    onRollingFreqChange,
    onRollingFunctionChange,
    readOnly,
    schedule,
    onScheduleChange
  } = props;

  const scheduleBasedRollingFreq = useMemo(() => getRollingFreqFromSchedule(schedule), [schedule]);

  const isDemoMode = featureFlagService.isDemoMode();

  const bizDataQueryRef = useRefState(bizDataQuery);

  const { buildingBlockConfig, id: widgetId, widgetConfig } = bizDataQuery || {};
  const { buildingBlockDef } = buildingBlockConfig || {};
  const { fieldConfig, aggregator, sliceDef } = buildingBlockDef || {};

  const defLastSelSlices = sliceDef?.sliceSets;
  const lastSelectedSlicesRef = useRef<UserServiceFieldSliceSet[]>(defLastSelSlices || []);

  const fieldName = fieldConfig?.userServiceField?.fieldName;
  const showAggAndMetric =
    comparator &&
    fieldConfig &&
    fieldConfig.userServiceField &&
    !USFieldWidgetUtils.isEventIDField(fieldName) &&
    !USFieldWidgetUtils.isHasErrorField(fieldName);

  const uiSchedule = schedule?.labels?.[UI_SCHEDULE_KEY];
  const isContinuousSchedule = uiSchedule === SCHEDULE_TYPES.everyMinute;

  const showLookBack = !isContinuousSchedule;
  const showSlices =
    widgetId || widgetConfig ? true : isDemoMode ? defLastSelSlices?.[0]?.slices?.length > 0 : Boolean(aggregator);

  const { eventTypeName } = pickerContext || {};

  const presetQueryOptions = useMemo(
    () => getPresetQueryOptions(bizDataQuery, eventTypeName),
    [bizDataQuery, eventTypeName]
  );
  const selectedPresetQueryOption = useMemo(
    () => getSelectedPresetOption(bizDataQuery, eventTypeName, comparator, presetQueryOptions),
    [bizDataQuery, comparator, eventTypeName, presetQueryOptions]
  );
  const fieldOrMetricOptions = useMemo(
    () => (fieldConfig ? getFieldOrMetricNameOptions(fieldConfig) : []),
    [fieldConfig]
  );
  const selectedFieldOrMetricOpt = useMemo(
    () => fieldOrMetricOptions.find(({ value }) => value === (aggregator || "")),
    [aggregator, fieldOrMetricOptions]
  );

  const onAggChange = useCallback(
    (aggOpt: IncSelectOption) => {
      const bizDataQuery = bizDataQueryRef.current;

      const { value: aggregator, label } = aggOpt;

      const { buildingBlockConfig } = bizDataQuery;
      const bBlockDef = buildingBlockConfig?.buildingBlockDef || ({} as BuildingBlockDef);
      const nBuildingBlockDef: BuildingBlockDef = {
        ...bBlockDef,
        aggregator,
        name: label
      };

      const nBuildingBlockConfig: BuildingBlockConfig = {
        ...buildingBlockConfig,
        buildingBlockDef: nBuildingBlockDef
      };

      let sliceSet: SliceSet;

      // Reset rolling function and frequency if no aggregator exists
      if (!aggregator) {
        onRollingFreqChange(null);
        onRollingFunctionChange(null);
        nBuildingBlockConfig.aggregator = null;
        nBuildingBlockDef.sliceDef = {
          bizFields: [],
          sliceSets: []
        };
      } else {
        if (!rollingFreq) {
          onRollingFreqChange({
            ...scheduleBasedRollingFreq
          });
        }
        const rollingFunc = getRollingFunctionForAgg(aggregator);
        onRollingFunctionChange(rollingFunc);

        if (!nBuildingBlockDef.sliceDef.sliceSets?.length) {
          nBuildingBlockDef.sliceDef.sliceSets = [...lastSelectedSlicesRef.current];
        }
        sliceSet = convertUSFieldSliceSetToTagSlice(last(nBuildingBlockDef.sliceDef.sliceSets));
      }

      onChange({
        ...bizDataQuery,
        buildingBlockConfig: nBuildingBlockConfig,
        sliceSpec: {
          ...bizDataQuery.sliceSpec,
          sliceSet
        }
      });
    },
    [bizDataQueryRef, onChange, onRollingFreqChange, onRollingFunctionChange, rollingFreq, scheduleBasedRollingFreq]
  );

  const onSliceSetsChange = useCallback(
    (sliceSets: UserServiceFieldSliceSet[], bizDataQuery: BizDataQuery) => {
      lastSelectedSlicesRef.current = sliceSets;
      onChange(bizDataQuery);
    },
    [onChange]
  );

  const onPresetQueryOptChange = useCallback(
    (opt: PresetQueryOption) => {
      const { data, label } = opt;
      onComparatorChange(data.comparator);

      if (!data.comparator) {
        onAggChange({
          label,
          value: null
        });
      } else if (data.aggregator) {
        onAggChange({
          label,
          value: data.aggregator
        });
      }
    },
    [onAggChange, onComparatorChange]
  );

  return (
    <>
      <IncSelect
        autoAdjustWidth
        autoSort={false}
        isSearchable={false}
        onChange={onPresetQueryOptChange}
        options={presetQueryOptions}
        value={selectedPresetQueryOption}
        wrapperClass="op-condition-editor--opt-select"
      />

      {showAggAndMetric && (
        <>
          <VerticallyCenteredRow className="marginLt8 marginRt8 inc-label-common">in</VerticallyCenteredRow>

          <IncSelect
            autoAdjustWidth
            autoSort={false}
            isSearchable={false}
            onChange={onAggChange}
            options={fieldOrMetricOptions}
            value={selectedFieldOrMetricOpt}
            wrapperClass="field-or-metric-selector"
          />
        </>
      )}

      {showSlices && (
        <>
          <VerticallyCenteredRow className="marginLt8 marginRt8 inc-label-common">for each</VerticallyCenteredRow>

          <BizDataQuerySliceSetEditor
            bizDataQuery={bizDataQuery}
            onChange={onSliceSetsChange}
            onRollingFunctionChange={onRollingFunctionChange}
            pickerContext={pickerContext}
          />
        </>
      )}

      {!isContinuousSchedule && (
        <OffsetOrRollingFrequencyEditor
          onRollingFreqChange={onRollingFreqChange}
          onScheduleChange={onScheduleChange}
          readOnly={readOnly}
          rollingFreq={rollingFreq}
          schedule={schedule}
          skipPrefixForRollingFreq
          skipRollingFrequency={!showLookBack}
        />
      )}
    </>
  );
};
