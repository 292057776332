import { IncSelect, IncSelectOption } from "@inception/ui";
import React, { useMemo, useState, useEffect } from "react";
import {
  ArrayReductionConfig,
  AggregationOperator,
  AggregationOperatorMap,
  PercentileLabelMap
} from "../transform-config/TransformConfig";

interface ArrayReductionCompProps {
  config: ArrayReductionConfig;
  onChange: (config: ArrayReductionConfig) => void;
}

export const ArrayReductionComp: React.FC<ArrayReductionCompProps> = props => {
  const { onChange, config } = props;
  const [selectedOp, setSelectedOp] = useState<OperatorOption>();

  const opOptions: OperatorOption[] = useMemo(
    () =>
      Object.keys(AggregationOperatorMap).map(op => ({
        label: AggregationOperatorMap[op as AggregationOperator],
        value: op,
        op: op as AggregationOperator
      })),
    []
  );

  const percentileOptions: PercentileOption[] = useMemo(
    () =>
      Object.keys(PercentileLabelMap).map(label => ({
        label: label,
        value: label,
        percentile: PercentileLabelMap[label]
      })),
    []
  );

  const [selectedPerc, setSelectedPerc] = useState<PercentileOption>(percentileOptions[0]);

  useEffect(() => {
    if (
      (selectedOp && selectedOp?.op !== config?.op) ||
      (config?.params?.length && selectedPerc?.percentile !== config?.params[0]?.longVal)
    ) {
      const newConfig: ArrayReductionConfig = {
        op: selectedOp.value as AggregationOperator,
        params: []
      };

      if (newConfig.op === "percentile" && selectedPerc) {
        newConfig.params = [
          {
            longVal: selectedPerc.percentile
          }
        ];
      }
      onChange(newConfig);
    }
  }, [config, onChange, selectedOp, selectedPerc]);

  useEffect(() => {
    if (config) {
      const selectedOp = opOptions.find(op => op.value === config.op);
      if (config.op === "percentile") {
        //longVal from backend coming as string, strict equality check below is failing, so disabling eqeqeq
        //eslint-disable-next-line eqeqeq
        const selectedPerc = percentileOptions.find(op => op.percentile == config.params[0]?.longVal);
        setSelectedPerc(selectedPerc);
      }
      setSelectedOp(selectedOp);
    }
  }, [config, opOptions, percentileOptions]);

  return (
    <div className="array-red-container">
      <IncSelect
        className="op-selector"
        onChange={setSelectedOp}
        options={opOptions}
        value={selectedOp}
      />
      {config?.op === "percentile" && (
        <IncSelect
          className="percentile-selector"
          onChange={setSelectedPerc}
          options={percentileOptions}
          value={selectedPerc}
        />
      )}
    </div>
  );
};

interface OperatorOption extends IncSelectOption {
  op: AggregationOperator;
}

interface PercentileOption extends IncSelectOption {
  percentile: number;
}
