import React, { FC, memo, useEffect } from "react";
import { BizEntityFiltersProps } from "../types";
import { useSeriesSelection } from "../hooks";
import { DataDefinitionFilter } from "./DataDefinitionFilter";

export const BizEntityFilters: FC<BizEntityFiltersProps> = memo(props => {
  const {
    resultMeta,
    widgetResponseDTO,
    onFiltersExtracted,
    isPostAggResultMeta,
    allowPartialMatch = false,
    ...dataDefProps
  } = props;

  const { querySchema, widgetConfig } = widgetResponseDTO || {};
  const querySchemas = querySchema?.querySchema;

  const { bizFilter, seriesFilters } = useSeriesSelection(
    resultMeta,
    widgetConfig,
    querySchemas,
    isPostAggResultMeta,
    allowPartialMatch
  );

  useEffect(() => {
    onFiltersExtracted && onFiltersExtracted(bizFilter, seriesFilters);
  }, [bizFilter, onFiltersExtracted, seriesFilters]);

  const shouldRenderFilter = Boolean(bizFilter?.filterValues?.length) || Boolean(seriesFilters?.length);

  return (
    shouldRenderFilter && (
      <DataDefinitionFilter
        {...dataDefProps}
        bizFilter={bizFilter}
        seriesFilters={seriesFilters}
        widgetResponseDTO={widgetResponseDTO}
      />
    )
  );
});
