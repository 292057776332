import { last } from "lodash";
import React, { FC, useCallback, useMemo } from "react";
import { EventGroupBySelector } from "../../../../components";
import { useTimeRange, useRefState } from "../../../../core";
import {
  BizDataQuery,
  UserServiceFieldSliceSet,
  FieldPickerContextDTO,
  BuildingBlockDef,
  BuildingBlockConfig,
  WidgetConfigUtils,
  DemoDataParams
} from "../../../../services/api/explore";
import { convertUSFieldSliceSetToTagSlice } from "../../../../utils/ExploreUtils";

interface Props {
  bizDataQuery: BizDataQuery;
  onChange: (sliceSet: UserServiceFieldSliceSet[], bizDataQuery: BizDataQuery) => void;
  isLoading: boolean;
  demoDataParams?: DemoDataParams;
}

export const BuildingBlockSliceSelector: FC<Props> = props => {
  const { bizDataQuery, onChange, isLoading, demoDataParams } = props;

  const { getLatestTimeRange } = useTimeRange();

  const { buildingBlockConfig } = bizDataQuery;
  const { buildingBlockDef } = buildingBlockConfig;
  const { aggregator } = buildingBlockDef;

  const bizDataQueryRef = useRefState(bizDataQuery);
  const { entityTypeId, eventTypeId } = useMemo(
    () => WidgetConfigUtils.getEntityTypeAndEventTypeFromBizDataQuery(bizDataQuery),
    [bizDataQuery]
  );

  const fieldPickerContext = useMemo<FieldPickerContextDTO>(
    () => ({
      entityId: entityTypeId ? null : eventTypeId,
      entityName: null,
      entityType: entityTypeId,
      userServices: entityTypeId && eventTypeId ? [{ userServiceEntityId: eventTypeId }] : [],
      showFields: true
    }),
    [entityTypeId, eventTypeId]
  );

  const onSliceSetsChange = useCallback(
    (sliceSets: UserServiceFieldSliceSet[]) => {
      const bizDataQuery = bizDataQueryRef.current;
      const { buildingBlockConfig } = bizDataQuery;
      const { buildingBlockDef } = buildingBlockConfig;

      const nBuildingBlockDef: BuildingBlockDef = {
        ...buildingBlockDef
      };

      nBuildingBlockDef.sliceDef.sliceSets = sliceSets;
      const nBuildingBlockConfig: BuildingBlockConfig = {
        ...buildingBlockConfig,
        buildingBlockDef: nBuildingBlockDef
      };

      const sliceSet = convertUSFieldSliceSetToTagSlice(last(sliceSets));

      onChange(sliceSets, {
        ...bizDataQuery,
        buildingBlockConfig: nBuildingBlockConfig,
        sliceSpec: {
          ...bizDataQuery.sliceSpec,
          sliceSet
        }
      });
    },
    [bizDataQueryRef, onChange]
  );

  const sliceSets = useMemo(() => buildingBlockDef.sliceDef.sliceSets, [buildingBlockDef]);

  return aggregator ? (
    <EventGroupBySelector
      canRemoveImplicitSlice
      demoDataParams={demoDataParams}
      displayImplicitSlice
      fieldPickerContext={fieldPickerContext}
      getLatestTimeRange={getLatestTimeRange}
      hideLabel
      isLoading={isLoading}
      onChange={onSliceSetsChange}
      sliceSets={sliceSets}
    />
  ) : (
    <></>
  );
};
