import React, { useCallback, useEffect, useState } from "react";
import { useClipboard, useNotifications } from "../core";
import { ReactComponent as CopyIcon } from "../../images/icons/Copy.svg";

export type CopyToClipboardProps = {
  text: string;
};

const CopyToClipboard: React.FC<CopyToClipboardProps> = props => {
  const { text } = props;

  const [copyText, setCopyText] = useState<string>(text);
  const { addToClipboard } = useClipboard();
  const { notifySuccess } = useNotifications();

  const copyFieldValueToClipboard = useCallback(() => {
    addToClipboard(copyText);
    notifySuccess("Copied to clipboard");
  }, [addToClipboard, copyText, notifySuccess]);

  useEffect(() => {
    setCopyText(text);
  }, [text]);

  return (
    <>
      <CopyIcon
        onClick={copyFieldValueToClipboard}
        style={{ cursor: "pointer" }}
      />
    </>
  );
};

export default CopyToClipboard;
