import { useCallback, useMemo } from "react";
import { FetchFn, InitialState, useDataFetch } from "../../../../core";
import { EntityNamesResponse, fieldPickerApiService } from "../../../../services/api/explore";

export const useFetchEntityLookUp = (entityIds: string[]) => {
  const initialState = useMemo<InitialState<EntityNamesResponse, string>>(
    () => ({
      data: null,
      error: "",
      isError: false,
      isSuccess: false,
      isFetching: false
    }),
    []
  );

  const fetchUseCaseSampleValues = useCallback<FetchFn<EntityNamesResponse, string>>(async () => {
    const result = {
      data: null as EntityNamesResponse,
      error: null as string,
      isError: false,
      isSuccess: false,
      isFetching: false
    };

    try {
      const response = await fieldPickerApiService.getEntityLookup(entityIds);

      if (response.data) {
        result.data = response.data;
        result.isError = false;
        result.error = "";
        result.isFetching = false;
        result.isSuccess = true;
      }
    } catch (error) {
      result.error = error.message;
      result.isError = true;
      result.isFetching = false;
    }

    return result;
  }, [entityIds]);

  return useDataFetch(fetchUseCaseSampleValues, initialState, true);
};
