import React, { FC, useMemo, useCallback } from "react";
import { computeFurtherOccurrencesForCron } from "@inception/ui";
import { isEqual } from "lodash";
import { VerticallyCenteredRow } from "../../../../components";
import { USFieldWidgetUtils } from "../../../../dashboard/widgets/USField/USFieldWidgetUtils";
import { BizDataQuery, UserServiceFieldMetricConfigDefinition } from "../../../../services/api/explore";
import { OpSchedule, OpThreshold } from "../../../../services/api/operationalise";
import { SCHEDULE_TYPES, UI_SCHEDULE_KEY } from "../../../constants";
import { setThreshold, useOpStore } from "../../../context";
import { getCronExpressionForSpecificSchedule, getMetricSliceSet } from "../../../utils";
import { scheduleOptions } from "../ScheduleEditor/options";
import { ThresholdEditorV2 } from "./ThresholdEditorV2";

interface Props {}

export const ThresholdEditorWrapper: FC<Props> = () => {
  const { state, dispatch } = useOpStore();

  const { opCreationConfig, context, readOnly } = state;

  const { opCreationConfigDef } = opCreationConfig;
  const {
    bizDataQuery,
    threshold,
    schedule,
    rollingFreq: baseMetricRollingFreq,
    rollingFunction: baseMetricRollingFunction
  } = opCreationConfigDef || {};

  const onThresholdChange = useCallback(
    (threshold: OpThreshold) => {
      dispatch(setThreshold(threshold));
    },
    [dispatch]
  );

  const metricOrFieldName = useMemo(() => getMetricOrFieldName(bizDataQuery), [bizDataQuery]);
  const metricSliceSet = useMemo(() => getMetricSliceSet(bizDataQuery), [bizDataQuery]);

  const buildingBlockDef = bizDataQuery?.buildingBlockConfig?.buildingBlockDef;
  const isEventOperationalize = !buildingBlockDef?.aggregator && context.selectionContext === "field";
  const enableAutomaticThreshold = shouldEnableAutomaticThreshold(schedule);

  const fieldName = buildingBlockDef?.fieldConfig?.userServiceField?.fieldName;
  const isEventIDOperationalize =
    isEventOperationalize &&
    (USFieldWidgetUtils.isEventIDField(fieldName) || USFieldWidgetUtils.isHasErrorField(fieldName));

  const baseMetricAggFunction = useMemo(() => {
    if (bizDataQuery?.buildingBlockConfig) {
      const { buildingBlockDef } = bizDataQuery.buildingBlockConfig;
      return buildingBlockDef?.aggregator;
    } else if (bizDataQuery?.widgetConfig) {
      const { dataDefinition } = bizDataQuery.widgetConfig;
      const { metricId } = bizDataQuery.sliceSpec;
      const metricDef = dataDefinition.metrics[metricId];
      return (metricDef as UserServiceFieldMetricConfigDefinition)?.userServiceFieldMetricConfig?.aggregator;
    }

    return "count";
  }, [bizDataQuery]);

  return (
    <>
      {!isEventIDOperationalize && (
        <div className="op-threshold-editor">
          <VerticallyCenteredRow className="inc-text-body-medium inc-text-inactive marginBt16">
            Threshold
          </VerticallyCenteredRow>

          {Boolean(threshold) && (
            <ThresholdEditorV2
              baseMetricAggFunction={baseMetricAggFunction}
              baseMetricRollingFreq={baseMetricRollingFreq}
              baseMetricRollingFunction={baseMetricRollingFunction}
              bizDataQuery={bizDataQuery}
              enableAutomaticThreshold={enableAutomaticThreshold}
              isEventIDOperationalize={isEventIDOperationalize}
              isEventOperationalize={isEventOperationalize}
              metricOrFieldName={metricOrFieldName}
              metricSliceSet={metricSliceSet}
              onChange={onThresholdChange}
              opThreshold={threshold}
              pickerContext={context}
              readonly={readOnly}
            />
          )}
        </div>
      )}
    </>
  );
};

const getMetricOrFieldName = (bizDataQuery: BizDataQuery): string => {
  const { buildingBlockConfig, widgetConfig, sliceSpec } = bizDataQuery || {};

  if (buildingBlockConfig) {
    const { buildingBlockDef, name } = buildingBlockConfig;
    return buildingBlockDef?.name || name;
  } else if (widgetConfig) {
    const { dataDefinition, labels, name: widgetName } = widgetConfig;
    const { metricId } = sliceSpec;
    const metricDef = dataDefinition.metrics[metricId] as UserServiceFieldMetricConfigDefinition;
    return labels?.name || metricDef?.name || widgetName || "";
  }

  return "";
};

const shouldEnableAutomaticThreshold = (opSchedule: OpSchedule) => {
  if (opSchedule) {
    const { labels, schedule } = opSchedule;

    const scheduleStr = labels?.[UI_SCHEDULE_KEY];
    const existsInLabels = [
      SCHEDULE_TYPES.everyMinute,
      SCHEDULE_TYPES.every5Minutes,
      SCHEDULE_TYPES.every15Minutes,
      SCHEDULE_TYPES.every30Minutes,
      SCHEDULE_TYPES.everyHour,
      SCHEDULE_TYPES.everyDay,
      SCHEDULE_TYPES.everyWeek,
      SCHEDULE_TYPES.everyMonth
    ].includes(scheduleStr);

    if (existsInLabels) {
      return true;
    }

    if (schedule?.specificScheduleConfig) {
      const opSchCronExpr = getCronExpressionForSpecificSchedule(schedule.specificScheduleConfig);
      const matchingOpt = scheduleOptions.find(opt => {
        if (opt.data) {
          const optCronExpr = getCronExpressionForSpecificSchedule(opt.data);

          const optCronOccurrances = computeFurtherOccurrencesForCron(optCronExpr, 3);
          const cronOccurrances = computeFurtherOccurrencesForCron(opSchCronExpr, 3);

          return isEqual(optCronOccurrances, cronOccurrances);
        }

        return false;
      });

      return Boolean(matchingOpt);
    }
  }

  return false;
};
