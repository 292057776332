import { cloneDeep } from "lodash";
import { TimeRange } from "../../../../../../core";
import timeRangeUtils from "../../../../../../utils/TimeRangeUtils";
import { CatalogVizRendererProps } from "../common";
import { CatalogWidgetFetchDataPayload } from "../../../types";
import { LimitSpecFunction, OverTagPostAgg, OverTimePostAgg, SliceSpec } from "../../../../../../services/api/explore";
import { SortAndLimit } from "../../../../../../components";
import { CatalogQueryConfig } from "../../../models";

export const getWidgetTitleBasedOnTimeRange = (timeRange: TimeRange, widgetTitle: string, type: "bar" | "ss") => {
  const { raw } = timeRange;
  const label = timeRangeUtils.getLabelByTimeRange(raw.from, raw.to);
  const isRalative = timeRangeUtils.isRelativeTime(raw.from);
  const inString = isRalative ? "in" : "from";
  const title = type === "ss" ? `${widgetTitle} ${inString} ${label}` : `${widgetTitle} per day ${inString} ${label}`;
  return title;
};

export const getBarChartProps = (
  dataFetchPayload: CatalogWidgetFetchDataPayload,
  childrenDataFetchPayload?: CatalogWidgetFetchDataPayload,
  metricIds?: string[]
): Pick<CatalogVizRendererProps, "metricId" | "childMetricIds" | "childrenDataFetchPayload" | "dataFetchPayload"> => {
  const payload = cloneDeep(dataFetchPayload);
  const [firstMetric, ...restMetrcis] = metricIds || [];
  payload.sliceSpec = formatSliceSpecBasedOnAggregator(
    payload.sliceSpec,
    [],
    true,
    [firstMetric || ""],
    undefined,
    undefined,
    true
  );
  let childDataPayload: CatalogWidgetFetchDataPayload;
  if (childrenDataFetchPayload) {
    const childPayload = cloneDeep(childrenDataFetchPayload);
    const sliceSpec: SliceSpec[] = [];
    restMetrcis?.forEach((metricId, idx) => {
      if (childPayload?.sliceSpec?.length > idx) {
        const spec = childPayload.sliceSpec[idx];
        sliceSpec.push({
          ...spec,
          metricId
        });
      } else {
        const spec = payload.sliceSpec[0];
        sliceSpec.push({
          ...spec,
          metricId
        });
      }
    });
    childPayload.sliceSpec = sliceSpec;
    childDataPayload = childPayload;
  } else {
    const childPayload = cloneDeep(dataFetchPayload);
    const sliceSpec: SliceSpec[] = [];
    restMetrcis?.forEach((metricId, idx) => {
      if (childPayload?.sliceSpec?.length > idx) {
        const spec = childPayload.sliceSpec[idx];
        sliceSpec.push({
          ...spec,
          metricId
        });
      } else {
        const spec = payload.sliceSpec[0];
        sliceSpec.push({
          ...spec,
          metricId
        });
      }
    });
    childPayload.sliceSpec = sliceSpec;
    childDataPayload = childPayload;
  }
  payload.sliceSpec.forEach(spec => {
    const postAgg = spec.postAgg as OverTimePostAgg;
    postAgg.isSingleStatQuery = false;
    if (postAgg.overTimeAgg) {
      postAgg.overTimeAgg.timeInSeconds = Math.floor(postAgg.overTimeAgg.timeInSeconds / 10);
    }
  });
  return {
    metricId: firstMetric,
    dataFetchPayload: payload,
    childrenDataFetchPayload: childDataPayload,
    childMetricIds: restMetrcis
  };
};

export const getTableProps = (
  dataFetchPayload: CatalogWidgetFetchDataPayload,
  childrenDataFetchPayload: CatalogWidgetFetchDataPayload,
  topNLimit: SortAndLimit,
  aggregatedTags: string[],
  metricIds: string[],
  pQueryConfig: CatalogQueryConfig
): Pick<
  CatalogVizRendererProps,
  | "seriesLimit"
  | "aggregatedTags"
  | "childrenDataFetchPayload"
  | "dataFetchPayload"
  | "metricId"
  | "childMetricIds"
  | "queryConfig"
> => {
  const parentPayload = cloneDeep(dataFetchPayload);
  const [firstMetric, ...restMetrics] = metricIds || [];
  parentPayload.sliceSpec = formatSliceSpecBasedOnAggregator(
    parentPayload.sliceSpec,
    aggregatedTags,
    true,
    [firstMetric || ""],
    topNLimit.limit,
    topNLimit.sortType,
    true
  ).filter(e => e.metricId);
  let childPayload = cloneDeep(childrenDataFetchPayload);
  if (childPayload) {
    const sliceSpec = formatSliceSpecBasedOnAggregator(
      childPayload.sliceSpec,
      aggregatedTags,
      true,
      restMetrics
    ).filter(e => e.metricId);
    if (sliceSpec.length) {
      childPayload.sliceSpec = sliceSpec;
    } else {
      childPayload = null;
    }
  }
  const queryConfig = cloneDeep(pQueryConfig);
  if (queryConfig.sourceQueryConfig.queryType === "widgetConfig") {
    queryConfig.sourceQueryConfig.childMetricIds = restMetrics;
    queryConfig.sourceQueryConfig.metricId = firstMetric;
  }

  return {
    dataFetchPayload: parentPayload,
    seriesLimit: topNLimit.limit,
    aggregatedTags: aggregatedTags,
    childrenDataFetchPayload: childPayload,
    metricId: firstMetric,
    childMetricIds: restMetrics,
    queryConfig
  };
};

const formatSliceSpecBasedOnAggregator = (
  sliceSpec: SliceSpec[],
  aggregatedTags?: string[],
  useMetricId?: boolean,
  metricId?: string[],
  limit?: number,
  type?: LimitSpecFunction,
  shouldUseFirstMetricId?: boolean
): SliceSpec[] =>
  sliceSpec.map((e, i) => {
    const postAgg = e.postAgg as OverTagPostAgg;
    if (aggregatedTags) {
      postAgg.overTagAgg = {
        aggregator: postAgg.overTagAgg.aggregator,
        tagName: aggregatedTags
      };
    }
    if (limit) {
      const limitSpec = {
        function: type,
        limit: limit
      };
      if (postAgg?.sortSpec?.limitSpec) {
        postAgg.sortSpec.limitSpec = limitSpec;
      } else {
        postAgg.sortSpec = {
          ...postAgg.sortSpec,
          limitSpec
        };
      }
    }
    return {
      ...e,
      metricId: useMetricId ? metricId?.[i] || (shouldUseFirstMetricId ? metricId?.[0] : null) : e?.metricId,
      postAgg
    };
  });
