import { toString } from "cronstrue";
import { generateId } from "../../core";
import { BizDataQuery, WidgetConfigDTO, WidgetConfigUtils } from "../../services/api/explore";
import { CompareOperator, OpSchedule } from "../../services/api/operationalise";
import { getDtoFromWidgetConfig } from "../../utils/ExploreUtils";
import { SCHEDULE_TYPES, UI_SCHEDULE_KEY } from "../constants";
import { getCronExpressionForSpecificSchedule } from "../utils";

export const shouldEnableAutomaticThreshold = (scheduleStr: string) =>
  [
    SCHEDULE_TYPES.everyMinute,
    SCHEDULE_TYPES.every5Minutes,
    SCHEDULE_TYPES.every15Minutes,
    SCHEDULE_TYPES.every30Minutes,
    SCHEDULE_TYPES.everyHour,
    SCHEDULE_TYPES.everyDay,
    SCHEDULE_TYPES.everyWeek,
    SCHEDULE_TYPES.everyMonth
  ].includes(scheduleStr);

export const getComparatorAndScheduleText = (schedule: OpSchedule, comparator: CompareOperator) => {
  let comparatorText = "";
  let altComparatorText = "";
  let scheduleText = "";

  comparatorText =
    comparator === CompareOperator.Above
      ? "spike"
      : comparator === CompareOperator.Below
        ? "drop"
        : (comparatorText = comparator === CompareOperator.AboveOrBelow ? "spike/drop" : "");

  altComparatorText =
    comparator === CompareOperator.Above
      ? "more"
      : comparator === CompareOperator.Below
        ? "less"
        : comparator === CompareOperator.AboveOrBelow
          ? "more/less"
          : "";

  const { schedule: scheduleObj, labels } = schedule || {};

  if (labels?.[UI_SCHEDULE_KEY]) {
    scheduleText = labels[UI_SCHEDULE_KEY];
    scheduleText = scheduleText === "custom" ? "" : scheduleText;
  } else if (scheduleObj?.specificScheduleConfig) {
    const cronExpr = getCronExpressionForSpecificSchedule(scheduleObj?.specificScheduleConfig);
    scheduleText = cronExpr ? toString(cronExpr).toLowerCase().replaceAll("every", "") : "";
  }

  return {
    comparatorText,
    altComparatorText,
    scheduleText
  };
};

export const getWidgetConfigForKPI = (bizDataQuery: BizDataQuery): [WidgetConfigDTO, string, string[]] => {
  const { buildingBlockConfig, widgetConfig, idProps, sliceSpec } = bizDataQuery;
  const aggregateTags = (sliceSpec?.sliceSet?.slices || []).map(slice => slice.tagName);

  if (widgetConfig) {
    return [getDtoFromWidgetConfig(widgetConfig), sliceSpec.metricId, aggregateTags];
  }

  const { entityTypeId, eventTypeId } = WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(idProps);
  const metricId = sliceSpec.buildingBlockConfigId || generateId();

  const { name, buildingBlockDef, cohortId } = buildingBlockConfig || {};
  const widgetConfigDto: WidgetConfigDTO = {
    dataDefinition: {
      fields: {},
      metrics: {
        [metricId]: {
          id: metricId,
          name,
          sourceType: "userServiceField",
          doNotSave: true,
          userServiceFieldMetricConfig: {
            aggregator: buildingBlockDef?.aggregator || "avg",
            userServiceField: buildingBlockDef?.fieldConfig?.userServiceField,
            eventFilters: {
              userServiceFilters: [
                {
                  userServiceFilterExpressions: buildingBlockDef?.filters?.filterExpressions || []
                }
              ]
            },
            sliceSets: buildingBlockDef?.sliceDef?.sliceSets || [
              {
                slices: []
              }
            ]
          }
        }
      }
    },
    cohortDefinition: cohortId
      ? {
          cohortId,
          name: cohortId
        }
      : null,
    isStatic: false,
    name,
    bizEntityType: entityTypeId,
    userServiceEntityId: entityTypeId ? null : eventTypeId,
    visualizations: [
      {
        dataDefs: [
          {
            enabled: true,
            id: metricId,
            type: "userServiceField"
          }
        ],
        id: metricId,
        type: "timeseries"
      }
    ]
  };

  return [widgetConfigDto, metricId, aggregateTags];
};
