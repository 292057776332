// hard coding others value to 15 precent;
export const OTHERS_PERCENT = 15;
export const OTHERS_LABEL = "Others";

export type HighChartsDrilldownSeries<T> = {
  name: string;
  id: string;
  series: T[];
  custom?: Record<string, any>;
};

export type IncHCCustomCommon = Record<string, any> & {
  compareData?: number | string;
  invertColors?: boolean;
  isOthersSeries?: boolean;
};
