import { XAxisOptions } from "highcharts";
import { merge } from "lodash";
import { HighChartsNumberFormatter, getFormattedDateTime, getInceptionTheme } from "@inception/ui";
import { getXAxisLabelsAndFormat } from "../../time-series/ChartOptionsBuilder";
import { TimeRange } from "../../../core";
import { FONT_STYLE } from "../../charts";
import { IncVBarSeriesOptions } from "../../bar-chart/types";
import { incidentTimelineTooltipFormatter } from "../IncidentTimeline/utils";
import { BinnedCorrelatedEventsInfo } from "./types";

const X_AXIS_ID = "X-Axis";
const actionPrefix = "imp-";

export const getChangeEventTimelineXAxisOptions = (
  binnedCorrelatedEvents: BinnedCorrelatedEventsInfo[],
  impactWidgetThreads: number[],
  timeRange: TimeRange,
  width: number,
  corEventsPrefix: string
) => {
  const { labelFormat: xAxisLabelFormat } = getXAxisLabelsAndFormat(timeRange, width);
  const correlatedEventsSeconds = binnedCorrelatedEvents.map(ce => ce.timeStampSecs * 1000);

  const filteredAxisLabels = impactWidgetThreads.filter(secs => !correlatedEventsSeconds.includes(secs));

  const tickPositioners = [...filteredAxisLabels, ...correlatedEventsSeconds];

  const xAxisOptions: XAxisOptions = merge({
    type: "datetime",
    grid: {
      enabled: false
    },
    offset: 0,
    title: {
      text: "",
      style: {
        color: getInceptionTheme().plugins.graph.legendTextHover,
        marginTop: 26
      }
    },
    tickPositions: tickPositioners,
    tickLength: 0,
    labels: {
      enabled: true,
      align: "center",
      style: FONT_STYLE,
      x: 0,
      y: 46,
      useHTML: true,
      formatter: that => {
        const intVal = parseInt(that.value.toString(), 10);
        const value = intVal;
        const correlatedEventSec = correlatedEventsSeconds.find(x => x === intVal);
        if (correlatedEventSec > 0) {
          return `<div id=${corEventsPrefix}${intVal.toString()} />`;
        } else if (filteredAxisLabels.includes(intVal)) {
          const formattedString = getFormattedDateTime(value, xAxisLabelFormat, {
            withSeconds: false
          });
          return `<span class="inc-text-subtext-medium inc-text-color-secondary" id=${actionPrefix}${intVal.toString()}>${formattedString}</span>`;
        }
        return "";
      }
    },
    id: X_AXIS_ID
  } as XAxisOptions);
  return xAxisOptions;
};

export const getChangeEventTimelineHighChartOptions = (
  hOptions: Highcharts.Options,
  width: number,
  height: number,
  xAxisOptions: Highcharts.XAxisOptions,
  series: IncVBarSeriesOptions[],
  accessorHeaderMap?: Record<string, string>
) => {
  const options = {
    ...hOptions,
    yAxis: [
      {
        gridLineWidth: 0,
        visible: false,
        title: {
          text: ""
        }
      }
    ],
    title: {
      text: ""
    },
    chart: {
      width,
      height: height - 10,
      backgroundColor: "transparent",
      spacingBottom: 0,
      numberFormatter: HighChartsNumberFormatter
    },
    xAxis: xAxisOptions,
    tooltip: {
      ...hOptions.tooltip,
      className: "highcharts-tooltip",
      shared: true,
      useHTML: true,
      formatter: function () {
        return incidentTimelineTooltipFormatter.apply(this as any, [accessorHeaderMap, false, ""]);
      }
    },
    series: series
  };
  return options as Highcharts.Options;
};
