import { IncTextfield } from "@inception/ui";
import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { EntityPropertyValue } from "../../../../../core";
import { ActionTemplatePropertyEditorProps } from "./types";

export const TextBoxEditor: FC<ActionTemplatePropertyEditorProps> = memo(props => {
  const { onPropsChange, property, value } = props;

  const { defaultValue, description, label, propId, required, type: propType } = property;

  const type = propType === "_long" ? "number" : "text";
  const valueKey: keyof EntityPropertyValue = propType === "_long" ? "longVal" : "stringVal";

  const defValue = value?.[valueKey] || "";
  const [textValue, setTextValue] = useState(defValue);

  useEffect(() => {
    const defValue = value?.[valueKey] || "";
    setTextValue(defValue);
  }, [valueKey, value]);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTextValue(value);
  }, []);

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      let fValue: string | number = e.target.value;

      if (propType === "_long") {
        const nValue = parseInt(fValue, 10);
        fValue = isNaN(nValue) ? defaultValue?.longVal || 3 : nValue;
      }

      onPropsChange(propId, {
        [valueKey]: fValue
      });
    },
    [defaultValue?.longVal, valueKey, onPropsChange, propId, propType]
  );

  return (
    <IncTextfield
      helpText={description}
      label={label}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={label}
      required={required}
      type={type}
      value={textValue}
    />
  );
});
