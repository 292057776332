import ConstantVariableImpl from "../model-impl/ConstantVariableImpl";
import CustomVariableImpl from "../model-impl/CustomVariableImpl";
import QueryVariableImpl from "../model-impl/QueryVariableImpl";
import VariableImpl from "../model-impl/VariableImpl";
import { VariableType } from "../models/VariableModel";
import CohortVariableImpl from "../model-impl/CohortVariableImpl";
import EventVariableImpl from "../model-impl/EventVariableImpl";
import EventExpressionVariableImpl from "../model-impl/EventExpressionVariableImpl";

class VariableUtils {
  getVariableByType(type: VariableType): VariableImpl {
    switch (type) {
      case VariableType.Cohort: {
        return new CohortVariableImpl({});
      }

      case VariableType.Event: {
        return new EventVariableImpl({});
      }

      case VariableType.EventExpression: {
        return new EventExpressionVariableImpl({});
      }

      case VariableType.Constant: {
        return new ConstantVariableImpl({});
      }

      case VariableType.Custom: {
        return new CustomVariableImpl({});
      }

      case VariableType.Query: {
        return new QueryVariableImpl({});
      }

      default: {
        return new VariableImpl({});
      }
    }
  }
}

export const variableUtils = new VariableUtils();
