import { ComputedCell, TooltipProps } from "@nivo/heatmap";
import React, { FC, memo } from "react";
import { IncHeatMapDatum, IncHeatMapTooltipFormatter } from "./types";

type Props = TooltipProps<IncHeatMapDatum> & {
  formatter?: IncHeatMapTooltipFormatter;
};

export const HeatMapTooltip: FC<Props> = memo(props => {
  const { cell, formatter } = props;

  const tooltipText = getTooltipText(cell, formatter);
  return tooltipText ? (
    <div className="highcharts-tooltip">
      <div className="inc-charts-tooltip">{getTooltipText(cell, formatter)}</div>
    </div>
  ) : (
    <></>
  );
});

const getTooltipText = (cell: ComputedCell<IncHeatMapDatum>, formatter: Props["formatter"]) => {
  if (formatter) {
    return formatter(cell);
  }

  return cell.formattedValue;
};
