import { IncButton, IncTextfield, IncToolTip } from "@inception/ui";
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { TimeZone } from "../../core";
import { FEATURE_FLAGS, featureFlagService } from "../../services/feature-flags";
import { SelectorTimeRange, TimeRangeSelectorProps } from "./types";
import { TimeRangeSelectorPopper } from "./TimeRangeSelectorPopper";

const TimeRangeSelector = forwardRef<HTMLDivElement, TimeRangeSelectorProps>((props, ref) => {
  const {
    timeRange: pTimeRange,
    hideTimeSelect,
    label,
    format: pFormat,
    i18nDisabled,
    buttonDisplay,
    disabled = false,
    disableTooltip = false,
    size = "default"
  } = props;

  const textClassName = size === "small" ? "inc-text-subtext-medium" : "inc-text-body-medium";

  const showTimeZone = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.enableTimezone);

  const timeZone: TimeZone = useMemo(() => timeRangeUtils.getTimeZoneFromTimeRange(pTimeRange), [pTimeRange]);

  const timeRange: SelectorTimeRange = useMemo(
    () => ({
      from: pTimeRange.from.valueOf(),
      to: pTimeRange.to.valueOf(),
      fromLabel: pTimeRange.raw.from,
      toLabel: pTimeRange.raw.to
    }),
    [pTimeRange]
  );

  const initLabelText = timeRangeUtils.getLabelByTimeRange(
    timeRange.fromLabel,
    timeRange.toLabel,
    !hideTimeSelect,
    i18nDisabled,
    pFormat,
    showTimeZone ? timeZone : null
  );
  const [open, setOpen] = useState(false);
  const [labelText, setLabelText] = useState(initLabelText);
  const timeRangeSelectorBtnClass = `time-range-selector-button ${disabled ? "time-range-selector-button--disabled" : ""} ${textClassName}`;
  const timeSelectorLabelInputClass = `time-selector-label-input ${disabled ? "time-selector-label-input--disabled" : ""} ${textClassName}`;

  const onTimeRangeClick = useCallback(() => {
    if (!disabled) {
      setOpen(true);
    }
  }, [disabled]);

  useEffect(() => {
    const newLabel = timeRangeUtils.getLabelByTimeRange(
      timeRange.fromLabel,
      timeRange.toLabel,
      !hideTimeSelect,
      i18nDisabled,
      pFormat,
      showTimeZone ? timeZone : null
    );
    setLabelText(newLabel);
  }, [timeRange, hideTimeSelect, i18nDisabled, pFormat, timeZone, showTimeZone]);

  const btnRef = useRef();

  if (!timeRange) {
    return <></>;
  }

  const timeRangeButton = buttonDisplay ? (
    <IncButton
      className={timeRangeSelectorBtnClass}
      color="link"
      id="time-range-selector-button"
      onClick={onTimeRangeClick}
      size={size === "default" ? "regular" : "small"}
      type="button"
      variant="text"
    >
      {labelText}
    </IncButton>
  ) : (
    <></>
  );

  return (
    <div
      className="time-range-selector-container"
      data-cy="time-range-selector"
      ref={ref}
    >
      {buttonDisplay && label && (
        <div
          className="inc-label-common"
          style={{ marginBottom: "6px" }}
        >
          {label}
        </div>
      )}
      <span ref={btnRef}>
        {buttonDisplay && (
          <>
            {disableTooltip && timeRangeButton}
            {!disableTooltip && (
              <IncToolTip
                placement="top"
                titleId="timerange.change"
              >
                {timeRangeButton}
              </IncToolTip>
            )}
          </>
        )}

        {!buttonDisplay && (
          <IncTextfield
            className={timeSelectorLabelInputClass}
            label={label}
            onChange={() => {}}
            onClick={onTimeRangeClick}
            readOnly={true}
            value={labelText}
          />
        )}
      </span>

      <TimeRangeSelectorPopper
        {...props}
        anchorEl={btnRef.current}
        onClose={() => setOpen(false)}
        open={open}
        showTimeZone={showTimeZone}
      />
    </div>
  );
});

export default TimeRangeSelector;
