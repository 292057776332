import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IncSelect, IncSelectOption, IncTextfield } from "@inception/ui";
import { cloneDeep, isEqual } from "lodash";
import {
  BizDataQuery,
  BuildingBlockConfig,
  FieldPickerContextDTO,
  ImpactedWidget,
  UserServiceField,
  UserServiceFieldSliceSet,
  WidgetConfigUtils
} from "../../../../../../platform/services/api/explore";
import { KPIQueryEditor } from "../../../../../../platform/components";
import { getWidgetConfigFromDto } from "../../../../../../platform/utils/ExploreUtils";
import { generateId, useRefState, useToggleState } from "../../../../../../platform/core";
import { UberOperationalizeTypes } from "../../../../../../platform/services/api/operationalise";

type Props = {
  bizDataQuery: BizDataQuery;
  onChange: (bizDataQuery: BizDataQuery) => void;
  defaultSliceSet?: UserServiceFieldSliceSet;
  disableSliceSelection?: boolean;
  disableEntityTypeChange?: boolean;
  impactedWidgets?: ImpactedWidget[];
  pickerContextDto: FieldPickerContextDTO;
  onLoadMetrics?: (loading: boolean) => void;
};

const QueryEditorPopper: FC<Props> = props => {
  const { bizDataQuery: pBizDataQuery, impactedWidgets = [], onChange, pickerContextDto, onLoadMetrics } = props;

  const { entityId, userServices, entityType } = pickerContextDto || {};
  const eventTypeId = entityId || (entityType ? userServices?.[0]?.userServiceEntityId : "");

  const defaultQuery = useMemo(() => getDefaultBizDataQuery(eventTypeId), [eventTypeId]);
  const [bizDataQuery, setBizDataQuery] = useState<BizDataQuery>(null);
  const { close: stopSettingUpQuery, isOpen: isQuerySetupInProgress, open: startSettingUpQuery } = useToggleState(true);

  const fetchWidgetConfigOnEdit = useCallback(
    async (pBizDataQuery: BizDataQuery) => {
      if (pBizDataQuery) {
        if (!pBizDataQuery?.widgetConfig) {
          startSettingUpQuery();
          const nBizDataQuery = await WidgetConfigUtils.getWidgetConfigBasedBizDataQuery(pBizDataQuery, true);
          setBizDataQuery(nBizDataQuery);
          setTimeout(() => stopSettingUpQuery(), 0);
        } else {
          setBizDataQuery(pBizDataQuery);
        }
      } else {
        setBizDataQuery(defaultQuery);
      }
      stopSettingUpQuery();
    },
    [defaultQuery, startSettingUpQuery, stopSettingUpQuery]
  );

  useEffect(() => {
    fetchWidgetConfigOnEdit(pBizDataQuery);
  }, [fetchWidgetConfigOnEdit, pBizDataQuery]);

  const fetchImpactedWidgetConfigOnEdit = useCallback(
    async (pBizDataQuery: BizDataQuery) => {
      if (pBizDataQuery) {
        if (pBizDataQuery?.widgetConfig) {
          const nBizDataQuery = WidgetConfigUtils.getBizDataQueryBasedOnSliceSpec(pBizDataQuery);
          onChange(nBizDataQuery);
        } else {
          const nBizDataQuery = await WidgetConfigUtils.getWidgetConfigBasedBizDataQuery(pBizDataQuery, true);
          onChange(nBizDataQuery);
        }
      } else {
        onChange(defaultQuery);
      }
    },
    [defaultQuery, onChange]
  );

  const [selectedImpactedWidgetOption, setSelectedImpactedWidgetOption] =
    useState<IncSelectOption<ImpactedWidget>>(null);

  const impactedWidgetOptions = useMemo<Array<IncSelectOption<ImpactedWidget>>>(
    () =>
      impactedWidgets.map(e => ({
        label: e.name,
        value: e.id,
        data: e
      })),
    [impactedWidgets]
  );

  const onChangeOption = useCallback(
    async (option: IncSelectOption<ImpactedWidget>) => {
      setSelectedImpactedWidgetOption(option);
      const { bizDataQuery } = option.data || {};
      if (bizDataQuery) {
        startSettingUpQuery();
        await fetchImpactedWidgetConfigOnEdit(cloneDeep(bizDataQuery));
        setTimeout(() => {
          stopSettingUpQuery();
        }, 0);
      }
    },
    [fetchImpactedWidgetConfigOnEdit, startSettingUpQuery, stopSettingUpQuery]
  );

  const bizDataQueryRef = useRefState<BizDataQuery>(bizDataQuery);

  const onChangeKpi = useCallback(
    (nBizDataQuery: BizDataQuery) => {
      if (!isQuerySetupInProgress) {
        const bizDataQuery = bizDataQueryRef.current;
        if (bizDataQuery?.widgetConfig) {
          if (!isEqual(bizDataQuery.widgetConfig, nBizDataQuery?.widgetConfig)) {
            onChange(nBizDataQuery);
          }
        } else {
          onChange(nBizDataQuery);
        }
      }
    },
    [bizDataQueryRef, isQuerySetupInProgress, onChange]
  );

  return (
    <>
      <IncSelect
        label="Use from existing"
        onChange={onChangeOption}
        options={impactedWidgetOptions}
        placeholder="Use query from existing impact indicators"
        value={selectedImpactedWidgetOption}
      />
      <div>
        {!isQuerySetupInProgress && (
          <KpiEditorWrapper
            bizDataQuery={bizDataQuery}
            onChange={onChangeKpi}
            onLoadMetrics={onLoadMetrics}
          />
        )}
      </div>
    </>
  );
};

type KpiEditorWrapperProps = {
  bizDataQuery: BizDataQuery;
  onChange: (bizDataQuery: BizDataQuery) => void;
  onLoadMetrics?: (loading: boolean) => void;
};

const KpiEditorWrapper: FC<KpiEditorWrapperProps> = props => {
  const { bizDataQuery, onChange, onLoadMetrics } = props;
  const kpiQuery = useMemo<UberOperationalizeTypes.OpBizDataQuery>(
    () =>
      bizDataQuery
        ? {
            bizDataQuery,
            rollingFreq: null,
            rollingFunction: null
          }
        : null,
    [bizDataQuery]
  );

  const name = useMemo(
    () => (kpiQuery?.bizDataQuery ? WidgetConfigUtils.getNameFromBizDataQuery(kpiQuery?.bizDataQuery) : ""),
    [kpiQuery]
  );

  const isNameChanging = useRef<boolean>(false);
  const onChangeKpi = useCallback(
    (isValid: boolean, errorsText: string, kpi: UberOperationalizeTypes.OpBizDataQuery) => {
      if (isValid && !isNameChanging.current) {
        onChange(kpi.bizDataQuery);
      }
    },
    [onChange]
  );

  const onNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (kpiQuery) {
        const nextBizDataQuery = cloneDeep(kpiQuery.bizDataQuery);
        WidgetConfigUtils.setNameForBizDataQuery(nextBizDataQuery, e.target.value);
        isNameChanging.current = true;
        onChange(nextBizDataQuery);
        setTimeout(() => {
          isNameChanging.current = false;
        }, 0);
      }
    },
    [kpiQuery, onChange]
  );

  if (!kpiQuery) {
    return <></>;
  }

  return (
    <KPIQueryEditor
      kpiQuery={kpiQuery}
      onLoadMetrics={onLoadMetrics}
      onStateChange={onChangeKpi}
    >
      <IncTextfield
        label="Name"
        onChange={onNameChange}
        placeholder="Key Performance Indicator"
        value={name}
      />
    </KPIQueryEditor>
  );
};

const getDefaultBizDataQuery = (eventTypeId: string): BizDataQuery => {
  const buildingBlockConfigId = generateId();
  const buildingBlockConfig: BuildingBlockConfig = {
    id: buildingBlockConfigId,
    name: "Metric Name",
    aggregator: null,
    buildingBlockDef: {
      aggregator: "sum",
      fieldConfig: {
        userServiceField: {
          userServices: eventTypeId
            ? [
                {
                  userServiceEntityId: eventTypeId
                }
              ]
            : []
        } as UserServiceField
      },
      filters: {
        filterExpressions: []
      },
      sliceDef: {
        sliceSets: [
          {
            slices: []
          }
        ]
      }
    }
  };
  const widgetConfigDto = WidgetConfigUtils.getWidgetConfigDtoFromBuildingBlockConfig(buildingBlockConfig);

  const bizDataQuery: BizDataQuery = {
    sliceSpec: {
      selectorSpec: {
        filters: []
      },
      sliceSet: {
        slices: []
      },
      metricId: buildingBlockConfigId
    },
    widgetConfig: getWidgetConfigFromDto(widgetConfigDto)
  };

  return bizDataQuery;
};

export default QueryEditorPopper;
