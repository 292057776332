import React, { useState, useEffect, useMemo, useCallback } from "react";
import { forEach } from "lodash";
import { IncSelectOption, IncSelect } from "@inception/ui";
import {
  TransformationConfig,
  DateTimeFormatMap,
  DateFormat,
  EpochConversionTypeMap,
  EpochConversionType
} from "../transform-config/TransformConfig";

interface Props {
  config: TransformationConfig<"dateTransformation">;
  onChange: (config: TransformationConfig<"dateTransformation">) => void;
}

export const DateTimeComp: React.FC<Props> = (props: Props) => {
  const { config, onChange } = props;
  const [selectOpt, setSelectedOpt] = useState<IncSelectOption>(null);
  const [epochConversion, setEpochConversion] = useState<IncSelectOption>();

  const [dateFormatOptions, epochConversionOptions] = useMemo(() => {
    const dateFormatOptions: IncSelectOption[] = [];
    const epochConversionOptions: IncSelectOption[] = [];
    forEach(DateTimeFormatMap, (value: string, key: string) => {
      if (key !== DateFormat.NOTSET) {
        dateFormatOptions.push({
          label: value,
          value: key
        });
      }
    });
    forEach(EpochConversionTypeMap, (value: string, key: string) => {
      if (key !== DateFormat.NOTSET) {
        epochConversionOptions.push({
          label: value,
          value: key
        });
      }
    });
    return [dateFormatOptions, epochConversionOptions];
  }, []);

  const updateConfig = useCallback(
    (value: DateFormat | string, epochConversion: EpochConversionType) => {
      let newConfig: TransformationConfig<"dateTransformation"> = null;

      if (DateTimeFormatMap[value as DateFormat]) {
        newConfig = {
          dateformat: value as DateFormat,
          outputValue: epochConversion,
          format: ""
        };
      } else {
        newConfig = {
          dateformat: DateFormat.NOTSET,
          outputValue: epochConversion,
          format: value
        };
      }
      onChange(newConfig);
    },
    [onChange]
  );

  const onChangeFormat = useCallback(
    (option: IncSelectOption) => {
      setSelectedOpt(option);
      updateConfig(option.value as DateFormat | string, epochConversion.value as EpochConversionType);
    },
    [epochConversion, updateConfig]
  );

  const onChangeEpochConversion = useCallback(
    (option: IncSelectOption) => {
      setEpochConversion(option);
      updateConfig(selectOpt.value as DateFormat | string, option.value as EpochConversionType);
    },
    [selectOpt, updateConfig]
  );

  useEffect(() => {
    if (!selectOpt) {
      let option = null;
      if (config) {
        if (config.dateformat && config.dateformat !== DateFormat.NOTSET) {
          option = dateFormatOptions.find(x => x.value === config?.dateformat);
        } else {
          option = {
            label: config?.format,
            value: config?.format
          };
        }
        const epochConverstion = epochConversionOptions.find(x => x.value === config.outputValue);
        setSelectedOpt(option);
        setEpochConversion(epochConverstion);
      } else {
        option = dateFormatOptions[0];
        setSelectedOpt(option);
        setEpochConversion(epochConversionOptions[0]);
        onChange({
          dateformat: option.value as DateFormat,
          outputValue: epochConversionOptions[0].value as EpochConversionType,
          format: ""
        });
      }
    }
  }, [config, dateFormatOptions, epochConversionOptions, onChange, selectOpt]);

  return (
    <div>
      <IncSelect
        allowCreate
        className="marginBt16"
        label="Date Format"
        onChange={onChangeFormat}
        options={dateFormatOptions}
        value={selectOpt}
        width={360}
      />
      <IncSelect
        allowCreate
        className="marginBt16"
        label="Conver to"
        onChange={onChangeEpochConversion}
        options={epochConversionOptions}
        value={epochConversion}
        width={360}
      />
    </div>
  );
};
