import React, { FC, useMemo, useCallback, useRef } from "react";
import { IncSelectOption, IncSelect, IncDurationSelector, IncToggle } from "@inception/ui";
import { IncSelectorDuration } from "@inception/ui/src/components/DurationSelector/DurationSelector";
import { css } from "emotion";
import { MetricTableColumnQueryConfig } from "../types";
import { TimeRange, RawTimeRange } from "../../../core";
import { TimeObjUnit, PostAggProjection, ForecastProjection } from "../../../services/api/explore";
import { TimeRangeController } from "../../time-range";
import { noOp } from "../../../utils";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { VerticallyCenteredRow } from "../../flex-components";
import { getDurationFromTimeObj, getTimeObjFromDuration } from "../../../utils/DurationUtils";
import {
  customDsOption,
  automaticDsOption,
  customTrOption,
  offsetTrOption,
  automaticTrOption,
  projOptions,
  trOptions,
  dsOptions,
  getForecastProjOptions,
  getForecastDataOption
} from "./options";

interface Props {
  queryConfig: MetricTableColumnQueryConfig;
  curTimeRange: TimeRange;
  onChange: (queryConfig: Partial<MetricTableColumnQueryConfig>) => void;
}

export const QueryConfigEditor: FC<Props> = props => {
  const { curTimeRange, onChange, queryConfig } = props;

  const { timeOffset, timeRange, metricQueryConfig } = queryConfig;

  const {
    downsample,
    projection = "current",
    compareOffset,
    forecastProjections = [],
    enableForecast = false
  } = metricQueryConfig || {};

  const forecastProjOptions = useMemo(() => getForecastProjOptions(), []);

  const metricQueryConfigRef = useRef(metricQueryConfig);
  useMemo(() => {
    metricQueryConfigRef.current = metricQueryConfig;
  }, [metricQueryConfig]);

  const selForecastOptions = useMemo(() => {
    const selOpts = forecastProjOptions.filter(opt => forecastProjections.includes(opt.data));
    if (!selOpts.length) {
      selOpts.push(getForecastDataOption());
    }

    return selOpts;
  }, [forecastProjOptions, forecastProjections]);

  const selDsOption = downsample ? customDsOption : automaticDsOption;

  const selTrOption = useMemo(
    () => (timeRange ? customTrOption : timeOffset ? offsetTrOption : automaticTrOption),
    [timeOffset, timeRange]
  );

  const onTrOptionChange = useCallback(
    (opt: IncSelectOption) => {
      const { value } = opt;

      if (value === customTrOption.value) {
        onChange({
          timeRange: curTimeRange,
          timeOffset: null
        });
      } else if (value === offsetTrOption.value) {
        onChange({
          timeRange: null,
          timeOffset: {
            unit: TimeObjUnit.days,
            value: 1
          }
        });
      } else if (value === automaticTrOption.value) {
        onChange({
          timeRange: null,
          timeOffset: null
        });
      }
    },
    [curTimeRange, onChange]
  );

  const onDsOptionChange = useCallback(
    (opt: IncSelectOption) => {
      const { value } = opt;
      const metricQueryConfig = metricQueryConfigRef.current;

      if (value === customDsOption.value) {
        onChange({
          metricQueryConfig: {
            ...metricQueryConfig,
            downsample: {
              unit: TimeObjUnit.hours,
              value: 1
            }
          }
        });
      } else if (value === automaticDsOption.value) {
        onChange({
          metricQueryConfig: {
            ...metricQueryConfig,
            downsample: null
          }
        });
      }
    },
    [onChange]
  );

  const onForecastProjOptsChange = useCallback(
    (opts: ReadonlyArray<IncSelectOption<ForecastProjection>>) => {
      let fProjections = opts.map(opt => opt.data);
      fProjections = fProjections.length > 0 ? fProjections : [ForecastProjection.forecast];

      onChange({
        metricQueryConfig: {
          ...metricQueryConfig,
          forecastProjections: fProjections
        }
      });
    },
    [metricQueryConfig, onChange]
  );

  const onProjectionChange = useCallback(
    (opt: IncSelectOption<PostAggProjection>) => {
      const { data } = opt;
      const metricQueryConfig = metricQueryConfigRef.current;

      const projection = data;
      const compareOffset =
        projection === "current"
          ? null
          : metricQueryConfig.compareOffset || {
              unit: TimeObjUnit.hours,
              value: 1
            };

      onChange({
        metricQueryConfig: {
          ...metricQueryConfig,
          projection,
          compareOffset
        }
      });
    },
    [onChange]
  );

  const updateTimeRange = useCallback(
    (rawTr: RawTimeRange) => {
      const timeRange = timeRangeUtils.getTimeRangeFromRaw(rawTr);
      onChange({
        timeRange
      });
    },
    [onChange]
  );

  const onTrOffsetChange = useCallback(
    (duration: IncSelectorDuration) => {
      let durationValue = parseFloat(duration.duration?.toString());
      durationValue = isNaN(durationValue) ? 1 : durationValue;

      const timeOffset = getTimeObjFromDuration(durationValue, duration.durationType);
      onChange({
        timeOffset
      });
    },
    [onChange]
  );

  const onDsOffsetChange = useCallback(
    (duration: IncSelectorDuration) => {
      let durationValue = parseFloat(duration.duration?.toString());
      durationValue = isNaN(durationValue) ? 1 : durationValue;

      const downsample = getTimeObjFromDuration(durationValue, duration.durationType);

      const metricQueryConfig = metricQueryConfigRef.current;
      onChange({
        metricQueryConfig: {
          ...metricQueryConfig,
          downsample
        }
      });
    },
    [onChange]
  );

  const onChangeForcast = useCallback(
    (checked: boolean) => {
      const existingForecastProjections = metricQueryConfigRef.current.forecastProjections;

      onChange({
        metricQueryConfig: {
          ...metricQueryConfig,
          enableForecast: checked,
          forecastProjections: checked
            ? existingForecastProjections?.length
              ? existingForecastProjections
              : [ForecastProjection.forecast]
            : []
        }
      });
    },
    [metricQueryConfig, onChange]
  );

  const onCompareOffsetChange = useCallback(
    (duration: IncSelectorDuration) => {
      let durationValue = parseFloat(duration.duration?.toString());
      durationValue = isNaN(durationValue) ? 1 : durationValue;

      const compareOffset = getTimeObjFromDuration(durationValue, duration.durationType);

      const metricQueryConfig = metricQueryConfigRef.current;
      onChange({
        metricQueryConfig: {
          ...metricQueryConfig,
          compareOffset
        }
      });
    },
    [onChange]
  );

  const isSpecificTrOption = selTrOption === customTrOption;
  const isOffsetTrOption = selTrOption === offsetTrOption;

  const trOffsetDuration = useMemo(() => (timeOffset ? getDurationFromTimeObj(timeOffset) : null), [timeOffset]);

  const isCustomDsOption = selDsOption === customDsOption;
  const dsOffsetDuration = useMemo(() => (downsample ? getDurationFromTimeObj(downsample) : null), [downsample]);

  const selProjOption = useMemo(
    () => projOptions.find(opt => opt.value === projection) || projOptions[0],
    [projection]
  );
  const compareOffsetDuration = useMemo(
    () => (compareOffset ? getDurationFromTimeObj(compareOffset) : null),
    [compareOffset]
  );
  const showCompareSelector = projection !== "current";

  return (
    <div className={className}>
      <IncSelect
        label="Query Time Range"
        onChange={onTrOptionChange}
        options={trOptions}
        value={selTrOption}
        wrapperClass="marginTp12 marginBt12"
      />

      {isSpecificTrOption && (
        <VerticallyCenteredRow className="marginBt12">
          <VerticallyCenteredRow className="marginRt12">Select time range</VerticallyCenteredRow>
          <TimeRangeController
            compareTimeRange={null}
            disableRefresh
            hideQuickTabs
            setCompareTimeRange={noOp}
            setTimeRange={updateTimeRange}
            showCompareSelector={false}
            showTimeRangeSelector
            timeRange={timeRange}
          />
        </VerticallyCenteredRow>
      )}

      {isOffsetTrOption && (
        <IncDurationSelector
          className="marginBt12"
          duration={trOffsetDuration}
          label="Time range offset"
          onChange={onTrOffsetChange}
        />
      )}

      {Boolean(metricQueryConfig) && (
        <>
          <IncSelect
            label="Projection"
            onChange={onProjectionChange}
            options={projOptions}
            value={selProjOption}
            wrapperClass="marginBt12"
          />

          {showCompareSelector && (
            <IncDurationSelector
              className="marginBt12"
              duration={compareOffsetDuration}
              label="Compare offset"
              onChange={onCompareOffsetChange}
            />
          )}

          <IncSelect
            label="Downsample"
            onChange={onDsOptionChange}
            options={dsOptions}
            value={selDsOption}
            wrapperClass="marginBt12"
          />

          {isCustomDsOption && (
            <IncDurationSelector
              duration={dsOffsetDuration}
              label="Downsample duration"
              onChange={onDsOffsetChange}
            />
          )}

          <VerticallyCenteredRow className="marginTp6 inc-flex-grow">
            <IncToggle
              checked={enableForecast}
              className="marginRt12"
              label={"Show Forecast"}
              onChange={onChangeForcast}
            />

            {enableForecast && (
              <IncSelect
                isMulti
                onChange={onForecastProjOptsChange}
                options={forecastProjOptions}
                value={selForecastOptions}
              />
            )}
          </VerticallyCenteredRow>
        </>
      )}
    </div>
  );
};

const className = css`
  .inception-select {
    flex-grow: 1;
  }

  .inception-react-select-container {
    max-width: 25%;
    min-width: 200px;
  }
`;
