import { IncSelect } from "@inception/ui";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { logger } from "../../../../core";
import {
  BizDataQuery,
  SliceSpec,
  WidgetConfigUtils,
  useFetchWidgetResponseDTO
} from "../../../../services/api/explore";
import { extractSliceSetsFromDataDefinition, getDtoFromWidgetConfig } from "../../../../utils/ExploreUtils";
import {
  getSliceSetOptions,
  SliceSetOption
} from "../../../../operationalise-v2/v3/editors/SuppressionsEditor/biz-data-query/utils";

interface Props {
  bizDataQuery: BizDataQuery;
  onChange: (bizDataQuery: BizDataQuery) => void;
  isLoading: boolean;
}

export const WidgetIdSliceSelector: FC<Props> = props => {
  const { bizDataQuery, onChange, isLoading } = props;

  const { sliceSpec: selectedSliceSpec, id: widgetId, widgetConfig: bdqWidgetConfig } = bizDataQuery;

  const { eventTypeId, entityTypeId } = useMemo(
    () => WidgetConfigUtils.getEntityTypeAndEventTypeFromBizDataQuery(bizDataQuery),
    [bizDataQuery]
  );

  const {
    data: widgetResponseDto,
    error: widgetFetchError,
    isFetching: isWidgetFetching,
    fetchWidgetResponseDTO
  } = useFetchWidgetResponseDTO();

  useEffect(() => {
    const fEventTypeId = entityTypeId ? null : eventTypeId;
    if (widgetId) {
      fetchWidgetResponseDTO(widgetId, entityTypeId, fEventTypeId);
    } else if (bdqWidgetConfig) {
      //get the widget response DTO
      const widgetConfigDto = getDtoFromWidgetConfig(bdqWidgetConfig);
      fetchWidgetResponseDTO(widgetId, entityTypeId, fEventTypeId, widgetConfigDto);
    }
  }, [bdqWidgetConfig, entityTypeId, eventTypeId, fetchWidgetResponseDTO, widgetId]);

  useEffect(() => {
    if (widgetFetchError) {
      logger.error("WidgetIdSliceEditor", "Error fetching widget response", widgetFetchError);
    }
  }, [widgetFetchError]);

  const { querySchema, widgetConfig } = widgetResponseDto || {};
  const { dataDefinition } = widgetConfig || {};

  const { metrics } = dataDefinition || {};
  const widgetQuerySchema = querySchema?.querySchema;

  const selectedMetricId = useMemo(() => {
    if (selectedSliceSpec?.metricId) {
      return selectedSliceSpec?.metricId;
    } else {
      const exprMetricId = (widgetQuerySchema || []).find(x => {
        const { metricId } = x;
        return metrics[metricId]?.sourceType === "expression";
      })?.metricId;

      if (exprMetricId) {
        return exprMetricId;
      }

      return widgetQuerySchema?.[0]?.metricId || "";
    }
  }, [metrics, selectedSliceSpec?.metricId, widgetQuerySchema]);

  const metricAndSliceRecords = useMemo(
    () => WidgetConfigUtils.getMetricSliceSetRecord(metrics, widgetQuerySchema),
    [metrics, widgetQuerySchema]
  );

  const sliceSpecs = useMemo(() => {
    if (widgetResponseDto && !isWidgetFetching) {
      const sliceSpecs: SliceSpec[] = [];

      if (dataDefinition && querySchema && selectedMetricId) {
        if (metrics[selectedMetricId].sourceType === "expression") {
          const sliceRecord = metricAndSliceRecords.find(x => x.metricId === selectedMetricId);
          sliceSpecs.push({
            sliceSet: sliceRecord.sliceSet,
            selectorSpec: {
              filters: []
            },
            metricId: selectedMetricId
          });
        } else {
          const sliceSetsByMetricId = extractSliceSetsFromDataDefinition(dataDefinition, widgetQuerySchema);
          const selectedMetricSliceSets = sliceSetsByMetricId[selectedMetricId] || [];

          selectedMetricSliceSets.forEach(ss =>
            sliceSpecs.push({
              sliceSet: ss,
              selectorSpec: {
                filters: []
              },
              metricId: selectedMetricId
            })
          );
        }
      }

      return sliceSpecs;
    }

    return [];
  }, [
    dataDefinition,
    isWidgetFetching,
    metricAndSliceRecords,
    metrics,
    querySchema,
    selectedMetricId,
    widgetQuerySchema,
    widgetResponseDto
  ]);

  const sliceSetOptions = useMemo(
    () => getSliceSetOptions(sliceSpecs, widgetQuerySchema, true),
    [sliceSpecs, widgetQuerySchema]
  );
  const selSliceSetOption = useMemo(
    () =>
      sliceSetOptions.find(opt => {
        const {
          sliceSpec: { sliceSet, metricId }
        } = opt.data;
        const { metricId: selMetricId, sliceSet: selSliceSet } = selectedSliceSpec || {};

        return metricId === selMetricId && WidgetConfigUtils.compareSliceSets(sliceSet, selSliceSet);
      }),
    [selectedSliceSpec, sliceSetOptions]
  );

  const onSliceSpecChange = useCallback(
    (sliceSpec: SliceSpec) => {
      onChange({
        ...bizDataQuery,
        sliceSpec
      });
    },
    [bizDataQuery, onChange]
  );

  const onSliceSetChange = useCallback(
    (selOpt: SliceSetOption) => {
      const { sliceSpec } = selOpt.data;
      onSliceSpecChange(sliceSpec);
    },
    [onSliceSpecChange]
  );

  return (
    <IncSelect
      autoAdjustWidth
      autoSort={false}
      className="metric-and-slice-selector--metric-selector"
      isLoading={isWidgetFetching || isLoading}
      isSearchable={false}
      onChange={onSliceSetChange}
      options={sliceSetOptions}
      value={selSliceSetOption}
    />
  );
};
