import { defaultsDeep } from "lodash";
import BaseWidgetModel from "../../../models/BaseWidgetModel";
import TimeSeriesWidgetImpl from "../../TimeSeries/models/impl";
import { BizOpQuery } from "../../../../services/api/operationalise";
import BizOpQueryWidgetModel from "./model";

export default class BizOpQueryWidgetImpl extends TimeSeriesWidgetImpl implements BizOpQueryWidgetModel {
  type: "biz-op-query";
  bizOpQuery: BizOpQuery;
  generateDemoData: boolean;

  constructor(model: Partial<BizOpQueryWidgetModel>, meta?: Record<string, any>) {
    super(model, meta);
    this.assign(model);
  }

  assign(model: Partial<BizOpQueryWidgetModel>) {
    const wModel: any = defaultsDeep({ type: "biz-op-query" }, model);
    super.assign(wModel);
    this.type = "biz-op-query";
    this.bizOpQuery = wModel.bizOpQuery;
    this.generateDemoData = wModel.generateDemoData || false;
  }

  getSaveModel(): BaseWidgetModel {
    const model = super.getSaveModel();
    return model;
  }
}
