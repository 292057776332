import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { cloneDeep, values } from "lodash";
import { BizDataQuery } from "../../services/api/explore";
import { useTenantConfig, useInputState } from "../../core";
import { RawDataProperties } from "../../services/api/chat";
import { LoadingSpinner, VerticallyCenteredRow } from "../../components";
import EventTable from "../../dashboard/widgets/Catalog/components/renderers/table/changeEventDrawer/RawEventTable/EventTable";
import RawEventCriteria from "../../dashboard/widgets/Catalog/components/renderers/table/changeEventDrawer/RawEventTable/RawEventCriteria";
import { convertCriteriaFieldsFromRawEvents } from "../../dashboard/widgets/Catalog/components/renderers/table/changeEventDrawer/RawEventTable/utils";
import { FieldsToFilter } from "../../components/triage/ChangeEventDrawer/ChangeEventTable/utils";
import { useFetchRawEvents } from "../../dashboard/widgets/Catalog/components/renderers/table/changeEventDrawer/hooks";

type RawDataProps = {
  dataQuery: BizDataQuery;
  rawDataProperties: RawDataProperties;
  fromMillis: string;
  toMillis: string;
};

export const RawDataRenderer = memo<RawDataProps>(props => {
  const { dataQuery, rawDataProperties, fromMillis: fromMillisStr, toMillis: toMillisStr } = props;
  const { tenantConfigState } = useTenantConfig();
  const currencyType = tenantConfigState?.currency;
  const { preselectedDims } = rawDataProperties || {};
  // const { timeRange } = useTimeRange();
  // const { fromMillis, toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);

  const fromMillis = parseInt(fromMillisStr, 10);
  const toMillis = parseInt(toMillisStr, 10);

  const {
    widgetConfig: { bizEntityType, userServiceEntityId }
  } = dataQuery;
  const { data, error, isError, isFetching, refetch } = useFetchRawEvents(
    dataQuery,
    userServiceEntityId,
    bizEntityType,
    fromMillis,
    toMillis,
    1000
  );

  useEffect(() => {
    if (!data) {
      refetch();
    }
  }, [refetch, data]);

  const { inputValue, onInputChange } = useInputState("");
  const fieldsToFilter: FieldsToFilter[] = useMemo(
    () => [
      {
        name: "bicycle",
        checkAbsolute: false
      },
      {
        name: "inception",
        checkAbsolute: false
      }
    ],
    []
  );

  const fieldsToSelect = useMemo(
    () => convertCriteriaFieldsFromRawEvents(data?.data || [], inputValue, fieldsToFilter, [], {}),
    [data?.data, fieldsToFilter, inputValue]
  );

  const preSelectionMap = useMemo(() => {
    const map: Record<string, string> = {
      timeStamp: "timeStamp"
    };

    preselectedDims.forEach(d => {
      if (fieldsToSelect.find(x => x.field === d)) {
        map[d] = d;
      }
    });

    if (values(map).length === 1) {
      fieldsToSelect.forEach(x => {
        if (values(map).length < 5) {
          map[x.field] = x.field;
        }
      });
    }
    return map;
  }, [fieldsToSelect, preselectedDims]);

  const [selectionMap, setSelectionMap] = useState<Record<string, string>>({});

  useEffect(() => {
    setSelectionMap(preSelectionMap);
  }, [preSelectionMap]);

  const onCustomColumnNameChange = useCallback((key: string, value: string) => {
    setSelectionMap(prev => ({
      ...prev,
      [key]: value
    }));
  }, []);

  const onSelectionChange = useCallback((key: string) => {
    setSelectionMap(prev => {
      if (prev[key]) {
        const clonedKeys = cloneDeep(prev);
        delete clonedKeys[key];
        return clonedKeys;
      } else {
        const customValue = key;
        return {
          ...prev,
          [key]: customValue
        };
      }
    });
  }, []);

  const onSelectAll = useCallback(() => {
    const selectionMap: Record<string, string> = {};
    fieldsToSelect.forEach(ele => {
      selectionMap[ele.field] = ele.field;
    });
    setSelectionMap(selectionMap);
  }, [fieldsToSelect]);
  const onRemoveAll = useCallback(() => {
    setSelectionMap({});
  }, []);

  const dataAvailable = useMemo(() => Boolean(data?.data?.length), [data?.data?.length]);

  return (
    <div className="raw-data-step-renderer marginBt16">
      <div
        className="change-event-table-wrapper"
        style={{ flex: 1 }}
      >
        {isFetching && <LoadingSpinner titleText="Loading..." />}
        {!isFetching && isError && (
          <VerticallyCenteredRow className="message inc-flex-center">
            Error fetching data: {error}
          </VerticallyCenteredRow>
        )}
        {!isFetching && !isError && dataAvailable && (
          <div className="events-table">
            <RawEventCriteria
              fieldsToSelect={fieldsToSelect}
              isFetching={isFetching}
              onRemoveAll={onRemoveAll}
              onSearchTextChange={onInputChange}
              onSelectAll={onSelectAll}
              onSelectionChange={onSelectionChange}
              searchText={inputValue}
              selectionMap={selectionMap}
            />
            <EventTable
              currencyType={currencyType}
              edit={false}
              enableDownload
              error={error}
              isError={isError}
              isFetching={isFetching}
              onColumnNameChange={onCustomColumnNameChange}
              rawEventsData={data}
              selectionMap={selectionMap}
            />
          </div>
        )}
        {!isFetching && !isError && !dataAvailable && (
          <VerticallyCenteredRow className="message inc-flex-center">No data found</VerticallyCenteredRow>
        )}
      </div>
    </div>
  );
});
