import { IncSelect, IncSelectOption, IncTextfield } from "@inception/ui";
import { cx } from "emotion";
import { cloneDeep, debounce, isEmpty, isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AddCircle, DiscoveryDeleteOutlined, DiscoveryPlusCircle } from "../../core/iconwrapper";
import { getOptionFromString } from "../../utils/ExploreUtils";
import { LinkButton } from "../../components";
import { LogicalOperation, FieldPrimType, NO_VALUE_OPERATIONS } from "../../core/data";
import { getLogicalOperation, getOpMapFromFieldType, LogicalOpMap, generateId } from "../../core";
import { Condition, JoinFiltersOperator, JoinFiltersOperatorOption, FilterWithKey } from "./types";

export type GenericFilterDataOption = {
  value: string[];
  type: FieldPrimType;
};

type GenericFilterColumnOption = {
  label: string;
  value: string;
  type: FieldPrimType;
};
interface ConditionsBuilderRowProps {
  data: Record<string, GenericFilterDataOption>;
  filter: Condition | null;
  onChange: (filter: Condition) => void;
  onDelete: () => void;
  showLabel?: boolean;
  keyLabel?: string;
  disableColumnSelection?: boolean;
  index?: number;
  showChoosenLabel?: boolean;
  hideActions?: boolean;
}

const ConditionsBuilderRow: React.FC<ConditionsBuilderRowProps> = props => {
  const {
    data,
    filter,
    showLabel,
    onChange,
    onDelete,
    keyLabel,
    disableColumnSelection = false,
    index,
    showChoosenLabel,
    hideActions
  } = props;

  const labelString = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const [filterInternal, setFilterInternal] = useState<Condition>();

  const [filterKey, setFilterKey] = useState<GenericFilterColumnOption>(null);
  const [filterOp, setFilterOp] = useState<IncSelectOption>(null);
  const [filterOps, setFilterOps] = useState<Record<string, LogicalOperation>>(LogicalOpMap);
  const [label, setLabel] = useState<string>(labelString[index]);
  // This is used when showing the select options for value
  const [filterValue, setFilterValue] = useState<IncSelectOption>(undefined);

  // This is used when showing the text box for value
  const [valueText, setValueText] = useState<string>(filter?.value);

  const fieldNames = useMemo(() => {
    const fields = [];
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        fields.push({
          label: key,
          value: key,
          type: value.type
        });
      }
    }
    return fields;
  }, [data]);

  const ops = useMemo(() => Object.keys(filterOps).map(op => getOptionFromString(op)), [filterOps]);

  const fieldValues = useMemo(() => {
    if (!filterKey) {
      return [];
    }
    const values = data[filterKey.value];
    return values?.value?.map(value => getOptionFromString(value));
  }, [data, filterKey]);

  useEffect(() => {
    if (!filter || isEqual(filter, filterInternal)) {
      return;
    }

    setFilterInternal(filter);
    if (filter.key !== filterKey?.value) {
      const keyOption = fieldNames.find(field => field.value === filter.key);
      const option = keyOption
        ? keyOption
        : {
            label: filter.key,
            value: filter.key,
            type: null
          };
      setFilterKey(option);
      setFilterOps(getOpMapFromFieldType(option?.type));
    }

    if (filter.op !== filterOp?.value) {
      const opOption = ops.find(op => op.value === filter.op);
      setFilterOp(opOption);
    }

    if (isEmpty(fieldValues)) {
      // text box case
      if (filter.value !== valueText) {
        setValueText(valueText);
      }
    } else {
      if (filter.value !== filterValue?.value) {
        const valueOption = fieldValues?.find(val => val.value === filter.value) ?? getOptionFromString(filter.value);
        setFilterValue(valueOption);
      }
    }
  }, [filter, fieldNames, ops, fieldValues, filterKey, filterOp, filterValue, valueText, filterInternal]);

  const onFilterKeyChange = useCallback((option: GenericFilterColumnOption) => {
    const OpMap = getOpMapFromFieldType(option.type);
    setFilterKey(option);
    setFilterOps(OpMap);
    setFilterOp(null);
  }, []);

  const onOpChange = useCallback(option => {
    setFilterOp(option);
  }, []);

  const onValueChange = useCallback(option => {
    setFilterValue(option);
  }, []);

  const onLabelChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(e.target.value);
  }, []);

  const debounceTextUpdate = useMemo(() => debounce((value: string) => setValueText(value), 700), []);

  const onTextValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value || "";
      debounceTextUpdate(value);
    },
    [debounceTextUpdate]
  );

  useEffect(() => {
    if (!filterKey || !filterOp) {
      return;
    }

    if (!filterValue && !valueText && !NO_VALUE_OPERATIONS.includes(filterOp?.value)) {
      return;
    }

    const val = isEmpty(fieldValues) ? valueText : filterValue?.value;

    const newFilter: Condition = {
      key: filterKey.value,
      op: filterOp.value,
      value: val || null,
      opValue: getLogicalOperation(filterOp.value as LogicalOperation),
      label: label
    };

    if (!isEqual(filter, newFilter)) {
      onChange(newFilter);
    }
  }, [fieldValues, filter, filterKey, filterOp, filterValue, onChange, valueText, label]);

  useEffect(() => {
    if (filter && filter?.label) {
      setLabel(filter.label);
    }
  }, [filter]);

  return (
    <>
      <div className="condition-builder-row">
        <div className="key-container">
          <IncSelect<GenericFilterColumnOption>
            isDisabled={disableColumnSelection}
            label={showLabel ? keyLabel : ""}
            onChange={onFilterKeyChange}
            options={fieldNames}
            placeholder={keyLabel}
            value={filterKey}
          />
        </div>

        <div className="op-container">
          <IncSelect
            label={showLabel ? "Condition" : ""}
            onChange={onOpChange}
            options={ops}
            placeholder="Operator"
            value={filterOp}
          />
        </div>
        <div className={showChoosenLabel ? "value-container" : "value-container1"}>
          {!isEmpty(fieldValues) ? (
            <IncSelect
              label={showLabel ? "Value" : ""}
              onChange={onValueChange}
              options={fieldValues}
              placeholder="Value"
              value={filterValue as IncSelectOption}
            />
          ) : (
            <IncTextfield
              defaultValue={valueText}
              disabled={NO_VALUE_OPERATIONS.includes(filterOp?.value)}
              label={showLabel ? "Value" : ""}
              onChange={onTextValueChange}
              placeholder="Value"
            />
          )}
        </div>
        {showChoosenLabel && (
          <div className="label-container">
            {
              <IncTextfield
                label={""}
                onChange={onLabelChange}
                value={filter?.label || label}
              />
            }
          </div>
        )}
        {!hideActions && (
          <div
            className={cx("remove-container", showLabel ? "remove-container--with-label" : "")}
            onClick={onDelete}
          >
            <DiscoveryDeleteOutlined />
          </div>
        )}
      </div>
    </>
  );
};

interface ConditionsBuilderProps {
  keyLabel: string;
  data: Record<string, GenericFilterDataOption>;
  filters: Condition[];
  onChange: (filters: Condition[]) => void;
  onJoinFiltersOperatorChange?: (value: JoinFiltersOperator) => void;
  joinFiltersOperator?: JoinFiltersOperator;
  label?: string;
  addLabel?: string;
  addEmptyFirstFilter?: boolean;
  addDisabled?: boolean;
  showConditionsDropDown?: boolean;
  hideActions?: boolean;
  hideInputLabels?: boolean;
}

const constructFiltersWithKeys = (
  filters: Condition[],
  prevFiltersWithKeys: Array<FilterWithKey<Condition>> = []
): Array<FilterWithKey<Condition>> => {
  if (!isEmpty(filters)) {
    return filters.map(filter => {
      const prevKey = prevFiltersWithKeys?.find(pf => filter && pf.filter === filter)?.key;
      const key = prevKey || generateId();

      return {
        key,
        filter
      };
    });
  }
  return [];
};

const filterOperatorConditionOptions: JoinFiltersOperatorOption[] = [
  {
    label: "and",
    value: "AND"
  },
  {
    label: "or",
    value: "OR"
  }
];

export const GenericFiltersBuilder: React.FC<ConditionsBuilderProps> = props => {
  const {
    addEmptyFirstFilter,
    data,
    filters,
    label,
    onChange,
    keyLabel,
    addDisabled,
    onJoinFiltersOperatorChange,
    joinFiltersOperator,
    showConditionsDropDown,
    hideActions,
    hideInputLabels
  } = props;

  const [filtersWithKey, setFiltersWithKey] = useState(constructFiltersWithKeys(filters));

  useEffect(() => {
    setFiltersWithKey(prev => constructFiltersWithKeys(filters, prev));
  }, [filters]);

  const joinOperator: JoinFiltersOperatorOption = useMemo(() => {
    if (joinFiltersOperator === "OR") {
      return {
        label: "or",
        value: "OR"
      };
    } else {
      return {
        label: "and",
        value: "AND"
      };
    }
  }, [joinFiltersOperator]);

  const updateFilters = useCallback(
    (filtersWithKey: Array<FilterWithKey<Condition>>) => {
      setFiltersWithKey(filtersWithKey);

      const filters = filtersWithKey.map(({ filter }) => filter);
      onChange(filters);
    },
    [onChange]
  );

  const onFilterChange = useCallback(
    (filter: Condition, key: string) => {
      const _filters = cloneDeep(filtersWithKey);
      const currFilter = _filters.find(x => x.key === key);
      currFilter.filter = filter;
      updateFilters(_filters);
    },
    [filtersWithKey, updateFilters]
  );

  const addRows = useCallback(() => {
    const _filters = cloneDeep(filtersWithKey);
    const nFilter: FilterWithKey<Condition> = {
      key: generateId(),
      filter: null
    };
    _filters.push(nFilter);
    updateFilters(_filters);
  }, [filtersWithKey, updateFilters]);

  const removeRow = useCallback(
    (key: string) => {
      let _filters = cloneDeep(filtersWithKey);

      _filters = _filters.filter(x => x.key !== key);

      updateFilters(_filters);
    },
    [filtersWithKey, updateFilters]
  );

  useEffect(() => {
    if (isEmpty(filtersWithKey) && addEmptyFirstFilter) {
      addRows();
    }
  }, [addEmptyFirstFilter, addRows, filters, filtersWithKey]);

  return (
    <div className="inc-flex-column inc-flex-grow generic-filters-builder flex-gap-8">
      {label &&
        filtersWithKey?.map(({ filter, key }, i) => (
          <div
            className="inc-flex-row label-enabled inc-flex-center"
            key={key}
          >
            {i === 0 ? (
              <div className="inc-label-common width56">{label}</div>
            ) : (
              <div className="width56">
                {!showConditionsDropDown && (
                  <IncSelect
                    onChange={option => onJoinFiltersOperatorChange && onJoinFiltersOperatorChange(option.value)}
                    options={filterOperatorConditionOptions}
                    placeholder=""
                    value={joinOperator}
                  />
                )}
              </div>
            )}
            <div className="and-clause-row">
              <ConditionsBuilderRow
                data={data}
                disableColumnSelection={addDisabled}
                filter={filter}
                hideActions={hideActions}
                index={i}
                keyLabel={keyLabel}
                onChange={filter => onFilterChange(filter, key)}
                onDelete={() => removeRow(key)}
                showChoosenLabel={showConditionsDropDown}
                showLabel={false}
              />
            </div>
            {!hideActions && (
              <DiscoveryPlusCircle
                className="action-add-circle"
                height={20}
                onClick={addRows}
                width={20}
              />
            )}
          </div>
        ))}

      {!label && (
        <>
          {filtersWithKey?.map(({ filter, key }, i) => (
            <div
              className="and-clause-row"
              key={key}
            >
              <ConditionsBuilderRow
                data={data}
                disableColumnSelection={addDisabled}
                filter={filter}
                hideActions={hideActions}
                index={i}
                keyLabel={keyLabel}
                onChange={filter => onFilterChange(filter, key)}
                onDelete={() => removeRow(key)}
                showChoosenLabel={showConditionsDropDown}
                showLabel={hideInputLabels ? false : i === 0}
              />
            </div>
          ))}
          {!hideActions && (
            <LinkButton
              className="marginTp4"
              disabled={addDisabled}
              icon={AddCircle}
              onClick={addRows}
              text={props.addLabel || "Filter"}
            />
          )}
        </>
      )}
    </div>
  );
};
