// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AgentChatTypes {
  export interface AgentChat {
    tenantId: string;
    query: string;
    chatId: string;
    allowedEvent: string[];
    allowedEntity: string[];
    entityLookupMap: Record<string, string>;
    eventLookupMap: Record<string, string>;
    tenantName: string;
    companyName: string;
    reqId: string;
  }

  export enum Status {
    NA = "NA",
    COMPLETED = "COMPLETED",
    ONGOING = "ONGOING",
    CANCELLED = "CANCELLED"
  }

  export interface QueryConversionResponse {
    query: string;
    message: string;
    suggestions: string[];
    matchedFields: string[];
  }

  export interface ChatResponse {
    queryConversionResponse: QueryConversionResponse;
    triageQueryResponse: TriageQueryResponse;
    summary: string;
    startTimeMillis: number;
    endTimeMillis: number;
    totalTimeTake: number;
    totalStepsExecuted: number;
    agentStepResponseList: AgentStepResponse[];
    status: Status;
    title: string;
  }

  export interface TriageQueryConversionResponse {
    queryConversionResponse: QueryConversionResponse;
    triageTool: string;
    drillDownTool: TriageTool;
  }

  export enum TriageTool {
    NOT_SPECIFIED = "NOT_SPECIFIED",
    EVENT_CORRELATION = "EVENT_CORRELATION",
    DIMENSIONAL_ANALYSIS = "DIMENSIONAL_ANALYSIS"
  }

  export interface AgentStepResponse {
    input_prompt: string;
    thought: string;
    actionInput: string;
    toolName: string;
    context: string;
    llmModelName: string;
    subAgentStepResponseList: AgentStepResponse[];
    source: Source;
    fontAwesomeIcon: string;
    userFriendlyThought: string;
    userFriendlyStepName: string;
    output: string;
  }

  export interface TriageQueryResponse {
    drillDownStepsOutputList: DrillDownStepsOutput[];
  }

  export interface DrillDownStepsOutput {
    triageQueryConversionResponse: TriageQueryConversionResponse;
    drillDownStepsOutputList: DrillDownStepsOutput[];
    thought: string;
    source: Source;
    causeIdentified: string;
  }

  export interface Source {
    type: string;
    id: string;
    name: string;
    description: string;
    sourceType: SourceType;
  }

  export enum SourceType {
    GENERATED = "GENERATED",
    KNOWLEDGE_BASE = "KNOWLEDGE_BASE",
    USER_INPUT = "USER_INPUT",
    STEP = "STEP"
  }
}
