import { CancelToken } from "axios";
import { v4 as uuidV4 } from "uuid";
import { SaveOnboardingDataRequest, SaveOnboardingResponse } from "../../../../features/demo-data-generator/types";
import datasourceApiManager from "../DatasourceApiService";
import { logger } from "../../../core";
import {
  AddEventSourceRequest,
  AutoDiscoveryStatusUIRequest,
  AutoDiscoveryStatusUIResponse,
  CancelAutoDiscoverRequest,
  CancelAutoDiscoverResponse,
  DataTransformationPreviewRequest,
  DataTransformationPreviewResponse,
  DeleteMappingRequest,
  DiscoverEventDetailsResponse,
  DiscoverEventsResponse,
  DiscoverSourceDetailsResponse,
  DiscoveryCommonUIResponse,
  EventGroupRequest,
  EventGroupResponse,
  InitiateAutoDiscoveryUIRequest,
  InitiateAutoDiscoveryUIResponse,
  MappingEventTypePreviewRequest,
  MappingEventTypePreviewResponse,
  MappingGroupPreviewRequest,
  MappingGroupPreviewResponse,
  MappingSourcePreviewRequest,
  MappingSourcePreviewResponse,
  MappingSplitEventTypeRequest,
  MappingSplitEventTypeResponse,
  VerifyEventTypeRequest,
  VerifyMappingRequest,
  VerifyMappingResponse,
  VerticalContextResponse,
  VerticalsResponse,
  AddEventGroupRequest,
  AutoDiscoveryStatusUIRequestV2,
  AutoDiscoveryStatusUIResponseV2,
  FieldMappingOptionsRequest,
  FieldMappingOptionsResponse,
  RunOnboardingDiscoveryRequest,
  DataOnBoardingDiscoveryStatus,
  DataOnboardingDiscoveryRequest,
  DataOnBoardingDiscoveryResponse,
  VerifyEventTypeDataModel,
  DataUploadStatusRequest,
  DataUploadStatusResponse,
  DataModelResponse,
  UploadDataRequest,
  DataOnboardingDiscoveryStatusResponseV2,
  DataOnboardingDiscoveryRequestV2,
  GetDataModelRequest,
  GetBicycleFieldInsightRequest,
  BicycleFieldInsightResponse,
  GetDataModelRequestWithUseCase
} from "./types";

interface AutoDiscoveryServiceInterface {
  getAutoDiscoveredEvents: (industry: string) => Promise<DiscoverEventsResponse>;
  getAutoDiscoverUserServiceDetails: (userServiceName: string) => Promise<DiscoverEventDetailsResponse>;
  getAutoDiscoverEventSourceDetails: (eventSourceName: string) => Promise<DiscoverSourceDetailsResponse>;
  getAutoDiscoverySchema: (from: number, to: number) => Promise<DiscoverEventsResponse>;

  getVerticals: () => Promise<VerticalsResponse>;
  getVerticalContext: (verticalName: string) => Promise<VerticalContextResponse>;
  initiateAutoDiscovery: (
    request: InitiateAutoDiscoveryUIRequest,
    enabledConnections?: boolean
  ) => Promise<InitiateAutoDiscoveryUIResponse>;
  getAutoDiscoveryStatus: (request: AutoDiscoveryStatusUIRequest) => Promise<AutoDiscoveryStatusUIResponse>;
  getAutoDiscovertyStatusV2: (
    request: AutoDiscoveryStatusUIRequestV2,
    dryRun?: boolean
  ) => Promise<AutoDiscoveryStatusUIResponseV2>;
  cancelAutoDiscoveryRequest: (request: CancelAutoDiscoverRequest) => Promise<CancelAutoDiscoverResponse>;

  // Autodiscovery mapping related API's
  getAllMappingGroups: (
    request: EventGroupRequest,
    startTimeMillis: number,
    endTimeMillis: number
  ) => Promise<EventGroupResponse>;
  verifyMapping: (request: VerifyMappingRequest) => Promise<VerifyMappingResponse>;
  deleteMapping: (request: DeleteMappingRequest) => Promise<VerifyMappingResponse>;
  getMappingEventSourcePreview: (
    request: MappingSourcePreviewRequest,
    startTimeMillis: number,
    endTimeMillis: number
  ) => Promise<MappingSourcePreviewResponse>;
  getMappingEventGroupPreview: (
    request: MappingGroupPreviewRequest,
    cancelToken: CancelToken,
    startTimeMillis: number,
    endTimeMillis: number
  ) => Promise<MappingGroupPreviewResponse>;
  getMappingEventTypePreview: (
    request: MappingEventTypePreviewRequest,
    cancelToken: CancelToken,
    startTimeMillis: number,
    endTimeMillis: number
  ) => Promise<MappingEventTypePreviewResponse>;
  splitMappingEventType: (
    request: MappingSplitEventTypeRequest,
    cancelToken: CancelToken,
    startTimeMillis: number,
    endTimeMillis: number
  ) => Promise<MappingSplitEventTypeResponse>;
  verifyEventType: (request: VerifyEventTypeRequest, dryRun?: boolean) => Promise<DiscoveryCommonUIResponse>;
  getDataTransformationPreview: (
    request: DataTransformationPreviewRequest,
    cancelToken: CancelToken,
    startTimeMillis: number,
    endTimeMillis: number
  ) => Promise<DataTransformationPreviewResponse>;
  //Edit flow API
  getMappings: (
    request: EventGroupRequest,
    startTimeMillis: number,
    endTimeMillis: number
  ) => Promise<EventGroupResponse>;
  addEventSource: (
    request: AddEventSourceRequest,
    cancelToken: CancelToken,
    startTimeMillis: number,
    endTimeMillis: number
  ) => Promise<MappingGroupPreviewResponse>;
  addEventGroup: (
    request: AddEventGroupRequest,
    startTimeMillis: number,
    endTimeMillis: number
  ) => Promise<MappingGroupPreviewResponse>;
  getExportEventSourceDataUrl: (eventSourceId: string) => string;
  getFieldMappingOptions: (request: FieldMappingOptionsRequest) => Promise<FieldMappingOptionsResponse>;
  saveCustomerData(request: SaveOnboardingDataRequest): Promise<SaveOnboardingResponse>;
  triggerOnboardingDiscovery(
    request: Omit<RunOnboardingDiscoveryRequest, "traceInfo">
  ): Promise<DataOnBoardingDiscoveryStatus>;
  getDiscoveryStatus(request: Omit<DataOnboardingDiscoveryRequest, "traceId">): Promise<DataOnBoardingDiscoveryStatus>;
  getDiscoveryStatusV2(request: DataOnboardingDiscoveryRequestV2): Promise<DataOnboardingDiscoveryStatusResponseV2>;
  getDiscoveryResponse(
    request: Omit<DataOnboardingDiscoveryRequest, "traceId">
  ): Promise<DataOnBoardingDiscoveryResponse>;
  saveDataModel(request: Omit<VerifyEventTypeDataModel, "traceInfo">): Promise<any>;
  uploadFileDataStatus(request: Omit<DataUploadStatusRequest, "traceInfo">): Promise<DataUploadStatusResponse>;
  triggerDataOnboardingV2(
    payload: Omit<RunOnboardingDiscoveryRequest, "traceInfo">
  ): Promise<DataOnBoardingDiscoveryStatus>;
  getDataOnboardingRecommendations(
    payload: Omit<DataOnboardingDiscoveryRequest, "traceId">
  ): Promise<DataModelResponse>;
  uploadEventTypeModelData(payload: Omit<UploadDataRequest, "traceInfo">): Promise<DataUploadStatusResponse>;
  getDataModel(payload: Omit<GetDataModelRequest, "traceInfo">): Promise<DataModelResponse>;
  getDataModelFromBizEntityRoute(payload: GetDataModelRequestWithUseCase): Promise<DataModelResponse>;
  getBicycleFieldsInsights(
    payload: Omit<GetBicycleFieldInsightRequest, "traceInfo">
  ): Promise<BicycleFieldInsightResponse>;
}

class AutoDiscoveryApiService implements AutoDiscoveryServiceInterface {
  async getAutoDiscoveredEvents(industry: string) {
    try {
      const response = await datasourceApiManager
        .getDefault()
        .post(`/event-mapping/api/v1/autodiscover?industry=${industry}`, {});
      if (response && response?.data) {
        return response.data as DiscoverEventsResponse;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error while fetching auto discovered events`, e);
      throw e;
    }

    return null;
  }

  async getAutoDiscoverUserServiceDetails(userServiceName: string) {
    try {
      const response = await datasourceApiManager
        .getDefault()
        .post(`/event-mapping/api/v1/autodiscover/user-service-details/${userServiceName}`, {});
      if (response && response?.data) {
        return response.data as DiscoverEventDetailsResponse;
      }
    } catch (e) {
      logger.error(
        "EventMapping-AutoDisovery-API-Service",
        `Error while fetching auto discovered user service details`,
        e
      );
      throw e;
    }

    return null;
  }

  async getAutoDiscoverEventSourceDetails(eventSourceName: string) {
    try {
      const response = await datasourceApiManager
        .getDefault()
        .post(`/event-mapping/api/v1/autodiscover/source-details/${eventSourceName}`, {});
      if (response && response?.data) {
        return response.data as DiscoverSourceDetailsResponse;
      }
    } catch (e) {
      logger.error(
        "EventMapping-AutoDisovery-API-Service",
        `Error while fetching auto discovered user service details`,
        e
      );
      throw e;
    }

    return null;
  }

  async getAutoDiscoverySchema(from: number, to: number) {
    try {
      const response = await datasourceApiManager
        .getDefault()
        .get(`/event-mapping/api/v1/config/auto-discovery?st=${from}&et=${to}`);
      if (response && response?.data) {
        return response.data as DiscoverEventsResponse;
      }
    } catch (e) {
      logger.error(
        "EventMapping-AutoDisovery-API-Service",
        `Error while fetching auto discovered user service details`,
        e
      );
      throw e;
    }

    return null;
  }

  async getVerticals() {
    try {
      const response = await datasourceApiManager.getDefault().get(
        `/vertical-context/api/v1/verticals`,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          data: {}
        }
      );
      if (response && response?.data) {
        return response.data as VerticalsResponse;
      }
    } catch (e) {
      logger.error("VerticalContextApiService", `Error while fetching verticals response`, e);
      throw e;
    }

    return null;
  }

  async getVerticalContext(verticalName: string) {
    try {
      const response = await datasourceApiManager
        .getDefault()
        .get(`/vertical-context/api/v1/verticals/${verticalName}`);
      if (response && response?.data) {
        return response.data as VerticalContextResponse;
      }
    } catch (e) {
      logger.error("VerticalContextApiService", `Error while fetching vertical context response`, e);
      throw e;
    }

    return null;
  }

  async initiateAutoDiscovery(request: InitiateAutoDiscoveryUIRequest, enabledConnections?: boolean) {
    try {
      const uiRequest: InitiateAutoDiscoveryUIRequest = {
        ...request,
        traceInfo: {
          traceId: uuidV4()
        }
      };
      const enabled = enabledConnections || false;
      const response = await datasourceApiManager
        .getDefault()
        .post(`/event-mapping/api/v1/auto-discovery/initiate?enableExistingConnections=${enabled}`, uiRequest);

      if (response && response?.data) {
        return response.data as InitiateAutoDiscoveryUIResponse;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error while initiating auto discovery request`, e);
      throw e;
    }

    return null;
  }

  async saveCustomerData(request: SaveOnboardingDataRequest) {
    try {
      const response = await datasourceApiManager
        .getDefault()
        .post(`/event-mapping/api/v1/data-onboarding/rawdata`, request);

      if (response && response?.data) {
        return response.data as SaveOnboardingResponse;
      }
    } catch (e) {
      logger.error("EventMapping-Demo-data-generator", `Error while isaving data`, e);
      throw e;
    }

    return null;
  }

  async getAutoDiscoveryStatus(request: AutoDiscoveryStatusUIRequest) {
    try {
      const uiRequest: AutoDiscoveryStatusUIRequest = {
        ...request,
        traceInfo: {
          traceId: uuidV4()
        }
      };
      const response = await datasourceApiManager
        .getDefault()
        .post(`/event-mapping/api/v1/auto-discovery/get-status`, uiRequest);

      if (response && response?.data) {
        return response.data as AutoDiscoveryStatusUIResponse;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error while reading auto discovery request status`, e);
      throw e;
    }

    return null;
  }

  async getAutoDiscovertyStatusV2(request: AutoDiscoveryStatusUIRequestV2, dryRun = false) {
    try {
      const uiRequest: AutoDiscoveryStatusUIRequestV2 = {
        ...request,
        traceInfo: {
          traceId: uuidV4()
        }
      };
      const response = await datasourceApiManager
        .getDefault()
        .post(`/event-mapping/api/v1/auto-discovery/get-discovery-status?dryRun=${dryRun}`, uiRequest);

      if (response && response?.data) {
        return response.data as AutoDiscoveryStatusUIResponseV2;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error while reading auto discovery request status`, e);
      throw e;
    }

    return null;
  }

  async getFieldMappingOptions(request: FieldMappingOptionsRequest) {
    try {
      const uiRequest: FieldMappingOptionsRequest = {
        ...request,
        traceInfo: {
          traceId: uuidV4()
        }
      };
      const response = await datasourceApiManager
        .getDefault()
        .post(`/event-mapping/api/v1/auto-discovery/get-field-mapping-options`, uiRequest);

      if (response && response?.data) {
        return response.data as FieldMappingOptionsResponse;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error while fetching field mapping options`, e);
      throw e;
    }

    return null;
  }

  async cancelAutoDiscoveryRequest(request: CancelAutoDiscoverRequest) {
    try {
      const response = await datasourceApiManager
        .getDefault()
        .post(`/event-mapping/api/v1/auto-discovery/cancel`, request);
      if (response && response?.data) {
        return response.data as CancelAutoDiscoverResponse;
      }
    } catch (e) {
      logger.error(
        "EventMapping-AutoDisovery-API-Service",
        `Error while cancelling auto discover request: ${request.requestId}`,
        e
      );
      throw e;
    }

    return null;
  }

  async triggerOnboardingDiscovery(request: Omit<RunOnboardingDiscoveryRequest, "traceInfo">) {
    const requestBody: RunOnboardingDiscoveryRequest = {
      traceInfo: {
        traceId: uuidV4()
      },
      ...request
    };
    const url = "/event-mapping/api/v1/data-onboarding/trigger-discovery";
    try {
      const response = await datasourceApiManager.getDefault().post(url, requestBody);
      if (response && response.data) {
        return response.data as DataOnBoardingDiscoveryStatus;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error discovering`, e);
      throw e;
    }

    return null;
  }

  async getDiscoveryStatus(request: Omit<DataOnboardingDiscoveryRequest, "traceId">) {
    const requestBody: DataOnboardingDiscoveryRequest = {
      traceId: uuidV4(),
      ...request
    };
    const url = "/event-mapping/api/v1/data-onboarding/get-discovery-status";
    try {
      const response = await datasourceApiManager.getDefault().post(url, requestBody);
      if (response && response.data) {
        return response.data as DataOnBoardingDiscoveryStatus;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error discovering`, e);
      throw e;
    }

    return null;
  }

  async getDiscoveryStatusV2(request: Omit<DataOnboardingDiscoveryRequestV2, "traceId">) {
    const url = "/event-mapping/api/v1/data-onboarding/get-discovery-status-v2";
    try {
      const response = await datasourceApiManager.getDefault().post(url, request);
      if (response && response.data) {
        return (response.data as any).discoveryStatus as DataOnboardingDiscoveryStatusResponseV2;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error discovering`, e);
      throw e;
    }

    return null;
  }

  async getDiscoveryResponse(request: DataOnboardingDiscoveryRequest) {
    const url = "/event-mapping/api/v1/data-onboarding/get-discovery-response";
    const requestBody: DataOnboardingDiscoveryRequest = {
      traceId: uuidV4(),
      ...request
    };
    try {
      const response = await datasourceApiManager.getDefault().post(url, requestBody);
      if (response && response.data) {
        return response.data as DataOnBoardingDiscoveryResponse;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error discovering`, e);
      throw e;
    }

    return null;
  }

  async saveDataModel(request: Omit<VerifyEventTypeDataModel, "traceInfo">) {
    const requestBody: VerifyEventTypeDataModel = {
      traceInfo: {
        traceId: uuidV4()
      },
      ...request
    };
    const url = "/modelling-service/api/v2/verify/event-type-data-model";
    try {
      const response = await datasourceApiManager.getDefault().post(url, requestBody);
      if (response && response.data) {
        return response.data as any;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error saving Model`, e);
      throw e;
    }

    return null;
  }

  async getAllMappingGroups(request: EventGroupRequest, startTimeMillis: number, endTimeMillis: number) {
    const requestBody: EventGroupRequest = {
      traceInfo: {
        traceId: uuidV4()
      },
      ...request
    };
    let url = "/event-mapping/api/v1/auto-discovery/get-all-groups";
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    url = `${url}${timeParams}`;
    try {
      const response = await datasourceApiManager.getDefault().post(url, requestBody);
      if (response && response.data) {
        return response.data as EventGroupResponse;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error getting all groups`, e);
      throw e;
    }

    return null;
  }

  async verifyMapping(request: VerifyMappingRequest) {
    const requestBody: VerifyMappingRequest = {
      traceInfo: {
        traceId: uuidV4()
      },
      ...request
    };
    try {
      const response = await datasourceApiManager
        .getDefault()
        .post("/event-mapping/api/v1/auto-discovery/verify-mapping", requestBody);
      if (response && response.data) {
        return response.data as VerifyMappingResponse;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error deleting mapping`, e);
      throw e;
    }

    return null;
  }

  async deleteMapping(request: DeleteMappingRequest) {
    const requestBody: DeleteMappingRequest = {
      traceInfo: {
        traceId: uuidV4()
      },
      ...request
    };
    try {
      const response = await datasourceApiManager
        .getDefault()
        .post("/event-mapping/api/v1/auto-discovery/delete-mapping", requestBody);
      if (response && response.data) {
        return response.data as VerifyMappingResponse;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error deleting mapping`, e);
      throw e;
    }

    return null;
  }

  async getMappingEventSourcePreview(
    request: MappingSourcePreviewRequest,
    startTimeMillis: number,
    endTimeMillis: number
  ) {
    const requestBody: MappingSourcePreviewRequest = {
      traceInfo: {
        traceId: uuidV4()
      },
      ...request
    };
    let url = "/event-mapping/api/v1/auto-discovery/get-event-source-preview";
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    url = `${url}${timeParams}`;
    try {
      const response = await datasourceApiManager.getDefault().post(url, requestBody);
      if (response && response.data) {
        return response.data as MappingSourcePreviewResponse;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error while fetching source preview response`, e);
      throw e;
    }

    return null;
  }

  async getMappingEventTypePreview(
    request: MappingEventTypePreviewRequest,
    cancelToken: CancelToken,
    startTimeMillis: number,
    endTimeMillis: number
  ) {
    const requestBody: MappingEventTypePreviewRequest = {
      traceInfo: {
        traceId: uuidV4()
      },
      ...request
    };
    let url = "/event-mapping/api/v1/auto-discovery/get-event-type-preview";
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    url = `${url}${timeParams}`;
    try {
      const response = await datasourceApiManager.getDefault().post(url, requestBody, {
        cancelToken: cancelToken
      });
      if (response && response.data) {
        return response.data as MappingEventTypePreviewResponse;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error while fetching event type preview`, e);
      throw e;
    }

    return null;
  }

  async getMappingEventGroupPreview(
    request: MappingGroupPreviewRequest,
    cancelToken: CancelToken,
    startTimeMillis: number,
    endTimeMillis: number
  ) {
    const requestBody: MappingGroupPreviewRequest = {
      traceInfo: {
        traceId: uuidV4()
      },
      ...request
    };
    let url = "/event-mapping/api/v1/auto-discovery/get-event-group-preview";
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    url = `${url}${timeParams}`;
    try {
      const response = await datasourceApiManager.getDefault().post(url, requestBody, {
        cancelToken: cancelToken
      });
      if (response && response.data) {
        return response.data as MappingGroupPreviewResponse;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error while fetching source preview response`, e);
      throw e;
    }

    return null;
  }

  async splitMappingEventType(
    request: MappingSplitEventTypeRequest,
    cancelToken: CancelToken,
    startTimeMillis: number,
    endTimeMillis: number
  ) {
    //event-mapping/api/v1/auto-discovery/split-event-type
    const requestBody: MappingSplitEventTypeRequest = {
      traceInfo: {
        traceId: uuidV4()
      },
      ...request
    };
    let url = "/event-mapping/api/v1/auto-discovery/split-event-type";
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    url = `${url}${timeParams}`;
    try {
      const response = await datasourceApiManager.getDefault().post(url, requestBody, {
        cancelToken: cancelToken
      });
      if (response && response.data) {
        return response.data as MappingSplitEventTypeResponse;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error while fetching source preview response`, e);
      throw e;
    }

    return null;
  }

  async verifyEventType(request: VerifyEventTypeRequest, dryRun = false) {
    const requestBody: VerifyEventTypeRequest = {
      traceInfo: {
        traceId: uuidV4()
      },
      ...request
    };
    const url = `/event-mapping/api/v1/auto-discovery/verify-event-type?isDryRun=${dryRun}`;
    try {
      const response = await datasourceApiManager.getDefault().post(url, requestBody);
      if (response && response.data) {
        return response.data as DiscoveryCommonUIResponse;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error while verifying event type`, e);
      throw e;
    }

    return null;
  }

  async getMappings(request: EventGroupRequest, startTimeMillis: number, endTimeMillis: number) {
    const requestBody: EventGroupRequest = {
      traceInfo: {
        traceId: uuidV4()
      },
      ...request
    };
    let url = "/event-mapping/api/v1/auto-discovery/get-mappings";
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    url = `${url}${timeParams}`;
    try {
      const response = await datasourceApiManager.getDefault().post(url, requestBody);
      if (response && response.data) {
        return response.data as EventGroupResponse;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error getting mappings`, e);
      throw e;
    }

    return null;
  }

  async getDataTransformationPreview(
    request: DataTransformationPreviewRequest,
    cancelToken: CancelToken,
    startTimeMillis: number,
    endTimeMillis: number
  ) {
    //event-mapping/api/v1/auto-discovery/transform-field
    const requestBody: DataTransformationPreviewRequest = {
      traceInfo: {
        traceId: uuidV4()
      },
      ...request
    };
    let url = "/event-mapping/api/v1/auto-discovery/transform-field";
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    url = `${url}${timeParams}`;
    try {
      const response = await datasourceApiManager.getDefault().post(url, requestBody, {
        cancelToken: cancelToken
      });
      if (response && response.data) {
        return response.data as DataTransformationPreviewResponse;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error while fetching data transformation`, e);
      throw e;
    }

    return null;
  }

  async addEventSource(
    request: AddEventSourceRequest,
    cancelToken: CancelToken,
    startTimeMillis: number,
    endTimeMillis: number
  ) {
    //event-mapping/api/v1/auto-discovery/event-group/add-event-source
    const requestBody: AddEventSourceRequest = {
      traceInfo: {
        traceId: uuidV4()
      },
      ...request
    };
    try {
      let url = "/event-mapping/api/v1/auto-discovery/event-group/add-event-source";
      const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
      url = `${url}${timeParams}`;
      const response = await datasourceApiManager.getDefault().post(url, requestBody, {
        cancelToken: cancelToken
      });
      if (response && response.data) {
        return response.data as MappingGroupPreviewResponse;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error while adding sources`, e);
      throw e;
    }

    return null;
  }

  async addEventGroup(request: AddEventGroupRequest, startTimeMillis: number, endTimeMillis: number) {
    const requestBody: AddEventGroupRequest = {
      traceInfo: {
        traceId: uuidV4()
      },
      ...request
    };
    let url = "/event-mapping/api/v1/auto-discovery/add-event-group";
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    url = `${url}${timeParams}`;
    try {
      const response = await datasourceApiManager.getDefault().post(url, requestBody);
      if (response && response.data) {
        return response.data as MappingGroupPreviewResponse;
      }
    } catch (e) {
      logger.error("EventMapping-AutoDisovery-API-Service", `Error while adding event group`, e);
      throw e;
    }

    return null;
  }

  getExportEventSourceDataUrl(eventSourceId: string) {
    const url = `/event-mapping/api/v1/auto-discovery/event-source-export-html/${eventSourceId}`;
    return datasourceApiManager.getDefault().getUrl(url);
  }

  async uploadEventTypeModelData(payload: Omit<UploadDataRequest, "traceInfo">): Promise<DataUploadStatusResponse> {
    try {
      const response = await datasourceApiManager
        .getDefault()
        .post<DataUploadStatusResponse, UploadDataRequest>("/modelling-service/api/v2/upload-data", {
          ...payload,
          traceInfo: {
            traceId: uuidV4()
          }
        });
      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      logger.error("EventMapping upload request", "failed to upload data ", e);
    }
  }

  async uploadFileDataStatus(payload: Omit<DataUploadStatusRequest, "traceInfo">): Promise<DataUploadStatusResponse> {
    try {
      const response = await datasourceApiManager
        .getDefault()
        .post<DataUploadStatusResponse, DataUploadStatusRequest>(`/modelling-service/api/v2/upload-data/status`, {
          ...payload,
          traceInfo: {
            traceId: uuidV4()
          }
        });

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      logger.error("EventMapping upload status", "failed to fetch file upload data status", e);
      throw e;
    }
  }

  async triggerDataOnboardingV2(
    payload: Omit<RunOnboardingDiscoveryRequest, "traceInfo">
  ): Promise<DataOnBoardingDiscoveryStatus> {
    try {
      const response = await datasourceApiManager
        .getDefault()
        .post<
          DataOnBoardingDiscoveryStatus,
          RunOnboardingDiscoveryRequest
        >("/event-mapping/api/v1/data-onboarding/trigger-data-onboarding", {
          ...payload,
          traceInfo: {
            traceId: uuidV4()
          }
        });

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      logger.error("AutoDiscoveryApiService", `Error while triggering data onboarding request`);
      throw e;
    }

    return null;
  }

  async getDataOnboardingRecommendations(
    payload: Omit<DataOnboardingDiscoveryRequest, "traceId">
  ): Promise<DataModelResponse> {
    try {
      const response = await datasourceApiManager
        .getDefault()
        .post<
          DataModelResponse,
          DataOnboardingDiscoveryRequest
        >("/event-mapping/api/v1/data-onboarding/get-onboarding-recommendation", {
          ...payload,
          traceId: uuidV4()
        });

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      logger.error("AutoDiscoveryApiService", `Error while fetching data onboarding recommendations`);
      throw e;
    }

    return null;
  }

  async getDataModel(payload: Omit<GetDataModelRequest, "traceInfo">): Promise<DataModelResponse> {
    try {
      const response = await datasourceApiManager
        .getDefault()
        .post<DataModelResponse, GetDataModelRequest>("/modelling-service/api/v2/get-data-model", {
          ...payload,
          traceInfo: {
            traceId: uuidV4()
          }
        });

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      logger.error("AutoDiscoveryApiService", `Error while fetching data model`);
      throw e;
    }
    return null;
  }

  async getDataModelFromBizEntityRoute(
    payload: Omit<GetDataModelRequestWithUseCase, "traceInfo">
  ): Promise<DataModelResponse> {
    try {
      const response = await datasourceApiManager
        .getDefault()
        .post<DataModelResponse, GetDataModelRequestWithUseCase>("/api/bizEntity/useCase/mappings", {
          ...payload,
          dataModel: {
            ...payload.dataModel,
            traceInfo: {
              traceId: uuidV4()
            }
          }
        });

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      logger.error("AutoDiscoveryApiService", `Error while fetching data model`);
      throw e;
    }
  }

  async getBicycleFieldsInsights(
    payload: Omit<GetBicycleFieldInsightRequest, "traceInfo">
  ): Promise<BicycleFieldInsightResponse> {
    try {
      const url = "/modelling-service/api/v2/bicycle-field-insights";
      const response = await datasourceApiManager
        .getDefault()
        .post<BicycleFieldInsightResponse, GetBicycleFieldInsightRequest>(url, {
          ...payload,
          traceInfo: {
            traceId: uuidV4()
          }
        });

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      logger.error("AutoDiscoveryApiService", "failed to fetch bicycle fields insights");
      throw e;
    }
  }

  private getTimeParams(startTimeMillis: number, endTimeMillis: number) {
    return `?st=${startTimeMillis}&et=${endTimeMillis}`;
  }
}

const autoDiscoveryApiService: AutoDiscoveryServiceInterface = new AutoDiscoveryApiService();
export default autoDiscoveryApiService;
