import React, { useCallback } from "react";
import { IncTabButtons } from "@inception/ui";
import { ExploreType, EXPLORE_TYPE_BIZ_ENTITY, EXPLORE_TYPE__USERSERVICE } from "../../services/api/explore";

interface Props {
  onChange: (id: ExploreType) => void;
  selectedTabId: ExploreType;
}

const bizEntityLabel = "Entity";
const userServiceLabel = "Event Type";

const tabs = [
  {
    id: bizEntityLabel,
    label: bizEntityLabel
  },
  {
    id: userServiceLabel,
    label: userServiceLabel
  }
];

export const ExploreTabButtons: React.FC<Props> = (props: Props) => {
  const { onChange, selectedTabId } = props;

  const onTypeChange = useCallback(
    (id: string) => {
      if (id === bizEntityLabel) {
        onChange(EXPLORE_TYPE_BIZ_ENTITY);
      } else {
        onChange(EXPLORE_TYPE__USERSERVICE);
      }
    },
    [onChange]
  );

  const selectedTab = selectedTabId === "bizEntity" ? bizEntityLabel : userServiceLabel;
  return (
    <IncTabButtons
      onChange={onTypeChange}
      selectedTabId={selectedTab}
      tabs={tabs}
    />
  );
};
