import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IncButton, IncFaIcon, IncModal, IncModalProps, IncSmartText } from "@inception/ui";
import { cloneDeep, isEmpty } from "lodash";
import { OpFragmentEditProps } from "../types";
import { VerticallyCenteredRow } from "../../components";
import { logger, useToggleState } from "../../core";
import { OpCreationFragmentEditType } from "../../services/api/operationalise";
import { TriggersEditorWrapper } from "../../operationalise-v2/v3/editors";
import { getComparatorAndScheduleText } from "../../operationalise-v2/uber-operationalize/utils";
import {
  BizDataQuery,
  UserServiceFieldMetricConfigDefinition,
  WidgetConfigUtils,
  exploreApiService
} from "../../services/api/explore";

type Props = OpFragmentEditProps;

export const StartTriggerFragmentEditor: FC<Props> = props => {
  const { opFragment, invalidFragmentJsx } = props;

  if (!isEmpty(opFragment?.startTriggerWithContext?.startTrigger)) {
    return <StartTriggerFragmentEditorInternal {...props} />;
  }

  return invalidFragmentJsx;
};

const StartTriggerFragmentEditorInternal: FC<Props> = props => {
  const { className, opFragment, onChange, onClose, showEditorInModal = false } = props;

  const { isOpen: isUpdateInProgress, open: startUpdate, close: finishUpdate } = useToggleState();

  const errorMessageRef = useRef("");

  const { comparator, opSchedule, startTrigger: defStartTrigger, baseKpi } = opFragment.startTriggerWithContext || {};
  const [startTrigger, setStartTrigger] = useState(cloneDeep(defStartTrigger));

  const resetStartTrigger = useCallback(() => {
    setStartTrigger(cloneDeep(defStartTrigger));
  }, [defStartTrigger]);

  useEffect(() => {
    resetStartTrigger();
  }, [resetStartTrigger]);

  const onCancelChanges = useCallback(() => {
    resetStartTrigger();
    onClose?.();
  }, [onClose, resetStartTrigger]);

  const onSaveChanges = useCallback(async () => {
    errorMessageRef.current = "";
    startUpdate();

    const { isError, message } = await onChange(
      {
        ...opFragment,
        startTriggerWithContext: {
          ...opFragment.startTriggerWithContext,
          startTrigger
        }
      },
      OpCreationFragmentEditType.TRIGGERS
    );

    if (isError) {
      errorMessageRef.current = message;
    }

    finishUpdate();
  }, [finishUpdate, startTrigger, onChange, opFragment, startUpdate]);

  const { comparatorText, scheduleText, altComparatorText } = useMemo(
    () => getComparatorAndScheduleText(opSchedule, comparator),
    [comparator, opSchedule]
  );

  const { metricOrFieldName, isMetricOrFieldNameLoading } = useGetMetricOrFieldName(baseKpi);

  const jsx = useMemo(
    () => (
      <div className={className}>
        <TriggersEditorWrapper
          altComparatorText={altComparatorText}
          comparatorText={comparatorText}
          editorOnly
          metricName={metricOrFieldName}
          onChange={setStartTrigger}
          readOnly={isMetricOrFieldNameLoading}
          scheduleText={scheduleText}
          startTrigger={startTrigger}
        />

        {!showEditorInModal && (
          <VerticallyCenteredRow className="flex-gap-12 marginTp10">
            {!isUpdateInProgress && (
              <IncButton
                color="primary"
                label="Save Changes"
                onClick={onSaveChanges}
                size="small"
              />
            )}

            {isUpdateInProgress && (
              <IncButton
                color="primary"
                loading
                loadingText="Saving..."
                size="small"
              />
            )}

            {Boolean(errorMessageRef.current) && (
              <VerticallyCenteredRow className="status-danger flex-gap-10">
                <IncFaIcon
                  className="status-danger"
                  iconName="exclamation-triangle"
                />
                <IncSmartText
                  className="status-danger"
                  text={errorMessageRef.current}
                />
              </VerticallyCenteredRow>
            )}

            {!isUpdateInProgress && (
              <IncButton
                color="secondary-blue"
                label="Cancel"
                onClick={onCancelChanges}
                size="small"
              />
            )}
          </VerticallyCenteredRow>
        )}
      </div>
    ),
    [
      altComparatorText,
      className,
      comparatorText,
      isMetricOrFieldNameLoading,
      isUpdateInProgress,
      metricOrFieldName,
      onCancelChanges,
      onSaveChanges,
      scheduleText,
      showEditorInModal,
      startTrigger
    ]
  );

  const { close: closeModal, isOpen: IsModalOpen } = useToggleState(true);

  const actions: IncModalProps["actions"] = useMemo(
    () => ({
      primary: {
        label: "Save Changes",
        onClick: () => {
          closeModal();
          onSaveChanges();
        },
        color: "primary",
        showLoader: isUpdateInProgress
      },
      secondary: {
        label: "Cancel",
        onClick: () => {
          closeModal();
          onCancelChanges();
        },
        color: "secondary"
      }
    }),
    [closeModal, isUpdateInProgress, onCancelChanges, onSaveChanges]
  );

  return (
    <>
      {!showEditorInModal && jsx}
      {showEditorInModal && (
        <IncModal
          actions={actions}
          contentClassName="op-fragment-editors-modal-content padding16"
          onClose={closeModal}
          show={IsModalOpen}
          size="xxlg"
          titleText="Triggers"
          withActionsBorder
          withTitleBorder
        >
          {jsx}
        </IncModal>
      )}
    </>
  );
};

export const useGetMetricOrFieldName = (bizDataQuery: BizDataQuery) => {
  const [isLoading, setIsLoading] = useState(true);
  const metricOrFieldNameRef = useRef("");

  const fetchNameViaWidgetId = useCallback(async (bizDataQuery: BizDataQuery) => {
    const { sliceSpec, id, idProps } = bizDataQuery;
    const { entityTypeId, eventTypeId } = WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(idProps);
    try {
      const { data, error, message } = await exploreApiService.getWidgetConfig(eventTypeId, entityTypeId, id);
      if (error || !data?.widgetConfig) {
        logger.error("useGetMetricOrFieldName", "Error fetching widgetConfig", message);
      } else {
        const { dataDefinition, labels, name: widgetName } = data.widgetConfig;
        const { metricId } = sliceSpec;
        const metricDef = dataDefinition.metrics[metricId] as UserServiceFieldMetricConfigDefinition;
        metricOrFieldNameRef.current = metricDef?.name || widgetName || labels?.name || "";
      }
    } catch (error) {
      logger.error("useGetMetricOrFieldName", "JS Error fetching widgetConfig", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const { buildingBlockConfig, widgetConfig, sliceSpec } = bizDataQuery;
    if (buildingBlockConfig) {
      const { buildingBlockDef, name } = buildingBlockConfig;
      metricOrFieldNameRef.current = buildingBlockDef?.name || name;
      setIsLoading(false);
    } else if (widgetConfig) {
      const { dataDefinition, labels, name: widgetName } = widgetConfig;
      const { metricId } = sliceSpec;
      const metricDef = dataDefinition.metrics[metricId] as UserServiceFieldMetricConfigDefinition;
      metricOrFieldNameRef.current = metricDef?.name || widgetName || labels?.name || "";
      setIsLoading(false);
    } else {
      fetchNameViaWidgetId(bizDataQuery);
    }
  }, [bizDataQuery, fetchNameViaWidgetId]);

  return {
    isMetricOrFieldNameLoading: isLoading,
    metricOrFieldName: metricOrFieldNameRef.current
  };
};
