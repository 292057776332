import { useMemo, useCallback, useEffect, useRef } from "react";
import { logger, TimeRange, useForceUpdate } from "../../../../core";
import { FieldPickerModel, useFieldPicker } from "../../../../field-picker";
import {
  DemoDataGenInfo,
  DemoDataParams,
  FieldPickerContextDTO,
  FieldSchemaResponse,
  OverallMappingStatus,
  UserServiceFieldSlice,
  WidgetConfigUtils
} from "../../../../services/api/explore";
import timeRangeUtils from "../../../../utils/TimeRangeUtils";
import { CatalogWidgetUtils } from "../CatalogWidgetUtils";
import { CatalogQueryConfig } from "../models";

export const useInitialiseFields = (
  entityType: string,
  eventTypeId: string,
  queryConfig: CatalogQueryConfig,
  timeRange: TimeRange,
  generateDemoData?: boolean
) => {
  const forceUpdate = useForceUpdate();

  const demoParams = useMemo<DemoDataParams>(() => {
    const useCaseQueryConfig =
      queryConfig?.sourceQueryConfig.queryType === "useCase" ? queryConfig.sourceQueryConfig.dataQueryConfig : null;
    if (useCaseQueryConfig) {
      const { mappingStatus, demoDataGenInfo } = useCaseQueryConfig;

      if (mappingStatus) {
        if (mappingStatus.overallStatus && mappingStatus.overallStatus === OverallMappingStatus.PENDING) {
          return {
            ...(demoDataGenInfo || ({} as DemoDataGenInfo)),
            generateDemoData: true
          };
        } else if (mappingStatus.isIncomplete) {
          return {
            ...(demoDataGenInfo || ({} as DemoDataGenInfo)),
            generateDemoData: true
          };
        }
      }
    }

    return generateDemoData
      ? {
          ...({} as DemoDataGenInfo),
          generateDemoData: true
        }
      : null;
  }, [generateDemoData, queryConfig]);

  const isValidWidget = useMemo(() => {
    if (demoParams) {
      return true;
    }

    const widgetResponseDTO = CatalogWidgetUtils.getWidgetResponseDTO("", "", "", queryConfig, [], "", "");
    const { widgetConfig, widgetId } = widgetResponseDTO || {};

    if (!widgetConfig && !widgetId) {
      logger.error(
        "Catalog Widget",
        "Invalid widget with no id or widgetConfig set in bizDataQuery. Marking as unmapped",
        {
          queryConfig
        }
      );

      return false;
    }

    if (!entityType && !eventTypeId) {
      logger.error("Catalog Widget", "No entityType or eventType found in bizDataQuery.idProps. Marking as unmapped", {
        queryConfig
      });

      return false;
    }

    return true;
  }, [demoParams, entityType, eventTypeId, queryConfig]);

  const fieldPickerContext: FieldPickerContextDTO = useMemo(
    () => ({
      entityId: !entityType ? eventTypeId : null,
      entityName: entityType,
      entityType,
      showFields: true,
      userServices: eventTypeId
        ? [
            {
              userServiceEntityId: eventTypeId
            }
          ]
        : []
    }),
    [entityType, eventTypeId]
  );

  const fieldPickerModelRef = useRef<FieldPickerModel>(null);

  const {
    data: fieldPickerModel,
    isFetching: isFetchingFieldsData,
    isSuccess: isFieldsFetchSuccess,
    isError: isFieldsFetchError,
    error: fieldsFetchError,
    getFields
  } = useFieldPicker();

  useMemo(() => {
    if (fieldPickerModel) {
      fieldPickerModelRef.current = fieldPickerModel;
    }
  }, [fieldPickerModel]);

  useMemo(() => {
    if (fieldsFetchError) {
      logger.error("Catalog Widget", "Error fetching fields", fieldsFetchError);
    }
  }, [fieldsFetchError]);

  const getLatestTimeRange = useCallback(() => {
    const latestTr = timeRangeUtils.getTimeRangeFromRaw(timeRange.raw);
    return latestTr;
  }, [timeRange.raw]);

  const fetchFields = useCallback(() => {
    const { from, to } = getLatestTimeRange();
    if (fieldPickerContext && queryConfig?.sourceQueryConfig?.queryType !== "widgetConfig") {
      getFields(fieldPickerContext, from.valueOf(), to.valueOf(), false, demoParams);
    } else if (queryConfig?.sourceQueryConfig?.queryType === "widgetConfig") {
      const { widgetResponse, metricId } = queryConfig.sourceQueryConfig;

      const metricDef = WidgetConfigUtils.getMetricDefinition(widgetResponse.widgetConfig, metricId);
      const { usFieldMetricDef } = WidgetConfigUtils.getMetricDefinitions(metricDef);
      const slices = (usFieldMetricDef?.userServiceFieldMetricConfig?.sliceSets || []).reduce(
        (acc, curr) => [...acc, ...curr.slices],
        [] as UserServiceFieldSlice[]
      );

      const fieldsResponse: FieldSchemaResponse = {
        bizFields: [],
        bizMetrics: [],
        resultMetadata: {
          resultEntityMap: {}
        },
        userServiceFields: slices.map(slice => ({
          userServiceField: slice.userServiceField,
          userServiceMetadata: {}
        })),
        userServiceMetrics: []
      };

      const fieldPickerModel = new FieldPickerModel(fieldPickerContext);
      fieldPickerModel.init(fieldsResponse);

      fieldPickerModelRef.current = fieldPickerModel;
      forceUpdate();
    }
  }, [demoParams, fieldPickerContext, forceUpdate, getFields, getLatestTimeRange, queryConfig.sourceQueryConfig]);

  useEffect(() => {
    if (!fieldPickerModelRef.current && isValidWidget) {
      fetchFields();
    }
  }, [fetchFields, fieldPickerModel, isValidWidget]);

  return {
    initialiseInProgress: (!fieldPickerModelRef.current || isFetchingFieldsData) && !isFieldsFetchError,
    initialiseSuccess: isFieldsFetchSuccess || !isFieldsFetchError,
    fieldPickerModel: fieldPickerModelRef.current,
    isValidWidget,
    demoParams,
    initialiseError: isFieldsFetchError ? "Error fetching fields" : null
  };
};
