import { IncLoadingSpinner } from "@inception/ui";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Layout } from "react-grid-layout";
import { isEqual } from "lodash";
import { dashboardUtils } from "..";
import { BaseWidgetImpl } from "../..";
import { VerticallyCenteredRow } from "../../../components";
import { Role, useNotifications, useTenantConfig, useToggleState, useVerticalConfig } from "../../../core";
import {
  EntityOperation,
  FieldPickerContextDTO,
  LogicalOperator,
  UserServiceFilterExpression,
  UserServiceFilterExpressionTree,
  UserServiceTuple
} from "../../../services/api/explore";
import { FieldPickerUtils, USERSERVICE_TAG } from "../../../utils";
import DashboardImpl from "../../model-impl/DashboardImpl";
import EventExpressionVariableImpl from "../../model-impl/EventExpressionVariableImpl";
import EventVariableImpl from "../../model-impl/EventVariableImpl";
import VariableImpl from "../../model-impl/VariableImpl";
import {
  CohortVariableModel,
  EventExpressionVariableModel,
  EventVariableModel,
  VariableType
} from "../../models/VariableModel";
import { VariableSrv } from "../../variables";
import { FilterWidgetImpl } from "../../widgets/FilterWidget/models";
import { FiltersContainer, EventFiltersContainer, AdvancedFilterModal } from "../../widgets/widget-components";
import { useDashboardFilters } from "../hooks/useDashboardFilters";
import appConfig from "../../../../appConfig";
import { useAuth } from "../../../login/state/useAuth";
import CohortVariableImpl from "../../model-impl/CohortVariableImpl";
import { ALL_USERSERVICES_ENTITY_TYPE_ID } from "../../../utils/ExploreUtils";
import { AddFilter } from "./AddFilter";
import { AddEntityFilter } from "./AddEntityFilter";

interface Props {
  onVariablesUpdate: (variables: VariableImpl[], widgetId: string) => void;
  dbImpl: DashboardImpl;
  variableSrvMap: Record<string, VariableSrv>;
  onUpdateCohortId: (cohortId: string, widgetId: string, isCohortLocked?: boolean, isCohortVisible?: boolean) => void;
  variableLoadingStateMap: Record<string, boolean>;
  onAddWidgetToDashboard: () => void;
}

export const DashboardFilterPanel: React.FC<Props> = (props: Props) => {
  const {
    dbImpl,
    variableSrvMap,
    onVariablesUpdate,
    onUpdateCohortId,
    variableLoadingStateMap,
    onAddWidgetToDashboard
  } = props;

  const canEdit = dbImpl.meta.edit;
  const isAnonShare = Boolean(appConfig?.anomShareId);

  const { authState } = useAuth();
  const { user } = authState;
  const isAnonymousUser = user?.isMockUser ?? false;
  const isPartnerUser = user?.role === Role.Guest;
  const isInternalUser = !isAnonymousUser && !isPartnerUser;

  const { notifyInfo } = useNotifications();

  const { errorText, fieldValuesMap, isError, isFetching } = useDashboardFilters(dbImpl);

  const { genericEventFilterWidget, genericEventFilterVarSrv } = useMemo(() => {
    const genericEventFilterWidget = dbImpl.widgets.find(
      (x: BaseWidgetImpl) => x.type === "filter" && (x as FilterWidgetImpl).genericEventFilter
    );
    const genericEventFilterVarSrv = genericEventFilterWidget ? variableSrvMap[genericEventFilterWidget.id] : null;

    return {
      genericEventFilterWidget,
      genericEventFilterVarSrv
    };
  }, [dbImpl.widgets, variableSrvMap]);

  const { selectedCohortVariables } = useMemo(() => {
    const entityFilterWidgets = dbImpl.widgets.filter(
      (x: BaseWidgetImpl) => x.type === "filter" && (x as FilterWidgetImpl).entityTypeId
    );

    const entityFilterVarSrv = entityFilterWidgets
      .map((x: BaseWidgetImpl) => variableSrvMap[x.id])
      .filter((x: VariableSrv) => x) as VariableSrv[];

    const selectedCohortVariables = entityFilterVarSrv?.map(x => x.getEntityCohortVariables()).flat() || [];

    return {
      selectedCohortVariables
    };
  }, [dbImpl.widgets, variableSrvMap]);

  const exprTree = useMemo(() => {
    const eventExprVar = genericEventFilterVarSrv
      ?.getVariables()
      ?.find(
        x => x.type === VariableType.EventExpression && x.name === dashboardUtils.GLOBAL_EVENT_EXPRESSION_FILTER_NAME
      ) as EventExpressionVariableImpl;

    return (
      eventExprVar?.getFilterExpressionTree() ?? {
        filterNodes: [],
        logicalOperator: LogicalOperator.AND
      }
    );
  }, [genericEventFilterVarSrv]);

  const onAddFilter = useCallback(
    (filterExpr: UserServiceFilterExpression) => {
      const { field } = filterExpr;
      const fieldLabel = FieldPickerUtils.getUserServiceFieldLabel(field);

      const eVarModel: Partial<EventVariableModel> = {
        userServiceId: ALL_USERSERVICES_ENTITY_TYPE_ID,
        userServiceField: field,
        operator: filterExpr.operator as EntityOperation,
        value: filterExpr.value || filterExpr.values,
        name: fieldLabel,
        type: VariableType.Event
      };
      const varImpl = new EventVariableImpl(eVarModel);

      if (!genericEventFilterWidget) {
        const impl = dashboardUtils.getDefaultWidgetImplByType("filter") as FilterWidgetImpl;
        impl.title = `Event Filters`;
        impl.userServiceId = ALL_USERSERVICES_ENTITY_TYPE_ID;
        impl.genericEventFilter = true;
        impl.variables = [varImpl];
        const layout: Partial<Layout> = {
          x: 0,
          y: 0
        };
        const model = impl.getSaveModel();
        dbImpl.addWidget(model, layout);
        onAddWidgetToDashboard();
      } else {
        const existingVars = [
          ...genericEventFilterWidget.variables
            .map(x => {
              if (x.type === VariableType.Event) {
                return new EventVariableImpl(x);
              } else if (x.type === VariableType.EventExpression) {
                return new EventExpressionVariableImpl(x);
              }
              return null;
            })
            .filter(x => Boolean(x))
        ];

        const isDuplicate = existingVars.some(
          x =>
            x?.name === varImpl?.name &&
            (x as EventVariableImpl)?.dataType === varImpl?.dataType &&
            (x as EventVariableImpl)?.userServiceField?.fieldName === varImpl?.userServiceField?.fieldName
        );
        if (isDuplicate) {
          notifyInfo(`Filter with name ${varImpl.name} already exists`);
          return;
        }

        const variables = [...existingVars, varImpl];
        onVariablesUpdate(variables, genericEventFilterWidget.id);
      }
    },
    [dbImpl, genericEventFilterWidget, notifyInfo, onAddWidgetToDashboard, onVariablesUpdate]
  );

  const onAddEntityFilter = useCallback(
    (bizEntityType: string, variable: CohortVariableModel) => {
      const existingWidget = dbImpl.widgets.find(
        (x: BaseWidgetImpl) => x.type === "filter" && (x as FilterWidgetImpl).entityTypeId === bizEntityType
      );
      const variableImpl = new CohortVariableImpl(variable);

      if (existingWidget) {
        const existingVars = existingWidget.variables.map(x => new CohortVariableImpl(x));

        const isDuplicate = existingVars.some(x => {
          const result =
            x?.name === variableImpl?.name &&
            x?.bizField?.entityField?.propName === variableImpl?.bizField?.entityField?.propName &&
            isEqual(x?.bizField, variableImpl?.bizField);
          return result;
        });

        if (isDuplicate) {
          notifyInfo(`Filter ${variableImpl.entityTypeId}.${variableImpl?.name} already exists!`);
        } else {
          onVariablesUpdate([...existingVars, variableImpl], existingWidget.id);
        }
      } else {
        const impl = dashboardUtils.getDefaultWidgetImplByType("filter") as FilterWidgetImpl;
        impl.cohortId = "";
        impl.cohortVisible = false;
        impl.setVariables([variableImpl]);
        impl.entityTypeId = bizEntityType;
        impl.title = `${bizEntityType} filters`;
        const model = impl.getSaveModel();
        const layout: Partial<Layout> = {
          x: 0,
          y: 0
        };
        dbImpl.addWidget(model, layout);
        onAddWidgetToDashboard();
      }
    },
    [dbImpl, notifyInfo, onAddWidgetToDashboard, onVariablesUpdate]
  );

  const onAddCohortFilter = useCallback(
    (bizEntityType: string) => {
      const existingWidget = dbImpl.widgets.find(
        (x: BaseWidgetImpl) => x.type === "filter" && (x as FilterWidgetImpl).entityTypeId === bizEntityType
      );

      if (existingWidget) {
        if ((existingWidget as FilterWidgetImpl).cohortVisible) {
          notifyInfo(`Cohort Filter for ${bizEntityType} already exists!`);
        } else {
          onUpdateCohortId("", existingWidget.id, false, true);
        }
      } else {
        const impl = dashboardUtils.getDefaultWidgetImplByType("filter") as FilterWidgetImpl;
        impl.entityTypeId = bizEntityType;
        impl.title = `${bizEntityType} filters`;
        impl.cohortId = "";
        impl.cohortVisible = true;
        const model = impl.getSaveModel();
        const layout: Partial<Layout> = {
          x: 0,
          y: 0
        };
        dbImpl.addWidget(model, layout);
        onAddWidgetToDashboard();
      }
    },
    [dbImpl, notifyInfo, onAddWidgetToDashboard, onUpdateCohortId]
  );

  const onAddFilterTree = useCallback(
    (expressionTree: UserServiceFilterExpressionTree) => {
      const eVarModel: Partial<EventExpressionVariableModel> = {
        userServiceId: USERSERVICE_TAG,
        name: dashboardUtils.GLOBAL_EVENT_EXPRESSION_FILTER_NAME,
        type: VariableType.EventExpression,
        expressionTree
      };
      const varImpl = new EventExpressionVariableImpl(eVarModel);

      if (!genericEventFilterWidget) {
        const impl = dashboardUtils.getDefaultWidgetImplByType("filter") as FilterWidgetImpl;
        impl.title = `Event Filters`;
        impl.userServiceId = USERSERVICE_TAG;
        impl.genericEventFilter = true;
        impl.variables = [varImpl];
        dbImpl.addWidget(impl.getSaveModel(), {
          x: 0,
          y: 0
        });
        onAddWidgetToDashboard();
      } else {
        let variableUpdated = false;

        const existingVars = [
          ...genericEventFilterWidget.variables.map(prevVar => {
            if (prevVar.name === dashboardUtils.GLOBAL_EVENT_EXPRESSION_FILTER_NAME) {
              variableUpdated = true;
              return varImpl;
            } else if (prevVar.type === VariableType.EventExpression) {
              return new EventExpressionVariableImpl(prevVar);
            } else if (prevVar.type === VariableType.Event) {
              return new EventVariableImpl(prevVar);
            }
            return prevVar;
          })
        ];

        const variables = variableUpdated ? existingVars : [...existingVars, varImpl];
        onVariablesUpdate(variables, genericEventFilterWidget.id);
      }
    },
    [dbImpl, genericEventFilterWidget, onAddWidgetToDashboard, onVariablesUpdate]
  );

  const {
    isOpen: isAddAdvancedFilterOpen,
    open: openAddAdvancedFilter,
    close: closeAddAdvancedFilter
  } = useToggleState();

  const [isAddFilterContextLoading, setIsAddFilterContextLoading] = useState(!isAnonShare);
  const [userServices, setUserServices] = useState<UserServiceTuple[]>(null);

  const { tenantConfigState } = useTenantConfig();
  const isNewNavigation = tenantConfigState?.useNewNavigation || false;

  const { useCaseApi, useCaseSchemaApi, useCaseSchemaExists, useCaseSchemasFetching, verticalConfig } =
    useVerticalConfig();
  const { useCaseToUnderlyingInfo, useCaseId } = verticalConfig || {};
  const { getSelectedUseCases } = useCaseApi;
  const { fetchUseCaseSchemasForVerticals } = useCaseSchemaApi;
  const schemasFetchingRef = useRef(!useCaseSchemaExists && !isAnonShare);

  const { enableAddEntityFilter } = tenantConfigState;

  useEffect(() => {
    if (isNewNavigation && useCaseId && !useCaseSchemaExists && !isAnonShare) {
      fetchUseCaseSchemasForVerticals();
      setTimeout(() => {
        schemasFetchingRef.current = false;
      }, 100);
    }
  }, [fetchUseCaseSchemasForVerticals, isAnonShare, isNewNavigation, useCaseId, useCaseSchemaExists]);

  useEffect(() => {
    if (isNewNavigation && useCaseId && !useCaseSchemasFetching && !schemasFetchingRef.current && !isAnonShare) {
      const useCases = getSelectedUseCases();
      const useCaseIds = useCases.map(useCase => useCase.id);
      const eventTypeIds = new Set<string>();

      for (const useCaseId of useCaseIds) {
        const useCaseInfo = useCaseToUnderlyingInfo[useCaseId];
        useCaseInfo?.eventTypes?.forEach(eventType => eventTypeIds.add(eventType.entityId));
      }

      const userServices: UserServiceTuple[] = [];
      eventTypeIds.forEach(eventTypeId => {
        userServices.push({
          userServiceEntityId: eventTypeId
        });
      });
      setUserServices(userServices);
      setIsAddFilterContextLoading(false);
    } else {
      schemasFetchingRef.current = false;
      setIsAddFilterContextLoading(false);
    }
  }, [
    fetchUseCaseSchemasForVerticals,
    getSelectedUseCases,
    isAnonShare,
    isNewNavigation,
    useCaseId,
    useCaseSchemasFetching,
    useCaseToUnderlyingInfo
  ]);

  const fieldPickerContext = useMemo<FieldPickerContextDTO>(
    () => ({
      entityId: "",
      entityType: ALL_USERSERVICES_ENTITY_TYPE_ID,
      entityName: "",
      userServices: userServices || []
    }),
    [userServices]
  );

  const isAddFilterMetaLoading = isAddFilterContextLoading || useCaseSchemasFetching || schemasFetchingRef.current;

  return (
    <div className="dashboard-filter-panel">
      <VerticallyCenteredRow className="dashboard-filters-container flex-gap-8 inc-flex-row-wrap">
        {isFetching && <IncLoadingSpinner />}
        {isError && !isFetching && <span className="status-danger">{errorText || "Error fetching filters"}</span>}
        {!isFetching && (
          <>
            {Object.keys(fieldValuesMap || {})
              .sort((a, b) => {
                const variableSrvA = variableSrvMap[a];
                const variableSrvB = variableSrvMap[b];
                const strA = variableSrvA.getEntityTypeId().toLowerCase();
                const strB = variableSrvB.getEntityTypeId().toLowerCase();

                if (strA < strB) {
                  return -1;
                }
                if (strA > strB) {
                  return 1;
                }
                return 0;
              })
              .map(widgetId => {
                const fieldValues = fieldValuesMap[widgetId];
                const variableSrv = variableSrvMap[widgetId];
                const widget = dbImpl.widgets.find(x => x.id === widgetId);

                if (widget) {
                  const { entityTypeId } = widget as FilterWidgetImpl;

                  return (
                    <FiltersContainer
                      canEdit={canEdit}
                      entityTypeId={entityTypeId}
                      fieldValues={fieldValues}
                      isInternalUser={isInternalUser}
                      key={widgetId}
                      onUpdateCohortId={onUpdateCohortId}
                      onVariablesUpdate={onVariablesUpdate}
                      variableLoadingStateMap={variableLoadingStateMap}
                      variableSrv={variableSrv}
                      widgetId={widgetId}
                    />
                  );
                }

                return null;
              })
              .filter(Boolean)}

            {genericEventFilterVarSrv && (
              <EventFiltersContainer
                canEdit={canEdit}
                isInternalUser={isInternalUser}
                onVariablesUpdated={onVariablesUpdate}
                variableSrv={genericEventFilterVarSrv}
                widgetId={genericEventFilterWidget.id}
              />
            )}

            {canEdit && !isAnonShare && (
              <AddFilter
                isMetaLoading={isAddFilterMetaLoading}
                onAddFilter={onAddFilter}
                onAddFilterTree={openAddAdvancedFilter}
                userServices={userServices}
              />
            )}

            {canEdit && !isAnonShare && enableAddEntityFilter && (
              <AddEntityFilter
                isMetaLoading={isAddFilterMetaLoading}
                onAddCohortFilter={onAddCohortFilter}
                onAddEntityFilter={onAddEntityFilter}
                selectedCohortVariables={selectedCohortVariables}
              />
            )}
          </>
        )}

        {isAddAdvancedFilterOpen && (
          <AdvancedFilterModal
            fieldPickerContext={fieldPickerContext}
            filterExprTree={exprTree}
            onChange={onAddFilterTree}
            onClose={closeAddAdvancedFilter}
          />
        )}
      </VerticallyCenteredRow>
    </div>
  );
};
