import {
  IncDateTimeFormat,
  IncHighchartsDateTimeFormat,
  DateTimeOptions,
  getFormattedDateTime,
  IncSelectOption
} from "@inception/ui";
import { duration } from "moment";
import { range } from "lodash";
import { dateTime, TimeRange } from "../../../../core";
import kbn from "../../../../services/datasources/core/kbn";
import { oneMonthOpt, timeIntervalOptions } from "../options";
import { IncHeatMapSerie } from "../../../../components/heat-map-v2";
import { ExtAlertCountResponseEntry, ResultsHeatMapDatum } from "./types";

export const HEATMAP_AXIS_LABEL_CUSTOM_ACCESSOR = "heatMapYAxisLabel";
export const HEATMAP_DATA_SPACE_BUFFER = 2;

type HeatMapSerie = IncHeatMapSerie<ResultsHeatMapDatum>;

export const getHeatMapEntries = (
  countEntries: ExtAlertCountResponseEntry[],
  timeIntervalMillis: number,
  startTimeMillis: number,
  endTimeMillis: number,
  numPaddingColumns: number,
  isTwoDimensional: boolean
) => {
  const {
    groupInterval,
    xAxisFormat,
    xAxisFormatOptions,
    yAxisFormat,
    yAxisFormatOptions,
    yAxisTimeInterval: possibleYAxisTimeInterval
  } = getHeatMapAxesFormats(startTimeMillis, endTimeMillis, timeIntervalMillis, isTwoDimensional);

  const yAxisTimeInterval = isTwoDimensional ? possibleYAxisTimeInterval : timeIntervalMillis;
  const xAxisTimeInterval = Math.min(yAxisTimeInterval, timeIntervalMillis);

  let bucketSize = Math.floor(yAxisTimeInterval / xAxisTimeInterval);

  const groupFactor = Math.floor(groupInterval / yAxisTimeInterval);
  const groupSize = groupFactor * bucketSize;

  let iterStartTimeMillis = startTimeMillis;

  //Extra Coloumn required = adjusting factor = totalblocks-extracell/ grpsize
  const totalCells = Math.round((endTimeMillis - startTimeMillis) / timeIntervalMillis);
  const extraCell = groupSize * Math.floor(countEntries.length / groupSize);
  const adjustingFactor = Math.ceil((totalCells - extraCell) / groupSize);
  const numGroups = Math.floor(countEntries.length / groupSize) + adjustingFactor;

  let entryIdx = 0;

  const heatMapSeriesMap = new Map<string, HeatMapSerie>();

  for (let groupIdx = 0; groupIdx < numGroups; groupIdx++) {
    const maxX = bucketSize;

    const start = iterStartTimeMillis;
    const end = Math.min(iterStartTimeMillis + groupInterval, endTimeMillis);

    const formattedStart = getFormattedDateTime(start, xAxisFormat, xAxisFormatOptions);
    const formattedEnd = getFormattedDateTime(end, xAxisFormat, xAxisFormatOptions);

    const isDailyInterval = groupInterval === duration(1, "d").asMilliseconds();
    const startAndEndMatch = formattedStart === formattedEnd;
    const groupName =
      startAndEndMatch || isDailyInterval || !isTwoDimensional ? formattedStart : `${formattedStart} - ${formattedEnd}`;

    for (let yIdx = 0; yIdx < groupFactor; yIdx++) {
      const serieStartMillis = iterStartTimeMillis + yIdx * yAxisTimeInterval;

      const serieId = getFormattedDateTime(serieStartMillis, yAxisFormat, yAxisFormatOptions);

      const serie: HeatMapSerie = heatMapSeriesMap.get(serieId) || {
        id: serieId,
        data: []
      };
      heatMapSeriesMap.set(serieId, serie);

      for (let xIdx = 0; xIdx < maxX; xIdx++) {
        const start = serieStartMillis + xIdx * xAxisTimeInterval;
        const end = Math.min(start + xAxisTimeInterval, endTimeMillis);

        const x = "";

        if (xIdx < bucketSize) {
          const entry = countEntries[entryIdx];
          entryIdx += 1;

          let count = 0;

          if (entry) {
            // Actual Series
            const { count: countStr } = entry;
            count = parseInt(countStr.toString(), 10);
          }

          const formattedStart = getFormattedDateTime(start, yAxisFormat);
          const formattedEnd = getFormattedDateTime(end - 1, yAxisFormat);

          const cellName = formattedStart === formattedEnd ? formattedStart : `${formattedStart} - ${formattedEnd}`;

          serie.data.push({
            x,
            y: count,
            endTimeMillis: end,
            entry,
            groupName,
            cellName,
            startTimeMillis: start
          });
        }
      }
    }

    iterStartTimeMillis += groupInterval;
  }

  let heatMapSeries = Array.from(heatMapSeriesMap.values());
  heatMapSeries.forEach(series => {
    const rangeStart = bucketSize;
    const rangeEnd = bucketSize * numGroups + 1;

    const idxArr = range(rangeStart, rangeEnd, bucketSize);

    idxArr.forEach((idx, iter) => {
      // Buffer series to show a gap between groups
      const arr: ResultsHeatMapDatum[] = Array(numPaddingColumns).fill(1).map(getEmptyEntry);

      const index = idx + iter * numPaddingColumns;
      series.data.splice(index, 0, ...arr);
    });

    series.data.forEach((d, idx) => (d.x = idx));
  });

  if (!isTwoDimensional && heatMapSeries.length) {
    const nHeatMapSeries: typeof heatMapSeries = [
      {
        data: [],
        id: ""
      }
    ];
    const idxFactor = heatMapSeries.length;
    heatMapSeries.forEach((series, groupIdx) => {
      const { data } = series;
      data.forEach((datum, idx) => {
        const x = (idx % groupSize) + groupSize * (groupIdx + idxFactor * Math.floor(idx / groupSize));
        nHeatMapSeries[0].data.push({
          ...datum,
          x
        });
      });
    });

    nHeatMapSeries[0].data.sort((a, b) => (a.x as number) - (b.x as number));
    heatMapSeries = nHeatMapSeries;
    bucketSize = groupSize;
  } else {
    for (const timeSlot of heatMapSeries) {
      if (timeSlot.data && Array.isArray(timeSlot.data)) {
        timeSlot.data = timeSlot.data.filter(item => item.entry != null);
      }
    }

    const days: string[] = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    heatMapSeries.sort((a, b) => days.indexOf(a.id) - days.indexOf(b.id));
  }

  return {
    heatMapSeries,
    bucketSize
  };
};

const getEmptyEntry = (): ResultsHeatMapDatum => ({
  x: "",
  y: null,
  endTimeMillis: null,
  entry: null,
  groupName: "",
  startTimeMillis: null,
  cellName: null
});

export const getDefaultTimeIntervalOpt = (timeRange: TimeRange): IncSelectOption<number> => {
  const isRelativeTime = timeRange.raw.to === "now";
  const diffMillis = timeRange.to.valueOf() - timeRange.from.valueOf();

  const predefinedOpt =
    timeIntervalOptions.find(opt => {
      const optMillis = parseInt(opt.value, 10);
      return optMillis <= diffMillis;
    }) || oneMonthOpt;

  if (!isRelativeTime) {
    const fromMillis = timeRange.from.clone().startOf("day").add(1, "ms").valueOf();
    const toMillis = timeRange.to.clone().endOf("day").valueOf();

    const fromLabel = getFormattedDateTime(fromMillis, IncDateTimeFormat.minimal);
    const toLabel = getFormattedDateTime(toMillis, IncDateTimeFormat.minimal);

    return {
      label: `${fromLabel} to ${toLabel}`,
      value: `${fromMillis} to ${toMillis}`,
      data: predefinedOpt.data
    };
  } else {
    return predefinedOpt;
  }
};

export const heatMapColorsByRange: Record<string, string> = {
  "0": "#ffffff29",
  "1-5": "#FFCF72",
  "5-10": "#F9A471",
  "10+": "#D25D6C"
};

export const getTileColorByCount = (count: number) => {
  if (count <= 0) {
    return heatMapColorsByRange["0"];
  } else if (count > 0 && count <= 5) {
    return heatMapColorsByRange["1-5"];
  } else if (count > 5 && count <= 10) {
    return heatMapColorsByRange["5-10"];
  }

  return heatMapColorsByRange["10+"];
};

export const getHeatMapAxesFormats = (
  startTimeMillis: number,
  endTimeMillis: number,
  timeIntervalMillis: number,
  isTwoDimensional: boolean
) => {
  const startDateTime = dateTime(startTimeMillis);
  const endDateTime = dateTime(endTimeMillis);

  const diff = endDateTime.diff(startDateTime, "millisecond");

  let xAxisFormat: IncHighchartsDateTimeFormat | IncDateTimeFormat = IncHighchartsDateTimeFormat.monthDay;
  const xAxisFormatOptions: DateTimeOptions = {
    skipTime: true
  };

  let yAxisFormat: IncHighchartsDateTimeFormat | IncDateTimeFormat = IncHighchartsDateTimeFormat.shortDayOnly;
  const yAxisFormatOptions: DateTimeOptions = {
    skipTime: true
  };

  let yAxisTimeInterval = kbn.round_interval(timeIntervalMillis);
  let groupInterval = yAxisTimeInterval;

  if (diff > ONE_WEEK) {
    xAxisFormat = IncHighchartsDateTimeFormat.monthDayDescriptive;

    yAxisFormat = IncHighchartsDateTimeFormat.shortDayOnly;

    yAxisTimeInterval = ONE_DAY;
    groupInterval = isTwoDimensional ? ONE_WEEK : ONE_DAY;
  } else if (diff === ONE_WEEK) {
    xAxisFormat = IncHighchartsDateTimeFormat.monthDayDescriptive;
    xAxisFormatOptions.skipTime = isTwoDimensional;

    yAxisFormat = isTwoDimensional ? IncHighchartsDateTimeFormat.timeOnly : IncHighchartsDateTimeFormat.shortDayOnly;
    yAxisFormatOptions.skipTime = false;

    yAxisTimeInterval = 6 * ONE_HOUR;
    groupInterval = isTwoDimensional ? ONE_DAY : 12 * ONE_HOUR;
  } else if (diff > ONE_DAY) {
    xAxisFormat = IncHighchartsDateTimeFormat.monthDayDescriptive;
    xAxisFormatOptions.skipTime = isTwoDimensional;

    yAxisFormat = isTwoDimensional ? IncHighchartsDateTimeFormat.timeOnly : IncHighchartsDateTimeFormat.shortDayOnly;
    yAxisFormatOptions.skipTime = false;

    yAxisTimeInterval = 6 * ONE_HOUR;
    groupInterval = isTwoDimensional ? ONE_DAY : 6 * ONE_HOUR;
  } else {
    xAxisFormat = IncHighchartsDateTimeFormat.timeOnly;
    xAxisFormatOptions.skipTime = false;

    yAxisFormat = IncHighchartsDateTimeFormat.timeOnly;
    yAxisFormatOptions.withSeconds = true;
    yAxisFormatOptions.skipTime = false;

    const hrDiff = endDateTime.diff(startDateTime, "hour");
    if (hrDiff > 12) {
      yAxisTimeInterval = 20 * ONE_MIN;
      groupInterval = ONE_HOUR;
    } else if (hrDiff > 6) {
      yAxisTimeInterval = 10 * ONE_MIN;
      groupInterval = 30 * ONE_MIN;
    } else if (hrDiff > 3) {
      yAxisTimeInterval = 5 * ONE_MIN;
      groupInterval = 15 * ONE_MIN;
    } else if (hrDiff > 1) {
      yAxisTimeInterval = 2.5 * ONE_MIN;
      groupInterval = 5 * ONE_MIN;
    } else {
      yAxisTimeInterval = ONE_MIN;
      groupInterval = ONE_MIN;
    }
  }

  return {
    xAxisFormat,
    xAxisFormatOptions,
    yAxisFormat,
    yAxisFormatOptions,
    yAxisTimeInterval,
    groupInterval
  };
};

const ONE_MIN = 60 * 1000;
const ONE_HOUR = 60 * ONE_MIN;
const ONE_DAY = 24 * ONE_HOUR;
const ONE_WEEK = 7 * ONE_DAY;
