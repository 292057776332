import { XAxisOptions, AnnotationsOptions } from "highcharts";
import { merge, capitalize, isEqual, cloneDeep } from "lodash";
import { default as React, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getFormattedDateTime, IncButton, IncDateTimeFormat, IncToolTip, ISaxIcon } from "@inception/ui";
import { TimeSeries, logger, TimeRange, useToggleState, useForceUpdate } from "../../../core";
import TimeSeriesComponent from "../../../components/time-series/TimeSeries";
import { IncTimeSeriesOptions } from "../../../components/time-series/types";
import { DataQueryRequest, DataQueryResponse, ScopedVars } from "../../../services/api/types";
import kbn from "../../../services/datasources/core/kbn";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { useVariables } from "../useVariables";
import { Op10zeDataQuery } from "../../../services/datasources/operationalize/types";
import { TimeSeriesHelper } from "../TimeSeries/models/TimeSeriesUtils";
import { getSeasonSecsStr } from "../../../utils/ExploreUtils";
import { RegionHighlight } from "../../../components/charts/plot-bands/RegionHighlights";
import { featureFlagService, FEATURE_FLAGS } from "../../../services/feature-flags";
import {
  EvaluatedDataTime,
  Event,
  MonitoringFreqForPeriod,
  OpThresholdSeasonality
} from "../../../services/api/operationalise";
import { VerticallyCenteredRow } from "../../../components";
import SparkLine from "../../../components/sparkline/SparkLine";
import { getLabelForTimeObj } from "../../../utils/DurationUtils";
import { Op10zeWidgetProps } from "./types";
import {
  useOp10zeQuery,
  Op10zeQueryResponse,
  Op10zeDataQueryResponse,
  Op10zePreviewQueryResponse
} from "./useOp10zeQuery";
import { Op10zeQueryWidgetUtils } from "./utils";
import { useMonitoringFrequencyRenderer } from "./useMonitoringFrequencyRenderer";

const getFontSizeBasedOnHeight = (height: number): number => {
  const minFontSize = 10;
  const maxFontSize = 14;

  // Define a reasonable height range for scaling
  const minHeight = 100; // Smallest height, corresponding to the minFontSize
  const maxHeight = 800; // Largest height, corresponding to the maxFontSize

  // Clamp the height within the range
  const clampedHeight = Math.max(minHeight, Math.min(maxHeight, height));

  // Scale font size proportionally between minFontSize and maxFontSize
  const fontSize = minFontSize + ((clampedHeight - minHeight) * (maxFontSize - minFontSize)) / (maxHeight - minHeight);

  return fontSize;
};

const Op10zeQueryWidget: React.FC<Op10zeWidgetProps> = props => {
  const {
    widget,
    variableSrvMap,
    variableLoadingStateMap,
    timeRange,
    loadingElement,
    noDataElement,
    variablesLoadingElement,
    onAction,
    setCustomActions
  } = props;

  const {
    id,
    datasource,
    queries: wQueries,
    chartType,
    title,
    subTitleHtmlStr,
    mode,
    properties,
    isSparkline,
    disableSparklineInteraction,
    seasonality,
    lookBack,
    isOpportunity
  } = widget;
  const { highlights, hideHistory = false } = widget.properties || {};

  const { insightMode, payload } = wQueries?.[0] || {};
  const { frequency } = payload || {};

  const isPreviewMode = mode === "preview";
  const shouldOverrideTimeRange = isPreviewMode && insightMode;

  const [fetchedTimeseries, setFetchedTimeseries] = useState<TimeSeries[]>([]);
  const [fetchedEvaluatedDataTime, setFetchedEvaluatedDataTime] = useState<EvaluatedDataTime[]>([]);
  const [seasonSecs, setSeasonSecs] = useState<number>();
  const [queryTimeRange, setQueryTimeRange] = useState(timeRange);

  const [suppressionReasonInfo, setSuppressionReasonInfo] = useState<Record<string, string>>({});
  const [suppressionRegions, setSuppressionRegions] = useState<RegionHighlight[]>([]);
  const [incidentRegions, setIncidentRegions] = useState<RegionHighlight[]>([]);

  const [monitoredFrequencies, setMonitoredFrequencies] = useState<MonitoringFreqForPeriod[]>([]);
  const [customTimeRange, setCustomTimeRange] = useState<TimeRange>();
  const [allEvents, setAllEvents] = useState<Event[]>([]);

  useEffect(() => {
    setQueryTimeRange(timeRange);
  }, [timeRange]);

  const getExtTooltipInfo = useCallback(
    (tsMillis: number) => {
      let tooltipStr = "";
      const tsSecsStr = timeRangeUtils.getSecondsFromMillis(tsMillis).toString();
      const suppressionReason: string = suppressionReasonInfo[tsSecsStr] || "";
      const toolTipDataIndex = fetchedTimeseries?.[0]?.datapoints?.findIndex(val => val?.[1] === tsMillis);
      const evTimeObj = fetchedEvaluatedDataTime[toolTipDataIndex];
      if (evTimeObj) {
        const startTimeMillis = parseInt(evTimeObj?.fromTimeInSec || "0", 10) * 1000;
        const endTimeMillis = parseInt(evTimeObj?.toTimeInSec || "0", 10) * 1000;
        const startTime = getFormattedDateTime(startTimeMillis, IncDateTimeFormat.minimal, undefined, undefined, true);
        const endTime = getFormattedDateTime(endTimeMillis, IncDateTimeFormat.minimal, undefined, undefined, true);
        tooltipStr += `<div class='inc-charts-tooltip-series-row'>
              <div class='inc-charts-tooltip-series-name text-center'>Time range: ${startTime} - ${endTime}</div> 
            </div>`;
      }
      if (suppressionReason) {
        tooltipStr += `<div class='inc-charts-tooltip-series-row'>
      <div class='inc-text-subtext-medium status-warning'>This anomaly was suppressed because the ${suppressionReason}</div>
    </div>`;
      }

      return [tooltipStr, false] as [string, boolean];
    },
    [fetchedEvaluatedDataTime, fetchedTimeseries, suppressionReasonInfo]
  );

  const { from, raw, to } = queryTimeRange;

  const dashboardTimeRange = useMemo(
    () => ({
      from: from.valueOf(),
      to: to.valueOf()
    }),
    [from, to]
  );

  const interval = useMemo(() => kbn.calculateInterval(dashboardTimeRange, null, "60s").interval, [dashboardTimeRange]);
  const onError = useCallback(
    (error: string) => {
      onAction({
        type: "onError",
        error
      });
    },
    [onAction]
  );

  const onSelectTimeRange = useCallback((startMillis: number, endMillis: number) => {
    const nTr = timeRangeUtils.getTimeRangeFromRaw({
      from: startMillis.toString(),
      to: endMillis.toString(),
      fromType: "absolute",
      toType: "absolute"
    });
    setQueryTimeRange(nTr);
    return true;
  }, []);

  const onResetSelection = useCallback(() => {
    setQueryTimeRange(timeRange);
  }, [timeRange]);

  const manualZoomProps = useMemo(
    () => ({
      izZoomed: !isEqual(timeRange, queryTimeRange),
      showResetZoomControl: true
    }),
    [queryTimeRange, timeRange]
  );

  const { variables: scopedVars, variablesLoading } = useVariables(variableSrvMap, widget, variableLoadingStateMap);

  const variableValues = useMemo<ScopedVars>(
    () => ({
      ...scopedVars,
      __interval: {
        text: interval,
        value: interval
      }
    }),
    [interval, scopedVars]
  );

  const { isOpen: shouldShowHistoricalData, toggle: toggleHistoricalData } = useToggleState();

  const dqr = useMemo<DataQueryRequest<Op10zeDataQuery>>(() => {
    const { from, to } = timeRangeUtils.getTimeRangeMillisFromRaw(raw);
    return {
      dashboardId: "1",
      panelId: id,
      targets: wQueries.map(e => {
        const nQuery = cloneDeep(e);
        if (nQuery.payload) {
          nQuery.payload.showHistoricalData = shouldShowHistoricalData;
        }
        if (nQuery.previewPayload) {
          nQuery.previewPayload.showHistoricalData = shouldShowHistoricalData;
        }

        return nQuery;
      }) as Op10zeDataQuery[],
      startTime: from,
      endTime: to,
      interval: interval,
      requestId: id,
      scopedVars: variableValues
    };
  }, [id, interval, shouldShowHistoricalData, raw, variableValues, wQueries]);

  const { isError, isFetching, data, isSuccess, error, refetch } = useOp10zeQuery(dqr, datasource, null);

  const showSuppressionRegion = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.enableSuppressionBands) && false;

  useEffect(() => {
    if (!variablesLoading) {
      refetch();
      logger.debug("Operationalize Widget", `Calling refetch for ${id}`);
    }
  }, [id, refetch, variablesLoading, variableValues, raw, dqr]);

  useEffect(() => {
    const { from, to } = timeRangeUtils.getTimeRangeFromRaw(raw);
    widget.options = merge(widget.options || {}, {
      xAxis: {
        max: to.unix(),
        min: from.unix()
      } as XAxisOptions
    } as IncTimeSeriesOptions);
  }, [raw, widget, widget.options]);

  useEffect(() => {
    if (isFetching) {
      onError("");
    }

    const isAxiosError = (data as any)?.isAxiosError || isError;
    const errMessage = (data as any)?.message || error;

    // Array.isArray(Data) check is added because in some cases for whatever reason data is not an array and code errors
    // when doing data.forEach()
    if (!isFetching && isSuccess && data && Array.isArray(data) && !isAxiosError) {
      const allTimeSeries: TimeSeries[] = [];
      const errs: string[] = [];
      const allEvents: Event[] = [];
      const evaluatedDataTime: EvaluatedDataTime[] = [];
      data.forEach((d: DataQueryResponse<Op10zeQueryResponse[]>) => {
        const { data, error } = d;

        const datumEntry = isPreviewMode ? data[0]?.previewData : data[0]?.data;
        const datum = datumEntry as Op10zeDataQueryResponse;
        const previewDatum = datumEntry as Op10zePreviewQueryResponse;
        if (datum) {
          const { events, evaluatedDataTime: pEvaluatedDataTime } = datum;
          if (pEvaluatedDataTime && !isPreviewMode) {
            evaluatedDataTime.push(...pEvaluatedDataTime);
          }
          allEvents.push(...(events || []));
        }

        const { seasonSecs, suppressionRegions: dSuppressionRegions = [], timeseries = [] } = datumEntry || {};

        if (error) {
          errs.push(error.data?.message || error.message || "Query error");
        }

        allTimeSeries.push(...timeseries);
        setSeasonSecs(seasonSecs);

        if (!isPreviewMode) {
          const { summary = [], suppressionReasonInfo = {} } = datum || {};

          let incidentRegions = Op10zeQueryWidgetUtils.getIncidentBands(summary, id, to.valueOf(), isOpportunity);
          incidentRegions = incidentRegions.sort(
            (irA, irB) => parseInt(String(irA.from), 10) - parseInt(String(irB.from), 10)
          );

          setIncidentRegions(incidentRegions);
          setSuppressionReasonInfo(suppressionReasonInfo);
        }

        if (isPreviewMode) {
          const monitoredFrequencies = previewDatum?.monitoringFrequency;
          setMonitoredFrequencies(monitoredFrequencies);
        }

        if (isPreviewMode) {
          const { endTimeMillis: endTimeMillisStr, startTimeMillis: startTimeMillisStr } =
            previewDatum?.timeRange || {};

          const endTimeMillis = parseInt(String(endTimeMillisStr), 10);
          const startTimeMillis = parseInt(String(startTimeMillisStr), 10);

          if (!isNaN(endTimeMillis) && !isNaN(startTimeMillis)) {
            const timeRange = timeRangeUtils.getTimeRangeFromRaw({
              from: String(startTimeMillis),
              to: String(endTimeMillis)
            });

            setCustomTimeRange(timeRange);
          }
        }

        if (showSuppressionRegion) {
          const suppressionRegions = Op10zeQueryWidgetUtils.getSuppressionBands(dSuppressionRegions);
          setSuppressionRegions(suppressionRegions);
        }

        setAllEvents(allEvents);
      });

      allTimeSeries.forEach(ts => {
        if (ts.target === "{}") {
          ts.target = title;
        } else if (ts.target.startsWith("{") && ts.target.endsWith("}")) {
          ts.target = ts.target.substring(1, ts.target.length - 1);
        }
      });

      setFetchedEvaluatedDataTime(evaluatedDataTime);
      setFetchedTimeseries(allTimeSeries);
    }

    if (!isFetching && isAxiosError && errMessage) {
      logger.debug("Operationalize Widget", `${widget.title} - Cancelling fetch api - ${JSON.stringify(errMessage)}`);
      setFetchedTimeseries([]);
      setIncidentRegions([]);
      setSuppressionRegions([]);
      setSuppressionReasonInfo({});
      setSeasonSecs(null);
      onError(errMessage.toString());
    }
  }, [
    data,
    error,
    id,
    isError,
    isFetching,
    isOpportunity,
    isPreviewMode,
    isSuccess,
    mode,
    monitoredFrequencies,
    onError,
    showSuppressionRegion,
    title,
    to,
    widget.title
  ]);

  const showSeasonality = seasonality && seasonality !== OpThresholdSeasonality.NONE;
  const showSeasonSecs = seasonSecs && !seasonality;
  const showLookBack = Boolean(lookBack) || Boolean(frequency);

  const lookBackJsx = useMemo(() => {
    if (!showLookBack) {
      return <></>;
    }
    return (
      <div className="inc-flex-column flex-gap-8 padding8">
        {frequency && (
          <div className="inc-text-subtext-medium">
            Frequency: <span className="inc-text-element">{getLabelForTimeObj(frequency)}</span>
          </div>
        )}
        {lookBack && (
          <div className="inc-text-subtext-medium">
            Lookback: <span className="inc-text-element">{getLabelForTimeObj(lookBack)}</span>
          </div>
        )}
      </div>
    );
  }, [frequency, lookBack, showLookBack]);

  useEffect(() => {
    const lookBackComp = showLookBack && (
      <IncToolTip
        placement="bottom"
        showArrow
        titleElement={lookBackJsx}
      >
        <ISaxIcon
          className="inc-cursor-pointer marginLt10"
          iconName="InfoCircle"
        />
      </IncToolTip>
    );
    if (showSeasonSecs) {
      const seasonSecsStr = getSeasonSecsStr(seasonSecs);
      setCustomActions([
        {
          actionComponent: (
            <VerticallyCenteredRow>
              <div className="inc-text-subtext-medium marginLt10">
                Seasonality: <span className="inc-text-element">{seasonSecsStr}</span>
              </div>
              {lookBackComp}
            </VerticallyCenteredRow>
          ),
          showInHeader: true
        }
      ]);
    } else if (showSeasonality) {
      setCustomActions([
        {
          actionComponent: (
            <VerticallyCenteredRow>
              <div className="inc-text-subtext-medium marginLt10">
                Seasonality: <span className="inc-text-element">{capitalize(seasonality)}</span>
              </div>
              {lookBackComp}
            </VerticallyCenteredRow>
          ),
          showInHeader: true
        }
      ]);
    } else if (showLookBack) {
      setCustomActions([
        {
          actionComponent: lookBackComp,
          showInHeader: true
        }
      ]);
    }
  }, [lookBack, lookBackJsx, seasonSecs, seasonality, setCustomActions, showLookBack, showSeasonSecs, showSeasonality]);

  const seriesToRender = useMemo(() => {
    const timeseriesHelper = new TimeSeriesHelper(fetchedTimeseries || [], widget);
    const { seriesOptions } = timeseriesHelper;
    return seriesOptions;
  }, [fetchedTimeseries, widget]);

  const regionHighlights = useMemo(
    () => [...(highlights || []), ...suppressionRegions, ...incidentRegions],
    [highlights, incidentRegions, suppressionRegions]
  );

  const { bars, legends } = useMonitoringFrequencyRenderer(monitoredFrequencies, queryTimeRange);

  const widgetOptions = useMemo(() => {
    const annotations: AnnotationsOptions[] = [];

    const allSeries: number[][] = [];
    fetchedTimeseries.forEach(series => {
      series.datapoints.forEach(x => allSeries.push(x));
    });

    allEvents.forEach(event => {
      const { startMillis, title } = event;
      const startMicros = startMillis * 1000;
      const yVal = allSeries.find(x => x?.[1] === startMicros)[0];

      annotations.push({
        draggable: "",
        labels: [
          {
            point: {
              xAxis: 0,
              yAxis: 0,
              x: startMillis,
              y: yVal
            },
            backgroundColor: "transparent",
            borderColor: "none",
            useHTML: true,
            formatter: () => {
              const formattedStartTime = getFormattedDateTime(startMicros, IncDateTimeFormat.minimal);
              return `<div class="series-annotation inc-flex-column">
              <span class="inc-text-subtext">${title}</span>
              <span class="marginTp2">${formattedStartTime}</span>
              <span class="marginTp2" style="color: #39ACFF; cursor: pointer;">View more</span>         
            </div>`;
            }
          }
        ]
      });
    });

    return {
      ...widget.options,
      annotations
    };
  }, [allEvents, fetchedTimeseries, widget.options]);

  const sparklineSeries = useMemo(
    () =>
      seriesToRender.map(series => ({
        ...series,
        yAxis: 0
      })),
    [seriesToRender]
  );

  const boxRef = useRef<HTMLDivElement>();

  const forceUpdate = useForceUpdate();

  const renderSeriesCount = seriesToRender.length;
  const hideTimeseries = !renderSeriesCount || isFetching;

  useEffect(() => {
    if (!hideTimeseries) {
      setTimeout(() => forceUpdate(), 10);
    }
  }, [forceUpdate, hideTimeseries]);

  const getContainerDimensions = useCallback(() => {
    const canvasElem: HTMLElement = boxRef.current;
    if (canvasElem) {
      const subElement = canvasElem.querySelector(".highcharts-plot-background");
      const dimensions = subElement?.getBoundingClientRect();
      if (dimensions) {
        const { height } = dimensions;
        const { width } = dimensions;
        return {
          width,
          height
        };
      }
    }
    return {
      width: -1,
      height: -1
    };
  }, [boxRef]);
  const { height } = getContainerDimensions();

  const fontSize = getFontSizeBasedOnHeight(height);

  const horizontalPadding = fontSize * 0.5;
  const verticalPadding = fontSize * 0.1;
  const historyBtnStyle: React.CSSProperties = useMemo(
    () => ({
      position: "absolute",
      top: `0px`,
      left: "40px",
      padding: `${verticalPadding}px ${horizontalPadding}px`,
      fontSize: `${fontSize}px`
    }),
    [fontSize, horizontalPadding, verticalPadding]
  );

  if (variablesLoading) {
    return <>{variablesLoadingElement}</>;
  }

  const seriesTimeRange = shouldOverrideTimeRange ? customTimeRange || queryTimeRange : queryTimeRange;

  return (
    <div
      className="height-100 width-100"
      ref={boxRef}
    >
      {!variablesLoading && !isFetching && Boolean(subTitleHtmlStr) && (
        <div
          className="width-100"
          dangerouslySetInnerHTML={{
            __html: subTitleHtmlStr
          }}
        />
      )}

      {!variablesLoading && isFetching && loadingElement}
      {!variablesLoading && !isFetching && !renderSeriesCount && noDataElement}

      {!hideTimeseries && (
        <div
          className="timeseries-widget-graph visible-on-hover height-100"
          style={timeSeriesContainerStyle}
        >
          {!isSparkline && (
            <TimeSeriesComponent
              containerElemClass="timeseries-widget-graph"
              getExtTooltipInfo={getExtTooltipInfo}
              id={`chart-${id}`}
              manualZoomProps={manualZoomProps}
              onResetSelectedTimeRange={onResetSelection}
              onSelectTimeRange={onSelectTimeRange}
              options={widgetOptions}
              properties={properties}
              regionHighlights={regionHighlights}
              series={seriesToRender}
              timeRange={seriesTimeRange}
              title={title}
              type={chartType}
            />
          )}
          {isSparkline && (
            <SparkLine
              containerElemClass={"timeseries-widget-graph"}
              disableInteractions={disableSparklineInteraction}
              properties={properties}
              series={sparklineSeries}
              timeRange={seriesTimeRange}
            />
          )}
          {!hideHistory && (
            <IncButton
              className="display-element marginLtAuto"
              color="secondary"
              onClick={toggleHistoricalData}
              size="small"
              style={historyBtnStyle}
            >
              {shouldShowHistoricalData ? "Hide History" : "Show History"}
            </IncButton>
          )}
        </div>
      )}

      {Boolean(bars.length) && <VerticallyCenteredRow>{bars}</VerticallyCenteredRow>}

      {Boolean(legends.length) && (
        <VerticallyCenteredRow className="marginTp12">
          <VerticallyCenteredRow className="marginRt12 inc-text-subtext-medium">
            Monitoring Frequency
          </VerticallyCenteredRow>
          {legends}
        </VerticallyCenteredRow>
      )}
    </div>
  );
};

const timeSeriesContainerStyle: React.CSSProperties = {
  position: "relative",
  minHeight: "24px"
};

export default Op10zeQueryWidget;
