import { getInceptionTheme } from "@inception/ui/src/themes/ThemeProvider";
import IncWorkflowConnector from "./Connector";
import { IncEntityNode } from "./Entity";
import { IncActivityNode } from "./Activity";

export const WORKFLOW_CONSTANTS = {
  ENTITY: "entity",
  ACTIVITY: "activity"
};

export const NodeTypes: any = {
  entity: IncEntityNode,
  activity: IncActivityNode
};

export const EdgeTypes = {
  connector: IncWorkflowConnector
};
export const ConnectionLineStyle = {
  stroke: getInceptionTheme().inceptionColors.accentBlue,
  "stroke-width": 10
};

export const NodeColors = ["#be57ca", "#6e76e3", "#847547", "#5a175d", "#dd9933"];

export const DefaultEdgeOptions = {
  type: "connector" //connector
};

export const Layout = {
  originX: 0,
  originY: 0,
  group: {
    marginTop: 150,
    spacing: 80
  },
  entity: {
    spacing: 300,
    width: 150,
    height: 60
  },
  activity: {
    spacing: 80,
    width: 200,
    height: 60,
    style: {
      opacity: 10
    }
  }
};
