import { IncFaIcon } from "@inception/ui";
import { css } from "emotion";
import React, { CSSProperties, FC, useCallback, useMemo, useState } from "react";
import { isEqual, pick } from "lodash";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../components";
import { getCompareSeriesOverrides } from "../../../components/incidentTimeSeries/TimeSeriesWidgetHelper";
import { StandAloneWidget } from "../../../components/StandaloneWidget";
import { TimeRangeSelector } from "../../../components/time-range";
import { generateId, shouldExcludeTag, useRefState } from "../../../core";
import Op10zeWidgetImpl from "../../../dashboard/widgets/Operationalize/models/impl";
import Op10zeWidgetModel from "../../../dashboard/widgets/Operationalize/models/model";
import {
  BizEntityType,
  DemoDataParams,
  OverallMappingStatus,
  SelectorSpec,
  SelectorTag
} from "../../../services/api/explore";
import { MonitoredDataSchema, OpCreationConfig, ThresholdType } from "../../../services/api/operationalise";
import { Op10zeDataQuery } from "../../../services/datasources/operationalize/types";
import { featureFlagService } from "../../../services/feature-flags";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { ReactComponent as BicycleSvg } from "../../../../images/logo-bicycle.svg";
import appConfig from "../../../../appConfig";
import { PreviewSchemaRenderer } from "./PreviewSchemaRenderer";
import { SeasonalityCalendar } from "./SeasonalityCalendar";
import { getResultSchemaFromMonitoredDataSchema } from "./utils";

interface Props {
  opCreationConfig: OpCreationConfig;
  entityTypeInfo: BizEntityType;

  seriesSelection: Record<string, string>;
  onSeriesSelectionChange: (selection: Record<string, string>) => void;

  demoDataParams?: DemoDataParams;
}

export const PreviewRenderer: FC<Props> = props => {
  const {
    entityTypeInfo,
    opCreationConfig,
    seriesSelection,
    onSeriesSelectionChange,
    demoDataParams: defDemoDataParams
  } = props;

  const isMappingIncomplete =
    opCreationConfig?.opMappingStatus?.queryMappingStatus?.isIncomplete ||
    opCreationConfig?.opMappingStatus?.queryMappingStatus?.overallStatus === OverallMappingStatus.PENDING;
  const demoDataParams = useMemo<DemoDataParams>(
    () => ({
      ...(defDemoDataParams || {}),
      generateDemoData: isMappingIncomplete
    }),
    [defDemoDataParams, isMappingIncomplete]
  );

  const setSeriesSelectionRef = useRefState(onSeriesSelectionChange);

  const { name: op10zeName } = opCreationConfig;

  const isManualThreshold = opCreationConfig?.opCreationConfigDef?.threshold?.thresholdType !== ThresholdType.AUTO;

  const isDemoMode = featureFlagService.isDemoMode();
  const displaySeasonalityCalendar = isDemoMode;

  const [seasonalityEdit, setSeasonalityEdit] = useState(false);

  const graphClassName = displaySeasonalityCalendar
    ? seasonalityEdit
      ? css`
          width: calc(100% - 640px);
          height: 100%;
        `
      : css`
          width: calc(100% - 340px);
          height: 100%;
        `
    : "width-100 height-100";

  const { name: entityTypeName } = entityTypeInfo || {};

  const { defTimeRange, compareTimeRange } = useMemo(() => {
    const defTimeRange = timeRangeUtils.getTimeRangeFromRaw({
      from: "now-1d",
      to: "now"
    });
    const compareTimeRange = timeRangeUtils.getCompareTimeRangeFromRaw(defTimeRange, "1h");

    return {
      defTimeRange,
      compareTimeRange
    };
  }, []);

  const [timeRange, setTimeRange] = useState(defTimeRange);
  const [previewSchemaLoading, setPreviewSchemaLoading] = useState(true);
  const [previewSchemaError, setPreviewSchemaError] = useState("");
  const [numSeries, setNumSeries] = useState(0);

  const onPreviewSchemaChange = useCallback(
    (previewSchema: MonitoredDataSchema) => {
      const resultSchema = getResultSchemaFromMonitoredDataSchema(previewSchema);
      if (resultSchema.length) {
        const tagsArr = Object.keys(resultSchema[0] || {}).filter(tag => !shouldExcludeTag(tag, true));
        const seriesExists = resultSchema.find(s => isEqual(pick(s, tagsArr), seriesSelection));

        if (!seriesExists) {
          setSeriesSelectionRef.current(pick(resultSchema[0], tagsArr));
        }
      }

      setNumSeries(resultSchema.length);
    },
    [seriesSelection, setSeriesSelectionRef]
  );

  const widgetImpl = useMemo<Op10zeWidgetImpl>(() => {
    const tags: SelectorTag[] = [];

    const selectorSpec: SelectorSpec = {
      filters: [
        {
          tags
        }
      ]
    };

    Object.keys(seriesSelection || {}).forEach(key => {
      const value = seriesSelection[key];
      const tag: SelectorTag = {
        key,
        value: [value]
      };

      tags.push(tag);
    });

    const model: Op10zeWidgetModel = {
      datasource: appConfig.defaultOp10zeDsName,
      id: generateId(),
      queries: [
        {
          previewPayload: {
            opSetupReq: {
              opCreationConfig
            },
            selectorSpec: selectorSpec || {
              filters: []
            }
          },
          refId: "A",
          ...(isMappingIncomplete ? demoDataParams : {}),
          insightMode: isMappingIncomplete
        } as Op10zeDataQuery
      ],
      title: op10zeName,
      type: "operationalize",
      mode: "preview",
      seriesOverrides: getCompareSeriesOverrides(),
      chartType: "spline",
      meta: {
        resizable: false,
        hideHeader: true,
        hideActions: true,
        compare: false,
        edit: false
      },
      properties: {
        borderLess: true
      },
      options: {
        yAxis: [
          {
            visible: false,
            grid: {
              enabled: false
            }
          },
          {
            visible: false,
            grid: {
              enabled: false
            }
          }
        ]
      }
    };

    return new Op10zeWidgetImpl(model);
  }, [demoDataParams, isMappingIncomplete, op10zeName, opCreationConfig, seriesSelection]);

  const isPreviewSchemaError = Boolean(previewSchemaError);

  const thresholdTypeStr = isManualThreshold ? "manual" : "auto";

  return (
    <div className="op-preview-v3">
      <div className="op-preview-v3__header width-100">
        <VerticallyCenteredRow className="marginBt16">
          <BicycleSvg style={iconStyle} />
          <VerticallyCenteredRow className="marginLt12 inc-text-body">
            Bicycle will monitor{" "}
            {previewSchemaLoading ? (
              <LoadingSpinner
                className="marginLt4 marginRt4"
                titleText=" "
              />
            ) : (
              numSeries
            )}{" "}
            series with {thresholdTypeStr} threshold
          </VerticallyCenteredRow>
          <VerticallyCenteredRow className="marginLtAuto inc-text-body">
            <TimeRangeSelector
              buttonDisplay
              onTimeSelect={setTimeRange}
              timeRange={timeRange}
            />
          </VerticallyCenteredRow>
        </VerticallyCenteredRow>
        <VerticallyCenteredRow className="width-100">
          <PreviewSchemaRenderer
            demoDataParams={demoDataParams}
            entityTypeName={entityTypeName}
            onChange={onSeriesSelectionChange}
            onErrorChange={setPreviewSchemaError}
            onLoadingChange={setPreviewSchemaLoading}
            onPreviewSchemaChange={onPreviewSchemaChange}
            opCreationConfig={opCreationConfig}
            selection={seriesSelection}
            timeRange={timeRange}
          />
        </VerticallyCenteredRow>
      </div>

      <div className="op-preview-v3__viz">
        {previewSchemaLoading && (
          <LoadingSpinner
            className="message marginTp12"
            titleText="Fetching schema..."
          />
        )}
        {!previewSchemaLoading && (
          <>
            {isPreviewSchemaError && (
              <VerticallyCenteredRow className="message status-danger marginTp12">
                <IncFaIcon
                  className="marginRt10"
                  iconName="exclamation-triangle"
                />
                <span>Error fetching schema</span>
              </VerticallyCenteredRow>
            )}
            {!isPreviewSchemaError && (
              <>
                {seriesSelection === undefined && (
                  <VerticallyCenteredRow className="message status-info marginTp12">
                    <IncFaIcon
                      className="marginRt10"
                      iconName="info-circle"
                    />
                    <span>No series found</span>
                  </VerticallyCenteredRow>
                )}
                {Boolean(seriesSelection) && (
                  <>
                    <VerticallyCenteredRow className="width-100">
                      <div className={graphClassName}>
                        <StandAloneWidget
                          compareTimeRange={compareTimeRange}
                          timeRange={timeRange}
                          widget={widgetImpl}
                        />
                      </div>
                      {displaySeasonalityCalendar && (
                        <SeasonalityCalendar
                          onEditToggle={setSeasonalityEdit}
                          timeRange={timeRange}
                        />
                      )}
                    </VerticallyCenteredRow>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const iconStyle: CSSProperties = {
  height: 12,
  width: 24
};
