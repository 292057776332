import React, { useEffect, useMemo, useRef, useState } from "react";
import { getFormattedDateTime } from "@inception/ui";
import { isEqual } from "lodash";
import { WidgetExtProps } from "../types";
import { useVariables } from "../useVariables";
import { dateTime, logger, TimeRange } from "../../../core";
import ChartOptionsBuilder from "../../../components/time-series/ChartOptionsBuilder";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import {
  BizFieldPredicate,
  ExploreEntityFilter,
  SliceSpec,
  UserServiceFilterList
} from "../../../services/api/explore";
import { convertUSFieldSliceSetToTagSlice, getDefaultWidgetResponseDto } from "../../../utils/ExploreUtils";
import { USFieldWidgetUtils } from "../USField/USFieldWidgetUtils";
import { pluralizeWord } from "../../../utils";
import { FunnelRenderer } from "./renderer";
import FunnelWidgetImpl from "./models/impl";
import { useFetchFunnelQuerySchema } from "./hooks";
import { getFunnelNodeIds, getWidgetResponseDTOForFunnel } from "./FunnelWidgetUtils";
import { useCustomActions } from "./actions";

interface FunnelWidgetProps extends WidgetExtProps {
  widget: FunnelWidgetImpl;
}

type FiltersState = {
  entityFilters: ExploreEntityFilter[];
  eventFilters: UserServiceFilterList;
};

const FunnelWidget: React.FC<FunnelWidgetProps> = props => {
  const {
    widget,
    noDataElement,
    timeRange,
    compareTimeRange,
    loadingElement,
    variablesLoadingElement,
    variableSrvMap,
    variableLoadingStateMap,
    setCustomActions: onActionsChange,
    eventTypeToFieldsMap
  } = props;

  const prevFiltersRef = useRef<FiltersState>({
    entityFilters: [],
    eventFilters: {
      userServiceFilters: []
    }
  });

  const { funnelId, properties, id: widgetId } = widget;

  const { fromMillis: startTimeMillis, toMillis: endTimeMillis } = useMemo(
    () => timeRangeUtils.getMillisFromTimeRange(timeRange),
    [timeRange]
  );

  const compareTimeInSeconds = useMemo(() => {
    const compareFromMillis = compareTimeRange.from.valueOf();
    const fromMillis = timeRange.from.valueOf();

    return timeRangeUtils.getSecondsFromMillis(fromMillis - compareFromMillis);
  }, [compareTimeRange.from, timeRange.from]);

  const {
    data: querySchema,
    error: querySchemaFetchError,
    isError: isQuerySchemaFetchError,
    isFetching: isQuerySchemaFetching
  } = useFetchFunnelQuerySchema(funnelId);

  const funnelConfig = querySchema?.funnelConfig;
  useMemo(() => {
    widget.setFunnelConfig(funnelConfig);
  }, [funnelConfig, widget]);

  const widgetResponseDto = useMemo(() => {
    if (isQuerySchemaFetching) {
      const bizEntityType = widget.getBizEntityType();
      return getDefaultWidgetResponseDto(bizEntityType, null);
    }

    return getWidgetResponseDTOForFunnel(widget);
  }, [isQuerySchemaFetching, widget]);

  const funnelNodeIds = useMemo(() => getFunnelNodeIds(funnelConfig), [funnelConfig]);

  const { selectedFilters: pSelectedFilters, disableCompare } = properties;

  const [sliceTags, setSliceTags] = useState<string[]>();
  const [selectedFilters, setSelectedFilters] = useState(pSelectedFilters || []);

  useMemo(() => {
    widget.setSelectedFilters(selectedFilters);
  }, [selectedFilters, widget]);

  useMemo(() => {
    widget.setSliceTags(sliceTags);
  }, [sliceTags, widget]);

  const displayLabel = useMemo(() => {
    const { fields, label } = funnelConfig?.fieldDef || {};
    if (label) {
      return `Unique ${label}`;
    } else if (fields?.length) {
      const usField = fields[0];
      const displayFieldName = USFieldWidgetUtils.getDisplayFieldName(usField);
      return `Unique ${pluralizeWord(displayFieldName)}`;
    }

    return `Unique Events`;
  }, [funnelConfig]);

  useEffect(() => {
    if (!isQuerySchemaFetching && !sliceTags) {
      if (funnelConfig) {
        const sliceTagsSet = new Set<string>();
        (funnelConfig.sliceSet || []).map(ss => ss.slices.forEach(s => sliceTagsSet.add(s.tagName)));
        const sliceTags = Array.from(sliceTagsSet);
        setSliceTags(sliceTags);
      } else {
        setSliceTags([]);
      }
    }
  }, [funnelConfig, isQuerySchemaFetching, sliceTags]);

  useEffect(() => {
    if (isQuerySchemaFetchError) {
      logger.error("FunnelWidget", "Error fetching funnel query schema", querySchemaFetchError);
    }
  }, [isQuerySchemaFetchError, querySchemaFetchError]);

  const {
    entityFilters: vEntityFilters,
    cohortFilters: vCohortFilters,
    eventFilters: vEventFilters,
    variablesLoading
  } = useVariables(variableSrvMap, widget, variableLoadingStateMap, widgetResponseDto, eventTypeToFieldsMap, true);

  const { entityFilters, eventFilters } = useMemo(() => {
    if (!variablesLoading) {
      const entityFilters: ExploreEntityFilter[] = [];
      const eventFilters: UserServiceFilterList = vEventFilters[widgetId] || {
        userServiceFilters: []
      };

      if (vEntityFilters?.length || vCohortFilters?.length) {
        const predicates: BizFieldPredicate[] = [];
        vEntityFilters?.forEach(ef => ef.fieldType === "bizEntityField" && predicates.push(ef.predicate));
        vCohortFilters?.forEach(ef => ef.fieldType === "bizEntityField" && predicates.push(ef.predicate));
        entityFilters.push({
          filters: predicates
        });
      }

      const nextFilters: FiltersState = {
        entityFilters,
        eventFilters
      };

      prevFiltersRef.current = !isEqual(prevFiltersRef.current, nextFilters) ? nextFilters : prevFiltersRef.current;
    }

    return prevFiltersRef.current;
  }, [vCohortFilters, vEntityFilters, vEventFilters, variablesLoading, widgetId]);

  const { currentStr, compareStr, displayCompareStr } = useMemo(() => {
    const toMillis = timeRange.from.valueOf();
    const fromMillis = toMillis - compareTimeInSeconds * 1000;

    const tr: TimeRange = {
      from: dateTime(fromMillis),
      to: dateTime(toMillis),
      raw: {
        from: fromMillis.toString(),
        to: toMillis.toString()
      }
    };

    const { includeSeconds, labelFormat } = new ChartOptionsBuilder().getXAxisLabelsAndFormat(tr);

    const origTimeStr = getFormattedDateTime(toMillis, labelFormat, {
      withSeconds: includeSeconds
    });
    const compareTimeStr = getFormattedDateTime(fromMillis, labelFormat, {
      withSeconds: includeSeconds
    });

    return {
      currentStr: origTimeStr,
      compareStr: compareTimeStr,
      compareTimeStr: [origTimeStr, compareTimeStr].join(" vs "),
      displayCompareStr: timeRangeUtils.getCompareTimeStr(toMillis, compareTimeRange.from.valueOf(), "Previous")
    };
  }, [compareTimeInSeconds, compareTimeRange.from, timeRange.from]);

  const sliceSpec = useMemo(() => {
    const sliceSpec: SliceSpec[] = [];

    const sliceSets = funnelConfig?.sliceSet || [];
    funnelNodeIds.forEach(funnelNodeId => {
      sliceSets.forEach(usfSliceSet => {
        const sliceSet = convertUSFieldSliceSetToTagSlice(usfSliceSet);
        sliceSpec.push({
          selectorSpec: {
            filters: selectedFilters
          },
          sliceSet,
          journeyNodeId: funnelNodeId,
          postAgg: {
            overTagAgg: {
              aggregator: "distinctCount",
              tagName: []
            },
            isSingleStatQuery: true,
            projections: ["current"]
          }
        });
      });
    });

    return sliceSpec;
  }, [funnelConfig, funnelNodeIds, selectedFilters]);

  const customActionProps = useMemo(
    () => ({
      widget,
      isFunnelConfigFetching: isQuerySchemaFetching,
      funnelId,
      selectedFilters,
      setSelectedFilters,
      setSliceTags,
      sliceSets: funnelConfig?.sliceSet,
      sliceTags,
      timeRange,
      bizEntityType: funnelConfig?.bizEntityType
    }),
    [
      funnelConfig?.bizEntityType,
      funnelConfig?.sliceSet,
      funnelId,
      isQuerySchemaFetching,
      selectedFilters,
      sliceTags,
      timeRange,
      widget
    ]
  );

  const { customActions } = useCustomActions(customActionProps);

  useEffect(() => {
    onActionsChange(customActions);
    return () => onActionsChange([]);
  }, [customActions, onActionsChange]);

  if (isQuerySchemaFetching) {
    return <>{loadingElement}</>;
  }

  if (variablesLoading) {
    return <>{variablesLoadingElement}</>;
  }

  if (!funnelConfig) {
    return <div className="status-danger">Funnel configuration not found</div>;
  }

  return (
    <FunnelRenderer
      compareStr={compareStr}
      compareTimeInSeconds={compareTimeInSeconds}
      currentStr={currentStr}
      disableCompare={disableCompare}
      displayCompareStr={displayCompareStr}
      displayLabel={displayLabel}
      endTimeMillis={endTimeMillis}
      entityFilters={entityFilters}
      eventFilters={eventFilters}
      funnelId={funnelId}
      noDataElement={noDataElement}
      sliceSpec={sliceSpec}
      startTimeMillis={startTimeMillis}
      variablesLoading={variablesLoading || isQuerySchemaFetching}
    />
  );
};

export default FunnelWidget;
