import { IncMenuItemProps, IncMenuToggleProps, IncFaIcon, IncRadioButton, IncMenu } from "@inception/ui";
import React, { FC, useEffect, useMemo, useCallback, memo } from "react";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../components";
import { useAccessPrivilege } from "../../../core";
import { OpSimulationStatus } from "../../../services/api/operationalise";
import { TEMPORARY_SIMULATION_KEY, PRIMARY_CONFIG_AS_SIMULATION_KEY } from "../../constants";
import { useOpStore, setSimulationState } from "../../context";
import { SimulationConfig } from "../../context/types";
import { useFetchSimulationState, getSimulationInfo } from "./useFetchSimulationState";

type CProps = {
  id: string;
  onClick: () => void;
  onDelete: () => void;
  onClone: () => void;
  isSelected: boolean;
  op10zeId: string;
  simulationConfig: SimulationConfig;
  disableOpEdit?: boolean;
};

export const OpSimulationCard: FC<CProps> = memo(props => {
  const { dispatch } = useOpStore();

  const { isSelected, onClick, onDelete, onClone, op10zeId, disableOpEdit = false, simulationConfig } = props;

  const { canCreate, canDelete } = useAccessPrivilege();

  const { simulationId, name } = simulationConfig;

  const { data, isError, isFetching } = useFetchSimulationState(op10zeId, simulationId);

  useEffect(() => {
    if (!isError && !isFetching) {
      dispatch(
        setSimulationState({
          simulationId,
          simulationState: data
        })
      );
    }
  }, [data, dispatch, isError, isFetching, simulationId]);

  const { numPercentCompleted, timeRemainingStr } = useMemo(() => getSimulationInfo(data), [data]);

  const isTemporarySimulation = simulationId === TEMPORARY_SIMULATION_KEY;
  const isPrimarySimulation = simulationId === PRIMARY_CONFIG_AS_SIMULATION_KEY;

  const shouldFetchSimulationState = !isTemporarySimulation && !isPrimarySimulation;

  const style = isSelected
    ? {
        border: "1px solid #39ACFF",
        background: "linear-gradient(0deg, rgba(57, 172, 255, 0.05), rgba(57, 172, 255, 0.05)), #2F3842",
        borderRadius: 8,
        padding: "20px 16px"
      }
    : {
        borderRadius: 8,
        background: "#2F3842",
        padding: "20px 16px"
      };

  const menuItems = useCallback(
    (itemProps: IncMenuItemProps) => {
      const { className, closeMenu } = itemProps;
      const headerClassName = `inc-text-subtext-medium ${className}`;

      const lOnClone = (e: any) => {
        e.stopPropagation();
        closeMenu(e);
        onClone();
      };

      const lOnDelete = (e: any) => {
        e.stopPropagation();
        closeMenu(e);
        onDelete();
      };

      return (
        <>
          {canCreate && (
            <div
              className={headerClassName}
              onClick={lOnClone}
            >
              Duplicate
            </div>
          )}
          {canDelete && (
            <div
              className={headerClassName}
              onClick={lOnDelete}
            >
              Delete
            </div>
          )}
        </>
      );
    },
    [canCreate, canDelete, onClone, onDelete]
  );

  const toggle = useCallback((toggleProps: IncMenuToggleProps) => {
    const { show, toggleMenu } = toggleProps;

    const onClick = (e: any) => {
      e.stopPropagation();
      toggleMenu(!show);
    };

    return (
      <div
        className="action-icon-button display-element"
        onClick={onClick}
      >
        <IncFaIcon
          className="inc-cursor-pointer"
          iconName="ellipsis-h"
        />
      </div>
    );
  }, []);

  const isInProgress = status === OpSimulationStatus.IN_PROGERSS;

  const shouldShowMenu = !isTemporarySimulation && (canCreate || canDelete) && isSelected && !disableOpEdit;

  return (
    <VerticallyCenteredRow className="marginBt12 simulations-list--card visible-on-hover">
      <IncRadioButton
        checked={isSelected}
        onClick={onClick}
      />

      <div
        className="width-100 marginLt12 inc-cursor-pointer"
        onClick={onClick}
        style={style}
      >
        <VerticallyCenteredRow className="width-100">
          <VerticallyCenteredRow>{name}</VerticallyCenteredRow>

          {shouldShowMenu && (
            <IncMenu
              items={menuItems}
              toggle={toggle}
            />
          )}
        </VerticallyCenteredRow>

        {shouldFetchSimulationState && (
          <>
            {isFetching && (
              <LoadingSpinner
                className="marginTp8"
                titleText="Fetching state..."
              />
            )}
            {!isFetching && (
              <>
                <div className="inc-text-element inc-text-inactive marginBt4 marginTp8">
                  {isError && (
                    <VerticallyCenteredRow className="status-danger">
                      <IncFaIcon
                        className="marginRt6"
                        iconName="exclamation-triangle"
                      />
                      Error fetching state
                    </VerticallyCenteredRow>
                  )}
                  {!isError && isInProgress && (
                    <>
                      {!isNaN(numPercentCompleted) && `${numPercentCompleted}% completed`}
                      {isNaN(numPercentCompleted) && "-"}
                    </>
                  )}
                </div>

                {!isError && isInProgress && (
                  <>
                    <progress
                      className="simulation-progress"
                      max="100"
                      value={numPercentCompleted}
                    />

                    <VerticallyCenteredRow className="marginTp16 inc-text-subtext-medium">
                      <div className="inc-text-inactive">Estimated Finish Time</div>
                      <div className="marginLt4">{timeRemainingStr}</div>
                    </VerticallyCenteredRow>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </VerticallyCenteredRow>
  );
});
