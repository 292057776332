import React, { FC } from "react";
import { IncSelect } from "@inception/ui";
import { AggregationUIOptions } from "../../../../core";

interface Props {
  aggregations: AggregationUIOptions[];
  selectedAggregation: AggregationUIOptions;
  onAggChange: (nAgg: AggregationUIOptions) => void;
}

export const AggregationSelector: FC<Props> = props => {
  const { aggregations, selectedAggregation, onAggChange } = props;

  const numAggregations = aggregations.length;

  if (numAggregations === 1) {
    return <></>;
  }

  return (
    <IncSelect
      autoAdjustWidth
      autoSort={false}
      isSearchable={false}
      onChange={onAggChange}
      options={aggregations}
      value={selectedAggregation}
      wrapperClass="aggregator-selector"
    />
  );
};
