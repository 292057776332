import { escape } from "lodash";
import { dataFrameToTimeseries } from "../../../core";
import { ENTITY_TAG } from "../../../utils";
import TimeSeriesWidgetImpl from "../../../dashboard/widgets/TimeSeries/models/impl";
import { TimeSeriesHelper } from "../../../dashboard/widgets/TimeSeries/models/TimeSeriesUtils";
import {
  UserServiceFieldSliceSet,
  getDisplayTagNameForUSFieldSlice,
  UserServiceFieldSlice
} from "../../../services/api/explore";
import { BizEntityDataResult } from "../../../dashboard/widgets/utils";

export const getAccessorHeaderMap = (
  alertingEventFields: UserServiceFieldSliceSet,
  diagnosticEventFields: UserServiceFieldSliceSet
) => {
  const accessorHeaderMap: Record<string, string> = {};
  const entityTags: string[] = [];

  const addToHeaderMapAndTags = (slice: UserServiceFieldSlice) => {
    const { tagName } = slice;

    const { displayTagName, isEntityTag } = getDisplayTagNameForUSFieldSlice(slice);

    isEntityTag && entityTags.push(tagName);
    accessorHeaderMap[tagName] = displayTagName;
  };

  alertingEventFields.slices.forEach(addToHeaderMapAndTags);
  diagnosticEventFields.slices.forEach(addToHeaderMapAndTags);

  return {
    accessorHeaderMap,
    entityTags
  };
};

export const getTagsDataAndTimeseries = (
  data: BizEntityDataResult,
  accessorHeaderMap: Record<string, string>,
  groupByTags: string[]
) => {
  const displayTags = groupByTags.map(tagName => accessorHeaderMap[tagName] || tagName);
  const { tsSeries, postAggEntityLookupData } = constructTimeseries(data, groupByTags, displayTags);

  const tagsData: Array<Record<string, string>> = [];
  tsSeries.forEach(serie => {
    const { color, custom } = serie;
    const tags = custom?.tagsData?.tags;
    if (tags) {
      tagsData.push({
        ...tags,
        color: String(color)
      });
    }
  });

  return {
    tsSeries,
    tagsData,
    lookupData: postAggEntityLookupData
  };
};

const constructTimeseries = (data: BizEntityDataResult, aggregatedTags: string[], displayAggregatedTags: string[]) => {
  const { postAggEntityLookupData = {}, postAggData = {} } = data || {};
  const { data: dataframes = [] } = Object.values(postAggData)[0] || {};

  const timeseries = dataframes.map(df => {
    const { name, labels = {}, eLabels = labels } = df;

    Object.entries(labels).forEach(([key, value]) => {
      df.eLabels = df.eLabels || {};
      df.eLabels[key] = postAggEntityLookupData[value] || value;
    });

    let visibleTags = [...aggregatedTags];
    let visibleDisplayTags = [...displayAggregatedTags];

    const entityTagIdx = visibleTags.findIndex(tag => tag === ENTITY_TAG);

    if (entityTagIdx !== -1) {
      visibleTags.splice(entityTagIdx, 1);
      visibleTags = [ENTITY_TAG, ...visibleTags];

      const entityDisplayTag = visibleDisplayTags[entityTagIdx];
      visibleDisplayTags.splice(entityTagIdx, 1);
      visibleDisplayTags = [entityDisplayTag, ...visibleDisplayTags];
    }

    if (visibleTags.length > 1) {
      const dfNameArr = visibleTags.map((tag, idx) => {
        const key = visibleDisplayTags[idx] || tag;
        const value = eLabels[tag] || labels[tag];
        return `${key}: ${value}`;
      });
      df.name = dfNameArr.join(", ");
    } else {
      const tag = visibleTags[0];
      const value = eLabels[tag] || labels[tag];
      df.name = value || name;
    }

    df.name = escape(df.name);
    return dataFrameToTimeseries(df);
  });

  const tsWidget = new TimeSeriesWidgetImpl({
    chartType: "areaspline"
  });

  const tsSeries = new TimeSeriesHelper(timeseries, tsWidget).seriesOptions;

  return {
    tsSeries,
    postAggEntityLookupData
  };
};
