import React, { FC, useMemo, useCallback } from "react";
import { Visualisations, VizToQueryConfig } from "../../../../../core";
import { VizSwitcher } from "../../components";

interface Props {
  visualisation: Visualisations;
  vizOptions: VizOption[];
  onVisualisationChange: (visualisation: Visualisations) => void;

  label?: string;
}

export const CatalogVizSwitcher: FC<Props> = props => {
  const { visualisation: viz, vizOptions, onVisualisationChange, label } = props;

  const selVizOpt = useMemo(
    () => vizOptions.find(({ visualisation }) => viz === visualisation) || vizOptions[0],
    [viz, vizOptions]
  );

  const onVizChange = useCallback(
    (opt: VizOption) => {
      onVisualisationChange(opt.visualisation);
    },
    [onVisualisationChange]
  );

  return (
    <VizSwitcher
      label={label}
      onVizChange={onVizChange}
      selectedViz={selVizOpt}
      vizOptions={vizOptions}
    />
  );
};

type VizOption = VizToQueryConfig & {
  id: string;
};
