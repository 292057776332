import { getInceptionTheme } from "@inception/ui";
import React, { useMemo } from "react";
import { SingleStatVisualization } from "../../../../../components/single-stat/SingleStatVisualization";
import { useCommonRendererFunctionality } from "./common";
import { USFWRendererProps } from "./types";

export const SingleStatRenderer: React.FC<USFWRendererProps> = props => {
  const { loadingElement, noDataElement, properties, dataType } = props;

  const { singleStat } = properties;
  const {
    defaultBackground = "transparent",
    defaultColor = getInceptionTheme().inceptionColors.palette.BL25,
    thresholds = []
  } = singleStat || {};

  const { data, isFetching, isError, dataExists } = useCommonRendererFunctionality(props);

  const displayValue: number = useMemo(() => {
    const datum = data?.[0];
    if (datum) {
      const postAggData = datum.postAggResult.data;

      const refId = Object.keys(postAggData)[0];

      const dataFrame = postAggData[refId]?.data || [];
      return dataFrame[0]?.fields?.[1]?.data?.[0] || null;
    }
    return "";
  }, [data]);

  const { color, backgroundColor } = useMemo(() => {
    const color = defaultColor;
    const backgroundColor = defaultBackground;
    // thresholds supported only for percent_100 for now
    if (dataType === "PERCENT_100") {
      const threshold = thresholds.find(x => x.fromVal > displayValue && x.toVal < displayValue);
      if (threshold) {
        return {
          color: threshold.color,
          backgroundColor: threshold.background
        };
      }
    }

    return {
      color,
      backgroundColor
    };
  }, [dataType, defaultBackground, defaultColor, displayValue, thresholds]);

  if (isFetching) {
    return loadingElement;
  }

  if (!dataExists || isError || !displayValue) {
    return noDataElement;
  }

  return (
    <SingleStatVisualization
      backgroundColor={backgroundColor}
      data={displayValue}
      textColor={color}
    />
  );
};
