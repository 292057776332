import { logger, dateTime, generateId } from "../../core";
import { fieldPickerApiService, FieldPickerContext, BizFieldInfo, EntityOperation } from "../../services/api/explore";
import { CohortVariableModel, CohortFilterType, VariableType } from "../models/VariableModel";
import entityStoreApiService from "../../services/api/EntityStoreApiService";
import { isErrorResponse } from "../../services/api/utils";

const NAME_FIELD = "Name";

export const fetchCohortVariables = async (
  isDemoMode: boolean,
  entityTypeId: string,
  cohortId: string,
  pinDeficitCount = 4
): Promise<CohortVariableModel[]> => {
  const to = dateTime();
  const from = to.clone().subtract(1, "days");

  const startMillis = from.valueOf();
  const endMillis = to.valueOf();

  const fieldPickerContext: FieldPickerContext = {
    bizEntityType: entityTypeId,
    showFields: true
  };

  let bizFields: BizFieldInfo[] = [];

  try {
    const businessFieldsCallback = fieldPickerApiService.getBizEntityFields(
      entityTypeId,
      fieldPickerContext,
      startMillis,
      endMillis
    );

    const { bizFields: rBizFields = [] } = await businessFieldsCallback;
    bizFields = rBizFields.filter(bfInfo => !bfInfo.bizField?.entityField?.relNames.length);

    const variables: CohortVariableModel[] = [];

    bizFields.forEach(bfInfo => {
      const { bizField } = bfInfo;
      if (bizField && bizField.entityField) {
        const { entityField } = bizField;
        const { propName } = entityField;
        variables.push(getEntityVariableModel(entityTypeId, cohortId, propName, bizField));
      }
    });

    const orderedVariables = await pinVariables(
      entityTypeId,
      cohortId,
      variables,
      startMillis,
      endMillis,
      isDemoMode,
      pinDeficitCount
    );
    return orderedVariables;
  } catch (error) {
    logger.error("Cohort dashboard", "Error fetching business fields", error);
    return [];
  }
};

export const getEntityVariableModel = (
  entityTypeId: string,
  cohortId: string,
  fieldName: string,
  bizField: CohortVariableModel["field"],
  operator: EntityOperation = "eq",
  value: string | string[] = []
) => {
  const varModel: CohortVariableModel = {
    id: generateId(),
    cohortId,
    depFieldNames: [],
    entityTypeId: entityTypeId,
    name: fieldName,
    type: VariableType.Cohort,
    value,
    hide: true,
    defaultValue: null,
    includeAll: false,
    label: fieldName,
    multi: true,
    field: bizField,
    fieldType: "bizEntityField",
    filterType: CohortFilterType.Entity,
    operator,
    supportsAggregation: true
  };

  return varModel;
};

const pinVariables = async (
  entityTypeId: string,
  cohortId: string,
  variables: CohortVariableModel[],
  startMillis: number,
  endMillis: number,
  isDemoMode: boolean,
  pinDeficit = 4
): Promise<CohortVariableModel[]> => {
  const presetBizFields: string[] = [];
  const presetBizFieldsCount = presetBizFields.length;

  if (pinDeficit !== 0) {
    await fetchAggSuggestions(entityTypeId, cohortId, variables, startMillis, endMillis, isDemoMode);
  }

  let pinnedCount = 0;

  let nameCohortVar: CohortVariableModel;

  const unPinnedVariableGroup = {
    lowCardVars: [] as CohortVariableModel[],
    highCardVars: [] as CohortVariableModel[]
  };

  const validVariables: CohortVariableModel[] = [];

  variables.forEach(v => {
    const { name, aggSuggestion } = v;

    const { cardinality, stats } = aggSuggestion?.aggregationMeta || {};
    // Mark no ratio cases as low cardinality
    const lowCardinality = (cardinality?.ratio ?? 0) < 0.5;
    const minExists = !stats?.min?.toString().includes("Infinity");
    const maxExists = !stats?.max?.toString().includes("Infinity");
    const valid = minExists && maxExists;

    if (name === NAME_FIELD) {
      nameCohortVar = v;
      v.isPinned = true;
    } else if (valid) {
      const pinned = presetBizFields.includes(name);
      if (!pinned) {
        if (lowCardinality) {
          unPinnedVariableGroup.lowCardVars.push(v);
        } else {
          unPinnedVariableGroup.highCardVars.push(v);
        }
      }
      pinnedCount += pinned ? 1 : 0;
      v.isPinned = pinned;
      validVariables.push(v);
    }
  });

  // Add extra pins only if none of the biz fields from config are pinned
  const addDeficit = pinnedCount === 0;

  if (addDeficit) {
    let deficit = (presetBizFieldsCount || pinDeficit) - pinnedCount;

    const { lowCardVars } = unPinnedVariableGroup;

    const lowCardDeficitVars = lowCardVars.slice(0, deficit);
    lowCardDeficitVars.forEach(v => (v.isPinned = true));
    const numLowCardAdded = lowCardDeficitVars.length;
    deficit -= numLowCardAdded;

    // const highCardDeficitVars = highCardVars.slice(0, deficit);
    // highCardDeficitVars.forEach(v => v.isPinned = true);
  }

  const orderedVariables: CohortVariableModel[] = [nameCohortVar];
  presetBizFields.forEach(fieldName => {
    const idx = validVariables.findIndex(v => v.name === fieldName);
    if (idx !== -1) {
      const v = validVariables[idx];
      orderedVariables.push(v);
      validVariables.splice(idx, 1);
    }
  });

  orderedVariables.push(...validVariables);
  return orderedVariables;
};

const fetchAggSuggestions = async (
  entityTypeId: string,
  cohortId: string,
  variables: CohortVariableModel[],
  startMillis: number,
  endMillis: number,
  isDemoMode: boolean
): Promise<void> => {
  const vMap: Record<string, CohortVariableModel> = {};
  const fieldNames = variables.map(v => {
    const { name } = v;
    vMap[name] = v;
    return name;
  });

  const suggestionsCallback = entityStoreApiService.suggestEntityAggregation(
    startMillis,
    endMillis,
    entityTypeId,
    cohortId,
    fieldNames,
    isDemoMode
  );

  try {
    const { data: cohortResponse, status, statusText } = await suggestionsCallback;

    const agg = cohortResponse.suggestedAggregations || {};
    const isError = isErrorResponse(status);
    const error = isError ? statusText : "";

    if (isError || !agg) {
      logger.error("Cohort variable suggestions", `Error fetching suggestions`, error);
    } else {
      Object.keys(agg).forEach(fieldName => {
        const v = vMap[fieldName];
        if (v) {
          v.aggSuggestion = agg[fieldName];
        }
      });
    }
  } catch (error) {
    logger.error("Cohort variable suggestions", `Error fetching suggestions`, error);
  }

  return;
};
