import { IncFaIcon, IncToolTip } from "@inception/ui";
import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../components";
import { TimeRangeSelectorPopper } from "../../../components/time-range";
import { dateTime, generateId, logger, RawTimeRange, TimeRange, useToggleState } from "../../../core";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { featureFlagService, FEATURE_FLAGS } from "../../../services/feature-flags";
import { useFetchIncidentsCount } from "../../../services/api/operationalise";
import { TimeIntervalOption, timeIntervalOptions } from "./options";
import { ResultsTable } from "./results-table/ResultsTable";
import { getDefaultTimeIntervalOpt, heatMapColorsByRange } from "./utils";
import { ResultsViz } from "./ResultsViz";

interface Props {
  timeRange: TimeRange;
  onTimeRangeChange: (rawTimeRange: RawTimeRange) => void;

  opConfigId: string;
  opName: string;
  widgetId?: string;
  entityTypeId: string;
  entityTypeName?: string;

  shouldRenderInfo?: boolean;
  simulationId?: string;
  isEventOperationalize: boolean;
}

export const ResultsCard: FC<Props> = props => {
  const {
    timeRange,
    onTimeRangeChange,
    opName: opOrSimulationName,
    opConfigId,
    shouldRenderInfo,
    simulationId,
    widgetId,
    entityTypeId,
    entityTypeName = entityTypeId,
    isEventOperationalize = false
  } = props;

  const defTimeIntervalOpt = useMemo(() => getDefaultTimeIntervalOpt(timeRange), [timeRange]);
  const [timeIntervalOpt, setTimeIntervalOpt] = useState<TimeIntervalOption>(defTimeIntervalOpt);

  const timeIntervalMillisRef = useRef(timeIntervalOpt.data);
  const timeIntervalLabelRef = useRef(timeIntervalOpt.label);

  useEffect(() => {
    if (defTimeIntervalOpt.value !== timeIntervalOpt.value) {
      timeIntervalMillisRef.current = defTimeIntervalOpt.data;
      timeIntervalLabelRef.current = defTimeIntervalOpt.label;
      setTimeIntervalOpt(defTimeIntervalOpt);
    }
  }, [defTimeIntervalOpt, timeIntervalOpt]);

  const btnRef = useRef<HTMLDivElement>(null);

  const { close, isOpen, open } = useToggleState();

  const onTimeRangeUpdate = useCallback(
    (nTimeRange: TimeRange) => {
      const { from, to } = nTimeRange.raw;
      const label = timeRangeUtils.getLabelByTimeRange(from, to);
      timeIntervalLabelRef.current = label;

      const t = timeRangeUtils.getTimeRangeFromRaw(nTimeRange.raw);

      const startDateTime = dateTime(t.from.valueOf());
      const endDateTime = dateTime(t.to.valueOf());

      let intervalMillis = ONE_HOUR;

      const diff = endDateTime.diff(startDateTime, "millisecond");

      if (diff > ONE_WEEK) {
        intervalMillis = ONE_DAY;
      } else if (diff > ONE_DAY) {
        intervalMillis = 6 * ONE_HOUR;
      } else {
        const hrDiff = endDateTime.hour() - startDateTime.hour();
        if (hrDiff > 12) {
          intervalMillis = 15 * ONE_MIN;
        } else if (hrDiff > 6) {
          intervalMillis = 10 * ONE_MIN;
        } else if (hrDiff > 3) {
          intervalMillis = 5 * ONE_MIN;
        } else if (hrDiff > 1) {
          intervalMillis = ONE_MIN;
        } else {
          intervalMillis = ONE_MIN;
        }
      }
      timeIntervalMillisRef.current = intervalMillis;
      onTimeRangeChange(nTimeRange.raw);
    },
    [onTimeRangeChange]
  );

  const { disableZoomIn, disableZoomOut, zoomInIdx, zoomOutIdx } = useMemo(() => {
    const numIntervalOpts = timeIntervalOptions.length;
    const intervalOptIdx = timeIntervalOptions.findIndex(opt => opt.value === timeIntervalOpt.value);
    const zoomInIdx = intervalOptIdx + 1;
    const zoomOutIdx = intervalOptIdx - 1;

    const disableZoomOut = zoomOutIdx < 0;
    const disableZoomIn = zoomInIdx > numIntervalOpts - 1;

    return {
      disableZoomIn,
      disableZoomOut,
      zoomInIdx,
      zoomOutIdx
    };
  }, [timeIntervalOpt.value]);

  const onZoomInInterval = useCallback(() => {
    const opt = timeIntervalOptions[zoomInIdx];
    opt && setTimeIntervalOpt(opt);
  }, [zoomInIdx]);

  const onZoomOutInterval = useCallback(() => {
    const opt = timeIntervalOptions[zoomOutIdx];
    opt && setTimeIntervalOpt(opt);
  }, [zoomOutIdx]);

  const { fromMillis: startTimeMillis, toMillis: endTimeMillis } = useMemo(
    () => timeRangeUtils.getMillisFromTimeRange(timeRange),
    [timeRange]
  );

  const alertCountTimeIntervalMillis = endTimeMillis - startTimeMillis;

  const { data, error, isError, isFetching } = useFetchIncidentsCount(
    opConfigId,
    startTimeMillis,
    endTimeMillis,
    alertCountTimeIntervalMillis,
    simulationId
  );

  useEffect(() => {
    if (isError) {
      logger.error("ResultsCard", "Error fetching incidents count", error);
    }
  }, [error, isError]);

  const alertsCount = data?.[0]?.count || "-";

  const legend = useMemo(() => getLegend(), []);

  const zoomInButton = useMemo(
    () => (
      <div
        className="time-interval-selector--button"
        data-disabled={disableZoomIn}
        onClick={onZoomInInterval}
      >
        <IncFaIcon iconName="magnifying-glass-plus" />
      </div>
    ),
    [disableZoomIn, onZoomInInterval]
  );

  const zoomOutButton = useMemo(
    () => (
      <div
        className="time-interval-selector--button"
        data-disabled={disableZoomOut}
        onClick={onZoomOutInterval}
      >
        <IncFaIcon iconName="magnifying-glass-minus" />
      </div>
    ),
    [disableZoomOut, onZoomOutInterval]
  );

  const zoomInOptLabel = timeIntervalOptions[zoomInIdx]?.label;
  const zoomOutOptLabel = timeIntervalOptions[zoomOutIdx]?.label;

  const showHeatmap = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.showHeatmap);

  return (
    <div className="results-card">
      <VerticallyCenteredRow className="marginBt24">
        <div className="marginRt12 inc-text-body-medium">Results</div>

        <VerticallyCenteredRow className="marginLt16 time-interval-selector">
          <VerticallyCenteredRow
            className="time-interval-selector--range"
            onClick={open}
            ref={btnRef}
          >
            <VerticallyCenteredRow className="inc-text-subtext-medium">
              {timeIntervalLabelRef.current}
            </VerticallyCenteredRow>
            <IncFaIcon
              className="marginLt6"
              iconName="calendar"
            />
          </VerticallyCenteredRow>

          {zoomInOptLabel && !disableZoomIn && (
            <IncToolTip
              placement="top"
              titleText={zoomInOptLabel}
            >
              {zoomInButton}
            </IncToolTip>
          )}
          {(!zoomInOptLabel || disableZoomIn) && zoomInButton}

          {zoomOutOptLabel && !disableZoomOut && (
            <IncToolTip
              placement="top"
              titleText={zoomOutOptLabel}
            >
              {zoomOutButton}
            </IncToolTip>
          )}
          {(!zoomOutOptLabel || disableZoomOut) && zoomOutButton}
        </VerticallyCenteredRow>
      </VerticallyCenteredRow>

      <VerticallyCenteredRow className="marginBt8">
        <div className="inc-text-subtext-medium">Statistics</div>
        {shouldRenderInfo && (
          <>
            <IncFaIcon
              className="marginLt10 marginRt8"
              iconName="info-circle"
            />
            <div className="inc-text-element">
              Adjust the condition configurations to add or reduce the amount of alerts simulated.
            </div>
          </>
        )}
      </VerticallyCenteredRow>

      <VerticallyCenteredRow className="marginBt24">
        <div className="alerts-count-card">
          <div className="inc-text-body-bold marginBt6">
            {isFetching && <LoadingSpinner titleText=" " />}
            {!isFetching && (
              <>
                {isError && (
                  <IncToolTip
                    placement="top"
                    titleText="Error fetching alert count"
                    variant="error"
                  >
                    <VerticallyCenteredRow>
                      <IncFaIcon
                        className="status-danger"
                        iconName="exclamation-triangle"
                      />
                    </VerticallyCenteredRow>
                  </IncToolTip>
                )}
                {!isError && <VerticallyCenteredRow>{alertsCount}</VerticallyCenteredRow>}
              </>
            )}
          </div>

          <div className="inc-text-subtext-medium inc-text-inactive">Alerts</div>
        </div>
      </VerticallyCenteredRow>

      {showHeatmap && (
        <div className="results-card--heatmap">
          <VerticallyCenteredRow className="marginBt12">
            <VerticallyCenteredRow className="inc-text-subtext-medium">Alert Heatmap</VerticallyCenteredRow>
            {legend}
          </VerticallyCenteredRow>

          <ResultsViz
            endTimeMillis={endTimeMillis}
            opId={opConfigId}
            simulationId={simulationId}
            startTimeMillis={startTimeMillis}
            timeIntervalMillis={timeIntervalMillisRef.current}
          />
        </div>
      )}

      <div className="results-card--table marginTp24">
        <ResultsTable
          endTimeMillis={endTimeMillis}
          entityTypeId={entityTypeId}
          entityTypeName={entityTypeName}
          isEventOperationalize={isEventOperationalize}
          opId={opConfigId}
          opOrSimulationName={opOrSimulationName}
          simulationId={simulationId}
          startTimeMillis={startTimeMillis}
          widgetId={widgetId}
        />
      </div>

      <TimeRangeSelectorPopper
        anchorEl={btnRef.current}
        onClose={close}
        onTimeSelect={onTimeRangeUpdate}
        open={isOpen}
        timeRange={timeRange}
      />
    </div>
  );
};

const getLegend = () => {
  const keys = Object.keys(heatMapColorsByRange);
  const uniqId = generateId();
  const legendEntries = keys.map((colorKey, idx) => {
    const key = [colorKey, idx, uniqId].join("_");
    const color = heatMapColorsByRange[colorKey];
    const style: CSSProperties = {
      ...squareStyle,
      background: color
    };

    return (
      <VerticallyCenteredRow
        className="marginRt16"
        key={key}
      >
        <div
          className="marginRt6"
          style={style}
        />
        <div className="inc-text-subtext-medium">{colorKey}</div>
      </VerticallyCenteredRow>
    );
  });

  return (
    <VerticallyCenteredRow className="marginLt16">
      <VerticallyCenteredRow className="marginRt10 inc-text-inactive inc-text-subtext-medium">
        Count
      </VerticallyCenteredRow>
      {legendEntries}
    </VerticallyCenteredRow>
  );
};

const squareStyle: CSSProperties = {
  width: 10,
  height: 10,
  borderRadius: 2
};

const ONE_MIN = 60 * 1000;
const ONE_HOUR = 60 * ONE_MIN;
const ONE_DAY = 24 * ONE_HOUR;
const ONE_WEEK = 7 * ONE_DAY;
