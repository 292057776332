import React, { useMemo, useCallback, ChangeEvent, MouseEvent, useState } from "react";
import { IncTextfield, IncButton, IncCollapsible } from "@inception/ui";
import { CheckBoxList } from "../checkbox-list";
import { FieldPickerUtils } from "../../utils";
import LoadingSpinner from "../Loading/Loading";
import { addToFieldsSelection as addToFieldsSelection, addToMetricsSelection, getLabel } from "./utils";
import { FieldOrMetricTypes, USFieldInfoWithId, USMetricInfoWithId } from "./types";
import { generateFieldsCheckboxOptions, generateMetricsCheckboxOptions } from "./CheckBoxPropsGenerator";
import { USEntityFieldsRenderer } from "./EntityFieldsRenderer";

interface Props {
  label?: string;
  isLoading: boolean;

  performanceFields: USFieldInfoWithId[];
  businessFields: USFieldInfoWithId[];
  entityFields: USFieldInfoWithId[];
  journeyFields: USFieldInfoWithId[];
  metrics: USMetricInfoWithId[];

  fieldsSelectionMap: Record<string, boolean>;
  metricsSelectionMap: Record<string, boolean>;
  onSelectionChange: (
    fieldsSelectionMap: Record<string, boolean>,
    metricsSelectionMap: Record<string, boolean>
  ) => void;

  displayMetricFieldsAsMetrics?: boolean;
}

export const FieldsAndMetricsSidePanel: React.FC<Props> = props => {
  const {
    label: fieldsLabel = "",
    isLoading,
    performanceFields,
    businessFields,
    entityFields,
    journeyFields,
    fieldsSelectionMap,
    onSelectionChange,
    metrics,
    metricsSelectionMap,
    displayMetricFieldsAsMetrics
  } = props;

  const usFields = useMemo(
    () => [...(entityFields || []), ...(businessFields || []), ...(performanceFields || []), ...(journeyFields || [])],
    [businessFields, entityFields, performanceFields, journeyFields]
  );

  const usageMetricFields = useMemo(() => usFields.filter(({ isMetricField }) => isMetricField), [usFields]);

  const { usBusinessFields, usEntityFields, usJourneyFields, usPerformanceFields } = useMemo(() => {
    const usBusinessFields = displayMetricFieldsAsMetrics
      ? businessFields?.filter(({ isMetricField }) => !isMetricField)
      : businessFields;
    const usEntityFields = displayMetricFieldsAsMetrics
      ? entityFields?.filter(({ isMetricField }) => !isMetricField)
      : entityFields;
    const usPerformanceFields = displayMetricFieldsAsMetrics
      ? performanceFields?.filter(({ isMetricField }) => !isMetricField)
      : performanceFields;
    const usJourneyFields = displayMetricFieldsAsMetrics
      ? journeyFields?.filter(({ isMetricField }) => !isMetricField)
      : journeyFields;

    return {
      usBusinessFields: usBusinessFields || [],
      usEntityFields: usEntityFields || [],
      usPerformanceFields: usPerformanceFields || [],
      usJourneyFields: usJourneyFields || []
    };
  }, [businessFields, displayMetricFieldsAsMetrics, entityFields, journeyFields, performanceFields]);

  const [searchText, setSearchText] = useState("");

  const selectOrClearAllFields = useCallback(
    (clear = false) => {
      const nFieldsSelectionMap: Record<string, boolean> = {};
      const nMetricsSelectionMap: Record<string, boolean> = {};

      usFields.forEach(({ usField }) => {
        const label = FieldPickerUtils.getUserServiceFieldLabel(usField.userServiceField);
        nFieldsSelectionMap[label] = !clear;
      });

      metrics.forEach(({ metricId }) => (nMetricsSelectionMap[metricId] = !clear));

      onSelectionChange(nFieldsSelectionMap, nMetricsSelectionMap);
    },
    [metrics, onSelectionChange, usFields]
  );

  const {
    selEntityFieldCount,
    totalEntityFieldCount,
    selPerfFieldCount,
    totalPerfFieldCount,
    selJourneyFieldCount,
    totalJourneyFieldCount,
    selUsageMetricsCount,
    totalUsageMetricsCount,
    selMetricsCount,
    totalMetricsCount
  } = useMemo(() => {
    const totalPerfFieldCount = usPerformanceFields?.length;
    const totalEntityFieldCount = usBusinessFields?.length + usEntityFields?.length;
    const totalJourneyFieldCount = usJourneyFields?.length;
    const totalUsageMetricsCount = usageMetricFields?.length;
    const totalMetricsCount = metrics?.length;

    let selEntityFieldCount = 0;
    let selPerfFieldCount = 0;
    let selJourneyFieldCount = 0;
    let selMetricsCount = 0;
    let selUsageMetricsCount = 0;

    selEntityFieldCount += addToFieldsSelection(usBusinessFields, fieldsSelectionMap);
    selEntityFieldCount += addToFieldsSelection(usEntityFields, fieldsSelectionMap);
    selPerfFieldCount += addToFieldsSelection(usPerformanceFields, fieldsSelectionMap);
    selJourneyFieldCount += addToFieldsSelection(usJourneyFields, fieldsSelectionMap);
    selUsageMetricsCount += addToFieldsSelection(usageMetricFields, fieldsSelectionMap);
    selMetricsCount += addToMetricsSelection(metrics, metricsSelectionMap);

    return {
      selEntityFieldCount,
      selPerfFieldCount,
      totalPerfFieldCount,
      totalEntityFieldCount,
      selJourneyFieldCount,
      totalJourneyFieldCount,
      selMetricsCount,
      totalMetricsCount,
      selUsageMetricsCount,
      totalUsageMetricsCount
    };
  }, [
    fieldsSelectionMap,
    metrics,
    metricsSelectionMap,
    usageMetricFields,
    usBusinessFields,
    usEntityFields,
    usJourneyFields,
    usPerformanceFields
  ]);

  const onFieldsSelectionChange = useCallback(
    (partSelection: Record<string, boolean>) => {
      const nFieldsSelectionMap = {
        ...fieldsSelectionMap,
        ...partSelection
      };
      onSelectionChange(nFieldsSelectionMap, metricsSelectionMap);
    },
    [fieldsSelectionMap, metricsSelectionMap, onSelectionChange]
  );

  const onMetricsSelectionChange = useCallback(
    (partSelection: Record<string, boolean>) => {
      const nMetricsSelectionMap = {
        ...metricsSelectionMap,
        ...partSelection
      };
      onSelectionChange(fieldsSelectionMap, nMetricsSelectionMap);
    },
    [fieldsSelectionMap, metricsSelectionMap, onSelectionChange]
  );

  const renderFields = useCallback(
    (list: USFieldInfoWithId[], type: FieldOrMetricTypes, label: string, additionalJsx?: JSX.Element) => {
      const checkboxOptions = generateFieldsCheckboxOptions(list, fieldsSelectionMap, searchText.toLowerCase());

      const onChange = (id: string, checked: boolean) => onFieldsSelectionChange({ [id]: checked });

      const component = (
        <>
          <CheckBoxList
            onChange={onChange}
            options={checkboxOptions}
          />
          {additionalJsx}
        </>
      );

      return (
        <IncCollapsible
          className="collapsible-padding"
          content={component}
          header={label}
          iconPosition="right"
          show
        />
      );
    },
    [fieldsSelectionMap, searchText, onFieldsSelectionChange]
  );

  const renderMetrics = useCallback(
    (metrics: USMetricInfoWithId[], label: string, additionalJsx?: JSX.Element) => {
      const checkboxOptions = generateMetricsCheckboxOptions(
        metrics,
        metricsSelectionMap,
        searchText.toLowerCase()
      ).sort((a, b) => {
        const labelA = a.name?.toLocaleLowerCase() || "";
        const labelB = b.name.toLocaleLowerCase() || "";

        return labelA.localeCompare(labelB);
      });

      const onChange = (id: string, checked: boolean) => onMetricsSelectionChange({ [id]: checked });

      const component = (
        <>
          <CheckBoxList
            onChange={onChange}
            options={checkboxOptions}
          />
          {additionalJsx}
        </>
      );

      return (
        <IncCollapsible
          className="collapsible-padding"
          content={component}
          header={label}
          iconPosition="right"
          show
        />
      );
    },
    [metricsSelectionMap, searchText, onMetricsSelectionChange]
  );

  const onSearchTextChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    setSearchText(evt.target.value);
  }, []);

  const onTextFieldClick = useCallback((evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
  }, []);

  const clearAll = useCallback(() => {
    selectOrClearAllFields(true);
  }, [selectOrClearAllFields]);

  const selectAll = useCallback(() => {
    selectOrClearAllFields(false);
  }, [selectOrClearAllFields]);

  const {
    businessFieldsExists,
    performanceFieldsExists,
    entityFieldsExists,
    journeyFieldsExists,
    usageMetricsExists,
    metricsExists
  } = useMemo(
    () => ({
      businessFieldsExists: usBusinessFields?.length > 0,
      performanceFieldsExists: usPerformanceFields?.length > 0,
      entityFieldsExists: usEntityFields?.length > 0,
      journeyFieldsExists: usJourneyFields?.length > 0,
      usageMetricsExists: usageMetricFields?.length > 0,
      metricsExists: metrics?.length > 0
    }),
    [metrics, usageMetricFields, usBusinessFields, usEntityFields, usJourneyFields, usPerformanceFields]
  );

  const performanceFieldsLabel = `${getLabel(FieldOrMetricTypes.PERFORMANCE_FIELDS)} (${selPerfFieldCount} / ${totalPerfFieldCount})`;
  const businessFieldsLabel = `${getLabel(FieldOrMetricTypes.BUSINESS_FIELDS)} (${selEntityFieldCount} / ${totalEntityFieldCount})`;
  const journeyFieldsLabel = `${getLabel(FieldOrMetricTypes.JOURNEY_FIELDS)} (${selJourneyFieldCount} / ${totalJourneyFieldCount})`;

  const usageMetricsLabel = `${getLabel(FieldOrMetricTypes.USAGE_METRICS)} (${selUsageMetricsCount} / ${totalUsageMetricsCount})`;
  const metricsLabel = `${getLabel(FieldOrMetricTypes.METRICS)} (${selMetricsCount} / ${totalMetricsCount})`;

  return (
    <div className="fields-side-panel">
      {isLoading && <LoadingSpinner titleText="Loading fields..." />}
      {!isLoading && (
        <div className="fields-panel">
          {Boolean(fieldsLabel) && <div className="inc-text-body-medium marginBt16">{fieldsLabel}</div>}

          <div className="fields-section">
            <div className="search-bar">
              <IncTextfield
                autoFocus={true}
                onChange={onSearchTextChange}
                onClick={onTextFieldClick}
                placeholder={"Search by name"}
                value={searchText}
              />
            </div>

            <div className="inc-flex-row inc-flex-space-contents inc-flex-center-vertical marginBt8">
              <IncButton
                color="link"
                onClick={selectAll}
              >
                Select All
              </IncButton>
              <IncButton
                className="clear-button marginRt12"
                color="link"
                onClick={clearAll}
              >
                Clear
              </IncButton>
            </div>

            {/* Custom Metrics */}
            {metricsExists && (
              <div className="inc-flex-column marginBt12 fields-list">{renderMetrics(metrics, metricsLabel)}</div>
            )}

            {/* Performance Metrics */}
            {displayMetricFieldsAsMetrics && usageMetricsExists && (
              <div className="inc-flex-column marginBt12 fields-list">
                {renderFields(usageMetricFields, FieldOrMetricTypes.USAGE_METRICS, usageMetricsLabel)}
              </div>
            )}

            {/* Journey Fields */}
            {journeyFieldsExists && (
              <div className="inc-flex-column marginBt12 fields-list">
                {renderFields(usJourneyFields, FieldOrMetricTypes.JOURNEY_FIELDS, journeyFieldsLabel)}
              </div>
            )}

            {/* Business Fields */}
            {(businessFieldsExists || entityFieldsExists) && (
              <div className="inc-flex-column marginBt12 fields-list">
                {renderFields(
                  usBusinessFields,
                  FieldOrMetricTypes.BUSINESS_FIELDS,
                  businessFieldsLabel,
                  <USEntityFieldsRenderer
                    fieldSelection={fieldsSelectionMap}
                    fields={usEntityFields}
                    onSelectionChange={onFieldsSelectionChange}
                    searchText={searchText.toLowerCase()}
                    show
                  />
                )}
              </div>
            )}

            {/* Performance Fields */}
            {performanceFieldsExists && (
              <div className="inc-flex-column marginBt12 fields-list">
                {renderFields(usPerformanceFields, FieldOrMetricTypes.PERFORMANCE_FIELDS, performanceFieldsLabel)}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
