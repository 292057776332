import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IncModal, IncModalProps, IncTextfield } from "@inception/ui";
import { OpBizDataQuery, UberOperationalizeTypes } from "../../services/api/operationalise";
import { VerticallyCenteredRow, KPIQueryEditor, LoadingSpinner } from "../../components";
import { useInputState } from "../../core";
import { BizDataQuery, WidgetConfigUtils } from "../../services/api/explore";
import { getDefaultBizDataQuery } from "./context";

type Props = {
  opBizDataQuery: OpBizDataQuery;
  onUpdateQuery: (bizDataQuery: BizDataQuery) => void;
  onUpdateName: (name: string) => void;
  isOpen: boolean;
  onClose: () => void;
  eventTypeId: string;
};

const DiagnosticKpiEditorPopper: FC<Props> = props => {
  const { onUpdateName, onUpdateQuery, opBizDataQuery, isOpen, onClose, eventTypeId } = props;
  const { bizDataQuery: pBizDataQuery, name: pName } = opBizDataQuery || {};

  const { inputValue, onInputChange: onChangeName } = useInputState(pName);

  const [kpiQuery, setKpiQuery] = useState<UberOperationalizeTypes.OpBizDataQuery>();

  const initialiseKPI = useCallback(async () => {
    try {
      if (pBizDataQuery) {
        const transformedQuery = await WidgetConfigUtils.getWidgetConfigBasedBizDataQuery(pBizDataQuery);
        setKpiQuery({
          bizDataQuery: transformedQuery,
          rollingFreq: null,
          rollingFunction: null
        });
      } else {
        setKpiQuery({
          bizDataQuery: getDefaultBizDataQuery(eventTypeId),
          rollingFreq: null,
          rollingFunction: null
        });
      }
    } catch (error) {
      setKpiQuery({
        bizDataQuery: getDefaultBizDataQuery(eventTypeId),
        rollingFreq: null,
        rollingFunction: null
      });
    }
  }, [eventTypeId, pBizDataQuery]);

  useEffect(() => {
    initialiseKPI();
  }, [initialiseKPI]);

  const onChange = useCallback((isValid: boolean, errorsText: string, kpi: UberOperationalizeTypes.OpBizDataQuery) => {
    if (isValid) {
      setKpiQuery(kpi);
    }
  }, []);

  const onSave = useCallback(() => {
    if (kpiQuery?.bizDataQuery) {
      onUpdateQuery(kpiQuery.bizDataQuery);
      onUpdateName(inputValue);
      onClose();
    }
  }, [kpiQuery, inputValue, onClose, onUpdateName, onUpdateQuery]);

  const actions = useMemo<IncModalProps["actions"]>(
    () => ({
      primary: {
        onClick: onSave,
        color: "primary",
        label: "Save",
        icon: "save",
        disabled: !inputValue
      },
      secondary: {
        onClick: onClose,
        color: "secondary-blue",
        label: "Cancel"
      }
    }),
    [inputValue, onClose, onSave]
  );

  return (
    <IncModal
      actions={actions}
      contentClassName="inc-flex-column"
      disableFocusOnLoad
      onClose={onClose}
      show={isOpen}
      size="lg"
      titleText={"Edit KPI"}
    >
      <VerticallyCenteredRow>
        <IncTextfield
          errorText="Name is required"
          hasError={!inputValue}
          label="KPI Name"
          onChange={onChangeName}
          placeholder="KPI name"
          value={inputValue}
        />
      </VerticallyCenteredRow>

      {!kpiQuery && <LoadingSpinner titleText="Loading Key Performance Indicator..." />}
      {Boolean(kpiQuery) && (
        <KPIQueryEditor
          kpiQuery={kpiQuery}
          onStateChange={onChange}
          showIsSpikePositive
        />
      )}
    </IncModal>
  );
};

export default DiagnosticKpiEditorPopper;
