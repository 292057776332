import { cloneDeep } from "lodash";
import { MetricTableColumnInfo, MetricTableSlice } from "../types";
import { generateId } from "../../../core";
import {
  UserServiceFieldSliceSet,
  UserServiceFieldMetricConfigDefinition,
  WidgetConfig
} from "../../../services/api/explore";
import { convertUSFieldSliceSetToTagSlice } from "../../../utils/ExploreUtils";

export const getNewMetricColumn = (entityTypeId: string, slices: MetricTableSlice[]): MetricTableColumnInfo => {
  const metricId = generateId();
  const { sliceSet, usFieldSliceSet } = getUsFieldSlicesFromTableSlices(slices);

  const widgetConfig: WidgetConfig = {
    name: `${entityTypeId} Metric table config`,
    isStatic: false,
    visualizations: [],
    bizEntityType: entityTypeId,
    dataDefinition: {
      fields: {},
      metrics: {
        [metricId]: {
          id: metricId,
          sourceType: "userServiceField",
          name: "New metric",
          userServiceFieldMetricConfig: {
            aggregator: null,
            eventFilters: {
              userServiceFilters: []
            },
            sliceSets: [usFieldSliceSet],
            userServiceField: null
          }
        }
      }
    }
  };

  const metricColumn: MetricTableColumnInfo = {
    id: generateId(),
    name: "New metric",
    queryConfig: {
      metricQueryConfig: {
        entityTypeId,
        bizDataQuery: {
          labels: {
            entityTypeId,
            name: "New metric"
          },
          sliceSpec: {
            metricId,
            selectorSpec: {
              filters: []
            },
            sliceSet
          },
          widgetConfig
        }
      },
      queryType: "metric",
      dataType: "_long",
      subType: "none"
    }
  };

  return metricColumn;
};

export const getCloneMetricColumn = (metricColumn: MetricTableColumnInfo): MetricTableColumnInfo => {
  const clMetricColumn = cloneDeep(metricColumn);

  clMetricColumn.id = generateId();
  clMetricColumn.name = `${metricColumn.name} (copy)`;

  const { metricQueryConfig } = clMetricColumn.queryConfig;
  const bizDataQuery = metricQueryConfig?.bizDataQuery;

  const metrics = bizDataQuery?.widgetConfig?.dataDefinition?.metrics;

  if (metrics) {
    const metricIdMap: Record<string, string> = {};
    const nMetrics: typeof metrics = {};

    Object.values(metrics).forEach(metric => {
      const metricId = metric.id;
      const nMetricId = generateId();

      metricIdMap[metricId] = nMetricId;
      nMetrics[nMetricId] = metric;
    });

    bizDataQuery.widgetConfig.dataDefinition.metrics = nMetrics;

    const prevSliceSpecMetricId = bizDataQuery.sliceSpec.metricId;
    bizDataQuery.sliceSpec.metricId = metricIdMap[prevSliceSpecMetricId];
  }

  return clMetricColumn;
};

export const getUpdatedMetricColumns = (
  metricColumns: MetricTableColumnInfo[],
  slices: MetricTableSlice[]
): MetricTableColumnInfo[] => {
  const nMetricColumns = [...metricColumns];

  const { sliceSet, usFieldSliceSet } = getUsFieldSlicesFromTableSlices(slices);

  nMetricColumns.forEach(metricColumn => {
    const { queryConfig } = metricColumn;

    const bizDataQuery = queryConfig.metricQueryConfig?.bizDataQuery;
    if (bizDataQuery) {
      bizDataQuery.sliceSpec = {
        ...bizDataQuery.sliceSpec,
        sliceSet
      };

      const metrics = bizDataQuery.widgetConfig?.dataDefinition?.metrics;
      if (metrics) {
        const usFieldMetrics = Object.values(metrics).filter(
          metric => metric.sourceType === "userServiceField"
        ) as UserServiceFieldMetricConfigDefinition[];
        usFieldMetrics.forEach(metric => {
          metric.userServiceFieldMetricConfig.sliceSets = [usFieldSliceSet];
        });
      }
    }
  });

  return nMetricColumns;
};

export const getUsFieldSlicesFromTableSlices = (slices: MetricTableSlice[]) => {
  const usFieldSlices = slices.map(({ slice }) => slice);
  const usFieldSliceSet: UserServiceFieldSliceSet = {
    slices: usFieldSlices
  };
  const sliceSet = convertUSFieldSliceSetToTagSlice(usFieldSliceSet);

  return {
    usFieldSlices,
    usFieldSliceSet,
    sliceSet
  };
};
