import { produce, setAutoFreeze } from "immer";
import {
  OpCreationConfig,
  WindowTriggerCondition,
  OpSchedule,
  OpThreshold,
  UIIntegrationActionConfig,
  BizActionConfig,
  Op10zeStage,
  SuppressionConfigDef,
  OpTriageConfig,
  OpStartTrigger,
  WhatsNewConfig,
  OpMetaDataV2,
  OpAnalysisCreationConfig
} from "../../services/api/operationalise";
import {
  TimeObj,
  BizDataQuery,
  CohortConfig,
  ImpactedWidget,
  UserServiceFieldSlice,
  MetricUserServiceFilters,
  ExploreEntityFilter
} from "../../services/api/explore";
import { getAllCohortDefinition } from "../../utils/CohortUtils";
import { OpMode } from "../types";
import {
  SET_OPERATIONALISE_CONTEXT,
  SET_OPERATIONALISE_CONFIG,
  SET_END_TRIGGER,
  SET_ROLLING_FREQ,
  SET_ROLLING_FUNCTION,
  SET_SCHEDULE,
  SET_START_TRIGGER,
  SET_BIZ_DATA_QUERY,
  SET_THRESHOLD,
  SET_ENTITY_AGGREGATED,
  SET_ACTION_CONFIG,
  DELETE_ACTION_CONFIG,
  SET_UI_INTEGRATION_CONFIGS_CONTEXT,
  SET_PARTIAL_OPERATIONALISE_CONTEXT,
  DISABLE_ACTION_CONFIG,
  ENABLE_ACTION_CONFIG,
  SET_TEMPORARY_ACTION,
  DELETE_TEMPORARY_ACTION,
  SET_TEMPORARY_ACTIONS,
  SET_COHORT_CONFIG,
  RESET_COHORT_CONFIG,
  SET_STAGE,
  SET_IMPACT_WIDGETS,
  SET_DIAGNOSTIC_FIELDS,
  SET_SUPPRESSION_CONFIGS,
  SET_SIMULATIONS,
  SET_HISTORY,
  SET_SIMULATION_CONFIG,
  SET_SIMULATION_CONFIG_NAME,
  SET_OP10ZE_ID,
  SET_READ_ONLY,
  SET_IS_DRAFT,
  SET_PRIMARY_OP_CREATION_CONFIG,
  SET_SIMULATION_STATE,
  SET_OP_TRIAGE_CONFIG,
  UPDATE_ENTITY_FILTERS,
  UPDATE_EVENT_FILTERS,
  SET_OPERATIONALISE_STATE,
  SNOOZE_ACTION_CONFIG,
  SNOOZE_SERIES_CONFIG,
  SET_ACTION_CATEGORIES,
  SET_WHATS_NEW_CONFIG,
  SET_SAVE_OP_CREATION_CONFIG,
  SET_OP_MODE,
  SET_OP_META_DATA,
  SET_OP_ANALYSIS_CONFIG,
  SET_SHOULD_RELOAD_EDITORS
} from "./action_names";
import {
  OpStateAction,
  OpState,
  OpContext,
  SetActionConfigPayload,
  SimulationConfig,
  SetSimulationStatePayload,
  SetActionSnoozePayload,
  SetSeriesSnoozePayload,
  SetActionCategoriesPayload
} from "./types";
import { getIdProps } from "./reducerUtils";
import { opReducerTransformations } from "./reducer_transforms";

const opReducer = (state: OpState, action: OpStateAction): OpState => {
  switch (action.type) {
    case SET_OPERATIONALISE_CONTEXT: {
      const nContext = action.payload as OpContext;
      state.context = nContext;
      return state;
    }

    case SET_PARTIAL_OPERATIONALISE_CONTEXT: {
      const nContext = action.payload as Partial<OpContext>;
      state.context = {
        ...state.context,
        ...nContext
      };
      return state;
    }

    case SET_UI_INTEGRATION_CONFIGS_CONTEXT: {
      const uiIntegrationActionConfigs = (action.payload || []) as UIIntegrationActionConfig[];
      const nMap = uiIntegrationActionConfigs.reduce(
        (acc, entry) => {
          const { id, connectionId } = entry;
          const key = [id, connectionId].join("-");
          acc[key] = entry;
          return acc;
        },
        {} as Record<string, UIIntegrationActionConfig>
      );
      state.context.uiIntegrationActionConfigsMap = {
        ...(state.context.uiIntegrationActionConfigsMap || {}),
        ...nMap
      };
      return state;
    }

    case SET_OPERATIONALISE_CONFIG: {
      const nConfig = action.payload as OpCreationConfig;
      state.opCreationConfig = nConfig;
      if (nConfig) {
        const idProps = getIdProps(state.context);
        state.opCreationConfig.idProps = idProps;
      }
      return state;
    }

    case SET_OP_ANALYSIS_CONFIG: {
      const nConfig = action.payload as OpAnalysisCreationConfig;
      state.opCreationConfig.opAnalysisConfig = nConfig;
      return state;
    }

    case SET_SHOULD_RELOAD_EDITORS: {
      const nConfig = action.payload as boolean;
      state.shouldReloadEditors = nConfig;
      return state;
    }

    case SET_OPERATIONALISE_STATE: {
      const nState = action.payload as Partial<OpState>;
      state = {
        ...state,
        ...nState
      };

      return state;
    }

    case SET_OP_META_DATA: {
      const name = action.payload as OpMetaDataV2;
      state.metaData = name;
      return state;
    }

    case SET_WHATS_NEW_CONFIG: {
      const config = action.payload as WhatsNewConfig;
      if (state.opCreationConfig.opCreationConfigDef) {
        state.opCreationConfig.opCreationConfigDef.whatsNewConfig = config;
      }
      return state;
    }

    case SET_SCHEDULE: {
      const schedule = action.payload as OpSchedule;
      state = opReducerTransformations.updateOpSchedule(state, schedule) as OpState;

      return state;
    }

    case SET_BIZ_DATA_QUERY: {
      const bizDataQuery = action.payload as BizDataQuery;
      state = opReducerTransformations.updateBizDataQuery(state, bizDataQuery) as OpState;

      return state;
    }

    case SET_ROLLING_FUNCTION: {
      const rollingFunc = action.payload as string;
      state = opReducerTransformations.updateRollingFunction(state, rollingFunc) as OpState;

      return state;
    }

    case SET_ROLLING_FREQ: {
      const rollingFreq = action.payload as TimeObj;
      state = opReducerTransformations.updateRollingFrequency(state, rollingFreq) as OpState;

      return state;
    }

    case SET_THRESHOLD: {
      const threshold = action.payload as OpThreshold;
      state = opReducerTransformations.updateThreshold(state, threshold) as OpState;

      return state;
    }

    case SET_ENTITY_AGGREGATED: {
      const entityAggregated = action.payload as boolean;
      state = opReducerTransformations.updateEntityAggregated(state, entityAggregated) as OpState;

      return state;
    }

    case SET_START_TRIGGER: {
      const startTrigger = action.payload as OpStartTrigger;
      state.opCreationConfig.opCreationConfigDef.startTrigger = startTrigger;
      delete state.opCreationConfig.opCreationConfigDef.triggerCondition;
      return state;
    }

    case SET_END_TRIGGER: {
      const endTriggerCondition = action.payload as WindowTriggerCondition;
      state.opCreationConfig.opCreationConfigDef.triggerCondition.endTriggerCondition = endTriggerCondition;
      return state;
    }

    case SET_ACTION_CONFIG: {
      const { bizAction, bizActionId } = action.payload as SetActionConfigPayload;
      state.opCreationConfig.bizActions[bizActionId] = bizAction;
      return state;
    }

    case DELETE_ACTION_CONFIG: {
      const bizActionId = action.payload as string;
      delete state.opCreationConfig.bizActions[bizActionId];
      return state;
    }

    case DISABLE_ACTION_CONFIG: {
      const bizActionId = action.payload as string;
      state.opCreationConfig.bizActions[bizActionId].isDisabled = true;
      return state;
    }

    case ENABLE_ACTION_CONFIG: {
      const bizActionId = action.payload as string;
      state.opCreationConfig.bizActions[bizActionId].isDisabled = false;
      return state;
    }

    case SNOOZE_ACTION_CONFIG: {
      const { bizActionId, timeRange } = action.payload as SetActionSnoozePayload;
      if (
        state.opCreationConfig.bizActions[bizActionId].alertActionConfig?.alertActionConfigDef?.alertActionDef
          ?.actionMuteSpec
      ) {
        state.opCreationConfig.bizActions[
          bizActionId
        ].alertActionConfig.alertActionConfigDef.alertActionDef.actionMuteSpec = {
          muteTimeRange: timeRange
        };
      } else {
        state.opCreationConfig.bizActions[bizActionId].alertActionConfig.alertActionConfigDef.alertActionDef = {
          ...state.opCreationConfig.bizActions[bizActionId].alertActionConfig.alertActionConfigDef.alertActionDef,
          actionMuteSpec: {
            muteTimeRange: timeRange
          }
        };
      }
      return state;
    }

    case SNOOZE_SERIES_CONFIG: {
      const { seriesFilters } = action.payload as SetSeriesSnoozePayload;
      state.opCreationConfig.actionEvalSpec = {
        ...(state.opCreationConfig.actionEvalSpec || {}),
        excludeSeriesFilterList: {
          ...(state.opCreationConfig.actionEvalSpec?.excludeSeriesFilterList || {}),
          seriesFilters
        }
      };
      return state;
    }

    case SET_TEMPORARY_ACTIONS: {
      const tempActions = action.payload as Record<string, BizActionConfig>;
      state.temporaryActions = tempActions;
      return state;
    }

    case SET_TEMPORARY_ACTION: {
      const { bizAction, bizActionId } = action.payload as SetActionConfigPayload;
      state.temporaryActions[bizActionId] = bizAction;
      return state;
    }

    case DELETE_TEMPORARY_ACTION: {
      const bizActionId = action.payload as string;
      delete state.temporaryActions[bizActionId];
      return state;
    }

    case SET_COHORT_CONFIG: {
      const cohortConfig = action.payload as CohortConfig;
      const { cohortId } = cohortConfig;
      const context: OpContext = {
        ...state.context,
        cohortDefinition: cohortConfig,
        cohortState: null
      };
      const idProps = getIdProps(context);
      if (state.opCreationConfig?.idProps) {
        state.opCreationConfig.idProps = idProps;
        state.opCreationConfig.opCreationConfigDef.bizDataQuery.cohortId = cohortId;
        if (state.opCreationConfig?.opCreationConfigDef?.bizDataQuery?.buildingBlockConfig) {
          state.opCreationConfig.opCreationConfigDef.bizDataQuery.buildingBlockConfig.cohortId = cohortId;
          if (state.opCreationConfig.opCreationConfigDef.bizDataQuery.buildingBlockConfig.bizIdProps?.secondary) {
            state.opCreationConfig.opCreationConfigDef.bizDataQuery.buildingBlockConfig.bizIdProps.secondary.cohortId =
              cohortId;
          }
        }
      }
      state.context = context;
      return state;
    }

    case RESET_COHORT_CONFIG: {
      const context: OpContext = {
        ...state.context,
        cohortDefinition: getAllCohortDefinition(state.context.entityTypeId)
      };
      delete state.opCreationConfig.opCreationConfigDef.bizDataQuery.cohortId;
      delete state.opCreationConfig.idProps.secondary.cohortId;
      if (state.opCreationConfig.opCreationConfigDef.bizDataQuery.buildingBlockConfig) {
        delete state.opCreationConfig.opCreationConfigDef.bizDataQuery.buildingBlockConfig.cohortId;
        if (state.opCreationConfig.opCreationConfigDef.bizDataQuery.buildingBlockConfig.bizIdProps?.secondary) {
          delete state.opCreationConfig.opCreationConfigDef.bizDataQuery.buildingBlockConfig.bizIdProps.secondary
            .cohortId;
        }
      }
      state.context = context;
      return state;
    }

    case SET_STAGE: {
      const stage = action.payload as Op10zeStage;
      state.opCreationConfig.stage = stage;
      return state;
    }

    case SET_OP10ZE_ID: {
      const operationaliseId = action.payload as string;
      state.op10zeId = operationaliseId;
      return state;
    }

    case SET_SUPPRESSION_CONFIGS: {
      const suppression = action.payload as SuppressionConfigDef[];
      if (state.opCreationConfig.opCreationConfigDef) {
        state.opCreationConfig.opCreationConfigDef.suppression = suppression;
      }
      return state;
    }

    case SET_SIMULATION_CONFIG: {
      const simulationConfig = action.payload as SimulationConfig;
      state.opCreationConfig = simulationConfig.opCreationConfig;
      state.selectedSimulation = simulationConfig;

      if (state.opCreationConfig) {
        const idProps = getIdProps(state.context);
        state.opCreationConfig.idProps = idProps;
      }

      return state;
    }

    case SET_SIMULATION_CONFIG_NAME: {
      const simulationConfigName = action.payload as string;
      state.selectedSimulation.name = simulationConfigName;

      const listEntry = state.simulationConfigs?.find(
        entry => entry.simulationId === state.selectedSimulation.simulationId
      );
      if (listEntry) {
        listEntry.name = simulationConfigName;
      }

      return state;
    }

    case SET_SIMULATIONS: {
      const simulations = action.payload as SimulationConfig[];
      state.simulationConfigs = simulations;
      return state;
    }

    case SET_SIMULATION_STATE: {
      const { simulationId, simulationState } = action.payload as SetSimulationStatePayload;

      state.simulationStateMap[simulationId] = simulationState;
      return state;
    }

    case SET_HISTORY: {
      const history = action.payload as OpCreationConfig["history"];
      state.history = history || {};
      return state;
    }

    case SET_IMPACT_WIDGETS: {
      const impactedWidgets = action.payload as ImpactedWidget[];
      state.opCreationConfig.impactedWidgets = { impactedWidgets };
      return state;
    }

    case SET_DIAGNOSTIC_FIELDS: {
      const slices = action.payload as UserServiceFieldSlice[];
      state.opCreationConfig.diagnosticFields = { slices: slices };
      return state;
    }

    case SET_READ_ONLY: {
      const readOnly = action.payload as boolean;
      state.readOnly = readOnly;
      return state;
    }

    case SET_IS_DRAFT: {
      const isDraft = action.payload as boolean;
      state.isDraft = isDraft;
      return state;
    }

    case SET_PRIMARY_OP_CREATION_CONFIG: {
      const opCreationConfig = action.payload as OpCreationConfig;
      state.primaryOpCreationConfig = opCreationConfig;
      return state;
    }

    case SET_SAVE_OP_CREATION_CONFIG: {
      const opCreationConfig = action.payload as OpCreationConfig;
      state.saveOpCreationConfig = opCreationConfig;
      return state;
    }

    case SET_OP_TRIAGE_CONFIG: {
      const partOpTriageConfig = action.payload as Partial<OpTriageConfig>;
      state.opCreationConfig.impactFields = partOpTriageConfig.impactFields || state.opCreationConfig.impactFields;
      state.opCreationConfig.impactedWidgets =
        partOpTriageConfig.impactedWidgets || state.opCreationConfig.impactedWidgets;
      state.opCreationConfig.diagnosticFields =
        partOpTriageConfig.diagnosticFields || state.opCreationConfig.diagnosticFields;
      return state;
    }

    case UPDATE_ENTITY_FILTERS: {
      const entityFilters = action.payload as ExploreEntityFilter[];
      state.opCreationConfig.opCreationConfigDef.bizDataQuery.entityFilters = entityFilters;
      return state;
    }

    case UPDATE_EVENT_FILTERS: {
      const eventFilters = action.payload as MetricUserServiceFilters;
      state.opCreationConfig.opCreationConfigDef.bizDataQuery.metricUserServiceFilters = eventFilters;
      return state;
    }

    case SET_ACTION_CATEGORIES: {
      const { actionCategories, sourceTypeInfoByCategory } = action.payload as SetActionCategoriesPayload;

      state.actionCategories = actionCategories;
      state.sourceTypeInfoByCategory = sourceTypeInfoByCategory;

      return state;
    }

    case SET_OP_MODE: {
      const mode = action.payload as OpMode;
      state.context.mode = mode;
      return state;
    }

    default:
      return { ...state };
  }
};

setAutoFreeze(false);
export default produce(opReducer);
