import { useCallback } from "react";
import { useFetchDataStatus } from "../../../../../platform/core";
import {
  UserServiceFieldPreviewRequest,
  UserServiceFieldPreviewResponse
} from "../../../../../platform/services/api/entity-mapping";
import { eventMappingApiService } from "../../../../../platform/services/api/event-mapping";

export const useFetchTransformationPreview = () => {
  const { data, isFetching, isSuccess, isError, setStatus } = useFetchDataStatus<UserServiceFieldPreviewResponse>();

  const fetchPreview = useCallback(
    async (configuredStreamId: string, payload: UserServiceFieldPreviewRequest, start: number, end: number) => {
      try {
        setStatus(null, true, false, false, null);
        const resp = await eventMappingApiService.getDataTransformationPreview(configuredStreamId, payload, start, end);
        if (resp) {
          setStatus(resp, false, true, false, null);
        } else {
          setStatus(null, false, false, true, null);
        }
      } catch (error) {
        setStatus(null, false, false, true, error);
      }
    },
    [setStatus]
  );

  return {
    data,
    isFetching,
    isSuccess,
    isError,
    fetchPreview
  };
};
