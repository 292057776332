import React, { useState, useMemo, useCallback } from "react";
import { IncModal, IncRadioButton, IncTextfield } from "@inception/ui";
import { IncButtonColor } from "@inception/ui/src/components/Button/Button";
import { startCase } from "lodash";
import { LimitSpecFunction } from "../../../../../../services/api/explore";
import { VerticallyCenteredRow } from "../../../../../../components";
import { MAX_INT32 } from "../../../../../../utils";

interface Props {
  show: boolean;
  defaultFunction: LimitSpecFunction;
  initialValue: number;
  onClose: () => void;
  onConfirmClick: (func: LimitSpecFunction, value: number) => void;
}

export const DownloadCSVModel: React.FC<Props> = (props: Props) => {
  const { show, defaultFunction, initialValue, onClose, onConfirmClick } = props;
  const [value, setValue] = useState(initialValue || "");
  const [selectedRadioBtn, setSelectedRadioBtn] = useState<"custom" | "all">("custom");

  const actions = useMemo(() => {
    const intVal = selectedRadioBtn === "custom" ? Number(value) : MAX_INT32;
    return {
      primary: {
        id: "common.actions.confirm",
        onClick: () => onConfirmClick(defaultFunction, intVal),
        color: "primary" as IncButtonColor
      },
      secondary: {
        id: "common.actions.close",
        onClick: () => onClose(),
        color: "link" as IncButtonColor
      }
    };
  }, [defaultFunction, onClose, onConfirmClick, selectedRadioBtn, value]);

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
  };

  const labelContainer = useMemo(
    () => (
      <VerticallyCenteredRow>
        <span>{startCase(defaultFunction)}</span>
        <IncTextfield
          className="textfield marginLt8"
          onChange={onValueChange}
          type={"number"}
          value={value}
        />
      </VerticallyCenteredRow>
    ),
    [defaultFunction, value]
  );

  const onCustomRadioBtnClicked = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setSelectedRadioBtn("custom");
    }
  }, []);

  const onAllClick = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setSelectedRadioBtn("all");
    }
  }, []);

  return (
    <IncModal
      actions={actions}
      onClose={onClose}
      show={show}
      size="xsm"
      titleText="Select"
    >
      <VerticallyCenteredRow>
        <IncRadioButton
          checked={selectedRadioBtn === "custom"}
          label={labelContainer}
          onChange={onCustomRadioBtnClicked}
        />
        <IncRadioButton
          checked={selectedRadioBtn === "all"}
          className="marginLt16"
          label="All"
          onChange={onAllClick}
        />
      </VerticallyCenteredRow>
    </IncModal>
  );
};
