import React, { FC, useState, useMemo, useCallback, useRef } from "react";
import { cloneDeep } from "lodash";
import { IncButton, IncFaIcon, IncModal, IncModalProps, IncPopConfirm, IncSmartText, IncToolTip } from "@inception/ui";
import { FieldPickerContextDTO, Workflow } from "../../services/api/explore";
import { generateId, logger, useNotifications, useRefState, useToggleState } from "../../core";
import { operationaliseV2ApiService } from "../../services/api/operationalise";
import { WorkflowEditor } from "../../../features/alerts/summary/new/components/workflow";
import { VerticallyCenteredRow } from "../flex-components";
import { KPIWorkflowDTO } from "./types";

interface Props {
  kpiId: string;
  eventTypeId: string;
  entityTypeId: string;

  kpiWorkflows: KPIWorkflowDTO[];
  onChange: (workflows: KPIWorkflowDTO[]) => void;
}

export const KPIWorkflowsEditor: FC<Props> = props => {
  const { onChange, kpiWorkflows, entityTypeId, eventTypeId, kpiId } = props;

  const { notifyError } = useNotifications();

  const kpiWorkflowsRef = useRefState(kpiWorkflows);
  const pickerContextDto = useMemo<FieldPickerContextDTO>(
    () => ({
      entityId: entityTypeId ? "" : eventTypeId,
      entityName: "",
      entityType: entityTypeId,
      showFields: true,
      showMetrics: false,
      userServices:
        eventTypeId && entityTypeId
          ? [
              {
                userServiceEntityId: eventTypeId
              }
            ]
          : []
    }),
    [entityTypeId, eventTypeId]
  );

  const editorModeRef = useRef<"add" | "edit">("add");
  const editorMode = editorModeRef.current;

  const [workflowToEdit, setWorkflowToEdit] = useState<Workflow>();

  const onAddWorkflow = useCallback(() => {
    editorModeRef.current = "add";
    setWorkflowToEdit({
      description: "",
      icon: "image",
      id: generateId(),
      labels: {
        kpiId
      },
      name: "New Workflow",
      workflowDef: {}
    });
  }, [kpiId]);

  const onCloneWorkflow = useCallback((workflow: Workflow) => {
    editorModeRef.current = "edit";

    const workflowToClone = cloneDeep(workflow);
    workflowToClone.name = `[CLONE] ${workflowToClone.name}`;
    workflowToClone.id = generateId();
    setWorkflowToEdit(workflowToClone);
  }, []);

  const onEditWorkflow = useCallback((workflow: Workflow) => {
    editorModeRef.current = "edit";
    setWorkflowToEdit(cloneDeep(workflow));
  }, []);

  const {
    isOpen: isRemoveConfirmationOpen,
    open: openRemoveConfirmation,
    close: closeRemoveConfirmation
  } = useToggleState();

  const workflowToRemoveRef = useRef<KPIWorkflowDTO>();
  const onTriggerRemoveKPIWorkflow = useCallback(
    (workflow: KPIWorkflowDTO) => {
      workflowToRemoveRef.current = workflow;
      openRemoveConfirmation();
    },
    [openRemoveConfirmation]
  );

  const [isRemoveWorkflowInProgress, setIsRemoveWorkflowInProgress] = useState(false);
  const onConfirmRemoveWorkflow = useCallback(async () => {
    closeRemoveConfirmation();
    const { workflow, isSavedAsConfig } = workflowToRemoveRef.current || {};
    const workflowId = workflow?.id;

    if (workflowId) {
      const onRemoveSuccess = () => {
        onChange(kpiWorkflowsRef.current.filter(w => w.workflow?.id !== workflowId));
        setIsRemoveWorkflowInProgress(false);
      };

      setIsRemoveWorkflowInProgress(true);
      if (isSavedAsConfig) {
        // Saved workflow
        try {
          const { data, error, message } = await operationaliseV2ApiService.deleteWorkflowForOp(kpiId, workflowId);

          if (error || !data) {
            logger.error("WorkflowsEditor", "Error deleting workflow", message);
            notifyError("Error deleting workflow");
          } else {
            onRemoveSuccess();
          }
        } catch (err) {
          logger.error("WorkflowsEditor", "JS Error deleting workflow", err);
        } finally {
          setIsRemoveWorkflowInProgress(false);
        }
      } else {
        onRemoveSuccess();
      }
    } else {
      closeRemoveConfirmation();
    }
  }, [kpiId, notifyError, onChange, kpiWorkflowsRef, closeRemoveConfirmation]);

  const onCancelRemoveWorkflow = useCallback(() => {
    workflowToRemoveRef.current = undefined;
    closeRemoveConfirmation();
  }, [closeRemoveConfirmation]);

  const workflowCards = useMemo(
    () =>
      kpiWorkflows.map(kpiWorkflow => {
        const { workflow } = kpiWorkflow;

        const { id = generateId(), name = "Workflow Configuration Loading Name Value" } = workflow || {};

        const onRemove = () => !isRemoveWorkflowInProgress && onTriggerRemoveKPIWorkflow(kpiWorkflow);
        const onClone = () => onCloneWorkflow(workflow);
        const onEdit = () => onEditWorkflow(workflow);

        return (
          <VerticallyCenteredRow
            className="workflows-list--entry"
            key={id}
          >
            <IncSmartText
              className="width-100"
              text={name}
            />

            <IncToolTip
              titleText="Edit"
              variant="info"
            >
              <IncFaIcon
                className="paddingLt4 paddingRt4 status-info inc-cursor-pointer"
                iconName="edit"
                onClick={onEdit}
              />
            </IncToolTip>

            <IncToolTip
              titleText="Clone"
              variant="info"
            >
              <IncFaIcon
                className="paddingLt4 paddingRt4 status-info inc-cursor-pointer"
                iconName="clone"
                onClick={onClone}
              />
            </IncToolTip>

            <IncToolTip
              titleText="Remove"
              variant="error"
            >
              <VerticallyCenteredRow>
                <IncPopConfirm
                  buttonProps={{
                    okButton: {
                      text: "Remove",
                      danger: true
                    },
                    cancelButton: {
                      text: "Cancel"
                    }
                  }}
                  onCancel={onCancelRemoveWorkflow}
                  onConfirm={onConfirmRemoveWorkflow}
                  open={isRemoveConfirmationOpen}
                  title="Are you sure you want to remove this workflow?"
                >
                  <IncFaIcon
                    className={`paddingLt4 paddingRt4 status-danger inc-cursor-pointer ${isRemoveWorkflowInProgress ? "disableClick" : ""}`}
                    iconName="trash-alt"
                    onClick={onRemove}
                  />
                </IncPopConfirm>
              </VerticallyCenteredRow>
            </IncToolTip>
          </VerticallyCenteredRow>
        );
      }),
    [
      isRemoveConfirmationOpen,
      isRemoveWorkflowInProgress,
      onCancelRemoveWorkflow,
      onCloneWorkflow,
      onConfirmRemoveWorkflow,
      onEditWorkflow,
      onTriggerRemoveKPIWorkflow,
      kpiWorkflows
    ]
  );

  const onAddOrUpdateWorkflow = useCallback(() => {
    const workflows = kpiWorkflowsRef.current;
    if (editorMode === "edit") {
      onChange(
        workflows.map(kpiWorkflow =>
          kpiWorkflow.workflow?.id === workflowToEdit.id
            ? {
                ...kpiWorkflow,
                workflow: workflowToEdit
              }
            : kpiWorkflow
        )
      );
    } else {
      onChange([
        ...workflows,
        {
          workflow: workflowToEdit,
          isSavedAsConfig: false
        }
      ]);
    }

    setWorkflowToEdit(null);
  }, [editorMode, onChange, workflowToEdit, kpiWorkflowsRef]);

  const onCancelAddOrUpdateWorkflow = useCallback(() => {
    editorModeRef.current = "add";
    setWorkflowToEdit(null);
  }, []);

  const actions = useMemo<IncModalProps["actions"]>(
    () => ({
      primary: {
        onClick: onAddOrUpdateWorkflow,
        label: editorMode === "add" ? "Add" : "Update"
      },
      secondary: {
        onClick: onCancelAddOrUpdateWorkflow,
        label: "Cancel"
      }
    }),
    [editorMode, onAddOrUpdateWorkflow, onCancelAddOrUpdateWorkflow]
  );

  return (
    <div className="workflows-list">
      {workflowCards}

      <IncButton
        color="link"
        iconName="plus-circle"
        label="Add"
        onClick={onAddWorkflow}
      />

      {Boolean(workflowToEdit) && (
        <IncModal
          actions={actions}
          className="inc-regular-modal"
          onClose={onCancelAddOrUpdateWorkflow}
          show
          showClose
          size="xlg"
          titleText={editorMode === "add" ? "Add Workflow" : "Edit Workflow"}
        >
          <WorkflowEditor
            isError={false}
            isFetching={false}
            onChangeWorkflow={setWorkflowToEdit}
            pickerContextDto={pickerContextDto}
            workflow={workflowToEdit}
          />
        </IncModal>
      )}
    </div>
  );
};
