import {
  IncFaIconName,
  IncGenericIcon,
  IncTextfield,
  IncSkeleton,
  IncToolTip,
  IncSmartText,
  IncButton,
  IncFaIcon,
  IncCheckbox
} from "@inception/ui";
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CypressConstants } from "@bicycle/tests";
import { logger, useTimeRange } from "../../core";
import { FieldPickerModel, useFieldPicker } from "../../field-picker";
import {
  BizEntityType,
  compareUSFields,
  FieldPickerContextDTO,
  useFetchExploreBizEntityTypes,
  UserServiceField,
  UserServiceTuple
} from "../../services/api/explore";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { dataTypeManager, isCohortField } from "../../utils";
import { VerticallyCenteredRow } from "../flex-components";
import { ALL_USERSERVICES_ENTITY_TYPE_ID } from "../../utils/ExploreUtils";

interface FieldPickerPopUpContentProps {
  eventId?: string;
  entityTypeId?: string;
  userServices?: UserServiceTuple[];

  onSelect: (field: UserServiceField) => void;
  multiSelectionProps?: {
    selectedFields: UserServiceField[];
    onSelectionChange: (fields: UserServiceField[]) => void;
  };

  excludeAllUserServiceField?: boolean;
  containerClassName?: string;
  labelClassName?: string;

  label?: string;
  skipUserServiceName?: boolean;
  skipCohortFields?: boolean;
  isMetaLoading?: boolean;
  skipEntityIcons?: boolean;

  fieldPickerModel?: FieldPickerModel;
  onFieldsFetched?: (model: FieldPickerModel) => void;

  bizEntityTypes?: BizEntityType[];
  onBizEntityTypesFetched?: (beTypes: BizEntityType[]) => void;

  children?: JSX.Element;
}

export const FieldPickerPopUpContent = (props: FieldPickerPopUpContentProps) => {
  const {
    isMetaLoading,
    entityTypeId,
    eventId,
    containerClassName,
    labelClassName,
    onSelect,
    label,
    excludeAllUserServiceField,
    skipUserServiceName,
    skipCohortFields,
    userServices,
    fieldPickerModel: defFieldPickerModel,
    onFieldsFetched,
    bizEntityTypes: defBizEntityTypes,
    onBizEntityTypesFetched,
    skipEntityIcons = false,
    children,
    multiSelectionProps
  } = props;

  const isMulti = Boolean(multiSelectionProps);
  const { onSelectionChange, selectedFields = [] } = multiSelectionProps || {};

  const [searchText, setSearchText] = useState("");

  const {
    data: fieldsData,
    getFields,
    error: fieldsError,
    isError: isFieldsError,
    isFetching: isFieldsFetching
  } = useFieldPicker();

  useEffect(() => {
    if (isFieldsError) {
      logger.error("FieldPickerPopUpV2", "Failed to fetch fields", fieldsError);
    }
  }, [fieldsError, isFieldsError]);

  const isFieldsFetchingRef = useRef(!defFieldPickerModel);
  const fieldPickerModel = defFieldPickerModel || fieldsData;
  const eventTypeNameMap = useMemo(() => {
    const eventTypesInfo = fieldPickerModel?.getAllUserservices() || [];
    return eventTypesInfo.reduce(
      (map, info) => {
        map[info.entityId] = info.name;
        return map;
      },
      {} as Record<string, string>
    );
  }, [fieldPickerModel]);

  const fieldsExist = Boolean(fieldPickerModel);

  const { timeRange } = useTimeRange();

  const fieldPickerContext = useMemo<FieldPickerContextDTO>(
    () => ({
      entityId: eventId || "",
      entityType: excludeAllUserServiceField ? entityTypeId : entityTypeId || ALL_USERSERVICES_ENTITY_TYPE_ID,
      entityName: "",
      showFields: true,
      userServices: userServices || []
    }),
    [entityTypeId, eventId, excludeAllUserServiceField, userServices]
  );

  useEffect(() => {
    if (!fieldsExist && !isMetaLoading) {
      const { fromMillis, toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);
      getFields(fieldPickerContext, fromMillis, toMillis);

      setTimeout(() => (isFieldsFetchingRef.current = false));
    }
  }, [fieldPickerContext, fieldsExist, getFields, isMetaLoading, timeRange]);

  useEffect(() => {
    if (onFieldsFetched && fieldsExist) {
      onFieldsFetched(fieldPickerModel);
    }
  }, [fieldPickerModel, fieldsExist, onFieldsFetched]);

  const {
    bizEntityTypes: fetchedBETypes,
    error: beTypeFetchError,
    isFetching: isBETypesFetching,
    fetchBizEntityList,
    isError: isBETypesFetchError
  } = useFetchExploreBizEntityTypes();

  const isBETypesFetchingRef = useRef(!defBizEntityTypes);
  const bizEntityTypes = defBizEntityTypes || fetchedBETypes;

  const beTypesExist = Boolean(bizEntityTypes.length) && !isBETypesFetching;

  useEffect(() => {
    if (!skipEntityIcons && !defBizEntityTypes) {
      fetchBizEntityList();

      setTimeout(() => (isBETypesFetchingRef.current = false));
    }
  }, [defBizEntityTypes, fetchBizEntityList, skipEntityIcons]);

  useEffect(() => {
    if (isBETypesFetchError) {
      logger.error("FieldPickerV2", "Error fetching Biz Entity Types", beTypeFetchError);
    }
  }, [beTypeFetchError, isBETypesFetchError]);

  useEffect(() => {
    if (onBizEntityTypesFetched && beTypesExist) {
      onBizEntityTypesFetched(bizEntityTypes);
    }
  }, [beTypesExist, bizEntityTypes, onBizEntityTypesFetched]);

  const fieldPickerOptions = useMemo(() => {
    if (fieldPickerModel) {
      let fields = fieldPickerModel.getAllUserServiceFieldsTableData();
      if (skipCohortFields) {
        fields = fields.filter(x => !isCohortField(x.field));
      }

      if (searchText) {
        fields = fields.filter(x => x.fieldLabel.toLowerCase().includes(searchText.toLowerCase()));
        fields = fields.sort((a, b) => {
          const aStartsWith = a.fieldLabel.toLowerCase().startsWith(searchText.toLowerCase());
          const bStartsWith = b.fieldLabel.toLowerCase().startsWith(searchText.toLowerCase());

          if (aStartsWith && !bStartsWith) {
            return -1;
          } else if (!aStartsWith && bStartsWith) {
            return 1;
          }
          return 0;
        });
      }

      fields = fields.sort((a, b) => a.fieldLabel.toLowerCase().localeCompare(b.fieldLabel.toLowerCase()));

      return fields;
    }

    return [];
  }, [fieldPickerModel, skipCohortFields, searchText]);

  const bizEntityTypeToIcons = useMemo(
    () =>
      (bizEntityTypes || []).reduce(
        (acc, beType) => {
          const { entityTypeId, metadata } = beType;

          const iconName = (metadata?.icon || "image") as IncFaIconName;
          const style: CSSProperties = metadata?.iconColor
            ? {
                color: metadata.iconColor,
                fill: metadata.iconColor
              }
            : {};

          const iconJsx = (
            <VerticallyCenteredRow
              className="inc-flex-center"
              style={{ width: 16 }}
            >
              <IncGenericIcon
                iconName={iconName}
                size={14}
                style={style}
              />
            </VerticallyCenteredRow>
          );

          acc[entityTypeId] = iconJsx;

          return acc;
        },
        {} as Record<string, JSX.Element>
      ),
    [bizEntityTypes]
  );

  const onSearchTextChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchText(value);
  }, []);

  const fieldPickerOptionsJsx = useMemo(
    () =>
      fieldPickerOptions.map((fieldPickerOpt, idx) => {
        const { field, fieldLabel } = fieldPickerOpt;

        const { dataType, entityField, userServices } = field || {};

        const isEntity = entityField?.propType && entityField?.propType === "NA" && !entityField?.relNames?.length;
        const entityTypeId = entityField?.entityType;

        const eventTypeNames =
          userServices?.map(x => eventTypeNameMap[x.userServiceEntityId] || x.userServiceEntityId) || [];
        const toolTipEventTypesStr = eventTypeNames.join(", ");
        const displayEventTypesStr =
          eventTypeNames.slice(0, 2).join(", ") +
          (eventTypeNames.length > 2 ? `+ ${eventTypeNames.length - 2} more` : "");

        let dataTypeIcon = isEntity ? bizEntityTypeToIcons[entityTypeId] : null;
        if (!dataTypeIcon) {
          const appliedDataType =
            entityField?.propType && entityField?.propType !== "NA" ? entityField.propType : dataType;
          dataTypeIcon = dataTypeManager.getDataTypeDescriptor(appliedDataType).getIcon({ height: 16 });
        }

        const isSelected = selectedFields.some(selUsField => compareUSFields(selUsField, field));
        const onClick = (e: React.MouseEvent) => {
          e.stopPropagation();

          if (!isMulti) {
            onSelect(field);
          } else if (onSelectionChange) {
            const nextSelection = isSelected
              ? selectedFields.filter(selUsField => !compareUSFields(selUsField, field))
              : [...selectedFields, field];
            onSelectionChange(nextSelection);
          }
        };
        const key = `field-item-${fieldLabel}-${idx}`;
        return (
          <VerticallyCenteredRow
            className="field-item flex-gap-6 width-100"
            key={key}
            onClick={onClick}
          >
            {isMulti && <IncCheckbox checked={isSelected} />}
            {dataTypeIcon}
            <IncSmartText
              className="inc-text-subtext marginRtAuto"
              style={!skipUserServiceName ? { width: "calc(100% - 100px - 16px)" } : undefined}
              text={fieldLabel}
            />

            {!skipUserServiceName && Boolean(displayEventTypesStr) && (
              <IncToolTip
                showArrow
                titleText={toolTipEventTypesStr}
              >
                <IncSmartText
                  className="inc-text-element-medium inc-text-inactive text-right"
                  style={{ width: 100 }}
                  text={displayEventTypesStr}
                />
              </IncToolTip>
            )}
          </VerticallyCenteredRow>
        );
      }),
    [
      bizEntityTypeToIcons,
      eventTypeNameMap,
      fieldPickerOptions,
      isMulti,
      onSelect,
      onSelectionChange,
      selectedFields,
      skipUserServiceName
    ]
  );

  const isFieldsLoading = isFieldsFetching || isFieldsFetchingRef.current;
  const isBizEntityTypesLoading = isBETypesFetching || isBETypesFetchingRef.current;
  const isLoading = isFieldsLoading || isBizEntityTypesLoading || isMetaLoading;

  const mockUserServiceField = useMemo(
    () =>
      ({
        userServices: [],
        fieldName: `${searchText}`,
        dataType: "STRING",
        entityField: {
          entityType: "",
          relNames: [],
          propType: "NA",
          propName: ""
        },
        bizEntityFieldName: "userService$i_userService",
        displayBizEntityFieldName: "userService",
        allUserService: false,
        isMappingIncomplete: false,
        isAvailableInTenantContext: false,
        rawFieldName: "",
        isListDataType: false
      }) as UserServiceField,
    [searchText]
  );

  return (
    <div className={`field-picker-pop-up-v2 inc-flex-column flex-gap-12 ${containerClassName}`}>
      <div className="upper-section inc-flex-column flex-gap-8">
        {Boolean(label) && (
          <div className={labelClassName}>
            <span className="marginRt4">{label}</span>
          </div>
        )}
        <IncTextfield
          autoFocus
          data-cy={CypressConstants.components.KPI.FieldPickerPopUpContent.attributes.FieldPickerSearch}
          onChange={onSearchTextChange}
          placeholder={"Search"}
          startIcon="magnifying-glass"
          value={searchText}
        />
        {isLoading && (
          <IncSkeleton
            active
            paragraph={{ rows: 6 }}
          />
        )}
        {!isLoading && (
          <>
            {isFieldsError && (
              <VerticallyCenteredRow
                className="status-danger inc-text-subtext-semibold flex-gap-8"
                data-cy={CypressConstants.components.KPI.FieldPickerPopUpContent.attributes.FieldPickerError}
              >
                <IncFaIcon iconName="exclamation-triangle" />
                Error while fetching fields
              </VerticallyCenteredRow>
            )}
            {!isFieldsError && (
              <div className="field-items-container inc-flex-column flex-gap-12 paddingRt4 paddingBt12">
                {fieldPickerOptionsJsx}
                {!fieldPickerOptionsJsx.length && (
                  <IncButton
                    color="link"
                    label={searchText}
                    onClick={() => onSelect(mockUserServiceField)}
                  />
                )}
              </div>
            )}
            {children}
          </>
        )}
      </div>
    </div>
  );
};
