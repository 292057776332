import React, { useCallback, useMemo } from "react";
import { IncCheckbox } from "@inception/ui";
import { OpAnalysisType } from "../../services/api/operationalise";
import { VerticallyCenteredRow } from "../../components";
import { setAnalysisTypes, useOpAnalysisStore } from "./context";

export const AnalysisTypesEditor = () => {
  const { dispatch, state } = useOpAnalysisStore();
  const { opAnalysisImpl } = state;
  const analysisTypes = useMemo(() => opAnalysisImpl.getAnalysisTypes(), [opAnalysisImpl]);

  const onChange = useCallback(
    (analysisType: OpAnalysisType, checked: boolean) => {
      if (checked) {
        dispatch(setAnalysisTypes([...analysisTypes, analysisType]));
      } else {
        dispatch(setAnalysisTypes(analysisTypes.filter(x => x !== analysisType)));
      }
    },
    [analysisTypes, dispatch]
  );

  return (
    <div className="inc-flex-column flex-gap-4">
      <VerticallyCenteredRow className="flex-gap-12">
        <span className="inc-text-body">Analysis Type: </span>
        <IncCheckbox
          checked={analysisTypes.includes(OpAnalysisType.MULTI_DIM)}
          label={"Multi Dimensional Analysis"}
          labelProps={{ placement: "end" }}
          onChange={(e, checked: boolean) => onChange(OpAnalysisType.MULTI_DIM, checked)}
        />
        <IncCheckbox
          checked={analysisTypes.includes(OpAnalysisType.TRENDS)}
          label={"Trend Analysis"}
          labelProps={{ placement: "end" }}
          onChange={(e, checked: boolean) => onChange(OpAnalysisType.TRENDS, checked)}
        />
      </VerticallyCenteredRow>
      {analysisTypes.length === 0 && (
        <span className="inc-text-subtext status-danger">Atleast one analysis type needs to be selected</span>
      )}
    </div>
  );
};
