import { IncSelectOption } from "@inception/ui";

export type TimeIntervalOption = IncSelectOption<number>;

export const twoMonthOpt: TimeIntervalOption = {
  label: "Last 2 months",
  value: "now-56d",
  data: 6 * 60 * 60 * 1000 // 6 hrs
};

export const oneMonthOpt: TimeIntervalOption = {
  label: "Last month",
  value: "now-28d",
  data: 6 * 60 * 60 * 1000 // 6 hrs
};

export const oneWeekOpt: TimeIntervalOption = {
  label: "Last week",
  value: "now-1w",
  data: 60 * 60 * 1000 // 1 hrs
};

export const oneDayOpt: TimeIntervalOption = {
  label: "Last day",
  value: "now-1d",
  data: 60 * 60 * 1000 // 1 hr
};

export const twelveHoursOpt: TimeIntervalOption = {
  label: "Last 12 hours",
  value: "now-12h",
  data: 30 * 60 * 1000 // 30 mins
};

export const sixHoursOpt: TimeIntervalOption = {
  label: "Last 6 hours",
  value: "now-6h",
  data: 15 * 60 * 1000 // 15 mins
};

export const threeHoursOpt: TimeIntervalOption = {
  label: "Last 3 hours",
  value: "now-3h",
  data: 10 * 60 * 1000 // 10 min
};

export const oneHourOpt: TimeIntervalOption = {
  label: "Last 1 hour",
  value: "now-1h",
  data: 5 * 60 * 1000 // 5 min
};

export const timeIntervalOptions = [
  twoMonthOpt,
  oneMonthOpt,
  oneWeekOpt,
  oneDayOpt,
  twelveHoursOpt,
  sixHoursOpt,
  threeHoursOpt,
  oneHourOpt
];
