import { IncRTable, TableDataColumn, IncSmartText } from "@inception/ui";
import { orderBy } from "lodash";
import React, { useCallback, useMemo } from "react";
import { EntityPropertyValue } from "../../../platform/core";
import {
  DataTransformationPreviewResponse,
  TransformedValue
} from "../../../platform/services/api/auto-discovery/types";

interface DataTransformationPreviewProps {
  previewDataResponse: DataTransformationPreviewResponse;
}

export const DataTransformationPreviewTable: React.FC<DataTransformationPreviewProps> = props => {
  const {
    previewDataResponse: { previewData }
  } = props;

  const getRawFieldValues = useCallback((rawFieldValues: Record<string, EntityPropertyValue>) => {
    const values = Object.values(rawFieldValues).map(rawFieldValue => {
      const key = rawFieldValue ? (Object.keys(rawFieldValue)[0] as keyof EntityPropertyValue) : null;
      return key ? rawFieldValue[key].toString() : "";
    });
    return values.join(", ");
  }, []);

  const uniqePreviewResponses = useMemo(() => {
    const inputFields: Set<string> = new Set();
    const responsesToDisplay = previewData?.transformedValues?.filter(data => {
      const rawFieldValues = getRawFieldValues(data.rawFieldValues);
      const shouldDisplay = !inputFields.has(rawFieldValues);
      if (shouldDisplay) {
        inputFields.add(rawFieldValues);
      }
      return shouldDisplay;
    });
    return orderBy(responsesToDisplay, rep => getRawFieldValues(rep.rawFieldValues));
  }, [getRawFieldValues, previewData]);

  const cols = useMemo(() => {
    const tableCols: Array<TableDataColumn<TransformedValue>> = [
      {
        accessor: "rawFieldValues",
        header: "Incoming Value",
        renderer: celldata => <IncSmartText text={getRawFieldValues(celldata)} />
      },
      {
        accessor: "transformedValue",
        header: "Transformed Value",
        renderer: (celldata, rowdata) => {
          const { error, transformedValue } = rowdata;
          const key = transformedValue ? (Object.keys(transformedValue)[0] as keyof EntityPropertyValue) : null;
          const value = key ? transformedValue[key].toString() : "";
          const className = error ? "status-danger" : "";
          return (
            <IncSmartText
              text={error ? error : value}
              textClass={className}
            />
          );
          // return <span className={className}>{error ? error : value}</span>;
        }
      }
    ];
    return tableCols;
  }, [getRawFieldValues]);

  return (
    <>
      {previewData ? (
        <IncRTable
          columns={cols}
          data={uniqePreviewResponses}
          pagination={{
            enabled: true,
            pageSize: 10
          }}
        />
      ) : (
        <div>No data found</div>
      )}
    </>
  );
};
