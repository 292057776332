import { AxiosError } from "axios";
import { useCallback, useMemo } from "react";
import { FetchCallBackResult, FetchFn, InitialState, useDataFetch } from "../../../../core";
import commonAlertsApiService, { CommonAlertsResponseItem } from "../../../../services/api/CommonAlerts";

export const useFetchAlertResults = (
  op10zeId: string,
  startTimeMillis: number,
  endTimeMillis: number,
  simulationId = "",
  additionalData = false,
  lookupData = true
) => {
  const initialState = useMemo<InitialState<CommonAlertsResponseItem[], string>>(
    () => ({
      data: [],
      error: "",
      isError: false,
      isFetching: true,
      isSuccess: false
    }),
    []
  );

  const fetchFn = useCallback<FetchFn<CommonAlertsResponseItem[], string>>(async () => {
    const result: FetchCallBackResult<CommonAlertsResponseItem[], string> = {
      data: [],
      error: "",
      isError: false,
      isSuccess: false
    };

    try {
      const { alertResponse } = await commonAlertsApiService.fetchAlertResults(
        op10zeId,
        startTimeMillis,
        endTimeMillis,
        simulationId,
        additionalData,
        lookupData
      );

      result.isSuccess = true;
      result.data = alertResponse.alertEventRecords || [];
    } catch (err) {
      result.isError = true;

      const axiosErr = err as AxiosError;
      const { isAxiosError } = axiosErr;

      if (isAxiosError) {
        result.error = axiosErr.response?.statusText || axiosErr.message || "Error fetching alerts";
      } else {
        result.error = err.message || "Error fetching alerts";
      }
    }

    return result;
  }, [additionalData, endTimeMillis, lookupData, op10zeId, simulationId, startTimeMillis]);

  return useDataFetch(fetchFn, initialState);
};
