import {
  IncFaIconName,
  IncSmartText,
  AutoExpandContent,
  IncButton,
  IncFaIcon,
  IncGenericIcon,
  ISaxIcon
} from "@inception/ui";
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { isNil } from "lodash";
import { cx } from "emotion";
import {
  VerticallyCenteredRow,
  IncTabBar,
  IncTab,
  LoadingSpinner,
  GenericHighchartsRenderer,
  MarkdownRenderer
} from "../../components";
import { Tab } from "../../components/Tabs/TabBar";
import {
  ConversationFragment,
  ConversationStatus,
  FunnelTriagePayload,
  StepOutput,
  VizOption,
  ConversationResponse,
  ConversationFragmentType,
  ParameterValue
} from "../../services/api/chat";
import { featureFlagService, FEATURE_FLAGS } from "../../services/feature-flags";
import { OpCreationConfig } from "../../services/api/operationalise";
import { logger, useTimeRange, useToggleState } from "../../core";
import { CorrelatedEventsTimeline, TriageTableDrillDown } from "../../components/triage";
import {
  DemoDataParams,
  TriageClustersResponse,
  useFetchAlertsSummary,
  WidgetAlertsSummaryResponseV3,
  WidgetAlertSummaryRequest2
} from "../../services/api/explore";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { JourneyFunnelRenderer } from "../../components/triage/journey-funnel/JourneyFunnelRenderer";
import { PlanEditor } from "../../recipe/plan-generation/PlanEditor";
import { PlanExecutionRenderer } from "../../recipe/plan-generation/PlanExecutionRenderer";
import { InitialFragmentRenderer } from "../../components/fragments";
import { TypingAnimation } from "./TypingAnimation";
import { RawDataRenderer } from "./RawDataRenderer";
import { MemoizedChatBotVizRenderer } from "./VizRenderer";

interface Props {
  fragment: ConversationFragment;
  title?: string;
  subTitle?: string;
  onOperationalize?: (opCreationConfig: OpCreationConfig) => void;
  demoDataParams: DemoDataParams;
}

export const StepsRenderer: FC<Props> = props => {
  const { fragment, onOperationalize, demoDataParams } = props;

  const { fragment: fragmentData } = fragment;

  const stepOutput = fragmentData?.agentOutput?.stepOutput;

  const [selectedTabId, setSelectedTabId] = useState<string | number>(stepOutput[stepOutput?.length - 1]?.stepName);

  const showTabs = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.enableDebugMode);

  const tabs: IncTab[] = useMemo(
    () =>
      stepOutput.map(key => ({
        label: key.stepName,
        id: key.stepName,
        iconName: key.icon
      })),
    [stepOutput]
  );

  const tabRender = useCallback(
    (tab: Tab) => (
      <VerticallyCenteredRow className="flex flex-gap-6 inc-text-subtext-medium">
        <IncFaIcon iconName={tab.iconName as IncFaIconName} />
        <IncSmartText text={tab.label} />
      </VerticallyCenteredRow>
    ),
    []
  );

  const selectedIndex = stepOutput.findIndex(step => step.stepName === selectedTabId);

  const [responseJSX] = useMemo(
    () =>
      getResponseJsx(
        stepOutput?.[selectedIndex]?.conversationResponse,
        "",
        selectedIndex.toString(),
        demoDataParams,
        onOperationalize
      ),
    [demoDataParams, onOperationalize, selectedIndex, stepOutput]
  );

  return (
    <div>
      {showTabs && (
        <VerticallyCenteredRow
          className="marginBt6 flex-gap-8"
          style={{ overflowX: "auto" }}
        >
          <IncTabBar
            disableRouteChange
            handleSelection={setSelectedTabId}
            selected={selectedTabId}
            tabRenderer={tabRender}
            tabs={tabs}
          />
        </VerticallyCenteredRow>
      )}
      <div className="marginBt12 marginTp10">{responseJSX}</div>
    </div>
  );
};

export const GeneratingResponseRenderer: FC<Props> = props => {
  const { fragment, title, onOperationalize, demoDataParams } = props;
  const { fragment: fragmentData } = fragment;
  const stepOutputs = fragmentData?.agentOutput?.stepOutput;
  const stepsData = useMemo(() => {
    if (stepOutputs) {
      return stepOutputs.map(stepOutput => ({
        name: stepOutput?.stepName,
        iconName: stepOutput?.icon,
        conversationResponse: stepOutput?.conversationResponse,
        stepOutput
      }));
    }
    return [];
  }, [stepOutputs]);

  const responseJSX = useCallback(
    (response: ConversationResponse, userMessage: string, key: string) =>
      getResponseJsx(response, userMessage, key, demoDataParams, onOperationalize),
    [demoDataParams, onOperationalize]
  );

  return (
    <VerticallyCenteredRow
      className="marginBt6 inc-flex-column width-100"
      style={{ height: "fit-content" }}
    >
      <div className="inc-text-header-regular marginBt16 inc-flex-self-start">{title || ""}</div>

      <div className="inc-flex-column suggestions inc-flex-self-start width-100">
        {!stepsData?.length && (
          <div className="dialogue-text width-fit-content">
            <TypingAnimation className="typing-loader" />
          </div>
        )}
        {stepsData.map((step, idx) => {
          const { conversationResponse, name, iconName, stepOutput } = step;

          if (idx === stepsData.length - 1) {
            return (
              <div
                className="marginBt16 marginTp16"
                key={idx}
              >
                <VerticallyCenteredRow
                  className="marginBt4 inc-flex-self-start flex-gap-8"
                  key={idx}
                >
                  <div className="marginLt8">
                    <LoadingSpinner titleText=" " />
                  </div>

                  {name}
                </VerticallyCenteredRow>
                <div className="marginLt32">{responseJSX(conversationResponse, "", idx.toString())}</div>
              </div>
            );
          } else {
            return (
              <>
                {Boolean(stepOutput) && (
                  <AccordionItems
                    demoDataParams={demoDataParams}
                    index={idx}
                    isInProgress
                    key={idx}
                    onOperationalize={onOperationalize}
                    stepOutput={stepOutput}
                    totalItems={stepsData.length}
                  />
                )}
                {!stepOutput && (
                  <VerticallyCenteredRow
                    className="recommendation-pill marginBt16 inc-flex-self-start"
                    key={idx}
                    style={{ background: "rgba(59, 180, 67, 0.16)" }}
                  >
                    <div className="marginRt8">
                      <IncFaIcon iconName={iconName as IncFaIconName} />
                    </div>
                    {name}
                    <div
                      className="marginLt8"
                      style={{ color: "#3bb443" }}
                    >
                      <IncFaIcon iconName="check" />
                    </div>
                  </VerticallyCenteredRow>
                )}
              </>
            );
          }
        })}
      </div>
    </VerticallyCenteredRow>
  );
};

interface AccordionItemProp {
  stepOutput: StepOutput;
  index: number;
  totalItems: number;
  onOperationalize?: (opCreationConfig: OpCreationConfig) => void;
  demoDataParams: DemoDataParams;
  isInProgress?: boolean;
}

const AccordionItems: FC<AccordionItemProp> = props => {
  const { index, onOperationalize, stepOutput: item, totalItems, demoDataParams, isInProgress = false } = props;

  const { isOpen, toggle } = useToggleState();

  const { conversationResponse, icon, stepName } = item || {};

  const [responseJSX] = useMemo(
    () =>
      getResponseJsx(
        conversationResponse,
        "",
        index.toString(),
        demoDataParams,
        onOperationalize,
        null,
        null,
        null,
        null,
        true
      ),
    [conversationResponse, demoDataParams, index, onOperationalize]
  );

  const cxName =
    index === totalItems - 1
      ? "inc-flex-column flex-gap-8 accordion-last-item"
      : "inc-flex-column flex-gap-8 accordion-item";
  return (
    <div className={cxName}>
      <div
        className="inc-flex-row flex inc-flex-space-contents width-100"
        onClick={() => toggle()}
      >
        <VerticallyCenteredRow className="flex-gap-8">
          {isInProgress && (
            <ISaxIcon
              color="#3bb443"
              iconName="TickCircle"
              variant="Outline"
            />
          )}
          <IncGenericIcon iconName={icon as IncFaIconName} />
          {stepName}
        </VerticallyCenteredRow>
        <IncFaIcon
          className="collapse-arrow"
          data-expanded={isOpen}
          iconName="angle-down"
        />
      </div>
      {isOpen && responseJSX}
    </div>
  );
};

export const ResponseRenderer: FC<Props> = props => {
  const { fragment, title, subTitle, onOperationalize, demoDataParams } = props;
  const { fragment: fragmentData, uiExtraInfo } = fragment;

  const stepOutput = fragmentData?.agentOutput?.stepOutput;
  const defaultOpen = uiExtraInfo?.defaultOpen === true || false;

  const { isOpen, toggle } = useToggleState(defaultOpen);

  const cxName = isOpen
    ? "inc-flex-row flex inc-flex-space-contents paddingRt8 paddingLt8 accordion-header"
    : "inc-flex-row flex inc-flex-space-contents paddingRt8 paddingLt8 accordion-curved-header";

  return (
    <VerticallyCenteredRow
      className="marginBt16 inc-flex-column"
      style={{ height: "fit-content" }}
    >
      <div className="inc-text-header-regular marginBt10 inc-flex-self-start">{title || ""}</div>
      <div className="inc-text-body marginBt16 inc-flex-self-start inc-text-inactive">{subTitle || ""}</div>
      <div
        className={cxName}
        onClick={() => toggle()}
      >
        {"See how Bicycle generated this response."}
        <IncFaIcon
          className="collapse-arrow"
          data-expanded={isOpen}
          iconName="angle-down"
        />
      </div>
      {isOpen &&
        Boolean(stepOutput?.length) &&
        stepOutput.map((item, idx) => (
          <AccordionItems
            demoDataParams={demoDataParams}
            index={idx}
            key={idx}
            onOperationalize={onOperationalize}
            stepOutput={item}
            totalItems={stepOutput?.length}
          />
        ))}
    </VerticallyCenteredRow>
  );
};

export const getResponseJsx = (
  response: ConversationResponse,
  userMessage?: string,
  key?: string,
  demoDataParams?: DemoDataParams,
  onOperationalize?: (opCreationConfig: OpCreationConfig) => void,
  onApplyRecommendation?: (recommendation: string) => void,
  opConfigId?: string,
  incidentId?: string,
  isHighlightTable?: boolean,
  neverRenderInCard?: boolean,
  onImplementPlan?: (planId: string, paramValues: ParameterValue[]) => void,
  chatId = "",
  hideFoooterActions = false,
  useParentWidth = false
) => {
  if (response) {
    const {
      fragment: fragments = [],
      // planExecutionResponse = {},
      status = ConversationStatus.NA,
      message = "",
      recommendations = [],
      title: cardTitle = "",
      messageId = ""
    } = response;

    let recommendationsHandled = false;

    const getFragmentJsxUtil = (fragment: ConversationFragment, idx: number) => {
      const chKey = [key, "fragment", idx].join("-");
      let className = "";
      let children: string | JSX.Element = "";
      let renderInCard = !neverRenderInCard;

      const { fragment: fragmentData, type, title, responseInfo } = fragment;

      if (type === ConversationFragmentType.NA) {
        const errors = (responseInfo?.errors || []).map(err => err.message);
        const isError = Boolean(errors.length);

        const message = errors.length ? errors.join("\n") : fragmentData?.response || "Unknown response";

        className = isError ? "status-danger" : "";
        children = message;
      } else if (type === ConversationFragmentType.MARKDOWN) {
        renderInCard = false;
        const mdResponse = fragmentData?.response || "";
        children = (
          <MarkdownRenderer
            className="marginBt12"
            mdContent={mdResponse}
          />
        );
      } else if (type === ConversationFragmentType.QUERY) {
        const queryFragment = fragmentData.queryFragment || {};
        children = (
          <div>
            <VerticallyCenteredRow className="marginBt6">Generated query:</VerticallyCenteredRow>
            <code>{JSON.stringify(queryFragment, null, 4)}</code>
          </div>
        );
      } else if (type === ConversationFragmentType.WIDGET) {
        renderInCard = false;
        const { dataQuery, fromMillis, toMillis, viz, compareFromOffset, disableCompare, properties } =
          fragmentData.vizFragment;

        recommendationsHandled = true;
        if (viz === VizOption.RAW_DATA) {
          children = (
            <>
              {!dataQuery && (
                <VerticallyCenteredRow className="marginBt6 status-danger width-100 inc-flex-center">
                  Invalid raw data request
                </VerticallyCenteredRow>
              )}
              {Boolean(dataQuery) && (
                <RawDataRenderer
                  dataQuery={dataQuery}
                  fromMillis={fromMillis}
                  rawDataProperties={(properties as any).rawDataProperties}
                  toMillis={toMillis}
                />
              )}
            </>
          );
        } else {
          children = (
            <>
              {Boolean(dataQuery) && (
                <MemoizedChatBotVizRenderer
                  bizDataQuery={dataQuery}
                  compareFromOffset={compareFromOffset}
                  demoDataParams={demoDataParams}
                  disableCompare={disableCompare}
                  fromMillis={fromMillis}
                  properties={properties}
                  title={title}
                  toMillis={toMillis}
                  vizType={viz}
                />
              )}
              {!dataQuery && (
                <VerticallyCenteredRow className="status-danger width-100 inc-flex-center marginBt6">
                  No query configuration exists
                </VerticallyCenteredRow>
              )}

              {Boolean(recommendations?.length) && (
                <>
                  <div className="inc-text-subtext inc-text-inactive marginBt8 marginTp16">
                    Discover more with these prompts:
                  </div>
                  <VerticallyCenteredRow className="suggestions inc-flex-column">
                    {recommendations.map((recommendation, idx) => {
                      const eKey = [key, "recommendation", idx].join("_");
                      const onClick = () => onApplyRecommendation(recommendation);
                      const cxName = `inc-flex-row ${
                        idx !== recommendations?.length - 1
                          ? "recommendation-item"
                          : "recommendation-item-without-border"
                      }`;

                      return (
                        <VerticallyCenteredRow
                          className={cxName}
                          key={eKey}
                          onClick={onClick}
                        >
                          {recommendation}
                          <IncFaIcon iconName="arrow-right" />
                        </VerticallyCenteredRow>
                      );
                    })}
                  </VerticallyCenteredRow>
                </>
              )}
            </>
          );
        }
      } else if (type === ConversationFragmentType.OP10ZE) {
        const opCreationConfig = fragmentData.opCreationConfig || fragmentData.bizDataOperationalize?.opCreationConfig;
        const onClick = () => {
          if (opCreationConfig && !isNil(onOperationalize)) {
            onOperationalize(opCreationConfig);
          }
        };
        children = (
          <div>
            {Boolean(opCreationConfig) && (
              <VerticallyCenteredRow className="marginBt6 flex-gap-8">
                <IncButton
                  color="link"
                  onClick={onClick}
                >
                  Operationalize
                </IncButton>
                {opCreationConfig?.name}
              </VerticallyCenteredRow>
            )}
            {!opCreationConfig && (
              <VerticallyCenteredRow className="marginBt6 status-danger width-100 inc-flex-center">
                No insight configuration exists
              </VerticallyCenteredRow>
            )}
          </div>
        );
      } else if (type === ConversationFragmentType.AGENT_OUTPUT) {
        if (status === ConversationStatus.ONGOING || status === ConversationStatus.NA) {
          renderInCard = false;
          children = (
            <GeneratingResponseRenderer
              demoDataParams={demoDataParams}
              fragment={fragment}
              onOperationalize={onOperationalize}
              title={cardTitle || userMessage}
            />
          );
        } else if (status === ConversationStatus.COMPLETED) {
          renderInCard = false;
          children = (
            <ResponseRenderer
              demoDataParams={demoDataParams}
              fragment={fragment}
              onOperationalize={onOperationalize}
              subTitle={message}
              title={cardTitle || userMessage}
            />
          );
        } else {
          renderInCard = false;
          children = (
            <StepsRenderer
              demoDataParams={demoDataParams}
              fragment={fragment}
              onOperationalize={onOperationalize}
            />
          );
        }
      } else if (type === ConversationFragmentType.CORRELATED_EVENTS) {
        renderInCard = false;

        const { correlatedEventsRequest } = fragmentData;
        children = (
          <>
            {!correlatedEventsRequest && (
              <VerticallyCenteredRow className="marginBt6 status-danger width-100 inc-flex-center">
                No correlated events request exists
              </VerticallyCenteredRow>
            )}
            {Boolean(correlatedEventsRequest) && (
              <CorrelatedEventsTimeline
                hideChangeEventsTable
                hideEventCriteria
                impactedWidgets={[]}
                incidentId={incidentId}
                opConfigId={opConfigId}
                source={{
                  type: "payload",
                  payload: correlatedEventsRequest
                }}
              />
            )}
          </>
        );
      } else if (type === ConversationFragmentType.CORRELATED_EVENTS_RESPONSE) {
        renderInCard = false;

        const { correlatedEventSearchResponse } = fragmentData;
        children = (
          <>
            {!correlatedEventSearchResponse && (
              <VerticallyCenteredRow className="marginBt6 status-danger width-100 inc-flex-center">
                Invalid correlated events response
              </VerticallyCenteredRow>
            )}
            {Boolean(correlatedEventSearchResponse) && (
              <CorrelatedEventsTimeline
                hideChangeEventsTable
                hideEventCriteria
                impactedWidgets={[]}
                incidentId={incidentId}
                opConfigId={opConfigId}
                source={{
                  type: "data",
                  data: correlatedEventSearchResponse
                }}
              />
            )}
          </>
        );
      } else if (type === ConversationFragmentType.CORRELATED_EVENTS_WIDGET_PAYLOAD) {
        renderInCard = false;

        const { correlatedEventWidgetPayload } = fragmentData;
        const { correlatedEventSearchRequest, vizFragment } = correlatedEventWidgetPayload || {};

        const impactedWidgets = (vizFragment || []).map(vf => vf.impactWidget).filter(Boolean);

        children = (
          <>
            {!correlatedEventSearchRequest && (
              <VerticallyCenteredRow className="marginBt6 status-danger width-100 inc-flex-center">
                No correlated events request exists
              </VerticallyCenteredRow>
            )}
            {Boolean(correlatedEventSearchRequest) && (
              <CorrelatedEventsTimeline
                hideChangeEventsTable
                hideEventCriteria
                impactedWidgets={impactedWidgets}
                incidentId={incidentId}
                opConfigId={opConfigId}
                source={{
                  type: "payload",
                  payload: correlatedEventSearchRequest
                }}
              />
            )}
          </>
        );
      } else if (type === ConversationFragmentType.TRIAGE_CLUSTERS_RESPONSE) {
        renderInCard = false;

        const { triageClustersResponse } = fragmentData;
        children = (
          <>
            {!triageClustersResponse && (
              <VerticallyCenteredRow className="marginBt6 status-danger width-100 inc-flex-center">
                Invalid triage clusters response
              </VerticallyCenteredRow>
            )}
            {Boolean(triageClustersResponse) && (
              <TriageTableDrilldownWrapper
                isHighlightTable={isHighlightTable}
                response={triageClustersResponse}
              />
            )}
          </>
        );
      } else if (type === ConversationFragmentType.TRIAGE_REQUEST_PAYLOAD) {
        renderInCard = false;

        const { triageRequestPayload } = fragmentData;
        children = (
          <>
            {!triageRequestPayload && (
              <VerticallyCenteredRow className="marginBt6 status-danger width-100 inc-flex-center">
                Invalid drilldown request
              </VerticallyCenteredRow>
            )}
            {Boolean(triageRequestPayload) && <TriageTableDrilldownWrapper payload={triageRequestPayload} />}
          </>
        );
      } else if (type === ConversationFragmentType.FUNNEL_TRIAGE_PAYLOAD) {
        renderInCard = false;

        const { funnelTriagePayload } = fragmentData;
        children = (
          <>
            {!funnelTriagePayload && (
              <VerticallyCenteredRow className="marginBt6 status-danger width-100 inc-flex-center">
                Invalid funnel request
              </VerticallyCenteredRow>
            )}
            {Boolean(funnelTriagePayload) && <TriageFunnelRenderer funnelPayload={funnelTriagePayload} />}
          </>
        );
      } else if (type === ConversationFragmentType.HIGHCHARTS) {
        renderInCard = false;

        const { highchartsFragment } = fragmentData;
        children = (
          <>
            {!highchartsFragment && (
              <VerticallyCenteredRow className="marginBt6 status-danger width-100 inc-flex-center">
                Invalid highcharts request
              </VerticallyCenteredRow>
            )}
            {Boolean(highchartsFragment) && (
              <GenericHighchartsRenderer
                constructorType={highchartsFragment.constructorType}
                options={highchartsFragment.options}
                title={highchartsFragment.title}
              />
            )}
          </>
        );
      } else {
        children = (
          <div>
            <VerticallyCenteredRow className="marginBt6">Generated query:</VerticallyCenteredRow>
            <code>{JSON.stringify(fragmentData || {}, null, 4)}</code>
          </div>
        );
      }

      return (
        <div
          className={className}
          key={chKey}
        >
          {renderInCard ? <AutoExpandContent initHeight={250}>{children}</AutoExpandContent> : children}
        </div>
      );
    };

    let addPadding = false;

    const fragmentsJsxV2 = fragments.map((fragment, idx) => {
      if (!isNil(fragment?.plan)) {
        return (
          <PlanEditor
            chatId={chatId}
            isFetching={status === ConversationStatus.ONGOING}
            key={idx}
            onImplementPlan={onImplementPlan}
            plan={fragment?.plan}
          />
        );
      } else if (!isNil(fragment?.planExecutionResponse)) {
        return (
          <PlanExecutionRenderer
            key={idx}
            messageId={messageId}
            planExecutionResponse={fragment?.planExecutionResponse}
            status={status}
          />
        );
      } else if (!isNil(fragment?.fragment)) {
        addPadding = true;
        return getFragmentJsxUtil(fragment, idx);
      } else if (!isNil(fragment?.lowLevelFragment)) {
        return (
          <InitialFragmentRenderer
            demoDataParams={demoDataParams}
            hideFoooterActions={hideFoooterActions}
            key={idx}
            lowLevelFragment={fragment?.lowLevelFragment}
            messageId={messageId}
            status={status}
            title={cardTitle}
            useParentWidth={useParentWidth}
          />
        );
      } else {
        return null;
      }
    });

    const className = cx("dialogue-text", {
      padding16: addPadding
    });
    const responseJSX = (
      <div className={className}>
        {Boolean(message) && <div className="marginBt16">{message}</div>}
        {!fragmentsJsxV2?.length && (
          <div className="padding16">
            <TypingAnimation className="typing-loader" />
          </div>
        )}
        {fragmentsJsxV2}
      </div>
    );

    return [responseJSX, recommendationsHandled];
  }

  return [null, false];
};

type TrDDProps = {
  payload?: WidgetAlertSummaryRequest2;
  response?: TriageClustersResponse;
  isHighlightTable?: boolean;
};

export const TriageTableDrilldownWrapper = memo<TrDDProps>(props => {
  const { payload, response, isHighlightTable = false } = props;

  const { timeRange, compareTimeRange } = useTimeRange();
  const { fromMillis: startTimeMillis, toMillis: endTimeMillis } = useMemo(
    () => timeRangeUtils.getMillisFromTimeRange(timeRange),
    [timeRange]
  );
  const compareTimeMillis = useMemo(
    () =>
      timeRangeUtils.getDiffMillisFromTimeRange({
        from: compareTimeRange.from,
        to: timeRange.from,
        raw: compareTimeRange.raw
      }),
    [compareTimeRange.from, compareTimeRange.raw, timeRange.from]
  );

  useMemo(() => {
    if (payload) {
      payload.baselineTimeRangeMillis = compareTimeMillis;
    }
  }, [compareTimeMillis, payload]);

  const { data, error, isError, isFetching } = useFetchAlertsSummary(
    payload,
    startTimeMillis,
    endTimeMillis,
    false,
    true,
    response
  );

  useEffect(() => {
    if (isError) {
      logger.error("StepsRenderer", "Error fetching multi dimension analysis", error);
    }
  }, [error, isError]);

  const alertSummary = (data as TriageClustersResponse)?.clusters ? (data as TriageClustersResponse) : null;
  const customAlertSummary = (data as WidgetAlertsSummaryResponseV3)?.combinations
    ? (data as WidgetAlertsSummaryResponseV3)
    : null;

  const offsetMillis = parseInt(alertSummary?.offsetMillis, 10);
  const compareString = useMemo(() => {
    if (!Number.isNaN(offsetMillis)) {
      return timeRangeUtils.getTriageCompareStringFromMillis(offsetMillis);
    }

    return "Compare";
  }, [offsetMillis]);

  return (
    <>
      {isFetching && <LoadingSpinner />}
      {!isFetching && (
        <>
          {isError && (
            <VerticallyCenteredRow className="inc-text-subtext-medium status-danger width-100 inc-flex-center">
              Error fetching data
            </VerticallyCenteredRow>
          )}
          {!isError && (
            <TriageTableDrillDown
              alertSummary={alertSummary}
              compareString={compareString}
              customAlertSummary={customAlertSummary}
              isHighlightTable={isHighlightTable}
            />
          )}
        </>
      )}
    </>
  );
});

type FunnelProps = {
  funnelPayload: FunnelTriagePayload;
};

export const TriageFunnelRenderer = memo<FunnelProps>(props => {
  const { funnelPayload } = props;
  const { funnelData, triageClustersResponse, triageRequestPayload } = funnelPayload;

  const { timeRange, compareTimeRange } = useTimeRange();
  const { fromMillis: startTimeMillis, toMillis: endTimeMillis } = useMemo(
    () => timeRangeUtils.getMillisFromTimeRange(timeRange),
    [timeRange]
  );

  const compareTimeMillis = useMemo(
    () =>
      timeRangeUtils.getDiffMillisFromTimeRange({
        from: compareTimeRange.from,
        to: timeRange.from,
        raw: compareTimeRange.raw
      }),
    [compareTimeRange.from, compareTimeRange.raw, timeRange.from]
  );

  const compareTimeInSeconds = timeRangeUtils.getSecondsFromMillis(compareTimeMillis);
  const compareStr = timeRangeUtils.getTriageCompareStringFromMillis(compareTimeMillis);

  return (
    <div className="inc-flex-column width-100 flex-gap-12">
      {Boolean(funnelData) && (
        <JourneyFunnelRenderer
          compareStr={compareStr}
          compareTimeInSeconds={compareTimeInSeconds}
          currentStr="Current"
          disabledXAxisLabels
          endTimeMillis={endTimeMillis}
          funnelData={funnelData}
          funnelId=""
          showImpactRegions
          startTimeMillis={startTimeMillis}
        />
      )}

      {(Boolean(triageClustersResponse) || Boolean(triageRequestPayload)) && (
        <TriageTableDrilldownWrapper
          payload={triageRequestPayload}
          response={triageClustersResponse}
        />
      )}
    </div>
  );
});
