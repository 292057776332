import React, { FC, useMemo, useCallback, useState, useRef, useEffect } from "react";
import { IncFaIcon } from "@inception/ui";
import { isEqual } from "lodash";
import { ConditionsBuilder } from "../../explore";
import {
  ConditionWithPickerType,
  DemoDataParams,
  FieldPickerContextDTO,
  UserServiceFilterExpression
} from "../../../services/api/explore";
import {
  getConditionsFromUSFilterExpressions,
  getUSFilterExpressionsFromConditions
} from "../../../utils/ExploreUtils";
import { USFieldWidgetUtils } from "../../../dashboard/widgets/USField/USFieldWidgetUtils";
import { FieldPickerModel } from "../../../field-picker";
import { BaseProps } from "./types";

interface Props extends BaseProps {
  filterExpressions: UserServiceFilterExpression[];
  onChange: (filterExpressions: UserServiceFilterExpression[]) => void;
  noToggle?: boolean;
  readOnly?: boolean;

  hideHasErrorFilter?: boolean;
  hideLabel?: boolean;
  demoDataParams?: DemoDataParams;
}

export const EventFilterSelector: FC<Props> = props => {
  const {
    fieldPickerContext,
    filterExpressions: pFilterExpressions,
    onChange,
    getLatestTimeRange,
    noToggle = false,
    readOnly = false,
    hideHasErrorFilter = false,
    hideLabel = false,
    demoDataParams
  } = props;

  const filterExpressions = useMemo(() => pFilterExpressions || [], [pFilterExpressions]);

  const prevPickerContextRef = useRef<FieldPickerContextDTO>();
  const [fieldPickerModel, setFieldPickerModel] = useState<FieldPickerModel>();

  useEffect(() => {
    if (!isEqual(fieldPickerContext, prevPickerContextRef.current)) {
      prevPickerContextRef.current = fieldPickerContext;
      setFieldPickerModel(null);
    }
  }, [fieldPickerContext]);

  const [showFilters, setShowFilters] = useState(true);
  const toggleFilters = useCallback(() => setShowFilters(prev => !prev), []);

  const errorFiltersRef = useRef<UserServiceFilterExpression[]>([]);
  const nonErrorFilterExpressions = useMemo(() => {
    let errorFilterExpressions: UserServiceFilterExpression[];
    let nonErrorFilterExpressions: UserServiceFilterExpression[];

    if (hideHasErrorFilter) {
      errorFilterExpressions = filterExpressions.filter(f => USFieldWidgetUtils.isHasErrorField(f.field.fieldName));
      nonErrorFilterExpressions = filterExpressions.filter(f => !USFieldWidgetUtils.isHasErrorField(f.field.fieldName));
    } else {
      errorFilterExpressions = [];
      nonErrorFilterExpressions = filterExpressions;
    }

    errorFiltersRef.current = errorFilterExpressions;

    return nonErrorFilterExpressions;
  }, [filterExpressions, hideHasErrorFilter]);

  const conditions = useMemo<ConditionWithPickerType[]>(() => {
    const filterExprExists = nonErrorFilterExpressions.length > 0;
    if (filterExprExists) {
      return getConditionsFromUSFilterExpressions(nonErrorFilterExpressions);
    }

    return [null];
  }, [nonErrorFilterExpressions]);

  const onConditionsChange = useCallback(
    (nConditions: ConditionWithPickerType[]) => {
      const filters = getUSFilterExpressionsFromConditions(nConditions, true);
      const newFilterExpressions = [...filters, ...errorFiltersRef.current];
      onChange(newFilterExpressions);
    },
    [onChange]
  );

  const shouldRender = useMemo(
    () => (readOnly ? Boolean((conditions || []).filter(Boolean).length) : true),
    [conditions, readOnly]
  );

  return (
    <>
      {shouldRender && (
        <div className="event-field-filters">
          {!showFilters && !noToggle && (
            <div
              className="filters-toggle"
              onClick={toggleFilters}
            >
              <div>Show More</div>
              <IncFaIcon
                className="marginLt6"
                iconName="angle-down"
              />
            </div>
          )}

          {(showFilters || noToggle) && (
            <ConditionsBuilder
              demoDataParams={demoDataParams}
              fieldPickerContext={fieldPickerContext}
              fieldPickerModel={fieldPickerModel}
              fieldTypes={["userServiceField"]}
              filterJoin="AND"
              filters={conditions}
              getLatestTimeRange={getLatestTimeRange}
              hideSelectLabels
              label={hideLabel ? undefined : "Where"}
              labelAlign="horizontal"
              onChange={onConditionsChange}
              onFieldPickerFetch={setFieldPickerModel}
              readOnly={readOnly}
            />
          )}

          {showFilters && !noToggle && (
            <div
              className="filters-toggle marginTp12"
              onClick={toggleFilters}
            >
              <div>Hide</div>
              <IncFaIcon
                className="marginLt6"
                iconName="angle-up"
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
