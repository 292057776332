import React, { FC, useState } from "react";
import { OpCreationConfig } from "../../services/api/operationalise";
import { PreviewRenderer } from "../../operationalise-v2/v3/preview/PreviewRenderer";
import { DemoDataParams } from "../../services/api/explore";

interface Props {
  opCreationConfig: OpCreationConfig;
  demoDataParams?: DemoDataParams;
}

export const OpRecipePreview: FC<Props> = props => {
  const { opCreationConfig, demoDataParams } = props;

  const [seriesSelection, setSeriesSelection] = useState<Record<string, string>>();

  return (
    <PreviewRenderer
      demoDataParams={demoDataParams}
      entityTypeInfo={null}
      onSeriesSelectionChange={setSeriesSelection}
      opCreationConfig={opCreationConfig}
      seriesSelection={seriesSelection}
    />
  );
};
