import { generateId } from "@inception/ui";
import { last, isEmpty } from "lodash";
import {
  DataDefinition,
  WidgetConfigDTO,
  BinaryArithmeticOperator,
  SliceSet,
  ExpressionDef,
  Expression,
  Visualization,
  CohortConfig,
  UserServiceFieldMetricConfig,
  TimeObjUnit,
  TimeObj,
  ExpressionMetricConfig
} from "../../services/api/explore";
import {
  getExpressionTree,
  ExpressionTreeNode,
  ExpressionNodeOperators,
  FieldPickerUtils,
  eventFieldUtils,
  ENTITY_TAG
} from "../../utils";
import {
  ALL_USERSERVICES_ENTITY_TYPE_ID,
  ALL_USERSERVICES_EVENT_TYPE_ID,
  convertUSFieldSliceSetToTagSlice,
  EXPRESSION_TAG,
  QUERY_LOOKUP_KEY
} from "../../utils/ExploreUtils";
import { MetricAggregationsRecord } from "../../core";
import { MetricsWithEventTypeAndId, MetricWithEventTypeAndId } from "./types";

type MetricDetailsByQueryId = Record<
  string,
  {
    metricId: string;
    sliceSet: SliceSet;
  }
>;

export const getWidgetConfigForMetrics = (
  metricsWithEventTypeAndId: MetricsWithEventTypeAndId,
  expression: string,
  metricName: string,
  bizEntityTypeId: string,
  cohortDefinition: CohortConfig,
  allowIncompleteMetrics = false,
  doNotSave = false,
  preferEventTypeIdForWidgetConfig = false,
  defVizMetricId?: string
) => {
  const metrics: DataDefinition["metrics"] = {};
  const visualizations: Visualization[] = [];
  const metricDetailsByQueryId: MetricDetailsByQueryId = {};

  const numMetrics = metricsWithEventTypeAndId.length;
  const addExpressionMetric = numMetrics > 1 || Boolean(expression);

  let idx = 0;

  let isValid = true;
  let message = "";

  const userServiceEntityIds = new Set<string>();

  metricsWithEventTypeAndId.forEach(configWithId => {
    const { metricConfig, configId, etcMetricDef, eventTypeId, metricName: lMetricName } = configWithId;

    if (eventTypeId) {
      userServiceEntityIds.add(eventTypeId);
    }

    if (metricConfig) {
      const { aggregator, userServiceField } = metricConfig;

      const queryId = String.fromCharCode(65 + idx++);

      const usfValid = allowIncompleteMetrics ? true : !isEmpty(userServiceField);
      const aggValid = allowIncompleteMetrics ? true : !isEmpty(aggregator);
      const configValid = usfValid && aggValid;

      isValid = isValid && configValid;
      message = !isValid ? "Invalid metric configuration" : "";

      const usfSliceSet = last(metricConfig.sliceSets);
      const sliceSet = usfSliceSet
        ? convertUSFieldSliceSetToTagSlice(usfSliceSet)
        : {
            slices: []
          };

      metricDetailsByQueryId[queryId] = {
        metricId: configId,
        sliceSet
      };

      const usfMetricName = addExpressionMetric ? lMetricName || getMetricName(metricConfig, sliceSet) : metricName;

      metrics[configId] = {
        sourceType: "userServiceField",
        id: configId,
        name: addExpressionMetric ? usfMetricName : metricName,
        userServiceFieldMetricConfig: metricConfig,
        doNotSave,
        labels: {
          excludeDataFetch: addExpressionMetric.toString(),
          [QUERY_LOOKUP_KEY]: queryId,
          isAllEventTypesCase: String(eventTypeId === ALL_USERSERVICES_EVENT_TYPE_ID)
        }
      };
    } else if (etcMetricDef) {
      metrics[etcMetricDef.id] = etcMetricDef;
    }
  });

  const labels: WidgetConfigDTO["labels"] = expression
    ? {
        [EXPRESSION_TAG]: expression
      }
    : {};

  let userServiceEntityId = "";
  let bizEntityType = "";

  if (userServiceEntityIds.has(ALL_USERSERVICES_EVENT_TYPE_ID)) {
    userServiceEntityId = "";
    bizEntityType = ALL_USERSERVICES_ENTITY_TYPE_ID;
  } else {
    userServiceEntityId = userServiceEntityIds.size === 1 ? userServiceEntityIds.values().next().value : "";
    userServiceEntityId =
      (preferEventTypeIdForWidgetConfig || !bizEntityTypeId) && userServiceEntityId ? userServiceEntityId : "";
    bizEntityType = userServiceEntityId ? "" : bizEntityTypeId;
  }

  const widgetConfigDto: WidgetConfigDTO = {
    dataDefinition: {
      fields: {},
      metrics
    },
    cohortDefinition,
    isStatic: false,
    name: metricName,
    visualizations,
    userServiceEntityId,
    bizEntityType,
    labels
  };

  let vizMetricId = "";

  if (isValid || allowIncompleteMetrics) {
    if (addExpressionMetric && !expression) {
      isValid = false;
      message = "Expression cannot be empty for multiple metrics";
    } else {
      if (expression) {
        const { expressionTree, isValid: exprValid } = getExpressionTree(expression);

        const variablesNodes = expressionTree.getVariableNodesByVariable();
        const numVariables = Object.keys(variablesNodes).length;
        const metricsMatchVariables = numVariables <= numMetrics;

        isValid = exprValid && metricsMatchVariables;
        message = isValid ? "" : !exprValid ? "Invalid expression" : "Variables in expression do not match metrics";

        if (isValid) {
          const rootNode = expressionTree.getRootNode();
          const exprDef: ExpressionDef = {
            leftExpr: null,
            op: null,
            rightExpr: null
          };

          const exprMetricId = defVizMetricId || generateId();
          metrics[exprMetricId] = {
            id: exprMetricId,
            name: metricName,
            sourceType: "expression",
            expressionMetricConfig: {
              expression: exprDef
            },
            doNotSave
          };

          constructExpressionDef(exprDef, rootNode, metricDetailsByQueryId);
          vizMetricId = exprMetricId;
        }
      } else {
        const metricId = Object.keys(metrics)[0];
        vizMetricId = metricId;
      }
    }

    if (vizMetricId) {
      visualizations.push(getVisualisation(vizMetricId));
      metrics[vizMetricId].name = metricName || "Metric";
    }
  }

  return {
    isValid,
    message,
    widgetConfigDto,
    vizMetricId
  };
};

export const getDefaultMetricWithEventTypeAndId = (eventTypeId: string): MetricWithEventTypeAndId => ({
  configId: generateId(),
  eventTypeId: eventTypeId,
  metricConfig: {
    aggregator: "",
    eventFilters: {
      userServiceFilters: []
    },
    sliceSets: [],
    userServiceField: null,
    lookBack: getDefaultLookBack()
  }
});

export const getDefaultMetricsForWidgetConfig = (
  widgetConfigDto: WidgetConfigDTO,
  defaultEventTypeIdForAll?: string,
  retainMetricIds?: boolean
) => {
  let metricName = "";
  let metricsWithEventTypeAndId: MetricsWithEventTypeAndId;
  let expression: string = null;
  const metricIdLookup: Record<string, string> = {};
  let exprMetricId = "";
  let subType: string;
  let isSpikePositive: boolean;
  let lookBack: TimeObj;

  if (widgetConfigDto) {
    const {
      name,
      labels = {},
      dataDefinition: { metrics }
    } = widgetConfigDto;

    metricName = name;
    expression = labels[EXPRESSION_TAG];
    metricsWithEventTypeAndId = [];

    let idx = 0;
    const metricsLookup: Record<string, MetricWithEventTypeAndId> = {};

    const metricsArr = Object.values(metrics);
    metricsArr.forEach(metricDef => {
      if (metricDef.sourceType === "userServiceField") {
        const {
          labels = {},
          id: oMetricId,
          userServiceFieldMetricConfig,
          subType: mSubType,
          isSpikePositive: mIsSpikePositive,
          name
        } = metricDef;

        const nMetricId = retainMetricIds ? oMetricId : generateId();
        const queryId = labels[QUERY_LOOKUP_KEY] || String.fromCharCode(65 + idx);

        const { userServiceField, lookBack: mLookBack } = userServiceFieldMetricConfig;
        const eventTypeId =
          labels?.isAllEventTypesCase === "true" || userServiceField?.allUserService
            ? ALL_USERSERVICES_EVENT_TYPE_ID
            : userServiceField?.userServices?.[0]?.userServiceEntityId || defaultEventTypeIdForAll;

        if (eventTypeId) {
          metricsLookup[queryId] = {
            configId: nMetricId,
            eventTypeId,
            metricConfig: userServiceFieldMetricConfig,
            metricName: name
          };
          idx++;

          metricIdLookup[nMetricId] = oMetricId;
        }

        subType = mSubType;
        isSpikePositive = mIsSpikePositive;
        lookBack = mLookBack;
      } else if (metricDef.sourceType === "expression") {
        exprMetricId = metricDef.id;
        subType = metricDef.subType;
        isSpikePositive = metricDef.isSpikePositive;
      } else {
        const { labels = {}, id: oMetricId, name } = metricDef;

        const nMetricId = retainMetricIds ? oMetricId : generateId();
        const queryId = labels[QUERY_LOOKUP_KEY] || String.fromCharCode(65 + idx);

        metricsLookup[queryId] = {
          configId: nMetricId,
          eventTypeId: "",
          metricConfig: null,
          etcMetricDef: metricDef,
          metricName: name
        };
        idx++;

        metricIdLookup[nMetricId] = oMetricId;
      }
    });

    const sortedQueryKeys = Object.keys(metricsLookup).sort();
    sortedQueryKeys.forEach(queryId => {
      const config = metricsLookup[queryId];
      metricsWithEventTypeAndId.push(config);
    });
  }

  if (exprMetricId) {
    try {
      const expressionLabels = JSON.parse(expression);
      expression = expressionLabels[exprMetricId]?.[EXPRESSION_TAG] || "";
    } catch (err) {
      // Do nothing
    }
  } else {
    expression = "";
  }

  return {
    metricName,
    metricsWithEventTypeAndId,
    expression,
    exprMetricId,
    metricIdLookup,
    subType,
    isSpikePositive,
    lookBack
  };
};

export const getSaveWidgetConfigDto = (
  widgetConfigDto: WidgetConfigDTO,
  metricIdLookup: Record<string, string>,
  exprMetricId: string
) => {
  const saveWidgetConfigDto: WidgetConfigDTO = {
    ...widgetConfigDto,
    dataDefinition: {
      fields: {},
      metrics: {}
    }
  };

  const eMetrics = widgetConfigDto.dataDefinition.metrics;
  const { metrics } = saveWidgetConfigDto.dataDefinition;

  let nExprMetricId = "";

  Object.keys(eMetrics).forEach(nMetricId => {
    const metric = eMetrics[nMetricId];

    const oMetricId = metricIdLookup[nMetricId];

    if (metric.sourceType === "expression") {
      exprMetricId = exprMetricId || nMetricId;
      nExprMetricId = nMetricId;

      updateExpressionMetric(metric.expressionMetricConfig, metricIdLookup);

      metrics[exprMetricId] = {
        ...metric,
        id: exprMetricId
      };
    } else {
      const metricId = oMetricId || nMetricId;
      metrics[metricId] = {
        ...metric,
        id: metricId
      };
    }
  });

  saveWidgetConfigDto.visualizations = widgetConfigDto.visualizations.map(vizDef => {
    const nDataDefs = vizDef.dataDefs.map(d => ({
      ...d,
      id: d.id === nExprMetricId ? exprMetricId : metricIdLookup[d.id] || d.id
    }));

    return {
      ...vizDef,
      dataDefs: nDataDefs
    };
  });

  return saveWidgetConfigDto;
};

const updateExpressionMetric = (
  expressionMetricConfig: ExpressionMetricConfig,
  metricIdLookup: Record<string, string>
) => {
  if (!expressionMetricConfig) {
    return;
  }

  const { sliceSpec, expression } = expressionMetricConfig;

  const { leftExpr, rightExpr } = expression || {};

  if (sliceSpec) {
    sliceSpec.metricId = metricIdLookup[sliceSpec.metricId] || sliceSpec.metricId;
  }

  if (leftExpr?.expressionMetricConfig) {
    updateExpressionMetric(leftExpr.expressionMetricConfig, metricIdLookup);
  }

  if (rightExpr?.expressionMetricConfig) {
    updateExpressionMetric(rightExpr.expressionMetricConfig, metricIdLookup);
  }
};

const getDefaultLookBack = (): TimeObj => ({
  unit: TimeObjUnit.minutes,
  value: 1
});

const constructExpressionDef = (
  exprDef: ExpressionDef,
  node: ExpressionTreeNode,
  metricDetailsByQueryId: MetricDetailsByQueryId
) => {
  if (!node) {
    return;
  }

  const left = node.getLeft();
  const right = node.getRight();

  if (left && right) {
    const isOperator = node.isOperatorNode();

    if (isOperator) {
      const op = node.getOperator();
      const binaryOp = getBinaryOperatorFromOperator(op);
      exprDef.op = binaryOp;

      const { expression: leftExpr, isOperator: leftIsOperator } = processNode(left, metricDetailsByQueryId);

      const { expression: rightExpr, isOperator: rightIsOperator } = processNode(right, metricDetailsByQueryId);

      exprDef.leftExpr = leftExpr;
      exprDef.rightExpr = rightExpr;

      if (leftIsOperator) {
        constructExpressionDef(leftExpr.expressionMetricConfig.expression, left, metricDetailsByQueryId);
      }

      if (rightIsOperator) {
        constructExpressionDef(rightExpr.expressionMetricConfig.expression, right, metricDetailsByQueryId);
      }
    }
  } else {
    return;
  }
};

const getBinaryOperatorFromOperator = (operator: ExpressionNodeOperators) => {
  switch (operator) {
    case "*":
      return BinaryArithmeticOperator.multiplication;
    case "-":
      return BinaryArithmeticOperator.subtraction;
    case "+":
      return BinaryArithmeticOperator.addition;
    case "/":
      return BinaryArithmeticOperator.division;
    default:
      return BinaryArithmeticOperator.binaryOperatorUnset;
  }
};

const processNode = (node: ExpressionTreeNode, metricDetailsByQueryId: MetricDetailsByQueryId) => {
  const isOperator = node.isOperatorNode();
  const isScalar = node.isScalarNode();

  let expression: Expression;

  if (isOperator) {
    expression = {
      expressionMetricConfig: {
        expression: {
          leftExpr: null,
          op: null,
          rightExpr: null
        }
      }
    };
  } else if (isScalar) {
    const scalar = node.getScalar();
    expression = {
      scalar
    };
  } else {
    const variable = node.getVariable();
    const { metricId, sliceSet } = metricDetailsByQueryId[variable];
    expression = {
      expressionMetricConfig: {
        sliceSpec: {
          metricId,
          selectorSpec: {
            filters: []
          },
          sliceSet
        }
      }
    };
  }

  return {
    expression,
    isOperator
  };
};

const getVisualisation = (metricId: string): Visualization => ({
  dataDefs: [
    {
      enabled: true,
      id: metricId,
      type: "expression"
    }
  ],
  id: generateId(),
  type: "timeseries"
});

const getMetricName = (metricConfig: UserServiceFieldMetricConfig, sliceSet: SliceSet, skipFilters = true) => {
  let usfMetricName = "";

  const { aggregator, filterExpressions = [], userServiceField } = metricConfig;

  const aggrStr = MetricAggregationsRecord[aggregator]?.longDisplayName || aggregator;
  usfMetricName = userServiceField ? FieldPickerUtils.generateMetricNamefromUSF(userServiceField, aggrStr) : "";

  const numFilters = filterExpressions.length;
  const filtersExist = numFilters > 0;
  const multipleFiltersExist = numFilters > 1;

  let filtersStr = "";
  if (filtersExist && !skipFilters) {
    const filterStrArr: string[] = [];
    filterExpressions.forEach(fe => {
      const { field, value, values } = fe;

      const allValues = values ? values : [value];
      const valStr = allValues.join(", ");
      const keyStr = eventFieldUtils.removeFieldsPrefix(field.fieldName);

      const filterStr = `${keyStr}: ${valStr}`;
      filterStrArr.push(filterStr);
    });

    if (multipleFiltersExist) {
      const lastIdx = numFilters - 1;
      const initFiltersStr = filterStrArr.slice(0, lastIdx).join(", ");
      const lastFilterStr = filterStrArr.slice(lastIdx);
      filtersStr = `${initFiltersStr} and ${lastFilterStr}`;
    } else {
      filtersStr = filterStrArr[0];
    }
    filtersStr = ` for ${filtersStr}`;
  }
  usfMetricName += filtersStr;

  const slices = sliceSet?.slices || [];
  let slicesStr = slices
    .filter(s => s.tagName !== ENTITY_TAG)
    .map(s => s.tagName)
    .join(", ");
  slicesStr = slicesStr ? ` by ${slicesStr}` : "";
  usfMetricName += slicesStr;

  return usfMetricName;
};
