import { IncClickAwayPopper, IncPopper, IncTooltipProps } from "@inception/ui";
import React, { FC, useEffect, useMemo, useRef } from "react";
import { CustomSelect } from "../../../../components";
import { useToggleState } from "../../../../core";

interface Props {
  label?: string;
  placeholderText?: string;
  placement?: IncTooltipProps["placement"];

  hideBackdrop?: boolean;
  useClickAway?: boolean;
  className?: string;

  valueLabel: JSX.Element | string;
  children: (open: () => void, close: () => void, isOpen: boolean) => JSX.Element | JSX.Element[];
  onModalStateChange?: (isOpen: boolean) => void;
}

export const CustomSelectWrapper: FC<Props> = props => {
  const {
    label,
    useClickAway,
    valueLabel,
    className,
    children,
    placeholderText,
    placement = "bottom-end",
    onModalStateChange,
    hideBackdrop = false
  } = props;

  const { close, isOpen, open } = useToggleState();

  useEffect(() => {
    if (onModalStateChange) {
      onModalStateChange(isOpen);
    }
  }, [isOpen, onModalStateChange]);

  const editorRef = useRef<HTMLDivElement>(null);

  const childrenJsx = useMemo(() => children(open, close, isOpen), [children, isOpen, open, close]);

  return (
    <>
      <CustomSelect
        anchor={editorRef}
        className={className}
        label={label}
        onClick={open}
        placeholderText={placeholderText}
        showClearButton={false}
        value={valueLabel}
      />

      {isOpen && !hideBackdrop && <div className="inc-modal-backdrop custom-select-backdrop" />}

      {useClickAway && (
        <IncClickAwayPopper
          anchorEl={editorRef.current}
          onClickAway={close}
          placement={placement}
          show={isOpen}
        >
          {childrenJsx}
        </IncClickAwayPopper>
      )}

      {!useClickAway && (
        <IncPopper
          anchorEl={editorRef.current}
          placement={placement}
          show={isOpen}
        >
          {childrenJsx}
        </IncPopper>
      )}
    </>
  );
};
