import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IncTextArea, IncButton } from "@inception/ui";
import { css } from "emotion";
import { CypressConstants } from "@bicycle/tests";
import {
  BizDataQuery,
  DimensionalAnalysisToolSpec,
  FieldPickerContextDTO,
  FieldPickerOptionData,
  ImpactedWidget,
  ToolParam,
  UserServiceField,
  UserServiceFieldSlice,
  WidgetConfigUtils
} from "../../../../../../platform/services/api/explore";
import { IncTabBar, FieldPickerContainer, VerticallyCenteredRow, IncTab } from "../../../../../../platform/components";
import { EntityPropertyValue, useRefState, useToggleState } from "../../../../../../platform/core";
import { FieldPickerUtils } from "../../../../../../platform/utils";
import ParamsEditor from "./ParamsEditor";
import QueryEditor from "./QueryEditor";

type Props = {
  dimensionalAnalysisToolSpec: DimensionalAnalysisToolSpec;
  onChange: (dimensionalAnalysisToolSpec: DimensionalAnalysisToolSpec) => void;
  onLoadMetrics: (loading: boolean) => void;
  pickerContextDto: FieldPickerContextDTO;
  impactedWidgets: ImpactedWidget[];
  params: ToolParam[];
  children?: React.ReactNode;
  updateHeight: () => void;
};

const DimensionalAnalysisToolSpecEditor: React.FC<Props> = props => {
  const {
    dimensionalAnalysisToolSpec,
    onChange,
    impactedWidgets,
    pickerContextDto: defPickerContextDto,
    onLoadMetrics,
    updateHeight,
    params,
    children
  } = props;

  const dimensionalAnalysisToolSpecRef = useRefState<DimensionalAnalysisToolSpec>(dimensionalAnalysisToolSpec);

  const { query, querySource, dimensions, paramValues } = dimensionalAnalysisToolSpec || {};
  const { idProps } = query || {};

  const pickerContextDto = useMemo(() => {
    if (idProps) {
      const { entityTypeId, eventTypeId } = WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(idProps);
      const dto: FieldPickerContextDTO = {
        entityId: eventTypeId,
        entityName: null,
        entityType: entityTypeId
      };
      if (entityTypeId || eventTypeId) {
        return dto;
      } else {
        return defPickerContextDto;
      }
    } else {
      return defPickerContextDto;
    }
  }, [defPickerContextDto, idProps]);

  const { slices } = dimensions || {};

  const selectedFields = useMemo<FieldPickerOptionData[]>(
    () =>
      (slices || []).map(slice => ({
        payload: slice.userServiceField,
        type: "userServiceField"
      })),
    [slices]
  );

  const [queryTab, setQueryTab] = useState(query ? "query" : "querySource");

  const handleSelection = useCallback(
    (id: string | number) => {
      setQueryTab(id as string);
      onChange({
        ...dimensionalAnalysisToolSpec,
        querySource: id === "querySource" ? "" : null,
        query: null,
        dimensions: { slices: [] }
      });
    },
    [dimensionalAnalysisToolSpec, onChange]
  );

  const onChangeDescription = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...dimensionalAnalysisToolSpec,
        querySource: e.target.value
      });
    },
    [dimensionalAnalysisToolSpec, onChange]
  );
  const onChangeParamValues = useCallback(
    (key: string, value: EntityPropertyValue) => {
      onChange({
        ...dimensionalAnalysisToolSpec,

        paramValues: {
          ...dimensionalAnalysisToolSpec?.paramValues,
          [key]: value
        }
      });
    },
    [dimensionalAnalysisToolSpec, onChange]
  );

  const onChangeQuery = useCallback(
    (nQuery: BizDataQuery) => {
      const dimensionalAnalysisToolSpec = dimensionalAnalysisToolSpecRef.current;
      if (dimensionalAnalysisToolSpec) {
        let needToUpdateDimensions = true;
        const prevQuery = dimensionalAnalysisToolSpec?.query;
        if (nQuery?.idProps && prevQuery?.idProps) {
          const { entityTypeId, eventTypeId } = WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(nQuery?.idProps);
          const { entityTypeId: prevEntityTypeId, eventTypeId: prevEventTypeId } =
            WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(prevQuery.idProps);
          const prevId = prevEntityTypeId || prevEventTypeId;
          const currentId = entityTypeId || eventTypeId;
          if (prevId === currentId) {
            needToUpdateDimensions = false;
          }
        }
        onChange({
          ...dimensionalAnalysisToolSpec,
          query: nQuery,
          querySource: null,
          dimensions: needToUpdateDimensions ? { slices: [] } : dimensionalAnalysisToolSpec?.dimensions
        });
      }
    },
    [dimensionalAnalysisToolSpecRef, onChange]
  );

  const onChangeDimensions = useCallback(
    (options: FieldPickerOptionData[]) => {
      const usFields = options
        .filter(option => option.type === "userServiceField")
        .map(option => option.payload as unknown as UserServiceField);
      const nSlices = usFields.map((usField): UserServiceFieldSlice => {
        const tagName = FieldPickerUtils.getPromSanitizedUSFName(usField);
        return {
          tagName,
          userServiceField: usField
        };
      });
      onChange({
        ...dimensionalAnalysisToolSpec,
        dimensions: {
          slices: nSlices
        }
      });
    },
    [dimensionalAnalysisToolSpec, onChange]
  );

  const { isOpen: isParamsEditorVisible, toggle: toggleParamsEditor } = useToggleState();

  useEffect(() => {
    updateHeight();
  }, [isParamsEditorVisible, updateHeight]);

  return (
    <>
      <div data-cy={CypressConstants.components.TriageCypressConstants.WorkflowConstants.attributes.querySourceTab}>
        <IncTabBar
          className={marginBt0}
          disableRouteChange
          handleSelection={handleSelection}
          selected={queryTab}
          tabs={tabs}
        />
      </div>

      {queryTab === "querySource" && (
        <IncTextArea
          errorText="Query source is required"
          hasError={!querySource}
          label="Query Source"
          name="querySource"
          onChange={onChangeDescription}
          placeholder="Enter query source"
          value={querySource}
        />
      )}
      {queryTab === "query" && (
        <QueryEditor
          bizDataQuery={query}
          impactedWidgets={impactedWidgets}
          onChange={onChangeQuery}
          onLoadMetrics={onLoadMetrics}
          pickerContextDto={pickerContextDto}
        />
      )}
      <FieldPickerContainer
        fieldPickerContextDto={pickerContextDto}
        fieldTypes={["userServiceField"]}
        isMulti
        label="Dimensions"
        onChange={onChangeDimensions}
        selectedOptions={selectedFields}
      />

      <VerticallyCenteredRow>
        {children}
        {params?.length > 0 && (
          <IncButton
            className="marginLtAuto"
            color="link"
            onClick={toggleParamsEditor}
            size="small"
          >
            {isParamsEditorVisible ? "Hide additional params" : "Show additional params"}
          </IncButton>
        )}
      </VerticallyCenteredRow>
      {isParamsEditorVisible && (
        <div className="width-100 inc-flex-column flex-gap-12">
          {params.map((toolSpecParam, key) => (
            <ParamsEditor
              key={key}
              onChange={onChangeParamValues}
              paramValueRecord={paramValues}
              toolParam={toolSpecParam}
            />
          ))}
        </div>
      )}
    </>
  );
};

const tabs: IncTab[] = [
  {
    id: "querySource",
    label: "Query Source"
  },
  {
    id: "query",
    label: "Custom Query"
  }
];

const marginBt0 = css`
  margin-bottom: 0px !important;
`;

export default DimensionalAnalysisToolSpecEditor;
