import React, { FC, useCallback, useMemo } from "react";
import { CurrencyType, IncSmartText } from "@inception/ui";
import { isNil } from "lodash";
import { Datum } from "../utils";
import { CatalogWidgetProperties } from "../../../../models";
import { DataType } from "../../../../../../../core";
import { getFormattedValue } from "../../common";
import { WidgetResponseDTO } from "../../../../../../../services/api/explore";
import appConfig from "../../../../../../../../appConfig";
import { isEntity } from "../../../../../../../utils";
import { SimpleEntityNameRenderer } from "../../../../../../../components";

type Props = {
  data?: Datum;
  metricNames: Record<string, string>;
  matricIds: string[];
  aggTags: string[];
  displayAggTags: string[];
  fieldName: string;
  dataType: DataType;
  tagsDataType: Record<string, DataType>;
  currencyType: CurrencyType;
  widgetResponseDto: WidgetResponseDTO;
  widgetProperties: CatalogWidgetProperties;
  entityLookup: Record<string, string>;
};

const VALUE_ACCESSOR = "___value__";

const TableInfo: FC<Props> = props => {
  const {
    data,
    metricNames,
    fieldName,
    dataType,
    currencyType,
    widgetResponseDto,
    widgetProperties,
    entityLookup,
    displayAggTags,
    tagsDataType,
    aggTags
  } = props;

  const { dataTypeCustomisation } = widgetProperties;

  const { metrics = {} } = widgetResponseDto?.widgetConfig?.dataDefinition || {};
  const keys = useMemo(() => {
    const keys: Array<{ key: string; formattedKey: string }> = [];
    aggTags.forEach((tag, i) => {
      const tagExist = data[tag];
      if (tagExist) {
        keys.push({
          key: tag,
          formattedKey: formatLabel(tag, metricNames, displayAggTags, i)
        });
      }
    });
    const metricKeys = Object.keys(metricNames);
    metricKeys.forEach(metricName => {
      const formattedId = `${metricName}${VALUE_ACCESSOR}`;
      const tagExist = data[formattedId];
      if (!isNil(tagExist)) {
        keys.push({
          key: formattedId,
          formattedKey: formatLabel(metricName, metricNames)
        });
      }
    });
    return keys;
  }, [aggTags, data, displayAggTags, metricNames]);

  const valueFormatter = useCallback(
    (metricId: string, value: string | number) => {
      const formatedId = metricId.replaceAll(VALUE_ACCESSOR, "").trim();
      const subType = metrics[formatedId]?.subType as DataType;
      const aggType = tagsDataType?.[formatedId];
      if (typeof value === "string") {
        const renderAsEntity = isEntity(value);
        // todo: add a better way to handle this instead of directly accessing bootConfig.anomShareId here
        if (renderAsEntity && !appConfig.anomShareId) {
          return (
            <SimpleEntityNameRenderer
              id={value}
              minimalLoader
              showPropertiesPopover
            />
          );
        }
      }
      const formattedValue = getFormattedValue(
        fieldName,
        value,
        aggType || subType || dataType,
        currencyType,
        dataTypeCustomisation
      );
      const isHTML = formattedValue?.startsWith("<") && formattedValue?.endsWith(">");
      if (isHTML) {
        return (
          <span
            dangerouslySetInnerHTML={{ __html: formattedValue }}
            style={{
              display: "inline-block",
              width: "fit-content"
            }}
          />
        );
      } else {
        const text = entityLookup?.[formattedValue] || formattedValue;
        return (
          <IncSmartText
            className="inc-text-body-medium"
            text={text}
          />
        );
      }
    },
    [metrics, tagsDataType, fieldName, dataType, currencyType, dataTypeCustomisation, entityLookup]
  );
  return (
    <div className="inc-grid inc-grid--grid-2">
      {keys.map((e, i) => (
        <div key={i}>
          <IncSmartText
            className="inc-text-color-secondary inc-text-subtext-medium marginBt4"
            text={e.formattedKey}
          />
          {valueFormatter(e.key, data[e.key])}
        </div>
      ))}
    </div>
  );
};

export default TableInfo;

export const formatLabel = (
  id: string,
  metricNames: Record<string, string>,
  displayTags?: string[],
  index?: number
) => {
  const formattedId = id.replaceAll(VALUE_ACCESSOR, "").trim();
  if (displayTags) {
    const aggTag = displayTags?.[index] || formattedId;
    return aggTag;
  }
  const metricIndex = metricNames[formattedId];
  if (!metricIndex) {
    return formattedId;
  } else {
    return metricIndex;
  }
};
