import { IncCol, IncContainer, IncRow } from "@inception/ui";
import React, { CSSProperties, ReactElement, memo, useEffect, useMemo } from "react";
import { transparentize } from "polished";
import { useTitle, useVerticalConfig } from "../../core/hooks";
import { getLocalizedString } from "../../utils/LocalizedStringUtils";
import { ScreenHeader } from "./ScreenHeader";

interface ScreenContainProps {
  className?: string;
  screenHeaderMessageId?: string;
  screenSubHeaderMessageId?: string;
  screenSubHeaderHelpTextId?: string;
  screenHeaderMessageValues?: Array<string | number>;
  style?: React.CSSProperties;
  children: React.ReactNode | React.ReactNode[];

  // Use this if you want to pass in your own FormattedMessage
  formattedMessage?: ReactElement;
  skipPadding?: boolean;
  footer?: React.ReactNode;
  addCopilotColorGradient?: boolean;
}

const ScreenContainerRow: React.FC<ScreenContainProps> = (props: ScreenContainProps) => {
  const { setTitle } = useTitle();
  const {
    screenHeaderMessageId,
    screenHeaderMessageValues,
    formattedMessage,
    className = "",
    skipPadding = false,
    footer,
    screenSubHeaderMessageId,
    screenSubHeaderHelpTextId,
    addCopilotColorGradient = false
  } = props;

  useEffect(() => {
    const title = getLocalizedString(screenHeaderMessageId, screenHeaderMessageValues);
    setTitle({
      title,
      titleElement: formattedMessage ? (
        formattedMessage
      ) : screenHeaderMessageId ? (
        <ScreenHeader
          subTitleHelpTextId={screenSubHeaderHelpTextId}
          subTitleId={screenSubHeaderMessageId}
          titleId={screenHeaderMessageId}
          titleValues={screenHeaderMessageValues}
        />
      ) : null
    });
  }, [
    screenHeaderMessageId,
    screenHeaderMessageValues,
    setTitle,
    formattedMessage,
    screenSubHeaderMessageId,
    screenSubHeaderHelpTextId
  ]);

  return (
    <>
      <IncContainer
        className={className}
        skipPadding={skipPadding}
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          height: `calc(100vh - 64px)`
        }}
      >
        {addCopilotColorGradient && <CopilotColorGradient />}
        <IncRow style={props.style}>
          <IncCol
            md={12}
            style={{
              padding: "0 16px",
              paddingBottom: 16
            }}
          >
            {/* Render other stuff */}
            {props.children}
          </IncCol>
        </IncRow>
      </IncContainer>
      {footer && <Footer> {footer} </Footer>}
    </>
  );
};

const Footer = (props: { children: React.ReactNode }) => <>{props.children}</>;

export default ScreenContainerRow;

const CopilotColorGradient = memo(() => {
  const { verticalConfig } = useVerticalConfig();
  const { useCaseId, useCaseDTOs } = verticalConfig;
  const copilotColor = useMemo(
    () => useCaseDTOs.find(useCase => useCase.id === useCaseId)?.color,
    [useCaseDTOs, useCaseId]
  );
  const copilotSelectionExists = Boolean(useCaseId) && Boolean(copilotColor);

  if (copilotSelectionExists) {
    const bgColor = transparentize(0.88, copilotColor);
    const style: CSSProperties = {
      width: "75%",
      height: 433,
      background: `linear-gradient(180deg, ${bgColor} 0%, rgba(21, 32, 43, 0.16) 100%)`,
      position: "fixed",
      top: -190,
      left: "12.5%",
      zIndex: -1,
      borderRadius: "0px 0px 1000px 1000px",
      filter: "blur(106.94999694824219px)"
    };

    return <div style={style} />;
  }

  return <></>;
});
