import { IncFaIcon, IncToolTip } from "@inception/ui";
import { omit, pick } from "lodash";
import React, { FC, useMemo, useCallback } from "react";
import { IncTabBar, VerticallyCenteredRow } from "../../../components";
import { Tab } from "../../../components/Tabs/TabBar";
import { useOpStore } from "../../context";
import { OP_ACTION_ERROR_PREFIX } from "../../types";
import { validateOpConfig } from "../../utils";

interface Props {
  isConditionsView: boolean;
  isActionsView: boolean;

  switchToActionsView: () => void;
  switchToConditionsView: () => void;

  numActions: number;
}

export const OpV3TabBar: FC<Props> = props => {
  const { isConditionsView, switchToActionsView, switchToConditionsView, numActions } = props;

  const tabs = useMemo<Tab[]>(
    () => [
      {
        id: "conditions",
        label: "Conditions"
      },
      {
        id: "actions",
        label: "Actions"
      }
    ],
    []
  );

  const handleSelection = useCallback(
    (tabId: string | number) => {
      const strTabId = String(tabId);
      if (strTabId === "conditions") {
        switchToConditionsView();
      } else {
        switchToActionsView();
      }
    },
    [switchToActionsView, switchToConditionsView]
  );

  const { state } = useOpStore();
  const { opCreationConfig, context, sourceTypeInfoByCategory } = state;
  const { uiIntegrationActionConfigsMap } = context;
  const { disableOpEdit } = opCreationConfig || {};

  const { errors, isValid } = useMemo(
    () => validateOpConfig(opCreationConfig, uiIntegrationActionConfigsMap, sourceTypeInfoByCategory, false),
    [opCreationConfig, sourceTypeInfoByCategory, uiIntegrationActionConfigsMap]
  );

  const { actionErrorsMap, configErrorsMap } = useMemo(() => {
    let actionErrorsMap: Record<string, string> = {};
    let configErrorsMap: Record<string, string> = {};

    if (!isValid) {
      const allKeys = Object.keys(errors);
      const actionsErrorKeys = allKeys.filter(key => key.startsWith(OP_ACTION_ERROR_PREFIX));
      actionErrorsMap = pick(errors, actionsErrorKeys);
      configErrorsMap = omit(errors, actionsErrorKeys);
    }

    return {
      actionErrorsMap,
      configErrorsMap
    };
  }, [errors, isValid]);

  const actionsError = useMemo(() => Object.values(actionErrorsMap).filter(Boolean).join("\n"), [actionErrorsMap]);
  const configError = useMemo(() => Object.values(configErrorsMap).filter(Boolean).join("\n"), [configErrorsMap]);

  const tabRenderer = useCallback(
    (tab: Tab) => {
      if (tab.id === "conditions") {
        return (
          <VerticallyCenteredRow className="flex-gap-10">
            {tab.label}
            {disableOpEdit && (
              <IncToolTip titleText="Conditions edit has been restricted for this operationalize. However, you can still add, edit or delete actions.">
                <VerticallyCenteredRow className="view-only-chip">
                  <IncFaIcon iconName={"eye"} /> View Only
                </VerticallyCenteredRow>
              </IncToolTip>
            )}
            {!isConditionsView && Boolean(configError) && (
              <IncToolTip
                placement="top"
                titleText={configError}
                variant="error"
              >
                <VerticallyCenteredRow>
                  <IncFaIcon
                    className="status-danger"
                    iconName="exclamation-triangle"
                  />
                </VerticallyCenteredRow>
              </IncToolTip>
            )}
          </VerticallyCenteredRow>
        );
      } else {
        if (numActions) {
          return (
            <VerticallyCenteredRow className="flex-gap-10">
              {tab.label} ({numActions})
              {isConditionsView && Boolean(actionsError) && (
                <IncToolTip
                  placement="top"
                  titleText={actionsError}
                  variant="error"
                >
                  <VerticallyCenteredRow>
                    <IncFaIcon
                      className="status-danger"
                      iconName="exclamation-triangle"
                    />
                  </VerticallyCenteredRow>
                </IncToolTip>
              )}
            </VerticallyCenteredRow>
          );
        }

        return (
          <VerticallyCenteredRow className="status-info">
            <IncFaIcon
              className="marginRt8"
              iconName="plus"
            />
            Add Actions
          </VerticallyCenteredRow>
        );
      }
    },
    [actionsError, configError, disableOpEdit, isConditionsView, numActions]
  );

  const selectedTabId = isConditionsView ? "conditions" : "actions";

  return (
    <IncTabBar
      className="operationalise-v3--tab-bar"
      disableRouteChange
      handleSelection={handleSelection}
      selected={selectedTabId}
      tabRenderer={tabRenderer}
      tabs={tabs}
    />
  );
};
