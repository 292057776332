import React, { ChangeEvent, ReactNode, useCallback, useState, CSSProperties, memo } from "react";
import {
  IncButton,
  IncClickAway,
  IncPopper,
  IncTextfield,
  IncToolTip,
  IncFaIcon,
  IncFaIconName,
  nonBrandIncFaIconsList
} from "@inception/ui";
import { FormattedMessage } from "react-intl";
import { useAccessPrivilege } from "../core";

type UploadIconPopoverProps = {
  onSaveIcon: (iconName: IncFaIconName) => void;
  iconElem: ReactNode;
  noEditText?: boolean;
  iconStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
};

export const UploadIconPopover: React.FC<UploadIconPopoverProps> = memo(props => {
  const { onSaveIcon, iconElem, iconStyle, wrapperStyle, noEditText = false } = props;

  const [filteredIcons, setFilteredIconsList] = useState<IncFaIconName[]>(nonBrandIncFaIconsList);
  const [selectedIcon, setSelectedIcon] = useState<IncFaIconName>(null);

  const { canCreate, canEdit } = useAccessPrivilege();
  const hasAccessToUpdateIcon = canEdit || canCreate;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (hasAccessToUpdateIcon) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onSearchChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    const searchText = evt.target.value;

    if (!searchText) {
      setFilteredIconsList(nonBrandIncFaIconsList);
      return;
    }

    const icons = nonBrandIncFaIconsList.filter((i: IncFaIconName) => i.indexOf(searchText) !== -1);
    setFilteredIconsList(icons);
  }, []);

  const onSelectIcon = useCallback((selectedIcon: IncFaIconName) => {
    setSelectedIcon(selectedIcon);
  }, []);

  const onSave = useCallback(
    selection => {
      onSaveIcon(selection);
      handleClose();
    },
    [handleClose, onSaveIcon]
  );

  return (
    <div
      className="upload-icon-popover visible-on-hover"
      style={wrapperStyle}
    >
      <div
        className="view-more-text"
        onClick={handleClick}
        style={hasAccessToUpdateIcon ? { cursor: "pointer" } : {}}
      >
        {iconElem}
        {hasAccessToUpdateIcon && !noEditText && <div className="icon-edit-text display-element">Edit</div>}
      </div>
      <IncClickAway onClickAway={handleClose}>
        {refFn => (
          <IncPopper
            anchorEl={anchorEl}
            offset={{
              x: 0,
              y: 0
            }}
            placement="bottom"
            ref={refFn}
            show={open}
          >
            <div style={popperStyle}>
              <div>
                <div style={{ marginBottom: 16 }}>
                  <IncButton
                    color="primary"
                    disabled={!selectedIcon}
                    onClick={() => onSave(selectedIcon)}
                  >
                    <FormattedMessage id="common.actions.save" />
                  </IncButton>
                </div>

                <IncTextfield
                  onChange={onSearchChange}
                  placeholder="Search icons"
                />
              </div>

              <div
                className="biz-entity-config-icon-list-wrapper"
                style={{}}
              >
                {filteredIcons.map(icon => (
                  <IncToolTip
                    key={icon}
                    placement="top-start"
                    showArrow
                    titleText={icon}
                  >
                    <div
                      className={`inc-text-header-medium inc-flex-row inc-flex-center biz-entity-config-icon-list-wrapper-iconContainer ${selectedIcon === icon ? "iconContainerSelected" : ""}`}
                      key={icon}
                      onClick={event => {
                        event.stopPropagation();
                        onSelectIcon(icon);
                      }}
                      title={icon}
                    >
                      <IncFaIcon
                        iconName={icon}
                        style={iconStyle}
                      />
                    </div>
                  </IncToolTip>
                ))}
              </div>
            </div>
          </IncPopper>
        )}
      </IncClickAway>
    </div>
  );
});

const popperStyle: CSSProperties = {
  width: 340,
  padding: 16,
  height: 300,
  overflowX: "hidden"
};
