import { generateId, IncSmartText, IncToolTip } from "@inception/ui";
import React, { FC, useEffect, useState, useMemo, useCallback, useRef, memo, CSSProperties } from "react";
import getChartColor from "../../../../components/charts/colors";
import { IncHeatMapSerie, IncHeatMapTooltipFormatter } from "../../../../components/heat-map-v2";

interface Props {
  series: IncHeatMapSerie[];
  containerElemClass: string;
  bucketSize: number;

  getColorByValue?: (value: number) => string;
  tooltipFormatter?: IncHeatMapTooltipFormatter;
}

export const OneDimensionalHeatMap: FC<Props> = memo(props => {
  const { series, containerElemClass, tooltipFormatter, getColorByValue, bucketSize } = props;

  const uniqId = useMemo(() => generateId(), []);

  const [dimensions, setDimensions] = useState<Pick<DOMRect, "width" | "height">>({
    height: 0,
    width: 0
  });

  const { height, width } = dimensions;

  const chartContainerRef = useRef<HTMLDivElement>();

  const getChartDimensions = useCallback(() => {
    const canvasElem: HTMLElement = chartContainerRef.current;
    if (canvasElem) {
      const widgetItemContainer: Element = canvasElem.closest(`.${containerElemClass}`);
      const dimensions = widgetItemContainer?.getBoundingClientRect();
      if (dimensions) {
        const { width } = dimensions;
        const { height } = dimensions;
        return {
          width,
          height
        };
      }
    }
    return {
      width: 0,
      height: 0
    };
  }, [containerElemClass]);

  useEffect(() => {
    if (!width && !height) {
      // Calculate dimensions on every render
      const dimensions = getChartDimensions();
      setDimensions(dimensions);
    }
  }, [dimensions.height, dimensions.width, getChartDimensions, height, width]);

  const getColor = useMemo(() => getColorByValue || getChartColor, [getColorByValue]);

  const nodes = useMemo(() => {
    const serie = series[0];
    const { data } = serie;

    let numTicks = 0;

    const nodes: JSX.Element[] = [];
    const numDataPoints = data.length;
    data.forEach((node, idx) => {
      const isFirstEntry = idx === 0;
      const isLastEntry = idx === numDataPoints - 1;

      const isFirstEntryOfBucket = idx % bucketSize === 0;
      const isLastEntryOfBucket = idx % bucketSize === bucketSize - 1;
      const shouldAddTickLine = idx !== 0 && isFirstEntryOfBucket;
      const shouldAddTickText =
        numDataPoints / bucketSize < 5 ? true : (numTicks + (shouldAddTickLine ? 1 : 0)) % 2 === 1;
      // const shouldAddTickText = true;

      const { y, groupName, cellName } = node as Record<string, any>;

      const color = getColor(y);
      const nodeStyle: CSSProperties = {
        background: color,
        height: 20,
        minWidth: 10,
        maxWidth: 10,
        marginRight: isLastEntry ? "auto" : isLastEntryOfBucket ? 0 : 4,
        borderRadius: 2,
        opacity: 0.64,
        marginLeft: isFirstEntry ? "auto" : 0
      };

      if (shouldAddTickLine) {
        numTicks += 1;

        const tickStyle: CSSProperties = {
          minWidth: 2,
          maxWidth: 2,
          height: 32,
          opacity: 0.5,
          background: "#4A505C",
          margin: "0 8px"
        };

        const key = [uniqId, "tick", groupName, numTicks].join("_");
        const tickJsx = (
          <div
            className="inc-flex-column flex-gap-4 inc-flex-center"
            key={key}
          >
            <div style={tickStyle} />
            <IncSmartText
              className="inc-text-element inc-text-inactive"
              style={{ opacity: shouldAddTickText ? 1 : 0 }}
              text={groupName}
            />
          </div>
        );
        nodes.push(tickJsx);
      }

      const tooltipText = tooltipFormatter({
        data: node,
        value: y
      } as any);
      const titleElement = tooltipText ? (
        <div className="highcharts-tooltip">
          <div className="inc-charts-tooltip">{tooltipText}</div>
        </div>
      ) : null;

      const key = [uniqId, "node", groupName, cellName, idx].join("_");
      const nodeJsx = titleElement ? (
        <IncToolTip
          key={key}
          placement="top"
          titleElement={titleElement}
        >
          <div
            className="cursor-pointer"
            style={nodeStyle}
          />
        </IncToolTip>
      ) : (
        <div
          className="cursor-pointer"
          key={key}
          style={nodeStyle}
        />
      );
      nodes.push(nodeJsx);
    });

    return nodes;
  }, [bucketSize, getColor, series, tooltipFormatter, uniqId]);

  return (
    <div
      className="one-dim-heatmap-v2 width-100 height-100"
      ref={chartContainerRef}
    >
      <div
        className="inc-flex-row height-100 paddingBt8"
        style={{ overflow: "auto" }}
      >
        {nodes}
      </div>
    </div>
  );
});
