import { produce, setAutoFreeze } from "immer";
import { groupBy } from "lodash";
import { ChatBotStrategy, Conversation, ConversationEntry } from "../../services/api/chat";
import { getConversationListFromConversations } from "../utils";
import { NEW_CONVERSATION_ID } from "../constants";
import { ChatBotState, ChatBotStateAction } from "./types";
import {
  SET_CONVERSATIONS_LIST,
  SET_CONVERSATION,
  ADD_TO_CONVERSATION,
  SET_SELECTED_CONVERSATION_ID,
  SET_CONVERSATIONS,
  TOGGLE_HISTORY,
  REPLACE_CONVERSATION
} from "./action_names";

const chatBotReducer = (state: ChatBotState, action: ChatBotStateAction): ChatBotState => {
  switch (action.type) {
    case SET_CONVERSATIONS_LIST: {
      const conversationsList = action.payload as ConversationEntry[];
      const selectedConversationId = state?.selectedConversationId || NEW_CONVERSATION_ID;

      state.conversationsList = conversationsList;
      state.selectedConversationId = selectedConversationId;
      break;
    }

    case SET_CONVERSATION: {
      const conversation = action.payload as Conversation;
      const { conversationEntries: conversations, chatId } = conversation;
      state.conversationsMap[chatId] = {
        ...conversation,
        conversationEntries: [...(state.conversationsMap[chatId]?.conversationEntries || []), ...conversations]
      };
      break;
    }

    case SET_CONVERSATIONS: {
      const conversations = action.payload as Conversation[];
      const map = groupBy(conversations, c => c.chatId || c.conversationEntries[0]?.chatId);
      Object.entries(map).forEach(([chatId, conversations]) => {
        conversations.forEach(conversation => {
          const { conversationEntries: conversations } = conversation;
          state.conversationsMap[chatId] = {
            ...conversation,
            conversationEntries: [...(state.conversationsMap[chatId]?.conversationEntries || []), ...conversations]
          };
        });
      });
      break;
    }

    case ADD_TO_CONVERSATION: {
      const conversation = action.payload as ConversationEntry;
      const { chatId } = conversation;

      const isNewConversation = !state.conversationsMap[chatId]?.conversationEntries?.length;

      state.conversationsMap[chatId] = isNewConversation
        ? {
            chatId,
            conversationEntries: [],
            nextPage: -1,
            page: 1,
            strategy: ChatBotStrategy.DEFAULT,
            userId: ""
          }
        : state.conversationsMap[chatId] || {
            chatId,
            conversationEntries: [],
            nextPage: -1,
            page: 1,
            strategy: ChatBotStrategy.DEFAULT,
            userId: ""
          };

      const pConversationEntries = state.conversationsMap[chatId].conversationEntries;
      state.conversationsMap[chatId].conversationEntries = [...pConversationEntries, conversation];

      const conversations = Object.values(state.conversationsMap);
      const conversationList = getConversationListFromConversations(conversations).filter(Boolean);
      state.conversationsList = conversationList;

      if (isNewConversation) {
        state.selectedConversationId = chatId;
      }

      break;
    }

    case REPLACE_CONVERSATION: {
      const conversation = action.payload as ConversationEntry;
      const { chatId } = conversation;

      const isNewConversation = !state.conversationsMap[chatId]?.conversationEntries?.length;
      const emptyConversation: Conversation = {
        chatId,
        conversationEntries: [],
        nextPage: -1,
        page: 1,
        strategy: ChatBotStrategy.DEFAULT,
        userId: ""
      };
      state.conversationsMap[chatId] = isNewConversation
        ? emptyConversation
        : state.conversationsMap[chatId] || emptyConversation;

      const pConversationEntries = state.conversationsMap[chatId].conversationEntries.filter(
        entry => entry.message !== conversation.message
      );
      state.conversationsMap[chatId].conversationEntries = [...pConversationEntries, conversation];

      const conversations = Object.values(state.conversationsMap);
      const conversationList = getConversationListFromConversations(conversations).filter(Boolean);
      state.conversationsList = conversationList;

      if (isNewConversation) {
        state.selectedConversationId = chatId;
      }

      break;
    }

    case SET_SELECTED_CONVERSATION_ID: {
      const chatId = action.payload as string;
      state.selectedConversationId = chatId;
      break;
    }

    case TOGGLE_HISTORY: {
      state.showHistory = !state.showHistory;
      break;
    }

    default:
      return { ...state };
  }
};

setAutoFreeze(false);
export default produce(chatBotReducer);
