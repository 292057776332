import React, { FC, useState, useEffect, useRef, useCallback, useMemo } from "react";
import { cloneDeep, isEqual } from "lodash";
import { IncFaIcon, IncModal, IncModalProps, IncSelect, IncSelectOption } from "@inception/ui";
import {
  BizDataQuery,
  BuildingBlockConfig,
  ImpactedWidget,
  SliceSet,
  TimeObj,
  UserServiceField,
  WidgetConfigUtils
} from "../../../../services/api/explore";
import { CustomSelect, LoadingSpinner, VerticallyCenteredRow } from "../../../../components";
import { appendProjectionsToTheLabel, getLabelFromBizDataQuery } from "../BizDataQueryEditor/utils";
import { generateId, logger, useToggleState } from "../../../../core";
import { getWidgetConfigFromDto } from "../../../../utils/ExploreUtils";
import { compareObjectsWithSameProperties } from "../../../../utils";
import { SuppressionEditorPickerContext } from "./types";
import SuppressionQueryEditorV2 from "./SuppressionQueryEditorV2";

type ImpactWidgetOption = IncSelectOption<ImpactedWidget>;
interface Props {
  bizDataQuery: BizDataQuery;
  compareTimeSeconds?: number;
  onChange: (bizDataQuery: BizDataQuery) => void;

  pickerContext: SuppressionEditorPickerContext;

  rollingFunction: string;
  onRollingFunctionChange: (rollingFunction: string) => void;

  rollingFreq: TimeObj;
  onRollingFreqChange: (freq: TimeObj) => void;

  showBizDataQueryOnly?: boolean;
  getPredefinedMetrics?: () => Promise<ImpactedWidget[]>;
}

export const SuppressionQueryEditorWrapper: FC<Props> = props => {
  const {
    bizDataQuery: pBizDataQuery,
    rollingFreq: pRollingFreq,
    rollingFunction: pRollingFunction,
    getPredefinedMetrics,
    onChange,
    onRollingFreqChange,
    onRollingFunctionChange,
    pickerContext,
    showBizDataQueryOnly,
    compareTimeSeconds
  } = props;

  const [impactedWidgetList, setImpactedWidgetList] = useState<ImpactedWidget[]>([]);
  const {
    isOpen: isFetchingImpactedWidgets,
    open: startFetchingImpactedWidgets,
    close: stopFetchingImpactedWidgets
  } = useToggleState();

  const impactedWidgetOptions = useMemo<ImpactWidgetOption[]>(() => {
    const options = impactedWidgetList.map(impactedWidget => ({
      label: impactedWidget.name,
      value: impactedWidget.id,
      data: impactedWidget
    }));
    return [...options, customOption];
  }, [impactedWidgetList]);

  const { isOpen: isModalOpen, open: onOpenModal, close: closeModal } = useToggleState();

  const onCloseModal = useCallback(() => {
    setSelectedImpactedWidgetOption(null);
    closeModal();
  }, [closeModal]);

  const fetchImpactedWidget = useCallback(async () => {
    try {
      startFetchingImpactedWidgets();
      const widgets = await getPredefinedMetrics();
      setImpactedWidgetList(widgets);
    } catch (error) {
      logger.error("SuppressionQueryEditorWrapper", "Error fetching impacted widget", error);
    } finally {
      stopFetchingImpactedWidgets();
    }
  }, [getPredefinedMetrics, startFetchingImpactedWidgets, stopFetchingImpactedWidgets]);

  const [bizDataQuery, setBizDataQuery] = useState<BizDataQuery>(null);
  const { widgetConfig } = bizDataQuery || {};
  const [showQuery, setShowQuery] = useState(false);

  const [selectedImpactedWidgetOption, setSelectedImpactedWidgetOption] = useState<ImpactWidgetOption>(null);

  const fetchBizDataQueryOnChange = useCallback(async () => {
    setShowQuery(false);
    const query = await getBizDataQuery(pickerContext, cloneDeep(pBizDataQuery), pBizDataQuery?.sliceSpec?.sliceSet);
    setBizDataQuery(query);
    setTimeout(() => {
      setShowQuery(true);
    }, 10);
  }, [pBizDataQuery, pickerContext]);

  useEffect(() => {
    fetchBizDataQueryOnChange();
  }, [fetchBizDataQueryOnChange]);

  useEffect(() => {
    if (isModalOpen && showQuery && !isFetchingImpactedWidgets) {
      setSelectedImpactedWidgetOption(prev => {
        if (prev) {
          return prev;
        }
        const option = impactedWidgetOptions.find(option => {
          const { bizDataQuery } = option.data || {};
          const { widgetConfig: impactedWidgetConfig } = bizDataQuery || {};
          return compareObjectsWithSameProperties(impactedWidgetConfig, widgetConfig);
        });
        if (option) {
          return option;
        }
        return customOption;
      });
    }
  }, [impactedWidgetOptions, isFetchingImpactedWidgets, isModalOpen, showQuery, widgetConfig]);

  const { sliceSpec } = pBizDataQuery || {};
  const { sliceSet: defSliceSet } = sliceSpec || {};

  const onChangeOption = useCallback(
    async (option: ImpactWidgetOption) => {
      setSelectedImpactedWidgetOption(option);
      setShowQuery(false);
      const { bizDataQuery } = option?.data || {};
      if (option.value === "custom") {
        const query = await getBizDataQuery(pickerContext, null, defSliceSet);
        setBizDataQuery(query);
      } else if (bizDataQuery) {
        const query = await getBizDataQuery(pickerContext, cloneDeep(bizDataQuery));
        setBizDataQuery(query);
      }
      setTimeout(() => {
        setShowQuery(true);
      }, 10);
    },
    [defSliceSet, pickerContext]
  );

  useEffect(() => {
    if (isModalOpen && getPredefinedMetrics) {
      fetchImpactedWidget();
    }
  }, [fetchImpactedWidget, getPredefinedMetrics, isModalOpen]);

  const resetBizDataQuery = useCallback(() => fetchBizDataQueryOnChange(), [fetchBizDataQueryOnChange]);

  const [rollingFreq, setRollingFreq] = useState(pRollingFreq);

  const resetRollingFreq = useCallback(() => setRollingFreq(pRollingFreq), [pRollingFreq]);
  useEffect(() => {
    resetRollingFreq();
  }, [resetRollingFreq]);

  const [rollingFunction, setRollingFunction] = useState(pRollingFunction);
  const resetRollingFunction = useCallback(() => setRollingFunction(pRollingFunction), [pRollingFunction]);
  useEffect(() => {
    resetRollingFunction();
  }, [resetRollingFunction]);

  const { errorMessage, isValid } = useMemo(() => {
    const metrics = bizDataQuery?.widgetConfig?.dataDefinition?.metrics;
    const metricId = bizDataQuery?.sliceSpec?.metricId;
    let isValid = false;
    const selectedMetric = metrics?.[metricId];
    if (selectedMetric) {
      isValid = true;
    }
    const errorMessage = isValid ? "" : "Please select a valid metric";

    return {
      isValid,
      errorMessage
    };
  }, [bizDataQuery]);

  const onApplyChanges = useCallback(() => {
    onChange(bizDataQuery);
    onRollingFreqChange(rollingFreq);
    onRollingFunctionChange(rollingFunction);
    onCloseModal();
  }, [
    bizDataQuery,
    onChange,
    onCloseModal,
    onRollingFreqChange,
    onRollingFunctionChange,
    rollingFreq,
    rollingFunction
  ]);

  const onCancelChanges = useCallback(() => {
    resetBizDataQuery();
    resetRollingFreq();
    resetRollingFunction();
    onCloseModal();
  }, [onCloseModal, resetBizDataQuery, resetRollingFreq, resetRollingFunction]);

  const [label, setLabel] = useState<string>();

  const prevConstructPayloadRef = useRef<any>();

  const updateLabel = useCallback(async () => {
    const nBizDataQuery = cloneDeep(pBizDataQuery);
    const nConstructPayload = {
      bizDataQuery: nBizDataQuery,
      rollingFreq: pRollingFreq
    };

    const prevConstructPayload = prevConstructPayloadRef.current;
    if (!isEqual(prevConstructPayload, nConstructPayload) && nBizDataQuery) {
      setLabel(null);
      const rollingFreq = showBizDataQueryOnly ? null : pRollingFreq;
      const label = await getLabelFromBizDataQuery(nBizDataQuery, rollingFreq);
      if (label) {
        const { projections } = nBizDataQuery?.sliceSpec?.postAgg || {};
        const nLabel = appendProjectionsToTheLabel(label, projections);
        setLabel(nLabel);
      } else {
        setLabel(label);
      }
      prevConstructPayloadRef.current = nConstructPayload;
    }
  }, [pBizDataQuery, pRollingFreq, showBizDataQueryOnly]);

  useEffect(() => {
    updateLabel();
  }, [updateLabel]);

  const labelDiv = useMemo(() => {
    if (label) {
      return label;
    }

    if (label === null) {
      return <LoadingSpinner titleText=" " />;
    }

    return <div className="inc-label-common">Select</div>;
  }, [label]);

  const actions = useMemo<IncModalProps["actions"]>(
    () => ({
      primary: {
        label: "Apply",
        onClick: onApplyChanges,
        disabled: !isValid
      },
      secondary: {
        label: "Cancel",
        onClick: onCancelChanges
      },
      postJSX: !isValid && (
        <VerticallyCenteredRow className="status-danger inc-text-subtext-medium">
          <IncFaIcon
            className="marginRt6"
            iconName="exclamation"
          />
          {errorMessage}
        </VerticallyCenteredRow>
      )
    }),
    [errorMessage, isValid, onApplyChanges, onCancelChanges]
  );

  const childrenJsx = useMemo(
    () => (
      <div className="op-v3-editor-popper suppression-editor-popper-content">
        {Boolean(getPredefinedMetrics) && (
          <IncSelect
            autoSort={false}
            className="marginBt12"
            onChange={onChangeOption}
            options={impactedWidgetOptions}
            placeholder={"Select metric"}
            readOnly={isFetchingImpactedWidgets}
            value={selectedImpactedWidgetOption}
          />
        )}
        {showQuery && bizDataQuery && (
          <SuppressionQueryEditorV2
            bizDataQuery={bizDataQuery}
            compareTimeSeconds={compareTimeSeconds}
            onChange={setBizDataQuery}
            readonly={isFetchingImpactedWidgets}
          />
        )}
      </div>
    ),
    [
      bizDataQuery,
      compareTimeSeconds,
      getPredefinedMetrics,
      impactedWidgetOptions,
      isFetchingImpactedWidgets,
      onChangeOption,
      selectedImpactedWidgetOption,
      showQuery
    ]
  );

  return (
    <div className="biz-data-query-editor">
      <CustomSelect
        onClick={onOpenModal}
        placeholderText="Select"
        showClearButton={false}
        value={labelDiv}
      />
      <IncModal
        actions={actions}
        className="suppression-editor-popper"
        onClose={onCloseModal}
        show={isModalOpen}
        size="lg"
        titleText={"Update Query"}
      >
        {childrenJsx}
      </IncModal>
    </div>
  );
};

const getBizDataQuery = async (
  pickerContext: SuppressionEditorPickerContext,
  pBizDataQuery: BizDataQuery,
  defSliceSet?: SliceSet
): Promise<BizDataQuery> => {
  if (!pBizDataQuery) {
    const defaultQuery = getDefaultBizDataQuery(pickerContext?.eventTypeId, defSliceSet);
    return Promise.resolve(defaultQuery);
  }
  const query = await WidgetConfigUtils.getWidgetConfigBasedBizDataQuery(pBizDataQuery, true);
  return query;
};

const customOption: ImpactWidgetOption = {
  label: "Custom",
  value: "custom",
  data: null
};

const getDefaultBizDataQuery = (eventTypeId: string, defSliceSet?: SliceSet): BizDataQuery => {
  const buildingBlockConfigId = generateId();
  const buildingBlockConfig: BuildingBlockConfig = {
    id: buildingBlockConfigId,
    name: "Metric Name",
    aggregator: null,
    bizIdProps: {
      primary: {
        eventTypes: {
          userServiceInfo: [
            {
              userServiceEntityId: eventTypeId
            }
          ]
        }
      },
      secondary: {}
    },
    buildingBlockDef: {
      aggregator: "sum",
      fieldConfig: {
        userServiceField: {
          userServices: eventTypeId
            ? [
                {
                  userServiceEntityId: eventTypeId
                }
              ]
            : []
        } as UserServiceField
      },
      filters: {
        filterExpressions: []
      },
      sliceDef: {
        sliceSets: [
          {
            slices: []
          }
        ]
      }
    }
  };
  const widgetConfigDto = WidgetConfigUtils.getWidgetConfigDtoFromBuildingBlockConfig(buildingBlockConfig);

  const bizDataQuery: BizDataQuery = {
    sliceSpec: {
      selectorSpec: {
        filters: []
      },
      sliceSet: defSliceSet || {
        slices: []
      },
      postAgg: {
        projections: ["current"]
      },
      metricId: buildingBlockConfigId
    },
    widgetConfig: getWidgetConfigFromDto(widgetConfigDto)
  };

  return bizDataQuery;
};
