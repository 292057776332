import React, { FC, memo, useEffect, useMemo } from "react";
import { IncButton, IncFaIconName } from "@inception/ui";
import { logger, useTimeRange, useToggleState } from "../core";
import { GetIncidentInfoProjection, useFetchInsightSummaryInfo } from "../services/api/operationalise";
import timeRangeUtils from "../utils/TimeRangeUtils";
import { MarkdownRenderer, VerticallyCenteredRow } from ".";

interface Props {
  opConfigId: string;
  incidentId: string;
  generateDemoData: boolean;
}

export const CauseSummaryCard: FC<Props> = (props: Props) => {
  const { isOpen: showDetails, toggle: onToggleDetails } = useToggleState(false);

  return (
    <div className="cause-summary-card inc-flex-column flex-gap-16">
      <VerticallyCenteredRow>
        <span className="inc-text-header-semibold">Cause Summary</span>
        <IncButton
          className="marginLtAuto"
          color="link"
          onClick={onToggleDetails}
        >
          {showDetails ? "Hide details" : "View details"}
        </IncButton>
      </VerticallyCenteredRow>

      <SimpleCauseSummary
        {...props}
        hideExtraSummary={!showDetails}
      />
    </div>
  );
};

type CSProps = Props & {
  hideExtraSummary?: boolean;
};

export const SimpleCauseSummary = memo<CSProps>(props => {
  const { opConfigId, incidentId, generateDemoData, hideExtraSummary } = props;

  const { timeRange } = useTimeRange();

  const { fromMillis, toMillis } = useMemo(() => timeRangeUtils.getMillisFromTimeRange(timeRange), [timeRange]);

  const projections: GetIncidentInfoProjection[] = useMemo(() => [GetIncidentInfoProjection.CAUSE_SUMMARY_ENGLISH], []);

  const { data, error, isError, isFetching, refetch } = useFetchInsightSummaryInfo(
    opConfigId,
    incidentId,
    projections,
    fromMillis,
    toMillis,
    generateDemoData,
    true
  );

  useEffect(() => {
    if (incidentId && opConfigId) {
      refetch();
    }
  }, [incidentId, opConfigId, refetch]);

  useEffect(() => {
    if (isError) {
      logger.error("CauseSummaryCard", "Error fetching cause summary", error);
    }
  }, [isError, error]);

  const { causeSummaryString, causeSummaryStringExtra } = useMemo(() => {
    if (data?.causeSummary) {
      const {
        previousCount,
        currentCount,
        offsetMillis: offsetMillisStr,
        metricName,
        isSpikeGood,
        causeSummary,
        causeSummaryExtraInfo
      } = data.causeSummary;

      const offsetMillis = parseInt(offsetMillisStr, 10);
      const compareString = timeRangeUtils.getCompareStringFromTimeShiftMillis(offsetMillis);

      const trimmedCauseSummary = causeSummary
        ?.split("<br>")
        ?.filter(char => Boolean(char.trim()))
        ?.join("<br>");

      const isPositiveChange = isSpikeGood ? currentCount > previousCount : currentCount < previousCount;
      const iconName: IncFaIconName = currentCount > previousCount ? "arrow-up" : "arrow-down";

      return {
        causeSummaryString: trimmedCauseSummary || "",
        compareString,
        previousCount,
        currentCount,
        metricName,
        iconName,
        isPositiveChange,
        causeSummaryStringExtra: causeSummaryExtraInfo || ""
      };
    }

    return {
      causeSummaryString: "",
      previousCount: 0,
      currentCount: 0,
      metricName: "",
      compareString: "",
      iconName: null as IncFaIconName,
      isPositiveChange: false
    };
  }, [data]);

  const isLoading = isFetching || (!isError && !data);
  const causeSummaryExists = Boolean(causeSummaryString) || Boolean(causeSummaryStringExtra);

  return (
    <>
      {isLoading && (
        <div className="flex-gap-16 inc-flex-column">
          <div
            className="inc-text-subtext-medium width-100"
            data-show-loader="true"
          >
            Sample Cause Summary
          </div>
          <div
            className="inc-text-subtext-medium width-100"
            data-show-loader="true"
          >
            Sample Cause Summary
          </div>
          <div
            className="inc-text-subtext-medium width-100"
            data-show-loader="true"
          >
            Sample Cause Summary
          </div>
        </div>
      )}
      {!isLoading && (
        <>
          {isError && (
            <VerticallyCenteredRow className="status-danger">Error fetching Cause Summary</VerticallyCenteredRow>
          )}
          {!isError && (
            <>
              {causeSummaryExists && (
                <div className="inc-flex-column flex-gap-12">
                  <MarkdownRenderer mdContent={causeSummaryString} />

                  {!hideExtraSummary && <MarkdownRenderer mdContent={causeSummaryStringExtra} />}
                </div>
              )}
              {!causeSummaryExists && (
                <VerticallyCenteredRow className="inc-text-subtext-medium">No Cause Summary</VerticallyCenteredRow>
              )}
            </>
          )}
        </>
      )}
    </>
  );
});
