import React, { FC, useEffect, useState, useMemo, useRef, useCallback } from "react";
import {
  ExploreEntityFilter,
  IncidentTimelineResponse,
  ImpactWidgetDataPostAgg,
  TimeObjUnit,
  IncidentTimelineBin,
  UserServiceFilterList
} from "../../../services/api/explore";
import { TimeRange, dateTime } from "../../../core";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { getGroupKey, useFetchImpactedWidgetsData } from "../impact-widget/hooks";
import kbn from "../../../services/datasources/core/kbn";
import { VerticallyCenteredRow } from "../../flex-components";
import LoadingSpinner from "../../Loading/Loading";
import { IncidentTimelineContext } from "../types";
import { getMetricIdForImpactWidget } from "../utils";
import { getBinsFromImpactedWidgetData, getBinsFromIncidentResponse } from "./utils";
import { IncidentTimeline } from "./IncidentTimeline";
import { useFetchIncidentTimeline } from "./hooks";

interface Props {
  timelineContext: IncidentTimelineContext;

  numBins?: number;
  eventFieldFilters?: UserServiceFilterList;
  entityFilters?: ExploreEntityFilter[];

  onDataFetch?: (data: IncidentTimelineResponse, isLoading: boolean, error: string) => void;
  hideHeader?: boolean;
  accessorHeaderMap?: Record<string, string>;

  updateTimeRangeFromBins?: (tr: TimeRange) => void;
  minXAxisIntervalMs?: number;
  hideTimeline?: boolean;
  generateDemoData?: boolean;
}

export const IncidentTimelineWrapper: FC<Props> = props => {
  const {
    timelineContext,
    entityFilters: pEntityFilters,
    eventFieldFilters: pEventFieldFilters,
    numBins: pNumBins = 0,
    onDataFetch,
    hideHeader = false,
    accessorHeaderMap,
    updateTimeRangeFromBins: updateTimeRange,
    minXAxisIntervalMs,
    hideTimeline,
    generateDemoData
  } = props;

  const ref = useRef<HTMLDivElement>();
  const [numBins, setNumBins] = useState(pNumBins);

  const entityFilters = useMemo(() => pEntityFilters || [], [pEntityFilters]);
  const eventFieldFilters = useMemo(
    () =>
      pEventFieldFilters || {
        userServiceFilters: []
      },
    [pEventFieldFilters]
  );

  useEffect(() => {
    if (ref.current && !numBins) {
      const width = ref.current.getBoundingClientRect()?.width;
      if (width) {
        const numBins = Math.ceil(width / 60);
        setNumBins(numBins);
      } else {
        setNumBins(20);
      }
    }
  }, [numBins]);

  const { impactedWidgetContext, incidentId, type, opConfigId, timeRange, uniqueAlerts } = timelineContext;

  const impactMetricName = impactedWidgetContext?.impactedWidget?.name;

  const isIncidentMode = type === "incident";

  const { from, to } = useMemo(() => timeRangeUtils.getTimeRangeMillisFromRaw(timeRange.raw), [timeRange.raw]);

  const {
    impactedWidgets,
    groupBy: groupByTags,
    impactedWidgetId,
    groupKey
  } = useMemo(() => {
    if (isIncidentMode || !impactedWidgetContext) {
      return {
        impactedWidgets: [],
        groupBy: [],
        impactedWidgetId: null,
        groupKey: null
      };
    }

    const { alertingSlices, impactedWidget } = impactedWidgetContext;

    const impactedWidgets = [impactedWidget];

    let groupBy: string[] = [];
    // Need to fix alertingSlices empty issue
    if (alertingSlices?.length) {
      groupBy = [...alertingSlices];
    } else {
      groupBy = (impactedWidget?.bizDataQuery?.sliceSpec?.sliceSet?.slices || []).map(({ tagName }) => tagName);
    }

    return {
      impactedWidgets,
      groupBy,
      impactedWidgetId: impactedWidget.id,
      groupKey: getGroupKey(groupBy)
    };
  }, [impactedWidgetContext, isIncidentMode]);

  const { overTimeAgg, intervalSecs } = useMemo(() => {
    let intervalMs = Math.ceil(to - from) / (numBins || 1);
    intervalMs = minXAxisIntervalMs ? Math.max(intervalMs, minXAxisIntervalMs) : intervalMs;

    const roundedIntervalMs = kbn.round_interval(intervalMs);
    const intervalSecs = timeRangeUtils.getSecondsFromMillis(roundedIntervalMs);

    const overTimeAgg: ImpactWidgetDataPostAgg = {
      bins: null,
      timeDuration: {
        unit: TimeObjUnit.seconds,
        value: intervalSecs
      }
    };

    return {
      overTimeAgg,
      intervalSecs
    };
  }, [from, minXAxisIntervalMs, numBins, to]);

  const userServiceFilters = useMemo<Record<string, UserServiceFilterList>>(() => {
    const userServiceFilters: Record<string, UserServiceFilterList> = {};

    if (eventFieldFilters) {
      impactedWidgets.forEach(iw => {
        const metricId = getMetricIdForImpactWidget(iw);
        userServiceFilters[metricId] = eventFieldFilters;
      });
    }

    return userServiceFilters;
  }, [eventFieldFilters, impactedWidgets]);

  const {
    data: incidentTimelineResponse,
    error: incidentTimelineError,
    isError: isIncidentTimelineError,
    isFetching: isIncidentTimelineFetching,
    refetch: fetchIncidentTimeline
  } = useFetchIncidentTimeline(incidentId, numBins, eventFieldFilters, entityFilters, from, to, generateDemoData);

  const { refetch: fetchImpactedWidgetData, resultsByImpactedWidget } = useFetchImpactedWidgetsData(
    impactedWidgets,
    incidentId,
    opConfigId,
    groupByTags,
    entityFilters,
    userServiceFilters,
    from,
    to,
    groupByTags,
    overTimeAgg,
    intervalSecs,
    null,
    null,
    null,
    generateDemoData
  );

  useEffect(() => {
    if (numBins && incidentId && type === "incident") {
      fetchIncidentTimeline();
    }
  }, [fetchIncidentTimeline, incidentId, numBins, from, to, type]);

  useEffect(() => {
    if (!isIncidentMode && impactedWidgetId) {
      fetchImpactedWidgetData();
    }
  }, [fetchImpactedWidgetData, impactedWidgetId, isIncidentMode, type, entityFilters, userServiceFilters]);

  const {
    data: impactedWidgetData,
    error: impactedWidgetError,
    isError: isImpactedWidgetError,
    isFetching: isImpactedWidgetFetching
  } = resultsByImpactedWidget?.[impactedWidgetId]?.[groupKey] || {};

  const isLoading = !numBins || isIncidentTimelineFetching || isImpactedWidgetFetching;
  const isError = isIncidentTimelineError || isImpactedWidgetError;
  const error = incidentTimelineError || impactedWidgetError;

  const bins = useMemo(() => {
    if (isIncidentMode) {
      return getBinsFromIncidentResponse(incidentTimelineResponse, timeRange, uniqueAlerts);
    }

    return getBinsFromImpactedWidgetData(impactedWidgetData, from, to);
  }, [from, impactedWidgetData, incidentTimelineResponse, isIncidentMode, timeRange, to, uniqueAlerts]);

  const onBinsSelect = useCallback(
    (bins: IncidentTimelineBin[]) => {
      let startTimeMillis = Number.POSITIVE_INFINITY;
      let endTimeMillis = Number.NEGATIVE_INFINITY;

      bins.forEach(bin => {
        startTimeMillis = Math.min(startTimeMillis, bin.startTimeMillis);
        endTimeMillis = Math.max(endTimeMillis, bin.endTimeMillis);
      });

      if (Number.isFinite(startTimeMillis) && Number.isFinite(endTimeMillis)) {
        const tr: TimeRange = {
          from: dateTime(startTimeMillis),
          to: dateTime(endTimeMillis),
          raw: {
            from: startTimeMillis.toString(),
            to: endTimeMillis.toString(),
            fromType: "absolute",
            toType: "absolute"
          }
        };

        updateTimeRange && updateTimeRange(tr);
      }
    },
    [updateTimeRange]
  );

  useEffect(() => {
    if (onDataFetch) {
      const isDataFetching = isIncidentTimelineFetching || isImpactedWidgetFetching;
      const dataError = incidentTimelineError || impactedWidgetError;
      const timelineResponse: IncidentTimelineResponse = {
        bins,
        alert: {
          lookupData: impactedWidgetData?.postAggEntityLookupData || {}
        } as any
      };
      onDataFetch(timelineResponse, isDataFetching, dataError);
    }
  }, [
    bins,
    impactedWidgetData,
    impactedWidgetError,
    incidentTimelineError,
    incidentTimelineResponse,
    isImpactedWidgetFetching,
    isIncidentTimelineFetching,
    onDataFetch
  ]);

  return hideTimeline ? (
    <></>
  ) : (
    <div
      className="incident-timeline-wrapper"
      ref={ref}
    >
      {!hideHeader && <div className="inc-text-body-medium marginBt16">Timeline</div>}

      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          {isError && (
            <VerticallyCenteredRow className="error-message">
              Error fetching data for timeline: {error}
            </VerticallyCenteredRow>
          )}
          {!isError && (
            <>
              {Boolean(bins?.length) && (
                <IncidentTimeline
                  accessorHeaderMap={accessorHeaderMap}
                  bins={bins}
                  impactMetricName={impactMetricName}
                  isMetricMode={!isIncidentMode}
                  minXAxisIntervalMs={minXAxisIntervalMs}
                  onSelect={onBinsSelect}
                  timeRange={timeRange}
                />
              )}
              {!bins?.length && (
                <VerticallyCenteredRow className="message inc-flex-center">
                  No incidents exist for the selected time range
                </VerticallyCenteredRow>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
