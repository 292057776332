import Highcharts from "highcharts";
import HCMore from "highcharts/highcharts-more";
import Boost from "highcharts/modules/boost";

HCMore(Highcharts);
Boost(Highcharts);

Highcharts.setOptions({
  time: {
    useUTC: false
  }
});

export default Highcharts;
