import React, { FC, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HCMore from "highcharts/highcharts-more";
import Variwide from "highcharts/modules/variwide";
import { IncFaIcon } from "@inception/ui";
import { CorrelatedEventsResponse, IncidentTimelineBin } from "../../../services/api/explore";
import { VerticallyCenteredRow } from "../../flex-components";
import { useChangeEventGraphBuilder } from "./useChangeEventGraphBuilder";

HCMore(Highcharts);
Variwide(Highcharts);

interface Props {
  bins: IncidentTimelineBin[];
  correlatedEventsResponse: CorrelatedEventsResponse;
  timeSeriesAxisLabels: number[];
}

export const ChangeEventTimeline: FC<Props> = props => {
  const { highChartsCallback, options, ref } = useChangeEventGraphBuilder(props);
  const legends = useMemo(
    () => (
      <VerticallyCenteredRow className="marginTp8">
        <IncFaIcon
          className="marginLt12"
          iconName="calendar-days"
        />
        <span className="inc-text-subtext marginLt6">Change Events</span>
      </VerticallyCenteredRow>
    ),
    []
  );

  return (
    <div className="impact-analysis-viz">
      <div className="incident-timeline">
        <div
          className="chart-container"
          ref={ref}
        >
          <HighchartsReact
            callback={highChartsCallback}
            highcharts={Highcharts}
            options={options}
          />
        </div>
      </div>
      {legends}
    </div>
  );
};
