import { useCallback, useMemo } from "react";
import { InitialState, FetchCallBackResult, useDataFetch } from "../../../../core";
import { FunnelQuerySchema, journeyApiService } from "../../../../services/api/explore";

export const useFetchFunnelQuerySchema = (funnelId: string, disableAutoFetch = false) => {
  const initialState = useMemo<InitialState<FunnelQuerySchema, string>>(
    () => ({
      data: {
        funnelConfig: null
      },
      error: "",
      isError: false,
      isFetching: !disableAutoFetch,
      isSuccess: false
    }),
    [disableAutoFetch]
  );

  const fetchFn = useCallback(async () => {
    const result: FetchCallBackResult<FunnelQuerySchema, string> = {
      data: {
        funnelConfig: null
      },
      error: "",
      isError: false,
      isSuccess: false
    };

    const { data, error, message } = await journeyApiService.getFunnelQuerySchema(funnelId);

    if (error) {
      result.isError = true;
      result.error = message;
    } else {
      result.data = data;
      result.isSuccess = true;
    }

    return result;
  }, [funnelId]);

  return useDataFetch(fetchFn, initialState, disableAutoFetch);
};
