import { isEmpty } from "lodash";
import React, { useEffect, FC, useCallback, useMemo, CSSProperties } from "react";
import { EntityPropertyValue, generateId } from "../../../../../core";
import { ActionTemplateElementProp, TemplateCanvasSection } from "../../../../../services/api/operationalise";
import { SelectEditor } from "./SelectEditor";
import { TextBoxEditor } from "./TextBoxEditor";
import { ToggleEditor } from "./ToggleEditor";

interface PropertiesEditorProps {
  properties: ActionTemplateElementProp[];
  section: TemplateCanvasSection;
  onChange: (section: TemplateCanvasSection) => void;
  skipTitle?: boolean;
}

export const SectionPropertiesEditor: FC<PropertiesEditorProps> = props => {
  const { properties, section, onChange, skipTitle = false } = props;

  const uniqId = useMemo(() => generateId(), []);

  const onPropsChange = useCallback(
    (propId: string, value: EntityPropertyValue) => {
      const nSection: TemplateCanvasSection = {
        ...section,
        prop: {
          propValue: {
            ...section?.prop?.propValue,
            [propId]: value
          }
        }
      };
      onChange(nSection);
    },
    [onChange, section]
  );

  useEffect(() => {
    if (section && isEmpty(section.prop) && properties.length) {
      const propValue = properties.reduce(
        (acc, prop) => ({
          ...acc,
          [prop.propId]: prop.defaultValue
        }),
        {} as Record<string, EntityPropertyValue>
      );

      onChange({
        ...section,
        prop: {
          propValue
        }
      });
    }
  }, [onChange, properties, section]);

  const propertiesElements = useMemo(
    () =>
      properties
        .sort((a, b) => a.fieldOrder - b.fieldOrder)
        .map(p => {
          const key = [p.propId, uniqId].join("_");
          const propValue = section?.prop?.propValue?.[p.propId];

          return (
            <div
              className="property-entry"
              key={key}
            >
              {renderPropField(p, onPropsChange, propValue)}
            </div>
          );
        }),
    [onPropsChange, properties, section?.prop?.propValue, uniqId]
  );

  const style: CSSProperties = {
    marginLeft: skipTitle ? 0 : 12
  };

  if (!propertiesElements.length) {
    return <></>;
  }

  return (
    <div className="section-editor--properties-editor">
      {!skipTitle && <div className="title marginBt8">Properties</div>}
      <div
        className="properties-list"
        style={style}
      >
        {propertiesElements}
      </div>
    </div>
  );
};

const renderPropField = (
  property: ActionTemplateElementProp,
  onPropsChange: (propId: string, value: EntityPropertyValue) => void,
  value?: EntityPropertyValue
) => {
  switch (property.editor) {
    case "text_box": {
      return (
        <TextBoxEditor
          onPropsChange={onPropsChange}
          property={property}
          value={value}
        />
      );
    }
    case "multi_select": {
      return (
        <SelectEditor
          isMulti
          onPropsChange={onPropsChange}
          property={property}
          value={value}
        />
      );
    }
    case "single_select": {
      return (
        <SelectEditor
          isMulti={false}
          onPropsChange={onPropsChange}
          property={property}
          value={value}
        />
      );
    }
    case "toggle":
      return (
        <ToggleEditor
          onPropsChange={onPropsChange}
          property={property}
          value={value}
        />
      );
    default:
      return null;
  }
};
