import { AxiosError } from "axios";
import { useCallback, useMemo } from "react";
import { FetchCallBackResult, FetchFn, InitialState, useDataFetch } from "../../../../../../../../core";
import {
  CohortEntityFilter,
  MetricUserServiceFilters,
  SliceSpec,
  WidgetConfigDTO,
  WidgetDataDTO,
  exploreApiService
} from "../../../../../../../../services/api/explore";
import { EventChartType, getIntervalSecs } from "../eventTimelineUtils";

export const useFetchWidgetTimeline = (
  entityType: string,
  widgetConfig: WidgetConfigDTO,
  fromMillis: number,
  toMillis: number,
  downsample: string,
  sliceSpec: SliceSpec[],
  widgetId?: string,
  entityId?: string,
  version?: number,
  cohortFilters?: CohortEntityFilter[],
  metricUserServiceFilters?: MetricUserServiceFilters,
  numBins = 10,
  chartType?: EventChartType
) => {
  const initialState = useMemo<InitialState<WidgetDataDTO, string>>(
    () => ({
      data: null,
      error: null,
      isError: false,
      isFetching: false
    }),
    []
  );

  const fetchFn = useCallback<FetchFn<WidgetDataDTO, string>>(async () => {
    const result: FetchCallBackResult<WidgetDataDTO, string> = {
      data: null,
      error: null,
      isError: false,
      isSuccess: false
    };
    const intervalSecs = getIntervalSecs(fromMillis, toMillis, downsample, numBins, chartType);

    try {
      if (widgetId) {
        const { data, error, message } = await exploreApiService.getWidgetData(
          entityId || "",
          entityType,
          widgetId,
          fromMillis,
          toMillis,
          intervalSecs,
          sliceSpec,
          null,
          null,
          cohortFilters,
          [],
          true,
          -1,
          false,
          false,
          metricUserServiceFilters
        );
        if (error) {
          result.isError = true;
          result.error = message;
        } else {
          result.isSuccess = true;
          result.data = data;
        }
      } else {
        const { data, error, message } = await exploreApiService.getWidgetDataByConfig(
          entityType,
          entityId || "",
          widgetConfig,
          version,
          fromMillis,
          toMillis,
          intervalSecs,
          sliceSpec,
          null,
          null,
          [],
          true,
          -1
        );
        if (error) {
          result.isError = true;
          result.error = message;
        } else {
          result.isSuccess = true;
          result.data = data;
        }
      }
    } catch (err) {
      result.isError = true;
      const axiosError = err as AxiosError;
      if (axiosError.isAxiosError) {
        result.error = axiosError.message;
      } else {
        result.error = err.message?.toString() || err.toString();
      }
    }

    return result;
  }, [
    chartType,
    cohortFilters,
    downsample,
    entityId,
    entityType,
    fromMillis,
    metricUserServiceFilters,
    numBins,
    sliceSpec,
    toMillis,
    version,
    widgetConfig,
    widgetId
  ]);

  return useDataFetch(fetchFn, initialState, true);
};
