import React, { ReactNode, useCallback } from "react";
import { IncMultiSelect, MultiSelectOption } from "@inception/ui";
import { uniq } from "lodash";

const constructSelectOptions = (
  key: string,
  options: string[],
  optionsInfo: string[],
  optionsLabelLookup: Record<string, string>
) =>
  options.map((option, idx) => ({
    label: optionsLabelLookup?.[option] || option,
    value: option,
    meta: optionsInfo[idx],
    key
  }));

type SeriesSelectItemProps = {
  id: string;
  options: MultiSelectOption[];
  defaultOptions: MultiSelectOption[];
  onSelectChange: (args: MultiSelectOption[], id: string, isAllSelected: boolean) => void;
  maxWidth?: number;
  menuAsPortal: boolean;
  itemsCount?: number;
  /**
   * displays literal 'All' as selected items if all items are selected
   */
  displayLiteralAll?: boolean;
  hideOptionsCount?: boolean;
  allowCreate?: boolean;
  skipAllOption?: boolean;
  disabled?: boolean;
  label?: string;

  isLoading?: boolean;
};

export const SeriesSelectItem: React.FC<SeriesSelectItemProps> = React.memo(props => {
  const {
    id,
    options,
    defaultOptions,
    onSelectChange,
    maxWidth,
    menuAsPortal,
    itemsCount = 1,
    displayLiteralAll,
    allowCreate,
    skipAllOption,
    disabled,
    label = id,
    isLoading,
    hideOptionsCount
  } = props;

  const onSeriesSelectionChange = useCallback(
    (selected, isAllSelected) => {
      onSelectChange(selected, id, isAllSelected);
    },
    [id, onSelectChange]
  );

  return (
    <IncMultiSelect
      allowCreate={allowCreate}
      disabled={disabled}
      displayLiteralAll={displayLiteralAll}
      hideOptionsCount={hideOptionsCount}
      isLoading={isLoading}
      itemsCount={itemsCount}
      label={label}
      maxWidth={maxWidth}
      menuAsPortal={menuAsPortal}
      onSelectChange={onSeriesSelectionChange}
      options={options}
      skipAllOption={skipAllOption}
      value={defaultOptions}
    />
  );
});

type MultipleSelectsProps = {
  selectedOptions: Map<string, string[]>;
  optionsInfo?: Map<string, string[]>;
  groupOptions?: Map<string, string[]>;
  labels?: Record<string, string>;
  optionsLabelLookup?: Record<string, Record<string, string>>;
  onSeriesSelectionChanged: (args: Map<string, string[]>, id?: string, isAllSelected?: boolean) => void;
  parentElmWidth: number;
  menuAsPortal?: boolean;
  className?: string;
  hideSelectIfNoOptions?: boolean;
  itemsCount?: number;
  hideOptionsCount?: boolean;
  /**
   * displays literal 'All' as selected items if all items are selected
   */
  displayLiteralAll?: boolean;
  skipAllOption?: Record<string, boolean>;
  disabled?: boolean;
};

export const MultipleSelects: React.FC<MultipleSelectsProps> = props => {
  const {
    groupOptions,
    selectedOptions,
    optionsInfo,
    onSeriesSelectionChanged,
    parentElmWidth,
    menuAsPortal,
    className: pClassName = "",
    hideSelectIfNoOptions,
    itemsCount = 1,
    displayLiteralAll,
    skipAllOption,
    disabled,
    labels,
    optionsLabelLookup,
    hideOptionsCount
  } = props;

  const onSeriesSelectionChange = useCallback(
    (selected: MultiSelectOption[], id?: string, isAllSelected?: boolean) => {
      const optionsSelected: Map<string, string[]> = new Map();

      selected.forEach((option: MultiSelectOption) => {
        const { key, value } = option;

        if (!optionsSelected.has(key)) {
          optionsSelected.set(key, []);
        }
        const values = optionsSelected.get(key);

        optionsSelected.set(key, uniq([...values, value]));
      });
      onSeriesSelectionChanged(optionsSelected, id, isAllSelected);
    },
    [onSeriesSelectionChanged]
  );

  const constructMultipleSelects = useCallback(() => {
    const nodes: ReactNode[] = [];
    const maxWidthPossible = Math.floor(parentElmWidth / groupOptions.size);

    for (const [key, value] of groupOptions.entries()) {
      const skipAllOpt = (skipAllOption || {})[key] ?? false;
      const optionsInfoByKey = optionsInfo?.get(key) || [];
      const optionsLabelLookupByKey = optionsLabelLookup?.[key] || {};
      const selectOptions = constructSelectOptions(key, value, optionsInfoByKey, optionsLabelLookupByKey);
      const selectedOptionsByKey = selectedOptions.get(key);
      let fOptions: MultiSelectOption[] = [];

      if (selectedOptionsByKey) {
        fOptions = constructSelectOptions(key, selectedOptionsByKey, optionsInfoByKey, optionsLabelLookupByKey);
      }

      if (!hideSelectIfNoOptions || (hideSelectIfNoOptions && value.length > 0)) {
        nodes.push(
          <SeriesSelectItem
            defaultOptions={fOptions}
            disabled={disabled}
            displayLiteralAll={displayLiteralAll}
            hideOptionsCount={hideOptionsCount}
            id={key}
            itemsCount={itemsCount}
            key={key}
            label={labels?.[key]}
            maxWidth={maxWidthPossible}
            menuAsPortal={menuAsPortal}
            onSelectChange={onSeriesSelectionChange}
            options={selectOptions}
            skipAllOption={skipAllOpt}
          />
        );
      }
    }
    return nodes;
  }, [
    parentElmWidth,
    groupOptions,
    skipAllOption,
    optionsInfo,
    optionsLabelLookup,
    selectedOptions,
    hideSelectIfNoOptions,
    disabled,
    displayLiteralAll,
    hideOptionsCount,
    itemsCount,
    labels,
    menuAsPortal,
    onSeriesSelectionChange
  ]);

  const className = `inc-multiple-selects ${pClassName}`;

  return <div className={className}>{constructMultipleSelects()}</div>;
};
