import React, { FC, useCallback, useMemo } from "react";
import { IncFaIcon, IncSmartText, IncToolTip } from "@inception/ui";
import { uniqBy } from "lodash";
import {
  FieldPickerContextDTO,
  FieldPickerOptionData,
  UserServiceField,
  UserServiceFieldSlice,
  UserServiceTuple
} from "../../services/api/explore";
import { FieldPickerContainer, VerticallyCenteredRow } from "../../components";
import { FieldPickerUtils } from "../../utils";
import { ALL_USERSERVICES_ENTITY_TYPE_ID } from "../../utils/ExploreUtils";
import { setAnalysisDiagnosticFields, useOpAnalysisStore } from "./context";

type Props = {
  eventTypeId: string;
};

const DiagnosticFieldsEditorWrapper: FC<Props> = () => {
  const { state, dispatch } = useOpAnalysisStore();
  const { opAnalysisImpl } = state;
  const diagnosticFields = useMemo(() => opAnalysisImpl.getDiagnosticFields() || [], [opAnalysisImpl]);
  const kpis = useMemo(() => opAnalysisImpl.getKpis(), [opAnalysisImpl]);
  const commonUserServices = useMemo<UserServiceTuple[]>(() => {
    const kpiValues = Object.values(kpis);
    const userServices: UserServiceTuple[] = [];
    kpiValues.forEach(e => {
      const { bizDataQuery } = e;
      const { idProps } = bizDataQuery || {};
      const { primary } = idProps || {};
      const { eventTypes } = primary || {};
      const { userServiceInfo = [] } = eventTypes || {};
      if (userServiceInfo.length) {
        userServices.push(...userServiceInfo);
      }
    });
    const uniqUserServices = uniqBy(userServices, e => e.userServiceEntityId);
    return uniqUserServices;
  }, [kpis]);

  const context = useMemo<FieldPickerContextDTO>(
    () => ({
      userServices: commonUserServices?.length > 1 ? commonUserServices : null,
      entityId:
        commonUserServices?.length > 1
          ? ""
          : commonUserServices?.length
            ? commonUserServices?.[0]?.userServiceEntityId
            : "",
      entityName: "",
      entityType: commonUserServices?.length > 1 ? ALL_USERSERVICES_ENTITY_TYPE_ID : ""
    }),
    [commonUserServices]
  );

  const selectedOptions = useMemo<FieldPickerOptionData[]>(
    () =>
      diagnosticFields.map(dimension => ({
        type: "userServiceField",
        payload: dimension.userServiceField
      })),
    [diagnosticFields]
  );

  const onChange = useCallback(
    (options: FieldPickerOptionData[]) => {
      const usFields = options
        .filter(option => option.type === "userServiceField")
        .map(option => option.payload as unknown as UserServiceField);
      const nDimensions = usFields.map((usField): UserServiceFieldSlice => {
        const tagName = FieldPickerUtils.getPromSanitizedUSFName(usField);
        return {
          tagName,
          userServiceField: usField
        };
      });
      dispatch(setAnalysisDiagnosticFields(nDimensions));
    },
    [dispatch]
  );

  return (
    <div className="analysis-editor--dimensions-editor">
      <VerticallyCenteredRow className="flex-gap-12">
        <IncSmartText text="Diagnostic Fields" />
        {!selectedOptions.length && (
          <IncToolTip
            showArrow
            titleText="Select Fields"
            variant="error"
          >
            <IncFaIcon
              className="status-danger inc-cursor-pointer"
              iconName="warning"
            />
          </IncToolTip>
        )}
      </VerticallyCenteredRow>
      <FieldPickerContainer
        fieldPickerContextDto={context}
        fieldTypes={["userServiceField"]}
        isMulti
        label=""
        onChange={onChange}
        selectedOptions={selectedOptions}
        showCommonUSFieldsOnly
      />
    </div>
  );
};

export default DiagnosticFieldsEditorWrapper;
