import React, { FC, useMemo, useCallback, useRef } from "react";
import { IncSelectOption, IncToggle, IncTextfield, IncSelect } from "@inception/ui";
import { css, cx } from "emotion";
import { MetricTableConfig, MetricTableProperties } from "../types";
import { VerticallyCenteredRow } from "../../flex-components";

interface Props {
  metricTableConfig: MetricTableConfig;
  onChange: (tableConfig: Partial<MetricTableConfig>) => void;
}

export const PropertiesTab: FC<Props> = props => {
  const { metricTableConfig, onChange } = props;

  const { metricColumns, slices, properties: pProperties } = metricTableConfig;

  const propertiesRef = useRef(pProperties || {});
  useMemo(() => {
    propertiesRef.current = pProperties || {};
  }, [pProperties]);

  const onPropertyChange = useCallback(
    (partConfig: Partial<MetricTableProperties>) => {
      onChange({
        properties: {
          ...propertiesRef.current,
          ...partConfig
        }
      });
    },
    [onChange]
  );

  const {
    // filterable = true,
    pagination,
    showDisplayStats = true,
    sort,
    seriesLimit = 1000
  } = propertiesRef.current;

  // const setFilterable = useCallback((filterable: boolean) => {
  //   onPropertyChange({ filterable });
  // }, [onPropertyChange]);

  const setPagination = useCallback(
    (pagination: Partial<MetricTableProperties["pagination"]>) => {
      onPropertyChange({
        pagination: {
          enabled: true,
          pageSize: 20,
          ...(propertiesRef.current.pagination || ({} as any)),
          ...pagination
        }
      });
    },
    [onPropertyChange]
  );

  const setShowDisplayStats = useCallback(
    (showDisplayStats: boolean) => {
      onPropertyChange({ showDisplayStats });
    },
    [onPropertyChange]
  );

  const setSort = useCallback(
    (sort: Partial<MetricTableProperties["sort"]>) => {
      onPropertyChange({
        sort: {
          order: "desc",
          ...(propertiesRef.current.sort || ({} as any)),
          ...sort
        }
      });
    },
    [onPropertyChange]
  );

  const onPageSizeChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const { valueAsNumber } = evt.target;
      if (isNaN(valueAsNumber)) {
        setPagination({ pageSize: 0 });
      } else {
        setPagination({ pageSize: valueAsNumber });
      }
    },
    [setPagination]
  );

  const onPaginationEnableChange = useCallback(
    (enabled: boolean) => {
      setPagination({ enabled });
    },
    [setPagination]
  );

  const sortOptions = useMemo<IncSelectOption[]>(() => {
    const options: IncSelectOption[] = [];

    metricColumns.forEach(column =>
      options.push({
        label: column.name,
        value: column.id
      })
    );

    slices.forEach(slice =>
      options.push({
        label: slice.name,
        value: slice.slice.tagName
      })
    );

    return options;
  }, [metricColumns, slices]);

  const selSortOpt = useMemo<IncSelectOption | null>(
    () => sortOptions.find(opt => opt.value === sort?.accessor),
    [sort, sortOptions]
  );
  const selSortOrderOpt = useMemo<IncSelectOption | null>(
    () => sortOrderOptions.find(opt => opt.value === sort?.order),
    [sort]
  );

  const onSortOptionChange = useCallback(
    (opt: IncSelectOption) => {
      setSort({
        accessor: opt.value
      });
    },
    [setSort]
  );

  const onSortDirectionChange = useCallback(
    (opt: IncSelectOption) => {
      setSort({
        order: opt.value as any
      });
    },
    [setSort]
  );

  const onSeriesLimitChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const { valueAsNumber } = evt.target;
      if (isNaN(valueAsNumber)) {
        onPropertyChange({ seriesLimit: 0 });
      } else {
        onPropertyChange({ seriesLimit: valueAsNumber });
      }
    },
    [onPropertyChange]
  );

  return (
    <>
      {/* <IncToggle
      checked={filterable}
      className="marginBt12"
      label="Allow filters"
      onChange={setFilterable}
    /> */}

      <IncToggle
        checked={showDisplayStats}
        className="marginBt12"
        label="Show display stats"
        onChange={setShowDisplayStats}
      />

      <IncTextfield
        containerClassName="inc-flex-row marginBt12"
        label="Series limit"
        onChange={onSeriesLimitChange}
        type="number"
        value={seriesLimit}
      />

      <div className={className}>
        <div className="inc-text-body-medium marginBt8">Pagination</div>

        <VerticallyCenteredRow>
          <IncToggle
            checked={pagination?.enabled || false}
            className="marginRt12 marginTp24"
            label="Enable"
            onChange={onPaginationEnableChange}
          />

          <IncTextfield
            containerClassName="inc-flex-row"
            label="Page size"
            onChange={onPageSizeChange}
            type="number"
            value={pagination?.pageSize || 20}
          />
        </VerticallyCenteredRow>
      </div>

      <div className={className}>
        <div className="inc-text-body-medium marginBt8">Sort</div>

        <VerticallyCenteredRow>
          <IncSelect
            label="Sort by"
            onChange={onSortOptionChange}
            options={sortOptions}
            value={selSortOpt}
            wrapperClass="marginRt12"
          />

          <IncSelect
            label="Order"
            onChange={onSortDirectionChange}
            options={sortOrderOptions}
            value={selSortOrderOpt}
          />
        </VerticallyCenteredRow>
      </div>
    </>
  );
};

const sortOrderOptions: IncSelectOption[] = [
  {
    label: "Ascending",
    value: "asc"
  },
  {
    label: "Descending",
    value: "desc"
  }
];

const cssClassName = css`
  background: #2a343e !important;

  .ui-biz-flow--derived-node-editor {
    background: transparent;
  }

  .inception-select {
    min-width: 240px;
  }
`;
const className = cx("inc-card-layout display-block width-100 marginBt12", cssClassName);
