import { useMemo, useState, useCallback } from "react";
import { range, forEach } from "lodash";
import { TimeRange, useSchemaStore, shouldExcludeTag, logger } from "../../../core";
import { getMillisFromTimeObj } from "../../../utils";
import {
  UserServiceFieldSlice,
  SelectorSpec,
  SortSpec,
  SelectorTag,
  WidgetResponseDTO
} from "../../../services/api/explore";
import { EntityWidgetData } from "../../../biz-entity";
import { MetricTableConfig, MetricTableVariableContext, MetricTableColumnInfo } from "../types";
import {
  getMetricData,
  getEntityData,
  MetricTableQueryResult,
  getDataFramesFromEntityDataByProjection
} from "../queryUtils";

export type MetricTableDataFetchState = MetricTableQueryResult & {
  isFetching: boolean;
};

export const DEF_SERIES_LIMIT = 1000;

export const useFetchMetricTableData = (
  metricTableConfig: MetricTableConfig,
  timeRange: TimeRange,
  widgetResponseDTOMap: Record<string, WidgetResponseDTO>,
  variablesContext?: MetricTableVariableContext
) => {
  const { metricColumns, slices, properties } = metricTableConfig;

  const sort = useMemo(() => properties?.sort, [properties]);
  const seriesLimit = useMemo(() => properties?.seriesLimit || DEF_SERIES_LIMIT, [properties]);

  const usfSlices = useMemo(() => slices.map(slice => slice.slice), [slices]);

  const numMetrics = metricColumns.length;
  const [dataFetchState, setDataFetchState] = useState<MetricTableDataFetchState[]>(getDefaultState(numMetrics));

  const { entityTypes } = useSchemaStore();
  const entityTypeMap = useMemo(() => {
    const entityTypeMap: Record<string, string> = {};
    forEach(entityTypes, typeInfo => {
      const { typeReference } = typeInfo;
      const { id, typeName } = typeReference;
      entityTypeMap[id] = typeName;
    });
    return entityTypeMap;
  }, [entityTypes]);

  const fetchData = useCallback(async () => {
    setDataFetchState(getDefaultState(numMetrics));
    const nState = getDefaultState(numMetrics);

    let sortColumnIdx = metricColumns.findIndex(c => c.id === sort?.accessor);
    sortColumnIdx = sortColumnIdx === -1 ? 0 : sortColumnIdx;

    const sortColumn = metricColumns[sortColumnIdx];

    const sortSpec: SortSpec = {
      limitSpec: {
        function: sort?.order === "asc" ? "bottom" : "top",
        limit: seriesLimit
      },
      sortBy: sortColumn.queryConfig.metricQueryConfig?.projection || "current"
    };

    const widgetResponseDTO = widgetResponseDTOMap[sortColumn.id];
    const sortDataResponse = await getMetricColumnData(
      sortColumn,
      timeRange,
      usfSlices,
      sortColumnIdx,
      entityTypeMap,
      widgetResponseDTO,
      sortSpec,
      null,
      variablesContext
    );

    nState[sortColumnIdx] = {
      isFetching: false,
      ...sortDataResponse
    };

    const selectorSpec = getSelectorSpecFromSortResponse(sortDataResponse);

    const promises = metricColumns.map(async (column, idx) => {
      if (idx !== sortColumnIdx) {
        const widgetResponseDTO = widgetResponseDTOMap[column.id];
        const partResult = await getMetricColumnData(
          column,
          timeRange,
          usfSlices,
          sortColumnIdx,
          entityTypeMap,
          widgetResponseDTO,
          null,
          selectorSpec,
          variablesContext
        );

        nState[idx] = {
          isFetching: false,
          ...partResult
        };
      }
    });

    await Promise.allSettled(promises);
    setDataFetchState(nState);
  }, [
    entityTypeMap,
    metricColumns,
    numMetrics,
    seriesLimit,
    sort,
    timeRange,
    usfSlices,
    variablesContext,
    widgetResponseDTOMap
  ]);

  return {
    fetchData,
    dataFetchState,
    isFetching: dataFetchState.some(s => s.isFetching)
  };
};

const getDefaultState = (numMetrics: number): MetricTableDataFetchState[] =>
  range(0, numMetrics).map(() => ({
    queryType: null,
    isFetching: true,
    isError: false,
    error: null,
    data: null,
    projection: null,
    forecastProjections: []
  }));

const getMetricColumnData = async (
  column: MetricTableColumnInfo,
  timeRange: TimeRange,
  usfSlices: UserServiceFieldSlice[],
  idx: number,
  entityTypeMap: Record<string, string>,
  widgetResponseDTO: WidgetResponseDTO,
  sortSpec?: SortSpec,
  selectorSpec?: SelectorSpec,
  variablesContext?: MetricTableVariableContext
) => {
  const { id, queryConfig, name: metricName } = column;

  const { metricQueryConfig, queryType, entityQueryConfig, timeRange: cTimeRange, timeOffset } = queryConfig;

  let qTimeRange = timeRange;

  if (cTimeRange) {
    qTimeRange = cTimeRange;
  } else if (timeOffset) {
    const millis = getMillisFromTimeObj(timeOffset);
    const from = timeRange.to.clone().subtract(millis);
    const to = timeRange.to.clone();

    qTimeRange = {
      from,
      to,
      raw: {
        from: from.valueOf().toString(),
        to: to.valueOf().toString()
      }
    };
  }

  let partResult: MetricTableQueryResult;

  if (queryType === "metric") {
    partResult = await getMetricData(
      metricName,
      metricQueryConfig,
      usfSlices,
      qTimeRange,
      id,
      idx,
      entityTypeMap,
      widgetResponseDTO,
      sortSpec,
      selectorSpec,
      variablesContext
    );
  } else {
    partResult = await getEntityData(entityQueryConfig, qTimeRange);
  }

  return partResult;
};

const getSelectorSpecFromSortResponse = (sortDataResponse: MetricTableQueryResult) => {
  const { data, error, isError, queryType, projection } = sortDataResponse;

  if (isError) {
    logger.error(
      "FetchMetricTableData",
      "Error constructing selector spec from sort response due to data error",
      error
    );
  }

  const selectorSpec: SelectorSpec = {
    filters: []
  };

  if (queryType === "metric" && !isError) {
    const entityWidgetDataArr = data as EntityWidgetData[];
    entityWidgetDataArr.forEach(d => {
      const dataFrames = getDataFramesFromEntityDataByProjection(d, projection);

      dataFrames.forEach(df => {
        const { labels = {} } = df;
        const tags = Object.keys(labels);

        const selectorTags: SelectorTag[] = [];

        tags.forEach(tag => {
          const canInclude = !shouldExcludeTag(tag);
          if (canInclude) {
            const tagValue = labels[tag];

            selectorTags.push({
              key: tag,
              value: [tagValue]
            });
          }
        });

        selectorSpec.filters.push({
          tags: selectorTags
        });
      });
    });
  }

  return selectorSpec;
};
