import React, { CSSProperties, FC, useMemo } from "react";
import { IncGenericIcon, generateId, IncFaIcon } from "@inception/ui";
import { OpCreationFragmentV2 } from "../../services/api/operationalise";
import { getSourceTypeIcon } from "../../components/business-entity";
import { OpCreationFragmentEditProps } from "../types";
import { LowLevelFragment, OpFragmentType } from "../../services/api/chat";
import { noOp } from "../../utils";
import { VerticallyCenteredRow } from "../../components";
import { OpFragmentRenderer } from "./OpFragmentRenderer";

interface Props {
  opCreationFragment: OpCreationFragmentV2;
  fragmentPath: string;
  children?: JSX.Element;

  // Required in edit mode
  editProps?: OpCreationFragmentEditProps;
  onClose?: () => void;
  hideSummary?: boolean;
  summaryJsx?: JSX.Element;
  summaryClassName?: string;

  allowCustomFragmentEdit?: boolean;
  onCustomFragmentEdit?: (pathToEdit: string) => void;
  showEditorInModal?: boolean;
}

export const OpCreationFragmentV2Renderer: FC<Props> = props => {
  const {
    children,
    editProps,
    opCreationFragment,
    fragmentPath,
    onClose,
    hideSummary = true,
    summaryJsx,

    summaryClassName = "",
    allowCustomFragmentEdit = false,
    onCustomFragmentEdit,
    showEditorInModal = false
  } = props;

  const {
    name,
    description,
    shortSummary,
    labelFragment,
    lowLevelFragments: defLowLevelFragments,
    type
  } = opCreationFragment || {};

  const lowLevelFragments = useMemo(() => {
    const lowLevelFragments: LowLevelFragment[] = [];
    defLowLevelFragments.forEach(llFragment => {
      if (llFragment) {
        if (llFragment.lowLevelFragmentList?.lowLevelFragment?.length) {
          llFragment.lowLevelFragmentList.lowLevelFragment = llFragment.lowLevelFragmentList.lowLevelFragment.filter(
            fragment =>
              fragment?.opFragment ? !fragmentTypesToIgnore.includes(fragment.opFragment.opFragmentType) : true
          );
        }
        lowLevelFragments.push(llFragment);
      }
    });

    const llF = lowLevelFragments.filter(
      fragment => fragment?.opFragment?.opFragmentType !== OpFragmentType.OP_CAUSE_GRAPH
    );
    return llF;
  }, [defLowLevelFragments]);

  const { iconColor, iconName, iconSvg } = labelFragment || {};

  const uniqId = useMemo(() => `opFragment-${type}-${generateId()}`, [type]);

  const iconJsx = useMemo(() => {
    if (iconName) {
      const style: CSSProperties = {
        fill: iconColor || "inherit",
        color: iconColor || "inherit",
        fontSize: 16
      };

      return (
        <IncGenericIcon
          className="marginBtAuto"
          iconName={iconName}
          size={16}
          style={style}
          variant="Outline"
        />
      );
    }

    if (iconSvg) {
      return getSourceTypeIcon("", iconSvg, "marginBtAuto", 16);
    }

    return <></>;
  }, [iconColor, iconName, iconSvg]);

  const fragmentsJsx = useMemo(
    () =>
      lowLevelFragments?.map((fragment, index) => (
        <OpFragmentRenderer
          allowCustomFragmentEdit={allowCustomFragmentEdit}
          editProps={editProps}
          fragmentPath={`${fragmentPath}.lowLevelFragments[${index}]`}
          id={`${uniqId}-llf-${fragment.type}-${index}`}
          key={`${uniqId}-llf-${fragment.type}-${index}`}
          level={0}
          lowLevelFragment={fragment}
          onCustomFragmentEdit={onCustomFragmentEdit}
          showEditorInModal={showEditorInModal}
        />
      )),
    [
      allowCustomFragmentEdit,
      editProps,
      fragmentPath,
      lowLevelFragments,
      onCustomFragmentEdit,
      showEditorInModal,
      uniqId
    ]
  );

  return (
    <div className="op-creation-fragment-v2 height-100">
      <div className="op-creation-fragment-v2--header inc-flex-row flex-gap-16">
        {iconJsx}
        <IncFaIcon
          className="inc-cursor-pointer status-info"
          iconName="chevron-left"
          onClick={onClose ? onClose : noOp}
        />
        <div className="inc-flex-column flex-gap-4">
          <div className="inc-text-header-medium">{name}</div>
          {Boolean(description) && <div className="inc-text-inactive inc-text-subtext-medium">{description}</div>}
        </div>
      </div>

      <div className={`inc-flex-column summary-and-fragments--${summaryClassName} paddingBt16 height-100`}>
        {!hideSummary && (
          <VerticallyCenteredRow className="padding16 inc-text-body-medium op-creation-fragment-v2--summary">
            {shortSummary}
            {summaryJsx}
          </VerticallyCenteredRow>
        )}
        <div className="op-creation-fragment-v2--fragments">{fragmentsJsx}</div>
      </div>
      {children}
    </div>
  );
};

const fragmentTypesToIgnore: OpFragmentType[] = [
  OpFragmentType.OP_KPI,
  OpFragmentType.OP_SLICES,
  OpFragmentType.OP_START_TRIGGER_V2,
  OpFragmentType.OP_THRESHOLD_V2,
  OpFragmentType.OP_EVALUATION_WINDOW,
  OpFragmentType.OP_TYPE_INFO,
  OpFragmentType.OP_COMPARATOR,
  OpFragmentType.OP_CAUSE_GRAPH
];
