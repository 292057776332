import { cloneDeep, isEqual, merge, orderBy, pick } from "lodash";
import { MutableRefObject } from "react";
import {
  getDefaultMetricsForWidgetConfig,
  getSaveWidgetConfigDto,
  getWidgetConfigForMetrics
} from "../../../../../../biz-entity";
import {
  compareUSFields,
  getMetricIdsForExpressionMetric,
  MetricDefinition,
  UserServiceField,
  UserServiceFieldMetricConfig,
  UserServiceFieldMetricConfigDefinition,
  UserServiceFieldSliceSet,
  UserServiceFilterExpression,
  WidgetConfigDTO,
  WidgetConfigUtils
} from "../../../../../../services/api/explore";
import {
  ALL_USERSERVICES_ENTITY_TYPE_ID,
  ALL_USERSERVICES_EVENT_TYPE_ID,
  EXPRESSION_TAG
} from "../../../../../../utils/ExploreUtils";
import { DataEditorState, DataEditorStateEntry } from "./types";

export const getDefaultMetricsStateFromWidgetConfig = (
  widgetConfig: WidgetConfigDTO,
  userServiceEntityId: string,
  metricsVisualOrder?: string[]
) => {
  const { metrics } = widgetConfig.dataDefinition;
  const widgetConfigs: WidgetConfigDTO[] = [];
  const kpiIds: string[] = [];

  const allMetricIds = Object.keys(metrics);
  const extractedMetricIds: string[] = [];

  let labels: Record<string, any> = {};

  if (widgetConfig.labels) {
    labels = widgetConfig.labels;
  }

  try {
    labels = JSON.parse(labels?.[EXPRESSION_TAG] || "{}");
  } catch {
    // Do nothing
  }

  Object.values(metrics).forEach(metric => {
    if (metric.sourceType === "expression") {
      const childMetricIds = labels?.[metric.id]?.childMetricIds
        ? labels?.[metric.id].childMetricIds
        : getMetricIdsForExpressionMetric(metric.expressionMetricConfig);
      const childMetrics = pick(metrics, childMetricIds);
      const subMetrics: typeof metrics = {
        ...childMetrics,
        [metric.id]: metric
      };

      extractedMetricIds.push(...childMetricIds, metric.id);
      const subWidgetConfig = cloneDeep(widgetConfig);
      subWidgetConfig.name = metric.name;
      subWidgetConfig.dataDefinition.metrics = subMetrics;

      widgetConfigs.push(subWidgetConfig);
      kpiIds.push(metric.kpiId || "");
    }
  });

  const restMetricIds = allMetricIds.filter(id => !extractedMetricIds.includes(id));
  restMetricIds.forEach(metricId => {
    const subWidgetConfig = cloneDeep(widgetConfig);
    const metric = metrics[metricId];
    subWidgetConfig.name = metric.name;
    subWidgetConfig.dataDefinition.metrics = {
      [metricId]: metric
    };

    widgetConfigs.push(subWidgetConfig);
    kpiIds.push(metric.kpiId || "");
  });

  let metricsState = widgetConfigs.map(
    (widgetConfig, idx): DataEditorStateEntry =>
      getDataEditorStateFromWidgetConfigDto(widgetConfig, userServiceEntityId, kpiIds[idx])
  );

  if (metricsVisualOrder) {
    metricsState = orderBy(
      metricsState,
      entry => {
        const visualOrderKey = getVisualOrderKey(entry);
        return metricsVisualOrder.indexOf(visualOrderKey);
      },
      "asc"
    );
  }

  return metricsState;
};

export function getDataEditorStateFromWidgetConfigDto(
  widgetConfig: WidgetConfigDTO,
  userServiceEntityId: string,
  kpiId: string
) {
  const { metricsWithEventTypeAndId, metricIdLookup, ...restSubState } = getDefaultMetricsForWidgetConfig(
    widgetConfig,
    userServiceEntityId
  );

  // Always keep metricIds constant
  metricsWithEventTypeAndId.forEach(metric => {
    metric.configId = metricIdLookup[metric.configId] || metric.configId;
  });

  return {
    uniqId: Object.values(metricIdLookup)
      .map((metricId, idx) => metricId + idx)
      .join("_"),
    ...restSubState,
    metricsWithEventTypeAndId,
    metricIdLookup: {},
    eventTypeId: userServiceEntityId,
    hasExpression: Boolean(restSubState.expression),
    kpiId
  };
}

export const getWidgetConfigStateFromMetricsState = (
  metricsState: DataEditorState,
  bizEntityTypeId: string,
  isEntityFirst: boolean,
  disableUSFieldCheckRef: MutableRefObject<boolean>
) => {
  let widgetConfigDTO: WidgetConfigDTO;
  let isValid = true;
  let validMessage = "";
  const vizMetricIds: string[] = [];

  const expressionLabels: Record<string, any> = {};
  let hasAllUserServicesEventTypeId = false;
  metricsState.forEach(metricState => {
    const {
      metricsWithEventTypeAndId,
      expression,
      metricName,
      eventTypeId,
      subType,
      isSpikePositive,
      lookBack,
      metricIdLookup,
      exprMetricId: defExprMetricId,
      uniqId,
      kpiId
    } = metricState;

    const {
      widgetConfigDto: metricsWidgetConfigDto,
      isValid: isValidL,
      message,
      vizMetricId
    } = getWidgetConfigForMetrics(
      metricsWithEventTypeAndId,
      expression,
      metricName,
      null,
      null,
      disableUSFieldCheckRef.current,
      true,
      null,
      defExprMetricId || uniqId
    );

    const childMetricIds: string[] = [];
    Object.values(metricsWidgetConfigDto?.dataDefinition?.metrics || {}).forEach(metricDef => {
      if (metricDef.sourceType === "userServiceField") {
        metricDef.userServiceFieldMetricConfig.lookBack = lookBack;
        childMetricIds.push(metricIdLookup[metricDef.id] || metricDef.id);
      }
      metricDef.kpiId = kpiId;
    });

    const vMetricId = metricIdLookup[vizMetricId] || vizMetricId;
    let exprMetricId = "";

    if (expression) {
      expressionLabels[vMetricId] = {
        [EXPRESSION_TAG]: expression,
        childMetricIds
      };

      exprMetricId = vMetricId;
    }

    disableUSFieldCheckRef.current = false;

    const prevWidgetConfigName = widgetConfigDTO?.name;
    widgetConfigDTO = merge(
      widgetConfigDTO,
      getSaveWidgetConfigDto(metricsWidgetConfigDto, metricIdLookup, exprMetricId)
    );
    prevWidgetConfigName && (widgetConfigDTO.name = prevWidgetConfigName);

    if (!isEntityFirst) {
      widgetConfigDTO.userServiceEntityId = eventTypeId;
    } else {
      widgetConfigDTO.bizEntityType = bizEntityTypeId;
    }
    hasAllUserServicesEventTypeId = hasAllUserServicesEventTypeId || eventTypeId === ALL_USERSERVICES_EVENT_TYPE_ID;

    const metricDef = widgetConfigDTO?.dataDefinition?.metrics?.[vMetricId];
    if (metricDef) {
      metricDef.isSpikePositive = isSpikePositive;
      metricDef.subType = subType;
    }

    isValid = isValid && isValidL;
    validMessage = validMessage || message;
    vizMetricIds.push(vMetricId);
  });

  if (widgetConfigDTO) {
    widgetConfigDTO.labels = {
      [EXPRESSION_TAG]: JSON.stringify(expressionLabels)
    };
  }

  if (widgetConfigDTO && hasAllUserServicesEventTypeId) {
    widgetConfigDTO.bizEntityType = ALL_USERSERVICES_ENTITY_TYPE_ID;
    widgetConfigDTO.userServiceEntityId = "";
  }

  return {
    widgetConfigDTO,
    isValid,
    validMessage,
    vizMetricIds
  };
};

export const checkIfWidgetsAreEqual = (widgetConfigA: WidgetConfigDTO, widgetConfigB: WidgetConfigDTO) => {
  const {
    bizEntityType: bizEntityTypeA,
    dataDefinition: dataDefinitionA,
    userServiceEntityId: userServiceIdA
  } = widgetConfigA;

  const {
    bizEntityType: bizEntityTypeB,
    dataDefinition: dataDefinitionB,
    userServiceEntityId: userServiceIdB
  } = widgetConfigB;

  const entityTypeIdsMatch =
    !bizEntityTypeA && !bizEntityTypeB ? true : (bizEntityTypeA || "") === (bizEntityTypeB || "");
  const userServiceEntityIdsMatch =
    !userServiceIdA && !userServiceIdB ? true : (userServiceIdA || "") === (userServiceIdB || "");

  let dataDefinitionsMatch = true;
  const metricsA = Object.values(dataDefinitionA?.metrics || {});
  const metricsB = Object.values(dataDefinitionB?.metrics || {});

  if (metricsA.length !== metricsB.length) {
    dataDefinitionsMatch = false;
  } else {
    dataDefinitionsMatch = metricsA.every(metricA => {
      const usfMetricA = metricA as UserServiceFieldMetricConfigDefinition;
      const metricB = metricsB.find(metricB => {
        const usfMetricB = metricB as UserServiceFieldMetricConfigDefinition;

        const cMetricA = pick(usfMetricA, metricPickProps);
        const cMetricB = pick(usfMetricB, metricPickProps);

        return Object.keys(cMetricA).every(key => {
          const pKey = key as keyof UserServiceFieldMetricConfigDefinition;
          const valueA = cMetricA[pKey];
          const valueB = cMetricB[pKey];

          if (pKey === "userServiceFieldMetricConfig") {
            const cValueA = pick(valueA, metricConfigPickProps) as Partial<UserServiceFieldMetricConfig>;
            const cValueB = pick(valueB, metricConfigPickProps) as Partial<UserServiceFieldMetricConfig>;

            return metricConfigPickProps.every(key => {
              const cKey = key as keyof UserServiceFieldMetricConfig;
              const sValueA = cValueA[cKey];
              const sValueB = cValueB[cKey];

              if (cKey === "userServiceField") {
                return compareUSFields(sValueA as UserServiceField, sValueB as UserServiceField);
              } else if (cKey === "sliceSets") {
                const sliceSetA = (sValueA as UserServiceFieldSliceSet[])[0];
                const sliceSetB = (sValueB as UserServiceFieldSliceSet[])[0];
                return WidgetConfigUtils.compareSliceSets(sliceSetA, sliceSetB);
              } else {
                return isEqual(sValueA, sValueB);
              }
            });
          }

          return isEqual(valueA, valueB);
        });
      });
      return Boolean(metricB);
    });
  }

  return entityTypeIdsMatch && userServiceEntityIdsMatch && dataDefinitionsMatch;
};

export const getFinalMetricUserServiceFilters = (
  eventFilters: UserServiceFilterExpression[],
  metrics: Record<string, MetricDefinition>,
  metricIds: string[]
): WidgetConfigDTO["metricUserServiceFilters"] => {
  const metricUserServiceFilters: WidgetConfigDTO["metricUserServiceFilters"] = {};

  metricIds.forEach(metricId => {
    const metricDef = metrics[metricId];

    if (metricDef) {
      const metricsToCheck: string[] = [];

      if (metricDef.sourceType === "expression") {
        const childMetricIds = getMetricIdsForExpressionMetric(metricDef.expressionMetricConfig);
        metricsToCheck.push(...childMetricIds);
      } else {
        metricsToCheck.push(metricId);
      }

      const metricEventTypes = metricsToCheck.reduce((acc, metricId) => {
        const metricDef = metrics[metricId];
        if (metricDef.sourceType === "userServiceField") {
          const { userServiceField } = metricDef.userServiceFieldMetricConfig;
          const eventTypes = (userServiceField?.userServices || []).map(us => us.userServiceEntityId);
          return [...acc, ...eventTypes];
        }

        return acc;
      }, [] as string[]);

      const filteredExpressions = eventFilters.filter(filter => {
        if (filter?.field) {
          const { userServices } = filter.field;
          const fEventTypes = (userServices || []).map(us => us.userServiceEntityId);
          return metricEventTypes.some(metricEventType => fEventTypes.includes(metricEventType));
        }

        return false;
      });

      metricUserServiceFilters[metricId] = {
        userServiceFilters: [
          {
            userServiceFilterExpressions: filteredExpressions
          }
        ]
      };
    }
  });

  return metricUserServiceFilters;
};

export const getVisualOrderKey = (entry: DataEditorStateEntry) => {
  if (entry.hasExpression && entry.metricsWithEventTypeAndId?.length > 1) {
    return entry.exprMetricId || entry.uniqId;
  }

  return entry.metricsWithEventTypeAndId[0]?.configId || entry.uniqId;
};

const metricPickProps: Array<keyof UserServiceFieldMetricConfigDefinition> = [
  "isSpikePositive",
  "subType",
  "userServiceFieldMetricConfig",
  "name"
];

const metricConfigPickProps: Array<keyof UserServiceFieldMetricConfig> = [
  "aggregator",
  "filterExpressions",
  "eventFilters",
  "lookBack",
  "sliceSets",
  "userServiceField"
];
