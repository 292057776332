import React, { FC, useCallback } from "react";
import { BizDataQuery, DemoDataParams, UserServiceFieldSliceSet } from "../../../../services/api/explore";
import { BuildingBlockSliceSelector } from "./BuildingBlockSliceSelector";
import { WidgetIdSliceSelector } from "./WidgetIdSliceSelector";

interface Props {
  bizDataQuery: BizDataQuery;
  onChange: (sliceSet: UserServiceFieldSliceSet[], bizDataQuery: BizDataQuery) => void;
  isLoading: boolean;
  demoDataParams?: DemoDataParams;
}

export const BizDataQuerySliceSetEditor: FC<Props> = props => {
  const { bizDataQuery, onChange } = props;
  const onMetricSlicesChange = useCallback(
    (bizDataQuery: BizDataQuery) => {
      onChange(null, bizDataQuery);
    },
    [onChange]
  );

  return (
    <>
      {Boolean(bizDataQuery?.id || bizDataQuery?.widgetConfig) && (
        <WidgetIdSliceSelector
          {...props}
          onChange={onMetricSlicesChange}
        />
      )}
      {Boolean(bizDataQuery?.buildingBlockConfig) && <BuildingBlockSliceSelector {...props} />}
    </>
  );
};
