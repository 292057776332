import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForceUpdate } from "../../../core/hooks";
import BaseWidgetModel from "../../models/BaseWidgetModel";
import WidgetHeader from "../widget-components/WidgetHeader";
import { BaseWidgetProps, WidgetCustomAction, WidgetExtProps } from "../types";
import BaseWidget from "./BaseWidget";

const BaseWidgetWithHeader: React.FC<BaseWidgetProps> = props => {
  const noOp = () => {};

  const { widget, onUpdate, onVizChange = noOp, timeRange, customActions: pCustomActions } = props;

  const defTimeRange = widget.timeRange || timeRange;
  const [widgetTimeRange, setWidgetTimeRange] = useState(defTimeRange);

  const maximizeWidgetPropsRef = useRef<WidgetExtProps>();
  const onWidgetPropsChange = useCallback((widgetProps: WidgetExtProps) => {
    maximizeWidgetPropsRef.current = widgetProps;
  }, []);

  const headerRef = useRef<HTMLDivElement>();

  const updateDashboard = onUpdate || noOp;

  const [customActions, setCustomActions] = useState<WidgetCustomAction[]>([]);
  const [hasSubTitle, setHasSubTitle] = useState(false);

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    setWidgetTimeRange(defTimeRange);
  }, [defTimeRange]);

  const onWidgetHeaderChange = useCallback(
    (shouldUpdateDashboard: boolean, newModel: BaseWidgetModel): void => {
      if (shouldUpdateDashboard) {
        updateDashboard();
      }

      // Handle edit widget change
      if (newModel) {
        if (newModel.type !== widget.type) {
          onVizChange(newModel.type, newModel);
        } else {
          widget.update(newModel);
        }
      }
      forceUpdate();
    },
    [forceUpdate, onVizChange, updateDashboard, widget]
  );

  const onMaximize = useCallback(() => {
    if (props.onMaximize) {
      props.onMaximize(maximizeWidgetPropsRef.current, {});
    }
  }, [props]);

  const onMaximizeWithAddlContext = useCallback(
    (addlContext: Record<string, any>) => {
      if (props.onMaximize) {
        props.onMaximize(maximizeWidgetPropsRef.current, addlContext);
      }
    },
    [props]
  );

  const fCustomActions = useMemo<WidgetCustomAction[]>(
    () => [...customActions, ...(pCustomActions || [])],
    [customActions, pCustomActions]
  );

  const widgetHeader = (
    <WidgetHeader
      {...props}
      customActionItems={fCustomActions}
      headerRef={headerRef}
      onChange={onWidgetHeaderChange}
      onMaximize={onMaximize}
      setHasSubTitle={setHasSubTitle}
      setTimeRange={setWidgetTimeRange}
      timeRange={widgetTimeRange}
    />
  );

  return (
    <BaseWidget
      {...props}
      hasSubTitle={hasSubTitle}
      header={widgetHeader}
      headerRef={headerRef}
      onMaximizeWithAddlContext={onMaximizeWithAddlContext}
      onWidgetPropsChange={onWidgetPropsChange}
      setCustomActions={setCustomActions}
      timeRange={widgetTimeRange}
    />
  );
};

export default BaseWidgetWithHeader;
