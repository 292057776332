import React, { FC, useMemo, useCallback } from "react";
import { IncSelectOption, IncSelect, IncFaIcon } from "@inception/ui";

export type ValidationResponse = {
  valid: boolean;
  message: string;
};

export interface ExpressionEditorProps {
  expression: string;
  onChange: (nExpression: string) => void;
  validator?: (expression: string) => ValidationResponse;
  options?: IncSelectOption[];
}

export const ExpressionEditor: FC<ExpressionEditorProps> = props => {
  const { expression, onChange, validator = defaultValidator, options = [] } = props;

  const onExpressionChange = useCallback(
    (nExpressionOpt: IncSelectOption) => {
      if (nExpressionOpt) {
        const { value } = nExpressionOpt;
        onChange(value);
      }
    },
    [onChange]
  );

  const { valid, message } = useMemo(() => validator(expression), [expression, validator]);

  const exprOpt = useMemo<IncSelectOption>(
    () =>
      expression
        ? {
            label: expression,
            value: expression
          }
        : null,
    [expression]
  );

  return (
    <div className="expression-editor">
      <IncSelect
        allowCreate
        onChange={onExpressionChange}
        options={options}
        placeholder={placeholder}
        value={exprOpt}
      />
      {!valid && <div className="marginTp6 status-danger">{message}</div>}
    </div>
  );
};

const defaultValidator = () => ({
  valid: true,
  message: ""
});

const placeholder = (
  <div className="expression-editor--placeholder">
    <IncFaIcon
      className="marginRt6"
      iconName="plus"
    />
    <div>Add Expression</div>
  </div>
);
