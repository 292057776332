import React, { useMemo } from "react";
import { cloneDeep } from "lodash";
import { WidgetCustomAction } from "../../../types";
import {
  BizEntityFilters,
  TagFilterSelection,
  BizEntityFiltersProps,
  checkIfFiltersExist
} from "../../../../../biz-entity";
import { MetricResultDataDTO, WidgetResponseDTO } from "../../../../../services/api/explore";
import { USFWProps } from "../../types";
import { FilterIcon14 } from "../../../../../core/iconwrapper";

type Props = {
  widget: USFWProps["widget"];
  resultMeta: MetricResultDataDTO[];
  isResultMetaFetching: boolean;
  widgetResponseFetchInProgress: boolean;
  widgetResponseDTO: WidgetResponseDTO;
  allowPartialMatch: boolean;
  selectedFilters: TagFilterSelection;
  onFiltersExtracted: BizEntityFiltersProps["onFiltersExtracted"];
  setSelectedFilters: (nSel: TagFilterSelection) => void;
};

export const useSelectFiltersAction = (props: Props) => {
  const {
    allowPartialMatch,
    isResultMetaFetching,
    onFiltersExtracted,
    resultMeta,
    selectedFilters,
    setSelectedFilters,
    widget,
    widgetResponseDTO,
    widgetResponseFetchInProgress
  } = props;

  const isDetailedMode = widget.mode === "detailed";

  const filtersExist = useMemo(() => checkIfFiltersExist(selectedFilters), [selectedFilters]);

  const numResultMetaSeries = resultMeta?.[0]?.schema?.length || 0;
  const viewFiltersAction = useMemo<WidgetCustomAction>(() => {
    if (isResultMetaFetching || widgetResponseFetchInProgress) {
      const tooltipText = "Loading filters...";
      const actionComponent = <FilterIcon14 className="fa-fade" />;

      return {
        actionComponent,
        showInHeader: true,
        tooltipText
      };
    }

    const showViewFilters =
      numResultMetaSeries > 1 && Boolean(widgetResponseDTO?.querySchema?.querySchema?.length) && isDetailedMode;

    if (showViewFilters) {
      const nWidgetResponseDTO = {
        ...widgetResponseDTO,
        querySchema: cloneDeep(widgetResponseDTO.querySchema)
      };

      const tooltipText = "View filters";
      const actionComponent = (
        <BizEntityFilters
          allowPartialMatch={allowPartialMatch}
          filterMessage=""
          filtersExist={filtersExist}
          isPostAggResultMeta
          onFiltersExtracted={onFiltersExtracted}
          onSeriesFilterChange={setSelectedFilters}
          preventDisableMetric={resultMeta.length < 2}
          resultMeta={resultMeta}
          selectedFilters={selectedFilters}
          widgetResponseDTO={nWidgetResponseDTO}
        />
      );

      return {
        actionComponent,
        showInHeader: true,
        tooltipText
      };
    }

    return null;
  }, [
    allowPartialMatch,
    filtersExist,
    isDetailedMode,
    isResultMetaFetching,
    numResultMetaSeries,
    onFiltersExtracted,
    resultMeta,
    selectedFilters,
    setSelectedFilters,
    widgetResponseDTO,
    widgetResponseFetchInProgress
  ]);

  return viewFiltersAction;
};
