import React, { FC, useCallback, useMemo, useState } from "react";
import { ContentState, EditorState, convertFromHTML, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { cx } from "emotion";
import { convertHTMLToMarkDown, convertMarkdownToHtml } from "../../utils";

interface Props {
  markdown: string;
  onSave: (markdown: string) => void;

  placeholder?: string;
  hideToolBar?: boolean;

  editorClassName?: string;
  editorWrapperClassName?: string;
  className?: string;
}

export const MarkdownEditor: FC<Props> = props => {
  const {
    markdown = "",
    editorClassName: pEditorClassName = "",
    editorWrapperClassName: wrapperForEditorClassName = "",
    hideToolBar = false,
    onSave,
    className: pWrapperClassName = "",
    placeholder
  } = props;

  const htmlContent: string = useMemo(() => convertMarkdownToHtml(markdown), [markdown]);

  const blocksFromHtml = useMemo(() => convertFromHTML(htmlContent), [htmlContent]);

  const contentState: ContentState = useMemo(() => {
    const { contentBlocks = [], entityMap } = blocksFromHtml;
    return ContentState.createFromBlockArray(contentBlocks, entityMap);
  }, [blocksFromHtml]);

  const initialEditorState = useMemo(() => EditorState.createWithContent(contentState), [contentState]);

  const [editorState, setEditorState] = useState(initialEditorState);
  const onEditorStateChange = useCallback(
    (editorState: EditorState) => {
      setEditorState(editorState);
      const contentState = editorState.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
      const html = draftToHtml(rawContentState);
      const markdown = convertHTMLToMarkDown(html);
      onSave(markdown || "");
    },
    [onSave]
  );

  const editorClassName = cx("inc-markdown-content", pEditorClassName);
  const wrapperClassName = cx("inc-flex-column flex-gap-8", pWrapperClassName);

  return (
    <div className={wrapperClassName}>
      <Editor
        editorClassName={editorClassName}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        placeholder={placeholder}
        toolbarHidden={hideToolBar}
        wrapperClassName={wrapperForEditorClassName}
      />
    </div>
  );
};
