import { map } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IncNumericField, generateId, getInceptionTheme } from "@inception/ui";
import { ChartColorPicker } from "../../../../../../../biz-flow/ChartColorPicker";
import { DiscoveryDeleteOutlined, DiscoveryPlusCircle } from "../../../../../../../core/iconwrapper";
import { SingleStatThreshold } from "../../../../models";

export interface ColorRangeCustomization extends SingleStatThreshold {
  id: string;
}
export interface thresholdCustomizationProps {
  threshold: ColorRangeCustomization;
  showDelete: boolean;
  onDataChange: (threshhold: ColorRangeCustomization) => void;
  handleAddThreshold: () => void;
  handleDeleteThreshold: (id: string) => void;
}

export interface ColorRangeCustomizationProp {
  thresholds: ColorRangeCustomization[];
  onThresholdChange: (thresholds: ColorRangeCustomization[]) => void;
}

export const UIColorRangeCondition: FC<ColorRangeCustomizationProp> = props => {
  const { thresholds: pThresholds, onThresholdChange } = props;

  const [thresholds, setThresholds] = useState<ColorRangeCustomization[]>(pThresholds || [getGeneratedThreshold()]);

  const updateThresholdValue = useCallback(
    (thresholds: ColorRangeCustomization[]) => {
      onThresholdChange(thresholds);
    },
    [onThresholdChange]
  );

  const handleDeleteThreshold = useCallback(
    (id: string) => {
      const filteredThresholds = thresholds.filter(data => data.id !== id);
      setThresholds(filteredThresholds);
      updateThresholdValue(filteredThresholds);
    },
    [thresholds, updateThresholdValue]
  );

  const handleAddThreshold = useCallback(() => {
    let updatedThresholds: ColorRangeCustomization[] = [];
    setThresholds(thresholds => {
      const lastRecord = thresholds[thresholds.length - 1];
      const newRecord = getGeneratedThreshold();
      const { toVal, fromVal } = lastRecord;
      const { chartColors } = getInceptionTheme();
      const randomIndex: number = Math.floor(Math.random() * chartColors.length);
      newRecord.color = chartColors[randomIndex];
      newRecord.fromVal = toVal + 1;
      newRecord.toVal = newRecord.fromVal + (toVal - fromVal);
      updatedThresholds = [...thresholds, newRecord];
      return updatedThresholds;
    });
    updateThresholdValue(updatedThresholds);
  }, [updateThresholdValue]);

  const widgets = useMemo(
    () =>
      map(thresholds, (threshold, idx) => (
        <UIColorRangeConditionRow
          handleAddThreshold={handleAddThreshold}
          handleDeleteThreshold={handleDeleteThreshold}
          onDataChange={() => updateThresholdValue(thresholds)}
          showDelete={idx > 0}
          threshold={threshold}
        />
      )),
    [handleAddThreshold, handleDeleteThreshold, thresholds, updateThresholdValue]
  );

  return (
    <div className="color-selector-list">
      <div className="section-container d-flex flex-column">
        <div className="mb-2">Stat Color Ranges</div>
        <div className="d-flex flex-column width-100">{widgets}</div>
      </div>
    </div>
  );
};

const UIColorRangeConditionRow: FC<thresholdCustomizationProps> = (props: thresholdCustomizationProps) => {
  const { threshold, onDataChange, handleAddThreshold, handleDeleteThreshold, showDelete } = props;

  const { color: pColor, toVal, fromVal, id } = threshold;

  const [color, setColor] = useState<string>();

  useEffect(() => {
    setColor(pColor);
  }, [pColor]);

  const onColorChange = useCallback(
    (color: string) => {
      setColor(color);
      threshold.color = color;
      onDataChange(threshold);
    },
    [onDataChange, threshold]
  );

  const handleMinValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { valueAsNumber } = e.target;
      const nextValue = isNaN(valueAsNumber) ? 1 : valueAsNumber;
      threshold.fromVal = nextValue;
      onDataChange(threshold);
    },
    [onDataChange, threshold]
  );

  const handleMaxValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { valueAsNumber } = e.target;
      const nextValue = isNaN(valueAsNumber) ? 1 : valueAsNumber;
      threshold.toVal = nextValue;
      onDataChange(threshold);
    },
    [onDataChange, threshold]
  );

  return (
    <div
      className="d-flex flex-row width-100 align-items-center color-range-customization flex-gap-16"
      key={id}
    >
      <div className="color-picker-container">
        <ChartColorPicker
          color={color}
          hideEditOnHover
          onChange={onColorChange}
          size={24}
        />
      </div>
      <div>
        <IncNumericField
          containerClassName="d-flex flex-row"
          defaultValue={fromVal}
          disableAbbreviations={true}
          errorText="From value is greater than To value"
          label="From"
          onChange={handleMinValueChange}
        ></IncNumericField>
      </div>
      <div>
        <IncNumericField
          containerClassName="d-flex flex-row"
          defaultValue={toVal}
          disableAbbreviations={true}
          errorText="To value is smaller than From value"
          label="To"
          onChange={handleMaxValueChange}
        ></IncNumericField>
      </div>
      <div>
        <DiscoveryPlusCircle
          className="action-add-circle mr-2"
          height={16}
          onClick={handleAddThreshold}
          style={{ fill: "#39ACFF" }}
          width={16}
        />

        {showDelete && (
          <DiscoveryDeleteOutlined
            className="action-delete-circle"
            height={16}
            onClick={() => handleDeleteThreshold(id)}
            width={16}
          />
        )}
      </div>
    </div>
  );
};

export const getGeneratedThreshold: () => ColorRangeCustomization = () => ({
  fromVal: 1,
  toVal: 100,
  color: "#fff",
  background: "",
  id: generateId()
});
