import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IncButton, IncFaIcon, IncModal, IncModalProps, IncSmartText } from "@inception/ui";
import { cloneDeep } from "lodash";
import { OpFragmentEditProps } from "../types";
import { LoadingSpinner, VerticallyCenteredRow } from "../../components";
import { logger, useToggleState } from "../../core";
import { OpCreationFragmentEditType, operationaliseV2ApiService } from "../../services/api/operationalise";
import { WidgetConfigUtils, fieldPickerApiService } from "../../services/api/explore";
import { SuppressionsEditor } from "../../operationalise-v2/v3/editors/SuppressionsEditor/SuppresionsEditor";
import { getTimeRange } from "../../core/hooks/time-range/TimeRangeGetter";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { SuppressionEditorPickerContext } from "../../operationalise-v2/v3/editors";
import { getRollingFreqFromSchedule } from "../../operationalise-v2/context/reducerUtils";
import { getMillisFromTimeObj } from "../../utils";

type Props = OpFragmentEditProps;

export const SuppressionsFragmentEditor: FC<Props> = props => {
  const { opFragment, invalidFragmentJsx } = props;

  if (opFragment?.suppressionsWithContext?.suppressions) {
    return <SuppressionsFragmentEditorInternal {...props} />;
  }

  return invalidFragmentJsx;
};

const SuppressionsFragmentEditorInternal: FC<Props> = props => {
  const {
    className,
    opFragment,
    onChange,
    onClose,
    demoDataParams,
    opCreationConfig,
    showEditorInModal = false
  } = props;

  const { generateDemoData = false } = demoDataParams || {};

  const { isOpen: isUpdateInProgress, open: startUpdate, close: finishUpdate } = useToggleState();
  const { opCreationConfigDef, opAnalysisConfig, outlierConfig } = opCreationConfig || {};
  const { schedule: opConfigDefSchedule } = opCreationConfigDef || {};
  const { schedule: outlierSchedule } = outlierConfig || {};
  const { schedule: opAnalysisSchedule } = opAnalysisConfig || {};
  const opSchedule = useMemo(
    () => opConfigDefSchedule || outlierSchedule || opAnalysisSchedule,
    [opAnalysisSchedule, opConfigDefSchedule, outlierSchedule]
  );

  const getPredefinedMetrics = useCallback(async () => {
    try {
      const { data, error } = await operationaliseV2ApiService.getDefaultOpTriageConfig(opCreationConfig);
      if (error) {
        logger.error("SuppressionEditorWrapper", "Error fetching impacted widgets");
        return [];
      }
      const { impactedWidgets } = data || {};
      return impactedWidgets?.impactedWidgets || [];
    } catch (error) {
      logger.error("SuppressionEditorWrapper", "Error fetching impacted widgets", error);
      return [];
    }
  }, [opCreationConfig]);

  const compareTimeSeconds = useMemo(() => {
    if (!opSchedule) {
      return 0;
    }
    const freq = getRollingFreqFromSchedule(opSchedule);
    const millis = getMillisFromTimeObj(freq);
    const secs = timeRangeUtils.getSecondsFromMillis(millis);
    return secs;
  }, [opSchedule]);

  const errorMessageRef = useRef("");

  const { suppressions: defSuppressions, baseKpi } = opFragment.suppressionsWithContext || {};
  const [suppressions, setSuppressions] = useState(cloneDeep(defSuppressions || []));

  const { eventTypeId, entityTypeId } = useMemo(
    () => WidgetConfigUtils.getEntityTypeAndEventTypeFromBizDataQuery(baseKpi),
    [baseKpi]
  );

  const [context, setContext] = useState<SuppressionEditorPickerContext>({
    entityTypeId,
    eventTypeId,
    eventTypeName: eventTypeId || "",
    bizEntityFieldName: null,
    implicitSlice: null
  });

  const [isContextLoading, setIsContextLoading] = useState(true);
  const fetchContext = useCallback(async () => {
    setIsContextLoading(true);

    const timeRange = getTimeRange();
    const { fromMillis, toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);

    const eventTypeInfoPromise = eventTypeId
      ? fieldPickerApiService.getUserserviceInfo(eventTypeId, fromMillis, toMillis, generateDemoData)
      : Promise.resolve({
          data: {
            name: eventTypeId
          },
          error: false,
          message: ""
        });

    const entityTypeInfoPromise = entityTypeId
      ? fieldPickerApiService.getBizEntityType(entityTypeId, generateDemoData)
      : Promise.resolve({
          data: {
            name: entityTypeId
          },
          error: false,
          message: ""
        });

    const [eventTypeInfoResult, entityTypeInfoResult] = await Promise.allSettled([
      eventTypeInfoPromise,
      entityTypeInfoPromise
    ]);

    let eventTypeName = eventTypeId;
    let entityTypeName = entityTypeId;

    if (eventTypeInfoResult.status === "fulfilled") {
      const { data, error, message } = eventTypeInfoResult.value;

      if (error) {
        logger.error("SuppressionsFragmentEditor", "Error fetching event type info", message);
      } else {
        eventTypeName = data?.name || eventTypeId;
      }
    } else {
      logger.error("SuppressionsFragmentEditor", "Error fetching event type info", eventTypeInfoResult.reason);
    }

    if (entityTypeInfoResult.status === "fulfilled") {
      const { data, error, message } = entityTypeInfoResult.value;

      if (error) {
        logger.error("ThresholdEditorWrapper", "Error fetching entity type info", message);
      } else {
        entityTypeName = data?.name || entityTypeId;
      }
    } else {
      logger.error("ThresholdEditorWrapper", "Error fetching entity type info", entityTypeInfoResult.reason);
    }

    setContext(prev => ({
      ...prev,
      eventTypeName,
      entityTypeName
    }));

    setIsContextLoading(false);
  }, [entityTypeId, eventTypeId, generateDemoData]);

  useEffect(() => {
    fetchContext();
  }, [fetchContext]);

  const resetSuppressions = useCallback(() => {
    setSuppressions(cloneDeep(defSuppressions || []));
  }, [defSuppressions]);

  useEffect(() => {
    resetSuppressions();
  }, [resetSuppressions]);

  const onCancelChanges = useCallback(() => {
    resetSuppressions();
    onClose?.();
  }, [onClose, resetSuppressions]);

  const onSaveChanges = useCallback(async () => {
    errorMessageRef.current = "";
    startUpdate();

    const { isError, message } = await onChange(
      {
        ...opFragment,
        suppressionsWithContext: {
          ...opFragment.suppressionsWithContext,
          suppressions
        }
      },
      OpCreationFragmentEditType.SUPPRESSIONS
    );

    if (isError) {
      errorMessageRef.current = message;
    }

    finishUpdate();
  }, [finishUpdate, suppressions, onChange, opFragment, startUpdate]);

  const jsx = useMemo(
    () => (
      <div className={className}>
        {isContextLoading && <LoadingSpinner titleText="Fetching information..." />}
        {!isContextLoading && (
          <SuppressionsEditor
            bizDataQuery={baseKpi}
            compareTimeSeconds={compareTimeSeconds}
            getPredefinedMetrics={getPredefinedMetrics}
            onChange={setSuppressions}
            pickerContext={context}
            suppressions={suppressions}
          />
        )}
        {!showEditorInModal && (
          <VerticallyCenteredRow className="flex-gap-12 marginTp10">
            {!isUpdateInProgress && (
              <IncButton
                color="primary"
                label="Save Changes"
                onClick={onSaveChanges}
                size="small"
              />
            )}

            {isUpdateInProgress && (
              <IncButton
                color="primary"
                loading
                loadingText="Saving..."
                size="small"
              />
            )}

            {Boolean(errorMessageRef.current) && (
              <VerticallyCenteredRow className="status-danger flex-gap-10">
                <IncFaIcon
                  className="status-danger"
                  iconName="exclamation-triangle"
                />
                <IncSmartText
                  className="status-danger"
                  text={errorMessageRef.current}
                />
              </VerticallyCenteredRow>
            )}

            {!isUpdateInProgress && (
              <IncButton
                color="secondary-blue"
                label="Cancel"
                onClick={onCancelChanges}
                size="small"
              />
            )}
          </VerticallyCenteredRow>
        )}
      </div>
    ),
    [
      baseKpi,
      className,
      compareTimeSeconds,
      context,
      getPredefinedMetrics,
      isContextLoading,
      isUpdateInProgress,
      onCancelChanges,
      onSaveChanges,
      showEditorInModal,
      suppressions
    ]
  );

  const { close: closeModal, isOpen: IsModalOpen } = useToggleState(true);

  const actions: IncModalProps["actions"] = useMemo(
    () => ({
      primary: {
        label: "Save Changes",
        onClick: () => {
          closeModal();
          onSaveChanges();
        },
        color: "primary",
        showLoader: isUpdateInProgress
      },
      secondary: {
        label: "Cancel",
        onClick: () => {
          closeModal();
          onCancelChanges();
        },
        color: "secondary"
      }
    }),
    [closeModal, isUpdateInProgress, onCancelChanges, onSaveChanges]
  );

  return (
    <>
      {!showEditorInModal && jsx}
      {showEditorInModal && (
        <IncModal
          actions={actions}
          contentClassName="op-fragment-editors-modal-content padding16"
          onClose={closeModal}
          show={IsModalOpen}
          size="xxlg"
          titleText="Exceptions"
          withActionsBorder
          withTitleBorder
        >
          {jsx}
        </IncModal>
      )}
    </>
  );
};
