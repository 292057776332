import React, { FC, useRef, useMemo, useCallback, useEffect } from "react";
import { IncSelectOption, IncSelect } from "@inception/ui";
import { isEqual, clone, uniqBy } from "lodash";
import moment from "moment";
import { TimeObj } from "../../services/api/explore";
import { ENTITY_TAG } from "../../utils";
import { MultipleSelects, VerticallyCenteredRow } from "../../components";
import { shouldExcludeTag, NAME_TAG } from "../../core";
import { getLabelForTimeObj } from "../../utils/DurationUtils";
import { MonitoredDataSchema } from "../../services/api/operationalise";

interface Props {
  entityTypeName: string;

  opDataSchema: MonitoredDataSchema;
  schemaLoading: boolean;

  selectedSchema: Map<string, string[]>;
  onSelectionChange: (nSelection: Map<string, string[]>) => void;

  selectedFrequencies: TimeObj[];
  onFrequenciesChange: (nSelection: TimeObj[]) => void;

  hideFrequencies?: boolean;
}

export const CompareSchemaRenderer: FC<Props> = props => {
  const {
    opDataSchema,
    schemaLoading,
    onSelectionChange,
    selectedSchema,
    onFrequenciesChange: pOnFrequenciesChange,
    selectedFrequencies,
    entityTypeName,
    hideFrequencies
  } = props;

  const containerRef = useRef<HTMLDivElement>();

  const { filterOptions, filterLabels, filterOptionsLabelLookup, frequencyOptions } = useMemo(() => {
    const { entityLookupData = {} } = opDataSchema;
    let filterOptions: Map<string, string[]> = new Map();
    let frequencyOptions: Array<IncSelectOption<TimeObj>> = [];
    const filterLabels: Record<string, string> = {};
    const filterOptionsLabelLookup: Record<string, Record<string, string>> = {};

    if (!schemaLoading) {
      const { filterOptions: filterOptionsFromSchema, frequencyOptions: frequencyOptionsFromSchema } =
        getFilterOptionsFromSchema(opDataSchema);

      filterOptions = filterOptionsFromSchema;
      frequencyOptions = frequencyOptionsFromSchema;

      const keys = Array.from(filterOptions.keys());
      keys.forEach(key => {
        filterLabels[key] = key;
        if (key === ENTITY_TAG) {
          filterLabels[key] = entityTypeName;
        }
        filterOptionsLabelLookup[key] = entityLookupData;
      });
    }

    return {
      filterOptions,
      filterLabels,
      filterOptionsLabelLookup,
      frequencyOptions
    };
  }, [opDataSchema, schemaLoading, entityTypeName]);

  const initialiseFilters = useCallback(() => {
    onSelectionChange(filterOptions);
  }, [filterOptions, onSelectionChange]);

  useEffect(() => {
    if (!schemaLoading && selectedSchema.size === 0) {
      initialiseFilters();
    }
  }, [schemaLoading, initialiseFilters, selectedSchema]);

  const selectedFrequencyOpts = useMemo(
    () =>
      selectedFrequencies?.length ? selectedFrequencies.map(freq => getFrequencyOption(freq)) : [allFrequenciesOption],
    [selectedFrequencies]
  );

  const onFrequenciesChange = useCallback(
    (nSelection: IncSelectOption<TimeObj>) => {
      const nSelectedFrequencies = nSelection.data ? [nSelection].map(({ data }) => data) : [];
      const selectionChanged = !isEqual(selectedFrequencies, nSelectedFrequencies);
      if (selectionChanged) {
        pOnFrequenciesChange(nSelectedFrequencies);
      }
    },
    [pOnFrequenciesChange, selectedFrequencies]
  );

  const onChange = useCallback(
    (nSelectionMap: Map<string, string[]>, key: string) => {
      const prevSelection = (selectedSchema.get(key) || []).sort();
      const newSelection = (nSelectionMap.get(key) || []).sort();
      const selectionChanged = !isEqual(prevSelection, newSelection);
      if (selectionChanged) {
        const nMap = clone(selectedSchema);
        nMap.set(key, newSelection);
        onSelectionChange(nMap);
      }
    },
    [onSelectionChange, selectedSchema]
  );

  const shouldShowFrequencies = !hideFrequencies && Boolean(frequencyOptions?.length);
  return (
    <div
      className="compare-schema-renderer"
      ref={containerRef}
    >
      {Boolean(filterOptions.size) && (
        <div className="compare-schema-renderer--schema">
          <MultipleSelects
            displayLiteralAll
            groupOptions={filterOptions}
            labels={filterLabels}
            menuAsPortal
            onSeriesSelectionChanged={onChange}
            optionsLabelLookup={filterOptionsLabelLookup}
            parentElmWidth={containerRef.current?.clientWidth ?? 0}
            selectedOptions={selectedSchema}
          />
        </div>
      )}
      {shouldShowFrequencies && (
        <>
          {frequencyOptions?.length > 1 && (
            <IncSelect
              alignment="row"
              autoAdjustWidth
              autoSort={false}
              isSearchable={false}
              label="Frequency"
              onChange={onFrequenciesChange}
              options={frequencyOptions}
              value={selectedFrequencyOpts[0]}
              wrapperClass="marginLt12"
            />
          )}
          {frequencyOptions?.length === 1 && (
            <VerticallyCenteredRow className="compare-schema-renderer--frequency">
              <div className="inc-text-subtext-medium inc-text-inactive marginRt4 marginLt2">Frequency:</div>
              <div className="inc-text-subtext-medium">{getLabelForTimeObj(frequencyOptions[0].data)}</div>
            </VerticallyCenteredRow>
          )}
        </>
      )}
    </div>
  );
};

const getFilterOptionsFromSchema = (opDataSchema: MonitoredDataSchema) => {
  const filterOptions: Map<string, string[]> = new Map();
  let frequencyOptions: Array<IncSelectOption<TimeObj>> = [];
  const { series = [] } = opDataSchema;

  const filterOptionsSet: Record<string, Set<string>> = {};

  series.forEach(schemaEntry => {
    const { freq, timeSeries } = schemaEntry;

    timeSeries.label.forEach(tag => {
      const { name: tagKey, value: tagValue } = tag;
      const existingValues = filterOptionsSet[tagKey] || new Set<string>();
      existingValues.add(tagValue);
      filterOptionsSet[tagKey] = existingValues;
    });

    if (freq?.frequency) {
      const freqOption = getFrequencyOption(freq.frequency);
      frequencyOptions.push(freqOption);
    }
  });

  const tagKeys = Object.keys(filterOptionsSet);
  tagKeys.forEach(tagKey => {
    const canInclude = !shouldExcludeTag(tagKey) && tagKey !== NAME_TAG;
    if (canInclude) {
      const tagValues = filterOptionsSet[tagKey];
      const tagValuesArr = Array.from(tagValues).sort();
      filterOptions.set(tagKey, tagValuesArr);
    }
  });

  frequencyOptions = uniqBy(frequencyOptions, "label");
  frequencyOptions = frequencyOptions.sort((a, b) => {
    const durA = getDurationSecs(a.label);
    const durB = getDurationSecs(b.label);
    return durA - durB;
  });
  frequencyOptions = [allFrequenciesOption, ...frequencyOptions];

  return {
    filterOptions,
    frequencyOptions
  };
};

const getFrequencyOption = (freq: TimeObj): IncSelectOption<TimeObj> => {
  const label = getLabelForExploreDuration(freq);
  return {
    label,
    value: label,
    data: freq
  };
};

const getDurationSecs = (durationStr: string) => {
  const value = parseInt(durationStr, 10);
  const unit = durationStr.replace(value.toString(), "");
  return moment.duration(value, unit as any).asSeconds();
};

const getLabelForExploreDuration = (duration: TimeObj): string => getLabelForTimeObj(duration);

const allFrequenciesOption: IncSelectOption<TimeObj> = {
  label: "All",
  value: "all",
  data: null
};
