import { DiscoveredFieldDef } from "../../services/api/auto-discovery/types";
import { UserServiceFieldDef } from "../../services/api/event-mapping/types";
import { FieldTransformationConfig, TransformType } from "./TransformConfig";

export function getUniqueFieldName(field: UserServiceFieldDef) {
  return `${field.fieldName}${field?.predefinedFieldType || ""}`;
}

export function getTransformConfigFromDiscoveredField(fieldDef: DiscoveredFieldDef) {
  const dataTransformations: Array<FieldTransformationConfig<TransformType>> = fieldDef?.dataTransformations;
  return dataTransformations?.length ? dataTransformations[0] : null;
}
