import { useMemo, useRef } from "react";
import {
  WidgetResponseDTO,
  CohortEntityFilter,
  LimitSpecFunction,
  DemoDataParams,
  UserServiceFilterList
} from "../../../../../../services/api/explore";
import { TimeRange, Visualisations } from "../../../../../../core";
import { getDownSampleInterval } from "../../../../../../utils";
import { useFetchCatalogWidgetData } from "../../../hooks";
import { CatalogWidgetFetchDataPayload } from "../../../types";
import timeRangeUtils from "../../../../../../utils/TimeRangeUtils";
import { DashboardTimeRange } from "../../../../../models";

const MAX_DATA_POINTS = 50;

export const useFetchCommonData = (
  queryId: string,
  cohortFilters: CohortEntityFilter[],
  entityFilters: CohortEntityFilter[],
  eventFilters: Record<string, UserServiceFilterList>,
  entityType: string,
  dataFetchPayload: CatalogWidgetFetchDataPayload,
  timeRange: TimeRange,
  compareTimeRange: TimeRange,
  userServiceId: string,
  widgetResponseDTO: WidgetResponseDTO,
  seriesLimit: number,
  limitSpecFunction: LimitSpecFunction,
  variablesLoading: boolean,
  dsIntervalStr?: string,
  demoParams?: DemoDataParams,
  visualisation?: Visualisations,
  skipDataFetch = false
) => {
  const finDsIntervalStr = useMemo(() => {
    const { fromMillis, toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);

    const dbTimeRange: DashboardTimeRange = {
      from: fromMillis,
      to: toMillis
    };

    return getDownSampleInterval(dsIntervalStr, dbTimeRange, MAX_DATA_POINTS);
  }, [dsIntervalStr, timeRange]);

  const limit = useMemo(() => {
    if (dataFetchPayload?.sliceSpec?.[0]?.postAgg?.sortSpec) {
      return -1;
    }

    return seriesLimit;
  }, [dataFetchPayload?.sliceSpec, seriesLimit]);

  const { isFetching, data, error, isError, refetch, queryTimeRange } = useFetchCatalogWidgetData({
    id: queryId,
    cohortFilters,
    entityFilters,
    entityType,
    ...(dataFetchPayload || ({} as any)),
    timeRange,
    userServiceId,
    eventFilters,
    widgetResponseDTO,
    limit,
    dsIntervalStr: finDsIntervalStr,
    demoParams,
    visualisation
  });

  const querySchema = useMemo(() => widgetResponseDTO?.querySchema?.querySchema || [], [widgetResponseDTO]);
  const querySchemaExists = querySchema?.length > 0;

  const refetchTriggeredRef = useRef(false);

  /**
   * We are using useMemo here since the child metric fetch depends on this.
   * If this is async, then child metric data is also fetched unnecessarily.
   */
  useMemo(() => {
    if (!variablesLoading && querySchemaExists && dataFetchPayload && !skipDataFetch) {
      refetchTriggeredRef.current = true;
      refetch();

      setTimeout(() => {
        refetchTriggeredRef.current = false;
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    refetch,
    timeRange,
    compareTimeRange,
    entityFilters,
    cohortFilters,
    eventFilters,
    variablesLoading,
    dataFetchPayload,
    querySchemaExists
  ]);

  return {
    isFetching: refetchTriggeredRef.current || isFetching || !querySchemaExists,
    isError,
    error,
    data,
    queryTimeRange
  };
};
