import { IncButton, IncEditor, IncModal } from "@inception/ui";
import { clone, cloneDeep, isEqual, last, uniqBy } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { css } from "emotion";
import { VerticallyCenteredRow } from "../../../../../../components";
import { logger, RawTimeRange, useToggleState, useVerticalConfig, Visualisations } from "../../../../../../core";
import {
  DemoDataParams,
  UserServiceFieldMetricConfigDefinition,
  UserServiceFilterExpression
} from "../../../../../../services/api/explore";
import { featureFlagService } from "../../../../../../services/feature-flags";
import { noOp } from "../../../../../../utils";
import timeRangeUtils from "../../../../../../utils/TimeRangeUtils";
import { TableProperties, WidgetQuerySourceConfig } from "../../../models";
import { WidgetConfigurationTabProps } from "../types";
import { CatalogWidgetUtils } from "../../../CatalogWidgetUtils";
import { CatalogVizSwitcher } from "../VizSwitcher";
import { ALL_USERSERVICES_ENTITY_TYPE_ID, ALL_USERSERVICES_EVENT_TYPE_ID } from "../../../../../../utils/ExploreUtils";
import { QueryEditorWrapper, TimePropertiesEditor, WidgetFiltersEditor } from "./components";
import { DataEditorState, WidgetConfigState } from "./types";
import { useFetchEventTypesList } from "./useFetchEventTypeList";
import {
  checkIfWidgetsAreEqual,
  getDefaultMetricsStateFromWidgetConfig,
  getFinalMetricUserServiceFilters,
  getVisualOrderKey,
  getWidgetConfigStateFromMetricsState
} from "./utils";

interface Props extends WidgetConfigurationTabProps {
  hideVizSelection?: boolean;
  hideBreakdownSelection?: boolean;
  hideTimeRangeSelection?: boolean;
  hideWidgetFilterSelection?: boolean;
  noCollapseMetrics?: boolean;
  hideComponentMetricsGroupBy?: boolean;
  singleMetricMode?: boolean;
  hideMetricCustomisation?: boolean;
  skipKpiMetricOptions?: boolean;
  onLoadMetrics?: (loading: boolean) => void;
}

export const DataTab: FC<Props> = props => {
  const {
    widgetImpl,
    onWidgetImplChange,
    widgetResponseDTO: pWidgetResponseDTO,
    compareWidgetConfigDto,
    onUnsavedChangesExist = noOp,
    setValidState,
    readOnly = false,
    hideBreakdownSelection = false,
    hideTimeRangeSelection = false,
    hideVizSelection = false,
    hideWidgetFilterSelection = false,
    noCollapseMetrics = false,
    hideComponentMetricsGroupBy = false,
    singleMetricMode = false,
    hideMetricCustomisation = false,
    skipKpiMetricOptions = false,
    onLoadMetrics
  } = props;

  const isDebugMode = featureFlagService.isDebugMode();

  const isEditable = !readOnly;

  const [widgetResponseDTO, setWidgetResponseDTO] = useState({
    ...pWidgetResponseDTO,
    widgetId: ""
  });

  useEffect(() => {
    setWidgetResponseDTO({
      ...pWidgetResponseDTO,
      widgetId: ""
    });
  }, [pWidgetResponseDTO]);

  const { widgetConfig } = widgetResponseDTO || {};
  const bizEntityTypeId = widgetConfig?.bizEntityType;
  const isEntityFirst = Boolean(bizEntityTypeId);

  const defEventFilters = useMemo(() => {
    const fWidgetConfig = compareWidgetConfigDto || widgetConfig;
    let eventFilters: UserServiceFilterExpression[] = [];
    Object.values(fWidgetConfig?.metricUserServiceFilters || {}).forEach(defEventFiltersList => {
      const filters = defEventFiltersList?.userServiceFilters?.[0]?.userServiceFilterExpressions || [];
      eventFilters.push(...filters);
    });

    eventFilters = uniqBy(eventFilters, "field.fieldName");
    return eventFilters;
  }, [compareWidgetConfigDto, widgetConfig]);
  const [eventFilters, setEventFilters] = useState<UserServiceFilterExpression[]>(cloneDeep(defEventFilters));

  const {
    generateDemoData,
    entityType: bizEntityType,
    userServiceId,
    timeRange: widgetTimeRange,
    downsample,
    queryConfig,
    properties,
    compareInterval,
    metricsVisualOrder
  } = widgetImpl;

  const { verticalConfig } = useVerticalConfig();
  const { companyName, subVerticalId, verticalId, useCaseId } = verticalConfig || {};

  const demoDataParams = useMemo(
    () =>
      ({
        generateDemoData,
        companyName,
        subVertical: subVerticalId,
        usecase: useCaseId,
        vertical: verticalId
      }) as DemoDataParams,
    [companyName, generateDemoData, subVerticalId, useCaseId, verticalId]
  );

  const { visualisation, disableEventTypeEdit = false, table: tableProperties } = properties;

  const vizOptions = useMemo(() => CatalogWidgetUtils.getVisualisations(queryConfig), [queryConfig]);
  const onVisualisationChange = useCallback(
    (visualisation: Visualisations) => {
      onWidgetImplChange(widgetImpl => {
        const nWidgetImpl = clone(widgetImpl);
        nWidgetImpl.properties.visualisation = visualisation;
        return nWidgetImpl;
      });
    },
    [onWidgetImplChange]
  );

  const disableUSFieldCheckRef = useRef(false);

  const widgetConfigStateRef = useRef<WidgetConfigState>(null);
  const metricsVisualOrderRef = useRef(metricsVisualOrder);
  const eventFiltersRef = useRef<UserServiceFilterExpression[]>(eventFilters);

  const updateWidgetImplWithNewConfig = useCallback(() => {
    onWidgetImplChange(widgetImpl => {
      const eventFilters = eventFiltersRef.current || [];
      const nWidgetImpl = clone(widgetImpl);

      const { vizMetricIds, widgetConfigDTO } = widgetConfigStateRef.current;

      const metrics = widgetConfigDTO?.dataDefinition?.metrics || {};
      const dataDefinitions = Object.values(metrics).filter(def => def.sourceType === "userServiceField");

      const aggregatedTagsSet = new Set<string>();
      dataDefinitions.forEach(metricDef => {
        if (metricDef?.sourceType === "userServiceField") {
          const { sliceSets = [] } = metricDef?.userServiceFieldMetricConfig || {};
          const slices = last(sliceSets)?.slices || [];
          slices.forEach(slice => aggregatedTagsSet.add(slice.tagName));
        }
      });
      const aggregateTags = Array.from(aggregatedTagsSet);

      const filterMetricIds = Object.keys(metrics);

      nWidgetImpl.queryConfig = {
        ...nWidgetImpl.queryConfig,
        sourceQueryConfig: {
          queryType: "widgetConfig",
          metricId: vizMetricIds[0],
          widgetResponse: {
            widgetConfig: {
              ...widgetConfigDTO,
              metricUserServiceFilters: getFinalMetricUserServiceFilters(eventFilters, metrics, filterMetricIds)
            },
            querySchema: {
              querySchema: []
            },
            version: 1,
            widgetId: null
          },
          childMetricIds: vizMetricIds.slice(1)
        }
      };

      nWidgetImpl.properties = {
        ...nWidgetImpl.properties,
        aggregateTags
      };

      nWidgetImpl.metricsVisualOrder = metricsVisualOrderRef.current;

      return nWidgetImpl;
    });
  }, [onWidgetImplChange]);

  const onEventFiltersChange = useCallback(
    (eventFilters: UserServiceFilterExpression[]) => {
      const filtersValid = eventFilters.reduce((acc, filter) => {
        if (!acc) {
          return acc;
        }

        const { field, operator, value, values } = filter;

        if (!field || !operator) {
          return acc && false;
        }

        const isDoesNotExist = operator === "=" && value === null && Boolean(values) && values.length === 0;
        const isValueValid =
          operator === "in" || operator === "not in"
            ? Boolean(values) && values.length > 0
            : isDoesNotExist
              ? true
              : Boolean(value);

        return acc && isValueValid;
      }, true);

      if (filtersValid) {
        eventFiltersRef.current = eventFilters;
        updateWidgetImplWithNewConfig();
      }

      setEventFilters(eventFilters);
    },
    [updateWidgetImplWithNewConfig]
  );

  const {
    error,
    exploreUserservices: eventTypes,
    isError,
    isFetching
  } = useFetchEventTypesList(bizEntityTypeId, userServiceId, demoDataParams);

  useEffect(() => {
    if (onLoadMetrics) {
      onLoadMetrics(isFetching);
    }
  }, [isFetching, onLoadMetrics]);

  useEffect(() => {
    if (isError) {
      logger.error("DataTab", "Error fetching event types", error);
    }
  }, [error, isError]);

  const metricsState = useMemo(() => {
    let metricsState: DataEditorState = [];

    if (!isFetching) {
      const widgetConfigDTO = widgetResponseDTO.widgetConfig;
      if (widgetConfigDTO) {
        let userServiceEntityId: string;

        if (!widgetConfigDTO.userServiceEntityId) {
          const metricDef = Object.values(widgetConfigDTO.dataDefinition.metrics || {}).find(
            metric => metric.sourceType === "userServiceField"
          ) as UserServiceFieldMetricConfigDefinition;
          userServiceEntityId =
            widgetConfigDTO.bizEntityType === ALL_USERSERVICES_ENTITY_TYPE_ID
              ? ALL_USERSERVICES_EVENT_TYPE_ID
              : metricDef?.userServiceFieldMetricConfig?.userServiceField?.userServices?.[0]?.userServiceEntityId;
        } else {
          userServiceEntityId = widgetConfigDTO.userServiceEntityId;
        }

        if (userServiceEntityId) {
          if (!isEntityFirst) {
            widgetConfigDTO.bizEntityType = null;
            widgetConfigDTO.userServiceEntityId = userServiceEntityId;
          } else {
            widgetConfigDTO.userServiceEntityId = null;
          }
        }

        metricsState = getDefaultMetricsStateFromWidgetConfig(widgetConfigDTO, userServiceEntityId, metricsVisualOrder);
      }

      if (!widgetConfigStateRef.current) {
        widgetConfigStateRef.current = getWidgetConfigStateFromMetricsState(
          metricsState,
          bizEntityTypeId,
          isEntityFirst,
          disableUSFieldCheckRef
        );
      }
    }

    return metricsState;
  }, [bizEntityTypeId, isEntityFirst, isFetching, metricsVisualOrder, widgetResponseDTO.widgetConfig]);

  const onMetricsStateChange = useCallback(
    (updaterFunction: (metricsState: DataEditorState) => DataEditorState) => {
      const nMetricsState = updaterFunction(metricsState);
      const widgetConfigState = getWidgetConfigStateFromMetricsState(
        nMetricsState,
        bizEntityTypeId,
        isEntityFirst,
        disableUSFieldCheckRef
      );

      widgetConfigStateRef.current = widgetConfigState;
      metricsVisualOrderRef.current = nMetricsState.map(getVisualOrderKey);
      updateWidgetImplWithNewConfig();
    },
    [bizEntityTypeId, isEntityFirst, metricsState, updateWidgetImplWithNewConfig]
  );

  const updateTimeRange = useCallback(
    (raw: RawTimeRange) => {
      const nTr = raw ? timeRangeUtils.getTimeRangeFromRaw(raw) : null;
      onWidgetImplChange(prev => {
        const next = clone(prev);
        next.timeRange = nTr;
        return next;
      });
    },
    [onWidgetImplChange]
  );

  const updateDownsample = useCallback(
    (downsample: string) => {
      onWidgetImplChange(prev => {
        const next = clone(prev);
        next.downsample = downsample;
        return next;
      });
    },
    [onWidgetImplChange]
  );

  const updateCompareInterval = useCallback(
    (compareInterval: string) => {
      onWidgetImplChange(prev => {
        const next = clone(prev);
        next.compareInterval = compareInterval;
        next.properties.disableCompare = !compareInterval;
        return next;
      });
    },
    [onWidgetImplChange]
  );

  const updateTableProperties = useCallback(
    (tableProperties: TableProperties) => {
      onWidgetImplChange(prev => {
        const next = clone(prev);
        next.properties.table = tableProperties;
        return next;
      });
    },
    [onWidgetImplChange]
  );

  const { isValid, validMessage, widgetConfigDTO } = widgetConfigStateRef.current || {};

  const queryWidgetConfig = (queryConfig?.sourceQueryConfig as WidgetQuerySourceConfig)?.widgetResponse?.widgetConfig;
  useEffect(() => {
    if (widgetConfigDTO) {
      const defWidgetConfigDto = compareWidgetConfigDto || queryWidgetConfig;
      const configChangesExist = !checkIfWidgetsAreEqual(widgetConfigDTO, defWidgetConfigDto);
      const filterChangesExist = !isEqual(defEventFilters, eventFilters);

      const changesExist = configChangesExist || filterChangesExist;
      onUnsavedChangesExist(changesExist);
    }
  }, [
    compareWidgetConfigDto,
    defEventFilters,
    eventFilters,
    onUnsavedChangesExist,
    queryWidgetConfig,
    widgetConfigDTO
  ]);

  useEffect(() => {
    if (setValidState && !isFetching) {
      return setValidState(isValid, validMessage);
    }
  }, [isFetching, isValid, setValidState, validMessage]);

  const { close: closeViewConfigModal, isOpen: isViewConfigModalOpen, open: openViewConfigModal } = useToggleState();

  const eventTypeIds = useMemo(() => {
    const eventTypeIdsSet = new Set<string>();
    metricsState.forEach(ms => {
      const { metricsWithEventTypeAndId } = ms;
      metricsWithEventTypeAndId.forEach(metric => {
        const { eventTypeId } = metric;
        eventTypeIdsSet.add(eventTypeId);
      });
    });

    return Array.from(eventTypeIdsSet);
  }, [metricsState]);

  const stringifiedWidgetConfig = useMemo(() => JSON.stringify(widgetConfigDTO, null, 4), [widgetConfigDTO]);
  const addMetricLabel =
    visualisation === Visualisations.table
      ? "Add Column"
      : visualisation === Visualisations.timeseries
        ? "Add Series"
        : visualisation === Visualisations.barChart
          ? "Add Bar"
          : "Add Metric";
  const metricNameLabel =
    visualisation === Visualisations.table
      ? "Column Name"
      : visualisation === Visualisations.timeseries
        ? "Series Name"
        : visualisation === Visualisations.barChart
          ? "Bar Name"
          : "Metric Name";
  const metricDescriptionLabel =
    visualisation === Visualisations.table
      ? "Column Description"
      : visualisation === Visualisations.timeseries
        ? "Series Description"
        : visualisation === Visualisations.barChart
          ? "Bar Description"
          : "Metric Description";
  const metricLabel =
    visualisation === Visualisations.table
      ? "Columns"
      : visualisation === Visualisations.timeseries
        ? "Series"
        : visualisation === Visualisations.barChart
          ? "Bars"
          : "Metrics";

  return (
    <div className="data-tab-content flex-gap-16">
      {isFetching && (
        <div
          className="width-100 marginTp16"
          data-show-loader={true}
          style={{ height: "100%" }}
        />
      )}
      {!isFetching && Boolean(metricsState) && (
        <>
          {!disableEventTypeEdit && (
            <VerticallyCenteredRow className="marginLt2">
              {!hideVizSelection && (
                <CatalogVizSwitcher
                  label="Widget Type"
                  onVisualisationChange={onVisualisationChange}
                  visualisation={visualisation}
                  vizOptions={vizOptions}
                />
              )}

              {isDebugMode && (
                <IncButton
                  className="marginLtAuto"
                  color="link"
                  onClick={openViewConfigModal}
                  size="small"
                >
                  View configuration
                </IncButton>
              )}
            </VerticallyCenteredRow>
          )}

          <QueryEditorWrapper
            addMetricLabel={addMetricLabel}
            bizEntityType={bizEntityType}
            demoDataParams={demoDataParams}
            disableUSFieldCheckRef={disableUSFieldCheckRef}
            downsample={downsample}
            eventTypes={eventTypes}
            hideComponentMetricsGroupBy={hideComponentMetricsGroupBy}
            hideMetricCustomisation={hideMetricCustomisation}
            isEditable={isEditable}
            metricDescriptionLabel={metricDescriptionLabel}
            metricLabel={metricLabel}
            metricNameLabel={metricNameLabel}
            metricsState={metricsState}
            noCollapseMetrics={noCollapseMetrics}
            onDownsampleChange={updateDownsample}
            setMetricsState={onMetricsStateChange}
            showBreakdown={visualisation !== Visualisations.sparkLine && !hideBreakdownSelection}
            singleMetricMode={singleMetricMode}
            skipKpiMetricOptions={skipKpiMetricOptions}
            tableProperties={tableProperties}
            updateTableProperties={updateTableProperties}
          >
            {!hideTimeRangeSelection && (
              <TimePropertiesEditor
                compareInterval={compareInterval}
                onCompareIntervalChange={updateCompareInterval}
                onTimeRangeChange={updateTimeRange}
                timeRange={widgetTimeRange}
              />
            )}

            {!hideWidgetFilterSelection && (
              <WidgetFiltersEditor
                bizEntityType={bizEntityTypeId}
                eventFilters={eventFilters}
                eventTypeIds={eventTypeIds}
                onEventFiltersChange={onEventFiltersChange}
              />
            )}
          </QueryEditorWrapper>
        </>
      )}

      {isDebugMode && (
        <IncModal
          className={heightClassName}
          onClose={closeViewConfigModal}
          show={isViewConfigModalOpen}
          titleText="View Configuration"
        >
          <IncEditor
            className={heightClassName}
            mode="json"
            readOnly
            value={stringifiedWidgetConfig}
          />
        </IncModal>
      )}
    </div>
  );
};

const heightClassName = css`
  height: 80vh;
  margin-bottom: 32px;
`;
