import { IncButton, IncFaIcon, IncSelect, IncSelectOption } from "@inception/ui";
import React, { memo, FC, useCallback, useMemo } from "react";
import {
  FieldSelectorProps,
  OperatorSelectorProps,
  ValueEditorProps,
  CombinatorSelectorProps,
  ActionWithRulesAndAddersProps
} from "react-querybuilder";
import { FieldPickerContainer } from "../field-picker";
import { FieldPickerOptionData } from "../../services/api/explore/types/fieldpickerTypes";
import { FieldPickerUtils } from "../../utils";
import { LogicalOperator, UserServiceField } from "../../services/api/explore";
import { AutoCompleter } from "./Autocompleter";
import { ConditionsBuilderUtils, EXPR_TREE_DOES_NOT_EXIST_VALUE } from "./utils";
import { QueryBuilderRule } from "./types";

export const AddRuleOrGroupAction: FC<ActionWithRulesAndAddersProps> = memo(props => {
  const { handleOnClick, label } = props;
  const displayLabel = label.slice(1);

  return (
    <IncButton
      className="marginLt16"
      color="link"
      iconName="circle-plus"
      iconType="iconText"
      label={displayLabel}
      onClick={handleOnClick}
      size="small"
    />
  );
});

export const RemoveRuleOrGroupAction: FC<ActionWithRulesAndAddersProps> = memo(props => {
  const { handleOnClick } = props;

  return (
    <IncFaIcon
      className="inc-cursor-pointer status-danger marginLt10"
      iconName="trash-can"
      onClick={handleOnClick}
    />
  );
});

export const QueryValueEditor: FC<ValueEditorProps> = props => {
  const { context, rule, handleOnChange } = props;

  const { dependentFilters, readOnly = false, showLabel = false } = context;

  const { field: rField, operator } = rule as QueryBuilderRule;

  const value = Array.isArray(rule?.value) ? undefined : rule.value;
  const values = Array.isArray(rule?.value) ? rule.value : undefined;

  const field: FieldPickerOptionData = typeof rField === "string" ? null : rField;
  const tag = field ? FieldPickerUtils.getPromSanitizedUSFName(field.payload as UserServiceField) : null;

  const selectedOperatorOpt = useMemo(() => {
    const operatorOptions = ConditionsBuilderUtils.getFilterOperators(field);
    const operatorOpt = ConditionsBuilderUtils.getSelectedOperator(
      operator,
      value,
      operatorOptions,
      EXPR_TREE_DOES_NOT_EXIST_VALUE
    );
    return operatorOpt;
  }, [field, operator, value]);

  const isDoesNotExistOperator = selectedOperatorOpt?.value === ConditionsBuilderUtils.nullOperatorOpt.value;
  const isMulti = ["in", "not in"].includes(operator);

  return (
    <div className="value-container">
      {!isDoesNotExistOperator && (
        <AutoCompleter
          context={field}
          dependentFilters={dependentFilters}
          isMulti={isMulti}
          onChange={handleOnChange}
          readOnly={readOnly}
          selected={isMulti ? values : value}
          showLabel={showLabel}
          tag={tag}
        />
      )}
    </div>
  );
};

export const QueryOperatorEditor: FC<OperatorSelectorProps> = props => {
  const { rule, context, handleOnChange } = props;

  const { showLabel = false } = context;

  const { field: rField, operator, value } = rule as QueryBuilderRule;

  const field: FieldPickerOptionData = typeof rField === "string" ? null : rField;

  const { operatorOptions, selectedOperatorOpt } = useMemo(() => {
    const operatorOptions = ConditionsBuilderUtils.getFilterOperators(field);
    const operatorOpt = ConditionsBuilderUtils.getSelectedOperator(
      operator,
      value,
      operatorOptions,
      EXPR_TREE_DOES_NOT_EXIST_VALUE
    );

    return {
      operatorOptions,
      selectedOperatorOpt: operatorOpt
    };
  }, [field, operator, value]);

  const onOperatorChange = useCallback(
    (opt: IncSelectOption) => {
      handleOnChange(opt.value);
    },
    [handleOnChange]
  );

  const menuPortalTarget = useMemo(() => document.body, []);
  return (
    <div className="op-container">
      <IncSelect
        autoSort={false}
        label={showLabel ? "Condition" : ""}
        menuPlacement="auto"
        menuPortalTarget={menuPortalTarget}
        onChange={onOperatorChange}
        options={operatorOptions}
        placeholder="="
        value={selectedOperatorOpt}
      />
    </div>
  );
};

export const QueryFieldEditor: FC<FieldSelectorProps> = props => {
  const { context, rule, handleOnChange } = props;

  const { fieldPickerModel, fieldPickerContext, fieldTypes, showLabel, demoDataParams } = context;

  const { field: rField } = rule as QueryBuilderRule;

  const field: FieldPickerOptionData = typeof rField === "string" ? null : rField;

  const onFilterKeyChange = useCallback(
    (options: FieldPickerOptionData[]) => {
      const selOp = options[0];
      handleOnChange(selOp);
    },
    [handleOnChange]
  );

  const selectedFieldOptions = field ? [field] : [];

  return (
    <div className="key-container">
      <FieldPickerContainer
        demoDataParams={demoDataParams}
        fieldPickerContextDto={fieldPickerContext}
        fieldPickerModel={fieldPickerModel}
        fieldTypes={fieldTypes}
        hideLabel={!showLabel}
        label={showLabel ? "Field" : ""}
        onChange={onFilterKeyChange}
        selectedOptions={selectedFieldOptions}
      />
    </div>
  );
};

export const CombinatorSelector: FC<CombinatorSelectorProps> = memo(props => {
  const { handleOnChange, value } = props;

  const selectedOpt = combinatorOpts.find(x => x.value === value);
  const onChange = (e: IncSelectOption) => {
    handleOnChange(e.value);
  };

  return (
    <IncSelect
      autoAdjustWidth
      autoAdjustWidthBuffer={12}
      className="combinator-selector"
      isSearchable={false}
      onChange={onChange}
      options={combinatorOpts}
      value={selectedOpt}
    />
  );
});

const combinatorOpts: IncSelectOption[] = [
  {
    label: "AND",
    value: LogicalOperator.AND
  },
  {
    label: "OR",
    value: LogicalOperator.OR
  }
];
