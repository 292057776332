import { extend } from "lodash";
import BaseWidgetImpl from "../../model-impl/BaseWidgetImpl";
import BaseWidgetModel, { CommonWidgetOptions, CommonWidgetProperties, Query } from "../../models/BaseWidgetModel";

export type WidgetBuilderCallbackResult<M extends BaseWidgetModel, B extends BaseWidgetBuilder<M>> = {
  setDatasource: (dsName: string) => B;
};

const baseWidgetBuilder: BaseWidgetBuilderCallback = (model = {}) => {
  const baseWidgetModel: BaseWidgetModel = new BaseWidgetImpl(model).getSafeDefaults();

  // Return a method that ensures a datasource is set before setting any other properties
  return {
    setWidgetType: (type: string) => {
      baseWidgetModel.type = type;

      // Return a method that ensures a datasource is set before setting any other properties
      return {
        setDatasource: (dsName: string) => {
          baseWidgetModel.datasource = dsName;

          return new BaseWidgetBuilder(baseWidgetModel);
        }
      };
    }
  };
};

export class BaseWidgetBuilder<M extends BaseWidgetModel = BaseWidgetModel> {
  protected model: M;

  constructor(model: M) {
    this.model = model;
    this.model.properties = this.model.properties || {};
    this.model.options = this.model.options || {};
  }

  setId(id: string) {
    this.model.id = id;
    return this;
  }

  setTitle(title: string) {
    this.model.title = title;
    return this;
  }

  setOptions<O extends CommonWidgetOptions>(options: O) {
    this.model.options = options;
    return this;
  }

  addOptions<O extends CommonWidgetOptions>(options: O) {
    extend(this.model.options, options);
    return this;
  }

  setProperties<P extends CommonWidgetProperties>(properties: P) {
    this.model.properties = properties;
    return this;
  }

  enableEdit() {
    this.model.meta.edit = true;
    return this;
  }

  disableEdit() {
    this.model.meta.edit = false;
    return this;
  }

  enableCompare() {
    this.model.meta.compare = true;
    return this;
  }

  disableCompare() {
    this.model.meta.compare = false;
    return this;
  }

  enableResize() {
    this.model.meta.resizable = true;
    return this;
  }

  disableResize() {
    this.model.meta.resizable = false;
    return this;
  }

  enableHeader() {
    this.model.meta.hideHeader = false;
    return this;
  }

  disableHeader() {
    this.model.meta.hideHeader = true;
    return this;
  }

  enableBorder() {
    this.model.properties.borderLess = false;
    return this;
  }

  disableBorder() {
    this.model.properties.borderLess = true;
    return this;
  }

  enableActions() {
    this.model.meta.hideActions = false;
    return this;
  }

  disableActions() {
    this.model.meta.hideActions = true;
    return this;
  }

  enableTransparent() {
    this.model.properties.transparent = true;
    return this;
  }

  disableTransparent() {
    this.model.properties.transparent = false;
    return this;
  }

  disablePadding() {
    this.model.options.noPadding = true;
    return this;
  }

  enablePadding() {
    this.model.options.noPadding = false;
    return this;
  }

  addQuery(query: Query) {
    this.model.queries.push(query);
    return this;
  }

  protected onQueryAdd = (query: any) => {
    this.model.queries.push(query);
  };

  buildModel() {
    return this.model;
  }
}

type BaseWidgetBuilderCallback = (model?: Partial<BaseWidgetModel>) => {
  setWidgetType: (type: string) => WidgetBuilderCallbackResult<BaseWidgetModel, BaseWidgetBuilder<BaseWidgetModel>>;
};

export default baseWidgetBuilder;
