import { generateUUID, getInceptionTheme } from "@inception/ui";
import React, { useCallback, useMemo } from "react";
import { isNil } from "lodash";
import { SingleStatVisualization } from "../../../../../components/single-stat/SingleStatVisualization";
import { Visualisations } from "../../../../../core";
import { CatalogVizRendererProps, getFormattedValue, useCommonRendererFunctionality } from "./common";

type Props = CatalogVizRendererProps & {
  fontSize?: number;
  metricId?: string;
};

export const SingleStatRenderer: React.FC<Props> = (props: Props) => {
  const {
    loadingElement,
    noDataElement,
    properties,
    fontSize,
    metricId: singleStatMetricId,
    queryId,
    dataType: defDataType,
    dataTypeByMetricId,
    dataFetchPayload,
    currencyType
  } = props;

  const { singleStat } = properties;
  const { defaultBackground, defaultColor, thresholds } = singleStat || {};
  const { sliceSpec, mode } = dataFetchPayload || {};
  const dataType = dataTypeByMetricId?.[singleStatMetricId] || defDataType;

  const singleStatProps = useMemo<
    Pick<CatalogVizRendererProps, "childMetricIds" | "childrenDataFetchPayload" | "dataFetchPayload" | "queryId">
  >(() => {
    const nSliceSpec = singleStatMetricId
      ? (sliceSpec || []).filter(sliceSpec => sliceSpec.metricId === singleStatMetricId)
      : (sliceSpec || []).slice(0, 1);

    return {
      dataFetchPayload: {
        sliceSpec: nSliceSpec,
        mode
      },
      childrenDataFetchPayload: null,
      childMetricIds: [],
      queryId: singleStatMetricId ? queryId + singleStatMetricId + generateUUID() : queryId
    };
  }, [mode, queryId, singleStatMetricId, sliceSpec]);

  const { data, isFetching, isError, dataExists, fieldName } = useCommonRendererFunctionality({
    ...props,
    ...singleStatProps,
    visualisation: Visualisations.singleStat
  });

  const displayValue = useMemo(() => {
    const datum = data?.[0];
    if (datum) {
      const postAggData = datum.postAggResult.data;

      const refId = Object.keys(postAggData)[0];

      const dataFrame = postAggData[refId]?.data || [];
      return dataFrame[0]?.fields?.[1]?.data?.[0];
    }

    return null;
  }, [data]);

  const formatter = useCallback(
    (value: number) => {
      if (value === undefined || value === null) {
        return "-";
      }

      return getFormattedValue(fieldName, value, dataType, currencyType);
    },
    [currencyType, dataType, fieldName]
  );

  const { color, backgroundColor } = useMemo(() => {
    const color = defaultColor || getInceptionTheme().inceptionColors.palette.BL25;
    const backgroundColor = defaultBackground || "transparent";

    const threshold = thresholds?.find(x => x.fromVal <= displayValue && x.toVal >= displayValue);
    if (threshold) {
      return {
        color: threshold.color,
        backgroundColor: threshold.background || defaultBackground
      };
    }

    return {
      color,
      backgroundColor
    };
  }, [defaultBackground, defaultColor, displayValue, thresholds]);

  if (isFetching) {
    return loadingElement;
  }

  if (!dataExists || isError || isNil(displayValue)) {
    return noDataElement;
  }

  return (
    <SingleStatVisualization
      backgroundColor={backgroundColor}
      data={displayValue}
      fontSize={fontSize}
      formatter={formatter}
      textColor={color}
    />
  );
};
