import React, { memo, useMemo } from "react";
import appConfig from "../../appConfig";
import { TimeRange, generateId } from "../core";
import Op10zeWidgetImpl from "../dashboard/widgets/Operationalize/models/impl";
import Op10zeWidgetModel from "../dashboard/widgets/Operationalize/models/model";
import { SelectorTag, SelectorSpec, TimeObj } from "../services/api/explore";
import timeRangeUtils from "../utils/TimeRangeUtils";
import { getBufferTimeMinsForIncidentSeries } from "../utils";
import { getCompareSeriesOverrides } from "./incidentTimeSeries/TimeSeriesWidgetHelper";
import { StandAloneWidget } from "./StandaloneWidget";

interface WrapperProps {
  tagCombination: Record<string, string>;
  incidentId: string;
  opConfigId: string;
  simulationId: string;
  startTimeMillis: number;
  endTimeMillis: number;
  frequency: TimeObj;
  lookBack: TimeObj;
  generateDemoData?: boolean;
  asSparkline?: boolean;
  noPointMarker?: boolean;
  hideLegends?: boolean;
  metricName?: string;
  isOpportunity?: boolean;
  enableBufferTimeRange?: boolean;
  rollingFrequencyMillis?: number;
  mqId?: string;
  compareTimeRange?: TimeRange;
  ignoreEndBuffer?: boolean;
  hideHistory?: boolean;
}

export const Op10zeQueryWidgetWrapper = memo<WrapperProps>(props => {
  const {
    endTimeMillis,
    tagCombination,
    incidentId,
    opConfigId,
    simulationId,
    startTimeMillis,
    frequency,
    lookBack,
    compareTimeRange: pCompareTimeRange,
    asSparkline = false,
    hideLegends = false,
    noPointMarker = false,
    generateDemoData,
    metricName = "",
    isOpportunity = false,
    rollingFrequencyMillis = null,
    enableBufferTimeRange = false,
    ignoreEndBuffer = false,
    hideHistory,
    mqId = ""
  } = props;

  const { compareTimeRange, timeRange } = useMemo(() => {
    let fromMillisStr = String(startTimeMillis);
    let toMillisStr = String(endTimeMillis);

    if (enableBufferTimeRange) {
      const { startTimeMillisWithBuffer, endTimeMillisWithBuffer } = getBufferTimeMinsForIncidentSeries(
        startTimeMillis,
        endTimeMillis,
        rollingFrequencyMillis,
        ignoreEndBuffer
      );
      fromMillisStr = String(startTimeMillisWithBuffer);
      toMillisStr = String(endTimeMillisWithBuffer);
    }

    const timeRange = timeRangeUtils.getTimeRangeFromRaw({
      from: fromMillisStr,
      to: toMillisStr
    });

    const compareTimeRange = pCompareTimeRange
      ? pCompareTimeRange
      : timeRangeUtils.getCompareTimeRangeFromRaw(timeRange, "1d");

    return {
      compareTimeRange,
      timeRange
    };
  }, [
    enableBufferTimeRange,
    endTimeMillis,
    ignoreEndBuffer,
    pCompareTimeRange,
    rollingFrequencyMillis,
    startTimeMillis
  ]);

  const widgetId = useMemo(() => generateId(), []);

  const widgetImpl = useMemo(() => {
    const tags: SelectorTag[] = [];

    Object.keys(tagCombination || {}).forEach(key => {
      const value = tagCombination[key];
      const tag: SelectorTag = {
        key,
        value: [value]
      };

      tags.push(tag);
    });

    const selectorSpec: SelectorSpec = {
      filters: [
        {
          tags
        }
      ]
    };
    const model: Op10zeWidgetModel = {
      datasource: appConfig.defaultOp10zeDsName,
      id: widgetId,
      queries: [
        {
          payload: {
            forCount: false,
            frequency,
            lookBack,
            mqId,
            // downsampleSecs,
            selectorSpec
          },
          generateDemoData,
          opId: opConfigId,
          simulationId,
          incidentId,
          refId: "A",
          previewPayload: null,
          query: ""
        }
      ],
      title: metricName,
      type: "operationalize",
      isOpportunity,
      mode: "data",
      seriesOverrides: getCompareSeriesOverrides(null, isOpportunity).map(override => ({
        ...override,
        marker: {
          ...(override.marker || {}),
          radius:
            override.alias === ".*anomaly.*" || override.alias === ".*suppression.*"
              ? override.marker.radius
              : noPointMarker
                ? 0
                : 1
        }
      })),
      chartType: "spline",
      options: {
        yAxis: [
          {
            visible: !asSparkline,
            grid: {
              enabled: false
            }
          },
          {
            visible: !asSparkline,
            grid: {
              enabled: false
            }
          }
        ],
        xAxis: {
          visible: !asSparkline
        },
        legend: {
          enabled: false
        }
      },
      properties: {
        legend: {
          show: !asSparkline && !hideLegends
        },
        chartType: "line",
        disableInteractions: asSparkline,
        borderLess: true,
        hideHistory,
        transparent: true
      },
      meta: {
        resizable: false,
        hideHeader: true,
        hideActions: true,
        compare: false,
        edit: false
      },
      disableSparklineInteraction: asSparkline
    };

    return new Op10zeWidgetImpl(model);
  }, [
    asSparkline,
    frequency,
    generateDemoData,
    hideHistory,
    hideLegends,
    incidentId,
    isOpportunity,
    lookBack,
    metricName,
    mqId,
    noPointMarker,
    opConfigId,
    simulationId,
    tagCombination,
    widgetId
  ]);

  return (
    <StandAloneWidget
      compareTimeRange={compareTimeRange}
      timeRange={timeRange}
      widget={widgetImpl}
    />
  );
});
