import { useMemo } from "react";
import { USFWProps } from "../../types";
import { AggregationUIOptions, TimeRange } from "../../../../../core";
import {
  MetricResultDataDTO,
  CohortEntityFilter,
  WidgetResponseDTO,
  UserServiceFilterList
} from "../../../../../services/api/explore";
import { BizEntityFiltersProps, TagFilterSelection } from "../../../../../biz-entity";
import { WidgetCustomAction } from "../../../types";
import { useUSFWAddToDashboardAction } from "./useUSFWAddToDashboardAction";
import { useViewEventsAction } from "./useViewEventsAction";
import { useViewDashboardFilters } from "./useViewDashboardFilters";
import { useSelectFiltersAction } from "./useSelectFiltersAction";
import { useViewConfigurationAction } from "./useViewConfigurationAction";
import { useAggSelectAction } from "./useAggSelectAction";
import { useViewModeSwitchAction } from "./useViewModeSwitchAction";
import { useDisableCompareAction } from "./useDisableCompareAction";
import { useDownsampleAction } from "./useDownsampleAction";
import { useAlertsAction } from "./useAlertsAction";
import { useTimeRangeAction } from "./useTimeRangeAction";

type Props = {
  widget: USFWProps["widget"];
  aggOptions: AggregationUIOptions[];
  aggregator: AggregationUIOptions;
  setAggregator: (aggregator: AggregationUIOptions) => void;
  resultMeta: MetricResultDataDTO[];
  isResultMetaFetching: boolean;
  widgetResponseFetchInProgress: boolean;
  allowPartialMatch: boolean;
  onFiltersExtracted: BizEntityFiltersProps["onFiltersExtracted"];
  setSelectedFilters: (nSel: TagFilterSelection) => void;
  dbImpl: USFWProps["dbImpl"];
  propertiesChanged: boolean;
  entityFilters: CohortEntityFilter[];
  cohortFilters: CohortEntityFilter[];
  eventFilters: Record<string, UserServiceFilterList>;
  selectedFilters: TagFilterSelection;
  timeRange: TimeRange;
  dataQueryConfig: Record<any, any>;
  widgetResponseDTO: WidgetResponseDTO;

  headerRef: React.RefObject<HTMLDivElement>;
};

export const useCustomActions = (props: Props) => {
  const { widget, headerRef, timeRange } = props;

  const {
    title,
    widgetConfigRefId,
    properties: { showAlerts = true }
  } = widget;

  const isAdhocView = widget.renderMode === "adhoc-view";

  const { addToDashboardActionComponent, addToDashboardActionModal } = useUSFWAddToDashboardAction(props);

  const { viewMode, viewModeSwitchAction } = useViewModeSwitchAction(props);

  const viewEventsAction = useViewEventsAction(props);
  const viewConfigurationAction = useViewConfigurationAction(props);
  const viewDashboardFiltersAction = useViewDashboardFilters(props);
  const viewFiltersAction = useSelectFiltersAction(props);
  const aggSelectAction = useAggSelectAction(props);
  const disableCompareAction = useDisableCompareAction(props);
  const downsampleAction = useDownsampleAction({
    ...props,
    anchorRef: headerRef
  });
  const alertsAction = useAlertsAction(widgetConfigRefId, title, timeRange, headerRef);
  const timeRangeAction = useTimeRangeAction(props);

  const customActions = useMemo(() => {
    const customActions: WidgetCustomAction[] = [];

    const addToDashboardAction =
      addToDashboardActionComponent && !isAdhocView
        ? {
            actionComponent: addToDashboardActionComponent
          }
        : null;

    addToDashboardAction && customActions.push(addToDashboardAction);
    viewEventsAction && customActions.push(viewEventsAction);
    viewConfigurationAction && customActions.push(viewConfigurationAction);
    viewModeSwitchAction && customActions.push(viewModeSwitchAction);
    viewDashboardFiltersAction && customActions.push(viewDashboardFiltersAction);
    downsampleAction && customActions.push(downsampleAction);
    disableCompareAction && customActions.push(disableCompareAction);
    alertsAction && showAlerts && customActions.push(alertsAction);
    viewFiltersAction && customActions.push(viewFiltersAction);
    aggSelectAction && customActions.push(aggSelectAction);
    timeRangeAction && customActions.push(timeRangeAction);

    return customActions;
  }, [
    addToDashboardActionComponent,
    aggSelectAction,
    alertsAction,
    disableCompareAction,
    downsampleAction,
    isAdhocView,
    timeRangeAction,
    viewConfigurationAction,
    viewDashboardFiltersAction,
    viewEventsAction,
    viewFiltersAction,
    viewModeSwitchAction,
    showAlerts
  ]);

  return {
    viewMode,
    customActions,
    addToDashboardActionModal
  };
};
