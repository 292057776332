import React, { useCallback, useMemo, useRef } from "react";
import { cloneDeep, forEach, some } from "lodash";
import { ISaxIcon } from "@inception/ui";
import { WidgetCustomAction } from "../../../types";
import {
  SelectorSpec,
  UserServiceFilter,
  UserServiceFilterExpression,
  WidgetResponseDTO
} from "../../../../../services/api/explore";
import { CatalogWidgetProps, DataState } from "../../types";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../../../components";
import {
  getDefaultMetricsStateFromWidgetConfig,
  getFinalMetricUserServiceFilters,
  getWidgetConfigStateFromMetricsState
} from "../../maximize-view/components/Data/utils";
import { useForceUpdate } from "../../../../../core";
import { WidgetFilters } from "../../components/WidgetFilters";
import { FetchResultMetaProps } from "../useGetResultMeta";

type Props = {
  widget: CatalogWidgetProps["widget"];
  setWidgetSelectorSpec: (selectorSpec: SelectorSpec) => void;
  widgetSelectorSpec: SelectorSpec;
  widgetResponseFetchInProgress: boolean;
  widgetResponseDTO: WidgetResponseDTO;
  allowPartialMatch: boolean;
  dataState: DataState;
  fetchResultMetaProps: FetchResultMetaProps;
};

export const useSelectFiltersAction = (props: Props) => {
  const {
    setWidgetSelectorSpec,
    widgetSelectorSpec,
    widgetResponseDTO,
    widget,
    widgetResponseFetchInProgress,
    fetchResultMetaProps,
    dataState
  } = props;

  const { widgetConfig } = widgetResponseDTO || {};
  const { metricUserServiceFilters } = widget || {};
  const { metricUserServiceFilters: userServiceConfigFilters } = widgetConfig || {};

  const filtersExist = useMemo(() => {
    let exist = false;
    //check for the selector spec filter length
    const { filters } = widgetSelectorSpec || {};
    forEach(filters, filter => {
      const { tags } = filter;
      if (tags.length > 0) {
        exist = true;
        return false;
      }
    });

    if (!exist) {
      //check for the userServiceConfigFilters filter length
      forEach(userServiceConfigFilters || {}, filtersList => {
        if (
          some(
            filtersList?.userServiceFilters || {},
            (userServiceFilter: UserServiceFilter) => userServiceFilter.userServiceFilterExpressions.length > 0
          )
        ) {
          exist = true;
          return false; // Exit the loop early
        }
      });
    }

    if (!exist) {
      //check for the metricUserServiceFilters filter length
      forEach(metricUserServiceFilters || {}, filtersList => {
        if (
          some(
            filtersList?.userServiceFilters || {},
            (userServiceFilter: UserServiceFilter) => userServiceFilter.userServiceFilterExpressions.length > 0
          )
        ) {
          exist = true;
          return false; // Exit the loop early
        }
      });
    }
    return exist;
  }, [metricUserServiceFilters, userServiceConfigFilters, widgetSelectorSpec]);

  const forceUpdate = useForceUpdate();

  const disableUSFieldCheckRef = useRef(false);
  /**
   *
   */
  const updateFilters = useCallback(
    (filters: UserServiceFilterExpression[]) => {
      const { bizEntityType } = widgetConfig;
      const metrics = widgetConfig.dataDefinition?.metrics || {};
      const metricsState = getDefaultMetricsStateFromWidgetConfig(widgetConfig, widgetConfig.userServiceEntityId);
      const isEntityFirst = Boolean(bizEntityType);
      const widgetConfigStateRef = getWidgetConfigStateFromMetricsState(
        metricsState,
        bizEntityType,
        isEntityFirst,
        disableUSFieldCheckRef
      );
      const { vizMetricIds } = widgetConfigStateRef;
      const metricUserServiceFilters = getFinalMetricUserServiceFilters(filters, metrics, vizMetricIds);
      widget.metricUserServiceFilters = metricUserServiceFilters;
      forceUpdate();
    },
    [forceUpdate, widget, widgetConfig]
  );
  /**
   *
   */

  const viewFiltersAction = useMemo<WidgetCustomAction>(() => {
    const { fetchCount, isLoading, totalCount } = dataState || {};

    if (widgetResponseFetchInProgress || isLoading) {
      const actionComponent = (
        <VerticallyCenteredRow className="filters-info-container">
          <ISaxIcon
            className="status-info"
            fade
            iconName="Filter"
            size={15}
            variant="Bold"
          />
          <LoadingSpinner
            className="status-info marginLt4"
            titleText=" "
          />
        </VerticallyCenteredRow>
      );

      return {
        actionComponent,
        showInHeader: true,
        tooltipText: ""
      };
    }

    const showViewFilters = Boolean(widgetResponseDTO?.querySchema?.querySchema?.length);

    if (showViewFilters) {
      const nWidgetResponseDTO = {
        ...widgetResponseDTO,
        querySchema: cloneDeep(widgetResponseDTO.querySchema)
      };

      const actionComponent = (
        <WidgetFilters
          className="inc-cursor-pointer"
          fetchResultMetaProps={fetchResultMetaProps}
          filterMessage={`${fetchCount} / ${totalCount}`}
          filtersExist={filtersExist}
          metricUserServiceFilters={widget?.metricUserServiceFilters}
          onFilterChange={updateFilters}
          setWidgetSelectorSpec={setWidgetSelectorSpec}
          skipTooltip
          widgetResponseDTO={nWidgetResponseDTO}
          widgetSelectorSpec={widgetSelectorSpec}
        />
      );

      return {
        actionComponent,
        showInHeader: true,
        tooltipText: ""
      };
    }

    return null;
  }, [
    dataState,
    fetchResultMetaProps,
    filtersExist,
    setWidgetSelectorSpec,
    updateFilters,
    widget?.metricUserServiceFilters,
    widgetResponseDTO,
    widgetResponseFetchInProgress,
    widgetSelectorSpec
  ]);

  return viewFiltersAction;
};
