import React, { FC, useEffect, useCallback, memo, useState, useMemo, useRef } from "react";
import { cloneDeep, isEqual, pick } from "lodash";
import { IncFaIcon, IncGenericIcon, IncInModalConfirmation, IncModal, IncSmartText } from "@inception/ui";
import { LoadingSpinner, VerticallyCenteredRow } from "../../components";
import { isValidCohortId, getAllCohortDefinition } from "../../utils/CohortUtils";
import { operationaliseV2ApiService, OpCreationConfig } from "../../services/api/operationalise";
import {
  useNotifications,
  generateId,
  useToggleState,
  useAccessPrivilege,
  useFetchActionCategoryList,
  useTenantConfig
} from "../../core";
import { logger } from "../../core/logging/Logger";
import { OpDefContext, OpContext, SimulationConfig } from "../context/types";
import {
  OpProvider,
  useOpStore,
  setOperationaliseContext,
  setPartialOperationaliseContext,
  setOperationaliseConfig,
  setActionCategories,
  setTemporaryActions,
  setSimulations,
  setHistory,
  setSimulationConfig,
  setOp10zeId,
  setPrimaryOpCreationConfig,
  setReadOnly,
  setIsDraft,
  setSaveOpCreationConfig,
  setOpMetaData,
  setShouldReloadEditors
} from "../context";
import { fetchContext, getDefaultOpConfig, getOpMetaDataFromOp } from "../utils";
import {
  UI_SCHEDULE_KEY,
  SCHEDULE_TYPES,
  TEMPORARY_SIMULATION_KEY,
  PRIMARY_CONFIG_AS_SIMULATION_KEY
} from "../constants";
import { BizDataQuery, CohortConfig } from "../../services/api/explore";
import { noOp } from "../../utils";
import { OpV3ConditionsWrapper, OverviewRendererV3, OpV3ActionsWrapper } from "./wrappers";
import { OperationaliseV3Props } from "./types";
import { NameAndDescriptionModalWrapper } from "./editors";
import { ImpactAndCausalFieldsEditor } from "./editors/ImpactAndCausalFields";
import { transformStartTriggersForOpConfig, transformTimeZoneForOpConfig } from "./editors/common";

export const OperationaliseV3Modal: FC<OperationaliseV3Props> = props => (
  <OpProvider>
    <OperationalizeV3Wrapper {...props} />
  </OpProvider>
);

export const OperationalizeV3Wrapper = memo<OperationaliseV3Props>(props => {
  const {
    isMetaDataLoading,
    entityTypeId,
    eventTypeId,
    cohortId,
    opCreationContext,
    eventTypeName,
    entityTypeName,
    bizEntityFieldName,
    defaultNewConfig,
    defaultMode = "create",
    implicitSlice,
    defaultOp10zeSelectionId,
    onHandlersChange,
    onClose,
    onConfigChange,
    footerChildren: pFooterChildren = <></>,
    hasError,
    error,
    defaultTab = "configuration",
    onSaveOrUpdate = noOp,
    onSimulate = noOp,
    skipSaveOrUpdate = false,
    ignoreActionConnectionErrors = false,
    noModal = false,
    monitorBtnLabel
  } = props;

  const { canEdit, canCreate } = useAccessPrivilege();

  const { tenantConfigState } = useTenantConfig();
  const { useNewNavigation, defaultTimezone } = tenantConfigState || {};

  const { close: closeConfirmation, open: openConfirmation, isOpen: confirmationIsOpen } = useToggleState();

  const {
    close: switchToConditionsView,
    isOpen: isActionsView,
    open: switchToActionsView
  } = useToggleState(defaultTab === "actions");

  const { close: closeNameModal, isOpen: isNameModalOpen, open: openNameModal } = useToggleState();

  const triageConfigInitedRef = useRef(false);
  const setTriageConfigInited = useCallback((inited: boolean) => (triageConfigInitedRef.current = inited), []);
  const triageCompareOpConfigRef = useRef<BizDataQuery>();

  const { notifyError } = useNotifications();

  const [isContextLoading, setIsContextLoading] = useState(true);
  const [isConfigLoading, setIsConfigLoading] = useState(true);

  const [saveOrUpdateInProgress, setSaveOrUpdateInProgress] = useState(false);
  const [overrideInProgress, setOverrideInProgress] = useState(false);

  const [mode, setMode] = useState(defaultMode);

  const { dispatch, state } = useOpStore();
  const { context, metaData, op10zeId, opCreationConfig, readOnly, selectedSimulation } = state;

  const { widgetId } = context;

  // Reset triage config inited state if opCreationConfig's bizDataQuery has changed
  const prevBizDataQuery = triageCompareOpConfigRef.current;
  const nextBizDataQuery = opCreationConfig?.opCreationConfigDef?.bizDataQuery;
  useMemo(() => {
    const bizDataQueryChanged = isEqual(prevBizDataQuery, nextBizDataQuery);
    if (bizDataQueryChanged) {
      triageConfigInitedRef.current = false;
      triageCompareOpConfigRef.current = nextBizDataQuery;
    }
  }, [nextBizDataQuery, prevBizDataQuery]);

  useEffect(() => {
    if (onConfigChange) {
      onConfigChange(opCreationConfig);
    }
  }, [onConfigChange, opCreationConfig]);

  const resetState = useCallback(() => {
    setMode(null);
    dispatch(setOperationaliseConfig(null));
    onClose();
  }, [dispatch, onClose]);

  const updateOpConfigState = useCallback(
    (opConfig: OpCreationConfig) => {
      if (opConfig) {
        const partContext = getScheduleSelectionAndCohortContext(opConfig);
        partContext.sessionId = generateId();

        dispatch(setPartialOperationaliseContext(partContext));
        dispatch(setOperationaliseConfig(opConfig));
        dispatch(setShouldReloadEditors(true));

        dispatch(setTemporaryActions({}));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (onHandlersChange) {
      onHandlersChange({
        updateOpCreationConfig: updateOpConfigState
      });
    }
  }, [onHandlersChange, updateOpConfigState]);

  const initialiseContext = useCallback(async () => {
    setIsContextLoading(true);

    const context: OpDefContext = {
      cohortDefinition: null,
      cohortState: null,
      entityTypeInfo: null,
      eventTypeInfo: null,
      mode: defaultMode,
      implicitSlice,
      demoDataParams: opCreationContext.demoDataParams
    };

    await fetchContext(entityTypeId, eventTypeId, cohortId, context);

    const cohortName = isValidCohortId(cohortId) ? context?.cohortDefinition?.name : "";

    dispatch(
      setOperationaliseContext({
        ...context,
        ...opCreationContext,
        entityTypeId,
        eventTypeId,
        eventTypeName: eventTypeName || context?.eventTypeInfo?.name,
        entityTypeName: entityTypeName || context?.entityTypeInfo?.name,
        bizEntityFieldName: bizEntityFieldName,
        cohortName,
        uiIntegrationActionConfigsMap: {},
        sessionId: generateId(),
        ignoreActionConnectionErrors
      })
    );

    setIsContextLoading(false);
  }, [
    bizEntityFieldName,
    cohortId,
    defaultMode,
    dispatch,
    entityTypeId,
    entityTypeName,
    eventTypeId,
    eventTypeName,
    ignoreActionConnectionErrors,
    implicitSlice,
    opCreationContext
  ]);

  useEffect(() => {
    dispatch(setPartialOperationaliseContext({ mode }));
  }, [dispatch, mode]);

  const initialiseOpConfig = useCallback(async () => {
    setIsConfigLoading(true);
    let opConfig: OpCreationConfig;
    let simulationConfig: SimulationConfig;
    let op10zeId: string;
    let primaryOpConfig: OpCreationConfig;
    let saveOpCreationConfig: OpCreationConfig;

    const simulations: SimulationConfig[] = [];

    if (defaultMode === "edit" && defaultOp10zeSelectionId) {
      const { data, error, message } = await operationaliseV2ApiService.getOpConfigs(null, defaultOp10zeSelectionId);

      opConfig = data.opCreationConfigs?.[defaultOp10zeSelectionId];
      transformStartTriggersForOpConfig(opConfig);

      if (error || !opConfig) {
        notifyError("Error fetching configuration");
        logger.error("OperationaliseV3", "Error fetching configuration", message);
      } else {
        op10zeId = defaultOp10zeSelectionId;
        const simulationConfigs: SimulationConfig[] = (opConfig.simulations || []).map(s => ({
          ...s,
          isPrimary: s.labels?.isPrimary === "true"
        }));
        simulations.push(...simulationConfigs);

        saveOpCreationConfig = opConfig;
        if (!opConfig.isDraft) {
          primaryOpConfig = cloneDeep(opConfig);
          simulationConfig = {
            simulationId: PRIMARY_CONFIG_AS_SIMULATION_KEY,
            createdBy: null,
            description: primaryOpConfig.description,
            name: primaryOpConfig.name,
            isPrimary: true,
            labels: {
              isPrimary: "true"
            },
            opCreationConfig: primaryOpConfig
          };
        } else {
          if (!opConfig.simulations?.length) {
            opConfig.simulations = [
              {
                simulationId: PRIMARY_CONFIG_AS_SIMULATION_KEY,
                createdBy: null,
                description: opConfig.description,
                name: opConfig.name,
                labels: {
                  isPrimary: "false"
                },
                opCreationConfig: cloneDeep(opConfig)
              }
            ];
          }

          /**
           * Picking up the triage config from the primary opConfig and putting them here.
           * Since we use opCreationConfig from the state to edit these, and they are part of primary op config.
           */
          opConfig.simulations.forEach(simulationConfig => {
            const triageConfig = pick(opConfig, "impactedWidgets", "diagnosticFields", "impactFields");
            simulationConfig.opCreationConfig = {
              ...simulationConfig.opCreationConfig,
              ...triageConfig
            };
          });

          const simulation = opConfig.simulations[0];
          simulationConfig = {
            ...simulation,
            isPrimary: false
          };
        }
      }
    } else if (defaultMode === "create") {
      opConfig = cloneDeep(defaultNewConfig);
      transformStartTriggersForOpConfig(opConfig);
      transformTimeZoneForOpConfig(opConfig, defaultTimezone);

      simulationConfig = {
        createdBy: null,
        description: "",
        isPrimary: false,
        name: "Condition 1",
        opCreationConfig: opConfig,
        simulationId: TEMPORARY_SIMULATION_KEY,
        labels: {
          isPrimary: "false"
        }
      };
    }

    updateOpConfigState(opConfig);
    const opMetaData = getOpMetaDataFromOp(opConfig);
    dispatch(setOpMetaData(opMetaData));
    dispatch(setSimulationConfig(simulationConfig));
    dispatch(setSimulations(simulations));
    dispatch(setHistory(opConfig?.history || {}));

    if (op10zeId) {
      dispatch(setOp10zeId(op10zeId));
      dispatch(setReadOnly(true));
    }

    if (primaryOpConfig) {
      dispatch(setPrimaryOpCreationConfig(primaryOpConfig));
    }

    if (saveOpCreationConfig) {
      dispatch(setSaveOpCreationConfig(saveOpCreationConfig));
    }

    setIsConfigLoading(false);
  }, [
    defaultMode,
    defaultNewConfig,
    defaultOp10zeSelectionId,
    defaultTimezone,
    dispatch,
    notifyError,
    updateOpConfigState
  ]);

  useEffect(() => {
    if (!isMetaDataLoading) {
      initialiseContext();
    }
  }, [initialiseContext, isMetaDataLoading]);

  useEffect(() => {
    if (!isContextLoading) {
      initialiseOpConfig();
    }
  }, [initialiseOpConfig, isContextLoading]);

  const onOverrideOpCreationConfig = useCallback(
    async (opCreationConfig: OpCreationConfig) => {
      setOverrideInProgress(true);

      delete opCreationConfig.createdBy;
      delete opCreationConfig.lastUpdatedBy;

      const { data, error, message } = await operationaliseV2ApiService.editOpConfig(
        opCreationConfig,
        op10zeId,
        widgetId
      );
      if (error) {
        notifyError("Error while updating operationalize");
        logger.error("OpV3Editor", "Error editing operationalization", message);
      } else {
        const { op10zeId, opCreationConfig } = data;

        dispatch(setPrimaryOpCreationConfig(opCreationConfig));
        dispatch(
          setSimulationConfig({
            createdBy: opCreationConfig.createdBy,
            description: opCreationConfig.description,
            isPrimary: true,
            labels: {
              isPrimary: "true"
            },
            name: opCreationConfig.name,
            opCreationConfig,
            simulationId: PRIMARY_CONFIG_AS_SIMULATION_KEY
          })
        );
        dispatch(setReadOnly(true));
        dispatch(setOp10zeId(op10zeId));
        dispatch(setIsDraft(false));
      }
      setOverrideInProgress(false);
    },
    [dispatch, notifyError, op10zeId, widgetId]
  );

  const opConfigExists = Boolean(state.opCreationConfig);
  const canClose = !(saveOrUpdateInProgress || overrideInProgress);

  const onTriggerClose = useCallback(() => {
    if (hasError) {
      onClose();
    }
    if (selectedSimulation?.simulationId === TEMPORARY_SIMULATION_KEY) {
      openConfirmation();
    } else {
      onClose();
    }
  }, [hasError, onClose, openConfirmation, selectedSimulation?.simulationId]);

  const errorElement = <div className="status-danger">{error || "Error initializing operationalize"}</div>;

  const { name: opName, description: opDescription, icon } = metaData || {};

  const titleText = useMemo(() => {
    if (hasError) {
      return "Operationalize";
    }

    return (
      <VerticallyCenteredRow className="flex-gap-12">
        {useNewNavigation && Boolean(icon) && (
          <IncGenericIcon
            iconName={icon}
            size={16}
          />
        )}

        <VerticallyCenteredRow
          className="inc-text-header-medium"
          style={{ whiteSpace: "nowrap" }}
        >
          {opName || "Operationalize"}
        </VerticallyCenteredRow>

        {Boolean(opDescription) && (
          <>
            <div className="vertical-separator" />
            <IncSmartText
              className="inc-text-inactive inc-text-subtext-medium"
              text={opDescription}
            />
          </>
        )}

        {(canCreate || canEdit) && (
          <IncFaIcon
            className="status-info inc-cursor-pointer inc-text-body"
            iconName="edit"
            onClick={openNameModal}
          />
        )}
      </VerticallyCenteredRow>
    );
  }, [canCreate, canEdit, hasError, icon, opDescription, opName, openNameModal, useNewNavigation]);

  const numActions = useMemo(
    () => Object.keys(opCreationConfig?.bizActions || {}).length,
    [opCreationConfig?.bizActions]
  );

  const defConfigForAction = useMemo(() => defaultNewConfig || opConfigForActions, [defaultNewConfig]);

  const {
    data: actionCategories,
    error: actionCategoriesError,
    isError: isActionCategoriesFetchError,
    isFetching: isActionCategoriesFetching,
    refetch,
    sourceTypeInfoByCategory
  } = useFetchActionCategoryList(defConfigForAction, true);

  useEffect(() => {
    if (!isMetaDataLoading) {
      refetch();
    }
  }, [isMetaDataLoading, refetch]);

  useEffect(() => {
    if (isActionCategoriesFetchError) {
      logger.error("Operattionalize V3", "Error fetching action categories", actionCategoriesError);
    }
  }, [actionCategoriesError, isActionCategoriesFetchError]);

  useEffect(() => {
    if (!isActionCategoriesFetching) {
      dispatch(
        setActionCategories({
          actionCategories,
          sourceTypeInfoByCategory
        })
      );
    }
  }, [actionCategories, dispatch, isActionCategoriesFetching, sourceTypeInfoByCategory]);

  const opV3Content = (
    <>
      {hasError && <div className="inc-flex-center padding16 height-100">{errorElement}</div>}
      {!hasError && (
        <>
          {overrideInProgress && (
            <div className="inc-modal--confirmation">
              <div className="inc-modal--confirmation-content">
                <LoadingSpinner titleText="Updating operationalize..." />
              </div>
            </div>
          )}
          {isContextLoading && <LoadingSpinner titleText="Fetching details..." />}
          {!isContextLoading && isConfigLoading && <LoadingSpinner titleText="Fetching configuration..." />}
          {!isContextLoading && !isConfigLoading && (
            <>
              {!isActionsView && (
                <OpV3ConditionsWrapper
                  defaultNewConfig={defaultNewConfig}
                  hideListView={!op10zeId}
                  monitorBtnLabel={monitorBtnLabel}
                  numActions={numActions}
                  onOverrideOpCreationConfig={onOverrideOpCreationConfig}
                  onSaveOrUpdate={onSaveOrUpdate}
                  onSimulate={onSimulate}
                  opConfigExists={opConfigExists}
                  readOnly={readOnly}
                  setSaveOrUpdateInProgress={setSaveOrUpdateInProgress}
                  skipSaveOrUpdate={skipSaveOrUpdate}
                  switchToActionsView={switchToActionsView}
                >
                  <div className="operationalise-v3--header">
                    <OverviewRendererV3
                      saveOrUpdateInProgress={saveOrUpdateInProgress}
                      setSaveOrUpdateInProgress={setSaveOrUpdateInProgress}
                    />
                  </div>
                </OpV3ConditionsWrapper>
              )}
              {isActionsView && (
                <OpV3ActionsWrapper
                  numActions={numActions}
                  opConfigExists={opConfigExists}
                  saveOrUpdateInProgress={saveOrUpdateInProgress}
                  setSaveOrUpdateInProgress={setSaveOrUpdateInProgress}
                  switchToConditionsView={switchToConditionsView}
                >
                  <div className="operationalise-v3--header">
                    <ImpactAndCausalFieldsEditor
                      initialized={triageConfigInitedRef.current}
                      saveOrUpdateInProgress={saveOrUpdateInProgress}
                      setInitialized={setTriageConfigInited}
                      setSaveOrUpdateInProgress={setSaveOrUpdateInProgress}
                    />
                  </div>
                </OpV3ActionsWrapper>
              )}
            </>
          )}

          {confirmationIsOpen && (
            <IncInModalConfirmation
              message="You have unsaved changes. Are you sure you want to leave this page?"
              onCancel={closeConfirmation}
              onConfirm={resetState}
            />
          )}

          <NameAndDescriptionModalWrapper
            onClose={closeNameModal}
            onSaveOrUpdate={onSaveOrUpdate}
            open={isNameModalOpen}
            saveOrUpdateInProgress={saveOrUpdateInProgress}
            setSaveOrUpdateInProgress={setSaveOrUpdateInProgress}
          />
        </>
      )}
    </>
  );

  if (noModal) {
    return opV3Content;
  }

  return (
    <IncModal
      className="operationalise-v3"
      closeOnBackdrop={false}
      closeOnEscape={false}
      disableFocusOnLoad
      footerChildren={pFooterChildren}
      onClose={onTriggerClose}
      show
      showClose={canClose}
      size="side-pane"
      titleText={titleText}
    >
      {opV3Content}
    </IncModal>
  );
});

const getScheduleSelectionAndCohortContext = (opConfig: OpCreationConfig): Partial<OpContext> => {
  const { schedule } = opConfig?.opCreationConfigDef || opConfig?.outlierConfig || {};
  const bizDataQuery =
    opConfig?.opCreationConfigDef?.bizDataQuery || opConfig?.outlierConfig?.bizDataQuery?.bizDataQuery;

  const { buildingBlockConfig, id: widgetId } = bizDataQuery || {};

  const uiScheduleType = schedule?.labels?.[UI_SCHEDULE_KEY];

  let scheduleSelectionContext: OpContext["scheduleSelectionContext"];
  let selectionContext: OpContext["selectionContext"];
  if (buildingBlockConfig) {
    const aggregator = buildingBlockConfig?.buildingBlockDef?.aggregator;
    selectionContext = aggregator ? "metric" : "field";
    scheduleSelectionContext = uiScheduleType === SCHEDULE_TYPES.whenEventOccurs ? "field" : "fieldOrMetric";
  } else if (widgetId) {
    scheduleSelectionContext = "metric";
    selectionContext = "metric";
  }

  const cohortDefinition: CohortConfig = opConfig.idProps?.primary
    ? getAllCohortDefinition(opConfig.idProps.primary.bizEntityTypeId)
    : null;
  const cohortId = opConfig.idProps?.secondary?.cohortId || "";
  if (cohortId) {
    cohortDefinition.cohortId = cohortId;
    cohortDefinition.name = "";
  }

  return {
    selectionContext,
    scheduleSelectionContext,
    cohortDefinition,
    cohortState: null
  };
};

const opConfigForActions = getDefaultOpConfig();
opConfigForActions.idProps = {
  primary: {},
  secondary: {
    widgetId: ""
  }
};
delete opConfigForActions.opCreationConfigDef.bizDataQuery.id;
opConfigForActions.opCreationConfigDef.bizDataQuery.buildingBlockConfig = {
  id: "",
  name: "",
  buildingBlockDef: {
    aggregator: "count",
    fieldConfig: {
      userServiceField: {
        bizEntityFieldName: "",
        dataType: "STRING",
        fieldName: "",
        displayBizEntityFieldName: "",
        userServices: []
      }
    },
    filters: {
      filterExpressions: []
    },
    sliceDef: {
      sliceSets: [
        {
          slices: []
        }
      ]
    }
  }
};
