import { Options, Point, SeriesPackedbubbleOptions, ChartOptions } from "highcharts";
import { merge, defaults, forEach, repeat, escape as escapeStr } from "lodash";
import {
  HighChartsNumberFormatter,
  HighChartsLegendClick,
  getInceptionTheme,
  IncPercentRenderer,
  formatNumber
} from "@inception/ui";
import React, { isValidElement } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import getChartColor from "../charts/colors";
import { generateId } from "../../core";
import { renderReactComponent } from "../../../AppRenderer";
import { PackedBubbleSeries, LegendProps, ValueType } from "./types";

const FONT_STYLE: Highcharts.CSSObject = {
  color: "#4B6681",
  fontSize: "12px",
  height: 16,
  fontWeight: "500"
};

const LEGEND_WIDTH_PER = 15; // Percentage of chart width to be allocated for legend
const MIN_BUBBLE_SIZE = 75; // Minimum bubble size in pixels

export class BubbleChartOptionsBuilder {
  private options: Options;

  constructor(defOptions: Partial<Options> = {}) {
    this.initOptions(defOptions);
  }

  setChartFields(width: number, height: number): BubbleChartOptionsBuilder {
    this.options.chart = merge(
      {
        type: "packedbubble",
        height,
        width,
        zoomType: "x",
        backgroundColor: "transparent",
        spacingBottom: 10,
        numberFormatter: HighChartsNumberFormatter
      } as ChartOptions,
      this.options.chart
    );

    return this;
  }

  clearSeriesData(): BubbleChartOptionsBuilder {
    this.options.series = [];
    return this;
  }

  setSeriesData(
    series: PackedBubbleSeries[],
    keepBubbleSizeConstant: boolean,
    showDataLabels: boolean
  ): BubbleChartOptionsBuilder {
    const getTooltipProps = (p: Point) => this.getTooltipProps(p);

    const bubbleSeries = (series || []).map((serie, idx): SeriesPackedbubbleOptions => {
      const { name, bubbleSize, custom = {}, getTooltip, onClick, formatValue, bubbleColor, data } = serie;

      const sCustom: Record<string, any> = {
        visible: true,
        ...custom,
        getTooltip,
        keepBubbleSizeConstant,
        formatValue
      };
      sCustom.visibleHInternal = sCustom.visible; // We use this to hide/show the serie without triggering a re-render

      let className = "highcharts-visibility-transition";
      className += onClick ? " inc-cursor-pointer" : "";
      className += sCustom.visible ? "" : " highcharts-invisible";

      const shouldShowDataLabels = showDataLabels && sCustom.visible;
      const getSubtextDivHtml = (point: Point) => this.getSubtextDivHtml(point);

      return {
        name,
        className,
        type: "packedbubble",
        custom: sCustom,
        data: [
          {
            name,
            value: bubbleSize,
            data
          }
        ],
        visible: keepBubbleSizeConstant || sCustom.visible,
        opacity: sCustom.visible ? 1 : 0,
        color: bubbleColor || getChartColor(idx),
        dataLabels: {
          enabled: shouldShowDataLabels,
          formatter: function () {
            const { radius } = this.point as any;
            const width = radius * 1.5;
            const seriesName = this.series.getName();
            const style = `width: ${width}px; overflow: hidden; text-overflow: ellipsis; 
              white-space: nowrap; text-align: center; color: inherit;`;
            const subTextDivHtml = getSubtextDivHtml(this.point);
            return `<div class="data-label-text" style="${style}">
            ${seriesName}
            ${subTextDivHtml}
            </div>`;
          },
          style: {
            color: "inherit"
          }
        },
        showInLegend: sCustom.visible,
        point: {
          events: {
            mouseOver: function () {
              const tooltipProps = getTooltipProps(this);
              const isTooltipReactElement = isValidElement(tooltipProps);
              const point = this as any;

              if (isTooltipReactElement) {
                window.setTimeout(() => {
                  const tooltipId = getTooltipId(point?.id);
                  const tooltipElem = document.querySelector(`#${tooltipId}`);
                  if (tooltipElem) {
                    renderReactComponent(tooltipProps, tooltipElem);
                  }
                }, 0);
              }
            },
            click: function (event) {
              onClick?.(this.series, event);
            }
          }
        }
      };
    });

    if (this.options) {
      this.options.series = bubbleSeries;
    }

    this.setMaxBubbleSize();

    return this;
  }

  setToolTip(): BubbleChartOptionsBuilder {
    const getTooltipProps = (p: Point) => this.getTooltipProps(p);
    this.options.tooltip = {
      enabled: true,
      shared: false,
      outside: true,
      useHTML: true,
      formatter: function () {
        const customOptions = this.series.options.custom || {};
        const shouldShowTooltip = customOptions.visible && customOptions.visibleHInternal;

        if (shouldShowTooltip) {
          let points: Point[] = [];
          if (this.points) {
            points = this.points.map(p => p.point);
          } else if (this.point) {
            // When shared tooltip = false, we only get 1 point.
            points.push(this.point);
          } else {
            return "";
          }

          const tooltipId = getTooltipId((points[0] as any)?.id);
          let isReactElement = false;

          let finalStr = "";
          let radius = 0;

          for (let i = 0; i < points.length; ++i) {
            const point = points[i];
            const { options: pOptions, color: pColor } = point;
            const { name, value } = pOptions;
            radius = Math.max(radius, (point as any).radius || 0);

            const tooltipProps = getTooltipProps(point);
            const isTooltipReactElement = isValidElement(tooltipProps);

            isReactElement = isReactElement || isTooltipReactElement;

            if (!isTooltipReactElement) {
              const { header = "", content = "", extras = {} } = tooltipProps;
              finalStr += `<div class='inc-charts-tooltip-datetime'>
              <div class="inc-highcharts-square-symbol" style="background-color:${pColor};"></div>
              <div>${header || name}</div>
            </div>
            <div class='inc-charts-tooltip-series-row'>
              <div class='inc-charts-tooltip-series-name'>${content || "Value"}</div> 
              <div class='inc-charts-tooltip-series-value'>
                <span>${value}</span>
              </div>
            </div>`;

              // eslint-disable-next-line no-loop-func
              forEach(extras, (v, k) => {
                const values = Array.isArray(v) ? v : [v];
                const kAsSpaces = repeat(" ", k.length);
                values.forEach((value, idx) => {
                  const key = idx === 0 ? k : kAsSpaces;
                  finalStr += `<div class='inc-charts-tooltip-series-row'>
                  <div class='inc-charts-tooltip-series-name'>${key}</div> 
                  <div class='inc-charts-tooltip-series-value'>
                    <span>${(value || "-").toString()}</span>
                  </div>
                </div>`;
                });
              });
            }
          }

          if (!isReactElement) {
            finalStr = `<div class='inc-charts-tooltip inc-bubble-charts-tooltip' id="${tooltipId}">
            ${finalStr}
            </div>`;
          } else {
            const marginLeft = radius * 2 + 10;
            finalStr = `<div id="${tooltipId}" style="margin-left:${marginLeft}px; margin-top: -25%;"></div>`;
          }

          return finalStr;
        }
        return "";
      }
    };

    return this;
  }

  setLegend(uLegendProps?: LegendProps): BubbleChartOptionsBuilder {
    defaults(uLegendProps || {}, {
      enabled: true,
      position: "bottom",
      valueType: "string",
      decimalPrecision: 0
    });

    const baseLegendProps: Highcharts.LegendOptions = {
      symbolPadding: 0,
      symbolHeight: 0,
      symbolWidth: 0,
      squareSymbol: false,
      symbolRadius: 0,
      itemMarginBottom: 6,
      enabled: uLegendProps.enabled,
      useHTML: true,
      itemStyle: FONT_STYLE,
      // The following symbol related settings are required to not show the default highcharts symbols.
      // We want to show the square symbol always which is done on the labelFormatter.
      labelFormatter: function () {
        const ctx = this as any;
        const { name, color, yData } = ctx;
        const value = yData[0];
        const titleText = escapeStr(name);

        const width = ctx.chart.chartWidth * (LEGEND_WIDTH_PER / 100);
        const effWidth = width - 22;
        const nameWidth = effWidth * 0.75;
        const valueWidth = effWidth * 0.25;
        const valueDiv = getStringValue(uLegendProps.valueType, value, uLegendProps.decimalPrecision);

        return `
          <div class='inc-highcharts-legend inc-highcharts-bubble-legend' style="width: ${width}px;">
            <div class='inc-highcharts-square-symbol' style="background-color:${color};"></div>
            <div class="legend-title" title="${titleText}" style="width: ${nameWidth}px;">
              ${name}
            </div>
            <div class="legend-value" title="${value}" style="width: ${valueWidth}px;">
              ${valueDiv}
            </div>
          </div>
        `;
      }
    };

    const bottomLegendConfig: Highcharts.LegendOptions = {
      layout: "horizontal",
      align: "left",
      maxHeight: 90,
      margin: 16
    };

    // Default is to show legends at bottom
    if (!uLegendProps || !uLegendProps.position || uLegendProps.position === "bottom") {
      this.options.legend = {
        ...baseLegendProps,
        ...bottomLegendConfig
      };
    } else if (uLegendProps.position === "right") {
      this.options.legend = {
        ...baseLegendProps,
        align: "right",
        verticalAlign: "top",
        layout: "vertical",
        width: `${LEGEND_WIDTH_PER}%`
      };
    }

    return this;
  }

  setDataLabels(showDataLabels: boolean): BubbleChartOptionsBuilder {
    (this.options.plotOptions.packedbubble.dataLabels as any).enabled = showDataLabels;
    return this;
  }

  build(): Options {
    return this.options;
  }

  private getSubtextDivHtml(point: Point) {
    const pt = point as any;
    const { value, series } = pt;
    const { options } = series;
    const { custom = {} } = options;
    const fValue = custom?.formatValue ? custom.formatValue(value) : value;
    const subTextDiv = (
      <div
        className="inc-flex-column inc-flex-center"
        style={{ top: "-10px" }}
      >
        <div className="inc-text-subtext marginTp4 data-label-subtext">{fValue}</div>
      </div>
    );
    return renderToStaticMarkup(subTextDiv);
  }

  private setMaxBubbleSize() {
    const serLength = (this.options.series || []).length;
    if (serLength > 0) {
      const { width, height } = this.options.chart;
      const widthNum = parseInt(width.toString(), 10);
      const heightNum = parseInt(height.toString(), 10);
      const legendWidth = widthNum * (LEGEND_WIDTH_PER / 100);

      const plotWidth = widthNum - legendWidth;
      const plotArea = plotWidth * heightNum;
      const minBubbleSize: number = (this.options.plotOptions.packedbubble.minSize as number) || MIN_BUBBLE_SIZE;
      let maxBubbleSize = Math.sqrt(plotArea / serLength);
      maxBubbleSize = Math.ceil(maxBubbleSize);
      maxBubbleSize = Math.max(maxBubbleSize, minBubbleSize);
      maxBubbleSize = Math.min(maxBubbleSize, heightNum * 0.75);
      this.options.plotOptions.packedbubble.maxSize = this.options.plotOptions.packedbubble.maxSize || maxBubbleSize;
      this.options.plotOptions.packedbubble.minSize = Math.min(maxBubbleSize, minBubbleSize);
    } else {
      this.options.plotOptions.packedbubble.maxSize = "100%";
    }

    this.throttleOptions();
  }

  private throttleOptions() {
    const serLength = (this.options.series || []).length;
    let { maxSize } = this.options.plotOptions.packedbubble;
    let { minSize } = this.options.plotOptions.packedbubble;
    let maxIterations = 200;
    let maxSpeed = 5;

    if (serLength > 50) {
      maxSize = "150%";
      minSize = "100%";
      maxIterations = 1000;
      maxSpeed = 15;
    }

    this.options.plotOptions.packedbubble.maxSize = maxSize;
    this.options.plotOptions.packedbubble.minSize = minSize;
    this.options.plotOptions.packedbubble.layoutAlgorithm.maxIterations = maxIterations;
    this.options.plotOptions.packedbubble.layoutAlgorithm.maxSpeed = maxSpeed;
  }

  private getTooltipProps(point: Point) {
    const { series } = point;
    const { options: sOptions } = series;
    const { getTooltip } = sOptions?.custom || {};
    const tooltipProps = getTooltip ? getTooltip() : {};
    return tooltipProps;
  }

  private initOptions(defOptions: Partial<Options> = {}) {
    this.options = this.getDefaultOptions();
    merge(this.options, defOptions);
  }

  private getDefaultOptions(): Options {
    return {
      chart: {
        spacingRight: 8,
        spacingLeft: 0
      },
      accessibility: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        series: {
          animation: false,
          events: {
            legendItemClick: HighChartsLegendClick
          }
        },
        packedbubble: {
          minSize: MIN_BUBBLE_SIZE,
          clip: false,
          findNearestPointBy: "x",
          layoutAlgorithm: {
            enableSimulation: false,
            bubblePadding: 12,
            gravitationalConstant: 0.02,
            linkLength: 10
          },
          dataLabels: {
            enabled: true,
            useHTML: true,
            allowOverlap: true,
            style: {
              border: "none",
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: `'Outfit', 'Helvetica Neue', Arial, sans-serif`,
              color: getInceptionTheme().inceptionColors.primary1
            }
          },
          draggable: false,
          stickyTracking: false
        }
      },
      title: {
        text: ""
      }
    };
  }
}

export const getTooltipId = (seriesId: string) => {
  seriesId = seriesId || generateId();
  return `hc-tooltip-${seriesId}`;
};

const getStringValue = (valueType: ValueType, value: any, decimalPrecision = 0): string => {
  if (valueType === "percentage") {
    return renderToStaticMarkup(
      <IncPercentRenderer
        decimalPrecision={decimalPrecision}
        value={value}
      />
    );
  } else if (valueType === "number") {
    return formatNumber(value);
  } else {
    return value;
  }
};
