import { IncFaIcon, IncSelectOption, IncSlimSelect } from "@inception/ui";
import { pick } from "lodash";
import React, { FC, useEffect, useState, useMemo, useCallback } from "react";
import { VerticallyCenteredRow } from "../../../components";
import { generateId, shouldExcludeTag, TimeRange, useRefState } from "../../../core";
import { DemoDataParams } from "../../../services/api/explore";
import {
  MonitoredDataSchema,
  OpCreationConfig,
  operationaliseV2ApiService
} from "../../../services/api/operationalise";
import { ENTITY_TAG } from "../../../utils";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { getResultSchemaFromMonitoredDataSchema } from "./utils";

interface Props {
  selection: Record<string, string>;
  onChange: (opt: Record<string, string>) => void;
  timeRange: TimeRange;
  opCreationConfig: OpCreationConfig;
  entityTypeName: string;

  onPreviewSchemaChange: (schemaResponse: MonitoredDataSchema) => void;
  onLoadingChange: (loading: boolean) => void;
  onErrorChange: (error: string) => void;
  onEntityLookupChange?: (entityLookup: Record<string, string>) => void;

  demoDataParams?: DemoDataParams;
}

export const PreviewSchemaRenderer: FC<Props> = props => {
  const {
    timeRange,
    opCreationConfig,
    onEntityLookupChange,
    entityTypeName,
    onChange,
    onErrorChange,
    onLoadingChange,
    onPreviewSchemaChange,
    selection,
    demoDataParams
  } = props;

  const { name: op10zeName } = opCreationConfig || {};
  const uniqId = useMemo(() => generateId(), []);

  const selectionRef = useRefState(selection);

  const { fromMillis, toMillis } = useMemo(() => timeRangeUtils.getMillisFromTimeRange(timeRange), [timeRange]);

  const [previewSchema, setPreviewSchema] = useState<MonitoredDataSchema>(getDefaultPreviewSchema());
  const [previewSchemaLoading, setPreviewSchemaLoading] = useState(true);
  const [previewSchemaError, setPreviewSchemaError] = useState("");

  useEffect(() => {
    onLoadingChange(previewSchemaLoading);
  }, [onLoadingChange, previewSchemaLoading]);

  useEffect(() => {
    if (previewSchema?.entityLookupData && onEntityLookupChange) {
      onEntityLookupChange(previewSchema.entityLookupData);
    }
  }, [onEntityLookupChange, previewSchema]);

  const onPreviewSchemaChangeRef = useRefState(onPreviewSchemaChange);
  useEffect(() => {
    if (previewSchema) {
      const onPreviewSchemaChange = onPreviewSchemaChangeRef.current;
      onPreviewSchemaChange(previewSchema);
    }
  }, [onPreviewSchemaChangeRef, previewSchema]);

  useEffect(() => {
    onErrorChange(previewSchemaError);
  }, [onErrorChange, previewSchemaError]);

  const fetchPreviewSchema = useCallback(async () => {
    setPreviewSchemaLoading(true);
    setPreviewSchemaError("");
    setPreviewSchema(getDefaultPreviewSchema());

    selectionRef.current = null;

    const { data, error, message } = await operationaliseV2ApiService.getOp10zePreviewSchemaV2(
      opCreationConfig,
      null,
      fromMillis,
      toMillis,
      demoDataParams
    );

    if (!error && data) {
      setPreviewSchema(data);
    } else {
      setPreviewSchemaError(message);
    }

    setPreviewSchemaLoading(false);
  }, [demoDataParams, fromMillis, opCreationConfig, selectionRef, toMillis]);

  useEffect(() => {
    fetchPreviewSchema();
  }, [fetchPreviewSchema]);

  const { options, tagsArr, validCombinations } = useMemo(() => {
    const { entityLookupData } = previewSchema || {};

    let defSelection: Record<string, string>;
    const validCombinations = new Set<string>();
    const options: PreviewSchemaOption[] = [];
    let tagsArr: string[] = [];

    const resultSchema = getResultSchemaFromMonitoredDataSchema(previewSchema);
    if (resultSchema?.length) {
      tagsArr = Object.keys(resultSchema[0]).filter(tag => !shouldExcludeTag(tag, true));
      resultSchema.forEach(schema => {
        defSelection = defSelection || pick(schema, tagsArr);

        const valueArr: string[] = [];

        let arrToEvaluate = options;
        tagsArr.forEach(tag => {
          const value = schema[tag];
          const label = entityLookupData[value] || value;

          valueArr.push([tag, value].join(valueDelimeter));

          let optionsEntry = options.find(opt => opt.value === value);
          if (!optionsEntry) {
            optionsEntry = {
              label,
              value,
              data: []
            };
            arrToEvaluate.push(optionsEntry);
          }

          arrToEvaluate = optionsEntry.data;
        });

        validCombinations.add(valueArr.join(tagDelimeter));
      });
    }

    return {
      tagsArr,
      options,
      defSelection,
      validCombinations
    };
  }, [previewSchema]);

  const schemaExists = previewSchema?.series?.length > 0;
  const isPreviewSchemaError = Boolean(previewSchemaError);

  const onSeriesSelectionChange = useCallback(
    (key: string, value: string) => {
      const selection = selectionRef.current;
      const nSelection = {
        ...selection,
        [key]: value
      };

      const combinationStr = Object.keys(nSelection)
        .map(key => [key, nSelection[key]].join(valueDelimeter))
        .join(tagDelimeter);
      const isValidCombination = validCombinations.has(combinationStr);
      if (isValidCombination) {
        onChange(nSelection);
      } else {
        const tagIdx = tagsArr.findIndex(tag => tag === key);
        const tagsToPick = tagsArr.slice(0, tagIdx + 1);

        const partSelection = pick(nSelection, tagsToPick);
        const combinationStr = Object.keys(partSelection)
          .map(key => [key, nSelection[key]].join(valueDelimeter))
          .join(tagDelimeter);
        const validCombinationsArr = Array.from(validCombinations);
        const validPartCombinations = validCombinationsArr.filter(combination =>
          combination.startsWith(combinationStr)
        );
        const validPartCombination = validPartCombinations[0] || validCombinationsArr[0];

        const uSelection: Record<string, string> = {};
        validPartCombination.split(tagDelimeter).forEach(value => {
          const [tag, val] = value.split(valueDelimeter);
          uSelection[tag] = val;
        });
        onChange(uSelection);
      }
    },
    [onChange, selectionRef, tagsArr, validCombinations]
  );

  const seriesSelectJsx = useMemo(() => {
    if (selection) {
      let arrToEvaluate = options;

      if (!tagsArr.length) {
        return <VerticallyCenteredRow className="inc-label-common">{op10zeName}</VerticallyCenteredRow>;
      }

      return tagsArr.map((tagKey, idx) => {
        const key = [uniqId, tagKey, idx].join("_");
        const label = tagKey === ENTITY_TAG ? entityTypeName : tagKey;
        const value = selection[tagKey];

        const selOpt = arrToEvaluate.find(opt => opt.value === value);
        const options = [...arrToEvaluate];

        arrToEvaluate = selOpt?.data || [];

        const onOptChange = (opt: IncSelectOption) => onSeriesSelectionChange(tagKey, opt.value);

        return (
          <VerticallyCenteredRow
            className="marginRt8"
            key={key}
          >
            <VerticallyCenteredRow className="inc-label-common marginRt8">{label}</VerticallyCenteredRow>
            <IncSlimSelect
              autoAdjustWidth
              isSearchable
              onChange={onOptChange}
              options={options}
              value={selOpt}
            />
          </VerticallyCenteredRow>
        );
      });
    }
    return <></>;
  }, [entityTypeName, onSeriesSelectionChange, op10zeName, options, selection, tagsArr, uniqId]);

  return (
    <>
      <VerticallyCenteredRow className="inc-label-common marginRt8">This is a preview of</VerticallyCenteredRow>
      {previewSchemaLoading && (
        <IncFaIcon
          className="inc-text-subtext-medium"
          iconName="spinner"
          spin
        />
      )}
      {!previewSchemaLoading && (
        <>
          {isPreviewSchemaError && (
            <VerticallyCenteredRow className="status-danger inc-text-subtext-medium">
              <IncFaIcon
                className="marginRt10"
                iconName="exclamation-triangle"
              />
              <span>Error fetching schema</span>
            </VerticallyCenteredRow>
          )}
          {!isPreviewSchemaError && (
            <>
              {!schemaExists && (
                <VerticallyCenteredRow className="status-warning inc-text-subtext">
                  <IncFaIcon
                    className="marginRt10"
                    iconName="exclamation"
                  />
                  <span>No series found</span>
                </VerticallyCenteredRow>
              )}
              {schemaExists && seriesSelectJsx}
            </>
          )}
        </>
      )}
    </>
  );
};

type PreviewSchemaOption = IncSelectOption<IncSelectOption[]>;

const getDefaultPreviewSchema = (): MonitoredDataSchema => ({
  entityLookupData: {},
  series: []
});

const tagDelimeter = "i__tag_delimeter";
const valueDelimeter = "i__value_delimeter";
