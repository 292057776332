import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IncSmartText } from "@inception/ui";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {
  CohortEntityFilter,
  MetricUserServiceFilters,
  WidgetResponseDTO
} from "../../../../../../../services/api/explore";
import { TimeRange } from "../../../../../../../core";
import timeRangeUtils from "../../../../../../../utils/TimeRangeUtils";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../../../../../components";
import { CatalogWidgetProperties } from "../../../../models";
import { Datum } from "../utils";
import { useFetchWidgetTimeline } from "./hooks";
import {
  EventChartType,
  EventSeries,
  formatWidgetConfigBasedOnMetric,
  generateSeriesDataFromResponse,
  generateSliceSpecBasedOnMetric,
  getChartOptions
} from "./eventTimelineUtils";

type Props = {
  metricId: string;
  widgetResponseDto: WidgetResponseDTO;
  entityType: string;
  eventTypeId?: string;
  widgetProperties: CatalogWidgetProperties;
  version?: number;
  bins?: number;
  tableInfo?: Datum;
  timeRange: TimeRange;
  chartType: EventChartType;
  aggTags?: string[];
  chartLabel?: string;
  downsample: string;
  cohortFilters?: CohortEntityFilter[];
  metricUserServiceFilters?: MetricUserServiceFilters;
};

const ChangeEventTimeLine: FC<Props> = props => {
  const {
    metricId,
    widgetResponseDto,
    eventTypeId,
    entityType,
    version,
    widgetProperties,
    tableInfo,
    timeRange,
    aggTags,
    downsample,
    chartType,
    chartLabel,
    cohortFilters,
    metricUserServiceFilters
  } = props;
  const { widgetConfig: pWidgetConfig } = widgetResponseDto;
  const widgetConfig = useMemo(
    () => formatWidgetConfigBasedOnMetric(pWidgetConfig, metricId),
    [metricId, pWidgetConfig]
  );
  const { fromMillis, toMillis } = useMemo(() => timeRangeUtils.getMillisFromTimeRange(timeRange), [timeRange]);

  const ref = useRef<HTMLDivElement>();
  const [numBins, setNumBins] = useState(0);
  useEffect(() => {
    if (ref.current && !numBins) {
      const width = ref.current.getBoundingClientRect()?.width;
      if (width) {
        const numBins = Math.ceil(width / 60);
        setNumBins(numBins);
      } else {
        setNumBins(20);
      }
    }
  }, [numBins]);
  const sliceSpec = useMemo(
    () =>
      generateSliceSpecBasedOnMetric(
        widgetResponseDto,
        metricId,
        fromMillis,
        toMillis,
        downsample,
        tableInfo,
        aggTags,
        numBins,
        chartType
      ),
    [aggTags, chartType, downsample, fromMillis, metricId, numBins, tableInfo, toMillis, widgetResponseDto]
  );
  const { data, error, isError, isFetching, refetch } = useFetchWidgetTimeline(
    entityType,
    widgetConfig,
    fromMillis,
    toMillis,
    downsample,
    sliceSpec,
    widgetResponseDto.widgetId,
    eventTypeId,
    version,
    cohortFilters,
    metricUserServiceFilters,
    numBins,
    chartType
  );
  const { seriesData, truthColor, legends } = useMemo(
    () => generateSeriesDataFromResponse(data?.metricResults, widgetProperties, chartType, chartLabel),
    [chartLabel, chartType, data?.metricResults, widgetProperties]
  );

  useEffect(() => {
    if (widgetConfig && (entityType || eventTypeId) && numBins) {
      refetch();
    }
  }, [refetch, widgetConfig, entityType, numBins, eventTypeId]);

  return (
    <div
      className="widget-event-timeline"
      ref={ref}
    >
      <div className="widget-event-gantchart">
        {isFetching && <LoadingSpinner />}
        {!isFetching && (
          <>
            {isError ? (
              <VerticallyCenteredRow className="error-message">
                Error fetching data for timeline: {error}
              </VerticallyCenteredRow>
            ) : seriesData?.length ? (
              <EventTimeLine
                chartType={chartType}
                legends={legends}
                series={seriesData}
                timeRange={timeRange}
                truthColor={truthColor}
              />
            ) : (
              <VerticallyCenteredRow className="message inc-flex-center">No data found</VerticallyCenteredRow>
            )}
          </>
        )}
      </div>
    </div>
  );
};

type Legend = {
  color: string;
  label: string;
  opacity?: number;
};

type ChartProps = {
  series: EventSeries[];
  timeRange: TimeRange;
  truthColor: string;
  legends: Legend[];
  chartType: EventChartType;
};

const EventTimeLine: FC<ChartProps> = props => {
  const { series, timeRange, truthColor, legends, chartType } = props;
  const ref = useRef<HTMLDivElement>();
  const [chartRendered, setChartRendered] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setChartRendered(true);
    }
  }, [ref]);

  const getChartDimensions = useCallback(() => {
    const canvasElem: HTMLElement = ref.current;
    if (canvasElem) {
      const widgetItemContainer: Element = canvasElem.closest(".widget-event-gantchart");
      const dimensions = widgetItemContainer?.getBoundingClientRect();

      if (dimensions) {
        const { height } = dimensions;
        const { width } = dimensions;
        return {
          width,
          height: height - 44
        };
      }
    }
    return {
      width: -1,
      height: -1
    };
  }, [ref]);
  const { width, height } = getChartDimensions();
  const chartOptions = useMemo(
    () => getChartOptions(timeRange, width, height, series, truthColor, chartType),
    [timeRange, width, height, series, truthColor, chartType]
  );

  const legendsHtml = useMemo(
    () => (
      <VerticallyCenteredRow className="inc-flex inc-flex-row">
        {legends.map(({ color, label, opacity }, key) => (
          <VerticallyCenteredRow
            className="marginTp8"
            key={key}
          >
            <span
              className="marginLt12 legend-box"
              style={{
                backgroundColor: color,
                opacity
              }}
            />
            <span className="inc-text-subtext marginLt6">{label}</span>
          </VerticallyCenteredRow>
        ))}
      </VerticallyCenteredRow>
    ),
    [legends]
  );

  return (
    <div className="widget-event-timeline-wrapper">
      <IncSmartText text="Timeline" />
      <div className="widget-event-timeline-container">
        <div ref={ref}>
          {chartRendered && (
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions}
            />
          )}
        </div>
      </div>
      {legendsHtml}
    </div>
  );
};

export default ChangeEventTimeLine;
