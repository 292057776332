import { IncToolTip, IncFaIcon } from "@inception/ui";
import React, { FC, useEffect, useMemo } from "react";
import { LoadingSpinner, VerticallyCenteredRow } from "../../../../components";
import { getFormattedValueForImpactedWidget } from "../../../../components/triage/impact-widget/utils";
import { useForceUpdate } from "../../../../core";
import { BizEntityDataResultEntry } from "../../../../dashboard/widgets/utils";
import {
  ExploreEntityFilter,
  ForecastSpec,
  ImpactedWidget,
  UserServiceFilterList
} from "../../../../services/api/explore";
import { useFetchImpactedWidgetDataForIncidents } from "../hooks/useFetchImpactedWidgetDataForIncidents";
import { ImpactedWidgetSelection, ResultsTableCellDataState, ResultsTableDataEntry } from "./types";

interface Props {
  incidentIds: string[];
  impactedWidget: ImpactedWidget;
  opConfigId: string;
  groupBy: string[];
  rowDataObj: ResultsTableDataEntry;
  startTimeMillis: number;
  endTimeMillis: number;
  dataKey: keyof ImpactedWidgetSelection;
  forecastSpec?: ForecastSpec;
  generateDemoData?: boolean;
}

export const ImpactedWidgetCellRenderer: FC<Props> = props => {
  const { groupBy, impactedWidget, incidentIds, opConfigId, rowDataObj, dataKey, forecastSpec, generateDemoData } =
    props;

  const forceUpdate = useForceUpdate();

  const impactedWidgetId = impactedWidget.id;

  const { entityFilters, userServiceFilters } = useMemo(
    () => ({
      entityFilters: [] as ExploreEntityFilter[],
      userServiceFilters: {} as Record<string, UserServiceFilterList>
    }),
    []
  );

  const { refetch, resultsByIncidentId } = useFetchImpactedWidgetDataForIncidents(
    impactedWidget,
    incidentIds,
    opConfigId,
    groupBy,
    entityFilters,
    userServiceFilters,
    groupBy,
    null,
    forecastSpec,
    generateDemoData
  );

  useEffect(() => {
    if (dataKey === "data") {
      refetch();
    }
  }, [dataKey, refetch]);

  const defResultsMap = rowDataObj?.dataState?.[impactedWidgetId];

  useEffect(() => {
    if (dataKey !== "data") {
      document.addEventListener("resultsTableDataUpdated", () => forceUpdate());
    }
  }, [dataKey, forceUpdate]);

  const resultsMap = useMemo(() => {
    if (dataKey === "data") {
      const resultsMap: Partial<Record<keyof ImpactedWidgetSelection, ResultsTableCellDataState>> = {};

      let data = 0;
      let forecastData = 0;
      let forecastDelta = 0;
      let forecastPercent = 0;

      const results = Object.values(resultsByIncidentId || {})
        .map(resultRec => Object.values(resultRec || {})[0])
        .filter(Boolean);

      const isFetching = results.some(result => result.isFetching);
      const isError = results.some(result => result.isError);
      const error = results.find(result => result.isError)?.error;

      if (!isFetching && !isError) {
        const bizEntityDataArr = results.map(r => r.data);
        bizEntityDataArr.forEach(curr => {
          const { postAggData, forecastAggData, forecastAggDeltaData, forecastAggPerChangeData } = curr || {};

          const dataValue = getDataValueAsNumber(postAggData);
          const forecastDataValue = getDataValueAsNumber(forecastAggData);
          const forecastDeltaValue = getDataValueAsNumber(forecastAggDeltaData);
          const forecastDeltaPercentageValue = getDataValueAsNumber(forecastAggPerChangeData);

          data += dataValue;
          forecastData += forecastDataValue;
          forecastDelta += forecastDeltaValue;
          forecastPercent += forecastDeltaPercentageValue;
        });
      }

      const commonResult: Omit<ResultsTableCellDataState, "dataValue" | "formattedDataValue"> = {
        error,
        isError,
        isFetching
      };

      resultsMap["data"] = {
        dataValue: data,
        formattedDataValue: getFormattedValueForImpactedWidget(impactedWidget, data),
        ...commonResult
      };

      resultsMap["forecast"] = {
        dataValue: forecastData,
        formattedDataValue: getFormattedValueForImpactedWidget(impactedWidget, forecastData),
        ...commonResult
      };

      resultsMap["forecastDelta"] = {
        dataValue: forecastDelta,
        formattedDataValue: getFormattedValueForImpactedWidget(impactedWidget, forecastDelta),
        ...commonResult
      };

      resultsMap["forecastDeltaPerc"] = {
        dataValue: forecastPercent,
        formattedDataValue: getFormattedValueForImpactedWidget(impactedWidget, forecastPercent),
        ...commonResult
      };

      return resultsMap;
    }

    return defResultsMap;
  }, [dataKey, defResultsMap, impactedWidget, resultsByIncidentId]);

  const { formattedDataValue, isError, error, isFetching = true } = resultsMap?.[dataKey] || {};

  useEffect(() => {
    const rowDataState = rowDataObj.dataState;
    rowDataState[impactedWidgetId] = resultsMap;

    document.dispatchEvent(new CustomEvent("resultsTableDataUpdated"));
  }, [forceUpdate, impactedWidgetId, incidentIds, isFetching, resultsMap, rowDataObj]);

  return (
    <>
      {isFetching && <LoadingSpinner titleText=" " />}
      {!isFetching && (
        <>
          {isError && (
            <IncToolTip
              placement="top"
              titleText={error}
              variant="error"
            >
              <VerticallyCenteredRow className="flex-grow inc-flex-center">
                <IncFaIcon
                  className="status-danger"
                  iconName="warning"
                />
              </VerticallyCenteredRow>
            </IncToolTip>
          )}
          {!isError && (
            <VerticallyCenteredRow className="flex-grow inc-flex-center">{formattedDataValue}</VerticallyCenteredRow>
          )}
        </>
      )}
    </>
  );
};

const getDataValueAsNumber = (dataRec: Record<string, BizEntityDataResultEntry>) => {
  const dataArr = Object.values(dataRec || {})[0]?.data || [];
  const fields = dataArr[0]?.fields || [];
  const valueArr = fields[1]?.data?.filter(Boolean) || [];
  const dataValue = (valueArr[0] as number)?.toFixed(2) ?? "0";

  let numDataValue = parseFloat(dataValue);
  numDataValue = !isNaN(numDataValue) ? numDataValue : null;

  return numDataValue;
};
