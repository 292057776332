import { IncSelectOption } from "@inception/ui";

export const GROUP_BY_NONE_OPT: IncSelectOption = {
  label: "None",
  value: "none"
};

export const GROUP_BY_DATE_OPT: IncSelectOption = {
  label: "Date",
  value: "date"
};

export const resultsTableGroupByOpts = [GROUP_BY_NONE_OPT, GROUP_BY_DATE_OPT];
