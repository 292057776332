import React, { FC } from "react";
import { IncToolTip, IncTooltipProps } from "@inception/ui";

interface Props {
  variant: IncTooltipProps["variant"] | "links";
  infoText: string;
}

export const WidgetInfoCorner: FC<Props> = props => {
  const { infoText, variant } = props;

  const className = `widget-info-corner widget-info-corner--${variant}`;
  const ttVariant = variant === "links" ? "info" : variant;

  return (
    <IncToolTip
      placement="top-start"
      showArrow
      titleText={infoText}
      variant={ttVariant}
    >
      <div className={className}>
        <i className="fa" />
        <span className="widget-info-corner-inner" />
      </div>
    </IncToolTip>
  );
};
