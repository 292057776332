import React, { FC, useCallback, useEffect, useState } from "react";
import { IncButton, IncFaIcon } from "@inception/ui";
import { SeasonalityOverrideDef, operationaliseV2ApiService } from "../../services/api/operationalise";
import { VerticallyCenteredRow } from "../../components";
import { BizImpactConfig } from "../../services/api/types";
import { generateId, logger, useToggleState } from "../../core";
import { BizImpactConfigEditorModal } from "./BizImpactConfigEditorModal";

interface Props {
  seasonalityOverride: SeasonalityOverrideDef;
  onChange: (seasonalityOverride: SeasonalityOverrideDef, error: string) => void;
  readOnly?: boolean;
  editorOnly?: boolean;
}

export const SeasonalityOverrideEditor: FC<Props> = props => {
  const { onChange, seasonalityOverride, readOnly, editorOnly } = props;

  const { bizImpactConfigId, impactConfig, description } = seasonalityOverride;

  const {
    isOpen: isImpactConfigLoading,
    open: startImpactConfigFetch,
    close: finishImpactConfigFetch
  } = useToggleState(!impactConfig);

  const [impactConfigToEdit, setImpactConfigToEdit] = useState(impactConfig);

  const fetchBizImpactConfig = useCallback(async () => {
    startImpactConfigFetch();
    try {
      const { data, error, message } = await operationaliseV2ApiService.getBizImpactConfig(bizImpactConfigId);

      if (error || !data?.bizImpactConfig) {
        logger.error("SeasonalityOverrideEditor", "Error fetching BizImpactConfig", {
          error,
          message,
          data
        });
      } else {
        setImpactConfigToEdit(data.bizImpactConfig);
      }
    } catch (err) {
      logger.error("SeasonalityOverrideEditor", "Error fetching BizImpactConfig", {
        err,
        bizImpactConfigId
      });
    }
    finishImpactConfigFetch();
  }, [bizImpactConfigId, finishImpactConfigFetch, startImpactConfigFetch]);

  useEffect(() => {
    if (bizImpactConfigId) {
      fetchBizImpactConfig();
    }
  }, [bizImpactConfigId, fetchBizImpactConfig]);

  const {
    isOpen: isEditOverrideOpen,
    open: openEditOverridePopper,
    close: closeEditOverridePopper
  } = useToggleState(editorOnly);

  const onImpactConfigChange = useCallback(
    (impactConfig: BizImpactConfig) => {
      setImpactConfigToEdit(impactConfig);
      const nextOverride: SeasonalityOverrideDef = {
        ...seasonalityOverride,
        impactConfig: {
          ...impactConfig,
          id: generateId()
        },
        description: impactConfig.name
      };
      delete nextOverride.bizImpactConfigId;
      const { error } = validateSeasonalityOverride(nextOverride);
      onChange(nextOverride, error);
      closeEditOverridePopper();
    },
    [closeEditOverridePopper, onChange, seasonalityOverride]
  );

  return (
    <VerticallyCenteredRow className="inc-text-subtext-medium flex-gap-10">
      {!editorOnly && (
        <>
          {description}
          {!readOnly && (
            <>
              <IncButton
                color="link"
                disabled={isImpactConfigLoading}
                iconType="icon"
                onClick={openEditOverridePopper}
                size="small"
              >
                {isImpactConfigLoading && (
                  <IncFaIcon
                    iconName="spinner"
                    spin
                  />
                )}
                {!isImpactConfigLoading && <IncFaIcon iconName="edit" />}
              </IncButton>
            </>
          )}
        </>
      )}

      {isEditOverrideOpen && (
        <BizImpactConfigEditorModal
          bizImpactConfig={impactConfigToEdit}
          onCancel={closeEditOverridePopper}
          onChange={onImpactConfigChange}
        />
      )}
    </VerticallyCenteredRow>
  );
};

const validateSeasonalityOverride = (seasonalityOverride: SeasonalityOverrideDef) => {
  const { bizImpactConfigId, description, impactConfig } = seasonalityOverride;

  let isValid = true;
  let error = "";

  if (!description) {
    isValid = false;
    error = "Name is required";
  }

  if (!bizImpactConfigId && !impactConfig) {
    isValid = false;
    error = "Impact Configuration is required";
  }

  return {
    isValid,
    error
  };
};
