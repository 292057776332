import React, { FC, useCallback, useMemo } from "react";
import { CypressConstants } from "@bicycle/tests";
import { StaticThresholdDef, CompareOperator, PeerThreshold } from "../../../../services/api/operationalise";
import { OpContext } from "../../../context/types";
import {
  FieldPickerContextDTO,
  FieldPickerOptionData,
  UserServiceField,
  UserServiceFieldSlice,
  BizFieldWithMeta,
  UserServiceFieldSliceSet,
  SliceSet,
  PickerFieldType
} from "../../../../services/api/explore";
import { FieldPickerContainer, VerticallyCenteredRow } from "../../../../components";
import { FieldPickerUtils } from "../../../../utils";
import { isFieldOperationalise } from "../../../utils";
import { PeerSelector } from "../../../components";

interface Props {
  comparator: CompareOperator;
  thresholdDef: StaticThresholdDef;
  onChange: (thresholdDef: StaticThresholdDef) => void;
  pickerContext: OpContext;
  metricOrFieldName: string;
  metricSliceSet: UserServiceFieldSliceSet | SliceSet;
  isEventOperationalize: boolean;
}

export const PeerGroupThresholdEditorV2: FC<Props> = props => {
  const { onChange, thresholdDef, pickerContext, metricSliceSet } = props;

  const fieldOperationalise = isFieldOperationalise(pickerContext);

  const { peerThreshold } = thresholdDef;

  const { usFieldSlice, bizFieldSlice, peerOfSlice } = peerThreshold;

  const onSlicesChange = useCallback(
    (selFields: FieldPickerOptionData[]) => {
      const bizFields: BizFieldWithMeta[] = [];
      const usFields: UserServiceField[] = [];

      selFields.forEach(({ type, payload }) => {
        if (type === "bizEntityField") {
          bizFields.push(payload as BizFieldWithMeta);
        } else if (type === "userServiceField") {
          usFields.push(payload as UserServiceField);
        }
      });

      const usFieldSlices = usFields.map(
        (usField): UserServiceFieldSlice => ({
          tagName: FieldPickerUtils.getPromSanitizedUSFName(usField),
          userServiceField: usField
        })
      );

      const nPeerThreshold = {
        ...peerThreshold,
        usFieldSlice: {
          slices: usFieldSlices
        },
        bizFieldSlice: {
          bizFields
        }
      };

      if (fieldOperationalise) {
        delete nPeerThreshold.bizFieldSlice;
      } else {
        delete nPeerThreshold.usFieldSlice;
      }

      onChange({
        ...thresholdDef,
        peerThreshold: nPeerThreshold
      });
    },
    [fieldOperationalise, onChange, peerThreshold, thresholdDef]
  );

  const onPeerThresholdChange = useCallback(
    (peerThreshold: PeerThreshold) => {
      onChange({
        ...thresholdDef,
        peerThreshold
      });
    },
    [onChange, thresholdDef]
  );

  const sliceFields = useMemo<FieldPickerOptionData[]>(() => {
    const bizFields = (bizFieldSlice?.bizFields || []).map(
      (bizField): FieldPickerOptionData => ({
        type: "bizEntityField",
        payload: bizField
      })
    );

    const usFields = (usFieldSlice?.slices || []).map(
      (usFieldSlice): FieldPickerOptionData => ({
        type: "userServiceField",
        payload: usFieldSlice.userServiceField
      })
    );

    return fieldOperationalise ? usFields : bizFields;
  }, [bizFieldSlice, fieldOperationalise, usFieldSlice]);

  const { entityTypeId, eventTypeId, bizEntityFieldName } = pickerContext;

  const contextDto = useMemo<FieldPickerContextDTO>(() => {
    const entityType = fieldOperationalise ? entityTypeId : peerOfSlice?.entityTypeName || entityTypeId;

    return {
      entityType,
      userServices: eventTypeId
        ? [
            {
              userServiceEntityId: eventTypeId
            }
          ]
        : [],
      entityId: entityType ? null : eventTypeId,
      entityName: null,
      userServiceToBizEntityFieldName: eventTypeId && bizEntityFieldName ? { [eventTypeId]: bizEntityFieldName } : {}
    };
  }, [bizEntityFieldName, entityTypeId, eventTypeId, fieldOperationalise, peerOfSlice]);

  const fieldTypes = useMemo<PickerFieldType[]>(
    () => (fieldOperationalise ? ["userServiceField"] : ["bizEntityField"]),
    [fieldOperationalise]
  );

  const { attributes } = CypressConstants.components.Operationalize.ThresholdEditor;

  return (
    <div
      className="peer-group-threshold"
      data-cy={attributes.peerThresholdWrapper}
    >
      <VerticallyCenteredRow className="marginLt8 marginRt8 inc-label-common">for</VerticallyCenteredRow>

      <PeerSelector
        disableSliceChangeForField
        metricSliceSet={metricSliceSet}
        onChange={onPeerThresholdChange}
        peerThreshold={peerThreshold}
        pickerContext={pickerContext}
      />

      <VerticallyCenteredRow className="marginLt8 marginRt8 inc-label-common">with same</VerticallyCenteredRow>

      <FieldPickerContainer
        className="peer-slice-field-selector"
        fieldPickerContextDto={contextDto}
        fieldTypes={fieldTypes}
        hideLabel
        isMulti
        label=""
        onChange={onSlicesChange}
        selectedOptions={sliceFields}
      />
    </div>
  );
};
