import { IncCheckbox, IncFaIcon, IncSmartText, IncTextfield } from "@inception/ui";
import { css, cx } from "emotion";
import { cloneDeep, isEqual } from "lodash";
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { FieldPickerModel } from "../field-picker";
import { InsightDescription } from "../services/api";
import {
  BizDataQuery,
  DemoDataParams,
  FieldPickerContextDTO,
  FieldPickerOptionData,
  OverallMappingStatus,
  UserServiceField,
  UserServiceFieldMetricConfigDefinition,
  UserServiceFieldSlice,
  UserServiceFieldWithMeta,
  WidgetConfigUtils
} from "../services/api/explore";
import { CompareOperator, OpCreationConfig, OpInsightType } from "../services/api/operationalise";
import { FieldPickerUtils } from "../utils";
import { FieldPickerContainer, MappingIncompleteOverlay, VerticallyCenteredRow } from ".";

interface Props {
  opCreationConfig: OpCreationConfig;

  isLoading?: boolean;
  isDQCLoading?: boolean;
  error?: string;

  titleActionsJsx?: JSX.Element | JSX.Element[];
  actionsJsx?: JSX.Element | JSX.Element[];

  hideDataNotConnectedBanner?: boolean;

  hideDimensions?: boolean;
  disableDimensionsEdit?: boolean;

  enableSelection?: boolean;
  isSelected?: boolean;
  onToggleSelection?: () => void;
  hideDetails?: boolean;
  checkBoxVariant?: "check" | "add";
}

export const OperationalizeCard: FC<Props> = memo(props => {
  const {
    opCreationConfig,
    isLoading,
    isDQCLoading: pIsDQCLoading,
    titleActionsJsx,
    actionsJsx,
    enableSelection = false,
    onToggleSelection,
    isSelected,
    hideDataNotConnectedBanner = false,
    hideDimensions = false,
    disableDimensionsEdit = false,
    hideDetails = false,
    checkBoxVariant = "check"
  } = props;

  const { name, description, insightType, importance, automation, causes } = useMemo(
    () => getInsight(isLoading ? null : opCreationConfig),
    [isLoading, opCreationConfig]
  );

  const isDQCLoading = isLoading ? true : pIsDQCLoading;

  const insightTypeStr =
    insightType === OpInsightType.REAL_TIME_DETECTION
      ? "Anomaly Detection"
      : insightType === OpInsightType.TREND_DETECTION
        ? "Trend"
        : insightType === OpInsightType.JOURNEY_ANALYSIS
          ? "Journey"
          : "Outlier";

  const mappingStatus = opCreationConfig?.opMappingStatus?.queryMappingStatus;
  const isMappingIncomplete =
    mappingStatus?.isIncomplete || mappingStatus?.overallStatus === OverallMappingStatus.PENDING;
  const showDataNotConnectedBanner = hideDataNotConnectedBanner
    ? false
    : isLoading || isDQCLoading
      ? false
      : isMappingIncomplete && Boolean(false);

  const onClick = enableSelection && !showDataNotConnectedBanner && !isDQCLoading ? onToggleSelection : undefined;

  const selectionCheckbox = enableSelection ? (
    <IncCheckbox
      checked={isSelected}
      data-show-loader={isDQCLoading}
      noImplicitHeight
      onChange={e => {
        e.stopPropagation();
        onToggleSelection?.();
      }}
      onClick={e => e.stopPropagation()}
    />
  ) : null;

  const onSelectButtonClick = useCallback(
    e => {
      e.stopPropagation();
      onToggleSelection?.();
    },
    [onToggleSelection]
  );

  const SelectionButton = useMemo(
    () =>
      enableSelection ? (
        isSelected ? (
          <IncFaIcon
            color="#39ACFF"
            data-show-loader={isDQCLoading}
            iconName="square-minus"
            onClick={onSelectButtonClick}
            size="2x"
          />
        ) : (
          <IncFaIcon
            color="#39ACFF"
            data-show-loader={isDQCLoading}
            iconName="square-plus"
            onClick={onSelectButtonClick}
            size="2x"
            variant="regular"
          />
        )
      ) : null,
    [enableSelection, isSelected, isDQCLoading, onSelectButtonClick]
  );

  const { entityTypeId, eventTypeId } = WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(
    opCreationConfig?.idProps
  );

  const fieldPickerContext = useMemo<FieldPickerContextDTO>(
    () => ({
      entityId: entityTypeId ? null : eventTypeId,
      entityName: entityTypeId,
      entityType: entityTypeId,
      showFields: true,
      showMetrics: false,
      userServices:
        entityTypeId && eventTypeId
          ? [
              {
                userServiceEntityId: eventTypeId
              }
            ]
          : []
    }),
    [entityTypeId, eventTypeId]
  );
  const [fieldPickerModel, setFieldPickerModel] = useState<FieldPickerModel>();

  const getUSFieldForCustomFieldName = useCallback(
    (fieldName: string): UserServiceFieldWithMeta => {
      const referenceField = fieldPickerModel.getAllUserServiceFields()[0];

      if (referenceField) {
        const clField = cloneDeep(referenceField);
        clField.userServiceField = {
          bizEntityFieldName: "",
          dataType: "STRING",
          displayBizEntityFieldName: "",
          fieldName,
          userServices: clField.userServiceField.userServices
        };
        return clField;
      } else {
        return {
          userServiceField: {
            bizEntityFieldName: "",
            dataType: "STRING",
            displayBizEntityFieldName: "",
            fieldName,
            userServices: eventTypeId
              ? [
                  {
                    userServiceEntityId: eventTypeId
                  }
                ]
              : []
          }
        };
      }
    },
    [eventTypeId, fieldPickerModel]
  );

  const bizDataQuery =
    opCreationConfig?.opCreationConfigDef?.bizDataQuery ||
    opCreationConfig?.trendDetectionConfigDef?.bizDataQuery?.bizDataQuery;

  const defSlices = bizDataQueryHandler(bizDataQuery).getSlices();
  const defSliceFields = useMemo(
    () =>
      defSlices?.map(sl => ({
        userServiceField: sl.userServiceField
      })),
    [defSlices]
  );
  const [slices, setSlices] = useState<UserServiceFieldSlice[]>([]);
  useEffect(() => {
    if (defSlices) {
      setSlices(prev => (!isEqual(prev, defSlices) ? defSlices : prev));
    }
  }, [defSlices]);

  const sliceOpts = useMemo<FieldPickerOptionData[]>(
    () =>
      slices.map(slice => ({
        payload: slice.userServiceField,
        type: "userServiceField"
      })),
    [slices]
  );

  const onSlicesChange = useCallback(
    (selOpts: FieldPickerOptionData[], isFieldPickerClose: boolean) => {
      const usFields = selOpts.map(slice => slice.payload) as UserServiceField[];
      const slices = usFields.map(
        usField =>
          ({
            tagName: FieldPickerUtils.getPromSanitizedUSFName(usField),
            userServiceField: usField
          }) as UserServiceFieldSlice
      );

      setSlices(slices);

      if (isFieldPickerClose) {
        bizDataQueryHandler(bizDataQuery).updateSlices(slices);
      }
    },
    [bizDataQuery]
  );

  const className = cx("visible-on-hover insight-preview-card height-100", {
    readonly: showDataNotConnectedBanner
  });

  const nameWrapperCx = cx(
    "width-100",
    {
      "inc-cursor-pointer": enableSelection && !showDataNotConnectedBanner && !isDQCLoading
    },
    checkBoxVariant === "add" ? "flex-gap-8" : "flex-gap-16"
  );

  const demoDataParams = useMemo(
    () => ({ generateDemoData: isMappingIncomplete }) as DemoDataParams,
    [isMappingIncomplete]
  );

  return (
    <div
      className="insight-preview-card-wrapper"
      data-incomplete={showDataNotConnectedBanner}
    >
      <MappingIncompleteOverlay
        asCard
        isIncomplete={showDataNotConnectedBanner}
        message="Connect data and unlock, or edit to use existing data"
        postJsx={titleActionsJsx}
        preJsx={selectionCheckbox}
      >
        <div
          className={className}
          data-selected={isSelected}
        >
          <VerticallyCenteredRow
            className={nameWrapperCx}
            onClick={onClick}
          >
            {!isLoading && !showDataNotConnectedBanner && checkBoxVariant === "check" && selectionCheckbox}
            {!isLoading && !showDataNotConnectedBanner && checkBoxVariant === "add" && SelectionButton}

            <IncSmartText
              className="inc-text-body-medium"
              data-show-loader={isLoading}
              text={name}
            />

            <div
              className="insight-type-pill"
              data-show-loader={isLoading}
            >
              {insightTypeStr}
            </div>

            {!showDataNotConnectedBanner && titleActionsJsx}
          </VerticallyCenteredRow>

          <IncSmartText
            className="inc-text-subtext-medium inc-text-inactive"
            data-show-loader={isLoading}
            text={description}
          />

          {!hideDetails && (
            <div className="inc-flex-column flex-gap-4 width-100">
              <VerticallyCenteredRow
                className={descriptionCx}
                data-show-loader={isLoading || isDQCLoading}
              >
                <IncFaIcon iconName="circle-info" />
                <IncSmartText text={importance} />
              </VerticallyCenteredRow>

              <VerticallyCenteredRow
                className={descriptionCx}
                data-show-loader={isLoading || isDQCLoading}
              >
                <IncFaIcon iconName="exclamation-triangle" />
                <IncSmartText text={causes.join(", ")} />
              </VerticallyCenteredRow>

              <VerticallyCenteredRow
                className={descriptionCx}
                data-show-loader={isLoading || isDQCLoading}
              >
                <IncFaIcon iconName="bell" />
                <IncSmartText text={automation} />
              </VerticallyCenteredRow>
            </div>
          )}

          {!hideDimensions && (
            <>
              {!(isLoading || isDQCLoading) && (
                <FieldPickerContainer
                  asInlineEditor
                  canAddCustomField
                  customUserServiceFields={defSliceFields}
                  demoDataParams={demoDataParams}
                  disabled={disableDimensionsEdit}
                  fieldPickerContextDto={fieldPickerContext}
                  fieldPickerModel={fieldPickerModel}
                  fieldTypes={["userServiceField"]}
                  getUSFieldForCustomFieldName={getUSFieldForCustomFieldName}
                  hideLabel
                  isMulti
                  label=""
                  onChange={onSlicesChange}
                  onDataFetch={setFieldPickerModel}
                  placeholder="Add Dimensions"
                  readOnly={true}
                  selectedOptions={sliceOpts}
                />
              )}
              {(isLoading || isDQCLoading) && (
                <IncTextfield
                  data-show-loader="true"
                  defaultValue="flex-gap-12 inc-text-subtext inc-text-inactive"
                />
              )}
            </>
          )}

          {!showDataNotConnectedBanner && actionsJsx}
        </div>
      </MappingIncompleteOverlay>
    </div>
  );
});

export const getInsight = (opCreationConfig?: OpCreationConfig): InsightDescription => {
  if (opCreationConfig) {
    const bizActions = opCreationConfig?.bizActions || {};
    const values = Object.values(bizActions);
    const actionNames = values.map(element => element?.alertActionConfig?.name).filter(Boolean);

    return {
      name: opCreationConfig.name,
      automation: actionNames.join(", "),
      causes: opCreationConfig.opCreationConfigMetadata?.causeMetaData?.causes || [],
      description: opCreationConfig.description,
      icon: opCreationConfig.opCreationConfigMetadata?.icon,
      importance: opCreationConfig?.opCreationConfigMetadata?.importance || "",
      insightType: opCreationConfig?.opCreationConfigMetadata?.insightType,
      isOpportunity: opCreationConfig?.opCreationConfigMetadata?.isOpportunity,
      metric: "",
      spikeDropCompareOperator: opCreationConfig?.opCreationConfigMetadata?.spikeDropCompareOperator,
      trigger: "",
      involvedEvents: []
    };
  }

  return {
    name: "Monitor Spike in Failed Bookings due to payment error",
    automation: "Actions include slack channels, automated reports",
    causes: ["Payment provider error", "Booking system error"],
    importance: "Important for identifying and address issues, customer satisfaction",
    description:
      "Monitor, track, and triage all failed bookings and slice them by dimensions to get a big picture view of the underlying issue.",
    icon: null,
    insightType: OpInsightType.REAL_TIME_DETECTION,
    isOpportunity: true,
    metric: "Failed Bookings",
    spikeDropCompareOperator: CompareOperator.Above,
    trigger: "Important for identifying and address issues, customer satisfaction",
    involvedEvents: ["Carriers", "Suppliers"]
  };
};

const bizDataQueryHandler = (bizDataQuery: BizDataQuery) => {
  const { buildingBlockConfig, widgetConfig, sliceSpec } = bizDataQuery || {};

  return {
    getSlices: () => {
      if (buildingBlockConfig) {
        return buildingBlockConfig.buildingBlockDef?.sliceDef?.sliceSets?.[0]?.slices;
      } else if (widgetConfig) {
        const metrics = widgetConfig.dataDefinition?.metrics;
        const usfMetric = metrics?.[sliceSpec?.metricId] as UserServiceFieldMetricConfigDefinition;

        return usfMetric?.userServiceFieldMetricConfig?.sliceSets?.[0]?.slices;
      }
    },
    updateSlices: (slices: UserServiceFieldSlice[]) => {
      if (buildingBlockConfig?.buildingBlockDef) {
        buildingBlockConfig.buildingBlockDef.sliceDef = {
          sliceSets: [{ slices }]
        };
      } else if (widgetConfig) {
        const metrics = widgetConfig.dataDefinition?.metrics;
        const usfMetric = metrics?.[sliceSpec?.metricId] as UserServiceFieldMetricConfigDefinition;

        if (usfMetric?.userServiceFieldMetricConfig) {
          usfMetric.userServiceFieldMetricConfig.sliceSets = [{ slices }];
        }
      }

      bizDataQuery.sliceSpec.sliceSet = WidgetConfigUtils.convertUSFieldSliceSetToTagSlice({ slices });
    }
  };
};

const descriptionCx = cx(
  "inc-flex-row inc-flex-center-vertical flex-gap-12 inc-text-subtext-medium inc-text-inactive",
  css`
    height: 16px;
  `
);
