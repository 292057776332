import { merge, escape, parseInt, isNumber } from "lodash";
import {
  formatNumber,
  getFormattedDateTime,
  IncDateTimeFormat,
  HighChartsLegendClick,
  HighChartsNumberFormatter,
  getInceptionTheme
} from "@inception/ui";
import Highcharts, {
  Chart,
  ChartSelectionContextObject,
  Point,
  SeriesLineOptions,
  XAxisOptions,
  YAxisOptions
} from "highcharts";
import addRegionHighlights, { RegionHighlight } from "../charts/plot-bands/RegionHighlights";
import {
  TimeSeriesLegendProperties,
  TimeSeriesTooltipProperties,
  TimeSeriesYAxisProperties
} from "../../dashboard/widgets/TimeSeries/models/model";
import { MAX_NUM_SERIES_DISPLAYED } from "../../dashboard/widgets/TimeSeries/models/TimeSeriesUtils";
import addAnnotations, { IncChartAnnotation } from "../charts/annotations/annotations";
import { syncExtremes } from "../charts/SynchronousTooltip";
import { TimeRange, TimeZone } from "../../core";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { getDateTimeFormatForCharts } from "../charts";
import { IncTimeSeriesOptions } from "./types";

const X_AXIS_ID = "X-Axis";
const MAX_LEGEND_SERIES = 100;

const FONT_STYLE: Highcharts.CSSObject = {
  color: getInceptionTheme().inceptionColors.palette.BL50,
  fontSize: "12px",
  height: 16,
  fontWeight: "500"
};

const AXIS_TITLE_STYLE: Highcharts.CSSObject = {
  color: getInceptionTheme().inceptionColors.palette.BL25,
  fontSize: "12px",
  height: 16,
  fontWeight: "500"
};

// Wrapper over selection callback. Not exposing all the details for now since they're not required
export type TimeseriesSelectionCallback = (
  from: number,
  to: number,
  eventX: number,
  eventY: number,
  chartInstance: Chart,
  event: ChartSelectionContextObject
) => boolean;

export default class ChartOptionsBuilder {
  private chartOptions: Highcharts.Options = {};

  constructor(options?: Highcharts.Options) {
    // Use constructor to set basic options
    this.chartOptions.chart = {
      spacingRight: 10,
      spacingLeft: 2,
      spacingBottom: 0,
      zooming: {
        type: "x",
        resetButton: {
          theme: {
            fill: "transparent",
            stroke: "#39acff",
            padding: 4,
            style: {
              fontSize: "10px",
              color: "#39acff",
              borderRadius: 4
            },
            states: {
              hover: {
                fill: "transparent",
                stroke: "#39acff"
              }
            }
          },
          position: {
            align: "right",
            verticalAlign: "top",
            x: -10,
            y: -10
          }
        }
      }
    };

    this.chartOptions.accessibility = {
      enabled: false
    };

    this.chartOptions.credits = { enabled: false };

    this.chartOptions.boost = {
      enabled: true,
      allowForce: true,
      seriesThreshold: MAX_NUM_SERIES_DISPLAYED + 1, // for some reason +1 is required else all lines charts are of same color. TODO: Figure out why
      usePreallocated: true
    };

    this.chartOptions.plotOptions = {
      series: {
        animation: false,
        states: {
          hover: {
            enabled: true,
            lineWidthPlus: 0
          }
        },
        // Please don't decrease this without talking with @akshay.
        // This is causing some weird behavior of join end points in case this is quite low in case of comparisons usecase
        boostThreshold: 2880,
        marker: {
          enabled: true,
          radius: 0,
          states: {
            hover: {
              enabled: true,
              radius: 4,
              radiusPlus: 0,
              // fillColor: '#FFFFFF',
              lineWidth: 1
            }
          }
        },
        events: {
          legendItemClick: HighChartsLegendClick
        }
      },
      area: {
        showInLegend: true,
        lineWidth: 3,
        linkedTo: ":previous",
        color: "#aacfff",
        fillOpacity: 0.4,
        zIndex: 1,
        marker: {
          enabled: true,
          radius: 0,
          symbol: "circle",
          states: {
            hover: {
              enabled: true,
              radius: 4,
              radiusPlus: 0,
              lineWidth: 1
            }
          }
        },
        states: {
          hover: {
            enabled: true
          }
        }
      },
      arearange: {
        showInLegend: true,
        lineWidth: 0,
        linkedTo: ":previous",
        color: "#aacfff",
        fillOpacity: 0.3,
        zIndex: 1,
        marker: {
          enabled: true,
          radius: 2,
          symbol: "circle"
        },
        states: {
          hover: {
            enabled: true
          }
        }
      },
      spline: {
        lineWidth: 1
      },
      line: {
        lineWidth: 1
      },
      column: {
        borderWidth: 0
      },
      areaspline: {
        fillOpacity: 0.1,
        lineWidth: 1
      }
    };

    if (options) {
      this.chartOptions = merge(this.chartOptions, options);
    }
  }

  setChartFields(type: string, width: number, height: number): ChartOptionsBuilder {
    this.chartOptions.chart = merge(
      {
        type,
        height,
        width,
        zoomType: "x",
        backgroundColor: "transparent",
        spacingBottom: 0,
        numberFormatter: HighChartsNumberFormatter
      },
      this.chartOptions.chart || {}
    );
    return this;
  }

  setPlotOptions(disableInteractions: boolean): ChartOptionsBuilder {
    if (disableInteractions) {
      this.chartOptions.plotOptions.series.enableMouseTracking = false;
      if (!this.chartOptions.plotOptions.series.events) {
        this.chartOptions.plotOptions.series.events = {};
      }

      this.chartOptions.plotOptions.series.events.legendItemClick = () => false;
    }

    return this;
  }

  setTitle(title: string): ChartOptionsBuilder {
    this.chartOptions.title = {};
    this.chartOptions.title.text = title;
    return this;
  }

  setXAxis(
    title?: string,
    timeRange?: TimeRange,
    intervalSecs?: number,
    axisOverrides?: XAxisOptions | XAxisOptions[]
  ): ChartOptionsBuilder {
    const {
      includeSeconds,
      labelFormat: xAxisLabelFormat,
      labels: xAxisLabels
    } = getXAxisLabelsAndFormat(timeRange, this.chartOptions.chart.width as number, intervalSecs);

    const xAxisOptions: XAxisOptions = merge(
      {
        // startOnTick: true,
        // ordinal: false,
        // endOnTick: true,
        type: "datetime",
        tickmarkPlacement: "on",
        grid: {
          enabled: false
        },
        title: {
          text: title,
          style: {
            color: getInceptionTheme().plugins.graph.legendTextHover,
            marginTop: 16
          }
        },
        tickPositioner: function (this) {
          // Cloning this to ensure the labels array is not mutated after the zoom behaviour
          return [...xAxisLabels];
        },
        labels: {
          align: "center",
          style: FONT_STYLE,
          x: 0,
          formatter: that => {
            const value = parseInt(that.value.toString(), 10) * 1000;
            return getFormattedDateTime(value, xAxisLabelFormat, {
              withSeconds: includeSeconds
            });
          }
        },
        visible: true,
        crosshair: true,
        minorTickLength: 0,
        tickLength: 0,
        lineColor: `#C3D1DF`,
        id: X_AXIS_ID,
        events: {
          setExtremes: (this.chartOptions as any).syncTooltip ? syncExtremes : null
        }
      } as XAxisOptions,
      this.chartOptions.xAxis || {}
    );

    if (timeRange?.from && timeRange.to) {
      xAxisOptions.min = timeRangeUtils.getSecondsFromMillis(timeRange.from.valueOf());
      xAxisOptions.max = timeRangeUtils.getSecondsFromMillis(timeRange.to.valueOf());
    }

    this.chartOptions.xAxis = merge(xAxisOptions, axisOverrides) as XAxisOptions[];
    return this;
  }

  setYAxis(
    maxValue: number,
    yAxisProps: TimeSeriesYAxisProperties,
    axisOverrides?: YAxisOptions | YAxisOptions[],
    allowDecimals = true
  ): ChartOptionsBuilder {
    const defaultYAxis: YAxisOptions[] = [
      {
        id: "yAxis1",
        endOnTick: true,
        gridLineWidth: 1,
        gridLineColor: getInceptionTheme().inceptionColors.palette.BL400,
        visible: true,
        //tickAmount: 4,   // hide the axis to prevent double axis
        tickColor: getInceptionTheme().plugins.graph.legendText,
        title: {
          text: "",
          style: AXIS_TITLE_STYLE
        },
        maxRange: maxValue,
        opposite: false,
        allowDecimals,
        labels: {
          style: FONT_STYLE,
          formatter: function (this) {
            if (isNumber(this.value)) {
              return formatNumber(this.value, false, true);
            }
            return this.value;
          }
        }
      },
      {
        id: "yAxis2",
        endOnTick: true,
        gridLineWidth: 1,
        gridLineColor: getInceptionTheme().inceptionColors.palette.BL400,
        visible: true,
        //tickAmount: 4,   // hide the axis to prevent double axis
        tickColor: getInceptionTheme().plugins.graph.legendText,
        title: {
          text: "",
          style: AXIS_TITLE_STYLE
        },
        opposite: true,
        labels: {
          style: FONT_STYLE,
          formatter: function (this) {
            if (isNumber(this.value)) {
              return formatNumber(this.value, false, true);
            }
            return this.value;
          }
        }
      }
    ];

    this.chartOptions.yAxis = merge(defaultYAxis, axisOverrides) as YAxisOptions[];

    // Set Y Axis 1 overrides if applicable
    if (!yAxisProps || !yAxisProps.axisProps || yAxisProps.axisProps.length === 0) {
      return this;
    }

    for (let i = 0; i < yAxisProps.axisProps.length; ++i) {
      const axisProps = yAxisProps.axisProps[i];
      const hcYAxis = this.chartOptions.yAxis[i];

      if (!axisProps) {
        continue;
      }

      const { label, labelFormatter, max, min, unit, unitBeforeValue, type } = axisProps;

      if (unit) {
        // if we want to show the unit before the value
        if (unitBeforeValue) {
          hcYAxis.labels.format = `${unit} {value}`;
        } else {
          hcYAxis.labels.format = `{value} ${unit}`;
        }
      }

      if (label) {
        hcYAxis.title.text = label;
      }

      if (type) {
        hcYAxis.type = type;
      }

      const numMin = parseFloat(min);
      if (!isNaN(numMin)) {
        hcYAxis.min = numMin;
      }

      const numMax = parseFloat(max);
      if (!isNaN(numMax)) {
        hcYAxis.max = numMax;
      }

      //Below changes are made for mock visualization on operationalise screen
      if (yAxisProps?.plotLines?.length) {
        hcYAxis.plotLines = yAxisProps.plotLines;
      } else {
        hcYAxis.plotLines = null;
      }

      if (labelFormatter) {
        hcYAxis.labels.formatter = function (this) {
          return labelFormatter(this.value);
        };
      }
    }

    return this;
  }

  clearSeriesData(): ChartOptionsBuilder {
    this.chartOptions.series = [];
    return this;
  }

  getXAxisLabelsAndFormat(timeRange: TimeRange, minIntervalMs = 0) {
    return getXAxisLabelsAndFormat(timeRange, this.chartOptions.chart.width as number, minIntervalMs);
  }

  // setComparisonSeriesData(seriesData: Map<string, Array<[(number), (number | null)]>> | undefined,
  //   type: TimeSeriesChartType, shiftedBy: number): ChartOptionsBuilder {

  //   if (!seriesData) {
  //     return this;
  //   }

  //   let i = 0;
  //   seriesData.forEach((value: Array<[(number), (number | null)]>, key: string) => {
  //     // Need to calculate time array which is shifted so that it aligns
  //     const shiftedValue: Array<[(number), (number | null)]> = [];
  //     for (let i = 0; i < value.length; ++i) {
  //       shiftedValue.push([value[i][0] + shiftedBy, value[i][1]]);
  //     }

  //     if (key) {
  //               this.chartOptions.series?.push({
  //                 id: key,
  //                 data: shiftedValue,
  //                 color: getSeriesColor(i, true),
  //                 name: key,
  //                 type: "spline",
  //                 yAxis: 0,
  //                 visible: true
  //               });

  //               ++i;
  //     }
  //   });

  //   return this;
  // }

  setSeriesData(series: IncTimeSeriesOptions[]): ChartOptionsBuilder {
    if (this.chartOptions) {
      this.chartOptions.series = [...(series || [])];
    }
    //console.log("series", this.chartOptions.series);

    if (this.chartOptions.series.length <= 30) {
      this.chartOptions.series.forEach(s => {
        if (s.type === "line" || s.type === "spline") {
          (s as SeriesLineOptions).lineWidth = 2;
        }
      });
    }

    return this;
  }

  setToolTip(
    shiftedTime: number,
    tooltipProperties: TimeSeriesTooltipProperties,
    getExtTooltipInfo?: (tsMillis: number) => [string, boolean],
    timeZone?: TimeZone
  ): ChartOptionsBuilder {
    const { formatter, valueFomatter, shared, sortBy } = tooltipProperties || {};

    this.chartOptions.tooltip = {
      enabled: true,
      shared: false,
      //followPointer: true,
      outside: true,
      useHTML: true,
      formatter:
        formatter ??
        function () {
          let points: Point[] = [];
          if (this.points) {
            points = this.points.map(p => p.point);
          } else if (this.point) {
            // When shared tooltip = false, we only get 1 point.
            points.push(this.point);
          } else {
            return "";
          }

          let timestampMs: number = (this.x as number) * 1000;

          const sortedPoints = points;

          // If we are having tooltip in shared mode, obey the sort order
          if (shared && sortBy) {
            // If we have specified some sort order, sort by ascending order.
            // If sort by descending, just reverse the ascendingly sorted array.
            if (tooltipProperties.sortBy !== "none") {
              sortedPoints.sort(ascendingComparator);
              if (tooltipProperties.sortBy === "desc") {
                sortedPoints.reverse();
              }
            }
          }

          let tooltipStr = "";
          for (let i = 0; i < sortedPoints.length; ++i) {
            const point = sortedPoints[i];
            const { series } = sortedPoints[i];

            const { name, type, options } = series;
            if (name.indexOf("Comparison: ") !== -1) {
              timestampMs = (this.x as number) - shiftedTime;
            }

            let seriesName = name;

            if (seriesName.length > MAX_LEGEND_SERIES) {
              seriesName = `${seriesName.substr(0, MAX_LEGEND_SERIES)}...`;
            }

            const formatValue = valueFomatter ? (value: number) => valueFomatter(value, options?.custom) : formatNumber;
            const { y, high, low } = point.options;

            if (type === "arearange") {
              const max = !isNaN(high) ? formatValue(+(high || 0)) : "-NA-";
              const min = !isNaN(low) ? formatValue(+(low || 0)) : "-NA-";
              tooltipStr += `<div class='inc-charts-tooltip-series-row'>
              <div class="inc-highcharts-square-symbol" style="background-color:${point.color};"></div>
              <div class='inc-charts-tooltip-series-name'>${seriesName} (lower):</div> 
              <div class='inc-charts-tooltip-series-value'><span>${min}</span></div>
            </div>`;
              tooltipStr += `<div class='inc-charts-tooltip-series-row'>
              <div class="inc-highcharts-square-symbol" style="background-color:${point.color};"></div>
              <div class='inc-charts-tooltip-series-name'>${seriesName} (upper):</div> 
              <div class='inc-charts-tooltip-series-value'><span>${max}</span></div>
            </div>`;
            } else {
              const value = !isNaN(y) ? formatValue(+(y || 0)) : "-";
              tooltipStr += `<div class='inc-charts-tooltip-series-row'>
              <div class="inc-highcharts-square-symbol" style="background-color:${point.color};"></div>
              <div class='inc-charts-tooltip-series-name'>${seriesName}</div> 
              <div class='inc-charts-tooltip-series-value'><span>${value}</span></div>
            </div>`;
            }
          }

          if (getExtTooltipInfo) {
            const [externalTooltip, overrideCurrent] = getExtTooltipInfo(timestampMs);
            if (overrideCurrent) {
              tooltipStr = "";
            }

            tooltipStr += externalTooltip;
          }

          const timeStampStr = getFormattedDateTime(
            timestampMs,
            IncDateTimeFormat.full,
            { withSeconds: true },
            timeZone
          );
          return `<div class='inc-charts-tooltip'>
          <div class='inc-charts-tooltip-datetime'>
            ${timeStampStr}
          </div>
          ${tooltipStr}
          </div>
        `;
        }
    };

    if (tooltipProperties) {
      this.chartOptions.tooltip.shared = tooltipProperties.shared;
    }

    return this;
  }

  setLegend(legendProps: TimeSeriesLegendProperties): ChartOptionsBuilder {
    const baseLegendProps: Highcharts.LegendOptions = {
      symbolPadding: 0,
      symbolHeight: 0,
      symbolWidth: 0,
      squareSymbol: false,
      symbolRadius: 0,
      itemMarginBottom: 6,
      enabled: legendProps?.show !== false,
      useHTML: true,
      itemStyle: FONT_STYLE,
      // The following symbol related settings are required to not show the default highcharts symbols.
      // We want to show the square symbol always which is done on the labelFormatter.
      labelFormatter: function () {
        const ctx = this as any;
        return `
          <div class='inc-highcharts-legend'>
            <div class='inc-highcharts-square-symbol' style="background-color:${ctx.color}"></div>
            <div class="legend-title" title="${escape(ctx.name)}">${ctx.name}</div>
          </div>
        `;
      }
    };

    const bottomLegendConfig: Highcharts.LegendOptions = {
      layout: "horizontal",
      align: "left",
      maxHeight: 90,
      margin: 16
    };

    // Default is to show legends at bottom
    if (!legendProps || !legendProps.position || legendProps.position === "bottom") {
      this.chartOptions.legend = {
        ...baseLegendProps,
        ...bottomLegendConfig
      };
    } else if (legendProps.position === "right") {
      this.chartOptions.legend = {
        ...baseLegendProps,
        align: "right",
        verticalAlign: "top",
        layout: "vertical",
        width: "25%"
      };
    }

    return this;
  }

  setPlotBands(regionHighlights: RegionHighlight[]): ChartOptionsBuilder {
    addRegionHighlights(this.chartOptions, regionHighlights);
    return this;
  }

  setAnnotations(annotations: IncChartAnnotation[], maxValue: number) {
    addAnnotations(this.chartOptions, annotations, maxValue);
    return this;
  }

  setSelectionActions(onSelect: TimeseriesSelectionCallback, onResetSelectedTimeRange: () => void) {
    this.chartOptions.chart.events = this.chartOptions.chart.events || {};
    this.chartOptions.chart.events.selection = function (this, event) {
      const { resetSelection } = event as any;

      if (!resetSelection) {
        const { min, max } = event.xAxis[0];

        const from = Math.floor(min) * 1000;
        const to = Math.floor(max) * 1000;

        const { originalEvent } = event as any;
        const eventX = originalEvent?.offsetX;
        const eventY = originalEvent?.offsetY;

        return onSelect(from, to, eventX, eventY, this, event);
      } else if (onResetSelectedTimeRange) {
        onResetSelectedTimeRange();
      }
    };

    return this;
  }

  build(): Highcharts.Options {
    return {
      ...this.chartOptions
    };
  }
}

export const ascendingComparator = (a: Point, b: Point): number => {
  const aValue = a.y ? a.y : 0;
  const bValue = b.y ? b.y : 0;
  if (aValue >= bValue) {
    return 1;
  } else if (aValue < bValue) {
    return -1;
  }

  return 0;
};

const round_interval = (interval: number) => {
  const oneSec = 1000;
  const oneMin = 60 * oneSec;
  const oneHr = 60 * oneMin;
  const oneDay = 24 * oneHr;
  const oneWk = 7 * oneDay;
  switch (true) {
    case interval < 17.5 * oneSec:
      return 15 * oneSec;
    case interval < 45 * oneSec:
      return 30 * oneSec;
    case interval < 1.5 * oneMin:
      return 1 * oneMin;
    case interval < 3.5 * oneMin:
      return 2 * oneMin;
    case interval < 7.5 * oneMin:
      return 5 * oneMin;
    case interval < 12.5 * oneMin:
      return 10 * oneMin;
    case interval < 17.5 * oneMin:
      return 15 * oneMin;
    case interval < 25 * oneMin:
      return 20 * oneMin;
    case interval < 45 * oneMin:
      return 30 * oneMin;
    case interval < 1.5 * oneHr:
      return 1 * oneHr;
    case interval < 2.5 * oneHr:
      return 2 * oneHr;
    case interval < 4.5 * oneHr:
      return 3 * oneHr;
    case interval < 9 * oneHr:
      return 6 * oneHr;
    case interval < 1 * oneDay:
      return 12 * oneHr;
    case interval < 1 * oneWk:
      return 1 * oneDay;
    case interval < 3 * oneWk:
      return 1 * oneWk;
    case interval < 6 * oneWk:
      return 30 * oneDay;
    default:
      return 365 * oneDay;
  }
};

export const getXAxisLabelsAndFormat = (timeRange: TimeRange, chartWidth: number, minIntervalMs = 0) => {
  const { format, withSeconds, ticks, range, min, max } = getDateTimeFormatForCharts(timeRange, chartWidth);

  const calcInterval = Math.max(Math.ceil(range / ticks), minIntervalMs);
  const interval = round_interval(calcInterval);

  const fMin = min - (min % interval);

  let xAxisLabels: number[] = [];

  for (let ts = fMin; ts <= max; ts += interval) {
    const labelTs = Math.floor(ts / 1000);
    xAxisLabels.push(labelTs);
  }

  const idx = Math.floor(xAxisLabels.length / ticks) || 1;
  xAxisLabels = xAxisLabels.filter((_, i) => i % idx === 0);

  return {
    includeSeconds: withSeconds,
    labelFormat: format,
    labels: xAxisLabels
  };
};
