import { useMemo, useCallback } from "react";
import { DataType, InitialState, FetchFn, FetchCallBackResult, useDataFetch } from "../../core";
import {
  BizFieldInfo,
  BizServiceMetric,
  ConditionWithPickerType,
  fieldPickerApiService,
  FieldPickerOptionData,
  FieldValues,
  FieldValuesInput,
  UserServiceField,
  UserServiceFilterExpression,
  UserServiceMetric
} from "../../services/api/explore";
import { FieldPickerUtils } from "../../utils";

interface AutocompleteContext {
  context: FieldValuesInput;
  wrapValueInQuotes: boolean;
}
type AutocompleteResponse = {
  fieldValues: FieldValues;
  wrapValueInQuotes: boolean;
};

export const usePickerAutocompleter = (
  pickerContext: FieldPickerOptionData,
  tag: string,
  fromMills: number,
  toMillis: number,
  searchText?: string,
  limit?: number
) => {
  const initialState = useMemo<InitialState<AutocompleteResponse, string>>(
    () => ({
      data: {
        fieldValues: {
          entityLookupData: {},
          values: []
        },
        wrapValueInQuotes: false
      },
      error: "",
      isError: false,
      isFetching: false,
      isSuccess: false
    }),
    []
  );

  const fetchFn = useCallback<FetchFn<AutocompleteResponse, string>>(async () => {
    const result: FetchCallBackResult<AutocompleteResponse, string> = {
      data: {
        fieldValues: {
          entityLookupData: {},
          values: []
        },
        wrapValueInQuotes: false
      },
      error: "",
      isError: false,
      isSuccess: false
    };

    const { data, error, message } = await getFieldValueCompletion(
      pickerContext,
      tag,
      fromMills,
      toMillis,
      searchText,
      limit
    );
    result.data = data;
    result.error = error ? message : "";
    result.isSuccess = !error;
    result.isError = error;

    try {
      if (pickerContext && tag) {
        const { context, wrapValueInQuotes } = getContext(pickerContext, tag);

        const response = await fieldPickerApiService.getFieldValueCompletion(
          context,
          fromMills,
          toMillis,
          searchText,
          limit
        );
        result.data = {
          fieldValues: response,
          wrapValueInQuotes
        };
        result.isSuccess = true;
      }
    } catch (e) {
      result.isError = true;
      result.error = e.message.toString();
    }

    return result;
  }, [fromMills, limit, pickerContext, searchText, tag, toMillis]);

  return useDataFetch(fetchFn, initialState, true);
};

export const getFieldValueCompletion = async (
  pickerContext: FieldPickerOptionData,
  tag: string,
  fromMills: number,
  toMillis: number,
  searchText?: string,
  limit?: number,
  dependentFilters?: ConditionWithPickerType[],
  useFilterFieldValuesUrl?: boolean
) => {
  const result = {
    error: false,
    message: "",
    data: {
      fieldValues: {
        entityLookupData: {},
        values: []
      },
      wrapValueInQuotes: false
    } as AutocompleteResponse
  };

  try {
    if (pickerContext) {
      const { context, wrapValueInQuotes } = getContext(pickerContext, tag, dependentFilters);

      const response = await fieldPickerApiService.getFieldValueCompletion(
        context,
        fromMills,
        toMillis,
        searchText,
        limit,
        useFilterFieldValuesUrl
      );
      result.data = {
        fieldValues: response,
        wrapValueInQuotes
      };
    }
  } catch (e) {
    result.error = true;
    result.message = e.message.toString();
  }

  return result;
};

const toUserServiceFilterExpression = (condition: ConditionWithPickerType): UserServiceFilterExpression => {
  // Ensuring that the payload is of type UserServiceField
  if (condition.field.type !== "userServiceField") {
    throw new Error("Invalid condition field type. Expected userServiceField");
  }

  return {
    field: condition.field.payload as UserServiceField,
    operator: condition.operator,
    value: condition.value,
    values: condition.values
  };
};

const getContext = (
  pickerContext: FieldPickerOptionData,
  tag: string,
  dependentFilters?: ConditionWithPickerType[]
): AutocompleteContext => {
  if (pickerContext) {
    const { payload, type } = pickerContext;
    let fieldValuesInput: FieldValuesInput = null;
    let wrapValueInQuotes = true;
    let dataType: DataType | "NA" = null;
    switch (type) {
      case "bizEntityField": {
        const p = payload as BizFieldInfo;
        fieldValuesInput = { bizField: p.bizField };
        dataType = p.bizField.entityField.propType;
        break;
      }
      case "bizEntityMetric": {
        const p = payload as BizServiceMetric;
        const slice = FieldPickerUtils.findSliceWithTagName(tag, p.metricMetadata.sliceSets);
        dataType = slice.fieldType;
        fieldValuesInput = {
          bizMetric: p,
          slice: slice
        };
        break;
      }
      case "userServiceField": {
        const p = payload as UserServiceField;
        dataType = p.dataType;
        const userServiceFilterExpressions = dependentFilters
          ? dependentFilters.map(toUserServiceFilterExpression)
          : [];
        fieldValuesInput = {
          userServiceField: p,
          filters: userServiceFilterExpressions
        };
        break;
      }
      case "userServiceMetric": {
        const p = payload as UserServiceMetric;
        const slice = FieldPickerUtils.findSliceWithTagName(tag, p.metricMetadata.sliceSets);
        dataType = slice.fieldType;
        fieldValuesInput = {
          userServiceMetric: p,
          slice: slice
        };
        break;
      }
      default: {
        fieldValuesInput = null;
        break;
      }
    }

    // Wrap in quotes is false if datatype is Booleans, null, numbers
    if ((["LONG", "DOUBLE", "BOOLEAN"] as DataType[]).includes(dataType as DataType)) {
      wrapValueInQuotes = false;
    }

    return {
      context: fieldValuesInput,
      wrapValueInQuotes
    };
  }
};
