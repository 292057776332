import React, { FC, useMemo } from "react";
import { IncButton, generateId } from "@inception/ui";
import { OpCreationFragmentType, OpCreationFragmentV2 } from "../../services/api/operationalise";
import { OpCreationFragmentEditProps } from "../types";
import { LowLevelFragment } from "../../services/api/chat";
import { MarkdownRenderer, VerticallyCenteredRow } from "../../components";
import { useToggleState } from "../../core";
import { OpRecipePreview } from "../wrappers";
import { OpFragmentRenderer } from "./OpFragmentRenderer";

interface Props {
  opCreationFragment: OpCreationFragmentV2;
  fragmentPath: string;
  children?: JSX.Element;
  order: number;

  // Required in edit mode
  editProps?: OpCreationFragmentEditProps;
}

export const OpCreationFragmentV2RendererWrapper: FC<Props> = props => {
  const { editProps, opCreationFragment, fragmentPath, order, children } = props;

  const { name, lowLevelFragments, type, summary, description } = opCreationFragment || {};

  const uniqId = useMemo(() => `opFragment-${type}-${generateId()}`, [type]);
  const { isOpen: isEditMode, toggle: toggleEditView } = useToggleState();

  const { fragmentsJsx, supportsEdit } = useMemo(() => {
    let fragments: LowLevelFragment[] = [];
    const fragmentPaths: string[] = [];
    if (type === OpCreationFragmentType.INSIGHT_GENERATION) {
      (lowLevelFragments || []).forEach((fragment, parentIdx) => {
        const nestedFragments = fragment?.lowLevelFragmentList?.lowLevelFragment || [];
        fragments.push(...nestedFragments);
        nestedFragments.forEach((fragment, frIdx) =>
          fragmentPaths.push(
            `${fragmentPath}.lowLevelFragments[${parentIdx}].lowLevelFragmentList.lowLevelFragment[${frIdx}]`
          )
        );
      });
    } else {
      fragments = lowLevelFragments || [];
      fragments.forEach((fragment, index) => fragmentPaths.push(`${fragmentPath}.lowLevelFragments[${index}]`));
    }

    const fragmentsJsx = fragments.map((fragment, index) => (
      <OpFragmentRenderer
        editProps={editProps}
        fragmentPath={`${fragmentPath}.lowLevelFragments[${index}]`}
        id={`${uniqId}-llf-${fragment.type}-${index}`}
        key={`${uniqId}-llf-${fragment.type}-${index}`}
        level={0}
        lowLevelFragment={fragment}
      />
    ));

    if (!fragments.length) {
      fragmentsJsx.push(
        <VerticallyCenteredRow
          className="inc-text-inactive"
          key="no-fragments"
        >
          None
        </VerticallyCenteredRow>
      );
    }

    const supportsEdit = fragments.length === 0 ? true : fragments.some(fragment => !fragment.opFragment?.readOnly);
    return {
      fragmentsJsx,
      supportsEdit
    };
  }, [editProps, fragmentPath, lowLevelFragments, type, uniqId]);

  return (
    <div className="op-creation-fragment-v2">
      <div className="op-creation-fragment-v2--header">
        <VerticallyCenteredRow className="op-creation-fragment-v2--order">{order}</VerticallyCenteredRow>
        <div className="inc-flex-column flex-gap-4 width-100">
          <VerticallyCenteredRow className="inc-text-header-medium width-100">
            {name}

            {Boolean(editProps) && supportsEdit && (
              <IncButton
                className="marginLtAuto"
                color="link"
                onClick={toggleEditView}
              >
                {isEditMode ? "Back" : "Edit"}
              </IncButton>
            )}
          </VerticallyCenteredRow>

          {!isEditMode && (
            <MarkdownRenderer
              className="inc-text-inactive inc-text-subtext-medium"
              mdContent={summary || "None"}
            />
          )}

          {Boolean(description) && isEditMode && (
            <VerticallyCenteredRow className="inc-text-inactive inc-text-subtext-medium">
              {description}
            </VerticallyCenteredRow>
          )}
        </div>
      </div>

      {(isEditMode || (Boolean(editProps) && type === OpCreationFragmentType.INSIGHT_GENERATION)) && (
        <div className="op-creation-fragment-v2--fragments">
          {isEditMode && fragmentsJsx}
          {!isEditMode && Boolean(editProps) && type === OpCreationFragmentType.INSIGHT_GENERATION && (
            <OpRecipePreview
              demoDataParams={editProps.demoDataParams}
              opCreationConfig={editProps.opCreationConfig}
            />
          )}
        </div>
      )}

      {children}
    </div>
  );
};
