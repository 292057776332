import { default as React, useMemo } from "react";
import { IncidentCardSource, IncidentCardV2 } from "../../../components";
import { Op10zeWidgetProps } from "./types";

const BizOpQueryWidget: React.FC<Op10zeWidgetProps> = props => {
  const { widget, noDataElement } = props;

  const { bizOpQuery, generateDemoData } = widget;

  const { bizDataOperationalize, incidentId } = bizOpQuery;

  const { opConfigId } = bizDataOperationalize || {};

  const incidentCardSource = useMemo<IncidentCardSource>(
    () => ({
      type: "incidentId",
      incidentId,
      opConfigId
    }),
    [incidentId, opConfigId]
  );

  return (
    <>
      {!incidentId && noDataElement}
      {Boolean(incidentId) && (
        <IncidentCardV2
          generateDemoData={generateDemoData}
          noCard
          source={incidentCardSource}
        />
      )}
    </>
  );
};

export default BizOpQueryWidget;
