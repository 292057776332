import Highcharts, { Chart } from "highcharts";
import HCMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import Boost from "highcharts/modules/boost";
import Annotations from "highcharts/modules/annotations";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { max } from "lodash";
import { RegionHighlight } from "../charts/plot-bands/RegionHighlights";
import { TimeSeriesProperties } from "../../dashboard/widgets/TimeSeries/models/model";
import { IncChartAnnotation } from "../charts/annotations/annotations";
import { CommonWidgetOptions } from "../../dashboard/models/BaseWidgetModel";
import addSynchronousTooltip from "../charts/SynchronousTooltip";
import { TimeRange, generateId } from "../../core";
import { FEATURE_FLAGS, featureFlagService } from "../../services/feature-flags";
import { TimeSeriesChartType, IncTimeSeriesOptions, ChartSelectionAction } from "./types";
import ChartOptionsBuilder, { TimeseriesSelectionCallback } from "./ChartOptionsBuilder";
import TimeseriesChartSelectionMenu, { defaultSelectionData, TimeseriesSelectionData } from "./ChartSelectionMenu";

HCMore(Highcharts);
Boost(Highcharts);
Annotations(Highcharts);

Highcharts.AST.bypassHTMLFiltering = true;

Highcharts.setOptions({
  time: {
    useUTC: false
  }
});

export interface TimeSeriesProps {
  title: string;
  type: TimeSeriesChartType;
  id?: string;
  containerElemClass: string;

  properties?: TimeSeriesProperties;
  // Map of series name to data where date is of the form [[timestamp1, value1], [timestamp2, value2]]
  series?: IncTimeSeriesOptions[];

  maxValue?: number;
  usedInNonDashboardWidget?: boolean; // Is this used in standalone screens vs in dashboards with widgets

  seriesColors?: string[];
  regionHighlights?: RegionHighlight[];
  annotations?: IncChartAnnotation[];

  // In case of series which have large number of series use this
  disableInteractions?: boolean;
  options?: Options;

  chartSelectionActions?: ChartSelectionAction[];
  timeRange?: TimeRange;
  onSelectTimeRange?: (start: number, end: number) => boolean | void;
  onResetSelectedTimeRange?: () => void;

  // Function to provide and external tooltip. Return [tooltip as string, boolean to override default content]
  getExtTooltipInfo?: (timestampMillis: number) => [string, boolean];
  manualZoomProps?: {
    izZoomed: boolean;
    showResetZoomControl: boolean;
  };
}

const TimeSeries: React.FC<TimeSeriesProps> = (props: TimeSeriesProps) => {
  const [, setChartRendered] = useState(false);
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>(null);
  const [chartInstance, setChartInstance] = useState<Chart>(null);

  const chartContainerRef = useRef<HTMLDivElement>();

  const [selectionData, setSelectionData] = useState<TimeseriesSelectionData>({
    ...defaultSelectionData
  });

  const {
    type,
    id,
    containerElemClass,
    maxValue: pMaxValue,
    regionHighlights,
    series,
    disableInteractions,
    properties,
    options: hcOptions,
    annotations: pAnnotations,
    chartSelectionActions,
    timeRange,
    onSelectTimeRange,
    onResetSelectedTimeRange,
    getExtTooltipInfo,
    manualZoomProps
  } = props;

  const [maxValue, setMaxValue] = useState(pMaxValue);

  const annotations = useMemo(() => {
    const annts = pAnnotations || [];
    annts.forEach(annotation => (annotation.id = annotation.id || generateId()));
    return annts;
  }, [pAnnotations]);

  const shouldShowSelectionMenu = hcOptions?.hideActionsOnDragAndSelect !== true;
  const hasSyncTooltip = hcOptions?.syncTooltip === true;

  const getChartDimensions = useCallback(() => {
    const canvasElem: HTMLElement = chartContainerRef.current;
    if (canvasElem) {
      const widgetItemContainer: Element = canvasElem.closest(`.${containerElemClass}`);
      const dimensions = widgetItemContainer?.getBoundingClientRect();
      if (dimensions) {
        const { height } = dimensions;
        const { width } = dimensions;
        return {
          width,
          height
        };
      }
    }
    return {
      width: -1,
      height: -1
    };
  }, [chartContainerRef, containerElemClass]);

  useEffect(() => {
    if (chartContainerRef.current) {
      setChartRendered(true);
    }
  }, [chartContainerRef]);

  // Calculate dimensions on every render
  const { width, height } = getChartDimensions();

  const onSelect: TimeseriesSelectionCallback = useCallback(
    (from, to, x, y, chart, event) => {
      if (shouldShowSelectionMenu) {
        setSelectionData({
          isActive: true,
          chart,
          from,
          to,
          event,
          x,
          y
        });
        return false;
      }

      if (onSelectTimeRange) {
        const result = onSelectTimeRange(from, to);
        return result || false;
      }
    },
    [onSelectTimeRange, shouldShowSelectionMenu]
  );
  const showTimeZone = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.enableTimezone);
  useEffect(() => {
    if (height <= 0 || !width) {
      return;
    }

    // If we have chart type set in properties use that. We are using the one passed in this simple till we
    // remove the deprecated usage of chartType in widget model
    const derivedType: TimeSeriesChartType = properties?.chartType ? properties.chartType : type;

    const optionsBuilder: ChartOptionsBuilder = new ChartOptionsBuilder(hcOptions)
      .setChartFields(derivedType, width, height)
      .setPlotOptions(disableInteractions)
      .setTitle(null)
      .setXAxis(null, timeRange)
      .setYAxis(maxValue, properties?.yAxis, hcOptions?.yAxis)
      .clearSeriesData()
      .setSeriesData(series)
      .setToolTip(
        0,
        properties?.tooltip,
        getExtTooltipInfo,
        showTimeZone ? timeRange.timeZone || timeRange.raw.timeZone : null
      )
      .setLegend(properties?.legend)
      .setPlotBands(regionHighlights)
      .setSelectionActions(onSelect, onResetSelectedTimeRange)
      .setAnnotations(annotations, maxValue);

    const options = optionsBuilder.build();

    setChartOptions(options);
  }, [
    type,
    maxValue,
    series,
    width,
    height,
    regionHighlights,
    properties,
    hcOptions,
    disableInteractions,
    onSelect,
    annotations,
    timeRange,
    onResetSelectedTimeRange,
    getExtTooltipInfo,
    showTimeZone
  ]);

  useEffect(() => {
    if (!chartInstance) {
      return;
    }
    if (hasSyncTooltip) {
      addSynchronousTooltip(chartInstance);
    }
  }, [chartInstance, hasSyncTooltip]);

  useEffect(() => {
    if (chartInstance && manualZoomProps?.izZoomed && manualZoomProps?.showResetZoomControl) {
      chartInstance?.showResetZoom();
    }
  }, [chartInstance, manualZoomProps]);

  const highchartsCallback = useCallback(
    (chart: Chart) => {
      const shouldUpdateMaxValue = isNaN(Number(maxValue)) && Boolean(annotations?.length);

      /**
       * Update yMax when annotations are set.
       * It is needed for the annotations to appear on top.
       */
      if (shouldUpdateMaxValue) {
        const maxValues = chart.yAxis.map(axis => axis.getExtremes().max);
        const yMax = max(maxValues);

        setMaxValue(yMax);
      }

      setChartInstance(chart);
    },
    [annotations, maxValue]
  );

  const chartId = useMemo(() => id || generateId(), [id]);

  return (
    <div
      className="chart-container"
      id={chartId}
      ref={chartContainerRef}
    >
      <TimeseriesChartSelectionMenu
        chartSelectionActions={chartSelectionActions}
        onSelectionDataChange={setSelectionData}
        selectionData={selectionData}
      />
      {chartOptions && (
        <HighchartsReact
          callback={highchartsCallback}
          highcharts={Highcharts}
          options={chartOptions}
        />
      )}
    </div>
  );
};

export default TimeSeries;

type Options = CommonWidgetOptions & Highcharts.Options;
