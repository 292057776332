import React, { useCallback, useMemo, useRef } from "react";
import { BaseWidgetImpl } from "../..";
import VariableImpl from "../../model-impl/VariableImpl";
import { VariableSrv } from "../../variables";
import { ResultCohort } from "../../../services/api/explore";
import { CohortPicker } from "../../../components";
import { EntityFieldPicker } from "./EntityFieldsPicker";

interface Props {
  entityTypeId: string;
  variableSrv: VariableSrv;
  widget: BaseWidgetImpl;
  onVariablesUpdate: (variables: VariableImpl[], widgetId: string) => void;
  onUpdateCohortId: (cohortId: string, widgetId: string, isLocked?: boolean) => void;
  variableLoadingStateMap: Record<string, boolean>;
}

export const EntityFilters: React.FC<Props> = (props: Props) => {
  const {
    variableSrv,
    entityTypeId,
    onVariablesUpdate,
    widget: { id: widgetId },
    variableLoadingStateMap,
    onUpdateCohortId
  } = props;

  const cohortIdRef = useRef("");

  const cohortId = useMemo(() => {
    if (!variableLoadingStateMap[widgetId]) {
      const id = variableSrv.getCohortId();
      if (cohortIdRef.current !== id) {
        cohortIdRef.current = id;
      }
      return id;
    }
    return "";
  }, [variableLoadingStateMap, widgetId, variableSrv]);

  const onCohortChange = useCallback(
    (resultCohort: ResultCohort, isPinned?: boolean) => {
      const id = resultCohort?.id || "";
      if (cohortIdRef.current !== id) {
        cohortIdRef.current = id;
      }
      onUpdateCohortId(id, widgetId, isPinned);
    },
    [onUpdateCohortId, widgetId]
  );

  return (
    <div className="entiy-filters">
      <CohortPicker
        cohortState={"saved"}
        containerClass={"cohort-name-container"}
        entityTypeId={entityTypeId}
        isCohortPinned={variableSrv.isCohortPinned}
        onCohortChange={onCohortChange}
        value={cohortId}
      />
      <EntityFieldPicker
        cohortId={cohortIdRef.current}
        entityTypeId={entityTypeId}
        onVariablesUpdate={onVariablesUpdate}
        pillLimit={1}
        variableSrv={variableSrv}
        widgetId={widgetId}
      />
    </div>
  );
};
