import React, { FC, useMemo, useCallback, useState, useRef } from "react";
import { size } from "lodash";
import { IncFaIcon, IncClickAwayPopper, IncTextfield, generateId } from "@inception/ui";
import { VerticallyCenteredRow } from "../../../../../../components";
import { WidgetQuerySchemaResponse } from "../../../../../../services/api/explore";

interface Props {
  changeMetricHeaders: Record<string, string>;
  onChange: (changeMetricHeaders: Record<string, string>) => void;
  querySchemaResponse: WidgetQuerySchemaResponse;
}

export const ChangeMetricsRenamer: FC<Props> = props => {
  const { changeMetricHeaders, onChange, querySchemaResponse } = props;

  const metricNames = useMemo(() => {
    const metricNames: Record<string, string> = {};
    const qsArr = querySchemaResponse?.querySchema || [];
    qsArr.forEach(({ metricId, metricName }) => {
      metricNames[metricId] = metricName;
    });
    return metricNames;
  }, [querySchemaResponse]);

  const uid = useMemo(() => generateId(), []);
  const ref = useRef<HTMLDivElement>(null);

  const [headers, setHeaders] = useState(changeMetricHeaders);

  const [show, setShow] = useState(false);
  const openPopper = useCallback(() => setShow(true), []);
  const closePopper = useCallback(() => {
    onChange(headers);
    setShow(false);
  }, [headers, onChange]);

  const onMetricHeaderChange = useCallback((nHeader: string, id: string) => {
    setHeaders(prev => ({
      ...prev,
      [id]: nHeader
    }));
  }, []);

  const editors = useMemo(
    () =>
      Object.keys(headers || {}).map((metricId, idx) => {
        const key = `${uid}-${idx}`;

        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          onMetricHeaderChange(value, metricId);
        };

        const metricName = metricNames[metricId] || metricId;
        const label = `Header for ${metricName}`;
        const header = headers[metricId] || "";

        return (
          <IncTextfield
            containerClassName="marginBt8"
            key={key}
            label={label}
            onChange={onChange}
            value={header}
          />
        );
      }),
    [headers, metricNames, onMetricHeaderChange, uid]
  );

  const headersExist = Boolean(size(changeMetricHeaders));

  return (
    headersExist && (
      <>
        <VerticallyCenteredRow ref={ref}>
          <IncFaIcon
            iconName="gear"
            onClick={openPopper}
          />
        </VerticallyCenteredRow>

        <IncClickAwayPopper
          anchorEl={ref.current}
          onClickAway={closePopper}
          show={show}
        >
          <div
            className="inc-card-layout inc-flex-column"
            style={{ width: 350 }}
          >
            <div className="inc-text-subtext-medium marginBt10">Edit metric headers</div>

            {editors}
          </div>
        </IncClickAwayPopper>
      </>
    )
  );
};
