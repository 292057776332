import { clone, isEqual, cloneDeep, forEach, union, isString, isArray } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  useNotifications,
  useTimeRange,
  TimeRange,
  useInceptionRoute,
  useQueryParams,
  useRefState
} from "../../../core";
import DashboardImpl from "../../model-impl/DashboardImpl";
import VariableImpl from "../../model-impl/VariableImpl";
import { VariableSrv } from "../../variables";
import { BaseWidgetImpl } from "../..";
import CohortVariableImpl from "../../model-impl/CohortVariableImpl";
import { GLOBAL_VARIABLE_SRV_KEY } from "../../variables/constants";
import { FilterWidgetImpl } from "../../widgets/FilterWidget/models";
import { VariableType, VAR_URL_PREFIX, EVENT_VAR_URL_PREFIX, EventVariableModel } from "../../models/VariableModel";
import EventVariableImpl from "../../model-impl/EventVariableImpl";
import { QueryParamDTO, resolveFiltersFromQueryParams, QueryFilterExpression } from "../../../utils/QueryParamUtils";
import { FieldPickerUtils, NameEntityFieldLabel } from "../../../utils";
import { EntityOpToTraceQueryOpMap } from "../../../services/api/explore";
import { getEntityVariableModel } from "../../variables/CohortVariableUtils";
import { getVariableImpl } from "../../model-impl/utils";
import { ALL_USERSERVICES_ENTITY_TYPE_ID } from "../../../utils/ExploreUtils";
import { useFetchEventTypeFieldsMapV2 } from "./useFetchEventTypeFieldsMap";

interface Props {
  dbImpl: DashboardImpl;
  timeRange: TimeRange;
  onDashboardUpdate: () => void;
  refreshDashboard: () => void;
  updateDashboardVariables: (variables: VariableImpl[]) => void;
  updateDashboardTime: (timeRange: TimeRange, compareTimeRange: TimeRange) => void;

  shouldWaitForInit?: boolean;
  ignoreUrlChanges?: boolean;
}

export const useDashboardVariables = (props: Props) => {
  const widgetVariableSrvMap = useRef<Record<string, VariableSrv>>({});
  const [widgetVariableSrvLoadingState, setWidgetVariableSrvLoadingState] = useState<Record<string, boolean>>({});
  const filtersInitialized = useRef(false);
  /**
   * We use this ref to store the variable of the last updated variable name.
   * This allows us to update the options of only the variables that depend on this variable.
   * This saves us from updating non-dependent variables.
   * If this is undefined, we update all variables.
   */
  const updatedVarNameRef = useRef<string>();

  const {
    dbImpl,
    updateDashboardVariables,
    updateDashboardTime,
    refreshDashboard,
    timeRange: presetTimeRange,
    shouldWaitForInit,
    ignoreUrlChanges,
    onDashboardUpdate
  } = props;

  const { variables, entityTypeId, entityTypeName, cohortId, cohortName, widgets } = dbImpl;

  const { eventTypeToFieldsMap, isEventTypeFieldsMapInitialized } = useFetchEventTypeFieldsMapV2({
    timeRange: dbImpl?.timeRange,
    shouldWaitForInit
  });

  /**
   * We use this ref to store the prev stat change triggers.
   * We use this to evaluate whether the effect has been triggered due to compare time range
   * change or for other valid reasons. We skip update options if only compare time range is different.
   */
  const prevTriggerRef = useRef({
    timeRange: null,
    variables: []
  });

  const queryParams = useQueryParams();
  const queryParamsRef = useRefState(queryParams);

  const location = useLocation();
  const { navigate } = useInceptionRoute();
  const { notifyError } = useNotifications();
  const { timeRange: tr, compareTimeRange: cTr } = useTimeRange();

  const fTr = presetTimeRange || tr;

  const updateVariableOptions = useCallback(
    async (timeRange: TimeRange) => {
      try {
        // Pass the variable name here to refresh only the variables that depend on this variable.
        setWidgetVariableSrvLoadingState(prev => ({
          ...prev,
          [GLOBAL_VARIABLE_SRV_KEY]: true
        }));

        const variableSrvMap = clone(widgetVariableSrvMap.current);
        const globalVarSrv = variableSrvMap[GLOBAL_VARIABLE_SRV_KEY];
        await globalVarSrv.updateVariableOptions(timeRange);
        widgetVariableSrvMap.current = variableSrvMap;

        setWidgetVariableSrvLoadingState(prev => ({
          ...prev,
          [GLOBAL_VARIABLE_SRV_KEY]: false
        }));

        // Reset the name ref, since the update kicked in.
        updatedVarNameRef.current = undefined;
      } catch (err) {
        notifyError((err as Error).message);
      }
    },
    [notifyError]
  );

  const shouldUpdateOptions = useCallback((nTimeRange: TimeRange, nVariables: VariableImpl[]) => {
    const { timeRange: pTimeRange, variables: pVariables } = prevTriggerRef.current;

    const timeRangeChanged = !isEqual(pTimeRange, nTimeRange);
    const variablesChanged = !isEqual(pVariables, nVariables);

    return timeRangeChanged || variablesChanged;
  }, []);

  const updateEntityFiltersFromQueryParams = useCallback(
    (entityFilterExpressions: QueryFilterExpression[]) => {
      entityFilterExpressions.forEach((filter: QueryFilterExpression) => {
        let varUpdated = false;
        const { key, operator, value } = filter;
        const fieldKeys = key.split(".");
        const entityTypeId = fieldKeys[0];
        const name = fieldKeys.splice(1, fieldKeys.length).join(".");

        widgets.forEach(widget => {
          if (!varUpdated) {
            const { variables } = widget;
            const filterWidget = widget as FilterWidgetImpl;
            const { entityTypeId: fEntityTypeId } = filterWidget;
            if (fEntityTypeId === entityTypeId) {
              variables.forEach(variable => {
                const variableEntityTypeAndNameMatches =
                  variable.type === VariableType.Cohort &&
                  (variable as CohortVariableImpl).entityTypeId === entityTypeId &&
                  variable.name === name;

                if (variableEntityTypeAndNameMatches) {
                  const cohortVar = variable as CohortVariableImpl;
                  cohortVar.value = value;
                  cohortVar.operator = operator;
                  varUpdated = true;
                }
              });
              if (!varUpdated) {
                if (name === "cohortId" && widget.type === "filter") {
                  (widget as FilterWidgetImpl).cohortId = value as string;
                } else {
                  const varModel = getEntityVariableModel(entityTypeId, "", name, null, operator, value);
                  const cohortVarImpl = new CohortVariableImpl(varModel);
                  cohortVarImpl.value = value;
                  cohortVarImpl.operator = operator;
                  cohortVarImpl.isPinned = false;
                  cohortVarImpl.isLocked = true;
                  varUpdated = true;
                  variables.push(cohortVarImpl);
                }
              }
            }
          }
        });
      });
    },
    [widgets]
  );

  const updateEventFiltersFromQueryParams = useCallback(
    (eventFilterExpressions: QueryFilterExpression[]) => {
      eventFilterExpressions.forEach((filter: QueryFilterExpression) => {
        let varUpdated = false;
        const { key, operator: eOperator, value } = filter;

        const fieldKeys = key.split(".");
        const userServiceId = fieldKeys[0];
        const fieldLabel = fieldKeys.splice(1, fieldKeys.length).join(".");
        const operator = EntityOpToTraceQueryOpMap[eOperator];

        widgets.forEach(widget => {
          const isFilterWidget = widget.type === "filter";
          const filterWidget = widget as FilterWidgetImpl;
          const isUserServiceIdMatches = isFilterWidget && filterWidget?.userServiceId === userServiceId && !varUpdated;
          if (isUserServiceIdMatches) {
            const filterWidget = widget as FilterWidgetImpl;

            const { variables } = widget;
            variables.forEach(variable => {
              if (variable.type === VariableType.Event) {
                const eventVar = variable as EventVariableImpl;
                const vFieldLabel = FieldPickerUtils.getUserServiceFieldLabel(eventVar.userServiceField);
                if (vFieldLabel === fieldLabel) {
                  eventVar.operator = operator;
                  eventVar.value = value;
                  varUpdated = true;
                }
              }
            });
            if (!varUpdated) {
              const eventTypeFields = eventTypeToFieldsMap[userServiceId];
              eventTypeFields?.forEach(usf => {
                const cFieldLabel = FieldPickerUtils.getUserServiceFieldLabel(usf.userServiceField);
                if (cFieldLabel === fieldLabel) {
                  const eVarModel: Partial<EventVariableModel> = {
                    userServiceId,
                    userServiceField: usf.userServiceField,
                    operator: operator,
                    value: value,
                    name: cFieldLabel,
                    type: VariableType.Event
                  };
                  const eventVarImpl = new EventVariableImpl(eVarModel);
                  const variableImplList = filterWidget.variables.map(x => new EventVariableImpl(x));
                  variableImplList.push(eventVarImpl);
                  filterWidget.variables = variableImplList;
                  varUpdated = true;
                }
              });
            }
          }
        });
      });
    },
    [eventTypeToFieldsMap, widgets]
  );

  const updateVariablesFromQueryParams = useCallback(() => {
    const entityFilterExpressions = resolveFiltersFromQueryParams(queryParams, VAR_URL_PREFIX);
    const eventFilterExpressions = resolveFiltersFromQueryParams(queryParams, EVENT_VAR_URL_PREFIX);

    updateEntityFiltersFromQueryParams(entityFilterExpressions);
    updateEventFiltersFromQueryParams(eventFilterExpressions);

    filtersInitialized.current = true;
  }, [queryParams, updateEntityFiltersFromQueryParams, updateEventFiltersFromQueryParams]);

  const updateFilterQueryParams = useCallback(() => {
    let masterQueryParamList: QueryParamDTO[] = [];
    const queryStringParams: Record<string, string | string[]> = {};
    const queryParamsObj = Object.assign(queryParamsRef.current);

    // Cleanup existing variable values
    const keys = Object.keys(queryParamsObj);
    const varKeys = keys.filter(key => key.startsWith(VAR_URL_PREFIX) || key.startsWith(EVENT_VAR_URL_PREFIX));
    varKeys.forEach(k => delete queryParamsObj[k]);

    const currVariableSrvMap = widgetVariableSrvMap.current;
    forEach(currVariableSrvMap, (varSrv, widgetId) => {
      const { isFilterWidget } = varSrv;
      const isGlobalVarSrv = widgetId === GLOBAL_VARIABLE_SRV_KEY;

      if (!isGlobalVarSrv && isFilterWidget) {
        const queryParamList = varSrv.getFilterQueryParams();

        masterQueryParamList = union(masterQueryParamList, queryParamList);
      }
    });

    masterQueryParamList.forEach(x => {
      const value = x.queryValue;
      const key = x.queryKey;
      queryStringParams[key] = value;
    });

    const finalQP = {
      ...queryParamsObj,
      ...queryStringParams
    };

    const url = location.pathname;
    navigate(url, {
      queryParams: finalQP,
      replace: true
    });
  }, [location.pathname, navigate, queryParamsRef]);

  const updateOrInitVariableSrv = useCallback(async () => {
    const variableSrvMap = clone(widgetVariableSrvMap.current);
    const nVarSrv = new VariableSrv(fTr, variables, entityTypeId, "", entityTypeName, cohortId, cohortName, [], true);
    variableSrvMap[GLOBAL_VARIABLE_SRV_KEY] = nVarSrv;

    const widgetVarLoadingStates: Record<string, boolean> = {};
    widgetVarLoadingStates[GLOBAL_VARIABLE_SRV_KEY] = true;

    widgets.forEach(x => {
      widgetVarLoadingStates[x.id] = true;
    });

    setWidgetVariableSrvLoadingState(widgetVarLoadingStates);

    const deletedWidgets: string[] = [];
    Object.keys(variableSrvMap).forEach(id => {
      // ignore global srv key
      if (id !== GLOBAL_VARIABLE_SRV_KEY && !widgets.find(w => w.id === id)) {
        deletedWidgets.push(id);
      }
    });

    deletedWidgets.forEach(id => {
      delete variableSrvMap[id];
    });

    const isTimeRangeChanged = !isEqual(prevTriggerRef.current.timeRange, fTr);

    const promises = widgets.map(async (widget: BaseWidgetImpl) => {
      const { id, variables } = widget;
      const shouldAddVarSrv = !variableSrvMap[id];

      if (shouldAddVarSrv || isTimeRangeChanged) {
        let entityTypeId = "";
        let userServiceId = "";
        let cohortId = "";
        let isCohortPinned = false;
        let isCohortLocked = true;
        let cohortVisible = false;
        const isFilterWidget = widget.type === "filter";
        if (isFilterWidget) {
          const filterWidget = widget as FilterWidgetImpl;
          entityTypeId = filterWidget.entityTypeId;
          userServiceId = filterWidget.userServiceId;
          cohortId = filterWidget.cohortId;
          isCohortPinned = filterWidget.isCohortPinned;
          isCohortLocked = filterWidget.isCohortLocked;
          cohortVisible = filterWidget.cohortVisible;
        }
        const allUsFields = eventTypeToFieldsMap[ALL_USERSERVICES_ENTITY_TYPE_ID] || [];
        const widgetVars = variables.map(v => getVariableImpl(v, allUsFields));
        const newVarSrv = new VariableSrv(
          fTr,
          widgetVars,
          entityTypeId,
          userServiceId,
          "",
          cohortId,
          "",
          (widget as FilterWidgetImpl).appliedWidgets,
          isFilterWidget,
          isCohortPinned,
          isCohortLocked,
          cohortVisible
        );
        await newVarSrv.resolveBizFields(fTr);
        variableSrvMap[id] = newVarSrv;
      }
      return;
    });

    await Promise.allSettled(promises);

    const nWidgetVarLoadingStates: Record<string, boolean> = {};
    nWidgetVarLoadingStates[GLOBAL_VARIABLE_SRV_KEY] = false;

    widgets.forEach(x => {
      nWidgetVarLoadingStates[x.id] = false;
    });

    widgetVariableSrvMap.current = variableSrvMap;
    setWidgetVariableSrvLoadingState(nWidgetVarLoadingStates);

    const shouldUpdate = shouldUpdateOptions(fTr, variables);
    if (shouldUpdate) {
      // This refresh will trigger update variableSrv in widgets and thus loading state is shown
      refreshDashboard();
      await updateVariableOptions(fTr);
      prevTriggerRef.current = {
        timeRange: fTr,
        variables: cloneDeep(variables)
      };
    }

    updateDashboardTime(fTr, cTr);
  }, [
    cTr,
    cohortId,
    cohortName,
    entityTypeId,
    entityTypeName,
    fTr,
    refreshDashboard,
    shouldUpdateOptions,
    updateDashboardTime,
    updateVariableOptions,
    variables,
    widgets,
    eventTypeToFieldsMap
  ]);

  useEffect(() => {
    if (isEventTypeFieldsMapInitialized && !filtersInitialized.current && !ignoreUrlChanges) {
      updateVariablesFromQueryParams();
    }
  }, [ignoreUrlChanges, isEventTypeFieldsMapInitialized, updateVariablesFromQueryParams]);

  useEffect(() => {
    if (isEventTypeFieldsMapInitialized) {
      updateOrInitVariableSrv();
    }
  }, [isEventTypeFieldsMapInitialized, updateOrInitVariableSrv]);

  const onUpdateCohortId = useCallback(
    (cohortId: string, widgetId: string, isCohortLocked?: boolean, isCohortVisible?: boolean) => {
      if (widgetId) {
        setWidgetVariableSrvLoadingState(prev => ({
          ...prev,
          [widgetId]: true
        }));

        const nWidgetVariableSrvMap = clone(widgetVariableSrvMap.current);
        const selectedVarSrv = nWidgetVariableSrvMap[widgetId];
        if (selectedVarSrv) {
          const widget = widgets.find(x => x.id === widgetId);
          (widget as FilterWidgetImpl).cohortId = cohortId;
          if (isCohortVisible !== undefined) {
            (widget as FilterWidgetImpl).cohortVisible = isCohortVisible;
          }
          if (isCohortLocked !== undefined) {
            (widget as FilterWidgetImpl).isCohortLocked = isCohortLocked;
          }
          selectedVarSrv.setCohortId(cohortId, isCohortLocked, isCohortVisible);
        }

        widgetVariableSrvMap.current = nWidgetVariableSrvMap;
        updateFilterQueryParams();
        setWidgetVariableSrvLoadingState(prev => ({
          ...prev,
          [widgetId]: false
        }));
      }
      return;
    },
    [updateFilterQueryParams, widgets]
  );

  const onVariablesUpdated = useCallback(
    (newVariables: VariableImpl[], widgetId: string) => {
      // gets triggered when updating global dashboard variables
      if (!widgetId) {
        updateDashboardVariables(newVariables);
      } else {
        setWidgetVariableSrvLoadingState(prev => ({
          ...prev,
          [widgetId]: true
        }));

        const nWidgetVariableSrvMap = clone(widgetVariableSrvMap.current);
        const cohortId = nWidgetVariableSrvMap[widgetId]?.getCohortId() || "";
        delete nWidgetVariableSrvMap[widgetId];

        const widget = widgets.find(x => x.id === widgetId);
        let userServiceId = "";
        let entityTypeId = "";
        let isCohortPinned = false;
        let isCohortLocked = false;
        let cohortVisible = false;
        const isFilterWidget = widget.type === "filter";
        if (widget.type === "filter" && !(widget as FilterWidgetImpl).genericEventFilter) {
          const filterWidget = widget as FilterWidgetImpl;
          userServiceId = filterWidget.userServiceId;
          entityTypeId = filterWidget.entityTypeId;
          isCohortPinned = filterWidget.isCohortPinned;
          isCohortLocked = filterWidget.isCohortLocked;
          cohortVisible = filterWidget.cohortVisible;
        }
        const variableSrv = new VariableSrv(
          tr,
          newVariables,
          entityTypeId,
          userServiceId,
          "",
          cohortId,
          "",
          [],
          isFilterWidget,
          isCohortPinned,
          isCohortLocked,
          cohortVisible
        );
        nWidgetVariableSrvMap[widgetId] = variableSrv;

        widget.variables = newVariables;

        widgetVariableSrvMap.current = nWidgetVariableSrvMap;
        updateFilterQueryParams();
        setWidgetVariableSrvLoadingState(prev => ({
          ...prev,
          [widgetId]: false
        }));
      }
      onDashboardUpdate();
    },
    [onDashboardUpdate, tr, updateDashboardVariables, updateFilterQueryParams, widgets]
  );

  const onAddAdhocEntityFilter = useCallback(
    async (key: string, value: string, fieldEntityType: string) => {
      const widget = widgets.find(x => x.type === "filter" && (x as FilterWidgetImpl).entityTypeId === fieldEntityType);
      const widgetId = widget?.id || "";
      if (!widgetId) {
        return;
      }
      setWidgetVariableSrvLoadingState(prev => ({
        ...prev,
        [widgetId]: true
      }));

      const nWidgetVariableSrvMap = clone(widgetVariableSrvMap.current);
      const variableSrv = nWidgetVariableSrvMap[widgetId];

      const entityNameVariable =
        (widget.variables.find(x => x.name === NameEntityFieldLabel) as CohortVariableImpl) || null;
      if (entityNameVariable) {
        entityNameVariable.value = value;
        entityNameVariable.operator = "eq";
        variableSrv.updateVariableValues(NameEntityFieldLabel, [value]);
      } else {
        const varModel = getEntityVariableModel(fieldEntityType, "", NameEntityFieldLabel, null, "eq", value);
        const cohortVarImpl = new CohortVariableImpl(varModel);
        cohortVarImpl.value = value;
        cohortVarImpl.operator = "eq";
        cohortVarImpl.isPinned = true;

        variableSrv.addVariable(cohortVarImpl);
        widget.variables.push(cohortVarImpl);
        await variableSrv.resolveBizFields(tr);
      }

      widgetVariableSrvMap.current = nWidgetVariableSrvMap;
      updateFilterQueryParams();

      setWidgetVariableSrvLoadingState(prev => ({
        ...prev,
        [widgetId]: false
      }));
    },
    [widgets, updateFilterQueryParams, tr]
  );

  const onVariablesValueUpdated = useCallback(
    (name: string, value: string | string[], widgetId: string) => {
      // gets triggered when updating global dashboard variable values
      if (!widgetId) {
        const varSrv = widgetVariableSrvMap.current[GLOBAL_VARIABLE_SRV_KEY];
        varSrv.updateVariableValues(name, value);
        updatedVarNameRef.current = name;
        const clonedVars = clone(varSrv.getVariables());
        updateDashboardVariables(clonedVars);
      } else {
        const variableMap = clone(widgetVariableSrvMap.current);
        const varSrv = variableMap[widgetId];
        varSrv.updateVariableValues(name, value);

        const widget = widgets.find(w => w.id === widgetId);
        const variable = widget?.variables.find(v => v.name === name);

        if (variable.type === VariableType.Cohort) {
          const cohortVar = variable as CohortVariableImpl;
          cohortVar.value = value;
        }

        widgetVariableSrvMap.current = variableMap;
        updateFilterQueryParams();
        setWidgetVariableSrvLoadingState(prev => ({
          ...prev
        }));
      }
      onDashboardUpdate();
    },
    [onDashboardUpdate, updateDashboardVariables, updateFilterQueryParams, widgets]
  );

  const onAddAdhocEventFilter = useCallback(
    (usFieldName: string, value: string) => {
      setWidgetVariableSrvLoadingState(prev => ({
        ...prev,
        [GLOBAL_VARIABLE_SRV_KEY]: true
      }));

      let filterWidget = dbImpl.widgets.find(
        w => w.type === "filter" && (w as FilterWidgetImpl).userServiceId === ALL_USERSERVICES_ENTITY_TYPE_ID
      ) as FilterWidgetImpl;

      if (!filterWidget) {
        filterWidget = new FilterWidgetImpl({
          appliedWidgets: [],
          userServiceId: ALL_USERSERVICES_ENTITY_TYPE_ID,
          entityTypeId: "",
          genericEventFilter: true
        });
        dbImpl.addWidget(filterWidget);
      }

      let varSrv = widgetVariableSrvMap.current[filterWidget.id];
      if (!varSrv) {
        varSrv = new VariableSrv(tr);
        widgetVariableSrvMap.current[filterWidget.id] = varSrv;
      }
      const variable = varSrv.variables.find(v => v.name === usFieldName);
      const userServiceFieldsWithMeta = eventTypeToFieldsMap[ALL_USERSERVICES_ENTITY_TYPE_ID];

      if (variable) {
        const nWidgetVariableSrvMap = clone(widgetVariableSrvMap.current);
        const variableSrv = nWidgetVariableSrvMap[filterWidget.id];
        const { variables } = variableSrv;
        variables.forEach(item => {
          if (item.name === usFieldName && value) {
            if (isString(item.value) && item.value !== value) {
              (item as EventVariableImpl).setValue([item.value, value]);
              (item as EventVariableImpl).setOperator("in");
            } else if (isArray(item.value) && item?.value?.length > 0 && !item.value.includes(value)) {
              (item as EventVariableImpl).setValue([...item.value, value]);
            } else {
              (item as EventVariableImpl).setValue(value);
            }
          }
        });

        widgetVariableSrvMap.current = nWidgetVariableSrvMap;
        onVariablesUpdated(variables, filterWidget.id);
      } else {
        if (userServiceFieldsWithMeta) {
          for (const usf of userServiceFieldsWithMeta) {
            const cFieldLabel = FieldPickerUtils.getUserServiceFieldLabel(usf.userServiceField);
            if (cFieldLabel === usFieldName) {
              const eVarModel: Partial<EventVariableModel> = {
                userServiceId: ALL_USERSERVICES_ENTITY_TYPE_ID,
                userServiceField: usf.userServiceField,
                operator: "=",
                value: value,
                name: cFieldLabel,
                type: VariableType.Event
              };

              const eventVarImpl = new EventVariableImpl(eVarModel);

              const nWidgetVariableSrvMap = clone(widgetVariableSrvMap.current);
              const variableSrv = nWidgetVariableSrvMap[filterWidget.id];
              variableSrv.addVariable(eventVarImpl);

              widgetVariableSrvMap.current = nWidgetVariableSrvMap;

              const existingVars = filterWidget.variables.map(x => new EventVariableImpl(x));
              onVariablesUpdated([...(existingVars || []), eventVarImpl], filterWidget.id);
              break;
            }
          }
        }
      }
      setWidgetVariableSrvLoadingState(prev => ({
        ...prev,
        [GLOBAL_VARIABLE_SRV_KEY]: false
      }));
      onDashboardUpdate();
    },
    [dbImpl, eventTypeToFieldsMap, onDashboardUpdate, onVariablesUpdated, tr]
  );

  return {
    widgetVariableSrvMap: widgetVariableSrvMap.current,
    onVariablesUpdated,
    onVariablesValueUpdated,
    onAddAdhocEntityFilter,
    onUpdateCohortId,
    widgetVariableSrvLoadingState,
    isEventTypeFieldsMapInitialized,
    eventTypeToFieldsMap,
    onAddAdhocEventFilter,
    refreshVariableSrvMap: updateOrInitVariableSrv,
    updateFilterQueryParams: updateFilterQueryParams
  };
};
