import { IncSelect, IncSelectOption } from "@inception/ui";
import React, { FC, memo, useCallback, useMemo } from "react";
import { ActionTemplatePropertyEditorProps } from "./types";

type Props = ActionTemplatePropertyEditorProps & {
  isMulti: boolean;
};

export const SelectEditor: FC<Props> = memo(props => {
  const { onPropsChange, property, value, isMulti } = props;

  const { description, label, propId, required, ranges, lookupData } = property;

  const options = useMemo(
    () =>
      ranges.map(r => ({
        value: r.stringVal,
        label: lookupData[r.stringVal] || r.stringVal
      })),
    [lookupData, ranges]
  );

  const selectedOptions = useMemo(() => {
    if (isMulti) {
      const setValues = value?.setValue?.values || [];
      return setValues.map(v => ({
        value: v,
        label: lookupData[v] || v
      }));
    }

    return options.find(o => o.value === value?.stringVal) || null;
  }, [isMulti, lookupData, options, value]);

  const onSelectionChange = useCallback(
    (options: IncSelectOption[] | IncSelectOption) => {
      if (isMulti) {
        const fOpts = options as IncSelectOption[];
        onPropsChange(propId, {
          stringVal: null,
          setValue: { values: fOpts.map(o => o.value) }
        });
      } else {
        const fOpt = options as IncSelectOption;
        onPropsChange(propId, {
          stringVal: fOpt.value
        });
      }
    },
    [isMulti, onPropsChange, propId]
  );

  return (
    <IncSelect
      autoAdjustWidth
      helpText={description}
      isMulti={isMulti}
      label={label}
      onChange={onSelectionChange as any}
      options={options}
      placeholder={label}
      required={required}
      value={selectedOptions}
    />
  );
});
