import { BizService } from "../../explore/BizServiceCommon";
import { ConversationResponse, GenerateFromInputPromptRequest } from "../types";

class GenerateFromPromptApi extends BizService {
  generateFromPrompt(request: GenerateFromInputPromptRequest) {
    this.init();

    const url = this.getBizEntityUrl("generateFromPrompt");
    const requestCallback = () =>
      this.datasource.post<ConversationResponse, GenerateFromInputPromptRequest>(url, request);
    return this.getResult(requestCallback);
  }

  getGenerationStatus(pollId: string) {
    this.init();

    const url = this.getBizEntityUrl(`generateFromPrompt/${pollId}/status`);
    const requestCallback = () => this.datasource.post<ConversationResponse, never>(url);
    return this.getResult(requestCallback);
  }
}

export const generateFromPromptApi = new GenerateFromPromptApi();
