import { uniqBy } from "lodash";
import {
  BizDataQuery,
  ExploreEntityFilter,
  MetricUserServiceFilters,
  RawEvent,
  WidgetResponseDTO
} from "../../../../../../../../services/api/explore";
import { EventChartType, formatWidgetConfigBasedOnMetric, generateSliceSpecBasedOnMetric } from "../eventTimelineUtils";
import { Datum } from "../../utils";
import { getWidgetConfigFromDto } from "../../../../../../../../utils/ExploreUtils";
import { RawEventCriteriaField } from "./types";

export type FieldsToFilter = {
  name: string;
  checkAbsolute: boolean;
};

export const convertCriteriaFieldsFromRawEvents = (
  rawEvents: RawEvent[],
  searchText: string,
  fieldsToFilter: FieldsToFilter[],
  defaultSelectedTag: string[],
  selectionMap: Record<string, string>
): RawEventCriteriaField[] => {
  const timeStampField: RawEventCriteriaField = {
    defaultSelected: false,
    field: "timeStamp"
  };
  const selectedKeys: RawEventCriteriaField[] = [timeStampField];
  rawEvents.forEach(val => {
    const keys = Object.keys(val.tags)
      .filter(
        tag => !checkFieldsIncludesTag(tag, fieldsToFilter) && tag.toLowerCase().includes(searchText.toLowerCase())
      )
      .map(e => ({
        field: e,
        defaultSelected: defaultSelectedTag.includes(e.toLowerCase())
      }));
    selectedKeys.push(...keys);
  });
  Object.keys(selectionMap).forEach(key => {
    if (!selectedKeys.some(e => e.field === key)) {
      selectedKeys.push({
        field: key,
        defaultSelected: false
      });
    }
  });
  const fields = rawEvents.length ? uniqBy(selectedKeys, c => c.field) : [];
  return fields;
};

const checkFieldsIncludesTag = (tag: string, fieldsToFilter: FieldsToFilter[]) =>
  fieldsToFilter.findIndex(field => {
    if (field.checkAbsolute) {
      return tag === field.name;
    } else {
      return tag.toLowerCase().replaceAll("_", "").includes(field.name.toLowerCase());
    }
  }) !== -1;

type BizDataProps = {
  widgetResponseDto: WidgetResponseDTO;
  metricId: string;
  fromMillis: number;
  toMillis: number;
  downsample: string;
  tableInfo?: Datum;
  aggTags: string[];
  entityFilters?: ExploreEntityFilter[];
  metricUserServiceFilters?: MetricUserServiceFilters;
  numBins?: number;
  chartType?: EventChartType;
};

type BizDataQueryFunction = (props: BizDataProps) => BizDataQuery;

export const getBizDataQuery: BizDataQueryFunction = ({
  widgetResponseDto,
  metricId,
  tableInfo,
  aggTags,
  downsample,
  fromMillis,
  toMillis,
  entityFilters,
  metricUserServiceFilters,
  numBins,
  chartType
}) => {
  const sliceSpec = generateSliceSpecBasedOnMetric(
    widgetResponseDto,
    metricId,
    fromMillis,
    toMillis,
    downsample,
    tableInfo,
    aggTags,
    numBins,
    chartType
  )?.[0];
  const widgetConfigDto = formatWidgetConfigBasedOnMetric(widgetResponseDto.widgetConfig, metricId);
  const widgetConfig = getWidgetConfigFromDto(widgetConfigDto);
  const bizDataQuery: BizDataQuery = {
    sliceSpec,
    widgetConfig: widgetResponseDto.widgetId ? null : widgetConfig,
    id: widgetResponseDto.widgetId ? widgetResponseDto.widgetId : null,
    entityFilters,
    metricUserServiceFilters
  };
  return bizDataQuery;
};
