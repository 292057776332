import React, { FC, useCallback, memo } from "react";
import { IncButton, IncFaIcon } from "@inception/ui";
import { TimeRange, useTimeRange, useToggleState } from "../../core";
import { VerticallyCenteredRow } from "../flex-components";
import { MetricTableConfig, MetricTableVariableContext } from "./types";
import { MetricTableRenderer } from "./MetricTableRenderer";
import { MetricTableWidgetEditor } from "./metric-table-editor";

interface Props {
  metricTableConfig: MetricTableConfig;
  defaultFileName: string;

  timeRange?: TimeRange;
  variablesContext?: MetricTableVariableContext;

  editable?: boolean;
  onChange?: (tableConfig: MetricTableConfig) => void;
}

export const MetricTable: FC<Props> = memo(props => {
  const {
    metricTableConfig,
    defaultFileName,
    timeRange: pTimeRange,
    variablesContext,
    editable,
    onChange: pOnChange
  } = props;

  const { timeRange: gTimeRange } = useTimeRange();

  const timeRange = pTimeRange ?? gTimeRange;
  const canEdit = editable && Boolean(pOnChange);

  const { close: closeEditor, isOpen: isEditorOpen, open: openEditor } = useToggleState();

  const onChange = useCallback(
    (tableConfig: MetricTableConfig) => {
      closeEditor();
      pOnChange(tableConfig);
    },
    [closeEditor, pOnChange]
  );

  return (
    <div className="metric-table">
      {canEdit && (
        <VerticallyCenteredRow className="marginBt12">
          <IncButton
            className="marginLtAuto"
            color="secondary-blue"
            iconType="iconText"
            onClick={openEditor}
            size="small"
          >
            <IncFaIcon
              iconName="edit"
              style={{ transform: "scale(0.85)" }}
            />
            Edit
          </IncButton>
        </VerticallyCenteredRow>
      )}

      <MetricTableRenderer
        defaultFileName={defaultFileName}
        metricTableConfig={metricTableConfig}
        timeRange={timeRange}
        variablesContext={variablesContext}
      />

      {isEditorOpen && (
        <MetricTableWidgetEditor
          metricTableConfig={metricTableConfig}
          onChange={onChange}
          onClose={closeEditor}
          open={isEditorOpen}
        />
      )}
    </div>
  );
});
