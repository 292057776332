import {
  TooltipFormatterContextObject,
  PointEventsOptionsObject,
  Point,
  PointLabelObject,
  AxisLabelsFormatterContextObject
} from "highcharts";
import { getFormattedDateTime, formatNumber } from "@inception/ui";
import { inRange } from "lodash";
import { css, cx } from "emotion";
import { IncVBarSeriesOptions } from "../../bar-chart/types";
import { IncidentTimelineBin, IncidentTimelineResponse } from "../../../services/api/explore";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { getDateTimeFormatForCharts } from "../../charts";
import { MTSIncidentSummaryLine } from "../../../services/api/operationalise";
import { shouldExcludeTag, TimeRange } from "../../../core";
import { pluralizeWord } from "../../../utils";
import { BizEntityDataResult } from "../../../dashboard/widgets/utils";

const MTS_CUSTOM_ACCESSOR = "__mts__";
const RANGE_CUSTOM_ACCESSOR = "__range__";
const SERIES_TYPE_CUSTOM_ACCESSOR = "__series_type__";
const BIN_CUSTOM_ACCESSOR = "__bin__";
const TAGMAP_CUSTOM_ACCESSOR = "__tag_count_map__";
const DATA_BIN_ACCESSOR = "__is_data_bin__";

const CLOSED_COLOR = "#BAE637";
const ACTIVE_COLOR = "#FF4D4F";

type OnSelectCallback = (bin: IncidentTimelineBin) => void;

export const getBarSeriesFromBins = (
  incidentTimelineBins: IncidentTimelineBin[],
  onSelect: OnSelectCallback,
  selectedBins: IncidentTimelineBin[],
  isDataBin = false
) => {
  const series: IncVBarSeriesOptions[] = [];
  const timestamps: number[] = [];

  const pointEvents: PointEventsOptionsObject = {
    click: function () {
      return clickCallback.bind(this)(onSelect);
    },
    select: function () {
      return selectCallback.bind(this)(onSelect);
    }
  };

  const activeSeries: IncVBarSeriesOptions = {
    type: "column",
    data: [],
    colors: [ACTIVE_COLOR],
    className: "active-series",
    opacity: 0.75,
    borderColor: "#212B36",
    borderWidth: 1,
    point: {
      events: pointEvents
    }
  };

  const closedSeries: IncVBarSeriesOptions = {
    type: "column",
    data: [],
    colors: [CLOSED_COLOR],
    className: "closed-series",
    opacity: 0.75,
    borderColor: "#212B36",
    borderWidth: 1,
    point: {
      events: pointEvents
    }
  };

  incidentTimelineBins.forEach((bin, binIdx) => {
    const { activeMTS, stoppedNowMTS, startTimeMillis, endTimeMillis, activeMTSLines, stoppedNowMTSLines } = bin;

    let numActive = activeMTS.length;
    let numClosed = stoppedNowMTS.length;

    if (isDataBin) {
      numActive = activeMTSLines.reduce((total, line) => total + line.lastSeenSec, 0);
      numClosed = stoppedNowMTSLines.reduce((total, line) => total + line.lastSeenSec, 0);
    }

    const startTimeSecs = timeRangeUtils.getSecondsFromMillis(startTimeMillis);
    timestamps.push(startTimeSecs);

    const range = {
      startTimeMillis: parseInt(startTimeMillis.toString(), 10),
      endTimeMillis: parseInt(endTimeMillis.toString(), 10)
    };

    const selected = selectedBins.length ? selectedBins.includes(bin) : true;
    const opacityClassName = css`
      opacity: 0.25;
    `;

    const activeSeriesClassName = cx({
      "series-point-top-round": !numClosed,
      [opacityClassName]: !selected
    });

    const closedSeriesClassName = cx("series-point-top-round", {
      [opacityClassName]: !selected
    });

    activeSeries.data.push({
      x: binIdx,
      y: numActive,
      className: activeSeriesClassName,
      custom: {
        [MTS_CUSTOM_ACCESSOR]: activeMTSLines,
        [RANGE_CUSTOM_ACCESSOR]: range,
        [SERIES_TYPE_CUSTOM_ACCESSOR]: "active",
        [BIN_CUSTOM_ACCESSOR]: bin,
        [DATA_BIN_ACCESSOR]: isDataBin
      }
    });

    closedSeries.data.push({
      x: binIdx,
      y: numClosed,
      className: closedSeriesClassName,
      custom: {
        [MTS_CUSTOM_ACCESSOR]: stoppedNowMTSLines,
        [RANGE_CUSTOM_ACCESSOR]: range,
        [SERIES_TYPE_CUSTOM_ACCESSOR]: "closed",
        [BIN_CUSTOM_ACCESSOR]: bin,
        [DATA_BIN_ACCESSOR]: isDataBin
      }
    });
  });

  series.push(closedSeries, activeSeries);

  return {
    series,
    timestamps
  };
};

export function incidentTimelineTooltipFormatter(
  this: TooltipFormatterContextObject,
  accessorHeaderMap: Record<string, string>,
  isMetricMode: boolean,
  impactMetricName: string
) {
  if (this) {
    const { points } = this;

    if (points.length) {
      const [pt1, pt2] = points;

      const pt1Custom = (pt1?.point as any)?.custom || {};
      const pt2Custom = (pt2?.point as any)?.custom || {};

      const range = (pt1Custom || pt2Custom)?.[RANGE_CUSTOM_ACCESSOR];

      if (range) {
        const { startTimeMillis, endTimeMillis } = range;

        const activePoint =
          pt1Custom[SERIES_TYPE_CUSTOM_ACCESSOR] === "active"
            ? pt1.point
            : pt2Custom[SERIES_TYPE_CUSTOM_ACCESSOR] === "active"
              ? pt2.point
              : null;

        const closedPoint =
          pt1Custom[SERIES_TYPE_CUSTOM_ACCESSOR] === "closed"
            ? pt1.point
            : pt2Custom[SERIES_TYPE_CUSTOM_ACCESSOR] === "closed"
              ? pt2.point
              : null;

        const activeCustom = (activePoint as any)?.custom || {};
        const closedCustom = (closedPoint as any)?.custom || {};

        const activeMTS: MTSIncidentSummaryLine[] = activeCustom[MTS_CUSTOM_ACCESSOR] || [];
        const closedMTS: MTSIncidentSummaryLine[] = closedCustom[MTS_CUSTOM_ACCESSOR] || [];

        const activeCount = activeMTS.length || 0;
        const closedCount = closedMTS.length || 0;

        if (!activeCount && !closedCount) {
          return "";
        }

        let tagCountMap: Record<string, number> =
          pt1Custom[TAGMAP_CUSTOM_ACCESSOR] || pt2Custom[TAGMAP_CUSTOM_ACCESSOR];

        if (!tagCountMap) {
          tagCountMap = {};
          const tags = (activeMTS[0] || closedMTS[0])?.ts?.tags || {};
          const displayTagKeys = Object.keys(tags).filter(t => !shouldExcludeTag(t));

          const tagValuesMap: Map<string, Set<string>> = new Map();

          [...activeMTS, ...closedMTS].forEach(mts => {
            const { tags } = mts.ts;
            displayTagKeys.forEach(tagKey => {
              const set = tagValuesMap.get(tagKey) || new Set<string>();
              const tagValue = tags[tagKey];
              tagValue && set.add(tagValue);

              tagValuesMap.set(tagKey, set);
            });
          });

          tagValuesMap.forEach((values, tagKey) => {
            const numValues = values.size;
            numValues && (tagCountMap[tagKey] = numValues);
          });
        }

        pt1Custom[TAGMAP_CUSTOM_ACCESSOR] = tagCountMap;
        pt2Custom[TAGMAP_CUSTOM_ACCESSOR] = tagCountMap;

        const displayTagKeys = Object.keys(tagCountMap).filter(t => !shouldExcludeTag(t));

        const plotWidth = (pt1 || pt2)?.series?.chart?.plotWidth || 1000;
        const { format, withSeconds } = getDateTimeFormatForCharts(null, plotWidth);
        const startStr = getFormattedDateTime(startTimeMillis, format, {
          withSeconds
        });
        const endStr = getFormattedDateTime(endTimeMillis, format, {
          withSeconds
        });
        const rangeStr = `${startStr} - ${endStr}`;

        const tagDivs = displayTagKeys.map(tagKey => {
          const count = tagCountMap[tagKey];
          const displayKey = pluralizeWord(accessorHeaderMap?.[tagKey] || tagKey);

          return `
            <div class="inc-flex-row inc-flex-center-vertical marginBt8">
              <div class="inc-text-subtext marginRt6" style="color: #B7BCC9;">Unique count of ${displayKey}: </div>
              <div class="inc-text-subtext-medium">${count}</div>
            </div>`;
        });

        const metricData = activeMTS.reduce((acc, { lastSeenSec }) => acc + lastSeenSec, 0);
        const formattedMetricData = formatNumber(metricData);

        const activeAndClosedInfo = !isMetricMode
          ? `
            <div class="inc-flex-row inc-flex-center-vertical marginTp12">
              ${
                activeCount
                  ? `
                <div class="inc-text-subtext-medium marginRt16" style="color: #FF523B;">
                  ${activeCount} Violating
                </div>
                `
                  : ""
              }

              ${
                closedCount
                  ? `
                <div class="inc-text-subtext-medium" style="color: #3BB443;">
                  ${closedCount} Resolved
                </div>
                `
                  : ""
              }
            </div>`
          : "";

        const impactMetricInfo = isMetricMode
          ? `<div class="inc-flex-row inc-flex-center-vertical marginBt12">
              <div class="inc-text-subtext marginRt6" style="color: #B7BCC9;">${impactMetricName}: </div>
              <div class="inc-text-subtext-medium">${formattedMetricData}</div>
            </div>`
          : "";

        return `
          <div class='inc-charts-tooltip' style="background: #2F3842;">
            <div class="marginBt12">
              <div class="inc-text-subtext-medium">${rangeStr}</div>
              <div class="inc-text-subtext marginTp2" style="color: #B7BCC9;">Time </div>
            </div>

            ${impactMetricInfo}

            ${tagDivs.join("")}

            ${activeAndClosedInfo}
          </div>
        `;
      }

      return "";
    }

    return "";
  }

  return "";
}

export function incidentTimelineDataLabelFormatter(this: PointLabelObject) {
  if (this) {
    const { point } = this;

    const { custom } = point as any;
    const bin = custom[BIN_CUSTOM_ACCESSOR] as IncidentTimelineBin;
    const isDataBin = custom[DATA_BIN_ACCESSOR];

    if (bin && point.color === CLOSED_COLOR) {
      const { activeMTS, stoppedNowMTS, activeMTSLines } = bin;

      const numActive = activeMTS?.length;
      const numStopped = stoppedNowMTS?.length;

      let activeStr = numActive?.toString();

      if (isDataBin) {
        const totalValue = activeMTSLines.reduce((total, line) => total + line.lastSeenSec, 0);
        activeStr = formatNumber(totalValue);
      }

      let div = '<div class="inc-flex-row inc-flex-center" style="opacity: 0.75;">';
      div += numActive
        ? `<div style="color: ${ACTIVE_COLOR};">
        ${activeStr}
      </div>`
        : "";

      div +=
        numStopped && numActive
          ? `<div class="marginLt2 marginRt2">
        /
      </div>`
          : "";

      div += numStopped
        ? `<div style="color: ${CLOSED_COLOR};">
        ${numStopped}
      </div>`
        : "";

      div += "</div>";

      return div;
    }

    return "";
  }

  return "";
}

export function incidentTimelineAxisLabelFormatter(
  context: AxisLabelsFormatterContextObject,
  timestamps: number[],
  timeRange: TimeRange
) {
  if (context) {
    const { pos, chart } = context;

    const { chartWidth } = chart;

    const tsSecs = timestamps[pos];
    const tsMillis = tsSecs * 1000;

    const { format, withSeconds } = getDateTimeFormatForCharts(timeRange, chartWidth);

    const dateStr = getFormattedDateTime(tsMillis, format, { withSeconds });
    return dateStr.split(" ").join("<br />");
  }

  return "";
}

export const getBinsFromImpactedWidgetData = (
  impactedWidgetData: BizEntityDataResult,
  startTimeMillis: number,
  defaultLastTs: number
) => {
  const bins: IncidentTimelineBin[] = [];

  const { data } = Object.values(impactedWidgetData?.postAggData || {})[0] || {};
  const dataByTs = new Map<number, MTSIncidentSummaryLine[]>();

  const dataExists = Boolean(data?.length);

  if (dataExists) {
    data.forEach(df => {
      const { fields, labels, metricName } = df;
      const [timestampField, valueField] = fields;

      const timestamps = timestampField.data as number[];
      const values = valueField.data as number[];

      timestamps.forEach((ts, idx) => {
        const arr = dataByTs.get(ts) || [];
        const value = values[idx];

        if (inRange(ts, startTimeMillis, defaultLastTs + 1)) {
          arr.push({
            lastSeenSec: value || 0,
            mtsId: "",
            ranges: [],
            ts: {
              aggTks: [],
              metricName,
              tags: labels
            }
          });
        }

        dataByTs.set(ts, arr);
      });
    });

    const timestamps = Array.from(dataByTs.keys()).sort();

    timestamps.forEach((ts, idx) => {
      const summaryLines = dataByTs.get(ts);
      const startTimeMillis = ts;
      let endTimeMillis = timestamps[idx + 1];
      endTimeMillis = endTimeMillis ? endTimeMillis : defaultLastTs;

      if (summaryLines.length) {
        bins.push({
          activeMTS: summaryLines.map(line => line.mtsId),
          activeMTSLines: summaryLines,
          endTimeMillis,
          startTimeMillis,
          stoppedNowMTS: [],
          stoppedNowMTSLines: []
        });
      }
    });
  }

  return bins;
};

export const getBinsFromIncidentResponse = (
  incidentResponse: IncidentTimelineResponse,
  timeRange?: TimeRange,
  uniqueAlerts = false
) => {
  let bins = [...(incidentResponse?.bins || [])];

  if (timeRange) {
    const { fromMillis, toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);

    bins = bins.filter(bin => {
      const { startTimeMillis } = bin;
      return inRange(startTimeMillis, fromMillis, toMillis);
    });
  }

  if (uniqueAlerts) {
    const nBins = bins.map((bin): IncidentTimelineBin => {
      const { activeMTSLines, endTimeMillis, startTimeMillis, stoppedNowMTSLines } = bin;

      const nActiveMTSLines = activeMTSLines.filter(line => {
        const { ranges } = line;
        const canInclude = ranges.some(range => inRange(range.windowStartSec * 1000, startTimeMillis, endTimeMillis));
        return canInclude;
      });

      const nStoppedNowMTSLines = stoppedNowMTSLines.filter(line => {
        const { ranges } = line;
        const canInclude = ranges.some(range => inRange(range.endSec * 1000, startTimeMillis, endTimeMillis));
        return canInclude;
      });

      const nActiveMTS = nActiveMTSLines.map(line => line.mtsId);
      const nStoppedNowMTS = nStoppedNowMTSLines.map(line => line.mtsId);

      return {
        ...bin,
        activeMTS: nActiveMTS,
        activeMTSLines: nActiveMTSLines,
        stoppedNowMTS: nStoppedNowMTS,
        stoppedNowMTSLines: nStoppedNowMTSLines
      };
    });

    return nBins;
  }

  return bins;
};

const clickCallback = function (this: Point, onSelect: OnSelectCallback) {
  processSelection(this, onSelect);
};

const selectCallback = function (this: Point, onSelect: OnSelectCallback) {
  processSelection(this, onSelect);
};

const processSelection = (point: Point, onSelect: OnSelectCallback) => {
  const { custom } = point as any;
  const bin = custom[BIN_CUSTOM_ACCESSOR] as IncidentTimelineBin;
  onSelect(bin);
};
