import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { cloneDeep, isEqual, isNil } from "lodash";
import { MuteTimeRange, SeriesFilter } from "../../../services/api/operationalise";
import { SnoozeModal } from "../../v3/actions/ActionCard";
import { dateTime } from "../../../core";
import { SelectorSpec } from "../../../services/api/explore";

type Props = {
  seriesFilters?: SeriesFilter[];
  mtsId?: string;
  selectorSpec?: SelectorSpec;
  onChangeSeriesFilter: (seriesFilter: SeriesFilter[]) => void;
};

const SnoozeModalWrapper: FC<Props> = props => {
  const { seriesFilters, selectorSpec, onChangeSeriesFilter } = props;

  const pSnoozeTimeRange = useMemo<MuteTimeRange>(() => {
    let muteTimeRange: MuteTimeRange;
    const series = (seriesFilters || []).find(filter => isEqual(filter.selectorSpec, selectorSpec));

    if (series) {
      muteTimeRange = series.timeRange;
    } else {
      muteTimeRange = null;
    }
    return muteTimeRange;
  }, [selectorSpec, seriesFilters]);

  const [snoozeTimeRange, setSnoozeTimeRange] = useState(pSnoozeTimeRange);

  useEffect(() => {
    setSnoozeTimeRange(pSnoozeTimeRange);
  }, [pSnoozeTimeRange]);

  const isSnoozeForever = useMemo(() => {
    if (snoozeTimeRange) {
      const { startTimeMillis, endTimeMillis } = snoozeTimeRange;
      return Number(startTimeMillis) === 0 && Number(endTimeMillis) === 0;
    }
    return false;
  }, [snoozeTimeRange]);

  const isSnoozed = useMemo(() => {
    if (isSnoozeForever) {
      return true;
    }
    if (snoozeTimeRange) {
      const { startTimeMillis, endTimeMillis } = snoozeTimeRange;
      if (startTimeMillis && endTimeMillis) {
        const nowMillis = dateTime().valueOf();
        return Number(startTimeMillis) <= nowMillis && nowMillis <= Number(endTimeMillis);
      }
      return false;
    }
    return false;
  }, [isSnoozeForever, snoozeTimeRange]);

  const onSaveTimeRange = useCallback(
    (timeRange: MuteTimeRange) => {
      let newSeries = cloneDeep(seriesFilters || []);
      if (isNil(timeRange.startTimeMillis) || isNil(timeRange.endTimeMillis)) {
        newSeries = newSeries.filter(series => !isEqual(series.selectorSpec, selectorSpec));
      } else {
        const filterIndex = newSeries.findIndex(series => isEqual(series.selectorSpec, selectorSpec));
        if (filterIndex !== -1) {
          newSeries[filterIndex] = {
            ...newSeries[filterIndex],
            timeRange
          };
        } else {
          newSeries.push({
            selectorSpec,
            timeRange
          });
        }
      }
      setSnoozeTimeRange(timeRange);
      onChangeSeriesFilter(newSeries);
    },
    [selectorSpec, onChangeSeriesFilter, seriesFilters]
  );

  return (
    <SnoozeModal
      iconClassName="snooze-series-icon marginLt12"
      isSnoozeEnable={isSnoozed}
      isSnoozeForever={isSnoozeForever}
      onSave={onSaveTimeRange}
      popperClassName="snooze-series-popper"
      showSnoozeForever
      snoozedClassName="snoozed"
      timeRange={snoozeTimeRange}
      title="Snooze Series"
    />
  );
};

export default SnoozeModalWrapper;
