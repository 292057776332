import React, { FC, useMemo, useCallback, useRef, useState } from "react";
import { generateId, IncButton, IncFaIcon } from "@inception/ui";
import { cloneDeep } from "lodash";
import { SuppressionConfigDef } from "../../../../services/api/operationalise";
import { VerticallyCenteredRow } from "../../../../components";
import { BizDataQuery, ImpactedWidget } from "../../../../services/api/explore";
import { SuppressionEditorRow } from "./SuppressionEditorRow";
import { SuppressionEditorPickerContext } from "./types";

interface Props {
  suppressions: SuppressionConfigDef[];
  pickerContext: SuppressionEditorPickerContext;
  bizDataQuery: BizDataQuery;
  onChange: (suppressions: SuppressionConfigDef[]) => void;

  compareTimeSeconds?: number;
  readOnly?: boolean;
  getPredefinedMetrics?: () => Promise<ImpactedWidget[]>;
}

export const SuppressionsEditor: FC<Props> = props => {
  const {
    onChange,
    suppressions: pSuppressions,
    pickerContext,
    bizDataQuery: pBizDataQuery,
    readOnly = false,
    getPredefinedMetrics,
    compareTimeSeconds
  } = props;

  const suppresionsRef = useRef<SuppressionConfigDef[]>(pSuppressions);

  const [isDefaultInitialized, setIsDefaultInitialized] = useState(false);

  const updateDefaultSuppressions = useCallback(() => {
    setIsDefaultInitialized(false);
    if (pSuppressions) {
      suppresionsRef.current = pSuppressions;
    }
    setIsDefaultInitialized(true);
  }, [pSuppressions]);

  useMemo(() => {
    updateDefaultSuppressions();
  }, [updateDefaultSuppressions]);

  const suppressions = suppresionsRef.current;

  const uniqId = useMemo(() => generateId(), []);

  const onSuppressionDelete = useCallback(
    (idx: number) => {
      const nSuppressions = [...suppresionsRef.current];
      nSuppressions.splice(idx, 1);
      onChange(nSuppressions);
    },
    [onChange]
  );

  const onSuppressionChange = useCallback(
    (suppressionDef: SuppressionConfigDef, idx: number) => {
      const nSuppressions = [...suppresionsRef.current];
      nSuppressions.splice(idx, 1, suppressionDef);
      onChange(nSuppressions);
    },
    [onChange]
  );

  const onSuppressionAdd = useCallback(async () => {
    setIsDefaultInitialized(false);
    const nSuppressions = [...suppresionsRef.current];
    const nSuppression: SuppressionConfigDef = await getDefaultSuppression(cloneDeep(pBizDataQuery));
    nSuppression.operator = "lt";
    nSuppressions.push(nSuppression);
    onChange(nSuppressions);
    setIsDefaultInitialized(true);
  }, [onChange, pBizDataQuery]);

  const addSuppressionButton = useMemo(
    () =>
      readOnly ? (
        <></>
      ) : (
        <IncButton
          className="width-fit-content"
          color="link"
          disabled={!isDefaultInitialized}
          iconType="iconText"
          loading={!isDefaultInitialized}
          onClick={onSuppressionAdd}
          size="regular"
        >
          <IncFaIcon iconName="plus" />
          <VerticallyCenteredRow>Add Condition</VerticallyCenteredRow>
        </IncButton>
      ),
    [isDefaultInitialized, onSuppressionAdd, readOnly]
  );

  const suppressionEditors = useMemo(() => {
    const suppressionEditors = suppressions.map((suppression, idx) => {
      const { sliceSpec } = suppression.bizDataQuery || {};

      const queryComponentId = sliceSpec?.buildingBlockConfigId || sliceSpec?.metricId || sliceSpec?.fieldId;
      const id = queryComponentId || generateId();

      const key = `${uniqId}-${idx}-${id}`;

      const onDelete = readOnly ? null : () => onSuppressionDelete(idx);
      const onChange = (suppressionDef: SuppressionConfigDef) => onSuppressionChange(suppressionDef, idx);

      return (
        <SuppressionEditorRow
          compareTimeSeconds={compareTimeSeconds}
          getPredefinedMetrics={getPredefinedMetrics}
          key={key}
          onChange={onChange}
          onDelete={onDelete}
          pickerContext={pickerContext}
          suppressionConfigDef={suppression}
        />
      );
    });

    return suppressionEditors;
  }, [
    compareTimeSeconds,
    getPredefinedMetrics,
    onSuppressionChange,
    onSuppressionDelete,
    pickerContext,
    readOnly,
    suppressions,
    uniqId
  ]);

  const suppressionEditorWrapperClass = `inc-flex-column inc-flex-grow`;

  const shouldRender = readOnly ? suppressionEditors.length > 0 : true;

  const className = `suppressions-editor inc-flex-row inc-flex-grow`;

  return shouldRender ? (
    <div className={className}>
      <div className={suppressionEditorWrapperClass}>
        {suppressionEditors}
        {addSuppressionButton}
      </div>
    </div>
  ) : (
    <></>
  );
};

const getDefaultSuppression = (defBizDataQuery: BizDataQuery): Promise<SuppressionConfigDef> =>
  Promise.resolve({
    bizDataQuery: removeNameFromBizDataQuery(defBizDataQuery),
    operator: null,
    value: null
  });

const removeNameFromBizDataQuery = (bizDataQuery: BizDataQuery): BizDataQuery => {
  const nQuery = cloneDeep(bizDataQuery);
  if (nQuery?.buildingBlockConfig?.name) {
    nQuery.buildingBlockConfig.name = "";
  }
  if (nQuery?.widgetConfig?.name) {
    nQuery.widgetConfig.name = "";
  }
  return nQuery;
};
