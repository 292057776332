import { cloneDeep } from "lodash";
import { ConnectorSchema, ConnectorSchemaResponse } from "@inception/connector-config-ui/src/schema-types";
import datasourceApiManager from "../DatasourceApiService";
import {
  IntegrationsResponse,
  IntegrationTypeInfo,
  SaveEntityConfig,
  SaveSourceConfig,
  SaveSourceConfigureConnection,
  SourceType,
  SourceTypeDef
} from "../event-mapping";
import { logger } from "../../../core";
import {
  StreamsTypeBatchData,
  ConnectorInstanceData,
  ConnectorPreviewData,
  EntityMasterMapping,
  PreviewDataRequestV2,
  PreviewEntitiesData,
  SaveBizSystemConnectorRequestV2,
  Stream,
  TestAndDiscoverRequest,
  PreviewField,
  PayloadPointer,
  TestAndDiscoverEditRequest,
  SyncMode,
  Cluster,
  ConfiguredConnectorStreamList,
  ConfiguredConnectorStream,
  SaveBizKnowledgeBaseConnectorRequestV2
} from "./types";

/**
 * Enitity mapping API V2 calls
 */
interface EntityMappingApiServiceInterfaceV2 {
  getAllConnections: () => Promise<ConnectorInstanceData[]>;
  getConnectionById: (connectorId: string) => Promise<SaveBizSystemConnectorRequestV2>;
  getSourcesByType: (sourceType: SourceType) => Promise<SourceTypeDef[]>;
  getSourceTypeIcons: (sourceTypeIds: string[]) => Promise<Record<string, string>>;
  saveBizSource: (request: SaveBizSystemConnectorRequestV2) => Promise<SaveBizSystemConnectorRequestV2>;
  testAndDiscoverStreams: (request: TestAndDiscoverRequest) => Promise<Stream[]>;
  getPreviewData: (request: PreviewDataRequestV2) => Promise<ConnectorPreviewData>;

  getFieldsSchema: (connectionId: string, stream: Stream) => Promise<[PayloadPointer, Record<string, PreviewField>]>;
  getMappingsPreviewData: (
    connectionId: string,
    stream: Stream,
    entityTypeId: string,
    propertyNames: string[],
    mapping: EntityMasterMapping,
    streamIdForConnection: string
  ) => Promise<PreviewEntitiesData[]>;
  getExistingConnectedStreams: (connectionId: string) => Promise<Record<string, string>>;
  saveConnectionStream: (
    data: SaveEntityConfig | SaveSourceConfig,
    source: "EVENT" | "BUSINESS_SYSTEM"
  ) => Promise<string | SaveSourceConfig>;
  upsertEntityMapping: (mapping: EntityMasterMapping) => Promise<EntityMasterMapping>;
  getConnectionStreamByIds: (streamIds: string[]) => Promise<Record<string, StreamsTypeBatchData>>;
  getConnectionSpec: (sourceType: string, sourceTypeId: string, connectionId: string) => Promise<ConnectorSchema>;
  getClusters: (from: number, to: number) => Promise<Cluster[]>;
}

class EntityMappingApiServiceV2 implements EntityMappingApiServiceInterfaceV2 {
  readonly ENTITY_MAPPING_BASE_URL_V2: string = "/entity-mapping/api/v2";

  async getAllConnections(): Promise<ConnectorInstanceData[]> {
    try {
      const response: {
        data: {
          configuredConnections: ConnectorInstanceData[];
        };
      } = await datasourceApiManager
        .getDefault()
        .get(`${this.ENTITY_MAPPING_BASE_URL_V2}/connections/source-types/BUSINESS_SYSTEM`);

      if (response && response.data) {
        return response.data.configuredConnections || [];
      }
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error fetchng all source types", e);
    }

    return [];
  }

  async getConnectionById(connectorId: string): Promise<SaveBizSystemConnectorRequestV2> {
    try {
      const response: {
        data: SaveBizSystemConnectorRequestV2;
      } = await datasourceApiManager.getDefault().get(`${this.ENTITY_MAPPING_BASE_URL_V2}/connections/${connectorId}`);

      if (response) {
        return response.data;
      }
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error while saving source");
      throw e;
    }

    return null;
  }

  async deleteitem(connectorId: string): Promise<any> {
    try {
      const response: {
        data: any;
      } = await datasourceApiManager
        .getDefault()
        .delete(`${this.ENTITY_MAPPING_BASE_URL_V2}/connections/${connectorId}`);

      if (response) {
        return response.data;
      }
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error while deleting Connections", e);
      throw e;
    }
    return null;
  }

  async deleteKnowledgeBaseConnection(streamId: string): Promise<any> {
    try {
      const url = `${this.ENTITY_MAPPING_BASE_URL_V2}/connections/connector-stream/${streamId}`;
      const response: {
        data: any;
      } = await datasourceApiManager.getDefault().delete(url);

      if (response) {
        return response.data;
      }
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error while deleting Connections", e);
      throw e;
    }
    return null;
  }

  async getSourcesByType(sourceType: SourceType): Promise<SourceTypeDef[]> {
    try {
      const response: {
        data: {
          sourceTypes: SourceTypeDef[];
        };
      } = await datasourceApiManager.getDefault().post(`${this.ENTITY_MAPPING_BASE_URL_V2}/connectors/source-types`, {
        sourceType: sourceType,
        category: "ALL"
      });

      return response.data?.sourceTypes || [];
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error fetchng all source types", e);
    }

    return [];
  }

  async getSourceTypeIcons(sourceTypeIds: string[]): Promise<Record<string, string>> {
    try {
      const response: {
        data: {
          icons: Record<string, string>;
        };
      } = await datasourceApiManager
        .getDefault()
        .post(`${this.ENTITY_MAPPING_BASE_URL_V2}/connectors/source-type-icon`, {
          sourceTypeIds: sourceTypeIds
        });

      return response.data?.icons || {};
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error fetchng all source types", e);
    }

    return {};
  }

  async testAndDiscoverStreams(
    request: TestAndDiscoverRequest | TestAndDiscoverEditRequest | SaveSourceConfigureConnection
  ): Promise<Stream[]> {
    try {
      const response: {
        data: {
          stream: Stream[];
        };
      } = await datasourceApiManager
        .getDefault()
        .post(`${this.ENTITY_MAPPING_BASE_URL_V2}/connections/test-discover`, request);

      if (response && response.data) {
        return response.data.stream || [];
      }
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error while fetching streams");
      throw e;
    }

    return [];
  }

  async getPreviewData(request: PreviewDataRequestV2): Promise<ConnectorPreviewData> {
    try {
      const response: {
        data: ConnectorPreviewData;
      } = await datasourceApiManager
        .getDefault()
        .post(`${this.ENTITY_MAPPING_BASE_URL_V2}/mappings/data-preview`, request);

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error while fetching preview data");
    }

    return null;
  }

  async saveKnowledgeBaseSource(
    request: SaveBizKnowledgeBaseConnectorRequestV2
  ): Promise<SaveBizKnowledgeBaseConnectorRequestV2> {
    try {
      const response: {
        data: SaveBizKnowledgeBaseConnectorRequestV2;
      } = await datasourceApiManager
        .getDefault()
        .post(`/entity-mapping/api/v2/connections/connection-stream/`, request);

      if (response) {
        return response.data;
      }
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error while saving source");
      throw e;
    }

    return null;
  }

  async saveBizSource(request: SaveBizSystemConnectorRequestV2): Promise<SaveBizSystemConnectorRequestV2> {
    try {
      const response: {
        data: SaveBizSystemConnectorRequestV2;
      } = await datasourceApiManager
        .getDefault()
        .post(`${this.ENTITY_MAPPING_BASE_URL_V2}/connections/connection-config`, request);

      if (response) {
        return response.data;
      }
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error while saving source");
      throw e;
    }

    return null;
  }

  async getFieldsSchema(connectionId: string, stream: Stream): Promise<[PayloadPointer, Record<string, PreviewField>]> {
    try {
      const response: {
        data: {
          payloadPointer: PayloadPointer;
          fields: Record<string, PreviewField>;
        };
      } = await datasourceApiManager.getDefault().post(`${this.ENTITY_MAPPING_BASE_URL_V2}/connectors/schema`, {
        connectionId,
        configuredStream: getConfiguredStream(stream)
      });

      if (response && response.data) {
        return [response.data.payloadPointer, response.data.fields || {}];
      }
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error while fetchng field schema");
    }

    return [null, {}];
  }

  async getMappingsPreviewData(
    connectionId: string,
    stream: Stream,
    entityTypeId: string,
    propertyNames: string[],
    mapping: EntityMasterMapping,
    streamIdForConnection: string
  ): Promise<PreviewEntitiesData[]> {
    try {
      const cloneMapping = cloneDeep(mapping);
      delete cloneMapping.primaryType.fieldMappings;

      let streamDetailsObj: any = null;

      //we need to always send connectionId, configuredSteam while preview data
      //while adding relationship between entities, we will not fetch stream info, so pass streamId in that case only
      if (!stream) {
        streamDetailsObj = {
          streamDetails: {
            configuredConnectorStreamId: streamIdForConnection
          }
        };
      } else {
        streamDetailsObj = {
          streamDetails: {
            connectionId,
            configuredStream: getConfiguredStream(stream),
            configuredConnectorStreamId: streamIdForConnection || null
          }
        };
      }

      const response: {
        data: {
          entities: PreviewEntitiesData[];
        };
      } = await datasourceApiManager.getDefault().post(`${this.ENTITY_MAPPING_BASE_URL_V2}/mappings/preview`, {
        entityTypeId: entityTypeId,
        propertyNames: propertyNames,
        masterMapping: cloneMapping,
        ...streamDetailsObj
      });

      if (response) {
        return response.data?.entities || [];
      }
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error while fetching preview data");
    }

    return [];
  }

  async getExistingSavedStreams(connectionId: string): Promise<Record<string, ConfiguredConnectorStream>> {
    try {
      const response: {
        data: {
          connectedStreamDetails: Record<string, ConfiguredConnectorStream>;
        };
      } = await datasourceApiManager
        .getDefault()
        .get(`${this.ENTITY_MAPPING_BASE_URL_V2}/connections/${connectionId}/discover-fetch-connected-stream`);

      if (response && response.data) {
        const streamResponse = response.data.connectedStreamDetails || {};
        return streamResponse;
      }
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error while fetching existing connected streams");
      throw e;
    }

    return {};
  }
  async getExistingConnectedStreams(connectionId: string): Promise<Record<string, string>> {
    try {
      const response: {
        data: {
          connectedStreams: Record<string, string>;
        };
      } = await datasourceApiManager
        .getDefault()
        .get(`${this.ENTITY_MAPPING_BASE_URL_V2}/connections/${connectionId}/discover-fetch-connected-stream`);

      if (response && response.data) {
        return response.data.connectedStreams || {};
      }
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error while fetching existing connected streams");
      throw e;
    }

    return {};
  }

  async saveConnectionStream(data: SaveEntityConfig | SaveSourceConfig): Promise<string | SaveSourceConfig> {
    try {
      if (data.sourceType === "BUSINESS_SYSTEM") {
        const request = data as SaveEntityConfig;
        const { stream, connectionId, sourceType, syncMode, cursorField, streamIdForConnection } = request;
        const response: {
          data: {
            configuredConnectorStreamId: string;
          };
        } = await datasourceApiManager
          .getDefault()
          .post(`${this.ENTITY_MAPPING_BASE_URL_V2}/connections/connection-stream`, {
            name: stream.name,
            connectionId,
            configuredStream: getConfiguredStream(stream, syncMode, cursorField),
            sourceType,
            configuredConnectorStreamId: streamIdForConnection || null
          });

        if (response && response.data) {
          return response.data.configuredConnectorStreamId as string;
        }
      } else {
        const response: {
          data: SaveSourceConfig;
        } = await datasourceApiManager
          .getDefault()
          .post(`${this.ENTITY_MAPPING_BASE_URL_V2}/connections/connection-stream`, data);

        return response.data as SaveSourceConfig;
      }
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error while saving connection stream");
      throw e;
    }

    return null;
  }
  async saveConfiguredStream(data: ConfiguredConnectorStream): Promise<ConfiguredConnectorStream> {
    try {
      const response: {
        data: ConfiguredConnectorStream;
      } = await datasourceApiManager
        .getDefault()
        .post(`${this.ENTITY_MAPPING_BASE_URL_V2}/connections/connection-stream`, data);

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error while saving configured connection stream");
      throw e;
    }

    return null;
  }

  async upsertEntityMapping(mapping: EntityMasterMapping): Promise<EntityMasterMapping> {
    const cloneMapping = cloneDeep(mapping);
    delete cloneMapping.primaryType.fieldMappings;

    try {
      const response: {
        data: EntityMasterMapping;
      } = await datasourceApiManager.getDefault().post(`${this.ENTITY_MAPPING_BASE_URL_V2}/mappings`, cloneMapping);

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error while saving mapping");
      throw e;
    }

    return null;
  }

  async getConnectionStreamByIds(streamIds: string[]): Promise<Record<string, StreamsTypeBatchData>> {
    try {
      const response: {
        data: {
          connectionStreams: Record<string, StreamsTypeBatchData>;
        };
      } = await datasourceApiManager.getDefault().post(`${this.ENTITY_MAPPING_BASE_URL_V2}/connectors/type-batch`, {
        values: streamIds
      });

      if (response && response.data) {
        return response.data.connectionStreams || {};
      }
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error while fetching configured streams");
      throw e;
    }

    return null;
  }

  async getConnectionSpec(sourceType: string, sourceTypeId: string, connectionId: string): Promise<ConnectorSchema> {
    try {
      const response: {
        data: ConnectorSchemaResponse;
      } = await datasourceApiManager
        .getDefault()
        .post(`${this.ENTITY_MAPPING_BASE_URL_V2}/connectors/specification/${sourceType}`, {
          sourceTypeId: sourceTypeId,
          connectionId: connectionId
        });

      if (response && response.data) {
        return {
          ...response.data,
          connectionSpecification: JSON.parse(response.data.connectionSpecification)
        };
      }
    } catch (e) {
      logger.error("EventMappingApiService", `Error while fetching connection spec for: ${sourceTypeId}`, e);
    }

    return null;
  }

  async getClusters(from: number, to: number): Promise<Cluster[]> {
    try {
      const response: {
        data: {
          clusters: Cluster[];
        };
      } = await datasourceApiManager
        .getDefault()
        .get(`${this.ENTITY_MAPPING_BASE_URL_V2}/clusters?st=${from}&et=${to}`);

      if (response?.data?.clusters) {
        return response.data.clusters;
      }
    } catch (e) {
      logger.error("EntityMappingApiServiceV2", "Error while fetching configured streams");
      throw e;
    }

    return [];
  }

  async getConfiguredSourceDetails(connectorSourceId: string): Promise<SaveSourceConfig> {
    try {
      const response: {
        data: SaveSourceConfig;
      } = await datasourceApiManager
        .getDefault()
        .get(`${this.ENTITY_MAPPING_BASE_URL_V2}/connections/connector-stream/${connectorSourceId}`);

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      logger.error("EntityMappingApiService", `Error while configured source with id ${connectorSourceId}`, e);
    }

    return null;
  }

  async getAvailableIntegrations(): Promise<IntegrationTypeInfo[]> {
    try {
      const response: {
        data: IntegrationsResponse;
      } = await datasourceApiManager.getDefault().get(`/entity-mapping/api/v2/connectors/integration-info`);

      if (response && response.data) {
        return response.data.integrationTypes;
      }
    } catch (e) {
      logger.error("EntityMappingApiService", `Error while fetching integration info`, e);
    }

    return null;
  }

  async getIntegrationTypeForSourceType(sourceType: SourceType): Promise<IntegrationTypeInfo> {
    let integrationType: IntegrationTypeInfo;
    try {
      const integrationTypesResponse = await this.getAvailableIntegrations();

      if (integrationTypesResponse.length) {
        integrationType = integrationTypesResponse.find(integrationType => integrationType.type === sourceType);
      }

      if (integrationType) {
        const sourceNames = integrationType.sourceTypes.map(sourceType => sourceType.sourceTypeId);

        const iconsCache = await this.getSourceTypeIcons(sourceNames);

        if (iconsCache) {
          integrationType.sourceTypes.forEach(sourceType => {
            sourceType.icon = iconsCache[sourceType.sourceTypeId];
          });
        }
        return integrationType;
      }
    } catch (e) {
      logger.error("EntityMappingApiService", `Error while fetching integration info`, e);
    }

    return null;
  }

  async getConnectorStreams(sourceType: SourceType): Promise<ConfiguredConnectorStream[]> {
    try {
      const response: {
        data: ConfiguredConnectorStreamList;
      } = await datasourceApiManager.getDefault().get(`/entity-mapping/api/v2/connector-streams/all/${sourceType}`);

      if (response && response.data) {
        return response.data.configuredConnectorStream;
      }
    } catch (e) {
      logger.error("EntityMappingApiService", `Error while fetching Connector Streams info`, e);
    }

    return null;
  }
}

const entityMappingApiServiceV2 = new EntityMappingApiServiceV2();
export default entityMappingApiServiceV2;

const getConfiguredStream = (stream: Stream, syncMode?: SyncMode, cursorField?: string[]) => ({
  syncMode: syncMode || "",
  cursorField: cursorField || [],
  stream: {
    ...stream,
    jsonSchema: stream.jsonSchema
  }
});
