import { IncFaIcon, IncSelect, IncSelectOption, IncToolTip } from "@inception/ui";
import React, { FC, useCallback, useMemo, useState, useEffect } from "react";
import { TimeRange } from "../../../core";
import { ImpactedWidgetList } from "../../../services/api/explore";
import { VerticallyCenteredRow } from "../../flex-components";
import { TimeRangeSelector } from "../../time-range";
import { IncidentTimelineContext } from "../types";

interface Props {
  isLoading: boolean;
  error: string;

  incidentId: string;
  impactedWidgetsList: ImpactedWidgetList[];

  disableContextSelection?: boolean;
  defContext?: IncidentTimelineContext;
  onContextChange: (context: TimelineHeaderContext) => void;
  extCtxOptions?: Option[];

  tagsData: Array<Record<string, string>>;
  accessorHeaderMap: Record<string, string>;
  lookupData?: Record<string, string>;
  entityTags?: string[];

  timeRange: TimeRange;
  setTimeRange: (nTr: TimeRange) => void;
  resetTimeRange: () => void;
  shouldShowReset?: boolean;

  addlFilterJsx?: JSX.Element;
  hideTimeRange?: boolean;
}

export type TimelineHeaderContext = Omit<IncidentTimelineContext, "opConfigId" | "timeRange">;

export const TimelineHeader: FC<Props> = props => {
  const {
    incidentId,
    onContextChange,
    isLoading,
    error,
    impactedWidgetsList,
    timeRange,
    setTimeRange,
    resetTimeRange,
    shouldShowReset = false,
    defContext,
    addlFilterJsx,
    disableContextSelection,
    hideTimeRange = false,
    extCtxOptions
  } = props;

  const options = useMemo<Option[]>(() => {
    const options: Option[] = [getTotalAlertsOption(incidentId), getUniqAlertsOption(incidentId)];

    impactedWidgetsList.forEach(listItem => {
      const { alertingSlices, diagnosticSlices, impactedWidgets } = listItem;

      impactedWidgets
        .filter(iw => iw.isPrimary)
        .forEach(iw => {
          const { id, name } = iw;
          options.push({
            label: name,
            value: id,
            data: {
              impactedWidgetContext: {
                alertingSlices,
                diagnosticSlices,
                impactedWidget: iw
              },
              incidentId,
              type: "impactedWidget",
              uniqueAlerts: false,
              monitoredSeriesContext: null
            }
          });
        });
    });

    if (extCtxOptions) {
      options.push(...extCtxOptions);
    }

    return options;
  }, [extCtxOptions, impactedWidgetsList, incidentId]);

  const [contextOpt, setContextOpt] = useState<Option>();

  useEffect(() => {
    if (!isLoading) {
      const defOption = defContext?.type ? getDefaultOption(incidentId, defContext) : options[2] || options[0];

      if (!defContext?.type && defOption) {
        onContextChange(defOption.data);
      }
      setContextOpt(defOption);
    }
  }, [defContext, incidentId, isLoading, onContextChange, options]);

  const onCtxChange = useCallback(
    (opt: Option) => {
      setContextOpt(opt);
      onContextChange(opt.data);
    },
    [onContextChange]
  );

  const label =
    contextOpt?.data?.type === "incident"
      ? "Total count of alerts"
      : contextOpt?.data?.impactedWidgetContext?.impactedWidget?.name;

  return (
    <VerticallyCenteredRow className="timeline-header">
      {!disableContextSelection && (
        <div className="transparent-select-container">
          <IncSelect
            alignment="row"
            errorText={error}
            hasError={Boolean(error)}
            isLoading={isLoading}
            isSearchable={false}
            label="Timeline"
            onChange={onCtxChange}
            options={options}
            value={contextOpt}
            wrapperClass="timeline-context-select"
          />
        </div>
      )}
      {disableContextSelection && (
        <div className="timeline-context-select inc-text-subtext-medium status-info">{contextOpt?.label || "None"}</div>
      )}

      <VerticallyCenteredRow className="display-none">
        <div className="inc-label-common marginRt10">Timeline</div>
        <div className="status-info inc-text-subtext-medium">{label}</div>
      </VerticallyCenteredRow>

      <VerticallyCenteredRow className="marginLtAuto">
        {addlFilterJsx}

        {/* Time range selection */}

        {!hideTimeRange && (
          <>
            {shouldShowReset && (
              <IncToolTip
                placement="top"
                titleText="Set page time range"
              >
                <VerticallyCenteredRow
                  className="inc-cursor-pointer status-info marginRt16"
                  onClick={resetTimeRange}
                >
                  <IncFaIcon
                    className="marginRt4"
                    iconName="chevron-left"
                    style={{ transform: "scale(0.75)" }}
                  />
                  <VerticallyCenteredRow>Back</VerticallyCenteredRow>
                </VerticallyCenteredRow>
              </IncToolTip>
            )}
            <TimeRangeSelector
              buttonDisplay
              onTimeSelect={setTimeRange}
              timeRange={timeRange}
            />
          </>
        )}
      </VerticallyCenteredRow>
    </VerticallyCenteredRow>
  );
};

const getDefaultOption = (incidentId: string, context?: IncidentTimelineContext): Option => {
  const { impactedWidgetContext, monitoredSeriesContext, uniqueAlerts, type } = context || {};

  const impactedWidget = type === "impactedWidget" ? impactedWidgetContext?.impactedWidget : null;
  if (impactedWidget) {
    return {
      label: impactedWidget.name,
      value: impactedWidget.id,
      data: context
    };
  }

  if (type === "monitoredSeries" && monitoredSeriesContext) {
    return {
      label: "Monitored series",
      value: "monitoredSeries",
      data: context
    };
  }

  if (type === "incident" && uniqueAlerts) {
    return getUniqAlertsOption(incidentId);
  }

  return getTotalAlertsOption(incidentId);
};

const getTotalAlertsOption = (incidentId: string): Option => ({
  label: "Total count of alerts",
  value: "alerts",
  data: {
    impactedWidgetContext: null,
    incidentId,
    type: "incident",
    uniqueAlerts: false,
    monitoredSeriesContext: null
  }
});

const getUniqAlertsOption = (incidentId: string): Option => ({
  label: "Unique alerts",
  value: "unique_alerts",
  data: {
    impactedWidgetContext: null,
    incidentId,
    type: "incident",
    uniqueAlerts: true,
    monitoredSeriesContext: null
  }
});

type Option = IncSelectOption<TimelineHeaderContext>;
