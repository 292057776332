import { IncFaIcon, IncToolTip } from "@inception/ui";
import React, { CSSProperties, FC, useCallback, useMemo } from "react";
import { VerticallyCenteredRow } from "../../../../../../components";
import {
  ActionTemplateElementGroup,
  ActionTemplateElementProp,
  ActionTemplateElementsResponse,
  ActionTemplateTextElement,
  ElementPropertyValues,
  SectionElementType,
  TemplateCanvas,
  TemplateCanvasSection
} from "../../../../../../services/api/operationalise";
import { SectionPropertiesEditor } from "../../section-properties";
import { MultiLineTemplateEditor } from "../../editors";
import { WEB_LINK_ELEMENT_ID } from "../../utils";

interface Props {
  textElements: ActionTemplateTextElement[];
  elementGroups: ActionTemplateElementGroup[];

  section: TemplateCanvasSection;
  properties: ActionTemplateElementProp[];
  onChange: (section: TemplateCanvasSection) => void;

  loopTooltip: string;
}

export const TablePropertiesRenderer: FC<Props> = props => {
  const { section, textElements, elementGroups, properties, onChange: setSection, loopTooltip } = props;

  const { title, content, childElementProp, title2, content2, childElementProp2, sectionId } = section;

  const designerTemplate = useMemo<ActionTemplateElementsResponse>(
    () => ({
      elementGroup: elementGroups,
      textElement: textElements.filter(el => el.elementId !== WEB_LINK_ELEMENT_ID),
      sectionElement: []
    }),
    [elementGroups, textElements]
  );

  const onPropertyChange = useCallback(
    (property: TextProperty, propProperty: PropProperty, nCanvas: TemplateCanvas) => {
      const content = nCanvas.section[0]?.content || "";
      const childElementProp = nCanvas.section[0]?.childElementProp || {};
      const prevChildElementProp = section.childElementProp || {};
      const nextChildElementProp = {
        ...prevChildElementProp,
        ...childElementProp
      };

      setSection({
        ...section,
        [propProperty]: nextChildElementProp,
        [property]: content
      });
    },
    [section, setSection]
  );

  const titleCanvas = useMemo<TemplateCanvas>(
    () => getCanvas(`${sectionId}_title`, title, childElementProp),
    [childElementProp, sectionId, title]
  );
  const onTitleChange = useCallback(
    (canvas: TemplateCanvas) => onPropertyChange("title", "childElementProp", canvas),
    [onPropertyChange]
  );

  const title2Canvas = useMemo<TemplateCanvas>(
    () => getCanvas(`${sectionId}_title2`, title2, childElementProp2),
    [childElementProp2, sectionId, title2]
  );
  const onTitle2Change = useCallback(
    (canvas: TemplateCanvas) => onPropertyChange("title2", "childElementProp2", canvas),
    [onPropertyChange]
  );

  const contentCanvas = useMemo<TemplateCanvas>(
    () => getCanvas(`${sectionId}_content`, content, childElementProp),
    [childElementProp, content, sectionId]
  );
  const onContentChange = useCallback(
    (canvas: TemplateCanvas) => onPropertyChange("content", "childElementProp", canvas),
    [onPropertyChange]
  );

  const content2Canvas = useMemo<TemplateCanvas>(
    () => getCanvas(`${sectionId}_content2`, content2, childElementProp2),
    [childElementProp2, content2, sectionId]
  );
  const onContent2Change = useCallback(
    (canvas: TemplateCanvas) => onPropertyChange("content2", "childElementProp2", canvas),
    [onPropertyChange]
  );

  return (
    <>
      <VerticallyCenteredRow>
        <table
          className="table table-dark inc-cursor-pointer"
          style={style}
        >
          <thead>
            <tr>
              <th></th>
              <th>
                <div className="inc-flex-column">
                  <div className="inc-label-common marginBt6">Column I</div>
                  <MultiLineTemplateEditor
                    designerTemplate={designerTemplate}
                    onChange={onTitleChange}
                    overrideLastSavedWithIncoming
                    templateCanvas={titleCanvas}
                  />
                </div>
              </th>
              <th>
                <div className="inc-flex-column">
                  <div className="inc-label-common marginBt6">Column II</div>
                  <MultiLineTemplateEditor
                    designerTemplate={designerTemplate}
                    onChange={onTitle2Change}
                    overrideLastSavedWithIncoming
                    templateCanvas={title2Canvas}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <VerticallyCenteredRow
                  className="inc-label-common"
                  style={{ height: "100%" }}
                >
                  Rows
                  <IncToolTip
                    placement="top"
                    showArrow
                    titleText={loopTooltip}
                  >
                    <VerticallyCenteredRow className="marginLt8">
                      <IncFaIcon iconName="redo" />
                    </VerticallyCenteredRow>
                  </IncToolTip>
                </VerticallyCenteredRow>
              </td>
              <td>
                <MultiLineTemplateEditor
                  designerTemplate={designerTemplate}
                  onChange={onContentChange}
                  overrideLastSavedWithIncoming
                  templateCanvas={contentCanvas}
                />
              </td>
              <td>
                <MultiLineTemplateEditor
                  designerTemplate={designerTemplate}
                  onChange={onContent2Change}
                  overrideLastSavedWithIncoming
                  templateCanvas={content2Canvas}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </VerticallyCenteredRow>

      <div className="marginBt12">
        <SectionPropertiesEditor
          onChange={setSection}
          properties={properties}
          section={section}
        />
      </div>
    </>
  );
};

const getCanvas = (
  sectionId: string,
  content: string,
  childElementProp: Record<string, ElementPropertyValues>
): TemplateCanvas => ({
  footer: [],
  header: [],
  section: [
    {
      sectionElementId: "rich-text-section",
      sectionId,
      type: SectionElementType.RICH_TEXT,
      content,
      childElementProp
    }
  ]
});

type TextProperty = "title" | "content" | "title2" | "content2";
type PropProperty = "childElementProp" | "childElementProp2";

const style: CSSProperties = {
  borderRadius: 8
};
