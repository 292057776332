import React, { FC, useMemo } from "react";
import { round } from "lodash";
import { generateId } from "../../core";
import { VerticallyCenteredRow } from "../flex-components";
import { GroupedBarEntry } from "./types";
import { GroupedBarsLegend } from "./Legend";
import { GroupedBarsHBar } from "./HBar";

interface Props {
  entries: GroupedBarEntry[];
  totalValue: number;
}

export const GroupedBars: FC<Props> = props => {
  const { entries, totalValue } = props;

  const baseId = useMemo(() => generateId(), []);

  const legends = useMemo(
    () =>
      entries.map((entry, idx) => {
        const key = [baseId, entry.id, idx, "legend"].join("-");
        return (
          <GroupedBarsLegend
            {...entry}
            key={key}
          />
        );
      }),
    [baseId, entries]
  );

  const bars = useMemo(
    () =>
      entries.map((entry, idx) => {
        const { id, value } = entry;
        const key = [baseId, id, idx, "bar"].join("-");
        let widthPer = (value / totalValue) * 100;
        widthPer = round(widthPer, 2);

        return (
          <GroupedBarsHBar
            key={key}
            {...entry}
            widthPer={widthPer}
          />
        );
      }),
    [baseId, entries, totalValue]
  );

  return (
    <div className="grouped-bars">
      <div className="grouped-bars--legends width-100">{legends}</div>

      <VerticallyCenteredRow className="width-100">{bars}</VerticallyCenteredRow>
    </div>
  );
};
