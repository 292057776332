import { IncButton, IncFaIcon } from "@inception/ui";
import { isEmpty, isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { cx } from "emotion";
import QueryBuilder from "react-querybuilder";
import { AddCircle } from "../../core/iconwrapper";
import {
  FieldPickerContextDTO,
  PickerFieldType,
  ConditionWithPickerType,
  LogicalOperator,
  DemoDataParams,
  compareUSFields,
  UserServiceField,
  FieldPickerOptionData
} from "../../services/api/explore";
import { LinkButton } from "../../components";
import { generateId, TimeRange } from "../../core";
import { FieldPickerModel } from "../../field-picker";
import {
  ConditionWithPickerTypeExpressionTree,
  FilterWithKey,
  QueryBuilderRule,
  QueryBuilderRuleGroup,
  SliceContext
} from "./types";
import {
  QueryFieldEditor,
  QueryOperatorEditor,
  QueryValueEditor,
  CombinatorSelector,
  AddRuleOrGroupAction,
  RemoveRuleOrGroupAction
} from "./QueryBuilderEditors";
import { ConditionsBuilderRow } from "./ConditionBuilderRow";
import { getConditionTreeForRuleGroup, getRuleGroupForConditionTree } from "./utils";

interface ConditionsBuilderProps {
  bizEntityType?: string;
  fieldPickerModel?: FieldPickerModel;
  onFieldPickerFetch?: (fieldPickerModel: FieldPickerModel) => void;

  fieldPickerContext?: FieldPickerContextDTO;
  demoDataParams?: DemoDataParams;
  fieldTypes: PickerFieldType[];

  filters: ConditionWithPickerType[];
  filterExpressionTree?: ConditionWithPickerTypeExpressionTree;
  useExpressionTree?: boolean;
  onChange: (
    filters: ConditionWithPickerType[],
    filtersValid: boolean,
    filterExpressions: ConditionWithPickerTypeExpressionTree
  ) => void;

  label?: string;
  labelAlign?: "vertical" | "horizontal";
  hideSelectLabels?: boolean;
  readOnly?: boolean;

  getLatestTimeRange?: () => TimeRange;
  sliceContext?: SliceContext;
  filterJoin?: string | JSX.Element;
}

export const ConditionsBuilder: React.FC<ConditionsBuilderProps> = props => {
  const {
    filters,
    onChange,
    label: labelStr,
    filterJoin,
    labelAlign = "vertical",
    hideSelectLabels = true,
    useExpressionTree,
    filterExpressionTree,
    readOnly = false,
    sliceContext,
    demoDataParams,
    ...restProps
  } = props;

  const filterExpressionTreeToUse = useMemo(
    () => (useExpressionTree ? filterExpressionTree : null),
    [filterExpressionTree, useExpressionTree]
  );

  const [filtersWithKey, setFiltersWithKey] = useState<Array<FilterWithKey<ConditionWithPickerType>>>(
    constructFiltersWithKeys(filters)
  );
  const [ruleGroup, setRuleGroup] = useState<QueryBuilderRuleGroup>(
    getRuleGroupForConditionTree(filterExpressionTreeToUse)
  );

  useEffect(() => {
    if (filterExpressionTreeToUse) {
      setRuleGroup(prevGroup => {
        const nextGroup = getRuleGroupForConditionTree(filterExpressionTreeToUse);
        return isEqual(prevGroup, nextGroup) ? prevGroup : nextGroup;
      });
    }
  }, [filterExpressionTreeToUse]);

  useEffect(() => {
    setFiltersWithKey(prev => constructFiltersWithKeys(filters, prev));
  }, [filters]);

  const updateConditionWithPickerType = useCallback(
    (nFilters: any) => {
      onChange(null, true, nFilters);
    },
    [onChange]
  );

  const updateFilters = useCallback(
    (nFilters: Array<FilterWithKey<ConditionWithPickerType>>) => {
      setFiltersWithKey([...nFilters]);

      const filtersValid = nFilters.reduce((acc, filterWithKey) => {
        const { filter } = filterWithKey || {};
        const { field } = filter || {};

        return acc && !isEmpty(field);
      }, true);

      if (filtersValid) {
        const filters = nFilters.map(({ filter }) => filter);
        onChange(filters, filtersValid, null);
      }
    },
    [onChange]
  );

  const onFilterChange = useCallback(
    (filter: ConditionWithPickerType, key: string) => {
      const _filters = filtersWithKey;
      _filters.forEach(x => {
        if (x.key === key) {
          x.filter = filter;
        }
      });
      updateFilters(_filters);
    },
    [filtersWithKey, updateFilters]
  );

  const onUpdateConditionTree = useCallback(
    (query: QueryBuilderRuleGroup) => {
      const conditionTree = getConditionTreeForRuleGroup(query);
      updateConditionWithPickerType(conditionTree);
    },
    [updateConditionWithPickerType]
  );

  const addRows = useCallback(
    (idx?: number) => {
      const _filters = filtersWithKey;
      const nFilter: FilterWithKey<ConditionWithPickerType> = {
        key: generateId(),
        filter: null
      };

      if (idx === undefined) {
        _filters.push(nFilter);
      } else {
        _filters.splice(idx + 1, 0, nFilter);
      }

      updateFilters(_filters);
    },
    [filtersWithKey, updateFilters]
  );

  const removeRow = useCallback(
    (key: string) => {
      let _filters = filtersWithKey;
      _filters = _filters.filter(x => x.key !== key);

      updateFilters(_filters);
    },
    [filtersWithKey, updateFilters]
  );

  const alignLabelHorizontally = labelAlign === "horizontal";

  const label = useMemo(
    () => (labelStr ? <div className="inc-text-subtext-medium">{labelStr}</div> : <></>),
    [labelStr]
  );

  const filtersExist = Boolean(filtersWithKey.length);

  const addFilterButton = useMemo(() => {
    const onAddRow = () => addRows();
    return filtersExist ? (
      <></>
    ) : (
      <LinkButton
        icon={AddCircle}
        onClick={onAddRow}
        style={{ width: "fit-content" }}
        text="Filter"
      />
    );
  }, [addRows, filtersExist]);

  const labelDiv = useMemo(() => {
    if (useExpressionTree) {
      return null;
    }

    if (alignLabelHorizontally) {
      return !filtersExist ? (
        <div className="inc-flex-row inc-flex-center-vertical inc-text-subtext-medium marginBt10">
          {label}
          <div className="marginLt10">{addFilterButton}</div>
        </div>
      ) : (
        <></>
      );
    }

    return <div className="marginBt10 inc-text-subtext-medium">{label}</div>;
  }, [useExpressionTree, alignLabelHorizontally, label, filtersExist, addFilterButton]);

  const addFilterHandle = useMemo(() => {
    if (alignLabelHorizontally && !filtersExist) {
      return <></>;
    }

    return addFilterButton;
  }, [addFilterButton, alignLabelHorizontally, filtersExist]);

  const getFilterPrefix = useCallback(
    (idx: number) => {
      let content;
      if (alignLabelHorizontally && idx === 0) {
        content = label;
      } else if (filterJoin) {
        content = filterJoin;
      }

      return content ? <div className="marginRt10 filter-join">{content}</div> : null;
    },
    [alignLabelHorizontally, filterJoin, label]
  );

  const onQueryExprChange = useCallback(
    (ruleGroup: QueryBuilderRuleGroup) => {
      setRuleGroup(ruleGroup);
      onUpdateConditionTree(ruleGroup);
    },
    [onUpdateConditionTree]
  );

  const onAddGroup = useCallback((ruleGroup: QueryBuilderRuleGroup) => {
    ruleGroup.combinator = LogicalOperator.AND;
    ruleGroup.rules = [
      {
        field: null,
        operator: "=",
        value: ""
      }
    ];

    return ruleGroup;
  }, []);

  const onAddRule = useCallback(
    (rule: QueryBuilderRule): QueryBuilderRule => ({
      ...rule,
      field: null,
      operator: "=",
      value: ""
    }),
    []
  );

  const conditionRowsJsx = useMemo(
    () =>
      filtersWithKey.map((filterWithKey, i) => {
        const hasAddRow = !readOnly;
        const { key, filter } = filterWithKey;
        const filterPrefix = getFilterPrefix(i);
        const className = cx("condition-builder-row-wrapper", {
          "has-filter-join": Boolean(filterPrefix),
          "has-add-row": hasAddRow
        });
        const onAddRow = () => addRows(i);
        const onChange = (eFilter: ConditionWithPickerType) => onFilterChange(eFilter, key);
        const onDelete = () => removeRow(key);
        const showLabel = i === 0 && !hideSelectLabels;
        const dependentFilters = filtersWithKey
          .filter(({ key: otherKey }) => otherKey !== key)
          .map(({ filter }) => filter); // filters out current filter

        return (
          <div
            className={className}
            key={key}
          >
            {filterPrefix}

            <ConditionsBuilderRow
              demoDataParams={demoDataParams}
              dependentFilters={dependentFilters}
              filter={filter}
              onChange={onChange}
              onDelete={onDelete}
              readOnly={readOnly}
              removable={hasAddRow}
              showLabel={showLabel}
              sliceContext={sliceContext}
              {...restProps}
            />

            {hasAddRow && (
              <IncButton
                className="add-row-button"
                color="link"
                iconType="icon"
                onClick={onAddRow}
              >
                <IncFaIcon iconName="plus-circle" />
              </IncButton>
            )}
          </div>
        );
      }),
    [
      addRows,
      demoDataParams,
      filtersWithKey,
      getFilterPrefix,
      hideSelectLabels,
      onFilterChange,
      readOnly,
      removeRow,
      restProps,
      sliceContext
    ]
  );

  return (
    <div className="conditions-builder">
      {labelDiv}
      {useExpressionTree && (
        <div className="inc-query-builder condition-builder-row">
          <QueryBuilder
            context={props}
            controlElements={{
              valueEditor: QueryValueEditor,
              fieldSelector: QueryFieldEditor,
              operatorSelector: QueryOperatorEditor,
              combinatorSelector: CombinatorSelector,
              addRuleAction: AddRuleOrGroupAction,
              addGroupAction: AddRuleOrGroupAction,
              removeRuleAction: RemoveRuleOrGroupAction,
              removeGroupAction: RemoveRuleOrGroupAction
            }}
            onAddGroup={onAddGroup}
            onAddRule={onAddRule}
            onQueryChange={onQueryExprChange}
            query={ruleGroup}
          />
        </div>
      )}
      {!useExpressionTree && conditionRowsJsx}
      {addFilterHandle}
    </div>
  );
};

const constructFiltersWithKeys = (
  filters: ConditionWithPickerType[],
  prevFiltersWithKeys: Array<FilterWithKey<ConditionWithPickerType>> = []
): Array<FilterWithKey<ConditionWithPickerType>> => {
  if (!isEmpty(filters)) {
    return filters.map((filter, i) => {
      const prevKey = prevFiltersWithKeys?.find(
        (pf, index) => compareFieldsOfFilters(filter?.field, pf?.filter?.field) && i === index
      )?.key;
      const key = prevKey || generateId();

      return {
        key,
        filter
      };
    });
  }
  return [];
};

const compareFieldsOfFilters = (fieldA: FieldPickerOptionData, fieldB: FieldPickerOptionData): boolean => {
  if (!fieldA || !fieldB) {
    return false;
  }
  const { type: typeA, payload: payloadA } = fieldA || {};
  const { type: typeB, payload: payloadB } = fieldB || {};
  if (typeA !== typeB) {
    return false;
  }
  if (typeA === "userServiceField") {
    return compareUSFields(payloadA as UserServiceField, payloadB as UserServiceField);
  }
  return isEqual(fieldA, fieldB);
};
