import { downloadBlobFile } from "../../../../utils";
import { ResultsTableDataColumn, ResultsTableDataEntry } from "./types";

type Result = {
  success: boolean;
  message: string;
};

export const downloadResultsAsCsv = (
  columns: ResultsTableDataColumn[],
  data: ResultsTableDataEntry[],
  fileName: string
) => {
  const result: Result = {
    message: "",
    success: true
  };

  const csvLines: string[][] = [];
  const headers: string[] = [];

  const columnsToInclude = columns.filter(c => !c.excludeInDownload);
  columnsToInclude.forEach(column => {
    const { accessor, excludeInDownload, getDataFn, header } = column;

    if (!excludeInDownload) {
      headers.push(header as string);

      data.forEach((entry, rowIdx) => {
        const csvArr = csvLines[rowIdx] || [];
        const value = entry[accessor] as any;
        const saveValue = getDataFn ? getDataFn(value, entry) : value;

        csvArr.push(`"${saveValue}"`);

        csvLines[rowIdx] = csvArr;
      });
    }
  });

  csvLines.unshift(headers);

  const csvString = csvLines.map(line => line.join(",")).join("\n");
  const blob = new Blob([csvString], {
    type: "text/csv"
  });

  fileName = `Results for ${fileName}.csv`;
  downloadBlobFile(blob, fileName);

  return result;
};
