import { defaults, isEmpty, omit, isArray } from "lodash";
import { VariableResolverResult } from "../dashboard-variables/resolvers/CustomVariableResolver";
import { VariableType, CohortVariableModel, CohortFilterType, VAR_URL_PREFIX } from "../models/VariableModel";
import { DataType, FieldDataType, TimeRange, logger } from "../../core";
import {
  EntityOperation,
  BizField,
  FieldSourceType,
  MetricSourceType,
  BizServiceMetric,
  UserServiceMetric,
  UserServiceField,
  EntityField,
  CohortEntityFilterField,
  CohortEntityFilterFieldType,
  CohortEntityFilter
} from "../../services/api/explore";
import entityStoreApiService from "../../services/api/EntityStoreApiService";
import {
  EntityAggregationValue,
  EntityAggregationSuggestion,
  EntityAggregationResponse
} from "../../services/api/types";
import { isErrorResponse } from "../../services/api/utils";
import {
  getFinalValuesAndOperatorForDataType,
  processAggregationsResponse,
  getAggregationRequestPayload
} from "../widgets/utils";
import { getFilterExprQueryParam, QueryParamDTO } from "../../utils/QueryParamUtils";
import VariableImpl, { VariableAggRequestPayload } from "./VariableImpl";

const DEFAULTS: CohortVariableModel = {
  id: "",
  name: "",
  multi: false,
  value: null,
  defaultValue: "",
  type: VariableType.Cohort,
  allValue: "",
  includeAll: false,
  label: "",
  hide: true,
  cohortId: "",
  entityTypeId: "",
  depFieldNames: [],
  field: null,
  fieldType: null,
  filterType: CohortFilterType.Entity,
  isPinned: false,
  isLocked: false
};

type OptionsData = {
  options: EntityAggregationValue[];
  error: string;
};

export default class CohortVariableImpl extends VariableImpl implements CohortVariableModel {
  id: string;
  entityTypeId: string;
  cohortId: string;
  depFieldNames: string[];
  field: CohortEntityFilterField;
  dataType: DataType | "NA";
  subType: FieldDataType;
  operator: EntityOperation;
  fieldType: CohortEntityFilterFieldType;
  filterType: CohortFilterType;
  bizField: BizField;
  kind: string;
  filterOptionsData: OptionsData;
  isPinned: boolean;
  isLocked: boolean;
  aggSuggestion: EntityAggregationSuggestion;

  readonly type = VariableType.Cohort;
  private readonly highCardinalityProperties = ["Id", "Name"];

  constructor(model: Partial<CohortVariableModel>) {
    super(model);
    this.assign(model);
  }
  depFieldIds: string[];

  protected assign(model: Partial<CohortVariableModel>) {
    const cvModel: Partial<CohortVariableModel> = {};
    defaults(cvModel, model, DEFAULTS);
    const {
      cohortId,
      entityTypeId,
      depFieldNames,
      field,
      fieldType,
      filterType,
      operator = "eq",
      value,
      isPinned,
      isLocked,
      aggSuggestion
    } = cvModel;

    this.cohortId = cohortId;
    this.entityTypeId = entityTypeId;
    this.depFieldNames = depFieldNames;
    this.filterType = filterType;
    this.isPinned = isPinned;
    this.isLocked = isLocked;

    this.assignField(field, fieldType);

    this.operator = operator;
    super.assign(cvModel);
    this.helpText = "Use a cohort filter";
    this.setDependencies();

    if (value) {
      const values = Array.isArray(value) ? value : [value];
      if (this.dataType !== "NA" && this.dataType !== "BOOLEAN") {
        const { operator, values: fValues } = getFinalValuesAndOperatorForDataType(values, this.dataType);
        this.operator = operator;
        this.value = fValues;
      }
    }

    this.resetOptions();
    this.aggSuggestion = aggSuggestion;
  }

  isCohortFilter() {
    return this.filterType === CohortFilterType.Cohort;
  }

  update(vModel: Partial<CohortVariableModel>) {
    this.assign(vModel);
  }

  setDefaultValue(value: string) {
    this.defaultValue = value;
  }

  setOperator(operator: EntityOperation) {
    this.operator = operator;
  }

  setValue(value: string | string[]) {
    this.value = value;
  }

  getPredicate(enableEmptyPredicates = false): CohortEntityFilter {
    const value = this.getValue();
    const predicate = {
      fieldType: this.fieldType,
      predicate: {
        bizField: this.field as BizField,
        op: this.operator,
        value: value?.value || "",
        values: value?.values || []
      }
    };

    if (enableEmptyPredicates) {
      return predicate;
    } else {
      return value ? predicate : null;
    }
  }

  getFilterQueryParams(): QueryParamDTO {
    const value = this.getValue();
    if (value) {
      const name = `${this.entityTypeId}.${this.name}`;
      return getFilterExprQueryParam(name, this.operator, this.value, VAR_URL_PREFIX);
    }
    return null;
  }

  async resolveValues(timeRange: TimeRange): Promise<VariableResolverResult> {
    if (!this.isCohortFilter() && this.isPinned) {
      await this.fetchSuggestions(timeRange);
      if (this.aggSuggestion) {
        const { aggValues, error } = await this.fetchAggregationValues(timeRange);
        this.filterOptionsData = {
          options: aggValues,
          error
        };
      }
    }

    return {
      ...this.optionsData
    };
  }

  updateValues(aggResponse: EntityAggregationResponse, errorMessage: string) {
    const { aggValues, error } = processAggregationsResponse(aggResponse, errorMessage, this.name);
    this.filterOptionsData = {
      options: aggValues,
      error
    };
  }

  getOptionsData() {
    return this.filterOptionsData;
  }

  validate() {
    const base = super.validate();
    let hasError = false;
    const { messages } = base;

    if (!this.cohortId) {
      hasError = true;
      messages["cohortId"] = "Cohort cannot be empty";
    }

    if (!this.entityTypeId) {
      hasError = true;
      messages["entityType"] = "Entity type cannot be empty";
    }

    return {
      hasError: hasError || base.hasError,
      messages
    };
  }

  adjustValueAndDefaultValue() {
    // No-op. This is done to prevent the default behaviour that changes value based on optionsData
  }

  getSaveModel(): CohortVariableModel {
    const model = super.getSaveModel();
    const omitProps = ["filterOptionsData", "kind", "aggSuggestion"];
    const cModel = omit(model, omitProps);
    return cModel as CohortVariableModel;
  }

  private getValue() {
    let values: string[] = [];
    let value = "";
    const arrTypes: EntityOperation[] = ["in", "range"];
    let valueExists = false;
    if (arrTypes.includes(this.operator)) {
      values = isArray(this.value) ? this.value : [this.value];
      valueExists = (values || []).reduce((acc, val) => acc || !isEmpty(val), false as boolean);
    } else {
      value = this.value as string;
      valueExists = !isEmpty(value);
    }
    return valueExists
      ? {
          value,
          values
        }
      : null;
  }

  private async fetchAggregationValues(timeRange: TimeRange) {
    const { startMillis, endMillis } = this.getRequestTimeRange(timeRange);
    const [aggRequest, isValid] = getAggregationRequestPayload(this.aggSuggestion);

    const result = {
      aggValues: [] as EntityAggregationValue[],
      error: ""
    };

    if (isValid) {
      const aggregationsCallback = await entityStoreApiService.getEntityAggregation(
        startMillis,
        endMillis,
        this.entityTypeId,
        this.cohortId,
        [aggRequest]
      );

      const { data: aggData, status, statusText } = await aggregationsCallback;

      const isError = isErrorResponse(status);
      if (isError) {
        result.error = statusText;
      } else {
        const { aggValues, error } = processAggregationsResponse(aggData, null, this.name);
        result.aggValues = aggValues;
        result.error = error;
      }
    } else {
      result.error = "No aggregator found for this field";
    }

    return result;
  }

  private async fetchSuggestions(timeRange: TimeRange) {
    const { aggSuggestion } = this;

    if (this.entityTypeId && !aggSuggestion) {
      const { startMillis, endMillis } = this.getRequestTimeRange(timeRange);

      const shouldCallSuggest = this.preSuggestAggregationCheck();
      if (!shouldCallSuggest) {
        return;
      }

      const suggestionsCallback = entityStoreApiService.suggestEntityAggregation(
        startMillis,
        endMillis,
        this.entityTypeId,
        this.cohortId,
        [this.name]
      );

      try {
        const { data: cohortResponse, status, statusText } = await suggestionsCallback;

        const agg = cohortResponse.suggestedAggregations || {};
        const isError = isErrorResponse(status);
        const error = isError ? statusText : "";

        if (isError || !agg) {
          this.filterOptionsData.error = error;
          logger.error("Cohort variable suggestions", `Error fetching suggestions for ${this.name}`, error);
        } else {
          this.aggSuggestion = agg[this.name];
        }
      } catch (e) {
        this.filterOptionsData.error = e.message;
        logger.error("Cohort variable suggestions", `Error fetching suggestions for ${this.name}`, e);
      }
    }
  }
  preSuggestAggregationCheck() {
    const bizField = this.field as BizField;
    const propName = bizField?.entityField?.propName;
    const isBizEntityField = this.fieldType === "bizEntityField";

    const shouldSkipSuggAggr = isBizEntityField && this.highCardinalityProperties.includes(propName);
    if (shouldSkipSuggAggr) {
      this.aggSuggestion = {
        kind: "_str",
        field: this.name,
        description: "Cardinality=1 by default",
        aggregationMeta: {
          cardinality: {
            ratio: 1,
            value: ""
          }
        },
        suggestedAggregationOperator: {}
      };
      return false;
    }
    return true;
  }

  async getAggregationRequest(timeRange: TimeRange): Promise<VariableAggRequestPayload> {
    if (this.isPinned) {
      await this.fetchSuggestions(timeRange);
      if (this.aggSuggestion) {
        if (!this.isCohortFilter() && this.isPinned) {
          const [aggRequest, isValid] = getAggregationRequestPayload(this.aggSuggestion);
          if (isValid) {
            const { startMillis, endMillis } = this.getRequestTimeRange(timeRange);

            return {
              startMillis,
              aggRequest,
              cohortId: this.cohortId,
              entityTypeId: this.entityTypeId,
              mockData: false,
              endMillis,
              variableName: this.name
            };
          } else {
            logger.error(
              "Cohort variable",
              "Error getting aggregation request",
              `No aggregator found for ${this.name}`
            );
            return super.getAggregationRequest(timeRange);
          }
        } else {
          logger.debug("Cohort variable", "Skipping aggregation request", `FieldName: ${this.name}`);
          return super.getAggregationRequest(timeRange);
        }
      } else {
        logger.error(
          "Cohort variable",
          "Error getting suggest aggregation request",
          `No aggregator found for ${this.name}`
        );
        return super.getAggregationRequest(timeRange);
      }
    } else {
      logger.error("Cohort variable", "Skipping aggregation request", `Filter ${this.name} is not pinned`);
      return super.getAggregationRequest(timeRange);
    }
  }

  private setDependencies() {
    this.dependencies = [...this.depFieldNames];
  }

  private assignField(
    field: BizField | UserServiceField | BizServiceMetric | UserServiceMetric,
    fieldType: FieldSourceType | MetricSourceType
  ) {
    this.field = field;
    this.fieldType = fieldType;

    let entityField: EntityField;

    switch (fieldType) {
      case "bizEntityField": {
        const bizField = field as BizField;
        entityField = bizField?.entityField || null;
        break;
      }

      case "userServiceField": {
        const usField = field as UserServiceField;
        entityField = usField.entityField;
        break;
      }

      default:
        break;
    }

    this.dataType = entityField?.propType || "NA";
    this.subType = entityField?.kindDescriptor?.type || "not_set";

    if (fieldType === "bizEntityField") {
      this.bizField = field as BizField;
    }
  }

  private resetOptions() {
    this.optionsData = {
      error: "",
      data: []
    };

    this.filterOptionsData = {
      options: [],
      error: ""
    };
  }

  private getRequestTimeRange(timeRange: TimeRange) {
    logger.debug("Cohort variable", `Update request for ${this.name} with time range `, timeRange);
    /** This is temporaruy change due to entity-platform issue.
     * This should be changed to use global time range.
     */
    const startMillis = -60;
    const endMillis = 0;

    return {
      startMillis,
      endMillis
    };
  }
}
