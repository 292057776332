import {
  IncCheckbox,
  IncFaIcon,
  IncFaIconName,
  IncRTable,
  IncRTableProps,
  IncSelect,
  TableDataColumn
} from "@inception/ui";
import TextWithInfo from "@inception/ui/src/components/HorizontalBarWithInfo/TextWithInfoIcon";
import { cloneDeep, isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { IncDataTypeSelect } from "../../../../../platform/components/data-type";
import { DataTypeOption, getAllDataTypeOptions, MappingType } from "../../../../../platform/components/data-type/utils";
import { FieldPrimType } from "../../../../../platform/core";
import { useNotifications } from "../../../../../platform/core/hooks/useNotificationHook";
import {
  SourceFieldMapping,
  PreviewDataObj,
  UiBizfieldDetailsWithType
} from "../../../../../platform/services/api/entity-mapping";
import { PredefinedFieldType, UserServiceFieldDef } from "../../../../../platform/services/api/event-mapping";
import {
  FieldTransformationConfig,
  TransformType
} from "../../../../../platform/transformation/transform-config/TransformConfig";
import { flattenObject, pluralizeWord } from "../../../../../platform/utils/Utils";
import FieldTransformationModal from "../config-forms/components/FieldTransformationConfigModal";
import { FieldPreviewDataModal } from "./FieldPreviewModal";
import { CategoryOption, PredefinedFieldTypeOption, SampleValueType, SelectorGridFieldDef } from "./types";
import { getUserServiceFieldDef } from "./utils";

type FieldSelectorGridProps = {
  configuredStreamId: string;
  mode: "add" | "edit" | "read-only";
  userServiceFields: string[];
  initialFieldsSelection: UserServiceFieldDef[];
  commonFields?: UserServiceFieldDef[];
  fieldNameToBizFieldDetails: Map<string, UiBizfieldDetailsWithType>;
  onFieldChange?: (fields: SelectorGridFieldDef[]) => void;
  userServicePreviewData: PreviewDataObj;
  gridPageSize?: number;
  gridShowFilterSearch?: boolean;
  onUnSavedChange?: (value: boolean) => void;
};

const FieldSelectorGrid: React.FC<FieldSelectorGridProps> = props => {
  const {
    mode,
    initialFieldsSelection,
    userServiceFields,
    fieldNameToBizFieldDetails,
    userServicePreviewData,
    commonFields,
    configuredStreamId,
    gridPageSize,
    gridShowFilterSearch = true,
    onUnSavedChange,
    onFieldChange
  } = props;

  // const { dispatch, state } = useUserServiceMappingStore() || {};
  // This is union of userServiceFields and initialFieldsSelection.
  // all non-selected fields will be set based on fieldNameToBizFieldDetails available from preview
  // Data type, category changes are reflected in this
  const [allFields, setAllFields] = useState<SelectorGridFieldDef[]>([]);

  // Keeps track of only the selected fields
  const [changeDetected, setChangeDetected] = useState<boolean>(false);

  const [searchString, setSearchString] = useState<string>("");
  const [pageNumber, setPageNumber] = useState(1);

  const [showTransformationConfig, setShowTransformationConfig] = useState<boolean>(false);
  const [fieldToTransform, setFieldToTransform] = useState<SelectorGridFieldDef>(null);
  const [previewField, setPreviewField] = useState<SelectorGridFieldDef>(null);

  const { notifyError } = useNotifications();

  const { formatMessage } = useIntl();

  const fieldSampleValues = useMemo(() => {
    const sampleVals: Record<string, SampleValueType[]> = {};
    (userServicePreviewData?.jsonRecords || []).forEach(x => {
      const parsedJson: Record<string, SampleValueType> = JSON.parse(x);
      const flattenedObject = flattenObject(parsedJson);
      for (const k in flattenedObject) {
        if (sampleVals[k]) {
          sampleVals[k].push(flattenedObject[k]);
        } else {
          sampleVals[k] = [flattenedObject[k]];
        }
      }
    });
    return sampleVals;
  }, [userServicePreviewData]);

  const allDataTypeOptions = useMemo(() => getAllDataTypeOptions(MappingType.event), []);

  const getUniqueFieldName = useCallback(
    (rowData: UserServiceFieldDef) => `${rowData.fieldName}${rowData?.predefinedFieldType || ""}`,
    []
  );

  const onFieldChecked = useCallback(
    (rowData: UserServiceFieldDef, checked: boolean) => {
      const clonedAllFields = cloneDeep(allFields);
      const fieldDef = clonedAllFields.find(f => getUniqueFieldName(f) === getUniqueFieldName(rowData));
      // add it to the selectedFields
      if (checked) {
        fieldDef.selected = true;
      } else {
        // Remove from selectedFields
        fieldDef.selected = false;
      }

      setAllFields(clonedAllFields);
      setChangeDetected(true);
    },
    [allFields, getUniqueFieldName]
  );

  const onGlobalFilterChange = useCallback((searchStr: string) => {
    setPageNumber(1);
    setSearchString(searchStr);
  }, []);

  const onPageChange = useCallback((newPageRows: SelectorGridFieldDef[], newPageNum: number) => {
    setPageNumber(newPageNum);
  }, []);

  const getCheckedStatus = useCallback(
    (rowData: UserServiceFieldDef): boolean => {
      const elem = allFields.find(s => getUniqueFieldName(s) === getUniqueFieldName(rowData) && s.selected);
      return !!elem;
    },
    [allFields, getUniqueFieldName]
  );

  const onDataTypeSelected = useCallback(
    (rowData: UserServiceFieldDef, dataTypeOption: DataTypeOption) => {
      const clonedAllFields = cloneDeep(allFields);
      const fieldDef = clonedAllFields.find(f => getUniqueFieldName(f) === getUniqueFieldName(rowData));
      if (fieldDef) {
        fieldDef.datatype = dataTypeOption.value as FieldPrimType;
        fieldDef.subtype = dataTypeOption?.kindDescriptor?.type || "none";
        fieldDef.predefinedFieldType = null;
        fieldDef.category = "BUSINESS";
        setAllFields(clonedAllFields);
        setChangeDetected(true);
      }
    },
    [allFields, getUniqueFieldName]
  );

  const getDataTypeSelectionOption = useCallback(
    (rowData: UserServiceFieldDef): DataTypeOption => {
      const fieldDef = allFields.find(f => getUniqueFieldName(f) === getUniqueFieldName(rowData));
      if (fieldDef) {
        //if fieldDef has subtype, then DataTypeSelector should show corresponding value/label
        const selectedDataType = allDataTypeOptions.find(o => {
          if (fieldDef.subtype && fieldDef.subtype !== "none") {
            return o.value === fieldDef.datatype && o?.kindDescriptor?.type === fieldDef.subtype;
          }
          return o.value === fieldDef.datatype;
        });
        return selectedDataType ?? allDataTypeOptions[0];
      }

      return null;
    },
    [allDataTypeOptions, allFields, getUniqueFieldName]
  );

  const onCategorySelected = useCallback(
    (rowData: UserServiceFieldDef, category: CategoryOption) => {
      const clonedAllFields = cloneDeep(allFields);
      const fieldDef = clonedAllFields.find(f => getUniqueFieldName(f) === getUniqueFieldName(rowData));
      if (fieldDef) {
        fieldDef.category = category.value;
        fieldDef.predefinedFieldType = category.value === "BUSINESS" ? null : fieldDef.predefinedFieldType;
        setAllFields(clonedAllFields);
        setChangeDetected(true);
      }
    },
    [allFields, getUniqueFieldName]
  );

  const getCategorySelection = useCallback(
    (rowData: UserServiceFieldDef): CategoryOption => {
      const fieldDef = allFields.find(f => getUniqueFieldName(f) === getUniqueFieldName(rowData));
      if (fieldDef) {
        const option = categoryOptions.find(co => co.value === fieldDef.category);
        return option;
      }

      return null;
    },
    [allFields, getUniqueFieldName]
  );

  const onPredefinedFieldTypeSelected = useCallback(
    (rowData: UserServiceFieldDef, predefinedFieldTypeOption: PredefinedFieldTypeOption) => {
      const clonedAllFields = cloneDeep(allFields);
      if (rowData.datatype === predefinedFieldTypeOption.kind || isEmpty(predefinedFieldTypeOption.kind)) {
        const userServiceFieldMapped = clonedAllFields.find(
          f => f.predefinedFieldType === predefinedFieldTypeOption.value
        );
        const fieldDef = clonedAllFields.find(f => getUniqueFieldName(f) === getUniqueFieldName(rowData));
        if (fieldDef && !userServiceFieldMapped) {
          fieldDef.predefinedFieldType = predefinedFieldTypeOption.value.trim() as PredefinedFieldType;
          fieldDef.category = predefinedFieldTypeOption.categoryType ?? fieldDef.category;
          setAllFields(clonedAllFields);
          setChangeDetected(true);
        } else {
          setAllFields(clonedAllFields);
          if (userServiceFieldMapped?.fieldName !== fieldDef.fieldName) {
            notifyError(
              formatMessage(
                { id: "field.type.already.mapped" },
                {
                  0: predefinedFieldTypeOption.value,
                  1: userServiceFieldMapped.fieldName
                }
              )
            );
          }
        }
      } else {
        setAllFields(clonedAllFields);
        notifyError(
          formatMessage(
            { id: "field.type.not.matched" },
            {
              0: rowData.datatype,
              1: predefinedFieldTypeOption.kind
            }
          )
        );
      }
    },
    [allFields, formatMessage, getUniqueFieldName, notifyError]
  );

  const getPredefinedFieldTypeSelection = useCallback(
    (rowData: UserServiceFieldDef): PredefinedFieldTypeOption => {
      const fieldDef = allFields.find(f => getUniqueFieldName(f) === getUniqueFieldName(rowData));
      if (fieldDef) {
        const option = predefinedFieldTypeOptions.find(co => co.value === fieldDef.predefinedFieldType);
        if (!option && fieldDef.predefinedFieldType) {
          return {
            kind: fieldDef.datatype,
            label: fieldDef.predefinedFieldType,
            value: fieldDef.predefinedFieldType,
            categoryType: fieldDef.category
          };
        }
        return option;
      }

      return null;
    },
    [allFields, getUniqueFieldName]
  );

  const onEditClick = useCallback((rowData: SelectorGridFieldDef) => {
    setFieldToTransform(rowData);
    setShowTransformationConfig(true);
  }, []);

  const onSaveTransformation = useCallback(
    (rowData: UserServiceFieldDef, fieldTransformationConfig: FieldTransformationConfig<TransformType>) => {
      const clonedAllFields = cloneDeep(allFields);
      const fieldDef = clonedAllFields.find(f => getUniqueFieldName(f) === getUniqueFieldName(rowData));
      if (fieldDef) {
        fieldDef.fieldMapping.valueMapping.dataTransformations = fieldTransformationConfig
          ? [fieldTransformationConfig]
          : [];
        fieldDef.datatype = fieldTransformationConfig?.outputType ?? fieldDef.datatype;
        fieldDef.subtype = null;
        setAllFields(clonedAllFields);
        setChangeDetected(true);
      }
      setShowTransformationConfig(false);
    },
    [allFields, getUniqueFieldName]
  );

  const recordsStrFn = useCallback(
    (filterCount: number) => {
      const selectedLength = allFields?.length ? allFields.filter(f => f.selected).length : null;
      return selectedLength
        ? `${selectedLength} of ${pluralizeWord("field", filterCount)} selected`
        : `${pluralizeWord("field", filterCount)} found`;
    },
    [allFields]
  );

  const columns = useMemo(() => {
    const cols: Array<TableDataColumn<SelectorGridFieldDef>> = [];

    if (mode !== "read-only") {
      cols.push({
        header: "",
        accessor: "fieldName",
        sortable: false,
        width: "8%",
        renderer: (cellData: any, rowData: SelectorGridFieldDef) => (
          <IncCheckbox
            checked={getCheckedStatus(rowData)}
            onChange={(_e, checked) => onFieldChecked(rowData, checked)}
          ></IncCheckbox>
        )
      });
    }

    cols.push({
      header: "Event Column Name",
      accessor: "fieldName",
      sortable: false,
      width: mode !== "read-only" ? "28%" : "36%",
      renderer: (cellData: any, rowData: SelectorGridFieldDef) => (
        <div className="inc-flex-row">
          <TextWithInfo
            helpText={mode !== "read-only" ? "Show Preview" : ""}
            onInfoClick={() => setPreviewField(rowData)}
            text={rowData.fieldName}
          />
        </div>
      )
    });

    cols.push({
      header: "Data Type",
      accessor: "fieldName",
      sortable: false,
      width: "18%",
      renderer: (cellData: any, rowData: SelectorGridFieldDef) => {
        if (mode === "read-only") {
          return getDataTypeSelectionOption(rowData)?.label || "String";
        } else {
          return (
            <div style={{ width: "155px" }}>
              <IncDataTypeSelect
                label={""}
                onSelect={(selection: DataTypeOption) => onDataTypeSelected(rowData, selection)}
                selected={getDataTypeSelectionOption(rowData)}
              />
            </div>
          );
        }
      }
    });

    cols.push({
      header: "Category",
      accessor: "fieldName",
      sortable: false,
      width: "18%",
      renderer: (cellData: any, rowData: SelectorGridFieldDef) => {
        if (mode === "read-only") {
          return getCategorySelection(rowData)?.label || "Business";
        } else {
          return (
            <div style={{ width: "120px" }}>
              <IncSelect<CategoryOption>
                isSearchable={false}
                label={""}
                onChange={selected => onCategorySelected(rowData, selected)}
                options={categoryOptions}
                value={getCategorySelection(rowData)}
              />
            </div>
          );
        }
      }
    });

    cols.push({
      header: "Bicycle Event Field",
      accessor: "fieldName",
      sortable: false,
      width: "18%",
      renderer: (cellData: any, rowData: SelectorGridFieldDef) => {
        if (mode === "read-only") {
          return getPredefinedFieldTypeSelection(rowData)?.label || "None";
        } else {
          const predefinedFieldVal = getPredefinedFieldTypeSelection(rowData);
          const validRegex = /^[A-Za-z0-9_-]*$/;
          return (
            <div style={{ width: "120px" }}>
              <IncSelect<PredefinedFieldTypeOption>
                allowCreate
                errorText={formatMessage({
                  id: "user.service.mapped.name.err.text"
                })}
                hasError={predefinedFieldVal?.value ? !validRegex.test(predefinedFieldVal?.value) : false}
                isDisabled={!rowData.selected}
                isSearchable={true}
                label={""}
                onChange={selected => onPredefinedFieldTypeSelected(rowData, selected)}
                options={predefinedFieldTypeOptions}
                value={predefinedFieldVal}
              />
            </div>
          );
        }
      }
    });

    cols.push({
      header: "Actions",
      accessor: "fieldName",
      sortable: false,
      width: "10%",
      renderer: (cellData: any, rowData: SelectorGridFieldDef) => {
        const disabled = !rowData.selected || mode === "read-only";
        const hasTransformations = rowData.fieldMapping.valueMapping?.dataTransformations?.length > 0;
        const icon: IncFaIconName = hasTransformations ? "gears" : "gear";

        return (
          <div
            className={`action-icon ${disabled ? "disableClick" : ""}`}
            onClick={() => onEditClick(rowData)}
          >
            <IncFaIcon iconName={icon} />
          </div>
        );
      }
    });

    return cols;
  }, [
    formatMessage,
    getCategorySelection,
    getCheckedStatus,
    getDataTypeSelectionOption,
    getPredefinedFieldTypeSelection,
    mode,
    onCategorySelected,
    onDataTypeSelected,
    onEditClick,
    onFieldChecked,
    onPredefinedFieldTypeSelected
  ]);

  useEffect(() => {
    const fieldsData: SelectorGridFieldDef[] = [];

    const set: Set<string> = new Set();

    // section to identify common fields
    (commonFields || []).forEach(s => {
      set.add(getUniqueFieldName(s));
    });

    // Push the initial field selections in the array first
    (initialFieldsSelection || []).forEach(s => {
      set.add(getUniqueFieldName(s));
      const valueMapping = s?.fieldMapping?.valueMapping;
      fieldsData.push({
        ...s,
        selected: true,
        fieldMapping: {
          valueMapping: {
            ...valueMapping,
            //below is a hack to get jsonPath to show in transformation modal
            //this is done because already saved rules not have jsonPath to the fields
            jsonPath: valueMapping.jsonPath || fieldNameToBizFieldDetails?.get(s.fieldName)?.valueMapping.jsonPath || ""
          }
        },
        sampleVals: fieldSampleValues[s.fieldName]
      });
    });

    // add the other/all fields with defaults to empty
    //below should mutate allFields only one (first) time when fieldNameToBizFieldDetails exists
    if (fieldNameToBizFieldDetails?.size) {
      userServiceFields.forEach(f => {
        if (!set.has(f)) {
          fieldsData.push({
            fieldName: f,
            fieldMapping: {
              valueMapping: fieldNameToBizFieldDetails.get(f)?.valueMapping || null
            } as SourceFieldMapping,
            datatype: getDataType(fieldNameToBizFieldDetails.get(f)?.type) as FieldPrimType,
            category: "BUSINESS", // we want to default to BUSINESS
            subtype: "none",
            predefinedFieldType: null,
            selected: false,
            sampleVals: fieldSampleValues[f]
          });
        }
      });
    }

    setAllFields(fieldsData);
  }, [
    fieldNameToBizFieldDetails,
    initialFieldsSelection,
    userServiceFields,
    fieldSampleValues,
    commonFields,
    getUniqueFieldName
  ]);

  useEffect(() => {
    onUnSavedChange && onUnSavedChange(changeDetected);
    if (changeDetected) {
      onFieldChange && onFieldChange(allFields);
    }
  }, [allFields, changeDetected, onFieldChange, onUnSavedChange]);

  const pagination: IncRTableProps["pagination"] = useMemo(
    () => ({
      enabled: true,
      pageSize: gridPageSize || 10,
      defaultPage: pageNumber
    }),
    [gridPageSize, pageNumber]
  );

  const globalFilter: IncRTableProps["globalFilter"] = useMemo(
    () => ({
      enabled: gridShowFilterSearch,
      placeholder: "Search field name",
      hideLabel: true,
      align: "left",
      filter: searchString,
      recordsStrFn: recordsStrFn
    }),
    [gridShowFilterSearch, recordsStrFn, searchString]
  );

  return (
    <div>
      <IncRTable
        classNames={{ table: "field-selector-table" }}
        columns={columns}
        data={allFields}
        globalFilter={globalFilter}
        noDataMessage="No fields found"
        onGlobalFilterChange={onGlobalFilterChange}
        onPageChange={onPageChange}
        pagination={pagination}
      />
      <FieldTransformationModal
        configuredStreamId={configuredStreamId}
        fieldDef={getUserServiceFieldDef(fieldToTransform)}
        onClose={() => setShowTransformationConfig(false)}
        onSave={onSaveTransformation}
        previewData={userServicePreviewData}
        show={showTransformationConfig}
      />
      <FieldPreviewDataModal
        onClose={() => setPreviewField(null)}
        previewField={previewField}
        show={!isEmpty(previewField)}
      />
    </div>
  );
};

export default FieldSelectorGrid;

const categoryOptions: CategoryOption[] = [
  {
    label: "Business",
    value: "BUSINESS"
  },
  {
    label: "Diagnostic",
    value: "DIAGNOSTIC"
  }
];

export const START_TIME_MICROS_KEY = "startTimeMicros";

export const predefinedFieldTypeOptions: PredefinedFieldTypeOption[] = [
  {
    label: "duration",
    kind: "_long",
    value: "duration",
    categoryType: "DIAGNOSTIC",
    subType: "duration_micros"
  },
  {
    label: "hasError",
    kind: "_bool",
    value: "hasError",
    categoryType: "DIAGNOSTIC",
    subType: "none"
  },
  {
    label: START_TIME_MICROS_KEY,
    kind: "_long",
    value: START_TIME_MICROS_KEY,
    categoryType: "DIAGNOSTIC",
    subType: "epoch_micros"
  }
];

function getDataType(type: string) {
  if (type?.startsWith("_array")) {
    return "_set";
  } else if (type === "_boolean") {
    return "_bool";
  }

  return type;
}
