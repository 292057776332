import { mergeDeep } from "@apollo/client/utilities";
import { generateId } from "@inception/ui";
import { EditorState } from "draft-js";
import { SectionElementType, TemplateCanvas, TemplateCanvasSection } from "../../../../../services/api/operationalise";
import { TemplateBlockData, TemplateEntityData } from "./types";

export const getCanvasFromEditorState = (editorState: EditorState): TemplateCanvas => {
  const templateCanvas: TemplateCanvas = {
    footer: [],
    header: [],
    section: []
  };

  const contentState = editorState.getCurrentContent();

  const blocksArr = contentState.getBlocksAsArray();
  const entityMap = contentState.getAllEntities();

  const numBlocks = blocksArr.length;

  let textsArr: string[] = [];
  let textElementProps: TemplateCanvasSection["childElementProp"] = {};
  let section: TemplateCanvasSection;

  const addSectionForText = () => {
    if (textsArr.length) {
      const contentText = textsArr.join("\n");
      const richTextSection: TemplateCanvasSection = {
        sectionId: generateId(),
        ...(section || {}),
        sectionElementId: "rich-text-section",
        type: SectionElementType.RICH_TEXT,
        content: contentText,
        childElementProp: textElementProps
      };
      templateCanvas.section.push(richTextSection);

      textsArr = [];
      textElementProps = {};
      section = null;
    }
  };

  for (let index = 0; index < numBlocks; index++) {
    const block = blocksArr[index];

    const blockType = block.getType();
    const { section: blockSection } = block.getData().toObject() as TemplateBlockData;

    if (blockType === SectionElementType.RICH_TEXT) {
      if (section) {
        section = mergeDeep(section, blockSection);
      } else {
        section = blockSection;
      }

      const blockText = block.getText();
      const textLen = blockText.length;

      const characterList = block.getCharacterList();

      const entityRangeMap: EntityMap = new Map();
      characterList.forEach((ch, idx) => {
        const entityKey = ch.getEntity();
        if (entityKey) {
          const entityEntry = entityRangeMap.get(entityKey);
          const clEntityEntry = entityEntry || {
            start: idx,
            end: idx
          };
          clEntityEntry.end = idx;
          entityRangeMap.set(entityKey, clEntityEntry);
        }
      });

      let contentText = "";

      let textStart = 0;
      entityRangeMap.forEach((entityEntry, entityKey) => {
        const { end, start } = entityEntry;

        const entity = entityMap.get(entityKey)?.getData() as TemplateEntityData;
        const { matchStr } = entity;
        const { properties } = entity;

        const tokens = matchStr.replace(/&lt;&lt;|&gt;&gt;|<<|>>|[\s]*/g, "").split("|");
        const tokenId = tokens[0];

        const prevText = blockText.substring(textStart, start);
        contentText += prevText;
        contentText += matchStr;

        textStart = end + 1;

        if (properties) {
          textElementProps[tokenId] = properties;
        }
      });

      const lastText = blockText.substring(textStart, textLen);
      contentText += lastText;

      textsArr.push(contentText);
    } else {
      /**
       * Push the rich-text section with the text content until now.
       * We're doing this since populating each block as a section will add more space between lines.
       */
      addSectionForText();
      templateCanvas.section.push(blockSection);
    }
  }

  // If the blocks array doesn't end with a non-rich text block, then the texts should not be omitted
  addSectionForText();

  return templateCanvas;
};

type EntityMap = Map<string, EntityMapEntry>;
type EntityMapEntry = {
  start: number;
  end: number;
};
