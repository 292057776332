import { IncTextfield } from "@inception/ui";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CypressConstants } from "@bicycle/tests";
import LoadingSpinner from "../Loading/Loading";
import { logger, useNotifications, useTimeRange, TimeRange } from "../../core";
import {
  DemoDataParams,
  FieldPickerContextDTO,
  FieldPickerOptionData,
  PickerFieldType,
  UserServiceFieldWithMeta
} from "../../services/api/explore";
import { FieldPickerModel, useFieldPicker, FieldPickerRow } from "../../field-picker";
import { USFieldWidgetUtils } from "../../dashboard/widgets/USField/USFieldWidgetUtils";
import { getFieldPickerOptionData } from "./utils";
import { PickerTable } from "./PickerTableBody";
import { FieldPickerColumnUtils } from "./TableColumnUtils";

export interface FieldPickerTableProps {
  fieldTypes: PickerFieldType[];
  onChange: (options: FieldPickerOptionData, closePicker?: boolean) => void;
  fieldPickerModel?: FieldPickerModel;
  fieldPickerContextDto?: FieldPickerContextDTO;
  isMulti?: boolean;
  onSelectionChange?: (options: FieldPickerOptionData[]) => void;
  selectedOptions?: FieldPickerOptionData[];
  onDataFetch?: (model: FieldPickerModel) => void;
  getLatestTimeRange?: () => TimeRange;
  hideEventIdField?: boolean;
  skipOptions?: FieldPickerOptionData[];
  isLoading?: boolean;
  demoDataParams?: DemoDataParams;
  customUserServiceFields?: UserServiceFieldWithMeta[];
  showCommonUSFieldsOnly?: boolean;
}

const FieldPickerTable: React.FC<FieldPickerTableProps> = props => {
  const { getLatestTimeRange: uTRGetLatestTimeRange } = useTimeRange();

  const {
    fieldTypes,
    onChange,
    fieldPickerModel,
    fieldPickerContextDto: fieldPickerContext,
    isMulti,
    onSelectionChange,
    selectedOptions,
    onDataFetch,
    getLatestTimeRange = uTRGetLatestTimeRange,
    hideEventIdField = false,
    skipOptions,
    isLoading,
    demoDataParams,
    customUserServiceFields,
    showCommonUSFieldsOnly = false
  } = props;

  const { data, isFetching: isFetchingFieldsData, isError, isSuccess, error, getFields } = useFieldPicker();

  const { notifyError } = useNotifications();
  const [searchText, setSearchText] = useState<string>("");

  const selectedUSTraceFields = useRef<FieldPickerOptionData[]>([]);
  const selectedUSSpanFields = useRef<FieldPickerOptionData[]>([]);
  const selectedUsEntityFields = useRef<FieldPickerOptionData[]>([]);
  const selectedBizMetrics = useRef<FieldPickerOptionData[]>([]);
  // const selectedUSMetrics = useRef<FieldPickerOptionData[]>([]);
  const selectedPerfMetrics = useRef<FieldPickerOptionData[]>([]);
  const selectedBizAttributes = useRef<FieldPickerOptionData[]>([]);

  const [fieldPickerDataModel, setFieldPickerDataModel] = useState<FieldPickerModel>(null);

  /**
   * --------------------------------------
   * Event Type trace fields, spans & Event Type entity fields
   * --------------------------------------
   */
  const usFieldColumns = useMemo(() => {
    if (!fieldPickerDataModel) {
      return [];
    }
    return FieldPickerColumnUtils.getUserServiceFieldsTableColumns();
  }, [fieldPickerDataModel]);

  const usTraceFieldsTableData = useMemo(() => {
    if (!fieldPickerDataModel) {
      return [];
    }
    const data = fieldPickerDataModel.getUserServiceFieldsTableData(
      selectedOptions,
      skipOptions,
      showCommonUSFieldsOnly
    );
    selectedUSTraceFields.current = data.filter(r => r.rowSelected).map(getFieldPickerOptionData);
    return data;
  }, [fieldPickerDataModel, selectedOptions, skipOptions, showCommonUSFieldsOnly]);

  const usSpanTableData = useMemo(() => {
    if (!fieldPickerDataModel) {
      return [];
    }
    let data = fieldPickerDataModel.getUserServiceSpansTableData(selectedOptions, skipOptions, showCommonUSFieldsOnly);
    data = hideEventIdField ? data.filter(r => !USFieldWidgetUtils.isEventIDField(r.field.fieldName)) : data;

    selectedUSSpanFields.current = data.filter(r => r.rowSelected).map(getFieldPickerOptionData);
    return data;
  }, [fieldPickerDataModel, hideEventIdField, selectedOptions, skipOptions, showCommonUSFieldsOnly]);

  const usEntityTableData = useMemo(() => {
    if (!fieldPickerDataModel) {
      return [];
    }
    const data = fieldPickerDataModel.getUserServiceEntityFieldsTableData(
      selectedOptions,
      skipOptions,
      customUserServiceFields,
      showCommonUSFieldsOnly
    );
    selectedUsEntityFields.current = data.filter(r => r.rowSelected).map(getFieldPickerOptionData);
    return data;
  }, [customUserServiceFields, fieldPickerDataModel, selectedOptions, skipOptions, showCommonUSFieldsOnly]);

  /**
   * --------------------------------------
   * Biz entity metrics and Event Type metrics
   * --------------------------------------
   */
  const metricsColumns = useMemo(() => {
    if (!fieldPickerDataModel) {
      return [];
    }
    return FieldPickerColumnUtils.getMetricsTableColumns();
  }, [fieldPickerDataModel]);

  const bizMetricsTableData = useMemo(() => {
    if (!fieldPickerDataModel) {
      return [];
    }
    const data = fieldPickerDataModel.getBizEntityMetricTableData(selectedOptions);
    selectedBizMetrics.current = data.filter(r => r.rowSelected).map(getFieldPickerOptionData);
    return data;
  }, [fieldPickerDataModel, selectedOptions]);

  const perfMetricsTableData = useMemo(() => {
    if (!fieldPickerDataModel) {
      return [];
    }
    const data = fieldPickerDataModel.getPerfMetricTableData(selectedOptions);
    selectedPerfMetrics.current = data.filter(r => r.rowSelected).map(getFieldPickerOptionData);
    return data;
  }, [fieldPickerDataModel, selectedOptions]);

  // const usMetricsTableData = useMemo(() => {
  //   if (!fieldPickerDataModel) {
  //     return [];
  //   }
  //   const data = fieldPickerDataModel.getUserServiceMetricTableData(selectedOptions);
  //   selectedUSMetrics.current = data.filter(r => r.rowSelected).map(getFieldPickerOptionData);
  //   return data;
  // }, [fieldPickerDataModel, selectedOptions]);

  /**
   * --------------------------------------
   * Biz fields (ltv, name, etc)
   * --------------------------------------
   */
  const bizFieldsColumns = useMemo(() => {
    if (!fieldPickerDataModel) {
      return [];
    }
    return FieldPickerColumnUtils.getBizEntityFieldsColumns();
  }, [fieldPickerDataModel]);

  const bizFieldsTableData = useMemo(() => {
    if (!fieldPickerDataModel) {
      return [];
    }
    const data = fieldPickerDataModel.getBizEntityFieldsTableData(selectedOptions);
    selectedBizAttributes.current = data.filter(r => r.rowSelected).map(getFieldPickerOptionData);
    return data;
  }, [fieldPickerDataModel, selectedOptions]);

  const fetchFields = useCallback(() => {
    const { from, to } = getLatestTimeRange();
    if (fieldPickerContext && !fieldPickerDataModel) {
      const newPickerContext: FieldPickerContextDTO = {
        ...fieldPickerContext,
        showMetrics: false,
        showFields: false
      };

      if (!isEmpty(fieldTypes)) {
        if (fieldTypes.find(t => t === "userServiceField" || t === "bizEntityField")) {
          newPickerContext.showFields = true;
        }
        if (fieldTypes.find(t => t === "bizEntityMetric" || t === "userServiceMetric")) {
          newPickerContext.showMetrics = true;
        }
      } else {
        newPickerContext.showFields = newPickerContext.showMetrics = true;
      }
      logger.debug("Calling picker with ", "", newPickerContext);
      getFields(newPickerContext, from.valueOf(), to.valueOf(), false, demoDataParams);
    }
  }, [demoDataParams, fieldPickerContext, fieldPickerDataModel, fieldTypes, getFields, getLatestTimeRange]);

  useEffect(() => {
    if (!isFetchingFieldsData) {
      if (isSuccess && data) {
        setFieldPickerDataModel(data);
        onDataFetch && onDataFetch(data);
      }
      if (isError && error) {
        logger.error("FieldPickerTable", "Error while fetching entity api", error);
        notifyError("Error while fetching entity fields api");
      }
    }
  }, [data, isError, isSuccess, error, isFetchingFieldsData, notifyError, onDataFetch]);

  useEffect(() => {
    if (fieldPickerModel) {
      setFieldPickerDataModel(fieldPickerModel);
    } else {
      fetchFields();
    }
  }, [fetchFields, fieldPickerModel]);

  const onFieldSelect = useCallback(
    (rowData: FieldPickerRow<PickerFieldType>, closePicker = true) => {
      const opData = getFieldPickerOptionData(rowData);
      onChange(opData, closePicker);
    },
    [onChange]
  );

  const updateMultiSelections = useCallback(
    (
      selectionRef: React.MutableRefObject<FieldPickerOptionData[]>,
      rowData: Array<FieldPickerRow<PickerFieldType>>
    ) => {
      let newSelections: FieldPickerOptionData[] = [];

      const opData = rowData.map(getFieldPickerOptionData);

      [
        selectedBizAttributes,
        selectedBizMetrics,
        selectedPerfMetrics,
        selectedUSTraceFields,
        selectedUSSpanFields,
        selectedUsEntityFields
      ].forEach(s => {
        if (s !== selectionRef) {
          newSelections = newSelections.concat(...s.current);
        } else {
          newSelections = newSelections.concat(...opData);
        }
      });

      selectionRef.current = opData;
      onSelectionChange(newSelections);
    },
    [onSelectionChange]
  );

  const onUsBizEntityRowSelection = useCallback(
    (rowData: Array<FieldPickerRow<PickerFieldType>>) => {
      updateMultiSelections(selectedUsEntityFields, rowData);
    },
    [updateMultiSelections]
  );

  const onUSTraceFieldRowSelection = useCallback(
    (rowData: Array<FieldPickerRow<PickerFieldType>>) => {
      updateMultiSelections(selectedUSTraceFields, rowData);
    },
    [updateMultiSelections]
  );

  const onUSSpanRowSelection = useCallback(
    (rowData: Array<FieldPickerRow<PickerFieldType>>) => {
      updateMultiSelections(selectedUSSpanFields, rowData);
    },
    [updateMultiSelections]
  );

  const onBizMetricSelection = useCallback(
    (rowData: Array<FieldPickerRow<PickerFieldType>>) => {
      updateMultiSelections(selectedBizMetrics, rowData);
    },
    [updateMultiSelections]
  );

  // const onUSMetricSelection = useCallback((rowData: Array<FieldPickerRow<PickerFieldType>>) => {
  //   updateMultiSelections(selectedUSMetrics, rowData);
  // }, [updateMultiSelections]);

  const onPerfMetricSelection = useCallback(
    (rowData: Array<FieldPickerRow<PickerFieldType>>) => {
      updateMultiSelections(selectedPerfMetrics, rowData);
    },
    [updateMultiSelections]
  );

  const onBizFieldSelection = useCallback(
    (rowData: Array<FieldPickerRow<PickerFieldType>>) => {
      updateMultiSelections(selectedBizAttributes, rowData);
    },
    [updateMultiSelections]
  );

  const onSearchTextChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const text = e.target.value;
    setSearchText(text);
  }, []);

  if (isFetchingFieldsData || isLoading) {
    return <LoadingSpinner />;
  }

  const { attributes } = CypressConstants.components.FieldPickerV1;

  return (
    <>
      <div style={{ width: "100%" }}>
        <IncTextfield
          autoFocus={true}
          onChange={onSearchTextChange}
          placeholder="Search by name"
          value={searchText}
        />
      </div>
      {fieldTypes.map((fieldType, i) => {
        const TableComp =
          fieldType === "bizEntityField" ? (
            <PickerTable<"bizEntityField">
              columns={bizFieldsColumns}
              data={bizFieldsTableData}
              data-cy={attributes.bizEntityFieldsTable}
              fieldPickerContext={fieldPickerContext}
              headerLabel={"Entity Attributes"}
              isMulti={isMulti}
              onMultiSelectionChange={onBizFieldSelection}
              onSingleSelectionChange={onFieldSelect}
              searchText={searchText}
            />
          ) : fieldType === "bizEntityMetric" ? (
            <>
              <PickerTable<"bizEntityMetric" | "userServiceMetric">
                columns={metricsColumns}
                data={bizMetricsTableData}
                data-cy={attributes.bizEntityMetricsTable}
                fieldPickerContext={fieldPickerContext}
                headerLabel={"Business Metrics"}
                hideSectionHeader={fieldTypes.length <= 2}
                isMulti={isMulti}
                onMultiSelectionChange={onBizMetricSelection}
                onSingleSelectionChange={onFieldSelect}
                searchText={searchText}
              />
              <div className="table-divider" />
              <PickerTable<"bizEntityMetric" | "userServiceMetric">
                columns={metricsColumns}
                data={perfMetricsTableData}
                data-cy={attributes.userServiceMetricsTable}
                fieldPickerContext={fieldPickerContext}
                headerLabel={"Performance Metrics"}
                hideSectionHeader={fieldTypes.length <= 2}
                isMulti={isMulti}
                onMultiSelectionChange={onPerfMetricSelection}
                onSingleSelectionChange={onFieldSelect}
                searchText={searchText}
              />
            </>
          ) : fieldType === "userServiceField" ? (
            <>
              <PickerTable
                columns={usFieldColumns}
                data={usTraceFieldsTableData}
                data-cy={attributes.businessFieldsTable}
                entityMap={fieldPickerDataModel?.getEntityMap()}
                fieldPickerContext={fieldPickerContext}
                headerLabel={"Event Type - Business Fields"}
                isMulti={isMulti}
                onMultiSelectionChange={onUSTraceFieldRowSelection}
                onSingleSelectionChange={onFieldSelect}
                searchText={searchText}
              />
              <div className="table-divider" />
              <PickerTable
                columns={usFieldColumns}
                data={usSpanTableData}
                data-cy={attributes.performanceFieldsTable}
                entityMap={fieldPickerDataModel?.getEntityMap()}
                fieldPickerContext={fieldPickerContext}
                headerLabel={"Event Type - Performance Fields"}
                isMulti={isMulti}
                onMultiSelectionChange={onUSSpanRowSelection}
                onSingleSelectionChange={onFieldSelect}
                searchText={searchText}
              />
              <div className="table-divider" />
              <PickerTable
                columns={usFieldColumns}
                data={usEntityTableData}
                data-cy={attributes.businessEntitiesTable}
                entityMap={fieldPickerDataModel?.getEntityMap()}
                fieldPickerContext={fieldPickerContext}
                headerLabel={"Event Type - Business Entities"}
                isMulti={isMulti}
                onMultiSelectionChange={onUsBizEntityRowSelection}
                onSingleSelectionChange={onFieldSelect}
                searchText={searchText}
              />
            </>
          ) : (
            <></>
          );
        return (
          <React.Fragment key={i}>
            {i > 0 && i < fieldTypes.length - 1 && <div className="table-divider" />}
            {TableComp}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default React.memo(FieldPickerTable);
