import { DataType } from "../../../../core";
import { BizDataQuery } from "../../explore";
import { Actor, Interaction } from "../../onboarding";
import { BizIdProps } from "../../operationalise";

export interface BizProcess {
  id: string;
  name: string;

  actors: BizProcessActor[];
  interactions: BizProcessInteraction[];
}

export interface BizProcessActor {
  actor: Actor;
  statQueries: BizProcessStatQueryConfig[];
  uiVizProps: Record<string, string>;
}

export interface BizProcessInteraction {
  interaction: Interaction;
  statQueries: BizProcessStatQueryConfig[];
  uiVizProps: Record<string, string>;
}

export enum StatVisualizationType {
  SINGLE_STAT = "SINGLE_STAT",
  TREND = "TREND",
  REVERSE_TREND = "REVERSE_TREND"
}

export enum StatPosition {
  UNSET = "UNSET",
  HEADER = "HEADER",
  DEF_NAME = "DEF_NAME",
  STAT_ROW = "STAT_ROW"
}

export interface BizProcessStatQueryConfig {
  query: BizDataQuery;
  dataType: DataType;
  idProps: BizIdProps;

  label: string;
  vizType: StatVisualizationType;
  position: StatPosition;
  uiVizProps: Record<string, string>;
}
