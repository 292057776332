import {
  IncToolTip,
  IncFaIcon,
  IncMenu,
  IncMenuItemProps,
  IncMenuToggleProps,
  IncButton,
  IncDurationSelector,
  IncSelectorDuration,
  IncClickAwayPopper,
  IncToggle
} from "@inception/ui";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { cx } from "emotion";
import { VerticallyCenteredRow } from "../../../components";
import { getSourceTypeIcon } from "../../../components/business-entity";
import { dateTime, useAccessPrivilege, useToggleState } from "../../../core";
import { ActionCategoryType, MuteTimeRange } from "../../../services/api/operationalise";
import { ActionInfo } from "../../components";
import { ReactComponent as SnoozeIcon } from "../../../../images/icons/Snooze.svg";
import { getTimeObjFromDuration } from "../../../utils/DurationUtils";
import { getMillisFromTimeObj } from "../../../utils";
import { getTimeshiftSelectionFromSecs } from "../../../utils/ExploreUtils";

interface Props {
  actionCategoryType: ActionCategoryType;
  sourceTypeInfo: Record<string, Record<string, ActionInfo>>;
  sourceTypeIconRef: React.MutableRefObject<Record<string, JSX.Element>>;
  isUnsavedAction: boolean;
  isDisabled: boolean;
  selectedActionId: string;
  sourceTypeId: string;
  bizActionId: string;
  snoozeTimeRange?: MuteTimeRange;
  onSelect: () => void;
  onDelete: () => void;
  onClone: () => void;
  onEnable: () => void;
  onSnooze: (timeRange: MuteTimeRange) => void;
  onDisable: () => void;

  errors: string[];
  name: string;
}

export const ActionCard: FC<Props> = props => {
  const {
    actionCategoryType,
    sourceTypeInfo,
    sourceTypeIconRef,
    isUnsavedAction,
    selectedActionId,
    sourceTypeId,
    bizActionId,
    isDisabled,
    onSelect,
    onDelete,
    onClone,
    onEnable,
    onDisable,
    onSnooze,
    snoozeTimeRange,
    errors,
    name = "Custom action"
  } = props;

  const { canCreate, canDelete, canEdit } = useAccessPrivilege();
  const canChange = canCreate || canEdit || canDelete;

  const { icon = "" } = sourceTypeInfo[actionCategoryType]?.[sourceTypeId] || {};

  const isSelected = selectedActionId === bizActionId;
  const error = errors.join("\n");

  const iconJsx = sourceTypeIconRef.current[sourceTypeId] || getSourceTypeIcon(sourceTypeId, icon, "", 24);
  sourceTypeIconRef.current[sourceTypeId] = icon ? iconJsx : null;

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onSelect && onSelect();
    },
    [onSelect]
  );

  const isSnoozed = useMemo(() => {
    if (isDisabled) {
      return false;
    }
    if (snoozeTimeRange) {
      const { startTimeMillis, endTimeMillis } = snoozeTimeRange;
      if (startTimeMillis && endTimeMillis) {
        const nowMillis = dateTime().valueOf();
        return startTimeMillis <= nowMillis && nowMillis <= endTimeMillis;
      }
      return false;
    }
    return false;
  }, [isDisabled, snoozeTimeRange]);

  const style = isSelected
    ? {
        border: "1px solid #39ACFF",
        background: "linear-gradient(0deg, rgba(57, 172, 255, 0.05), rgba(57, 172, 255, 0.05)), #2F3842",
        borderRadius: 8,
        padding: "20px 16px"
      }
    : {
        borderRadius: 8,
        background: "#2F3842",
        padding: "20px 16px"
      };

  const menuItems = useCallback(
    (itemProps: IncMenuItemProps) => {
      const { className, closeMenu } = itemProps;
      const headerClassName = `inc-text-subtext-medium ${className}`;

      const wrapperFunction = (e: any, func: () => void) => {
        e.stopPropagation();
        closeMenu(e);
        func();
      };

      const lOnClone = (e: any) => wrapperFunction(e, onClone);
      const lOnDelete = (e: any) => wrapperFunction(e, onDelete);
      const lOnEnable = (e: any) => wrapperFunction(e, onEnable);
      const lOnDisable = (e: any) => wrapperFunction(e, onDisable);

      return (
        <>
          {canCreate && (
            <div
              className={headerClassName}
              onClick={lOnClone}
            >
              Duplicate
            </div>
          )}
          {canDelete && (
            <div
              className={headerClassName}
              onClick={lOnDelete}
            >
              Delete
            </div>
          )}
          {canEdit && (
            <>
              {isDisabled && (
                <div
                  className={headerClassName}
                  onClick={lOnEnable}
                >
                  Enable
                </div>
              )}
              {!isDisabled && (
                <div
                  className={headerClassName}
                  onClick={lOnDisable}
                >
                  Disable
                </div>
              )}
            </>
          )}
        </>
      );
    },
    [canCreate, canDelete, canEdit, isDisabled, onClone, onDelete, onDisable, onEnable]
  );

  const toggle = useCallback((toggleProps: IncMenuToggleProps) => {
    const { show, toggleMenu } = toggleProps;

    const onClick = (e: any) => {
      e.stopPropagation();
      toggleMenu(!show);
    };

    return (
      <div
        className="action-icon-button display-element"
        onClick={onClick}
      >
        <IncFaIcon
          className="inc-cursor-pointer"
          iconName="ellipsis-h"
        />
      </div>
    );
  }, []);

  return (
    <VerticallyCenteredRow
      className={`actions-list--card marginBt12 width-100 visible-on-hover ${isDisabled ? "actions-list--disabled" : ""} ${isSnoozed ? "actions-list--snoozed" : ""}`}
      data-selected={isSelected}
      style={style}
    >
      {(isDisabled || isSnoozed) && (
        <div className={`action-badge ${isSnoozed ? "snoozed-badge" : ""}`}>{isDisabled ? "Disabled" : "Snoozed"}</div>
      )}

      <VerticallyCenteredRow
        className="action-label inc-flex-grow marginRt12 inc-cursor-pointer"
        onClick={onClick}
      >
        <div className="marginRt12">{iconJsx}</div>

        <VerticallyCenteredRow>{name}</VerticallyCenteredRow>
      </VerticallyCenteredRow>

      {Boolean(error) && (
        <IncToolTip
          placement="top"
          titleText={error}
          variant="error"
        >
          <div className="error-message marginRt12">
            <IncFaIcon iconName="warning" />
          </div>
        </IncToolTip>
      )}

      {!isUnsavedAction && canChange && !isDisabled && (
        <SnoozeModal
          isSnoozeEnable={isSnoozed}
          onSave={onSnooze}
          timeRange={snoozeTimeRange}
        />
      )}

      {!isUnsavedAction && canChange && (
        <IncMenu
          items={menuItems}
          toggle={toggle}
        />
      )}
    </VerticallyCenteredRow>
  );
};

const getDurationFromTimeRange = (timeRange?: MuteTimeRange) => {
  const { startTimeMillis, endTimeMillis } = timeRange || {};
  const durationMillis = (endTimeMillis || 0) - (startTimeMillis || 0);
  return getTimeshiftSelectionFromSecs(durationMillis / 1000);
};

type PopperProps = {
  onSave: (timeRange: MuteTimeRange) => void;
  isSnoozeEnable?: boolean;
  isSnoozeForever?: boolean;
  showSnoozeForever?: boolean;
  timeRange?: MuteTimeRange;
  title?: string;
  iconClassName?: string;
  snoozedClassName?: string;
  popperClassName?: string;
};

export const SnoozeModal: FC<PopperProps> = props => {
  const {
    isSnoozeForever: pIsSnoozeForever,
    showSnoozeForever,
    onSave,
    isSnoozeEnable,
    timeRange,
    title = "Snooze Action",
    iconClassName = "snooze-action display-element marginRt12",
    snoozedClassName = "snoozed",
    popperClassName: pPopperClassName
  } = props;
  const ref = useRef<HTMLButtonElement>(null);
  const { close, isOpen, open } = useToggleState(false);

  const { isOpen: enabledSnooze, setIsOpen: setEnableSnooze, toggle } = useToggleState(isSnoozeEnable);

  const {
    isOpen: isSnoozeForever,
    setIsOpen: setSnoozeForever,
    toggle: toggleSnoozeForever
  } = useToggleState(pIsSnoozeForever);

  const [duration, setDuration] = useState<IncSelectorDuration>(getDurationFromTimeRange(timeRange));
  useEffect(() => {
    if (isSnoozeEnable) {
      setDuration(getDurationFromTimeRange(timeRange));
    } else {
      setDuration(getDurationFromTimeRange({}));
    }
  }, [isSnoozeEnable, timeRange]);

  useEffect(() => {
    if (isSnoozeForever) {
      setEnableSnooze(true);
      setDuration(
        getDurationFromTimeRange({
          endTimeMillis: 0,
          startTimeMillis: 0
        })
      );
    }
  }, [isSnoozeForever, setEnableSnooze, timeRange]);

  useEffect(() => {
    if (!enabledSnooze) {
      setSnoozeForever(false);
    }
  }, [enabledSnooze, setSnoozeForever]);

  useEffect(() => {
    setSnoozeForever(pIsSnoozeForever);
  }, [pIsSnoozeForever, setSnoozeForever]);

  useEffect(() => {
    setEnableSnooze(isSnoozeEnable);
  }, [isSnoozeEnable, setEnableSnooze]);

  const save = useCallback(() => {
    if (enabledSnooze) {
      if (isSnoozeForever) {
        const timeRange: MuteTimeRange = {
          startTimeMillis: 0,
          endTimeMillis: 0
        };
        onSave(timeRange);
        close();
      } else if (duration) {
        const { duration: durationValue, durationType } = duration;
        const timeObj = getTimeObjFromDuration(durationValue as number, durationType);
        const millis = getMillisFromTimeObj(timeObj);
        const startMillis = dateTime().valueOf();
        const timeRange: MuteTimeRange = {
          startTimeMillis: startMillis,
          endTimeMillis: startMillis + millis
        };
        onSave(timeRange);
        close();
      }
    } else {
      const timeRange: MuteTimeRange = {};
      onSave(timeRange);
      close();
    }
  }, [close, duration, enabledSnooze, isSnoozeForever, onSave]);

  const openPopper = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      open();
    },
    [open]
  );

  const showDurationSelector = useMemo(() => {
    if (!enabledSnooze) {
      return false;
    }
    if (showSnoozeForever) {
      return !isSnoozeForever;
    }
    return true;
  }, [enabledSnooze, isSnoozeForever, showSnoozeForever]);

  const popperClassName = cx("inc-card-layout", {
    [pPopperClassName]: Boolean(pPopperClassName)
  });
  const snoozeClassName = cx("inc-cursor-pointer", {
    [iconClassName]: Boolean(iconClassName),
    [snoozedClassName]: Boolean(snoozedClassName) && isSnoozeEnable
  });

  return (
    <>
      <div
        className={snoozeClassName}
        onClick={openPopper}
        ref={ref as any}
      >
        <SnoozeIcon />
      </div>
      <IncClickAwayPopper
        anchorEl={ref.current}
        className={popperClassName}
        onClickAway={close}
        placement="bottom-end"
        show={isOpen}
      >
        <div className="inc-flex-column flex-gap-16">
          <IncToggle
            checked={enabledSnooze}
            className="inc-flex-space-contents"
            label={title}
            labelVariant="card-header"
            onChange={toggle}
          />
          {showSnoozeForever && enabledSnooze && (
            <IncToggle
              checked={isSnoozeForever}
              className="inc-flex-space-contents"
              disabled={!enabledSnooze}
              label={"Snooze Forever"}
              labelVariant="card-header"
              onChange={toggleSnoozeForever}
            />
          )}
          {showDurationSelector && (
            <IncDurationSelector
              duration={duration}
              hideLabel
              isDisabled={!enabledSnooze || isSnoozeForever}
              onChange={setDuration}
            />
          )}
          <VerticallyCenteredRow className="inc-flex-space-contents">
            <IncButton
              className="inc-flex-center width-50 marginRt16"
              color="primary"
              onClick={save}
            >
              Save
            </IncButton>
            <IncButton
              className="inc-flex-center width-50"
              color="secondary-blue"
              onClick={close}
            >
              Cancel
            </IncButton>
          </VerticallyCenteredRow>
        </div>
      </IncClickAwayPopper>
    </>
  );
};
