import { IncTextfield, IncFaIcon } from "@inception/ui";
import React, { ChangeEvent, useCallback, useState, useEffect } from "react";
import { FieldPrimType, FieldSubType } from "../../core/data/types/DataTypes";
import { FieldTransformationConfig, TransformType, ConditionalConfig } from "../transform-config/TransformConfig";
import { FieldTransformationForm } from "../FieldTransformationForm";

interface ConditionalCompProps {
  config: ConditionalConfig;
  onChange: (config: ConditionalConfig) => void;
  defaultOutputType?: FieldPrimType;
  getLocationEntityTypes: () => Record<string, string>;
  setEntityTypeValidity: (valid: boolean) => void;
  uniqueFieldName: string;
  fieldSubType: FieldSubType;
}

export const ConditionalComp: React.FC<ConditionalCompProps> = props => {
  const {
    config,
    onChange,
    defaultOutputType,
    getLocationEntityTypes,
    setEntityTypeValidity,
    uniqueFieldName,
    fieldSubType
  } = props;
  const { expression, trueTransformation, falseTransformation } = config ?? {};
  const [expr, setExpr] = useState<string>(expression ?? "");
  const [trueConfig, setTrueConfig] = useState<FieldTransformationConfig<TransformType>>(trueTransformation);
  const [falseConfig, setFalseConfig] = useState<FieldTransformationConfig<TransformType>>(falseTransformation);

  const updateConfig = useCallback(
    (
      expr: string,
      ifConfig: FieldTransformationConfig<TransformType>,
      elseConfig: FieldTransformationConfig<TransformType>
    ) => {
      if (!expr || !ifConfig || !elseConfig) {
        onChange(null);
        return;
      }
      const newConfig: ConditionalConfig = {
        expression: expr,
        trueTransformation: ifConfig,
        falseTransformation: elseConfig
      };
      onChange(newConfig);
    },
    [onChange]
  );

  useEffect(() => {
    const { expression, trueTransformation, falseTransformation } = config ?? {};
    if ((expression ?? "") !== expr || trueTransformation !== trueConfig || falseTransformation !== falseConfig) {
      updateConfig(expr, trueConfig, falseConfig);
    }
  }, [trueConfig, falseConfig, expr, config, updateConfig]);

  const onExprChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const textVal = e.target.value;
    setExpr(textVal);
  }, []);

  const onTrueConfigChange = useCallback((config: FieldTransformationConfig<TransformType>) => {
    setTrueConfig(config);
  }, []);

  const onFalseConfigChange = useCallback((config: FieldTransformationConfig<TransformType>) => {
    setFalseConfig(config);
  }, []);

  return (
    <div className="conditional-transform">
      <IncTextfield
        className="marginBt16"
        label="Expression"
        onChange={onExprChange}
        value={expr}
      />
      <ConditionBlock
        config={trueConfig}
        defaultOutputType={defaultOutputType}
        fieldSubType={fieldSubType}
        getLocationEntityTypes={getLocationEntityTypes}
        label="If True"
        onConfigChange={onTrueConfigChange}
        setEntityTypeValidity={setEntityTypeValidity}
        uniqueFieldName={uniqueFieldName}
      />
      <ConditionBlock
        config={falseConfig}
        defaultOutputType={defaultOutputType}
        fieldSubType={fieldSubType}
        getLocationEntityTypes={getLocationEntityTypes}
        label="If False"
        onConfigChange={onFalseConfigChange}
        setEntityTypeValidity={setEntityTypeValidity}
        uniqueFieldName={uniqueFieldName}
      />
    </div>
  );
};

interface ConditionBlockProps {
  label: string;
  config: FieldTransformationConfig<TransformType>;
  onConfigChange: (config: FieldTransformationConfig<TransformType>) => void;
  defaultOutputType?: FieldPrimType;
  getLocationEntityTypes: () => Record<string, string>;
  setEntityTypeValidity: (valid: boolean) => void;
  uniqueFieldName: string;
  fieldSubType: FieldSubType;
}

const ConditionBlock: React.FC<ConditionBlockProps> = props => {
  const {
    label,
    config,
    onConfigChange,
    getLocationEntityTypes,
    setEntityTypeValidity,
    uniqueFieldName,
    fieldSubType
  } = props;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const toggleCollapse = useCallback(() => setIsCollapsed(collapse => !collapse), []);

  return (
    <>
      <div
        className="condition-config-header"
        onClick={toggleCollapse}
      >
        <IncFaIcon
          className="marginRt8"
          iconName={isCollapsed ? "caret-right" : "caret-down"}
        />
        <span className="inc-text-subtext-medium">{label}</span>
        <div className="condition-config-header--line" />
      </div>
      {!isCollapsed && (
        <div className="marginLt16">
          <FieldTransformationForm
            config={config}
            fieldDataType="_str"
            fieldSubType={fieldSubType}
            getLocationEntityTypes={getLocationEntityTypes}
            onChange={onConfigChange}
            setEntityTypeValidity={setEntityTypeValidity}
            uniqueFieldName={uniqueFieldName}
          />
        </div>
      )}
    </>
  );
};
