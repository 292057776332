import { orderBy } from "lodash";
import { IncSelect } from "@inception/ui";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { CohortSelectOption } from "../../services/api/explore/types";
import { useFetchCohortList } from "../../services/api/explore/hooks/useFetchCohortList";
import { getAllCohortName } from "../../utils/CohortUtils";

interface CohortSelectorProps {
  entityTypeId: string;
  entityTypeName?: string;
  value: CohortSelectOption;
  onChange: (op: CohortSelectOption) => void;
  /**
   * Executes once, after the selector has fetched options to populate.
   */
  onLoad?: (options: CohortSelectOption[]) => void;
  allowAll?: boolean;
  allowCreate?: boolean;
  label?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  prefetched?: CohortSelectOption[];
}

export const CohortSelector: React.FC<CohortSelectorProps> = props => {
  const {
    entityTypeId,
    value,
    onChange,
    label,
    entityTypeName,
    allowAll,
    allowCreate,
    onLoad,
    prefetched,
    isClearable = false,
    isDisabled = false
  } = props;
  const [cohortOptions, setCohortOptions] = useState<CohortSelectOption[]>([]);

  // reference of options against which onLoad is called.
  const onLoadOptions = useRef<CohortSelectOption[]>();

  const setCohortOptionsHelper = useCallback(
    (options: CohortSelectOption[]) => {
      options = orderBy(options, op => op.label.toLowerCase());
      if (allowAll) {
        const name = getAllCohortName(entityTypeName);
        const allOption: CohortSelectOption = {
          label: name,
          value: NONE_COHORT_VALUE,
          cohort: {
            id: "all",
            name: name
          }
        };
        options.splice(0, 0, allOption);
      }

      if (allowCreate) {
        const newOption: CohortSelectOption = {
          label: "Create New",
          value: NEW_COHORT_VALUE,
          cohort: null
        };
        options.push(newOption);
      }
      setCohortOptions(options);
    },
    [allowAll, allowCreate, entityTypeName]
  );

  const { data: cohortList, isFetching: isFetchingCohorts } = useFetchCohortList(entityTypeId, !!prefetched);

  useEffect(() => {
    if (prefetched) {
      return setCohortOptionsHelper(prefetched);
    }

    if (!isFetchingCohorts && cohortList && entityTypeId) {
      const nCohortOptions = cohortList.map(c => ({
        label: c.name,
        value: c.id,
        cohort: c
      }));
      setCohortOptionsHelper(nCohortOptions);
    }
  }, [cohortList, entityTypeId, isFetchingCohorts, prefetched, setCohortOptionsHelper]);

  useEffect(() => {
    if (onLoad && cohortOptions.length > 0 && onLoadOptions.current !== cohortOptions) {
      onLoadOptions.current = cohortOptions;
      onLoad(cohortOptions);
    }
  }, [onLoad, cohortOptions]);

  return (
    <IncSelect
      autoSort={false}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={isFetchingCohorts}
      isMulti={false}
      label={label ?? "Pick Cohort"}
      onChange={onChange}
      options={cohortOptions}
      value={value}
    />
  );
};

const NEW_COHORT_VALUE = "new";
const NONE_COHORT_VALUE = "none";
