import React, { FC, useCallback, useMemo } from "react";
import { ISaxIcon, IncSmartText } from "@inception/ui";
import { uniqBy } from "lodash";
import { SuppressionsEditor } from "../v3/editors/SuppressionsEditor/SuppresionsEditor";
import {
  BizIdProps,
  Op10zeStage,
  OpCreationConfig,
  SuppressionConfigDef,
  operationaliseV2ApiService
} from "../../services/api/operationalise";
import { OpContext } from "../context/types";
import { VerticallyCenteredRow } from "../../components";
import { UserServiceTuple } from "../../services/api/explore";
import { logger } from "../../core";
import { getRollingFreqFromSchedule } from "../context/reducerUtils";
import { getMillisFromTimeObj } from "../../utils";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { setAnalysisSuppression, useOpAnalysisStore } from "./context";

type Props = {
  eventTypeId: string;
};

const SuppressionEditorWrapper: FC<Props> = props => {
  const { eventTypeId } = props;
  const pickerContext: OpContext = useMemo(
    () => ({
      bizEntityFieldName: null,
      cohortDefinition: null,
      cohortState: null,
      entityTypeId: null,
      entityTypeInfo: null,
      eventTypeId: eventTypeId,
      eventTypeInfo: null,
      implicitSlice: null,
      mode: "create",
      sessionId: null,
      uiIntegrationActionConfigsMap: {},
      widgetId: null
    }),
    [eventTypeId]
  );
  const { dispatch, state } = useOpAnalysisStore();
  const { opAnalysisImpl } = state;
  const suppressions = useMemo(() => opAnalysisImpl.getSuppression() || [], [opAnalysisImpl]);
  const opSchedule = useMemo(() => opAnalysisImpl.getSchedule(), [opAnalysisImpl]);
  const kpiRecord = useMemo(() => opAnalysisImpl.getKpis(), [opAnalysisImpl]);
  const bizDataQuery = useMemo(() => Object.values(kpiRecord || {})?.[0]?.bizDataQuery, [kpiRecord]);
  const onChangeSuppressions = useCallback(
    (suppressions: SuppressionConfigDef[]) => {
      dispatch(setAnalysisSuppression(suppressions));
    },
    [dispatch]
  );

  const opAnalysisConfig = useMemo(() => opAnalysisImpl.getConfig(), [opAnalysisImpl]);

  const opConfig = useMemo<OpCreationConfig>(() => {
    const opAnalysisKpis = Object.values(opAnalysisConfig?.kpis || {});
    const userServiceInfo: UserServiceTuple[] = [];
    opAnalysisKpis.forEach(kpi => {
      const { bizDataQuery } = kpi;
      const { widgetConfig } = bizDataQuery || {};
      const { userServiceEntityId } = widgetConfig || {};
      if (userServiceEntityId) {
        userServiceInfo.push({
          userServiceEntityId
        });
      }
    });

    const idProps: BizIdProps = {
      primary: {
        eventTypes: {
          userServiceInfo: uniqBy(userServiceInfo, usrInfo => usrInfo.userServiceEntityId)
        }
      },
      secondary: {}
    };

    const config: OpCreationConfig = {
      bizActions: {},
      createdBy: null,
      description: "",
      name: "",
      idProps,
      lastUpdatedBy: null,
      opCreationConfigDef: null,
      opCreationConfigExpr: null,
      opAnalysisConfig,
      runBooks: [],
      stage: Op10zeStage.draft
    };
    return config;
  }, [opAnalysisConfig]);

  const getPredefinedMetrics = useCallback(async () => {
    try {
      const { data, error } = await operationaliseV2ApiService.getDefaultOpTriageConfig(opConfig);
      if (error) {
        logger.error("SuppressionEditorWrapper", "Error fetching impacted widgets");
        return [];
      }
      const { impactedWidgets } = data || {};
      return impactedWidgets?.impactedWidgets || [];
    } catch (error) {
      logger.error("SuppressionEditorWrapper", "Error fetching impacted widgets", error);
      return [];
    }
  }, [opConfig]);

  const compareTimeSeconds = useMemo(() => {
    if (!opSchedule) {
      return 0;
    }
    const freq = getRollingFreqFromSchedule(opSchedule);
    const millis = getMillisFromTimeObj(freq);
    const secs = timeRangeUtils.getSecondsFromMillis(millis);
    return secs;
  }, [opSchedule]);

  return (
    <div className={`analysis-editor--suppression-editor`}>
      <VerticallyCenteredRow className="flex-gap-12">
        <IncSmartText text="Exceptions" />
        <ISaxIcon iconName="InfoCircle" />
        <IncSmartText
          className="inc-text-element-medium inc-text-inactive"
          text="Add scenarios that you do not want to see, even if the condition is violated"
        />
      </VerticallyCenteredRow>
      <SuppressionsEditor
        bizDataQuery={bizDataQuery}
        compareTimeSeconds={compareTimeSeconds}
        getPredefinedMetrics={getPredefinedMetrics}
        onChange={onChangeSuppressions}
        pickerContext={pickerContext}
        suppressions={suppressions}
      />
    </div>
  );
};

export default SuppressionEditorWrapper;
