import { compact, trim, uniq } from "lodash";

export interface VariableResolverResult {
  error: string;
  data: string[];
}

class CustomVariableResolver {
  resolveValues(query: string): Promise<VariableResolverResult> {
    const result: VariableResolverResult = {
      error: "",
      data: []
    };
    try {
      let values = query.split(",");
      values = values.map(trim);
      values = uniq(values);
      values = compact(values);
      result.data = values;
    } catch (err) {
      result.error = err.message;
    }

    return Promise.resolve(result);
  }
}

const customVariableResolver = new CustomVariableResolver();

export default customVariableResolver;
