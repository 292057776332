import { max, defaultsDeep, merge } from "lodash";
import {
  XAxisOptions,
  YAxisOptions,
  TooltipOptions,
  DataLabelsFormatterCallbackFunction,
  XAxisScrollbarOptions,
  PlotSeriesDataLabelsOptions,
  AxisLabelsFormatterContextObject
} from "highcharts";
import { formatNumber } from "@inception/ui";
import getChartColor from "../charts/colors";
import { CommonWidgetOptions } from "../../dashboard/models/BaseWidgetModel";
import { BaseOptionsBuilderWithAxes } from "../charts";
import { BarChartLayout } from "../../dashboard/widgets/BarChart/BarChartHelper";
import { IncHBarSeriesOptions } from "./types";

/**
 * This a builder for bar chart widget. The series can vary between column and bar
 * based on the layout being vertical or horizontal repectively. Do not confuse this with
 * column chart's options builder. This is widget specific and data here is generic.
 * In Future: Merge the bar chartg widget and column chart and control colors and other
 * specifications using options provided.
 */

class BarChartOptionsBuilder extends BaseOptionsBuilderWithAxes {
  private minBarDimension = 25; // height for horizontal chart and width for vertical chart
  private maxBarDimension = 50; // height for horizontal chart and width for vertical chart
  private scrollbarProperties: XAxisScrollbarOptions = {
    enabled: true,
    liveRedraw: true,
    barBackgroundColor: "#c7d0d9",
    barBorderColor: "#f7f8fa",
    barBorderWidth: 1,
    barBorderRadius: 3,
    showFull: true,
    trackBackgroundColor: "transparent",
    rifleColor: "transparent",
    trackBorderColor: "transparent",
    buttonBackgroundColor: "#c7d0d9",
    buttonBorderColor: "transparent",
    buttonBorderRadius: 3,
    height: 10
  };

  layout: BarChartLayout;

  private showDataLabels = false;
  private dataLabelsOptions: PlotSeriesDataLabelsOptions = {};
  private barDimension: number;

  constructor(options?: CommonWidgetOptions & Highcharts.Options) {
    super(options);
    this.barDimension = options?.barDimension;
  }

  setLayout(layout: BarChartLayout) {
    this.layout = layout;
    return this;
  }

  setShowDataLabels(showLabels: boolean) {
    this.showDataLabels = showLabels;
    return this;
  }

  setDataLabelsOptions(labelOptions: PlotSeriesDataLabelsOptions) {
    this.dataLabelsOptions = labelOptions;
    return this;
  }

  setChartFields(width: number, height: number) {
    super.setChartFields(width, height);

    if (this.layout === "vertical") {
      this.chartOptions.chart.type = "column";
      this.chartOptions.chart.zooming = {
        type: "x"
      };
    } else {
      this.chartOptions.chart.type = "bar";
      this.chartOptions.chart.zooming = {
        type: undefined
      }; // No zooming for horizontal chart
    }

    return this;
  }

  setSeriesData(
    series: IncHBarSeriesOptions[],
    labelPrecision?: number,
    formatter?: DataLabelsFormatterCallbackFunction
  ) {
    const labels: string[] = [];
    const colors: string[] = [];

    series.forEach(serie => {
      serie.data?.forEach((d, i) => {
        labels.push((d as [string, number])[0]);
        colors.push(getChartColor(i));
      });
      serie.colors = serie.colors || colors;
      serie.pointPadding = 0;
      serie.groupPadding = 0.05;
      serie.colorByPoint = true;
      serie.getExtremesFromAll = true;

      if (this.barDimension) {
        serie.pointWidth = this.barDimension;
        serie.maxPointWidth = this.barDimension + 10;
      } else {
        serie.maxPointWidth = this.maxBarDimension;
      }
    });

    this.addDataLabels(labels, formatter);

    const maxBars = max(series.map(s => s.data?.length));
    const numSeries = this.stacked ? 1 : series.length;
    this.addScroll(maxBars, numSeries);

    this.chartOptions.series = series;

    return this;
  }

  setToolTip(
    showAxisName?: boolean,
    hideSeriesName?: boolean,
    tooltipValueFormatter?: (value: string | number) => string
  ) {
    this.chartOptions.tooltip = this.chartOptions.tooltip || {};

    const defaultOptions: TooltipOptions = {
      enabled: true,
      shared: true,
      followPointer: true,
      outside: true,
      useHTML: true,
      formatter: function () {
        if (!this.points) {
          return "";
        }

        let tooltipText = `<div class='inc-charts-tooltip'>`;
        this.points.forEach((pt, i) => {
          const formattedValue = tooltipValueFormatter ? tooltipValueFormatter(pt.y) : formatNumber(pt.y);

          // Typing this to any since opacity and options property exist but it still complains that they're not valid
          const series = pt.series as any;
          const seriesName = series?.options?.getTooltipText ? series.options.getTooltipText(pt.x) : pt.x;

          tooltipText += `<div class='inc-charts-tooltip-series-row' >
            <div class="inc-highcharts-square-symbol" style="background-color:${this.points[i].color}; opacity: ${series.opacity}"></div>
              <div class='inc-charts-tooltip-series-name'>${seriesName}</div> 
                <div class='inc-charts-tooltip-series-value'> <span>${formattedValue}</span></div >
                  </div>`;
        });

        tooltipText += "</div>";
        return tooltipText;
      }
    };

    defaultsDeep(this.chartOptions.tooltip, defaultOptions);
    return this;
  }

  setLegend(showLegend: boolean) {
    super.setLegend(showLegend);
    this.chartOptions.legend = {
      ...this.chartOptions.legend,
      layout: "horizontal"
    };

    return this;
  }

  setYAxis(
    maxValue: number,
    yAxisOptions: Highcharts.YAxisOptions[],
    yAxisTitle = "",
    allowDecimals = true,
    yAxisLabelFormatter: (ctx: AxisLabelsFormatterContextObject) => string = undefined,
    hideYAxis = false
  ) {
    super.setYAxis(maxValue, yAxisOptions, yAxisTitle, allowDecimals, yAxisLabelFormatter);

    const fYAxisOptions = this.chartOptions.yAxis as YAxisOptions[];
    fYAxisOptions[0].visible = true;

    if (hideYAxis) {
      fYAxisOptions.forEach(y => (y.visible = false));
    }

    return this;
  }

  private addScroll(maxData: number, numSeries: number) {
    const isHorizontalLayout = this.layout === "horizontal";
    const height = parseInt(this.chartOptions.chart.height.toString(), 10);
    const width = parseInt(this.chartOptions.chart.width.toString(), 10);

    const totalDimension = isHorizontalLayout ? height : width;
    const minBarDimension = this.barDimension || this.minBarDimension;
    const scrollNeeded = maxData * numSeries * minBarDimension + maxData * 8 > totalDimension;

    const maxBarDimension = this.barDimension ? this.barDimension + 0.25 * this.barDimension : this.minBarDimension;
    let maxBars = Math.floor(totalDimension / (maxBarDimension * numSeries)) - 1;
    maxBars = Math.min(maxBars, maxData - 1);

    if (this.chartOptions) {
      const xAxis = this.chartOptions.xAxis as XAxisOptions;
      xAxis.min = 0;
      xAxis.max = maxBars;

      if (scrollNeeded) {
        xAxis.scrollbar = this.scrollbarProperties;
        this.chartOptions.chart.spacingBottom = isHorizontalLayout ? 0 : 16;
        this.chartOptions.chart.spacingRight = isHorizontalLayout ? 16 : 0;
      }
    }
  }

  private addDataLabels(labels: string[], formatter?: DataLabelsFormatterCallbackFunction) {
    if (this.chartOptions) {
      const xAxis = this.chartOptions.xAxis as XAxisOptions;
      xAxis.categories = labels;

      if (this.showDataLabels) {
        const isHorizontalLayout = this.layout === "horizontal";
        const align = isHorizontalLayout ? "right" : "left";
        const verticalAlign = isHorizontalLayout ? "middle" : "top";

        this.chartOptions.plotOptions.series.dataLabels = merge(
          {
            enabled: true,
            align,
            inside: true,
            color: "#ffffff",
            allowOverlap: true,
            verticalAlign,
            className: "inc-data-label",
            useHTML: true,
            formatter:
              formatter ||
              function () {
                // const pt = this.point as any;
                // const labelInsideBar = isHorizontalLayout ? pt.shapeArgs.height > 50 : pt.shapeArgs.height > 30;
                // const color = labelInsideBar ? '#ffffff' : '#000000';
                // const style = `color: ${color} !important;`;

                return this.y
                  ? `<span style="">
            ${formatNumber(this.y)}
          </span>`
                  : "";
              }
          },
          this.dataLabelsOptions
        );
      }
    }
  }
}

export default BarChartOptionsBuilder;
