import { isEmpty } from "lodash";
import {
  PayloadPointer,
  PreviewField,
  SourceSchema,
  UiBizfieldDetailsWithType,
  SourceValueMapping
} from "../../../../platform/services/api/entity-mapping";
import { SourceTreePredicate } from "../../../services/api/event-mapping";
import { LogicalOperation, StringOperation, NumberOperation } from "../../../core/data/types/BaseOperatorTypes";

export const getFieldsFromPreviewSchema = (schema: SourceSchema): Map<string, UiBizfieldDetailsWithType> => {
  const fieldsToDetails: Map<string, UiBizfieldDetailsWithType> = new Map();

  if (!schema || isEmpty(schema.fields)) {
    return fieldsToDetails;
  }

  getFieldsInternalRec(schema.fields, fieldsToDetails, schema.payloadPointer, null, "");

  return fieldsToDetails;
};

const getFieldsInternalRec = (
  fields: Record<string, PreviewField>,
  fieldsToDetails: Map<string, UiBizfieldDetailsWithType>,
  topLevelPayloadPointer: PayloadPointer,
  parentPayloadPointers: PayloadPointer,
  parentFieldDisplayName: string
) => {
  if (isEmpty(fields)) {
    return;
  }

  for (const [fieldName, fieldDetails] of Object.entries(fields)) {
    let chainedPayloadPointer: PayloadPointer = null;
    if (parentPayloadPointers) {
      const fieldDetailsPaths = (fieldDetails.payloadPointer && fieldDetails.payloadPointer.paths) || [];
      chainedPayloadPointer = {
        paths: [...parentPayloadPointers.paths, ...fieldDetailsPaths]
      };
    } else if (!isEmpty(fieldDetails.payloadPointer) && !isEmpty(fieldDetails.payloadPointer.paths)) {
      chainedPayloadPointer = {
        paths: [...fieldDetails.payloadPointer.paths]
      };
    }

    let displayName = "";
    if (parentFieldDisplayName) {
      displayName = `${parentFieldDisplayName}.${fieldName}`;
    } else {
      displayName = fieldName;
    }

    const uiFieldDetails: UiBizfieldDetailsWithType = {
      fieldName,
      displayName,
      valueMapping: {
        payloadPointer: chainedPayloadPointer,
        fieldPaths: fieldDetails.paths,
        transform: null,
        returnType: fieldDetails.returnType,
        jsonPath: fieldDetails.jsonPath,
        dataTransformations: fieldDetails?.dataTransformations ?? []
      },
      rootPayloadPointer: topLevelPayloadPointer,
      type: fieldDetails.type
    };

    fieldsToDetails.set(displayName, uiFieldDetails);

    // If this further has more internal fields
    if (!isEmpty(fieldDetails.fields)) {
      getFieldsInternalRec(
        fieldDetails.fields,
        fieldsToDetails,
        topLevelPayloadPointer,
        chainedPayloadPointer,
        displayName
      );
    }
  }
};

export const getFieldValueFromPredicate = (predicate: SourceTreePredicate) => {
  let val = null;
  if (predicate?.value?.stringVal) {
    val = predicate.value.stringVal;
  } else if (predicate?.value?.booleanVal) {
    val = predicate.value.booleanVal;
  } else if (predicate?.value?.longVal) {
    val = predicate.value.booleanVal;
  } else if (predicate?.value?.doubleVal) {
    val = predicate.value.doubleVal;
  } else if (predicate.fieldValue) {
    val = predicate.fieldValue;
  }
  return val;
};

export const getLogicalOperationFromPredicate = (predicate: SourceTreePredicate) => {
  let op: LogicalOperation = null;

  if (predicate.operation) {
    // check if its a numberFilterOp or stringFilterOperation.
    if (predicate.operation.stringFilterOperation) {
      const stringOp = predicate.operation.stringFilterOperation as StringOperation;
      op = stringOp;
    }

    if (predicate.operation.numberFilterOperation) {
      const numOp = predicate.operation.numberFilterOperation as NumberOperation;
      op = numOp;
    }
    //all new filters will have comparisonOperation
    if (predicate.operation.comparisonOperation) {
      const comOp = predicate.operation.comparisonOperation as LogicalOperation;
      op = comOp;
    }
  }
  return op;
};

export const getFieldsNameFromFieldValueMapping = (fieldValueMapping: SourceValueMapping) => {
  let chainedParentFieldName = "";
  if (fieldValueMapping.payloadPointer && fieldValueMapping.payloadPointer?.paths?.length) {
    fieldValueMapping.payloadPointer.paths.forEach(path => {
      chainedParentFieldName = chainedParentFieldName
        ? `${chainedParentFieldName}.${path.segment || ""}`
        : `${path.segment || ""}`;
    });
    const fieldName = fieldValueMapping?.fieldPaths[0]?.segment || "";
    const fName = fieldName ? `.${fieldName}` : "";
    return chainedParentFieldName ? `${chainedParentFieldName}${fName}` : fieldName;
  } else {
    //below returns a field name from jsonPath excluding special characters
    //Ex: $.data[*].event.order_id --> .data.event.order_id
    const fieldName = fieldValueMapping.jsonPath.replace(/[^\w. ]/g, "");
    //we don't want first dot in the name (.data.event.order_id -> data.event.order_id)
    return fieldName.substring(1);
  }
};
