import {
  CurrencyType,
  IncButton,
  IncDateTimeFormat,
  IncFaIcon,
  IncRTable,
  IncRTableProps,
  IncSmartText,
  IncTextfield,
  TableDataColumn,
  getFormattedDateTime
} from "@inception/ui";
import React, { ChangeEvent, FC, useCallback, useMemo } from "react";
import { isEmpty, isNil, isObject, keys, values } from "lodash";
import { VerticallyCenteredRow } from "../../../../../../../../components";
import { RawDataResponse } from "../../../../../../../../services/api/explore";
import { CatalogWidgetProperties } from "../../../../../models";
import { EntityPropertySet, EntityPropertyValue, downloadCSVFromJsonRecords } from "../../../../../../../../core";
import { getFormattedValue } from "../../../common";

type Props = {
  isError: boolean;
  isFetching: boolean;
  error: string;
  selectionMap: Record<string, string>;
  rawEventsData?: RawDataResponse;
  currencyType?: CurrencyType;
  widgetProperties?: CatalogWidgetProperties;
  edit?: boolean;
  onColumnNameChange: (key: string, value: string) => void;
  enableDownload?: boolean;
};

type RawEventTableData = Record<string, EntityPropertyValue>;
type RawEventTableColumn = TableDataColumn<RawEventTableData>;

const getSortResult = (value1: string | number, value2: string | number) => {
  if (typeof value1 === "string" && typeof value2 === "string") {
    return value1.localeCompare(value2) > 0 ? "greater" : "lesser";
  }

  return value1 === value2 ? "equal" : value1 < value2 ? "lesser" : "greater";
};

const EventTable: FC<Props> = props => {
  const {
    currencyType,
    edit,
    error,
    isError,
    isFetching,
    onColumnNameChange,
    rawEventsData,
    selectionMap,
    widgetProperties,
    enableDownload = false
  } = props;

  const { data: rawEvents, entityLookupData } = rawEventsData || {};
  const { dataTypeCustomisation } = widgetProperties || {};

  const getColumnValue = useCallback(
    (rowData: RawEventTableData, key: string, sortValue = false) => {
      const singleRow = rowData?.[key] || {};
      const value = Object.values(singleRow)?.[0];
      let formattedValue = entityLookupData?.[String(value)] || value;

      if (singleRow.dateTimeVal !== undefined && singleRow.dateTimeVal !== null) {
        formattedValue = Number(singleRow.dateTimeVal);

        if (!sortValue) {
          formattedValue = getFormattedDateTime(formattedValue, IncDateTimeFormat.minimal);
        }
      }

      if (singleRow.booleanVal !== null && singleRow.booleanVal !== undefined) {
        formattedValue = Number(singleRow.booleanVal);

        if (!sortValue) {
          formattedValue = getFormattedValue("", formattedValue, "BOOLEAN", currencyType, dataTypeCustomisation);
        }
      }
      const setValues = (formattedValue as EntityPropertySet)?.values;
      if (setValues?.length) {
        return setValues.map(e => entityLookupData?.[e] || e).join(", ");
      }

      return formattedValue || "";
    },
    [currencyType, dataTypeCustomisation, entityLookupData]
  );

  const columns = useMemo<RawEventTableColumn[]>(() => {
    const tagsColumns: RawEventTableColumn[] = [];

    const selectionKeys = Object.keys(selectionMap);
    selectionKeys.forEach(key => {
      if (!isNil(selectionMap[key])) {
        const title = selectionMap[key];
        const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
          onColumnNameChange(key, e.target.value);
        };

        const column: RawEventTableColumn = {
          accessor: key as keyof RawEventTableData,
          header: (
            <TableHeader
              changeTitle={onChangeTitle}
              edit={edit}
              title={title}
            />
          ),
          sortable: true,
          renderer: (cellData: any, rowData: RawEventTableData) => {
            const formattedValue = getColumnValue(rowData, key);
            const isHTML =
              typeof formattedValue === "string" && formattedValue.startsWith("<") && formattedValue.endsWith(">");

            return (
              <VerticallyCenteredRow>
                {isHTML && (
                  <span
                    dangerouslySetInnerHTML={{ __html: String(formattedValue) }}
                    style={{
                      display: "inline-block",
                      width: "fit-content"
                    }}
                  />
                )}
                {!isHTML && (
                  <IncSmartText
                    className="marginRt6"
                    text={String(formattedValue)}
                  />
                )}
              </VerticallyCenteredRow>
            );
          },
          sortFn: (datumA, datumB, columnId) => {
            const valueA = getColumnValue(datumA, columnId, true);
            const valueB = getColumnValue(datumB, columnId, true);
            const numA = isNaN(Number(valueA)) ? valueA?.toString() : Number(valueA);
            const numB = isNaN(Number(valueB)) ? valueB?.toString() : Number(valueB);

            return getSortResult(numA, numB);
          },
          defaultSort: "desc",
          globalFilterFn: (rowData: RawEventTableData, globalFilter: string) => {
            const formattedValue = getColumnValue(rowData, key);
            return String(formattedValue).toLowerCase().includes(String(globalFilter).toLowerCase());
          }
        };

        tagsColumns.push(column);
      }
    });

    return tagsColumns;
  }, [edit, getColumnValue, onColumnNameChange, selectionMap]);

  const tableData = useMemo(() => {
    const rows: RawEventTableData[] = [];
    rawEvents?.forEach(val => {
      const tags = Object.keys(val?.tags || {});
      const enrichedTags: Record<string, EntityPropertyValue> = {};
      tags.forEach(e => {
        const dt = val.tags[e];
        enrichedTags[e] = dt;
      });

      enrichedTags[TIMESTAMP_KEY] = {
        dateTimeVal: val.timestampMillis.toString()
      };

      rows.push({
        ...enrichedTags
      });
    });

    return rows;
  }, [rawEvents]);

  const className = edit ? "events-table--fields-table" : `events-table--fields-table view-only-table`;

  const downloadData = useCallback(() => {
    const jsonList = tableData
      .map(row => {
        const object: Record<string, any> = {};
        keys(row).forEach(x => {
          const value = values(row[x])[0];
          if (!isObject(value)) {
            object[x] = entityLookupData[value as string] ? entityLookupData[value as string] : value;
          }
        });
        return object;
      })
      .filter(x => !isEmpty(x));

    downloadCSVFromJsonRecords(jsonList);
  }, [entityLookupData, tableData]);

  return (
    <div className={className}>
      <IncRTable
        columns={columns}
        data={tableData}
        errorDataMessage={error}
        globalFilter={globalFilter}
        hasError={isError}
        isLoading={isFetching}
        pagination={pagination}
        sort={defSort}
      >
        {enableDownload ? (
          <IncButton
            color="secondary-blue"
            onClick={downloadData}
          >
            <IncFaIcon
              className="marginRt8"
              iconName="download"
            />
            Download
          </IncButton>
        ) : null}
      </IncRTable>
    </div>
  );
};

type HeaderProps = {
  title: string;
  changeTitle: (event: ChangeEvent<HTMLInputElement>) => void;
  edit?: boolean;
};

const TableHeader: FC<HeaderProps> = props => {
  const { title, changeTitle, edit } = props;
  return edit ? (
    <IncTextfield
      onChange={changeTitle}
      value={title}
    />
  ) : (
    <IncSmartText text={title} />
  );
};

export default EventTable;

const globalFilter: IncRTableProps["globalFilter"] = {
  enabled: true,
  align: "left",
  hideLabel: true,
  hideStatus: true,
  placeholder: "Type a keyword"
};

const pagination: IncRTableProps["pagination"] = {
  enabled: true,
  viewMode: "minimal",
  pageSize: 10
};

const TIMESTAMP_KEY = "timeStamp";

const defSort = {
  accessor: TIMESTAMP_KEY,
  order: "desc"
} as any;
