import React, { FC, useCallback, useMemo } from "react";
import { IncToggle } from "@inception/ui";
import { WidgetCustomizationProps } from "./types";

export const TopNUICustomization: FC<WidgetCustomizationProps> = props => {
  const { properties, onPropertiesChange } = props;

  const showBarChart = useMemo(() => properties?.topN?.showBarChart, [properties?.topN?.showBarChart]);

  const updateWidgetRenderProp = useCallback(() => {
    onPropertiesChange(properties);
  }, [onPropertiesChange, properties]);

  const onCheckShowBarchart = useCallback(
    (check: boolean) => {
      if (properties.topN) {
        properties.topN.showBarChart = check;
      } else {
        properties.topN = {
          showBarChart: check
        };
      }
      updateWidgetRenderProp();
    },
    [properties, updateWidgetRenderProp]
  );

  return (
    <div className="customization-content">
      <div className="d-flex flex-column">
        <section className="section">
          <IncToggle
            checked={showBarChart}
            label="Show Bar Chart"
            onChange={onCheckShowBarchart}
          />
        </section>
      </div>
    </div>
  );
};
