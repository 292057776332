import React, { FC, useState, useCallback, useMemo, useEffect } from "react";
import {
  ButtonGroupOption,
  IncFaIconName,
  IncFaIcon,
  ButtonSelectionGroup,
  IncSelectOption,
  generateId
} from "@inception/ui";
import { isEmpty, intersection, difference, clone, groupBy, cloneDeep } from "lodash";
import { cx, css } from "emotion";
import {
  UserServiceFieldMetricConfig,
  EntityMeta,
  UserServiceFieldSliceSet,
  USEntityMeta
} from "../../../services/api/explore";
import { EventFieldQueryEditorProps, ExpressionEditorProps } from "../../../components";
import { MetricEditorTree } from "../components";
import { MetricsWithEventTypeAndId, MetricWithEventTypeAndId } from "../types";
import { TimeRange } from "../../../core";
import { featureFlagService } from "../../../services/feature-flags";
import { getDefaultMetricWithEventTypeAndId } from "../utils";

interface Props {
  entityTypeId: string;
  eventTypeInfos: EntityMeta[];
  eventTypeToBizEntityFieldNamesMap?: Record<string, string[]>;

  onChange: React.Dispatch<React.SetStateAction<MetricsWithEventTypeAndId>>;
  metricsWithEventTypeAndId: MetricsWithEventTypeAndId;
  expression: string;
  onExpressionChange: (nExpression: string) => void;
  getLatestTimeRange: () => TimeRange;
  className?: string;

  disableSliceSelection?: boolean;
  defaultSliceSet?: UserServiceFieldSliceSet;
}

export const AddMetricRenderer: FC<Props> = props => {
  const {
    onChange,
    eventTypeInfos,
    metricsWithEventTypeAndId,
    className,
    onExpressionChange: pOnExpressionChange,
    entityTypeId,
    getLatestTimeRange,
    expression: pExpression,
    disableSliceSelection = false,
    defaultSliceSet,
    eventTypeToBizEntityFieldNamesMap
  } = props;

  const isDebugMode = featureFlagService.isDebugMode();

  const [expression, setExpression] = useState(pExpression);
  const [wrapperHeight, setWrapperheight] = useState(0);

  useEffect(() => {
    setExpression(pExpression);
  }, [pExpression]);

  const onExpressionChange = useCallback(
    (expr: string) => {
      setExpression(expr);
      pOnExpressionChange && pOnExpressionChange(expr);
    },
    [pOnExpressionChange]
  );

  const numMetrics = metricsWithEventTypeAndId.length;
  const metricsExist = numMetrics > 0;

  const expressionOptions = useMemo(() => getOptionsForMetrics(numMetrics), [numMetrics]);

  const onMetricChange = useCallback(
    (idx: number, mode: "edit" | "delete" | "add", nConfigWithId: MetricWithEventTypeAndId) => {
      onChange(prev => {
        const next = clone(prev);

        if (mode === "edit") {
          next.splice(idx, 1, nConfigWithId);
        } else if (mode === "add") {
          const configToAdd = cloneDeep(nConfigWithId);
          configToAdd.configId = generateId();
          next.splice(idx, 0, configToAdd);
        } else {
          next.splice(idx, 1);
        }

        return next;
      });
    },
    [onChange]
  );

  const metricsValidationInfo = useMemo(() => {
    const validationInfo: string[] = [];

    metricsWithEventTypeAndId.forEach(configWithId => {
      const { aggregator, userServiceField } = configWithId.metricConfig;

      const usfValid = !isEmpty(userServiceField);
      const aggValid = !isEmpty(aggregator);
      const configValid = usfValid && aggValid;

      const message = configValid ? "" : !usfValid ? "Field selection cannot be empty" : "Aggregation cannot be empty";
      validationInfo.push(message);
    });

    return validationInfo;
  }, [metricsWithEventTypeAndId]);

  const eventTypeIdToInfoMap = useMemo<Record<string, USEntityMeta>>(() => {
    const map: Record<string, USEntityMeta> = {};
    eventTypeInfos.forEach(
      us =>
        (map[us.entityId] = {
          ...us,
          beFieldNames: eventTypeToBizEntityFieldNamesMap?.[us.entityId] || []
        })
    );
    return map;
  }, [eventTypeInfos, eventTypeToBizEntityFieldNamesMap]);

  const buttonGroupOptions = useMemo<ButtonGroupOption[]>(
    () =>
      (eventTypeInfos || []).map(us => {
        const { entityId, name, metadata } = us;
        const iconName = (metadata?.icon || "image") as IncFaIconName;
        const icon = (
          <IncFaIcon
            className="marginRt8"
            iconName={iconName}
          />
        );

        return {
          id: entityId,
          label: name,
          icon
        };
      }),
    [eventTypeInfos]
  );

  const selectedOptions = useMemo(() => {
    const selectedEventTypeIds = new Set<string>();
    metricsWithEventTypeAndId.forEach(({ eventTypeId }) => selectedEventTypeIds.add(eventTypeId));
    return buttonGroupOptions.filter(({ id }) => selectedEventTypeIds.has(id));
  }, [buttonGroupOptions, metricsWithEventTypeAndId]);

  const onEventTypeSelectionChange = useCallback(
    (nOpts: ButtonGroupOption | ButtonGroupOption[]) => {
      onExpressionChange("");
      const opts = Array.isArray(nOpts) ? nOpts : [nOpts];
      const nEventTypeIds = opts.map(({ id }) => id);
      const existingEventTypeIds = metricsWithEventTypeAndId.map(({ eventTypeId }) => eventTypeId);

      const configsToRetain = intersection(nEventTypeIds, existingEventTypeIds);
      const newOrOldEventTypes = difference(nEventTypeIds, existingEventTypeIds);
      const metricsByEventType = groupBy(metricsWithEventTypeAndId, "eventTypeId");
      const nMetricsWithEventTypeAndId: MetricsWithEventTypeAndId = [];

      configsToRetain.forEach(eventTypeId => {
        const configs = metricsByEventType[eventTypeId] || [];
        nMetricsWithEventTypeAndId.push(...configs);
      });

      newOrOldEventTypes.forEach(eventTypeId => {
        const configs = metricsByEventType[eventTypeId];
        // If configs exist it means the button is toggled, so skip them
        if (!configs) {
          const nConfigWithId = getDefaultMetricWithEventTypeAndId(eventTypeId);

          if (defaultSliceSet) {
            nConfigWithId.metricConfig.sliceSets = [defaultSliceSet];
          }

          nMetricsWithEventTypeAndId.push(nConfigWithId);
        }
      });

      onChange(nMetricsWithEventTypeAndId);
    },
    [defaultSliceSet, metricsWithEventTypeAndId, onChange, onExpressionChange]
  );

  const expressionEditorProps = useMemo<ExpressionEditorProps>(
    () =>
      numMetrics
        ? {
            expression,
            onChange: onExpressionChange,
            options: expressionOptions
          }
        : null,
    [expression, expressionOptions, numMetrics, onExpressionChange]
  );

  const metricEditorPropsRec = useMemo(() => {
    const metricEditorPropsRec: Record<string, EventFieldQueryEditorProps> = {};

    if (numMetrics) {
      let counter = 0;
      metricsWithEventTypeAndId.forEach((configWithId, idx) => {
        const { configId, eventTypeId, metricConfig, etcMetricDef } = configWithId;

        const eventTypeInfo = eventTypeIdToInfoMap[eventTypeId] || {
          name: eventTypeId,
          entityId: eventTypeId,
          entityType: entityTypeId
        };
        const queryId = String.fromCharCode(65 + counter++);
        const onChange = (nMetricConfig: UserServiceFieldMetricConfig) => {
          onMetricChange(idx, "edit", {
            configId,
            eventTypeId,
            metricConfig: nMetricConfig
          });
        };
        const onDuplicate = () => onMetricChange(idx, "add", configWithId);
        const onRemove = () => onMetricChange(idx, "delete", configWithId);

        const props: EventFieldQueryEditorProps = {
          eventTypeInfo,
          onChange,
          queryId,
          usFieldMetricConfig: metricConfig,
          error: metricsValidationInfo[idx],
          entityTypeId,
          onDuplicate,
          onRemove,
          getLatestTimeRange,
          displayImplicitSlice: true,
          canRemoveImplicitSlice: isDebugMode,
          disableSliceSelection,
          etcMetricDef
        };

        metricEditorPropsRec[configId] = props;
      });
    }

    return metricEditorPropsRec;
  }, [
    numMetrics,
    metricsWithEventTypeAndId,
    eventTypeIdToInfoMap,
    metricsValidationInfo,
    entityTypeId,
    getLatestTimeRange,
    isDebugMode,
    disableSliceSelection,
    onMetricChange
  ]);

  const editorWrapperClassName = useMemo(() => {
    const cssClassName = css`
      height: ${numMetrics ? wrapperHeight || 16 : 16}px;
    `;
    return cx("marginTp12 metrics-editor", cssClassName);
  }, [numMetrics, wrapperHeight]);

  return (
    <div className={className}>
      <ButtonSelectionGroup
        isMulti
        label="Select Event Type"
        onChange={onEventTypeSelectionChange}
        options={buttonGroupOptions}
        selectedOptions={selectedOptions}
        unstyled
      />
      <div className={editorWrapperClassName}>
        {metricsExist && (
          <MetricEditorTree
            expressionEditorProps={expressionEditorProps}
            metricEditorPropsRec={metricEditorPropsRec}
            onHeightChange={setWrapperheight}
          />
        )}
        {!metricsExist && (
          <div className="inc-text-subtext-medium marginBt12 status-warning">Select EventType(s) to add metric(s)</div>
        )}
      </div>
    </div>
  );
};

const getOptionsForMetrics = (numEventTypes: number) => {
  const options: IncSelectOption[] = [];

  if (numEventTypes === 1) {
    options.push(getOpt("None", ""));
    options.push(getOpt("A * 100"));
    options.push(getOpt("A + 100"));
    options.push(getOpt("A / 100"));
    options.push(getOpt("A - 100"));
  } else if (numEventTypes === 2) {
    options.push(getOpt("A / B"));
    options.push(getOpt("A + B"));
    options.push(getOpt("(A / B) * 100"));
    options.push(getOpt("(A + B) * B"));
    options.push(getOpt("(A - B) * B"));
  } else if (numEventTypes > 2) {
    options.push(getOpt("A / B"));
    options.push(getOpt("(A / B) * 100"));
    options.push(getOpt("A / C"));
    options.push(getOpt("(A / C) * 100"));
    options.push(getOpt("(A / B) * C"));
    options.push(getOpt("(A + B) * C"));
    options.push(getOpt("(A - B) * C"));
  }

  return options;
};

const getOpt = (label: string, value = label): IncSelectOption => ({
  label,
  value
});
