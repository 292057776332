import { useQuery, QueryResult } from "react-query";
import { useCallback, useRef, useMemo } from "react";
import axios, { CancelTokenSource } from "axios";
import { groupBy, cloneDeep, clone } from "lodash";
import { DataQuery, DataQueryRequest, DataQueryResponse } from "../../services/api/types";
import { logger } from "../../core";
import datasourceApiService from "../../services/api/DatasourceApiService";
import { entityEnricherRegistry } from "../../utils/EntityEnricher";

export interface WidgetQueryResult<T = unknown, TResult = Array<DataQueryResponse<T>>, TError = unknown>
  extends QueryResult<TResult, TError> {
  cancelTokenSource: CancelTokenSource;
}

export type WidgetQuery = <I, O>(
  dqr: DataQueryRequest<DataQuery>,
  widgetType: string,
  datasource?: string,
  queryOptions?: QueryOptions<I, O>
) => WidgetQueryResult<O>;

export interface QueryTransformer<I, O> {
  name?: string;
  transform(dqr: Array<DataQueryResponse<I>>): Promise<Array<DataQueryResponse<O>>>;
}

export interface QueryOptions<I, O> {
  compareDQR?: DataQueryRequest<DataQuery>; // add comparision DQR.
  transformer?: QueryTransformer<I, O>;
  enricherType?: "timeseries" | "explore";
}

export const useWidgetQuery: WidgetQuery = <I, O>(
  dqr: DataQueryRequest<DataQuery>,
  widgetType: string,
  datasource?: string,
  queryOptions?: QueryOptions<I, O>
): WidgetQueryResult<O> => {
  const queryKey = `widgetquery-${dqr.requestId}-${dqr.panelId}-${widgetType}-${dqr.startTime}-${dqr.endTime}`;
  const tokenSourceRef = useRef<CancelTokenSource>(null);
  const prevTokenSourceRef = useRef<CancelTokenSource>(null);

  const queryWithTransform = useCallback(
    (queryKey: string) => {
      // apply any data tranformations via query transformers
      // Add a cancel token to the dqr
      const source = axios.CancelToken.source();
      logger.debug("Widget query", `Created a new token for ${queryKey}`);
      const nTokenSource = source;
      dqr.cancelToken = nTokenSource.token;

      prevTokenSourceRef.current = clone(tokenSourceRef.current);
      tokenSourceRef.current = source;

      logger.debug("widget query", `Querying for ${queryKey}`);

      return query(queryKey, dqr, datasource, queryOptions);
    },
    [datasource, dqr, queryOptions]
  );

  const queryConfig = useMemo(
    () => ({
      enabled: false,
      refetchOnWindowFocus: false,
      cacheTime: 1,
      notifyOnStatusChange: true
    }),
    []
  );

  const { refetch: qRefetch, ...useQueryResult } = useQuery(queryKey, queryWithTransform, queryConfig);

  const refetch = useCallback(() => {
    if (prevTokenSourceRef.current) {
      prevTokenSourceRef.current.cancel("Query cancelled from query refetch");
      prevTokenSourceRef.current = null;
    }
    return qRefetch();
  }, [qRefetch]);

  return {
    ...useQueryResult,
    isSuccess: !dqr ? false : useQueryResult.isSuccess,
    refetch,
    cancelTokenSource: tokenSourceRef.current
  };
};

export const query = async <I, O>(
  queryKey: string,
  dqr: DataQueryRequest<DataQuery>,
  datasource?: string,
  queryOptions?: QueryOptions<I, O>
): Promise<Array<DataQueryResponse<O>>> => {
  const targets = dqr.targets || [];
  const promiseAll = [];
  const groupByDS = groupBy(targets, t => t.datasource);

  for (const [dsName, targetsByDS] of Object.entries(groupByDS)) {
    const groupDSName = !dsName || dsName === "undefined" || dsName === "mixed" ? datasource : dsName;
    targetsByDS.forEach(targetsByDS => {
      targetsByDS.datasource = groupDSName;
    });
    const clonedDQR = cloneDeep(dqr);
    clonedDQR.cancelToken = dqr.cancelToken;
    clonedDQR.targets = cloneDeep(targetsByDS);
    const instance = datasourceApiService.get(groupDSName);
    // FIX: Any typing here
    promiseAll.push(instance.query(clonedDQR as any));
  }

  if (queryOptions?.compareDQR) {
    const compareTargetDS = queryOptions?.compareDQR.targets[0].datasource;
    const instance = datasourceApiService.get(compareTargetDS || datasource);
    const clonedCompare = cloneDeep(queryOptions?.compareDQR);
    // FIX: Any typing here
    promiseAll.push(instance.query(clonedCompare as any));
  }

  try {
    const results = await (Promise.all(promiseAll) as unknown as Promise<Array<DataQueryResponse<I>>>);
    // Forcing this typing for I and O being same type and without transformer
    let transformed = results as any as Array<DataQueryResponse<O>>;

    const enrichPromises = results.map(async t => {
      if (queryOptions?.enricherType) {
        const { entities, data } = await entityEnricherRegistry.enrichData<O>(queryOptions.enricherType, t.data as any);
        t.data = data as any;
        t.entityContext = entities;
      }
    });

    await Promise.all(enrichPromises);

    if (queryOptions?.transformer) {
      try {
        logger.debug("widget query", `Applying transformer ${queryOptions?.transformer.name || ""} for ${queryKey}`);
        transformed = await queryOptions?.transformer.transform(results);
      } catch (error) {
        logger.error(
          "widget query",
          `Error while applying transformer ${queryOptions?.transformer.name || ""} for ${queryKey}`,
          error
        );
      }
    }

    return transformed;
  } catch (e) {
    logger.error("Query error", `Error while querying ${queryKey}`, e);
    return e as any;
  }
};
