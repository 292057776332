import React from "react";
import { NodeProps, Handle, Position } from "react-flow-renderer";
import { EventFieldQueryEditor, ExpressionEditor } from "../../../../components";
import { AddMetricTreeNode } from "./types";

type Props = NodeProps<AddMetricTreeNode>;

export const MetricEditorRenderer = (props: Props) => {
  const { data } = props;
  const { metricEditorProps } = data;

  return (
    <>
      <Handle
        isConnectable={false}
        position={Position.Right}
        type="source"
      />

      <EventFieldQueryEditor {...metricEditorProps} />
    </>
  );
};

export const ExpressionEditorRenderer = (props: Props) => {
  const { data } = props;
  const { expressionEditorProps } = data;

  return (
    <>
      <Handle
        isConnectable={false}
        position={Position.Left}
        type="target"
      />

      <ExpressionEditor {...expressionEditorProps} />
    </>
  );
};
