import {
  getFormattedDateTime,
  getInceptionTheme,
  IncDateTimeFormat,
  IncRTable,
  IncRTableProps,
  IncToolTip,
  TableDataColumn
} from "@inception/ui";
import React, { FC, useMemo, memo } from "react";
import { css } from "emotion";
import { OpStateList, OpStateStatus, OpState } from "../../../services/api/operationalise";

type Props = {
  opStateList: OpStateList;
  showRunHistoryLoading: boolean;
};

export const OpRunHistoryRenderer: FC<Props> = memo(props => {
  const { opStateList, showRunHistoryLoading, children } = props;

  const { opStatus } = opStateList;

  const columns = useMemo(() => getColumns(), []);
  const numRuns = opStatus.length;

  return (
    <div className="run-history ">
      <div className="inc-flex-row inc-flex-center-vertical">Showing Last {numRuns} Runs</div>

      <div className="run-history-table marginTp12">
        <IncRTable
          columns={columns}
          data={opStatus}
          isLoading={showRunHistoryLoading}
          noDataMessage="No Run History found."
          pagination={pagination}
        >
          <>{children}</>
        </IncRTable>
      </div>
    </div>
  );
});

const statusChipClass = css`
  height: 20px;
  width: 4px;
  border-radius: 4px;
`;

const pagination: IncRTableProps<OpState>["pagination"] = {
  enabled: true,
  viewMode: "minimal",
  pageSize: 10
};

const getColumns = (): Array<TableDataColumn<OpState>> => {
  const columns: Array<TableDataColumn<OpState>> = [
    {
      accessor: "status",
      header: "",
      width: 30,
      renderer: (state: OpStateStatus) => {
        const success = state === OpStateStatus.success;
        const failed = state === OpStateStatus.failed;
        const processing = state === OpStateStatus.processing;

        const color = success
          ? getInceptionTheme().inceptionColors.accentGreen
          : failed
            ? getInceptionTheme().inceptionColors.palette.N400
            : getInceptionTheme().inceptionColors.accentBlue;

        const statusText = success
          ? "finished successfully"
          : failed
            ? "failed"
            : processing
              ? "is in progress"
              : "is in ambiguous state";

        const tooltip = `This run ${statusText}`;

        const className = css`
          background: ${color};
        `;
        return (
          <IncToolTip
            placement="top"
            showArrow
            titleText={tooltip}
          >
            <div className="text-center">
              <div className={`${className} ${statusChipClass}`} />
            </div>
          </IncToolTip>
        );
      }
    },
    {
      accessor: "lastRunEpochMillis",
      header: "Last Run At",
      sortable: true,
      type: "datetime",
      renderer: (lastRunEpochMillis: string) => {
        const millis = parseInt(lastRunEpochMillis, 10);
        const formattedDateTime = getFormattedDateTime(millis, IncDateTimeFormat.minimal);
        return formattedDateTime;
      }
    },
    {
      accessor: "numAlertEvents",
      header: "Alert Events",
      sortable: true,
      type: "number"
    }
  ];

  return columns;
};
