import { IncCheckbox, IncRTable, TableDataColumn, useIsVisibleOnce } from "@inception/ui";
import { clone, cloneDeep, groupBy, isEmpty, isEqual, isString, throttle, uniq } from "lodash";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CypressConstants } from "@bicycle/tests";
import { Collapse, Expand } from "../../core/iconwrapper";
import { FieldPickerRow, FieldPickerSource } from "../../field-picker";
import { EntityMeta, FieldPickerContextDTO, PickerFieldType, UIUserService } from "../../services/api/explore";
import { FieldPickerUtils } from "../../utils/FieldPickerUtils";
import { MultipleSelects } from "../series-select";

interface PickerTableBodyProps<T extends PickerFieldType> {
  headerLabel: string;
  columns: Array<TableDataColumn<FieldPickerRow<T>>>;
  data: Array<FieldPickerRow<T>>;
  onSingleSelectionChange: (rowData: FieldPickerRow<T>, closePicker?: boolean) => void;
  hideSectionHeader?: boolean;
  isMulti?: boolean;
  onMultiSelectionChange?: (newSelectionData: Array<FieldPickerRow<T>>) => void;
  selectedOptions?: Array<FieldPickerRow<T>>;
  compareSelectedOptions?: (original: FieldPickerRow<T>, option: FieldPickerRow<T>) => boolean;
  // entity map should go away with design change
  entityMap?: Record<string, EntityMeta>;
  searchText?: string;
  fieldPickerContext: FieldPickerContextDTO;
}

const uskey = "Event Types";
const rootApiKey = "Root Apis";
const stringSourcesKey = "Sources";

function PickerTableBody<T extends PickerFieldType>(props: PickerTableBodyProps<T>) {
  const {
    headerLabel,
    columns,
    data: allData,
    onSingleSelectionChange,
    hideSectionHeader,
    isMulti,
    onMultiSelectionChange,
    searchText = "",
    fieldPickerContext,
    entityMap,
    ...restProps
  } = props;

  const tableRef = useRef<HTMLDivElement>();
  const { wasVisibleOnce } = useIsVisibleOnce(tableRef);

  const allUSExplicit = fieldPickerContext?.allUSExplicit || false;
  const isNounFirstConfig = isEmpty(fieldPickerContext?.entityId);
  const allUSCompatible = isNounFirstConfig && allUSExplicit;

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const [sourceFilters, setSourceFilters] = useState<Map<string, string[]>>(new Map());
  const [sourceOptions, setSourceOptions] = useState<Map<string, string[]>>(new Map());
  const [data, setData] = useState<Array<FieldPickerRow<T>>>([]);
  const [selectAllUserServices, setSelectAllUserServices] = useState(false);
  const curSearchText = useRef<string>(searchText);
  const prevAllDataRef = useRef<Array<FieldPickerRow<T>>>(null);

  const updateSourceOptions = useCallback(() => {
    // Check if data has changed
    const hasDataChanged = prevAllDataRef.current !== allData;
    hasDataChanged && (prevAllDataRef.current = allData);

    // Use the preselect if data has changed, else use the existing source filters state
    const sFilters = hasDataChanged ? getPreselectSourceMap(allData) : sourceFilters;
    const sourceOptions = getSourceOptions(allData, sFilters);
    const isEmptyFilter = (sFilters.get(uskey) ?? []).length === 0;

    // pre select all possible sources if none selected
    if (isEmptyFilter && sourceOptions.size > 0) {
      setSourceFilters(sourceOptions);
      setSelectAllUserServices(allUSCompatible);
    } else {
      // In preselect case, the isEmptyFilter will be false, so we need to set the preselect filters
      setSourceFilters(sFilters);
    }

    setSourceOptions(sourceOptions);
  }, [allData, allUSCompatible, sourceFilters]);

  useEffect(() => {
    updateSourceOptions();
  }, [updateSourceOptions]);

  const applyFiltersThrottled = useMemo(
    () =>
      throttle((labelText: string, sourceFilters: Map<string, string[]>) => {
        if (allData.length === 0) {
          return;
        }

        if (sourceFilters.size === 0 && labelText.length === 0) {
          setData(allData);
        }

        let filteredData = allData;
        if (sourceFilters.size > 0) {
          let uServicesFilters = sourceFilters.get(uskey) ?? [];
          uServicesFilters = uServicesFilters.filter(Boolean);
          const rootApisFilters = sourceFilters.get(rootApiKey) ?? [];
          const stringSources = sourceFilters.get(stringSourcesKey) ?? [];

          const isMatchingSource = (s: UIUserService) => {
            // if user services are not selected include all
            const matchesUs = uServicesFilters.length > 0 ? uServicesFilters.includes(s.userService.name) : true;
            // if root apis are not selected include all
            const matchesRootApi =
              s.rootApis.length > 1 && rootApisFilters.length > 0
                ? Boolean(rootApisFilters.find(r => s.rootApis.map(entity => entity.name).includes(r)))
                : true;
            return matchesUs && matchesRootApi;
          };

          // filter the data based on source filters
          filteredData = filteredData.filter(d => {
            const { sources } = d;
            // source is not string, its list of user services
            if ((sources[0] as UIUserService)?.userService) {
              if (uServicesFilters.length === 0 && rootApisFilters.length === 0) {
                return true;
              }
              if ((sources as UIUserService[]).find(isMatchingSource)) {
                return true;
              }
            }

            return (sources as string[]).find(s => stringSources.includes(s));
          });

          // alter the source shown in the table if user service filters are provided
          if (uServicesFilters.length > 0 || rootApisFilters.length > 0) {
            // not cloning will alter original data
            filteredData = cloneDeep(filteredData);
            filteredData.forEach(data => {
              if (!isString(data.sources[0])) {
                const newSources: UIUserService[] = [];
                const origSources = data.sources as UIUserService[];
                origSources.forEach(s => {
                  if (isMatchingSource(s)) {
                    newSources.push({
                      userService: s.userService,
                      rootApis:
                        s.rootApis.length > 1 && rootApisFilters.length > 0
                          ? s.rootApis.filter(r => rootApisFilters.includes(r.name))
                          : s.rootApis
                    });
                  }
                });
                data.sources = newSources;
              }
            });
          }
        }

        if (labelText.length > 0 && filteredData.length > 0) {
          const sanitizedRegEx = labelText.toLocaleLowerCase().replace(/[|\\{}()[\]^$+*?.]/g, "\\$&");
          filteredData = filteredData.filter(d => d.fieldLabel.toLowerCase().search(sanitizedRegEx) !== -1);
          if (filteredData.length === 0) {
            setCollapsed(true);
          }
        }

        // expand section if search text change
        if (curSearchText.current !== labelText && filteredData.length > 0) {
          curSearchText.current = labelText;
          setCollapsed(false);
        }

        setData(filteredData);
      }, 500),
    [allData]
  );

  useEffect(() => {
    applyFiltersThrottled(searchText.trim(), sourceFilters);
  }, [sourceFilters, searchText, applyFiltersThrottled]);

  const updateRowWithUserServices = useCallback(
    (row: FieldPickerRow<T>, sourceFilters: Map<string, string[]>) => {
      const clonedRow = cloneDeep(row) as FieldPickerRow<T>;
      if (row.fieldType === "userServiceField") {
        const usRow = clonedRow as FieldPickerRow<"userServiceField">;
        if (entityMap && sourceFilters.size > 0) {
          const usFilters = sourceFilters.get(uskey) || [];
          const rootApisFilters = sourceFilters.get(rootApiKey) || [];

          const usIdToUSTupleMap = groupBy(usRow.field.userServices, us => us.userServiceEntityId);
          const usIds = Object.keys(entityMap).filter(id => usFilters.includes(entityMap[id].name));
          const rootApiIds = Object.keys(entityMap).filter(id => rootApisFilters.includes(entityMap[id].name));

          const filteredUserServices = usRow.field.userServices.filter(us => {
            const matchesUs = usIds.length > 0 ? usIds.includes(us.userServiceEntityId) : true;
            const matchesRootApi =
              usIdToUSTupleMap[us.userServiceEntityId].length > 1 && rootApiIds.length > 0
                ? rootApiIds.includes(us.rootApi)
                : true;
            return matchesUs && matchesRootApi;
          });

          const fieldUserServices = selectAllUserServices ? [] : filteredUserServices;

          usRow.field.userServices = fieldUserServices;
          usRow.field.allUserService = selectAllUserServices;
        }
      }
      return clonedRow;
    },
    [entityMap, selectAllUserServices]
  );

  const onSourceChange = useCallback(
    (selectedOptions: Map<string, string[]>, id) => {
      const allUS = allData.map(data => data.sources).flat() as UIUserService[];

      const isUSChanged = id === uskey;

      setSourceFilters(filters => {
        const nValues = selectedOptions.get(id) ?? [];
        const newFilters = clone(filters);
        newFilters.set(id, nValues);

        if (isUSChanged) {
          const newSelectedUs = nValues;
          const curSelectedRootApis = filters.get(rootApiKey) ?? [];
          const allowedRootApisNames = getRootApisNamesForUserServiceNames(newSelectedUs, allUS);
          const filteredRootApiNames = curSelectedRootApis.filter(r => allowedRootApisNames.includes(r));

          const curSelectedUs = filters.get(uskey);
          const addedUS = newSelectedUs.filter(us => !curSelectedUs.includes(us));
          const addedRootApiNames = getRootApisNamesForUserServiceNames(addedUS, allUS);
          const newSelectedRootApiNames = filteredRootApiNames.concat(addedRootApiNames);

          newFilters.set(rootApiKey, newSelectedRootApiNames);
        }

        const selectedRow = allData.find(d => d.rowSelected);
        if (!isEqual(newFilters, filters) && selectedRow && !isMulti) {
          // if a row is already selected update it with latest user services selections.
          // And trigger selection change function
          const updatedRow = updateRowWithUserServices(selectedRow, newFilters);
          onSingleSelectionChange(updatedRow, false);
        }
        return newFilters;
      });
    },
    [allData, isMulti, updateRowWithUserServices, onSingleSelectionChange]
  );

  const sourceColumn = columns.find(c => c.accessor === "sources");
  const hasSources = Boolean(sourceColumn);

  const onRowClick = useCallback(
    (row: FieldPickerRow<T>) => {
      const _row = updateRowWithUserServices(row, sourceFilters);
      onSingleSelectionChange(_row);
    },
    [onSingleSelectionChange, sourceFilters, updateRowWithUserServices]
  );

  const onSelectionChange = useCallback(
    (rows: Array<FieldPickerRow<T>>) => {
      if (data.length === allData.length) {
        onMultiSelectionChange(rows);
        return;
      }
      // data and allData length are not same when theres a search text
      // the data filtered out by search text may contain selection and needed to be added to
      // selected rows returned by the table
      const currentSelections = allData.filter(row => row.rowSelected);
      const finalSelections: Array<FieldPickerRow<T>> = [];
      currentSelections.forEach(selRow => {
        if (!data.find(d => isEqual(d.field, selRow.field))) {
          finalSelections.push(selRow); // add selected rows filtered out by search text
        }
      });
      // add rows selected after text search
      finalSelections.push(...rows);
      onMultiSelectionChange(finalSelections);
    },
    [onMultiSelectionChange, data, allData]
  );

  const getFilterOptions = useCallback(() => {
    if (isNounFirstConfig) {
      return sourceOptions;
    }
    // if verb first experience
    const sourceOpClone = clone(sourceOptions);
    sourceOpClone.delete(uskey);
    return sourceOpClone;
  }, [isNounFirstConfig, sourceOptions]);

  const onSelectAllUSChange = useCallback((e: ChangeEvent, checked: boolean) => {
    setSelectAllUserServices(checked);
    setSourceFilters(prevFilters => {
      const nFilters = clone(prevFilters);
      const prevUsValues = prevFilters.get(uskey) ?? [];
      const nUsValues = checked ? [] : [...prevUsValues];
      nFilters.set(uskey, nUsValues);
      return nFilters;
    });
  }, []);

  const { attributes } = CypressConstants.components.FieldPickerV1;
  return (
    <div
      className="field-picker-table"
      ref={tableRef}
      {...restProps}
    >
      <div
        className="section-option-header"
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? <Expand /> : <Collapse />} {headerLabel} - ({data.length})
      </div>
      {!wasVisibleOnce && (
        <IncRTable
          addRowSelectionColumn={isMulti}
          classNames={{
            table: "section-body",
            header: "section-header",
            row: "section-row",
            body: "table-body"
          }}
          columns={columns}
          data={[]}
          density="compact"
          hideHeaders={hideSectionHeader}
          isLoading
          variant="transparent"
        />
      )}

      {wasVisibleOnce && !collapsed && allData.length > 0 && (
        <>
          <div className="field-picker-filter-row">
            {/* <div style={{ width: hasSources ? '250px' : '100%' }}>
              <IncTextfield
                className="marginRt8"
                onChange={onSearchTextChange}
                placeholder="Search by name"
                value={searchText} />
            </div> */}
            {hasSources && (
              <div className="inc-flex-grow">
                {allUSCompatible && (
                  <IncCheckbox
                    checked={selectAllUserServices}
                    label="All Event Types"
                    onChange={onSelectAllUSChange}
                  />
                )}
                <MultipleSelects
                  disabled={selectAllUserServices}
                  groupOptions={getFilterOptions()}
                  hideSelectIfNoOptions
                  onSeriesSelectionChanged={onSourceChange}
                  parentElmWidth={550}
                  selectedOptions={sourceFilters}
                />
              </div>
            )}
          </div>
          {allData.length > 0 && (
            <IncRTable
              addRowSelectionColumn={isMulti}
              classNames={{
                table: "section-body",
                header: "section-header",
                row: "section-row",
                body: "table-body"
              }}
              columns={columns}
              data={data}
              data-cy={attributes.fieldPickerFieldsTable}
              density="compact"
              hideHeaders={hideSectionHeader}
              onRowClick={onRowClick}
              onSelectionChange={onSelectionChange}
              sort={{
                accessor: "fieldLabel",
                order: "asc"
              }}
              variant="transparent"
            />
          )}
        </>
      )}
    </div>
  );
}

export const PickerTable = React.memo(PickerTableBody) as typeof PickerTableBody;

const getRootApisNamesForUserServiceNames = (usNames: string[], allUS: UIUserService[]) =>
  uniq(
    allUS
      .filter(us => usNames.includes(us.userService.name))
      .map(us => us.rootApis)
      .flat()
      .map(r => r.name)
  );

const getPreselectSourceMap = <T extends PickerFieldType>(allData: Array<FieldPickerRow<T>>) => {
  const map: Map<string, string[]> = new Map();
  const selectedRow = allData.find(row => row.rowSelected && row.fieldType === "userServiceField");
  if (!selectedRow) {
    return map;
  }

  const selectedSources = FieldPickerUtils.getSourcesToPreSelect(allData as Array<FieldPickerRow<"userServiceField">>);
  if (!selectedSources || selectedSources.length === 0) {
    return map;
  }
  const userServiceNames: Set<string> = new Set();
  const rootApiNames: Set<string> = new Set();
  const stringSourceNames: Set<string> = new Set();
  selectedSources?.forEach((source: FieldPickerSource) => {
    if (isString(source)) {
      stringSourceNames.add(source);
    } else {
      userServiceNames.add(source.userService.name);
      source.rootApis.forEach(rootApi => {
        rootApiNames.add(rootApi.name);
      });
    }
  });
  if (userServiceNames.size > 0) {
    map.set(uskey, Array.from(userServiceNames.values()));
  }
  if (rootApiNames.size > 0) {
    map.set(rootApiKey, Array.from(rootApiNames.values()));
  }
  if (stringSourceNames.size > 0) {
    map.set(stringSourcesKey, Array.from(stringSourceNames.values()));
  }

  return map;
};

const getSourceOptions = <T extends PickerFieldType>(
  allData: Array<FieldPickerRow<T>>,
  sourceFilters: Map<string, string[]>
) => {
  const sourceOptions = new Map<string, string[]>();

  if (allData.length > 0) {
    const userServiceNames: Set<string> = new Set();
    const rootApiNames: Set<string> = new Set();
    const stringSourceNames: Set<string> = new Set();
    const selectedUserServices = sourceFilters.get(uskey) ?? [];
    const isEmptyFilter = selectedUserServices.length === 0;

    allData.forEach(d => {
      d.sources?.forEach((source: FieldPickerSource) => {
        if (isString(source)) {
          stringSourceNames.add(source);
        } else {
          userServiceNames.add(source.userService.name);
          if ((isEmptyFilter || selectedUserServices.includes(source.userService.name)) && source.rootApis.length > 1) {
            source.rootApis.forEach(rootApi => {
              rootApiNames.add(rootApi.name);
            });
          }
        }
      });
    });

    if (userServiceNames.size > 0) {
      sourceOptions.set(uskey, Array.from(userServiceNames.values()));
    }
    if (rootApiNames.size > 0) {
      sourceOptions.set(rootApiKey, Array.from(rootApiNames.values()));
    }
    if (stringSourceNames.size > 0) {
      sourceOptions.set(stringSourcesKey, Array.from(stringSourceNames.values()));
    }
  }

  return sourceOptions;
};
