import React, { FC, useEffect, useCallback, useState, useMemo, memo } from "react";
import { IncFaIcon, IncModal, IncModalProps, IncTextfield, IncToggle } from "@inception/ui";
import { cx, css } from "emotion";
import { isEmpty } from "lodash";
import { ImpactedWidget, BizDataQuery, UserServiceFieldSliceSet } from "../../../../services/api/explore";
import { VerticallyCenteredRow } from "../../../../components/flex-components";
import { DerivedNodeEditor } from "../../../../biz-flow/nodes-and-edges/DerivedNodeEditor";
import { UIBizFlowNode } from "../../../../biz-flow/types";
import { IncDataTypeSelect, PrimKindMap } from "../../../../components/data-type";
import { getAllDataTypeOptions, MappingType, DataTypeOption } from "../../../../components/data-type/utils";
import { useToggleState, FieldPrimType, FieldSubType, useRefState } from "../../../../core";

interface Props {
  open: boolean;
  onClose: () => void;

  impactedWidget: ImpactedWidget;
  onChange: (impactedWidget: ImpactedWidget) => void;

  disableSliceSelection?: boolean;
  disablePrimaryToggle?: boolean;
  disableEntityTypeChange?: boolean;

  defaultSliceSet?: UserServiceFieldSliceSet;
}

export const AddImpactedWidgetModal: FC<Props> = memo(props => {
  const {
    open,
    onClose,
    impactedWidget,
    onChange,
    disableSliceSelection = false,
    disablePrimaryToggle = false,
    disableEntityTypeChange = false,
    defaultSliceSet
  } = props;

  const impactedWidgetRef = useRefState(impactedWidget);

  const { toggle: toggleAdvanced, isOpen: isAdvancedOpen } = useToggleState();

  const allDataTypeOptions = useMemo(() => getAllDataTypeOptions(MappingType.event), []);

  const { name, isPrimary: pIsPrimary, bizDataQuery, dataType = "_str", subType = "none" } = impactedWidget;

  const { isOpen: isPrimary, setIsOpen: setIsPrimary } = useToggleState(pIsPrimary);

  const [node, setNode] = useState<UIBizFlowNode>(getNode(bizDataQuery, name));
  const [error, setError] = useState<string>();

  const [dataTypeOption, setDataTypeOption] = useState(
    getDataTypeSelectionOption(dataType, subType, allDataTypeOptions)
  );

  useEffect(() => {
    setNode(getNode(bizDataQuery, name));
  }, [bizDataQuery, name]);

  const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNode(prev => ({
      ...prev,
      label: value
    }));
  }, []);

  const onIsPrimaryChange = useCallback(
    (value: boolean) => {
      setIsPrimary(value);
    },
    [setIsPrimary]
  );

  const onSave = useCallback(() => {
    const impactedWidget = impactedWidgetRef.current;

    const { value = "_str", kindDescriptor } = dataTypeOption || {};

    const { label: name, bizDataQuery } = node;

    if (bizDataQuery?.widgetConfig) {
      bizDataQuery.widgetConfig.name = name;
    }

    onChange({
      ...impactedWidget,
      isPrimary,
      bizDataQuery,
      name,
      dataType: value as FieldPrimType,
      subType: kindDescriptor?.type || "none"
    });
    onClose();
  }, [dataTypeOption, impactedWidgetRef, isPrimary, node, onChange, onClose]);

  const queryError = useMemo(() => {
    const metrics = node?.bizDataQuery?.widgetConfig?.dataDefinition?.metrics || {};

    if (isEmpty(metrics)) {
      return "Invalid metric";
    }

    return error;
  }, [error, node]);

  const canSave = !queryError;

  const actions = useMemo<IncModalProps["actions"]>(
    () => ({
      primary: {
        id: "common.actions.save",
        onClick: onSave,
        disabled: !canSave
      },
      secondary: {
        id: "common.actions.cancel",
        onClick: onClose
      }
    }),
    [canSave, onClose, onSave]
  );

  const { label } = node;

  return (
    <IncModal
      actions={actions}
      disableFocusOnLoad
      onClose={onClose}
      show={open}
      showClose
      size="xlg"
      titleText="Add custom metric"
    >
      <div className={className}>
        <VerticallyCenteredRow className="width-100">
          <IncTextfield
            label="Name"
            onChange={onNameChange}
            placeholder="Enter name"
            value={label}
          />

          <VerticallyCenteredRow className="marginLtAuto">
            {!disablePrimaryToggle && (
              <IncToggle
                checked={isPrimary}
                label="Primary"
                onChange={onIsPrimaryChange}
              />
            )}
          </VerticallyCenteredRow>
        </VerticallyCenteredRow>

        <div className="width-100 marginTp12">
          <DerivedNodeEditor
            defaultSliceSet={defaultSliceSet}
            disableSliceSelection={disableSliceSelection}
            hideEntityTypeSelector={disableEntityTypeChange}
            node={node}
            setError={setError}
            setNode={setNode}
          />
        </div>

        <VerticallyCenteredRow
          className="width-100 marginTp12 inc-cursor-pointer status-info inc-text-subtext"
          onClick={toggleAdvanced}
        >
          <IncFaIcon
            className="collapse-arrow marginRt12"
            data-expanded={isAdvancedOpen}
            iconName="caret-down"
          />
          {isAdvancedOpen ? "Hide Advanced" : "Show Advanced"}
        </VerticallyCenteredRow>

        {isAdvancedOpen && (
          <div className="width-100 marginTp8 marginLt24 paddingRt24">
            <IncDataTypeSelect
              label="Data type"
              onSelect={setDataTypeOption}
              selected={dataTypeOption}
            />
          </div>
        )}
      </div>
    </IncModal>
  );
});

const cssClassName = css`
  background: #2a343e !important;

  .ui-biz-flow--derived-node-editor {
    background: transparent;
    padding: 0px;
  }
`;
const className = cx("inc-card-layout display-block width-100 marginBt12", cssClassName);

const getNode = (bizDataQuery: BizDataQuery, label: string): UIBizFlowNode => ({
  id: "",
  label,
  position: {
    x: 0,
    y: 0
  },
  renderType: "single",
  type: "derived",
  bizDataQuery
});

const getDataTypeSelectionOption = (
  dataType: FieldPrimType,
  subType: FieldSubType,
  allDataTypeOptions: DataTypeOption[]
): DataTypeOption => {
  if (dataType) {
    // Doing this to support STRING and _str
    dataType = dataType.startsWith("_") ? dataType : PrimKindMap[dataType]?.kind || dataType;

    //if fieldDef has subtype, then DataTypeSelector should show corresponding value/label
    const selectedDataType = allDataTypeOptions.find(o => {
      if (subType && subType !== "none") {
        return o.value === dataType && o?.kindDescriptor?.type === subType;
      }
      return o.value === dataType;
    });
    return selectedDataType ?? allDataTypeOptions[0];
  }

  return null;
};
