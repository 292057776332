import React, { FC, useMemo, useRef, useCallback, useState, useEffect, CSSProperties } from "react";
import ReactFlow, {
  Node,
  Edge,
  ReactFlowProvider,
  Position,
  ConnectionMode,
  NodeChange,
  Handle
} from "react-flow-renderer";
import { EventFieldQueryEditorProps, ExpressionEditorProps } from "../../../../components";
import { AddMetricTreeNode } from "./types";
import { MetricEditorRenderer, ExpressionEditorRenderer } from "./Renderers";

interface Props {
  metricEditorPropsRec: Record<string, EventFieldQueryEditorProps>;
  expressionEditorProps: ExpressionEditorProps;
  onHeightChange: (nHeight: number) => void;
}

type TreeNode = Node<AddMetricTreeNode>;

const EXPR_EDITOR_ID = "expression_editor";
const EXPR_EDITOR_FAKE_ID = "expression_editor_dummy";
const EXPR_EDITOR_HEIGHT = 50;
const EXPR_EDITOR_WIDTH = 200;
const METRIC_EDITOR_HEIGHT = 324;
const BUFFER_X = 50;
const BUFFER_Y = 25;
const EXPR_EDITOR_TYPE = "expression-editor";
const EXPR_EDITOR_FAKE_TYPE = "expression-editor-fake";
const METRIC_EDITOR_TYPE = "metric-editor";

export const MetricEditorTree: FC<Props> = props => {
  const { metricEditorPropsRec, expressionEditorProps, onHeightChange } = props;

  const { clientWidth } = document.body;
  const metricEditorWidth = Math.floor(clientWidth * 0.45);

  const [nodes, setNodes] = useState<TreeNode[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);

  const heightMap = useRef<Record<string, number>>({});

  const updateNodesAndEdges = useCallback(
    (changes: NodeChange[]) => {
      let updateNodes = false;
      changes.forEach(entry => {
        if (entry.type === "dimensions") {
          const { dimensions, id } = entry;
          heightMap.current[id] = dimensions.height;
          updateNodes = true;
        }
      });

      if (updateNodes) {
        const { edges, nodes } = computeLayout(
          heightMap.current,
          metricEditorPropsRec,
          expressionEditorProps,
          metricEditorWidth
        );

        setNodes(nodes);
        setEdges(edges);
      }
    },
    [expressionEditorProps, metricEditorPropsRec, metricEditorWidth]
  );

  useEffect(() => {
    heightMap.current = {};

    const { edges, nodes } = computeLayout(
      heightMap.current,
      metricEditorPropsRec,
      expressionEditorProps,
      metricEditorWidth
    );

    setEdges(edges);
    setNodes(nodes);

    const curHeight = Object.values(heightMap.current).reduce((prev, curr) => prev + curr, 0);
    onHeightChange(curHeight);
  }, [expressionEditorProps, metricEditorPropsRec, metricEditorWidth, onHeightChange]);

  const nodeTypes = useMemo(
    () => ({
      [EXPR_EDITOR_TYPE]: ExpressionEditorRenderer,
      [METRIC_EDITOR_TYPE]: MetricEditorRenderer,
      [EXPR_EDITOR_FAKE_TYPE]: DotRenderer
    }),
    []
  );

  return (
    <ReactFlowProvider>
      <ReactFlow
        className="metrics-editor-tree"
        connectionMode={ConnectionMode.Loose}
        contentEditable={false}
        draggable={false}
        edges={edges}
        maxZoom={1}
        minZoom={1}
        nodeTypes={nodeTypes}
        nodes={nodes}
        onNodesChange={updateNodesAndEdges}
        panOnDrag={false}
        panOnScroll={false}
        preventScrolling={false}
      />
    </ReactFlowProvider>
  );
};

const computeLayout = (
  heightMap: Record<string, number>,
  metricEditorPropsRec: Record<string, EventFieldQueryEditorProps>,
  expressionEditorProps: ExpressionEditorProps,
  metricEditorWidth: number
) => {
  const nodes: TreeNode[] = [];
  const edges: Edge[] = [];

  let y = 0;

  const configIds = Object.keys(metricEditorPropsRec);
  configIds.forEach(configId => {
    const metricEditorProps = metricEditorPropsRec[configId];

    const { queryId } = metricEditorProps;
    const nodeId = `${configId}-${queryId}`;

    const edge: Edge = {
      id: `${configId} - ${EXPR_EDITOR_ID} - ${queryId}`,
      source: nodeId,
      target: EXPR_EDITOR_FAKE_ID,
      style: {
        stroke: "#4A505C",
        strokeWidth: 1
      }
    };

    const height = heightMap[configId] || METRIC_EDITOR_HEIGHT;

    heightMap[configId] = height;

    const node: TreeNode = {
      data: {
        expressionEditorProps: null,
        metricEditorProps
      },
      id: nodeId,
      type: METRIC_EDITOR_TYPE,
      position: {
        x: 0,
        y
      },
      width: metricEditorWidth,
      height,
      draggable: false,
      selectable: false,
      sourcePosition: Position.Right
    };

    y += height + BUFFER_Y;

    nodes.push(node);
    Boolean(expressionEditorProps) && edges.push(edge);
  });

  const numMetrics = configIds.length;
  if (expressionEditorProps && Boolean(numMetrics)) {
    const bufferHeight = (BUFFER_Y + 58 - 25) * (numMetrics - 1);
    const effY = y - bufferHeight;
    const exprY = effY * 0.5;

    const exprEditorFakeNode: TreeNode = {
      data: {
        expressionEditorProps: null,
        metricEditorProps: null
      },
      id: EXPR_EDITOR_FAKE_ID,
      type: EXPR_EDITOR_FAKE_TYPE,
      width: 10,
      height: 50,
      position: {
        x: metricEditorWidth + BUFFER_X,
        y: exprY - 6
      },
      positionAbsolute: {
        x: metricEditorWidth + BUFFER_X,
        y: exprY - 6
      },
      draggable: false,
      selectable: false,
      sourcePosition: Position.Right
    };

    const exprEditorNode: TreeNode = {
      data: {
        expressionEditorProps,
        metricEditorProps: null
      },
      id: EXPR_EDITOR_ID,
      type: EXPR_EDITOR_TYPE,
      width: EXPR_EDITOR_WIDTH,
      height: EXPR_EDITOR_HEIGHT,
      position: {
        x: metricEditorWidth + BUFFER_X + 30,
        y: exprY
      },
      positionAbsolute: {
        x: metricEditorWidth + BUFFER_X + 30,
        y: exprY
      },
      draggable: false,
      selectable: false
    };

    const edge: Edge = {
      id: `${EXPR_EDITOR_FAKE_ID} - ${EXPR_EDITOR_ID}`,
      source: EXPR_EDITOR_FAKE_ID,
      target: EXPR_EDITOR_ID,
      style: {
        stroke: "#4A505C",
        strokeWidth: 1
      }
    };

    nodes.push(exprEditorFakeNode, exprEditorNode);
    edges.push(edge);
  }

  return {
    nodes,
    edges
  };
};

const DotRenderer = () => {
  const dotStyle: CSSProperties = {
    height: 4,
    width: 4,
    border: "1px solid #4a505c",
    background: "#4a505c",
    borderRadius: "50%"
  };

  const style: CSSProperties = {
    height: EXPR_EDITOR_HEIGHT,
    width: 4,
    paddingTop: 23
  };

  return (
    <>
      <Handle
        isConnectable={false}
        position={Position.Left}
        type="target"
      />

      <Handle
        isConnectable={false}
        position={Position.Right}
        type="source"
      />

      <div
        className="inc-flex-row inc-flex-vertical-center"
        style={style}
      >
        <div style={dotStyle} />
      </div>
    </>
  );
};
