import { createAction } from "@reduxjs/toolkit";
import { EventGroup, EventGroupResponse } from "../../../platform/services/api/auto-discovery/types";
import {
  EventEntityDetailsData,
  EventTypeFieldMappingDetails,
  MappingGroupDetailsData,
  MappingSourceDetailsData
} from "../types";

export const SET_EVENT_GROUP_RESPONSE = "set-event-group-response";
export const DELETE_EVENT_GROUP = "delete-event-group";
export const UPDATE_EVENT_GROUP = "update-event-group";
export const UPDATE_EVENT_TYPE_SUMMARY_NODES = "update-event-type-summary-node";
export const RESET_EVENT_TYPE_SUMMARY_NODES = "reset-event-type-summary-node";
export const SET_MAPPING_SOURCE_DETAILS_DATA = "set-mapping-source-details-data";
export const SET_MAPPING_DETAILS_DATA = "set-mapping-details-data";
export const SET_EVENT_ENTITY_DETAILS_DATA = "set-event-entity-details-data";

export const setEventGroupResponse = createAction<EventGroupResponse>(SET_EVENT_GROUP_RESPONSE);
export const deleteEventGroup = createAction<EventGroup>(DELETE_EVENT_GROUP);
export const updateEventGroup = createAction<EventGroup>(UPDATE_EVENT_GROUP);
export const updateEventTypeSummaryNodes = createAction<EventTypeFieldMappingDetails>(UPDATE_EVENT_TYPE_SUMMARY_NODES);
export const resetEventTypeSummaryNodes = createAction(RESET_EVENT_TYPE_SUMMARY_NODES);
export const setMappingSourceDetailsData = createAction<MappingSourceDetailsData>(SET_MAPPING_SOURCE_DETAILS_DATA);
export const setMappingDetailsData = createAction<MappingGroupDetailsData>(SET_MAPPING_DETAILS_DATA);
export const setEventEntityDetailsData = createAction<EventEntityDetailsData>(SET_EVENT_ENTITY_DETAILS_DATA);
