import React from "react";
import { IncFaIcon } from "../../icons";

type Position = "topcenter" | "center" | "topleft" | "right";
interface LoadingSpinnerProps {
  label?: string;
  position?: Position;
  className?: string;
  color?: string;
}

const IncLoadingSpinner: React.FC<LoadingSpinnerProps> = (props: LoadingSpinnerProps) => {
  const { label = "Loading...", position = "center", className: pClassName, color = "white" } = props;

  const className = `inc-flex-row inc-flex-grow loadingspinner ${pClassName}`;

  return (
    <>
      <div className={className}>
        <div className={`inc-flex-grow ${position}`}>
          <div className="inc-flex-row inc-flex-center-vertical">
            <IncFaIcon
              className="marginRt8"
              color={color}
              iconName="spinner"
              spin
            />
            {label}
          </div>
        </div>
      </div>
    </>
  );
};

export default IncLoadingSpinner;
