import { useCallback, useState } from "react";
import BaseWidgetImpl from "../../../model-impl/BaseWidgetImpl";
import { dashboardApi, DashboardListItem } from "../../../api";
import { useInceptionRoute, generateId, useLoggedInUserInfo } from "../../../../core";
import { routePaths } from "../../../../../app/RoutePaths";
import DashboardModel, { PartialWidgetLayout } from "../../../models/DashboardModel";
import { DashboardImpl } from "../../..";
import BaseWidgetModel from "../../../models/BaseWidgetModel";
import { dashboardUtils } from "../../../dashboard-grid";
import { AddToDashboardProps } from "./types";

interface Props<T extends BaseWidgetImpl> extends AddToDashboardProps<T> {
  selection: DashboardListItem[];
  newDashboardName: string;
  openDashboardsAfterAdd: boolean;

  onError?: (error: string) => void;
  onAddSuccess: () => void;
}

export const useAddToDashboard = <T extends BaseWidgetImpl>(props: Props<T>) => {
  const [addInProgress, setAddInProgress] = useState(false);

  const { navigate } = useInceptionRoute();
  const userInfo = useLoggedInUserInfo();

  const {
    widgets,
    openDashboardsAfterAdd,
    selection,
    newDashboardName,
    onError,
    onAddSuccess,
    getWidgetsAsync,
    layouts
  } = props;

  const postAddToDashboard = useCallback(
    (successDbIds: string[]) => {
      if (openDashboardsAfterAdd) {
        successDbIds.forEach(dbId => {
          const path = `${routePaths.dashboards}/${dbId}`;
          navigate(path, { newTab: true });
        });
      }

      if (successDbIds?.length) {
        onAddSuccess();
      }
    },
    [navigate, onAddSuccess, openDashboardsAfterAdd]
  );

  const addToDashboard = useCallback(async (): Promise<[boolean, string, boolean]> => {
    let hasError = false;
    const errors: string[] = [];

    const dashboardsPromises = selection.map(dbItem => dashboardApi.getDashboard(dbItem.id));
    const dbModelResults = await Promise.all(dashboardsPromises);

    const widgetsPromise = getWidgetsAsync || emptyResultPromise;
    const asyncWidgets = await widgetsPromise();

    const allWidgets = [...widgets, ...asyncWidgets];
    const widgetModels = allWidgets.map(w => {
      const model = w.getSaveModel();
      model.id = generateId();
      delete model.meta;
      return model;
    });

    const dbSaveModels: DashboardModel[] = [];
    dbModelResults.forEach((dbRes, i) => {
      const { data: dbModel, error } = dbRes;

      if (!error && dbModel) {
        const dbSaveModel = getUpdatedDashboardModel(dbModel, widgetModels, layouts);
        dbSaveModels.push(dbSaveModel);
      } else {
        hasError = true;
        errors.push(`Fetch failed for dashboard ${selection[i].name}`);
      }
    });

    const dbUpdateRequests = dbSaveModels.map(dbSaveModel => dashboardApi.updateDashboard(dbSaveModel, userInfo));
    if (newDashboardName) {
      const partModel: Partial<DashboardModel> = { name: newDashboardName };
      const dbSaveModel = getUpdatedDashboardModel(partModel, widgetModels, layouts);
      dbSaveModels.push(dbSaveModel);
      dbUpdateRequests.push(dashboardApi.createDashboard(dbSaveModel, userInfo));
    }

    const dbUpdateResults = await Promise.all(dbUpdateRequests);
    const successIds: string[] = [];

    dbUpdateResults.forEach((dbUpdateResult, i) => {
      const { error } = dbUpdateResult;
      const dbModel = dbSaveModels[i];
      const { name, id } = dbModel;

      if (error) {
        hasError = true;
        errors.push(`Add to dashboard failed for dashboard ${name}`);
      } else {
        successIds.push(id);
      }
    });

    postAddToDashboard(successIds);

    return [hasError, errors.join("\n"), true];
  }, [getWidgetsAsync, layouts, newDashboardName, postAddToDashboard, selection, userInfo, widgets]);

  const addToDashboardWrapper = useCallback(async (): Promise<[string, boolean]> => {
    setAddInProgress(true);
    const [error, message, isDashboardAdd] = await addToDashboard();
    const errMsg = error ? message : "";
    setAddInProgress(false);
    if (onError && errMsg) {
      onError(errMsg);
    }
    return [errMsg, isDashboardAdd];
  }, [onError, addToDashboard]);

  return {
    addToDashboardCallback: addToDashboardWrapper,
    addInProgress
  };
};

const emptyResultPromise = <T extends BaseWidgetImpl>() => Promise.resolve([] as T[]);

const getUpdatedDashboardModel = <T extends BaseWidgetModel>(
  dbModel: Partial<DashboardModel>,
  widgetModels: T[],
  layouts: PartialWidgetLayout[],
  apptuitDbId?: number
) => {
  const dbImpl = new DashboardImpl(dbModel, apptuitDbId);
  widgetModels.forEach((widgetModel, idx) => {
    const layout = layouts?.[idx] || dashboardUtils.getDefaultLayoutForWidget(widgetModel);
    dbImpl.addWidget(widgetModel, layout);
  });
  return dbImpl.getSaveModel();
};
