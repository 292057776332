import React, { FC, useCallback, CSSProperties, MouseEvent, useMemo } from "react";
import { IncButton, IncMenu, IncMenuItemProps, IncMenuToggleProps, IncToolTip } from "@inception/ui";
import { widgetRegistry } from "../WidgetRegistry";

interface Props {
  widgetType: string;
  onVizChange: (newType: string) => void;
  variant: "property" | "menu";
  widgetTypeOptions?: string[];
}

const WidgetVizSwitcher: FC<Props> = props => {
  const { onVizChange, variant, widgetType, widgetTypeOptions } = props;

  const widgetConf = widgetRegistry.getPropsByWidgetId(widgetType);
  const widgetIcon = widgetConf?.iconComponent() || "I";

  const noOp = useCallback(() => {}, []);

  const getIcon = useCallback(
    (icon: any, withMargin = false, onClick = noOp) => {
      const wrapperStyles: CSSProperties = {
        height: "24px",
        width: "24px",
        marginRight: withMargin ? 8 : 0
      };

      const iconWrapperStyles: CSSProperties = {
        transform: "scale(0.8)"
      };

      return (
        <span
          className="icon-btn inc-flex-row"
          onClick={onClick}
          style={wrapperStyles}
        >
          <span
            className="inc-flex-row"
            style={iconWrapperStyles}
          >
            {icon}
          </span>
        </span>
      );
    },
    [noOp]
  );

  const widgetVizMenuOptions = useCallback(
    (itemProps: IncMenuItemProps) => {
      const { className, closeMenu } = itemProps;

      const widgetActionClassName = `widget-action ${className}`;

      const vizOptions: JSX.Element[] = [];
      widgetRegistry.getAllWidgetConfigs().forEach(config => {
        const { enableInDashboard, id, label, iconComponent } = config;

        const onClick = (e: MouseEvent) => {
          onVizChange(id);
          closeMenu(e.nativeEvent);
        };

        if (enableInDashboard && id !== widgetType && id !== "entity-data") {
          vizOptions.push(
            <div
              className={widgetActionClassName}
              key={id}
              onClick={onClick}
            >
              {getIcon(iconComponent(), true)}
              {label}
            </div>
          );
        }
      });
      return <>{vizOptions}</>;
    },
    [getIcon, onVizChange, widgetType]
  );

  const widgetToggle = useCallback(
    (toggleProps: IncMenuToggleProps) => {
      const { show, toggleMenu } = toggleProps;

      const onClick = () => {
        toggleMenu(!show);
      };
      return (
        <IncToolTip
          placement="top"
          titleText="Switch visualization"
        >
          {getIcon(widgetIcon, false, onClick)}
        </IncToolTip>
      );
    },
    [getIcon, widgetIcon]
  );

  const widgetButtons = useMemo(() => {
    const buttons: JSX.Element[] = [];
    const allWidgetConfigs = widgetRegistry.getAllWidgetConfigs();
    const allowedWidgetConfig = widgetTypeOptions
      ? allWidgetConfigs.filter(config => widgetTypeOptions.includes(config.id))
      : allWidgetConfigs;

    allowedWidgetConfig.forEach((config, i) => {
      const { iconComponent, id, enableInDashboard, label } = config;
      if (enableInDashboard) {
        const className = `${i !== allowedWidgetConfig.length - 1 ? "marginRt8" : ""} ${widgetType === id ? "active" : "inactive"}`;

        buttons.push(
          <IncToolTip
            key={id}
            placement="top"
            titleText={label}
          >
            <IncButton
              className={className}
              color="secondary"
              onClick={() => onVizChange(id)}
            >
              {iconComponent()}
            </IncButton>
          </IncToolTip>
        );
      }
    });

    return (
      <div className="widget-properties widget-type-viz">
        <div className="widget-properties--section section-open">
          <div className="section-name">Chart Type</div>
          <div className="inc-flex-row">{buttons}</div>
        </div>
      </div>
    );
  }, [onVizChange, widgetType, widgetTypeOptions]);

  const vizMenu = useMemo(
    () => (
      <IncMenu
        className="widget-actions"
        defaultFocus
        items={widgetVizMenuOptions}
        toggle={widgetToggle}
      />
    ),
    [widgetToggle, widgetVizMenuOptions]
  );

  return variant === "property" ? widgetButtons : vizMenu;
};

export default WidgetVizSwitcher;
