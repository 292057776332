import React, { FC, useCallback, useMemo } from "react";
import { IncButton } from "@inception/ui";
import {
  OpCreationConfig,
  OpCreationFragmentEditType,
  operationaliseV2ApiService
} from "../../services/api/operationalise";
import { OpFragment, OpFragmentType } from "../../services/api/chat";
import { logger } from "../../core";
import {
  EventCriteriaFragmentEditor,
  KPIWithComparatorFragmentEditor,
  KPIWithComparatorAndTrendFragmentEditor,
  LookBackFragmentEditor,
  ScheduleFragmentEditor,
  StartTriggerFragmentEditor,
  SuppressionsFragmentEditor,
  ThresholdFragmentEditor,
  BizActionFragmentEditor,
  SeasonalityOverridesFragmentEditor,
  HistoryToConsiderFragmentEditor
} from "../editors";
import { OpFragmentEditCallback, OpFragmentEditProps } from "../types";
import { DemoDataParams } from "../../services/api/explore";
import { VerticallyCenteredRow } from "../../components";

interface Props {
  opCreationConfig: OpCreationConfig;
  fragmentPath: string;
  opFragment: OpFragment;
  onChange: (opFragment: OpFragment, opCreationConfig: OpCreationConfig) => void;

  demoDataParams?: DemoDataParams;
  onClose?: () => void;
  showEditorInModal?: boolean;
}

export const OpFragmentEditRenderer: FC<Props> = props => {
  const {
    opCreationConfig,
    onChange: onChangeWithOpConfig,
    opFragment,
    onClose,
    demoDataParams,
    showEditorInModal = false
  } = props;

  const onChange = useCallback<OpFragmentEditCallback>(
    async (opFragment: OpFragment, opFragmentEditType: OpCreationFragmentEditType) => {
      let isError = false;
      let errMessage = "";
      try {
        const { data, error, message } = await operationaliseV2ApiService.getUpdatedOperationalizeViaFragments(
          opCreationConfig,
          opFragment,
          opFragmentEditType
        );

        if (error || !data?.opCreationConfig) {
          logger.fatal("OpFragmentEditRenderer", "Error updating opCreationConfig via fragments", message);
          isError = true;
          errMessage = message;
        } else {
          onChangeWithOpConfig(opFragment, data.opCreationConfig);
        }
      } catch (err) {
        logger.error("OpFragmentEditRenderer", "Error updating opCreationConfig via fragments", err);
        errMessage = "An unexpected error occurred while updating the recipe. Please try again.";
        isError = true;
      }

      return {
        isError,
        message: errMessage
      };
    },
    [onChangeWithOpConfig, opCreationConfig]
  );

  const componentProps = useMemo<OpFragmentEditProps>(() => {
    const className = "inc-flex-column width-100 flex-gap-12 op-config-editor-v2";
    const invalidFragmentJsx = (
      <div className={className}>
        <VerticallyCenteredRow className="inc-label-common flex-gap-12">Invalid configuration</VerticallyCenteredRow>

        {Boolean(onClose) && (
          <VerticallyCenteredRow className="flex-gap-12 marginTp10">
            <IncButton
              color="secondary-blue"
              label="Cancel"
              onClick={onClose}
              size="small"
            />
          </VerticallyCenteredRow>
        )}
      </div>
    );

    return {
      opFragment,
      onChange,
      onClose,
      opCreationConfig,
      demoDataParams,
      className,
      invalidFragmentJsx,
      showEditorInModal
    };
  }, [demoDataParams, onChange, onClose, opCreationConfig, opFragment, showEditorInModal]);
  console.log(opFragment.opFragmentType);

  switch (opFragment.opFragmentType) {
    case OpFragmentType.OP_SCHEDULE: {
      return <ScheduleFragmentEditor {...componentProps} />;
    }

    case OpFragmentType.OP_KPI_WITH_COMPARATOR: {
      return <KPIWithComparatorFragmentEditor {...componentProps} />;
    }

    case OpFragmentType.OP_KPI_WITH_COMPARATOR_AND_TREND: {
      return <KPIWithComparatorAndTrendFragmentEditor {...componentProps} />;
    }

    case OpFragmentType.OP_LOOKBACK: {
      return <LookBackFragmentEditor {...componentProps} />;
    }

    case OpFragmentType.OP_EVENT_CRITERIA: {
      return <EventCriteriaFragmentEditor {...componentProps} />;
    }

    case OpFragmentType.OP_START_TRIGGER: {
      return <StartTriggerFragmentEditor {...componentProps} />;
    }

    case OpFragmentType.OP_SUPPRESSIONS: {
      return <SuppressionsFragmentEditor {...componentProps} />;
    }

    case OpFragmentType.OP_THRESHOLD: {
      return <ThresholdFragmentEditor {...componentProps} />;
    }

    case OpFragmentType.OP_BIZ_ACTION: {
      return <BizActionFragmentEditor {...componentProps} />;
    }

    case OpFragmentType.OP_SEASONALITY_OVERRIDES: {
      return <SeasonalityOverridesFragmentEditor {...componentProps} />;
    }

    case OpFragmentType.OP_HISTORY_TO_CONSIDER: {
      return <HistoryToConsiderFragmentEditor {...componentProps} />;
    }

    default:
      return null;
  }
};
