import {
  IncButton,
  IncModal,
  IncSelect,
  IncSelectOption,
  IncTabButtons,
  IncToolTip,
  Tab,
  IncCheckbox,
  IncRadioButton
} from "@inception/ui";
import { cloneDeep, isEmpty, uniqBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { v4 } from "uuid";
import { LoadingSpinner } from "../../../platform/components";
import IncDataTypeSelect from "../../../platform/components/data-type/selector/DataTypeSelector";
import { DataTypeOption, getAllDataTypeOptions, MappingType } from "../../../platform/components/data-type/utils";
import DistributionBars, { DistributionBarProp } from "../../../platform/components/distribution-bars/DistributionBars";
import { TimeRange, useNotifications, useTimeRange } from "../../../platform/core";
import { FieldPrimType, FieldSubType } from "../../../platform/core/data/types/DataTypes";
import {
  DataTransformationPreviewRequest,
  DiscoveredFieldDef,
  FieldTypeCategory,
  VerticalEventField,
  DiscoveredCustomFieldDef
} from "../../../platform/services/api/auto-discovery/types";
import { PreviewDataObj } from "../../../platform/services/api/entity-mapping";
import { FieldTransformationForm } from "../../../platform/transformation/FieldTransformationForm";
import {
  FieldTransformationConfig,
  TransformType
} from "../../../platform/transformation/transform-config/TransformConfig";
import { getTransformConfigFromDiscoveredField } from "../../../platform/transformation/transform-config/utils";
import { useFetchDataTransformation } from "../hooks/FetchDataTransformation";
import { useDiscoverMappingStore } from "../store/DiscoverMappingProvider";
import { FieldCategoryOption, FieldTimeConfig, fieldTypeToValType } from "../types";
import { autoDiscoveryUtils } from "../utils/autoDiscoveryUtils";
import { predefinedFieldTypeOptions } from "../../configuration/integrations/user-service-mapping2/fields-modal/FieldSelectorGrid";
import timeRangeUtils from "../../../platform/utils/TimeRangeUtils";
import { FEATURE_FLAGS, featureFlagService } from "../../../platform/services/feature-flags";
import { DataTransformationPreviewTable } from "./DataTransformationPreviewTable";

type Props = {
  show: boolean;
  onClose: () => void;
  selected: {
    field: DiscoveredFieldDef;
    recommendedEvent: VerticalEventField;
    customObj: DiscoveredCustomFieldDef;
  };
  rawFields: Record<string, DiscoveredFieldDef>;
  onUpdate: (
    field: DiscoveredFieldDef,
    recommendedEvent: VerticalEventField,
    predefinedFieldType: string,
    isVirtual: boolean
  ) => void;
  onAddCustomFieldObj: (
    field: DiscoveredFieldDef,
    customObject: DiscoveredCustomFieldDef,
    fieldDef: DiscoveredFieldDef,
    customObjType: string,
    customObjName: string
  ) => void;
  onEditCustomFieldObject: (
    field: DiscoveredFieldDef,
    customObj: DiscoveredCustomFieldDef,
    prevFieldName: string
  ) => void;
  getLocationTypes: () => Record<string, string>;
  previewData: PreviewDataObj;
  customObjectFields: DiscoveredCustomFieldDef[];
  pTimeRange?: TimeRange;
  variant?: string;
  eventTypes?: string[];
  disableCustomObjectFields?: boolean;
};

type DataTypeValue = {
  dataType: FieldPrimType;
  subType: FieldSubType;
};

const fieldCategoryOptions: FieldCategoryOption[] = [
  {
    label: FieldTypeCategory.BUSINESS,
    value: FieldTypeCategory.BUSINESS
  },
  {
    label: FieldTypeCategory.DIAGNOSTIC,
    value: FieldTypeCategory.DIAGNOSTIC
  }
];

const validNameRegex = /^[A-Za-z0-9_-]*$/;

const MapFieldToEventModal = (props: Props) => {
  const {
    show,
    onClose,
    selected,
    rawFields,
    onUpdate,
    getLocationTypes,
    previewData,
    customObjectFields = [],
    onAddCustomFieldObj,
    onEditCustomFieldObject,
    pTimeRange,
    variant,
    eventTypes,
    disableCustomObjectFields = false
  } = props;
  const timeRangeFromHook = useTimeRange();
  const isModal = !variant || variant !== "inline";
  const timeRange = useMemo(() => {
    if (pTimeRange) {
      return pTimeRange;
    } else {
      return timeRangeFromHook.timeRange;
    }
  }, [pTimeRange, timeRangeFromHook]);

  const [previewTab, setPreviewTab] = useState("distribution");
  const [selectedField, setSelectedField] = useState<IncSelectOption<DiscoveredFieldDef>>(null);
  const [selectedFieldTypeName, setSelectedFieldTypeName] = useState<string>("");
  const [selectedFieldCategory, setSelectedFieldCategory] = useState<FieldCategoryOption>(null);
  const [selectedFieldDataType, setSelectedFieldDataType] = useState<DataTypeValue>(null);
  const [fieldTransformationConfig, setFieldTransformationConfig] =
    useState<FieldTransformationConfig<TransformType>>(null);
  const [entityTypeInvalid, setEntityTypeInvalid] = useState(false);
  const [isPredefinedFieldType, setIsPredefinedFieldType] = useState(false);
  const [mapToCustomField, setMapToCustomField] = useState(selected?.customObj ? true : false);
  const [selectedCustomObjType, setSelectedCustomObjType] = useState(
    selected?.customObj
      ? {
          label: selected?.customObj?.customObjectType,
          value: selected?.customObj?.customObjectType
        }
      : null
  );
  const [selectedCustomField, setSelectedCustomField] = useState<IncSelectOption<DiscoveredCustomFieldDef>>(
    selected?.customObj
      ? {
          label: selected.customObj.predefinedFieldType,
          value: selected.customObj.predefinedFieldType,
          data: selected.customObj
        }
      : null
  );
  const [customRawField, setCustomRawField] = useState<IncSelectOption<DiscoveredFieldDef>>(null);
  const { state } = useDiscoverMappingStore() || {};
  const { requestId } = state || {};

  const { notifyError } = useNotifications();
  const { formatMessage } = useIntl();

  const {
    data: tranformationPreviewResponse,
    isFetching: transformationFetching,
    isError: transformationError,
    isSuccess: transformationSuccess,
    fetchPreview: fetchTransformationPreview
  } = useFetchDataTransformation();
  const mode = useMemo(() => (selected?.field?.fieldJsonPath ? "Edit" : "Add"), [selected]);

  const allDataTypeOptions = useMemo(() => getAllDataTypeOptions(MappingType.event), []);

  const previewTabs: Tab[] = useMemo(
    () => [
      {
        id: "distribution",
        label: "Distribution"
      },
      {
        id: "events",
        label: "Events",
        disable: !selectedField?.data?.dataTransformations?.length
      }
    ],
    [selectedField]
  );

  const isTryEnabled = fieldTransformationConfig && selectedField && !entityTypeInvalid;
  const [isTimeChanged, setIsTimeChanged] = useState(isTryEnabled);
  useEffect(() => {
    setIsTimeChanged(isTryEnabled);
  }, [isTryEnabled]);

  const rawFieldRef = useRef<DiscoveredFieldDef>(selected?.field || null);
  //INGEST/QUERY TIME -- starts
  //const getField = useCallback(() => {
  //}, []);
  const [preSelectTransformation, setPreSelectTransformation] = useState<TransformType>(null);
  const [fieldTimeConfig, setFieldTimeConfig] = useState<FieldTimeConfig>(
    selected?.field?.isVirtual ? FieldTimeConfig.QueryTime : FieldTimeConfig.IngestTime
  );
  const [disableOnQuery, setDisableOnQuery] = useState<boolean>(selected?.field?.isVirtual);

  const OnChangeQueryTime = useCallback(
    (evt: any) => {
      const val = evt.target.value as FieldTimeConfig;
      setIsTimeChanged(false);
      setFieldTransformationConfig(null);
      setFieldTimeConfig(val);
      if (val === FieldTimeConfig.QueryTime) {
        setDisableOnQuery(true);
        setPreSelectTransformation("druidTransformationConfig");
        if (selectedField === null) {
          const obj: DiscoveredFieldDef = {
            cardinalityResponse: null,
            statsResponse: null,
            dataType: null,
            entityType: "",
            fieldJsonPath: null,
            isFieldEntity: false,
            isFieldUnique: false,
            name: "",
            sampleValues: null,
            selected: false,
            subtype: null,
            fieldCategory: null,
            predefinedFieldType: null,
            dataTransformations: [],
            distribution: null,
            compositeFields: [],
            verticalContextField: null,
            mappingType: null,
            isCommonField: false,
            isVirtual: false
          };
          setSelectedField({
            label: null,
            value: null,
            data: obj
          });
        }
      } else {
        setDisableOnQuery(false);
        setPreSelectTransformation(null);
        if (selectedField?.value === null) {
          setSelectedField(null);
        }
      }
    },
    [selectedField]
  );
  //INGEST/QUERY TIME -- ends

  const setEntityTypeValidity = useCallback((valid: boolean) => {
    setEntityTypeInvalid(!valid);
  }, []);
  const getDataTypeSelectionOption = useCallback(
    (field: DataTypeValue): DataTypeOption => {
      if (field) {
        //if fieldDef has subtype, then DataTypeSelector should show corresponding value/label
        const selectedDataType = allDataTypeOptions.find(o => {
          if (field.subType && field.subType !== "none") {
            return o.value === field.dataType && o?.kindDescriptor?.type === field.subType;
          }
          return o.value === field.dataType;
        });
        return selectedDataType ?? allDataTypeOptions[0];
      }

      return null;
    },
    [allDataTypeOptions]
  );

  const onModalClose = useCallback(() => {
    //if there any ongoing preview calls, we can cancel
    // if (cancelTokenSource.current) {
    //   cancelTokenSource.current.cancel();
    // }
    onClose();
  }, [onClose]);

  const onUpdateField = useCallback(() => {
    if (mapToCustomField && !selected.customObj) {
      const name = selectedCustomField?.data ? "" : selectedCustomField?.label;
      if (!selectedCustomField?.data && !customRawField?.data && !selectedCustomObjType) {
        notifyError("Invalid Input");
        return;
      }
      const field: DiscoveredFieldDef = {
        ...selectedField?.data,
        predefinedFieldType: selectedFieldTypeName
      };
      onAddCustomFieldObj(field, selectedCustomField?.data, customRawField?.data, selectedCustomObjType.value, name);
    } else if (selected.customObj) {
      const field = {
        ...selectedField.data,
        predefinedFieldType: selectedFieldTypeName
      };
      onEditCustomFieldObject(field, selected.customObj, selected.field.predefinedFieldType);
    } else {
      onUpdate(
        selectedField?.data,
        selected?.recommendedEvent,
        selectedFieldTypeName,
        fieldTimeConfig === FieldTimeConfig.QueryTime
      );
    }
  }, [
    customRawField,
    mapToCustomField,
    onAddCustomFieldObj,
    onEditCustomFieldObject,
    onUpdate,
    selected,
    selectedCustomField,
    selectedField,
    selectedFieldTypeName,
    notifyError,
    selectedCustomObjType,
    fieldTimeConfig
  ]);

  const onNameChange = useCallback(
    (option: IncSelectOption) => {
      const clonedField = cloneDeep(selectedField);
      const value = option?.value || "";
      const predifinedField = predefinedFieldTypeOptions.find(x => x.value === value);
      if (predifinedField) {
        const fieldCategoryOption = fieldCategoryOptions.find(x => x.value === predifinedField.categoryType);
        setSelectedFieldTypeName(value);
        setSelectedFieldDataType({
          dataType: predifinedField.kind,
          subType: predifinedField.subType
        });
        setSelectedFieldCategory(fieldCategoryOption);
        setIsPredefinedFieldType(true);
        clonedField.data.dataType = predifinedField.kind;
        clonedField.data.subtype = predifinedField.subType;
        clonedField.data.fieldCategory = fieldCategoryOption.value;
      } else {
        setSelectedFieldTypeName(value);
        setIsPredefinedFieldType(false);
      }
      clonedField.data.predefinedFieldType = value;
      if (disableOnQuery) {
        clonedField.data.name = value;
        clonedField.data.fieldJsonPath = `$.${value}`;
        //clonedField.data.jsonPath = '$.' + value;
      }
      setSelectedField(clonedField);
    },
    [disableOnQuery, selectedField]
  );

  const onSelectedFieldChange = useCallback(
    (field: IncSelectOption<DiscoveredFieldDef>) => {
      let clonedField = cloneDeep(field);
      clonedField = {
        ...clonedField,
        data: {
          ...clonedField.data,
          fieldCategory: FieldTypeCategory.BUSINESS
        }
      };
      if (selected?.recommendedEvent) {
        clonedField.data.verticalContextField = selected.recommendedEvent;
        clonedField.data.predefinedFieldType = selectedFieldTypeName || selected.recommendedEvent.defaultName;
        // clonedField.data.fieldCategory = selected.recommendedEvent.category;
        clonedField.data.dataType = selected.recommendedEvent?.kind || "_str";
        clonedField.data.subtype = selected.recommendedEvent?.subType || "none";
      }
      setSelectedField(clonedField);
      setSelectedFieldTypeName(clonedField.data.predefinedFieldType || clonedField.data.name);
      setSelectedFieldDataType({
        dataType: clonedField.data.dataType,
        subType: clonedField.data.subtype
      });
      setSelectedFieldCategory({
        label: clonedField.data.fieldCategory,
        value: clonedField.data.fieldCategory
      });
    },
    [selected, selectedFieldTypeName]
  );

  const onFieldCategoryChange = useCallback(
    (category: FieldCategoryOption) => {
      const clonedField = cloneDeep(selectedField);
      clonedField.data.fieldCategory = category.value;
      setSelectedFieldCategory(category);
      setSelectedField(clonedField);
    },
    [selectedField]
  );

  const onFieldDataTypeChange = useCallback(
    (dataTypeOption: DataTypeOption) => {
      const clonedField = cloneDeep(selectedField);
      clonedField.data.dataType = dataTypeOption.value as FieldPrimType;
      clonedField.data.subtype = dataTypeOption?.kindDescriptor?.type || "none";
      setSelectedField(clonedField);
      setSelectedFieldDataType({
        dataType: clonedField.data.dataType,
        subType: clonedField.data.subtype
      });
    },
    [selectedField]
  );

  const getTransformationRequestPayload = useCallback(
    (
      requestId: string,
      field: DiscoveredFieldDef,
      previewDataObj: PreviewDataObj
    ): DataTransformationPreviewRequest => {
      const requestPayload: DataTransformationPreviewRequest = {
        requestId: requestId || v4(),
        previewData: previewDataObj,
        field: field,
        eventTypes: eventTypes,
        availableField: []
      };
      let { fieldJsonPath } = field;
      if (selected?.customObj) {
        const newCustomFieldObj = cloneDeep(selected.customObj);
        const fieldDef = newCustomFieldObj.fieldDef.find(x => x.name === field.name);
        fieldJsonPath = autoDiscoveryUtils.getCustomObjSubFieldJsonPath(field, newCustomFieldObj.fieldJsonPath);
        if (fieldDef) {
          fieldDef.dataTransformations = field.dataTransformations;
          fieldDef.fieldJsonPath = fieldJsonPath;
        } else {
          newCustomFieldObj.fieldDef.push({
            ...field,
            fieldJsonPath
          });
        }
        requestPayload.customObject = newCustomFieldObj;
      } else if (selectedCustomField?.data) {
        const newCustomFieldObj = cloneDeep(selectedCustomField.data);
        fieldJsonPath = autoDiscoveryUtils.getCustomObjSubFieldJsonPath(field, newCustomFieldObj.fieldJsonPath);
        newCustomFieldObj.fieldDef.push({
          ...field,
          fieldJsonPath
        });
        requestPayload.customObject = newCustomFieldObj;
      } else if (customRawField?.data) {
        const customObject = autoDiscoveryUtils.getCustomFieldObjectDefFromFieldDef(
          customRawField.data,
          selectedCustomObjType?.value,
          selectedCustomField?.label
        );
        fieldJsonPath = autoDiscoveryUtils.getCustomObjSubFieldJsonPath(field, customObject.fieldJsonPath);
        customObject.fieldDef.push({
          ...field,
          fieldJsonPath
        });
        requestPayload.customObject = customObject;
      }
      if (disableOnQuery) {
        requestPayload.field.isVirtual = true;
      }
      if (rawFieldRef.current) {
        requestPayload.availableField.push(rawFieldRef.current);
      }
      requestPayload.field.fieldJsonPath = fieldJsonPath;
      return requestPayload;
    },
    [eventTypes, selected, selectedCustomField, customRawField, disableOnQuery, selectedCustomObjType]
  );

  const onTryTransformation = useCallback(
    (field?: DiscoveredFieldDef) => {
      const { fromMillis, toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);
      const clonedField = field || cloneDeep(selectedField?.data);
      if (!disableOnQuery) {
        clonedField.isVirtual = false;
      }
      const transformationRequest = getTransformationRequestPayload(requestId, clonedField, previewData);
      fetchTransformationPreview(transformationRequest, fromMillis, toMillis);
      setPreviewTab("events");
    },
    [
      fetchTransformationPreview,
      getTransformationRequestPayload,
      previewData,
      requestId,
      selectedField,
      timeRange,
      disableOnQuery
    ]
  );

  const onFieldTransformationChange = useCallback(
    (
      transformConfig: FieldTransformationConfig<TransformType>,
      deleteTransformation: boolean,
      isLocationTransformation: boolean
    ) => {
      if (!disableOnQuery && selectedField.data.isVirtual) {
        const clonedField = cloneDeep(selectedField);
        clonedField.data.dataTransformations = [];
        setFieldTransformationConfig(null);
        setSelectedField(clonedField);
        setSelectedFieldDataType({
          dataType: clonedField.data.dataType,
          subType: clonedField.data.subtype
        });
        //Enable below if we want to fetch on every input change while updating transformations
        onTryTransformation(clonedField.data);
        return;
      }
      if (transformConfig && !entityTypeInvalid) {
        const clonedField = cloneDeep(selectedField);
        //TODO: just check on the below commented code with sumit and take proper action
        // clonedField.data.dataType = transformConfig.outputType as FieldPrimType;
        // clonedField.data.subtype = "none";
        clonedField.data.dataTransformations = [transformConfig];
        if (selectedFieldDataType) {
          clonedField.data.dataType = selectedFieldDataType.dataType;
          clonedField.data.subtype = selectedFieldDataType.subType;
        }
        setFieldTransformationConfig(transformConfig);
        setSelectedField(clonedField);
        setSelectedFieldDataType({
          dataType: clonedField.data.dataType,
          subType: clonedField.data.subtype
        });
        //Enable below if we want to fetch on every input change while updating transformations
        //onTryTransformation(clonedField.data);
      }
      if (deleteTransformation) {
        const clonedField = cloneDeep(selectedField);
        clonedField.data.dataTransformations = [];
        setFieldTransformationConfig(null);
        setSelectedField(clonedField);
        setSelectedFieldDataType({
          dataType: clonedField.data.dataType,
          subType: clonedField.data.subtype
        });
        //Enable below if we want to fetch on every input change while updating transformations
        onTryTransformation(clonedField.data);
      }
      if (isLocationTransformation && selectedField?.data?.subtype !== "geolocation") {
        const clonedField = cloneDeep(selectedField);
        clonedField.data.dataType = "_str" as FieldPrimType;
        clonedField.data.subtype = "geolocation";
        setSelectedField(clonedField);
        setSelectedFieldDataType({
          dataType: clonedField.data.dataType,
          subType: clonedField.data.subtype
        });
      }
    },
    [disableOnQuery, selectedField, entityTypeInvalid, onTryTransformation, selectedFieldDataType]
  );

  const onSelectedTabChange = useCallback(
    (tabId: string) => {
      setPreviewTab(tabId);
      if (tabId === "events") {
        onTryTransformation();
      }
    },
    [onTryTransformation]
  );

  const rawFieldOptions = useMemo(() => {
    const options: Array<IncSelectOption<DiscoveredFieldDef>> = [];
    if (rawFields) {
      for (const [jsonPath, fieldDef] of Object.entries(rawFields)) {
        const fieldName = getFieldNameFromFieldDef(fieldDef);
        options.push({
          label: fieldName,
          value: jsonPath,
          data: fieldDef
        });
      }
    }
    return options;
  }, [rawFields]);

  const distributionLabels: DistributionBarProp[] = useMemo(() => {
    const labels: DistributionBarProp[] = [];
    if (selectedField) {
      //const selectedRawField = rawFields[selectedField.data.fieldJsonPath];
      const valType = fieldTypeToValType[selectedField.data.dataType];
      selectedField.data.distribution?.valueDistribution?.forEach(data => {
        labels.push({
          title: data.value[valType]?.toString() || data.value["stringVal"]?.toString(),
          value: data.share
        });
      });
    }
    return labels;
  }, [selectedField]);

  const footer = useMemo(
    () => (
      <div className="flex">
        <IncButton
          color="primary"
          disabled={isEmpty(selectedField)}
          onClick={onUpdateField}
        >
          {mode === "Add" ? "Add" : "Save"}
        </IncButton>
        <IncButton
          color="secondary-blue"
          onClick={onModalClose}
        >
          Cancel
        </IncButton>
      </div>
    ),
    [mode, onModalClose, onUpdateField, selectedField]
  );

  useEffect(() => {
    if (!isEmpty(selected?.field)) {
      if (isEmpty(selected?.field?.verticalContextField) && selected?.recommendedEvent) {
        selected.field.verticalContextField = selected.recommendedEvent;
      }
      const fieldName = getFieldNameFromFieldDef(selected.field);
      setSelectedField({
        label: fieldName || selected?.field?.name,
        value: selected.field.fieldJsonPath,
        data: selected.field
      });
      setSelectedFieldTypeName(selected.field.predefinedFieldType || selected.field.name);
      setSelectedFieldCategory(
        selected.field.fieldCategory
          ? {
              label: selected.field.fieldCategory,
              value: selected.field.fieldCategory
            }
          : {
              label: FieldTypeCategory.BUSINESS,
              value: FieldTypeCategory.BUSINESS
            }
      );
      setSelectedFieldDataType({
        dataType: selected.field.dataType,
        subType: selected.field.subtype
      });
    } else {
      if (!isEmpty(selected?.recommendedEvent)) {
        setSelectedFieldTypeName(selected.recommendedEvent.defaultName);
        setSelectedFieldCategory({
          label: selected.recommendedEvent.category,
          value: selected.recommendedEvent.category
        });
        setSelectedFieldDataType({
          dataType: selected.recommendedEvent?.kind || "_str",
          subType: selected.recommendedEvent?.subType || "none"
        });
      }
    }
  }, [selected]);

  useEffect(() => {
    if (selectedField) {
      const obj = getTransformConfigFromDiscoveredField(selectedField.data);
      setFieldTransformationConfig(obj);
    }
  }, [selectedField]);

  useEffect(() => {
    if (transformationSuccess && !isEmpty(tranformationPreviewResponse?.transformedFieldDef)) {
      //NOTE: below will be multiple names (from composite fields) when selecting multiple raw fields supported
      //we should maintain, two different states - one for rawFields and one for selectedField
      const labelName = tranformationPreviewResponse?.transformedFieldDef?.name;
      const jsonPath = tranformationPreviewResponse?.transformedFieldDef?.fieldJsonPath;
      let fieldName = "";
      if (jsonPath.includes("bicycle.")) {
        fieldName = labelName;
      } else {
        fieldName = jsonPath.split(/\$.(.*)/s)[1];
      }
      setSelectedField({
        label: fieldName,
        value: jsonPath,
        data: tranformationPreviewResponse?.transformedFieldDef
      });
    }
    if (transformationError) {
      notifyError("Error fetching transformation. Please try again");
    }
  }, [notifyError, tranformationPreviewResponse, transformationError, transformationSuccess]);

  const onChangeCustomObjType = useCallback((option: IncSelectOption) => {
    setSelectedCustomObjType(option);
    setCustomRawField(null);
  }, []);
  const onChangeCustomField = useCallback(
    (option: IncSelectOption<DiscoveredCustomFieldDef>) => setSelectedCustomField(option),
    []
  );
  const onCustomRawfieldObjectChange = useCallback(
    (option: IncSelectOption<DiscoveredFieldDef>) => setCustomRawField(option),
    []
  );

  const onChangeMapToCustomField = useCallback((e, checked: boolean) => {
    setMapToCustomField(checked);
    if (!checked) {
      setCustomRawField(null);
      setSelectedCustomField(null);
    }
  }, []);

  const existingCustomObjTypes = useMemo(() => {
    const list = customObjectFields.map(x => ({
      label: x.customObjectType,
      value: x.customObjectType
    }));
    return uniqBy(list, x => x.value);
  }, [customObjectFields]);

  const existingCustomFields = useMemo(() => {
    const options: Array<IncSelectOption<DiscoveredCustomFieldDef>> = [];
    customObjectFields.forEach(fieldDef => {
      if (selectedCustomObjType?.value === fieldDef.customObjectType) {
        options.push({
          label: fieldDef.predefinedFieldType,
          value: fieldDef.predefinedFieldType,
          data: fieldDef
        });
      }
    });
    return options;
  }, [customObjectFields, selectedCustomObjType]);

  const customRawFieldObjects = useMemo(() => {
    const options: Array<IncSelectOption<DiscoveredFieldDef>> = [];
    const jsonPath = selectedField?.data?.fieldJsonPath || "";
    if (jsonPath && previewData?.sourceSchema?.fields) {
      const customRawFieldJsonPaths = autoDiscoveryUtils.getValidCustomRawFieldObjects(
        previewData.sourceSchema.fields,
        jsonPath
      );
      customRawFieldJsonPaths.forEach(path => {
        for (const [jsonPath, fieldDef] of Object.entries(rawFields)) {
          if (fieldDef.fieldJsonPath === path) {
            const fieldName = getFieldNameFromFieldDef(fieldDef);
            options.push({
              label: fieldName,
              value: jsonPath,
              data: fieldDef
            });
          }
        }
      });
    }
    return options;
  }, [previewData, rawFields, selectedField]);

  const nameValue = useMemo(() => {
    const predefinedFieldType = predefinedFieldTypeOptions.find(x => x.value === selectedFieldTypeName);
    if (predefinedFieldType) {
      return predefinedFieldType;
    }
    return {
      label: selectedFieldTypeName,
      value: selectedFieldTypeName
    };
  }, [selectedFieldTypeName]);

  const inValidName = nameValue?.value ? !validNameRegex.test(nameValue?.value) : false;

  const isCustomSubField = selected?.customObj ? true : false;

  const transformTypes: TransformType[] = useMemo(
    () => (fieldTimeConfig === FieldTimeConfig.QueryTime ? ["druidTransformationConfig"] : []),
    [fieldTimeConfig]
  );

  const onRawFieldChange = useCallback(
    (selectedField: IncSelectOption<DiscoveredFieldDef>) => {
      onSelectedFieldChange(selectedField);
      rawFieldRef.current = selectedField?.data;
    },
    [onSelectedFieldChange]
  );

  const mapperUI = useMemo(
    () => (
      <>
        <div className="map-field-to-event-modal-content inc-flex-row">
          <div className="form-container p-a-1-50">
            {featureFlagService.isFeatureEnabled(FEATURE_FLAGS.enableVirtualFields) && (
              <div className="inc-flex-row marginTp6">
                <div style={{ flex: 1 }}>
                  <div
                    className="inc-flex-row inc-flex-center-vertical"
                    style={{ width: "95%" }}
                  >
                    <IncRadioButton
                      checked={fieldTimeConfig === "Ingest Time"}
                      onChange={OnChangeQueryTime}
                      value={FieldTimeConfig.IngestTime}
                    />
                    <div className="inc-text-body marginLt8">Ingest Time</div>
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div
                    className="inc-flex-row inc-flex-center-vertical"
                    style={{ width: "95%" }}
                  >
                    <IncRadioButton
                      checked={fieldTimeConfig === "Query Time"}
                      onChange={OnChangeQueryTime}
                      value={FieldTimeConfig.QueryTime}
                    />
                    <div className="inc-text-body marginLt8">Query Time</div>
                  </div>
                </div>
              </div>
            )}
            <div className="inc-flex-row marginTp24">
              <div style={{ flex: 1 }}>
                <div style={{ width: "95%" }}>
                  <IncSelect
                    allowCreate
                    isDisabled={disableOnQuery}
                    label="Raw field"
                    onChange={onRawFieldChange}
                    options={rawFieldOptions}
                    value={selectedField}
                  />
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <IncSelect
                  allowCreate
                  errorText={formatMessage({
                    id: "user.service.mapped.name.err.text"
                  })}
                  hasError={inValidName}
                  isDisabled={(isEmpty(selectedField) || !isEmpty(selected?.recommendedEvent)) && !disableOnQuery}
                  isSearchable={true}
                  label={"Name"}
                  onChange={onNameChange}
                  options={predefinedFieldTypeOptions}
                  value={nameValue}
                />
              </div>
            </div>
            <div className="inc-flex-row marginTp24">
              <div style={{ flex: 1 }}>
                <div style={{ width: "95%" }}>
                  <IncSelect<FieldCategoryOption>
                    allowCreate={false}
                    isDisabled={
                      (!isEmpty(selected?.recommendedEvent) || isEmpty(selectedField) || isPredefinedFieldType) &&
                      !disableOnQuery
                    }
                    isSearchable={false}
                    label="Field type"
                    onChange={onFieldCategoryChange}
                    options={fieldCategoryOptions}
                    value={selectedFieldCategory}
                  />
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <IncDataTypeSelect
                  disabled={
                    (!isEmpty(selected?.recommendedEvent) || isEmpty(selectedField) || isPredefinedFieldType) &&
                    !disableOnQuery
                  }
                  label="Data type"
                  onSelect={onFieldDataTypeChange}
                  selected={getDataTypeSelectionOption(selectedFieldDataType)}
                />
              </div>
            </div>
            <div className="inc-flex-column marginTp16">
              {!disableCustomObjectFields && (
                <IncCheckbox
                  checked={mapToCustomField && !disableOnQuery}
                  disabled={isCustomSubField || disableOnQuery}
                  label="Map to Custom Object"
                  onChange={onChangeMapToCustomField}
                />
              )}
              {mapToCustomField && !disableOnQuery && (
                <>
                  <IncSelect
                    allowCreate
                    isClearable
                    isDisabled={isCustomSubField}
                    label="Select Custom Object Type"
                    onChange={onChangeCustomObjType}
                    options={existingCustomObjTypes}
                    value={selectedCustomObjType}
                    wrapperClass="marginTp16"
                  />
                  {selectedCustomObjType && (
                    <IncSelect
                      allowCreate
                      isClearable
                      isDisabled={isCustomSubField}
                      label="Select Custom Object Name"
                      onChange={onChangeCustomField}
                      options={existingCustomFields}
                      value={selectedCustomField}
                      wrapperClass="marginTp16"
                    />
                  )}
                  {selectedCustomField && !selectedCustomField.data && (
                    <IncSelect
                      allowCreate={false}
                      isClearable
                      isSearchable={false}
                      label="Select Field"
                      onChange={onCustomRawfieldObjectChange}
                      options={customRawFieldObjects}
                      value={customRawField}
                      wrapperClass="marginTp16"
                    />
                  )}
                </>
              )}
            </div>
            <div className="inc-flex-column marginTp24">
              <FieldTransformationForm
                allowedtransformTypes={transformTypes}
                config={fieldTransformationConfig}
                disableTranformSelect={disableOnQuery ? false : !selectedField}
                fieldDataType={selectedFieldDataType?.dataType as FieldPrimType}
                fieldSubType={selectedField?.data?.subtype as FieldSubType}
                getLocationEntityTypes={getLocationTypes}
                onChange={onFieldTransformationChange}
                preSelectTransformation={preSelectTransformation}
                setEntityTypeValidity={setEntityTypeValidity}
                showWarning
                uniqueFieldName={autoDiscoveryUtils.getUniqFieldName(selectedField?.data)}
              />
              {isTryEnabled && isTimeChanged && (
                <IncToolTip
                  placement="right"
                  titleText="Click to apply transformation and see updated data"
                  variant="info"
                >
                  <IncButton
                    className="inc-flex-center-horizontal"
                    color="primary"
                    onClick={() => onTryTransformation()}
                    size="small"
                    style={{
                      width: 50,
                      borderRadius: 6
                    }}
                  >
                    Test
                  </IncButton>
                </IncToolTip>
              )}
            </div>
          </div>
          <div className="preview-container p-a-1-50">
            <div className="preview-header">
              <div className="inc-text-body-medium">Preview</div>
              <div className="preview-tab-buttons">
                <IncTabButtons
                  onChange={onSelectedTabChange}
                  selectedTabId={previewTab}
                  tabs={previewTabs}
                />
              </div>
            </div>
            {previewTab === "distribution" && (
              <div className="preview-body marginTp32">
                {distributionLabels?.length ? (
                  <div className="distribution-bars-container">
                    <DistributionBars values={distributionLabels} />
                  </div>
                ) : null}
              </div>
            )}
            {previewTab === "events" && (
              <div className="preview-body marginTp32">
                {transformationFetching && <LoadingSpinner titleText="Fetching transformation. Please wait..." />}
                {transformationSuccess && tranformationPreviewResponse && (
                  <div>
                    <DataTransformationPreviewTable previewDataResponse={tranformationPreviewResponse} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    ),
    [
      fieldTimeConfig,
      OnChangeQueryTime,
      disableOnQuery,
      onRawFieldChange,
      rawFieldOptions,
      selectedField,
      formatMessage,
      inValidName,
      selected?.recommendedEvent,
      onNameChange,
      nameValue,
      isPredefinedFieldType,
      onFieldCategoryChange,
      selectedFieldCategory,
      onFieldDataTypeChange,
      getDataTypeSelectionOption,
      selectedFieldDataType,
      disableCustomObjectFields,
      mapToCustomField,
      isCustomSubField,
      onChangeMapToCustomField,
      onChangeCustomObjType,
      existingCustomObjTypes,
      selectedCustomObjType,
      onChangeCustomField,
      existingCustomFields,
      selectedCustomField,
      onCustomRawfieldObjectChange,
      customRawFieldObjects,
      customRawField,
      transformTypes,
      fieldTransformationConfig,
      getLocationTypes,
      onFieldTransformationChange,
      preSelectTransformation,
      setEntityTypeValidity,
      isTryEnabled,
      isTimeChanged,
      onSelectedTabChange,
      previewTab,
      previewTabs,
      distributionLabels,
      transformationFetching,
      transformationSuccess,
      tranformationPreviewResponse,
      onTryTransformation
    ]
  );

  return (
    <>
      {isModal && (
        <IncModal
          className="map-field-to-entity-modal"
          closeOnBackdrop={false}
          closeOnEscape={false}
          footerChildren={footer}
          onClose={onModalClose}
          show={show}
          size="lg"
          titleText="Map Field To Event"
        >
          {mapperUI}
        </IncModal>
      )}
      {!isModal && mapperUI}
    </>
  );
};

export default MapFieldToEventModal;

export const getFieldNameFromFieldDef = (fieldDef: DiscoveredFieldDef) => {
  if (fieldDef?.fieldJsonPath?.includes("bicycle.")) {
    return fieldDef.name;
  } else {
    return (fieldDef?.fieldJsonPath || "").split(/\$.(.*)/s)[1];
  }
};
