import React, { FC, useState, useCallback, useRef, useEffect } from "react";
import { IncFaIcon } from "@inception/ui";
import {
  IncidentSliceContribution,
  CompareQuerySchemaResponse,
  ImpactWidgetDataRequest,
  TriagePageConfigPayload,
  UserServiceFilterList
} from "../../../services/api/explore";
import { operationaliseV2ApiService, OpSchema } from "../../../services/api/operationalise";
import { logger } from "../../../core/logging/Logger";
import LoadingSpinner from "../../Loading/Loading";
import { VerticallyCenteredRow } from "../../flex-components";
import { TimeRange } from "../../../core";
import { ImpactedWidgetsState } from "../types";
import { KeyIndicatorsWithImpactedWidgets } from "./KeyIndicatorsWithImpactedWidgets";

interface Props {
  isFetching: boolean;
  error: string;
  keyContributors: IncidentSliceContribution[];

  impactedWidgetsState: ImpactedWidgetsState;
  opSchema: OpSchema;

  lookupData?: Record<string, string>;

  entityTypeId: string;
  incidentId: string;
  opConfigId: string;
  cohortId: string;

  entityFilters?: ImpactWidgetDataRequest["entityFilters"];
  eventFieldFilters?: UserServiceFilterList;

  timeRange?: TimeRange;
  preset?: TriagePageConfigPayload["keyIndicators"];
  hideDrawers?: boolean;

  generateDemoData?: boolean;
}

export const KeyIndicatorsWrapper: FC<Props> = props => {
  const {
    keyContributors,
    lookupData,
    isFetching: keyContributorsFetching,
    error: keyContributorsError,
    opConfigId,
    impactedWidgetsState,
    preset,
    incidentId,
    generateDemoData
  } = props;

  const { impactedWidgetsFetchError, impactedWidgetsLists, isImpactedWidgetsFetchError, isImpactedWidgetsFetching } =
    impactedWidgetsState;

  useEffect(() => {
    if (isImpactedWidgetsFetchError) {
      logger.error("KeyIndicators", "Error fetching impacted widgets", impactedWidgetsFetchError);
    }
  }, [impactedWidgetsFetchError, isImpactedWidgetsFetchError]);

  const [schemaLoading, setSchemaLoading] = useState(true);
  const [compareSchema, setCompareSchema] = useState<CompareQuerySchemaResponse>(null);
  const [compareSchemaError, setCompareSchemaError] = useState("");

  const isLoading = keyContributorsFetching || schemaLoading || isImpactedWidgetsFetching;
  const isError = Boolean(compareSchemaError) || Boolean(keyContributorsError) || isImpactedWidgetsFetchError;

  const compareSchemaFetched = useRef(false);

  const [expanded, setExpanded] = useState(true);
  const toggleExpand = useCallback(() => {
    if (!isLoading) {
      setExpanded(prev => !prev);
    }
  }, [isLoading]);

  const [numExtraItems, setNumExtraItems] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(prev => !prev);

  const fetchCompareSchema = useCallback(async () => {
    setSchemaLoading(true);
    setCompareSchemaError("");
    try {
      const { data, error, message } = await operationaliseV2ApiService.getOp10zeSchema(
        opConfigId,
        incidentId,
        null,
        null,
        generateDemoData
      );
      if (error) {
        setCompareSchemaError(message);
      } else {
        setCompareSchema(data);
      }
    } catch (err) {
      logger.error("Key Indicators", "Error fetching compare schema", err);
      setCompareSchemaError(err.message);
    } finally {
      compareSchemaFetched.current = true;
      setSchemaLoading(false);
    }
  }, [generateDemoData, incidentId, opConfigId]);

  useEffect(() => {
    if (!compareSchemaFetched.current) {
      fetchCompareSchema();
    }
  }, [fetchCompareSchema]);

  return (
    <div
      className="key-indicators-wrapper"
      data-expanded={expanded}
    >
      <VerticallyCenteredRow className="header-wrapper">
        <VerticallyCenteredRow
          className="inc-flex-grow inc-cursor-pointer"
          onClick={toggleExpand}
        >
          {!isLoading && (
            <IncFaIcon
              className="arrow-icon"
              iconName="chevron-circle-up"
            />
          )}
          <div className="inc-text-body-medium">Key Indicators</div>

          {Boolean(numExtraItems) && <div className="marginLt10 inc-label-common">+ {numExtraItems} more</div>}
        </VerticallyCenteredRow>

        {Boolean(numExtraItems) && (
          <div
            className="marginLtAuto status-info inc-text-subtext-medium inc-cursor-pointer"
            onClick={toggleShowMore}
          >
            {showMore ? "Show less" : "Show more"}
          </div>
        )}
      </VerticallyCenteredRow>

      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          {isError && (
            <div className="status-danger">
              Error fetching data: {compareSchemaError || keyContributorsError || impactedWidgetsFetchError}
            </div>
          )}
          {!isError && (
            <div className="key-indicators--indicators">
              <KeyIndicatorsWithImpactedWidgets
                compareSchema={compareSchema}
                impactedWidgetsLists={impactedWidgetsLists}
                keyContributors={keyContributors}
                lookupData={lookupData}
                opConfigId={opConfigId}
                preset={preset}
                setNumExtraItems={setNumExtraItems}
                showAll={showMore}
                {...props}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
