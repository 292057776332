import React, { useMemo, CSSProperties } from "react";
import { capitalize, upperCase, lowerCase, snakeCase, camelCase } from "lodash";
import { cx } from "emotion";
import { WidgetExtProps } from "../types";
import { convertMarkdownToHtml } from "../../../utils";
import TextWidgetImpl from "./impl";

export interface TextWidgetProps extends WidgetExtProps {
  widget: TextWidgetImpl;
}

const TextWidget: React.FC<TextWidgetProps> = props => {
  const { widget } = props;
  const { text, properties } = widget;

  const { caseStyle = "none", centerAlign: center = "none", size = 16, weight = 500 } = properties;

  const style = useMemo<CSSProperties>(
    () => ({
      flex: "1",
      height: "100%",
      width: "100%",
      fontSize: size,
      fontWeight: weight
    }),
    [size, weight]
  );

  const renderText = useMemo(() => {
    if (!text) {
      return "";
    }

    switch (caseStyle) {
      case "camel":
        return camelCase(text);
      case "capitalize":
        return capitalize(text);
      case "lower":
        return lowerCase(text);
      case "snake":
        return snakeCase(text);
      case "upper":
        return upperCase(text);
      case "markdown":
        return `<div class="inc-flex-column width-100 height-100" style="overflow: auto;">
        ${convertMarkdownToHtml(text)}
      </div>`;
      default:
        return text;
    }
  }, [caseStyle, text]);

  const isMarkdown = caseStyle === "markdown";

  const className = cx(
    "inc-text-widget",
    "inc-flex-row",
    {
      "inc-flex-center": center === "both",
      "inc-flex-center-horizontal": center === "horizontal",
      "inc-flex-center-vertical": center === "vertical"
    },
    []
  );

  return (
    <>
      {isMarkdown && (
        <div
          className={className}
          dangerouslySetInnerHTML={{ __html: renderText }}
          style={style}
        />
      )}
      {!isMarkdown && (
        <div
          className={className}
          style={style}
        >
          {renderText}
        </div>
      )}
    </>
  );
};

export default TextWidget;
