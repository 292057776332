import { IncClickAwayPopper } from "@inception/ui";
import React from "react";
import { FieldPickerModel } from "../../field-picker";
import { BizEntityType, UserServiceField, UserServiceTuple } from "../../services/api/explore";
import { FieldPickerPopUpContent } from "./FieldPickerPopUpContent";

interface FieldPickerPopUpV2Props {
  eventId?: string;
  entityTypeId?: string;
  userServices?: UserServiceTuple[];

  show: boolean;
  anchorEl: HTMLElement;

  onSelect: (field: UserServiceField) => void;
  onClose: () => void;
  multiSelectionProps?: {
    selectedFields: UserServiceField[];
    onSelectionChange: (fields: UserServiceField[]) => void;
  };

  skipCohortFields?: boolean;
  isMetaLoading?: boolean;
  skipEntityIcons?: boolean;

  fieldPickerModel?: FieldPickerModel;
  onFieldsFetched?: (model: FieldPickerModel) => void;

  bizEntityTypes?: BizEntityType[];
  onBizEntityTypesFetched?: (beTypes: BizEntityType[]) => void;

  children?: JSX.Element;
  className?: string;
  containerClassName?: string;
  excludeAllUserServiceField?: boolean;
}

export const FieldPickerPopUpV2 = (props: FieldPickerPopUpV2Props) => {
  const { show, anchorEl, onClose, className = "", containerClassName = "" } = props;

  return (
    <IncClickAwayPopper
      anchorEl={anchorEl}
      className={className}
      onClickAway={onClose}
      placement="bottom-end"
      show={show}
    >
      <FieldPickerPopUpContent
        containerClassName={containerClassName}
        {...props}
      />
    </IncClickAwayPopper>
  );
};
