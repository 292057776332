import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IncButton, IncFaIcon, IncModal, IncModalProps, IncSmartText } from "@inception/ui";
import { cloneDeep } from "lodash";
import { OpFragmentEditProps } from "../types";
import { VerticallyCenteredRow } from "../../components";
import { useToggleState } from "../../core";
import { OpCreationFragmentEditType } from "../../services/api/operationalise";
import { OpScheduleEditorV2 } from "../../operationalise-v2/v3/editors/ScheduleEditor/ScheduleEditorV2";

type Props = OpFragmentEditProps;

export const ScheduleFragmentEditor: FC<Props> = props => {
  const { opFragment, invalidFragmentJsx } = props;

  if (opFragment?.opSchedule?.schedule) {
    return <ScheduleFragmentEditorInternal {...props} />;
  }

  return invalidFragmentJsx;
};

const ScheduleFragmentEditorInternal: FC<Props> = props => {
  const { className, opFragment, onChange, onClose, showEditorInModal = false } = props;

  const { isOpen: isUpdateInProgress, open: startUpdate, close: finishUpdate } = useToggleState();

  const errorMessageRef = useRef("");

  const defSchedule = opFragment.opSchedule;
  const [schedule, setSchedule] = useState(cloneDeep(defSchedule));

  const resetSchedule = useCallback(() => {
    setSchedule(cloneDeep(defSchedule));
  }, [defSchedule]);

  useEffect(() => {
    resetSchedule();
  }, [resetSchedule]);

  const onCancelChanges = useCallback(() => {
    resetSchedule();
    onClose?.();
  }, [onClose, resetSchedule]);

  const onSaveChanges = useCallback(async () => {
    errorMessageRef.current = "";
    startUpdate();

    const { isError, message } = await onChange(
      {
        ...opFragment,
        opSchedule: schedule
      },
      OpCreationFragmentEditType.SCHEDULE
    );

    if (isError) {
      errorMessageRef.current = message;
    }

    finishUpdate();
  }, [finishUpdate, onChange, opFragment, schedule, startUpdate]);

  const jsx = useMemo(
    () => (
      <div className={className}>
        <VerticallyCenteredRow className="flex-gap-12">
          <OpScheduleEditorV2
            onChange={setSchedule}
            opSchedule={schedule}
            readOnly={false}
            skipStartEnd={false}
          />
        </VerticallyCenteredRow>

        {!showEditorInModal && (
          <VerticallyCenteredRow className="flex-gap-12 marginTp10">
            {!isUpdateInProgress && (
              <IncButton
                color="primary"
                label="Save Changes"
                onClick={onSaveChanges}
                size="small"
              />
            )}

            {isUpdateInProgress && (
              <IncButton
                color="primary"
                loading
                loadingText="Saving..."
                size="small"
              />
            )}

            {Boolean(errorMessageRef.current) && (
              <VerticallyCenteredRow className="status-danger flex-gap-10">
                <IncFaIcon
                  className="status-danger"
                  iconName="exclamation-triangle"
                />
                <IncSmartText
                  className="status-danger"
                  text={errorMessageRef.current}
                />
              </VerticallyCenteredRow>
            )}

            {!isUpdateInProgress && (
              <IncButton
                color="secondary-blue"
                label="Cancel"
                onClick={onCancelChanges}
                size="small"
              />
            )}
          </VerticallyCenteredRow>
        )}
      </div>
    ),
    [className, isUpdateInProgress, onCancelChanges, onSaveChanges, schedule, showEditorInModal]
  );

  const { close: closeModal, isOpen: IsModalOpen } = useToggleState(true);

  const actions: IncModalProps["actions"] = useMemo(
    () => ({
      primary: {
        label: "Save Changes",
        onClick: () => {
          closeModal();
          onSaveChanges();
        },
        color: "primary",
        showLoader: isUpdateInProgress
      },
      secondary: {
        label: "Cancel",
        onClick: () => {
          closeModal();
          onCancelChanges();
        },
        color: "secondary"
      }
    }),
    [closeModal, isUpdateInProgress, onCancelChanges, onSaveChanges]
  );

  return (
    <>
      {!showEditorInModal && jsx}
      {showEditorInModal && (
        <IncModal
          actions={actions}
          contentClassName="op-fragment-editors-modal-content padding16"
          onClose={closeModal}
          show={IsModalOpen}
          size="xxlg"
          titleText="How often to look for the pattern"
          withActionsBorder
          withTitleBorder
        >
          {jsx}
        </IncModal>
      )}
    </>
  );
};
