import {
  USFieldWidgetModel,
  USFWQueryConfig,
  USFieldWidgetImpl,
  USFWMode,
  USFWRenderMode,
  USFieldWidgetProperties
} from "../../widgets/USField/models";
import VariableModel from "../../models/VariableModel";
import { BaseWidgetBuilder, WidgetBuilderCallbackResult } from "./BaseWidgetBuilder";

const usFieldWidgetBuilder = (
  model: Partial<USFieldWidgetModel> = {}
): WidgetBuilderCallbackResult<USFieldWidgetModel, USFieldWidgetBuilder> => {
  const tsModel = new USFieldWidgetImpl(model).getSafeDefaults() as USFieldWidgetModel;

  // Return a method that ensures a datasource is set before setting any other properties
  return {
    setDatasource: (dsName: string) => {
      tsModel.datasource = dsName;

      return new USFieldWidgetBuilder(tsModel);
    }
  };
};

class USFieldWidgetBuilder extends BaseWidgetBuilder<USFieldWidgetModel> {
  constructor(model: USFieldWidgetModel) {
    super(model);
    this.model = model;
    this.model.properties = this.model.properties || ({} as any);
    this.model.options = this.model.options || {};
  }

  setUserserviceId(usId: string) {
    this.model.userServiceId = usId;
    return this;
  }

  setEntityType(entityType: string) {
    this.model.entityType = entityType;
    return this;
  }

  setBizEntityFieldName(bizEntityFieldName: string) {
    this.model.bizEntityFieldName = bizEntityFieldName;
    return this;
  }

  setQueryConfig(queryConfig: USFWQueryConfig) {
    this.model.queryConfig = queryConfig;
    return this;
  }

  setNumOpConfigs(numOpConfigs: number) {
    this.model.numOpConfigs = numOpConfigs;
    return this;
  }

  setWidgetConfigRefId(widgetConfigRefId: string) {
    this.model.widgetConfigRefId = widgetConfigRefId;
    return this;
  }

  setEntityLookUp(entityLookUp: Record<string, string>) {
    this.model.entityLookUp = entityLookUp;
    return this;
  }

  setEventTypeName(eventTypeName: string) {
    this.model.eventTypeName = eventTypeName;
    return this;
  }

  setMode(mode: USFWMode) {
    this.model.mode = mode;
    return this;
  }

  setVariables(variables: VariableModel[]) {
    this.model.variables = variables;
    return this;
  }

  setRenderMode(renderMode: USFWRenderMode) {
    this.model.renderMode = renderMode;
    return this;
  }

  setProperties(properties: USFieldWidgetProperties) {
    this.model.properties = properties;
    return this;
  }
}

export default usFieldWidgetBuilder;
