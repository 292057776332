import React, { FC, useMemo } from "react";
import { cloneDeep } from "lodash";
import { css, cx } from "emotion";
import timeRangeUtils from "../../../../../utils/TimeRangeUtils";
import { TimeSeriesProperties } from "../../../TimeSeries/models/model";
import SparkLine from "../../../../../components/sparkline/SparkLine";
import { VerticallyCenteredRow } from "../../../../../components";
import { OverTimePostAgg } from "../../../../../services/api/explore";
import { dataTypeManager } from "../../../../../utils";
import { CatalogVizRendererProps, useCommonRendererFunctionality } from "./common";
import { constructTimeseries, getTimeSeriesProperties } from "./TimeseriesRenderer";

export const SparkLineRenderer: FC<CatalogVizRendererProps> = props => {
  const {
    className,
    dataType: defDataType,
    dataTypeByMetricId,
    aggregator,
    currencyType,
    noDataElement,
    loadingElement,
    aggregatedTags,
    displayAggregatedTags,
    properties: widgetProperties,
    timeRange,
    compareTimeRange,
    dataFetchPayload: pDataFetchPayload,
    widgetResponseDTO
  } = props;

  const { sparkLine: sparkLineProperties, dataTypeCustomisation } = widgetProperties;

  const {
    color: sparkLineColor,
    background: sparkLineBackground,
    hide: hideSparkline,
    singleStatProperties,
    metricId: sparkLineMetricId
  } = sparkLineProperties || {};

  const { duration: durationCustomization } = dataTypeCustomisation || {};
  const { maxTerms: durMaxTerms, precision: durPrecision } = durationCustomization || {};

  const { thresholds, defaultColor } = singleStatProperties || {};

  const { sliceSpec, mode } = pDataFetchPayload || {};
  const dataType = dataTypeByMetricId?.[sparkLineMetricId] || defDataType;

  const sparkLineProps = useMemo<
    Pick<CatalogVizRendererProps, "childMetricIds" | "childrenDataFetchPayload" | "dataFetchPayload">
  >(() => {
    let nSliceSpec = sparkLineMetricId
      ? (sliceSpec || []).filter(sliceSpec => sliceSpec.metricId === sparkLineMetricId)
      : (sliceSpec || []).slice(0, 1);

    if (nSliceSpec.length === 0 && sliceSpec?.length > 0) {
      nSliceSpec = cloneDeep(sliceSpec).slice(0, 1);
      nSliceSpec[0].metricId = sparkLineMetricId;
    }

    return {
      dataFetchPayload: {
        sliceSpec: nSliceSpec,
        mode
      },
      childrenDataFetchPayload: null,
      childMetricIds: []
    };
  }, [sparkLineMetricId, mode, sliceSpec]);

  const { data, fieldName, metricName, isFetching, isError, dataExists, childMetricNames, hasChildMetrics } =
    useCommonRendererFunctionality({
      ...props,
      ...sparkLineProps,
      skipDataFetch: hideSparkline
    });

  const compareTimeStr = useMemo(
    () => timeRangeUtils.getCompareTimeStr(timeRange.from.valueOf(), compareTimeRange.from.valueOf()),
    [compareTimeRange.from, timeRange.from]
  );

  const series = useMemo(() => {
    const series = constructTimeseries(
      data,
      metricName,
      childMetricNames,
      aggregatedTags,
      displayAggregatedTags,
      compareTimeStr,
      sparkLineProperties,
      hasChildMetrics,
      true,
      false,
      null,
      dataTypeByMetricId
    );
    series.forEach(serie => (serie.type = "area"));

    return series;
  }, [
    data,
    metricName,
    childMetricNames,
    aggregatedTags,
    displayAggregatedTags,
    compareTimeStr,
    sparkLineProperties,
    hasChildMetrics,
    dataTypeByMetricId
  ]);

  const aggregatorLabel = aggregator?.label;
  const properties = useMemo<TimeSeriesProperties>(() => {
    const seriesSize = series.length;
    return getTimeSeriesProperties(fieldName, dataType, currencyType, aggregatorLabel, seriesSize);
  }, [aggregatorLabel, currencyType, dataType, fieldName, series.length]);

  const chartTimeRange = useMemo(() => {
    const chartTimeRange = timeRangeUtils.getTimeRangeFromRaw(timeRange.raw);
    chartTimeRange.timeZone = timeRange.timeZone;
    return chartTimeRange;
  }, [timeRange.raw, timeRange.timeZone]);

  const { querySchema = [] } = widgetResponseDTO?.querySchema || {};
  const metricVsOverTimeAggMap = useMemo(
    () =>
      querySchema.reduce(
        (acc, query) => {
          const { metricId, defaultTimeAgg } = query;
          acc[metricId] = defaultTimeAgg;
          return acc;
        },
        {} as Record<string, string>
      ),
    [querySchema]
  );

  const singleStatProps = useMemo<
    Pick<CatalogVizRendererProps, "childMetricIds" | "childrenDataFetchPayload" | "dataFetchPayload">
  >(() => {
    const nSliceSpec = cloneDeep(sparkLineProps.dataFetchPayload.sliceSpec);
    nSliceSpec.forEach(ss => {
      ss.postAgg.isSingleStatQuery = true;
      (ss.postAgg as OverTimePostAgg).overTimeAgg = {
        aggregator: metricVsOverTimeAggMap[ss.metricId] || "avg",
        timeInSeconds: timeRangeUtils.getSecondsFromMillis(timeRangeUtils.getDiffMillisFromTimeRange(timeRange))
      };
    });

    return {
      dataFetchPayload: {
        sliceSpec: nSliceSpec,
        mode
      },
      childrenDataFetchPayload: null,
      childMetricIds: []
    };
  }, [metricVsOverTimeAggMap, mode, sparkLineProps.dataFetchPayload.sliceSpec, timeRange]);

  const {
    data: singleStatData,
    isFetching: isFetchingSingleStatData,
    isError: isErrorSingleStat,
    dataExists: isSingleStatDataExists
  } = useCommonRendererFunctionality({
    ...props,
    ...singleStatProps,
    queryId: `${props.queryId}_single_stat`
  });

  const singleStatValue = useMemo(() => {
    const datum = singleStatData?.[0];
    if (datum) {
      const postAggData = datum.postAggResult.data;

      const refId = Object.keys(postAggData)[0];

      const dataFrame = postAggData[refId]?.data || [];
      const val = dataFrame[0]?.fields?.[1]?.data?.[0] || null;
      return val;
    }
    return "";
  }, [singleStatData]);

  const formattedSingleStatValue = useMemo(
    () =>
      dataTypeManager.getDataTypeDescriptor(dataType)?.getFormattedValue(singleStatValue, {
        numberFormatOptions: {
          currencyType,
          compact: true
        },
        percentFormatOptions: {
          precision: 2
        },
        durationFormatOptions: {
          maxTerms: durMaxTerms,
          precision: durPrecision
        }
      }) || singleStatValue,
    [currencyType, dataType, durMaxTerms, durPrecision, singleStatValue]
  );

  const singleStatTextColor = useMemo(() => {
    // check the value in thresholds
    if (thresholds?.length > 0) {
      const threshold = thresholds?.find((x: any) => x.fromVal <= singleStatValue && x.toVal >= singleStatValue);
      if (threshold) {
        return threshold.color;
      }
    }
    return defaultColor;
  }, [singleStatValue, defaultColor, thresholds]);

  const textClass = useMemo(
    () => css`
      color: ${singleStatTextColor};
      font-size: 40px;
      line-height: 40px;
      font-weight: 600;
    `,
    [singleStatTextColor]
  );

  const isFetchingSparklineData = hideSparkline ? false : isFetching;

  if (isFetchingSparklineData || isFetchingSingleStatData) {
    return loadingElement;
  }

  if ((!hideSparkline ? !dataExists || isError : false) || !isSingleStatDataExists || isErrorSingleStat) {
    return noDataElement;
  }

  const textClasses = cx(textClass, "inc-flex-center width-100", {
    "height-100": hideSparkline
  });

  return (
    <div
      className={className}
      style={{ backgroundColor: sparkLineBackground }}
    >
      <VerticallyCenteredRow className={textClasses}>{formattedSingleStatValue}</VerticallyCenteredRow>

      {!hideSparkline && (
        <div className={sparklineClass}>
          <SparkLine
            containerElemClass={sparklineClass}
            properties={properties}
            series={series}
            seriesColors={[sparkLineColor]}
            timeRange={chartTimeRange}
          />
        </div>
      )}
    </div>
  );
};

const sparklineClass = css`
  height: calc(100% - 54px);
`;
